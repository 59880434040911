import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { CecoService } from 'app/rest/ceco.service';
import { RestExt } from 'app/services/rest-client-extension';
import { BillingaccountService } from 'app/rest/billingaccount.service';

@Component({
  selector: 'app-cost-centre-form',
  templateUrl: './cost-centre-form.component.html',
  styleUrls: ['./cost-centre-form.component.scss'],
  providers: [CecoService, IconService, BillingaccountService],
  encapsulation: ViewEncapsulation.None,
})
export class CostCentreFormComponent
  extends EntityFormComponent<Rest.Ceco>
  implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    cecoEnabled: boolean;

    clientID: number;

    entityEnumImageClient: Rest.IconEntityAssociationEnum = "CLIENT";

    profileImage: Rest.Icon;

    //FORM LABELS
    clientImageLabel: string;

    // Preservation of relationships
  companyRequestContext: Rest.ListPaginationRequest;
  companyList: Rest.BillingAccount[] = [];


    constructor(
        private cecoService: CecoService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
        public translateService: TranslateService,
        public billingAccountService: BillingaccountService
    ) {
        super(cecoService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ClientFormComponent
     */
    beforeShow() {
        this.cecoEnabled = !this.entity.disabled;
        this.clientID = this.authenticationService.user.client.id;

        this.clientImageLabel = this.translate.instant('control-panel.clients.clientImage');

        this.companyRequestContext = {
            sortBy: "name",
            sortAsc: true,
            pageSize: 10,
            filters:{}
        } as unknown as Rest.ListPaginationRequest;

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.costCentres.createCostCentre');
        } else {
            this.title = this.translateService.instant('tableManagement.costCentres.editCostCentre');
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        this.companyRequestContext.filters["clientID"] = [this.clientID.toString()];


      this.billingAccountService.getPage(this.companyRequestContext).then((p) => {
        this.companyList = p.entities;
      });
    }

    onChangeCompany(event) {
        this.entity.billingAccount = event.value;
      }
    
      onFilterCompany(event) {
        this.companyRequestContext = {
          sortBy: "name",
          sortAsc: true,
          pageSize: 10,
          filters:{}
        } as unknown as Rest.ListPaginationRequest;
    
        this.companyRequestContext.filters["clientID"] = [this.clientID.toString()];
        this.companyRequestContext.filters["name"] = [event.filter ?? ''];
        this.billingAccountService.getPage(this.companyRequestContext).then((p) => {
          this.companyList = p.entities;
        });
      }
}
