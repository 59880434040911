<p-accordionTab>

  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Jamming Detection (AT + GTJDC)</span>
        </div>
      </div>
    </p-header>
  
        <div class="ui-g">
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Mode" >
              <p-dropdown  (onChange)="trackEventModeChange(jammingDetection.mode,$event)" [options]="jammingModeOptions"
              [(ngModel)]="jammingDetection.mode" [style]="{'width':'190px'}"></p-dropdown>
            </form-input>
          </div>
          <div [class.disableDIV]=!trackModeSwitch>
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Engine Off to Tow">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="signalThreshold" size="2" [(ngModel)]="jammingDetection.signalThreshold"
                        [min]="0" [max]="31"></p-spinner>
                    <span class="ui-inputgroup-addon">0~31</span>
                </div>
            </form-input>
          </div>
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Fake Tow Delay">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="jammingCellNumberThreshold" size="2" [(ngModel)]="jammingDetection.jammingCellNumberThreshold"
                        [min]="0" [max]="99"></p-spinner>
                    <span class="ui-inputgroup-addon">0~99</span>
                </div>
            </form-input>
          </div>
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Tow Interval">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="enterJammingTimerThreshold" size="7" [(ngModel)]="jammingDetection.enterJammingTimerThreshold"
                        [min]="0" [max]="300"></p-spinner>
                    <span class="ui-inputgroup-addon">0~300 </span>
                    <span class="ui-inputgroup-addon" >seconds</span>
                </div>
            </form-input>
          </div>
  
        <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Rest Duration">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="quitJammingTimerThreshold" size="2" [(ngModel)]="jammingDetection.quitJammingTimerThreshold"
                        [min]="0" [max]="3600"></p-spinner>
                    <span class="ui-inputgroup-addon">0~3600</span>
                    <span class="ui-inputgroup-addon" >seconds</span>
                </div>
            </form-input>
        </div>
  
        
        </div> 
        </div>
  
  </p-accordionTab>
