<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SENSOR SENSITIVITY
          (LEVEL)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Sensitivity grade">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sensorSensitivity.sensitivityGrade" [min]="1" [max]="5"></p-spinner>
          <span class="ui-inputgroup-addon">1~5</span>
          <span class="ui-inputgroup-addon">grade</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>