import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class MaintenanceplanService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.MaintenancePlan): Rest.RestResponse<Rest.MaintenancePlan> {
return this.client.MaintenancePlan_create(arg0);
}

update(arg0: Rest.MaintenancePlan): Rest.RestResponse<Rest.MaintenancePlan> {
return this.client.MaintenancePlan_update(arg0);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.MaintenancePlan>> {
return this.client.MaintenancePlan_getPage(arg0);
}

getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.MaintenancePlan>> {
return this.client.MaintenancePlan_getPageAndFilters(arg0);
}

changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.MaintenancePlan_changeStatus(arg0, queryParams);
}

getList(arg0) : Rest.RestResponse<Rest.MaintenancePlan[]> {
	return this.client.MaintenancePlan_getList(arg0);
}

}
