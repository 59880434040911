import { Rest } from '../../../../rest/rest_client';
import RestResponse = Rest.RestResponse;
import { Rest_logistics } from '../../Logistics/services/rest_client_logistics';

export namespace Rest_task {
	export interface Delegation extends Rest.Trimmable, Rest.WithId {
		id: number;
		code_name: string;
		info: string;
		client: Rest.Client;
		user: Rest.User;
		routedata: Route[];
		vehicledata: Rest.Vehicle[];
		delegUserData: DelegationUser[];
		//usersCheckeds: Rest.User[];
	}

	export interface Route extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		delegation: Delegation;
		//originRoutes: string; //esto tendrán que sera las tiendas
		//originRoute: Rest.POI;
		originName: String;
		originRoute: Rest_logistics.Customer;
		user: Rest.User;
		name: string;
		description: string;
		deleted: boolean;
		checkedvalue: boolean;
		//checkedvalue: boolean;
		routeType: RouteType;
		routeDriversDefault: RouteDriverDefault[];
	}



	export interface RouteType extends Rest.Trimmable, Rest.WithId {
		id: number;
		code: string;
		name: string;
		description: string;

	}

	// export interface Userbo extends Rest.Trimmable, Rest.WithId {
	// 	user:Rest.User;
	// }
	// export interface Userapp extends Rest.Trimmable, Rest.WithId {
	// 	user:Rest.User;
	// }

	export interface DelegationUser extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		delegation: Delegation;
		user: Rest.User;
		//checked:boolean;
	}

	export interface RouteDriverDefault extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		route: Rest_task.Route;
		user: Rest.User;
		monday: Boolean;
		tuesday: Boolean;
		wednesday: Boolean;
		thursday: Boolean;
		friday: Boolean;
		saturday: Boolean;
		sunday: Boolean;
		deleted: Boolean;
		dateCreated: Date;
	}

	export interface ReportTypes extends Rest.Trimmable {
		id: number;
		name: string;
	}

	export interface HttpClient {
		request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R }): Rest.RestResponse<R>;
	}

	/**
	 * PREDICTIONS INTERFACES
	 */
	export interface ProcessStatusReport {
		id: string;
		type: TypeEnum;
		executionStartDate: Date;
		executionEndDate: Date;
		status: StatusEnum;
		executionMetrics: ExecutionMetrics;
	}

	export interface Pipeline {
		multiplyStep: string;
	}

	export enum TypeEnum {
		PUNCTUAL = "PUNCTUAL",
		STREAMING = "STREAMING",
	}

	export enum StatusEnum {
		PENDING = "PENDING",
		RUNNING = "RUNNING",
		SUCCESS = "SUCCESS",
		FAIL = "FAIL",
	}

	export enum Source {
		FINITE = "FINITE",
		STREAMING = "STREAMING",
	}

	export interface ExecutionMetrics {
		source: Source;
		pipeline: Pipeline;
		sink: string;
	}
	export interface Demand {
		customerCode: string;
		productId: string;
		historic: Map<string, Map<string, number>>;
	}

	export interface Order {
		order_id: number;
		date_created: string;
		customer: Customer;
		Product: Product;
	}

	export interface Customer {
		customerCode: number;
		deliveryPostCode: string;
		deliveryCity: string;
	}

	export interface Product {
		productCode: string;
		quantity: number;
		unityOfMeasure: string;
		productFamily: string;
	}

	export interface OrderResult {
		order_id: number;
		group: number;
	}

	export interface ProcessPID {
		code: string;
		pid: string;
	}

	export interface Anomaly {
		kafkaServer: string;
		vehiclesToListen: number[];
	}

	export interface Telemetry {
		temperature: string;
		warning: string;
	}

	export interface Article {
		id: number;
		code: string;
		name: string;
		client: Rest.Client;
	}

	export class RestApplicationClient {
		constructor(protected httpClient: HttpClient) {
		}

		/** DELEGATIONS  **/
		Delegation_create(arg0: Delegation): Rest.RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/delegations`,
				data: arg0,
			});
		}

		Delegation_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_task.Delegation>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/delegations/page`,
				data: arg0,
			});
		}

		Delegation_find(uid: string, queryParams?: { id?: number }): RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/delegations/${uid}`,
				queryParams: queryParams,
			});
		}

		Delegation_update(arg0: Delegation): Rest.RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/delegations`,
				data: arg0,
			});
		}


		Delegation_delete(arg0: Delegation): Rest.RestResponse<boolean> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/delegations/delete`,
				data: arg0,
			});
		}

		delegationGetAllDataTask(id: number): Rest.RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/delegations/allDataTask/${id}`,
			});
		}

		delegationAddAllDataTask(arg0: Rest_task.Delegation): Rest.RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/delegations/AddAllDataTask`,
				data: arg0,
			});
		}

		delegationUpdateAllDataTask(arg1: Delegation): Rest.RestResponse<Rest_task.Delegation> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/delegations/UpdateAllDataTask`,
				data: arg1,
			});
		}
		/*
			Delegation_getAvailableUserAssignations(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_task.Delegation>> {
				return this.httpClient.request({
					method: 'POST',
					url: uriEncoding`api/delegations/user/assignations`,
					data: arg0,
				});
			} */

		User_getPage(arg0: Rest.ListPaginationRequest, type: number): Rest.RestResponse<Rest.Page<Rest.User>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/pageTask/${type}`,
				data: arg0,
			});
		}

		User_create(arg0: Rest.User, type: number): Rest.RestResponse<Rest.User> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/create/task/${type}`,
				data: arg0,
			});
		}

		User_update(arg0: Rest.User, type: number): Rest.RestResponse<Rest.User> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/user/update/task/${type}`,
				data: arg0,
			});
		}

		User_delete(arg0: { userId: number, projectTypeId: number }): Rest.RestResponse<void> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/user/delete/task`,
				data: arg0,
			});
		}

		Userbo_getPermissions(): Rest.RestResponse<Rest.Profile[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`/api/task/roleprofilehierarchy`,
			});
		}

		/** USER BO/APP **/
		/*
		 Userboapp_create(arg0: Delegation, arg2:number): Rest.RestResponse<Rest.User> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user`,
				data: arg0,
			});
	
		 }
	
		Userboapp_getPage(arg0: Rest.ListPaginationRequest, type:number): Rest.RestResponse<Rest.Page<Rest.User>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/pageboapp/${type}`,
				data: arg0,
			});
		}
	
		 Userboapp_update(arg0: Delegation): Rest.RestResponse<Rest.User> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/user`,
				data: arg0,
			});
		}
	
		Userboapp_getPageAndFilters(arg0: Rest.ListPaginationRequest, type:number): Rest.RestResponse<Rest.ListPageAndFilters<Rest.User>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/pageAndFiltersboapp/${type}`,
				data: arg0,
			});
		} */

		/*Userboapp(type:number): Rest.RestResponse<Rest.User[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/userDriversTask/${type}`,
			});
		}*/

		Vehicle_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Vehicle>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/vehicle/page`,
				data: arg0,
			});
		}


		//Routes
		/**
	 * HTTP POST /api/user/pageAndFilters
	 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RouteController.getPage
	 */
		Route_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_task.Route>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routes/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/user/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RouteController.User_getPageAndFilters
		 */
		Route_getPageAndFilters(arg0: Rest.ListPaginationRequest): RestResponse<Rest.ListPageAndFilters<Rest_task.Route>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routes/pageAndFilters`,
				data: arg0,
			});
		}


		Route_find(uid: string, queryParams?: { id?: number }): RestResponse<Rest_task.Route> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/routes/${uid}`,
				queryParams: queryParams,
			});
		}
		Route_create(arg0: Route): Rest.RestResponse<Rest.GenericResp<Rest_task.Route>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routes`,
				data: arg0,
			});
		}
		Route_update(arg0: Route): Rest.RestResponse<Rest.GenericResp<Rest_task.Route>> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/routes`,
				data: arg0,
			});
		}

		// Route_create(arg0: Route): Rest.RestResponse<Rest_task.Route> {
		// 		return this.httpClient.request({
		// 			method: 'POST',
		// 			url: uriEncoding`api/task/routes`,
		// 			data: arg0,
		// 		});
		// 	}
		// Route_update(arg0: Route): Rest.RestResponse<Rest_task.Route> {
		// 		return this.httpClient.request({
		// 			method: 'PUT',
		// 			url: uriEncoding`api/task/routes`,
		// 			data: arg0,
		// 		});
		// 	}


		Route_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/routes/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Route_delete(arg0: Route): Rest.RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/routes`,
				data: arg0,
			});
		}

		//Route Types
		RouteType_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<RouteType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routes/types/page`,
				data: arg0,
			});
		}

		RouteType_findByUserClient(): RestResponse<RouteType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/routes/types`,
			});
		}

		/*
		**  PREDICTIONS METHODS
		*/
		getStatusPID(pid: string): RestResponse<ProcessStatusReport> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/logisticsAi/process/${pid}`,
			});
		}

		prediction_find(arg0: Demand): Rest.RestResponse<Map<string, number>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/logisticsAi/demand`,
				data: arg0,
			});
		}

		getArticlesList(productId: string): RestResponse<Map<string, Map<string, number>>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/logisticsAi/neighbours/${productId}`,
			});
		}

		getOrder(arg0: Order): RestResponse<OrderResult> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/logisticsAi/order`,
				data: arg0,
			});
		}

		getAnomalyPID(arg0: Anomaly): RestResponse<ProcessPID> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/logisticsAi/anomaly`,
				data: arg0,
			});
		}

		getAnomaly(arg0: number[]): RestResponse<Map<string, Map<string, Telemetry>>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/logisticsAi/anomaly/result`,
				data: arg0,
			});
		}

		getArticlesPage(arg0: Rest.ListPaginationRequest): RestResponse<Rest.Page<Article>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/article/page`,
				data: arg0,
			});
		}

	}


	function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
		let result = '';
		for (let i = 0; i < substitutions.length; i++) {
			result += template[i];
			result += encodeURIComponent(substitutions[i]);
		}
		result += template[template.length - 1];
		return result;

	}


}
