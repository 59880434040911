import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from "@angular/core";
import { SelectItem } from "primeng/api";

import { Rest } from "../../../../rest/rest_client";
import { RealtimeService } from "../../../../rest/realtime.service";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { RestExt } from "../../../../services/rest-client-extension";
import { RealTimeDataService } from "../../../../services/rt-data/rt-data.service";
import { AuthenticationService } from "../../../../core/services/authentication/authentication.service";

@Component({
  selector: 'app-sensor-chart-v2',
  templateUrl: './sensor-chart-v2.component.html',
  styleUrls: ['./sensor-chart-v2.component.css'],
  providers: [RealtimeService],
})
export class SensorChartV2Component implements OnInit, OnChanges {
    @Input() fleets: Rest.RTFleet[];
    @Input() inputSettings: Rest.SensorChartSettings;
    @Output() settingsChange: EventEmitter<Rest.SensorChartSettings> = new EventEmitter<Rest.SensorChartSettings>();
    protected tagsAndValues: { [index: string]: Rest.CategoricalValue[] };
    protected listTagsAndValues: Rest.DTagAndValue[];
    public availableTags: SelectItem[];
    public availableSensor: SelectItem[];
    public availableActuator: SelectItem[];

    protected sensorTagValue: { [index: string]: Rest.CategoricalValue[] };
    protected actuatorTagValue: { [index: string]: Rest.CategoricalValue[] };
    
    numercialValues: number[] = [];
    availableModels: SelectItem[];

    //Labels
    categorical: string;
    numerical: string;

    //Form vars
    settings: Rest.SensorChartSettings;
    displaySettingsForm: boolean = false;
    categoricalMappings: RestExt.Pair<string, string>[];
    categoricalValues: { [index: string]: Rest.CategoricalValue };
    numericalMappings: RestExt.Pair<number, string>[];

    userLanguage: string;



    //chart vars
    private mappings: RestExt.Pair<string, string>[];
    chartData: any;
    chartOptions = {
        legend: {
            position: "right",
        },
        animation: {
            duration: 0,
        },
    };



    constructor(
        protected i18n: I18nService,
        private rtService: RealtimeService,
        private realTimeDataService: RealTimeDataService,
        private authService: AuthenticationService
    ) {
        this.fleets = [];
        this.settings = Object.assign({}, emptySettings);
        this.numericalMappings = [];
        this.categoricalMappings = [];
        this.categoricalValues = {};
        this.mappings = [];
        this.availableModels = RestExt.getEnumItemsNoTranslation(RestExt.DEnum);
        this.availableModels.push({ label: "ALL", value: "ALL" });
    }

    ngOnInit() {
      this.realTimeDataService.vehicleInfo.subscribe(
          (v: RestExt.ExtendedVehicleDataMessage) => {
            this.reloadChart();
          }
      );
      this.userLanguage = this.authService.user.language.toLowerCase();
      this.categorical = this.i18n._("Categorical");
      this.numerical = this.i18n._("Numerical");
      this.settings.filterType = this.settings.filterType;
    }

    ngOnChanges(changes: SimpleChanges) {
      this.reloadChart();
      if (changes["fleets"]) {
        this.loadSources();
      }

      if (changes["settings"]) {
        this.loadData();
        this.reloadChart();
      }
    }

    loadData() {
        // this.categoricalMappings = [];
        // this.categoricalValues = {};
        // this.numericalMappings = [];

        if (this.settings == null) return;

        for (let key in this.settings.categoricalValuesMappings) {
          this.categoricalMappings.push({
            key: key,
            value: this.settings.categoricalValuesMappings[key].color,
          });
          
          let translation = this.settings.categoricalValuesMappings[key].keyTranslation;
          this.categoricalValues[key] = <Rest.CategoricalValue>{
            key: key,
            en: translation,
            es: translation,
            fr: translation,
            cat: translation,
          };
        }

        // for (let key in this.settings.numericalValuesColors) {
        //     this.numericalMappings.push({
        //         key: Number(key),
        //         value: this.settings.numericalValuesColors[key],
        //     });
        // }
        // this.categoricalMappings = [...this.categoricalMappings];
        // this.numericalMappings = this.numericalMappings.sort(
        //     (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
        //         return a.key - b.key;
        //     }
        // );
        // this.numericalMappings = [...this.numericalMappings];
        // Object.assign(this.settings, this.settings);

        // this.mappings = this.settings.showCategoricalValue
        //     ? this.categoricalMappings
        //     : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
        //         return <RestExt.Pair<string, string>>{
        //             key: a.key + "",
        //             value: a.value,
        //         };
        //     });
    }

    switchNumeric() {
      this.settings.showCategoricalValue = false;
      this.updateCategoricalTags();
    }

    switchCategorical() {
      this.settings.showCategoricalValue = true;
      this.updateCategoricalTags();
    }

    updateCategoricalTags() {}

    onColorChange(pair: RestExt.Pair<number, string>) {
      let foundPair = this.numericalMappings.find((a) => a.key == pair.key);
      foundPair.value = pair.value;

      this.saveNumerical();
    }

    load() {
      this.loadSources();
      this.loadNumerical();
    }

    loadSources() {
      if (this.fleets == null) {
        this.tagsAndValues = {};
        this.availableTags = [];
        this.reloadChart();
        return;
      }

      let vehiclesIds: number[] = [];
      this.fleets.forEach((f: Rest.RTFleet) => {
        f.groups.forEach((g: Rest.RTVehiclesGroup) => {
          g.vehicles.forEach((v: Rest.RTVehicle) => {
            vehiclesIds.push(v.id);
          });
        });
      });

      this.rtService.getTagsAndCategoricalValues(vehiclesIds).then((r) => {
        this.listTagsAndValues = r;
        this.availableTags = [];
        this.availableSensor = [];
        this.availableActuator = [];

        this.listTagsAndValues.forEach((dv: Rest.DTagAndValue) => {
          if (dv.dEnum == "SENSOR") {
              this.sensorTagValue = dv.tagAndValues;
          }
          if (dv.dEnum == "ACTUATOR") {
              this.actuatorTagValue = dv.tagAndValues;
          }
          if (dv.dEnum == "ALL") {
              this.tagsAndValues = dv.tagAndValues;
          }
        });

        for (let tag in this.sensorTagValue) {
          this.availableSensor.push(<SelectItem>{ label: tag, value: tag });
        }

        for (let tag in this.actuatorTagValue) {
          this.availableActuator.push(<SelectItem>{ label: tag, value: tag });
        }

        for (let tag in this.tagsAndValues) {
          this.availableTags.push(<SelectItem>{ label: tag, value: tag });
        }

        this.availableTags = [...this.availableTags];
        this.availableActuator = [...this.availableActuator];
        this.availableSensor = [...this.availableSensor];
        this.updateCategoricalTags();
      });
    }

    loadNumerical() {
      this.numericalMappings = [];
      for (let key in this.settings.numericalValuesColors) {
        this.numericalMappings.push({
          key: Number(key),
          value: this.settings.numericalValuesColors[key],
        });
      }
      this.numericalMappings = this.numericalMappings.sort(
        (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
          return a.key - b.key;
        }
      );
    }

    save() {
      this.saveNumerical();
    }

    saveNumerical() {
      this.settings.numericalValuesColors = {};
      for (let key in this.numericalMappings) {
        this.settings.numericalValuesColors[this.numericalMappings[key].key] =
          this.numericalMappings[key].value;
      }
    }

    addNumericalValueMapping() {
      this.numericalMappings.push(<RestExt.Pair<number, string>>{
        key: this.numericalMappings.length + 1,
        value: "#f6f6f6",
      });
      this.numericalMappings = this.numericalMappings.sort(
        (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
          return a.key - b.key;
        }
      );
      this.numericalMappings = [...this.numericalMappings];

      this.settings.numericalValuesColors = {};
      for (let key in this.numericalMappings) {
        this.settings.numericalValuesColors[this.numericalMappings[key].key] = this.numericalMappings[key].value;
      }
    }

    removeNumericalValueMapping(pair) {
      this.numericalMappings.splice(this.numericalMappings.indexOf(pair), 1);
      this.numericalMappings = [...this.numericalMappings];
    }






    reloadChart() {
      let frequencies = this.mappings.map((m: RestExt.Pair<string, string>) => {
          return 0;
      });
      this.realTimeDataService.vehicleInfoArr.forEach(
          (v: RestExt.ExtendedVehicleDataMessage) => {
              frequencies[v.category_idx]++;
          }
      );
      let labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
          return m.key;
      });
      if (this.settings.showCategoricalValue) {
          labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
              return this.categoricalValues[m.key][this.userLanguage];
          });
      }

      this.chartData = {
          labels: labels,
          datasets: [
              {
                  data: frequencies,
                  backgroundColor: this.mappings.map(
                      (m: RestExt.Pair<string, string>) => {
                          return m.value;
                      }
                  ),
              },
          ],
      };
    }
}

const emptySettings = <Rest.SensorChartSettings>{
    id: 0,
    showCategoricalValue: false,
    sensorTag: "",
    categoricalValuesMappings: {},
    numericalValuesColors: {},
    filterType: "SENSOR",
};
