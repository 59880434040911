<!-- SIDE PANEL MAIN CONTAINER -->
<div class="container-fluid m-0 custom-panel real-time-fleet-main-container">

  <!-- ROW 1: TILE, SEARCH BAR & SETTINGS ICON -->
  <div class="row side-panel-title-row">

    <!-- COL 1: TITLE -->
    <div class="col">
      <div class="d-flex align-items-center real-time-side-panel-title">
        <div class="custom-title">{{'real-time.deviceTitle' | translate}}</div>
      </div>
    </div>

    <!-- COL 2: SEARCH BAR & SETTINGS ICON -->
    <div class="col">
      <div class="d-flex align-items-center justify-content-end rt-side-panel-search">

        <!-- SEARCH BAR -->
        <div class="input-group">

          <div class="input-group-text d-flex align-items-center justify-content-center ps-1 custom-rt-fleet-tree-group-text">
            <svg-icon class="d-flex m-0 p-0" src="assets/icons/search.svg" [svgStyle]="{'height.px': 15, 'width.px': 15}"></svg-icon>
          </div>

          <input type="text"
                 class="form-control custom-rt-fleet-tree-search"
                 [(ngModel)]="deviceSearchString"
                 (keyup)="deviceSearch()">

        </div>

        <!-- SETTINGS ICON -->
        <div class="ms-2">
          <button
            type="button"
            class="btn custom-side-panel-settings-button"
            data-bs-toggle="modal"
            data-bs-target="#sidePanelSettings">
            <svg-icon
              src="assets/icons/settings.svg"
              class="d-flex flex-wrap"
              [svgStyle]="{ 'height.px': 25, 'width.px': 20 }">
            </svg-icon>
          </button>
        </div>

      </div>
    </div>

  </div>

  <hr class="separator mt-0 mb-4 custom-real-time-separator">

  <!-- ROW 2: FLEETS TREE -->
  <div class="row mt-3 fleets-tree-row">
    <app-rt-fleet-tree
      [fleets]=fleets
      [(selection)]=selectedElems
      [cascadeSelection]=true
      [selectableEntities]=true></app-rt-fleet-tree>
  </div>

</div>

<!-- SIDE PANEL SETTINGS MODAL-->
<p-dialog
  id="sidePanelSettings"
  class="modal fade"

  header="{{'real-time.settingsModal.title' | translate}}"
  [(visible)]="settingsModalVisible"
  [closable]="false"

  aria-hidden="true"
  aria-labelledby="sidePanelSettings"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1">
  
  <ng-template pTemplate="header">
    <span class="modal-header-text modal-title custom-title" i18n>{{'real-time.settingsModal.title' | translate}}</span>
    <button type="button" class="btn-close custom-modal-close-button" data-bs-dismiss="modal" aria-label="Close"></button>
  </ng-template>

  <div class="container-fluid large-settings-modal ">
    <!-- ROW 1: OPTIONS AND PANEL SELECTOR -->
    <div class="row">
      <div *ngIf="hasAvailableSettings" class="col-4 breaking-column-s">
        <div class="d-flex align-items-center h-100">
          <label class="form-label custom-label mb-0 me-2" i18n>{{'real-time.settingsModal.sidePanel' | translate}}</label>
          <p-dropdown class="custom-input"
            (onChange)="settingsChanged()"
            [options]="availableSettings"
            [(ngModel)]="_selectedSettings"
            [style]="{'width':'250px'}">
          </p-dropdown>
        </div>
      </div>
      <br>
      <div class="col breaking-column-s">
        <div
          class="d-flex align-items-center justify-content-end h-100 gap-2"
          [class.noSettingsAvailable]="!hasAvailableSettings">
          <button class="btn custom-button custom-side-panel-button" data-bs-target="#sidePanelEditName" data-bs-toggle="modal" data-bs-dismiss="modal">
            {{'general.add' | translate}}
          </button>
    
          <button
            class="btn custom-button custom-side-panel-button"
            [disabled]="!hasAvailableSettings"
            (click)=update()
            i18n>{{'general.save' | translate}}
          </button>
    
          <button
            class="btn custom-button custom-side-panel-button"
            [disabled]="!hasAvailableSettings"
            (click)=delete()
            i18n>
            {{'general.delete' | translate}}
          </button>
    
        </div>
      </div>
    </div>

    <br>

    <div class="general-container" *ngIf="hasAvailableSettings">
      <div class="general-container-header">
        <div class="row">
          <div class="col-6">
            <div *ngIf="!panelNameEditActivated; else panelNameField" class="d-flex align-items-center h-45">
              <label class="">{{_selectedSettings.name}}</label>
            </div>
            
            <ng-template #panelNameField>
              <input
                type="text"
                id="side-panel-settings-name"
                name="side-panel-settings-name"
                class="form-control custom-input h-45"
                [(ngModel)]=_selectedSettings.name>
            </ng-template>
          </div>
          <div class="col d-flex align-items-center justify-content-end">

            <div *ngIf="!panelNameEditActivated; else panelNameEditActions">
              <div class="general-container-padding">
                <button
                  type="button"
                  (click)="togglePanelNameEdit()"
                  class="btn custom-side-panel-settings-button">
                  <svg-icon
                    src="assets/icons/sat-settings.svg"
                    class="d-flex flex-wrap"
                    [svgStyle]="{ 'height.px': 20, 'width.px': 20 }">
                  </svg-icon>
                </button>
              </div>
            </div>

            <ng-template #panelNameEditActions>
              <div class="general-container-padding">
                <button
                  type="button"
                  (click)="discardPanelNameChange()"
                  class="btn custom-side-panel-settings-button">
                  <svg-icon
                    src="assets/icons/close.svg"
                    class="d-flex flex-wrap">
                  </svg-icon>
                </button>
              </div>

              <div class="general-container-padding">
                <button
                  type="button"
                  (click)="savePanelNameChange()"
                  class="btn custom-side-panel-settings-button">
                  <svg-icon
                    src="assets/icons/check.svg"
                    class="d-flex flex-wrap">
                  </svg-icon>
                </button>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
      
      <div class="general-container-body ">
        <div class="row">

          <div class="col-6 general-container-padding breaking-column">
            <!-- SIDE PANEL FLEET FILTER MODAL BODY -->
            <div
              class="general-container-box"
              aria-labelledby="sidePanelFleetFilter"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1">
              <!-- ROW 1: FLEET FILTER -->
              <div class="row">
                <!-- TODO: add[showCheckboxes]="true" -->
                
                <app-fleet-tree [viewDisableds]=false
                [fleets]=fleets
                            [(selection)]="fleetsGroupsAndVehiclesSelected"
                            [selectableEntities]=false
                            [cascadeSelection]=true
                ></app-fleet-tree>
              </div>
            </div>
          </div>

          <br>

          <div class="col-6 general-container-padding breaking-column">
            <!-- ROW 2: SETTINGS -->
            <div class="general-container-box">
              <div class="row">
                <app-sensor-chart
                  [fleets]=fleets
                  [(settings)]=_selectedSettings.sensorChartSettings>
                </app-sensor-chart>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  
  <div [hidden]="true" class="modal-dialog modal-lg modal-dialog-centered">
    <!-- SIDE PANEL SETTINGS MODAL CONTENT -->
    <div class="modal-content">
      <!-- SIDE PANEL SETTINGS MODAL BODY -->
      <div class="modal-body">
          <app-sensor-chart></app-sensor-chart>
      </div>
    </div>
  </div>

</p-dialog>
    <!-- SIDE PANEL EDIT NAME MODAL -->
<div class="modal fade side-panel-edit-name-modal" id="sidePanelEditName" aria-hidden="true" aria-labelledby="sidePanelEditName"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">

    <!-- SIDE PANEL EDIT NAME MODAL CONTENT -->
    <div class="modal-content">

      <!-- SIDE PANEL EDIT NAME MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col">
              <span class="modal-title custom-title" i18n>{{'real-time.editNameModal.title' | translate}}</span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button type="button"
                        class="btn-close m-0 p-0 custom-modal-close-button"
                        data-bs-target="#sidePanelSettings"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- SIDE PANEL EDIT NAME MODAL BODY -->
      <div class="modal-body">
        <div class="container-fluid">

          <!-- ROW 1: PANEL NAME -->
          <div class="row mt-3 mb-3">
            <div class="d-flex align-items-center justify-content-center save-panel-name-container">
              <label for="side-panel-settings-name" class="form-label mb-0 me-2 custom-label" i18n>{{'general.name' | translate}}:</label>
              <input type="text" id="side-panel-settings-name" name="side-panel-settings-name" class="form-control custom-input h-45"
                     [(ngModel)]=_selectedSettings.name>
            </div>
          </div>

        </div>
      </div>

      <!-- SIDE PANEL EDIT NAME MODAL FOOTER -->
      <div class="modal-footer">
        <div class="d-flex align-items-center justify-content-center w-100 gap-3">

          <button class="btn custom-button"
                  (click)=createNew()
                  data-bs-target="#sidePanelSettings"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  i18n>{{'general.saveAsNew' | translate}}
          </button>
        </div>
      </div>

    </div>

  </div>
</div>