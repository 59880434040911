import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { VehicleStatusService } from 'app/modules/vehicle-inventory/services/vehiclestatus.service';

@Component({
	selector: 'app-vehicle-status',
	templateUrl: './vehicle-status.component.html',
	styleUrls: ['./vehicle-status.component.css'],
	providers: [VehicleStatusService],
})
export class VehicleStatusComponent extends EntityFormComponent<Rest.VehicleStatus> implements OnInit {
	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;

	active: boolean;

	/*Permissions */
	disableSave: boolean = false;
	disabeChangeStatus: boolean;
	errorMessages: string[] = [];

	constructor(
		private vehicleStatusService: VehicleStatusService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService
	) {
		super(vehicleStatusService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof DelegationFormComponent
	 */
	beforeShow() {
		/*Permissions */
		if (this.isNew) {
			this.entity.color = '#ff0000';
			this.active = true;
			this.entity.disabled = false;
			this.title = this.translateService.instant('tableManagement.vehicleStatus.form.createVehicleStatus');
		} else {
			this.active = !this.entity.disabled;
			this.title = this.translateService.instant('tableManagement.vehicleStatus.form.updateVehicleStatus');
		}
	}

	public saveChanges() {
		this.disableSave = true;
		if (!this.validateStatusForm()) {
			this.disableSave = false;
			return;
		}

		if (this.isNew) {
			this.vehicleStatusService.create(this.entity).then((p) => {
				this.disableSave = false;
				this.display = false;
				this.return.emit(p);
			});
		} else {
			this.vehicleStatusService.update(this.entity).then((p) => {
				this.disableSave = false;
				this.display = false;
				this.return.emit(p);
			});
		}

	}

	public onChangeStatus(status: boolean) {
		this.active = !this.entity.disabled;
		this.disableSave = true;
		//this.changeStatus(!this.entity.disabled);
		const that = this;
		this.vehicleStatusService
			.changeStatus([this.entity.id], {status})
			.then(
				function (res) {
					// console.log("DONE!");
					 that.display = false;
					 that.disableSave = false;
					 that.return.emit(true);
				},
				(err) => {
					 console.log(err);
					 that.handleError(err, that);
					 that.return.emit(false);
				}
		  )
		  .catch((e) => {
				this.handleError(e, that);
		  });

	}

	public validateStatusForm(): Boolean {
		// console.log("[VEHICLE-FORM] validateVehicleForm *** ");
		this.errorMessages = [];

		if (!this.entity.name || this.entity.name === '' || !this.entity.color) {
			this.errorMessages.push(this.translateService.instant('error.required-field.generic'));
			this.notificationsService.add(
				Severity.error,
				this.translateService.instant(this.isNew ? 'general.createError' : 'general.updateError') + "!",
				this.translateService.instant('error.required-field.generic')
			);
			window.scroll({ top: 0, behavior: 'smooth' });
		}
		return this.errorMessages.length === 0;
	}
}
