import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { DeviceService } from '../../../rest/device.service';
import { Rest } from '../../../rest/rest_client';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService, Severity, } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { EntityListComponent } from '../../entity-list/entity-list.component';
import { DeviceFormComponent } from '../device-form/device-form.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { LoadingService } from 'app/services/loading-service/loading.service';

@Component({
    selector: 'app-devices-list',
    templateUrl: './devices-list.component.html',
    styleUrls: ['./devices-list.component.css'],
    providers: [DeviceService, ConfirmationService, UserTimePipe],
})
export class DevicesListComponent extends EntityListComponent<Rest.Device> {
    @ViewChild(DeviceFormComponent, { static: true })
    deviceForm: DeviceFormComponent;

    deviceService: DeviceService;
    EnabledFilter: SelectItem[];
    StatusFilter: SelectItem[];

    constructor(
        deviceService: DeviceService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        i18n: I18nService,
        authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            deviceService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.deviceService = deviceService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._('Devices');
        this.form = this.deviceForm;
        this.fetchEntityBeforeShowUpdateForm = true;

        this.hasEntityFilters = true;

        this.route.paramMap.subscribe((params: ParamMap) => {
            // (+) before `params.get()` turns the string into a number
            let id = +params.get('id');
            if (id) {
                this.service.find('' + id).then((e: Rest.Device) => {
                    /**Permissions */
                    e['_$update'] = true;
                    e['_$changeStatus'] = true;
                    /** */
                    this.update(e);
                });
            }
        });
    }

    Status(status: Rest.DeviceStatus) {
        return this.i18n._(status);
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters['enabled'],
            this.i18n
        );
        this.StatusFilter = RestExt.getEnumItems(
            RestExt.DeviceStatusEnum,
            this.i18n
        );

        this.paginationResult.entities.forEach((d: any) => {
            d._$status_str = this.Status(d.status);

            /**Permissions */
            d._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector('changeStatus', 'Device'),
                d.id
            );
            d._$update = this.hasPermission(
                this.permissionsEntityTypeSelector('update', 'Device'),
                d.id
            );
            /** */

            //For each device, initialize the associated device config with a dummy object to avoid null exceptions in device-config-form
            d.parameters = RestExt.emptyVT10Params();
        });
        /**Permissions */
        this.checkSelectAll();
        /** */
    }

    async handleRowExpand(event) {
        //Before showing device-config form, load the device config from server
        const device = await this.deviceService.find(event.data.id);
        event.data.parameters = device.configuration.parameters;
    }

    add() {
        /* We load  VT10 because its set by default on the form */
        //let o = <Rest.Device>{deviceType:<Rest.DeviceType>{id: null}};
        const o = { deviceType: { id: null } as Rest.DeviceType } as Rest.Device;
        /* RICARD */
        //o.configuration = <Rest.DeviceConfiguration>{deviceType: null};
        o.configuration = { deviceType: null } as Rest.DeviceConfiguration;
        //o.configuration.parameters = RestExt.emptyVT10Params();
        this.create(o);
    }

    refreshDeviceTypeList(event) {
        this.selectedEntities = []; //last change to init selected entities and not remove already remove entities
        this.loadFilters = true; //If necessary, reload the entities filters

        this.refresh();
        if (event) {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(':)'),
                this.i18n._('Entities updated')
            );
        } else {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(':)'),
                this.i18n._('Entity deleted')
            );
        }
    }
}
