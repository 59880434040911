import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-digital-input-port-settings-gl320m',
  templateUrl: './digital-input-port-settings-gl320m.component.html',
  styleUrls: ['./digital-input-port-settings-gl320m.component.css']
})
export class DigitalInputPortSettingsGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Disable the digital input.", value: "DISABLE" },
    { label: "The device will report the message +RESP:GTDIS if the status of the input changes", value: "REPORT_GTDIS_ON_CHANGES" },
    { label: "If the status of the input is changed to 0, the device will disable the sleep mode", value: "CHANGES_SLEEP_MODE" },
    { label: "If the status of the input is changed to 0, the SOS event will be triggered", value: "SOS_EVENT" },
 ];
  @Input() disParameters: Rest.DigitalInputPortSettingsGL320M;
  


  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.disParameters);
    }
  }
}