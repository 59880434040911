<!-- MORE FILTERS PANEL-->
<p-overlayPanel 
 (mouseleave)="hideOverlayPanel()" #op  [style]="{'max-width': '80vw'}">
  <div class="container" class='custom-table' style="display: flex; flex-wrap: wrap; align-items: flex-end;">
    <ng-container *ngFor="let extraFilter of extraFilters">
      <div class="extra-filter">
        <label id='extraFilterTitle' for='extraFilterTitle'
          class='form-label mb-0 me-3 custom-label logistics-label mt-2'>
          {{ extraFilter.header }}
        </label>
        <p-columnFilter class="mt-1 custom-table" (input)="onExtraFilterChange($event, extraFilter)" type='text'
          [field]='extraFilter.field' placeholder="{{ 'general.search' | translate }}">
        </p-columnFilter>
      </div>
    </ng-container>
    <p-button (click)="filterTable()" icon="pi pi-check" label="{{'general.filters.apply' | translate }}"
      class="mt-2"></p-button>
  </div>
</p-overlayPanel>
<div class="text-end">
  <p-button (click)="op.toggle($event)" icon="text-orange-500 pi pi-filter" label="{{ 'promotedRoutes.moreFilters' | translate }}"
    style="color: white!important"></p-button>
</div>