import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
   selector: 'app-geo-fence-configuration-gmt200',
   templateUrl: './geo-fence-configuration-gmt200.component.html',
   styleUrls: ['./geo-fence-configuration-gmt200.component.css'],
})
export class GeoFenceConfigurationGmt200Component extends ServerValidatedFormComponent implements OnInit, OnChanges {
   @Input() geoParameters: Rest.GeoFenceParametersGMT200;
   @Input() firmware: RestExt.FirmwareVersion;

   modeOptions: any = [
      { label: "Disable the zone Geo-Fence function", value: "DISABLE" },
      { label: "Entering the zone", value: "ENTER_ZONE" },
      { label: "Exiting the zone", value: "EXIT_ZONE" },
      { label: "Both entering and exiting", value: "EXIT_ENTER_ZONE" },
   ];

   modeStateOptions: any = [
      { label: "Report upon getting the state for the first time", value: "REPORT_STATE_FIRST_TIME" },
      { label: "Do not report until the state changes", value: "REPORT_UNTIL_STATE_CHANGES" },
   ];

   modeTriggerOptions: SelectItem[];

   modeReportTriggerOptions: any = [
      {label: "Disable the +RESP:GTGES report.", value:"DISABLE_GTGES"},
      {label: "Enable the +RESP:GTGES report.", value:"ENABLE_GTGES"}
   ];

   modeLastPointOptions: any = [
      {label: "Disable this parameter.", value:"DISABLE"},
      {label: "Enable this parameter.", value:"ENABLE"},
   ]

   constructor(protected i18n: I18nService) {
      super(i18n);
   }

   ngOnInit() {
      if(this.firmware > 5){
         this.modeTriggerOptions= [
            { label: "Disable auto trigger mode.", value:"DISABLE"},
            { label: "Automatically set Geo-Fence after the ignition is turned off.", value:"AUTO_GEOFENCE_AFTER_IGNITION_OFF"},
            { label: "Manually enable Geo-Fence after the ignition is turned off.", value:"MANUAL_GEOFENCE_AFTER_IGNITION_OFF"},
            { label: "Automatically set Geo-Fence after the ignition is turned off.", value: "AUTOMATICALLY_GEOFENCE_AFTER_IGNITION_OFF"}
         ];
      }else{
         this.modeTriggerOptions= [
            { label: "Disable auto trigger mode.", value:"DISABLE"},
            { label: "Automatically set Geo-Fence after the ignition is turned off.", value:"AUTO_GEOFENCE_AFTER_IGNITION_OFF"},
            { label: "Manually enable Geo-Fence after the ignition is turned off.", value:"MANUAL_GEOFENCE_AFTER_IGNITION_OFF"},
         ];
      }

   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['deviceParams']) {
         console.log('arrive deviceParams', this.geoParameters);
      }
   }

   trackModeSwitch = false;

   geoFenceModeChange(value, event) {
      console.log('value:', value);
      if (value == 0) {
         this.trackModeSwitch = false;
      } else {
         this.trackModeSwitch = true;
      }
   }
}
