import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

/* <--- PRIMENG MODULES ---> */
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SpinnerModule } from 'primeng/spinner';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ChartModule } from 'primeng/chart';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FileUploadModule } from 'primeng/fileupload';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';

/* <--- COMPONENTS ---> */
import { LoadingComponent } from './components/loading/loading.component';
import { TableAddButtonComponent } from '../../ui/table-add-button/table-add-button.component';
import { TableExcelButtonComponent } from '../../ui/table-excel-button/table-excel-button.component';
import {
    TableChangeStatusButtonComponent
} from '../../ui/table-change-status-button/table-change-status-button.component';
import { TableDeleteButtonComponent } from '../../ui/table-delete-button/table-delete-button.component';
import {
    TableSendMessageButtonComponent
} from '../../ui/table-send-message-button/table-send-message-button.component';
import { SendMessageDialogComponent } from '../../components/send-message-dialog/send-message-dialog.component';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { TableDownloadButtonComponent } from '../../ui/table-download-button/table-download-button.component';
import { TableExtraColumnFiltersComponent } from 'app/ui/table-extra-column-filters/table-extra-column-filters.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ImageExpandableComponent } from 'app/ui/image-expandable/image-expandable.component';

import { VehicleStateComponent } from 'app/components/fleet-edit-view/vehicle-state/vehicle-state.component';
import { VehicleStateButtonComponent } from '../../ui/vehicle-state-button/vehicle-state-button.component';
import { TableNavbarComponent } from 'app/navigation/table-navbar/table-navbar.component';
import { OldSharedModule } from 'app/components/shared/old-shared.module';
import { ChangeStatusSharedComponent } from './components/dialogs/change-status-dialog/change-status-shared.component';
import { PagedSearchDropdown } from 'app/components/paged-search-dropdown/paged-search-dropdown';
import { BillingAccountFormComponent } from 'app/components/clients/billing-account-form/billing-account-form.component';
import { TablePDFButtonComponent } from 'app/ui/table-pdf-button/table-pdf-button.component';
import { MaintenanceExecutionsListComponent } from 'app/components/maintenance-executions-list/maintenance-executions-list.component';
import { MaintenanceExecutionFormComponent } from 'app/components/maintenance-execution-form/maintenance-execution-form.component';
import { DocFormComponent } from '../vehicle-inventory/components/documents/doc-form/doc-form.component';
import { DocTableComponent } from '../vehicle-inventory/components/documents/doc-table/doc-table.component';
import { FormInput } from 'app/components/form-input/form-input.component';
import { DocDriverPointsComponent } from '../vehicle-inventory/components/documents/doc-driver-points/doc-driver-points.component';
import { DocNoteComponent } from '../vehicle-inventory/components/documents/doc-note/doc-note.component';
import { MaintenanceExecutionStatusColorPipe } from 'app/components/shared/maintenance-execution-status-color.pipe';
import { MaintenanceExecutionAlertComponent } from 'app/components/maintenance-execution-alert/maintenance-execution-alert/maintenance-execution-alert.component';
import { TableDuplicateButtonComponent } from 'app/ui/table-duplicate-button/table-duplicate-button.component';
import { WeekPickerComponent } from './components/week-picker/week-picker.component';

@NgModule({
    declarations: [
        LoadingComponent,
        TableAddButtonComponent,
        TableChangeStatusButtonComponent,
        TableDeleteButtonComponent,
        TableSendMessageButtonComponent,
        SendMessageDialogComponent,
        ConfirmDialogComponent,
        TableDownloadButtonComponent,
        TableExcelButtonComponent,
        TableExtraColumnFiltersComponent,
        ImageExpandableComponent,

        VehicleStateComponent,
        VehicleStateButtonComponent,
        TableNavbarComponent,
        PagedSearchDropdown,
        ChangeStatusSharedComponent,
        TableExcelButtonComponent,
        TablePDFButtonComponent,
        MaintenanceExecutionsListComponent,
        MaintenanceExecutionFormComponent,
        DocFormComponent,
        DocTableComponent,
        DocDriverPointsComponent,
        DocNoteComponent,
        FormInput,
        MaintenanceExecutionStatusColorPipe,
        MaintenanceExecutionAlertComponent,
        TableDuplicateButtonComponent,
        WeekPickerComponent
    ],

    imports: [
        CommonModule,
        FormsModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule,
        NgxChartsModule,
        BsDatepickerModule,

        /* <--- PRIMENG MODULES ---> */
        TableModule,
        DialogModule,
        CalendarModule,
        MessagesModule,
        MessageModule,
        ConfirmDialogModule,
        PanelModule,
        DropdownModule,
        InputTextModule,
        TooltipModule,
        InputNumberModule,
        InputTextareaModule,
        CheckboxModule,
        InputSwitchModule,
        SpinnerModule,
        TabViewModule,
        AccordionModule,
        ToggleButtonModule,
        ChartModule,
        ChipsModule,
        ColorPickerModule,
        FileUploadModule,
        ListboxModule,
        MenubarModule,
        MultiSelectModule,
        RadioButtonModule,
        RippleModule,
        ToastModule,
        TreeModule,
        OverlayPanelModule,
        OldSharedModule,
    ],

    exports: [
        CommonModule,
        FormsModule,
        AngularSvgIconModule,
        TranslateModule,
        NgxChartsModule,
        BsDatepickerModule,

        /* <--- PRIMENG MODULES ---> */
        TableModule,
        DialogModule,
        CalendarModule,
        MessagesModule,
        MessageModule,
        ConfirmDialogModule,
        PanelModule,
        DropdownModule,
        InputTextModule,
        TooltipModule,
        InputNumberModule,
        InputTextareaModule,
        CheckboxModule,
        InputSwitchModule,
        SpinnerModule,
        TabViewModule,
        AccordionModule,
        ToggleButtonModule,
        ChartModule,
        ChipsModule,
        ColorPickerModule,
        FileUploadModule,
        ListboxModule,
        MenubarModule,
        MultiSelectModule,
        RadioButtonModule,
        RippleModule,
        ToastModule,
        TreeModule,

        /* <--- COMPONENTS ---> */
        LoadingComponent,
        TableAddButtonComponent,
        TableChangeStatusButtonComponent,
        TableDeleteButtonComponent,
        TableSendMessageButtonComponent,
        SendMessageDialogComponent,
        ConfirmDialogComponent,
        TableDownloadButtonComponent,
        TableExcelButtonComponent,
        TableExtraColumnFiltersComponent,
        ImageExpandableComponent,

        VehicleStateButtonComponent,
        VehicleStateComponent,
        TableNavbarComponent,
        PagedSearchDropdown,
        ChangeStatusSharedComponent,
        TableExcelButtonComponent,
        TablePDFButtonComponent,
        MaintenanceExecutionsListComponent,
        MaintenanceExecutionFormComponent,
        DocFormComponent,
        DocTableComponent,
        DocDriverPointsComponent,
        DocNoteComponent,
        FormInput,
        MaintenanceExecutionStatusColorPipe,
        MaintenanceExecutionAlertComponent,
        TableDuplicateButtonComponent,
        WeekPickerComponent
    ]
})
export class SharedModule {
}
