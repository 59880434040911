<div class='flex-column justify-content-center align-items-center'>
	<div class='flex-column justify-content-center p-5'>

		<!-- PROMOTED-ROUTES PRIMENG DATATABLE	-->
		<p-table
				 id="table"
				 [value]='paginationResult.entities'
				 [lazy]='true'
				 (onLazyLoad)='loadData($event)'
				 [rows]='paginationRequest.pageSize'
				 [totalRecords]='paginationResult.filteredEntities'
				 [rowsPerPageOptions]='[10,20,30,40,50]'
				 [paginator]='true'
				 [rowHover]='true'
				 [showCurrentPageReport]='true'
				 [autoLayout]='true'
				 currentPageReportTemplate=
				 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
				 class='custom-table'
				 styleClass='p-datatable-striped'
		>

			<!-- TABLE TITLE DEFINITION -->
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
					{{ 'promotedRoutes.promotedRoutesTable' | translate }}
				</div>
			</ng-template>

			<!------ TABLE COLUMN DEFINITION ------------------------------------------------------------>
			<ng-template pTemplate='header'>
				<tr>

					<!-- SELECT -->
					<th class='checkbox-column'>
						<!-- EMPTY -->
					</th>

					<!-- NAME -->
					<th pSortableColumn='nameSpace'>
						<div class="d-flex align-items-center h-100">
							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.name' | translate }}
							</div>
							<!-- COLUMN SORT && FILTER ICONS -->
							<!-- <div class="d-flex align-items-center h-100"> -->
								<!-- COLUMN SORT -->
								<!-- <p-sortIcon field='nameSpace'></p-sortIcon> -->
								<!-- COLUMN FILTER -->
								<!-- <p-columnFilter field='nameSpace'
												type='text'
												placeholder="{{ 'general.search' | translate }}"
												display='menu'
												[showMatchModes]='false'
												[showOperator]='false'
												[showAddButton]='false'
								></p-columnFilter>
							</div>  -->
						</div>
					</th>

					<!-- START DATE -->
					<th pSortableColumn='startDate'>
						<div class="d-flex align-items-center h-100">
							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.startDate' | translate }}
							</div>
							<!-- COLUMN SORT ICON -->
							<!-- <div class="d-flex align-items-center h-100"> -->
								<!-- COLUMN SORT -->
								<!-- <p-sortIcon field='startDate'></p-sortIcon>
							</div> -->
						</div>
					</th>

					<!-- END DATE -->
					<th pSortableColumn='endDate'>
						<div class="d-flex align-items-center h-100">
							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.endDate' | translate }}
							</div>
							<!-- COLUMN SORT ICON -->
							<!-- <div class="d-flex align-items-center h-100"> -->
								<!-- COLUMN SORT -->
								<!-- <p-sortIcon field='endDate'></p-sortIcon>
							</div> -->
						</div>
					</th>

					<!-- PROVIDER NAME -->
					<th pSortableColumn='startPublicationDate'>
						<div class="d-flex align-items-center h-100">
							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'promotedRoutes.providerName' | translate }}
							</div>
							<!-- COLUMN SORT ICON -->
							<!-- <div class="d-flex align-items-center h-100"> -->
								<!-- COLUMN SORT -->
								<!-- <p-sortIcon field='startPublicationDate'></p-sortIcon>
							</div> -->
						</div>
					</th>

					<!-- STATE -->
					<th>
						<div class="d-flex align-items-center h-100">
							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.status' | translate }}
							</div>
						</div>
					</th>

					<!-- NOTIFICACIONS -->
					<!-- <th>
						<div class="d-flex align-items-center h-100">
							!-- COLUMN TITLE --
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'promotedRoutes.notificationTitle' | translate }}
							</div>
						</div>
					</th> -->


				</tr>
			</ng-template>

			<!------- TABLE BODY ------------------------------------------------------------------------>
			<ng-template pTemplate='body' let-space>
				<tr class='p-selectable-row custom-rows' (dblclick)='editSpace(space)'>

					<!-- DELETE -->
					<td>
						<button type='button'
								class='btn custom-table-remove-button'
								(click)='deleteSpace(space)'
						>
							<svg-icon src='assets/icons/trash.svg'
									  class='d-flex flex-wrap custom-table-remove-button-icon'
									  [svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
							></svg-icon>
						</button>
					</td>

					<!-- NAME -->
					<td>
						{{ space.campaign.name }}
					</td>

					<!-- START DATE -->
					<td>
						{{ space.campaign.startDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- END DATE -->
					<td>
						{{ space.campaign.endDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- PROVIDER NAME -->
					<td>
						{{ space.campaign.provider.name }}
					</td>

					<!-- STATUS -->
					<td>
						<div *ngIf='space.campaign.active'>
							{{ 'promotedRoutes.enabled' | translate }}
						</div>
						<div *ngIf='!space.campaign.active'>
							{{ 'promotedRoutes.disabled' | translate }}
						</div>
					</td>

					<!-- Notifcations -->
					<!-- <td>
						<div *ngIf="sendNotifControl === null" [style]="'text-align: center; width: 100%;'">
							<button class="custom-button" (click)='scheduleSendNotif(space)'
							[disabled]='!space.campaign.active'>
								{{ 'promotedRoutes.notification.send' | translate }}
							</button>
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'PENDING'">
							{{ 'promotedRoutes.notification.pending' | translate }}
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'STARTED'">
							{{ 'promotedRoutes.notification.started' | translate }}
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'FINISHED'">
							{{ 'promotedRoutes.notification.finished' | translate }}
						</div>
					</td> -->

				</tr>
			</ng-template>

			<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
							{{ 'table.tableEmptyMessage' | translate }}!
						</div>
					</td>
				</tr>
			</ng-template>

			<!------ FOOTER ----------------------------------------------------------------------------->
			<ng-template pTemplate="footer">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex flex-row align-items-center p-3 gap-3">

							<!-- ADD RACING EVENT BUTTON -->
							<app-table-add-button [text]=" 'promotedRoutes.addSpace' | translate "
												  (btnClick)="createSpace()"
							></app-table-add-button>

						</div>
					</td>
				</tr>
			</ng-template>

		</p-table>

	</div>
</div>

<!-- DELETE EVENT DIALOG -->
<p-confirmDialog #deleteEventDialog
				 [header]=" 'control-panel.delete-dialog.title' | translate "
				 class='custom-dialog'
				 icon='pi pi-exclamation-triangle'
>
	<ng-template pTemplate="footer">

		<!-- REJECT BUTTON -->
		<button (click)="deleteEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>

		<!-- ACCEPT BUTTON -->
		<button (click)="deleteEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>

	</ng-template>
</p-confirmDialog>

<!-- SPACE FORM -->
<app-promoted-routes-form></app-promoted-routes-form>
<!-- <app-promoted-routes-form (spaceSelected)="showSpaceFormComponent($event)"></app-promoted-routes-form> -->


