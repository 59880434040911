import { Injectable } from '@angular/core';
import { EntityService } from '../../../rest/entity.service';
import { Rest } from '../../../rest/rest_client';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import RestResponse = Rest.RestResponse;
import Page = Rest.Page;
import Request = Rest.Request;

@Injectable({
    providedIn: 'root'
})
export class RequestService extends EntityService<any> {
    private _vehicleId: number | null;

    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient
    ) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);

        this._vehicleId = null;
    }

    /**
     * getPage()
     * */
    public getPage(arg0: Rest.ListPaginationRequest): RestResponse<Page<Request>> {

        if (this.vehicleId) {
            let queryParams = {
                vehicleId: this.vehicleId
            };
            return this.client.Request_getPage(arg0, queryParams);
        } else {
            return this.client.Request_getPage(arg0);
        }

    }

    /**
     * sendRequest()
     * */
    public sendRequest(deviceIdList: string[], message: string): Promise<any> {

        return this.http.put(
            environment.webApiBaseUrl + 'api/sat/sendConfiguration',
            {
                'devices': deviceIdList,
                'message': message
            }
        ).toPromise();

    }

    /**
     * cancelRequest
     * */
    public cancelRequest(requestId: number): Promise<any> {
        return this.http.get(
            environment.webApiBaseUrl + 'api/device/requests/cancel/' + requestId
        ).toPromise();
    }

    /* <--- GETTERS ---> */

    public get vehicleId(): number | null {
        return this._vehicleId;
    }

    /* <--- SETTERS ---> */

    public set vehicleId(vehicleId: number) {
        this._vehicleId = vehicleId;
    }
}
