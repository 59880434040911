import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class MaintenanceService  extends EntityService<Rest.Maintenance>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.Maintenance): Rest.RestResponse<Rest.Maintenance> {
return this.client.Maintenance_create(arg0);
}

update(arg0: Rest.Maintenance): Rest.RestResponse<Rest.Maintenance> {
return this.client.Maintenance_update(arg0);
}

getExecutionsOfVehicle(maintenancevehicle: string, queryParams?: { maintenancevehicle?: string; }): Rest.RestResponse<Rest.MaintenanceExecution[]> {
return this.client.Maintenance_getExecutionsOfVehicle(maintenancevehicle, queryParams);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Maintenance>> {
return this.client.Maintenance_getPage(arg0);
}

getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Maintenance>> {
return this.client.Maintenance_getPageAndFilters(arg0);
}

changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.Maintenance_changeStatus(arg0, queryParams);
}

getList(arg0) : Rest.RestResponse<Rest.Maintenance[]> {
	return this.client.Maintenance_getList(arg0);
}

getListByPlan(arg0) : Rest.RestResponse<Rest.Maintenance[]> {
	return this.client.Maintenance_getListByPlan(arg0);
}

}
