<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">MILEAGE CONFIGURATION
          (MILEAGE)</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="mileage.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Mileage">
      </form-input>
    </div>
    <div class="col">
      <div class="ui-inputgroup">
        <p-spinner size="5" [(ngModel)]="mileage.mileage" [min]="0" [max]="999999"></p-spinner>
        <span class="ui-inputgroup-addon">0~999999</span>
        <span class="ui-inputgroup-addon">km</span>
      </div>
    </div>
  </div>
</p-accordionTab>