<!-- INSURANCE TYPE PRIMENG TABLE -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="code"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.real-state.realStatesTable' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION-->
    <ng-template pTemplate="header">
        <tr>

            <!-- society -->
            <th pSortableColumn='billingAccount.code'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.society' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='billingAccount'></p-sortIcon>
                        <p-columnFilter field='billingAccount' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- contractNumber -->
            <th pSortableColumn='name'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.contractNumber' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='name'></p-sortIcon>
                        <p-columnFilter field='name' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- contractDenomination -->
            <th pSortableColumn='name'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.contractDenomination' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='name'></p-sortIcon>
                        <p-columnFilter field='name' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- currency -->
            <th>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.currency' | translate }}
                    </div>
                </div>
            </th>

            <!-- contractStart -->
            <th pSortableColumn='financing.startDate'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.contractStart' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='financingStartDate'></p-sortIcon>
                        <p-columnFilter field='financingStartDate' type='date' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- contractEnd -->
            <th pSortableColumn='financing.endDate'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.contractEnd' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='financingEndDate'></p-sortIcon>
                        <p-columnFilter field='financingEndDate' type='date' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- bpCode -->
            <th pSortableColumn='codigoBP'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.bpCode' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='codigoBP'></p-sortIcon>
                        <p-columnFilter field='codigoBP' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- amount -->
            <th pSortableColumn='financing.monthlyFee'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.amount' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='importValue'></p-sortIcon>
                        <p-columnFilter field='importValue' type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- wayToPay -->
            <th pSortableColumn='financing.wayToPay.name'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.wayToPay' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='wayToPay'></p-sortIcon>
                        <p-columnFilter field='wayToPay' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- ceco -->
            <th pSortableColumn='ceco.code'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.ceco' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='ceco'></p-sortIcon>
                        <p-columnFilter field='ceco' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- interestType -->
            <th pSortableColumn='financing.interestRate'>
                <div class='d-flex align-items-center h-100'>
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.real-state.interestType' | translate }}
                    </div>
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='interest'></p-sortIcon>
                        <p-columnFilter field='interest' type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>
                </div>
            </th>
        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION-->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-realState>
        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(realState)">

            <td>{{ realState.billingAccount.code }}</td>
            <td>{{ realState.name }}</td>
            <td>{{ realState.name }}</td>
            <td>EUR</td>
            <!-- <td>{{ realState.currency }}</td> -->
            <td>{{ realState.financing.startDate | date:'dd/MM/yyyy' }}</td>
            <td>{{ realState.financing.endDate | date:'dd/MM/yyyy' }}</td>

            <td>{{ realState.financing.provider?.providerParameters?.codeBp ?? '' }}
            </td>

            <td>{{ realState.financing.monthlyFee }}</td>

            <td>{{ realState.financing.wayToPay.name.substring(0,1).toUpperCase() }}</td>

            <td>{{ realState.ceco.code }}</td>

            <td>{{ realState.financing.interestRate }}</td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="11" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER-->
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="11" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">

                    <div class="d-flex flex-row " style="margin-left: auto;">
                        <!-- TABLE EXCEL BUTTON -->
                        <app-table-excel-button class="buttonTableExcel"
                            (btnClick)="exportExcel()"></app-table-excel-button>
                        <app-table-pdf-button pTooltip="{{'reports.exportPDF' | translate }}" tooltipPosition="top"
                            class="buttonTableExcel" (btnClick)="exportPdf()"></app-table-pdf-button>
                    </div>
                    <!--<app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button> -->
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->

</p-table>