import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { Rest } from 'app/rest/rest_client';
import { ConfirmationService } from 'primeng/api';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { DriverLicenseService } from 'app/modules/vehicle-inventory/services/driverlicense.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { IconService } from 'app/rest/icon.service';
import { UserService } from 'app/rest/user.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
import { CecoService } from 'app/rest/ceco.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { DivisionService } from 'app/rest/division.service';
import { SubdivisionService } from 'app/rest/subdivision.service';
@Component({
    selector: 'app-driver-info-card',
    templateUrl: './driver-info-card.component.html',
    styleUrls: ['./driver-info-card.component.css'],
    providers: [DriverService, DriverLicenseService, DocumentationService, IconService, UserService, BillingaccountService, CecoService, DivisionService, SubdivisionService]
})



export class DriverInfoCardComponent extends EntityFormComponent<Rest.InvDriver> implements OnInit {
    title: String;
    imageUrl: string = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEDavqO-ac8oTE1zFxpIoW_qeufxyvB8pyzKzjBhr0TLcRmD3JO0KoxBe32Ygv48J0DJA&usqp=CAU';

    dates: { [key: string]: Date }
    expeditionDate: Date;
    expirationDate: Date;
    type = "driver";
    personalEmail: string;
    clientId: number = this.authenticationService.user.client.id;

    userImageFile: any;
    userEncodedImage: string;
    profileImage: Rest.Icon;
    entityEnumImageProfile: Rest.IconEntityAssociationEnum = "PROFILE_FLEET";

    vehicles: Rest.Vehicle[] = [];

    billingAccountList: Rest.BillingAccount[] = [];
    cecoList: Rest.Ceco[] = [];
    divisionList: Rest.Division[] = [];
    subDivisionList: Rest.Subdivision[] = [];

    isBillingAccountLoaded: Boolean = false;
    selectedBillingAccount: Rest.BillingAccount;
    selectedDivision: Rest.Division;

    isCecoListLoaded: Boolean = false;
    isSubdivisionListLoaded: Boolean = false;

    // PERMISSIONS
    canEdit: boolean = false;

    constructor(
        protected driverService: DriverService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected driverLicenseService: DriverLicenseService,
        protected translate: TranslateService,
        private iconService: IconService,
        private userService: UserService,
        private cecoService: CecoService,
        private billingAccountService: BillingaccountService,
        private divisionService: DivisionService,
        private subDivisionService: SubdivisionService,
    ) {
        super(
            driverService, notificationsService, i18n, authenticationService)
    }

    ngOnInit() {
        this.clearLicenseDates();

        this.setPermissions();
    }

    setPermissions() {
        const user = this.authenticationService.user;
        const rolesForForm = ['INV_DRIVER_CREATE', 'INV_DRIVER_UPDATE'];

        this.canEdit = this.authenticationService.isOneRoleGranted(rolesForForm, user);
    }

    protected beforeShow(): void {
        console.log('+++ driver...');
        console.log(this.entity);
        this.userEncodedImage = '../../../../assets/images/UI_Elements/HD_transparent_picture.png'; //Reset the image to an empty png image from assets.

        if (this.isNew) {
            this.title = this.translate.instant("drivers.form.createDriver");
            const userTmp = { id: 0 };
            this.initNewUser(0)
            // const userTmp2 = this.getNewUser(0);
            //  this.entity.user = userTmp2 as Rest.User;
        } else {
            this.title = this.translate.instant("drivers.form.editDriver");

            // We are setting the value of the path of the image, depending if it's already set or not

            if (this.entity.user.profileImage != null) {
                this.userEncodedImage = this.entity.user.profileImage.imageEncoded;
            }

            for (var device in this.entity.user.devices) {
                this.vehicles.push(this.entity.user.devices[device].vehicle);
            }

            for (let index = 0; index < 10; index++) {
                var newVehilce: Rest.Vehicle;
                newVehilce = { "name": "vehicle" + index } as Rest.Vehicle;
                this.vehicles.push(newVehilce);
            }

        }
        //init combos variables
        this.selectedBillingAccount = null;
        this.selectedDivision = null;
        this.isCecoListLoaded = false;
        this.isSubdivisionListLoaded = false;

        if (this.entity.ceco) {
            this.selectedBillingAccount = this.entity.ceco?.billingAccount;
        }
        if (this.entity.subdivision) {
            this.selectedDivision = this.entity.subdivision.division;
        }
        this.getBillingAccounts(this.clientId);
        this.getDivisionsList(this.clientId);

        this.personalEmail = this.authenticationService.user.email;

    }

    initNewUser(userId) {
        //this.entity.user = this.entity.user ? this.entity.user : <Rest.User>{};
        if (this.isNew) {
            this.entity.user = <Rest.User>{};
            this.entity.id = 0;
            this.entity.user.id = 0;
            this.entity.user.timeZone = "Europe/Madrid";
            this.entity.user.password = "$2a$10$hWkHityYvBYzPpnx5b9CYOfprMGJ3qlU3Q7JNGRU8paglUZ6iPsYK";
            this.entity.user.language = "ES";
            this.entity.user.metric = "INTERNATIONAL_SYSTEM_OF_UNIT";
            this.entity.user.birthDate = new Date();
            this.entity.importERP = false;
        }
        /* return {
             id: userId,
             name: null,
             enabled: true,
             timeZone: "Europe/Madrid",
             isAdmin: false,
             surname1: null,
             surname2: null,
             userName: null,
             password: "$2a$10$hWkHityYvBYzPpnx5b9CYOfprMGJ3qlU3Q7JNGRU8paglUZ6iPsYK",
             email: null,
             phoneNumber: null,
             language: "ES",
             metric: "INTERNATIONAL_SYSTEM_OF_UNIT",
             postalAddress: null,
              birthDate: null,
             address: null,
             population: null,
             province: null
              
             parent: User;
         isAdmin: boolean;
         surname1: string;
         surname2: string;
         userName: string;
         password: string;
         email: string;
         phoneNumber: string;
         language: Language;
         accountExpiry: Date;
         metric: Metric;
         canManageUsers: boolean;
         canManageFleets: boolean;
         canManageMaintenancePlans: boolean;
         canManageDevices: boolean;
         canManageClients: boolean;
         canManageZoneGroups: boolean;
         canManagePOICategories: boolean;
         canManageNotificationGroups: boolean;
         canManageRules: boolean;
         defaultDashboard: string;
         canSeeUTERondesDashboard: boolean;
         canSeeReports: boolean;
         canSeeRTDashboard: boolean;
         canSeeSAT: boolean;
         canSeeRaceEvents: boolean;
         canSeeTax: boolean;
         canCreatUTERondesPOCVisits: boolean;
         canAccesBackend: boolean;
         children: User[];
         roles: Role[];
         devices: Device[];
         fleets: Fleet[];
         maintenancePlans: MaintenancePlan[];
         noticeGroups: NoticeGroup[];
         poiCategories: POICategory[];
         profiles: Profile[];
         zoneGroups: ZoneGroup[];
         rolesProfiles: RoleProfilePair[];
         profileImage: Icon;
         canSeeInventoryVehicles: boolean;
         canSeeDrivers: boolean;
         canSeeMaintenance: boolean;
         canSeeTableManagement: boolean;
         canSeeDashboards: boolean;
         userCode: String;
         activeDirectory: String;
         postalAddress: String;
         city: String;
         state: String;
         address: String;
         population: String;
         province: String;
         canSeeInventoryReport: boolean;
         cp: string;
         birthDate: Date;
 
             */
    }

    imageChange(event): void {
        let reader = new FileReader();
        this.userImageFile = event.target.files[0];
        reader.onload = (event: any) => {
            this.userEncodedImage = event.target.result;
        };

        const formData = new FormData();
        formData.append('myfile[]', this.userImageFile);
        formData.append('entity', this.entityEnumImageProfile);
        formData.append('userId', this.entity.user.id.toString());

        this.iconService.uploadIcon(formData).then((res) => {
            this.notificationsService.add(Severity.success, this.translate.instant('userProfile.settingImageTitle'), this.translate.instant('userProfile.settingsImageUpdatedMessage'));
            this.profileImage = res;
            this.userEncodedImage = res.imageEncoded;
        }).catch(() => {
            this.notificationsService.add(Severity.error, this.translate.instant('userProfile.settingsFailedImageTitle'), this.translate.instant('userProfile.settingsFailedImageMessage'));
        });
    }

    loadDriverLicense() {
        this.clearLicenseDates()
        var licenses = this.entity.licenseData;
        this.expirationDate = licenses.length > 0 ? licenses[0].expirationDate : null;
        for (var license of licenses) {
            this.expeditionDate = this.expeditionDate > license.expeditionDate ? this.expeditionDate : license.expeditionDate;
            if (license.licenseType == 'A') {
                this.dates["A"] = license.expeditionDate;
                //this.expeditionDateA = license.expeditionDate;
            } else if (license.licenseType == 'A2') {
                this.dates["A2"] = license.expeditionDate;
                //this.expeditionDateA2 = license.expeditionDate;
            } else if (license.licenseType == 'B') {
                this.dates["B"] = license.expeditionDate;
                //this.expeditionDateB = license.expeditionDate;
            } else if (license.licenseType == 'C') {
                this.dates["C"] = license.expeditionDate;
                //this.expeditionDateC = license.expeditionDate;
            } else if (license.licenseType == 'C1') {
                this.dates["C1"] = license.expeditionDate;
                //this.expeditionDateC1 = license.expeditionDate;
            } else if (license.licenseType == 'D') {
                this.dates["D"] = license.expeditionDate;
                //this.expeditionDateD = license.expeditionDate;
            } else if (license.licenseType == 'D1') {
                this.dates["D1"] = license.expeditionDate;
                //this.expeditionDateD1 = license.expeditionDate;
            } else if (license.licenseType == 'CE') {
                this.dates["CE"] = license.expeditionDate;
                //this.expeditionDateCE = license.expeditionDate;
            }
        }
    }

    saveLicenseData() {
        let licenseData = this.entity.licenseData;
        for (var type of Object.keys(this.dates)) {
            let license = licenseData.find(l => l.licenseType == type);

            if (license != null) {
                license.expeditionDate = this.dates[type];
                license.expirationDate = this.expirationDate;
                license.driver = { "id": this.entity.id } as Rest.InvDriver;
            } else {
                if (this.dates[type] != null) {
                    licenseData.push({
                        "id": 0,
                        "driver": { "id": this.entity.id } as Rest.InvDriver,
                        "licenseType": type as Rest.InvLicenseType,
                        "expeditionDate": this.dates[type],
                        "expirationDate": this.expirationDate,
                    })
                }
            }
        }

        this.driverLicenseService.upload(licenseData).then((response) => {
            this.display = false;
            this.return.emit(response);
        }).catch((err) => {
            console.log(err);
            this.handleError(err, this);
        })
    }

    changeLicenseDate() {
        this.expeditionDate = null;
        for (var date of Object.values(this.dates)) {
            if (date != null) {
                this.expeditionDate = this.expeditionDate > date ? this.expeditionDate : date;
            }
        }
    }

    clearLicenseDates() {
        this.expeditionDate = null;
        this.dates = {
            "A": null,
            "A2": null,
            "B": null,
            "C": null,
            "C1": null,
            "D": null,
            "D1": null,
            "CE": null
        }
    }

    public getBillingAccounts(clientId: number) {
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.billingAccountService.getList(clientId)
                .then(
                    function (response) {
                        if (response === null || response === undefined) {
                            that.notificationsService.add(
                                Severity.warn,
                                that.translate.instant('general.warn') + "!",
                                that.translate.instant('control-panel.vehicles.billingAccount') + ": " +
                                that.translate.instant('table.tableEmptyMessage'));
                        } else {
                            that.billingAccountList = response.sort((a, b) => a.name > b.name ? 1 : -1);
                            if (that.selectedBillingAccount) {
                                that.getCecoList(that.selectedBillingAccount.id);
                            }
                        }
                        that.isBillingAccountLoaded = true;
                    },
                    /* failure */
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    },
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }


    public getCecoList(billingAccountId: number) {
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.cecoService
                .getList(billingAccountId)
                .then(
                    function (response) {
                        that.cecoList = response;
                        that.isCecoListLoaded = true;
                    },
                    /* failure */
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    },
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }

    public getDivisionsList(clientId: number) {
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.divisionService.getList(clientId)
                .then(
                    function (response) {
                        if (response === null || response === undefined) {
                            that.notificationsService.add(
                                Severity.warn,
                                that.translate.instant('general.warn') + "!",
                                that.translate.instant('control-panel.vehicles.billingAccount') + ": " +
                                that.translate.instant('table.tableEmptyMessage'));
                        } else {
                            that.divisionList = response.sort((a, b) => a.name > b.name ? 1 : -1);
                            if (that.selectedDivision) {
                                that.getSubdivisionsList(that.selectedDivision.id);
                            }
                        }
                        that.isBillingAccountLoaded = true;
                    },
                    /* failure */
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    },
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }


    public getSubdivisionsList(divisionId: number) {
        let subDivisionRequest = {
            sortBy: null,
            sortAsc: true,
            pageSize: 10,
            filters: {
                divisionId: [divisionId ?? '']
            },
            loadPermissions: false
        } as unknown as Rest.ListPaginationRequest;
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.subDivisionService.getPage(subDivisionRequest)
                .then(
                    function (response) {
                        that.subDivisionList = response.entities;
                        that.isSubdivisionListLoaded = true;
                    },
                    /* failure */
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    },
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }


    public onChangeBillingAccount(event) {
        //  this.entity.ceco = event.value;
        //this.selectedCeco = null;
        //this.selectedCeco = null;
        this.entity.ceco = null;

        this.isCecoListLoaded = false;
        this.selectedBillingAccount = event.value
        this.isCecoListLoaded = false;
        this.getCecoList(this.selectedBillingAccount.id);
        //this.errorMessages = [];
    }

    public onChangeCeco(event) {
        //  this.entity.ceco = event.value;
        //this.selectedCeco = null;
        //this.selectedCeco = null;

        this.entity.ceco = event.value

        //this.errorMessages = [];
    }

    public onChangeDivision(event) {
        //  this.entity.ceco = event.value;
        //this.selectedCeco = null;
        //this.selectedCeco = null;
        this.entity.subdivision = null;
        this.selectedDivision = event.value
        this.isSubdivisionListLoaded = false;
        this.getSubdivisionsList(this.selectedDivision.id);
        //this.errorMessages = [];
    }
    public onChangeSubdivision(event) {
        //  this.entity.ceco = event.value;
        //this.selectedCeco = null;
        //this.selectedCeco = null;

        this.entity.subdivision = event.value

        //this.errorMessages = [];
    }
    beforeSave() {
        if (!this.entity.importERP) {
            this.entity.user.userName = this.entity.nif.toString() + this.entity.code.toString();
        }
    }


}
