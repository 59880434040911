import { Component, OnInit } from '@angular/core';
import { UteService } from 'app/rest/ute.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { ZoneService } from 'app/rest/zone.service';
import { RestExt } from 'app/services/rest-client-extension';
import { Rest } from '../../../rest/rest_client';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';

@Component({
    selector: 'app-poc-visit-form',
    templateUrl: './poc-visit-form.component.html',
    styleUrls: ['./poc-visit-form.component.css'],
    providers: [VehicleService],
})

export class PoCVisitFormComponent implements OnInit {
    visible = false;
    user: Rest.User;
    messages: any[];
    vehicleFilter = RestExt.firstPageRequest();
    cpFilter = RestExt.firstPageRequest();

    entity = {
        vehicle: { id: -1 },
        cp: { id: -1 },
        date: new Date(),
    };

    now = new Date();

    ngOnInit(): void {
        this.user = this.auth.user;
    }

    async send() {
        if (this.entity.vehicle.id == -1 || this.entity.date == null || this.entity.cp.id == -1) {
            console.error("Some of the inputs are not filled");
            this.notificationsService.add(Severity.error, this.i18n._(':('), 'Please fill all the inputs before save');
            //this.i18n._('Pleade fill all the inputs before save'));  // ricard :) TODO: ADD I18N
            return;
        }
        try {
            await this.uteService.createVisit(this.entity.cp.id, this.entity.vehicle.id, this.entity.date.getTime());
            this.notificationsService.add(Severity.success, this.i18n._(':)'), "");
            //Clear the form
            this.entity = {
                vehicle: { id: -1 },
                cp: { id: -1 },
                date: new Date(),
            };
            this.visible = false;
        } catch (ex) {
            console.error(ex);
            this.notificationsService.add(Severity.error, this.i18n._(':('), ex);
        }

    }

    constructor(public i18n: I18nService, private notificationsService: NotificationsService, private auth: AuthenticationService, private uteService: UteService,
                public zoneService: ZoneService, public vehicleService: VehicleService) {
    }
}
