<!-- TODO: VERY IMPORTANT: Since PrimeNG's DataTable is deprecated, the whole table should be recreated -->
<!--<p-dataTable [value]="translations" [editable]="true" [rows]="10"
 [rowsPerPageOptions]="[10,20,30]" [paginator]="true" [(selection)]="selectedEntities" #dt
 (onEditComplete)="update($event.data)">

    <p-header>
        <ng-container i18n>Translations</ng-container>
    </p-header>

    <p-column [style]="{'width':'38px'}" selectionMode="multiple"></p-column>
    <p-column field="tag" header="Tag" [editable]="true"></p-column>
    <p-column field="catalan" header="Catalan" [editable]="true"></p-column>
    <p-column field="english" header="English" [editable]="true"></p-column>
    <p-column field="spanish" header="Spanish" [editable]="true"></p-column>

</p-dataTable>-->

<!-- Add Translation -->

<div style="float:left; width:170px; padding-top:20px">
    <button type="button" pButton icon="fa-plus" style="float:left" (click)="add()" i18n-label label="Add Translation"></button>
</div>

<!-- Remove Translation -->

<div *ngIf="selectedEntities.length > 0" style="float:left; padding-top:20px">
    <button type="button" pButton icon="fa-close" style="float:left" (click)="remove()" i18n-label label="Remove selected"></button>
</div>

<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
