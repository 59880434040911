import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { PoiService } from "../../../rest/poi.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { CercaliaMapService } from "../../../services/cercalia-map/cercalia-map.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import {
    CustomMapOptions,
    Location,
} from "../../shared/custom-map/custom-map.model";
import { SelectItem } from 'primeng/api/selectitem';
import { PoicategoryService } from "../../../rest/poicategory.service";
import { RestExt } from 'app/services/rest-client-extension';
import { CustomMapComponent } from 'app/components/shared/custom-map/custom-map.component';

@Component({
    selector: "app-poi-form",
    templateUrl: "./poi-form.component.html",
    styleUrls: ["./poi-form.component.scss"],
    providers: [PoiService, CercaliaMapService, PoicategoryService],
})
export class PoiFormComponent
    extends EntityFormComponent<Rest.POI>
    implements OnInit {
    @ViewChild(CustomMapComponent) map: CustomMapComponent;
    clonedEntity: Rest.POI;
    poiCategoryService: PoicategoryService;
    title: string;

    availableCategories: Rest.POICategory[];
    poiCategoriesPaginationRequest: Rest.ListPaginationRequest;

    pois: Rest.POI[];
    icon: string;

    //opciones para configurar el mapa de pois.
    //estas opciones varian en función del modo en que se inicializa el dialogo cada vez.
    // showMap = true;
    showMap = false;
    customMapOptions: CustomMapOptions = {};

    //Datos de entrada para este componente. Se utilizan para configurar el mapa
    @Input() selectedPOICategory: Rest.POICategory;
    @Input() selectedPOI: Rest.POI;
    @Input() realTime: boolean;
    

    constructor(
        poiService: PoiService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private cercaliaMapService: CercaliaMapService,
        authenticationService: AuthenticationService,
        poiCategoryService: PoicategoryService
    ) {
        super(poiService, notificationsService, i18n, authenticationService);
        this.poiCategoryService = poiCategoryService;
    }

    ngOnInit() {
        let that = this;
        super.ngOnInit();
        //si el input selectedPOICategory existe, inicializamos el nombre del mapa,
        // guardamos la lista de pois asocidos a este y su icono (para pintar sus pois en el mapa usando ese icono)
        let mapIdAux;
        let poisAux;
        let icon;
        if (this.selectedPOICategory) {
            mapIdAux = "" + this.selectedPOICategory.id;
            poisAux = this.selectedPOICategory.pois;
            icon = this.selectedPOICategory.icon.imageEncoded;
                
        }

        this.pois = poisAux || [];
        this.icon = icon;
        this.poiCategoriesPaginationRequest = RestExt.firstPageRequest();
        this.poiCategoryService.getAllEnabledCategories().then(function (response) {
            that.availableCategories = response;
        });
        this.initEntity(false);

    }

    /**
     * Cada vez que un @Input modifica su valor (desde el exterior), se ejecuta este método.
     * Lo aprovechamos para reconfigurar las opciones del dialogo y/o mapa
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        let mapIdAux;
        let poisAux;
        let icon;
        if (this.selectedPOICategory) {
            mapIdAux = "" + this.selectedPOICategory.id; //no lo usamos de momento
            poisAux = this.selectedPOICategory.pois;
            icon = this.selectedPOICategory.icon.imageEncoded;
        }

        this.pois = poisAux || [];
        this.icon = icon;
        this.configureMode();
    }

    /**
     * Se configuran las opciones del mapa para que este muestre o no ciertos iconos y
     * capture/genere los eventos correspondientes
     */
    private configureMode() {
        this.selectedPOI ? this.initEntity(true) : this.initEntity(false);
    }

    beforeShow() {
        this.showMap = true;

        if(this.realTime){
            this.pois = [this.entity];
        }
    }

    close(event) {

        this.showMap = false;
        //this.initEntity(false);
        // console.log('onHide');
        this.onClose.emit();
    }

    async initMapAfterViewInit(event) {
        this.configureMode();
    }

    beforeSave() {
        for (const p in this.entity) {

            if (this.entity[p] === "_$visited") {
                delete this.entity[p];
            }
        }

        //POI se tiene que crear
        if (this.isNew) {
            if(this.selectedPOICategory){
                const categoryTmp = {id: this.selectedPOICategory.id};
                this.entity.poiCategory = categoryTmp as Rest.POICategory;
            }
 
        }
        //Update POI/PoiCategory
        else {
            const poiCategory: Rest.POICategory = {
                id: this.selectedPOICategory.id,
                enabled: this.selectedPOICategory.enabled,
                owner: this.selectedPOICategory.owner,
                icon: this.selectedPOICategory.icon,
                name: this.selectedPOICategory.name,
                pois: [],
            };
            delete poiCategory["pois"];

        }
    }

    beforeCreate() {
        //this.initEntity(false);
    }

    saveNewPOIsList() {
        this.save();
    }

    notifyCreatePOI(location: Location) {
        //crear vertex, afegir a position
        //actualitza provincia, postal code, address, municipality
        this.updatePOIFieldsFromMap(location);
    }

    private initEntity(edit: boolean) {
        if (edit) {
            this.isNew = false;
            this.title = this.i18n._("Edit POI");

            //No se pueden crear pois. Solo se puede editar el editPOI. Los otros pois solo se muestran.
            //El poi que editamos solo actualiza los datos en el formulario. Para guardar hay que pulsar save.
            this.customMapOptions = {
                createPOIonClick: false,
                editPOIs: false,
                editPOIid: this.selectedPOI.id,
            };

            this.entity.name = this.selectedPOI.name;
            this.entity.position = this.selectedPOI.position;
            this.entity.address = this.selectedPOI.address;
            this.entity.postalCode = this.selectedPOI.postalCode;
            this.entity.municipality = this.selectedPOI.municipality;
            this.entity.province = this.selectedPOI.province;
            this.entity.phoneNumber = this.selectedPOI.phoneNumber;
            this.entity.contactPerson = this.selectedPOI.contactPerson;
            this.entity.timeZone = this.selectedPOI.timeZone;
            this.entity.observations = this.selectedPOI.observations;
            this.entity.param1 = this.selectedPOI.param1;
            this.entity.param2 = this.selectedPOI.param2;
            this.entity.poiCategory = this.selectedPOICategory;
            
            window.setTimeout(() => {
                this.map.centerMapAtPoint(this.entity.position.latitude,this.entity.position.longitude,10);
            }, 500);
            
        } else {
            this.isNew = true;
            this.title = this.i18n._("Create POI");

            //Se puede crear un solo poi al clicar en el mapa. El resto de pois no son editables (solo visibles)
            if(this.realTime){

                this.customMapOptions = {
                    createPOIonClick: false,
                    editPOIs: false,
                    restrictNPois: 0,
                    editPOIid: 0,
                };
            }else{
                this.customMapOptions = {
                    createPOIonClick: true,
                    editPOIs: false,
                    restrictNPois: 1,
                };
            }
        }
    }

    private updatePOIFieldsFromMap(location: Location) {
        let vertex: Rest.Vertex;
        if (!this.entity.position) {
            vertex = {
                latitude: Number(location.lat),
                longitude: Number(location.lon),
            };
        } else {
            vertex = this.entity.position;
            vertex.latitude = Number(location.lat);
            vertex.longitude = Number(location.lon);
        }

        this.entity.position = vertex;
        this.entity.address = location.address;
        this.entity.postalCode = location.postalCode;
        this.entity.municipality = location.municipality;
        this.entity.province = location.subregion;
        this.entity.timeZone = location.timezone ? location.timezone : ""; //GMT+time_zone
    }

    notifyDeletePOI($event: any) {
        // TODO: IMPORTANT!!! -> Method not implemented!
    }

    categoryChanged(){
        this.icon = this.entity.poiCategory.icon.imageEncoded;
        for (let poi of this.pois) {
            poi.poiCategory = this.entity.poiCategory;
        }
    }
}
