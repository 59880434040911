import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class ProfileService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.Profile): Rest.RestResponse<Rest.Profile> {
return this.client.Profile_create(arg0);
}

delete(arg0: Rest.Profile[]): Rest.RestResponse<void> {
return this.client.Profile_delete(arg0);
}

update(arg0: Rest.Profile): Rest.RestResponse<Rest.Profile> {
return this.client.Profile_update(arg0);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Profile>> {
return this.client.Profile_getPage(arg0);
}

getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Profile>> {
return this.client.Profile_getPageAndFilters(arg0);
}
}
