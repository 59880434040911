import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';

import { EntityService } from '../../rest/entity.service';
import { Rest } from '../../rest/rest_client';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { I18nService } from '../../services/i18n/i18n.service';
import { LoadingService } from '../../services/loading-service/loading.service';
import { NotificationsService, Severity } from '../../services/notifications-service/notifications.service';
import { RestExt } from '../../services/rest-client-extension';
import { EntityFormComponent } from '../entity-form/entity-form.component';
import { UserTimePipe } from '../shared/UserTimePipe';
import { ExcelService } from "app/services/excel-service/excel.service";
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { TimerRefreshService } from 'app/services/timer/timer-refresh.service';

@Component({
    selector: 'app-entity-list',
    templateUrl: './entity-list.component.html',
    styleUrls: ['./entity-list.component.css'],
})
export class EntityListComponent<T> implements OnInit, AfterViewInit, OnDestroy {
    protected alive = true;
    protected canBeEnabled = true;
    messages: any[];
    visible: boolean;
    errors = {};
    //IF true, on Update call, the service find method will be called to retrieve the affected entity
    fetchEntityBeforeShowUpdateForm = false;
    title: string;
    selectedEntities: T[];
    paginationRequest: Rest.ListPaginationRequest;
    paginationResult: Rest.Page<T>;
    paginationResultExcel: Rest.Page<T>;
    filters: { [index: string]: Rest.TableFilterSelectItem[] };
    loadFilters: boolean; //Flag indicating that associated entity filters must be loaded at next request
    hasEntityFilters = false; //Flag indicating that the entity list can be filtered by associated entities. If true, requires that the REST service used has the method getPageAndFilters
    protected form: EntityFormComponent<T>;
    _selectedColumns: any[];
    columns: any[];
    extraFilters = [];
    allColumnsSelected = true
    /*Permissions */
    permissions = new Map<RestExt.ActionEnum, number[]>();
    user: Rest.User;
    /** */

    /*Check box Column selection */
    entitiesSelected = new Map<number, boolean>();
    selectedAll = false;
    disableSelectAll = true;

    iconListHeight = 20
    iconListWidth = 20

    showSaveResponse = true

    /** */

    /*#Change Status */
    showStatusDialog = false;
    /** */
    showDeleteDialog = false;

    loading = false; //Loading...
    isSelectableColumn = false;
    isColumn = false;
    showLoadingIcon = true
    @Output() protected propagateRefreshEvent = new EventEmitter();
    @ViewChild('dt') table: any;
    @Input() protected listId: string;
    //Timer Refresh
    private timerRefreshSubscription: Subscription;
    firstTimerLoad = true;
    hasTimerRefresh = false;
    componentId = "";



    //private loadingService: LoadingService;
    //protected userTime: UserTimePipe;
    constructor(
        protected service: EntityService<T>,
        protected confirmationService: ConfirmationService,
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService?: ExcelService,
        protected timerRefreshService?: TimerRefreshService
    ) {
        //this.loadingService = AppModule.injector.get(LoadingService);
        //this.userTime = AppModule.injector.get(UserTimePipe);
    }

    selectableColumn() {
        this.isSelectableColumn = true;
    }

    initFilters() {
    }

    ngOnInit() {
        console.debug('generic-list ngInit ');
        this.loadFilters = true;
        this.visible = true;
        this.messages = [];
        this.selectedEntities = [];

        //If local storage contains paginationRequest info, retrieve it.
        if (this.listId && localStorage.getItem(this.listId + '-paginationRequest')) {
            try {
                this.paginationRequest = JSON.parse(localStorage.getItem(this.listId + '-paginationRequest'));
            } catch (e) {
                console.error(e);
            }

            if (this.table) {
                this.resetProperties(this.table);
            }
        }

        if (!this.paginationRequest) {
            this.paginationRequest = RestExt.firstPageRequest();
        }
        if (!this.filters) {
            this.filters = {};
        }

        this.paginationResult = {
            filteredEntities: 0,
            entities: [],
        } as Rest.Page<T>;

        /*Permissions */
        this.user = this.authenticationService.user;
        this.setIconsListSize()
        /** */
    }

    ngAfterViewInit() {
        console.debug('generic-list afterViewInit');
        const that = this;

        if (this.form) {
            this.form.return
                .takeWhile(() => this.alive)
                .subscribe((entity) => {
                    //TODO: capture updated/created object
                    if (that.reloadFilters(entity as any) === true) {
                        that.loadFilters = true;
                    }
                    if (that.showSaveResponse) that.notificationsService.add(Severity.success, that.i18n._(':)'), that.i18n._('Entity updated')); "";
                    that.refresh();
                });
        }
        this.setTimerSubscription();
        // TODO: Following code should be uncommented in order for the other tables to work

        /* this.fetch().then(() => {
      this.afterLoad();
    });*/
    }

    ngOnDestroy() {
        this.alive = false;
        this.destroyTimerSubscription();
    }

    /**
     * Weather or not filters should be reloaded based on the new entity.
     * Subclasses of entity list must check if, after the modifications performed to the entity, the filters must be reloaded to include/exclude options
     */
    protected reloadFilters(modifiedEntity: T) {
        return false;
    }

    refresh() {
        console.debug('generic-list refresh');
        const that = this;
        this.fetch().then(() => {
            this.afterLoad();
            that.propagateRefreshEvent.emit();
        });
    }

    resetProperties(dataTable) {
        dataTable.sortField = this.paginationRequest.sortBy;
        dataTable.sortOrder = this.paginationRequest.sortAsc ? 1 : 0;
        dataTable.first = this.paginationRequest.offset;

        /*if( this.paginationRequest.filters)
    {
      dataTable.filters = [];
      for(const key in  this.paginationRequest.filters)
      {
        dataTable.filters[key] = {value:this.paginationRequest.filters[key]};
      }
    }
    this.loadFilters = true;*/
        /*
    for (const key in event.filters) {
      //It is a multiselect filter, and thus, the value is an array.
      if (event.filters[key].value.constructor === Array) {
        this.paginationRequest.filters[key] = event.filters[key].value
      } else {
        //It is a single selection filter (input text, date,etc...) we have to create an array with the only value
        this.paginationRequest.filters[key] = [event.filters[key].value];
      }
    }*/
    }

    applyFilters(event: LazyLoadEvent) {
        this.paginationRequest.filters = {};
        this.paginationRequest.pageSize = event.rows;
        /*
       for (let key in event.filters) {
           //It is a multiselect filter, and thus, the value is an array.
           // TODO: Check if following code is correct! event.filters[key] is always an Array and thus the check doesn't make sense!
       
           if (event.filters[key][0].value !== null) {
               if (event.filters[key][0].value.constructor === Array) {
                   this.paginationRequest.filters[key] = event.filters[key][0].value;
               } else {
                   //It is a single selection filter (input text, date,etc...) we have to create an array with the only value
                   this.paginationRequest.filters[key] = [event.filters[key][0].value];
               }
           }
       }   */

        for (let key in event.filters) {
            const filterValue = event.filters[key];
            // check filter type 'menu' or 'row'
            if (Array.isArray(filterValue) && filterValue.length > 0) {
                //menu filter, used in tables, receive data in array of filter objects
                const firstValue = filterValue[0].value;
                if (firstValue !== null && firstValue !== '') {
                    if (firstValue.constructor === Array) {
                        this.paginationRequest.filters[key] = firstValue;
                    } else {
                        // Si no es un array, crea un array con el único valor
                        this.paginationRequest.filters[key] = [firstValue];
                    }
                }
            } else {
                //In row filters, used in extra-column-filters, receive data in filter object
                if (event.filters[key].value !== null && event.filters[key].value !== '') {
                    this.paginationRequest.filters[key] = [event.filters[key].value];
                }
            }
        }
        //If enabled filter is available and  no filter is provided for "enabled" asume is true (show only enabled)
        /*if (this.canBeEnabled && !this.paginationRequest.filters.enabled) {
      this.paginationRequest.filters.enabled = ["true"];
    }*/
        this.paginationRequest.sortBy = event.sortField;
        this.paginationRequest.sortAsc = event.sortOrder === 1;
        this.paginationRequest.offset = event.first;
    }

    protected beforeLoad() {
    }
    protected afterLoadExcel() {
    }


    protected afterLoad() {
    }

    protected beforeCreate() {
    }

    loadData(event: LazyLoadEvent) {
        this.applyFilters(event);
        this.beforeLoad();
        this.showLoadingIcon = true;
        //Store the new status in localStorage
        if (this.listId) {
            try {
                localStorage.setItem(this.listId + '-paginationRequest', JSON.stringify(this.paginationRequest));
            } catch (e) {
                console.error(e);
            }
        }
        this.fetch().then(() => {
            this.afterLoad();
        });
    }

    arrayToString(array) {
        const nestedEntities = [];
        // eslint-disable-next-line guard-for-in
        for (const a in array) {
            nestedEntities.push(array[a].name);
        }
        return nestedEntities.join(', ');
    }

    updateNoDisplay(entity: T) {
        this.form.updateNodisplay(entity);
    }

    update(entity: T) {
        this.form.update(entity);
    }

    create(entity?: T) {
        this.beforeCreate();

        this.form.create(entity);
    }

    protected beforeDelete() {
    }

    delete() {
        if (this.selectedEntities.length > 0) {
            this.showDeleteDialog = true;

            const that = this;
            this.confirmationService.confirm({
                message: that.i18n._('Are you sure you want to delete these entities?'),
                accept: () => {
                    const l = [];
                    // eslint-disable-next-line guard-for-in
                    for (const e in this.selectedEntities) {
                        l.push({ id: this.selectedEntities[e]['id'] });
                    }

                    this.service
                        .delete(l)
                        .then(
                            function (ret) {
                                that.notificationsService.add(Severity.success, that.i18n._(':)'), that.i18n._('Entities deleted'));
                                that.selectedEntities = []; //last change to init selected entities and not remove already remove entities
                                that.loadFilters = true; //If necessary, reload the entities filters
                                that.refresh();
                            },
                            (err) => {
                                console.log(err);
                                that.handleError(err, that);
                            },
                        )
                        .catch((e) => {
                            this.handleError(e, that);
                        });
                },
            });
        }
    }

    deleteNoDisplay() {
        if (this.selectedEntities.length > 0) {
            const that = this;
            const l = [];
            for (const e in this.selectedEntities) {
                l.push({ id: this.selectedEntities[e]['id'] });
            }

            this.service.delete(l).then(function (ret) {
                that.notificationsService.add(Severity.success, that.i18n._(':)'), that.i18n._('Entities deleted'));
                that.loadFilters = true; //If necessary, reload the entities filters
                that.refresh();
            });
        }
    }

    editDialog(event) {
        if (!this.isSelectableColumn) {
            this.onRowDblClick({ data: event });
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editDialogTable(event) {
        if (!this.isColumn && !this.isSelectableColumn) {
            //console.log('onRowDBlClick');
            //console.log(event['_$changeStatus']);
            this.onRowDblClick(event);
        }

        this.isSelectableColumn = false;
        this.isColumn = false;
    }

    protected beforeonRowDblClick() {
    }

    onRowDblClick(event) {
        this.beforeonRowDblClick();

        /*Permissions */
        const updatePermission: boolean = event.data['_$update'];
        const changeStatusPermission: boolean = event.data['_$changeStatus'];
        /** */
        if (this.fetchEntityBeforeShowUpdateForm) {
            this.service.find(event.data['id']).then((e: T) => {
                /*Permissions */
                e['_$update'] = updatePermission;
                e['_$changeStatus'] = changeStatusPermission;
                /** */
                this.update(e);
            });
        } else {
            this.update(event.data);
        }
    }

    fetch() {
        if (this.showLoadingIcon) {
            //default hideLoading = false
            this.loading = true;
            this.loadingService.increaseRESTOngoingRequestCounter();
        }
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            //Load the filters and page if requested and the entity can be filtered by other entities
            let t = this.paginationRequest;
            /**
             * Convert all the dates from the user defined time zone to UTC
             */
            if (this.paginationRequest != null) {
                for (let f in this.paginationRequest.filters) {
                    for (let idx in this.paginationRequest.filters[f]) {
                        if ((this.paginationRequest.filters[f][idx] as any) instanceof Date) {
                            (this.paginationRequest.filters[f] as any)[idx] = this.userTime.calendarDateToUTC(this.paginationRequest.filters[f][idx]);
                        }
                    }
                }
            }
            if (this.loadFilters && this.hasEntityFilters) {
                //If paginationRequest is null create a new one
                if (this.paginationRequest == null) {
                    this.paginationRequest = RestExt.firstPageRequest();
                }

                this.service
                    .getPageAndFilters(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response.page;
                            that.filters = response.filters;
                            that.loadFilters = false;

                            /*Permissions */
                            for (let a in response.page.permissions) {
                                that.permissions[RestExt.ActionEnum[a]] = response.page.permissions[a];
                            }
                            //that.form.permissions = that.permissions;
                            /** */
                            /*Checkbox colum selection */
                            for (let a in response.page.entities) {
                                that.entitiesSelected[response.page.entities[a]['id']] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();

                            resolve();
                        },

                        function (error) {
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            that.loading = false;

                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        that.loading = false;

                        resolve();
                    });
            } else {
                this.service
                    .getPage(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response;

                            /*Permissions */
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            for (let a in response.permissions) {
                                that.permissions[RestExt.ActionEnum[a]] = response.permissions[a];
                            }
                            //that.form.permissions = that.permissions;
                            /** */
                            /*Checkbox colum selection */
                            for (let a in response.entities) {
                                that.entitiesSelected[response.entities[a]['id']] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();

                            resolve();
                        },
                        /* failure */

                        function (error) {
                            console.log('The request failed: ' + error);
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            that.loading = false;
                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        console.log('Catched: ' + reason);
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        that.loading = false;

                        resolve();
                    });
            }
        });
        return promise;
    }

    fetchExcel() {
        this.loading = true;
        this.loadingService.increaseRESTOngoingRequestCounter();
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            let paginationRequestExcel = { ...this.paginationRequest };
            paginationRequestExcel.pageSize = 2147483647; //2000 max java int (max excel size)
            if (paginationRequestExcel != null) {
                for (let f in paginationRequestExcel.filters) {
                    for (let idx in paginationRequestExcel.filters[f]) {
                        if ((paginationRequestExcel.filters[f][idx] as any) instanceof Date) {
                            (paginationRequestExcel.filters[f] as any)[idx] = this.userTime.calendarDateToUTC(paginationRequestExcel.filters[f][idx]);
                        }
                    }
                }
            }
            //If paginationRequest is null create a new one
            if (paginationRequestExcel == null) {
                paginationRequestExcel = RestExt.firstPageRequest();
                paginationRequestExcel.pageSize = 2147483647; //2000 max java int (max excel size)
            }
            if (this.loadFilters && this.hasEntityFilters) {

                this.service
                    .getPageAndFilters(paginationRequestExcel)
                    .then(
                        function (response) {
                            that.paginationResultExcel = response.page;
                            that.filters = response.filters;
                            that.loadFilters = false;
                            that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },

                        function (error) {
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            that.loading = false;
                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        that.loading = false;
                        resolve();
                    });
            } else {
                this.service
                    .getPage(paginationRequestExcel)
                    .then(
                        function (response) {
                            that.paginationResultExcel = response;
                            that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },
                        /* failure */

                        function (error) {
                            console.log('The request failed: ' + error);
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            that.loading = false;
                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        console.log('Catched: ' + reason);
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        that.loading = false;
                        resolve();
                    });
            }
        });
        return promise;
    }

    /*CheckBox Colum selection */
    checkSelectAll() {
        for (let a in this.paginationResult.entities) {
            if (this.paginationResult.entities[a]['_$changeStatus'] === true) {
                this.disableSelectAll = false;
            }
        }
    }

    selectAll(select: boolean) {
        if (select) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            for (let a in this.paginationResult.entities) {
                if (this.paginationResult.entities[a]['_$changeStatus'] === true) {
                    if (this.selectedEntities.some((x) => x === this.paginationResult.entities[a]) === false) {
                        this.selectedEntities.push(this.paginationResult.entities[a]);
                    }
                    this.entitiesSelected[this.paginationResult.entities[a]['id']] = select;
                }
            }
        } else {
            for (let i = this.selectedEntities.length - 1; i >= 0; i--) {
                this.selectedEntities.splice(i, 1);
            }
            for (let a in this.entitiesSelected) {
                this.entitiesSelected[a] = select;
            }
        }
    }

    changeSelection(id: number, value: boolean) {
        this.entitiesSelected[id] = value;
        if (value) {
            for (let a in this.paginationResult.entities) {
                if (this.paginationResult.entities[a]['id'] === id) {
                    this.selectedEntities.push(this.paginationResult.entities[a]);
                }
            }
        } else {
            for (let i = this.selectedEntities.length - 1; i >= 0; i--) {
                if (this.selectedEntities[i]['id'] === id) {
                    this.selectedEntities.splice(i, 1);
                }
            }
            this.selectedAll = false;
        }
    }

    /** */

    closeDeleteDialog(event) {
        let close: boolean;
        close = event;
        this.showDeleteDialog = close;
    }

    refreshList(event, notif: boolean = true) {
        //console.log("----- ENTITY-LIST  RefreshList -------");
        if (notif) this.notificationsService.add(Severity.success, this.i18n._(':)'), this.i18n._('Entities status changed'));
        this.selectedEntities = []; //last change to init selected entities and not remove already remove entities
        this.loadFilters = true; //If necessary, reload the entities filters
        this.showStatusDialog = false;
        this.refresh();
    }

    closeStatusDialog(event) {
        let close: boolean;
        close = event;
        this.showStatusDialog = close;
    }

    /***************** */

    /*Permissions */

    /*Match from view entity the type of each node to select which permission we need to check  */
    permissionsEntityTypeSelector(action: string, entityType: string) {
        switch (entityType) {
            case 'Device':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.DEVICES_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.DEVICES_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Notice Group':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.NOTIFICATION_GROUPS_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.NOTIFICATION_GROUPS_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Zone Group':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.ZONES_GROUPS_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.ZONES_GROUPS_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'POI Category':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.POIS_CATEGORIES_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.POIS_CATEGORIES_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Maintenance Plan':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.MAINTENANCE_PLANS_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.MAINTENANCE_PLANS_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Maintenance':
                switch (action) {
                    case 'execution':
                        return RestExt.ActionEnum.MAINTENANCE_EXECUTE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'User':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.USERS_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.USERS_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Rol':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.ROLES_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.ROLES_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            case 'Profile':
                switch (action) {
                    case 'changeStatus':
                        return RestExt.ActionEnum.PROFILES_DISABLE;
                    case 'update':
                        return RestExt.ActionEnum.PROFILES_UPDATE;
                    default:
                        console.log('Unknown action');
                        break;
                }
            default:
                //  console.log('Unknown entity');
                break;
        }
    }

    /*returns true if the list of entities contains selected entity */
    hasPermission(action: RestExt.ActionEnum, id: number) {
        if (this.permissions[action] !== undefined) {
            /*if (this.permissions[action].some(x => x == id) == true)
        return true;*/
            return this.permissions[action].some((x) => x === id) === true;
        } else {
            return false;
        }
    }

    /** */

    changeStatus() {
        if (this.selectedEntities.length > 0) {
            this.showStatusDialog = true;
        }
    }

    cleanSelectedEntities() {
        this.selectedEntities = [];
    }

    protected handleError(err, that) {
        that.errors = {};
        if (typeof err === 'string') {
            try {
                err = JSON.parse(err);
                if (err.class === 'AtlantisMessage') {
                    this.notificationsService.add(err.type === 'Warning' ? Severity.warn : Severity.error, this.i18n._(':('), err.message);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    //EXCEL
    filterTitles = [];
    getFilterFileName() {
        let filterName = "";
        let count = 0;
        for (const filter in this.paginationRequest.filters) {
            if (filter != "client" && filter != "id" && filter != "clientID") {
                let filterfields = filter.split(".");
                if (filterfields.length > 0) {
                    filterName += "_" + filterfields[filterfields.length - 1] + "_";
                    const filtersHeader = Object.keys(this.paginationRequest.filters)
                        .map(key => `${key}: ${this.paginationRequest.filters[key].join(', ')}`)
                        .join('\n');
                }
                for (let i = 0; i < this.paginationRequest.filters[filter].length; i++) {
                    // filterName += "" + this.paginationRequest.filters[filter][i] + "_";;
                }
            }
            count++;
        }
        return filterName;
    }
    //versió excel estatic
    exportExcelWithColumns(fileName, excelColumns) {
        fileName = fileName.replace(/\s/g, '');
        fileName.trim();
        //filteredEntities -> total records
        // if(this.paginationRequest.pageSize < this.paginationResultExcel.filteredEntities){
        this.fetchExcel().then(() => {
            this.afterLoadExcel();
            const excelEntities = this.paginationResultExcel.entities.map(entity => this.mapEntity(entity, excelColumns));
            let fullFileName = fileName //+ this.getFilterFileName()
            this.excelService.exportAsExcelFile(excelEntities, fullFileName);
        });
        //}
    }

    //versió excel taula dinàmica
    exportExcelWithColumnsMetadata(fileName, excelColumns) {
        fileName = fileName.replace(/\s/g, '');
        fileName.trim();
        //excelColumns = excelColumns.map(item => ({ ...item, header: this.translate.instant(item.header) }));
        this.fetchExcel().then(() => {
            this.afterLoadExcel();
            const excelEntities = this.paginationResultExcel.entities.map(entity => this.mapEntityMetadata(entity, excelColumns));
            let fullFileName = fileName + this.getFilterFileName()
            this.excelService.exportAsExcelFile(excelEntities, fullFileName);
        });
        //}
    }


    mapEntity<T, U>(entity: T, propertiesMapping: { [key in keyof U]: { bindField: (entity: any) => any; map?: boolean } }): U {
        const entityMapped: any = {};
        for (const property in propertiesMapping) {
            const mapping = propertiesMapping[property];
            const fieldFlag = mapping.map === undefined ? true : mapping.map;
            let value = "";
            if (fieldFlag && mapping.bindField) {
                value = mapping.bindField(entity);
            }
            entityMapped[property] = value;
        }
        return entityMapped as U;
    }


    mapEntityMetadata<T, U>(entity: T, columnsMetadata: [{ field: string, header: string, excel: Boolean, bindField?: (entity: any) => any; }]): U {
        const entityMapped: any = {};
        columnsMetadata.forEach(column => {
            if (column.excel) {
                let value = ""
                if (column.bindField) {
                    value = column.bindField(entity)
                }
                else {
                    value = entity[column.field]
                }
                entityMapped[column.header] = value;
            }

        });
        return entityMapped as U;
    }


    //TIMER REFRESH TABLES
    private setTimerSubscription() {
        //subscripcion al timerRefresh. Debe estar iniciado desde componente (ejemplo logisitics.component)
        if (this.timerRefreshService && this.hasTimerRefresh) {
            if (!this.timerRefreshSubscription || this.timerRefreshSubscription.closed) {
                this.timerRefreshSubscription = this.timerRefreshService.timer$.subscribe(() => {
                    if (!this.firstTimerLoad) {
                        this.showLoadingIcon = false
                        this.fetch().then(() => {
                            this.afterLoad();
                        });
                        this.showLoadingIcon = true
                    }
                    this.firstTimerLoad = false;
                });
            }
        }
    }
    private destroyTimerSubscription() {
        if (this.timerRefreshService && this.hasTimerRefresh) {
            if (this.timerRefreshSubscription) {
                this.timerRefreshSubscription.unsubscribe();
            }
        }
    }
    //ICONS SIZE from TABLE CONFIGS
    protected setIconsListSize() {
        if (this.user?.tableSize === 'BIG') {
            this.iconListHeight = 20
            this.iconListWidth = 20
        }
        else {
            this.iconListHeight = 14
            this.iconListWidth = 14
        }
    }
    //SELECTED COLUMNS
    @Input()
    set selectedColumns(val: any[]) {
        this._selectedColumns = this.columns.filter(col => val.includes(col));
    }

    get selectedColumns(): any[] {
        //No llamar desde HTML
        return this._selectedColumns;
    }
    visibleColumns: any;
    onSelectedColumnsChange(event: any) {
        this._selectedColumns = this.columns.filter(col => event.includes(col));
        this.mapSelectedColumns();
    }

    mapSelectedColumns() {
        const visibleColumns: { [key: string]: boolean } = {};
        this.extraFilters = this.extraFilters?.filter(extraFilter => extraFilter.initial);
        this.columns.forEach(col => {
            visibleColumns[col.field] = this.selectedColumns.includes(col);
            /* EXTRA FILTER CONFiG*/
            this.setExtraFilters_UnSelectedColumns(col, visibleColumns[col.field]);
        });
        this.allColumnsSelected = this._selectedColumns?.length === this.columns?.length
        this.visibleColumns = visibleColumns
    }

    setExtraFilters_UnSelectedColumns(col, visibleColumn) {
        if (col.filter && col.type == 'text' && this.table && this.table.filters) {
            let filterValue = null
            if(Array.isArray(this.table?.filters[col.field])){
                 //column header filters -> array format
                filterValue = this.table?.filters[col.field][0]?.value ?? ""
            }
            else{
                //extra row filter   -> object format
                filterValue = this.table?.filters[col.field]?.value ?? ""
            } 

            if(visibleColumn){
                 this.table.filters[col.field] = [{ value: filterValue }]
            }
            else{
                 this.extraFilters.push({ field: col.field, header: col.header, initial: false });
                 this.table.filters[col.field] = { value: filterValue }
            }
        }
    }

}
