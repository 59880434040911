<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- CARD -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-name" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.card' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <p-dropdown [options]='cardList' [(ngModel)]='entity.card' [disabled]="!editCard"
                        [placeholder]="cardPlaceholder" dataKey='id'
                        optionLabel='number' [style]="{'width':'250px'}" appendTo='body' [filter]="true" filterBy="number"
                        (onChange)="onChangeCard($event)" (onFilter)="onFilterCard($event)">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!-- IMPORT VALUE -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-iValue" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.amount' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <div class="input-wrapper">
                        <p-inputNumber [(ngModel)]="entity.amount" mode="decimal" locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)"></p-inputNumber>
                        <span class="input-symbol">€</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- CONSUMPTION DATE -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.date' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">


                     <p-calendar [(ngModel)]='dateConsumption' [showTime]='false'
                    showIcon='true' appendTo='body' [firstDayOfWeek]="1" dateFormat='{{i18n.dateFormat}}'
                    appendTo='body'></p-calendar>

                </div>
            </div>
        </div>

        <!-- LITERS -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-liters" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.litres' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardConsumption-liters" class="form-control custom-input"
                        [(ngModel)]="entity.liters">
                </div>
            </div>
        </div>

        <!-- KMS -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="carConsumption-kms" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.km' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="carConsumption-kms" class="form-control custom-input"
                        [(ngModel)]="entity.kms">
                </div>
            </div>
        </div> 

        <!-- DIFF KMS -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-diffKms" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.diffKms' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="fine-reason" class="form-control custom-input" [(ngModel)]="entity.difKms">
                </div>
            </div>
        </div> 

        <!-- DIFF DAYS -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-diffDays" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.diffDays' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardConsumption-diffDays" class="form-control custom-input" [(ngModel)]="entity.difDays">
                </div>
            </div>
        </div> 

        <!-- DIFF CONSUMPTION -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-diffConsumption" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.diffConsumption' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardConsumption-diffConsumption" class="form-control custom-input" [(ngModel)]="entity.difConsumption">
                </div>
            </div>
        </div> 

        <!-- KMS COST -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-kmsCost" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.kmsCost' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardConsumption-kmsCost" class="form-control custom-input" [(ngModel)]="entity.kmsCost">
                </div>
            </div>
        </div> 

        <!-- LITER COST -->
         <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardConsumption-literCost" class="custom-label" i18n>
                    {{'tableManagement.cardsConsumption.literCost' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardConsumption-literCost" class="form-control custom-input" [(ngModel)]="entity.literCost">
                </div>
            </div>
        </div> 

    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>

                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>