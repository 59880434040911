<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Motion Sensor Alarm
          (AT+GTMSA)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="modeOptions" [(ngModel)]="msaParameters.mode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Send Last Position">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="lastPositionOptions" [(ngModel)]="msaParameters.lastPositionMode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Sensitivity">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" step="1" [(ngModel)]="msaParameters.sensitivity" [min]="1" [max]="10"></p-spinner>
          <span class="ui-inputgroup-addon">1~10</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Alarm Timeout">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" step="1" [(ngModel)]="msaParameters.alarmTimeout" [min]="5" [max]="10"></p-spinner>
          <span class="ui-inputgroup-addon">5~10</span>
          <span class="ui-inputgroup-addon"> sec</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>