import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-speed-alarm-form-gv300can",
  templateUrl: "./speed-alarm-form-gv300can.component.html",
  styleUrls: ["./speed-alarm-form-gv300can.component.css"],
})
export class SpeedAlarmFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() speedAlarmParameters: Rest.SpeedAlarmParametersGV300CAN;

  modeOptions: any = [
    { label: "Disable speed alarm", value: 0 },
    {
      label: "Report speed alarm if the current speed is winthin the range",
      value: 1,
    },
    {
      label: "Report speed alarm if the current speed is outside the range",
      value: 2,
    },
    {
      label:
        "Report speed alarm once if the current speed enters or exits the speed range",
      value: 3,
    },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.speedAlarmParameters);
    }
  }

  trackModeSwitch = false;

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
