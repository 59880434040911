<!-- <p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display"> -->

<p-dialog
	header="{{ title | uppercase }}"
	[(visible)]="display"
	[responsive]="true"
	showEffect="fade"
	[modal]="true"
	*ngIf="display"
	[draggable]="false"
	[resizable]="false"
	[closeOnEscape]="false"
	[style]="{ width: '80vw' }"
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
	appendTo="body"
>
	<!-- BODY -->
	<div class="container-md">
		
		<div #errorContainer>
			<div *ngIf='errorMessages.length !== 0'>
				<div *ngFor='let error of errorMessages'>
					<div class='alert alert-warning' role='alert'>{{error}}</div>
				</div>
			</div>
		</div>

		<!-- IMPORT & PAY DATE -->
		<div class="row mt-3">
			<!-- IMPORT -->
			<div class="col-md-6">
				<!-- <label for="tax-name" class="custom-label" i18n>
                    <strong>{{'tableManagement.fine.amount' | translate}} (*)</strong>
                </label> -->
				<form-input
					[required]="true"
					[align]="'cols-2'"
					fieldName="{{ 'tableManagement.fine.amount' | translate }}"
				>
					<div class="input-wrapper" [style]="{ width: '200px' }">
						<p-inputNumber
							[(ngModel)]="entity.taxImport"
							inputId="locale-us"
							mode="decimal"
							locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving"
						></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</form-input>
			</div>

			<!-- PAY DATE -->
			<div class="col-md-6">
				<!-- <div class="row">
                    <div class="col-12">
                        <label for="pay-date" class="custom-label" i18n>
                            <strong>{{'tableManagement.cardsConsumption.date' | translate}} (*)</strong>
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12"> -->
				<form-input
					[required]="true"
					[align]="'cols-2'"
					fieldName="{{ 'tableManagement.cardsConsumption.date' | translate }}"
				>
					<p-calendar
						[(ngModel)]="payDate"
						[readonlyInput]="true"
						[showTime]="true"
						[style]="{ width: '200px' }"
						dateFormat="{{ i18n.dateFormat }}"
						showIcon="true"
						(ngModelChange)="onDateChange()"
						appendTo="body"
						[disabled]="saving"
					></p-calendar>
				</form-input>
				<!-- </div>
                </div> -->
			</div>
		</div>

		<!-- DESCRIPTION -->
		<div class="row mt-3">
			<!-- DESCRIPTION INPUT -->
			<div class="col-md-6">
				<!-- <label for="tax-name" class="custom-label" i18n>
					<strong>{{ 'general.description' | translate }}</strong>
				</label> -->
				<form-input [required]="true" [align]="'cols-2'" fieldName="{{ 'general.description' | translate }}">
					<textarea
						rows="2"
						cols="80"
						pInputTextarea
						[pTooltip]="entity.description"
						tooltipPosition="top"
						[(ngModel)]="entity.description"
						class="observations"
						fitContent="true"
						[disabled]="saving"
					></textarea>
				</form-input>
			</div>

			<!-- VEHICLE -->
			<div class="col-md-6">
				<!-- *ngIf="editVehicle" -->
				<form-input
					[required]="true"
					[align]="'cols-2'"
					fieldName="{{ 'tableManagement.cardLists.vehicle' | translate }}"
				>
					<p-dropdown
						[options]="vehicleList"
						[(ngModel)]="entity.vehicle"
						[placeholder]="vehiclePlaceholder"
						dataKey="id"
						optionLabel="name"
						[style]="{ width: '250px' }"
						[disabled]="!editVehicle || saving"
						appendTo="body"
						[filter]="true"
						filterBy="name"
						(onChange)="onChangeVehicle($event)"
						(onFilter)="onFilterVehicle($event)"
					>
					</p-dropdown>
				</form-input>
			</div>
		</div>

		<div class="mt-3" *ngIf="!isNew">
			<app-doc-table type="taxes" [entity]="entity" [nested]="true" [ids]="ids" (return)="refresh()">
			</app-doc-table>
		</div>
	</div>

	<p-footer>
		<div class="d-flex justify-content-center bd-highlight">
			<div class="p-2 bd-highlight">
				<div *ngIf="isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
						{{ saving ? ('general.saving' | translate) : ('general.create' | translate) }}
					</button>
				</div>
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
						{{ saving ? ('general.saving' | translate) : ('general.save' | translate) }}
					</button>
				</div>
			</div>
			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="changeStatus(!active)" i18n [disabled]="saving">
						{{ 'general.changeStatus' | translate }}
					</button>
				</div>
			</div>
		</div>
	</p-footer>
</p-dialog>
