<!-- BILLING ACCOUNTS PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate="
         {first}
         {{ 'table.to' | translate }} {last}
         {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.clients.billingAccountsTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>


            <!-- CODE -->
            <th pSortableColumn="code">
              <div class="d-flex align-items-center h-100">
      
                <!-- COLUMN TITLE -->
                <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'control-panel.clients.codeBa' | translate }}
                </div>
      
                <!-- COLUMN SORT && FILTER ICONS -->
                <div class="d-flex align-items-center h-100">
                  <p-sortIcon field="code"></p-sortIcon>
                  <p-columnFilter field="code"
                                  type="text"
                                  matchMode="contains"
                                  placeholder="{{ 'general.search' | translate }}"
                                  display="menu"
                                  [showMatchModes]="false"
                                  [showOperator]="false"
                                  [showAddButton]="false"
                  ></p-columnFilter>
                </div>
      
              </div>
            </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>





      <!-- ACTIVE -->
      <th pSortableColumn="enabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="enabled"></p-sortIcon>
            <p-columnFilter field="enabled"
                            type="boolean"
                            display="menu"
                            [showApplyButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-billingAccount>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(billingAccount)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="billingAccount"></p-tableCheckbox>
        </div>
      </td>

            <!-- CODE -->
      <td>
        {{billingAccount.code}}
      </td>

      <!-- NAME -->
      <td>
        {{billingAccount.name}}
      </td>


      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
             [ngClass]="{'true-icon pi-check-circle': billingAccount.enabled, 'false-icon pi-times-circle': !billingAccount.enabled}"></i>
        </div>
      </td>

    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="9" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD BILLING ACCOUNT BUTTON -->
          <app-table-add-button [text]=" 'control-panel.clients.billingAccount' | translate "
                                (btnClick)="create()"
                                [isDisabled]="false"
          ></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
                                          [isDisabled]=" selectedEntities.length === 0"
          ></app-table-change-status-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG  -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.billingAccounts' | translate "
                            [display]="true"
                            [service]=billingaccountService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-dialog>
</div>

<app-company-form></app-company-form>