import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MileageStatsService extends EntityService<Rest.MileageStats> {

    client: Rest.RestApplicationClient;
	 getKmStats = new Subject();

    constructor(private jquery: JQueryHTTPClient,private http: HttpClient) {
		console.log("[DOCUMENTATION-SERVICE] constructor ::::: ");
      super();
      this.client = new Rest.RestApplicationClient(jquery);

    }

    getStats(arg0): Rest.RestResponse<Rest.MileageStats> {
        return this.client.MileageStats_getStats(arg0);
    }

}
