import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-alarm-moving-configuration-we-track2',
  templateUrl: './alarm-moving-configuration-we-track2.component.html',
  styleUrls: ['./alarm-moving-configuration-we-track2.component.css'],
})
export class AlarmMovingConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit {

  @Input() alarmMoving: Rest.AlarmMovingConfigurationWeTrack2;
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  constructor(protected i18n: I18nService) {  super(i18n);}

  AlarmMovingOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"SMS_GPRS"},
    {label: "GPRS+SMS+phone call", value:"GPRS_SMS_PHONECALL"},        
  ];

  ngOnInit() {
    if(this.alarmMoving!=null){this.disableDIV(!this.alarmMoving.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.alarmMoving);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
