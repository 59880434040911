import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService } from 'primeng/api';
import { TargetsService } from '../../service/targets.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { CampaignService } from '../../service/campaign.service';
import { FiltersService } from '../../service/filters.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RestExt } from 'app/services/rest-client-extension';
import { IndexOfPipe, ValueOfPipe } from '../../pipes/EnumArrayPipe';

@Component({
  selector: 'app-targets-list',
  templateUrl: './targets-list.component.html',
  styleUrls: ['./targets-list.component.css'],
  providers: [
		ConfirmationService,
		IndexOfPipe
  ]
})
export class TargetsListComponent extends EntityFormComponent<Rest.FidTargets> implements OnInit, OnDestroy {

	@Input() campaign: Rest.FidCampaigns;

	/** SELECTED ITEMS */
	selectedTarget: Rest.FidTargets;
	selectedFilter: Rest.FidFilters;
	targetIndex: number;
	filterIndex: number;

	/** PARAMETER/TYPE/VALUE LISTS*/
	fullInfoParameterList: Rest.FullInfoParameters[];
	parameterType: string[];

	/** HTML ITEMS */
	targetList: Rest.FidTargets[] = [];  		//MAIN TARGET LIST
	filterList: Rest.FidFilters[] = [];			//MAIN FILTER LIST
	parameterList: string[] = []; 				//PARAMETER DROPDOWN LIST
	filterTypeList: string[] = [];  				//OPERATOR LIST: type os value (TXT, BOL; COM,...)
	valueList: any[] = [];        				//VALUE LIST if value is enum
	selectedFilterParam: Rest.FidParameters;	//target selected
	selectedFilterType: Rest.FidFilterType;	//filter selected
	selectedFilterValue: string;					//value selected or typed
	loaded: boolean = false;						//show Loading....
	modifiyFilter: boolean = false;  			//Start editing/create filter
	isDropdown: boolean = true;					//value field is dropdown or input field
	filterListLoaded: boolean = false;			//select target first message
	filterParamSelected: boolean = false;		//enabled/disabled filterTypeList
	filterTypeSelected: boolean = false;		//enabled/disabled value field
	filterValueSelected: boolean = false;		//enabled/disabled SAVE button
	isEditingDisabled: boolean = false;
	isPanelVisible: boolean = false;
	createNewFilterPrompt: boolean = false;

	/** others */
	isNew: boolean = false;
	showDeleteDialog: boolean = false;
	loading: boolean = true; //Loading...
	targetSubscription: Subscription;
	collapsed: boolean = true;

	constructor(
		public targetsService: TargetsService,
		public filtersService: FiltersService,
		public campaignService: CampaignService,
		protected confirmationService: ConfirmationService,
		protected notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authService: AuthenticationService,
		private translate: TranslateService,
		public indexOfPipe: IndexOfPipe,
		//public valueOfPipe: ValueOfPipe
   ) {
      super(targetsService, notificationsService, i18n, authService);
   }

  	ngOnInit(): void {
		// console.log("[TARGETS-LIST] OnInit ********************");
		// console.log(this.campaign);
		this.targetSubscription = this.targetsService.endTargetEditing
      .subscribe(
         (item: Rest.FidTargets) => {

         }
      );
		this.messages = [];
		this.targetList = this.campaign.targets;
		if (this.targetList === null) this.collapsed = false;
		this.filterList = []; //this.targetList[0].filters;
		this.selectedTarget = null;
		this.selectedFilter = null; //this.targetList[0].filters[0];
		this.getFullInfoParameters();
		this.showPanel();
		this.afterLoad()

		/* omplir llistes de valors enum */
		this.campaignService.getDeviceCommunicationList();
		this.campaignService.getDeviceTypeList();
		this.campaignService.getGenderList();
		this.campaignService.getLanguageList();
	}

	ngOnDestroy() {
		// console.log("*** onDesrtoy() ***");
		this.targetSubscription.unsubscribe();
	}

	public showPanel() {
		// console.log("[TARGETS-LIST] ShowPanel ***");
		this.isPanelVisible = true;
		this.resetFilterFields();
	}

	protected afterLoad() {
		// console.log("[TARGETS-LIST] afterLoad ***");
		this.loaded = true;
		this.filterListLoaded = false;
		this.filterParamSelected = false;
		this.filterTypeSelected = false;
	}

	protected getFullInfoParameters() {
		// console.log("[TARGETS-LIST] getFullInfoParameters ***");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.campaignService
				.getFullInfoParameters()
				.then(
					 function (response) {
						// console.log("[TARGETS-LIST] getFullInfoParameters - RESPONSE ***");
						that.fullInfoParameterList = response;
						// console.log(that.fullInfoParameterList);
						that.fullInfoParameterList.forEach(element => {
							/** obtenim la llista de parametres primer
							 * la resta de llistes aniran segons el parametre
							 * escollit pero ho extreurem tot de fullInfoParameterList
							 */
							that.parameterList.push(element.parameter);
						});
						// console.log(that.parameterList);
					 },
					 /* failure */
					 function (error) {
						  console.log('The request failed: ' + error);
						  resolve();
					 },
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	protected getValues(type: string) {
		// console.log("[TARGETS-LIST] getValues *** " + type);
		// console.log("isNew: " + this.isNew);

		switch(type) {
			case "BOL":
				this.isDropdown = true;
				this.valueList = [ "true", "false" ];
				if (!this.isNew) {
					this.selectedFilterValue = this.selectedFilter.value;
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
			case "COM":
				this.isDropdown = true;
				this.valueList = this.campaignService.deviceCommunicationList;
				if (!this.isNew) {
					this.selectedFilterValue = this.valueList[this.selectedFilter.value];
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
			case "DEV":
				this.isDropdown = true;
				this.valueList = this.campaignService.deviceTypeList;
				if (!this.isNew) {
					this.selectedFilterValue = this.valueList[this.selectedFilter.value];
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
			case "GEN":
				this.isDropdown = true;
				this.valueList = this.campaignService.genderList;
				if (!this.isNew) {
					this.selectedFilterValue = this.valueList[this.selectedFilter.value];
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
			case "LNG":
				this.isDropdown = true;
				this.valueList = this.campaignService.languageList;
				if (!this.isNew) {
					this.selectedFilterValue = this.valueList[this.selectedFilter.value];
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
			default: //TXT
				this.isDropdown = false;
				if (!this.isNew) {
					this.selectedFilterValue = this.selectedFilter.value;
					// console.log("this.selectedFilterValue: " + this.selectedFilterValue);
				}
				break;
		}
	}

	public showFilters(target) {
		// console.log("[TARGETS-LIST] showFilters ***");
		// console.log(target);
		this.filterList = target.filters;
		// console.log(this.filterList);
		this.selectedTarget = target;
		// console.log(this.selectedTarget);
		this.filterListLoaded = true;
		this.resetFilterFields();
	}

	public editFilter(filter) {
		// console.log("[TARGETS-LIST] editFilter ***");
		this.isNew = false;
		this.selectedFilter = filter;

		/** load parameter field */
		this.selectedFilterParam = this.selectedFilter.parameter;

		/** load operators field */
		this.filterTypeList = this.fullInfoParameterList[this.parameterList.indexOf(this.selectedFilterParam)].filterTypes;
		this.selectedFilterType = this.selectedFilter.filterType;

		/** load values field */
		this.getValues(this.fullInfoParameterList[this.parameterList.indexOf(this.selectedFilterParam)].valueType);

		/**  */
		this.modifiyFilter = true;
		this.filterParamSelected = true;
		this.filterTypeSelected = true;
		this.filterValueSelected = true;

	}

	public createFilter() {
		// console.log("[TARGETS-LIST] createFilter ***");
		/** init camps */
		this.resetFilterFields();
		this.isNew = true;
		this.createNewFilterPrompt = true;
		this.modifiyFilter = true;
	}

	public onChangeParameter(event) {
		// console.log("[TARGETS-LIST] onChangeParameter *** " + this.selectedFilterParam);

		/** reset filter fields but param */
		this.selectedFilterType = null;
		this.selectedFilterValue = null;
		this.filterTypeSelected = false;
		this.filterValueSelected = false;

		/** load type dropdown */
		this.filterTypeList = this.fullInfoParameterList[this.parameterList.indexOf(this.selectedFilterParam)].filterTypes;
		this.filterParamSelected = true;

		/** load values field */
		this.getValues(this.fullInfoParameterList[this.parameterList.indexOf(this.selectedFilterParam)].valueType);
	}

	public onChangeType(event) {
		// console.log("[TARGETS-LIST] onChangeType *** " + this.selectedFilterType);
		this.filterTypeSelected = true;
		if (!this.isDropdown) this.filterValueSelected = true;
	}

	public onChangeValue(event) {
		// console.log("[TARGETS-LIST] onChangeValue *** " + this.selectedFilterValue);
		this.filterValueSelected = true;
	}

	public createTarget() {
		// console.log("[TARGETS-LIST] createTarget ***");
		this.selectedTarget = {
			id: 0,
			campaign: this.campaign,
			name: "",
			description: "",
			createdByUser: this.authService.user,
			creationDate: new Date(),
			filters: []
		}
		this.isNew = true;
		this.targetsService.startEditTarget.next({isNew: true, target: this.selectedTarget});
	}

	public editTarget() {
		// console.log("[TARGETS-LIST] editTarget *** " + this.selectedTarget.id);
		this.isNew = false;
		this.targetsService.startEditTarget.next({isNew: false, target: this.selectedTarget});
		this.resetFilterFields();
	}

	public resetFilterFields() {

		/** reset filter fields */
		this.selectedFilterParam = null;
		this.selectedFilterType = null;
		this.selectedFilterValue = null;
		this.modifiyFilter = false;
		this.filterParamSelected = false;
		this.filterTypeSelected = false;
		this.filterValueSelected = false;

	}

	public deleteTarget(target) {
		// console.log("[TARGETS-LIST] deleteTarget ***");
		// console.log(target);

		let confirmationMessage =
			this.translate.instant('control-panel.delete-dialog.message') + ' ' +
			this.translate.instant('promotedRoutes.theSpace') + ': ' +
			'<strong>' + target.name + '</strong>?';

		//console.log(confirmationMessage);
		this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				//console.log("accept");
				this.targetsService.deleteTarget(target.id).then((response) => {
					//console.log(response);
					if (response) {
						this.notificationsService.add(
							Severity.success,
							this.translate.instant('success.success'),
							this.translate.instant('success.delete.event')
						);
						/* actualizamos la variable local */
						this.targetIndex = this.campaign.targets.indexOf(target);
						this.campaign.targets.splice(this.targetIndex,1);
						this.filterIndex = null;
						this.filterList = null;
						this.filterListLoaded = false;
						this.resetFilterFields();
						/* actualizamos la lista de spaces */
						this.campaignService.campaignSaved.next();
					}
				});
			},
		});
	}

	public deleteFilter(filter) {
		// console.log("[TARGETS-LIST] deleteFilter ***");

		let confirmationMessage =
			this.translate.instant('control-panel.delete-dialog.message') + ' ' +
			this.translate.instant('promotedRoutes.theSpace') + ': ' +
			'<strong>' + filter.parameter + " " + filter.filterType + " " + filter.value + '</strong>?';

		//console.log(confirmationMessage);
		this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				//console.log("accept");
				this.filtersService.deleteFilter(filter.id).then((response) => {
					//console.log(response);
					if (response) {
						this.notificationsService.add(
							Severity.success,
							this.translate.instant('success.success'),
							this.translate.instant('success.delete.event')
						);
						/* actualizamos la variable local */
						this.targetIndex = this.campaign.targets.indexOf(this.selectedTarget);
						this.filterIndex = this.campaign.targets[this.targetIndex].filters.indexOf(filter);
						this.campaign.targets[this.targetIndex].filters.splice(this.filterIndex,1);
						this.resetFilterFields();
						/* actualizamos la lista de spaces */
						this.campaignService.campaignSaved.next();
					}
				});
			},
		});
	}

	public saveFilter() {
		// console.log("[TARGETS-LIST] saveFilter ***");
		this.createNewFilterPrompt = false;
		let data = {
			id: this.isNew ? 0 : this.selectedFilter.id,
			target: { id: this.selectedTarget.id },
			parameter: this.selectedFilterParam,
			filterType: this.selectedFilterType,
			value: this.indexOfPipe.transform(this.selectedFilterValue, this.selectedFilterParam)
		}

		if (this.isNew) {
			this.filtersService.createFilter(data).then((filter) => {
				if (filter) {
					/* actualizamos la variable local */
					this.targetIndex = this.campaign.targets.indexOf(this.selectedTarget);
					this.filterIndex = this.campaign.targets[this.targetIndex].filters.indexOf(this.selectedFilter);
					this.campaign.targets[this.targetIndex].filters.push(filter);
					/* actualizamos la lista de spaces */
					this.campaignService.campaignSaved.next();
					/* toast */
					this.notificationsService.add(
						Severity.success,
						this.translate.instant('general.done') + "!",
						this.translate.instant('general.successMsg'));
				}
			});
		} else {
			this.filtersService.updateFilter(data).then((filter) => {
				if (filter) {
					/* actualizamos la variable local */
					this.targetIndex = this.campaign.targets.indexOf(this.selectedTarget);
					this.filterIndex = this.campaign.targets[this.targetIndex].filters.indexOf(this.selectedFilter);
					this.campaign.targets[this.targetIndex].filters[this.filterIndex].parameter = data.parameter; //this.selectedFilterParam;
					this.campaign.targets[this.targetIndex].filters[this.filterIndex].filterType = data.filterType; //this.selectedFilterType;
					this.campaign.targets[this.targetIndex].filters[this.filterIndex].value = data.value; //this.selectedFilterValue;
					/* actualizamos la lista de spaces */
					this.campaignService.campaignSaved.next();
					/* toast */
					this.notificationsService.add(
						Severity.success,
						this.translate.instant('general.done') + "!",
						this.translate.instant('general.successMsg'));
				}
			});
		}
	}

}

