<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Protocol Watchdog
          (AT + GTDOG)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-dropdown [options]="modeOptions" [(ngModel)]="dogParameters.mode"
                  [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Ignition Frequency">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="dogParameters.ignitionFrequency" [min]="10" [max]="120"></p-spinner>
                <span class="ui-inputgroup-addon">10~120</span>
                <span class="ui-inputgroup-addon">min</span>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-3">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="dogParameters.interval" [min]="1" [max]="30"></p-spinner>
                <span class="ui-inputgroup-addon">1~30</span>
                <span class="ui-inputgroup-addon">day</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Time">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="text" maxlength="4" pInputText [(ngModel)]="dogParameters.time" [style]="{'width':'100%'}"/>
              </div>
            </div>
          </div>

        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Digital Input ID">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="dogParameters.digitalInputId" [min]="0" [max]="1"></p-spinner>
              <span class="ui-inputgroup-addon">0~1</span>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Other
                Settings</span>
            </div>
          </div>
        </p-header>
        <div class="row mt-3">
          <div class="row mt-3">
            <p-checkbox binary="true" label="Report Before Reboot" [(ngModel)]="dogParameters.reportBeforeReboot">
            </p-checkbox>
          </div>

          <div class="row mt-3">

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="GSM interval">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="dogParameters.gsmInterval" [min]="0" [max]="1440"></p-spinner>
                  <span class="ui-inputgroup-addon">0~1440</span>
                  <span class="ui-inputgroup-addon">min</span>
                </div>
              </div>
            </div>

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="PDP interval">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="dogParameters.pdpInterval" [min]="0" [max]="1440"></p-spinner>
                  <span class="ui-inputgroup-addon">0~1440</span>
                  <span class="ui-inputgroup-addon">min</span>
                </div>
              </div>
            </div>

          </div>


          <div class="row mt-3">

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="PDP reset interval">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="dogParameters.pdpResetInterval" [min]="0" [max]="1440"></p-spinner>
                  <span class="ui-inputgroup-addon">0~1440</span>
                  <span class="ui-inputgroup-addon">min</span>
                </div>
              </div>
            </div>

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="Fail interval">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="dogParameters.failInterval" [min]="0" [max]="1440"></p-spinner>
                  <span class="ui-inputgroup-addon">0~1440</span>
                  <span class="ui-inputgroup-addon">min</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>