<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">NETWORK LINK COORDINATES
          (EURL)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Network Link">
      </form-input>
    </div>
    <div class="col">
      <div class="ui-inputgroup">
        <input type="text" pInputText [(ngModel)]="networkLinkCoordinates.networkLink" class="txtbStyle">
      </div>
    </div>
  </div>
</p-accordionTab>