import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
 })
export class DriverpointsService extends EntityService<Rest.InvDriverPoints> {

    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient,private http: HttpClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    public createDriverPoints(driverPoints: string): Promise<Rest.InvDriverPoints> {

		const formData = new FormData();
		formData.append('points', driverPoints);

        return this.http
          .post(environment.webApiBaseUrl + 'api/inventory/driverpoints', formData)
          .toPromise()
          .then((response: any) => {
            return response;
          })
          .catch((error) => {
            this.notificationsService.add(Severity.error, 'Error', error.message);
            return null;
          });
      }

      public updateDriverPoints(driverPoints): Promise<Rest.InvDriverPoints> {

			const formData = new FormData();
			formData.append('points', driverPoints);

        return this.http
          .post(environment.webApiBaseUrl + 'api/inventory/driverpoints/update', formData)
          .toPromise()
          .then((response: any) => {
            return response;
          })
          .catch((error) => {
            this.notificationsService.add(Severity.error, 'Error', error.message);
            return null;
          });
      }

    findDocument(arg0: string,queryParams?: { id?: number }): Rest.RestResponse<Rest.InvDriverPoints> {
        return this.client.DriverPoints_findDocument(arg0, queryParams);
    }

}
