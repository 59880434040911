<p-accordionTab i18n-header header="ARM event configuration">

  <div class="ui-g">

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Enable/Disable" [errors]="errors.enable">
        <p-inputSwitch [(ngModel)]="arm.enable"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Event action" [errors]="errors.EventAction">
        <p-dropdown [options]="availableEventActions" [(ngModel)]="arm.eventAction" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Enable/Disable arm" [errors]="errors.enableSetArm">
        <p-inputSwitch [(ngModel)]="arm.enableSetArm"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Enable/Disable disarm" [errors]="errors.enableSetDisarm">
        <p-inputSwitch [(ngModel)]="arm.enableSetDisarm"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Enable/Disable siren" [errors]="errors.enableSirenActivation">
        <p-inputSwitch [(ngModel)]="arm.enableSirenActivation"></p-inputSwitch>
      </form-input>
    </div>


  </div>

</p-accordionTab>