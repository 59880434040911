
<div id="pre-config-form">

    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <p-messages [(value)]="messages"></p-messages>
        </div>
    </div>
    <div *ngIf="firmwareValue !== undefined">

        <p-panel i18n-header header="VT10 Parameters">
            <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
                <div class="ui-g-12 ui-md-6">
                    <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                        <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                            [(ngModel)]="selectedPreconfig" [style]="{'width':'200px'}" filter="filter"></p-dropdown>
                    </form-input>
                </div>
            </div>

            <p-tabView #tv (onChange)="tabChanged()">

                <p-tabPanel i18n-header header="Events">

                    <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>

                        <app-speed-event-config-form [deviceParams]="deviceParams"></app-speed-event-config-form>


                        <div *ngIf="firmwareValue > 2">
                            <app-analog-input-config-form [deviceParams]="deviceParams"></app-analog-input-config-form>
                            <app-milage-config-form [deviceParams]="deviceParams"></app-milage-config-form>
                        </div>


                        <app-track-event-config-form [isTrack2]="false" [track]="deviceParams.proprietaryParameters.track"></app-track-event-config-form>
                        <app-track-event-config-form [isTrack2]="true" [track]="deviceParams.proprietaryParameters.track2"></app-track-event-config-form>


                        <div *ngIf="firmwareValue > 2">
                            <app-roaming-event-form [roaming]="deviceParams.proprietaryParameters.roaming"></app-roaming-event-form>
                        </div>

                        <div *ngIf="firmwareValue > 1">
                            <app-power-saving-config-form [powerSaving]="deviceParams.proprietaryParameters.powerSaving"></app-power-saving-config-form>
                            <app-report-detection-event-form [dr]="deviceParams.proprietaryParameters.dr"></app-report-detection-event-form>
                            <app-acc-event-form [acc]="deviceParams.proprietaryParameters.acc"></app-acc-event-form>
                        </div>

                        <div *ngIf="firmwareValue > 2">
                            <app-sos-event-form [sosEvent]="deviceParams.proprietaryParameters.sos"></app-sos-event-form>
                            <app-acc-on-without-tag-event-configuration [deviceParams]="deviceParams"></app-acc-on-without-tag-event-configuration>
                            <app-rfdetected-event-configuration [rfDetectedEventConfig]="deviceParams.proprietaryParameters.rfDetected"></app-rfdetected-event-configuration>
                            <app-tag-inout-config [tagInOutEventConfig]="deviceParams.proprietaryParameters.tagInOut"></app-tag-inout-config>
                        </div>


                        <app-tow-event-config-form [tow]="deviceParams.proprietaryParameters.towAlert"></app-tow-event-config-form>


                        <div *ngIf="firmwareValue > 1">
                            <app-gps-antena-conf-form [deviceParams]="deviceParams"></app-gps-antena-conf-form>
                        </div>

                        <div *ngIf="firmwareValue > 2">
                            <app-ignition-event-form [ignitionOff]="deviceParams.proprietaryParameters.ignitionOff"></app-ignition-event-form>
                            <app-harsh-acceleration-config-form [deviceParams]="deviceParams"> </app-harsh-acceleration-config-form>
                            <app-harsh-breaking-config-form [deviceParams]="deviceParams"></app-harsh-breaking-config-form>
                            <app-emergency-stop-event-form [deviceParams]="deviceParams"></app-emergency-stop-event-form>
                            <app-harsh-cornering-event-configuration [deviceParams]="deviceParams"></app-harsh-cornering-event-configuration>
                            <app-time-configuration [time]="deviceParams.proprietaryParameters.time"></app-time-configuration>
                        </div>

                    </p-accordion>

                </p-tabPanel>

                <div *ngIf="firmwareValue > 2">

                    <p-tabPanel i18n-header header="Sensors">
                        <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
                    </p-tabPanel>

                    <p-tabPanel i18n-header header="Actuators">
                        <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
                    </p-tabPanel>

                    <p-tabPanel i18n-header header="Led Patterns">
                        <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
                            <app-led-pattern-config-form [ledPattern]="deviceParams.proprietaryParameters.ledPAttern1"></app-led-pattern-config-form>
                            <app-led-pattern-config-form [ledPattern]="deviceParams.proprietaryParameters.ledPAttern2"></app-led-pattern-config-form>
                            <app-led-pattern-config-form [ledPattern]="deviceParams.proprietaryParameters.ledPAttern3"></app-led-pattern-config-form>
                            <app-led-pattern-config-form [ledPattern]="deviceParams.proprietaryParameters.ledPAttern4"></app-led-pattern-config-form>
                            <app-led-pattern-config-form [ledPattern]="deviceParams.proprietaryParameters.ledPAttern5"></app-led-pattern-config-form>
                        </p-accordion>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="RFTags">
                        <app-rftag-configuration [RFTags]="deviceParams.proprietaryParameters.rfTags"></app-rftag-configuration>
                    </p-tabPanel>

                </div>

            </p-tabView>

        </p-panel>

    </div>

    <p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>
