import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class RoutePoiService extends EntityService<Rest.FidRoutePOI> {

	client: Rest.RestApplicationClient;
	startedEditing = new Subject<Rest.FidRoutePOI>();
	startedCreating = new Subject<{showCoordinates: boolean, coordinates: any}>();
	endTranslationEditing = new Subject<Rest.FidRoutePOI>();

	constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	public addPoi(poiData: string, poiLogoFile?: any): Promise<Rest.FidRoutePOI | null> {

		// console.log("[ROUTRE-POI-SERVICE] addPoi ***");
		// console.log(poiData);
		// console.log(poiLogoFile);
		/* FORM DATA */
		const formData = new FormData();
		formData.append('image', poiLogoFile);
		formData.append('poi', poiData);

		return this.http
			 .post(environment.webApiBaseUrl + 'api/fid/routepoi', formData)
			 .toPromise()
			 .then((response: any) => {
				  return response;
			 })
			 .catch((error) => {
				  this.notificationsService.add(Severity.error, 'Error', error.message);
				  return null;
			 });
  }

  /**
	* updatePoi()
	* */
  updatePoi(poiData: string, poiLogoFile?: any): Promise<Rest.FidRoutePOI | null> {

		// console.log("[ROUTRE-POI-SERVICE] addPoi ***");
		// console.log(poiData);
		// console.log(poiLogoFile);

		/* FORM DATA */
		const formData = new FormData();
		formData.append('image', poiLogoFile);
		formData.append('poi', poiData);

		return this.http
			 .post(environment.webApiBaseUrl + 'api/fid/routepoi/update', formData)
			 .toPromise()
			 .then((response: any) => {
				  return response;
			 })
			 .catch((error) => {
				  this.notificationsService.add(Severity.error, 'Error', error.message);
				  return null;
			 });
  }

  /**
	* deletePoi()
	* */
  deletePoi(poiId: number): Promise<boolean> {
		return this.http
			 .delete(environment.webApiBaseUrl + 'api/fid/routepoi/' + poiId)
			 .toPromise()
			 .then(() => {
				  return true;
			 })
			 .catch((error) => {
				  this.notificationsService.add(Severity.error, 'Error', error.message);
				  return false;
			 });
  }
}
