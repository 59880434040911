import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'app-frequency-change-fri-gmt200',
  templateUrl: './frequency-change-fri-gmt200.component.html',
  styleUrls: ['./frequency-change-fri-gmt200.component.css'],
})
export class FrequencyChangeFriGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() ffcParameters: Rest.FrequencyChangeFriGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  constructor(protected i18n: I18nService) { super(i18n); }

  modeOptions: SelectItem[];

  ngOnInit() {
    if(this.firmware > 5){
      this.modeOptions= [
        {label: "Disable this function", value:"DISABLE"},
        {label: "Change report interval of FRI to IGF Report interval in AT + GTFRI when ignition is off", value:"CHANGE_REPORT_IN_GTFRI_WHEN_IGNITION_OFF"},
        {label: "Change report interval of FRI to NMD Report interval in AT+GTNMD when the device is in non-movement state", value:"CHANGE_REPORT_IN_GTNMD_WHEN_DEVICE_NON_MOVEMENT_STATE"},
        {label: "Change report interval of FRI to Tow Interval in AT + GTTOW when the device is being towed. This mode only takes effect when Tow Enable is 2", value:"CHANGE_REPORT_IN_GTTOW_WHEN_DEVICE_IS_TOWED"}
      ]
    }else{
      this.modeOptions= [
        {label: "Disable this function", value:"DISABLE"},
        {label: "Change report interval of FRI to IGF Report interval in AT + GTFRI when ignition is off", value:"CHANGE_REPORT_IN_GTFRI_WHEN_IGNITION_OFF"},
        {label: "Change report interval of FRI to NMD Report interval in AT+GTNMD when the device is in non-movement state", value:"CHANGE_REPORT_IN_GTNMD_WHEN_DEVICE_NON_MOVEMENT_STATE"},
      ]
    }
 
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.ffcParameters);
    }
  }

}
