<div class="flex-column justify-content-center align-items-center">

    <div class="flex-column p-0 mx-5 me-5">
        <app-data-import-navbar></app-data-import-navbar>
    </div>

    <hr class="separator mx-5 me-5">

    <div class="flex-column justify-content-center pb-5 mx-5 me-5">
        <router-outlet></router-outlet>
    </div>

</div>
