import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Rest } from 'app/rest/rest_client';
import { SelectItem } from 'primeng/api/selectitem';
import { RestExt } from 'app/services/rest-client-extension';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RealtimeService } from 'app/rest/realtime.service';
import { RealTimeDataService } from 'app/services/rt-data/rt-data.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';

@Component({
  selector: 'app-chart-settings',
  templateUrl: './chart-settings.component.html',
  styleUrls: ['./chart-settings.component.css']
})
export class ChartSettingsComponent implements OnInit {

  @Input() chartOptions: any;
  @Input() width:any
  @Input() height:any
  settings: Rest.SensorChartSettings;
  
  fleets: Rest.RTFleet[];
  protected tagsAndValues: { [index: string]: Rest.CategoricalValue[] };
  protected listTagsAndValues: Rest.DTagAndValue[];
  public availableTags: SelectItem[];
  public availableSensor: SelectItem[];
  public availableActuator: SelectItem[];

  protected sensorTagValue: { [index: string]: Rest.CategoricalValue[] };
  protected actuatorTagValue: { [index: string]: Rest.CategoricalValue[] };

  availableModels: SelectItem[];

  //chart vars
  private mappings: RestExt.Pair<string, string>[];
  chartData: any;

  //Labels
  categorical: string;
  numerical: string;

  displaySettingsForm: boolean = false;
  categoricalMappings: RestExt.Pair<string, string>[];
  categoricalValues: { [index: string]: Rest.CategoricalValue };
  numericalMappings: RestExt.Pair<number, string>[];

  userLanguage: string;
  _settings: Rest.SensorChartSettings;
  constructor(
    protected i18n: I18nService,
    private rtService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef
    ) {
        this.fleets = [];
        this._settings = Object.assign({}, emptySettings);
        this.numericalMappings = [];
        this.categoricalMappings = [];
        this.categoricalValues = {};
        this.mappings = [];
        this.availableModels = RestExt.getEnumItemsNoTranslation(RestExt.DEnum);
        this.availableModels.push({ label: "ALL", value: "ALL" });
    }

    ngOnInit() {
        
        //this.loadData();
        //Subscrive to new vehicle data
        this.realTimeDataService.vehicleInfo.subscribe(
            (v: RestExt.ExtendedVehicleDataMessage) => {               
                this.reloadChart();
            }
        );

        //subscribe to changes in confi
        this.realTimeDataService.settingsChange.subscribe((settings: Rest.SensorChartSettings) =>{
            this.settings = settings;
            //this.cdr.detectChanges(); // Trigger change detection
            this.loadData();
            this.reloadChart();
        });
        
        
        
    }

    updateCategoricalTags() {
    var newMappings = [];
    this.categoricalValues = {};
    if (
        this._settings.sensorTag == null &&
        this.availableTags != null &&
        this.availableTags.length > 0
    )
        this._settings.sensorTag = this.availableTags[0].value;
    if (this._settings.sensorTag != null && this.sensorTagValue != null) {
        switch (this._settings.filterType) {
            case "ALL":
                for (let idx in this.tagsAndValues[this._settings.sensorTag]) {
                    newMappings.push({
                        key: this.tagsAndValues[this._settings.sensorTag][idx].key,
                        value: "#f6f6f6",
                    });
                    this.categoricalValues[
                        this.tagsAndValues[this._settings.sensorTag][idx].key
                        ] = this.tagsAndValues[this._settings.sensorTag][idx];
                }
                break;
            case "SENSOR":
                for (let idx in this.sensorTagValue[this._settings.sensorTag]) {
                    newMappings.push({
                        key: this.sensorTagValue[this._settings.sensorTag][idx].key,
                        value: "#f6f6f6",
                    });
                    this.categoricalValues[
                        this.sensorTagValue[this._settings.sensorTag][idx].key
                        ] = this.sensorTagValue[this._settings.sensorTag][idx];
                }
                break;
            case "ACTUATOR":
                for (let idx in this.actuatorTagValue[this._settings.sensorTag]) {
                    newMappings.push({
                        key: this.actuatorTagValue[this._settings.sensorTag][idx].key,
                        value: "#f6f6f6",
                    });
                    this.categoricalValues[
                        this.actuatorTagValue[this._settings.sensorTag][idx].key
                        ] = this.actuatorTagValue[this._settings.sensorTag][idx];
                }
                break;
        }
    }

    if (!this.categoricalMappings) this.categoricalMappings = newMappings;
    else
        this.categoricalMappings = newMappings.map((m: any) => {
            return this.categoricalMappings.find((nm: any) => {
                return nm.key == m.key;
            })
                ? this.categoricalMappings.find((nm: any) => {
                    return nm.key == m.key;
                })
                : m;
        });

    this.categoricalMappings = [...this.categoricalMappings];
    }

    reloadChart() {

        let frequencies = this.mappings.map((m: RestExt.Pair<string, string>) => {
            return 0;
        });

        this.realTimeDataService.vehicleInfoArr.forEach(
            (v: RestExt.ExtendedVehicleDataMessage) => {
                frequencies[v.category_idx]++;
            }
        );

        let labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
            return m.key;
        });

        if (this.settings.showCategoricalValue) {
            labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
                return this.categoricalValues[m.key][this.userLanguage];
            });
        }

        this.chartData = {
            labels: labels,
            datasets: [
                {
                    data: frequencies,
                    backgroundColor: this.mappings.map(
                        (m: RestExt.Pair<string, string>) => {
                            return m.value;
                        }
                    ),
                },
            ],
        };
    }

    reloadChartManual(newSettings: Rest.SensorChartSettings) {
        this.settings = newSettings;
        this.loadData();
        this.reloadChart();
    }

    loadData() {
    this.categoricalMappings = [];
    this.categoricalValues = {};
    this.numericalMappings = [];

    if (this.settings == null) return;

    for (let key in this.settings.categoricalValuesMappings) {
        this.categoricalMappings.push({
            key: key,
            value: this.settings.categoricalValuesMappings[key].color,
        });
        /*
                When loading the settings from db, assume that the available categoricalValues correspond to the mapings defined in the settings object. Use
                the keyTranslation for each language (assume the user hasn't changed the language)
                */
        let translation =
            this.settings.categoricalValuesMappings[key].keyTranslation;
        this.categoricalValues[key] = <Rest.CategoricalValue>{
            key: key,
            en: translation,
            es: translation,
            fr: translation,
            cat: translation,
        };
    }

    for (let key in this.settings.numericalValuesColors) {
        this.numericalMappings.push({
            key: Number(key),
            value: this.settings.numericalValuesColors[key],
        });
    }
    this.categoricalMappings = [...this.categoricalMappings];
    this.numericalMappings = this.numericalMappings.sort(
        (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
            return a.key - b.key;
        }
    );
    this.numericalMappings = [...this.numericalMappings];
    Object.assign(this._settings, this.settings);

    this.mappings = this.settings.showCategoricalValue
        ? this.categoricalMappings
        : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
            return <RestExt.Pair<string, string>>{
                key: a.key + "",
                value: a.value,
            };
        });
    }

    updateBackingMapping() {
    this.settings.numericalValuesColors = {};
    for (let key in this.numericalMappings) {
        this.settings.numericalValuesColors[this.numericalMappings[key].key] =
            this.numericalMappings[key].value;
    }

    this.settings.categoricalValuesMappings = {};
    for (let key in this.categoricalMappings) {
        this.settings.categoricalValuesMappings[
            this.categoricalMappings[key].key
            ] = <Rest.CategoricalValueMapping>{
            keyTranslation:
                this.categoricalValues[this.categoricalMappings[key].key][
                    this.userLanguage
                    ],
            color: this.categoricalMappings[key].value,
        };
    }
    this.mappings = this.settings.showCategoricalValue
        ? this.categoricalMappings
        : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
            return <RestExt.Pair<string, string>>{
                key: a.key + "",
                value: a.value,
            };
        });
    }
}

const emptySettings = <Rest.SensorChartSettings>{
  id: 0,
  showCategoricalValue: false,
  sensorTag: "",
  categoricalValuesMappings: {},
  numericalValuesColors: {},
  filterType: "SENSOR",
};


