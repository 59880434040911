import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { InsuranceTypeFormComponent } from "../InsuranceType-form/InsuranceType-form.component";
import { InsuranceTypeService } from "app/modules/vehicle-inventory/services/table-management/InsuranceType.service";

import InsuranceType = Rest.InsuranceType;
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import { UploadFileFormComponent } from "../../../documents/upload-file-form/upload-file-form.component";

@Component({
    selector: 'app-insurancetype-list',
    templateUrl: './InsuranceType-list.component.html',
    styleUrls: ['./InsuranceType-list.component.scss'],
    providers: [InsuranceTypeService, ConfirmationService, UserTimePipe, ExcelService],
})
export class InsuranceTypeListComponent
    extends EntityListComponent<InsuranceType>
    implements OnInit {
    title: string = this.i18n._("InsuranceTypes");

    @ViewChild(InsuranceTypeFormComponent, { static: true })
    InsuranceTypeForm: InsuranceTypeFormComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];
    fileFormTitle = "";
    renewalType = "INSURANCE";
    @ViewChild(UploadFileFormComponent) uploadFileForm!: UploadFileFormComponent;

    constructor(
        private InsuranceTypeService: InsuranceTypeService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService
    ) {
        super(
            InsuranceTypeService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.InsuranceTypeService = InsuranceTypeService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.InsuranceTypeForm;
    }

    /**
     * Loads the data for the entity list.
     *
     * @memberof InsuranceTypeListComponent
     */

    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }
    protected afterLoad(): void {
        this.InsuranceTypeService.getPage(this.paginationRequest).then((p) => {
            this.paginationResult = p;
        });

        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((it: any) => {
            it._$noticeChannelsStr = this.arrayToString(it.noticeChannels);

            /*Permissions */
            it._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "InsuranceType"),
                it.id
            ); //This is for the validation of entity-list (selectAll function)
            it._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "InsuranceType"),
                it.id
            );
            /** */
        });

        this.checkSelectAll();
    }

    //EXCEL
    excelColumns = {};
    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.insurance.insuranceTypeManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }
    setExcelColumns() {
        const code = (entity: any) => entity.code ?? ''
        const name = (entity: any) => entity.name ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''

        this.excelColumns = {
            [this.translateService.instant('general.code')]: { bindField: code },
            [this.translateService.instant('general.name')]: { bindField: name },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }
    //UPLOAD FILE
    showFileForm() {
        this.fileFormTitle = this.translateService.instant('tableManagement.insurances');
        this.renewalType = 'INSURANCE';
        this.uploadFileForm.showDialog();
    }
}