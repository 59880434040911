<!-- IO MODAL-->
<div #ioModal class="modal fade io-modal" id="ioModal" aria-hidden="true" aria-labelledby="ioModal"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">

    <!-- IO MODAL CONTENT -->
    <div class="modal-content">

      <!-- IO MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col">
              <span class="modal-title custom-title" i18n>
                IO
              </span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button #closeIOModalButton type="button" class="btn-close m-0 p-0 custom-modal-close-button"
                        (click)="closeIOModal()" data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- IO MODAL BODY -->
      <div class="modal-body">

        <!-- IO MAIN CONTAINER -->
        <div class="container-fluid p-0 ">

          <!-- ROW 1: SENSORS && ACTUATORS -->
          <div class="row m-0">

            <!-- COL 1: SENSORS -->
            <div class="col ms-3 me-3 p-0">
              <div class="container p-0 h-100 io-inner-container">

                <!-- SENSORS CONTAINER HEADER-->
                <div class="container ps-3 pe-3 io-inner-container-header">
                  <div class="d-flex align-items-center h-100">
                    <div class="ps-3 io-header-title">
                      Sensors
                    </div>
                  </div>
                </div>

                <!-- SENSORS CONTAINER BODY -->
                <div class="container ps-3 pe-3 io-inner-container-body">

                  <div class="row" *ngFor="let sensorValue of info.sensors">
                    <div class="col">

                      <span *ngIf="sensorValue.device.type === 'DIGITAL'">
                        {{sensorValue.device.name}} ({{sensorValue.device.tag}}):{{sensorValue.categoricValue}}
                        <p-inputSwitch [disabled]="true" [(ngModel)]="sensorValue.categoricValue"></p-inputSwitch>
                      </span>
                      <span *ngIf="sensorValue.device.type === 'ANALOG'">
                        {{sensorValue.device.name}} ({{sensorValue.device.tag}}):{{sensorValue.categoricValue}}
                        <input type="number" readonly="true" [(ngModel)]="sensorValue.numericValue">
                      </span>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- COL 2: ACTUATORS -->
            <div class="col w-100 p-0">
              <div class="container p-0 h-100 io-inner-container">

                <!-- ACTUATORS CONTAINER HEADER-->
                <div class="container ps-3 pe-3 io-inner-container-header">
                  <div class="d-flex align-items-center h-100">
                    <div class="ps-3 io-header-title">
                      Actuators
                    </div>
                  </div>
                </div>

                <!-- ACTUATORS CONTAINER BODY -->
                <div class="container p-0 io-inner-container-body">

                  <div class="row" *ngFor="let actuatorValue of info.actuators">
                    <div class="col">

                      <span *ngIf="actuatorValue.device.type === 'DIGITAL'">
                      {{actuatorValue.device.name}} ({{actuatorValue.device.tag}}):{{actuatorValue.categoricValue}}
                        <p-inputSwitch [disabled]="true" [(ngModel)]="actuatorValue.categoricValue"></p-inputSwitch>

                      <span *ngIf="actuatorValue.device.type === 'ANALOG'">
                        {{actuatorValue.device.name}} ({{actuatorValue.device.tag}}):{{actuatorValue.categoricValue}}
                        <input type="number" readonly="true" [(ngModel)]="actuatorValue.numericValue">
                      </span>
                    </span>
                      <button (click)="editActuatorValue((actuatorValue))" i18n>Edit</button>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>

      <!-- IO MODAL FOOTER -->
      <div class="modal-footer">
        <div class="d-flex justify-content-center w-100">
          <button class="btn custom-button" i18n>Button</button>
        </div>
      </div>

    </div>

  </div>
</div>

<!-- TODO: SWITCH FROM PRIMENG P-DIALOG TO BOOTSTRAP MODAL -->
<p-dialog i18n-header [header]="edditedActuatorValue.device.name" [visible]=actuatorFormVisible [responsive]="true"
          (onHide)="discardNewActuatorvalue()" appendTo="body"
          showEffect="fade" [modal]="true">

  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid ui-grid-responsive ui-fluid">

      <div *ngIf="edditedActuatorValue.device.type === 'DIGITAL'">
        <div class="ui-grid-row">
          <div class="ui-grid-col-12">
            <span class="label ui-g-3" i18n>Current value</span>
            <span class="label ui-g-9">
                            {{edditedActuatorValue.categoricValue}}
              <p-inputSwitch [(ngModel)]="edditedActuatorValue.categoricValue" [disabled]="true"></p-inputSwitch>
                        </span>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-grid-col-12">
            <span class="label ui-g-3" i18n>New value</span>
            <span class="label ui-g-9">
                        <p-inputSwitch [(ngModel)]="newDigitalActuatorValue"></p-inputSwitch>
                        </span>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-grid-col-12">
            <span class="label ui-g-3" i18n>Toggle</span>
            <span class="label ui-g-9">
                            <p-inputSwitch [(ngModel)]="toggle"></p-inputSwitch>
                            </span>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="toggle">
          <div class="ui-g-12">
            <form-input i18n-fieldName fieldName="Toggle Times">
              <div class="ui-inputgroup">
                <p-spinner id="toggletimes" size="5" [(ngModel)]="toggletimes" [min]="0" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">0-255</span>
                <span class="ui-inputgroup-addon" i18n>times</span>
              </div>
            </form-input>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="toggle">
          <div class="ui-g-12">
            <form-input i18n-fieldName fieldName="Toggle Duration">
              <div class="ui-inputgroup">
                <p-spinner id="toggleInterval" size="5" [(ngModel)]="toggleInterval" [min]="0" [max]="10"></p-spinner>
                <span class="ui-inputgroup-addon">0-10</span>
                <span class="ui-inputgroup-addon" i18n>seconds</span>
              </div>
            </form-input>
          </div>
        </div>
      </div>

      <div *ngIf="edditedActuatorValue.device.type === 'ANALOG'">
        <div class="ui-grid-row">
          <div class="ui-grid-col-12">
            <span class="label ui-g-3" i18n>Current value</span>
            <span class="label ui-g-9">
                            {{edditedActuatorValue.numericValue}} <input type="number" [(ngModel)]="edditedActuatorValue.numericValue"
                                                                         [readonly]="true">
                        </span>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-grid-col-12">
            <span class="label ui-g-3" i18n>New value</span>
            <span class="label ui-g-9">
                        <input type="number" [(ngModel)]="newAnalogActuatorValue">
                        </span>
          </div>
        </div>


      </div>
    </div>
  </div>
  <p-footer>
    <button (click)=setNewActuatorValue() i18n>Set</button>
    <button (click)="discardNewActuatorvalue()" i18n>Discard</button>
  </p-footer>

</p-dialog>
