<div class="container-fluid">

    <!-- HEADER COMPONENT -->
    <reports-header [phase]="selectedPhase" class="mb-4"></reports-header>

    <!-- PHASE1: BODY COMPONENT -->
    <reports-body *ngIf="selectedPhase === 1" (phaseChange)="onPhaseChange($event)" class="mb-4"></reports-body>

    <!--#region PHASE 2: FORMS -->
    <!-- LICENSE REPORT -->
    <app-license-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'DRIVERS_EXPIRATION'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-license-report-form>

    <!-- INSURANCE REPORT -->
    <app-insurance-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'INSURANCE_ACTIVE'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-insurance-report-form>
    <!--#endregion-->

    <!-- VEHICLE ACCESORY REPORT -->
    <app-vehicle-accesory-report-form
        *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'VEHICLE_ACCESORY'" [report]="reportData"
        (phaseChange)="onPhaseChange($event)"></app-vehicle-accesory-report-form>

    <!-- FINES REPORT -->
    <app-fines-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'FINES'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-fines-report-form>

    <!-- VEHICLE CONSUMPTION REPORT -->
    <app-vehicle-consumption-report-form
        *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'VEHICLE_CONSUMPTION'" [report]="reportData"
        (phaseChange)="onPhaseChange($event)"></app-vehicle-consumption-report-form>

    <!-- CARDS REPORT -->
    <app-cards-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'CARD'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-cards-report-form>

    <!-- ITV EXPIRATION REPORT -->
    <app-itv-expiration-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'ITV_EXPIRATION'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-itv-expiration-report-form>

    <!-- VEHICLE EXPIRATION REPORT -->
    <app-vehicle-expiration-report-form
        *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'VEHICLE_EXPIRATION'" [report]="reportData"
        (phaseChange)="onPhaseChange($event)"></app-vehicle-expiration-report-form>

    <!-- VEHICLE REPORT -->
    <app-vehicle-report-form *ngIf="selectedPhase === 2 && reportData.reportData.reportType === 'VEHICLE'"
        [report]="reportData" (phaseChange)="onPhaseChange($event)"></app-vehicle-report-form>

    <!-- #endregion PHASE: 2 FORMS -->

    <!-- PHASE3: REPORT GENERATOR -->
    <app-report-generator [reportName]="selectedReportName" *ngIf="selectedPhase === 3" [report]="reportData"
        (phaseChange)="onPhaseChange($event)"></app-report-generator>

</div>