import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-location-data-upload-configuration-we-track2",
  templateUrl: "./location-data-upload-configuration-we-track2.component.html",
  styleUrls: ["./location-data-upload-configuration-we-track2.component.css"],
})
export class LocationDataUploadConfigurationWeTrack2Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() locationDataUpload: Rest.LocationDataUploadConfigurationWeTrack2;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.locationDataUpload);
    }
  }

  handleChange($event: any) {}
}
