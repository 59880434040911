import { Component, OnInit } from "@angular/core";
import { EntityService } from "../../rest/entity.service";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService, Severity } from "../../services/notifications-service/notifications.service";
import { SimpleForm } from "../simple-form/simple-form";
import { LoadingService } from '../../services/loading-service/loading.service';

@Component({
    selector: "app-entity-form",
    templateUrl: "./entity-form.component.html",
    styleUrls: ["./entity-form.component.css"],
})
export class EntityFormComponent<T> extends SimpleForm<T> implements OnInit {
    constructor(
        private service: EntityService<T>,
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
    ) {
        super(notificationsService, i18n, authenticationService);
    }

    save() {
        this.beforeSave();
        this.messages = [];

        const that = this;
        if (this.isNew) {
            this.service
                .create(this.entity)
                .then(
                    function (res) {
                        that.showModalForm(false);
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.service
                .update(this.entity)
                .then(
                    function (res) {
                        that.showModalForm(false);
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }

    saveWithGenericResp() {
        this.beforeSave();
        this.messages = [];

        const that = this;
        if (this.isNew) {
            this.service
                .createWithGenericResponse(this.entity)
                .then(
                    function (res) {
                        if (res.ok) {
                            that.showModalForm(false);
                            that.return.emit(res.data);
                        }
                        else {
                            that.notificationsService.add(
                                Severity.error,
                                that.i18n._("Error"),
                                res.error?.error_param,
                            );
                        };
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.service
                .updateWithGenericResponse(this.entity)
                .then(
                    function (res) {
                        if (res.ok) {
                            that.showModalForm(false);
                            that.return.emit(res.data);
                        }
                        else {
                            that.notificationsService.add(
                                Severity.error,
                                that.i18n._("Error"),
                                res.error?.error_param,
                            );
                        }
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }


    async changeStatus(status: boolean) {
        const that = this;
        const listIds: number[] = [];

        listIds.push(this.entity["id"]);

        this.service
            .changeStatus(listIds, { status })
            .then(
                function (res) {
                    that.showModalForm(false);
                    that.return.emit(true);
                },
                (err) => {
                    console.log(err);
                    that.handleError(err, that);
                    that.return.emit(false);
                }
            )
            .catch((e) => {
                this.handleError(e, that);
            });

        this.display = false;
    }

    handleError(err, that) {
        that.errors = {};
        if (typeof err === "string") {
            err = JSON.parse(err);
        }
        if (err.errors) {
            for (const i in err.errors) {
                if (err.errors[i].fieldName == "") {
                    that.messages.push({
                        severity: "error",
                        detail: err.errors[i].errorDescription,
                        summary: "",
                    });
                } else {
                    const e = err.errors[i];
                    if (!that.errors[e.fieldName]) {
                        that.errors[e.fieldName] = [];
                    }
                    that.errors[e.fieldName].push(e.errorDescription);
                }
            }
        }
    }
}
