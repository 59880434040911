<p-dialog
	header="{{ title }}"
	[(visible)]="display"
	[responsive]="true"
	showEffect="fade"
	[modal]="true"
	*ngIf="display"
	appendTo="body"
>
	<!-- #region BODY -->
	<div class="container-md">

		<div #errorContainer>
			<div *ngIf='errorMessages.length !== 0'>
				<div *ngFor='let error of errorMessages'>
					<div class='alert alert-warning' role='alert'>{{error}}</div>
				</div>
			</div>
		</div>

		<!-- ISSUER & SANCTION DATE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.fine.issuer' | translate }} (*)</strong>
				</label>
				<div class="p-3"></div>
				<input
					type="text"
					id="fine-issuer"
					class="form-control custom-input"
					[(ngModel)]="entity.issuer"
					[style]="{ width: '300px' }"
					[disabled]="saving"
				/>
			</div>

			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.fine.sanctionDate' | translate }} (*)</strong>
				</label>
				<div class="p-3"></div>
				<p-calendar
					[(ngModel)]="sanctionDate"
					[showTime]="false"
					[style]="{ width: '200px' }"
					dateFormat="{{ i18n.dateFormat }}"
					showIcon="true"
					appendTo="body"
					[disabled]="saving"
				></p-calendar>
			</div>
		</div>

		<!-- REASON & POINTS -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<div class="col d-flex flex-row align-items-center">
					<label for="fine-name" class="custom-label" i18n>
						<strong>{{ 'tableManagement.fine.reason' | translate }} (*)</strong>
					</label>
					<div class="p-3"></div>
					<p-dropdown
						[options]="reasonList"
						[(ngModel)]="entity.reason"
						placeholder="{{ 'tableManagement.fineReason.fineReason' | translate }}"
						dataKey="id"
						optionLabel="reason"
						[style]="{ width: '250px' }"
						appendTo="body"
						[filter]="true"
						filterBy="reason"
						[disabled]="saving"
					>
					</p-dropdown>
				</div>
			</div>

			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.points' | translate }}
				</label>
				<div class="p-3"></div>
				<input
					type="text"
					id="fine-points"
					class="form-control custom-input"
					[(ngModel)]="entity.dgtPoints"
					[disabled]="saving"
				/>
			</div>
		</div>

		<!-- FILE NUMBER & AMOUNT -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.amount' | translate }}
				</label>

				<div class="p-3"></div>

				<div class="input-wrapper">
					<p-inputNumber
						[(ngModel)]="entity.amount"
						inputId="locale-us"
						mode="decimal"
						locale="es-ES"
						[minFractionDigits]="0"
						(onKeyDown)="onKeyDownDecimal($event)"
						[disabled]="saving"
					></p-inputNumber>
					<span class="input-symbol">€</span>
				</div>
			</div>

			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.bonusAmount' | translate }}
				</label>

				<div class="p-3"></div>

				<div class="input-wrapper">
					<p-inputNumber
						[(ngModel)]="entity.bonusAmount"
						inputId="locale-us"
						mode="decimal"
						locale="es-ES"
						[minFractionDigits]="0"
						(onKeyDown)="onKeyDownDecimal($event)"
						[disabled]="saving"
					></p-inputNumber>
					<span class="input-symbol">€</span>
				</div>
			</div>
		</div>

		<!-- BONUS AMOUNT & RESPONSIBLE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<div class="col d-flex flex-row align-items-center">
					<label for="fine-name" class="custom-label" i18n>
						{{ 'tableManagement.fine.responsible' | translate }}
					</label>
					<div class="p-3"></div>
					<p-dropdown
						[options]="responsibleList"
						[(ngModel)]="entity.responsible"
						placeholder="{{ 'tableManagement.fine.responsible' | translate }}"
						dataKey="id"
						[style]="{ width: '250px' }"
						appendTo="body"
						[filter]="true"
						optionLabel="formatResponsibleLabel"
						(onChange)="onChangeResponsible($event)"
						(onFilter)="onFilterResponsible($event)"
						filterBy="formatResponsibleLabel"
						[disabled]="saving"
					>
						<ng-template let-responsible pTemplate="item">
							<div class="flex align-items-center gap-2">
								<div>{{ responsible.surname1 }} {{ responsible.surname2 }}, {{ responsible.name }}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.fine.fileNumber' | translate }} (*)</strong>
				</label>
				<div class="p-3"></div>
				<input
					type="text"
					id="fine-file-number"
					class="form-control custom-input"
					[(ngModel)]="entity.fileNumber"
					[disabled]="saving"
				/>
			</div>
		</div>

		<hr />

		<!-- NOTIFICATION DATE & METHOD -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.notificationDate' | translate }}
				</label>
				<div class="p-3"></div>
				<p-calendar
					[(ngModel)]="notificationDate"
					[showTime]="false"
					[style]="{ width: '200px' }"
					dateFormat="{{ i18n.dateFormat }}"
					showIcon="true"
					appendTo="body"
					[disabled]="saving"
				></p-calendar>
			</div>

			<div class="col d-flex flex-row align-items-center">
				<div class="col d-flex flex-row align-items-center">
					<label for="fine-name" class="custom-label" i18n>
						{{ 'tableManagement.fine.notificationMethod' | translate }}
					</label>
					<div class="p-3"></div>
					<p-dropdown
						[options]="communicationMethods"
						[(ngModel)]="entity.notificationMethod"
						placeholder="{{ 'tableManagement.fine.notificationMethod' | translate }}"
						dataKey="id"
						[style]="{ width: '250px' }"
						appendTo="body"
						[disabled]="saving"
					>
					</p-dropdown>
				</div>
			</div>
		</div>

		<!-- IDENTIFICATION REQUEST DATE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.identificationRequestDate' | translate }}
				</label>
				<div class="p-3"></div>
				<p-calendar
					[(ngModel)]="identityRequestDate"
					[showTime]="false"
					[style]="{ width: '200px' }"
					dateFormat="{{ i18n.dateFormat }}"
					showIcon="true"
					appendTo="body"
					[disabled]="saving"
				></p-calendar>
			</div>
		</div>

		<!-- IDENTIFICATION DATE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.identificationDate' | translate }}
				</label>
				<div class="p-3"></div>
				<p-calendar
					[(ngModel)]="identityDate"
					[showTime]="false"
					[style]="{ width: '200px' }"
					dateFormat="{{ i18n.dateFormat }}"
					showIcon="true"
					appendTo="body"
					[disabled]="saving"
				></p-calendar>
			</div>

			<div class="col d-flex flex-row align-items-center">
				<div class="col d-flex flex-row align-items-center">
					<label for="fine-name" class="custom-label" i18n>
						{{ 'tableManagement.fine.identificationMethod' | translate }}
					</label>
					<div class="p-3"></div>
					<p-dropdown
						[options]="communicationMethods"
						[(ngModel)]="entity.identificationMethod"
						placeholder="{{ 'tableManagement.fine.identificationMethod' | translate }}"
						dataKey="id"
						[style]="{ width: '250px' }"
						appendTo="body"
						[disabled]="saving"
					>
					</p-dropdown>
				</div>
			</div>
		</div>

		<!-- PAID BY DELEGATION DATE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'tableManagement.fine.paidByDelegation' | translate }}
				</label>
				<div class="p-3"></div>
				<p-calendar
					[(ngModel)]="paymentDate"
					[showTime]="false"
					[style]="{ width: '200px' }"
					dateFormat="{{ i18n.dateFormat }}"
					showIcon="true"
					appendTo="body"
					[disabled]="saving"
				></p-calendar>
				<div class="p-3"></div>
				<input
					class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
					type="checkbox"
					[(ngModel)]="entity.paidByDelegation"
					[disabled]="saving"
				/>
			</div>
		</div>

		<hr />

		<!-- STATUS -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.fineStatus.fineStatus' | translate }} (*)</strong>
				</label>
				<div class="p-3"></div>
				<p-dropdown
					[options]="statusList"
					[(ngModel)]="entity.finesStatus"
					placeholder="{{ 'tableManagement.fineStatus.fineStatus' | translate }}"
					dataKey="id"
					optionLabel="name"
					[style]="{ width: '250px' }"
					appendTo="body"
					[filter]="true"
					filterBy="name"
					(onChange)="onChangeStatus($event)"
					(onFilter)="onFilterStatus($event)"
					[disabled]="saving"
				>
				</p-dropdown>
			</div>
		</div>

		<!-- OBSERVATIONS -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'general.observations' | translate }}
				</label>
				<div class="p-3"></div>
				<textarea
					rows="2"
					cols="80"
					pInputTextarea
					[pTooltip]="entity.observations"
					tooltipPosition="top"
					[(ngModel)]="entity.observations"
					class="observations"
					fitContent="true"
					[disabled]="saving"
				></textarea>
			</div>
		</div>

		<div class="mt-3">
			<p-panel header="{{ 'vehicleAssignments.header' | translate }}" class="w-100">
				<div class="row justify-content-start">
					<div class="col d-flex flex-row align-items-center">
						<label for="fine-name" class="custom-label" i18n>
							<strong>{{ 'tableManagement.cardLists.vehicle' | translate }} (*)</strong>
						</label>
						<div class="p-3"></div>
						<p-dropdown
							[options]="vehicleList"
							[(ngModel)]="entity.vehicle"
							[placeholder]="vehiclePlaceholder"
							dataKey="id"
							optionLabel="name"
							[style]="{ width: '250px' }"
							[disabled]="!editVehicle || saving"
							appendTo="body"
							[filter]="true"
							filterBy="name"
							(onChange)="onChangeVehicle($event)"
							(onFilter)="onFilterVehicle($event)"
						>
						</p-dropdown>
					</div>
					<div class="col d-flex flex-row align-items-center">
						<label for="fine-name" class="custom-label" i18n>
							<strong>{{ 'tableManagement.fine.driver' | translate }} (*)</strong>
						</label>
						<div class="p-3"></div>
						<p-dropdown
							[options]="driverList"
							[(ngModel)]="entity.driver"
							placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.fine.driver' | translate }}"
							dataKey="id"
							optionLabel="formatDriverLabel"
							[style]="{ width: '250px' }"
							appendTo="body"
							[filter]="true"
							(onChange)="onChangeDriver($event)"
							(onFilter)="onFilterDriver($event)"
							[disabled]="saving"
						>
							<ng-template let-driver pTemplate="item">
								<div class="flex align-items-center gap-2">
									<div>
										({{ driver.code }}) {{ driver.user.surname1 }} {{ driver.user.surname2 }}
										{{ driver.user.name }}
									</div>
								</div>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
			</p-panel>
		</div>

		<!-- taula de documents -->
		<div class="row mt-3" *ngIf="!isNew">
			<app-doc-table type="fines" [entity]="entity" [nested]="true" [ids]="ids" (return)="refresh()">
			</app-doc-table>
		</div>

		<!-- ACTIVE -->
		<div class="row justify-content-start mt-3">
			<div class="col d-flex flex-row align-items-center">
				<label for="fine-name" class="custom-label" i18n>
					{{ 'control-panel.poi-categories.poiEnabled' | translate }}
				</label>
				<div class="p-3"></div>
				<input
					class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
					type="checkbox"
					[(ngModel)]="active"
					[disabled]="saving"
				/>
			</div>
		</div>
	</div>
	<!-- #endregion BODY -->

	<!-- #region FOOTER -->
	<p-footer>
		<div class="d-flex justify-content-center bd-highlight">
			<div class="p-2 bd-highlight">
				<div *ngIf="isNew && isCreateGranted">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
						{{ saving ? ('general.saving' | translate) : ('general.create' | translate) }}
					</button>
				</div>
				<div *ngIf="!isNew && isUpdateGranted">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
						{{ saving ? ('general.saving' | translate) : ('general.save' | translate) }}
					</button>
				</div>
			</div>
			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew && isDisableGranted">
					<button class="btn custom-button" (click)="changeStatus(!active)" i18n [disabled]="saving">
						{{ 'general.changeStatus' | translate }}
					</button>
				</div>
			</div>
		</div>
	</p-footer>
	<!-- #endregion FOOTER -->
</p-dialog>
