import { Component, OnInit } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { ConversionalgorithmService } from "../../../rest/conversionalgorithm.service";
import { EntityService } from "../../../rest/entity.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import ConversionAlgorithm = Rest.ConversionAlgorithm;

@Component({
    selector: "app-conversion-algorithm-form",
    templateUrl: "./conversion-algorithm-form.component.html",
    styleUrls: ["./conversion-algorithm-form.component.css"],
    providers: [ConversionalgorithmService, ConfirmationService],
})
export class ConversionAlorithmFormComponent
    extends EntityFormComponent<Rest.ConversionAlgorithm>
    implements OnInit {
    availableTypes: SelectItem[];
    title: string;
    codeInputSettings = {lineNumbers: true, mode: "text"};
    displaySample = false;

    constructor(
        private conversionalgorithmService: ConversionalgorithmService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService
    ) {
        super(
            conversionalgorithmService,
            notificationsService,
            i18n,
            authenticationService
        );
        this.availableTypes = RestExt.getEnumItems(RestExt.SensorTypeEnum, i18n);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    beforeShow() {
        if (this.isNew) {
            this.title = this.i18n._("Create Conversion Algorithm");
        } else {
            this.title = this.i18n._("Edit Conversion Algorithm");
        }
    }

    deleteCategoricalValue(categoricalValue) {
        alert(JSON.stringify(categoricalValue));

        this.entity.categoricalValues.forEach((c,index)=>{
            if(c.key === categoricalValue.key && c.cat === categoricalValue.cat && c.en === categoricalValue.en &&
                c.es === categoricalValue.es && c.fr === categoricalValue.fr) this.entity.categoricalValues.splice(index,1);
        });
    }

    addCategoricalValue() {
        this.entity.categoricalValues.push({
            key: "",
            cat: "",
            en: "",
            es: "",
            fr: "",
        } as Rest.CategoricalValue);
        this.entity.categoricalValues = [...this.entity.categoricalValues];
    }

    onBlur(event, categoricalValue, key){
        categoricalValue[key] = event.target.innerText;
    }
}
