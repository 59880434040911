import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { CardService } from 'app/rest/card.service';
import { CardTypeService } from 'app/modules/vehicle-inventory/services/table-management/cardType.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';

@Component({
	selector: 'app-card-form',
	templateUrl: './card-form.component.html',
	styleUrls: ['./card-form.component.css'],
	providers: [CardTypeService, InvVehicleService], //CardService,
	encapsulation: ViewEncapsulation.None,
})
export class CardFormComponent extends EntityFormComponent<Rest.Card> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null; //quan creem una targeta nova des del form de vehicle tindrem el vehicle informat per aquest input

	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;
	expirationDate: Date;
	deassignDate: Date;
	saving: boolean = false;

	active: boolean;

	/*Permissions */
	disableSave: boolean;
	disabeChangeStatus: boolean;

	typeRequestContext: any;
	vehicleRequestContext: any;

	typesList: Rest.CardType[] = [];
	vehicleList: Rest.Vehicle[] = [];
	backupVehicle: Rest.Vehicle;
	vehiclePlaceholder: string;
	errorMessages: string[] = [];
	canCreate: boolean = false;
	canUpdate: boolean = false;
	canChangeStatus: boolean = false;

	constructor(
		private cardService: CardService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public typeService: CardTypeService,
		public vehicleService: InvVehicleService,
		private userTime: UserTimePipe
	) {
		super(cardService, notificationsService, i18n, authenticationService);

		this.cardService = cardService;
	}

	ngOnInit() {
		super.ngOnInit();
		this.setPermissions();
	}

	setPermissions() {
		const user = this.authenticationService.user;
	
		this.canCreate = this.authenticationService.isRoleGranted('INV_CARDS_CREATE', user);
		this.canUpdate = this.authenticationService.isRoleGranted('INV_CARDS_UPDATE', user);
		this.canChangeStatus = this.authenticationService.isRoleGranted('INV_CARDS_DISABLE', user);
	  }

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof CardFormComponent
	 */
	beforeShow() {
		this.active = !this.entity.disabled;

		/*Permissions */
		if (this.isNew) {
			this.disableSave = false;
			this.title = this.translateService.instant('tableManagement.cardLists.createCardList');
			//preinformem vehicle per quan venim de la llista de targetes de un vehicle
			this.entity.vehicle = this.vehicle;
		} else {
			this.title = this.translateService.instant('tableManagement.cardLists.editCardList');
			if (this.entity['_$update'] === true) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
			if (this.entity['_$changeStatus'] === true) {
				this.disabeChangeStatus = false;
			} else {
				this.disabeChangeStatus = true;
			}
		}
		/* Formatt dates */
		this.expirationDate = this.entity.expirationDate
			? this.userTime.utcToCalendarDate(this.entity.expirationDate)
			: null;
		this.deassignDate = this.entity.deassignDate ? this.userTime.utcToCalendarDate(this.entity.deassignDate) : null;

		this.backupVehicle = this.entity.vehicle;

		this.vehiclePlaceholder = this.translateService.instant('general.select');
		this.vehiclePlaceholder += ' ';
		this.vehiclePlaceholder += this.translateService.instant('tableManagement.cardLists.vehicle');

		if (this.entity.vehicle != null && this.entity.vehicle !== undefined) {
			this.vehiclePlaceholder = this.entity.vehicle.name;
		}

		this.typeRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: true,
		} as Rest.ListPaginationRequest;

		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: false,
		} as Rest.ListPaginationRequest;

		if (this.vehicle) {
			this.vehicleRequestContext.filters['name'] = [this.vehicle.name];
		}

		this.typeService.getPage(this.typeRequestContext).then((p) => {
			this.typesList = p.entities;
		});

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	beforeSave() {
		this.entity.expirationDate = this.expirationDate;
		this.entity.deassignDate = this.deassignDate;
		if (this.vehicle) this.entity.assignDate = new Date();
	}

	onChangeVehicle(event) {
		//  console.log('+++ onChangeVehicle');
		this.entity.vehicle = event.value;
	}

	onFilterVehicle(event) {
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {
				name: [event.filter ?? ''],
			},
			loadPermissions: false,
		} as unknown as Rest.ListPaginationRequest;

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	validateCardForm() {
		this.errorMessages = [];
		if (!this.entity.number || this.entity.number === '' || this.entity.number === "0" ||
			 !this.entity.type ||
			 !this.expirationDate ||
			 !this.entity.vehicle) {
			this.errorMessages.push(
				this.translateService.instant('error.required-field.generic'),
			);
			// this.notificationsService.add(
			// 	Severity.error,
			// 	this.translateService.instant(this.isNew ? 'general.createError' : 'updateError') + "!",
			// 	this.translateService.instant('error.required-field.generic'));
			window.scroll({ top: 0, behavior: 'smooth' });
		}
		return this.errorMessages.length === 0;
	}

	saveChanges() {
		this.saving = true;

		if (this.validateCardForm()) {
			this.beforeSave();
			if (this.entity.vehicle) {
				this.entity.vehicle.driverAssignments = null; // info not necessary to send
			}
			if (this.isNew) {
				this.cardService.create(this.entity).then((p) => {
					this.display = false;
					this.saving = false;
					this.return.emit(p);
					this.cardService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
				});
			} else {
				if (this.entity.vehicle === null) {
					this.entity.vehicle = this.backupVehicle;
				}
				this.cardService.update(this.entity).then((p) => {
					this.display = false;
					this.saving = false;
					this.return.emit(p);
					this.cardService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
				});
			}
		}else{
			this.saving = false;
		}
	}
}
