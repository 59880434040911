import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, Subscription } from 'rxjs';

@Injectable()
export class TimerRefreshService {
  private defaultTimerInterval: number = 12000000; // Intervalo por defecto en milisegundos (12000000 -> 2 min)
  private timerSubscription: Subscription;
  private timerSubject = new BehaviorSubject<void>(null);
  public timer$: Observable<void> = this.timerSubject.asObservable();

  constructor() {
    //  this.startTimer(this.defaultTimerInterval);
  }

  public startTimer(timerInterval: number = this.defaultTimerInterval) {
    this.stopTimer(); // Detener el timer actual si está activo

    if (!this.timerSubscription || this.timerSubscription.closed) {
      this.timerSubscription = interval(timerInterval).subscribe(() => {
        this.timerSubject.next();
      });
    }
  }

  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
