import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../notifications-service/notifications.service';
//import { RealTimeDataService } from '../../../services/rt-data/rt-data.service';

@Injectable({
    providedIn: 'root',
})
export class MapGuard implements CanActivate {

    constructor(private auth: AuthenticationService, private router: Router, private notificationService: NotificationsService) {

       // let m = this.router.url.match('.*?/map/sat-map=(.*)');
    
      
       //let m = window.location.href.match('.*?/map/sat-map=(.*)');

     /*  if (m) {
       this.auth.loginWithToken(decodeURIComponent(m[1])).then(() => {
          this.rtDataService.initDetachedMode(); 
          //this.router.navigate(['map']);
          this.router.navigate(['/map/sat-map']);

      });  
    } */
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this.auth.isUserLogged() && this.auth.user != null) {
        if (this.auth.user.canSeeRTDashboard) {
      //    this.rtDataService.initDetachedMode(); 
          return true;     
       }
    }
        this.router.navigate(['/']);
        this.notificationService.add(Severity.error, 'Access Denied', 'You are not authorized to access the page!');
        return false;
    }
   /*
    getUrlParameterByName(name: string, url?: any) {
      if (!url) { url = window.location.href; }
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      if (!results) { return null; }
      if (!results[2]) { return ''; }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } */
}
