<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	dataKey="name"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.mileage.mileagesManagement' | translate }}
		</div>
	</ng-template>
	<!-- #endregion TABLE TITE DEFINITION -->

	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>
			<!-- DATE -->
			<th pSortableColumn="readingDate">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.date' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="readingDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- KMS -->
			<th pSortableColumn="kms">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.mileage.mileage' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="kms"></p-sortIcon>
						<p-columnFilter
							field="kms"
							type="numeric"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- TYPE -->
			<th pSortableColumn="type">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.type' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="type"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="type" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
								[hideOnClear]="true">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">

									<p-multiSelect [ngModel]="value" (onChange)="filter($event.value);" [filter]="true"
										[options]="typeList" placeholder="{{ 'general.filters.any' | translate }}"
										[virtualScroll]="true" itemSize="30" optionLabel="label" optionValue="label"
										[showToggleAll]="false" [panelStyle]="{'min-width': '350px'}">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.label}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- CARD -->
			<th pSortableColumn="consumption.card.number">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.card' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="consumption.card.number"></p-sortIcon>
						<p-columnFilter
							field="card"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- AMOUNT -->
			<th pSortableColumn="consumption.amount">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.fine.amount' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="consumption.amount"></p-sortIcon>
						<p-columnFilter
							field="amount"
							type="numeric"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- LITRES -->
			<th pSortableColumn="consumption.liters">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardsConsumption.litres' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="consumption.liters"></p-sortIcon>
						<p-columnFilter
							field="liters"
							type="numeric"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- C / L -->
			<th pSortableColumn="consumption.literCost">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardsConsumption.literCost' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="consumption.literCost"></p-sortIcon>
						<p-columnFilter
							field="literCost"
							type="numeric"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>
	<!-- #endregion TABLE COLUMN DEFINITION -->

	<!-- #region TABLE BODY -->
	<ng-template pTemplate="body" let-mileage>
		<tr class="p-selectable-row custom-rows" (dblclick)="onEditDialog(mileage)">
			<!-- DATE -->
			<td>
				{{ mileage.readingDate | date : 'dd/MM/yy HH:mm:ss' }}
			</td>

			<!-- MILEAGE -->
			<td>
				{{ mileage.kms }}
			</td>

			<!-- MILEAGE -->
			<td>
				{{ mileage.type }}
			</td>

			<!-- CARD -->
			<td>
				{{ mileage.consumption !== null ? mileage.consumption?.consumptionRef : '' }}
			</td>

			<!-- AMOUNT -->
			<td>
				{{ mileage.consumption !== null ? mileage.consumption?.amount : '' | number: '1.2-2' }}
			</td>

			<!-- LITRES -->
			<td>
				{{ mileage.consumption !== null ? mileage.consumption?.liters : '' | number: '1.2-2'}}
			</td>

			<!-- COST -->
			<td>
				{{ mileage.consumption !== null ? mileage.consumption?.literCost : '' | number: '1.2-2'}}
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE BODY -->

	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="7" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="footer">
		<tr>
			<td colspan="7" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD FINANCE TYPE BUTTON -->
					<app-table-add-button
						[isDisabled]="!isCreateGranted"
						[text]="'tableManagement.mileage.mileage' | translate"
						(btnClick)="create()"
					></app-table-add-button>
					<!-- btn Km Stats -->
					<button
						type="button"
						class="btn custom-button"
						(click)="onShowKmStats()"
						[disabled]="isEditingDisabled"
					>
						{{ 'tableManagement.mileage.mileageSummary' | translate }}
					</button>

					<!-- EXCEL BUTTON -->
					<app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
				</div>
			</td>
		</tr>
	</ng-template>
</p-table>

<app-mileage-form [vehicle]="vehicle"></app-mileage-form>
<app-mileage-stats [vehicle]="vehicle"></app-mileage-stats>
