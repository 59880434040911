<!-- CHANGE STATUS DIALOG -->
<p-dialog [header]=" 'control-panel.statusDialog.title' | translate "
          (onHide)="onHide()"
          [(visible)]="display"
          [responsive]="true"
          [transitionOptions]="'0.2s'"
          [modal]="true"
          [draggable]="false"
          [resizable]="false"
          class="custom-dialog"
>

  <!-- DISABLE ALL -->
  <div *ngIf="!displayEnable && displayDisable" class="pb-3">
    {{ 'control-panel.statusDialog.disableAllSelected' | translate }} {{items}}:
  </div>

  <!-- ENABLE ALL -->
  <div *ngIf="displayEnable && !displayDisable" class="pb-3">
    {{ 'control-panel.statusDialog.enableAllSelected' | translate }} {{items}}:
  </div>

  <!-- CHANGE STATUS -->
  <div *ngIf="displayEnable && displayDisable" class="pb-3">
    {{ 'control-panel.statusDialog.newStatus' | translate }} {{items}}:
  </div>

  <!-- LIST OF OBJECTS -->
  <div class="d-flex flex-column">
    <div *ngFor="let object of entitiesList">
      <ul>
        <li>{{object.nameToShow}}</li>
      </ul>
    </div>
  </div>

  <!-- DIALOG FOOTER -->
  <p-footer>
    <div class="d-flex flex-row justify-content-evenly">
      <button *ngIf="displayEnable" (click)="changeStatus(true)" class="custom-button">
        {{ 'general.enabled' | translate }}
      </button>
      <button *ngIf="displayDisable" (click)="changeStatus(false)" class="custom-button">
        {{ 'general.disabled' | translate }}
      </button>
    </div>
  </p-footer>

</p-dialog>
