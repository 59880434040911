import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import * as xml2js from 'xml2js';

@Injectable()
export class RoutesService extends EntityService<Rest.FidRoute> {

	client: Rest.RestApplicationClient;
	startedEditing = new Subject<Rest.FidRoute>();
	startedCreating = new Subject<Rest.FidSpace>();
	endTranslationEditing = new Subject<Rest.FidRoute>();
	routeSaved = new Subject<Rest.FidCampaigns>();
	orderSaved = new Subject<Rest.FidCampaigns>();

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService,
		private translate: TranslateService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	/** obtenir llista de routes d'un space **/
	public getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.FidRoute>> {
		let queryParams = {
			showPois: true,
			encodeImages: true
		};
		// console.log("[ROUTES-SERVICE] *** getRoutes ****");
		// console.log(arg0);
		// console.log(queryParams);
		return this.client.PromotedRoutes_getRoutes(arg0, queryParams);
	}

	/** obtenir llista de categories **/
	public getCategories(language: Rest.Language): Rest.RestResponse<Rest.RoutesCategory[]> {
		// console.log("[ROUTES-SERVICE] *** getCategories ****");
		let queryParams = {
			lang: language
		};
		return this.client.RoutesCategory_getAll(queryParams);
	}

	/** init Route */
	public initRoute(): Rest.FidRoute {
		return {
			id: null,
			nameRoute: "",
			nameRouteCat: null,
			nameRouteEng: null,
			nameRouteFra: null,
			nameRouteIta: null,
			shortDescription: "",
			shortDescriptionCat: null,
			shortDescriptionEng: null,
			shortDescriptionFra: null,
			shortDescriptionIta: null,
			description: "",
			descriptionCat: null,
			descriptionEng: null,
			descriptionFra: null,
			descriptionIta: null,
			space: null,
			enabled: null,
			datePublication: null,
			numPos: 0,
			category: null,
			totalKms: 0,
			totalTime: "",
			totalTimeCat: null,
			totalTimeEng: null,
			totalTimeFra: null,
			totalTimeIta: null,
			image: null,
			track: null,
			infoLink: "",
			infoLinkCat: null,
			infoLinkEng: null,
			infoLinkFra: null,
			infoLinkIta: null,
			sharedLink: "",
			sharedLinkCat: null,
			sharedLinkEng: null,
			sharedLinkFra: null,
			sharedLinkIta: null,
			activitiesLink: "",
			activitiesLinkCat: null,
			activitiesLinkEng: null,
			activitiesLinkFra: null,
			activitiesLinkIta: null,
			pois: null
		}

	}

	public refreshRoute(translations: Rest.Translations): any {
		// console.log("[ROUTE-SERVICE] **** refrechTranslations ****");
		// console.log(translations);
		let editedRoute: Rest.FidRoute;
		editedRoute = this.initRoute();
		editedRoute.nameRoute = translations.field === "name" ? translations.esp : null;
		editedRoute.nameRouteCat =  translations.field === "name" ? translations.cat : null;
		editedRoute.nameRouteEng = translations.field === "name" ? translations.eng : null;
		editedRoute.nameRouteFra = translations.field === "name" ? translations.fra : null;
		editedRoute.nameRouteIta = translations.field === "name" ? translations.ita : null;
		editedRoute.shortDescription = translations.field === "shortDescription" ? translations.esp : null;
		editedRoute.shortDescriptionCat = translations.field === "shortDescription" ? translations.cat : null;
		editedRoute.shortDescriptionEng = translations.field === "shortDescription" ? translations.eng : null;
		editedRoute.shortDescriptionFra = translations.field === "shortDescription" ? translations.fra : null;
		editedRoute.shortDescriptionIta = translations.field === "shortDescription" ? translations.ita : null;
		editedRoute.description = translations.field === "description" ? translations.esp : null;
		editedRoute.descriptionCat = translations.field === "description" ? translations.cat : null;
		editedRoute.descriptionEng = translations.field === "description" ? translations.eng : null;
		editedRoute.descriptionFra = translations.field === "description" ? translations.fra : null;
		editedRoute.descriptionIta = translations.field === "description" ? translations.ita : null;
		editedRoute.infoLink = translations.field === "infoLink" ? translations.esp : null;
		editedRoute.infoLinkCat = translations.field === "infoLink" ? translations.cat : null;
		editedRoute.infoLinkEng = translations.field === "infoLink" ? translations.eng : null;
		editedRoute.infoLinkFra = translations.field === "infoLink" ? translations.fra : null;
		editedRoute.infoLinkIta = translations.field === "infoLink" ? translations.ita : null;
		editedRoute.sharedLink = translations.field === "sharedLink" ? translations.esp : null;
		editedRoute.sharedLinkCat = translations.field === "sharedLink" ? translations.cat : null;
		editedRoute.sharedLinkEng = translations.field === "sharedLink" ? translations.eng : null;
		editedRoute.sharedLinkFra = translations.field === "sharedLink" ? translations.fra : null;
		editedRoute.sharedLinkIta = translations.field === "sharedLink" ? translations.ita : null;
		editedRoute.activitiesLink = translations.field === "activitiesLink" ? translations.esp : null;
		editedRoute.activitiesLinkCat = translations.field === "activitiesLink" ? translations.cat : null;
		editedRoute.activitiesLinkEng = translations.field === "activitiesLink" ? translations.eng : null;
		editedRoute.activitiesLinkFra = translations.field === "activitiesLink" ? translations.fra : null;
		editedRoute.activitiesLinkIta = translations.field === "activitiesLink" ? translations.ita : null;
		editedRoute.totalTime = translations.field === "totalTime" ? translations.esp : null;
		editedRoute.totalTimeCat = translations.field === "totalTime" ? translations.cat : null;
		editedRoute.totalTimeEng = translations.field === "totalTime" ? translations.eng : null;
		editedRoute.totalTimeFra = translations.field === "totalTime" ? translations.fra : null;
		editedRoute.totalTimeIta = translations.field === "totalTime" ? translations.ita : null;
		this.endTranslationEditing.next(editedRoute);

	}

   public updateRoute(routeData: string, routeLogoFile?: any, routeTrackFile?: any, noToast?: Boolean): Promise<Rest.FidRoute> {

		//  console.log("[ROUTES-SERVICE] *** updateRoute ****");
		//  console.log(routeData);
		//  console.log(routeLogoFile);
		//  console.log(routeTrackFile);
		//  console.log(noToast);

		const formData = new FormData();
		formData.append('image', routeLogoFile);
		formData.append('track', routeTrackFile);
		formData.append('route', routeData);

		return this.http
		.post(environment.webApiBaseUrl + `api/fid/route/save`, formData)
		.toPromise()
		.then((response: any) => {
			if (noToast === null || !noToast) this.notificationsService.add(Severity.success, 'OK', this.translate.instant('general.successMsg'));
			 return response;
		})
		.catch((error) => {
				console.log(error);
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
   }

	public addRoute(routeData: string, routeLogoFile?: any, routeTrackFile?: any): Promise<Rest.FidRoute> {

		// console.log("[ROUTES-SERVICE] *** addRoute ****");
		// console.log(routeData);
		// console.log(routeLogoFile);
		// console.log(routeTrackFile);

		const formData = new FormData();
		formData.append('image', routeLogoFile);
		formData.append('track', routeTrackFile);
		formData.append('route', routeData);

		return this.http
		.post(environment.webApiBaseUrl + `api/fid/route/save`, formData)
		.toPromise()
		.then((response: any) => {
			this.notificationsService.add(Severity.success, 'OK', this.translate.instant('general.successMsg'));
			 return response;
		})
		.catch((error) => {
				console.log(error);
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
   }

	public deleteRoute(data): Promise<Rest.FidRoute> {
		// console.log("[ROUTE-SERVICE] deleteRoute ****");
		// console.log(data);
		return this.http
		.delete(environment.webApiBaseUrl + `api/fid/route/` + data)
		.toPromise()
		.then((response: any) => {
			 return response;
		})
		.catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
	}
}
