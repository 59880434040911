<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade"
          [modal]="true" *ngIf="display">

  <div class="container-md">
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'general.name' | translate }}" [errors]="errors.name">
          <input pInputText id="name" [(ngModel)]="entity.name" />
        </form-input>
      </div>
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.codeBa' | translate }}" [errors]="errors.name">
          <input pInputText id="code" [(ngModel)]="entity.code" />
        </form-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.populationBa' | translate }}" [errors]="errors.name">
          <input pInputText id="population" [(ngModel)]="entity.population" />
        </form-input>
      </div>
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.countryBa' | translate }}" [errors]="errors.name">
          <input pInputText id="country" [(ngModel)]="entity.country" />
        </form-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.provinceBa' | translate }}" [errors]="errors.name">
          <input pInputText id="province" [(ngModel)]="entity.province" />
        </form-input>
      </div>
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.postalCodeBa' | translate }}" [errors]="errors.name">
          <input pInputText id="postalCode" [(ngModel)]="entity.postalCode" />
        </form-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{ 'control-panel.clients.addressBa' | translate }}" [errors]="errors.name">
          <input pInputText id="address" [(ngModel)]="entity.address" />
        </form-input>
      </div>
      <div class="col">
        <form-input [required]="false" i18n-fieldName fieldName="{{ 'control-panel.clients.cif' | translate }}" [errors]="errors.cif">
          <!--  <input pInputText id="cif" pattern="[0-9]{8}[A-Za-z]{1}" [(ngModel)]="entity.cif" /> -->
          <div>
           <input pInputText id="cif" pattern="" [(ngModel)]="entity.cif"/>
           <div *ngIf="!cifValid" class="alert">
            <div >
              dni format error isValidNif
            </div>
   
          </div>
          
         </div>
   
         </form-input>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <form-input i18n-fieldName fieldName="{{'general.enabled' | translate}}" [errors]="errors.enabled">
        <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
      </form-input>
    </div>

  </div>

  <p-footer>
    <div class="d-flex justify-content-center bd-highlight">

      <div class="p-2 bd-highlight">

        <div *ngIf="disableSave === false"> <!--saveBillingAccountForm(entity)-->
          <button type="button" pButton icon="fa fa-check" (click)="saveBillingAccountForm(entity)" i18n-label label="Save"></button>
        </div>

        <div *ngIf="disableSave === true">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa fa-check" (click)="saveBillingAccountForm(entity)" i18n-label label="Save"></button>
          </div>
        </div>

      </div>

      <div class="p-2 bd-highlight">

        <div *ngIf="isNew === false">

          <div *ngIf="disabeChangeStatus === false">
            <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="Change status"></button>
          </div>

          <div *ngIf="disabeChangeStatus === true">
            <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status" tooltipPosition="top">
              <button disabled="true" type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="Change status"></button>
            </div>
          </div>

        </div>

      </div>

    </div>
  </p-footer>

</p-dialog>
