import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-crash-detection-gmt200',
  templateUrl: './crash-detection-gmt200.component.html',
  styleUrls: ['./crash-detection-gmt200.component.css'],
})
export class CrashDetectionGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() craParameters: Rest.CrashDetectionGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  constructor(protected i18n: I18nService) { super(i18n); }

  craOptions: any = [
    {label: "Disable this function",value:"DISABLE"},
    {label: "Enable this function",value:"ENABLE"},
    {label: "The acceleration sensor data will be converted in accordance with three axis self-calibration", value:"MODE2"},       
    {label: "Mostly like Mode 2, the difference is the mode <Fall Degrees> on the Z-axis is checked additionally", value:"MODE3"} 
  ];
  samplingOptions: any = [
    {label: "Start acceleration sampling after power on", value:"ACCELERATION_AFTER_POWER_ON"},
    {label: "Start acceleration sampling after ignition on", value:"ACCELERATION_AFTER_IGNITION_ON"}
  ]
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.craParameters);
    }
  }

}
