import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InsurancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
