import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NoticegroupService } from '../../../rest/noticegroup.service';
import { Rest } from '../../../rest/rest_client';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { EntityListComponent } from '../../entity-list/entity-list.component';
import { NoticeGroupFormComponent } from '../notice-group-form/notice-group-form.component';

@Component({
    selector: 'app-notice-groups-list',
    templateUrl: './notice-groups-list.component.html',
    styleUrls: ['./notice-groups-list.component.css'],
    providers: [NoticegroupService, ConfirmationService, UserTimePipe],
})
export class NoticeGroupsListComponent
    extends EntityListComponent<Rest.NoticeGroup>
    implements OnInit {
    EnabledFilter: SelectItem[];
    noticeGroupService: NoticegroupService;
    title: string = this.i18n._('Notice Groups ');
    noticeGroup: Rest.User;

    @ViewChild(NoticeGroupFormComponent, { static: true })
        /*@ViewChild("dt", { static: false })
        dt: Table;*/
    noticeGroupForm: NoticeGroupFormComponent;

    filteredData = [];

    constructor(
        noticeGroupService: NoticegroupService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        // @ts-ignore
        super(
            noticeGroupService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );

        this.noticeGroupService = noticeGroupService;
        this.fetchEntityBeforeShowUpdateForm = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.hasEntityFilters = true;
        this.form = this.noticeGroupForm;
        /*Permissions */
        this.user = this.authenticationService.user;
        /** */
        this.afterLoad();
        console.log('NOTICE GROUP: ngOnInit');
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters['enabled'],
            this.i18n
        );
        this.filteredData = this.paginationResult.entities;
        console.log('LENGTH: ' + this.paginationResult.entities.length);
        this.paginationResult.entities.forEach((ng: any) => {
            console.log(ng.toString());
            // eslint-disable-next-line prefer-const
            let noticeChannels = [];
            if (ng.notifyBySMS) {
                noticeChannels.push('SMS');
            }
            if (ng.notifyByApp) {
                noticeChannels.push('APP');
            }
            if (ng.notifyByEmail) {
                noticeChannels.push('Email');
            }
            if (ng.notifyByWeb) {
                noticeChannels.push('Web');
            }

            ng.noticeChannelsStr = noticeChannels.join(', ');
            /*Permissions */
            ng._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector('changeStatus', 'Notice Group'),
                ng.id
            );
            ng._$update = this.hasPermission(
                this.permissionsEntityTypeSelector('update', 'Notice Group'),
                ng.id
            );
            /** */
        });

        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    updateFilterName(event) {
        const lowerValue = event.target.value.toLowerCase();
        console.log(lowerValue);

        this.filteredData = this.paginationResult.entities.filter(
            (item) =>
                item.name.toLowerCase().indexOf(lowerValue) !== -1 || !lowerValue
        );
    }

    updateFilterDescription(event) {
        const lowerValue = event.target.value.toLowerCase();
        console.log(lowerValue);

        this.filteredData = this.paginationResult.entities.filter(
            (item) =>
                item.name.toLowerCase().indexOf(lowerValue) !== -1 || !lowerValue
        );
    }

    updateFilterActive(event) {
        const lowerValue = event.target.value.toLowerCase();
        console.log(lowerValue);

        if (lowerValue === 'active') {
            this.filteredData = this.paginationResult.entities.filter(
                (item) => item.enabled === true || !lowerValue
            );
        } else if (lowerValue === 'inactive') {
            this.filteredData = this.paginationResult.entities.filter(
                (item) => item.enabled === false || !lowerValue
            );
        }
    }
}
