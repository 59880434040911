import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { UserService } from 'app/rest/user.service';
import { FinancingService } from 'app/modules/vehicle-inventory/services/table-management/financing.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { WayToPayService } from 'app/rest/way_to_pay.service';
import { ProviderService } from 'app/rest/provider.service';
import { FinanceTypeService } from 'app/modules/vehicle-inventory/services/table-management/financing_type.service';
import { RentingTypeService } from 'app/modules/vehicle-inventory/services/table-management/rentingType.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';

import Financing = Rest.Financing;
import { ActivatedRoute } from '@angular/router';
import { RestExt } from 'app/services/rest-client-extension';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';

@Component({
	selector: 'app-financing-form',
	templateUrl: './financing-form.component.html',
	styleUrls: ['./financing-form.component.scss'],
	providers: [
		UserService,
		WayToPayService,
		ProviderService,
		FinanceTypeService,
		RentingTypeService,
		InvVehicleService,
	],
	encapsulation: ViewEncapsulation.None,
})
export class FinancingFormComponent extends EntityFormComponent<Financing> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null;
	// @Input() startDate?: Date = null;
	// @Input() endDate?: Date = null;
	//@Input() cancellationDate?: Date = null;

	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;
	client: Rest.Client;
	ownVehicle: boolean = false;

	active: boolean;

	/*Permissions */
	disableSave: boolean;
	disabeChangeStatus: boolean;
	file: File;

	//#region FORM CALCULATIONS VARIABLES
	vatPercentage: number = 21;

	// Preservation of relationships
	vehicleRequestContext: Rest.ListPaginationRequest;
	wayToPayRequestContext: Rest.ListPaginationRequest;
	providerRequestContext: Rest.ListPaginationRequest;
	financeTypeRequestContext: Rest.ListPaginationRequest;
	rentingTypeRequestContext: Rest.ListPaginationRequest;
	vehicleReplacedRequestContext: Rest.ListPaginationRequest;

	replacedVehicleList: Rest.Vehicle[] = [];
	vehicleList: Rest.Vehicle[] = [];
	wayToPayList: Rest.WayToPay[] = [];
	providerList: Rest.Provider[] = [];
	financeTypeList: Rest.FinanceType[] = [];
	rentingTypeList: Rest.RentingType[] = [];

	providerFilter: string[] = [];

	ownFinancingTypeId: number = 2;
	ownProviderTypeId: number = 5;
	rentingTypeId: number = 3;
	providerRentingTypeId: number = 4;

	financeTypePlaceholder: string = '';
	rentingTypePlaceholder: string = '';
	providerPlaceholder: string = '';
	wayToPayPlaceholder: string = '';

	ids: number[] = [];

	selectedWayToPay;
	startDateFinancing: Date;
	endDateFinancing: Date;
	cancellationDateFinancing: Date;

	errorMessages: string[] = [];
	saving: boolean = false;
	startDate: Date = null;
	endDate: Date = null;
	cancellationDate: Date = null;
	provider: Rest.Provider = null;

	/* variables per quan es crida el component de Financing des del formulari de vehicles.
	  Podrem preinformar el vehicle en el formulari de creaciï¿½ del financing */
	//@Input() vehicleId?: any = null;

	//PERMISSIONS
	showValues: boolean = false;
	formManipulation: boolean = false;

	constructor(
		protected confirmationService: ConfirmationService,
		private financingService: FinancingService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public userService: UserService,
		public vehicleService: InvVehicleService,
		public wayToPayService: WayToPayService,
		public providerService: ProviderService,
		public financeTypeService: FinanceTypeService,
		public rentingTypeService: RentingTypeService,
		private route: ActivatedRoute,
		private userTime: UserTimePipe,
	) {
		super(financingService, notificationsService, i18n, authenticationService);
	}

	ngOnInit(): void {
		//  console.log("[FINANCING-FORM] ngOninit ***");
		//  console.log(this.vehicle);

		this.client = this.authenticationService.user.client;

		this.vehicleRequestContext = RestExt.firstPageRequest();
		this.vehicleRequestContext.filters['clientId'] = [this.client.id.toString()];

		this.wayToPayRequestContext = RestExt.firstPageRequestVehicleInventory();
		this.providerRequestContext = RestExt.firstPageRequestVehicleInventory();
		this.financeTypeRequestContext = RestExt.firstPageRequestVehicleInventory();
		this.rentingTypeRequestContext = RestExt.firstPageRequestVehicleInventory();

		this.setPermissions();

		// this.getProvidersList();
	}

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof FineFormComponent
	 */
	beforeShow() {
		console.log('[FINANCING-FORM] beforeShow ');
		console.log(this.entity);
		this.getProvidersList();
		this.active = !this.entity.disabled;

		this.ids = [];
		if (this.entity.document && this.entity.document.length > 0) {
			this.entity.document.forEach((x) => this.ids.push(x.id));
		} else {
			this.ids = [-1];
		}

		if (this.vehicle) {
			this.entity.vehicle = this.vehicle;
		}

		if (this.isNew) {
			this.disableSave = false;
			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.financing.createFinancing')
				: this.vehicle.name +
				' ' +
				this.vehicle.model?.brand?.name +
				' - ' +
				this.vehicle.model?.name +
				' - ' +
				this.vehicle.version?.name;
		} else {
			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.financing.editFinancing')
				: this.vehicle.name +
				' ' +
				this.vehicle.model?.brand?.name +
				' - ' +
				this.vehicle.model?.name +
				' - ' +
				this.vehicle.version?.name;

			if (this.entity['_$update'] === true) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
			if (this.entity['_$changeStatus'] === true) {
				this.disabeChangeStatus = false;
			} else {
				this.disabeChangeStatus = true;
			}

			this.ownVehicle = this.entity.financingType.ownVehicle;
			console.log('ownVehicle:', this.ownVehicle);
		}

		this.startDateFinancing = this.entity.startDate ? this.userTime.utcToCalendarDate(this.entity.startDate) : null;
		this.endDateFinancing = this.entity.endDate ? this.userTime.utcToCalendarDate(this.entity.endDate) : null;
		this.cancellationDateFinancing = this.entity.cancellationDate ? this.userTime.utcToCalendarDate(this.entity.cancellationDate) : null;

		//#region PRESERVATION OF RELATIONSHIPS
		//REPLACED VEHICLE & VEHICLE
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			filters:{}
		} as unknown as Rest.ListPaginationRequest;

		if(this.entity.vehicle){
			this.vehicleRequestContext.filters["name"] = [this.entity.vehicle.name];
		}

		this.vehicleReplacedRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			filters:{}
		} as unknown as Rest.ListPaginationRequest;

		if(this.entity.replacedVehicle){
			this.vehicleReplacedRequestContext.filters["name"] = [this.entity.replacedVehicle.name];
		}

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});

		this.vehicleService.getPage(this.vehicleReplacedRequestContext).then((p) => {
			this.replacedVehicleList = p.entities;
		});

		//WAY TO PAY
		this.wayToPayRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 30,
		} as unknown as Rest.ListPaginationRequest;

		let that = this;

		this.wayToPayPlaceholder = this.translateService.instant('general.select');
		this.wayToPayPlaceholder += ' ';
		this.wayToPayPlaceholder += this.translateService.instant('tableManagement.wayToPay.wayToPay');

		this.wayToPayService.getPage(this.wayToPayRequestContext).then((p) => {
			that.wayToPayList = p.entities;

			if (this.isNew && this.wayToPayList.length > 0) {
				this.entity.wayToPay = this.wayToPayList[0];

				this.wayToPayList.forEach((t) => {
					if (t.isdefault) {
						this.entity.wayToPay = t;
					}
				});

				this.wayToPayPlaceholder = this.entity.wayToPay.name;
			}
		});

		//FINANCE TYPE
		this.financeTypeRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
		} as unknown as Rest.ListPaginationRequest;

		this.financeTypePlaceholder = this.translateService.instant('general.select');
		this.financeTypePlaceholder += ' ';
		this.financeTypePlaceholder += this.translateService.instant('tableManagement.financing.financingType');

		this.financeTypeService.getPage(this.financeTypeRequestContext).then((p) => {
			this.financeTypeList = p.entities;

			/*if (this.isNew) {
				this.entity.financingType = this.financeTypeList[0];
				this.financeTypePlaceholder = this.entity.financingType.name;
			}*/
		});

		//RENTING TYPE
		this.rentingTypeRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
		} as unknown as Rest.ListPaginationRequest;

		this.rentingTypePlaceholder = this.translateService.instant('general.select');
		this.rentingTypePlaceholder += ' ';
		this.rentingTypePlaceholder += this.translateService.instant('tableManagement.rentingTypes.rentingType');

		this.rentingTypeService.getPage(this.rentingTypeRequestContext).then((p) => {
			this.rentingTypeList = p.entities;

			if (this.isNew && this.rentingTypeList.length > 0) {
				this.entity.rentingType = this.rentingTypeList[0];

				this.rentingTypeList.forEach((t) => {
					if (t.isdefault) {
						this.entity.rentingType = t;
					}
				});

				this.rentingTypePlaceholder = this.entity.rentingType.name;
			}
		});
		//#endregion PRESERVATION OF RELATIONSHIPS

		if (this.entity['_$update'] === true) {
			this.disableSave = false;
		} else {
			this.disableSave = true;
		}
		if (this.entity['_$changeStatus'] === true) {
			this.disabeChangeStatus = false;
		} else {
			this.disabeChangeStatus = true;
		}

		this.calculateFinancialFigures();

		this.providerPlaceholder = this.translateService.instant('general.select');
		this.providerPlaceholder += ' ';
		this.providerPlaceholder += this.translateService.instant('tableManagement.providers.provider');

		if (this.entity.provider !== null && this.entity.provider !== undefined) {
			this.providerPlaceholder = this.entity.provider.name;
		}
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['INV_INSURANCE_CREATE', 'INV_INSURANCE_UPDATE'];

		this.showValues = this.authenticationService.isRoleGranted('INV_FINANCING_COSTS_READ', user);
		this.formManipulation = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	public showDialog(isNew: boolean) {
		this.isNew = isNew;
		this.entity = {
			id: 0,
			contract: '',
			interestRate: null,
			contractKm: null,
			monthlyFee: null,
			startDate: null,
			endDate: null,
			investmentCost: null,
			excessCost: null,
			buyBackValue: null,
			kmAdjustment: null,
			pffValue: null,
			optionsValue: null,
			extrasValue: null,
			taxBaseValue: null,
			vatValue: null,
			totalValue: null,
			cancellationDate: null,
			cancellationKm: null,
			cancellationValue: null,
			amortizationPeriod: null,
			observations: '',
			disabled: false,
			document: null,
			replacedVehicle: null,
			wayToPay: null,
			vehicle: this.vehicle,
			provider: null,
			financingType: null,
			rentingType: null,
			client: this.client,
		};
		// this.findVehicle(this.vehicleId);
		this.beforeShow();
		this.display = true;
	}

	onKeyDownDecimal(event: any) {
		var pressedKey = event.key;
		const keyMap = new Map<number, string>();
		keyMap.set(110, ',');
		const { keyCode } = event;
		if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
			event.preventDefault();
			event.target.value += ',';
		}
	}

	/**
	 * Calculate and update financial values for the entity.
	 */
	calculateFinancialFigures() {
		this.updateTaxBaseValue();
		this.calculateVatValue();
		this.calculateTotalValue();
		this.formatDecimalValues();
	}

	/**
	 * Update the tax base value based on the PFF, options, and extras values.
	 */
	updateTaxBaseValue() {
		this.entity.taxBaseValue = this.entity.pffValue + this.entity.optionsValue + this.entity.extrasValue;
	}

	/**
	 * Calculate the VAT value based on the tax base value and VAT percentage.
	 */
	calculateVatValue() {
		this.entity.vatValue = this.entity.taxBaseValue * (this.vatPercentage / 100);
	}

	/**
	 * Calculate the total value including tax base and VAT.
	 */
	calculateTotalValue() {
		this.entity.totalValue = this.entity.taxBaseValue + this.entity.vatValue;
	}

	/**
	 * Formats values to two decimal places.
	 */
	formatDecimalValues() {
		this.entity.vatValue = this.toFixedDecimal(this.entity.vatValue);
		this.entity.totalValue = this.toFixedDecimal(this.entity.totalValue);
	}

	/**
	 * Convert a number to a fixed decimal format.
	 * @param {number} value - The value to format.
	 * @return {number} The value formatted to two decimal places.
	 */
	toFixedDecimal(value: number): number {
		return parseFloat(value.toFixed(2));
	}

	/**
	 * Handle input changes and recalculate values.
	 */
	onValuesChange(event) {
		this.calculateFinancialFigures();
	}

	onFileSelected(event) {
		this.file = event.target.files[0];
	}

	validateFinancingForm() {
		this.errorMessages = [];

		if (
			!this.entity.financingType ||
			!this.entity.provider ||
			!this.entity.contract ||
			!this.startDateFinancing ||
			!this.entity.wayToPay
		) {
			this.errorMessages.push(this.translateService.instant('error.required-field.generic'));
			// this.notificationsService.add(
			// 	Severity.error,
			// 	this.translateService.instant(this.isNew ? 'general.createError' : 'updateError') + '!',
			// 	this.translateService.instant('error.required-field.generic')
			// );
			window.scroll({ top: 0, behavior: 'smooth' });
			this.saving = false;
		}
		return this.errorMessages.length === 0;
	}

	saveChanges() {
		this.saving = true;
		var that = this;
		this.beforeSave();
		this.entity.client = this.authenticationService.user.client;

		if (this.validateFinancingForm()) {
			// if (this.entity.provider === null || this.entity.provider === undefined) {
			// 	this.confirmationService.confirm({
			// 		message: this.translateService.instant('tableManagement.financing.providerEmpty'),
			// 		accept: () => {},
			// 	});
			// 	return;
			// }

			// if (this.entity.vehicle === null || this.entity.vehicle === undefined) {
			// 	this.confirmationService.confirm({
			// 		message: this.translateService.instant('tableManagement.financing.vehicleEmpty'),
			// 		accept: () => {},
			// 	});
			// 	return;
			// }

			// if (this.entity.startDate === null || this.entity.startDate === undefined) {
			// 	this.confirmationService.confirm({
			// 		message: this.translateService.instant('tableManagement.financing.startDateEmpty'),
			// 		accept: () => {},
			// 	});
			// 	return;
			// }

			if (this.isNew) {
				let financingString = JSON.stringify(this.entity);
				this.financingService
					.createFinancing(financingString, null)
					.then((p) => {
						that.display = false;
						this.saving = false;
						//that.file = null;
						that.financingService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
						that.return.emit(p);
						//if (that.vehicleId) that.financingService.created.next(p); //si venim del form de vehicle
					})
					.catch((error) => {
						that.notificationsService.add(Severity.error, 'Error', error.message);
						return null;
					});
			} else {
				let financingString = JSON.stringify(this.entity);
				this.financingService
					.updateFinancing(financingString, null)
					.then((p) => {
						that.display = false;
						that.saving = false;
						that.file = null;
						that.financingService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
						that.return.emit(p);
					})
					.catch((error) => {
						that.notificationsService.add(Severity.error, 'Error', error.message);
						return null;
					});
			}
		}
	}

	//   onDocumentNameChange(newValue: any) {
	//     if (!this.entity.document) {
	//       this.entity.document = {} as Rest.InvDocument;
	//     }
	//     this.entity.document.name = newValue;
	//   }

	toLocalISOString(date: Date) {
		const offset = date.getTimezoneOffset();
		const localDate = new Date(date.getTime() - offset * 60 * 1000);
		return localDate.toISOString();
	}

	findVehicle(vehicleId) {
		// console.log('[FINANCING-FORM] findVehicle ***');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.vehicleService
				.findVehicle(this.vehicle.id.toString())
				.then(
					(response: Rest.Vehicle) => {
						if (response) {
							that.entity.vehicle = response;
							// console.log(that.entity);
						} else {
							// console.log('No vehicle!');
							resolve();
						}
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	onChangeReplacedVehicle(event) {
		this.entity.replacedVehicle = event.value;
	}

	onFilterReplacedVehicle(event) {
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
		} as unknown as Rest.ListPaginationRequest;

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.replacedVehicleList = p.entities;
		});
	}

	onChangeVehicle(event) {
		this.entity.vehicle = event.value;
	}

	onFilterVehicle(event) {
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
		} as unknown as Rest.ListPaginationRequest;

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	onChangeWayToPay(event) {
		this.entity.wayToPay = event.value;
	}

	onFilterWayToPay(event) {
		this.wayToPayRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
		} as unknown as Rest.ListPaginationRequest;

		this.wayToPayService.getPage(this.wayToPayRequestContext).then((p) => {
			this.wayToPayList = p.entities;
		});
	}

	onChangeProvider(event) {
		this.entity.provider = event.value;
	}

	// onFilterProvider() {
	//   this.providerRequestContext = {
	//     sortBy: "name",
	//     sortAsc: true,
	//     pageSize: 10,
	//   } as unknown as Rest.ListPaginationRequest;

	//   this.providerService.getPage(this.providerRequestContext).then((p) => {
	//     this.providerList = p.entities;
	//   });
	// }

	getProvidersList() {
		var providerRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			filters: {},
			pageSize: 100,
			loadPermissions: true,
		} as unknown as Rest.ListPaginationRequest;

		if (this.entity.financingType) {
			this.providerRequestContext.filters["type"] = this.entity.financingType.ownVehicle ?
				[RestExt.ProviderType.Concesionario.toString()] :
				[RestExt.ProviderType.Renting.toString()];
		}
		this.providerList = [];

		this.providerService.getPage(this.providerRequestContext).then((p) => {
			console.log(p.entities);
			this.providerList = p.entities;

			// p.entities.forEach((t) => {
			// 	// propio - concesionario
			// 	if (this.entity.financingType.id === this.ownFinancingTypeId) {
			// 		if (t.type.id === this.ownProviderTypeId) {
			// 			this.providerList.push(t);
			// 		}
			// 	} else if (this.entity.financingType.id === this.rentingTypeId) {
			// 		if (t.type.id === this.providerRentingTypeId) {
			// 			this.providerList.push(t);
			// 		}
			// 	} else {
			// 		this.providerList.push(t);
			// 	}

			// 	// var requiredTypeId = this.ownVehicle ? 5 : 4;
			// 	// if (t.type.id === requiredTypeId) {
			// 	//   if (this.providerFilter.length > 0) {
			// 	//     if (this.providerFilter.includes(t.name)) {
			// 	//       this.providerList.push(t);
			// 	//     }
			// 	//   } else {
			// 	//     this.providerList.push(t);
			// 	//   }
			// 	// }
			// });
			//this.providerList.sort((a, b) => (a.name > b.name ? 1 : -1));
		});
	}

	onChangeFinanceType(event) {
		console.log('[FINANCING-FORM] onChangeFinanceType ');
		this.providerPlaceholder = this.translateService.instant('general.select');
		this.providerPlaceholder += ' ';
		this.providerPlaceholder += this.translateService.instant('tableManagement.providers.provider');

		this.ownVehicle = event.value.ownVehicle;
		//console.log('ownVehicle:', this.ownVehicle);

		this.getProvidersList();

		this.entity.financingType = event.value;
	}

	onFilterFinanceType(event) {
		this.financeTypeRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
		} as unknown as Rest.ListPaginationRequest;

		this.financeTypeService.getPage(this.financeTypeRequestContext).then((p) => {
			this.financeTypeList = p.entities;
		});
	}

	onChangeRentingType(event) {
		this.entity.rentingType = event.value;
	}

	onFilterRentingType() {
		this.rentingTypeRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
		} as unknown as Rest.ListPaginationRequest;

		this.rentingTypeService.getPage(this.rentingTypeRequestContext).then((p) => {
			this.rentingTypeList = p.entities;
		});
	}

	refresh() {
		// console.log("[FINANCING-FORM] refresh");
		this.return.emit();
	}

	duplicate(financing) {
		this.isNew = true;
		this.entity = {} as Rest.Financing;
		this.entity.amortizationPeriod = financing.amortizationPeriod;
		this.entity.buyBackValue = financing.buyBackValue;
		this.entity.cancellationKm = financing.cancelationKm;
		this.entity.cancellationValue = financing.cancelationValue;
		this.entity.cancellationDate = financing.cancellationDate;
		this.entity.client = financing.client;
		this.entity.id = 0;
		this.entity.document = null;
		this.entity.contract = financing.contract + ' (DUP)';
		this.entity.contractKm = financing.contractKm;
		this.entity.disabled = financing.disabled;
		this.entity.endDate = financing.endDate;
		this.entity.excessCost = financing.excessCost;
		this.entity.extrasValue = financing.extrasValue;
		this.entity.financingType = financing.financingType;
		this.entity.interestRate = financing.interestRate;
		this.entity.investmentCost = financing.investmentCost;
		this.entity.kmAdjustment = financing.kmAdjustment;
		this.entity.monthlyFee = financing.monthlyFee;
		this.entity.observations = financing.observations;
		this.entity.optionsValue = financing.optionsValue;
		this.entity.pffValue = financing.pffValue;
		this.entity.provider = financing.provider;
		this.entity.rentingType = financing.rentingType;
		this.entity.replacedVehicle = financing.replacedVehicle;
		this.entity.startDate = financing.startDate;
		this.entity.taxBaseValue = financing.taxBaseValue;
		this.entity.totalValue = financing.totalValue;
		this.entity.vatValue = financing.vatValue;
		this.entity.vehicle = financing.vehicle;
		this.entity.wayToPay = financing.wayToPay;
		this.beforeShow();
		this.display = true;
	}

	beforeSave() {
		this.entity.startDate = this.startDateFinancing;
		this.entity.endDate = this.endDateFinancing;
		this.entity.cancellationDate = this.cancellationDateFinancing;
		this.entity.disabled = !this.active;
		console.log(this.entity);
	}
}
