import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { RoutesService } from './routes.service';
import { Subject } from 'rxjs/internal/Subject';
import { CampaignService } from './campaign.service';

@Injectable()
export class TranslationsService extends EntityService<Rest.Translations>  {

	startEditTranslations = new Subject<Rest.Translations>();

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService,
		public campaignService: CampaignService,
		public routesService: RoutesService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	public refreshTranslations(translations: Rest.Translations): any {
		// console.log("[TRANSLATION-SERVICE] **** refreshTranslations ****");
		// console.log(translations);
		switch(translations.type) {
			case "campaign":
				return this.campaignService.refreshCampaign(translations);
			case "route":
				return this.routesService.refreshRoute(translations);
		}
	}
}
