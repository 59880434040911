import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { SelectItem } from "primeng/api";

import { Rest } from "../../rest/rest_client";
import { I18nService } from "../../services/i18n/i18n.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "paged-picklist",
  templateUrl: "./paged-picklist.html",
  styleUrls: ["./paged-picklist.css"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PagedPickList<T> implements OnInit, OnChanges {
  /**
   *
   *
   * sample usage:
   *
   * <paged-picklist [pageFunction]="this.maintenanceServ.getPage.bind(this.deviceService)" labelProperty="name" [(rightListEntities)]="maintenances" ></paged-picklist>
   *
   */

  /**Optional pagination requests object containing some pre-set filters and sort options. Please note that the filter corresponding to labelProperty will be overriden   */
  @Input() paginationRequest: Rest.ListPaginationRequest;
  /*the user input text will be sent to the pageFunction using this filter dictionary key*/
  @Input() labelProperty = "name";

  //list of entities in the right list
  @Input() rightListEntities: T[];
  @Output() protected rightListEntitiesChange: EventEmitter<T[]> =
    new EventEmitter();
  //** */
  /**function that implements the pagging
   *
   example:

  [pageFunction]="this.profileService.getPage.bind(this.profileService)"

  this.profileService refers to an atribute of the component using the paged-picklist component
  .bind(xxx) is used to solve the problem regardin the "this" keyword used inside the function
   *
   *
   */
  @Input() pageFunction: (
    req: Rest.ListPaginationRequest
  ) => Rest.RestResponse<Rest.Page<T>>;

  searchLeftText: string;
  availableLeft: SelectItem[];
  selectedLeft: T[];

  searchRightText: string;
  availableRight: SelectItem[];
  selectedRight: T[];
  private rightFullList: SelectItem[];
  hasMore: boolean;
  constructor(protected i18n: I18nService) {}

  ngOnChanges(changes) {
    if (changes.rightListEntities || changes.labelProperty) {
      //const tentativeFullRight = this.rightListEntities ? this.rightListEntities.map((e: any) => ({ label: e[this.labelProperty], value: e })) : [];
      var tentativeFullRight;

      if (
        this.rightListEntities != undefined &&
        this.rightListEntities.length > 0
      ) {
        this.rightListEntities.forEach((e: any) => {
          if (this.labelProperty.indexOf("|") >= 0) {
            var splitted = this.labelProperty.split("|");
            var a;

            for (a = 0; a < splitted.length; a++) {
              if (a == 0) {
                e._$labelProperty =
                  e[splitted[a].trim()] == null
                    ? " " + " | "
                    : e._$labelProperty + e[splitted[a].trim()] + " | ";
              }
              if (a < splitted.length - 1 && a > 0) {
                e._$labelProperty =
                  e[splitted[a].trim()] == null
                    ? e._$labelProperty + " | "
                    : e._$labelProperty + e[splitted[a].trim()] + " | ";
              }
              if (a == splitted.length - 1) {
                e._$labelProperty =
                  e[splitted[a].trim()] == null
                    ? e._$labelProperty
                    : e._$labelProperty + e[splitted[a].trim()];
              }
            }
          } else {
            e._$labelProperty = e[this.labelProperty];
          }

          tentativeFullRight = this.rightListEntities.map((e: any) => ({
            label: e._$labelProperty,
            value: e,
          }));
        });
      } else {
        tentativeFullRight = [];
      }

      //Detect if they have been changes in the full right list
      if (
        this.rightFullList != null &&
        tentativeFullRight.length == this.rightFullList.length
      ) {
        let hasChanges = false;
        tentativeFullRight.forEach((v) => {
          hasChanges = hasChanges
            ? hasChanges
            : tentativeFullRight.findIndex((v2) => v.value.id == v2.value.id) ==
              -1;
        });
        if (!hasChanges) {
          return;
        }
      }
      this.rightFullList = tentativeFullRight;
      this.availableRight = [...this.rightFullList];
      this.reset();
    }
  }

  reset() {
    this.hasMore = false;
    if (this.paginationRequest == null) {
      if (this.labelProperty == "name") {
        this.paginationRequest = {
          sortBy: this.labelProperty,
          sortAsc: true,
          //pageSize: 10,
          filters: {},
        } as Rest.ListPaginationRequest;
      } else {
        this.paginationRequest = {
          sortAsc: true,
          //pageSize: 10,
          filters: {},
        } as Rest.ListPaginationRequest;
      }
    }
    this.searchLeftText = "";
    this.selectedLeft = [];

    this.searchRightText = "";
    this.selectedRight = [];

    this.searchLeft("");
  }

  add() {
    this.addItemsToRightList(this.selectedLeft);
    this.selectedLeft = [];
    //clear the right search
    this.searchRightText = "";
    this.searchRight("");

    this.doSearchLeft();
  }

  remove() {
    this.removeItemsFromRightList(this.selectedRight);
    this.selectedRight = [];

    //clear the right search
    this.searchRightText = "";
    this.searchRight("");

    this.doSearchLeft();
  }

  leftItemDblClick(item: any) {
    item = item.value[0];
    this.addItemsToRightList([item]);
    //clear the right search
    this.searchRightText = "";
    this.searchRight("");

    //reload the left
    this.doSearchLeft();
  }

  rightItemDblClick(item: any) {
    item = item.value[0];
    this.removeItemsFromRightList([item]);
    //clear the right search
    this.searchRightText = "";
    this.searchRight("");

    //reload the left
    this.doSearchLeft();
  }

  /**
   * add the given list of select item to the right list
   * @param items
   */
  addItemsToRightList(items: T[]) {
    //add the items
    //for (const i in items) { this.rightFullList.push({ label: items[i][this.labelProperty], value: items[i] }); }
    for (const i in items) {
      this.rightFullList.push({
        label: items[i]["_$labelProperty"],
        value: items[i],
      });
    }
    //sort the list
    this.rightFullList = this.rightFullList.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    this.availableRight = [...this.rightFullList];
    this.rightListEntitiesChange.emit(this.rightFullList.map((e) => e.value));
  }

  /**
   * remove the given list of select item from the right list
   * @param items
   */
  removeItemsFromRightList(items: T[]) {
    //remove the items
    for (const i in items) {
      this.rightFullList.splice(
        this.rightFullList.findIndex((e) => e.value.id == (items[i] as any).id),
        1
      );
    }
    this.rightListEntitiesChange.emit(this.rightFullList.map((e) => e.value));
  }

  searchLeft(newValue) {
    //new
    /*if (this.labelProperty.indexOf('|') < 0) {
      this.paginationRequest.pageSize = 10;
    }*/

    if (newValue.trim().length > 0) {
      this.paginationRequest.filters[this.labelProperty] = [];
      this.paginationRequest.filters[this.labelProperty].push(newValue);
    } else {
      delete this.paginationRequest.filters[this.labelProperty];
    }
    //Reset page size
    this.paginationRequest.pageSize = 10;
    this.doSearchLeft();
  }

  more() {
    this.paginationRequest.pageSize += 10;
    this.doSearchLeft();
  }

  doSearchLeft() {
    const that = this;
    this.paginationRequest.loadPermissions = false;
    this.pageFunction(this.paginationRequest).then(function (response) {
      //that.availableLeft = response.entities.map((e: any) => ({ label: e[that.labelProperty], value: e }));

      response.entities.forEach((e: any) => {
        if (that.labelProperty.indexOf("|") >= 0) {
          var splitted = that.labelProperty.split("|");
          var a;

          for (a = 0; a < splitted.length; a++) {
            if (a == 0) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? " " + " | "
                  : e._$labelProperty + e[splitted[a].trim()] + " | ";
            }
            if (a < splitted.length - 1 && a > 0) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? e._$labelProperty + " | "
                  : e._$labelProperty + e[splitted[a].trim()] + " | ";
            }
            if (a == splitted.length - 1) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? e._$labelProperty
                  : e._$labelProperty + e[splitted[a].trim()];
            }
          }
        } else {
          e._$labelProperty = e[that.labelProperty];
        }
      });
      that.availableLeft = response.entities.map((e: any) => ({
        label: e._$labelProperty,
        value: e,
      }));

      //filter out the options already present in the right list
      that.availableLeft = that.availableLeft.filter((e: any) => {
        return (
          that.rightFullList.find((a: any) => a.value.id == e.value.id) == null
        );
      });

      that.hasMore =
        response.filteredEntities > that.paginationRequest.pageSize;
      that.availableLeft = [...that.availableLeft];
      //that.hasMore = response.entities.length < response.filteredEntities;
      //restrict the selected left items only to those visible (availableLeft)
      const selectedMatching = that.selectedLeft.filter((e: any) => {
        return that.availableLeft.find((a: any) => a.value.id == e.id) != null;
      });
      that.selectedLeft = [...selectedMatching];
      //If there are more options but the left column is empty. Load them autmatically
      if (that.hasMore && that.availableLeft.length == 0) {
        that.paginationRequest.pageSize += 10;
        that.doSearchLeft();
      }
    });
  }

  searchRight(newValue: string) {
    //compute the items in the right full list that match the search
    const matching =
      newValue.trim().length != 0
        ? this.rightFullList.filter(
            (e: SelectItem) =>
              e.label != null &&
              e.label.toLowerCase().indexOf(newValue.toLowerCase()) > -1
          )
        : this.rightFullList;
    this.availableRight = [...matching];

    //restrict the selected right items only to those visible (availableRight)
    const selectedMatching = this.selectedLeft.filter((e: any) => {
      return this.availableLeft.find((a: any) => a.value.id == e.id) != null;
    });
    this.selectedRight = [...selectedMatching];
  }

  ngOnInit() {
    this.reset();
  }
}
