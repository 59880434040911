import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { DelegationFormComponent } from '../delegation-form/delegation-form.component';
import { DelegationService } from "app/modules/vehicle-inventory/services/table-management/delegation.service";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

import Delegation = Rest.Delegation;

@Component({
    selector: 'app-delegation-list',
    templateUrl: './delegation-list.component.html',
    styleUrls: ['./delegation-list.component.css'],
    providers: [ConfirmationService, UserTimePipe, ExcelService],
})
export class DelegationListComponent
    extends EntityListComponent<Delegation>
    implements OnInit {
    title: string = this.i18n._("Delegations");

    @ViewChild(DelegationFormComponent, { static: true })
    delegationForm: DelegationFormComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];


    constructor(
        private delegationService: DelegationService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService
    ) {
        super(
            delegationService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.delegationService = delegationService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.delegationForm;
        this.hasEntityFilters = false;
    }

    protected beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    afterLoad() {
        // this.EnabledFilter = RestExt.getEnabledFilter(
        //     this.filters["enabled"],
        //     this.i18n
        // );
        this.paginationResult.entities.forEach((dl: any) => {
            dl._$noticeChannelsStr = this.arrayToString(dl.noticeChannels);
            /*Permissions */
            dl._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "Delegation"),
                dl.id
            ); //This is for the validation of entity-list (selectAll function)
            dl._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "Delegation"),
                dl.id
            );
            /** */
        });

        this.checkSelectAll();
    }
    excelColumns = {};
    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.delegations.delegationsManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }
    setExcelColumns() {
        const code = (entity: any) => entity.code ?? ''
        const name = (entity: any) => entity.name ?? ''
        const fleetManagerName = (entity: any) => entity.fleetManager?.name ?? ''
        const serviceManagerName = (entity: any) => entity.serviceManager?.name ?? ''
        const zoneLeaderName = (entity: any) => entity.zoneLeader?.name ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''

        this.excelColumns = {
            [this.translateService.instant('general.code')]: { bindField: code },
            [this.translateService.instant('general.name')]: { bindField: name },
            [this.translateService.instant('tableManagement.delegations.fleetManager')]: { bindField: fleetManagerName },
            [this.translateService.instant('tableManagement.delegations.serviceManager')]: { bindField: serviceManagerName },
            [this.translateService.instant('tableManagement.delegations.zoneChief')]: { bindField: zoneLeaderName },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }
}
