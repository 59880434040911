import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Rest } from '../../../../../../rest/rest_client';
import { AuthenticationService } from '../../../../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../../../../services/rest-client-extension';
import { EntityListComponent } from '../../../../../../components/entity-list/entity-list.component';
import { CardFormComponent } from '../card-form/card-form.component';
import { CardService } from 'app/rest/card.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { UploadFileFormComponent } from '../../../documents/upload-file-form/upload-file-form.component';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
@Component({
	selector: 'app-card-lists',
	templateUrl: './card-lists.component.html',
	styleUrls: ['./card-lists.component.css'],
	providers: [ConfirmationService, UserTimePipe, ExcelService],
})
export class CardListsComponent extends EntityListComponent<Rest.Card> implements OnInit, OnDestroy {
	@Input() vehicle?: any;
	@Input() startDate?: Date;
	@Input() endDate?: Date;
	@Input() nested?: Boolean = false;
	@Input() onlyAvailable?: Boolean = false;

	title: string = this.i18n._('Card list');

	@ViewChild(CardFormComponent, { static: true })
	editForm: CardFormComponent;
	isEditingDisabled: Boolean = false;

	user: Rest.User;
	EnabledFilter: SelectItem[];

	filteredCards: Rest.Card[];
	availableCardsList: Rest.Card[];
	selectedAvailableCard: Rest.Card = null;
	showFilteredCards: boolean = false;
	availableCardListPlaceHolder: string = '';

	@ViewChild(UploadFileFormComponent) uploadFileForm!: UploadFileFormComponent;
	renewalType = 'CARD';
	fileFormTitle = '';

	refreshListSuscription: Subscription;

	@Input() showTitle: boolean = true;

	// PERMISSIONS
	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isDisabledGranted: boolean = false;

	constructor(
		private cardService: CardService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected excelService: ExcelService,
		protected translateService: TranslateService
	) {
		super(
			cardService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime,
			excelService
		);

		this.cardService = cardService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.form = this.editForm;
		this.hasEntityFilters = false;
		//this.getAvailableCardList();
		this.refreshListSuscription = this.cardService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				//console.log("------ CARDLIST REFRESH -------");
				this.getList(item.startDate, item.endDate);
			}
		);

		this.setPermissions();
	}

	ngOnDestroy(): void {
		this.refreshListSuscription.unsubscribe();
	}

	public getList(startDate: Date, endDate: Date) {
		// console.log("[CARD-LIST] getList");
		// console.log("startDate: " + startDate);
		// console.log("endDate: " + endDate);
		if (startDate) this.startDate = startDate;
		if (endDate) this.endDate = endDate;
		this.beforeLoad();
		super.refresh();
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['INV_CARDS_READ', 'INV_CARDS_UPDATE'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('INV_CARDS_CREATE', user);
		this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_CARDS_DISABLE', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	editDialog(event) {
		if (!this.isFormGranted || !this.isCreateGranted) {
			return;
		}

		if (!this.isSelectableColumn) {
			this.onRowDblClick({ data: event });
		}

		this.isSelectableColumn = false;
		this.isColumn = true;
	}

	protected beforeLoad(): void {
		// console.log("[CARD-LIST] beforeLoad *** ");
		// console.log("vehicle: " + this.vehicle.id);
		// console.log("startDate: " + this.startDate);
		// console.log("endDate: " + this.endDate);
		this.paginationRequest.sortBy = "assignDate";
		this.paginationRequest.sortAsc= false;
		if (this.vehicle) {
			this.paginationRequest.filters['vehicle'] = [this.vehicle.name];
		}
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];

		if (this.paginationRequest.filters.hasOwnProperty('disabled')) {
			this.paginationRequest.filters['disabled'] =
				this.paginationRequest.filters['disabled'][0].toString() == 'true' ? ['false'] : ['true'];
		}
		//  if (!this.onlyAvailable) {
		//    this.paginationRequest.filters["available"] = ["true"];
		//  }
		//console.log(this.paginationRequest.filters);
	}

	afterLoad() {
		//console.log("[CARD-LIST] afterLoad ***");
		//  this.EnabledFilter = RestExt.getEnabledFilter(
		//    this.filters["enabled"],
		//    this.i18n
		//  );
		this.paginationResult.entities.forEach((ct: any) => {
			//console.log(ct);
			// console.log(ct);
			ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
			/*Permissions */
			ct._$changeStatus = this.hasPermission(this.permissionsEntityTypeSelector('changeStatus', 'Card'), ct.id); //This is for the validation of entity-list (selectAll function)
			ct._$update = this.hasPermission(this.permissionsEntityTypeSelector('update', 'Card'), ct.id);
			/** */
		});


		this.checkSelectAll();
	}

	// onAddAvailableCard() {
	// 	// console.log("[CARD-LIST] onAddAvailableCard *** ");
	// 	// console.log(this.selectedAvailableCard);
	// 	let cardData = {
	// 		id: this.selectedAvailableCard.id,
	// 		number: this.selectedAvailableCard.number,
	// 		type: this.selectedAvailableCard.type ? { id: this.selectedAvailableCard.type.id } : null,
	// 		assignDate: new Date(),
	// 		deassignDate: null,
	// 		expirationDate: this.selectedAvailableCard.expirationDate,
	// 		disabled: this.selectedAvailableCard.disabled,
	// 		observations: this.selectedAvailableCard.observations,
	// 		client: this.selectedAvailableCard.client ? { id: this.selectedAvailableCard.client.id } : null,
	// 		vehicle: { id: this.vehicle.id },
	// 	};
	// 	// console.log(cardData);
	// 	this.assignCardtoVehicle(cardData);
	// }

	// getAvailableCardList() {
	// 	this.isEditingDisabled = true;
	// 	const promise = new Promise<void>((resolve, reject) => {
	// 		const that = this;
	// 		this.objectService
	// 			.getList(this.vehicle.client.id)
	// 			.then(
	// 				function (response) {
	// 					// console.log("==> AVAILABLE CARD LIST...");
	// 					// console.log(response);
	// 					that.availableCardsList = response;
	// 					that.isEditingDisabled = false;
	// 					if (that.availableCardsList) {
	// 						that.availableCardsList.sort((a, b) => (a.number > b.number ? 1 : -1));
	// 						that.availableCardListPlaceHolder = that.translateService.instant('general.select') + '...';
	// 					} else {
	// 						that.availableCardListPlaceHolder = that.translateService.instant(
	// 							'tableManagement.cardLists.noAvailableCards'
	// 						);
	// 					}
	// 				},
	// 				/* failure */
	// 				function (error) {
	// 					console.log('The request failed: ' + error);
	// 					resolve();
	// 				}
	// 			)
	// 			.catch(function (reason) {
	// 				console.log('Catched: ' + reason);
	// 				resolve();
	// 			});
	// 	});
	// }

	// assignCardtoVehicle(cardData) {
	// 	// console.log("[CARD-LIST] assignCardtoVehicle *** ");
	// 	this.isEditingDisabled = true;
	// 	const promise = new Promise<void>((resolve, reject) => {
	// 		const that = this;
	// 		this.objectService
	// 			.update(cardData)
	// 			.then(
	// 				function (response) {
	// 					// console.log("==> Update Card DONE!");
	// 					// console.log(response);
	// 					//that.getAvailableCardList();
	// 					that.refresh();
	// 					that.isEditingDisabled = false;
	// 				},
	// 				/* failure */
	// 				function (error) {
	// 					console.log('The request failed: ' + error);
	// 					that.isEditingDisabled = false;
	// 					resolve();
	// 				}
	// 			)
	// 			.catch(function (reason) {
	// 				console.log('Catched: ' + reason);
	// 				that.isEditingDisabled = false;
	// 				resolve();
	// 			});
	// 	});
	// }

	afterSave() {
		// console.log("[CARD-LIST] afterSave *** ");
		this.refresh();
	}

	//   create() {
	// 		if (this.nested) {

	// 		} else {
	// 			super.create();
	// 		}
	//   }

	excelColumns = {};
	exportExcel() {
		this.setExcelColumns();
		let fileNameTitle = this.translateService.instant('tableManagement.cardLists.cardListsManagement');
		super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
	}
	setExcelColumns() {
		const number = (entity: any) => entity.number ?? '';
		const type = (entity: any) => entity.type?.name ?? '';
		const vehicleName = (entity: any) => entity.vehicle?.name ?? '';
		const productLimit = (entity: any) => entity.type?.productLimit ?? '';
		const operationLimit = (entity: any) => entity.type?.operationLimit ?? '';
		const expirationDate = (entity: any) => entity.expirationDate ?? '';
		const deAssignDate = (entity: any) => entity.deassignDate ?? '';
		const disabled = (entity: any) => entity.disabled ?? '';
		const codeProductLimit = (entity: any) => entity.type?.codeProductLimit ?? '';

		this.excelColumns = {
			[this.translateService.instant('tableManagement.cardLists.number')]: { bindField: number },
			[this.translateService.instant('control-panel.vehicles.vehicleType')]: { bindField: type },
			[this.translateService.instant('control-panel.vehicles.codeProductLimit')]: { bindField: codeProductLimit },
			[this.translateService.instant('tableManagement.cardLists.vehicle')]: { bindField: vehicleName },
			[this.translateService.instant('tableManagement.cardLists.productLimit')]: { bindField: productLimit },
			[this.translateService.instant('tableManagement.cardLists.operationLimit')]: { bindField: operationLimit },
			[this.translateService.instant('tableManagement.cardLists.expirationDate')]: { bindField: expirationDate },
			[this.translateService.instant('tableManagement.cardLists.deassignDate')]: { bindField: deAssignDate },
			[this.translateService.instant('general.disabled')]: { bindField: disabled },
		};
	}

	//UPLOAD FILE
	showFileForm() {
		//this.fileFormTitle = this.translateService.instant('tableManagement.cardsConsumption.cardConsumption');
		this.fileFormTitle = 'Renovaciones de Targeta';
		this.renewalType = 'CARD';
		this.uploadFileForm.showDialog();
	}
}
