<!-- OUTER CONTAINER -->
<div class="d-flex align-items-center vw-100 vh-100 m-0 p-0 main-login-container">

  <!-- INNER CONTAINER -->
  <div class="container inner-login-container">

    <!-- ROW 1: LOGO -->
    <div class="row mt-4">
      <div class="d-flex justify-content-center">
        <img src="../../../assets/images/login/atlantis-fleet-21.svg" id="atlantis-logo">
      </div>
    </div>

    <div class="container-fluid flex-fill align-self-center text-center">
      <div class="mt-5" id="error-404-text">404</div>

      <div class="mt-5" id="page-not-found-text" i18n>{{'error.pageNotFound' | translate }}</div>
    </div>

  </div>
</div>

