import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../notifications-service/notifications.service';

@Injectable({
    providedIn: 'root',
})
export class TacoGuard implements CanActivate {

    constructor(private auth: AuthenticationService, private router: Router, private notificationService: NotificationsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (this.auth.user.canSeeTaco) {
            return true;
        }

        this.router.navigate(['/']);

        this.notificationService.add(Severity.error, 'Access Denied', 'You are not authorized to access the page!');
        return false;
    }
}
