import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';
import { Rest } from '../../../rest/rest_client';
import { UserService } from '../../../rest/user.service';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import User = Rest.User;

const API_URL = environment.webApiBaseUrl;


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private _userSubject: BehaviorSubject<User>;
    private _user: Observable<User>;
    private _token: string | null;
    private _refreshToken: string | null;

    constructor(
        private notificationService: NotificationsService,
        private userService: UserService,
        private http: HttpClient,
        private jqueryClient: JQueryHTTPClient,
        public translate: TranslateService,
        private router: Router
    ) {
        this._userSubject = new BehaviorSubject<User>(null);
        this._user = this._userSubject.asObservable();
        this._token = null;

        if (localStorage.getItem('refreshToken')) {
            this._refreshToken = localStorage.getItem('refreshToken');
        } else {
            this._refreshToken = null;
        }

    }

    /* <--- GETTERS ---> */

    public get user(): User {
        return this._userSubject.value;
    }

    public get userObservable(): Observable<User> {
        return this._user;
    }

    public get token(): string | null {
        return this._token;
    }

    public get refreshToken(): string | null {
        return this._refreshToken;
    }

    public set refreshToken(refreshToken: string | null) {
        this._refreshToken = refreshToken;
    }

    public set token(token: string | null) {
        this._token = token;
    }

    /* <--- SETTERS ---> */

    public set userSubject(user: User) {
        this._userSubject.next(user);
    }

    /* <--- PUBLIC METHODS ---> */

    /**
     * login()
     * */
    public login(username: string, password: string): Promise<boolean> {

        return this.http.post(
            environment.webApiBaseUrl + 'api/login',
            {
                username,
                password,
            }
        ).toPromise()
            .then((response: any) => {
                if (response.token && response.refreshToken && response.user) {
                    localStorage.setItem('refreshToken', response.refreshToken);

                    this._token = response.token;
                    this._refreshToken = response.refreshToken;
                    this.userSubject = response.user;
                    this.checkLanguage(response.user.language.toString().toLowerCase());
                    return true;
                }
                return false;
            })
            .catch((error) => {
                this.notificationService.add(Severity.error, 'Login Failed', 'Status Code: ' + error.status);
                return false;
            });

    }

    public getSelf(token: string, refreshToken: string, rememberMe: boolean): Promise<boolean> {
        this._token = token;
        this._refreshToken = refreshToken;
        return this.http.get(
            environment.webApiBaseUrl + 'api/login/user'
            
        ).toPromise()
            .then((response: any) => {

                if(rememberMe){
                    localStorage.setItem('username', response.userName);

                }
                localStorage.setItem('refreshToken', refreshToken);

                this.userSubject = response;
                this.checkLanguage(response.user.language.toString().toLowerCase());
                return true;

            })
            .catch((error) => {
                this.notificationService.add(Severity.error, 'Login Failed', 'Status Code: ' + error.status);
                return false;
            });

    }


    /**
     * loginWithToken()
     * TODO: Optimize the implementation: if a refresh token is used, updateToken can be called!!!
     * */
    public loginWithToken(token: string) {
        //Parametrize jquery client singleton with token to be used in subsequent calls
        this.jqueryClient.securityToken = token;
        this._token = token;

        return new Promise((resolve, reject) => {
            //get the details of the current user
            this.userService
                .getSelf()
                .then((self: Rest.User) => {
                    this.userSubject = self;
                    this.user.accountExpiry = new Date(this.user.accountExpiry);
                    resolve('Well done!');
                })
                .catch((ex) => {
                    this.notificationService.add(Severity.error, 'Login Failed', 'Status Code: ' + ex.status);
                    reject('upss');
                });
        }).catch((ex) => {
            this.notificationService.add(Severity.error, 'Login Failed', 'Status Code: ' + ex.status);
        });
    }

    /**
     * logout()
     * */
    public logout(): void {
        this.userSubject = null;
        this._token = null;
        this._refreshToken = null;
        localStorage.removeItem('refreshToken');
        this.jqueryClient.securityToken = '';

        this.router.navigate(['/logout']);
    }

    /**
     * updateUser()
     * TODO: Optimize implementation, the setter can be used to do the same
     * Function that updates the user's information provided by the UserSettings Component.
     * Reinitialize all components involved: HeaderComponent and TranslateComponent.
     * */
    public updateUser(userSettings: any): void {
        /* Update user with the new data */
        this.user.email = userSettings.email;
        this.user.name = userSettings.name;
        this.user.surname1 = userSettings.surname1;
        this.user.surname2 = userSettings.surname2;
        this.user.timeZone = userSettings.timeZone;
        this.user.metric = userSettings.metric;
        this.user.phoneNumber = userSettings.phoneNumber;
        this.user.language = userSettings.language;
        this.user.defaultDashboard = userSettings.defaultDashboard;
        this.user.profileImage = userSettings.profileImage;
        this.user.tableSize  = userSettings.tableSize;
        /* Update the display language */
        this.checkLanguage(userSettings.language.toString().toLowerCase());

        /* Refresh the window in order to init all component and make the changes take effect */
        window.location.reload();
    }

    public updateUserProfile(profile: any): void {
        this.user.profileImage = profile;
        window.location.reload();
    }

    /**
     * updateToken()
     * */
    public updateToken() {
        return this.http.post(API_URL + 'api/login/refresh', {
            refreshToken: this.refreshToken
        });
    }

    /**
     * isUserLogged()
     * */
    public isUserLogged(): boolean {
        return this.refreshToken !== null;
    }

    /**
     * initDataAfterRefresh()
     * */
    public initDataAfterRefresh(initData: any): boolean {

        if (initData.token && initData.refreshToken && initData.user) {
            this._refreshToken = initData.refreshToken;
            this._token = initData.token;
            this.userSubject = initData.user;
            localStorage.setItem('refreshToken', this.refreshToken);

            return true;
        }
        return false;
    }

    public initDataAfterSSO(token, refreshToken, user){


        this._refreshToken = refreshToken;
        this._token = token;
        this.userSubject = user;
        localStorage.setItem('refreshToken', this.refreshToken);
        this.checkLanguage(user.language.toString().toLowerCase());

    }

    public isRoleGranted(role: string, user: User): boolean {
        if (!user.rolesProfiles || user.rolesProfiles.length === 0) {
            return false;
        }

        for (const roleProfilePair of user.rolesProfiles) {
            // Check if the profile and actions within the roleProfilePair are defined
            if (roleProfilePair.profile && roleProfilePair.profile.actions) {

                for (const action of roleProfilePair.profile.actions) {

                    if (action.name === role) {
                        return true; // Role is granted
                    }
                }
            }
        }

        return false;
    }

    public areAllRolesGranted(roles: string[], user: User): boolean {
        for (const role of roles) {
            if (!this.isRoleGranted(role, user)) {
                return false;
            }
        }
        return true;
    }

    public isOneRoleGranted(roles: string[], user: User): boolean {
        for (const role of roles) {
            if (this.isRoleGranted(role, user)) {
                return true;
            }
        }
        return false;
    }

    /**
     * checkLanguage()
     * */
    public checkLanguage(language: string): void {
        /* Set the language */
        switch (language) {
            case 'es':
                this.translate.use('es-ES');
                localStorage.setItem('lang', 'es-ES');
                break;
            case 'ca':
                this.translate.use('ca-ES');
                localStorage.setItem('lang', 'ca-ES');
                break;
            case 'fr':
                this.translate.use('fr-FR');
                localStorage.setItem('lang', 'fr-FR');
                break;
            case 'en':
                this.translate.use('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
            default:
                this.translate.use('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
        }
    }

}
