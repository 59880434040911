<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Digital Output Port
          Settings(AT + GTOUT)</span>
      </div>
    </div>
  </p-header>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Main
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output1 Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="outParameters.status"></p-inputSwitch>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Duration">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="outParameters.duration" [min]="0" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">0~255</span>
                <span class="ui-inputgroup-addon">(x100ms)</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Toggle Times">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="outParameters.toggleTimes" [min]="0" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">0~255</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>

    <div *ngIf="firmware > 16" class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Other
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="ui-g">
          <form-input fieldName="DOS REPORT"></form-input>
          <div class="ui-g">
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="for output1 (not) to report +RESP:GTDOS"
                  [(ngModel)]="outParameters.output1NotReport">
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>

</p-accordionTab>