import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

export class TaskUseful {

    public static  formatdateAPI(fecha:Date): string
    {
        const offset = fecha.getTimezoneOffset(); // Obtener el offset de la zona horaria local en minutos

        // Ajustar la fecha y hora local según el offset
       // fecha.setMinutes(fecha.getMinutes() - offset);

        // Obtener los componentes de la fecha y hora local
        const año = fecha.getFullYear();
        const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Sumar 1 a los meses ya que en JavaScript los meses comienzan desde 0
        const dia = ('0' + fecha.getDate()).slice(-2);
        const horas = ('0' + fecha.getHours()).slice(-2);
        const minutos = ('0' + fecha.getMinutes()).slice(-2);
        const segundos = ('0' + fecha.getSeconds()).slice(-2);

        const cadenaFechaHora = `${año}-${mes}-${dia}T${horas}:${minutos}:${segundos}.000Z`;
        return cadenaFechaHora;
    }

}