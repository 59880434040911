import { Input, Component, ViewEncapsulation } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-rfdetected-event-configuration",
  templateUrl: "./rfdetected-event-configuration.component.html",
  styleUrls: ["./rfdetected-event-configuration.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class RfdetectedEventConfigurationComponent extends ServerValidatedFormComponent {
  constructor(protected i18n: I18nService) {
    super(i18n);
  }
  @Input() rfDetectedEventConfig: Rest.RFDetectedEventConfiguration;

  /**
   * Needed to prevent toggle of UI component
   * @param event
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
  }
}
