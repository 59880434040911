import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class ActionService extends EntityService<Rest.Action>{
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Action>> {
    return this.client.Action_getPage(arg0);
  }
}
