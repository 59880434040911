import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-power-saving-config-form",
  templateUrl: "./power-saving-config-form.component.html",
  styleUrls: ["./power-saving-config-form.component.css"],
})
export class PowerSavingConfigFormComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }
  @Input() powerSaving: Rest.PowerSaving;
  powerSavingSwitch: Boolean;
  @ViewChild("powersaving") powersaving;

  availablePowSavingmodes: SelectItem[];

  ngOnInit() {
    this.availablePowSavingmodes = RestExt.getEnumItems(
      RestExt.PowerSavingMode,
      this.i18n
    );
    this.powerSaving.mode = "MODE_0";
    this.powerSavingSwitch =
      this.powerSaving.mode ==
      RestExt.PowerSavingMode[RestExt.PowerSavingMode.MODE_0]
        ? false
        : true;
  }

  powerSavingModeChange(event) {
    this.powerSavingSwitch =
      this.powerSaving.mode ==
      RestExt.PowerSavingMode[RestExt.PowerSavingMode.MODE_0]
        ? false
        : true;
  }
}
