import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges,ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'app-tow-alarm-information-gmt200-form',
  templateUrl: './tow-alarm-information-gmt200-form.component.html',
  styleUrls: ['./tow-alarm-information-gmt200-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TowAlarmInformationGmt200FormComponent implements OnInit,OnChanges {

  @Input() towParameters: Rest.TowAlarmGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  towOptions: SelectItem[];

  constructor() { 


  }

  ngOnInit() {
    if(this.firmware > 5){
      this.towOptions = [
        {label: "Disable the tow alarm function.",value:"DISABLE"},
        {label: "Enable the tow alarm function. Sending the tow alarm according to tow interval",value:"ENABLE_SEND_TOW_ACCORDING_TOW_INTERVAL"},
        {label: "Enable the tow alarm function. Sending the tow alarm message immediately when entering or exiting the tow", value:"ENABLE_SEND_TOW_IMMEDIATELY_WHEN_ENTERING_OR_EXIT"},          
      ];
    }else{
      this.towOptions = [
        {label: "Disable the tow alarm function.",value:"DISABLE"},
        {label: "Enable the tow alarm function. Sending the tow alarm according to tow interval",value:"ENABLE_SEND_TOW_ACCORDING_TOW_INTERVAL"},          
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }


}
