import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from "@angular/core";
import { SelectItem } from "primeng/api";

import { Rest } from "../../../../rest/rest_client";
import { RealtimeService } from "../../../../rest/realtime.service";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { RestExt } from "../../../../services/rest-client-extension";
import { RealTimeDataService } from "../../../../services/rt-data/rt-data.service";
import { AuthenticationService } from "../../../../core/services/authentication/authentication.service";
import { ChartSettingsComponent } from "../../chart-settings/chart-settings.component";
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: "app-sensor-chart",
    templateUrl: "./sensor-chart.component.html",
    styleUrls: ["./sensor-chart.component.css"],
    providers: [RealtimeService],
})
export class SensorChartComponent implements OnInit, OnChanges {
    @Input() settings: Rest.SensorChartSettings;
    @Output() settingsChange: EventEmitter<Rest.SensorChartSettings> = new EventEmitter<Rest.SensorChartSettings>();

    @Input() fleets: Rest.RTFleet[];
    protected tagsAndValues: { [index: string]: Rest.CategoricalValue[] };
    protected listTagsAndValues: Rest.DTagAndValue[];
    public availableTags: SelectItem[];
    public availableSensor: SelectItem[];
    public availableActuator: SelectItem[];

    protected sensorTagValue: { [index: string]: Rest.CategoricalValue[] };
    protected actuatorTagValue: { [index: string]: Rest.CategoricalValue[] };

  @ViewChild(ChartSettingsComponent, {static: false}) graph: ChartSettingsComponent;
    
    numercialValues: number[] = [];

    availableModels: SelectItem[];

    //chart vars
    private mappings: RestExt.Pair<string, string>[];
    chartData: any;
    chartOptions = {
        legend: {
            position: "right",
        },
        animation: {
            duration: 0,
        },
    };

    //Labels
    categorical: string;
    numerical: string;

    //Form vars
    _settings: Rest.SensorChartSettings;
    modalSettings: Rest.SensorChartSettings = Object.assign({}, emptySettings);
    displaySettingsForm: boolean = false;
    categoricalMappings: RestExt.Pair<string, string>[];
    categoricalValues: { [index: string]: Rest.CategoricalValue };
    numericalMappings: RestExt.Pair<number, string>[];

    userLanguage: string;

    constructor(
        protected i18n: I18nService,
        private rtService: RealtimeService,
        private realTimeDataService: RealTimeDataService,
        private authService: AuthenticationService,
        private translate: TranslateService
    ) {
        this.fleets = [];
        this._settings = Object.assign({}, emptySettings);
        this.numericalMappings = [];
        this.categoricalMappings = [];
        this.categoricalValues = {};
        this.mappings = [];
        this.availableModels = RestExt.getEnumItemsNoTranslation(RestExt.DEnum);
        this.availableModels.push({ label: "ALL", value: "ALL" });
    }

    ngOnInit() {

        this.userLanguage = this.getUserLanguage(this.authService.user.language);
        this.categorical = this.translate.instant("real-time.settingsModal.categorical");
        this.numerical = this.translate.instant("real-time.settingsModal.numeric");
        
        this.loadData();
        this._settings.filterType = this.settings.filterType;

        this._settings.showCategoricalValue =this.settings.showCategoricalValue;

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["fleets"]) {
            if (this.fleets == null) {
                this.tagsAndValues = {};
                this.availableTags = [];
                return;
            }
            let vehiclesIds: number[] = [];
            //Get the Ids of the vehicles
            this.fleets.forEach((f: Rest.RTFleet) => {
                f.groups.forEach((g: Rest.RTVehiclesGroup) => {
                    g.vehicles.forEach((v: Rest.RTVehicle) => {
                        vehiclesIds.push(v.id);
                    });
                });
            });

            this.rtService.getTagsAndCategoricalValues(vehiclesIds).then((r) => {
                this.listTagsAndValues = r;
                this.availableTags = [];
                this.availableSensor = [];
                this.availableActuator = [];

                this.listTagsAndValues.forEach((dv: Rest.DTagAndValue) => {
                    if (dv.dEnum == "SENSOR") {
                        this.sensorTagValue = dv.tagAndValues;
                    }
                    if (dv.dEnum == "ACTUATOR") {
                        this.actuatorTagValue = dv.tagAndValues;
                    }
                    if (dv.dEnum == "ALL") {
                        this.tagsAndValues = dv.tagAndValues;
                    }
                });

                for (let tag in this.sensorTagValue) {
                    this.availableSensor.push(<SelectItem>{ label: tag, value: tag });
                }

                for (let tag in this.actuatorTagValue) {
                    this.availableActuator.push(<SelectItem>{ label: tag, value: tag });
                }

                for (let tag in this.tagsAndValues) {
                    this.availableTags.push(<SelectItem>{ label: tag, value: tag });
                }

                this.availableTags = [...this.availableTags];
                this.availableActuator = [...this.availableActuator];
                this.availableSensor = [...this.availableSensor];
                this.updateCategoricalTags();
            });
        }
        if (changes["settings"]) {
            this.loadData();
            //this.reloadChart();
        }
    }

    switchNumeric() {
        this._settings.showCategoricalValue = false;
        this.settings.showCategoricalValue = false;
        this.updateCategoricalTags();
    }

    switchCategorical() {
        this._settings.showCategoricalValue = true;
        this.settings.showCategoricalValue = true;
        this.updateCategoricalTags();
    }

    updateCategoricalTags() {
        var newMappings = [];
        this.categoricalValues = {};
        if (
            this._settings.sensorTag == null &&
            this.availableTags != null &&
            this.availableTags.length > 0
        )
            this._settings.sensorTag = this.availableTags[0].value;
        if (this._settings.sensorTag != null && this.sensorTagValue != null) {
            switch (this._settings.filterType) {
                case "ALL":
                    for (let idx in this.tagsAndValues[this._settings.sensorTag]) {
                        newMappings.push({
                            key: this.tagsAndValues[this._settings.sensorTag][idx].key,
                            value: "#f6f6f6",
                        });
                        this.categoricalValues[
                            this.tagsAndValues[this._settings.sensorTag][idx].key
                            ] = this.tagsAndValues[this._settings.sensorTag][idx];
                    }
                    break;
                case "SENSOR":
                    for (let idx in this.sensorTagValue[this._settings.sensorTag]) {
                        newMappings.push({
                            key: this.sensorTagValue[this._settings.sensorTag][idx].key,
                            value: "#f6f6f6",
                        });
                        this.categoricalValues[
                            this.sensorTagValue[this._settings.sensorTag][idx].key
                            ] = this.sensorTagValue[this._settings.sensorTag][idx];
                    }
                    break;
                case "ACTUATOR":
                    for (let idx in this.actuatorTagValue[this._settings.sensorTag]) {
                        newMappings.push({
                            key: this.actuatorTagValue[this._settings.sensorTag][idx].key,
                            value: "#f6f6f6",
                        });
                        this.categoricalValues[
                            this.actuatorTagValue[this._settings.sensorTag][idx].key
                            ] = this.actuatorTagValue[this._settings.sensorTag][idx];
                    }
                    break;
            }
        }

        if (!this.categoricalMappings) this.categoricalMappings = newMappings;
        else
            this.categoricalMappings = newMappings.map((m: any) => {
                return this.categoricalMappings.find((nm: any) => {
                    return nm.key == m.key;
                })
                    ? this.categoricalMappings.find((nm: any) => {
                        return nm.key == m.key;
                    })
                    : m;
            });

        this.categoricalMappings = [...this.categoricalMappings];

    }
    displayForm() {
        //If nothing is selected, select the default
        if (
            this._settings.sensorTag == null &&
            this.availableTags != null &&
            this.availableTags.length > 0
        )
            this._settings.sensorTag = this.availableTags[0].value;
        if (this._settings.filterType == null) this._settings.filterType = "SENSOR";
        this.updateCategoricalTags();
        this.displaySettingsForm = true;
    }
    loadData() {
        this.categoricalMappings = [];
        this.categoricalValues = {};
        this.numericalMappings = [];

        if (this.settings == null) return;

        for (let key in this.settings.categoricalValuesMappings) {
            this.categoricalMappings.push({
                key: key,
                value: this.settings.categoricalValuesMappings[key].color,
            });
            /*
                  When loading the settings from db, assume that the available categoricalValues correspond to the mapings defined in the settings object. Use
                  the keyTranslation for each language (assume the user hasn't changed the language)
                  */
            let translation =
                this.settings.categoricalValuesMappings[key].keyTranslation;
            this.categoricalValues[key] = <Rest.CategoricalValue>{
                key: key,
                en: translation,
                es: translation,
                fr: translation,
                cat: translation,
            };
        }

        for (let key in this.settings.numericalValuesColors) {
            this.numericalMappings.push({
                key: Number(key),
                value: this.settings.numericalValuesColors[key],
            });
        }
        this.categoricalMappings = [...this.categoricalMappings];
        this.numericalMappings = this.numericalMappings.sort(
            (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
                return a.key - b.key;
            }
        );
        this.numericalMappings = [...this.numericalMappings];
        Object.assign(this._settings, this.settings);

        this.mappings = this.settings.showCategoricalValue
            ? this.categoricalMappings
            : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
                return <RestExt.Pair<string, string>>{
                    key: a.key + "",
                    value: a.value,
                };
            });
    }



    sortNumericalValues() {
        this.numericalMappings = this.numericalMappings.sort(
            (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
                return a.key - b.key;
            }
        );
    }

    save() {
        this.settings = this._settings;
        Object.assign(this.settings, this._settings);
        this.updateBackingMapping();
        this.displaySettingsForm = false;
        this.realTimeDataService.setSensorChartSettings(this.settings);
    }

    discard() {
        this.displaySettingsForm = false;
        this._settings = Object.assign({}, this.settings);
        this.loadData();
    }

    updateBackingMapping() {
        this.settings.numericalValuesColors = {};
        for (let key in this.numericalMappings) {
            this.settings.numericalValuesColors[this.numericalMappings[key].key] =
                this.numericalMappings[key].value;
        }

        this.settings.categoricalValuesMappings = {};
        for (let key in this.categoricalMappings) {
            this.settings.categoricalValuesMappings[
                this.categoricalMappings[key].key
            ] = <Rest.CategoricalValueMapping>{
                keyTranslation: this.categoricalValues[this.categoricalMappings[key].key][
                    this.userLanguage
                ],
                color: this.categoricalMappings[key].value,
            };
        }
        this.mappings = this.settings.showCategoricalValue
            ? this.categoricalMappings
            : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
                return <RestExt.Pair<string, string>>{
                    key: a.key + "",
                    value: a.value,
                };
            });

    }

    addNumericalValueMapping() {
        this.modalSettings = this._settings;

        this.numericalMappings.push(<RestExt.Pair<number, string>>{
            key: this.numericalMappings.length + 1,
            value: "#f6f6f6",
        });
        this.numericalMappings = this.numericalMappings.sort(
            (a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => {
                return a.key - b.key;
            }
        );
        this.numericalMappings = [...this.numericalMappings];

        this.modalSettings.numericalValuesColors = {};
        for (let key in this.numericalMappings) {
            this.modalSettings.numericalValuesColors[this.numericalMappings[key].key] = this.numericalMappings[key].value;
        }

        this.saveNumerical();
    }

    removeNumericalValueMapping(pair) {
        this.numericalMappings.splice(
            this.numericalMappings.indexOf(pair),
            1
        );
        this.numericalMappings = [...this.numericalMappings];
        this.save();
        this.saveNumerical();
    }

    onNumericKeyChange(event: KeyboardEvent) {
        this.saveNumerical();
    }

    onNumericalColorChange(pair: RestExt.Pair<number, string>) {
        let foundPair = this.numericalMappings.find((a) => a.key == pair.key);
        foundPair.value = pair.value;
        this.saveNumerical();
    }

    saveNumerical() {
        this._settings.numericalValuesColors = {};
        for (let key in this.numericalMappings) {
            this._settings.numericalValuesColors[this.numericalMappings[key].key] =
            this.numericalMappings[key].value;
        }

        this.graph.reloadChartManual(this._settings);
    }

    onCategoricalColorChange(pair: RestExt.Pair<string, string>) {
        let foundPair = this.categoricalMappings.find((a) => a.key == pair.key);
        foundPair.value = pair.value;
        this.saveCategorical();
    }

    saveCategorical() {
        this._settings.categoricalValuesMappings = {};
        for (let key in this.categoricalMappings) {
            this._settings.categoricalValuesMappings[this.categoricalMappings[key].key] =
                <Rest.CategoricalValueMapping> {
                keyTranslation: this.categoricalValues[this.categoricalMappings[key].key][
                    this.userLanguage
                ],
                color: this.categoricalMappings[key].value,
            };
        }
    }








    reloadChart() {
        this.mappings = this.settings.showCategoricalValue
        ? this.categoricalMappings
        : this.numericalMappings.map((a: RestExt.Pair<number, string>) => {
            return <RestExt.Pair<string, string>>{
                key: a.key + "",
                value: a.value,
            };
        });

        let frequencies = this.mappings.map((m: RestExt.Pair<string, string>) => {
            return 0;
        });
        this.realTimeDataService.vehicleInfoArr.forEach(
            (v: RestExt.ExtendedVehicleDataMessage) => {
                frequencies[v.category_idx]++;
            }
        );
        let labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
            return m.key;
        });
        if (this.settings.showCategoricalValue) {
            labels = this.mappings.map((m: RestExt.Pair<string, string>) => {
                return this.categoricalValues[m.key][this.userLanguage];
            });
        }
  
        this.chartData = {
            labels: labels,
            datasets: [
                {
                    data: frequencies,
                    backgroundColor: this.mappings.map(
                        (m: RestExt.Pair<string, string>) => {
                            return m.value;
                        }
                    ),
                },
            ],
        };
    }

    getUserLanguage(language:Rest.Language){
        var userLanguage = "";
        if(language == "CA"){
            userLanguage = "cat";
        }else{
            userLanguage = language.toString().toLowerCase();
        }
        return userLanguage;

    }
}

const emptySettings = <Rest.SensorChartSettings>{
    id: 0,
    showCategoricalValue: false,
    sensorTag: "",
    categoricalValuesMappings: {},
    numericalValuesColors: {},
    filterType: "SENSOR",
};
