import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-external-battery-alarm-configuration-concox-mt200',
  templateUrl: './external-battery-alarm-configuration-concox-mt200.component.html',
  styleUrls: ['./external-battery-alarm-configuration-concox-mt200.component.css'],
})
export class ExternalBatteryAlarmConfigurationConcoxMt200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() batteryAlarm: Rest.ExternalBatteryAlarmConfigurationConcoxMT200;
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  BatteryAlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"GPRS_SMS"},           
  ];
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
    if(this.batteryAlarm!=null){this.disableDIV(!this.batteryAlarm.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.batteryAlarm);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
