<!-- CALL BACK MODAL-->
<div #callBackModal class="modal fade call-back-modal" id="callBackModal" aria-hidden="true" aria-labelledby="callBackModal"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-md modal-dialog-centered">

    <!-- CALL BACK MODAL CONTENT -->
    <div class="modal-content">

      <!-- CALL BACK MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col">
              <span class="modal-title custom-title" i18n>
                Call
              </span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button #closeCallBackModalButton type="button" class="btn-close m-0 p-0 custom-modal-close-button"
                        (click)="closeCallBackModal()" data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- CALL BACK MODAL BODY -->
      <div class="modal-body">
        <div class="container-fluid">

          <!-- ROW 1: SEND MESSAGE LABEL && TEXTAREA -->
          <div class="row mt-3 mb-2">

            <!-- COL 1: SEND MESSAGE LABEL -->
            <div class="col">
              <div class="d-flex align-items-center h-100">
                <label for="phoneNumber" class="form-label custom-label mb-0 me-3" i18n>
                  Phone number:
                </label>
              </div>
            </div>

            <!-- COL 2: SEND MESSAGE TEXTAREA -->
            <div class="col">
              <div class="d-flex align-items-center h-100">
                <input [(ngModel)]="phoneNumber" type="tel" id="phoneNumber" class="form-control custom-input">
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- SEND MESSAGE MODAL FOOTER -->
      <div class="modal-footer">
        <div class="d-flex justify-content-center w-100">
          <button class="btn custom-button" [disabled]="!phoneNumber || phoneNumber.length===0" (click)=send() i18n>Send</button>
        </div>
      </div>

    </div>

  </div>
</div>
