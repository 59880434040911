import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';

@Injectable({
	providedIn: 'root'
 })
export class DocumentationService extends EntityService<Rest.InvDocument> {

    client: Rest.RestApplicationClient;
	 startedEditing = new Subject<Rest.InvDocumentEdit>();
	 refreshList = new Subject<Rest.refreshDocListRequest>();

    constructor(private jquery: JQueryHTTPClient,private http: HttpClient) {
		console.log("[DOCUMENTATION-SERVICE] constructor ::::: ");
      super();
      this.client = new Rest.RestApplicationClient(jquery);

    }

    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvDocument>> {
        return this.client.Document_getPage(arg0);
    }

	//  getTypeList(language: Rest.Language): Rest.RestResponse<Rest.InvDocumentType[]> {
	// 	let queryParams = {
	// 		lang: language
	// 	};
	// 	console.log(queryParams);
	// 	return this.client.Document_getTypeList(queryParams);
   //  }

    deleteDocument(documentId: number): Promise<boolean> {

      return this.http
          .delete(environment.webApiBaseUrl + 'api/inventory/document/' + documentId)
          .toPromise()
          .then(() => {
              return true;
          })
          .catch((error) => {
              return false;
          });
   }

   find(arg0: string,queryParams?: { id?: number }): Rest.RestResponse<Rest.InvDocument> {
      return this.client.Document_find(arg0, queryParams);
   }

   public createDocument(document: string, logo?: any): Promise<Rest.InvDocument> {
		// console.log("[DOC-SERVICE] createDocument ***");
		// console.log(document);
		// console.log(logo);
	   const formData = new FormData();
      formData.append('documentBody', document);
      if (logo) {
       	formData.append('documentFile', logo);
     	}

     	return this.http
        	.post(environment.webApiBaseUrl + 'api/inventory/document', formData)
        	.toPromise()
        	.then((response: any) => {
           	return response;
       	})
        	.catch((error) => {
         	this.notificationsService.add(Severity.error, 'Error', error.message);
          	return null;
         });
   }

   public updateDocument(document: string, logo?: any): Promise<Rest.InvDocument> {
		// console.log("[DOC-SERVICE] updateDocument ***");
		// console.log(document);
		// console.log(logo);
      const formData = new FormData();
      formData.append('documentBody', document);
      if (logo) {
         formData.append('documentFile', logo);
      }

      return this.http
         .post(environment.webApiBaseUrl + 'api/inventory/document/update', formData)
         .toPromise()
         .then((response: any) => {
         	return response;
         })
         .catch((error) => {
            this.notificationsService.add(Severity.error, 'Error', error.message);
            return null;
         });
   }

   public renewalFiles(documentFile: any, renewalType: string): Promise<any> {
      const formData = new FormData();
      formData.append('renewalType', renewalType);
      if (documentFile !== undefined) {
         formData.append('documentFile', documentFile);
      }

      return this.http
         .post(environment.webApiBaseUrl + 'api/inventory/renewalFiles', formData)
         .toPromise()
         .then((response: any) => {
           return response;
         })
         .catch((error) => {
            this.notificationsService.add(Severity.error, 'Error', error.message);
            return null;
         });
   }


}
