import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-center-phone-number-configuration-concox-mt200",
  templateUrl:
    "./center-phone-number-configuration-concox-mt200.component.html",
  styleUrls: ["./center-phone-number-configuration-concox-mt200.component.css"],
})
export class CenterPhoneNumberConfigurationConcoxMt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() centerPhoneNumber: Rest.CenterPhoneNumberConfigurationConcoxMT200;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }
  SosOptions: any = [
    { label: "DELETE", value: "DELETE" },
    { label: "ADD", value: "ADD" },
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.centerPhoneNumber);
    }
  }
}
