export namespace Auxiliar {

    export function toEntitySelectItem(entity:any):any
    {
        return {label:entity.name, value:entity};
    }

    export function toEntitySelectItemArr(entities:any[]):any
    {
       return  entities.map((entity)=>({label:entity.name, value:entity}));
        
    }
    export function toEntityUsersSelectItemArr(entities:any[]):any
    {
       return  entities.map((entity)=>({label:entity.userName, value:entity}));
        
    }
    export function toEntityRouteSelectItemArr(entities:any[]):any
    {
       return  entities.map((entity)=>({label:entity.name, value:entity}));
        
    }
    export function toEntityIncidenceTypeSelectItemArr(entities:any[]):any
    {
       return  entities.map((entity)=>({label:entity.code, value:entity}));
        
    }

}