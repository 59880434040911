import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class RealtimeService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 getVehicleEvents(arg0: number[], queryParams?: { start?: Date; end?: Date; }): Rest.RestResponse<Rest.Event[]> {
return this.client.RealTime_getVehicleEvents(arg0, queryParams);
}

getTree(arg0: Rest.FleetTreeSettings): Rest.RestResponse<Rest.RTFleet[]> {
return this.client.RealTime_getTree(arg0);
}

createFleetTreeSettings(arg0: Rest.FleetTreeSettings): Rest.RestResponse<Rest.FleetTreeSettings> {
return this.client.RealTime_createFleetTreeSettings(arg0);
}

getFleetTreeSettings(): Rest.RestResponse<Rest.FleetTreeSettings[]> {
return this.client.RealTime_getFleetTreeSettings();
}

updateFleetTreeSettings(arg0: Rest.FleetTreeSettings): Rest.RestResponse<Rest.FleetTreeSettings> {
return this.client.RealTime_updateFleetTreeSettings(arg0);
}

deleteFleetTreeSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<any> {
return this.client.RealTime_deleteFleetTreeSettings(settingsId, queryParams);
}

findFleetTreeSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<Rest.FleetTreeSettings> {
return this.client.RealTime_findFleetTreeSettings(settingsId, queryParams);
}

createMapSettings(arg0: Rest.MapSettings): Rest.RestResponse<Rest.MapSettings> {
return this.client.RealTime_createMapSettings(arg0);
}

getMapSettings(): Rest.RestResponse<Rest.MapSettings[]> {
return this.client.RealTime_getMapSettings();
}

updateMapSettings(arg0: Rest.MapSettings): Rest.RestResponse<Rest.MapSettings> {
return this.client.RealTime_updateMapSettings(arg0);
}

deleteMapSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<void> {
return this.client.RealTime_deleteMapSettings(settingsId, queryParams);
}

findMapSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<Rest.MapSettings> {
return this.client.RealTime_findMapSettings(settingsId, queryParams);
}

countVehicleReadings(vehicleId: string, queryParams?: { vehicleId?: number; start?: Date; end?: Date; }): Rest.RestResponse<number> {
return this.client.RealTime_countVehicleReadings(vehicleId, queryParams);
}

getVehicleReadings(vehicleId: string, queryParams?: { vehicleId?: number; start?: Date; end?: Date; }): Rest.RestResponse<{ [index: string]: Rest.SimpleDeviceValue[] }> {
return this.client.RealTime_getVehicleReadings(vehicleId, queryParams);
}

createRealTimeSettings(arg0: Rest.RealTimeSettings): Rest.RestResponse<Rest.RealTimeSettings> {
return this.client.RealTime_createRealTimeSettings(arg0);
}

getRealTimeSettings(): Rest.RestResponse<Rest.RealTimeSettings[]> {
return this.client.RealTime_getRealTimeSettings();
}

updateRealTimeSettings(arg0: Rest.RealTimeSettings): Rest.RestResponse<Rest.RealTimeSettings> {
return this.client.RealTime_updateRealTimeSettings(arg0);
}

deleteRealTimeSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<void> {
return this.client.RealTime_deleteRealTimeSettings(settingsId, queryParams);
}

findRealTimeSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<Rest.RealTimeSettings> {
return this.client.RealTime_findRealTimeSettings(settingsId, queryParams);
}

getTagsAndCategoricalValues(arg0: number[]): Rest.RestResponse<Rest.DTagAndValue[]> {
return this.client.RealTime_getTagsAndCategoricalValues(arg0);
}

getLastVehicleDataMessages(arg0: number[], queryParams?: {start?:number, end?:number, maxCount?: number; }): Rest.RestResponse<Rest.VehicleDataMessage[]> {
return this.client.RealTime_getLastVehicleDataMessages(arg0, queryParams);
}

createVehicleDetailsSettings(arg0: Rest.VehicleDetailsSettings): Rest.RestResponse<Rest.VehicleDetailsSettings> {
return this.client.RealTime_createVehicleDetailsSettings(arg0);
}

getVehicleDetailsSettings(): Rest.RestResponse<Rest.VehicleDetailsSettings[]> {
return this.client.RealTime_getVehicleDetailsSettings();
}

updateVehicleDetailsSettings(arg0: Rest.VehicleDetailsSettings): Rest.RestResponse<Rest.VehicleDetailsSettings> {
return this.client.RealTime_updateVehicleDetailsSettings(arg0);
}

deleteVehicleDetailsSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<void> {
return this.client.RealTime_deleteVehicleDetailsSettings(settingsId, queryParams);
}

findVehicleDetailsSettings(settingsId: string, queryParams?: { settingsId?: number; }): Rest.RestResponse<Rest.VehicleDetailsSettings> {
return this.client.RealTime_findVehicleDetailsSettings(settingsId, queryParams);
}

getVehicleInfo(arg0: number[]): Rest.RestResponse<Rest.Vehicle[]> {
return this.client.RealTime_getVehicleInfo(arg0);
}

getLastVehicleDataMessage(arg0: number): Rest.RestResponse<Rest.VehicleDataMessage> {
  return this.client.RealTime_getLastVehicleDataMessage(arg0);
}
}
