import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-report-detection-event-form-vt200",
  templateUrl: "./report-detection-event-form-vt200.component.html",
  styleUrls: ["./report-detection-event-form-vt200.component.css"],
})
export class ReportDetectionEventFormVt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() dr: Rest.DRVT200;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
}
