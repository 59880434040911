import { Injectable } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { RestExt } from '../rest-client-extension';
import { Rest } from 'app/rest/rest_client';
import { OrdersService } from 'app/modules/task/Logistics/services/orders.service';
import { Rest_logistics } from 'app/modules/task/Logistics/services/rest_client_logistics';

export enum Severity {
   success = 'success',
   info = 'info',
   warn = 'warn',
   error = 'error',
}

@Injectable()
export class NotificationsService {

   constructor(
      public messageService: MessageService,
      // protected orderService: OrdersService,
   ) { }

   public add(severity: Severity, summary: string, detail: string): void {
      this.messageService.add({
         severity: severity,
         summary: summary,
         key: 'customToast',
         detail: detail,
         sticky: false,
         life: 5000,
      });
   }
}
