import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";

import { LoadingService } from "app/services/loading-service/loading.service";
import { MaintenanceService } from "../../rest/maintenance.service";
import { Rest } from "../../rest/rest_client";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityListComponent } from "../entity-list/entity-list.component";
import { MaintenanceFormComponent } from "../maintenance-form/maintenance-form.component";
import { UserTimePipe } from "../shared/UserTimePipe";

@Component({
    selector: "app-maintenances-view-list",
    templateUrl: "./maintenances-view-list.component.html",
    styleUrls: ["./maintenances-view-list.component.css"],
    providers: [MaintenanceService, ConfirmationService, UserTimePipe],
})
export class MaintenancesViewListComponent
    extends EntityListComponent<Rest.Maintenance>
    implements OnInit {
    maintenances: Rest.Maintenance[];
    title: string;
    displayDialog: boolean;

    messageWarning: string;
    containsNestedEntities = [];

    @Input() parentMaintenancePlan: Rest.MaintenancePlan;

    @ViewChild(MaintenanceFormComponent, {static: true})
    maintenanceForm: MaintenanceFormComponent;

    EnabledFilter: SelectItem[];
    maintenanceService: MaintenanceService;
    showStatusDialog = false;

    /*Permissions */
    hasPermissions: boolean;

    /** */

    constructor(
        maintenanceService: MaintenanceService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            maintenanceService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.maintenanceService = maintenanceService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._("Maintenances");
        this.form = this.maintenanceForm;
        this.fetchEntityBeforeShowUpdateForm = false;
        this.hasEntityFilters = true;
        /*Permissions */
        this.hasPermissions = this.parentMaintenancePlan["_$update"];
        /** */
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((maintenance: any) => {
            /*Permissions */
            maintenance._$changeStatus = this.hasPermissions;
            maintenance._$update = this.hasPermissions;
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    protected beforeLoad() {
        this.paginationRequest.filters["maintenancePlanID"] = [
            this.parentMaintenancePlan.id.toString(),
        ];
    }

    createMaintenanceDialog() {
        this.create({
            maintenancePlan: this.parentMaintenancePlan,
        } as Rest.Maintenance);
    }

    close() {
        let elementsdeleted = [];
        this.displayDialog = false;
        this.selectedEntities = [];
        this.maintenances = [];
        this.containsNestedEntities = [];

        if (this.containsNestedEntities != null) {
            elementsdeleted = this.containsNestedEntities.splice(
                0,
                this.containsNestedEntities.length
            );
        }
    }
}
