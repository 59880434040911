<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="true" appendTo="body"
  *ngIf="display">

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <h4>For VT10</h4>

    <div style="margin-left: 5%;">
      <p>ACCOnTimePeriod (ANALOG PORT 6) </p>
      <p>Voltage level backup battery (ANALOG PORT 7)</p>
      <p>Remote controller ID (ANALOG PORT 8) TAG:DRIVER_ID </p>
      <p>Remote controller voltage level (ANALOG PORT 9)</p>
      <p>Remote controller button pressed (ANALOG PORT 10)</p>
      <p>IG/ACC (Digital Port 0) </p>
    </div>

    <h4>For GV300</h4>
    <div style="margin-left: 5%;">
      <p>IG/ACC (Digital Port 0) </p>
    </div>

    <div class="container">

      <!-- ROW 1 -->
      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
            </form-input>
          </div>
          <div class="col">
            <input pInputText id="name" [(ngModel)]="entity.name" class="txtbStyle" />
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input i18n-fieldName [required]="true" fieldName="tag" [errors]="errors.tag">
            </form-input>
          </div>
          <div class="col">
            <input pInputText id="tag" [(ngModel)]="entity.tag" class="txtbStyle" />
          </div>
        </div>

      </div>


      <!-- ROW 2 -->
      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input i18n-fieldName [required]="true" fieldName="type" [errors]="errors.type">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown placeholder="Select Device Type" [options]="availableTypes" [(ngModel)]="entity.type"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input [required]="true" i18n-fieldName fieldName="port" [errors]="errors.port">
            </form-input>
          </div>
          <div class="col">
            <input pInputText id="port" [(ngModel)]="entity.port" type="number" class="txtbStyle" />
          </div>
        </div>

      </div>

      <!-- ROW 3 -->
      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input [required]="false" i18n-fieldName fieldName="External Id" [errors]="errors.externalId">
            </form-input>
          </div>
          <div class="col">
            <input pInputText id="externalId" [(ngModel)]="entity.externalId" class="txtbStyle" />
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input i18n-fieldName fieldName="conversion algorithm" [errors]="errors.conversionAlgorithm">
            </form-input>
          </div>
          <div class="col">
            <paged-search-dropdown [paginationRequest]="enableFilter"
            [pageFunction]="this.conversionAlgorithmService.getPage.bind(this.conversionAlgorithmService)"
            [(selectedEntity)]="entity.conversionAlgorithm"></paged-search-dropdown>
          </div>
        </div>

      </div>

    </div>

  </div>

  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="user.isAdmin === true">
      <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="Save"></button>
    </div>
  </p-footer>

</p-dialog>