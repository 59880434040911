<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	dataKey="id"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.cardLists.cardListsManagement' | translate }}
		</div>
	</ng-template>
	<!-- #endregion TABLE TITE DEFINITION -->

	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

			<!-- LOGO -->
			<th pSortableColumn="logo">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.brands.logo' | translate }}
					</div>
				</div>
			</th>

			<!-- NUMBER -->
			<th pSortableColumn="number">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.number' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="number"></p-sortIcon>
						<p-columnFilter
							field="number"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- TYPE -->
			<th pSortableColumn="type.name">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.vehicles.vehicleType' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="type.name"></p-sortIcon>
						<p-columnFilter
							field="type"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- START DATE -->
			<th pSortableColumn="startDate">
				<div
					title="{{ 'tableManagement.cardLists.assignDateShort' | translate }}"
					class="d-flex align-items-center h-100"
				>
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.assignDateShort' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="startDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- DEASSIGN DATE -->
			<th pSortableColumn="endDate">
				<div
					title="{{ 'tableManagement.cardLists.deassignDate' | translate }}"
					class="d-flex align-items-center h-100"
				>
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.deassignDateShort' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="endDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="disabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="disabled"></p-sortIcon>
						<p-columnFilter
							field="disabled"
							type="boolean"
							display="menu"
							[showApplyButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>
	<!-- #endregion TABLE COLUMN DEFINITION -->

	<!-- #region TABLE BODY -->
	<ng-template pTemplate="body" let-htccard>
		<tr class="p-selectable-row custom-rows">

			<!-- IMAGE -->
			<td>
				<div *ngIf="!htccard.card.type.image; else cardImage">
					<img
						#image
						src="{{ '/assets/images/UI_Elements/no-image-available.svg' }}"
						class="img-responsive"
						[style]="{ 'max-height': '50px' }"
					/>
				</div>
				<ng-template #cardImage>
					<div>
						<img
							#image
							src="{{ htccard.card.type.image.imageEncoded }}"
							class="img-responsive"
							[style]="{ 'max-height': '50px' }"
						/>
					</div>
				</ng-template>
			</td>

			<!-- NUMBER -->
			<td>
				{{ htccard.card.number }}
			</td>

			<!-- TYPE -->
			<td>
				{{ htccard.card.type.name }}
			</td>

			<!-- ASSIGN DATE -->
			<td>
				{{ htccard.startDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- DEASSIGN DATE -->
			<td>
				{{ htccard.endDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i
						class="pi"
						[ngClass]="{
							'true-icon pi-check-circle': !htccard.card.disabled,
							'false-icon pi-times-circle': htccard.card.disabled
						}"
					></i>
				</div>
			</td>

		</tr>
	</ng-template>
	<!-- #endregion TABLE BODY -->

	<!-- #region TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="6" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE EMPTY MESSAGE -->

</p-table>


