import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { JQueryHTTPClient } from "./JQueryHTTPClient";
import { Rest } from "./rest_client";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { NotificationsService, Severity } from "../services/notifications-service/notifications.service";
import RestResponse = Rest.RestResponse;
import User = Rest.User;

@Injectable()
export class UserService extends EntityService<User> {
    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient,
        private notificationsService: NotificationsService
    ) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.User): Rest.RestResponse<User> {
        return this.client.User_create(arg0);
    }

    getSelf(): Rest.RestResponse<Rest.User> {
        return this.client.User_getSelf();
    }

    refreshSession(): Promise<any> {
        return this.client.User_refreshSession();
    }

    update(arg0: Rest.User): Rest.RestResponse<User> {
        return this.client.User_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.Page<Rest.User>> {
        return this.client.User_getPage(arg0);
     }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<User>> {
         return  this.client.User_getPageAndFilters(arg0);
     }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        return this.client.User_changeStatus(arg0, queryParams);
    }

    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.User> {
        return this.client.User_find(arg0, queryParams);
    }

    getUserProfileInfo(uid: number): Rest.RestResponse<any> {
        return this.client.User_getUserProfileInfo(uid);
    }

    updateUserProfileInfo(userSettings: any): RestResponse<any> {
        return this.client.User_updateUserProfileInfo(userSettings);
    }

    async getUserAllowedDashboards(uid: number): RestResponse<any> {
        return await this.client.User_getUserAllowedDashboards(uid);
    }

    /**
     * getUserByDevice()
     * */
    public getUsersByDevice(deviceId: number): Promise<User[]> {
        /* HEADERS */
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.jquery.securityToken,
        });

        /* PARAMS */
        const params = new HttpParams().set('deviceId', deviceId);

        return this.http
            .get(environment.webApiBaseUrl + 'api/user/list/bydevice', { headers, params })
            .toPromise()
            .then((response: any) => {
                return response as User[];
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return [];
            });
    }

    /** get data task (food) */
    // public userAllDataTask(arg0: string, queryParams?: { id?: number; }): Rest.RestResponse<User[]> {
    public userAllDataTask(uid: number): Rest.RestResponse<User> {
        // return this.client.User_AllDataTask(arg0, queryParams);
        return this.client.User_AllDataTask(uid);
     }
    /**
     * getUsersByClient
     */
    public getUsersByClient(clientId: number): RestResponse<User[]> {
        return this.client.User_getUsersWithSharedClient(clientId);
    }
}
