<p-table
	[value]="paginationResult.entities"
	id="table"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="10"
	[rowsPerPageOptions]="[10,20,30,40,50]"
	[paginator]="true"
	[loading]="loading"
   [rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	currentPageReportTemplate="
      {{ 'table.showing' | translate }} {first}
      {{ 'table.to' | translate }} {last}
      {{ 'table.of' | translate }} {totalRecords}
      {{ 'table.entries' | translate}}"
	class="custom-table"
	styleClass="p-datatable-striped">

   <!-- #region TABLE TITLE DEFINITION -->
   <ng-template pTemplate="caption">
      <div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
		{{ 'tableManagement.accessories.accessoriesManagement' | translate }}
	      </div>
   </ng-template>
   <!-- #endregion -->

   <!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

			<!-- EXTRA TYPE -->
			<!-- <th pSortableColumn="extrasType">
				<div class='d-flex align-items-center h-100'>
					!-- COLUMN TITLE --
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                  {{ 'general.type' | translate }}
               </div>

					!-- COLUMN SORT && FILTER ICONS --
					<div class="d-flex align-items-center h-100">
                  !-- COLUMN SORT && FILTER ICONS --
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="extrasType"></p-sortIcon>
							<p-columnFilter field="extrasType" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="vehicleTypeList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div class="p-multiselect-type-option">
												<span class="ml-1">{{option.name}} ({{option.code}})</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
                  </div>
					</div>
				</div>
			</th> -->

         <!-- DESCRIPTION -->
         <th pSortableColumn="description">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'general.description' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="description"></p-sortIcon>
                  	<p-columnFilter field="description" type="text" matchMode="contains"
                        placeholder="{{ 'general.search' | translate }}"
								display="menu"
								[showMatchModes]="false"
                        [showOperator]="false"
								[showAddButton]="false">
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

         <!-- SERIAL NUMBER -->
         <th pSortableColumn="serialNumber">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.accessories.serialNumber' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="serialNumber"></p-sortIcon>
                  	<p-columnFilter field="serialNumber" type="text" matchMode="contains"
                        placeholder="{{ 'general.search' | translate }}"
								display="menu"
								[showMatchModes]="false"
                        [showOperator]="false"
								[showAddButton]="false">
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

			<!-- INSTALLATION DATE -->
         <th pSortableColumn="installationDate">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.accessories.installationDate' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="installationDate"></p-sortIcon>
							<p-columnFilter type="date" field="installationDate" placeholder="Search"
								[showMatchModes]="false" [showOperator]="false" display="menu"
								class="p-ml-auto">
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

			<!-- DESINSTALLATION DATE -->
         <th pSortableColumn="desinstallationDate">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.accessories.desinstallationDate' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="desinstallationDate"></p-sortIcon>
							<p-columnFilter type="date" field="desinstallationDate" placeholder="Search"
								[showMatchModes]="false" [showOperator]="false" display="menu"
								class="p-ml-auto">
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

      	<!-- ACTIVE -->
      	<th pSortableColumn="enabled">
				<div class="d-flex align-items-center h-100">

			  	<!-- COLUMN TITLE -->
			  	<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
				 	{{ 'general.active' | translate }}
			  	</div>

			  	<!-- COLUMN SORT && FILTER ICONS -->
			  	<div class="d-flex align-items-center h-100">
				 	<p-sortIcon field="!enabled"></p-sortIcon>
				 	<p-columnFilter field="!enabled" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
			  	</div>

				</div>
		 	</th>

		</tr>
	</ng-template>
   <!-- #endregion  -->

   <!-- #region TABLE BODY DEFINITION -->
   <ng-template pTemplate="body" let-accessory>
      <tr class='p-selectable-row custom-rows' (dblclick)='editVehicleAccessory(accessory)'>
			<td>{{accessory.description}}</td>
			<td>{{accessory.serialNumber}}</td>
         <td>{{accessory.installationDate | date: 'dd/MM/yyyy'}}</td>
			<td>{{accessory.desinstallationDate | date: 'dd/MM/yyyy'}}</td>
			<td>
				<div class="d-flex justify-content-center align-items-center">
				  <i class="pi" [ngClass]="{'true-icon pi-check-circle': !accessory.disabled, 'false-icon pi-times-circle': accessory.disabled}"></i>
				</div>
			 </td>
      </tr>
   </ng-template>
   <!-- #endregion -->

	<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="9" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!------ FOOTER ----------------------------------------------------------------------------->
	<ng-template pTemplate="footer">
		<tr>
			<td colspan="9" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD RACING EVENT BUTTON -->
					<app-table-add-button *ngIf="isCreateGranted" [text]=" 'tableManagement.accessories.accessory' | translate "
					   (btnClick)="createVehicleAccessory()"
					></app-table-add-button>

					<!-- EXCEL BUTTON -->
					<app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button>
				</div>
			</td>
		</tr>
	</ng-template>

</p-table>

<app-inventory-vehicle-accessory-form [vehicle]="vehicle">
</app-inventory-vehicle-accessory-form>
