import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class CampaignService extends EntityService<Rest.FidCampaigns> {

	client: Rest.RestApplicationClient;
	endTranslationEditing = new Subject<Rest.FidCampaigns>();
	campaignSaved = new Subject<Rest.FidCampaigns>();
	changesPending = new Subject();
	spaceAdded = new Subject<Rest.FidCampaigns>();

	public deviceCommunicationList: any[];
	public deviceTypeList: any[];
	public genderList: any[];
	public languageList: any[];
	//public sendNotifControl: any;

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	public getParameters(): Rest.RestResponse<string[]> {
		return this.client.FidEnum_getParameters();
	}

	public getFullInfoParameters(): Rest.RestResponse<Rest.FullInfoParameters[]> {
		return this.client.FidEnum_getFullInfoParameters();
	}

	public getTypes(parameter): Rest.RestResponse<string[]> {
		return this.client.FidEnum_getTypes(parameter);
	}

	public getValueTypes(parameter): Rest.RestResponse<String> {
		return this.client.FidEnum_getValueType(parameter);
	}

	public initCampaign(): Rest.FidCampaigns {
		return {
			id: null,
			name: null,
			nameCat: null,
			nameEng: null,
			nameFra: null,
			nameIta: null,
			description: null,
			descriptionCat: null,
			descriptionEng: null,
			descriptionFra: null,
			descriptionIta: null,
			provider: null,
			textColor: "#FFFFFF",
			startDate: null,
			endDate: null,
			infoLink: null,
			infoLinkCat: null,
			infoLinkEng: null,
			infoLinkFra: null,
			infoLinkIta: null,
			buyLink: null,
			buyLinkCat: null,
			buyLinkEng: null,
			buyLinkFra: null,
			buyLinkIta: null,
			createdByUser: null,
			creationDate: null,
			expirationDate: null,
			targets: null,
			campaignType: null,
			active: null,
			image: null
		};
	}

	public refreshCampaign(translations: Rest.Translations): void {
		// console.log("[CAMPAIGN-SERVICE] **** refreshCampaign ****");
		// console.log(translations);
		let editedCampaign: Rest.FidCampaigns;
		editedCampaign = this.initCampaign();
		editedCampaign.name = translations.field === "name" ? translations.esp : null;
		editedCampaign.nameCat =  translations.field === "name" ? translations.cat : null;
		editedCampaign.nameEng = translations.field === "name" ? translations.eng : null;
		editedCampaign.nameFra = translations.field === "name" ? translations.fra : null;
		editedCampaign.nameIta = translations.field === "name" ? translations.ita : null;
		editedCampaign.description = translations.field === "description" ? translations.esp : null;
		editedCampaign.descriptionCat = translations.field === "description" ? translations.cat : null;
		editedCampaign.descriptionEng = translations.field === "description" ? translations.eng : null;
		editedCampaign.descriptionFra = translations.field === "description" ? translations.fra : null;
		editedCampaign.descriptionIta = translations.field === "description" ? translations.ita : null;
		editedCampaign.infoLink = translations.field === "infoLink" ? translations.esp : null;
		editedCampaign.infoLinkCat = translations.field === "infoLink" ? translations.cat : null;
		editedCampaign.infoLinkEng = translations.field === "infoLink" ? translations.eng : null;
		editedCampaign.infoLinkFra = translations.field === "infoLink" ? translations.fra : null;
		editedCampaign.infoLinkIta = translations.field === "infoLink" ? translations.ita : null;
		editedCampaign.buyLink = translations.field === "buyLink" ? translations.esp : null;
		editedCampaign.buyLinkCat = translations.field === "buyLink" ? translations.cat : null;
		editedCampaign.buyLinkEng = translations.field === "buyLink" ? translations.eng : null;
		editedCampaign.buyLinkFra = translations.field === "buyLink" ? translations.fra : null;
		editedCampaign.buyLinkIta = translations.field === "buyLink" ? translations.ita : null;
		// console.log(editedCampaign);
		this.endTranslationEditing.next(editedCampaign);
	}

   public updateCampaign(campaignData: string, image?: any): Promise<Rest.FidCampaigns> {

		// console.log("[CAMPAIGNS-SERVICE] *** updateCampaign ****");
		// console.log(campaignData);
		// console.log(image);

		const formData = new FormData();
		formData.append('image', image);
		formData.append('campaign', campaignData);

		return this.http
		.post(environment.webApiBaseUrl + `api/fid/campaigns/save`, formData)
		.toPromise()
		.then((response: any) => {
			 return response;
		})
		.catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
   }

	public getDeviceCommunicationList() {
		// console.log("[CAMPAIGN-SERVICE] getDeviceCommunicationList *** ");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.client
				.FidEnum_getDeviceCommunication()
				.then(
					function (response) {
						// console.log("response: " + response);
						that.deviceCommunicationList = response;
					},
					/* failure */
					function (error) {
					   console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getDeviceTypeList() {
		// console.log("[CAMPAIGN-SERVICE] getDeviceTypeList *** ");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.client
				.FidEnum_getDeviceType()
				.then(
					function (response) {
						// console.log("response: " + response);
						that.deviceTypeList = response;
					},
					/* failure */
					function (error) {
					   console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getGenderList() {
		// console.log("[CAMPAIGN-SERVICE] getGender *** ");
			const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.client
				.FidEnum_getGender()
				.then(
					function (response) {
						// console.log("response: " + response);
						that.genderList = response;
					},
					/* failure */
					function (error) {
					   console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getLanguageList() {
		// console.log("[CAMPAIGN-SERVICE] getLanguageList *** ");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.client
				.FidEnum_getLanguage()
				.then(
					function (response) {
						// console.log("response: " + response);
						that.languageList = response;
					},
					/* failure */
					function (error) {
					   console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public createSendNotif(queryParams): Promise<Rest.FidSendNotifControl> {
		return this.client.PromotedRoutes_sendNotifications(queryParams);
	}

	public getNotifControl(queryParams): Rest.RestResponse<Rest.FidSendNotifControl> {
		return this.client.PromotedRoutes_getNotifControl(queryParams);
	}

	public deleteNotifControl(options): Promise<boolean> {

		return this.http.delete(
			environment.webApiBaseUrl + 'api/fid/sendnotifcontrol', //?campaignId=' + campaignId
			options
		).toPromise()
			.then(() => {
				 return true;
			})
			.catch((error) => {
				 this.notificationsService.add(Severity.error, 'Error', error.message);
				 return false;
			});
	}

}
