import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { RestExt } from 'app/services/rest-client-extension';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
//import { DocFormComponent } from '../doc-form/doc-form.component';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-doc-table',
	templateUrl: './doc-table.component.html',
	styleUrls: ['./doc-table.component.css'],
	encapsulation: ViewEncapsulation.None,
	//providers: [DocumentationService]
})
export class DocTableComponent extends EntityListComponent<Rest.InvDocument> implements OnInit, OnDestroy {
	//@ViewChild('vehicleInfoCard') vehicleInfoCard: VehicleInfoCardComponent;

	//@ViewChild('docForm') docForm: DocFormComponent;

	@Input() type: string; // 'vehicle', 'driver', 'fines', 'taxes',...
	@Input() entity: any;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;
	@Input() nested?: Boolean = false;
	@Input() ids: number[] = [];

	@Input() showTitle: boolean = true;

	@Output() public return = new EventEmitter();

	title: string;
	optionsFilterType: any[];
	colspan: string = '';
	// allDocTypeList: Rest.InvDocumentType[] = [];
	vehicle: Rest.Vehicle = null;

	refreshListSuscription: Subscription;

	// PERMISSIONS
	isCreateGranted: boolean = true;
	isFormGranted: boolean = true;
	isDeleteGranted: boolean = true;
	isDownloadGranted: boolean = true;

	constructor(
		protected documentationService: DocumentationService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		private datePipe: DatePipe,
		private translate: TranslateService
	) {
		super(
			documentationService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime
		);
	}

	ngOnInit(): void {
		// console.log("[DOC-TABLE] ngOnInit *** ");
		this.type = this.type.toUpperCase()
		// console.log(this.type);
		// console.log(this.entity);
		// console.log(this.ids);
		super.ngOnInit();

		this.user = this.authenticationService.user;
		this.colspan = this.nested ? '5' : '6';
		switch (this.type) {
			case 'DRIVER':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeDriver);
				this.title = this.translate.instant('documents.table.titleDriver');
				break;
			case 'FINES':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFines);
				this.title = this.translate.instant('tableManagement.fine.documents');
				this.vehicle = this.entity.vehicle;
				break;
			case 'TAXES':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeTaxes);
				this.title = this.translate.instant('tableManagement.tax.documents');
				this.vehicle = this.entity.vehicle;
				break;
			case 'VEHICLE':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeVehicle);
				this.title = this.translate.instant('documents.table.titleVehicle');
				this.vehicle = this.entity;
				break;
			case 'INSURANCE':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeInsurance);
				this.title = this.translate.instant('tableManagement.insurance.documents');
				this.vehicle = this.entity.vehicle;
				break;
			case 'FINANCING':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFinancing);
				this.title = this.translate.instant('tableManagement.financing.documents');
				this.vehicle = this.entity.vehicle;
				break;
			case 'EXECUTION':
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeExecution);
				this.title = this.translate.instant('maintenance.table.documents');
				this.vehicle = this.entity.vehicle;
				break;
			default:
				this.optionsFilterType = RestExt.getEnumItemsNoTranslation(RestExt.DocumentType);
				//this.title = this.translate.instant('documents.table.titleVehicle');
				break;
		};
		this.refreshListSuscription = this.documentationService.refreshList.subscribe(
			(item: Rest.refreshDocListRequest) => {
				// console.log("******** UPDATE DOCUMENT LIST *********");
				// console.log(item);
				this.getList(item);
				this.return.emit();
			}
		);

		this.setPermissions();
		// this.getdocTypeList();
	}

	onDestroy() {
		this.refreshListSuscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authenticationService.user;

		switch (this.type) {
			case 'DRIVER':
				const rolesForFormDriver = ['INV_DRIVER_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_DRIVER_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_DRIVER_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_DRIVER_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormDriver, user);

				break;

			case 'FINES':
				const rolesForFormFines = ['INV_FINES_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_FINES_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_FINES_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_FINES_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormFines, user);

				break;

			case 'TAXES':
				const rolesForFormTaxes = ['INV_TAXES_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_TAXES_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_TAXES_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_TAXES_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormFines, user);

				break;

			case 'FINANCING':
				const rolesForFormFinancing = ['INV_FINANCING_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_FINANCING_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_FINANCING_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_FINANCING_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormFinancing, user);

				break;

			case 'INSURANCE':
				const rolesForFormInsurance = ['INV_INSURANCE_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormInsurance, user);

				break;

			case 'EXECUTION':
				const rolesForFormExecution = ['MAINTENANCE_EXECUTION_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormExecution, user);

				break;

			case 'VEHICLE':
				const rolesForFormVehicle = ['INV_VEHICLE_DOC_UPDATE'];

				this.isCreateGranted = this.authenticationService.isRoleGranted('INV_VEHICLE_DOC_CREATE', user);
				this.isDeleteGranted = this.authenticationService.isRoleGranted('INV_VEHICLE_DOC_DELETE', user);
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_VEHICLE_DOC_DOWNLOAD', user);
				this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForFormVehicle, user);

				break;

			default:
				break;
		}


	}

	downloadFile(document: Rest.InvDocument) {
		if (!this.isDownloadGranted) {
			return;
		}

		var imageEncoded = document.document.imageEncoded;
		var contentToReplace = imageEncoded.split(',')[0] + ',';
		const byteCharacters = atob(imageEncoded.replace(contentToReplace, ''));
		const mime = imageEncoded.split(':')[1].split(';')[0];
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mime });
		var date = this.datePipe.transform(
			document.updateDate != null ? document.updateDate : document.createDate,
			'yyyyMMdd'
		);
		// save the blob using the FileSaver module
		saveAs(blob, date + '_' + document.name);
	}

	public onDeleteDocument(document: Rest.InvDocument) {

		if (this.isDeleteGranted === false) {
			return;
		}

		this.confirmationService.confirm({
			accept: () => {
				this.deleteDocument(document);
			},
		});
	}

	deleteDocument(document: Rest.InvDocument) {
		var that = this;
		this.documentationService.deleteDocument(document.id).then((response) => {
			if (response) {
				that.notificationsService.add(Severity.success, '', this.translate.instant('general.deleteSuccessfull'));
				that.refresh();
			} else {
				that.notificationsService.add(Severity.error, '', this.translate.instant('general.deleteError'));
			}
		});
	}

	beforeLoad() {
		// console.log("[DOC-TABLE] beforeLoad *** ");
		// console.log(this.type);
		// console.log(this.entity);
		// console.log(this.ids);
		// console.log(this.vehicle);

		this.paginationRequest.filters['disabled'] = ['false'];
		if (this.ids && this.ids.length > 0) this.paginationRequest.filters['ids'] = this.ids.map(id => id.toString());
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
		this.paginationRequest.filters['type'] = [];
		switch (this.type) {
			case 'DRIVER':
				if (this.type && this.entity) this.paginationRequest.filters['driver'] = [this.entity.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeDriver)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'FINES':
				if (this.type && this.entity && this.entity.vehicle) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFines)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'VEHICLE':
				if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.entity.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeVehicle)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'TAXES':
				if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeTaxes)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'FINANCING':
				if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFinancing)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'INSURANCE':
				if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeInsurance)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			case 'EXECUTION':
				if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeExecution)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
			default:
				if (this.type && this.entity) this.paginationRequest.filters[this.type.toLowerCase()] = [this.entity.id.toString()];
				RestExt.getEnumItemsNoTranslation(RestExt.DocumentType)
					.forEach(x => this.paginationRequest.filters['type'].push(x.label));
				break;
		};
		console.log(this.paginationRequest);
	}

	afterLoad() {
		// console.log("[DOC-TABLE] afterLoad *** " + this.type);
		// console.log(this.paginationResult);
	}

	openDialog(isNew, type, entity, vehicle) {
		// console.log("[DOC-TABLE] openDialog *** ");
		//this.docForm.showDialog(isNew, type, entity, vehicle);
		if (!this.isFormGranted || !this.isCreateGranted) {
			return;
		}

		let docEdit = {
			isNew: isNew,
			type: type,
			entity: entity,
			vehicle: vehicle
		} as Rest.InvDocumentEdit;
		// console.log(docEdit);
		this.documentationService.startedEditing.next(docEdit);
	}

	public getList(item: Rest.refreshDocListRequest) {
		// console.log("[DOC-TABLE] getList *** ");
		// console.log(this.type + " - " + item.type);
		if (this.type === item.type) {
			// console.log("[DOC-TABLE] getList.if *** ");
			// console.log(this.ids);
			this.paginationRequest.filters = {};
			this.beforeLoad();
			//this.paginationRequest.filters['disabled'] = ['false'];
			switch (this.type) {
				// case 'DRIVER':
				// 	break;
				case 'TAXES':
				case 'FINANCING':
				case 'INSURANCE':
				case 'EXECUTION':
				case 'FINES':
					if (this.type && this.entity) this.paginationRequest.filters['vehicle'] = [this.vehicle.id.toString()]; //[this.entity.vehicle.id.toString()];
					if (item.newDoc) {
						if (!this.ids || (this.ids && this.ids[0] === -1)) this.ids = [item.newDoc];
						else this.ids.push(item.newDoc);
					}
					this.paginationRequest.filters['ids'] = this.ids.map(id => id.toString());
					// console.log(this.paginationRequest.filters['ids']);
					break;
				// case 'VEHICLE':
				// 	break;
				default:
					if (this.type && this.entity) this.paginationRequest.filters[this.type.toLowerCase()] = [this.entity.id.toString()];
					break;
			};
			//si ens envien noves dates al refresh
			this.startDate = item.startDate;
			this.endDate = item.endDate;
			if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
			if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
			console.log(this.paginationRequest);
			super.refresh();
		}
	}

}
