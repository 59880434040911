import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-external-battery-alarm-configuration-we-track2',
  templateUrl: './external-battery-alarm-configuration-we-track2.component.html',
  styleUrls: ['./external-battery-alarm-configuration-we-track2.component.css'],
})
export class ExternalBatteryAlarmConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit {

  @Input() externalBatteryAlarm: Rest.ExternalBatteryAlarmConfigurationWeTrack2;
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  BatteryAlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"SMS_GPRS"}, 
    {label: "GPRS+SMS+phone", value:"GPRS_SMS_PHONECALL"},
    {label: "GPRS+CALL", value:"GPRS_CALL"}          
  ];
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
    if(this.externalBatteryAlarm!=null){this.disableDIV(!this.externalBatteryAlarm.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.externalBatteryAlarm);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
