import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { FleetService } from "../../../rest/fleet.service";
import { Rest } from "../../../rest/rest_client";
import {
  NotificationsService,
  Severity,
} from "../../../services/notifications-service/notifications.service";
import { ServerValidatedFormComponent } from "../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-fleet-form",
  templateUrl: "./fleet-form.component.html",
  styleUrls: ["./fleet-form.component.css"],
  providers: [FleetService, ConfirmationService],
})
export class FleetFormComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  fleet: Rest.Fleet;
  fleetName: string;
  display: Boolean;
  fleetService: FleetService;
  confirmationService: ConfirmationService;
  notificationsService: NotificationsService;

  isNew: Boolean;
  showChangeStatus: Boolean;

  /**#ChangeStatus */
  showStatusDialog: boolean = false;
  selectedEntities: Rest.Fleet[] = [];
  title: string;
  /*************** */

  /**Permissions */
  permissions: any;
  /** */

  // eslint-disable-next-line
  @Output("newFleet")
  // eslint-disable-next-line
  change: EventEmitter<Rest.Fleet> = new EventEmitter<Rest.Fleet>();
  // eslint-disable-next-line
  @Output("refreshList")
  refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() return = new EventEmitter();
  @Output() selectNode = new EventEmitter();

  @Input() checkBoxDisabled: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    fleetService: FleetService,
    protected i18n: I18nService,
    confirmationService: ConfirmationService,
    notificationsService: NotificationsService
  ) {
    super(i18n);
    this.fleetService = fleetService;
    this.confirmationService = confirmationService;
    this.notificationsService = notificationsService;
  }

  ngOnInit() {
    this.fleet = { metadata: {} } as Rest.Fleet;
    /**#ChangeStatus */
    this.title = this.i18n._("Fleets");
    /************** */
  }

  onHide(event) {}

  beforeCreate() {}

  show(f: Rest.Fleet, permissions: any) {
    this.clearMessages();
    //show(f: any) {
    this.permissions = permissions;

    if (f == null) {
      this.fleet = { metadata: {} } as Rest.Fleet;
      this.isNew = true;
      this.showChangeStatus = false;
    } else {
      this.isNew = false;
      this.fleet = f;
      this.showChangeStatus = true;
      this.fleetName = this.fleet.name;
    }
    this.display = true;
  }

  refresh() {
    this.display = false;
    this.refreshList.emit(true);
  }

  saveFleet() {
    this.clearMessages();
    const that: FleetFormComponent = this;
    if (this.isNew) {
      this.fleetService
        .create(this.fleet)
        .then(function (res) {
          that.change.emit(res);
          that.display = false;
        })
        .catch((e) => {
          this.handleError(e, that);
        });
    } else {
      this.fleetService
        .update(this.fleet)
        .then(function (res) {
          that.change.emit();
          that.display = false;
          that.notificationsService.add(
            Severity.success,
            that.i18n._(":)"),
            that.i18n._("Entities updated")
          );
        })
        .catch((e) => {
          this.handleError(e, that);
        });
    }
  }

  /** #ChangeStatus */
  changeStatus() {
    for (var i = this.selectedEntities.length - 1; i >= 0; i--) {
      this.selectedEntities.splice(i, 1);
    }
    this.selectedEntities.push(this.fleet);
    this.showStatusDialog = true;
  }

  refreshForm(event) {
    this.selectedEntities = [];
    this.closeStatusDialog(false);
    this.return.emit(true);
    this.selectNode.emit();
    var that = this;
    this.fleetService
      .findFleet(this.fleet.id.toString())
      .then(function (res) {
        that.show(res, that.permissions);
      })
      .catch((e) => {
        this.handleError(e, that);
      });
  }

  closeStatusDialog(event) {
    var close: boolean;
    close = event;
    this.showStatusDialog = close;
  }
  /***************** */
}
