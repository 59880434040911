import { Component } from "@angular/core";
import { SelectItem } from "primeng/api";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { MaintenanceplanService } from "../../rest/maintenanceplan.service";
import { Rest } from "../../rest/rest_client";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityFormComponent } from "../entity-form/entity-form.component";

@Component({
    selector: "app-maintenance-plan-form",
    templateUrl: "./maintenance-plan-form.component.html",
    styleUrls: ["./maintenance-plan-form.component.css"],
    providers: [MaintenanceplanService],
})
export class MaintenancePlanFormComponent extends EntityFormComponent<Rest.MaintenancePlan> {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: String;
    /**Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private maintenanceplanService: MaintenanceplanService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
        super(
            maintenanceplanService,
            notificationsService,
            i18n,
            authenticationService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        const that = this;
    }

    beforeShow() {
        /**Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.i18n._("Create Mantenance Plan");
            this.entity.owner = this.authenticationService.user;
        } else {
            this.title = this.i18n._("Edit Mantenance Plan");
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */
    }
}
