import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";

import RealState = Rest.RealState;

@Injectable()
export class RealStateService extends EntityService<RealState> {
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: RealState): Rest.RestResponse<RealState> {
        try {
            return this.client.RealState_create(arg0);
        } catch (error) {
            console.error("Error creating RealState:", error);
            throw error;
        }
    }

    update(arg0: RealState): Rest.RestResponse<RealState> {
        try {
            return this.client.RealState_update(arg0);
        } catch (error) {
            console.error("Error updating RealState:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<RealState>> {
        try {
            return this.client.RealState_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting RealState page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.RealState_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing RealState status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<RealState> {
        try {
            return this.client.RealState_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding RealState:", error);
            throw error;
        }
    }
}