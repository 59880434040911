<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Timer Report Configuration ($ST+TIMER)</span>
      </div>
      <div class="right">
          <p-inputSwitch [(ngModel)]="timerReport.enabled" (onChange)="handleChange($event)"></p-inputSwitch>
      </div>  
    </div>
  </p-header>
  <div class="ui-g">

    <div class="ui-g" #ContentDiv>
              
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Preset Time (hhmmss)" >
          <div class="ui-inputgroup">
            <input pattern="((0[1-9]|1[0-9]|2[0-3])[0-5][0-9][0-5][0-9]|235959)"type="number" pInputText [(ngModel)]="timerReport.presetTime"/>
          </div>
        </form-input>
    </div>    
  </div>
  </div>
</p-accordionTab>
