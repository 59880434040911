import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { CardConsumptionService } from 'app/modules/vehicle-inventory/services/table-management/cardConsumption.service';

import CardConsumption = Rest.CardConsumption;
import { CardService } from 'app/rest/card.service';
import { RestExt } from 'app/services/rest-client-extension';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';

@Component({
    selector: 'app-cardConsumption-form',
    templateUrl: './cardConsumption-form.component.html',
    styleUrls: ['./cardConsumption-form.component.scss'],
    providers: [CardConsumptionService, CardService],
    encapsulation: ViewEncapsulation.None,
})
export class CardConsumptionFormComponent
    extends EntityFormComponent<CardConsumption>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    dateConsumption: Date;

    // Preservation of relationships
    cardRequestContext: Rest.ListPaginationRequest;
    cardList: Rest.Card[] = [];

    @Input() card: Rest.Card;
    @Input() vehicle: Rest.Vehicle;
    cardPlaceholder: string = '';
    editCard: boolean = true;
    consumptionDate: Date;

    constructor(
        private cardConsumptionService: CardConsumptionService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        public translateService: TranslateService,
        private cardService: CardService,
        private userTime: UserTimePipe,
    ) {
        super(cardConsumptionService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof CardConsumptionFormComponent
     */
    beforeShow() {
        this.dateConsumption = null;
        this.cardList = [];
        this.cardRequestContext = RestExt.firstPageRequestVehicleInventory();


        if(this.vehicle){
            this.cardRequestContext.filters["vehicle"] = [this.vehicle.name];
        }

        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.cardsConsumption.createCardConsumption');
        } else {
            this.title = this.translateService.instant('tableManagement.cardsConsumption.editCardConsumption');

            //#region PRESERVATION OF RELATIONSHIPS
            //CARD


            if(this.entity.card){
                this.cardRequestContext.filters["number"] = [this.entity.card.number];
            }


            //#endregion PRESERVATION OF RELATIONSHIPS

            this.dateConsumption = this.userTime.utcToCalendarDate(
                this.entity.consumptionDate
            );

            
        }

        this.cardService.getPage(this.cardRequestContext).then((p) => {
            this.cardList = p.entities;
        });

        this.cardPlaceholder = this.translateService.instant('general.select');
        this.cardPlaceholder += ' ';
        this.cardPlaceholder += this.translateService.instant('tableManagement.cardsConsumption.card');

        if (this.card !== null && this.card !== undefined) {
            this.entity.card = this.card;
            this.cardPlaceholder = this.card.number;
            this.editCard = false;
        }
    }

    onChangeCard(event) {
        this.entity.card = event.value;
    }

    onFilterCard(event) {
        this.cardRequestContext = {
            sortBy: "number",
            sortAsc: true,
            pageSize: 10,
        } as unknown as Rest.ListPaginationRequest;

        if(this.vehicle){
            this.cardRequestContext.filters["vehicle"] = [this.vehicle.name];
        }

        this.cardService.getPage(this.cardRequestContext).then((p) => {
            this.cardList = p.entities;
        });
    }

    onKeyDownDecimal(event: any) {
        var pressedKey = event.key;
        const keyMap = new Map<number, string>();
        keyMap.set(190, ",");
        const { keyCode } = event;
        if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
            event.preventDefault();
            event.target.value += ',';
        }
    }

    protected beforeSave(): void {
        this.entity.consumptionDate = this.dateConsumption;
    }
}