import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-speed-alarm-parameters-gl320m',
  templateUrl: './speed-alarm-parameters-gl320m.component.html',
  styleUrls: ['./speed-alarm-parameters-gl320m.component.css']
})
export class SpeedAlarmParametersGl320mComponent extends ServerValidatedFormComponent implements OnInit {

  modeOptions: any = [
    { label: "Disable speed alarm", value: "DISABLE" },
    { label: "Enable when current speed in the range", value: "REPORT_IN" },
    { label: "Enable when current speed outside the range", value: "REPORT_OUT" },
    { label: "Enable when current speed withing or outside the range", value: "REPORT_IN_OR_OUT" },
    { label: "Enable when current speed changed from inside to outside to inside of the speed range", value: "REPORT_CHANGED_IN_TO_OUT_OR_OUT_TO_IN" },
  ];
  
  @Input() spdParameters: Rest.SpeedAlarmParametersGL320M;
  
  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.spdParameters);
    }
  }
}
