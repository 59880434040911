import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-roaming-event-form',
  templateUrl: './roaming-event-form.component.html',
  styleUrls: ['./roaming-event-form.component.css'],

})
export class RoamingEventFormComponent extends ServerValidatedFormComponent implements OnChanges {

  constructor(protected i18n: I18nService) {  super(i18n);}  @Input() roaming: Rest.Roaming;
  @ViewChild('roamingEvent') roamingEvent;


  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
  /**
   * Needed to prevent toggle of UI component
   * @param event 
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
   
  }
  

}
