<p-dialog header={{title}} [(visible)]="display" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="true" (onHide)="close($event)"
  styleClass="pDialog" [contentStyle]="{'overflow-y':'visible', 'max-height':'700px', 'min-width':'280px'}">

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <p-tabView styleClass="customTabView" #tv>

      <!-- ZONE GROUP DATA PANEL -->
      <p-tabPanel i18n-header header={{zoneGroupLabel}} class="tabPanel">

        <div (click)="focusOutOfMap()">
          <div class="container">

            <!-- ROW 1 -->
            <div class="row align-items-center">

              <div class="col">
                <form-input [required]="true" i18n-fieldName fieldName={{nameLabel}} [errors]="errors.name">
                  <input pInputText id="name" [(ngModel)]="entity.name" [style]="{'width':'100px'}" />
                </form-input>
              </div>

              <div class="col">
                <form-input i18n-fieldName fieldName={{headquartersLabel}} [errors]="errors.headquarters">
                  <div class="d-flex justify-content-center">
                    <p-checkbox [(ngModel)]="entity.headquarters" binary="true"></p-checkbox>
                  </div>
                </form-input>
              </div>

            </div>

            <!-- ROW 2 -->
            <div class="row align-items-center">

              <!-- Enabled checkbox -->
              <div class="col">
                <form-input i18n-fieldName fieldName={{enabledLabel}} [errors]="errors.enabled">
                  <div class="d-flex justify-content-center">
                    <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
                  </div>
                </form-input>
              </div>

              <!-- Events selector -->
              <div class="col">
                <form-input i18n-fieldName fieldName={{eventsLabel}} [errors]="errors.numEventsValid"
                  *ngIf="user.isAdmin">
                  <p-dropdown [options]="numevents" [(ngModel)]="entity.numEventsValid"
                    [style]="{'width':'auto'}"></p-dropdown>
                </form-input>
              </div>

            </div>

          </div>
        </div>

        <!-- <div (click)="focus()">
          <div class="disableDIV" #mapPH *ngIf="showMap">
            <app-custom-map #map mapId="map-zoneGroup" [display]="display" [options]="customMapOptions"
              [zones]="entity.zones" (loadMap)="initMapAfterViewInit($event)"></app-custom-map>
          </div>
        </div> -->

      </p-tabPanel>

      <!-- FLEETS PANEL -->
      <p-tabPanel i18n-header header={{fleetsLabel}} class="tabPanel">

        <div (click)="focusOutOfMap()" *ngIf="display">
          <div class="ui-g" [style]="{'overflow-y':'auto', 'overflow-x':'hidden', 'max-height':'340px'}">
            <app-fleet-tree [viewDisableds]=false [(selection)]="fleetsGroupsAndVehiclesSelected"
              [cascadeSelection]=true [selectableEntities]=false></app-fleet-tree>
          </div>
        </div>
      </p-tabPanel>

    </p-tabView>
  </div>

  <p-footer>
    <div class="d-flex justify-content-end">
      <div *ngIf="disableSave === false">
        <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label={{saveLabel}}
          class="SaveButton"></button>
      </div>

      <div *ngIf="disableSave === true">
        <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
          <button disabled="true" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
            label={{saveLabel}}></button>
        </div>
      </div>

      <div *ngIf="isNew === false">
        <div *ngIf="disabeChangeStatus === false">
          <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
            label={{changeStatusLabel}}></button>
        </div>

        <div *ngIf="disabeChangeStatus === true">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status"
            tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)"
              i18n-label label={{changeStatusLabel}}></button>
          </div>
        </div>
      </div>

    </div>
  </p-footer>

</p-dialog>