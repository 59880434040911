import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-virtual-ignition-selection-gmt200',
  templateUrl: './virtual-ignition-selection-gmt200.component.html',
  styleUrls: ['./virtual-ignition-selection-gmt200.component.css'],
})
export class VirtualIgnitionSelectionGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() vmsParameters: Rest.VirtualIgnitionSelectionGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }

  modeOptions: any = [
    {label: "Disable the virtual ignition detection function", value:"DISABLE"},
    {label: "Motion status to simulate ignition status", value:"IGNITION_STATUS"},
    {label: "Voltage virtual ignition detection mode", value:"VOLTAGE_DETECTION_MODE"},
    {label: "Accelerometer virtual ignition detection mode", value:"ACCELEROMETER_DETECTION_MODE"},
    {label: "Combined detection mode", value:"COMBINED_DETECTION_MODE"}
  ]

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.vmsParameters);
    }
  }

}
