import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from "@angular/core";
import { ConfirmationService } from "primeng/api";

import { Rest } from "../../../rest/rest_client";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { ActuatorFormComponent } from "../actuator-form/actuator-form.component";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";

@Component({
    selector: "app-actuators-list",
    templateUrl: "./actuator-list.component.html",
    styleUrls: ["./actuator-list.component.css"],
    providers: [ConfirmationService],
})
export class ActuatorListComponent implements OnInit {
    @ViewChild(ActuatorFormComponent, { static: true })
    actuatorForm: ActuatorFormComponent;
    @Input() actuators: Rest.Actuator[];
    @Output() actuatorsChange: EventEmitter<Rest.Actuator[]> = new EventEmitter<Rest.Actuator[]>();

    selectedEntities: Rest.Actuator[];
    private lastAssignedId = 0;
    user: Rest.User;

    isSelectableColumn: boolean = false;
    isColumn: boolean = false;

    /**Check box Column selection */
    entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
    selectedAll: boolean = false;
    disableSelectAll: boolean = true;

    /** */

    constructor(
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.selectedEntities = [];

        if (this.actuators == null) this.actuators = [];
        this.actuatorForm.setFormSubscriptor((ret: any) => {
            if (ret.id == undefined) ret.id = this.lastAssignedId--;
            let listActuator = this.actuators.find((a: Rest.Actuator) => {
                return a.id == ret.id;
            });
            if (listActuator != null) {
                Object.assign(listActuator, ret);
            } else {
                this.actuators.push(ret);
            }
            this.actuators = [...this.actuators];
            this.actuatorsChange.emit(this.actuators);
        });

        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    onRowDblClick(event: any) {
        this.actuatorForm.update(event.data);
    }

    create() {
        this.actuatorForm.create();
    }

    delete() {
        this.selectedEntities.forEach((a: Rest.Actuator) => {
            let idx = this.actuators.indexOf(a);
            if (idx >= 0) this.actuators.splice(idx, 1);
        });
        this.selectedEntities = [];
        this.actuators = [...this.actuators];
        this.actuatorsChange.emit(this.actuators);
    }

    /**CheckBox Colum selection */
    checkSelectAll() {
        for (var a in this.actuators) {
            //if (this.sensors[a]['_$changeStatus'] == true) {
            this.disableSelectAll = false;
            //}
        }
    }

    selectAll(select: boolean) {
        if (select) {
            for (var a in this.actuators) {
                //if (this.sensors[a]['_$changeStatus'] == true) {
                if (
                    this.selectedEntities.some((x) => x == this.actuators[a]) == false
                ) {
                    this.selectedEntities.push(this.actuators[a]);
                }
                this.entitiesSelected[this.actuators[a]["id"]] = select;
                //}
            }
        } else {
            for (var i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
                this.selectedEntities.splice(i, 1);
            }
            for (var a in this.entitiesSelected) {
                this.entitiesSelected[a] = select;
            }
        }
    }

    changeSelection(id: number, value: boolean) {
        this.entitiesSelected[id] = value;
        if (value) {
            for (var a in this.actuators) {
                if (this.actuators[a]["id"] == id) {
                    this.selectedEntities.push(this.actuators[a]);
                }
            }
        } else {
            for (var i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
                if (this.selectedEntities[i]["id"] == id) {
                    this.selectedEntities.splice(i, 1);
                }
            }
            this.selectedAll = false;
        }
    }

    selectableColumn() {
        this.isSelectableColumn = true;
    }

    editDialog(event) {
        if (!this.isSelectableColumn) {
            this.onRowDblClick({ data: event });
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    /** */
}
