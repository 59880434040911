<!-- CONVERSION ALGORITHMS PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate="
         {{ 'table.showing' | translate }} {first}
         {{ 'table.to' | translate }} {last}
         {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      Conversion Algorithms Table
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- TYPE -->
      <th>
        <!-- COLUMN TITLE -->
        <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
          {{ 'general.type' | translate }}
        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-conversionAlgorithm>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(conversionAlgorithm)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="conversionAlgorithm"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{conversionAlgorithm.name}}
      </td>

      <!-- TYPE -->
      <td>
        {{conversionAlgorithm.type}}
      </td>

    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="9" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD CONVERSION ALGORITHM BUTTON -->
          <app-table-add-button text="{{ 'control-panel.devices.device' | translate }}"
                                (btnClick)="create()"
                                [isDisabled]="!user.isAdmin"
          ></app-table-add-button>

          <!-- DELETE BUTTON -->
          <app-table-delete-button (btnClick)="delete()"
                                   [isDisabled]="!user.isAdmin || selectedEntities.length === 0"
          ></app-table-delete-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- DELETE DIALOG -->
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle"></p-confirmDialog>

<!-- CONVERSION ALGORITHM FORM -->
<app-conversion-algorithm-form></app-conversion-algorithm-form>
