import { Injectable } from '@angular/core';
import { EntityService } from 'app/rest/entity.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';

@Injectable({
	providedIn: 'root'
 })
export class VehicleAccessoryService extends EntityService<Rest.InvVehicleAccessory> {

	inventoryVehicleClient: Rest.RestApplicationClient;
	client: Rest.RestApplicationClient;
	startedEditing = new Subject<Rest.InvVehicleAccessory>();
	startedCreating = new Subject();
	refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

   constructor(
		private jquery: JQueryHTTPClient
	) {
	  super();
	  this.inventoryVehicleClient = new Rest.RestApplicationClient(jquery);
	  this.vehicleClient = new Rest.RestApplicationClient(jquery);
   }

	init(vehicle: Rest.Vehicle) :  Rest.InvVehicleAccessory {
		return {
			id: 0,
			vehicle: vehicle,
			description: "",
			installationDate: null,
			desinstallationDate: null,
			alarmType: null,
			stationCode: "",
			serialNumber: "",
			value: 0,
			verified: false,
			verificationDate: null,
			extrasType: null,
			observations: "",
			client: vehicle.client,
			disabled: false
		};
	}

	create(arg0: Rest.InvVehicleAccessory): Rest.RestResponse<Rest.InvVehicleAccessory> {
		return this.inventoryVehicleClient.InvVehicleAccessory_create(arg0);
  	}

  	update(arg0: Rest.InvVehicleAccessory): Rest.RestResponse<Rest.InvVehicleAccessory> {
		return this.inventoryVehicleClient.InvVehicleAccessory_update(arg0);
  	}

	updateVerified(arg0: Rest.InvVehicleAccessory): Rest.RestResponse<Rest.InvVehicleAccessory> {
		return this.inventoryVehicleClient.InvVehicleAccessory_updateVerified(arg0);
  	}

  	getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvVehicleAccessory>> {
		return this.inventoryVehicleClient.InvVehicleAccessory_getPage(arg0);
  	}

  	changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
		return this.inventoryVehicleClient.InvVehicleAccessory_changeStatus(arg0, queryParams);
  	}

  	find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.InvVehicleAccessory> {
		return this.inventoryVehicleClient.InvVehicleAccessory_find(arg0, queryParams);
  	}

}
