
/* This file contains all the functions needed for
   generating a fullscreen button for our cercalia map.
   Used in custom-map.component.ts */
exports.createCustomFullScreencontrol=function(map) {

    var isFullScreen = false;
    var fullscreenControl = new cercalia.control.Custom({
        svgPath: 'M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z',
        title: 'Full Screen',
        clickFunction: function () {
				console.log(isFullScreen);
            if (!isFullScreen) {
                var divMap = document.getElementById(map.getContainerId());
                requesFullScreen(divMap);
            } else {
                exitFullScreen();
            }
            isFullScreen = !isFullScreen;
				console.log(isFullScreen);
        }
    });

    return fullscreenControl;
}


function requesFullScreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
}

/**
 *
 */
function exitFullScreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
}
