import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFilterColumnsAutoFocus]'
})
export class FilterColumnsAutoFocusDirective {

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    // Note: setTimeout is only needed for use-case where user clicks column filter when another one is already open

    new MutationObserver(() => {
      setTimeout(() => {
        (document.querySelector('p-columnfilterformelement .p-inputtext') as HTMLElement)?.focus();
      }, 50);
    }).observe(this.elRef.nativeElement, { attributes: false, childList: true, subtree: true })
  }

}
