import { UteService } from "app/rest/ute.service";
import { I18nService } from "app/services/i18n/i18n.service";
import { NotificationsService } from "app/services/notifications-service/notifications.service";
import { LoadingService } from "app/services/loading-service/loading.service";
import { RestExt } from "app/services/rest-client-extension";
import { Rest } from "app/rest/rest_client";
import { RealtimeService } from "app/rest/realtime.service";
import { ZonegroupService } from "app/rest/zonegroup.service";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";
import {
  Component,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
} from "@angular/core";
import { Configuration } from "../models/Configuration";
import { paletterColor } from "assets/constants/viewsConstants";
import { Router } from "@angular/router";

@Component({
  selector: "app-ute-summary-table",
  templateUrl: "./ute-summary-table.component.html",
  styleUrls: ["./ute-summary-table.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [UteService, ZonegroupService],
})
export class UteSummaryTableComponent implements OnInit, OnChanges {
  header = "";
  loading = false;
  rangeDates: Date[] = [];
  openSummary = false;
  rows: any = [];

  // Contains the id of the header that is sortedby
  sortField: string; // ="lastMaintenanceDate";
  // Sort order as number, 1 for asc and -1 for dec in single sort mode
  sortOrder: number; // = 1;
  //expandedHeaders
  expandedHeaders = true;

  //Table columns

  cols: any = [];

  @Input() pdc_Events: any[];
  @Input() configuration: Configuration;
  @Output() followVehicleEvent = new EventEmitter<number[]>();

  constructor(
    private zoneGroupService: ZonegroupService,
    private uteService: UteService,
    protected i18n: I18nService,
    private notificationsService: NotificationsService,
    private loadingService: LoadingService,
    private realTimeService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private router: Router
  ) {
    /*
     for (let i = 0; i < this.pdc_Events.length; i++) {

      }
   */
    var test = "";
  }
  zones: Rest.Zone[];

  async show() {
    this.getAllZoneGroups();
  }

  allZones: any = {};

  getAllZoneGroups() {
    this.rows = [];
    this.sortPdcEvents("zoneGroup");
    this.setColumns();
    this.groupEvents();
    this.openSummary = true;
    const that = this;
  }

  groupEvents() {
    var currentGroup = "";
    var field1Count = 0;
    var field2Count = 0;
    var field3Count = 0;
    var field4Count = 0;
    var blackCount = 0;
    var redCount = 0;
    var yellowCount = 0;
    var greenCount = 0;
    var detectedCount = 0;
    var pdcCount = 0;
    
    if (this.pdc_Events != null && this.pdc_Events.length > 0) {
      var currentGroupPosition = 0;
      var lengthColoring = this.configuration.coloring.info ? this.configuration.coloring.info.length : 0;
      for (let i = 0; i < this.pdc_Events.length; i++) {
        if (currentGroup != this.pdc_Events[i].controlPoint.zoneGroup.name) {
          currentGroup = this.pdc_Events[i].controlPoint.zoneGroup.name;
          currentGroupPosition = this.rows.length;
          var count = this.getZonesCount(currentGroup);
          this.rows[currentGroupPosition] = new Object();
          
          this.rows[currentGroupPosition].fields = new Array(lengthColoring);
          for (let j = 0; j < lengthColoring; j++) {
            this.rows[currentGroupPosition].fields[j] = new Object();
            this.rows[currentGroupPosition].fields[j].count = 0;
            this.rows[currentGroupPosition].fields[j].color = this.configuration.coloring.info[j].value;
            this.rows[currentGroupPosition].fields[j].column = "fields_"+j;
          }

          this.rows[currentGroupPosition].group = currentGroup;
          this.rows[currentGroupPosition].total_detected = 0;
          this.rows[currentGroupPosition].pdc_count = count;
          //this.rows[currentGroupPosition].field_4 = 0;
          //this.rows[currentGroupPosition].field_1 = 0;
          //this.rows[currentGroupPosition].field_2 = 0;
          //this.rows[currentGroupPosition].field_3 = 0;
          this.rows[currentGroupPosition].color = "red";
          this.rows[currentGroupPosition].isTotal = false;
          pdcCount = pdcCount + count;
        }

        this.rows[currentGroupPosition].total_detected++;
        // this.rows[currentGroupPosition].pdc_count++;

        detectedCount++;
        //pdcCount++;

        for (let k = 0; k < lengthColoring; k++) {
          if(this.configuration.coloring.info[k].value == this.pdc_Events[i].color){
            this.rows[currentGroupPosition].fields[k].count = this.rows[currentGroupPosition].fields[k].count + 1; 
          }
        }

       /* if (this.pdc_Events[i].color == "green") {
          this.rows[currentGroupPosition].field_1++;
          greenCount++;
        } else if (this.pdc_Events[i].color == "yellow") {
          this.rows[currentGroupPosition].field_2++;
          yellowCount++;
        } else if (this.pdc_Events[i].color == "red") {
          this.rows[currentGroupPosition].field_3++;
          redCount++;
        } else if (this.pdc_Events[i].color == "black") {
          this.rows[currentGroupPosition].field_4++;
          blackCount++;
        }*/
      }
      //Count row
      this.rows[currentGroupPosition + 1] = new Object();
      this.rows[currentGroupPosition + 1].group = "TOTAL";
      this.rows[currentGroupPosition + 1].total_detected = detectedCount;
      this.rows[currentGroupPosition + 1].pdc_count = pdcCount;
      this.rows[currentGroupPosition + 1].fields = new Array(lengthColoring);

      for (let j = 0; j < lengthColoring; j++) {
        this.rows[currentGroupPosition + 1].fields[j] = new Object();
        this.rows[currentGroupPosition + 1].fields[j].count = this.rows[currentGroupPosition].fields[j].count;
        this.rows[currentGroupPosition + 1].fields[j].color = this.rows[currentGroupPosition].fields[j].color;
        this.rows[currentGroupPosition + 1].fields[j].column = this.rows[currentGroupPosition].fields[j].column;
      }

      //this.rows[currentGroupPosition + 1].field_4 = blackCount;
      //this.rows[currentGroupPosition + 1].field_1 = greenCount;
      //this.rows[currentGroupPosition + 1].field_2 = yellowCount;
      //this.rows[currentGroupPosition + 1].field_3 = redCount;
      this.rows[currentGroupPosition + 1].color = "red";
      this.rows[currentGroupPosition + 1].isTotal = true;

      //Percetage row
      this.rows[currentGroupPosition + 2] = new Object();
      this.rows[currentGroupPosition + 2].group = "";
      this.rows[currentGroupPosition + 2].total_detected = "";
      this.rows[currentGroupPosition + 2].pdc_count = "";

      this.rows[currentGroupPosition + 2].fields = new Array(lengthColoring);
      for (let j = 0; j < lengthColoring; j++) {
        this.rows[currentGroupPosition + 2].fields[j] = new Object();
        this.rows[currentGroupPosition + 2].fields[j].count = ((this.rows[currentGroupPosition].fields[j].count * 100) / detectedCount).toFixed(2) + "%";
        this.rows[currentGroupPosition + 2].fields[j].color = this.rows[currentGroupPosition].fields[j].color;
        this.rows[currentGroupPosition + 2].fields[j].column = this.rows[currentGroupPosition].fields[j].column;
    }



      //this.rows[currentGroupPosition + 2].field_4 =
        //((blackCount * 100) / detectedCount).toFixed(2) + "%";
      //this.rows[currentGroupPosition + 2].field_1 =
        //((greenCount * 100) / detectedCount).toFixed(2) + "%";
      //this.rows[currentGroupPosition + 2].field_2 =
        //((yellowCount * 100) / detectedCount).toFixed(2) + "%";
      //this.rows[currentGroupPosition + 2].field_3 =
        //((redCount * 100) / detectedCount).toFixed(2) + "%";
      this.rows[currentGroupPosition + 2].color = "red";
      this.rows[currentGroupPosition + 2].isTotal = true;
    }
  }

  getZonesCount(groupName) {
    var zonesCount = 0;
    for (let i = 0; i < this.allZones.page.entities.length; i++) {
      if (this.allZones.page.entities[i].name == groupName) {
        zonesCount = this.allZones.page.entities[i].zones.length;
        break;
      }
    }
    return zonesCount;
  }

  setColumns() {
    this.cols = [];
    var legend = null;
    legend = this.configuration.coloring?.info;
    /*if (this.configuration.maintenanceContributesToVigilance) {
      legend = paletterColor.MaintenanceColors;
    } else {
      legend = paletterColor.vigilanceColors;
    }*/

    if(legend){
      for (let i = 0; i < legend.length; i++) {
        var pos = i;
        this.cols[pos] = {
          header: legend[i].condition,
          shortHeader: legend[i].condition,
          field: "fields_"+pos,
          visible: true,
          filter: false,
          width: "auto",
        };
      }

    }

    this.cols[this.cols.length] = {
      header: "Detectats",
      shortHeader: "Total",
      field: "total_detected",
      visible: true,
      filter: false,
      width: "auto",
    };
    this.cols[this.cols.length] = {
      header: "Número",
      shortHeader: "Número",
      field: "pdc_count",
      visible: true,
      filter: false,
      width: "auto",
    };


    /* this.cols =[
          //TODO: uncomment
          {header: this.i18n._("Control Point"), shortHeader: this.i18n._("C Point"), field:"group", visible: true, filter:false},
          {header: this.i18n._("Last Maintenance Date"), shortHeader: this.i18n._("M Last Date"), field:"field_1", visible: true, filter: false},
          {header: this.i18n._("Maintenance Left Time"), shortHeader: this.i18n._("M Left Time"), field:"field_2", visible: true, filter: false},

          {header: this.i18n._("Last Vigilance Date"), shortHeader: this.i18n._("V Last Date"), field:"field_3", visible: true, filter:false},
          {header: this.i18n._("Vigilance Left Time"), shortHeader: this.i18n._("V Left Time"), field:"field_4", visible: true, filter:false},

          {header: this.i18n._("Minor Time Remaining"), shortHeader: this.i18n._("T Remaining"), field:"minorTimeRemaining", visible: true, filter:false},
          {header: this.i18n._("Vehicle"), shortHeader: this.i18n._("Vehicle"), field:"vehicle", visible: true, filter:false},


      ];*/
  }

  async loadData() {}

  closeSummary() {
    this.openSummary = false;
  }

  //@Output('controlPointClicked') controlPointClickedEvent = new EventEmitter<any>();
  //@Output('vehicleClicked') vehicleClickedEvent = new EventEmitter<any>();
  //@Output() focusOnControlPointClicked = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    /** Secure the rows atribute is the one changing */
    /*
    if(changes.rows){
      this.sortRows();
    }
    */ const that = this;

    this.zoneGroupService
      .getPageAndFilters(RestExt.allRecordsRequest())
      .then(
        function (response) {
          that.allZones = response;
          try {
            that.rows = [];
            that.sortPdcEvents("zoneGroup");
            that.groupEvents();
          } catch (error) {
            var test = error;
          }

          /* that.paginationResult = response.page;
            that.filters = response.filters;
            that.loadFilters = false;
            for (var a in response.page.permissions) {
              that.permissions[RestExt.ActionEnum[a]] = response.page.permissions[a];
            }

            for (var a in response.page.entities) {
              that.entitiesSelected[response.page.entities[a]['id']] = false;
            }
            that.selectedAll = false;
            that.loading = false;
            that.loadingService.decreaseRESTOngoingRequestCounter();
            resolve();
            */
        },
        function (error) {
          // that.loadingService.decreaseRESTOngoingRequestCounter();
          //resolve();
        }
      )
      .catch(function (reason) {
        // that.loadingService.decreaseRESTOngoingRequestCounter();
        // resolve();
      });
  }

  ngOnInit() {
    const that = this;
  }

  groupClicked(group) {
    this.router.navigate(["/zoneGroups/" + group]);
  }

  /**
   * Used By father component to refresh the headers when a config is loaded
   * The method setup the headers of the table
   * @param configuration new configuration recieved
   */

  public loadHeaders(configuration: Configuration) {
    this.configuration = configuration;
    let someHeaderIsSort = false;

    if (this.configuration !== null && this.configuration !== undefined) {
      this.configuration.columns.forEach((confColumn) => {
        //Colum not found
        if (!this.cols.find((col) => col.field === confColumn.id)) return;
        // Load Visivility
        this.cols.find((col) => col.field === confColumn.id).visible =
          confColumn.visible;
        this.cols.find((col) => col.field === confColumn.id).filter =
          confColumn.filter.active;

        if (confColumn.order.active) {
          // IF ORDER IS ACTIVED
          someHeaderIsSort = true;
          this.sortField = confColumn.id; // We store the id to sort by it

          if (confColumn.order.asc) {
            //event.sortOrder = Sort order as number, 1 for asc and -1 for dec in single sort mode
            this.sortOrder = 1;
          } else {
            this.sortOrder = -1;
          }
        }
      });

      this.setUpHeadersFormat();
      this.sortPdcEvents("zoneGroup");
    }
    /* If no one of the headears are sorted*/
    if (!someHeaderIsSort) {
      this.sortField = null;
    }
  }

  public setUpHeadersFormat() {
    if (!this.configuration.showMap) {
      this.expandedHeaders = true;
      return;
    }
    const visibleCols = this.cols.filter((col) => col.visible === true);

    if (visibleCols.length > 3) {
      this.expandedHeaders = false;
    } else {
      this.expandedHeaders = true;
    }
  }

  public sortPdcEvents(sortType): void {
    if (this.pdc_Events.length > 0) {
      if (sortType == "group") {
        this.pdc_Events.sort((a, b) => this.Sort(a.group, b.group, false));
      } else if (sortType == "zoneGroup") {
        this.pdc_Events.sort((a, b) =>
          this.Sort(
            a.controlPoint.zoneGroup.name,
            b.controlPoint.zoneGroup.name,
            false
          )
        );
      }
      //force the datatable to refresh
      this.pdc_Events = [...this.pdc_Events];
    }
  }

  private Sort(a, b, isDate): number {
    let x;
    if (a < b) {
      x = -1;
    } else if (a > b) {
      x = 1;
    } else {
      x = 0;
    }

    if (this.sortOrder === 1) {
      x = x * -1;
    }

    //If its a date is orderer in the oposite way
    if (isDate) {
      x = x * -1;
    }

    return x < 0 ? -1 : x > 0 ? 1 : 0;
  }

  public controlPointClicked(row) {
    //
    //this.controlPointClickedEvent.emit(row);
  }

  public vehicleClicked(row) {
    // this.vehicleClickedEvent.emit(row);
  }
  /**
   * Refresh the style of each row
   * @param rowData table row
   */
  onRowRender(rowData): string {
    return rowData.color;
  }
}
