<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >APN CONFIGURATION (APN)</span>
        </div>
      </div>
    </p-header>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="apn name" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="apn.name" class="txtbStyle"/>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="apn username" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="apn.username" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="apn password" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="apn.password" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
    </div>           
  </p-accordionTab>