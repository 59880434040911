import { Pipe, PipeTransform } from "@angular/core";
import { CampaignService } from "../service/campaign.service";

@Pipe({ 	name: 'indexOf' })
export class IndexOfPipe implements PipeTransform {

	constructor(
		public campaignService: CampaignService
	) { }

	transform(value: any, param: any): any {
		// console.log("***** indexOf PIPE *****");
		// console.log("value: " + value);
		// console.log("param: " + param);
		if (param === "DEVICE_COMMUNICATION") return this.campaignService.deviceCommunicationList.indexOf(value);
		else if (param === "DEVICE_TYPE") return this.campaignService.deviceTypeList.indexOf(value);
		else if (param === "USER_SEXE") {
			// console.log(this.campaignService.genderList);
			let ret: number = this.campaignService.genderList.indexOf(value);
			// console.log(ret);
			return ret;
		}
		else if (param === "USER_LANGUAGE") return this.campaignService.languageList.indexOf(value);
		else return value;
	}

}

@Pipe({ 	name: 'valueOf' })
export class ValueOfPipe implements PipeTransform {

	constructor(
		public campaignService: CampaignService
	) { }

	transform(value: number, param: string ): any {
		// console.log("***** valueOf PIPE *****");
		// console.log("param: " + param);
		// console.log("value: " + value);
		if (param === "DEVICE_COMMUNICATION") return this.campaignService.deviceCommunicationList[value];
		else if (param === "DEVICE_TYPE") return this.campaignService.deviceTypeList[value];
		else if (param === "USER_SEXE") return this.campaignService.genderList[value];
		else if (param === "USER_LANGUAGE") return this.campaignService.languageList[value];
		else return value;
	}

}
