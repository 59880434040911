import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class TranslationService  extends EntityService<Rest.Translation>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.Translation): Rest.RestResponse<Rest.Translation> {
return this.client.Translation_create(arg0);
}

Delete(arg0: Rest.Translation[]): Rest.RestResponse<void> {
return this.client.Translation_Delete(arg0);
}

getAll(): Rest.RestResponse<Rest.Translation[]> {
return this.client.Translation_getAll();
}

update(arg0: Rest.Translation): Rest.RestResponse<Rest.Translation> {
return this.client.Translation_update(arg0);
}
}
