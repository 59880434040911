<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Auto-Unlock PIN
          (AT+GTPIN)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col">
      <p-checkbox binary="true" label="Auto Unlock PIN" [(ngModel)]="pinParameters.autoUnlockPin">
      </p-checkbox>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="PIN">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="text" pInputText [(ngModel)]="pinParameters.pin" maxlength="8" class="txtbStyle"/>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>