<p-dialog *ngIf="display"
	header='{{ title }}'
   [(visible)]="display"
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
	[modal]='true'
	[draggable]='false'
	[resizable]='false'
	[closeOnEscape]='false'
	[style]="{width: '80vw'}"
	(visibleChange)="onHide()">

   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

	<!-- PANELL DADES DEL DOCUMENT -->
	<div class='d-flex flex-column gap-3 p-3'>

		<div class='d-flex row-cols-2 w-100'>

			<!-- COL 1 -->
			<div class='d-flex ps-3 align-items-start'>
				<div class="d-flex flex-column mb-3">
					<!-- PLAN -->
					<div class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="maintenancePlan" class="custom-label" i18n>
								<b>{{ 'maintenance.table.maintenancePlan' | translate}} (*)</b>
							</label>
						</div>
						<div class="col">
						  	<div class="float-end">
								<p-dropdown
									id="maintenancePlan"
									[options]='maintenancePlanList'
									[(ngModel)]='selectedMaintenancePlan'
									placeholder="Select..."
									[style]="{'width':'250px', 'margin-right':'5px'}"
									appendTo='body'
									dataKey='id'
									optionLabel='name'
									filterBy='name'
									[disabled]='isEditingDisabled || !isNew || !canEdit'
									(onChange)="maintenancePlanChanged($event)">
								</p-dropdown>
						  	</div>
						</div>
					</div>
					<!-- lastMaintenanceDate / lastMaintenanceKms -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="lastMaintenance" class="custom-label" i18n>
								<b>{{ 'maintenance.table.lastMaintenance' | translate}}</b>
							</label>
						</div>
						
						<div class="col">
						  	<div class="float-end">
								<p-calendar
									*ngIf="selectedMaintenance.periodicityType === 'TIME_BASED'; else lastKm"
									id="lastMaintenance"
									appendTo="body"
									[locale]="i18n.calendarLocale"
									[(ngModel)]="entity.lastMaintenanceDate"
									dateFormat="{{ i18n.dateFormat }}"
									showIcon="true"
									[style]="{ width: '250px', 'margin-right':'20px' }"
									[disabled]='isEditingDisabled || !canEdit'>
								</p-calendar>
								<ng-template #lastKm>
									<div class="input-wrapper">
										<input pInputText id="lastMaintenance"  type="number" [(ngModel)]="entity.lastMaintenanceKms" [disabled]='!canEdit' 
											[style]="{ width: '250px', 'margin-right':'-10px' }">
										<span class="input-symbol">km</span>
									</div>
								</ng-template>
						  	</div>
						</div>
					</div>
					<!-- doneMaintenanceDate / doneMaintenanceKms -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="doneMaintenance" class="custom-label" i18n>
								<b>{{ 'maintenance.table.doneMaintenance' | translate}}</b>
							</label>
						</div>
						<div class="col">
						  	<div class="float-end">
								<p-calendar
									*ngIf="selectedMaintenance.periodicityType === 'TIME_BASED'; else doneKm"
									id="doneMaintenance"
									appendTo="body"
									[locale]="i18n.calendarLocale"
									[(ngModel)]="entity.doneMaintenanceDate"
									dateFormat="{{ i18n.dateFormat }}"
									showIcon="true"
									[style]="{ width: '250px', 'margin-right':'20px' }"
									[disabled]='isEditingDisabled || !canEdit'>
								</p-calendar>
								<ng-template #doneKm>
									<div *ngIf="!isDateBased" class="input-wrapper">
										<input pInputText id="doneMaintenance"  type="number" [(ngModel)]="entity.doneMaintenanceKms" [disabled]='!canEdit || !canEdit' 
											[style]="{ width: '250px', 'margin-right':'-10px' }">
										<span class="input-symbol">km</span>
									</div>
								</ng-template>
						  	</div>
						</div>
					</div>
					<!-- ENABLED -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="disabled" class="custom-label" i18n>
								<b>{{ 'tableManagement.vehicle.disabled' | translate}} </b>
							</label>
						</div>

						<div class="col">
						  	<div class="float-end">
								<p-inputSwitch #toggle id="disabled"
									[(ngModel)]="!entity.enabled"
									(onChange)="toggleDisabled($event)"
									class="p-1"
									[disabled]='isEditingDisabled || !canEdit'>
								</p-inputSwitch>
						  	</div>
						</div>
					</div>
				</div>
			</div>

			<!-- COL 2 -->
			<div class='d-flex ps-3 align-items-start'>
				<div class="d-flex flex-column mb-3">
					<!-- MAINTENANCE -->
					<div class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="maintenance" class="custom-label" i18n>
								<b>{{ 'maintenance.table.maintenance' | translate}} (*)</b>
							</label>
						</div>

						<div class="col">
						  	<div class="float-end">
								<p-dropdown
									id="maintenance"
									[options]='maintenanceList'
									[(ngModel)]='selectedMaintenance'
									placeholder="Select..."
									[style]="{'width':'250px', 'margin-right':'10px'}"
									appendTo='body'
									dataKey='id'
									optionLabel='name'
									filterBy='name'
									[disabled]='isEditingDisabled || !maintenanceListLoaded || !isNew || !canEdit'
									(onChange)="maintenanceChanged($event)">
								</p-dropdown>
						  	</div>
						</div>
					</div>
					<!-- nextMaintenanceDate / nextMaintenanceKms -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="nextMaintenance" class="custom-label" i18n>
								<b>{{ 'maintenance.table.nextMaintenance' | translate}} (*)</b>
							</label>
						</div>
						<div class="col">
						  	<div class="float-end">
								<p-calendar
									*ngIf="selectedMaintenance.periodicityType === 'TIME_BASED'; else nextKm"
									id="nextMaintenance"
									appendTo="body"
									[locale]="i18n.calendarLocale"
									[(ngModel)]="entity.nextMaintenanceDate"
									dateFormat="{{ i18n.dateFormat }}"
									showIcon="true"
									[style]="{ width: '250px', 'margin-right':'20px' }"
									[disabled]='isEditingDisabled || !canEdit'>
								</p-calendar>
								<ng-template #nextKm>
									<div *ngIf="!isDateBased" class="input-wrapper">
										<input pInputText id="nextMaintenance"  type="number" [disabled]='!canEdit' [(ngModel)]="entity.nextMaintenanceKms" 
											[style]="{ width: '250px', 'margin-right':'-10px' }">
										<span class="input-symbol">km</span>
									</div>
								</ng-template>
						  	</div>
						</div>
					</div>

					<!-- INCIDENTS -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-center">
							<label for="incidents" class="custom-label" i18n>
								<b>{{ 'maintenance.table.incidents' | translate}} </b>
							</label>
						</div>

						<div class="col">
						  	<div class="float-end">
								<p-inputSwitch #toggle id="incidents"
									[(ngModel)]="entity.incidents"
									(onChange)="toggleIncidents($event)"
									class="p-1"
									[disabled]='isEditingDisabled || !canEdit'>
								</p-inputSwitch>
						  	</div>
						</div>
					</div>

					<!-- Observacions -->
					<div *ngIf="selectedMaintenance" class="row mt-3">
						<div class="col d-flex align-items-start">
							<label for="observations" class="custom-label" i18n>
								<b>{{ 'general.observations' | translate}} </b>
							</label>
						</div>
						<div class="col">
						  	<div class="float-end">
								<textarea pInputTextarea id="observations" [(ngModel)]="entity.observations"
								[rows]="2" [style]="{'width':'450px'}" [disabled]='isEditingDisabled || !canEdit'>
							</textarea>
						  	</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- Taula DOCUMENTS -->
		<div *ngIf="!isNew" class='d-flex row-cols-1 w-100'>
			<app-doc-table
				type="execution"
				[entity]="entity"
				[ids]="ids"
				(return)="refresh()">
			</app-doc-table>
		</div>

	</div>

	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class='d-flex flex-row-reverse bd-highlight'>
			<div class='p-2 bd-highlight'>
				<button  type='button' class='btn custom-button' (click)='onSaveExecution()' [disabled]='isEditingDisabled || !canEdit'>
					{{ isEditingDisabled ? ('general.saving' | translate) : ('general.save' | translate) }}
				</button>
			</div>
		</div>
	</p-footer>

</p-dialog>
