<p-panel header="{{ 'general.generalInformation' | translate }}" [ngClass]="isEditingDisabled ? 'disabledPanel' : ''"
	i18n-header>

	<!-- DISABLED CAMPAIGN ADVICE -->
	<div *ngIf="!isNew && !isEnabled" text-align: center>
		<div class='alert alert-warning' role='alert' style="background-color:rgb(206, 43, 43);
							color: whitesmoke;
							text-align: center">
			{{ 'promotedRoutes.campaignDisabled' | translate }}
		</div>
	</div>

	<!-- SECCION 1 -->
	<div class='d-flex flex-column gap-3 p-3'>

		<!-- ROW 1: NAME -->
		<div class="row align-items-center">

			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='true' fieldName="{{ 'general.name' | translate }}" [errors]='errors.name'>
					</form-input>
				</div>
				<div class="col">
					<input pInputText [(ngModel)]='campaign.name' class="txtbStyle" [readonly]='true'
					(click)='onTranslations("name")' />
				</div>
			</div>
		
			<!-- TEXT COLOR -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='false' fieldName="{{ 'promotedRoutes.textColor' | translate }}">
					</form-input>
				</div>
				<div class="col">
					<p-colorPicker [(ngModel)]="campaign.textColor"></p-colorPicker>
					<span style="margin-left: 12px"> {{ campaign.textColor }} </span>
				</div>
			</div>
		
		</div>

		<!-- ROW 2: DESCRIPTION & BANNER -->
		<div class="row align-items-center">

			<!-- DESCRIPTION -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input i18n-fieldName fieldName="{{ 'general.description' | translate }}"
					[errors]="errors.description">
				</form-input>
				</div>
				<div class="col">
					<textarea pInputTextarea id="description" [(ngModel)]="campaign.description" [rows]="3"
					[style]="{'width':'100%','min-width':'100px'}" enabled="false" [readonly]='true'
					(click)='onTranslations("description")'></textarea>
				</div>
			</div>
		
			<!-- BANNER -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input i18n-fieldName fieldName="{{ 'general.image' | translate }}" [errors]="errors.description">
					</form-input>
				</div>
				<div class="col">
					<button id='uploadImageButton' (click)='uploadImageInput.click()' class="txtbStyle">
						<img *ngIf='spaceImageUrl' id='uploadImageIcon' class='ps-3' alt='' src='{{ spaceImageUrl }}'
							class='img-responsive txtbStyle' style='max-height: 150px;'/>
						<img *ngIf='campaign?.image?.imageEncoded' id='uploadedImage' class='ps-3' alt=''
							[src]='spaceEncodedImage' class='img-responsive txtbStyle' style='max-height: 150px;'>
					</button>
					<input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png'
						(change)='imageChange($event)' class="txtbStyle">
				</div>
			</div>
		
		</div>

		<!-- ROW 3: START TIME && END TIME -->
		<div class="row align-items-center">

			<!-- START TIME -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input fieldName="{{ 'general.startDate' | translate }}" [errors]='' [required]='true'>
					</form-input>
				</div>
				<div class="col">
					<p-calendar [(ngModel)]='startDate' (ngModelChange)='eventStartDateChange()' [readonlyInput]='true'
					[showTime]='true' [style]="{'width':'100%','min-width':'100px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
					appendTo='body'></p-calendar>
				</div>
			</div>
		
			<!-- END TIME -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input fieldName="{{ 'general.endDate' | translate }}" [errors]='' [required]='true'>
					</form-input>
				</div>
				<div class="col">
					<p-calendar [(ngModel)]='endDate' (ngModelChange)='eventEndDateChange()'
					[disabled]='startDate === null' [readonlyInput]='true' [minDate]='startDate' [showTime]='true'
					[style]="{'width':'100%','min-width':'100px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
					appendTo='body'></p-calendar>
				</div>
			</div>
		
		</div>

		<!-- ROW 4: INOF LINK + provider -->
		<div class="row align-items-center">

			<!-- INFO LINK -->
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='false' fieldName="{{ 'promotedRoutes.infoLink' | translate }}"
					[errors]='errors.name'>
				</form-input>
				</div>
				<div class="col">
					<input pInputText [(ngModel)]='campaign.infoLink' class="txtbStyle" [readonly]='true'
					(click)='onTranslations("infoLink")' />
				</div>
			</div>
		
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='true' fieldName="{{ 'promotedRoutes.providerName' | translate }}">
					</form-input>
				</div>
				<div class="col">
					<p-dropdown [options]='providerList' [(ngModel)]='campaign.provider' dataKey='id' optionLabel='name'
					[style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
				</div>
			</div>
		
		</div>

		<!-- ROW 5: BUY LINK -->
		<div class="row align-items-center">

			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='false' fieldName="{{ 'promotedRoutes.buyLink' | translate }}"
					[errors]='errors.name'>
				</form-input>
				</div>
				<div class="col">
					<input pInputText [(ngModel)]='campaign.buyLink' class="txtbStyle" [readonly]='true'
					(click)='onTranslations("buyLink")' />
				</div>
			</div>
		
			<div class="col row dvcFields">
				<div class="col lblStyle">
					<form-input [required]='false' fieldName="{{ ('promotedRoutes.enabledSpace' | translate) }}"
					[errors]='errors.name'>
				</form-input>
				</div>
				<div class="col">
					<p-inputSwitch #toggle [(ngModel)]="campaign.active" [disabled]="false"
					(onChange)="toggleActivation($event)"></p-inputSwitch>
					<div *ngIf="changesPending" style='color: red; padding-left: "20px"'>
						* {{ 'promotedRoutes.rememberToSave' | translate }}
					</div>
				</div>
			</div>
		
		</div>

	</div>

	<!-- SECCION 2 -->
	<div *ngIf="!isNew" class='d-flex flex-column gap-3 p-3  w-100'>
		<!-- targets y filtros -->
		<div class='d-flex'>
			<app-targets-list [(campaign)]="campaign"></app-targets-list>
		</div>
	</div>
	<div *ngIf="isNew" text-align: center>
		<div class='alert alert-warning' role='alert' style="background-color: lightyellow;
					   color: rgb(0, 0, 0);
						text-align: center">
			{{ 'promotedRoutes.showTargets' | translate }}
		</div>
	</div>

	<!-- SECCION 3 -->
	<div *ngIf="!isNew && isEnabled" class='d-flex flex-column gap-3 p-3 w-100'>
		<div class='d-flex'>
			<p-panel header="{{ 'promotedRoutes.notificationTitle' | translate }}"
				[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header [toggleable]="true" [collapsed]="false"
				style="width: 100% !important">

				<div class='d-flex row-cols-1 w-100'>
					<div class='d-flex'>
						<div *ngIf="sendNotifControl === null">
							<p>{{ 'promotedRoutes.notification.send' | translate }}</p>
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'PENDING'">
							<p>{{ 'promotedRoutes.notification.pending' | translate }}</p>
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'STARTED'">
							<p>{{ 'promotedRoutes.notification.started' | translate }}
								{{ sendNotifControl.startProcesTime | date:'short' }} </p>
						</div>
						<div *ngIf="sendNotifControl !== null && sendNotifControl.state === 'FINISHED'">
							<p>{{ 'promotedRoutes.notification.finished' | translate }}
								{{ sendNotifControl.endProcesTime | date:'short' }}<br>
								{{ 'promotedRoutes.sendedNotif' | translate }}: {{ sendNotifControl.recipientsAmount }}
							</p>
						</div>
					</div>
				</div>

				<div class='d-flex row-cols-1 w-100'>
					<div class='d-flex'>
						<button *ngIf="sendNotifControl === null" class="custom-button txtbStyle"
							(click)='scheduleSendNotif(space)' [disabled]='!space.campaign.active'>
							{{ 'promotedRoutes.sendBtn' | translate }}
						</button>
						<button *ngIf="sendNotifControl !== null && sendNotifControl.state === 'PENDING'"
							class="custom-button txtbStyle" (click)='cancelSendNotif(space)' [disabled]='!space.campaign.active'>
							{{ 'promotedRoutes.cancelBtn' | translate }}
						</button>
						<button *ngIf="sendNotifControl !== null && sendNotifControl.state === 'FINISHED'"
							class="custom-button txtbStyle" (click)='scheduleSendNotif(space)'
							[disabled]='!space.campaign.active'>
							{{ 'promotedRoutes.resendBtn' | translate }}
						</button>
					</div>
				</div>
			</p-panel>
		</div>
	</div>
	<div *ngIf="!isNew && !isEnabled" text-align: center>
		<div class='alert alert-warning' role='alert' style="background-color: lightyellow;
					   color: rgb(0, 0, 0);
						text-align: center">
			{{ 'promotedRoutes.showNotif' | translate }}
		</div>
	</div>

	<!-- PIE DE PAGINA -->
	<!-- <p-footer>
			<div class='d-flex flex-row-reverse bd-highlight'>
					 <div class='p-2 bd-highlight'>
						<button type='button' class='btn custom-button' (click)='toggleActivation()' [disabled]='isEditingDisabled'>
							{{ campaign.active ? ('promotedRoutes.disabled' | translate) : ('promotedRoutes.enabled' | translate) }}
						</button>
					</div>
			 </div>
		</p-footer> -->

</p-panel>

<!-- PIE DE PAGINA -->
<!-- <p-footer>
   <div class='d-flex flex-row-reverse bd-highlight'>
     	<div class='p-2 bd-highlight'>
         <button type='button' class='btn custom-button' (click)='saveCampaign()' [disabled]='isEditingDisabled'>
           	{{ showRoutesList ? ('general.save' | translate) : ('promotedRoutes.saveAndShowRoutes' | translate) }}
        	</button>
    	</div>
 	</div>
</p-footer> -->

<!-- TRANSLATIONS FORM -->
<app-translations-form (translationsSaved)="refreshTranslations($event)"></app-translations-form>