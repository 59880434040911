export enum DrawZoneType {
  Polygon,
  Circle,
}

export type CallbackFunction = (event: any, lonLat: any) => void;
export interface ContextMenuOption {
  id: string;
  label: string;
  callback: CallbackFunction;
}

export interface CustomMapOptions {
  restrictNPois?: number; //total de POIS que se pueden crear en el mapa
  createPOIonClick?: boolean; //Si true, crea un POI (marker) en la posición del mapa clicada
  editPOIs?: boolean; //Si true, se pueden editar POIs en el mapa. En caso contrario solo se muestran.
  editPOIid?: number; //Si existe, el poi con este id se muestra con otro icono y se puede arrastrar
  drawZones?: boolean; //Si true, se pueden pintar una zona en el mapa
  drawZoneType?: DrawZoneType; //Las zonas se dibujan como poligono o como circulo
  editZones?: boolean; //Si true, se pueden editar las zonas en el mapa (drag and drop solo posicion).
  editZoneid?: number; //Si existe, el poi con este id se muestra con un estílo en concreto y se puede arrastrar y editar
  contextMenuOptions?: ContextMenuOption[]; //Si existe, define las opciones del menu desplegable contextual
  popupOptions?: PopupOptions;
}

export interface PopupOptions {
	popUpMinWidth?: number;
	popUpMaxWidth?: number;
	videoLinkIcon?: string;
	blogLinkIcon?: string;
	mode?: string;  //single: nomes un popup obert, multi: multiples popup oberts (default)
}

export interface Location {
  country: string;
  municipality: string;
  region: string;
  subregion: string;
  postalCode: string;
  city: string;
  address: string;
  prefix: string;
  lon: string;
  lat: string;
  fullAddress: string;
  timezone?: string;
}

export interface UpdatePOIMarkerEvent {
  poiId: number;
  location: Location;
}

export interface ZoneCercaliaWrapper {
  zoneType: DrawZoneType; //circle or polygon
  radius?: number; //circle radi (km? m?) required if zoneType == DrawZoneType.Circle
  centerLat?: number; //lat circle center required if zoneType == DrawZoneType.Circle
  centerLon?: number; //lon circle center required if zoneType == DrawZoneType.Circle
  wkt?: string; //required if zoneType == DrawZoneType.Polygon
}

export interface UpdateZoneMarkerEvent {
  zoneId: number;
  zone: ZoneCercaliaWrapper;
}

export interface CreateZoneMarkerEvent {
  zone: ZoneCercaliaWrapper;
}

export interface SearchProximityVehicles {
  x: number;
  y: number;
  srs: string;
  num: number;
  rad: number;
  weight: string;
  inverse: boolean;
  mos: Array<string>;
}
