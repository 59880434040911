<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Track Event Configuration ($ST+TRACKING)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Track Event Mode" >
            <p-dropdown  (onChange)="trackEventModeChange(trackEvent.mode,$event)" [options]="ModeOptions"
            [(ngModel)]="trackEvent.mode" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>

        <div [class.disableDIV]=!trackModeSwitch>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Time" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="trackEvent.time" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>
                  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Distance" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="trackEvent.distance" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >m</span>
              </div>
            </form-input>
          </div>
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Times" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="trackEvent.times" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>

        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output Control">
            <p-inputSwitch [(ngModel)]="trackEvent.outputControl"></p-inputSwitch>
          </form-input>
        </div>        
      </div>
      </div>
</p-accordionTab>
