import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { VehiclemodelService } from "app/rest/vehiclemodel.service";
import { EntityListComponent } from "app/components/entity-list/entity-list.component";
import { Rest } from "app/rest/rest_client";
import { VehicleVersionFormComponent } from "../vehicle-version-form/vehicle-version-form.component";
import { VehicleversionService } from "app/rest/vehicleversion.service";
import { NotificationsService } from "app/services/notifications-service/notifications.service";
import { I18nService } from "app/services/i18n/i18n.service";
import { AuthenticationService } from "app/core/services/authentication/authentication.service";
import { RestExt } from "app/services/rest-client-extension";
import { FueltypeService } from "app/modules/vehicle-inventory/services/fueltype.service";
import { TranslateService } from "@ngx-translate/core";
import { ExcelService } from "app/services/excel-service/excel.service";
@Component({
  selector: 'app-vehicle-version-list',
  templateUrl: './vehicle-version-list.component.html',
  styleUrls: ['./vehicle-version-list.component.css'],
  providers: [VehicleversionService, FueltypeService, ExcelService]
})
export class VehicleVersionListComponent extends EntityListComponent<Rest.VehicleVersion> implements OnInit {


  @ViewChild(VehicleVersionFormComponent, { static: true })
  vehicleVersionForm: VehicleVersionFormComponent;

  disableSave: boolean;

  user: Rest.User;
  optionsfuelType: SelectItem[];
  EnabledFilter: SelectItem[];

  constructor(
    public vehicleVersionService: VehicleversionService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    private fuelTypeService: FueltypeService,
    protected translateService: TranslateService,
    protected excelService: ExcelService,

  ) {
    super(
      vehicleVersionService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );

  }

  ngOnInit() {
    super.ngOnInit();
    this.loadOptionsFuelType();
    this.form = this.vehicleVersionForm;
    this.hasEntityFilters = false;
    /*Permissions */
    this.user = this.authenticationService.user;
    /** */
  }

  protected beforeLoad(): void {
    this.paginationRequest.filters["client"] = [this.user.client.id.toString()];
    if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }
  }

  loadOptionsFuelType() {
    var that = this;

    this.fuelTypeService.getList().then((data) => {
      that.optionsfuelType = [];
      for (let i = 0; i < data.length; i++) {
        that.optionsfuelType.push({ label: this.translateService.instant('tableManagement.fuelType.' + data[i]), value: data[i] });
      }
    });
  }

  afterLoad() {
    this.EnabledFilter = RestExt.getEnabledFilter(
      this.filters["enabled"],
      this.i18n
    );
    this.paginationResult.entities.forEach((cl: any) => {
      /*Permissions */
      cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
      cl._$update = true;
      /** */
    });
    /*Permissions */
    this.checkSelectAll();
    /** */
  }
  excelColumns = {};
  exportExcel() {
    this.setExcelColumns();
    let fileNameTitle = this.translateService.instant('tableManagement.versions.versionsManagement');
    super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
  }
  setExcelColumns() {
    const name = (entity: any) => entity.name ?? ''
    const brand = (entity: any) => entity.model?.brand?.name ?? ''
    const model = (entity: any) => entity.model?.name ?? ''
    const cylinderCapacity = (entity: any) => entity.cylinderCapacity ?? ''
    const consumption = (entity: any) => entity.consumption ?? ''
    const emissions = (entity: any) => entity.emissions ?? ''
    const fuelCapatity = (entity: any) => entity.fuelCapacity ?? ''
    const fuelType = (entity: any) => entity.fuelType && entity.fuelType.length > 0 ? this.translateService.instant('tableManagement.fuelType.' + entity.fuelType) : ''
    const observations = (entity: any) => entity.observations ?? ''

    this.excelColumns = {
      [this.translateService.instant('tableManagement.versions.name')]: { bindField: name },
      [this.translateService.instant('tableManagement.brands.brand')]: { bindField: brand },
      [this.translateService.instant('tableManagement.models.model')]: { bindField: model },
      [this.translateService.instant('tableManagement.versions.displacement')]: { bindField: cylinderCapacity },
      [this.translateService.instant('tableManagement.versions.consumption')]: { bindField: consumption },
      [this.translateService.instant('tableManagement.versions.emissions')]: { bindField: emissions },
      [this.translateService.instant('tableManagement.versions.fuelCapatity')]: { bindField: fuelCapatity },
      [this.translateService.instant('tableManagement.versions.fuelType')]: { bindField: fuelType },
      [this.translateService.instant('tableManagement.versions.observations')]: { bindField: observations },
    };
  }

}
