<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Report Delay Event</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g-12">
      <form-input i18n-fieldName fieldName="Low voltage level" [errors]="errors.lowVoltageLevel">
        <div class="ui-inputgroup">
          <p-spinner id="heading" size="5" [(ngModel)]="dr.lowVoltageLevel" [min]="0" [max]="30"></p-spinner>
          <span class="ui-inputgroup-addon">0.0~30.0</span>
          <span class="ui-inputgroup-addon" i18n>voltage</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling input1" [errors]="errors.pollingOnInput1">
        <p-inputSwitch [(ngModel)]="dr.pollingOnInput1"></p-inputSwitch>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling input2" [errors]="errors.pollingOnInput2">
        <p-inputSwitch [(ngModel)]="dr.pollingOnInput2"></p-inputSwitch>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling input3" [errors]="errors.pollingOnInput3">
        <p-inputSwitch [(ngModel)]="dr.pollingOnInput3"></p-inputSwitch>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling input4" [errors]="errors.pollingOnInput4">
        <p-inputSwitch [(ngModel)]="dr.pollingOnInput4"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on main power low" [errors]="errors.pollingOnMainPowerLow">
        <p-inputSwitch [(ngModel)]="dr.pollingOnMainPowerLow"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on main power lost" [errors]="errors.pollingOnMainPowerLost">
        <p-inputSwitch [(ngModel)]="dr.pollingOnMainPowerLost"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on main voltage recover" [errors]="errors.pollingOnMainPowerVoltageRecover">
        <p-inputSwitch [(ngModel)]="dr.pollingOnMainPowerVoltageRecover"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on main power recover" [errors]="errors.pollingOnMainPowerRecover">
        <p-inputSwitch [(ngModel)]="dr.pollingOnMainPowerRecover"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on battery voltage low" [errors]="errors.pollingOnBatteryVoltageLow">
        <p-inputSwitch [(ngModel)]="dr.pollingOnBatteryVoltageLow"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="polling on battery voltage recover" [errors]="errors.pollingOnBatteryVoltageRecover">
        <p-inputSwitch [(ngModel)]="dr.pollingOnBatteryVoltageRecover"></p-inputSwitch>
      </form-input>
    </div>

  </div>
</p-accordionTab>