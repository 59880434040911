import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'maintenanceExecutionStatusColor'
})
export class MaintenanceExecutionStatusColorPipe implements PipeTransform {

	transform(value): any {
		// console.log("[STATUS-PIPE] " + value);
		let ret: string = '#000000';
		switch (value) {
			case "OK": ret="#00CC00"; break;
			case "ALERT": ret="#FF9933"; break;
			case "EXPIRED": ret="#FF0000"; break;
			case "FINISHED": ret="#E0E0E0"; break;
		}
		// console.log("[STATUS-PIPE] " + value + " -> " + ret);
		return ret;
	}
}
