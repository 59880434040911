<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Outside Working Hours (AT+GTOWH)</span>
            </div>
          </div>
        </p-header>
        
        <div class="row mt-3">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Mode" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="modeOptions"
                    [(ngModel)]="owhParameters.mode" [style]="{'width':'100%','min-width':'100px'}" appendTo="body"></p-dropdown>
                </div>
            </div>

            <div class="row mt-3">
                <form-input fieldName="Day of Work"></form-input>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Monday" [(ngModel)]="owhParameters.monday">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="Friday" [(ngModel)]="owhParameters.friday">
                        </p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Tuesday" [(ngModel)]="owhParameters.tuesday">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="Saturday" [(ngModel)]="owhParameters.saturday">
                            </p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Wednesday" [(ngModel)]="owhParameters.wednesday">
                            </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="Sunday" [(ngModel)]="owhParameters.sunday">
                            </p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Thursday" [(ngModel)]="owhParameters.thursday">
                            </p-checkbox>
                    </div>
                    <div class="col"></div>
                </div>                                
            </div>
            
            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Working Hours Start1" >
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" maxlength="4" pInputText [(ngModel)]="owhParameters.workingHoursStart1" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Working Hours End1" >
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" maxlength="4" pInputText [(ngModel)]="owhParameters.workingHoursEnd1" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Working Hours Start2" >
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" maxlength="4" pInputText [(ngModel)]="owhParameters.workingHoursStart2" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Working Hours End2" >
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" maxlength="4" pInputText [(ngModel)]="owhParameters.workingHoursEnd2" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Digital Input ID">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="owhParameters.digitalInputId"
                                [min]="0" [max]="1"></p-spinner>
                            <span class="ui-inputgroup-addon">0~1</span>
                        </div>
                    </div>
                </div>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="RF Sleep Mode" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="rfSleepOptions"
                        [(ngModel)]="owhParameters.rfSleepMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
            </div>
         
        </div>
      </p-accordionTab>