import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../notifications-service/notifications.service';
import { UserService } from "../../../rest/user.service";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private notificationService: NotificationsService,
        private userService: UserService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        /* Partial login: There is a Refresh Token but the User data haven't been retrieved yet */
        if (this.auth.isUserLogged() && !this.auth.user) {

            return this.auth.updateToken().toPromise()
                .then((response: any) => {
                    if (response) {
                        let dataInitialized: boolean = this.auth.initDataAfterRefresh(response);
                        this.handleRoute(state);
                        return dataInitialized;
                    }

                    this.auth.logout();
                    return false;
                })
                .catch(() => {
                    return false;
                });

        }

        /* Full login: Both Refresh Token and User data are present */
        else if (this.auth.isUserLogged() && this.auth.user) {
            this.handleRoute(state);
            return true;
        }

        /*this.router.navigate(['/login']).then(() => {stopLoading();});*/
        /* TODO: Implement loading and remove the loader as a promise handle (see previous line for an example) */
        this.router.navigate(['/login']);

        if (state.url !== '/') {
            this.notificationService.add(Severity.error, 'Access Denied', 'You must log in order to access the page!');
        }
        return false;
    }

    /**
     * handleRoute()
     * */
    private handleRoute(state: RouterStateSnapshot): void {

        if (state.url === '/') {

            /* If user has default dashboard and is logged in, go to the dashboard */
            if (this.auth.user.defaultDashboard !== null) {
                this.router.navigate(['/' + this.auth.user.defaultDashboard]);
            } else {

                /* Check userAllowedDashboards to know where to redirect the user */
                this.userService.getUserAllowedDashboards(this.auth.user.id)
                    .then((response) => {
                        if (response.rt) {
                            this.router.navigate(['/rt']);
                        } else if (response.COEXPanel) {
                            this.router.navigate(['/COEXPanel']);
                        } else if (response.reports) {
                            this.router.navigate(['/reports']);
                        } else if (response.controlPanel) {
                            this.router.navigate(['/control-panel']);
                        } else if (response.racing) {
                            this.router.navigate(['/racing']);
                        } else {
                            this.router.navigate(['/login']);
                        }
                    })
                    .catch(() => {
                        this.router.navigate(['/login']);
                    });
            }
        }
    }
}
