<!-- BILLING ACCOUNTS PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate="
         {first}
         {{ 'table.to' | translate }} {last}
         {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.clients.billingAccountsTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- CODE -->
      <th pSortableColumn="code">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.codeBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="code"></p-sortIcon>
            <p-columnFilter field="code"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- POPULATION -->
      <th pSortableColumn="population">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.populationBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="population"></p-sortIcon>
            <p-columnFilter field="population"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- COUNTRY -->
      <th pSortableColumn="country">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.countryBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="country"></p-sortIcon>
            <p-columnFilter field="country"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- PROVINCE -->
      <th pSortableColumn="province">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.provinceBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="province"></p-sortIcon>
            <p-columnFilter field="province"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- POSTAL CODE -->
      <th pSortableColumn="postalCode">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.postalCodeBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="postalCode"></p-sortIcon>
            <p-columnFilter field="postalCode"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- ADDRESS -->
      <th pSortableColumn="postalCode">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.addressBa' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="address"></p-sortIcon>
            <p-columnFilter field="address"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- CIF -->
      <th pSortableColumn="cif">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.clients.cif' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="cif"></p-sortIcon>
            <p-columnFilter field="cif"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="enabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="enabled"></p-sortIcon>
            <p-columnFilter field="enabled"
                            type="boolean"
                            display="menu"
                            [showApplyButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-billingAccount>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(billingAccount)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="billingAccount"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{billingAccount.name}}
      </td>

      <!-- CODE -->
      <td>
        {{billingAccount.code}}
      </td>

      <!-- POPULATION -->
      <td>
        {{billingAccount.population}}
      </td>

      <!-- COUNTRY -->
      <td>
        {{billingAccount.country}}
      </td>

      <!-- PROVINCE -->
      <td>
        {{billingAccount.province}}
      </td>
      
      <!-- POSTAL CODE -->
      <td>
        {{billingAccount.postalCode}}
      </td>

      <!-- ADDRESS -->
      <td>
        {{billingAccount.address}}
      </td>

      <!-- CIF -->
      <td>
        {{billingAccount.cif}}
      </td>


      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
             [ngClass]="{'true-icon pi-check-circle': billingAccount.enabled, 'false-icon pi-times-circle': !billingAccount.enabled}"></i>
        </div>
      </td>

    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="9" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD BILLING ACCOUNT BUTTON -->
          <app-table-add-button [text]=" 'control-panel.clients.billingAccount' | translate "
                                (btnClick)="createBillingAccountDialog()"
                                [isDisabled]="!user.canManageClients || !hasPermissions"
          ></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
                                          [isDisabled]="!user.canManageClients || selectedEntities.length === 0"
          ></app-table-change-status-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG  -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.billingAccounts' | translate "
                            [display]="true"
                            [service]=billingaccountService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-dialog>
</div>

<!-- BILLING ACCOUNT FORM -->
<app-billing-account-form></app-billing-account-form>
