<div id="contentDiv">
    <div class="fakeMenuBarOnTheTop"></div>
    <div class="bannerBar">            
        <img class="logoImg" src="../../../../../assets/images/EnterpriseIcons/atlantis-fleet.png"/>
        <div><a i18n class="bannerText">IMPRESIÓ DEL MAPA</a></div>
        <img class="logoImg2" src="../../../../../assets/images/EnterpriseIcons/LogoUteSorigue-Comsa.jpg"/>
    </div>
    <div id="dateDiv" class="dateDivStyle">
        <label class="dateTimeLblStyle">{{dateTime}}</label>
    </div>

    <div class="centerDiv">
        <div style="padding-top:20px">
            <div style="width:220px;">
                <button pButton style="float:left"  (click)="onPrint()" i18n-label label="lblPrintMap"></button>
            </div>            
            <div style="width:220px;">
                <button pButton style="float:right"  (click)="onDownload()" i18n-label label="lblDownloadMap"></button>
            </div>
            <div style="padding-top:40px;" id ="mapDiv">
                <img id = "MapImg" class="imgSize" [src]="urlMapa"/>
            </div>
        </div>                                
    </div>                      
</div>