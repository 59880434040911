import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';
import { CardConsumptionService } from 'app/modules/vehicle-inventory/services/table-management/cardConsumption.service';
import { CardConsumptionFormComponent } from '../cardConsumption-form/cardConsumption-form.component';

import CardConsumption = Rest.CardConsumption;
import { UploadFileFormComponent } from '../../../documents/upload-file-form/upload-file-form.component';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

@Component({
    selector: 'app-cardConsumption-list',
    templateUrl: './cardConsumption-list.component.html',
    styleUrls: ['./cardConsumption-list.component.scss'],
    providers: [CardConsumptionService, ConfirmationService, UserTimePipe, ExcelService],
})
export class CardConsumptionListComponent
    extends EntityListComponent<CardConsumption>
    implements OnInit {


    @Input() vehicle:Rest.Vehicle;
    displayDocForm = false;
    @ViewChild(CardConsumptionFormComponent, { static: true })
    cardConsumptionForm: CardConsumptionFormComponent;
    title: string = this.i18n._("CardConsumptions");
    disableSave: boolean;
    EnabledFilter: SelectItem[];
    @ViewChild(UploadFileFormComponent) uploadFileForm!: UploadFileFormComponent;
    renewalType = "CONSUMPTION";
    fileFormTitle = "";

    showCardColumn: boolean = true;

    @Input() card: Rest.Card;

    // PERMISSIONS
    isCreateGranted: boolean = false;
    isFormGranted: boolean = false;
    isDisabledGranted: boolean = false;

    constructor(
        private cardConsumptionService: CardConsumptionService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService
    ) {
        super(
            cardConsumptionService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );
        this.cardConsumptionService = cardConsumptionService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.cardConsumptionForm;

        this.setPermissions();
    }

    beforeLoad(): void {
        //if (this.card != null) {
        this.paginationRequest.sortBy = "consumptionDate";
		this.paginationRequest.sortAsc= false;
        if (this.card) {
            this.paginationRequest.filters['card'] = [this.card.number.toString()];
            this.showCardColumn = false;
        }

        if(this.vehicle){
            this.paginationRequest.filters['vehicle'] = [this.vehicle.name.toString()];
        }

        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    setPermissions() {
        const user = this.authenticationService.user;
        const rolesForForm = ['INV_CARD_CONSUMPTION_READ', 'INV_CARD_CONSUMPTION_UPDATE'];

        this.isCreateGranted = this.authenticationService.isRoleGranted('INV_CARD_CONSUMPTION_CREATE', user);
        this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_CARD_CONSUMPTION_DISABLE', user);
        this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
    }

    editDialog(event) {
        if (!this.isFormGranted) {
            return;
        }

        if (!this.isSelectableColumn) {
            this.onRowDblClick({ data: event });
        }

        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    excelColumns = {};

    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.cardsConsumption.cardConsumptionsManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }

    setExcelColumns() {
        const card = (entity: any) => entity.card?.number ?? ''
        const amount = (entity: any) => entity.amount ?? ''
        const date = (entity: any) => entity.consumptionDate ?? ''
        const liters = (entity: any) => entity.liters ?? ''
        const km = (entity: any) => entity.kms ?? ''
        const difDays = (entity: any) => entity.difDays ?? ''
        const difKm = (entity: any) => entity.difKms ?? ''
        const difConsumption = (entity: any) => entity.difConsumption ?? ''
        const costKm = (entity: any) => entity.kmsCost ?? ''
        const costLiters = (entity: any) => entity.literCost ?? ''


        this.excelColumns = {
            [this.translateService.instant('tableManagement.cardsConsumption.card')]: { bindField: card },
            [this.translateService.instant('tableManagement.cardsConsumption.amount')]: { bindField: amount },
            [this.translateService.instant('tableManagement.cardsConsumption.date')]: { bindField: date },
            [this.translateService.instant('tableManagement.cardsConsumption.litres')]: { bindField: liters },
            [this.translateService.instant('tableManagement.cardsConsumption.km')]: { bindField: km },
            [this.translateService.instant('tableManagement.cardsConsumption.diffDays')]: { bindField: difDays },
            [this.translateService.instant('tableManagement.cardsConsumption.difKm')]: { bindField: difKm },
            [this.translateService.instant('tableManagement.cardsConsumption.diffConsumption')]: { bindField: difConsumption },
            [this.translateService.instant('tableManagement.cardsConsumption.kmsCost')]: { bindField: costKm },
            [this.translateService.instant('tableManagement.cardsConsumption.literCost')]: { bindField: costLiters },
        }
    }


    showFileForm() {
        this.fileFormTitle = this.translateService.instant('tableManagement.cardsConsumption.cardConsumption');
        this.renewalType = 'CONSUMPTION';
        this.uploadFileForm.showDialog();
    }
}
