import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "app/rest/JQueryHTTPClient";
import { EntityService } from "app/rest/entity.service";
import { Rest } from "app/rest/rest_client";

import FineStatus = Rest.FineStatus;

@Injectable()
export class FineStatusService extends EntityService<FineStatus> {
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.FineStatus): Rest.RestResponse<FineStatus> {
        try {
            return this.client.FineStatus_create(arg0);
        } catch (error) {
            console.error("Error creating FineStatus:", error);
            throw error;
        }
    }

    update(arg0: Rest.FineStatus): Rest.RestResponse<FineStatus> {
        try {
            return this.client.FineStatus_update(arg0);
        } catch (error) {
            console.error("Error updating FineStatus:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<FineStatus>> {
        try {
            return this.client.FineStatus_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting FineStatus page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.FineStatus_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing FineStatus status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<FineStatus> {
        try {
            return this.client.FineStatus_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding FineStatus:", error);
            throw error;
        }
    }
}