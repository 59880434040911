<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible' header='{{racingFormTitle}}' [(visible)]='isDialogVisible'
   [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]='true' [draggable]='false' [resizable]='false'
   [closeOnEscape]='false' [style]="{width: '95%'}">
   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

   <!-- GENERAL PANEL -->
   <p-panel header="{{ 'general.generalInformation' | translate }}" [ngClass]="isEditingDisabled ? 'disabledPanel' : ''"
      i18n-header>

      <div class='d-flex flex-column gap-3 p-3'>

         <!-- ROW 1: NAME && IMAGE && TYPE -->
         <div class="row align-items-center">

            <!-- NAME && IMAGE -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='true' fieldName="{{ 'general.name' | translate }}" [errors]='errors.name'>
                  </form-input>
               </div>
               <div class="col">
                  <!-- NAME -->
                  <input pInputText [(ngModel)]='racingEvent.nameEvent' class="txtbStyle" />
                  <!-- IMAGE -->
                  <button id='uploadImageButton' (click)='uploadImageInput.click()'>
                     <img *ngIf='eventImageUrl' id='uploadImageIcon' class='ps-3' alt='' src='{{eventImageUrl}}' />
                     <img *ngIf='racingEvent?.logoEvent?.imageEncoded' id='uploadedImage' class='ps-3' alt=''
                        [src]='eventEncodedImage'>
                  </button>
                  <input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png'
                     (change)='imageChange($event)'>
               </div>
            </div>

            <!-- TYPE -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='true' fieldName="{{ 'general.type' | translate }}">
                  </form-input>
               </div>
               <div class="col">
                  <p-dropdown [options]='eventTypeList' [(ngModel)]='selectedEventType' optionLabel='nameEvent'
                     [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
               </div>
            </div>

         </div>

         <!-- ROW 2: START TIME && END TIME -->
         <div class="row align-items-center">

            <!-- START TIME -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input fieldName="{{ 'general.startDate' | translate }}" [errors]='' [required]='true'>
                  </form-input>
               </div>
               <div class="col">
                  <p-calendar [(ngModel)]='eventStartDate' (ngModelChange)='eventStartDateChange()'
                     [readonlyInput]='true' [minDate]='dateNow' [showTime]='true'
                     [style]="{'width':'100%','min-width':'100px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
                     appendTo='body'></p-calendar>
               </div>
            </div>

            <!-- END TIME -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input fieldName="{{ 'general.endDate' | translate }}" [errors]='' [required]='true'>
                  </form-input>
               </div>
               <div class="col">
                  <p-calendar [(ngModel)]='eventEndDate' (ngModelChange)='publicationEndDate = null'
                     [disabled]='eventStartDate === null' [readonlyInput]='true' [minDate]='eventStartDate'
                     [showTime]='true' [style]="{'width':'100%','min-width':'100px'}" dateFormat='{{i18n.dateFormat}}'
                     showIcon='true' appendTo='body'></p-calendar>
               </div>
            </div>

         </div>

         <!-- ROW 3: START PUBLICATION && END PUBLICATION -->
         <div class="row align-items-center">

            <!-- START PUBLICATION -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input fieldName="{{ 'events.startDatePublication' | translate }}" [errors]='' [required]='true'>
                  </form-input>
               </div>
               <div class="col">
                  <p-calendar [(ngModel)]='publicationStartDate' (ngModelChange)='publicationEndDate = null'
                     [disabled]='eventStartDate === null' [readonlyInput]='true' [minDate]='dateNow'
                     [maxDate]='eventStartDate' [showTime]='true' [style]="{'width':'100%','min-width':'100px'}"
                     dateFormat='{{i18n.dateFormat}}' showIcon='true' appendTo='body'></p-calendar>
               </div>
            </div>

            <!-- END PUBLICATION -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input fieldName="{{ 'events.endDatePublication' | translate }}" [errors]='' [required]='true'>
                  </form-input>
               </div>
               <div class="col">
                  <p-calendar [(ngModel)]='publicationEndDate'
                     [disabled]='eventEndDate === null || publicationStartDate === null' [readonlyInput]='true'
                     [minDate]='publicationStartDate' [maxDate]='eventEndDate' [showTime]='true'
                     [style]="{'width':'100%','min-width':'100px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
                     appendTo='body'></p-calendar>
               </div>
            </div>

         </div>

         <!-- ROW 4: NUMBER OF PERSONS && NUMBER OF REGISTRATIONS -->
         <div class="row align-items-center">

            <!-- NUMBER OF PERSONS -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'general.numberPersons' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText type='number' max='9999999' min='0' [(ngModel)]='racingEvent.numPersons'
                     style='text-align: right' class="txtbStyle" />
               </div>
            </div>

            <!-- NUMBER OF REGISTRATIONS -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input fieldName="{{ 'general.numberRegistrations' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText type='number' [(ngModel)]='racingEvent.usersRegistered' [disabled]='true'
                     style='text-align: right' class="txtbStyle" />
               </div>
            </div>

         </div>

         <!-- ROW 5: DESCRIPTION && USER LEGAL WEB -->
         <div class="row align-items-center">

            <!-- DESCRIPTION -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'general.description' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText [(ngModel)]='racingEvent.infoEvent' class="txtbStyle" />
               </div>
            </div>

            <!-- USER LEGAL WEB -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'general.webPage' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText [(ngModel)]='racingEvent.urlLegalText' class="txtbStyle" />
               </div>
            </div>

         </div>

      </div>

   </p-panel>

   <!-- CODES PANEL -->
   <p-panel [style]="{'margin-top': '16px'}" header="{{ 'events.eventCodes' | translate }}"
      [ngClass]="isEditingDisabled ? 'disabledPanel' : ''">
      <div class='d-flex flex-column gap-3 p-3'>

         <!-- ROW 1 -->
         <div class="row align-items-center">

            <!-- CODE TYPE -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'events.codeType' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <p-dropdown [options]='eventCodeTypeList' [(ngModel)]='selectedEventCodeType'
                     [style]="{'width':'100%','min-width':'100px'}" placeholder='Select Type Code' i18n-placeholder
                     optionLabel='nameCode'></p-dropdown>
               </div>
            </div>

            <!-- EVENT CODE -->
            <div class="col row dvcFields" *ngIf='selectedEventCodeType.uniqueCode'>
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'events.eventCode' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText [(ngModel)]='generalEventCode.code' class="txtbStyle" />
               </div>
            </div>

            <!-- CSV UPLOAD -->
            <div class="col row dvcFields" *ngIf='!selectedEventCodeType.uniqueCode'>
               <div class="col lblStyle">
                  <button id='uploadCsvButton' (click)='uploadCsvInput.click()'>
                     <img id='uploadCsvIcon' alt='' src='{{csvIconUrl}}' />
                  </button>
               </div>
               <div class="col">
                  <input #uploadCsvInput [hidden]='true' type='file' accept='.csv' (change)='changeCSV($event)'>
               </div>
            </div>

         </div>

         <!-- ROW 2 -->
         <div *ngIf='!selectedEventCodeType.uniqueCode' class='d-flex flex-row justify-content-center pt-3'>
            <p-table [value]='uniqueEventCodeList' [rows]='10' [rowsPerPageOptions]='[10,20,30,40,50]'
               [paginator]='true' [rowHover]='true' [showCurrentPageReport]='true' [autoLayout]='true'
               currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
               styleClass='p-datatable-striped'>

               <!-- Table Column/Header Definition -->
               <ng-template pTemplate='header'>
                  <tr>

                     <th>
                        <div class='p-d-flex p-jc-between p-ai-center text-center'>
                           {{ 'events.eventCodes' | translate }}
                        </div>
                     </th>

                  </tr>
               </ng-template>

               <!-- Table Row Definition -->
               <ng-template pTemplate='body' let-eventCode>
                  <tr>
                     <td style='text-align: center;'>
                        <span class='p-column-title'>Codi Esdeveniment</span>
                        {{eventCode.code}}
                     </td>

               </ng-template>

               <!-- TABLE EMPTY MESSAGE -->
               <ng-template pTemplate='emptymessage'>
                  <tr>
                     <td colspan='2' class='p-0'>
                        <div class='d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message'
                           i18n>
                           {{ 'table.tableEmptyMessage' | translate }}!
                        </div>
                     </td>
                  </tr>
               </ng-template>

            </p-table>
         </div>

      </div>

   </p-panel>

   <!-- POI PANEL -->
   <p-panel [style]="{'margin-top': '16px', 'margin-bottom': '16px'}" header="{{ 'events.trackAndPois' | translate }}"
      [ngClass]="isEditingDisabled ? 'disabledPanel' : ''">
      <div class='d-flex flex-column gap-3 p-3'>

         <!-- ROW 1 -->
         <div class="row align-items-center">

            <!-- FILE TRACK -->
            <div class="col row dvcFields">
               <div class="col lblStyle">
                  <form-input [required]='false' fieldName="{{ 'events.trackFile' | translate }}" [errors]=''>
                  </form-input>
               </div>
               <div class="col">
                  <input pInputText [disabled]='true' [(ngModel)]='selectedTrack.fileName' disabled="true" class="txtbStyle"/>
               </div>
            </div>
         
            <!-- KML UPLOAD -->
            <div class="col row dvcFields">
                  <button id='uploadKmlButton' class='btn btn-outline-dark' (click)='uploadKmlInput.click()'>
                     ...
                  </button>
                  <input #uploadKmlInput [hidden]='true' type='file' accept='.kml' (change)='changeKml($event)'>
            </div>
         
         </div>

         <div class="row align-items-center">

            <!-- CREATE POIS WARNING -->
            <div *ngIf='isNew'>
               <div class='alert alert-warning' role='alert'>
                  {{ 'events.cannotAddPois' | translate}}!
               </div>
            </div>

            <div class="row align-items-center">

               <div class="col row dvcFields">
                     <app-custom-map #eventsMap mapId='eventsMap' display='true' style='width: 100%' [racingPois]='poiList'
                     [options]='customMapOptions' (eventCreatePOI)='addPoi($event)' class="mapStyle"></app-custom-map>
               </div>
            
               <div class="col row dvcFields">
                  <p-table [value]='poiList' dataKey='id' [rows]='10' [rowsPerPageOptions]='[10,20,30,40,50]'
                  [paginator]='true' [rowHover]='true' [showCurrentPageReport]='true' [autoLayout]='true'
                  [ngClass]="{'disabledPanel': isNew}"
                  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
                  styleClass='p-datatable-striped'>

                  <!-- Table Title -->
                  <ng-template pTemplate='caption'>
                     <div class='p-d-flex p-ai-center p-jc-between text-center'>POIs</div>
                  </ng-template>

                  <!-- Table Column/Header Definition -->
                  <ng-template pTemplate='header'>
                     <tr>

                        <th>
                           <div class='p-d-flex p-jc-between p-ai-center text-center'>
                              {{ 'events.pois.namePoi' | translate }}
                           </div>
                        </th>

                        <th style='width: 3rem;'>

                        </th>

                     </tr>
                  </ng-template>

                  <!-- Table Row Definition -->
                  <ng-template pTemplate='body' let-poi let-index='rowIndex'>

                     <tr class='p-selectable-row' (dblclick)='editPoi(poi)'>
                        <td style='text-align: center;'>
                           {{poi.namePoi}}
                        </td>

                        <td style='width: 3rem'>
                           <div class='d-flex'>
                              <button id='deletePoiButton' (click)='deletePoi(poi, index)'>
                                 <img id='deletePoiIcon' src='assets/images/Racing/delete.png' alt=''>
                              </button>
                           </div>
                        </td>

                     </tr>

                  </ng-template>

                  <!-- TABLE EMPTY MESSAGE -->
                  <ng-template pTemplate='emptymessage'>
                     <tr>
                        <td colspan='2' class='p-0'>
                           <div class='d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message'
                              i18n>
                              {{ 'table.tableEmptyMessage' | translate }}!
                           </div>
                        </td>
                     </tr>
                  </ng-template>

               </p-table>
               </div>
            
            </div>
            <div class='d-flex flex-row gap-3'>
               
               <div class='d-flex flex-column w-75'>

               </div>

               
            </div>

         </div>

      </div>

   </p-panel>

   <!-- POI FORM-->
   <app-poi-racing-form #poiRacingForm *ngIf='!isNew' [eventId]='racingEvent.id' [selectedPoi]='selectedPoi'
      (poiAdded)='addPoi($event)' (poiSaved)='savePoi($event)'></app-poi-racing-form>

   <p-footer>

      <div class='d-flex flex-row-reverse bd-highlight'>

         <div class='p-2 bd-highlight'>
            <button type='button' class='btn custom-button' (click)='saveRacingEvent()' [disabled]='isEditingDisabled'>
               {{ 'general.save' | translate }}
            </button>
         </div>
      </div>
   </p-footer>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" baseZIndex='10000'
   header="{{ 'control-panel.delete-dialog.title' | translate }}" icon='pi pi-exclamation-triangle'></p-confirmDialog>