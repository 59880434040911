<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">ALARM MOVING
          (MOVING)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="alarmMoving.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="moving radius">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="alarmMoving.movingRadius" [min]="100" [max]="1000"></p-spinner>
          <span class="ui-inputgroup-addon">100~1000</span>
          <span class="ui-inputgroup-addon">m</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Alarm Moving Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="AlarmMovingOptions" [(ngModel)]="alarmMoving.mode" [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>
</p-accordionTab>