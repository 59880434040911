import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { InsuranceTypeService } from 'app/modules/vehicle-inventory/services/table-management/InsuranceType.service';
import { InsuranceComponentService } from 'app/modules/vehicle-inventory/services/table-management/insuranceComponent.service';
import { ProviderService } from 'app/rest/provider.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-insurance-quarterly-calculation',
  templateUrl: './insurance-quarterly-calculation.component.html',
  styleUrls: ['./insurance-quarterly-calculation.component.css'],
  providers:[InsuranceComponentService, ProviderService, InsuranceTypeService]
})
export class InsuranceQuarterlyCalculationComponent extends  EntityFormComponent<any> {
  title: string;
  display = false;
  calculationData: Rest.InsuranceQuarterlyParameters;
  quarterOptions: any[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ];

  selectedQuarter: number;
  selectedInsuranceType: Rest.InsuranceType;
  selectedProvider: Rest.Provider;
  providerList: Rest.Provider[] = [];
  insuranceTypeList: Rest.InsuranceType[] = [];
  providerRequestContext: Rest.ListPaginationRequest;
  insuranceTypeRequestContext: Rest.ListPaginationRequest;

  @Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();
  year: Date;
  constructor(
    private insuranceComponentService: InsuranceComponentService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    private providerService: ProviderService,
    private insuranceTypeService: InsuranceTypeService

) {
    super(insuranceComponentService, notificationsService, i18n, authenticationService);
}

ngOnInit() {
  super.ngOnInit();
    
}
public showDialog( ){
  this.title = this.translateService.instant("tableManagement.insurance.calculateQuarter");
  this.providerRequestContext = RestExt.firstPageRequestVehicleInventory();
  this.insuranceTypeRequestContext = RestExt.firstPageRequestVehicleInventory();

  this.providerRequestContext.filters["type"] = [RestExt.ProviderType.Seguros.toString()];

  this.providerService.getPage(this.providerRequestContext).then((p) => {
    this.providerList = p.entities;
  });

  this.insuranceTypeService.getPage(this.insuranceTypeRequestContext).then((p) => {
    this.insuranceTypeList = p.entities;
  });

  this.display = true;


 }
 

closeFormDialog(): void {
  this.display = false;
  this.refreshTable.emit();
}

calculate(): void {
  var that = this;
  //initialize this.calculationData
  this.calculationData = {} as Rest.InsuranceQuarterlyParameters;
  this.calculationData.insuranceType = this.selectedInsuranceType;
  this.calculationData.provider = this.selectedProvider;

  if(this.selectedQuarter == 1){
    this.calculationData.since = new Date(this.year.getFullYear(),0,1,0,0,0,0);
    this.calculationData.until = new Date(this.year.getFullYear(),2,31,0,0,0,0);
  }else if(this.selectedQuarter == 2){
    this.calculationData.since = new Date(this.year.getFullYear(),3,1,0,0,0,0);
    this.calculationData.until = new Date(this.year.getFullYear(),5,30,0,0,0,0);
  }else if(this.selectedQuarter == 3){
    this.calculationData.since = new Date(this.year.getFullYear(),6,1,0,0,0,0);
    this.calculationData.until = new Date(this.year.getFullYear(),8,30,0,0,0,0);
  }else if(this.selectedQuarter == 4){
    this.calculationData.since = new Date(this.year.getFullYear(),9,1,0,0,0,0);
    this.calculationData.until = new Date(this.year.getFullYear(),11,31,0,0,0,0);
  }


  const offsetMinutes = this.calculationData.since.getTimezoneOffset();

  this.calculationData.since = new Date(this.calculationData.since.getTime() - offsetMinutes * 60000);
  this.calculationData.until = new Date(this.calculationData.until.getTime() - offsetMinutes * 60000);

  console.log(JSON.stringify(this.calculationData));


  this.insuranceComponentService.calculateQuarterly(this.calculationData).then((response) => {
    that.notificationsService.add(Severity.success, "", this.translateService.instant('general.updateSuccessfull'));
    that.display = false;
    that.refreshTable.emit();
  }).catch((error) => {
    that.notificationsService.add(Severity.error, "", this.translateService.instant('general.updateError'));
  });
}

onChangeProvider(event) {
  this.selectedProvider = event.value;
}

onFilterProvider(event) {
  this.providerRequestContext.filters["name"] = [event.filter ?? ''];
  this.providerRequestContext.filters["type"] = [RestExt.ProviderType.Seguros.toString()];
  this.providerService.getPage(this.providerRequestContext).then((p) => {
    this.providerList = p.entities;
  });
}

onChangeInsuranceType(event) {
  this.selectedInsuranceType = event.value;
}

onFilterInsuranceType(event) {


  this.insuranceTypeRequestContext.filters["name"] = [event.filter ?? ''];

  this.insuranceTypeService.getPage(this.insuranceTypeRequestContext).then((p) => {
    this.insuranceTypeList = p.entities;
  });
}

}
