<!-- VEHICLE PANEL MAIN CONTAINER -->
<div class="container-fluid m-0 p-0 custom-panel">

  <!-- ROW 1: VEHICLE PANEL NAV TABS -->
  <div class="row m-0 mt-3">
    <ul class="nav nav-tabs custom-nav-tabs">

      <li class="nav-item">
        <a href="#vehicle-data" class="nav-link active p-0 custom-title custom-tab-title first-nav-tab"
          data-bs-toggle="tab">
          <div class="d-flex align-items-center justify-content-center h-100" i18n>
            <span *ngIf="!isUserHosp">{{'real-time.vehiclePanel.vehicleData' | translate}}</span>
            <span *ngIf="isUserHosp">{{'real-time.vehiclePanel.housingData' | translate}}</span>
          </div>
        </a>
      </li>

      <li class="nav-item">
        <a href="#events" class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
          <div class="d-flex align-items-center justify-content-center h-100" i18n>
            {{'real-time.vehiclePanel.events' | translate}}
          </div>
        </a>
      </li>

      <li class="nav-item flex-fill">
        <div class="d-flex w-100">
          <a class="nav-link p-0 custom-title custom-tab-title empty-tab">
          </a>
        </div>
      </li>

    </ul>
  </div>

  <!-- ROW 2: VEHICLE PANEL NAV CONTENT -->
  <div class="row m-0">
    <div class="tab-content p-0">

      <div class="tab-pane fade show active" id="vehicle-data">

        <!-- ROW 1: VEHICLE DATA VIEW DROPDOWN & CONFIG BUTTON -->
        <div class="row row-cols-4 m-4">

          <!-- COL 1: VIEW DROPDOWN -->
          <div class="col ps-0 pe-0">
            <div class="d-flex align-items-center h-100">
              <select name="language" id="language" class="form-select custom-select custom-vehicle-panel-select"
                [(ngModel)]="_selectedSettings">
                <!--  [(ngModel)]="_selectedSettings"-->

                <option *ngFor="let setting of availableSettings" [ngValue]="setting">{{setting.name}}</option>
              </select>
            </div>
          </div>

          <!-- COL 2: CONFIG BUTTON -->
          <div class="col">
            <div class="d-flex align-items-center justify-content-start h-100">
              <button (click)="openModal()" type="button" class="btn custom-vehicle-panel-settings-button"
                data-bs-toggle="modal" data-bs-target="#vehiclePanelSettings">
                <svg-icon src="assets/icons/panel-config.svg" class="d-flex flex-wrap"
                  [svgStyle]="{ 'height.px': 25, 'width.px': 30}"></svg-icon>
              </button>
            </div>
          </div>

        </div>

        <!-- TODO: DELETE BUTTONS AFTER ALL TODOS ARE FINISHED -->
        <!-- ROW 0: TEMP BUTTONS-->
        <div hidden class="row mb-5">

          <div class="d-flex align-items-center justify-content-center gap-3">
            <button *ngIf="!isUserHosp" class="btn custom-button" (click)=table()>
              <span *ngIf="showTable">Hide Table</span>
              <span *ngIf="!showTable">Show Table</span>
            </button>

            <button *ngIf="!isUserHosp" class="btn custom-button" (click)=grid()>
              <span *ngIf="showGrid">Hide Grid</span>
              <span *ngIf="!showGrid">Show Grid</span>
            </button>
          </div>

        </div>

        <div class="ms-3 me-3 mb-2">
          <!-- <div> -->
          <!--<app-data-table *ngIf="showTable" [settings]=_selectedSettings></app-data-table>-->
          <app-data-table *ngIf="_selectedSettings.view === 'TABLE'" [settings]=_selectedSettings></app-data-table>
        </div>

        <!--<div *ngIf="showGrid">  -->
        <div *ngIf="_selectedSettings.view !== 'TABLE'">
          <div class="accordion custom-accordion">
            <div *ngFor="let v of vehicles">
              <app-vehicle-card [vehicle]=v [settings]=_selectedSettings></app-vehicle-card>
            </div>
          </div>
        </div>

      </div>

      <div class="tab-pane fade" id="events">

        <div class="m-4">
          <app-events-table></app-events-table>
        </div>

      </div>

    </div>
  </div>

</div>

<!-- VEHICLE PANEL SETTINGS MODAL-->
<div class="modal fade vehicle-panel-settings-modal" id="vehiclePanelSettings" aria-hidden="true"
  aria-labelledby="vehiclePanelSettings" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">

    <!-- VEHICLE PANEL SETTINGS MODAL CONTENT -->
    <div class="modal-content">

      <!-- VEHICLE PANEL SETTINGS MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col-10">
              <span class="modal-title custom-title" i18n>
                {{'real-time.vehiclePanel.devicePanelSettings' | translate}}
              </span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col-2">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button type="button" #closeModalEl class="btn-close m-0 p-0 custom-modal-close-button"
                  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- VEHICLE PANEL SETTINGS MODAL BODY -->
      <div class="modal-body">
        <div class="container-fluid">

          <!-- ROW 1: VEHICLE PANEL CONFIG & BUTTONS -->
          <!--  <div class="row mt-1">

            <div class="col">
              <div class="d-flex align-items-center h-100">
                <label for="vehicle-data-settings-name" class="form-label custom-label mb-0 me-2" i18n>
                  {{'real-time.vehiclePanel.settingsName' | translate}}:
                </label>
                <input type="text" id="vehicle-data-settings-name" class="form-control custom-input h-45" [(ngModel)]=_selectedSettings.name>
              </div>
            </div> 

    

          </div>
        -->


          <!-- ROW 3: VEHICLE PANEL VIEW SETTINGS -->
          <div class="row">
            <div class="col">
              <app-vehicle-panel-settings [(settings)]=_selectedSettings></app-vehicle-panel-settings>
            </div>
          </div>
          <!-- ROW 4:  NEW VIEW BUTTON -->
          <div class="row justify-content-end">

            <div class="col-auto mr-auto mt-3">
            <!-- SAVE AS NEW BUTTON -->
              <button *ngIf="!showNewView" class="btn custom-button" (click)="setNewViewForm()" i18n>
                {{'general.newConfig' | translate}}
              </button>
            </div>

            <div class="col-auto mr-auto mt-3">
            <!-- UPDATE BUTTON -->
              <button class="btn custom-button custom-device-panel-button"
                *ngIf="_selectedSettings.id !== -1 && !showNewView" (click)=update() i18n>
                {{'general.update' | translate}}
              </button>
            </div>

            <div class="col-auto mr-auto mt-3">
              <!-- DELETE BUTTON -->
              <button *ngIf="!showNewView" class="btn custom-button custom-device-panel-button"
                [disabled]="_selectedSettings.id === -1" (click)="delete()" i18n>
                {{'general.delete' | translate}}
              </button>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>