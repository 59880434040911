
<p-accordionTab i18n-header header="Jam Event">

      <div class="ui-g">

        <form-input [required]="true" i18n-fieldName fieldName="Output pattern" [errors]="errors.name">
          <app-output-pattern [outputPattern]="deviceParams.proprietaryParameters.jam.outputPattern"></app-output-pattern>
        </form-input>
      </div>

    </p-accordionTab>
