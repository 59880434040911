<div *ngIf="display">
    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <p-messages [(value)]="messages"></p-messages>
        </div>
    </div>

    <p-tabView #tv (onChange)="tabChanged($event)">
        <p-tabPanel i18n-header header="Vehicle">
            <div class="ui-g container">
                <input pInputText hidden="true" id="vehicleId" [(ngModel)]="vehicleId" type="text" />
                <input pInputText hidden="true" id="id" [(ngModel)]="groupId" type="text" />
                <div class="ui-g-12 ui-md-6">
                    <form-input [required]="true" fieldName="{{'control-panel.vehicles.name' | translate}}" [errors]="errors.name">
                        <input pInputText id="name" [(ngModel)]="vehicle.name" type="text" />
                    </form-input>
                </div>


                <div *ngIf="clientUser.id != 295656 && clientUser.id != 413513" class="ui-g-12 ui-md-6"> <!-- tax client can't change maxSpeed-->
                    <form-input fieldName="{{'control-panel.vehicles.maxSpeed' | translate}}" [required]="true"
                        [errors]="errors.maxSpeed">
                        <input placeholder="0.0" pInputText id="maxSpeed" [(ngModel)]="vehicle.maxSpeed" type="number"
                            step="0.1" />
                    </form-input>
                </div>



                <div class="ui-g-12 ui-md-6">
                    <form-input fieldName="{{'control-panel.vehicles.mileage' | translate}}" [required]="true" [errors]="errors.km">
                        <input placeholder="0.0" pInputText [(ngModel)]="mileage" type="text" type="number"
                            step="0.1" />
                        <!-- {{null|distance:true}} -->
                    </form-input>
                </div>

                <div class="ui-g-12">
                    <div class="ui-g">
                        <div class="ui-g-4 ui-sm-12">
                            <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleType' | translate}}" [errors]="errors.type">
                                <p-dropdown id="dropdownTypes" (onChange)="typeChanged($event.value)" i18n-placeholder
                                    placeholder="Select a Type" [options]="availableTypes" [ngModel]="selectedType" filter="filter" dataKey='id' optionLabel='name'
                                    [style]="{'width':'190px'}" filter="filter"></p-dropdown>
                            </form-input>
                        </div>

                        <div class="ui-g-4 ui-sm-12">
                            <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleBrand.name' | translate}}" [errors]="errors.brand">
                                <div class="d-flex justify-content-end align-items-center">
                        
                                    <p-dropdown id="dropdownBrands" [disabled]=dropdownBrandsDisable
                                        (onChange)="brandChanged($event.value)" i18n-placeholder
                                        placeholder="Select a Brand" [options]="availableBrands"
                                        [ngModel]="selectedBrand" [style]="{'width':'190px'}" filter="filter" dataKey='id' optionLabel='name'>
                                    </p-dropdown>
                       
                                    <button [style]="{'width':'40px'}" style="margin-left: 5%;" type="button" pButton icon="fa fa-plus" (click)="addBrand()"
                                        pTooltip="{{'control-panel.vehicles.addBrand' | translate}}" tooltipPosition="top"></button>                       
                                </div>
                            </form-input>
                        
                        </div>

                        <div class="ui-g-4 ui-sm-12">
                                <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleModel.name' | translate}}" [errors]="errors.model">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <p-dropdown id="dropdownModels" [disabled]=dropdownModelsDisable i18n-placeholder
                                            placeholder="Select a Model" [options]="availableModels"
                                            [(ngModel)]="vehicle.model" [style]="{'width':'190px'}" filter="filter" dataKey='id' optionLabel='name'>
                                        </p-dropdown>
                                    </div>
                                    <button [style]="{'width':'40px'}" style="margin-left: 5%;" type="button" pButton icon="fa fa-plus" (click)="addModel()"
                                        pTooltip="{{'control-panel.vehicles.addModel' | translate}}" tooltipPosition="top"></button>    
                                </form-input>
                            
                        </div>
                    </div>
                </div>

                <div *ngIf="clientUser.id != 295656 && clientUser.id != 413513" class="ui-g-12 ui-md-6"> <!-- tax client can't change maxSpeed-->

                    <form-input fieldName="{{'control-panel.vehicles.dateManufacture' | translate}}" [errors]="errors.dateOfManufacture">
                        <p-calendar [locale]="i18n.calendarLocale" [(ngModel)]="dateOfManufacture" showIcon="true"
                            styleClass="ui-column-filter"></p-calendar>
                    </form-input>
                </div>


                <div class="ui-g-12 ui-md-6">
                    <form-input fieldName="{{'control-panel.vehicles.device' | translate}}" [errors]="errors.device">
                        <paged-search-dropdown [pageFunction]="this.deviceService.getPage.bind(this.deviceService)"
                            labelProperty="externalId | imei | phoneNumber" [(selectedEntity)]="vehicle.device"
                            [paginationRequest]="deviceRequestContext"></paged-search-dropdown>
                    </form-input>
                </div>


                <div class="ui-g-12 ui-md-6">

                    <form-input [required]="true" fieldName="{{'control-panel.vehicles.fleet' | translate}}" [errors]="errors.fleet">
                        <paged-search-dropdown [pageFunction]="this.fleetService.getPage.bind(this.fleetService)"
                            (selectedEntityChange)="fleetChanged($event)" [(selectedEntity)]="selectedFleet"
                            [paginationRequest]="fleetRequestContext"></paged-search-dropdown>
                    </form-input>
                </div>

                <div class="ui-g-12 ui-md-6">

                    <form-input [required]="true" fieldName="{{'control-panel.vehicles.group' | translate}}" [errors]="errors.group">
                        <paged-search-dropdown [tooltip]=tooltipGroup [disabled]=disabledGroup
                            [pageFunction]="this.groupService.getPage.bind(this.groupService)"
                            [(selectedEntity)]="selectedGroup" [paginationRequest]="vehicleGroupRequestContext"
                            (selectedEntityChange)="groupChanged($event)"></paged-search-dropdown>
                    </form-input>
                </div>

                <div *ngIf="clientUser.id != 295656 && clientUser.id != 413513" class="ui-g-12 ui-md-6"> <!-- tax client can't change maxSpeed-->
                    <form-input fieldName="{{'control-panel.vehicles.maintenancePlan' | translate}}" [errors]="errors.maintenancePlan">
                        <paged-search-dropdown
                            [pageFunction]="this.maintenancePlanService.getPage.bind(this.maintenancePlanService)"
                            [(selectedEntity)]="maintenancePlan" [paginationRequest]="maintenancePlanRequestContext"
                            (selectedEntityChange)="maintenancePlanChanged($event)">
                        </paged-search-dropdown>
                    </form-input>
                </div>

                <div class="ui-g-12" *ngIf="user.isAdmin">
                    <div class="ui-g">

                        <div class="ui-g-6 ui-sm-12">
                            <form-input fieldName="{{'control-panel.vehicles.client' | translate}}" [errors]="errors.client">
                                <paged-search-dropdown
                                    [pageFunction]="this.clientService.getPage.bind(this.clientService)"
                                    [(selectedEntity)]="client" [paginationRequest]="clientRequestContext"
                                    (selectedEntityChange)="clientChanged($event)">
                                </paged-search-dropdown>
                            </form-input>
                        </div>

                        <div class="ui-g-6 ui-sm-12">
                            <form-input [required]="true" fieldName="{{'control-panel.vehicles.billingAccount' | translate}}"
                                [errors]="errors.billingAccount">
                                <paged-search-dropdown [tooltip]=selectClient [disabled]=dropdownBillingAccountDisable
                                    [pageFunction]="this.billingaccountService.getPage.bind(this.billingaccountService)"
                                    [(selectedEntity)]="billingAccount"
                                    [paginationRequest]="billingAccountRequestContext"
                                    (selectedEntityChange)="billingAccountChanged($event)"></paged-search-dropdown>
                            </form-input>
                        </div>
                        <div class="ui-g-6 ui-sm-12">
                            <form-input [required]="true" fieldName="{{'control-panel.vehicles.owner' | translate}}"
                                [errors]="errors.owner">
                                <paged-search-dropdown [tooltip]=selectClient [disabled]=dropdownOwnerDisable
                                    [pageFunction]="this.userService.getPage.bind(this.userService)"
                                    [(selectedEntity)]="owner"
                                    [paginationRequest]="userRequestContext"
                                    (selectedEntityChange)="ownerChanged($event)"></paged-search-dropdown>
                            </form-input>
                        </div>
                    </div>
                </div>
                <div *ngIf="clientUser.id != 295656 && clientUser.id != 413513" class="ui-g-12 ui-md-6 row"> <!-- tax client can't change maxSpeed-->
                    <form-input i18n-fieldName [required]="false" fieldName="Metadata">
                        <app-metadata-editor [(metadata)]="vehicle.metadata"></app-metadata-editor>
                    </form-input>
                </div>

                <div class="ui-g-12 ui-md-6 row" style="margin-bottom: 20px;">
                    <form-input fieldName="{{'control-panel.vehicles.enabled' | translate}}" [errors]="errors.enabled">
                        <p-checkbox disabled="true" [(ngModel)]="vehicle.enabled" binary="true"></p-checkbox>
                    </form-input>
                </div>

                <div class="ui-g-12 ui-md-6 row">
                    <div class="ui-g-4 col col-md-2">
                        <div *ngIf="isNew;then create else update"></div>
                        <ng-template #create>
                            <button type="button" pButton icon="fa fa-check" (click)="saveVehicle()"
                                label="{{'general.save' | translate}}"></button>
                        </ng-template>
                        <ng-template #update>
                            <div *ngIf="permissions._$update">
                                <button type="button" pButton icon="fa fa-check" (click)="saveVehicle()"
                                    label="{{'general.save' | translate}}"></button>
                            </div>
                            <div *ngIf="permissions._$update===false">
                                <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update"
                                    tooltipPosition="top">
                                    <button disabled="true" type="button" pButton icon="fa fa-check"
                                        (click)="saveVehicle()" label="{{'general.save' | translate}}"></button>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <!-- #ChangeStatus  -->
                    <div *ngIf="showChangeStatus" class="col col-md-2">
                        <div class="ui-g-8">
                            <!-- <div *ngIf="permissions._$changeStatus;then permission else noPersmission"></div> -->
                            <div
                                *ngIf="checkBoxDisabled[vehicleName+'-'+vehicle.id]===false;then permission else noPersmission">
                            </div>
                            <ng-template #permission>
                                <button type="button" pButton icon="fa fa-close" style="float:left"
                                    (click)="changeStatus()" label="{{'general.changeStatus' | translate}}"></button>
                            </ng-template>
                            <ng-template #noPersmission>
                                <div style="display: inline-block;" i18n-pTooltip
                                    pTooltip="No permissions to change status" tooltipPosition="top">
                                    <button type="button" disabled="true" pButton icon="fa fa-close" style="float:left"
                                        (click)="changeStatus()" label="{{'general.changeStatus' | translate}}"></button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <!--  -->
                </div>

            </div>
        </p-tabPanel>

      <!--<p-tabPanel i18n-header header="Maintenances" [disabled]="!maintenanceTabVisible || maintenancePlan === null">
            <div *ngIf="maintenanceTabSelected">
                <app-maintenances-list [parentMaintenancePlan]="maintenancePlan" [vehicle]="vehicle" #maintenanceList>
                </app-maintenances-list>
            </div>
        </p-tabPanel>-->
    </p-tabView>


    <!-- #ChangeStatus  -->
    <div *ngIf="showStatusDialog">
        <app-change-status-dialog [items]=title [display]="true" [service]=vehicleService
            [entitiesList]=selectedEntities (return)="refreshForm($event)" (close)="closeStatusDialog($event)">
        </app-change-status-dialog>
    </div>
    <!--  -->
</div>

<app-vehicle-brand-form [display]=displayAddBrand
(closeModal)="cancelAddBrand()"></app-vehicle-brand-form>
<app-vehicle-model-form [display]=displayAddModel
(closeModal)="cancelAddModel()"></app-vehicle-model-form>
<!--app-maintenances-list was ment to be inside tabPanel but placing it there caused @ViewChild fail to get a reference of the control (provably due to the use of ngIf inside the tabPanel implementation).
so, we render the element outside the panel, but only make it visible when the corresponding tab is selected.
nasty tricks, nasty tricks everywere!
-->
<!-- <div [hidden]="!maintenanceTabSelected">
    <app-maintenances-list [parentMaintenancePlan]="maintenancePlan" #maintenanceList></app-maintenances-list>
</div> -->
