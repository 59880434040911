<!-- CLIENTS LIST PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
  [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
  [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
  [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
  styleClass="p-datatable-striped">

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'tableManagement.costCentres.costCentresManagement' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- CODE -->
      <th pSortableColumn="code">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.code' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="code" type="text" matchMode="contains"
              placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- DESCRIPTION -->
      <th pSortableColumn='description'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'promotedRoutes.description' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='description'></p-sortIcon>
            <p-columnFilter field='description' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="disabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="disabled"></p-sortIcon>
            <p-columnFilter field="disabled" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-ceco>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(ceco)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="ceco"></p-tableCheckbox>
        </div>
      </td>

      <!-- CODE -->
      <td>
        {{ceco.code}}
      </td>

      <!-- DESCRIPTION -->
      <td>
        {{ceco.description}}
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
            [ngClass]="{'true-icon pi-check-circle': !ceco.disabled, 'false-icon pi-times-circle': ceco.disabled}"></i>
        </div>
      </td>
    </tr>

  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="4" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD CLIENT BUTTON -->
          <app-table-add-button [text]=" 'tableManagement.costCentres.costCentre' | translate " (btnClick)="create()"
            [isDisabled]="false"></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>

          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.clients' | translate " [display]="true"
    [service]=cecoService [entitiesList]=selectedEntities (return)="refreshList($event)"
    (close)="closeStatusDialog($event)"></app-change-status-dialog>
</div>

<!-- VEHICLE USAGE FORM -->
<app-cost-centre-form></app-cost-centre-form>