<p-dialog *ngIf="display" header="{{title}}" [(visible)]="display"
    [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false" [resizable]="false"
    [closeOnEscape]="false" (onHide)="onHide($event)">

    <!-- #region DRIVER INFO CARD SETTINGS MAIN CONTAINER -->
    <div class="row mt4 align-items-center">
        <div class="col-4">

            <!-- <img id="vehicle-data-settings-image" [src]="imageUrl" alt="Driver Image" class="img-fluid rounded-circle"
                style="max-width: 75%;"> -->

            <img id="vehicle-data-settings-image" src="{{userEncodedImage}}" alt="Driver Image"
                class="custom-user-profile-image mx-auto d-block p-0 img-fluid rounded-circle">

            <div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
                <div id="inner-icon-holder" class="d-flex align-items-center justify-content-center"
                    (click)='uploadImageInput.click()'>

                    <img src="assets/icons/camera-plus.png" class="custom-user-profile-icon" />

                    <input [disabled]="!canEdit" #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png'
                        (change)='imageChange($event)'>
                </div>
            </div>
        </div>

        <div class="col-sm-4 mt-3">
            <!-- CODE -->
            <label for="vehicle-data-settings-field2" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.code' | translate }}
            </label>

            <input [disabled]="!isNew || !canEdit" type="text" id="vehicle-data-settings-field2"
                [(ngModel)]="entity.code" class="form-control custom-input">

            <div class="p-3"></div>

            <!-- SURNAME -->
            <label for="vehicle-data-settings-field4" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.surname' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field4"
                [(ngModel)]="entity.user.surname1" class="form-control custom-input">
        </div>

        <div class="col-sm-4 mt-3">
            <!-- NAME -->
            <label for="vehicle-data-settings-field3" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.name' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field3"
                [(ngModel)]="entity.user.name" class="form-control custom-input">

            <div class="p-3"></div>

            <!-- SECOND SURNAME -->
            <label for="vehicle-data-settings-field4" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.secondSurname' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field4"
                [(ngModel)]="entity.user.surname2" class="form-control custom-input">
        </div>
    </div>

    <div class="row mt4 mb-4">
        <div class="col-sm-4 mt-3">
            <!-- NIF -->
            <label for="vehicle-data-settings-field5" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.nif' | translate }}
            </label>
            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field5"
                [(ngModel)]="entity.nif" class="form-control custom-input">

        </div>

        <div class="col-sm-4 mt-3">
            <!-- NIF'S EXPIRATION DATE -->
            <label for="vehicle-data-settings-field6" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.expirationDateNif' | translate }}
            </label>

            <input type="date" id="vehicle-data-settings-field6" [(ngModel)]="entity.nifExpirationDate"
                [disabled]="!canEdit" class="form-control custom-input">
        </div>

        <div class="col-sm-4 mt-3">
            <!-- NIF'S EXPEDITION DATE -->
            <label for="vehicle-data-settings-field6" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.expeditionDateNif' | translate }}
            </label>

            <input type="date" id="vehicle-data-settings-field6" [(ngModel)]="entity.nifExpeditionDate"
                [disabled]="!canEdit" class="form-control custom-input">
        </div>


    </div>

    <div class="row mt-4 mb-4">

        <!-- BIRTH DATE -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field7" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.birthDate' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="date" id="vehicle-data-settings-field7"
                [(ngModel)]="entity.user.birthDate" class="form-control custom-input">
        </div>

        <!-- ADDRESS -->
        <div class="col-sm-4 mt-3 ">
            <label for="vehicle-data-settings-field1" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.address' | translate }}
            </label>
            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field1"
                [(ngModel)]="entity.user.address" class="form-control custom-input">
        </div>

        <!-- POSTAL CODE -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.postalCode' | translate }}
            </label>
            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field9"
                [(ngModel)]="entity.user.cp" class="form-control custom-input">
        </div>

    </div>

    <div class="row mt-4 mb-4">

        <!-- POPULATION -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.population' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field9"
                [(ngModel)]="entity.user.population" class="form-control custom-input">
        </div>

        <!-- PHONE NUMBER -->
        <div class="col-sm-4 mt-3 ">
            <label for="vehicle-data-settings-field1" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.phoneNumber' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field1"
                [(ngModel)]="entity.user.phoneNumber" class="form-control custom-input">
        </div>

        <!-- EXTENSION -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.extension' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field9"
                [(ngModel)]="entity.extension" class="form-control custom-input">
        </div>

    </div>


    <div class="row mt-4 mb-4">

        <!-- MOBILE NUMBER -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.movil' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field9"
                [(ngModel)]="entity.professionalPhoneNumber" class="form-control custom-input">
        </div>

        <!-- RPV -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.rpv' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="vehicle-data-settings-field9"
                [(ngModel)]="entity.rpv" class="form-control custom-input">
        </div>

        <!-- COMPANY EMAIL -->
        <div class="col-sm-4 mt-3">
            <label for="vehicle-data-settings-field8" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.companyEmail' | translate }}
            </label>

            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="driver-data-field5"
                [(ngModel)]="entity.professionalEmail" class="form-control custom-input">
        </div>
    </div>

    <!-- PERSONAL EMAIL -->
    <div class="row mt-4 mb-4">
        <div class="col-sm-4 mt-3">
            <label for="driver-data-field5" class="form-label custom-label mb-0 me-2" i18n>
                {{ 'drivers.form.personalEmail' | translate }}
            </label>
            <input [disabled]="(!isNew && entity.importERP) || !canEdit" type="text" id="driver-data-field5"
                [(ngModel)]="entity.user.email" class="form-control custom-input">
        </div>
    </div>
    <!-- #endregion -->

    <div class="container-fluid p-0 custom-panel">

        <!-- #region ROW 1: VEHICLE INFO CARD NAV TABS -->
        <div class="row m-0 mt-4">
            <ul class="nav nav-tabs custom-nav-tabs">

                <li class="nav-item">
                    <a href="#driver-data1" class="nav-link active p-0 custom-title custom-tab-title first-nav-tab"
                        data-bs-toggle="tab">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            {{ 'drivers.form.driverData' | translate }}
                        </div>
                    </a>
                </li>

                <li class="nav-item">
                    <a (click)="loadDriverLicense()" href="#drivers-license-data"
                        class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            {{ 'drivers.form.licenseData' | translate }}
                        </div>
                    </a>
                </li>

                <!--<li class="nav-item">
                <a href="#view-alerts1" class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
                    <div class="d-flex align-items-center justify-content-center h-100">
                        Alertas
                    </div>
                </a>
            </li>-->

                <li class="nav-item">
                    <a href="#documents1" class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            {{ 'drivers.form.documents' | translate }}
                        </div>
                    </a>
                </li>

                <li class="nav-item">
                    <a href="#vehicles" class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            {{ 'tableManagement.vehicles' | translate }}
                        </div>
                    </a>
                </li>

            </ul>
        </div>
        <!-- #endregion -->

        <!-- #region ROW 2: VEHICLE INFO CARD TAB CONTENT -->
        <div class="row m-0">
            <div class="tab-content p-0">
                <!-- #region DRIVER DATA TAB  -->
                <div class="tab-pane fade show active" id="driver-data1">
                    <div class="container-fluid">
                        <!-- #region COMPANY AND CECO disabled-->
                        <!--    <ng-container
                            *ngIf="!isNew && entity.importERP; else editBillingAccount; else cecoAndSubdivisionEditable">
                            <div class="row mt-4 mb-4">
                                <div class="col-sm-6">
                                    <label for="driver-data-field2" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.company' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input [disabled]="!isNew" type="text" id="driver-data-billingaccount-name"
                                                [ngModel]="entity.ceco?.billingAccount?.code"
                                                class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-9">
                                            <input [disabled]="!isNew" type="text" id="driver-data-billingaccount-name"
                                                [ngModel]="entity.ceco?.billingAccount?.name"
                                                class="form-control custom-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label for="driver-data-field1" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.ceco' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input [disabled]="!isNew" type="text" id="driver-data-ceco-code"
                                                [ngModel]="entity.ceco?.code" class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-8">
                                            <input [disabled]="!isNew" type="text" id="driver-data-ceco-description"
                                                [ngModel]="entity.ceco?.description" class="form-control custom-input">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row mt-4 mb-4">
                                <div class="col-sm-6">
                                    <label for="driver-data-field3" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.division' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input [disabled]="!isNew" type="text" id="driver-data-division-code"
                                                [ngModel]="entity.subdivision?.division?.code"
                                                class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-9">
                                            <input [disabled]="!isNew" type="text" id="driver-data-division-name"
                                                [ngModel]="entity.subdivision?.division?.name"
                                                class="form-control custom-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label for="driver-data-field4" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.subdivision' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input [disabled]="!isNew" type="text" id="driver-data-subdivision-code"
                                                [ngModel]="entity.subdivision?.code" class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-9">
                                            <input [disabled]="!isNew" type="text" id="driver-data-subdivision-name"
                                                [ngModel]="entity.subdivision?.name" class="form-control custom-input">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->
                        <!-- #region COMPANY AND CECO Editable-->
                        <ng-container>
                            <div class="row mt-4 mb-4">
                                <div class="col-sm-6">
                                    <label for="driver-data-field2" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.company' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <p-dropdown [options]='billingAccountList'
                                                [ngModel]='selectedBillingAccount' placeholder="Select..." dataKey='id'
                                                optionLabel='code' [style]="{'width':'100%', 'margin-right':'5px'}"
                                                appendTo='body' filter="true" filterBy="code"
                                                (onChange)="onChangeBillingAccount($event)"
                                                [disabled]='!isNew && entity.importERP'>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]='billingAccountList'
                                                [ngModel]='selectedBillingAccount' placeholder="Select..." dataKey='id'
                                                optionLabel='name' [style]="{'width':'100%', 'margin-right':'5px'}"
                                                appendTo='body' filter="true" filterBy="name"
                                                (onChange)="onChangeBillingAccount($event)"
                                                [disabled]='!isNew && entity.importERP'>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <!-- CECO -->
                                <div class="col-sm-6">
                                    <label for="driver-data-field1" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.ceco' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <p-dropdown [options]='cecoList' [ngModel]='entity.ceco'
                                                placeholder="Select Ceco..." dataKey='id' optionLabel='code'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="code" (onChange)="onChangeCeco($event)"
                                                [disabled]='(!isNew && entity.importERP) || !isCecoListLoaded'>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]='cecoList' [ngModel]='entity.ceco'
                                                placeholder="Select..." dataKey='id' optionLabel='description'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="name" (onChange)="onChangeCeco($event)"
                                                [disabled]='(!isNew && entity.importERP) || !isCecoListLoaded'>
                                            </p-dropdown>
                                        </div>


                                        <!--  <form-input [required]='false' [align]="'cols-3'"
                                        fieldName="{{ 'drivers.table.ceco' | translate }}">
 
                                        <p-dropdown [options]='cecoList' [(ngModel)]='entity.ceco'
                                            placeholder="Select..." dataKey='id' optionLabel='code'
                                            [style]="{'width':'130px', 'margin-right':'5px'}" appendTo='body'
                                            filter="true" filterBy="code"
                                            [disabled]="!isCecoListLoaded || isEditingDisabled">
                                        </p-dropdown>
                                        <p-dropdown [options]='cecoList' [(ngModel)]='selectedCeco'
                                            placeholder="Select..." dataKey='id' optionLabel='description'
                                            [style]="{'width':'190px', 'margin-right':'5px'}" appendTo='body'
                                            filter="true" filterBy="description"
                                            [disabled]="!isCecoListLoaded || isEditingDisabled">
                                        </p-dropdown>
                                        <button *ngIf="selectedCeco !== null && cecoList !== null"
                                            class="btn custom-expand-button" type="button" alt="Remove"
                                            (click)="onRemoveCeco()">
                                            <svg-icon [disabled]='isEditingDisabled' [ngClass]="{'rotated': !expanded}"
                                                class="d-flex m-0 p-0 custom-expand-button-icon"
                                                src="assets/icons/remove.svg"
                                                [svgStyle]="{'height.px': 30, 'width.px': 30}"></svg-icon>
                                        </button>
                                    </form-input> -->
                                        <!--
                                        <div class="col-sm-4">
                                            <input [disabled]="!isNew" type="text" id="driver-data-ceco-code"
                                                [ngModel]="entity.ceco?.code" class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-8">
                                            <input [disabled]="!isNew" type="text" id="driver-data-ceco-description"
                                                [ngModel]="entity.ceco?.description" class="form-control custom-input">
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                            <!-- #endregion -->
                            <!-- #region DIVISION AND SUBDIVISION  -->
                            <div class="row mt-4 mb-4">
                                <!-- DIVISION -->
                                <div class="col-sm-6">
                                    <label for="driver-data-field2" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.division' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <p-dropdown [options]='divisionList' [ngModel]='selectedDivision'
                                                placeholder="Select..." dataKey='id' optionLabel='code'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="code" (onChange)="onChangeDivision($event)"
                                                [disabled]='!isNew && entity.importERP'>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]='divisionList' [ngModel]='selectedDivision'
                                                placeholder="Select..." dataKey='id' optionLabel='name'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="name" (onChange)="onChangeDivision($event)"
                                                [disabled]='!isNew && entity.importERP'>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <!-- SUBDIVISION -->
                                <div class="col-sm-6">
                                    <label for="driver-data-field1" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.subdivision' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <p-dropdown [options]='subDivisionList' [ngModel]='entity.subdivision'
                                                placeholder="Select Ceco..." dataKey='id' optionLabel='code'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="code" (onChange)="onChangeSubdivision($event)"
                                                [disabled]='(!isNew && entity.importERP) || !isSubdivisionListLoaded'>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]='subDivisionList' [ngModel]='entity.subdivision'
                                                placeholder="Select..." dataKey='id' optionLabel='name'
                                                [style]="{'width':'100%', 'margin-right':'5px'}" appendTo='body'
                                                filter="true" filterBy="name" (onChange)="onChangeSubdivision($event)"
                                                [disabled]='(!isNew && entity.importERP) || !isSubdivisionListLoaded'>
                                            </p-dropdown>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <!-- <div class="row mt-4 mb-4">
                                <div class="col-sm-6">
                                    <label for="driver-data-field3" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.division' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input [disabled]="!isNew" type="text" id="driver-data-division-code"
                                                [ngModel]="entity.subdivision?.division?.code"
                                                class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-9">
                                            <input [disabled]="!isNew" type="text" id="driver-data-division-name"
                                                [ngModel]="entity.subdivision?.division?.name"
                                                class="form-control custom-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label for="driver-data-field4" class="form-label custom-label mb-0 me-2" i18n>
                                        {{ 'drivers.form.subdivision' | translate }}
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input [disabled]="!isNew" type="text" id="driver-data-subdivision-code"
                                                [ngModel]="entity.subdivision?.code" class="form-control custom-input">
                                        </div>
                                        <div class="col-sm-9">
                                            <input [disabled]="!isNew" type="text" id="driver-data-subdivision-name"
                                                [ngModel]="entity.subdivision?.name" class="form-control custom-input">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </ng-container>



                        <!-- #endregion  -->
                        <div class="d-flex justify-content-center bd-highlight">
                            <div class="p-2 bd-highlight">
                                <div *ngIf="!isNew">
                                    <button [disabled]='!canEdit' class="btn custom-button" (click)="save()" i18n>
                                        {{'general.save' | translate}}
                                    </button>
                                </div>

                                <div *ngIf="isNew">
                                    <button [disabled]='!canEdit' class="btn custom-button" (click)="save()" i18n>
                                        {{'general.create' | translate}}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- #endregion -->

                <!-- #region DRIVERS LICENSE DATA TAB  -->
                <div class="tab-pane fade" id="drivers-license-data">
                    <div class="container-fluid">

                        <!-- #region A & A2 DRIVERS LICENSE -->
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-6">
                                <label for="company-data-field1" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicense' | translate }}
                                </label>
                                <input type="date" [disabled]="!isNew" [(ngModel)]="expeditionDate"
                                    id="company-data-field1" class="form-control custom-input">
                            </div>
                            <div class="col-sm-6">
                                <label for="company-data-field2" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expirationDateLicense' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="expirationDate" id="company-data-field2"
                                    class="form-control custom-input">
                            </div>
                        </div>
                        <hr class="solid">
                        <!-- #region A & A2 DRIVERS LICENSE -->
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-6">
                                <label for="company-data-field1" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseA' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['A']" id="company-data-field1"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                            <div class="col-sm-6">
                                <label for="company-data-field2" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseA2' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['A2']" id="company-data-field2"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                        </div>
                        <!-- #endregion -->

                        <!-- #region B & C DRIVERS LICENSE  -->
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-6">
                                <label for="company-data-field3" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseB' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['B']" id="company-data-field3"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                            <div class="col-sm-6">
                                <label for="company-data-field4" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseC' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['C']" id="company-data-field4"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                        </div>
                        <!-- #endregion -->

                        <!-- #region C1 & D DRIVERS LICENSE -->
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-6">
                                <label for="company-data-field5" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseC1' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['C1']" id="company-data-field5"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                            <div class="col-sm-6">
                                <label for="company-data-field6" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseD' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['D']" id="company-data-field6"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                        </div>
                        <!-- #endregion -->

                        <!-- #region D1 & CE DRIVERS LICENSE -->
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-6">
                                <label for="company-data-field5" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseD1' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['D1']" id="company-data-field5"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                            <div class="col-sm-6">
                                <label for="company-data-field6" class="form-label custom-label mb-0 me-2" i18n>
                                    {{ 'drivers.form.expeditionDateLicenseCE' | translate }}
                                </label>
                                <input type="date" [(ngModel)]="dates['CE']" id="company-data-field6"
                                    (ngModelChange)="changeLicenseDate()" class="form-control custom-input">
                            </div>
                        </div>

                        <div class="d-flex justify-content-center bd-highlight">
                            <div class="p-2 bd-highlight">
                                <div *ngIf="!isNew">
                                    <button class="btn custom-button" (click)="saveLicenseData()" i18n>
                                        {{'general.save' | translate}}
                                    </button>
                                </div>

                                <div *ngIf="isNew">
                                    <button class="btn custom-button" (click)="saveLicenseData()" i18n>
                                        {{'general.create' | translate}}
                                    </button>
                                </div>
                            </div>

                        </div>
                        <!-- #endregion -->
                    </div>
                </div>
                <!-- #endregion -->

                <!-- #region VIEW ALERTS TAB -->
                <div class="tab-pane fade" id="view-alerts1">
                    <div class="container-fluid">

                        <!-- #region ALERTS TABLE -->
                        <div class="row mt-4 mb-4">
                            <div class="col-12">
                                <!--<app-vehicle-alerts-table></app-vehicle-alerts-table>-->
                            </div>
                        </div>
                        <!-- #endregion -->

                    </div>
                </div>
                <!-- #endregion -->

                <!-- #region DOCUMENTS TAB -->
                <div class="tab-pane fade" id="documents1">
                    <div class="container-fluid">
                        <app-doc-table [type]="type" [entity]="entity" [nested]="true">
                        </app-doc-table>
                    </div>
                </div>
                <!-- #endregion -->

                <!-- #region VEHICLES TAB -->
                <div class="tab-pane fade" id="vehicles">
                    <div class="container-fluid p-3">

                        <!-- <app-vehicle-list [driver]="entity"></app-vehicle-list> -->
                        <app-drivers-assignments-list [driver]="entity"></app-drivers-assignments-list>

                        <!-- <ng-template ngFor let-vehicle [ngForOf]="vehicles" let-i="index">
                            <label class="form-label custom-label mb-0 me-2 vehicle-label mt-3" i18n>
                                {{vehicle.name}}
                            </label>
                        </ng-template> -->

                    </div>
                </div>
                <!-- #endregion -->

            </div>
        </div>
    </div>
</p-dialog>
<!-- #endregion -->