import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from '../rest_vehicle-inventory';

@Injectable({
  providedIn: 'root'
})
export class HtcCardListService extends EntityService<Rest.InvHtcCard> {

	client: Rest.RestApplicationClient;
	refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

	constructor(private jquery: JQueryHTTPClient) {
		 super();
		 this.client = new Rest.RestApplicationClient(jquery);
	}

	getPage(
		 arg0: Rest.ListPaginationRequest,
		 queryParams?: { includeDisabled?: boolean }
	): Rest.RestResponse<Rest.Page<Rest.InvHtcCard>> {
		 try {
			  return this.client.InvHtcCard_getPage(arg0, queryParams);
		 } catch (error) {
			  console.error("Error getting FineStatus page:", error);
			  throw error;
		 }
	}

}
