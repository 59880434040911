import { Component, OnInit, ViewEncapsulation, Input,ViewChild } from '@angular/core';
import { Rest } from 'app/rest/rest_client';

@Component({
  selector: 'app-start-stop-report-information-gmt200-form',
  templateUrl: './start-stop-report-information-gmt200-form.component.html',
  styleUrls: ['./start-stop-report-information-gmt200-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StartStopReportInformationGmt200FormComponent implements OnInit {

  @Input() StartStopReport: Rest.StartStopReportGMT200;
  
 

  
  constructor() { }

  ngOnInit() {
   
  }


}
