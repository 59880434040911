import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { UserService } from 'app/rest/user.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { RestExt } from 'app/services/rest-client-extension';
import { DocNoteComponent } from '../doc-note/doc-note.component';
import { DocDriverLicenseComponent } from '../doc-driver-license/doc-driver-license.component';
import { DocDriverPointsComponent } from '../doc-driver-points/doc-driver-points.component';
import { DriverpointsService } from 'app/modules/vehicle-inventory/services/driverpoints.service';
import { NoteService } from 'app/modules/vehicle-inventory/services/note.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { VehicleService } from 'app/rest/vehicle.service';
import { FineService } from 'app/rest/fine.service';
import { FineFormComponent } from '../../table-management/fines/fine-form/fine-form.component';
import { FineStatusService } from 'app/modules/vehicle-inventory/services/table-management/fineStatus.service';
import { TaxService } from 'app/modules/vehicle-inventory/services/table-management/taxes.service';
import { FinancingService } from 'app/modules/vehicle-inventory/services/table-management/financing.service';
import { InsuranceService } from 'app/modules/vehicle-inventory/services/table-management/insurance.service';
import { type } from 'jquery';
import { MaintenanceexecutionService } from 'app/rest/maintenanceexecution.service';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-doc-form',
	templateUrl: './doc-form.component.html',
	styleUrls: ['./doc-form.component.css'],
	providers: [DriverpointsService, NoteService]
})
export class DocFormComponent extends EntityFormComponent<Rest.InvDocument> implements OnInit, OnDestroy {

	@ViewChild('docNote') docNote: DocNoteComponent;
	@ViewChild('docDriverLicense') docDriverLicense: DocDriverLicenseComponent;
	@ViewChild('docDriverPoints') docDriverPoints: DocDriverPointsComponent;

	@Input() origen: string;  // type origen
	@Input() vehicle?: Rest.Vehicle;
	@Input() driver?: Rest.InvDriver;
	@Input() fine?: Rest.Fine;
	@Input() tax?: Rest.Tax;
	@Input() financing?: Rest.Financing;
	@Input() insurance?: Rest.Insurance;
	@Input() execution?: Rest.MaintenanceExecution;
	type: string;  // 'vehicle', 'driver', fine, tax, insurance, financing
	// vehicle: Rest.Vehicle;
	// driver: Rest.InvDriver;

	@Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();

	title: String;
	isDialogVisible: boolean = false;
	isEditingDisabled: boolean = false;
	errorMessages: string[] = [];
	isNew: boolean = false;
	showVehicle: boolean = false;

	// allDocTypeList: Rest.InvDocumentType[] = [];
	docTypeList: String[] = [];
	selectedVehiclePlate: string = null;

	documentFile: any;
	driverPoints: Rest.InvDriverPoints = null;
	note: Rest.InvNote = null;
	document: Rest.InvDocument = null;

	editingSubscription: Subscription;

	selectedFiles: FileList | null = null;

	// PERMISSIONS
	isDownloadGranted: boolean = true;

	constructor(
		private documentService: DocumentationService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public userService: UserService,
		private driverPointsService: DriverpointsService,
		private noteService: NoteService,
		private datePipe: DatePipe,
		private vehicleService: VehicleService,
		private finesService: FineService,
		private taxService: TaxService,
		private financingService: FinancingService,
		private insuranceService: InsuranceService,
		private executionService: MaintenanceexecutionService
	) {
		super(documentService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		// console.log("[DOC-FORM] ngOnInit ***");
		// console.log("origen: " + this.origen);
		super.ngOnInit();
		this.editingSubscription = this.documentService.startedEditing
			.subscribe(
				(item: Rest.InvDocumentEdit) => {
					// console.log("----- START EDITING -------");
					// console.log(this.origen.toUpperCase() + " === " + item.type.toUpperCase() + " ? ");
					if (this.origen.toUpperCase() === item.type.toUpperCase()) {
						this.showDialog(item.isNew, item.type, item.entity, item.vehicle);
					}
				});

		this.setPermissions();
	}

	ngOnDestroy(): void {
		this.editingSubscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authenticationService.user;

		switch (this.origen) {
			case 'DRIVER':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_DRIVER_DOC_DOWNLOAD', user);

				break;
			case 'FINES':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_FINES_DOC_DOWNLOAD', user);

				break;
			case 'TAXES':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_TAXES_DOC_DOWNLOAD', user);

				break;
			case 'FINANCING':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_FINANCING_DOC_DOWNLOAD', user);

				break;
			case 'INSURANCE':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_DOC_DOWNLOAD', user);

				break;

			case 'EXECUTION':
				this.isDownloadGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_DOC_DOWNLOAD', user);

				break;

			default:
				this.isDownloadGranted = true;
				break;
		}
	}

	public showDialog(isNew, type, entity, vehicle): void {

		// console.log("[DOC-FORM] showDialog ***");
		this.vehicle = vehicle;

		// console.log("type...");
		// console.log(type);
		// console.log("this.vehicle...");
		// console.log(this.vehicle);
		// console.log("this.driver...");
		// console.log(this.driver);
		// console.log("this.fine...");
		// console.log(this.fine);
		// console.log("this.tax...");
		// console.log(this.tax);
		// console.log("this.insurance...");
		// console.log(this.insurance);
		// console.log("this.financing...");
		// console.log(this.financing);
		// console.log("this.execution...");
		// console.log(this.execution);
		// console.log("entity...");
		// console.log(entity);

		this.isNew = isNew;
		this.type = type.toUpperCase();
		this.entity = <Rest.InvDocument>{};
		if (!this.isNew) {
			Object.assign(this.entity, entity);
		}
		this.errorMessages = [];
		this.driverPoints = null;
		this.note = null;
		// console.log("this.entity...");
		// console.log(this.entity);

		switch (this.type) {
			case "DRIVER":
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					this.title = this.driver.user?.surname1 + " " + this.driver.user?.surname2 + ",  " + this.driver.user?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeDriver);
				break;
			case "VEHICLE":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeVehicle);
				break;
			case "FINES":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					//this.vehicle = this.entity.vehicle;
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFines);
				break;
			case "TAXES":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					//this.vehicle = this.entity.vehicle;
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeTaxes);
				break;
			case "INSURANCE":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					//this.vehicle = this.entity.vehicle;
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeInsurance);
				break;
			case "FINANCING":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					//this.vehicle = this.entity.vehicle;
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeFinancing);
				break;
			case "EXECUTION":
				this.selectedVehiclePlate = this.vehicle.name;
				if (this.isNew) {
					this.translateService.instant("document.form.createDocument");
				} else {
					//this.vehicle = this.entity.vehicle;
					this.title = "(" + this.vehicle.name + ") " + this.vehicle.model?.brand?.name + " " + this.vehicle.model?.name + " " + this.vehicle.version?.name;
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentTypeExecution);
				break;
			default:
				if (this.isNew) {
					this.title = this.translateService.instant('documents.form.createDocument');
				} else {
					this.title = this.translateService.instant('documents.form.editDocument');
				}
				this.docTypeList = RestExt.getEnumItemsNoTranslation(RestExt.DocumentType);
				break;
		}
		this.isDialogVisible = true;

		/* camps extra segons tipus de document */
		this.onChangeType(null);
	}

	public changeDocumentLegacy(event: any) {

		this.documentFile = event.target.files[0];

		if (!this.entity.document) {
			this.entity.document = {
				name: "",
				imageEncoded: "",
				id: 0,
				entity: null,
				path: ""
			};
		}

		this.entity.document.name = this.documentFile.name;
	}

	public changeDocument(event: any): void {
		// Retrieve the file list from the event
		this.selectedFiles = event.target.files;

		// Map the FileList to an array of file names
		const fileNames = Array.from(this.selectedFiles).map(file => file.name);

		// Check if the document property is initialized; if not, initialize it
		if (!this.entity.document) {
			this.entity.document = {
				name: "",
				imageEncoded: "",
				id: 0,
				entity: null,
				path: ""
			};
		}

		// Update the document name with the names of all selected files, separated by commas
		this.entity.document.name = fileNames.join(", ");
	}

	public onChangeType(event) {
		// console.log("[DOC-FORM] onChangeType ***");
		// console.log(this.type);
		switch (this.type) {
			case "DRIVER":
				// console.log(this.entity);
				switch (this.entity.type) {
					case "DGT_POINTS":
						if (!this.isNew) {
							const that = this;
							this.driverPointsService
								.findDocument(this.entity.id.toString())
								.then(function (response) {
									if (response) that.driverPoints = response;
									else that.driverPoints = <Rest.InvDriverPoints>{};
									// console.log(that.driverPoints);
								})
								.catch((e) => {
									that.driverPoints = <Rest.InvDriverPoints>{};
								});
						} else {
							this.driverPoints = <Rest.InvDriverPoints>{};
							this.driverPoints.driver = this.driver;
							// console.log(this.driverPoints);
						}
						break;
					default:
				}
			case 'VEHICLE':
				// console.log(this.entity);
				switch (this.entity.type) {
					case "NOTE":
						// console.log(this.isNew);
						if (!this.isNew) {
							const that = this;
							this.noteService
								.findDocument(this.entity.id.toString())
								.then(function (response) {
									if (response) that.note = response;
									else that.note = <Rest.InvNote>{};
									// console.log(that.note);
								})
								.catch((e) => {
									that.note = <Rest.InvNote>{};
								});
						} else {
							this.note = <Rest.InvNote>{};
							this.note.vehicle = this.vehicle;
							// console.log(this.note);
						}
						break;
					default:
				}
				break;
			default:
		}
	}

	closeFormDialog(): void {
		this.isDialogVisible = false;
		this.refreshTable.emit();
	}

	protected beforeShow(): void {
		// if(this.isNew){
		//    this.title = this.translateService.instant("document.form.createDocument");
		// }else{
		//    this.title = this.translateService.instant("document.form.editDocument");
		// }
	}

	downloadFile() {
		// console.log("[DOC-FORM] downloadFile ***");
		// console.log(this.entity);
		if (!this.isDownloadGranted) {
			return;
		}

		var imageEncoded = this.entity.document.imageEncoded;
		var contentToReplace = imageEncoded.split(',')[0] + ',';
		const byteCharacters = atob(imageEncoded.replace(contentToReplace, ''));
		const mime = imageEncoded.split(':')[1].split(';')[0];
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mime });
		var date = this.datePipe.transform(
			this.entity.updateDate != null ? this.entity.updateDate : this.entity.createDate,
			'yyyyMMdd'
		);
		// save the blob using the FileSaver module
		saveAs(blob, date + '_' + this.entity.name);
	}

	public checkVehiclePlate(newPlate: string) {
		// console.log("[VEHICLE-FORM] checkVehiclePlate - " + newPlate);
		let arg0 = {
			name: newPlate
		}
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			return this.vehicleService
				.findVehicleByName(arg0)
				.then(
					function (res) {
						// console.log("==> PLATE VALIDATION... ");
						// console.log(res);
						if (res && res.length > 0) {
							that.entity.vehicle = res[0];
							that.saveDocument()
						} else {
							that.errorMessages.push(
								that.translateService.instant('control-panel.vehicles.wrongPlate'),
							);
							window.scroll({ top: 0, behavior: 'smooth' });
							this.isEditingDisabled = false;
						}

					},
					(err) => {
						this.isEditingDisabled = false;
						resolve();
					}
				)
				.catch((e) => {
					console.log('Catched: ' + e);
					this.isEditingDisabled = false;
					resolve();
				});
		});
	}

	public validateDocumentForm(): Boolean {
		// console.log("[DOC-FORM] validateDocumentForm *** ");
		// console.log(this.entity);
		// console.log(this.documentFile);
		if (!this.entity.name || this.entity.name === "" || !this.entity.document ||
			(this.entity.type === 'NOTE' && !this.docNote.validateNote()) ||
			(this.entity.type === 'DGT_POINTS' && !this.docDriverPoints.validateDriverPoints())) {
			this.errorMessages.push(
				this.translateService.instant('error.required-field.generic'),
			);
			window.scroll({ top: 0, behavior: 'smooth' });
			this.isEditingDisabled = false;
		}
		return this.errorMessages.length === 0;
	}

	public onSaveDocument() {
		this.errorMessages = [];
		this.isEditingDisabled = true;

		const uploadPromises = [];

		for (let i = 0; i < this.selectedFiles.length; i++) {
			this.documentFile = this.selectedFiles[i];
			this.entity.document.name = this.documentFile.name;

			if (i == 0 && (this.entity.name === "" || !this.entity.name)) {
				this.entity.name = this.documentFile.name.split('.')[0];
			} else if (i == 0 && (this.entity.name !== "" || this.entity.name)) {
				this.entity.name = this.entity.name;
			} else {
				this.entity.name = this.documentFile.name.split('.')[0];
			}

			if (this.type === 'FINES' && (this.selectedVehiclePlate || this.selectedVehiclePlate === "")) {
				const uploadPromise = this.selectedVehiclePlate || this.selectedVehiclePlate === ""
					? this.checkVehiclePlate(this.selectedVehiclePlate)
					: this.saveDocument();

				uploadPromises.push(uploadPromise);
			} else {
				const uploadPromise = this.saveDocument();
				uploadPromises.push(uploadPromise);
			}
		}

		Promise.all(uploadPromises).then(() => {
			console.log('All files uploaded');
		}).catch(error => {
			console.error('Error uploading files', error);
		});
	}

	/*******************************/
	/** CREATE ENTITIES FUNCIONS  **/
	/*******************************/
	public saveDocument() {
		// console.log("[DOC-FORM] saveDocument ***");
		// console.log("type...");
		// console.log(this.type);
		// console.log("this.vehicle...");
		// console.log(this.vehicle);
		// console.log("this.driver...");
		// console.log(this.driver);
		// console.log("this.fine...");
		// console.log(this.fine);
		// console.log("this.tax...");
		// console.log(this.tax);
		// console.log("this.insurance...");
		// console.log(this.insurance);
		// console.log("this.financing...");
		// console.log(this.financing);
		// console.log("this.execution...");
		// console.log(this.execution);

		if (this.validateDocumentForm()) {

			/** vehicle data */
			if (this.type === 'FINES') {
				this.vehicle = this.entity.vehicle;
			}

			let documentData = {
				id: this.isNew ? 0 : this.entity.id,
				name: this.entity.name,
				type: this.entity.type,
				document: this.documentFile ? null : { "id": this.entity.document.id },
				vehicle: this.vehicle ? { "id": this.vehicle.id } : null,
				driver: this.type === 'DRIVER' ? { "id": this.driver.id } : null,
				disabled: this.isNew ? false : this.entity.disabled,
			};
			let dataString = JSON.stringify(documentData);

			if (this.isNew) {
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.documentService
						.createDocument(dataString, this.documentFile)
						.then(
							function (response) {
								// console.log("==> create document... " + that.type);
								// console.log(response);
								that.document = response;
								if (that.type === "DRIVER" && that.entity.type === "DGT_POINTS") {
									that.docDriverPoints.create(that.document);
								}
								if (that.type === "VEHICLE" && that.entity.type === "NOTE") {
									that.docNote.create(that.document);
								}
								if (that.type === "FINES") {
									//add document to fine
									that.finesService.createDocument(that.fine.id, that.document.id);
								}
								if (that.type === "TAXES") {
									//add document to tax
									that.taxService.createDocument(that.tax.id, that.document.id);
								}
								if (that.type === "FINANCING") {
									//add document to financing
									that.financingService.createDocument(that.financing.id, that.document.id);
								}
								if (that.type === "INSURANCE") {
									//add document to insurance
									that.insuranceService.createDocument(that.insurance.id, that.document.id);
								}
								if (that.type === "EXECUTION") {
									//add document to insurance
									that.executionService.createDocument(that.execution.id, that.document.id);
								}
								//that.refreshTable.emit();
								// console.log("==> Send refresh " + that.type + " list: new document " + that.document.id);
								that.documentService.refreshList.next({ type: that.type, newDoc: that.document.id });
								that.isEditingDisabled = false;
								that.isDialogVisible = false;
							},
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							},
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});

			}
			else {
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.documentService
						.updateDocument(dataString, this.documentFile)
						.then(
							function (response) {
								// console.log("==> update document... " + that.type);
								// console.log(response);
								that.document = response;
								if (that.type === "DRIVER" && that.entity.type === "DGT_POINTS") {
									that.docDriverPoints.update();
								}
								if (that.type === "VEHICLE" && that.entity.type === "NOTE") {
									that.docNote.update();
								}
								if (that.type === "FINES") {
									that.finesService.createDocument(that.fine.id, that.document.id);
								}
								if (that.type === "TAX") {
									that.taxService.createDocument(that.tax.id, that.document.id);
								}
								if (that.type === "FINANCING") {
									that.financingService.createDocument(that.financing.id, that.document.id);
								}
								if (that.type === "INSURANCE") {
									that.insuranceService.createDocument(that.insurance.id, that.document.id);
								}
								if (that.type === "EXECUTION") {
									that.executionService.createDocument(that.execution.id, that.document.id);
								}
								//that.refreshTable.emit();
								// console.log("==> Send refresh " + that.type + " list: update document " + that.document.id);
								that.documentService.refreshList.next({ type: that.type, newDoc: that.document.id });
								that.isEditingDisabled = false;
								that.isDialogVisible = false;
							},
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							},
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});
			}

		}

	}

}


