import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { DashboardsService } from './services/dashboards.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css'],
  providers: [DashboardsService]
})
export class DashboardsComponent implements OnInit {

  dashboards: Rest.Dashboards[] = [];
  selectedUrl: SafeResourceUrl = "";

  dashboardType: string;

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardsService: DashboardsService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.dashboards = [];
    var that = this;
    this.dashboardsService.findByClientId(this.authenticationService.user.client.id).then((response) => {

      that.dashboards = response;
      //this will change in the future if we make that a client can have more than one different endpoint to show
      if (this.dashboards.length > 0) {
        this.selectedUrl = this.sanitizeUrl(this.dashboards[0].url);
      }

      this.dashboardType = that.dashboards[0].type;
    });

  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
