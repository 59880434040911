import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationsService, Severity } from '../services/notifications-service/notifications.service';
import Device = Rest.Device;

@Injectable()
export class DeviceService extends EntityService<any> {
    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient,
        private notificationsService: NotificationsService
    ) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(
        arg0: Rest.Device,
        queryParams?: { mode?: any }
    ): Rest.RestResponse<Rest.Device> {
        return this.client.Device_create(arg0, queryParams);
    }

    update(
        arg0: Rest.Device,
        queryParams?: { mode?: any }
    ): Rest.RestResponse<Rest.Device> {
        return this.client.Device_update(arg0, queryParams);
    }

    getPage(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.Page<Rest.Device>> {
        return this.client.Device_getPage(arg0);
    }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Device>> {
        return this.client.Device_getPageAndFilters(arg0);
    }

    getRequestsPage(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.Page<Rest.Request>> {
        return this.client.Device_getRequestsPage(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        return this.client.Device_changeStatus(arg0, queryParams);
    }

    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Device> {
        return this.client.Device_find(arg0, queryParams);
    }

    getCreditsDevice(deviceId: number): any {
        return this.client.Device_getCredits(deviceId);
    }

    saveCreditsDevice(arg0): any {
        return this.client.Device_saveCredits(arg0);
    }

    /**
     * getUserByDevice()
     * */
    public getDevicesByUser(userId: number): Promise<Device[]> {

        /* HEADERS */
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.jquery.securityToken,
        });

        /* PARAMS */
        const params = new HttpParams().set('userId', userId);

        return this.http
            .get(environment.webApiBaseUrl + 'api/device/list/byuser', { headers, params })
            .toPromise()
            .then((response: any) => {
                return response as Device[];
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return [];
            });
    }
}
