<div class='d-flex flex-column justify-content-center h-100 form-input'>

	<ng-template #content>
		<ng-content></ng-content>
	</ng-template>

	<!-- INPUTS alineats a la dreta (per defecte) -->
   <div *ngIf="!align" class='d-flex flex-row w-100 align-content-center justify-content-between'>
      <div class='d-flex flex-row align-content-center'>
      	<span style="font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em;" [hidden]='!fieldName'
            class='label d-flex align-content-center'
            [innerText]='fieldName'></span>
         <span [hidden]='!required' style="cursor:pointer; font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em"
            class='label d-flex align-content-center' pTooltip='{{toolTipRequired}}' tooltipPosition='bottom'
            [innerText]='asterisc'></span>
      </div>

      <div class='d-flex ps-3 align-content-center'>
         <!--<span [ngClass]="{'ui-g-7': fieldName, 'ui-g-12': !fieldName}">-->
         <!-- <ng-content></ng-content> -->
			<ng-container *ngTemplateOutlet="content"></ng-container>
      </div>

   </div>

	<!-- INPUTS alineats a l'esquerra -->
	<div *ngIf="align" class='d-flex flex-row w-100 align-content-center'>
		<div [class]="alignStyle">
      	<div class='d-flex flex-row align-content-center'>
	      	<span style="font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em;" [hidden]='!fieldName'
            	class='label d-flex align-content-center'
            	[innerText]='fieldName'></span>
         	<span [hidden]='!required' style="cursor:pointer; font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em"
	            class='label d-flex align-content-center' pTooltip='{{toolTipRequired}}' tooltipPosition='bottom'
            	[innerText]='asterisc'></span>
      	</div>
	      <div class='d-flex ps-3 align-content-center'>
				<ng-container *ngTemplateOutlet="content"></ng-container>
      	</div>
		</div>
   </div>

   <div class='row'>
      <div class='col'>
         <div *ngIf='errors && errors.length' class='ui-message ui-messages-error ui-corner-all' style='margin: 0;'>
            <ul class='errors'>
               <li *ngFor='let error of errors'>{{error}}</li>
            </ul>
         </div>
      </div>
   </div>

</div>
