<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">User Defined
                    Functions (AT + GTUDF)</span>
            </div>
        </div>
    </p-header>


    <div class="row mt-3">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Group ID">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="idOptions" [style]="{'width':'100%','min-width':'100px'}"
                    (onChange)="onUdfIdChange($event)"></p-dropdown>
            </div>
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Mode">
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="modeOptions" [(ngModel)]="udfParameters[selectedIndex].mode"
                        [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Debounce Time">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="udfParameters[selectedIndex].debounceTime" [min]="0"
                            [max]="86400"></p-spinner>
                        <span class="ui-inputgroup-addon">0~86400</span>
                        <span class="ui-inputgroup-addon">s</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Stocmd ACK">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="stoCmdOptions" [(ngModel)]="udfParameters[selectedIndex].modeStoCmd"
                    [style]="{'width':'100%','min-width':'100px'}">
                </p-dropdown>
            </div>
        </div>

        <div class="row mt-3">
            <form-input fieldName="Input id mask"></form-input>
            <div class="row mt-3">
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Power on finished"
                            [(ngModel)]="udfParameters[selectedIndex].powerOnFinished">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="The device is stationary"
                            [(ngModel)]="udfParameters[selectedIndex].deviceStationary"></p-checkbox>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Ignition on"
                            [(ngModel)]="udfParameters[selectedIndex].ignitionOn">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="The device is moving"
                            [(ngModel)]="udfParameters[selectedIndex].deviceMoving"></p-checkbox>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Ignition off"
                            [(ngModel)]="udfParameters[selectedIndex].ignitionOff">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="External charge inserted"
                            [(ngModel)]="udfParameters[selectedIndex].externalCharge"></p-checkbox>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="The PDP connection is attached"
                            [(ngModel)]="udfParameters[selectedIndex].pdpAttached">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="No external charger"
                            [(ngModel)]="udfParameters[selectedIndex].noExternalCharge"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="The PDP connection is not attached"
                            [(ngModel)]="udfParameters[selectedIndex].pdpNotAttached">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="The device is charging"
                            [(ngModel)]="udfParameters[selectedIndex].deviceCharging"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="The network is registered"
                            [(ngModel)]="udfParameters[selectedIndex].networkRegistered">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="The device is not charging"
                            [(ngModel)]="udfParameters[selectedIndex].deviceNotCharging"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="The network is not registered"
                            [(ngModel)]="udfParameters[selectedIndex].networkNonRegistered">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="External battery inserted"
                            [(ngModel)]="udfParameters[selectedIndex].externalBatteryInserted">
                        </p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Network roaming"
                            [(ngModel)]="udfParameters[selectedIndex].networkRoaming">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="No external battery"
                            [(ngModel)]="udfParameters[selectedIndex].noExternalBattery"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" label="Network non-roaming"
                            [(ngModel)]="udfParameters[selectedIndex].networkNonRoaming">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="Digital input 1 is low"
                            [(ngModel)]="udfParameters[selectedIndex].digitalInput1Low"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" binary="true" label="SIM card is locked"
                            [(ngModel)]="udfParameters[selectedIndex].simCardLocked">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" label="Digital input 1 is high"
                            [(ngModel)]="udfParameters[selectedIndex].digitalInput1High"></p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" binary="true" label="GPS is turned on"
                            [(ngModel)]="udfParameters[selectedIndex].gpsOn">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" binary="true" label="SIM card is inserted"
                            [(ngModel)]="udfParameters[selectedIndex].simCardInserted">
                        </p-checkbox>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <p-checkbox binary="true" binary="true" label="GPS is turned off"
                            [(ngModel)]="udfParameters[selectedIndex].gpsOff">
                        </p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox binary="true" binary="true" label="SIM card is not inserted"
                            [(ngModel)]="udfParameters[selectedIndex].simCardNotInserted">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <form-input fieldName="Inzizo mask"></form-input>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 0"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo0">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 7"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo7">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 14"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo14">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 1"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo1">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 8"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo8">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 15"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo15">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 2"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo2">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 9"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo9">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 16"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo16">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 3"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo3">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 10"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo10">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 17"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo17">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 4"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo4">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 11"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo11">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 18"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo18">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 5"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo5">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 12"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo12">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 19"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo19">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 3"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo6">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Inside the Geo 10"
                        [(ngModel)]="udfParameters[selectedIndex].insideGeo13">
                    </p-checkbox>
                </div>
                <div class="col"></div>
            </div>
        </div>
        <div class="row mt-3">
            <form-input fieldName="Outzizo mask"></form-input>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 0"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo0">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 7"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo7">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 14"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo14">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 1"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo1">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 8"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo8">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 15"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo15">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 2"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo2">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 9"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo9">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 16"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo16">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 3"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo3">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 10"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo10">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 17"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo17">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 4"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo4">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 11"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo11">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 18"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo18">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 5"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo5">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 12"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo12">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 19"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo19">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 3"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo6">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Outside the Geo 10"
                        [(ngModel)]="udfParameters[selectedIndex].outsideGeo13">
                    </p-checkbox>
                </div>
                <div class="col"></div>
            </div>
        </div>

        <div class="row mt-3">
            <form-input fieldName="Stocmd id mask"></form-input>

            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 0"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd0">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 11"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd11">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 22"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd22">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 1"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd1">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 12"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd12">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 23"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd23">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 2"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd2">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 13"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd13">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 24"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd24">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 3"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd3">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 14"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd14">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 25"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd25">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 4"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd4">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 15"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd15">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 26"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd26">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 5"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd5">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 16"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd16">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 27"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd27">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 6"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd6">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 17"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd17">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 28"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd28">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 7"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd7">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 18"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd18">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 29"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd29">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 8"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd8">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 19"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd19">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 30"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd30">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 9"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd9">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 20"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd20">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 31"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd31">
                    </p-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 10"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd10">
                    </p-checkbox>
                </div>
                <div class="col">
                    <p-checkbox binary="true" label="Stocmd ID mask 21"
                        [(ngModel)]="udfParameters[selectedIndex].stocmd21">
                    </p-checkbox>
                </div>
                <div class="col"></div>
            </div>
        </div>

    </div>
</p-accordionTab>