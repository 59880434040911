import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PanelModule } from "primeng/panel";

import { CercaliaMapService } from "./../../services/cercalia-map/cercalia-map.service";
import { CustomMapComponent } from "./custom-map/custom-map.component";

@NgModule({
    imports: [CommonModule, ButtonModule, PanelModule, ConfirmDialogModule],
    declarations: [CustomMapComponent],
    exports: [CustomMapComponent],
    providers: [CercaliaMapService, ConfirmationService],
})
export class OldSharedModule {
}
