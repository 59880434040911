import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class VehiclesgroupService  extends EntityService<Rest.VehiclesGroup>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.VehiclesGroup): Rest.RestResponse<Rest.VehiclesGroup> {
return this.client.VehiclesGroup_create(arg0);
}

update(arg0: Rest.VehiclesGroup): Rest.RestResponse<Rest.VehiclesGroup> {
return this.client.VehiclesGroup_update(arg0);
}

findGroup(group: string, queryParams?: { group?: number; }): Rest.RestResponse<Rest.VehiclesGroup> {
return this.client.VehiclesGroup_findGroup(group, queryParams);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.VehiclesGroup>> {
return this.client.VehiclesGroup_getPage(arg0);
}

changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.VehiclesGroup_changeStatus(arg0, queryParams);
}

getTreeGroups(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.VehiclesGroup>>{
  return this.client.VehiclesGroup_getTreeGroups(arg0);
}

findByFleet(fleetId: number): Rest.RestResponse<Rest.VehiclesGroup[]>{
  return this.client.VehiclesGroup_findByFleet(fleetId);
}

/* for inventory */
getByFleet(fleetId: number): Rest.RestResponse<Rest.VehiclesGroup[]>{
	return this.client.VehiclesGroup_getByFleet(fleetId);
 }

}
