import { Component, OnInit, ViewEncapsulation, ViewChild, SimpleChanges } from '@angular/core';
import { EntityListComponent } from '../../components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { ConfirmationService, LazyLoadEvent, SelectItem, FilterMetadata } from 'primeng/api'
import { UserTimePipe } from '../../components/shared/UserTimePipe';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { RestExt } from 'app/services/rest-client-extension';
import { DatePipe } from '@angular/common';
import { TacoService } from './services/taco.service';
import { ReportFilesService } from '../tax/services/report-files.service';
import { saveAs } from 'file-saver';
import { async } from 'rxjs';

@Component({
    selector: 'app-taco',
    templateUrl: './taco.component.html',
    styleUrls: ['./taco.component.css'],
    providers: [TacoService, ReportFilesService]
})
export class TacoComponent extends EntityListComponent<Rest.Tacograph> implements OnInit {
    startDate: Date;
    endDate: Date;
    entityRequestContext: Rest.ListPaginationRequest;

    startDateTxt: string;
    endDateTxt: string;
    today: Date = new Date();
    isHtcLoaded: Boolean = true;

    typeList: string[] = [];
    filterTypes: string[] = [];

    selectedHistoricOptionsList;
    historicOptionsList: any[] = [
        { "id": 0, "value": this.translate.instant('general.today') },
        { "id": 1, "value": this.translate.instant('tableManagement.vehicleHtc.thisMonth') },
        { "id": 2, "value": this.translate.instant('tableManagement.vehicleHtc.pastMonth') },
        { "id": 3, "value": this.translate.instant('tableManagement.vehicleHtc.thisYear') },
        { "id": 4, "value": this.translate.instant('tableManagement.vehicleHtc.pastYear') },
        { "id": 5, "value": this.translate.instant('tableManagement.vehicleHtc.lastFiveYears') }, //default
        { "id": 6, "value": this.translate.instant('tableManagement.vehicleHtc.custom') }
    ];

    constructor(
        public tacoService: TacoService,
        protected reportFilesService: ReportFilesService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        public i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        private translate: TranslateService,
        private datePipe: DatePipe
    ) {
        super(
            tacoService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.getTypes();
        this.entityRequestContext = RestExt.firstPageRequest();
        this.selectedHistoricOptionsList = this.historicOptionsList[1];
    }
    
    beforeLoad() {
        this.onChangePeriod();
        this.search();
    }

    getTypes() {
        this.typeList = [
            'DRIVER', 'VEHICLE'
        ];
    }

    onTypeFilterChange(event) {
        var typeFilter = event as string[];
        if (typeFilter.length == 0) {
            delete this.paginationRequest.filters['type'];
        } else {
            this.paginationRequest.filters['type'] = event;
        }

        this.refresh();
    }

    getDocumentType(data) {
        var colonIndex = data.indexOf(":");
        var semicolonIndex = data.indexOf(";");
        var type = data.substring(colonIndex + 1, semicolonIndex);
        return type;
    }

    getDocumentData(data): Uint8Array {
        var dataToReplace = "data:" + this.getDocumentType(data) + ";base64,";
        const byteCharacters = atob(data.replace(dataToReplace, ""));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }

    async downloadTaco(taco: Rest.Tacograph) {
        var rawData = await this.tacoService.download([taco.id]);
        const blob = new Blob([this.getDocumentData(rawData)]);
        saveAs(blob, taco.file.name);
    }

    async downloadSelected() {
        const arrayIds = this.selectedEntities.map(obj => obj.id);
        var rawData = await this.tacoService.download(arrayIds);
        const blob = new Blob([this.getDocumentData(rawData)], { type: 'application/zip' });
        const date = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        saveAs(blob, this.translate.instant('header.taco') + "_" + date);
    }

    search() {
        this.paginationRequest.filters['startDownloadDate'] = [this.userTime.toUTCISOString(this.startDate)];
        this.paginationRequest.filters['endDownloadDate'] = [this.userTime.toUTCISOString(this.endDate)];

        this.selectedEntities = [];

        if (!this.startDate || !this.endDate) {
            this.notificationsService.add(Severity.warn, this.translate.instant('general.warn'), this.translate.instant('tax.error.search'));
            return;
        }

        // if (this.endDate >= this.startDate) {
        //     this.notificationsService.add(Severity.warn, this.translate.instant('general.warn'), this.translate.instant('tax.error.dates'));
        //     return;
        // }

        this.tacoService.getPage(this.paginationRequest).then((pageResult) => {
            this.paginationResult = pageResult;
        });
    }

    public onStartDateChange() {
        this.selectedHistoricOptionsList = this.historicOptionsList[6];
    }

    public onEndDateChange() {
        this.selectedHistoricOptionsList = this.historicOptionsList[6];
    }

    public onChangePeriod(event?) {
        switch (this.selectedHistoricOptionsList.id) {
            case 0: this.onToday(); break;
            case 1: this.onThisMonth(); break;
            case 2: this.onPastMonth(); break;
            case 3: this.onThisYear(); break;
            case 4: this.onPastYear(); break;
            case 5: this.onLast5Years(); break;
            default: this.onCustom(); break;
        }
        this.today = new Date();
    }

    public onToday() {
        let now = new Date();
        this.startDate = now;
        this.endDate = now;
    }

    public onThisMonth() {
        let now = new Date();
        this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.endDate = now;
    }

    public onPastMonth() {
        let now = new Date();
        let newYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
        let newMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
        this.startDate = new Date(newYear, newMonth, 1);
        this.endDate = new Date(newYear, newMonth + 1, 0);
    }

    public onThisYear() {
        let now = new Date();
        this.startDate = new Date(now.getFullYear(), 0, 1);
        this.endDate = new Date();
    }

    public onPastYear() {
        let now = new Date();
        this.startDate = new Date(now.getFullYear() - 1, 0, 1);
        this.endDate = new Date(now.getFullYear(), 0, 0);
    }

    public onLast5Years() {
        let now = new Date();
        this.startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    public onCustom() {
        this.notificationsService.add(
            Severity.success,
            this.translate.instant('general.generalInformation') + "!",
            this.translate.instant('tableManagement.vehicleHtc.selectDates'));
    }
}
