import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { FleetService } from "../../../rest/fleet.service";
import { IconService } from "../../../rest/icon.service";
import { Rest } from "../../../rest/rest_client";
import { VehiclesgroupService } from "../../../rest/vehiclesgroup.service";
import {
  NotificationsService,
  Severity,
} from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-group-form",
  templateUrl: "./group-form.component.html",
  styleUrls: ["./group-form.component.css"],
  providers: [
    VehiclesgroupService,
    FleetService,
    IconService,
    ConfirmationService,
  ],
})
export class GroupFormComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  isNew: Boolean;
  showChangeStatus: Boolean;
  availableIcons: Rest.Icon[];
  group: Rest.VehiclesGroup;
  groupName: string;
  fleetRequestContext: Rest.ListPaginationRequest;

  display: Boolean;

  groupService: VehiclesgroupService;
  confirmationService: ConfirmationService;
  notificationsService: NotificationsService;

  /**#ChangeStatus */
  showStatusDialog: boolean = false;
  selectedEntities: Rest.VehiclesGroup[] = [];
  title: string;
  /*************** */

  /**Permissions */
  permissions: any;
  /** */

  // eslint-disable-next-line
  @Output("newGroup")
  // eslint-disable-next-line
  change: EventEmitter<Rest.VehiclesGroup> = new EventEmitter<Rest.VehiclesGroup>();

  // eslint-disable-next-line
  @Output("refreshList")
  refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output() return = new EventEmitter();
  @Output() selectNode = new EventEmitter();

  @Input() checkBoxDisabled: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    groupService: VehiclesgroupService,
    protected i18n: I18nService,
    public fleetService: FleetService,
    private iconService: IconService,
    confirmationService: ConfirmationService,
    notificationsService: NotificationsService
  ) {
    super(i18n);
    this.groupService = groupService;
    this.confirmationService = confirmationService;
    this.notificationsService = notificationsService;
  }

  ngOnInit() {
    var that = this;
    this.iconService.getEntityIcons("VEHICLE_GROUP").then(
      //this.iconService.getEntityIcons("POI_CATEGORY").then(
      function (response) {
        that.availableIcons = response;
      }
    );
    this.group = <Rest.VehiclesGroup>{ metadata: {} };

    /**#ChangeStatus */
    this.title = this.i18n._("Vehicle Groups");
    /************** */
  }

  show(isNew: boolean, entity: any, permissions: any) {
    this.clearMessages();
    this.isNew = isNew;
    this.permissions = permissions;
    this.fleetRequestContext = RestExt.firstPageRequest();

    //If it's new "entity" parameter will contain the fleet where the group is being created
    if (isNew) {
      this.group = <Rest.VehiclesGroup>{
        metadata: {},
        fleet: entity,
      };
      this.showChangeStatus = false;
      this.display = true;
    } else {
      //if is not new, "entity" paramter will contain the group being edited
      var that = this;
      this.groupService
        .findGroup(entity.id.toString())
        .then(function (res) {
          that.group = res;
          that.groupName = that.group.name;
          that.showChangeStatus = true;
          that.display = true;
          // RICARD: Removed becuase was poping on every click in the node tree
          //that.notificationsService.add(Severity.success, that.i18n._(':)'), that.i18n._('Entities updated'));
        })
        .catch((e) => {
          this.handleError(e, that);
        });
    }
  }

  refresh() {
    this.display = false;
    this.refreshList.emit(true);
  }

  saveGroup() {
    this.clearMessages();
    var that: GroupFormComponent = this;
    that = this;

    /*if (this.group.icon == null) {
      if (this.availableIcons.length > 0) {
        this.group.icon = this.availableIcons[0].value;
      }
    }*/
    if (this.isNew) {
      this.groupService
        .create(this.group)
        .then(function (res) {
          that.change.emit(res);
          that.display = false;
          that.notificationsService.add(
            Severity.success,
            that.i18n._(":)"),
            that.i18n._("Entities updated")
          );
        })
        .catch((e) => {
          this.handleError(e, that);
        });
    } else {
      this.groupService
        .update(this.group)
        .then(function (res) {
          that.change.emit();
          that.display = false;
          that.notificationsService.add(
            Severity.success,
            that.i18n._(":)"),
            that.i18n._("Entities updated")
          );
        })
        .catch((e) => {
          this.handleError(e, that);
        });
    }
  }

  /** #ChangeStatus */
  changeStatus() {
    for (var i = this.selectedEntities.length - 1; i >= 0; i--) {
      this.selectedEntities.splice(i, 1);
    }
    this.selectedEntities.push(this.group);
    this.showStatusDialog = true;
  }

  refreshForm(event) {
    this.selectedEntities = [];
    this.closeStatusDialog(false);
    this.return.emit(true);
    this.selectNode.emit();
    var that = this;
    this.groupService
      .findGroup(this.group.id.toString())
      .then(function (res) {
        that.show(false, res, that.permissions);
      })
      .catch((e) => {
        this.handleError(e, that);
      });
  }

  closeStatusDialog(event) {
    var close: boolean;
    close = event;
    this.showStatusDialog = close;
  }
  /***************** */
}
