import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-sos-form-gv800',
  templateUrl: './sos-form-gv800.component.html',
  styleUrls: ['./sos-form-gv800.component.css'],
})
export class SosFormGv800Component extends ServerValidatedFormComponent implements OnInit {

  @Input() sosParameters: Rest.SOSParametersGV800;
  m:Rest.ModeSos = "DISABLE";
  
  modeOptions: any = [
    {label: "Disable SOS function",value:0},
    {label: "SOS call only",value:1},
    {label: "Send the current position to the backend server only.",value:2},  
    {label: "Send the current position to the backend server first and then make SOS call(s)", value:3},
    {label: "Send the current position to the SMS gateway via SMS and make SOS call(s)", value: 4},         
  ];
 
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.sosParameters);
    }
  }

  onChange() {
    if (3 < this.sosParameters.digitalInputID && this.sosParameters.digitalInputID < 9) {
      if (this.sosParameters.digitalInputID === 4) {
        this.sosParameters.digitalInputID = 9;
      } else {
        this.sosParameters.digitalInputID = 3;
      }
    }
  }

  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }

}
