import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-jamming-behavior-setting-gmt200-form',
  templateUrl: './jamming-behavior-setting-gmt200-form.component.html',
  styleUrls: ['./jamming-behavior-setting-gmt200-form.component.css'],
})

export class JammingBehaviorSettingGmt200FormComponent extends ServerValidatedFormComponent implements OnInit {
  @Input() jbsParameters: Rest.JammingBehaviorSettingGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

}
