import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-sensor-set-configuration-we-track2',
  templateUrl: './sensor-set-configuration-we-track2.component.html',
  styleUrls: ['./sensor-set-configuration-we-track2.component.css'],
})
export class SensorSetConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit, OnChanges {

  @Input() sensorSet: Rest.SensorSetConfigurationWeTrack2;

  constructor(protected i18n: I18nService) {  super(i18n);}

 
  ngOnInit() {    
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.sensorSet);
    }
  }

}
