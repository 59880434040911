<!-- NEW DIALOG -->
<p-dialog
	*ngIf="isDialogVisible"
	header="{{ panelTitle }}"
	[(visible)]="isDialogVisible"
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	[closeOnEscape]="false"
	[style]="{ width: '80vw' }"
>
	<!-- FORM ERRORS -->
	<div #errorContainer>
		<div *ngIf="errorMessages.length !== 0">
			<div *ngFor="let error of errorMessages">
				<div class="alert alert-warning" role="alert">{{ error }}</div>
			</div>
		</div>
	</div>

	<!-- PANELL DADES DE VEHICLE -->
	<!-- <div class='d-flex flex-column gap-3 p-3'> -->

	<!-- <p-panel header="{{ panelTitle }}" class='w-100'> -->

	<div class="d-flex row-cols-2 w-100">
		<!--====== LEFT SIDE ========-->
		<div class="d-flex">
			<div class="d-flex flex-column mb-3">
				<div class="p-2 align-items-center">
					<!-- extra Type -->
					<form-input [required]="true" [align]="'cols-2'" fieldName="{{ 'general.type' | translate }}">
						<p-dropdown
							[options]="extrasTypeList"
							[(ngModel)]="vehicleAccessory.extrasType"
							placeholder="{{ 'general.select' | translate }}"
							dataKey="id"
							optionLabel="fullName"
							[style]="{ width: '250px', 'margin-right': '5px' }"
							appendTo="body"
							filter="true"
							filterBy="fullName"
							(onChange)="onExtrasTypeChange($event)"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						>
						</p-dropdown>
					</form-input>
				</div>

				<div class="p-2 align-items-center">
					<!-- description -->
					<form-input [required]="true" [align]="'cols-2'" fieldName="{{ 'general.description' | translate }}">
						<input
							pInputText
							[readonly]="false"
							[(ngModel)]="vehicleAccessory.description"
							[style]="{ width: '250px' }"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						/>
					</form-input>
				</div>

				<div
					*ngIf="
						vehicleAccessory.extrasType &&
						(vehicleAccessory.extrasType.code === 'EM' || vehicleAccessory.extrasType.code === 'AL')
					"
					class="p-2 align-items-center"
				>
					<!-- alarmType (si es alarma, code AL) -->
					<form-input
						*ngIf="vehicleAccessory.extrasType.code === 'AL'"
						[required]="true"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.alarmType' | translate }}"
					>
						<p-dropdown
							[options]="alarmTypeList"
							[(ngModel)]="vehicleAccessory.alarmType"
							placeholder="{{ 'tableManagement.accessories.alarmType' | translate }}"
							dataKey="id"
							optionLabel="name"
							[style]="{ width: '250px' }"
							appendTo="body"
							[filter]="true"
							filterBy="name"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						>
						</p-dropdown>
					</form-input>
					<!-- stationCode (si es emisora, code 10) -->
					<form-input
						*ngIf="vehicleAccessory.extrasType.code === 'EM'"
						[required]="false"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.stationCode' | translate }}"
					>
						<input
							pInputText
							[readonly]="false"
							[(ngModel)]="vehicleAccessory.stationCode"
							[style]="{ width: '250px' }"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						/>
					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- installation date -->
					<form-input
						[required]="true"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.installationDate' | translate }}"
					>
						<p-calendar
							[(ngModel)]="vehicleAccessory.installationDate"
							[readonlyInput]="true"
							[showTime]="false"
							[style]="{ width: '250px' }"
							dateFormat="{{ i18n.dateFormat }}"
							showIcon="true"
							appendTo="body"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						></p-calendar>
					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- desinstallation date -->
					<form-input
						[required]="false"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.desinstallationDate' | translate }}"
					>
						<p-calendar
							[(ngModel)]="vehicleAccessory.desinstallationDate"
							[readonlyInput]="true"
							[showTime]="false"
							[style]="{ width: '250px' }"
							dateFormat="{{ i18n.dateFormat }}"
							showIcon="true"
							appendTo="body"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						></p-calendar>
					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- value -->
					<form-input [required]="false" [align]="'cols-2'" fieldName="{{ 'general.value' | translate }}">
						<p-inputNumber
							[(ngModel)]="vehicleAccessory.value"
							mode="decimal"
							[min]="0"
							[minFractionDigits]="2"
							[showButtons]="true"
							[style]="{ width: '250px' }"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						></p-inputNumber>
					</form-input>
				</div>
			</div>
		</div>

		<!--====== RIGHT SIDE ========-->
		<div class="d-flex ps-3">
			<div class="d-flex flex-column mb-3">
				<div class="p-2 align-items-center">
					<!-- serial Number -->
					<form-input
						[required]="false"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.serialNumber' | translate }}"
					>
						<input
							pInputText
							[readonly]="false"
							[(ngModel)]="vehicleAccessory.serialNumber"
							[style]="{ width: '250px' }"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						/>
					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- disabled -->
					<form-input [required]="false" [align]="'cols-2'" fieldName="{{ 'general.disabled' | translate }}">
						<p-inputSwitch #toggle [(ngModel)]="vehicleAccessory.disabled" [disabled]="isEditingDisabled || (!isNew && !updateGranted)"> </p-inputSwitch>
					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- verification / verificationDate -->
					<form-input
						[required]="false"
						[align]="'cols-2'"
						fieldName="{{ 'tableManagement.accessories.verified' | translate }}"
					>
						<p-inputSwitch
							#toggle
							[(ngModel)]="vehicleAccessory.verified"
							(onChange)="toggleVerified($event)"
							[style]="{ 'margin-right': '10px' }"
							[disabled]="isEditingDisabled || (!allFieldsGranted && !verifiedGranted)"
						>
						</p-inputSwitch>
						
						<p-calendar [ngModel]="vehicleAccessory.verificationDate" [showTime]='true'
						(ngModelChange)="vehicleAccessory.verificationDate = $event"
                    showIcon='true' appendTo='body' [firstDayOfWeek]="1"
                    appendTo='body'></p-calendar>

					</form-input>
				</div>
				<div class="p-2 align-items-center">
					<!-- observations -->
					<form-input [required]="false" [align]="'cols-2'" fieldName="{{ 'general.observations' | translate }}">
						<textarea
							pInputTextarea
							id="observations"
							[(ngModel)]="vehicleAccessory.observations"
							[rows]="5"
							[style]="{ width: '550px' }"
							[disabled]="isEditingDisabled || (!isNew && !updateGranted)"
						>
						</textarea>
					</form-input>
				</div>
			</div>
		</div>
	</div>
	<!-- </p-panel> -->
	<!-- </div> -->

	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class="d-flex flex-row-reverse bd-highlight">
			<div class="p-2 bd-highlight">
				<button
					type="button"
					class="btn custom-button"
					(click)="onSaveVehicleAccessory()"
					[disabled]="isEditingDisabled || !allFieldsGranted"
				>
					{{ isEditingDisabled ? ('general.saving' | translate) : ('general.save' | translate) }}
				</button>
			</div>
		</div>
	</p-footer>
</p-dialog>
