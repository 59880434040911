import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../rest/JQueryHTTPClient";

import { EntityService } from "../rest/entity.service";
import { Rest } from "../rest/rest_client";

@Injectable()
export class LeaderService extends EntityService<Rest.InvLeader> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.InvLeader): Rest.RestResponse<Rest.InvLeader> {
    return this.client.Leader_create(arg0);
  }

  update(arg0: Rest.InvLeader): Rest.RestResponse<Rest.InvLeader> {
    return this.client.Leader_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.InvLeader>> {
    return this.client.Leader_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.InvLeader>> {
    return this.client.Leader_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.Leader_changeStatus(arg0, queryParams);
  }

   find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.InvLeader> {
    return this.client.Leader_find(arg0, queryParams);
  }

  getList(arg0: number): Rest.RestResponse<Rest.InvLeader[]> {
	return this.client.Leader_getList(arg0);
 }

}
