<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Speed Alarm (AT + GTSPD)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Speed Alarm Mode" >
            <p-dropdown  (onChange)="trackEventModeChange(speedAlarmParameters.mode,$event)" [options]="modeOptions"
            [(ngModel)]="speedAlarmParameters.mode" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>
        
        <div [class.disableDIV]=!trackModeSwitch>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Min speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedAlarmParameters.minSpeed" [min]="0" [max]="400"></p-spinner>
              <span class="ui-inputgroup-addon">0~400</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Max speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedAlarmParameters.maxSpeed" [min]="0" [max]="400"></p-spinner>
              <span class="ui-inputgroup-addon">0~400</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Validity" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedAlarmParameters.validity" [min]="0" [max]="3600"></p-spinner>
              <span class="ui-inputgroup-addon">0~3600</span>
              <span class="ui-inputgroup-addon" >s</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Send Interval" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedAlarmParameters.sendInterval" [min]="30" [max]="3600"></p-spinner>
              <span class="ui-inputgroup-addon">30~3600</span>
              <span class="ui-inputgroup-addon" >s</span>
            </div>
          </form-input>
        </div>
      </div>
      </div>
</p-accordionTab>

