<!----------------------->
<!-- FULLSCREEN NAVBAR -->
<!----------------------->
<nav class="navbar navbar-expand-lg mt-3 mb-4">
	<div class="container">
		<div class="collapse navbar-collapse align-items-center justify-content-center" id="navbarSupportedContent">
			<ul class="navbar-nav">

				<!-- CARDS -->
				<li class="nav-item custom-table-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="cards"
						[routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/star.svg" id='clientsIcon'
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.brands.brands' | translate}}</div>
					</a>
				</li>

				<!-- CEPSA -->
				<li class="nav-item custom-table-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="cepsa"
						[routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/speed.svg" id='clientsIcon'
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.models.models' | translate}}</div>
					</a>
				</li>

				<!-- INSURANCE -->
				<li class="nav-item custom-table-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="insurances"
						[routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/speed.svg" id='clientsIcon'
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.models.models' | translate}}</div>
					</a>
				</li>

			</ul>
		</div>
	</div>
</nav>

<!------------------->
<!-- MOBILE NAVBAR -->
<!------------------->
<div class="dropdown dropend d-sm-block d-lg-none">

	<a id="mobileMenuDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"
		aria-expanded="false">
		<svg-icon src="assets/icons/three-bars.svg" [svgStyle]="{ 'height.px': 50, 'width.px': 50 }"></svg-icon>
	</a>

	<div class="dropdown-menu animate slideIn border-0 p-0 mx-5" aria-labelledby="mobileMenuDropdown"
		style="min-width: 250px;">
		<div class="list-group custom-mobile-menu-list-group">

			<!-- DEVICES -->
			<div *ngIf="manageDevices" class="list-group-item border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex align-items-center justify-content-start p-0"
					[ngClass]="{'nav-link-active': devices.isActive || deviceTypes.isActive || conversionAlgorithms.isActive}"
					role="button" href="#devicesCollapse" data-bs-toggle="collapse" aria-controls="devicesCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/memory-chip.svg"
						class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.devices' | translate}}</div>
				</a>
			</div>

			<!-- DEVICES SUB ITEMS -->
			<div class="list-group collapse" id="devicesCollapse">

				<!-- DEVICES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #devices="routerLinkActive" routerLink="devices"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.devices' | translate}}</div>
					</a>
				</div>

				<!-- DEVICE TYPES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #deviceTypes="routerLinkActive" routerLink="deviceTypes"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.deviceTypes' | translate}}</div>
					</a>
				</div>

				<!-- CONVERSION ALGORITHM -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #conversionAlgorithms="routerLinkActive"
						routerLink="conversionAlgorithms" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.conversionAlgorithms' | translate}}</div>
					</a>
				</div>
			</div>

			<!-- VEHICLES -->
			<div *ngIf="manageVehicles || manageMaintenancePlans"
				class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': vehicles.isActive || maintenancePlans.isActive, 'disabled': !manageVehicles && !manageMaintenancePlans}"
					role="button" href="#vehiclesCollapse" data-bs-toggle="collapse" aria-controls="vehiclesCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/vehicle.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.vehicles' | translate}}</div>
				</a>
			</div>

			<!-- VEHICLES SUB ITEMS -->
			<div class="list-group collapse" id="vehiclesCollapse">

				<!-- VEHICLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageVehicles}" routerLink="fleets"
						[routerLinkActive]="['nav-link-active']" #vehicles="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.vehicles' | translate}}</div>
					</a>
				</div>

				<!--MAINTENANCE PLANS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageMaintenancePlans}" routerLink="maintenancePlans"
						[routerLinkActive]="['nav-link-active']" #maintenancePlans="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.maintenancePlans' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- ZONES -->
			<div *ngIf="manageZones" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="zoneGroups"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/grid.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.zones' | translate}}</div>
				</a>
			</div>

			<!-- PDIs -->
			<div *ngIf="managePOI" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="poiCategory"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/gps.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.pdi' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS -->
			<div *ngIf="manageNotificationGroups || manageRules"
				class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': notificationGroups.isActive || rules.isActive}" role="button"
					href="#notificationsCollapse" data-bs-toggle="collapse" aria-controls="notificationsCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/bell.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.notifications' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS SUB ITEMS -->
			<div class="list-group collapse" id="notificationsCollapse">

				<!-- NOTIFICATIONS GROUPS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageNotificationGroups}" routerLink="noticeGroups"
						[routerLinkActive]="['nav-link-active']" #notificationGroups="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.notificationGroups' | translate}}</div>
					</a>
				</div>

				<!-- RULES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageRules}" routerLink="rule" [routerLinkActive]="['nav-link-active']"
						#rules="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.rules' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- CLIENTS -->
			<div *ngIf="manageClients" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="clients"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/star.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.clients' | translate}}</div>
				</a>
			</div>

			<!-- USERS -->
			<div *ngIf="manageUsers" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': users.isActive || roles.isActive || profiles.isActive}" role="button"
					href="#usersCollapse" data-bs-toggle="collapse" aria-controls="usersCollapse" aria-expanded="false">
					<svg-icon src="assets/icons/user.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.users' | translate}}</div>
				</a>
			</div>

			<!-- USERS SUB ITEMS -->
			<div class="list-group collapse" id="usersCollapse">

				<!-- USERS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="users" [routerLinkActive]="['nav-link-active']" #users="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.users' | translate}}</div>
					</a>
				</div>

				<!-- ROLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="roles" [routerLinkActive]="['nav-link-active']" #roles="routerLinkActive"
						class="nav-link border-bottom">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.roles' | translate}}</div>
					</a>
				</div>

				<!-- PROFILES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="profiles" [routerLinkActive]="['nav-link-active']" #profiles="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.profiles' | translate}}</div>
					</a>
				</div>

			</div>

		</div>
	</div>

</div>
