import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';
import { RentingTypeService } from 'app/modules/vehicle-inventory/services/table-management/rentingType.service';
import { RentingTypeFormComponent } from '../form/rentingType-form.component';

import RentingType = Rest.RentingType;

@Component({
    selector: 'app-rentingType-list',
    templateUrl: './rentingType-list.component.html',
    styleUrls: ['./rentingType-list.component.scss'],
    providers: [RentingTypeService, ConfirmationService, UserTimePipe],
})
export class RentingTypeListComponent
    extends EntityListComponent<RentingType>
    implements OnInit {

    @ViewChild(RentingTypeFormComponent, { static: true })
    rentingTypeForm: RentingTypeFormComponent;

    title: string = this.i18n._("RentingType");
    disableSave: boolean;
    EnabledFilter: SelectItem[];

    constructor(
        private rentingTypeService: RentingTypeService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            rentingTypeService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.rentingTypeService = rentingTypeService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.rentingTypeForm;
    }

    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }
    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );
        this.paginationResult.entities.forEach((rt: any) => {
            /*Permissions */
            rt._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
            rt._$update = true;
            /** */
        });

        /*Permissions */
        this.checkSelectAll();
        /** */
    }
}

