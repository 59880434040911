import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';

@Injectable()
export class DriverLicenseService extends EntityService<Rest.InvDriverLicense> {
    

    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.InvDriverLicense): Rest.RestResponse<Rest.InvDriverLicense> {
        return this.client.DriverLicense_create(arg0);
    }
    
    upload(arg0: Rest.InvDriverLicense[]): Rest.RestResponse<Rest.InvDriverLicense[]> {
        return this.client.DriverLicense_upload(arg0);
    }

    update(arg0: Rest.InvDriverLicense): Rest.RestResponse<Rest.InvDriverLicense> {
        return this.client.DriverLicense_update(arg0);
    }
    
    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvDriverLicense>> {
        return this.client.DriverLicense_getPage(arg0);
    }
    
    changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
        return this.client.Driver_changeStatus(arg0, queryParams);
    }

    find(arg0: string,queryParams?: { id?: number }): Rest.RestResponse<Rest.InvDriverLicense> {
        return this.client.DriverLicense_find(arg0, queryParams);
    }
}