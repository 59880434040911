<div class="container-fluid p-0">

  <!-- NUMERICAL - CATEGORICAL VALUE SWITCH -->
  <div>
    <button
      class="value-button value-button-left"
      [ngClass]="{'value-button-selected': !_settings.showCategoricalValue}"
      [(ngModel)]="_settings.showCategoricalValue"
      (click)="switchNumeric()">
      {{'real-time.settingsModal.numeric' | translate}}
    </button>
    <button
      class="value-button value-button-right"
      [ngClass]="{'value-button-selected': _settings.showCategoricalValue}"
      [(ngModel)]="_settings.showCategoricalValue"
      (click)="switchCategorical()">
      {{'real-time.settingsModal.categorical' | translate}}
    </button>
  </div>

  <br>
  <br>
 
  <!-- SOURCE TYPE -->
  <div class="row">
    <div class="col-4">
      <label class="form-label custom-label mb-0 me-2" i18n>{{'general.source' | translate}}</label>
    </div>
    <div class="col">
      <p-dropdown
        class="custom-input"
        placeholder="Select source"
        [options]="availableModels"
        [(ngModel)]="_settings.filterType"
        (onChange)="updateCategoricalTags()"
        [style]="{'width':'250px'}"></p-dropdown>
    </div>
  </div>

  <br>

  <!-- SOURCE -->
  <div class="row">
    <div class="col-4">
      <label *ngIf="_settings.filterType === 'ALL'"
        class="form-label custom-label mb-0 me-2" i18n>{{'general.source' | translate}}</label>
      <label *ngIf="_settings.filterType === 'SENSOR'"
        class="form-label custom-label mb-0 me-2" i18n>{{'real-time.settingsModal.sensorTag' | translate}}</label>
      <label *ngIf="_settings.filterType === 'ACTUATOR'"
        class="form-label custom-label mb-0 me-2" i18n>{{'real-time.settingsModal.actuatorTag' | translate}}</label>
    </div>
    <div class="col">
      <p-dropdown *ngIf="_settings.filterType === 'ALL'"
        class="custom-input"
        [options]="availableTags"
        [(ngModel)]="_settings.sensorTag"
        (onChange)="updateCategoricalTags()"
        [style]="{'width':'250px'}"></p-dropdown>
      <p-dropdown *ngIf="_settings.filterType === 'SENSOR'"
        class="custom-input"
        [options]="availableSensor"
        [(ngModel)]="_settings.sensorTag"
        (onChange)="updateCategoricalTags()"
        [style]="{'width':'250px'}"></p-dropdown>
      <p-dropdown *ngIf="_settings.filterType === 'ACTUATOR'"
        class="custom-input"
        [options]="availableActuator"
        [(ngModel)]="_settings.sensorTag"
        (onChange)="updateCategoricalTags()"
        [style]="{'width':'250px'}"></p-dropdown>
    </div>
  </div>

  <br>

  <!-- TODO: No data to check the outcome. -->
  <!-- ROW 6: CATEGORICAL MAPPINGS -->
  <div class="row mt-3" *ngIf="_settings.showCategoricalValue">
    <div class="row mt-3" *ngFor='let pair of categoricalMappings'>
      <div class="col-2">
        <p-colorPicker
        (onChange)="onCategoricalColorChange(pair)"
        [(ngModel)]="pair.value"></p-colorPicker>
      </div>
      <div class="col-2">
        <span class="label" [innerText]="categoricalValues[pair.key][userLanguage]"></span>
      </div>
      <br>
    </div>
  </div>

  <!-- TODO: Check correct functionality. -->
  <!-- ROW 7: NUMERICAL VALUES -->
  <div class="row mt-3" *ngIf="!_settings.showCategoricalValue">
    <p class="mt-3" i18n>{{'real-time.settingsModal.upperLimitCategory' | translate}}</p>

    <p-table
      #dt [value]="numericalMappings"
      [rowHover]="true"
      [autoLayout]="true">

      <!-- Table Column/Header Definition -->
      <ng-template pTemplate="header">
        <tr>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center" i18n>{{'general.value' | translate}}</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center" i18n>{{'general.color' | translate}}</div>
          </th>

          <th style="width: 3rem">

          </th>

        </tr>
      </ng-template>

      <!-- Table Row Definition -->
      <ng-template pTemplate="body" let-pair>
        <tr>
          <td>
            <input
              type="text"
              name="side-panel-settings-name"
              class="form-control custom-input"
              (keyup)="onNumericKeyChange($event)"
              [(ngModel)]="pair.key"
              >
          </td>

          <!-- <td (change)="sortNumericalValues()">
            {{pair.key}}
          </td> -->

          <td>
            <p-colorPicker
              (onChange)="onNumericalColorChange(pair)"
              [(ngModel)]="pair.value"
              [inline]="true"></p-colorPicker>
          </td>

          <td>
            <button
              class="btn custom-button"
              style="width: 100px;"
              (click)=removeNumericalValueMapping(pair) i18n>
              {{'general.delete' | translate}}
            </button>
          </td>

        </tr>
      </ng-template>
    </p-table>

    <div class="d-flex justify-content-end mt-3">
      <button
        class="btn custom-button"
        style="width: 100px;"
        (click)=addNumericalValueMapping() i18n>
        {{'general.add' | translate}}
      </button>
    </div>
  </div>

  <!-- TODO: Fix functionality -->
  <!-- ROW 8: DATA CHART -->
  <div class="row mt-3">
    <div class="container-fluid mt-3" style="border: #97a2a9 solid 1px">
        <app-chart-settings [chartOptions]="chartOptions"></app-chart-settings>    
    </div>
  </div>
</div>
