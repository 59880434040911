<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">{{ledPattern.name}}</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="ledPattern.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!ledPattern.enabled>
    <div class="ui-g-12">
      <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
        <input pInputText id="name" [(ngModel)]="ledPattern.name" type="text" />
      </form-input>
    </div>

    <div class="ui-g-12">
      <b>Toggle value:</b>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed1" onLabel="Led1" offLabel="Led1"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed2" onLabel="Led2" offLabel="Led2"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed3" onLabel="Led3" offLabel="Led3"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed4" onLabel="Led4" offLabel="Led4"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed5" onLabel="Led5" offLabel="Led5"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed6" onLabel="Led6" offLabel="Led6"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed7" onLabel="Led7" offLabel="Led7"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed8" onLabel="Led8" offLabel="Led8"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.toggleLed9" onLabel="Led9" offLabel="Led9"></p-toggleButton>
      </div>
    </div>

    <div class="ui-g-12">
      <b>Final value:</b>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed1" onLabel="Led1" offLabel="Led1"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed2" onLabel="Led2" offLabel="Led2"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed3" onLabel="Led3" offLabel="Led3"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed4" onLabel="Led4" offLabel="Led4"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed5" onLabel="Led5" offLabel="Led5"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed6" onLabel="Led6" offLabel="Led6"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed7" onLabel="Led7" offLabel="Led7"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed8" onLabel="Led8" offLabel="Led8"></p-toggleButton>
      </div>
      <div class="ui-g-1">
        <p-toggleButton [(ngModel)]="ledPattern.finalValueLed9" onLabel="Led9" offLabel="Led9"></p-toggleButton>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Toggle Times" [errors]="errors.ledToggleTimes">
        <div class="ui-inputgroup">
          <p-spinner id="ledToggleTimes" size="5" [(ngModel)]="ledPattern.ledToggleTimes" [min]="0" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">0-255</span>
          <span class="ui-inputgroup-addon" i18n>times</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Toggle Duration" [errors]="errors.ledToggleDuration">
        <div class="ui-inputgroup">
          <p-spinner id="ledToggleDuration" size="5" [(ngModel)]="ledPattern.ledToggleDuration" [min]="0" [max]="10"></p-spinner>
          <span class="ui-inputgroup-addon">0-10</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Buzzer Times" [errors]="errors.buzzerToggleTimes">
        <div class="ui-inputgroup">
          <p-spinner id="buzzerToggleTimes" size="5" [(ngModel)]="ledPattern.buzzerToggleTimes" [min]="0" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">0-255</span>
          <span class="ui-inputgroup-addon" i18n>times</span>
        </div>
      </form-input>
    </div>


    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Buzzer Duration" [errors]="errors.buzzerToggleDuration">
        <div class="ui-inputgroup">
          <p-spinner id="buzzerToggleDuration" size="5" [(ngModel)]="ledPattern.buzzerToggleDuration" [min]="0" [max]="10"></p-spinner>
          <span class="ui-inputgroup-addon">0-10</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>
  </div>

</p-accordionTab>