import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class VehiclemodelService extends EntityService<Rest.VehicleModel>{
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  getModelsOfBrand(brandtype: string, queryParams?: { brandtype?: string; }): Rest.RestResponse<Rest.VehicleModel[]> {
    return this.client.VehicleModel_getModelsOfBrand(brandtype, queryParams);
  }

  getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.VehicleModel>> {
    return this.client.VehicleModel_getPage(arg0);
  }

  getBrandsOfType(type: string, queryParams?: { type?: number; }): Rest.RestResponse<Rest.VehicleBrand[]> {
    return this.client.VehicleModel_getBrandsOfType(type, queryParams);
  }

  create(arg0: Rest.VehicleModel): Rest.RestResponse<any> {
    return this.client.VehicleModel_create(arg0);
  }

  update(arg0: Rest.VehicleModel): Rest.RestResponse<Rest.VehicleModel> {
    return this.client.VehicleModel_update(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean, clientId?: number }
  ): Rest.RestResponse<void> {
    return this.client.VehicleModel_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.VehicleModel> {
    return this.client.VehicleModel_find(arg0, queryParams);
  }

  getBrands(): Rest.RestResponse<Rest.VehicleBrand[]> {
    return this.client.VehicleBrand_getList();
  }
}
