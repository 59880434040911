import { Injectable } from '@angular/core';
import { Rest } from './rest_client';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, Severity } from '../services/notifications-service/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import Terminal = Rest.Terminal;

@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    private _baseUrl: string = environment.webApiBaseUrl;

    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private translate: TranslateService
    ) {
    }

    /**
     * getTerminals()
     * */
    public getTerminals(userId: number): Promise<Terminal[]> {

        return this.http.get(
            this._baseUrl + 'api/deviceUser/' + userId
        ).toPromise()
            .then((response: any) => {
                return response ? response as Terminal[] : [];
            })
            .catch(() => {
                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.get.terminals')
                );
                return [];
            });

    }

    /**
     * updateTerminal()
     * */
    public updateTerminal(terminal: Terminal): Promise<Terminal | null> {

        return this.http.put(
            this._baseUrl + 'api/deviceUser',
            terminal
        ).toPromise()
            .then((response: any) => {
                return response?.id === terminal.id ? response : null;
            })
            .catch(() => {
                return null;
            })
    }

    /**
     * deleteTerminal()
     * */
    public deleteTerminal(terminalId: number): Promise<boolean> {

        return this.http.delete(
            this._baseUrl + 'api/deviceUser/' + terminalId,
        ).toPromise()
            .then(() => {

                this.notificationsService.add(
                    Severity.success,
                    this.translate.instant('success.success'),
                    this.translate.instant('success.delete.terminal') + '!'
                );

                return true;
            })
            .catch(() => {

                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.delete.terminal') + '!'
                );

                return false;
            })

    }
}
