<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GMT CONFIGURATION
          (GMT)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Hemisphere Time Zone">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="text" pInputText [(ngModel)]="gmt.hemisphereTimeZone" (keypress)="characterAllowed($event)"
            maxlength="1" class="txtbStyle">
          <span class="ui-inputgroup-addon">E/W</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Time Zone">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="gmt.timeZoneHour" [min]="0" [max]="12"></p-spinner>
          <span class="ui-inputgroup-addon">0~11</span>
          <span class="ui-inputgroup-addon">h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Half Time Zone">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="gmt.halfTimeZone" [min]="0" [max]="45" [step]="15"
            onkeydown="return false"></p-spinner>
          <span class="ui-inputgroup-addon">0/15/30/45</span>
          <span class="ui-inputgroup-addon">min</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>