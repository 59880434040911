import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverpointsService } from 'app/modules/vehicle-inventory/services/driverpoints.service';
import { NoteService } from 'app/modules/vehicle-inventory/services/note.service';
import { Rest } from 'app/rest/rest_client';
import { UserService } from 'app/rest/user.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';

@Component({
  selector: 'app-doc-note',
  templateUrl: './doc-note.component.html',
  styleUrls: ['./doc-note.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DocNoteComponent extends EntityFormComponent<Rest.InvNote> implements OnInit {

	@Input() isNew: boolean = false;
   @Input() note: Rest.InvNote = null;
	@Input() isEditingDisabled?: boolean = false;
	// @Input() errorMessages?: string[];

   @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

   constructor(
    private noteService: NoteService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public userService: UserService,
	 private datePipe: DatePipe
	) {
   	super(noteService, notificationsService, i18n, authenticationService);
	}

   ngOnInit(): void {
		console.log("[DOC-NOTE] ngOnInit ***");
		console.log(this.isNew);
		console.log(this.note);
      super.ngOnInit();
   }

	create(document): void {
		//console.log("[DOC-NOTE] create ***");
		var that = this;
		let data = {
			id: 0,
			description: this.note.description,
			document: { "id": document.id },
			vehicle: { "id": this.note.vehicle.id },
			noteDate: this.datePipe.transform(this.note.noteDate, 'yyyy-MM-dd') + "T00:00:00.000Z",
		}
		// console.log(data);
  		this.noteService.createNote(data).then((response) => {
      	that.notificationsService.add(Severity.success, "", this.translateService.instant('general.createSuccessfull'));
       		that.closeDialog.emit();
     	}).catch((error) => {
        	that.notificationsService.add(Severity.error, "", this.translateService.instant('general.createError'));
     	});
	}

  	update(): void {
		// console.log("[DOC-NOTE] update ***");
		// console.log(this.note);
		var that = this;
		let data = {
			id: this.note.id,
			document: { "id": this.note.document.id },
			description: this.note.description,
			noteDate: this.datePipe.transform(this.note.noteDate, 'yyyy-MM-dd') + "T00:00:00.000Z",
		}
 		this.noteService.updateNote(data).then((response) => {
			that.notificationsService.add(Severity.success, "", this.translateService.instant('general.updateSuccessfull'));
			that.closeDialog.emit();
		}).catch((error) => {
			that.notificationsService.add(Severity.error, "", this.translateService.instant('general.updateError'));
 		});
	}

	validateNote() {
		// console.log("[DOC-FORM] validateNote *** ");
		// console.log(this.note);
		if (!this.note.description || !this.note.noteDate)  {
			return false;
		} else {
			return true;
		}
	}

}

