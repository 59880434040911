<p-accordionTab>
  <p-header>
      <div class="ui-helper-clearfix">
          <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Power Saving Configuration ($WP+PSM)</span>
          </div>
      </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Power Saving Mode" >
        <p-dropdown  (onChange)="trackEventModeChange(powerSaving.mode,$event)" [options]="PowerSavingMode"
        [(ngModel)]="powerSaving.mode" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>

    <div [class.disableDIV]=!trackModeSwitch>
        <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Delay" >
              <div class="ui-inputgroup">
                <p-spinner size="3" [step]="1" [(ngModel)]="powerSaving.delay" [min]="60" [max]="65535"></p-spinner>
                <span class="ui-inputgroup-addon">60~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Sleep Mode" >
        <p-dropdown [options]="SleepingMode"
          [(ngModel)]="powerSaving.sleepMaskMode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>       
  </div>
  </div>

</p-accordionTab>
