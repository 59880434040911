import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Rest } from '../../../rest/rest_client';
import { UserService } from '../../../rest/user.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { EntityFormComponent } from '../../entity-form/entity-form.component';
import { TranslateService } from "@ngx-translate/core";
import { RestExt } from "../../../services/rest-client-extension";
import MetricEnum = RestExt.MetricEnum;
import { IconService } from 'app/rest/icon.service';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.css'],
    providers: [ConfirmationService, IconService],
})
export class UserSettingsComponent extends EntityFormComponent<Rest.User> implements OnInit {
    @ViewChild('modal') modal: ElementRef;
    @ViewChild('profileForm') form;
    @ViewChild('closeUserProfileModalButton') closeModalButton: ElementRef;

    userID: number;
    clientId: number;
    userName: string;
    email: string;
    name: string;
    surname1: string;
    surname2: string;
    timeZone: string;
    phoneNumber: string;

    availableUserLanguage = [];
    availableUserMetric = [];
    availableDashboards = [];
    availableTableSizes = []
    selectedMetric: any;
    selectedLanguage: any;
    selectedDashboard: any;
    selectedTableSize: any;

    successMessageTitle: string;
    successMessageContent: string;
    errorMessageTitle: string;
    errorMessageContent: string;

    userImageFile: any;
    userEncodedImage: string;

    entityEnumImageProfile: Rest.IconEntityAssociationEnum = "PROFILE_FLEET";

    profileImage: Rest.Icon;

    constructor(
        private router: Router,
        private userService: UserService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
    ) {
        super(userService, notificationsService, i18n, authenticationService);


        
        /* Retrieve translation for the Update Successful message title */
        this.translate.get('userProfile.settingsUpdatedTitle').subscribe((settingsUpdatedTitle) => {
            this.successMessageTitle = settingsUpdatedTitle;
        });

        /* Retrieve translation for the Update Successful message content */
        this.translate.get('userProfile.settingsUpdatedMessage').subscribe((settingsUpdatedMessage) => {
            this.successMessageContent = settingsUpdatedMessage;
        });

        /* Retrieve translation for the Error message title */
        this.translate.get('userProfile.settingsFailedTitle').subscribe((settingsFailedTitle) => {
            this.errorMessageTitle = settingsFailedTitle;
        });

        /* Retrieve translation for the Error message content */
        this.translate.get('userProfile.settingsObtainFailedMessage').subscribe((settingsObtainFailedMessage) => {
            this.errorMessageContent = settingsObtainFailedMessage;
        });

        this.authenticationService.userObservable.subscribe((user) => {
            if (user) {
                this.userID = user.id;
                this.clientId = user.client?.id;
                this.initUserSettings();
            }
        })

        /* if (this.authenticationService.isUserLogged()) {
             this.initUserSettings();
         }*/
    }

    ngOnInit() {
        super.ngOnInit();
    
    }

    /**
     * Function that initializes the component by retrieving current user's settings via an API call.
     * */
    initUserSettings(): void {

        this.availableUserLanguage = [];
        this.availableUserMetric = [];
        this.availableDashboards = [];
        this.availableTableSizes = [];
        /* Use API call to retrieve the user information */
        this.userService.getUserProfileInfo(this.userID)
            .then((response) => {
                /* Init form input variables */
                this.userName = response.userName;
                this.email = response.email;
                this.name = response.name;
                this.surname1 = response.surname1;
                this.surname2 = response.surname2;
                this.timeZone = response.timeZone;
                this.phoneNumber = response.phoneNumber;
                this.userEncodedImage = response.profileImage;


                /* Retrieve translations for the available user languages */
                this.translate.get('userProfile.availableUserLanguage').subscribe((languages) => {
                    this.availableUserLanguage = [];
                    this.availableUserLanguage.push({ id: 1, value: 'ES', label: languages.spanish });
                    this.availableUserLanguage.push({ id: 4, value: 'CA', label: languages.catalan });
                    this.availableUserLanguage.push({ id: 2, value: 'EN', label: languages.english });
                    this.availableUserLanguage.push({ id: 3, value: 'FR', label: languages.french });
                    this.availableUserLanguage.push({ id: 5, value: 'IT', label: languages.italian })

                    this.userLanguageInit(response.language);
                });

                /* Retrieve translations for the available metrics */
                this.translate.get('userProfile.availableUserMetric').subscribe((metrics) => {
                     this.availableUserMetric = [];
                    this.availableUserMetric.push({ value: 0, label: metrics.international });
                    this.availableUserMetric.push({ value: 1, label: metrics.imperial });

                    this.userMetricInit(response.metric);
                });

                /* Retrieve translations for the available dashboards */
                this.translate.get('header').subscribe((dashboards) => {
                     this.availableDashboards = [];
                    this.userDashboardInit(response.defaultDashboard, dashboards);
                });

                 /* Retrieve translations for the available metrics */
                this.translate.get('tableUserSettings.tableSizes').subscribe((tableSizes) => {
                    this.availableTableSizes = [];
                    this.availableTableSizes.push({ value: 0, label: tableSizes.big });
                    this.availableTableSizes.push({ value: 1, label: tableSizes.small });
                    this.userTableSizeInit(response.tableSize);
                });
            })
            .catch(() => {
                this.notificationsService.add(Severity.error, 'User Profile Error', 'An error occurred while trying to retrieve user\'s information!');
            });
    }

    /**
     * Function that calls initUserSettings() on dialog close. That way the information in the dialog will not be saved.
     */
    closeUserProfileDialog(): void {
        this.initUserSettings();
    }

    /**
     * Function that initializes the list of metric systems.
     */
    userMetricInit(metric: String): void {
        if (metric === 'INTERNATIONAL_SYSTEM_OF_UNIT') {
            this.selectedMetric = this.availableUserMetric[0];
        } else if (metric === 'IMPERIAL_SYSTEM') {
            this.selectedMetric = this.availableUserMetric[1];
        }
    }

    /**
     * Function that initializes the list table sizes
     */
    userTableSizeInit(tableSize: String): void {
        if (tableSize === 'BIG') {
            this.selectedTableSize = this.availableTableSizes [0];
        } else if (tableSize === 'SMALL') {
            this.selectedTableSize = this.availableTableSizes[1];
        }
        else{
            this.selectedTableSize = this.availableTableSizes [0];
        }
    }


    /**
     * Function that initializes the list of languages.
     */
    userLanguageInit(language: String): void {
        switch (language) {
            case 'ES':
                this.selectedLanguage = this.availableUserLanguage[0];
                break;
            case 'CA':
                this.selectedLanguage = this.availableUserLanguage[1];
                break;
            case 'EN':
                this.selectedLanguage = this.availableUserLanguage[2];
                break;
            case 'FR':
                this.selectedLanguage = this.availableUserLanguage[3];
                break;
            case 'IT':
                this.selectedLanguage = this.availableUserLanguage[4];
                break;
        }
    }

    /**
     * Function that initializes the list of dashboards, based on the user's permissions.
     */
    userDashboardInit(defaultDashboard: String, labels): void {

        /* Get the list of userAllowedDashboards from API call */
        this.userService.getUserAllowedDashboards(this.userID)
            .then((response) => {
                this.availableDashboards = [];

                /* Check which dashboards the user can see */
                if (response.COEXPanel) {
                    this.availableDashboards.push({
                        value: 'COEXPanel',
                        label: labels.COEXPanel,
                    });
                }
                if (response.rt) {
                    this.availableDashboards.push({
                        value: 'rt',
                        label: labels.rt,
                    });
                }
                if (response.reports) {
                    this.availableDashboards.push({
                        value: 'reports',
                        label: labels.reports,
                    });
                }
                if (response.controlPanel) {
                    this.availableDashboards.push({
                        value: 'control-panel',
                        label: labels.settings,
                    });
                }
                if (response.racing) {//RAQUEL RACINGS
                    this.availableDashboards.push({
                        value: 'racing',
                        label: labels.racing,
                    });
                } //FIN RAQUEL RACINGS
                if (response.tax) {
                    this.availableDashboards.push({
                        value: 'tax',
                        label: labels.tax,
                    });
                }
                if (response.sat) {
                    this.availableDashboards.push({
                        value: 'sat',
                        label: labels.sat,
                    });
                }
                if(response.inventoryVehicles){
                    this.availableDashboards.push({
                        value: 'inventoryVehicles',
                        label: labels.inventoryVehicles,
                    });
                }
                if(response.drivers){
                    this.availableDashboards.push({
                        value: 'drivers',
                        label: labels.drivers,
                    });
                }
                if(response.maintenance){
                    this.availableDashboards.push({
                        value: 'maintenance',
                        label: labels.maintenance,
                    });
                }
                if(response.tableManagement){
                    this.availableDashboards.push({
                        value: 'tableManagement',
                        label: labels.tableManagement
                    })
                }
                if(response.dashboards){
                    this.availableDashboards.push({
                        value: 'dashboards',
                        label: labels.dashboards
                    })
                }
                if (response.administration) {//RAQUEL task faltará aquí el tema de orders.
                    this.availableDashboards.push({
                        value: 'administration',
                        label: labels.administration
                    });
                } 
                /* Assign the selected default dashboard from the built list */
                this.availableDashboards.forEach((element, index) => {
                    if (element.value === defaultDashboard) {
                        this.selectedDashboard = this.availableDashboards[index];
                    }
                });

            })
            .catch(() => {
                this.notificationsService.add(Severity.error, 'ERROR', 'Could not retrieve allowed dashboards for the user!')
            });
    }

    /**
     * Function that updates the value of the user's time zone.
     */
    userTimeZoneUpdate(event): void {
        this.timeZone = event.value;
    }

    /* TODO: Implement user profile image functionality:: <<Jira AF3-13 / AF3-37>> */
    imageChange(event): void {
        let reader = new FileReader();
        this.userImageFile = event.target.files[0];
        reader.onload = (event: any) => {
            this.userEncodedImage = event.target.result;
        };
        //reader.readAsDataURL(event.target.files[0]);

        /* FORM DATA */
        const formData = new FormData();
        formData.append('myfile[]', this.userImageFile);
        formData.append('entity', this.entityEnumImageProfile);
        formData.append('userId', this.userID.toString());

        this.iconService.uploadIcon(formData).then((res) => {
            this.notificationsService.add(Severity.success, this.translate.instant('userProfile.settingImageTitle'), this.translate.instant('userProfile.settingsImageUpdatedMessage'));
            this.profileImage = res;
            this.userEncodedImage = res.imageEncoded;
            this.authenticationService.updateUserProfile(this.profileImage);
        }).catch(() => {
            this.notificationsService.add(Severity.error, this.translate.instant('userProfile.settingsFailedImageTitle'), this.translate.instant('userProfile.settingsFailedImageMessage'));
        });
    }

    /**
     * Function that updates the user's settings on <<submit>> button press, by making an API call and then updating {{currentUser}}
     * */
    updateUserSettings(): void {
        let userSettings = {
            "id": this.userID,
            "email": this.email,
            "name": this.name,
            "surname1": this.surname1,
            "surname2": this.surname2,
            "timeZone": this.timeZone,
            "metric": this.selectedMetric.value,
            "phoneNumber": this.phoneNumber,
            "language": this.selectedLanguage.id,
            "defaultDashboard": this.selectedDashboard !== undefined ? this.selectedDashboard.value : null,
            "profileImage": this.profileImage,
            "tableSize" : this.selectedTableSize?.value
        };

        this.userService.updateUserProfileInfo(userSettings)
            .then(() => {
                this.notificationsService.add(Severity.success, this.successMessageTitle, this.successMessageContent);

                userSettings.language = this.selectedLanguage.value;
                userSettings.defaultDashboard = this.selectedDashboard !== undefined ? this.selectedDashboard.value : null;
                userSettings.metric = MetricEnum[this.selectedMetric.value];
                this.authenticationService.updateUser(userSettings);

            })
            .catch(() => {
                this.notificationsService.add(Severity.error, this.errorMessageTitle, this.errorMessageContent);
            });



    }

}
