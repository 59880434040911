import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { VehicleStateComponent } from 'app/components/fleet-edit-view/vehicle-state/vehicle-state.component';

@Component({
  selector: 'app-vehicle-state-button',
  templateUrl: './vehicle-state-button.component.html',
  styleUrls: ['./vehicle-state-button.component.css'],
})
export class VehicleStateButtonComponent implements OnInit {

  @Input() vehicleId: number;
  @Input() buttonStyle: string;
  @Input() disabled: boolean;

  @ViewChild(VehicleStateComponent, { static: true }) vehicleStateForm: VehicleStateComponent;
  constructor() { }

  ngOnInit(): void {
  }

  showRealTimeInfo() {
    this.vehicleStateForm.showForm();
  }
}
