<!--<div class="flex-column justify-content-center p-5">

  //REPORTS PRIMENG DATATABLE
  <p-table [value]="paginationResult.entities"
           [lazy]="true"
           (onLazyLoad)="loadData($event)"
           [rows]="paginationRequest.pageSize"
           [totalRecords]="paginationResult.filteredEntities"
           [rowsPerPageOptions]="[10,20,30,40,50]"
           [paginator]="true"
           [rowHover]="true"
           [showCurrentPageReport]="true"
           [autoLayout]="true"
           dataKey="name"
           currentPageReportTemplate=
           "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
           class="custom-table"
           styleClass="p-datatable-striped"
  >

    //TABLE TITLE DEFINITION
    <ng-template pTemplate="caption">
      <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
        {{ 'reports.reports' | translate }}
      </div>
    </ng-template>

    //TABLE COLUMN DEFINITION
    <ng-template pTemplate="header">
      <tr>

        //EXPAND
        <th class="button-column">

        </th>

        //NAME
        <th pSortableColumn="name">
          <div class="d-flex align-items-center h-100">

            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'general.name' | translate }}
            </div>

            //COLUMN SORT && FILTER ICON
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="name"></p-sortIcon>
              <p-columnFilter field="name"
                              type="text"
                              matchMode="contains"
                              placeholder="{{ 'general.search' | translate }}"
                              display="menu"
                              [showMatchModes]="false"
                              [showOperator]="false"
                              [showAddButton]="false"
              ></p-columnFilter>
            </div>

          </div>
        </th>

        //TYPE
        <th>
          <div class="d-flex align-items-center h-100">

            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'general.type' | translate }}
            </div>

          </div>
        </th>

        //REQUEST DATE
        <th>
          <div class="d-flex align-items-center h-100">

            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'reports.requestDate' | translate }}
            </div>

          </div>
        </th>

        //STATUS
        <th>
          <div class="d-flex align-items-center h-100">

            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'general.status' | translate }}
            </div>

          </div>
        </th>
        // PERIOD START
        <th>
          <div class="d-flex align-items-center h-100">
            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'reports.periodStart' | translate }}
            </div>

          </div>
        </th>

        //PERIOD END
        <th>
          <div class="d-flex align-items-center h-100">
            //COLUMN TITLE
            <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
              {{ 'reports.periodEnd' | translate }}
            </div>

          </div>
        </th>

      </tr>
    </ng-template>

    //TABLE BODY
    <ng-template pTemplate="body" let-report let-expanded="expanded">
      <tr class="custom-rows">

        //EXPAND
        <td class="button-column">
          <div class="d-flex align-items-center justify-content-center">

            <button class="btn custom-expand-button" type="button" [pRowToggler]="report">
              <svg-icon [ngClass]="{'rotated': !expanded}"
                        class="d-flex m-0 p-0 custom-expand-button-icon"
                        src="assets/icons/select-down.svg"
                        [svgStyle]="{'height.px': 20, 'width.px': 20}"
              ></svg-icon>
            </button>

          </div>
        </td>

        //NAME
        <td>
          {{report.name}}
        </td>

        //TYPE
        <td>
          {{report.type}}
        </td>

        //REQUEST DATE
        <td>
          {{report.requestDate | date: 'dd/MM/yyyy HH:mm'}}
        </td>

        //STATUS
        <td>
          {{report.status}}
        </td>

        //PERIOD START
        <td>
          {{report.periodStart | date: 'dd/MM/yyyy'}}
        </td>

        //PERIOD END
        <td>
          {{report.periodEnd | date: 'dd/MM/yyyy'}}
        </td>

    </ng-template>

    //EXPANSION TEMPLATE
    <ng-template pTemplate="rowexpansion" let-report>
      <tr>

        <td colspan="7">
          <div class="d-flex flex-row justify-content-center p-5" style="background: white !important;">
            <button class="btn btn-outline-dark" (click)="showReport(report.id, 0)">Show Report</button>
          </div>
          <div class="d-flex flex-row p-3 gap-5" style="background: white !important;">

            <div class="d-flex flex-column">
              <div style="color: #C42C22">id:</div>
              <div style="color: #C42C22">name:</div>
              <div style="color: #C42C22">requestDate:</div>
              <div style="color: #C42C22">owner:</div>
              <div style="color: #C42C22">computationStartDate:</div>
              <div style="color: #C42C22">computationEndDate:</div>
              <div style="color: #C42C22">status:</div>
              <div style="color: #C42C22">type:</div>
              <div style="color: #C42C22">vehiclesIds:</div>
              <div style="color: #C42C22">vehiclesGroupsIds:</div>
              <div style="color: #C42C22">fleetsIds:</div>
              <div style="color: #C42C22">vehiclesIdsAndNames:</div>
              <div style="color: #C42C22">periodStart:</div>
              <div style="color: #C42C22">periodEnd:</div>
            </div>

            <div class="d-flex flex-column">
              <div>{{report.id}}</div>
              <div>{{report.name}}</div>
              <div>{{report.requestDate | date: "dd/MM/yyyy HH:mm" }}</div>
              <div>{{report?.owner ? report.owner.userName : 'NULL'}}</div>
              <div>{{report.computationStartDate | date: 'dd/MM/yyyy HH:mm' }}</div>
              <div>{{report.computationEndDate | date: 'dd/MM/yyyy HH:mm' }}</div>
              <div>{{report.status}}</div>
              <div>{{report.type}}</div>
              <div>{{report.vehicledIds ? report.vehicleGroupsIds : 'NULL'}}</div>
              <div>{{report.vehicleGroupsIds ? report.vehicleGroupsIds : 'NULL'}}</div>
              <div>{{report.fleetsIds ? report.fleetsIds : 'NULL'}}</div>
              <div>{{report.vehiclesIdsAndNames ? report.vehiclesIdsAndNames : 'NULL'}}</div>
              <div>{{report.periodStart | date: 'dd/MM/yyyy' }}</div>
              <div>{{report.periodEnd | date: 'dd/MM/yyyy' }}</div>

              //SHOW REPORT FOR A VEHICLE
              //<div class="ui-g-8 ui-md-6">
                // <h1>Vehicles</h1>
                //<button *ngFor="let pair of report.vehiclesIdsAndNames" (click)="showReport(report.id, pair.id)">
                  //{{pair.name}}
                  //</button>
                  //</div>
            </div>

          </div>

        </td>
      </tr>
    </ng-template>

    //TABLE EMPTY MESSAGE
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="p-0">
          <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
            {{ 'table.tableEmptyMessage' | translate }}!
          </div>
        </td>
      </tr>
    </ng-template>

    //FOOTER
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="7" class="p-0">
          <div class="d-flex flex-row align-items-center p-3 gap-3">

            //ADD REPORT BUTTON
            <app-table-add-button [text]=" 'reports.report' | translate "
                                  (btnClick)="addReport()"
                                  [isDisabled]="false"
            ></app-table-add-button>

          </div>
        </td>
      </tr>
    </ng-template>

  </p-table>

</div>

<app-vehicle-activity-report></app-vehicle-activity-report>
<app-report-form (return)=reportCreated($event)></app-report-form>-->



<!-- INFORME TELTONIKA -->
<!-- MESSAGES PANEL -->
<!-- MESSAGES PANEL -->
<div class='tab-pane fade show active'>

    <!-- SEARCH CONFIGURATION PANEL -->
    <div class='d-flex flex-row justify-content-evenly w-100 p-3 mt-4 mb-4 sat-search-panel-container'>
  
      <!-- COLUMN 1: VEHICLE ID && START DATE -->
      <div class='d-flex flex-column gap-3'>  
        <!-- START DATE -->
        <div class='d-flex align-items-center justify-content-between'>
          <label id='startDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
            {{ 'general.startDate' | translate }}:
          </label>
          <p-calendar id='startDateCalendar'
                dateFormat='dd/mm/yy'
                firstDayOfWeek=1
                class='custom-datepicker-with-button'
                [(ngModel)]='startDate'
                [showIcon]='true'
                [showOnFocus]='false'
          ></p-calendar>
        </div>
  
      </div>
    <!-- COLUMN 2: END DATE -->
    <div class='d-flex flex-column gap-3'>
      <!-- END DATE -->
      <div class='d-flex align-items-center justify-content-between'>
          <label id='endDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
            {{ 'general.endDate' | translate }}:
          </label>
          <p-calendar id='endDateCalendar'
                dateFormat='dd/mm/yy'
                class='custom-datepicker-with-button'
                [minDate]=startDate
                firstDayOfWeek=1
                [disabled]='startDate === null'
                [(ngModel)]='endDate'
                [showIcon]='true'
                [showOnFocus]='false'
          ></p-calendar>
        </div>
    </div>

      <!-- COLUMN 3: SEARCH CONTAINER -->
      <div class='d-flex flex-column gap-3'>
  
        <!-- SEARCH CONTAINER: SEARCH BUTTON && SETTINGS BUTTON && DETACH BUTTON -->
        <div class='d-flex align-items-center justify-content-between' style='width: 401px'>
  
          <!-- SEARCH BUTTON -->
          <button class='btn custom-button sat-field' (click)='search()'>
            {{ 'general.search' | translate }}
          </button> 

          <div class="buttonTableExcel">
            <button class="btn btn-success btn-sm" style="margin-left: 5px;" (click)="exportExcel()" pButton  type="button" label="{{'reports.export' | translate}}"></button>
          </div>
  
      </div>

  </div>
</div>
</div> 
  
          <p-table id="table"
                   [value]="paginationResult"           
                   [rowHover]="false"
                   [autoLayout]="true"
                   (onLazyLoad)='fetch()'
                   dataKey="name"
                   [resizableColumns]="true"
                   [scrollable]="true"
                   currentPageReportTemplate=
                   "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
                   class="custom-table"
                   styleClass="p-datatable-striped"
                   [scrollHeight]="height"
          >
        
        
            <ng-template pTemplate="caption">
              <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
                {{ 'reports.reports' | translate }}
              </div>
            </ng-template>
        
        
            <!-- TABLE COLUMN DEFINITION -->
		        <ng-template pTemplate='header' let-columns>           
              <tr style="position:sticky">
                  <th pFrozenColumn class="text-row frozen-column">
                      <div class='d-flex ms-3 justify-content-center' >
                        {{ 'general.generalInformation' | translate }}
                      </div>
                  </th>
                  <th *ngFor="let day of headerColumns" colspan="3" class="text-row">
                      <div class='d-flex ms-3 justify-content-center'>
                          {{day}}
                      </div>
                  </th>
              </tr> 
              <tr>
                <th pFrozenColumn class="text-row frozen-column">
                  <div class='d-flex ms-3 justify-content-center'>
                    {{ 'reports.hospitalMataro.item' | translate }}
                  </div>
                </th>
                  <th *ngFor="let data of headerColumnsData" class="text-row">
                    <div class='d-flex ms-3 justify-content-center'>
                      {{data}}
                    </div>
                  </th>  
            
              </tr>
            </ng-template>

            <!-- TABLE BODY -->
		<ng-template pTemplate='body' let-row>
        <tr class='custom-rows'>
          
          <!-- NAME -->
				  <td pFrozenColumn class="frozen-column" >
            <div *ngIf="row.avis" class='d-flex w-100 justify-content-center'>
                {{ 'reports.avis' | translate}} {{row.name}}
            </div>
            <div *ngIf="!row.avis" class='d-flex w-100 justify-content-center'>
                {{ 'reports.us' | translate}} {{row.name}}
            </div>
          </td>

          <!-- SENSORS -->
          <ng-container *ngFor="let days of row.days">
            <td *ngFor= 'let data of days.data, let i = index' [ngClass]="
              !data.newSensorData ? 'no-data' : ((!row.avis && data.value == 0) || (row.avis && data.value > 0)) ? 'low-rated':''" class="text-row">
              <div *ngIf="data.value > 0" >
                    {{data.value}}                  
              </div>                  
            </td>
          </ng-container>


           
  
          <!-- SENSORS 
          <td *ngFor='let sensor of columnsSensors, let i = index' [hidden]='!sensor.visible'>
            {{rowData.sensors[i]?.numericValue}}
          </td>-->
  
          <!-- ACTUATORS 
          <td *ngFor='let actuator of columnsActuators, let i = index' [hidden]='!actuator.visible'>
            {{rowData.actuators[i]?.rawValue}}
          </td>-->
  
        </tr>
      </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7" class="p-0">
                  <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                  </div>
                </td>
              </tr>
            </ng-template>
        
          </p-table>
        




