import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-gmtconfiguration-ob22',
  templateUrl: './gmtconfiguration-ob22.component.html',
  styleUrls: ['./gmtconfiguration-ob22.component.css']
})
export class GMTConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {

  @Input() gmt: Rest.GMTConfigurationOB22;

  constructor(protected i18n: I18nService) {  super(i18n);}

 
  ngOnInit() {
    
  }

  characterAllowed(event){
    var c = event.charCode;

    return c == 69 || c == 87; //only E OR W are allowed
  }
  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.gmt);
    }
  }

}
