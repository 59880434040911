<p-accordionTab i18n-header header="Idle event configuration">
  <div class="ui-g">

    <div class="ui-g-12 ui-m-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Speed threshold" [errors]="errors.idleEventConfig">
        <p-spinner size="5" [(ngModel)]="idleEventConfig.speedThreshold" [min]="5" [max]="255"></p-spinner>
        <span class="ui-inputgroup-addon">5~255</span>
        <span class="ui-inputgroup-addon" i18n>Km/h</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Start duration" [errors]="errors.idleEventConfig">
        <p-spinner size="5" [(ngModel)]="idleEventConfig.startDuration" [min]="0" [max]="65535"></p-spinner>
        <span class="ui-inputgroup-addon">0~65535</span>
        <span class="ui-inputgroup-addon" i18n>seconds</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input [required]="true" i18n-fieldname fiedlName="Start duration" [errors]="errors.idleEventConfig">
        <p-spinner id="idleEventConfig" size="5" [(ngModel)]="idleEventConfig.endDuration" [min]="0" [max]="65535"></p-spinner>
        <span class="ui-inputgroup-addon">0~65535</span>
        <span class="ui-inputgroup-addon" i18n>seconds</span>
      </form-input>
    </div>

  </div>
</p-accordionTab>
