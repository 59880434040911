import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";

import InventoryReportRequest = Rest.InventoryReportRequest; import { jsPDF } from 'jspdf';
import "jspdf-autotable";
import { DatePipe, formatDate } from '@angular/common';
import RealState = Rest.RealState;
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import { UploadFileFormComponent } from "../../../documents/upload-file-form/upload-file-form.component";
import { RealStateFormComponent } from '../real-state-form/real-state-form.component';
import { RealStateService } from 'app/modules/vehicle-inventory/services/table-management/realState.service';

@Component({
    selector: 'app-real-state-list',
    templateUrl: './real-state-list.component.html',
    styleUrls: ['./real-state-list.component.css'],
    providers: [RealStateService, ConfirmationService, UserTimePipe, ExcelService],
})
export class RealStateListComponent
    extends EntityListComponent<RealState>
    implements OnInit {
    title: string = this.i18n._("InsuranceTypes");

    @ViewChild(RealStateFormComponent, { static: true })
    realStateForm: RealStateFormComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];
    fileFormTitle = "";
    renewalType = "INSURANCE";
    @ViewChild(UploadFileFormComponent) uploadFileForm!: UploadFileFormComponent;

    exportPDFColumns;
    tableDataFormatted: any;

    constructor(
        private realStateService: RealStateService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
        private datePipe: DatePipe
    ) {
        super(
            realStateService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.realStateService = realStateService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.realStateForm;
    }

    /**
     * Loads the data for the entity list.
     *
     * @memberof InsuranceTypeListComponent
     */

    beforeLoad() {
        this.paginationRequest.sortBy = 'name';
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    //EXCEL
    excelColumns = {};

    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.real-state.realState');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }

    setExcelColumns() {
        const society = (entity: any) => entity.billingAccount.code ?? ''
        const contractNumber = (entity: any) => entity.name ?? ''
        const contractDenomination = (entity: any) => entity.name ?? ''

        // const currency = (entity: any) => entity.currency ?? ''
        const currency = (entity: any) => 'EUR'

        const contractStart = (entity: any) => {
            if (entity.financing.startDate === null || entity.financing.startDate === undefined) { return ''; }
            return formatDate(entity.financing.startDate, 'dd/MM/yyyy', 'es-ES');
        }
        const contractEnd = (entity: any) => {
            if (entity.financing.endDate === null || entity.financing.endDate === undefined) { return ''; }
            return formatDate(entity.financing.endDate, 'dd/MM/yyyy', 'es-ES');
        }
        const bpCode = (entity: any) => entity.financing.provider?.providerParameters?.codeBp ?? ''
        const amount = (entity: any) => entity.financing.monthlyFee ?? ''
        const wayToPay = (entity: any) => entity.financing.wayToPay.name.substring(0, 1).toUpperCase() ?? ''
        const ceco = (entity: any) => entity.ceco.code ?? ''
        const interestType = (entity: any) => entity.financing.interestRate ?? ''

        this.excelColumns = {
            [this.translateService.instant('tableManagement.real-state.society')]: { bindField: society },
            [this.translateService.instant('tableManagement.real-state.contractNumber')]: { bindField: contractNumber },
            [this.translateService.instant('tableManagement.real-state.contractDenomination')]: { bindField: contractDenomination },
            [this.translateService.instant('tableManagement.real-state.currency')]: { bindField: currency },
            [this.translateService.instant('tableManagement.real-state.contractStart')]: { bindField: contractStart },
            [this.translateService.instant('tableManagement.real-state.contractEnd')]: { bindField: contractEnd },
            [this.translateService.instant('tableManagement.real-state.bpCode')]: { bindField: bpCode },
            [this.translateService.instant('tableManagement.real-state.amount')]: { bindField: amount },
            [this.translateService.instant('tableManagement.real-state.wayToPay')]: { bindField: wayToPay },
            [this.translateService.instant('tableManagement.real-state.ceco')]: { bindField: ceco },
            [this.translateService.instant('tableManagement.real-state.interestType')]: { bindField: interestType },
        }
    }

    //UPLOAD FILE
    showFileForm() {
        this.fileFormTitle = this.translateService.instant('tableManagement.insurances');
        this.renewalType = 'INSURANCE';
        this.uploadFileForm.showDialog();
    }

    formatTableData(tableData: any[]): any[] {
        return tableData.map(obj => {
          let newTableData = {};
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              let field = key;
              let value = obj[key].value;
              newTableData[field] = value;
            }
          }
          return newTableData;
        });
      }

    exportPdf() {
        var that = this;
        this.exportPDFColumns = [
            {
                title: this.translateService.instant('tableManagement.real-state.society'),
                dataKey: 'society'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.contractNumber'),
                dataKey: 'contractNumber'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.contractDenomination'),
                dataKey: 'contractDenomination'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.currency'),
                dataKey: 'currency'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.contractStart'),
                dataKey: 'contractStart'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.contractEnd'),
                dataKey: 'contractEnd'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.bpCode'),
                dataKey: 'bpCode'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.amount'),
                dataKey: 'amount'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.wayToPay'),
                dataKey: 'wayToPay'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.ceco'),
                dataKey: 'ceco'
            },
            {
                title: this.translateService.instant('tableManagement.real-state.interestType'),
                dataKey: 'interestType'
            }
        ];


        this.tableDataFormatted = [];

        let fullDataRequest = {
			sortBy: 'id',
			sortAsc: true,
			pageSize: 100000000,
			filters: {},
			loadPermissions: false,
		} as Rest.ListPaginationRequest;

        this.realStateService.getPage(fullDataRequest).then((data: any) => {
            data.entities.forEach((e: any) => {

                var start = (e.financing.startDate === null || e.financing.startDate === undefined)
                ? ''
                : formatDate(e.financing.startDate, 'dd/MM/yyyy', 'es-ES') ?? '';
    
    
                var end = (e.financing.endDate === null || e.financing.endDate === undefined)
                ? ''
                : formatDate(e.financing.endDate, 'dd/MM/yyyy', 'es-ES') ?? '';
    
                that.tableDataFormatted.push({
                    society: e.billingAccount.name,
                    contractNumber: e.name,
                    contractDenomination: e.name,
                    currency: 'EUR',
                    contractStart: start,
                    contractEnd: end,
                    bpCode: e.financing.provider?.providerParameters?.codeBp,
                    amount: e.financing.monthlyFee,
                    wayToPay: e.financing.wayToPay.name.substring(0, 1).toUpperCase(),
                    ceco: e.ceco.description,
                    interestType: e.financing.interestRate
                });
            });

            let doc = new jsPDF('p', 'pt');
            // let pdfFontSize = 8
            //doc.addPage('a2');
            //screen orientation configuration
    
            if (this.exportPDFColumns.length > 5) {
                doc = new jsPDF('landscape');
            } else {
                doc = new jsPDF('p', 'pt');
            }
    
            const styles = {
                theme: 'grid',
                styles: { overflow: 'linebreak', fontSize: 6 },
                headStyles: { fillColor: [220, 53, 69], textColor: [255, 255, 255], fontSize: 7, overflow: 'linebreak' },
            };
    
            doc['autoTable']({
                columns: this.exportPDFColumns,
                body: that.tableDataFormatted,
                margin: { top: 5, right: 5, bottom: 5, left: 5 },
                ...styles,
                tableWidth: 'wrap',
                horizontalPageBreak: true,
            });
            let fileName = String('real_state').trim()
            doc.save(fileName + "_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".pdf");
        });
       


       
    }

 
}