<p-accordionTab>


    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">User Defined
                    Functions
                    (AT + GTUDF)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Main
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="modeOptions" [(ngModel)]="userDefinedFunction.mode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Group id">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="userDefinedFunction.groupId" [min]="0" [max]="31"></p-spinner>
                                <span class="ui-inputgroup-addon">0~31</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Debounce Time">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="userDefinedFunction.debounceTime" [min]="0"
                                    [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">0~86400</span>
                                <span class="ui-inputgroup-addon">s</span>
                            </div>
                        </div>
                    </div>
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Stocmd ACK">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="modeAck" [(ngModel)]="userDefinedFunction.modeStoCmd"
                                [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>
                </div>

                <div *ngIf="firmware > 5">
                    <div class="row mt-3">
                        <p-checkbox binary="true" label="Preserve Executed State"
                            [(ngModel)]="userDefinedFunction.preserveExecutedState"></p-checkbox>
                    </div>
                </div>
            </p-accordionTab>

        </div>
        <div class="ui-g">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mask
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="row dvcFields">
                        <form-input fieldName="Input id mask"></form-input>

                        <div class="row mt-3">
                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Power on finished"
                                        [(ngModel)]="userDefinedFunction.powerOnFinished">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="External charge inserted"
                                            [(ngModel)]="userDefinedFunction.externalCharge"></p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="No external charge"
                                            [(ngModel)]="userDefinedFunction.noExternalCharge"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Ignition on"
                                        [(ngModel)]="userDefinedFunction.ignitionOn">
                                        </p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="Ignition off"
                                            [(ngModel)]="userDefinedFunction.ignitionOff">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Digital input 1 is low"
                                        [(ngModel)]="userDefinedFunction.digitalInput1Low"></p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="Digital input 1 is high"
                                            [(ngModel)]="userDefinedFunction.digitalInput1High"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="The GPRS network is attached"
                                        [(ngModel)]="userDefinedFunction.gprsnetwork">
                                    </p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="The GPRS network is not attached"
                                            [(ngModel)]="userDefinedFunction.noGPRSNetwork">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Inside the speed range"
                                        [(ngModel)]="userDefinedFunction.insideSpeedRange"></p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="Outside the speed range"
                                            [(ngModel)]="userDefinedFunction.outsideSpeedRange"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="The GSM network registered"
                                        [(ngModel)]="userDefinedFunction.gsmnetwork">
                                        </p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="The GSM network not registered"
                                            [(ngModel)]="userDefinedFunction.noGSMNetwork">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Messages need to be sent"
                                        [(ngModel)]="userDefinedFunction.messageNeedToBeSent"></p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="No messages need to be sent"
                                            [(ngModel)]="userDefinedFunction.messageNoNeedToBeSent"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="Network roaming"
                                        [(ngModel)]="userDefinedFunction.networkRoaming">
                                        </p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="Network non roaming"
                                            [(ngModel)]="userDefinedFunction.networkNonRoaming">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row align-items-center">   No es mostra al formulari
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <div *ngIf="firmware > 16" class="col">
                                            <p-checkbox binary="true" label="Network Jamming is detected"
                                                [(ngModel)]="userDefinedFunction.networkJammingIsDetected"></p-checkbox>
                                        </div>
                                    </div>
                                    <div *ngIf="firmware > 16" class="col">
                                        <p-checkbox binary="true" label="Network Jamming is not detected"
                                            [(ngModel)]="userDefinedFunction.networkJammingIsNotDetected"></p-checkbox>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" binary="true" label="SIM card is locked"
                                        [(ngModel)]="userDefinedFunction.simCardLocked">
                                        </p-checkbox>
                                    </div>
                                    <div *ngIf="firmware > 5" class="col">
                                        <p-checkbox binary="true" label="Tow status"
                                            [(ngModel)]="userDefinedFunction.towStatus"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" binary="true" label="GPS is opened"
                                        [(ngModel)]="userDefinedFunction.gnssOn">
                                        </p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="GPS is closed"
                                            [(ngModel)]="userDefinedFunction.gnssOff"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row align-items-center">       No es mostra al formulari
                                <div class="col row dvcFields">
                                    <div *ngIf="firmware > 5" class="col">
                                        <p-checkbox binary="true" label="EPS alarm actived"
                                            [(ngModel)]="userDefinedFunction.epsAlarmActivated"></p-checkbox>
                                    </div>
                                    <div *ngIf="firmware > 5" class="col">
                                        <p-checkbox binary="true" label="EPS alarm desactived"
                                            [(ngModel)]="userDefinedFunction.epsAlarmDesactivated"></p-checkbox>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row align-items-center">
                                <div class="col row dvcFields">
                                    <div class="col">
                                        <p-checkbox binary="true" label="The device is stationary"
                                        [(ngModel)]="userDefinedFunction.deviceStationary"></p-checkbox>
                                    </div>
                                    <div class="col">
                                        <p-checkbox binary="true" label="The device is moving"
                                            [(ngModel)]="userDefinedFunction.deviceMoving"></p-checkbox>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row dvcFields mt-3">
                        <form-input fieldName="Inzizo mask"></form-input>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Inside the Geo 0"
                                    [(ngModel)]="userDefinedFunction.insideGeo0">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Inside the Geo 1"
                                    [(ngModel)]="userDefinedFunction.insideGeo1">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Inside the Geo 2"
                                    [(ngModel)]="userDefinedFunction.insideGeo2">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Inside the Geo 3"
                                    [(ngModel)]="userDefinedFunction.insideGeo3">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Inside the Geo 4"
                                    [(ngModel)]="userDefinedFunction.insideGeo4">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row dvcFields">
                        <form-input fieldName="Outzizo mask"></form-input>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Outside the Geo 0"
                                    [(ngModel)]="userDefinedFunction.insideGeo0">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Outside the Geo 1"
                                    [(ngModel)]="userDefinedFunction.insideGeo1">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Outside the Geo 2"
                                    [(ngModel)]="userDefinedFunction.insideGeo2">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Outside the Geo 3"
                                    [(ngModel)]="userDefinedFunction.insideGeo3">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Outside the Geo 4"
                                    [(ngModel)]="userDefinedFunction.insideGeo4">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row dvcFields">
                        <form-input fieldName="Stocmd id mask"></form-input>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 0"
                                        [(ngModel)]="userDefinedFunction.stocmd0">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 1"
                                        [(ngModel)]="userDefinedFunction.stocmd1">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 2"
                                        [(ngModel)]="userDefinedFunction.stocmd2">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 3"
                                        [(ngModel)]="userDefinedFunction.stocmd3">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 4"
                                        [(ngModel)]="userDefinedFunction.stocmd4">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 5"
                                        [(ngModel)]="userDefinedFunction.stocmd5">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 6"
                                    [(ngModel)]="userDefinedFunction.stocmd6">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 7"
                                        [(ngModel)]="userDefinedFunction.stocmd7">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 8"
                                        [(ngModel)]="userDefinedFunction.stocmd8">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 9"
                                        [(ngModel)]="userDefinedFunction.stocmd9">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 10"
                                        [(ngModel)]="userDefinedFunction.stocmd10">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 11"
                                        [(ngModel)]="userDefinedFunction.stocmd11">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 12"
                                        [(ngModel)]="userDefinedFunction.stocmd12">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 13"
                                        [(ngModel)]="userDefinedFunction.stocmd13">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 14"
                                        [(ngModel)]="userDefinedFunction.stocmd14">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 15"
                                        [(ngModel)]="userDefinedFunction.stocmd15">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 16"
                                        [(ngModel)]="userDefinedFunction.stocmd16">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 17"
                                        [(ngModel)]="userDefinedFunction.stocmd17">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 18"
                                        [(ngModel)]="userDefinedFunction.stocmd18">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 19"
                                        [(ngModel)]="userDefinedFunction.stocmd19">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 20"
                                    [(ngModel)]="userDefinedFunction.stocmd20">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 21"
                                        [(ngModel)]="userDefinedFunction.stocmd21">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 22"
                                        [(ngModel)]="userDefinedFunction.stocmd22">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 23"
                                        [(ngModel)]="userDefinedFunction.stocmd23">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 24"
                                        [(ngModel)]="userDefinedFunction.stocmd24">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 25"
                                        [(ngModel)]="userDefinedFunction.stocmd25">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 26"
                                        [(ngModel)]="userDefinedFunction.stocmd26">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 27"
                                        [(ngModel)]="userDefinedFunction.stocmd27">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 28"
                                        [(ngModel)]="userDefinedFunction.stocmd28">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 29"
                                        [(ngModel)]="userDefinedFunction.stocmd29">
                                    </p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col row dvcFields">
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 30"
                                    [(ngModel)]="userDefinedFunction.stocmd30">
                                    </p-checkbox>
                                </div>
                                <div class="col">
                                    <p-checkbox binary="true" label="Stocmd id mask 31"
                                    [(ngModel)]="userDefinedFunction.stocmd31">
                                    </p-checkbox>
                                </div>
                                <div class="col">

                                </div>
                                <div class="col">

                                </div>
                                <div class="col">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>