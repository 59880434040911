<div class="container-fluid mt-4">
    <!-- Filters Row -->
    <div class="row justify-content-center align-items-end">

        <!-- ESTABLISHMENT DROPDOWN -->
        <div class="col-lg-2 mb-5">
            <div class="form-group row">
                <label for="groups-selector" class="custom-label" i18n>
                    <strong>{{'header.establishment' | translate}}</strong>
                </label>

                <p-multiSelect id="groups-selector" [(ngModel)]="selectedGroups" [options]="groups" optionLabel="name"
                    optionValue="id" placeholder="{{ 'header.establishment' | translate }}"></p-multiSelect>
            </div>
        </div>

        <div class="col-lg-2 mb-5">
            <div class="form-group">
                <label class="custom-label" i18n>
                    <strong>{{'control-panel.maintenance-plans.periodicity' | translate}}</strong>
                </label>

                <p-dropdown [options]="dateOptions" [(ngModel)]="selectedDateRange" (onChange)="updateDateRange()"
                    placeholder="Select Date Range" optionLabel="label" optionValue="value"></p-dropdown>
            </div>
        </div>

        <div class="col-lg-2 mb-5" *ngIf="selectedDateRange !== 'custom'">
            <div class="form-group">
                <label class="custom-label" i18n>
                    <strong>{{'general.startDate' | translate}}: </strong>
                </label>
                <input type="text" pInputText [ngModel]="formattedStartDate" (ngModelChange)="startDate = $event"
                    readonly="readonly" placeholder="Select Start Date" class="form-control" />
            </div>
        </div>

        <div class="col-lg-2 mb-5" *ngIf="selectedDateRange !== 'custom'">
            <div class="form-group">
                <label class="custom-label" i18n>
                    <strong>{{'general.endDate' | translate}}: </strong>
                </label>

                <input type="text" pInputText [ngModel]="formattedEndDate" (ngModelChange)="startDate = $event"
                    readonly="readonly" placeholder="Select Start Date" class="form-control" />
            </div>
        </div>

        <div class="col-lg-2 mb-5" *ngIf="selectedDateRange === 'custom'">
            <div class="form-group">
                <label class="custom-label" i18n>
                    <strong>{{'tableManagement.vehicleHtc.period' | translate}}</strong>
                </label>

                <app-week-picker (dateSelected)="onDateSelected($event)"></app-week-picker>
            </div>
        </div>

        <div class="col-lg-2 mb-5">
            <div class="form-group mt-4">
                <button class="btn custom-button" [disabled]="!(selectedGroups.length > 0)" (click)="applyFilters()"
                    i18n>
                    {{ 'general.filters.apply' | translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Charts Row -->
    <div class="row">
        <div class="col-lg-6 mb-4" *ngFor="let chart of charts2dData; let i = index">
            <div class="chart-container card" #chartContainer>
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5>{{ chart.title }}</h5>
                        <button type="button" class="btn custom-rt-map-detach-button"
                            (click)="toggleFullscreen(chartContainer)">
                            <svg-icon src="../../../../assets/icons/sat-detach.svg" class="d-flex flex-wrap"
                                [svgStyle]="{'height.px': 25, 'width.px': 20}"></svg-icon>
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="card-body">
                    <ngx-charts-bar-vertical-2d [view]="view" [scheme]="chart.colorScheme" [results]="chart.data"
                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true"
                        [legendTitle]="'general.legend' | translate" [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="chart.xAxisLabel"
                        [yAxisLabel]="chart.yAxisLabel">
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4" *ngFor="let chart of charts; let i = index">
            <div class="chart-container card" #basicChartContainer>
                <!-- <div class="card-header">
                    <h5 class="float-left">{{ chart.title }}</h5>
                    <button class="btn btn-primary float-right"
                        (click)="toggleFullscreen(basicChartContainer)">Fullscreen</button>
                    <div class="clearfix"></div>
                </div> -->
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5>{{ chart.title }}</h5>
                        <button type="button" class="btn custom-rt-map-detach-button"
                            (click)="toggleFullscreen(basicChartContainer)">
                            <svg-icon src="../../../../assets/icons/sat-detach.svg" class="d-flex flex-wrap"
                                [svgStyle]="{'height.px': 25, 'width.px': 20}"></svg-icon>
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="card-body">
                    <ngx-charts-bar-vertical [view]="view" [scheme]="chart.colorScheme" [results]="chart.data"
                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="chart.xAxisLabel" [yAxisLabel]="chart.yAxisLabel">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </div>
    </div>
</div>