import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';


@Component({
  selector: 'app-preserving-device-logic-states-gl320m',
  templateUrl: './preserving-device-logic-states-gl320m.component.html',
  styleUrls: ['./preserving-device-logic-states-gl320m.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PreservingDeviceLogicStatesGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
   { label: "Disable this function", value: "DISABLE"},
   { label: "Preserve special device logical state", value: "PRESERVE"},
   { label: "Reset logical states list in the <Mask>", value: "RESET"},
  ]

 
   @Input() pdsParameters: Rest.PreservingDeviceLogicStatesGL320M;
   constructor(protected i18n: I18nService) { super(i18n); }
 
   ngOnInit() {
   }
 
 
 
   ngOnChanges(changes: SimpleChanges){
     if (changes["deviceParams"]) {
       console.log("arrive deviceParams", this.pdsParameters);
     }
   }
 
 }
