<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Harsh Behavior Monitoring (AT + GTHBM)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Speed Alarm Mode" >
            <p-dropdown  (onChange)="trackEventModeChange(hbmParameters.mode,$event)" [options]="modeOptions"
            [(ngModel)]="hbmParameters.mode" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>

        <div [class.disableDIV]=!trackModeSwitch>

        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="High Speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.highSpeed" [min]="100" [max]="400"></p-spinner>
              <span class="ui-inputgroup-addon">100~400</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vhb" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vhb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vha" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vha" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Medium Speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.mediumSpeed" [min]="60" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">60~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vmb" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vmb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vma" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vma" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vlb" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vlb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="6Vla" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vla" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Cornering and breaking threshold" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.corneringThreshold" [min]="30" [max]="70"></p-spinner>
              <span class="ui-inputgroup-addon">30~70</span>
              <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Cornering and breaking duration" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.coreringDuration" [min]="40" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">40~100</span>
              <span class="ui-inputgroup-addon" >*8ms</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Acceleration threshold" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.accelerationThreshold" [min]="15" [max]="50"></p-spinner>
              <span class="ui-inputgroup-addon">15~50</span>
              <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Acceleration duration" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.accelerationDuration" [min]="50" [max]="250"></p-spinner>
              <span class="ui-inputgroup-addon">50~250</span>
              <span class="ui-inputgroup-addon" >*8ms</span>
            </div>
          </form-input>
        </div>
      </div>
      </div>
</p-accordionTab>
