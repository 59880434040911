import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../rest/JQueryHTTPClient";
import { EntityService } from "../rest/entity.service";
import { Rest } from "../rest/rest_client";
import { Severity } from "app/services/notifications-service/notifications.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ProviderService extends EntityService<Rest.Provider> {
  client: Rest.RestApplicationClient;

  constructor(
    private jquery: JQueryHTTPClient,
    private http: HttpClient,
  ) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Provider): Rest.RestResponse<Rest.Provider> {
    return this.client.Provider_create(arg0);
  }

  update(arg0: Rest.Provider): Rest.RestResponse<Rest.Provider> {
    return this.client.Provider_update(arg0);
  }

  public createProvider(provider: string, logo?: any): Promise<Rest.Provider> {
    const formData = new FormData();
    formData.append('provider', provider);
    if (logo !== undefined) {
      formData.append('logo', logo);
    }

    return this.http
      .post(environment.webApiBaseUrl + 'api/inventory/provider', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });
  }

  public updateProvider(provider: string, logo?: any): Promise<Rest.Provider> {
    const formData = new FormData();
    formData.append('provider', provider);
    if (logo !== undefined) {
      formData.append('logo', logo);
    }

    return this.http
      .post(environment.webApiBaseUrl + 'api/inventory/provider/update', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.Provider>> {
    return this.client.Provider_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Provider>> {
    return this.client.Provider_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.Provider_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.Provider> {
    return this.client.Provider_find(arg0, queryParams);
  }

  getTypes(): Rest.RestResponse<Rest.ProviderType[]> {
    return this.client.Provider_getTypes();
  }
}
