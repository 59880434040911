import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { UserService } from 'app/rest/user.service';
import { InsuranceService } from 'app/modules/vehicle-inventory/services/table-management/insurance.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { ProviderService } from 'app/rest/provider.service';
import { InsuranceTypeService } from 'app/modules/vehicle-inventory/services/table-management/InsuranceType.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';

import Insurance = Rest.Insurance;
import { UserTimePipe } from 'app/components/shared/UserTimePipe';

@Component({
  selector: 'app-insurance-form',
  templateUrl: './insurance-form.component.html',
  styleUrls: ['./insurance-form.component.css'],
  providers: [UserService, ProviderService, InsuranceTypeService, InvVehicleService],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceFormComponent
  extends EntityFormComponent<Insurance>
  implements OnInit {

  /* variables per quan es crida el component de Financing des del formulari de vehicles.
     Podrem preinformar el vehicle en el formulari de creaci� del financing */
  @Input() vehicleId?: any = null;

  noticeChannels: SelectItem[];
  channelsList: Rest.NoticeChannel[];
  title: string;
  client: Rest.Client;

  active: boolean;
  /*Permissions */
  disableSave: boolean;
  disabeChangeStatus: boolean;
  file: File;

  canSeeCosts: boolean = false;
  formManipulation: boolean = false;

  //Preservation of relationships
  vehicleRequestContext: Rest.ListPaginationRequest;
  providerRequestContext: Rest.ListPaginationRequest;
  insuranceTypeRequestContext: Rest.ListPaginationRequest;

  vehicleList: Rest.Vehicle[] = [];
  providerList: Rest.Provider[] = [];
  insuranceTypeList: Rest.InsuranceType[] = [];

  provider: Rest.Provider = null;

  ids: number[] = [];

  startDateInsurance: Date;
  endDateInsurance: Date;
  disabledDateInsurance: Date;

  constructor(
    protected confirmationService: ConfirmationService,
    private insuranceService: InsuranceService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public userService: UserService,
    public vehicleService: InvVehicleService,
    public providerService: ProviderService,
    public insuranceTypeService: InsuranceTypeService,
    private userTime: UserTimePipe
  ) {
    super(insuranceService, notificationsService, i18n, authenticationService);
  }

  ngOnInit(): void {
    this.vehicleRequestContext = RestExt.firstPageRequest();

    this.client = this.authenticationService.user.client;

    this.providerRequestContext = RestExt.firstPageRequestVehicleInventory();
    this.insuranceTypeRequestContext = RestExt.firstPageRequestVehicleInventory();

    this.setPermissions();
  }

  /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof InsuranceFormComponent
   */
  beforeShow() {
    this.active = !this.entity.disabled;
    if (this.vehicleId) {
      this.entity.vehicle = this.vehicleId;
    }

    this.ids = [];
    if (this.entity.document && this.entity.document.length > 0) {
      this.entity.document.forEach(x => this.ids.push(x.id));
    } else {
      this.ids = [-1];
    }

    if (this.isNew) {
      this.disableSave = false;
      this.title = !this.vehicleId ? this.translateService.instant('tableManagement.insurance.createInsurance') : this.vehicleId.name + " " + this.vehicleId.model?.brand?.name + " - " + this.vehicleId.model?.name + " - " + this.vehicleId.version?.name;
    } else {

      this.title = !this.vehicleId ? this.translateService.instant('tableManagement.insurance.editInsurance') : this.vehicleId.name + " " + this.vehicleId.model?.brand?.name + " - " + this.vehicleId.model?.name + " - " + this.vehicleId.version?.name;


      //#endregion PRESERVATION OF RELATIONSHIPS

      if (this.entity["_$update"] === true) {
        this.disableSave = false;
      } else {
        this.disableSave = true;
      }

      if (this.entity["_$changeStatus"] === true) {
        this.disabeChangeStatus = false;
      } else {
        this.disabeChangeStatus = true;
      }
    }/*
    if(this.vehicleId){
      this.findVehicle(this.vehicleId);
    }*/

    this.startDateInsurance = this.entity.startDate ? this.userTime.utcToCalendarDate(this.entity.startDate) : null;;
    this.endDateInsurance = this.entity.endDate ? this.userTime.utcToCalendarDate(this.entity.endDate) : null;
    this.disabledDateInsurance = this.entity.disabledDate ? this.userTime.utcToCalendarDate(this.entity.disabledDate) : null;

    //#region PRESERVATION OF RELATIONSHIPS
    //VEHICLE
    if (this.entity.vehicle) {
      this.vehicleRequestContext.filters['name'] = [this.entity.vehicle.name];
    }

    this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
      this.vehicleList = p.entities;
    });

    //PROVIDER
    this.providerRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters: {}
    } as unknown as Rest.ListPaginationRequest;

    if(this.entity.provider){
      this.providerRequestContext.filters["name"] = [this.entity.provider.name];
    }

    this.providerRequestContext.filters["type"] = [RestExt.ProviderType.Seguros.toString()];
    this.providerService.getPage(this.providerRequestContext).then((p) => {
      this.providerList = p.entities;
    });

    //INSURANCE TYPE
    this.insuranceTypeRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
    } as unknown as Rest.ListPaginationRequest;

    this.insuranceTypeService.getPage(this.insuranceTypeRequestContext).then((p) => {
      this.insuranceTypeList = p.entities;
    });
  }

  setPermissions() {
    const user = this.authenticationService.user;
    const rolesForForm = ['INV_INSURANCE_CREATE', 'INV_INSURANCE_UPDATE'];

    this.canSeeCosts = this.authenticationService.isRoleGranted('INV_INSURANCE_COSTS_READ', user);
    this.formManipulation = this.authenticationService.isOneRoleGranted(rolesForForm, user);
  }

  public showDialog(isNew: boolean) {
    this.isNew = isNew;
    this.entity = {
      id: 0,
      agent: "",
      policyNumber: "",
      cost: 0,
      fleetCost: 0,
      startDate: null,
      endDate: null,
      observations: "",
      document: null,
      disabled: false,
      disabledDate: null,
      disabledUser: null,
      vehicle: null,
      provider: null,
      insuranceType: null,
      components: null,
      client: this.client
    }
    //this.findVehicle(this.vehicleId);
    this.beforeShow();
    this.display = true;
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }

  async findVehicle(vehicleId) {
    //  console.log("[INSURANCE-FORM] findVehicle ***");
    const promise = new Promise<void>((resolve, reject) => {
      const that = this;
      this.vehicleService.findVehicle(this.vehicleId.toString())
        .then((response: Rest.Vehicle) => {
          if (response) {
            that.entity.vehicle = response;
            console.log(that.entity);
          } else {
            console.log('No vehicle!');
            resolve();
          }
        },
          /* failure */
          function (error) {
            console.log('The request failed: ' + error);
            resolve();
          })
        .catch(function (reason) {
          console.log('Catched: ' + reason);
          resolve();
        });
    });
  }

  onChangeVehicle(event) {
    this.entity.vehicle = event.value;
  }

  onFilterVehicle(event) {
    this.vehicleRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters: {
        name: [event.filter ?? '']
      },
    } as unknown as Rest.ListPaginationRequest;


    this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
      this.vehicleList = p.entities;
    });
  }

  onChangeProvider(event) {
    this.entity.provider = event.value;
  }

  onFilterProvider(event) {
    this.providerRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters: {
        name: [event.filter ?? '']
      },
    } as unknown as Rest.ListPaginationRequest;
    this.providerRequestContext.filters["type"] = [RestExt.ProviderType.Seguros.toString()];
    this.providerService.getPage(this.providerRequestContext).then((p) => {
      this.providerList = p.entities;
    });
  }

  onChangeInsuranceType(event) {
    this.entity.insuranceType = event.value;
  }

  onFilterInsuranceType(event) {
    this.insuranceTypeRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters: {
        name: [event.filter ?? '']
      },
    } as unknown as Rest.ListPaginationRequest;

    this.insuranceTypeService.getPage(this.insuranceTypeRequestContext).then((p) => {
      this.insuranceTypeList = p.entities;
    });
  }

  onKeyDownDecimal(event: any) {
    var pressedKey = event.key;
    const keyMap = new Map<number, string>();
    keyMap.set(110, ",");
    const { keyCode } = event;
    if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
      event.preventDefault();
      event.target.value += ',';
    }
  }

  saveChanges() {
    var that = this;
    this.beforeSave();
    this.entity.client = this.authenticationService.user.client;

    this.entity.components = [];

    if (this.entity.disabled) {
      this.entity.disabledUser = this.authenticationService.user;
    }

    if (this.entity.startDate === null || this.entity.startDate === undefined) {
      this.confirmationService.confirm({
        message: this.translateService.instant('tableManagement.financing.startDateEmpty'),
        accept: () => { },
      });
      return;
    }
    console.log('+++ save');

    this.entity.components = [];
    let insuranceString = JSON.stringify(this.entity);

    if (this.isNew) {
      //console.log(this.file);

      this.insuranceService.createInsurance(insuranceString, null).then((p) => {
        that.display = false;
        that.return.emit(p);
        //if (that.vehicleId) that.insuranceService.created.next(p); //si venim del form de vehicle
      });
    } else {
      this.insuranceService.updateInsurance(insuranceString, null).then((p) => {
        that.display = false;
        that.return.emit(p);
      });
    }
  }

  refresh() {
    // console.log("[INSURANCE-FORM] refresh");
    this.return.emit();
  }

  duplicate(insurance) {
    this.isNew = true;
    this.entity = {} as Rest.Insurance;
    this.entity.id = 0;
    this.entity.document = null;
    this.entity.policyNumber = insurance.policyNumber + ' (DUP)';
    this.entity.agent = insurance.agent;
    this.entity.client = insurance.client;
    this.entity.components = insurance.components;
    this.entity.cost = insurance.cost;
    this.entity.disabled = insurance.disabled;
    this.entity.disabledDate = insurance.disabledDate;
    this.entity.disabledUser = insurance.disabledUser;
    this.entity.endDate = insurance.endDate;
    this.entity.fleetCost = insurance.fleetCost;
    this.entity.insuranceType = insurance.insuranceType;
    this.entity.observations = insurance.observations;
    this.entity.provider = insurance.provider;
    this.entity.startDate = insurance.startDate;
    this.entity.vehicle = insurance.vehicle;
    this.beforeShow();
    this.display = true;
  }

  beforeSave() {
    this.entity.startDate = this.startDateInsurance;
    this.entity.endDate = this.endDateInsurance;
    this.entity.disabledDate = this.disabledDateInsurance;
  }

}
