import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Accordion } from 'primeng/accordion';
import { TabView } from 'primeng/tabview';
import { Auxiliar } from '../../../model/Auxiliar';
import { PreconfigurationService } from '../../../rest/preconfiguration.service';
import { Rest } from '../../../rest/rest_client';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { ServerValidatedFormComponent } from '../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-gl320m-parameters-form',
  templateUrl: './gl320m-parameters-form.component.html',
  styleUrls: ['./gl320m-parameters-form.component.css'],
  providers: [PreconfigurationService, ConfirmationService]
})
export class Gl320mParametersFormComponent extends ServerValidatedFormComponent
implements OnInit, OnChanges {
//View params
@Input() deviceParams: Rest.DeviceParameters; //.DeviceParameters;
@Input() firmware: RestExt.FirmwareVersion;
@Input() isNew: boolean;
@Output() GL320MParamsChange: EventEmitter<Rest.DeviceParameters> =
    new EventEmitter<Rest.DeviceParameters>();
@ViewChild('tv', { static: true }) public tabView: TabView;
@ViewChild('ac', { static: true }) accordion: Accordion;
@Input() isConfig: boolean;
@Output() refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

preconfigService: PreconfigurationService;
confirmationService: ConfirmationService;
notificationsService: NotificationsService;

firmwareValue: any;

/*Events */
selected = false;

/*PreConfigs */
preConfigsList: Rest.DevicePreconfiguration[];
availablePreconfigs: SelectItem[];
selectedPreconfig: any;
selectednpmPreconfig: any;

constructor(
    preconfigService: PreconfigurationService,
    confirmationService: ConfirmationService,
    notificationsService: NotificationsService,
    protected i18n: I18nService
) {
    super(i18n);
    this.preconfigService = preconfigService;
    this.confirmationService = confirmationService;
    this.notificationsService = notificationsService;
    //this.i18n = i18n;
}

ngOnChanges(changes: SimpleChanges) {
    if (this.isNew && this.deviceParams == null) {
        this.deviceParams = RestExt.emptyGL320MParams();
    }


    if (changes['deviceParams']) {
        this.GL320MParamsChange.emit(this.deviceParams);
    }

    RestExt.initializeGL320MParams(this.deviceParams);

}

ngOnInit(): void {
    this.initConfig();

    if (this.isConfig) {
        this.LoadPreconfigsList();
    }
}

initConfig() {
    this.preConfigsList = [];
    this.selectedPreconfig = null;
    if (this.accordion !== undefined) {
        this.tabView.activeIndex = 0;
        this.closeAllAccordionTabs();
    }
}

loadPreconfig(event) {
    this.preconfigService
        .find(event.value.id)
        .then((pre: Rest.DevicePreconfiguration) => {
            this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
        });
}

LoadPreconfigsList() {
    let paginationRequest: Rest.ListPaginationRequest;
    this.availablePreconfigs = [];
    this.selectedPreconfig = null;
    this.preConfigsList = [];
    const that = this;
    paginationRequest = RestExt.firstPageRequest();
    paginationRequest.sortAsc = false;
    paginationRequest.filters = {
        firmware: [this.firmware + ''],
    };

    this.preconfigService.getPage(paginationRequest).then(function (response) {
        that.preConfigsList = response.entities;
        that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(
            response.entities
        );
    });
}

closeAllAccordionTabs() {
    if (!this.isNullOrUndefined(this.accordion.tabs)) {
        for (const tab of this.accordion.tabs) {
            if (tab.selected) {
                tab.selected = false;
            }
        }
    }
}

isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === 'undefined' || obj === null;
}

public getGL320MDeviceParams(): any {
    return this.deviceParams;
}

fillPreConfig(newParams: Rest.DeviceParameters) {
    //to load the values of the preconfig events but not the id assigned
    //remove id because if is from preconfig we have to assign as new sensors to config

    //to load the values of the preconfig events but not the id assigned
    newParams.id = this.deviceParams.id;

    // eslint-disable-next-line guard-for-in
    for (const a in newParams.sensors) {
        delete newParams.sensors[a]['id'];
    }

    // eslint-disable-next-line guard-for-in
    for (const a in newParams.actuators) {
        delete newParams.actuators[a]['id'];
    }

    //Load the rest preconfig parameters to GL320M config

    this.deviceParams.proprietaryParameters.type = 'GL320M';
    this.deviceParams = newParams;


    // eslint-disable-next-line guard-for-in
    for (const a in this.deviceParams.sensors) {
        this.deviceParams.sensors[a].parameters = null; //configTmp;
    }
    // eslint-disable-next-line guard-for-in
    for (const a in this.deviceParams.actuators) {
        this.deviceParams.actuators[a].parameters = null; //configTmp;
    }
}

tabChanged(){

}



}
