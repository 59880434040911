import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';

@Injectable({
  providedIn: 'root'
})
export class VehicleAssignmentsService extends EntityService<Rest.InvVehicleAssignment> {

	client: Rest.RestApplicationClient;
	selectDriver = new Subject();
	driverSelected = new Subject<Rest.InvDriverAssignment>();
	refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: any): Rest.RestResponse<Rest.InvVehicleAssignment> {
		return this.client.InvVehicleAssignments_create(arg0);
    }

    update(arg0: any): Rest.RestResponse<Rest.InvVehicleAssignment> {
        return this.client.InvVehicleAssignments_update(arg0);
    }

    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvVehicleAssignment>> {
        return this.client.InvVehicleAssignments_getPage(arg0);
    }

 	 getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.InvVehicleAssignment>> {
        return this.accessory.InvVehicleAssignments_getPageAndFilters(arg0);
    }

    changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
        return this.client.InvVehicleAssignments_changeStatus(arg0, queryParams);
    }

    find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.InvVehicleAssignment> {
        return this.client.InvVehicleAssignments_find(arg0, queryParams);
    }

	 getList(clientId: number): Rest.RestResponse<Rest.InvVehicleAssignment[]> {
		return this.client.InvVehicleAssignments_getList(clientId);
    }

}
