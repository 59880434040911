import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { TabView } from "primeng/tabview";
import { NoticegroupService } from "../../../rest/noticegroup.service";
import { Rest } from "../../../rest/rest_client";
import { UserService } from "../../../rest/user.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { FleetTreeComponent } from "../../fleet-edit-view/fleet-tree/fleet-tree.component";

@Component({
    selector: "app-notice-group-form",
    templateUrl: "./notice-group-form.component.html",
    styleUrls: ["./notice-group-form.component.css"],
    providers: [ConfirmationService, UserService],
})
export class NoticeGroupFormComponent
    extends EntityFormComponent<Rest.NoticeGroup>
    implements OnInit {
    @ViewChild("tv") public tabView: TabView;

    noticeGroupFormTitle: string;

    channelsList: Rest.NoticeChannel[];

    fleetsGroupsAndVehiclesSelected: object[];

    noticeGroup: Rest.NoticeGroup;

    userRequestContext: Rest.ListPaginationRequest;

    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    // TODO: Fields "showStatusDialog", "closeStatusDialog", "selectedEntities" and "title" were missing from legacy code!!!
    showStatusDialog: any;
    title: any;
    selectedEntities: [];

    constructor(
        private noticegroupService: NoticegroupService,
        notificationsService: NotificationsService,
        i18n: I18nService,
        private userService: UserService,
        authenticationService: AuthenticationService
    ) {
        super(
            noticegroupService,
            notificationsService,
            i18n,
            authenticationService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.noticeGroup = {
            notifyByApp: false,
            notifyByEmail: false,
            notifyBySMS: false,
            notifyByWeb: false,
        } as Rest.NoticeGroup;
        this.fleetsGroupsAndVehiclesSelected = [];
    }

    beforeShow() {
        this.userRequestContext = RestExt.firstPageRequest();
        //this.userRequestContext.sortBy = "name";
        this.userRequestContext.context = {
            entity: "NOTICE_GROUP",
            action: "",
            id: 0,
        };

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.entity.notifyByApp = false;
            this.entity.notifyByEmail = false;
            this.entity.notifyBySMS = false;
            this.entity.notifyByWeb = false;
        } else {
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }

            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */

        this.fleetsGroupsAndVehiclesSelected = [];
        //this.tabView.tabs[0].selected = true;
        //this.tabView.tabs[1].selected = false;

        if (this.isNew) {
            this.noticeGroupFormTitle = this.i18n._("Create Notice Group");
        } else {
            this.noticeGroupFormTitle = this.i18n._("Edit Notice Group");
            this.noticegroupService
                .find(this.entity.id + "")
                .then((resp: Rest.NoticeGroup) => {
                    //this.entity = resp;

                    if (resp.visibleFleets != null) {
                        resp.visibleFleets.forEach((d: Rest.Fleet) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    if (resp.visibleVehicles != null) {
                        resp.visibleVehicles.forEach((d: Rest.Vehicle) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    if (resp.visibleVehiclesGroups != null) {
                        resp.visibleVehiclesGroups.forEach((d: Rest.VehiclesGroup) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    this.fleetsGroupsAndVehiclesSelected = [
                        ...this.fleetsGroupsAndVehiclesSelected,
                    ];
                });
        }
    }

    beforeSave() {
        this.entity.visibleFleets = [];
        this.entity.visibleVehiclesGroups = [];
        this.entity.visibleVehicles = [];
        this.fleetsGroupsAndVehiclesSelected.forEach((o: object) => {
            if (FleetTreeComponent.isFleet(o)) {
                this.entity.visibleFleets.push(o as Rest.Fleet);
            }
            if (FleetTreeComponent.isVehicle(o)) {
                this.entity.visibleVehicles.push(o as Rest.Vehicle);
            }
            if (FleetTreeComponent.isVehicleGroup(o)) {
                this.entity.visibleVehiclesGroups.push(o as Rest.VehiclesGroup);
            }
        });
    }

    closeStatusDialog($event: any) {
        // TODO: Implement!
    }

    refreshForm($event: any) {
        // TODO: Implement!
    }
}
