<p-accordionTab>
   <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>rfDetected Event Configuration</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)"  [(ngModel)]="rfDetectedEventConfig.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>
  <div class="ui-g" [class.disableDIV]=!rfDetectedEventConfig.enabled>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Port" [errors]="errors.port">
        <p-spinner size="5" [(ngModel)]="rfDetectedEventConfig.port" [min]="1" [max]="4"></p-spinner>
        <span class="ui-inputgroup-addon">1~4</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Delay" [errors]="errors.delay">
        <p-spinner id="rfDetectedEventConfig" size="5" [(ngModel)]="rfDetectedEventConfig.delay" [min]="0" [max]="65535"></p-spinner>
        <span class="ui-inputgroup-addon">0~65535</span>
        <span class="ui-inputgroup-addon" i18n>ms</span>
      </form-input>
    </div>
  </div>
</p-accordionTab>
