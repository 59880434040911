import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { environment } from "environments/environment";
import { CercaliaMapService } from "../../../../services/cercalia-map/cercalia-map.service";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";

@Component({
  selector: "app-vehicle-proximity-dialog",
  templateUrl: "./vehicle-proximity-dialog.html",
  styleUrls: ["./vehicle-proximity-dialog.css"],
  encapsulation: ViewEncapsulation.None,
})
export class VehicleProximityDialog implements OnInit {
  header = "";
  env = environment;
  lblSearchProximity = "";
  punto = "";
  loading = false;
  PopUpOpen = false;
  marker: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShowPosition = new EventEmitter<any>();
  @Input() vehicleInfo: Array<RestExt.ExtendedVehicleDataMessage>;
  obj: Rest.proximityVehicleDialog[] = [];
  rows: Rest.proximityVehicleDialog[] = [];
  cols: any[] = [
    {
      header: this.i18n._("ORDEN"),
      field: "id",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
    {
      header: this.i18n._("FLOTA"),
      field: "fleetName",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
    {
      header: this.i18n._("GRUPO"),
      field: "groupName",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
    {
      header: this.i18n._("DESCRIPCIÓN"),
      field: "vehicleName",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
    {
      header: this.i18n._("DISTANCIA"),
      field: "distance",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
    {
      header: this.i18n._("TIEMPO"),
      field: "time",
      visible: true,
      filter: false,
      sortable: true,
      width: "10px",
    },
  ];

  constructor(
    public cercaliaService: CercaliaMapService,
    protected i18n: I18nService
  ) {}

  ngOnInit() {}

  async show(data: any) {
    this.rows = [];
    this.header = this.i18n._("INFORMACION");
    this.punto = data.x.toFixed(5) + "/" + data.y.toFixed(5);
    this.lblSearchProximity =
      this.i18n._(
        "Vehículos más cercanos según routing por tiempo al punto: "
      ) + " ";
    this.PopUpOpen = true;
    await this.loadData(data);
  }

  async loadData(data: any) {
    this.loading = true;
    if (data.mos.length > 0) {
      var result = await this.cercaliaService.getProximity(data);

      var mo = result.cercalia.proximity.molist.mo;

      for (var i = 0; i < mo.length; i++) {
        var dist = (mo[i].routedist / 1000).toFixed(3).replace(".", ",");
        var seconds = Math.floor((mo[i].routetime / 1000) % 60);
        var minutes = Math.floor((mo[i].routetime / (1000 * 60)) % 60);
        var hour = Math.floor((mo[i].routetime / (1000 * 60 * 60)) % 24);

        var vehicle = this.vehicleInfo.find((x) => x.vehicleId == mo[i].id);

        var routetime =
          (hour < 10 ? "0" + hour : hour) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds);
        this.obj[i] = {
          id: mo[i].pos,
          fleetName: vehicle.fleetName,
          groupName: vehicle.groupName,
          vehicleName: vehicle.vehicleName,
          distance: dist,
          time: routetime,
        };
      }

      this.rows = this.obj;
    }

    this.loading = false;
  }
  //<h2>` + this.pois[i].name + `</h2>
  //<p>` + this.pois[i].address + `</p>
  //<span style="font-size:0.8em;"> ` + this.pois[i].observations + `</span>
  showMarker() {
    var point = this.punto.split("/");
    const marker = new this.cercaliaService.cercalia.Marker({
      position: new this.cercaliaService.cercalia.LonLat(point[0], point[1]),
      popup: new this.cercaliaService.cercalia.Popup({
        title: "Destino",
        content:
          '<div style="width:100px"><b>Longitud: </b>' +
          point[0] +
          " <b>Latitud: </b> " +
          point[1] +
          "</div>",
        minWidth: 50,
        maxWidth: 150,
        visible: true,
      }),
    });
    this.marker = marker;

    this.onShowPosition.emit(this.marker);
  }

  closeButton() {
    this.PopUpOpen = false;
  }
}
