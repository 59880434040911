import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { ClientService } from "../../../../../../rest/client.service";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { SubdelegationFormComponent } from "../subdelegation-form/subdelegation-form.component";
import { SubdelegationService } from "app/rest/subdelegation.service";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

import Subdelegation = Rest.Subdelegation;

@Component({
    selector: 'app-subdelegation-list',
    templateUrl: './subdelegation-list.component.html',
    styleUrls: ['./subdelegation-list.component.css'],
    providers: [ConfirmationService, UserTimePipe, ExcelService],
})
export class SubdelegationListComponent
    extends EntityListComponent<Subdelegation>
    implements OnInit {
    title: string = this.i18n._("Subdelegations");

    @ViewChild(SubdelegationFormComponent, { static: true })
    subdelegationForm: SubdelegationFormComponent;

    disableSave: boolean;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    constructor(
        private subdelegationService: SubdelegationService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
    ) {
        super(
            subdelegationService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.subdelegationService = subdelegationService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.subdelegationForm;
        this.hasEntityFilters = false;
    }

    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }
    afterLoad() {
    }

    excelColumns = {};
    setExcelColumns() {
        const code = (entity: any) => entity.code ?? ''
        const name = (entity: any) => entity.name ?? ''
        const fleetManager = (entity: any) => entity.fleetManager?.name ?? ''
        const serviceManager = (entity: any) => entity.serviceManager?.name ?? ''
        const zoneChief = (entity: any) => entity.zoneLeader?.name ?? ''
        const delegation = (entity: any) => entity.zoneLeader?.name ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''

        this.excelColumns = {
            [this.translateService.instant('general.code')]: { bindField: code },
            [this.translateService.instant('general.name')]: { bindField: name },
            [this.translateService.instant('tableManagement.delegations.fleetManager')]: { bindField: fleetManager },
            [this.translateService.instant('tableManagement.delegations.serviceManager')]: { bindField: serviceManager },
            [this.translateService.instant('tableManagement.delegations.zoneChief')]: { bindField: zoneChief },
            [this.translateService.instant('tableManagement.delegations.delegation')]: { bindField: delegation },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }
    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.subdelegations.subdelegationsManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }
}
