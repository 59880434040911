import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { VehicleStatusComponent } from "../vehicle-status/vehicle-status.component";
import { VehicleStatusService } from "app/modules/vehicle-inventory/services/vehiclestatus.service";
import { ExcelService } from "app/services/excel-service/excel.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-status-list',
  templateUrl: './vehicle-status-list.component.html',
  styleUrls: ['./vehicle-status-list.component.css'],
  providers: [VehicleStatusService, ExcelService],
})
export class VehicleStatusListComponent extends EntityListComponent<Rest.VehicleStatus>
implements OnInit {

@ViewChild(VehicleStatusComponent, { static: true })
editForm: VehicleStatusComponent;

title: string = this.i18n._("Clients");
disableSave: boolean;
user: Rest.User;
EnabledFilter: SelectItem[];

constructor(
    private vehicleStatusService: VehicleStatusService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected translateService: TranslateService,
) {
    super(
        vehicleStatusService,
        confirmationService,
        notificationsService,
        i18n,
        authenticationService,
        loadingService,
        userTime,
        excelService
    );

}

ngOnInit() {
    super.ngOnInit();
    this.form = this.editForm;
    this.hasEntityFilters = false;
    this.user = this.authenticationService.user;
}
excelColumns = {};
exportExcel(){
    this.setExcelColumns();
    let fileNameTitle = this.translateService.instant('tableManagement.vehicleStatus.vehicleStatus');
    super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
}
setExcelColumns() {
    const code = (entity: any) => entity.code ?? ''
    const name = (entity: any) => entity.name ?? ''
    const color = (entity: any) => entity.color ?? '' 
    this.excelColumns = {
    //    [this.translateService.instant('tableManagement.vehicleStatus.table.code')]:{ bindField: code },
        [this.translateService.instant('tableManagement.vehicleStatus.table.name')]: { bindField: name },  
        [this.translateService.instant('tableManagement.vehicleStatus.table.color')]: { bindField: color },
    }
}

/*
afterLoad() {
    this.vehicleUsageService.getPage(this.paginationRequest).then((p) => {
        this.paginationResult = p;
    });
    // this.EnabledFilter = RestExt.getEnabledFilter(
    //     this.filters["enabled"],
    //     this.i18n
    // );
    // this.paginationResult.entities.forEach((cl: any) => {
    //     /*Permissions */
    //     cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
    //     cl._$update = true;
    //     /** */
    // });
    // /*Permissions */
    // this.checkSelectAll();
    /** 
}*/
}
