<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">


		<div #errorContainer>
			<div *ngIf='errorMessages.length !== 0'>
				<div *ngFor='let error of errorMessages'>
					<div class='alert alert-warning' role='alert'>{{error}}</div>
				</div>
			</div>
		</div>

        <!-- NUMBER & TYPE & VEHICLE -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    <strong>{{'tableManagement.cardLists.number' | translate}} (*)</strong>
                </label>
                <div class="p-3"></div>
                <input type="text" id="card-number" class="form-control custom-input" [(ngModel)]="entity.number" [disabled]="!isNew || saving"
					 	[style]="{'width':'250px'}">
            </div>

            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
						  <strong>{{'control-panel.vehicles.vehicleType' | translate}} (*)</strong>
                </label>
                <div class="p-3"></div>
                <p-dropdown [options]='typesList' [(ngModel)]='entity.type'
                    placeholder="{{'control-panel.vehicles.vehicleType' | translate}}" dataKey='id'
                    optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true" filterBy="name" [disabled]="saving">
                    <!-- (onChange)="onChangeStatus($event)" (onFilter)="onFilterStatus($event)"> -->
                </p-dropdown>
            </div>

            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
						  <strong>{{'tableManagement.cardLists.vehicle' | translate}} (*)</strong>
                </label>
                <div class="p-3"></div>
                <p-dropdown [options]='vehicleList' [(ngModel)]='entity.vehicle' [placeholder]="vehiclePlaceholder"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                    filterBy="name" (onChange)="onChangeVehicle($event)" (onFilter)="onFilterVehicle($event)"
						  [readonly]="vehicle" [disabled]="saving">
                </p-dropdown>
            </div>
        </div>

        <!-- EXPIRATION DATE & DEASSIGN DATE -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
						  <strong>{{'tableManagement.cardLists.expirationDateShort' | translate}} (*)</strong>
                </label>
                <div class="p-3"></div>
                <p-calendar [(ngModel)]='expirationDate' [showTime]='false' [style]="{'width':'200px'}"
                    dateFormat='{{i18n.dateFormat}}' showIcon='true' appendTo='body' [firstDayOfWeek]="1" [disabled]="saving"></p-calendar>
            </div>

            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    {{'tableManagement.cardLists.deassignDateShort' | translate}}
                </label>
                <div class="p-3"></div>
                <p-calendar [(ngModel)]='deassignDate' [showTime]='true'
                    [style]="{'width':'200px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
                    appendTo='body'  [firstDayOfWeek]="1" [disabled]="saving"></p-calendar>
            </div>

            <div class="col d-flex flex-row align-items-center">
            </div>
        </div>

        <!-- OBSERVATIONS -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    {{'general.observations' | translate}}
                </label>
                <div class="p-3"></div>
                <textarea rows="2" cols="80" pInputTextarea [pTooltip]="entity.observations" tooltipPosition="top"
                    [(ngModel)]="entity.observations" class="observations" fitContent="true" [disabled]="saving"></textarea>
            </div>
        </div>
        <!-- ACTIVE -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    {{'general.active' | translate }}
                </label>
                <div class="p-3"></div>
                <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="active" [disabled]="true">
            </div>
        </div>

        <div *ngIf="!isNew" class="mt-3">
            <app-cardConsumption-list [card]="entity">
            </app-cardConsumption-list>
        </div>
    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew && canCreate">
                    <button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
                        {{ saving ? ('general.saving' | translate) : ('general.create' | translate) }}
                    </button>
                </div>

                <div *ngIf="!isNew && canUpdate">
                    <button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="saving">
                        {{ saving ? ('general.saving' | translate) : ('general.save' | translate) }}
                    </button>
                </div>
            </div>

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew && canChangeStatus">
                    <button class="btn custom-button" (click)="changeStatus(active)" i18n [disabled]="saving">
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>
