<nav class="navbar navbar-expand-lg navbar-light bg-light justify-content-center">
    <div class="navbar-nav">
        <div class="nav-item">
            <a class="nav-link step-item active-step">
                1. {{'reportsWizard.header.reportType' | translate}}
            </a>
        </div>
        <div class="nav-item">
            <a class="nav-link step-item" [class.active-step]="phase === 2 || phase === 3">
                2. {{'reportsWizard.header.entities' | translate}}
            </a>
        </div>
        <div class="nav-item">
            <a class="nav-link step-item" [class.active-step]="phase === 3">
                3. {{'reportsWizard.header.timeFrame' | translate}}
            </a>
        </div>
    </div>
</nav>