import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-time-adjustment-gl320m',
  templateUrl: './time-adjustment-gl320m.component.html',
  styleUrls: ['./time-adjustment-gl320m.component.css'],

})
export class TimeAdjustmentGl320mComponent extends ServerValidatedFormComponent implements OnInit {

  signOptions: any = [
    { label: "+", value: "PLUS" },
    { label: "-", value: "MINUS" },
  ];
  
  @Input() tmaParameters: Rest.TimeAdjustmenGL320M;
  
  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.tmaParameters);
    }
  }
}
