import { Rest } from "app/rest/rest_client";

export class PoCsCurrentStatusRow {
    controlPoint: Rest.Zone
    lastMaintenanceDate: number // Milliseconds
    maintenanceLeftTime: number // Milliseconds
    lastVigilanceDate: number // Milliseconds
    vigilanceLeftTime: number // Milliseconds
    minorTimeRemaining: number // Milliseconds
    vehicle: BasicInfo
    group: BasicInfo
    color:string
}

export class BasicInfo{
    id: number
    name: string
}
export class ControlPoint extends BasicInfo{
    location:Rest.Vertex
}

export class PoCHistoryRow {
    visitDate: number
    vehicle: BasicInfo
    group: BasicInfo
    endVisitDate: number
    durationVisit: String
    kmVisit: number
}

export class VehicleHistoryRow {
    visitDate: number
    controlPoint: Rest.Zone
}
