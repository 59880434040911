import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { environment } from 'environments/environment';
import { Severity } from 'app/services/notifications-service/notifications.service';
import { HttpClient } from '@angular/common/http';

import VehicleBrand = Rest.VehicleBrand;

@Injectable()
export class VehiclebrandService extends EntityService<VehicleBrand>{
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient, private http: HttpClient) {
    super();

    this.client = new Rest.RestApplicationClient(jquery);
  }

  public createBrand(entity: string, logo?: any): Promise<Rest.VehicleBrand> {

    /* FORM DATA */
    const formData = new FormData();
    formData.append('logo', logo);
    formData.append('vehicleBrand', entity);


    return this.http  
      .post(environment.webApiBaseUrl + 'api/vehicleBrand', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });

  }

  public updateBrand(brand: string, logo?: any): Promise<Rest.VehicleBrand> {

    /* FORM DATA */
    const formData = new FormData();
    formData.append('vehicleBrand', brand);

    if (logo != undefined && logo != null) {
      formData.append('logo', logo);
    }

    return this.http
      .post(environment.webApiBaseUrl + 'api/vehicleBrand/update', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });

  }

  changeStatus(
    arg0: number[], 
    queryParams?: { id?: number, status?: boolean; }
    ): Rest.RestResponse<void> {
      return this.client.VehicleBrand_changeStatus( arg0, queryParams);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { clientId?: number }
  ): Rest.RestResponse<Rest.Page<Rest.VehicleBrand>> {
    return this.client.VehicleBrand_getPage(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.VehicleBrand> {
    return this.client.VehicleBrand_find(arg0, queryParams);
  }

}