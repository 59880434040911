<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible'
          header='{{ spaceFormTitle }}'
          [(visible)]='isDialogVisible'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [style]="{'width': '95%'}"
>
   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

	<!-- SECCION 1 -->
	<div class='d-flex flex-column gap-3 p-3'>
		<app-campaign-form
			[(isNew)]="isNew"
			[(space)]="space"
		></app-campaign-form>
	</div>

	<!-- SECCION 3 -->
	<div *ngIf="showRoutes" class='d-flex flex-column gap-3 p-3'>
		<!-- ROUTES LIST FORM -->
		<app-routes-list-form
			[(space)]="space"
		></app-routes-list-form>
	</div>
	<div *ngIf="!showRoutes" text-align: center>
		<div class='alert alert-warning' role='alert'
			  style="background-color: lightyellow;
					   color: rgb(0, 0, 0);
						text-align: center">
			{{ 'promotedRoutes.showRoutes' | translate }}
		</div>
	</div>

	<!-- PIE DE PAGINA -->
	<p-footer>
   <div class='d-flex flex-row-reverse bd-highlight'>
	    	<div class='p-2 bd-highlight'>
        		<button type='button' class='btn custom-button' (click)='saveSpace()' [disabled]='isEditingDisabled'>
	          	{{ 'general.save' | translate }}
       		</button>
   		</div>
 	</div>

	</p-footer>

</p-dialog>


