import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from "@angular/core";
import * as moment from "moment";
import {JQueryHTTPClient} from "../../../../../rest/JQueryHTTPClient";
import {ConfirmationService} from "primeng/api";
import {Rest} from "../../../../../rest/rest_client";
import {RealtimeService} from "../../../../../rest/realtime.service";
import {I18nService} from "../../../../../services/i18n/i18n.service";
import {RestExt} from "../../../../../services/rest-client-extension";
import {RealTimeDataService} from "../../../../../services/rt-data/rt-data.service";
import {UserTimePipe} from "../../../../shared/UserTimePipe";
import { AuthenticationService } from '../../../../../core/services/authentication/authentication.service';

@Component({
  selector: "app-vehicle-telemetry",
  templateUrl: "./vehicle-telemetry.component.html",
  styleUrls: ["./vehicle-telemetry.component.css"],
  providers: [RealtimeService, ConfirmationService, UserTimePipe],
})
export class VehicleTelemetryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: Rest.VehicleDetailsSettings;
  @Input() vehicle: Rest.Vehicle;
  info: RestExt.ExtendedVehicleDataMessage;

  deviceColors: { [key: string]: string }; //Color associated to each device id
  visibleDevices: { [key: string]: boolean }; //Indicates if the sensor is visible
  liveView: boolean = true;
  waitingHistoricalData = false; //Indicates that the component is waiting for historical data from the server and the incoming realtime data should be discarded

  numRecordsSafetyLimit = 1000; //If user requests mor than this number of records, show a warning and let the user decide if fetch the data or not.
  maxNumRecords = 5000; //User can't request more than this amount of data. If he tries, show an informative message but don't load the data.
  startDate: Date;
  endDate: Date;

  timeRangeUpdatedTimoutHandler: any; //When user updates the date/time input, prevent inmediate update to let the user set the final value
  defaultHistoryMinutes = 60 * 10; //When entering liveView, defaultHistoryMinutes of past data will be loaded
  private alive: boolean = true;
  isUserHosp: boolean = false;

  chartData: any;
  chartOptions = {
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
  };

  timestamps: number[]; //List containing the timestamps for the sensor lectures stores in sensor values
  labels: string[]; //List containing the labels for the plot
  deviceValues: { [key: number]: number[] }; //For each deviceId ([s/a]+id, contains a list of sensor numeric values associated with that sensor. Each N sensor value is the reading for the sensor on the instant represented by the date stored in the timestamps array at the N position
  constructor(
    private i18n: I18nService,
    private rtService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private confirmationService: ConfirmationService,
    private userTime: UserTimePipe,
    private jquery: JQueryHTTPClient,
    private authenticationService: AuthenticationService
  ) {
    this.timestamps = [];
    this.labels = [];
    this.deviceValues = {};
    this.deviceColors = {};
    this.visibleDevices = {};
    if(this.authenticationService.user.id == 277718){
      this.isUserHosp = true;
    }
  }

  ngOnInit() {
    this.info = {} as any;
    //Each time the vehicle info is updated, record the sensor values
    this.realTimeDataService.vehicleInfo
      .takeWhile(() => this.alive)
      .subscribe((v: RestExt.ExtendedVehicleDataMessage) => {
        if (!this.liveView) return;
        if (this.vehicle == null || v.vehicleId != this.vehicle.id) return;
        //Calculate the number of records that must be removed in order to keep the historical data as much as maxHistoryLength long.
        let recordsToRemove = 0;
        recordsToRemove = this.timestamps.length + 1 - this.maxNumRecords;
        if (this.waitingHistoricalData || !this.liveView) return; //Exit if no matching
        v.sensors.forEach((s: RestExt.ExtendedDValue) => {
          if (this.deviceValues["s" + s.device.id] == null)
            this.deviceValues["s" + s.device.id] = [];
          this.deviceValues["s" + s.device.id].push(s.numericValue);
          if (recordsToRemove > 0)
            this.deviceValues["s" + s.device.id].splice(0, recordsToRemove);
        });
        v.actuators.forEach((a: RestExt.ExtendedDValue) => {
          if (this.deviceValues["a" + a.device.id] == null)
            this.deviceValues["a" + a.device.id] = [];
          this.deviceValues["a" + a.device.id].push(a.numericValue);
          if (recordsToRemove > 0)
            this.deviceValues["a" + a.device.id].splice(0, recordsToRemove);
        });

        /**
         * Append a new label to the list with the string representation of the new date.
         * For the first date of the day/month/year use a long format
         * For the consecutive dates, use a short format
         */
        let lastDate =
          this.timestamps.length > 0
            ? new Date(this.timestamps[this.timestamps.length - 1])
            : null;
        let currentDate = new Date(v.deviceTime);
        if (
          lastDate == null ||
          lastDate.getDay() != currentDate.getDay() ||
          lastDate.getFullYear() != currentDate.getFullYear() ||
          lastDate.getMonth() != currentDate.getMonth()
        ) {
          this.labels.push(this.userTime.transform(v.deviceTime, "short"));
        } else {
          this.labels.push(this.userTime.transform(v.deviceTime, "mediumTime"));
        }

        this.timestamps.push(v.deviceTime);
        if (recordsToRemove > 0) {
          this.timestamps.splice(0, recordsToRemove);
          this.labels.splice(0, recordsToRemove);
        }

        this.updatePlot();
      });
    this.toggleLiveView();
  }

  /**
   * Test if the number of records associated to the sensors for the given timespan can be displayed without problems. If so, proceed. If data is > maxNumRecords inform the user. If data > numRecordsSafetyLimit warn the user.
   * Make a call to the WS to retrieve the sensor readings from the given start date to the end date.
   * @param start
   * @param end
   */
  tentativeLoadSensorData(start: Date, end: Date) {
    this.timestamps = [];
    this.labels = [];
    this.deviceValues = {};
    this.waitingHistoricalData = true;

    this.doLoadSensorData(start, end);
    /*this.rtService.countVehicleReadings(this.vehicle.id + "", { start: (start.toISOString() as any), end: (end.toISOString() as any) }).then((count: number) => {

            //Too much data requested, can't show it
            if (count > this.maxNumRecords) {
                this.confirmationService.confirm({
                    message: this.i18n._('The requested dataset length ({0}) exceeds the allowed limit ({1}). Please, reduce the requested timespan', count + "", this.maxNumRecords + ""),
                    header: this.i18n._('Too much data'),
                    icon: 'fa fa-ban',
                    acceptVisible: false,
                    accept: () => {
                        this.doLoadSensorData(start,end);
                    },
                });
                return;
            }

            //A lot of data requested, warn about it
            if (count > this.numRecordsSafetyLimit) {
                this.confirmationService.confirm({
                    message: this.i18n._('The requested dataset length ({0}) is quite big. Displaying it might cause performance issues. Do you want to proceed?', count + ""),
                    header: this.i18n._('A lot of data'),
                    icon: 'fa fa-exclamation-triangle',
                    acceptVisible: true,
                    accept: () => {
                        this.doLoadSensorData(start,end);
                    },
                     reject: () => {

                    },
                });
                return;
            }

            //The data can be loaded
            this.doLoadSensorData(start,end);


        });*/
  }

  doLoadSensorData(start: Date, end: Date) {
    this.waitingHistoricalData = true;

    var promise: Rest.RestResponse<{
      [index: string]: Rest.SimpleDeviceValue[];
    }>;
    //In live view mode, request RT server. Else request coordination module
    if (this.liveView)
      promise = this.jquery.request({
        method: "GET",
        url: RestExt.uriEncoding`telemetry`,
        queryParams: {vid: this.vehicle.id, maxCount: 10},
        data: null,
        headers: false,
        cmREST: false,
      });
    else
      promise = this.rtService.getVehicleReadings(this.vehicle.id + "", {
        start: start.toISOString() as any,
        end: end.toISOString() as any,
      });

    promise
      .then((data: { [index: string]: Rest.SimpleDeviceValue[] }) => {
        if (!data) data = {};
        let ids = this.vehicle.device.configuration.parameters.sensors
          .map((s) => "s" + s.id)
          .concat(
            this.vehicle.device.configuration.parameters.actuators.map(
              (s) => "a" + s.id
            )
          );
        //Historical data might be unaligned (i.e. not all sensors have readings for all timestamps). Plot requireds data to be aligned. We must fill missing blanks with null values
        //Get the complete set of all the timestamps form all devices
        let allTimestamps = [];
        ids.forEach((id) => {
          if (data[id])
            data[id]
              .map((v) => v.timestamp)
              .forEach((d) => allTimestamps.push(d.getTime()));
        });
        allTimestamps = Array.from(new Set(allTimestamps));
        allTimestamps = allTimestamps.sort((a, b) => {
          return a - b;
        });
        this.timestamps = allTimestamps;

        /**
         * Construct the labels for the timestamps
         */
        this.labels = [];
        let dates = this.timestamps.map((ts) => new Date(ts));
        if (this.timestamps.length > 0) {
          this.labels.push(
            this.userTime.transform(this.timestamps[0], "short")
          );
          let i = 0;
          for (i = 1; i < this.timestamps.length; i++) {
            if (
              dates[i - 1].getDay() != dates[i].getDay() ||
              dates[i - 1].getFullYear() != dates[i].getFullYear() ||
              dates[i - 1].getMonth() != dates[i].getMonth()
            ) {
              this.labels.push(
                this.userTime.transform(this.timestamps[i], "short")
              );
            } else {
              this.labels.push(
                this.userTime.transform(this.timestamps[i], "mediumTime")
              );
            }
          }
        }

        let operation = (id: number, letter: string) => {
          //Find the historical data associated to the device
          let originalValues: Rest.SimpleDeviceValue[] = data[letter + id]
            ? data[letter + id]
            : [];
          let key = letter + id;
          //Extract all the timestamps for this device
          let timestamps = originalValues.map((v) => v.timestamp.getTime());
          //Find the missing time stamps
          let missingTimestamps = allTimestamps.filter(
            (t) => !timestamps.includes(t)
          );
          //Add the missing data
          missingTimestamps.forEach((t) =>
            originalValues.push(<Rest.SimpleDeviceValue>{
              timestamp: t,
              numericValue: null,
              categoricValue: null,
            })
          );
          originalValues = originalValues.sort((a: any, b: any) => {
            return a.timestamp - b.timestamp;
          });

          this.deviceValues[key] = originalValues.map(
            (v: Rest.SimpleDeviceValue) => {
              return v.numericValue;
            }
          );
        };

        this.vehicle.device.configuration.parameters.sensors.forEach(
          (s: Rest.Sensor) => {
            operation(s.id, "s");
          }
        );
        this.vehicle.device.configuration.parameters.actuators.forEach(
          (a: Rest.Actuator) => {
            operation(a.id, "a");
          }
        );

        this.updatePlot();

        this.waitingHistoricalData = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  timeRangeUpdated() {
    if (this.timeRangeUpdatedTimoutHandler != null)
      clearTimeout(this.timeRangeUpdatedTimoutHandler);
    this.timeRangeUpdatedTimoutHandler = null;
    this.timeRangeUpdatedTimoutHandler = setTimeout(
      () =>
        this.tentativeLoadSensorData(
          this.userTime.calendarDateToUTC(this.startDate),
          this.userTime.calendarDateToUTC(this.endDate)
        ),
      5000
    );
  }

  toggleLiveView() {
    if (this.liveView) {
      this.startDate = null;
      this.endDate = null;
      this.tentativeLoadSensorData(
        moment(new Date())
          .add(-1 * this.defaultHistoryMinutes, "minutes")
          .toDate(),
        new Date()
      );
    } else {
      if (this.timestamps.length == 0) {
        this.startDate = moment(new Date())
          .add(-1 * this.defaultHistoryMinutes, "minutes")
          .toDate();
        this.endDate = new Date();
      } else {
        this.startDate = this.userTime.utcToCalendarDate(
          new Date(this.timestamps[0])
        );
        this.endDate = this.userTime.utcToCalendarDate(
          new Date(this.timestamps[this.timestamps.length - 1])
        );
      }
      this.tentativeLoadSensorData(
        this.userTime.calendarDateToUTC(this.startDate),
        this.userTime.calendarDateToUTC(this.endDate)
      );
    }
  }

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngOnChanges(changes: SimpleChanges) {
    //Each time that sensor data changes for vehicle, put the new sensor value to the list and add the new timestamp to the list of timestamps

    if (changes["vehicle"]) {
      this.timestamps = [];
      this.labels = [];
      this.deviceValues = {};
      this.deviceColors = {};
      this.visibleDevices = {};

      this.vehicle.device.configuration.parameters.sensors.forEach(
        (s: Rest.Sensor) => {
          this.deviceColors["s" + s.id] = this.getRandomColor();

          if(!this.isUserHosp){
            this.visibleDevices["s" + s.id] = true;
          }else{
            this.visibleDevices["s" + s.id] = this.checkSensorChecked(s.tag);

          }
        }
      );
      this.vehicle.device.configuration.parameters.actuators.forEach(
        (a: Rest.Actuator) => {
          this.deviceColors["a" + a.id] = this.getRandomColor();
          
          this.visibleDevices["a" + a.id] = true;
        }
      );
      this.updatePlot();
    }
  }

  updatePlot() {
    this.chartData = {
      labels: [...this.labels],
      datasets: [],
    };

    this.vehicle.device.configuration.parameters.sensors.forEach(
      (s: Rest.Sensor) => {
        if (this.visibleDevices["s" + s.id] == false) return;
        this.chartData.datasets.push({
          label: s.name,
          data: this.deviceValues["s" + s.id],
          borderColor: this.deviceColors["s" + s.id],
          lineTension: 0,
          fill: false,
        });
      }
    );
    this.vehicle.device.configuration.parameters.actuators.forEach(
      (a: Rest.Actuator) => {
        if (this.visibleDevices["a" + a.id] == false) return;
        this.chartData.datasets.push({
          label: a.name,
          data: this.deviceValues["a" + a.id],
          borderColor: this.deviceColors["a" + a.id],
          lineTension: 0,
          fill: false,
        });
      }
    );
  }

    checkSensorChecked(tag): boolean {
      if(!this.isUserHosp){
        return true;
      }
      else if((tag === 'BLE2_TEMPERATURE' ||  tag === 'BLE3_TEMPERATURE')){
        return true;
      }
      else{
        return false;
      }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
