
<p-dialog
      i18n-header header="Actuator"
      [(visible)]="display"
      [responsive]="true"
      [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
      [modal]="true"
       [draggable]="true"
      appendTo="body"
      *ngIf="display">

  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <div class="container">

      <!-- ROW 1 -->
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
          </form-input>
        </div>
        <div class="col">
          <input pInputText id="name" [(ngModel)]="entity.name" class="txtbStyle"/>
        </div>
      </div>

      <!-- ROW 2 -->
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input i18n-fieldName [required]="true" fieldName="tag" [errors]="errors.tag">
          </form-input>
        </div>
        <div class="col">
          <input pInputText id="tag" [(ngModel)]="entity.tag" class="txtbStyle"/>
        </div>
      </div>

      <!-- ROW 3 -->
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input [required]="true" i18n-fieldName fieldName="port" [errors]="errors.port">
          </form-input>
        </div>
        <div class="col">
          <input pInputText id="port" [(ngModel)]="entity.port" type="number" class="txtbStyle"/>
        </div>
      </div>

      <!-- ROW 4 -->
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input i18n-fieldName fieldName="conversion algorithm" [errors]="errors.conversionAlgorithm">
          </form-input>
        </div>
        <div class="col">
          <paged-search-dropdown [paginationRequest]="enableFilter"
          [pageFunction]="this.conversionAlgorithmService.getPage.bind(this.conversionAlgorithmService)"
          [(selectedEntity)]="entity.conversionAlgorithm"></paged-search-dropdown>
        </div>
      </div>

    </div>
  </div>

  <p-footer>
    <div class="d-flex justify-content-end" *ngIf="user.isAdmin === true">
      <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="Save"></button>
    </div>
  </p-footer>

</p-dialog>
