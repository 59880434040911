import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-table-excel-button',
  templateUrl: './table-excel-button.component.html',
  styleUrls: ['./table-excel-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableExcelButtonComponent {
  _text: string;

  @Input() set text(text: string) {
    this._text = text;
  }

  @Input() isDisabled = false;
  @Output() btnClick = new EventEmitter();

  constructor() {
    this._text = '';
  }

  onClick() {
    this.btnClick.emit();
  }

}
