import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Accordion } from "primeng/accordion";
import { TabView } from "primeng/tabview";
import { Auxiliar } from "../../../model/Auxiliar";
import { Rest } from "../../../rest/rest_client";
import { PreconfigurationService } from "../../../rest/preconfiguration.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-gv800-parameters-form",
  templateUrl: "./gv800-parameters-form.component.html",
  styleUrls: ["./gv800-parameters-form.component.css"],
  providers: [PreconfigurationService, ConfirmationService],
})
export class Gv800ParametersFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  //View params
  @Input() deviceParams: Rest.DeviceParameters; //.DeviceParameters;
  @Input() firmware: RestExt.FirmwareVersion;
  @Input() isNew: boolean;
  @Output() GV800ParamsChange: EventEmitter<Rest.DeviceParameters> =
    new EventEmitter<Rest.DeviceParameters>();
  @ViewChild("tv", { static: true }) public tabView: TabView;
  @ViewChild("ac", { static: true }) accordion: Accordion;

  @Input() isConfig: boolean;
  @Output()
  refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  preconfigService: PreconfigurationService;
  confirmationService: ConfirmationService;
  notificationsService: NotificationsService;

  firmwareValue: any;

  /**Events */
  selected = false;

  /**PreConfigs */
  preConfigsList: Rest.DevicePreconfiguration[];
  availablePreconfigs: SelectItem[];
  selectedPreconfig: any;

  constructor(
    preconfigService: PreconfigurationService,
    confirmationService: ConfirmationService,
    notificationsService: NotificationsService,
    protected i18n: I18nService
  ) {
    super(i18n);
    this.preconfigService = preconfigService;
    this.confirmationService = confirmationService;
    this.notificationsService = notificationsService;
    //this.i18n = i18n;
  }

  ngOnChanges(changes: SimpleChanges) {
    /* Sergi: this should be executed only if it's not initialized (not new) */
    if (this.isNew && this.deviceParams == null) {
      this.deviceParams = RestExt.emptyGV800Params();
      console.log("Parameters gv800 empty: ", this.deviceParams);
    }

    if (changes["deviceParams"]) {
      this.GV800ParamsChange.emit(this.deviceParams);
    }

    RestExt.initializeGV800Params(this.deviceParams);
  }

  ngOnInit() {
    this.initConfig();
    if (this.isConfig) {
      this.LoadPreconfigsList();
    }
  }

  public getGV800DeviceParams(): any {
    return this.deviceParams;
  }

  LoadPreconfigsList() {
    let paginationRequest: Rest.ListPaginationRequest;
    this.availablePreconfigs = [];
    this.selectedPreconfig = null;
    this.preConfigsList = [];

    const that = this;
    /*this.preconfigService.getPage(RestExt.allRecords).then(
       function (response) {
         that.preConfigsList = response.entities;
         that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(response.entities);
       });*/
    paginationRequest = RestExt.firstPageRequest();
    //paginationRequest.sortBy="creationDate";
    paginationRequest.sortAsc = false;

    paginationRequest.filters = {
      firmware: [this.firmware + ""],
    };

    this.preconfigService.getPage(paginationRequest).then(function (response) {
      that.preConfigsList = response.entities;
      that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(
        response.entities
      );
    });
  }

  initConfig() {
    this.preConfigsList = [];
    this.selectedPreconfig = null;

    if (this.accordion != undefined) {
      this.tabView.activeIndex = 0;
      this.closeAllAccordionTabs();
    }
  }

  loadPreconfig(event) {
    this.preconfigService
      .find(event.value.id)
      .then((pre: Rest.DevicePreconfiguration) => {
        this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
      });
  }

  fillPreConfig(newParams: Rest.DeviceParameters) {
    //to load the values of the preconfig events but not the id assigned
    //newParams.proprietaryParameters.speedEvent.id = this.deviceParams.proprietaryParameters.speedEvent.id;
    //remove id because if is from preconfig we have to assign as new sensors to config

    //to load the values of the preconfig events but not the id assigned
    newParams.id = this.deviceParams.id;

    for (const a in newParams.sensors) {
      delete newParams.sensors[a]["id"];
    }

    for (const a in newParams.actuators) {
      delete newParams.actuators[a]["id"];
    }
    //Load the rest preconfig parameters to gv300 config

    //Load the rest preconfig parameters to gv300 config

    //this.deviceParams.actuators = newParams.actuators;
    this.deviceParams.proprietaryParameters.type = "GV800";
    this.deviceParams = newParams;

    //assign id config to keep the assignation
    /*var configTmp: Rest.VT10Parameters = <Rest.VT10Parameters>{};
     configTmp.id = this.deviceParams.proprietaryParameters.id;*/
    for (const a in this.deviceParams.sensors) {
      this.deviceParams.sensors[a].parameters = null; //configTmp;
    }
    for (const a in this.deviceParams.actuators) {
      this.deviceParams.actuators[a].parameters = null; //configTmp;
    }
  }

  tabChanged() {
    /**
     * Dirty trick:
     * Force [(deviceParams)] onChange events to fire in sub-components to detect changes in LedPatterns names
     */
    this.deviceParams = Object.assign({}, this.deviceParams);

    this.GV800ParamsChange.emit(this.deviceParams);
  }
  refresh(status: boolean) {
    this.refreshList.emit(status);
  }

  closeAllAccordionTabs() {
    if (!this.isNullOrUndefined(this.accordion.tabs)) {
      for (const tab of this.accordion.tabs) {
        if (tab.selected) {
          tab.selected = false;
        }
      }
    }
  }

  isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
  }

  /*beforeShow() {
     this.firstFirmWare = RestExt.FirmwareVersion[this.firmware] + 1;
   }*/
}
