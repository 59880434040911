import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { PoiService } from "../../../rest/poi.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { PoiFormComponent } from "../poi-form/poi-form.component";

@Component({
    selector: "app-poi-list",
    templateUrl: "./poi-list.component.html",
    styleUrls: ["./poi-list.component.css"],
    providers: [PoiService, ConfirmationService, UserTimePipe],
})
export class PoiListComponent
    extends EntityListComponent<Rest.POI>
    implements OnInit {
    pois: Rest.POI[];

    title: string;
    displayDialog: boolean;

    messageWarning: string;
    containsNestedEntities = [];
    EnabledFilter: SelectItem[];

    //datos para compartir con formulario
    selectedPOI: Rest.POI; //poi que queremos editar
    selectedPOICategory: Rest.POICategory; //categoria padre del poi que queremos crear/editar

    @Input() parentPOICategory: Rest.POICategory; //categoria del poi (contiene lista de pois)

    @ViewChild(PoiFormComponent, {static: true}) poiForm: PoiFormComponent;

    poiService: PoiService;
    showStatusDialog = false;

    /*Permissions */
    hasPermissions: boolean;

    /** */

    constructor(
        poiService: PoiService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            poiService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.poiService = poiService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._("POIs");
        this.form = this.poiForm;
        this.fetchEntityBeforeShowUpdateForm = true;
        this.hasEntityFilters = true;
        /*Permissions */
        this.hasPermissions = this.parentPOICategory["_$update"];
        /** */
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((p: any) => {
            /*Permissions */
            p._$changeStatus = this.hasPermissions;
            p._$update = this.hasPermissions;
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    protected beforeLoad() {
        this.paginationRequest.filters["poiCategoryID"] = [
            this.parentPOICategory.id.toString(),
        ];
    }

    /*************************************************** */
    // editPOIDialog(event: { data: Rest.POI }) {

    //isSelectableColumn: is checkbox column
    //isColumn: means is event from column // false:means is event from table or blank cell

    editPOIDialog(event) {
        if (!this.isSelectableColumn) {
            this.selectedPOI = event; //custom-map will load pois from poiCategory
            this.selectedPOICategory = this.parentPOICategory;
            //this.selectedEntities = [];

            this.update(this.selectedPOI);
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editPOIDialogTable(event) {
        if (!this.isColumn && !this.isSelectableColumn) {
            //If enters here ii's because cell is empty or is the first cell which contains checkbox for selection
            this.selectedPOI = event; //custom-map will load pois from poiCategory
            this.selectedPOICategory = this.parentPOICategory;
            //this.selectedEntities = [];

            this.update(this.selectedPOI);
        }
        this.isSelectableColumn = false;
        this.isColumn = false;
    }

    /****************************************************** */

    createPOIDialog() {
        //this.selectedEntities = [];
        this.selectedPOICategory = this.parentPOICategory;
        this.selectedPOI = null;
        this.create();
    }

    close() {
        let elementsdeleted = [];
        this.displayDialog = false;
        this.selectedEntities = [];
        this.pois = [];
        this.containsNestedEntities = [];

        if (this.containsNestedEntities != null) {
            elementsdeleted = this.containsNestedEntities.splice(
                0,
                this.containsNestedEntities.length
            );
        }
    }

    containsObject(obj, list) {
        let i;
        for (i = 0 ; i < list.length ; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }
}
