import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-tow-event-config-form',
  templateUrl: './tow-event-config-form.component.html',
  styleUrls: ['./tow-event-config-form.component.css'],

})
export class TowEventConfigFormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {


  constructor(protected i18n: I18nService) {  super(i18n);}

  @Input() tow: Rest.TowEventConfiguration;
  @ViewChild('towEvent') towEvent;
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  /**
   * Needed to prevent toggle of UI component
   * @param event 
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
   
  }
  
 

}
