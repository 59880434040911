<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Canbus Parameters(AT+GTCAN)</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="canParameters.modeEnabled"></p-inputSwitch>
    </div>
    </div>
  </p-header>
  <!--   <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.speedEvent.enabled>-->
  <!-- <input type="text" [(ngModel)]="deviceParams.balh"> -->


  <div class="ui-g" #ContentDiv>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="CAN Report interval">
        <div class="ui-inputgroup">
          <p-spinner step="1" size="2" [(ngModel)]="canParameters.canReportInterval" [min]="0"
            [max]="86400"></p-spinner>
          <span class="ui-inputgroup-addon">0~86400 </span>
          <span class="ui-inputgroup-addon" >sec</span>
        </div>
      </form-input>
    </div>


    <!-- <div [class.disableDIV]=!trackModeSwitch> -->


    <div class="ui-g-12">
      <form-input fieldName="CAN Report Mask">

      </form-input>
    </div>


    <div class="ui-g-12">

      <div class="ui-g-12">

        <div class="ui-g-4">
          <p-checkbox binary="true" label="VIN" [(ngModel)]="canParameters.vin">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Ignition Key" [(ngModel)]="canParameters.ignitionKey">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Distance" [(ngModel)]="canParameters.totalDistance">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Fuel Used" [(ngModel)]="canParameters.totalFuelUsed">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Vehicle Speed" [(ngModel)]="canParameters.vehicleSpeed">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Engine RPM" [(ngModel)]="canParameters.engineRPM">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Engine Coolant Temperature" [(ngModel)]="canParameters.engineCoolantTemperature">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Fuel Consumption" [(ngModel)]="canParameters.fuelConsumption">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Fuel Level" [(ngModel)]="canParameters.fuelLevel">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Range" [(ngModel)]="canParameters.range">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Accelerator Pedal Pressure" [(ngModel)]="canParameters.acceleratorPedalPressure">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Engine Hours" [(ngModel)]="canParameters.totalEngineHours">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Driving Time" [(ngModel)]="canParameters.totalDrivingTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Engine Idle Time" [(ngModel)]="canParameters.totalEngineIdleTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Idle Fuel Used" [(ngModel)]="canParameters.totalIdleFuelUsed">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Axle Weight" [(ngModel)]="canParameters.axleWeight">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Information" [(ngModel)]="canParameters.tachographInformation">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Detailed Information" [(ngModel)]="canParameters.detailedInformation">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Lights" [(ngModel)]="canParameters.lights">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Doors" [(ngModel)]="canParameters.doors">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Vehicle Overspeed Time" [(ngModel)]="canParameters.totalVehicleOverspeedTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Vehicle Engine Overspeed Time" [(ngModel)]="canParameters.totalVehicleEngineOverSpeedTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Distance Impulses" [(ngModel)]="canParameters.totalDistanceImpulses">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="CAN Report Expansion Mask" [(ngModel)]="canParameters.canReportExpansionMask">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="GPS Information" [(ngModel)]="canParameters.gpsInformation">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="GSM Information" [(ngModel)]="canParameters.gsmInformation">
          </p-checkbox>
        </div>
        
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input fieldName="Additional Event">
        <p-dropdown [options]="AdditionalEventOptions" [(ngModel)]="canParameters.modeAdditionalEvent"
          [style]="{'width':'190px'}">
        </p-dropdown>
      </form-input>
    </div>
    <div class="ui-g-12">
      <form-input fieldName="CAN Expansion Report Mask">

      </form-input>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-12">
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Ad-BlueLevel" [(ngModel)]="canParameters.adBlueLevel">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Axle Weight 1st" [(ngModel)]="canParameters.axleWeight1st">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Axle Weight 3rd" [(ngModel)]="canParameters.axleWeight3rd">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Axle Weight 4th" [(ngModel)]="canParameters.axleWeight4th">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Overspeed Signal" [(ngModel)]="canParameters.tachographOverspeedSignal">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Vehicle Motion Signal" [(ngModel)]="canParameters.tachographVehicleMotionSignal">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Driving Direction" [(ngModel)]="canParameters.tachographDrivingDirection">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Analog Input Value" [(ngModel)]="canParameters.analogInputValue">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Engine Braking Factor" [(ngModel)]="canParameters.engineBrakingFactor">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Pedal Braking Factor" [(ngModel)]="canParameters.pedalBrakingFactor">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Accelerator Kick-downs" [(ngModel)]="canParameters.totalAcceleratorKickDowns">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Effective Engine Speed Time" [(ngModel)]="canParameters.totalEffectiveEngineSpeedTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Cruise Control Time" [(ngModel)]="canParameters.totalCruiseControlTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Accelerator Kick-down Time" [(ngModel)]="canParameters.totalAcceleratorKickDownTime">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Total Brake Applications" [(ngModel)]="canParameters.totalBrakeApplication">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Driver 1 Card Number" [(ngModel)]="canParameters.tachographDriver1CardNumber">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Driver 2 Card Number" [(ngModel)]="canParameters.tachographDriver2CardNumber">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Driver 1 Name" [(ngModel)]="canParameters.tachographDriver1Name">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Tachograph Driver 2 Name" [(ngModel)]="canParameters.tachographDriver2Name">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Registration Number" [(ngModel)]="canParameters.registrationNumber">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Expansion Information" [(ngModel)]="canParameters.expansionInformation">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Rapid Brakings" [(ngModel)]="canParameters.rapidBraking">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Rapid Accelerations" [(ngModel)]="canParameters.rapidAccelerations">
          </p-checkbox>
        </div>
      </div>
    </div>
    
  </div>
  <!-- </div> -->
</p-accordionTab>
