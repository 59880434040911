import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-analog-input-port-setting-gv800",
  templateUrl: "./analog-input-port-setting-gv800.component.html",
  styleUrls: ["./analog-input-port-setting-gv800.component.css"],
})
export class AnalogInputPortSettingGv800Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() aisParameters: Rest.AnalogInputPortSettingGV800;

  modeOptions: any = [
    { label: "Disable analog input alarm.", value: 0 },
    {
      label:
        "Enable analog input alarm. If the current input voltage is within the range, the alarm will be triggered",
      value: 1,
    },
    {
      label:
        "Enable analog input alarm. If the current input voltage is outside the range, the alarm will be triggered",
      value: 2,
    },
    {
      label:
        "Connect with the fuel sensor to support fuel level reports and monitoring",
      value: 3,
    },
    {
      label:
        "No alarm mode. The range is ignored and no alarm will be triggered.",
      value: 4,
    },
    {
      label:
        "Voltage processed mode. The range defined will be ignored. However, the voltage will be p",
      value: 5,
    },
  ];
  trackModeSwitch = false;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.aisParameters);
    }
  }

  onChange() {
    if (this.aisParameters.minThreshold > this.aisParameters.maxThreshold) {
      this.aisParameters.maxThreshold = this.aisParameters.minThreshold;
    }
  }

  onChangeMax() {
    if (this.aisParameters.minThreshold > this.aisParameters.maxThreshold) {
      this.aisParameters.minThreshold = this.aisParameters.maxThreshold;
    }
  }

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value === 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
