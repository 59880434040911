<button (click)="visible=true" pButton  type="button" *ngIf="user.canCreatUTERondesPOCVisits" class="btn btn-primary btn-sm" style="margin-top: 10px" i18n-label label="Add Control Point Visit"></button>
<p-dialog [responsive]="true" [resizable]="true" [contentStyle]="{minHeight: '250px'}" i18n-header header="Add Control Point Visit"
    [(visible)]="visible" dismissableMask=true modal=true>
    <div class="ui-grid ui-grid-responsive ui-fluid">


        <div class="ui-g">
            <div class="ui-g-12">
                <form-input [required]="true" i18n-fieldName fieldName="Control Point" >
                        <paged-search-dropdown [paginationRequest]="cpFilter" [pageFunction]="this.zoneService.getPage.bind(this.zoneService)" [(selectedEntity)]="entity.cp">
                            </paged-search-dropdown>

                </form-input>
            </div>
            <div class="ui-g-12">
                <form-input [required]="true" i18n-fieldName fieldName="Date" >
                    <p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"   [maxDate]="now"
                    dateFormat={{i18n.dateFormat}} [(ngModel)]="entity.date" showTime="true" readonlyInput="true">
                </p-calendar>
                </form-input>
            </div>
            <div class="ui-g-12">
                <form-input [required]="true" i18n-fieldName fieldName="Vehicle" >
                    <paged-search-dropdown [paginationRequest]="vehicleFilter" [pageFunction]="this.vehicleService.getPage.bind(this.vehicleService)" [(selectedEntity)]="entity.vehicle">
                  </paged-search-dropdown>
                </form-input>
            </div>



        </div>
    </div>

    <p-footer>

      <!-- TODO: Fix send() function to close the p-dialog! -->
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <div >
                    <button [disabled]="entity.date === null || entity.cp==null || entity.vehicle==null" type="button" pButton icon="fa fa-check" (click)="send()" i18n-label label="Save"></button>
                </div>
            </div>
        </p-footer>
</p-dialog>
