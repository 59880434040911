/* eslint-disable prefer-const */
import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { PoiService } from "../../../rest/poi.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../../services/notifications-service/notifications.service";
import { ServerValidatedFormComponent } from "../../server-validated-form/server-validated-form.component";

@Component({
    selector: "app-poi-categories-file-form",
    templateUrl: "./poi-categories-file-form.component.html",
    styleUrls: ["./poi-categories-file-form.component.css"],
    providers: [PoiService, ConfirmationService],
})
export class PoiCategoriesFileFormComponent
    extends ServerValidatedFormComponent
    implements OnInit {
    poiService: PoiService;
    visible = false;

    @ViewChild("fileInput", {static: true})
    fileInput: any;

    selectedPOICategory: Rest.POICategory;
    decimalRegex: RegExp = new RegExp("/^[0-9]+(.[0-9]{1,2})?$/");
    LoadedPOIs: Rest.POI[];

    constructor(
        poiService: PoiService,
        protected notificationsService: NotificationsService,
        protected confirmationService: ConfirmationService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService
    ) {
        super(i18n);
        this.poiService = poiService;
    }

    ngOnInit() {
        this.LoadedPOIs = [];
    }

    public show(pc: Rest.POICategory) {
        this.selectedPOICategory = pc;
        this.visible = true;
        this.LoadedPOIs = [];
        this.messages = [];
        this.errors = [];
    }

    public changeListener(files: FileList) {
        if (files && files.length > 0) {
            let file: File = files.item(0);
            let reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                let csv: string = reader.result.toString();
                let allTextLines = csv.split(/\r\n|\n/);

                for (let i = 1 ; i < allTextLines.length ; i++) {
                    let currentPOI = {} as Rest.POI;
                    currentPOI.position = {} as Rest.Vertex;
                    let rows = allTextLines[i].split(";");

                    if (!this.isEmptyLine(rows)) {
                        this.CheckNotNullFileValues(rows);

                        currentPOI.poiCategory = this.selectedPOICategory;
                        currentPOI.enabled = true;
                        currentPOI.name = rows[1];
                        currentPOI.address = rows[2];
                        currentPOI.municipality = rows[3];
                        currentPOI.position.latitude = parseFloat(
                            rows[4].replace(",", ".")
                        );
                        currentPOI.position.longitude = parseFloat(
                            rows[5].replace(",", ".")
                        );
                        currentPOI.postalCode = rows[6];
                        currentPOI.province = rows[7];
                        currentPOI.phoneNumber = rows[8];
                        currentPOI.contactPerson = rows[9];
                        currentPOI.observations = rows[10];
                        currentPOI.timeZone = "GMT";

                        this.LoadedPOIs.push(currentPOI);
                    }
                }

                this.LoadedPOIs = [...this.LoadedPOIs];
                this.fileInput.nativeElement.value = "";
            };
        }
    }

    public isEmptyLine(rowArray: string[]): boolean {
        let isEmpty = true;

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0 ; i < rowArray.length ; i++) {
            if (rowArray[i] !== "") {
                isEmpty = false;
            }
        }
        return isEmpty;
    }

    public CheckNotNullFileValues(rowArray: string[]) {
        if (
            rowArray[1] === "" ||
            rowArray[2] === "" ||
            rowArray[3] === "" ||
            rowArray[6] === "" ||
            rowArray[7] === ""
        ) {
            this.notificationsService.add(
                Severity.error,
                this.i18n._(":("),
                this.i18n._("The file contains empty required fields")
            );
            throw new Error("Empty required values");
        }
    }

    public createPOIs() {
        this.messages = [];
        this.errors = {};
        //If there is no errors the POIs will be created
        this.poiService
            .createByList(this.LoadedPOIs)
            .then(
                (res) => {
                    this.notificationsService.add(
                        Severity.success,
                        this.i18n._(":)"),
                        this.i18n._("Entities created")
                    );
                    this.LoadedPOIs = [];
                    this.visible = false;
                },
                (err) => {
                    this.notificationsService.add(
                        Severity.error,
                        this.i18n._(":("),
                        this.i18n._("Something went wrong")
                    );
                    console.log(err);
                    this.handleError(err, this);
                }
            )
            .catch((e) => {
                this.handleError(e, this);
            });
    }

    /*
    public validateTable (poi: Rest.POI,idx:number) {

      if (poi.name === "")  this.errors.push({fieldName: "POI "+idx+" name", errorDescription: "Not Empty" })
      if (poi.address === "")   this.errors.push({fieldName: "POI "+idx+" address", errorDescription: "Not Empty" })
      if (poi.postalCode === "")  this.errors.push({fieldName: "POI "+idx+" postalCode", errorDescription: "Not Empty" })
      if (poi.municipality === "") this.errors.push({fieldName: "POI "+idx+" municipality", errorDescription: "Not Empty" })
      if (poi.province === "") this.errors.push({fieldName: "POI "+idx+" province", errorDescription: "Not Empty" })

      if (this.errors.length > 0) {
        this.messages.push({ severity: "error", detail: "Table has errors", summary: "" });
        for (const i in this.errors)this.messages.push({ severity: "error", detail:  this.errors[i].errorDescription, summary:  this.errors[i].fieldName });
      }

    }
  */
}
