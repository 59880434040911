import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';


@Component({
  selector: 'app-speed-event-configuration-form-x8',
  templateUrl: './speed-event-configuration-form-x8.component.html',
  styleUrls: ['./speed-event-configuration-form-x8.component.css'],
})
export class SpeedEventConfigurationFormX8Component extends ServerValidatedFormComponent implements OnInit {

  @ViewChild('ContentDiv', { static: true }) ContentDiv;
  @Input() speedEvent: Rest.SpeedEventConfigurationX8;

  ReportModeOptions: any = [
    {label: "Disable",value:0},
    {label: "Logging",value:1},
    {label: "Polling", value:2}, 
    {label: "Logging and polling", value:3},
    {label: "Set Output", value:4},
    {label: "Logging and Set Output", value:5},
    {label: "Polling and Set Output", value:6},
    {label: "Logging and polling and Set Output", value:7}
  ];
 
  

  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
    if(this.speedEvent!=null){this.disableAnalogInputDIV(!this.speedEvent.enabled)};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }
  trackModeSwitch = false;
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }


}
