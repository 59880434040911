import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class VehicletypeService  extends EntityService<Rest.VehicleType>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.VehicleType>> {
return this.client.VehicleType_getPage(arg0);
}
}
