<div *ngIf="!driverPoints" class="p-2 align-items-center">
	<mat-spinner
		[diameter]="30"
		strokeWidth="5"
		[color]="primary">
	</mat-spinner>
</div>

<div *ngIf="driverPoints">
	<div class="p-2 align-items-center">
		<form-input [required]='true' [align]="'cols-2'" fieldName="{{ 'documents.driverPoints.points' | translate }}">
			<input pInputText type="number" [(ngModel)]="driverPoints.points" [disabled]='isEditingDisabled' [style]="{'width':'180px', 'margin-right':'20px'}"/>
		</form-input>
	</div>

	<div  class="p-2 align-items-center">
		<form-input [required]='true' [align]="'cols-2'" fieldName="{{ 'documents.driverPoints.date' | translate }}">
			<!-- <input pInputText [(ngModel)]="driverPoints.infoDate" [disabled]='isEditingDisabled' [style]="{'width':'180px', 'margin-right':'20px'}"/> -->
			<p-calendar [(ngModel)]='driverPoints.infoDate'
				[disabled]='isEditingDisabled'
				[readonlyInput]='true'
				[showTime]='false'
				[style]="{'width':'200px'}"
				dateFormat='{{i18n.dateFormat}}'
				showIcon='true'
				appendTo='body'
			></p-calendar>
		</form-input>
	</div>
</div>

<!-- <div class="row mt-4 mb-4">
    <div class="col-sm-6 mt-3">
        <label for="name" class="form-label custom-label mb-0 me-2" i18n>
            {{ 'documents.driverPoints.points' | translate }}
        </label>
        <input type="number" id="points" [(ngModel)]="driverPoints.points" min="0" max="12" class="form-control custom-input">
    </div>
    <div class="col-sm-6 mt-3">
        <label for="type" class="form-label custom-label mb-0 me-2" i18n>
            {{ 'documents.driverPoints.date' | translate }}
        </label>

        <input type="date" [(ngModel)]="driverPoints.infoDate" id="infoDate" class="form-control custom-input">
    </div>
</div> -->
<!-- <div class="d-flex justify-content-center bd-highlight">
    <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
            <button class="btn custom-button" (click)="save()" i18n>
                {{'general.save' | translate}}
            </button>
        </div>

        <div *ngIf="isNew">
            <button class="btn custom-button" (click)="save()" i18n>
                {{'general.create' | translate}}
            </button>
        </div>
    </div>

</div> -->
