<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Tow Alarm
          Configuration (AT + GTTOW)</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="towAlarm.towEnable"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" #towAlarmDiv>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Engine Off to Tow">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="EngineOffToTow" size="2" [(ngModel)]="towAlarm.engineOffToTow" [min]="5" [max]="15">
          </p-spinner>
          <span class="ui-inputgroup-addon">5~15</span>
          <span class="ui-inputgroup-addon" >minutes</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Fake Tow Delay">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="FakeTowDelay" size="2" [(ngModel)]="towAlarm.fakeTowDelay" [min]="0" [max]="10">
          </p-spinner>
          <span class="ui-inputgroup-addon">0~10</span>
          <span class="ui-inputgroup-addon" >minutes</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Tow Interval">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="TowInterval" size="7" [(ngModel)]="towAlarm.towInterval" [min]="30" [max]="86400">
          </p-spinner>
          <span class="ui-inputgroup-addon">30~86400 </span>
          <span class="ui-inputgroup-addon" >seconds</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Rest Duration">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="RestDuration" size="2" [(ngModel)]="towAlarm.restDuration" [min]="1" [max]="255">
          </p-spinner>
          <span class="ui-inputgroup-addon">1~255</span>
          <span class="ui-inputgroup-addon" >(×15sec)</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Motion Duration">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="MotionDuration" size="2" [(ngModel)]="towAlarm.motionDuration" [min]="1" [max]="10">
          </p-spinner>
          <span class="ui-inputgroup-addon">1~10</span>
          <span class="ui-inputgroup-addon" >(×100ms)</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Motion Threshold">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="MotionThreshold" size="2" [(ngModel)]="towAlarm.motionThreshold" [min]="2" [max]="4">
          </p-spinner>
          <span class="ui-inputgroup-addon">2~4</span>
        </div>
      </form-input>
    </div>

  </div>

</p-accordionTab>
