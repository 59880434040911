import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';


@Component({
  selector: 'app-idle-event-configuration',
  templateUrl: './idle-event-configuration.component.html',
  styleUrls: ['./idle-event-configuration.component.css'],

  encapsulation: ViewEncapsulation.None
})
export class IdleEventConfigurationComponent extends ServerValidatedFormComponent implements OnInit {

  constructor(protected i18n: I18nService) {  super(i18n);}

  @Input() idleEventConfig: Rest.IdleEventConfiguration;

  ngOnInit() {
  }

}
