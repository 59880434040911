import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-fence-alarm-configuration-ob22',
  templateUrl: './fence-alarm-configuration-ob22.component.html',
  styleUrls: ['./fence-alarm-configuration-ob22.component.css']
})
export class FenceAlarmConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {
  @Input() fenceAlarm: Rest.FenceAlarmConfigurationOB22
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  constructor(protected i18n: I18nService) {  super(i18n);}
  isCircle = true;
  FenceOptions: any = [
    {label: "CIRCLE AREA",value:"CIRCLE_AREA"},
    {label: "RECTANGLE AREA",value:"RECTANGLE_AREA"},            
  ];

  FenceAlarmOptions: any = [
    {label:"BOTH", value: null},
    {label:"IN", value:"IN"},
    {label:"OUT", value:"OUT"},
  ];

  AlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"GPRS_SMS"},            
  ];



  ngOnInit() {
    if(this.fenceAlarm.mode =="CIRCLE_AREA"){
      this.isCircle = true;
    }else{
      this.isCircle = false;
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.fenceAlarm);
    }
  }


  fenceModeChange(event){
    console.log(event.value);
    if(event.value == "CIRCLE_AREA"){
      this.isCircle = true;
    }else{
      this.isCircle = false;
    }

  }

}
