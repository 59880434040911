
<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add Rule backend call is not working;
4. Add translations;
5. Implement date filter -->

<!-- PrimeNG DataTable -->
<p-table #dt [value]="rows"
         (onEditComplete)="handleRowEdit($event)"
         dataKey="key"
         [rowHover]="true"
         [autoLayout]="true"
>

  <!-- Table Column/Header Definition -->
  <ng-template pTemplate="header">
    <tr>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">{{'control-panel.metadata.key' | translate}}</div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">{{'control-panel.metadata.value' | translate}}</div>
      </th>

      <th style="width: 3rem">

      </th>

    </tr>
  </ng-template>

  <!-- Table Row Definition -->
  <ng-template pTemplate="body" let-row>
    <tr>

      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="row.key">
          </ng-template>

          <ng-template pTemplate="output">
            {{row.key}}
          </ng-template>
        </p-cellEditor>
      </td>

      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="row.value">
          </ng-template>

          <ng-template pTemplate="output">
            {{row.value}}
          </ng-template>
        </p-cellEditor>

      </td>

      <td>
        <button (click)=deleteRow(row)>{{'general.delete' | translate}}</button>
      </td>

    </tr>
  </ng-template>

</p-table>

<!--<p-dataTable [value]="rows" [editable]="true" (onEditComplete)=handleRowEdit($event)>
    <p-column field="key" header="Key" [editable]="true"></p-column>
    <p-column field="value" header="Value" [editable]="true"></p-column>
    <p-column> <ng-template let-col let-row="rowData" let-ri="rowIndex" pTemplate="body">
        <button (click)=deleteRow(row) i18n>Delete</button>
    </ng-template></p-column>
</p-dataTable>-->

<button (click)=addRow() style="margin-top: 20px; margin-bottom: 20px;" >{{'general.add' | translate}}</button>
