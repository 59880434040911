<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">ANGLE UPLOAD
          (ANGLEREP)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="angle.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" #ContentDiv>
    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Angle Degree">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="angle.angleDegree" [min]="5" [max]="180"></p-spinner>
            <span class="ui-inputgroup-addon">5~180</span>
            <span class="ui-inputgroup-addon">degree</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Detection Time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="angle.detectionTime" [min]="2" [max]="5"></p-spinner>
            <span class="ui-inputgroup-addon">2~5</span>
            <span class="ui-inputgroup-addon">sec</span>
          </div>
        </div>
      </div>

    </div>
  </div>

</p-accordionTab>