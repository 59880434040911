import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { JQueryHTTPClient } from "./JQueryHTTPClient";
import { Rest } from "./rest_client";

@Injectable()
export class RuleService extends EntityService<Rest.Rule> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Rule): Rest.RestResponse<Rest.Rule> {
    return this.client.Rule_create(arg0);
  }

  delete(arg0: Rest.Rule[]): Rest.RestResponse<void> {
    return this.client.Rule_delete(arg0);
  }

  update(arg0: Rest.Rule): Rest.RestResponse<Rest.Rule> {
    return this.client.Rule_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.Page<Rest.Rule>> {
    return this.client.Rule_getPage(arg0);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Rule>> {
    return this.client.Rule_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.Rule_changeStatus(arg0, queryParams);
  }

  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.Rule> {
    return this.client.Rule_find(arg0, queryParams);
  }
}
