import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';
import { AccessoryFormComponent } from '../accessory-form/accessory-form.component';
import { AccessoryService } from 'app/rest/accessory.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

@Component({
  selector: 'app-accessory-list',
  templateUrl: './accessory-list.component.html',
  styleUrls: ['./accessory-list.component.css'],
  providers: [AccessoryService, ConfirmationService, UserTimePipe, ExcelService],
})
export class AccessoryListComponent
  extends EntityListComponent<Rest.Accessory>
  implements OnInit {

  @ViewChild(AccessoryFormComponent, { static: true })
  accessoryForm: AccessoryFormComponent;

  title: string = this.i18n._("Accessories");
  disableSave: boolean;
  EnabledFilter: SelectItem[];

  constructor(
    private accessoryService: AccessoryService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected translateService: TranslateService,
  ) {
    super(
      accessoryService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );
    this.accessoryService = accessoryService;
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.accessoryForm;
  }
  beforeLoad() {
    this.paginationRequest.sortBy = "startDate";
    
    this.paginationRequest.sortAsc= false;
    if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }
  }

  afterLoad() {
    this.EnabledFilter = RestExt.getEnabledFilter(
      this.filters["enabled"],
      this.i18n
    );
    this.paginationResult.entities.forEach((cl: any) => {
      /*Permissions */
      cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
      cl._$update = true;
      /** */
    });
    /*Permissions */
    this.checkSelectAll();
    /** */
  }
  excelColumns = {};
  exportExcel() {
    this.setExcelColumns();
    let fileNameTitle = this.translateService.instant('tableManagement.accessories.accessoriesManagement');
    super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
  }
  setExcelColumns() {
    const code = (entity: any) => entity.code ?? ''
    const name = (entity: any) => entity.name ?? ''
    const createDate = (entity: any) => entity.createDate ?? ''
    const updateDate = (entity: any) => entity.updateDate ?? ''
    const disabled = (entity: any) => !entity.disabled ?? ''
    this.excelColumns = {
      [this.translateService.instant('general.code')]: { bindField: code },
      [this.translateService.instant('general.name')]: { bindField: name },
      [this.translateService.instant('general.active')]: { bindField: disabled },
    }
  }

}
