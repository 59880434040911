import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { VehicleHtcService } from 'app/modules/vehicle-inventory/services/vehicle-htc.service';
import { CardService } from 'app/rest/card.service';
import { IconService } from 'app/rest/icon.service';
import { Rest } from 'app/rest/rest_client';
import { VehicleService } from 'app/rest/vehicle.service';
import { VehicleUsageService } from 'app/rest/vehicleUsage.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';
import { FineService } from 'app/rest/fine.service';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { DriverAssignmentsService } from 'app/modules/vehicle-inventory/services/driver-assignments.service';
import { VehicleAssignmentsService } from 'app/modules/vehicle-inventory/services/vehicle-assignments.service';
import { TaxService } from 'app/modules/vehicle-inventory/services/table-management/taxes.service';
import { VehicleAccessoryService } from 'app/modules/vehicle-inventory/services/vehicle-accessory.service';
import { FinancingService } from 'app/modules/vehicle-inventory/services/table-management/financing.service';
import { InsuranceService } from 'app/modules/vehicle-inventory/services/table-management/insurance.service';
import { MaintenanceexecutionService } from 'app/rest/maintenanceexecution.service';
import { HtcCardListService } from 'app/modules/vehicle-inventory/services/table-management/htc-card-list.service';

@Component({
  selector: 'app-inventory-htc-vehicle',
  templateUrl: './inventory-htc-vehicle.component.html',
  styleUrls: ['./inventory-htc-vehicle.component.css']
})
export class InventoryHtcVehicleComponent extends EntityFormComponent<RestVehicleInventory.InvVehicleHtcResponse> implements OnInit, OnDestroy {

	//@ViewChild("fineListForm", { static: true }) public fineListForm: FineListComponent;

	/* main */
	vehicle: Rest.Vehicle;
	isDialogVisible: boolean;
	isEditingDisabled: boolean;
	errorMessages: string[];
	vehicleFormTitle: string;
	errorContainer: ElementRef;

	/** historic date panel **/
	historicOptionsList: any[] = [
		{"id": 0, "value": this.translate.instant('general.today')},
		{"id": 1, "value": this.translate.instant('tableManagement.vehicleHtc.thisMonth')},
		{"id": 2, "value": this.translate.instant('tableManagement.vehicleHtc.pastMonth')},
		{"id": 3, "value": this.translate.instant('tableManagement.vehicleHtc.thisYear')},
		{"id": 4, "value": this.translate.instant('tableManagement.vehicleHtc.pastYear')},
		{"id": 5, "value": this.translate.instant('tableManagement.vehicleHtc.lastFiveYears')}, //default
		{"id": 6, "value": this.translate.instant('tableManagement.vehicleHtc.custom')}
	];
	selectedHistoricOptionsList;
	startDate: Date = new Date();
	endDate: Date = this.startDate;
	startDateTxt: string;
	endDateTxt: string;
	today: Date = new Date();
	isHtcLoaded: Boolean = true;

	/* subscriptions */
	getHtcSubscription: Subscription;

	/**  */
   constructor(
      public vehicleHtcService: VehicleHtcService,
		public vehicleService: VehicleService,
      private authService: AuthenticationService,
      public i18n: I18nService,
      public notificationsService: NotificationsService,
		private translate: TranslateService,
		private datePipe: DatePipe,
		public vehicleUsageService: VehicleUsageService,
		public iconService: IconService,
		public htcCardListService: HtcCardListService,
		public fineService: FineService,
		public documentationService: DocumentationService,
		public driverAssignmentService: DriverAssignmentsService,
		public vehicleAssignmentService: VehicleAssignmentsService,
		public vehicleAccessoryService: VehicleAccessoryService,
		public taxService: TaxService,
		public financingService: FinancingService,
		public insuranceService: InsuranceService,
		public executionService: MaintenanceexecutionService


   ) {
      super(vehicleHtcService, notificationsService, i18n, authService);
      this.errorMessages = [];
		this.isEditingDisabled = false;
   }

   ngOnInit() {
		// console.log("[HTC-VEHICLE-FORM] OnInit ***");
   	super.ngOnInit();
		this.getHtcSubscription = this.vehicleHtcService.getVehicleHtc
      	.subscribe(
        	(item: Rest.Vehicle) => {
				// console.log(item);
				this.showDialog(item);
         });
		//console.log(this.fineListForm);

   }

	ngOnDestroy() {
		this.getHtcSubscription.unsubscribe();
	}

	public showDialog(item: Rest.Vehicle): void {
		// console.log("[HTC-VEHICLES-FORM] showDialog ***");
		this.vehicle = item;
		this.init();
		this.isDialogVisible = true;
		this.selectedHistoricOptionsList = this.historicOptionsList[5]; //last 5 years
		this.onChangePeriod();
		// console.log(this.vehicle);
		//console.log(this.fineListForm);
   }

	public init() {
		window.scroll({ top: 0, behavior: 'smooth' });
		this.errorMessages = [];
	}

	public onChangePeriod(event?) {
		// console.log("[HTC-VEHICLES-FORM] onChangePeriod ***");
		// console.log(this.selectedHistoricOptionsList);
		switch (this.selectedHistoricOptionsList.id) {
			case 0: this.onToday();	break;
			case 1: this.onThisMonth(); break;
			case 2: this.onPastMonth(); break;
			case 3: this.onThisYear(); break;
			case 4: this.onPastYear();	break;
			case 5: this.onLast5Years();	break;
			default: this.onCustom(); break;
		}
		this.errorMessages = [];
		this.today = new Date();
	}

	public onToday() {
		// console.log("[HTC-VEHICLES-FORM] onToday ***");
		let now = new Date();
		this.startDate = now;
		this.endDate = now;
	}

	public onThisMonth() {
		// console.log("[HTC-VEHICLES-FORM] onThisMonth ***");
		let now = new Date();
		this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
		this.endDate = now;
	}

	public onPastMonth() {
		// console.log("[HTC-VEHICLES-FORM] onPastMonth ***");
		let now = new Date();
		let newYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
		let newMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
		this.startDate = new Date(newYear, newMonth, 1);
		this.endDate = new Date(newYear, newMonth + 1, 0);
	}

	public onThisYear() {
		// console.log("[HTC-VEHICLES-FORM] onThisYear ***");
		let now = new Date();
		this.startDate = new Date(now.getFullYear(), 0, 1);
		this.endDate = new Date();
	}

	public onPastYear() {
		// console.log("[HTC-VEHICLES-FORM] onPastYear ***");
		let now = new Date();
		this.startDate = new Date(now.getFullYear() - 1, 0, 1);
		this.endDate = new Date(now.getFullYear(), 0, 0);
	}

	public onLast5Years() {
		// console.log("[HTC-VEHICLES-FORM] onPastYear ***");
		let now = new Date();
		this.startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
		this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	}

	public onCustom() {
		// console.log("[HTC-VEHICLES-FORM] onCustom ***");
		/* toast */
		this.notificationsService.add(
			Severity.success,
			this.translate.instant('general.generalInformation') + "!",
			this.translate.instant('tableManagement.vehicleHtc.selectDates'));
	}

	public onStartDateChange() {
		// console.log("[HTC-VEHICLES-FORM] onStartDateChange ***");
		this.selectedHistoricOptionsList = this.historicOptionsList[6]; //custom mode
	}

	public onEndDateChange() {
		// console.log("[HTC-VEHICLES-FORM] onEndDateChange ***");
		this.selectedHistoricOptionsList = this.historicOptionsList[6]; //custom mode
	}

	public onSearch() {
		// console.log("[HTC-VEHICLES-FORM] onSearch ***");
		// console.log(this.selectedHistoricOptionsList);
		/** consultar datos historicos */
		this.isHtcLoaded = false;
		this.startDateTxt = this.datePipe.transform(this.startDate, 'yyyy-MM-dd') + "T00:00:00.000Z";
		this.endDateTxt = this.datePipe.transform(this.endDate, 'yyyy-MM-dd') + "T23:59:59.999Z";
		/* DRIVERS */
		this.driverAssignmentService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* ASSIGNMENTS */
		this.vehicleAssignmentService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* ACCESSORIES */
		this.vehicleAccessoryService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* DOCUMENTS */
		this.documentationService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt), type: 'VEHICLE' });
		/* CARDS */
		this.htcCardListService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* FINES */
		this.fineService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* TAXES */
		this.taxService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* FINANCING */
		this.financingService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });
		/* INSURANCE */
		this.insuranceService.refreshList.next({ vehicleId: this.vehicle.id, startDate: new Date(this.startDateTxt), endDate: new Date(this.endDateTxt) });

	}


}


