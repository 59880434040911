import {enableProdMode, Provider, StaticProvider} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { TranslationProviders } from './app/i18n-providers';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

TranslationProviders.getTranslationFile().then((providers) => {
  const options = { providers };
  //TODO workaround translate from ts: https://github.com/angular/angular/issues/11405
  // here we pass "options.providers" to "platformBrowserDynamic" as extra providers.
  // otherwise when we inject the token TRANSLATIONS it will be empty. The second argument of
  // "bootstrapModule" will assign the providers to the compiler and not our AppModule
  platformBrowserDynamic(options.providers as StaticProvider[]).bootstrapModule(AppModule, options);
});
//platformBrowserDynamic().bootstrapModule(AppModule);
