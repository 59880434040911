import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "./JQueryHTTPClient";

import { EntityService } from "./entity.service";
import { Rest } from "./rest_client";
import { Subject, Subscription } from "rxjs";

@Injectable()
export class BillingaccountService extends EntityService<Rest.BillingAccount> {
  client: Rest.RestApplicationClient;
  startedEditing = new Subject<Rest.BillingAccount>();

  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.BillingAccount): Rest.RestResponse<Rest.BillingAccount> {
    return this.client.BillingAccount_create(arg0);
  }

  update(arg0: Rest.BillingAccount): Rest.RestResponse<Rest.BillingAccount> {
    return this.client.BillingAccount_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.BillingAccount>> {
    return this.client.BillingAccount_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.BillingAccount>> {
    return this.client.BillingAccount_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.BillingAccount_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.BillingAccount> {
    return this.client.BillingAccount_find(arg0, queryParams);
  }

	getList(clientId: number) : Rest.RestResponse<Rest.BillingAccount[]> {
		return this.client.BillingAccount_getAll(clientId);
	}
}
