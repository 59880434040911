import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { I18nService } from '../../../../services/i18n/i18n.service';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-acc-event-form-vt200',
  templateUrl: './acc-event-form-vt200.component.html',
  styleUrls: ['./acc-event-form-vt200.component.css'],
})
export class AccEventFormVt200Component extends ServerValidatedFormComponent implements OnChanges {

  constructor(protected i18n: I18nService) {  super(i18n);}

  @Input() acc: Rest.ACCVT200;

  @ViewChild('accevent') accevent;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
  /**
   * Needed to prevent toggle of UI component
   * @param event
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();

  }

}
