import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "app/services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: 'app-alarm-call-number-times-configuration-ob22',
  templateUrl: './alarm-call-number-times-configuration-ob22.component.html',
  styleUrls: ['./alarm-call-number-times-configuration-ob22.component.css']
})
export class AlarmCallNumberTimesConfigurationOB22Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input()
  alarmCallNumberTimes: Rest.AlarmCallNumberTimesConfigurationOB22;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.alarmCallNumberTimes);
    }
  }
}
