import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { DistanceFormatPipe } from "../../../shared/DistanceFormatPipe";
import { I18nService } from "app/services/i18n/i18n.service";
@Component({
  selector: "app-milage-config-form",
  templateUrl: "./milage-config-form.component.html",
  styleUrls: ["./milage-config-form.component.css"],
  providers: [DistanceFormatPipe],
})
export class MilageConfigFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  constructor(
    private distanceFormat: DistanceFormatPipe,
    protected i18n: I18nService
  ) {
    super(i18n);
  }

  @Input() deviceParams: Rest.DeviceParameters;
  mileageSwitch: boolean;

  @ViewChild("configmileage", { static: true }) configmileage;

  /**Mileage Event */
  availablemileageConfigModes: SelectItem[];
  Sensors: Rest.Sensor[];
  mileage: number = 0;

  ngOnInit() {
    // Mileage Config Fields
    this.availablemileageConfigModes = RestExt.getEnumItems(
      RestExt.MileageConfigModes,
      this.i18n
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;

    if (this.deviceParams != null)
      this.mileage = this.distanceFormat.systemMetricToUserMetric(
        this.deviceParams.proprietaryParameters.mileageConfiguration.mileage
      );
    this.mileageSwitch =
      this.deviceParams.proprietaryParameters.mileageConfiguration.mode ==
        RestExt.MileageConfigModes[RestExt.MileageConfigModes.DISABLE] ||
      this.deviceParams.proprietaryParameters.mileageConfiguration.mode ==
        undefined;
    this.disableMileageConfigDIV(this.mileageSwitch);
  }

  mileageChanged() {
    if (this.deviceParams != null)
      this.deviceParams.proprietaryParameters.mileageConfiguration.mileage =
        this.distanceFormat.userMetricToSystemMetric(this.mileage);
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.deviceParams.proprietaryParameters.mileageConfiguration.mode = RestExt
      .MileageConfigModes[
      RestExt.MileageConfigModes
        .MILEAGE_WILL_BE_ACCUMULATED_ONLY_IF_THE_ACC_IS_ON
    ] as Rest.MileageConfigModes;
    this.disableMileageConfigDIV(!event.checked);
  }

  disableMileageConfigDIV(disable: Boolean) {
    this.mileageSwitch = !disable;

    if (disable) {
      this.deviceParams.proprietaryParameters.mileageConfiguration.mode =
        RestExt.MileageConfigModes[
          RestExt.MileageConfigModes.DISABLE
        ] as Rest.MileageConfigModes;
      this.configmileage.nativeElement.classList.add("disableDIV");
    } else {
      this.configmileage.nativeElement.classList.remove("disableDIV");
    }
  }

  mileageConfigModeChange(event) {
    this.mileageSwitch =
      this.deviceParams.proprietaryParameters.mileageConfiguration.mode ==
      RestExt.MileageConfigModes[RestExt.MileageConfigModes.DISABLE];
    this.disableMileageConfigDIV(this.mileageSwitch);
  }
}
