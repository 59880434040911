<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display">

  <div class="container-md">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>


    <!-- #FORM BODY -->

    <!-- ROW 1 -->
    <div class="row mt-4">
      <form-input [required]="true" i18n-fieldName fieldName="{{'control-panel.poi-categories.poiName' | translate}}"
        [errors]="errors.name">
        <input pInputText id="name" [(ngModel)]="entity.name" />
      </form-input>
    </div>

    <!-- ROW 2 -->
    <div class="row mt-4 mb-4">
      <form-input i18n-fieldName fieldName="{{'control-panel.poi-categories.poiEnabled' | translate}}"
        [errors]="errors.enabled">
        <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
      </form-input>
    </div>

    <!-- ROW 3 -->
    <div class="row mt-1 mb-1">
      <p class="text-center">{{clientImageLabel}}</p>
    </div>

    <!-- ROW 4: PROFILE IMAGE & ADD IMAGE BUTTON -->
    <div class="row mt-2 mb-4">

      <!-- PROFILE IMAGE -->
      <img #image src="{{clientEncodedImage}}" class="custom-user-profile-image mx-auto d-block p-0">

      <!-- ADD IMAGE BUTTON -->
      <div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
        <div id="inner-icon-holder" class="d-flex align-items-center justify-content-center"
          (click)='uploadImageInput.click()'>
          <img src="assets/icons/camera-plus.png" class="custom-user-profile-icon" />
          <input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png' (change)='imageChange($event)'>
        </div>
      </div>
    </div>
  </div>

  <p-footer>

    <div class="d-flex justify-content-center bd-highlight">

      <div class="p-2 bd-highlight">

        <div *ngIf="disableSave === false">
          <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
        </div>

        <div *ngIf="disableSave === true">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
              label="{{'general.save' | translate}}"></button>
          </div>
        </div>

      </div>

      <div class="p-2 bd-highlight">

        <div *ngIf="isNew === false">
          <div *ngIf="disabeChangeStatus === false">
            <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
              label="{{'general.changeStatus' | translate}}"></button>
          </div>
          <div *ngIf="disabeChangeStatus === true">
            <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status"
              tooltipPosition="top">
              <button disabled="true" type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)"
                i18n-label label="{{'general.changeStatus' | translate}}"></button>
            </div>
          </div>
        </div>

      </div>

    </div>

  </p-footer>

</p-dialog>