<p-dialog i18n-header header="Report" [(visible)]="display" [responsive]="true" showEffect="fade"
          [modal]="true" *ngIf="display">

    <div class="ui-grid ui-grid-responsive ui-fluid">
        <div class="ui-grid-row">

            <div class="ui-grid-col-12">
                    <form-input i18n-fieldName [required]="true" fieldName="Name" [errors]="errors.name">
                <input type="text" pInputText i18n-placeholder placeholder="Settings name" [(ngModel)]=entity.name>
            </form-input>
            </div>

        </div>

        <div class="ui-g-12 ui-md-6">
            <form-input i18n-fieldName [required]="true" fieldName="Type" [errors]="errors.type">
                <p-dropdown i18n-placeholder placeholder="Select a Type" [options]="availableTypes"
                    [(ngModel)]="entity.type" [style]="{'width':'190px'}" filter="filter"></p-dropdown>
            </form-input>
        </div>

        <div class="ui-grid-row">
            <div class="ui-grid-col-6">
                    <form-input i18n-fieldName [required]="true" fieldName="Start" [errors]="errors.periodStart">
                            <p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="periodStart"  dateFormat={{i18n.dateFormat}} showIcon="true" styleClass="ui-column-filter"></p-calendar>
                    </form-input>
                </div>

            <div class="ui-grid-col-6">
                    <form-input i18n-fieldName [required]="true" fieldName="End" [errors]="errors.periodEnd">
                            <p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="periodEnd"  dateFormat={{i18n.dateFormat}} showIcon="true" styleClass="ui-column-filter"></p-calendar>
                    </form-input>
            </div>

        </div>

        <app-fleet-tree [viewDisableds]=false [(selection)]="fleetsGroupsAndVehiclesSelected" [selectableEntities]=false [cascadeSelection]=true></app-fleet-tree>


        <p-footer>
            <div  class="ui-dialog-buttonpane ui-helper-clearfix">
                <button (click)=save() i18n>Create</button>
            </div>
        </p-footer>
    </div>
</p-dialog>
