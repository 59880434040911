<p-accordionTab *ngIf="outputPattern" i18n-header header="Output pattern configuration">

  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Output Pattern</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="outputPattern.enable"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!outputPattern.enable>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldName fieldName="port" [errors]="errors.name">
        <input pInputText id="port" [(ngModel)]="outputPattern.port" [min]="1" [max]="4" />
        <span class="ui-inputgroup-addon">1~4</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fieldName="toggle" [errors]="errors.toggle">
        <p-inputSwitch [(ngModel)]="outputPattern.toggle"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div *ngIf="outputPattern.toggle">

        <div class="ui-g-12 ui-md-6">
          <form-input [required]="true" i18n-fieldname fieldName="Toggle duration" [errors]="errors.toggleDuration">
            <p-spinner id="outputPatternToggleDuration" size="5" [(ngModel)]="outputPattern.toggleDuration" [min]="0" [max]="255"></p-spinner>
            <span class="ui-inputgroup-addon">0~255 * 100ms</span>
            <span class="ui-inputgroup-addon" i18n>ms</span>
          </form-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <form-input [required]="true" i18n-fieldname fieldName="Toggle times" [errors]="errors.toggleTimes">
            <p-spinner id="toggleTimes" size="5" [(ngModel)]="outputPattern.toggleTimes" [min]="0" [max]="255"></p-spinner>
            <span class="ui-inputgroup-addon">0~255 * 100ms</span>
            <span class="ui-inputgroup-addon" i18n>times</span>
          </form-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <form-input [required]="true" i18n-fieldname fieldName="final value" [errors]="errors.setOn">
            <p-inputSwitch [(ngModel)]="outputPattern.setOn"></p-inputSwitch>
          </form-input>
        </div>
      </div>

      <div *ngIf="!outputPattern.toggle">

        <div class="ui-g-12 ui-md-6">
          <form-input [required]="true" i18n-fieldname fieldName="Set on" [errors]="errors.setOn">
            <p-inputSwitch [(ngModel)]="outputPattern.setOn"></p-inputSwitch>
          </form-input>
        </div>

      </div>

    </div>

  </div>
</p-accordionTab>
