import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-global-parameters-gl320m',
  templateUrl: './global-parameters-gl320m.component.html',
  styleUrls: ['./global-parameters-gl320m.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalParametersGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  gpsOptions: any = [
    { label: "Do not turn off GPS chip", value: "DISABLE" },
    { label: "Turn off GPS chip after retrieving GPS information every time", value: "TURN_OFF_AFTER_GPS_INFORMATION" },
    { label: "Do not turn off GPS chip in ignition on state or movement state", value: "DISABLE_TURN_OFF_IN_IGNITION_OR_MOVEMENT" },  
  ];
  agpsOptions: any = [
    { label: "Disable AGPS function", value: "DISABLE" },
    { label: "AGPS offline mode", value: "OFFLINE" },
  ]
  ledOnOptions: any = [
    { label: "Each time after the device powers on", value: "EACH_TIME_AFTER_DEVICE_POWER_ON" },
    { label: "All LEDs work normally", value: "LEDS_NORMALLY" },
    { label: "All LEDs are off always", value: "LEDS_ALWAYS_OFF" },
  ]
  epbOptions: any = [
    { label: "Disable external power control unit with built-in motion sensor.", value: "DISABLE" },
    { label: "Enable external power control unit with built-in motion sensor.", value: "ENABLE" },
  ]
  gsmOptions: any = [
    { label: "Do not allow the cell information report", value: "CELL_INFORMATION_NOT_ALLOWED" },
    { label: "Allow the cell information report after failing to get GPS position if cell information is available", value: "ALLOW_CELL_INFORMATION_AFTER_FAILING_GPS_POSITION" },
    { label: "Report the message +RESP:GTGSM after each successful GPS fix if cell information is available", value: "REPORT_GTGSM_AFTER_SUCCESSFULL_GPS" },
    { label: "Report the message +RESP:GTGSM regardless of getting GPS position is successful or not if cell information is available", value: "REPORT_GTGSM" },
  ]
  walkingOptions: any = [
    { label: "Disable the walking mode", value: "DISABLE" },
    { label: "Enable the walking mode", value: "ENABLE" },
  ]
  @Input() globalParameters: Rest.GlobalParametersGL320M;
  


  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.globalParameters);
    }
  }
}