import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-accalarm-configuration-we-track2',
  templateUrl: './accalarm-configuration-we-track2.component.html',
  styleUrls: ['./accalarm-configuration-we-track2.component.css'],
})
export class AccalarmConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit {

  @ViewChild('ContentDiv', { static: true }) ContentDiv;
  @Input() accAlarm: Rest.ACCAlarmConfigurationWeTrack2
  constructor(protected i18n: I18nService) {  super(i18n);}

  AlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"GPRS_SMS"},    
  ];

  ACCOptions: any = [
    {label: "ACC changed alarm", value:"ACC_CHANGED_ALARM"},
    {label: "ACC turn to off alarm", value: "ACC_TURN_OFF_ALARM"},
    {label: "ACC turn to on alarm", value:"ACC_TURN_ON_ALARM"}
  ]

  ngOnInit() {    
    if(this.accAlarm!=null){this.disableDIV(!this.accAlarm.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.accAlarm);
    }
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
