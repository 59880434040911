<!-- [sortField]="sortField" [sortOrder]="sortOrder" [rowStyleClass]="onRowRender"  scrollWidth="${showMapMode == false ? 100vw : 100%}" -->
<p-dialog [responsive]="true" [contentStyle]="{minHeight: '300px'}" [(visible)]="openEventsByDates" dismissableMask=true
    closeOnEscape=true responsive=true modal=true class="dialogSummary">
    <p-header>
        <h3 i18n class="component_in_line1">Esdeveniments</h3>
    </p-header>

  <!--  <p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" (onSelect)="loadReportsData()"  dateFormat={{i18n.dateFormat}} [(ngModel)]="rangeDates" selectionMode="range" readonlyInput="true"></p-calendar> -->

  <div style="margin-bottom: 5px;">

    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1 m-2">
        <div>
          <label id='startDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
            {{ 'general.startDate' | translate }}:
          </label>
          <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayIniSelected" (onSelect)="loadReportsData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
        </div>
      </div>
    
      <div class="flex-grow-1 m-2">
        <div>
          <label id='endDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
            {{ 'general.endDate' | translate }}:
          </label>
          <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayEndSelected" (onSelect)="loadReportsData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
        </div>
      </div>
    
      <div>
        <div class="buttonHistoryEventsExcel text-end">
          <button class="ui-button-success" (click)="exportPoCsReportExcel()" pButton type="button" i18n-label label="Excel"></button>
        </div>
      </div>
    </div>

    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

 
  </div>


  <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
 -->

  <!-- PrimeNG DataTable -->
  <p-table #table
           [value]="rowsVisibles"
           [paginator]="true"
           [rows]="100"
           [autoLayout]="true"
           scrollHeight="74.0vh"
           [scrollable]="false"
           styleClass="p-datatable-sm"
  >

    <!-- Table Column/Header Definition -->
    <ng-template pTemplate="header" let-columns>
      <tr>

        <th>
          Nº
        </th>

        <th *ngFor="let col of cols" [hidden]="!col.visible">
          <div>
            <!-- HEADER -->
            <a [title]="col.header">
              <div class="overflow breakLongWords" *ngIf="expandedHeaders === true">
                {{col.header}}
              </div>
              <div class=" overflow breakLongWords" *ngIf="expandedHeaders === false">
                {{col.shortHeader}}
              </div>
            </a>
          </div>
        </th>

      </tr>
    </ng-template>

    <!-- Table Row Definition -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
      <tr>

        <td>
          <div class="indexCol">{{i+1}}</div>
        </td>

        <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">

          <td *ngSwitchCase="'controlPoint'" [hidden]="!col.visible">
            <div [title]="rowData.controlPoint.name" class="overflow cellClicable">
              {{rowData.controlPoint.name}}
            </div>
          </td>

          <td *ngSwitchCase="'lastVigilanceDate'" [hidden]="!col.visible">
            <div  [title]="rowData.lastVigilanceDate | date: 'short'" class="overflow">
            <span [attr.id]="rowData.vigilance_manual ? 'outstanding-field' : null">
              {{rowData.lastVigilanceDate | date: 'short'}}
            </span>
            </div>
          </td>

          <td *ngSwitchCase="'lastMaintenanceDate'" [hidden]="!col.visible">
            <div [title]="rowData.lastMaintenanceDate | date: 'short'" class="overflow">
            <span  [attr.id]="rowData.maintenance_manual ? 'outstanding-field' : null" >
              {{rowData.lastMaintenanceDate | date: 'short'}}
            </span>
            </div>
          </td>

          <td *ngSwitchCase="'maintenanceLeftTime'" [hidden]="!col.visible">
            <div  [title]="rowData.maintenanceLeftTime | millisToHumanReadable" class="overflow">
              {{rowData.maintenanceLeftTime | millisToHumanReadable}}
            </div>
          </td>

          <td *ngSwitchCase="'vigilanceLeftTime'" [hidden]="!col.visible">
            <div [title]="rowData.vigilanceLeftTime | millisToHumanReadable" class="overflow">
              {{rowData.vigilanceLeftTime | millisToHumanReadable}}
            </div>
          </td>

          <td *ngSwitchCase="'minorTimeRemaining'" [hidden]="!col.visible">
            <div  [title]="rowData.minorTimeRemaining | millisToHumanReadable" class="overflow">
              {{rowData.minorTimeRemaining | millisToHumanReadable}}
            </div>
          </td>

          <td *ngSwitchCase="'vehicle'" [hidden]="!col.visible">
            <div [title]="rowData.vehicle.name" class="overflow cellClicable">
              {{rowData.vehicle.name}}
            </div>
          </td>


        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" >
      <tr>
        <td colspan="7">
          <p i18n>No s'han trobat registres</p>
        </td>
      </tr>
    </ng-template>

  </p-table>


  <!--<p-dataTable  [rows]="100" [paginator]="true"  #table responsive="true" [value]="rowsVisibles" scrollHeight="76.8vh"  scrollWidth="calc(100% + 88px)" >

      &lt;!&ndash; INDEX COLUMN &ndash;&gt;
      <p-column  class="" header="Nº" styleClass="indexCol  overflow ellipsis">
          <ng-template  class="indexCol" let-i="rowIndex" pTemplate="rowExpansion">
              <div class="indexCol">{{i+1}}</div>
          </ng-template>
      </p-column>

      &lt;!&ndash; CUSTOM COLUMNS &ndash;&gt;
      <p-column width="auto" *ngFor="let col of cols" [field]="col.field" [header]="col.header" [hidden]="!col.visible">
          &lt;!&ndash; COLUMN WIH FILTER AND ORDER&ndash;&gt;
        &lt;!&ndash; <ng-template  pTemplate="header">
              <div>
                  <a [title]="col.header">
                      <div class="overflow ellipsis breakLongWords" *ngIf="expandedHeaders == true">
                          {{col.header}}
                      </div>
                      <div class=" overflow ellipsis breakLongWords" *ngIf="expandedHeaders == false">
                          {{col.shortHeader}}
                      </div>
                  </a>
              </div>
          </ng-template> &ndash;&gt;


      &lt;!&ndash; ___________________________________ROWS______________class="overflow ellipsis cellClicable"_____________________ &ndash;&gt;

         &lt;!&ndash; COLUMN WIH FILTER AND ORDER&ndash;&gt;
         <ng-template  pTemplate="header">
          <div>
              &lt;!&ndash; HEADER &ndash;&gt;
              <a [title]="col.header">
                  <div class="overflow ellipsis breakLongWords" *ngIf="expandedHeaders == true">
                      {{col.header}}
                  </div>
                  <div class=" overflow ellipsis breakLongWords" *ngIf="expandedHeaders == false">
                      {{col.shortHeader}}
                  </div>
              </a>
          </div>
      </ng-template>



          &lt;!&ndash;CUSTOM ROWS&ndash;&gt;
          <ng-template *ngIf="col.field === 'controlPoint'" let-row="rowData" pTemplate="body">
              <div  (click)="controlPointClicked(row)" [title]="row.controlPoint.name" class="overflow ellipsis cellClicable">
                  {{row.controlPoint.name}}
              </div>
          </ng-template>
          <ng-template *ngIf="col.field === 'lastVigilanceDate'" let-row="rowData" pTemplate="body">
              <div [title]="row.lastVigilanceDate | date: 'short'" class="overflow ellipsis">
                  {{row.lastVigilanceDate | date: 'short'}}
              </div>
          </ng-template>
          <ng-template *ngIf="col.field === 'lastMaintenanceDate'" let-row="rowData" pTemplate="body">
              <div  [title]="row.lastMaintenanceDate | date: 'short'" class="overflow ellipsis">
                  {{row.lastMaintenanceDate | date: 'short'}}
              </div>
          </ng-template>

         &lt;!&ndash; <ng-template *ngIf="col.field == 'maintenanceLeftTime'" let-row="rowData" pTemplate="body">
              <div  [title]="row.maintenanceLeftTime | millisToHumanReadable" class="overflow ellipsis">
                  {{row.maintenanceLeftTime | millisToHumanReadable}}
              </div>
          </ng-template>
          <ng-template *ngIf="col.field == 'vigilanceLeftTime'" let-row="rowData" pTemplate="body">
              <div [title]="row.vigilanceLeftTime | millisToHumanReadable" class="overflow ellipsis">
                  {{row.vigilanceLeftTime | millisToHumanReadable}}
              </div>
          </ng-template>
          <ng-template *ngIf="col.field == 'minorTimeRemaining'" let-row="rowData" pTemplate="body">
              <div  [title]="row.minorTimeRemaining | millisToHumanReadable" class="overflow ellipsis">
                  {{row.minorTimeRemaining | millisToHumanReadable}}
              </div>
          </ng-template>

          &ndash;&gt;
          <ng-template *ngIf="col.field === 'vehicle'" let-row="rowData" pTemplate="body">
              <div (click)="vehicleClicked(row)" [title]="row.vehicle.name" class="overflow ellipsis cellClicable">
                  {{row.vehicle.name}}
              </div>
          </ng-template>


      </p-column>



  </p-dataTable>-->

    <p-footer>
    <button pButton type="button" (click)="closeButton()" i18n-label label="Tancar"></button>
    </p-footer>
</p-dialog>
