import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewChild,
    OnDestroy,
} from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";

import { CustomMapComponent } from "../../shared/custom-map/custom-map.component";
import {
    ContextMenuOption,
    CreateZoneMarkerEvent,
    CustomMapOptions,
    DrawZoneType,
    SearchProximityVehicles,
} from "../../shared/custom-map/custom-map.model";
import { PoicategoryService } from "../../../rest/poicategory.service";
import { Rest } from "../../../rest/rest_client";
import { RealtimeService } from "../../../rest/realtime.service";
import { ZonegroupService } from "../../../rest/zonegroup.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import {
    RealTimeDataService,
    MapMessage,
} from "../../../services/rt-data/rt-data.service";
import { ZoneFormComponent } from "../../zones/zone-form/zone-form.component";
import { VehicleProximityDialog } from "../map-menu/vehicle-proximity-dialog/vehicle-proximity-dialog";
import { TranslateService } from '@ngx-translate/core';
import { PoiFormComponent } from '../../pois/poi-form/poi-form.component';

@Component({
    selector: "app-rt-map",
    templateUrl: "./rt-map.component.html",
    styleUrls: ["./rt-map.component.css"],
    providers: [
        RealtimeService,
        ConfirmationService,
        PoicategoryService,
        ZonegroupService,
    ],
})
export class RtMapComponent implements OnInit, OnChanges, OnDestroy {
    private alive: boolean = true;

    searchProximity: SearchProximityVehicles;
    availableSettings: SelectItem[];
    VehiclesInfo: Array<RestExt.ExtendedVehicleDataMessage> = []; //Lista de vehiculos que se pintan en el mapa
    //Internal copy of the selected settings. Keeps changes that are not ment to be communicated to parent components
    _selectedSettings: Rest.MapSettings;
    @Input() selectedSettings: Rest.MapSettings;
    @Output() selectedSettingsChange: EventEmitter<Rest.MapSettings> =
        new EventEmitter<Rest.MapSettings>();
    @ViewChild("VehicleProximityDialog", {static: true})
    VehicleProximityDialog: VehicleProximityDialog;
    @ViewChild(CustomMapComponent, {static: true}) map: CustomMapComponent;
    @ViewChild(ZoneFormComponent, {static: true}) zoneForm: ZoneFormComponent;
    @ViewChild(PoiFormComponent, {static:true}) poiForm: PoiFormComponent;

    displaySettingsForm = false;
    customMapOptions: CustomMapOptions;

    displayZoneGroupsSelector = false;
    displayPOICategoriesSelector = false;

    /**
     * Vehicles that must be centered
     */
    private centeredVehicles: number[];

    pois: Rest.POI[];
    zones: Rest.Zone[];
    //Backup of the zones in case a new zone is drawn and not commited
    private zonesBkp: Rest.Zone[];

    constructor(
        private i18n: I18nService,
        private rt: RealtimeService,
        private notificationsService: NotificationsService,
        public rtDataService: RealTimeDataService,
        private auth: AuthenticationService,
        public poiCategoryService: PoicategoryService,
        public zoneGroupService: ZonegroupService,
        public translateService: TranslateService
    ) {
        this.pois = [];
        this.zones = [];
        this.zonesBkp = [];
        var that = this;
        this.customMapOptions = {
            contextMenuOptions: [
                <ContextMenuOption>{
                    id: "1",
                    label: this.translateService.instant('real-time.mapOptions.zoneCircular'),
                    callback: async function () {
                        that.customMapOptions.drawZoneType = DrawZoneType.Circle;
                        that.customMapOptions.drawZones = true;
                        that.customMapOptions.createPOIonClick = false;
                        that.map.reConfigMap();
                    },
                },

                {
                    id: "2",
                    label: this.translateService.instant('real-time.mapOptions.zonePolygonal'),
                    callback: async function () {
                        that.customMapOptions.drawZoneType = DrawZoneType.Polygon;
                        that.customMapOptions.drawZones = true;
                        that.customMapOptions.createPOIonClick = false;
                        that.map.reConfigMap();
                    },
                },

                {
                    id: "3",
                    label: this.translateService.instant('real-time.mapOptions.vehicleProximity'),
                    callback: function (event: any, position: any) {
                        if (position != null) {
                            that.getProximityPoint(position, "EPSG:4326", "time");
                        }
                    },
                },
                {
                    id: "4",
                    label: this.translateService.instant('real-time.mapOptions.printMap'),
                    callback: function (event: any, position: any) {
                        that.map.getMapImage();
                    },
                },
                {
                    id:"5",
                    label: this.translateService.instant('real-time.mapOptions.poi'),
                    callback: function () {
                        that.customMapOptions.createPOIonClick = true;
                        that.customMapOptions.drawZones = false;
                        that.map.createPOI();
                    },
                }
            ],
        };
    }

    detach() {
        this.rtDataService.detachMap();
    }

    /**
     * Whenever the map is clicked, stop following the vehicles (if any)
     *
     * @memberof RtMapComponent
     */
    stopFollow() {
        if (this.centeredVehicles != null) console.log("stopFollow");
        this.centeredVehicles = null;
    }

    async getProximityPoint(lonLat: any, sysCoord: string, costType: string) {
        var numMax = 5;
        this.searchProximity = {
            x: lonLat.getLon(),
            y: lonLat.getLat(),
            srs: sysCoord,
            num: numMax,
            rad: 0,
            weight: costType,
            inverse: true,
            mos: null,
        };
        var moList = [];

        for (var i = 0 ; i < this.VehiclesInfo.length ; i++) {
            moList[i] =
                this.VehiclesInfo[i].location.latitude +
                "," +
                this.VehiclesInfo[i].location.longitude +
                "|" +
                this.VehiclesInfo[i].vehicleId;
        }

        this.searchProximity.mos = moList;

        this.VehicleProximityDialog.show(this.searchProximity);
    }

    ngOnInit() {
        var that = this;
        this.availableSettings = [];
        this._selectedSettings = emptySettings;
        this.settingsChanged();
        this.centeredVehicles = null;

        this.map.eventCreateZone
            .takeWhile(() => this.alive)
            .subscribe((z: CreateZoneMarkerEvent) => {
                if (z.zone.zoneType == DrawZoneType.Circle) {
                    that.zoneForm.entity.radius = z.zone.radius;
                    that.zoneForm.entity.center = {
                        latitude: z.zone.centerLat,
                        longitude: z.zone.centerLon,
                    } as Rest.Vertex;
                    that.zoneForm.entity.circular = true;
                    that.zoneForm.entity.name = "";
                    that.zoneForm.entity.eventOnEnter = false;
                    that.zoneForm.entity.eventOnLeave = false;
                    that.zoneForm.entity.rawPolygonVertexList = null;
                } else {
                    that.zoneForm.entity.rawPolygonVertexList = z.zone.wkt;
                    that.zoneForm.entity.circular = false;
                    that.zoneForm.entity.name = "";
                    that.zoneForm.entity.eventOnEnter = false;
                    that.zoneForm.entity.eventOnLeave = false;
                    that.zoneForm.entity.id = null;
                    that.zoneForm.entity.center = {
                        latitude: null,
                        longitude: null,
                    } as Rest.Vertex;
                }

                that.zoneForm.disableZoneTypeSelector = true;
                that.zoneForm.title = this.i18n._("Create Zone");

                that.zoneForm.create(this.zoneForm.entity);
                if (this.zonesBkp) this.zones = [...this.zonesBkp];
            });

        this.zoneForm.return
            .takeWhile(() => this.alive)
            .subscribe((entity) => {
                if (entity != undefined && entity["id"] > 0) {
                    that.notificationsService.add(
                        Severity.success,
                        that.i18n._(":)"),
                        that.i18n._("Zone created")
                    );
                    //Reload visible zones
                    if (that._selectedSettings != null)
                        that.visibleZoneGroupsChanged(
                            that._selectedSettings.visibleZoneGroups
                        );
                }
            });

        this.rtDataService.vehicleInfo
            .takeWhile(() => this.alive)
            .subscribe((vi) => {
                let isVisible =
                    this.rtDataService.watchedVehicles
                        .getValue()
                        .find((v) => v.visibleInMap && v.id == vi.vehicleId) != null;
                if (isVisible) {
                    //Draw the vehicle
                    this.map.addVehicle(vi);

                    if (
                        that.VehiclesInfo.findIndex((x) => x.vehicleId === vi.vehicleId) ===
                        -1
                    ) {
                        that.VehiclesInfo.push(vi);
                    }
                    //If vehicles should be centered, center them
                    if (this.centeredVehicles != null) {
                        this.map.centerMap(this.centeredVehicles);
                    }
                } else {
                    that.VehiclesInfo = that.VehiclesInfo.filter(
                        (x) => x.vehicleId !== vi.vehicleId
                    );
                }
            });

        this.rtDataService.mapInbox
            .takeWhile(() => this.alive)
            .subscribe((message) => {
                switch (message.tag) {
                    case "focus":
                        //Update the centered vehicles var
                        this.centeredVehicles = message.payload;
                        //Center the map
                        this.map.centerMap(this.centeredVehicles);
                        break;
                    case "showRoute":
                        this.map.addVehicleRoute(
                            message.payload.vid,
                            message.payload.segments,
                            message.payload.focus
                        );
                        break;
                    case "hideRoute":
                        this.map.removeVehicleRoute(message.payload);
                        break;
                    default:
                        console.error("Unknown message tag: " + message.tag);
                }
            });

        //Whenever visible vehicles change, remove non visible vehicles
        this.rtDataService.watchedVehicles
            .takeWhile(() => this.alive)
            .subscribe((wv) => {
                this.map.keepVehicles(
                    wv.filter((v) => v.visibleInMap).map((v) => v.id)
                );
            });
        
        //when create poi event fires open poiform component
        this.map.eventCreatePOI.takeWhile(() => this.alive)
            .subscribe((z:any) => {
                console.log(JSON.stringify(z));
                console.log("EVENT FIRED");
                console.log(that.poiForm);

                that.poiForm.entity.municipality = z.municipality;
                that.poiForm.entity.province = z.subregion;
                that.poiForm.entity.postalCode = z.postalCode;
                that.poiForm.entity.address = z.address;
                that.poiForm.entity.timeZone = z.timezone;
                that.poiForm.entity.position = {
                    latitude: z.lat,
                    longitude: z.lon,
                } as Rest.Vertex;
                //this.poiForm.lat
                that.poiForm.create(that.poiForm.entity);

        });
        this.poiForm.return
            .takeWhile(() => this.alive)
            .subscribe((entity) => {
                that.poiForm.entity = {} as Rest.POI;
                if (entity != undefined && entity["id"] > 0) {
                    that.notificationsService.add(
                        Severity.success,
                        that.i18n._(":)"),
                        that.i18n._("Poi created")
                    );
                    //Reload visible zones
                    if (that._selectedSettings != null)
                        that.visiblePOICategoriesChanged(
                            that._selectedSettings.visiblePOICategories
                        );
                }
            });
    }

    ngAfterViewInit() {

    }

    visibleZoneGroupsChanged(g: Rest.ZoneGroup[]) {
        let i = 0;
        this.zoneGroupService.findAll(g.map((z) => z.id)).then((zones) => {
            let zonesAux = [];
            zones.forEach((z) => (zonesAux = zonesAux.concat(z.zones)));
            this.zones = zonesAux;
            this.zonesBkp = [...zonesAux];
        });
    }

    visiblePOICategoriesChanged(g: Rest.POICategory[]) {
        let i = 0;
        this.poiCategoryService.findAll(g.map((p) => p.id)).then((cats) => {
            let poisAux = [];
            cats.forEach((cat) => {
                cat.pois.forEach((poi) => (poi.poiCategory = cat));
                poisAux = poisAux.concat(cat.pois);
            });
            this.pois = poisAux;
        });
    }

    ngOnChanges(changes) {
        //console.log("ngOnChanges");
        if (changes["selectedSettings"]) {
            if (this.selectedSettings.id == -1) return;
            if (
                this._selectedSettings &&
                this._selectedSettings.id == this.selectedSettings.id
            )
                return;

            this._selectedSettings = this.selectedSettings;

            this.settingsChanged();
        }
    }

    settingsChanged() {
        return this.rt.getMapSettings().then((settings: Rest.MapSettings[]) => {
            this.availableSettings = RestExt.entitiesToSelectItems(settings);
            if (this.availableSettings.length == 0)
                this.availableSettings.push(<SelectItem>{
                    label: "-",
                    value: Object.assign({}, emptySettings),
                });
            //There is no selected settings or it is no longer available. Select the first available one
            if (
                this._selectedSettings == null ||
                settings.find((as) => as.id == this._selectedSettings.id) == null
            ) {
                this._selectedSettings = this.availableSettings[0].value;
            } else {
                this._selectedSettings = this.availableSettings.find(
                    (as) => as.value.id == this._selectedSettings.id
                ).value;
            }
            this.pois = [];
            this.zones = [];
            this.zonesBkp = [];
            if (this._selectedSettings.id == -1) {
                this.selectedSettingsChange.emit(this._selectedSettings);
            } else {
                //Retrieve the full data associated with the settings
                this.rt
                    .findMapSettings(this._selectedSettings.id + "")
                    .then((settings: Rest.MapSettings) => {
                        this.availableSettings.find(
                            (as) => as.value.id == settings.id
                        ).value = settings;
                        this.availableSettings = [...this.availableSettings];
                        this._selectedSettings = settings;

                        this.visibleZoneGroupsChanged(settings.visibleZoneGroups);
                        this.visiblePOICategoriesChanged(settings.visiblePOICategories);

                        this.selectedSettingsChange.emit(settings);
                    });
            }
        });
    }

    showPosition(marker: any) {
        if (marker != null) {
            this.map.map.addMarker(marker);
        } else {
            this.map.deleteMarker(marker);
        }
    }

    /**
     * Store a new settings object in the server
     */
    create() {
        this._selectedSettings.id = 0;
        this.rt
            .createMapSettings(this._selectedSettings)
            .then((settings: Rest.MapSettings) => {
                this._selectedSettings = settings;
                this.settingsChanged();
                this.displaySettingsForm = false;
                this.notificationsService.add(
                    Severity.success,
                    this.i18n._(":)"),
                    this.i18n._("Entity updated")
                );
            });
    }

    /**
     * Update an existing settings object
     */
    update() {
        this.rt
            .updateMapSettings(this._selectedSettings)
            .then((settings: Rest.MapSettings) => {
                this.settingsChanged();
                this.displaySettingsForm = false;
                this.notificationsService.add(
                    Severity.success,
                    this.i18n._(":)"),
                    this.i18n._("Entity updated")
                );
            });
    }

    /**
     * Delete an existing settings object
     */
    delete() {
        this.rt.deleteMapSettings(this._selectedSettings.id + "").then(() => {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._("Entity deleted")
            );
            this.settingsChanged();
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }
}

const emptySettings = <Rest.MapSettings>{
    id: -1,
    visibleZoneGroups: [],
    visiblePOICategories: [],
};
