<p-accordionTab>

    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Roaming Event Toggle</span>
            </div>
            <div class="right">
                <p-inputSwitch   (onChange)="handleChange($event)"  [(ngModel)]="roaming.enabled"></p-inputSwitch>
            </div>
        </div>
    </p-header>

    <div class="ui-g" [class.disableDIV]=!roaming.enabled>
     
      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="generate event" [errors]="errors.generateEvent">
            <p-inputSwitch [(ngModel)]="roaming.generateEvent"></p-inputSwitch>
        </form-input>
      </div>
    </div>

</p-accordionTab>