<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Analog Input Configuration ($ST+AREPORT)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="AnalogInput Mode" >
            <p-dropdown  (onChange)="trackEventModeChange(analogInput.mode,$event)" [options]="ModeOptions"
            [(ngModel)]="analogInput.mode" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>

        <div [class.disableDIV]=!trackModeSwitch>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Report Action" >
            <p-dropdown [options]="ReportModeOptions"
              [(ngModel)]="analogInput.reportAction" [style]="{'width':'190px'}"></p-dropdown>
            </form-input>
          </div>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Analog Input Id" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="analogInput.analogInputId" [min]="9" [max]="10" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">9~10</span>
              </div>
            </form-input>
          </div>
                  
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Voltage Min Value" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="analogInput.voltageMinValue" [min]="0" [max]="30"></p-spinner>
              <span class="ui-inputgroup-addon">0~30</span>
              <span class="ui-inputgroup-addon" >v</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Voltage Max Value" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="analogInput.voltageMaxValue" [min]="0" [max]="30"></p-spinner>
              <span class="ui-inputgroup-addon">0~30</span>
              <span class="ui-inputgroup-addon" >v</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Duration" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="analogInput.duration" [min]="0" [max]="65535"></p-spinner>
              <span class="ui-inputgroup-addon">0~65535</span>
              <span class="ui-inputgroup-addon" >s</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output id" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="analogInput.outputId" [min]="1" [max]="8"></p-spinner>
              <span class="ui-inputgroup-addon">1~8</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output Control">
            <p-inputSwitch [(ngModel)]="analogInput.outputControl"></p-inputSwitch>
          </form-input>
        </div>        
      </div>
      </div>
</p-accordionTab>

