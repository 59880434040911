<!-- GLOBAL: TOASTS -->
<p-toast key="customToast" [baseZIndex]='9999'></p-toast>

<!-- MAIN CONTAINER -->
<div class="d-flex">

  <!-- SIDEBAR COLUMN: LEFT -->
  <div class="flex-column vh-100 sticky-top">
    <app-sidebar></app-sidebar>
  </div>

    <!-- CONTENT CONTAINER: RIGHT-->
    <div class="flex-column flex-fill overflow-hidden">

        <!-- HEADER -->
        <app-header></app-header>

        <!-- ROUTER OUTLET -->
        <router-outlet>

            <!-- LOADING SPINNER -->
            <app-loading [loading]='ongoingRequests > 0 || switchingRoutes' [fullScreen]='true'></app-loading>

        </router-outlet>

    </div>
</div>




