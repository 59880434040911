import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RentingTypeService } from 'app/modules/vehicle-inventory/services/table-management/rentingType.service';

import RentingType = Rest.RentingType;

@Component({
    selector: 'app-rentingType-form',
    templateUrl: './rentingType-form.component.html',
    styleUrls: ['./rentingType-form.component.scss'],
    providers: [RentingTypeService],
    encapsulation: ViewEncapsulation.None,
})
export class RentingTypeFormComponent
    extends EntityFormComponent<RentingType>
    implements OnInit {

    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;


    constructor(
        private rentingTypeService: RentingTypeService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        public translateService: TranslateService,
    ) {
        super(rentingTypeService, notificationsService, i18n, authenticationService);
    }

    /**
    * Loads and evaluates all the necessary data before showing the form.
    *
    * @memberof RentingTypeFormComponent
    */
    beforeShow() {
        this.active = !this.entity.disabled;

        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.rentingTypes.createRentingType');
        } else {
            this.title = this.translateService.instant('tableManagement.rentingTypes.createRentingType');
        }
    }
}