<!-- DELEGATIONS LIST PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
  [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
  [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
  [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name"
  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
  styleClass="p-datatable-striped">

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'tableManagement.delegations.delegationsManagement' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- CODE -->
      <th pSortableColumn="code">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.code' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="code"></p-sortIcon>
            <p-columnFilter field="code" type="text" matchMode="contains"
              placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn='name'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='name'></p-sortIcon>
            <p-columnFilter field='name' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>

        </div>
      </th>


      <!-- FLEET MANAGER -->
      <th pSortableColumn='fleetManager'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.delegations.fleetManager' | translate }}
          </div>

        </div>
      </th>

      <!-- SERVICE MANAGER -->
      <th pSortableColumn='imei'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.delegations.serviceManager' | translate }}
          </div>

        </div>
      </th>

      <!-- ZONE MANAGER -->
      <th pSortableColumn='imei'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.delegations.zoneChief' | translate }}
          </div>

        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="disabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="disabled"></p-sortIcon>
            <p-columnFilter field="disabled" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>


    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-delegation>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(delegation)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="delegation"></p-tableCheckbox>
        </div>
      </td>

      <!-- CODE -->
      <td>
        {{delegation.code}}
      </td>

      <!-- NAME -->
      <td>
        {{delegation.name}}
      </td>

      <!-- FLEET MANAGER -->
      <td>
        <span *ngIf="delegation.fleetManager">
          {{delegation.fleetManager?.surname1}} {{delegation.fleetManager?.surname2}}, {{delegation.fleetManager?.name}}
        </span>
      </td>

      <!-- SERVICE MANAGER -->
      <td>
        <span *ngIf="delegation.serviceManager">
        {{delegation.serviceManager?.surname1}} {{delegation.serviceManager?.surname2}},
        {{delegation.serviceManager?.name}}
        </span>
      </td>

      <!-- ZONE LEADER -->
      <td>
        <span *ngIf="delegation.zoneLeader">
           {{delegation.zoneLeader?.surname1}} {{delegation.zoneLeader?.surname2}}, {{delegation.zoneLeader?.name}}
        </span>
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
            [ngClass]="{'true-icon pi-check-circle': !delegation.disabled, 'false-icon pi-times-circle': delegation.disabled}"></i>
        </div>
      </td>
    </tr>

  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="7" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD DELEGATION BUTTON -->
          <app-table-add-button [text]=" 'tableManagement.delegations.delegation' | translate "
            (btnClick)="create()"></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="!user.canManageClients || selectedEntities.length === 0"></app-table-change-status-button>

          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-shared [items]=" 'control-panel.status-dialog.maintenancePlans' | translate " [display]="true"
    [service]=delegationService [entitiesList]=selectedEntities (return)="refreshList($event)"
    (close)="closeStatusDialog($event)"></app-change-status-shared>

</div>

<!-- DELEGATION FORM -->
<app-delegation-form></app-delegation-form>