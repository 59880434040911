<p-dialog 
*ngIf="display" header="{{title}}" [(visible)]="display"
[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false" [resizable]="false"
[closeOnEscape]="false"
[style]="{width: '40vw'}">
 
  <div class="content">
    
    <div class="row mt-4 mb-4">
        <div class="col-sm-6">
            <label for="startDate" class="custom-label" i18n>
                {{'tableManagement.insurance.year' |
                translate}}
            </label>
            <div class="p-0"></div>
            <p-calendar [(ngModel)]='year' [readonlyInput]='true' [showTime]='false'
                [style]="{'width':'200px'}" showIcon='true'
                appendTo='body' dateFormat="yy"
                view="year"></p-calendar>
        </div>

        <div class="col-sm-6">
            <label for="endDate" class="custom-label" i18n>
                {{'tableManagement.insurance.quarter' |
                translate}}
            </label>
            <div class="p-0"></div>
            <p-dropdown [options]='quarterOptions' [(ngModel)]='selectedQuarter'
                    placeholder="{{'general.select' | translate}} {{'tableManagement.insurance.quarter' |
                    translate}}"
                  [style]="{'width':'250px'}" appendTo='body'>
            </p-dropdown>
        </div>
    </div>

    <div class="row mt-4 mb-4">
        <div class="col-sm-6">
            <label for="insurance-type" class="custom-label" i18n>{{'tableManagement.insurance.insuranceType' |
                translate}}</label>
            <div class="p-0"></div>
            <p-dropdown [options]='insuranceTypeList' [(ngModel)]='entity.insuranceType'
                placeholder="{{'general.select' | translate}} {{'tableManagement.insurance.insuranceType' | translate}}"
                dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                filterBy="name" (onChange)="onChangeInsuranceType($event)" (onFilter)="onFilterInsuranceType($event)">
            </p-dropdown>
        </div>

        <div class="col-sm-6">
            <label for="insurance-provider" class="custom-label" i18n>{{'tableManagement.insurance.insurer' |
                translate}}</label>
            <div class="p-0"></div>
            <p-dropdown [options]='providerList' [(ngModel)]='entity.provider'
                placeholder="{{'general.select' | translate}} {{'tableManagement.insurance.insurer' | translate}}"
                dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                filterBy="name" (onChange)="onChangeProvider($event)" (onFilter)="onFilterProvider($event)">
            </p-dropdown>
        </div>
    </div>
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <button class="btn custom-button" (click)="calculate()" i18n>
                    {{'general.startProcess' | translate}}
                </button>
            </div>
        </div>
    </p-footer>
  </div>
</p-dialog>
