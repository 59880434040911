import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';

@Injectable({
  providedIn: 'root'
})
export class DriverAssignmentsService extends EntityService<Rest.InvDriverAssignment> {

	client: Rest.RestApplicationClient;
	 selectDriver = new Subject();
	 driverSelected = new Subject<Rest.InvDriverAssignment>();
	 refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: any): Rest.RestResponse<Rest.InvDriverAssignment> {
		return this.client.InvDriverAssignments_create(arg0);
    }

    update(arg0: any): Rest.RestResponse<Rest.InvDriverAssignment> {
        return this.client.InvDriverAssignments_update(arg0);
    }

    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvDriverAssignment>> {
        return this.client.InvDriverAssignments_getPage(arg0);
    }

 	 getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.InvDriverAssignment>> {
        return this.accessory.InvDriverAssignments_getPageAndFilters(arg0);
    }

    changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
        return this.client.InvDriverAssignments_changeStatus(arg0, queryParams);
    }

    find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.InvDriverAssignment> {
        return this.client.InvDriverAssignments_find(arg0, queryParams);
    }

	 getList(clientId: number): Rest.RestResponse<Rest.InvDriverAssignment[]> {
		return this.client.InvDriverAssignments_getList(clientId);
    }

}
