import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MockService } from 'app/modules/vehicle-inventory/services/mock-service';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { DriverInfoCardComponent } from '../driver/driver-info-card/driver-info-card.component';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { ZonegroupService } from 'app/rest/zonegroup.service';
import { Rest } from 'app/rest/rest_client';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
import { VehicleService } from 'app/rest/vehicle.service';

@Component({
    selector: 'app-drivers-table',
    templateUrl: './drivers-table.component.html',
    styleUrls: ['./drivers-table.component.css'],
    //providers: [DriverService, ConfirmationService, UserTimePipe, ExcelService, InvVehicleService, VehicleService]
})
export class DriversTableComponent extends EntityListComponent<Rest.InvDriver> implements OnInit {
    title: string = "Drivers";

    @ViewChild(DriverInfoCardComponent, { static: true }) driverForm: DriverInfoCardComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    // PERMISSIONS
    isCreateGranted: boolean = false;
    isFormGranted: boolean = false;
    isDisabledGranted: boolean = false;

    constructor(
        protected driverService: DriverService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
    ) {
        super(
            driverService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.driverService = driverService;

    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.driverForm;
        /*Permissions */
        this.user = this.authenticationService.user;
        /** */

        this.setPermissions();
    }


    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    setPermissions() {
        const user = this.authenticationService.user;
        const rolesForForm = ['INV_DRIVER_READ', 'INV_DRIVER_UPDATE'];

        this.isCreateGranted = this.authenticationService.isRoleGranted('INV_DRIVER_CREATE', user);
        this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_DRIVER_DISABLE', user);
        this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
    }

    editDialog(event) {
        if (!this.isFormGranted) {
            return;
        }

        if (!this.isSelectableColumn) {
            this.onRowDblClick({ data: event });
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    excelColumns = {};

    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('drivers.table.title');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }

    setExcelColumns() {
        const fullName = (entity: any) => `(${entity.code ?? ''})  ${entity.user?.surname1 ?? ''} ${entity.user?.surname2 ?? ''},  ${entity.user?.name ?? ''}`//${ } concatenación
        const birthDate = (entity: any) => entity.user?.birthDate ?? ''
        const userName = (entity: any) => entity.user?.userName ?? ''
        const nifExpirationDate = (entity: any) => entity.nifExpirationDate ?? ''
        const expeditionDate = (entity: any) => entity.licenseData?.[0]?.expeditionDate ?? '';
        const expirationDate = (entity: any) => entity.licenseData?.[0]?.expirationDate ?? '';
        const observations = (entity: any) => entity.observations ?? ''
        const division = (entity: any) => `(${entity.division?.code ?? ''}) ${entity.division?.name ?? ''}`
        const subDivision = (entity: any) => `(${entity.subdivision?.code ?? ''}) ${entity.subdivision?.name ?? ''}`
        const company = (entity: any) => `(${entity.ceco?.billingAccount?.code ?? ''}) ${entity.ceco?.billingAccount?.name ?? ''}`
        const ceco = (entity: any) => `(${entity.ceco?.code}) ${entity.ceco?.description ?? ''}`
        const disabled = (entity: any) => entity.disabled ?? ''

        this.excelColumns = {
            [this.translateService.instant('drivers.table.name')]: { bindField: fullName },
            [this.translateService.instant('drivers.table.birthDate')]: { bindField: birthDate },
            [this.translateService.instant('drivers.table.nif')]: { bindField: userName },
            [this.translateService.instant('drivers.table.expirationDateNif')]: { bindField: nifExpirationDate },
            [this.translateService.instant('drivers.table.expeditionDateLicense')]: { bindField: expeditionDate },
            [this.translateService.instant('drivers.table.expirationDateLicense')]: { bindField: expirationDate },
            [this.translateService.instant('drivers.table.observations')]: { bindField: observations },
            [this.translateService.instant('drivers.table.division')]: { bindField: division },
            [this.translateService.instant('drivers.table.subdivision')]: { bindField: subDivision },
            [this.translateService.instant('drivers.table.company')]: { bindField: company },
            [this.translateService.instant('drivers.table.ceco')]: { bindField: ceco },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }



}
