import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';

@Injectable()
export class CardService extends EntityService<Rest.Card>{
    client: Rest.RestApplicationClient;
	 refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Card): Rest.RestResponse<Rest.Card> {
        return this.client.Card_create(arg0);
    }

    update(arg0: Rest.Card): Rest.RestResponse<Rest.Card> {
        return this.client.Card_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
    ): Rest.RestResponse<Rest.Page<Rest.Card>> {
        return this.client.Card_getPage(arg0);
    }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Card>> {
        return this.client.Card_getPageAndFilters(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<any> {
        return this.client.Card_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Card> {
        return this.client.Card_find(arg0, queryParams);
    }

	getList(clientId: number): Rest.RestResponse<Rest.Card[]> {
		return this.client.Card_getList(clientId.toString());
   }
}
