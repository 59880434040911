import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-digital-input-port-setting',
  templateUrl: './digital-input-port-setting.component.html',
  styleUrls: ['./digital-input-port-setting.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class DigitalInputPortSettingComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {

  @Input() disParameters: Rest.DigitalInputPortSettings;

  modeOptions: any = [
    { label: "Hard wired ignition detection mode.", value: 0 },
    { label: "Motion status to simulate ignition status.", value: 1 },
    { label: "External power voltage mode", value: 2 },
    // { label: "Reserved", value: 3 },
    { label: "Accerelerometer mode.", value: 4 },
  ];

  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.disParameters);
    }
  }
}
