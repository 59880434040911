<!-- SIDEBAR -->
<div *ngIf="showSidebar()" class='h-100 sidebar-main-container scrollable-sidebar'>

	<div class="d-flex sidebar-outer-container">

		<!-- COL 1: ICONS -->
		<div class='flex-column column-icons'>
			<!-- NAVIGATION ICONS -->
			<ul class="nav nav-pills nav-flush text-center h-100">

				<li class="nav-item mt-3 d-sm-block">
					<a #menuToggleButton href="#collapsable-column" class="nav-link" (click)="toggleMenu()"
						role="button" aria-controls="collapsable-column collapsable-info" data-bs-toggle="collapse"
						data-bs-target=".multi-collapse">
						<svg-icon src="assets/icons/three-bars.svg"></svg-icon>
					</a>

				</li>

				<!-- REAL TIME -->
				<li *ngIf="showRt()" class="nav-item mt-4">
					<a routerLink="rt" [routerLinkActive]="['active']"
						class="nav-link d-flex align-items-center justify-content-center">
						<svg-icon src="assets/icons/explore.svg" class="sidebar-icon d-flex flex-wrap"
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- UTE PANEL -->
				<li *ngIf="showUtePanel()" class="nav-item mt-1">
					<a routerLink="COEXPanel" [routerLinkActive]="['active']"
						class="nav-link d-flex align-items-center justify-content-center">
						<svg-icon src="assets/icons/cube-grid.svg" class="sidebar-icon d-flex flex-wrap"
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- REPORTS -->
				<li *ngIf="showReports()" class="nav-item mt-1">
					<a routerLink="reports" [routerLinkActive]="['active']"
						class="nav-link d-flex align-items-center justify-content-center">
						<svg-icon src="assets/icons/file-text.svg" class="sidebar-icon d-flex flex-wrap"
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- RACING -->
				<li *ngIf='showRacing()' class='nav-item mt-1'>
					<a routerLink='racing' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/radar.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- TAX -->
				<li *ngIf='showTax()' class='nav-item mt-1'>
					<a routerLink='tax' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/file-text.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- TACO -->
				<li *ngIf='showTaco()' class='nav-item mt-1'>
					<a routerLink='taco' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/id-card.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- INVENTORY VEHICLES -->
				<li *ngIf='showInventoryVehicles()' class='nav-item mt-1'>
					<a routerLink='inventoryVehicles' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/vehicle-inventory.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- DRIVERS -->
				<li *ngIf='showDrivers()' class='nav-item mt-1'>
					<a routerLink='drivers' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/person.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- MAINTENANCE -->
				<li *ngIf='showMaintenance()' class='nav-item mt-1'>
					<a routerLink='maintenance' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/maintenance.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- TABLE MANAGEMENT-->
				<li *ngIf='showTableManagement()' class='nav-item mt-1'>
					<a routerLink='tableManagement' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/file-text.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- REPORTS WIZARD -->
				<li *ngIf='showReportsWizard()' class='nav-item mt-1'>
					<a routerLink='reports-wizard' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/reports-wizard.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- DATA IMPORT -->
				<!-- <li *ngIf='showDataImport()' class='nav-item mt-1'>
					<a routerLink='dataImport'
						[routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'
					>
						<svg-icon src='assets/icons/link_navigate.svg'
									class='sidebar-icon d-flex flex-wrap'
									[svgStyle]="{ 'width.px': 20 }"
						></svg-icon>
					</a>
				</li> -->

				<!-- DASHBOARDS -->
				<li *ngIf='showDashboards()' class='nav-item mt-1'>
					<a routerLink='dashboards' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/dashboard.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- CONTROL PANEL -->
				<li *ngIf="showSettings()" class="nav-item mt-1">
					<a routerLink="control-panel" [routerLinkActive]="['active']"
						class="nav-link d-flex align-items-center justify-content-center">
						<svg-icon src="assets/icons/settings.svg" class="sidebar-icon d-flex flex-wrap"
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- TASK ORDERS, INCIDENCES,ROUTES, ....-->
				<li *ngIf='showBOLogistics()' class='nav-item mt-1'>
					<a routerLink='logistics' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/file-text.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>
				<!-- TASK ADMINISTRATION -->
				<li *ngIf='showBOAdministration()' class='nav-item mt-1'>
					<a routerLink='administration' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/settings.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 20 }"></svg-icon>
					</a>
				</li>

				<!-- TASK PREDICTIONS -->
				<li *ngIf='showPredictionsStack()' class='nav-item mt-1'>
					<a routerLink='predictions' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/prediction_icon.svg'
							class='sidebar-icon d-flex flex-wrap prediction-icon' [svgStyle]="{ 'width.px': 20 }">
						</svg-icon>
					</a>
				</li>


				<!-- SAT -->
				<li *ngIf='showSATPanel()' class='nav-item mt-1'>
					<a routerLink='sat' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/tools.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 25 }"></svg-icon>
					</a>
				</li>

				<!-- RUTES PROMOCIONADES -->
				<li *ngIf='showRoutesPanel()' class='nav-item mt-1'>
					<a routerLink='promoted-routes' [routerLinkActive]="['active']"
						class='nav-link d-flex align-items-center justify-content-center'>
						<svg-icon src='assets/icons/route.svg' class='sidebar-icon d-flex flex-wrap'
							[svgStyle]="{ 'width.px': 25 }"></svg-icon>
					</a>
				</li>
			</ul>
		</div>

		<!-- COL 2: LABELS -->
		<div id="collapsable-column" class="flex-column collapse multi-collapse collapse-horizontal ">
			<!-- NAVIGATION LABELS -->
			<div class="d-flex flex-column h-100 column-labels border-0 rounded-0 text-sm-center">

				<a class="sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-3"></a>

				<!-- REAL TIME -->
				<a *ngIf="showRt()" routerLink="rt" [routerLinkActive]="['active']"
					class="sidebar-label nav-link d-flex align-items-center list-group-item border-0 text-truncate mt-4">
					{{ 'header.rt' | translate }}
				</a>

				<!-- UTE PANEL -->
				<a *ngIf="showUtePanel()" routerLink="COEXPanel" [routerLinkActive]="['active']"
					class="sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1">
					{{ 'header.COEXPanel' | translate }}
				</a>

				<!-- REPORTS -->
				<a *ngIf="showReports()" routerLink="reports" [routerLinkActive]="['active']"
					class="sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1">
					{{ 'header.reports' | translate }}
				</a>



				<!-- RACING -->
				<a *ngIf='showRacing()' routerLink='racing' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.racing' | translate }}
				</a>

				<!-- TAX -->
				<a *ngIf='showTax()' routerLink='tax' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.tax' | translate }}
				</a>

				<!-- TACO -->
				<a *ngIf='showTaco()' routerLink='taco' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.taco' | translate }}
				</a>

				<!-- INVENTORY VEHICLES -->
				<a *ngIf='showInventoryVehicles()' routerLink='inventoryVehicles' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.inventoryVehicles' | translate }}
				</a>

				<!-- DRIVERS -->
				<a *ngIf='showDrivers()' routerLink='drivers' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.drivers' | translate }}
				</a>

				<!-- MAINTENANCE -->
				<a *ngIf='showMaintenance()' routerLink='maintenance' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.maintenance' | translate }}
				</a>

				<!-- TABLE MANAGEMENT -->
				<a *ngIf='showTableManagement()' routerLink='tableManagement' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.tableManagement' | translate }}
				</a>

				<!-- REPORTS WIZARD -->
				<a *ngIf='showReportsWizard()' routerLink='reports-wizard' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'reports.reports' | translate }}
				</a>

				<!-- DASHBOARDS -->
				<a *ngIf='showDashboards()' routerLink='dashboards' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.dashboards' | translate }}
				</a>
				<!-- CONTROL PANEL -->
				<a *ngIf="showSettings()" routerLink="control-panel" [routerLinkActive]="['active']"
					class="sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1">
					{{ 'header.settings' | translate }}
				</a>

				<!-- LOGISTICS -->
				<a *ngIf='showBOLogistics()' routerLink='logistics' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.logistics' | translate }}
				</a>
				<!-- ADMINISTRATION LOGISTICS-->
				<a *ngIf='showBOLogistics()' routerLink='administration' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.administration' | translate }}
				</a>
				<!-- TASK PREDICTIONS -->
				<a *ngIf='showPredictionsStack()' routerLink='predictions' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.predictions' | translate }}
				</a>
				<!-- SAT -->
				<a *ngIf='showSATPanel()' routerLink='sat' [routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.sat' | translate }}
				</a>

				<!-- PROMOTED-ROUTES -->
				<a *ngIf='showRoutesPanel() || showSATPanel()'
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'
					[routerLinkActive]="['active']"
					class='sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1'>
					{{ 'header.promotedRoutes' | translate }}
				</a>

			</div>
		</div>
	</div>

	<!--INFO BLOCK-->
	<div id="info-block d-flex sidebar-outer-container">
		<div id="collapsable-info" class="collapse multi-collapse collapse-horizontal overflow-hidden mb-2">
			<div *ngIf="expanded"
				class="d-flex flex-column justify-content-center align-items-center h-100 ms-auto me-auto">
				<img src="../../../assets/images/login/atlantis-fleet-21.svg" id="atlantis-logo">
				<div class="contact-info-text mt-4">{{'sidebar.customerService' | translate}}</div>
				<div class="contact-info-text mt-1">(+34) 93 496 08 69</div>
				<div class="contact-info-text mt-4">info&#64;atlantisioe.com</div>
			</div>
		</div>
	</div>

	<!--LOGOUT-->
	<div class="d-flex logout">
		<div class="flex-column column-icons">
			<ul class="nav nav-pills nav-flush flex-column text-center h-100">
				<li class="nav-item mt-1">
					<a routerLink="logout" [routerLinkActive]="['logout']" (click)="logout()"
						class="nav-link d-flex align-items-center justify-content-center">
						<svg-icon src="assets/icons/power.svg" class="sidebar-icon d-flex flex-wrap"
							[svgStyle]="{ 'width.px': 20 }">
						</svg-icon>
					</a>
				</li>
			</ul>
		</div>

		<div class="flex-column collapse multi-collapse collapse-horizontal overflow-hidden">
			<div class="d-flex flex-column h-100 column-labels border-0 rounded-0 text-sm-center">
				<a (click)="logout()"
					class="sidebar-label d-flex align-items-center list-group-item border-0 text-truncate mt-1 logout-label">
					{{ 'sidebar.logout' | translate }}
				</a>
			</div>
		</div>
	</div>
</div>