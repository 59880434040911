<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>
        
        <!-- #FORM BODY -->
        <div class="row mt-4">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                  {{'general.code' | translate}}
                </label>
              </div>
              <div class="col">
                <div class="float-end">

                    <input pInputText id="code" class="form-control custom-input" [(ngModel)]="entity.code" />
                </div>
              </div>

            
        </div>

        <div class="row mt-4">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                  {{'general.description' | translate}}
                </label>
              </div>
              <div class="col">
                <div class="float-end">

                    <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.description" />

                </div>
              </div>
        </div>

        <!-- COMPANY -->
        <div class="row mt-4">
          <div class="col d-flex align-items-center">
              <label for="vehicle-data-settings-name" class="custom-label" i18n>
                  {{'drivers.table.company' | translate}}
              </label>
          </div>

          <div class="col">
              <div class="float-end">
                  <p-dropdown [options]='companyList' [(ngModel)]='entity.billingAccount'
                      placeholder="{{'tableManagement.companies.company' | translate}}" dataKey='id'
                      optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true" filterBy="name"
                      (onChange)="onChangeCompany($event)" (onFilter)="onFilterCompany($event)">
                  </p-dropdown>
              </div>
          </div>
          
      </div>


        <!-- ACTIVE -->
        <!-- ACTIVE -->
    <div *ngIf="!isNew">
        <div class="row mt-3">
          <div class="col d-flex align-items-center">
            <label for="vehicle-data-settings-name" class="custom-label" i18n>
              {{'general.enabled' | translate}}
            </label>
          </div>
  
          <div class="col">
            <div class="float-end">
              <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                type="checkbox" [(ngModel)]="cecoEnabled" [disabled]="!isNew" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-footer>

        <div class="d-flex justify-content-center bd-highlight">

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>

                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
            </div>

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(cecoEnabled)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>

        </div>

    </p-footer>

</p-dialog>
