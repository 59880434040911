import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { ZoneService } from "../../../rest/zone.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { ZoneFormComponent } from "../zone-form/zone-form.component";

@Component({
    selector: "app-zone-list",
    templateUrl: "./zone-list.component.html",
    styleUrls: ["./zone-list.component.css"],
    providers: [ZoneService, ConfirmationService, UserTimePipe],
})
export class ZoneListComponent
    extends EntityListComponent<Rest.Zone>
    implements OnInit {
    title: string;
    displayDialog: boolean;

    messageWarning: string;
    containsNestedEntities = [];

    @Input() parentZoneGroup: Rest.ZoneGroup;
    @ViewChild(ZoneFormComponent, {static: true}) zoneForm: ZoneFormComponent;

    EnabledFilter: SelectItem[];
    zoneService: ZoneService;
    showStatusDialog = false;

    displayForm = false;

    /*Permissions */
    hasPermissions: boolean;

    /** */

    constructor(
        zoneService: ZoneService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            zoneService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.zoneService = zoneService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._("Zones");
        this.form = this.zoneForm;
        this.fetchEntityBeforeShowUpdateForm = true;
        this.hasEntityFilters = true;
        /*Permissions */
        this.hasPermissions = this.parentZoneGroup["_$update"];
        /** */
        console.debug("zone-list ngInit ");
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((z: any) => {
            /*Permissions */
            z._$changeStatus = this.hasPermissions; //If has permissions to update Zone group, has permissions to changestatus, update, create zones
            z._$update = this.hasPermissions;
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    protected beforeLoad() {
        this.paginationRequest.filters["zoneGroupID"] = [
            this.parentZoneGroup.id.toString(),
        ];
    }

    createZoneDialog() {
        this.create({zoneGroup: this.parentZoneGroup} as Rest.Zone);
    }

    close() {
        let elementsdeleted = [];
        this.displayDialog = false;
        this.selectedEntities = [];

        this.containsNestedEntities = [];

        if (this.containsNestedEntities != null) {
            elementsdeleted = this.containsNestedEntities.splice(
                0,
                this.containsNestedEntities.length
            );
        }
    }

    beforeonRowDblClick() {
        this.displayForm = true;
    }

    onClose(event) {
        this.displayForm = false;
    }

    onBeforeInit(event) {
        this.form = this.zoneForm;
    }

    editZoneDialog(event) {
        if (!this.isSelectableColumn) {
            event.zoneGroup = this.parentZoneGroup;
            this.onRowDblClick({data: event});
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editZoneDialogTable(event) {
        if (
            !this.isColumn &&
            !this.isSelectableColumn &&
            event["originalEvent"]["srcElement"]["cellIndex"] > 0
        ) {
            event.zoneGroup = this.parentZoneGroup;
            this.onRowDblClick(event);
        }

        this.isSelectableColumn = false;
        this.isColumn = false;
    }
}
