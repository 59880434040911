<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

  <!-- #region FORM BODY -->
  <div class="container-md">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <!-- NAME -->
    <div class="row mt-3">
      <div class="col d-flex align-items-center">
        <label for="brand-name" class="custom-label" i18n>
          {{'general.name' | translate}}
        </label>
      </div>

      <div class="col">
        <div class="float-end">
          <input type="text" id="brand-name" class="form-control custom-input" [(ngModel)]="entity.name">
        </div>
      </div>
    </div>

    <!-- ROW 4: LOGO IMAGE & ADD IMAGE BUTTON -->
    <div class="row mt-2 mb-4">

      <!-- PROFILE IMAGE -->
      <img #image src="{{brandEncodedImage}}" class="custom-user-profile-image mx-auto d-block p-0">

      <!-- ADD IMAGE BUTTON -->
      <div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
        <div id="inner-icon-holder" class="d-flex align-items-center justify-content-center"
          (click)='uploadImageInput.click()'>
          <img src="assets/icons/camera-plus.png" class="custom-user-profile-icon" />
          <input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png' (change)='imageChange($event)'>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion FORM BODY -->

  <!-- #region FOOTER -->
  <p-footer>
    <div class="d-flex justify-content-center bd-highlight">
      <div class="p-2 bd-highlight">
        <div *ngIf="isNew">
          <button class="btn custom-button" (click)="saveChanges()" i18n>
            {{'general.create' | translate}}
          </button>
        </div>

        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="saveChanges()" i18n>
            {{'general.save' | translate}}
          </button>
        </div>
      </div>

      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="changeStatus(!entity.disabled)" i18n>
            {{'general.changeStatus' | translate}}
          </button>
        </div>
      </div>
    </div>
  </p-footer>
  <!-- #endregion FOOTER -->
</p-dialog>