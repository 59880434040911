import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-speed-event-config-form-vt200",
  templateUrl: "./speed-event-config-form-vt200.component.html",
  styleUrls: ["./speed-event-config-form-vt200.component.css"],
})
export class SpeedEventConfigFormVt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @ViewChild("ContentDiv") ContentDiv;

  @Input() speedEvent: Rest.SpeedEventConfigurationVT200;

  SpeedEventSpeedingModes: any = [
    {
      label:
        "ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY",
      value: 0,
    },
    {
      label:
        "ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY",
      value: 1,
    },
  ];

  OutputPortsModes: any = [
    { label: "Disable", value: 0 },
    { label: "OUTPUT 1", value: 1 },
    { label: "OUTPUT 2", value: 2 },
    { label: "OUTPUT 2", value: 3 },
    { label: "OUTPUT 2", value: 4 },
  ];

  SpeedEventMode: any = [
    { label: "DISABLE", value: 0 },
    { label: "LOGGING", value: 1 },
    { label: "POLLING", value: 2 },
    { label: "LOGGING_AND_POLLING", value: 3 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.ContentDiv.nativeElement.classList.remove("disableDIV");
    }
  }
  trackModeSwitch = false;
  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {}
}
