import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-user-defined-function-gl320m',
  templateUrl: './user-defined-function-gl320m.component.html',
  styleUrls: ['./user-defined-function-gl320m.component.css']
})
export class UserDefinedFunctionGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Disable the group", value: "DISABLE_FUNCTIONS" },
    { label: "Enable the group", value: "ENABLE_FUNCTIONS" },
    { label: "Delete the group", value: "DELETE_FUNCTIONS" },
 ];

 stoCmdOptions: any = [
  { label: "Do not send acknowledgment message when the stored command is executed", value: "NOT_SEND_ACK_MESSAGE" },
  { label: "Send acknowledgment message when the stored command is executed", value: "SEND_ACK_MESSAGE" },
 ]
  @Input() udfParameters: Rest.UserDefinedFunctionGL320M[];
  idOptions: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(i => ({label: i.toString(), value: i}));

  constructor(protected i18n: I18nService) { super(i18n); }
  selectedIndex: number = 0;
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.udfParameters);
    }
  }

  onUdfIdChange(event){
    this.selectedIndex = event.value;
  }
}
