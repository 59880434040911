<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Command String storage
          (AT + GTCMD)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Main
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Stored Command ID">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="idOptions" [style]="{'width':'100%','min-width':'100px'}"
              (onChange)="onCommandIdChange($event)"></p-dropdown>
            </div>
          </div>
        
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="modeOptions" [(ngModel)]="cmdParameters[selectedIndex].mode"
              [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Command String">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="text" pInputText [(ngModel)]="cmdParameters[selectedIndex].command" maxlength="200" class="txtbStyle" />
              </div>
            </div>
          </div>
        
        </div>
      </p-accordionTab>


    </div>
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Additional
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

            <div class="col">
              <p-checkbox binary="true" label="Dynamic" [(ngModel)]="cmdParameters[selectedIndex].dynamic">
              </p-checkbox>
            </div>
        
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="parameter">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [disabled]="!cmdParameters[selectedIndex].dynamic" [options]="parameterOptions"
              [(ngModel)]="cmdParameters[selectedIndex].parameter" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        
        </div>
      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>