import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-fence-alarm-configuration-we-track2',
  templateUrl: './fence-alarm-configuration-we-track2.component.html',
  styleUrls: ['./fence-alarm-configuration-we-track2.component.css'],
})
export class FenceAlarmConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit {

  @Input() fenceAlarm: Rest.FenceAlarmConfigurationWeTrack2
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  constructor(protected i18n: I18nService) {  super(i18n);}
  isCircle = true;
  FenceOptions: any = [
    {label: "CIRCLE AREA",value:"CIRCLE_AREA"},
    {label: "RECTANGLE AREA",value:"RECTANGLE_AREA"},            
  ];

  FenceAlarmOptions: any = [
    {label:"BOTH", value: null},
    {label:"IN", value:"IN"},
    {label:"OUT", value:"OUT"},
  ];

  AlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"GPRS_SMS"},            
  ];



  ngOnInit() {
    if(this.fenceAlarm!=null){this.disableDIV(!this.fenceAlarm.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.fenceAlarm);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

  fenceModeChange(event){

    if(event.value == 0){
      this.isCircle = true;
    }else{
      this.isCircle = false;
    }

  }

}
