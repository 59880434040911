import { Injectable } from "@angular/core";
import { EntityService } from "app/rest/entity.service";
import { JQueryHTTPClient } from "app/rest/JQueryHTTPClient";
import { Rest } from "app/rest/rest_client";

@Injectable()
export class TacoService extends EntityService<Rest.Tacograph> {
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    // create(arg0: any): Rest.RestResponse<Rest.Tacograph> {
    //     try {
    //         return this.client.Mileage_create(arg0);
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    // update(arg0: any): Rest.RestResponse<Rest.Tacograph> {
    //     return this.client.Mileage_update(arg0);
    // }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Rest.Tacograph>> {
        return this.client.Taco_getPage(arg0, queryParams);
    }

    // changeStatus(
    //     arg0: number[],
    //     queryParams?: { status?: boolean }
    // ): Rest.RestResponse<void> {
    //     return this.client.Mileage_changeStatus(arg0, queryParams);
    // }

    // // @ts-ignore
    // find(
    //     arg0: string,
    //     queryParams?: { id?: number }
    // ): Rest.RestResponse<Rest.Tacograph> {
    //     return this.client.Mileage_find(arg0, queryParams);
    // }

    public download(arg0: number[]) : Rest.RestResponse<string>{
        return this.client.Taco_download(arg0);
    }
}