import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-track-event-configuration-form-x8",
  templateUrl: "./track-event-configuration-form-x8.component.html",
  styleUrls: ["./track-event-configuration-form-x8.component.css"],
})
export class TrackEventConfigurationFormX8Component
  extends ServerValidatedFormComponent
  implements OnInit
{
  @ViewChild("ContentDiv") ContentDiv;
  @Input() trackEvent: Rest.TrackEventConfigurationX8;

  ModeOptions: any = [
    { label: "Disable", value: 0 },
    { label: "Time Mode", value: 1 },
    { label: "Distance Mode", value: 2 },
    { label: "Intellitrack Mode", value: 3 },
    { label: "Time and ACC Mode", value: 5 },
    { label: "Distance and ACC Mode", value: 6 },
    { label: "Intellitrack and ACC Mode", value: 7 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.trackEvent);
    }
  }
  trackModeSwitch = false;
  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {}
}
