<div id="pre-config-form">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>

  <p-panel header="GV300 Parameters">
      <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
          <div class="ui-g-12 ui-md-6">
            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                </form-input>
              </div>
              <div class="col">
                <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                [(ngModel)]="selectedPreconfig" [style]="{'width':'100%','min-width':'100px'}" filter="filter"></p-dropdown>
              </div>
            </div>
          </div>
        </div>

    <p-tabView #tv (onChange)="tabChanged()">
      <p-tabPanel header="Events">
        <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
          <!-- This should be where all form components will display -->
          <app-global-parameters-form [globalParameters]="deviceParams.proprietaryParameters.globalParameters">
          </app-global-parameters-form>
          <app-fixed-report-information-form [friParameters]="deviceParams.proprietaryParameters.friParameters">
          </app-fixed-report-information-form>
          <app-tow-alarm-information-form [towAlarm]="deviceParams.proprietaryParameters.towAlarm">
          </app-tow-alarm-information-form>
          <app-speed-alarm-form [speedAlarmParameters]="deviceParams.proprietaryParameters.speedAlarmParameters">
          </app-speed-alarm-form>
          <app-sos-form [sosParameters]="deviceParams.proprietaryParameters.sosParameters"></app-sos-form>
          <app-hbm-form [hbmParameters]="deviceParams.proprietaryParameters.hbmParameters"></app-hbm-form>
          <app-jamming-detection-information-form
            [jammingDetection]="deviceParams.proprietaryParameters.jammingDetection">
          </app-jamming-detection-information-form>
          <app-start-stop-report-information-form
            [StartStopReport]="deviceParams.proprietaryParameters.startStopReport">
          </app-start-stop-report-information-form>
          <app-jamming-behavior-setting-form [jbsParameters]="deviceParams.proprietaryParameters.jbsParameters">
          </app-jamming-behavior-setting-form>
          <app-analog-input-port-setting [aisParameters]="deviceParams.proprietaryParameters.aisParameters"></app-analog-input-port-setting>
          <app-digital-input-port-setting [disParameters]="deviceParams.proprietaryParameters.disParameters"></app-digital-input-port-setting>
          <app-canbus-device-configuration [canParameters]="deviceParams.proprietaryParameters.canParameters"></app-canbus-device-configuration>
          <app-authentication-id-configuration [idaParameters]="deviceParams.proprietaryParameters.idaParameters"></app-authentication-id-configuration>
        </p-accordion>

      </p-tabPanel>


      <p-tabPanel  header="Sensors">
        <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
        <!-- Sensors list -->
      </p-tabPanel>
      <p-tabPanel i18n-header header="Actuators">
        <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
      </p-tabPanel>

      <!-- Commented code for actuators (why?)
            <p-tabPanel  header="Actuators">
                <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
            </p-tabPanel>

            <p-tabPanel  header="Led Patterns">
                <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
                    <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern1"></app-led-pattern-config-form>
                    <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern2"></app-led-pattern-config-form>
                    <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern3"></app-led-pattern-config-form>
                    <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern4"></app-led-pattern-config-form>
                    <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern5"></app-led-pattern-config-form>
                </p-accordion>
            </p-tabPanel>

            <p-tabPanel  header="RFTags">
                <app-rftag-configuration [(RFTags)]="deviceParams.proprietaryParameters.rfTags"></app-rftag-configuration>
            </p-tabPanel>

             -->
    </p-tabView>
  </p-panel>
  <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>
