import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Accordion } from 'primeng/accordion';
import { TabView } from 'primeng/tabview';
import { Auxiliar } from '../../../model/Auxiliar';
import { Rest } from '../../../rest/rest_client';
import { PreconfigurationService } from '../../../rest/preconfiguration.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { ServerValidatedFormComponent } from '../../server-validated-form/server-validated-form.component';

@Component({
   selector: 'app-evix-parameters-form',
   templateUrl: './evix-parameters-form.component.html',
   styleUrls: ['./evix-parameters-form.component.css'],
   providers: [PreconfigurationService, ConfirmationService],
})
export class EvixParametersFormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {
   //View params
   @Input() deviceParams: Rest.DeviceParameters;
   @Input() firmware: RestExt.FirmwareVersion;
   @Input() isNew: boolean;
   @Output() EVIXParamsChange: EventEmitter<Rest.DeviceParameters> = new EventEmitter<Rest.DeviceParameters>();
   @ViewChild('tv') public tabView: TabView;
   @ViewChild('ac') accordion: Accordion;

   @Input() isConfig: boolean;
   @Output('refreshList')
   refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

   preconfigService: PreconfigurationService;
   confirmationService: ConfirmationService;
   notificationsService: NotificationsService;
   firmwareValue: any;

   /**Events */
   selected = false;

   /**PreConfigs */
   preConfigsList: Rest.DevicePreconfiguration[];
   availablePreconfigs: SelectItem[];
   selectedPreconfig: any;

   constructor(
      preconfigService: PreconfigurationService,
      confirmationService: ConfirmationService,
      notificationsService: NotificationsService,
      protected i18n: I18nService,
   ) {
      super(i18n);
      this.preconfigService = preconfigService;
      this.confirmationService = confirmationService;
      this.notificationsService = notificationsService;
      this.i18n = i18n;
   }

   ngOnChanges(changes: SimpleChanges) {
      /* Sergi: solved Bug */
      if (this.isNew && this.deviceParams == null) {
         this.deviceParams = RestExt.emptyEVIXParams();
      }

      //this.deviceParams.configuration = <Rest.DeviceConfiguration>{deviceType:"VT10"};

      if (changes['deviceParams']) {
         this.EVIXParamsChange.emit(this.deviceParams);
      }
      if (changes['firmware']) {
         if (this.firmware == undefined) {
            this.firmwareValue = undefined;
         } else {
            this.firmwareValue = RestExt.FirmwareVersion[this.firmware] + 1; //Add 1 because returns the index of enum value and starts at 0 which corresponds to first item
            RestExt.initializeEVIXParams(this.deviceParams);
            this.LoadPreconfigsList();
            /*if (this.isNew) {
           RestExt.removeInvalidParams(this.[(deviceParams)], this.firmwareValue);
         } else {
           if (RestExt.FirmwareVersion[changes['firmware'].previousValue] + 1 > this.firmwareValue) {
             RestExt.removeInvalidParams(this.[(deviceParams)], this.firmwareValue);
           } else {
             RestExt.addNewParams(this.[(deviceParams)], this.firmwareValue);
           }
         }*/
         }
      }
   }

   ngOnInit() {
      this.initConfig();
      if (this.isConfig) {
         this.LoadPreconfigsList();
      }
   }

   public getEVIXDeviceParams(): any {
      return this.deviceParams;
   }

   LoadPreconfigsList() {
      let paginationRequest: Rest.ListPaginationRequest;
      this.availablePreconfigs = [];
      this.selectedPreconfig = null;
      this.preConfigsList = [];

      const that = this;
      /*this.preconfigService.getPage(RestExt.allRecords).then(
      function (response) {
        that.preConfigsList = response.entities;
        that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(response.entities);
      });*/
      paginationRequest = RestExt.firstPageRequest();
      //paginationRequest.sortBy="creationDate";
      paginationRequest.sortAsc = false;

      paginationRequest.filters = {
         firmware: [this.firmware + ''],
      };

      this.preconfigService.getPage(paginationRequest).then(function (response) {
         that.preConfigsList = response.entities;
         that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(response.entities);
      });
   }

   initConfig() {
      this.preConfigsList = [];
      this.selectedPreconfig = null;

      if (this.accordion != undefined) {
         this.tabView.activeIndex = 0;
         this.closeAllAccordionTabs();
      }
   }

   loadPreconfig(event) {
      this.preconfigService.find(event.value.id).then((pre: Rest.DevicePreconfiguration) => {
         this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
      });
   }
   fillPreConfig(newParams: Rest.DeviceParameters) {
      //remove id because if is from preconfig we have to assign as new sensors to config
      for (const a in newParams.sensors) {
         delete newParams.sensors[a]['id'];
      }

      for (const a in newParams.actuators) {
         delete newParams.actuators[a]['id'];
      }
      //Load the rest preconfig parameters to vt10 config
      this.deviceParams.sensors = newParams.sensors;
      this.deviceParams.actuators = newParams.actuators;
      this.deviceParams.proprietaryParameters.type = 'EVIX';

      //assign id config to keep the assignation
      /*var configTmp: Rest.VT10Parameters = <Rest.VT10Parameters>{};
    configTmp.id = this.deviceParams.proprietaryParameters.id;*/
      for (const a in this.deviceParams.sensors) {
         this.deviceParams.sensors[a].parameters = null; //configTmp;
      }
      for (const a in this.deviceParams.actuators) {
         this.deviceParams.actuators[a].parameters = null; //configTmp;
      }
   }

   tabChanged() {
      /**
       * Dirty trick:
       * Force [(deviceParams)] onChange events to fire in sub-components to detect changes in LedPatterns names
       */
      this.deviceParams = Object.assign({}, this.deviceParams);
      this.EVIXParamsChange.emit(this.deviceParams);
   }
   refresh(status: boolean) {
      this.refreshList.emit(status);
   }

   closeAllAccordionTabs() {
      if (!this.isNullOrUndefined(this.accordion.tabs)) {
         for (const tab of this.accordion.tabs) {
            if (tab.selected) {
               tab.selected = false;
            }
         }
      }
   }

   isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
      return typeof obj === 'undefined' || obj === null;
   }
}
