import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PhaseChangeEvent, InventoryReportsService } from 'app/modules/vehicle-inventory/services/inventory-reports.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';

import InventoryReportRequest = Rest.InventoryReportRequest; import { jsPDF } from 'jspdf';
import "jspdf-autotable";
import { ExcelService } from 'app/services/excel-service/excel.service';
import { SortEvent } from 'primeng/api';
import { table } from 'console';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-report-generator',
  templateUrl: './report-generator.component.html',
  styleUrls: ['./report-generator.component.css'],
  providers: [InventoryReportsService, ExcelService],
  encapsulation: ViewEncapsulation.None
})
export class ReportGeneratorComponent implements OnInit {
  client: Rest.Client;

  @Output() phaseChange = new EventEmitter<PhaseChangeEvent>();

  @Input() report: PhaseChangeEvent;

  formData: any;
  reportRequest: InventoryReportRequest;

  tableData: any;
  tableDataFormatted: any; //data transformed to clasic json 

  display: boolean = false;
  columns: any[];
  values: Rest.ReportResponseItem[] = [];
  exportPDFColumns;

  // LICENSE REPORT DATA
  expirationDate: string;
  alarmDate: string;

  //INSURANCE REPORT DATA
  startDate: string;

  //FINES REPORT DATA
  startDateFines: string;
  endDateFines: string;

  // ITV REPORT DATA
  alarmDateMaintenances: string;


  dialogWidth = '70vw';
  colspanTest = '4';

  constructor(
    private inventoryReportsService: InventoryReportsService,
    private authenticationService: AuthenticationService,
    public excelService: ExcelService,
    private datePipe: DatePipe

  ) { }

  ngOnInit(): void {
    this.client = this.authenticationService.user.client;
    this.formData = this.report.formData;
  }

  ngOnChanges() {
    console.log(this.report.formData);
  }

  goBack() {
    // Will go back with the same form data and emitting a PhaseChangeEvent with pase 2 and all its form data

    this.phaseChange.emit({ phase: 2, reportData: this.report.reportData, formData: this.report.formData });
  }

  generateReport() {
    console.log('Generating report...'); //TODO: Eliminar console.log

    switch (this.report.reportData.reportType) {
      case 'DRIVERS_EXPIRATION':
        this.generateDriversExpirationReport();
        break;

      case 'INSURANCE_ACTIVE':
        this.generateInsuranceActiveReport();
        break;

      case 'VEHICLE_ACCESORY':
        this.generatevehicleAccesoryReport();
        break;

      case 'FINES':
        this.generateFinesReport();
        break;

      case 'VEHICLE_CONSUMPTION':
        this.generateReportWithoutDates();
        break;

      case 'CARD':
        this.generateReportWithoutDates();
        break;

      case 'ITV_EXPIRATION':
        this.generateITVExpirationReport();
        break;

      case `VEHICLE_EXPIRATION`:
        this.generateFinesReport();
        break;

      case 'VEHICLE':
        this.generateReportWithoutDates();
        break;

      default:
        console.error('Unsupported report type');
        break;
    }
  }

  generateDriversExpirationReport() {

    const filters = this.processFilters(this.formData);

    if (!this.alarmDate && !this.expirationDate) {
      alert('Please select a date.'); // Use a more user-friendly notification method as needed
      return;
    }

    const formattedAlarmDate = this.formatDate(this.alarmDate);
    filters['alarmDate'] = [formattedAlarmDate];

    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest('DRIVERS_EXPIRATION', filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  generateInsuranceActiveReport() {
    const startDate = this.formatDate(this.startDate);

    if (!startDate) {
      alert('Please select a start date.'); // Use a more user-friendly notification method as needed
      return;
    }

    const filters = this.processFilters(this.formData);
    // Assume 'startDate' is the correct filter for INSURANCE_ACTIVE reports
    filters['date'] = [startDate];

    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest(this.report.reportData.reportType, filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  generatevehicleAccesoryReport() {
    const filters = this.processFilters(this.formData);
    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest('VEHICLE_ACCESORY', filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  generateFinesReport() {
    const formattedStartDate = this.formatDate(this.startDateFines);
    const formattedEndDate = this.formatDate(this.endDateFines);

    if (!formattedStartDate || !formattedEndDate) {
      alert('Please select some date intervals.'); // Consider a more user-friendly notification method
      return;
    }

    const filters = this.processFilters(this.formData);

    filters['startDate'] = [formattedStartDate];
    filters['endDate'] = [formattedEndDate];

    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest(this.report.reportData.reportType, filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  generateITVExpirationReport() {
    const alarmDate = this.formatDate(this.alarmDateMaintenances);

    if (!alarmDate) {
      alert('Please select an alarm date.'); // Use a more user-friendly notification method as needed
      return;
    }

    const filters = this.processFilters(this.formData);
    filters['alarmDate'] = [alarmDate];
    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest(this.report.reportData.reportType, filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  generateReportWithoutDates() {
    const filters = this.processFilters(this.formData);
    const parameters = this.processParameters(this.formData.parameters);
    this.reportRequest = this.prepareReportRequest(this.report.reportData.reportType, filters, parameters);

    this.sendReportRequest(this.reportRequest);
  }

  private formatDate(date: Date | string): string {
    if (!date) return null;

    const dateObj = new Date(date);
    return dateObj.toISOString();
  }

  private processFilters(formData: any): { [key: string]: any } {
    const filters = {};

    Object.keys(formData).forEach(key => {
      if (key !== 'parameters') {
        let value = formData[key];

        // Check if the value is an array and has at least one non-null item
        if (Array.isArray(value)) {
          value = value.filter(item => item != null && (typeof item !== 'object' || item.id != null));
          if (value.length > 0) {
            filters[key] = value.map(item => typeof item === 'object' && item.id ? item.id : item);
          }
        }
        // For non-array values, check if they're non-null/undefined and not an empty string
        else if (value != null && value !== '') {
          filters[key] = [typeof value === 'object' && value.id ? value.id : value];
        }
      }
    });

    return filters;
  }

  private processParameters(parameters: any): string[] {
    return Array.isArray(parameters) ? parameters : [parameters];
  }

  private prepareReportRequest(reportType: string, filters: any, parameters: string[]): InventoryReportRequest {
    return {
      report: {
        client: { id: this.client.id },
        reportType
      },
      filters,
      parameters
    } as InventoryReportRequest;
  }

  sendReportRequest(reportRequest: InventoryReportRequest) {
    this.inventoryReportsService.generateReport(reportRequest).then(resp => {
      if (resp == undefined || resp == null) {
        //TODO: Translate message
        alert('No se han encontrado datos para los parámetros seleccionados. Por favor, intente con otros parámetros.');
      }
      this.tableData = resp;

      console.log('Report generated:', this.tableData);

      //table columns
      this.columns = Object.keys(this.tableData[0]).map(key => ({ header: this.tableData[0][key].field, field: key }));
      //full width dialog
      if (this.columns?.length > 6) {
        this.dialogWidth = '95vw';
      }

      this.tableDataFormatted = this.formatTableData(this.tableData)
      //pdf columns
      this.exportPDFColumns = this.columns.map(col => ({
        title: col.header,
        dataKey: col.field
      }));

      // Handle successful report generation
      // console.log(JSON.stringify(this.tableDataFormatted));
      this.display = true;
    }).catch(error => {
      console.error('Error generating report:', error);
    });
  }

  exportPdf() {
    let doc = new jsPDF('p', 'pt');
    // let pdfFontSize = 8
    //doc.addPage('a2');
    //screen orientation configuration
    if (this.exportPDFColumns.length > 5) {
      doc = new jsPDF('landscape');
    }
    else {
      doc = new jsPDF('p', 'pt');
    }
    const styles = {
      theme: 'grid',
      styles: { overflow: 'linebreak', fontSize: 8 },
      headStyles: { fillColor: [220, 53, 69], textColor: [255, 255, 255], fontSize: 7, overflow: 'linebreak' },

    };
    doc['autoTable']({
      columns: this.exportPDFColumns,
      body: this.tableDataFormatted,
      margin: { top: 5, right: 5, bottom: 5, left: 5 },
      ...styles,
      tableWidth: 'wrap',
      horizontalPageBreak: true,
    });
    let fileName = String(this.report.reportData.name).trim()
    doc.save(fileName + "_" + this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + ".pdf");
  }

  exportExcel() {
    //this.reportsTable.rows = 1000000; 
    let tableDataExcel = this.formatTableDataExcel(this.tableData)
    let element = document.getElementById("dt");
    this.excelService.exportTableAsExcelFileV2(element, this.report.reportData.name, this.tableData, this.columns, tableDataExcel);
    //this.reportsTable.rows = 3;

  }
  //mapping taula HTML
  formatTableData(tableData: any[]): any[] {
    return tableData.map(obj => {
      let newTableData = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let field = key;
          let value = obj[key].value;
          newTableData[field] = value;
        }
      }
      return newTableData;
    });
  }
  //mapping EXCEL
  formatTableDataExcel(tableData: any[]): any[] {
    return tableData.map(obj => {
      let newTableData = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let field = obj[key].field;
          let value = obj[key].value;
          newTableData[field] = value;
        }
      }
      return newTableData;
    });
  }


  //es fa un custom sort per ordenar les columnes tipus date i integer, per defecte no funcionen
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
      if (this.isInteger(value1) && typeof this.isInteger(value2)) {
        result = value1 - value2;
      }
      else if (this.isValidDate(value1) && this.isValidDate(value2)) {
        let date1 = new Date(this.parseDate(value1));
        let date2 = new Date(this.parseDate(value2));
        result = date1.getTime() - date2.getTime();
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      if (result === null) {
        if (value1 === null && value2 !== null) result = -1;
        else if (value1 !== null && value2 === null) result = 1;
        else if (value1 === null && value2 === null) result = 0;
      }

      return event.order * result;
    });
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(this.parseDate(dateString));
    return !isNaN(date.getTime());
  }

  isInteger(value: string): boolean {
    return /^\d+$/.test(value);
  }

  parseDate(dateString): string {
    const parts = dateString.split("/");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
}

