import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from 'bootstrap';
import { Observable, Subscription, of } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { Rest } from '../../rest/rest_client';
import User = Rest.User;
import { LazyLoadEvent } from 'primeng/api';
import { RestExt } from 'app/services/rest-client-extension';
import { IncidencesNotReadService } from 'app/modules/task/Logistics/services/incidences/incidences-nr.service';
import { Rest_logistics } from 'app/modules/task/Logistics/services/rest_client_logistics';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RealTimeDataService } from 'app/services/rt-data/rt-data.service';
import { RestExtTask } from 'app/modules/task/rest-client-task-extension';
import { NotificationNavigationService } from './notification-navigation.service';

import rExt = RestExtTask;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [IncidencesNotReadService],
})
export class HeaderComponent implements OnInit {
    userObservable: Observable<User>;
    sectionIconUrl: string;
    sectionTitle: string;

    userProfileImage: string;
    userNameAndSurname: string;

    routerUrl: string;

    // Header notifications pannel
    pendingIncidences: boolean = false; // This can reflect the initial API call
    hasRemainingIncidences: boolean = false; // Local state to toggle UI
    showNotifications: boolean = false;

    // PERMISSIONS VARIABLES
    user: Rest.User;
    manageincidences: boolean;

    private subs = new Subscription();

    constructor(
        private i18n: I18nService,
        private router: Router,
        private translate: TranslateService,
        private auth: AuthenticationService,
        private notificationsService: NotificationsService,
        protected incidencesNrService: IncidencesNotReadService,
        private rtDataService: RealTimeDataService,
        private notificationNavigationService: NotificationNavigationService,
    ) {
        this.userObservable = this.auth.userObservable;

        this.notificationNavigationService.showNotifications$.subscribe(show => {
            this.showNotifications = show;
        });
    }

    ngOnInit(): void {
        this.userObservable.subscribe((user) => {
            if (user) {
                // Initialization code
                if (user.profileImage != null) {
                    this.userProfileImage = user.profileImage.imageEncoded;
                } else {
                    this.userProfileImage = 'assets/icons/default-user.png';
                }

                this.manageincidences = rExt.hasPermissionrp(rExt.ActionTaskEnum.TASK_INCIDENCES_NOTREAD_VIEW, user);

                if (user.surname1) {
                    this.userNameAndSurname = user.name + ' ' + user.surname1;
                } else {
                    this.userNameAndSurname = user.name;
                }

                // Conditional code based on the manageincidences flag
                if (this.manageincidences) {
                    this.getNrIncidences({ first: 0, rows: 10, sortOrder: 1 });
                    this.subs.add(this.notificationNavigationService.hasRemainingIncidences$.subscribe(value => {
                        this.hasRemainingIncidences = value;
                    }));

                    this.rtDataService.notReadedIncidents.subscribe((incidence) => {
                        console.log('Incidence received: ', incidence);

                        // CHANGE THE VALUE OF THE NOTIFICATION ICON
                        this.hasRemainingIncidences = true;
                        this.notificationNavigationService.setHasRemainingIncidences(true);
                    });
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe(); // Prevent memory leaks
    }

    showHeader() {
        this.routerUrl = this.router.url;

        /* Check the Router location in order to set the header title and icon */
        this.sectionIconUrl = 'assets/icons/settings.svg';
        this.sectionTitle = this.translate.instant('header.settings');

        switch (this.routerUrl) {
            case '/rt':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/explore.svg';
                break;
            case '/COEXPanel':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/cube-grid.svg';
                break;
            case '/reports':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/racing':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/radar.svg';
                break;
            case '/tax':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/taco':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1));
                this.sectionIconUrl = 'assets/icons/id-card.svg';
                break;
            case '/sat/messages':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1, 4));
                this.sectionIconUrl = 'assets/icons/tools.svg';
                break;
            case '/sat/received-messages':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1, 4));
                this.sectionIconUrl = 'assets/icons/tools.svg';
                break;
            case '/sat/devices':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1, 4));
                this.sectionIconUrl = 'assets/icons/tools.svg';
                break;
            case '/sat/users':
                this.sectionTitle = this.translate.instant('header.' + this.routerUrl.slice(1, 4));
                this.sectionIconUrl = 'assets/icons/tools.svg';
                break;
            case '/inventoryVehicles':
                this.sectionTitle = this.translate.instant('header.inventoryVehicles');
                this.sectionIconUrl = 'assets/icons/vehicle-inventory.svg';
                break;
            case '/drivers':
                this.sectionTitle = this.translate.instant('header.drivers');
                this.sectionIconUrl = 'assets/icons/person.svg';
                break;
            case '/maintenance':
                this.sectionTitle = this.translate.instant('header.maintenance');
                this.sectionIconUrl = 'assets/icons/maintenance.svg';
                break;
            case '/reports-wizard':
                this.sectionTitle = this.translate.instant('header.reportsWizard');
                this.sectionIconUrl = 'assets/icons/reports-wizard.svg';
                break;
            case '/tableManagement':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/providers':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/vehicleUsage':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/brands':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/models':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/delegations':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/subdelegations':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/companies':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/costCentres':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/tableManagement/leaders':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/dataImport':
                this.sectionTitle = this.translate.instant('header.tableManagement');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/dashboards':
                this.sectionTitle = this.translate.instant('header.dashboards');
                this.sectionIconUrl = 'assets/icons/dashboard.svg';
                break;
            case '/administration/delegations':
                this.sectionTitle = this.translate.instant('header.administration');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/administration/userbo':
                this.sectionTitle = this.translate.instant('header.administration');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/administration/userapp':
                this.sectionTitle = this.translate.instant('header.administration');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
            case '/administration/routes':
                this.sectionTitle = this.translate.instant('header.administration');
                this.sectionIconUrl = 'assets/icons/settings.svg';
                break;
                case '/administration/customers':
                    this.sectionTitle = this.translate.instant('header.administration');
                    this.sectionIconUrl = 'assets/icons/settings.svg';
                    break;
            case '/logistics/orders':
                this.sectionTitle = this.translate.instant('header.logistics');
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/logistics/routesg':
                this.sectionTitle = this.translate.instant('header.logistics');
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/logistics/incidences':
                this.sectionTitle = this.translate.instant('header.logistics');
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
                case '/logistics/transactions':
                    this.sectionTitle = this.translate.instant('header.logistics');
                    this.sectionIconUrl = 'assets/icons/file-text.svg';
                    break;
            case '/logistics/envases':
                this.sectionTitle = this.translate.instant('header.logistics');
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/logistics/logisticaInversa':
                this.sectionTitle = this.translate.instant('header.logistics');
                this.sectionIconUrl = 'assets/icons/file-text.svg';
                break;
            case '/predictions':
                this.sectionTitle = this.translate.instant('header.predictions');
                this.sectionIconUrl = 'assets/icons/prediction_icon.svg';
                break;
            case '/predictions/prediction':
                this.sectionTitle = this.translate.instant('header.predictions');
                this.sectionIconUrl = 'assets/icons/prediction_icon.svg';
                break;
            case '/predictions/classification':
                this.sectionTitle = this.translate.instant('header.predictions');
                this.sectionIconUrl = 'assets/icons/prediction_icon.svg';
                break;
            case '/predictions/anomalies':
                this.sectionTitle = this.translate.instant('header.predictions');
                this.sectionIconUrl = 'assets/icons/prediction_icon.svg';
                break;
                case '/predictions/entrenament':
                    this.sectionTitle = this.translate.instant('header.predictions');
                    this.sectionIconUrl = 'assets/icons/prediction_icon.svg';
                    break;
        }

        /* Check the Router location in order to show or hide the header */
        return !(
            this.routerUrl === '/' ||
            this.routerUrl.includes('/login') ||
            this.routerUrl === '/logout' ||
            this.routerUrl === '/404' ||
            this.routerUrl === '/map/sat-map'
        );
    }

    /*
     * Implement show notifications function 
    */
    showNotificationsPanel() {
        this.notificationNavigationService.toggle();
    }

    /**
   * Get not resolved incidences and notify user
   */
    getNrIncidences(event: LazyLoadEvent): void {
        let paginationRequest: Rest.ListPaginationRequest;
        let nrIncidences: Rest.Page<Rest_logistics.Order>;

        paginationRequest = RestExt.allRecordsRequest();
        paginationRequest.sortAsc = false;
        paginationRequest.filters = {}
        paginationRequest.pageSize = event.rows;
        paginationRequest.sortAsc = event.sortOrder === 1;
        paginationRequest.offset = event.first;

        this.incidencesNrService.getPage(paginationRequest).then((incidences) => {
            nrIncidences = incidences;
            const hasIncidences = nrIncidences.entities.length > 0;

            this.notificationNavigationService.setHasRemainingIncidences(hasIncidences);
            this.pendingIncidences = hasIncidences; // Update local flag if needed
        });
    }

    /* TODO: Implement show help function */
    showHelpPanel() {
        //console.log("language: " + this.auth.user.language)
        const otherWindow = window.open('https://www.atlantisioe.com/' + this.auth.user.language + '/fleet/', 'window');
    }

    showUserPanel(modalRef: ElementRef) {
        const modal = new Modal(modalRef.nativeElement);
        modal.show();
    }
}
