import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { JQueryHTTPClient } from "./JQueryHTTPClient";
import { Rest } from "./rest_client";

@Injectable()
export class RoleService extends EntityService<any> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Role): Rest.RestResponse<Rest.Role> {
    return this.client.Role_create(arg0);
  }

  delete(arg0: Rest.Role[]): Rest.RestResponse<void> {
    return this.client.Role_delete(arg0);
  }

  update(arg0: Rest.Role): Rest.RestResponse<Rest.Role> {
    return this.client.Role_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.Role>> {
    return this.client.Role_getPage(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.Role> {
    return this.client.Role_find(arg0, queryParams);
  }
}
