import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-time-configuration',
  templateUrl: './time-configuration.component.html',
  styleUrls: ['./time-configuration.component.css'],

  encapsulation: ViewEncapsulation.None
})
export class TimeConfigurationComponent extends ServerValidatedFormComponent implements OnInit {

  constructor(protected i18n: I18nService) {  super(i18n);}
  @Input() time: Rest.TimeConfiguration;

  ngOnInit() {
  }

}
