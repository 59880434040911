import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class SpaceService extends EntityService<Rest.FidSpace> {

	client: Rest.RestApplicationClient;
	startedEditing = new Subject<Rest.FidSpace>();
	startedCreating = new Subject();
	endTranslationEditing = new Subject<Rest.FidCampaigns>();
	spaceAdded = new Subject<Rest.FidSpace>();
	campaignSaved = new Subject<Rest.FidCampaigns>();

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService,
		private translate: TranslateService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	/** obtenir llista de spaces **/
	public getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.FidSpace>> {
		let queryParams = {
			showRoutes: false,
			encodeImages: true
		};
		// console.log(arg0);
		// console.log(queryParams);
		return this.client.PromotedRoutes_getPage(arg0, queryParams);
	}

	/** deleteSpace() **/
	public deleteSpace(space: Rest.FidSpace): Promise<boolean> {

		return this.http.delete(environment.webApiBaseUrl + 'api/fid/space/' + space.id)
			.toPromise()
			.then(() => {
			   return true;
			})
			.catch((error) => {
			   this.notificationsService.add(Severity.error, 'Error', error.message);
			   return false;
			});
	}

	public addSpace(campaignData: string, image?: any): Promise<Rest.FidSpace> {

		const formData = new FormData();
		formData.append('image', image);
		formData.append('campaign', campaignData);

		return this.http.post(environment.webApiBaseUrl + 'api/fid/space/', formData)
			.toPromise()
			.then((response: any) => {
				this.notificationsService.add(Severity.success, 'OK', this.translate.instant('general.successMsg'));
				 return response;
			})
			.catch((error) => {
					console.log(error);
				 this.notificationsService.add(Severity.error, 'Error', error.message);
				 return null;
			});

	}

}
