<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Backup Battery Control ($ST+BBCTRL)</span>
      </div>
      <div class="right">
          <p-inputSwitch [(ngModel)]="backupBattery.enabled"></p-inputSwitch>
      </div>  
    </div>
  </p-header>
</p-accordionTab>
