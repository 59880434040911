import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { Subject } from 'rxjs';

@Injectable()
export class AccessoryService extends EntityService<Rest.Accessory>{
    accessory: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.accessory = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Accessory): Rest.RestResponse<Rest.Accessory> {
        return this.accessory.Accessory_create(arg0);
    }

    update(arg0: Rest.Accessory): Rest.RestResponse<Rest.Accessory> {
        return this.accessory.Accessory_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
    ): Rest.RestResponse<Rest.Page<Rest.Accessory>> {
        return this.accessory.Accessory_getPage(arg0);
    }

    getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Accessory>> {
        return this.accessory.Accessory_getPageAndFilters(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<any> {
        return this.accessory.Accessory_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Accessory> {
        return this.accessory.Accessory_find(arg0, queryParams);
    }

	 getListByClient(arg0: string) : Rest.RestResponse<Rest.Accessory[]> {
		return this.accessory.Accessory_getAll(arg0);
	}

    //TODO: Add the getBrandsOfType method

}
