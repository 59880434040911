import { Component, Input, OnInit } from "@angular/core";
import { I18nService } from "../../services/i18n/i18n.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.css"],
})
export class FormInput implements OnInit {
  @Input() fieldName: string;
  @Input() errors: any[];
  @Input() required: boolean;
  @Input() align?: string;

  toolTipRequired: string;
  asterisc: string;
  cursor: string;
  alignStyle: String;

  constructor(protected i18n: I18nService) {  }

  ngOnInit() {
    if (this.required) {
      this.toolTipRequired = this.i18n._("Required Field");
      this.asterisc = "(*)";
    } else {
      this.toolTipRequired = null;
      this.asterisc = "";
    }
	 if (this.align !== null) {
		switch(this.align) {
			case "cols-2": this.alignStyle = "d-flex row-cols-2 w-100 align-content-center"; break;
			case "cols-3": this.alignStyle = "d-flex row-cols-3 w-100 align-content-center"; break;
			default: break;
		}
	 }
  }
}
