import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";

@Injectable({
	providedIn: 'root'
 })
export class DelegationService extends EntityService<Rest.Delegation> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Delegation): Rest.RestResponse<Rest.Delegation> {
    try {
      return this.client.Delegation_create(arg0);
    } catch (error) {
      console.error("Error creating delegation:", error);
      throw error;
    }
  }

  update(arg0: Rest.Delegation): Rest.RestResponse<Rest.Delegation> {
    return this.client.Delegation_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.Delegation>> {
    return this.client.Delegation_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Delegation>> {
    return this.client.Delegation_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.Delegation_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.Delegation> {
    return this.client.Delegation_find(arg0, queryParams);
  }

  getList(clientId: number): Rest.RestResponse<Rest.Delegation[]> {
    return this.client.Delegation_getList(clientId);
  }
}
