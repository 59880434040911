<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible'
          header='{{ spaceFormTitle }}'
          [(visible)]='isDialogVisible'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [style]="{width: '80vw'}"
>
   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

	<!-- GENERAL PANEL -->
	<p-panel header="{{ 'promotedRoutes.translationsTitle' | translate }}"
		[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header>

		<!-- DISCLAMER -->
		<div class='d-flex flex-column gap-3 p-3'
			style="color: red;">
			{{ 'promotedRoutes.translationRequired' | translate }}
		</div>

		<!-- SECCION 1 -->
		<div class='d-flex flex-column gap-3 p-3'>

			<!-- ROW 1: ESPAÑOL -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="Español" [errors]='errors.name'>
					<textarea *ngIf="isTextArea" pInputTextarea id="Español" [(ngModel)]='translations.esp' [rows]="3" [style]="{'width':'350px'}" ></textarea>
					<input *ngIf="!isTextArea" pInputText [(ngModel)]='translations.esp' [style]="{'width':'350px'}"/>
				</form-input>
			</div>
			<!-- ROW 1: CATALÀ -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="Català" [errors]='errors.name'>
					<textarea *ngIf="isTextArea" pInputTextarea id="Català" [(ngModel)]='translations.cat' [rows]="3" [style]="{'width':'350px'}" ></textarea>
					<input *ngIf="!isTextArea" pInputText [(ngModel)]='translations.cat' [style]="{'width':'350px'}"/>
				</form-input>
			</div>
			<!-- ROW 1: ENGLISH -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="English" [errors]='errors.name'>
					<textarea *ngIf="isTextArea" pInputTextarea id="English" [(ngModel)]='translations.eng' [rows]="3" [style]="{'width':'350px'}" ></textarea>
					<input *ngIf="!isTextArea" pInputText [(ngModel)]='translations.eng' [style]="{'width':'350px'}" />
				</form-input>
			</div>
			<!-- ROW 1: Français -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="Français" [errors]='errors.name'>
					<textarea *ngIf="isTextArea" pInputTextarea id="Français" [(ngModel)]='translations.fra' [rows]="3" [style]="{'width':'350px'}" ></textarea>
					<input *ngIf="!isTextArea" pInputText [(ngModel)]='translations.fra' [style]="{'width':'350px'}"/>
				</form-input>
			</div>
			<!-- ROW 1: ITALIANO -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="Italiano" [errors]='errors.name'>
					<textarea *ngIf="isTextArea" pInputTextarea id="Italiano" [(ngModel)]='translations.ita' [rows]="3" [style]="{'width':'350px'}" ></textarea>
					<input *ngIf="!isTextArea" pInputText [(ngModel)]='translations.ita' [style]="{'width':'350px'}"/>
				</form-input>
			</div>

		</div>

	</p-panel>

	<!-- PIE DE PAGINA -->
   <p-footer>
      <div class='d-flex flex-row-reverse bd-highlight'>
         <div class='p-2 bd-highlight'>
            <button type='button' class='btn custom-button' (click)='onSaveTranslations()' [disabled]='isEditingDisabled'>
               OK
            </button>
         </div>
      </div>
   </p-footer>
</p-dialog>
