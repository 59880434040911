<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ title }}
		</div>
	</ng-template>
	<!-- #endregion -->

   <!-- #region TABLE TITLE DEFINITION -->
   <ng-template pTemplate="caption">
      <div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
         {{ title }}
      </div>
   </ng-template>
   <!-- #endregion -->
	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>
			<!-- NAME -->
			<th pSortableColumn="name">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'documents.table.name' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="name"></p-sortIcon>
						<p-columnFilter
							field="name"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- TYPE -->
			<th pSortableColumn="type">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'documents.table.type' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<!-- COLUMN FILTER -->
					<div class="d-flex align-items-center h-100">
						<p-columnFilter
							field="type"
							type="text"
							matchMode="contains"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						>
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-multiSelect
									[ngModel]="value"
									[options]="optionsFilterType"
									placeholder="{{ 'general.search' | translate }}"
									(onChange)="filter($event.value)"
								></p-multiSelect>
							</ng-template>
						</p-columnFilter>
					</div>
				</div>
			</th>

			<!-- FECHA -->
			<th>
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'documents.table.date' | translate }}
					</div>
				</div>
			</th>
			<!-- ACTIVE -->
			<th *ngIf="!nested" pSortableColumn="disabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>
				</div>
			</th>
			<!-- DOWNLOAD -->
			<th class="button-column"></th>

			<!-- DELETE -->
			<th class="button-column"></th>
		</tr>
	</ng-template>
	<!-- #endregion  -->

	<ng-template pTemplate="body" let-document>
		<tr class="p-selectable-row custom-rows" (dblclick)="openDialog(false, type, document, vehicle)">
			<!-- NAME -->
			<td>
				{{ document.name }}
			</td>

			<!-- TYPE -->
			<td>
				{{ 'documents.type.' + document.type | translate }}
			</td>

			<td>
				<ng-container *ngIf="document.updateDate !== null; then updateDate; else createDate"> </ng-container>
				<ng-template #updateDate>
					<div>
						{{ document.updateDate | date : 'dd/MM/yyyy' }}
					</div>
				</ng-template>
				<ng-template #createDate>
					<div>
						{{ document.createDate | date : 'dd/MM/yyyy' }}
					</div>
				</ng-template>
			</td>

			<!-- ACTIVE -->
			<td *ngIf="!nested">
				<div class="d-flex justify-content-center align-items-center">
					<i
						class="pi"
						[ngClass]="{
							'true-icon pi-check-circle': !document.disabled,
							'false-icon pi-times-circle': document.disabled
						}"
					>
					</i>
				</div>
			</td>

			<!-- DOWNLOAD FILE -->
			<td>
				<button type="button" class="btn custom-table-download-button" (click)="downloadFile(document)">
					<svg-icon
						src="assets/icons/download-button.svg"
						class="d-flex flex-wrap custom-table-download-button-icon"
						[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
					></svg-icon>
				</button>
			</td>

			<!-- DELETE -->
			<td>
				<button type="button" class="btn custom-table-remove-button" (click)="onDeleteDocument(document)">
					<svg-icon
						src="assets/icons/trash.svg"
						class="d-flex flex-wrap custom-table-remove-button-icon"
						[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
					></svg-icon>
				</button>
			</td>
		</tr>
	</ng-template>

	<!-- TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td [attr.colspan]="colspan" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- FOOTER -->
	<ng-template pTemplate="footer">
		<tr>
			<td [attr.colspan]="colspan" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD DELEGATION BUTTON -->
					<app-table-add-button
						*ngIf="isCreateGranted"
						[text]="documento"
						(btnClick)="openDialog(true, type, null, vehicle)"
						[isDisabled]="false"
					>
					</app-table-add-button>
				</div>
			</td>
		</tr>
	</ng-template>
</p-table>

<!-- : type === 'FINES' || type === 'TAXES' || type === 'FINANCING' || type === 'INSURANCE' ? entity.vehicle : null" -->
<app-doc-form
	#docForm
	[origen]="type"
	[vehicle]="type === 'VEHICLE' ? vehicle : null"
	[driver]="type === 'DRIVER' ? entity : null"
	[fine]="type === 'FINES' ? entity : null"
	[tax]="type === 'TAXES' ? entity : null"
	[financing]="type === 'FINANCING' ? entity : null"
	[insurance]="type === 'INSURANCE' ? entity : null"
	[execution]="type === 'EXECUTION' ? entity : null"
	(refreshTable)="refresh()"
>
</app-doc-form>

<!-- CONFIRM SAVE EVENT DIALOG -->
<p-confirmDialog
	#saveEventDialog
	[header]="'confirm.critic' | translate"
	[message]="'control-panel.delete-dialog.messageDocument' | translate"
	class="custom-dialog"
	icon="pi pi-exclamation-triangle"
>
	<ng-template pTemplate="footer">
		<!-- REJECT BUTTON -->
		<button (click)="saveEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>
		<!-- ACCEPT BUTTON -->
		<button (click)="saveEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>
	</ng-template>
</p-confirmDialog>
