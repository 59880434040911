<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display"
    appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- FRAME NUMBER -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label class="custom-label" i18n>
                    {{'control-panel.vehicles.licensePlate' | translate}}
                </label>

                <div class="p-3"></div>

                <input type="text" class="form-control custom-input" [(ngModel)]="entity.vehicle.name" [disabled]="true"
                    [style]="{ width: '200px' }">
            </div>
            <div class="col d-flex flex-row align-items-center"></div>
        </div>

        <!-- LAST -->
        <div *ngIf="isNew" class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label class="custom-label" i18n>
                    {{'tableManagement.mileage.last' | translate}}
                </label>

                <div class="p-3"></div>

                <input type="text" class="form-control custom-input" [(ngModel)]="lastKM" [disabled]="true"
                    [style]="{ width: '200px' }">
            </div>
            <div class="col d-flex flex-row align-items-center"></div>
        </div>

        <hr>

        <!-- DATE -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label class="custom-label" i18n>
                    {{'general.date' | translate}}
                </label>

                <div class="p-3"></div>

                <p-calendar [(ngModel)]="readingDate" [showTime]="true" [disabled]='!canEdit'
                    [style]="{ width: '200px' }" dateFormat="{{ i18n.dateFormat }}" showIcon="true"
                    appendTo="body"></p-calendar>
            </div>
            <div class="col d-flex flex-row align-items-center"></div>
        </div>

        <!-- MILEAGE -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label class="custom-label" i18n>
                    {{'tableManagement.mileage.mileage' | translate}}
                </label>

                <div class="p-3"></div>

                <input type="text" class="form-control custom-input" [(ngModel)]="entity.kms" [disabled]='!canEdit'
                    [style]="{ width: '200px' }">
            </div>
            <div class="col d-flex flex-row align-items-center"></div>
        </div>

    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="saveChanges()" [disabled]='!canEdit' i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>

                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveChanges()" [disabled]='!canEdit' i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>