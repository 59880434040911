import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { UserService } from "../../../rest/user.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { UserFormComponent } from "../user-form/user-form.component";
import { DeviceFormComponent } from "../../device-edit-view/device-form/device-form.component";
import Device = Rest.Device;
import User = Rest.User;

@Component({
    selector: "app-users-list",
    templateUrl: "./users-list.component.html",
    styleUrls: ["./users-list.component.css"],
    providers: [UserService, ConfirmationService, UserTimePipe],
})
export class UsersListComponent
    extends EntityListComponent<User | Device>
    implements OnInit {
    LanguageFilter: SelectItem[];
    MetricFilter: SelectItem[];
    EnabledFilter: SelectItem[];

    userService: UserService;
    // showStatusDialog = false;

    @ViewChild(UserFormComponent, {static: true}) userForm: UserFormComponent;
    @ViewChild(DeviceFormComponent, {static: true}) deviceForm: DeviceFormComponent;

    user: Rest.User;

    constructor(
        userService: UserService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            userService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );

        this.userService = userService;
        this.fetchEntityBeforeShowUpdateForm = true;

        this.MetricFilter = RestExt.getEnumItems(RestExt.MetricEnum, this.i18n);
        this.LanguageFilter = RestExt.getEnumItems(RestExt.LanguageEnum, this.i18n);
    }

    Metric(metric: Rest.Metric) {
        return metric;
    }

    Language(language: Rest.Language) {
        return language;
    }

    ngOnInit() {
        super.ngOnInit();
        this.hasEntityFilters = true;
        this.form = this.userForm;
        this.setExtraFilters();
        /*Permissions */
        //this.user = this.authenticationService.getCurrentUser();
        /** */
    }

    afterLoad() {
        // this.EnabledFilter = RestExt.getEnabledFilter(
        //     this.filters["enabled"],
        //     this.i18n
        // );
        // this.paginationResult.entities.forEach((usr: any) => {
        //     /*Permissions */
        //     usr._$changeStatus = this.user.canManageUsers;
        //     //usr._$update = this.user.canManageUsers;
        //     /** */
        // });
        // /*Permissions */
        // this.checkSelectAll();
        /** */
    }

    public showUserFormComponent(user: User): void {
        this.form = this.userForm;
        this.editDialog(user);
    }

    public showDeviceFormComponent(device: Device): void {
        this.form = this.deviceForm;
        this.deviceForm.showForm(device);
    }

    extraFilters = [];
setExtraFilters(){  
   let driverTitle = "test" 
   let packageNumberTitle = "Package Number" 
   this.extraFilters = [
      {field: 'user.name', header:  driverTitle},
      {field: 'pakcages.packageNumber',  header: packageNumberTitle  },
    ]; 
}
}
