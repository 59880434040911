<!-- VEHICLE PANEL MAP VIEW MAIN CONTAINER -->
<div class="container-fluid p-0 ">

  <!-- ROW 1: CONTAINERS -->
  <div class="row m-0">

    <!-- COL 1: LEFT CONTAINER - ACTIVE SINCE && ADDRESS && SPEED && SEND MESSAGE -->
    <div class="col p-0">
      <div class="container p-0 h-100 map-view-inner-container">

        <!-- LEFT CONTAINER HEADER-->
        <div class="container ps-3 pe-3" id="first-map-view-inner-container-header">
          <div class="d-flex align-items-center h-100">

            <!-- HEADER ICON -->
            <svg-icon src="assets/icons/device-panel-map-view-left-icon.svg" class="d-flex flex-wrap"
                      [svgStyle]="{ 'height.px': 20, 'width.px': 20}"></svg-icon>

            <!-- DEVICE ACTIVE SINCE -->
            <div class="ps-3 map-view-panel-title">
              Active since 25/08/2021 09:37:41
            </div>

          </div>
        </div>

        <!-- LEFT CONTAINER BODY -->
        <div class="container ps-3 pe-3 map-view-inner-container-body">

          <!-- ROW 1: ADDRESS -->
          <div class="row m-0 mt-3">

            <!-- ADDRESS ICON && TITLE -->
            <div class="d-flex align-items-center p-0">

              <!-- ADDRESS ICON -->
              <svg-icon src="assets/icons/house.svg" class="d-flex flex-wrap"
                        [svgStyle]="{ 'height.px': 20, 'width.px': 20}"></svg-icon>

              <!--ADDRESS TITLE -->
              <div class="d-flex align-items-center ps-3 map-view-panel-title">
                Address:
              </div>
            </div>

            <!-- ADDRESS CONTENT -->
            <div class="col p-0 map-view-address-content-container">

              <div *ngIf="!location" class="row ps-3 m-0 map-view-panel-content-text">
                Unknown
              </div>

              <!-- ADDRESS CONTENT FIRST LINE - STREET & NUMBER -->
              <div *ngIf="location" class="row ps-3 m-0 map-view-panel-content-text">
                {{addressFirstLine}}
              </div>

              <!-- ADDRESS CONTENT SECOND LINE - CITY & PROVINCE -->
              <div *ngIf="location" class="row ps-3 m-0 mt-1 map-view-panel-content-text">
                {{addressSecondLine}}
              </div>

            </div>

          </div>

          <!-- ROW 2: SPEED-->
          <div *ngIf="settings.showSpeeed" class="row m-0 mt-3">

            <!-- SPEED ICON && TITLE -->
            <div class="d-flex align-items-center p-0" id="speed-icon-title-container">

              <!-- SPEED ICON -->
              <svg-icon src="assets/icons/speed.svg" class="d-flex flex-wrap"
                        [svgStyle]="{ 'height.px': 16, 'width.px': 20}"></svg-icon>

              <!-- SPEED TITLE -->
              <div class="d-flex align-items-center ps-3 map-view-panel-title">
                Speed:
              </div>

            </div>

            <!-- SPEED CONTENT -->
            <div class="col p-0 map-view-address-content-container">

              <div class="row ps-3 m-0 map-view-panel-content-text">
                {{info.speed|distance:true}}/h
              </div>

            </div>

          </div>

          <!-- ROW 3: SEND MESSAGE BUTTON -->
          <div class="row m-0 mt-3 mb-3">
            <button 
             [hidden]='isUserHosp'
              class="btn custom-button" id="map-view-send-message-button" 
              (click)="showSMSForm(sendMessageModal['sendMessageModal'])" 
              i18n>
              <span class="d-flex align-items-center">
                <svg-icon src="assets/icons/send-message-button-icon.svg" class="d-flex flex-wrap"
                          [svgStyle]="{ 'height.px': 15, 'width.px': 20}"></svg-icon>
                <span class="ps-2">Send message</span>
              </span>
            </button>
          </div>

        </div>

      </div>
    </div>

    <!-- COL 2: CENTER CONTAINER - CURRENT TIME && DEVICE TIME && LAST GPS TIME && PLATFORM TIME -->
    <div class="col ms-3 me-3 p-0">
      <div class="container p-0 h-100 map-view-inner-container">

        <!-- CENTER CONTAINER HEADER-->
        <div class="container ps-3 pe-3 map-view-inner-container-header">
          <div class="d-flex align-items-center h-100">

            <!-- HEADER ICON -->
            <svg-icon src="assets/icons/clock.svg" class="d-flex flex-wrap"
                      [svgStyle]="{ 'height.px': 30, 'width.px': 30}"></svg-icon>

            <!-- TIME -->
            <div class="ps-3 map-view-panel-header-title-left">
              Time:
              <span class="map-view-panel-header-title-right">
                {{currentTime | date:'dd/MM/yyyy, HH:mm'}}h
              </span>
            </div>

          </div>
        </div>

        <!-- CENTER CONTAINER BODY -->
        <div class="container ps-3 pe-3 map-view-inner-container-body">

          <!-- ROW 1: DEVICE TIME -->
          <div class="row m-0 mt-3">

            <!--DEVICE TIME TITLE -->
            <div class="row align-items-center p-0 m-0 map-view-panel-title">
              Device time:
            </div>

            <!-- DEVICE TIME CONTENT -->
            <div class="row p-0 m-0 map-view-panel-content-text">
              {{info.deviceTime|usertime}}
            </div>

          </div>

          <!-- ROW 2: LAST GPS TIME -->
          <div class="row m-0 mt-3">

            <!-- LAST GPS TIME TITLE -->
            <div class="row align-items-center p-0 m-0 map-view-panel-title">
              Last GPS time:
            </div>

            <!-- LAST GPS TIME CONTENT -->
            <div class="row p-0 m-0 map-view-panel-content-text">
              {{info.lastGPSDateTime|usertime}}
            </div>

          </div>

          <!-- ROW 3: PLATFORM TIME -->
          <div class="row m-0 mt-3 mb-3">

            <!-- PLATFORM TIME TITLE -->
            <div class="row align-items-center p-0 m-0 map-view-panel-title">
              Platform time:
            </div>

            <!-- PLATFORM TIME CONTENT -->
            <div class="row p-0 m-0 map-view-panel-content-text">
              {{info.platformTime|usertime}}
            </div>

          </div>

        </div>

      </div>
    </div>

    <!-- COL 3: RIGHT CONTAINER -->
    <div class="col w-100 p-0">
      <div class="container p-0 h-100 map-view-inner-container">

        <!-- RIGHT CONTAINER HEADER-->
        <div class="container ps-3 pe-3 map-view-inner-container-header">
          <div class="d-flex align-items-center h-100">

            <div class="d-flex align-items-center w-100">

              <!-- HEADER ICON -->
              <svg-icon src="assets/icons/radar.svg" class="d-flex flex-wrap"
                        [svgStyle]="{ 'height.px': 30, 'width.px': 30}"></svg-icon>

              <!-- POSITION -->
              <div class="ps-3 map-view-panel-header-title-left">
                Position:
                <span class="map-view-panel-header-title-right">
                  {{info.latitude}}, {{info.longitude}}
                </span>
              </div>

            </div>

            <div class="d-flex align-items-center">
              <button class="btn p-0" id="map-view-center-map-button" (click)="centerVehicleOnMap()">
                <svg-icon src="assets/icons/center-map.svg" class="d-flex flex-wrap"
                          [svgStyle]="{ 'height.px': 25, 'width.px': 25}"></svg-icon>
              </button>
            </div>

          </div>
        </div>

        <!-- RIGHT CONTAINER BODY -->
        <div class="container p-0 map-view-inner-container-body">

          <!-- ROW 1: MAP -->
          <div class="row m-0" id="map-view-small-map-container">
            <app-custom-map [customMapStyle]="'border: 0; border-radius: 30px;'"
                            [mapId]="mapID"
                            [options]="customMapOptions"
                            [showCercacliaOptions]="false"
                            display=true
            ></app-custom-map>
          </div>

        </div>

      </div>
    </div>

  </div>

  <!-- ROW 2: DETAILS NOT PRESENT IN THE DESIGN -->
  <div *ngIf="false"
       class="row mt-3 m-0 p-4"
       style="border: 1px solid #C7D1D3; border-radius: 10px;"
  >

    <h1 class="fs-3 text-center"><--- DETAILS NOT PRESENT IN THE DESIGN ---></h1>

    <!-- STATUS -->
    <div *ngIf="settings.showStatus" class="row mt-3 p-2" style="border: 1px solid #C7D1D3; border-radius: 10px;">
      <span><span i18n>Status: </span>{{info.color}}</span>
    </div>

    <!-- SATELLITES -->
    <div class="row mt-3 p-2" style="border: 1px solid #C7D1D3; border-radius: 10px;">
      <span><span i18n>Satellites: </span>{{info.satelite}}</span>
    </div>

    <!-- MILEAGE -->
    <div *ngIf="settings.showMileage" class="row mt-3 p-2" style="border: 1px solid #C7D1D3; border-radius: 10px;">
      <span><span i18n>Vehicle Mileage: </span>{{info.vehicleMileage|distance:true}}</span>
      <span><span i18n>Device Mileage: </span>{{info.deviceMileage|distance:true}}</span>
    </div>

    <!-- STATUS ICONS -->
    <div *ngIf="settings.showStatus" class="row mt-3 p-2" style="border: 1px solid #C7D1D3; border-radius: 10px;">
      <div class="d-flex align-items-center justify-content-start gap-3">
        <span><i class="fa fa-wifi" [ngStyle]="{'color':gpsIconColor}"></i></span>
        <span><i class="fa fa-signal" [ngStyle]="{'color':signalIconColor}"></i></span>
        <span class="fa fa-arrow-circle-down"
              [ngStyle]="{'-ms-transform':stringArrwStyle,'-webkit-transform':stringArrwStyle,'transform':stringArrwStyle}"></span>
        <span><i class="fa fa-user" [ngStyle]="{'color':driverIconColor}"></i></span>
      </div>
    </div>

    <!-- ANALOG IO-->
    <div *ngIf="settings.showAnalogIO" class="row mt-3 p-2" style="border: 1px solid #C7D1D3; border-radius: 10px;">
      <span>Vehicle IO: </span>
      <app-io [vehicle]=vehicle></app-io>
    </div>
  </div>

</div>

<!-- SEND MESSAGE COMPONENT -->
<app-sms #sendMessageModal [vehicleId]=vehicle.id></app-sms>

