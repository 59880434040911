<!-- TERMINALS LIST CONTAINER -->
<div *ngIf='terminalsList && terminalsList.length !== 0'>

    <div class='d-flex flex-row justify-content-end pb-2'>
        <button id='favourite-button'
                class='btn custom-button'
                [disabled]='selectedTerminals.length === 0'
                (click)='updateTerminalNotifications()'
        >
            <div class='d-flex flex-row align-items-center gap-2'>
                <svg-icon src="assets/icons/star.svg" [svgStyle]="{'width.px': 20}"></svg-icon>
                <div class='d-flex'>
                    {{ 'general.favourite' | translate }}
                </div>
            </div>
        </button>
    </div>

    <!-- TERMINALS LIST CONTAINER -->
    <div class='d-flex flex-column gap-3'>
        <div *ngFor='let terminal of terminalsList'>

            <div class='d-flex flex-row gap-3 p-3 terminal-container'>

                <!-- SELECT TERMINAL -->
                <div class='d-flex flex-column justify-content-center'>
                    <div class='d-flex'>
                        <p-checkbox name="terminals"
                                    [value]='terminal'
                                    [disabled]='terminal.pushReception'
                                    [(ngModel)]="selectedTerminals"
                                    (onChange)='updateSelectedTerminals($event)'
                        ></p-checkbox>
                    </div>
                </div>

                <!-- TERMINAL DESCRIPTION && TERMINAL PLATFORM && TERMINAL APP VERSION && TERMINAL APP TOKEN -->
                <div class='d-flex flex-column gap-2 flex-grow-1'>

                    <!-- TERMINAL DESCRIPTION && TERMINAL PLATFORM && TERMINAL APP VERSION -->
                    <div class='d-flex flex-row gap-5'>

                        <!-- TERMINAL DESCRIPTION -->
                        <div class='d-flex flex-column justify-content-center terminal-description-text'>
                            {{terminal.deviceDesc}}
                        </div>

                        <!-- TERMINAL PLATFORM -->
                        <div class='d-flex flex-column justify-content-center terminal-platform-text'>
                            ({{terminal.platformId}})
                        </div>

                        <!-- TERMINAL APP VERSION -->
                        <div class='d-flex align-items-center gap-3 text-nowrap terminal-app-version-text'>
                            {{ 'general.version' | translate }}:
                            <div *ngIf='terminal.appVersion; else appVersionNull'>{{terminal.appVersion}}</div>
                            <ng-template #appVersionNull>N/A</ng-template>
                        </div>

                    </div>

                    <!-- TERMINAL TOKEN -->
                    <div class='d-flex flex-column flex-grow-1'>
                        <p-accordion class='custom-terminal-accordion'>
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    Token
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class='text-break'>
                                        {{terminal.deviceToken}}
                                    </div>
                                </ng-template>
                            </p-accordionTab>
                        </p-accordion>
                    </div>

                </div>

                <!-- TERMINAL DELETE && NOTIFICATION BUTTON -->
                <div class='d-flex flex-row'>

                    <div class='d-flex flex-column align-items-center justify-content-center'>

                        <!-- TERMINAL NOTIFICATION -->
                        <div *ngIf='terminal.pushReception; else deleteButton'>
                            <svg-icon src="assets/icons/star.svg" id='star-icon'
                                      [svgStyle]="{'width.px': 20}"></svg-icon>&nbsp;
                               <button type='button' class='btn delete-terminal-button'
                                      (click)='confirmDeleteTerminal(terminal, true)'>
                                  <svg-icon src='assets/icons/trash.svg'
                                            class='d-flex flex-wrap'
                                            [svgStyle]="{ 'height.px': 25 }"
                                  ></svg-icon>
                              </button>
                        </div>

                        <!-- DELETE BUTTON -->
                        <ng-template #deleteButton>
                            <button type='button' class='btn delete-terminal-button'
                                    (click)='confirmDeleteTerminal(terminal, false)'>
                                <svg-icon src='assets/icons/trash.svg'
                                          class='d-flex flex-wrap'
                                          [svgStyle]="{ 'height.px': 25 }"
                                ></svg-icon>
                            </button>
                        </ng-template>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<!-- EMPTY MESSAGE CONTAINER -->
<div *ngIf='terminalsList && terminalsList.length === 0'
     id='emptyTerminalsListMessage'
     class='d-flex flex-row justify-content-center'
>
    {{ 'error.empty-list.terminals' | translate }}!
</div>

<!-- LOADING CONTAINER -->
<app-loading [loading]='!terminalsList' [fullScreen]='false' [height]='100' [width]='100'></app-loading>

<!-- CONFIRM DELETE TERMINAL DIALOG -->
<p-confirmDialog id='confirmDeleteTerminalDialog'
                 icon="pi pi-exclamation-triangle"
                 position='bottom-right'
                 [closeOnEscape]='false'
                 defaultFocus='reject'
                 acceptLabel="{{ 'general.yes' | translate }}"
                 rejectLabel="{{ 'general.no' | translate }}"
>
    <ng-template pTemplate='header'></ng-template>
</p-confirmDialog>
