<div *ngIf="display">

    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <p-messages [(value)]="messages"></p-messages>
        </div>
    </div>

    <p-panel i18n-header header="Fleet">

        <div class="ui-g">
            <div class="ui-g-12 ui-md-6">

                <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
                    <input pInputText id="name" [(ngModel)]="fleet.name" type="text" />
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName [required]="false" fieldName="enabled" [errors]="errors.enabled">
                    <p-checkbox disabled="true" [(ngModel)]="fleet.enabled" binary="true"></p-checkbox>
                </form-input>
            </div>

            <form-input i18n-fieldName [required]="false" fieldName="Metadata">
                <app-metadata-editor [(metadata)]="fleet.metadata"></app-metadata-editor>
            </form-input>





            <div class="ui-g-12 ui-md-6 row">
                <div class="col col-md-2">
                    <div *ngIf="isNew;then create else update"></div>
                    <ng-template #create>
                        <button type="button" pButton icon="fa fa-check" (click)="saveFleet()" i18n-label label="Save"></button>
                    </ng-template>
                    <ng-template #update>
                        <div *ngIf="permissions._$update">
                            <button type="button" pButton icon="fa fa-check" (click)="saveFleet()" i18n-label label="Save"></button>
                        </div>
                        <div *ngIf="permissions._$update===false">
                            <div style="float: left" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
                                <button disabled="true" type="button" pButton icon="fa fa-check" (click)="saveFleet()" i18n-label label="Save"></button>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="showChangeStatus" class="col col-md-2">
                    <!-- #ChangeStatus  -->
                    <div class="ui-g-8">
                        <div *ngIf="checkBoxDisabled[fleetName+'-'+fleet.id]===false;then permission else noPersmission"></div>
                        <ng-template #permission>
                            <button type="button" pButton icon="fa fa-close" style="float:left" (click)="changeStatus()" i18n-label label="Change status"></button>
                        </ng-template>
                        <ng-template #noPersmission>
                            <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status" tooltipPosition="top">
                                <button disabled="true" type="button" pButton icon="fa fa-close" style="float:left" (click)="changeStatus()" i18n-label label="Change status"></button>
                            </div>
                        </ng-template>
                    </div>
                    <!--  -->
                </div>
            </div>
            <div class="ui-g-12 ui-md-6">
                <div class="ui-g-4">
                </div>
                <div class="ui-g-8">
                </div>
            </div>
        </div>
    </p-panel>

    <!-- #ChangeStatus  -->
    <div *ngIf="showStatusDialog">
        <app-change-status-dialog [items]=title [display]="true" [service]=fleetService [entitiesList]=selectedEntities (return)="refreshForm($event)"
            (close)="closeStatusDialog($event)"></app-change-status-dialog>
    </div>
    <!--  -->
</div>
