import { Component } from "@angular/core";
import { SelectItem } from "primeng/api";

import { ConversionalgorithmService } from "../../../rest/conversionalgorithm.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { SimpleForm } from "../../simple-form/simple-form";

@Component({
    selector: "app-sensor-form",
    templateUrl: "./sensor-form.component.html",
    styleUrls: ["./sensor-form.component.css"],
    providers: [ConversionalgorithmService],
})
export class SensorFormComponent extends SimpleForm<Rest.Sensor> {
    enableFilter;
    conversionAlgorithmService: ConversionalgorithmService;
    private availableTypes: SelectItem[];
    user: Rest.User;
    title: String;

    constructor(
        conversionAlgorithmService: ConversionalgorithmService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
        super(notificationsService, i18n, authenticationService);
        this.conversionAlgorithmService = conversionAlgorithmService;
        this.availableTypes = RestExt.getEnumItems(
            RestExt.SensorTypeEnum,
            this.i18n
        );
    }

    ngOnInit() {
        //TODO: ENABLEFILTER
        this.enableFilter = {
            sortBy: "name",
            sortAsc: true,
            pageSize: 10,
            loadPermissions: true,
            filters: {},
        };

        super.ngOnInit();
        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    save() {
        this.return.emit(this.entity);
        this.display = false;
    }

    beforeShow() {
        if (this.isNew) {
            this.title = this.i18n._("Create Sensor");
        } else {
            this.title = this.i18n._("Edit Sensor");
        }
    }
}
