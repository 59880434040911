import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

import Tax = Rest.Tax;
import { TaxService } from "app/modules/vehicle-inventory/services/table-management/taxes.service";
import { TaxFormComponent } from "../taxes-form/taxes-form.component";
import { RestVehicleInventory } from "app/modules/vehicle-inventory/services/rest_vehicle-inventory";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-taxes-list',
  templateUrl: './taxes-list.component.html',
  styleUrls: ['./taxes-list.component.css'],
  providers: [ConfirmationService, UserTimePipe, ExcelService],
})
export class TaxListComponent
  extends EntityListComponent<Tax>
  implements OnInit, OnDestroy {
  @Input() vehicle?: Rest.Vehicle = null;
  @Input() startDate?: Date = null;
  @Input() endDate?: Date = null;
  @Input() nested?: Boolean = false;

  @Input() showTitle: boolean = true;

  title: string = this.i18n._("Tax");
  showVehicleColumn: boolean = true;

  @ViewChild(TaxFormComponent, { static: true })
  TaxForm: TaxFormComponent;

  user: Rest.User;
  EnabledFilter: SelectItem[];

  refreshListSuscription: Subscription;

  // PERMISSIONS
  isCreateGranted: boolean = false;
  isFormGranted: boolean = false;
  isDisabledGranted: boolean = false;

  constructor(
    private TaxService: TaxService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected translateService: TranslateService
  ) {
    super(
      TaxService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );

    this.TaxService = TaxService;
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.TaxForm;
    this.hasEntityFilters = false;

    if (this.vehicle !== null) {
      this.showVehicleColumn = false;
    }
    this.refreshListSuscription = this.TaxService.refreshList.subscribe(
      (item: RestVehicleInventory.InvVehicleHtcRequest) => {
        //console.log(" ____ REFRESH TAX LIST _____");
        this.getList(item.startDate, item.endDate);
      }
    );

    this.setPermissions();
  }

  onDestroy() {
    this.refreshListSuscription.unsubscribe();
  }

  setPermissions() {
    const user = this.authenticationService.user;
    const rolesForForm = ['INV_TAXES_UPDATE', 'INV_TAXES_READ'];

    this.isCreateGranted = this.authenticationService.isRoleGranted('INV_TAXES_CREATE', user);
    this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_TAXES_DISABLE', user);
    this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
  }

  editDialog(event) {
    if (!this.isFormGranted) {
      return;
    }

    if (!this.isSelectableColumn) {
      this.onRowDblClick({ data: event });
    }

    this.isSelectableColumn = false;
    this.isColumn = true;
  }

  beforeLoad(): void {
    //  console.log("[TAXES-LIST] beforeLoad ***");
    //  console.log(this.paginationRequest.filters)
    this.paginationRequest.filters = {};
    if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
    // if (this.startDate) this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
    // if (this.endDate) this.paginationRequest.filters['endDate'] = [this.endDate.toISOString()];
    if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }
    //  console.log(this.paginationRequest);
  }

  afterLoad() {
    //  console.log("[TAXES-LIST] afterLoad ***");
    //  console.log(this.paginationResult);
    this.paginationResult.entities.forEach((dl: any) => {
      dl._$noticeChannelsStr = this.arrayToString(dl.noticeChannels);
      /*Permissions */
      dl._$changeStatus = this.hasPermission(
        this.permissionsEntityTypeSelector("changeStatus", "Tax"),
        dl.id
      ); //This is for the validation of entity-list (selectAll function)
      dl._$update = this.hasPermission(
        this.permissionsEntityTypeSelector("update", "Tax"),
        dl.id
      );
      /** */
    });

    this.checkSelectAll();
  }

  public getList(startDate: Date, endDate: Date) {
    // console.log("[TAXES-LIST] getList ***");
    // console.log(startDate);
    // console.log(endDate);
    this.startDate = startDate;
    this.endDate = endDate;
    this.beforeLoad();
    super.refresh();
  }

  excelColumns = {};

  exportExcel() {
    this.setExcelColumns();
    let fileNameTitle = this.translateService.instant('tableManagement.Taxs.TaxsManagement');
    super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
  }

  setExcelColumns() {
    const vehicle = (entity: any) => entity.vehicle?.name ?? ''
    const description = (entity: any) => entity.description ?? ''
    const payDate = (entity: any) => entity.payDate ?? ''
    const importTax = (entity: any) => entity.taxImport ?? ''

    this.excelColumns = {
      [this.translateService.instant('general.vehicle')]: { bindField: vehicle },
      [this.translateService.instant('general.description')]: { bindField: description },
      [this.translateService.instant('tableManagement.tax.payDate')]: { bindField: payDate },
      [this.translateService.instant('tableManagement.fine.amount')]: { bindField: importTax },
    }
  }
}
