import { Injectable } from '@angular/core';
import { Rest } from '../../../rest/rest_client';
import { EntityService } from '../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';


@Injectable()
export class ReportFilesService extends EntityService<any> {
    private _startDate: Date | null;
    private _endDate: Date | null;
    private _vehicleId: number | null;
    private _groupId: number | null;
    private _fleetId: number | null;
    private _clientId: number | null;
    private _billingAccountId: number | null;

    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
        this._startDate = null;
        this._endDate = null;
        this._vehicleId = null;
        this._groupId = null;
        this._fleetId = null;
        this._clientId = null;
        this._billingAccountId = null;
    }

    public getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.ReportFiles>> {

        if ((this.vehicleId || this.groupId || this.fleetId || this.clientId || this.billingAccountId) && this.startDate && this.endDate) {

            if(this.vehicleId){
                let queryParams = {
                    vehicleId: this.vehicleId,
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString(),
                };
                return this.client.ReportFiles_getPage(arg0, queryParams);
            }else if(this.groupId){
                let queryParams = {
                    groupId: this.groupId,
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString(),
                };
                return this.client.ReportFiles_getPage(arg0, queryParams);
            }else if(this.fleetId){
                let queryParams = {
                    fleetId: this.fleetId,
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString(),
                };
                return this.client.ReportFiles_getPage(arg0, queryParams);
            }else if(this.clientId){
                let queryParams = {
                    clientId: this.clientId,
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString(),
                };
                return this.client.ReportFiles_getPage(arg0, queryParams);
            }else if(this.billingAccountId){
                let queryParams = {
                    billingAccountId: this.billingAccountId,
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString(),
                };
                return this.client.ReportFiles_getPage(arg0, queryParams);
            }
            
        } else {
            return this.client.ReportFiles_getPage(arg0);
        }

    }

    public downloadReportFile(arg0: number[]) : Rest.RestResponse<string>{
        return this.client.ReportFiles_download(arg0);
    }



    
    /* GETTERS && SETTERS */

    public get startDate(): Date | null {
        return this._startDate;
    };

    public set startDate(startDate: Date) {
        this._startDate = startDate;
    }

    public get endDate(): Date | null {
        return this._endDate;
    }

    public set endDate(endDate: Date) {
        this._endDate = endDate;
    }

    public get vehicleId(): number | null{
        return this._vehicleId;
    }

    public set vehicleId(vehicleId: number){
        this._vehicleId = vehicleId;
    }

    public get groupId(): number | null{
        return this._groupId;
    }

    public set groupId(groupId: number){
        this._groupId = groupId;
    }

    public get fleetId(): number | null{
        return this._fleetId;
    }

    public set fleetId(fleetId: number){
        this._fleetId = fleetId;
    }

    public get clientId(): number | null{
        return this._clientId;
    }

    public set clientId(clientId: number){
        this._clientId = clientId;
    }

    public get billingAccountId(): number | null{
        return this._billingAccountId;
    }

    public set billingAccountId(billingAccountId:number){
        this._billingAccountId = billingAccountId;
    }
}