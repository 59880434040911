import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { EntityService } from './entity.service';
@Injectable()
export class SimPlansService extends EntityService<Rest.SimPlans> {
   client: Rest.RestApplicationClient;
   constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
   }

   getSimPlansByGroup(groupId: string, queryParams?: { groupId?: number }): Rest.RestResponse<Rest.SimPlans[]> {
      return this.client.SimPlans_getSimPlansByGroup(groupId, queryParams);
   }
}
