import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { I18nService } from '../services/i18n/i18n.service';
import { NotificationsService, Severity } from '../services/notifications-service/notifications.service';
import { RestExt } from '../services/rest-client-extension';
import { Rest } from './rest_client';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class JQueryHTTPClient implements Rest.HttpClient {
   static RegISO8601: any = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-][\d:]+)|Z)?$/i;

   public securityToken: string;

   public static objectWithDateStringToDate(object: any | object | any[] | null): any {
      if (object === null) {
         return object;
      }

      if (Array.isArray(object)) {
         object.forEach((element) => {
            element = this.objectWithDateStringToDate(element);
         });
         return object;
      }

      if (object instanceof Object) {
         const propertyNames = Object.getOwnPropertyNames(object);
         let that = this;
         propertyNames.forEach((element) => {
            if (typeof object[element] === 'string') {
               // eslint-disable-next-line radix
               if (isNaN(parseInt(object[element])) || isNaN(Date.parse(object[element]))) {
                  return;
               }
               if (JQueryHTTPClient.RegISO8601.test(object[element])) {
                  const date = Date.parse(object[element]);
                  object[element] = new Date(date);
               }
               return;
            } else if (typeof object[element] === 'object') {
               object[element] = that.objectWithDateStringToDate(object[element]);
               return;
            }
         });
         return object;
      }

      return object;
   }

   constructor(private router: Router, private http: HttpClient, private notificationsService: NotificationsService, private i18n: I18nService, private translateService: TranslateService) { }

   public urlify(obj) {
      let str = '';
      // eslint-disable-next-line guard-for-in
      for (const key in obj) {
         if (str !== '') {
            str += '&';
         }
         str += key + '=' + encodeURIComponent(obj[key]);
      }
      return str;
   }

   public async request(requestConfig: {
      method: string;
      url: string;
      queryParams?: any;
      data?: any;
      headers?: any;
      cmREST?: boolean;
   }): Promise<any> {
      //TODO: parametrize
      const composedUrl =
         (requestConfig.cmREST !== false ? environment.webApiBaseUrl + requestConfig.url : environment.RTAPIBaseUrl + requestConfig.url) +
         (requestConfig.queryParams ? '?' + this.urlify(requestConfig.queryParams) : '');

      //const heads = new Headers();
      let heads = new HttpHeaders();
      heads = heads.append(
         'content-Type',
         requestConfig.headers && requestConfig.headers['content-Type'] ? requestConfig.headers['content-Type'] : 'application/json',
      );
      if (requestConfig.headers && requestConfig.headers['content-Type']) {
         heads = heads.append('Accept', '*/*');
      }

      //heads.append('token', this.securityToken);
      heads = heads.append('Authorization', 'Bearer ' + this.securityToken);
      //const op = {
      /*let op = {
      method: requestConfig.method,
      url: composedUrl,
      headers: heads,
    } as RequestOptionsArgs;*/

      if (requestConfig.method === 'POST' || requestConfig.method === 'PUT' || requestConfig.method === 'DELETE') {
         //
         //this has to be done since some times, we are expanding the objects with additional properties not belonging to them.
         //If whe make a REST API call with that object, it will fail as unknown properties are provided.
         //TODO: find an automatic way of removing from an object properties not belonging to an interface
         //https://github.com/Microsoft/TypeScript/issues/6515
         //https://stackoverflow.com/questions/31829951/how-to-reduce-javascript-object-to-only-contain-properties-from-interface
         if (requestConfig.data != null) {
            RestExt.removeUnwantedProperties(requestConfig.data);
         }
         heads = heads.append(
            'Conent-Type',
            requestConfig.headers && requestConfig.headers['content-Type'] ? requestConfig.headers['content-Type'] : 'application/json',
         );
         //op.body = JSON.stringify(requestConfig.data);
      }

      return new Promise<void>(async (resolve, reject) => {
         //TODO: improve error handling
         //let e = null;
         //let resp =  this.http.request(composedUrl, op).catch((er:any,c:Observable<Response>)=>{return new ObservableInput<>er;});

         try {
            const resp = this.http.request(requestConfig.method, composedUrl, {
               body: requestConfig.data,
               headers: heads,
            });
            const httpResponse = await resp.toPromise();

            /* <--- FOR TESTING PURPOSES ---> */
            /* TODO: REMOVE AFTER UPDATING ANGULAR TO VERSION 12 */
            /*console.log('HTTPRESPONSE: ');
            console.log(httpResponse);*/

            httpResponse.toString().length !== 0 ? resolve(JQueryHTTPClient.objectWithDateStringToDate(httpResponse)) : resolve();
         } catch (err) {
            /*console.log('ERROR MESSAGE: ' + err.message);
            console.log('ERROR: ' + err.value);*/

            if (err.status === 0 || err.text === '') {
               this.notificationsService.add(Severity.error, this.i18n._('Connection error'), this.i18n._('The server is not responding'));
               reject(err);
               return;
            }
            if (err.status === 401) {
               this.notificationsService.add(Severity.error, this.i18n._('Authentication error'), this.i18n._('You are not authenticated'));
               this.router.navigateByUrl('/login');
               return;
            }
            if (err.status === 400) {
               this.notificationsService.add(Severity.error, this.i18n._('Upss'), this.i18n._('Something went wrong'));
               reject(err.message);
               return;
            }
            if (err.status === 500) {
               console.log('500');
               this.notificationsService.add(Severity.error, this.i18n._('Upss'), this.translateService.instant('error.error.500'));

               //Marçal: he afegit check function per evitar "TypeError: err.text is not a function"
               if (typeof err.text === 'function') {
                  reject(err.text() ? JSON.parse(err.text()) : err);     // TODO: ROBERT TE AFEGIT AIXO
               } else {
                  reject(err.message);
               }
               // reject(err.text() ? JSON.parse(err.text()) : err); // TODO: ROBERT TE AFEGIT AIXO
               return;
            }
            reject({});
         }
      });
   }

   public async requestUploadImage(method: string, url: string, files?: any, requestParam?: any): Promise<{}> {
      let xhr = new XMLHttpRequest();
      let formData = new FormData();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < files.length; i++) {
         //formData.append('myfile[]', files[i], files[i].name);
         formData.append(requestParam, files[i], files[i].name);
      }

      //xhr_1.open('POST', 'http://localhost:4200/api/poiCategory/upload', true);
      //BUG ESTA ATACANT A LA API EN LOCAL
      xhr.open(method, 'http://atl3.atlantisfleet.com:10080/cmapi/api/icon/upload?entity=EVENTS', true);
      //xhr.open('POST','http://localhost:4200/api/icon/upload?entity=EVENT');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.securityToken);
      xhr.withCredentials = true;

      return new Promise(async (resolve, reject) => {
         let that = this;
         xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
               // @ts-ignore
               resolve();
            }
            if (xhr.readyState === 4 && xhr.status !== 200) {
               that.notificationsService.add(Severity.error, that.i18n._('Upss'), that.i18n._('Something went wrong'));
               reject();
            }
         };

         xhr.ontimeout = function () {
            that.notificationsService.add(Severity.error, that.i18n._('Upss'), that.i18n._('Something went wrong'));
            reject();
         };
         xhr.onerror = function () {
            that.notificationsService.add(Severity.error, that.i18n._('Upss'), that.i18n._('Something went wrong'));
            reject();
         };
         xhr.send(formData);
      });
   }

}
