import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { CardConsumptionService } from 'app/modules/vehicle-inventory/services/table-management/cardConsumption.service';
import { Rest } from 'app/rest/rest_client';

@Component({
  selector: 'app-card-consumption-summary',
  templateUrl: './card-consumption-summary.component.html',
  styleUrls: ['./card-consumption-summary.component.css'],
  providers:[CardConsumptionService]
})
export class CardConsumptionSummaryComponent implements OnInit {

  @Input() vehicle: Rest.Vehicle;

  data: Rest.CardConsumptionSummary;
  cardConsumptionService: CardConsumptionService;
  translateService: TranslateService; 
  constructor(
    cardConsumptionService: CardConsumptionService,
    translateService: TranslateService,

) {
    this.cardConsumptionService = cardConsumptionService;
    this.translateService = translateService;
}

  ngOnInit(): void {
    this.cardConsumptionService.summary(this.vehicle.id).then(response => {
      this.data = response;
    });
  }

}
