<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Voltage Virtual Ignition Setting(AT+GTVVS)</span>
            </div>
          </div>
        </p-header>
        <div class="row mt-3">
            <div class="row mt-3">
                <form-input  fieldName="Ignition On Voltage">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="vvsParameters.ignitionOnVoltage" [min]="250" [max]="28000"></p-spinner>
                        <span class="ui-inputgroup-addon">250~28000</span>
                        <span class="ui-inputgroup-addon">mV</span>
                    </div>
                </form-input>
            </div>
            <div class="row mt-3">
                <form-input  fieldName="Voltage Offset">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="vvsParameters.voltageOffset" [min]="200" [max]="2000"></p-spinner>
                        <span class="ui-inputgroup-addon">200~2000</span>
                        <span class="ui-inputgroup-addon">mV</span>
                    </div>
                 </form-input>
            </div>
            <div class="row mt-3">
                <form-input  fieldName="Ignition On Debounce">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="vvsParameters.ignitionOnDebounce" [min]="5" [max]="255"></p-spinner>
                        <span class="ui-inputgroup-addon">5~255</span>
                        <span class="ui-inputgroup-addon">(x2s)</span>
                    </div>
                </form-input>
            </div>
            <div class="row mt-3">
                <form-input  fieldName="Ignition Off Debounce">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="vvsParameters.ignitionOffDebounce" [min]="5" [max]="255"></p-spinner>
                        <span class="ui-inputgroup-addon">5~255</span>
                        <span class="ui-inputgroup-addon">(x2s)</span>
                    </div>
                </form-input>
            </div>

            <div class="row mt-3">
                <form-input  fieldName="Smart Voltage Adjustment" >
                    <div class="ui-inputgroup">
                        <p-dropdown [options]="modeOptions" [(ngModel)]="vvsParameters.mode" [style]="{'width':'340px'}"></p-dropdown>
                     </div>                  
                </form-input>
            </div>
          </div>       
      </p-accordionTab>
