import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-external-battery-protection-configuration-concox-mt200",
  templateUrl:
    "./external-battery-protection-configuration-concox-mt200.component.html",
  styleUrls: [
    "./external-battery-protection-configuration-concox-mt200.component.css",
  ],
})
export class ExternalBatteryProtectionConfigurationConcoxMt200Component
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  @Input() batteryAlarm: Rest.ExternalBatteryProtectionConfigurationConcoxMT200;

  BatteryAlarmOptions: any = [
    { label: "GPRS Only", value: "GPRS" },
    { label: "SMS+GPRS", value: "GPRS_SMS" },
  ];
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.batteryAlarm);
    }
  }

  handleChange($event: any) {}
}
