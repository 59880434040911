import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UsersListComponent } from '../../components/users/users-list/users-list.component';
import { UserFormComponent } from '../../components/users/user-form/user-form.component';
import { DeviceFormComponent } from '../../components/device-edit-view/device-form/device-form.component';
import { FormInput } from '../../components/form-input/form-input.component';
import { ChangeStatusDialogComponent } from '../../components/change-status-dialog/change-status-dialog.component';
import {
    VT10ParametersForm
} from '../../components/device-parameters/vt10-parameters-form/vt10-parameters-form.component';
import {
    SpeedEventConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/speed-event-config-form/speed-event-config-form.component';
import {
    AnalogInputConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/analog-input-config-form/analog-input-config-form.component';
import {
    MilageConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/milage-config-form/milage-config-form.component';
import {
    TrackEventConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/track-event-config-form/track-event-config-form.component';
import {
    RoamingEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/roaming-event-form/roaming-event-form.component';
import {
    PowerSavingConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/power-saving-config-form/power-saving-config-form.component';
import {
    ReportDetectionEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/report-detection-event-form/report-detection-event-form.component';
import {
    AccEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/acc-event-form/acc-event-form.component';
import {
    SosEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/sos-event-form/sos-event-form.component';
import {
    AccOnWithoutTagEventConfigurationComponent
} from '../../components/device-parameters/vt10-parameters-form/acc-on-without-tag-event-configuration/acc-on-without-tag-event-configuration.component';
import {
    RfdetectedEventConfigurationComponent
} from '../../components/device-parameters/vt10-parameters-form/rfdetected-event-configuration/rfdetected-event-configuration.component';
import {
    TagInoutConfigComponent
} from '../../components/device-parameters/vt10-parameters-form/tag-inout-config/tag-inout-config.component';
import {
    TowEventConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/tow-event-config-form/tow-event-config-form.component';
import {
    OutputPatternComponent
} from '../../components/device-parameters/vt10-parameters-form/output-pattern/output-pattern.component';
import {
    GpsAntenaConfFormComponent
} from '../../components/device-parameters/vt10-parameters-form/gps-antena-conf-form/gps-antena-conf-form.component';
import {
    IgnitionEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/ignition-event-form/ignition-event-form.component';
import {
    HarshAccelerationConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/harsh-acceleration-config-form/harsh-acceleration-config-form.component';
import {
    HarshBreakingConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/harsh-breaking-config-form/harsh-breaking-config-form.component';
import {
    EmergencyStopEventFormComponent
} from '../../components/device-parameters/vt10-parameters-form/emergency-stop-event-form/emergency-stop-event-form.component';
import {
    HarshCorneringEventConfigurationComponent
} from '../../components/device-parameters/vt10-parameters-form/harsh-cornering-event-configuration/harsh-cornering-event-configuration.component';
import {
    TimeConfigurationComponent
} from '../../components/device-parameters/vt10-parameters-form/time-configuration/time-configuration.component';
import { SensorListComponent } from '../../components/device-parameters/sensor-list/sensor-list.component';
import { SensorFormComponent } from '../../components/device-parameters/sensor-form/sensor-form.component';
import { ActuatorListComponent } from '../../components/device-parameters/actuator-list/actuator-list.component';
import { ActuatorFormComponent } from '../../components/device-parameters/actuator-form/actuator-form.component';
import {
    LedPatternConfigFormComponent
} from '../../components/device-parameters/vt10-parameters-form/led-pattern-config-form/led-pattern-config-form.component';
import {
    RftagConfigurationComponent
} from '../../components/device-parameters/vt10-parameters-form/rftag-configuration/rftag-configuration.component';
import {
    Gv300ParametersFormComponent
} from '../../components/device-parameters/gv300-parameters-form/gv300-parameters-form.component';
import {
    GlobalParametersFormComponent
} from '../../components/device-parameters/gv300-parameters-form/global-parameters-form/global-parameters-form.component';
import {
    FixedReportInformationFormComponent
} from '../../components/device-parameters/gv300-parameters-form/fixed-report-information-form/fixed-report-information-form.component';
import {
    TowAlarmInformationFormComponent
} from '../../components/device-parameters/gv300-parameters-form/tow-alarm-information-form/tow-alarm-information-form.component';
import {
    SpeedAlarmFormComponent
} from '../../components/device-parameters/gv300-parameters-form/speed-alarm-form/speed-alarm-form.component';
import { SosFormComponent } from '../../components/device-parameters/gv300-parameters-form/sos-form/sos-form.component';
import { HbmFormComponent } from '../../components/device-parameters/gv300-parameters-form/hbm-form/hbm-form.component';
import {
    JammingDetectionInformationFormComponent
} from '../../components/device-parameters/gv300-parameters-form/jamming-detection-information-form/jamming-detection-information-form.component';
import {
    StartStopReportInformationFormComponent
} from '../../components/device-parameters/gv300-parameters-form/start-stop-report-information-form/start-stop-report-information-form.component';
import {
    JammingBehaviorSettingFormComponent
} from '../../components/device-parameters/gv300-parameters-form/jamming-behavior-setting-form/jamming-behavior-setting-form.component';
import {
    AnalogInputPortSettingComponent
} from '../../components/device-parameters/gv300-parameters-form/analog-input-port-setting/analog-input-port-setting.component';
import {
    DigitalInputPortSettingComponent
} from '../../components/device-parameters/gv300-parameters-form/digital-input-port-setting/digital-input-port-setting.component';
import {
    CanbusDeviceConfigurationComponent
} from '../../components/device-parameters/gv300-parameters-form/canbus-device-configuration/canbus-device-configuration.component';
import {
    AuthenticationIdConfigurationComponent
} from '../../components/device-parameters/gv300-parameters-form/authentication-id-configuration/authentication-id-configuration.component';
import {
    GMT200ParametersFormComponent
} from '../../components/device-parameters/gmt200-parameters-form/gmt200-parameters-form.component';
import {
    BearerSettingInformationGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/bearer-setting-information-gmt200/bearer-setting-information-gmt200.component';
import {
    ServerRegistrationInformationGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/server-registration-information-gmt200/server-registration-information-gmt200.component';
import {
    GlobalParametersGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/global-parameters-gmt200-form/global-parameters-gmt200-form.component';
import {
    ProtocolWatchdogGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/protocol-watchdog-gmt200/protocol-watchdog-gmt200.component';
import {
    PreserveDeviceLogicStatesGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/preserve-device-logic-states-gmt200/preserve-device-logic-states-gmt200.component';
import {
    FixedReportInformationGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/fixed-report-information-gmt200-form/fixed-report-information-gmt200-form.component';
import {
    FrequencyChangeFriGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/frequency-change-fri-gmt200/frequency-change-fri-gmt200.component';
import {
    GeoFenceConfigurationGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/geo-fence-configuration-gmt200/geo-fence-configuration-gmt200.component';
import {
    TowAlarmInformationGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/tow-alarm-information-gmt200-form/tow-alarm-information-gmt200-form.component';
import {
    SpeedAlarmGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/speed-alarm-gmt200-form/speed-alarm-gmt200-form.component';
import {
    SosGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/sos-gmt200-form/sos-gmt200-form.component';
import {
    ExcessiveIdlingDetectionGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/excessive-idling-detection-gmt200/excessive-idling-detection-gmt200.component';
import {
    HbmGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/hbm-gmt200-form/hbm-gmt200-form.component';
import {
    StartStopReportInformationGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/start-stop-report-information-gmt200-form/start-stop-report-information-gmt200-form.component';
import {
    JammingBehaviorSettingGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/jamming-behavior-setting-gmt200-form/jamming-behavior-setting-gmt200-form.component';
import {
    JammingDetectionInformationGmt200FormComponent
} from '../../components/device-parameters/gmt200-parameters-form/jamming-detection-information-gmt200-form/jamming-detection-information-gmt200-form.component';
import {
    CrashDetectionGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/crash-detection-gmt200/crash-detection-gmt200.component';
import {
    AxisCalibrationGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/axis-calibration-gmt200/axis-calibration-gmt200.component';
import {
    DigitalOutputPortSettingGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/digital-output-port-setting-gmt200/digital-output-port-setting-gmt200.component';
import {
    ExternalPowerSupplyConfigurationGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/external-power-supply-configuration-gmt200/external-power-supply-configuration-gmt200.component';
import {
    DigitalInputPortSettingGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/digital-input-port-setting-gmt200/digital-input-port-setting-gmt200.component';
import {
    OutputPortBindingGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/output-port-binding-gmt200/output-port-binding-gmt200.component';
import {
    CommandStorageConfigurationGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/command-storage-configuration-gmt200/command-storage-configuration-gmt200.component';
import {
    UserDefinedFunctionsConfigurationGmt200Component
} from '../../components/device-parameters/gmt200-parameters-form/user-defined-functions-configuration-gmt200/user-defined-functions-configuration-gmt200.component';
import {
    VirtualIgnitionSelectionGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/virtual-ignition-selection-gmt200/virtual-ignition-selection-gmt200.component';
import {
    VoltageVirtualIgnitionGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/voltage-virtual-ignition-gmt200/voltage-virtual-ignition-gmt200.component';
import {
    AccelerometerVirtualIgnitionSettingsGMT200Component
} from '../../components/device-parameters/gmt200-parameters-form/accelerometer-virtual-ignition-settings-gmt200/accelerometer-virtual-ignition-settings-gmt200.component';
import {
    ConcoxMT200ParametersFormComponent
} from '../../components/device-parameters/concox-mt200-parameters-form/concox-mt200-parameters-form.component';
import {
    AlarmMovingConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/alarm-moving-configuration-concox-mt200/alarm-moving-configuration-concox-mt200.component';
import {
    AngleConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/angle-configuration-concox-mt200/angle-configuration-concox-mt200.component';
import {
    ApnconfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/apnconfiguration-concox-mt200/apnconfiguration-concox-mt200.component';
import {
    BatteryAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/battery-alarm-configuration-concox-mt200/battery-alarm-configuration-concox-mt200.component';
import {
    DefenseConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/defense-configuration-concox-mt200/defense-configuration-concox-mt200.component';
import {
    ExternalBatteryAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/external-battery-alarm-configuration-concox-mt200/external-battery-alarm-configuration-concox-mt200.component';
import {
    ExternalBatteryProtectionConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/external-battery-protection-configuration-concox-mt200/external-battery-protection-configuration-concox-mt200.component';
import {
    GmtconfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/gmtconfiguration-concox-mt200/gmtconfiguration-concox-mt200.component';
import {
    GprsblockingAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/gprsblocking-alarm-configuration-concox-mt200/gprsblocking-alarm-configuration-concox-mt200.component';
import {
    GprsconfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/gprsconfiguration-concox-mt200/gprsconfiguration-concox-mt200.component';
import {
    GpsDataSendingIntervalConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/gps-data-sending-interval-configuration-concox-mt200/gps-data-sending-interval-configuration-concox-mt200.component';
import {
    HeartBeatIntervalConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/heart-beat-interval-configuration-concox-mt200/heart-beat-interval-configuration-concox-mt200.component';
import {
    HstconfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/hstconfiguration-concox-mt200/hstconfiguration-concox-mt200.component';
import {
    InstructionPasswordConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/instruction-password-configuration-concox-mt200/instruction-password-configuration-concox-mt200.component';
import {
    MileageConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/mileage-configuration-concox-mt200/mileage-configuration-concox-mt200.component';
import {
    PowerAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/power-alarm-configuration-concox-mt200/power-alarm-configuration-concox-mt200.component';
import {
    RelayConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/relay-configuration-concox-mt200/relay-configuration-concox-mt200.component';
import {
    ReviseInstructionPasswordConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/revise-instruction-password-configuration-concox-mt200/revise-instruction-password-configuration-concox-mt200.component';
import {
    SensorConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/sensor-configuration-concox-mt200/sensor-configuration-concox-mt200.component';
import {
    ServerConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/server-configuration-concox-mt200/server-configuration-concox-mt200.component';
import {
    SosconfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/sosconfiguration-concox-mt200/sosconfiguration-concox-mt200.component';
import {
    StaticDataFilteringConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/static-data-filtering-configuration-concox-mt200/static-data-filtering-configuration-concox-mt200.component';
import {
    VibrationAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/vibration-alarm-configuration-concox-mt200/vibration-alarm-configuration-concox-mt200.component';
import {
    SensorSetConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/sensor-set-configuration-concox-mt200/sensor-set-configuration-concox-mt200.component';
import {
    AlarmCallNumberTimesConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/alarm-call-number-times-configuration-concox-mt200/alarm-call-number-times-configuration-concox-mt200.component';
import {
    AlarmOverSpeedConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/alarm-over-speed-configuration-concox-mt200/alarm-over-speed-configuration-concox-mt200.component';
import {
    AutomaticApnConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/automatic-apn-configuration-concox-mt200/automatic-apn-configuration-concox-mt200.component';
import {
    CenterPhoneNumberConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/center-phone-number-configuration-concox-mt200/center-phone-number-configuration-concox-mt200.component';
import {
    ExternalVoltageUploadConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/external-voltage-upload-configuration-concox-mt200/external-voltage-upload-configuration-concox-mt200.component';
import {
    FenceAlarmConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/fence-alarm-configuration-concox-mt200/fence-alarm-configuration-concox-mt200.component';
import {
    GpsDistanceIntervalConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/gps-distance-interval-configuration-concox-mt200/gps-distance-interval-configuration-concox-mt200.component';
import {
    NetworkLinkCoordinatesConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/network-link-coordinates-configuration-concox-mt200/network-link-coordinates-configuration-concox-mt200.component';
import {
    SensorSensitivityConfigurationConcoxMt200Component
} from '../../components/device-parameters/concox-mt200-parameters-form/sensor-sensitivity-configuration-concox-mt200/sensor-sensitivity-configuration-concox-mt200.component';
import {
    WeTrack2ParametersFormComponent
} from '../../components/device-parameters/we-track2-parameters-form/we-track2-parameters-form.component';
import {
    AlarmMovingConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/alarm-moving-configuration-we-track2/alarm-moving-configuration-we-track2.component';
import {
    AngleConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/angle-configuration-we-track2/angle-configuration-we-track2.component';
import {
    ApnconfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/apnconfiguration-we-track2/apnconfiguration-we-track2.component';
import {
    BatteryAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/battery-alarm-configuration-we-track2/battery-alarm-configuration-we-track2.component';
import {
    DefenseConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/defense-configuration-we-track2/defense-configuration-we-track2.component';
import {
    ExternalBatteryAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/external-battery-alarm-configuration-we-track2/external-battery-alarm-configuration-we-track2.component';
import {
    ExternalBatteryProtectionConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/external-battery-protection-configuration-we-track2/external-battery-protection-configuration-we-track2.component';
import {
    GmtconfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/gmtconfiguration-we-track2/gmtconfiguration-we-track2.component';
import {
    GprsblockingAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/gprsblocking-alarm-configuration-we-track2/gprsblocking-alarm-configuration-we-track2.component';
import {
    GpsdataSendingIntervalConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/gpsdata-sending-interval-configuration-we-track2/gpsdata-sending-interval-configuration-we-track2.component';
import {
    HeartBeatIntervalConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/heart-beat-interval-configuration-we-track2/heart-beat-interval-configuration-we-track2.component';
import {
    HstconfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/hstconfiguration-we-track2/hstconfiguration-we-track2.component';
import {
    MileageConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/mileage-configuration-we-track2/mileage-configuration-we-track2.component';
import {
    PowerAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/power-alarm-configuration-we-track2/power-alarm-configuration-we-track2.component';
import {
    RelayConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/relay-configuration-we-track2/relay-configuration-we-track2.component';
import {
    ServerConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/server-configuration-we-track2/server-configuration-we-track2.component';
import {
    SosconfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/sosconfiguration-we-track2/sosconfiguration-we-track2.component';
import {
    StaticDataFilteringConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/static-data-filtering-configuration-we-track2/static-data-filtering-configuration-we-track2.component';
import {
    VibrationAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/vibration-alarm-configuration-we-track2/vibration-alarm-configuration-we-track2.component';
import {
    SensorSetConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/sensor-set-configuration-we-track2/sensor-set-configuration-we-track2.component';
import {
    AlarmCallNumberTimesConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/alarm-call-number-times-configuration-we-track2/alarm-call-number-times-configuration-we-track2.component';
import {
    AlarmOverSpeedConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/alarm-over-speed-configuration-we-track2/alarm-over-speed-configuration-we-track2.component';
import {
    AutomaticApnconfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/automatic-apnconfiguration-we-track2/automatic-apnconfiguration-we-track2.component';
import {
    CenterPhoneNumberConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/center-phone-number-configuration-we-track2/center-phone-number-configuration-we-track2.component';
import {
    ExternalVoltageUploadConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/external-voltage-upload-configuration-we-track2/external-voltage-upload-configuration-we-track2.component';
import {
    FenceAlarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/fence-alarm-configuration-we-track2/fence-alarm-configuration-we-track2.component';
import {
    GpsdistanceIntervalConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/gpsdistance-interval-configuration-we-track2/gpsdistance-interval-configuration-we-track2.component';
import {
    NetworkLinkCoordinatesConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/network-link-coordinates-configuration-we-track2/network-link-coordinates-configuration-we-track2.component';
import {
    SensorSensitivityConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/sensor-sensitivity-configuration-we-track2/sensor-sensitivity-configuration-we-track2.component';
import {
    AccalarmConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/accalarm-configuration-we-track2/accalarm-configuration-we-track2.component';
import {
    LbsuploadConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/lbsupload-configuration-we-track2/lbsupload-configuration-we-track2.component';
import {
    LocationDataUploadConfigurationWeTrack2Component
} from '../../components/device-parameters/we-track2-parameters-form/location-data-upload-configuration-we-track2/location-data-upload-configuration-we-track2.component';
import {
    Gv300canParametersFormComponent
} from '../../components/device-parameters/gv300can-parameters-form/gv300can-parameters-form.component';
import {
    GlobalParametersFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/global-parameters-form-gv300can/global-parameters-form-gv300can.component';
import {
    FixedReportInformationFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/fixed-report-information-form-gv300can/fixed-report-information-form-gv300can.component';
import {
    TowAlarmInformationFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/tow-alarm-information-form-gv300can/tow-alarm-information-form-gv300can.component';
import {
    SpeedAlarmFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/speed-alarm-form-gv300can/speed-alarm-form-gv300can.component';
import {
    SosFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/sos-form-gv300can/sos-form-gv300can.component';
import {
    HbmFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/hbm-form-gv300can/hbm-form-gv300can.component';
import {
    JammingDetectionInformationFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/jamming-detection-information-form-gv300can/jamming-detection-information-form-gv300can.component';
import {
    StartStopReportInformationFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/start-stop-report-information-form-gv300can/start-stop-report-information-form-gv300can.component';
import {
    JammingBehaviorSettingFormGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/jamming-behavior-setting-form-gv300can/jamming-behavior-setting-form-gv300can.component';
import {
    AnalogInputPortSettingGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/analog-input-port-setting-gv300can/analog-input-port-setting-gv300can.component';
import {
    DigitalInputPortSettingGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/digital-input-port-setting-gv300can/digital-input-port-setting-gv300can.component';
import {
    CanbusDeviceConfigurationGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/canbus-device-configuration-gv300can/canbus-device-configuration-gv300can.component';
import {
    AuthenticationIdConfigurationGv300canComponent
} from '../../components/device-parameters/gv300can-parameters-form/authentication-id-configuration-gv300can/authentication-id-configuration-gv300can.component';
import {
    Gv800ParametersFormComponent
} from '../../components/device-parameters/gv800-parameters-form/gv800-parameters-form.component';
import {
    GlobalParametersFormGv800Component
} from '../../components/device-parameters/gv800-parameters-form/global-parameters-form-gv800/global-parameters-form-gv800.component';
import {
    FixedReportInformationFormGv800Component
} from '../../components/device-parameters/gv800-parameters-form/fixed-report-information-form-gv800/fixed-report-information-form-gv800.component';
import {
    TowAlarmInformationGv800Component
} from '../../components/device-parameters/gv800-parameters-form/tow-alarm-information-gv800/tow-alarm-information-gv800.component';
import {
    SpeedAlarmFormGv800Component
} from '../../components/device-parameters/gv800-parameters-form/speed-alarm-form-gv800/speed-alarm-form-gv800.component';
import {
    SosFormGv800Component
} from '../../components/device-parameters/gv800-parameters-form/sos-form-gv800/sos-form-gv800.component';
import {
    HbmFormGv800Component
} from '../../components/device-parameters/gv800-parameters-form/hbm-form-gv800/hbm-form-gv800.component';
import {
    AnalogInputPortSettingGv800Component
} from '../../components/device-parameters/gv800-parameters-form/analog-input-port-setting-gv800/analog-input-port-setting-gv800.component';
import {
    DigitalInputPortSettingGv800Component
} from '../../components/device-parameters/gv800-parameters-form/digital-input-port-setting-gv800/digital-input-port-setting-gv800.component';
import {
    CanbusDeviceConfigurationGv800Component
} from '../../components/device-parameters/gv800-parameters-form/canbus-device-configuration-gv800/canbus-device-configuration-gv800.component';
import {
    AuthenticationIdConfigurationGv800Component
} from '../../components/device-parameters/gv800-parameters-form/authentication-id-configuration-gv800/authentication-id-configuration-gv800.component';
import {
    X8ParametersFormComponent
} from '../../components/device-parameters/x8-parameters-form/x8-parameters-form.component';
import {
    AnalogInputConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/analog-input-configuration-form-x8/analog-input-configuration-form-x8.component';
import {
    BackupBatteryControlFormX8Component
} from '../../components/device-parameters/x8-parameters-form/backup-battery-control-form-x8/backup-battery-control-form-x8.component';
import {
    IdleEventConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/idle-event-configuration-form-x8/idle-event-configuration-form-x8.component';
import {
    InputDelayTimeConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/input-delay-time-configuration-form-x8/input-delay-time-configuration-form-x8.component';
import {
    MileageConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/mileage-configuration-form-x8/mileage-configuration-form-x8.component';
import {
    PowerSavingFormX8Component
} from '../../components/device-parameters/x8-parameters-form/power-saving-form-x8/power-saving-form-x8.component';
import {
    SpeedEventConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/speed-event-configuration-form-x8/speed-event-configuration-form-x8.component';
import {
    TimerReportConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/timer-report-configuration-form-x8/timer-report-configuration-form-x8.component';
import {
    TrackEventConfigurationFormX8Component
} from '../../components/device-parameters/x8-parameters-form/track-event-configuration-form-x8/track-event-configuration-form-x8.component';
import {
    Vt200ParametersFormComponent
} from '../../components/device-parameters/vt200-parameters-form/vt200-parameters-form.component';
import {
    AccEventFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/acc-event-form-vt200/acc-event-form-vt200.component';
import {
    AnalogInputConfigFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/analog-input-config-form-vt200/analog-input-config-form-vt200.component';
import {
    PowerSavingConfigFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/power-saving-config-form-vt200/power-saving-config-form-vt200.component';
import {
    ReportDetectionEventFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/report-detection-event-form-vt200/report-detection-event-form-vt200.component';
import {
    SpeedEventConfigFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/speed-event-config-form-vt200/speed-event-config-form-vt200.component';
import {
    TowEventConfigFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/tow-event-config-form-vt200/tow-event-config-form-vt200.component';
import {
    TrackEventConfigFormVt200Component
} from '../../components/device-parameters/vt200-parameters-form/track-event-config-form-vt200/track-event-config-form-vt200.component';
import {
    EvixParametersFormComponent
} from '../../components/device-parameters/evix-parameters-form/evix-parameters-form.component';
import { IofrogParametersFormComponent } from '../../components/device-parameters/iofrog-parameters-form/iofrog-parameters-form.component';
import { TeltonikaParametersFormComponent } from '../../components/device-parameters/teltonika-parameters-form/teltonika-parameters-form.component';
import { UserTimePipe } from '../../components/shared/UserTimePipe';
import { DistanceFormatPipe } from '../../components/shared/DistanceFormatPipe';
import { TerminalsListComponent } from '../../components/users/terminals-list/terminals-list.component';
import { TimeZoneSelectorComponent } from '../../components/shared/time-zone-selector/time-zone-selector.component';
import { Ob22ParametersFormComponent } from '../../components/device-parameters/ob22-parameters-form/ob22-parameters-form.component';
import { AngleConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/angle-configuration-ob22/angle-configuration-ob22.component';
import { ApnConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/apn-configuration-ob22/apn-configuration-ob22.component';
import { BatteryAlarmConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/battery-alarm-configuration-ob22/battery-alarm-configuration-ob22.component';
import { GMTConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/gmtconfiguration-ob22/gmtconfiguration-ob22.component';
import { GPRSBlockingAlarmConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/gprsblocking-alarm-configuration-ob22/gprsblocking-alarm-configuration-ob22.component';
import { HeartbeatIntervalConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/heartbeat-interval-configuration-ob22/heartbeat-interval-configuration-ob22.component';
import { MileageConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/mileage-configuration-ob22/mileage-configuration-ob22.component';
import { InstructionPasswordConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/instruction-password-configuration-ob22/instruction-password-configuration-ob22.component';
import { PowerAlarmConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/power-alarm-configuration-ob22/power-alarm-configuration-ob22.component';
import { ServerConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/server-configuration-ob22/server-configuration-ob22.component';
import { SOSConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/sosconfiguration-ob22/sosconfiguration-ob22.component';
import { StaticDataFilteringConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/static-data-filtering-configuration-ob22/static-data-filtering-configuration-ob22.component';
import { ReviseInstructionPasswordConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/revise-instruction-password-configuration-ob22/revise-instruction-password-configuration-ob22.component';
import { GPSDataSendingIntervalConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/gpsdata-sending-interval-configuration-ob22/gpsdata-sending-interval-configuration-ob22.component';
import { FenceAlarmConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/fence-alarm-configuration-ob22/fence-alarm-configuration-ob22.component';
import { AlarmCallNumberTimesConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/alarm-call-number-times-configuration-ob22/alarm-call-number-times-configuration-ob22.component';
import { AlarmOverSpeedConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/alarm-over-speed-configuration-ob22/alarm-over-speed-configuration-ob22.component';
import { SensorSensitivityConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/sensor-sensitivity-configuration-ob22/sensor-sensitivity-configuration-ob22.component';
import { AutomaticGMTConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/automatic-gmtconfiguration-ob22/automatic-gmtconfiguration-ob22.component';
import { UploadACCConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/upload-accconfiguration-ob22/upload-accconfiguration-ob22.component';
import { AutomaticAPNConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/automatic-apnconfiguration-ob22/automatic-apnconfiguration-ob22.component';
import { NetworkLinkCoordinatesConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/network-link-coordinates-configuration-ob22/network-link-coordinates-configuration-ob22.component';
import { GPSDistanceIntervalConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/gpsdistance-interval-configuration-ob22/gpsdistance-interval-configuration-ob22.component';
import { BatchConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/batch-configuration-ob22/batch-configuration-ob22.component';
import { SendsConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/sends-configuration-ob22/sends-configuration-ob22.component';
import { DelayVoiceMonitorConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/delay-voice-monitor-configuration-ob22/delay-voice-monitor-configuration-ob22.component';
import { StaticLastPositionConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/static-last-position-configuration-ob22/static-last-position-configuration-ob22.component';
import { SMSForwardingConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/smsforwarding-configuration-ob22/smsforwarding-configuration-ob22.component';
import { LBSUploadConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/lbsupload-configuration-ob22/lbsupload-configuration-ob22.component';
import { PullAlarmConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/pull-alarm-configuration-ob22/pull-alarm-configuration-ob22.component';
import { GPRSConfigurationOB22Component } from '../../components/device-parameters/ob22-parameters-form/gprsconfiguration-ob22/gprsconfiguration-ob22.component';
import { Gl320mParametersFormComponent } from '../../components/device-parameters/gl320m-parameters-form/gl320m-parameters-form.component';
import { BearerSettingInformationGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/bearer-setting-information-gl320m/bearer-setting-information-gl320m.component';
import { ServerRegistrationInformationGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/server-registration-information-gl320m/server-registration-information-gl320m.component';
import { QuickStartSettingsGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/quick-start-settings-gl320m/quick-start-settings-gl320m.component';
import { GlobalParametersGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/global-parameters-gl320m/global-parameters-gl320m.component';
import { UnlockPinGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/unlock-pin-gl320m/unlock-pin-gl320m.component';
import { ProtocolWatchdogGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/protocol-watchdog-gl320m/protocol-watchdog-gl320m.component';
import { TimeAdjustmentGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/time-adjustment-gl320m/time-adjustment-gl320m.component';
import { NonMovementDetectionGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/non-movement-detection-gl320m/non-movement-detection-gl320m.component';
import { FunctionKeySettingsGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/function-key-settings-gl320m/function-key-settings-gl320m.component';
import { NetworkSelectionGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/network-selection-gl320m/network-selection-gl320m.component';
import { OutsideWorkingHoursGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/outside-working-hours-gl320m/outside-working-hours-gl320m.component';
import { BindingSimCardGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/binding-sim-card-gl320m/binding-sim-card-gl320m.component';
import { SerialPortSettingsGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/serial-port-settings-gl320m/serial-port-settings-gl320m.component';
import { FixedReportInformationGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/fixed-report-information-gl320m/fixed-report-information-gl320m.component';
import { GeoFenceParametersGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/geo-fence-parameters-gl320m/geo-fence-parameters-gl320m.component';
import { SpeedAlarmParametersGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/speed-alarm-parameters-gl320m/speed-alarm-parameters-gl320m.component';
import { TemperatureAlarmGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/temperature-alarm-gl320m/temperature-alarm-gl320m.component';
import { MotionSensorGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/motion-sensor-gl320m/motion-sensor-gl320m.component';
import { DigitalInputPortSettingsGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/digital-input-port-settings-gl320m/digital-input-port-settings-gl320m.component';
import { PreservingDeviceLogicStatesGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/preserving-device-logic-states-gl320m/preserving-device-logic-states-gl320m.component';
import { GpsAssistedMotionMeasurementGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/gps-assisted-motion-measurement-gl320m/gps-assisted-motion-measurement-gl320m.component';
import { CommandStorageGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/command-storage-gl320m/command-storage-gl320m.component';
import { UserDefinedFunctionGl320mComponent } from '../../components/device-parameters/gl320m-parameters-form/user-defined-function-gl320m/user-defined-function-gl320m.component';
import { AdditionalConfigurationGL320MComponent } from '../../components/device-parameters/gl320m-parameters-form/additional-configuration-gl320m/additional-configuration-gl320m.component';
import { VehicleInventoryModule } from '../vehicle-inventory/vehicle-inventory.module';
import { SmartCoffeeParametersFormComponent } from '../../components/device-parameters/smart-coffee-parameters-form/smart-coffee-parameters-form.component';

@NgModule({
    declarations: [
        UsersListComponent,
        UserFormComponent,
        DeviceFormComponent,
      //   FormInput,
        ChangeStatusDialogComponent,
        UserTimePipe,
        DistanceFormatPipe,
        TerminalsListComponent,
        TimeZoneSelectorComponent,
        /* <--- VT10 ---> */
        VT10ParametersForm,
        SpeedEventConfigFormComponent,
        AnalogInputConfigFormComponent,
        MilageConfigFormComponent,
        TrackEventConfigFormComponent,
        RoamingEventFormComponent,
        PowerSavingConfigFormComponent,
        ReportDetectionEventFormComponent,
        AccEventFormComponent,
        SosEventFormComponent,
        AccOnWithoutTagEventConfigurationComponent,
        RfdetectedEventConfigurationComponent,
        TagInoutConfigComponent,
        TowEventConfigFormComponent,
        OutputPatternComponent,
        GpsAntenaConfFormComponent,
        IgnitionEventFormComponent,
        HarshAccelerationConfigFormComponent,
        HarshBreakingConfigFormComponent,
        HarshCorneringEventConfigurationComponent,
        EmergencyStopEventFormComponent,
        TimeConfigurationComponent,
        SensorListComponent,
        SensorFormComponent,
        ActuatorListComponent,
        ActuatorFormComponent,
        LedPatternConfigFormComponent,
        RftagConfigurationComponent,

        /* <--- GV300 ---> */
        Gv300ParametersFormComponent,
        GlobalParametersFormComponent,
        FixedReportInformationFormComponent,
        TowAlarmInformationFormComponent,
        SpeedAlarmFormComponent,
        SosFormComponent,
        HbmFormComponent,
        JammingDetectionInformationFormComponent,
        JammingBehaviorSettingFormComponent,
        StartStopReportInformationFormComponent,
        AnalogInputPortSettingComponent,
        DigitalInputPortSettingComponent,
        CanbusDeviceConfigurationComponent,
        AuthenticationIdConfigurationComponent,

        /* <--- GMT200 ---> */
        GMT200ParametersFormComponent,
        BearerSettingInformationGMT200Component,
        ServerRegistrationInformationGmt200Component,
        GlobalParametersGmt200FormComponent,
        ProtocolWatchdogGMT200Component,
        PreserveDeviceLogicStatesGMT200Component,
        FixedReportInformationGmt200FormComponent,
        FrequencyChangeFriGMT200Component,
        GeoFenceConfigurationGmt200Component,
        TowAlarmInformationGmt200FormComponent,
        SpeedAlarmGmt200FormComponent,
        SosGmt200FormComponent,
        ExcessiveIdlingDetectionGMT200Component,
        HbmGmt200FormComponent,
        StartStopReportInformationGmt200FormComponent,
        JammingBehaviorSettingGmt200FormComponent,
        JammingDetectionInformationGmt200FormComponent,
        CrashDetectionGMT200Component,
        AxisCalibrationGMT200Component,
        DigitalOutputPortSettingGmt200Component,
        ExternalPowerSupplyConfigurationGmt200Component,
        DigitalInputPortSettingGmt200Component,
        OutputPortBindingGMT200Component,
        CommandStorageConfigurationGmt200Component,
        UserDefinedFunctionsConfigurationGmt200Component,
        VirtualIgnitionSelectionGMT200Component,
        VoltageVirtualIgnitionGMT200Component,
        AccelerometerVirtualIgnitionSettingsGMT200Component,

        /* <--- Concox MT200 ---> */
        ConcoxMT200ParametersFormComponent,
        AlarmMovingConfigurationConcoxMt200Component,
        AngleConfigurationConcoxMt200Component,
        ApnconfigurationConcoxMt200Component,
        BatteryAlarmConfigurationConcoxMt200Component,
        DefenseConfigurationConcoxMt200Component,
        ExternalBatteryAlarmConfigurationConcoxMt200Component,
        ExternalBatteryProtectionConfigurationConcoxMt200Component,
        GmtconfigurationConcoxMt200Component,
        GprsblockingAlarmConfigurationConcoxMt200Component,
        GprsconfigurationConcoxMt200Component,
        GpsDataSendingIntervalConfigurationConcoxMt200Component,
        HeartBeatIntervalConfigurationConcoxMt200Component,
        HstconfigurationConcoxMt200Component,
        InstructionPasswordConfigurationConcoxMt200Component,
        MileageConfigurationConcoxMt200Component,
        PowerAlarmConfigurationConcoxMt200Component,
        RelayConfigurationConcoxMt200Component,
        ReviseInstructionPasswordConfigurationConcoxMt200Component,
        SensorConfigurationConcoxMt200Component,
        ServerConfigurationConcoxMt200Component,
        SosconfigurationConcoxMt200Component,
        StaticDataFilteringConfigurationConcoxMt200Component,
        VibrationAlarmConfigurationConcoxMt200Component,
        SensorSetConfigurationConcoxMt200Component,
        AlarmCallNumberTimesConfigurationConcoxMt200Component,
        AlarmOverSpeedConfigurationConcoxMt200Component,
        AutomaticApnConfigurationConcoxMt200Component,
        CenterPhoneNumberConfigurationConcoxMt200Component,
        ExternalVoltageUploadConfigurationConcoxMt200Component,
        FenceAlarmConfigurationConcoxMt200Component,
        GpsDistanceIntervalConfigurationConcoxMt200Component,
        NetworkLinkCoordinatesConfigurationConcoxMt200Component,
        SensorSensitivityConfigurationConcoxMt200Component,

        /* <--- WeTrack2 ---> */
        WeTrack2ParametersFormComponent,
        AlarmMovingConfigurationWeTrack2Component,
        AngleConfigurationWeTrack2Component,
        ApnconfigurationWeTrack2Component,
        BatteryAlarmConfigurationWeTrack2Component,
        DefenseConfigurationWeTrack2Component,
        ExternalBatteryAlarmConfigurationWeTrack2Component,
        ExternalBatteryProtectionConfigurationWeTrack2Component,
        GmtconfigurationWeTrack2Component,
        GprsblockingAlarmConfigurationWeTrack2Component,
        GpsdataSendingIntervalConfigurationWeTrack2Component,
        HeartBeatIntervalConfigurationWeTrack2Component,
        HstconfigurationWeTrack2Component,
        MileageConfigurationWeTrack2Component,
        PowerAlarmConfigurationWeTrack2Component,
        RelayConfigurationWeTrack2Component,
        ServerConfigurationWeTrack2Component,
        SosconfigurationWeTrack2Component,
        StaticDataFilteringConfigurationWeTrack2Component,
        VibrationAlarmConfigurationWeTrack2Component,
        SensorSetConfigurationWeTrack2Component,
        AlarmCallNumberTimesConfigurationWeTrack2Component,
        AlarmOverSpeedConfigurationWeTrack2Component,
        AutomaticApnconfigurationWeTrack2Component,
        CenterPhoneNumberConfigurationWeTrack2Component,
        ExternalVoltageUploadConfigurationWeTrack2Component,
        FenceAlarmConfigurationWeTrack2Component,
        GpsdistanceIntervalConfigurationWeTrack2Component,
        NetworkLinkCoordinatesConfigurationWeTrack2Component,
        SensorSensitivityConfigurationWeTrack2Component,
        AccalarmConfigurationWeTrack2Component,
        LbsuploadConfigurationWeTrack2Component,
        LocationDataUploadConfigurationWeTrack2Component,

        /* <--- GV300CAN ---> */
        Gv300canParametersFormComponent,
        GlobalParametersFormGv300canComponent,
        FixedReportInformationFormGv300canComponent,
        TowAlarmInformationFormGv300canComponent,
        SpeedAlarmFormGv300canComponent,
        SosFormGv300canComponent,
        HbmFormGv300canComponent,
        JammingDetectionInformationFormGv300canComponent,
        StartStopReportInformationFormGv300canComponent,
        JammingBehaviorSettingFormGv300canComponent,
        AnalogInputPortSettingGv300canComponent,
        DigitalInputPortSettingGv300canComponent,
        CanbusDeviceConfigurationGv300canComponent,
        AuthenticationIdConfigurationGv300canComponent,

        /* <--- GV800 ---> */
        Gv800ParametersFormComponent,
        GlobalParametersFormGv800Component,
        FixedReportInformationFormGv800Component,
        TowAlarmInformationGv800Component,
        SpeedAlarmFormGv800Component,
        SosFormGv800Component,
        HbmFormGv800Component,
        AnalogInputPortSettingGv800Component,
        DigitalInputPortSettingGv800Component,
        CanbusDeviceConfigurationGv800Component,
        AuthenticationIdConfigurationGv800Component,

        /* <--- X8 ---> */
        X8ParametersFormComponent,
        AnalogInputConfigurationFormX8Component,
        BackupBatteryControlFormX8Component,
        IdleEventConfigurationFormX8Component,
        InputDelayTimeConfigurationFormX8Component,
        MileageConfigurationFormX8Component,
        PowerSavingFormX8Component,
        SpeedEventConfigurationFormX8Component,
        TimerReportConfigurationFormX8Component,
        TrackEventConfigurationFormX8Component,

        /* <--- VT200 ---> */
        Vt200ParametersFormComponent,
        AccEventFormVt200Component,
        AnalogInputConfigFormVt200Component,
        PowerSavingConfigFormVt200Component,
        ReportDetectionEventFormVt200Component,
        SpeedEventConfigFormVt200Component,
        TowEventConfigFormVt200Component,
        TrackEventConfigFormVt200Component,

        /* <--- EVIX ---> */
        EvixParametersFormComponent,

        /* <--- Iofrog ---> */
        IofrogParametersFormComponent,

        /* <--- Telktonika ---> */
        TeltonikaParametersFormComponent,

        /* <--- OB22 ---> */
        Ob22ParametersFormComponent,
        AngleConfigurationOB22Component,
        ApnConfigurationOB22Component,
        BatteryAlarmConfigurationOB22Component,
        GMTConfigurationOB22Component,
        GPRSBlockingAlarmConfigurationOB22Component,
        HeartbeatIntervalConfigurationOB22Component,
        MileageConfigurationOB22Component,
        InstructionPasswordConfigurationOB22Component,
        PowerAlarmConfigurationOB22Component,
        ServerConfigurationOB22Component,
        SOSConfigurationOB22Component,
        StaticDataFilteringConfigurationOB22Component,
        ReviseInstructionPasswordConfigurationOB22Component,
        GPSDataSendingIntervalConfigurationOB22Component,
        FenceAlarmConfigurationOB22Component,
        AlarmCallNumberTimesConfigurationOB22Component,
        AlarmOverSpeedConfigurationOB22Component,
        SensorSensitivityConfigurationOB22Component,
        AutomaticGMTConfigurationOB22Component,
        UploadACCConfigurationOB22Component,
        AutomaticAPNConfigurationOB22Component,
        NetworkLinkCoordinatesConfigurationOB22Component,
        GPSDistanceIntervalConfigurationOB22Component,
        BatchConfigurationOB22Component,
        SendsConfigurationOB22Component,
        DelayVoiceMonitorConfigurationOB22Component,
        StaticLastPositionConfigurationOB22Component,
        SMSForwardingConfigurationOB22Component,
        LBSUploadConfigurationOB22Component,
        PullAlarmConfigurationOB22Component,
        GPRSConfigurationOB22Component,

        /* GL320M */
        Gl320mParametersFormComponent,
        BearerSettingInformationGl320mComponent,
        ServerRegistrationInformationGl320mComponent,
        QuickStartSettingsGl320mComponent,
        GlobalParametersGl320mComponent,
        UnlockPinGl320mComponent,
        ProtocolWatchdogGl320mComponent,
        TimeAdjustmentGl320mComponent,
        NonMovementDetectionGl320mComponent,
        FunctionKeySettingsGl320mComponent,
        NetworkSelectionGl320mComponent,
        OutsideWorkingHoursGl320mComponent,
        BindingSimCardGl320mComponent,
        SerialPortSettingsGl320mComponent,
        FixedReportInformationGl320mComponent,
        GeoFenceParametersGl320mComponent,
        SpeedAlarmParametersGl320mComponent,
        TemperatureAlarmGl320mComponent,
        MotionSensorGl320mComponent,
        DigitalInputPortSettingsGl320mComponent,
        PreservingDeviceLogicStatesGl320mComponent,
        GpsAssistedMotionMeasurementGl320mComponent,
        CommandStorageGl320mComponent,
        UserDefinedFunctionGl320mComponent,
        AdditionalConfigurationGL320MComponent,

        /* <--- Smart Coffee ---> */
        SmartCoffeeParametersFormComponent,
    ],

    exports: [
        UsersListComponent,
        UserFormComponent,
        DeviceFormComponent,
        //FormInput,
        ChangeStatusDialogComponent,
        UserTimePipe,
        DistanceFormatPipe,
        TerminalsListComponent,
        TimeZoneSelectorComponent,

        /* <--- VT10 ---> */
        VT10ParametersForm,
        SpeedEventConfigFormComponent,
        AnalogInputConfigFormComponent,
        MilageConfigFormComponent,
        TrackEventConfigFormComponent,
        RoamingEventFormComponent,
        PowerSavingConfigFormComponent,
        ReportDetectionEventFormComponent,
        AccEventFormComponent,
        SosEventFormComponent,
        AccOnWithoutTagEventConfigurationComponent,
        RfdetectedEventConfigurationComponent,
        TagInoutConfigComponent,
        TowEventConfigFormComponent,
        OutputPatternComponent,
        GpsAntenaConfFormComponent,
        IgnitionEventFormComponent,
        HarshAccelerationConfigFormComponent,
        HarshBreakingConfigFormComponent,
        HarshCorneringEventConfigurationComponent,
        EmergencyStopEventFormComponent,
        TimeConfigurationComponent,
        SensorListComponent,
        SensorFormComponent,
        ActuatorListComponent,
        ActuatorFormComponent,
        LedPatternConfigFormComponent,
        RftagConfigurationComponent,

        /* <--- GV300 ---> */
        Gv300ParametersFormComponent,
        GlobalParametersFormComponent,
        FixedReportInformationFormComponent,
        TowAlarmInformationFormComponent,
        SpeedAlarmFormComponent,
        SosFormComponent,
        HbmFormComponent,
        JammingDetectionInformationFormComponent,
        JammingBehaviorSettingFormComponent,
        StartStopReportInformationFormComponent,
        AnalogInputPortSettingComponent,
        DigitalInputPortSettingComponent,
        CanbusDeviceConfigurationComponent,
        AuthenticationIdConfigurationComponent,

        /* <--- GMT200 ---> */
        GMT200ParametersFormComponent,
        BearerSettingInformationGMT200Component,
        ServerRegistrationInformationGmt200Component,
        GlobalParametersGmt200FormComponent,
        ProtocolWatchdogGMT200Component,
        PreserveDeviceLogicStatesGMT200Component,
        FixedReportInformationGmt200FormComponent,
        FrequencyChangeFriGMT200Component,
        GeoFenceConfigurationGmt200Component,
        TowAlarmInformationGmt200FormComponent,
        SpeedAlarmGmt200FormComponent,
        SosGmt200FormComponent,
        ExcessiveIdlingDetectionGMT200Component,
        HbmGmt200FormComponent,
        StartStopReportInformationGmt200FormComponent,
        JammingBehaviorSettingGmt200FormComponent,
        JammingDetectionInformationGmt200FormComponent,
        CrashDetectionGMT200Component,
        AxisCalibrationGMT200Component,
        DigitalOutputPortSettingGmt200Component,
        ExternalPowerSupplyConfigurationGmt200Component,
        DigitalInputPortSettingGmt200Component,
        OutputPortBindingGMT200Component,
        CommandStorageConfigurationGmt200Component,
        UserDefinedFunctionsConfigurationGmt200Component,
        VirtualIgnitionSelectionGMT200Component,
        VoltageVirtualIgnitionGMT200Component,
        AccelerometerVirtualIgnitionSettingsGMT200Component,

        /* <--- Concox MT200 ---> */
        ConcoxMT200ParametersFormComponent,
        AlarmMovingConfigurationConcoxMt200Component,
        AngleConfigurationConcoxMt200Component,
        ApnconfigurationConcoxMt200Component,
        BatteryAlarmConfigurationConcoxMt200Component,
        DefenseConfigurationConcoxMt200Component,
        ExternalBatteryAlarmConfigurationConcoxMt200Component,
        ExternalBatteryProtectionConfigurationConcoxMt200Component,
        GmtconfigurationConcoxMt200Component,
        GprsblockingAlarmConfigurationConcoxMt200Component,
        GprsconfigurationConcoxMt200Component,
        GpsDataSendingIntervalConfigurationConcoxMt200Component,
        HeartBeatIntervalConfigurationConcoxMt200Component,
        HstconfigurationConcoxMt200Component,
        InstructionPasswordConfigurationConcoxMt200Component,
        MileageConfigurationConcoxMt200Component,
        PowerAlarmConfigurationConcoxMt200Component,
        RelayConfigurationConcoxMt200Component,
        ReviseInstructionPasswordConfigurationConcoxMt200Component,
        SensorConfigurationConcoxMt200Component,
        ServerConfigurationConcoxMt200Component,
        SosconfigurationConcoxMt200Component,
        StaticDataFilteringConfigurationConcoxMt200Component,
        VibrationAlarmConfigurationConcoxMt200Component,
        SensorSetConfigurationConcoxMt200Component,
        AlarmCallNumberTimesConfigurationConcoxMt200Component,
        AlarmOverSpeedConfigurationConcoxMt200Component,
        AutomaticApnConfigurationConcoxMt200Component,
        CenterPhoneNumberConfigurationConcoxMt200Component,
        ExternalVoltageUploadConfigurationConcoxMt200Component,
        FenceAlarmConfigurationConcoxMt200Component,
        GpsDistanceIntervalConfigurationConcoxMt200Component,
        NetworkLinkCoordinatesConfigurationConcoxMt200Component,
        SensorSensitivityConfigurationConcoxMt200Component,

        /* <--- WeTrack2 ---> */
        WeTrack2ParametersFormComponent,
        AlarmMovingConfigurationWeTrack2Component,
        AngleConfigurationWeTrack2Component,
        ApnconfigurationWeTrack2Component,
        BatteryAlarmConfigurationWeTrack2Component,
        DefenseConfigurationWeTrack2Component,
        ExternalBatteryAlarmConfigurationWeTrack2Component,
        ExternalBatteryProtectionConfigurationWeTrack2Component,
        GmtconfigurationWeTrack2Component,
        GprsblockingAlarmConfigurationWeTrack2Component,
        GpsdataSendingIntervalConfigurationWeTrack2Component,
        HeartBeatIntervalConfigurationWeTrack2Component,
        HstconfigurationWeTrack2Component,
        MileageConfigurationWeTrack2Component,
        PowerAlarmConfigurationWeTrack2Component,
        RelayConfigurationWeTrack2Component,
        ServerConfigurationWeTrack2Component,
        SosconfigurationWeTrack2Component,
        StaticDataFilteringConfigurationWeTrack2Component,
        VibrationAlarmConfigurationWeTrack2Component,
        SensorSetConfigurationWeTrack2Component,
        AlarmCallNumberTimesConfigurationWeTrack2Component,
        AlarmOverSpeedConfigurationWeTrack2Component,
        AutomaticApnconfigurationWeTrack2Component,
        CenterPhoneNumberConfigurationWeTrack2Component,
        ExternalVoltageUploadConfigurationWeTrack2Component,
        FenceAlarmConfigurationWeTrack2Component,
        GpsdistanceIntervalConfigurationWeTrack2Component,
        NetworkLinkCoordinatesConfigurationWeTrack2Component,
        SensorSensitivityConfigurationWeTrack2Component,
        AccalarmConfigurationWeTrack2Component,
        LbsuploadConfigurationWeTrack2Component,
        LocationDataUploadConfigurationWeTrack2Component,

        /* <--- GV300CAN ---> */
        Gv300canParametersFormComponent,
        GlobalParametersFormGv300canComponent,
        FixedReportInformationFormGv300canComponent,
        TowAlarmInformationFormGv300canComponent,
        SpeedAlarmFormGv300canComponent,
        SosFormGv300canComponent,
        HbmFormGv300canComponent,
        JammingDetectionInformationFormGv300canComponent,
        StartStopReportInformationFormGv300canComponent,
        JammingBehaviorSettingFormGv300canComponent,
        AnalogInputPortSettingGv300canComponent,
        DigitalInputPortSettingGv300canComponent,
        CanbusDeviceConfigurationGv300canComponent,
        AuthenticationIdConfigurationGv300canComponent,

        /* <--- GV800 ---> */
        Gv800ParametersFormComponent,
        GlobalParametersFormGv800Component,
        FixedReportInformationFormGv800Component,
        TowAlarmInformationGv800Component,
        SpeedAlarmFormGv800Component,
        SosFormGv800Component,
        HbmFormGv800Component,
        AnalogInputPortSettingGv800Component,
        DigitalInputPortSettingGv800Component,
        CanbusDeviceConfigurationGv800Component,
        AuthenticationIdConfigurationGv800Component,

        /* <--- X8 ---> */
        X8ParametersFormComponent,
        AnalogInputConfigurationFormX8Component,
        BackupBatteryControlFormX8Component,
        IdleEventConfigurationFormX8Component,
        InputDelayTimeConfigurationFormX8Component,
        MileageConfigurationFormX8Component,
        PowerSavingFormX8Component,
        SpeedEventConfigurationFormX8Component,
        TimerReportConfigurationFormX8Component,
        TrackEventConfigurationFormX8Component,

        /* <--- VT200 ---> */
        Vt200ParametersFormComponent,
        AccEventFormVt200Component,
        AnalogInputConfigFormVt200Component,
        PowerSavingConfigFormVt200Component,
        ReportDetectionEventFormVt200Component,
        SpeedEventConfigFormVt200Component,
        TowEventConfigFormVt200Component,
        TrackEventConfigFormVt200Component,

        /* <--- EVIX ---> */
        EvixParametersFormComponent,
        /* <--- Iofrog ---> */
        IofrogParametersFormComponent,
        /* <--- Teltonika ---> */
        TeltonikaParametersFormComponent,

        /* <--- OB22 ---> */
        Ob22ParametersFormComponent,
        AngleConfigurationOB22Component,
        ApnConfigurationOB22Component,
        BatteryAlarmConfigurationOB22Component,
        GMTConfigurationOB22Component,
        GPRSBlockingAlarmConfigurationOB22Component,
        HeartbeatIntervalConfigurationOB22Component,
        MileageConfigurationOB22Component,
        InstructionPasswordConfigurationOB22Component,
        PowerAlarmConfigurationOB22Component,
        ServerConfigurationOB22Component,
        SOSConfigurationOB22Component,
        StaticDataFilteringConfigurationOB22Component,
        ReviseInstructionPasswordConfigurationOB22Component,
        GPSDataSendingIntervalConfigurationOB22Component,
        FenceAlarmConfigurationOB22Component,
        AlarmCallNumberTimesConfigurationOB22Component,
        AlarmOverSpeedConfigurationOB22Component,
        SensorSensitivityConfigurationOB22Component,
        AutomaticGMTConfigurationOB22Component,
        UploadACCConfigurationOB22Component,
        AutomaticAPNConfigurationOB22Component,
        NetworkLinkCoordinatesConfigurationOB22Component,
        GPSDistanceIntervalConfigurationOB22Component,
        BatchConfigurationOB22Component,
        SendsConfigurationOB22Component,
        DelayVoiceMonitorConfigurationOB22Component,
        StaticLastPositionConfigurationOB22Component,
        SMSForwardingConfigurationOB22Component,
        LBSUploadConfigurationOB22Component,
        PullAlarmConfigurationOB22Component,
        GPRSConfigurationOB22Component,

        /* GL320M */
        Gl320mParametersFormComponent,
        BearerSettingInformationGl320mComponent,
        ServerRegistrationInformationGl320mComponent,
        QuickStartSettingsGl320mComponent,
        GlobalParametersGl320mComponent,
        UnlockPinGl320mComponent,
        ProtocolWatchdogGl320mComponent,
        TimeAdjustmentGl320mComponent,
        NonMovementDetectionGl320mComponent,
        FunctionKeySettingsGl320mComponent,
        NetworkSelectionGl320mComponent,
        OutsideWorkingHoursGl320mComponent,
        BindingSimCardGl320mComponent,
        SerialPortSettingsGl320mComponent,
        FixedReportInformationGl320mComponent,
        GeoFenceParametersGl320mComponent,
        SpeedAlarmParametersGl320mComponent,
        TemperatureAlarmGl320mComponent,
        MotionSensorGl320mComponent,
        DigitalInputPortSettingsGl320mComponent,
        PreservingDeviceLogicStatesGl320mComponent,
        GpsAssistedMotionMeasurementGl320mComponent,
        CommandStorageGl320mComponent,
        UserDefinedFunctionGl320mComponent,

        /* <--- Smart Coffee ---> */
        SmartCoffeeParametersFormComponent,
    ],

    imports: [
        SharedModule,
    ]
})
export class TempExportComponentsModule {
}
