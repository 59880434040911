import { Component, OnInit, ViewEncapsulation, ViewChild, SimpleChanges } from '@angular/core';
import { EntityListComponent } from '../../components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { ConfirmationService, LazyLoadEvent, SelectItem, FilterMetadata } from 'primeng/api'
import { UserTimePipe } from '../../components/shared/UserTimePipe';
import { ReportFilesService } from './services/report-files.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { BillingaccountService} from 'app/rest/billingaccount.service';
import { saveAs } from 'file-saver';
import { RestExt } from 'app/services/rest-client-extension';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
  providers: [ReportFilesService, BillingaccountService]
})
export class TaxComponent extends EntityListComponent<Rest.ReportFiles> {

  startDate: Date;
  endDate: Date;
  entityType: Rest.ReportFileEntityType;
  entityId: number | null;
  availableEntities: SelectItem[];
  selectedEntity: any;
  entityRequestContext: Rest.ListPaginationRequest;

  ngOnInit(){
    super.ngOnInit();
    this.entityRequestContext =  RestExt.firstPageRequest();
    this.entityRequestContext.filters = {clientID:[this.authenticationService.user.client.id.toString()]}

    this.billingAccountService.getPage(this.entityRequestContext).then(
      (pageResult) => {
        // Assuming the first result is at index 0
        if (pageResult.entities.length > 0) {
          this.selectedEntity = pageResult.entities[0];
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }



  constructor(
    protected reportFilesService: ReportFilesService,
    public billingAccountService: BillingaccountService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    private translate: TranslateService,
    private datePipe: DatePipe
) {
    super(
        reportFilesService,
        confirmationService,
        notificationsService,
        i18n,
        authenticationService,
        loadingService,
        userTime
    );
}

EntityOptions: any = [
  {label: this.translate.instant('general.device'),value:"VEHICLE"},
  {label: this.translate.instant('general.subgroup'),value:"GROUP"},
  {label: this.translate.instant('general.group'), value:"FLEET"}
];


  search(){
    this.selectedEntities = [];

    if (this.startDate && this.endDate) {

      if(this.endDate >= this.startDate){
        this.endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() + 1, 0);
        this.endDate.setHours(23,59,59,999);

        //convert utc date to local date
        const offsetMinutes = this.startDate.getTimezoneOffset();

        this.reportFilesService.startDate = new Date(this.startDate.getTime() - offsetMinutes * 60000);
        this.reportFilesService.endDate = new Date(this.endDate.getTime() - offsetMinutes * 60000);

        if(!this.selectedEntity){
          this.reportFilesService.clientId = this.authenticationService.user.client.id;
        }else{
          this.reportFilesService.billingAccountId = this.selectedEntity.id;
        }



        let event: LazyLoadEvent = {
            first: 0,
            rows: 10,
            sortOrder: 0,
            multiSortMeta: undefined,
            globalFilter: null
        };


        this.loadData(event);
      }else{
        this.notificationsService.add(Severity.warn, this.translate.instant('general.warn'), this.translate.instant('tax.error.dates'));
      }


    } else {
      this.notificationsService.add(Severity.warn, this.translate.instant('general.warn'), this.translate.instant('tax.error.search'));
    }

  }
  protected beforeLoad() {
    this.paginationRequest.filters["reportType"] = ["1"];//we only need to search for reports of type tax
  }
  changeEntityType(){
    this.selectedEntity = null;
  }


  downloadPdf(report: Rest.ReportFiles){

    var that = this;
    this.reportFilesService
    .downloadReportFile([report.id])
    .then(function (res) {
      const byteCharacters = atob(res.replace("data:application/pdf;base64,",""));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const date = that.datePipe.transform(report.reportDate, 'yyyyMM');
      // save the blob using the FileSaver module
      saveAs(blob,that.translate.instant('tax.pdfName') + "_" + date + "_" + (!that.selectedEntity ? that.authenticationService.user.client.name : that.selectedEntity.cif) + "_" + report.entityName);
    })
    .catch((e) => {
        this.handleError(e, that);
    });
  }

  downloadZip(ids: number[]){
    var that = this;
    this.reportFilesService
    .downloadReportFile(ids)
    .then(function (res) {
      const byteCharacters = atob(res.replace("data:application/zip;base64,",""));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/zip' });
      // save the blob using the FileSaver module
      saveAs(blob,that.translate.instant('tax.pdfName') + "_" + (!that.selectedEntity ? that.authenticationService.user.client.name : that.selectedEntity.cif) + ".zip");
    })
    .catch((e) => {
      console.log(e);
        this.handleError(e, that);
    });
  }

  download(){
    if(this.selectedEntities.length == 1){
      this.downloadPdf(this.selectedEntities[0]);
    }else{
      const arrayIds = this.selectedEntities.map(obj => obj.id);
      this.downloadZip(arrayIds);
    }
  }

  onChangeStartDate(value){

    if(!this.endDate){
      this.endDate = value;
    }   
  }

  showPDF(report: Rest.ReportFiles){
    var that = this;
    this.reportFilesService
    .downloadReportFile([report.id])
    .then(function (res) {
      const byteCharacters = atob(res.replace("data:application/pdf;base64,",""));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      
      // Generate a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the PDF viewer in a new tab or window
      window.open(url, '_blank');

      // Revoke the URL after use (optional)
      URL.revokeObjectURL(url);
     
    })
    .catch((e) => {
        this.handleError(e, that);
    });
  }
}
