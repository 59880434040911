import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-canbus-device-configuration-gv800',
  templateUrl: './canbus-device-configuration-gv800.component.html',
  styleUrls: ['./canbus-device-configuration-gv800.component.css'],
})
export class CanbusDeviceConfigurationGv800Component extends ServerValidatedFormComponent implements OnInit {

  @ViewChild('ContentDiv', { static: true }) ContentDiv;
  @Input() canParameters: Rest.CanbusDeviceConfigurationGV800;

  

  AdditionalEventOptions: any = [
    {label: "ignore all additional event",value:0},
    {label: "by ignition on off event",value:1},                  
  ];
 
  

  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
    if(this.canParameters!=null){this.disableDIV(!this.canParameters.modeEnabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.canParameters);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
