<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Speed Event Configuration ($ST+SPEEDING)</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="speedEvent.enabled" (onChange)="handleChange($event)"></p-inputSwitch>
    </div>
    </div>
  </p-header>
      <div class="ui-g">

        <div class="ui-g" #ContentDiv>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Report Action" >
            <p-dropdown [options]="ReportModeOptions"
              [(ngModel)]="speedEvent.reportAction" [style]="{'width':'190px'}"></p-dropdown>
            </form-input>
          </div>
                  
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Min Speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedEvent.minSpeed" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Max Speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedEvent.maxSpeed" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Duration" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedEvent.duration" [min]="1" [max]="65535"></p-spinner>
              <span class="ui-inputgroup-addon">1~65535</span>
              <span class="ui-inputgroup-addon" >s</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output Id" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="speedEvent.outputId" [min]="1" [max]="8"></p-spinner>
              <span class="ui-inputgroup-addon">1~8</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output Control">
            <p-inputSwitch [(ngModel)]="speedEvent.outputControl"></p-inputSwitch>
          </form-input>
        </div>       
      </div>
      </div>
</p-accordionTab>