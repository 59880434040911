<app-view-settings-form #ViewSettingsDisplay (someConfigurationChange)="configurationsChanged($event)"></app-view-settings-form>

<!-- TODO: <<deleteConfiguration>> call is not working!
-->
<div style="overflow-y: hidden;">
  <div class="FixedSideMenu"> <!--- showCloseIcon="false" modal="false" -->
    <div class="realTimeIcon"><app-connection-watch ></app-connection-watch></div>
    <div  class="dropDownClass">
      <p-dropdown [style]="{'height': '2em'}" [options]="configOptions" [(ngModel)]="selectedConfig" (onChange)="loadConfigurationWS(); sideBarDisplay = false" i18n-placeholder placeholder="Select a Configuration" optionField="name"></p-dropdown>
    </div>
    <div class ="configButton">
      <button class="btn btn-primary btn-sm" style="height: 2.1em !important;" pButton icon="fa fa-cog" i18n-label lable="Pop-up" (click)="openViewPopUp()"></button>
    </div>





  <div class="buttonCenterVechiles">
    <button class="ui-button-danger btn btn-primary btn-sm" (click)="centerAllVehicles()" pButton  type="button"  i18n-label label="Centrar"></button>
  </div>


  <div style="float:right; margin-left: auto; display: flex;">

    <div *ngIf="configuration.coloring" class="paletteContainer" style="height: 2.1em">

      <div class="BallTextBox" *ngFor="let color of configuration.coloring.info">
          <div class="BallTextPack" >
            <div class="colorBall" [ngStyle]="{'background-color':color.value}"></div>
            <div class="colorText" >{{color.condition}}</div>
          </div>
      </div>

  </div>
    <div class="buttonReportDates">
      <button (click)="openEventsByDatePopUp()" class="btn btn-primary btn-sm" pButton  type="button"  i18n-label label="Històric"></button>
    </div>
  <div class="buttonSummary" >
    <button (click)="openSummaryPopUp()" class="btn btn-primary btn-sm" style="margin-left: 5px;" pButton  type="button"  i18n-label label="Resum"></button>
  </div>


  <div class="buttonTableExcel">
    <button class="btn btn-success btn-sm" style="margin-left: 5px;" (click)="exportExcel()" pButton  type="button"  i18n-label label="Excel"></button>
  </div>
</div>
  </div>



<div style="display: flex;height: 77vh;">
  <div [ngClass] = "{leftCol: configuration.showMap === true && configuration.showTable === true, mapAloneContainer: configuration.showMap  === true && configuration.showTable === false ,hideDiv: configuration.showMap  !== true}">
      <div [ngClass] ="{FixedMap: configuration.showMap  === true && configuration.showTable === true, mapAlone: configuration.showMap  === true && configuration.showTable === false ,hideDiv: configuration.showMap  !== true}">
        <app-ute-map #map  [pocs]=rowsWithColor  (eventVehicleClicked)="vehicleClicked($event)" (PoCClicked)="PoCClicked($event)"></app-ute-map>
    </div>
  </div>

  <div [ngClass] ="{rightCol: configuration.showTable === true && configuration.showMap  === true, tableAloneContainer: configuration.showTable === true && configuration.showMap  === false, hideDiv: configuration.showTable !== true}">

    <div [ngClass] ="{FixedTable: configuration.showTable === true && configuration.showMap  === true, tableAlone: configuration.showTable === true && configuration.showMap  === false, hideDiv: configuration.showTable !== true}">
      <app-ute-panel-table [showMapMode]="configuration.showMap"  (focusOnControlPointClicked)="focusOnControlPointClicked($event)" (controlPointClicked)="controlPointClicked($event)" (vehicleClicked)="vehicleClickedInTable($event)" #table [rows]="rowsWithColor"></app-ute-panel-table>
    </div>
  </div>

  <!-- [ngClass] ="{hideDiv: configuration.showMap == true || configuration.showTable == true, center: configuration.showMap == false && configuration.showTable == false}" -->
  <div class="center" *ngIf="configuration.showTable === false && configuration.showMap === false"  i18n>
      MAP AND THE TABLE ARE HIDDEN, TO CHANGE THIS CONFIGURATION USE THE CONFIG BUTTON AT THE TOP OF THE PAGE
  </div>
</div>

<div class="center" *ngIf="configuration === null" i18n>
  NO CONFIGURATION SELECTED
</div>
</div>
<app-ute-control-point-history #UteControlPointHistory [configuration]="configuration"></app-ute-control-point-history>
<app-ute-vehicle-history (followVehicleEvent)="followVehicle($event)"  #UteVehicleHistory></app-ute-vehicle-history>
<app-ute-summary-table [pdc_Events]="rowsWithColor" [configuration]="configuration"  #UteSummaryTableComponent></app-ute-summary-table>
<app-ute-report-events  [rol]=configuration.coloring?.rol [vehiclesIds]="vehiclesIds"  #UteReportEventsComponent></app-ute-report-events >

