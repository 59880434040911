import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';


@Component({
  selector: 'app-alarm-over-speed-configuration-ob22',
  templateUrl: './alarm-over-speed-configuration-ob22.component.html',
  styleUrls: ['./alarm-over-speed-configuration-ob22.component.css'],
})
export class AlarmOverSpeedConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {

  @Input() alarmOverSpeed: Rest.AlarmOverSpeedConfigurationOB22;
  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  constructor(protected i18n: I18nService) {  super(i18n);}

  AlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"GPRS_SMS"},            
  ];

  ngOnInit() {  
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.alarmOverSpeed);
    }
  }

}