import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { Rest } from "../../../../rest/rest_client";
import { RealtimeService } from "../../../../rest/realtime.service";
import { UserTimePipe } from "../../../shared/UserTimePipe";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { RestExt } from "../../../../services/rest-client-extension";
import { RealTimeDataService } from "../../../../services/rt-data/rt-data.service";
import { CercaliaMapService } from "../../../../services/cercalia-map/cercalia-map.service";
import { AuthenticationService } from "../../../../core/services/authentication/authentication.service";
@Component({
    selector: "app-events-table",
    templateUrl: "./events-table.component.html",
    styleUrls: ["./events-table.component.css"],
    providers: [RealtimeService, CercaliaMapService],
})
export class EventsTableComponent implements OnInit, OnChanges, OnDestroy {
    private alive: boolean = true;
    eventsArr: Rest.Event[] = [];
    isCollapsed: false;

    constructor(
        private i18n: I18nService,
        private rtService: RealtimeService,
        private realTimeDataService: RealTimeDataService,
        private rtDataService: RealTimeDataService,
        private auth: AuthenticationService,
        private userTimePipe: UserTimePipe,
        private jquery: JQueryHTTPClient
    ) {
        this.eventsArr = [];
    }

    ngOnInit() {
        this.rtDataService.event
            .takeWhile(() => this.alive)
            .subscribe((e: Rest.Event) => {
                this.eventsArr.push(this.computeDerivatedValues(e));
                this.eventsArr = this.eventsArr.sort(
                    (a, b) => a.deviceTime - b.deviceTime
                );
                this.eventsArr = [...this.eventsArr];
            });

        /**
         * Whenever the vehicle subscription changes, get the new data
         */

        this.rtDataService.watchedVehicles
            .takeWhile(() => this.alive)
            .subscribe((watched: RestExt.WatchedVehicle[]) => {
                if (watched == null) this.eventsArr = [];
                return this.jquery
                    .request({
                        method: "POST",
                        url: RestExt.uriEncoding`events`,
                        queryParams: {maxCount: 10},
                        data: watched.map((w) => w.id),
                        headers: false,
                        cmREST: false,
                    })
                    .then((data: Rest.Event[]) => {
                        if (data != null)
                            this.eventsArr = data.map((e) => this.computeDerivatedValues(e));
                        else this.eventsArr = [];
                    });
            });
    }

    computeDerivatedValues(event: any): any {
        event._$expandable =
            event.type == "REQUEST_QUEUED" ||
            event.type == "REQUEST_SENT" ||
            event.type == "REQUEST_SENDING" ||
            event.type == "REQUEST_CANCELLED" ||
            event.type == "SENSOR" ||
            event.type == "ZONE" ||
            event.type == "CUSTOM";

        if (
            event.type == "REQUEST_QUEUED" ||
            event.type == "REQUEST_SENT" ||
            event.type == "REQUEST_SENDING" ||
            event.type == "REQUEST_CANCELLED"
        ) {
            event._$requestId = "" + event.requestId;
        } else {
            event._$requestId = "-";
        }
        //Format the date
        event._$timestr = this.userTimePipe.transform(event.timestamp);
        event._$serialzed = JSON.stringify(event);
        if (event.type == "CUSTOM") {
            switch (this.auth.user.language) {
                case "ES":
                    event._$description = (event as Rest.CustomEvent).descriptionES;
                    break;
                case "CA":
                    event._$description = (event as Rest.CustomEvent).descriptionCA;
                    break;
                case "FR":
                    event._$description = (event as Rest.CustomEvent).descriptionFR;
                    break;
                case "EN":
                    event._$description = (event as Rest.CustomEvent).descriptionEN;
                default:
                    break;
            }
        }

        //event.timestamp = this.userTimePipe.toUserTime(event.timestamp);

        let vehicleData = this.rtDataService.watchedVehicles
            .getValue()
            .find((wv) => wv.id == event.vehicleId);
        event._$vehicleName = vehicleData ? vehicleData.name : "?";
        return event;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["settings"]) {
            this.eventsArr = [];
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }
}
