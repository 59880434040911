<p-dialog header={{userFormTitle}} [(visible)]="display" [responsive]="true"
    [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="true" *ngIf="display">

    <div class="container-xl">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <p-panel i18n-header header="General" [style]="{'margin-bottom': '20px'}">

            <div class="container">

                <div class="row row-cols-2 mt-3">
                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.name' | translate}}"
                            [errors]="errors.name">
                            <input pInputText id="name" [(ngModel)]="entity.name" [style]="{'width':'250px'}" />
                        </form-input>
                    </div>
                </div>

                <div class="row mt-3">

                    <div class="col">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.firstSurname' | translate}}" [errors]="errors.surname1">
                            <input pInputText id="surname1" [(ngModel)]="entity.surname1" [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                    <div class="col">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.secondSurname' | translate}}" [errors]="errors.surname2">
                            <input pInputText id="surname2" [(ngModel)]="entity.surname2" [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                </div>

                <div class="row mt-3">

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.language' | translate}}"
                            [errors]="errors.language">
                            <p-dropdown #ddLan id="language" i18n-placeholder
                                placeholder="{{'general.select' | translate}} {{'userProfile.language' | translate}}"
                                [options]="availableUserLanguage" [(ngModel)]="entity.language" filter="filter"
                                [style]="{'width':'250px'}"></p-dropdown>
                        </form-input>
                    </div>

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.timeZone' | translate}}"
                            [errors]="errors.timeZone">
                            <app-time-zone-selector [(timeZone)]="entity.timeZone"
                                (onChange)="timeZoneUpdate($event)"></app-time-zone-selector>
                        </form-input>
                    </div>

                </div>

                <div class="row mt-3">

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.metric' | translate}}"
                            [errors]="errors.metric">
                            <p-dropdown #ddMet id="metric" i18n-placeholder
                                placeholder="{{'general.select' | translate}} {{'userProfile.metric' | translate}}"
                                [options]="availableUserMetric" [(ngModel)]="entity.metric"
                                [style]="{'width':'250px'}"></p-dropdown>
                        </form-input>
                    </div>

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.username' | translate}}"
                            [errors]="errors.userName">
                            <input pInputText id="userName" [(ngModel)]="entity.userName" [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                </div>

                <div class="row mt-3">

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'login.password' | translate}}"
                            [errors]="errors.password">
                            <input pInputText id="password" type="password" [(ngModel)]="entity.password"
                                [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                    <div class="col">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'userProfile.email' | translate}}"
                            [errors]="errors.email">
                            <input pInputText id="email" [(ngModel)]="entity.email" [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                </div>

                <div class="row mt-3">

                    <div class="col">
                        <form-input [required]="false" i18n-fieldName fieldName="{{'userProfile.phone' | translate}}"
                            [errors]="errors.phoneNumber">
                            <input pInputText id="phoneNumber" [(ngModel)]="entity.phoneNumber"
                                [style]="{'width':'250px'}" />
                        </form-input>
                    </div>

                    <div class="col">
                        <form-input [required]="false" i18n-fieldName fieldName="{{'userProfile.ssoId' | translate}}"
                            [errors]="errors.ssoId">
                            <input pInputText id="ssoId" [(ngModel)]="entity.ssoId"
                                [style]="{'width':'250px'}" />
                        </form-input>
                    </div>
                </div>

                <div class="row mt-3 mb-4">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.accountExpiryDate' | translate}}"
                            [errors]="errors.accountExpiry">
                            <p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"
                                [(ngModel)]="accountExpiry" dateFormat={{i18n.dateFormat}} showIcon="true"
                                styleClass="ui-column-filter" [style]="{'width':'250px'}"></p-calendar>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.isAdmin">
                        <form-input i18n-fieldName fieldName="{{'control-panel.clients.client' | translate}}"
                            [required]="false" [errors]="errors.client">
                            <p-dropdown id="dropdownClients"
                                placeholder="{{'general.select' | translate}} {{'control-panel.clients.client' | translate}}"
                                [options]="availableClients" [(ngModel)]="entity.client" [style]="{'width':'250px'}"
                                filter="filter" filterBy="name" dataKey='id'></p-dropdown>
                        </form-input>
                    </div>
                </div>

                <div class="row mt-3 mb-4">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'general.enabled' | translate}}"
                            [errors]="errors.enabled">
                            <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>

            </div>
        </p-panel>

		  <!-- Checkbox dels "CAN" -->
        <p-panel i18n-header header="UI" [style]="{'margin-bottom': '20px'}">

            <div class="container">

					 <div class="d-flex row-cols-2 w-100">
						<!-- PARTE IZQ: DRIVER -->
						<div class="d-flex">
							<div class="d-flex flex-column mb-3">
								<div class="p-2">
									<form-input i18n-fieldName fieldName="{{'userProfile.defaultDashboard' | translate}}"
										[errors]="errors.defaultDashboard">
										<p-dropdown id="defaultDashboard"
									 		placeholder="{{'general.select' | translate}} {{'userProfile.defaultDashboard' | translate}}"
									 		[options]="availableDashboards" [(ngModel)]="entity.defaultDashboard"
									 		[style]="{'width':'190px'}" filter="filter"></p-dropdown>
						  			</form-input>
								</div>
							</div>
						</div>
					</div>

					<div class="d-flex row-cols-2 w-100">
						<!-- PARTE IZQ: DRIVER -->
						<div class="d-flex">
							<div class="d-flex flex-column mb-3">
								<div *ngFor="let iteml of leftCheckBox; index as l" class="p-2">
									<form-input i18n-fieldName fieldName="{{'userProfile.' + iteml.tag | translate}}"
										[errors]="'errors.' + iteml.errors">
										<p-checkbox [(ngModel)]="iteml.ngModel" binary="true"></p-checkbox>
							  		</form-input>
								</div>
							</div>
						</div>
						<!-- PARTE DER: date & kms -->
						<div class="d-flex">
							<div class="d-flex flex-column mb-3">
								<div *ngFor="let itemr of rightCheckBox; index as r" class="p-2">
									<form-input i18n-fieldName fieldName="{{'userProfile.' + itemr.tag | translate}}"
										[errors]="'errors.' + itemr.errors">
										<p-checkbox [(ngModel)]="itemr.ngModel" binary="true"></p-checkbox>
							 		</form-input>
								</div>
							</div>
						</div>
					</div>

					<!-- <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.defaultDashboard' | translate}}"
                            [errors]="errors.defaultDashboard">
                            <p-dropdown id="defaultDashboard"
                                placeholder="{{'general.select' | translate}} {{'userProfile.defaultDashboard' | translate}}"
                                [options]="availableDashboards" [(ngModel)]="entity.defaultDashboard"
                                [style]="{'width':'190px'}" filter="filter"></p-dropdown>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeUTERondesDashboard' | translate}}"
                            [errors]="errors.canSeeUTERondesDashboard">
                            <p-checkbox [(ngModel)]="entity.canSeeUTERondesDashboard" binary="true"></p-checkbox>
                        </form-input>
                    </div>
               </div>

               <div class="row mt-3 mb-4">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeReports' | translate}}"
                            [errors]="errors.canSeeReports">
                            <p-checkbox [(ngModel)]="entity.canSeeReports" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeRTDashboard' | translate}}"
                            [errors]="errors.canSeeRTDashboard">
                            <p-checkbox [(ngModel)]="entity.canSeeRTDashboard" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeSAT' | translate}}"
                            [errors]="errors.canSeeSat">
                            <p-checkbox [(ngModel)]="entity.canSeeSat" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeRaceEvents' | translate}}"
                            [errors]="errors.canSeeSat">
                            <p-checkbox [(ngModel)]="entity.canSeeRaceEvents" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeTax' | translate}}"
                            [errors]="errors.canSeeTax">
                            <p-checkbox [(ngModel)]="entity.canSeeTax" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeInventoryVehicles' | translate}}"
                            [errors]="errors.canSeeInventoryVehicles">
                            <p-checkbox [(ngModel)]="entity.canSeeInventoryVehicles" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeDrivers' | translate}}"
                            [errors]="errors.canSeeDrivers">
                            <p-checkbox [(ngModel)]="entity.canSeeDrivers" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeMaintenance' | translate}}"
                            [errors]="errors.canSeeMaintenance">
                            <p-checkbox [(ngModel)]="entity.canSeeMaintenance" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeTableManagement' | translate}}"
                            [errors]="errors.canSeeTableManagement">
                            <p-checkbox [(ngModel)]="entity.canSeeTableManagement" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeDashboards' | translate}}"
                            [errors]="errors.canSeeDashboards">
                            <p-checkbox [(ngModel)]="entity.canSeeDashboards" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="canSeeBologistics" [errors]="errors.canSeeBologistics">
                            <p-checkbox [(ngModel)]="entity.canSeeBologistics" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                   <div class="col">
                        <form-input i18n-fieldName fieldName="canSeeApplogistics" [errors]="errors.canSeeApplogistics">
                            <p-checkbox [(ngModel)]="entity.canSeeApplogistics" binary="true"></p-checkbox>
                       </form-input>
                    </div>
                </div> 
                <div class="row mt-3">
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeInventoryReport' | translate}}"
                            [errors]="errors.canSeeInventoryReport">
                            <p-checkbox [(ngModel)]="entity.canSeeInventoryReport" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                    <div class="col">
                        <form-input i18n-fieldName fieldName="{{'userProfile.canSeeTaco' | translate}}"
                            [errors]="errors.canSeeTaco">
                            <p-checkbox [(ngModel)]="entity.canSeeTaco" binary="true"></p-checkbox>
                        </form-input>
                    </div>
                </div> -->
            </div>
        </p-panel>

        <p-panel i18n-header header="{{'navbar.roles' | translate}}" [style]="{'margin-bottom': '20px'}">
            <div class="container">

                <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
                1. Check correct functionality;
                2. Remove unused code;
                4. Add translations;
                -->

                <p-table #dt [value]="entity.rolesProfiles" [rows]="10"
                    [totalRecords]="entity.rolesProfiles!==null?entity.rolesProfiles.length:0"
                    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [rowHover]="true" [autoLayout]="true"
                    [pageLinks]="3">

                    <!-- Table Column/Header Definition -->
                    <ng-template pTemplate="header">
                        <tr>

                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">{{'control-panel.users.role' |
                                    translate}}
                                    <p-sortIcon field="role"></p-sortIcon>
                                    <p-columnFilter type="text" field="role" [showMatchModes]="false"
                                        [showOperator]="false" display="menu" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">{{'control-panel.users.profile' |
                                    translate}}
                                    <p-sortIcon field="profile"></p-sortIcon>
                                    <p-columnFilter type="text" field="profile" display="menu" [showMatchModes]="false"
                                        [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th style="width: 3rem">
                                <ng-template let-roleProfilePair="rowData" pTemplate="body">
                                    <div>
                                        <button type="button" i18n-pTooltip pTooltip="Remove Role-Profile pair"
                                            tooltipPosition="left">
                                            <i (click)="removePair(roleProfilePair)" class="fa fa-trash-o fa-lg"
                                                aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </ng-template>
                            </th>

                        </tr>
                    </ng-template>

                    <!-- Table Row Definition -->
                    <ng-template pTemplate="body" let-permission>
                        <tr>

                            <td>
                                <span class="p-column-title">{{'control-panel.users.role' | translate}}</span>
                                {{permission.role.name}}
                            </td>

                            <td>
                                <span class="p-column-title">{{'control-panel.users.profile' | translate}}</span>
                                {{permission.profile.name}}
                            </td>

                            <td>
                                <button type="button" i18n-pTooltip pTooltip="Remove Role-Profile pair"
                                    tooltipPosition="left">
                                    <i (click)="removePair(permission.rolesProfiles)" class="fa fa-trash-o fa-lg"
                                        aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">
                                <p i18n>{{'table.tableEmptyMessage' | translate}}</p>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>


                <!--<p-dataTable [value]="entity.rolesProfiles" [totalRecords]="entity.rolesProfiles!=null?entity.rolesProfiles.length:0" [rows]="10"
                  [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" #dt>
                  <p-header>
                    <ng-container i18n>Role-Profile pairs</ng-container>
                  </p-header>
                  <p-column field="role.name" i18n-header header="role" [filter]="true" i18n-filterPlaceholder filterPlaceholder="Search">
                  </p-column>

                  <p-column field="profile.name" i18n-header header="profile" [filter]="true" i18n-filterPlaceholder filterPlaceholder="Search">
                  </p-column>

                  <p-column [style]="{'width':'50px'}">
                    <ng-template let-roleProfilePair="rowData" pTemplate="body">
                      <div>
                        <button type="button" i18n-pTooltip pTooltip="Remove Role-Profile pair" tooltipPosition="left">
                          <i (click)="removePair(roleProfilePair)" class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                        </button>
                      </div>
                    </ng-template>
                  </p-column>


                </p-dataTable>-->


                <div class="row align-items-center mt-3 mb-4">

                    <div class="col">
                        <label i18n>{{'control-panel.users.profile' | translate}}</label>
                        <paged-search-dropdown [pageFunction]="this.profileService.getPage.bind(this.profileService)"
                            [(selectedEntity)]="selectedProfile" (selectedEntityChange)="selectedProfileChanged($event)"
                            [paginationRequest]="profilePaginationRequest"></paged-search-dropdown>
                    </div>

                    <div class="col">
                        <label i18n>{{'control-panel.users.role' | translate}}</label>
                        <paged-search-dropdown [pageFunction]="this.roleService.getPage.bind(this.roleService)"
                            [(selectedEntity)]="selectedRole" (selectedEntityChange)="selectedRoleChanged()"
                            [paginationRequest]="rolePaginationRequest"></paged-search-dropdown>
                    </div>

                    <div class="col align-self-center">
                        <button [disabled]="selectedProfile===null || selectedRole===null" (click)="addPair()">+
                        </button>
                    </div>

                </div>

            </div>
        </p-panel>

        <p-panel i18n-header header="{{'userProfile.permissions' | translate}}" [style]="{'margin-bottom': '20px'}">

            <div class="container">

                <div class="row align-items-center mt-3">

                    <div class="col" *ngIf="user.isAdmin">
                        <form-input [required]="false" i18n-fieldName fieldName="{{'userProfile.isAdmin' | translate}}"
                            [errors]="errors.isAdmin">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.isAdmin"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canAccesBackend  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canAccessBackend' | translate}}" [errors]="errors.canAccesBackend">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canAccesBackend"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                </div>

                <div class="row align-items-center mt-3">

                    <div class="col" *ngIf="user.canManageUsers  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageUsers' | translate}}" [errors]="errors.canManageUsers">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageUsers"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canManageFleets  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageFleets' | translate}}" [errors]="errors.canManageFleets">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageFleets"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                </div>

                <div class="row align-items-center mt-3">

                    <div class="col" *ngIf="user.canManageMaintenancePlans  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageMaintenancePlans' | translate}}"
                            [errors]="errors.canManageMaintenancePlans">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageMaintenancePlans"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canManageDevices  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageDevices' | translate}}"
                            [errors]="errors.canManageDevices">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageDevices"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                </div>

                <div class="row align-items-center mt-3">

                    <div class="col" *ngIf="user.canManageClients  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageClients' | translate}}"
                            [errors]="errors.canManageClients">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageClients"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canManageZoneGroups  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageZoneGroups' | translate}}"
                            [errors]="errors.canManageZoneGroups">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageZoneGroups"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                </div>

                <div class="row align-items-center mt-3">

                    <div class="col" *ngIf="user.canManagePOICategories && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManagePOICategories' | translate}}"
                            [errors]="errors.canManagePOICategories">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManagePOICategories"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canManageNotificationGroups && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageNotificationGroups' | translate}}"
                            [errors]="errors.canManageNotificationGroups">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageNotificationGroups"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                </div>

                <div class="row align-content-end mt-3 mb-4">

                    <div class="col" *ngIf="user.canManageRules  && !entity.isAdmin">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canManageRules' | translate}}" [errors]="errors.canManageRules">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canManageRules"></p-inputSwitch>
                            </div>
                        </form-input>
                    </div>

                    <div class="col" *ngIf="user.canCreatUTERondesPOCVisits">
                        <form-input [required]="false" i18n-fieldName
                            fieldName="{{'userProfile.canCreatUTERondesPOCVisits' | translate}}"
                            [errors]="errors.canCreatUTERondesPOCVisits">
                            <div class="float-end">
                                <p-inputSwitch [(ngModel)]="entity.canCreatUTERondesPOCVisits"></p-inputSwitch>
                            </div>

                        </form-input>
                    </div>

                </div>




            </div>

        </p-panel>

        <div *ngIf='!isNew' class="pb-3">
            <p-panel header="{{'navbar.devices' | translate}}">

                <!-- DEVICES PRIMENG DATATABLE -->
                <p-table [value]='devices' [rows]='10' [rowsPerPageOptions]='[10,20,30,40,50]' [paginator]='true'
                    [rowHover]='true' [autoLayout]='true'>

                    <!-- TABLE TITLE DEFINITION -->
                    <ng-template pTemplate='caption'>
                        <div class='d-flex align-items-center justify-content-center text-uppercase mb-5 table-title'>
                            {{ 'control-panel.devices.devicesTable' | translate }}
                        </div>
                    </ng-template>

                    <!-- TABLE COLUMN DEFINITION -->
                    <ng-template pTemplate='header'>
                        <tr>

                            <!-- DEVICE ID -->
                            <th pSortableColumn='id'>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'control-panel.devices.deviceId' | translate }}
                                    </div>

                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-sortIcon field='id'></p-sortIcon>
                                        <p-columnFilter field='id' type='numeric' matchMode='contains'
                                            placeholder="{{ 'general.search' | translate }}" display='menu'
                                            [showMatchModes]='false' [showOperator]='false'
                                            [showAddButton]='false'></p-columnFilter>
                                    </div>

                                </div>

                            </th>

                            <!-- EXTERNAL ID -->
                            <th pSortableColumn='externalId'>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'control-panel.devices.deviceIMEI' | translate }}
                                    </div>

                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-sortIcon field='externalId'></p-sortIcon>
                                        <p-columnFilter field='externalId' type='text' matchMode='contains'
                                            placeholder="{{ 'general.search' | translate }}" display='menu'
                                            [showMatchModes]='false' [showOperator]='false'
                                            [showAddButton]='false'></p-columnFilter>
                                    </div>

                                </div>

                            </th>

                            <!-- NAME -->
                            <th>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'general.name' | translate }}
                                    </div>

                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-columnFilter field='vehicle.name' type='text' matchMode='contains'
                                            placeholder="{{ 'general.search' | translate }}" display='menu'
                                            [showMatchModes]='false' [showOperator]='false'
                                            [showAddButton]='false'></p-columnFilter>
                                    </div>

                                </div>

                            </th>

                            <!-- PHONE NUMBER -->
                            <th pSortableColumn='phoneNumber'>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'general.phoneNumber' | translate }}
                                    </div>

                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-sortIcon field='phoneNumber'></p-sortIcon>
                                        <p-columnFilter field='phoneNumber' type='text' matchMode='contains'
                                            placeholder="{{ 'general.search' | translate }}" display='menu'
                                            [showMatchModes]='false' [showOperator]='false'
                                            [showAddButton]='false'></p-columnFilter>
                                    </div>

                                </div>

                            </th>

                            <!-- VEHICLE ID -->
                            <th>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'control-panel.vehicles.vehicleId' | translate }}
                                    </div>

                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-columnFilter field='vehicle.id' type='numeric' matchMode='contains'
                                            placeholder="{{ 'general.search' | translate }}" display='menu'
                                            [showMatchModes]='false' [showOperator]='false'
                                            [showAddButton]='false'></p-columnFilter>
                                    </div>

                                </div>

                            </th>

                            <!-- VEHICLE NAME -->
                            <th>
                                <div class='d-flex align-items-center h-100'>

                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ 'control-panel.vehicles.vehicleName' | translate }}
                                    </div>

                                </div>
                            </th>

                            <!-- INFO -->
                            <th class='button-column'>

                            </th>

                        </tr>
                    </ng-template>

                    <!-- TABLE BODY -->
                    <ng-template pTemplate='body' let-device>
                        <tr class='p-selectable-row custom-rows' (dblclick)='showDeviceFormComponent(device)'>

                            <!-- DEVICE ID -->
                            <td>
                                {{device.id}}
                            </td>

                            <!-- DEVICE IMEI -->
                            <td>
                                {{device.imei}}
                            </td>

                            <!-- NAME -->
                            <td>
                                {{device.vehicle?.name ? device.vehicle.name : 'N/A'}}
                            </td>

                            <!-- PHONE NUMBRE -->
                            <td>
                                {{device.phoneNumber}}
                            </td>

                            <!-- VEHICLE ID -->
                            <td>
                                {{device.vehicle?.id ? device.vehicle.id : 'N/A'}}
                            </td>

                            <!-- VEHICLE NAME -->
                            <td>
                                {{device.vehicle?.name ? device.vehicle.name : 'N/A'}}
                            </td>

                            <!-- INFO -->
                            <td>
                                <app-vehicle-state-button *ngIf="device.vehicle"
                                    [buttonStyle]="'btn custom-table-remove-button'" [disabled]="false"
                                    [vehicleId]="device.vehicle.id"></app-vehicle-state-button>
                            </td>

                        </tr>
                    </ng-template>

                    <!-- TABLE EMPTY MESSAGE -->
                    <ng-template pTemplate='emptymessage'>
                        <tr>
                            <td colspan='7' class='p-0'>
                                <div class='d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message'
                                    i18n>
                                    {{ 'table.tableEmptyMessage' | translate }}!
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>

            </p-panel>
        </div>

        <p-panel *ngIf="(user.isAdmin === true || user.canSeeSAT === true) && !isNew" i18n-header
            header="{{'userProfile.credit.credits' | translate}}" [style]="{'margin-bottom': '20px'}">

            <div class="col">
                <form-input fieldName="{{'userProfile.credit.credits' | translate}}">
                    <input [readOnly]="true" [ngModel]="credits?.currentCredits?.credits" />
                </form-input>
            </div>
            <div class="col">
                <form-input fieldName="{{'userProfile.credit.addCredits' | translate}}">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="addCredits" [min]="0" [max]="999999999"></p-spinner>
                    </div>
                </form-input>
            </div>
            <div class="container">
                <p-table #dt [value]="credits?.historicCredits" [rows]="10"
                    [totalRecords]="credits?.historicCredits.length" [rowsPerPageOptions]="[10,20,30,40,50]"
                    [paginator]="true" [rowHover]="true" [autoLayout]="true" [pageLinks]="3">

                    <!-- Table Column/Header Definition -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="purchaseDate">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.purchaseDate' |
                                    translate}}
                                    <p-sortIcon field="Purchase Date"></p-sortIcon>
                                    <p-columnFilter type="date" field="purchaseDate" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>


                            <th pSortableColumn="credits">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.credits' |
                                    translate}}
                                    <p-sortIcon field="Credits"></p-sortIcon>
                                    <p-columnFilter type="text" field="credits" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="creditPrice">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.price' |
                                    translate}}
                                    <p-sortIcon field="Credit Price"></p-sortIcon>
                                    <p-columnFilter type="text" field="creditPrice" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="costSMS">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.costSMS' |
                                    translate}}
                                    <p-sortIcon field="Cost SMS"></p-sortIcon>
                                    <p-columnFilter type="text" field="costSMS" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="costEmail">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.costEmail' |
                                    translate}}
                                    <p-sortIcon field="Cost Email"></p-sortIcon>
                                    <p-columnFilter type="text" field="costEmail" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="import">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.import' |
                                    translate}}
                                    <p-sortIcon field="Import"></p-sortIcon>
                                    <p-columnFilter type="text" field="import" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="creditsBeforePurchase">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'userProfile.credit.creditsBeforePurchase' | translate}}
                                    <p-sortIcon field="creditsBeforePurchase"></p-sortIcon>
                                    <p-columnFilter type="text" field="creditsBeforePurchase" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="purchaseUsername">
                                <div class="p-d-flex p-jc-between p-ai-center">{{'userProfile.credit.purchaseUsername' |
                                    translate}}
                                    <p-sortIcon field="actionUser.userName"></p-sortIcon>
                                    <p-columnFilter type="text" field="actionUser.username" placeholder="Search"
                                        [showMatchModes]="false" [showOperator]="false" display="menu"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <!-- Table Row Definition -->
                    <ng-template pTemplate="body" let-historicCredits>
                        <tr>
                            <td>
                                <span class="p-column-title">Purchase Date</span>
                                {{historicCredits.purchaseDate|usertime:'shortDate'}}
                            </td>
                            <td>
                                <span class="p-column-title">Credits</span>
                                {{historicCredits.credits}}
                            </td>
                            <td>
                                <span class="p-column-title">Credit Price</span>
                                {{historicCredits.creditPrice}}
                            </td>
                            <td>
                                <span class="p-column-title">Cost SMS</span>
                                {{historicCredits.costSMS}}
                            </td>
                            <td>
                                <span class="p-column-title">Cost Email</span>
                                {{historicCredits.costEmail}}
                            </td>
                            <td>
                                <span class="p-column-title">Import</span>
                                {{historicCredits.import}}
                            </td>
                            <td>
                                <span class="p-column-title">Credits before purchase</span>
                                {{historicCredits.creditsBeforePurchase}}
                            </td>
                            <td>
                                <span class="p-column-title">Purchase Username</span>
                                {{historicCredits.actionUser?.userName}}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">
                                <p i18n>{{'table.tableEmptyMessage' | translate}}</p>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </p-panel>

        <!-- TERMINALS PANEL -->
        <p-panel *ngIf='!isNew' header="{{ 'general.terminals' | translate }}" [toggleable]='true'>
            <app-terminals-list [terminalsList]='terminals'></app-terminals-list>
        </p-panel>


        <!-- #ChangeStatus  -->
        <div *ngIf="showStatusDialog">
            <app-change-status-dialog [items]=title [display]="true" [service]=userService
                [entitiesList]=selectedEntities (return)="refreshForm($event)"
                (close)="closeStatusDialog($event)"></app-change-status-dialog>
        </div>
        <!--  -->

    </div>

    <p-footer>
        <div class="d-flex flex-row-reverse bd-highlight">
            <div *ngIf="(user.isAdmin === true || user.canSeeSAT === true) && !isNew" class="p-2 bd-highlight">
                <button type="button" pButton icon="fa fa-check" (click)="saveCredits()" i18n-label
                    label="{{'userProfile.credit.saveCredits' | translate}}" class="btn custom-button"></button>
            </div>
            <div class="p-2 bd-highlight">
                <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
                    label="{{'general.changeStatus' | translate}}" class="btn custom-button"></button>
            </div>
            <div class="p-2 bd-highlight">
                <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
                    label="{{'general.save' | translate}}" class="btn custom-button"></button>
            </div>
        </div>
    </p-footer>

</p-dialog>
