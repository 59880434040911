<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Non-movement Detection (AT+GTNMD)</span>
            </div>
          </div>
        </p-header>
        
        <div class="row mt-3">
            <div class="row mt-3">
                <form-input fieldName="ICCID Mode" ></form-input>
                <div class="row mt-3">
                    <p-checkbox binary="true" label="Bit0: Suspend the report of FRI and Geo-fence when it detects non-movement" [(ngModel)]="nmdParameters.suspendFRIAndGeoFenceWhenNoMovement">
                    </p-checkbox>
                </div>
                <div class="row mt-3">
                    <p-checkbox binary="true" label="Bit1: Report the message +RESP:GTNMR to the backend server when it detects non-movement" [(ngModel)]="nmdParameters.reportGTNMRWhenNoMovement">
                    </p-checkbox>
                </div>  
                <div class="row mt-3">
                    <p-checkbox binary="true" label="Bit2: Report the message +RESP:GTNMR to the backend server when it detects movement" [(ngModel)]="nmdParameters.reportGTNMRWhenMovement">
                    </p-checkbox>
                </div> 
                <div class="row mt-3">
                    <p-checkbox binary="true" label="Bit3: Change GPS fix interval and +RESP:GTFRI report interval to <Rest send interval> when it detects non-movement" [(ngModel)]="nmdParameters.changeGPSFixIntervalAndGTFRIReportIntervalWhenNoMovement">
                    </p-checkbox>
                </div>                                    
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Non-movement Duration">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.noMovementDuration"
                                [min]="1" [max]="255"></p-spinner>
                            <span class="ui-inputgroup-addon">1~255</span>
                            <span class="ui-inputgroup-addon">sec(x14)</span>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Movement Duration">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.movementDuration"
                                [min]="1" [max]="50"></p-spinner>
                            <span class="ui-inputgroup-addon">1~50</span>
                            <span class="ui-inputgroup-addon">ms(x128)</span>
                        </div>
                    </div>
                </div>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Movement Threshold">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.movementThreshold"
                                [min]="2" [max]="9"></p-spinner>
                            <span class="ui-inputgroup-addon">2~9</span>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Fix Interval at Rest">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.fixIntervalRest"
                                [min]="1" [max]="86400"></p-spinner>
                            <span class="ui-inputgroup-addon">1~86400</span>
                            <span class="ui-inputgroup-addon">sec</span>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Send Interval at Rest">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.sendIntervalRest"
                                [min]="5" [max]="86400"></p-spinner>
                            <span class="ui-inputgroup-addon">5~86400</span>
                            <span class="ui-inputgroup-addon">sec</span>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="PM Rest Threshold">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.pmRestThreshold"
                                [min]="2" [max]="9"></p-spinner>
                            <span class="ui-inputgroup-addon">2~9</span>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="PM Motion Threshold">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="nmdParameters.pmMotionThreshold"
                                [min]="2" [max]="9"></p-spinner>
                            <span class="ui-inputgroup-addon">2~9</span>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="URC Report" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="urcReportOptions"
                    [(ngModel)]="nmdParameters.urcReport" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Enter Movement by Command" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="commandOptions"
                    [(ngModel)]="nmdParameters.movementCommand" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="NMD Report Mode" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="reportOptions"
                    [(ngModel)]="nmdParameters.modeReport" [style]="{'width':'100%','min-width':'100px'}" appendTo="body"></p-dropdown>
                </div>
            </div>
        </div>
      </p-accordionTab>