import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { FineReasonService } from 'app/modules/vehicle-inventory/services/table-management/fineReason.service';

@Component({
  selector: 'app-fine-reasons-form',
  templateUrl: './fine-reasons-form.component.html',
  styleUrls: ['./fine-reasons-form.component.css'],
  providers:[FineReasonService]

})
export class FineReasonsFormComponent extends EntityFormComponent<Rest.FineReasons>
implements OnInit {


    title: string;




    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private fineReasonService: FineReasonService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(fineReasonService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    beforeShow() {

        /*Permissions */
        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.fineReason.createFineReason');
        } else {
            this.title = this.translateService.instant('tableManagement.fineReason.editFineReason');

        }
    }

}
