import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";



@Injectable()
export class AlarmTypeService extends EntityService<Rest.InvAlarmType> {
    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.InvAlarmType): Rest.RestResponse<Rest.InvAlarmType> {
        try {
            return this.client.AlarmType_create(arg0);
        } catch (error) {
            console.error("Error creating AlarmType:", error);
            throw error;
        }
    }

    update(arg0: Rest.InvAlarmType): Rest.RestResponse<Rest.InvAlarmType> {
        try {
            return this.client.AlarmType_update(arg0);
        } catch (error) {
            console.error("Error updating AlarmType:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Rest.InvAlarmType>> {
        try {
            return this.client.AlarmType_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.AlarmType_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.InvAlarmType> {
        try {
            return this.client.AlarmType_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding AlarmType:", error);
            throw error;
        }
    }
}