<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="form-label custom-label mb-0 me-2" i18n>
                    {{'control-panel.vehicles.vehicleType' | translate}}
                </label>
                <div class="p-0"></div>
                <p-dropdown [options]='vehicleTypeList' [(ngModel)]='selectedType'
                    placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.types.type' | translate }}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" (onChange)="typeChanged($event)"
                    filter="true" filterBy="name">
                </p-dropdown>
            </div>

            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'control-panel.vehicles.vehicleBrand.name' | translate}}
                </label>
                <div class="p-0"></div>
                <p-dropdown [options]='brandList' [(ngModel)]='selectedBrand'
                    placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.brands.brand' | translate }}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" (onChange)="brandChanged($event)"
                    filter="true" filterBy="name">
                </p-dropdown>
            </div>
        </div>


        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="form-label custom-label mb-0 me-2" i18n>
                    {{'control-panel.vehicles.vehicleModel.name' | translate}}
                </label>
                <div class="p-0"></div>
                <p-dropdown [options]='modelList' [(ngModel)]='selectedModel'
                    placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.models.model' | translate }}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" filter="true" filterBy="name">
                </p-dropdown>
            </div>

            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'tableManagement.versions.name' | translate}}
                </label>
                <input type="text" id="vehicle-data-settings-name" class="form-control custom-input"
                    [(ngModel)]="entity.name">
            </div>
        </div>

        <!-- CILINDER CAPACITY & CONSUMPTION -->
        <div class="row mt-4 mb-4">

            <!-- CILINDER CAPACITY INPUT -->
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'tableManagement.versions.displacement' | translate}}
                </label>

                <div class="input-wrapper">
                    <input type="number" id="vehicle-data-settings-name" class="form-control custom-input short-input"
                        [(ngModel)]="entity.cylinderCapacity">
                    <span class="input-symbol-short">cc</span>
                </div>

            </div>

            <!-- CONSUMPTION INPUT -->
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'tableManagement.versions.consumption' | translate}}
                </label>

                <div class="input-wrapper">
                    <input type="number" id="vehicle-data-settings-name"
                        class="form-control custom-input mid-input" [(ngModel)]="entity.consumption">
                    <span class="input-symbol-mid">l/100km</span>
                </div>

            </div>
        </div>

        <!-- EMISSIONS & FUEL CAPACITY -->
        <div class="row mt-4 mb-4">

            <!-- EMISSIONS INPUT -->
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'tableManagement.versions.emissions' | translate}}
                </label>

                <div class="input-wrapper">
                    <input type="number" id="vehicle-data-settings-name" class="form-control custom-input mid-input"
                        [(ngModel)]="entity.emissions">
                    <span class="input-symbol-emissions">g/km</span>
                </div>

            </div>

            <!-- FUEL CAPACITY INPUT -->
            <div class="col-sm-6">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'tableManagement.versions.fuelCapatity' | translate}}
                </label>

                <div class="input-wrapper">
                    <input type="number" id="vehicle-data-settings-name" class="form-control custom-input short-input"
                        [(ngModel)]="entity.fuelCapacity">
                    <span class="input-symbol-short">l</span>
                </div>

            </div>
        </div>

        <!-- FUEL TYPE -->
        <div class="row mt-4 mb-4">

            <!-- FUEL TYPE INPUT -->
            <div class="col-sm-6 ">
                <label for="type" class="form-label custom-label mb-0 me-2" i18n>
                    {{'tableManagement.versions.fuelType' | translate}}
                </label>
                <select id="vehicle-data-settings-field3" [(ngModel)]="entity.fuelType"
                    class="form-control custom-input">
                    <option *ngFor="let option of optionsFuelType" [value]="option.value">{{ option.label }}</option>
                </select>
            </div>
        </div>

        <!-- OBSERVATIONS -->
        <div class="row justify-content-start mt-3">
            <div class="col d-flex flex-row align-items-center">
                <label for="fine-name" class="custom-label" i18n>
                    {{'general.observations' | translate}}
                </label>
                <div class="p-3"></div>
                <textarea rows="2" cols="80" pInputTextarea [pTooltip]="entity.observations" tooltipPosition="top"
                    [(ngModel)]="entity.observations" class="observations" fitContent="true"></textarea>
            </div>
        </div>

        <!-- ROW 4 -->
        <div *ngIf="!isNew">
            <div class="row mt-3">
                <div class="col d-flex align-items-center">
                    <label for="vehicle-data-settings-name" class="custom-label" i18n>
                        {{'general.enabled' | translate}}
                    </label>
                </div>

                <div class="col">
                    <div class="float-end">
                        <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                            type="checkbox" [(ngModel)]="!entity.disabled" [disabled]="true" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>

        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>

                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
            </div>

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(!entity.disabled)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>

        </div>

    </p-footer>
</p-dialog>