import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-power-saving-form-x8",
  templateUrl: "./power-saving-form-x8.component.html",
  styleUrls: ["./power-saving-form-x8.component.css"],
})
export class PowerSavingFormX8Component
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() powerSaving: Rest.PowerSavingX8;

  SleepModeOptions: any = [
    { label: "Mode 0", value: 0 },
    { label: "Mode 1", value: 1 },
    { label: "Mode 2", value: 2 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  onChange() {}
}
