import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-mileage-configuration-we-track2',
  templateUrl: './mileage-configuration-we-track2.component.html',
  styleUrls: ['./mileage-configuration-we-track2.component.css'],
})
export class MileageConfigurationWeTrack2Component extends ServerValidatedFormComponent implements OnInit {

  @Input() mileage: Rest.MileageConfigurationWeTrack2;

  @ViewChild('ContentDiv', { static: true }) ContentDiv;

  constructor(protected i18n: I18nService) {  super(i18n);}
 
  ngOnInit() {
    if(this.mileage!=null){this.disableDIV(!this.mileage.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.mileage);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
