<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Jamming Behavior Setting
          (AT + GTJBS)</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="jbsParameters.mode"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" #JammingBehaviousDiv>
    <!-- boolean -->

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Siren on timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.sirenOnTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535 *100ms</span>
            <!-- This has not a correct UI, should be checked -->
            <!-- <span class="ui-inputgroup-addon" ></span> -->
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Siren off timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.sirenOffTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535 *100ms</span>
            <!-- <span class="ui-inputgroup-addon" ></span> -->
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Ready fuel realese timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.readyFuelRealeseTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>
    
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Check speed">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="jbsParameters.checkSpeed"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Speed Limit">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.speedLimit" [min]="0" [max]="999"></p-spinner>
            <span class="ui-inputgroup-addon">0~999</span>
            <span class="ui-inputgroup-addon">km/h</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Output 1 init state">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="jbsParameters.output1InitState"></p-inputSwitch>
        </div>
      </div>
    
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Motion sensor">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="jbsParameters.motionSensor"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="GPS fix failure timeout timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.gpsFixFailureTimeoutTimer" [min]="1" [max]="100"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon">min</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Enable Siren">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="jbsParameters.enableSiren"></p-inputSwitch>
        </div>
      </div>
    
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Release fuel cut off timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.releaseFuelCutOffTimer" [min]="0" [max]="1000"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon">min</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Check Jamming In T3">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="jbsParameters.checkJamingInT3"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Wiating release fuel timer">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.wiatingReleaseFuelTimer" [min]="1" [max]="100"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon">min</span>
          </div>
        </div>
      </div>
    
    </div>

  </div>
</p-accordionTab>