<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">ALARM CALL NUMBER OF
          TIMES (CALL)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Dialing Times">
      </form-input>
    </div>
    <div class="col">
      <div class="ui-inputgroup">
        <p-spinner size="5" [(ngModel)]="alarmCallNumberTimes.times" [min]="1" [max]="3"></p-spinner>
        <span class="ui-inputgroup-addon">1~3</span>
        <span class="ui-inputgroup-addon">times</span>
      </div>
    </div>
  </div>

</p-accordionTab>