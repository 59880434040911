<p-table [value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
          {{ 'drivers.table.title' | translate }}
        </div>
    </ng-template>
    <!-- #endregion -->

	 <ng-template #filterLoading>
		<div class="d-flex align-items-center h-100" [style]="{'margin-right':'10px'}">
			<mat-spinner
				[diameter]="20"
				strokeWidth="3"
				[color]="primary">
			</mat-spinner>
		</div>
	 </ng-template>

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
      <tr *ngIf="vehicle">

			<!-- NAME -->
         <th pSortableColumn="code">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.table.name' | translate }}
               </div>

               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='code'></p-sortIcon>
						<p-columnFilter field='user'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>

			<!-- START DATE -->
         <th pSortableColumn="startDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					  	{{ 'tableManagement.cardLists.assignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="startDate"></p-sortIcon>
						<p-columnFilter field="startDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- ENDS DATE -->
			<th pSortableColumn="endDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					   {{ 'tableManagement.cardLists.deassignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="endDate"></p-sortIcon>
						<p-columnFilter field="endDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- DIVISION -->
         <th pSortableColumn="division" [pSortableColumnDisabled]="!isDivisionListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.divisions.division' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isDivisionListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="division"></p-sortIcon>
							<p-columnFilter field="division" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="divisionList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

			<!-- SUBDIVISION -->
         <th pSortableColumn="subDivision" [pSortableColumnDisabled]="!isSubdivisionListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.subdivisions.subdivision' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isSubdivisionListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="subDivision"></p-sortIcon>
							<p-columnFilter field="subDivision" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="subdivisionList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

		 <!-- CECO -->
         <th pSortableColumn="ceco" [pSortableColumnDisabled]="!isCecoListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.form.ceco' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isCecoListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="ceco"></p-sortIcon>
							<p-columnFilter field="ceco" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="cecoList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="description" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.description}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

		 <!-- BILLING ACCOUNT -->
         <th pSortableColumn="billingAccount" [pSortableColumnDisabled]="!isBillingAccountListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.form.company' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isBillingAccountListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="billingAccount"></p-sortIcon>
							<p-columnFilter field="billingAccount" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="billingAccountList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

      </tr>
      <tr *ngIf="driver">

			<!-- CAR LICENSE PLATE -->
			<th pSortableColumn="name">
				<div class='d-flex align-items-center h-100'>
					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                  {{ 'control-panel.vehicles.licensePlate' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                	<!-- COLUMN SORT && FILTER ICONS -->
                  	<div class="d-flex align-items-center h-100">
                    	<p-sortIcon field="name"></p-sortIcon>
                  		<p-columnFilter field="name" type="text" matchMode="contains"
									placeholder="{{ 'general.search' | translate }}"
									display="menu"
									[showMatchModes]="false"
									[showOperator]="false"
									[showAddButton]="false">
						</p-columnFilter>
                  	</div>
					</div>
				</div>
			</th>

        <!-- BRAND -->
        <th pSortableColumn="brand">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.brands.brand' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="brand"></p-sortIcon>
							<p-columnFilter field="brand" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="brandList" placeholder="{{ 'general.filters.any' | translate }}" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div class="p-multiselect-brand-option">
												<img *ngIf='option?.logo?.imageEncoded' id='uploadedImage' alt='' [src]="option.logo.imageEncoded" [style]="{'vertical-align':'middle', 'max-height':'32px', 'width':'32px', 'margin-right':'10px'}">
												<img *ngIf='!option?.logo?.imageEncoded' id='uploadedImage' alt='' [src]="assets/icons/no-image-available.svg" [style]="{'vertical-align':'middle', 'max-height':'32px', 'width':'32px', 'margin-right':'10px'}">
												<span class="ml-1">{{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

			<!-- MODEL -->
         <th pSortableColumn="model">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.models.model' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="model"></p-sortIcon>
							<p-columnFilter field="model" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="modelList" placeholder="{{ 'general.filters.any' | translate }}" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div class="p-multiselect-model-option">
												<span class="ml-1">{{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

			<!-- VERSION -->
         <th pSortableColumn="version">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.versions.version' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="version"></p-sortIcon>
							<p-columnFilter field="version" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="versionList" placeholder="{{ 'general.filters.any' | translate }}" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div class="p-multiselect-version-option">
												<span class="ml-1">{{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
                  </div>
					</div>

				</div>
         </th>

			<!-- START DATE -->
         <th pSortableColumn="startDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					  	{{ 'tableManagement.cardLists.assignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="startDate"></p-sortIcon>
						<p-columnFilter field="startDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- ENDS DATE -->
			<th pSortableColumn="endDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					   {{ 'tableManagement.cardLists.deassignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="endDate"></p-sortIcon>
						<p-columnFilter field="endDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

      </tr>
    </ng-template>

   <ng-template pTemplate="body" let-da>
      <tr *ngIf="vehicle" class="p-selectable-row custom-rows">
         <td>({{da.driver?.code}}) {{da.driver?.user?.surname1}} {{da.driver?.user?.surname2}}, {{da.driver?.user?.name}}</td>
         <td>{{da.startDate | date: 'dd/MM/yyyy'}}</td>
			<td>{{da.endDate | date: 'dd/MM/yyyy'}}</td>
			<td *ngIf="da.division; else na">({{da.division.code}}) {{da.division.name}}</td>
			<td *ngIf="da.subDivision; else na">({{da.subDivision.code}}) {{da.subDivision.name}}</td>
			<td *ngIf="da.ceco; else na">({{da.ceco.code}}) {{da.ceco.description}}</td>
			<td *ngIf="da.billingAccount; else na">({{da.billingAccount.code}}) {{da.billingAccount.name}}</td>
      </tr>
      <tr *ngIf="driver" class="p-selectable-row custom-rows">
			<td>{{da.vehicle?.name}}</td>             <!-- matricula -->
			<td>{{!da.vehicle?.model.brand ? '' : da.vehicle?.model.brand.name}}</td>
			<td>{{!da.vehicle?.model ? '' : da.vehicle?.model.name}}</td>
         <td>{{!da.vehicle?.version ? '' : da.vehicle?.version.name}}</td>
         <td>{{da.startDate | date: 'dd/MM/yyyy'}}</td>
			<td>{{da.endDate | date: 'dd/MM/yyyy'}}</td>
		</tr>
   </ng-template>

	<ng-template #na>
		<td>N/A</td>
	</ng-template>

     <!-- TABLE EMPTY MESSAGE -->
   <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="vehicle ? 7 : 8" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td [attr.colspan]="vehicle ? 7 : 8" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">
          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>
          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>


<!-- CHANGE STATUS DIALOG -->
<!-- <div *ngIf="showStatusDialog">
  <app-change-status-shared [items]=" 'drivers.table.drivers' | translate "
                            [display]="true"
                            [service]=driverService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-shared>
</div> -->

<!-- DRIVER INFO CARD -->
<!-- <app-driver-info-card></app-driver-info-card> -->
