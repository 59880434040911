/* eslint-disable prefer-const */
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { ConfirmationService, LazyLoadEvent } from "primeng/api";

import { LoadingService } from "app/services/loading-service/loading.service";
import { MaintenanceService } from "../../rest/maintenance.service";
import { MaintenanceexecutionService } from "../../rest/maintenanceexecution.service";
import { Rest } from "../../rest/rest_client";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityListComponent } from "../entity-list/entity-list.component";
import { MaintenanceExecutionFormComponent } from "../maintenance-execution-form/maintenance-execution-form.component";
import {
    MaintenanceExecutionsListComponent
} from "../maintenance-executions-list/maintenance-executions-list.component";
import { UserTimePipe } from "../shared/UserTimePipe";

@Component({
    selector: "app-maintenances-list",
    templateUrl: "./maintenances-list.component.html",
    styleUrls: ["./maintenances-list.component.css"],
    providers: [
        MaintenanceService,
        MaintenanceexecutionService,
        ConfirmationService,
        UserTimePipe,
    ],
})
export class MaintenancesListComponent
    extends EntityListComponent<Rest.Maintenance>
    implements OnInit, OnChanges {
    // vehicle: Rest.Vehicle;
    selectedExecutions: Rest.MaintenanceExecution[];
    title: string;
    displayDialog: boolean;
    messageWarning: string;
    maintenanceTemp: Rest.Maintenance;

    maintenanceService: MaintenanceService;
    maintenanceExecutionService: MaintenanceexecutionService;

    showStatusDialog = false;

    //datos para compartir con formulario
    selectedMaintenance: Rest.Maintenance;
    selectMaintenancePlan: Rest.MaintenancePlan;

    event: LazyLoadEvent;

    newExecution = false;

    @Input() vehicle: Rest.Vehicle;
    @Input() parentMaintenancePlan: Rest.MaintenancePlan;
    @ViewChild(MaintenanceExecutionFormComponent, {static: true})
    maintenanceExecutionForm: MaintenanceExecutionFormComponent;

    isNew = false;

    execution: Rest.MaintenanceExecution;
    //@ViewChild(MaintenanceFormComponent) maintenanceForm: MaintenanceFormComponent;
    @ViewChild(MaintenanceExecutionsListComponent)
    executionList: MaintenanceExecutionsListComponent;

    /*Permissions */
    hasPermissions: boolean;

    /** */

    constructor(
        maintenanceService: MaintenanceService,
        maintenanceExecutionService: MaintenanceexecutionService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            maintenanceService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.maintenanceService = maintenanceService;
        this.maintenanceExecutionService = maintenanceExecutionService;
    }

    // initialize(vehicle: Rest.Vehicle) {
    //   this.vehicle = vehicle;
    //   this.loadFilters = false;
    //   this.paginationRequest.filters["vehicleID"] = [this.vehicle.id.toString()];
    //   this.refresh();
    // }

    ngOnInit() {
        super.ngOnInit();

        //this.form = this.maintenanceForm;
        this.title = this.i18n._("Maintenance");
        this.selectedExecutions = [];
        this.hasEntityFilters = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["vehicle"] && changes["vehicle"].previousValue !== undefined) {
            if (
                this.vehicle != null &&
                this.vehicle !== undefined &&
                this.vehicle["id"] !== undefined
            ) {
                let event: LazyLoadEvent;
                event = {
                    filters: {},
                    first: 0,
                    globalFilter: null,
                    multiSortMeta: undefined,
                    rows: 10,
                    sortField: undefined,
                    sortOrder: 1,
                };

                //this.ngOnInit();
                this.loadData(event);
            }
        }
    }

    protected beforeLoad() {
        this.paginationRequest.filters["maintenancePlanID"] = [
            this.parentMaintenancePlan.id.toString(),
        ];
        this.paginationRequest.filters["vehicleID"] = [this.vehicle.id.toString()];
        this.loadFilters = false;
    }

    rowClass(rowData) {
        return rowData.HasAnnexes ? "" : "noExpander";
    }

    afterLoad() {
        //var that = this;
        this.paginationResult.entities.forEach((maintenance: any) => {
            //add transitive properites (_$) to object only for render pourposes.
            maintenance._$lastExecutionDate = this.getLastExecutionDate(maintenance);
            maintenance._$nextExecutionDate = this.getNextExecutionDate(maintenance);
            maintenance._$lastExecutionKM = this.getLastExecutionKM(maintenance);
            maintenance._$nextExecutionKM = this.getNextExecutionKM(maintenance);
            maintenance._$status = this.getIconState(maintenance);

            /*Permissions */
            maintenance._$execution = this.hasPermission(
                this.permissionsEntityTypeSelector("execution", "Maintenance"),
                maintenance.id
            );
            /** */
            //sort the executions
            this.sortExecutionsByInstant(-1, maintenance);

            /*Permissions */
            let hasPermissions = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "Maintenance Plan"),
                maintenance.maintenancePlan.id
            );
            maintenance._$changeStatus = hasPermissions;
            maintenance._$update = hasPermissions;
            /** */
        });

        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    // addExecution(maintenance: Rest.Maintenance) {
    //   this.executionList.addExecution(maintenance);
    // }

    addExecution(maintenance: Rest.Maintenance) {
        this.newExecution = true;

        this.maintenanceExecutionForm.create({
            maintenance,
            vehicle: this.vehicle,
        } as Rest.MaintenanceExecution);

        this.maintenanceExecutionForm.readonly = false;

        this.maintenanceExecutionForm.setFormSubscriptor(
            (entity: Rest.MaintenanceExecution) => {
                this.refresh();
            }
        );
    }

    getLastExecutionDate(maintenance: Rest.Maintenance): any {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.TIME_BASED
                ]
        ) {
            return null;
        }

        let lastDate: Date;
        let newHistory = [];

        for (let a in maintenance.history) {
            if (maintenance.history[a].vehicle.id === this.vehicle.id) {
                newHistory.push(maintenance.history[a]);
            }
        }
        lastDate = newHistory
            .map((execution: Rest.MaintenanceExecution) => execution.nextMaintenanceDate)
            .sort(function (a, b) {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            })
            .reverse()[0];

        if (lastDate != null) {
            //return new Date(lastDate);
            return lastDate;
        } else {
            return null;
        }
    }

    getLastExecutionKM(maintenance: Rest.Maintenance): any {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.DISTANCE_BASED
                ]
        ) {
            return null;
        }

        let lastKm: number;
        let newHistory = [];

        for (let a in maintenance.history) {
            if (maintenance.history[a].vehicle.id === this.vehicle.id) {
                newHistory.push(maintenance.history[a]);
            }
        }

        lastKm = newHistory
            .map((execution: Rest.MaintenanceExecution) => execution.nextMaintenanceKms)
            .sort(function (a, b) {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            })
            .reverse()[0];

        if (lastKm != null) {
            return lastKm;
        } else {
            return null;
        }
        //return maintenance.history.map((execution: Rest.MaintenanceExecution) => execution.kmofExecution).sort().reverse()[0];
    }

    // getLastExecutionKM(maintenance: Rest.Maintenance): number {

    //   if (maintenance.periodicityType != RestExt.MaintenancePeriodicityType[RestExt.MaintenancePeriodicityType.DISTANCE_BASED]) { return null; }
    //   return maintenance.history.map((execution: Rest.MaintenanceExecution) => execution.kmofExecution).sort().reverse()[0];

    // }

    getNextExecutionDate(maintenance: Rest.Maintenance): any {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.TIME_BASED
                ]
        ) {
            return null;
        }
        let lastDate: Date;
        lastDate = this.getLastExecutionDate(maintenance);
        let nextDate: Date;
        if (lastDate != null && maintenance.periodicity != null) {
            let periodicityTimeUnitStr: string;
            switch (maintenance.periodicityTimeUnit) {
                case "DAYS":
                    periodicityTimeUnitStr = "d";
                    break;
                case "YEARS":
                    periodicityTimeUnitStr = "y";
                    break;
                case "MONTHS":
                    periodicityTimeUnitStr = "m";
                    break;
            }
            return moment(lastDate)
                .add(maintenance.periodicity, periodicityTimeUnitStr as any)
                .toDate();
        } else {
            return null;
        }
    }

    getNextExecutionKM(maintenance: Rest.Maintenance): any {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.DISTANCE_BASED
                ]
        ) {
            return null;
        }
        if (maintenance.periodicity != null) {
            const lastKm = this.getLastExecutionKM(maintenance);
            if (lastKm != null) {
                return lastKm + maintenance.periodicity;
            }
        }
        return null;
    }

    getNextNotificationDate(maintenance: Rest.Maintenance): Date {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.TIME_BASED
                ]
        ) {
            return null;
        }
        const nextExecutionDate = this.getNextExecutionDate(maintenance);
        if (maintenance.notifyBefore != null) {
            return moment(nextExecutionDate)
                .add(maintenance.notifyBefore * -1, "d")
                .toDate();
        }
        return null;
    }

    sortExecutionsByInstant(order: number, maintenance: Rest.Maintenance) {
        const comparer = function (
            a: Rest.MaintenanceExecution,
            b: Rest.MaintenanceExecution
        ): number {
            let result = -1;
            if (a.maintenance.periodicityType === "TIME_BASED") {
                if (a.nextMaintenanceDate > b.nextMaintenanceDate) {
                    result = 1;
                }
            } else {
                if (a.nextMaintenanceKms > b.nextMaintenanceKms) {
                    result = 1;
                }
            }
            return result * order;
        };
        maintenance.history.sort(comparer);
        //Substitute the entire history array by itself. Necessary for angular to detect the change and fire the re-drawing
        maintenance.history = [...maintenance.history];
    }

    getNextNotificationKM(maintenance: Rest.Maintenance): number {
        if (
            maintenance.periodicityType !==
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.DISTANCE_BASED
                ]
        ) {
            return null;
        }
        const nextExecutionKM = this.getNextExecutionKM(maintenance);
        if (maintenance.notifyBefore != null) {
            return nextExecutionKM - maintenance.notifyBefore;
        }
        return null;
    }

    getIconState(maintenance: Rest.Maintenance): string {
        const today = new Date();

        if (
            maintenance.periodicityType ===
            RestExt.MaintenancePeriodicityType[
                RestExt.MaintenancePeriodicityType.TIME_BASED
                ] &&
            maintenance.history.length > 0
        ) {
            if (today > this.getNextExecutionDate(maintenance)) {
                return "Red";
            }
            if (
                today < this.getNextExecutionDate(maintenance) &&
                today >= this.getNextNotificationDate(maintenance)
            ) {
                return "Orange";
            }
            if (today < this.getNextNotificationDate(maintenance)) {
                return "Green";
            }
        }
        if (
            (maintenance.periodicityType ===
                RestExt.MaintenancePeriodicityType[
                    RestExt.MaintenancePeriodicityType.DISTANCE_BASED
                    ]) !=
            null &&
            maintenance.history.length > 0
        ) {
            if (this.vehicle.km > this.getNextExecutionKM(maintenance)) {
                return "Red";
            }
            if (
                this.vehicle.km < this.getNextExecutionKM(maintenance) &&
                this.vehicle.km >= this.getNextNotificationKM(maintenance)
            ) {
                return "Orange";
            }
            if (this.vehicle.km < this.getNextNotificationKM(maintenance)) {
                return "Green";
            }
        }

        return "Red";
    }

    containsObject(obj, list) {
        let i;
        for (i = 0 ; i < list.length ; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }

    notification(event) {
        this.selectedExecutions = [];
        if (this.newExecution) {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._("Execution saved")
            );
        } else {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._("Execution updated")
            );
        }

        this.newExecution = false;
    }

    // delete() {
    //   if (this.selectedExecutions.length > 0) {
    //     const that = this;
    //     this.confirmationService.confirm({
    //       message: that.i18n._('Are you sure you want to delete these entities?'),
    //       accept: () => {
    //         const l = [];
    //         for (const e in this.selectedExecutions) {
    //           l.push({ id: this.selectedExecutions[e]["id"] });
    //         }

    //         this.maintenanceExecutionService.delete(l).then(function (ret) {
    //           that.notificationsService.add(Severity.success, that.i18n._(':)'), that.i18n._('Entities deleted'));
    //           that.selectedExecutions = [];
    //           that.refresh();
    //         });
    //       },
    //     });
    //   }
    // }

    notifyChildFetch() {
        this.refresh();
    }
}
