<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">DELAY VOICE MONITOR
          (DELAY)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Delay">
      </form-input>
    </div>
    <div class="col">
      <div class="ui-inputgroup">
        <p-spinner size="5" [(ngModel)]="delay.time" [min]="5" [max]="18"></p-spinner>
        <span class="ui-inputgroup-addon">5~18</span>
        <span class="ui-inputgroup-addon">s</span>
      </div>
    </div>
  </div>
</p-accordionTab>