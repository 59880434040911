import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { JQueryHTTPClient } from "./JQueryHTTPClient";
import { Rest } from "./rest_client";

@Injectable()
export class PoiService extends EntityService<Rest.POI> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.POI): Rest.RestResponse<Rest.POI> {
    return this.client.POI_create(arg0);
  }

  update(arg0: Rest.POI): Rest.RestResponse<Rest.POI> {
    return this.client.POI_update(arg0);
  }

  createByList(arg0: Rest.POI[]): Rest.RestResponse<Rest.POI[]> {
    return this.client.POI_createByList(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.Page<Rest.POI>> {
    return this.client.POI_getPage(arg0);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.POI>> {
    return this.client.POI_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.POI_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.POI> {
    return this.client.POI_find(arg0, queryParams);
  }

  
  getPageTask(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.Page<Rest.POI>> {
    return this.client.POI_getPageTask(arg0);
  }

  getPageTaskRoute(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.Page<Rest.POI>> {
    return this.client.POI_getPageTaskRoute(arg0);
  }

  
}
