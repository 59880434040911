<!-- ROLES PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.users.rolesTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- DESCRIPTION -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.description' | translate }}
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-role>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(role)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="role"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{role.name}}
      </td>

      <!-- DESCRIPTION -->
      <td>
        {{role.description}}
      </td>

    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="4" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD ROLE BUTTON -->
          <app-table-add-button [text]=" 'control-panel.users.role' | translate "
                                (btnClick)="create()"
                                [isDisabled]="!user.canManageUsers"
          ></app-table-add-button>

          <!-- DELETE ROLE BUTTON -->
          <app-table-delete-button (btnClick)="delete()"
                                   [isDisabled]="!user.canManageUsers || selectedEntities.length === 0"
          ></app-table-delete-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CONFIRMATION DIALOG -->
<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle"></p-confirmDialog>

<!-- ROLE FORM-->
<app-role-form></app-role-form>
