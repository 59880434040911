import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Auxiliar } from '../../../model/Auxiliar';
import { ClientService } from '../../../rest/client.service';
import { ProfileService } from '../../../rest/profile.service';
import { Rest } from '../../../rest/rest_client';
import { RoleService } from '../../../rest/role.service';
import { UserService } from '../../../rest/user.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { EntityFormComponent } from '../../entity-form/entity-form.component';
import { UserTimePipe } from '../../shared/UserTimePipe';
import { SATService } from 'app/modules/sat/services/sat.service';
import { DeviceService } from '../../../rest/device.service';
import { TerminalService } from '../../../rest/terminal.service';
import User = Rest.User;
import Device = Rest.Device;
import Terminal = Rest.Terminal;
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.css'],
	providers: [ConfirmationService, RoleService, ProfileService, ClientService, SATService, DeviceService],
})
export class UserFormComponent extends EntityFormComponent<Rest.User> implements OnChanges, OnInit {
	@Output() deviceSelected = new EventEmitter<Device>();

	userFormTitle: string;
	availableUserLanguage: SelectItem[];
	availableDashboards: SelectItem[] = []; //informarem dinámicament srgons el client
	//      { value: 'COEXPanel', label: this.translate.instant('header.COEXPanel')},
	//      { value: 'rt', label: this.translate.instant('header.rt')},
	//      { value: 'sat', label:this.translate.instant('header.sat')},
	//      { value: 'reports', label:this.translate.instant('header.reports')},
	//      { value: 'control-panel', label:this.translate.instant('header.settings')},
	//      { value: 'racing', label:this.translate.instant('header.racing')},
	//      { value: 'tax', label:this.translate.instant('header.tax')},
	//      { value: 'inventoryVehicles', label:this.translate.instant('header.inventoryVehicles')},
	//      { value: 'drivers', label:this.translate.instant('header.drivers')},
	//      { value: 'maintenance', label:this.translate.instant('header.maintenance')},
	//      { value: 'tableManagement', label:this.translate.instant('header.tableManagement')},
	//      { value: 'dashboards', label:this.translate.instant('header.dashboards')},
	//      { value: 'BOLogistics', label:this.translate.instant('header.BOLogistics')},    //raquel
	//      { value: 'AppLogistics', label:this.translate.instant('header.AppLogistics')},    //raquel
	//  ];
	availableCheckBox: { tag: string; ngModel: boolean; dash: String }[] = [];
	leftCheckBox: { tag: string; ngModel: boolean }[] = [];
	rightCheckBox: { tag: string; ngModel: boolean }[] = [];

	availableUserMetric: SelectItem[];
	availableClients: SelectItem[];

	selectedProfile: Rest.Profile;
	selectedRole: Rest.Role;

	profilePaginationRequest: Rest.ListPaginationRequest;
	rolePaginationRequest: Rest.ListPaginationRequest;

	//Information about the user logged in
	user: Rest.User;
	accountExpiry: Date;
	showStatusDialog: any;
	title: any;
	// TODO: Field "selectedEntities" was missing from legacy code!!!
	selectedEntities: any;
	credits: Rest.CreditsUserSAT;
	addCredits: number = 0;

	devices: Device[];
	terminals: Terminal[];

	constructor(
		public userService: UserService,
		private authService: AuthenticationService,
		public notificationsService: NotificationsService,
		public roleService: RoleService,
		public profileService: ProfileService,
		private clientService: ClientService,
		protected i18n: I18nService,
		private userTime: UserTimePipe,
		private satService: SATService,
		private deviceService: DeviceService,
		private terminalService: TerminalService,
		private translate: TranslateService
	) {
		super(userService, notificationsService, i18n, authService);

		this.availableUserLanguage = RestExt.getEnumItemsNoTranslation(RestExt.LanguageEnum);
		this.availableUserMetric = RestExt.getEnumItems(RestExt.MetricEnum, this.i18n);
	}

	ngOnInit() {
		super.ngOnInit();
		this.user = {} as Rest.User;
		//this.credits = {} as Rest.CreditsUserSAT;
		//this.availableClients = [];
	}

	/**
	 * Cada vez que un @Input modifica su valor (desde el exterior), se ejecuta este método.
	 * Lo aprovechamos para reconfigurar las opciones del dialogo y/o mapa
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges) {}

	beforeShow() {
		// eslint-disable-next-line prefer-const
		let that = this;
		this.profilePaginationRequest = RestExt.firstPageRequest();
		this.profilePaginationRequest.filters = {};
		this.rolePaginationRequest = RestExt.firstPageRequest();
		this.rolePaginationRequest.filters = {};
		this.selectedProfile = null;
		this.selectedRole = null;
		this.user = this.authService.user;
		let paginationRequest: Rest.ListPaginationRequest;
		paginationRequest = RestExt.allRecordsRequest();
		paginationRequest.sortAsc = false;
		paginationRequest.filters = {
			enabled: ['true'],
		};
		if (this.isNew) {
			this.userFormTitle = this.i18n._('Create User');
			this.entity.rolesProfiles = [];
			this.clientService.getPage(paginationRequest).then(function (response) {
				that.availableClients = Auxiliar.toEntitySelectItemArr(response.entities);
			});
		} else {
			paginationRequest.context = {
				entity: 'USER',
				action: 'EDIT',
				id: that.entity.id,
			};
			this.clientService.getPage(paginationRequest).then(function (response) {
				that.availableClients = Auxiliar.toEntitySelectItemArr(response.entities);

				if (that.entity.client != null) {
					that.entity.client = response.entities.find((b) => b.id === that.entity.client.id);
				}
			});
			this.accountExpiry = this.userTime.utcToCalendarDate(this.entity.accountExpiry);
			this.userFormTitle = this.i18n._('Edit User');

			if (this.user.isAdmin || this.user.canSeeSAT) {
				this.satService.getCreditsUser(that.entity.id).then(function (response) {
					that.credits = response;
				});
			}

			/* Get the Devices accessible by User */
			this.deviceService.getDevicesByUser(this.entity.id).then((devices) => {
				this.devices = devices;
			});

			/* Get terminals registered to User */
			this.terminalService.getTerminals(this.entity.id).then((terminals) => {
				this.terminals = terminals;
			});
		}

		/***********************************************
		 * DASHBOARD y CHECKBOX dinàmics segons client *
		 ***********************************************/
		this.availableDashboards = [];
		this.availableCheckBox = [];
		this.leftCheckBox = [];
		this.rightCheckBox = [];
		if (this.authService.user.client.id === 638340 || this.authService.user.client.id === 394971) {
			this.availableCheckBox = [
				{ tag: 'canSeeInventoryVehicles', ngModel: this.entity.canSeeInventoryVehicles, dash: 'inventoryVehicles' },
				{ tag: 'canSeeDrivers', ngModel: this.entity.canSeeDrivers, dash: 'drivers' },
				{ tag: 'canSeeTableManagement', ngModel: this.entity.canSeeTableManagement, dash: 'tableManagement' },
				{ tag: 'canSeeInventoryReport', ngModel: this.entity.canSeeInventoryReport, dash: null },
			];
		} else {
			this.availableCheckBox = [
				{ tag: 'canSeeUTERondesDashboard', ngModel: this.entity.canSeeUTERondesDashboard, dash: 'COEXPanel' },
				{ tag: 'canSeeReports', ngModel: this.entity.canSeeReports, dash: 'reports' },
				{ tag: 'canSeeRTDashboard', ngModel: this.entity.canSeeRTDashboard, dash: 'rt' },
				{ tag: 'canSeeSAT', ngModel: this.entity.canSeeSAT, dash: 'sat' },
				{ tag: 'canSeeRaceEvents', ngModel: this.entity.canSeeRaceEvents, dash: 'racing' },
				{ tag: 'canSeeTax', ngModel: this.entity.canSeeTax, dash: 'tax' },
				{ tag: 'canSeeInventoryVehicles', ngModel: this.entity.canSeeInventoryVehicles, dash: 'inventoryVehicles' },
				{ tag: 'canSeeDrivers', ngModel: this.entity.canSeeDrivers, dash: 'drivers' },
				{ tag: 'canSeeMaintenance', ngModel: this.entity.canSeeMaintenance, dash: 'maintenance' },
				{ tag: 'canSeeTableManagement', ngModel: this.entity.canSeeTableManagement, dash: 'tableManagement' },
				{ tag: 'canSeeDashboards', ngModel: this.entity.canSeeDashboards, dash: 'dashboards' },
				// { tag: 'canSeeBologistics', ngModel: this.entity.canSeeBologistics, dash: 'BOLogistics' },  [2024.05.14] la Raquel diu que ho tregui
				// { tag: 'canSeeApplogistics', ngModel: this.entity.canSeeApplogistics, dash: 'AppLogistics' },  [2024.05.14] la Raquel diu que ho tregui
				{ tag: 'canSeeInventoryReport', ngModel: this.entity.canSeeInventoryReport, dash: null },
				{ tag: 'canSeeTaco', ngModel: this.entity.canSeeTaco, dash: null },
			];
		}
		let contador = 0;
		this.availableCheckBox.forEach((x) => {
			if (contador % 2 === 0) this.leftCheckBox.push(x);
			else this.rightCheckBox.push(x);
			contador++;
			if (x.dash)
				this.availableDashboards.push({
					value: x.dash,
					label: this.translate.instant('header.' + x.dash),
				});
		});
	}

	selectedProfileChanged($event: any) {
		delete this.rolePaginationRequest.filters['profile'];
		if (this.selectedProfile != null) {
			this.rolePaginationRequest.filters['profile'] = [this.selectedProfile.id + ''];
		}
	}

	addPair() {
		this.entity.rolesProfiles.push({
			role: this.selectedRole,
			profile: this.selectedProfile,
		} as Rest.RoleProfilePair);
		this.entity.rolesProfiles = [...this.entity.rolesProfiles];
		this.selectedRole = null;
		this.selectedProfile = null;
	}

	selectedRoleChanged() {
		delete this.profilePaginationRequest.filters['role'];
		if (this.selectedRole != null) {
			this.profilePaginationRequest.filters['role'] = [this.selectedRole.id + ''];
		}
	}

	removePair(roleProfilePair: Rest.RoleProfilePair) {
		this.entity.rolesProfiles.splice(this.entity.rolesProfiles.indexOf(roleProfilePair), 1);
		this.entity.rolesProfiles = [...this.entity.rolesProfiles];
	}

	beforeSave() {
		this.entity.accountExpiry = this.userTime.calendarDateToUTC(this.accountExpiry);
		this.availableCheckBox.forEach( x => {
			if (x.tag === 'canSeeUTERondesDashboard') this.entity.canSeeUTERondesDashboard = x.ngModel;
			if (x.tag === 'canSeeReports') this.entity.canSeeReports = x.ngModel;
			if (x.tag === 'canSeeRTDashboard') this.entity.canSeeRTDashboard = x.ngModel;
			if (x.tag === 'canSeeSAT') this.entity.canSeeSAT = x.ngModel;
			if (x.tag === 'canSeeRaceEvents') this.entity.canSeeRaceEvents = x.ngModel;
			if (x.tag === 'canSeeTax') this.entity.canSeeTax = x.ngModel;
			if (x.tag === 'canSeeInventoryVehicles') this.entity.canSeeInventoryVehicles = x.ngModel;
			if (x.tag === 'canSeeDrivers') this.entity.canSeeDrivers = x.ngModel;
			if (x.tag === 'canSeeMaintenance') this.entity.canSeeMaintenance = x.ngModel;
			if (x.tag === 'canSeeTableManagement') this.entity.canSeeTableManagement = x.ngModel;
			if (x.tag === 'canSeeDashboards') this.entity.canSeeDashboards = x.ngModel;
			if (x.tag === 'canSeeInventoryReport') this.entity.canSeeInventoryReport = x.ngModel;
			if (x.tag === 'canSeeTaco') this.entity.canSeeTaco = x.ngModel;
		});
	}

	onHide(event) {}

	beforeCreate() {}

	closeStatusDialog($event: any) {
		// TODO: Implement!
	}

	refreshForm($event: any) {
		// TODO: Implement!
	}

	saveCredits() {
		var that = this;
		if (this.addCredits !== undefined && this.addCredits !== null && this.addCredits > 0) {
			let creditsHistory = <Rest.CreditsHistory>{};
			if (this.credits.currentCredits == null) {
				creditsHistory.costEmail = 0.1;
				creditsHistory.costSMS = 1;
				creditsHistory.creditsBeforePurchase = 0;
				creditsHistory.credits = this.addCredits;
				creditsHistory.user = that.entity;
				creditsHistory.actionUser = that.user;
				creditsHistory.creditPrice = 0.2;
				creditsHistory.import = creditsHistory.credits * creditsHistory.creditPrice;
			} else {
				if (this.credits.historicCredits.length > 0) {
					creditsHistory = this.credits.historicCredits[this.credits.historicCredits.length - 1];
				} else {
					creditsHistory.creditPrice = this.credits.currentCredits.creditPrice;
					creditsHistory.costEmail = this.credits.currentCredits.costEmail;
					creditsHistory.costSMS = this.credits.currentCredits.costSMS;
				}

				creditsHistory.id = 0;
				creditsHistory.credits = this.addCredits;
				creditsHistory.user = that.entity;
				creditsHistory.actionUser = that.user;
				creditsHistory.creditsBeforePurchase = this.credits.currentCredits.credits;
				creditsHistory.import = this.addCredits * creditsHistory.creditPrice;
			}

			this.satService.updateCreditsUser(creditsHistory).then(function (response) {
				that.credits = response;
				that.addCredits = 0;
			});
		}
	}

	/**
	 * showDeviceFormComponent()
	 * */
	public showDeviceFormComponent(device: Device): void {
		this.deviceSelected.emit(device);
		this.display = false;
	}

	/**
	 * showForm()
	 * */
	public showForm(user: User): void {
		/*user.id.toString()*/

		/* Retrieve User's info from the server */
		this.userService
			.find(user.id.toString())
			.then((response: User) => {
				/*Permissions */
				response['_$update'] = true;
				response['_$changeStatus'] = true;

				/* Update user */
				this.update(response);
			})
			.catch((error) => {
				this.handleError(error, this);
			});
	}
}
