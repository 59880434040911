<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible'
          header="{{ 'promotedRoutes.targetManagment' | translate }}"
          [(visible)]='isDialogVisible'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [style]="{width: '40vw'}"
>
   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

	<!-- GENERAL PANEL -->
	<!-- <p-panel header="{{ 'promotedRoutes.translationsTitle' | translate }}"
		[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header> -->

		<!-- SECCION 1 -->
		<div class='d-flex flex-column gap-3 p-3'>

			<!-- ROW 1: name -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='true' fieldName="{{ 'general.name' | translate }}" [errors]='errors.name'>
					<input pInputText [(ngModel)]='target.name' [style]="{'width':'350px'}"/>
				</form-input>
			</div>
			<!-- ROW 1: Description -->
			<div class='d-flex row-cols-2 w-100'>
				<form-input [required]='false' fieldName="{{ 'general.description' | translate }}" [errors]='errors.name'>
					<textarea pInputTextarea id="description" [(ngModel)]='target.description' [rows]="3" [style]="{'width':'350px'}" ></textarea>
				</form-input>
			</div>

		</div>

	<!-- </p-panel> -->

	<!-- PIE DE PAGINA -->
   <p-footer>
      <div class='d-flex flex-row-reverse bd-highlight'>
         <div class='p-2 bd-highlight'>
            <button type='button' class='btn custom-button' (click)='onSaveTarget()' [disabled]='isEditingDisabled'>
               OK
            </button>
         </div>
      </div>
   </p-footer>
</p-dialog>
