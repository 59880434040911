import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { MaintenanceService } from "../../rest/maintenance.service";
import { Rest } from "../../rest/rest_client";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityFormComponent } from "../entity-form/entity-form.component";
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: "app-maintenance-form",
    templateUrl: "./maintenance-form.component.html",
    styleUrls: ["./maintenance-form.component.css"],
    providers: [MaintenanceService],
})
export class MaintenanceFormComponent
    extends EntityFormComponent<Rest.Maintenance>
    implements OnInit {
    title: String;
    periodicityTimeUnitOptions: SelectItem[];

    notifyBeforeTimeString: string = '';

    // [selectedMaintenancePlan]="selectedMaintenancePlan" [selectedMaintenance]="selectedMaintenance"
    /*
    @Input() selectedMaintenancePlan: Rest.MaintenancePlan;
    @Input() selectedMaintenance: Rest.Maintenance;
  */
    constructor(
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private maintenanceService: MaintenanceService,
        public translateService: TranslateService,
        authenticationService: AuthenticationService
    ) {
        super(
            maintenanceService,
            notificationsService,
            i18n,
            authenticationService
        );

        this.periodicityTimeUnitOptions = RestExt.getEnumItems(
            RestExt.PeriodicityEnum,
            this.i18n
        );
    }

    ngOnInit() {
        super.ngOnInit();

        this.notifyBeforeTimeString = this.translateService.instant('control-panel.maintenance-plans.days');
    }

    onTimeUnitChange(event) {
        console.log("+++ onTimeUnitChange", event);
        this.notifyBeforeTimeString = '';

        if (event.value == "DAYS") {
            this.notifyBeforeTimeString = this.translateService.instant('control-panel.maintenance-plans.days');
        } else if (event.value == "MONTHS") {
            this.notifyBeforeTimeString = this.translateService.instant('control-panel.maintenance-plans.months');
        } else if (event.value == "YEARS") {
            this.notifyBeforeTimeString = this.translateService.instant('control-panel.maintenance-plans.years');
        }
    }

    beforeSave() {
        if (this.entity.periodicityTimeUnit == undefined) {
            this.entity.periodicityTimeUnit = "DAYS";
        }
    }

    beforeShow() {
        if (this.isNew) {
            this.title = this.i18n._("Create Maintenance Plan");
        } else {
            this.title = this.i18n._("Edit Mantenance Plan");
        }
    }
}
