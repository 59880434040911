<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.vehicleStatus.table.title' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>

            <!-- SELECT -->
            <th class="checkbox-column">
                <div class="d-flex justify-content-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
            </th>

            <!-- NAME -->
            <th pSortableColumn='name'>
                <div class='d-flex align-items-center h-100'>

                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.vehicleStatus.table.name' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='name'></p-sortIcon>
                        <p-columnFilter field='name' type='text' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
                            [showOperator]='false' [showAddButton]='false'></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- COLOR -->
            <th>
                <div class='d-flex align-items-center h-100'>

                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.vehicleStatus.table.color' | translate }}
                    </div>

                </div>
            </th>

            <!-- ACTIVE -->
            <th>
                <div class='d-flex align-items-center h-100'>

                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'general.active' | translate }}
                    </div>

                </div>
            </th>

        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-vehicleStatus>
        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(vehicleStatus)">

            <!-- SELECT -->
            <td>
                <div class="d-flex justify-content-center">
                    <p-tableCheckbox [value]="vehicleStatus"></p-tableCheckbox>
                </div>
            </td>

            <!-- NAME -->
            <td>
                {{ vehicleStatus.name }}
            </td>

            <!-- COLOR -->
            <td [ngStyle]="{ 'background-color': vehicleStatus.color }">
            </td>

            <!-- ACTIVE -->
            <td>
                <div class="d-flex justify-content-center align-items-center">
                    <i class="pi"
                        [ngClass]="{'true-icon pi-check-circle': !vehicleStatus.disabled, 'false-icon pi-times-circle': vehicleStatus.disabled}"></i>
                </div>
            </td>
        </tr>

    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="4" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">

                    <!-- ADD CARDTYPE BUTTON -->
                    <app-table-add-button [text]=" 'tableManagement.vehicleStatus.vehicleStatus' | translate"
                        (btnClick)="create()"></app-table-add-button>

                    <!-- CHANGE STATUS BUTTON -->
                    <app-table-change-status-button (btnClick)="changeStatus()"
                        [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>
                    
                    <!-- EXCEL BUTTON -->
                    <app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">

    <app-change-status-shared [items]=" 'control-panel.status-dialog.maintenancePlans' | translate " [display]="true"
        [service]=vehicleStatusService [entitiesList]=selectedEntities (return)="refreshList($event)"
        (close)="closeStatusDialog($event)"></app-change-status-shared>

</div>

<!-- CARD TYPE FORM -->
<app-vehicle-status></app-vehicle-status>