<p-accordionTab>
  <p-header>
      <div class="ui-helper-clearfix">
          <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Analog Input Configuration</span>
          </div>
          <div class="right">
              <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="analogInputSwitch"></p-inputSwitch>
          </div>
      </div>
  </p-header>

  <div class="ui-g" #analoginput>
      <div class="ui-g-12 ui-md-6">
          <input pInputText hidden="true" id="id" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.id" type="text" />
          <form-input i18n-fieldName fieldName="mode" [errors]="errors.analogInputMode">
              <p-dropdown id="analogmode" (onChange)="analogInputModeChange($event)" [options]="availableanalogInputModes" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.mode"
                  [style]="{'width':'300px'}"></p-dropdown>
          </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
          <div class="ui-g-4">
          </div>
          <div class="ui-g-8">
          </div>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="action" [errors]="errors.action">
              <p-dropdown id="action" [options]="availableanalogAction" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.action" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>

      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="analog port" [errors]="errors.analogPort">
              <p-inputSwitch [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.analogPort"></p-inputSwitch>
          </form-input>

      </div>

      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="minimum voltage level" [errors]="errors.minimumVoltageLevel">
              <div class="ui-inputgroup">
                  <p-spinner step="0.01" id="minimumVoltageLevel" size="7" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.minimumVoltageLevel"
                      [min]="0" [max]="30"></p-spinner>
                  <span class="ui-inputgroup-addon">0.00~30.00</span>
                  <span class="ui-inputgroup-addon" i18n>volts</span>
              </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="maximum voltage level" [errors]="errors.maximumVoltageLevel">
              <div class="ui-inputgroup">
                  <p-spinner step="0.01" id="maximumVoltageLevel" size="7" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.maximumVoltageLevel"
                      [min]="0" [max]="30"></p-spinner>
                  <span class="ui-inputgroup-addon">0.00~30.00</span>
                  <span class="ui-inputgroup-addon" i18n>volts</span>
              </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="duration" [errors]="errors.duration">
              <div class="ui-inputgroup">
                  <p-spinner thousandSeparator="." id="analogInputDuration" size="7" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.duration"
                      [min]="0" [max]="65535"></p-spinner>
                  <span class="ui-inputgroup-addon">0~65535</span>
                  <span class="ui-inputgroup-addon" i18n>seconds</span>
              </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="output control" [errors]="errors.analogOutputControl">
              <p-inputSwitch id="analogOutputControl" (onChange)="analogInputOutputControlChange($event)" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.outputControl"></p-inputSwitch>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="output port" [errors]="errors.analogOutputPort">
              <p-dropdown id="outputPort" [disabled]="!deviceParams.proprietaryParameters.analogInputFunction.outputControl" (onChange)="analogInputOutputPortChange($event)"
                  [options]="availableanalogOutputPort" [(ngModel)]="deviceParams.proprietaryParameters.analogInputFunction.outputPort"
                  [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
      </div>
  </div>

</p-accordionTab>