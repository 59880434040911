import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-gps-assisted-motion-measurement-gl320m',
  templateUrl: './gps-assisted-motion-measurement-gl320m.component.html',
  styleUrls: ['./gps-assisted-motion-measurement-gl320m.component.css']
})
export class GpsAssistedMotionMeasurementGl320mComponent extends ServerValidatedFormComponent implements OnInit {

  modeOptions: any = [
    { label: "Disable this function", value: "DISABLE" },
    { label: "Enable this function", value: "ENABLE" },
  ];

  speedOptions: any = [
    { label: "Disable the function", value: "DISABLE" },
    { label: "Enable the function", value: "ENABLE" },
  ];
  
  @Input() gamParameters: Rest.GPSAssistedMotionMeasurementGL320M;
  


  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.gamParameters);
    }
  }
}
