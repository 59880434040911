import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-mileage-configuration-form-x8",
  templateUrl: "./mileage-configuration-form-x8.component.html",
  styleUrls: ["./mileage-configuration-form-x8.component.css"],
})
export class MileageConfigurationFormX8Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() mileage: Rest.MileageConfigurationX8;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
}
