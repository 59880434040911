<p-dialog
	header="{{ title }}"
	[(visible)]="display"
	[responsive]="true"
	showEffect="fade"
	[modal]="true"
	*ngIf="display"
	appendTo="body"
>
	<div class="container-md">
		<!-- FORM ERRORS -->
		<div #errorContainer>
			<div *ngIf="errorMessages.length !== 0">
				<div *ngFor="let error of errorMessages">
					<div class="alert alert-warning" role="alert">{{ error }}</div>
				</div>
			</div>
		</div>

		<!-- NAME -->
		<div class="row">
			<div class="col d-flex align-items-center">
				<label for="vehicle-data-settings-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.vehicleStatus.form.name' | translate }} (*)</strong>
				</label>
			</div>

			<div class="col">
				<div class="float-end">
					<input
						type="text"
						id="vehicle-data-settings-name"
						class="form-control custom-input"
						[(ngModel)]="entity.name"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>

		<!-- COLOR -->
		<div class="row mt-3">
			<div class="col d-flex align-items-center">
				<label for="vehicle-data-settings-name" class="custom-label" i18n>
					<strong>{{ 'tableManagement.vehicleStatus.form.color' | translate }} (*)</strong>
				</label>
			</div>

			<div class="col">
				<div class="float-end">
					<p-colorPicker [(ngModel)]="entity.color" [inline]="true" [disabled]="disableSave"></p-colorPicker>
				</div>
			</div>
		</div>

		<!-- ACTIVE -->
		<div *ngIf="!isNew">
			<div class="row mt-3">
				<div class="col d-flex align-items-center">
					<label for="vehicle-data-settings-name" class="custom-label" i18n>
						{{ 'general.enabled' | translate }}
					</label>
				</div>

				<div class="col">
					<div class="float-end">
						<input
							class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
							type="checkbox"
							[(ngModel)]="active"
							[disabled]="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

	<p-footer>
		<div class="d-flex justify-content-center bd-highlight">
			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="disableSave">
						{{ disableSave ? ('general.saving' | translate) : ('general.save' | translate) }}
					</button>
				</div>

				<div *ngIf="isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="disableSave">
						{{ disableSave ? ('general.saving' | translate) : ('general.create' | translate) }}
					</button>
				</div>
			</div>

			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="onChangeStatus(!entity.disabled)" i18n [disabled]="disableSave">
						{{ 'general.changeStatus' | translate }}
					</button>
				</div>
			</div>
		</div>
	</p-footer>
</p-dialog>
