import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { UteService } from "app/rest/ute.service";
import { ExcelService } from "app/services/excel-service/excel.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-ute-report-events",
  templateUrl: "./ute-report-events.component.html",
  styleUrls: ["./ute-report-events.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [UteService, ExcelService, DatePipe],
})
export class UteReportEventsComponent implements OnInit {
  rows: any[] = [];
  rowsVisibles: any[] = [];

  openEventsByDates = false;
  dayIniSelected: Date;
  dayEndSelected: Date;

  @Input() vehiclesIds: number[] = [];
  @Input() rol: String;

  sortField: string;
  loading = false;
  sortOrder: number; // = 1;
  selectedDay: Date;

  rowsExcel: any[];
  rowObject: any;
  expandedHeaders = false;

  cols: any[];

  constructor(
    public i18n: I18nService,
    private uteService: UteService,
    private excelService: ExcelService,
    private dp: DatePipe
  ) {}

  async show() {
    this.rows = [];
    this.rowsVisibles = [];
    this.dayIniSelected = new Date(Date.now());
    this.dayEndSelected = new Date(Date.now());
    this.openEventsByDates = true;
    let start = null;
    let end = null;
    let limit = 100;
    let idPoint = null;
    this.cols = [
      //TODO: uncomment
      {
        header: "Punt de control",
        shortHeader: "Punt Control",
        field: "controlPoint",
        visible: true,
        filter: false,
      },
      {
        header: "Data última vigilancia",
        shortHeader: "Data últim v",
        field: "lastVigilanceDate",
        visible: (this.rol === "Vigilance" || this.rol === "VigilanceAndMaintenance"),
        filter: false,
      },
      {
        header: "Data últim manteniment",
        shortHeader: "Data últim m",
        field: "lastMaintenanceDate",
        visible: (this.rol === "Maintenance" || this.rol === "VigilanceAndMaintenance"),
        filter: false,
      },
      {
        header: "Vehicle",
        shortHeader: "Vehicle",
        field: "vehicle",
        visible: true,
        filter: false,
      },
    ];
    this.loadReportsData();
  }

  ngOnInit() {
    
  }

  async loadReportsData() {

    
    let limit = null;
    let start = null;
    let end = null;
    this.rowsVisibles = [];
    this.rows = [];

    start = this.dayIniSelected;
    start.setHours(0, 0, 0, 0);
    end = this.dayEndSelected;
    end.setHours(23, 59, 59, 0);

    this.loading = true;
    this.rows = await this.uteService.getUTERondesHistoryRecords(
      this.rol,
      start ? start.getTime() : null,
      end ? end.getTime() : null,
      this.vehiclesIds
    );
    this.filterRowsDate();
    this.loading = false;
    this.sortField = "lastVigilanceDate";
    this.sortRows();
  }

  public sortRows(): void {
    if (this.sortField == null) {
      return;
    }
    if (this.rowsVisibles.length > 0) {
      if (this.sortField == "controlPoint") {
        this.rowsVisibles.sort((a, b) =>
          this.Sort(a.controlPoint.name, b.controlPoint.name, false)
        );
      } else if (this.sortField == "lastMaintenanceDate") {
        this.rowsVisibles.sort((a, b) =>
          this.Sort(a.lastMaintenanceDate, b.lastMaintenanceDate, true)
        );
      } else if (this.sortField == "lastVigilanceDate") {
        this.rowsVisibles.sort((a, b) =>
          this.Sort(a.lastVigilanceDate, b.lastVigilanceDate, true)
        );
      }
    } else if (this.sortField == "vehicle") {
      this.rowsVisibles.sort((a, b) =>
        this.Sort(a.vehicle.name, b.vehicle.name, false)
      );
    }
    //force the datatable to refresh
    this.rowsVisibles = [...this.rowsVisibles];
  }

  // vigilanceDateAux: Date;
  // maintenanceDateAux: Date;

  filterRowsDate() {
    this.selectedDay = this.dayIniSelected;
    this.selectedDay.setHours(0, 0, 0, 0);

    for (let i = 0; i < this.rows.length; i++) {
      let vigilanceDateAux = new Date(this.rows[i].lastVigilanceDate);
      let maintenanceDateAux = new Date(this.rows[i].lastMaintenanceDate);

      if (
        this.selectedDay < vigilanceDateAux ||
        this.selectedDay < maintenanceDateAux
      ) {
        this.rowsVisibles.push(this.rows[i]);
      }
    }
  }
  exportPoCsReportExcel() {
    this.rowsExcel = [];
    for (let i = 0; i < this.rowsVisibles.length; i++) {
      this.rowObject = {};
      for (let j = 0; j < this.cols.length; j++) {
        let column = this.cols[j];
        if (column.field && column.visible) {
          this.rowObject[column.header] = this.resolveFieldData(
            this.rowsVisibles[i],
            column.field
          );
        }
      }
      this.rowsExcel[i] = this.rowObject;
    }
    this.excelService.exportAsExcelFile(this.rowsExcel, "sample");
  }

  resolveFieldData(data: any, field: string): any {
    if (data && field) {
      switch (field) {
        case "controlPoint":
          return data.controlPoint.name;
        case "lastMaintenanceDate":
          return this.dp.transform(data.lastMaintenanceDate, "short");
        case "lastVigilanceDate":
          return this.dp.transform(data.lastVigilanceDate, "short");
        case "maintenanceLeftTime":
          return this.dp.transform(data.maintenanceLeftTime, "short");
        case "minorTimeRemaining":
          return this.dp.transform(data.minorTimeRemaining, "short");
        case "vehicle":
          return data.vehicle.name;
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  private Sort(a, b, isDate): number {
    let x;
    if (a < b) {
      x = -1;
    } else if (a > b) {
      x = 1;
    } else {
      x = 0;
    }

    if (this.sortOrder === 1) {
      x = x * -1;
    }

    //If its a date is orderer in the oposite way
    if (isDate) {
      x = x * -1;
    }

    return x < 0 ? -1 : x > 0 ? 1 : 0;
  }

  closeButton() {
    this.openEventsByDates = false;
  }
}
