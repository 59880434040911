<div class='flex-column justify-content-center align-items-center'>

	<div class='flex-column justify-content-center pb-5 mx-5 me-5'>
		<div class='tab-pane fade show active'>
			<div class='container-fluid justify-content-evenly p-3 mt-4 mb-4 tax-search-panel-container'>
				<div class="row flex-wrap align-items-end mt-1">

					<div class='d-flex'>
						<form-input fieldName="{{ 'tableManagement.vehicleHtc.since' | translate }}" [errors]=''
							[required]='true' style="margin-right: 20px">
							<p-calendar [(ngModel)]='startDate' (ngModelChange)='onStartDateChange()'
								[readonlyInput]='true' [showTime]='false' [style]="{'width':'150px'}"
								dateFormat='{{i18n.dateFormat}}' showIcon='true' appendTo='body'></p-calendar>
						</form-input>
						<form-input fieldName="{{ 'tableManagement.vehicleHtc.to' | translate }}" [errors]=''
							[required]='true' style="margin-right: 20px">
							<p-calendar [(ngModel)]='endDate' (ngModelChange)='onEndDateChange()'
								[disabled]='startDate === null' [readonlyInput]='true' [maxDate]="today"
								[showTime]='false' [style]="{'width':'150px'}" dateFormat='{{i18n.dateFormat}}'
								showIcon='true' appendTo='body'></p-calendar>
						</form-input>
						<form-input [required]='false'
							fieldName="{{ 'tableManagement.vehicleHtc.period' | translate }}">
							<p-dropdown [options]='historicOptionsList' [(ngModel)]='selectedHistoricOptionsList'
								dataKey='id' optionLabel='value' [style]="{'width':'250px'}" appendTo='body'
								(onChange)="onChangePeriod($event)"></p-dropdown>
						</form-input>

						<div class="p-3"></div>

						<button class='btn custom-button tax-field' (click)='search()'>
							{{ 'general.search' | translate }}
						</button>
					</div>

				</div>
			</div>
		</div>



		<!-- REPORT FILES PRIMENG DATATABLE -->
		<p-table id="table" [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
			[rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
			[rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities"
			[loading]="loading" [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true"
			currentPageReportTemplate="
         {{ 'table.showing' | translate }} {first}
         {{ 'table.to' | translate }} {last}
         {{ 'table.of' | translate }} {totalRecords}
         {{ 'table.entries' | translate}}" class="custom-table" styleClass="p-datatable-striped">

			<!-- TABLE TITLE DEFINITION -->
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
					{{ 'taco.title' | translate }}
				</div>
			</ng-template>

			<!-- TABLE COLUMN DEFINITION -->
			<ng-template pTemplate="header">
				<tr>

					<!-- CHECKBOX COLUMN -->
					<th class="checkbox-column">
						<div class="d-flex justify-content-center">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</div>
					</th>

					<!-- VEHICLE -->
					<th pSortableColumn="vehicle.name">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'tableManagement.vehicle.vehicle' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<p-sortIcon field="vehicle.name"></p-sortIcon>
								<p-columnFilter field="vehicle" type="text" matchMode="contains"
									placeholder="{{ 'general.search' | translate }}" display="menu"
									[showMatchModes]="false" [showOperator]="false"
									[showAddButton]="false"></p-columnFilter>
							</div>
						</div>
					</th>

					<!-- DRIVER -->
					<th pSortableColumn="driver.user.name">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'drivers.header' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<p-sortIcon field="driver.user.name"></p-sortIcon>
								<p-columnFilter field="driver" type="text" matchMode="contains"
									placeholder="{{ 'general.search' | translate }}" display="menu"
									[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								</p-columnFilter>
							</div>
						</div>
					</th>

					<!-- TYPE -->
					<th pSortableColumn="type">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'general.type' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<div class="d-flex align-items-center h-100">
									<p-sortIcon field="type"></p-sortIcon>
									<p-columnFilter field="type" matchMode="in" display="menu" [showMatchModes]="false"
										[showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="typeList" placeholder="{{ 'general.filters.any' | translate }}"
												(onChange)="onTypeFilterChange($event.value)" >
												<ng-template let-option pTemplate="item">
													<div class='d-flex row-cols-2 w-100 align-items-center'>
														<div>{{option === 'DRIVER' ? ('drivers.header' | translate) : 'tableManagement.vehicle.vehicle' | translate}}</div>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
								</div>
							</div>

						</div>
					</th>

					<!-- START DATE -->
					<th pSortableColumn="startDate">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'general.startDate' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<p-sortIcon field="startDate"></p-sortIcon>
							</div>
						</div>
					</th>

					<!-- END DATE -->
					<th pSortableColumn="endDate">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'general.endDate' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<p-sortIcon field="endDate"></p-sortIcon>
							</div>
						</div>
					</th>

					<!-- DOWNLOAD DATE -->
					<th pSortableColumn="downloadDate">
						<div class='d-flex align-items-center h-100'>
							<div
								class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
								{{ 'taco.downloadDate' | translate }}
							</div>
							<div class="d-flex align-items-center h-100">
								<p-sortIcon field="downloadDate"></p-sortIcon>
							</div>
						</div>
					</th>

					<!-- DOWNLOAD -->
					<th class='button-column'>
					</th>

				</tr>
			</ng-template>

			<!-- TABLE BODY -->
			<ng-template pTemplate="body" let-report>
				<tr class="p-selectable-row custom-rows">

					<!-- SELECT -->
					<td>
						<div class="d-flex justify-content-center">
							<p-tableCheckbox [value]="report"></p-tableCheckbox>
						</div>
					</td>

					<!-- VEHICLE -->
					<td>
						<div class="d-flex w-100 justify-content-center">
							{{report.vehicle.name}}
						</div>
					</td>

					<!-- DRIVER -->
					<td>
						<div *ngIf="report.driver !== null">
							({{ report.driver !== null ? report.driver?.code : "" }}) {{ report.driver !== null ?
							report.driver?.user?.surname1 : "" }} {{ report.driver !== null ?
							report.driver?.user?.surname2 : "" }}, {{ report.driver !== null ? report.driver?.user?.name
							:
							"" }}
						</div>
					</td>

					<!-- TYPE -->
					<td>
						<div class="d-flex w-100 justify-content-center">
							{{report.type === 'DRIVER' ? ('drivers.header' | translate) : 'tableManagement.vehicle.vehicle' | translate}}
						</div>
					</td>

					<!-- START DATE -->
					<td>
						{{ report.startDate | date:'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- END DATE -->
					<td>
						{{ report.endDate | date:'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- DATE -->
					<td>
						{{ report.downloadDate | date:'dd/MM/yy HH:mm:ss' }}
					</td>

					<td>
						<button type='button' class='btn custom-table-download-button' (click)='downloadTaco(report)'>
							<svg-icon src='assets/icons/download-button.svg'
								class='d-flex flex-wrap custom-table-download-button-icon'
								[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"></svg-icon>
						</button>
					</td>
				</tr>
			</ng-template>

			<!-- TABLE EMPTY MESSAGE -->
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message"
							i18n>
							{{ 'table.tableEmptyMessage' | translate }}!
						</div>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="footer">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex flex-row align-items-center p-3 gap-3">
							<app-table-download-button (btnClick)="downloadSelected()"
								[isDisabled]="selectedEntities.length === 0"></app-table-download-button>
						</div>
					</td>
				</tr>
			</ng-template>

		</p-table>
	</div>
</div>