<div class="flex-column justify-content-center align-items-center">

  <div class="flex-column p-0 mx-5 me-5">
    <app-table-navbar></app-table-navbar>
  </div>

  <hr class="separator mx-5 me-5">

  <div appFilterColumnsAutoFocus class="flex-column justify-content-center pb-5 mx-5 me-5">
    <router-outlet></router-outlet>
  </div>

</div>
