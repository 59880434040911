import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-geo-fence-parameters-gl320m',
  templateUrl: './geo-fence-parameters-gl320m.component.html',
  styleUrls: ['./geo-fence-parameters-gl320m.component.css']
})
export class GeoFenceParametersGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Disable the zone's Geo-fence function", value: "DISABLE" },
    { label: "Entering the zone", value: "ENTER_ZONE" },
    { label: "Exiting the zone", value: "EXIT_ZONE" },
    { label: "Both entering and exiting", value: "EXIT_ENTER_ZONE" },
 ];

 modeStateOptions: any = [
  { label: "The device should report its state when gettings the state for the first time", value: "REPORT_STATE_FIRST_TIME" },
  { label: "The device doesn't report its state until the state changes", value: "REPORT_UNTIL_STATE_CHANGES" },
 ]
  @Input() geoParameters: Rest.GeoFenceParametersGL320M[];
  idOptions: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map(i => ({label: i.toString(), value: i}));

  constructor(protected i18n: I18nService) { super(i18n); }
  selectedIndex: number = 0;
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.geoParameters);
    }
  }

  onGeoIdChange(event){
    this.selectedIndex = event.value;
  }
}