import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-input-delay-time-configuration-form-x8',
  templateUrl: './input-delay-time-configuration-form-x8.component.html',
  styleUrls: ['./input-delay-time-configuration-form-x8.component.css'],
})
export class InputDelayTimeConfigurationFormX8Component extends ServerValidatedFormComponent implements OnInit {

  @Input() inputDelayTime: Rest.InputDelayTimeConfigurationX8;
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }


}
