<p-dialog *ngIf="display" header="{{title}}" [(visible)]="display"
    [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false" [resizable]="false"
    [closeOnEscape]="false" [style]="{width: '40vw'}">

    <div class="content">

        <p-table id="dt" [value]="tableData" [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true"
            [autoLayout]="true" dataKey="name" class="custom-table" styleClass="p-datatable-striped">

            <!-- #region TABLE TITLE DEFINITION -->
            <ng-template pTemplate="caption">
                <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
                    TEST
                </div>
            </ng-template>
            <!-- #endregion TABLE TITE DEFINITION -->

            <!-- #region TABLE COLUMN DEFINITION -->
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of values" [pSortableColumn]="col.field">
                        <div class="d-flex align-items-center h-100">
                            <!-- COLUMN TITLE -->
                            <div
                                class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                                {{col.field}}
                            </div>

                            <!-- COLUMN SORT && FILTER ICONS -->
                            <div class="d-flex align-items-center h-100">
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                <p-columnFilter [field]="col.field" type="text" matchMode="contains"
                                    placeholder="{{ 'general.search' | translate }}" display="menu"
                                    [showMatchModes]="false" [showOperator]="false"
                                    [showAddButton]="false"></p-columnFilter>
                            </div>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <!-- #endregion TABLE COLUMN DEFINITION -->

            <!-- #region TABLE BODY -->
            <ng-template pTemplate="body" let-rowData>

                <tr class="p-selectable-row custom-rows">
                    <td *ngFor="let col of values">
                        {{col.value}}
                    </td>
                </tr>

            </ng-template>
            <!-- #endregion TABLE BODY -->


        </p-table>
        <app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
        <button (click)="exportPdf()"> pdf </button>
    </div>
</p-dialog>