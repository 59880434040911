import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
@Component({
  selector: 'app-additional-configuration-gl320m',
  templateUrl: './additional-configuration-gl320m.component.html',
  styleUrls: ['./additional-configuration-gl320m.component.css']
})
export class AdditionalConfigurationGL320MComponent extends ServerValidatedFormComponent implements OnInit {

  @Input() additionalParameters: Rest.AdditionalParametersGL320M;
  
  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.additionalParameters);
    }
  }

}
