<!-- LEADERS  LIST PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
  [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
  [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
  [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
  styleClass="p-datatable-striped">

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'tableManagement.leaders.leadersManagement' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="user.name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="user.name"></p-sortIcon>
            <p-columnFilter field="user" type="text" matchMode="contains"
              placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- FLEET LEADER  -->
      <th pSortableColumn='fleetLeader'>
        <div class='d-flex align-items-center h-100'>
          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.leaders.fleetLeader' | translate }}
          </div>
          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='fleetLeader'></p-sortIcon>
            <p-columnFilter field="fleetLeader" type="boolean" display="menu"
              [showApplyButton]="false"></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- ZONE LEADER  -->
      <th pSortableColumn='zoneLeader'>
        <div class='d-flex align-items-center h-100'>
          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.leaders.zoneLeader' | translate }}
          </div>
          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='zoneLeader'></p-sortIcon>
            <p-columnFilter field="zoneLeader" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- SERVICE LEADER  -->
      <th pSortableColumn='serviceLeader'>
        <div class='d-flex align-items-center h-100'>
          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'tableManagement.leaders.serviceLeader' | translate }}
          </div>
          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='serviceLeader'></p-sortIcon>
            <p-columnFilter field="serviceLeader" type="boolean" display="menu"
              [showApplyButton]="false"></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="disabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="disabled"></p-sortIcon>
            <p-columnFilter field="disabled" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-leader>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(leader)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="leader"></p-tableCheckbox>
        </div>
      </td>
      <!-- NAME -->
      <td>
        {{leader.user.surname1}} {{leader.user.surname2}}, {{leader.user.name}}
      </td>

      <!-- FLEET LEADER  -->
      <td class="text-center align-middle">
        <div *ngIf="leader.fleetLeader" class="d-flex justify-content-center align-items-center">
          <i class="pi true-icon pi-check-circle"></i>
        </div>
        <!---  <input *ngIf="leader.fleetLeader"
          class="form-check-input d-flex justify-content-center align-items-center custom-checkbox" [disabled]="true"
          type="checkbox" [ngModel]="leader.fleetLeader" /> -->

      </td>
      <!-- ZONE LEADER  -->
      <td class="text-center align-middle">
        <div *ngIf="leader.zoneLeader" class="d-flex justify-content-center align-items-center">
          <i class="pi true-icon pi-check-circle"></i>
        </div>
      </td>
      <!-- SERVICE LEADER  -->
      <td class="text-center align-middle">
        <div *ngIf="leader.serviceLeader" class="d-flex justify-content-center align-items-center">
          <i class="pi true-icon pi-check-circle"></i>
        </div>
      </td>
      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
            [ngClass]="{'true-icon pi-check-circle': !leader.disabled, 'false-icon pi-times-circle': leader.disabled}"></i>
        </div>
      </td>
    </tr>

  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">
          <!-- ADD LEADER BUTTON -->
          <app-table-add-button [text]=" 'tableManagement.leaders.leader' | translate " (btnClick)="create()"
            [isDisabled]="false"></app-table-add-button>
          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>
          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button> 
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.clients' | translate " [display]="true"
    [service]=leaderService [entitiesList]=selectedEntities (return)="refreshList($event)"
    (close)="closeStatusDialog($event)"></app-change-status-dialog>
</div>

<!-- LEADER FORM -->
<app-leader-form></app-leader-form>