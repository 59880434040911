import { Injectable } from "@angular/core";
import { EntityService } from "../../../rest/entity.service";
import { JQueryHTTPClient } from "../../../rest/JQueryHTTPClient";
import { Rest } from "../../../rest/rest_client";

@Injectable()
export class FueltypeService extends EntityService<Rest.FuelType> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  getList(): Rest.RestResponse<Rest.FuelType[]> {
    return this.client.FuelType_getList();
  }
}
