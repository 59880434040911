import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AccessoryService } from 'app/rest/accessory.service';

@Component({
  selector: 'app-accessory-form',
  templateUrl: './accessory-form.component.html',
  styleUrls: ['./accessory-form.component.css'],
  providers: [AccessoryService, IconService],
  encapsulation: ViewEncapsulation.None,
})
export class AccessoryFormComponent
  extends EntityFormComponent<Rest.Accessory>
  implements OnInit {
  noticeChannels: SelectItem[];
  channelsList: Rest.NoticeChannel[];
  title: string;
  active: boolean;

  constructor(
    private accessoryService: AccessoryService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    public translateService: TranslateService,
  ) {
    super(accessoryService, notificationsService, i18n, authenticationService);
  }

  /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof AccessoryFormComponent
   */
  beforeShow() {
    this.active = !this.entity.disabled;

    if (this.isNew) {
      this.title = this.translateService.instant('tableManagement.accessories.createAccessory');
    } else {
      this.title = this.translateService.instant('tableManagement.accessories.editAccessory');
    }
  }
}
