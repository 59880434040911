<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display"
    appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- society -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.society' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.billingAccount.name">
                </div>
            </div>
        </div>

        <!-- contractNumber -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.contractNumber' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- contractDenomination -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.contractDenomination' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- currency -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.currency' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.currency">
                </div>
            </div>
        </div>

        <!-- contractStart -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.contractStart' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <p-calendar [(ngModel)]='startDate' [readonlyInput]='true' [showTime]='true'
                        [style]="{'width':'200px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
                        appendTo='body'></p-calendar>
                    <!-- <input type="text" class="form-control custom-input" [(ngModel)]="startDate"> -->
                </div>
            </div>
        </div>

        <!-- contractEnd -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.contractEnd' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <p-calendar [(ngModel)]='endDate' [readonlyInput]='true' [showTime]='true'
                        [style]="{'width':'200px'}" dateFormat='{{i18n.dateFormat}}' showIcon='true'
                        appendTo='body'></p-calendar>
                    <!-- <input type="text" class="form-control custom-input" [(ngModel)]="endDate"> -->
                </div>
            </div>
        </div>

        <!-- bpCode -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.bpCode' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input"
                        [(ngModel)]="entity.financing.provider.providerParameters.codeBp">
                </div>
            </div>
        </div>

        <!-- amount -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.amount' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.financing.monthlyFee">
                </div>
            </div>
        </div>

        <!-- wayToPay -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.wayToPay' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.financing.wayToPay.name">
                </div>
            </div>
        </div>

        <!-- ceco -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.ceco' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.ceco.description">
                </div>
            </div>
        </div>

        <!-- interestType -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label class="custom-label" i18n>
                    {{ 'tableManagement.real-state.interestType' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input type="text" class="form-control custom-input" [(ngModel)]="entity.financing.interestRate">
                </div>
            </div>
        </div>

        <!-- ACTIVE -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="insuranceType-enabled" class="custom-label" i18n>
                    {{'general.active' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                        type="checkbox" [(ngModel)]="active">
                </div>
            </div>
        </div>

    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <!-- <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div> -->

                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveChanges()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>