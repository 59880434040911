import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { CardTypeService } from 'app/modules/vehicle-inventory/services/table-management/cardType.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { ProviderService } from 'app/rest/provider.service';
import { IconService } from 'app/rest/icon.service';

import CardType = Rest.CardType;
import { RestExt } from 'app/services/rest-client-extension';
import { Auxiliar } from 'app/model/Auxiliar';

@Component({
    selector: 'app-card-type-form',
    templateUrl: './cardType-form.component.html',
    styleUrls: ['./cardType-form.component.scss'],
    providers: [CardTypeService, IconService, ProviderService],
    encapsulation: ViewEncapsulation.None,
})
export class CardTypeFormComponent
    extends EntityFormComponent<CardType>
    implements OnInit {

    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;

    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    //ICON IMAGE
    cardTypeID: number;
    cardTypeImage: any;
    cardTypeImageFile: any;
    cardTypeEncodedImage: string;
    newCardTypeImage: Rest.Icon;

    entityEnumImageBrand: Rest.IconEntityAssociationEnum = "INVENTORY";
    iconImage: Rest.Icon;

    //Preservation of relationships
    providerRequestContext: Rest.ListPaginationRequest;

    selectableProviders: Rest.Provider[] = [];

    constructor(
        private cardTypeService: CardTypeService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private iconService: IconService,
        public translateService: TranslateService,
        private providerService: ProviderService,
    ) {
        super(cardTypeService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
        
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof DelegationFormComponent
     */
    beforeShow() {
        this.active = !this.entity.disabled;
        this.selectableProviders = [];
        // Presevation of relationships
        this.providerRequestContext = RestExt.firstPageRequestVehicleInventory();

        this.cardTypeID = this.entity.id;

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.cardTypes.createCardType');
        } else {
            this.title = this.translateService.instant('tableManagement.cardTypes.editCardType');

            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }

            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }

            // We are setting the value of the path of the image, depending if it's already set or not
            if (this.entity.image != null) {
                this.cardTypeEncodedImage = this.entity.image.imageEncoded;
            } else {
                this.cardTypeEncodedImage = '../../../../assets/images/UI_Elements/HD_transparent_picture.png'; //Reset the image to an empty png image from assets.
            }

            if(this.entity.provider){
                this.providerRequestContext.filters["name"] = [this.entity.provider.name];
            }
        }
        var that = this;
        this.providerService.getPage(this.providerRequestContext).then((p) => {
            p.entities.forEach((t) => {
                if (t.type.id === 1 || t.type.id === 2) {
                    that.selectableProviders.push(t);
                }
            });
        });
    }

    getProvidersType() {

        this.providerService.getPage(this.providerRequestContext).then((p) => {
            p.entities.forEach((t) => {
                if (t.type.id === 1 || t.type.id === 2) {
                    this.selectableProviders.push(t);
                }
            });
        });
    }

    saveCardType() {
        this.beforeSave();
        this.messages = [];

        let cardTypeString = JSON.stringify(this.entity);

        const that = this;
        if (this.isNew) {
            this.cardTypeService
                .createCardType(cardTypeString, this.cardTypeImage)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.cardTypeService
                .updateCardType(cardTypeString, this.cardTypeImage)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }

    onFilterProvider(event) {
        this.providerRequestContext.filters["name"] = [event.filter ?? ''];

        this.providerService.getPage(this.providerRequestContext).then((p) => {
            p.entities.forEach((t) => {
                if (t.type.id === 1 || t.type.id === 2) {
                    this.selectableProviders.push(t);
                }
            });
        });
    }

    onChangeProvider(event) {
        this.entity.provider = event.value;
    }

    /**
     * Takes a local image, and communicates with the API, in order to 
     * restore the client image.
     *
     * @param {*} event
     * @memberof CardTypeFormComponent
     */
    imageChange(event): void {

        const reader = new FileReader();
        this.cardTypeImage = event.target.files[0];
        reader.readAsDataURL(this.cardTypeImage);
        reader.onload = (event: any) => {
            console.log(reader.result);
            this.cardTypeEncodedImage = event.target.result;
        };
    }
}