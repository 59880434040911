import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges,} from "@angular/core";
import {Rest} from "../../../../rest/rest_client";
import {RealtimeService} from "../../../../rest/realtime.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-vehicle-panel-settings",
  templateUrl: "./vehicle-panel-settings.component.html",
  styleUrls: ["./vehicle-panel-settings.component.css"],
  providers: [RealtimeService],
})
export class VehiclePanelSettingsComponent implements OnInit {
  @Input() settings: Rest.VehicleDetailsSettings;

  displaySettingsForm = false;
  _settings: Rest.VehicleDetailsSettings = <Rest.VehicleDetailsSettings>{};

  availableViews = [];
  selectedView: any;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.viewInit(this._settings.view);
  }

  show() {
    this.displaySettingsForm = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["settings"]) {
      Object.assign(this._settings, this.settings);
   //  this._settings =  this.settings
     this.initSelectedView(this._settings.view)
    }
  }

  save() {
 
    this._settings.view =this.selectedView.value;
    Object.assign(this.settings, this._settings);
    this.displaySettingsForm = false;
  }

  saveView(view){
     this._settings.view = view.value
  }


  discard(e) {
    Object.assign(this._settings, this.settings);
    this.displaySettingsForm = false;
  }

  /**
   * viewInit() - Initializes {{availableViews}} array and {{selectedView}} variable.
   * */
  viewInit(view: string): void {

    /* Get view translations */
    this.translate.get('real-time.vehiclePanel.settings.viewList').subscribe((views) => {

      /* Fill {{availableViews}} array */
      /*
      this.availableViews.push({id: 1, value: 'GRID', label: views.grid});
      this.availableViews.push({id: 2, value: 'MAP', label: views.map});
      this.availableViews.push({id: 3, value: 'TELEMETRY', label: views.telemetry});
      this.availableViews.push({id: 4, value: 'QUICK_ACTIONS', label: views.quickActions}); 
      */

      this.availableViews.push({id: 1, value: 'TABLE', label: views.table});
      this.availableViews.push({id: 2, value: 'GRID', label: views.list});


    });
    this.initSelectedView(view)
    /* Initialize {{selectedView}} using the {{availableViews}} array. */
  
  }

    

    

  initSelectedView(view){
    switch (view) {
     
      //old available views

      case 'MAP':
        this.selectedView = this.availableViews[0];
        break;

      case 'TELEMETRY':
        this.selectedView = this.availableViews[0];
        break;
      case 'QUICK_ACTIONS':
        this.selectedView = this.selectedView[0];
        break;
        

        //new available views
        case 'TABLE':
        this.selectedView = this.availableViews[0];
        break;
 
        case 'GRID':
          this.selectedView = this.availableViews[1];
          break;
  
    }
    if( this.selectedView != null){
      this._settings.view =this.selectedView.value;
    }
  }

}
