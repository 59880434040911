import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";

import { LoadingService } from "app/services/loading-service/loading.service";
import { MaintenanceService } from "../../rest/maintenance.service";
import { MaintenanceplanService } from "../../rest/maintenanceplan.service";
import { Rest } from "../../rest/rest_client";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityListComponent } from "../entity-list/entity-list.component";
import { MaintenancePlanFormComponent } from "../maintenance-plan-form/maintenance-plan-form.component";
import { UserTimePipe } from "../shared/UserTimePipe";

@Component({
    selector: "app-maintenance-plans-list",
    templateUrl: "./maintenance-plans-list.component.html",
    styleUrls: ["./maintenance-plans-list.component.css"],
    providers: [
        MaintenanceplanService,
        ConfirmationService,
        MaintenanceService,
        UserTimePipe,
    ],
})
export class MaintenancePlansListComponent
    extends EntityListComponent<Rest.MaintenancePlan>
    implements OnInit {
    title: string = this.i18n._("Maintenance Plans");
    displayDialog: boolean;
    messageWarning: string;
    EnabledFilter: SelectItem[];

    /** #ChangeStatus */
    // showStatusDialog = false;
    showStatusDialogMaintenance = false;

    @ViewChild(MaintenancePlanFormComponent, {static: true})
    maintenancePlanForm: MaintenancePlanFormComponent;

    constructor(
        private maintenancePlanService: MaintenanceplanService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        i18n: I18nService,
        private maintenanceService: MaintenanceService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            maintenancePlanService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        //This entity can be filtered by other entities.
        this.maintenancePlanService = maintenancePlanService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.maintenancePlanForm;
        this.hasEntityFilters = true;
    }

    editPlanDialog(event) {
        if (!this.isSelectableColumn) {
            this.onRowDblClick({data: event});
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editPlanDialogTable(event) {
        if (
            !this.isColumn &&
            !this.isSelectableColumn &&
            event["originalEvent"]["srcElement"]["cellIndex"] > 1
        ) {
            this.onRowDblClick(event);
        }

        this.isSelectableColumn = false;
        this.isColumn = false;
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((mp: any) => {
            //Add a new property to each plan with the notice channels concatenated
            mp._$noticeChannelsStr = this.arrayToString(mp.noticeChannels);
            /*Permissions */
            mp._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "Maintenance Plan"),
                mp.id
            );
            mp._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "Maintenance Plan"),
                mp.id
            );
            /** */
        });

        this.checkSelectAll();
    }
}
