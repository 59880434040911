import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { environment } from "../../../../environments/environment";
import { catchError, filter, switchMap, take } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private _isRefreshing: boolean
    private _refreshTokenSubject: BehaviorSubject<any>;

    constructor(
        private auth: AuthenticationService
    ) {
        this._isRefreshing = false;
        this._refreshTokenSubject = new BehaviorSubject<any>(null);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isLoggedIn = this.auth.token;
        const isApiUrl = this.isApiUrl(request.url);
        const urlNeedsToken = this.urlNeedsToken(request.url);

        /* If user is logged in, and it's an API call, append the token to the call */
        if (isLoggedIn && isApiUrl && urlNeedsToken) {
            request = this.addTokenHeader(request, this.auth.token)
        }

        /* If there is an error with the request */
        return next.handle(request)
            .pipe(catchError(error => {

                /* If server response is 401 Unauthorized, try to get new token */
                if (error.status === 401) {
                    return this.handle401Error(request, next);
                }

                /* If server response is 403, Refresh Token has expired */
                if (error.status === 403) {
                    this.auth.logout();
                }

                return throwError(error);
            }));
    }

    /**
     * handle401Error()
     * */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        /* If it's already trying to obtain new token */
        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this._refreshTokenSubject.next(null);

            /* If there is a Refresh Token */
            if (this.auth.refreshToken) {

                /* Try to update the Bearer Token */
                return this.auth.updateToken().pipe(
                    switchMap((response: any) => {
                        this._isRefreshing = false;

                        this.auth.initDataAfterRefresh(response);
                        this._refreshTokenSubject.next(response.token);

                        return next.handle(this.addTokenHeader(request, response.token));
                    }),
                    catchError((err) => {
                        this._isRefreshing = false;
                        this.auth.logout();
                        return throwError(err);
                    })
                );
            }
        }

        return this._refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    /**
     * addTokenHeader()
     * */
    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * isApiUrl()
     * */
    private isApiUrl(url: string): boolean {
        const isWebApiUrl: boolean = url.startsWith(environment.webApiBaseUrl);
        const rtApiUrl: boolean = url.startsWith(environment.RTAPIBaseUrl);
        const rtSocketUrl: boolean = url.startsWith(environment.RTSocketURL);

        return isWebApiUrl || rtApiUrl || rtApiUrl;
    }

    /**
     * urlNeedsToken()
     * */
    private urlNeedsToken(url: string): boolean {
        const refreshUrl = url.endsWith('/login/refresh');
        const loginUrl = url.endsWith('/login');

        return !refreshUrl && !loginUrl;
    }
}
