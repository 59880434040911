import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../rest/JQueryHTTPClient";

import { EntityService } from "../rest/entity.service";
import { Rest } from "../rest/rest_client";

@Injectable()
export class CecoService extends EntityService<Rest.Ceco> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Ceco): Rest.RestResponse<Rest.Ceco> {
    return this.client.Ceco_create(arg0);
  }

  update(arg0: Rest.Ceco): Rest.RestResponse<Rest.Ceco> {
    return this.client.Ceco_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.Ceco>> {
    return this.client.Ceco_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Ceco>> {
    return this.client.Ceco_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.Ceco_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.Ceco> {
    return this.client.Ceco_find(arg0, queryParams);
  }

  	getList(billingAccountId: number): Rest.RestResponse<Rest.Ceco[]> {
		return this.client.Ceco_getList(billingAccountId);
	}

}
