<div class="container-fluid p-0 custom-panel info-card-main-container">

  <!-- ROW 1: TITLE, COUNTER & ICON -->
  <div class="row">

    <!-- COL 1: TITLE & COUNTER -->
    <div class="col-7 mx-3">

      <!-- ROW 1: TITLE -->
      <div class="row mt-3">
        <div class="custom-title info-card-title">{{title}}</div>
      </div>

      <!-- ROW 2: COUNTER -->
      <div class="row mt-2">
        <div class="info-card-counter">{{counter}}</div>
      </div>

    </div>

    <!-- COL 2: ICON -->
    <div class="col mt-3 p-0">
      <div class="d-flex align-items-center justify-content-center p-0 m-0">
        <svg-icon class="m-0 p-0" src="{{icon}}" [svgStyle]="{'height.px': 50, 'width.px': 50}"></svg-icon>
      </div>
    </div>

  </div>

  <!-- ROW 2: STATISTICS -->
  <div class="row pb-3 ms-3 mt-4">
    <div class="d-flex m-0 p-0">
      <div class="info-card-statistics" [ngClass]="{'positive-statistics': statistics >= 0}" *ngIf="statistics >= 0">+</div>
      <div class="info-card-statistics" [ngClass]="{'positive-statistics': statistics >= 0, 'negative-statistics': statistics < 0}">{{statistics}}%
      </div>
      <div class="info-card-statistics-text text-break ms-2" i18n>{{'infoCard.statisticsText' | translate}}</div>
    </div>
  </div>

</div>
