<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Virtual Ignition Mode Selection (AT+GTVMS)</span>
            </div>
          </div>
        </p-header>
      
        <div class="ui-g">
                <div class="row row-cols-2 mt-3">
                        <form-input  fieldName="Virtual Ignition Mode" >
                          <div class="ui-inputgroup">
                              <p-dropdown [options]="modeOptions" [(ngModel)]="vmsParameters.mode" [style]="{'width':'340px'}"></p-dropdown>
                          </div>                  
                        </form-input>
                    </div>
                    <div class="row mt-3">
                        <div class="row mt-3">
                                <div class="col">
                                        <form-input fieldName="Virtual Ignition On Mask"></form-input>
                                </div>
                                <div class ="col">
                                    <div class="row mt-3">
                                        <p-checkbox binary="true" label="Bit0 (01): Voltage virtual ignition detection" [(ngModel)]="vmsParameters.voltageVirtualIgnitionOnDetection">
                                        </p-checkbox>
                                    </div>
                                    <div class="row mt-3">
                                        <p-checkbox binary="true" label="Bit1 (02): Motion status virtual ignition detection" [(ngModel)]="vmsParameters.motionStatusVirtualIgnitionOnDetection">
                                        </p-checkbox>
                                    </div>
                                </div>
                        </div>
                         
                    </div>
                    <div class="row mt-3">
                            <div class="col">
                                    <form-input fieldName="Virtual Ignition Off Mask"></form-input>
                            </div>
                            <div class ="col">
                                <div class="row mt-3">
                                    <p-checkbox binary="true" label="Bit0 (01): Voltage virtual ignition detection" [(ngModel)]="vmsParameters.voltageVirtualIgnitionOffDetection">
                                    </p-checkbox>
                                </div>
                                <div class="row mt-3">
                                    <p-checkbox binary="true" label="Bit1 (02): Motion status virtual ignition detection" [(ngModel)]="vmsParameters.motionStatusVirtualIgnitionOffDetection">
                                    </p-checkbox>
                                </div>
                            </div>                         
                    </div>
                    <div class="row mt-3">
                        <p-checkbox binary="true" label="Virtual Ignition On Logic" [(ngModel)]="vmsParameters.virtualIgnitionOnLogic">
                        </p-checkbox>
                    </div>
        </div>
      </p-accordionTab>
