import { Component, Input, OnChanges, OnInit, SimpleChanges,OnDestroy } from '@angular/core';

import { Rest } from '../../../../rest/rest_client';
import { RealtimeService } from '../../../../rest/realtime.service';
import { I18nService } from '../../../../services/i18n/i18n.service';
import { RestExt } from '../../../../services/rest-client-extension';
import { RealTimeDataService } from '../../../../services/rt-data/rt-data.service';
import { CercaliaMapService } from '../../../../services/cercalia-map/cercalia-map.service';
@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.css'],
    providers: [RealtimeService,CercaliaMapService]
})


export class DataTableComponent implements OnInit, OnChanges,OnDestroy {
    @Input() settings: Rest.VehicleDetailsSettings;
    
    vehicleInfoArr: RestExt.ExtendedVehicleDataMessage[] = [];
    isCollapsed:false;
    private alive: boolean = true;
    constructor(private i18n: I18nService, private rtService: RealtimeService, private realTimeDataService: RealTimeDataService, private rtDataService: RealTimeDataService, private cercaliaMapService:CercaliaMapService) {
        this.vehicleInfoArr = [];
    }

    ngOnInit() {
        
        this.rtDataService.vehicleInfo.takeWhile(() => this.alive).subscribe((vi: RestExt.ExtendedVehicleDataMessage) => {
            this.processVehicleDataMessage(vi);
        });

        this.rtDataService.watchedVehicles.takeWhile(() => this.alive).subscribe((watched:RestExt.WatchedVehicle[])=>
        {
             //Discard vehicleInfoArr related to removed vehicles
            this.vehicleInfoArr = [... this.vehicleInfoArr.filter(inf=>{return watched.findIndex(v=>v.id == inf.vehicleId && v.visibleInMap)!=-1})];
        });

        this.rtDataService.vehicleInfoArr.forEach(vi=>this.processVehicleDataMessage(vi));
    }

    private processVehicleDataMessage(vi:RestExt.ExtendedVehicleDataMessage)
    {
        let addr = this.cercaliaMapService.reverseGeocode(vi.location.longitude,vi.location.latitude).then
        (add => {
            vi.address = add.address +" - "+add.city;
            (vi as any).vehicle  = this.rtDataService.watchedVehicles.getValue().find(v=>v.id == vi.vehicleId);
            this.vehicleInfoArr = [... this.rtDataService.vehicleInfoArr.filter(inf=>{return this.rtDataService.watchedVehicles.getValue().findIndex(v=>v.id == inf.vehicleId && v.visibleInMap)!=-1})];
        });
    }

    remove(info:RestExt.ExtendedVehicleDataMessage)
    {
        let newWatched = this.rtDataService.watchedVehicles.getValue();
        let v = newWatched.find((watched:RestExt.WatchedVehicle)=>{return info.vehicleId == watched.id});
        if(v!=null) v.visibleInMap = false;
        this.rtDataService.watchedVehicles.next(newWatched);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["settings"]) {
            this.vehicleInfoArr = [];

        }
    }

    ngOnDestroy()
    {
        this.alive = false;
    }

    rowExpand()
    {
        /*When the row expands trigger a window resize to froce the map component to calculate its size */
        window.dispatchEvent(new Event('resize'));
    }

}
