<p-accordionTab>

  <p-header>
      <div class="ui-helper-clearfix">
          <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Tag In/Out configuration</span>
          </div>
          <div class="right">
              <p-inputSwitch  [(ngModel)]="tagInOutEventConfig.enabled"></p-inputSwitch>
              <!-- There was an (onChange) that was not binded to any method, so it was terminated-->
          </div>
      </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!tagInOutEventConfig.enabled>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Detected time interval" [errors]="errors.tagDetectedTimeInterval">
        <p-spinner size="5" [(ngModel)]="tagInOutEventConfig.tagDetectedTimeInterval" [min]="15" [max]="255"></p-spinner>
        <span class="ui-inputgroup-addon">15~255</span>
        <span class="ui-inputgroup-addon" i18n>seconds</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Detected times" [errors]="errors.tagDetectedTimes">
        <p-spinner size="5" [(ngModel)]="tagInOutEventConfig.tagDetectedTimes" [min]="1" [max]="10"></p-spinner>
        <span class="ui-inputgroup-addon">1~10</span>
        <span class="ui-inputgroup-addon" i18n>times</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Undetected time interval" [errors]="errors.tagUndetectedTimeInterval">
        <p-spinner size="5" [(ngModel)]="tagInOutEventConfig.tagUndetectedTimeInterval" [min]="15" [max]="255"></p-spinner>
        <span class="ui-inputgroup-addon">15~255</span>
        <span class="ui-inputgroup-addon" i18n>seconds</span>
      </form-input>
    </div>

    <div class="ui-g-12 ui-m-6">
      <form-input i18n-fieldname fieldName="Undetected times" [errors]="errors.tagUndetectedTimes">
        <p-spinner id="tagInOutEventConfig" size="5" [(ngModel)]="tagInOutEventConfig.tagUndetectedTimes" [min]="1" [max]="10"></p-spinner>
        <span class="ui-inputgroup-addon">1~10</span>
        <span class="ui-inputgroup-addon" i18n>times</span>
      </form-input>
    </div>

  </div>
</p-accordionTab>
