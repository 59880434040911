import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-server-registration-information-gmt200',
  templateUrl: './server-registration-information-gmt200.component.html',
  styleUrls: ['./server-registration-information-gmt200.component.css'],
})
export class ServerRegistrationInformationGmt200Component extends ServerValidatedFormComponent implements OnInit {
  @Input() sriParameters: Rest.ServerRegistrationInformationGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }

  reportModeOptions: any = [
    {label: "Stop reporting",value:"STOP"},
    {label: "TCP short-connection preferred mode",value:"TCP_SHORT_CONNECTION_PREFERRED"},
    {label: "TCP short-connection forced mode", value:"TCP_SHORT_CONNECTION_FORCED"},       
    {label: "TCP long-connecton mode", value:"TCP_LONG_CONNECTION"},
    {label: "UDP mode", value:"UDP"},
    {label: "Force on SMS", value:"FORCED_SMS"},
    {label: "UDP with fixed local port", value:"UDP_FIXED_LOCAL_PORT"},
    {label: "TCP long-connection mode with the backup server", value:"BACKUP_SERVER_CONNECTION_TCP_LONG_CONNECTION"} 
  ];

  bufferModeOptions: any = [
    {label: "Disable", value:"DISABLE"},
    {label: "Low priority.", value:"LOW_PRIORITY"},
    {label: "High priority.", value:"HIGH_PRIORITY"}
  ]

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.sriParameters);
    }
  }

}
