/* eslint-disable prefer-const */
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { CustomMapComponent } from "app/components/shared/custom-map/custom-map.component";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Auxiliar } from "../../../model/Auxiliar";
import { NoticegroupService } from "../../../rest/noticegroup.service";
import { Rest } from "../../../rest/rest_client";
import { ZoneService } from "../../../rest/zone.service";
import { ZonegroupService } from "../../../rest/zonegroup.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { CercaliaMapService } from "../../../services/cercalia-map/cercalia-map.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import {
    CreateZoneMarkerEvent,
    CustomMapOptions,
    DrawZoneType,
    UpdateZoneMarkerEvent,
} from "../../shared/custom-map/custom-map.model";
import { ZoneGroupFormComponent } from "../zone-group-form/zone-group-form.component";
import Zone = Rest.Zone;
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-zone-form",
    templateUrl: "./zone-form.component.html",
    styleUrls: ["./zone-form.component.scss"],
    providers: [
        ZonegroupService,
        ZoneService,
        CercaliaMapService,
        ConfirmationService,
        NoticegroupService,
    ],
})
export class ZoneFormComponent
    extends EntityFormComponent<Rest.Zone>
    implements OnInit {
    title: string;
    Types: SelectItem[];
    disableZoneTypeSelector = false;
    numevents: SelectItem[];
    user: Rest.User;

    // Change:
    //@Input() zonesList: Rest.Zone[];

    // -------------- //
    // OPCIONES MAPA  //
    // showMap = true;
    showMap = false;
    customMapOptions: CustomMapOptions = {};
    currentZonesToDisplay: Rest.Zone[] = [];

    //Input data for the component. They're used for map configuration.
    zones: Rest.Zone[] = [];

    @ViewChild(ZoneGroupFormComponent, { static: true })
    zoneGroupForm: ZoneGroupFormComponent;
    @ViewChild(CustomMapComponent) map: CustomMapComponent;

    //Evento que genera el formulario cuando se quiere actualizar la posición de un poi
    @Output() updateZoneEvent: EventEmitter<UpdateZoneMarkerEvent> =
        new EventEmitter<UpdateZoneMarkerEvent>();
    @Input() realTime: boolean;

    zoneGroupCreated: Rest.ZoneGroup;
    noticeGroupCreated: Rest.NoticeGroup;
    selectedZoneGroup: Rest.ZoneGroup;
    availableZoneGroups: SelectItem[];
    zoneGroups: Rest.ZoneGroup[];

    // End OPCIONES MAPA  //
    // ------------------ //
    zoneGroupService: ZonegroupService;

    // ------------------ //
    // FORM LABELS //
    zoneData: string;
    zoneName: string;
    zoneType: string;
    enabledLabel: string;
    eventsLabel: string;
    enterLabel: string;
    leaveLabel: string;
    zoneGroupLabel: string;
    zonePlaceholder: string;
    saveButton: string;
    customPoints: string;
    // End FORM LABELS  //
    // ------------------ //

    constructor(
        zoneService: ZoneService,
        notificationsService: NotificationsService,
        private translate: TranslateService,
        protected i18n: I18nService,
        private confirmationService: ConfirmationService,
        private noticegroupService: NoticegroupService,
        private cercaliaMapService: CercaliaMapService,
        zonegroupService: ZonegroupService,
        protected authenticationService: AuthenticationService
    ) {
        // @ts-ignore
        super(zoneService, notificationsService, i18n, authenticationService);
        this.zoneGroupService = zonegroupService;
    }

    ngOnInit() {
        let that = this;
        super.ngOnInit();

        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
        this.numevents = [];
        this.numevents.push({ label: "1", value: 1 });
        this.numevents.push({ label: "2", value: 2 });
        this.numevents.push({ label: "3", value: 3 });
        this.numevents.push({ label: "4", value: 4 });
        this.numevents.push({ label: "5", value: 5 });
        this.Types = [];
        this.Types.push({ label: this.i18n._("Circular"), value: true });
        this.Types.push({ label: this.i18n._("Poligonal"), value: false });

        this.translateLabels();

        this.zoneGroupForm.return
            .takeWhile(() => this.alive)
            .subscribe((entity: Rest.ZoneGroup) => {
                if (entity !== undefined && entity.id > 0) {
                    that.confirmationService.confirm({
                        message: that.i18n._(
                            "Do you want to be notified about the events of the Zones in the Zone Group created?"
                        ),
                        accept: () => {
                            let usersList = [];
                            let tagEventsList = [];
                            usersList.push(entity.owner);
                            tagEventsList.push("Zone_Enter_zgId" + entity.id);
                            tagEventsList.push("Zone_Leave_zgId" + entity.id);
                            let newNoticeGroup: Rest.NoticeGroup = {
                                id: null,
                                enabled: true,
                                owner: entity.owner,
                                name: "Notice Group " + entity.name,
                                description: "Notice_Group_zgId" + entity.id,
                                notifyBySMS: true,
                                notifyByApp: true,
                                notifyByEmail: true,
                                notifyByWeb: true,
                                visibleUsers: usersList,
                                visibleFleets: entity.fleets,
                                visibleVehiclesGroups: entity.vehiclesGroups,
                                visibleVehicles: entity.vehicles,
                                tag_events: tagEventsList,
                                noticeChannelsStr: "",
                            };

                            that.noticegroupService
                                .create(newNoticeGroup)
                                .then(
                                    function (ret) {
                                        that.notificationsService.add(
                                            Severity.success,
                                            that.i18n._(":)"),
                                            that.i18n._("Notice Group Created")
                                        );
                                        that.noticeGroupCreated = ret;
                                    },
                                    (err) => {
                                        console.log(err);
                                        that.handleError(err, that);
                                    }
                                )
                                .catch((e) => {
                                    this.handleError(e, that);
                                });
                        },
                    });

                    that.availableZoneGroups.push(Auxiliar.toEntitySelectItem(entity));
                    that.selectedZoneGroup = entity;
                    this.entity.zoneGroup = entity;
                    that.notificationsService.add(
                        Severity.success,
                        that.i18n._(":)"),
                        that.i18n._("Zone Group Created")
                    );
                    that.zoneGroupCreated = entity;
                }
            });
    }

    /**
     * Translate all the variables that are binded on the form,
     * using the translator library.
     *
     * @private
     * @memberof ZoneFormComponent
     */
    private translateLabels() {
        this.zoneData = this.translate.instant('zones.zone-form.zone-data');
        this.zoneName = this.translate.instant('zones.zone-form.name');
        this.zoneType = this.translate.instant('zones.zone-form.type');
        this.enabledLabel = this.translate.instant('zones.zone-form.enabled');
        this.eventsLabel = this.translate.instant('zones.zone-form.events');
        this.enterLabel = this.translate.instant('zones.zone-form.enter');
        this.leaveLabel = this.translate.instant('zones.zone-form.leave');
        this.zoneGroupLabel = this.translate.instant('zones.zone-form.zone-group');
        this.zonePlaceholder = this.translate.instant('zones.zone-form.select-zone');
        this.saveButton = this.translate.instant('zones.zone-form.save');
        this.customPoints = this.translate.instant('zones.zone-form.polylines');
    }

    zoneStringChanged(points) {
        this.entity.circular = false;
        if (points != null && points !== "") {
            this.entity.rawPolygonVertexList = '"POLYGON((' + points + '))"';
        }
        this.fireZoneTypeChangedEvent(null);
    }

    createZoneGroup() {
        this.zoneGroupForm.create();
    }

    close(event) {
        this.showMap = false;
    }

    private fireZoneTypeChangedEvent(event: any) {
        //console.log(event.value);
        this.configureMap();
    }

    notifyCreateZone(evtZone: CreateZoneMarkerEvent) {
        if (evtZone.zone.zoneType === DrawZoneType.Circle) {
            this.entity.radius = evtZone.zone.radius;
            this.entity.center = {
                latitude: evtZone.zone.centerLat,
                longitude: evtZone.zone.centerLon,
            } as Rest.Vertex;
        } else {
            this.entity.rawPolygonVertexList = evtZone.zone.wkt;
        }
    }

    notifyUpdateZone(event: UpdateZoneMarkerEvent) {
        this.updateZoneEvent.emit(event);
    }

    beforeSave() {
        if (this.entity.circular) {
            this.entity.rawPolygonVertexList = null;
            this.entity.zoneJSON = JSON.stringify({ vertex: [] });
        } else {
            let raw = this.entity.rawPolygonVertexList;
            //"POLYGON((0.1167197314239296 42.3141920646041,-2.2343544873260712 40.9013793289792,-1.7509560498260694 40.082599898966436,1.6108603564239306 41.61165338481504,0.1167197314239296 42.3141920646041))"
            //lon lat
            raw = raw.replace("POLYGON((", "");
            raw = raw.replace("))", "");
            let vertexList = [];
            raw.split(",").forEach((lonLatStr) => {
                lonLatStr = lonLatStr.replace('"', "");
                let lonLat = lonLatStr.split(" ");
                vertexList.push({
                    lat: parseFloat(lonLat[1].trim()),
                    lon: parseFloat(lonLat[0].trim()),
                });
            });
            this.entity.zoneJSON = JSON.stringify({ vertex: vertexList });

            this.entity.radius = null;
            this.entity.center = null;
        }
    }

    zoneGroupChanged() {
        this.zones = [...this.entity.zoneGroup.zones];

        //this.zones = this.zonesList;
        //remove zone from list
        if (!this.isNew) {
            this.zones.splice(
                this.zones.findIndex((z: Rest.Zone) => z.id === this.entity.id),
                1
            );
        }
        this.zones.push(this.entity);
        this.zones = this.zones.filter((z) => z.enabled);
    }

    configureMap() {
       
            //Se puede crear un solo poi al clicar en el mapa. El resto de pois no son editables (solo visibles)
            this.customMapOptions = {
                editZones: false,
                drawZones: true,
                editZoneid:this.entity.id,
                drawZoneType: this.entity.circular
                    ? DrawZoneType.Circle
                    : DrawZoneType.Polygon,
               
            };
            this.disableZoneTypeSelector = false;
        
    }

    async beforeShow() {

        if (this.realTime) {
            this.showMap = false;
        } else {
            this.showMap = true;
        }

        this.selectedZoneGroup = null;
        let that = this;
        let currentZGId = this.entity.zoneGroup ? this.entity.zoneGroup.id : -1;
        let allZG = (
            await that.zoneGroupService.getPage(RestExt.allRecordsRequest())
        ).entities;
        this.zoneGroups = allZG.filter((zg) => zg.enabled || zg.id === currentZGId);
        this.availableZoneGroups = Auxiliar.toEntitySelectItemArr(this.zoneGroups);

        if (that.entity.zoneGroup != null) {
            that.entity.zoneGroup = this.zoneGroups.find(
                (b) => b.id === that.entity.zoneGroup.id
            );
        }
    }

    async initMapAfterViewInit(event) {

        if (this.isNew) {
            this.entity.circular = true;
            this.entity.center = { latitude: 0, longitude: 0 } as Rest.Vertex;
            this.entity.radius = 0;
        }
        // Change:
        this.zones = [...this.entity.zoneGroup.zones];

        //this.zones = this.zonesList;
        //remove zone from list
        if (!this.isNew) {
            this.zones.splice(
                this.zones.findIndex((z: Rest.Zone) => z.id === this.entity.id),
                1
            );
        }
        this.zones.push(this.entity);
        this.zones = this.zones.filter((z) => z.enabled);
        this.configureMap();
        if (!this.isNew) {
            window.setTimeout(() => {
                this.map.centerMapAtZone([this.entity.id]);
            }, 500);
        }

    }

    notifyUpdatePOI($event: UpdateZoneMarkerEvent) {
        // TODO: Implement!
    }

    groupChanged($event: any) {
        //TODO: Implement!!!
    }
}
