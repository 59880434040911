import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { UserService } from 'app/rest/user.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';

import User = Rest.User;
import Tax = Rest.Tax;
import { TaxService } from 'app/modules/vehicle-inventory/services/table-management/taxes.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';

@Component({
	selector: 'app-taxes-form',
	templateUrl: './taxes-form.component.html',
	styleUrls: ['./taxes-form.component.css'],
	providers: [UserService, InvVehicleService],
	encapsulation: ViewEncapsulation.None,
})
export class TaxFormComponent extends EntityFormComponent<Tax> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;

	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;

	active: boolean;
	ids: number[] = [];

	/*Permissions */
	disableSave: boolean;
	disabeChangeStatus: boolean;

	payDate: Date;
	defaultDate: Date = new Date();
	now: string;

	editVehicle: boolean;
	backupVehicle: Rest.Vehicle;
	vehiclePlaceholder: string;

	vehicleRequestContext: any;
	vehicleList: Rest.Vehicle[] = [];

	// DROPDOWNS MOCK VARIABLES (WILL BE CHANGED IN THE FUTURE)
	owner: User;
	users: User[];

	file: File;

	errorMessages: string[] = [];
	saving: boolean = false;

	constructor(
		private TaxService: TaxService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public userService: UserService,
		public invVehicleService: InvVehicleService
	) {
		super(TaxService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof TaxFormComponent
	 */
	beforeShow() {
		// console.log("[TAXES-FORM] beforeShow ***");
		// console.log(this.entity);
		if (this.entity.payDate === null || this.entity.payDate === undefined) {
			this.entity.payDate = new Date();
		}

		console.log(this.entity.document);
		this.ids = [];
		if (this.entity.document && this.entity.document.length > 0) {
			this.entity.document.forEach((x) => this.ids.push(x.id));
		} else {
			this.ids = [-1];
		}

		this.editVehicle = true;
		this.active = !this.entity.disabled;

		sanctionDate: Date;

		this.fetchUsersData();

		/*Permissions */
		if (this.isNew) {
			this.disableSave = false;
			if (this.vehicle !== null) {
				this.entity.vehicle = this.vehicle;
			}
			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.insurance.createInsurance')
				: this.vehicle.name +
				  ' ' +
				  this.vehicle.model?.brand?.name +
				  ' - ' +
				  this.vehicle.model?.name +
				  ' - ' +
				  this.vehicle.version?.name;
		} else {
			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.insurance.editInsurance')
				: this.vehicle.name +
				  ' ' +
				  this.vehicle.model?.brand?.name +
				  ' - ' +
				  this.vehicle.model?.name +
				  ' - ' +
				  this.vehicle.version?.name;
			if (this.entity['_$update'] === true) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
			if (this.entity['_$changeStatus'] === true) {
				this.disabeChangeStatus = false;
			} else {
				this.disabeChangeStatus = true;
			}
		}

		this.payDate = new Date(this.entity.payDate);
		this.vehiclePlaceholder = this.translateService.instant('general.select');
		this.vehiclePlaceholder += ' ';
		this.vehiclePlaceholder += this.translateService.instant('tableManagement.cardLists.vehicle');

		if (this.entity.vehicle !== null && this.entity.vehicle !== undefined) {
			this.vehiclePlaceholder = this.entity.vehicle.name;
			this.backupVehicle = this.entity.vehicle;
		}

		if (this.vehicle !== null && this.entity.vehicle !== undefined) {
			this.editVehicle = false;
			this.entity.vehicle = this.vehicle;
			this.backupVehicle = this.vehicle;
		}

		if (this.isNew && this.vehicle !== null) {
			this.editVehicle = false;
		}

		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: false,
		} as Rest.ListPaginationRequest;

		this.invVehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	afterShow() {
		//  console.log("[TAXES-FORM] afterShow ***");
		//  console.log(this.entity);
	}

	async fetchUsersData() {
		try {
			this.owner = await this.userService.getSelf();

			this.users = await this.userService.getUsersByClient(this.owner.client.id);
		} catch (error) {
			//Error handling
			console.error('Error fetching users data: ', error);
		}
	}

	onDateChange() {
		this.entity.payDate = this.payDate;
	}

	onChangeVehicle(event) {
		//  console.log('+++ onChangeVehicle', event);
		this.entity.vehicle = event.value;
	}

	onFilterVehicle(event) {
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {
				name: [event.filter ?? ''],
			},
			loadPermissions: false,
		} as unknown as Rest.ListPaginationRequest;

		this.invVehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	//   onFileSelected(event) {
	//     this.file = event.target.files[0];

	//     console.log('+++ ', this.file);
	//     this.TaxService.createDocument(this.entity.id, this.vehicle, this.file);
	//   }

	onKeyDownDecimal(event: any) {
		var pressedKey = event.key;
		const keyMap = new Map<number, string>();
		keyMap.set(110, ',');
		const { keyCode } = event;
		if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
			event.preventDefault();
			event.target.value += ',';
		}
	}

	validateTaxForm() {
		this.errorMessages = [];

		if (this.vehicle !== null) {
			this.entity.vehicle = this.vehicle;
		}
		
		if (
			!this.entity.taxImport ||
			!this.payDate ||
			!this.entity.vehicle ||
			!this.entity.description ||
			this.entity.description === ''
		) {
			this.errorMessages.push(this.translateService.instant('error.required-field.generic'));
			// this.notificationsService.add(
			// 	Severity.error,
			// 	this.translateService.instant(this.isNew ? 'general.createError' : 'updateError') + '!',
			// 	this.translateService.instant('error.required-field.generic')
			// );
			window.scroll({ top: 0, behavior: 'smooth' });
			this.saving = false;
		}
		return this.errorMessages.length === 0;
	}

	saveChanges() {
		// console.log("[TAXES-FORM] saveChanges ***");
		// console.log(this.entity);
		this.saving = true;
		if (this.validateTaxForm()) {
			if (this.isNew) {
				// if (this.vehicle !== null) {
				// 	this.entity.vehicle = this.vehicle;
				// }

				this.entity.vehicle.driverAssignments = null;

				this.TaxService.create(this.entity).then((p) => {
					this.display = false;
					this.saving = false;
					this.return.emit(p);
					this.TaxService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
				});
			} else {
				if (this.entity.vehicle === null) {
					this.entity.vehicle = this.backupVehicle;
				} else {
					this.entity.vehicle = this.vehicle;
				}

				this.entity.vehicle.driverAssignments = null;
				

				this.TaxService.update(this.entity).then((p) => {
					this.display = false;
					this.saving = false;
					this.return.emit(p);
					this.TaxService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
				});
			}
		}
	}

	refresh() {
		// console.log("[TAX-FORM] refresh");
		this.return.emit();
	}
}
