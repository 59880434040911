import {Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';

import {Rest} from '../../../../../rest/rest_client';
import {RealtimeService} from '../../../../../rest/realtime.service';
import {DeviceService} from '../../../../../rest/device.service';
import {I18nService} from '../../../../../services/i18n/i18n.service';
import {NotificationsService} from '../../../../../services/notifications-service/notifications.service';
import {RealTimeDataService} from '../../../../../services/rt-data/rt-data.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css'],
  providers: [RealtimeService, DeviceService]
})


export class SMSComponent implements OnInit {
  @ViewChild('sendMessageModal') sendMessageModal: ElementRef;
  @ViewChild('closeSendMessageModalButton') closeModalButton: ElementRef;

  @Input() vehicleId: number;
  showForm: boolean = false;
  content: string = "";
  actuatorMessageWatch: { [key: number]: number };

  constructor(private i18n: I18nService, private rtService: RealtimeService, private realTimeDataService: RealTimeDataService, private notificationService: NotificationsService, private deviceService: DeviceService) {
  }

  ngOnInit() {

    this.actuatorMessageWatch = {};
    /**
     * When a request response is recieved check if there is any watch defined over it (it means that it is an actuatorChangeRequestResponse originated from this component).
     * If true, handle it, if not, ignore it.
     */
    /*this.realTimeDataService.requestResponse.subscribe((resp: Rest.ResponseMessage) => {
        for (let actuatorId in this.actuatorMessageWatch) {
            if (this.actuatorMessageWatch[actuatorId] != resp.originalRequest.id) continue;
            //Handle the message
            switch (resp.status) {
                case "ACK":
                    this.notificationService.add(Severity.success, this.i18n._(':)'), this.i18n._('Actuator change request acknowledged by device!'));
                    break;
                case "NACK":
                    this.notificationService.add(Severity.error, this.i18n._(':('), this.i18n._('Actuator change request not acknowledged by device!'));
                    break;

            }
            //Remove the watch
            delete this.actuatorMessageWatch[actuatorId];
            return;

        }

    });*/
  }


  send() {
    //Construct the request object
    let req = <Rest.DigitalActuatorChangeRequest>{
      digital: true,
      id: null,
      vehicleId: this.vehicleId,
      platformTime: null,
      requestType: "SET_ACTUATOR_VALUE",
      tag: "",
    }

    //Send a request to the device
    /* this.deviceService.sendRequest(this.vehicleId + "", req).then((request: Rest.RequestMessage) => {
         //Register the requestId associated to the device in the actuatorMessageWatch collection
         this.actuatorMessageWatch[req.actuatorId] = request.id;

         //Inform the realTimeData service that we are interested in recieving the response for the request
         this.realTimeDataService.watchRequestResponse(request.id, this.vehicleId);

         this.notificationService.add(Severity.success, this.i18n._(':)'), this.i18n._('SMS queued. Waiting for message to be sent'));

     }).catch((e: any) => {
         this.notificationService.add(Severity.error, this.i18n._(':('), this.i18n._('Unable to request an actuator change'));
     });*/

    this.content = "";
  }

  closeSendMessageModal(): void {
    this.content = "";
  }

  ngOnChanges(changes: SimpleChanges) {

  }


}
