<div class='flex-column justify-content-center align-items-center'>
	<div class='flex-column justify-content-center p-5'>

		<!-- RACING PRIMENG DATATABLE -->
		<p-table [value]='paginationResult.entities'
				 [lazy]='true'
				 (onLazyLoad)='loadData($event)'
				 [rows]='paginationRequest.pageSize'
				 [totalRecords]='paginationResult.filteredEntities'
				 [rowsPerPageOptions]='[10,20,30,40,50]'
				 [paginator]='true'
				 [rowHover]='true'
				 [showCurrentPageReport]='true'
				 [autoLayout]='true'
				 currentPageReportTemplate=
				 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
				 class='custom-table'
				 styleClass='p-datatable-striped'
		>

			<!-- TABLE TITLE DEFINITION -->
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
					{{ 'events.eventsTable' | translate }}
				</div>
			</ng-template>

			<!-- TABLE COLUMN DEFINITION -->
			<ng-template pTemplate='header'>
				<tr>

					<!-- SELECT -->
					<th class='checkbox-column'>
						<!-- EMPTY -->
					</th>

					<!-- NAME -->
					<th pSortableColumn='nameEvent'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.name' | translate }}
							</div>

							<!-- COLUMN SORT && FILTER ICONS -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='nameEvent'></p-sortIcon>

								<!-- COLUMN FILTER -->
								<p-columnFilter field='nameEvent'
												type='text'
												placeholder="{{ 'general.search' | translate }}"
												display='menu'
												[showMatchModes]='false'
												[showOperator]='false'
												[showAddButton]='false'
								></p-columnFilter>

							</div>

						</div>
					</th>

					<!-- TYPE -->
					<th pSortableColumn='type'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.type' | translate }}
							</div>

							<!-- COLUMN SORT ICON -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='type'></p-sortIcon>

							</div>

						</div>
					</th>

					<!-- STATUS -->
					<th>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.status' | translate }}
							</div>

						</div>
					</th>

					<!-- START DATE -->
					<th pSortableColumn='startDate'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.startDate' | translate }}
							</div>

							<!-- COLUMN SORT ICON -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='startDate'></p-sortIcon>

							</div>

						</div>
					</th>

					<!-- END DATE -->
					<th pSortableColumn='endDate'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'general.endDate' | translate }}
							</div>

							<!-- COLUMN SORT ICON -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='endDate'></p-sortIcon>

							</div>

						</div>
					</th>

					<!-- START PUBLICATION DATE -->
					<th pSortableColumn='startPublicationDate'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'events.startDatePublication' | translate }}
							</div>

							<!-- COLUMN SORT ICON -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='startPublicationDate'></p-sortIcon>

							</div>

						</div>
					</th>

					<!-- END PUBLICATION DATE -->
					<th pSortableColumn='endPublicationDate'>
						<div class="d-flex align-items-center h-100">

							<!-- COLUMN TITLE -->
							<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
								{{ 'events.endDatePublication' | translate }}
							</div>

							<!-- COLUMN SORT ICON -->
							<div class="d-flex align-items-center h-100">

								<!-- COLUMN SORT -->
								<p-sortIcon field='endPublicationDate'></p-sortIcon>

							</div>

						</div>
					</th>

				</tr>
			</ng-template>

			<!-- TABLE BODY -->
			<ng-template pTemplate='body' let-racing>
				<tr class='p-selectable-row custom-rows' (dblclick)='editRacingEvent(racing)'>

					<!-- DELETE -->
					<td>

						<button type='button'
								class='btn custom-table-remove-button'
								(click)='deleteRacingEvent(racing)'
								[disabled]='racing.startDate < date_now'
						>
							<svg-icon src='assets/icons/trash.svg'
									  class='d-flex flex-wrap custom-table-remove-button-icon'
									  [svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
							></svg-icon>
						</button>

					</td>

					<!-- NAME -->
					<td>
						{{racing.nameEvent}}
					</td>

					<!-- TYPE -->
					<td>
						{{racing.eventType.nameEvent}}
					</td>

					<!-- STATUS -->
					<td>
						<div *ngIf='racing.startDate <= date_now'>

							<!-- EVENT STARTED -->
							<div *ngIf='racing.endDate > date_now '>
								{{ 'events.started' | translate }}
							</div>

							<!-- EVENT ENDED -->
							<div *ngIf='racing.endDate <= date_now'>
								{{ 'events.ended' | translate }}
							</div>

							<!-- EVENT PENDING -->
							<div *ngIf='racing.startDate > date_now'>
								{{ 'events.pending' | translate }}
							</div>

						</div>
					</td>

					<!-- START DATE -->
					<td>
						{{ racing.startDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- END DATE -->
					<td>
						{{ racing.endDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- START PUBLICATION DATE -->
					<td>
						{{ racing.startPublicationDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

					<!-- END PUBLICATION DATE -->
					<td>
						{{racing.endPublicationDate | date: 'dd/MM/yy HH:mm:ss' }}
					</td>

			</ng-template>

			<!-- TABLE EMPTY MESSAGE -->
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message"
							 i18n>
							{{ 'table.tableEmptyMessage' | translate }}!
						</div>
					</td>
				</tr>
			</ng-template>

			<!-- FOOTER -->
			<ng-template pTemplate="footer">
				<tr>
					<td colspan="8" class="p-0">
						<div class="d-flex flex-row align-items-center p-3 gap-3">

							<!-- ADD RACING EVENT BUTTON -->
							<app-table-add-button [text]=" 'events.event' | translate "
												  (btnClick)="createRacingEvent()"
							></app-table-add-button>

						</div>
					</td>
				</tr>
			</ng-template>

		</p-table>

	</div>
</div>

<!-- DELETE EVENT DIALOG -->
<p-confirmDialog #deleteEventDialog
				 [header]=" 'control-panel.delete-dialog.title' | translate "
				 class='custom-dialog'
				 icon='pi pi-exclamation-triangle'
>
	<ng-template pTemplate="footer">

		<!-- REJECT BUTTON -->
		<button (click)="deleteEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>

		<!-- ACCEPT BUTTON -->
		<button (click)="deleteEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>

	</ng-template>
</p-confirmDialog>

<!-- RACING FORM -->
<app-racing-form (racingEventAdded)='addRacingEvent()'
				 (racingEventSaved)='saveRacingEvent()'
				 [eventTypes]='availableTypeEvent'
				 [eventCodeTypes]='availableTypeCode'
				 [selectedRacingEvent]='selectedRacingEvent'
></app-racing-form>
