<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Harsh Behavior
          Monitoring (AT + GTHBM)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <p-accordionTab [selected]="true">
      <p-header>
        <div class="ui-helper-clearfix">
          <div class="left">
            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
              Settings</span>
          </div>
        </div>
      </p-header>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Enable Harsh Behavior Monitoring">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="hbmParameters.modeEnabled"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">

        <div class="col row align-items-center">
          <form-input fieldName="High Speed" [style]="{'margin-top':'30px'}"></form-input>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="High Speed">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="hbmParameters.highSpeed" [min]="100" [max]="400"></p-spinner>
                <span class="ui-inputgroup-addon">100~400</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

          <div class="row align-items-center">

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="6Vhb">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="hbmParameters.vhb" [min]="0" [max]="100"></p-spinner>
                  <span class="ui-inputgroup-addon">0~100</span>
                  <span class="ui-inputgroup-addon">km/h</span>
                </div>
              </div>
            </div>

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="6Vha">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="hbmParameters.vha" [min]="0" [max]="100"></p-spinner>
                  <span class="ui-inputgroup-addon">0~100</span>
                  <span class="ui-inputgroup-addon">km/h</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="col row align-items-center">
          <form-input fieldName="Medium Speed" [style]="{'margin-top':'30px'}"></form-input>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Medium Speed">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="hbmParameters.mediumSpeed" [min]="60" [max]="100"></p-spinner>
                <span class="ui-inputgroup-addon">60~100</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

          <div class="row align-items-center">

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="6Vmb">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="hbmParameters.vmb" [min]="0" [max]="100"></p-spinner>
                  <span class="ui-inputgroup-addon">0~100</span>
                  <span class="ui-inputgroup-addon">km/h</span>
                </div>
              </div>
            </div>

            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input fieldName="6Vma">
                </form-input>
              </div>
              <div class="col">
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="hbmParameters.vma" [min]="0" [max]="100"></p-spinner>
                  <span class="ui-inputgroup-addon">0~100</span>
                  <span class="ui-inputgroup-addon">km/h</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="col row align-items-center">
          <form-input fieldName="Low Speed" [style]="{'margin-top':'30px'}"></form-input>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="6Vlb">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="hbmParameters.vlb" [min]="0" [max]="100"></p-spinner>
                <span class="ui-inputgroup-addon">0~100</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="6Vla">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="hbmParameters.vla" [min]="0" [max]="100"></p-spinner>
                <span class="ui-inputgroup-addon">0~100</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </p-accordionTab>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>


        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="hbmParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="hbmParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>

        </div>
      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>