<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Power Saving Configuration ($ST+PMGR)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g-12 ui-md-6">
    <form-input  fieldName="Low Voltage" >
      <div class="ui-inputgroup">
        <p-spinner size="5" [(ngModel)]="powerSaving.lowVoltage" [min]="8" [max]="30"></p-spinner>
        <span class="ui-inputgroup-addon">8~30</span>
        <span class="ui-inputgroup-addon" >v</span>
      </div>
    </form-input>
  </div>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Sleep Mode" >
            <p-dropdown  [options]="SleepModeOptions"
            [(ngModel)]="powerSaving.mode" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Delay" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="powerSaving.delay" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Power up Duration" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="powerSaving.powerUpDuration" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Power Down Duration" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="powerSaving.powerDownDuration" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~65535</span>
                <span class="ui-inputgroup-addon" >s</span>
              </div>
            </form-input>
          </div>     
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Sleep Priority">
              <p-inputSwitch [(ngModel)]="powerSaving.sleepPriority"></p-inputSwitch>
            </form-input>
          </div>   
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Shock Sensor">
              <p-inputSwitch [(ngModel)]="powerSaving.enableShockSensor"></p-inputSwitch>
            </form-input>
          </div>      
      </div>
</p-accordionTab>
