import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';

import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { TaskUseful } from 'app/modules/task/shared/task_useful';

import { VehiclesgroupService } from 'app/rest/vehiclesgroup.service';
import { ChartConfig, DashboardsChartsService } from '../services/dashborads-charts.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from 'app/services/i18n/i18n.service';

import { Color, ScaleType } from '@swimlane/ngx-charts';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboards-charts',
  templateUrl: './dashboards-charts.component.html',
  styleUrls: ['./dashboards-charts.component.css'],
  providers: [VehiclesgroupService, DashboardsChartsService],
  encapsulation: ViewEncapsulation.None
})

export class DashboardsChartsComponent implements OnInit {
  vehicleGroupService: VehiclesgroupService;

  name = 'Angular';
  width: number = 800;
  height: number = 550;
  fitContainer: boolean = false;

  formattedStartDate: string;
  formattedEndDate: string;

  request: Rest.DashBoardChartsRequest;

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;

  showXAxisLabel = true;
  xAxisLabel = 'Dies';
  showYAxisLabel = true;
  yAxisLabel = 'Kg de Cafè';
  timeline = true;
  doughnut = true;

  isFullScreen: boolean = false;
  view: any[] = [undefined, undefined];

  // DROPDOWN OPTIONS
  groups: Rest.VehiclesGroup[];
  selectedGroups: Rest.VehiclesGroup[] = [];
  pageRequest: Rest.ListPaginationRequest;
  dates: Date[];

  totalSelectedGroups: number = 0;

  // GENERAL VARIABLES
  chartsLoaded: boolean = false;

  // CUSTOM COLOR SCHEMES FOR THE CHARTS
  customColorsBrown: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#8B4513', '#A0522D', '#D2B48C', '#DEB887', '#F4A460']
  };

  // Define a custom color scheme around #C42C22
  customColorsRed: Color = {
    name: 'customRed',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      '#F75D50', // Lighter (mixed with white)
      '#E3472F', // Slightly lighter
      '#C42C22', // Base color
      '#A1241B', // Slightly darker
      '#7E1D15'  // Darker (mixed with black)
    ]
  };

  // Define a custom blue color scheme
  customColorsBlue: Color = {
    name: 'customBlue',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      '#5A7FA9', // Lighter
      '#416C90', // Slightly lighter
      '#204C7E', // Base color
      '#1A3D64', // Slightly darker
      '#142E4A'  // Darker
    ]
  };

  constructor(
    vehicleGroupService: VehiclesgroupService,
    private service: DashboardsChartsService,
    protected i18n: I18nService,
    public translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.vehicleGroupService = vehicleGroupService;
  }

  ngOnInit(): void {
    this.pageRequest = RestExt.firstPageRequest();

    this.dates = [new Date(), new Date()]; // Default to today's date

    this.updateDateRange();
    this.getGroups();
    this.setupRequest();
  }

  //Method to change the request and pass it into the service 
  applyFilters() {
    this.updateRequest();
  }

  // LOADING ALL THE GROUPS FOR THE DROPDOWN
  getGroups() {
    var that = this;

    this.pageRequest.filters = {};
    this.pageRequest.pageSize = 1000000;

    this.vehicleGroupService.getTreeGroups(this.pageRequest).then(
      function (response) {
        that.groups = response.entities;
      },
      function (error) {
      }
    )
      .catch(function (error) {
        console.log(error);
      }
      );
  }

  setupRequest() {
    this.request = {
      coffeeMakers: null,
      groups: null, // Assuming 'selectedGroups' is an array of group objects with an 'id' property
      startDate: this.dates[0],
      endDate: this.dates[1]
    };
    this.loadData();
  }

  //update the data of the request
  updateRequest() {
    this.request.groups = this.selectedGroups as any[];
    this.request.startDate = this.dates[0];
    this.request.endDate = this.dates[1];
    this.loadData();
  }

  loadData() {
    forkJoin({
      chartsResponse: this.service.getDashBoardChartsData(this.request),
      coffeeMakerUsage: this.service.getDashBoardCoffeMakerUser(this.request)
    })
      .subscribe({
        next: ({ chartsResponse, coffeeMakerUsage }) => {
          this.updateCharts(chartsResponse);
          this.updateCoffeeMakerChart(coffeeMakerUsage); // Update the coffee maker chart

          console.log('All chart data loaded:', { chartsResponse, coffeeMakerUsage });
        },
        error: (error) => {
          console.error('Failed to load chart data:', error);
        }
      });
  }

  updateCharts(data: Rest.DashBoardChartsResponse) {
    // Update chart data methods...

    if (this.totalSelectedGroups !== this.selectedGroups.length) {
      this.totalSelectedGroups = this.selectedGroups.length;
      this.charts2dData[0].colorScheme = { domain: this.generateColorScheme(data.coffeeMakerUse.length) };
    }

    this.charts[0].data = this.convertWeekData(data.consumption);
    this.charts[1].data = this.convertWeekData(data.alerts);
    this.charts[2].data = this.convertWeekData(data.orders);
  }

  updateCoffeeMakerChart(coffeeMakerUsage: Rest.CoffeeMakerUseResponse) {
    this.charts2dData[0].data = this.convertHourlyData(coffeeMakerUsage.coffeeMakerUse); // Update your specific chart for coffee maker usage
    // Adjust color schemes or other chart properties as needed
  }

  // Data conversion methods
  convertWeekData(weeksData: Rest.SmartCoffeeWeek[]): { name: string, value: number }[] {
    return weeksData.map(week => ({
      name: `${week.week}`,
      value: week.value
    }));
  }

  convertHourlyData(coffeeMakersUsage: Rest.CoffeeMakerHourlyUse[]): any[] {
    if (!Array.isArray(coffeeMakersUsage)) {
      console.error('Invalid data type for coffeeMakersUsage:', JSON.stringify(coffeeMakersUsage));
      return []; // Return empty array or handle error as appropriate
    }

    const hourlyData = {}; // Object to accumulate the hourly data

    // Loop through each coffee maker's usage data
    coffeeMakersUsage.forEach(coffeeMakerUsage => {
      coffeeMakerUsage.hourUse.forEach(hourUse => {
        if (!hourlyData[hourUse.hour]) {
          hourlyData[hourUse.hour] = {
            name: hourUse.hour,
            series: []
          };
        }
        // Add each coffee maker's data to the corresponding hour
        hourlyData[hourUse.hour].series.push({
          name: coffeeMakerUsage.coffeeMaker.name,
          value: hourUse.value
        });
      });
    });

    // Convert the accumulated object into an array suitable for ngx-charts
    const result = Object.keys(hourlyData).sort().map(hour => hourlyData[hour]);

    return result;
  }

  // DATE MANIPULATION METHODS
  updateDateRange(): void {
    let endDate: Date = new Date(); // Today's date
    let startDate: Date = new Date(); // This will be adjusted based on selection

    switch (this.selectedDateRange) {
      case 'lastWeek':
        startDate = this.startOfWeek(new Date(endDate)); // Monday of this week
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6); // Sunday of this week
        break;
      case 'lastMonth':
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case 'last6Months':
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case 'lastYear':
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      case 'custom':
        break;
      default:
        return;
    }

    startDate.setHours(0, 0, 0, 0); // Start of the day for startDate
    endDate.setHours(23, 59, 59, 999); // End of the day for endDate

    let startdDateToFormat = startDate;
    let endDateToFormat = endDate;

    this.formattedStartDate = TaskUseful.formatdateAPI(startdDateToFormat);
    this.formattedEndDate = TaskUseful.formatdateAPI(endDateToFormat);

    console.log("Calculated Start Date:", this.formattedStartDate);
    console.log("Calculated End Date:", this.formattedEndDate);

    // Assign into the start and end dates the formatted string dates
    startDate = new Date(this.formattedStartDate);
    endDate = new Date(this.formattedEndDate);

    this.formattedStartDate = this.formatDateForDisplay(TaskUseful.formatdateAPI(startdDateToFormat));
    this.formattedEndDate = this.formatDateForDisplay(TaskUseful.formatdateAPI(endDateToFormat));

    this.dates = [startDate, endDate];
  }

  startOfWeek(date: Date): Date {
    const day = date.getDay(); // Get current day number, Sunday - 0, Monday - 1, ..., Saturday - 6
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust to the previous Monday
    date.setDate(diff);
    return new Date(date); // Return new Date object for immutability
  }

  onDateSelected(dateRange: { startDate: Date, endDate: Date }): void {
    let endDate: Date = dateRange.endDate; // Today's date
    let startDate: Date = dateRange.startDate; // This will be adjusted based on selection

    startDate.setHours(0, 0, 0, 0); // Start of the day for startDate
    endDate.setHours(23, 59, 59, 999); // End of the day for endDate

    let startdDateToFormat = startDate;
    let endDateToFormat = endDate;

    this.formattedStartDate = TaskUseful.formatdateAPI(startdDateToFormat);
    this.formattedEndDate = TaskUseful.formatdateAPI(endDateToFormat);

    console.log("Calculated Start Date:", this.formattedStartDate);
    console.log("Calculated End Date:", this.formattedEndDate);

    // Assign into the start and end dates the formatted string dates
    startDate = new Date(this.formattedStartDate);
    endDate = new Date(this.formattedEndDate);

    this.dates = [startDate, endDate];
  }

  endOfWeek(date: Date): Date {
    date.setHours(23, 59, 59, 999); // Set time to just before midnight
    return date;
  }

  formatDateForDisplay(dateString: string): string {
    // Split the dateString at 'T' to ignore time, then split the date part by '-' to get components
    const [year, month, day] = dateString.split('T')[0].split('-');
    // Reformat to 'dd/MM/yyyy'
    return `${day}/${month}/${year}`;
  }

  // Generates a random color in hexadecimal format
  generateRandomColor(): string {
    let letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }

  // Generates an array of random colors
  generateColorScheme(dataLength: number): string[] {
    return Array.from({ length: dataLength }, () => this.generateRandomColor());
  }

  // FORM DATA
  dateOptions: SelectItem[] = [
    { label: this.translateService.instant('general.timeFrames.lastWeek'), value: 'lastWeek' },
    { label: this.translateService.instant('general.timeFrames.lastMonth'), value: 'lastMonth' },
    { label: this.translateService.instant('general.timeFrames.last6Months'), value: 'last6Months' },
    { label: this.translateService.instant('general.timeFrames.lastYear'), value: 'lastYear' },
    { label: this.translateService.instant('general.timeFrames.custom'), value: 'custom' }
  ];
  selectedDateRange: string = 'lastWeek';  // Default to 'lastWeek' to hide the datapicker

  charts: ChartConfig[] = [
    {
      data: [{ name: '1', value: 20 }, { name: '2', value: 80 }],
      colorScheme: this.customColorsBrown,
      title: this.translateService.instant('dashboard-charts.consumption'),
      xAxisLabel: this.translateService.instant('dashboard-charts.weeks'),
      yAxisLabel: this.translateService.instant('dashboard-charts.coffeeKg'),
    },
    {
      data: [{ name: '1', value: 20 }, { name: '2', value: 80 }],
      colorScheme: this.customColorsRed,
      title: this.translateService.instant('dashboard-charts.alarms'),
      xAxisLabel: this.translateService.instant('dashboard-charts.weeks'),
      yAxisLabel: ''
    },
    {
      data: [{ name: '1', value: 20 }, { name: '2', value: 80 }],
      colorScheme: this.customColorsBlue,
      title: this.translateService.instant('dashboard-charts.buys'),
      xAxisLabel: this.translateService.instant('dashboard-charts.weeks'),
      yAxisLabel: this.translateService.instant('dashboard-charts.coffeeKg'),
    },
  ];

  charts2dData: ChartConfig[] = [
    {
      data: [
        {
          "name": "1",
          "series": [
            {
              "name": "Test1",
              "value": 2243772
            },
            {
              "name": "Cofee",
              "value": 1227770
            },
          ]
        },
      ],
      colorScheme: { domain: ['#5AA454', '#51bef5'] },
      title: this.translateService.instant('dashboard-charts.usePattern'),
      xAxisLabel: this.translateService.instant('dashboard-charts.hours'),
      yAxisLabel: this.translateService.instant('dashboard-charts.uses'),
    },
  ];

  // FULL SCREEN RESPONSIVENESS METHODS
  toggleFullscreen(elem: HTMLElement, chartType: string): void {
    if (!document.fullscreenElement) {
      elem.requestFullscreen().then(() => {
        this.isFullScreen = true;
        // Immediately update dimensions for fullscreen
        this.updateChartView(elem, chartType, true);
      }).catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen().then(() => {
        this.isFullScreen = false;
        // Reset dimensions when exiting fullscreen
        this.updateChartView(elem, chartType, false);
      });
    }
  }

  updateChartView(elem: HTMLElement, chartType: string, isFullscreen: boolean) {
    if (isFullscreen) {
      this.view = [elem.clientWidth - 15, elem.clientHeight - 100]; // Adjust the chart view to fit the fullscreen
    } else {
      this.view = [undefined, undefined]; // Reset the chart view to auto-resize
    }
    this.cdRef.detectChanges(); // Trigger Angular to update the view
  }

}