<p-dialog [responsive]="true" [contentStyle]="{minHeight: '250px'}" [(visible)]="openSummary" dismissableMask=true
    closeOnEscape=true responsive=true modal=true class="dialogSummary">
    <p-header>

        <h3 class="component_in_line1">
            Resum de punts de control
        </h3>
    </p-header>
    <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    <!-- [sortField]="sortField" [sortOrder]="sortOrder" [rowStyleClass]="onRowRender"  scrollWidth="${showMapMode == false ? 100vw : 100%}" -->


  <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
 -->

  <!-- PrimeNG DataTable -->
  <p-table #table
           [value]="rows"
           [autoLayout]="true"
           scrollHeight="50vh"
           [scrollable]="false"
           styleClass="dtSummary"
  >

    <!-- Table Column/Header Definition -->
    <ng-template pTemplate="header" let-col="cols">
      <tr>

        <th pSortableColumn="group" [style]="{'min-width':'250px'}" class="text-center">
          <div class="p-d-flex p-jc-between p-ai-center">Grup<p-sortIcon field="group"></p-sortIcon>
          </div>
        </th>

        <th *ngFor="let col of cols" [style]="{'min-width':'100px'}" class="text-center">
          <div>

            <!-- HEADER -->
            <a [title]="col.header">{{col.header}}</a>

          </div>
        </th>

      </tr>
    </ng-template>

    <!-- Table Row Definition -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
      <tr>

        <td class="text-center">
          <div (click)="groupClicked(rowData.group)" [title]="rowData.group"
               class="overflow cellClicable" [ngClass]="{true:'row-total'}[rowData.isTotal]" >
            {{rowData.group}}
          </div>
        </td>

        <ng-container *ngFor="let col of cols">
            <ng-container *ngFor="let field of rowData.fields">
              <td *ngIf="field.column === col.field" class="text-center">
                <div [title]="field" class="overflow" [ngStyle]="{
                  'background-color': rowData.isTotal === false ? field.color : '#FFF2CC',
                  'color': rowData.isTotal === false ? 'white' : 'red',
                  'font-weight': rowData.isTotal === false ? 'normal' : 'bold'
                }">
                  {{ field.count }}
                </div>
              </td>
            </ng-container>
        
          <td *ngIf="col.field === 'total_detected'" class="text-center">
            <div [title]="rowData.total_detected" class="overflow cellClicable" [ngClass]="{true:'row-total'}[rowData.isTotal]">
              {{rowData.total_detected}}
            </div>
          </td>
        
          <td *ngIf="col.field === 'pdc_count'" class="text-center">
            <div [title]="rowData.pdc_count" class="overflow" [ngClass]="{true:'row-total'}[rowData.isTotal]">
              {{rowData.pdc_count}}
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-template>

  </p-table>


  <!--<p-dataTable #table responsive="true" [value]="rows" scrollable="true"
        scrollHeight="50vh" scrollWidth="calc(100% + 88px)" class="dtSummary">
        &lt;!&ndash; rowStyleClass="#{!row.isTotal ? 'dtSummary' : null}" &ndash;&gt;
        &lt;!&ndash; CUSTOM COLUMNS&ndash;&gt;

       &lt;!&ndash; <p-column width="auto" *ngFor="let col of cols;  let i = index" [field]="col.field" [header]="col.header" >&ndash;&gt;
            &lt;!&ndash;CUSTOM ROWS&ndash;&gt;
       &lt;!&ndash; COLUMN WIH FILTER AND ORDER&ndash;&gt;
        <p-column field="group" header="Grupo" [sortable]="true" [style]="{'width': '220px'}">
            <ng-template  let-row="rowData" pTemplate="body">
                <div (click)="groupClicked(row.group)" [title]="row.group"
                    class="overflow ellipsis cellClicable" [ngClass]="{true:'row-total'}[row.isTotal]" >
                    {{row.group}}
                </div>
            </ng-template>
        </p-column>

        <p-column width="auto" *ngFor="let col of cols;  let i = index" [field]="col.field" [header]="col.header" >
            <ng-template *ngIf="col.field === 'field_4'" let-row="rowData" pTemplate="body">
                <div [title]="row.field_4" class="overflow ellipsis"   [ngClass]="{'row-black': row.isTotal==false, 'row-total': row.isTotal==true}">
                    {{row.field_4}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'field_3'" let-row="rowData" pTemplate="body">
                <div [title]="row.field_3" class="overflow ellipsis"   [ngClass]="{'row-red': row.isTotal==false, 'row-total': row.isTotal==true}">
                    {{row.field_3}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'field_2'" let-row="rowData" pTemplate="body">
                <div [title]="row.field_2" class="overflow ellipsis"  [ngClass]="{'row-yellow': row.isTotal==false, 'row-total': row.isTotal==true}">
                    {{row.field_2}}
                </div>
            </ng-template>
            <ng-template  *ngIf="col.field === 'field_1'" let-row="rowData" pTemplate="body">
                <div [title]="row.field_1" class="overflow ellipsis"   [ngClass]="{true:'row-total',false:'row-green'}[row.isTotal]">
                    {{row.field_1}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'total_detected'" let-row="rowData" pTemplate="body">
                <div [title]="row.total_detected" class="overflow ellipsis cellClicable"   [ngClass]="{true:'row-total'}[row.isTotal]">
                    {{row.total_detected}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'pdc_count'" let-row="rowData" pTemplate="body">
                <div  [title]="row.pdc_count" class="overflow ellipsis"  [ngClass]="{true:'row-total'}[row.isTotal]">
                    {{row.pdc_count}}
                </div>
            </ng-template>
        </p-column>


    </p-dataTable>-->
    <p-footer>
        <button pButton type="button" (click)="closeSummary()" i18n-label label="Tancar"></button>
    </p-footer>
</p-dialog>
