
<p-dialog header="{{ 'sat.statusVehicle.header' | translate }}" [(visible)]="display"[draggable]="false" [responsive]="false"
[resizable]="false" showEffect="fade" [modal]="true" *ngIf="display">
  <div class="container-xl">
    <div class="tables-container" >
      <table class="sensor-table">
        <thead>
          <tr>
            <th style="color:green; font-weight: bold !important;" colspan="3">{{ 'sat.statusVehicle.sensorTable.header' | translate }}</th>
          </tr>
        </thead>
        <tbody>
<!--  if i don't put the style color for every row and the button that opens this dialog is in a p-table heredates this style .custom-table .p-datatable-tbody > .custom-rows:hover td 
   and the text is white-->
          <tr> 
            <td style="color:#4D4D4C !important;">{{entity.lastGPSDateTime | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td style="color:#4D4D4C !important;">{{ 'sat.statusVehicle.sensorTable.latitude' | translate }}</td>
            <td style="color:#4D4D4C !important;">{{ entity?.latitude }}</td>
          </tr>
          <tr>
            <td style="color:#4D4D4C !important;">{{entity.lastGPSDateTime | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td style="color:#4D4D4C !important;">{{ 'sat.statusVehicle.sensorTable.longitude' | translate }}</td>
            <td style="color:#4D4D4C !important;">{{ entity?.longitude }}</td>
          </tr>
          <tr>
            <td style="color:#4D4D4C !important;">{{entity.lastGPSDateTime | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td style="color:#4D4D4C !important;">{{ 'sat.statusVehicle.sensorTable.speed' | translate }}</td>
            <td style="color:#4D4D4C !important;">{{ entity?.speed }}</td>
          </tr>
          <tr>
            <td style="color:#4D4D4C !important;">{{entity.platformTime | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td style="color:#4D4D4C !important;">{{ 'sat.statusVehicle.sensorTable.platformTime' | translate }}</td>
            <td style="color:#4D4D4C !important;">{{ entity?.platformTime | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          </tr>

          <tr *ngFor="let sensor of entity?.sensors">
            <td style="color:#4D4D4C !important;">{{ sensor.time | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
            <td style="color:#4D4D4C !important;">{{ sensor.tag }}</td>
            <td style="color:#4D4D4C !important;">{{ sensor.numericValue }}</td>
          </tr>
        </tbody>
      </table>
      <table class="additional-data-table">
        <thead>
        </thead>
        <tbody>

          <tr>
            <td style="color: blue !important;
            font-weight: bold !important;">{{ 'sat.statusVehicle.statusTable.status' | translate }}</td>
            <td style="color:#4D4D4C !important;" colspan="2"> 
              <span [ngStyle]="getStatusStyle()">{{ statusText }}</span>&nbsp;
              <span *ngIf="status !== 'Desconegut'">{{ 'sat.statusVehicle.statusTable.statusLabel' | translate }} {{ dateStatus }}</span>
            </td>
          </tr>
          <tr>
            <td style="color: blue !important;
            font-weight: bold !important;">{{ 'sat.statusVehicle.statusTable.place' | translate }} </td>
            <td style="color:#4D4D4C !important;" colspan="2">{{address}}</td>
          </tr>
          <tr>
            <td style="color: blue !important;
            font-weight: bold !important;">{{ 'sat.statusVehicle.statusTable.speed' | translate }}</td>
            <td style="color:#4D4D4C !important;"colspan="2"> <span style="font-weight: bold;">{{ entity?.speed }} KM/H </span>&nbsp; {{ entity?.lastGPSDateTime | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
          </tr>
          
        </tbody>
      </table>
    </div>

    <div class="container p-4">
        <app-custom-map mapId="mapVehicleState" [options]="customMapOptions" [display]="true"[markers]="markers" [showCercacliaOptions]="false">

        </app-custom-map>
    </div>




    
  </div>
  <p-footer>

    <div class="d-flex justify-content-center bd-highlight">

      <div class="p-2 bd-highlight">

        <div>
          <button type="button" pButton icon="fa fa-check" (click)="refresh()" label="{{ 'sat.statusVehicle.refresh' | translate }}"></button>
        </div>


      </div>

    </div>

  </p-footer>
  </p-dialog>
