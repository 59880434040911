<!-- CONNECTION WATCH MAIN CONTAINER-->
<div class="container-fluid p-0 custom-panel info-card-main-container" >
 
  <!-- ROW 1: TITLE & ICON -->
  <div class="row">
 
    <!-- COL 1: TITLE-->
    <div class="col-7">
 
      <!-- ROW 1: TITLE -->
      <div class="row p-3">
        <div class="custom-title info-card-title">{{'connectionStatus.title' | translate}}</div>
      </div>
 
    </div>
 
    <!-- COL 2: ICON -->
    <div class="col ms-4 p-3">
      <div class="d-flex align-items-center justify-content-center">
        <div [className]="rtConnectionInfoSeverity">
          <i class="fa fa-wifi connection-status-icon" [title]="rtConnectionInfoText" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
 
  <!-- ROW 2: CONNECTION MESSAGE -->
  <div class="row p-3">
    <div class="d-flex">
 
      <div *ngIf="rtConnectionInfoSeverity === 'info'" class="info-card-statistics-text text-break">
        {{'general.connecting' | translate}}
      </div>
 
      <div *ngIf="rtConnectionInfoSeverity === 'success'" class="info-card-statistics-text text-break severity-success">
        {{'general.connected' | translate}}
      </div>
 
      <div *ngIf="rtConnectionInfoSeverity === 'error'" class="info-card-statistics-text text-break severity-error">
        {{'general.notConnected' | translate}}. {{'connectionStatus.nextRetryIn' | translate}} {{reconnectionTimer}}
      </div>
 
    </div>
  </div>
</div>
