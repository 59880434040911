import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UteService } from "app/rest/ute.service";
import { I18nService } from "app/services/i18n/i18n.service";
import {
  NotificationsService,
  Severity,
} from "app/services/notifications-service/notifications.service";
import { LoadingService } from "app/services/loading-service/loading.service";
import { VehicleHistoryRow } from "../models/Rows";
import { RealtimeService } from "app/rest/realtime.service";
import { ZonegroupService } from "app/rest/zonegroup.service";
import { environment } from "environments/environment";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";
import { Output, EventEmitter } from "@angular/core";
import { ExcelService } from "app/services/excel-service/excel.service";

@Component({
  selector: "app-ute-vehicle-history",
  templateUrl: "./ute-vehicle-history.html",
  styleUrls: ["./ute-vehicle-history.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [UteService, ZonegroupService, ExcelService],
})
export class UteVehicleHistory implements OnInit {
  header = "";
  env = environment;
  invalidRange = false;
  vehicleId: number = null;
  loading = false;
  dayIniSelected: Date;
  dayEndSelected: Date;
  PopUpOpen = false;
  rows: VehicleHistoryRow[] = [];
  cols: any[] = [
    {
      header: "Data inici",
      field: "visitDate",
      visible: true,
      filter: false,
    },
    {
      header: "Punt de control",
      field: "controlPoint",
      visible: true,
      filter: false,
    },

    {
      header: "Data Fi",
      field: "endVisitDate",
      visible: true,
      filter: false,
    },
    {
      header: "Durada",
      field: "durationVisit",
      visible: true,
      filter: false,
    },
    {
      header: "Km",
      field: "kmVisit",
      visible: true,
      filter: false,
    },

  ];
  name: string;
  @Output() followVehicleEvent = new EventEmitter<number[]>();

  followVehicleButton() {
    let vehiclesIds: number[] = [];
    vehiclesIds.push(this.vehicleId);
    this.PopUpOpen = false;
    //followVehicleId(vehiclesIds);
    this.followVehicleEvent.emit(vehiclesIds);
  }

  constructor(
    private zoneGroupService: ZonegroupService,
    private uteService: UteService,
    public i18n: I18nService,
    private notificationsService: NotificationsService,
    private loadingService: LoadingService,
    private realTimeService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private excelService:ExcelService
  ) {}

  ngOnInit() {}

  async show(name: string, id: number) {
    this.dayIniSelected = new Date(Date.now());
    this.dayEndSelected = new Date(Date.now());
    this.header = "Historial vehicle: " + name;
    this.name = name;
    this.vehicleId = id;
    this.rows = [];

    this.PopUpOpen = true;
    // WE WILL LOAD ROWS  WITH NAME ID this.loadChart(currenHumanID);

    await this.loadData();
  }

  async loadData() {
    let start = null;
    let end = null;
    let limit = null;
    start = this.dayIniSelected;
    start.setHours(0, 0, 0, 0);
    end = this.dayEndSelected;
    end.setHours(23, 59, 59, 0);

    this.loading = true;
    this.rows = await this.uteService.getVehicleHistory(
      this.vehicleId,
      start ? start.getTime() : null,
      end ? end.getTime() : null,
      limit
    );
    this.loading = false;
  }

  closeButton() {
    this.PopUpOpen = false;
  }

  exportExcel() {
    let element = document.getElementById("dt"); 
  this.excelService.exportTableAsExcelFile(element, this.name); 
  }
}
