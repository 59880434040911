<div class="p-0 custom-panel info-card-main-container">

    <!-- ROW 1: TITLE & ICON -->
    <div class="row" >
  
      <!-- COL 1: TITLE-->
      <div class="col-7 mx-3">
  
        <!-- ROW 1: TITLE -->
        <div class="row mt-3">
          <div class="custom-title info-card-title">{{'chartSettings.title' | translate}}</div>
        </div>
  
      </div>
  
    </div>
  
    <!-- ROW 2: CONNECTION MESSAGE -->
    <div class="row">
      <div class="d-flex m-0 p-0 chart-container">
        <app-chart-settings [chartOptions]="chartOptions" [height]="99" [width]="140"></app-chart-settings>
      </div>
    </div>
  
  </div>