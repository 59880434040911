<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >SERVER CONFIGURATION (SERVER)</span>
        </div>
      </div>
    </p-header>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Address Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown [options]="AddressOptions" [(ngModel)]="server.addressMode"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
        </div>
      </div>
    
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Address" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="server.address" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Port" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="number" pInputText [(ngModel)]="server.port" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Protocol Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown [options]="ProtocolOptions" [(ngModel)]="server.protocolMode"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
        </div>
      </div>
    
    </div>
  </p-accordionTab>
  