import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { TabView } from "primeng/tabview";

import { Auxiliar } from "../../../model/Auxiliar";
import { DeviceService } from "../../../rest/device.service";
import { FleetService } from "../../../rest/fleet.service";
import { IconService } from "../../../rest/icon.service";
import { MaintenanceService } from "../../../rest/maintenance.service";
import { MaintenanceplanService } from "../../../rest/maintenanceplan.service";
import { Rest } from "../../../rest/rest_client";
import { VehicleService } from "../../../rest/vehicle.service";
import { VehiclebrandService } from "../../../rest/vehiclebrand.service";
import { VehiclemodelService } from "../../../rest/vehiclemodel.service";
import { VehiclesgroupService } from "../../../rest/vehiclesgroup.service";
import { VehicletypeService } from "../../../rest/vehicletype.service";
import { ClientService } from "../../../rest/client.service";
import { BillingaccountService } from "../../../rest/billingaccount.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService, Severity, } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { MaintenancesListComponent } from "../../maintenances-list/maintenances-list.component";
import { ServerValidatedFormComponent } from "../../server-validated-form/server-validated-form.component";
import { UserTimePipe } from "../../shared/UserTimePipe";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { DistanceFormatPipe } from "../../shared/DistanceFormatPipe";
import { UserService } from '../../../rest/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: "app-vehicle-form",
    templateUrl: "./vehicle-form.component.html",
    styleUrls: ["./vehicle-form.component.css"],
    providers: [
        VehicleService,
        FleetService,
        VehiclesgroupService,
        VehicletypeService,
        DeviceService,
        ClientService,
        BillingaccountService,
        VehiclemodelService,
        VehiclebrandService,
        IconService,
        MaintenanceService,
        ConfirmationService,
        MaintenanceplanService,
        UserTimePipe,
        DistanceFormatPipe,
        I18nService,
        UserService
    ],
})
export class VehicleFormComponent
    extends ServerValidatedFormComponent
    implements OnInit {
    private maintenanceList: MaintenancesListComponent;

    @ViewChild(MaintenancesListComponent) set content(
        content: MaintenancesListComponent
    ) {
        this.maintenanceList = content;
    }

    @ViewChild("tv") public tabView: TabView;

    messages: any[];

    selectedTab = 0;
    index = 0;
    vehicle: Rest.Vehicle;
    vehicleName: string;
    maintenancePlan: Rest.MaintenancePlan;
    client: Rest.Client;
    billingAccount: Rest.BillingAccount;
    owner: Rest.User;
    availableTypes: Rest.VehicleType[] = [];
    selectedType: Rest.VehicleType;
    selectedFleet: Rest.Fleet;
    selectedGroup: Rest.VehiclesGroup;
    selectedClient: Rest.Client;
    selectedBillingAccount: Rest.BillingAccount;

    /*availableClients: SelectItem[];
    selectedClient: Rest.Client;

    availableBillingAccounts: SelectItem[];
    selectedBillingAccount: Rest.BillingAccount;*/

    availableBrands: Rest.VehicleBrand[]= [];
    selectedBrand: Rest.VehicleBrand;

    availableModels: Rest.VehicleModel[] = [];

    maintenanceTabVisible: boolean;
    maintenanceTabSelected: boolean;

    display: Boolean;
    isNew: Boolean;
    showChangeStatus: Boolean;

    dropdownGroupsDisable: Boolean;
    dropdownBrandsDisable: Boolean;
    dropdownModelsDisable: Boolean;
    dropdownBillingAccountDisable: Boolean;
    dropdownOwnerDisable: Boolean;
    loadMaintenances: Boolean = false;

    disabledGroup: Boolean = false;

    displayAddBrand: Boolean = false;
    displayAddModel: Boolean = false;

    /**#ChangeStatus */
    showStatusDialog: boolean = false;
    selectedEntities: Rest.Vehicle[] = [];
    title: string;
    /*************** */

    /**Permissions */
    permissions: any;
    /** */

    tooltipGroup: string = this.i18n._("Please, select a fleet");
    user: Rest.User;
    selectClient = this.i18n._("Please, select a client");

    // eslint-disable-next-line
    @Output("newVehicle")
        // eslint-disable-next-line @angular-eslint/no-output-native
    change: EventEmitter<Rest.Vehicle> = new EventEmitter<Rest.Vehicle>();

    // eslint-disable-next-line
    @Output("refreshList")
    refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    @Output() return = new EventEmitter();
    @Output() selectNode = new EventEmitter();

    @Input() checkBoxDisabled: Map<string, boolean> = new Map<string, boolean>();
    clientUser: Rest.Client;
    /**Preservation of relationships */
    maintenancePlanRequestContext: Rest.ListPaginationRequest;
    deviceRequestContext: Rest.ListPaginationRequest;
    fleetRequestContext: Rest.ListPaginationRequest;
    vehicleGroupRequestContext: Rest.ListPaginationRequest;
    clientRequestContext: Rest.ListPaginationRequest;
    billingAccountRequestContext: Rest.ListPaginationRequest;
    userRequestContext: Rest.ListPaginationRequest;
    /*********************************/

    dateOfManufacture: Date;
    mileage: number;

    constructor(
        public vehicleService: VehicleService,
        public fleetService: FleetService,
        public groupService: VehiclesgroupService,
        private typeService: VehicletypeService,
        public clientService: ClientService,
        public billingaccountService: BillingaccountService,
        private modelService: VehiclemodelService,
        private brandService: VehiclebrandService,
        public deviceService: DeviceService,
        protected authenticationService: AuthenticationService,
        private iconService: IconService,
        private maintenanceService: MaintenanceService,
        private confirmationService: ConfirmationService,
        private notificationsService: NotificationsService,
        public maintenancePlanService: MaintenanceplanService,
        private userTime: UserTimePipe,
        private distancePipe: DistanceFormatPipe,
        public userService: UserService,
        public translate: TranslateService,
        i18n: I18nService
    ) {
        super(i18n);
    }

    ngOnInit() {
        this.title = this.i18n._("Vehicle");
        this.user = this.authenticationService.user;
        this.clientUser = this.authenticationService.user.client;
    }

    tabChanged(e) {
        this.maintenanceTabSelected = e.index == 1;
    }

    saveVehicle() {
        this.vehicle.dateOfManufacture = this.userTime.calendarDateToUTC(
            this.dateOfManufacture
        );
        this.vehicle.km = this.distancePipe.userMetricToSystemMetric(this.mileage);

        this.clearMessages();
        this.beforeSave();
        const that: VehicleFormComponent = this;
        if (this.isNew) {
            this.vehicleService
                .create(this.vehicle)
                .then(function (res) {
                    that.change.emit(res);
                    that.display = false;
                })
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.vehicleService
                .update(this.vehicle)
                .then(function (res) {
                    that.change.emit();
                    that.display = false;
                    that.notificationsService.add(Severity.success, "", this.translate.instant('general.updateSuccessfull'));
                })
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }

    show(isNew: boolean, entity: any, permissions: any) {
        this.isNew = isNew;
        const that = this;
        this.clearMessages();
        // if(this.authenticationService.getCurrentUser().isAdmin){
        //   this.isAdmin = true;
        // }else{this.isAdmin = false;}

        /***Preservation of relationships */
        this.maintenancePlanRequestContext = RestExt.firstPageRequest();
        this.deviceRequestContext = RestExt.firstPageRequest();
        this.fleetRequestContext = RestExt.firstPageRequest();
        this.vehicleGroupRequestContext = RestExt.firstPageRequest();
        this.clientRequestContext = RestExt.firstPageRequest();
        this.billingAccountRequestContext = RestExt.firstPageRequest();
        this.userRequestContext = RestExt.firstPageRequest();
        if (!this.isNew) {
            this.billingAccountRequestContext.context = {
                entity: "VEHICLE",
                action: "EDIT",
                id: entity.id,
            };

            this.maintenancePlanRequestContext.context = {
                entity: "VEHICLE",
                action: "EDIT",
                id: entity.id,
            };

            this.deviceRequestContext.context = {
                entity: "VEHICLE",
                action: "EDIT",
                id: entity.id,
            };

            this.fleetRequestContext.context = {
                entity: "VEHICLE",
                action: "EDIT",
                id: entity.id,
            };

            this.vehicleGroupRequestContext.context = {
                entity: "VEHICLE",
                action: "EDIT",
                id: entity.id,
            };
            
        }

        this.userRequestContext.context = {
            entity: "VEHICLE",
            action: "EDIT",
            id: entity.id,
        };
        /**********************************/

        this.permissions = permissions;
        this.disabledGroup = false;

        if (!this.isNew) {
            //Load maintenances for the vehicle
            this.maintenanceTabVisible = true;
            this.vehicle = { metadata: {} } as Rest.Vehicle;
            this.vehicleService
                .findVehicle(entity.id.toString())
                .then(function (response) {
                    that.vehicle = response;
                    that.vehicleName = that.vehicle.name;
                    that.dateOfManufacture = that.userTime.utcToCalendarDate(
                        that.vehicle.dateOfManufacture
                    );
                    that.mileage = that.distancePipe.systemMetricToUserMetric(
                        that.vehicle.km
                    );

                    that.selectedFleet = that.vehicle.group.fleet;
                    that.fleetChanged(that.selectedFleet);
                    that.selectedGroup = that.vehicle.group;
                    that.maintenancePlan = that.vehicle.maintenancePlan;
                    that.client = that.vehicle.billingAccount.client;
                    that.billingAccount = that.vehicle.billingAccount;
                    that.owner = that.vehicle.owner;

                    //Load available types, then brands then models
                    that.typeService
                        .getPage(RestExt.allRecordsRequest())
                        .then(function (response) {
                            that.availableTypes = response.entities;

                            that.selectedType = response.entities.find(
                                (b) => b.id == that.vehicle.model.type.id
                            );

                            that.modelService
                                .getBrandsOfType(that.vehicle.model.type.id.toString())
                                .then(function (response) {
                                    that.availableBrands = response;
                                    that.dropdownBrandsDisable = response.length <= 0;
                                    that.selectedBrand = that.availableBrands.find(
                                        (b) => b.id == that.vehicle.model.brand.id
                                    );


                                    that.modelService
                                        .getModelsOfBrand(
                                            that.vehicle.model.brand.id.toString() +
                                            "-" +
                                            that.vehicle.model.type.id
                                        )
                                        .then(function (response) {
                                            that.availableModels = response;
                                            that.dropdownModelsDisable = response.length <= 0;
                                            that.vehicle.model = response.find(
                                                (b) => b.id == that.vehicle.model.id
                                            );
                                        });
                                });

                        });

                    that.showChangeStatus = true;
                });

        } else {
            this.dateOfManufacture = null;
            this.mileage = null;
            this.client = null;
            this.dropdownModelsDisable = true;
            this.dropdownBillingAccountDisable = true;
            this.dropdownBrandsDisable = true;
            this.dropdownOwnerDisable = true;
            this.maintenanceTabVisible = false;
            const group: Rest.VehiclesGroup = entity as Rest.VehiclesGroup;
            const billingAccount: Rest.BillingAccount = entity.billingaccount;
            this.vehicle = { metadata: {}, group } as Rest.Vehicle;
            this.selectedFleet = group.fleet;
            this.fleetChanged(this.selectedFleet);
            this.selectedGroup = group;
            this.selectedBrand = null;
            this.selectedType = null;
            this.maintenancePlan = null;
            this.vehicle.maxSpeed = null;

            this.typeService
                .getPage(RestExt.allRecordsRequest())
                .then(function (response) {
                    that.availableTypes = response.entities;

                });
            this.availableBrands = [];
            this.availableModels = [];

            this.showChangeStatus = false;
        }
        this.display = true;

       
    }

    beforeSave() {
        this.vehicle.dateOfManufacture = this.userTime.calendarDateToUTC(
            this.dateOfManufacture
        );
        this.vehicle.billingAccount = this.billingAccount;
    }

    /**
     * When vehicle type changes, update the available brands and invalidate selected model
     * @param type
     */
    typeChanged(type: Rest.VehicleType) {
        const that = this;
        this.selectedType = type;
        this.modelService
            .getBrandsOfType(type.id.toString())
            .then(function (response) {
                that.availableBrands = response;
                that.dropdownBrandsDisable = response.length < 0;
            });
        that.dropdownModelsDisable = true;
        that.vehicle.model = null;
    }

    clientChanged(event) {
        if (event == null) {
            this.vehicle.billingAccount = null;
            this.billingAccount = null;
            this.vehicle.owner = null;
            this.owner = null;
            this.dropdownBillingAccountDisable = true;
            this.dropdownOwnerDisable = true;
        } else {
            this.dropdownBillingAccountDisable = false;
            this.billingAccountRequestContext.filters["clientID"] = [
                event.id.toString(),
            ];
            this.client = event;
            this.billingAccount = null;
            this.owner = null;
           

        }
    }

    billingAccountChanged(event) {
        if (event == null || this.client == null) {
            this.vehicle.billingAccount = null;
            this.billingAccount = null;
            this.vehicle.owner = null;
            this.owner = null;
            this.dropdownOwnerDisable = true;
            if (this.client == null) {
                this.dropdownBillingAccountDisable = true;
            } else {
                this.dropdownBillingAccountDisable = false;
            }

        } else {
            this.vehicle.billingAccount = event;
            this.dropdownOwnerDisable = false;
            this.owner = null;
        }
    }

    ownerChanged(event){
        if(event == null || this.client == null || this.billingAccount == null){
            this.vehicle.owner = null;
            this.owner = null;


            if(this.client){
                this.userRequestContext.filters["client"] = [this.client.id.toString()];
            }

            if(this.client == null || this.billingAccount == null){
                this.dropdownOwnerDisable = true;
            }else{
                this.dropdownOwnerDisable = false;
            }

        }else{
            this.userRequestContext.filters["client"] = [event.id.toString()];
            this.vehicle.owner = event;
            this.dropdownOwnerDisable = false;
        }
    }

    /**
     * when vehicle brand changes, update available models and invalidate selected one
     * @param brand
     */
    brandChanged(brand: Rest.VehicleBrand) {
        const that = this;
        this.selectedBrand = brand;
        this.modelService
            .getModelsOfBrand(brand.id.toString() + "-" + this.selectedType.id)
            .then(function (response) {
                that.availableModels = response;
                that.dropdownModelsDisable = response.length < 0;
            });
        that.dropdownModelsDisable = true;
        that.vehicle.model = null;
    }

    /**
     * When fleet changes, invalidate selected group
     * @param fleet
     */
    fleetChanged(event) {
        if (event == null) {
            this.vehicle.group = null;
            this.selectedGroup = null;
            this.disabledGroup = true;
        } else {
            this.disabledGroup = false;
            this.vehicleGroupRequestContext.filters["fleet"] = [event.id.toString()];
        }
    }

    maintenancePlanChanged(event) {
        this.vehicle.maintenancePlan = event;
    }

    groupChanged(event) {
        if (event == null || this.selectedFleet == null) {
            this.vehicle.group = null;
            this.selectedGroup = null;
            if (this.selectedFleet == null) {
                this.disabledGroup = true;
            } else {
                this.disabledGroup = false;
            }
        } else {
            this.vehicle.group = event;
        }
    }

    /**
     * search among available groups for the selected fleet
     */
    getGroupPagingFunction() {
        const that = this;
        return (req: Rest.ListPaginationRequest) => {
            req.filters["fleet"] = [
                (that.selectedFleet ? that.selectedFleet.id : -1).toString(),
            ];
            return that.groupService.getPage(req);
        };
    }

    deleteVehicle() {
        const that = this;
        this.confirmationService.confirm({
            message: this.translate.instant('control-panel.vehicles.confirmDelete'),
            accept: () => {
                const l = [];
                l.push({ id: this.vehicle.id });
                this.vehicleService.delete(l).then(function (ret) {
                    that.notificationsService.add(Severity.success, "", this.translate.instant('general.deleteSuccessfull'));
                    that.refresh();
                });
            },
        });
    }

    refresh() {
        this.display = false;
        this.refreshList.emit(true);
    }

    /** #ChangeStatus */
    changeStatus() {
        for (var i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
            this.selectedEntities.splice(i, 1);
        }
        this.selectedEntities.push(this.vehicle);
        this.showStatusDialog = true;
    }

    refreshForm(event) {
        this.selectedEntities = [];
        this.closeStatusDialog(false);
        this.return.emit(true);
        this.selectNode.emit();
        var that = this;
        this.vehicleService
            .findVehicle(this.vehicle.id.toString())
            .then(function (res) {
                that.show(false, res, that.permissions);
            })
            .catch((e) => {
                this.handleError(e, that);
            });
    }

    closeStatusDialog(event) {
        var close: boolean;
        close = event;
        this.showStatusDialog = close;
    }

    /***************** */

        // TODO: Fields "vehicleId" and "groupId" were missing from legacy code!!!
    vehicleId: any;
    groupId: any;

    addModel(){
        this.displayAddModel = true;
    }

    addBrand(){
        this.displayAddBrand = true;
    }

    cancelAddBrand(){
        this.displayAddBrand = false;
        //refresh list of brands

        if(!this.dropdownBrandsDisable){
            var that = this;
            that.modelService
            .getBrandsOfType(that.selectedType.id.toString())
            .then(function (response) {
                that.availableBrands = response;
            });
        }

    }

    cancelAddModel(){
        this.displayAddModel = false;
        //refresh list of models
       
        if(!this.dropdownModelsDisable){
            var that = this;
            that.modelService.getModelsOfBrand(
                that.selectedBrand.id.toString() +
                "-" +
                that.selectedType.id.toString()
            ).then(function (response) {
                that.availableModels =response;
            });
        }

    }
}
