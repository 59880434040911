import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';


@Component({
  selector: 'app-unlock-pin-gl320m',
  templateUrl: './unlock-pin-gl320m.component.html',
  styleUrls: ['./unlock-pin-gl320m.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UnlockPinGl320mComponent extends ServerValidatedFormComponent implements OnInit {
  
  @Input() pinParameters: Rest.UnlockPINGL320M;
  
  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.pinParameters);
    }
  }
}
