<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GPS DISTANCE INTERVAL
          (DISTANCE)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Distance Interval">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="gpsDistanceInterval.distanceInterval" [min]="50" [max]="10000"></p-spinner>
          <span class="ui-inputgroup-addon">50~10000</span>
          <span class="ui-inputgroup-addon">m</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>