
<div *ngIf="display">
    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <p-messages [(value)]="messages"></p-messages>
        </div>
    </div>

    <p-panel header="Group">
        <div class="ui-g container">
            <div class="ui-g-12 ui-md-6">

                <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
                    <input pInputText id="name" [(ngModel)]="group.name" type="text" />
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">

                <form-input [required]="true" i18n-fieldName fieldName="fleet" [errors]="errors.fleet">
                    <paged-search-dropdown [pageFunction]="this.fleetService.getPage.bind(this.fleetService)" [(selectedEntity)]="group.fleet" [paginationRequest]="fleetRequestContext"></paged-search-dropdown>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input [required]="true" i18n-fieldName fieldName="icon" [errors]="errors.icon">
                    <p-dropdown id="dropdownIcons" placeholder="Select an Icon" [options]="availableIcons" [(ngModel)]="group.icon" [style]="{'width':'190px'}"
                        filter="filter">

                        <!-- SELECTED ICON TEMPLATE -->
                     <ng-template pTemplate='selectedItem'>
                        <div *ngIf='group?.icon'>
                           <div class='d-flex flex-row w-100 h-100 gap-3 ps-3'>
                              <img [src]='group?.icon?.imageEncoded' alt='' class='group-icon' />
                              <div class='d-flex poi-icon-name'>{{group.icon.name}}</div>
                           </div>
                        </div>
                     </ng-template>

                        <ng-template let-icon pTemplate="item">
                            <div class='d-flex flex-row w-100 h-100 gap-3 ps-2'>
                                <img [src]='icon?.imageEncoded' alt='' class ='group-icon'/>
                                <div style="font-size:14px;float:right;margin-top:4px">{{icon.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName [required]="false" fieldName="enabled" [errors]="errors.enabled">
                    <p-checkbox disabled="true" [(ngModel)]="group.enabled" binary="true"></p-checkbox>
                </form-input>
            </div>
            <form-input i18n-fieldName [required]="false" fieldName="Metadata">
                <app-metadata-editor [(metadata)]="group.metadata"></app-metadata-editor>
            </form-input>

            <div class="ui-g-12 ui-md-6">
                <div class="ui-g-4">
                </div>
                <div class="ui-g-8">
                </div>
            </div>
            <div class="ui-g-12 ui-md-6">
                <div class="ui-g-4">
                </div>
                <div class="ui-g-8">
                </div>
            </div>
            <div class="ui-g-12 ui-md-6 row">
                <div class="ui-g-4 col col-md-2">
                    <div *ngIf="isNew;then create else update"></div>
                    <ng-template #create>
                        <button type="button" pButton icon="fa fa-check" (click)="saveGroup()" i18n-label label="Save"></button>
                    </ng-template>
                    <ng-template #update>
                        <div *ngIf="permissions._$update">
                            <button type="button" pButton icon="fa fa-check" (click)="saveGroup()" i18n-label label="Save"></button>
                        </div>
                        <div *ngIf="permissions._$update===false">
                            <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
                                <button disabled="true" type="button" pButton icon="fa fa-check" (click)="saveGroup()" i18n-label label="Save"></button>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="showChangeStatus" class="col col-md-2">
                    <!-- #ChangeStatus  -->
                    <div class="ui-g-8">
                        <div *ngIf="checkBoxDisabled[groupName+'-'+group.id]===false;then permission else noPersmission"></div>
                        <ng-template #permission>
                            <button type="button" pButton icon="fa fa-close" style="float:left" (click)="changeStatus()" i18n-label label="Change status"></button>
                        </ng-template>
                        <ng-template #noPersmission>
                            <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status" tooltipPosition="top">
                                <button type="button" disabled="true" pButton icon="fa fa-close" style="float:left" (click)="changeStatus()" i18n-label label="Change status"></button>
                            </div>
                        </ng-template>
                    </div>
                    <!--  -->
                </div>
            </div>

        </div>
    </p-panel>

    <!-- #ChangeStatus  -->
    <div *ngIf="showStatusDialog">
        <app-change-status-dialog [items]=title [display]="true" [service]=groupService [entitiesList]=selectedEntities (return)="refreshForm($event)"
            (close)="closeStatusDialog($event)"></app-change-status-dialog>
    </div>
    <!--  -->

</div>
