import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RealStateService } from 'app/modules/vehicle-inventory/services/table-management/realState.service';

import RealState = Rest.RealState;

@Component({
    selector: 'app-real-state-form',
    templateUrl: './real-state-form.component.html',
    styleUrls: ['./real-state-form.component.css'],
    providers: [RealStateService],
    encapsulation: ViewEncapsulation.None,
})
export class RealStateFormComponent
    extends EntityFormComponent<RealState>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];

    title: string;

    active: boolean;

    startDate: Date = null;
    endDate: Date = null;

    constructor(
        private realStateService: RealStateService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(realStateService, notificationsService, i18n, authenticationService);
    }

    /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof RealStateFormComponent
   */
    beforeShow() {
        this.active = !this.entity.disabled;

        console.log(this.entity);

        if (this.isNew) {
            this.startDate = null;
            this.endDate = null;

            this.title = this.translateService.instant('tableManagement.real-state.createRealState');
        } else {
            if (this.entity.financing.startDate != null) {
                this.startDate = new Date(this.entity.financing.startDate);
            }
            if (this.entity.financing.endDate != null) {
                this.endDate = new Date(this.entity.financing.endDate);
            }
            
            this.title = this.translateService.instant('tableManagement.real-state.editRealState');
        }
    }

    saveChanges() {
        this.entity.financing.startDate = this.startDate;
        this.entity.financing.endDate = this.endDate;

        if (this.isNew) {
            this.realStateService.create(this.entity).then((p) => {
                this.display = false;
                this.return.emit(p);
            })
                .catch((error) => {
                    this.notificationsService.add(Severity.error, 'Error', error.message);
                    return null;
                });
        } else {
            console.log('+++ updating');
            this.realStateService.update(this.entity).then((p) => {
                this.display = false;
                this.return.emit(p);
            })
                .catch((error) => {
                    this.notificationsService.add(Severity.error, 'Error', error.message);
                    return null;
                });
        }
    }
}