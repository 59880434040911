import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";

import InsuranceType = Rest.InsuranceType;

@Injectable()
export class InsuranceTypeService extends EntityService<InsuranceType> {
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: InsuranceType): Rest.RestResponse<InsuranceType> {
        try {
            return this.client.InsuranceType_create(arg0);
        } catch (error) {
            console.error("Error creating insuranceType:", error);
            throw error;
        }
    }

    update(arg0: InsuranceType): Rest.RestResponse<InsuranceType> {
        try {
            return this.client.InsuranceType_update(arg0);
        } catch (error) {
            console.error("Error updating insuranceType:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<InsuranceType>> {
        try {
            return this.client.InsuranceType_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting insuranceType page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.InsuranceType_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing insuranceType status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<InsuranceType> {
        try {
            return this.client.InsuranceType_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding insuranceType:", error);
            throw error;
        }
    }
}