import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { ConfirmationService } from "primeng/api";
import { NotificationsService } from "../../../../services/notifications-service/notifications.service";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-rftag-configuration",
  templateUrl: "./rftag-configuration.component.html",
  styleUrls: ["./rftag-configuration.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class RftagConfigurationComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  constructor(
    protected notificationsService: NotificationsService,
    protected confirmationService: ConfirmationService,
    protected i18n: I18nService
  ) {
    super(i18n);
  }

  @Input() RFTags: Rest.RFTag[];
  selectedEntities: Rest.RFTag[];

  ngOnInit() {
    this.selectedEntities = [];
  }

  add() {
    this.RFTags.push(<Rest.RFTag>{});
    this.RFTags = [...this.RFTags];
  }

  remove() {
    for (const e in this.selectedEntities) {
      var index = this.RFTags.indexOf(this.selectedEntities[e]);
      this.RFTags.splice(index, 1);
      this.RFTags = [...this.RFTags];
    }
    this.selectedEntities = [];
  }

  update(r: Rest.RFTag) {
    var index = this.RFTags.indexOf(r);
    this.RFTags[index] = r;
  }
}
