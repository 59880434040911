<div #modal class="modal fade profile-modal" data-bs-backdrop="static" data-bs-keyboard="false"
     tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">

    <!-- PROFILE MODAL DIALOGUE CONTENT -->
    <div class="modal-content">

      <!-- PROFILE MODAL DIALOGUE HEADER -->
      <div class="modal-header pb-0">
        <div class="container-fluid">

          <!-- ROW 1: PROFILE IMAGE & ADD IMAGE BUTTON -->
          <div class="row">

            <!-- PROFILE IMAGE -->
            <img #image src="{{userEncodedImage}}" class="custom-user-profile-image mx-auto d-block p-0">

            <!-- ADD IMAGE BUTTON -->
            <div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
              <div id="inner-icon-holder" class="d-flex align-items-center justify-content-center" (click)='uploadImageInput.click()'>
                <img src="assets/icons/camera-plus.png" class="custom-user-profile-icon"/>
                <input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png' (change)='imageChange($event)'>
              </div>
            </div>
          </div>

          <!-- ROW 2: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">
            <div class="col">
              <span class="modal-title custom-title" i18n>{{'profile.profile' | translate}}</span>
            </div>

            <div class="col">
              <div class="d-flex justify-content-end">
                <button #closeUserProfileModalButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeUserProfileDialog()"></button>
              </div>
            </div>
          </div>
          <hr class="separator custom-user-profile-separator">
        </div>
      </div>


      <!-- PROFILE MODAL DIALOGUE BODY -->
      <div class="modal-body pt-0 pb-0">
        <div class="container-fluid">

          <!-- PROFILE FORM -->
          <form name="form" (ngSubmit)="updateUserSettings()" #profileForm="ngForm">

            <!-- ROW 1: USERNAME & EMAIL -->
            <div class="row align-items-end mt-1">

              <!-- COL 1: USERNAME -->
              <div class="col-md mt-3 mt-md-0">
                <label for="userName" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.username' | translate}}</label>
                <input type="text" name="userName" id="userName" class="form-control custom-input custom-user-profile-input"
                       [(ngModel)]="userName"
                       readonly/>
              </div>

              <!-- COL 2: EMAIL -->
              <div class="col-md mt-3 mt-md-0">
                <label for="email" class="form-label custom-label custom-user-profile-input-label" i18n>{{'userProfile.email' | translate}}(*)</label>
                <input #emailField='ngModel'
                       type="email"
                       name="email"
                       id="email"
                       class="form-control custom-input custom-user-profile-input"
                       [(ngModel)]="email"
                       [ngClass]="{ 'is-invalid': profileForm.submitted && emailField.invalid }"
                       email required/>

                <!-- EMAIL ERRORS -->
                <div *ngIf="profileForm.submitted && emailField.invalid" class="invalid-feedback custom-user-profile-invalid-feedback">
                  <div *ngIf="emailField.errors.required" i18n>{{'userProfile.emailRequired' | translate}}</div>
                  <div *ngIf="emailField.errors.email" i18n>{{'userProfile.emailValid' | translate}}</div>
                </div>

              </div>

            </div>

            <!-- ROW 2: NAME & SURNAME 1 & SURNAME 2 -->
            <div class="row align-items-end mt-md-3">

              <!-- COL 1: NAME -->
              <div class="col-md mt-3 mt-md-0">
                <label for="name" class="form-label custom-label custom-user-profile-input-label" i18n>{{'userProfile.name' | translate}}(*)</label>
                <input #nameField='ngModel'
                       type="text"
                       name="name"
                       id="name"
                       class="form-control custom-input custom-user-profile-input"
                       [(ngModel)]="name"
                       [ngClass]="{'is-invalid': profileForm.submitted && nameField.invalid}"
                       required>

                <!-- NAME ERRORS -->
                <div *ngIf="profileForm.submitted && nameField.invalid" class="invalid-feedback custom-user-profile-invalid-feedback">
                  <div *ngIf="nameField.errors.required" i18n>{{'userProfile.nameRequired' | translate}}</div>
                </div>

              </div>

              <!-- COL 2: SURNAME 1 -->
              <div class="col-md mt-3 mt-md-0">
                <label for="surname1" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.firstSurname' | translate}}</label>
                <input type="text" name="surname1" id="surname1" class="form-control custom-input custom-user-profile-input"
                       [(ngModel)]="surname1">
              </div>

              <!-- COL 3: SURNAME 2 -->
              <div class="col-md mt-3 mt-md-0">
                <label for="surname2" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.secondSurname' | translate}}</label>
                <input type="text" name="surname2" id="surname2" class="form-control custom-input custom-user-profile-input"
                       [(ngModel)]="surname2">
              </div>
            </div>

            <!-- ROW 3: TIME ZONE & METRICS & PHONE -->
            <div class="row row-cols-md-3 align-items-end mt-md-3">

              <!-- COL 1: TIME ZONE -->
              <div class="col-md mt-3 mt-md-0">
                <label for="timeZone" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.timeZone' | translate}}</label>
                <app-time-zone-selector
                  *ngIf="timeZone"
                  id="timeZone"
                  [(timeZone)]="timeZone"
                  (timeZoneChange)="userTimeZoneUpdate($event)"
                ></app-time-zone-selector>
              </div>

              <!-- COL 2: METRIC -->
              <div class="col-md mt-3 mt-md-0">
                <label for="metric" class="form-label custom-label custom-user-profile-input-label" i18n>{{'userProfile.metric' | translate}}</label>
                <select name="metric" id="metric" class="form-select custom-user-profile-input custom-select"
                        [(ngModel)]="selectedMetric"
                >
                  <option *ngFor="let metric of availableUserMetric" [ngValue]="metric">{{metric.label}}</option>
                </select>
              </div>

              <!-- COL 3: PHONE -->
              <div class="col-md mt-3 mt-md-0">
                <label for="phone" class="form-label custom-label custom-user-profile-input-label" i18n>{{'userProfile.phone' | translate}}</label>
                <input type="tel" name="phone" id="phone" class="form-control custom-input custom-user-profile-input" [(ngModel)]="phoneNumber"
                       required>
              </div>
            </div>

            <!-- ROW 4: LANGUAGE & DEFAULT DASHBOARD-->
            <div class="row row-cols-md-3 align-items-end mt-md-3 mb-4">

              <!-- COL 1: LANGUAGE -->
              <div class="col-md mt-3 mt-md-0">
                <label for="language" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.language' | translate}}</label>
                <select name="language" id="language" class="form-select custom-user-profile-input custom-select"
                        [(ngModel)]="selectedLanguage"
                >
                  <option *ngFor="let language of availableUserLanguage" [ngValue]="language">{{language.label}}</option>
                </select>
              </div>

              <!-- COL 2: DEFAULT DASHBOARD -->
              <div class="col-md mt-3 mt-md-0">
                <label for="defaultDashboard" class="form-label custom-label custom-user-profile-input-label"
                       i18n>{{'userProfile.defaultDashboard' | translate}}</label>
                <select name="defaultDashboard" id="defaultDashboard"
                        class="form-select custom-user-profile-input custom-select"
                        [(ngModel)]="selectedDashboard"
                >
                  <option *ngFor="let dashboard of availableDashboards" [ngValue]="dashboard">{{dashboard.label}}</option>
                </select>
              </div>
              <!-- COL 3: TABLE SIZE -->
              <ng-container *ngIf="clientId === 2 
               ||  clientId === 589055 
               ||  clientId === 355802 
               ||  clientId=== 638342 ">
                <div class="col-md mt-3 mt-md-0" >
                  <label for="tableSize" class="form-label custom-label custom-user-profile-input-label"
                        i18n>{{'tableUserSettings.tableSize' | translate}}</label>
                  <select name="tableSize" id="tableSize"
                          class="form-select custom-user-profile-input custom-select"
                          [(ngModel)]="selectedTableSize" >
                    <option *ngFor="let tableSize of availableTableSizes" [ngValue]="tableSize">{{tableSize.label}}</option>
                  </select>
                </div>
              </ng-container>
            </div>
            <hr class="separator custom-user-profile-separator">
            <!-- ROW 5: SUBMIT BUTTON -->
            <div class="row mt-4 mb-4">
              <div class="d-flex align-items-center justify-content-center">
                <button class="btn custom-button custom-user-profile-button" type="submit" i18n>{{'general.save' | translate}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>

    </div>
  </div>
</div>
