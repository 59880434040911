import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { Rest } from 'app/rest/rest_client';
import { DatePipe } from '@angular/common';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { ConfirmationService, PrimeNGConfig, SelectItem } from 'primeng/api';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import { CecoService } from 'app/rest/ceco.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { VehiclesgroupService } from 'app/rest/vehiclesgroup.service';
import { VehicleUsageService } from 'app/rest/vehicleUsage.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
import { CardService } from 'app/rest/card.service';
import { IconService } from 'app/rest/icon.service';
import { FleetService } from 'app/rest/fleet.service';
import { VehicleHtcService } from 'app/modules/vehicle-inventory/services/vehicle-htc.service';
import { FinancingService } from 'app/modules/vehicle-inventory/services/table-management/financing.service';
import { InsuranceService } from 'app/modules/vehicle-inventory/services/table-management/insurance.service';
import { DriverAssignmentsService } from 'app/modules/vehicle-inventory/services/driver-assignments.service';
import { VehicleAssignmentsService } from 'app/modules/vehicle-inventory/services/vehicle-assignments.service';
import { BrandFormComponent } from '../../table-management/brands/brands-form/brand-form.component';
import { ModelFormComponent } from '../../table-management/models/model-form/model-form.component';
import { VehicleVersionFormComponent } from '../../table-management/vehicle-version/vehicle-version-form/vehicle-version-form.component';
import { FueltypeService } from 'app/modules/vehicle-inventory/services/fueltype.service';
import { LeaderService } from 'app/rest/leader.service';
import { MileageService } from 'app/modules/vehicle-inventory/services/table-management/mileage.service';

@Component({
	selector: 'app-inventory-vehicle-form',
	templateUrl: './inventory-vehicle-form.component.html',
	styleUrls: ['./inventory-vehicle-form.component.css'],
	providers: [CecoService, BillingaccountService, IconService, CardService, FueltypeService, NotificationsService, MileageService],
})
export class InventoryVehicleFormComponent extends EntityFormComponent<Rest.Vehicle> implements OnInit, OnDestroy {
	@Input() clientId: number;
	@Input() fleetId: number;

	isDialogVisible: boolean;
	severeError: boolean = false;
	isEditingDisabled: boolean;
	//public showPlateChangesDialog: EventEmitter<boolean>;
	errorMessages: string[];
	vehicleFormTitle: string;
	errorContainer: ElementRef;
	collapsed: Boolean = false;
	historicOptionsList: any[] = [
		{ id: 0, value: this.translate.instant('general.today') },
		{ id: 1, value: this.translate.instant('tableManagement.vehicleHtc.thisMonth') },
		{ id: 2, value: this.translate.instant('tableManagement.vehicleHtc.pastMonth') },
		{ id: 3, value: this.translate.instant('tableManagement.vehicleHtc.thisYear') },
		{ id: 4, value: this.translate.instant('tableManagement.vehicleHtc.pastYear') },
		{ id: 5, value: this.translate.instant('tableManagement.vehicleHtc.custom') },
	];
	selectedHistoricOptionsList: any = this.historicOptionsList[0];
	//isHistoricData: Boolean = false;
	// fineVisible: Boolean = false;
	editingSubscription: Subscription;
	creatingSubscription: Subscription;
	savedSubscription: Subscription;
	vehicleAddedSubscription: Subscription;
	changesPendingSubscription: Subscription;
	driverSelectedSubscription: Subscription;
	insuranceCreatedSubscription: Subscription;
	financingCreateSubscription: Subscription;

	/** historic date panel **/
	startDate: Date = new Date(0);
	endDate: Date = new Date();
	startDateTxt: string;
	endDateTxt: string;
	today: Date = new Date();
	isHtcLoaded: Boolean = true;
	htcVisible: Boolean = false;

	/*******************/
	/** FLEET & GROUP **/
	/*******************/
	selectedFleet: Rest.Fleet = null;
	fleetList: Rest.Fleet[] = [];

	/**************/
	/** VEHICLE  **/
	/**************/
	originalVehicle: Rest.Vehicle = null;
	selectedVehicle: Rest.Vehicle = null;
	brandList: Rest.VehicleBrand[] = [];
	modelList: Rest.VehicleModel[] = [];
	versionList: Rest.InvVehicleVersion[] = [];
	statusList: Rest.VehicleStatus[] = [];
	vehicleGroupList: Rest.VehiclesGroup[] = [];
	vehicleUsageList: Rest.VehicleUsage[] = [];
	responsiblesList: Rest.InvLeader[] = [];
	serviceManagerList: Rest.User[] = [];
	zoneLeaderList: Rest.User[] = [];
	fleetManagerList: Rest.User[] = [];
	selectedBrand: Rest.VehicleBrand;
	selectedModel: Rest.VehicleModel;
	selectedVersion: Rest.InvVehicleVersion;
	selectedBillingAccount: Rest.BillingAccount;
	selectedVehicleStatus: Rest.VehicleStatus;
	isBillingAccountChanged: Boolean = false;
	isStatusChanged: Boolean = false;
	isBrandLoaded: Boolean = false;
	isModelLoaded: Boolean = false;
	isVersionLoaded: Boolean = false;
	isStatusLoaded: Boolean = false;
	brandSpinner: Boolean = false;
	logoImageUrl: string = '';
	logoEncodedImage: any = null;
	vehicleImageUrl: string = '';
	vehicleEncodedImage: any = null;
	vehicleImage: any = null;
	entityVehicleImage: Rest.IconEntityAssociationEnum = 'VEHICLE';
	nameLocked = true;

	/****************/
	/** FUEL CARD  **/
	/****************/
	showCardForm: Boolean = false;
	cardList: Rest.Card[] = [];
	cardImageUrl: string = '';
	cardEncodedImage: any = null;

	/****************/
	/** INSURANCE  **/
	/****************/

	/**************************/
	/** RENTING / FUNANCING  **/
	/**************************/

	/************************/
	/** DRIVER ASSIGNMENT  **/
	/************************/
	driverAssignment: Rest.InvDriverAssignment = null;
	isDriverListLoaded: Boolean = false;
	selectedDriver: Rest.InvDriver = null;
	driverSpinner: Boolean = false;
	showDriverForm: Boolean = false;
	driverRequestContext: any;
	dateDriverAssignmentDisabled: Boolean = true;
	dateDriverAssignment: Date;
	/*************************/
	/** VEHICLE ASSIGNMENT  **/
	/*************************/
	vehicleAssignment: Rest.InvVehicleAssignment = null;
	assignmentSpinner: Boolean = false;
	showAssignmentForm: Boolean = false;
	dateVehicleAssignmentDisabled: Boolean = true;
	dateVehicleAssignment: Date;
	/* biling account */
	billingAccountList: Rest.BillingAccount[] = [];
	isBillingAccountLoaded: Boolean = false;
	/* delegation */
	delegationList: Rest.Delegation[] = [];
	selectedDelegation: Rest.Delegation;
	isDelegationListLoaded: Boolean = false;
	/* subdelegation */
	subdelegationList: Rest.Subdelegation[] = [];
	selectedSubdelegation: Rest.Subdelegation;
	isSubdelegationListLoaded: Boolean = false;
	/* ceco */
	cecoList: Rest.Ceco[] = [];
	selectedCeco: Rest.Ceco;
	isCecoListLoaded: Boolean = false;
	/* iva */
	ivaTypesList: Rest.InvIvaType[] = [];
	selectedIva: string;
	/* responsables */
	fleetManager: Rest.User = null;
	serviceManager: Rest.User = null;
	zoneLeader: Rest.User = null;
	customFleetManager: Boolean = false;
	customServiceManager: Boolean = false;
	customZoneLeader: Boolean = false;
	// fleetManagerFullName: String;
	// serviceManagerFullName: String;
	// zoneLeaderFullName: String;
	fleetManagerImageUrl: string = '';
	fleetManagerEncodedImage: any = null;
	serviceManagerImageUrl: string = '';
	serviceManagerEncodedImage: any = null;
	zoneLeaderImageUrl: string = '';
	zoneLeaderEncodedImage: any = null;

	fleetManagerPlaceHolder: String = '';
	serviceManagerPlaceHolder: String = '';
	zoneLeaderPlaceHolder: String = '';

	/***********/
	/** TABS  **/
	/***********/
	showTabs: Boolean = false;

	initialEnabledStatus: Boolean = false;
	initialDisabledDate: Date = null;
	initialKmValue: number = 0;
	minDateVehicleAssignment: Date;
	minDateDriverAssignment: Date;

	statusPlacehodler: string = '';

	billingAccountsMatch: boolean = true;
	showMatchingWarning: boolean = true;

	/*******************/
	/*** PERMISSIONS ***/
	/*******************/
	isSaveGranted: boolean = false;
	isObservationsGranted: boolean = false;
	isVehicleStateGranted: boolean = false;
	isChangeDriverGranted: boolean = false;

	@ViewChild(BrandFormComponent, { static: true })
	brandForm: BrandFormComponent;

	@ViewChild(ModelFormComponent, { static: true })
	modelForm: ModelFormComponent;

	@ViewChild(VehicleVersionFormComponent, { static: true })
	vehicleVersionForm: VehicleVersionFormComponent;

	optionsfuelType: SelectItem[];

	/**  */
	constructor(
		public invVehicleService: InvVehicleService,
		public vehicleService: VehicleService,
		public fleetService: FleetService,
		public groupService: VehiclesgroupService,
		private authService: AuthenticationService,
		public i18n: I18nService,
		public notificationsService: NotificationsService,
		public translate: TranslateService,
		private datePipe: DatePipe,
		private driverService: DriverService,
		private delegationService: DelegationService,
		private subdelegationService: SubdelegationService,
		private cecoService: CecoService,
		private billingAccountService: BillingaccountService,
		public vehicleUsageService: VehicleUsageService,
		public iconService: IconService,
		public cardService: CardService,
		public vehicleHtcService: VehicleHtcService,
		public financingService: FinancingService,
		public insuranceService: InsuranceService,
		public confirmationService: ConfirmationService,
		public driverAssignmentsService: DriverAssignmentsService,
		public vehicleAssignmentsService: VehicleAssignmentsService,
		public fuelTypeService: FueltypeService,
		private config: PrimeNGConfig,
		private leaderService: LeaderService,
		private mileageService: MileageService,
	) {
		super(invVehicleService, notificationsService, i18n, authService);
		this.errorMessages = [];
		//this.showPlateChangesDialog = new EventEmitter<boolean>();
	}

	ngOnInit() {
		// console.log('[VEHICLE-FORM] ngOnInit ***');
		super.ngOnInit();
		this.config.getTranslation;
		this.selectedVehicle = this.invVehicleService.initVehicle();
		this.getVehicleStatus(this.clientId);
		this.getBrandsByClient(this.clientId);
		this.getBillingAccounts(this.clientId);
		this.getDelegationList(this.clientId);
		this.getResponsiblesByClient(this.clientId);
		this.getIvaTypes();
		this.getFleetList();
		this.getVehicleUsageList(this.clientId);
		this.loadOptionsFuelType();
		this.editingSubscription = this.invVehicleService.startedEditing.subscribe((item: Rest.Vehicle) => {
			// console.log("______ EDIT ______");
			this.isNew = false;
			this.config.getTranslation;
			this.vehicleFormTitle = this.translate.instant('tableManagement.vehicle.editVehicle');
			this.showDialog(item);
		});
		this.creatingSubscription = this.invVehicleService.startedCreating.subscribe(() => {
			// console.log("______ CREATE ______");
			this.isNew = true;
			this.config.getTranslation;
			this.vehicleFormTitle = this.translate.instant('tableManagement.vehicle.createVehicle');
			this.showDialog(this.invVehicleService.initVehicle());
		});
		this.driverSelectedSubscription = this.driverService.driverSelected.subscribe((item: Rest.InvDriver) => {
			this.selectedDriver = item;
			this.selectedDriver.fullName =
				(this.selectedDriver.user.surname1 === null ? '' : this.selectedDriver.user.surname1) +
				' ' +
				(this.selectedDriver.user.surname2 === null ? '' : this.selectedDriver.user.surname2) +
				', ' +
				(this.selectedDriver.user.name === null ? '' : this.selectedDriver.user.name);
			this.initDriverAssignmentDate();
		});

		this.setPermissions();
	}

	ngOnDestroy() {
		this.editingSubscription.unsubscribe();
		this.creatingSubscription.unsubscribe();
		this.driverSelectedSubscription.unsubscribe();
	}

	public showDialog(item: Rest.Vehicle): void {
		console.log('[VEHICLE-FORM] showDialog ***');
		console.log(item);
		this.originalVehicle = item;
		Object.assign(this.selectedVehicle, this.originalVehicle);
		console.log(this.selectedVehicle);
		this.isEditingDisabled = false;
		this.isDialogVisible = true;
		this.severeError = false;
		this.initialEnabledStatus = this.selectedVehicle.enabled;
		this.initialDisabledDate = this.selectedVehicle.disabledDate;
		this.initialKmValue = this.selectedVehicle.km;

		this.dateVehicleAssignmentDisabled = true;
		this.dateDriverAssignmentDisabled = true;
		this.init();
	}

	public init() {
		// console.log('[VEHICLE-FORM] init ***');
		window.scroll({ top: 0, behavior: 'smooth' });
		this.errorMessages = [];
		this.isHtcLoaded = true;
		this.getAvailableCards(this.clientId);
		//this.getResponsiblesByClient(this.clientId);
		if (this.isNew) {
			this.isBillingAccountChanged = false;
			this.isStatusChanged = false;
			this.isModelLoaded = false;
			this.isVersionLoaded = false;
			this.selectedBrand = null;
			this.selectedModel = null;
			this.selectedVersion = null;
			this.selectedBillingAccount = null;
			//this.selectedVehicleStatus = null;
			this.selectedDriver = null;
			this.selectedDelegation = null;
			this.selectedSubdelegation = null;
			this.selectedCeco = null;
			this.selectedIva = null;
			this.vehicleAssignment = null;
			this.logoImageUrl = 'assets/icons/no-image-available.svg';
			this.logoEncodedImage = null;
			this.vehicleImageUrl = 'assets/icons/camera-plus.png'; //'assets/icons/no-image-available.svg';
			this.vehicleEncodedImage = null;
			this.cardImageUrl = 'assets/icons/no-image-available.svg';
			this.cardEncodedImage = null;
			this.driverSpinner = false;
			this.assignmentSpinner = false;
			this.showCardForm = false;
			this.showAssignmentForm = false;
			this.showDriverForm = false;
			this.selectedFleet = this.fleetList[0];
			this.vehicleGroupList = this.selectedFleet.groups;
			this.selectedVehicle.group = this.vehicleGroupList[0];
			this.showTabs = false;
			this.nameLocked = false;
			this.driverAssignment = null;
			this.dateVehicleAssignment = null;
			this.minDateDriverAssignment = new Date(0);
			this.minDateVehicleAssignment = new Date(0);
			this.customFleetManager = false;
			this.customServiceManager = false;
			this.customZoneLeader = false;
			this.fleetManager = null;
			this.serviceManager = null;
			this.zoneLeader = null;
			// this.fleetManagerPlaceHolder = this.translate.instant('general.select') + "...";
			// this.serviceManagerPlaceHolder = this.translate.instant('general.select') + "...";
			// this.zoneLeaderPlaceHolder = this.translate.instant('general.select') + "...";
		} else {
			this.getVehicleAssignment(this.selectedVehicle.id);
			this.getDriverAssignment(this.selectedVehicle.id);
			this.selectedBrand = this.selectedVehicle.model.brand;
			let temp = this.brandList.find((x) => x.id === this.selectedBrand.id);
			if (temp !== null && temp !== undefined) this.selectedBrand.logo = temp.logo; //Obtenim el imageEncoded de la llista
			if (this.selectedBrand !== null) {
				this.getModelsByBrand(this.selectedBrand.id);
			}
			this.selectedModel = this.selectedVehicle.model;
			if (this.selectedModel !== null) {
				this.getVersionsByModel(this.selectedModel.id);
			}
			this.selectedVersion = this.selectedVehicle.version;
			this.selectedVehicleStatus = this.selectedVehicle.status;
			this.driverSpinner = true;

			if (this.selectedBrand.logo) {
				this.logoImageUrl = null;
				this.logoEncodedImage = this.selectedBrand.logo.imageEncoded;
			} else {
				this.logoImageUrl = 'assets/icons/no-image-available.svg';
				this.logoEncodedImage = null;
			}
			if (this.selectedVehicle.image) {
				this.vehicleImageUrl = null;
				this.vehicleEncodedImage = this.selectedVehicle.image.imageEncoded;
			} else {
				this.vehicleImageUrl = 'assets/icons/camera-plus.png'; //'assets/icons/no-image-available.svg';
				this.vehicleEncodedImage = null;
			}
			/* fleet i grup */
			this.selectedFleet = this.selectedVehicle.group.fleet;
			let index = this.fleetList.findIndex((x) => {
				return x.id === this.selectedFleet.id;
			});
			if (index >= 0) this.vehicleGroupList = this.fleetList[index].groups;
			else {
				this.notificationsService.add(
					Severity.error,
					'ERROR!',
					this.translate.instant('error.get.data') + ' (VehicleID: ' + this.selectedVehicle.id + ').'
				);
				this.severeError = true;
			}
			/* tabs */
			this.showTabs = true;
			this.nameLocked = true;
		}
		this.refreshResponsables();
	}

	setPermissions() {
		const user = this.authService.user;
		const rolesForForm = ['VEHICLES_UPDATE', 'VEHICLES_DISABLE'];

		this.isObservationsGranted = this.authService.isRoleGranted('INV_VEHICLE_OBSERVATIONS_READ', user);
		this.isVehicleStateGranted = this.authService.isRoleGranted('INV_VEHICLE_STATE_READ', user);
		this.isChangeDriverGranted = this.authService.isRoleGranted('INV_VEHICLE_CHANGE_DRIVER', user);

		if (this.isNew) {
			this.isSaveGranted = this.authService.isRoleGranted('VEHICLES_CREATE', user);
		} else {
			this.isSaveGranted = this.authService.isOneRoleGranted(rolesForForm, user);
		}
	}

	/*******************
	 * CODES WARNINGS **
	 ******************/
	checkBillingAccountsMatch() {
		const vehiclePlate = this.selectedVehicle.name;
		const userId = this.authService.user.id;

		const showWarning = localStorage.getItem(vehiclePlate + "_" + userId + "_showWarning",);

		// Set default to true if value not set
		if (showWarning === null || showWarning === undefined) {
			this.showMatchingWarning = true;
		} else {
			this.showMatchingWarning = showWarning === 'true';
		}

		if (this.selectedBillingAccount && this.selectedDriver) {
			const driverBillingAccount = this.selectedDriver.subdivision?.division?.billingAccount?.code;

			if (driverBillingAccount !== this.selectedBillingAccount.code) {
				this.billingAccountsMatch = false;
				// Only show the warning if showMatchingWarning is true

				if (this.showMatchingWarning) {
					this.notificationsService.add(
						Severity.warn,
						this.translate.instant('general.warn') + "!",
						this.translate.instant('vehicleAssignments.header') + ": " +
						this.translate.instant('vehicleAssignments.dataNotMatching'));
				}
			}
		} else {
			this.billingAccountsMatch = true;
		}

		localStorage.setItem(vehiclePlate + "_" + userId + "_showWarning", String(this.showMatchingWarning));
	}

	toggleWarningVisibility() {
		const vehiclePlate = this.selectedVehicle.name;
		const userId = this.authService.user.id;

		this.showMatchingWarning = !this.showMatchingWarning;

		//Setting item to localStorage
		if (this.showMatchingWarning) {
			localStorage.setItem(vehiclePlate + "_" + userId + "_showWarning", 'false');
		} else {
			localStorage.setItem(vehiclePlate + "_" + userId + "_showWarning", 'true');
		}
	}

	/*********************/
	/**  GET BD DATA    **/
	/*********************/
	public getResponsiblesByClient(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.brandSpinner = true;
			this.leaderService
				.getList(clientId)
				.then(
					function (response) {
						if (!response) {
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('tableManagement.brands.brands') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.responsiblesList = response;
							that.fleetManagerList = [];
							that.zoneLeaderList = [];
							that.serviceManagerList = [];
							that.responsiblesList.forEach((x) => {
								if (x.fleetLeader) that.fleetManagerList.push(x.user);
								if (x.zoneLeader) that.zoneLeaderList.push(x.user);
								if (x.serviceLeader) that.serviceManagerList.push(x.user);
							});

							that.fleetManagerList.sort((a, b) => (a.surname1 > b.surname1 ? 1 : -1));
							that.fleetManagerList = that.fleetManagerList.map((u) => ({
								...u,
								formatResponsibleLabel: `${u.surname1} ${u.surname2}, ${u.name}`,
							}));

							that.zoneLeaderList.sort((a, b) => (a.surname1 > b.surname1 ? 1 : -1));
							that.zoneLeaderList = that.zoneLeaderList.map((u) => ({
								...u,
								formatResponsibleLabel: `${u.surname1} ${u.surname2}, ${u.name}`,
							}));

							that.serviceManagerList.sort((a, b) => (a.surname1 > b.surname1 ? 1 : -1));
							that.serviceManagerList = that.serviceManagerList.map((u) => ({
								...u,
								formatResponsibleLabel: `${u.surname1} ${u.surname2}, ${u.name}`,
							}));
						}
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getBrandsByClient(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.brandSpinner = true;
			this.invVehicleService
				.getBrands(clientId)
				.then(
					function (response) {
						if (response === null || response === undefined) {
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('tableManagement.brands.brands') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.brandList = response;
							that.brandList.sort((a, b) => (a.name > b.name ? 1 : -1));
						}
						that.isBrandLoaded = true;
						that.brandSpinner = false;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getModelsByBrand(brandId: number) {
		this.isModelLoaded = false;
		this.isVersionLoaded = false;
		this.brandSpinner = true;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getModels(brandId)
				.then(
					function (response) {
						if (response === null || response === undefined) {
							that.selectedModel = null;
							that.isModelLoaded = false;
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('tableManagement.models.models') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.modelList = response;
							that.modelList.sort((a, b) => (a.name > b.name ? 1 : -1));
							that.isModelLoaded = true;
						}
						that.brandSpinner = false;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVersionsByModel(modelId: number) {
		this.isVersionLoaded = false;
		this.brandSpinner = true;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getVersions(modelId)
				.then(
					function (response) {
						if (response === null || response === undefined) {
							that.isVersionLoaded = false;
							that.selectedVersion = null;
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('tableManagement.versions.versions') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.versionList = response;
							that.versionList.sort((a, b) => (a.name > b.name ? 1 : -1));
							that.isVersionLoaded = true;
						}
						that.brandSpinner = false;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getBillingAccounts(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getBillingAccounts(clientId)
				.then(
					function (response) {
						if (response === null || response === undefined) {
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('control-panel.vehicles.billingAccount') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.billingAccountList = response.sort((a, b) => (a.name > b.name ? 1 : -1));
						}
						that.isBillingAccountLoaded = true;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleStatus(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getVehicleStatus(clientId)
				.then(
					function (response) {
						if (response === null || response === undefined) {
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('tableManagement.vehicleStatus.vehicleStatus') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
						} else {
							that.statusList = response;
							that.statusList.sort((a, b) => (a.name > b.name ? 1 : -1));

							that.statusPlacehodler = that.translate.instant('general.select');
							that.statusPlacehodler += ' ';
							that.statusPlacehodler += that.translate.instant('tableManagement.financing.financingType');

							if (that.statusList.length > 0) {
								that.selectedVehicleStatus = that.statusList[0];

								that.statusList.forEach((t) => {
									if (t.isdefault) {
										that.selectedVehicleStatus = t;
									}
								});

								that.statusPlacehodler = that.selectedVehicleStatus.name;
							}
						}
						that.isStatusLoaded = true;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getIvaTypes() {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getIvaTypes()
				.then(
					function (response) {
						that.ivaTypesList = response;
						that.selectedIva = 'P100';
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleAssignment(vehicleId: number) {
		// console.log('[VEHICLE-FORM] getVehicleAssignment **** ');
		// console.log(this.selectedVehicle.vehicleAssignments);
		this.assignmentSpinner = true;
		// console.log(this.vehicleAssignment);
		if (this.selectedVehicle.vehicleAssignments.length > 0) {
			this.vehicleAssignment = this.selectedVehicle.vehicleAssignments[0];
			this.selectedBillingAccount = this.vehicleAssignment.company;
			this.selectedDelegation = this.vehicleAssignment.delegation ? this.vehicleAssignment.delegation : this.vehicleAssignment.subDelegation.delegation;
			this.selectedSubdelegation = this.vehicleAssignment.subDelegation;
			this.selectedCeco = this.vehicleAssignment.ceco;
			this.selectedIva = this.vehicleAssignment.iva;
			this.showAssignmentForm = this.vehicleAssignment !== null;
			this.dateVehicleAssignment = this.vehicleAssignment.startDate;
			this.minDateVehicleAssignment = this.dateVehicleAssignment;
			// this.getSubdelegationList(this.vehicleAssignment.subDelegation.delegation.id);
			this.getSubdelegationList(this.selectedDelegation.id);
			this.getCecoList(this.vehicleAssignment.company.id);
		} else {
			this.selectedBillingAccount = null;
			this.selectedDelegation = null;
			this.selectedSubdelegation = null;
			this.selectedCeco = null;
			this.selectedIva = null;
			this.showAssignmentForm = false;
			this.dateVehicleAssignment = null;
			this.minDateVehicleAssignment = new Date(0);
			this.notificationsService.add(
				Severity.warn,
				this.translate.instant('general.warn') + '!',
				this.translate.instant('vehicleAssignments.header') +
				': ' +
				this.translate.instant('table.tableEmptyMessage')
			);
		}
		this.assignmentSpinner = false;
	}

	public getDriverAssignment(vehicleId: number) {
		// console.log('[VEHICLE-FORM] getDriverAssignment *** ');
		this.driverSpinner = true;
		this.driverAssignment = null;
		this.showDriverForm = false;
		this.selectedDriver = null;
		this.dateDriverAssignment = null;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			const now = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
			const ini = this.datePipe.transform(new Date(0), 'yyyy-MM-dd');
			this.invVehicleService
				.getDriverAssignments(vehicleId, ini, now)
				.then(
					function (response) {
						if (!response) {
							that.notificationsService.add(
								Severity.warn,
								that.translate.instant('general.warn') + '!',
								that.translate.instant('drivers.header') +
								': ' +
								that.translate.instant('table.tableEmptyMessage')
							);
							that.driverSpinner = false;
							that.minDateDriverAssignment = new Date(0);
						} else {
							that.driverAssignment = response[0];
							//that.driverAssignment = response.find((driverAssignment) => driverAssignment.endDate === null);
							if (!that.driverAssignment) {
								//that.driverAssignment = response[0];
							}
							that.dateDriverAssignment = that.driverAssignment?.startDate;
							that.minDateDriverAssignment = that.dateDriverAssignment;
							if(that.driverAssignment.driver){
								that.getDriver(that.driverAssignment.driver.id);
							}else{
								that.driverSpinner = false;
							}
							
						}
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getDriver(driverId: number) {
		// console.log('[VEHICLE-FORM] getDriver *** ');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.driverService
				.find(driverId.toString())
				.then(
					function (response) {
						that.selectedDriver = response;
						that.selectedDriver.fullName =
							(that.selectedDriver.user.surname1 === null ? '' : that.selectedDriver.user.surname1) +
							' ' +
							(that.selectedDriver.user.surname2 === null ? '' : that.selectedDriver.user.surname2) +
							', ' +
							(that.selectedDriver.user.name === null ? '' : that.selectedDriver.user.name);
						that.showDriverForm = that.selectedDriver !== null;
						that.driverSpinner = false;

						if (that.selectedBillingAccount && that.selectedDriver) {
							that.checkBillingAccountsMatch();
						}
					},

					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getDelegationList(clientId: number) {
		// console.log('[VEHICLE-FORM] getDelegationList *** ');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.delegationService
				.getList(clientId)
				.then(
					function (response) {
						that.delegationList = response;
						// console.log('that.delegationList...');
						// console.log(that.delegationList);
						that.isDelegationListLoaded = true;
						if (that.selectedDelegation) that.refreshResponsables();
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getSubdelegationList(delegationId: number) {
		// console.log('[VEHICLE-FORM] getSubdelegationList *** ');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.subdelegationService
				.getList(delegationId)
				.then(
					function (response) {
						that.subdelegationList = response;
						// console.log('that.subdelegationList...');
						// console.log(that.subdelegationList);
						that.isSubdelegationListLoaded = true;
						if (that.selectedSubdelegation) that.refreshResponsables();
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getCecoList(billingAccountId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.cecoService
				.getList(billingAccountId)
				.then(
					function (response) {
						that.cecoList = response;
						that.isCecoListLoaded = true;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getFleetList() {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.fleetService
				.getInventoryTree()
				.then(
					function (response) {
						that.fleetList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleGroupList(fleetId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.groupService
				.getByFleet(fleetId)
				.then(
					function (response) {
						that.vehicleGroupList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleUsageList(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.vehicleUsageService
				.getList(clientId)
				.then(
					function (response) {
						that.vehicleUsageList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getAvailableCards(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.cardService
				.getList(clientId)
				.then(
					function (response) {
						if (response) response.forEach((x) => that.cardList.push(x));
						/* ordenem alfabeticament */
						that.cardList.sort((a, b) => (a.number > b.number ? 1 : -1));
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	/********************************/
	/**  ON  events                **/
	/********************************/
	public onChangeBrand(event) {
		this.selectedBrand = event.value;
		this.selectedModel = null;
		this.selectedVersion = null;
		this.modelList = null;
		this.versionList = null;
		this.errorMessages = [];
		if (this.selectedBrand.logo) {
			this.logoImageUrl = null;
			this.logoEncodedImage = this.selectedBrand.logo.imageEncoded;
		} else {
			this.logoImageUrl = 'assets/icons/no-image-available.svg';
			this.logoEncodedImage = null;
		}
		this.getModelsByBrand(this.selectedBrand.id);
	}

	public onChangeModel(event) {
		this.selectedModel = event.value;
		this.selectedVehicle.model = this.selectedModel;
		/* versions */
		this.isVersionLoaded = false;
		this.selectedVersion = null;
		this.versionList = null;
		this.errorMessages = [];
		this.getVersionsByModel(this.selectedModel.id);
	}

	public onChangeVersion(event) {
		this.selectedVersion = event.value;
		this.selectedVehicle.version = this.selectedVersion;
		this.errorMessages = [];
	}

	public onChangeBillingAccount(event) {
		this.selectedBillingAccount = event.value;
		this.selectedCeco = null;
		this.isCecoListLoaded = false;
		this.getCecoList(this.selectedBillingAccount.id);
		this.errorMessages = [];
		this.initVehicleAssignmentDate();

		this.checkBillingAccountsMatch();
	}

	public onChangeVehicleStatus(event) {
		this.selectedVehicleStatus = event.value;
		this.selectedVehicle.status = this.selectedVehicleStatus;
		this.errorMessages = [];
	}

	public onChangeDelegation(event) {
		this.selectedDelegation = event.value;
		this.selectedSubdelegation = null;
		this.isSubdelegationListLoaded = false;
		this.getSubdelegationList(this.selectedDelegation.id);
		this.initVehicleAssignmentDate();
		this.errorMessages = [];
		this.refreshResponsables();
	}

	public onChangeSubDelegation(event) {
		this.selectedSubdelegation = event.value;
		this.refreshResponsables();
		this.initVehicleAssignmentDate();
		this.errorMessages = [];
		this.refreshResponsables();
	}

	public onChangeCeco(event) {
		this.initVehicleAssignmentDate();
	}

	public toggleRegistered(event) {
		this.errorMessages = [];
		if (event.checked) {
			this.selectedVehicle.dateRegistered = new Date();
		} else {
			this.selectedVehicle.dateRegistered = null;
		}
	}

	public toggleDisabled(event) {
		this.errorMessages = [];
		if (event.checked) {
			this.selectedVehicle.enabled = !event.checked;
			this.selectedVehicle.disabledDate = new Date();
			this.selectedVehicle.disabledUser = this.authService.user;
		} else {
			this.selectedVehicle.enabled = !event.checked;
			this.selectedVehicle.disabledDate = null;
			this.selectedVehicle.disabledUser = null;
			this.selectedVehicle.updateDate = new Date();
			this.selectedVehicle.updateUser = this.authService.user;
		}
	}

	public onChangeFleet(event?) {
		this.vehicleGroupList = this.selectedFleet.groups;
		this.errorMessages = [];
	}

	public onChangeVehicleGroup(event) {
		this.errorMessages = [];
	}

	public onChangeVehicleUsage(event) {
		this.errorMessages = [];
	}

	public onVisibleChange() {
		Object.assign(this.selectedVehicle, this.originalVehicle);
	}

	public onRemoveDriver() {
		this.selectedDriver = null;
	}

	public onRemoveDelegation() {
		this.selectedDelegation = null;
		this.selectedSubdelegation = null;
		this.subdelegationList = null;
		this.refreshResponsables();
	}

	public onRemoveSubDelegation() {
		this.selectedSubdelegation = null;
		this.refreshResponsables();
	}

	public onRemoveCeco() {
		this.selectedCeco = null;
		//this.refreshResponsables();
	}

	public onRemoveIva() {
		this.selectedIva = null;
	}

	public onAddAssignment() {
		this.showAssignmentForm = true;
	}

	public onAddDriver() {
		this.showDriverForm = true;
	}

	public onChangeNameLockStatus() {
		this.nameLocked = !this.nameLocked;
	}

	public onSelectDriver() {
		this.driverService.selectDriver.next();
	}

	public onImageChange(event: any): void {
		const imageReader = new FileReader();
		this.vehicleImage = event.target.files[0];
		if (event.target.files && event.target.files.length) {
			imageReader.onload = (readerEvent: any) => {
				this.vehicleImageUrl = readerEvent.target.result;
				this.selectedVehicle.image = null;
			};
			imageReader.readAsDataURL(event.target.files[0]);
		}
	}

	public viewFinancingDocument() {
		//TODO
		this.notificationsService.add(
			Severity.success,
			this.translate.instant('general.generalInformation') + '!',
			this.translate.instant('events.pending')
		);
	}

	public viewInsuranceDocument() {
		//TODO
		this.notificationsService.add(
			Severity.success,
			this.translate.instant('general.generalInformation') + '!',
			this.translate.instant('events.pending')
		);
	}

	onShowHistoric() {
		this.vehicleHtcService.getVehicleHtc.next(this.selectedVehicle);
	}

	public onSaveVehicle() {
		// console.log('[VEHICLE-FORM] onSaveVehicle *** ');
		// console.log('oldName: ' + this.originalVehicle.name);
		// console.log('newName: ' + this.selectedVehicle.name);
		// console.log('this.isNew: ' + this.isNew);
		this.errorMessages = [];

		/* si el vehicle es nou, o no ho es pero hem modificat la matricula ... */
		if (this.isNew || (!this.isNew && this.originalVehicle.name !== this.selectedVehicle.name)) {
			this.checkDuplicatePlate(this.selectedVehicle.name);
		} else {
			this.onChangePlate();
		}
	}

	public onChangePlate() {
		/* validem si la matricula ha canviat per mostrar un avis */
		if (!this.isNew && this.originalVehicle.name !== this.selectedVehicle.name) {
			this.confirmationService.confirm({
				message: this.translate.instant('tableManagement.vehicle.changePlateMsg'),
				accept: () => {
					this.saveVehicle();
				},
			});
		} else {
			this.saveVehicle();
		}
	}

	/*******************************/
	/** CREATE ENTITIES FUNCIONS  **/
	/*******************************/
	public saveVehicle() {
		// console.log('[VEHICLE-FORM] saveVehicle *** ');
		if (this.validateVehicleForm()) {
			/** vehicle data */
			let vehicleData = {
				id: this.isNew ? 0 : this.selectedVehicle.id,
				//billingAccount: this.isNew ? { "id": this.selectedBillingAccount.id } : { "id": this.selectedVehicle.billingAccount.id },
				billingAccount: { id: this.selectedBillingAccount.id },
				owner:
					this.isNew || !this.selectedVehicle.owner
						? { id: this.authService.user.id }
						: { id: this.selectedVehicle.owner.id }, //quin propietari informar??
				name: this.selectedVehicle.name.toUpperCase().replace(' ', ''),
				model: !this.selectedModel ? null : { id: this.selectedModel.id },
				image: !this.selectedVehicle.image ? null : { id: this.selectedVehicle.image.id },
				version: !this.selectedVersion ? null : { id: this.selectedVersion.id },
				frameNumber: this.selectedVehicle.frameNumber,
				disabledDate:
					!this.selectedVehicle.disabledDate ? null : this.selectedVehicle.disabledDate.toISOString(),
				gps: this.selectedVehicle.gps,
				client: this.isNew ? { id: this.clientId } : { id: this.selectedVehicle.client.id },
				disabledUser:
					!this.selectedVehicle.disabledUser ? null : { id: this.selectedVehicle.disabledUser.id },
				createDate: this.isNew ? new Date().toISOString() : this.selectedVehicle.createDate,
				createUser: this.isNew ? { id: this.authService.user.id } : this.selectedVehicle.createUser,
				updateDate: this.isNew ? null : new Date().toISOString(),
				updateUser: this.isNew ? null : { id: this.authService.user.id },
				status: !this.selectedVehicleStatus ? null : { id: this.selectedVehicleStatus.id },
				usage: !this.selectedVehicle.usage ? null : { id: this.selectedVehicle.usage.id },
				observations: this.selectedVehicle.observations,
				//fuelCard: this.selectedVehicle.fuelCard === null ? null : { "id": this.selectedVehicle.fuelCard.id },
				group: !this.selectedVehicle.group ? null : { id: this.selectedVehicle.group.id },
				enabled: this.selectedVehicle.enabled,
				color: this.selectedVehicle.color,
				km: this.selectedVehicle.km,
				maxSpeed: this.selectedVehicle.maxSpeed,
				registered: false,
				insurance:
					this.isNew || !this.selectedVehicle.insurance ? null : { id: this.selectedVehicle.insurance.id },
				financing:
					this.isNew || !this.selectedVehicle.financing ? null : { id: this.selectedVehicle.financing.id },
				lastManualKMUpdate:
					this.initialKmValue === this.selectedVehicle.km
						? null
						: parseInt(this.selectedVehicle.disabledDate.getTime().toFixed(0)),
				delegation: !this.selectedDelegation ? null : { id: this.selectedDelegation.id },
				subDelegation: !this.selectedSubdelegation ? null : { id: this.selectedSubdelegation.id },
				ceco: !this.selectedBillingAccount ? null : { id: this.selectedCeco.id },
				driver: !this.selectedDriver ? null : { id: this.selectedDriver.id },
				fleetManager: this.customFleetManager ? { id: this.fleetManager.id } : null,
				serviceManager: this.customServiceManager ? { id: this.serviceManager.id } : null,
				zoneLeader: this.customZoneLeader ? { id: this.zoneLeader.id } : null,
				dateRegistered: this.selectedVehicle.dateRegistered
			};

			// console.log(vehicleData);
			// console.log('==> Create/Update ....');
			if (this.isNew) {
				this.createVehicle(vehicleData);

				// this.isNew = false; ABRAHAM 16/04/2024 CIR-270
			} else {
				if (
					this.initialEnabledStatus !== this.selectedVehicle.enabled ||
					this.initialDisabledDate !== this.selectedVehicle.disabledDate
				) {
					this.confirmationService.confirm({
						message: this.selectedVehicle.enabled
							? this.translate.instant('tableManagement.vehicleStatus.enableVehicle')
							: this.translate.instant('tableManagement.vehicleStatus.disableVehicle'),
						accept: async () => {
							var vehicleToSend = this.selectedVehicle;
							vehicleToSend.driverAssignments = null;
							await this.invVehicleService.changeStatusCirsa(vehicleToSend, {
								status: this.selectedVehicle.enabled,
							});
							this.updateVehicle(vehicleData);
						},
					});
				} else {
					this.isEditingDisabled = false;
					this.updateVehicle(vehicleData);
				}
			}
		}
	}

	public updateVehicle(newVehicle) {
		console.log('[VEHICLE-FORM] updateVehicle *** ');
		console.log(newVehicle);
		//Object.assign(this.originalVehicle, this.selectedVehicle);
		this.isEditingDisabled = true;

		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.updateVehicle(newVehicle)
				.then(
					function (response) {
						that.selectedVehicle = response;
						that.getVehicle(that.selectedVehicle.id); //BUG: el update no retorna be les subentitats
						/** imatge del vehicle */
						if (that.vehicleImage) that.createVehicleImage();
						/** vehicleAssignment Data */
						that.createVehicleAssignment();
						/** DriverAssignment Data */
						if (that.selectedDriver) that.createDriverAssignment();
						if (!that.selectedVehicle.enabled &&
							 that.originalVehicle.enabled !== that.selectedVehicle.enabled &&
							 that.selectedVehicle.km) {
								that.createMileage('HI');
							}
						Object.assign(that.originalVehicle, that.selectedVehicle);
						//that.isDialogVisible = false;
						that.isEditingDisabled = false;
						that.notificationsService.add(
							Severity.success,
							that.translate.instant('general.generalInformation') + '!',
							that.translate.instant('Vehicle ' + that.selectedVehicle.id + ' updated successful.')
						);
						that.invVehicleService.vehicleSaved.next();
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public createVehicle(newVehicle) {
		// console.log('[VEHICLE-FORM] createVehicle *** ');
		this.isEditingDisabled = true;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.createVehicle(newVehicle)
				.then(
					function (response) {
						// console.log('+++ response...');
						// console.log(response);
						//that.selectedVehicle = response;
						that.selectedVehicle.id = response.id;
						that.selectedVehicle.client = response.client;
						that.selectedVehicle.group = response.group;
						that.selectedVehicle.billingAccount = response.billingAccount;
						that.selectedVehicle.owner = response.owner;
						that.selectedVehicle.lastManualKMUpdate = response.lastManualKMUpdate;
						that.selectedVehicle.status = response.status;
						// console.log('+++ selectedVehicle...');
						// console.log(that.selectedVehicle);
						/** imatge del vehicle */
						if (that.vehicleImage) that.createVehicleImage();
						/** vehicleAssignment Data */
						that.createVehicleAssignment();
						/** DriverAssignment Data */
						if (that.selectedDriver) that.createDriverAssignment();
						that.isEditingDisabled = false;
						that.showTabs = true;
						that.notificationsService.add(
							Severity.success,
							that.translate.instant('general.generalInformation') + '!',
							that.translate.instant('Vehicle ' + that.selectedVehicle.id + ' created successful.')

						);
						that.isNew = false; //pq es pugui seguir modificant sense tancar el form
						Object.assign(that.originalVehicle, response);
						// console.log("that.originalVehicle...");
						// console.log(that.originalVehicle);
						that.invVehicleService.vehicleSaved.next();
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public createVehicleImage() {
		const formData = new FormData();
		formData.append('myfile[]', this.vehicleImage);
		formData.append('entity', this.entityVehicleImage);
		formData.append('eventId', this.selectedVehicle.id.toString());
		this.iconService
			.uploadIcon(formData)
			.then((res) => {
				this.notificationsService.add(
					Severity.success,
					this.translate.instant('userProfile.settingImageTitle'),
					this.translate.instant('userProfile.settingsImageUpdatedMessage')
				);
				this.vehicleImage = res;
				this.vehicleEncodedImage = res.imageEncoded;
			})
			.catch(() => {
				this.notificationsService.add(
					Severity.error,
					this.translate.instant('userProfile.settingsFailedImageTitle'),
					this.translate.instant('userProfile.settingsFailedImageMessage')
				);
			});
	}

	public createVehicleAssignment() {
		// console.log("[VEHICLE-FORM] createVehicleAssignment ***");
		// console.log("this.vehicleAssignment...");
		// console.log(this.vehicleAssignment);
		// console.log("this.selectedBillingAccount...");
		// console.log(this.selectedBillingAccount);
		// console.log("this.selectedDelegation...");
		// console.log(this.selectedDelegation);
		// console.log("this.selectedSubdelegation...");
		// console.log(this.selectedSubdelegation);
		// console.log("this.selectedCeco...");
		// console.log(this.selectedCeco);
		// console.log("this.selectedIva...");
		// console.log(this.selectedIva);
		/** mirem si hi han canvis */
		if (
			!this.vehicleAssignment ||
			(this.vehicleAssignment &&
				(this.selectedBillingAccount.id !== this.vehicleAssignment.company.id ||
					(this.selectedDelegation && !this.vehicleAssignment.delegation) ||
					(this.selectedDelegation && this.vehicleAssignment.delegation && this.selectedDelegation.id !== this.vehicleAssignment.delegation.id) ||

					(!this.selectedSubdelegation && this.vehicleAssignment.subDelegation) ||
					(this.selectedSubdelegation && !this.vehicleAssignment.subDelegation) ||
					(this.selectedSubdelegation && this.vehicleAssignment.subDelegation && this.selectedSubdelegation.id !== this.vehicleAssignment.subDelegation.id) ||

					(this.selectedCeco && !this.vehicleAssignment.ceco) ||
					(this.selectedCeco && this.vehicleAssignment.ceco && this.selectedCeco.id !== this.vehicleAssignment.ceco.id) ||

					(this.selectedIva && !this.vehicleAssignment.iva) ||
					(this.selectedIva && this.vehicleAssignment.iva && this.selectedIva !== this.vehicleAssignment.iva)
				)
			)
		) {
			/** Si hi han canvis tanquem el vehicleAssignment actual ...  */
			// console.log("=> Close vehicleAssignment...");
			if (this.vehicleAssignment) {
				let oldVehicleAssignmentData = {
					id: this.vehicleAssignment.id,
					vehicle: { id: this.selectedVehicle.id },
					company: { id: this.vehicleAssignment.company.id },
					delegation: this.vehicleAssignment.delegation ? { id: this.vehicleAssignment.delegation.id } : null,
					subDelegation: this.vehicleAssignment.subDelegation ? { id: this.vehicleAssignment.subDelegation.id } : null,
					ceco: this.vehicleAssignment.ceco ? { id: this.vehicleAssignment.ceco.id } : null,
					iva: this.vehicleAssignment.iva,
					department: null,
					startDate: this.vehicleAssignment.startDate,
					endDate: this.dateVehicleAssignment,
					client: { id: this.clientId },
					disabled: false,
				};
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.vehicleAssignmentsService
						.update(oldVehicleAssignmentData)
						.then(
							function (response) { },
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							}
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});
			}

			/** nou vehicleAssignment */
			// console.log("=> New vehicleAssignment...");
			let newVehicleAssignmentData = {
				id: 0,
				vehicle: { id: this.selectedVehicle.id },
				company: { id: this.selectedBillingAccount.id },
				delegation: this.selectedDelegation ? { id: this.selectedDelegation.id } : null,
				subDelegation: this.selectedSubdelegation ? { id: this.selectedSubdelegation.id } : null,
				ceco: this.selectedCeco ? { id: this.selectedCeco.id } : null,
				iva: this.selectedIva,
				department: null, //pendent de veure si ho posem
				startDate: this.dateVehicleAssignment,
				endDate: null, //l'assignment actual no te data final
				client: { id: this.clientId },
				disabled: false,
			};
			const promise = new Promise<void>((resolve, reject) => {
				const that = this;
				this.vehicleAssignmentsService
					.create(newVehicleAssignmentData)
					.then(
						function (response) {
							that.vehicleAssignment = response;
							that.dateVehicleAssignmentDisabled = true;
						},
						/* failure */
						function (error) {
							console.log('The request failed: ' + error);
							resolve();
						}
					)
					.catch(function (reason) {
						console.log('Catched: ' + reason);
						resolve();
					});
			});
		}
	}

	public createDriverAssignment() {
		/** mirem si hi han canvis */
		if (
			!this.driverAssignment ||
			(this.driverAssignment && this.selectedDriver.id !== this.driverAssignment.driver.id)
		) {
			/** Si hi han canvis tanquem el vehicleAssignment actual ...  */
			if (this.driverAssignment) {
				let oldDriverAssignmentData = {
					id: this.driverAssignment.id,
					vehicle: { id: this.selectedVehicle.id },
					driver: { id: this.driverAssignment.driver.id },
					startDate: this.driverAssignment.startDate,
					endDate: this.dateDriverAssignment,
				};
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.driverAssignmentsService
						.update(oldDriverAssignmentData)
						.then(
							function (response) { },
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							}
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});
			}

			/** nou driverAssignment */
			let newDriverAssignmentData = {
				id: 0,
				vehicle: { id: this.selectedVehicle.id },
				driver: { id: this.selectedDriver.id },
				startDate: this.dateDriverAssignment,
				endDate: null,
				client: { id: this.clientId },
				disabled: false,
			};

			this.checkBillingAccountsMatch();
			const promise = new Promise<void>((resolve, reject) => {
				const that = this;
				this.driverAssignmentsService
					.create(newDriverAssignmentData)
					.then(
						function (response) {
							that.driverAssignment = response;
							that.dateDriverAssignmentDisabled = true;
							that.createMileage('HI');
						},
						/* failure */
						function (error) {
							console.log('The request failed: ' + error);
							resolve();
						}
					)
					.catch(function (reason) {
						console.log('Catched: ' + reason);
						resolve();
					});
			});
		}
	}

	public createMileage(type: string) {
		let objectString = {
			id: 0,
			vehicle: { id: this.selectedVehicle.id, lastManualKMUpdate: this.selectedVehicle.km },
			type: type,
			readingDate: new Date(),
			kms: this.selectedVehicle.km,
			consumption: null,
			client: { id: this.authService.user.client.id },
			disabled: false
	   };
	   console.log(objectString);
	   const promise = new Promise<void>((resolve, reject) => {
		const that = this;
		this.mileageService
			.create(objectString)
			.then(
				function (response) {
					that.mileageService.refreshList.next({ vehicleId: that.selectedVehicle.id });
				 },
				/* failure */
				function (error) {
					console.log('The request failed: ' + error);
					resolve();
				}
			)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
	});
	}

	/**********************/
	/** ALTRES FUNCTIONS **/
	/**********************/
	public getVehicle(vehicleId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.vehicleService
				.findVehicle(vehicleId.toString())
				.then(
					function (response) {
						that.selectedVehicle = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	/* ordre: vehicle -> subdelegation -> delegation */
	public refreshResponsables() {
		// console.log('______ [VEHICLE-FORM] refreshResponsables ______________ ');
		// console.log(this.selectedVehicle.fleetManager);
		// console.log(this.selectedVehicle.serviceManager);
		// console.log(this.selectedVehicle.zoneLeader);
		let index = this.selectedDelegation ? this.delegationList.findIndex((x) => x.id === this.selectedDelegation.id) : -1;
		let index_s = this.selectedSubdelegation ? this.subdelegationList.findIndex((x) => x.id === this.selectedSubdelegation.id) : -1;

		/*==== FLEET MANAGER ===============*/
		this.fleetManagerPlaceHolder = this.translate.instant('general.select') + "...";
		this.fleetManagerImageUrl = null;
		this.fleetManagerEncodedImage = null;
		if (this.selectedVehicle.fleetManager) {
			this.customFleetManager = true;
			this.fleetManagerPlaceHolder = '';
		} else {
			this.customFleetManager = false;
			if (index_s !== -1 && this.subdelegationList[index_s].fleetManager) this.fleetManager = this.subdelegationList[index_s].fleetManager;
			else if (index !== -1 && this.delegationList[index].fleetManager) this.fleetManager = this.delegationList[index].fleetManager;
			else this.fleetManager = null;
		}
		if (this.fleetManager) {
			if (this.fleetManagerList.findIndex((x) => x.id === this.fleetManager.id) === -1)
				this.fleetManagerPlaceHolder = '[' + this.fleetManager.surname1 + ' ' + this.fleetManager.surname2 + ', ' + this.fleetManager.name + ']';
			this.refreshFleetManagerImage();
		}

		/*==== SERVICE MANAGER ===============*/
		this.serviceManagerPlaceHolder = this.translate.instant('general.select') + "...";
		this.serviceManagerImageUrl = null;
		this.serviceManagerEncodedImage = null;
		if (this.selectedVehicle.serviceManager) {
			this.customServiceManager = true;
			this.serviceManagerPlaceHolder = '';
		} else {
			this.customServiceManager = false;
			if (index_s !== -1 && this.subdelegationList[index_s].serviceManager) this.serviceManager = this.subdelegationList[index_s].serviceManager;
			else if (index !== -1 && this.delegationList[index].serviceManager) this.serviceManager = this.delegationList[index].serviceManager;
			else this.serviceManager = null;
		}
		if (this.serviceManager) {
			if (this.serviceManagerList.findIndex((x) => x.id === this.serviceManager.id) === -1)
				this.serviceManagerPlaceHolder = '[' + this.serviceManager.surname1 + ' ' + this.serviceManager.surname2 + ', ' + this.serviceManager.name + ']';
			this.refreshServiceManagerImage();
		}

		/*==== ZONE LEADER ===============*/
		this.zoneLeaderPlaceHolder = this.translate.instant('general.select') + "...";
		this.zoneLeaderImageUrl = null;
		this.zoneLeaderEncodedImage = null;
		if (this.selectedVehicle.zoneLeader) {
			this.customZoneLeader = true;
			this.zoneLeaderPlaceHolder = '';
		} else {
			this.customZoneLeader = false;
			if (index_s !== -1 && this.subdelegationList[index_s].zoneLeader) this.zoneLeader = this.subdelegationList[index_s].zoneLeader;
			else if (index !== -1 && this.delegationList[index].zoneLeader) this.zoneLeader = this.delegationList[index].zoneLeader;
			else this.zoneLeader = null;
		}
		if (this.zoneLeader) {
			if (this.zoneLeaderList.findIndex((x) => x.id === this.zoneLeader.id) === -1)
				this.zoneLeaderPlaceHolder = '[' + this.zoneLeader.surname1 + ' ' + this.zoneLeader.surname2 + ', ' + this.zoneLeader.name + ']';
			this.refreshZoneLeaderImage();
		}

		// console.log("********************************************");
		// console.log("customVehicle? " + this.customFleetManager);
		// console.log("delegation? " + ((index !== -1 && this.delegationList[index].fleetManager) ? true : false));
		// console.log("subdelegation? " + ((index_s !== -1 && this.subdelegationList[index_s].fleetManager) ? true : false));
		// console.log("placeHolder? " + this.zoneLeaderPlaceHolder);
		// console.log(this.fleetManager);

		// console.log("********************************************");
		// console.log("customServiceManager? " + this.customServiceManager);
		// console.log("delegation? " + ((index !== -1 && this.delegationList[index].serviceManager) ? true : false));
		// console.log("subdelegation? " + ((index_s !== -1 && this.subdelegationList[index_s].serviceManager) ? true : false));
		// console.log("placeHolder? " + this.serviceManagerPlaceHolder);
		// console.log(this.serviceManager);

		// console.log("********************************************");
		// console.log("customZoneLeader? " + this.customZoneLeader);
		// console.log("delegation? " + ((index !== -1 && this.delegationList[index].zoneLeader) ? true : false));
		// console.log("subdelegation? " + ((index_s !== -1 && this.subdelegationList[index_s].zoneLeader) ? true : false));
		// console.log("placeHolder? " + this.zoneLeaderPlaceHolder);
		// console.log(this.zoneLeader);
	}

	public validateVehicleForm(): Boolean {
		// console.log("[VEHICLE-FORM] validateVehicleForm *** ");
		this.errorMessages = [];

		if (
			this.selectedVehicle.name === '' ||
			!this.selectedVehicle.group ||
			/* vehicle assignment */
			!this.selectedBillingAccount ||
			!this.selectedDelegation ||
			//!this.selectedSubdelegation ||
			!this.selectedCeco ||
			!this.selectedIva ||
			/* vehicle usage */
			!this.selectedVehicle.usage ||
			/* vehicle status */
			!this.selectedVehicleStatus
		) {
			this.errorMessages.push(this.translate.instant('error.required-field.generic'));
			this.notificationsService.add(
				Severity.error,
				this.translate.instant(this.isNew ? 'general.createError' : 'general.updateError') + '!',
				this.translate.instant('error.required-field.generic')
			);
			window.scroll({ top: 0, behavior: 'smooth' });
		}
		return this.errorMessages.length === 0;
	}

	public checkDuplicatePlate(newPlate: string) {
		// console.log("[VEHICLE-FORM] checkDuplicatePlate - " + newPlate);
		let arg0 = {
			name: newPlate,
		};

		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			return this.vehicleService
				.findVehicleByName(arg0)
				.then(
					function (res) {
						if (res && res.length > 0) {
							that.errorMessages.push(that.translate.instant('control-panel.vehicles.duplicatePlate'));
							that.notificationsService.add(
								Severity.error,
								this.translate.instant('general.error') + '!',
								this.translate.instant('control-panel.vehicles.duplicatePlate')
							);
							window.scroll({ top: 0, behavior: 'smooth' });
						} else {
							that.onChangePlate();
						}
					},
					(err) => {
						resolve();
					}
				)
				.catch((e) => {
					console.log('Catched: ' + e);
					resolve();
				});
		});
	}

	onKeyDownDecimal(event: any) {
		var pressedKey = event.key;
		const keyMap = new Map<number, string>();
		keyMap.set(110, ',');
		const { keyCode } = event;
		if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
			event.preventDefault();
			event.target.value += ',';
		}
	}

	initVehicleAssignmentDate() {
		if (this.dateVehicleAssignmentDisabled) {
			this.dateVehicleAssignment = new Date();
		}
		this.dateVehicleAssignmentDisabled = false;
	}

	initDriverAssignmentDate() {
		if (this.dateDriverAssignmentDisabled) {
			this.dateDriverAssignment = new Date();
		}
		this.dateDriverAssignmentDisabled = false;
	}

	addBrand() {
		this.brandForm.create();
		this.brandForm.return.subscribe((value) => {
			this.getBrandsByClient(this.clientId);
		});
	}

	async addModel() {
		this.modelForm.create();
		this.modelForm.return.subscribe((value) => {
			this.getModelsByBrand(this.selectedBrand.id);
		});
	}

	addVersion() {
		this.vehicleVersionForm.create();
		this.vehicleVersionForm.return.subscribe((value) => {
			this.getVersionsByModel(this.selectedModel.id);
		});
	}

	loadOptionsFuelType() {
		var that = this;

		this.fuelTypeService.getList().then((data) => {
			that.optionsfuelType = [];
			for (let i = 0; i < data.length; i++) {
				that.optionsfuelType.push({
					label: this.translate.instant('tableManagement.fuelType.' + data[i]),
					value: data[i],
				});
			}
		});
	}

	onCustomFleetManager(event) {
		// console.log('[VEHICLE-FORM] onCustomFleetManager ***');
		// console.log(event);
		// console.log(this.fleetManager);
		this.customFleetManager = true;
		this.selectedVehicle.fleetManager = this.fleetManager;
		this.refreshFleetManagerImage();
	}

	refreshFleetManagerImage() {
		if (!this.fleetManager || !this.fleetManager.profileImage) {
			this.fleetManagerImageUrl = 'assets/icons/no-image-available.svg';
			this.fleetManagerEncodedImage = null;
		} else {
			this.fleetManagerImageUrl = null;
			this.fleetManagerEncodedImage = this.fleetManager.profileImage.imageEncoded;
		}
	}

	onCustomServiceManager(event) {
		// console.log('[VEHICLE-FORM] onCustomServiceManager ***');
		// console.log(event);
		// console.log(this.serviceManager);
		this.customServiceManager = true;
		this.selectedVehicle.serviceManager = this.serviceManager;
		this.refreshServiceManagerImage();
	}

	refreshServiceManagerImage() {
		if (!this.serviceManager || !this.serviceManager.profileImage) {
			this.serviceManagerImageUrl = 'assets/icons/no-image-available.svg';
			this.serviceManagerEncodedImage = null;
		} else {
			this.serviceManagerImageUrl = null;
			this.serviceManagerEncodedImage = this.serviceManager.profileImage.imageEncoded;
		}
	}

	onCustomZoneLeader(event) {
		// console.log('[VEHICLE-FORM] onCustomZoneLeader ***');
		// console.log(event);
		// console.log(this.zoneLeader);
		this.customZoneLeader = true;
		this.selectedVehicle.zoneLeader = this.zoneLeader;
		this.refreshZoneLeaderImage();
	}

	refreshZoneLeaderImage() {
		if (!this.zoneLeader || !this.zoneLeader.profileImage) {
			this.zoneLeaderImageUrl = 'assets/icons/no-image-available.svg';
			this.zoneLeaderEncodedImage = null;
		} else {
			this.zoneLeaderImageUrl = null;
			this.zoneLeaderEncodedImage = this.zoneLeader.profileImage.imageEncoded;
		}
	}

	onCustomUndo(type) {
		// console.log("[VEHICLE-FORM] onCustomUndo " + type);
		switch (type) {
			case 'fleetManager':
				this.selectedVehicle.fleetManager = null;
				break;
			case 'serviceManager':
				this.selectedVehicle.serviceManager = null;
				break;
			case 'zoneLeader':
				this.selectedVehicle.zoneLeader = null;
				break;
		}
		this.refreshResponsables();
	}

}
