import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-tow-alarm-information-gv800",
  templateUrl: "./tow-alarm-information-gv800.component.html",
  styleUrls: ["./tow-alarm-information-gv800.component.css"],
})
export class TowAlarmInformationGv800Component
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() towAlarm: Rest.TowAlarmGV800;
  @ViewChild("towAlarmDiv", { static: true }) towAlarmDiv;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {
    if (this.towAlarm != null) {
      this.disableAnalogInputDIV(!this.towAlarm.towEnable);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.towAlarmDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.towAlarmDiv.nativeElement.classList.remove("disableDIV");
    }
  }

  trackModeSwitch = false;

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
