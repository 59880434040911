import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";

import { HttpClient } from "@angular/common/http";


import { Subject } from "rxjs";

@Injectable()
export class InsuranceComponentService extends EntityService<Rest.InsuranceComponent> {
    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }


    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: {includeDisabled?: boolean; }
    ): Rest.RestResponse<Rest.Page<Rest.InsuranceComponent>> {
        try {
            return this.client.InsuranceComponent_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting InsuranceComponent page:", error);
            throw error;
        }
    }

    calculateQuarterly(arg0:Rest.InsuranceQuarterlyParameters):any{
        try {
            return this.client.InsuranceComponent_calculateQuarterly(arg0);
        } catch (error) {
            console.error("Error calculating quarterly:", error);
            throw error;
        }
    }



}
