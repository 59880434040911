<div class="container m-0 p-0">
  <div class="row">
    <p-tree
      class="custom-fleet-tree"
      [value]="fleetsTree"
      selectionMode="single"
      [propagateSelectionUp]="false"
      [propagateSelectionDown]="false">

      <ng-template let-node pTemplate="entity">

        <div *ngIf="selectableEntities;then selectable else noSelectable"></div>

        <!-- #SELECTABLE -->
        <ng-template #selectable>

          <div *ngIf="node.data.id < 0;then noAbleToSelect else ableToSelect"></div>

          <!-- #SELECTABLE - #NO ABLE TO SELECT -->
          <ng-template #noAbleToSelect>

            <div *ngIf="node.entityType === 'Fleet';then noVisibleFleet else noVisibleGroup"></div>

            <!-- #SELECTABLE - #NO ABLE TO SELECT - #NO VISIBLE FLEET -->
            <ng-template #noVisibleFleet>

              <div class="row">

                <!-- COL 1: GLOBE ICON & FLEET NAME -->
                <div class="col p-0">
                  <div class="d-flex align-items-center custom-fleet-label-container" pTooltip="Non visible fleet">
                    <svg-icon class="d-flex m-0 p-0 ms-2" src="assets/icons/globe-fleet-tree.svg"
                              [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>
                    <label class="form-label m-0 ms-2 custom-fleet-tree-label not-visible">
                      {{node.label}}
                    </label>
                  </div>
                </div>

                <!-- COL 2: EXPAND & COLLAPSE BUTTONS -->
                <div class="col">
                  <div class="d-flex justify-content-end">

                    <svg-icon *ngIf="!node.expanded" class="d-flex m-0 p-0 me-2 custom-tree-node-icon" src="assets/icons/tree-expand.svg"
                              (click)="expandRecursive(node, true)"
                              [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

                    <svg-icon *ngIf="node.expanded" class="d-flex m-0 p-0 me-2 custom-tree-node-icon" src="assets/icons/tree-collapse.svg"
                              (click)="expandRecursive(node, false)"
                              [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

                  </div>
                </div>

              </div>

            </ng-template>

            <!-- #SELECTABLE - #NO ABLE TO SELECT - #NO VISIBLE GROUP -->
            <ng-template #noVisibleGroup>

              <div class="d-flex align-items-center">
                <div class="form-check d-flex align-items-center m-0 p-0" pTooltip="Non visible group">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox fleet-tree-checkbox"
                         type="checkbox"
                         [indeterminate]="indeterminateEntity[node.label+'-'+node.data.id]"
                         [(ngModel)]="optionEntities[node.label+'-'+node.data.id]"
                         (change)="nodeCheck($event, node)"
                  />

                  <label class="form-label m-0 ms-2 custom-fleet-tree-label second-level-label">
                    {{node.label}}
                  </label>

                </div>
              </div>

            </ng-template>

          </ng-template>

          <!-- #SELECTABLE - #ABLE TO SELECT -->
          <ng-template #ableToSelect>

            <!-- #SELECTABLE - #ABLE TO SELECT - FLEET -->
            <div *ngIf="node.entityType === 'Fleet'" class="row fleet-tree-first-level-label"
                 [ngClass]="{'fleet-tree-first-level-collapsed': !node.expanded}">

              <!-- COL 1: GLOBE ICON & FLEET NAME -->
              <div class="col p-0">
                <div class="d-flex align-items-center h-100 ps-3 custom-fleet-label-container">
                  <svg-icon class="d-flex m-0 p-0" src="assets/icons/globe-fleet-tree.svg"
                            [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>
                  <button id="rt-fleet-fleet-label-button" (click)="expandRecursive(node, collapsedFleet)">
                    <label class="form-label m-0 ms-2 custom-fleet-tree-label">
                      {{node.label}}
                    </label>
                  </button>
                </div>
              </div>

              <!-- COL 2: EXPAND & COLLAPSE BUTTONS -->
              <div class="col col-2 p-0">
                <div class="d-flex align-items-center justify-content-end h-100 pe-3">

                  <svg-icon *ngIf="!node.expanded" class="d-flex m-0 p-0 custom-tree-node-icon" src="assets/icons/tree-expand.svg"
                            (click)="expandRecursive(node, true)"
                            [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

                  <svg-icon *ngIf="node.expanded" class="d-flex m-0 p-0 custom-tree-node-icon" src="assets/icons/tree-collapse.svg"
                            (click)="expandRecursive(node, false)"
                            [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

                </div>
              </div>

            </div>

            <!-- #SELECTABLE - #ABLE TO SELECT - GROUP -->
            <div *ngIf="node.entityType === 'Group'">
              <div class="d-flex align-items-center mt-2 me-3 fleet-tree-second-level-label">

                <!-- GROUP CHECKBOX && GROUP EXPAND BUTTON && GROUP NAME && GROUP VEHICLE COUNTER -->
                <div class="form-check d-flex align-items-center flex-fill m-0 p-0">

                  <!-- GROUP CHECKBOX -->
                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox fleet-tree-checkbox"
                         type="checkbox"
                         [indeterminate]="indeterminateEntity[node.label+'-'+node.data.id]"
                         [(ngModel)]="optionEntities[node.label+'-'+node.data.id]"
                         (change)="nodeCheck($event, node)"
                         [disabled]="isNodeEmpty(node)"
                  />

                  <!-- GROUP EXPAND BUTTON -->
                  <div class="d-flex justify-content-center ms-2">

                    <button id="rt-fleet-expand-group-button"
                            [disabled]="isNodeEmpty(node)"
                            (click)="expandRecursive(node, collapsedGroup)"
                    >
                      <svg-icon [ngClass]="{'rotated': !node.expanded}"
                                class="d-flex m-0 p-0 custom-tree-node-icon"
                                src="assets/icons/select-down.svg"
                                [svgStyle]="{'height.px': 20, 'width.px': 20}"
                      ></svg-icon>
                    </button>

                  </div>

                  <!-- GROUP NAME -->
                  <button id="rt-fleet-group-label-button"
                          [disabled]="isNodeEmpty(node)"
                          (click)="expandRecursive(node, collapsedGroup)"
                  >
                    <label [ngClass]="{'rt-fleet-disabled-group': isNodeEmpty(node)}"
                           class="form-label m-0 ms-2 custom-fleet-tree-label second-level-label"
                    >
                      {{node.label}}
                    </label>
                  </button>

                  <!-- GROUP VEHICLE COUNTER -->
                  <div class="d-flex justify-content-end flex-fill" id="rt-fleet-vehicle-counter">
                    {{node.children.length}}
                    <div *ngIf="node.children.length === 1" class="ms-2">vehicle</div>
                    <div *ngIf="node.children.length > 1 || isNodeEmpty(node)" class="ms-2">vehicles</div>
                  </div>

                </div>
              </div>
            </div>

            <!-- #SELECTABLE - #ABLE TO SELECT - VEHICLE -->
            <div *ngIf="node.entityType === 'Vehicle'"
              (mouseleave)="hoveredVehicleId = -1"
              (mouseenter)="hoveredVehicleId = node.data.id">
              <div class="d-flex align-items-center mt-2 mb-2 me-3 pt-1 fleet-tree-third-level-label">
                <div class="form-check d-flex align-items-center m-0 p-0 flex-fill">

                  <!-- VEHICLE CHECKBOX -->
                  <input class="col-md-1 form-check-input d-flex justify-content-center align-items-center custom-checkbox fleet-tree-checkbox"
                    type="checkbox"
                    [indeterminate]="indeterminateEntity[node.label+'-'+node.data.id]"
                    [(ngModel)]="optionEntities[node.label+'-'+node.data.id]"
                    (change)="nodeCheck($event, node)"
                  />

                  <!-- VEHICLE STATUS ICON -->
                  <div class="d-flex align-items-center ms-2">

                    <!--<div *ngIf="node.enabled" class="d-flex">
                        <svg-icon class="d-flex m-0 p-0 ms-2" src="assets/icons/fleet-vehicle-green.svg"
                        [ngStyle]="{ 'fill': getVehicleColorState(node.data.id), 'stroke': getVehicleColorState(node.data.id) }"
                        [svgStyle]="{'height.px': 15, 'width.px': 15}"></svg-icon>                                                                    
                    </div>-->
                    <div *ngIf="node.enabled" class="d-flex">
 
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">
                          <g data-name="Path 656">
                              <path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" 
                              fill-rule="evenodd" [attr.fill]="'url(#gradient-' + node.data.id + ')'" transform="translate(125.85 682.004)"/>
                              <path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" 
                              stroke-linejoin="round" stroke-linecap="round" [attr.stroke]="getVehicleColorState(node.data.id,false)" fill="transparent" stroke-width=".96295"/>
                          </g>
                          <defs>
                              <linearGradient x1=".5" y1="0" x2=".5" y2="1" [id]="'gradient-' + node.data.id">
                                  <stop [attr.stop-color]="getVehicleColorState(node.data.id,true)" offset="0"/> 
                                  <stop [attr.stop-color]="getVehicleColorState(node.data.id,false)" offset="1"/>
                                                                                                     
                              </linearGradient>
                          </defs>
                      </svg>
                                                                                           
                    </div>

                    <div *ngIf="!node.enabled" class="d-flex vehicle-state-icon-container">
                      <img src="assets/icons/fleet-vehicle-red.png" class="vehicle-state-icon">
                    </div>

                  </div>

                  <!-- VEHICLE NAME -->
                  <button id="rt-fleet-vehicle-label-button">
                    <label
                      (click)="vehicleLabelClicked(node)"
                      class="form-label m-0 ms-2 custom-fleet-tree-label third-level-label">
                        {{node.label}}
                    </label>
                  </button>

                  <!-- VEHICLE OPTIONS -->
                  <div *ngIf="hoveredVehicleId == node.data.id"
                    class="justify-content-end d-flex flex-fill">
                      <app-tree-quick-actions
                        *ngFor="let vehicleOption of vehicleOptions"
                        [vehicle]="node.data"
                        [actionType]="vehicleOption">
                      </app-tree-quick-actions>
                  </div>
                  

                </div>
              </div>
            </div>

          </ng-template>

        </ng-template>



        

        <!-- #NO SELECTABLE -->
        <ng-template #noSelectable>

          <!-- #NO SELECTABLE - FLEET -->
          <div *ngIf="node.entityType === 'Fleet'" class="row">

            <!-- COL 1: GLOBE ICON & FLEET NAME -->
            <div class="col p-0">
              <div class="d-flex align-items-center custom-fleet-label-container">
                <svg-icon class="d-flex m-0 p-0 ms-2" src="assets/icons/globe-fleet-tree.svg"
                          [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>
                <label class="form-label m-0 ms-2 custom-fleet-tree-label">
                  {{node.label}}
                </label>
              </div>
            </div>

            <!-- COL 2: EXPAND & COLLAPSE BUTTONS -->
            <div class="col">
              <div class="d-flex justify-content-end">

                <svg-icon *ngIf="!node.expanded" class="d-flex m-0 p-0 me-2 custom-tree-node-icon" src="assets/icons/tree-expand.svg"
                          (click)="expandRecursive(node, true)"
                          [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

                <svg-icon *ngIf="node.expanded" class="d-flex m-0 p-0 me-2 custom-tree-node-icon" src="assets/icons/tree-collapse.svg"
                          (click)="expandRecursive(node, false)"
                          [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>

              </div>
            </div>

          </div>

          <!-- #NO SELECTABLE - GROUP -->
          <div *ngIf="node.entityType === 'Group'">

            <div class="d-flex align-items-center">
              <div class="form-check d-flex align-items-center m-0 p-0">

                <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox fleet-tree-checkbox"
                       type="checkbox"
                       [indeterminate]="indeterminateEntity[node.label+'-'+node.data.id]"
                       [(ngModel)]="optionEntities[node.label+'-'+node.data.id]"
                       (change)="nodeCheck($event, node)"
                />

                <label class="form-label m-0 ms-2 custom-fleet-tree-label second-level-label">
                  {{node.label}}
                </label>

              </div>
            </div>

          </div>

          <!-- #NO SELECTABLE - VEHICLE -->
          <div *ngIf="node.entityType === 'Vehicle'">

            <div class="d-flex align-items-center">
              <div class="form-check d-flex align-items-center m-0 p-0">

                <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox fleet-tree-checkbox"
                       type="checkbox"
                       [indeterminate]="indeterminateEntity[node.label+'-'+node.data.id]"
                       [(ngModel)]="optionEntities[node.label+'-'+node.data.id]"
                       (change)="nodeCheck($event, node)"
                />

                <div class="d-flex align-items-center ms-2">

                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">
                      <g data-name="Path 656">
                          <path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" 
                          fill-rule="evenodd" [attr.fill]="'url(#gradient-' + node.data.id + ')'" transform="translate(125.85 682.004)"/>
                          <path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" 
                          stroke-linejoin="round" stroke-linecap="round" [attr.stroke]="getVehicleColorState(node.data.id,false)" fill="transparent" stroke-width=".96295"/>
                      </g>
                      <defs>
                          <linearGradient x1=".5" y1="0" x2=".5" y2="1" [id]="'gradient-' + node.data.id">
                              <stop [attr.stop-color]="getVehicleColorState(node.data.id,true)" offset="0"/> 
                              <stop [attr.stop-color]="getVehicleColorState(node.data.id,false)" offset="1"/>
                                                                                                 
                          </linearGradient>
                      </defs>
                  </svg>

                </div>

                <label class="form-label m-0 ms-2 custom-fleet-tree-label second-level-label" (dblclick)="nodeClicked(node)">
                  {{node.label}}
                </label>

              </div>
            </div>

          </div>

        </ng-template>

      </ng-template>

      <ng-template pTemplate="empty">
        {{'table.tableEmptyMessage' | translate}}
      </ng-template>

    </p-tree>
  </div>
</div>
