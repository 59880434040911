import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { PhaseChangeEvent } from 'app/modules/vehicle-inventory/services/inventory-reports.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { DivisionService } from 'app/rest/division.service';
import { SubdivisionService } from 'app/rest/subdivision.service';

import client = Rest.Client;

import BillingAcount = Rest.BillingAccount;
import Division = Rest.Division;
import Subdivision = Rest.Subdivision;



@Component({
  selector: 'app-license-report-form',
  templateUrl: './license-report-form.component.html',
  styleUrls: ['./license-report-form.component.css'],
  providers: [
    BillingaccountService,
    DivisionService,
    SubdivisionService
  ],
  encapsulation: ViewEncapsulation.None
})
export class LicenseReportFormComponent implements OnInit {
  client: client;

  @Output() phaseChange = new EventEmitter<PhaseChangeEvent>();
  @Input() report: PhaseChangeEvent;

  reportData: Rest.InventoryReport;

  reportForm = new FormGroup({
    billingAccount: new FormControl(null),
    division: new FormControl(null),
    subdivision: new FormControl(null),
    expirationDate: new FormControl(null),
    parameters: new FormControl('DNI')
  });

  // PRESERVATION OF RELATIONSHIPS
  billingAccountRequestContext: Rest.ListPaginationRequest;
  divisionRequestContext: Rest.ListPaginationRequest;
  subdivisionRequestContext: Rest.ListPaginationRequest;

  // FORM OPTIONS LISTS
  billingAccounts: BillingAcount[] = [];
  divisions: Division[] = [];
  subdivisions: Subdivision[];

  isBillingAccountLoaded: Boolean = false;
  isDivisionLoaded: Boolean = false;
  isSubdivisionLoaded: Boolean = false;

  // FORM DATA
  selectedBillingAccounts: BillingAcount[] = [];
  selectedDivisions: Division[] = [];
  selectedSubdivisions: Subdivision[] = [];

  isBillingAccountChanged: Boolean = false;
  isDivisionChanged: Boolean = false;
  isSubdivisionChanged: Boolean = false;

  constructor(
    protected authenticationService: AuthenticationService,
    private billingAccountService: BillingaccountService,
    private divisionService: DivisionService,
    private subdivisionService: SubdivisionService
  ) { }

  ngOnInit(): void {
    this.client = this.authenticationService.user.client;

    this.billingAccountRequestContext = RestExt.firstPageRequestVehicleInventory();
    this.billingAccountRequestContext.filters["clientId"] = [this.client.id.toString()];

    this.divisionRequestContext = RestExt.firstPageRequestVehicleInventory();

    this.subdivisionRequestContext = RestExt.firstPageRequestVehicleInventory();

    this.fetchData();
  }

  onSubmit() {
    if (this.reportForm.valid) {
      this.toPhase3();
    }
  }

  goBack() {
    this.phaseChange.emit({ phase: 1, resetData: true });
    this.reportForm.reset();
  }

  toPhase3() {
    this.phaseChange.emit({ phase: 3, reportData: this.report.reportData, formData: this.reportForm.value });
    //TODO: Handle next button
  }

  fetchData() {
    this.reportData = this.report.reportData;

    this.fetchBillingAccounts();
    this.fetchDivisions();
    this.fetchSubdivisions();
  }

  fetchBillingAccounts() {
    this.billingAccountRequestContext = {
      sortBy: 'code',
      sortAsc: true,
      filters: { enabled: [true] },
    } as unknown as Rest.ListPaginationRequest;
    this.billingAccountRequestContext.filters["clientID"] = [this.client.id.toString()];


    this.billingAccountService.getPage(this.billingAccountRequestContext).then((p) => {
      this.billingAccounts = p.entities.map(account => ({
        ...account,
        codeName: `${account.code} ${account.name}`
      }));

      this.isBillingAccountLoaded = true;
    });
  }

  fetchDivisions() {
    this.divisionRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { disabled: [false] }
    } as unknown as Rest.ListPaginationRequest;

    this.divisionService.getPage(this.divisionRequestContext).then((p) => {
      this.divisions = p.entities.map(division => ({
        ...division,
        codeName: `${division.code} ${division.name}`
      }));

      this.isDivisionLoaded = true;
    });
  }

  fetchSubdivisions() {
    this.subdivisionRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { disabled: [false] }
    } as unknown as Rest.ListPaginationRequest;

    this.subdivisionService.getPage(this.subdivisionRequestContext).then((p) => {
      this.subdivisions = p.entities.map(subdivision => ({
        ...subdivision,
        codeName: `${subdivision.code} ${subdivision.name}`
      }));

      this.isSubdivisionLoaded = true;
    });
  }

}
