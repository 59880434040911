<p-panel *ngIf="display" header="{{ 'alerts.header' | translate }}" class="w-100">
	<!-- SECCION 1: SELECCIÓ DE RESPONSABLE -->
	<div class="d-flex row-cols-2 w-100 align-items-center">
		<div class="d-flex align-items-center">
			<div class="p-2 align-items-center">
				<form-input
					[required]="false"
					fieldName="{{ 'alerts.receivers' | translate }}"
					[style]="{ 'margin-right': '50px' }"
				>
					<p-multiSelect
						[options]="responsibleList"
						[(ngModel)]="selectedResponsibles"
						[placeholder]="placeHolder"
						optionLabel="name"
						[showClear]="true"
						[style]="{ width: '300px' }"
						[filter]="true"
						appendTo="body"
						[virtualScroll]="true"
						[virtualScrollItemSize]="50"
						optionLabel="formatResponsibleLabel"
						filterBy="formatResponsibleLabel"
						[disabled]="alertFormDisabled || saving || deleting"
					>
					</p-multiSelect>
				</form-input>
			</div>

			<!-- Send Web -->
			<div class="d-flex ps-3 align-items-center">
				<div class="p-2 align-items-center" [style]="{ 'margin-right': '50px' }">
					<form-input [required]="false" [align]="'cols-3'" fieldName="{{ 'alerts.sendWeb' | translate }}">
						<p-inputSwitch #web [(ngModel)]="sendWeb" (onChange)="toggleWeb($event)" class="p-1" [disabled]="alertFormDisabled || saving || deleting"> </p-inputSwitch>
					</form-input>
				</div>
				<!-- Send SMS -->
				<div class="p-2 align-items-center" [style]="{ 'margin-right': '50px' }">
					<form-input [required]="false" [align]="'cols-3'" fieldName="{{ 'alerts.sendSMS' | translate }}">
						<p-inputSwitch #sms [(ngModel)]="sendSMS" (onChange)="toggleSMS($event)" class="p-1" [disabled]="alertFormDisabled || saving || deleting"> </p-inputSwitch>
					</form-input>
				</div>
				<!-- Send Email -->
				<div class="p-2 align-items-center" [style]="{ 'margin-right': '50px' }">
					<form-input [required]="false" [align]="'cols-3'" fieldName="{{ 'alerts.sendEmail' | translate }}">
						<p-inputSwitch #email [(ngModel)]="sendEmail" (onChange)="toggleEmail($event)" class="p-1" [disabled]="alertFormDisabled || saving || deleting">
						</p-inputSwitch>
					</form-input>
				</div>
			</div>
		</div>
	</div>
	<p-footer>
		<div class="d-flex flex-row-reverse bd-highlight">
			<div class="p-2 bd-highlight">
				<!-- btn SAVE -->
				<button type="button" class="btn custom-button" (click)="saveChanges()" [disabled]="isEditingDisabled || saving || deleting">
					{{ saving ? ('alerts.saving' | translate) : dbNoticeGroup ? ('alerts.update' | translate) : ('alerts.create' | translate) }}
				</button>
			</div>
			<div class="p-2 bd-highlight">
				<!-- btn DELETE -->
				<button *ngIf="dbNoticeGroup" type="button" class="btn custom-button" (click)="deleteAlerts()" [disabled]="alertFormDisabled || saving || deleting">
					{{ deleting ? ('alerts.deleting' | translate) : ('alerts.delete' | translate) }}
				</button>
			</div>
		</div>
	</p-footer>
</p-panel>
