<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Software
                    Protocol WatchDog (AT + GTDOG)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Basic Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="modeOptions" [(ngModel)]="dogParameters.mode"
                        [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Reboot Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="dogParameters.rebootInterval" [min]="1"
                                    [max]="30"></p-spinner>
                                <span class="ui-inputgroup-addon">1~30</span>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Unit">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="unitOptions" [(ngModel)]="dogParameters.unit"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Reboot Time">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" maxlength="4" pInputText [(ngModel)]="dogParameters.rebootTime" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Ignition Frequency">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="dogParameters.ignitionFrequency" [min]="10"
                                    [max]="120"></p-spinner>
                                <span class="ui-inputgroup-addon">10~120</span>
                                <span class="ui-inputgroup-addon">min</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Input ID">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="dogParameters.digitalInputId" [min]="0"
                                    [max]="1"></p-spinner>
                                <span class="ui-inputgroup-addon">0~1</span>
                            </div>
                        </div>
                    </div>
                
                </div>
            </p-accordionTab>
        </div>


        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Other Settings</span>
                        </div>
                    </div>
                </p-header>
                
                <div class="row mt-3">
                    <p-checkbox binary="true" label="Report Before Reboot"
                        [(ngModel)]="dogParameters.reportBeforeReboot">
                    </p-checkbox>
                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>