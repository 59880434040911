import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { SubdivisionsFormComponent } from "../subdivisions-form/subdivisions-form.component";
import { SubdivisionService } from "app/rest/subdivision.service";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

@Component({
  selector: 'app-subdivisions-list',
  templateUrl: './subdivisions-list.component.html',
  styleUrls: ['./subdivisions-list.component.css'],
  providers: [ConfirmationService, UserTimePipe, ExcelService],
})
export class SubdivisionsListComponent
  extends EntityListComponent<Rest.Subdivision>
  implements OnInit {

  title: string = this.i18n._("Divisions");

  @ViewChild(SubdivisionsFormComponent, { static: true })
  editForm: SubdivisionsFormComponent;

  user: Rest.User;
  EnabledFilter: SelectItem[];

  constructor(
    private objectService: SubdivisionService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected translateService: TranslateService,
  ) {
    super(
      objectService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );

    this.objectService = objectService;
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.form = this.editForm;
    this.hasEntityFilters = false;
  }

 beforeLoad() {
    if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }  
  }
  afterLoad() {
    this.EnabledFilter = RestExt.getEnabledFilter(
      this.filters["enabled"],
      this.i18n
    );
    this.paginationResult.entities.forEach((ct: any) => {
      ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
      /*Permissions */
      ct._$changeStatus = this.hasPermission(
        this.permissionsEntityTypeSelector("changeStatus", "Division"),
        ct.id
      ); //This is for the validation of entity-list (selectAll function)
      ct._$update = this.hasPermission(
        this.permissionsEntityTypeSelector("update", "Division"),
        ct.id
      );
      /** */
    });
    this.checkSelectAll();
  }


  excelColumns = {};
  setExcelColumns() {
    const code = (entity: any) => entity.code ?? ''
    const name = (entity: any) => entity.name ?? ''
    const disabled = (entity: any) => entity.disabled ?? ''
    this.excelColumns = {
        [this.translateService.instant('general.code')]: { bindField: code },
        [this.translateService.instant('general.name')]:  { bindField: name },
        [this.translateService.instant('general.disabled')]: { bindField: disabled },
       }
  }
  exportExcel(){
      this.setExcelColumns();
      let fileNameTitle = this.translateService.instant('tableManagement.subdivisions.subdivisionsManagement');
      super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
  }
}
