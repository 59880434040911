import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
@Component({
  selector: 'app-function-key-settings-gl320m',
  templateUrl: './function-key-settings-gl320m.component.html',
  styleUrls: ['./function-key-settings-gl320m.component.css']
})
export class FunctionKeySettingsGl320mComponent extends ServerValidatedFormComponent implements OnInit {

  powerKeyOptions: any = [
    { label: "Pressing power key will not power off the device", value: "PRESSING_NOT_POWER" },
    { label: "Pressing power key will power off the device", value: "PRESSING_POWER" },
    { label: "Long pressing power key to activate the SOS mode", value: "LONG_PRESSING_POWER_ACTIVATE_SOS" },
  ];

 powerOptions: any = [
  { label: "Do not power on the terminal completely", value: "DISABLE" },
  { label: "Power on the terminal completely", value: "ENABLE" },
 ];

 functionKeyOptions: any = [
  { label: "Ignore the function key operation", value: "IGNORE" },
  { label: "Geo-fence mode", value: "GEO_FENCE" },
  { label: "Geo-fence around the current position", value: "GEO_FENCE_CURRENT_POSITION" },
  { label: "SOS mode", value: "SOS" },
  { label: "Location mode", value: "LOCATION" },
  { label: "mixed mode", value: "MIXED" },
 ]

 powerKeyIndicationOptions: any = [
  { label: "Disable the motor when the power key is long pressed", value: "DISABLE" },
  { label: "Enable the motor to vibrate when the power key is long pressed", value: "ENABLE" },
 ]

 functionKeyIndicationOptions: any = [
  { label: "Disable the motor when the function key is long pressed", value: "DISABLE" },
  { label: "Enable the motor to vibrate when the function key is long pressed", value: "ENABLE" },
 ]

 sosOptions: any = [
  { label: "Report only the last GPS location immediately after SOS event is triggered", value: "REPORT_LAST_GPS" },
  { label: "Try to report the current GPS location after SOS event is triggered", value: "REPORT_CURRENT_GPS" },
  { label: "Report the last GPS location immediately after SOS event is triggered and then tries to get the current GPS location to report", value: "REPORT_LAST_GPS_THEN_REPORT_CURRENT_GPS" },
 ]

 triggerOptions: any = [
  { label: "Ignore the function key operation", value: "IGNORE" },
  { label: "Geo-fence mode", value: "GEO_FENCE" },
  { label: "Geo-fence around the current position", value: "GEO_FENCE_CURRENT_POSITION" },
  { label: "Send a SOS message to back server", value: "SEND_SOS" },
  { label: "Send a +RESP: GTLOC to back server", value: "SEND_GTLOC" },
 ]


  @Input() fksParameters: Rest.FunctionKeySettingsGL320M;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.fksParameters);
    }
  }

}