<div class='flex-column justify-content-center align-items-center'>

	<div class='flex-column justify-content-center pb-5 mx-5 me-5'>

<div class='tab-pane fade show active'>

		<!-- SEARCH CONFIGURATION PANEL -->
		<div class='container-fluid justify-content-evenly p-3 mt-4 mb-4 tax-search-panel-container'>
				<div class="row flex-wrap align-items-end mt-1">
					<!-- client ID -->
				<div class='col-md mt-3 mt-md-0 '>
					<div class="d-flex align-items-center">
						<label id='entityLabel' class='form-label mb-0 me-3 custom-label tax-label'>
							{{ 'control-panel.clients.billingAccount' | translate }}:
						</label>
								<paged-search-dropdown
								[pageFunction]="billingAccountService.getPage.bind(billingAccountService)"
								[(selectedEntity)]="selectedEntity" [paginationRequest]="entityRequestContext"
								>
								</paged-search-dropdown>

					</div>


				</div>
	
				<!-- START DATE -->
<div class='col-md mt-3 mt-md-0'>
	<div class="d-flex align-items-center">
		<label id='startDateLabel' class='form-label mb-0 me-3 custom-label tax-label'>
			{{ 'general.startDate' | translate }}:
		</label>
		<p-calendar 
			#startDateCalendar
			(ngModelChange)="onChangeStartDate(startDateCalendar.value)"
			id='startDateCalendar'
			view="month"
			dateFormat='mm/yy'
			firstDayOfWeek=1
			class='custom-datepicker-with-button'
			[(ngModel)]="startDate"
			[showIcon]='true'
			[showOnFocus]='false'
		></p-calendar>
	</div>
</div>

<!-- END DATE -->
<div class='col-md mt-3 mt-md-0'>
	<div class="d-flex align-items-center">
		<label id='endDateLabel' class='form-label mb-0 me-3 custom-label tax-label'>
			{{ 'general.endDate' | translate }}:
		</label>
		<p-calendar 
			id='endDateCalendar'
			view="month"
			dateFormat='mm/yy'
			class='custom-datepicker-with-button'
			[minDate]=startDate
			firstDayOfWeek=1
			[disabled]='startDate === null'
			[(ngModel)]='endDate'
			[showIcon]='true'
			[showOnFocus]='false'
		></p-calendar>
	</div>
</div>
				<!-- SEARCH CONTAINER: SEARCH BUTTON && SETTINGS BUTTON && DETACH BUTTON -->
				<div class='col-md mt-3 mt-md-0'>
	
					<!-- SEARCH BUTTON -->
						<button class='btn custom-button tax-field' (click)='search()'>
							{{ 'general.search' | translate }}
						</button>	
				</div>
				</div>
				


	
		</div>	
</div>


<!-- REPORT FILES PRIMENG DATATABLE -->
<p-table 
		 id="table"
		 [value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate="
         {{ 'table.showing' | translate }} {first}
         {{ 'table.to' | translate }} {last}
         {{ 'table.of' | translate }} {totalRecords}
         {{ 'table.entries' | translate}}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

	<!-- TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tax.caption' | translate }}
		</div>
	</ng-template>

	<!-- TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>
			<!-- CHECKBOX COLUMN -->
			<th class="checkbox-column">
					<div class="d-flex justify-content-center">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</div>
			</th>
			<!-- REPORT DATE -->
			<th pSortableColumn="reportDate">
				<div class='d-flex align-items-center h-100'>
					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tax.table.columns.reportDate' | translate }}
                    </div>
                    
                    <!-- COLUMN SORT -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="reportDate"></p-sortIcon>
                    </div>
				</div>
			</th>

            <!-- DOWNLOAD -->
			<th class='button-column'>

            </th>
			<!-- PREVIEW -->
			<th class='button-column'>

			</th>
		</tr>
	</ng-template>

	<!-- TABLE BODY -->
	<ng-template pTemplate="body" let-report>
		<tr class="p-selectable-row custom-rows">
			<!-- SELECT -->
			<td>
					<div class="d-flex justify-content-center">
						<p-tableCheckbox [value]="report"></p-tableCheckbox>
					</div>
			</td>
			<!-- REPORT DATE -->
			<td>
				<div class="d-flex w-100 justify-content-center">
					{{report.reportDate | date:'yyyy/MM'}}
				</div>
				
			</td>
            <td>
				<button type='button' class='btn custom-table-download-button' (click)='downloadPdf(report)'>
					<svg-icon src='assets/icons/download-button.svg'
						class='d-flex flex-wrap custom-table-download-button-icon'
						[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
					></svg-icon>
				</button>
			</td>
			<td>
				<button type='button' class='btn custom-table-download-button' (click)='showPDF(report)'>
					<svg-icon src='assets/icons/preview.svg'
						class='d-flex flex-wrap custom-table-download-button-icon'
						[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
					></svg-icon>
				</button>
			</td>		
		</tr>
	</ng-template>

	<!-- TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="4" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="footer">
			<tr>
				<td colspan="4" class="p-0">
					<div class="d-flex flex-row align-items-center p-3 gap-3">	
						<!-- DOWNLOAD BUTTON -->
						<app-table-download-button (btnClick)="download()"
							[isDisabled]="selectedEntities.length === 0"
						></app-table-download-button>	
					</div>
				</td>
			</tr>
	</ng-template>
	
</p-table>

</div>
</div>