<p-dialog header={{title}} [(visible)]="display" (onHide)="onHide($event)" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="true"
  [contentStyle]="{'overflow':'visible','min-height':'225px'}">

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <!-- EDIT POI CATEGORY CONTAINER -->
    <div class="container">

      <!-- ROW 1 -->
      <div class="row align-items-center mt-4">

        <form-input [required]="true" i18n-fieldName fieldName={{nameLabel}} [errors]="errors.name">
          <input pInputText id="name" [(ngModel)]="entity.name" [style]="{'max-width':'130px'}"/>
        </form-input>

      </div>

      <!-- ROW 2 -->
      <div class="row align-items-center mt-4">
        <form-input [required]="true" i18n-fieldName fieldName={{iconLabel}} [errors]="errors.icon">
          <div class="d-flex justify-content-start align-items-center">

            <p-dropdown id="dropdownIcons" [options]="availableIcons" [(ngModel)]="selectedIcon"
              [style]="{'max-width':'110px'}" filter="filter">
              <ng-template let-icon pTemplate="item">
                <div class='d-flex flex-row w-100 h-100 gap-3 ps-3'>
                  <img src='{{icon.value.imageEncoded}}' class="poi-icon" />
                  <div class='d-flex poi-icon-name'>{{icon.label}}</div>
                </div>
              </ng-template>
            </p-dropdown>

            <button [style]="{'width':'40px'}" style="margin-left: 5%;" type="button" pButton icon="fa fa-plus"
              (click)="addIcons()" i18n-pTooltip pTooltip="Add New Icons" tooltipPosition="top"></button>
          
            </div>
        </form-input>
      </div>

      <!-- ROW 3 -->
      <div class="row align-items-center mt-4">
        <form-input i18n-fieldName fieldName={{enabledLabel}} [errors]="errors.enabled">
          <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
        </form-input>
      </div>

    </div>

    <!-- <div *ngIf="showMap">
      <app-custom-map mapId="map-categories"
                      [display]="display"
                      [options]="customMapOptions"
                      [pois]="pois" [iconPOI]="icon"
                      (eventUpdatePOI)="notifyUpdatePOI($event)"
      ></app-custom-map>
    </div> -->

  </div>

  <p-footer>

    <div class="d-flex justify-content-end align-items-center">

      <div *ngIf="disableSave === false">
        <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label={{saveLabel}}></button>
      </div>

      <div *ngIf="disableSave === true">
        <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
          <button disabled="true" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
            label={{saveLabel}}></button>
        </div>
      </div>

      <div *ngIf="isNew === false">

        <div *ngIf="disabeChangeStatus === false">
          <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
            label={{changeStatusLabel}}></button>
        </div>

        <div *ngIf="disabeChangeStatus === true">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status"
            tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)"
              i18n-label label={{changeStatusLabel}}></button>
          </div>
        </div>

      </div>
    </div>

  </p-footer>

  <app-add-icons-form [display]=displayAddIcons [entity]="entityCategory"
    (closeAddIcon)="cancelAddIcons()"></app-add-icons-form>

</p-dialog>