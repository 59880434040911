import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';
import { TranslationsService } from '../../service/translations.service';
import { TargetsService } from '../../service/targets.service';
import { CampaignService } from '../../service/campaign.service';

@Component({
  selector: 'app-target-form',
  templateUrl: './target-form.component.html',
  styleUrls: ['./target-form.component.css']
})
export class TargetFormComponent extends EntityFormComponent<Rest.FidTargets> implements OnInit, OnDestroy {

	isDialogVisible: boolean;
	target: Rest.FidTargets | null;
	errorMessages: string[];
	editMode = false;
	spaceFormTitle: string;
	isTextArea: boolean;
	translations: Rest.Translations;

	@Output() targetSaved = new EventEmitter<Rest.FidTargets>();
	@Input() campaign: Rest.FidCampaigns;
	isNew: boolean;
	subscription: Subscription;
	targetIndex: number;
	filterIndex: number;
	filtersService: any;
	selectedFilterParam: string;
	selectedFilterType: string;
	selectedFilterValue: string;

	constructor(
		public targetsService: TargetsService,
	 	private authService: AuthenticationService,
	 	public i18n: I18nService,
	 	public notificationsService: NotificationsService,
	 	private translate: TranslateService,
	 	private campaignService: CampaignService
	) {
		super(targetsService, notificationsService, i18n, authService);
		this.editMode = false;
		this.errorMessages = [];
	}

	ngOnInit(): void {
		// console.log("[TARGET-FORM] ***** on-init *****");
		super.ngOnInit();
		this.subscription = this.targetsService.startEditTarget
			.subscribe(
				(item: {isNew: boolean, target: Rest.FidTargets}) => {
					this.target = item.target;
					this.isNew = item.isNew;
					this.showDialog();
			 	}
		 	);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	* showDialog()
	* */
	public showDialog(): void {
		// console.log("[TARGET-FORM] ***** showDialog ***** ");
		// console.log("isNew: " + this.isNew);
		// console.log(this.target);
		window.scroll({ top: 0, behavior: 'smooth' });
		this.isDialogVisible = true;
   }

	public onSaveTarget() {
		// console.log("[TARGET-FORM] onSaveTarget ***");
		// console.log("isNew: " + this.isNew);
		let data = {
			id: this.isNew ? 0 : this.target.id,
			campaign: { id: this.campaign.id },
			name: this.target.name,
			description: this.target.description,
			createdByUser: this.authService.user,
			creationDate: new Date(),
			filters: []
		}

		if (this.isNew) {
			this.targetsService.createTarget(data).then((target) => {
				if (target) {
					/* actualizamos la variable local */
					this.campaign.targets.push(target);
					/* actualizamos la lista de spaces */
					this.campaignService.campaignSaved.next();
					/* toast */
					// this.notificationsService.add(
					// 	Severity.success,
					// 	this.translate.instant('general.done') + "!",
					// 	this.translate.instant('general.successMsg'));
					this.isDialogVisible = false;
				}
			});
		} else {
			this.targetsService.updateTarget(data).then((target) => {
				if (target) {
					/* actualizamos la variable local */
					this.targetIndex = this.campaign.targets.indexOf(this.target);
					// console.log("targetIndex: " + this.targetIndex);
					this.campaign.targets[this.targetIndex] = target;
					/* actualizamos la lista de spaces */
					this.campaignService.campaignSaved.next();
					/* toast */
					// this.notificationsService.add(
					// 	Severity.success,
					// 	this.translate.instant('general.done') + "!",
					// 	this.translate.instant('general.successMsg'));
					this.isDialogVisible = false;
				}
			});
		}
	 }
	selectedTarget(selectedTarget: any): any {
		throw new Error('Method not implemented.');
	}
	selectedFilter(selectedFilter: any): any {
		throw new Error('Method not implemented.');
	}


 }
