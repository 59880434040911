<div *ngIf="!note" class="p-2 align-items-center">
	<mat-spinner
		[diameter]="30"
		strokeWidth="5"
		[color]="primary">
	</mat-spinner>
</div>

<div *ngIf="note">
	<div class="p-2 align-items-center">
		<form-input [required]='true' [align]="'cols-2'" fieldName="{{ 'general.description' | translate }}">
			<!-- <input pInputText [(ngModel)]="driverPoints.points" [disabled]='isEditingDisabled' [style]="{'width':'180px', 'margin-right':'20px'}"/> -->
			<textarea pInputTextarea id="description"
				[(ngModel)]="note.description" [rows]="3"
				[style]="{'width':'350px'}" [disabled]='isEditingDisabled'>
			</textarea>
		</form-input>
	</div>

	<div  class="p-2 align-items-center">
		<form-input [required]='true' [align]="'cols-2'" fieldName="{{ 'documents.note.noteDate' | translate }}">
			<p-calendar [(ngModel)]='note.noteDate'
				[disabled]='isEditingDisabled'
				[readonlyInput]='true'
				[showTime]='false'
				[style]="{'width':'200px'}"
				dateFormat='{{i18n.dateFormat}}'
				showIcon='true'
				appendTo='body'
			></p-calendar>
		</form-input>
	</div>

</div>
