<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.fine.finesManagement' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>
            <!-- SELECT -->
            <th class="checkbox-column">
                <div class="d-flex justify-content-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
            </th>

            <!-- VEHICLE -->
            <th pSortableColumn="observations">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.vehicle.vehicle' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="vehicle.name"></p-sortIcon>
                        <p-columnFilter field="vehicle" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- DRIVER -->
            <th pSortableColumn="observations">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'drivers.header' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter field="driver" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false">
                        </p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- AMOUNT -->
            <th pSortableColumn="observations">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.amount' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="amount"></p-sortIcon>
                        <p-columnFilter field="amount" type="numeric" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false">
                        </p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- FILE NUMBER -->
            <th pSortableColumn="fileNumber">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.fileNumber' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="fileNumber"></p-sortIcon>
                        <p-columnFilter field="fileNumber" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false">
                        </p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- REASON -->
            <th pSortableColumn="reason.reason">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.reason' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="reason.reason"></p-sortIcon>
                        <p-columnFilter field="reason" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- ISSUER -->
            <th pSortableColumn="issuer">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.issuer' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="issuer"></p-sortIcon>
                        <p-columnFilter field="issuer" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- SANCTION DATE -->
            <th pSortableColumn="sanctionDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.sanctionDate' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="sanctionDate"></p-sortIcon>
                        <p-columnFilter field="sanctionDate" type="date" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- STATUS -->
            <th pSortableColumn="status">
                <div class="d-flex align-items-center h-100">
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.fine.status' | translate }}
                    </div>
                    <div class="d-flex align-items-center h-100">
                        <div class="d-flex align-items-center h-100">
                            <p-sortIcon field="finesStatus"></p-sortIcon>
                            <p-columnFilter field="fineStatus" matchMode="in" display="menu" [showMatchModes]="false"
                                [showOperator]="false" [showAddButton]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [ngModel]="value" [options]="fineStatusList"
                                        placeholder="{{ 'general.filters.any' | translate }}"
                                        (onChange)="filter($event.value)" optionLabel="name" optionValue="name">
                                        <ng-template let-option pTemplate="item">
                                            <div class='d-flex row-cols-2 w-100 align-items-center'>
                                                <div class="status-marcha" [style]="{'--color': option.color }"></div>
                                                <div>{{ option.name }}</div>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </div>
                </div>
            </th>

            <!-- ACTIVE -->
            <th *ngIf="!nested" pSortableColumn='disabled'>
                <div class='d-flex align-items-center h-100'>
                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'general.active' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='disabled'></p-sortIcon>
                        <p-columnFilter field='disabled' type='boolean' display='menu'
                            [showApplyButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-fine>
        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(fine)">
            <!-- SELECT -->
            <td>
                <div class="d-flex justify-content-center">
                    <p-tableCheckbox [value]="fine"></p-tableCheckbox>
                </div>
            </td>

            <!-- VEHICLE -->
            <td>
                {{ fine.vehicle !== null ? fine.vehicle?.name : "N/A" }}
            </td>

            <!-- DRIVER -->
            <td>
                ({{ fine.driver !== null ? fine.driver?.code : "N/A" }}) {{ fine.driver !== null ?
                fine.driver?.user?.surname1 : "N/A" }} {{ fine.driver !== null ? fine.driver?.user?.surname2 : "" }}, {{
                fine.driver !== null ? fine.driver?.user?.name : "" }}
            </td>

            <!-- AMOUNT -->
            <td>
                {{ fine.amount }} €
            </td>

            <!-- FILE NUMBER -->
            <td>
                {{ fine.fileNumber }}
            </td>

            <!-- REASON -->
            <td>
                {{ fine.reason?.reason }}
            </td>

            <!-- ISSUER -->
            <td>
                {{ fine.issuer }}
            </td>

            <!-- SANCTION DATE -->
            <td>
                {{ fine.sanctionDate | date:'dd/MM/yy HH:mm:ss' }}
            </td>

            <!-- STATUS COLOR -->
            <!-- <td [ngStyle]="{ 'background-color': fine.finesStatus.color }"></td> -->
            <td>
                <div class='d-flex row-cols-2 w-100 align-items-center'>
                    <div class="status-marcha" [style]="{'--color': fine.finesStatus.color }"></div>
                    <div>{{ fine.finesStatus.name }}</div>
                </div>
            </td>

            <!-- ACTIVE -->
            <td *ngIf="!nested">
                <div class="d-flex justify-content-center align-items-center">
                    <i class="pi"
                        [ngClass]="{'true-icon pi-check-circle': !fine.disabled, 'false-icon pi-times-circle': fine.disabled}"></i>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="10" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td [attr.colspan]="colspan" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">
                    <!-- ADD FINE BUTTON -->
                    <app-table-add-button *ngIf="isCreateGranted" [text]=" 'tableManagement.fine.fines' | translate"
                        (btnClick)="create()">
                    </app-table-add-button>
                    <!-- CHANGE STATUS BUTTON -->
                    <app-table-change-status-button (btnClick)="changeStatus()"
                        [isDisabled]="selectedEntities.length === 0 || !isDisabledGranted">
                    </app-table-change-status-button>

                    <!-- EXCEL BUTTON -->
                    <app-table-excel-button class="buttonTableExcel"
                        (btnClick)="exportExcel()"></app-table-excel-button>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">

    <app-change-status-shared [items]=" 'tableManagement.fine.fines' | translate " [display]="true"
        [service]=fineService [entitiesList]=selectedEntities (return)="refreshList($event)"
        (close)="closeStatusDialog($event)"></app-change-status-shared>

</div>

<!-- FINE FORM -->
<app-fine-form [vehicle]="vehicle" [startDate]="startDate" [endDate]="endDate"
    (return)="refreshList($event,false)"></app-fine-form>
<!--<app-fine-form></app-fine-form>-->