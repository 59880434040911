import { Component, Input, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { MaintenanceexecutionService } from "../../../rest/maintenanceexecution.service";
import { Rest } from "../../../rest/rest_client";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { UserTimePipe } from "../../shared/UserTimePipe";
import { ReportService } from "../../../rest/report.service";
import { CustomMapComponent } from "../../shared/custom-map/custom-map.component";

declare var jquery: any;
declare var $: any;

@Component({
    selector: "app-vehicle-activity-report",
    templateUrl: "./vehicle-activity-report.component.html",
    styleUrls: ["./vehicle-activity-report.component.css"],
    providers: [ReportService],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VehicleActivityReport {
    _display: boolean = false;
    report: Rest.VehicleActivityReport;
    mapMode: MapMode = "SINGLE";
    @ViewChild(CustomMapComponent) singleMap: CustomMapComponent;
    pois: any[][] = [];
    mapModes = [
        {label: "NONE", value: "NONE"},
        {label: "SINGLE", value: "SINGLE"},
        {label: "PER_EVENT", value: "PER_EVENT"},
    ];

    customMapOptions = {
        controls: [],
        contextMenuOptions: [],
        rightClickMenu: false,
    };
    // TODO: Field "icon" was missing from legacy code!!!
    icon: any;

    constructor(
        private reportService: ReportService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private userTime: UserTimePipe,
        authenticationService: AuthenticationService
    ) {
    }

    display(report: Rest.VehicleActivityReport) {
        this._display = true;
        this.report = report;
        let evtId = 0;
        //Assign an id to each event
        this.report.events.forEach((e: any) => {
            e.$id = evtId++;
            this.pois[e.$id] = [
                {position: {latitude: e.location[0], longitude: e.location[1]}},
            ];
        });

        this.initMap();
    }

    eventClickedInList(event: Rest.VehicleActivityEvent) {
        if (this.mapMode != "SINGLE") return;
        this.singleMap.centerMapAtPoint(event.location[0], event.location[1], 20);
        $("#single-map").attr("tabindex", -1).focus();
    }

    initMap() {
        if (this.mapMode != "SINGLE") return;
        ///Must delay the initialization....
        setTimeout(() => {
            this.singleMap.initMap();
            var route: Rest.Vertex[][] = [];
            route[0] = this.report.locations.map((l: number[]) => {
                return <Rest.Vertex>{latitude: l[0], longitude: l[1]};
            });
            this.singleMap.addVehicleRoute(1, route, false, null);
            this.singleMap.centerMap(null);

            this.report.events.forEach((e) => {
                this.singleMap.drawEvent(e.location[0], e.location[1], function () {
                    $("#event-" + (e as any).$id)
                        .attr("tabindex", (e as any).$id)
                        .focus();
                });
            });
        }, 1000);
    }
}

type MapMode = "NONE" | "SINGLE" | "PER_EVENT";
