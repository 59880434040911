import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {
    @Input() displayDialog: EventEmitter<boolean>;
    @Input() message: string;
    @Input() icon: string;
    @Input() position: string;

    @Output() onAccept: EventEmitter<any>;
    @Output() onReject: EventEmitter<any>;

    constructor(
        private confirmationService: ConfirmationService
    ) {
        this.displayDialog = new EventEmitter<boolean>();
        this.message = '';
        this.icon = '';
        this.position = 'bottom-right';

        this.onAccept = new EventEmitter<any>();
        this.onReject = new EventEmitter<any>();
    }

    ngOnInit(): void {

			console.log("[CONFIRM-DIALOG] ngOnInit ***");
        /* ICON INIT */
        switch (this.icon) {

            case 'exclamation-triangle':
                this.icon = 'pi pi-exclamation-triangle';
                break;

            default:
                this.icon = '';
                break;

        }

        this.displayDialog.subscribe((display) => {
				console.log("[CONFIRM-DIALOG] display: "  + display);
            if (display) {
                this.confirmationService.confirm({
                    accept: () => {
								console.log("[CONFIRM-DIALOG] accept ");
                        this.onAccept.emit()
                    },
                    reject: () => {
								console.log("[CONFIRM-DIALOG] reject ");
                        this.onReject.emit();
                    }
                });
            }
        });
    }

}
