import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusOnNextEnter]'
})
export class FocusOnNextEnterDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown.enter', ['$event']) onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent default Enter key action (e.g., form submission)
    const formElements = this.el.nativeElement.form.elements;
    const currentElementIndex = Array.from(formElements).indexOf(this.el.nativeElement);

    if (currentElementIndex >= 0) {
      // Attempt to focus the next form element
      const nextElement = formElements[currentElementIndex + 1];
      if (nextElement) {
        nextElement.focus();
      } else {
        // Optionally, handle the case where there's no next element (e.g., submit the form)
      }
    }
  }

}
