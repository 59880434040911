import { Component, Input, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { MaintenanceexecutionService } from "../../../rest/maintenanceexecution.service";
import { Rest } from "../../../rest/rest_client";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { UserTimePipe } from "../../shared/UserTimePipe";
import { ReportService } from "../../../rest/report.service";
import { SelectItem } from "primeng/api";
import { FleetTreeComponent } from "../../fleet-edit-view/fleet-tree/fleet-tree.component";

@Component({
    selector: "app-report-form",
    templateUrl: "./report-form.component.html",
    styleUrls: ["./report-form.component.css"],
    providers: [ReportService],
})
export class ReportFormComponent extends EntityFormComponent<Rest.Report> {
    periodStart: Date;
    fleetsGroupsAndVehiclesSelected: object[] = [];
    periodEnd: Date;
    @ViewChild(FleetTreeComponent) fleetTree: FleetTreeComponent;

    availableTypes: SelectItem[];

    constructor(
        private reportService: ReportService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private userTime: UserTimePipe,
        authenticationService: AuthenticationService
    ) {
        super(reportService, notificationsService, i18n, authenticationService);
        this.availableTypes = [
            {
                label: this.i18n._("VEHICLE_ACTIVITY_REPORT"),
                value: "VEHICLE_ACTIVITY",
            },
        ];
    }

    beforeShow() {
        this.fleetsGroupsAndVehiclesSelected = [];
    }

    beforeSave() {
        this.entity.periodStart = this.userTime.calendarDateToUTC(this.periodStart);
        this.entity.periodEnd = this.userTime.calendarDateToUTC(this.periodEnd);

        this.entity.vehiclesIds = [];
        this.entity.fleetsIds = [];
        this.entity.vehiclesGroupsIds = [];
        this.fleetsGroupsAndVehiclesSelected.forEach((o: object) => {
            if (FleetTreeComponent.isFleet(o)) {
                this.entity.fleetsIds.push((o as Rest.Fleet).id);
            }
            if (FleetTreeComponent.isVehicle(o)) {
                this.entity.vehiclesIds.push((o as Rest.Vehicle).id);
            }
            if (FleetTreeComponent.isVehicleGroup(o)) {
                this.entity.vehiclesGroupsIds.push((o as Rest.VehiclesGroup).id);
            }
        });
    }

    ngOnInit() {
        //Initialize entity object to avoid html render errors
        this.entity = {} as Rest.Report;
    }
}
