import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-authentication-id-configuration-gv300can",
  templateUrl: "./authentication-id-configuration-gv300can.component.html",
  styleUrls: ["./authentication-id-configuration-gv300can.component.css"],
})
export class AuthenticationIdConfigurationGv300canComponent
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() idaParameters: Rest.AuthenticationIdConfigurationGV300CAN;

  modeOptions: any = [
    { label: "Disable", value: 0 },
    { label: "Only authorized ID cards can unlock the vehicle", value: 1 },
    { label: "any ID card can unlock the vehicle", value: 2 },
  ];

  modeReportOptions: any = [
    { label: "Do not report ID", value: 0 },
    { label: "report the ID which is authorized", value: 1 },
    { label: "report the ID which is unauthorized", value: 2 },
    { label: "report authorized or unauthorized", value: 3 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }
  trackModeSwitch = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.idaParameters);
    }
  }

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value === 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {
    // TODO: Empty method!
  }
}
