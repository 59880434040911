import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { BillingaccountService } from "../../../rest/billingaccount.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { BillingAccountFormComponent } from "../billing-account-form/billing-account-form.component";
import { Subscription } from "rxjs";

@Component({
    selector: "app-billing-accounts-list",
    templateUrl: "./billing-accounts-list.component.html",
    styleUrls: ["./billing-accounts-list.component.css"],
    providers: [BillingaccountService, ConfirmationService, UserTimePipe],
})
export class BillingAccountsListComponent
    extends EntityListComponent<Rest.BillingAccount>
    implements OnInit {
    title: string = this.i18n._("Billing Accounts");

    @ViewChild(BillingAccountFormComponent, { static: true })
    billingAccountForm: BillingAccountFormComponent;
    @Input() parentClient: Rest.Client;

    disableSave: boolean;
    user: Rest.User;

    /* Permissions */
    hasPermissions: boolean;
    /** */

    displayDialog: boolean;
    billingAccounts: Rest.BillingAccount[];
    containsNestedEntities = [];
    EnabledFilter: SelectItem[];

	 editingSubscription: Subscription;

    constructor(
        private billingaccountService: BillingaccountService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            billingaccountService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.billingaccountService = billingaccountService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.hasEntityFilters = true;
        this.form = this.billingAccountForm;

        /*Permissions */
        this.user = this.authenticationService.user;
        /** */
        this.hasPermissions = this.parentClient["_$update"];

		  this.editingSubscription = this.billingaccountService.startedEditing
		  .subscribe(
			 (item: Rest.BillingAccount) => {
			  console.log(item);
			  super.editDialog(item);
			  }
		  );
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );
        this.paginationResult.entities.forEach((cl: any) => {
            /*Permissions */
            cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
            cl._$update = true;
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    protected beforeLoad() {
        this.paginationRequest.filters["clientID"] = [
            this.parentClient.id.toString(),
        ];
    }

    createBillingAccountDialog() {
        this.create({ client: this.parentClient } as Rest.BillingAccount);
    }

    close() {
        let elementsdeleted = [];
        this.displayDialog = false;
        this.selectedEntities = [];
        this.billingAccounts = [];
        this.containsNestedEntities = [];

        if (this.containsNestedEntities !== null) {
            elementsdeleted = this.containsNestedEntities.splice(
                0,
                this.containsNestedEntities.length
            );
        }
    }
}
