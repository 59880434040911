import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class ReportService extends EntityService<Rest.Report>{
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Report): Rest.RestResponse<Rest.Report> {
    return this.client.Report_create(arg0);
  }

  getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Report>> {
    return this.client.Report_getPage(arg0);
  }

  getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Report>> {
    return this.client.Report_getPageAndFilters(arg0);
  }

  getReportPart(rid: string, vid: string, queryParams?: { rid?: number; vid?: number; }): Rest.RestResponse<string> {
    return this.client.Report_getReportPart(rid, vid, queryParams);
  }

  getHospitalMataro(queryParams?: { startDate?: String; endDate?: String; }): Rest.RestResponse<Rest.Page<Rest.ReportTableHospitalMataro>> {
    return this.client.Report_getHospitalMataro(queryParams);
  }

}
