import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { VehicleService } from "app/rest/vehicle.service";
import { RealTimeDataService } from "app/services/rt-data/rt-data.service";
import { ConfirmationService } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { RuleService } from "../../../rest/rule.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService, Severity, } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { FleetTreeComponent } from "../../fleet-edit-view/fleet-tree/fleet-tree.component";

@Component({
    selector: "app-rule-form",
    templateUrl: "./rule-form.component.html",
    styleUrls: ["./rule-form.component.css"],
    providers: [RuleService, ConfirmationService, VehicleService, HttpClient],
})
export class RuleFormComponent
    extends EntityFormComponent<Rest.Rule>
    implements OnInit, OnDestroy {
    private ruleFormTitle: string;
    private codeInputSettings = { lineNumbers: true, mode: "text/x-go" };
    private fleetsGroupsAndVehiclesSelected: object[];
    private rtSource: boolean;
    private handcraftedVDM = "[]";
    private handcraftedEnteringData = "{}";
    private handcraftedEnteringDataTypeIsEvent = true;
    private handcraftedEvents = "[]";
    disableSave: boolean;
    disabeChangeStatus: boolean;
    user: Rest.User;
    playing = false;
    testVehicleId = 386;
    evalIntervalFreq = 10000;
    metadata: {};

    evalInterval = null;
    //Cache of events
    events: Rest.Event[] = [];

    //Cache of vehicle data messages
    vdm: Rest.VehicleDataMessage[] = [];
    //Executable code constructed with user input
    executableCode: any;
    //Keeps track of each execution of the rule
    evalResultHistory: EvalResult[] = [];

    constructor(
        private ruleService: RuleService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        private rtDataService: RealTimeDataService,
        private vehicleService: VehicleService,
        private http: HttpClient
    ) {
        super(ruleService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.fleetsGroupsAndVehiclesSelected = [];
        /*Permissions */
        this.user = this.authenticationService.user;
    }

    ngOnDestroy() {
        this.alive = false;
    }

    updateEvents(e: Rest.Event) {
        this.events = this.events.sort((e1, e2) => e1.deviceTime - e2.deviceTime);
        this.fireRuleEval(e, "EVENT");
        this.events.push(e);
    }

    updateVehicleDataMessage(m: Rest.VehicleDataMessage) {
        this.vdm = this.vdm.sort((m1, m2) => m1.deviceTime - m2.deviceTime);
        this.fireRuleEval(m, "POSITION");
        this.vdm.push(m);
    }

    async compileCode() {
        //Construct the rule code: append the user code to the provided utils
        let code = await this.http
            .get("./assets/scripting/jsSerializationUtils.js", {
                responseType: "text",
            })
            .toPromise();
        code = code.replace("{{{ALGORITHM_BODY}}}", this.entity.code);
        //Wrap the code inside a function
        code =
            "{" +
            code +
            "\nreturn detect(events,positions,metadata,incommingMessagePayload,incommingMessageType,currentTime);}";
        try {
            this.executableCode = new Function(
                "events",
                "positions",
                "metadata",
                "incommingMessagePayload",
                "incommingMessageType",
                "currentTime",
                code
            );
        } catch (ex) {
            this.notificationsService.add(
                Severity.error,
                "Rule code invalid",
                ex.toString()
            );
            return;
        }
    }

    async play() {
        await this.compileCode();
        //Clear past eval history
        this.evalResultHistory = [];
        //Retrieve the vehicle
        let vehicle;
        try {
            vehicle = await this.vehicleService.findVehicle(this.testVehicleId + "");
        } catch (ex) {
            this.notificationsService.add(
                Severity.error,
                "Unknown vehicle",
                "Unknown vehicle"
            );
            return;
        }
        //Watch for incoming data from vehicle
        this.rtDataService.watchedVehicles.next([
            vehicle as RestExt.WatchedVehicle,
        ]);
        //Create a timer that will evaluate the rule at a fixed freq.
        if (this.evalInterval != null) {
            window.clearInterval(this.evalInterval);
        }

        this.playing = true;
    }

    fireRuleEval(incommingMessagePayload, incommingMessageType) {
        const evsString = JSON.stringify(this.events);
        const vdmString = JSON.stringify(this.vdm);
        const metadataString = JSON.stringify(this.metadata);

        const time = new Date().getTime();
        let res: any;
        const errors = [];
        let log = [];
        let ruleFired = false;
        let generatedEvents = [];
        try {
            res = this.executableCode(
                evsString,
                vdmString,
                metadataString,
                incommingMessagePayload,
                incommingMessageType,
                time
            );
            if (res != null && Array.isArray(res) && res.length == 3) {
                log = (res as any).pop(2);
            }
            ruleFired = res[0];
            if (res[1] && res[1].trim().length > 0) {
                generatedEvents = JSON.parse(res[1]);
            }
        } catch (ex) {
            errors.push(ex.stack);
        }
        this.evalResultHistory.push({
            ruleFired,
            events: JSON.parse(evsString).map((e) => JSON.stringify(e)),
            vdm: JSON.parse(vdmString).map((e) => JSON.stringify(e)),
            generatedEvents,
            errors,
            log,
        });
    }

    clearStatus() {
        this.events = [];
        this.vdm = [];
    }

    async test() {
        this.evalResultHistory = [];
        await this.compileCode();
        this.events = JSON.parse(this.handcraftedEvents);
        this.vdm = JSON.parse(this.handcraftedVDM);
        this.fireRuleEval(
            JSON.parse(this.handcraftedEnteringData),
            this.handcraftedEnteringDataTypeIsEvent ? "EVENT" : "DATA"
        );
    }

    async pause() {
        this.rtDataService.watchedVehicles.next([]);
        this.playing = false;
        if (this.evalInterval != null) {
            window.clearInterval(this.evalInterval);
        }
    }

    beforeShow() {
        /*Permissions */
        this.disableSave = !this.user.canManageRules;
        this.disabeChangeStatus = !this.user.canManageRules;

        this.playing = false;
        this.executableCode = null;
        this.events = [];
        this.vdm = [];
        this.evalResultHistory = [];

        /** */
        this.rtDataService.event
            .takeWhile(() => this.display)
            .subscribe((e) => this.updateEvents(e));
        this.rtDataService.vehicleInfo
            .takeWhile(() => this.display)
            .subscribe((v) => this.updateVehicleDataMessage(v));

        /** */
        this.fleetsGroupsAndVehiclesSelected = [];

        if (this.isNew) {
            this.ruleFormTitle = this.i18n._("Create Rule");
        } else {
            this.ruleFormTitle = this.i18n._("Edit Rule");
        }

        this.entity.code = "Add your python code here";

        if (!this.isNew) {
            this.ruleService.find(this.entity.id + "").then((resp: Rest.Rule) => {
                this.entity = resp;

                if (resp.fleets != null) {
                    resp.fleets.forEach((d: Rest.Fleet) => {
                        this.fleetsGroupsAndVehiclesSelected.push(d);
                    });
                }
                if (resp.vehicles != null) {
                    resp.vehicles.forEach((d: Rest.Vehicle) => {
                        this.fleetsGroupsAndVehiclesSelected.push(d);
                    });
                }
                if (resp.vehiclesGroup != null) {
                    resp.vehiclesGroup.forEach((d: Rest.VehiclesGroup) => {
                        this.fleetsGroupsAndVehiclesSelected.push(d);
                    });
                }
                this.fleetsGroupsAndVehiclesSelected = [
                    ...this.fleetsGroupsAndVehiclesSelected,
                ];
            });
        }
    }

    beforeSave() {
        this.entity.fleets = [];
        this.entity.vehiclesGroup = [];
        this.entity.vehicles = [];
        this.fleetsGroupsAndVehiclesSelected.forEach((o: object) => {
            if (FleetTreeComponent.isFleet(o)) {
                this.entity.fleets.push(o as Rest.Fleet);
            }
            if (FleetTreeComponent.isVehicle(o)) {
                this.entity.vehicles.push(o as Rest.Vehicle);
            }
            if (FleetTreeComponent.isVehicleGroup(o)) {
                this.entity.vehiclesGroup.push(o as Rest.VehiclesGroup);
            }
        });
    }
}

// eslint-disable-next-line max-classes-per-file
export class EvalResult {
    ruleFired: boolean;
    events: string[];
    vdm: string[];
    errors: string[];
    generatedEvents: string[][];
    log: string[];
}
