<p-dialog header="{{title}}"
          [(visible)]="display"
          [responsive]="true"
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]="true"
          [draggable]="true">

  <div class="container-xl">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <div class="row mt-3">

      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{'general.name' | translate}}" [errors]="errors.name">
          <input pInputText id="name" [(ngModel)]="entity.name"/>
        </form-input>
      </div>

      <div class="col">
        <form-input [required]="true" i18n-fieldName fieldName="{{'general.type' | translate}}" [errors]="errors.type">
          <p-dropdown i18n-placeholder placeholder="{{'general.select' | translate}} {{'general.type' | translate}}" [options]="availableTypes"
                      [(ngModel)]="entity.type" [style]="{'width':'250px'}" filter="filter"></p-dropdown>
        </form-input>
      </div>
    </div>

    <div class="row row-cols-2 mt-3">

      <form-input i18n-fieldName fieldName="{{'general.description' | translate}}" [errors]="errors.description">
        <textarea class="form-control" id="description" [(ngModel)]="entity.description" [rows]="5" style="resize: none;"></textarea>
      </form-input>

    </div>

    <div class="row row-cols-2 mt-3">

      <div class="col col-sm">
        <form-input [required]="true" i18n-fieldName fieldName="{{'general.code' | translate}}" [errors]="errors.code">
          <!--<button (click)='displaySample=true'>Show sample</button>-->

          <textarea [(ngModel)]="entity.code" name="text" cols="40" rows="5"></textarea>

        </form-input>
      </div>

      <div class="col">
        <form-input [required]="false" i18n-fieldName fieldName="{{'control-panel.conversionAlgorithm.categoricalValues' | translate}}" [errors]="errors.categoricalValues">


          <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
          1. Check correct functionality;
          2. Remove unused code;
          3. {{addCategoricalValue}} button is not working;
          4. Add translations;

          -->

          <!-- PrimeNG DataTable -->
          <p-table #dt [value]="entity.categoricalValues"
                   [rowHover]="true"
                   [autoLayout]="true"
          >

            <!-- Table Column/Header Definition -->
            <ng-template pTemplate="header">
              <tr>

                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">Key</div>
                </th>

                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">Catalan</div>
                </th>

                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">English</div>
                </th>

                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">Spanish</div>
                </th>

                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">French</div>
                </th>

              </tr>
            </ng-template>

            <!-- Table Row Definition -->
            <ng-template pTemplate="body" let-categoricalValue>
              <tr>

                <td contenteditable="true" (blur)="onBlur($event, categoricalValue, 'key')">
                  <span class="p-column-title">Key</span>
                  {{categoricalValue.key}}
                </td>

                <td contenteditable="true" (blur)="onBlur($event, categoricalValue, 'cat')">
                  <span class="p-column-title">Catalan</span>
                  {{categoricalValue.cat}}
                </td>

                <td contenteditable="true" (blur)="onBlur($event, categoricalValue, 'en')">
                  <span class="p-column-title">English</span>
                  {{categoricalValue.en}}
                </td>

                <td contenteditable="true" (blur)="onBlur($event, categoricalValue, 'es')">
                  <span class="p-column-title">Spanish</span>
                  {{categoricalValue.es}}
                </td>

                <td contenteditable="true" (blur)="onBlur($event, categoricalValue, 'fr')">
                  <span class="p-column-title">French</span>
                  {{categoricalValue.fr}}
                </td>

                <td>
                  <button type='button'
                      class='btn custom-table-remove-button'
                      (click)='deleteCategoricalValue(categoricalValue)'
                  >
                    <svg-icon src='assets/icons/trash.svg'
                          class='d-flex flex-wrap custom-table-remove-button-icon'
                          [svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
                    ></svg-icon>
                  </button>
                </td>

              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td>
                  <p i18n>No records found</p>
                </td>
              </tr>
            </ng-template>

          </p-table>

          <!--<p-dataTable [value]="entity.categoricalValues" [editable]="true">
             <p-header>
                 <ng-container i18n>Categorical Values</ng-container>
             </p-header>

             <p-column field="key"  i18n-header header="key" [editable]="true"></p-column>
             <p-column field="cat" i18n-header  header="Catalan" [editable]="true"></p-column>
             <p-column field="en" i18n-header  header="English" [editable]="true"></p-column>
             <p-column field="es" i18n-header  header="Spanish" [editable]="true"></p-column>
             <p-column field="fr" i18n-header  header="French" [editable]="true"></p-column>


             <p-column field="" header="">
                  <ng-template pTemplate="body" let-col let-categoricalValue="rowData">
                    <button (click)="deleteCategoricalValue(categoricalValue.key)" i18n>Delete</button>
                  </ng-template>

                </p-column>

         </p-dataTable>-->

          <div class="row mt-3">
            <div class="d-flex justify-content-center">
              <button type="button" style="width: 40%;" pButton icon="fa fa-plus" (click)="addCategoricalValue()" i18n-label label="Add"></button>
            </div>
          </div>

        </form-input>
      </div>

    </div>

  </div>

  <p-footer>
    <div class="d-flex justify-content-end">
      <button class="btn custom-button" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
    </div>
  </p-footer>

  <p-dialog i18n-header header="Sample" [(visible)]="displaySample" [responsive]="true">
    <code *ngIf="entity.type==='ANALOG'">
      #'''
      #Analogical Conversion Algorithm Example
      #
      #function convert(raw)
      #raw: float
      #
      #return (categoricValue, numericValue)
      # categoricalValue: String
      # numericalValue: float
      #'''
      #function convert(raw)&#123;
      #
      #categoricalValue = ''
      #numericalValue = 0
      #
      ###############################################
      ################### Write here ################
      #
      #if (raw < 10.0)&#123;
      # categoricalValue = 'Low';
      # numericalValue = -1;
      #&#125;
      #else&#123;
      # if(raw < 20.0)&#123;
      # categoricalValue = 'Medium';
      # numericalValue = 0;
      # &#125;
      # else&#123;
      # categoricalValue = 'High';
      # numericalValue = 1;
      # &#125;
      #&#125;
      #
      ###############################################
      #
      #return [categoricalValue, numericalValue];
      #&#125;
    </code>

    <code *ngIf="entity.type==='DIGITAL'">
      #
      #'''
      #Digital Conversion Algorithm Example
      #
      #convert(raw):
      #raw: boolean
      #
      #return (categoricValue, numericValue)
      # categoricalValue: String
      # numericalValue: int (0 or 1)
      #'''
      #function convert(raw)&#123;
      #
      #categoricalValue = '';
      #numericalValue = 0;
      #
      ###############################################
      ################### Write here ################
      #
      #if(raw)&#123;
      # categoricalValue = 'Low';
      # numericalValue = 0;
      # &#125;
      #else&#123;
      # categoricalValue = 'High';
      # numericalValue = 1;
      # &#125;
      ###############################################
      #
      #return [categoricalValue, numericalValue]
      #&#125;
    </code>
  </p-dialog>

</p-dialog>

