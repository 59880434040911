<!-- ZONE GROUPS PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         dataKey="name"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped">

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.zone-groups.zoneGroupsTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- EXPAND -->
      <th class="button-column">

      </th>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- NUMBER OF ZONES -->
      <th>
        <!-- COLUMN TITLE -->
        <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
          {{ 'control-panel.zone-groups.numberOfZones' | translate }}
        </div>
      </th>

      <th pSortableColumn="enabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="enabled"></p-sortIcon>
            <p-columnFilter field="enabled"
                            type="boolean"
                            display="menu"
                            [showApplyButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-zoneGroup let-expanded="expanded">
    <tr class="p-selectable-row custom-rows" (dblclick)="editZoneDialog(zoneGroup)">

      <!-- EXPAND -->
      <td class="button-column">
        <div class="d-flex align-items-center justify-content-center">

          <button class="btn custom-expand-button" type="button" [pRowToggler]="zoneGroup">
            <svg-icon [ngClass]="{'rotated': !expanded}"
                      class="d-flex m-0 p-0 custom-expand-button-icon"
                      src="assets/icons/select-down.svg"
                      [svgStyle]="{'height.px': 20, 'width.px': 20}"
            ></svg-icon>
          </button>

        </div>
      </td>

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="zoneGroup"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{zoneGroup.name}}
      </td>

      <!-- NUMBER OF ZONES -->
      <td>
        <div class="d-flex justify-content-center">
          {{zoneGroup.zones.length}}
        </div>
      </td>

      <!-- ACTIVE -->
      <td class="p-text-center">
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
             [ngClass]="{'true-icon pi-check-circle': zoneGroup.enabled, 'false-icon pi-times-circle': !zoneGroup.enabled}"></i>
        </div>
      </td>

    </tr>
  </ng-template>

  <!-- EXPANSION TEMPLATE -->
  <ng-template pTemplate="rowexpansion" let-zone>
    <tr>
      <td colspan="6">

        <app-zone-list [listId]="'zone-list'+zone.id" [parentZoneGroup]="zone" (propagateRefreshEvent)="notifyChildFetch()"></app-zone-list>

      </td>
    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD ZONE GROUP BUTTON -->
          <app-table-add-button [text]=" 'control-panel.zone-groups.zoneGroup' | translate "
                                (btnClick)="create()"
                                [isDisabled]="!user.canManageZoneGroups"
          ></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
                                          [isDisabled]="!user.canManageZoneGroups || selectedEntities.length === 0"
          ></app-table-change-status-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CONFIRMATION DIALOG -->
<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle"></p-confirmDialog>


<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.zoneGroups' | translate "
                            [display]="true"
                            [service]=zonegroupService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-dialog>
</div>

<!-- ZONE GROUP FORM -->
<app-zone-group-form></app-zone-group-form>