import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as moment from "moment";
import {CercaliaMapService} from "../../../../services/cercalia-map/cercalia-map.service";

@Component({
  selector: "app-map-image-print",
  templateUrl: "./map-image-print.html",
  styleUrls: ["./map-image-print.css"],
  encapsulation: ViewEncapsulation.None,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MapImagePrint implements OnInit {
  urlMapa = "";
  dateTime = "";

  constructor(public cercaliaService: CercaliaMapService) {
  }

  async ngOnInit() {
    let url = await localStorage.getItem("mapToPrint");
    this.urlMapa = url;
    this.dateTime = moment().format("MM/DD/YYYY HH:mm:ss");
  }

  onPrint() {
    window.print();
  }

  onDownload() {
    let data = document.getElementById("contentDiv");
    html2canvas(data, {allowTaint: true}).then(
      (canvas) => {
        let imgWidth = 208;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jspdf("portrait", "mm", "a4", true);
        let position = 0;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        let userName = JSON.parse(localStorage.getItem("currentUser")).userName;
        let fileName =
          "AtlantisFleet_map_" +
          userName +
          "_" +
          moment().format("YYYYMMDDHHmmss") +
          ".pdf";
        pdf.save(fileName);
      }
    );
  }
}
