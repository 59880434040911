import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';
import { environment } from 'environments/environment';
import { Severity } from 'app/services/notifications-service/notifications.service';

@Injectable({
	providedIn: 'root'
 })
export class NoteService extends EntityService<Rest.InvNote> {

    client: Rest.RestApplicationClient;
	 refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient,private http: HttpClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    public createNote(note): Promise<Rest.InvNote> {

		return this.http
		  .post(environment.webApiBaseUrl + 'api/inventory/note', note)
		  .toPromise()
		  .then((response: any) => {
			 return response;
		  })
		  .catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		  });
	 }

	 public updateNote(note): Promise<Rest.InvNote> {

		return this.http
		  .post(environment.webApiBaseUrl + 'api/inventory/note/update', note)
		  .toPromise()
		  .then((response: any) => {
			 return response;
		  })
		  .catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		  });
	 }

    findDocument(arg0: string,queryParams?: { id?: number }): Rest.RestResponse<Rest.InvNote> {
        return this.client.Note_findDocument(arg0, queryParams);
    }

}
