<!----------------------->
<!-- FULLSCREEN NAVBAR -->
<!----------------------->
<nav class="navbar navbar-expand-lg mt-3 mb-4">
	<div class="container">
		<div class="collapse navbar-collapse align-items-center justify-content-center" id="navbarSupportedContent">
			<ul class="navbar-nav">

				<!-- DEVICES DROPDOWN -->
				<li *ngIf="manageDevices" class="nav-item dropdown custom-nav-element">

					<!-- DEVICES DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
					   [ngClass]="{'nav-link-active': devices.isActive || deviceTypes.isActive || conversionAlgorithms.isActive}"
					   id="devicesDropdown"
					   role="button"
					   data-bs-toggle="dropdown"
					   aria-expanded="false">
						<svg-icon src="assets/icons/memory-chip.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.devices' | translate}}</div>
					</a>

					<!-- DEVICES DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-dropdown-menu" aria-labelledby="devicesDropdown">

						<!-- DEVICES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #devices="routerLinkActive" routerLink="devices"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.devices' | translate}}</div>
							</a>
						</li>

						<!-- DEVICE TYPES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #deviceTypes="routerLinkActive"
							   routerLink="deviceTypes" class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.deviceTypes' | translate}}</div>
							</a>
						</li>

						<!-- CONVERSION ALGORITHM -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #conversionAlgorithms="routerLinkActive"
							   routerLink="conversionAlgorithms" class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.conversionAlgorithms' | translate}}</div>
							</a>
						</li>

					</ul>
				</li>

				<!-- VEHICLES DROPDOWN -->
				<li *ngIf="manageVehicles || manageMaintenancePlans" class="nav-item dropdown custom-nav-element">

					<!-- VEHICLES DROPDOWN HEADER -->
					<a class="nav-link d-flex justify-content-center align-items-center p-0" href="#"
					   id="vehiclesDropdown" role="button"
					   [ngClass]="{'nav-link-active': vehicles.isActive || maintenancePlans.isActive, 'disabled': !manageVehicles && !manageMaintenancePlans}"
					   data-bs-toggle="dropdown"
					   aria-expanded="false">
						<svg-icon src="assets/icons/vehicle.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.vehicles' | translate}}</div>
					</a>

					<!-- VEHICLES DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-dropdown-menu" aria-labelledby="vehiclesDropdown">

						<!-- VEHICLES -->
						<li class="nav-item">
							<a [ngClass]="{'disabled': !manageVehicles}" routerLink="fleets"
							   [routerLinkActive]="['nav-link-active']" #vehicles="routerLinkActive"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.vehicles' | translate}}</div>
							</a>
						</li>

						<!-- MAINTENANCE PLANS -->
						<li class="nav-item">
							<a [ngClass]="{'disabled': !manageMaintenancePlans}" routerLink="maintenancePlans"
							   [routerLinkActive]="['nav-link-active']"
                               #maintenancePlans="routerLinkActive" class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.maintenancePlans' | translate}}</div>
							</a>
						</li>

					</ul>
				</li>

				<!-- ZONES -->
				<li *ngIf="manageZones" class="nav-item custom-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="zoneGroups"
					   [routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/grid.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.zones' | translate}}</div>
					</a>
				</li>

				<!-- PDIs -->
				<li *ngIf="managePOI" class="nav-item custom-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="poiCategory"
					   [routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/gps.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.pdi' | translate}}</div>
					</a>
				</li>

				<!-- NOTIFICATIONS DROPDOWN -->
				<li *ngIf="manageNotificationGroups || manageRules" class="nav-item dropdown custom-nav-element">

					<!-- NOTIFICATIONS DROPDOWN HEADER -->
					<a [ngClass]="{'nav-link-active': notificationGroups.isActive || rules.isActive}"
					   class="nav-link d-flex justify-content-center align-items-center p-0" href="#"
					   id="notificationDropdown" role="button"
					   data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/bell.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.notifications' | translate}}</div>
					</a>

					<!-- NOTIFICATIONS DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-dropdown-menu"
						aria-labelledby="notificationDropdown">

						<!-- NOTIFICATIONS GROUPS -->
						<li class="nav-item">
							<a [ngClass]="{'disabled': !manageNotificationGroups}" routerLink="noticeGroups"
							   [routerLinkActive]="['nav-link-active']"
                               #notificationGroups="routerLinkActive" class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.notificationGroups' | translate}}</div>
							</a>
						</li>

						<li class="nav-item">
							<a [ngClass]="{'disabled': !manageRules}" routerLink="rule"
							   [routerLinkActive]="['nav-link-active']" #rules="routerLinkActive"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.rules' | translate}}</div>
							</a>
						</li>

					</ul>
				</li>

				<!-- CLIENTS -->
				<li *ngIf="manageClients" class="nav-item custom-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="clients"
					   [routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/star.svg"
								  id='clientsIcon'
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.clients' | translate}}</div>
					</a>
				</li>

				<!-- USERS DROPDOWN -->
				<li *ngIf="manageUsers" class="nav-item dropdown custom-nav-element">

					<!-- USERS DROPDOWN HEADER -->
					<a [ngClass]="{'nav-link-active': users.isActive || roles.isActive || profiles.isActive}"
					   class="nav-link d-flex justify-content-center align-items-center p-0" href="#" id="usersDropdown"
					   role="button" data-bs-toggle="dropdown"
					   aria-expanded="false">
						<svg-icon src="assets/icons/user.svg"
								  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
								  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
						></svg-icon>
						<div class="nav-bar-text" i18n>{{'navbar.users' | translate}}</div>
					</a>

					<!-- USERS DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-dropdown-menu" aria-labelledby="usersDropdown">

						<!-- USERS -->
						<li class="nav-item">
							<a routerLink="users" [routerLinkActive]="['nav-link-active']" #users="routerLinkActive"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.users' | translate}}</div>
							</a>
						</li>

						<!-- ROLES -->
						<li class="nav-item">
							<a routerLink="roles" [routerLinkActive]="['nav-link-active']" #roles="routerLinkActive"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.roles' | translate}}</div>
							</a>
						</li>

						<!-- PROFILES -->
						<li class="nav-item">
							<a routerLink="profiles" [routerLinkActive]="['nav-link-active']"
                               #profiles="routerLinkActive"
							   class="nav-link">
								<div class="nav-bar-text" i18n>{{'navbar.profiles' | translate}}</div>
							</a>
						</li>

					</ul>
				</li>

			</ul>
		</div>
	</div>
</nav>

<!------------------->
<!-- MOBILE NAVBAR -->
<!------------------->
<div class="dropdown dropend d-sm-block d-lg-none">

	<a id="mobileMenuDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"
	   aria-expanded="false">
		<svg-icon src="assets/icons/three-bars.svg" [svgStyle]="{ 'height.px': 50, 'width.px': 50 }"></svg-icon>
	</a>

	<div class="dropdown-menu animate slideIn border-0 p-0 mx-5" aria-labelledby="mobileMenuDropdown"
		 style="min-width: 250px;">
		<div class="list-group custom-mobile-menu-list-group">

			<!-- DEVICES -->
			<div *ngIf="manageDevices" class="list-group-item border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex align-items-center justify-content-start p-0"
				   [ngClass]="{'nav-link-active': devices.isActive || deviceTypes.isActive || conversionAlgorithms.isActive}"
				   role="button"
				   href="#devicesCollapse"
				   data-bs-toggle="collapse"
				   aria-controls="devicesCollapse"
				   aria-expanded="false">
					<svg-icon src="assets/icons/memory-chip.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.devices' | translate}}</div>
				</a>
			</div>

			<!-- DEVICES SUB ITEMS -->
			<div class="list-group collapse" id="devicesCollapse">

				<!-- DEVICES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #devices="routerLinkActive" routerLink="devices"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.devices' | translate}}</div>
					</a>
				</div>

				<!-- DEVICE TYPES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #deviceTypes="routerLinkActive" routerLink="deviceTypes"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.deviceTypes' | translate}}</div>
					</a>
				</div>

				<!-- CONVERSION ALGORITHM -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #conversionAlgorithms="routerLinkActive"
					   routerLink="conversionAlgorithms"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.conversionAlgorithms' | translate}}</div>
					</a>
				</div>
			</div>

			<!-- VEHICLES -->
			<div *ngIf="manageVehicles || manageMaintenancePlans"
				 class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
				   [ngClass]="{'nav-link-active': vehicles.isActive || maintenancePlans.isActive, 'disabled': !manageVehicles && !manageMaintenancePlans}"
				   role="button"
				   href="#vehiclesCollapse"
				   data-bs-toggle="collapse"
				   aria-controls="vehiclesCollapse"
				   aria-expanded="false">
					<svg-icon src="assets/icons/vehicle.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.vehicles' | translate}}</div>
				</a>
			</div>

			<!-- VEHICLES SUB ITEMS -->
			<div class="list-group collapse" id="vehiclesCollapse">

				<!-- VEHICLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageVehicles}" routerLink="fleets"
					   [routerLinkActive]="['nav-link-active']"
                       #vehicles="routerLinkActive"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.vehicles' | translate}}</div>
					</a>
				</div>

				<!--MAINTENANCE PLANS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageMaintenancePlans}" routerLink="maintenancePlans"
					   [routerLinkActive]="['nav-link-active']"
                       #maintenancePlans="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.maintenancePlans' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- ZONES -->
			<div *ngIf="manageZones" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="zoneGroups"
				   [routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/grid.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
					></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.zones' | translate}}</div>
				</a>
			</div>

			<!-- PDIs -->
			<div *ngIf="managePOI" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="poiCategory"
				   [routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/gps.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
					></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.pdi' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS -->
			<div *ngIf="manageNotificationGroups || manageRules"
				 class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
				   [ngClass]="{'nav-link-active': notificationGroups.isActive || rules.isActive}"
				   role="button"
				   href="#notificationsCollapse"
				   data-bs-toggle="collapse"
				   aria-controls="notificationsCollapse"
				   aria-expanded="false">
					<svg-icon src="assets/icons/bell.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
					></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.notifications' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS SUB ITEMS -->
			<div class="list-group collapse" id="notificationsCollapse">

				<!-- NOTIFICATIONS GROUPS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageNotificationGroups}" routerLink="noticeGroups"
					   [routerLinkActive]="['nav-link-active']"
                       #notificationGroups="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.notificationGroups' | translate}}</div>
					</a>
				</div>

				<!-- RULES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageRules}" routerLink="rule" [routerLinkActive]="['nav-link-active']"
                       #rules="routerLinkActive"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.rules' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- CLIENTS -->
			<div *ngIf="manageClients" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="clients"
				   [routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/star.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
					></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.clients' | translate}}</div>
				</a>
			</div>

			<!-- USERS -->
			<div *ngIf="manageUsers" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
				   [ngClass]="{'nav-link-active': users.isActive || roles.isActive || profiles.isActive}"
				   role="button"
				   href="#usersCollapse"
				   data-bs-toggle="collapse"
				   aria-controls="usersCollapse"
				   aria-expanded="false">
					<svg-icon src="assets/icons/user.svg"
							  class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							  [svgStyle]="{ 'height.px': 14, 'width.px': 14 }"
					></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.users' | translate}}</div>
				</a>
			</div>

			<!-- USERS SUB ITEMS -->
			<div class="list-group collapse" id="usersCollapse">

				<!-- USERS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="users" [routerLinkActive]="['nav-link-active']" #users="routerLinkActive"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.users' | translate}}</div>
					</a>
				</div>

				<!-- ROLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="roles" [routerLinkActive]="['nav-link-active']" #roles="routerLinkActive"
					   class="nav-link border-bottom">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.roles' | translate}}</div>
					</a>
				</div>

				<!-- PROFILES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="profiles" [routerLinkActive]="['nav-link-active']" #profiles="routerLinkActive"
					   class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.profiles' | translate}}</div>
					</a>
				</div>

			</div>

		</div>
	</div>

</div>

