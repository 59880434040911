import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "app/services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-alarm-call-number-times-configuration-concox-mt200",
  templateUrl:
    "./alarm-call-number-times-configuration-concox-mt200.component.html",
  styleUrls: [
    "./alarm-call-number-times-configuration-concox-mt200.component.css",
  ],
})
export class AlarmCallNumberTimesConfigurationConcoxMt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input()
  alarmCallNumberTimes: Rest.AlarmCallNumberTimesConfigurationConcoxMT200;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.alarmCallNumberTimes);
    }
  }
}
