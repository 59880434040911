<p-accordionTab>
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Digital Input Port
            Settings(AT + GTDIS)</span>
        </div>
      </div>
    </p-header>
    <!-- Ignition Input -->
    <div class="ui-g">
      <div class="ui-g-12">
        <form-input  fieldName="Ignation Input">
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Sample Period">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.samplePeriod" [min]="0" [max]="30000"></p-spinner>
            <span class="ui-inputgroup-addon">0~30000</span>
            <span class="ui-inputgroup-addon" >mv</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="MPF Debounce Time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.mpfDebounceTime" [min]="0" [max]="30000"></p-spinner>
            <span class="ui-inputgroup-addon">0~30000</span>
            <span class="ui-inputgroup-addon" >mv</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Ignition Detection Mode">
          <p-dropdown [options]="modeOptions" [(ngModel)]="disParameters.mode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
      <!-- Digital input 1 -->
      <div class="ui-g-12">
        <form-input  fieldName="Digital input 1">
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Enable">
          <p-inputSwitch [(ngModel)]="disParameters.enableD1"></p-inputSwitch>
        </form-input>
      </div>
      <div [class.disableDIV]=!disParameters.enableD1 class=ui-g-12>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Debounce time">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD1" [min]="0" [max]="20"></p-spinner>
              <span class="ui-inputgroup-addon">0~20</span>
              <span class="ui-inputgroup-addon" >*10ms</span>
            </div>
          </form-input>
        </div>
  
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Validity time">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD1" [min]="0" [max]="12"></p-spinner>
              <span class="ui-inputgroup-addon">0~12</span>
              <span class="ui-inputgroup-addon" >*2s</span>
            </div>
          </form-input>
        </div>
      </div>
      <!-- Digital Input 2 -->
      <div class="ui-g-12">
        <form-input  fieldName="Digital input 2">
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Enable">
          <p-inputSwitch [(ngModel)]="disParameters.enableD2"></p-inputSwitch>
        </form-input>
      </div>
      <div [class.disableDIV]=!disParameters.enableD2 class=ui-g-12>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Debounce time">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD2" [min]="0" [max]="20"></p-spinner>
              <span class="ui-inputgroup-addon">0~20</span>
              <span class="ui-inputgroup-addon" >*10ms</span>
            </div>
          </form-input>
        </div>
  
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Validity time">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD2" [min]="0" [max]="12"></p-spinner>
              <span class="ui-inputgroup-addon">0~12</span>
              <span class="ui-inputgroup-addon" >*2s</span>
            </div>
          </form-input>
        </div>
      </div>
    </div>
  </p-accordionTab>
