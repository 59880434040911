<!-- MAINTENANCES PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.maintenance-plans.maintenancesTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- DESCRIPTION -->
      <th pSortableColumn="description">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.description' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="description"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- OBSERVATION -->
      <th pSortableColumn="observations">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.observations' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="observations"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="enabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="enabled"></p-sortIcon>
            <p-columnFilter type="boolean"
                            field="enabled"
                            display="menu"
                            [showApplyButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-maintenance>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(maintenance)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="maintenance"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{maintenance.name}}
      </td>

      <!-- DESCRIPTION -->
      <td>
        {{maintenance.description}}
      </td>

      <!-- OBSERVATIONS -->
      <td>
        {{maintenance.observations}}
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi" [ngClass]="{'true-icon pi-check-circle': maintenance.enabled, 'false-icon pi-times-circle': !maintenance.enabled}"></i>
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="5" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD MAINTENANCE BUTTON -->
          <app-table-add-button [text]=" 'control-panel.maintenance-plans.maintenance' | translate "
                                (btnClick)="createMaintenanceDialog()"
                                [isDisabled]="!user.canManageMaintenancePlans && !hasPermissions"
          ></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
                                          [isDisabled]="!user.canManageMaintenancePlans || selectedEntities.length === 0"
          ></app-table-change-status-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.maintenances' | translate "
                            [display]="true"
                            [service]=maintenanceService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-dialog>
</div>

<!-- MAINTENANCE FORM -->
<app-maintenance-form></app-maintenance-form>
