import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-protocol-watchdog-gmt200',
  templateUrl: './protocol-watchdog-gmt200.component.html',
  styleUrls: ['./protocol-watchdog-gmt200.component.css'],
})
export class ProtocolWatchdogGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() dogParameters: Rest.ProtocolWatchdogGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }

  modeOptions: any = [
    {label: "Disable this function", value:"DISABLE"},
    {label: "Reboot periodically according to the <Interval> and <Time> setting", value:"REBOOT_PERIODICALLY"},
    {label: "Reboot when ignition on", value:"REBOOT_WHEN_IGNITION_ON"}
  ]

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.dogParameters);
    }
  }

}
