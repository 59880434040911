import { Component, ViewEncapsulation, Input } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-jam-event-config",
  templateUrl: "./jam-event-config.component.html",
  styleUrls: ["./jam-event-config.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class JamEventConfigComponent extends ServerValidatedFormComponent {
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() deviceParams: Rest.DeviceParameters;
}
