<p-accordionTab>
  <p-header>
      <div class="ui-helper-clearfix">
          <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Analog Input Configuration ($WP+SETAE)</span>
          </div>
      </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="AnalogInput Mode" >
        <p-dropdown  (onChange)="trackEventModeChange(analogInput.mode,$event)" [options]="AnalogInputModes"
        [(ngModel)]="analogInput.mode" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>

    <div [class.disableDIV]=!trackModeSwitch>
        <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Analog Input Id" >
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="analogInput.analogPort" [min]="1" [max]="2" (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">1~2</span>
              </div>
            </form-input>
          </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Report Action" >
        <p-dropdown [options]="ActionMode"
          [(ngModel)]="analogInput.action" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>


              
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Voltage Min Value" >
        <div class="ui-inputgroup">
          <p-spinner size="5" [step]="0.01" [(ngModel)]="analogInput.minVoltageLevel" [min]="0" [max]="30"></p-spinner>
          <span class="ui-inputgroup-addon">0~30</span>
          <span class="ui-inputgroup-addon" >v</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Voltage Max Value" >
        <div class="ui-inputgroup">
          <p-spinner size="5" [step]="0.01" [(ngModel)]="analogInput.maxVoltageLevel" [min]="0" [max]="30"></p-spinner>
          <span class="ui-inputgroup-addon">0~30</span>
          <span class="ui-inputgroup-addon" >v</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Duration" >
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="analogInput.duration" [min]="0" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">0~65535</span>
          <span class="ui-inputgroup-addon" >s</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Output Port" >
        <p-dropdown [options]="OutpurPortsModes"
          [(ngModel)]="analogInput.outputPort" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Output Control">
        <p-inputSwitch [(ngModel)]="analogInput.outputControl"></p-inputSwitch>
      </form-input>
    </div>        
  </div>
  </div>

</p-accordionTab>
