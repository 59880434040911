<!-- POI PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'control-panel.poi-categories.poiTable' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter field="name"
                            type="text"
                            matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

      <!-- ADDRESS -->
      <th pSortableColumn="address">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.address' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="address"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- POSTAL CODE -->
      <th pSortableColumn="postalCode">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.postalCode' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="postalCode"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- MUNICIPALITY -->
      <th pSortableColumn="municipality">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.municipality' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="municipality"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- PROVINCE -->
      <th pSortableColumn="province">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.province' | translate }}
          </div>

          <!-- COLUMN SORT ICON -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="province"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- PHONE NUMBER -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.phoneNumber' | translate }}
          </div>

        </div>
      </th>

      <!-- CONTACT PERSON -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'control-panel.poi-categories.contactPerson' | translate }}
          </div>

        </div>
      </th>

      <!-- TIME ZONE -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.timeZone' | translate }}
          </div>

        </div>
      </th>

      <!-- OBSERVATIONS -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.observations' | translate }}
          </div>

        </div>
      </th>

      <!-- PARAM 1 -->
      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            Param 1
          </div>

        </div>
      </th>

      <th>
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            Param 2
          </div>

        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn="enabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="enabled"></p-sortIcon>
            <p-columnFilter field="enabled"
                            type="boolean"
                            display="menu"
                            [showApplyButton]="false"
            ></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-poi>
    <tr class="p-selectable-row custom-rows" (dblclick)="editPOIDialog(poi)">

      <!-- SELECT -->
      <td class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="poi"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        {{poi.name}}
      </td>

      <!-- ADDRESS -->
      <td>
        {{poi.address}}
      </td>

      <!-- POSTAL CODE -->
      <td>
        <div class="d-flex justify-content-center">
          {{poi.postalCode}}
        </div>
      </td>

      <!-- MUNICIPALITY -->
      <td>
        {{poi.municipality}}
      </td>

      <!-- PROVINCE -->
      <td>
        {{poi.province}}
      </td>

      <!-- PHONE NUMBER -->
      <td>
        {{poi.phoneNumber}}
      </td>

      <!-- CONTACT PERSON -->
      <td>
        {{poi.contactPerson}}
      </td>

      <!-- TIME ZONE -->
      <td>
        <div class="d-flex justify-content-center">
          {{poi.timeZone}}
        </div>
      </td>

      <!-- OBSERVATIONS -->
      <td>
        {{poi.observations}}
      </td>

      <!-- PARAM 1 -->
      <td>
        {{poi.param1}}
      </td>

      <!-- PARAM 2 -->
      <td>
        {{poi.param2}}
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
             [ngClass]="{'true-icon pi-check-circle': poi.enabled, 'false-icon pi-times-circle': !poi.enabled}"></i>
        </div>
      </td>

    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="13" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="13" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD POI BUTTON -->
          <app-table-add-button [text]=" 'control-panel.poi-categories.poi' | translate "
                                (btnClick)="createPOIDialog()"
                                [isDisabled]="!user.canManagePOICategories || !hasPermissions"
          ></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
                                          [isDisabled]="!user.canManagePOICategories || selectedEntities.length === 0"
          ></app-table-change-status-button>

        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.poi' | translate "
                            [display]="true"
                            [service]=poiService
                            [entitiesList]=selectedEntities
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)"
  ></app-change-status-dialog>
</div>

<!-- POI FORM -->
<app-poi-form [selectedPOICategory]="selectedPOICategory" [selectedPOI]="selectedPOI"></app-poi-form>
