<p-messages [(value)]="messages"></p-messages>


<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;

-->

<!-- PrimeNG DataTable -->
<p-table #dt [value]="paginationResult.entities"
         [lazy]="true"
         (onLazyLoad)="loadData($event)"
         [rows]="paginationRequest.pageSize"
         [totalRecords]="paginationResult.filteredEntities"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [loading]="loading"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         dataKey="name"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
>

  <!-- Table Title -->
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between text-center">{{title}}</div>
  </ng-template>

  <!-- Table Column/Header Definition -->
  <ng-template pTemplate="header">
    <tr>

      <th style="width: 3rem">

      </th>

      <th pSortableColumn="name">
        <div class="p-d-flex p-jc-between p-ai-center">Name<p-sortIcon field="name"></p-sortIcon>
          <p-columnFilter type="text" field="name" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
        </div>
      </th>

      <th pSortableColumn="description">
        <div class="p-d-flex p-jc-between p-ai-center">Description<p-sortIcon field="description"></p-sortIcon>
          <p-columnFilter type="text" field="description" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
        </div>
      </th>

      <th pSortableColumn="observations">
        <div class="p-d-flex p-jc-between p-ai-center">Observations<p-sortIcon field="observations"></p-sortIcon>
          <p-columnFilter type="text" field="observations" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
        </div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Last Execution</div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Next Execution</div>
      </th>


      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Status</div>
      </th>

      <th style="width: 3rem">

      </th>

    </tr>
  </ng-template>

  <!-- Table Row Definition -->
  <ng-template pTemplate="body" let-maintenance let-expanded="expanded">
    <tr>

      <td>
        <button type="button" pButton pRipple [pRowToggler]="maintenance" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>

      <td>
        <span class="p-column-title">Name</span>
        {{maintenance.name}}
      </td>

      <td>
        <span class="p-column-title">Description</span>
        {{maintenance.description}}
      </td>

      <td>
        <span class="p-column-title">Observations</span>
        {{maintenance.observations}}
      </td>

      <td>
        <span class="p-column-title">Last Execution</span>
        {{ maintenance.periodicityType ==="TIME_BASED" ? (maintenance._$lastExecutionDate|date: 'dd/MM/yyyy') : (maintenance._$lastExecutionKM|distance:true)}}
      </td>

      <td>
        <span class="p-column-title">Next Execution</span>
        {{ maintenance.periodicityType ==="TIME_BASED" ? (maintenance._$nextExecutionDate|date: 'dd/MM/yyyy') : (maintenance._$nextExecutionKM|distance:true)}}
      </td>

      <td class="p-text-center">

        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
          <div *ngIf="maintenance._$status !== undefined">
            <img src="{{'./assets/images/MaintenanceStates/'+ maintenance._$status+'.png'}}" style="width:24px;position:absolute;top:1px;left:50%"
            />
          </div>
        </div>
      </td>

      <td>
        <div *ngIf="maintenance._$execution === true">
          <button (click)="addExecution(maintenance)" type="button" i18n-pTooltip pTooltip="Add Execution" tooltipPosition="left">
            <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
          </button>
        </div>
      </td>

    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-maintenanceExecution>
    <tr>

      <td colspan="7">

        <div class="p-p-3">
          <app-maintenance-executions-list [parentMaintenance]="maintenanceExecution" [vehicle]="vehicle" (propagateRefreshEvent)="notifyChildFetch()"></app-maintenance-executions-list>
        </div>

      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p i18n>No records found</p>
      </td>
    </tr>
  </ng-template>

</p-table>

<!--<p-dataTable [value]="paginationResult.entities" [totalRecords]="paginationResult.filteredEntities" [rows]="paginationRequest.pageSize"
(onLazyLoad)="loadData($event)"
    [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" resizableColumns="true" lazy=true
    expandableRows="true" [rowStyleClass]="rowClass" #dt>

    <p-header>
        <ng-container i18n>Maintenances</ng-container></p-header>
    <p-column [style]="{'width':'38px'}" styleClass="col-icon" expander="true"></p-column>

    &lt;!&ndash; <p-column field="id" header="Id" hidden="true" [filter]="false" filterPlaceholder="Buscar" filterMatchMode="contains"></p-column> &ndash;&gt;
    <p-column field="name" i18n-header header="Name" [filter]="true" i18n-filterPlaceholder filterPlaceholder="Search" filterMatchMode="contains"></p-column>
    <p-column field="description" i18n-header header="Description" [filter]="true" i18n-filterPlaceholder filterPlaceholder="Search"
        filterMatchMode="contains"></p-column>
    <p-column field="observations" i18n-header header="Observations" [filter]="true" i18n-filterPlaceholder filterPlaceholder="Search"
        filterMatchMode="contains"></p-column>

    <p-column i18n-header header="Last Execution">
        <ng-template let-maintenance="rowData" pTemplate="body">
            <div> {{ maintenance.periodicityType ==="TIME_BASED" ? (maintenance._$lastExecutionDate|date: 'dd/MM/yyyy') : (maintenance._$lastExecutionKM|distance:true)
                }}
            </div>
        </ng-template>
    </p-column>
    <p-column i18n-header header="Next Execution">
        <ng-template let-maintenance="rowData" pTemplate="body">
            <div> {{ maintenance.periodicityType ==="TIME_BASED" ? (maintenance._$nextExecutionDate|date: 'dd/MM/yyyy') : (maintenance._$nextExecutionKM|distance:true)
                }}
            </div>
        </ng-template>
    </p-column>

    <p-column i18n-header header="Status">
        <ng-template let-maintenance="rowData" pTemplate="body">
            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                &lt;!&ndash;<img src="src/app/resources/images/MaintenanceStates/{{maintenance.state}}.png" style="width:24px;position:absolute;top:1px;left:50%"/>&ndash;&gt;
                <div *ngIf="maintenance._$status != undefined">
                    <img src="{{'./assets/images/MaintenanceStates/'+ maintenance._$status+'.png'}}" style="width:24px;position:absolute;top:1px;left:50%"
                    />
                </div>
            </div>
        </ng-template>
    </p-column>
    <p-column [style]="{'width':'50px'}">
        <ng-template let-maintenance="rowData" pTemplate="body">
            <div *ngIf="maintenance._$execution == true">
                <button (click)="addExecution(maintenance)" type="button" i18n-pTooltip pTooltip="Add Execution" tooltipPosition="left">
                    <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
                </button>
            </div>
        </ng-template>
    </p-column>


    <ng-template let-maintenance pTemplate="rowexpansion" ng-show="fade">
        <div class="ui-grid ui-grid-responsive" style="margin-top:10px">
            <app-maintenance-executions-list [parentMaintenance]="maintenance" [vehicle]="vehicle" (propagateRefreshEvent)="notifyChildFetch()"></app-maintenance-executions-list>
        </div>
    </ng-template>


</p-dataTable>-->

<app-maintenance-execution-form (return)=notification($event)></app-maintenance-execution-form>
