import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-outside-working-hours-gl320m',
  templateUrl: './outside-working-hours-gl320m.component.html',
  styleUrls: ['./outside-working-hours-gl320m.component.css']
})
export class OutsideWorkingHoursGl320mComponent extends ServerValidatedFormComponent implements OnInit {


 modeOptions: any = [
  { label: "Disable this function", value: "DISABLE"},
  { label: "Manual mode", value: "MANUAL"},
  { label: "Full manual mode", value: "FULL_MANUAL"},
  { label: "Automatic mode", value: "AUTOMATIC"},
 ]

 rfSleepOptions: any = [
  { label: "Do not shut down radio", value: "NO_SHUT_DOWN"},
  { label: "Shut down radio", value: "SHUT_DOWN"},
 ]

  @Input() owhParameters: Rest.OutsideWorkingHoursGL320M;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.owhParameters);
    }
  }

}
