<p-table [value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
          {{ 'vehicleAssignments.header' | translate }}
        </div>
    </ng-template>
    <!-- #endregion -->

	 <ng-template #filterLoading>
		<div class="d-flex align-items-center h-100" [style]="{'margin-right':'10px'}">
			<mat-spinner
				[diameter]="20"
				strokeWidth="3"
				[color]="primary">
			</mat-spinner>
		</div>
	 </ng-template>

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
      <tr>

			<!-- EMPRESA -->
         <th pSortableColumn="billingAccount">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.companies.company' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div class="d-flex align-items-center h-100">
                     <p-sortIcon field="billingAccount"></p-sortIcon>
							<p-columnFilter field="billingAccount" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="companiesList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
            		</div>
					</div>

				</div>
         </th>

			<!-- START DATE -->
         <th pSortableColumn="startDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					  	{{ 'tableManagement.cardLists.assignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="startDate"></p-sortIcon>
						<p-columnFilter field="startDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- ENDS DATE -->
			<th pSortableColumn="endDate">
				<div class='d-flex align-items-center justify-content-center flex-fill h-100'>
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
					   {{ 'tableManagement.cardLists.deassignDateShort' | translate }}
					</div>

               <!-- COLUMN SORT && FILTER ICONS -->
               <div class="d-flex align-items-center h-100">
						<p-sortIcon field="endDate"></p-sortIcon>
						<p-columnFilter field="endDate" type="date" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- DELEGATION -->
         <th pSortableColumn="Delegation" [pSortableColumnDisabled]="!isDelegationListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.delegations.delegation' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isDelegationListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="Delegation"></p-sortIcon>
							<p-columnFilter field="Delegation" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="delegationList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

			<!-- Subdelegation -->
         <th pSortableColumn="Subdelegation" [pSortableColumnDisabled]="!isSubdelegationListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'tableManagement.subdelegations.subdelegation' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isSubdelegationListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="Subdelegation"></p-sortIcon>
							<p-columnFilter field="Subdelegation" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="subdelegationList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

			<!-- CECO -->
         <th pSortableColumn="ceco" [pSortableColumnDisabled]="!isCecoListLoaded">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.table.ceco' | translate }}
               </div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
                  <!-- COLUMN SORT && FILTER ICONS -->
                  <div *ngIf="isSubdelegationListLoaded; else filterLoading" class="d-flex align-items-center h-100">
                     <p-sortIcon field="ceco"></p-sortIcon>
							<p-columnFilter field="ceco" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="cecoList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
										  	<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
										  	</div>
									 	</ng-template>
									</p-multiSelect>
						  		</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
         </th>

      </tr>
   </ng-template>

   <ng-template pTemplate="body" let-va>
      <tr class="p-selectable-row custom-rows">
         <td>({{va.company?.code}}) {{va.company?.name}}</td>
         <td>{{va.startDate | date: 'dd/MM/yyyy'}}</td>
			<td>{{va.endDate | date: 'dd/MM/yyyy'}}</td>
			<td *ngIf="va.delegation; else na">({{va.delegation.code}}) {{va.delegation.name}}</td>
			<td *ngIf="va.subDelegation; else na">({{va.subDelegation.code}}) {{va.subDelegation.name}}</td>
			<td>{{va.ceco?.code}} {{va.ceco?.name}}</td>
      </tr>
   </ng-template>

	<ng-template #na>
		<td>N/A</td>
	</ng-template>

     <!-- TABLE EMPTY MESSAGE -->
   <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="6" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">
          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>
          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel"  (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>
