<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display">

  <div class="container-md">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <!-- #FORM BODY -->
    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.users.user' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <p-dropdown [options]='usersList' [(ngModel)]="entity.user" dataKey='id' placeholder="Select an User" optionLabel="name" appendTo='body'
            filter="true" [style]="{'min-width':'250px'}" [disabled]="!isNew" (onChange)="onChangeLeader($event)" (onFilter)="onFilterLeader($event)"
            >

            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="entity.user">
                <div> {{ entity.user.surname1}} {{entity.user.surname2}}, {{ entity.user.name}}
                </div>
              </div>
            </ng-template>
            <ng-template let-user pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div> {{user.surname1}} {{ user.surname2}}, {{ user.name}}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    <!-- FLEET LEADER -->
    <div class="row mt-3">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{ 'tableManagement.leaders.fleetLeader' | translate }}
        </label>
      </div>

      <div class="col">
        <div class="float-end">
          <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
            type="checkbox" [(ngModel)]="entity.fleetLeader" />
        </div>
      </div>
    </div>
    <!-- ZONE LEADER -->
    <div class="row mt-3">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{ 'tableManagement.leaders.zoneLeader' | translate }}
        </label>
      </div>

      <div class="col">
        <div class="float-end">
          <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
            type="checkbox" [(ngModel)]="entity.zoneLeader" />
        </div>
      </div>
    </div>
    <!-- SERVICE LEADER -->
    <div class="row mt-3">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{ 'tableManagement.leaders.serviceLeader' | translate }}
        </label>
      </div>

      <div class="col">
        <div class="float-end">
          <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
            type="checkbox" [(ngModel)]="entity.serviceLeader" />
        </div>
      </div>
    </div>

    <!-- ACTIVE -->
    <!--
       <div class="row mt-3">
        <div class="col d-flex align-items-center">
          <label for="vehicle-data-settings-name" class="custom-label" i18n>
            {{'general.active' | translate}}
          </label>
        </div>

        <div class="col">
          <div class="float-end">
            <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
              type="checkbox"  [disabled]="true" [checked]="!entity.disabled" (change)="entity.disabled = !entity.disabled" />
          </div>
        </div>
     </div>
     -->
  </div>

  <p-footer>

    <div class="d-flex justify-content-center bd-highlight">

      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.save' | translate}}
          </button>
        </div>

        <div *ngIf="isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.create' | translate}}
          </button>
        </div>
      </div>

      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="changeStatus(!entity.disabled)" i18n>
            {{'general.changeStatus' | translate}}
          </button>
        </div>
      </div>

    </div>

  </p-footer>

</p-dialog>