import { Component } from '@angular/core';
import {UserTimePipe} from "../shared/UserTimePipe"
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [UserTimePipe],
})

export class HomeComponent {
   constructor(private ut:UserTimePipe)
{}

private thetime=new Date();
private userTime="2018-04-15T05:00:00.00+00:00";
private userTime1="";
private calTime= new Date();
private calTime1= new Date();
test()
{
  this.calTime = this.ut.utcToCalendarDate(this.userTime);
  this.userTime1 = this.ut.transform(this.ut.calendarDateToUTC(this.calTime1),null,0);

}
}
