<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>


        <!-- #FORM BODY -->


        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'control-panel.vehicles.vehicleType' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <p-dropdown
						[options]='vehicleTypeList' [(ngModel)]='entity.type'
						placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.types.type' | translate }}"
						dataKey='id' optionLabel='name'
						[style]="{'width':'250px', 'margin-right':'10px'}"
						appendTo='body'
                        (onChange)="typeChanged($event)"
						filter="true" filterBy="name">
					</p-dropdown>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'control-panel.vehicles.vehicleBrand.name' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <p-dropdown
						[options]='brandList' [(ngModel)]='entity.brand'
						placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.brands.brand' | translate }}"
						dataKey='id' optionLabel='name'
						[style]="{'width':'250px', 'margin-right':'10px'}"
						appendTo='body'
						filter="true" filterBy="name">
					</p-dropdown>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'general.name' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="vehicle-data-settings-name" class="form-control custom-input"
                        [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- ROW 4 -->
        <div *ngIf="!isNew">
            <div class="row mt-3">
                <div class="col d-flex align-items-center">
                    <label for="vehicle-data-settings-name" class="custom-label" i18n>
                        {{'general.enabled' | translate}}
                    </label>
                </div>

                <div class="col">
                    <div class="float-end">
                        <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                            type="checkbox" [(ngModel)]="modelEnabled" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>

        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveChanges()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>

                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="saveChanges()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
            </div>

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(modelEnabled)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>

        </div>

    </p-footer>
</p-dialog>