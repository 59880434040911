import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../notifications-service/notifications.service';
import { UserService } from "../../../rest/user.service";
import { fromPromise } from "rxjs-compat/observable/fromPromise";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ControlPanelGuard implements CanActivate {

    constructor(private auth: AuthenticationService, private router: Router, private notificationService: NotificationsService, private userService: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        /* Retrieve user permission from API */
        return fromPromise(this.userService.getUserAllowedDashboards(this.auth.user.id)
            .then((response) => {
                if (response.controlPanel) {
                    return true;
                }

                /* TODO: Implement loading and remove the loader as a promise handle (see previous line for an example) */
                this.router.navigate(['/']);

                this.notificationService.add(Severity.error, 'Access Denied', 'You are not authorized to access the page!');
                return false;
            })
            .catch(() => {
                return false;
            }));
    }
}
