<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >GPRS CONFIGURATION (GPRSON)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="gprs.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>
</p-accordionTab>

