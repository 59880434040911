import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-image-expandable',
  templateUrl: './image-expandable.component.html',
  styleUrls: ['./image-expandable.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageExpandableComponent {


  dialogWidth: string = 'auto';
  dialogHeight: string = 'auto';
  zoom: string;
  isZoomed = false;
  pos = { top: 0, left: 0, x: 0, y: 0 };
  title: string;

  displayExpandedImage: Boolean = false;

  @ViewChild('img') 'img': ElementRef;
  @ViewChild('container') 'container': ElementRef;

  @Input() imageSrc: string;
  @Input() thumbnailWidth: number = 40;
  @Input() thumbnailHeight: number = 30;
  initialWidth: number = 0;
  initialHeight: number = 0;

  constructor(
    protected i18n: I18nService,
    public translate: TranslateService) {
    this.title = "IMAGE";
    this.translate.get('logistics').subscribe((views) => {
      this.title = views.photos.toUpperCase();
    });
  }

  beforeShow() {

  }

  ngOnInit() {
    this.initialWidth = window.innerWidth;
    this.initialHeight = window.innerHeight;
  }

  public showExpandableImage(): void {
    //this.pwidthIcon = this.initialWidth;
    //this.pheightIcon = this.initialHeight;
    this.isZoomed = true;
    this.resizeDialog();
    this.displayExpandedImage = true;
  }

  resizeDialog(e?) {
    console.log(e?.clientY, e?.clientX);
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed) {
      this.container.nativeElement.style.overflow = 'hidden';
      // this.container.nativeElement.style.width = '100%';
      //this.container.nativeElement.style.height = '100%';
      this.container.nativeElement.style.width = '98vw';
      this.container.nativeElement.style.height = '100%';
    } else {
      this.container.nativeElement.style.overflow = 'hidden';
      this.container.nativeElement.style.width = 'auto';
      this.container.nativeElement.style.height = 'auto';
    }
  }
}
