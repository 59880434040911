import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { HtcCardListService } from 'app/modules/vehicle-inventory/services/table-management/htc-card-list.service';

@Component({
  selector: 'app-htc-card-list',
  templateUrl: './htc-card-list.component.html',
  styleUrls: ['./htc-card-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HtcCardListComponent extends EntityListComponent<Rest.InvHtcCard> implements OnInit, OnDestroy {
	@Input() vehicle?: any;
	@Input() startDate?: Date;
	@Input() endDate?: Date;

	title: string = this.i18n._('Card list');

	// @ViewChild(CardFormComponent, { static: true })
	// editForm: CardFormComponent;
	isEditingDisabled: Boolean = false;

	refreshListSuscription: Subscription;

	constructor(
		private htcCardListService: HtcCardListService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected translateService: TranslateService
	) {
		super(
			htcCardListService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime
		);
	}

	ngOnInit(): void {
		// console.log("[HTC-CARD-LIST] ngOnInit *** ");
		super.ngOnInit();
		// this.form = this.editForm;
		this.hasEntityFilters = false;
		this.refreshListSuscription = this.htcCardListService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				this.getList(item.startDate, item.endDate);
			}
		);
	}

	ngOnDestroy(): void {
		this.refreshListSuscription.unsubscribe();
	}

	public getList(startDate: Date, endDate: Date) {
		// console.log("[HTC-CARD-LIST] getList *** ");
		this.startDate = startDate;
		this.endDate = endDate;
		this.beforeLoad();
		super.refresh();
	}

	protected beforeLoad(): void {
		// console.log("[HTC-CARD-LIST] beforeLoad *** ");
		if (this.vehicle) this.paginationRequest.filters['vehicle'] = [this.vehicle.id];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.endDate.toISOString()];
		// console.log(this.paginationRequest);
	}

	afterLoad() {
		// console.log("[HTC-CARD-LIST] afterLoad ***");
		// console.log(this.paginationResult.entities);
	}

}
