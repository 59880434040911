import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { I18nService } from '../../../../services/i18n/i18n.service';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-acc-event-form',
  templateUrl: './acc-event-form.component.html',
  styleUrls: ['./acc-event-form.component.css'],

})
export class AccEventFormComponent extends ServerValidatedFormComponent implements  OnChanges {

  constructor(protected i18n: I18nService) {  super(i18n);}

  @Input() acc: Rest.ACC;

  @ViewChild('accevent') accevent;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
  /**
   * Needed to prevent toggle of UI component
   * @param event
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();

  }


}
