import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { I18nService } from '../../../services/i18n/i18n.service';
import { RacingService } from '../../../rest/racing.service';
import { Rest } from '../../../rest/rest_client';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import IconRacing = Rest.IconRacing;

@Component({
    selector: 'app-poi-racing-form',
    templateUrl: './poi-racing-form.component.html',
    styleUrls: ['./poi-racing-form.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PoiRacingFormComponent implements OnInit, OnChanges {
    @Input() eventId: number;
    @Input() selectedPoi: Rest.POIRacing | null;
    @Output() poiAdded = new EventEmitter<any>();
    @Output() poiSaved = new EventEmitter<any>();

    poiRacingFormTitle: string;
    isDialogVisible: boolean;
    loading: boolean;
    isNew: boolean;

    clientId: number;
    poi: Rest.POIRacing;

    poiFormErrorMessages: string[];

    poiCoordinates: any;
    poiIcons: IconRacing[];

    selectedPoiIcon: IconRacing;

    uploadImageIconUrl: string;
    poiLogo: any;

    constructor(
        private racingService: RacingService,
        private notificationsService: NotificationsService,
        private auth: AuthenticationService,
        private i18n: I18nService,
        private translate: TranslateService,
    ) {
		console.log("[POI-RACING-FORM] constructor ***");
        this.poiRacingFormTitle = '';
        this.isDialogVisible = false;
        this.loading = false;
        this.isNew = false;
        this.clientId = this.auth.user.client.id;

        this.poiFormErrorMessages = [];
        this.poiCoordinates = null;

        this.poiIcons = [];
    }

    ngOnInit(): void {
        /* Retrieve POI icons from server */
        this.racingService
            .getPoiIcons(this.clientId)
            .then((response) => {
                this.poiIcons = response;
					 console.log(this.poiIcons);
                this.selectedPoiIcon = this.poiIcons[0];
            })
            .catch(() => {
                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.get.poiIcons') + '!',
                );
            });
    }

    ngOnChanges() {
        if (this.selectedPoi) {
            this.poi = {
                id: this.selectedPoi.id,
                namePoi: this.selectedPoi.namePoi,
                infoTxt: this.selectedPoi.infoTxt,
                blogUrl: this.selectedPoi.blogUrl,
                videoUrl: this.selectedPoi.videoUrl,
                longitude: this.selectedPoi.longitude,
                latitude: this.selectedPoi.latitude,
                deleted: this.selectedPoi.deleted,
                dateDeleted: this.selectedPoi.dateDeleted,
                imagePoi: this.selectedPoi.imagePoi,
                itemIcon: this.selectedPoi.itemIcon,
                event: {
                    id: this.eventId,
                },
            };

            /* If the POI has an image, set uploadImageIconUrl to NULL to disable the div containing the default no image icon */
            this.uploadImageIconUrl = this.poi.imagePoi ? null : 'assets/icons/no-image-available.svg';

            this.selectedPoiIcon = this.poi.itemIcon;
        }
    }

    /**
     * savePoi()
     * */
    public savePoi(): void {
        if (this.validatePoiForm()) {
            /* Create custom POI structure to send to API */
            let poiData = {
                id: this.isNew ? 0 : this.poi.id,
                namePoi: this.poi.namePoi,
                infoTxt: this.poi.infoTxt,
                blogUrl: this.poi.blogUrl,
                videoUrl: this.poi.videoUrl,
                longitude: this.poi.longitude,
                latitude: this.poi.latitude,
                deleted: false,
                imagePoi: null,
                itemIcon: {
                    id: this.selectedPoiIcon.id,
                },
                event: {
                    id: this.eventId,
                },
            };

            /* If POI is updated with new image */
            if (!this.isNew && this.poi.imagePoi) {
                poiData.imagePoi = {
                    id: this.poi.imagePoi.id,
                };
            }

            /* Stringify the custom POI structure */
            let poiString = JSON.stringify(poiData);

            /* Create new POI */
            if (this.isNew) {
                this.racingService.addPoi(this.eventId, poiString, this.poiLogo).then((poi) => {
                    if (poi) {
                        poi.itemIcon.iconId.imageEncoded = this.selectedPoiIcon.iconId.imageEncoded;
                        this.poiAdded.emit(poi);
                        this.isDialogVisible = false;
                    }
                });
            } else {
                this.racingService.updatePoi(poiString, this.poiLogo).then((poi) => {
                    if (poi) {
                        poi.itemIcon = this.selectedPoiIcon;
                        this.poiSaved.emit(poi);
                        this.isDialogVisible = false;
                    }
                });
            }
        }

    }

    /**
     * initPoi()
     * */
    public initPoi(): void {
        this.poi = {
            id: 0,
            namePoi: '',
            infoTxt: '',
            blogUrl: '',
            videoUrl: '',
            longitude: this.poiCoordinates ? this.poiCoordinates.getLon() : 0,
            latitude: this.poiCoordinates ? this.poiCoordinates.getLat() : 0,
            deleted: false,
            dateDeleted: null,
            imagePoi: null,
            itemIcon: this.selectedPoiIcon,
            event: {
                id: this.eventId,
            },
        };

        this.uploadImageIconUrl = 'assets/icons/no-image-available.svg';
    }

    /**
     * validatePoiForm()
     * */
    private validatePoiForm(): boolean {
        if (this.poi.namePoi === '') {
            this.poiFormErrorMessages.push(
                this.translate.instant('error.required-field.name'),
            );
            return false;
        }
        return true;
    }

    /**
     * showDialog()
     * */
    public showDialog(isNew: boolean, coordinates?: any): void {
        this.poiCoordinates = coordinates ? coordinates : null;
        this.isNew = isNew;
        if (isNew) {
            this.initPoi();
            this.poiRacingFormTitle = this.translate.instant('general.create') + ' POI';
        } else {
            this.ngOnChanges();
            this.poiRacingFormTitle = this.translate.instant('general.edit') + ' POI';
        }
        this.isDialogVisible = true;
    }

    /**
     * onImageChange()
     * */
    public onImageChange(event: any): void {
        const imageReader = new FileReader();
        this.poiLogo = event.target.files[0];
        if (event.target.files && event.target.files.length) {
            imageReader.onload = (readerEvent: any) => {
                this.uploadImageIconUrl = readerEvent.target.result;
                this.poi.imagePoi = null;
            };

            imageReader.readAsDataURL(event.target.files[0]);
        }
    }

}
