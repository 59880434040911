//import * as moment from 'moment';
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";

/**
 * Pipe that transforms any date expressed in UTC to the user's timezone
 * Format parameter especification:
 * https://angular.io/api/common/DatePipe
 */
@Pipe({ name: "usertime" })
export class UserTimePipe implements PipeTransform {
    constructor(
        private datePipe: DatePipe,
        private authService: AuthenticationService
    ) {
    }

    toUserTime(ts: number) {
        let gmtOffset =
            moment(ts).tz(this.authService.user.timeZone).utcOffset() *
            60 *
            60;
        let gmtOffsetSeconds = gmtOffset;
        return ts + gmtOffsetSeconds;
    }

    /**
     *
     * @param value Given a date object, return the Iso string representation of this time in UTC
     */
    toUTCISOString(value: any) {
        return this.transform(value, "y-MM-ddTHH:mm:ss") + ".000Z";
    }

    /**
     * Given a date that is suposed to be in the user time zone (but is in the browser time zone), return the date in UTC
     * e.g. user is seeing 12:00 in the calendar input, he is thinking in the 12:00 in Moscow but the browser is using Madrid time.
     * @param value
     */
    calendarDateToUTC(value: any) {
        let gmtOffset =
            moment(value).tz(this.authService.user.timeZone).utcOffset() *
            60 *
            1000;
        let browserGMTOffset = new Date(value).getTimezoneOffset() * 60 * 1000 * -1;
        return new Date(new Date(value).getTime() + browserGMTOffset - gmtOffset);
    }

    /*
     * Inverse of calendarDateToUTC
     */
    utcToCalendarDate(value: any) {
        let gmtOffset =
            moment(value).tz(this.authService.user.timeZone).utcOffset() *
            60 *
            1000;
        let browserGMTOffset = new Date(value).getTimezoneOffset() * 60 * 1000 * -1;
        return new Date(new Date(value).getTime() - browserGMTOffset + gmtOffset);
    }

    transform(value: any, format?: string, gmtOffset?: number): string | null {
        if (!value) {
            return null;
        }
        //GMT offset for the user is represented in hours. Transform it to miliseconds
        gmtOffset =
            (gmtOffset != null
                ? gmtOffset
                : moment(value)
                    .tz(this.authService.user.timeZone)
                    .utcOffset()) *
            60 *
            1000;
        if (!format) format = "short";
        //getTimezoneOffset represents the number of minutes to be added to a date represented in the user time zone to obtain the UTC value
        let browserGMTOffset = new Date(value).getTimezoneOffset() * 60 * 1000 * -1;
        if (typeof value === "number") {
            //Date expressed in miliseconds since UTC

            value = value - browserGMTOffset + gmtOffset;
            return this.datePipe.transform(value, format);
        }
        if (typeof value === "string") {
            let s = value as string;
            if (s.indexOf("GMT") == -1) {
                console.error("Time " + s + " is not GMT formated");
                return null;
            }
            let parts = s.split("GMT");
            if (parts.length > 1 && parts[1].trim().length != 0 && parts[1] != "+0") {
                console.error(
                    "Time " + s + " is not GMT+0. It is " + s.split("GMT")[1]
                );
                return null;
            }
            let d = new Date(value);
            value = d.getTime() - browserGMTOffset + gmtOffset;
            return this.datePipe.transform(value, format);
        } else {
            //Assume it's a date object
            value = value.getTime() - browserGMTOffset + gmtOffset;
            return this.datePipe.transform(value, format);
        }
    }
}
