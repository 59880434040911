<!-- [rows]="10" -->
<!-- id="table" -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
	[rows]='paginationRequest.pageSize' [totalRecords]='paginationResult.filteredEntities'
	[rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [loading]="loading" [rowHover]="true"
	[showCurrentPageReport]="true" [autoLayout]="true" currentPageReportTemplate="
      {{ 'table.showing' | translate }} {first}
      {{ 'table.to' | translate }} {last}
      {{ 'table.of' | translate }} {totalRecords}
      {{ 'table.entries' | translate}}" class="custom-table" styleClass="p-datatable-striped"
	[globalFilterFields]="['name','brand.name','model.name','version.name','type.name','frameNumber','status.name','billingAccount.name']"
	[filters]="vehicleTableFilters" sortField="name" [sortOrder]="1">

	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.vehicle.vehiclesManagement' | translate }}
		</div>
	</ng-template>
	<!-- #endregion -->

	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

			<!-- CAR LICENSE PLATE -->
			<th pSortableColumn="name">
				<div class='d-flex align-items-center h-100'>
					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
						{{ 'control-panel.vehicles.licensePlate' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="name"></p-sortIcon>
							<p-columnFilter field="name" type="text" matchMode="contains"
								placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- BRAND -->
			<th pSortableColumn="model.brand">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.brands.brand' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN SORT && FILTER ICONS -->
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="model.brand"></p-sortIcon>
							<p-columnFilter field="brand" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="brandList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div class="p-multiselect-brand-option">
												<img *ngIf='option?.logo?.imageEncoded' id='uploadedImage' alt=''
													[src]="'option.logo.imageEncoded'"
													[style]="{'vertical-align':'middle', 'max-height':'32px', 'width':'32px', 'margin-right':'10px'}">
												<img *ngIf='!option?.logo?.imageEncoded' id='uploadedImage' alt=''
													[src]="'assets/icons/no-image-available.svg'"
													[style]="{'vertical-align':'middle', 'max-height':'32px', 'width':'32px', 'margin-right':'10px'}">
												<span class="ml-1">{{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
			</th>

			<!-- MODEL -->
			<th pSortableColumn="model">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.models.model' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN SORT && FILTER ICONS -->
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="model"></p-sortIcon>
							<p-columnFilter field="model" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="modelList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div class="p-multiselect-model-option">
												<span class="ml-1">{{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
			</th>

			<!-- VERSION -->
			<th pSortableColumn="version">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.versions.version' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN SORT && FILTER ICONS -->
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="version"></p-sortIcon>
							<p-columnFilter field="version" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="versionList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div class="p-multiselect-version-option">
												<span class="ml-1">{{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
			</th>

			<!-- FRAME NUMBER -->
			<th pSortableColumn="frameNumber">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.vehicles.frameNumber' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN SORT && FILTER ICONS -->
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="frameNumber"></p-sortIcon>
							<p-columnFilter field="frameNumber" type="text" matchMode="contains"
								placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- STATUS -->
			<th *ngIf="isVehicleStateGranted" pSortableColumn="status">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.vehicleStatus.vehicleStatus' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="status"></p-sortIcon>
							<p-columnFilter field="status" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="vehicleStatusList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div class='d-flex row-cols-2 w-100 align-items-center'>
												<div class="status-marcha" [style]="{'--color': option.color }"></div>
												<div>{{ option.name }}</div>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- DRIVER CODE -->
			<th pSortableColumn="driver.code">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'drivers.driverCode' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="driver.code"></p-sortIcon>
						<p-columnFilter field="driverCode" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- DRIVER -->
			<th pSortableColumn="driver.user.name">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'drivers.driverName' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="driver.user.name"></p-sortIcon>
						<p-columnFilter field="driverName" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- BILLING CODE -->
			<th pSortableColumn="billingAccount.code">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.code' | translate }} {{ 'tableManagement.companies.company' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="billingAccount.code"></p-sortIcon>
							<p-columnFilter field="billingAccount" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="companiesList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="code" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.code}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- BILLING -->
			<th pSortableColumn="billingAccount.name">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.companies.company' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="billingAccount.name"></p-sortIcon>
							<p-columnFilter field="billingAccount" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="companiesList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- DELEGATION CODE -->
			<th pSortableColumn="subDelegation.delegation.code">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.code' | translate }} {{ 'tableManagement.delegations.delegation' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="subDelegation.delegation.name"></p-sortIcon>
							<p-columnFilter field="delegation" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="delegationList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="code" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.code}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- DELEGATION -->
			<th pSortableColumn="subDelegation.delegation.name">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.delegations.delegation' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="subDelegation.delegation.name"></p-sortIcon>
							<p-columnFilter field="delegation" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="delegationList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- SUBDELEGATION amagada de moment columna  -->
			<!--	<th pSortableColumn="subDelegation">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.subdelegations.subdelegation' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="subDelegation"></p-sortIcon>
							<p-columnFilter field="subDelegation" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="subdelegationList"
										placeholder="{{ 'general.filters.any' | translate }}"
										(onChange)="filter($event.value)" optionLabel="name" optionValue="id">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">({{option.code}}) {{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>

				</div>
			</th>
 		-->

			<!-- CECO CODE -->
			<th pSortableColumn="ceco.code">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.code' | translate }} {{ 'drivers.table.ceco' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="ceco.description"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="ceco" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
								[hideOnClear]="true">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<!--[onLazyLoad]="getCecoListLazyLoad"  [lazy]="true"
									onLazyLoad -- serveix per carregar les dades amb paginació al servidor,
									es el mateix event lazyLoad que les ptable
									en els dropdowns i multiselect no esta disponible fins versio primeng 15 -->
									<p-multiSelect [ngModel]="value" (onChange)="filter($event.value);" [filter]="true"
										[options]="cecoList" placeholder="{{ 'general.filters.any' | translate }}"
										[virtualScroll]="true" itemSize="30" optionLabel="code" optionValue="id"
										[showToggleAll]="false" [panelStyle]="{'min-width': '350px'}">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.code}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- CECO -->
			<th pSortableColumn="ceco.description">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'drivers.table.ceco' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="ceco.description"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="ceco" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
								[hideOnClear]="true">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<!--[onLazyLoad]="getCecoListLazyLoad"  [lazy]="true"
									onLazyLoad -- serveix per carregar les dades amb paginació al servidor,
									es el mateix event lazyLoad que les ptable
									en els dropdowns i multiselect no esta disponible fins versio primeng 15 -->
									<p-multiSelect [ngModel]="value" (onChange)="filter($event.value);" [filter]="true"
										[options]="cecoList" placeholder="{{ 'general.filters.any' | translate }}"
										[virtualScroll]="true" itemSize="30" optionLabel="description" optionValue="id"
										[showToggleAll]="false" [panelStyle]="{'min-width': '350px'}">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.description}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- RENTING COMPANY -->
			<th pSortableColumn="financing.provider.name">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.financing' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="financing.provider.name"></p-sortIcon>
							<!--<p-columnFilter field="financingProvider" type="text" matchMode="contains"
								placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
							</p-columnFilter>-->
						</div>
					</div>
				</div>
			</th>

			<!-- RENTING START DATE -->
			<th pSortableColumn="financing.startDate">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.start' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="financing.startDate"></p-sortIcon>
						</div>
					</div>
				</div>
			</th>

			<!-- RENTING END DATE -->
			<th pSortableColumn="financing.endDate">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.end' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="financing.startDate"></p-sortIcon>
						</div>
					</div>
				</div>
			</th>

			<!-- RENTING CANCELLATION DATE -->
			<th pSortableColumn="financing.cancellationDate">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.cancelationDate' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="financing.cancellationDate"></p-sortIcon>
						</div>
					</div>
				</div>
			</th>

			<!-- RENTING VALUE -->
			<th pSortableColumn="financing.monthlyFee">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.monthlyFee' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="financing.monthlyFee"></p-sortIcon>
							<p-columnFilter field="financingMonthlyFee" type="numeric"
								placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- VAT -->
			<th>
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.financing.monthlyFee' | translate }}
						
					</div>
				</div>
			</th>

			<!-- DATE REGISTERED -->
			<th pSortableColumn="dateRegistered">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.createDate' | translate }}
						
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="dateRegistered"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="dateRegistered" matchMode="in" display="menu"
							[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
							[hideOnClear]="true">
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-calendar [(ngModel)]="startDateCreated" (onChange)="filter($event.value);" placeholder="{{ 'general.startDate' | translate }}" dateFormat="yy-mm-dd"></p-calendar>
								<p-calendar [(ngModel)]="endDateCreated" (onChange)="filter($event.value);" placeholder="{{ 'general.endDate' | translate }}" dateFormat="yy-mm-dd"></p-calendar>
							</ng-template>
						</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- DISABLED DATE -->
			<th pSortableColumn="disabledDate">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.disabledDate' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="disabledDate"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="disabledDate" matchMode="in" display="menu"
							[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
							[hideOnClear]="true">
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-calendar [(ngModel)]="startDateDisabled" (onChange)="filter($event.value);" placeholder="{{ 'general.startDate' | translate }}" dateFormat="yy-mm-dd"></p-calendar>
								<p-calendar [(ngModel)]="endDateDisabled" (onChange)="filter($event.value);" placeholder="{{ 'general.endDate' | translate }}" dateFormat="yy-mm-dd"></p-calendar>
							</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="enabled">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="enabled"></p-sortIcon>
						<p-columnFilter field="enabled" type="boolean" display="menu"
							[showApplyButton]="false"></p-columnFilter>
					</div>

				</div>
			</th>

		</tr>
	</ng-template>
	<!-- #endregion  -->

	<!-- #region TABLE BODY DEFINITION -->
	<ng-template pTemplate="body" let-vehicle>
		<tr class='p-selectable-row custom-rows' (dblclick)='editVehicle(vehicle)'>
			<td>{{vehicle.name}}</td> <!-- matricula -->

			<td>{{!vehicle.model.brand ? '' : vehicle.model.brand.name}}</td>

			<td>{{!vehicle.model ? '' : vehicle.model.name}}</td>

			<td>{{!vehicle.version ? '' : vehicle.version.name}}</td>

			<td>{{vehicle.frameNumber}}</td>

			<td *ngI="isVehicleStateGranted">
				<div class='d-flex row-cols-2 w-100 align-items-center'>
					<div class="status-marcha" [style]="{'--color': vehicle.status.color }"></div>
					<div>{{ vehicle.status.name }}</div>
				</div>
			</td>

			<td *ngIf="vehicle.driver !== null; else na">
				{{vehicle.driver.code}}
			</td>

			<td *ngIf="vehicle.driver !== null; else na">
				{{vehicle.driver.user.surname1}}
				{{vehicle.driver.user.surname2}} {{vehicle.driver.user.name}}
			</td>

			<td>{{vehicle.billingAccount.code}}</td>

			<td>{{vehicle.billingAccount.name}}</td>

			<td *ngIf="vehicle.delegation">{{vehicle.delegation.code}}</td>
			<td *ngIf="vehicle.subDelegation && !vehicle.delegation">{{vehicle.subDelegation.delegation.code}}</td>
			<td *ngIf="!vehicle.subDelegation && !vehicle.delegation">N/A</td>

			<td *ngIf="vehicle.delegation">{{vehicle.delegation.name}}</td>
			<td *ngIf="vehicle.subDelegation && !vehicle.delegation">{{vehicle.subDelegation.delegation.name}}</td>
			<td *ngIf="!vehicle.subDelegation && !vehicle.delegation">N/A</td>

			<!--
			<td *ngIf="vehicle.vehicleAssignments.length > 0; else na">
				{{vehicle.vehicleAssignments[0].subDelegation.name}}</td>
			-->

			<td *ngIf="vehicle.ceco !== null; else na">
				{{vehicle.ceco.code}}</td>

			<td *ngIf="vehicle.ceco !== null; else na">
				{{vehicle.ceco.description}}</td>

			<td *ngIf="vehicle.financing !== null; else na">
				{{vehicle.financing.provider.name}}</td>

			<td *ngIf="vehicle.financing !== null; else na">
				{{vehicle.financing.startDate}}</td>

			<td *ngIf="vehicle.financing !== null; else na">
				{{vehicle.financing.endDate}}</td>

			<td *ngIf="vehicle.financing !== null; else na">
				{{vehicle.financing.cancellationDate}}</td>

			<td *ngIf="vehicle.financing !== null; else na">
				{{vehicle.financing.monthlyFee}}</td>

			<td *ngIf="vehicle.vehicleAssignments.length > 0; else na">
				{{vehicle.vehicleAssignments[0].iva}}</td>
			
			<td>{{vehicle.dateRegistered | date: 'yyyy-MM-dd'}}</td>
			<td>{{vehicle.disabledDate | date: 'yyyy-MM-dd'}}</td>


			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i class="pi"
						[ngClass]="{'true-icon pi-check-circle': vehicle.enabled, 'false-icon pi-times-circle': !vehicle.enabled}"></i>
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion -->

	<ng-template #na>
		<td>N/A</td>
	</ng-template>

	<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="23" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!------ FOOTER ----------------------------------------------------------------------------->
	<ng-template pTemplate="footer">
		<tr>
			<td colspan="23" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD RACING EVENT BUTTON -->
					<app-table-add-button *ngIf="isCreateGranted" [text]=" 'tableManagement.vehicle.vehicle' | translate "
						(btnClick)="createVehicle()"></app-table-add-button>

					<!-- EXCEL BUTTON -->
					<app-table-excel-button class="buttonTableExcel"
						(btnClick)="exportExcel()"></app-table-excel-button>
				</div>
			</td>
		</tr>
	</ng-template>

</p-table>

<app-inventory-vehicle-form [clientId]="selectedClient">
</app-inventory-vehicle-form>
<!-- <app-vehicle-info-card></app-vehicle-info-card> -->
