import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { Severity } from "app/services/notifications-service/notifications.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

import Financing = Rest.Financing;
import { Subject } from "rxjs";
import { RestVehicleInventory } from "../rest_vehicle-inventory";

@Injectable({
	providedIn: 'root'
 })
export class FinancingService extends EntityService<Financing> {
    client: Rest.RestApplicationClient;
	 //created = new Subject<Rest.Financing>();
	 refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    createFinancing(financing: string, document?: any): Rest.RestResponse<Financing> {
        const formData = new FormData();
        formData.append('financing', financing);

        if (document) {
            formData.append('documentFile', document);
        }

        return this.http
            .post(environment.webApiBaseUrl + 'api/inventory/financing', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }

    updateFinancing(financing: string, document?: any): Rest.RestResponse<Financing> {
        const formData = new FormData();
        formData.append('financing', financing);

        if (document) {
            formData.append('documentFile', document);
        }

        return this.http
            .post(environment.webApiBaseUrl + 'api/inventory/financing/update', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }

    // @ts-ignore
    create(arg0: Rest.Financing): Rest.RestResponse<Financing> {
        try {
            return this.client.Financing_create(arg0);
        } catch (error) {
            console.error("Error creating Financing:", error);
            throw error;
        }
    }

    // @ts-ignore
    update(arg0: Rest.Financing): Rest.RestResponse<Financing> {
        try {
            return this.client.Financing_update(arg0);
        } catch (error) {
            console.error("Error updating Financing:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Financing>> {
        try {
            return this.client.Financing_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting Financing page:", error);
            throw error;
        }
    }

    // @ts-ignore
    getDocument(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Financing> {
        try {
            return this.client.Financing_getDocument(arg0, queryParams);
        } catch (error) {
            console.error("Error getting Financing by document:", error);
            throw error;
        }
    }

    // @ts-ignore
    delete(arg0: Financing[]): Rest.RestResponse<void> {
        try {
            return this.client.Financing_delete(arg0);
        } catch (error) {
            console.error("Error deleting Financing:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
      ): Rest.RestResponse<void> {
        return this.client.Financing_changeStatus(arg0, queryParams);
      }

	createDocument(financingId: number, documentId: number): Rest.RestResponse<Rest.Financing> {
		let queryParams = {
			financingId: financingId,
			documentId: documentId
		}
		return this.client.Financing_addDocument(queryParams);
	}
}
