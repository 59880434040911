<div class="container-fluid mx-0 px-0 custom-map-main-container">
  <ng-container>
    <div [ngStyle]="{'display':(display?'block':'none')}" [attr.style]="customMapStyle" #mymap id="{{mapId}}" class="custom-map">
      <input type="hidden" id="vehicleClickId" value="" />


    </div>
  </ng-container>
</div>

<!-- Dialog confirm delete POI -->
<p-confirmDialog key="deleteMarker" icon="fa fa-question-circle" appendTo="body"></p-confirmDialog>

<!-- Dialog to many markers -->
<p-confirmDialog key="toManyMarkers" rejectVisible="false" icon="fa fa-exclamation-circle" appendTo="body" #cd>
  <p-footer>
    <button type="button" pButton icon="fa-check" label="Ok" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

