import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { VehicleUsageService } from 'app/rest/vehicleUsage.service';

@Component({
    selector: 'app-vehicle-usage-form',
    templateUrl: './vehicle-usage-form.component.html',
    styleUrls: ['./vehicle-usage-form.component.scss'],
    providers: [VehicleUsageService, IconService],
    encapsulation: ViewEncapsulation.None,
})
export class VehicleUsageFormComponent
    extends EntityFormComponent<Rest.VehicleUsage>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    active: boolean;

    constructor(
        private vehicleUsageService: VehicleUsageService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        public translateService: TranslateService,
    ) {
        super(vehicleUsageService, notificationsService, i18n, authenticationService);
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ClientFormComponent
     */
    beforeShow() {
        this.active = !this.entity.disabled;
        
        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.vehicleUsage.createVehicleUsage');
        } else {
            this.title = this.translateService.instant('tableManagement.vehicleUsage.editVehicleUsage');
        }
    }
}
