import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-sos-gmt200-form',
  templateUrl: './sos-gmt200-form.component.html',
  styleUrls: ['./sos-gmt200-form.component.css'],
})
export class SosGmt200FormComponent extends ServerValidatedFormComponent implements OnInit {

  @Input() sosParameters: Rest.SOSParametersGMT200;
  m:Rest.ModeSos = "DISABLE";
  
  modeOptions: any = [
    {label: "Disable SOS function.",value:"DISABLE"},
    {label: "Send the current position to the backend server only.",value:"GPRS"},
    {label: "Send the current position as a google maps hyperlink to the <SOS Number> via SMS.",value:"SMS"},           
  ];
 
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.sosParameters);
    }
  }

  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }

}
