import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { VehicleAccessoryService } from 'app/modules/vehicle-inventory/services/vehicle-accessory.service';
import { Rest } from 'app/rest/rest_client';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-inventory-vehicle-accessory-list',
	templateUrl: './inventory-vehicle-accessory-list.component.html',
	styleUrls: ['./inventory-vehicle-accessory-list.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class InventoryVehicleAccessoryListComponent extends EntityListComponent<Rest.InvVehicleAccessory> implements OnInit, OnDestroy {

	rowData: Rest.Vehicle;
	user: Rest.User;
	EnabledFilter: SelectItem[];
	selectedAccessory: Rest.InvVehicleAccessory;

	/** Params **/
	@Input() vehicle: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;

	@Input() showTitle: boolean = true;

	paginationRequest: Rest.ListPaginationRequest;
	paginationResult: Rest.Page<Rest.InvVehicleAccessory>;

	updateListSubscription: Subscription;

	// PERMISSIONS
	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isDisabledGranted: boolean = false;
	verifiedGranted: boolean = false;

	constructor(
		private vehicleAccessoryService: VehicleAccessoryService,
		protected confirmationService: ConfirmationService,
		protected notificationsService: NotificationsService,
		protected i18n: I18nService,
		protected authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected excelService: ExcelService,
		private translateService: TranslateService
	) {
		super(
			vehicleAccessoryService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime,
			excelService
		);
	}

	ngOnInit(): void {
		// console.log("[VEHICLE-ACCESSORIES-LIST] ngOnInit ***");
		super.ngOnInit();
		// this.updateListSubscription = this.vehicleAccessoryService.refreshList
		//    .subscribe(
		//       () => {
		// 		  	this.refresh();
		//    	}
		// );
		this.updateListSubscription = this.vehicleAccessoryService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				// console.log("[VEHICLE-ACCESSORIES-LIST] refreshList ***");
				// console.log(item);
				this.getList(item.startDate, item.endDate);
			}
		);

		this.setPermissions();
	}

	ngOnDestroy(): void {
		this.updateListSubscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['INV_EXTRAS_READ', 'INV_EXTRAS_UPDATE', 'INV_EXTRAS_UPDATE_VERIFIED'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('INV_EXTRAS_CREATE', user);
		this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_EXTRAS_DISABLE', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	afterLoad() {
		// console.log("[VEHICLE-ACCESSORIES-LIST] afterLoad ***");
		this.paginationResult.entities.forEach((cl: any) => {
			/*Permissions */
			cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
			cl._$update = true;
			/** */
		});
		/*Permissions */
		this.checkSelectAll();

	}

	protected beforeLoad() {
		this.paginationRequest.filters["vehicleId"] = [this.vehicle.id.toString()];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
		console.log(this.paginationRequest.filters);
	}

	public createAccessory(): void {
		this.vehicleAccessoryService.startedCreating.next();
	}

	public editVehicle(vehicle: Rest.Vehicle): void {
		this.vehicleAccessoryService.startedEditing.next(this.selectedAccessory);
	}

	excelColumns = {};
	exportExcel() {
		this.setExcelColumns();
		let fileNameTitle = this.translateService.instant('tableManagement.vehicle.vehiclesManagement');
		super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
	}
	setExcelColumns() {
		const vehicle = (entity: any) => this.vehicle === null ? '' : this.vehicle.name ?? ''
		const description = (entity: any) => entity.description ?? ''
		const installationDate = (entity: any) => entity.installationDate === null ? '' : entity.installationDate?.toISOString() ?? ''
		const desinstallationDate = (entity: any) => entity.desinstallationDate === null ? '' : entity.desinstallationDate?.toISOString() ?? ''
		const extrasType = (entity: any) => entity.extrasType?.name ?? ''
		const alarmType = (entity: any) => entity.alarmType ?? ''
		const stationCode = (entity: any) => entity.stationCode ?? ''
		const serialNumber = (entity: any) => entity.serialNumber ?? ''
		const value = (entity: any) => entity.value ?? ''
		const verified = (entity: any) => entity.verified ?? ''
		const verificationDate = (entity: any) => entity.verificationDate?.toISOString() ?? ''
		const observations = (entity: any) => entity.observations ?? ''
		const client = (entity: any) => entity.client?.name ?? ''
		const disabled = (entity: any) => !entity.disabled ?? ''

		this.excelColumns = {
			[this.translateService.instant('tableManagement.vehicle.vehicle')]: { bindField: vehicle },
			[this.translateService.instant('general.description')]: { bindField: description },
			[this.translateService.instant('tableManagement.accessories.installationDate')]: { bindField: installationDate },
			[this.translateService.instant('tableManagement.accessories.desinstallationDate')]: { bindField: desinstallationDate },
			[this.translateService.instant('general.type')]: { bindField: extrasType },
			[this.translateService.instant('tableManagement.accessories.alarmType')]: { bindField: alarmType },
			[this.translateService.instant('tableManagement.accessories.stationCode')]: { bindField: stationCode },
			[this.translateService.instant('tableManagement.accessories.serialNumber')]: { bindField: serialNumber },
			[this.translateService.instant('general.value')]: { bindField: value },
			[this.translateService.instant('tableManagement.accessories.verified')]: { bindField: verified },
			[this.translateService.instant('tableManagement.accessories.verificationDate')]: { bindField: verificationDate },
			[this.translateService.instant('general.observations')]: { bindField: observations },
			[this.translateService.instant('control-panel.client')]: { bindField: client },
			[this.translateService.instant('general.active')]: { bindField: disabled }
		}
	}

	public createVehicleAccessory(): void {
		this.vehicleAccessoryService.startedCreating.next();
	}

	public editVehicleAccessory(vehicleAccessory: Rest.InvVehicleAccessory): void {
		if (!this.isFormGranted) {
			return;
		}

		this.vehicleAccessoryService.startedEditing.next(vehicleAccessory);
	}

	public getList(startDate?: Date, endDate?: Date) {
		this.startDate = startDate;
		this.endDate = endDate;
		this.beforeLoad();
		super.refresh();
	}

}


