import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LeaderService } from 'app/rest/leader.service';
import { NoticegroupService } from 'app/rest/noticegroup.service';
import { Rest } from 'app/rest/rest_client';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/api';

@Component({
	selector: 'app-maintenance-execution-alert',
	templateUrl: './maintenance-execution-alert.component.html',
	styleUrls: ['./maintenance-execution-alert.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class MaintenanceExecutionAlertComponent extends EntityListComponent<Rest.InvLeader> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null;
	title: string;
	locale: string = 'es-ES';
	display: boolean = false;

	selectedResponsibles: Rest.InvLeader[] = [];
	responsibleList: Rest.InvLeader[] = [];

	responsibleRequestContext: any;
	sendSMS: Boolean = false;
	sendWeb: Boolean = false;
	sendEmail: Boolean = false;
	alertFormDisabled: Boolean = false;
	deleting: Boolean = false;
	saving: Boolean = false;

	tagEvents = ['MAINTENANCE_ALERT_TIME', 'MAINTENANCE_EXPIRED_TIME', 'MAINTENANCE_ALERT_KM', 'MAINTENANCE_EXPIRED_KM'];
	placeHolder: string = '';
	dbNoticeGroup: Rest.NoticeGroup = null;

	constructor(
		private leaderService: LeaderService,
		protected confirmationService: ConfirmationService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected excelService: ExcelService,
		public translateService: TranslateService,
		public noticegroupService: NoticegroupService
	) {
		super(
			leaderService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime,
			excelService
		);
	}

	ngOnInit() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] ngOnInit ***');
		super.ngOnInit();
		this.display = true;
		this.lazyLoad();
		this.placeHolder =
			this.translateService.instant('general.select') + ' ' +
			this.translateService.instant('alerts.receivers') + '...';
	}

	/* En PrimeNg 13 no funciona el lazyLoad en els Dropdown i multiselect, per tant
	   forcem el load des de ngOnInit */
	lazyLoad() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] lazyLoad ***');
		if (this.vehicle) {
			this.beforeLoad();
			this.getList();
		}
	}

	beforeLoad() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] beforeLoad ***');
		this.alertFormDisabled = true;
	}

	getList() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] getList ***');
		this.leaderService.getList(this.authenticationService.user.client.id).then((p) => {
			this.responsibleList = p;
			// console.log('this.responsibleList...');
			// console.log(this.responsibleList);
			this.afterLoad();
		});
	}

	afterLoad() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] afterLoad ***');
		// console.log(this.responsibleList);
		this.responsibleList = this.responsibleList.map((leader) => ({
			...leader,
			formatResponsibleLabel: `${leader.user.surname1} ${leader.user.surname2}, ${leader.user.name}`,
		}));
		// console.log(this.responsibleList);
		//get selectedReceivers ans send configuration
		this.getNoticeGroup();
	}

	toggleWeb(event) {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] toggleWeb ***');
		// console.log(event);
		this.sendWeb = event.checked;
	}
	toggleSMS(event) {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] toggleSMS ***');
		// console.log(event);
		this.sendSMS = event.checked;
	}
	toggleEmail(event) {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] toggleEmail ***');
		// console.log(event);
		this.sendEmail = event.checked;
	}

	saveChanges() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] saveChanges ***');
		this.sendNoticeGroup();
	}

	deleteAlerts() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] deleteAlerts ***');
		this.deleteNoticeGroup();
	}

	sendNoticeGroup() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] sendNoticeGroup ***');
		// console.log(this.selectedResponsibles);
		this.saving = true;
		let visibleUsers: Rest.User[] = [];
		this.selectedResponsibles.forEach((x) => visibleUsers.push(x.user));
		let data = {
			id: this.dbNoticeGroup ? this.dbNoticeGroup.id : 0,
			name: 'Maintenance alerts vehicle ' + this.vehicle.name,
			description: 'Maintenance alerts vehicle ' + this.vehicle.name,
			owner: { id: this.authenticationService.user.id },
			enabled: true,
			notifyBySMS: this.sendSMS,
			notifyByApp: false,
			notifyByWeb: this.sendWeb,
			notifyByEmail: this.sendEmail,
			visibleVehicles: [{ id: this.vehicle.id }],
			visibleVehiclesGroups: null,
			visibleFleets: null,
			visibleUsers: visibleUsers,
			tag_events: this.tagEvents,
		};
		// console.log(data);
		if (this.dbNoticeGroup) {
			this.noticegroupService.update(data).then((p) => {
				// console.log('==> Update noticeGroup DONE!');
				// console.log(p);
				this.dbNoticeGroup = p;
				this.saving = false;
				this.notificationsService.add(
					Severity.success,
					this.translateService.instant('success.success') + "!",
					this.translateService.instant('general.updateSuccessfull'));
			});
		} else {
			this.noticegroupService.create(data).then((p) => {
				// console.log('==> Create noticeGroup DONE!');
				// console.log(p);
				this.dbNoticeGroup = p;
				this.saving = false;
				this.notificationsService.add(
					Severity.success,
					this.translateService.instant('success.success') + "!",
					this.translateService.instant('general.createSuccessfull'));
			});
		}
	}

	getNoticeGroup() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] getNoticeGroup ***');
		this.noticegroupService.getNoticeGroupByTagsAndVehicle(this.vehicle.id, this.tagEvents).then((p) => {
			if (p.length > 0) {
				this.placeHolder = ''; //per defecte posara els seleccionats
				this.dbNoticeGroup = p[0];
				// console.log("NoticeGroup...");
				// console.log(this.dbNoticeGroup);
				this.sendEmail = this.dbNoticeGroup.notifyByEmail;
				this.sendSMS = this.dbNoticeGroup.notifyBySMS;
				this.sendWeb = this.dbNoticeGroup.notifyByWeb;
				this.dbNoticeGroup.visibleUsers.forEach((v) => {
					let responsible = this.responsibleList.filter((x) => x.user.id === v.id);
					if (responsible.length > 0) {
						this.selectedResponsibles.push(responsible[0]);
					}
				});
				// console.log("selectedResponsibles...");
				// console.log(this.selectedResponsibles);
			}
			this.alertFormDisabled = false;
		});
	}

	deleteNoticeGroup() {
		// console.log('[MAINTENANCE-EXECUTION-ALERT] deleteNoticeGroup ***');
		this.deleting = true;
		let confirmationMessage =
			'¿' + this.translateService.instant('control-panel.delete-dialog.message') + ' ' +
			this.translateService.instant('alerts.deleteMsg') + '?';

		this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				this.noticegroupService
					.deleteMaintenance(this.dbNoticeGroup.id)
					.then((isDeleted: boolean) => {
						if (isDeleted) {
							// console.log("=> DELETED OK!");
							this.notificationsService.add(
								Severity.success,
								this.translateService.instant('success.success') + "!",
								this.translateService.instant('general.deleteSuccessfull'));
							this.selectedResponsibles = [];
							this.sendEmail = false;
							this.sendSMS = false;
							this.sendWeb = false;
							this.dbNoticeGroup = null;
							this.placeHolder =
								this.translateService.instant('general.select') + ' ' +
								this.translateService.instant('alerts.receivers') + '...';
							this.deleting = false;
						}
					});
			},
			reject: () => {
				this.deleting = false;
			},
		});
	}
}
