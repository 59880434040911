<!-- USERS PRIMENG DATATABLE -->
<p-table
	#dt
[value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

	<!-- TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'control-panel.users.usersTable' | translate }}
		</div>
	</ng-template>

	<!-- TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

			<!-- SELECT -->
			<th class="checkbox-column">
				<div class="d-flex justify-content-center">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</div>
			</th>

			<!-- NAME -->
			<th pSortableColumn="name">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.name' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="name"></p-sortIcon>
						<p-columnFilter field="name"
										type="text"
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display="menu"
										[showMatchModes]="true"
										[showOperator]="false"
										[showAddButton]='false'
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- FIRST SURNAME -->
			<th pSortableColumn="surname1">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'userProfile.firstSurname' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="firstSurname"></p-sortIcon>
						<p-columnFilter field="surname1"
										type="text"
										matchMode='contains'
										placeholder="Search"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]='false'
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- SECOND SURNAME -->
			<th pSortableColumn="surname2">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'userProfile.secondSurname' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="surname2"></p-sortIcon>
						<p-columnFilter field="surname2"
										type="text"
										matchMode="contains"
										placeholder="{{ 'general.search' | translate }}"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]="false"
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- USERNAME -->
			<th pSortableColumn="userName">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'userProfile.username' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="username"></p-sortIcon>
						<p-columnFilter field="userName"
										type="text"
										matchMode="contains"
										placeholder="{{ 'general.search' | translate }}"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]="false"
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- EMAIL -->
			<th pSortableColumn="email">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'userProfile.email' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="email"></p-sortIcon>
						<p-columnFilter field="email"
										type="text"
										matchMode="contains"
										placeholder="{{ 'general.search' | translate }}"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]="false"
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- EXPIRATION DATE -->
			<th pSortableColumn='accountExpiry'>
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.users.expirationDate' | translate }}
					</div>

					<!-- COLUMN SORT ICON -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="expirationDate"></p-sortIcon>
					</div>

				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="enabled">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="enabled"></p-sortIcon>
						<p-columnFilter field="enabled"
										type="boolean"
										display="menu"
										[showApplyButton]="false"
						></p-columnFilter>
					</div>

				</div>
			</th>

		</tr>
	</ng-template>

	<!-- TABLE BODY -->
	<ng-template pTemplate="body" let-user>
		<tr class="p-selectable-row custom-rows" (dblclick)="showUserFormComponent(user)">

			<!-- SELECT -->
			<td>
				<div class="d-flex justify-content-center">
					<p-tableCheckbox [value]="user"></p-tableCheckbox>
				</div>
			</td>

			<!-- NAME -->
			<td>
				{{user.name}}
			</td>

			<!-- FIRST SURNAME -->
			<td>
				{{user.surname1}}
			</td>

			<!-- SECOND SURNAME -->
			<td>
				{{user.surname2}}
			</td>

			<!-- USERNAME -->
			<td>
				{{user.userName}}
			</td>

			<!-- EMAIL -->
			<td>
				{{user.email}}
			</td>

			<!-- EXPIRATION DATE -->
			<td>
				<div class="d-flex justify-content-center">
					{{user.accountExpiry | date: 'dd/MM/yy'}}
				</div>
			</td>

			<!-- ACTIVE -->
			<td class="p-text-center">
				<div class="d-flex justify-content-center align-items-center">
					<i class="pi"
					   [ngClass]="{'true-icon pi-check-circle': user.enabled, 'false-icon pi-times-circle': !user.enabled}"></i>
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="9" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- FOOTER -->
	<ng-template pTemplate="footer">
		<tr>
			<td colspan="8" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">

					<!-- ADD MAINTENANCE PLAN BUTTON -->
					<app-table-add-button [text]=" 'control-panel.users.user' | translate "
										  (btnClick)="create()"
										  [isDisabled]="!user.canManageUsers"
					></app-table-add-button>

					<!-- CHANGE STATUS BUTTON -->
					<app-table-change-status-button (btnClick)="changeStatus()"
													[isDisabled]="!user.canManageUsers || selectedEntities.length === 0"
					></app-table-change-status-button>

				</div>
			</td>
		</tr>
	</ng-template>

</p-table>

<!-- CONFIRMATION DIALOG -->
<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle"></p-confirmDialog>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
	<app-change-status-dialog [items]=" 'control-panel.status-dialog.users' | translate "
							  [display]="true"
							  [service]=userService
							  [entitiesList]=selectedEntities
							  (return)="refreshList($event)"
							  (close)="closeStatusDialog($event)"
	></app-change-status-dialog>
</div>

<!-- USER FORM -->
<app-user-form (deviceSelected)="showDeviceFormComponent($event)"></app-user-form>

<!-- DEVICE FORM -->
<app-device-form (userSelected)="showUserFormComponent($event)"></app-device-form>
