<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Network Select
                    (AT+GTNTS)</span>
            </div>
        </div>
    </p-header>

    <div class="row mt-3">
        <p-checkbox binary="true" label="Enable" [(ngModel)]="ntsParameters.enabled">
        </p-checkbox>
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="White Oper1">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.whiteOper1" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="White Oper2">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.whiteOper2" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="White Oper3">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.whiteOper3" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Black Oper1">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.blackOper1" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Black Oper2">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.blackOper2" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Black Oper3">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="ntsParameters.blackOper3" maxlength="10" class="txtbStyle"/>
                </div>
            </div>
        </div>

    </div>
</p-accordionTab>