import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-bearer-setting-information-gl320m',
  templateUrl: './bearer-setting-information-gl320m.component.html',
  styleUrls: ['./bearer-setting-information-gl320m.component.css'],
})
export class BearerSettingInformationGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  networkOptions: any = [
    { label: "Auto. (LTE & GSM)", value: "AUTO" },
    { label: "GSM only", value: "GSM_ONLY" },
    { label: "LTE only", value: "LTE_ONLY" },
    { label: "GSM First. (LTE & GSM)", value: "GSM_FIRST" },
 ];

 lteOptions: any = [
   { label: "Cat-M1 & Cat-NB2. (Cat-M1 First)", value: "M1_NB2"},
   { label: "Cat-NB2 & Cat-M1. (Cat-NB2 First)", value: "NB2_M1"},
   { label: "Cat-M1", value: "M1"},
   { label: "Cat-NB2", value: "NB2"},
   { label: "Cat-NB2 first", value: "NB2_FIRST"},
   { label: "Cat-NB2 only", value: "NB2_ONLY"}
 ]

 authenticationOptions: any = [
  { label: "No authentication", value: "NONE"},
  { label: "PAP authentication", value: "PAP"},
  { label: "CHAP authentication", value: "CHAP"},
  { label: "PAP or CHAP authentication", value: "PAP_OR_CHAP"},
 ]

 netregOptions: any = [
  { label: "Disable manually register the network", value: "DISABLE"},
  { label: "Enable manually register the network", value: "ENABLE"},
  
 ]
  @Input() bsiParameters: Rest.BearerSettingInformationGL320M;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.bsiParameters);
    }
  }

}
