<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >DETECT LAST POSITION BEFORE START (STATICREP)</span>
        </div>
  
        <div class="right">
          <p-inputSwitch [(ngModel)]="staticRep.enabled"></p-inputSwitch>
        </div>
      </div>
    </p-header>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Time Range" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="staticRep.timeRange" [min]="10" [max]="300"></p-spinner>
            <span class="ui-inputgroup-addon">10~300</span>
            <span class="ui-inputgroup-addon" >sec</span>
          </div>
        </div>
      </div>
    
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Min Detection Speed" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="staticRep.minDetectionSpeed" [min]="0" [max]="100"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon">km/h</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Detecting Times" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="staticRep.detectingTimes" [min]="1" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">1~20</span>
          </div>
        </div>
      </div>
    
    </div>
  </p-accordionTab>