import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';


import { Rest_logistics } from 'app/modules/task/Logistics/services/rest_client_logistics';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';

import { IncidencesNotReadService } from 'app/modules/task/Logistics/services/incidences/incidences-nr.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationNavigationService } from '../notification-navigation.service';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [IncidencesNotReadService],
})
export class NotificationPanelComponent implements OnInit {

  pendingIncidences: boolean = false; // Flag to show if there are incidences not resolved
  hasUntreatedIncidences$: Observable<boolean>; // Observable to show if there are incidences not resolved

  nrIncidences: Rest.Page<Rest_logistics.Order>; // Stores the incidences not resolved

  constructor(
    protected incidencesNrService: IncidencesNotReadService,
    private i18n: I18nService,
    private notificationsService: NotificationsService,
    public translate: TranslateService,
    private router: Router,
    private notificationNavigationService: NotificationNavigationService,
  ) { }

  ngOnInit(): void {
    this.getNrIncidences({ first: 0, rows: 10, sortOrder: 1 });
  }

  // HEADER NOTIFICATIONS METHODS
  /**
   * Get not resolved incidences and notify user
   */
  getNrIncidences(event: LazyLoadEvent): void {
    let paginationRequest: Rest.ListPaginationRequest;
    paginationRequest = RestExt.allRecordsRequest();
    paginationRequest.sortAsc = false;
    paginationRequest.filters = {}
    paginationRequest.pageSize = event.rows;
    paginationRequest.sortAsc = event.sortOrder === 1;
    paginationRequest.offset = event.first;

    this.incidencesNrService.getPage(paginationRequest).then((incidences) => {
      this.nrIncidences = incidences;

      // Notify user of incidences not resolved
      if (this.nrIncidences.entities.length > 0) {
        this.pendingIncidences = true;

        this.hasUntreatedIncidences$ = of(this.pendingIncidences);
      }
    });
  }

  // Handle permissions to navigate to incidences
  onIncidenceClick() {
    // Hide notification panel
    this.notificationNavigationService.hide();

    // navigation handling
    this.notificationNavigationService.setFromNotificationPanel(true); // Set the flag
    this.router.navigate(['/logistics/incidences/']);
  }

}
