<p-accordionTab>

  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Tow Event Alert</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)"  [(ngModel)]="tow.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!tow.enabled>


    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="satellites Fixed" [errors]="errors.satellitesFixed">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="satellitesFixed" size="12" [(ngModel)]="tow.satellitesFixed" step="1" [min]="3" [max]="12"></p-spinner>
          <span class="ui-inputgroup-addon">3~12</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="speed threshold" [errors]="errors.speedThreshold">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="speedThreshold" size="12" [(ngModel)]="tow.speedThreshold" step="1" [min]="10" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">10~255</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="tow Duration" [errors]="errors.towDuration">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="towDuration" size="12" [(ngModel)]="tow.towDuration" step="1" [min]="30" [max]="25500"></p-spinner>
          <span class="ui-inputgroup-addon">30~25500</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="auto Reset" [errors]="errors.autoReset">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="autoReset" size="12" [(ngModel)]="tow.autoReset" step="1" [min]="30" [max]="25500"></p-spinner>
          <span class="ui-inputgroup-addon">30~25500</span>
        </div>
      </form-input>
    </div>

  </div>

</p-accordionTab>