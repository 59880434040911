<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">DEFENSE CONFIGURATION
          (DEFENSE)</span>
      </div>
    </div>
  </p-header>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Delay Duration">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="defense.delayDuration" [min]="1" [max]="60"></p-spinner>
            <span class="ui-inputgroup-addon">1~60</span>
            <span class="ui-inputgroup-addon">min</span>
          </div>
        </div>
      </div>

</p-accordionTab>