import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportListComponent } from './components/reports/report-list/report-list.component';
import { RacingListComponent } from './components/racing/racing-list/racing-list.component';
import { RealTimeBaseComponent } from './components/real-time/base/rt-base.component';
import { DevicesListComponent } from './components/device-edit-view/devices-list/devices-list.component';
import { DeviceTypeEditViewComponent } from './components/device-type-edit-view/device-type-edit-view.component';
import { FleetEditViewComponent } from './components/fleet-edit-view/fleet-tree-edit/fleet-tree-edit.component';
import { HackTranslationsComponent } from './components/hack-translations/hack-translations.component';
import {
    ConversionAlogrithmListComponent,
} from './components/conversion-algorithm/conversion-algorithm-list/conversion-algorithm-list.component';
import { MaintenancePlansListComponent } from './components/maintenance-plans-list/maintenance-plans-list.component';
import { MaintenancesListComponent } from './components/maintenances-list/maintenances-list.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PoiCategoriesListComponent } from './components/pois/poi-categories-list/poi-categories-list.component';
import { PoiListComponent } from './components/pois/poi-list/poi-list.component';
import { NoticeGroupsListComponent } from './components/notice-group/notice-groups-list/notice-groups-list.component';
import { ProfilesListComponent } from './components/profiles-list/profiles-list.component';
import { ClientsListComponent } from './components/clients/clients-list/clients-list.component';
import { RolesListComponent } from './components/roles/roles-list/roles-list.component';
import { UsersListComponent } from './components/users/users-list/users-list.component';
import { ZoneGroupsListComponent } from './components/zones/zone-groups-list/zone-groups-list.component';
import { ZoneListComponent } from './components/zones/zone-list/zone-list.component';
import { CercaliaResolver } from './services/resolves/cercalia.reolver';
import { TranslationComponent } from './components/translation-list/translation-list.component';
import { RuleListComponent } from './components/rule/rule-list/rule-list.component';
import { UtePanelComponent } from './components/ute-rondes/ute-panel/ute-panel.component';
import { MapImagePrint } from './components/real-time/map-menu/map-image-print/map-image.print';
import { AuthGuard } from './services/guards/auth/auth.guard';
import { RealTimeGuard } from './services/guards/real-time/real-time.guard';
import { UtePanelGuard } from './services/guards/ute-panel/ute-panel.guard';
import { ReportsGuard } from './services/guards/reports/reports.guard';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { ControlPanelGuard } from './services/guards/control-panel/control-panel.guard';
import { DevicesGuard } from './services/guards/control-panel/devices/devices.guard';
import { VehiclesGuard } from './services/guards/control-panel/vehicles/vehicles.guard';
import { MaintenancePlansGuard } from './services/guards/control-panel/maintenance-plans/maintenance-plans.guard';
import { ZoneGroupsGuard } from './services/guards/control-panel/zone-groups/zone-groups.guard';
import { PoiCategoryGuard } from './services/guards/control-panel/poi-category/poi-category.guard';
import {
    NotificationsGroupsGuard,
} from './services/guards/control-panel/notifications-groups/notifications-groups.guard';
import { RulesGuard } from './services/guards/control-panel/rules/rules.guard';
import { ClientsGuard } from './services/guards/control-panel/clients/clients.guard';
import { UsersGuard } from './services/guards/control-panel/users/users.guard';
import { RacingGuard } from './services/guards/racing/racing.guard';
import { TaxComponent } from './modules/tax/tax.component';
import { RtMapComponent } from './components/real-time/rt-map/rt-map.component';
import { SatMapComponent } from './modules/map/components/sat-map/sat-map.component';
import { MapGuard } from './services/guards/map/map.guard';
import { TaxGuard } from './services/guards/tax/tax-guard.guard';
import { InventoryVehiclesComponent } from './modules/vehicle-inventory/components/vehicle/inventory-vehicles.component';
import { InventoryVehiclesGuard } from './services/guards/inventory-vehicles/inventory-vehicles.guard';
import { DriversComponent } from './modules/vehicle-inventory/components/drivers/drivers.component';
import { DriversGuard } from './services/guards/drivers/drivers.guard';
import { MaintenanceFormComponent } from './components/maintenance-form/maintenance-form.component';
import { TableManagementComponent } from './modules/vehicle-inventory/components/table-management/table-management.component';
import { TableManagementGuard } from './services/guards/table-management/table-management.guard';
import { DashboardsComponent } from './modules/dashboards/dashboards.component';
import { DashboardsGuard } from './services/guards/dashboards/dashboards.guard';
import { PromotedRoutesGuard } from './services/guards/promoted-routes/promoted-routes.guard';
import { FidPromotedRoutesComponent } from './modules/fid-promoted-routes/fid-promoted-routes.component';
import { ProvidersComponent } from './modules/vehicle-inventory/components/table-management/providers-management/providers-list/providers.component';
import { VehicleUsageListComponent } from './modules/vehicle-inventory/components/table-management/vehicle-usage-management/vehicle-usage-list/vehicle-usage-list.component';
import { BrandListComponent } from './modules/vehicle-inventory/components/table-management/brands/brands-list/brand-list.component';
import { DelegationListComponent } from './modules/vehicle-inventory/components/table-management/delegations/delegation-list/delegation-list.component';
import { CompanyListComponent } from './modules/vehicle-inventory/components/table-management/companies/company-list/company-list.component';
import { CostCentreListComponent } from './modules/vehicle-inventory/components/table-management/cost-centres/cost-centre-list/cost-centre-list.component';
import { SubdelegationListComponent } from './modules/vehicle-inventory/components/table-management/subdelegations/subdelegation-list/subdelegation-list.component';
import { DataImportComponent } from './components/data-import/data-import.component';
import { CardsComponent } from './components/data-import/cards/cards.component';
import { CepsaComponent } from './components/data-import/cepsa/cepsa.component';
import { InsurancesComponent } from './components/data-import/insurances/insurances.component';
import { DriversTableComponent } from './modules/vehicle-inventory/components/drivers/drivers-table/drivers-table.component';
import { DriverInfoCardComponent } from './modules/vehicle-inventory/components/drivers/driver/driver-info-card/driver-info-card.component';
import { MaintenanceExecutionFormComponent } from './components/maintenance-execution-form/maintenance-execution-form.component';
import { MaintenanceExecutionsListComponent } from './components/maintenance-executions-list/maintenance-executions-list.component';
import { CardTypesListComponent } from './modules/vehicle-inventory/components/table-management/cardTypes/cardTypesList/cardTypes-list.component';
import { AccessoryListComponent } from './modules/vehicle-inventory/components/table-management/acessories/accessory-list/accessory-list.component';
import { FinanceTypesListComponent } from './modules/vehicle-inventory/components/table-management/financing-types/finance-types-list/finance-types-list.component';
import { WayToPayListComponent } from './modules/vehicle-inventory/components/table-management/way-to-pay/way-to-pay-list/way-to-pay-list.component';
import { VehicleStatusListComponent } from './modules/vehicle-inventory/components/table-management/vehicle-status/vehicle-status-list/vehicle-status-list.component';
import { DivisionsListComponent } from './modules/vehicle-inventory/components/table-management/divisions/divisions-list/divisions-list.component';
import { SubdivisionsListComponent } from './modules/vehicle-inventory/components/table-management/subdivisions/subdivisions-list/subdivisions-list.component';
import { InsuranceTypeListComponent } from './modules/vehicle-inventory/components/table-management/InsuranceTypes/InsuranceType-list/InsuranceType-list.component';
import { ModelListComponent } from './modules/vehicle-inventory/components/table-management/models/model-list/model-list.component';
import { VehicleVersionListComponent } from './modules/vehicle-inventory/components/table-management/vehicle-version/vehicle-version-list/vehicle-version-list.component';
import { CardListsComponent } from './modules/vehicle-inventory/components/table-management/card-lists/card-lists/card-lists.component';
import { FineListComponent } from './modules/vehicle-inventory/components/table-management/fines/fine-list/fine-list.component';
import { FineStatusListComponent } from './modules/vehicle-inventory/components/table-management/fineStatus/fineStatus-list/fineStatus-list.component';
import { CardConsumptionListComponent } from './modules/vehicle-inventory/components/table-management/cardConsumptions/cardConsumptions-list/cardConsumption-list.component';
import { RentingTypeListComponent } from './modules/vehicle-inventory/components/table-management/rentingType/list/rentingType-list.component';
import { FinancingListComponent } from './modules/vehicle-inventory/components/table-management/financing/financing-list/financing-list.component';
import { InsuranceListComponent } from './modules/vehicle-inventory/components/table-management/insurance/insurance-list/insurance-list.component';
import { InventoryHtcVehicleComponent } from './modules/vehicle-inventory/components/vehicle/inventory-htc-vehicle/inventory-htc-vehicle.component';
import { FinancingFormComponent } from './modules/vehicle-inventory/components/table-management/financing/financing-form/financing-form.component';
import { TaxListComponent } from './modules/vehicle-inventory/components/table-management/taxes/taxes-list/taxes-list.component';
import { FineReasonsListComponent } from './modules/vehicle-inventory/components/table-management/fineReasons/fine-reasons-list/fine-reasons-list.component';
import { ReportsWizardComponent } from './modules/vehicle-inventory/components/reports-wizard/reports-wizard.component';
import { ReportsWizardGuard } from './services/guards/inventory-reports/reports-wizard.guard';
import { LeaderListComponent } from './modules/vehicle-inventory/components/table-management/leaders/leader-list/leader-list.component';
import { RealStateListComponent } from './modules/vehicle-inventory/components/table-management/real-state/real-state-list/real-state-list.component';
import { TacoComponent } from './modules/taco/taco.component';
import { TacoGuard } from './services/guards/taco/taco.guard';


const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            { path: 'rt', component: RealTimeBaseComponent, canActivate: [RealTimeGuard] },
            { path: 'COEXPanel', component: UtePanelComponent, canActivate: [UtePanelGuard] },
            { path: 'reports', component: ReportListComponent, canActivate: [ReportsGuard] },
            {
                path: 'sat',
                loadChildren: () => import('./modules/sat/sat.module').then(module => module.SatModule),
            },
            {
                path: 'control-panel',
                component: ControlPanelComponent,
                canActivate: [ControlPanelGuard],
                children: [
                    { path: 'devices', component: DevicesListComponent, canActivate: [DevicesGuard] },
                    { path: 'deviceTypes', component: DeviceTypeEditViewComponent, canActivate: [DevicesGuard] },
                    {
                        path: 'conversionAlgorithms',
                        component: ConversionAlogrithmListComponent,
                        canActivate: [DevicesGuard],
                    },
                    { path: 'fleets', component: FleetEditViewComponent, canActivate: [VehiclesGuard] },
                    {
                        path: 'maintenancePlans',
                        component: MaintenancePlansListComponent,
                        canActivate: [MaintenancePlansGuard],
                    },
                    {
                        path: 'zoneGroups',
                        component: ZoneGroupsListComponent,
                        canActivate: [ZoneGroupsGuard],
                        resolve: { cercalia: CercaliaResolver },
                    },
                    {
                        path: 'poiCategory',
                        component: PoiCategoriesListComponent,
                        canActivate: [PoiCategoryGuard],
                        resolve: { cercalia: CercaliaResolver },
                    },
                    {
                        path: 'noticeGroups',
                        component: NoticeGroupsListComponent,
                        canActivate: [NotificationsGroupsGuard]
                    },
                    { path: 'rule', component: RuleListComponent, canActivate: [RulesGuard] },
                    { path: 'clients', component: ClientsListComponent, canActivate: [ClientsGuard] },
                    { path: 'users', component: UsersListComponent, canActivate: [UsersGuard] },
                    { path: 'roles', component: RolesListComponent, canActivate: [UsersGuard] },
                    { path: 'profiles', component: ProfilesListComponent, canActivate: [UsersGuard] },
                ],
            },
            { path: 'racing', component: RacingListComponent, canActivate: [RacingGuard] },
            { path: 'tax', component: TaxComponent, canActivate: [TaxGuard],
            loadChildren: () => import('./modules/tax/tax.module').then(module => module.TaxModule), },
         
           
           { path: 'map/rt-map', component: RtMapComponent, canActivate: [MapGuard]},
           { path: 'inventoryVehicles', component: InventoryVehiclesComponent, canActivate: [InventoryVehiclesGuard]},
           { path: 'drivers', component: DriversComponent, canActivate: [DriversGuard]},
           { path: 'maintenance', component: MaintenanceFormComponent, canActivate: [MaintenancePlansGuard]},
           { path: 'tableManagement', component: TableManagementComponent, canActivate: [TableManagementGuard]},
           { path: 'dashboards', component: DashboardsComponent, canActivate: [DashboardsGuard], loadChildren: () => import('./modules/dashboards/dashboards.module').then(module => module.DashboardsModule)},
           {
            path: 'administration',
            loadChildren: () => import('./modules/task/administration/administration.module').then(module => module.AdministrationModule),
            },
            {
                path: 'logistics',
                loadChildren: () => import('./modules/task/Logistics/logistics.module').then(module => module.LogisticsModule),
            },
            {
                path: 'predictions',
                loadChildren: () => import('./modules/task/Prediction/prediction.module').then(module => module.PredictionModule),
            },
            {
                path: 'taco', component: TacoComponent, canActivate: [TacoGuard],
                loadChildren: () => import('./modules/taco/taco.module').then(module => module.TacoModule),
            },


            { path: 'map/rt-map', component: RtMapComponent, canActivate: [MapGuard] },

            {
                path: 'inventoryVehicles',
                component: InventoryVehiclesComponent,
                canActivate: [InventoryVehiclesGuard],
                loadChildren: () =>
                    import('./modules/vehicle-inventory/vehicle-inventory.module').then(module => module.VehicleInventoryModule),
                //  children: [
                //      { path: 'inventoryVehiclesTable', component: InventoryVehiclesTableComponent, canActivate: [InventoryVehiclesGuard] },
                //  ]
            },

            {
                path: 'drivers',
                component: DriversComponent,
                canActivate: [DriversGuard],
                children: [
                    { path: 'driversTable', component: DriversTableComponent, canActivate: [DriversGuard] },
                    { path: 'driverInfoCard', component: DriverInfoCardComponent, canActivate: [DriversGuard] },
                ]
            },

            {
                path: 'maintenance',
                component: MaintenanceFormComponent,
                canActivate: [MaintenancePlansGuard],
                children: [
                    { path: 'maintenanceExecutionForm', component: MaintenanceExecutionFormComponent, canActivate: [MaintenancePlansGuard] },
                    { path: 'maintenanceExecutionList', component: MaintenanceExecutionsListComponent, canActivate: [MaintenancePlansGuard] },
                ]
            },

            {
                path: 'tableManagement',
                component: TableManagementComponent,
                canActivate: [TableManagementGuard],
                children: [
                    { path: 'providers', component: ProvidersComponent, canActivate: [TableManagementGuard] },

                    { path: 'vehicleStatus', component: VehicleStatusListComponent, canActivate: [TableManagementGuard] },
                    { path: 'vehicleUsage', component: VehicleUsageListComponent, canActivate: [TableManagementGuard] },
                    { path: 'brands', component: BrandListComponent, canActivate: [TableManagementGuard] },
                    { path: 'models', component: ModelListComponent, canActivate: [TableManagementGuard] },
                    { path: 'versions', component: VehicleVersionListComponent, canActivate: [TableManagementGuard] },
                    { path: 'accessories', component: AccessoryListComponent, canActivate: [TableManagementGuard] },
                    { path: 'taxes', component: TaxListComponent, canActivate: [TableManagementGuard] },

                    { path: 'delegations', component: DelegationListComponent, canActivate: [TableManagementGuard] },
                    { path: 'subdelegations', component: SubdelegationListComponent, canActivate: [TableManagementGuard] },
                    { path: 'divisions', component: DivisionsListComponent, canActivate: [TableManagementGuard] },
                    { path: 'subdivisions', component: SubdivisionsListComponent, canActivate: [TableManagementGuard] },
                    { path: 'companies', component: CompanyListComponent, canActivate: [TableManagementGuard] },
                    { path: 'costCentres', component: CostCentreListComponent, canActivate: [TableManagementGuard] },
                    { path: 'leaders', component: LeaderListComponent, canActivate: [TableManagementGuard] },

                    { path: 'cardTypes', component: CardTypesListComponent, canActivate: [TableManagementGuard] },
                    { path: 'cardLists', component: CardListsComponent, canActivate: [TableManagementGuard] },
                    { path: 'cardConsumptions', component: CardConsumptionListComponent, canActivate: [TableManagementGuard] },

                    { path: 'financeTypes', component: FinanceTypesListComponent, canActivate: [TableManagementGuard] },
                    { path: 'wayToPay', component: WayToPayListComponent, canActivate: [TableManagementGuard] },
                    { path: 'fines', component: FineListComponent, canActivate: [TableManagementGuard] },
                    { path: 'fineStatus', component: FineStatusListComponent, canActivate: [TableManagementGuard] },
                    { path: 'fineReasons', component: FineReasonsListComponent, canActivate: [TableManagementGuard]},
                    { path: 'rentingTypes', component: RentingTypeListComponent, canActivate: [TableManagementGuard] },
                    { path: 'financing', component: FinancingListComponent, canActivate: [TableManagementGuard] },
                    { path: 'financing/:vehicleId', component: FinancingListComponent, canActivate: [TableManagementGuard] }, //crear financing per al vehicle indicat

                    { path: 'InsuranceType', component: InsuranceTypeListComponent, canActivate: [TableManagementGuard] },
                    { path: 'insurance', component: InsuranceListComponent, canActivate: [TableManagementGuard] },
                    { path: 'insurance/:vehicleId', component: InsuranceListComponent, canActivate: [TableManagementGuard] }, //crear insurance per al vehicle indicat
                    
                    { path: 'maintenancePlans', component: MaintenancePlansListComponent, canActivate: [TableManagementGuard] },

                    { path: 'realState', component: RealStateListComponent, canActivate: [TableManagementGuard] },

                ]
            },

            { path: 'reports-wizard', component: ReportsWizardComponent, canActivate: [ReportsWizardGuard] },


            {
                path: 'dataImport',
                component: DataImportComponent,
                canActivate: [TableManagementGuard],
                children: [
                    { path: 'cards', component: CardsComponent, canActivate: [TableManagementGuard] },
                    { path: 'cepsa', component: CepsaComponent, canActivate: [TableManagementGuard] },
                    { path: 'insurances', component: InsurancesComponent, canActivate: [TableManagementGuard] },
                ]
            },

            { path: 'dashboards', component: DashboardsComponent, canActivate: [DashboardsGuard], loadChildren: () => import('./modules/dashboards/dashboards.module').then(module => module.DashboardsModule) },
            {
                path: 'promoted-routes', component: FidPromotedRoutesComponent, canActivate: [PromotedRoutesGuard],
                loadChildren: () => import('./modules/fid-promoted-routes/fid-promoted-routes.module').then(module => module.FidPromotedRoutesModule),
            }

        ],
    },

    {
        path: 'map',
        loadChildren: () => import('./modules/map/map.module').then(module => module.MapModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(module => module.LoginModule)
    },
    //{ path: 'map', loadChildren: () => import('./modules/map/map.module').then(module => module.MapModule)},
    // { path: 'map', component: RtMapComponent },
    //  { path: 'map', component: SatMapComponent },
    // { path: 'map/sat-map', component: RtMapComponent,  canActivate: [MapGuard] },
    // { path: 'map/sat-map', component: RtMapComponent,  canActivate: [AuthGuard] },



    //   { path: 'map/sat-map', component: RtMapComponent, canActivate: [MapGuard]},



    {
        path: 'logout',
        loadChildren: () => import('./modules/login/login.module').then(module => module.LoginModule)
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' },

    /* TODO: Implement PageNotFoundComponent */
    { path: '**', redirectTo: '/404' },
    //{ path: 'map', component: RtMapComponent },

    /* TODO: Delete HackTranslationsComponent*/
    { path: 'literalmissingtranslations', component: HackTranslationsComponent },

    /* TODO: Delete TranslationComponent */
    { path: 'translation', component: TranslationComponent },

    { path: 'printMapa', component: MapImagePrint },

    /* TODO: Delete DummyComponent */
    /*{ path: '??', component: DummyComponent, pathMatch: 'full' },*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

export const routingComponents = [
    MaintenancesListComponent,
    DeviceTypeEditViewComponent,
    DevicesListComponent,
    MaintenancePlansListComponent,
    ZoneGroupsListComponent,
    ZoneListComponent,
    FleetEditViewComponent,
    PoiCategoriesListComponent,
    PoiListComponent,
    TranslationComponent,
    RuleListComponent,
    ProfilesListComponent,
    PageNotFoundComponent,
    HackTranslationsComponent,
    ClientsListComponent,
];
