import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-non-movement-detection-gl320m',
  templateUrl: './non-movement-detection-gl320m.component.html',
  styleUrls: ['./non-movement-detection-gl320m.component.css'],
})
export class NonMovementDetectionGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  urcReportOptions: any = [
    { label: "Do not output the sensor's state to UART", value: "DISABLE" },
    { label: "Output a URC to UART to indicate the state change", value: "ENABLE" },
 ];

 commandOptions: any = [
  { label: "Do not change motion state after receiving the commands", value: "NOT_CHANGE_MOTION_STATE" },
  { label: "Force the device to enter movement state after receiving one of the commands", value: "FORCE_MOVEMENT_STATE" },
 ]

 reportOptions: any = [
  { label: "Report the last fixed position", value: "REPORT_LAST_POSITION"},
  { label: "Report the current position", value: "REPORT_CURRENT_POSITION"},
  { label: "Report the last fixed position immediately, then turn on GPS to get current position and report again", value: "REPORT_LAST_POSITION_THEN_TURN_ON_GPS"},
 ]

  @Input() nmdParameters: Rest.NonMovementDetectionGL320M;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.nmdParameters);
    }
  }

}
