<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.cardsConsumption.cardConsumptionsManagement' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITLE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>

            <!-- CARD -->
            <th pSortableColumn='card.number' *ngIf="showCardColumn">
                <div class='d-flex align-items-center h-100'>

                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'tableManagement.cardsConsumption.card' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='card.number'></p-sortIcon>
                        <p-columnFilter field='card' type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"> </p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- CARD -->
            <th pSortableColumn='card.vehicle.name' *ngIf="showCardColumn">
                <div class='d-flex align-items-center h-100'>

                    <!-- COLUMN TITLE -->
                    <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                        {{ 'control-panel.vehicles.licensePlate' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class='d-flex align-items-center h-100'>
                        <p-sortIcon field='card.vehicle.name'></p-sortIcon>
                        <p-columnFilter field='card' type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"> </p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- IMPORT VALUE -->
            <th pSortableColumn="amount">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.amount' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="amount"></p-sortIcon>
                        <p-columnFilter field="amount" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- CONSUMPTION DATE -->
            <th pSortableColumn="consumptionDate">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.date' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="consumptionDate"></p-sortIcon>
                    </div>

                </div>
            </th>

            <!-- CONSUMPTION DETAIL FIELDS -->
            <!-- LITERS -->
            <th pSortableColumn="liters">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.litres' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="liters"></p-sortIcon>
                        <p-columnFilter field="liters" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- KMS -->
            <th pSortableColumn="kms">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.km' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="kms"></p-sortIcon>
                        <p-columnFilter field="kms" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- DIFF KMS -->
            <th pSortableColumn="difKms">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.diffKms' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="difKms"></p-sortIcon>
                        <p-columnFilter field="difKms" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- DIFF DAYS -->
            <th pSortableColumn="difDays">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.diffDays' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="difDays"></p-sortIcon>
                        <p-columnFilter field="difDays" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- DIFF CONSUMPTION -->
            <th pSortableColumn="difConsumption">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.diffConsumption' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="difConsumption"></p-sortIcon>
                        <p-columnFilter field="difConsumption" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- KMS COST -->
            <th pSortableColumn="kmsCost">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.kmsCost' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="kmsCost"></p-sortIcon>
                        <p-columnFilter field="kmsCost" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- LITER COST -->
            <th pSortableColumn="literCost">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.literCost' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="literCost"></p-sortIcon>
                        <p-columnFilter field="literCost" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- CARD BILLING -->
            <th pSortableColumn="cardBilling.invoiceNumber">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.invoiceNumber' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="cardBilling.invoiceNumber"></p-sortIcon>
                        <p-columnFilter field="invoiceNumber" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- CARD BILLING DATE -->
            <th pSortableColumn="cardBilling.billingDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.billingDate' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="cardBilling.billingDate"></p-sortIcon>
                        <p-columnFilter field="billingDate" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- PLACE -->
            <th pSortableColumn="place">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.place' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="place"></p-sortIcon>
                        <p-columnFilter field="place" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- PLACE CITY -->
            <th pSortableColumn="placeCity">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.cardsConsumption.placeCity' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="placeCity"></p-sortIcon>
                        <p-columnFilter field="placeCity" type='numeric' matchMode='contains'
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

        </tr>
    </ng-template>

    <!-- FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="5" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">
                    <div class="d-flex flex-row " style="margin-left: auto;">
                        <!-- UPLOAD FILE FORM  -->
                        <div class="buttonTableExcel custom-image-button " (click)="showFileForm()">
                            <svg-icon src="assets/icons/upload-file.svg"
                                [svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
                        </div>
                        <!-- TABLE EXCEL BUTTON -->
                        <app-table-excel-button class="buttonTableExcel"
                            (btnClick)="exportExcel()"></app-table-excel-button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>

    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-entity>
        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(entity)">

            <!-- CARD -->
            <td *ngIf="showCardColumn">
                {{ entity.card.number }}
            </td>

            <!-- PLATE -->
            <td *ngIf="showCardColumn">
                {{ entity.vehicle?.name }}
            </td>

            <!-- IMPORT VALUE -->
            <td>
                {{ entity.amount | number: '1.2-2'}}
            </td>

            <!-- CONSUMPTION DATE -->
            <td>
                {{ entity.consumptionDate | date: 'dd/MM/yyyy' }}
            </td>

            <!-- LITERS -->
            <td>
                {{ entity.liters | number: '1.2-2'}}
            </td>

            <!-- KMS -->
            <td>
                {{ entity.kms }}
            </td>

            <!-- DIFF KMS -->
            <td>
                {{ entity.difKms }}
            </td>

            <!-- DIFF DAYS -->
            <td>
                {{ entity.difDays }}
            </td>

            <!-- DIFF CONSUMPTION -->
            <td>
                {{ entity.difConsumption | number: '1.2-2'}}
            </td>

            <!-- KMS COST -->
            <td>
                {{ entity.kmsCost | number: '1.2-2'}}
            </td>

            <!-- LITER COST -->
            <td>
                {{ entity.literCost | number: '1.2-2' }}
            </td>

            <td>
                {{ entity.cardBilling?.invoiceNumber }}
            </td>

            <td>
                {{ entity.cardBilling?.billingDate | date: 'dd/MM/yyyy' }}
            </td>

            <td>
                {{ entity.place }}
            </td>

            <td>
                {{ entity.placeCity }}
            </td>
        </tr>

    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="15" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="15" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">

                    <!-- ADD FINE BUTTON -->
                    <app-table-add-button *ngIf="isCreateGranted" [text]=" 'tableManagement.cardsConsumption.cardConsumption' | translate"
                        (btnClick)="create()"></app-table-add-button>

                    <div class="d-flex flex-row " style="margin-left: auto;">
                        <!-- UPLOAD FILE FORM  -->
                        <div class="buttonTableExcel custom-image-button "
                            pTooltip="{{'tableManagement.cardsConsumption.cardsConsumptionProcess' | translate }}"
                            tooltipPosition="top" (click)="showFileForm()">
                            <svg-icon src="assets/icons/upload-file.svg"
                                [svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
                        </div>
                        <!--  EXCEL BUTTON -->
                        <app-table-excel-button class="buttonTableExcel"
                            (btnClick)="exportExcel()"></app-table-excel-button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->

</p-table>

<div *ngIf="vehicle" class="summary-container">
    <app-card-consumption-summary [vehicle]="vehicle"></app-card-consumption-summary>
</div>

<!-- FINE FORM -->
<app-cardConsumption-form [card]="card" [vehicle]="vehicle"></app-cardConsumption-form>

<!-- UPLOAD FILE FORM -->
<app-upload-file-form (refreshTable)="refresh()" [renewalType]="renewalType"
    [title]="fileFormTitle"></app-upload-file-form>