import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-table-extra-column-filters',
  templateUrl: './table-extra-column-filters.component.html',
  styleUrls: ['./table-extra-column-filters.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableExtraColumnFiltersComponent {
 
   @Input() extraFilters;
  @Input() table;
  @ViewChild('op') op: OverlayPanel;  // Declaración de la referencia al OverlayPanel
  showOverlayPanel = true;
  @Output() btnClick = new EventEmitter();

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router, private zone: NgZone, private cdr: ChangeDetectorRef) {
   
   
  }

  ngOnInit(){
    this.showOverlayPanel = true;
    let that = this;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        
      //  takeUntil(this.onDestroy$)
      )
      .subscribe((event: NavigationEnd) => {
        // Detectar el cambio de componente  
        that.op?.hide();
        that.cdr?.detectChanges();
      }); 
  }
/*
  private sub = this.router.events
  .pipe(
    filter(event => event instanceof NavigationStart),
    map(event => event as NavigationStart),  // appease typescript
    filter(event => event.url !== '/employees')
  )
  .subscribe(
    event =>   this.hideOverlayPanel()

    
  );*/


  ngOnDestroy() {
    console.log('TableExtraColumnFiltersComponent se está destruyendo.');

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onClick() {
    this.btnClick.emit();
  }

  hideOverlayPanel() {
     // this.op.hide()
  }

/*
  filterTable(): void {
    //OLD
    this.op.hide();
    this.extraFilters.forEach(extraFilter => {
       if(extraFilter.value != null && extraFilter.value != ""){
          this.table.filter(extraFilter.value, extraFilter.field,  'startsWith' )
       }
     });
  } */
 

  filterTable(): void {
    this.op.hide();
    this.table.filter();

  } 
  
  onExtraFilterChange(event: InputEvent, extraFilterChanged: any) {
   }
 

}
