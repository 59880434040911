<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-6">
            <!-- REPORT TITLE  -->
            <h2 class="mb-3">{{'reportsWizard.header.reportOf' | translate }} {{report.reportData.name}}</h2>

            <!-- GO BACK BUTTON -->
            <button type="button" class="btn btn-secondary mb-3" (click)="goBack()">
                <i class="pi pi-arrow-left"></i>
                {{ 'reportsWizard.reportsForm.goBack' | translate }}
            </button>

            <form [formGroup]="reportForm" (ngSubmit)="onSubmit()" class="p-3 border rounded">
                <!-- Billing Account Field -->
                <div class="mb-3">
                    <label for="billingAccount">{{'tableManagement.companies.company' | translate}}</label>

                    <p-multiSelect [options]="billingAccounts" [(ngModel)]="selectedBillingAccounts"
                        formControlName="billingAccount" optionLabel="codeName" class="ms-3" [virtualScroll]="true"
                        itemSize="30" [panelStyle]="{'min-width': '350px'}"
                        defaultLabel="{{'general.select' | translate}} {{'tableManagement.companies.company' | translate}}"
                        [showClear]="true">
                        <ng-template let-item pTemplate="option">
                            <div class="billing-account-option">
                                ({{ item.code }}) {{ item.name }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>

                <!-- CECO Field -->
                <div class="mb-3">
                    <label for="ceco">{{'tableManagement.costCentres.costCentre' | translate}}</label>

                    <p-multiSelect [options]="cecos" [(ngModel)]="selectedCecos" formControlName="ceco"
                        optionLabel="codeName" class="ms-3" [virtualScroll]="true" itemSize="30"
                        [panelStyle]="{'min-width': '350px'}"
                        defaultLabel="{{'general.select' | translate}} {{'tableManagement.costCentres.costCentre' | translate}}"
                        [showClear]="true">
                        <ng-template let-item pTemplate="option">
                            <div class="billing-account-option">
                                ({{ item.code }}) {{ item.description }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>

                <!-- Delegation field -->
                <div class="mb-3">
                    <label for="delegation">{{'tableManagement.delegations.delegation' | translate}}</label>

                    <p-multiSelect [options]="delegations" [(ngModel)]="selectedDelegations"
                        formControlName="delegation" optionLabel="codeName" class="ms-3"
                        defaultLabel="{{'general.select' | translate}} {{'tableManagement.delegations.delegation' | translate}}"
                        [showClear]="true">
                        <ng-template let-item pTemplate="option">
                            <div class="delegation-option">
                                ({{ item.code }}) {{ item.name }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>

                <!-- Subdelegation field -->
                <div class="mb-3">
                    <label for="subdelegation">{{'tableManagement.subdelegations.subdelegation' | translate}}</label>

                    <p-multiSelect [options]="subdelegations" [(ngModel)]="selectedSubdelegations"
                        formControlName="subdelegation" optionLabel="codeName" class="ms-3"
                        defaultLabel="{{'general.select' | translate}} {{'tableManagement.subdelegations.subdelegation' | translate}}"
                        [showClear]="true">
                        <ng-template let-item pTemplate="option">
                            <div class="delegation-option">
                                ({{ item.code }}) {{ item.name }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>

                <!-- Accesories field -->
                <div class="mb-3">
                    <label for="accesories">{{'tableManagement.accessories.accessories' | translate}}</label>

                    <p-multiSelect [options]="accesories" [(ngModel)]="selectedAccesories" formControlName="accesories"
                        optionLabel="name" class="ms-3"
                        defaultLabel="{{'general.select' | translate}} {{'tableManagement.accessories.accessories' | translate}}"
                        [showClear]="true"></p-multiSelect>
                </div>
            </form>

            <!-- SUBMIT BUTTON -->
            <button type="submit" pButton (click)="toPhase3()"
                label="{{ 'reportsWizard.reportsForm.next' | translate }}" [disabled]="!reportForm.valid"
                class="btn btn-primary mt-3 w-100"
                [ngStyle]="{'background-color': '#C42C22', 'border-color': '#C42C22'}"></button>
        </div>
    </div>
</div>