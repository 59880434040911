<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Command Storage
          Settings(AT + GTCMD)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">

    <div class="row align-items-center">
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Mode" >
          </form-input>
        </div>
        <div class="col">
          <p-dropdown [options]="modeOptions" [(ngModel)]="commandStorage.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Store cmd id">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="commandStorage.cmdId" [min]="0" [max]="31"></p-spinner>
            <span class="ui-inputgroup-addon">0~31</span>
            <span class="ui-inputgroup-addon" >id</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Command String" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" [style]="{'width':'100%','min-width':'100px'}" pInputText [(ngModel)]="commandStorage.command"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>