<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Authentication
          Id(AT+GTIDA)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown (onChange)="trackEventModeChange(idaParameters.mode, $event)" [options]="modeOptions"
          [(ngModel)]="idaParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

    <div [class.disableDIV]=!trackModeSwitch>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Start Index">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.startIndex" [min]="1" [max]="250"
                (onChange)="onChange()"></p-spinner>
              <span class="ui-inputgroup-addon">1~250</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="End Index">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.endIndex" [min]="1" [max]="250"
                (onChange)="onChange()"></p-spinner>
              <span class="ui-inputgroup-addon">1~250</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="ID Number list">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <input type="text" pInputText [(ngModel)]="idaParameters.idNumberList" class="txtbStyle"/>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Timeout after Ignition Off">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.timeoutAfterIgnitionOff" [min]="15" [max]="600"></p-spinner>
              <span class="ui-inputgroup-addon">15~600</span>
              <span class="ui-inputgroup-addon">sec</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Mode Report">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="modeReportOptions" [(ngModel)]="idaParameters.modeReport"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Validity Time">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.validityTime" [min]="15" [max]="600"></p-spinner>
              <span class="ui-inputgroup-addon">15~600</span>
              <span class="ui-inputgroup-addon">sec</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Output Id">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.outputId" [min]="0" [max]="3"></p-spinner>
              <span class="ui-inputgroup-addon">0~3</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Output status">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.outputStatus" [min]="0" [max]="2"></p-spinner>
              <span class="ui-inputgroup-addon">0~2</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Duration">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.duration" [min]="0" [max]="2"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon">(X100 ms)</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Toggle Times">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.toggleTimes" [min]="0" [max]="2"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon">sec</span>
            </div>
          </div>
        </div>
        
        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Mode Report RFID">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="modeReportRfidOptions" [(ngModel)]="idaParameters.modeReportRfid"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
          </div>
        </div>
      
      </div>

    </div>
  </div>
</p-accordionTab>