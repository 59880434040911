import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LeaderService } from 'app/rest/leader.service';
import { UserService } from 'app/rest/user.service';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
    selector: 'app-leader-form',
    templateUrl: './leader-form.component.html',
    styleUrls: ['./leader-form.component.scss'],
    providers: [LeaderService, IconService, UserService],
    encapsulation: ViewEncapsulation.None,
})
export class LeaderFormComponent
    extends EntityFormComponent<Rest.InvLeader>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;
    selectedUser: Rest.User;

    cecoEnabled: boolean;

    clientID: number;

    entityEnumImageClient: Rest.IconEntityAssociationEnum = "CLIENT";

    //FORM LABELS
    clientImageLabel: string;
    userRequestContext: Rest.ListPaginationRequest;
    usersList: Rest.User[] = [];
    usersLoaded: boolean = false;

    constructor(
        private leaderService: LeaderService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
        public translateService: TranslateService,
        private userService: UserService,
    ) {
        super(leaderService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.return.subscribe((status: boolean) => {
            this.entity.disabled = status;
        });

    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ClientFormComponent
     */
    beforeShow() {
        //   this.cecoEnabled = !this.entity.disabled;
        //    this.clientID = this.entity.id;
        this.entity.client = this.authenticationService.user.client;

        this.userRequestContext = {
            sortBy: null,
            sortAsc: true,
            pageSize: 10,
            filters: {
            },
            loadPermissions: false
        } as unknown as Rest.ListPaginationRequest;


        
        
        this.clientImageLabel = this.translate.instant('control-panel.clients.clientImage');
        /*Permissions */
        if (this.isNew) {
            this.entity.user = null;
            this.entity.disabled = false;
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.leaders.createLeader');
            this.userRequestContext.filters["leader"] = ["true"];
        } else {
            this.userRequestContext.filters["fullName"]=[this.entity.user.name];
            this.title = this.translateService.instant('tableManagement.leaders.editLeader');
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }

        this.userService.getPage(this.userRequestContext).then((p) => {
            this.usersList = p.entities;
            this.usersLoaded = true;
        });

        //this.getUsers()
        /** */
    }
    public getUsers() {
        let usersRequest = {
            sortBy: null,
            sortAsc: true,
            pageSize: 10,
            filters: {
            },
            loadPermissions: false
        } as unknown as Rest.ListPaginationRequest;
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.userService.getPage(usersRequest)
                .then(
                    function (response) {
                        that.usersList = response.entities;
              
                        that.usersLoaded = true;
                    },
                    /* failure */
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    },
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }

    onChangeLeader(event) {
        if (event.value !== null) {
          this.entity.user = event.value;
        }
      }
    
      onFilterLeader(event) {
        if (event.filter === null) {
          event.filter = '';
        }
    
        this.userRequestContext.filters["fullName"] = [event.filter];
    
        this.userService.getPage(this.userRequestContext).then((p) => {
          this.usersList = p.entities;
        });
      }


}
