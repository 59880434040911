<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Digital Input Port
          Settings(AT + GTDIS)</span>
      </div>
    </div>
  </p-header>
  <!-- Ignition Input -->
  <div class="ui-g">
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Ignition Detection Mode">
        <p-inputSwitch [(ngModel)]="disParameters.mode"></p-inputSwitch>
      </form-input>
    </div>
    <!-- Digital input 1 -->
    <div class="ui-g-12">
      <form-input  fieldName="Digital input 1">
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Enable">
        <p-inputSwitch [(ngModel)]="disParameters.enableD1"></p-inputSwitch>
      </form-input>
    </div>
    <div [class.disableDIV]=!disParameters.enableD1 class=ui-g-12>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Debounce time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD1" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon" >*10ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Validity time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD1" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon" >*2s</span>
          </div>
        </form-input>
      </div>
    </div>
    <!-- Digital Input 2 -->
    <div class="ui-g-12">
      <form-input  fieldName="Digital input 2">
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Enable">
        <p-inputSwitch [(ngModel)]="disParameters.enableD2"></p-inputSwitch>
      </form-input>
    </div>
    <div [class.disableDIV]=!disParameters.enableD2 class=ui-g-12>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Debounce time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD2" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon" >*10ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Validity time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD2" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon" >*2s</span>
          </div>
        </form-input>
      </div>
    </div>



    <!-- Digital Input 3 -->
    <div class="ui-g-12">
      <form-input  fieldName="Digital input 3">
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Enable">
        <p-inputSwitch [(ngModel)]="disParameters.enableD3"></p-inputSwitch>
      </form-input>
    </div>
    <div [class.disableDIV]=!disParameters.enableD3 class=ui-g-12>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Debounce time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD3" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon" >*10ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Validity time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD3" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon" >*2s</span>
          </div>
        </form-input>
      </div>
    </div>





    <!-- Digital Input 4 -->
    <div class="ui-g-12">
      <form-input  fieldName="Digital input 4">
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Enable">
        <p-inputSwitch [(ngModel)]="disParameters.enableD4"></p-inputSwitch>
      </form-input>
    </div>
    <div [class.disableDIV]=!disParameters.enableD4 class=ui-g-12>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Debounce time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD4" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon" >*10ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Validity time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD4" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon" >*2s</span>
          </div>
        </form-input>
      </div>
    </div>



    <!-- Digital Input 5 -->
    <div class="ui-g-12">
      <form-input  fieldName="Digital input 5">
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Enable">
        <p-inputSwitch [(ngModel)]="disParameters.enableD5"></p-inputSwitch>
      </form-input>
    </div>
    <div [class.disableDIV]=!disParameters.enableD5 class=ui-g-12>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Debounce time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD5" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon" >*10ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Validity time">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD5" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon" >*2s</span>
          </div>
        </form-input>
      </div>
    </div>

  </div>
</p-accordionTab>
