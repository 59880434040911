import { Component, Input, OnInit } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-jamming-detection-information-form-gv300can",
  templateUrl: "./jamming-detection-information-form-gv300can.component.html",
  styleUrls: ["./jamming-detection-information-form-gv300can.component.css"],
})
export class JammingDetectionInformationFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() jammingDetection: Rest.JammingDetectionGV300CAN;

  jammingModeOptions: any = [
    { label: "Disable.", value: 0 },
    { label: "Enable detection on: Enters into “Jamming” state.", value: 1 },
    {
      label: "Enable detection on: Enters or leaving “Jamming” state..",
      value: 2,
    },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  trackModeSwitch = false;

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
