import { Rest } from "app/rest/rest_client";
import { I18nService } from "app/services/i18n/i18n.service";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { EntityService } from '../../rest/entity.service';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { LoadingService } from '../../services/loading-service/loading.service';
import { NotificationsService, Severity } from '../../services/notifications-service/notifications.service';
import { RestExt } from '../../services/rest-client-extension';
import { Rest_task } from "./administration/services/rest_client_task";

export namespace RestExtTask {

  export enum FormTaskEmum {
    ORDERS = "ORDERS",
    ROUTES = "ROUTES",
    INCIDENCES = "INCIDENCES",
    ADMINDELEGATION = "ADMINDELEGATION",
    ADMINROUTES = "ADMINROUTES",
    ADMINUSERSBO = "ADMINUSERSBO",
    ADMINUSERSAPP = "ADMINUSERSAPP",
    STORES = "STORES",
    VALISES = "VALISES",
    PACKAGING = "PACKAGING",
    LOGINVERSE = "LOGINVERSE",
    PAYMENT = "PAYMENT",
    PREDICTION = "PREDICTION"
  }

  export enum ActionTaskEnum {
    TASK_ORDERS_VIEW="TASK_ORDERS_VIEW", 
    TASK_ORDERS_DETAIL="TASK_ORDERS_DETAIL", 
    TASK_ORDERS_DELETE="TASK_ORDERS_DELETE", 
    TASK_ORDERS_ROUTEDAY_UPDATE="TASK_ORDERS_ROUTEDAY_UPDATE", 
    TASK_ORDERS_INCIDENCES_RETURN="TASK_ORDERS_INCIDENCES_RETURN", 
    TASK_ORDERS_PACKAGES_UPDATE="TASK_ORDERS_PACKAGES_UPDATE", 
    TASK_ORDERS_PACKAGES_DELETE="TASK_ORDERS_PACKAGES_DELETE", 
    TASK_ORDERS_PACKAGES_READING="TASK_ORDERS_PACKAGES_READING", 
    TASK_INCIDENCES_VIEW="TASK_INCIDENCES_VIEW", 
    TASK_INCIDENCES_DETAIL="TASK_INCIDENCES_DETAIL", 
    TASK_INCIDENCES_NOTREAD_VIEW="TASK_INCIDENCES_NOTREAD_VIEW", 
    TASK_INCIDENCES_NOTREAD_UPDATE="TASK_INCIDENCES_NOTREAD_UPDATE", 
    TASK_ROUTES_VIEW="TASK_ROUTES_VIEW", 
    TASK_ROUTES_DETAIL="TASK_ROUTES_DETAIL", 
    TASK_ROUTES_SEGREGATE="TASK_ROUTES_SEGREGATE", 
    TASK_DELEGATIONS_VIEW="TASK_DELEGATIONS_VIEW", 
    TASK_DELEGATIONS_UPDATE="TASK_DELEGATIONS_UPDATE", 
    TASK_DELGATIONS_DELETE="TASK_DELGATIONS_DELETE", 
    TASK_USERSAPP_VIEW="TASK_USERSAPP_VIEW", 
    TASK_USERSAPP_UPDATE="TASK_USERSAPP_UPDATE", 
    TASK_USERSAPP_VIEW_ROUTES="TASK_USERSAPP_VIEW_ROUTES", 
    TASK_USERSAPP_UPDATE_ROUTES="TASK_USERSAPP_UPDATE_ROUTES", 
    TASK_USERSAPP_DELETE="TASK_USERSAPP_DELETE",
    TASK_ROUTES_ADMIN_VIEW="TASK_ROUTES_ADMIN_VIEW", 
    TASK_ROUTES_ADMIN_UPDATE="TASK_ROUTES_ADMIN_UPDATE", 
    TASK_ROUTES_ADMIN_VIEW_COND="TASK_ROUTES_ADMIN_VIEW_COND", 
    TASK_ROUTES_UPDATE_COND="TASK_ROUTES_UPDATE_COND", 
    TASK_ROUTES_ADMIN_DELETE="TASK_ROUTES_ADMIN_DELETE", 
    TASK_USERSBO_VIEW="TASK_USERSBO_VIEW", 
    TASK_USERSBO_UPDATE="TASK_USERSBO_UPDATE", 
    TASK_USERSBO_DELETE="TASK_USERSBO_DELETE", 
    TASK_STORES_VIEW="TASK_STORES_VIEW", 
    TASK_STORES_UPDATE="TASK_STORES_UPDATE", 
    TASK_STORES_DELETE="TASK_STORES_DELETE", 
    TASK_STORES_VALISE_VIEW="TASK_STORES_VALISE_VIEW", 
    TASK_STORES_VALISE_UPDATE="TASK_STORES_VALISE_UPDATE", 
    TASK_STORES_VALISE_DELETE="TASK_STORES_VALISE_DELETE", 
    TASK_STORES_ASSIGDELIVERY_VIEW="TASK_STORES_ASSIGDELIVERY_VIEW", 
    TASK_STORES_ASSIGDELIVERY_UPDATE="TASK_STORES_ASSIGDELIVERY_UPDATE", 
    TASK_STORES_ASSIGDELIVERY_DELETE="TASK_STORES_ASSIGDELIVERY_DELETE", 
    TASK_STORES_ASSIGCOLLECTED_VIEW="TASK_STORES_ASSIGCOLLECTED_VIEW", 
    TASK_STORES_ASSIGCOLLECTED_UPDATE="TASK_STORES_ASSIGCOLLECTED_UPDATE", 
    TASK_STORES_ASSIGCOLLECTED_DELETE="TASK_STORES_ASSIGCOLLECTED_DELETE", 
    TASK_VALISES_VIEW="TASK_VALISES_VIEW", 
    TASK_VALISES_VIEW_DETALL="TASK_VALISES_VIEW_DETALL", 
    TASK_VALISES_AUTOASSIG="TASK_VALISES_AUTOASSIG", 
    TASK_VALISES_UPDATE="TASK_VALISES_UPDATE", 
    TASK_VALISES_DELETE="TASK_VALISES_DELETE", 
    TASK_VALISES_INTERMEDIATE_VIEW="TASK_VALISES_INTERMEDIATE_VIEW", 
    TASK_VALISES_INTERMEDIATE_UPDATE="TASK_VALISES_INTERMEDIATE_UPDATE", 
    TASK_VALISES_INTERMEDIATE_DELETE="TASK_VALISES_INTERMEDIATE_DELETE", 
    TASK_VALISES_IMPORT="TASK_VALISES_IMPORT", 
    TASK_LOGINVERSE_VIEW="TASK_LOGINVERSE_VIEW", 
    TASK_LOGINVERSE_VIEW_DETALL="TASK_LOGINVERSE_VIEW_DETALL", 
    TASK_LOGINVERSE_UPDATE="TASK_LOGINVERSE_UPDATE", 
    TASK_LOGINVERSE_DELETE="TASK_LOGINVERSE_DELETE", 
    TASK_LOGINVERSE_INTERMEDIATE_VIEW="TASK_LOGINVERSE_INTERMEDIATE_VIEW", 
    TASK_LOGINVERSE_INTERMEDIATE_UPDATE="TASK_LOGINVERSE_INTERMEDIATE_UPDATE", 
    TASK_LOGINVERSE_INTERMEDIATE_DELETE="TASK_LOGINVERSE_INTERMEDIATE_DELETE", 
    TASK_PACKAGING_VIEW="TASK_PACKAGING_VIEW", 
    TASK_PACKAGING_REGULARIZE="TASK_PACKAGING_REGULARIZE",
    TASK_ORDERS_PAYMENT_VIEW="TASK_ORDERS_PAYMENT_VIEW",
     TASK_PREDICTION_ARTICLE="TASK_PREDICTION_ARTICLE",
     TASK_PREDICTION_ANOMALIES="TASK_PREDICTION_ANOMALIES",
     TASK_PREDICTION_CLASSIFICATION="TASK_PREDICTION_CLASSIFICATION",
     TASK_PACKAGING_DETAIL="TASK_PACKAGING_DETAIL",
     TASK_PREDICTION_ENTRENAMENT ="TASK_PREDICTION_ENTRENAMENT"
  }

  // export class ActionsFormsService {
  //  //permissions = new Map<RestExtTask.ActionTaskEnum, string[]>();
  //  permissions: RestExtTask.ActionTaskEnum[];
  //  permissionforms :ActionTaskEnum[];
  //  valuesactions :Rest.Action[];
  //  exist:Boolean;

  export function getpermisionsUser(user: Rest.User, entityType: RestExtTask.FormTaskEmum) {
    let permissions: RestExtTask.ActionTaskEnum[] = [];
    let permissionforms: ActionTaskEnum[];
    let valuesactions: Rest.Action[];
    this.permissionforms = this.getActionsByType(entityType);
    let pos = 0;
    for (let a in user.rolesProfiles) {
      this.valuesactions = this.obtenerElementosComunes(this.permissionforms, user.rolesProfiles[a].profile.actions);
      // this.valuesactions = user.profiles[a].actions.filter(item => {
      //     return Object.values(this.permissionforms).some(value => typeof value === 'string' && item.name === value);
      //   });
      for (let b in this.valuesactions) {
        if (!permissions.includes(this.valuesactions[b].name)) {
          permissions[pos] = this.valuesactions[b].name;
          pos = pos + 1;
        }
      }
    }
    return permissions;
  }

  export function obtenerElementosComunes(enumTipo: RestExtTask.ActionTaskEnum[], listaClases: Rest.Action[]): Rest.Action[] {
    const elementosComunes: Rest.Action[] = [];

    for (const value in enumTipo) {
      if (listaClases.filter(item => item.name === enumTipo[value]).length > 0) {
        elementosComunes.push(listaClases.filter(item => item.name === enumTipo[value])[0]);
      }
    }

    return elementosComunes;
  }


  export function hasPermission(action: RestExtTask.ActionTaskEnum, user: Rest.User) {
    if ((user.profiles != null) && (user.profiles.length > 0)) {
      for (let a in user.profiles) {
        if (user.profiles[a].actions.some((x) => x.name === action.toString()) === true) {
          return true;
        }
      }
      return false;
    }
    else {
      for (let a in user.rolesProfiles) {
        if (user.rolesProfiles[a].profile.actions.some((x) => x.name === action.toString()) === true) {
          return true;
        }
      }
      return false;
    }
  }
  export function hasPermissionrp(action: RestExtTask.ActionTaskEnum, user: Rest.User) {
    for (let a in user.rolesProfiles) {
      if (user.rolesProfiles[a].profile.actions.some((x) => x.name === action.toString()) === true) {
        return true;
      }
    }
    return false;
  }

    
      export function getActionsByType(tipo: FormTaskEmum): ActionTaskEnum[] {
       switch (tipo) {
             case FormTaskEmum.ORDERS:
                 return [ActionTaskEnum.TASK_ORDERS_VIEW, 
                     ActionTaskEnum.TASK_ORDERS_DETAIL, 
                     ActionTaskEnum.TASK_ORDERS_DELETE,
                     ActionTaskEnum.TASK_ORDERS_ROUTEDAY_UPDATE,
                     ActionTaskEnum.TASK_ORDERS_INCIDENCES_RETURN, 
                     ActionTaskEnum.TASK_ORDERS_PACKAGES_UPDATE, 
                     ActionTaskEnum.TASK_ORDERS_PACKAGES_DELETE, 
                     ActionTaskEnum.TASK_ORDERS_PACKAGES_READING];
             case FormTaskEmum.ROUTES:
                 return [ActionTaskEnum.TASK_ROUTES_VIEW,
                     ActionTaskEnum.TASK_ROUTES_DETAIL, 
                     ActionTaskEnum.TASK_ORDERS_ROUTEDAY_UPDATE,
                     ActionTaskEnum.TASK_ROUTES_SEGREGATE, 
                     ActionTaskEnum.TASK_ROUTES_UPDATE_COND];
             case FormTaskEmum.INCIDENCES:
                 return [ActionTaskEnum.TASK_INCIDENCES_VIEW, 
                    ActionTaskEnum.TASK_INCIDENCES_DETAIL, 
                    ActionTaskEnum.TASK_INCIDENCES_NOTREAD_VIEW, 
                     ActionTaskEnum.TASK_INCIDENCES_NOTREAD_UPDATE];
             case FormTaskEmum.ADMINDELEGATION:
                return [ActionTaskEnum.TASK_DELEGATIONS_VIEW, 
                     ActionTaskEnum.TASK_DELEGATIONS_UPDATE, 
                     ActionTaskEnum.TASK_DELGATIONS_DELETE];
             case FormTaskEmum.ADMINROUTES:
                 return [ActionTaskEnum.TASK_ROUTES_ADMIN_VIEW, 
                     ActionTaskEnum.TASK_ROUTES_ADMIN_UPDATE, 
                     ActionTaskEnum.TASK_ROUTES_ADMIN_VIEW_COND, 
                     ActionTaskEnum.TASK_ROUTES_UPDATE_COND, 
                     ActionTaskEnum.TASK_ROUTES_ADMIN_DELETE];
             case FormTaskEmum.ADMINUSERSBO:
                 return [ActionTaskEnum.TASK_USERSBO_VIEW, 
                     ActionTaskEnum.TASK_USERSBO_UPDATE, 
                     ActionTaskEnum.TASK_USERSBO_DELETE];
             case FormTaskEmum.ADMINUSERSAPP:
                 return [ActionTaskEnum.TASK_USERSAPP_VIEW, 
                     ActionTaskEnum.TASK_USERSAPP_UPDATE, 
                     ActionTaskEnum.TASK_USERSAPP_VIEW_ROUTES, 
                     ActionTaskEnum.TASK_USERSAPP_UPDATE_ROUTES, 
                    ActionTaskEnum.TASK_USERSAPP_DELETE];
             case FormTaskEmum.STORES:
                 return [ActionTaskEnum.TASK_STORES_VIEW, 
                     ActionTaskEnum.TASK_STORES_UPDATE, 
                     ActionTaskEnum.TASK_STORES_DELETE, 
                     ActionTaskEnum.TASK_STORES_VALISE_VIEW, 
                     ActionTaskEnum.TASK_STORES_VALISE_UPDATE, 
                     ActionTaskEnum.TASK_STORES_VALISE_DELETE, 
                     ActionTaskEnum.TASK_STORES_ASSIGDELIVERY_VIEW, 
                     ActionTaskEnum.TASK_STORES_ASSIGDELIVERY_UPDATE, 
                     ActionTaskEnum.TASK_STORES_ASSIGDELIVERY_DELETE, 
                     ActionTaskEnum.TASK_STORES_ASSIGCOLLECTED_VIEW, 
                     ActionTaskEnum.TASK_STORES_ASSIGCOLLECTED_UPDATE, 
                    ActionTaskEnum.TASK_STORES_ASSIGCOLLECTED_DELETE];
             case FormTaskEmum.VALISES:
                 return [ActionTaskEnum.TASK_VALISES_VIEW, 
                     ActionTaskEnum.TASK_VALISES_VIEW_DETALL, 
                     ActionTaskEnum.TASK_VALISES_AUTOASSIG, 
                     ActionTaskEnum.TASK_VALISES_UPDATE, 
                     ActionTaskEnum.TASK_VALISES_DELETE, 
                     ActionTaskEnum.TASK_VALISES_INTERMEDIATE_VIEW, 
                     ActionTaskEnum.TASK_VALISES_INTERMEDIATE_UPDATE, 
                     ActionTaskEnum.TASK_VALISES_INTERMEDIATE_DELETE, 
                     ActionTaskEnum.TASK_VALISES_IMPORT];
             case FormTaskEmum.PACKAGING:
                 return [ActionTaskEnum.TASK_PACKAGING_VIEW, 
                     ActionTaskEnum.TASK_PACKAGING_REGULARIZE,
                    ActionTaskEnum.TASK_PACKAGING_DETAIL];
             case FormTaskEmum.LOGINVERSE:
                 return [ActionTaskEnum.TASK_LOGINVERSE_VIEW, 
                     ActionTaskEnum.TASK_LOGINVERSE_VIEW_DETALL, 
                     ActionTaskEnum.TASK_LOGINVERSE_UPDATE, 
                     ActionTaskEnum.TASK_LOGINVERSE_DELETE, 
                     ActionTaskEnum.TASK_LOGINVERSE_INTERMEDIATE_VIEW, 
                     ActionTaskEnum.TASK_LOGINVERSE_INTERMEDIATE_UPDATE, 
                     ActionTaskEnum.TASK_LOGINVERSE_INTERMEDIATE_DELETE];
             case FormTaskEmum.PAYMENT:
                      return [ActionTaskEnum.TASK_ORDERS_PAYMENT_VIEW];
             case FormTaskEmum.PREDICTION:
                        return [ActionTaskEnum.TASK_PREDICTION_ARTICLE, 
                            ActionTaskEnum.TASK_PREDICTION_ANOMALIES, 
                            ActionTaskEnum.TASK_PREDICTION_CLASSIFICATION,
                            ActionTaskEnum.TASK_PREDICTION_ENTRENAMENT];
             default:
             return [];
         }
     }
//  }

}
