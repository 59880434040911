<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" *ngIf="display">

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <div class="container">

      <!-- NAME -->
      <div class="row mt-3">
        <div class="col d-flex align-items-center">
          <label for="fineStatus-name" class="custom-label" i18n>
            {{'tableManagement.vehicleStatus.form.name' | translate}}
          </label>
        </div>
        <div class="col">
          <div class="float-end">
            <input type="text" id="name" class="form-control custom-input" [(ngModel)]="entity.name">
          </div>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <div class="row mt-3">
        <div class="col d-flex align-items-center">
          <label for="fineStatus-name" class="custom-label" i18n>
            {{'general.description' | translate}}
          </label>
        </div>
        <div class="col">
          <div class="float-end">
            <input type="text" id="description" class="form-control custom-input" [(ngModel)]="entity.description">
          </div>
        </div>
      </div>

      <!-- OBSERVATIONS -->
      <div class="row mt-3">
        <div class="col d-flex align-items-center">
          <label for="fineStatus-name" class="custom-label" i18n>
            {{'general.observations' | translate}}
          </label>
        </div>
        <div class="col">
          <div class="float-end">
            <input type="text" id="observations" class="form-control custom-input" [(ngModel)]="entity.observations">
          </div>
        </div>
      </div>

      <!-- ENABLED -->
      <div class="row mt-3 mb-3">
        <div class="col-6 d-flex align-items-center">
          <label for="fineStatus-enabled" class="custom-label" i18n>
            {{'control-panel.poi-categories.poiEnabled' | translate }}
          </label>
        </div>
        <div class="col">
          <div class="float-end">
            <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
              type="checkbox" [(ngModel)]="entity.enabled" disabled="true">
          </div>
        </div>
      </div>

      <!-- PERIODICITY TYPE -->
      <div class="row mt-3 mb-3 mt-3">
        <div class="col-6 d-flex align-items-center">
          <label for="fineStatus-enabled" class="custom-label" i18n>
            {{'control-panel.maintenance-plans.periodicity' | translate }}
          </label>
        </div>
        <div class="col">
          <div class="float-end">
            <p-radioButton i18n-label label="{{'control-panel.maintenance-plans.time' | translate}}" name="groupname"
              value="TIME_BASED" [(ngModel)]="entity.periodicityType"></p-radioButton>
            <p-radioButton [style]="{'margin-left':'20px'}" i18n-label
              label="{{'control-panel.maintenance-plans.kms' | translate}}" name="groupname" value="DISTANCE_BASED"
              [(ngModel)]="entity.periodicityType"></p-radioButton>
          </div>
        </div>
      </div>


      <div *ngIf="this.entity.periodicityType === 'TIME_BASED'">

        <!-- PERIODICITY -->
        <div class="row mt-4">
          <div class="col d-flex align-items-center">
            <label for="fineStatus-name" class="custom-label" i18n>
              {{'control-panel.maintenance-plans.periodicity' | translate}} {{'control-panel.maintenance-plans.time' |
              translate}}
            </label>
          </div>
          <div class="col">

            <div class="float-end">
              <p-dropdown [options]="periodicityTimeUnitOptions" [(ngModel)]="entity.periodicityTimeUnit"
                (onChange)="onTimeUnitChange($event)" [style]="{'width':'100px'}"></p-dropdown>
            </div>

            <div class="p-2 float-end"></div>

            <div class="float-end">
              <p-inputNumber [(ngModel)]="entity.periodicity" [showButtons]="true" [step]="1"
                [inputStyle]="{'width':'50px'}"></p-inputNumber>
            </div>

          </div>
        </div>


        <!-- NOTIFY BEFORE -->
        <div class="row mt-3">
          <div class="col d-flex align-items-center">
            <label for="fineStatus-name" class="custom-label" i18n>
              {{'control-panel.maintenance-plans.notifyBefore' | translate}} ({{notifyBeforeTimeString}})
            </label>
          </div>
          <div class="col">
            <div class="float-end">
              <p-inputNumber [(ngModel)]="entity.notifyBefore" [showButtons]="true" [step]="1" [min]="0" [max]="365"
                [inputStyle]="{'width':'210px'}"></p-inputNumber>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="this.entity.periodicityType === 'DISTANCE_BASED'">

        <!-- PERIODICITY -->
        <div class="row mt-4">
          <div class="col d-flex align-items-center">
            <label for="fineStatus-name" class="custom-label" i18n>
              {{'control-panel.maintenance-plans.periodicity' | translate}} {{'control-panel.maintenance-plans.kms' |
              translate}}
            </label>
          </div>
          <div class="col">
            <div class="float-end">
              <p-inputNumber [(ngModel)]="entity.periodicity" [showButtons]="true"
                [inputStyle]="{'width':'210px'}"></p-inputNumber>
            </div>
          </div>
        </div>

        <!-- NOTIFY BEFORE -->
        <div class="row mt-3">
          <div class="col d-flex align-items-center">
            <label for="fineStatus-name" class="custom-label" i18n>
              {{'control-panel.maintenance-plans.notifyBefore' | translate}} ({{'control-panel.maintenance-plans.kms' |
              translate}})
            </label>
          </div>
          <div class="col">
            <div class="float-end">
              <p-inputNumber [(ngModel)]="entity.notifyBefore" [showButtons]="true"
                [inputStyle]="{'width':'210px'}"></p-inputNumber>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <p-footer>
    <div class="d-flex justify-content-center bd-highlight">

      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.save' | translate}}
          </button>
        </div>

        <div *ngIf="isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.create' | translate}}
          </button>
        </div>
      </div>

      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="changeStatus(!entity.enabled)" i18n>
            {{'general.changeStatus' | translate}}
          </button>
        </div>
      </div>

    </div>
  </p-footer>

</p-dialog>