import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-serial-port-settings-gl320m',
  templateUrl: './serial-port-settings-gl320m.component.html',
  styleUrls: ['./serial-port-settings-gl320m.component.css']
})
export class SerialPortSettingsGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  indexOptions: any = [
   { label: "9600", value: "5"},
   { label: "115200", value: "12"},
  ]
 
   @Input() urtParameters: Rest.SerialPortSettingsGL320M;
   constructor(protected i18n: I18nService) { super(i18n); }
 
   ngOnInit() {
   }
 
 
 
   ngOnChanges(changes: SimpleChanges){
     if (changes["deviceParams"]) {
       console.log("arrive deviceParams", this.urtParameters);
     }
   }
 
 }
