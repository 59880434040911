<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Global parameters
          (AT + GTCFG)</span>
      </div>
    </div>
  </p-header>
  <!--   <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.speedEvent.enabled>-->
  <!-- <input type="text" [(ngModel)]="deviceParams.balh"> -->


  <div class="ui-g">



    <!-- <div [class.disableDIV]=!trackModeSwitch> -->


    <div class="ui-g-12">
      <form-input fieldName="Event Mask">

      </form-input>
    </div>


    <div class="ui-g-12">

      <div class="ui-g-12">

        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTPNA" [(ngModel)]="globalParameters.eventMaskRESPGTPNA">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTPFA" [(ngModel)]="globalParameters.eventMaskRESPGTPFA">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTMPN" [(ngModel)]="globalParameters.eventMaskRESPGTMPN">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTMPF" [(ngModel)]="globalParameters.eventMaskRESPGTMPF">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTBPL" [(ngModel)]="globalParameters.eventMaskRESPGTBPL">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTBTC" [(ngModel)]="globalParameters.eventMaskRESPGTBTC">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTSTC" [(ngModel)]="globalParameters.eventMaskRESPGTSTC">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" binary="true" label="+RESP:GTSTT" [(ngModel)]="globalParameters.eventMaskRESPGTSTT">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" binary="true" label="+RESP:GTANT" [(ngModel)]="globalParameters.eventMaskRESPGTANT">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" binary="true" label="+RESP:GTPDP" [(ngModel)]="globalParameters.eventMaskRESPGTPDP">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" binary="true" label="+RESP:GTPNL" [(ngModel)]="globalParameters.eventMaskRESPGTPNL">
          </p-checkbox>
        </div>

        <div class="ui-g-12">
          <p-checkbox binary="true" label="Ignition ON +RESP:GTIGN, +RESP:GTIGF"
            [(ngModel)]="globalParameters.eventMaskIgnitionOnRESPGTIGNGTIGF"></p-checkbox>
        </div>
        <div class="ui-g-12">
          <p-checkbox binary="true" label="Ignition ON/OFF +RESP:GTIGL"
            [(ngModel)]="globalParameters.eventMaskIgnitionOnOffRESPGTIGL"></p-checkbox>
        </div>
        <div class="ui-g-12">
          <p-checkbox binary="true" label="+RESP:GTEXP"
            [(ngModel)]="globalParameters.eventMaskRESPGTEXP"></p-checkbox>
        </div>
      </div>
    </div>


    <div class="ui-g-12 ui-md-6">
      <form-input fieldName="Power Saving Mode">
        <p-dropdown [options]="powerSavingOptions" [(ngModel)]="globalParameters.powerSaving"
          [style]="{'width':'190px'}">
        </p-dropdown>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input fieldName="Info Report Enable">
        <p-inputSwitch [(ngModel)]="globalParameters.infoReportEnable"></p-inputSwitch>
      </form-input>
    </div>


    <div class="ui-g-12 ui-md-6">
      <form-input fieldName="Info Report Interval">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="infoReportInterval" size="7" [(ngModel)]="globalParameters.infoReportInterval"
            [min]="30" [max]="86400"></p-spinner>
          <span class="ui-inputgroup-addon">30~86400 </span>
          <span class="ui-inputgroup-addon" >seconds</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="GPS Lost Time">
        <div class="ui-inputgroup">
          <p-spinner step="1" id="gpsLostTime" size="2" [(ngModel)]="globalParameters.gpslostTime" [min]="0"
            [max]="30"></p-spinner>
          <span class="ui-inputgroup-addon">0~30 </span>
          <span class="ui-inputgroup-addon" >minutes</span>
        </div>
      </form-input>
    </div>



    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="gsm Report">
        <!-- [disabled]="!globalParameters." -->
        <p-dropdown [options]="GSMReportOptions" [(ngModel)]="globalParameters.gsmreportMode"
          [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>

    <div class="ui-g-12">

      <div class="ui-g-0 ui-md-6">
      </div>
      <div class="ui-g-12">

        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTRTL" [(ngModel)]="globalParameters.gsmreportRESPGTRTL">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTLBC" [(ngModel)]="globalParameters.gsmreportRESPGTLBC">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTFRI +RESP:GTERI"
            [(ngModel)]="globalParameters.gsmreportRESPGTFRIRESPGTERI"></p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTSOS" [(ngModel)]="globalParameters.gsmreportRESPGTSOS">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="+RESP:GTTOW" [(ngModel)]="globalParameters.gsmreportRESPGTTOW">
          </p-checkbox>
        </div>
      </div>
    </div>


    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="LED On">
        <p-inputSwitch [(ngModel)]="globalParameters.ledON"></p-inputSwitch>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Wake up Hour Interval">
        <div class="ui-inputgroup">
          <p-spinner step="1" [(ngModel)]="globalParameters.wakeUpHourInterval" [min]="0"
            [max]="30"></p-spinner>
          <span class="ui-inputgroup-addon">1~720 </span>
          <span class="ui-inputgroup-addon" >hours</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Sensor Wake up Debounce">
        <div class="ui-inputgroup">
          <p-spinner step="1" [(ngModel)]="globalParameters.sensorWakeUpDebounce" [min]="0"
            [max]="60"></p-spinner>
          <span class="ui-inputgroup-addon">0~60 </span>
          <span class="ui-inputgroup-addon" >s</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Work time Wake Up">
        <div class="ui-inputgroup">
          <p-spinner step="1" [(ngModel)]="globalParameters.workTimeWakeUp" [min]="3"
            [max]="60"></p-spinner>
          <span class="ui-inputgroup-addon">3~60 </span>
          <span class="ui-inputgroup-addon" >minutes</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="One Wire Sample Period">
        <div class="ui-inputgroup">
          <p-spinner step="1" [(ngModel)]="globalParameters.oneWireSamplePeriod" [min]="0"
            [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">0~255</span>
          <span class="ui-inputgroup-addon" >s</span>
        </div>
      </form-input>
    </div>

  </div>
  <!-- </div> -->
</p-accordionTab>
