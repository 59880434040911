import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { EntityService } from './entity.service';
@Injectable()
export class SimOperatorTypeService extends EntityService<Rest.SimOperatorType> {
   client: Rest.RestApplicationClient;
   constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
   }

   getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.SimOperatorType>> {
      return this.client.SimOperatorType_getPage(arg0);
   }
}
