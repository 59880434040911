<!-- SEND MESSAGE DIALOG -->
<p-dialog header="{{ 'general.sendMessage' | translate }} {{this.headerContent}}"
		  [(visible)]='displayDialog'
		  [modal]='true'
		  [resizable]='false'
		  [draggable]='false'
		  [closeOnEscape]='false'
		  [style]="{ width: '60vw' }"
		  (onHide)='initSendMessageDialog()'
		  class='custom-dialog'
>

	<!-- SEND MESSAGE DIALOG BODY -->
	<ng-template pTemplate='content'>

		<div class='d-flex flex-column w-100 h-100 gap-3'>

			<div class='d-flex'>
				<label class='custom-label'>
					{{ 'general.content' | translate }}:
				</label>
			</div>

			<textarea pInputTextarea
					  id='send-message-textarea'
					  [(ngModel)]='messageContent'
					  [rows]='5'
					  [cols]='30'
					  [autoResize]='true'
			></textarea>

		</div>

	</ng-template>

	<!-- SEND MESSAGE DIALOG FOOTER -->
	<ng-template pTemplate='footer'>

		<div class='d-flex flex-row justify-content-end w-100'>

			<button class='btn custom-button' (click)='sendMessage()' [disabled]="messageContent === ''">
				{{ 'general.sendMessageButton' | translate }}
			</button>

		</div>

	</ng-template>

</p-dialog>
