import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-settings-stats',
  templateUrl: './settings-stats.component.html',
  styleUrls: ['./settings-stats.component.css']
})
export class SettingsStatsComponent{

  chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels:{
            boxWidth: 5,
            generateLabels: function(chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function(label, i) {
                    var dataset = data.datasets[0];
                    var value = dataset.data[i];
                    return {
                      text: value,
                      fillStyle: dataset.backgroundColor[i],
                      hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                      index: i,
                      datasetIndex: 0
                    };
                  });
                }
                return [];
              }
        }

      }
    },
    animation: {
      duration: 0
    }
  };
  constructor(
  ) {
  }

}



