import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app.routing';
import { RouteReuseStrategy } from '@angular/router';

/*----------------------*/
/* <--- COMPONENTS ---> */
/*----------------------*/
import { DevicesListComponent } from './components/device-edit-view/devices-list/devices-list.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { FlexibleGrowlComponent } from './components/flexible-growl/flexible-growl.component';
import { HackTranslationsComponent } from './components/hack-translations/hack-translations.component';
import {
    MaintenanceExecutionFormComponent
} from './components/maintenance-execution-form/maintenance-execution-form.component';
import { MaintenanceFormComponent } from './components/maintenance-form/maintenance-form.component';
import { MaintenancePlanFormComponent } from './components/maintenance-plan-form/maintenance-plan-form.component';
import { MaintenancesViewListComponent } from './components/maintenances-view-list/maintenances-view-list.component';
import { PoiCategoryFormComponent } from './components/pois/poi-category-form/poi-category-form.component';
import { PoiFormComponent } from './components/pois/poi-form/poi-form.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { RealTimeBaseComponent } from './components/real-time/base/rt-base.component';
import { RoleFormComponent } from './components/roles/role-form/role-form.component';
import { RolesListComponent } from './components/roles/roles-list/roles-list.component';
import { ZoneGroupFormComponent } from './components/zones/zone-group-form/zone-group-form.component';
import {
    ConversionAlorithmFormComponent
} from './components/conversion-algorithm/conversion-algorithm-form/conversion-algorithm-form-component';
import { RuleFormComponent } from './components/rule/rule-form/rule-form-component';
import {
    DeviceTypesListComponent
} from './components/device-type-edit-view/device-types-list/device-types-list.component';
import { FleetFormComponent } from './components/fleet-edit-view/fleet-form/fleet-form.component';
import { FleetTreeComponent } from './components/fleet-edit-view/fleet-tree/fleet-tree.component';
import { GroupFormComponent } from './components/fleet-edit-view/group-form/group-form.component';
import { VehicleFormComponent } from './components/fleet-edit-view/vehicle-form/vehicle-form.component';
import { HomeComponent } from './components/home/home.component';
import { PagedPickList } from './components/paged-picklist/paged-picklist';
import { RealTimeFleetTreeComponent } from './components/real-time/side-panel/fleet-tree/rt-fleet-tree.component';
import { SensorChartComponent } from './components/real-time/side-panel/sensor-chart/sensor-chart.component';
import { ReportFormComponent } from './components/reports/report-form/report-form.component';
import { ReportListComponent } from './components/reports/report-list/report-list.component';
import { VehicleActivityReport } from './components/reports/vehicle-activity-report/vehicle-activity-report.component';
import { UserSettingsComponent } from './components/users/user-settings/user-settings.component';
import { ZoneFormComponent } from './components/zones/zone-form/zone-form.component';
import { RacingFormComponent } from './components/racing/racing-form/racing-form.component';
import { RacingListComponent } from './components/racing/racing-list/racing-list.component';
import { SidePanelComponent } from './components/real-time/side-panel/side-panel.component';
import { DataTableComponent } from './components/real-time/vehicle-panel/data-table/data-table.component';
import { EventsTableComponent } from './components/real-time/vehicle-panel/events-table/events-table.component';
import {
    VehiclePanelSettingsComponent
} from './components/real-time/vehicle-panel/settings/vehicle-panel-settings.component';
import { VehicleCardComponent } from './components/real-time/vehicle-panel/vehicle-card/vehicle-card.component';
import { VehiclePanelComponent } from './components/real-time/vehicle-panel/vehicle-panel.component';
import { CallBackComponent } from './components/real-time/vehicle-panel/views/call-back/call-back.component';
import { VehicleDetailsComponent } from './components/real-time/vehicle-panel/views/details/vehicle-details.component';
import { IOComponent } from './components/real-time/vehicle-panel/views/io/io.component';
import {
    QuickActionsComponent
} from './components/real-time/vehicle-panel/views/quick-actions/quick-actions.component';
import { SMSComponent } from './components/real-time/vehicle-panel/views/sms/sms.component';
import {
    VehicleTelemetryComponent
} from './components/real-time/vehicle-panel/views/telemetry/vehicle-telemetry.component';
import { KeysPipe } from './components/shared/KeysPipe';
import { UserTimePipe } from './components/shared/UserTimePipe';
import {
    ConversionAlogrithmListComponent
} from './components/conversion-algorithm/conversion-algorithm-list/conversion-algorithm-list.component';
import { RuleListComponent } from './components/rule/rule-list/rule-list.component';
import { TranslationComponent } from './components/translation-list/translation-list.component';
import {
    MaintenanceExecutionsListComponent
} from './components/maintenance-executions-list/maintenance-executions-list.component';
import { AddIconsFormComponent } from './components/pois/add-icons-form/add-icons-form.component';
import {
    DelayInputConfigFormComponent
} from './components/device-parameters/vt10-parameters-form/delay-input-config-form/delay-input-config-form.component';
import { DummyComponent } from './components/dummy-component/dummy.component';
import { NoticeGroupFormComponent } from './components/notice-group/notice-group-form/notice-group-form.component';
import { NoticeGroupsListComponent } from './components/notice-group/notice-groups-list/notice-groups-list.component';
import { ConnectionWatchComponent } from './components/real-time/connection-watch/connection-watch.component';
import { RtMapComponent } from './components/real-time/rt-map/rt-map.component';
import { BillingAccountFormComponent } from './components/clients/billing-account-form/billing-account-form.component';
import {
    BillingAccountsListComponent
} from './components/clients/billing-accounts-list/billing-accounts-list.component';
import { ClientFormComponent } from './components/clients/client-form/client-form.component';
import { ClientsListComponent } from './components/clients/clients-list/clients-list.component';
import {
    ArmConfgiurationComponent
} from './components/device-parameters/vt10-parameters-form/arm-confgiuration/arm-confgiuration.component';
import {
    IdleEventConfigurationComponent
} from './components/device-parameters/vt10-parameters-form/idle-event-configuration/idle-event-configuration.component';
import {
    JamEventConfigComponent
} from './components/device-parameters/vt10-parameters-form/jam-event-config/jam-event-config.component';
import {
    MetadataEditor as MetadataEditorComponent
} from './components/fleet-edit-view/metadata-editor/metadata-editor.component';
import {
    PoiCategoriesFileFormComponent
} from './components/pois/poi-categories-file-form/poi-categories-file-form.component';
import { MillisecondsToDuration } from './components/ute-rondes/pipes/millisecondsToDuration';
import { UteControlPointHistory } from './components/ute-rondes/ute-control-point-history/ute-control-point-history';
import { UTEMapComponent } from './components/ute-rondes/ute-map/ute-map.component';
import { UtePanelTableComponent } from './components/ute-rondes/ute-panel-table/ute-panel-table.component';
import { UtePanelComponent } from './components/ute-rondes/ute-panel/ute-panel.component';
import { UteVehicleHistory } from './components/ute-rondes/ute-vehicle-history/ute-vehicle-history';
import { ViewSettingsFormComponent } from './components/ute-rondes/view-settings-form/view-settings-form.component';
import { MapImagePrint } from './components/real-time/map-menu/map-image-print/map-image.print';
import {
    VehicleProximityDialog
} from './components/real-time/map-menu/vehicle-proximity-dialog/vehicle-proximity-dialog';
import { PoCVisitFormComponent } from './components/ute-rondes/poc-visit-form/poc-visit-form.component';
import { UteReportEventsComponent } from './components/ute-rondes/ute-report-events/ute-report-events.component';
import { UteSummaryTableComponent } from './components/ute-rondes/ute-summary-table/ute-summary-table.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { NavbarComponent } from './navigation/navbar/navbar.component';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { PoiRacingFormComponent } from './components/pois/poi-racing-form/poi-racing-form.component';
import { InfoCardComponent } from './components/real-time/info-card/info-card.component';


/*--------------------*/
/* <--- SERVICES ---> */
/*--------------------*/
import { UserService } from './rest/user.service';
import { NotificationsService } from './services/notifications-service/notifications.service';
import { RealTimeDataService } from './services/rt-data/rt-data.service';
import { I18nService } from './services/i18n/i18n.service';
import { CercaliaResolver } from './services/resolves/cercalia.reolver';
import { LoadingService } from './services/loading-service/loading.service';
import { JQueryHTTPClient } from './rest/JQueryHTTPClient';
import { DelegationService } from './modules/task/administration/services/delegation.service';

/*-----------------*/
/* <--- OTHER ---> */
/*-----------------*/
import { OldSharedModule } from './components/shared/old-shared.module';
import { TokenInterceptor } from './core/interceptors/token/token.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './modules/shared/shared.module';
import { TempExportComponentsModule } from './modules/temp-export-components/temp-export-components.module';
import { SatRouteReuseStrategy } from './modules/sat/helpers/sat-route-reuse.strategy';
import { AdministrationRouteReuseStrategy } from './modules/task/administration/helpers/administration-route-reuse.strategy'; 
import { LogisticsRouteReuseStrategy } from './modules/task/Logistics/helpers/logistics-route-reuse.strategy'; 
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MessageService } from 'primeng/api';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import { TreeQuickActionsComponent } from './components/real-time/side-panel/fleet-tree/tree-quick-actions/tree-quick-actions.component';
import { SensorChartV2Component } from './components/real-time/side-panel/sensor-chart-v2/sensor-chart-v2.component';
import { SettingsStatsComponent } from './components/real-time/settings-stats/settings-stats.component';
import { ChartSettingsComponent } from './components/real-time/chart-settings/chart-settings.component';
import { VehicleModelFormComponent } from './components/fleet-edit-view/vehicle-model-form/vehicle-model-form.component';
import { VehicleBrandFormComponent } from './components/fleet-edit-view/vehicle-brand-form/vehicle-brand-form.component';
import { DataImportComponent } from './components/data-import/data-import.component';
import { DataImportNavbarComponent } from './navigation/data-import-navbar/data-import-navbar.component';
import { CardsComponent } from './components/data-import/cards/cards.component';
import { CepsaComponent } from './components/data-import/cepsa/cepsa.component';
import { InsurancesComponent } from './components/data-import/insurances/insurances.component';
import { ChangeStatusDialogInvertedComponent } from './components/change-status-dialog-inverted/change-status-dialog-inverted.component';
import { MaintenanceExecutionStatusColorPipe } from './components/shared/maintenance-execution-status-color.pipe';
import { MaintenanceExecutionAlertComponent } from './components/maintenance-execution-alert/maintenance-execution-alert/maintenance-execution-alert.component';
import { UnitsMeasureComponent } from './components/task/units-measure/units-measure.component';
import { NotificationPanelComponent } from './navigation/header/notification-panel/notification-panel.component';
import { TimerRefreshService } from './services/timer/timer-refresh.service';
 

registerLocaleData(localeEs, 'es');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeEn, 'en');

@NgModule({
    declarations: [
        AppComponent,
        routingComponents,
        FlexibleGrowlComponent,
        ReportFormComponent,
        ReportListComponent,
        VehicleActivityReport,
        ProfileFormComponent,
        PoiCategoryFormComponent,
        PoiFormComponent,
        //MaintenanceExecutionFormComponent,
        MaintenancePlanFormComponent,
        MaintenancesViewListComponent,
        ZoneGroupFormComponent,
        ZoneFormComponent,
        RuleFormComponent,
        FleetTreeComponent,
        FleetFormComponent,
        GroupFormComponent,
        VehicleFormComponent,
        DeviceTypesListComponent,
        TranslationComponent,
        RuleListComponent,
        ConversionAlogrithmListComponent,
        EntityFormComponent,
        EntityListComponent,
        DevicesListComponent,
        HackTranslationsComponent,
        RolesListComponent,
        RoleFormComponent,
        MaintenanceFormComponent,
        HomeComponent,
        UserSettingsComponent,
        PagedPickList,
        RealTimeBaseComponent,
        SensorChartComponent,
        RealTimeFleetTreeComponent,
        SidePanelComponent,
        VehiclePanelComponent,
        VehicleCardComponent,
        VehicleDetailsComponent,
        VehiclePanelSettingsComponent,
        VehicleTelemetryComponent,
        IOComponent,
        SMSComponent,
        CallBackComponent,
        QuickActionsComponent,
        KeysPipe,
        //MaintenanceExecutionsListComponent,
        AddIconsFormComponent,
        DataTableComponent,
        EventsTableComponent,
        ConnectionWatchComponent,
        RtMapComponent,
        UTEMapComponent,
        DummyComponent,
        NoticeGroupsListComponent,
        NoticeGroupFormComponent,
        ConversionAlorithmFormComponent,
        DummyComponent,
        DelayInputConfigFormComponent,
        JamEventConfigComponent,
        IdleEventConfigurationComponent,
        ArmConfgiurationComponent,
        MetadataEditorComponent,
        PoiCategoriesFileFormComponent,
        ClientsListComponent,
        ClientFormComponent,
        BillingAccountsListComponent,
        BillingAccountFormComponent,
        ViewSettingsFormComponent,
        UtePanelComponent,
        UtePanelTableComponent,
        MillisecondsToDuration,
        UteControlPointHistory,
        UteVehicleHistory,
        PoCVisitFormComponent,
        UteSummaryTableComponent,
        UteReportEventsComponent,
        VehicleProximityDialog,
        MapImagePrint,
        HeaderComponent,
        SidebarComponent,
        NavbarComponent,
        ControlPanelComponent,
        RacingListComponent,
        RacingFormComponent,
        PoiRacingFormComponent,
        InfoCardComponent,
        TreeQuickActionsComponent,
        SensorChartV2Component,
        SettingsStatsComponent,
        ChartSettingsComponent,
        VehicleModelFormComponent,
        VehicleBrandFormComponent,
        DataImportComponent,
        DataImportNavbarComponent,
        CardsComponent,
        CepsaComponent,
        InsurancesComponent,
        ChangeStatusDialogInvertedComponent,
        NotificationPanelComponent
    ],
    imports: [
        SharedModule,
        TempExportComponentsModule,
        NgxWebstorageModule.forRoot(),
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        OldSharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],

    providers: [
        CercaliaResolver,
        JQueryHTTPClient,
        NotificationsService,
        I18nService,
        DecimalPipe,
        DatePipe,
        UserService,
        RealTimeDataService,
        LoadingService,
        UserTimePipe,
        MessageService,
        DelegationService,
      { provide: RouteReuseStrategy, useClass: SatRouteReuseStrategy },
        // { provide: RouteReuseStrategy, useClass: AdministrationRouteReuseStrategy },
       // { provide: RouteReuseStrategy, useClass: LogisticsRouteReuseStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'es' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static injector: Injector;

    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
