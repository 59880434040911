<!-- GENERAL PANEL -->
<p-panel *ngIf='isPanelVisible' header="{{ 'promotedRoutes.targetList' | translate }}"
	[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header [toggleable]="true" [(collapsed)]="collapsed"
	style="width: 100% !important">

	<!-- <div class='d-flex'> -->
		<div class="row align-items-center">

		<!-- TARGETS PRIMENG DATATABLE -->
		<div class="col row dvcFields">

			<p-table [value]='targetList' id="target-table" dataKey='id' [rows]='10'
				[rowsPerPageOptions]='[10,20,30,40,50]' [paginator]='true' [rowHover]='true'
				[showCurrentPageReport]='true' [autoLayout]='true' [ngClass]="{'disabledPanel': false}"
				currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
				styleClass='p-datatable-striped' [tableStyle]="{ 'max-width': '500px' }" selectionMode="single"
				metaKeySelection="true">

				<!------ TABLE COLUMN DEFINITION ------------------------------------------------------------>
				<ng-template pTemplate='header'>
					<tr>
						<!-- SELECT -->
						<th class='checkbox-column'>
							<!-- EMPTY -->
						</th>

						<!-- NAME -->
						<th>
							<div class="d-flex align-items-center h-100">
								<!-- COLUMN TITLE -->
								<div
									class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
									{{ 'general.name' | translate }}
								</div>
							</div>
						</th>

						<!-- Description -->
						<th>
							<div class="d-flex align-items-center h-100">
								<!-- COLUMN TITLE -->
								<div
									class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
									{{ 'promotedRoutes.description' | translate }}
								</div>
							</div>
						</th>
					</tr>
				</ng-template>

				<!------- TABLE BODY ------------------------------------------------------------------------>
				<!-- (dblclick)='editTarget(target)' -->
				<ng-template pTemplate='body' let-target>
					<tr class='p-selectable-row custom-rows' [pSelectableRow]='target' (click)='showFilters(target)'>

						<!-- DELETE -->
						<td>
							<button type='button' class='btn custom-table-remove-button' (click)='deleteTarget(target)'>
								<svg-icon src='assets/icons/trash.svg'
									class='d-flex flex-wrap custom-table-remove-button-icon'
									[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"></svg-icon>
							</button>
						</td>

						<!-- NAME -->
						<td>
							{{ target.name }}
						</td>

						<!-- Description -->
						<td>
							{{ target.description }}
						</td>

					</tr>
				</ng-template>

				<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8" class="p-0">
							<div *ngIf="!loaded"
								class="d-flex align-items-center justify-content-center h-100 p-3 custom-empty-message"
								i18n>
								{{ 'general.loading' | translate }}
							</div>
							<div *ngIf="loaded"
								class="d-flex align-items-center justify-content-center h-100 p-3 custom-empty-message"
								i18n>
								{{ 'table.tableEmptyMessage' | translate }}!
							</div>
						</td>
					</tr>
				</ng-template>

				<!------ FOOTER ----------------------------------------------------------------------------->
				<ng-template pTemplate="footer">
					<tr>
						<td colspan="8" class="p-0">
							<div class="d-flex flex-row align-items-center p-3 gap-3">

								<!-- ADD RACING EVENT BUTTON -->
								<app-table-add-button [text]=" 'promotedRoutes.addTarget' | translate "
									(btnClick)="createTarget()"></app-table-add-button>

								<button type='button' class='btn custom-button' (click)='editTarget()'
									[disabled]='selectedTarget === null'>
									{{ 'general.edit' | translate }}
								</button>

							</div>
						</td>
					</tr>
				</ng-template>

			</p-table>

		</div>

		<!-- FILTERS PRIMENG DATATABLE -->
		<div class="col row dvcFields">

			<p-table [value]='filterList' id="filter-table" dataKey='id' [rows]='10'
				[rowsPerPageOptions]='[10,20,30,40,50]' [paginator]='true' [rowHover]='true'
				[showCurrentPageReport]='true' [autoLayout]='true' [ngClass]="{'disabledPanel': false}"
				currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
				styleClass='p-datatable-striped' [tableStyle]="{ 'max-width': '500px' }" selectionMode="single"
				metaKeySelection="true">

				<!------ TABLE COLUMN DEFINITION ------------------------------------------------------------>
				<ng-template pTemplate='header'>
					<tr>
						<!-- SELECT -->
						<th class='checkbox-column'>
							<!-- EMPTY -->
						</th>

						<!-- NAME -->
						<th>
							<div class="d-flex align-items-center h-100">
								<div
									class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
									{{ 'promotedRoutes.filter-parameter' | translate }}
								</div>
							</div>
						</th>

						<!-- filter-type -->
						<th>
							<div class="d-flex align-items-center h-100">
								<div
									class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
									{{ 'promotedRoutes.filter-type' | translate }}
								</div>
							</div>
						</th>

						<!-- filter-value -->
						<th>
							<div class="d-flex align-items-center h-100">

								<div
									class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
									{{ 'promotedRoutes.filter-value' | translate }}
								</div>
							</div>
						</th>
					</tr>
				</ng-template>

				<!------- TABLE BODY ------------------------------------------------------------------------>
				<ng-template pTemplate='body' let-filter>
					<tr class='p-selectable-row custom-rows' [pSelectableRow]='filter' (click)='editFilter(filter)'>

						<!-- DELETE -->
						<td>
							<button type='button' class='btn custom-table-remove-button' (click)='deleteFilter(filter)'>
								<svg-icon src='assets/icons/trash.svg'
									class='d-flex flex-wrap custom-table-remove-button-icon'
									[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"></svg-icon>
							</button>
						</td>

						<!-- NAME -->
						<td>
							{{ filter.parameter }}
						</td>

						<!-- Description -->
						<td>
							{{ filter.filterType }}
						</td>

						<!-- Description -->
						<td> {{ filter.value | valueOf: filter.parameter }}</td>

					</tr>
				</ng-template>

				<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8" class="p-0">
							<div *ngIf="!loaded"
								class="d-flex align-items-center justify-content-center h-100 p-3 custom-empty-message"
								i18n>
								{{ 'general.loading' | translate }}
							</div>
							<div *ngIf="loaded"
								class="d-flex align-items-center justify-content-center h-100 p-3 custom-empty-message"
								i18n>
								{{ filterListLoaded ? ('table.tableEmptyMessage' | translate) :
								('promotedRoutes.filterListPending' | translate) }}
							</div>
						</td>
					</tr>
				</ng-template>

				<!------ FOOTER ----------------------------------------------------------------------------->
				<ng-template pTemplate="footer">
					<tr>
						<td colspan="8" class="p-0">
							<div class="d-flex flex-row align-items-center p-3 gap-3">

								<!-- ADD RACING EVENT BUTTON -->
								<app-table-add-button [text]=" 'promotedRoutes.addFilter' | translate "
									(btnClick)="createFilter()" [isDisabled]='!filterListLoaded'></app-table-add-button>

							</div>
						</td>
					</tr>
				</ng-template>

			</p-table>

		</div>

		<!-- edit filter -->
		<div class="col row dvcFields">

			<div class='d-flex row justify-content-end'>

				<div *ngIf="createNewFilterPrompt" style='color: red; padding-left: "10px"'>
					* {{ 'promotedRoutes.createNewFilterPrompt' | translate }}
				</div>

				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'promotedRoutes.filter-parameter' | translate }}">
						</form-input>
					</div>
					<div class="col">
						<p-dropdown [options]='parameterList' [(ngModel)]='selectedFilterParam'
							[style]="{'width':'100%','min-width':'100px'}" [disabled]='!modifiyFilter' appendTo='body'
							(onChange)="onChangeParameter($event)" placeholder="Select..."></p-dropdown>
					</div>
				</div>

				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'promotedRoutes.filter-type' | translate }}">
						</form-input>
					</div>
					<div class="col">
						<p-dropdown [options]='filterTypeList' [(ngModel)]='selectedFilterType'
							[style]="{'width':'100%','min-width':'100px'}" [disabled]='!filterParamSelected'
							appendTo='body' (onChange)="onChangeType($event)" placeholder="Select..."></p-dropdown>
					</div>
				</div>

				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'promotedRoutes.filter-value' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input *ngIf="!isDropdown" pInputText [(ngModel)]='selectedFilterValue' class="txtbStyle"
							[disabled]='!filterTypeSelected' />
						<p-dropdown *ngIf="isDropdown" [options]='valueList' [(ngModel)]='selectedFilterValue'
							[style]="{'width':'100%','min-width':'100px'}" [disabled]='!filterTypeSelected'
							appendTo='body' (onChange)="onChangeValue($event)" placeholder="Select...">
						</p-dropdown>
					</div>
				</div>

				<div class='d-flex flex-row-reverse bd-highlight'>
					<div class='p-2 bd-highlight'>
						<button type='button' class='btn custom-button' (click)='saveFilter()'
							[disabled]='!filterValueSelected'>
							{{ 'general.save' | translate }}
						</button>
					</div>
				</div>

				<div *ngIf='showToast'>
					<p-toast></p-toast>
					<button type="button" pButton pRipple (click)="show()" label="Show"
						class="p-button-success"></button>
				</div>

			</div>

		</div>


	</div>
	<!-- </div> -->


</p-panel>

<!-- DELETE EVENT DIALOG -->
<p-confirmDialog #deleteEventDialog [header]=" 'control-panel.delete-dialog.title' | translate " class='custom-dialog'
	icon='pi pi-exclamation-triangle'>
	<ng-template pTemplate="footer">

		<!-- REJECT BUTTON -->
		<button (click)="deleteEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>

		<!-- ACCEPT BUTTON -->
		<button (click)="deleteEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>

	</ng-template>
</p-confirmDialog>

<app-target-form [(campaign)]="campaign"></app-target-form>