<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Jamming Behavior Setting
          (AT + GTJBS)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Mode">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-inputSwitch [(ngModel)]="jbsParameters.modeEnabled"></p-inputSwitch>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Delay Duration(T1)">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.delayDuration" [min]="1" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">1~65535</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Fuel Cut-off Timer(T2)">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.fuelCutOffTimer" [min]="1" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">1~65535</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>
    
  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Motion Sensor">
        </form-input>
      </div>
      <div class="col">
        <p-inputSwitch [(ngModel)]="jbsParameters.motionSensor"></p-inputSwitch>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Speed Limit">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.speedLimit" [min]="0" [max]="999"></p-spinner>
          <span class="ui-inputgroup-addon">0~999</span>
          <span class="ui-inputgroup-addon">km/h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="GNSS Fix Fail Timer(T3)">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.gnssFixFailureTimer" [min]="30" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">30~65535</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div *ngIf="firmware > 16" class="ui-g-12 ui-md-6">
      <form-input fieldName="Release Fuel Cut-off Timer(T4)">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.releaseFuelCutOffTimer" [min]="0" [max]="1000"></p-spinner>
          <span class="ui-inputgroup-addon">0~1000</span>
          <span class="ui-inputgroup-addon">min</span>
        </div>
      </form-input>
    </div>

    <div *ngIf="firmware > 16" class="ui-g-12 ui-md-6">
      <form-input fieldName="Check Jamming in T2">
        <p-inputSwitch [(ngModel)]="jbsParameters.checkJamming"></p-inputSwitch>
      </form-input>
    </div>

    <div *ngIf="firmware > 16" class="ui-g-12 ui-md-6">
      <form-input fieldName="Waiting Release Fuel Timer (T5)">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.waitingReleaseFuelTimer" [min]="0" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">0~65335</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </form-input>
    </div>

  </div>
</p-accordionTab>