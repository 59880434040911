import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { FineReasonsFormComponent } from '../fine-reasons-form/fine-reasons-form.component';
import { FineReasonService } from 'app/modules/vehicle-inventory/services/table-management/fineReason.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { ConfirmationService } from 'primeng/api';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-fine-reasons-list',
    templateUrl: './fine-reasons-list.component.html',
    styleUrls: ['./fine-reasons-list.component.css'],
    providers: [FineReasonService, ExcelService]
})
export class FineReasonsListComponent extends EntityListComponent<Rest.FineReasons>
    implements OnInit {

    @ViewChild(FineReasonsFormComponent, { static: true })
    editForm: FineReasonsFormComponent;

    disableSave: boolean;

    user: Rest.User;

    constructor(
        private fineReasonService: FineReasonService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
    ) {
        super(
            fineReasonService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.editForm;
        this.hasEntityFilters = false;
        this.user = this.authenticationService.user;
    }

    beforeLoad(){
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    excelColumns = {};
    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.fineReason.fineReasonManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }
    setExcelColumns() {
        const reason = (entity: any) => entity.reason ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''
        this.excelColumns = {
            [this.translateService.instant('tableManagement.fineReason.reason')]: { bindField: reason },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }

}
