<div id="pre-config-form">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>

  <p-panel header="ConcoxMT200 Parameters">
    <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable"
          [options]="availablePreconfigs" [(ngModel)]="selectedPreconfig" [style]="{'width':'100%','min-width':'100px'}"
          filter="filter"></p-dropdown>
        </div>
      </div>
    </div>

    <p-tabView #tv (onChange)="tabChanged()">
      <p-tabPanel header="Events">
        <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
          <app-alarm-moving-configuration-concox-mt200
            [alarmMoving]="deviceParams.proprietaryParameters.alarmMoving"></app-alarm-moving-configuration-concox-mt200>
          <app-angle-configuration-concox-mt200
            [angle]="deviceParams.proprietaryParameters.angle"></app-angle-configuration-concox-mt200>
          <app-apnconfiguration-concox-mt200
            [apn]="deviceParams.proprietaryParameters.apn"></app-apnconfiguration-concox-mt200>
          <app-battery-alarm-configuration-concox-mt200
            [batteryAlarm]="deviceParams.proprietaryParameters.batteryAlarm"></app-battery-alarm-configuration-concox-mt200>
          <app-defense-configuration-concox-mt200
            [defense]="deviceParams.proprietaryParameters.defense"></app-defense-configuration-concox-mt200>
          <app-external-battery-alarm-configuration-concox-mt200
            [batteryAlarm]="deviceParams.proprietaryParameters.externalBatteryAlarm"></app-external-battery-alarm-configuration-concox-mt200>
          <app-external-battery-protection-configuration-concox-mt200
            [batteryAlarm]="deviceParams.proprietaryParameters.externalBatteryProtection"></app-external-battery-protection-configuration-concox-mt200>
          <app-gmtconfiguration-concox-mt200
            [gmt]="deviceParams.proprietaryParameters.gmt"></app-gmtconfiguration-concox-mt200>
          <app-gprsblocking-alarm-configuration-concox-mt200
            [gprsAlarm]="deviceParams.proprietaryParameters.gprsAlarm"></app-gprsblocking-alarm-configuration-concox-mt200>
          <app-gprsconfiguration-concox-mt200
            [gprs]="deviceParams.proprietaryParameters.gprs"></app-gprsconfiguration-concox-mt200>
          <app-gps-data-sending-interval-configuration-concox-mt200
            [gpsDataSendingInterval]="deviceParams.proprietaryParameters.gpsDataSendingInterval"></app-gps-data-sending-interval-configuration-concox-mt200>
          <app-heart-beat-interval-configuration-concox-mt200
            [heartbeatInterval]="deviceParams.proprietaryParameters.heartbeatInterval"></app-heart-beat-interval-configuration-concox-mt200>
          <app-hstconfiguration-concox-mt200
            [hst]="deviceParams.proprietaryParameters.hst"></app-hstconfiguration-concox-mt200>
          <app-instruction-password-configuration-concox-mt200
            [instructionPassword]="deviceParams.proprietaryParameters.instructionPassword"></app-instruction-password-configuration-concox-mt200>
          <app-mileage-configuration-concox-mt200
            [mileage]="deviceParams.proprietaryParameters.mileage"></app-mileage-configuration-concox-mt200>
          <app-power-alarm-configuration-concox-mt200
            [powerAlarm]="deviceParams.proprietaryParameters.powerAlarm"></app-power-alarm-configuration-concox-mt200>
          <app-relay-configuration-concox-mt200
            [relay]="deviceParams.proprietaryParameters.relay"></app-relay-configuration-concox-mt200>
          <app-revise-instruction-password-configuration-concox-mt200
            [reviseInstructionPassword]="deviceParams.proprietaryParameters.reviseInstructionPassword"></app-revise-instruction-password-configuration-concox-mt200>
          <app-sensor-configuration-concox-mt200
            [sensor]="deviceParams.proprietaryParameters.sensor"></app-sensor-configuration-concox-mt200>
          <app-server-configuration-concox-mt200
            [server]="deviceParams.proprietaryParameters.server"></app-server-configuration-concox-mt200>
          <app-sosconfiguration-concox-mt200
            [sos]="deviceParams.proprietaryParameters.sos"></app-sosconfiguration-concox-mt200>
          <app-static-data-filtering-configuration-concox-mt200
            [staticDataFiltering]="deviceParams.proprietaryParameters.staticDataFiltering"></app-static-data-filtering-configuration-concox-mt200>
          <app-vibration-alarm-configuration-concox-mt200
            [vibrationAlarm]="deviceParams.proprietaryParameters.vibrationAlarm"></app-vibration-alarm-configuration-concox-mt200>
          <app-sensor-set-configuration-concox-mt200
            [sensorSet]="deviceParams.proprietaryParameters.sensorSet"></app-sensor-set-configuration-concox-mt200>
          <app-alarm-call-number-times-configuration-concox-mt200
            [alarmCallNumberTimes]="deviceParams.proprietaryParameters.alarmCallNumberTimes"></app-alarm-call-number-times-configuration-concox-mt200>
          <app-alarm-over-speed-configuration-concox-mt200
            [alarmOverSpeed]="deviceParams.proprietaryParameters.alarmOverSpeed"></app-alarm-over-speed-configuration-concox-mt200>
          <app-automatic-apn-configuration-concox-mt200
            [automaticApn]="deviceParams.proprietaryParameters.automaticApn"></app-automatic-apn-configuration-concox-mt200>
          <app-center-phone-number-configuration-concox-mt200
            [centerPhoneNumber]="deviceParams.proprietaryParameters.centerPhoneNumber"></app-center-phone-number-configuration-concox-mt200>
          <app-external-voltage-upload-configuration-concox-mt200
            [externalVoltageUpload]="deviceParams.proprietaryParameters.externalVoltageUpload"></app-external-voltage-upload-configuration-concox-mt200>
          <app-fence-alarm-configuration-concox-mt200
            [fenceAlarm]="deviceParams.proprietaryParameters.fenceAlarm"></app-fence-alarm-configuration-concox-mt200>
          <app-gps-distance-interval-configuration-concox-mt200
            [gpsDistanceInterval]="deviceParams.proprietaryParameters.gpsDistanceInterval"></app-gps-distance-interval-configuration-concox-mt200>
          <app-network-link-coordinates-configuration-concox-mt200
            [networkLinkCoordinates]="deviceParams.proprietaryParameters.networkLinkCoordinates"></app-network-link-coordinates-configuration-concox-mt200>
          <app-sensor-sensitivity-configuration-concox-mt200
            [sensorSensitivity]="deviceParams.proprietaryParameters.sensorSensitivity"></app-sensor-sensitivity-configuration-concox-mt200>
        </p-accordion>
      </p-tabPanel>

      <p-tabPanel header="Sensors">
        <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
        <!-- Sensors list -->
      </p-tabPanel>
      <p-tabPanel i18n-header header="Actuators">
        <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
      </p-tabPanel>

    </p-tabView>
  </p-panel>
  <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>