<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Serial Port Settigns
          (AT+GTURT)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Baudrate Index">
      </form-input>
    </div>
    <div class="col">
      <p-dropdown [options]="indexOptions" [(ngModel)]="urtParameters.index"
        [style]="{'width':'100%','min-width':'100px'}" appendTo="body"></p-dropdown>
    </div>
  </div>
</p-accordionTab>