import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
   selector: 'app-control-panel',
   templateUrl: './control-panel.component.html',
   styleUrls: ['./control-panel.component.css'],
   encapsulation: ViewEncapsulation.None,
})
export class ControlPanelComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
