  // COLOR PALETTE
  export const paletterColor = {
      
    
    whithoutColoring : [
    {
      condition: '-',
      value: '#F5F5F2',
    }
    ],

    vigilanceColors : [
    {
      condition: '< 24h ',
      value: 'rgb(0,176,80)',
    },
    {
      condition: '< 48h',
      value: 'rgb(255,255,0)',
    },
    {
      condition: '< 72h',
      value: 'rgb(255,0,0)',
    },
    {
      condition: '> 72h',
      value: 'rgb(0,0,0)',
    }
    ],
  
    MaintenanceColors : [
    {
      condition: '< 8h ',
      value: 'rgb(0,176,80)',
    },
    {
      condition: '< 16h',
      value: 'rgb(255,255,0)',
    },
    {
      condition: '< 24h',
      value: 'rgb(255,0,0)',
    },
    {
      condition: '> 24h',
      value: 'rgb(0,0,0)',
    }
    ],

}