import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-metadata-editor",
  templateUrl: "./metadata-editor.component.html",
  styleUrls: ["./metadata-editor.component.css"],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MetadataEditor implements OnChanges {
  //metadata is a map string:string but JSON doesn't allow to serialize Map object...
  @Output() metadataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() metadata: any = {};

  rows: Row[] = [];
  ngOnChanges(changes: SimpleChanges): void {
    this.rows = [];
    if (this.metadata != null) {
      for (var key in this.metadata) {
        if (this.metadata.hasOwnProperty(key)) {
          this.rows.push({ key: key, value: this.metadata[key] });
        }
      }
      this.rows = [...this.rows];
    } else {
      this.metadata = {};
    }
  }

  constructor() {}

  handleRowEdit(event): void {
    if (this.metadata != null) {
      this.metadata = {};
      this.rows.forEach((r) => (this.metadata[r.key] = r.value));
      this.rows = [...this.rows];
      this.metadataChange.emit(this.metadata);
    }
  }
  addRow(): void {
    if (this.metadata != null) {
      this.rows.push({ key: "", value: "" });
      this.rows = [...this.rows];
      this.metadata[""] = "";
      this.metadataChange.emit(this.metadata);
    }
  }

  deleteRow(row: Row): void {
    if (this.metadata != null) {
      this.rows = this.rows.filter((r) => r.key != row.key);
      this.metadata = {};
      this.rows.forEach((r) => (this.metadata[r.key] = r.value));
      this.rows = [...this.rows];
      this.metadataChange.emit(this.metadata);
    }
  }
}
class Row {
  key: string;
  value: string;
}
