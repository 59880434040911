import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { CercaliaMapService } from '../cercalia-map/cercalia-map.service';

@Injectable()
export class CercaliaResolver implements Resolve<any> {
  constructor(private cercaliaMapService: CercaliaMapService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.cercaliaMapService.onMapInitialized();
  }
}