import { Injectable } from '@angular/core';
import { EntityService } from '../../../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../../../rest/JQueryHTTPClient';
import { Rest_logistics } from '../rest_client_logistics';
import { Rest } from '../../../../../rest/rest_client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../../../../services/notifications-service/notifications.service';
import { TaskUseful } from 'app/modules/task/shared/task_useful';

@Injectable()
export class IncidencesNotReadService extends EntityService<Rest_logistics.Order> {
    client: Rest.RestApplicationClient;
    private _startDate: Date | null;
    private _endDate: Date | null;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient,
        private auth: AuthenticationService,
        private notificationsService: NotificationsService,
    ) {
        super();
        this.client_task = new Rest_logistics.RestApplicationClient(jquery);
        this._startDate = null;
        this._endDate = null;
    }

    /**
     * Get incidences not resolved
     * @param event
     * @returns void
     * @memberof NotificationPanelComponent
    */
    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
        return this.client_task.Order_getOrderIncidencenr(arg0);
    }

    markedincidences(orderId: number): Rest.RestResponse<boolean> {
        return this.client_task.markedincidences(orderId);
    }

}
