<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Tow Event Configuration ($WP+SETTOW)</span>
            </div>
        </div>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Tow Mode">
          <p-dropdown  (onChange)="trackEventModeChange(towEvent.mode,$event)" [options]="TowEventMode"
          [(ngModel)]="towEvent.mode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
  
      <div [class.disableDIV]=!trackModeSwitch>
          <div class="ui-g-12 ui-md-6">
              <form-input  fieldName="Satellite Fixed" >
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="towEvent.satellitesFixed" [min]="3" [max]="12" (onChange)="onChange()"></p-spinner>
                  <span class="ui-inputgroup-addon">3~12</span>
                </div>
              </form-input>
            </div>  
            <div class="ui-g-12 ui-md-6">
                <form-input  fieldName="Speed Threshold" >
                  <div class="ui-inputgroup">
                    <p-spinner size="5" [(ngModel)]="towEvent.speedThreshold" [min]="10" [max]="65535" (onChange)="onChange()"></p-spinner>
                    <span class="ui-inputgroup-addon">10~65535</span>
                    <span class="ui-inputgroup-addon">s</span>
                  </div>
                </form-input>
              </div> 
              <div class="ui-g-12 ui-md-6">
                  <form-input  fieldName="Tow Duration" >
                    <div class="ui-inputgroup">
                      <p-spinner size="5" [(ngModel)]="towEvent.towDuration" [min]="10" [max]="65535" (onChange)="onChange()"></p-spinner>
                      <span class="ui-inputgroup-addon">s</span>
                    </div>
                  </form-input>
                </div> 
                <div class="ui-g-12 ui-md-6">
                    <form-input  fieldName="Auto Reset Duration" >
                      <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="towEvent.autoReset" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                        <span class="ui-inputgroup-addon">0~65535</span>
                      </div>
                    </form-input>
                  </div>      
    </div>
    </div>
  
  </p-accordionTab>
