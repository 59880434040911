import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IconService extends EntityService<any>{
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient, private http: HttpClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.Icon): Rest.RestResponse<Rest.Icon> {
    return this.client.Icon_create(arg0);
  }

  delete(arg0: Rest.Icon[]): Rest.RestResponse<void> {
    return this.client.Icon_delete(arg0);
  }

  update(arg0: Rest.Icon): Rest.RestResponse<Rest.Icon> {
    return this.client.Icon_update(arg0);
  }

  getEntityIcons(entity: string, queryParams?: { entity?: string; }): Rest.RestResponse<Rest.Icon[]> {
    return this.client.Icon_getEntityIcons(entity, queryParams);
  }

  getById(id: number): Rest.RestResponse<Rest.Icon> {
    return this.client.Icon_getById(id);
  }

  uploadIcon(data: FormData): Rest.RestResponse<Rest.Icon> {
    return this.http.post(environment.webApiBaseUrl + 'api/icon/upload', data)
      .toPromise()
      .then((response: any) => {
        console.log(response);
        return response;
      })
  }
}
