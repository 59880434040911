<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Harsh Breaking Event Configuration</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)"  [(ngModel)]="deviceParams.proprietaryParameters.harshBreaking.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.harshBreaking.enabled>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Speed Drop Threshold" [errors]="errors.speedDropThreshold">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="speedDropThreshold" size="12" [(ngModel)]="deviceParams.proprietaryParameters.harshBreaking.speedDropThreshold" step="1" [min]="5"
            [max]="255">
          </p-spinner>
          <span class="ui-inputgroup-addon">5~250</span>
          <span class="ui-inputgroup-addon" i18n>km/h</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="duration" [errors]="errors.duration">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="duration" size="12" [(ngModel)]="deviceParams.proprietaryParameters.harshBreaking.duration" step="1" [min]="0"
            [max]="10">
          </p-spinner>
          <span class="ui-inputgroup-addon">0~10</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Led pattern" [errors]="errors.ledPatternIndex">
        <p-dropdown [options]="avaibleLedPatterns" [(ngModel)]="deviceParams.proprietaryParameters.harshBreaking.ledPatternIndex"
          [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>

  </div>
</p-accordionTab>