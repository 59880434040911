import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';
import { RoutesService } from '../../fid-shared/service/routes.service';
import { TranslationsService } from '../../fid-shared/service/translations.service';
import * as xml2js from 'xml2js';
import { CercaliaMapService } from 'app/services/cercalia-map/cercalia-map.service';
import { CustomMapComponent } from 'app/components/shared/custom-map/custom-map.component';
import { ContextMenuOption, CustomMapOptions } from 'app/components/shared/custom-map/custom-map.model';
import { TranslateService } from '@ngx-translate/core';
import { CampaignService } from 'app/modules/fid-shared/service/campaign.service';
import { PoiRoutesFormComponent } from '../poi-routes-form/poi-routes-form.component';
import { RacingService } from 'app/rest/racing.service';
import { RoutePoiService } from 'app/modules/fid-shared/service/route-poi.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-routes-form',
  templateUrl: './routes-form.component.html',
  styleUrls: ['./routes-form.component.css'],
  providers: [
		RacingService,
		ConfirmationService
  ]
})
export class RoutesFormComponent extends EntityFormComponent<Rest.FidRoute> implements OnInit, OnDestroy {

	@ViewChild('errorContainer') errorContainer: ElementRef;
	@ViewChild('poiRoutesForm', { static: false }) poiForm: PoiRoutesFormComponent;
	@ViewChild('eventsMap', { static: false }) set eventsMap(eventsMap: CustomMapComponent) {
		// console.log(eventsMap);
		if (eventsMap) {

			if (!this.isNew) {
		   	this.customMapOptions = {
					popupOptions: {
						popUpMinWidth: 300,
						popUpMaxWidth: 400,
						videoLinkIcon: './assets/icons/link_navigate.svg',
						blogLinkIcon: './assets/icons/link_navigate.svg',
						mode: "single"
					},
					contextMenuOptions: [
						<ContextMenuOption>{  // es defineixen les opcions en el menu contextual sobre el mapa. id es l'ordre
						   id: '1',
							label: this.translate.instant('events.pois.createPoi'),
							callback: (event: any, position: any) => {
								//eventsMap.map.closePopups();
								//this.poiForm.showDialog(true, false, position);
								this.routePoiService.startedCreating.next({showCoordinates: false, coordinates: position});
							},
						},
			 		],
		   	};
			} else {
				this.customMapOptions = {
					 contextMenuOptions: [],
				};
		   }

			eventsMap.loadMapPromise.then(() => {
				this.map = eventsMap;
				if (this.map?.map && !this.entityKmlFileLoaded) {
					this.loadEntityKmlFile();
				}
			});
		}
  	}

	isDialogVisible: boolean;
	isEditingDisabled: boolean;
	errorMessages: string[];
	spaceFormTitle: string;

	categoryList: Rest.RoutesCategory[];

	editSubscription: Subscription;
	createSubscription: Subscription;

	/** variables FidRoute */
	route: Rest.FidRoute;
	editedRoute: Rest.FidRoute;
	space: Rest.FidSpace;
	routeImageUrl: string;
	routeImageFile: any;
	routeEncodedImage: any;
	isImageChanged: boolean = false;

	/* Event Code Type variables */
	eventCodeTypeList: Rest.EventCodeTypeRacing[];
	selectedEventCodeType: Rest.EventCodeTypeRacing;
	generalEventCode: Rest.CodesEvents;
	uniqueEventCodeList: Rest.CodesEvents[];
	selectedColor: string;

	/* MAP variables */
	customMapOptions: CustomMapOptions;
	map: any;
	poiList: Rest.POIRacing[];
	selectedPoi: Rest.FidRoutePOI;
	selectedTrack: any;
	showPoiDeleteConfirmDialog: boolean;
	entityKmlFileLoaded: boolean;
	disablePoiPanel: boolean;
	isEnabled: boolean;
	changesPending: boolean;

	/* multilanguage fields */
	translations: Rest.Translations;
	translationSubscription: Subscription;

   constructor(
      public routesService: RoutesService,
      private authService: AuthenticationService,
      public i18n: I18nService,
      public notificationsService: NotificationsService,
		public translationsService: TranslationsService,
		private mapService: CercaliaMapService,
		private translate: TranslateService,
		public campaignService: CampaignService,
		public routePoiService: RoutePoiService,
		private confirmationService: ConfirmationService
   ) {
      super(routesService, notificationsService, i18n, authService);
		this.isEditingDisabled = false;
		this.errorMessages = [];

		this.poiList = [];
		this.selectedPoi = null;
		this.selectedTrack = {
			 fileName: '',
			 kmlFile: null,
		};
		this.showPoiDeleteConfirmDialog = false;
		this.entityKmlFileLoaded = false;
		this.disablePoiPanel = false;
		//this.csvIconUrl = './assets/images/Racing/csv.png';
   }

   ngOnInit() {
		// console.log("[ROUTES-FORM] OnInit ***");
   	super.ngOnInit();
		this.fillCategories();

		this.editSubscription = this.routesService.startedEditing
    	  .subscribe(
         	(item: Rest.FidRoute) => {
					this.isNew = false;
					this.editedRoute = this.routesService.initRoute();
		  			this.showDialog(item);
	      	});
		this.createSubscription = this.routesService.startedCreating
    	  .subscribe(
         	(item: Rest.FidSpace) => {
					this.space = item;
					this.isNew = true;
					this.editedRoute = this.routesService.initRoute();
					this.showDialog(this.editedRoute);
	      	});
		this.translationSubscription = this.routesService.endTranslationEditing
      	.subscribe(
         	(item: Rest.FidRoute) => {
		  			this.editedRoute = item;
					this.refresh();
	      	});

   }

	ngOnDestroy() {
		// console.log("[ROUTES-FORM] ngOnDestroy ***");
		this.createSubscription.unsubscribe();
		this.editSubscription.unsubscribe();
		this.translationSubscription.unsubscribe();
	}

	public showDialog(item: Rest.FidRoute): void {
		// console.log("[ROUTES-FORM] showDialog ***");
		window.scroll({ top: 0, behavior: 'smooth' });
		this.errorMessages = [];
		this.isDialogVisible = true;
		this.disablePoiPanel = false;
		this.changesPending = false;
		if (!this.isNew) {
			this.route = item;
			this.isEnabled = this.route.enabled;
			this.fillRoute();
		}else{
			this.isEnabled = false;
			this.route = this.routesService.initRoute();
			this.routeImageUrl = 'assets/icons/no-image-available.svg';
			this.entityKmlFileLoaded = true;
			this.generalEventCode = {
				 id: 0,
				 code: '',
				 usersRegistered: 0,
				 deleted: false,
				 dateDeleted: null,
			};
			this.selectedTrack = {
				 fileName: '',
				 kmlFile: null,
			};
			this.poiList = [];

			/** temp ..  */
			// this.route.nameRoute = "Evento de prueba";
			// this.route.shortDescription = "Decripción breve del evento de prueba";
			// this.route.totalKms = 200;
			// this.route.totalTime = "1 día";
			/** .. temp */
		}
	}

	public fillRoute() {

		// console.log("[ROUTES-FORM] fillRoute ***********************");
		if (this.route.image) {
			this.routeImageUrl = null;
			this.routeEncodedImage = this.route.image.imageEncoded;
		}else{
			this.routeImageUrl = 'assets/icons/no-image-available.svg';
			this.routeEncodedImage = null;
		}

		/* Init KML track  */
		//*TEMP*/this.route.track.path = "C:/temp/IconsUpload" + this.route.track.path;
		// console.log("[ROUTES-FORM] Init KML track ***********************");
      if (this.route.track) {
			this.selectedTrack.fileName = this.route.track.name;
			this.entityKmlFileLoaded = false;
		} else {
			this.selectedTrack = {
				 fileName: '',
				 kmlFile: null,
			};
			this.entityKmlFileLoaded = true;
		}

		/* Init POI list */
		// console.log("[ROUTES-FORM] Init POI List  ***");
		this.poiList = [];
		// console.log(this.route.pois);
		this.route.pois.forEach(x => this.poiList.push(this.toPOIRacing(x)));
		// console.log(this.poiList)

	}

	/** convertir de FidRoutePOI a POIRacing que es el que el custom-map pot mostrar */
	public toPOIRacing(x: Rest.FidRoutePOI): Rest.POIRacing {
		// console.log("[ROUTES-FORM] toPOIRacing  ");
		return  {
			id: x.id,
			namePoi: x.name,
			infoTxt: x.description,
			imagePoi: x.imagePoi,
			blogUrl: x.link1,
			videoUrl: x.link2,
			latitude: x.latitude,
			longitude: x.longitude,
			deleted: null,
			dateDeleted: null,
			//itemIcon: iconItem,
			itemIcon: x.itemIcon,
			event: null
		}

	}

	public onTranslations(field: string): void {
		// console.log("[ROUTES-FORM] onTranslations ***");
		switch(field) {
			case "name": {
				this.translations = {
					esp: this.route.nameRoute,
					cat: this.route.nameRouteCat,
					eng: this.route.nameRouteEng,
					fra: this.route.nameRouteFra,
					ita: this.route.nameRouteIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "shortDescription": {
				this.translations = {
					esp: this.route.shortDescription,
					cat: this.route.shortDescriptionCat,
					eng: this.route.shortDescriptionEng,
					fra: this.route.shortDescriptionFra,
					ita: this.route.shortDescriptionIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "description": {
				this.translations = {
					esp: this.route.description,
					cat: this.route.descriptionCat,
					eng: this.route.descriptionEng,
					fra: this.route.descriptionFra,
					ita: this.route.descriptionIta,
					textArea: true,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "infoLink": {
				this.translations = {
					esp: this.route.infoLink,
					cat: this.route.infoLinkCat,
					eng: this.route.infoLinkEng,
					fra: this.route.infoLinkFra,
					ita: this.route.infoLinkIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "sharedLink": {
				this.translations = {
					esp: this.route.sharedLink,
					cat: this.route.sharedLinkCat,
					eng: this.route.sharedLinkEng,
					fra: this.route.sharedLinkFra,
					ita: this.route.sharedLinkIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "activitiesLink": {
				this.translations = {
					esp: this.route.activitiesLink,
					cat: this.route.activitiesLinkCat,
					eng: this.route.activitiesLinkEng,
					fra: this.route.activitiesLinkFra,
					ita: this.route.activitiesLinkIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			case "totalTime": {
				this.translations = {
					esp: this.route.totalTime,
					cat: this.route.totalTimeCat,
					eng: this.route.totalTimeEng,
					fra: this.route.totalTimeFra,
					ita: this.route.totalTimeIta,
					textArea: false,
					field: field,
					type: "route",
					id: this.route.id
				}
				break;
			}
			default: {
				//statements;
				break;
			}
		}
		this.translationsService.startEditTranslations.next(this.translations);

	}

	public refresh() {
		if (this.editedRoute.nameRoute !== null) this.route.nameRoute = this.editedRoute.nameRoute;
		if (this.editedRoute.nameRouteCat !== null) this.route.nameRouteCat = this.editedRoute.nameRouteCat;
		if (this.editedRoute.nameRouteEng !== null) this.route.nameRouteEng = this.editedRoute.nameRouteEng;
		if (this.editedRoute.nameRouteFra !== null) this.route.nameRouteFra = this.editedRoute.nameRouteFra;
		if (this.editedRoute.nameRouteIta !== null) this.route.nameRouteIta = this.editedRoute.nameRouteIta;
		if (this.editedRoute.shortDescription !== null) this.route.shortDescription = this.editedRoute.shortDescription;
		if (this.editedRoute.shortDescriptionCat !== null) this.route.shortDescriptionCat = this.editedRoute.shortDescriptionCat;
		if (this.editedRoute.shortDescriptionEng !== null) this.route.shortDescriptionEng = this.editedRoute.shortDescriptionEng;
		if (this.editedRoute.shortDescriptionFra !== null) this.route.shortDescriptionFra = this.editedRoute.shortDescriptionFra;
		if (this.editedRoute.shortDescriptionIta !== null) this.route.shortDescriptionIta = this.editedRoute.shortDescriptionIta;
		if (this.editedRoute.description !== null) this.route.description = this.editedRoute.description;
		if (this.editedRoute.descriptionCat !== null) this.route.descriptionCat = this.editedRoute.descriptionCat;
		if (this.editedRoute.descriptionEng !== null) this.route.descriptionEng = this.editedRoute.descriptionEng;
		if (this.editedRoute.descriptionFra !== null) this.route.descriptionFra = this.editedRoute.descriptionFra;
		if (this.editedRoute.descriptionIta !== null) this.route.descriptionIta = this.editedRoute.descriptionIta;
		if (this.editedRoute.infoLink !== null) this.route.infoLink = this.editedRoute.infoLink;
		if (this.editedRoute.infoLinkCat !== null) this.route.infoLinkCat = this.editedRoute.infoLinkCat;
		if (this.editedRoute.infoLinkEng !== null) this.route.infoLinkEng = this.editedRoute.infoLinkEng;
		if (this.editedRoute.infoLinkFra !== null) this.route.infoLinkFra = this.editedRoute.infoLinkFra;
		if (this.editedRoute.infoLinkIta !== null) this.route.infoLinkIta = this.editedRoute.infoLinkIta;
		if (this.editedRoute.sharedLink !== null) this.route.sharedLink = this.editedRoute.sharedLink;
		if (this.editedRoute.sharedLinkCat !== null) this.route.sharedLinkCat = this.editedRoute.sharedLinkCat;
		if (this.editedRoute.sharedLinkEng !== null) this.route.sharedLinkEng = this.editedRoute.sharedLinkEng;
		if (this.editedRoute.sharedLinkFra !== null) this.route.sharedLinkFra = this.editedRoute.sharedLinkFra;
		if (this.editedRoute.sharedLinkIta !== null) this.route.sharedLinkIta = this.editedRoute.sharedLinkIta;
		if (this.editedRoute.activitiesLink !== null) this.route.activitiesLink = this.editedRoute.activitiesLink;
		if (this.editedRoute.activitiesLinkCat !== null) this.route.activitiesLinkCat = this.editedRoute.activitiesLinkCat;
		if (this.editedRoute.activitiesLinkEng !== null) this.route.activitiesLinkEng = this.editedRoute.activitiesLinkEng;
		if (this.editedRoute.activitiesLinkFra !== null) this.route.activitiesLinkFra = this.editedRoute.activitiesLinkFra;
		if (this.editedRoute.activitiesLinkIta !== null) this.route.activitiesLinkIta = this.editedRoute.activitiesLinkIta;
		if (this.editedRoute.totalTime !== null) this.route.totalTime = this.editedRoute.totalTime;
		if (this.editedRoute.totalTimeCat !== null) this.route.totalTimeCat = this.editedRoute.totalTimeCat;
		if (this.editedRoute.totalTimeEng !== null) this.route.totalTimeEng = this.editedRoute.totalTimeEng;
		if (this.editedRoute.totalTimeFra !== null) this.route.totalTimeFra = this.editedRoute.totalTimeFra;
		if (this.editedRoute.totalTimeIta !== null) this.route.totalTimeIta = this.editedRoute.totalTimeIta;
	}

	public fillCategories() {
		// console.log("[ROUTES-FORM] fillCategories ***********************");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.routesService
				.getCategories('EN')
				.then(
			 		function (response) {
						that.categoryList = response;
						//console.log(that.categoryList);
		 			},
		 			/* failure */
		 			function (error) {
				  		// console.log('The request failed: ' + error);
			  			resolve();
		 			},
				)
				.catch(function (reason) {
			 		// console.log('Catched: ' + reason);
					resolve();
				});
		});

	}

	public changeKml(event: any) {
		// console.log("[ROUTES-FORM] changeKml ***********************");
		let kmlFile = event.target.files[0];
		let reader = new FileReader();
		let xml: any;

		reader.onload = (loadEvent: any) => {
			 let result = loadEvent.target.result;

			 /* Parse kml to a JSON structure */
			 xml2js.parseString(result, (error, parsedXml) => {
				  xml = parsedXml ? parsedXml : null;
			 });

			 /* Parse the XML JSON structure to wkt */
			 this.parseKmlFile(xml)
				  .then((wkt: string) => {
						this.selectedTrack.kmlFile = kmlFile;
						this.selectedTrack.fileName = kmlFile.name;

						let feature = new this.mapService.cercalia.Feature({
							 strokeColor: '#c42c22',
							 fillColor: '#c42c22',
							 strokeWidth: 3,
							 outline: false,
							 wkt: wkt,
						});

						this.map.initMap();
						this.map.loadRacingPois();
						this.map.map.addFeature(feature);
						this.map.map.centerToFeatures([feature]);
				  })
				  .catch((error) => {
						this.notificationsService.add(Severity.error, 'Error', error);
				  });
		};

		reader.readAsText(kmlFile);
	}

	public imageChange(event: any): void {
		// console.log("[ROUTES-FORM] imageChange ***");
		// console.log(event);
		let reader = new FileReader();
		this.routeImageFile = event.target.files[0];
		reader.onload = (event: any) => {
			 this.routeImageUrl = event.target.result;
			 this.routeEncodedImage = null;
		};
		reader.readAsDataURL(event.target.files[0]);
		this.isImageChanged = true;
  }

	private validateRouteForm(): boolean {
		// console.log("[ROUTES-FORM] validateRouteForm ***");
		this.errorMessages = [];

		/* Check if Name is empty */
		if (this.route.nameRoute === null || this.route.nameRoute === '' ||
		    this.route.shortDescription === null || this.route.shortDescription === '' ||
			 this.route.totalKms === null || this.route.totalKms === 0 ||
			 this.route.totalTime === null || this.route.totalTime === '' ||
			 this.route.category === null) {
			this.errorMessages.push(
				 this.translate.instant('error.required-field.generic'),
			);
	   }

		// console.log(this.errorMessages);
		return this.errorMessages.length === 0;
  }

	public saveRoute(): void {
		// console.log("[ROUTES-FORM] saveRoute  ***");
		if (this.validateRouteForm()) {
			/* Create custom Racing Event structure to send to API */
			let routeData = {
				id: this.isNew ? 0 : this.route.id,
				nameRoute: this.route.nameRoute,
				nameRouteCat: this.route.nameRouteCat,
				nameRouteEng: this.route.nameRouteEng,
				nameRouteFra: this.route.nameRouteFra,
				nameRouteIta: this.route.nameRouteIta,
				shortDescription: this.route.shortDescription,
				shortDescriptionCat: this.route.shortDescriptionCat,
				shortDescriptionEng: this.route.shortDescriptionEng,
				shortDescriptionFra: this.route.shortDescriptionFra,
				shortDescriptionIta: this.route.shortDescriptionIta,
				description: this.route.description,
				descriptionCat: this.route.descriptionCat,
				descriptionEng: this.route.descriptionEng,
				descriptionFra: this.route.descriptionFra,
				descriptionIta: this.route.descriptionIta,
				space: this.isNew ? { id: this.space.id } : { id: this.route.space.id },
				enabled: this.isNew ? false : this.route.enabled,
				datePublication: this.isNew ? new Date() : this.route.datePublication,
				numPos: this.isNew ? this.getNextPos() : this.route.numPos,
				category: { id: this.route.category.id },
				totalKms: this.route.totalKms,
				totalTime: this.route.totalTime,
				totalTimeCat: this.route.totalTimeCat,
				totalTimeEng: this.route.totalTimeEng,
				totalTimeFra: this.route.totalTimeFra,
				totalTimeIta: this.route.totalTimeIta,
				track: (!this.selectedTrack.kmlFile && this.route.track !== null) ? { id: this.route.track.id } : null,
				image: (!this.routeImageFile && this.route.image !== null) ? { id: this.route.image.id } : null,
				infoLink: this.route.infoLink,
				infoLinkCat: this.route.infoLinkCat,
				infoLinkEng: this.route.infoLinkEng,
				infoLinkFra: this.route.infoLinkFra,
				infoLinkIta: this.route.infoLinkIta,
				sharedLink: this.route.sharedLink,
				sharedLinkCat: this.route.sharedLinkCat,
				sharedLinkEng: this.route.sharedLinkEng,
				sharedLinkFra: this.route.sharedLinkFra,
				sharedLinkIta: this.route.sharedLinkIta,
				activitiesLink: this.route.activitiesLink,
				activitiesLinkCat: this.route.activitiesLinkCat,
				activitiesLinkEng: this.route.activitiesLinkEng,
				activitiesLinkFra: this.route.activitiesLinkFra,
				activitiesLinkIta: this.route.activitiesLinkIta
				//pois: this.route.pois
			};

			// console.log(routeData);
			/* Stringify the custom Racing Event structure */
			let RouteDataString = JSON.stringify(routeData);

			/* Add new Route */
			if (this.isNew) {
				this.routesService.addRoute(RouteDataString, this.routeImageFile, this.selectedTrack.kmlFile)
				 	.then((route) => {
						if (route) {
							this.isDialogVisible = false;
							/* actualizamos la lista de spaces */
							this.routesService.routeSaved.next();
							this.campaignService.campaignSaved.next();
						}
					});
			}
			/* Save existing Route */
			else {
				this.routesService.updateRoute(RouteDataString, this.routeImageFile, this.selectedTrack.kmlFile)
					.then((route) => {
						if (route) {
							this.isDialogVisible = false;
							/* actualizamos la lista de spaces */
							this.routesService.routeSaved.next();
							this.campaignService.campaignSaved.next();
						}
			   	});
			}
	   } else {
			this.scrollDialogToTop();
	   }

	}

	public getNextPos(): number {
		// console.log("[ROUTE-SERVICE] getNextPos ***");
		let lastRoute: number;
		if (this.space.routes.length === 0) {
			return 1;
		}else{
			lastRoute = this.space.routes.length - 1;
			let lastPos: number = this.space.routes[lastRoute].numPos;
			return lastPos + 1;
		}
	}

	public toggleActivation() {
		// console.log("[ROUTES-FORM] toggleActivation ***");
		this.changesPending = true;
		//this.saveRoute();
	}


	/*************************************************************************************************************************/
	/** KML FUNCTIONS ********************************************************************************************************/
	/*************************************************************************************************************************/
	public loadEntityKmlFile(): void {
		// console.log("[ROUTE-SERVICE] loadEntityKmlFile ***");
		if (this.route?.track) {

			 let content = window.atob(this.route.track.imageEncoded);
			 let xml: any;

			 xml2js.parseString(content, (error, parsedXml) => {
				  xml = parsedXml ? parsedXml : null;
			 });

			 this.parseKmlFile(xml)
				  .then((wkt: string) => {

						let feature = new this.mapService.cercalia.Feature({
							 strokeColor: '#c42c22',
							 fillColor: '#c42c22',
							 strokeWidth: 3,
							 outline: false,
							 wkt: wkt,
						});

						this.map.map.addFeature(feature);

						this.map.map.centerToFeatures([feature]);
				  })
				  .catch((error) => {
						this.notificationsService.add(Severity.error, 'Error', error);
				  });
		}

		this.entityKmlFileLoaded = true;
	}

	public parseKmlFile(xml: any): Promise<string> {
		// console.log("[ROUTES-SERVICE] parseKmlFile ***********************");
		return new Promise<string>((resolve, reject) => {
			/* Search for the <LineString> tag inside the xml object */
			let lineStrings = this.findNestedObject(xml, 'LineString');
			let coordinates = [];

			/* Search for the <coordinates> tag inside the <LineString> objects */
			if (lineStrings.length !== 0) {
				lineStrings.forEach((coordinate) => {
					coordinates.push(this.findNestedObject(coordinate, 'coordinates'));
				});
			} else {
				reject();
			}

			if (coordinates.length !== 0) {
				let parsedCoordinates: string = '';

				/* Clean each coordinate */
				coordinates.forEach((coordinate, index) => {

					parsedCoordinates += this.cleanCoordinate(coordinate[0]);

					if (index !== coordinates.length - 1) {
						parsedCoordinates += ',';
					}
				});

				/* Check if the coordinates are expressed in 2d or 3d and concatenate accordingly */
				if (this.getLineStringCoordinatesLength(parsedCoordinates) === 2) {
					parsedCoordinates = 'LINESTRING(' + parsedCoordinates + ')';
				} else if (this.getLineStringCoordinatesLength(parsedCoordinates) === 3) {
					parsedCoordinates = 'LINESTRING Z(' + parsedCoordinates + ')';
				} else {
					reject();
				}

				resolve(parsedCoordinates);
			} else {
				reject(this.translate.instant('error.process.kml') + '!');
			}
		});
	}

	public findNestedObject(objectToSearch, keyToFind): any {
		// console.log("[ROUTES-SERVICE] findNestedObject ***********************");
		let foundObjects = [];

		JSON.stringify(objectToSearch, (_, nestedObject) => {
			 if (nestedObject && nestedObject[keyToFind]) {
			  foundObjects.push(nestedObject[keyToFind]);
			 }
			 return nestedObject;
		});
		return foundObjects;
	}

	public cleanCoordinate(coordinate): string {
		// console.log("[ROUTES-SERVICE] cleanCoordinate ***********************");
		let parsedString = coordinate[0];

		/* STEP 1: Clean content from special characters */
		parsedString = parsedString.replace(/(?:\r|\n|\t)/g, '');

		/* STEP 2: If there is a leftover white space in the beginning, remove it */
		if (parsedString[0] === ' ') {
			 parsedString = parsedString.trimStart(1);
		}

		/* STEP 3: If there are is a leftover white space at the end, remove it */
		if (parsedString[parsedString.length - 1] === ' ') {
			 parsedString = parsedString.trimEnd(1);
		}

		/* STEP 4: Replace all white spaces to ';' to separate the pairs */
		parsedString = parsedString.replace(/\s/g, ';');

		/* STEP 5: Replace all ',' to white space to separate each coordinate inside the pairs */
		parsedString = parsedString.replace(/,/g, ' ');

		/* STEP 6: Replace all ';' back to white spaces to separate the pairs */
		parsedString = parsedString.replace(/;/g, ',');

		return parsedString;
	}

	public getLineStringCoordinatesLength(lineString: string): number | null {
		// console.log("[ROUTES-SERVICE] getLineStringCoordinatesLength ***********************");
		let splitString = lineString.split(',');

		if (splitString[0]) {
			return splitString[0].split(' ').length;
		} else {
			return null;
		}
	}


	/*************************************************************************************************************************/
	/** POI FUNCTIONS ********************************************************************************************************/
	/*************************************************************************************************************************/
	/** event poiAdded (create)*/
	public addPoi(poi): void {
		// console.log("[ROUTES-FORM] addPoi ***");
		this.poiList = [this.toPOIRacing(poi), ...this.poiList];
		this.route.pois = [poi, ...this.route.pois];
   }

	/** event poiSaved (edit)*/
   public savePoi(poi): void {
		// console.log("[ROUTES-FORM] savePoi ***");
		// console.log(this.poiList);
		this.route.pois[this.route.pois.findIndex((x) => { return (x.id === poi.id); })] = poi;
		this.poiList[this.poiList.findIndex((x) => { return (x.id === poi.id); })] = this.toPOIRacing(poi);
		// console.log(this.poiList);
		this.refreshPois();

	}

	/** dblClick */
   public editPoi(poi): void {
		// console.log("[ROUTES-FORM] editPoi ***");
		// console.log(poi);
		this.map.map.closePopups();
		const filterPoi = this.route.pois.find((x) => { if (x.id === poi.id) return x; });
		// console.log(filterPoi);
		this.selectedPoi = filterPoi;
		//this.poiForm.showDialog(false, true);
		this.routePoiService.startedEditing.next(this.selectedPoi);
   }

	public addPoiManual() {
		// console.log("[ROUTES-FORM] addPoiManual ***");
		this.poiForm.showDialog(true, true);
	}

   public deletePoi(poi, poiIndex): void {
		// console.log("[ROUTES-FORM] deletePoi ***");
		this.selectedPoi = poi;

		let confirmationMessage =
			this.translate.instant('control-panel.delete-dialog.stats-advice') + '<br><br>' +
			this.translate.instant('control-panel.delete-dialog.message') + ' ' +
			this.translate.instant('events.pois.thePoi') + ': ' + `<strong>` + poi.namePoi + '</strong>?';

		this.confirmationService.confirm({
			 message: confirmationMessage,
			 accept: () => {
				  this.routePoiService.deletePoi(poi.id).then((isPoiDeleted: boolean) => {
						if (isPoiDeleted) {

							 /* A sliced copy of poiList is created and assigned to the existing list to trigger PrimeNG's table to detect the change and update it */
							 this.poiList = this.poiList.slice(0, poiIndex).concat(this.poiList.slice(poiIndex + 1));
							 this.route.pois = this.route.pois.slice(0, poiIndex).concat(this.route.pois.slice(poiIndex + 1));
							//  console.log(this.route.pois);
						}
				  });
			 },
			 reject: () => {
				  this.selectedPoi = null;
			 },
		});
   }

	public refreshPois() {
		this.map.racingPois = this.poiList;
		// console.log(this.poiList);
		// console.log(this.map.racingPois);
		this.map.loadRacingPois();
	}

	/**************************************************/
	/*** dialog tools *********************************/
	/**************************************************/
	private scrollDialogToTop(): void {
		this.errorContainer.nativeElement.scroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
   }
}
