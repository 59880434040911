<!-- VEHICLE CARD ACCORDION ITEM -->
<div class="accordion-item ms-4 me-4 mb-4 custom-accordion-item">

  <!-- ACCORDION HEADER -->
  <div class="accordion-header custom-accordion-header">

    <!-- VEHICLE ICON & NAME -->
    <button class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse-' + vehicleID"
            [attr.aria-controls]="'panelStayOpen' + vehicleID"
            aria-expanded="true"
    >

      <!-- VEHICLE ICON -->
      
      <!----><div>
          <img [src]='vehicleIcon' alt='' class ='d-flex flex-wrap' style="height: 20px; width: 30px;"/>
      </div>

      <!-- VEHICLE NAME -->
      <span class="ms-2 custom-accordion-header-text">
        {{vehicleName}}
      </span>

    </button>

  </div>

  <!-- ACCORDION COLLAPSE -->
  <div class="accordion-collapse collapse show"
       [attr.id]="'collapse-' + vehicleID"
       [attr.aria-labelledby]="'panelStayOpen' + vehicleID"
  >

    <!-- ACCORDION BODY -->
    <div class="accordion-body custom-accordion-body">

      <div class="container-fluid p-0">

        <!-- TODO: DELETE BUTTONS AFTER ALL TODOS ARE FINISHED -->
        <!-- ROW 0: TEMP BUTTONS-->
        <div hidden class="row mb-5">

          <div class="d-flex align-items-center justify-content-center gap-3">
            <button
            *ngIf="!isUserHosp"
            class="btn custom-button" (click)=map()>
              <span *ngIf="showMapView">Hide Map</span>
              <span *ngIf="!showMapView">Show Map</span>
            </button>

            <button 
            *ngIf="!isUserHosp"
            class="btn custom-button" (click)=telemetry()>
              <span *ngIf="showTelemetryView">Hide Telemetry</span>
              <span *ngIf="!showTelemetryView">Show Telemetry</span>
            </button>

            <button
            *ngIf="!isUserHosp"
            class="btn custom-button" (click)=quickActions()>
              <span *ngIf="showQuickActionsView">Hide Quick Actions</span>
              <span *ngIf="!showQuickActionsView">Show Quick Actions</span>
            </button>
          </div>

        </div>

        <!-- TODO: FIX CONFIG -->
        <div>
          <app-vehicle-details *ngIf="settings.showMap" [vehicle]=vehicle [settings]=settings></app-vehicle-details>
        </div>

        <div>
          <app-vehicle-telemetry *ngIf="settings.showTelemetry" [vehicle]=vehicle [settings]=settings></app-vehicle-telemetry>
        </div>

        <div>
          <app-quick-actions *ngIf="settings.showQuickActions" [vehicle]=vehicle [settings]=settings></app-quick-actions>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- DETAILS NOT PRESENT IN THE DESIGN -->

<!-- REMOVE BUTTON -->
<!--<button class="btn btn-danger" style="height: 30px; width: 30px; padding: 0 !important;" (click)="remove()">X</button>-->

<!-- GROUP -->
<!--<span *ngIf="settings.showVehicleGroupName"><span *ngIf="settings.showFieldLabel">Group: </span> {{groupName}}</span>-->

<!-- FLEET -->
<!--<span *ngIf="settings.showFleetName"><span *ngIf="settings.showFieldLabel">Fleet: </span> {{fleetName}}</span>-->
