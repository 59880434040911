<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Input/Output Port
          Binding Settings (AT + GTIOB)</span>
      </div>
    </div>
  </p-header>

  <div class="row mt-3">
    <div class="row align-items-center">
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="IOB ID">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="iobParameters.iobId" [min]="0" [max]="2"></p-spinner>
            <span class="ui-inputgroup-addon">0~2</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col row dvcFields">
        <form-input fieldName="Input Settings"></form-input>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Input Mask"></form-input>
            </div>
            <div class="col">
              <div class="col-12">
                <p-checkbox binary="true" label="Ignition detection" [(ngModel)]="iobParameters.inputIgnitionDetection">
                </p-checkbox>
              </div>
              <div class="col-12">
                <p-checkbox binary="true" label="digital input 1" [(ngModel)]="iobParameters.inputDigitalInput1">
                </p-checkbox>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Trigger Mask"></form-input>
            </div>
            <div class="col">
              <div class="col">
                <p-checkbox binary="true" label="Ignition detection"
                  [(ngModel)]="iobParameters.triggerIgnitionDetection">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="digital input 1" [(ngModel)]="iobParameters.triggerDigitalInput1">
                </p-checkbox>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Sample Period">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="iobParameters.samplePeriod" [min]="0" [max]="12"></p-spinner>
                <span class="ui-inputgroup-addon">0~12</span>
                <span class="ui-inputgroup-addon">(x2s)</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <form-input fieldName="Output Settings"></form-input>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="iobParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="iobParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</p-accordionTab>