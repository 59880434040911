import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class PoicategoryService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.POICategory): Rest.RestResponse<Rest.POICategory> {
return this.client.POICategory_create(arg0);
}

update(arg0: Rest.POICategory): Rest.RestResponse<Rest.POICategory> {
return this.client.POICategory_update(arg0);
}

findAll(arg0: number[]): Rest.RestResponse<Rest.POICategory[]> {
return this.client.POICategory_findAll(arg0);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.POICategory>> {
return this.client.POICategory_getPage(arg0);
}

getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.POICategory>> {
return this.client.POICategory_getPageAndFilters(arg0);
}


changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.POICategory_changeStatus(arg0, queryParams);
}
getAllEnabledCategories():Rest.RestResponse<Rest.POICategory[]>{
  return this.client.POICategory_getAllEnabledCategories();
}
}
