import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { UserService } from 'app/rest/user.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';

@Injectable({
  providedIn: 'root'
})
export class DriversGuard implements CanActivate {

  constructor(private auth: AuthenticationService, private router: Router, private notificationService: NotificationsService, private userService: UserService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    /* Retrieve user permission from API */
    return fromPromise(this.userService.getUserAllowedDashboards(this.auth.user.id)
    .then((response) => {
        if (response.drivers) {
            return true;
        }

        /* TODO: Implement loading and remove the loader as a promise handle (see previous line for an example) */
        this.router.navigate(['/']);

        this.notificationService.add(Severity.error, 'Access Denied', 'You are not authorized to access the page!');
        return false;
    })
    .catch(() => {
        return false;
    }));
  }
  
}
