<div class="container-fluid">

  <div class="row">

    <div class="col col-4">
      <app-fleet-tree [viewDisableds]=true
                      [(selection)]="selectedEntities"
                      (nodeSelected)="nodeSelected($event)"
                      (changeStatus)="changeStatus($event)"
                      (enableEntities)="enableEntities($event)"
                      showAddButtons="true"
                      [cascadeSelection]=true
                      [selectableEntities]=true>
      </app-fleet-tree>
    </div>

    <div class="col">

      <!-- FLEET FORM -->
      <app-fleet-form [checkBoxDisabled]=checkBoxDisabled
                      (newFleet)="notifyNewFleet($event)"
                      (return)="refreshList($event)"
                      (selectNode)="selectNode()">

      </app-fleet-form>

      <!-- GROUP FORM -->
      <app-group-form [checkBoxDisabled]=checkBoxDisabled
                      (newGroup)="notifyNewGroup($event)"
                      (return)="refreshList($event)"
                      (selectNode)="selectNode()">
      </app-group-form>

      <!-- VEHICLE FORM -->
      <app-vehicle-form [checkBoxDisabled]=checkBoxDisabled
                        (newVehicle)="notifyNewVehicle($event)"
                        (return)="refreshList($event)"
                        (selectNode)="selectNode()">
      </app-vehicle-form>

    </div>

  </div>

</div>

<!-- #ChangeStatus  -->
<div *ngIf="showStatusDialog">
  <!-- pass all three different services Fleet, Group, Vehicle  -->
  <app-change-status-dialog [items]=entities
                            [display]="true"
                            [isFleetTree]=true
                            [service]=fleetService
                            [service2]=groupService
                            [service3]=vehicleService
                            [entitiesList]=selectedEntities
                            [fleets]=fleetsChangeStatus
                            [groups]=groupsChangeStatus
                            [vehicles]=vehiclesChangeStatus
                            [cascadeSelection]=true
                            (return)="refreshList($event)"
                            (close)="closeStatusDialog($event)">
  </app-change-status-dialog>
</div>
