import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { Configuration } from "../models/Configuration";
import { ConfigChangesInfo } from "../models/ConfigChangesInfo";
import { BaseFilter } from "../models/BaseFilter";
import { IDFilter } from "../models/IDFilter";
import { ConfirmationService } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { UteService } from "../../../rest/ute.service";
import {
  NotificationsService,
  Severity,
} from "../../../services/notifications-service/notifications.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { ZonegroupService } from "app/rest/zonegroup.service";
import { paletterColor } from "assets/constants/viewsConstants";
import { AuthenticationService } from "app/core/services/authentication/authentication.service";

//TODO: uncomment
//import { paletterColor } from 'assets/constants/viewsConstants';

@Component({
  selector: "app-view-settings-form",
  templateUrl: "./view-settings-form.component.html",
  styleUrls: ["./view-settings-form.component.css"],
  providers: [ConfirmationService, ZonegroupService, UteService],
  encapsulation: ViewEncapsulation.None,
})
export class ViewSettingsFormComponent implements OnInit {
  //USE TO NOTIFY FATHER TO RELOAD INFO
  @Output()
  someConfigurationChange: EventEmitter<ConfigChangesInfo> = new EventEmitter();

  zonesFilter; // = RestExt.firstPageRequest();
  categoryService: ZonegroupService;
  uteService: any;
  //public selectedNames:any;

  // Dialog vars
  private resolve;

  // PopUP var
  display = false;

  // VEHICLES(PARSED) LOAD IN COMPONENT app-fleet-tree
  fleetsGroupsAndVehiclesSelected: any[] = [];

  // COLOR PALETTE
  //TODO: uncomment
  whithoutColoring = paletterColor.whithoutColoring;

  vigilanceColors = paletterColor.vigilanceColors;

  MaintenanceColors = paletterColor.MaintenanceColors;

  // CURRENTLY CONFIGURATION
  currentConfiguration: Configuration = null;

  // LIST OF CONFIGURATIONS [{name,id}]
  allConfigurations: any[] = [];

  //LIST OF ALL COLORING

  configurationColoring: Rest.ConfigurationColoring;
  coloring: boolean;

  // OPTIONS USED ON DROPDOWNS
  orderOptions = [
    { label: this.i18n._("Ascending"), value: true },
    { label: this.i18n._("Descending"), value: false },
  ];

  fileterOptions = [
    { label: this.i18n._("Less than"), value: false },
    { label: this.i18n._("Bigger than"), value: true },
  ];


  optionsRolColoring : any [] = [];

  // DEFAULT CONFIGURATION SETTINGS(USE WHEN WE CREATE A NEW CONFIG)
  baseConfiguration: Configuration = {
    name: this.i18n._("New Configuration"),
    id: null,
    columns: [
      // Nom Pdc
      {
        id: "controlPoint",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: true,
          ids: [],
        } as IDFilter,
      },
      // Data darrera conservació
      {
        id: "lastMaintenanceDate",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      // Temps restant conservació
      {
        id: "maintenanceLeftTime",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      // Data darrera vigilància
      {
        id: "lastVigilanceDate",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      // Temps restant vigilància
      {
        id: "vigilanceLeftTime",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      // Menor Temps restants:
      {
        id: "minorTimeRemaining",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      /*{
        id: "enterZoneDate",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      {
        id: "leaveZoneDate",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      {
        id: "durationTimeInZone",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },
      {
        id: "mileageInZone",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: false,
          BiggerThan: true,
          time: null,
        } as BaseFilter,
      },*/
      // Vehicle
      {
        id: "vehicle",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: true,
          ids: [],
        } as IDFilter,
      },
      // Grup
      {
        id: "group",
        visible: true,
        order: {
          active: false,
          asc: true,
        },
        filter: {
          active: true,
          ids: [],
        } as IDFilter,
      },
    ],
    coloring: null,
    maintenanceContributesToVigilance: false,
    showTable: true,
    showMap: true,
    onlyVisisbleVehicles: false,
    maintenanceTimeLimit: 60 * 24,
    vigilanceTimeLimit: 60 * 24,
  };

  constructor(
    categoryService: ZonegroupService,
    protected confirmationService: ConfirmationService,
    uteService: UteService,
    private notificationsService: NotificationsService,
    protected authService: AuthenticationService,
    protected i18n: I18nService
  ) {
    this.zonesFilter = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      loadPermissions: true,
      filters: { enabled: ["true"] },
    };

    this.categoryService = categoryService;
    this.uteService = uteService;
    this.loadListOfConfigurations();
    this.loadColoringConfiguration();
  }

  ngOnInit() {

  }

  loadColoringConfiguration() {
    this.uteService.findColoringConfigurationByClient(this.authService.user.client.id).then(
      (response) => {
        this.configurationColoring = response;

        for(let config  of this.configurationColoring.coloring){
          this.optionsRolColoring.push({ label: config.rol, value: config });
        }

      },
      (error) => {
        if (error.status === 500) {
          console.log(error);
        } else {
          console.log(error);
        }
      }
    );
  }
  /**
   * LOAD THE LIST OF CONFIGURATIONS
   */
  loadListOfConfigurations() {
    this.uteService.getListOfConfigurations().then(
      (responce) => {
        this.allConfigurations = responce;
      },
      (error) => {
        if (error.status === 500) {
          console.log(error);
        } else {
          console.log(error);
        }
      }
    );
  }

  /**
   * DELETE THE CONFIGURATION RELATED TO THE INDEX, AND REFRESH THE LIST
   */
  deleteConfiguration(index: number) {
    if (index > -1) {
      this.uteService
        .deleteConfiguration(this.allConfigurations[index].id)
        .then(
          (responce) => {
            this.notificationsService.add(
              Severity.success,
              this.i18n._("Delete"),
              this.i18n._("The configuration was deleted")
            );
            this.someConfigurationChange.emit({
              id: this.allConfigurations[index].id,
              deleted: true,
            });
            this.loadListOfConfigurations();
            this.currentConfiguration = null;
            this.display = false;
          },
          (error) => {
            if (error.status === 500) {
              console.log(error);
            } else {
              console.log(error);
            }
          }
        );
    }
  }

  /**
   *
   * @param event
   * @param id        Id of the column that call the mehtod
   * @param isSorting Value of the order checkbox
   */
  OrderChanged(event: any, id: string, isSorting: boolean) {
    if (isSorting) {
      this.currentConfiguration.columns.forEach((column) => {
        if (column.id !== id) {
          column.order.active = false;
        }
      });
    }
  }

  /**
   *  GIVEN AN ID WE LOAD IT AND REFRESH COLOR AND VEHICLES COMPONENT
   * @param id Configuration id
   */
  loadConfiguration(id: number) {
    this.currentConfiguration = null;

    if (id == null || id === undefined) {
      console.log("Error id with null");
      return;
    }
    this.uteService.getConfiguration(id).then(
      (responce) => {
        this.currentConfiguration = responce;
        this.coloring = this.currentConfiguration.coloring.info != null;
        this.loadSelectedVehiclesGroups();
      },
      (error) => {
        if (error.status === 500) {
          console.log(error);
        } else {
          console.log(error);
        }
      }
    );
  }

  /**
   * UNLOAD/DISCARD CURRENT CONFIGURATION
   */
  cancelCurrentConfiguration() {
    this.currentConfiguration = null;
  }

  /**
   * SAVE CURRENT CONFIGURATION
   */
  saveCurrentConfiguration() {
    if(!this.coloring){
      this.currentConfiguration.coloring.info = null;
    }

    // TRANSFORM SELECTING FORMAT TO STORE FORMAT (BASICALLY WE REMOVE USELESS ATRIBUTES)
    this.storeSelectedVehiclesGroups();

    if (this.currentConfiguration.id == null) {
      this.saveNewConfiguration();
    } else {
      this.saveModifiedConfiguration();
    }
  }

  /**
   * IF ITS A NEW CONFIGURATION: WE CREATE THE NEW CONFIGURATION INTO WS, NOTIFY FATHER AND CLOSE VIEWSETTINGS COMPONENT
   */
  saveNewConfiguration() {
    this.uteService.createConfiguration(this.currentConfiguration).then(
      (responce) => {
        //this.loadListOfConfigurations(); // REFRESH LIST
        this.currentConfiguration = null; // REFRESH CURRENT CONFIGURATION
        this.someConfigurationChange.emit({ id: responce.id, deleted: false }); // NOTIFY PANEL THAT WE CHANGE SOME OF THE CONFIGURATIONS
        this.display = false; // CLOSE COMPONENT VIEW SETTINGS
        this.notificationsService.add(
          Severity.success,
          this.i18n._("Save"),
          this.i18n._("The configuration was saved")
        );
      },
      (error) => {
        if (error.status === 500) {
          console.log(error);
        } else {
          console.log(error);
        }
      }
    );
  }

  /**
   * IF ITS A EXISTING CONFIGURATION: WE OVERRIDE THE CHANGES OF THE CONFIGURATION INTO WS, NOTIFY FATHER AND CLOSE VIEWSETTINGS COMPONENT
   */
  saveModifiedConfiguration() {
    this.uteService.updateConfiguration(this.currentConfiguration).then(
      (responce) => {
        //this.loadListOfConfigurations(); // REFRESH LIST
        this.currentConfiguration = null; // REFRESH CURRENT CONFIGURATION
        this.someConfigurationChange.emit({ id: responce.id, deleted: false }); // NOTIFY PANEL THAT WE CHANGE SOME OF THE CONFIGURATIONS
        this.display = false; // CLOSE COMPONENT VIEW SETTINGS
        this.notificationsService.add(
          Severity.success,
          this.i18n._("Save"),
          this.i18n._("The configuration was saved")
        );
      },
      (error) => {
        if (error.status === 500) {
          console.log(error);
        } else {
          console.log(error);
        }
      }
    );
  }

  /**
   * PARSE AND STORE THE CURRENT SELECTED VEHICLES GROUPS AND FLEETS FROM COMPONENT app-fleet-tree INTO CURRENT CONFIGURATION
   */
  storeSelectedVehiclesGroups() {
    (
      this.currentConfiguration.columns.find((col) => col.id === "vehicle")
        .filter as IDFilter
    ).ids = this.ParseVehiclesAndGroupsBeforeSave();
  }

  /**
   * LOAD CURRENT CONFIGURATION VEHICLES GROUPS AND FLEETS IN THE VARIABLE INTERMEDIARY BETWEEN CONFIG AND app-fleet-tree COMPONENT
   */
  loadSelectedVehiclesGroups() {
    this.fleetsGroupsAndVehiclesSelected = (
      this.currentConfiguration.columns.find((col) => col.id === "vehicle")
        .filter as IDFilter
    ).ids;

  }

  /**
   * WE PARSE THE OUTPUT FROM app-fleet-tree COMPONENT, THIS WAY WE DISCARD A LOT OF NOTUSFULL ATRIBUTES
   */
  ParseVehiclesAndGroupsBeforeSave(): any {
    const parsed: any[] = [];
    this.fleetsGroupsAndVehiclesSelected.map((selection) => {
      selection = [selection];

      if (selection[0].hasOwnProperty("groups")) {
        //  IF HAVE 'groups' atribute we know its a fleet
        
        parsed.push(
          selection.map(function (fleet) {
            var groups = fleet.groups != null ? fleet.groups : [];
            
            return {
              name: fleet.name,
              id: fleet.id,
              groups: groups.map(function (group) {
                return {
                  id: group.id,                 
                  vehicles: group.vehicles.map(function (vehicle) {
                    return { id: vehicle.id };
                  }),
                };
              }),
            };
          })[0]
        );
      } else if (selection[0].hasOwnProperty("vehicles")) {
        //  IF HAVE 'vehicles' atribute we know its a group
        parsed.push(
          selection.map(function (group) {
            var fleetId = group.fleet != null ? group.fleet.id : 0;
            var fleetName = group.fleet != null ? group.fleet.name : "";
            var vehicles = group.vehicles != null ? group.vehicles : [];
            return {
              name: group.name,
              id: group.id,
              fleet: { id: fleetId, name: fleetName },
              vehicles: vehicles.map(function (vehicle) {
                return { id: vehicle.id };
              }),
            };
          })[0]
        );
      } else if (selection[0].hasOwnProperty("group")) {
        //  IF HAVE 'group' atribute we know its a vehicle
        parsed.push(
          selection.map(function (vehicle) {
            var groupId = vehicle.group != null ? vehicle.group.id : 0;
            var groupName = vehicle.group != null ? vehicle.group.name : "";
            return {
              name: vehicle.name,
              id: vehicle.id,
              group: { id: groupId, name: groupName },
            };
          })[0]
        );
      }
    });

    return parsed;
  }

  get rightListEntities(): any[] {
    const idColumn = 'controlPoint';
  
    const column = this.currentConfiguration.columns.find((col) => col.id === idColumn); 
  
    if (column && column.filter && (column.filter as IDFilter).ids) {
      return (column.filter as IDFilter).ids;
    }
  
    return [];
  }

  set rightListEntities(zones){
    var controlPointSelected = [];

    if(zones != null && zones != undefined && zones.length > 0){
      
      for(let i = 0; i < zones.length; i++){
        controlPointSelected.push({ id: zones[i].id, name: zones[i].name });
      }
    }
    (this.currentConfiguration.columns.find((col) => col.id === "controlPoint").filter as IDFilter).ids = controlPointSelected;
  }
  /**
   * CREATE A NEW CONFIG SETUP
   */
  loadBaseConfiguration() {
    this.currentConfiguration = JSON.parse(
      JSON.stringify(this.baseConfiguration)
    );
    this.loadSelectedVehiclesGroups();
  }

  /**
   * STORE THE SAME VISIBILITY AS VEHICLE COLUMN INTO GROUP COLUMN
   */
  transferToGroup() {
    this.currentConfiguration.columns.find(
      (col) => col.id === "group"
    ).visible = this.currentConfiguration.columns.find(
      (col) => col.id === "vehicle"
    ).visible;
  }

  /*
  getHeaderTitle(): string{
    if(this.currentConfiguration != null){
      return this.currentConfiguration.name;
    }
    return "Configurations"
  }
  */

  /**
   * OPEN VIEW SETTINGS AND CLEAN CONFIGURATION
   */
  show(): Promise<{}> {
    this.loadListOfConfigurations();
    
    this.display = true;
    this.currentConfiguration = null;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  /**
   * DIALOG WHEN WE TRY TO SWAP BETWEEN CONFIGURATIONS
   * @param configurationId
   */
  confirmChangeConfiguration(configurationId: number) {
    if (
      this.currentConfiguration != null &&
      this.currentConfiguration.id != null
    ) {
      this.confirmationService.confirm({
        message: this.i18n._(
          "Are you sure you want to change the configuration? Changes will not be saved"
        ),
        header: this.i18n._("Confirmation"),
        icon: "fa fa-exclamation-triangle",
        accept: () => {
          this.loadConfiguration(configurationId);
        },
        reject: () => {},
      });
    } else {
      this.loadConfiguration(configurationId);
    }
  }

  /**
   * DIALOG WHEN WE TRY TO DELETE A CONFIGURATIN
   */
  confirmDeleteConfiguration(i: number) {
    this.confirmationService.confirm({
      message: this.i18n._(
        "Are you sure you want to delete the configuration?"
      ),
      header: this.i18n._("Confirmation"),
      icon: "fa fa-exclamation-triangle",
      accept: () => {
        this.deleteConfiguration(i);
      },
      reject: () => {},
    });
  }

  /**
   * DIALOG WHEN WE TRY TO CANCEL A CONFIGURATION EDIT
   */
  async confirmCancelConfigEdit() {
    this.confirmationService.confirm({
      message: this.i18n._(
        "Are you sure you want to cancel the configuration editing? Changes will not be saved"
      ),
      header: this.i18n._("Confirmation"),
      icon: "fa fa-exclamation-triangle",
      accept: () => {
        this.cancelCurrentConfiguration();
      },
      reject: () => {},
    });
  }

  /**
   * DIALOAG WHEN WE TRY TO SAVE A CONFIGURATION
   */
  confirmSaveConfiguration() {
    //WE VERIFY THE VALIDITY OF THE ROWS BEFOR SAVE
    if (!this.verifyConfigurationFormat()) {
      return;
    }

    // IF ID IS NULL MEANS ITS A NEW CONFIGURATION
    if (this.currentConfiguration.id != null) {
      this.confirmationService.confirm({
        message: this.i18n._(
          "Are you sure you want to save the configuration?"
        ),
        header: this.i18n._("Confirmation"),
        icon: "fa fa-exclamation-triangle",
        accept: () => {
          this.saveCurrentConfiguration();
        },
        reject: () => {},
      });
    } else {
      this.saveCurrentConfiguration();
    }
  }

  /**
   * VERIFY IF IN THE CURRENTCONFIGURATION ATLEAST ONE CPO AND A ONE VEHCILE ARE SELECTED
   */
  verifyConfigurationFormat(): boolean {
    let correctFormat = true;
    // WE CHECK IF THE NAME FILTER IS FILLED ATLEAST WITH ONE REGISTER
    let nameIds = (
      this.currentConfiguration.columns.find((col) => col.id === "controlPoint")
        .filter as IDFilter
    ).ids;
    // WE CHECK IF THE VEHICLE FILTER IS FILLED ATLEAST WITH ONE REGISTER

    if (nameIds === undefined || nameIds == null || nameIds.length <= 0) {
      this.notificationsService.add(
        Severity.error,
        this.i18n._("Save"),
        this.i18n._(
          'The configuration has not the correct structure since no name was selected in "Control Point Group" column filter'
        )
      );
      correctFormat = false;
    }

    if (
      this.fleetsGroupsAndVehiclesSelected === undefined ||
      this.fleetsGroupsAndVehiclesSelected == null ||
      this.fleetsGroupsAndVehiclesSelected.length <= 0
    ) {
      this.notificationsService.add(
        Severity.error,
        this.i18n._("Save"),
        this.i18n._(
          'The configuration has not the correct structure since no vehicle was selected in "vehicle and group" column filter'
        )
      );
      correctFormat = false;
    }

    return correctFormat;
  }

  /*
  printCurrent():string{
    if(this.currentConfiguration!=null){
      return JSON.stringify(this.currentConfiguration)
    }
    return "Current es null"

  }
  */
}
