import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { RestVehicleInventory } from './rest_vehicle-inventory';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
 })
export class DriverService extends EntityService<Rest.InvDriver> {

	client: Rest.RestApplicationClient;
	 selectDriver = new Subject();
	 driverSelected = new Subject<Rest.InvDriver>();

    constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.InvDriver): Rest.RestResponse<Rest.InvDriver> {
        return this.client.Driver_create(arg0);
    }

    update(arg0: Rest.InvDriver): Rest.RestResponse<Rest.InvDriver> {
        return this.client.Driver_update(arg0);
    }

    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvDriver>> {
        return this.client.Driver_getPage(arg0);
    }

	getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.InvDriver>> {
        return this.accessory.Driver_getPageAndFilters(arg0);
    }

	getPageAll(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.InvDriver>> {
        return this.client.Driver_getPageAll(arg0);
    }
    changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
        return this.client.Driver_changeStatus(arg0, queryParams);
    }

    find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.InvDriver> {
        return this.client.Driver_find(arg0, queryParams);
    }

	getList(clientId: number): Rest.RestResponse<Rest.InvDriver[]> {
		return this.client.Driver_getList(clientId);
   }

}

