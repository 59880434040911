<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >SMS FORWARDING (FW)</span>
        </div>
      </div>
    </p-header>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Phone Number" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="smsForwarding.phoneNumber" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Content" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <input type="text" pInputText [(ngModel)]="smsForwarding.content" class="txtbStyle"/>
          </div>
        </div>
      </div>
    
    </div>
  </p-accordionTab>
  