import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TreeQuickActionsType } from '../rt-fleet-tree.component';
import { Rest } from 'app/rest/rest_client';
import { RealtimeService } from 'app/rest/realtime.service';
import { DeviceService } from 'app/rest/device.service';
import { RealTimeDataService } from 'app/services/rt-data/rt-data.service';
import { Router } from '@angular/router';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-tree-quick-actions',
  templateUrl: './tree-quick-actions.component.html',
  styleUrls: ['./tree-quick-actions.component.css'],
  providers: [RealtimeService, DeviceService]
})
export class TreeQuickActionsComponent implements OnInit {
  @Input() vehicle: Rest.Vehicle;
  @Input() actionType: TreeQuickActionsType;
  iconClass: String = "";
  routeShown: boolean = false;
  route: Rest.Vertex[] = [];

  constructor(private i18n: I18nService, private rtService: RealtimeService, private realTimeDataService: RealTimeDataService, private notificationService: NotificationsService, private deviceService: DeviceService, private router: Router, private jquery: JQueryHTTPClient) {
  }

  ngOnInit(): void {
    switch (this.actionType) {
      case TreeQuickActionsType.center:
        this.iconClass = "fa fa-map-marker";
        break;
      case TreeQuickActionsType.requestPosition:
        this.iconClass = "fa fa-map-o";
        break;
      case TreeQuickActionsType.settings:
        this.iconClass = "fa fa-cog";
        break;
      case TreeQuickActionsType.report:
        this.iconClass = "fa fa-file-text-o";
        break;
      case TreeQuickActionsType.showRoute:
        this.iconClass = "fa fa-map-o";
        break;
      case TreeQuickActionsType.sendMessage:
        this.iconClass = "fa fa-comments";
        break;
      case TreeQuickActionsType.io:
        this.iconClass = "fa fa-eye";
        break;
      case TreeQuickActionsType.alerts:
        this.iconClass = "fa fa-exclamation-triangle";
        break;
      case TreeQuickActionsType.sendLocation:
        this.iconClass = "fa fa-map-signs";
        break;
      case TreeQuickActionsType.call:
        this.iconClass = "fa fa-phone";
        break;
      case TreeQuickActionsType.maintenances:
        this.iconClass = "fa fa-adjust";
        break;
      case TreeQuickActionsType.calendar:
        this.iconClass = "fa fa-calendar";
        break;
      case TreeQuickActionsType.zones:
        this.iconClass = "fa fa-square-o";
        break;
    }
  }

  treeQuickAction() {

    if (this.actionType == TreeQuickActionsType.center) {
      this.realTimeDataService.focusMap([this.vehicle.id]);
    }



    else if (this.actionType == TreeQuickActionsType.requestPosition) {
      let req = <Rest.LocationRequestMessage>{
        id: null,
        vehicleId: this.vehicle.id,
        platformTime: null,
        requestType:"GET_LOCATION",
        tag:""
      }
      this.deviceService.sendRequest(this.vehicle.id +"", req).then(
        (request: Rest.RequestMessage) => {
          this.notificationService.add(Severity.success, this.i18n._(':)'),
          this.i18n._('Get location command queued. The message will be sent to the device as soon as possible'));
      }).catch((e: any) => {
        this.notificationService.add(Severity.error, this.i18n._(':('), this.i18n._('Unable to queue get location command.'));
      });
    }



    else if (this.actionType == TreeQuickActionsType.settings) {
      this.router.navigate(["control-panel/devices", {"id": this.vehicle.device.id}])
    }



    else if (this.actionType == TreeQuickActionsType.report) {
    }



    else if (this.actionType == TreeQuickActionsType.showRoute) {
      (): Promise<any> => {
        if (this.routeShown) {
          this.routeShown = false;
          this.realTimeDataService.hideRoute(this.vehicle.id);
        } else {
    
          //Get last 10 positions for the vehicle from the RT service
    
          return this.jquery.request({
            method:"POST",
            url: RestExt.uriEncoding`route`,
            queryParams: {maxCount: 10},
            data: [this.vehicle.id],
            headers: false,
            cmREST: false
          }).then((r: Rest.VehicleDataMessage[]) => {
            let vertex = r.map(m => <Rest.Vertex>{latitude: m.latitude, longitude: m.longitude});
            this.route = vertex;
            this.realTimeDataService.showRoute(this.vehicle.id, [vertex], true);
            this.routeShown = true;
    
            //From now on, when more data for the vehicle comes, the route will be updated as long as routeShown is true
            this.realTimeDataService.vehicleInfo.takeWhile(() => this.routeShown).subscribe((vi: Rest.VehicleDataMessage) => {
              if (vi.vehicleId != this.vehicle.id) return;
              this.route.push(<Rest.Vertex>{latitude: vi.latitude, longitude: vi.longitude});
              this.realTimeDataService.showRoute(this.vehicle.id, [this.route], false);
            });
          });
        }
    
      }
    }



    else if (this.actionType == TreeQuickActionsType.sendMessage) {
      // const sendMessageModal = new Modal(modalRef.nativeElement);
      // sendMessageModal.show();
    }



    else if (this.actionType == TreeQuickActionsType.io) {
      // const ioModal = new Modal(modalRef.nativeElement);
      // ioModal.show();
    }



    else if (this.actionType == TreeQuickActionsType.alerts) {
    }



    else if (this.actionType == TreeQuickActionsType.sendLocation) {
    }



    else if (this.actionType == TreeQuickActionsType.call) {
      // const callBackModal = new Modal(modalRef.nativeElement);
      // callBackModal.show();
    }



    else if (this.actionType == TreeQuickActionsType.maintenances) {
      this.router.navigate(["control-panel/fleets", {"vehicle": this.vehicle.id}]);
    }



    else if (this.actionType == TreeQuickActionsType.calendar) {
    }



    else if (this.actionType == TreeQuickActionsType.zones) {
    }

  }

}
