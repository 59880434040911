import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { BillingaccountService } from "../../../rest/billingaccount.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { isValidNif } from "nif-dni-nie-cif-validation";
import { Message } from "primeng/api";
import { Subscription } from "rxjs";

@Component({
    selector: "app-billing-account-form",
    templateUrl: "./billing-account-form.component.html",
    styleUrls: ["./billing-account-form.component.css"],
    providers: [BillingaccountService],
})
export class BillingAccountFormComponent
    extends EntityFormComponent<Rest.BillingAccount>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    cifValid: boolean = true;

    /* Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private billingaccountService: BillingaccountService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService
    ) {
        super(
            billingaccountService,
            notificationsService,
            i18n,
            authenticationService
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    beforeShow() {
        /**Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.i18n._("Create Billing Account");
        } else {
            this.title = this.i18n._("Edit Billing Account");
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */
    }


    private saveBillingAccountForm(billingAccount){
        if(this.validateBillingAccountForm(billingAccount)){
            this.save();
        }
    }

    private validateBillingAccountForm(billingAccount): boolean {
            this.cifValid = true;
            if (!isValidNif(billingAccount.cif) && billingAccount.cif != null) {
           // this.cifValid = false;
            alert("isValidNif false");


            this.messages.push(
                //this.translate.instant('error.required-field.name'),
            "dni format error isValidNif"
            );
            return false;
        }
        return true;
    }
}
