import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverAssignmentsService } from 'app/modules/vehicle-inventory/services/driver-assignments.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { CecoService } from 'app/rest/ceco.service';
import { DivisionService } from 'app/rest/division.service';
import { Rest } from 'app/rest/rest_client';
import { SubdivisionService } from 'app/rest/subdivision.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-drivers-assignments-list',
  templateUrl: './drivers-assignments-list.component.html',
  styleUrls: ['./drivers-assignments-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DriversAssignmentsListComponent extends EntityListComponent<Rest.InvDriverAssignment> implements OnInit, OnDestroy {
	title: string = "Drivers";

	@Input() vehicle?: Rest.Vehicle = null;
	@Input() driver?: Rest.InvDriver = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;
	client: Rest.Client = null;

	refreshListSuscription: Subscription;

	divisionList: Rest.Division[] = [];
	isDivisionListLoaded: Boolean = false;
	subdivisionList: Rest.Subdivision[] = [];
	isSubdivisionListLoaded: Boolean = false;
	cecoList: Rest.Ceco[] = [];
	isCecoListLoaded: Boolean = false;
	billingAccountList: Rest.BillingAccount[] = [];
	isBillingAccountListLoaded: Boolean = false;

	user: Rest.User;
	EnabledFilter: SelectItem[];
	constructor(
		 protected driverAssignmentsService: DriverAssignmentsService,
		 protected confirmationService: ConfirmationService,
		 public notificationsService: NotificationsService,
		 protected i18n: I18nService,
		 authenticationService: AuthenticationService,
		 protected loadingService: LoadingService,
		 protected userTime: UserTimePipe,
		 protected excelService: ExcelService,
		 protected translateService: TranslateService,
		 public divisionService: DivisionService,
		 public subdivisionService: SubdivisionService,
		 public cecoService: CecoService,
		 public billingAccountService: BillingaccountService
	) {
		 super(
			  driverAssignmentsService,
			  confirmationService,
			  notificationsService,
			  i18n,
			  authenticationService,
			  loadingService,
			  userTime,
			  excelService
		 );
	}

	ngOnInit() {
		 super.ngOnInit();
		 /*Permissions */
		 this.user = this.authenticationService.user;
		 /** */
		 this.client = this.driver ? this.driver.client : this.vehicle.client;
		 this.getDivisionList(this.client.id);
		 this.getSubdivisionList(this.client.id);
		 this.getCecoList(this.client.id);
		 this.getBillingAccountList(this.client.id);
		 this.refreshListSuscription = this.driverAssignmentsService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				// console.log("[DRIVERS-ASSIGNMENTS-LIST] refreshList ***");
				// console.log(item);
				this.getList(item.startDate, item.endDate);
			}
		);
	}

	onDestroy() {
		this.refreshListSuscription.unsubscribe();
	}

	public getList(startDate: Date, endDate: Date) {
		// console.log("[DRIVERS-ASSIGNMENTS-LIST] getList ***");
		this.startDate = startDate;
		this.endDate = endDate;
		if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		if (this.driver) this.paginationRequest.filters['driverId'] = [this.driver.id.toString()];
		this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
		this.paginationRequest.filters['endDate'] = [this.endDate.toISOString()];
		// console.log(this.paginationRequest);
		super.refresh();
	}

	beforeLoad(): void {
		if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		if (this.driver) this.paginationRequest.filters['driverId'] = [this.driver.id.toString()];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.endDate.toISOString()];
		if(this.paginationRequest.filters['ceco']) this.paginationRequest.filters['ceco'].length == 0 ? this.paginationRequest.filters['ceco'] = ["-2"]:"";
		if(this.paginationRequest.filters['billingAccount']) this.paginationRequest.filters['billingAccount'].length == 0 ? this.paginationRequest.filters['billingAccount'] = ["-2"]:"";
		if(this.paginationRequest.filters['division']) this.paginationRequest.filters['division'].length == 0 ? this.paginationRequest.filters['division'] = ["-2"]:"";
		if(this.paginationRequest.filters['subDivision']) this.paginationRequest.filters['subDivision'].length == 0 ? this.paginationRequest.filters['subDivision'] = ["-2"]:"";    
	}

	public afterLoad() {
		// console.log("[DRIVERS-ASSIGNMENTS-LIST] afterLoad ***");
		// console.log(this.paginationResult);
	}

	public getDivisionList(clientId: number) {
		// console.log("[DRIVERS-ASSIGNMENTS-LIST] getDivisionList ***");
		// console.log(clientId);
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.divisionService
				.getList(clientId)
				.then(
			 		function (response) {
						that.divisionList = response;
						that.isDivisionListLoaded = true;
						// console.log("*** divisionList ***");
						// console.log(that.divisionList);
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}

	public getSubdivisionList(clientId: number) {
		// console.log("[DRIVERS-ASSIGNMENTS-LIST] getSubdivisionList ***");
		// console.log(clientId);
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.subdivisionService
				.getList(clientId)
				.then(
			 		function (response) {
						that.subdivisionList = response;
						that.isSubdivisionListLoaded = true;
						// console.log("*** SubdivisionList ***");
						// console.log(that.subdivisionList);
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}

	public getCecoList(clientId: number) {
		var cecoRequestContext = {
			sortBy: "id",
			sortAsc: true,
			pageSize: 1000000,
			filters: {
				disabled: [false]
			},
		  } as unknown as Rest.ListPaginationRequest;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.cecoService
				.getPage(cecoRequestContext)
				.then(
			 		function (response) {
						that.cecoList = response.entities;
						that.isCecoListLoaded = true;
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}

	public getBillingAccountList(clientId: number) {

		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.billingAccountService
				.getList(clientId)
				.then(
			 		function (response) {
						that.billingAccountList = response;
						that.isBillingAccountListLoaded = true;
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}


	excelColumns = {};

	exportExcel(){
		 this.setExcelColumns();
		 let fileNameTitle = this.translateService.instant('vehicleAssignments.header');
		 super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
	}
	setExcelColumns() {
		 const fullName = (entity: any) => `(${entity.code ?? ''})  ${entity.user?.surname1 ?? ''} ${entity.user?.surname2 ?? ''},  ${entity.user?.name ?? ''}`//${ } concatenación
		 const startDate = (entity: any) => entity.startDate ?? '';
		 const endDate = (entity: any) => entity.endDate ?? '';
		 const disabled = (entity: any) => entity.disabled ?? ''

		this.excelColumns = {
			 [this.translateService.instant('drivers.table.name')]: { bindField: fullName },
			 [this.translateService.instant('drivers.table.startDate')]: { bindField: startDate },
			 [this.translateService.instant('drivers.table.endDate')]: {  bindField: endDate },
			 [this.translateService.instant('general.disabled')]: { bindField: disabled },
			}
	}



	}
