<p-dialog header={{noticeGroupFormTitle}} [(visible)]="display" [responsive]="true" showEffect="fade" 
   [modal]="true"  [draggable]="true" *ngIf="display">

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <div class="ui-g">

      <div class="ui-g-12 ui-md-6">
        <form-input [required]="true" i18n-fieldName fieldName="{{'general.name' | translate}}" [errors]="errors.name">
          <input pInputText type="text" id="name" [(ngModel)]="entity.name" />
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input [required]="true" i18n-fieldName fieldName="{{'general.description' | translate}}" [errors]="errors.description">
          <input pInputText id="description" [(ngModel)]="entity.description" />
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'general.enabled' | translate}}" [errors]="errors.enabled">
          <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'control-panel.notifications-groups.notifyBySMS' | translate}}" [errors]="errors.notifyBySMS">
          <p-checkbox  [(ngModel)]="entity.notifyBySMS" binary="true"></p-checkbox>
        </form-input>
      </div>

      
      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'control-panel.notifications-groups.notifyByEmail' | translate}}" [errors]="errors.notifyByEmail">
          <p-checkbox [(ngModel)]="entity.notifyByEmail" binary="true"></p-checkbox>
        </form-input>
      </div>

      
      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'control-panel.notifications-groups.notifyByApp' | translate}}" [errors]="errors.notifyByApp">
          <p-checkbox [(ngModel)]="entity.notifyByApp" binary="true"></p-checkbox>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'control-panel.notifications-groups.notifyByWeb' | translate}}" [errors]="errors.notifyByWeb">
          <p-checkbox [(ngModel)]="entity.notifyByWeb" binary="true"></p-checkbox>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="{{'control-panel.notifications-groups.tagEvents' | translate}}" [errors]="errors.tag_events">
          <p-chips [(ngModel)]="entity.tag_events"></p-chips>
        </form-input>
      </div>

    </div>

    <p-tabView #tv>

      <p-tabPanel i18n-header header="Users">
        <paged-picklist [paginationRequest]="userRequestContext" [pageFunction]="this.userService.getPage.bind(this.userService)" labelProperty="name" [(rightListEntities)]="entity.visibleUsers">
        </paged-picklist>
      </p-tabPanel>

      <p-tabPanel i18n-header header="Fleets">
        <app-fleet-tree [viewDisableds]=false [(selection)]="fleetsGroupsAndVehiclesSelected" [cascadeSelection]=true [selectableEntities]=false></app-fleet-tree>
      </p-tabPanel>
    </p-tabView>

    <div *ngIf="showStatusDialog">
      <app-change-status-dialog [items]=title [display]="true" [service]=userService [entitiesList]=selectedEntities (return)="refreshForm($event)"
        (close)="closeStatusDialog($event)"></app-change-status-dialog>
    </div>

  </div>

  <p-footer>

    <!-- Permissions -->
    <!-- <div *ngIf="isNew" class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="Save"></button>
    </div>

    <div *ngIf="isNew == false" class="ui-dialog-buttonpane ui-helper-clearfix">
      <div *ngIf="entity._$update">
        <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="Save"></button>
      </div>
      <div *ngIf="entity._$update==false">
        <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
          <button disabled="true" type="button" pButton icon="fa-check" (click)="save()" i18n-label label="Save"></button>
        </div>
      </div>
      <button type="button" pButton icon="fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="Change status"></button>
    </div> -->

    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <div *ngIf="disableSave == false">
        <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
      </div>
      <div *ngIf="disableSave == true">
        <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
          <button disabled="true" type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
        </div>
      </div>
      <div *ngIf="isNew == false">
        <div *ngIf="disabeChangeStatus == false">
          <button type="button" pButton icon="fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="{{'general.changeStatus' | translate}}"></button>
        </div>
        <div *ngIf="disabeChangeStatus == true">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status" tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="{{'general.changeStatus' | translate}}"></button>
          </div>
        </div>
      </div>
    </div>


  </p-footer>

</p-dialog>