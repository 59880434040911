import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "app/services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-sos-form-gv300can",
  templateUrl: "./sos-form-gv300can.component.html",
  styleUrls: ["./sos-form-gv300can.component.css"],
})
export class SosFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() sosParameters: Rest.SOSParametersGV300CAN;
  m: Rest.ModeSos = "DISABLE";

  modeOptions: any = [
    { label: "Disable SOS function", value: 0 },
    {
      label: "Send the current position to the backend server via GPRS only",
      value: 1,
    },
    {
      label: "Send the current position to the SOS Number via SMS only",
      value: 2,
    },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.sosParameters);
    }
  }

  onChange() {
    if (
      2 < this.sosParameters.digitalInputID &&
      this.sosParameters.digitalInputID < 9
    ) {
      if (this.sosParameters.digitalInputID === 3) {
        this.sosParameters.digitalInputID = 9;
      } else {
        this.sosParameters.digitalInputID = 2;
      }
    }
  }

  trackModeSwitch = false;

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
