import { Component, Input, OnInit } from '@angular/core';

/* TODO: Remove component, since Growl is deprecated and NotificationService is used instead of this module */
@Component({
   selector: 'app-flexible-growl',
   templateUrl: './flexible-growl.component.html',
   styleUrls: ['./flexible-growl.component.css'],
})
/**
 * Flexible growl component that enables message lifesapn set per message.
 * The default growl component https://www.primefaces.org/primeng/#/growl only allows to define stiky and life values
 * on a global basis (for every message displayed). This component permits to specify these parameters per message.
 */
export class FlexibleGrowlComponent implements OnInit {
   _msgs: NonStikyNotification[] = [];
   @Input() msgs: NonStikyNotification[] = [];

   constructor() {}

   ngOnInit() {}

   ngDoCheck() {
      if (this.msgs.length == this._msgs.length) {
         return;
      }

      for (const i in this.msgs) {
         const newMessage: NonStikyNotification = this.msgs[i];
         if (!this._msgs.find((m) => newMessage == m)) {
            const ts = +new Date();
            newMessage.ts = ts;
            this._msgs.push(newMessage);
            /* if(!newMessage.sticky)
         {
          var that = this;
          setTimeout(()=>{
            that.msgs.splice(this.msgs.indexOf(that.msgs.find(e=> e.ts == ts)),1);
          that._msgs.splice(that.msgs.indexOf(that._msgs.find(e=> e.ts == ts)),1);
          },newMessage.life)
        }*/
         }
      }
   }
}

export interface NonStikyNotification extends Notification {
   ts?: number;
}
