<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- NAME -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardType-name" class="custom-label" i18n>
                    {{'general.name' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="cardType-name" class="form-control custom-input" [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- ACTIVE -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="cardType-enabled" class="custom-label" i18n>
                    {{ 'general.active' | translate }}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                        type="checkbox" [(ngModel)]="active">
                </div>
            </div>
        </div>

    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="save()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(!active)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->

</p-dialog>