import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { Subject } from 'rxjs';
import { RestVehicleInventory } from './rest_vehicle-inventory';
import { InvVehicleService } from './invVehicle.service';

@Injectable({
	providedIn: 'root'
 })
export class VehicleHtcService extends EntityService<RestVehicleInventory.InvVehicleHtcResponse>{

	inventoryVehicleClient: RestVehicleInventory.RestApplicationClient;
	vehicleClient: Rest.RestApplicationClient;
	getVehicleHtc = new Subject<Rest.Vehicle>();

   constructor(
		private jquery: JQueryHTTPClient,
		public invVehicleService: InvVehicleService
	) {
	  super();
	  this.inventoryVehicleClient = new RestVehicleInventory.RestApplicationClient(jquery);
	  this.vehicleClient = new Rest.RestApplicationClient(jquery);
   }

	public initVehicleHtc() : RestVehicleInventory.InvVehicleHtcResponse {
		return {
			id: 0,
			vehicle: this.vehicleService.initVehicle(),
			vehicleStatus: null,
			vehicleUsage: null,
			documents: null,
			notes: null,
			// vehicleAssignments: null,
			// vehicleAccessories: null,
			// rentings: null,
			// insurances: null,
			// taxes: null,
			// fines: null,
			// maintenances: null,
			// cards: null,
			// maintenance: null,
			// driversAssignments: null
		};
	}

	public getVehicleHtcData(vehicleId: number, startDate: string, endDate: string) : Rest.RestResponse<RestVehicleInventory.InvVehicleHtcResponse> {
		console.log("[VEHICLE-HTC-SERVICE] getVehicleHtcData *****");
		let queryParams = {
			vehicleId: vehicleId,
			startDate: startDate,
			endDate: endDate
		};
		return this.inventoryVehicleClient.InvHtcVehicle_find(queryParams);
	}

	// public createStatusHtc(arg0): Promise<Rest.VehicleStatus> {
	// 	console.log("[VEHICLE-HTC-SERVICE] createStatusHtc *****");
	// 	console.log(arg0);
	// 	return this.iclient.InvStatusHtc_create(arg0);
	// }

	// public updateStatusHtc(arg0): Promise<Rest.VehicleStatus> {
	// 	console.log("[VEHICLE-HTC-SERVICE] updateStatusHtc *****");
	// 	console.log(arg0);
	// 	return this.client.InvStatusHtc_update(arg0);
	// }

}

