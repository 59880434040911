<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Track Event Configuration ($WP+TRACK)</span>
            </div>
        </div>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Tow Mode" >
          <p-dropdown  (onChange)="trackEventModeChange(trackEvent.mode,$event)" [options]="TrackMode"
          [(ngModel)]="trackEvent.mode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
  
      <div [class.disableDIV]=!trackModeSwitch>
            <div class="ui-g-12 ui-md-6">
                <form-input  fieldName="Time Interval" >
                  <div class="ui-inputgroup">
                    <p-spinner size="5" [(ngModel)]="trackEvent.time" [min]="5" [max]="65535" (onChange)="onChange()"></p-spinner>
                    <span class="ui-inputgroup-addon">5~65535</span>
                    <span class="ui-inputgroup-addon">s</span>
                  </div>
                </form-input>
              </div> 
              <div class="ui-g-12 ui-md-6">
                  <form-input  fieldName="Distance Interval" >
                    <div class="ui-inputgroup">
                      <p-spinner size="5" [(ngModel)]="trackEvent.distance" [min]="100" [max]="65535" (onChange)="onChange()"></p-spinner>
                      <span class="ui-inputgroup-addon">100~65535</span>
                      <span class="ui-inputgroup-addon">m</span>
                    </div>
                  </form-input>
                </div>
                <div class="ui-g-12 ui-md-6">
                    <form-input  fieldName="Tracking Times" >
                      <div class="ui-inputgroup">
                        <p-spinner size="5" [(ngModel)]="trackEvent.trackingTimes" [min]="0" [max]="65535" (onChange)="onChange()"></p-spinner>
                        <span class="ui-inputgroup-addon">0~65535</span>
                      </div>
                    </form-input>
                  </div>
                  <div class="ui-g-12 ui-md-6">
                      <form-input  fieldName="Track Basis" >
                        <p-dropdown [options]="TrackBasis"
                        [(ngModel)]="trackEvent.basis" [style]="{'width':'190px'}"></p-dropdown>
                      </form-input>
                    </div> 
                    <div class="ui-g-12 ui-md-6">
                        <form-input  fieldName="Heading" >
                          <div class="ui-inputgroup">
                            <p-spinner size="5" [(ngModel)]="trackEvent.heading" [min]="10" [max]="90" (onChange)="onChange()"></p-spinner>
                            <span class="ui-inputgroup-addon">10~90</span>
                            <span class="ui-inputgroup-addon">degree</span>
                          </div>
                        </form-input>
                      </div>  
    </div>
    </div>
  
  </p-accordionTab>
