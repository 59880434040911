import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { ServerValidatedFormComponent } from "app/components/server-validated-form/server-validated-form.component";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Accordion } from "primeng/accordion";
import { TabView } from "primeng/tabview";
import { I18nService } from "app/services/i18n/i18n.service";
import { NotificationsService } from "app/services/notifications-service/notifications.service";
import { PreconfigurationService } from "app/rest/preconfiguration.service";
import { Rest } from "app/rest/rest_client";
import { RestExt } from "app/services/rest-client-extension";
import { Auxiliar } from "app/model/Auxiliar";

@Component({
  selector: "app-we-track2-parameters-form",
  templateUrl: "./we-track2-parameters-form.component.html",
  styleUrls: ["./we-track2-parameters-form.component.css"],
  providers: [PreconfigurationService, ConfirmationService],
})
export class WeTrack2ParametersFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  //View params
  @Input() deviceParams: Rest.DeviceParameters; //.DeviceParameters;
  @Input() firmware: RestExt.FirmwareVersion;
  @Input() isNew: boolean;
  @Output() WeTrack2ParamsChange: EventEmitter<Rest.DeviceParameters> =
    new EventEmitter<Rest.DeviceParameters>();
  @ViewChild("tv", { static: true }) public tabView: TabView;
  @ViewChild("ac", { static: true }) accordion: Accordion;
  @Input() isConfig: boolean;
  @Output()
  refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  preconfigService: PreconfigurationService;
  confirmationService: ConfirmationService;
  notificationsService: NotificationsService;

  firmwareValue: any;

  /**Events */
  selected = false;

  /**PreConfigs */
  preConfigsList: Rest.DevicePreconfiguration[];
  availablePreconfigs: SelectItem[];
  selectedPreconfig: any;
  constructor(
    preconfigService: PreconfigurationService,
    confirmationService: ConfirmationService,
    notificationsService: NotificationsService,
    protected i18n: I18nService
  ) {
    super(i18n);
    this.preconfigService = preconfigService;
    this.confirmationService = confirmationService;
    this.notificationsService = notificationsService;
    //this.i18n = i18n;
  }
  ngOnChanges(changes: SimpleChanges): void {
    /* Sergi: this should be executed only if it's not initialized (not new) */
    if (this.isNew && this.deviceParams == null) {
      this.deviceParams = RestExt.emptyWeTrack2Params();
      console.log("Parameters ConcoxMT200 empty: ", this.deviceParams);
    }

    if (changes["deviceParams"]) {
      this.WeTrack2ParamsChange.emit(this.deviceParams);
    }
    
    RestExt.initializeWeTrack2Params(this.deviceParams);
  }
  ngOnInit(): void {
    this.initConfig();

    if (this.isConfig) {
      this.LoadPreconfigsList();
    }
  }

  initConfig() {
    this.preConfigsList = [];
    this.selectedPreconfig = null;
    if (this.accordion != undefined) {
      this.tabView.activeIndex = 0;
      this.closeAllAccordionTabs();
    }
  }

  loadPreconfig(event) {
    this.preconfigService
      .find(event.value.id)
      .then((pre: Rest.DevicePreconfiguration) => {
        this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
      });
  }

  LoadPreconfigsList() {
    let paginationRequest: Rest.ListPaginationRequest;
    this.availablePreconfigs = [];
    this.selectedPreconfig = null;
    this.preConfigsList = [];
    const that = this;
    paginationRequest = RestExt.firstPageRequest();
    paginationRequest.sortAsc = false;

    paginationRequest.filters = {
      firmware: [this.firmware + ""],
    };

    this.preconfigService.getPage(paginationRequest).then(function (response) {
      that.preConfigsList = response.entities;
      that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(
        response.entities
      );
    });
  }

  closeAllAccordionTabs() {
    if (!this.isNullOrUndefined(this.accordion.tabs)) {
      for (const tab of this.accordion.tabs) {
        if (tab.selected) {
          tab.selected = false;
        }
      }
    }
  }

  isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
  }

  public getWeTrack2DeviceParams(): any {
    return this.deviceParams;
  }

  fillPreConfig(newParams: Rest.DeviceParameters) {
    //to load the values of the preconfig events but not the id assigned

    //remove id because if is from preconfig we have to assign as new sensors to config

    //to load the values of the preconfig events but not the id assigned
    newParams.id = this.deviceParams.id;

    for (const a in newParams.sensors) {
      delete newParams.sensors[a]["id"];
    }

    for (const a in newParams.actuators) {
      delete newParams.actuators[a]["id"];
    }

    //Load the rest preconfig parameters to WeTrack2 config

    this.deviceParams.proprietaryParameters.type = "WeTrack2";
    this.deviceParams = newParams;

    for (const a in this.deviceParams.sensors) {
      this.deviceParams.sensors[a].parameters = null; //configTmp;
    }
    for (const a in this.deviceParams.actuators) {
      this.deviceParams.actuators[a].parameters = null; //configTmp;
    }
  }

  tabChanged() {}
}
