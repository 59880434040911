<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
	[rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
	styleClass="p-datatable-striped" appendTo="body">
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.cardLists.cardListsManagement' | translate }}
		</div>
	</ng-template>
	<!-- #endregion TABLE TITE DEFINITION -->

	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>
			<!-- SELECT -->
			<th class="checkbox-column">
				<div class="d-flex justify-content-center">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</div>
			</th>

			<!-- LOGO -->
			<th pSortableColumn="logo">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.brands.logo' | translate }}
					</div>
				</div>
			</th>

			<!-- NUMBER -->
			<th pSortableColumn="number">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.number' | translate }}
					</div>

					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="number"></p-sortIcon>
						<p-columnFilter field="number" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- TYPE -->
			<th pSortableColumn="type.name">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.vehicles.vehicleType' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="type.name"></p-sortIcon>
						<p-columnFilter field="type" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- CODE PRODUCT LIMIT  -->
			<th pSortableColumn="type.codeProductLimit">
				<div title="{{ 'tableManagement.cardLists.codeProductLimitShort' | translate }}"
					class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.codeProductLimitShort' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="type.codeProductLimit"></p-sortIcon>
						<p-columnFilter field="codeProductLimit" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

						<!-- OPERATION LIMIT  -->
			<th pSortableColumn="type.operationLimit">
				<div title="{{ 'tableManagement.cardLists.operationLimit' | translate }}"
					class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.operationLimitShort' | translate }}
					</div>
			
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="type.operationLimit"></p-sortIcon>
						<p-columnFilter field="operationLimit" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>


			<!-- VEHICLE -->
			<th *ngIf="!nested" pSortableColumn="vehicle.name">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.vehicle' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="vehicle.name"></p-sortIcon>
						<p-columnFilter field="vehicle" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- PRODUCT LIMIT  -->
			<th *ngIf="!nested" pSortableColumn="type.productLimit">
				<div title="{{ 'tableManagement.cardLists.productLimit' | translate }}"
					class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.productLimitShort' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="type.productLimit"></p-sortIcon>
						<p-columnFilter field="productLimit" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>



			<!-- EXPIRATION DATE -->
			<th pSortableColumn="expirationDate">
				<div title="{{ 'tableManagement.cardLists.expirationDate' | translate }}"
					class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.expirationDateShort' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="expirationDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ASSIGN DATE -->
			<th *ngIf="nested" pSortableColumn="assignDate">
				<div title="{{ 'tableManagement.cardLists.deassignDate' | translate }}"
					class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.assignDateShort' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="assignDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- DEASSIGN DATE -->
			<th pSortableColumn="deassignDate">
				<div title="{{ 'tableManagement.cardLists.deassignDate' | translate }}"
					class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.cardLists.deassignDateShort' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="deassignDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="disabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="disabled"></p-sortIcon>
						<p-columnFilter field="disabled" type="boolean" display="menu"
							[showApplyButton]="false"></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>
	<!-- #endregion TABLE COLUMN DEFINITION -->

	<!-- #region TABLE BODY -->
	<ng-template pTemplate="body" let-card>
		<tr class="p-selectable-row custom-rows" (dblclick)="editDialog(card)">
			<!-- SELECT -->
			<td>
				<div class="d-flex justify-content-center">
					<p-tableCheckbox [value]="card"></p-tableCheckbox>
				</div>
			</td>

			<!-- IMAGE -->
			<td>
				<div *ngIf="!card.type.image; else cardImage">
					<!-- <img #image src="{{'../../../../../../../../assets/images/UI_Elements/HD_transparent_picture.png'}}"
						class="custom-user-profile-image mx-auto d-block p-0" -->
					<img #image src="{{ '/assets/images/UI_Elements/no-image-available.svg' }}" class="img-responsive"
						[style]="{ 'max-height': '50px' }" />
				</div>
				<ng-template #cardImage>
					<div>
						<img #image src="{{ card.type.image.imageEncoded }}" class="img-responsive"
							[style]="{ 'max-height': '50px' }" />
					</div>
				</ng-template>
				<!-- <div>
					 	<img *ngIf='logoImageUrl' id='uploadImageIcon' class='ps-3' alt='' src='{{ logoImageUrl }}' class='img-responsive' [style]="{'max-height': '100px;', 'width':'50px', 'margin-right':'10px'}"/>
						<img *ngIf='selectedBrand?.logo?.imageEncoded' id='uploadedImage' class='ps-3' alt='' [src]='logoEncodedImage' class='img-responsive' [style]="{'max-height': '100px;', 'width':'50px', 'margin-right':'10px'}">
					</div> -->
			</td>

			<!-- NUMBER -->
			<td>
				{{ card.number }}
			</td>

			<!-- TYPE -->
			<td>
				{{ card.type?.name }}
			</td>

			<td>
				{{card.type?.codeProductLimit}}
			</td>

			<!-- OPERATION LIMIT -->
			<td>
				{{ card.type?.operationLimit }}
			</td>

			<!-- VEHICLE -->
			<td *ngIf="!nested">
				{{ card.vehicle?.name }}
			</td>
			<!-- PRODUCT LIMIT -->
			<td *ngIf="!nested">
				{{ card.type?.productLimit }}
			</td>


			<!-- EXPIRATION DATE -->
			<td>
				{{ card.expirationDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- ASSIGN DATE -->
			<td *ngIf="nested">
				{{ card.assignDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- DEASSIGN DATE -->
			<td>
				{{ card.deassignDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i class="pi" [ngClass]="{
							'true-icon pi-check-circle': !card.disabled,
							'false-icon pi-times-circle': card.disabled
						}"></i>
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE BODY -->

	<!-- #region TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="11" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE EMPTY MESSAGE -->

	<!-- #region TABLE FOOTER -->
	<ng-template pTemplate="footer">
		<tr *ngIf="nested">
			<td colspan="11" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<div class="d-flex">
						<!-- <form-input [required]='false' [isDisabled]="isEditingDisabled" fieldName="{{ 'tableManagement.cardLists.availableCards' | translate }}" [style]="{'margin-right':'50px'}"> -->
						<!-- <p-dropdown
									[options]='availableCardsList' [(ngModel)]='selectedAvailableCard'
									[placeholder]="availableCardListPlaceHolder"
									dataKey='id' optionLabel='number'
									[style]="{'width':'250px', 'margin-right':'10px'}"
									[disabled]='!availableCardsList || isEditingDisabled'
									appendTo='body'
									filter="true" filterBy="number">
								</p-dropdown>
								<button *ngIf="selectedAvailableCard" class="btn custom-expand-button" type="button" alt="Add Available Card" (click)="onAddAvailableCard()" [disabled]='isEditingDisabled' [style]="{'margin-right':'5px'}">
									<svg-icon
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/arrow-up.svg"
										[svgStyle]="{'height.px': 30, 'width.px': 30}"
									></svg-icon>
								</button>
								<button class="btn custom-expand-button" type="button" alt="Add Available Card" (click)="create()" [disabled]='isEditingDisabled' [style]="{'margin-right':'10px'}">
									<svg-icon
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/add.svg"
										[svgStyle]="{'height.px': 30, 'width.px': 30}"
									></svg-icon>
								</button> -->

						<app-table-add-button *ngIf="isCreateGranted" [text]="'tableManagement.cardLists.card' | translate"
							(btnClick)="create()"></app-table-add-button>

						<!-- CHANGE STATUS BUTTON -->
						<app-table-change-status-button (btnClick)="changeStatus()"
							[isDisabled]="selectedEntities.length === 0 || !isDisabledGranted"></app-table-change-status-button>
						<!-- </form-input> -->
					</div>
					<div class="d-flex flex-row" style="margin-left: auto">
						<!--  EXCEL BUTTON -->
						<app-table-excel-button [isDisabled]="isEditingDisabled" class="buttonTableExcel"
							(btnClick)="exportExcel()"></app-table-excel-button>
					</div>
				</div>
			</td>
		</tr>

		<tr *ngIf="!nested">
			<td colspan="11" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<div class="d-flex">
						<!-- ADD CARD BUTTON -->
						<app-table-add-button [text]="'tableManagement.cardLists.cards' | translate"
							(btnClick)="create()"></app-table-add-button>

						<!-- CHANGE STATUS BUTTON -->
						<app-table-change-status-button (btnClick)="changeStatus()"
							[isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>
					</div>

					<div class="d-flex flex-row" style="margin-left: auto">
						<!-- UPLOAD FILE FORM  -->
						<div class="buttonTableExcel custom-image-button"
							pTooltip="{{ 'tableManagement.cardLists.cardsRenewalProcess' | translate }}"
							tooltipPosition="top" (click)="showFileForm()">
							<svg-icon src="assets/icons/upload-file.svg"
								[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
						</div>
						<!--  EXCEL BUTTON -->
						<app-table-excel-button class="buttonTableExcel"
							(btnClick)="exportExcel()"></app-table-excel-button>
					</div>
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE FOOTER -->
</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
	<app-change-status-shared [items]="'tableManagement.cardLists.cards' | translate" [display]="true"
		[service]="cardService" [entitiesList]="selectedEntities" (return)="refreshList($event)"
		(close)="closeStatusDialog($event)"></app-change-status-shared>
</div>

<!-- CARD FORM -->
<app-card-form [vehicle]="vehicle" (return)="afterSave()"></app-card-form>

<!-- UPLOAD FILE FORM -->
<app-upload-file-form (refreshTable)="refresh()" [renewalType]="renewalType"
	[title]="fileFormTitle"></app-upload-file-form>
