import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {LazyLoadEvent} from "primeng/api";

import {Rest} from "../../../rest/rest_client";
import {ReportService} from "../../../rest/report.service";
import {AuthenticationService} from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { LoadingService } from "../../../services/loading-service/loading.service";
import { UserTimePipe } from "../../shared/UserTimePipe";
import { VehicleActivityReport } from "../vehicle-activity-report/vehicle-activity-report.component";
import { ReportFormComponent } from "../report-form/report-form.component";
import { ThrowStatement } from 'estree';
import { AppModule } from 'app/app.module';
import { ExcelService } from "app/services/excel-service/excel.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-report-list",
    templateUrl: "./report-list.component.html",
    styleUrls: ["./report-list.component.css"],
    providers: [ReportService, UserTimePipe, ExcelService],
})
export class ReportListComponent implements OnInit, OnDestroy {
    protected alive: boolean = true;
    paginationResult: Rest.ReportTableHospitalMataro[];
    startDate: Date;
    endDate: Date;
    headerColumns: String [];
    headerColumnsData: String [];
    height: any;
    constructor(
        protected service: ReportService,
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        private excelService: ExcelService,
        private translate: TranslateService
    ) {
        this.loadingService = AppModule.injector.get(LoadingService);
        //this.userTime = AppModule.injector.get(UserTimePipe);
        this.headerColumns = [];
        this.headerColumnsData = [];
    }
    ngOnDestroy() {
        this.alive = false;
    }

    ngOnInit() {
        this.height = window.innerHeight * 0.65 + "px";
        this.startDate = new Date();
        this.endDate = new Date();
        this.fetch();
    }

    search(){


        this.fetch();
    }

    fetch(){
        this.startDate.setHours(0, 0, 0,0);
        this.endDate.setHours(0,0,0,0);
        const that = this;
        let queryParams = {
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
        }

        this.service
        .getHospitalMataro(queryParams)
        .then(
            function (response) {            
                that.paginationResult = response.entities;
                var item = that.paginationResult[0];
                that.headerColumns = [];
                that.headerColumnsData = [];
                for(var i = 0;i<item.days.length; i++){
                    var data = item.days[i];
                    that.headerColumns.push(data.day);
                    
                
                    for(var j = 0;j<data.data.length;j++){
                        that.headerColumnsData.push(data.data[j].name);
                    }
                }
            }
        )
        .catch(function (reason) {
            
        });
    }

    exportExcel(){  
       let element = document.getElementById('table'); 
       this.excelService.exportTableAsExcelFile(element, "sample"); 
    }

    
    /*@ViewChild(ReportFormComponent, {static: true})
    reportForm: ReportFormComponent;
    @ViewChild(VehicleActivityReport, {static: true})
    vehicleActivityReport: VehicleActivityReport;

    protected alive: boolean = true;
    messages: any[];
    visible: boolean;
    errors = {};
    paginationRequest: Rest.ListPaginationRequest;
    paginationResult: Rest.Page<Rest.Report>;
    filters: { [index: string]: Rest.TableFilterSelectItem[] };
    loadFilters: boolean; //Flag indicating that associated entity filters must be loaded at next request
    hasEntityFilters = false; //Flag indicating that the entity list can be filtered by associated entities. If true, requires that the REST service used has the method getPageAndFilters

    /**Check box Column selection */
    /*entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
    selectedAll: boolean = false;
    disableSelectAll: boolean = true;
    /** */
    /*showDeleteDialog: boolean = false;

    loading: boolean = true; //Loading...

    isSelectableColumn: boolean = false;
    isColumn: boolean = false;

    //private loadingService: LoadingService;
    //protected userTime: UserTimePipe;
    constructor(
        protected service: ReportService,
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        //this.loadingService = AppModule.injector.get(LoadingService);
        //this.userTime = AppModule.injector.get(UserTimePipe);
    }

    selectableColumn() {
        this.isSelectableColumn = true;
    }

    initFilters() {
    }

    ngOnInit() {
        this.loadFilters = true;
        this.visible = true;
        this.messages = [];

        this.paginationRequest = RestExt.firstPageRequest();
        this.paginationResult = {
            filteredEntities: 0,
            entities: [],
        } as Rest.Page<Rest.Report>;
        this.filters = {};
    }

    ngOnDestroy() {
        this.alive = false;
    }

    refresh(): void {
        const that = this;
        this.fetch().then(() => {
        });
    }

    applyFilters(event: LazyLoadEvent) {
        this.paginationRequest.filters = {};
        this.paginationRequest.pageSize = event.rows;
        for (const key in event.filters) {
            //It is a multiselect filter, and thus, the value is an array.
            if (event.filters[key].value.constructor === Array) {
                this.paginationRequest.filters[key] = event.filters[key].value;
            } else {
                //It is a single selection filter (input text, date,etc...) we have to create an array with the only value
                this.paginationRequest.filters[key] = [event.filters[key].value];
            }
        }
        this.paginationRequest.sortBy = event.sortField;
        this.paginationRequest.sortAsc = event.sortOrder == 1;
        this.paginationRequest.offset = event.first;
    }

    loadData(event: LazyLoadEvent) {
        this.applyFilters(event);
        this.fetch();
    }

    arrayToString(array) {
        const nestedEntities = [];
        for (const a in array) {
            nestedEntities.push(array[a].name);
        }
        return nestedEntities.join(", ");
    }

    onRowDblClick(event) {
    }

    fetch() {
        //this.loading = true;
        this.loadingService.increaseRESTOngoingRequestCounter();
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            //Load the filters and page if requested and the entity can be filtered by other entities
            let t = this.paginationRequest;
            /**
             * Convert all the dates from the user defined time zone to UTC
             */
            /*if (this.paginationRequest != null) {
                for (var f in this.paginationRequest.filters) {
                    for (var idx in this.paginationRequest.filters[f]) {
                        if (
                            (this.paginationRequest.filters[f][idx] as any) instanceof Date
                        ) {
                            (this.paginationRequest.filters[f] as any)[idx] =
                                this.userTime.calendarDateToUTC(
                                    this.paginationRequest.filters[f][idx]
                                );
                        }
                    }
                }
            }
            if (this.loadFilters && this.hasEntityFilters) {
                //If paginationRequest is null create a new one
                if (this.paginationRequest == null) {
                    this.paginationRequest = RestExt.firstPageRequest();
                }

                this.service
                    .getPageAndFilters(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response.page;
                            that.filters = response.filters;
                            that.loadFilters = false;
                            /**Checkbox colum selection */
                            /*for (var a in response.page.entities) {
                                that.entitiesSelected[response.page.entities[a]["id"]] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            /*that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },

                        function (error) {
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        }
                    )
                    .catch(function (reason) {
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        resolve();
                    });
            } else {
                this.service
                    .getPage(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response;
                            /**Checkbox colum selection */
                            /*for (var a in response.entities) {
                                that.entitiesSelected[response.entities[a]["id"]] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            /*that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },
                        /* failure */

                        /*function (error) {
                            console.log("The request failed: " + error);
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        }
                    )
                    .catch(function (reason) {
                        console.log("Catched: " + reason);
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        resolve();
                    });
            }
        });
        return promise;
    }

    reportCreated(report) {
        this.notificationsService.add(
            Severity.success,
            this.i18n._(":)"),
            this.i18n._("Report request created. The result will be available soon")
        );
        this.refresh();
    }

    protected handleError(err, that) {
        that.errors = {};
        if (typeof err === "string") {
            try {
                err = JSON.parse(err);
                if ((err.class = "AtlantisMessage")) {
                    this.notificationsService.add(
                        err.type == "Warning" ? Severity.warn : Severity.error,
                        this.i18n._(":("),
                        err.message
                    );
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    showReport(reportId: number, vid: number) {
        this.service.getReportPart(reportId + "", vid + "").then((report) => {
            this.vehicleActivityReport.display(JSON.parse(report));
        });
    }

    addReport() {
        this.reportForm.create();
    }*/
}
