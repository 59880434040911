import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-vibration-alarm-configuration-concox-mt200',
  templateUrl: './vibration-alarm-configuration-concox-mt200.component.html',
  styleUrls: ['./vibration-alarm-configuration-concox-mt200.component.css'],
})
export class VibrationAlarmConfigurationConcoxMt200Component extends ServerValidatedFormComponent implements OnInit {
  @ViewChild('ContentDiv', { static: true }) ContentDiv;
  @Input() vibrationAlarm: Rest.VibrationAlarmConfigurationConcoxMT200

  constructor(protected i18n: I18nService) {  super(i18n);}

  AlarmOptions: any = [
    {label: "GPRS Only",value:"GPRS"},
    {label: "SMS+GPRS",value:"SMS_GPRS"},
    {label: "GPRS+SMS+phone call", value:"GPRS_SMS_PHONECALL"},
    {label: "GPRS+call", value:"GPRS_CALL"}      
  ];

  ngOnInit() {    
    if(this.vibrationAlarm!=null){this.disableDIV(!this.vibrationAlarm.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.vibrationAlarm);
    }
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}
