import { Injectable } from '@angular/core';
import { Rest } from 'app/rest/rest_client';
import { EntityService } from 'app/rest/entity.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { Subject } from 'rxjs';
import { VehicleService } from 'app/rest/vehicle.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
 })
export class InvVehicleService extends EntityService<Rest.Vehicle> {

	inventoryVehicleClient: Rest.RestApplicationClient;
	vehicleClient: Rest.RestApplicationClient;
	startedEditing = new Subject<Rest.Vehicle>();
	startedCreating = new Subject();
	endTranslationEditing = new Subject<Rest.Vehicle>();
	vehicleSaved = new Subject<Rest.Vehicle>();

	constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		public vehicleService: VehicleService
	) {
		super();
		this.inventoryVehicleClient = new Rest.RestApplicationClient(jquery);
		this.vehicleClient = new Rest.RestApplicationClient(jquery);
	}

	public initVehicle(): Rest.Vehicle {
		return {
			id: 0,
			billingAccount: null,
			owner: null,
			maintenancePlan: null,
			group: null,
			enabled: true,
			name: "",
			km: 0.1,
			maxSpeed: 0.1,
			lastManualKMUpdate: 0,
			metadata: null,
			model: null,
			dateOfManufacture: null,
			device: null,
			executions: null,
			registered: false,
			/* inventory fields */
			image: null,
			version: null,
			dateRegistered: null,
			frameNumber: null,
			disabledDate: null,
			gps: false,
			client: null,
			disabledUser: null,
			createDate: null,
			createUser: null,
			updateDate: null,
			updateUser: null,
			status: null,
			usage: null,
			observations: "",
			fuelCard: null,
			color: "",
			insurance: null,
			financing: null,
			/* vehicle Assignments fields */
			vehicleAssignments: null,
			driverAssignments: null,
			delegation: null,
			subDelegation: null,
			fleetManager: null,
			serviceManager: null,
			zoneLeader: null,
			delegationTask:null
		};
	}

   getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Vehicle>> {
		return this.inventoryVehicleClient.InvVehicle_getPage(arg0);
	}

	getFuelTypes(language: Rest.Language): Rest.RestResponse<Rest.InvFuelType[]> {
		let queryParams = {
			lang: language
		};
		return this.inventoryVehicleClient.Vehicle_getFuelTypes(queryParams);
	}

	getBrands(clientId: number): Rest.RestResponse<Rest.VehicleBrand[]> {
		return this.vehicleClient.VehicleBrand_getAll(clientId);
	}

	getModels(brandId: number): Rest.RestResponse<Rest.VehicleModel[]> {
		return this.vehicleClient.VehicleModel_getAll(brandId);
	}

	getModelsByClient(clientId: number): Rest.RestResponse<Rest.VehicleModel[]> {
		return this.vehicleClient.VehicleModel_getByClient(clientId);
	}

	getVersions(modelId: number): Rest.RestResponse<Rest.VehicleVersion[]> {
		return this.inventoryVehicleClient.VehicleVersion_getAll(modelId);
	}

	getVersionsByClient(clientId: number): Rest.RestResponse<Rest.VehicleVersion[]> {
		return this.inventoryVehicleClient.VehicleVersion_getByClient(clientId);
	}

	getVehicleTypes(): Rest.RestResponse<Rest.VehicleType[]> {
		return this.vehicleClient.VehicleType_getAll();
	}

	getBillingAccounts(clientId: number): Rest.RestResponse<Rest.BillingAccount[]> {
		return this.vehicleClient.BillingAccount_getAll(clientId);
	}

	getVehicleStatus(clientId: number): Rest.RestResponse<Rest.VehicleStatus[]> {
		return this.inventoryVehicleClient.VehicleStatus_getAll(clientId);
	}

	getVehicleAssignments(vehicleId: number, startDate: string, endDate: string): Rest.RestResponse<Rest.InvVehicleAssignment[]> {
		let queryParams = {
			vehicleId: vehicleId,
			startDate: startDate,
			endDate: endDate
		};
		return this.inventoryVehicleClient.InvVehicleAssignments_getHtc(queryParams);
	}

	getDriverAssignments(vehicleId: number, startDate: string, endDate: string): Rest.RestResponse<Rest.InvDriverAssignment[]> {
		let queryParams = {
			vehicleId: vehicleId,
			startDate: startDate,
			endDate: endDate
		};
		return this.inventoryVehicleClient.InvDriverAssignments_getHtc(queryParams);
	}

	getIvaTypes(): Rest.RestResponse<Rest.InvIvaType[]> {
		return this.inventoryVehicleClient.InvVehicleAssignments_getIvaTypes();
	}

	public createVehicle(arg0): Promise<Rest.Vehicle> {
		return this.vehicleClient.Vehicle_create(arg0);
	}

	public updateVehicle(arg0): Promise<Rest.Vehicle> {
		return this.vehicleClient.Vehicle_update(arg0);
	}

	public async changeStatusPromise(
		arg0: number[],
		queryParams?: { status?: boolean }
	): Promise<boolean> {
		await this.vehicleClient.Vehicle_changeStatusPromise(arg0, queryParams);
		return true;
	}

	public changeStatusCirsa(arg0:Rest.Vehicle, queryParams?:{status?: boolean}
		): Rest.RestResponse<boolean>{
		return this.vehicleClient.Vehicle_changeStatusInventory(arg0, queryParams);
		}

}
