import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Rest } from '../../../../../../rest/rest_client';
import { AuthenticationService } from '../../../../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../../../../services/rest-client-extension';
import { EntityListComponent } from '../../../../../../components/entity-list/entity-list.component';
import { FineService } from 'app/rest/fine.service';
import { FineFormComponent } from '../fine-form/fine-form.component';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { ExcelService } from "app/services/excel-service/excel.service";
import { TranslateService } from '@ngx-translate/core';
import { FineStatusService } from 'app/modules/vehicle-inventory/services/table-management/fineStatus.service';

@Component({
	selector: 'app-fine-list',
	templateUrl: './fine-list.component.html',
	styleUrls: ['./fine-list.component.css'],
	providers: [FineStatusService, ConfirmationService, UserTimePipe, ExcelService],
	// FineService, <- es declara a tableManagement perque no es perdi el Service quan venim d'altres formularis
})
export class FineListComponent extends EntityListComponent<Rest.Fine> implements OnInit, OnDestroy {
	@Input() vehicle?: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;
	@Input() nested?: Boolean = false;

	title: string = this.i18n._('Fine list');
	colspan: string = '';

	@ViewChild(FineFormComponent, { static: true })
	editForm: FineFormComponent;

	user: Rest.User;
	EnabledFilter: SelectItem[];

	refreshListSuscription: Subscription;

	fineStatusList: Rest.FineStatus[] = [];

	@Input() showTitle: boolean = true;

	// PERMISSIONS
	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isDisabledGranted: boolean = false;

	constructor(
		private objectService: FineService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected excelService: ExcelService,
		public fineStatusService: FineStatusService,
		public translate: TranslateService,
	) {
		super(
			objectService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime,
			excelService
		);

		this.objectService = objectService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.form = this.editForm;
		this.hasEntityFilters = false; //pq executi la funcio /page i no la /pageandfilters que no existeix a la API
		this.colspan = this.nested ? '9' : '10';
		this.getVehicleStatus();
		this.refreshListSuscription = this.objectService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				this.getList(item.startDate, item.endDate);
			}
		);

		this.setPermissions();
	}

	onDestroy() {
		this.refreshListSuscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['INV_FINES_READ', 'INV_FINES_UPDATE'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('INV_FINES_CREATE', user);
		this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_FINES_DISABLE', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	editDialog(event) {
		if (!this.isFormGranted) {
			return;
		}

		if (!this.isSelectableColumn) {
			this.onRowDblClick({ data: event });
		}

		this.isSelectableColumn = false;
		this.isColumn = true;
	}

	beforeLoad(): void {
		this.paginationRequest.sortBy = "sanctionDate";
		this.paginationRequest.sortAsc= false;
		if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
		if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
			this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
		}
		console.log(this.paginationRequest.filters);
	}

	afterLoad() {
		// console.log('[FINE-LIST] afterLoad: ');
		// console.log(this.paginationResult.entities);
		this.paginationResult.entities.forEach((ct: any) => {
			ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
			/*Permissions */
			ct._$changeStatus = this.hasPermission(
				this.permissionsEntityTypeSelector("changeStatus", "Division"),
				ct.id
			); //This is for the validation of entity-list (selectAll function)
			ct._$update = this.hasPermission(
				this.permissionsEntityTypeSelector("update", "Division"),
				ct.id
			);
			ct._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
			ct._$update = true;
			/** */
		});

		this.checkSelectAll();
	}

	getVehicleStatus() {
		var statusRequestContext = {
			sortBy: "name",
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: true
		} as Rest.ListPaginationRequest;

		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.fineStatusService.getPage(statusRequestContext)
				.then(
					function (response) {
						that.fineStatusList = response.entities;
						that.fineStatusList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getList(startDate: Date, endDate: Date) {
		// console.log("---- FINES GET LIST ------");
		if (startDate) this.startDate = startDate;
		if (endDate) this.endDate = endDate;
		this.beforeLoad();
		super.refresh();
	}

	excelColumns = {};

	exportExcel() {
		this.setExcelColumns();
		let fileNameTitle = this.translate.instant('tableManagement.fine.finesManagement');
		super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
	}

	setExcelColumns() {
		const observations = (entity: any) => entity.observations ?? ''
		const amount = (entity: any) => entity.amount ?? ''
		const fileNumber = (entity: any) => entity.fileNumber ?? ''
		const reason = (entity: any) => entity.reason ?? ''
		const issuer = (entity: any) => entity.issuer ?? ''
		const sanctionDate = (entity: any) => entity.sanctionDate ?? ''
		const status = (entity: any) => entity.finesStatus?.color ?? ''
		const disabled = (entity: any) => entity.disabled ?? ''

		this.excelColumns = {
			[this.translate.instant('general.observations')]: { bindField: observations },
			[this.translate.instant('tableManagement.fine.amount')]: { bindField: amount },
			[this.translate.instant('tableManagement.fine.fileNumber')]: { bindField: fileNumber },
			[this.translate.instant('tableManagement.fine.reason')]: { bindField: reason },
			[this.translate.instant('tableManagement.fine.issuer')]: { bindField: issuer },
			[this.translate.instant('tableManagement.fine.sanctionDate')]: { bindField: sanctionDate },
			[this.translate.instant('tableManagement.fine.status')]: { bindField: status },
			[this.translate.instant('general.disabled')]: { bindField: disabled },
		};
	}
}
