<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">LBS UPLOAD CONFIGURATION
          (LBSON)</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="lbsUpload.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Upload interval">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="lbsUpload.uploadInterval" [min]="10" [max]="3600"></p-spinner>
          <span class="ui-inputgroup-addon">10~3600</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Timeout time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="lbsUpload.timeOutTime" [min]="10" [max]="3600"></p-spinner>
          <span class="ui-inputgroup-addon">10~3600</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>