import { Rest } from "../rest/rest_client";
import { Injectable } from "@angular/core";

@Injectable()
export class EntityService<T> {
  [x: string]: any;

  create(arg0: T): Rest.RestResponse<T> {
    throw new Error("method not implemented");
  }
  delete(arg0: T[]): Rest.RestResponse<void> {
    throw new Error("method not implemented");
  }
  getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<T>> {
    throw new Error("method not implemented");
  }
  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<T>> {
    throw new Error("method not implemented");
  }
  update(arg0: T): Rest.RestResponse<T> {
    throw new Error("method not implemented");
  }

  find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<T> {
    throw new Error("method not implemented");
  }

  createWithGenericResponse(arg0: T): Rest.RestResponse<Rest.GenericResp<T>> {
    throw new Error("method not implemented");
  }
  
  updateWithGenericResponse(arg0: T): Rest.RestResponse<Rest.GenericResp<T>> {
    throw new Error("method not implemented");
  }
 
  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    throw new Error("method not implemented");
  }

  
}
