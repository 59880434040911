/* eslint-disable prefer-const */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { RestExt } from "app/services/rest-client-extension";
import { ConfirmationService } from "primeng/api";
import { PoicategoryService } from "../../../rest/poicategory.service";
import { RealtimeService } from "../../../rest/realtime.service";
import { Rest } from "../../../rest/rest_client";
import { ZonegroupService } from "../../../rest/zonegroup.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";
import { CustomMapComponent } from "../../shared/custom-map/custom-map.component";
import { PoCsCurrentStatusRow } from "../models/Rows";

@Component({
    selector: "app-ute-map",
    templateUrl: "./ute-map.component.html",
    styleUrls: ["./ute-map.component.css"],
    providers: [
        RealtimeService,
        ConfirmationService,
        PoicategoryService,
        ZonegroupService,
    ],
})
export class UTEMapComponent implements OnInit, OnChanges, OnDestroy {
    private alive = true;
    @ViewChild(CustomMapComponent, {static: true}) map: CustomMapComponent;
    @Output() PoCClicked = new EventEmitter<Rest.Zone>();
    @Output() eventVehicleClicked =
        new EventEmitter<RestExt.ExtendedVehicleDataMessage>();

    /**
     * Vehicles that must be centered
     */
    private centeredVehicles: number[] = [];

    @Input() pocs: PoCsCurrentStatusRow[] = [];
    pois = [];
    zones = [];
    mapOptions = {};

    constructor(
        private i18n: I18nService,
        private rt: RealtimeService,
        private notificationsService: NotificationsService,
        private rtDataService: RealTimeDataService,
        private auth: AuthenticationService,
        private poiCategoryService: PoicategoryService,
        private zoneGroupService: ZonegroupService
    ) {
        this.zones = [];
    }

    /*
     * Whenever the map is clicked, stop following the vehicles (if any)
     **/

    stopFollow() {
        if (this.centeredVehicles != null) {
            console.log("stopFollow");
        }
        this.centeredVehicles = null;
    }

    async ngOnInit() {
        const that = this;

        this.rtDataService.vehicleInfo
            .takeWhile(() => this.alive)
            .subscribe((vi) => {
                // this.map.addVehicle(vi);

                this.map.addVehicle(vi);
                this.centeredVehicles.push(vi.vehicleId);
                //If vehicles should be centered, center them
                if (this.centeredVehicles != null) {
                    this.map.centerMap(this.centeredVehicles);
                }
            });

        /**
         *  When zoom level changes:
         *  If the zoom is greater than a threshold and the zone labels are not being shown, show them.
         *  If the zoom is less than a threshold and the zone  labels are being shown, hide them.
         *
         *  Hidding or showing a zone label is made by seting the zone name to an empty string or to its value
         *
         */
        await this.map.loadMapPromise;

        /*this.map.eventMapClick.takeWhile(() => this.alive).subscribe((click)=>{

        });*/

        //Whenever visible vehicles change, remove non visible vehicles
        this.rtDataService.watchedVehicles
            .takeWhile(() => this.alive)
            .subscribe((wv) => {
                this.map.keepVehicles(wv.map((v) => v.id));
            });

        this.rtDataService.mapInbox
            .takeWhile(() => this.alive)
            .subscribe((message) => {
                switch (message.tag) {
                    case "focus":
                        //Update the centered vehicles var
                        this.centeredVehicles = message.payload;
                        //Center the map
                        this.map.centerMap(this.centeredVehicles);
                        break;
                    case "showRoute":
                        this.map.addVehicleRoute(
                            message.payload.vid,
                            message.payload.segments,
                            message.payload.focus
                        );
                        break;
                    case "hideRoute":
                        this.map.removeVehicleRoute(message.payload);
                        break;
                    default:
                        console.error("Unknown message tag: " + message.tag);
                }
            });
    }

    zoneClicked(zone: Rest.Zone) {
        if (this.pocs == null) {
            return;
        }
        let filtered = this.pocs.filter(
            (p) => p.controlPoint != null && p.controlPoint.id === zone.id
        );
        if (filtered.length === 0) {
            return;
        }
        this.PoCClicked.emit(filtered[0].controlPoint);
    }

    pocToZone() {
        let zonesAux = [];
        if (this.pocs == null) {
            return;
        }
        this.pocs.forEach((poc) => {
            if (!poc.controlPoint) {
                return;
            }

            let controlPointWithStyle = Object.assign({}, poc.controlPoint) as any;
            //Set the color of the zone to be the color of the row (if any)
            //color is red|blue|green + blink
            /*let colStr = "";
            try {
                colStr = poc.color ? poc.color.split(" ")[0] : "";
            } catch (ex) {
            }
            let colHex = "#ababab";
            if (colStr === "green") {
                colHex = "#00B050";
            }
            if (colStr === "black") {
                colHex = "#000000";
            }
            if (colStr === "yellow") {
                colHex = "#FFFF00";
            }
            if (colStr === "red") {
                colHex = "#FF0000";
            }
            if (colStr === "normal") {
                colHex = "#0000ff";
            }*/

            let colHex = poc.color ? this.rgbToHex(poc.color) : "#ababab";

            controlPointWithStyle.fillColor = colHex;
            controlPointWithStyle.outlineColor = colHex;
            controlPointWithStyle.strokeColor = "#000000";
            zonesAux.push(controlPointWithStyle);
        });
        this.zones = zonesAux;
    }

    async ngOnChanges(changes) {
        this.map.loadPOIS();

        if (changes["pocs"]) {
            this.pocToZone();
        }
    }

    refreshMap() {
        this.map.refresh();
    }

    centerOnBCN() {
        this.map.centerMapAtPoint(41.38879, 2.15899, 12);
    }

    centerOnPointOfControl(pocId) {
        this.map.centerMapAtZone([pocId]);
    }

    ngOnDestroy() {
        this.alive = false;
    }

    centerAllVehicles() {
        this.map.centerMap(this.centeredVehicles);
    }

    rgbToHex(rgb) {
        // Extract the R, G, and B values from the RGB string
        const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    
        if (!match) {
            throw new Error(`Invalid RGB format: ${rgb}`);
        }
    
        // Convert the extracted values to integers
        const r = parseInt(match[1]);
        const g = parseInt(match[2]);
        const b = parseInt(match[3]);
    
        // Ensure that the input values are within the valid range (0-255)
        const clamp = (value) => Math.min(255, Math.max(0, value));
    
        // Convert each component to its hexadecimal representation
        const toHex = (value) => {
            const hex = clamp(value).toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
    
        // Convert R, G, and B to hexadecimal
        const rHex = toHex(r);
        const gHex = toHex(g);
        const bHex = toHex(b);
    
        // Combine the hexadecimal values to form the color code
        const hexColor = `#${rHex}${gHex}${bHex}`;
    
        return hexColor;
    }
}
