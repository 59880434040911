import { Component, ViewEncapsulation, Input } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-acc-on-without-tag-event-configuration",
  templateUrl: "./acc-on-without-tag-event-configuration.component.html",
  styleUrls: ["./acc-on-without-tag-event-configuration.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class AccOnWithoutTagEventConfigurationComponent extends ServerValidatedFormComponent {
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() deviceParams: Rest.DeviceParameters;

  /**
   * Needed to prevent toggle of UI component
   * @param event
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
  }
}
