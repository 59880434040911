<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Mileage Configuration ($ST+MILEAGE)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">                
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Total Mileage" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="mileage.mileage" [min]="0" [max]="999999"></p-spinner>
              <span class="ui-inputgroup-addon">0~999999</span>
              <span class="ui-inputgroup-addon" >km</span>
            </div>
          </form-input>
        </div>        
      </div>
</p-accordionTab>
