<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Digital Input Port
          Settings (AT + GTDIS)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Digital Input
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="modeOptions" [(ngModel)]="disParameters.mode"
              [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Debounce Time">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" step="1" [(ngModel)]="disParameters.debounceTime" [min]="0" [max]="20"></p-spinner>
                <span class="ui-inputgroup-addon">0~20</span>
                <span class="ui-inputgroup-addon">ms (x10)</span>
              </div>
            </div>
          </div>
        
        </div>
      </p-accordionTab>


    </div>
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Additional
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="ui-g-12 ui-md-6">
          <p-checkbox binary="true" label="Input connected" [(ngModel)]="disParameters.connected">
          </p-checkbox>
        </div>


      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>