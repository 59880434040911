import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Rest } from 'app/rest/rest_client';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';

import { ServerValidatedFormComponent } from 'app/components/server-validated-form/server-validated-form.component';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-brand-form',
  templateUrl: './vehicle-brand-form.component.html',
  styleUrls: ['./vehicle-brand-form.component.css'],
  providers:[VehiclebrandService],
})
export class VehicleBrandFormComponent extends EntityFormComponent<Rest.VehicleBrand> implements OnInit {
  @Input() display: boolean;
  @Output() closeModal: EventEmitter<Boolean> = new EventEmitter<Boolean>();


  constructor(public vehiclebrandService: VehiclebrandService,
              public notificationsService: NotificationsService,
              public translate: TranslateService,
              public authenticationService: AuthenticationService,
              protected i18n: I18nService) {
    super(vehiclebrandService, notificationsService, i18n, authenticationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  onHide(event){
    this.closeModal.emit(false);
  }

  createBrand(){
    this.entity.id = 0;
    this.vehiclebrandService.createBrand(JSON.stringify(this.entity)).then((resp) =>{
      this.notificationsService.add(Severity.success, "", this.translate.instant('general.createSuccessfull'));
      this.closeModal.emit(false);
    }).catch((e)=>{
      this.notificationsService.add(Severity.error, "", this.translate.instant('general.createError'));
    });
  }
}
