import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-server-registration-information-gl320m',
  templateUrl: './server-registration-information-gl320m.component.html',
  styleUrls: ['./server-registration-information-gl320m.component.css']
})
export class ServerRegistrationInformationGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  reportOptions: any = [
   { label: "Stop mode", value: "STOP"},
   { label: "TCP short-connection preferred mode", value: "TCP_SHORT_CONNECTION_PREFERRED"},
   { label: "TCP short-connection forced mode", value: "TCP_SHORT_CONNECTION_FORCED"},
   { label: "TCP long-connection mode", value: "TCP_LONG_CONNECTION"},
   { label: "UDP mode", value: "UDP"},
   { label: "Force on SMS mode", value: "FORCED_SMS"},
   { label: "UDP with fixed local port mode", value: "UDP_FIXED_LOCAL_PORT"},
   { label: "Backup server supported TCP long-connection mode", value: "BACKUP_SERVER_CONNECTION_TCP_LONG_CONNECTION"},
  ]

  bufferOptions: any = [
   { label: "Disable", value: "DISABLE"},
   { label: "Low priority", value: "LOW_PRIORITY"},
   { label: "High priority", value: "HIGH_PRIORITY"},
  ]

  sackOptions: any = [
    { label: "Disable SACK", value: "DISABLE"},
    { label: "Receive SACK and check it", value: "RECIEVE_AND_CHECK"},
    { label: "Receive SACK but not check", value: "RECIEVE_ONLY"},
   ]
   
   multipacketOptions: any = [
    { label: "Disable", value: "DISABLE"},
    { label: "Enable", value: "ENABLE"},
   ]
   @Input() sriParameters: Rest.ServerRegistrationInformationGL320M;
   constructor(protected i18n: I18nService) { super(i18n); }
 
   ngOnInit() {
   }
 
 
 
   ngOnChanges(changes: SimpleChanges){
     if (changes["deviceParams"]) {
       console.log("arrive deviceParams", this.sriParameters);
     }
   }
 
 }