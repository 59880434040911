<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">ALARM OVER SPEED
          (SPEED)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="alarmOverSpeed.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Interval Time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="alarmOverSpeed.intervalTime" [min]="5" [max]="600"></p-spinner>
          <span class="ui-inputgroup-addon">5~600</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Speed Limit">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="alarmOverSpeed.speedLimit" [min]="1" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">1~255</span>
          <span class="ui-inputgroup-addon">km/h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Alarm Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="AlarmOptions" [(ngModel)]="alarmOverSpeed.modeAlarm" [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>
</p-accordionTab>