<!-- NEW DIALOG -->
<p-dialog
	*ngIf="isDialogVisible && !severeError"
	header="{{ vehicleFormTitle | uppercase }}"
	[(visible)]="isDialogVisible"
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	[closeOnEscape]="false"
	(visibleChange)="onVisibleChange()"
	[style]="{ width: '80vw' }"
>
	<!-- FORM ERRORS -->
	<div #errorContainer>
		<div *ngIf="errorMessages.length !== 0">
			<div *ngFor="let error of errorMessages">
				<div class="alert alert-warning" role="alert">{{ error }}</div>
			</div>
		</div>
	</div>

	<!-- PANELL DADES DE VEHICLE -->
	<div class="d-flex flex-column gap-3 p-3">
		<p-panel header="{{ 'tableManagement.vehicle.vehicleData' | translate }}" class="w-100">
			<!-- SECCION 1: vehiculo -->
			<div class="d-flex row-cols-1 w-100">
				<!-- MARCA -->
				<div class="d-flex align-items-center">
					<!-- matricula -->
					<form-input
						[required]="true"
						fieldName="{{ 'control-panel.vehicles.licensePlate' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>
						<span class="p-input-icon-right">
							<i
								*ngIf="!isNew"
								[attr.class]="nameLocked ? 'pi pi-lock' : 'pi pi-unlock'"
								(click)="onChangeNameLockStatus()"
							></i>
							<input
								pInputText
								[(ngModel)]="selectedVehicle.name"
								[readonly]="nameLocked"
								[style]="nameLocked ? { width: '150px', backgroundColor: '#F5F5F5' } : { width: '150px' }"
							/>
						</span>
					</form-input>
					<!-- logo -->
					<img
						*ngIf="logoImageUrl"
						id="uploadImageIcon"
						class="ps-3"
						alt=""
						src="{{ logoImageUrl }}"
						class="img-responsive"
						[style]="{ 'max-height': '100px;', width: '50px', 'margin-right': '10px' }"
					/>
					<img
						*ngIf="selectedBrand?.logo?.imageEncoded"
						id="uploadedImage"
						class="ps-3"
						alt=""
						[src]="logoEncodedImage"
						class="img-responsive"
						[style]="{ 'max-height': '100px;', width: '50px', 'margin-right': '10px' }"
					/>
					<!-- marca -->
					<p-dropdown
						[options]="brandList"
						[(ngModel)]="selectedBrand"
						placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.brands.brand' | translate }}"
						dataKey="id"
						optionLabel="name"
						[style]="{ width: '250px', 'margin-right': '10px' }"
						[disabled]="!isBrandLoaded || isEditingDisabled"
						appendTo="body"
						(onChange)="onChangeBrand($event)"
						filter="true"
						filterBy="name"
					>
					</p-dropdown>

					<button class="btn custom-expand-button" type="button" alt="Add Brand" (click)="addBrand()">
						<svg-icon
							[disabled]="isEditingDisabled"
							class="d-flex m-0 p-0 custom-expand-button-icon"
							src="assets/icons/add.svg"
							[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
						></svg-icon>
					</button>

					<div class="p-3"></div>

					<!-- model -->
					<p-dropdown
						[options]="modelList"
						[(ngModel)]="selectedModel"
						placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.models.model' | translate }}"
						dataKey="id"
						optionLabel="name"
						[style]="{ width: '250px', 'margin-right': '10px' }"
						[disabled]="!isModelLoaded || isEditingDisabled"
						appendTo="body"
						(onChange)="onChangeModel($event)"
						filter="true"
						filterBy="name"
					>
					</p-dropdown>

					<button class="btn custom-expand-button" type="button" alt="Add Model" (click)="addModel()">
						<svg-icon
							[disabled]="isEditingDisabled"
							class="d-flex m-0 p-0 custom-expand-button-icon"
							src="assets/icons/add.svg"
							[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
						></svg-icon>
					</button>

					<div class="p-3"></div>

					<!-- VERSION -->
					<p-dropdown
						[options]="versionList"
						[(ngModel)]="selectedVersion"
						placeholder="{{ 'general.select' | translate }} {{ 'tableManagement.versions.version' | translate }}"
						dataKey="id"
						optionLabel="name"
						[style]="{ width: '250px', 'margin-right': '10px' }"
						[disabled]="!isVersionLoaded || isEditingDisabled"
						appendTo="body"
						(onChange)="onChangeVersion($event)"
						filter="true"
						filterBy="name"
					>
					</p-dropdown>

					<button class="btn custom-expand-button" type="button" alt="Add Model Version" (click)="addVersion()">
						<svg-icon
							[disabled]="isEditingDisabled"
							class="d-flex m-0 p-0 custom-expand-button-icon"
							src="assets/icons/add.svg"
							[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
						></svg-icon>
					</button>

					<div class="p-3"></div>

					<mat-spinner *ngIf="brandSpinner" [diameter]="30" strokeWidth="5" [color]="primary"> </mat-spinner>
				</div>
			</div>

			<hr />
			<!----------------------------------------------------------------------------------------------->

			<!-- SECCION 2: foto y datos del vehiculo -->
			<div class="d-flex row-cols-2 w-100">
				<div class="d-flex ps-3 align-items-center">
					<!-- Datos del vehiculo -->
					<div class="d-flex flex-column mb-3">
						<!-- FOTO -->
						<div class="p-2 align-items-center">
							<button id="uploadImageButton" (click)="uploadImageInput.click()">
								<img
									*ngIf="vehicleImageUrl"
									id="uploadImageIcon"
									class="ps-3"
									alt=""
									src="{{ vehicleImageUrl }}"
									class="img-responsive"
									[style]="{ 'max-height': '200px;', width: '200px', 'margin-right': '10px' }"
								/>
								<img
									*ngIf="selectedVehicle?.image?.imageEncoded"
									id="uploadedImage"
									class="ps-3"
									alt=""
									[src]="vehicleEncodedImage"
									class="img-responsive"
									[style]="{ 'max-height': '200px;', width: '200px', 'margin-right': '10px' }"
								/>
							</button>
							<input
								#uploadImageInput
								[hidden]="true"
								[disabled]="isEditingDisabled"
								type="file"
								accept=".jpg, .png"
								(change)="onImageChange($event)"
							/>
						</div>
						<!-- fleet -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="true"
								[align]="'cols-2'"
								fieldName="{{ 'real-time.vehiclePanel.settings.fleetName' | translate }}"
							>
								<p-dropdown
									[options]="fleetList"
									[(ngModel)]="selectedFleet"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '250px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									(onChange)="onChangeFleet($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
							</form-input>
						</div>
						<!-- vehicleGroup -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="true"
								[align]="'cols-2'"
								fieldName="{{ 'real-time.vehiclePanel.settings.vehicleGroup' | translate }}"
							>
								<p-dropdown
									[options]="vehicleGroupList"
									[(ngModel)]="selectedVehicle.group"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '250px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									(onChange)="onChangeVehicleGroup($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
							</form-input>
						</div>
						<!-- FECHA ALTA -->
						<div class="p-2 align-items-center">
							<form-input
								[align]="'cols-2'"
								fieldName="Fecha alta"
							>
								<p-calendar
									appendTo="body"
									[(ngModel)]="selectedVehicle.dateRegistered"
									dateFormat="dd-mm-yy"
									showIcon="true"
									[style]="{ width: '180px', 'margin-right': '20px' }"
									[firstDayOfWeek]="1"
									[disabled]="false"
								></p-calendar>
								
							</form-input>
						</div>
					</div>
				</div>
				<!-- INFO VEHICLE -->
				<div class="d-flex ps-3 align-items-center">
					<!-- Datos del vehiculo -->
					<div class="d-flex flex-column mb-3">
						<!-- disabled -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.vehicle.disabled' | translate }}"
							>
								<p-calendar
									appendTo="body"
									[(ngModel)]="selectedVehicle.disabledDate"
									dateFormat="dd-mm-yy"
									showIcon="true"
									[style]="{ width: '180px', 'margin-right': '20px' }"
									[disabled]="isEditingDisabled"
									[firstDayOfWeek]="1"
									[disabled]="selectedVehicle.enabled"
								></p-calendar>
								<p-inputSwitch
									#toggle
									[(ngModel)]="!selectedVehicle.enabled"
									(onChange)="toggleDisabled($event)"
									class="p-1"
									[disabled]="isEditingDisabled"
								>
								</p-inputSwitch>
							</form-input>
						</div>

						<!-- km -->
						<div *ngIf="!selectedVehicle.enabled" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.cardsConsumption.km' | translate }}"
							>
								<p-inputNumber
									[(ngModel)]="selectedVehicle.km"
									mode="decimal"
									locale="es-ES"
									(onKeyDown)="onKeyDownDecimal($event)"
									[style]="{ width: '250px' }"
									[disabled]="isEditingDisabled"
								></p-inputNumber>
							</form-input>
						</div>

						<!-- Color -->
						<div class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.vehicleStatus.table.color' | translate }}"
							>
								<input
									pInputText
									[(ngModel)]="selectedVehicle.color"
									[disabled]="isEditingDisabled"
									[style]="{ width: '250px' }"
								/>
							</form-input>
						</div>

						<!-- Bastidor -->
						<div class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'control-panel.vehicles.frameNumber' | translate }}"
							>
								<input
									pInputText
									[(ngModel)]="selectedVehicle.frameNumber"
									[disabled]="isEditingDisabled"
									[style]="{ width: '250px' }"
								/>
							</form-input>
						</div>

						<!-- Status -->
						<div *ngIf="isVehicleStateGranted" class="p-2 align-items-center">
							<form-input
								[required]="true"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.vehicleStatus.vehicleStatus' | translate }}"
							>
								<p-dropdown
									[options]="statusList"
									[(ngModel)]="selectedVehicleStatus"
									[placeholder]="statusPlacehodler"
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '250px', 'margin-right': '5px' }"
									appendTo="body"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
							</form-input>
						</div>

						<!-- VehicleUsage -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="true"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.vehicleUsage.vehicleUsage' | translate }}"
							>
								<p-dropdown
									[options]="vehicleUsageList"
									[(ngModel)]="selectedVehicle.usage"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '250px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									(onChange)="onChangeVehicleUsage($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
							</form-input>
						</div>

						<!-- Observacions -->
						<div *ngIf="isObservationsGranted" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'general.observations' | translate }}"
							>
								<textarea
									pInputTextarea
									id="observations"
									[(ngModel)]="selectedVehicle.observations"
									[rows]="2"
									[style]="{ width: '450px' }"
									[disabled]="isEditingDisabled"
								>
								</textarea>
							</form-input>
						</div>
					</div>
				</div>
			</div>
		</p-panel>
	</div>

	<!-- PANELL ASSIGNMENT -->
	<div class="d-flex flex-column gap-3 p-3">
		<p-panel header="{{ 'vehicleAssignments.header' | translate }} (*)" class="w-100">
			<div class="d-flex row-cols-2 w-100">
				<!-- PARTE IZQ: ASSIGNMENT -->
				<div class="d-flex">
					<div *ngIf="assignmentSpinner" class="d-flex flex-column mb-3">
						<mat-spinner [diameter]="30" strokeWidth="5" [color]="primary"> </mat-spinner>
					</div>
					<div *ngIf="!assignmentSpinner && !showAssignmentForm" class="d-flex flex-column mb-3">
						<div class="p-2 align-items-center">
							{{ 'tableManagement.noAssignment' | translate }}
						</div>
						<div class="p-2 align-items-center">
							<app-table-add-button
								[disabled]="isEditingDisabled"
								[text]="'vehicleAssignments.assignment' | translate"
								(btnClick)="onAddAssignment()"
							></app-table-add-button>
						</div>
					</div>
					<div *ngIf="!assignmentSpinner && showAssignmentForm" class="d-flex flex-column mb-3">
						<!-- BillingAccount -->
						<div class="p-2">
							<form-input
								[required]="true"
								[align]="'cols-3'"
								fieldName="{{ 'tableManagement.companies.company' | translate }}"
							>
								<!-- <input pInputText [readonly]="true" [(ngModel)]='vehicleAssignment.company.code'	[style]="{'width':'100px', 'margin-right':'5px'}"/>
								<input pInputText [readonly]="true" [(ngModel)]='vehicleAssignment.company.name'	[style]="{'width':'250px'}"/> -->
								<p-dropdown
									[options]="billingAccountList"
									[(ngModel)]="selectedBillingAccount"
									placeholder="Select..."
									dataKey="id"
									optionLabel="code"
									[style]="{ width: '100px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="code"
									(onChange)="onChangeBillingAccount($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
								<p-dropdown
									[options]="billingAccountList"
									[(ngModel)]="selectedBillingAccount"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '220px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									(onChange)="onChangeBillingAccount($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
								<div *ngIf="!billingAccountsMatch" class="d-flex align-items-center">
									<i
										class="pi pi-exclamation-triangle p-2 ms-2"
										style="font-size: 1.5rem; color: var(--primaryRed);"
										pTooltip="{{ 'drivers.form.driversAndVehiclesCodesNotMatching' | translate }}"
										tooltipPosition="top">
									</i>
									<p-inputSwitch
										[(ngModel)]="showMatchingWarning"
										(onChange)="toggleWarningVisibility()"
										class="p-1 ms-2"></p-inputSwitch>
								</div>
							</form-input>
						</div>
						<!-- Delegation -->
						<div class="p-2">
							<form-input
								[required]="true"
								[align]="'cols-3'"
								fieldName="{{ 'tableManagement.delegations.delegation' | translate }}"
							>
								<p-dropdown
									[options]="delegationList"
									[(ngModel)]="selectedDelegation"
									placeholder="Select..."
									dataKey="id"
									optionLabel="code"
									[style]="{ width: '100px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="code"
									(onChange)="onChangeDelegation($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
								<p-dropdown
									[options]="delegationList"
									[(ngModel)]="selectedDelegation"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '220px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									(onChange)="onChangeDelegation($event)"
									[disabled]="isEditingDisabled"
								>
								</p-dropdown>
								<button
									*ngIf="selectedDelegation !== null && delegationList !== null"
									class="btn custom-expand-button"
									type="button"
									alt="Remove"
									(click)="onRemoveDelegation()"
								>
									<svg-icon
										[disabled]="isEditingDisabled"
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/remove.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- Sub-Delegation -->
						<div class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-3'"
								fieldName="{{ 'tableManagement.subdelegations.subdelegation' | translate }}"
							>
								<p-dropdown
									[options]="subdelegationList"
									[(ngModel)]="selectedSubdelegation"
									placeholder="Select..."
									dataKey="id"
									optionLabel="code"
									[style]="{ width: '100px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="code"
									[disabled]="!isSubdelegationListLoaded || isEditingDisabled"
									(onChange)="onChangeSubDelegation($event)"
								>
								</p-dropdown>
								<p-dropdown
									[options]="subdelegationList"
									[(ngModel)]="selectedSubdelegation"
									placeholder="Select..."
									dataKey="id"
									optionLabel="name"
									[style]="{ width: '220px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="name"
									[disabled]="!isSubdelegationListLoaded || isEditingDisabled"
									(onChange)="onChangeSubDelegation($event)"
								>
								</p-dropdown>
								<button
									*ngIf="selectedSubdelegation !== null && subdelegationList !== null"
									class="btn custom-expand-button"
									type="button"
									alt="Remove"
									(click)="onRemoveSubDelegation()"
								>
									<svg-icon
										[disabled]="isEditingDisabled"
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/remove.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- Ceco -->
						<div class="p-2">
							<form-input
								[required]="true"
								[align]="'cols-3'"
								fieldName="{{ 'drivers.table.ceco' | translate }}"
							>
								<p-dropdown
									[options]="cecoList"
									[(ngModel)]="selectedCeco"
									placeholder="Select..."
									dataKey="id"
									optionLabel="code"
									[style]="{ width: '130px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="code"
									[disabled]="!isCecoListLoaded || isEditingDisabled"
									(onChange)="onChangeCeco($event)"
								>
								</p-dropdown>
								<p-dropdown
									[options]="cecoList"
									[(ngModel)]="selectedCeco"
									placeholder="Select..."
									dataKey="id"
									optionLabel="description"
									[style]="{ width: '190px', 'margin-right': '5px' }"
									appendTo="body"
									filter="true"
									filterBy="description"
									[disabled]="!isCecoListLoaded || isEditingDisabled"
									(onChange)="onChangeCeco($event)"
								>
								</p-dropdown>
								<button
									*ngIf="selectedCeco !== null && cecoList !== null"
									class="btn custom-expand-button"
									type="button"
									alt="Remove"
									(click)="onRemoveCeco()"
								>
									<svg-icon
										[disabled]="isEditingDisabled"
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/remove.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- IVA -->
						<div class="p-2">
							<form-input [required]="true" [align]="'cols-3'" fieldName="IVA">
								<!-- <input pInputText [readonly]="false" [(ngModel)]='vehicleAssignment.iva' [style]="{'width':'150px'}"/> -->
								<p-dropdown
									[options]="ivaTypesList"
									[(ngModel)]="selectedIva"
									placeholder="Select..."
									[style]="{ width: '130px', 'margin-right': '5px' }"
									appendTo="body"
									[disabled]="isEditingDisabled"
								>
									<ng-template let-iva pTemplate="selectedItem">
										{{ iva.replace('P', '') }}
									</ng-template>
									<ng-template let-iva pTemplate="item">
										{{ iva.replace('P', '') }}
									</ng-template>
								</p-dropdown>
								<button
									*ngIf="selectedIva !== null && ivaTypesList !== null"
									class="btn custom-expand-button"
									type="button"
									alt="Remove"
									(click)="onRemoveIva()"
								>
									<svg-icon
										[disabled]="isEditingDisabled"
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/remove.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>
							</form-input>
						</div>
					</div>
				</div>

				<!-- PARTE DER: leaders -->
				<div *ngIf="!assignmentSpinner && showAssignmentForm" class="d-flex ps-3">
					<div class="d-flex flex-column mb-3">
						<!-- Responsable flota -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.delegations.fleetManager' | translate }}"
							>
								<img
									*ngIf="fleetManagerImageUrl"
									[disabled]="isEditingDisabled"
									id="fleetManagerImageIcon"
									class="ps-3"
									alt=""
									src="{{ fleetManagerImageUrl }}"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<img
									*ngIf="fleetManager !== null && fleetManager.profileImage?.imageEncoded"
									[disabled]="isEditingDisabled"
									id="fleetManagerImageUrl"
									class="ps-3"
									alt=""
									[src]="fleetManagerImage"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<p-dropdown
									[options]="fleetManagerList"
									[(ngModel)]="fleetManager"
									[placeholder]="fleetManagerPlaceHolder"
									dataKey="id"
									optionLabel="formatResponsibleLabel"
									[style]="{ width: '250px'}"
									appendTo="body"
									[filter]="true"
									filterBy="formatResponsibleLabel"
									[virtualScroll]="true"
									[virtualScrollItemSize]="50"
									[disabled]="isEditingDisabled"
									(onChange)="onCustomFleetManager($event)"
								>
								</p-dropdown>
								<button
									type="button"
									class="btn custom-expand-button"
									(click)="onCustomUndo('fleetManager')"
									[disabled]="isEditingDisabled"
								>
									<svg-icon
										*ngIf="customFleetManager"
										[ngClass]="{ rotated: !expanded }"
										pTooltip="{{ 'general.customIcon' | translate }}"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/custom.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30, 'fill-opacity': 0.4 }"
										[disabled]="true"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- Responsable serveis -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.delegations.serviceManager' | translate }}"
							>
								<img
									*ngIf="serviceManagerImageUrl"
									[disabled]="isEditingDisabled"
									id="serviceManagerImageIcon"
									class="ps-3"
									alt=""
									src="{{ serviceManagerImageUrl }}"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<img
									*ngIf="serviceManager !== null && serviceManager.profileImage?.imageEncoded"
									[disabled]="isEditingDisabled"
									id="serviceManagerImageUrl"
									class="ps-3"
									alt=""
									[src]="serviceManagerImage"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<p-dropdown
									[options]="serviceManagerList"
									[(ngModel)]="serviceManager"
									[style]="{ width: '250px'}"
									[placeholder]="serviceManagerPlaceHolder"
									[filter]="true"
									appendTo="body"
									[virtualScroll]="true"
									[virtualScrollItemSize]="50"
									dataKey="id"
									optionLabel="formatResponsibleLabel"
									filterBy="formatResponsibleLabel"
									[disabled]="isEditingDisabled"
									(onChange)="onCustomServiceManager($event)"
								>
								</p-dropdown>
								<button
									type="button"
									class="btn custom-expand-button"
									(click)="onCustomUndo('serviceManager')"
									[disabled]="isEditingDisabled"
								>
									<svg-icon
										*ngIf="customServiceManager"
										[ngClass]="{ rotated: !expanded }"
										pTooltip="{{ 'general.customIcon' | translate }}"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/custom.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30, 'fill-opacity': 0.4 }"
										[disabled]="true"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- Lider de zona -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'tableManagement.delegations.zoneChief' | translate }}"
							>
								<img
									*ngIf="zoneLeaderImageUrl"
									[disabled]="isEditingDisabled"
									id="zoneLeaderImageIcon"
									class="ps-3"
									alt=""
									src="{{ zoneLeaderImageUrl }}"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<img
									*ngIf="zoneLeader !== null && zoneLeader.profileImage?.imageEncoded"
									[disabled]="isEditingDisabled"
									id="zoneLeaderImageUrl"
									class="ps-3"
									alt=""
									[src]="zoneLeaderImage"
									class="img-responsive"
									[style]="{ 'max-height': '50px;', width: '50px', 'margin-right': '10px' }"
								/>
								<p-dropdown
									[options]="zoneLeaderList"
									[(ngModel)]="zoneLeader"
									[style]="{ width: '250px'}"
									[placeholder]="zoneLeaderPlaceHolder"
									[filter]="true"
									appendTo="body"
									[virtualScroll]="true"
									[virtualScrollItemSize]="50"
									dataKey="id"
									optionLabel="formatResponsibleLabel"
									filterBy="formatResponsibleLabel"
									[disabled]="isEditingDisabled"
									(onChange)="onCustomZoneLeader($event)"
								>
								</p-dropdown>
								<button
									type="button"
									class="btn custom-expand-button"
									(click)="onCustomUndo('zoneLeader')"
									[disabled]="isEditingDisabled"
								>
									<svg-icon
										*ngIf="customZoneLeader"
										[ngClass]="{ rotated: !expanded }"
										pTooltip="{{ 'general.customIcon' | translate }}"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/custom.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30, 'fill-opacity': 0.4 }"
										[disabled]="true"
									></svg-icon>
								</button>
							</form-input>
						</div>
						<!-- Data d'assignació de vehicle -->
						<div class="p-2 align-items-center">
							<form-input
								[required]="false"
								[leftAlign]="true"
								fieldName="{{ 'documents.table.date' | translate }}"
							>
								<p-calendar
									appendTo="body"
									[(ngModel)]="dateVehicleAssignment"
									dateFormat="{{ i18n.dateFormat }}"
									[minDate]="minDateVehicleAssignment"
									showIcon="true"
									[style]="{ width: '250px' }"
									[disabled]="dateVehicleAssignmentDisabled || isEditingDisabled"
								>
								</p-calendar>
							</form-input>
						</div>
					</div>
				</div>
			</div>
		</p-panel>
	</div>

	<!-- PANELL CONDUCTOR + ASSIGNMENT -->
	<div class="d-flex flex-column gap-3 p-3">
		<p-panel header="{{ 'drivers.header' | translate }}" class="w-100">
			<div class="d-flex row-cols-2 w-100">
				<!-- PARTE IZQ: DRIVER -->
				<div class="d-flex">
					<div *ngIf="driverSpinner" class="d-flex flex-column mb-3">
						<mat-spinner [diameter]="30" strokeWidth="5" [color]="primary"> </mat-spinner>
					</div>
					<div *ngIf="!driverSpinner && !showDriverForm" class="d-flex flex-column mb-3">
						<div class="p-2">
							{{ 'tableManagement.noDriver' | translate }}
						</div>
						<div class="p-2 align-items-center">
							<app-table-add-button
								[disabled]="isEditingDisabled"
								[text]="'drivers.header' | translate"
								(btnClick)="onAddDriver()"
							></app-table-add-button>
						</div>
					</div>
					<div *ngIf="!driverSpinner && showDriverForm" class="d-flex flex-column mb-3">
						<!-- Driver -->
						<div class="p-2">
							<form-input [required]="false" [align]="'cols-2'" fieldName="{{ 'drivers.header' | translate }}">
								<input
									pInputText
									[disabled]="isEditingDisabled"
									[readonly]="true"
									[ngModel]="selectedDriver?.fullName"
									(ngModelChange)="selectedDriver.fullName = $event"
								/>
								<button *ngIf="isChangeDriverGranted"
									class="btn custom-expand-button"
									type="button"
									alt="select"
									(click)="onSelectDriver()"
									[disabled]="isEditingDisabled"
								>
									<svg-icon
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/arrow_select.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>
								<button
									*ngIf="selectedDriver !== null && isChangeDriverGranted"
									class="btn custom-expand-button"
									type="button"
									alt="Remove"
									(click)="onRemoveDriver()"
									[disabled]="isEditingDisabled"
								>
									<svg-icon
										[ngClass]="{ rotated: !expanded }"
										class="d-flex m-0 p-0 custom-expand-button-icon"
										src="assets/icons/remove.svg"
										[svgStyle]="{ 'height.px': 30, 'width.px': 30 }"
									></svg-icon>
								</button>

								<div *ngIf="!billingAccountsMatch">
									<i
										class="pi pi-exclamation-triangle p-2 ms-2"
										style="font-size: 1.5rem; color: var(--primaryRed)"
										pTooltip="{{ 'drivers.form.driversAndVehiclesCodesNotMatching' | translate }}"
										tooltipPosition="top"
									></i>
								</div>
							</form-input>
						</div>
						<!-- Empresa -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.table.company' | translate }}"
								[style]="{ width: '250px' }"
							>
								{{ selectedDriver.subdivision?.division?.billingAccount?.code }} -
								{{ selectedDriver.subdivision?.division?.billingAccount?.name }}
							</form-input>
						</div>
						<!-- Division -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.table.division' | translate }}"
								[style]="{ width: '250px' }"
							>
								{{ selectedDriver.subdivision?.division?.code }} -
								{{ selectedDriver.subdivision?.division?.name }}
							</form-input>
						</div>
						<!-- Sub-Division -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.table.subdivision' | translate }}"
								[style]="{ width: '250px' }"
							>
								{{ selectedDriver.subdivision?.code }} -
								{{ selectedDriver.subdivision?.name }}
							</form-input>
						</div>
						<!-- Ceco -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.table.ceco' | translate }}"
								[style]="{ width: '250px' }"
							>
								{{ selectedDriver !== null ? selectedDriver.ceco?.code : '' }} -
								{{ selectedDriver !== null ? selectedDriver.ceco?.description : '' }}
							</form-input>
						</div>
					</div>
				</div>
				<!-- PARTE DER: date & kms -->
				<div *ngIf="!assignmentSpinner && showAssignmentForm" class="d-flex ps-3">
					<div class="d-flex flex-column mb-3">
						<!-- Data d'assignació -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.assignmentDate' | translate }}"
								[style]="{ width: '250px' }"
							>
								<p-calendar
									appendTo="body"
									[minDate]="minDateDriverAssignment"
									[(ngModel)]="dateDriverAssignment"
									dateFormat="yy-mm-dd"
									showIcon="true"
									[style]="{ width: '250px' }"
									[disabled]="dateDriverAssignmentDisabled || isEditingDisabled"
									[firstDayOfWeek]="1"
								>
								</p-calendar>
							</form-input>
						</div>
						<!-- km -->
						<div *ngIf="selectedDriver !== null" class="p-2">
							<form-input
								[required]="false"
								[align]="'cols-2'"
								fieldName="{{ 'drivers.assignKms' | translate }}"
							>
								<p-inputNumber
									[(ngModel)]="selectedVehicle.km"
									mode="decimal"
									locale="es-ES"
									(onKeyDown)="onKeyDownDecimal($event)"
									[style]="{ width: '250px' }"
									[disabled]="dateDriverAssignmentDisabled || isEditingDisabled"
								></p-inputNumber>
							</form-input>
						</div>
					</div>
				</div>
			</div>
		</p-panel>
	</div>

	<!-- Pestanyes: Manteniments / ALERTES (boto per desactivar-les totes?) / DOCUMENTACIO / NOTES-->
	<div *ngIf="showTabs" class="d-flex flex-column gap-3 p-3">
		<p-tabView>
			<!-- CARDS -->
			<p-tabPanel header="{{ 'tableManagement.cardLists.cards' | translate }}">
				<p>
					<app-card-lists
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
						[showTitle]="false"
					>
					</app-card-lists>
				</p>
			</p-tabPanel>

			<!-- FINES -->
			<p-tabPanel header="{{ 'tableManagement.fine.fines' | translate }}">
				<p>
					<app-fine-list
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
						[showTitle]="false"
					></app-fine-list>
				</p>
			</p-tabPanel>

			<!-- TAXES -->
			<p-tabPanel header="{{ 'tableManagement.tax.taxes' | translate }}">
				<p>
					<app-taxes-list
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
						[showTitle]="false"
					>
					</app-taxes-list>
				</p>
			</p-tabPanel>

			<!-- FINANCING -->
			<p-tabPanel header="{{ 'tableManagement.financing.financing' | translate }}">
				<p>
					<app-financing-list
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
						[showTitle]="false"
					>
					</app-financing-list>
				</p>
			</p-tabPanel>

			<!-- INSURANCE -->
			<p-tabPanel header="{{ 'tableManagement.insurances' | translate }}">
				<p>
					<app-insurance-list
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
						[showTitle]="false"
					>
					</app-insurance-list>
				</p>
			</p-tabPanel>

			<!-- DOCUMENTS -->
			<p-tabPanel header="{{ 'documents.header' | translate }}">
				<p>
					<app-doc-table
						[type]="'vehicle'"
						[entity]="selectedVehicle"
						[nested]="true"
						[showTitle]="false"
					></app-doc-table>
				</p>
			</p-tabPanel>

			<!-- ACCESSORIES -->
			<p-tabPanel header="{{ 'tableManagement.accessories.accessories' | translate }}">
				<p>
					<app-inventory-vehicle-accessory-list
						[vehicle]="selectedVehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[showTitle]="false"
					>
					</app-inventory-vehicle-accessory-list>
				</p>
			</p-tabPanel>

			<!-- MAINTENANCE -->
			<p-tabPanel header="{{ 'maintenance.header' | translate }}">
				<p>
					<app-maintenance-executions-list
						[vehicle]="selectedVehicle"
						[showTitle]="false"
					></app-maintenance-executions-list>
				</p>
				<p>
					<app-maintenance-execution-alert
						[vehicle]="selectedVehicle"
						[showTitle]="false"
					></app-maintenance-execution-alert>
				</p>
			</p-tabPanel>

			<!-- MILEAGE -->
			<p-tabPanel header="{{ 'tableManagement.mileage.mileage' | translate }}">
				<p>
					<app-mileage-list
						[vehicle]="selectedVehicle"
						[showTitle]="false"
					></app-mileage-list>
				</p>
			</p-tabPanel>

			<!-- CARD CONSUMPTION -->
			<p-tabPanel header="{{ 'tableManagement.cardsConsumption.cardConsumption' | translate }}">
				<p>
					<app-cardConsumption-list
						[vehicle]="selectedVehicle"
						[showTitle]="false"
					></app-cardConsumption-list>
				</p>
			</p-tabPanel>
			<div class="margin"></div>
		</p-tabView>
	</div>
	<div class="margin"></div>
	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class="d-flex flex-row-reverse bd-highlight">
			<div class="p-2 bd-highlight">
				<!-- btn historic -->
				<button type="button" class="btn custom-button" (click)="onShowHistoric()" [disabled]="isEditingDisabled">
					{{ 'tableManagement.vehicleHtc.getHtc' | translate }}
				</button>
				<!-- btn SAVE -->
				<button
					type="button"
					class="btn custom-button"
					(click)="onSaveVehicle()"
					[disabled]="isEditingDisabled || !isSaveGranted"
				>
					{{ isEditingDisabled ? ('general.saving' | translate) : ('general.save' | translate) }}
				</button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<app-inventory-htc-vehicle></app-inventory-htc-vehicle>
<app-drivers-select-list></app-drivers-select-list>

<!-- CONFIRM SAVE EVENT DIALOG -->
<p-confirmDialog
	#saveEventDialog
	[header]="'confirm.critic' | translate"
	class="custom-dialog"
	icon="pi pi-exclamation-triangle"
>
	<ng-template pTemplate="footer">
		<button (click)="saveEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>
		<button (click)="saveEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>
	</ng-template>
</p-confirmDialog>

<app-brand-form></app-brand-form>

<app-model-form></app-model-form>

<app-vehicle-version-form [optionsFuelType]="optionsfuelType"></app-vehicle-version-form>
