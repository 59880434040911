import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { DeviceService } from '../../../rest/device.service';
import { EntityService } from '../../../rest/entity.service';
import { MaintenanceplanService } from '../../../rest/maintenanceplan.service';
import { NoticegroupService } from '../../../rest/noticegroup.service';
import { PoicategoryService } from '../../../rest/poicategory.service';
import { Rest } from '../../../rest/rest_client';
import { RoleService } from '../../../rest/role.service';
import { ZonegroupService } from '../../../rest/zonegroup.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { EntityFormComponent } from '../../entity-form/entity-form.component';
import { FleetTreeComponent } from '../../fleet-edit-view/fleet-tree/fleet-tree.component';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import Role = Rest.Role;

@Component({
	selector: 'app-role-form',
	templateUrl: './role-form.component.html',
	styleUrls: ['./role-form.component.css'],
	providers: [
		ConfirmationService,
		MaintenanceplanService,
		ZonegroupService,
		PoicategoryService,
		PoicategoryService,
		DeviceService,
		NoticegroupService,
		DelegationService,
	],
})
export class RoleFormComponent extends EntityFormComponent<Rest.Role> implements OnInit {
	@ViewChild('tv') public tabView: TabView;

	roleFormTitle: string;
	currentUser: Rest.User;
	fleetsGroupsAndVehiclesSelected: object[];
	MaintenancePlanRequestContext: Rest.ListPaginationRequest;
	ZoneGroupRequestContext: Rest.ListPaginationRequest;
	poiCategoryRequestContext: Rest.ListPaginationRequest;
	deviceRequestContext: Rest.ListPaginationRequest;
	noticeGroupRequestContext: Rest.ListPaginationRequest;
	delegationRequestContext: Rest.ListPaginationRequest;

	/* view tabs flags */
	fleetTab: boolean = true;
	maintenancePlanTab: boolean = true;
	zoneGroupTab: boolean = true;
	poiCategoryTab: boolean = true;
	deviceTab: boolean = true;
	notificationGroupTab: boolean = true;
	delegationsTab: boolean = true;

	constructor(
		private roleService: RoleService,
		private authService: AuthenticationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		private maintenancePlanService: MaintenanceplanService,
		private zoneGroupService: ZonegroupService,
		private poiCategoryService: PoicategoryService,
		private deviceService: DeviceService,
		private noticegroupsService: NoticegroupService,
		private delegationService: DelegationService
	) {
		super(roleService, notificationsService, i18n, authService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.currentUser = {} as Rest.User;
		this.fleetsGroupsAndVehiclesSelected = [];
	}

	/**
	 * Cada vez que un @Input modifica su valor (desde el exterior), se ejecuta este método.
	 * Lo aprovechamos para reconfigurar las opciones del dialogo y/o mapa
	 * @param changes
	 */
	// ngOnChanges(changes: SimpleChanges) {}

	beforeShow() {
		this.fleetsGroupsAndVehiclesSelected = [];
		this.MaintenancePlanRequestContext = RestExt.firstPageRequest();
		this.ZoneGroupRequestContext = RestExt.firstPageRequest();
		this.poiCategoryRequestContext = RestExt.firstPageRequest();
		this.deviceRequestContext = RestExt.firstPageRequest();
		//this.deviceRequestContext.sortBy ="externalId";
		this.noticeGroupRequestContext = RestExt.firstPageRequest();
		this.delegationRequestContext = RestExt.firstPageRequestVehicleInventory();

		if (this.isNew) {
			this.roleFormTitle = this.i18n._('Create Role');
		} else {
			this.roleService.find(this.entity.id + '').then((resp: Rest.Role) => {
				if (resp.visibleFleets != null) {
					resp.visibleFleets.forEach((d: Rest.Fleet) => {
						this.fleetsGroupsAndVehiclesSelected.push(d);
					});
				}
				if (resp.visibleVehicles != null) {
					resp.visibleVehicles.forEach((d: Rest.Vehicle) => {
						this.fleetsGroupsAndVehiclesSelected.push(d);
					});
				}
				if (resp.visibleVehiclesGroups != null) {
					resp.visibleVehiclesGroups.forEach((d: Rest.VehiclesGroup) => {
						this.fleetsGroupsAndVehiclesSelected.push(d);
					});
				}
				this.fleetsGroupsAndVehiclesSelected = [...this.fleetsGroupsAndVehiclesSelected];
			});
			this.roleFormTitle = this.i18n._('Edit Role');
		}

		if (this.authService.user.client.id === 638340 || this.authService.user.client.id === 394971) {
			this.fleetTab = true;
			this.maintenancePlanTab = true;
			this.zoneGroupTab = false;
			this.poiCategoryTab = false;
			this.deviceTab = false;
			this.notificationGroupTab = true;
			this.delegationsTab = true;
		} else {
			this.fleetTab = true;
			this.maintenancePlanTab = true;
			this.zoneGroupTab = true;
			this.poiCategoryTab = true;
			this.deviceTab = true;
			this.notificationGroupTab = true;
			this.delegationsTab = true;
		}
	}

	beforeSave() {
		this.entity.visibleFleets = [];
		this.entity.visibleVehiclesGroups = [];
		this.entity.visibleVehicles = [];
		this.fleetsGroupsAndVehiclesSelected.forEach((o: object) => {
			if (FleetTreeComponent.isFleet(o)) {
				this.entity.visibleFleets.push(o as Rest.Fleet);
			}
			if (FleetTreeComponent.isVehicle(o)) {
				this.entity.visibleVehicles.push(o as Rest.Vehicle);
			}
			if (FleetTreeComponent.isVehicleGroup(o)) {
				this.entity.visibleVehiclesGroups.push(o as Rest.VehiclesGroup);
			}
		});
	}
}
