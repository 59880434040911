import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FineService } from 'app/rest/fine.service';
import { DocumentationService } from '../../services/documentation.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { FinancingService } from '../../services/table-management/financing.service';
import { InsuranceService } from '../../services/table-management/insurance.service';
import { CardService } from 'app/rest/card.service';
import { DivisionService } from 'app/rest/division.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { SubdivisionService } from 'app/rest/subdivision.service';
import { DelegationService } from '../../services/table-management/delegation.service';
import { TaxService } from '../../services/table-management/taxes.service';
import { VehicleAccessoryService } from '../../services/vehicle-accessory.service';
import { InvVehicleService } from '../../services/invVehicle.service';
import { MaintenanceexecutionService } from 'app/rest/maintenanceexecution.service';
import { HtcCardListService } from '../../services/table-management/htc-card-list.service';
import { HtcVehicleStatusListService } from '../../services/table-management/htc-vehicle-status-list.service';
import { HtcVehicleUsageListService } from '../../services/table-management/htc-vehicle-usage-list.service';

@Component({
  selector: 'app-table-management',
  templateUrl: './table-management.component.html',
  styleUrls: ['./table-management.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
	FineService,
	DocumentationService,
	InsuranceService,
	FinancingService,
	VehicleService,
	ExcelService,
	TaxService,
	SubdelegationService,
	SubdivisionService,
	DelegationService,
	DivisionService,
	CardService,
	VehicleAccessoryService,
	MaintenanceexecutionService,
	HtcCardListService,
	HtcVehicleStatusListService,
	HtcVehicleUsageListService
]
})
export class TableManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
