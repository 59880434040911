<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Settings for Preserving
          Special Logical State (AT + GTPDS)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Main
                Settings</span>
            </div>
          </div>
        </p-header>
        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Mode">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-dropdown [options]="modeOptions" [(ngModel)]="pdsParameters.mode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </div>
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mask
                Settings</span>
            </div>
          </div>
        </p-header>
        <div class="row mt-3">
          <form-input fieldName="Mask"></form-input>

          <div class="row mt-3">
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="States of GEO" [(ngModel)]="pdsParameters.statesOfGeo">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="State of cut relay output"
                  [(ngModel)]="pdsParameters.stateCutOffRelayOutput">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="Information of last know position"
                  [(ngModel)]="pdsParameters.informationLastKnownPosition">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="State of main power" [(ngModel)]="pdsParameters.stateMainPower">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="State of ignition" [(ngModel)]="pdsParameters.ignitionOnOffStatus">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="State of motion status" [(ngModel)]="pdsParameters.stateMotion">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div div *ngIf="firmware > 5" class="col">
                <p-checkbox binary="true" label="State of digital input 1"
                  [(ngModel)]="pdsParameters.stateDigitalInput1">
                </p-checkbox>
              </div>
            </div>

          </div>


        </div>
      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>