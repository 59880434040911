<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Bearer Settings (AT + GTBSI)</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="APN">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <input type="text" pInputText [(ngModel)]="bsiParameters.apn" maxlength="40" class="txtbStyle">
                    </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="User name">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <input type="text" pInputText [(ngModel)]="bsiParameters.apnUsername" maxlength="30" class="txtbStyle"/>
                    </div>
                </div>
            </div>
        
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input  fieldName="Password">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.apnPassword" maxlength="30" class="txtbStyle"/>
                </div>
            </div>
        </div>

      </p-accordionTab>