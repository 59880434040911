import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: 'app-smsforwarding-configuration-ob22',
  templateUrl: './smsforwarding-configuration-ob22.component.html',
  styleUrls: ['./smsforwarding-configuration-ob22.component.css']
})
export class SMSForwardingConfigurationOB22Component extends ServerValidatedFormComponent implements OnChanges {

  @Input() smsForwarding: Rest.SMSForwardingConfigurationOB22;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.smsForwarding);
    }
  }

}
