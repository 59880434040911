<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >HEARTBEAT INTERVAL CONFIGURATION (HBT)</span>
        </div>
      </div>
    </p-header>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Interval ACC ON" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="heartbeatInterval.interval1" [min]="1" [max]="19"></p-spinner>
            <span class="ui-inputgroup-addon">1~19</span>
            <span class="ui-inputgroup-addon" >min</span>
          </div>
        </div>
      </div>
    
      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input  fieldName="Interval ACC OFF" >
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="heartbeatInterval.interval2" [min]="1" [max]="19"></p-spinner>
            <span class="ui-inputgroup-addon">1~19</span>
            <span class="ui-inputgroup-addon" >min</span>
          </div>
        </div>
      </div>
    
    </div>
  </p-accordionTab>
  