<p-accordionTab>
 <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Harsh cornering event configuration</span>
      </div>
      <div class="right">
        <p-inputSwitch  (onChange)="handleChange($event)"  [(ngModel)]="deviceParams.proprietaryParameters.harshCornering.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>


    <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.harshCornering.enabled>

      <div class="ui-g-12 ui-m-6">
        <form-input i18n-fieldname fieldName="Heading change threshold" [errors]="errors.headingChangeThreshold">
          <p-spinner size="5" [(ngModel)]="deviceParams.proprietaryParameters.harshCornering.headingChangeThreshold" [min]="0" [max]="259"></p-spinner>
          <span class="ui-inputgroup-addon">0~259</span>
          <span class="ui-inputgroup-addon" i18n>deg</span>
        </form-input>
      </div>

      <div class="ui-g-12 ui-m-6">
        <form-input i18n-fieldname fieldName="Speed threshold" [errors]="errors.speedThreshold">
          <p-spinner size="5" [(ngModel)]="deviceParams.proprietaryParameters.harshCornering.speedThreshold" [min]="5" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">5~255</span>
          <span class="ui-inputgroup-addon" i18n>Km/h</span>
        </form-input>
      </div>

      <div class="ui-g-12 ui-m-6">
        <form-input i18n-fieldname fieldName="Duration" [errors]="errors.duration">
          <p-spinner id="harshCorneringEventConfig" size="5" [(ngModel)]="deviceParams.proprietaryParameters.harshCornering.duration" [min]="0" [max]="10"></p-spinner>
          <span class="ui-inputgroup-addon">0~10</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="Led pattern" [errors]="errors.ledPatternIndex">
          <p-dropdown [options]="avaibleLedPatterns" [(ngModel)]="deviceParams.proprietaryParameters.harshCornering.pledIndex"
            [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>

    </div>
</p-accordionTab>
