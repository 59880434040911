/* eslint-disable prefer-const */
import {
  Component,
  OnInit
} from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Rest } from "../../rest/rest_client";
import { TranslationService } from "../../rest/translation.service";
import { I18nService } from "../../services/i18n/i18n.service";
import {
  NotificationsService,
  Severity,
} from "../../services/notifications-service/notifications.service";

@Component({
  selector: "app-translations-list",
  templateUrl: "./translation-list.component.html",
  styleUrls: ["./translation-list.component.css"],
  providers: [TranslationService, ConfirmationService],
})
export class TranslationComponent implements OnInit {
  translations: ExtendedTranslation[];
  selectedEntities: ExtendedTranslation[];

  constructor(
    private translationService: TranslationService,
    protected notificationsService: NotificationsService,
    protected confirmationService: ConfirmationService,
    protected i18n: I18nService
  ) { }

  ngOnInit() {
    this.translationService.getAll().then(
      (translations) =>
      (this.translations = translations.map((t) => {
        let nt = t as ExtendedTranslation;
        nt._$isNew = false;
        return nt;
      }))
    );
    this.selectedEntities = [];
  }

  add() {
    this.translations.push({ _$isNew: true } as ExtendedTranslation);
    this.translations = [...this.translations];
  }

  update(t: ExtendedTranslation) {
    if (t._$isNew) {
      this.translationService.create(t);
      this.translations.push(t);
      this.notificationsService.add(
        Severity.success,
        this.i18n._(":)"),
        this.i18n._("Entitie created")
      );
    } else {
      this.translationService.update(t);
      this.notificationsService.add(
        Severity.success,
        this.i18n._(":)"),
        this.i18n._("Entitie updated")
      );
    }
  }

  remove() {
    if (this.selectedEntities.length > 0) {
      const that = this;
      this.confirmationService.confirm({
        message: that.i18n._("Are you sure you want to delete these entities?"),
        accept: () => {
          this.translationService.Delete(this.selectedEntities);

          // eslint-disable-next-line guard-for-in
          for (const e in this.selectedEntities) {
            let index = this.translations.indexOf(this.selectedEntities[e]);
            this.translations.splice(index, 1);
            this.translations = [...this.translations];
          }
          that.notificationsService.add(
            Severity.success,
            that.i18n._(":)"),
            that.i18n._("Entities deleted")
          );
          that.selectedEntities = []; //last change to init selected entities and not remove already remove entities
        },
      });
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ExtendedTranslation extends Rest.Translation {
  _$isNew: boolean;
}
