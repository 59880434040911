<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Jamming Detection
          (AT + GTJDC)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown (onChange)="trackEventModeChange(jammingDetection.mode,$event)" [options]="jammingModeOptions"
            [(ngModel)]="jammingDetection.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>

    </div>

    <div [class.disableDIV]=!trackModeSwitch>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Engine Off to Tow">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner step="1" id="signalThreshold" size="2" [(ngModel)]="jammingDetection.signalThreshold" [min]="0"
                [max]="31"></p-spinner>
              <span class="ui-inputgroup-addon">0~31</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Fake Tow Delay">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner step="1" id="jammingCellNumberThreshold" size="2"
                [(ngModel)]="jammingDetection.jammingCellNumberThreshold" [min]="0" [max]="99"></p-spinner>
              <span class="ui-inputgroup-addon">0~99</span>
            </div>
          </div>
        </div>

      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Tow Interval">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner step="1" id="enterJammingTimerThreshold" size="7"
                [(ngModel)]="jammingDetection.enterJammingTimerThreshold" [min]="0" [max]="300"></p-spinner>
              <span class="ui-inputgroup-addon">0~300 </span>
              <span class="ui-inputgroup-addon">seconds</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Rest Duration">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner step="1" id="quitJammingTimerThreshold" size="2"
                [(ngModel)]="jammingDetection.quitJammingTimerThreshold" [min]="0" [max]="3600"></p-spinner>
              <span class="ui-inputgroup-addon">0~3600</span>
              <span class="ui-inputgroup-addon">seconds</span>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</p-accordionTab>