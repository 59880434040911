<p-accordionTab>

  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Crash Detection
          (AT + GTCRA)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Mode">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-dropdown [options]="craOptions" [(ngModel)]="craParameters.mode"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Threshold_X">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.thresholdX" [min]="0" [max]="160"></p-spinner>
          <span class="ui-inputgroup-addon">0~160</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Threshold_Y">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.thresholdY" [min]="0" [max]="160"></p-spinner>
          <span class="ui-inputgroup-addon">0~160</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Threshold_Z">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.thresholdZ" [min]="0" [max]="160"></p-spinner>
          <span class="ui-inputgroup-addon">0~160</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Sampling start">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-dropdown [options]="samplingOptions" [(ngModel)]="craParameters.samplingStartMode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Samples before crash">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.samplesBeforeCrash" [min]="0" [max]="1500"></p-spinner>
          <span class="ui-inputgroup-addon">0~1500</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Samples after crash">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.samplesAfterCrash" [min]="0" [max]="1500"></p-spinner>
          <span class="ui-inputgroup-addon">0~1500</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Output ID">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.outputId" [min]="0" [max]="1"></p-spinner>
          <span class="ui-inputgroup-addon">0~1</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Output Status">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-inputSwitch [(ngModel)]="craParameters.outputStatus"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Speed Confirmation">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.speedConfirmation" [min]="0" [max]="80"></p-spinner>
          <span class="ui-inputgroup-addon">0~80</span>
          <span class="ui-inputgroup-addon">km/h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="ODO Confirmation">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.odoConfirmation" [min]="0" [max]="200"></p-spinner>
          <span class="ui-inputgroup-addon">0~200</span>
          <span class="ui-inputgroup-addon">m</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Confirmation Time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.confirmationTime" [min]="3" [max]="15"></p-spinner>
          <span class="ui-inputgroup-addon">3~15</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Fall Degrees">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="craParameters.fallDegrees" [min]="20" [max]="80"></p-spinner>
          <span class="ui-inputgroup-addon">20~80</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="firmware > 5" class="row mt-3">
    <form-input fieldName="Report ACC">
      <div class="ui-inputgroup">
        <p-inputSwitch [(ngModel)]="craParameters.reportAcc"></p-inputSwitch>
      </div>
    </form-input>
  </div>

</p-accordionTab>