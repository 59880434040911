<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Ignition Off Event</span>
      </div>
      <div class="right">
        <p-inputSwitch   (onChange)="handleChange($event)"  [(ngModel)]="ignitionOff.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g"  [class.disableDIV]=!ignitionOff.enabled>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Delay" [errors]="errors.delay">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="delay" size="12" [(ngModel)]="ignitionOff.delay" step="1" [min]="0"
            [max]="65535">
          </p-spinner>
          <span class="ui-inputgroup-addon">0~65535</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>

  </div>
</p-accordionTab>