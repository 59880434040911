import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { VehiclemodelService } from 'app/rest/vehiclemodel.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { RestExt } from 'app/services/rest-client-extension';
import { VehicletypeService } from 'app/rest/vehicletype.service';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { SelectItem } from 'primeng/api/selectitem';
import { Auxiliar } from 'app/model/Auxiliar';

@Component({
  selector: 'app-vehicle-model-form',
  templateUrl: './vehicle-model-form.component.html',
  styleUrls: ['./vehicle-model-form.component.css'],
  providers:[VehiclemodelService, VehicletypeService],
})
export class VehicleModelFormComponent extends EntityFormComponent<Rest.VehicleModel> implements OnInit, OnChanges {
  @Input() display: boolean;
  @Output() closeModal: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  availableTypes: SelectItem[];
  selectedType: Rest.VehicleType;
  availableBrands: SelectItem[];
  selectedBrand: Rest.VehicleBrand;
  model: Rest.VehicleModel;
  dropdownBrandsDisable: Boolean = true;
  dropdownModelsDisable: Boolean = true;

  constructor(public modelService: VehiclemodelService,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService,
    public typeService: VehicletypeService,
    protected i18n: I18nService) {
      super(modelService, notificationsService, i18n, authenticationService);
    }

  ngOnInit(): void {
    super.ngOnInit();
    //Load available types
    var that = this;
    that.typeService
    .getPage(RestExt.allRecordsRequest())
    .then(function (response) {
        that.availableTypes = Auxiliar.toEntitySelectItemArr(
            response.entities
        );
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["display"]) {
        if (!this.display) {
          this.dropdownBrandsDisable = true;
          this.dropdownModelsDisable = true;       
          this.selectedType = null;
          this.selectedBrand = null;
          this.entity.name = null;
        }      
    }
  }
  onHide(event){
    this.closeModal.emit(false);
  }
  typeChanged(type: Rest.DeviceType) {
    const that = this;
    this.modelService
        .getBrandsOfType(type.id.toString())
        .then(function (response) {
            that.availableBrands = Auxiliar.toEntitySelectItemArr(response);
            that.dropdownBrandsDisable = response.length < 0;
        });
    that.dropdownModelsDisable = true;
    this.entity.name = null;
  }
  brandChanged(brand: Rest.VehicleBrand) {
    const that = this;
    that.dropdownModelsDisable = false;
    that.entity.name = null;
  }
  createModel(){
    this.entity.id = 0;
    this.entity.type = this.selectedType;
    this.entity.brand = this.selectedBrand;
    this.modelService.create(this.entity).then((resp) =>{
        this.notificationsService.add(Severity.success, "", this.translate.instant('general.createSuccessfull'));
        this.closeModal.emit(false);      
    }).catch((e)=>{
      this.notificationsService.add(Severity.error, "", this.translate.instant('general.createError'));
    });
  }

}
