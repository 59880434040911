import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { InsuranceTypeService } from 'app/modules/vehicle-inventory/services/table-management/InsuranceType.service';
import { ProviderService } from 'app/rest/provider.service';
import { ReportService } from 'app/rest/report.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { Table } from 'primeng/table';
import jsPDF from 'jspdf';
import { report } from 'process';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers:[ReportService]
})
export class ReportComponent extends  EntityFormComponent<any> implements OnInit {
  title: string;
  display=false;
  reportRequest: Rest.InventoryReportRequest;
  tableData: any;
  reportGenerated: boolean = false;

  columns: string[];
  values: Rest.ReportResponseItem[] = [];
  exportColumnsPdf: any[];
  constructor(
    private reportService: ReportService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public excelService: ExcelService


) {
    super(reportService, notificationsService, i18n, authenticationService);
}

ngOnInit() {
  super.ngOnInit();
    
}

  public showDialog(){
    this.values = [];
    this.display = true;
    this.reportRequest = {} as Rest.InventoryReportRequest;
    this.reportRequest.report = {} as Rest.InventoryReport;
    this.reportRequest.report.id = 1;
    this.reportRequest.report.reportType = 'DRIVERS_EXPIRATION';
    this.reportRequest.report.client = {} as Rest.Client;
    this.reportRequest.report.client.id = 394971;
    this.reportRequest.filters = {} as { [index: string]: string[] };
    this.reportRequest.filters['expirationDate'] = ['2024-02-09T00:00:00.000Z'];
    this.reportRequest.filters['alarmDate'] = ['2024-04-01T00:00:00.000Z'];
    this.reportRequest.parameters = ['DNI'];
    
    this.reportService.generateReport(this.reportRequest).then((resp) => {
      this.tableData = resp;
      this.columns = Object.keys(this.tableData[0]).map(key => this.tableData[0][key].field);

      
      for(let i=0; i<this.tableData.length; i++){
        for (const key in this.tableData[i]) {
          if (this.tableData[i].hasOwnProperty(key)) {
              const element = this.tableData[i][key];
              this.values.push({
                  field: element.field,
                  value: element.value,
                  status: element.status
              });
          }
        }
      }

      console.log(JSON.stringify(this.values));
      this.reportGenerated = true;

    })
  
   }

   exportExcel(){
    let element = document.getElementById("dt"); 
    this.excelService.exportTableAsExcelFileV2(element, "test", this.tableData, this.columns);
   }

   exportPdf(){
    //this.exportColumnsPdf
    /*this.exportColumnsPdf = Object.keys(this.tableData[0]).map(key => ({title: this.tableData[0][key].field, dataKey: key}));
    const doc = new jsPDF('p', 'pt');
    doc['autoTable'](this.exportColumnsPdf, this.tableData);
    doc.save("products.pdf");*/

    /*const doc = new jsPDF();
    doc.autoTable({
        head: [this.cols.map(col => col.header)], // Assuming cols contains column definitions
        body: this.data.map(row => this.cols.map(col => row[col.field])) // Assuming data contains table data
    });
    doc.save('table.pdf');*/

    
   }
}
