import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { Rest } from '../../../../rest/rest_client';
import { I18nService } from '../../../../services/i18n/i18n.service';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
    selector: 'app-tag-inout-config',
    templateUrl: './tag-inout-config.component.html',
    styleUrls: ['./tag-inout-config.component.css'],

    encapsulation: ViewEncapsulation.None
})
export class TagInoutConfigComponent extends ServerValidatedFormComponent implements OnInit {

    constructor(protected i18n: I18nService) {
        super(i18n);
    }

    @Input() tagInOutEventConfig: Rest.TagInOutEventConfiguration;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes["deviceParams"]) return;
    }

}
