<p-dialog
	header="{{ title }}"
	[(visible)]="display"
	[responsive]="true"
	showEffect="fade"
	[modal]="true"
	*ngIf="display"
	appendTo="body"
>
	<div class="container-md">
		<!-- FORM ERRORS -->
		<div #errorContainer>
			<div *ngIf="errorMessages.length !== 0">
				<div *ngFor="let error of errorMessages">
					<div class="alert alert-warning" role="alert">{{ error }}</div>
				</div>
			</div>
		</div>

		<!-- NAME -->
		<div class="row mt-3">
			<div class="col d-flex align-items-center">
				<label for="vehicle-data-settings-name" class="custom-label" i18n>
					<strong>{{ 'general.name' | translate }} (*)</strong>
				</label>
			</div>

			<div class="col">
				<div class="float-end">
					<input
						type="text"
						id="vehicle-data-settings-name"
						class="form-control custom-input"
						[(ngModel)]="entity.name"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>

		<!-- TYPE -->
		<div class="row mt-3">
			<div class="col d-flex align-items-center">
				<label for="vehicle-data-settings-name" class="custom-label" i18n>
					<strong>{{ 'general.type' | translate }} (*)</strong>
				</label>
			</div>

			<div class="col">
				<div class="float-end">
					<p-dropdown
						appendTo="body"
						appFocusOnNextEnter
						[options]="selectableTypes"
						[(ngModel)]="providerType"
						[style]="{ width: '175px' }"
						[disabled]="disableSave"
					></p-dropdown>
				</div>
			</div>
		</div>

		<!-- BPCode, shown only if TYPE is 'Renting' -->
		<div class="row mt-3" *ngIf="providerType?.name === 'Renting'">
			<div class="col d-flex align-items-center">
				<label for="bp-code" class="custom-label">{{ 'tableManagement.providers.bonusCode' | translate }}</label>
			</div>
			<div class="col">
				<div class="float-end">
					<input
						type="text"
						id="bp-code"
						class="form-control custom-input"
						[(ngModel)]="bpCode"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>

		<!-- BPCode, shown only if TYPE is 'Renting' -->
		<div class="row mt-3" *ngIf="providerType?.name === 'Concesionario'">
			<div class="col d-flex align-items-center">
				<label for="province" class="custom-label">{{ 'general.province' | translate}}</label>
			</div>
			<div class="col">
				<div class="float-end">
					<input
						type="text"
						id="bp-province"
						class="form-control custom-input"
						[(ngModel)]="bpProvince"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>
		<div class="row mt-3" *ngIf="providerType?.name === 'Concesionario'">
			<div class="col d-flex align-items-center">
				<label for="population" class="custom-label"> {{ 'drivers.form.population' | translate}}</label>
			</div>
			<div class="col">
				<div class="float-end">
					<input
						type="text"
						id="bp-population"
						class="form-control custom-input"
						[(ngModel)]="bpPopulation"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>

		<!-- ROW 4: PROFILE IMAGE & ADD IMAGE BUTTON -->
		<div class="row mt-2 mt-3">
			<!-- PROFILE IMAGE -->
			<img #image src="{{ providerEncodedImage }}" class="custom-user-profile-image mx-auto d-block p-0" />

			<!-- ADD IMAGE BUTTON -->
			<div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
				<div
					id="inner-icon-holder"
					class="d-flex align-items-center justify-content-center"
					(click)="uploadImageInput.click()"
				>
					<img src="assets/icons/camera-plus.png" class="custom-user-profile-icon" />
					<input
						#uploadImageInput
						[hidden]="true"
						type="file"
						accept=".jpg, .png"
						(change)="imageChange($event)"
						[disabled]="disableSave"
					/>
				</div>
			</div>
		</div>

		<!-- ACTIVE -->
		<div *ngIf="!isNew">
			<div class="row mt-3">
				<div class="col d-flex align-items-center">
					<label for="vehicle-data-settings-name" class="custom-label" i18n>
						{{ 'general.active' | translate }}
					</label>
				</div>
				<div class="col">
					<div class="float-end">
						<!-- <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
              type="checkbox" [(ngModel)]="providerEnabled" [disabled]="!isNew" [disabled]="disableSave"/> -->
						<p-inputSwitch
							#toggle
							[(ngModel)]="!entity.disabled"
							(onChange)="toggleDisabled($event)"
							class="p-1"
							[disabled]="disableSave"
						>
						</p-inputSwitch>
					</div>
				</div>
			</div>
		</div>
	</div>

	<p-footer>
		<div class="d-flex justify-content-center bd-highlight">
			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="disableSave">
						{{ disableSave ? ('general.saving' | translate) : ('general.save' | translate) }}
					</button>
				</div>

				<div *ngIf="isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n [disabled]="disableSave">
						{{ disableSave ? ('general.saving' | translate) : ('general.create' | translate) }}
					</button>
				</div>
			</div>

			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="changeStatus(providerEnabled)" i18n [disabled]="disableSave">
						{{ 'general.changeStatus' | translate }}
					</button>
				</div>
			</div>
		</div>
	</p-footer>
</p-dialog>
