import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from "@angular/core";
import { ConfirmationService } from "primeng/api";

import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { SensorFormComponent } from "../sensor-form/sensor-form.component";

@Component({
    selector: "app-sensor-list",
    templateUrl: "./sensor-list.component.html",
    styleUrls: ["./sensor-list.component.css"],
    providers: [ConfirmationService],
})
export class SensorListComponent implements OnChanges, OnInit {
    @ViewChild(SensorFormComponent, { static: true })
    sensorForm: SensorFormComponent;
    @Input() sensors: Rest.Sensor[];
    @Output() sensorsChange: EventEmitter<Rest.Sensor[]> = new EventEmitter<Rest.Sensor[]>();

    selectedEntities: Rest.Sensor[];
    private lastAssignedId = 0;

    user: Rest.User;

    isSelectableColumn: boolean = false;
    isColumn: boolean = false;

    /**Check box Column selection */
    entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
    selectedAll: boolean = false;
    disableSelectAll: boolean = true;

    /** */

    constructor(
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.selectedEntities = [];
        this.selectedAll = false;

        if (this.sensors == null) {
            this.sensors = [];
        }
        this.sensorForm.setFormSubscriptor((ret: any) => {
            if (this.sensors == null) {
                this.sensors = [];
            }
            if (ret.id == undefined) ret.id = this.lastAssignedId--;
            let listSensor = this.sensors.find((s: Rest.Sensor) => {
                return s.id == ret.id;
            });
            if (listSensor != null) {
                Object.assign(listSensor, ret);
            } else {
                this.sensors.push(ret);
            }
            this.sensors = [...this.sensors];
            this.sensorsChange.emit(this.sensors);
        });

        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    onRowDblClick(event: any) {
        this.sensorForm.update(event.data);
    }

    create() {
        this.sensorForm.create();
    }

    delete() {
        this.selectedEntities.forEach((s: Rest.Sensor) => {
            let idx = this.sensors.indexOf(s);
            if (idx >= 0) this.sensors.splice(idx, 1);
        });
        this.selectedEntities = [];
        this.sensors = [...this.sensors];
        this.sensorsChange.emit(this.sensors);
    }

    editDialog(event) {
        if (!this.isSelectableColumn) {
            this.onRowDblClick({ data: event });
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editDialogTable(event) {
        if (
            !this.isColumn &&
            !this.isSelectableColumn &&
            event["originalEvent"]["srcElement"]["cellIndex"] > 0
        ) {
            this.onRowDblClick(event);
        }

        this.isSelectableColumn = false;
        this.isColumn = false;
    }

    /**CheckBox Colum selection */
    checkSelectAll() {
        for (var a in this.sensors) {
            //if (this.sensors[a]['_$changeStatus'] == true) {
            this.disableSelectAll = false;
            //}
        }
    }

    selectAll(select: boolean) {
        if (select) {
            for (var a in this.sensors) {
                //if (this.sensors[a]['_$changeStatus'] == true) {
                if (this.selectedEntities.some((x) => x == this.sensors[a]) == false) {
                    this.selectedEntities.push(this.sensors[a]);
                }
                this.entitiesSelected[this.sensors[a]["id"]] = select;
                //}
            }
        } else {
            for (var i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
                this.selectedEntities.splice(i, 1);
            }
            for (var a in this.entitiesSelected) {
                this.entitiesSelected[a] = select;
            }
        }
    }

    changeSelection(id: number, value: boolean) {
        this.entitiesSelected[id] = value;
        if (value) {
            for (var a in this.sensors) {
                if (this.sensors[a]["id"] == id) {
                    this.selectedEntities.push(this.sensors[a]);
                }
            }
        } else {
            for (var i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
                if (this.selectedEntities[i]["id"] == id) {
                    this.selectedEntities.splice(i, 1);
                }
            }
            this.selectedAll = false;
        }
    }

    selectableColumn() {
        this.isSelectableColumn = true;
    }

    /** */
}
