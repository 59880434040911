import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { ClientService } from 'app/rest/client.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { isValidNif } from "nif-dni-nie-cif-validation";

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  providers: [BillingaccountService],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyFormComponent
  extends EntityFormComponent<Rest.BillingAccount>
  implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    cifValid: boolean = true;
    display=true;
    /* Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private billingaccountService: BillingaccountService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
        super(
            billingaccountService,
            notificationsService,
            i18n,
            authenticationService
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    beforeShow() {
        /**Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.i18n._("Create Billing Account");
        } else {
            this.title = this.i18n._("Edit Billing Account");
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */
    }

    protected beforeSave(): void {
        this.entity.client = this.authenticationService.user.client;
    }


    private saveBillingAccountForm(billingAccount){
        if(this.validateBillingAccountForm(billingAccount)){
            this.save();
        }
    }

    private validateBillingAccountForm(billingAccount): boolean {
            this.cifValid = true;
            if (!isValidNif(billingAccount.cif) && billingAccount.cif != null) {
           // this.cifValid = false;
            alert("isValidNif false");


            this.messages.push(
                //this.translate.instant('error.required-field.name'),
            "dni format error isValidNif"
            );
            return false;
        }
        return true;
    }
}
