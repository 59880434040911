<p-dialog *ngIf='isDialogVisible'
          header='{{ vehicleFormTitle | uppercase }}'
          [(visible)]='isDialogVisible'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [style]="{width: '50vw'}"
>

<p-table [value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

   <!-- #region TABLE TITLE DEFINITION -->
   <ng-template pTemplate="caption">
      <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
         {{ 'general.select' | translate }} {{ 'drivers.header' | translate }}
      </div>
   </ng-template>
   <!-- #endregion -->

   <p-card >
      {{ 'general.dblClickSelect' | translate }}
	</p-card>

   <!-- #region TABLE COLUMN DEFINITION -->
   <ng-template pTemplate="header">
      <tr>
			<!-- NIF -->
         <th pSortableColumn="nif">
            <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
	            <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.table.nif' | translate }}
               </div>
               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='nif'></p-sortIcon>
						<p-columnFilter field='nif'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>
			<!-- CODE -->
         <th pSortableColumn="code">
            <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
	            <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'general.code' | translate }}
               </div>
               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='code'></p-sortIcon>
						<p-columnFilter field='code'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>
         <!-- NAME -->
         <th pSortableColumn="name">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'drivers.table.name' | translate }}
               </div>
               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='name'></p-sortIcon>
						<p-columnFilter field='name'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>
         <!-- SURNAME1 -->
         <th pSortableColumn="surname1">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'userProfile.firstSurname' | translate }}
               </div>
               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='surname1'></p-sortIcon>
						<p-columnFilter field='surname1'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>
         <!-- SURNAME 2 -->
         <th pSortableColumn="surname2">
            <div class="d-flex align-items-center h-100">
               <!-- COLUMN TITLE -->
               <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                  {{ 'userProfile.secondSurname' | translate }}
               </div>
               <!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='surname2'></p-sortIcon>
						<p-columnFilter field='surname2'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>
            </div>
         </th>
      </tr>
   </ng-template>

   <ng-template pTemplate="body" let-driver>
      <tr class="p-selectable-row custom-rows" (dblclick)="selectDriver(driver)">
         <td>{{driver.nif}}</td>
			<td>{{driver.code}}</td>
         <td>{{driver.user.name}}</td>
			<td>{{driver.user.surname1}}</td>
			<td>{{driver.user.surname2}}</td>
      </tr>
   </ng-template>

	<!-- TABLE EMPTY MESSAGE -->
   <ng-template pTemplate="emptymessage">
   	<tr>
      	<td colspan="13" class="p-0">
        		<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          		{{ 'table.tableEmptyMessage' | translate }}!
        		</div>
      	</td>
    	</tr>
  	</ng-template>

</p-table>

</p-dialog>
