import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataImportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
