import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-external-power-supply-configuration-gmt200',
  templateUrl: './external-power-supply-configuration-gmt200.component.html',
  styleUrls: ['./external-power-supply-configuration-gmt200.component.css'],
})
export class ExternalPowerSupplyConfigurationGmt200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() externalPowerSupply: Rest.ExternalPowerSupplyGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }
  modeOptions: any = [
    {label: "Disable",value:"DISABLE"},
    {label: "Report when the input voltage in the range",value:"ENABLE_WITHIN_RANGE"},
    {label: "Report when the input voltage outside the range", value:"ENABLE_OUTSIDE_RANGE"},       
    {label: "Report when the input voltage in or outside the range", value:"ENABLE_WITHIN_OR_OUTSIDE_RANGE"} 
  ];
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.externalPowerSupply);
    }
  }

}
