import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
@Component({
  selector: 'app-binding-sim-card-gl320m',
  templateUrl: './binding-sim-card-gl320m.component.html',
  styleUrls: ['./binding-sim-card-gl320m.component.css']
})
export class BindingSimCardGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Do not bind SIM card", value: "NOT_BIND" },
    { label: "Bind the current SIM card.", value: "BIND_CURRENT_SIM" },
    { label: "Bind the specified SIM card through ICCID", value: "BIND_SPECIFIED_SIM" },
    { label: "Bind the SIM card and lock", value: "BIND_SIM_AND_LOCK" },
 ];

  @Input() simParameters: Rest.BindingSIMCardGL320M;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }



  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.simParameters);
    }
  }

}