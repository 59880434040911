<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
 -->

<div class="container-fluid p-0">

  <!-- EVENTS PRIMENG TABLE -->
  <p-table #eventsTable [value]="eventsArr"
           dataKey="deviceTime"
           [rows]="10"
           [totalRecords]="eventsArr.length"
           [rowsPerPageOptions]="[10,20,30,40,50]"
           [paginator]="true"
           [rowHover]="true"
           [showCurrentPageReport]="false"
           [autoLayout]="true"
           currentPageReportTemplate=
           "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
           class="custom-table custom-rt-events-table"
  >

    <!-- EVENTS TABLE COLUMN HEADER DEFINITION -->
    <ng-template pTemplate="header">
      <tr class="custom-rt-events-table-columns">

        <!-- EXPAND ICON COLUMN -->
        <th style="width: 3rem;">

        </th>

        <!-- DEVICE TIME COLUMN -->
        <th pSortableColumn="deviceTime">
          <div class="d-flex align-items-center h-100">

            <!-- DEVICE TIME COLUMN ICON && TITLE -->
            <div class="d-flex flex-fill align-items-center justify-content-center h-100 rt-events-table-icon">
              <svg-icon src="assets/icons/rt-events-time.svg" class="d-flex flex-wrap pe-2"
                        [svgStyle]="{ 'height.px': 20}"></svg-icon>
              Time
            </div>

            <!-- DEVICE TIME COLUMN SORT ICON -->
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="deviceTime"></p-sortIcon>
            </div>

          </div>
        </th>

        <!-- TYPE COLUMN -->
        <th pSortableColumn="type">
          <div class="d-flex align-items-center h-100">

            <!--TYPE COLUMN ICON && TITLE-->
            <div class="d-flex flex-fill align-items-center justify-content-center h-100 rt-events-table-icon">
              <svg-icon src="assets/icons/rt-events-type.svg" class="d-flex flex-wrap pe-2"
                        [svgStyle]="{ 'height.px': 20}"></svg-icon>
              Type
            </div>

            <!-- TYPE COLUMN SORT && FILTER ICONS -->
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="type"></p-sortIcon>
              <p-columnFilter type="text" field="type" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false"
                              class="p-ml-auto"></p-columnFilter>
            </div>

          </div>
        </th>

        <!-- TAG COLUMN -->
        <th pSortableColumn="tag">
          <div class="d-flex align-items-center h-100">

            <!-- TAG COLUMN TITLE-->
            <div class="d-flex flex-fill align-items-center justify-content-center h-100 rt-events-table-icon">
              <svg-icon src="assets/icons/rt-events-tag.svg" class="d-flex flex-wrap pe-2"
                        [svgStyle]="{ 'height.px': 20}"></svg-icon>
              Tag
            </div>

            <!-- TAG COLUMN SORT && FILTER ICONS -->
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="tag"></p-sortIcon>
              <p-columnFilter type="text" field="tag" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false"
                              class="p-ml-auto"></p-columnFilter>
            </div>

          </div>
        </th>

        <!-- VEHICLE NAME COLUMN-->
        <th pSortableColumn="vehicleName">
          <div class="d-flex align-items-center h-100">

            <!-- VEHICLE NAME COLUMN TITLE -->
            <div class="d-flex flex-fill align-items-center justify-content-center h-100 rt-events-table-icon">
              <svg-icon src="assets/icons/rt-events-vehicle.svg" class="d-flex flex-wrap pe-2"
                        [svgStyle]="{ 'height.px': 20}"></svg-icon>
              Vehicle
            </div>

            <!-- VEHICLE NAME COLUMN SORT && FILTER ICONS -->
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="vehicleName"></p-sortIcon>
              <p-columnFilter type="text" field="vehicleName" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false"
                              class="p-ml-auto"></p-columnFilter>
            </div>

          </div>
        </th>

        <!-- REQUEST ID COLUMN -->
        <th pSortableColumn="requestId">
          <div class="d-flex align-items-center h-100">

            <!-- REQUEST ID COLUMN TITLE -->
            <div class="d-flex flex-fill align-items-center justify-content-center h-100 rt-events-table-icon">
              <svg-icon src="assets/icons/rt-events-id.svg" class="d-flex flex-wrap pe-2"
                        [svgStyle]="{ 'height.px': 20}"></svg-icon>
              Request ID
            </div>

            <!-- REQUEST ID COLUMN SORT && FILTER ICONS -->
            <div class="d-flex align-items-center h-100">
              <p-sortIcon field="requestId"></p-sortIcon>
              <p-columnFilter type="text" field="requestId" placeholder="Search" display="menu" [showMatchModes]="false" [showOperator]="false"
                              class="p-ml-auto"></p-columnFilter>
            </div>

          </div>
        </th>

      </tr>
    </ng-template>

    <!-- EVENTS TABLE ROW DEFINITION -->
    <ng-template pTemplate="body" let-event let-expanded="expanded">
      <tr class="rt-events-table-rows">

        <td class="p-0">
          <div class="d-flex align-items-center justify-content-center">

            <button *ngIf="event._$expandable" type="button" [pRowToggler]="event" class="btn rt-events-table-expand-row-button">
              <svg-icon [ngClass]="{'rotated': !expanded}"
                        class="d-flex m-0 p-0 rt-events-table-expand-icon"
                        src="assets/icons/select-down.svg"
                        [svgStyle]="{'height.px': 20, 'width.px': 20}"
              ></svg-icon>
            </button>

          </div>
        </td>

        <td>
          <span class="p-column-title">Time</span>
          {{event.deviceTime|usertime}}
        </td>

        <td>
          <span class="p-column-title">Type</span>
          {{event.type}}
        </td>

        <td>
          <span class="p-column-title">Tag</span>
          {{event.tag}}
        </td>

        <td>
          <span class="p-column-title">Vehicle</span>
          {{event._$vehicleName}}
        </td>

        <td>
          <span class="p-column-title">Request ID</span>
          {{event._$requestId}}
        </td>

      </tr>
    </ng-template>

    <!-- EVENTS TABLE ROW EXPANSION DEFINITION -->
    <ng-template pTemplate="rowexpansion" let-eventExpanded>
      <tr>

        <td colspan="6" class="p-0">

          <div class="flex-column p-3 m-5 rt-events-table-expanded-container">

            <!-- DIV 1: REQUEST TYPE && REQUEST ID && REQUEST OWNER && REQUEST STATUS -->
            <div
              *ngIf="eventExpanded.type === 'REQUEST_QUEUED' || eventExpanded.type === 'REQUEST_SENT' || eventExpanded.type === 'REQUEST_SENDING' || eventExpanded.type==='REQUEST_CANCELLED'">

              <!-- ROW 1: REQUEST TYPE -->
              <div class="row">

                <div class="col">
                  Request Type:
                </div>

                <div class="col">
                  {{eventExpanded.requestType}}
                </div>

              </div>

              <!-- ROW 2: REQUEST ID -->
              <div class="row mt-2">

                <div class="col">
                  Request Id:
                </div>

                <div class="col">
                  {{eventExpanded.requestId}}
                </div>

              </div>

              <!-- ROW 3: REQUEST OWNER -->
              <div class="row mt-2">

                <div class="col">
                  Request owner:
                </div>

                <div class="col">
                  {{eventExpanded.ownerName}}
                </div>

              </div>

              <!-- ROW 4: REQUEST STATUS -->
              <div class="row mt-2" *ngIf="eventExpanded.type==='REQUEST_SENT'">

                <div class="col">
                  Request status:
                </div>

                <div class="col">
                  {{eventExpanded.status}}
                </div>

              </div>

            </div>

            <!-- DIV 2: SENSOR -->
            <div class="row" *ngIf="eventExpanded.type === 'SENSOR'">

              <div class="col">
                Sensor:
              </div>

              <div class="col">
                {{eventExpanded.sensorTag}} ({{eventExpanded.sensorId}})
              </div>

            </div>

            <!-- DIV 3: ZONE && ZONE GROUP && ACTION -->
            <div *ngIf="eventExpanded.type === 'ZONE'">

              <!-- ROW 1: ZONE -->
              <div class="row mt-2">

                <div class="col">
                  Zone:
                </div>

                <div class="col">
                  {{eventExpanded.zoneName}} ({{eventExpanded.zoneId}})
                </div>

              </div>

              <!-- ROW 2: ZONE GROUP -->
              <div class="row mt-2">

                <div class="col">
                  Zone Group
                </div>

                <div class="col">
                  {{eventExpanded.zoneGroupName}}
                </div>

              </div>

              <!-- ROW 3: ACTION -->
              <div class="row mt-2">

                <div class="col">
                  Action:
                </div>

                <div class="col">
                  <span *ngIf="eventExpanded.isEntering" i18n>ENTER</span>
                  <span *ngIf="!eventExpanded.isEntering" i18n>LEAVE</span>
                </div>

              </div>

            </div>

            <!-- DIV 4: DESCRIPTION -->
            <div class="row mt-2" *ngIf="eventExpanded.type === 'CUSTOM'">

              <div class="col">
                Description:
              </div>

              <div class="col">
                {{eventExpanded.$_description}}
              </div>

            </div>

          </div>

        </td>
      </tr>
    </ng-template>

    <!-- EVENTS TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="p-0">
          <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message">
            No records found
          </div>
        </td>
      </tr>
    </ng-template>

  </p-table>

</div>
