import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EntityListComponent } from '../../components/entity-list/entity-list.component';
import { Rest } from 'app/rest/rest_client';
import { ConfirmationService, LazyLoadEvent, SelectItem} from 'primeng/api'
import { UserTimePipe } from '../../components/shared/UserTimePipe';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { SpaceService } from '../fid-shared/service/space.service';
import { RestExt } from 'app/services/rest-client-extension';
import { PromotedRoutesFormComponent } from './promoted-routes-form/promoted-routes-form.component';
import { RoutesService } from '../fid-shared/service/routes.service';
import { TranslationsService } from '../fid-shared/service/translations.service';
import { Subscription } from 'rxjs';
import { CampaignService } from '../fid-shared/service/campaign.service';
import { ProviderService } from '../fid-shared/service/provider.service';
import { TargetsService } from '../fid-shared/service/targets.service';
import { FiltersService } from '../fid-shared/service/filters.service';
import { PoiRoutesFormComponent } from './poi-routes-form/poi-routes-form.component';
import { RoutePoiService } from '../fid-shared/service/route-poi.service';


@Component({
  selector: 'app-promoted-routes',
  templateUrl: './fid-promoted-routes.component.html',
  styleUrls: ['./fid-promoted-routes.component.css'],
  providers: [
		SpaceService,
		CampaignService,
		ProviderService,
		RoutesService,
		TranslationsService,
		//ConfirmationService,
		TargetsService,
		FiltersService,
		RoutePoiService
	]
})
export class FidPromotedRoutesComponent extends EntityListComponent<Rest.FidSpace> implements OnInit, OnDestroy {

	@ViewChild(PromotedRoutesFormComponent, { static: true })
	spaceForm: PromotedRoutesFormComponent;
	updateListSubscription: Subscription;

	protected alive: boolean = true;
	messages: any[];
	visible: boolean;
	errors = {};

	paginationRequest: Rest.ListPaginationRequest;
	paginationResult: Rest.Page<Rest.FidSpace>;
	filters: { };
	loadFilters: boolean; //Flag indicating that associated entity filters must be loaded at next request
	hasEntityFilters = false; //Flag indicating that the entity list can be filtered by associated entities. If true, requires that the REST service used has the method getPageAndFilters

	entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
	selectedAll: boolean = false;
	disableSelectAll: boolean = true;
	user: Rest.User;
	showDeleteDialog: boolean = false;

	loading: boolean = true; //Loading...
	isSelectableColumn: boolean = false;
	isColumn: boolean = false;

	selectedSpace: Rest.FidSpace;

	constructor(
		private spaceService: SpaceService,
		protected confirmationService: ConfirmationService,
		protected notificationsService: NotificationsService,
		protected i18n: I18nService,
		protected authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		private translate: TranslateService,
   ) {
		super(
			 spaceService,
			 confirmationService,
			 notificationsService,
			 i18n,
			 authenticationService,
			 loadingService,
			 userTime
		);
   }

	ngOnInit(){
		// console.log("[FID-PROMOTED-ROUTES] onInit ******");
		this.user = this.authenticationService.user;
		this.loadFilters = true;
		this.visible = true;
		this.messages = [];

		this.updateListSubscription = this.spaceForm.spaceSaved
      .subscribe(
         () => {
			  this.refresh();
         }
      );

		this.paginationRequest = RestExt.firstPageRequest();
		this.paginationResult = {
			 filteredEntities: 0,
			 entities: [],
		} as Rest.Page<Rest.FidSpace>;
		this.filters = {};
		this.paginationRequest.filters = {};
		this.fetch();
		this.beforeCreate();

	}

	ngOnDestroy(): void {
		this.alive = false;
		this.updateListSubscription.unsubscribe();
	}

	selectableColumn() {
		this.isSelectableColumn = true;
   }

   initFilters() {
   }

	refresh(): void {
		// console.log("[PROMOTED-ROUTES] refresh ********");
		const that = this;
		this.fetch().then(() => {
		});
   }

	fetch() {
		// console.log("[PROMOTED-ROUTES] fetch ********");
		this.loadingService.increaseRESTOngoingRequestCounter();
		const promise = new Promise<void>((resolve, reject) => {
			 const that = this;
			 //Load the filters and page if requested and the entity can be filtered by other entities
			 let t = this.paginationRequest;

			 /**
			  * Convert all the dates from the user defined time zone to UTC
			  */
			 if (this.paginationRequest != null) {
				  for (var f in this.paginationRequest.filters) {
						for (var idx in this.paginationRequest.filters[f]) {
							 if (
								  (this.paginationRequest.filters[f][idx] as any) instanceof Date
							 ) {
								  (this.paginationRequest.filters[f] as any)[idx] =
										this.userTime.calendarDateToUTC(
											 this.paginationRequest.filters[f][idx],
										);
							 }
						}
				  }
			 }

			//  console.log("this.loadFilters: " + this.loadFilters);
			//  console.log("this.hasEntityFilters: " + this.hasEntityFilters);
			 if (this.loadFilters && this.hasEntityFilters) {
				  //If paginationRequest is null create a new one
				  if (this.paginationRequest == null) {
						this.paginationRequest = RestExt.firstPageRequest();
				  }

				  this.spaceService
						.getPageAndFilters(this.paginationRequest)
						.then(
							 function (response) {
								  that.paginationResult = response.page;
								  that.filters = response.filters;
								  that.loadFilters = false;
								  /**Checkbox colum selection */
								  for (var a in response.page.entities) {
										that.entitiesSelected[response.page.entities[a]['id']] = false;
								  }
								  that.selectedAll = false;
								  /** */
								  that.loading = false;
								  that.loadingService.decreaseRESTOngoingRequestCounter();
								  resolve();
							 },

							 function (error) {
								  that.loadingService.decreaseRESTOngoingRequestCounter();
								  resolve();
							 },
						)
						.catch(function (reason) {
							 that.loadingService.decreaseRESTOngoingRequestCounter();
							 resolve();
						});
			 } else {
				//   console.log("**** FETCH ELSE *****");
				  this.spaceService
						.getPage(this.paginationRequest)
						.then(
							 function (response) {
								  that.paginationResult = response;
								  //console.log(that.paginationResult);
								  /**Checkbox colum selection */
								  for (var a in response.entities) {
										that.entitiesSelected[response.entities[a]['id']] = false;
								  }
								  that.selectedAll = false;
								  /** */
								  that.loadingService.decreaseRESTOngoingRequestCounter();
								  resolve();
							 },
							 /* failure */

							 function (error) {
								  console.log('The request failed: ' + error);
								  that.loadingService.decreaseRESTOngoingRequestCounter();
								  resolve();
							 },
						)
						.catch(function (reason) {
							 console.log('Catched: ' + reason);
							 that.loadingService.decreaseRESTOngoingRequestCounter();
							 resolve();
						});
			 }
		});
		return promise;
   }

	// protected beforeLoad() {
	// 	//console.log("*** beforeLoad() ***");
	// 	//this.paginationRequest.filters["active"] = ["true"];//we only need to search for reports of type tax
	// }

	// protected afterLoad() {
	// 	console.log("*** afterLoad() ***");
	// 	console.log(this.paginationResult);
	// }

	public deleteSpace(space: Rest.FidSpace): void{
		//console.log("[PROMOTED-ROUTES]  DeleteSpace(" + space.id + ") ***");
		let confirmationMessage =
			this.translate.instant('control-panel.delete-dialog.stats-advice') + '<br><br>' +
			this.translate.instant('control-panel.delete-dialog.message') + ' ' +
			this.translate.instant('promotedRoutes.theSpace') + ': ' +
			'<strong>' + space.campaign.name + '</strong>?';

		//console.log(confirmationMessage);
  		this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				//console.log("accept");
			 	this.spaceService.deleteSpace(space).then((response) => {
					//console.log(response);
					if (response) {
						this.notificationsService.add(
							 Severity.success,
							 this.translate.instant('success.success'),
							 this.translate.instant('success.delete.event')
						);
						this.refresh();
				  	}
			 	});
			},
  		});
	}

	/**
   * createRacingEvent()
   * */
	public createSpace(): void {
		// console.log("[PROMOTED-ROUTES] createSpace ***");
		this.selectedSpace = null;
		//this.racingForm.showDialog(true);
		this.spaceService.startedCreating.next();
	}

	/**
	* editRacingEvent()
	* */
	public editSpace(space: Rest.FidSpace): void {
		this.selectedSpace = space;
		// console.log("[PROMOTED-ROUTES] editSpace(" + this.selectedSpace.id + ", " + this.selectedSpace.campaign.name + ", " + this.selectedSpace.campaign.provider.name +") *****");
		this.spaceService.startedEditing.next(this.selectedSpace);
	}


}
