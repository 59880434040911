<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Time Adjustment
                    (AT+GTTMA)</span>
            </div>
        </div>
    </p-header>

    <div class="row mt-3">

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Sign">
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="signOptions" [(ngModel)]="tmaParameters.sign"
                    [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>
        
            <div class="col">
                <p-checkbox binary="true" label="Daylight Saving" [(ngModel)]="tmaParameters.daylightSaving">
                </p-checkbox>
            </div>
        
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Hour Offset">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="tmaParameters.hourOffset" [min]="0" [max]="12"></p-spinner>
                        <span class="ui-inputgroup-addon">0~12</span>
                    </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Minute Offset">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="tmaParameters.minuteOffset" [min]="0" [max]="59"></p-spinner>
                        <span class="ui-inputgroup-addon">0~59</span>
                    </div>
                </div>
            </div>
        
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="UTC Time">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="tmaParameters.utcTime" maxlength="14" class="txtbStyle"/>
                </div>
            </div>
        </div>

    </div>
</p-accordionTab>