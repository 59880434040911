import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";

import { ActionService } from "../../rest/action.service";
import { ProfileService } from "../../rest/profile.service";
import { Rest } from "../../rest/rest_client";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { EntityFormComponent } from "../entity-form/entity-form.component";

@Component({
    selector: "app-profile-form",
    templateUrl: "./profile-form.component.html",
    styleUrls: ["./profile-form.component.css"],
    providers: [ProfileService, ActionService],
})
export class ProfileFormComponent
    extends EntityFormComponent<Rest.Profile>
    implements OnInit {
    actions: SelectItem[];
    selectedActions: SelectItem[];
    actionsList: Rest.Action[];
    disableSave: boolean;

    user: Rest.User;

    title: String;

    constructor(
        profilesService: ProfileService,
        private actionService: ActionService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authService: AuthenticationService
    ) {
        super(profilesService, notificationsService, i18n, authService);
    }

    toSelectItem(actions: Rest.Action[]): SelectItem[] {
        const result = [];
        for (const a in actions) {
            result.push({ label: actions[a].name, value: actions[a].id });
        }
        return result;
    }

    beforeShow() {
        this.selectedActions = [];
        for (const a in this.entity.actions) {
            const t: any = this.entity.actions[a].id;
            this.selectedActions.push(t);
        }

        if (this.isNew) {
            this.title = this.i18n._("Create Profile");
            this.disableSave = false;
        } else {
            this.title = this.i18n._("Edit Profile");
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
    }

    beforeSave() {
        this.entity.actions = [] as Rest.Action[];
        for (const a in this.actionsList) {
            for (const e in this.selectedActions) {
                const item: any = this.selectedActions[e];
                if (this.actionsList[a].id == item) {
                    this.entity.actions.push(this.actionsList[a]);
                }
            }
        }
    }

    ngOnInit() {
        super.ngOnInit();
        const that = this;
        this.actionService
            .getPage(RestExt.allRecords)
            .then((ac: Rest.Page<Rest.Action>) => {
                that.actions = that.toSelectItem(ac.entities);
                that.actionsList = ac.entities;
            });

        /**Permissions */
        this.user = this.authService.user;
        /** */
    }
}
