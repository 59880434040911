import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServerValidatedFormComponent } from 'app/components/server-validated-form/server-validated-form.component';
import { Auxiliar } from 'app/model/Auxiliar';
import { PreconfigurationService } from 'app/rest/preconfiguration.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { RestExt } from 'app/services/rest-client-extension';
import { Accordion } from 'primeng/accordion';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'app-smart-coffee-parameters-form',
  templateUrl: './smart-coffee-parameters-form.component.html',
  styleUrls: ['./smart-coffee-parameters-form.component.css'],
  providers: [PreconfigurationService, ConfirmationService]
})
export class SmartCoffeeParametersFormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {
  //View params
  @Input() deviceParams: Rest.DeviceParameters;
  @Input() firmware: RestExt.FirmwareVersion;
  @Input() isNew: boolean;
  @Output() SmartCoffeeParamsChange: EventEmitter<Rest.DeviceParameters> = new EventEmitter<Rest.DeviceParameters>();
  @ViewChild('tv') public tabView: TabView;
  @ViewChild('ac') accordion: Accordion;

  @Input() isConfig: boolean;
  @Output('refreshList')
  refreshList: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  preconfigService: PreconfigurationService;
  confirmationService: ConfirmationService;
  notificationsService: NotificationsService;
  firmwareValue: any;

  /**Events */
  selected = false;

  /**PreConfigs */
  preConfigsList: Rest.DevicePreconfiguration[];
  availablePreconfigs: SelectItem[];
  selectedPreconfig: any;

  constructor(
     preconfigService: PreconfigurationService,
     confirmationService: ConfirmationService,
     notificationsService: NotificationsService,
     protected i18n: I18nService,
  ) {
     super(i18n);
     this.preconfigService = preconfigService;
     this.confirmationService = confirmationService;
     this.notificationsService = notificationsService;
     this.i18n = i18n;
  }

  ngOnChanges(changes: SimpleChanges) {
     /* Sergi: solved Bug */
     if (this.isNew && this.deviceParams == null) {
        this.deviceParams = RestExt.emptySmartCoffeeParams();
     }

     //this.deviceParams.configuration = <Rest.DeviceConfiguration>{deviceType:"VT10"};

     if (changes['deviceParams']) {
        this.SmartCoffeeParamsChange.emit(this.deviceParams);
     }
     if (changes['firmware']) {
        if (this.firmware == undefined) {
           this.firmwareValue = undefined;
        } else {
           this.firmwareValue = RestExt.FirmwareVersion[this.firmware] + 1; //Add 1 because returns the index of enum value and starts at 0 which corresponds to first item
           RestExt.initializeSmartCoffeeParams(this.deviceParams);
           this.LoadPreconfigsList();

        }
     }
  }

  ngOnInit() {
     this.initConfig();
     if (this.isConfig) {
        this.LoadPreconfigsList();
     }
  }

  public getSmartCoffeeDeviceParams(): any {
     return this.deviceParams;
  }

  LoadPreconfigsList() {
     let paginationRequest: Rest.ListPaginationRequest;
     this.availablePreconfigs = [];
     this.selectedPreconfig = null;
     this.preConfigsList = [];

     const that = this;
     /*this.preconfigService.getPage(RestExt.allRecords).then(
     function (response) {
       that.preConfigsList = response.entities;
       that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(response.entities);
     });*/
     paginationRequest = RestExt.firstPageRequest();
     //paginationRequest.sortBy="creationDate";
     paginationRequest.sortAsc = false;

     paginationRequest.filters = {
        firmware: [this.firmware + ''],
     };

     this.preconfigService.getPage(paginationRequest).then(function (response) {
        that.preConfigsList = response.entities;
        that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(response.entities);
     });
  }

  initConfig() {
     this.preConfigsList = [];
     this.selectedPreconfig = null;

     if (this.accordion != undefined) {
        this.tabView.activeIndex = 0;
        this.closeAllAccordionTabs();
     }
  }

  loadPreconfig(event) {
     this.preconfigService.find(event.value.id).then((pre: Rest.DevicePreconfiguration) => {
        this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
     });
  }
  fillPreConfig(newParams: Rest.DeviceParameters) {
     //remove id because if is from preconfig we have to assign as new sensors to config
     for (const a in newParams.sensors) {
        delete newParams.sensors[a]['id'];
     }

     for (const a in newParams.actuators) {
        delete newParams.actuators[a]['id'];
     }
     //Load the rest preconfig parameters to vt10 config
     this.deviceParams.sensors = newParams.sensors;
     this.deviceParams.actuators = newParams.actuators;
     this.deviceParams.proprietaryParameters.type = 'SmartCoffee';

     //assign id config to keep the assignation
     /*var configTmp: Rest.VT10Parameters = <Rest.VT10Parameters>{};
   configTmp.id = this.deviceParams.proprietaryParameters.id;*/
     for (const a in this.deviceParams.sensors) {
        this.deviceParams.sensors[a].parameters = null; //configTmp;
     }
     for (const a in this.deviceParams.actuators) {
        this.deviceParams.actuators[a].parameters = null; //configTmp;
     }
  }

  tabChanged() {
     /**
      * Dirty trick:
      * Force [(deviceParams)] onChange events to fire in sub-components to detect changes in LedPatterns names
      */
     this.deviceParams = Object.assign({}, this.deviceParams);
     this.SmartCoffeeParamsChange.emit(this.deviceParams);
  }
  refresh(status: boolean) {
     this.refreshList.emit(status);
  }

  closeAllAccordionTabs() {
     if (!this.isNullOrUndefined(this.accordion.tabs)) {
        for (const tab of this.accordion.tabs) {
           if (tab.selected) {
              tab.selected = false;
           }
        }
     }
  }

  isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
     return typeof obj === 'undefined' || obj === null;
  }
}