<p-dialog [header]="isNew ? ('zones.zone-form.create' | translate) : ('zones.zone-form.update' | translate)" [draggable]="true" [(visible)]="display" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" (onHide)="close($event)" *ngIf="display"
  styleClass="pDialog">

  <div class="container-fluid m-0 p-0">

    <!-- <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div> !-->

    <!-- ZONE DATA -->
    <p-panel header={{zoneData}} [toggleable]="true" styleClass="custom-panel">

      <div class="d-flex justify-content-between container px-0">

        <!-- #region COL 1 -->
        <div class="col me-2">

          <!-- #region Name label and textbox -->
          <div class="row mb-2">
            <form-input i18n-fieldName fieldName={{zoneName}} [errors]="errors.name">
              <input pInputText [(ngModel)]="entity.name" [style]="{'width':'250px'}" />
            </form-input>
          </div>
          <!-- #endregion -->

          <!-- #region Zone Group selector -->
          <div class="row mb-2">
            <form-input [required]="true" i18n-fieldName fieldName={{zoneGroupLabel}} [errors]="errors.zoneGroup">

              <p-dropdown id="dropdownZoneGroups" (onChange)="zoneGroupChanged()" placeholder={{zonePlaceholder}}
                [options]="availableZoneGroups" [(ngModel)]="entity.zoneGroup" [style]="{'width':'100%'}"
                filter="filter"></p-dropdown>

              <button class="AddButton" type="button" pButton icon="fa fa-plus" (click)="createZoneGroup()"
                i18n-pTooltip pTooltip="Add New Zone Group" tooltipPosition="top"></button>

            </form-input>
          </div>
          <!-- #endregion -->

          <!-- #region Enabled label and checkbox -->
          <div class="row mb-2">
            
            <form-input i18n-fieldName fieldName={{enabledLabel}} [errors]="errors.enabled" *ngIf="!isRealtime">
              <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
            </form-input>

          </div>
          <!-- #endregion -->

        </div>

        <!-- #endregion -->

        <div class="vr"></div>

        <!-- #region COL 2 -->
        <div class="col ms-2">

          <!-- #region Type of the zone label and selector -->
          <div class="row mb-2">
            <form-input i18n-fieldName fieldName={{zoneType}} [errors]="errors.circular">
              <p-dropdown [options]="Types" [(ngModel)]="entity.circular" (onChange)="fireZoneTypeChangedEvent($event)"
                [disabled]="disableZoneTypeSelector" [style]="{'width':'250px'}"></p-dropdown>
            </form-input>
          </div>
          <!-- #endregion -->

          <!-- #region Events label and cehckboxes with its labels -->
          <div class="row mb-2">
            <div class="d-flex flex-row">

              <div class="col mr-2">
                <form-input fieldName={{eventsLabel}}></form-input>
              </div>

              <form-input fieldName="" [errors]="errors.eventOnEnter" class="ml-3">
                <p-checkbox i18n-label label={{enterLabel}} binary="true"
                  [(ngModel)]="entity.eventOnEnter"></p-checkbox>
              </form-input>

              <form-input fieldName="" [errors]="errors.eventOnLeave" class="ml-3">
                <p-checkbox i18n-label label={{leaveLabel}} binary="true"
                  [(ngModel)]="entity.eventOnLeave"></p-checkbox>
              </form-input>

            </div>
          </div>
          <!-- #endregion -->

          <!-- #region CUSTOM ZONE POINTS TEXTBOX -->

          <div class="d-flex row">
            <form-input i18n-fieldName fieldName={{customPoints}} [errors]="errors.name" *ngIf="user.isAdmin">
              <input type="text" [ngModel]="zoneStr" (ngModelChange)="zoneStringChanged($event)">
            </form-input>
          </div>
          <!-- #endregion -->

        </div>
        
      </div>
    </p-panel>

    <div *ngIf="showMap" class="mt-3">
      <app-custom-map #map mapId="map-zones" [display]="display" [options]="customMapOptions" [zones]="zones"
        (eventCreateZone)="notifyCreateZone($event)" (eventUpdateZone)="notifyUpdatePOI($event)"
        (loadMap)="initMapAfterViewInit($event)"></app-custom-map>
    </div>

  </div>

  <p-footer>

    <!-- Permissions -->
    <div *ngIf="isNew" class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label={{saveButton}}
        class="SaveButton"></button>
    </div>

    <div *ngIf="isNew === false" class="d-flex justify-content-end">

      <div *ngIf="entity._$update">
        <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label={{saveButton}}></button>
      </div>

      <div *ngIf="entity._$update === false">
        <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
          <button disabled="true" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
            label={{saveButton}}></button>
        </div>
      </div>

      <div *ngIf="entity._$update">
        <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
          label="Change status"></button>
      </div>

    </div>

  </p-footer>

</p-dialog>

<app-zone-group-form [realTime]=true></app-zone-group-form>

<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>