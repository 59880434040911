<p-dialog header={{ruleFormTitle}} [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true"
     [draggable]="true" *ngIf="display">

    <div class="ui-grid ui-grid-responsive">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>


        <p-tabView #tv>
            <p-tabPanel i18n-header header="General">
                <div class="ui-g">
                    <div class="ui-g-12 ui-md-6">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'general.name' | translate}}" [errors]="errors.name">
                            <input pInputText id="name" [(ngModel)]="entity.name" />
                        </form-input>
                    </div>

                    <div class="ui-g-12 ui-md-6">
                        <form-input i18n-fieldName fieldName="{{'general.description' | translate}}" [errors]="errors.description">
                            <textarea pInputTextarea id="description" [(ngModel)]="entity.description" [rows]="5"
                                [cols]="30"></textarea>
                        </form-input>
                    </div>

                    <div class="ui-g-12 ui-md-12">
                        <form-input [required]="true" i18n-fieldName fieldName="{{'general.code' | translate}}" [errors]="errors.code">
                            <code>

                            <br>#
                            <br>#function detect(events,positions,metadata,incommingMessagePayload,incommingMessageType,currentTime)
                            <br>#    events: eventsQuery
                            <br>#    positions: positionsQuery
                            <br>#    incommingMessagePayload: incomming message (event or position)
                            <br>#    incommingMessageType: EVENT | POSITION
                            <br>#    currentTime: millis
                            <br>#    
                            <br>#    return: [throw_event,events]
                            <br>#        throw_event: boolean indicating if the event should be thrown 
                            <br>#        events: serialized array of events to be thrown
                            <br>#
                            <br>function detect(events,positions,metadata,incommingMessagePayload,incommingMessageType,currentTime)&#123;                                       
                            <br>    var throwEvent = false;
                            <br>   
                            <br>    ###############################################
                            <br>    ################### Write here ################

                            </code>
                            <codemirror [(ngModel)]="entity.code" [config]="codeInputSettings"></codemirror>
                            <code>
                                    ###############################################
                                   &#125;
                            </code>
                        </form-input>
                    </div>

       
                    <div class="ui-g-12 ui-md-6">
                        <form-input i18n-fieldName [required]="false" fieldName="{{'general.metadata' | translate}}">
                            <app-metadata-editor [(metadata)]="metadata"></app-metadata-editor>
                        </form-input>
                    </div>

                    <div class="ui-g-12 ui-md-6">
                      <p-inputSwitch fieldName="{{'control-panel.rules.realTime' | translate}}" [(ngModel)]="rtSource"></p-inputSwitch>
                    </div>
                    

                    <div *ngIf="!rtSource">
                    
                    <form-input [required]="false" fieldName="{{'control-panel.rules.vdm' | translate}}">
                            <textarea type="text" [(ngModel)]="handcraftedVDM"></textarea>
                        </form-input>
                    <form-input   [required]="false" fieldName="{{'real-time.vehiclePanel.events' | translate}}">
                                <textarea type="text" [(ngModel)]="handcraftedEvents"></textarea>
                    </form-input>
                    <form-input   [required]="false" fieldName="{{'control-panel.rules.enteringData' | translate}}">
                            <input type="text" [(ngModel)]="handcraftedEnteringData"/>
                    </form-input>

                    <form-input fieldName="{{'control-panel.rules.dataEvent' | translate}}">
                      <p-inputSwitch onLabel="EVENT" offLabel="DATA" [(ngModel)]="handcraftedEnteringDataTypeIsEvent"></p-inputSwitch>
                    </form-input>
                    
                     
                    <button (click)="test()">Test</button>
                </div>

                     <form-input *ngIf="rtSource" [required]="true"   i18n-fieldName fieldName="Test vehicle Id"
                     [errors]="errors.testVehicleId">
                     <input pInputText id="testVehicleId" [(ngModel)]="testVehicleId" />
                    </form-input>

                    


                  
                    <button *ngIf="rtSource && playing" (click)="pause()">Pause</button>
                    <button *ngIf="rtSource && !playing" (click)="play()">Play</button>

                    <div class="ui-g-12 ui-md-12">
                        <h1>{{'control-panel.rules.evaluationHistory' | translate}}</h1>
                        <div    style="overflow: scroll;height: 30vh;">
                        <div *ngFor="let res of evalResultHistory">
                            <div>
                                <h3>Events ({{res.events.length}})</h3>
                                
                               <div><p *ngFor="let event of res.events">{{event}}</p></div>
                            </div>
                            <div>
                                <h3>Vdm  ({{res.vdm.length}})</h3>
                                <div><p *ngFor="let m of res.vdm">{{m}}</p></div>
                            </div>
                            <div>
                                <h3>Rule Fired</h3>
                                <div>{{res.ruleFired}}</div>
                            </div>
                            <div>
                                    <h3>GeneratedEvents</h3>
                                    <div><p *ngFor="let l of res.generatedEvents">{{l}}</p></div>
                                </div>
                            <div>
                                <h3>Log</h3>
                                <div><p *ngFor="let l of res.log">{{l}}</p></div>
                            </div>
                            <div>
                                <h3>Errors</h3>
                                <div><p *ngFor="let err of res.errors">{{err}}</p></div>
                            </div>
                            <hr>
                            <hr>
                        </div>
                        </div>
                    </div>


                </div>
            </p-tabPanel>
            <p-tabPanel i18n-header header="{{'zones.zone-group.fleets' | translate}}">
                <app-fleet-tree [viewDisableds]=false [(selection)]="fleetsGroupsAndVehiclesSelected"
                    [cascadeSelection]=true [selectableEntities]=false></app-fleet-tree>
            </p-tabPanel>            
        </p-tabView>




    </div>

    <p-footer>
        <!-- <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="Save"></button>
        </div> -->
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <div *ngIf="disableSave == false">
                <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
            </div>
            <div *ngIf="disableSave == true">
                <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update"
                    tooltipPosition="top">
                    <button disabled="true" type="button" pButton icon="fa-check" (click)="save()" i18n-label
                        label="{{'general.save' | translate}}"></button>
                </div>
            </div>
            <div *ngIf="isNew == false">
                <div *ngIf="disabeChangeStatus == false">
                    <button type="button" pButton icon="fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
                        label="{{'general.changeStatus' | translate}}"></button>
                </div>
                <div *ngIf="disabeChangeStatus == true">
                    <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to change status"
                        tooltipPosition="top">
                        <button disabled="true" type="button" pButton icon="fa-check"
                            (click)="changeStatus(!entity.enabled)" i18n-label label="{{'general.changeStatus' | translate}}"></button>
                    </div>
                </div>
            </div>
        </div>

    </p-footer>

</p-dialog>