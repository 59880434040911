import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";

import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import {
    Location,
    UpdatePOIMarkerEvent,
} from "../../shared/custom-map/custom-map.model";
import { PoiService } from "../../../rest/poi.service";
import { PoicategoryService } from "../../../rest/poicategory.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
    NotificationsService,
    Severity,
} from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { PoiCategoriesFileFormComponent } from "../poi-categories-file-form/poi-categories-file-form.component";
import { PoiCategoryFormComponent } from "../poi-category-form/poi-category-form.component";

@Component({
    selector: "app-poi-categories-list",
    templateUrl: "./poi-categories-list.component.html",
    styleUrls: ["./poi-categories-list.component.css"],
    providers: [
        PoicategoryService,
        ConfirmationService,
        PoiService,
        UserTimePipe,
    ],
})
export class PoiCategoriesListComponent
    extends EntityListComponent<Rest.POICategory>
    implements OnInit {
    selectedSubEntities: Rest.POI[];
    pois: Rest.POI[];
    poiCategoryTemp: Rest.POICategory;
    title: string;

    displayDialog: boolean;
    displayFileForm: boolean;

    messageWarning: string;
    containsNestedEntities = [];

    //datos para compartir con custom-map
    selectedPOICategory: Rest.POICategory; //categoria que queremos editar. Si listPOIs = [], se muestran los pois de selectedPOICategory.pois.
    editPOI: Rest.POI; //indica el POI que queremos editar al abrir el formulario (null si no editamos)
    listPOIs: Rest.POI[]; //indica la lista de pois que queremos mostrar en el mapa
    modeEdit: boolean;

    showPOIDialog = false;
    expander = true;
    EnabledFilter: SelectItem[];

    showStatusDialog = false;

    @ViewChild(PoiCategoryFormComponent, {static: true})
    poiCategoryForm: PoiCategoryFormComponent;
    @ViewChild(PoiCategoriesFileFormComponent, {static: true})
    poiFileForm: PoiCategoriesFileFormComponent;

    poisPositionUpdatedFromForm = 0; //indica los pois que se han arrastrado por el mapa. Al cerrar el dialogo esta variable se resetea a 0

    poiCategoryService: PoicategoryService;

    constructor(
        poiCategoryService: PoicategoryService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        private poiService: PoiService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            poiCategoryService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.poiCategoryService = poiCategoryService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.poiCategoryForm;
        this.selectedSubEntities = [];
        this.pois = [];
        this.hasEntityFilters = true;
        this.displayFileForm = false;
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );

        this.paginationResult.entities.forEach((pc: any) => {
            /*Permissions */
            pc._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "POI Category"),
                pc.id
            );
            pc._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "POI Category"),
                pc.id
            );
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }

    /**
     * Muestra el formulario en modo editar categoria.
     * Las configuraciones del mapa se haran en el componente poi-categories-list
     * @param category Categoria que se quiere editar en el dialogo
     */
    editCategoryDialog(category: Rest.POICategory) {
        this.selectedPOICategory = category; //custom-map will load pois from poiCategory
        this.listPOIs = null;
        //this.update(category);
        //muestra el formulario
        this.onRowDblClick({data: category});
    }

    /**
     * Handle del evento que genera el mapa que indica que un poi debe ser actualizado.
     * Este método lanza una petición al backend para actualizar el poi con los datos de event.location para el poi identificado por event.poiId
     * @param event UpdatePOIMarkerEvent:{poiID: id del poi que queremos editar, location: todos los datos relacionados con la nueva posición del poi}
     */
    notifyUpdatePOIevent(event: UpdatePOIMarkerEvent) {
        const poiId = event.poiId;
        const newLocation = event.location;
        const that = this;
        this.poisPositionUpdatedFromForm += 1;

        //buscar POI
        //si hemos seleccionado una categoria, buscamos solo en los pois de esa categoría
        if (this.selectedPOICategory) {
            const targetPOI = getPOIfromCategory(poiId, this.selectedPOICategory);
            if (targetPOI) {
                tryUpdatePOI(newLocation, targetPOI, this.selectedPOICategory, this);
            }
        }
        //sino buscamos en todos los pois
        else {
            let i;
            for (
                i = 0 ;
                i < (this.paginationResult.entities as Rest.POICategory[]).length ;
                i++
            ) {
                const category: Rest.POICategory = (
                    this.paginationResult.entities as Rest.POICategory[]
                )[i];
                const targetPOI = getPOIfromCategory(poiId, this.selectedPOICategory);
                if (targetPOI) {
                    tryUpdatePOI(newLocation, targetPOI, category, this);
                    break;
                }
            }
        }

        /**
         * Lanza petición para actualizar POI
         * @param location objecto con todos los nuevos datos relacionados con la nueva posición del poi
         * @param updatePoi poi actual (versión original no actualizada)
         * @param parentCategor categoria a la que pertenece el poi
         * @param controller el this/that no funciona (porque??), así que le pasamos el controlador (this) a la función
         */
        function tryUpdatePOI(
            location: Location,
            updatePoi: Rest.POI,
            parentCategor: Rest.POICategory,
            controller: PoiCategoriesListComponent
        ) {
            const poiRequest = Object.assign({}, updatePoi);
            poiRequest.position.latitude = Number(location.lat);
            poiRequest.position.longitude = Number(location.lon);
            poiRequest.address = location.address;
            poiRequest.postalCode = location.postalCode;
            poiRequest.municipality = location.municipality;
            poiRequest.province = location.subregion;
            poiRequest.timeZone = location.timezone;
            //delete poiRequest['_$visited'];

            controller.poiService.update(poiRequest).then((res) => {
                updatePoi.position.latitude = res["position"].latitude;
                updatePoi.position.longitude = res["position"].longitude;
                updatePoi.address = res["address"];
                updatePoi.postalCode = res["postalCode"];
                updatePoi.municipality = res["municipality"];
                updatePoi.province = res["province"];
                updatePoi.timeZone = res["timeZone"];
                controller.notificationsService.add(
                    Severity.success,
                    controller.i18n._(":)"),
                    controller.i18n._("POI position updated")
                );
            });
        }

        /**
         * Busca el poi con id "id" y lo devuelve si existe. Si no existe devuelve null
         * @param id del poi que buscamos
         * @param category categoria que contiene la lista de pois que vamos a recorrer
         */
        function getPOIfromCategory(id: number, category: Rest.POICategory) {
            let i;
            for (i = 0 ; i < category.pois.length ; i++) {
                if (category.pois[i].id === id) {
                    return category.pois[i];
                }
            }
            return null;
        }
    }

    createCategoryDialog() {
        this.selectedSubEntities = [];
        this.selectedEntities = [];
        this.pois = [];

        //Guarda la lista de pois de todas las categorias que hay en pantalla
        let i;
        let poisToMap: Rest.POI[] = [];
        const categories = this.paginationResult.entities;
        for (i = 0 ; i < categories.length ; i++) {
            poisToMap = poisToMap.concat(categories[i].pois);
        }

        //elementos compartidos con el formulario para mostrar en el mapa
        this.listPOIs = poisToMap;
        this.selectedPOICategory = null;
        this.create();
    }

    close() {
        this.displayDialog = false;
        this.selectedSubEntities = [];
        this.selectedEntities = [];
        this.pois = [];
        this.containsNestedEntities = [];
    }

    containsObject(obj, list) {
        let i;
        for (i = 0 ; i < list.length ; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    }

    notifyChildFetch() {
        console.log("fetch");
        this.refresh();
    }

    notifyOnCloseForm(e) {
        //TODO: no va, el poi-form no emite onclose...
        if (this.poisPositionUpdatedFromForm) {
            this.refresh();
        }
        this.poisPositionUpdatedFromForm = 0;
    }

    loadFileForm(pc: Rest.POICategory) {
        this.poiFileForm.show(pc);
    }
}
