import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class DivisionService extends EntityService<Rest.Division>{
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Division): Rest.RestResponse<Rest.Division> {
        return this.client.Division_create(arg0);
    }

    update(arg0: Rest.Division): Rest.RestResponse<Rest.Division> {
        return this.client.Division_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
    ): Rest.RestResponse<Rest.Page<Rest.Division>> {
        return this.client.Division_getPage(arg0);
    }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Division>> {
        return this.client.Division_getPageAndFilters(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<any> {
        return this.client.Division_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Division> {
        return this.client.Division_find(arg0, queryParams);
    }

	 getList(clientId: number): Rest.RestResponse<Rest.Division[]> {
		return this.client.Division_getList(clientId);
	 }

}
