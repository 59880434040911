import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-hstconfiguration-concox-mt200',
  templateUrl: './hstconfiguration-concox-mt200.component.html',
  styleUrls: ['./hstconfiguration-concox-mt200.component.css'],
})
export class HstconfigurationConcoxMt200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() hst: Rest.HSTConfigurationConcoxMT200;

  constructor(protected i18n: I18nService) {  super(i18n);}

 
  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.hst);
    }
  }

}
