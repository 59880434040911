import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-table-send-message-button',
    templateUrl: './table-send-message-button.component.html',
    styleUrls: ['./table-send-message-button.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TableSendMessageButtonComponent {
    @Input() isDisabled = false;
    @Output() btnClick = new EventEmitter();

    constructor() {
    }

    /**
     * onClick()
     * */
    public onClick(): void {
        this.btnClick.emit();
    }

}
