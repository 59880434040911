<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Digital Input Port
          Settings(AT + GTDIS)</span>
      </div>
    </div>
  </p-header>

  <!-- Ignition Input -->
  <div class="ui-g-12">
    <form-input fieldName="Ignation Input">
    </form-input>
  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Sample Period">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="disParameters.samplePeriod" [min]="0" [max]="30000"></p-spinner>
          <span class="ui-inputgroup-addon">0~30000</span>
          <span class="ui-inputgroup-addon">mv</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Ignition Detection Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="modeOptions" [(ngModel)]="disParameters.mode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

  </div>

  <!-- Digital input 1 -->
  <div class="ui-g-12">
    <form-input fieldName="Digital input 1">
    </form-input>
  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Enable">
        </form-input>
      </div>
      <div class="col">
        <p-inputSwitch [(ngModel)]="disParameters.enableD1"></p-inputSwitch>
      </div>
    </div>

    <div [class.disableDIV]=!disParameters.enableD1 class=ui-g-12>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Debounce time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD1" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon">*10ms</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Validity time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD1" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon">*2s</span>
          </div>
        </div>
      </div>

    </div>

  </div>

  <!-- Digital Input 2 -->
  <div class="ui-g-12">
    <form-input fieldName="Digital input 2">
    </form-input>
  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Enable">
        </form-input>
      </div>
      <div class="col">
        <p-inputSwitch [(ngModel)]="disParameters.enableD2"></p-inputSwitch>
      </div>
    </div>

    <div [class.disableDIV]=!disParameters.enableD2 class=ui-g-12>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Debounce time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTimeD2" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon">*10ms</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Validity time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTimeD2" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon">*2s</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</p-accordionTab>