import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { ProviderService } from 'app/rest/provider.service';
import { Auxiliar } from 'app/model/Auxiliar';

@Component({
	selector: 'app-provider-form',
	templateUrl: './provider-form.component.html',
	styleUrls: ['./provider-form.component.scss'],
	providers: [ProviderService, IconService],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderFormComponent extends EntityFormComponent<Rest.Provider> implements OnInit {
	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;
	/*Permissions */
	disableSave: boolean = false;
	disabeChangeStatus: boolean;
	errorMessages: string[] = [];

	providerEnabled: boolean;

	providerLogo: any;
	providerEncodedImage: string;
	providerLogoLabel: string;
	newProviderLogo: Rest.Icon;

	entityEnumImage: Rest.IconEntityAssociationEnum = 'INVENTORY';
	selectableTypes: SelectItem[] = [];

	formData: FormData;

	providerType: Rest.ProviderType;
	providerBonusRenting: Rest.ProviderBonusRenting;
	bpCode: string;
	bpPopulation: string;
	bpProvince: string;

	constructor(
		private providerService: ProviderService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private iconService: IconService,
		public translateService: TranslateService
	) {
		super(providerService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.formData = new FormData();

		this.providerService.getTypes().then((p) => {
			p.forEach((t) => {
				this.selectableTypes.push(Auxiliar.toEntitySelectItem(t));
			});
		});
	}

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof ClientFormComponent
	 */

	beforeShow() {
		this.bpCode = "";
		this.bpProvince = "";
		this.bpPopulation = "";
		this.providerEnabled = !this.entity.disabled;
		this.providerLogoLabel = this.translate.instant('tableManagement.brands.logo');

		/*Permissions */
		if (this.isNew) {
			this.disableSave = false;
			this.providerLogo = null;
			this.providerEncodedImage = null;
			this.providerLogoLabel = null;
			this.newProviderLogo = null;
			this.providerType = null;
			this.title = this.translateService.instant('tableManagement.providers.createProvider');
		} else {
			this.providerType = this.entity.type;
			this.title = this.translateService.instant('tableManagement.providers.editProvider');
			if (this.entity['_$update'] === true) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
			if (this.entity['_$changeStatus'] === true) {
				this.disabeChangeStatus = false;
			} else {
				this.disabeChangeStatus = true;
			}

			// We are setting the value of the path of the image, depending if it's already set or not
			if (this.entity.image != null) {
				this.providerEncodedImage = this.entity.image.imageEncoded;
			} else {
				this.providerEncodedImage = '../../../../assets/images/UI_Elements/HD_transparent_picture.png'; //Reset the image to an empty png image from assets.
			}

			if (this.entity.type.name.toUpperCase() === 'RENTING' && this.entity.providerParameters != null) {
				var bp = this.entity.providerParameters as Rest.ProviderBonusRenting;
				this.bpCode = bp.codeBp;
			}

			if(this.entity.type.name.toUpperCase() === 'CONCESIONARIO' && this.entity.providerParameters != null){
				var bpConcessionaire = this.entity.providerParameters as Rest.ProviderBonusConcessionaire;
				this.bpPopulation =  bpConcessionaire.population;
				this.bpProvince =  bpConcessionaire.province;
			}
		}
		this.disableSave = false;
		/** */
	}

	public toggleDisabled() {
		// console.log(this.entity.disabled);
	}

	saveChanges() {
		this.entity.type = this.providerType;
		this.disableSave = true;

		if (!this.validateProviderForm()) {
			this.disableSave = false;
			return;
		}

		if (this.isNew) {
			let apiProvider = {
				id: 0,
				name: this.entity.name,
				type: {
					id: this.entity.type.id,
					name: this.entity.type.name,
					code: this.entity.type.code,
				},
				providerParameters:
					this.entity.type.name.toUpperCase() === 'RENTING' ? 
					{
						type: this.entity.type.name,
						codeBp: this.bpCode,
					}
					: this.entity.type.name.toUpperCase() === 'CONCESIONARIO' ? {								
							type: this.entity.type.name,
							province: this.bpProvince,
							population: this.bpPopulation
						}
					: null,
				image: null,
				disabled: !this.providerEnabled,
				client: {
					id: this.authenticationService.user.client.id,
				},
			};
			let providerString = JSON.stringify(apiProvider);
			this.providerService.createProvider(providerString, this.providerLogo).then((p) => {
				this.disableSave = false;
				this.display = false;
				this.return.emit(p);
			});
		} else {
			let apiProvider = {
				id: this.entity.id,
				name: this.entity.name,
				type: {
					id: this.entity.type.id,
					name: this.entity.type.name,
					code: this.entity.type.code,
				},
				providerParameters:
					this.entity.type.name.toUpperCase() === 'RENTING' ? 
					{
						type: this.entity.type.name,
						codeBp: this.bpCode,
					}
					: this.entity.type.name.toUpperCase() === 'CONCESIONARIO' ? {								
							type: this.entity.type.name,
							province: this.bpProvince,
							population: this.bpPopulation
						}
					: null,
				image: null,
				disabled: !this.providerEnabled,
				client: {
					id: this.authenticationService.user.client.id,
				},
			};
			let providerString = JSON.stringify(apiProvider);
			// console.log(providerString);
			this.providerService.updateProvider(providerString, this.providerLogo).then((p) => {
				this.disableSave = false;
				this.display = false;
				this.return.emit(p);
			});
		}
	}

	/**
	 * Takes a local image, and communicates with the API, in order to
	 * restore the client image.
	 *
	 * @param {*} event
	 * @memberof ClientFormComponent
	 */

	imageChange(event): void {
		const reader = new FileReader();
		this.providerLogo = event.target.files[0];
		reader.readAsDataURL(this.providerLogo);
		reader.onload = (event: any) => {
			// console.log(reader.result);
			this.providerEncodedImage = event.target.result;
		};
	}

	public validateProviderForm(): Boolean {
		// console.log("[VEHICLE-FORM] validateVehicleForm *** ");
		this.errorMessages = [];

		if (!this.entity.name || this.entity.name === '' ||
			!this.entity.type) {
			this.errorMessages.push(
				this.translate.instant('error.required-field.generic'),
			);
			this.notificationsService.add(
				Severity.error,
				this.translate.instant(this.isNew ? 'general.createError' : 'general.updateError') + "!",
				this.translate.instant('error.required-field.generic'));
			window.scroll({ top: 0, behavior: 'smooth' });
		}
		return this.errorMessages.length === 0;
	}
}
