import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-authentication-id-configuration-gv800",
  templateUrl: "./authentication-id-configuration-gv800.component.html",
  styleUrls: ["./authentication-id-configuration-gv800.component.css"],
})
export class AuthenticationIdConfigurationGv800Component
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() idaParameters: Rest.AuthenticationIdConfigurationGV800;

  modeOptions: any = [
    { label: "Disable", value: 0 },
    { label: "Only authorized ID cards can unlock the vehicle", value: 1 },
    { label: "any ID card can unlock the vehicle", value: 2 },
  ];

  modeReportOptions: any = [
    { label: "report the ID which is authorized", value: 0 },
    { label: "report the ID which is unauthorized", value: 1 },
    { label: "report the ID which has logged out", value: 2 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }
  trackModeSwitch = false;
  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.idaParameters);
    }
  }

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value === 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {}
}
