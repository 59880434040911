import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService } from "primeng/api";

import { ProfileService } from "../../rest/profile.service";
import { Rest } from "../../rest/rest_client";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService } from "../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../entity-list/entity-list.component";
import { ProfileFormComponent } from "../profile-form/profile-form.component";
import { UserTimePipe } from "../shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";

@Component({
    selector: "app-profiles-list",
    templateUrl: "./profiles-list.component.html",
    styleUrls: ["./profiles-list.component.css"],
    providers: [ProfileService, ConfirmationService, UserTimePipe],
})
export class ProfilesListComponent
    extends EntityListComponent<Rest.Profile>
    implements OnInit {
    @ViewChild(ProfileFormComponent, { static: true })
    profileForm: ProfileFormComponent;

    disableSave: boolean;

    user: Rest.User;

    constructor(
        profilesService: ProfileService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            profilesService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.canBeEnabled = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.profileForm;
        this.hasEntityFilters = true;
        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    afterLoad() {
        this.paginationResult.entities.forEach((pf: any) => {
            /**Permissions */
            pf._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
            /** */
        });
        /**Permissions */
        this.checkSelectAll();
        /** */
    }
}
