import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NotificationsService, Severity } from '../../services/notifications-service/notifications.service';
import { RequestService } from '../../modules/sat/services/request.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-send-message-dialog',
    templateUrl: './send-message-dialog.component.html',
    styleUrls: ['./send-message-dialog.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SendMessageDialogComponent {
    @Input() displayDialog: boolean;
    @Input() deviceIdList: string[];
    @Output() closeDialog: EventEmitter<any>;
    @Input() headerContent: string;

    public messageContent: string;


    constructor(
        private requestService: RequestService,
        private notificationsService: NotificationsService,
        private translate: TranslateService
    ) {
        this.displayDialog = false;
        this.deviceIdList = [];
        this.closeDialog = new EventEmitter();

        this.messageContent = '';
        this.headerContent = '';
    }

    /**
     * initSendMessageDialog()
     * */
    public initSendMessageDialog(): void {
        this.messageContent = '';
        this.headerContent = '';
        this.closeDialog.emit();
    }

    /**
     * sendMessage()
     * */
    public sendMessage(): void {

        this.requestService.sendRequest(this.deviceIdList, this.messageContent)
            .then((response: any) => {

                this.closeDialog.emit();

                /* If message has been sent to all devices */
                if (response.ok) {

                    this.notificationsService.add(
                        Severity.success,
                        this.translate.instant('success.success'),
                        this.translate.instant('success.send.message') + '!'
                    );
                }

                /* If message has been sent to some devices */
                else {

                    this.notificationsService.add(
                        Severity.warn,
                        'Error ' + response.error.error_code,
                        response.error.error_param + '!'
                    );
                }
            })
            .catch((error) => {
                this.notificationsService.add(
                    Severity.error,
                    'Error ' + error.status,
                    error.message + '!'
                )
            });
    }

}
