<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Accelerometer Virtual Ignition Settings(AT+GTAVS)</span>
            </div>
          </div>
        </p-header>
        <div class="row mt-3">
      
          <div class="row mt-3">
            <form-input  fieldName="Sensor Rest Duration">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="avsParameters.restDuration" [min]="1" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">1~255</span>
                <span class="ui-inputgroup-addon" >sec</span>
              </div>
            </form-input>
          </div>
          <div class="row mt-3">
                <form-input  fieldName="Sensor Motion Validity">
                  <div class="ui-inputgroup">
                    <p-spinner size="5" [(ngModel)]="avsParameters.motionValidity" [min]="1" [max]="255"></p-spinner>
                    <span class="ui-inputgroup-addon">1~255</span>
                    <span class="ui-inputgroup-addon" >sec</span>
                  </div>
                </form-input>
        </div>
        </div>
      </p-accordionTab>
