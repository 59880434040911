import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Rest } from '../../../rest/rest_client';
import { ConfirmationService } from 'primeng/api';
import { TerminalService } from '../../../rest/terminal.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import Terminal = Rest.Terminal;

@Component({
    selector: 'app-terminals-list',
    templateUrl: './terminals-list.component.html',
    styleUrls: ['./terminals-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TerminalsListComponent implements OnInit {
    @Input() terminalsList: Terminal[];

    public selectedTerminals: Terminal[];

    constructor(
        private terminalService: TerminalService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private notificationsService: NotificationsService
    ) {
        this.selectedTerminals = [];
    }

    ngOnInit(): void {
    }

    /**
     * updateSelectedTerminals()
     * */
    public updateSelectedTerminals(event): void {
        if (!event.checked) {
            this.selectedTerminals = [];
        } else {
            const selectedTerminal: Terminal = this.selectedTerminals[this.selectedTerminals.length - 1];

            this.selectedTerminals = [];
            this.selectedTerminals.push(selectedTerminal);
        }

    }

    /**
     *  confirmDeleteTerminal()
     * */
    public confirmDeleteTerminal(terminal: Terminal, favorite: boolean): void {
        this.confirmationService.confirm({
            message: (!favorite ? this.translate.instant('confirm.delete.general') :  this.translate.instant('confirm.delete.terminalFavourite')) + ' <strong>' + terminal.deviceDesc + '</strong>?',
            accept: () => {
                this.deleteTerminal(terminal);
            }
        });
    }

    /**
     * updateTerminalNotifications()
     * */
    public updateTerminalNotifications(): void {
        let currentFavourite: Terminal = this.selectedTerminals[0];
        let previousFavourite: Terminal = null;

        /* Search for the previous old terminal */
        this.terminalsList.forEach((terminal) => {
            if (terminal.pushReception) {
                previousFavourite = terminal;
            }
        });

        /* Set the new terminal as favourite */
        currentFavourite.pushReception = true;
        this.terminalService.updateTerminal(currentFavourite).then((response) => {
            if (response) {
                currentFavourite = response;
                this.selectedTerminals = [];

                this.notificationsService.add(
                    Severity.success,
                    this.translate.instant('success.success'),
                    this.translate.instant('success.update.newTerminal') + '!'
                );

                /* Set the old terminal to default */
                if (previousFavourite) {
                    previousFavourite.pushReception = false;
                    this.terminalService.updateTerminal(previousFavourite).then((response) => {
                        if (response) {
                            previousFavourite = response;

                            this.notificationsService.add(
                                Severity.success,
                                this.translate.instant('success.success'),
                                this.translate.instant('success.update.oldTerminal') + '!'
                            );
                        } else {
                            previousFavourite.pushReception = true;

                            this.notificationsService.add(
                                Severity.error,
                                'Error',
                                this.translate.instant('error.update.oldTerminal') + '!'
                            );
                        }
                    });
                }
            } else {
                currentFavourite.pushReception = false;

                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.update.newTerminal') + '!'
                );
            }
        });
    }

    /**
     * deleteTerminal()
     * */
    private deleteTerminal(terminal: Terminal): void {
        this.terminalService.deleteTerminal(terminal.id).then((response) => {
            if (response) {
                this.terminalsList.splice(this.terminalsList.indexOf(terminal), 1);
            }
        });
    }
}
