import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { CardTypeService } from "app/modules/vehicle-inventory/services/table-management/cardType.service";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { ProviderService } from 'app/rest/provider.service';

import CardType = Rest.CardType;
import { CardTypeFormComponent } from "../cardTypeForm/cardType-form.component";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import { Auxiliar } from "app/model/Auxiliar";

@Component({
    selector: 'app-card-types-list',
    templateUrl: './cardTypes-list.component.html',
    styleUrls: ['./cardTypes-list.component.css'],
    providers: [CardTypeService, ConfirmationService, UserTimePipe, ExcelService, ProviderService],
})
export class CardTypesListComponent
    extends EntityListComponent<CardType>
    implements OnInit {

    title: string = this.i18n._("Card Types");

    @ViewChild(CardTypeFormComponent, { static: true })
    cardTypeForm: CardTypeFormComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    providersList: Rest.Provider[] = [];
    providerFilter: string[] = [];

    constructor(
        private cardTypeService: CardTypeService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected providerService: ProviderService,
        protected translateService: TranslateService
    ) {
        super(
            cardTypeService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.cardTypeService = cardTypeService;
    }


    ngOnInit(): void {
        super.ngOnInit();

        this.form = this.cardTypeForm;
        this.hasEntityFilters = false;
        this.getProvidersList();
    }

    beforeLoad(): void {
        let filtersEmpty = Object.keys(this.paginationRequest.filters).length === 0;

        if (!filtersEmpty) {
            const chars = [...this.paginationRequest.filters["provider"]];
            if (chars.length == 0) {
            }
        }
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    afterLoad() {
        this.cardTypeService.getPage(this.paginationRequest).then((p) => {
            this.paginationResult = p;
        });
        /*
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );
        */
        this.paginationResult.entities.forEach((ct: any) => {
            ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
            /*Permissions */
            ct._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "CardType"),
                ct.id
            ); //This is for the validation of entity-list (selectAll function)
            ct._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "CardType"),
                ct.id
            );
            /** */
        });

        this.checkSelectAll();
    }

    getProvidersList() {
        var providerRequestContext = {
            sortBy: "name",
            sortAsc: true,
            pageSize: 10,
            filters: {},
            loadPermissions: true
        } as Rest.ListPaginationRequest;

        this.providersList = [];

        this.providerService.getPage(providerRequestContext).then((p) => {
            p.entities.forEach((t) => {
                if (t.type.id === 1 || t.type.id === 2) {

                    if (this.providerFilter.length > 0) {
                        if (this.providerFilter.includes(t.name)) {
                            this.providersList.push(t);
                        }
                    } else {
                        this.providersList.push(t);
                    }
                }
            });

            this.providersList.sort((a, b) => a.name > b.name ? 1 : -1);
        });
    }

    excelColumns = {};

    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.cardTypes.cardTypesManagement');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }

    setExcelColumns() {
        const code = (entity: any) => entity.code ?? ''
        const name = (entity: any) => entity.name ?? ''
        const provider = (entity: any) => entity.provider?.name ?? ''
        const codeProductLimit = (entity: any) => entity.codeProductLimit ?? ''
        const productLimit = (entity: any) => entity.productLimit ?? ''
        const operationLimit = (entity: any) => entity.operationLimit ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''

        this.excelColumns = {
            [this.translateService.instant('general.code')]: { bindField: code },
            [this.translateService.instant('general.name')]: { bindField: name },
            [this.translateService.instant('tableManagement.providers.provider')]: { bindField: provider },
            [this.translateService.instant('tableManagement.cardTypes.codeProductLimit')]: { bindField: codeProductLimit },
            [this.translateService.instant('tableManagement.cardTypes.productLimit')]: { bindField: productLimit },
            [this.translateService.instant('tableManagement.cardTypes.operationLimit')]: { bindField: operationLimit },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }
}