import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";

import {Rest} from "../../../../../rest/rest_client";
import {RealtimeService} from "../../../../../rest/realtime.service";
import {DeviceService} from "../../../../../rest/device.service";
import {I18nService} from "../../../../../services/i18n/i18n.service";
import {NotificationsService, Severity,} from "../../../../../services/notifications-service/notifications.service";
import {RealTimeDataService} from "../../../../../services/rt-data/rt-data.service";
import {RequestResponseListener} from "../../../request-response-listener/request-response-listener.component";

@Component({
  selector: "app-call-back",
  templateUrl: "./call-back.component.html",
  styleUrls: ["./call-back.component.css"],
  providers: [RealtimeService, DeviceService],
})
export class CallBackComponent extends RequestResponseListener implements OnInit {
  @ViewChild('callBackModal') callBackModal: ElementRef;
  @ViewChild('closeCallBackModalButton') closeModalButton: ElementRef;
  @Input() vehicleId: number;
  showForm: boolean = false;
  phoneNumber: string = "";

  constructor(
    protected i18n: I18nService,
    rtService: RealtimeService,
    realTimeDataService: RealTimeDataService,
    notificationService: NotificationsService,
    deviceService: DeviceService
  ) {
    super(i18n, realTimeDataService, notificationService, deviceService);
  }

  ngOnInit() {
    this.setup();
  }

  send() {
    //Construct the request object
    let req = <Rest.CallBackRequestMessage>{
      id: null,
      vehicleId: this.vehicleId,
      platformTime: null,
      requestType: "CALL_BACK",
      tag: "",
      phoneNumber: this.phoneNumber,
    };

    this.sendRequest(req, this.vehicleId)
      .then((responsePromise: any) => {
        this.notificationService.add(
          Severity.success,
          this.i18n._(":)"),
          this.i18n._(
            "Call-Back command queued. The message will be sent to the device as soon as possible"
          )
        );
        responsePromise[0].then((response: Rest.ResponseMessage) => {
          //Handle the message
          switch (response.status) {
            case "ACK":
              this.notificationService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._(
                  "Call-Back command sent to the device. It will call you soon"
                )
              );
              break;
            default:
              this.notificationService.add(
                Severity.warn,
                this.i18n._(":|"),
                this.i18n._(
                  "There was a problem sending the request to the device"
                )
              );
              break;
          }
        });
      })
      .catch((e) => {
        this.notificationService.add(
          Severity.error,
          this.i18n._(":("),
          this.i18n._("There was a problem ququing the command")
        );
      });
    this.phoneNumber = "";
    this.showForm = false;
  }

  closeCallBackModal(): void {
    this.phoneNumber = '';
  }
}
