import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { Rest } from 'app/rest/rest_client';
import { Subscription } from 'rxjs';
import { TranslationsService } from '../../fid-shared/service/translations.service';
import { SpaceService } from 'app/modules/fid-shared/service/space.service';
import { CampaignFormComponent } from 'app/modules/fid-shared/campaign-form/campaign-form.component';
import { CampaignService } from 'app/modules/fid-shared/service/campaign.service';

@Component({
  selector: 'app-promoted-routes-form',
  templateUrl: './promoted-routes-form.component.html',
  styleUrls: ['./promoted-routes-form.component.css'],
  //providers: [PromotedRoutesService, ConfirmationService],
})
export class PromotedRoutesFormComponent extends EntityFormComponent<Rest.FidSpace> implements OnInit, OnDestroy {

	@ViewChild(CampaignFormComponent, { static: false })
	campaignForm: CampaignFormComponent;
	@Output() spaceSaved = new EventEmitter();

	isDialogVisible: boolean;
	isEditingDisabled: boolean;
	errorMessages: string[];
	spaceFormTitle: string;
	errorContainer: ElementRef;

	editingSubscription: Subscription;
	creatingSubscription: Subscription;
	campaignSavedSubscription: Subscription;
	spaceAddedSubscription: Subscription;
	changesPendingSubscription: Subscription;

	/** variables FidSpace */
	space: Rest.FidSpace;
	selectedSpace: Rest.FidSpace;

	editedCampaign: Rest.FidCampaigns;
	selectedProvider: Rest.FidProvider;
	spaceImageUrl: string;
	spaceImageFile: any;
	spaceEncodedImage: any;
	eventTypeList: Rest.EventTypeRacing[];
	selectedEventType: Rest.EventTypeRacing;
	providerList: Rest.FidProvider[];
	startDate: Date | null;
	endDate: Date | null;

	/* Event Code Type variables */
	eventCodeTypeList: Rest.EventCodeTypeRacing[];
	selectedEventCodeType: Rest.EventCodeTypeRacing;
	generalEventCode: Rest.CodesEvents;
	uniqueEventCodeList: Rest.CodesEvents[];

	/* multilanguage fields */
	translations: Rest.Translations;
	translationSubscription: Subscription;

	// showTargets: boolean;
	showRoutes: boolean;
	changesPending: boolean = false;

   constructor(
      public spaceService: SpaceService,
		public campaignService: CampaignService,
      private authService: AuthenticationService,
      public i18n: I18nService,
      public notificationsService: NotificationsService,
		public translationsService: TranslationsService
   ) {
      super(spaceService, notificationsService, i18n, authService);
      this.errorMessages = [];
		this.isEditingDisabled = false;
   }

   ngOnInit() {
		// console.log("[PROMOTED_ROUTES_FORM] OnInit *****");
   	super.ngOnInit();
		this.editingSubscription = this.spaceService.startedEditing
      .subscribe(
         (item: Rest.FidSpace) => {
				this.isNew = false;
				this.space = item;
				// this.showTargets = true;
				this.showRoutes = true;
			   this.showDialog();
         }
      );
		this.creatingSubscription = this.spaceService.startedCreating
      .subscribe(
         () => {
				this.isNew = true;
				// this.showTargets = false;
				this.showRoutes = false;
			   this.showDialog();
         }
      );
		this.campaignSavedSubscription = this.campaignService.campaignSaved
      .subscribe(
         (item: Rest.FidCampaigns) => {
            this.spaceSaved.emit();
         }
      );
		this.spaceAddedSubscription = this.campaignService.spaceAdded
      .subscribe(
         (item: Rest.FidCampaigns) => {
            this.spaceSaved.emit();
         }
      );
		this.changesPendingSubscription = this.campaignService.changesPending
		.subscribe(
         () => {
				this.changesPending = true;
         }
      );
   }

	ngOnDestroy() {
	 	 this.editingSubscription.unsubscribe();
		 this.creatingSubscription.unsubscribe();
		 this.campaignSavedSubscription.unsubscribe();
		 this.spaceAddedSubscription.unsubscribe();
		 this.changesPendingSubscription.unsubscribe();
	}

	/**
   * showDialog()
   * */
	public showDialog(): void {
		// console.log("[PROMOTED-RUTES-FORM] showDialog ***** ");
		window.scroll({ top: 0, behavior: 'smooth' });
		this.errorMessages = [];
		this.isDialogVisible = true;
   }

	public saveSpace() {
	// 	console.log("[PROMOTED-RUTES-FORM] saveSpace ***** ");
	// 	console.log(this.campaignForm);
		this.campaignForm.saveCampaign();
		this.isDialogVisible = false;
		this.changesPending = false;
	}

}


