import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-gps-antena-conf-form',
  templateUrl: './gps-antena-conf-form.component.html',
  styleUrls: ['./gps-antena-conf-form.component.css'],
})
export class GpsAntenaConfFormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {


  constructor(protected i18n: I18nService) {  super(i18n);}
  
  @Input() deviceParams: Rest.DeviceParameters;
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
  /**
   * Needed to prevent toggle of UI component
   * @param event 
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
   
  }
  
}
