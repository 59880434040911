<!-- TODO: Add "real-time-map-main-container" class into css -->
<div class="container-fluid m-0 custom-panel ">

  <div class="row rt-map-panel-header">

    <div class="col">
      <div class="d-flex align-items-center real-time-map-title">
        <div class="custom-title">{{'real-time.mapTitle' | translate}}</div>
      </div>
    </div>

    <div class="col">
      <div class="d-flex align-items-center justify-content-end gap-3 real-time-map-detach">

        <!-- SETTINGS ICON -->
        <button type="button" class="btn custom-rt-map-settings-button" data-bs-toggle="modal"
                data-bs-target="#rtMapSettings">
          <svg-icon src="assets/icons/settings.svg" class="d-flex flex-wrap"
                    [svgStyle]="{ 'height.px': 25, 'width.px': 20 }"></svg-icon>
        </button>

        <!-- DETACH ICON -->
        <button type="button" class="btn custom-rt-map-detach-button" (click)="detach()">
          <svg-icon src="assets/icons/detach.svg" class="d-flex flex-wrap"
                    [svgStyle]="{'height.px': 25, 'width.px': 20}"></svg-icon>
        </button>

      </div>

    </div>

  </div>

  <hr class="separator mt-0 mb-4 custom-real-time-separator">

  <!-- ROW 2: MAP COMPONENT -->
  <div class="row mt-3 py-0 map-row" (click)=stopFollow() (contextmenu)=stopFollow() (wheel)=stopFollow()>
    <app-custom-map mapId="map-rt"
                    [pois]="pois"
                    [zones]="zones"
                    [options]="customMapOptions"
                    display=true,
    ></app-custom-map>
  </div>

</div>

<!-- REAL TIME MAP SETTINGS MODAL-->
<div class="modal fade" id="rtMapSettings" aria-hidden="true" aria-labelledby="rtMapSettings"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">

    <!-- REAL TIME MAP SETTINGS MODAL CONTENT -->
    <div class="modal-content">

      <!-- REAL TIME MAP SETTINGS MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col">
              <span class="modal-title custom-title" i18n>{{'real-time.mapSettingsModal.title' | translate}}</span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button type="button" class="btn-close m-0 p-0 custom-modal-close-button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- REAL TIME MAP SETTINGS MODAL BODY -->
      <div class="modal-body">
        <div class="container-fluid">

          <!-- TODO: FIX IMPLEMENTATION - UNCOMMENT -->
          <!--ROW 1: MAP PANEL CONFIG & BUTTONS -->
          <div class="row">

            <!-- COL 1: MAP PANEL CONFIG -->
            <div class="col">
              <div class="d-flex align-items-center h-100">
                <label class="form-label mb-0 me-2 custom-label" i18n>{{'real-time.mapSettingsModal.mapPanel' | translate}}:</label>
                <p-dropdown class="custom-input"
                            (onChange)="settingsChanged()"
                            [options]="availableSettings"
                            [(ngModel)]="_selectedSettings"
                            [style]="{'width':'250px'}"
                ></p-dropdown>
              </div>
            </div>

            <!-- COL 2: MAP PANEL BUTTONS -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100 gap-2">
                <button class="btn custom-button custom-map-button" data-bs-target="#realTimeMapEditName" data-bs-toggle="modal"
                        data-bs-dismiss="modal" i18n>
                  {{'general.edit' | translate}}
                </button>
                <button class="btn custom-button custom-map-button" [disabled]="_selectedSettings.id===-1" (click)="delete()" i18n>
                  {{'general.delete' | translate}}
                </button>
              </div>
            </div>

          </div>

          <!-- ROW 2: ZONE GROUPS LABEL -->
          <div class="row text-center mt-5">
            <label class="custom-title custom-rt-map-title">{{'real-time.mapSettingsModal.zoneGroups' | translate}}</label>
          </div>

          <hr class="separator mt-0 mb-3 mt-1 custom-real-time-separator">

          <!-- ROW 3: ZONE GROUPS PAGED PICKLIST -->
          <div class="row">
            <div class="col">
              <paged-picklist [pageFunction]="this.zoneGroupService.getPage.bind(this.zoneGroupService)"
                              labelProperty="name"
                              [(rightListEntities)]="_selectedSettings.visibleZoneGroups"
                              (rightListEntitiesChange)="visibleZoneGroupsChanged($event)"
              ></paged-picklist>
            </div>
          </div>

          <!-- ROW 4: POI CATEGORIES LABEL -->
          <div class="row text-center mt-5">
            <label class="custom-title custom-rt-map-title">{{'real-time.mapSettingsModal.poiCategories' | translate}}</label>
          </div>

          <hr class="separator mt-0 mb-3 mt-1 custom-real-time-separator">

          <!-- ROW 5: POI CATEGORIES PAGED PICKLIST -->
          <div class="row">
            <div class="col">
              <paged-picklist [pageFunction]="this.poiCategoryService.getPage.bind(this.poiCategoryService)"
                              labelProperty="name"
                              [(rightListEntities)]="_selectedSettings.visiblePOICategories"
                              (rightListEntitiesChange)="visiblePOICategoriesChanged($event)"
              ></paged-picklist>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>

<!-- REAL TIME MAP EDIT NAME MODAL -->
<div class="modal fade side-panel-edit-name-modal" id="realTimeMapEditName" aria-hidden="true" aria-labelledby="realTimeMapEditName"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">

    <!-- REAL TIME MAP EDIT NAME MODAL CONTENT -->
    <div class="modal-content">

      <!-- REAL TIME MAP EDIT NAME MODAL HEADER -->
      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col">
              <span class="modal-title custom-title" i18n>{{'real-time.mapSettingsModal.editNameModalTitle' | translate}}</span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button type="button"
                        class="btn-close m-0 p-0 custom-modal-close-button"
                        data-bs-target="#rtMapSettings"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- REAL TIME MAP EDIT NAME MODAL BODY -->
      <div class="modal-body">
        <div class="container-fluid">

          <!-- ROW 1: PANEL NAME -->
          <div class="row mt-3 mb-3">
            <div class="d-flex align-items-center justify-content-center save-panel-name-container">
              <label class="form-label mb-0 me-2 custom-label" i18n>{{'general.name' | translate}}:</label>
              <input type="text" class="form-control custom-input h-45" id="map-panel-settings-name" name="map-panel-settings-name"
                     [(ngModel)]=_selectedSettings.name>

            </div>
          </div>

        </div>
      </div>

      <!-- REAL TIME MAP EDIT NAME MODAL FOOTER -->
      <div class="modal-footer">
        <div class="d-flex align-items-center justify-content-center w-100 gap-3">

          <button class="btn custom-button"
                  (click)=create()
                  data-bs-target="#sidePanelSettings"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  i18n>{{'general.saveAsNew' | translate}}
          </button>

          <button *ngIf="_selectedSettings.id !== -1"
                  class="btn custom-button"
                  (click)=update()
                  data-bs-target="#sidePanelSettings"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  i18n>{{'general.update' | translate}}
          </button>

        </div>
      </div>

    </div>

  </div>
</div>

<app-vehicle-proximity-dialog [vehicleInfo]="VehiclesInfo" (onShowPosition)="showPosition($event)"
                              #VehicleProximityDialog></app-vehicle-proximity-dialog>
<app-zone-form [realTime]=true></app-zone-form>
<app-poi-form [realTime]=true></app-poi-form>