<div id="pre-config-form">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>
  
    <p-panel header="OB22 Parameters">
        <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
            <div class="ui-g-12 ui-md-6">
              <div class="col row dvcFields">
                <div class="col lblStyle">
                  <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                  </form-input>
                </div>
                <div class="col">
                  <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                  [(ngModel)]="selectedPreconfig" [style]="{'width':'100%','min-width':'100px'}" filter="filter"></p-dropdown>
                </div>
              </div>
            </div>
          </div>
  
      <p-tabView #tv (onChange)="tabChanged()">
        <p-tabPanel header="Events">
          <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
            <app-alarm-call-number-times-configuration-ob22 [alarmCallNumberTimes]="deviceParams.proprietaryParameters.alarmCallNumberTimes"></app-alarm-call-number-times-configuration-ob22>
            <app-alarm-over-speed-configuration-ob22 [alarmOverSpeed]="deviceParams.proprietaryParameters.alarmOverSpeed"></app-alarm-over-speed-configuration-ob22>
            <app-angle-configuration-ob22 [angle]="deviceParams.proprietaryParameters.angle"></app-angle-configuration-ob22>
            <app-apn-configuration-ob22 [apn]="deviceParams.proprietaryParameters.apn"></app-apn-configuration-ob22>
            <app-automatic-apnconfiguration-ob22 [automaticApn]="deviceParams.proprietaryParameters.automaticApn"></app-automatic-apnconfiguration-ob22>
            <app-automatic-gmtconfiguration-ob22 [automaticGmt]="deviceParams.proprietaryParameters.automaticGmt"></app-automatic-gmtconfiguration-ob22>
            <app-batch-configuration-ob22 [batch]="deviceParams.proprietaryParameters.batch"></app-batch-configuration-ob22>
            <app-battery-alarm-configuration-ob22 [batteryAlarm]="deviceParams.proprietaryParameters.batteryAlarm"></app-battery-alarm-configuration-ob22>
            <app-delay-voice-monitor-configuration-ob22 [delay]="deviceParams.proprietaryParameters.delay"></app-delay-voice-monitor-configuration-ob22>
            <app-fence-alarm-configuration-ob22 [fenceAlarm]="deviceParams.proprietaryParameters.fenceAlarm"></app-fence-alarm-configuration-ob22>
            <app-gmtconfiguration-ob22 [gmt]="deviceParams.proprietaryParameters.gmt"></app-gmtconfiguration-ob22>
            <app-gprsblocking-alarm-configuration-ob22 [gprsAlarm]="deviceParams.proprietaryParameters.gprsAlarm"></app-gprsblocking-alarm-configuration-ob22>
            <app-gprsconfiguration-ob22 [gprs]="deviceParams.proprietaryParameters.gprs"></app-gprsconfiguration-ob22>
            <app-gpsdata-sending-interval-configuration-ob22 [gpsDataSendingInterval]="deviceParams.proprietaryParameters.gpsDataSendingInterval"></app-gpsdata-sending-interval-configuration-ob22>
            <app-gpsdistance-interval-configuration-ob22 [gpsDistanceInterval]="deviceParams.proprietaryParameters.gpsDistanceInterval"></app-gpsdistance-interval-configuration-ob22>
            <app-heartbeat-interval-configuration-ob22 [heartbeatInterval]="deviceParams.proprietaryParameters.heartbeatInterval"></app-heartbeat-interval-configuration-ob22>
            <app-instruction-password-configuration-ob22 [instructionPassword]="deviceParams.proprietaryParameters.instructionPassword"></app-instruction-password-configuration-ob22>
            <app-lbsupload-configuration-ob22 [lbsUpload]="deviceParams.proprietaryParameters.lbsUpload"></app-lbsupload-configuration-ob22>
            <app-mileage-configuration-ob22 [mileage]="deviceParams.proprietaryParameters.mileage"></app-mileage-configuration-ob22>
            <app-network-link-coordinates-configuration-ob22 [networkLinkCoordinates]="deviceParams.proprietaryParameters.networkLinkCoordinates"></app-network-link-coordinates-configuration-ob22>
            <app-power-alarm-configuration-ob22 [powerAlarm]="deviceParams.proprietaryParameters.powerAlarm"></app-power-alarm-configuration-ob22>
            <app-pull-alarm-configuration-ob22 [pullAlarm]="deviceParams.proprietaryParameters.pullAlarm"></app-pull-alarm-configuration-ob22>
            <app-revise-instruction-password-configuration-ob22 [reviseInstructionPassword]="deviceParams.proprietaryParameters.reviseInstructionPassword"></app-revise-instruction-password-configuration-ob22>
            <app-sends-configuration-ob22 [sends]="deviceParams.proprietaryParameters.sends"></app-sends-configuration-ob22>
            <app-sensor-sensitivity-configuration-ob22 [sensorSensitivity]="deviceParams.proprietaryParameters.sensorSensitivity"></app-sensor-sensitivity-configuration-ob22>
            <app-server-configuration-ob22 [server]="deviceParams.proprietaryParameters.server"></app-server-configuration-ob22>
            <app-smsforwarding-configuration-ob22 [smsForwarding]="deviceParams.proprietaryParameters.smsForwarding"></app-smsforwarding-configuration-ob22>
            <app-sosconfiguration-ob22 [sos]="deviceParams.proprietaryParameters.sos"></app-sosconfiguration-ob22>
            <app-static-data-filtering-configuration-ob22 [staticDataFiltering]="deviceParams.proprietaryParameters.staticDataFiltering"></app-static-data-filtering-configuration-ob22>
            <app-static-last-position-configuration-ob22 [staticRep]="deviceParams.proprietaryParameters.staticRep"></app-static-last-position-configuration-ob22>
            <app-upload-accconfiguration-ob22 [uploadAcc]="deviceParams.proprietaryParameters.uploadAcc"></app-upload-accconfiguration-ob22>
          </p-accordion>
        </p-tabPanel>
  
        <p-tabPanel  header="Sensors">
          <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
          <!-- Sensors list -->
        </p-tabPanel>
        <p-tabPanel i18n-header header="Actuators">
            <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
        </p-tabPanel>
  
      </p-tabView>
    </p-panel>
    <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
  </div>
  