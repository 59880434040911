<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Bearer Settings
                    (AT + GTBSI)</span>
            </div>
        </div>
    </p-header>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="LTE APN">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.lteApn" maxlength="40" class="txtbStyle"/>
                </div>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="LTE APN User Name">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.lteApnUsername" maxlength="30" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="LTE APN Password">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.lteApnPassword" maxlength="30" class="txtbStyle"/>
                </div>
            </div>
        </div>
    
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="GPRS APN">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.gprsApn" maxlength="40" class="txtbStyle"/>
                </div>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="GPRS APN User Name">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.gprsApnUsername" maxlength="30" class="txtbStyle"/>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="GPRS APN Password">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <input type="text" pInputText [(ngModel)]="bsiParameters.gprsApnPassword" maxlength="30" class="txtbStyle"/>
                </div>
            </div>
        </div>
    
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Network Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="networkOptions" [(ngModel)]="bsiParameters.networkMode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="LTE Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="lteOptions" [(ngModel)]="bsiParameters.lteMode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>
    
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="APN Authentication Methods">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="authenticationOptions" [(ngModel)]="bsiParameters.authenticationMethods"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Manual Netreg">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="netregOptions" [(ngModel)]="bsiParameters.manualNetreg"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>
    
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Edrx Periodic">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="bsiParameters.edrxPeriodic" [min]="0" [max]="10485760"></p-spinner>
                    <span class="ui-inputgroup-addon">0~10485760</span>
                    <span class="ui-inputgroup-addon">ms</span>
                </div>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Edrx M1 Pagings">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="bsiParameters.edrxM1Pagings" [min]="0" [max]="2048"></p-spinner>
                    <span class="ui-inputgroup-addon">0~2048</span>
                    <span class="ui-inputgroup-addon">ms (x10)</span>
                </div>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Edrx NB2 Pagings">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="bsiParameters.edrxNB2Pagings" [min]="0" [max]="4096"></p-spinner>
                    <span class="ui-inputgroup-addon">0~4096</span>
                    <span class="ui-inputgroup-addon">ms (x10)</span>
                </div>
            </div>
        </div>
    
    </div>
</p-accordionTab>