<!-- FULLSCREEN LOADING SPINNER: POSITION -> FIXED -->
<ng-container *ngIf='loading && fullScreen'>
    <div class='h-100 loading-outer-container'>
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
            <svg-icon src="assets/spinners/bars.svg"
                      [svgStyle]="{'width.px': width, 'height.px': height}"
            ></svg-icon>
        </div>
    </div>
</ng-container>

<!-- FLEXBOX LOADING SPINNER -->
<ng-container *ngIf='loading && !fullScreen'>
    <div class='d-flex flex-row justify-content-center'>
        <svg-icon src="assets/spinners/bars.svg"
                  [svgStyle]="{'width.px': width, 'height.px': height}"
        ></svg-icon>
    </div>
</ng-container>
