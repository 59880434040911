<p-dialog [responsive]="true" [contentStyle]="{minHeight: '250px'}" [(visible)]="PopUpOpen"
    dismissableMask=true closeOnEscape=true responsive=true modal=true>
    <p-header>

        <h3 class="component_in_line1">
            {{header}}
        </h3>
         <button  id="component_in_line2" class="ui-button-danger" i18n (click)="followVehicleButton()" type='button'>Centrar</button>
    </p-header>

  <!-- TODO: Fix <p-calendar> filter! -->
  <!--<p-calendar [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" (onSelect)="loadData()"  dateFormat={{i18n.dateFormat}} [(ngModel)]="rangeDates" selectionMode="range" readonlyInput="true"></p-calendar>-->
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex-grow-1 m-2">
      <div>
        <label id='startDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
          {{ 'general.startDate' | translate }}:
        </label>
        <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayIniSelected" (onSelect)="loadData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
      </div>
    </div>
  
    <div class="flex-grow-1 m-2">
      <div>
        <label id='endDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
          {{ 'general.endDate' | translate }}:
        </label>
        <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayEndSelected" (onSelect)="loadData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
      </div>
    </div>
    <div>
      <div class="buttonTableExcel text-end">
        <button class="btn btn-success btn-sm" (click)="exportExcel()" pButton type="button" i18n-label label="Excel"></button>
      </div>
    </div>
  </div>
    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />


  <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
    1. Check correct functionality;
    2. Remove unused code;
    3. Add translations;
  -->

  <!-- PrimeNG DataTable -->
  <p-table id="dt" [value]="rows"
           [rowHover]="true"
           [autoLayout]="true"
           [globalFilterFields]="['visitDate']"
           *ngIf="PopUpOpen"
  >

    <!-- Table Column/Header Definition -->
    <ng-template pTemplate="header" let-columns>
      <tr>

        <th *ngFor="let col of cols" [hidden]="!col.visible">
          <div>
            <a  [title]="col.header">{{col.header}}</a>
          </div>

          <div *ngIf="col.field === 'visitDate'">
            <!--<div class="p-d-flex p-jc-between p-ai-center">
              <p-columnFilter type="date" field="date" display="menu"></p-columnFilter>
            </div>-->
          </div>
        </th>

      </tr>
    </ng-template>

    <!-- Table Row Definition -->
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>

        <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">

          <td *ngSwitchCase="'visitDate'" [hidden]="!col.visible">
            <div [title]="rowData.visitDate | date: 'short'" class="overflow ellipsis">
              {{rowData.visitDate | date: 'short'}}
            </div>
          </td>

          <td *ngSwitchCase="'controlPoint'" [hidden]="!col.visible">
            <div [title]="rowData.controlPoint.name" class="overflow ellipsis">
              {{rowData.controlPoint.name}}
            </div>
          </td>

          <td *ngSwitchCase="'endVisitDate'" [hidden]="!col.visible">
            <div [title]="rowData.endVisitDate | date: 'short'" class="overflow ellipsis">
              {{rowData.endVisitDate !== 0 ? (rowData.endVisitDate | date: 'short') : '' }}
            </div>
          </td>

          <td *ngSwitchCase="'durationVisit'" [hidden]="!col.visible">
            <div [title]="rowData.durationVisit" class="overflow ellipsis">
              {{rowData.durationVisit}}
            </div>
          </td>

          <td *ngSwitchCase="'kmVisit'" [hidden]="!col.visible">
            <div [title]="rowData.kmVisit" class="overflow ellipsis">
              {{rowData.kmVisit}}
            </div>
          </td>

        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" >
      <tr>
        <td colspan="7">
          <p i18n>No s'han trobat registres</p>
        </td>
      </tr>
    </ng-template>

  </p-table>

    <!--<p-dataTable [value]="rows" [sortField]="sortField" [sortOrder]="sortOrder" *ngIf="PopUpOpen">
        <p-column *ngFor="let col of cols" [field]="col.field" [header]="col.header" [hidden]="!col.visible">
            <ng-template pTemplate="header" let-col>
                <a [title]="col.header">{{col.header}}</a>
            </ng-template>
            &lt;!&ndash; ___________________________________ROWS___________________________________ &ndash;&gt;
            <ng-template *ngIf="col.field === 'visitDate'" let-row="rowData" pTemplate="body">
                <div [title]="row.visitDate | date: 'short'" class="overflow ellipsis">
                    {{row.visitDate | date: 'short'}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'controlPoint'" let-row="rowData" pTemplate="body">
                <div [title]="row.controlPoint.name" class="overflow ellipsis">
                    {{row.controlPoint.name}}
                </div>
            </ng-template>


        </p-column>
    </p-dataTable>-->
    <p-footer>
        <button pButton type="button" (click)="closeButton()" i18n-label label="Tancar"></button>
    </p-footer>
</p-dialog>
