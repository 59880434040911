<p-accordionTab>

  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Speed Event Configuration</span>
      </div>
      <div class="right">
        <p-inputSwitch   (onChange)="handleChange($event)"  [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.speedEvent.enabled>
    
    <div class="ui-g-12 ui-md-6">
      <div class="ui-g-4">
      </div>
      <div class="ui-g-8">
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="minimum speed" [errors]="errors.minimumSpeed">
        <div class="ui-inputgroup">
          <p-spinner id="minimumSpeed" size="5" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.minimumSpeed" [min]="0" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">0~255</span>
          <span class="ui-inputgroup-addon" i18n>km/h</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="maximum speed" [errors]="errors.maximumSpeed">
        <div class="ui-inputgroup">
          <p-spinner id="maximumSpeed" size="5" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.maximumSpeed" [min]="0" [max]="255"></p-spinner>
          <span class="ui-inputgroup-addon">0~255</span>
          <span class="ui-inputgroup-addon" i18n>km/h</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="speeding mode" [errors]="errors.speedingMode">
        <p-dropdown (onChange)="speedingModeChange()" [options]="availablespeedingmode" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.speedingMode"
          [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="speed duration" [errors]="errors.speedDuration">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="speedDuration" size="7" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.speedDuration" min={{minSpeedingDuration}}
            max={{maxSpeedingDuration}}></p-spinner>
          <span class="ui-inputgroup-addon">{{minSpeedingDuration}}~{{maxSpeedingDuration}}</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="off speeding duration" [errors]="errors.offSpeedingDuration">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." [disabled]="disableOffSpeedingDuration" id="offSpeedingDuration" size="7" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.offSpeedingDuration"
            [min]="0" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">0~65535</span>
          <span class="ui-inputgroup-addon" i18n>seconds</span>
        </div>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="output control" [errors]="errors.outputcontrol">
        <p-inputSwitch (onChange)="speedEventOutputControlChange($event)" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.outputControl"></p-inputSwitch>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="output port" [errors]="errors.outputport">
        <p-dropdown (onChange)="speedEventOutputPortChange($event)" [disabled]="!deviceParams.proprietaryParameters.speedEvent.outputControl" [options]="availableoutputport"
          [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.outputPort" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="Led pattern" [errors]="errors.ledPatternIndex">
          <p-dropdown [options]="avaibleLedPatterns" [(ngModel)]="deviceParams.proprietaryParameters.speedEvent.ledPatternIndex"
            [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
  </div>

</p-accordionTab>