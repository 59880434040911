<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Add translations;
 -->

<!-- PrimeNG DataTable -->
<p-table [value]="vehicleInfoArr"
         [rows]="10"
         dataKey="vehicleName"
         [totalRecords]="vehicleInfoArr.length"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
         class="custom-table"
         styleClass="p-datatable-striped"
>

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      Vehicle data table
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- ROW EXPANSION COLUMN -->
      <th style="width: 3rem" *ngIf="vehicleInfoArr.length > 0">

      </th>

      <!-- VEHICLE NAME COLUMN -->
      <th pSortableColumn="vehicleName" *ngIf="settings.showVehicleName">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Vehicle
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="vehicleName"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- FLEET NAME COLUMN -->
      <th pSortableColumn="fleetName" *ngIf="settings.showVehicleName">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Fleet
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="fleeteName"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- GROUP NAME COLUMN -->
      <th pSortableColumn="groupName" *ngIf="settings.showVehicleGroupName">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Group
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="groupName"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- ADDRESS COLUMN -->
      <th pSortableColumn="address" *ngIf="settings.showAddress">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Address
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="address"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- SPEED COLUMN -->
      <th pSortableColumn="speed" *ngIf="settings.showSpeeed">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Speed
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="speed"></p-sortIcon>
          </div>

        </div>
      </th>

      <th pSortableColumn="vehicleMileage">
        <div class="d-flex align-items-center h-100">

          <div class="d-flex align-items-center justify-content-center flex-fill h-100">
            Mileage
          </div>

          <div class="d-flex align-items-center h-100 pe-2">
            <p-sortIcon field="vehicleMileage"></p-sortIcon>
          </div>

        </div>
      </th>

      <!-- REMOVE VEHICLE COLUMN -->
      <th style="width: 3rem" *ngIf="vehicleInfoArr.length > 0">

      </th>

    </tr>
  </ng-template>

  <!-- TABLE ROW DEFINITION -->
  <ng-template pTemplate="body" let-vehicle let-expanded="expanded">
    <tr class="custom-rows">
      <td class="p-0 button-column">

        <div class="d-flex align-items-center justify-content-center">

          <button class="btn custom-expand-button" type="button" [pRowToggler]="vehicle">
            <svg-icon [ngClass]="{'rotated': !expanded}"
                      class="d-flex m-0 p-0 custom-expand-button-icon"
                      src="assets/icons/select-down.svg"
                      [svgStyle]="{'height.px': 20, 'width.px': 20}"
            ></svg-icon>
          </button>

        </div>

      </td>

      <td>
        <span class="p-column-title">Vehicle</span>
        {{vehicle.vehicleName}}
      </td>

      <td>
        <span class="p-column-title">Fleet</span>
        {{vehicle.fleetName}}
      </td>

      <td>
        <span class="p-column-title">Group</span>
        {{vehicle.groupName}}
      </td>

      <td>
        <span class="p-column-title">Address</span>
        {{vehicle.address}}
      </td>

      <td>
        <span class="p-column-title">Speed</span>
        {{vehicle.speed|distance:true}}/h
      </td>

      <td>
        <span class="p-column-title">Mileage</span>
        {{vehicle.vehicleMileage|distance:true}}
      </td>

      <td class="p-0 button-column">
        <div class="d-flex align-items-center justify-content-center h-100">
          <button class="btn custom-table-remove-button" (click)="remove(vehicle)">
            <svg-icon [ngClass]="{'rotated': !expanded}"
                      class="d-flex m-0 p-0 custom-table-remove-button-icon"
                      src="assets/icons/close.svg"
                      [svgStyle]="{'height.px': 20, 'width.px': 20}"
            ></svg-icon>
          </button>
        </div>
      </td>

    </tr>
  </ng-template>

  <!-- TABLE ROW EXPANSION DEFINITION -->
  <ng-template pTemplate="rowexpansion" let-vehicleInfo>
    <tr class="row-expansion">

      <td colspan="8">

        <div class="p-p-3">
          <app-vehicle-details  *ngIf="settings.showMap"  [vehicle]=vehicleInfo.vehicle [settings]=settings></app-vehicle-details>
        </div>
        
        
        <div>
          <app-vehicle-telemetry *ngIf="settings.showTelemetry" [vehicle]=vehicleInfo.vehicle [settings]=settings></app-vehicle-telemetry>
          <!--<app-vehicle-telemetry *ngIf="settings.view==='TELEMETRY'" [vehicle]=vehicle [settings]=settings></app-vehicle-telemetry>-->
        </div>

        <div>
          <app-quick-actions *ngIf="settings.showQuickActions" [vehicle]=vehicleInfo.vehicle [settings]=settings></app-quick-actions>
          <!--<app-quick-actions *ngIf="settings.view==='QUICK_ACTIONS'" [vehicle]=vehicle [settings]=settings></app-quick-actions>-->
        </div>
        
      </td>
    </tr>
  </ng-template>

  <!-- TABLE EMPTY MESSAGE DEFINITION -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message">
          No vehicles selected
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>
