import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService, PrimeNGConfig, SelectItem } from 'primeng/api';
import { ExcelService } from "app/services/excel-service/excel.service";
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { Subscription } from 'rxjs';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { DatePipe } from '@angular/common';
import { CecoService } from 'app/rest/ceco.service';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
	selector: 'app-inventory-vehicles-table',
	templateUrl: './inventory-vehicles-table.component.html',
	styleUrls: ['./inventory-vehicles-table.component.css'],
	providers: [CecoService]
})
export class InventoryVehiclesTableComponent extends EntityListComponent<Rest.Vehicle> implements OnInit, OnDestroy {

	@Input() driver?: Rest.InvDriver = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;
	@Input() nested?: Boolean = false;

	user: Rest.User;
	EnabledFilter: SelectItem[];

	/** Params **/
	isNew: boolean = false;
	selectedVehicle: Rest.Vehicle;
	firstLoad: boolean = true;

	/** Vehicle Inventory GET_PAGE FILTERS **/
	selectedClient: number = 0; //394971; <--CIRSA

	brandList: Rest.VehicleBrand[] = [];
	modelList: Rest.VehicleModel[] = [];
	versionList: Rest.InvVehicleVersion[] = [];
	vehicleTypeList: Rest.VehicleType[] = [];
	vehicleStatusList: Rest.VehicleStatus[] = [];
	companiesList: Rest.BillingAccount[] = [];
	delegationList: Rest.Delegation[] = [];
	subdelegationList: Rest.Subdelegation[] = [];
	updateListSubscription: Subscription;
	driversList: Rest.InvDriver[] = [];
	cecoList: Rest.Ceco[] = [];
	vehicleTableFilters = {}

	// PERMISSIONS
	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isVehicleStateGranted: boolean = false;

	startDateCreated: Date;
	endDateCreated: Date;
	startDateDisabled: Date;
	endDateDisabled: Date;

	constructor(
		private invVehicleService: InvVehicleService,
		protected confirmationService: ConfirmationService,
		protected notificationsService: NotificationsService,
		protected i18n: I18nService,
		protected authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		private translateService: TranslateService,
		protected excelService: ExcelService,
		protected vehiclebrandService: VehiclebrandService,
		protected vehiclemodelService: VehiclebrandService,
		protected vehicleversionService: VehiclebrandService,
		private delegationService: DelegationService,
		private subdelegationService: SubdelegationService,
		private driverService: DriverService,
		private datePipe: DatePipe,
		private config: PrimeNGConfig,
		protected cecoService: CecoService,
	) {
		super(
			invVehicleService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime,
			excelService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.selectedClient = this.authenticationService.user.client.id;

		//set inital filters
		this.vehicleTableFilters = {
			'enabled': [{ value: true }]
		}
		//this.paginationRequest.filters["enabled"] = ['true'];
		this.config.setTranslation({
			apply: this.translateService.instant('general.filters.apply'),
			clear: this.translateService.instant('general.filters.clear'),
			//translations
		});

		this.updateListSubscription = this.invVehicleService.vehicleSaved
			.subscribe(
				() => {
					this.refresh();
				}
			);
		this.getBrandsByClient(this.selectedClient);
		this.getModelsByClient(this.selectedClient);
		this.getVersionsByClient(this.selectedClient);
		this.getVehicleTypes();
		this.getVehicleStatus(this.selectedClient);
		this.getDrivers(this.selectedClient);
		this.getCompanies(this.selectedClient);
		this.getDelegationList(this.selectedClient);
		this.getCecoList();
		this.selectedClient = this.authenticationService.user.client.id;

		this.setPermissions();
	}

	ngOnDestroy(): void {
		this.updateListSubscription.unsubscribe();
	}

	afterLoad() {
		this.paginationResult.entities.forEach((cl: any) => {
			console.log(cl);
			/*Permissions */
			cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
			cl._$update = true;
			/** */
		});
		/*Permissions */
		this.checkSelectAll();


		this.paginationResult.entities = this.paginationResult.entities.sort(
			(a, b) => a.name.localeCompare(b.name)
		);

	}

	protected beforeLoad() {
		this.paginationRequest.filters["client"] = [this.selectedClient.toString()];
		if (this.driver) this.paginationRequest.filters["driverId"] = [this.driver.id.toString()];
		if (this.startDateCreated) this.paginationRequest.filters["startDateCreated"] = [this.startDateCreated.toISOString()];
		if (this.endDateCreated) this.paginationRequest.filters["endDateCreated"] = [this.endDateCreated.toISOString()];
		if (this.startDateDisabled) this.paginationRequest.filters["startDateDisabled"] = [this.startDateDisabled.toISOString()];
		if (this.endDateDisabled) this.paginationRequest.filters["endDateDisabled"] = [this.endDateDisabled.toISOString()];
	}

	public createVehicle(): void {
		console.log("[VEHICLE-TABLE] createVehicle *** ");
		this.selectedVehicle = null;
		this.isNew = false;
		this.invVehicleService.startedCreating.next();
	}

	public editVehicle(vehicle: Rest.Vehicle): void {
		console.log("[VEHICLE-TABLE] editVehicle *** ");
		console.log(vehicle);
		if (!this.isFormGranted) {
			// If not granted, simply return without doing anything,
			// effectively disabling the functionality.
			return;
		}
		this.selectedVehicle = vehicle;
		this.isNew = false;
		this.invVehicleService.startedEditing.next(this.selectedVehicle);
	}

	setPermissions() {
		// console.log("[VEHICLE-FORM] setPermissions *** ");
		const user = this.authenticationService.user;
		const rolesForForm = ['VEHICLES_READ', 'VEHICLES_UPDATE'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('VEHICLES_CREATE', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);

		this.isVehicleStateGranted = this.authenticationService.isRoleGranted('INV_VEHICLE_STATE_READ', user);
		// console.log("this.isCreateGranted: " + this.isCreateGranted);
		// console.log("this.isFormGranted: " + this.isFormGranted);
	}

	excelColumns = {};
	exportExcel() {
		this.setExcelColumns();
		let fileNameTitle = this.translateService.instant('tableManagement.vehicle.vehiclesManagement');
		super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
	}

	setExcelColumns() {
		const name = (entity: any) => entity.name ?? ''
		const brand = (entity: any) => entity.model?.brand?.name ?? ''
		const model = (entity: any) => entity.model?.name ?? ''
		const version = (entity: any) => entity.version?.name ?? ''
		const modelType = (entity: any) => entity.model?.type?.name ?? ''
		const frameNumber = (entity: any) => entity.frameNumber ?? ''
		const vehicleStatus = (entity: any) => entity.status?.code ?? ''
		const billingAccount = (entity: any) => entity.billingAccount?.name ?? ''

		this.excelColumns = {
			[this.translateService.instant('control-panel.vehicles.licensePlate')]: { bindField: name },
			[this.translateService.instant('tableManagement.brands.brand')]: { bindField: brand },
			[this.translateService.instant('tableManagement.models.model')]: { bindField: model },
			[this.translateService.instant('tableManagement.versions.version')]: { bindField: version },
			[this.translateService.instant('tableManagement.models.type')]: { bindField: modelType },
			[this.translateService.instant('control-panel.vehicles.frameNumber')]: { bindField: frameNumber },
			[this.translateService.instant('tableManagement.vehicleStatus.vehicleStatus')]: { bindField: vehicleStatus },
			[this.translateService.instant('tableManagement.companies.company')]: { bindField: billingAccount }
		}
	}

	public getBrandsByClient(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService
				.getBrands(clientId)
				.then(
					function (response) {
						that.brandList = response;
						that.brandList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getModelsByClient(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService.getModelsByClient(clientId)
				.then(
					function (response) {
						that.modelList = response;
						that.modelList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVersionsByClient(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService.getVersionsByClient(clientId)
				.then(
					function (response) {
						that.versionList = response;
						that.versionList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleTypes() {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService.getVehicleTypes()
				.then(
					function (response) {
						that.vehicleTypeList = response;
						that.vehicleTypeList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getVehicleStatus(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService.getVehicleStatus(clientId)
				.then(
					function (response) {
						that.vehicleStatusList = response;
						that.vehicleStatusList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getDrivers(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.driverService.getList(clientId)
				.then(
					function (response) {
						that.driversList = response;
						that.driversList.sort((a, b) => a.driverAssignments[0]?.driver.user.name > b.driverAssignments[0]?.driver.user.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getCompanies(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.invVehicleService.getBillingAccounts(clientId)
				.then(
					function (response) {
						that.companiesList = response;
						that.companiesList.sort((a, b) => a.name > b.name ? 1 : -1);
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getDelegationList(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.delegationService
				.getList(clientId)
				.then(
					function (response) {
						that.delegationList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getSubdelegationList(clientId: number) {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.subdelegationService
				.getListByClient(clientId)
				.then(
					function (response) {
						that.subdelegationList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}


	getCecoList() {
		let cecoFilterRequest = {
			sortBy: null,
			sortAsc: true,
			pageSize: 2147483647,
			filters: {},
			loadPermissions: false
			//offset: event.first // número de pàgina (lazyload)
			//pageSize: event.rows // número de files (lazyload)
		} as unknown as Rest.ListPaginationRequest;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.cecoService.getPage(cecoFilterRequest)
				.then(
					function (response) {
						that.cecoList = response.entities;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}




}

