<p-dialog i18n-header header="Report" [(visible)]="_display" [responsive]="true" showEffect="fade"
          [modal]="true" *ngIf="_display">

    <div class="ui-grid ui-grid-responsive ui-fluid">
        <div class="ui-grid-row">

            <div class="ui-grid-col-12">
                    <p-dropdown (onChange)="initMap()" [options]="mapModes" [(ngModel)]="mapMode"></p-dropdown>
            </div>

        </div>
        <div class="ui-grid-row" >
                <div class="ui-grid-col-12" id="single-map" tabindex="-1">
                        <app-custom-map mapId="vehicle-report-map" [display]="mapMode==='SINGLE'" [options]="customMapOptions" ></app-custom-map>
                </div>
        </div>
        <div class="ui-grid-row">
                <div class="ui-grid-col-12" class="events">

                        <div class="ui-grid-row" *ngFor="let event of report.events" (click)="eventClickedInList(event)" [tabindex]="event.$id" [id]="'event-'+event.$id">
                                <div class="ui-grid-col-3">
                                        <app-custom-map class="small-map" [mapId]="'m-'+event.$id" [pois]="pois[event.$id]"  [options]="customMapOptions" [display]="mapMode==='PER_EVENT'"  [iconVehicle]="icon" ></app-custom-map>
                                </div>
                                <div class="ui-grid-col-3">
                                        <p><span i18n>Event</span>:&nbsp;<strong>event.type</strong></p>
                                </div>
                                <div class="ui-grid-col-3">
                                    <p><span i18n>Start</span>:&nbsp;<strong>{{event.s|usertime}}</strong></p>
                                </div>
                                <div class="ui-grid-col-3">
                                    <p><span i18n>End</span>:&nbsp;<strong>{{event.e|usertime}}</strong></p>
                                </div>

                                <div class="ui-grid-col-3">
                                        <p><span i18n>Duration</span>:&nbsp;<strong>{{(event.e-event.s)/(1000*60)}}'</strong></p>
                                </div>

                        </div>


                </div>
        </div>

    </div>
</p-dialog>
