import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { RoutePoiService } from 'app/modules/fid-shared/service/route-poi.service';
import { RacingService } from 'app/rest/racing.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-poi-routes-form',
  templateUrl: './poi-routes-form.component.html',
  styleUrls: ['./poi-routes-form.component.css']
})

export class PoiRoutesFormComponent implements OnInit, OnDestroy {
	@ViewChild('errorContainer') errorContainer: ElementRef;
	@Input() route: Rest.FidRoute;
	//@Input() selectedPoi: Rest.FidRoutePOI;
	selectedPoi: Rest.FidRoutePOI;
	selectedPoiIconName: string;
	@Output() poiAdded = new EventEmitter<any>();
	@Output() poiSaved = new EventEmitter<any>();
	editPoiSubscription: Subscription;
	createPoiSubscription: Subscription;

	createSubscription: Subscription;

	poiRouteFormTitle: string;
	isDialogVisible: boolean;
	loading: boolean;
	isNew: boolean;
	showCoordinates: boolean;

	clientId: number;
	poi: Rest.FidRoutePOI;

	poiFormErrorMessages: string[];

	poiCoordinates: any;
	poiIcons: Rest.IconRacing[];

	selectedPoiIcon: Rest.IconRacing;

	uploadImageIconUrl: string;
	poiLogo: any;

	constructor(
		 public routePoiService: RoutePoiService,
		 public notificationsService: NotificationsService,
		 private auth: AuthenticationService,
		 public i18n: I18nService,
		 private translate: TranslateService,
		 public racingService: RacingService
	) {
      //super(routePoiService, notificationsService, i18n, auth);
		//console.log("[POI-ROUTES-FORM] constructor ***");
		this.poiRouteFormTitle = '';
		this.isDialogVisible = false;
		this.loading = false;
		this.isNew = false;
		this.clientId = this.auth.user.client.id;

		this.poiFormErrorMessages = [];
		this.poiCoordinates = null;

		this.poiIcons = [];
	}

	ngOnInit(): void {
		// console.log("[POI-ROUTES-FORM] ngOnInit ***");

		/* Retrieve POI icons from server */
      this.racingService
         .getPoiIcons(this.clientId)
         .then((response) => {
            this.poiIcons = response;
				// console.log(this.poiIcons);
            // this.selectedPoiIcon = this.poiIcons[0];
         })
         .catch(() => {
            this.notificationsService.add(
               Severity.error,
               'Error',
               this.translate.instant('error.get.poiIcons') + '!',
            );
         });

		this.editPoiSubscription = this.routePoiService.startedEditing
    	  .subscribe(
         	(item: Rest.FidRoutePOI) => {
					this.selectedPoi = item;
					this.selectedPoiIcon = this.selectedPoi.itemIcon;
					let i = this.poiIcons.findIndex((x) => { return (x.id === this.selectedPoiIcon.id);});
					this.selectedPoiIconName = this.poiIcons[i].nameIcon;
					// console.log(this.selectedPoiIcon)
					// console.log();
					this.showDialog(false, true);
	      	});
		this.createPoiSubscription = this.routePoiService.startedCreating
    	  .subscribe(
         	(item: {showCoordinates: boolean, coordinates: any}) => {
					// console.log(item.showCoordinates);
					// console.log(item.coordinates);
					// console.log(this.route);
					this.showDialog(true, item.showCoordinates, item.coordinates);
	      	});
	}

	ngOnDestroy() {
		this.createPoiSubscription.unsubscribe();
		this.editPoiSubscription.unsubscribe();
	}

	// ngOnChanges() {
	// 	console.log("[POI-ROUTES-FORM] ngOnChanges ***");
	// 	console.log(this.selectedPoi);
	// 	this.infoPoi();
	// }

	/**
	 * showDialog()
	 * */
	public showDialog(isNew: boolean, showCoordinates: boolean, coordinates?: any): void {
		// console.log("[POI-ROUTES-FORM] showDialog ***");
		this.isNew = isNew;
		this.poiCoordinates = coordinates ? coordinates : null;
		this.showCoordinates = showCoordinates;
		// console.log("isNew: " + this.isNew);
		// console.log(this.selectedPoi);
		if (this.isNew) {
			 this.initPoi();
			 this.poiRouteFormTitle = this.translate.instant('general.create') + ' POI';
		} else {
			this.infoPoi();
			this.poiRouteFormTitle = this.translate.instant('general.edit') + ' POI';
		}
		this.isDialogVisible = true;
	}

	/**
	 * initPoi()
	 * */
	public initPoi(): void {
		// console.log("[POI-ROUTES-FORM] initPoi ***");
		this.poi = {
			id: 0,
			name: '',
			description: '',
			link1: '',
			link2: '',
			longitude: this.poiCoordinates ? this.poiCoordinates.getLon() : 0,
			latitude: this.poiCoordinates ? this.poiCoordinates.getLat() : 0,
			enabled: true,
			imagePoi: null,
			itemIcon: this.selectedPoiIcon,
			route: { id: this.route.id }
		};
		// console.log(this.poi);
		// console.log(this.selectedPoiIcon);
		this.uploadImageIconUrl = 'assets/icons/no-image-available.svg';
	}

	public infoPoi(): void {
		// console.log("[POI-ROUTES-FORM] infoPoi ***");
		if (this.selectedPoi) {
			/* If the POI has an image, set uploadImageIconUrl to NULL to disable the div containing the default no image icon */
			this.uploadImageIconUrl = this.selectedPoi.imagePoi ? null : 'assets/icons/no-image-available.svg';
			this.selectedPoiIcon = this.selectedPoi.itemIcon;
			this.poi = {
				id: this.selectedPoi.id,
				name: this.selectedPoi.name,
				description: this.selectedPoi.description,
				link1: this.selectedPoi.link1,
				link2: this.selectedPoi.link2,
				longitude: this.selectedPoi.longitude,
				latitude: this.selectedPoi.latitude,
				enabled: this.selectedPoi.enabled,
				imagePoi: this.selectedPoi.imagePoi,
				itemIcon: this.selectedPoi.itemIcon,
				route: {
					id: this.route.id,
			  },
			};
			// console.log(this.selectedPoiIcon);
		}
	}

	/**
	 * validatePoiForm()
	 * */
	private validatePoiForm(): boolean {
		 if (this.poi.name === '') {
			  this.poiFormErrorMessages.push(
					this.translate.instant('error.required-field.name'),
			  );
			  return false;
		 }
		 return true;
	}

	/**
	 * savePoi()
	 * */
	public savePoi(): void {
		// console.log("[POI-ROUTES-FORM] savePoi ***");
		// console.log("this.isNew: " + this.isNew);
		// console.log(this.uploadImageIconUrl);
		// console.log(this.poi.imagePoi);
		if (this.validatePoiForm()) {
			/* Create custom POI structure to send to API */
			let poiData = {
				id: this.isNew ? 0 : this.poi.id,
				name: this.poi.name,
				description: this.poi.description,
				link1: this.poi.link1,
				link2: this.poi.link2,
				longitude: this.poi.longitude,
				latitude: this.poi.latitude,
				enabled: true,
				imagePoi: null,
				itemIcon: { id: this.selectedPoiIcon.id }, // <-- IconRacing
				route: { id: this.route.id }
			};

			/* If POI is updated with new image */
         if (!this.isNew && this.poi.imagePoi) {
            poiData.imagePoi = {
               id: this.poi.imagePoi.id,
            };
         }
			// console.log(poiData);

			/* Stringify the custom POI structure */
			let poiString = JSON.stringify(poiData);
			// console.log(poiString);

			  /* Create new POI */
			  if (this.isNew) {
					this.routePoiService.addPoi(poiString, this.poiLogo).then((poi) => {
						if (poi) {
							poi.itemIcon.iconId.imageEncoded = this.selectedPoiIcon.iconId.imageEncoded;
							poi.imagePoi.imageEncoded = this.uploadImageIconUrl;
							this.poiAdded.emit(poi);
							this.isDialogVisible = false;
						}
					});
			  } else {
					this.routePoiService.updatePoi(poiString, this.poiLogo).then((poi) => {
						if (poi) {
							poi.itemIcon = this.selectedPoiIcon;
							if (this.uploadImageIconUrl !== null) poi.imagePoi.imageEncoded = this.uploadImageIconUrl;
							// console.log(this.uploadImageIconUrl);
							// console.log(this.poi.imagePoi);
							this.poiSaved.emit(poi);
							this.isDialogVisible = false;
						 }
					});
			  }
		 }
	}

	/**
	 * onImageChange()
	 * */
	public onImageChange(event: any): void {

		// console.log("[POI-ROUTES-FORM] onImageChange ***");
		// console.log(event);
		const imageReader = new FileReader();
		this.poiLogo = event.target.files[0];
		if (event.target.files && event.target.files.length) {
			imageReader.onload = (readerEvent: any) => {
				this.uploadImageIconUrl = readerEvent.target.result;
				this.poi.imagePoi = null;
			};
			imageReader.readAsDataURL(event.target.files[0]);
		}
		// console.log(this.uploadImageIconUrl);

	}

	public onChangeIconItem(event) {
		// console.log("[POI-ROUTES-FORM] onChangeIconItem ***");
		this.selectedPoiIcon = event.value;
		let i = this.poiIcons.findIndex((x) => { return (x.id === this.selectedPoiIcon.id);});
		this.selectedPoiIconName = this.poiIcons[i].nameIcon;
		//this.scrollIntoView();
		// console.log(this.selectedPoiIcon);
		// console.log(this.selectedPoiIconName);
		// console.log(event);
	}

	/**************************************************/
	/*** dialog tools *********************************/
	/**************************************************/
	private scrollIntoView(): void {
		this.errorContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
   }

}
