import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { EntityService } from './entity.service';
@Injectable()
export class SimPlanGroupsService extends EntityService<Rest.SimPlanGroups> {
   client: Rest.RestApplicationClient;
   constructor(private jquery: JQueryHTTPClient) {
      super();
      this.client = new Rest.RestApplicationClient(jquery);
   }

   getSimGroupsByOperatorType(type: string, queryParams?: { type?: number }): Rest.RestResponse<Rest.SimPlanGroups[]> {
      return this.client.SimPlanGroups_getSimGroupsByOperatorType(type, queryParams);
   }
}
