<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >SOS function (AT + GTSOS)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Speed Alarm Mode" >
            <p-dropdown (onChange)="trackEventModeChange(sosParameters.modeSos,$event)" [options]="modeOptions"
            [(ngModel)]="sosParameters.modeSos" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
        </div>

        <div [class.disableDIV]=!trackModeSwitch>
        
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Digital Input ID" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="sosParameters.digitalInputID" (onChange)="onChange()" [min]="0" [max]="5"></p-spinner>
              <span class="ui-inputgroup-addon">0~5</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="SOS Number" >
            <div class="ui-inputgroup">
              <input type="text" pInputText [(ngModel)]="sosParameters.sosNumber"/>
            </div>
          </form-input>
        </div>
      </div>
      </div>
</p-accordionTab>
