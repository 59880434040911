import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "./JQueryHTTPClient";
import { EntityService } from "./entity.service";
import { Rest } from "./rest_client";

@Injectable()
export class WayToPayService extends EntityService<Rest.WayToPay> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.WayToPay): Rest.RestResponse<Rest.WayToPay> {
    try {
      return this.client.WayToPay_create(arg0);
    } catch (error) {
      console.error("Error creating cardType:", error);
      throw error;
    }
  }

  update(arg0: Rest.WayToPay): Rest.RestResponse<Rest.WayToPay> {
    return this.client.WayToPay_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.WayToPay>> {
    return this.client.WayToPay_getPage(arg0, queryParams);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.WayToPay_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.WayToPay> {
    return this.client.WayToPay_find(arg0, queryParams);
  }
}