<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GPS-Assisted
                    Motion Measurement (AT+GTGAM)</span>
            </div>
        </div>
    </p-header>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="modeOptions" [(ngModel)]="gamParameters.mode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Speed Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="speedOptions" [(ngModel)]="gamParameters.speedMode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>

    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Motion Speed Threshold">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="gamParameters.motionSpeedThreshold" [min]="0"
                        [max]="50"></p-spinner>
                    <span class="ui-inputgroup-addon">0~50</span>
                    <span class="ui-inputgroup-addon">km/h</span>
                </div>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Motion Cumulative Time">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="gamParameters.motionCumulativeTime" [min]="10"
                        [max]="100"></p-spinner>
                    <span class="ui-inputgroup-addon">10~100</span>
                    <span class="ui-inputgroup-addon">sec</span>
                </div>
            </div>
        </div>
    
    </div>

    <div class="row align-items-center">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Motionless Cumulative Time">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="gamParameters.motionlessCumulativeTime" [min]="10"
                        [max]="250"></p-spinner>
                    <span class="ui-inputgroup-addon">10~250</span>
                    <span class="ui-inputgroup-addon">sec</span>
                </div>
            </div>
        </div>
    
        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="GPS Fix Failure Timeout">
                </form-input>
            </div>
            <div class="col">
                <div class="ui-inputgroup">
                    <p-spinner size="5" step="1" [(ngModel)]="gamParameters.failureTimeout" [min]="5"
                        [max]="1800"></p-spinner>
                    <span class="ui-inputgroup-addon">5~1800</span>
                    <span class="ui-inputgroup-addon">sec</span>
                </div>
            </div>
        </div>
    
    </div>
</p-accordionTab>