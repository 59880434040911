import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Message } from "primeng/api";
import { FileUpload } from "primeng/fileupload";

import { PoicategoryService } from "../../../rest/poicategory.service";
import { IconService } from '../../../rest/icon.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-add-icons-form",
  templateUrl: "./add-icons-form.component.html",
  styleUrls: ["./add-icons-form.component.css"],
  providers: [PoicategoryService, IconService],
})
export class AddIconsFormComponent {
  @Input() display: boolean;
  @Output() closeAddIcon: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @ViewChild("uf") fileUpload: FileUpload;
  @Input("entity") entityCategory: string;

  categoryService: PoicategoryService;
  iconService: IconService;

  uploadedFiles: any[] = [];
  msgs: Message[];
  files: any[] = [];

  constructor(
    categoryService: PoicategoryService,
    iconService: IconService,
    public notificationsService: NotificationsService,
    private translate: TranslateService,
  ) {
    this.categoryService = categoryService;
    this.iconService = iconService;
  }

  onHide(event) {
    this.display = false;
    this.closeAddIcon.emit(false);
  }

  onUpload(event): void {
    const formData = new FormData();

    //global parameters for all type of iconEntities
    formData.append('myfile[]', event.files[0]);
    formData.append('entity',this.entityCategory);
    console.log(formData);
    if(this.entityCategory == "POI_CATEGORY"){
      //no additional parameters for POI_CATEGORY
    }
    
    this.iconService.uploadIcon(formData).then((res) =>{
      this.notificationsService.add(Severity.success, this.translate.instant('icons.title'), this.translate.instant('icons.successUpload'));
      this.closeAddIcon.emit(true);
    }).catch((e) => {
      this.notificationsService.add(Severity.error, this.translate.instant('icons.title'), this.translate.instant('icons.failedUpload'))
  });
  }
}
