import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Rest } from '../../../../../../rest/rest_client';
import { AuthenticationService } from '../../../../../../core/services/authentication/authentication.service';
import { I18nService } from '../../../../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../../../../services/rest-client-extension';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { InsuranceService } from 'app/modules/vehicle-inventory/services/table-management/insurance.service';
import { InsuranceFormComponent } from '../insurance-form/insurance-form.component';

import Insurance = Rest.Insurance;
import { ActivatedRoute } from '@angular/router';
import { UploadFileFormComponent } from '../../../documents/upload-file-form/upload-file-form.component';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { InsuranceQuarterlyCalculationComponent } from '../insurance-quarterly-calculation/insurance-quarterly-calculation.component';
import { ReportComponent } from '../report/report.component';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
@Component({
	selector: 'app-insurance-list',
	templateUrl: './insurance-list.component.html',
	styleUrls: ['./insurance-list.component.css'],
	providers: [ConfirmationService, UserTimePipe, ExcelService],
	encapsulation: ViewEncapsulation.None,
})
export class InsuranceListComponent extends EntityListComponent<Insurance> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;
	@Input() nested?: Boolean = false;

	@Input() showTitle: boolean = true;
	colspan: number;

	title: string = this.i18n._('Insurance list');

	@ViewChild(InsuranceFormComponent, { static: true })
	editForm: InsuranceFormComponent;
	@ViewChild(UploadFileFormComponent) uploadFileForm!: UploadFileFormComponent;
	@ViewChild(InsuranceQuarterlyCalculationComponent)
	insuranceQuarterlyCalculation: InsuranceQuarterlyCalculationComponent;
	@ViewChild(ReportComponent) reportComponent: ReportComponent;
	fileFormTitle = '';

	user: Rest.User;
	EnabledFilter: SelectItem[];

	vehicleId: any = null;

	renewalType = 'INSURANCE';

	refreshListSuscription: Subscription;

	// PERMISSIONS
	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isDisabledGranted: boolean = false;
	canSeeCosts: boolean = false;

	constructor(
		private insuranceService: InsuranceService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		protected route: ActivatedRoute,
		protected translateService: TranslateService,
		protected excelService: ExcelService
	) {
		super(
			insuranceService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime
		);

		this.insuranceService = insuranceService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.colspan = this.nested ? 9 : 10;
		this.form = this.editForm;
		this.hasEntityFilters = false;
		this.loadFilters = false;

		this.vehicleId = this.route.snapshot.params['vehicleId'];
		//  console.log("vehicleId: " + this.vehicleId);

		this.refreshListSuscription = this.insuranceService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				//console.log(" ____ REFRESH FINANCING LIST _____");
				this.getList(item.startDate, item.endDate);
			}
		);

		this.setPermissions();
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['INV_INSURANCE_READ', 'INV_INSURANCE_UPDATE'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_CREATE', user);
		this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_INSURANCE_DISABLE', user);
		this.canSeeCosts = this.authenticationService.isRoleGranted('INV_INSURANCE_COSTS_READ', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	editDialog(event) {
		if (!this.isFormGranted) {
			return;
		}

		if (!this.isSelectableColumn) {
			this.onRowDblClick({ data: event });
		}

		this.isSelectableColumn = false;
		this.isColumn = true;
	}

	beforeLoad(): void {
		this.paginationRequest.sortBy = "startDate";
		this.paginationRequest.sortAsc= false;
		if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
		if (this.paginationRequest.filters.hasOwnProperty('disabled')) {
			this.paginationRequest.filters['disabled'] =
				this.paginationRequest.filters['disabled'][0].toString() == 'true' ? ['false'] : ['true'];
		}
		//console.log(this.paginationRequest.filters);
	}

	public getList(startDate: Date, endDate: Date) {
		this.startDate = startDate;
		this.endDate = endDate;
		this.beforeLoad();
		super.refresh();
	}

	afterLoad() {
		//console.log('+++', this.paginationResult.entities);
		this.checkSelectAll();
	}

	//UPLOAD FILE
	showFileForm() {
		this.fileFormTitle = this.translateService.instant('tableManagement.insurances');
		this.renewalType = 'INSURANCE';
		this.uploadFileForm.showDialog();
	}

	//QUARTERLY CALCULATION
	showQuarterlyCalculation() {
		this.insuranceQuarterlyCalculation.showDialog();
	}


	showReports() {
		this.reportComponent.showDialog();
	}

	excelColumns = {};

	exportExcel() {
		this.setExcelColumns();
		let fileNameTitle = this.translateService.instant('tableManagement.insurance.insuranceTypeManagement');
		super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
	}

	setExcelColumns() {
		const policyNumber = (entity: any) => entity.policyNumber ?? ''
		const agent = (entity: any) => entity.agent ?? ''
		const cost = (entity: any) => entity.cost ?? ''
		const observations = (entity: any) => entity.observations ?? ''
		const vehicle = (entity: any) => entity.vehicle?.name ?? ''
		const type = (entity: any) => entity.type?.name ?? ''
		const startDate = (entity: any) => entity.startDate ?? ''
		const endDate = (entity: any) => entity.endDate ?? ''
		const disabledDate = (entity: any) => entity.disabledDate ?? ''
		this.excelColumns = {
			[this.translateService.instant('tableManagement.insurance.policyNumber')]: { bindField: policyNumber },
			[this.translateService.instant('tableManagement.insurance.agent')]: { bindField: agent },
			[this.translateService.instant('tableManagement.insurance.cost')]: { bindField: cost },
			[this.translateService.instant('drivers.table.observations')]: { bindField: observations },
			[this.translateService.instant('tableManagement.vehicle.vehicle')]: { bindField: vehicle },
			[this.translateService.instant('general.type')]: { bindField: type },
			[this.translateService.instant('tableManagement.financing.start')]: { bindField: startDate },
			[this.translateService.instant('tableManagement.financing.end')]: { bindField: endDate },
			[this.translateService.instant('tableManagement.accessories.disabledDate')]: { bindField: disabledDate },
		}
	}

	duplicate(entities) {
		this.editForm.duplicate(entities[0]);
	}
}
