import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { WayToPayService } from 'app/rest/way_to_pay.service';

@Component({
  selector: 'app-way-to-pay-form',
  templateUrl: './way-to-pay-form.component.html',
  styleUrls: ['./way-to-pay-form.component.css'],
    providers: [WayToPayService],
    encapsulation: ViewEncapsulation.None,
})
export class WayToPayFormComponent
    extends EntityFormComponent<Rest.WayToPay>
    implements OnInit {

    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;


    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private objectService: WayToPayService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(objectService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof WayToPayFormComponent
     */
    beforeShow() {

        this.active = !this.entity.disabled;


        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.wayToPay.createWayToPay');
        } else {
            this.title = this.translateService.instant('tableManagement.wayToPay.editWayToPay');
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
    }
}