<!-- FIRST VERSION --- VEHICLE PANEL QUICK ACTIONS MAIN CONTAINER -->
<!--<div class="container-fluid p-0 ">
  <div class="row m-0">
    <div class="container p-0 h-100 quick-actions-view-inner-container">

      &lt;!&ndash; ROW 1: CENTER IN MAP BUTTON && REQUEST POSITION BUTTON && SETTINGS BUTTON && REPORT BUTTON &ndash;&gt;
      <div class="row ms-3 me-3 mt-3 mb-0">

        &lt;!&ndash; COL 1: CENTER IN MAP BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="center()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-marker mb-2" aria-hidden="true"></i>
                <span>Center in map</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 2: REQUEST POSITION BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="requestPosition()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-o mb-2" aria-hidden="true"></i>
                <span>Request position</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 3: SETTINGS BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="config()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-cog mb-2" aria-hidden="true"></i>
                <span>Settings</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 4: REPORT BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="report()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-file-text-o mb-2" aria-hidden="true"></i>
                <span>Report</span>
              </span>
            </button>
          </div>
        </div>

      </div>

      &lt;!&ndash; ROW 2: SHOW ROUTE BUTTON && SEND MESSAGE BUTTON && IO BUTTON && ALERTS BUTTON &ndash;&gt;
      <div class="row ms-3 me-3 mt-0 mb-0">

        &lt;!&ndash; COL 1: SHOW ROUTE BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="showRoute()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-o mb-2" aria-hidden="true"></i>
                <span>Show route</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 2: SEND MESSAGE BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="sendMessage()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-comments mb-2" aria-hidden="true"></i>
                <span>Send message</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 3: IO BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="io()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-eye mb-2" aria-hidden="true"></i>
                <span>IO</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 4: ALERTS BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="alerts()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-exclamation-triangle mb-2" aria-hidden="true"></i>
                <span>Alerts</span>
              </span>
            </button>
          </div>
        </div>

      </div>

      &lt;!&ndash; ROW 3: SEND LOCATION BUTTON && CALL BUTTON && MAINTENANCES BUTTON && CALENDAR BUTTON &ndash;&gt;
      <div class="row ms-3 me-3 mt-0 mb-0">

        &lt;!&ndash; COL 1: SEND LOCATION BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="sendLocation()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-signs mb-2" aria-hidden="true"></i>
                <span>Send location</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 2: CALL BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="call()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-phone mb-2" aria-hidden="true"></i>
                <span>Call</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 3: MAINTENANCES BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="showMaintenances()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-adjust mb-2" aria-hidden="true"></i>
                <span>Maintenances</span>
              </span>
            </button>
          </div>
        </div>

        &lt;!&ndash; COL 4: CALENDAR BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="calendar()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-calendar mb-2" aria-hidden="true"></i>
                <span>Calendar</span>
              </span>
            </button>
          </div>
        </div>

      </div>

      &lt;!&ndash; ROW 4: ZONES BUTTON &ndash;&gt;
      <div class="row row-cols-4 ms-3 me-3 mt-0 mb-0">

        &lt;!&ndash; COL 1: ZONES BUTTON &ndash;&gt;
        <div class="col p-3">
          <div class="d-flex justify-content-center quick-actions-button-container">
            <button class="btn custom-button custom-quick-action-button" (click)="zones()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-square-o mb-2" aria-hidden="true"></i>
                <span>Zones</span>
              </span>
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>-->


<!-- SECOND VERSION --- VEHICLE PANEL QUICK ACTIONS MAIN CONTAINER -->
<div class="container-fluid p-0 ">
  <div class="row m-0">
    <div class="container p-0 h-100 quick-actions-view-inner-container">
      <div class="row row-cols-auto justify-content-center ms-3 me-3 mt-3 mb-0">

        <!-- COL 1: CENTER IN MAP BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="center()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-marker mb-2" aria-hidden="true"></i>
                <span>Center in map</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 2: REQUEST POSITION BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="requestPosition()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-o mb-2" aria-hidden="true"></i>
                <span>Request position</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 3: SETTINGS BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="vehicleSettings()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-cog mb-2" aria-hidden="true"></i>
                <span>Settings</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 4: REPORT BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="report()" [disabled]="true">
              <span class="row d-flex align-items-center">
                <i class="fa fa-file-text-o mb-2" aria-hidden="true"></i>
                <span>Report</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 5: SHOW ROUTE BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="showRoute()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-o mb-2" aria-hidden="true"></i>
                <span>Show route</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 6: SEND MESSAGE BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="sendMessage(sendMessageModal['sendMessageModal'])">
              <span class="row d-flex align-items-center">
                <i class="fa fa-comments mb-2" aria-hidden="true"></i>
                <span>Send message</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 7: IO BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="showIOModal(ioModal['ioModal'])">
              <span class="row d-flex align-items-center">
                <i class="fa fa-eye mb-2" aria-hidden="true"></i>
                <span>IO</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 8: ALERTS BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="alerts()" [disabled]="true">
              <span class="row d-flex align-items-center">
                <i class="fa fa-exclamation-triangle mb-2" aria-hidden="true"></i>
                <span>Alerts</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 9: SEND LOCATION BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="sendLocation()" [disabled]="true">
              <span class="row d-flex align-items-center">
                <i class="fa fa-map-signs mb-2" aria-hidden="true"></i>
                <span>Send location</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 10: CALL BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="showCallBackModal(callBackModal['callBackModal'])">
              <span class="row d-flex align-items-center">
                <i class="fa fa-phone mb-2" aria-hidden="true"></i>
                <span>Call</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 11: MAINTENANCES BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="showMaintenances()">
              <span class="row d-flex align-items-center">
                <i class="fa fa-adjust mb-2" aria-hidden="true"></i>
                <span>Maintenances</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 12: CALENDAR BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="calendar()" [disabled]="true">
              <span class="row d-flex align-items-center">
                <i class="fa fa-calendar mb-2" aria-hidden="true"></i>
                <span>Calendar</span>
              </span>
            </button>
          </div>
        </div>

        <!-- COL 13: ZONES BUTTON -->
        <div class="col p-3">
          <div class="d-flex justify-content-center">
            <button class="btn custom-button custom-quick-action-button" (click)="zones()" [disabled]="true">
              <span class="row d-flex align-items-center">
                <i class="fa fa-square-o mb-2" aria-hidden="true"></i>
                <span>Zones</span>
              </span>
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<!-- SEND MESSAGE MODAL -->
<app-sms #sendMessageModal [vehicleId]=vehicle.id></app-sms>

<!-- IO MODAL -->
<app-io #ioModal [vehicle]=vehicle></app-io>

<!-- CALL BACK MODAL -->
<app-call-back #callBackModal [vehicleId]=vehicle.id></app-call-back>
