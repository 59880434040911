<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Tow Alarm
          Configuration (AT + GTTOW)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Tow Enable">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="towOptions" [(ngModel)]="towParameters.modeTow"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Engine Off to Tow">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="EngineOffToTow" size="2" [(ngModel)]="towParameters.engineOffToTow" [min]="5"
                  [max]="900">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~900</span>
                <span class="ui-inputgroup-addon">S</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Fake Tow Delay">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="FakeTowDelay" size="7" [(ngModel)]="towParameters.fakeTowDelay" [min]="0"
                  [max]="10">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~600</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Tow Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="TowInterval" size="7" [(ngModel)]="towParameters.towInterval" [min]="30"
                  [max]="86400">
                </p-spinner>
                <span class="ui-inputgroup-addon">30~86400 </span>
                <span class="ui-inputgroup-addon">seconds</span>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Tow Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="MotionThreshold" size="2" [(ngModel)]="towParameters.outputId" [min]="0"
                  [max]="1">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="towParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Duration">
              </form-input>
            </div>
            <div class="col">
              <div *ngIf="firmware > 5" class="ui-inputgroup">
                <p-spinner step="1" id="duration" size="7" [(ngModel)]="towParameters.duration" [min]="0" [max]="1800">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~1800 </span>
                <span class="ui-inputgroup-addon">(x100ms)</span>
              </div>

              <div *ngIf="5 >= firmware" class="ui-inputgroup">
                <p-spinner step="1" id="duration" size="7" [(ngModel)]="towParameters.duration" [min]="0" [max]="1800">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~255</span>
                <span class="ui-inputgroup-addon">(x100ms)</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Toggle Times">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="duration" size="7" [(ngModel)]="towParameters.toggleTimes" [min]="0"
                  [max]="255">
                </p-spinner>
                <span class="ui-inputgroup-addon">0~255 </span>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Motion Sensor
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Rest Duration">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner step="1" id="RestDuration" size="2" [(ngModel)]="towParameters.restDuration" [min]="1"
                [max]="255">
              </p-spinner>
              <span class="ui-inputgroup-addon">1~255</span>
              <span class="ui-inputgroup-addon">(×15sec)</span>
            </div>
          </div>
        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Motion Duration">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="MotionDuration" size="2" [(ngModel)]="towParameters.motionDuration" [min]="1"
                  [max]="10">
                </p-spinner>
                <span class="ui-inputgroup-addon">1~10</span>
                <span class="ui-inputgroup-addon">(×100ms)</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Motion Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="MotionThreshold" size="2" [(ngModel)]="towParameters.motionThreshold" [min]="1"
                  [max]="9">
                </p-spinner>
                <span class="ui-inputgroup-addon">1~9</span>
              </div>
            </div>
          </div>

        </div>

      </p-accordionTab>
    </div>

  </div>

</p-accordionTab>