import { Component, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService } from "primeng/api";
import { RuleService } from "../../../rest/rule.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { RuleFormComponent } from "../rule-form/rule-form-component";

@Component({
    selector: "app-rule-list",
    templateUrl: "./rule-list.component.html",
    styleUrls: ["./rule-list.component.css"],
    providers: [RuleService, ConfirmationService, UserTimePipe],
})
export class RuleListComponent extends EntityListComponent<Rest.Rule> {
    @ViewChild(RuleFormComponent, { static: true }) ruleForm: RuleFormComponent;

    ruleService: RuleService;

    constructor(
        ruleService: RuleService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            ruleService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.ruleService = ruleService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._("Rules");
        this.fetchEntityBeforeShowUpdateForm = true;
        this.form = this.ruleForm;
        this.hasEntityFilters = true;
        /*Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    afterLoad() {
        this.disableSelectAll = !this.user.canManageRules;
    }

    selectAll(select: boolean) {
        if (select) {
            // eslint-disable-next-line @typescript-eslint/no-shadow, prefer-const
            for (let a in this.paginationResult.entities) {
                if (this.user.canManageRules === true) {
                    if (
                        this.selectedEntities.some(
                            (x) => x === this.paginationResult.entities[a]
                        ) === false
                    ) {
                        this.selectedEntities.push(this.paginationResult.entities[a]);
                    }
                    this.entitiesSelected[this.paginationResult.entities[a]["id"]] =
                        select;
                }
            }
        } else {
            for (let i = this.selectedEntities.length - 1 ; i >= 0 ; i--) {
                this.selectedEntities.splice(i, 1);
            }
            // eslint-disable-next-line guard-for-in, prefer-const
            for (let a in this.entitiesSelected) {
                this.entitiesSelected[a] = select;
            }
        }
    }
}
