<!-- NEW DIALOG -->
<p-dialog
	*ngIf="isDialogVisible"
	header="{{ statsFormTitle | uppercase }}"
	[(visible)]="isDialogVisible"
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	[closeOnEscape]="false"
	[style]="{ width: '80vw' }"
>
	<!-- FORM ERRORS -->
	<div #errorContainer>
		<div *ngIf="errorMessages.length !== 0">
			<div *ngFor="let error of errorMessages">
				<div class="alert alert-warning" role="alert">{{ error }}</div>
			</div>
		</div>
	</div>

	<div *ngIf="loading">
		<mat-spinner [diameter]="30" strokeWidth="5" [color]="primary"> </mat-spinner>
	</div>

	<div *ngIf="!loading">
		<!-- PANEL: CONTRATO  -->
		<div class="d-flex flex-column gap-3 p-3">
			<p-panel header="{{ 'tableManagement.financing.contract' | translate }}" class="w-100">
				<!-- SECCION 1 -->
				<div class="d-flex row-cols-3 w-100">
					<div class="d-flex align-items-center">
						<form-input
							fieldName="{{ 'tableManagement.financing.start' | translate }}"
							[style]="{ 'margin-right': '50px' }"
						>
							<input
								pInputText
								[readonly]="true"
								[ngModel]="stats.contrato ? (stats.contrato.startDate | date : 'dd/MM/yyyy') : 'N/A'"
								[style]="{ 'width': '150px' }"
							/>
						</form-input>
					</div>
					<div class="d-flex ps-3 align-items-center">
						<form-input
							fieldName="{{ 'tableManagement.financing.end' | translate }}"
							[style]="{ 'margin-right': '50px' }"
						>
							<input pInputText [readonly]="true" [ngModel]="stats.contrato ? (stats.contrato.endDate | date : 'dd/MM/yyyy') : 'N/A'"
							[style]="{ 'width': '150px' }"/>
						</form-input>
					</div>
					<div class="d-flex ps-3 align-items-center">
						<form-input
							fieldName="{{ 'tableManagement.financing.contractKms' | translate }}"
							[style]="{ 'margin-right': '50px' }"
						>
							<input pInputText [readonly]="true" [ngModel]="stats.contrato !== null ? (stats.contrato.contractKm | number: '1.0') : 'N/A'"
							[style]="{ 'width': '150px' }"/>
						</form-input>
					</div>
				</div>
			</p-panel>
		</div>

		<!-- PANELES KM REAL + INFO CONTROL -->
		<div class="d-flex flex-column gap-3 p-3">
			<div class="d-flex row-cols-2 w-100">
				<!-- PANEL KM REAL -->
				<div class="d-flex align-items-stretch">
					<p-panel header="{{ 'tableManagement.mileage.realKms' | translate }} (CEPSA)" class="w-100">
						<div class="div-table">
							<div class="div-table-row">
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col" [style]="{ 'width': '200px' }">
									<strong>{{ 'general.date' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kms' | translate }}</strong>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.real' | translate }}</strong>
								</div>
								<div class="div-table-col" [style]="{ 'width': '200px' }">
									<input
										pInputText [readonly]="true"
										[ngModel]="stats.lastAU ? (stats.lastAU.readingDate | date : 'dd/MM/yyyy HH:mm:ss') : 'N/A'"
										[style]="{ 'width': '190px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastAU !== null ? (stats.lastAU.kms | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<!-- PANEL INFO CONTROL -->
				<div class="d-flex ps-3 align-items-stretch">
					<p-panel header="{{ 'tableManagement.mileage.infoControl' | translate }}" class="w-100">
						<div class="div-table">
							<div class="div-table-row">
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">
									<strong>{{ 'control-panel.maintenance-plans.months' | translate }}</strong>
								</div>
								<div class="div-table-col">&nbsp;</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.usefulLifeCto' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true"
										[ngModel]="stats.usefulLifeMonths !== null ? (stats.usefulLifeMonths | number: '1.0') : 'N/A'"
										[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">&nbsp;</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.desviation' | translate }}</strong>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.usefulLifeKms' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true"
										[ngModel]="stats.usefulLifeKms !== null ? (stats.usefulLifeKms | number: '1.0') : 'N/A'"
										[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText 	[readonly]="true"
										[ngModel]="stats.usefulLifeDesviation !== null ? (stats.usefulLifeDesviation | number: '1.2-2') : 'N/A'"
										[style]="{ 'width': '150px' }"/>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
			</div>
		</div>

		<!-- PANELES VERIFICACION KMS + CUMPLIR CTO -->
		<div class="d-flex flex-column gap-3 p-3">
			<div class="d-flex row-cols-2 w-100">
				<!-- PANEL VERIFICACION KMS -->
				<div class="d-flex align-items-stretch">
					<p-panel header="{{ 'tableManagement.mileage.kmVerification' | translate }}" class="w-100">
						<div class="div-table">
							<div class="div-table-row">
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">
									<strong>{{ 'general.date' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kms' | translate }}</strong>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.real' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText [readonly]="true"
										[ngModel]="stats.lastCO ? (stats.lastCO.readingDate | date : 'dd/MM/yyyy') : 'N/A'"
										[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastCO !== null ? (stats.lastCO.kms | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.theoric' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText	[readonly]="true"
										[ngModel]="stats.lastCO ? (stats.lastCO.readingDate | date : 'dd/MM/yyyy') : 'N/A'"
										[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.kmTheoric !== null ? (stats.kmTheoric | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.desviation' | translate }}</strong>
								</div>
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true"
										[ngModel]="stats.kmsDesviation !== null ? (stats.kmsDesviation | number: '1.2-2') : 'N/A'"
										[style]="{ 'width': '150px' }"
									/>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<!-- PANEL CUMPLIR CTO  -->
				<div class="d-flex ps-3 align-items-stretch">
					<p-panel header="{{ 'tableManagement.mileage.mustBe' | translate }}" class="w-100">
						<div class="div-table">
							<div class="div-table-row">
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kmDay' | translate }} </strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kmMonth' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kmEndCto' | translate }}</strong>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.kmsDay !== null ? (stats.kmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }" />
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.kmsMonth !== null ? (stats.kmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.kmsEndCto !== null ? (stats.kmsEndCto | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
			</div>
		</div>

		<!-- PANEL MEDIAS KMS -->
		<div class="d-flex flex-column gap-3 p-3">
			<div class="d-flex row-cols-1 w-100">
				<!-- PANEL MEDIAS KMS -->
				<div class="d-flex align-items-stretch">
					<p-panel header="{{ 'tableManagement.mileage.averagesKm' | translate }}" class="w-100">
						<div class="div-table">
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">&nbsp;</div>
								<div class="div-table-col">
									<strong>{{ 'general.date' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.days' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kmDay' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<strong>{{ 'tableManagement.mileage.kmMonth' | translate }}</strong>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">
									<strong>{{ 'tableManagement.mileage.sinceLastAssignment' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText [readonly]="true" [ngModel]="stats.lastAssignmentDate !== null ? (stats.lastAssignmentDate | date : 'dd/MM/yyyy') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastAssignmentDays !== null ? (stats.lastAssignmentDays | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastAssignmentKmsDay !== null ? (stats.lastAssignmentKmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastAssignmentKmsMonth !== null ? (stats.lastAssignmentKmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">
									<strong>{{ 'tableManagement.mileage.sinceLastMonth' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText [readonly]="true" [ngModel]="stats.lastMonthDate !== null ? (stats.lastMonthDate | date : 'dd/MM/yyyy') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastMonthDays !== null ? (stats.lastMonthDays | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastMonthKmsDay !== null ? (stats.lastMonthKmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastMonthKmsMonth !== null ? (stats.lastMonthKmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">
									<strong>{{ 'tableManagement.mileage.sinceLastTrim' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText [readonly]="true" [ngModel]="stats.lastTrimDate !== null ? (stats.lastTrimDate | date : 'dd/MM/yyyy') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastTrimDays !== null ? (stats.lastTrimDays | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastTrimKmsDay !== null ? (stats.lastTrimKmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.lastTrimKmsMonth !== null ? (stats.lastTrimKmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">
									<strong>{{ 'tableManagement.mileage.historic' | translate }}</strong>
								</div>
								<div class="div-table-col">
									<input pInputText [readonly]="true" [ngModel]="stats.htcDate !== null ? (stats.htcDate | date : 'dd/MM/yyyy') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.htcDays !== null ? (stats.htcDays | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.htcKmsDay !== null ? (stats.htcKmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.htcKmsMonth !== null ? (stats.htcKmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
							<div class="div-table-row">
								<div class="div-table-col" [style]="{ width: '300px' }">
									<strong>{{ 'tableManagement.mileage.average' | translate }}</strong>
								</div>
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">&nbsp;</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.averageKmsDay !== null ? (stats.averageKmsDay | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
								<div class="div-table-col">
									<input pInputText  [readonly]="true" [ngModel]="stats.averageKmsMonth !== null ? (stats.averageKmsMonth | number: '1.0') : 'N/A'"
									[style]="{ 'width': '150px' }"/>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
			</div>
		</div>
	</div>

	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class="d-flex flex-row-reverse bd-highlight">
			<div class="p-2 bd-highlight">
				<!-- btn CERRAR -->
				<button type="cancel" class="btn custom-button" (click)="onCloseButton()">
					{{ 'general.close' | translate }}
				</button>
			</div>
		</div>
	</p-footer>

</p-dialog>
