import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-import-navbar',
  templateUrl: './data-import-navbar.component.html',
  styleUrls: ['./data-import-navbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataImportNavbarComponent implements OnInit {
  url: string;
  manageDevices: boolean;
  manageVehicles: boolean;
  manageMaintenancePlans: boolean;
  manageZones: boolean;
  managePOI: boolean;
  manageNotificationGroups: boolean;
  manageRules: boolean;
  manageClients: boolean;
  manageUsers: boolean;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.url = this.router.url;
    this.manageDevices = auth.user.canManageDevices;
    this.manageVehicles = auth.user.canManageFleets;
    this.manageMaintenancePlans = auth.user.canManageMaintenancePlans;
    this.manageZones = auth.user.canManageZoneGroups;
    this.managePOI = auth.user.canManagePOICategories;
    this.manageNotificationGroups = auth.user.canManageNotificationGroups;
    this.manageRules = auth.user.canManageRules;
    this.manageClients = auth.user.canManageClients;
    this.manageUsers = auth.user.canManageUsers;
    /*
    this.router.events.subscribe((r: any) => {
        if (r.url === '/control-panel') {
            this.getDefaultRoute();
        }
    });*/
  }

  ngOnInit(): void {
      this.getDefaultRoute();
  }

  getDefaultRoute(): void {
    this.router.navigate(['/dataImport/cards']);           
    // if (this.manageDevices) {
    // } else if (this.manageVehicles) {
    //     this.router.navigate(['/tableManagement/']);
    // } else if (this.manageMaintenancePlans) {
    //     this.router.navigate(['/tableManagement/maintenancePlans']);
    // } else {
    //     this.router.navigate(['/tableManagement']);
    // }
  }

}
