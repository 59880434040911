import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { PhaseChangeEvent } from 'app/modules/vehicle-inventory/services/inventory-reports.service';

/**
 * Represents the wizard component for generating reports.
 * This component allows users to select different phases of report generation
 * and submits the selected phase to the backend service for processing.
 *
 * @implements OnInit to initialize component data.
 */
@Component({
  selector: 'app-reports-wizard',
  templateUrl: './reports-wizard.component.html',
  styleUrls: ['./reports-wizard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsWizardComponent implements OnInit {

  /**
   * The currently selected phase in the report wizard.
   * Defaults to 1, indicating the initial phase.
   */
  selectedPhase: number = 1;
  reportData: PhaseChangeEvent;

  reportType: string;

  formPhase2Data: any;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Handles phase change events emitted by child components or services.
   * @param event The phase change event containing the new phase.
   */
  onPhaseChange(event: PhaseChangeEvent): void {
    switch (event.phase) {
      case 1:
        //Handle phase 1 events
        break;
      case 2:
        //Handle phase 2 events
        if (event.reportData) {
          this.reportData = event;
        }
        break;
      case 3:
        this.reportData = event;
        break;
      default:
        break;
    }
    // Update the current phase
    this.selectedPhase = event.phase;
  }

}
