import { RestExt } from '../services/rest-client-extension';
import { Rest_logistics } from '../modules/task/Logistics/services/rest_client_logistics';
import { Rest_task } from 'app/modules/task/administration/services/rest_client_task';

export namespace Rest {
	export interface ListPaginationRequest extends PaginationRequest {
		filters: { [index: string]: string[] };
		sortBy: string;
		loadPermissions: boolean;
		context: Context;
	}
	/*
	export interface GenericResp extends Trimmable, WithId {
		ok: boolean;
		error: error;
		} */

	export interface GenericResp<T = any> extends Trimmable, WithId {
		ok: boolean;
		error: error;
		data?: T;
	}

	export interface error extends Trimmable, WithId {
		error_code: number;
		error_param: string;
		errorException: string;
	}

	export interface BillingAccount extends Trimmable, WithId {
		name: string;
		client: Client;
		enabled: boolean;
		cif: string;
		code: string;
		population: string;
		country: string;
		province: string;
		postalCode: string;
		address: string;
	}

	export interface Client extends Trimmable, WithId {
		owner: User;
		name: string;
		enabled: boolean;
		billingAccountList: BillingAccount[];
		clientLogo: Icon;
		clientconfiguration: Rest_logistics.clientconfiguration;
	}

	/*************************************************/
	/*****  INVENTORY INTERFACES ********* ini..  ****/
	/*************************************************/
	export interface Card extends Trimmable, WithId {
		number: string;
		type: CardType;
		vehicle: Vehicle;
		assignDate: Date;
		deassignDate: Date;
		expirationDate: Date;
		disabled: boolean;
		observations: string;
		client: Client;
	}

	export interface CardType extends Trimmable, WithId {
		code: string;
		name: string;
		provider: Provider;
		image: Rest.Icon;
		codeProductLimit: string;
		productLimit: number;
		operationLimit: number;
		disabled: boolean;
		disabledDate: Date;
	}

	export interface FinanceType extends Trimmable, WithId {
		code: string;
		name: string;
		ownVehicle: boolean;
		disabled: boolean;
		disabledDate: Date
	}

	export interface RentingType extends Trimmable, WithId {
		name: string;
		code: string;
		disabled: boolean;
		isdefault: boolean;
		client: Client;
	}

	export interface Financing extends Trimmable, WithId {
		contract: string;
		interestRate: number;
		contractKm: number;
		monthlyFee: number;
		startDate: Date;
		endDate: Date;
		investmentCost: number;
		excessCost: number;
		buyBackValue: number;
		kmAdjustment: number;
		pffValue: number;
		optionsValue: number;
		extrasValue: number;
		taxBaseValue: number;
		vatValue: number;
		totalValue: number;
		cancellationDate: Date;
		cancellationKm: number;
		cancellationValue: number;
		amortizationPeriod: number;
		observations: string;
		disabled: boolean;
		document: InvDocument[];
		replacedVehicle: Vehicle;
		wayToPay: WayToPay;
		vehicle: Vehicle;
		provider: Provider;
		financingType: FinanceType;
		rentingType: RentingType;
		client: Client;
	}

	export interface FineStatus extends Trimmable, WithId {
		name: string;
		disabled: boolean;
		color: string;
		client: Client;
	}

	export interface FineReasons extends Trimmable, WithId {
		reason: string;
		disabled: boolean;
		client: Client;
	}

	export interface Fine extends Trimmable, WithId {
		disabled: boolean;
		vehicle: Vehicle;
		driver: InvDriver;
		observations: string;
		amount: number;
		bonusAmount: number;
		fileNumber: string;
		reason: FineReasons;
		issuer: string;
		responsible: User;
		dgtPoints: number;
		sanctionDate: Date;
		notificationDate: Date;
		notificationMethod: string;
		identityRequestDate: Date;
		identityDate: Date;
		identificationMethod: string;
		paymentDate: Date;
		paidByDelegation: boolean;
		finesStatus: FineStatus;
		document: InvDocument[];
	}

	export interface Tax extends Trimmable, WithId {
		disabled: boolean;
		description: string;
		payDate: Date;
		vehicle: Vehicle;
		taxImport: number;
		document: InvDocument[];
	}

	export interface WayToPay extends Trimmable, WithId {
		code: string;
		name: string;
		disabled: boolean;
		isdefault: boolean;
		disabledDate: Date;
	}

	export interface RealState extends Trimmable, WithId {
		society: string;
		contractNumber: string;
		contractDenomination: string;
		currency: string;
		startDate: Date;
		endDate: Date;
		bpCode: string;
		amount: number;
		wayToPay: WayToPay;
		ceco: Ceco;
		interestType: string;
		financing: Financing;
		disabled: boolean;
	}

	export interface Insurance extends Trimmable, WithId {
		agent: string;
		policyNumber: string;
		cost: number;
		fleetCost: number;
		startDate: Date;
		endDate: Date;
		observations: string;
		document: InvDocument[];
		disabled: boolean;
		disabledDate: Date;
		disabledUser: User;
		vehicle: Vehicle;
		provider: Provider;
		insuranceType: InsuranceType;
		components: InsuranceComponent[];
		client: Client;
	}

	export interface InsuranceComponent extends Trimmable, WithId {
		description: string;
		startDate: Date;
		endDate: Date;
		cost: number;
		fleetCost: number;
		disabled: boolean;

		insurance: Insurance;

		client: Client;
	}

	export interface InsuranceType extends Trimmable, WithId {
		code: string;
		name: string;
		disabled: boolean;
		disabledDate: Date;
	}

	export interface InsuranceQuarterlyParameters extends Trimmable {
		since: Date;
		until: Date;
		insuranceType: InsuranceType;
		provider: Provider;
	}

	export interface ProviderType extends Trimmable, WithId {
		name: string;
		code: string;
	}

	export interface CardConsumption extends Trimmable, WithId {
		card: Card;
		amount: number;
		consumptionDate: Date;
		liters: number;
		kms: number;
		difKms: number;
		difDays: number;
		difConsumption: number;
		kmsCost: number;
		literCost: number;
		vehicle: Vehicle;
		cardBilling: CardBilling;
		consumptionType: string;
		place: string;
		placeCity: string;
		placeCode: string;
		grossAmount: number;
		discount: number;
		vat: number;
		billingName: string;
		consumptionRef: string;
		productCode: string;
		corpCode: string;
		transactionCode: string;
		inputType: CardConsumptionInputType;
		renwalFiles: RenewalFiles;
	}

	export interface CardBilling extends Trimmable, WithId {
		card: Card;
		billingDate: Date;
		provider: Provider;
		city: string;
		document: InvDocument;
		consumptions: CardConsumption[];
	}

	export interface CardConsumptionSummary extends Trimmable {
		lastMonth: InfoConsumptionSummary;
		lastQuarter: InfoConsumptionSummary;
		historic: InfoConsumptionSummary;
	}

	export interface InfoConsumptionSummary extends Trimmable {
		importValue: number;
		liters: number;
		kms: number;
		costLiters: number;
		costKms: number;
	}

	export interface RenewalFiles extends Trimmable, WithId {
		user: User;
		dateFileUpdate: Date;
		renewalFilesType: RenewalFilesType;
		file: Icon;
		client: Client;
	}

	export interface Accessory extends Trimmable, WithId {
		code: string;
		name: string;
		client: Client;
		disabled: boolean;
		disabledDate: Date;
		createUser: User;
		createDate: Date;
		updateUser: User;
		updateDate: Date;
		disableUser: User;
		fullName: String;
	}

	export interface Provider extends Trimmable, WithId {
		image: Icon;
		name: string;
		type: ProviderType;
		providerParameters: ProviderBonusParameters;
		disabled: boolean;
		client: Client;
	}

	export interface ProviderBonusParameters extends Trimmable {
		type: providerTypeEnum;
	}

	export type providerTypeEnum = 'COMBUSTIBLE' | 'VIAT' | 'SEGUROS' | 'RENTING' | 'CONCESIONARIO';

	export interface ProviderBonusRenting extends Trimmable, ProviderBonusParameters {
		codeBp: string;

	}

	export interface ProviderBonusConcessionaire extends Trimmable, ProviderBonusParameters {
		population: string;
		province: string;
	}

	export interface VehicleUsage extends Trimmable, WithId {
		code: string;
		name: string;
		disabled: boolean;
	}

	export interface VehicleStatus extends Trimmable, WithId {
		code: string;
		name: string;
		disabled: boolean;
		color: string;
		isdefault: boolean;
		client: Client;
	}

	export interface Delegation extends Trimmable, WithId {
		code: string;
		name: string;
		fleetManager: User;
		serviceManager: User;
		zoneLeader: User;
		disabled: boolean;
	}

	export interface Subdelegation extends Trimmable, WithId {
		code: string;
		name: string;
		fleetManager: User;
		serviceManager: User;
		zoneLeader: User;
		disabled: boolean;
		delegation: Delegation;
	}

	export interface Division extends Trimmable, WithId {
		code: string;
		name: string;
		billingAccount: BillingAccount;
		disabled: boolean;
	}

	export interface Subdivision extends Trimmable, WithId {
		code: string;
		name: string;
		division: Division;
		disabled: boolean;
	}

	export interface Ceco extends Trimmable, WithId {
		code: string;
		description: string;
		disabled: boolean;
		billingAccount: BillingAccount;
	}

	export interface InvLeader extends Trimmable, WithId {
		user: User;
		fleetLeader: Boolean;
		zoneLeader: Boolean;
		serviceLeader: Boolean;
		client: Client;
		disabled: boolean;
	}

	export interface InvDriver extends Trimmable, WithId {
		code: String;
		user: User;
		extension: String;
		rpv: String;
		professionalEmail: String;
		observations: String;
		subdivision: Subdivision;
		ceco: Ceco;
		client: Client;
		disabled: boolean;
		driverAssignments: InvDriverAssignment[];
		documentation: InvDocument[];
		licenseData: InvDriverLicense[];
		licensePoints: InvDriverPoints[];
		nifExpirationDate: Date;
		nifExpeditionDate: Date;
		nif: String;
		importERP: Boolean;
		fullName: String;
	}

	// export interface InvDriverAssignments extends Trimmable, WithId {
	// 	driver: InvDriver;
	// 	vehicle: Vehicle;
	// 	startDate: Date;
	// 	endDate: Date;
	// }

	// export interface InvDriverDD extends InvDriver {
	// 	fullName: String;
	// }

	export interface InvDocument extends Trimmable, WithId {
		name: String;
		type: String; //InvDocumentType;
		document: Icon;
		vehicle: Vehicle;
		driver: InvDriver;
		disabled: boolean;
		createDate: Date;
		updateDate: Date;
	}

	export interface InvDocumentEdit extends Trimmable {
		isNew: any;
		type: any;
		entity: any;
		vehicle: any;
	}

	// export interface InvDocumentType extends Trimmable, WithId {
	// 	label: String;
	// 	name: String;
	// }

	export interface InvDriverLicense extends Trimmable, WithId {
		driver: InvDriver;
		licenseType: InvLicenseType;
		expeditionDate: Date;
		expirationDate: Date;
	}

	export interface InvDriverPoints extends Trimmable, WithId {
		driver: InvDriver;
		points: number;
		infoDate: Date;
		infoUser: User;
		document: InvDocument;
	}

	export interface InvNote extends Trimmable, WithId {
		description: String;
		document: InvDocument;
		vehicle: Vehicle;
		noteDate: Date;
		disabled: boolean;
	}

	export interface InvHtcCard extends Trimmable, WithId {
		vehicle: Vehicle;
		card: Card;
		startDate: Date;
		endDate: Date;
	}

	export interface InvHtcVehicleStatus extends Trimmable, WithId {
		vehicle: Vehicle;
		vehicleStatus: VehicleStatus;
		startDate: Date;
		endDate: Date;
	}

	export interface InvHtcVehicleUsage extends Trimmable, WithId {
		vehicle: Vehicle;
		vehicleUsage: VehicleUsage;
		startDate: Date;
		endDate: Date;
	}

	export interface InvVehicleVersion extends Trimmable, WithId {
		name: string;
		model: VehicleModel;
		cylinderCapacity: number;
		consumption: number;
		emissions: number;
		fuelCapacity: number;
		fuelType: string;  //Enum FuelType obtingut de API
		observations: string;
		client: Client;
		disabled: boolean;
	}

	export interface InvFuelType {
		id: number;
		label: string;
		name: string;
	}

	export interface InvIvaType {
		id: number;
		label: string;
		value: number;
	}

	export interface InvVehicleAssignment extends Rest.Trimmable, Rest.WithId {
		vehicle: Rest.Vehicle;
		company: Rest.BillingAccount;
		delegation: Rest.Delegation;
		subDelegation: Rest.Subdelegation;
		ceco: Rest.Ceco;
		iva: string;
		department: InvDepartment;
		startDate: Date;
		endDate: Date;
		client: Rest.Client;
		disabled: Boolean;
	}

	export interface InvDriverAssignment extends Rest.Trimmable, Rest.WithId {
		vehicle: Rest.Vehicle;
		driver: Rest.InvDriver;
		startDate: Date;
		endDate: Date;
		billingAccount: BillingAccount;
		ceco: Ceco;
		subDivision: Subdivision;
		division: Division;
		client: Client;
	}

	export interface InvDepartment extends Rest.Trimmable, Rest.WithId {
		code: String;
		description: String;
		disables: Boolean;
	}

	export interface InvVehicleAccessory extends Rest.Trimmable, Rest.WithId {
		vehicle: Rest.Vehicle;
		description: String;
		installationDate: Date;
		desinstallationDate: Date;
		alarmType: InvAlarmType;
		stationCode: String;
		serialNumber: String;
		value: number;
		verified: Boolean;
		verificationDate: Date;
		extrasType: Rest.Accessory;
		observations: String;
		client: Rest.Client;
		disabled: Boolean;
	}

	export interface Mileage extends Rest.Trimmable, Rest.WithId {
		vehicle: Vehicle;
		type: MileageTypeEnum;
		readingDate: Date;
		kms: number;
		consumption: CardConsumption;
		client: Client;
		disabled: boolean;
		disabledDate: Date;
		createUser: User;
		createDate: Date;
		updateUser: User;
		updateDate: Date;
		disabledUser: User;
		lastManualKMUpdate: number;
		mileageUpdateDate: Date;
	}

	export type MileageTypeEnum =
		'AU' // Automatico Cepsa
		| 'CO' // Control Kilometraje
		| 'HI' // Cambio de ruta
		| 'CM' // Consumo manual;
		;

	export interface InventoryReport extends Rest.Trimmable, Rest.WithId {
		name: string;
		description: string;
		reportType: reportTypeEnum;
		icon: Icon;
		client: Client;
	}

	export interface InvAlarmType extends Trimmable, WithId {
		name: string;
		disabled: boolean;
		isdefault: boolean;
		client: Client;
	}

	export type reportTypeEnum =
		'DRIVERS_EXPIRATION' | 'INSURANCE_ACTIVE'
		| 'VEHICLE_ACCESORY' | 'VEHICLE_CONSUMPTION'
		| 'ITV_EXPIRATION' | 'FINES'
		| 'CARD' | 'VEHICLE_EXPIRATION'
		| 'VEHICLE';

	export interface InventoryReportRequest extends Trimmable {
		report: InventoryReport;
		filters: { [index: string]: string[] };
		parameters: string[]
	}

	/*************************************************/
	/*****  INVENTORY INTERFACES ********* fin..  ****/
	/*************************************************/

	export interface ConversionAlgorithm extends Trimmable, WithId {
		name: string;
		lastUpdate: number;
		type: DType;
		description: string;
		code: string;
		executableCode: string;
		categoricalValues: CategoricalValue[];
	}

	export interface Device extends Trimmable, WithId {
		password: string;
		externalId: string;
		firmware: FirmwareVersion;
		owner: User;
		deviceType: DeviceType;
		enabled: boolean;
		status: DeviceStatus;
		imei: string;
		pin: number;
		phoneNumber: string;
		dateOfManufacture: Date;
		dateOfLastRevision: Date;
		dateOfNextRevision: Date;
		configuration: DeviceConfiguration;
		vehicle: Vehicle;
		gmtoffset: number;
		pendingSimActivation: boolean;
		deviceCommunicationType: DeviceCommunicationType;
		appTypes: AppTypes;
		sim: SimDevicesPlanGroups;
	}

	export interface RequestMessage extends Message {
		// TODO: Field "request" was missing from legacy code!!!
		request: any;
		id: number;
		ownerId: number;
		ownerName: string;
		requestType: RequestType;
	}

	export interface DeviceType extends Trimmable {
		id: number;
		name: string;
		description: string;
		enabled: boolean;
	}

	export interface ActuatorChangeRequestMessage extends RequestMessage {
		digital: boolean;
		actuatorId: number;
	}

	export interface AnalogActuatorChangeRequest extends ActuatorChangeRequestMessage {
		requestedValue: number;
	}

	export interface CallBackRequestMessage extends RequestMessage {
		phoneNumber: string;
	}

	export interface CustomEvent extends Event {
		descriptionES: string;
		descriptionFR: string;
		descriptionEN: string;
		descriptionCA: string;
		ruleId: number;
		metadata: string;
	}

	export interface DigitalActuatorChangeRequest extends ActuatorChangeRequestMessage {
		requestedValue: boolean;
		toggleInterval: number;
		toggletimes: number;
	}

	export interface DigitalSensorEvent extends Event {
		sensorId: number;
		sensorTag: string;
	}

	export interface FreeTextRequest extends RequestMessage {
		request: string;
	}

	export interface FreeTextRequestResponseMessage extends ResponseMessage {
		response: string;
	}

	export interface GV300Params extends ProprietaryDeviceParameters {
		globalParameters: GlobalParameters;
		friParameters: FixedReportInformation;
		towAlarm: TowAlarm;
		speedAlarmParameters: SpeedAlarmParameters;
		sosParameters: SOSParameters;
		hbmParameters: HarshBehaviorMonitoring;
		jammingDetection: JammingDetection;
		startStopReport: StartStopReport;
		jbsParameters: JammingBehaviorSetting;
		aisParameters: AnalogInputPortSetting;
		disParameters: DigitalInputPortSettings;
		canParameters: CanbusDeviceConfiguration;
		idaParameters: AuthenticationIdConfiguration;
	}

	export interface GV800Params extends ProprietaryDeviceParameters {
		globalParameters: GlobalParametersGV800;
		friParameters: FixedReportInformationGV800;
		towAlarm: TowAlarmGV800;
		speedAlarmParameters: SpeedAlarmParametersGV800;
		sosParameters: SOSParametersGV800;
		hbmParameters: HarshBehaviorMonitoringGV800;
		startStopReport: StartStopReportGV800;
		aisParameters: AnalogInputPortSettingGV800;
		disParameters: DigitalInputPortSettingsGV800;
		canParameters: CanbusDeviceConfigurationGV800;
		idaParameters: AuthenticationIdConfigurationGV800;
	}

	export interface GlobalParametersGV800 extends Serializable {
		// TODO: Field was missing from legacy code!!!
		eventMaskRESPGTEXP: any;
		powerSaving: PowerSavingEnumGV800;
		eventMaskRESPGTPNA: boolean;
		eventMaskRESPGTPFA: boolean;
		eventMaskRESPGTMPN: boolean;
		eventMaskRESPGTMPF: boolean;
		eventMaskRESPGTBPL: boolean;
		eventMaskRESPGTBTC: boolean;
		eventMaskRESPGTSTC: boolean;
		eventMaskRESPGTSTT: boolean;
		eventMaskRESPGTANT: boolean;
		eventMaskRESPGTPDP: boolean;
		eventMaskRESPGTPNL: boolean;
		eventMaskIgnitionOnRESPGTIGNGTIGF: boolean;
		eventMaskIgnitionOnOffRESPGTIGL: boolean;
		eventMaskGTEXP: boolean;
		ledON: boolean;
		infoReportEnable: boolean;
		infoReportInterval: number;
		gsmreportRESPGTRTL: boolean;
		gsmreportRESPGTLBC: boolean;
		gsmreportRESPGTFRIRESPGTERI: boolean;
		gsmreportRESPGTSOS: boolean;
		gsmreportRESPGTTOW: boolean;
		gpslostTime: number;
		wakeUpHourInterval: number;
		sensorWakeUpDebounce: number;
		workTimeWakeUp: number;
		oneWireSamplePeriod: number;
		oneWireInformation: boolean;
		gsmreportMode: GSMReportModeGV800Enum;
	}

	export interface FixedReportInformationGV800 extends Serializable {
		mode: ModeFRIGV800;
		discardNoFix: boolean;
		ignReportInterval: number;
		distance: number;
		mileage: number;
		cornerReport: number;
		iGFReportInterval: number;
		rs485FuelSensorData: boolean;
		oneWireData: boolean;
		percentage: boolean;
		volume: boolean;
		rfAccessoryData: boolean;
		rpmInput: boolean;
	}

	export interface TowAlarmGV800 extends Serializable {
		towEnable: boolean;
		engineOffToTow: number;
		fakeTowDelay: number;
		towInterval: number;
		restDuration: number;
		motionDuration: number;
		motionThreshold: number;
	}

	export interface SpeedAlarmParametersGV800 extends Serializable {
		mode: ModeSAEnumGV800;
		minSpeed: number;
		maxSpeed: number;
		validity: number;
		sendInterval: number;
	}

	export interface SOSParametersGV800 extends Serializable {
		modeSos: ModeSOSGV800;
		digitalInputID: number;
		sosNumber: String;
	}

	export interface HarshBehaviorMonitoringGV800 extends Serializable {
		mode: ModeHBMGV800;
		highSpeed: number;
		mediumSpeed: number;
		vha: number;
		vhb: number;
		vma: number;
		vmb: number;
		vla: number;
		vlb: number;
		corneringThreshold: number;
		coreringDuration: number;
		accelerationThreshold: number;
		accelerationDuration: number;
	}

	export interface StartStopReportGV800 extends Serializable {
		startStopReportMode: boolean;
		timeToStop: number;
		timeToStart: number;
		startSpeed: number;
		loginStop: number;
	}

	export interface AnalogInputPortSettingGV800 extends Serializable {
		analogInputId: number;
		mode: ModeAisGV800;
		minThreshold: number;
		maxThreshold: number;
		sampleRate: number;
	}

	export interface DigitalInputPortSettingsGV800 extends Serializable {
		// TODO: Field was missing from legacy code!!!
		mode: any;
		ignitionDetection: boolean;
		enableD1: boolean;
		enableD2: boolean;
		enableD3: boolean;
		enableD4: boolean;
		enableD5: boolean;
		debounceTimeD1: number;
		debounceTimeD2: number;
		debounceTimeD3: number;
		debounceTimeD4: number;
		debounceTimeD5: number;
		validityTimeD1: number;
		validityTimeD2: number;
		validityTimeD3: number;
		validityTimeD4: number;
		validityTimeD5: number;
	}

	export interface CanbusDeviceConfigurationGV800 extends Serializable {
		modeEnabled: boolean;
		canReportInterval: number;
		vin: boolean;
		ignitionKey: boolean;
		totalDistance: boolean;
		totalFuelUsed: boolean;
		vehicleSpeed: boolean;
		engineRPM: boolean;
		engineCoolantTemperature: boolean;
		fuelConsumption: boolean;
		fuelLevel: boolean;
		range: boolean;
		acceleratorPedalPressure: boolean;
		totalEngineHours: boolean;
		totalDrivingTime: boolean;
		totalEngineIdleTime: boolean;
		totalIdleFuelUsed: boolean;
		axleWeight: boolean;
		tachographInformation: boolean;
		detailedInformation: boolean;
		lights: boolean;
		doors: boolean;
		totalVehicleOverspeedTime: boolean;
		totalVehicleEngineOverSpeedTime: boolean;
		totalDistanceImpulses: boolean;
		canReportExpansionMask: boolean;
		gpsInformation: boolean;
		gsmInformation: boolean;
		modeAdditionalEvent: ModeCanAdditionalEventEnumGV800;
		adBlueLevel: boolean;
		axleWeight1st: boolean;
		axleWeight3rd: boolean;
		axleWeight4th: boolean;
		tachographOverspeedSignal: boolean;
		tachographVehicleMotionSignal: boolean;
		tachographDrivingDirection: boolean;
		analogInputValue: boolean;
		engineBrakingFactor: boolean;
		pedalBrakingFactor: boolean;
		totalAcceleratorKickDowns: boolean;
		totalEffectiveEngineSpeedTime: boolean;
		totalCruiseControlTime: boolean;
		totalAcceleratorKickDownTime: boolean;
		totalBrakeApplication: boolean;
		tachographDriver1CardNumber: boolean;
		tachographDriver2CardNumber: boolean;
		tachographDriver1Name: boolean;
		tachographDriver2Name: boolean;
		registrationNumber: boolean;
		expansionInformation: boolean;
		rapidBraking: boolean;
		rapidAccelerations: boolean;
	}

	export interface AuthenticationIdConfigurationGV800 extends Serializable {
		mode: ModeIDAGV800Enum;
		startIndex: number;
		endIndex: number;
		idNumberList: String;
		timeoutAfterIgnitionOff: number;
		modeReport: ModeReportIdGV800Enum;
		validityTime: number;
		outputId: number;
		outputStatus: boolean;
		duration: number;
		toggleTimes: number;
	}

	export interface GV300CANParams extends ProprietaryDeviceParameters {
		globalParameters: GlobalParametersGV300CAN;
		friParameters: FixedReportInformationGV300CAN;
		towAlarm: TowAlarmGV300CAN;
		speedAlarmParameters: SpeedAlarmParametersGV300CAN;
		sosParameters: SOSParametersGV300CAN;
		hbmParameters: HarshBehaviorMonitoringGV300CAN;
		jammingDetection: JammingDetectionGV300CAN;
		startStopReport: StartStopReportGV300CAN;
		jbsParameters: JammingBehaviorSettingGV300CAN;
		aisParameters: AnalogInputPortSettingGV300CAN;
		disParameters: DigitalInputPortSettingsGV300CAN;
		canParameters: CanbusDeviceConfigurationGV300CAN;
		idaParameters: AuthenticationIdConfigurationGV300CAN;
	}

	export interface GlobalParametersGV300CAN extends Serializable {
		powerSaving: PowerSavingGV300CANEnum;
		eventMaskRESPGTPNA: boolean;
		eventMaskRESPGTPFA: boolean;
		eventMaskRESPGTMPN: boolean;
		eventMaskRESPGTMPF: boolean;
		eventMaskRESPGTBPL: boolean;
		eventMaskRESPGTBTC: boolean;
		eventMaskRESPGTSTC: boolean;
		eventMaskRESPGTSTT: boolean;
		eventMaskRESPGTANT: boolean;
		eventMaskRESPGTPDP: boolean;
		eventMaskPowerONRESPGTRTL: boolean;
		eventMaskIgnitionReportRESPGTIGNRESPGTIGFREPSGTVGN: boolean;
		eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: boolean;
		ledON: boolean;
		infoReportEnable: boolean;
		infoReportInterval: number;
		gpslostTime: number;
		gsmreportMode: GSMReportModeGV300CANEnum;
		gsmreportRESPGTRTL: boolean;
		gsmreportRESPGTFRIRESPGTERI: boolean;
		gsmreportRESPGTTOW: boolean;
		gsmreportRESPGTLBC: boolean;
		gpsworkingMode: GPSWorkingModeGV300CANEnum;
		gsmreportRESPGTSOS: boolean;
	}

	export interface FixedReportInformationGV300CAN extends Serializable {
		mode: ModeFRIGV300CAN;
		discardNoFix: boolean;
		checkInterval: number;
		sendInterval: number;
		distance: number;
		mileage: number;
		cornerReport: number;
		iGFReportInterval: number;
		digitalFuelSensorData: boolean;
		oneWireData: boolean;
		canData: boolean;
		percentage: boolean;
		volume: boolean;
		bluetoothAccesoryData: boolean;
	}

	export interface TowAlarmGV300CAN extends Serializable {
		towEnable: boolean;
		fakeTowDelay: number;
		towInterval: number;
		restDuration: number;
		engineOffToTow: number;
		motionDuration: number;
		motionThreshold: number;
	}

	export interface SpeedAlarmParametersGV300CAN extends Serializable {
		mode: ModeSAEGV300CANEnum;
		minSpeed: number;
		maxSpeed: number;
		validity: number;
		sendInterval: number;
	}

	export interface SOSParametersGV300CAN extends Serializable {
		modeSos: ModeSosGV300CAN;
		digitalInputID: number;
		sosNumber: string;
	}

	export interface HarshBehaviorMonitoringGV300CAN extends Serializable {
		mode: ModeHBMGV300CAN;
		highSpeed: number;
		mediumSpeed: number;
		vha: number;
		vhb: number;
		vma: number;
		vmb: number;
		vla: number;
		vlb: number;
		corneringThreshold: number;
		coreringDuration: number;
		accelerationThreshold: number;
		accelerationDuration: number;
	}

	export interface JammingDetectionGV300CAN extends Serializable {
		mode: JammingModeGV300CANEnum;
		signalThreshold: number;
		jammingCellNumberThreshold: number;
		enterJammingTimerThreshold: number;
		quitJammingTimerThreshold: number;
	}

	export interface StartStopReportGV300CAN extends Serializable {
		startStopReportMode: boolean;
		timeToStop: number;
		timeToStart: number;
		startSpeed: number;
		loginStop: number;
	}

	export interface JammingBehaviorSettingGV300CAN extends Serializable {
		mode: boolean;
		sirenOnTimer: number;
		sirenOffTimer: number;
		readyFuelRealeseTimer: number;
		checkSpeed: boolean;
		speedLimit: number;
		output1InitState: boolean;
		motionSensor: boolean;
		gpsFixFailureTimeoutTimer: number;
		enableSiren: boolean;
		releaseFuelCutOffTimer: number;
		checkJamingInT3: boolean;
		wiatingReleaseFuelTimer: number;
	}

	export interface AnalogInputPortSettingGV300CAN extends Serializable {
		mode: ModeAisGV300CAN;
		rangeSwitch: boolean;
		minThreshold: number;
		maxThreshold: number;
		sampleRate: number;
		voltageMarginError: number;
	}

	export interface DigitalInputPortSettingsGV300CAN extends Serializable {
		mode: ModeDisIgnGV300CAN;
		samplePeriod: number;
		mpfDebounceTime: number;
		enableD1: boolean;
		enableD2: boolean;
		debounceTimeD1: number;
		debounceTimeD2: number;
		validityTimeD1: number;
		validityTimeD2: number;
	}

	export interface CanbusDeviceConfigurationGV300CAN extends Serializable {
		modeEnabled: boolean;
		canReportInterval: number;
		canReportIgf: number;
		vin: boolean;
		ignitionKey: boolean;
		totalDistance: boolean;
		totalFuelUsed: boolean;
		vehicleSpeed: boolean;
		engineRPM: boolean;
		engineCoolantTemperature: boolean;
		fuelConsumption: boolean;
		fuelLevel: boolean;
		range: boolean;
		acceleratorPedalPressure: boolean;
		totalEngineHours: boolean;
		totalDrivingTime: boolean;
		totalEngineIdleTime: boolean;
		totalIdleFuelUsed: boolean;
		axleWeight: boolean;
		tachographInformation: boolean;
		detailedInformation: boolean;
		lights: boolean;
		doors: boolean;
		totalVehicleOverspeedTime: boolean;
		totalVehicleEngineOverSpeedTime: boolean;
		totalDistanceImpulses: boolean;
		canReportExpansionMask: boolean;
		gnssInformation: boolean;
		gsmInformation: boolean;
		modeAdditionalEvent: ModeCanAdditionalEventEnum;
		adBlueLevel: boolean;
		axleWeight1st: boolean;
		axleWeight3rd: boolean;
		axleWeight4th: boolean;
		tachographOverspeedSignal: boolean;
		tachographVehicleMotionSignal: boolean;
		tachographDrivingDirection: boolean;
		analogInputValue: boolean;
		engineBrakingFactor: boolean;
		pedalBrakingFactor: boolean;
		totalAcceleratorKickDowns: boolean;
		totalEffectiveEngineSpeedTime: boolean;
		totalCruiseControlTime: boolean;
		totalAcceleratorKickDownTime: boolean;
		totalBrakeApplication: boolean;
		tachographDriver1CardNumber: boolean;
		tachographDriver2CardNumber: boolean;
		tachographDriver1Name: boolean;
		tachographDriver2Name: boolean;
		registrationNumber: boolean;
		expansionInformation: boolean;
		rapidBraking: boolean;
		rapidAccelerations: boolean;
	}

	export interface AuthenticationIdConfigurationGV300CAN extends ProprietaryDeviceParameters {
		mode: ModeIDAGV300CANEnum;
		startIndex: number;
		endIndex: number;
		idNumberList: String;
		timeoutAfterIgnitionOff: number;
		modeReport: ModeReportIdGV300CANEnum;
		validityTime: number;
		outputId: number;
		outputStatus: number;
		duration: number;
		toggleTimes: number;
	}

	export interface WeTrack2Params extends ProprietaryDeviceParameters {
		alarmMoving: AlarmMovingConfigurationWeTrack2;
		batteryAlarm: BatteryAlarmConfigurationWeTrack2;
		apn: APNConfigurationWeTrack2;
		externalBatteryAlarm: ExternalBatteryAlarmConfigurationWeTrack2;
		externalBatteryProtection: ExternalBatteryProtectionConfigurationWeTrack2;
		gmt: GMTConfigurationWeTrack2;
		gprsBlockingAlarm: GPRSBlockingAlarmConfigurationWeTrack2;
		mileage: MileageConfigurationWeTrack2;
		powerAlarm: PowerAlarmConfigurationWeTrack2;
		relay: RelayConfigurationWeTrack2;
		server: ServerConfigurationWeTrack2;
		sos: SOSConfigurationWeTrack2;
		vibrationAlarm: VibrationAlarmConfigurationWeTrack2;
		gpsDataSendingInterval: GPSDataSendingIntervalConfigurationWeTrack2;
		alarmCallNumberTimes: AlarmCallNumberTimesConfigurationWeTrack2;
		alarmOverSpeed: AlarmOverSpeedConfigurationWeTrack2;
		automaticApn: AutomaticAPNConfigurationWeTrack2;
		centerPhoneNumber: CenterPhoneNumberConfigurationWeTrack2;
		externalVoltageUpload: ExternalVoltageUploadConfigurationWeTrack2;
		fenceAlarm: FenceAlarmConfigurationWeTrack2;
		gpsDistanceInterval: GPSDistanceIntervalConfigurationWeTrack2;
		networkLinkCoordinates: NetworkLinkCoordinatesConfigurationWeTrack2;
		sensorSensitivity: SensorSensitivityConfigurationWeTrack2;
		accAlarm: ACCAlarmConfigurationWeTrack2;
		lbsUpload: LBSUploadConfigurationWeTrack2;
		locationDataUpload: LocationDataUploadConfigurationWeTrack2;
		angle: AngleConfigurationWeTrack2;
		defense: DefenseConfigurationWeTrack2;
		heartbeatInterval: HeartBeatIntervalConfigurationWeTrack2;
		hst: HSTConfigurationWeTrack2;
		staticDataFiltering: StaticDataFilteringConfigurationWeTrack2;
		sensorSet: SensorSetConfigurationWeTrack2;
	}

	export interface AlarmMovingConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		movingRadius: number;
		mode: ModeAlarmMovingWeTrack2;
	}

	export interface BatteryAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeAlarmWeTrack2;
	}

	export interface APNConfigurationWeTrack2 extends Serializable {
		name: string;
		username: string;
		password: string;
	}

	export interface ExternalBatteryAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeExternalBatteryAlarmWeTrack2;
		power1: number;
		power2: number;
		timeDuration: number;
	}

	export interface ExternalBatteryProtectionConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeAlarmWeTrack2;
		voltageCutOff: number;
		voltageReconnecting: number;
		timeDuration: number;
	}

	export interface GMTConfigurationWeTrack2 extends Serializable {
		hemisphereTimeZone: string;
		timeZoneHour: number;
		halfTimeZone: number;
	}

	export interface GPRSBlockingAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
	}

	export interface MileageConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		mileage: number;
	}

	export interface PowerAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModePowerAlarmWeTrack2;
		detectionTime: number;
		minChargingTime: number;
	}

	export interface RelayConfigurationWeTrack2 extends Serializable {
		cutOff: boolean;
	}

	export interface ServerConfigurationWeTrack2 extends Serializable {
		addressMode: ModeAdressServerWeTrack2;
		address: string;
		port: number;
		protocolMode: ModeProtocolServerWeTrack2;
	}

	export interface SOSConfigurationWeTrack2 extends Serializable {
		mode: ModeSOSWeTrack2;
		phoneNumbers: string;
	}

	export interface VibrationAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeVibrationAlarmWeTrack2;
	}

	export interface GPSDataSendingIntervalConfigurationWeTrack2 extends Serializable {
		timer1: number;
		timer2: number;
	}

	export interface AlarmCallNumberTimesConfigurationWeTrack2 extends Serializable {
		times: number;
	}

	export interface AlarmOverSpeedConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		intervalTime: number;
		speedLimit: number;
		modeAlarm: ModeAlarmWeTrack2;
	}

	export interface AutomaticAPNConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
	}

	export interface CenterPhoneNumberConfigurationWeTrack2 extends Serializable {
		mode: ModeSOSWeTrack2;
		phoneNumber: string;
	}

	export interface ExternalVoltageUploadConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		uploadInterval: number;
	}

	export interface FenceAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		mode: ModeFenceWeTrack2;
		circle: CircleAreaWeTrack2;
		rectangle: RectangleAreaWeTrack2;
		modeAlarmFence: ModeAlarmFenceWeTrack2;
		modeAlarm: ModeAlarmWeTrack2;
	}

	export interface CircleAreaWeTrack2 extends Serializable {
		latitudeCenter: number;
		longitudeCenter: number;
		radius: number;
	}

	export interface RectangleAreaWeTrack2 extends Serializable {
		latitude1: number;
		longitude1: number;
		latitude2: number;
		longitude2: number;
	}

	export interface GPSDistanceIntervalConfigurationWeTrack2 extends Serializable {
		distanceInterval: number;
	}

	export interface NetworkLinkCoordinatesConfigurationWeTrack2 extends Serializable {
		networkLink: String;
	}

	export interface SensorSensitivityConfigurationWeTrack2 extends Serializable {
		sensitivityGrade: number;
	}

	export interface ACCAlarmConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeAlarmWeTrack2;
		modeACC: ModeACCAlarmWeTrack2;
	}

	export interface LBSUploadConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		uploadInterval: number;
		timeOutTime: number;
	}

	export interface LocationDataUploadConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
	}

	export interface AngleConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		angleDegree: number;
		detectionTime: number;
	}

	export interface DefenseConfigurationWeTrack2 extends Serializable {
		delayDuration: number;
	}

	export interface HeartBeatIntervalConfigurationWeTrack2 extends Serializable {
		interval1: number;
		interval2: number;
	}

	export interface HSTConfigurationWeTrack2 extends Serializable {
		speed: number;
	}

	export interface SensorSetConfigurationWeTrack2 extends Serializable {
		detectionTime: number;
		vibrationTimeGps: number;
		vibrationTimeAlarm: number;
		workingTime: number;
	}

	export interface StaticDataFilteringConfigurationWeTrack2 extends Serializable {
		enabled: boolean;
		maxFilteringDistance: number;
	}

	export interface ConcoxMT200Params extends ProprietaryDeviceParameters {
		alarmMoving: AlarmMovingConfigurationConcoxMT200;
		angle: AngleConfigurationConcoxMT200;
		batteryAlarm: BatteryAlarmConfigurationConcoxMT200;
		apn: APNConfigurationConcoxMT200;
		defense: DefenseConfigurationConcoxMT200;
		externalBatteryAlarm: ExternalBatteryAlarmConfigurationConcoxMT200;
		externalBatteryProtection: ExternalBatteryProtectionConfigurationConcoxMT200;
		gmt: GMTConfigurationConcoxMT200;
		gprsAlarm: GPRSBlockingAlarmConfigurationConcoxMT200;
		gprs: GPRSConfigurationConcoxMT200;
		heartbeatInterval: HeartBeatIntervalConfigurationConcoxMT200;
		hst: HSTConfigurationConcoxMT200;
		instructionPassword: InstructionPasswordConfigurationConcoxMT200;
		mileage: MileageConfigurationConcoxMT200;
		powerAlarm: PowerAlarmConfigurationConcoxMT200;
		relay: RelayConfigurationConcoxMT200;
		reviseInstructionPassword: ReviseInstructionPasswordConfigurationConcoxMT200;
		sensor: SensorConfigurationConcoxMT200;
		server: ServerConfigurationConcoxMT200;
		sos: SOSConfigurationConcoxMT200;
		staticDataFiltering: StaticDataFilteringConfigurationConcoxMT200;
		vibrationAlarm: VibrationAlarmConfigurationConcoxMT200;
		sensorSet: SensorSetConfigurationConcoxMT200;
		gpsDataSendingInterval: GPSDataSendingIntervalConfigurationConcoxMT200;
		alarmCallNumberTimes: AlarmCallNumberTimesConfigurationConcoxMT200;
		alarmOverSpeed: AlarmOverSpeedConfigurationConcoxMT200;
		automaticApn: AutomaticAPNConfigurationConcoxMT200;
		centerPhoneNumber: CenterPhoneNumberConfigurationConcoxMT200;
		externalVoltageUpload: ExternalVoltageUploadConfigurationConcoxMT200;
		fenceAlarm: FenceAlarmConfigurationConcoxMT200;
		gpsDistanceInterval: GPSDistanceIntervalConfigurationConcoxMT200;
		networkLinkCoordinates: NetworkLinkCoordinatesConfigurationConcoxMT200;
		sensorSensitivity: SensorSensitivityConfigurationConcoxMT200;
	}

	export interface AlarmMovingConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		movingRadius: number;
		mode: ModeAlarmMovingEnumConcoxMT200;
	}

	export interface AngleConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		angleDegree: number;
		detectionTime: number;
	}

	export interface BatteryAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeBatteryAlarmEnumConcoxMT200;
	}

	export interface APNConfigurationConcoxMT200 extends Serializable {
		name: string;
		username: string;
		password: string;
	}

	export interface DefenseConfigurationConcoxMT200 extends Serializable {
		delayDuration: number;
	}

	export interface ExternalBatteryAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeBatteryAlarmEnumConcoxMT200;
		power1: number;
		power2: number;
		timeDuration: number;
	}

	export interface ExternalBatteryProtectionConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeBatteryAlarmEnumConcoxMT200;
		power: number;
		timeDuration: number;
	}

	export interface GMTConfigurationConcoxMT200 extends Serializable {
		hemisphereTimeZone: string;
		timeZoneHour: number;
		halfTimeZone: number;
	}

	export interface GPRSBlockingAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
	}

	export interface GPRSConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
	}

	export interface HeartBeatIntervalConfigurationConcoxMT200 extends Serializable {
		interval1: number;
		interval2: number;
	}

	export interface HSTConfigurationConcoxMT200 extends Serializable {
		speed: number;
	}

	export interface InstructionPasswordConfigurationConcoxMT200 extends Serializable {
		password: string;
		enabled: boolean;
	}

	export interface MileageConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		mileage: number;
	}

	export interface PowerAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		modeAlarm: ModePowerAlarmEnumConcoxMT200;
		detectionTime: number;
		minChargingTime: number;
		stepAlarmProhibitionTime: number;
	}

	export interface RelayConfigurationConcoxMT200 extends Serializable {
		cutOff: boolean;
	}

	export interface ReviseInstructionPasswordConfigurationConcoxMT200 extends Serializable {
		oldPassword: string;
		newPassword: string;
	}

	export interface SensorConfigurationConcoxMT200 extends Serializable {
		detectionTime: number;
		alertDelay: number;
		vibrationAlertInterval: number;
	}

	export interface ServerConfigurationConcoxMT200 extends Serializable {
		addressMode: ModeServerEnumConcoxMT200;
		address: string;
		port: number;
		protocolMode: ModeProtocolServerEnumConcoxMT200;
	}

	export interface SOSConfigurationConcoxMT200 extends Serializable {
		mode: ModeSOSEnumConcoxMT200;
		phoneNumbers: string;
	}

	export interface StaticDataFilteringConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		maxFilteringDistance: number;
	}

	export interface VibrationAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeVibrationAlarmEnumConcoxMT200;
	}

	export interface SensorSetConfigurationConcoxMT200 extends Serializable {
		detectionTime: number;
		vibrationTimeGps: number;
		vibrationTimeAlarm: number;
		workingTime: number;
	}

	export interface GPSDataSendingIntervalConfigurationConcoxMT200 extends Serializable {
		timer1: number;
		timer2: number;
	}

	export interface AlarmCallNumberTimesConfigurationConcoxMT200 extends Serializable {
		times: number;
	}

	export interface AlarmOverSpeedConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		intervalTime: number;
		speedLimit: number;
		modeAlarm: ModeAlarmEnumConcoxMT200;
	}

	export interface AutomaticAPNConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
	}

	export interface CenterPhoneNumberConfigurationConcoxMT200 extends Serializable {
		mode: ModeSOSEnumConcoxMT200;
		phoneNumber: string;
	}

	export interface ExternalVoltageUploadConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		uploadInterval: number;
	}

	export interface FenceAlarmConfigurationConcoxMT200 extends Serializable {
		enabled: boolean;
		mode: ModeFenceEnumConcoxMT200;
		circle: CircleAreaConcoxMT200;
		rectangle: RectangleAreaConcoxMT200;
		modeAlarmFence: ModeAlarmFenceEnumConcoxMT200;
		modeAlarm: ModeAlarmEnumConcoxMT200;
	}

	export interface CircleAreaConcoxMT200 extends Serializable {
		latitudeCenter: number;
		longitudeCenter: number;
		radius: number;
	}

	export interface RectangleAreaConcoxMT200 extends Serializable {
		latitude1: number;
		longitude1: number;
		latitude2: number;
		longitude2: number;
	}

	export interface GPSDistanceIntervalConfigurationConcoxMT200 extends Serializable {
		distanceInterval: number;
	}

	export interface NetworkLinkCoordinatesConfigurationConcoxMT200 extends Serializable {
		networkLink: String;
	}

	export interface SensorSensitivityConfigurationConcoxMT200 extends Serializable {
		sensitivityGrade: number;
	}

	export interface EVIXParams extends ProprietaryDeviceParameters { }

	export interface IofrogParams extends ProprietaryDeviceParameters { }

	export interface TeltonikaParams extends ProprietaryDeviceParameters { }

	export interface SmartCoffeeParams extends ProprietaryDeviceParameters { }

	export interface OB22Params extends ProprietaryDeviceParameters {
		angle: AngleConfigurationOB22;
		apn: APNConfigurationOB22;
		batteryAlarm: BatteryAlarmConfigurationOB22;
		gmt: GMTConfigurationOB22;
		gprs: GPRSBlockingAlarmConfigurationOB22;
		gprsAlarm: GPRSBlockingAlarmConfigurationOB22;
		heartbeatInterval: HeartbeatIntervalConfigurationOB22;
		mileage: MileageConfigurationOB22;
		instructionPassword: InstructionPasswordConfigurationOB22;
		powerAlarm: PowerAlarmConfigurationOB22;
		server: ServerConfigurationOB22;
		sos: SOSConfigurationOB22;
		staticDataFiltering: StaticDataFilteringConfigurationOB22;
		reviseInstructionPassword: ReviseInstructionPasswordConfigurationOB22;
		gpsDataSendingInterval: GPSDataSendingIntervalConfigurationOB22;
		automaticApn: AutomaticAPNConfigurationOB22;
		networkLinkCoordinates: NetworkLinkCoordinatesConfigurationOB22;
		gpsDistanceInterval: GPSDistanceIntervalConfigurationOB22;
		fenceAlarm: FenceAlarmConfigurationOB22;
		alarmCallNumberTimes: AlarmCallNumberTimesConfigurationOB22;
		alarmOverSpeed: AlarmOverSpeedConfigurationOB22;
		sensorSensitivity: SensorSensitivityConfigurationOB22;
		automaticGmt: AutomaticGMTConfigurationOB22;
		uploadAcc: UploadACCConfigurationOB22;
		batch: BatchConfigurationOB22;
		sends: SendsConfigurationOB22;
		delay: DelayVoiceMonitorConfigurationOB22;
		staticRep: StaticLastPositionConfigurationOB22;
		smsForwarding: SMSForwardingConfigurationOB22;
		lbsUpload: LBSUploadConfigurationOB22;
		pullAlarm: PullAlarmConfigurationOB22;
	}

	export interface AngleConfigurationOB22 extends Serializable {
		enabled: boolean;
		angleDegree: number;
		detectionTime: number;
	}

	export interface APNConfigurationOB22 extends Serializable {
		name: string;
		username: string;
		password: string;
	}

	export interface BatteryAlarmConfigurationOB22 extends Serializable {
		enabled: boolean;
		modeAlarm: ModeBatteryAlarmEnumOB22;
	}

	export interface GMTConfigurationOB22 extends Serializable {
		hemisphereTimeZone: string;
		timeZoneHour: number;
		halfTimeZone: number;
	}

	export interface GPRSConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface GPRSBlockingAlarmConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface HeartbeatIntervalConfigurationOB22 extends Serializable {
		interval1: number;
		interval2: number;
	}

	export interface MileageConfigurationOB22 extends Serializable {
		enabled: boolean;
		mileage: number;
	}

	export interface InstructionPasswordConfigurationOB22 extends Serializable {
		password: string;
		enabled: boolean;
	}

	export interface PowerAlarmConfigurationOB22 extends Serializable {
		enabled: boolean;
		modeAlarm: ModePowerAlarmEnumOB22;
		detectionTime: number;
		minChargingTime: number;
	}

	export interface ServerConfigurationOB22 extends Serializable {
		addressMode: ModeServerEnumOB22;
		address: string;
		port: number;
		protocolMode: ModeProtocolServerEnumOB22;
	}

	export interface SOSConfigurationOB22 extends Serializable {
		mode: ModeSOSEnumOB22;
		phoneNumbers: string;
	}

	export interface StaticDataFilteringConfigurationOB22 extends Serializable {
		enabled: boolean;
		maxFilteringDistance: number;
	}

	export interface ReviseInstructionPasswordConfigurationOB22 extends Serializable {
		oldPassword: string;
		newPassword: string;
	}

	export interface GPSDataSendingIntervalConfigurationOB22 extends Serializable {
		timer1: number;
		timer2: number;
	}

	export interface AutomaticAPNConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface NetworkLinkCoordinatesConfigurationOB22 extends Serializable {
		networkLink: string;
	}

	export interface GPSDistanceIntervalConfigurationOB22 extends Serializable {
		distanceInterval: number;
	}

	export interface FenceAlarmConfigurationOB22 extends Serializable {
		enabled: boolean;
		mode: ModeFenceEnumOB22;
		circle: CircleAreaOB22;
		rectangle: RectangleAreaOB22;
		modeAlarmFence: ModeAlarmFenceEnumOB22;
		modeAlarm: ModeAlarmEnumOB22;
	}

	export interface CircleAreaOB22 extends Serializable {
		latitudeCenter: number;
		longitudeCenter: number;
		radius: number;
	}

	export interface RectangleAreaOB22 extends Serializable {
		latitude1: number;
		longitude1: number;
		latitude2: number;
		longitude2: number;
	}

	export interface AlarmCallNumberTimesConfigurationOB22 extends Serializable {
		times: number;
	}

	export interface AlarmOverSpeedConfigurationOB22 extends Serializable {
		enabled: boolean;
		intervalTime: number;
		speedLimit: number;
		modeAlarm: ModeAlarmEnumOB22;
	}

	export interface SensorSensitivityConfigurationOB22 extends Serializable {
		sensitivityGrade: number;
	}

	export interface AutomaticGMTConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface UploadACCConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface BatchConfigurationOB22 extends Serializable {
		enabled: boolean;
		numberMessages: number;
	}

	export interface SendsConfigurationOB22 extends Serializable {
		timeDuration: number;
	}

	export interface DelayVoiceMonitorConfigurationOB22 extends Serializable {
		time: number;
	}

	export interface StaticLastPositionConfigurationOB22 extends Serializable {
		enabled: boolean;
		timeRange: number;
		minDetectionSpeed: number;
		detectingTimes: number;
	}

	export interface SMSForwardingConfigurationOB22 extends Serializable {
		phoneNumber: string;
		content: string;
	}

	export interface LBSUploadConfigurationOB22 extends Serializable {
		enabled: boolean;
	}

	export interface PullAlarmConfigurationOB22 extends Serializable {
		enabled: boolean;
		mode: ModePullAlarmEnumOB22;
		detectionTime: number;
		acceleration: number;
	}

	export interface GMT200Params extends ProprietaryDeviceParameters {
		globalParameters: GlobalParametersGMT200;
		friParameters: FixedReportInformationGMT200;
		towParameters: TowAlarmGMT200;
		speedAlarmParameters: SpeedAlarmParametersGMT200;
		sosParameters: SOSParametersGMT200;
		hbmParameters: HarshBehaviorMonitoringGMT200;
		jammingDetection: JammingDetectionGMT200;
		startStopReport: StartStopReportGMT200;
		jbsParameters: JammingBehaviorSettingGMT200;
		disParameters: DigitalInputPortSettingsGMT200;
		commandStorage: CommandStorageGMT200;
		externalPowerSupply: ExternalPowerSupplyGMT200;
		userDefinedFunction: UserDefinedFunctionGMT200;
		geoParameters: GeoFenceParametersGMT200;
		sriParameters: ServerRegistrationInformationGMT200;
		outParameters: DigitalOutputPortSettingsGMT200;
		bsiParameters: BearerSettingInformationGMT200;
		dogParameters: ProtocolWatchdogGMT200;
		pdsParameters: PreservingDeviceLogicStatesGMT200;
		ffcParameters: FrequencyChangeFriGMT200;
		idlParameters: ExcessiveIdlingDetectionGMT200;
		craParameters: CrashDetectionGMT200;
		ascParameters: AxisCalibrationGMT200;
		iobParameters: OutputPortBindingGMT200;
		vmsParameters: VirtualIgnitionSelectionGMT200;
		vvsParameters: VoltageVirtualIgnitionGMT200;
		avsParameters: AccelerometerVirtualIgnitionSettingsGMT200;
	}

	export interface GlobalParametersGMT200 extends Serializable {
		powerSaving: PowerSavingEnumGMT200;
		eventMaskRESPGTPNA: boolean;
		eventMaskRESPGTPFA: boolean;
		eventMaskRESPGTMPN: boolean;
		eventMaskRESPGTMPF: boolean;
		eventMaskRESPGTBPL: boolean;
		eventMaskRESPGTBPN: boolean;
		eventMaskRESPGTBPF: boolean;
		eventMaskRESPGTBTC: boolean;
		eventMaskRESPGTSTC: boolean;
		eventMaskRESPGTSTT: boolean;
		eventMaskRESPGTPDP: boolean;
		eventMaskPowerONRESPGTRTL: boolean;
		eventMaskIgnitionReportRESPGTIGNRESPGTIGFRESPGTVGNRESPGTVGF: boolean;
		eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: boolean;
		eventMaskRESPGTCID: boolean;
		ledON: LedOnEnumGMT200;
		infoReportEnable: boolean;
		infoReportInterval: number;
		powerMode: PowerModeEnumGMT200;
		speed: boolean;
		azimuth: boolean;
		altitude: boolean;
		gsmTowerData: boolean;
		mileage: boolean;
		sendTime: boolean;
		deviceName: boolean;
		cellInfoReport: CellInfoReportEnumGMT200;
		agpsMode: boolean;
		locationCall: LocationCallEnumGMT200;
	}

	export interface FixedReportInformationGMT200 extends Serializable {
		mode: ModeFRIGMT200;
		discardNoFix: boolean;
		sendInterval: number;
		distance: number;
		mileage: number;
		cornerReport: number;
		igfReportInterval: number;
		periodEnable: boolean;
		startTime: string;
		endTime: string;
	}

	export interface TowAlarmGMT200 extends Serializable {
		modeTow: ModeTOWEnumGMT200;
		engineOffToTow: number;
		fakeTowDelay: number;
		towInterval: number;
		restDuration: number;
		motionDuration: number;
		motionThreshold: number;
		outputId: number;
		outputStatus: boolean;
		duration: number;
		toggleTimes: number;
	}

	export interface SpeedAlarmParametersGMT200 extends Serializable {
		mode: ModeSAEnumGMT200;
		minSpeed: number;
		maxSpeed: number;
		duration: number;
		sendInterval: number;
		outputId: number;
		outputStatus: boolean;
		durationOutput: number;
		toggleTimes: number;
	}

	export interface SOSParametersGMT200 extends Serializable {
		modeSos: ModeSosGMT200;
		digitalInputId: number;
		sosNumber: string;
		outputId: number;
		outputStatus: boolean;
	}

	export interface HarshBehaviorMonitoringGMT200 extends Serializable {
		modeEnabled: boolean;
		highSpeed: number;
		vhb: number;
		vha: number;
		mediumSpeed: number;
		vmb: number;
		vma: number;
		vlb: number;
		vla: number;
		outputId: number;
		outputStatus: boolean;
	}

	export interface JammingDetectionGMT200 extends Serializable {
		mode: JammingModeEnumGMT200;
		signalThreshold: number;
		jammingCellNumberThreshold: number;
		enterJammingTimerThreshold: number;
		quitJammingTimerThreshold: number;
		outputId: number;
		outputStatus: boolean;
	}

	export interface StartStopReportGMT200 extends Serializable {
		startStopReportMode: boolean;
		timeToStop: number;
		timeToStart: number;
		startSpeed: number;
	}

	export interface JammingBehaviorSettingGMT200 extends Serializable {
		modeEnabled: boolean;
		delayDuration: number;
		fuelCutOffTimer: number;
		motionSensor: boolean;
		speedLimit: number;
		gnssFixFailureTimer: number;
		releaseFuelCutOffTimer: number;
		checkJamming: boolean;
		waitingReleaseFuelTimer: number;
	}

	export interface DigitalInputPortSettingsGMT200 extends Serializable {
		samplePeriod: number;
		mpfDebounceTime: number;
		noIgnition: boolean;
		enable: boolean;
		debounceTime: number;
		validityTime: number;
	}

	export interface CommandStorageGMT200 extends Serializable {
		mode: ModeCommandStorageEnumGMT200;
		cmdId: number;
		command: string;
	}

	export interface ExternalPowerSupplyGMT200 extends Serializable {
		mode: ModeEPSEnumGMT200;
		minThreshold: number;
		maxThreshold: number;
		samplePeriod: number;
		debounceTime: number;
		outputId: number;
		outputStatus: boolean;
		syncFri: boolean;
		validity: number;
	}

	export interface UserDefinedFunctionGMT200 extends Serializable {
		mode: ModeUDFEnumGMT200;
		groupId: number;
		powerOnFinished: boolean;
		ignitionOn: boolean;
		ignitionOff: boolean;
		gprsnetwork: boolean;
		noGPRSNetwork: boolean;
		gsmnetwork: boolean;
		noGSMNetwork: boolean;
		networkRoaming: boolean;
		networkNonRoaming: boolean;
		simCardLocked: boolean;
		gnssOn: boolean;
		gnssOff: boolean;
		deviceStationary: boolean;
		deviceMoving: boolean;
		externalCharge: boolean;
		noExternalCharge: boolean;
		digitalInput1Low: boolean;
		digitalInput1High: boolean;
		simCardInserted: boolean;
		simCardNotInserted: boolean;
		insideSpeedRange: boolean;
		outsideSpeedRange: boolean;
		messageNeedToBeSent: boolean;
		messageNoNeedToBeSent: boolean;
		towStatus: boolean;
		epsAlarmActivated: boolean;
		epsAlarmDesactivated: boolean;
		debounceTime: number;
		insideGeo0: boolean;
		insideGeo1: boolean;
		insideGeo2: boolean;
		insideGeo3: boolean;
		insideGeo4: boolean;
		outsideGeo0: boolean;
		outsideGeo1: boolean;
		outsideGeo2: boolean;
		outsideGeo3: boolean;
		outsideGeo4: boolean;
		modeStoCmd: ModeStoCmdEnumGMT200;
		stocmd0: boolean;
		stocmd1: boolean;
		stocmd2: boolean;
		stocmd3: boolean;
		stocmd4: boolean;
		stocmd5: boolean;
		stocmd6: boolean;
		stocmd7: boolean;
		stocmd8: boolean;
		stocmd9: boolean;
		stocmd10: boolean;
		stocmd11: boolean;
		stocmd12: boolean;
		stocmd13: boolean;
		stocmd14: boolean;
		stocmd15: boolean;
		stocmd16: boolean;
		stocmd17: boolean;
		stocmd18: boolean;
		stocmd19: boolean;
		stocmd20: boolean;
		stocmd21: boolean;
		stocmd22: boolean;
		stocmd23: boolean;
		stocmd24: boolean;
		stocmd25: boolean;
		stocmd26: boolean;
		stocmd27: boolean;
		stocmd28: boolean;
		stocmd29: boolean;
		stocmd30: boolean;
		stocmd31: boolean;
		preserveExecutedState: boolean;
		networkJammingIsDetected: boolean;
		networkJammingIsNotDetected: boolean;
	}

	export interface GeoFenceParametersGMT200 extends Serializable {
		geoId: number;
		mode: ModeGEOEnumGMT200;
		longitude: number;
		latitude: number;
		radius: number;
		checkInterval: number;
		outputId: number;
		outputStatus: boolean;
		modeState: ModeGEOStateEnumGMT200;
		modeTrigger: ModeGEOTriggerEnumGMT200;
		modeReportTrigger: ModeGEOReportTriggerEnumGMT200;
		useLastPoint: ModeGEOLastPointGMT200;
	}

	export interface ServerRegistrationInformationGMT200 extends Serializable {
		mode: ModeReportSRIEnumGMT200;
		buffer: ModeBufferSRIEnumGMT200;
		mainServerIp: String;
		mainServerPort: number;
		backupServerIp: String;
		backupServerPort: number;
		smsGateway: String;
		heartbeatInterval: number;
		sackEnable: boolean;
		protocolFormat: boolean;
		alwaysOnConnection: boolean;
		enableSmsAck: boolean;
	}

	export interface DigitalOutputPortSettingsGMT200 extends Serializable {
		status: boolean;
		duration: number;
		toggleTimes: number;
		output1NotReport: boolean;
	}

	export interface BearerSettingInformationGMT200 extends Serializable {
		apn: String;
		apnUsername: String;
		apnPassword: String;
	}

	export interface ProtocolWatchdogGMT200 extends Serializable {
		mode: ModeDOGEnumGMT200;
		ignitionFrequency: number;
		interval: number;
		time: String;
		digitalInputId: number;
		reportBeforeReboot: boolean;
		gsmInterval: number;
		pdpInterval: number;
		pdpResetInterval: number;
		failInterval: number;
	}

	export interface PreservingDeviceLogicStatesGMT200 extends Serializable {
		mode: ModePDSEnumGMT200;
		statesOfGeo: boolean;
		informationLastKnownPosition: boolean;
		ignitionOnOffStatus: boolean;
		stateCutOffRelayOutput: boolean;
		stateMainPower: boolean;
		stateMotion: boolean;
		stateDigitalInput1: boolean;
	}

	export interface FrequencyChangeFriGMT200 extends Serializable {
		priority: number;
		mode: ModeFCCEnumGMT200;
	}

	export interface ExcessiveIdlingDetectionGMT200 extends Serializable {
		mode: ModeIDLEnumGMT200;
		timeToIdling: number;
		timeToMovement: number;
		outputId: number;
		outputStatus: boolean;
	}

	export interface CrashDetectionGMT200 extends Serializable {
		mode: ModeCRAEnumGMT200;
		thresholdX: number;
		thresholdY: number;
		thresholdZ: number;
		samplingStartMode: ModeCRASamplingStartEnumGMT200;
		samplesBeforeCrash: number;
		samplesAfterCrash: number;
		outputId: number;
		outputStatus: boolean;
		speedConfirmation: number;
		odoConfirmation: number;
		confirmationTime: number;
		fallDegrees: number;
		reportAcc: boolean;
	}

	export interface AxisCalibrationGMT200 extends Serializable {
		brakingSpeedThreshold: number;
		deltaSpeedThreshold: number;
		deltaHeadingThreshold: number;
		outputId: number;
		outputStatus: boolean;
	}

	export interface OutputPortBindingGMT200 extends Serializable {
		iobId: number;
		inputIgnitionDetection: boolean;
		inputDigitalInput1: boolean;
		triggerIgnitionDetection: boolean;
		triggerDigitalInput1: boolean;
		samplePeriod: number;
		outputStatus: boolean;
		outputId: number;
	}

	export interface VirtualIgnitionSelectionGMT200 extends Serializable {
		mode: MODEVMSEnumGMT200;
		voltageVirtualIgnitionOnDetection: boolean;
		motionStatusVirtualIgnitionOnDetection: boolean;
		voltageVirtualIgnitionOffDetection: boolean;
		motionStatusVirtualIgnitionOffDetection: boolean;
		virtualIgnitionOnLogic: boolean;
	}

	export interface VoltageVirtualIgnitionGMT200 extends Serializable {
		ignitionOnVoltage: number;
		voltageOffset: number;
		ignitionOnDebounce: number;
		ignitionOffDebounce: number;
		mode: MODEVVSEnumGMT200;
	}

	export interface AccelerometerVirtualIgnitionSettingsGMT200 extends Serializable {
		restDuration: number;
		motionValidity: number;
	}

	export interface X8Params extends ProprietaryDeviceParameters {
		trackEvent: TrackEventConfigurationX8;
		speedEvent: SpeedEventConfigurationX8;
		powerSaving: PowerSavingX8;
		analogInput: AnalogInputConfigurationX8;
		idleEvent: IdleEventConfigurationX8;
		mileage: MileageConfigurationX8;
		backupBattery: BackupBatteryControlX8;
		inputDelayTime: InputDelayTimeConfigurationX8;
		timerReport: TimerReportConfigurationX8;
	}

	export interface TrackEventConfigurationX8 extends Serializable {
		// TODO: outputControl field was missing in logacy code!!!
		outputControl: any;
		mode: TrackModeX8;
		time: number;
		distance: number;
		times: number;
		persistTracking: boolean;
	}

	export interface SpeedEventConfigurationX8 extends Serializable {
		enabled: boolean;
		reportAction: ReportActionX8;
		minSpeed: number;
		maxSpeed: number;
		duration: number;
		outputId: number;
		outputControl: boolean;
	}

	export interface PowerSavingX8 extends Serializable {
		lowVoltage: number;
		mode: SleepModeX8;
		delay: number;
		powerUpDuration: number;
		powerDownDuration: number;
		sleepPriority: boolean;
		enableShockSensor;
	}

	export interface AnalogInputConfigurationX8 extends Serializable {
		analogInputId: number;
		mode: AnalogInputModeX8;
		reportAction: ReportActionX8;
		voltageMinValue: number;
		voltageMaxValue: number;
		duration: number;
		outputId: number;
		outputControl: boolean;
	}

	export interface IdleEventConfigurationX8 extends Serializable {
		enabled: boolean;
		reportMode: ReportModeIdleEventX8;
		idleSpeed: number;
		duration: number;
	}

	export interface TimerReportConfigurationX8 extends Serializable {
		enabled: boolean;
		presetTime: number;
	}

	export interface MileageConfigurationX8 extends Serializable {
		mileage: number;
	}

	export interface BackupBatteryControlX8 extends Serializable {
		enabled: boolean;
	}

	export interface InputDelayTimeConfigurationX8 extends Serializable {
		delayTimeInput1: number;
		delayTimeInput2: number;
		delayTimeInput3: number;
		delayTimeInput4: number;
		delayTimeInput5: number;
		delayTimeInput6: number;
		delayTimeInput7: number;
		delayTimeInput8: number;
	}

	export interface VT200Params extends ProprietaryDeviceParameters {
		acc: ACCVT200;
		analogInput: AnalogInputFunctionConfigurationVT200;
		dr: DRVT200;
		powerSaving: PowerSavingVT200;
		speedEvent: SpeedEventConfigurationVT200;
		towEvent: TowEventConfigurationVT200;
		trackEvent: TrackEventConfigurationVT200;
	}

	export interface ACCVT200 extends Serializable {
		enabled: boolean;
		onThreshold: number;
		offThreshold: number;
		duration: number;
	}

	export interface AnalogInputFunctionConfigurationVT200 extends Serializable {
		analogPort: number;
		mode: AnalogInputModesVT200;
		action: AnalogActionModeVT200;
		minVoltageLevel: number;
		maxVoltageLevel: number;
		duration: number;
		outputPort: AnalogInputOutputPortsVT200;
		outputControl: boolean;
	}

	export interface DRVT200 extends Serializable {
		lowVoltageLevel: number;
		pollingOnInput1: boolean;
		pollingOnInput2: boolean;
		pollingOnInput3: boolean;
		pollingOnInput4: boolean;
		pollingOnMainPowerLow: boolean;
		pollingOnMainPowerLost: boolean;
		pollingOnMainPowerVoltageRecover: boolean;
		pollingOnMainPowerRecover: boolean;
		pollingOnBatteryVoltageLow: boolean;
		pollingOnBatteryVoltageRecover: boolean;
		loggingOnInput1: boolean;
		loggingOnInput2: boolean;
		loggingOnInput3: boolean;
		loggingOnInput4: boolean;
		loggingOnMainPowerLow: boolean;
		loggingOnMainPowerLost: boolean;
		loggingOnBatteryVoltageLow: boolean;
		loggingOnMainPowerVoltageRecover: boolean;
		loggingOnMainPowerRecover: boolean;
		loggingOnBatteryVoltageRecover: boolean;
	}

	export interface PowerSavingVT200 extends Serializable {
		mode: PowerSavingModeVT200;
		delay: number;
		sleepMaskMode: SleepingMaskModeVT200;
	}

	export interface SpeedEventConfigurationVT200 extends Serializable {
		mode: SpeedEventModeVT200;
		minimumSpeed: number;
		maximumSpeed: number;
		speedDuration: number;
		outputPort: SpeedEventOutputPortsVT200;
		outputControl: boolean;
		speedingMode: SpeedEventSpeedingModesVT200;
		offSpeedingDuration: number;
	}

	export interface TowEventConfigurationVT200 extends Serializable {
		satellitesFixed: number;
		speedThreshold: number;
		towDuration: number;
		autoReset: number;
		mode: TowEventModeVT200;
	}

	export interface TrackEventConfigurationVT200 extends Serializable {
		mode: TrackModeVT200;
		time: number;
		distance: number;
		heading: number;
		trackingTimes: number;
		basis: TrackBasisVT200;
	}

	export interface GL320MParams extends ProprietaryDeviceParameters {
		bsiParameters: BearerSettingInformationGL320M;
		sriParameters: ServerRegistrationInformationGL320M;
		qssParameters: QuickStartSettingsGL320M;
		globalParameters: GlobalParametersGL320M;
		pinParameters: UnlockPINGL320M;
		dogParameters: ProtocolWatchdogGL320M;
		tmaParameters: TimeAdjustmenGL320M;
		nmdParameters: NonMovementDetectionGL320M;
		fksParameters: FunctionKeySettingsGL320M;
		ntsParameters: NetworkSelectionGL320M;
		owhParameters: OutsideWorkingHoursGL320M;
		simParameters: BindingSIMCardGL320M;
		urtParameters: SerialPortSettingsGL320M;
		friParameters: FixedReportInformationGL320M;
		geoParameters: GeoFenceParametersGL320M[];
		spdParameters: SpeedAlarmParametersGL320M;
		temParameters: TemperatureAlarmGL320M;
		msaParameters: MotionSensorAlarmGL320M;
		disParameters: DigitalInputPortSettingsGL320M;
		pdsParameters: PreservingDeviceLogicStatesGL320M;
		gamParameters: GPSAssistedMotionMeasurementGL320M;
		cmdParameters: CommandStorageGL320M[];
		udfParameters: UserDefinedFunctionGL320M[];
		additionalParameters: AdditionalParametersGL320M;
	}

	export interface BearerSettingInformationGL320M extends Serializable {
		lteApn: string;
		lteApnUsername: string;
		lteApnPassword: string;
		gprsApn: string;
		gprsApnUsername: string;
		gprsApnPassword: string;
		networkMode: NetworkModeBSIGL320M;
		lteMode: LTEModeBSIGL320M;
		authenticationMethods: ModeAuthenticationMethodBSIGL320M;
		manualNetreg: ManualNetregBSIGL320M;
		edrxPeriodic: number;
		edrxM1Pagings: number;
		edrxNB2Pagings: number;
	}

	export interface ServerRegistrationInformationGL320M extends Serializable {
		mode: ModeReportSRIGL320M;
		buffer: ModeBufferSRIGL320M;
		mainServerIp: string;
		mainServerPort: number;
		backupServerIp: string;
		backupServerPort: number;
		smsGateway: string;
		heartbeatInterval: number;
		modeSack: ModeSackSRIGL320M;
		enableSmsAck: boolean;
		modeMultipacketSending: MultiPacketSendingSRIGL320M;
		dnsLookupInterval: number;
	}

	export interface QuickStartSettingsGL320M extends Serializable {
		modeReport: ModeReportQSSGL320M;
		lteApn: string;
		lteApnUsername: string;
		lteApnPassword: string;
		mainServerIp: string;
		mainServerPort: number;
		backupServerIp: string;
		backupServerPort: number;
		smsGateway: string;
		heartbeatInterval: number;
		modeBuffer: ModeBufferQSSGL320M;
		modeSack: ModeSackQSSGL320M;
	}

	export interface GlobalParametersGL320M extends Serializable {
		gpsMode: ModeGpsCFGGL320M;
		gpsDelay: number;
		agpsMode: ModeAgpsCFGGL320M;
		ledON: LedOnCFGGL320M;
		expiryEnable: boolean;
		expiryTime: string;
		speed: boolean;
		azimuth: boolean;
		altitude: boolean;
		cellInformation: boolean;
		sendTime: boolean;
		deviceName: boolean;
		eventMaskRESPGTPNA: boolean;
		eventMaskRESPGTPFA: boolean;
		eventMaskRESPGTEPN: boolean;
		eventMaskRESPGTEPF: boolean;
		eventMaskRESPGTBPL: boolean;
		eventMaskRESPGTBTC: boolean;
		eventMaskRESPGTSTC: boolean;
		eventMaskRESPGTSTT: boolean;
		eventMaskRESPGTPDP: boolean;
		eventMaskRESPGTPNL: boolean;
		eventMaskRESPGTIGNGTIGF: boolean;
		eventMaskRESPGTIGL: boolean;
		smsLocationRequest: boolean;
		infoReportEnable: boolean;
		infoReportInterval: number;
		epbMode: ModeEpbCFGGL320M;
		batteryPowerOn: boolean;
		gsmMode: ModeGsmCFGGL320M;
		eventMaskRESPGTRTL: boolean;
		eventMaskRESPGTFRIGTERI: boolean;
		batteryLowPercentage: number;
		walkingMode: ModeWalkingCFGGL320M;
	}

	export interface UnlockPINGL320M extends Serializable {
		autoUnlockPin: boolean;
		pin: string;
	}

	export interface ProtocolWatchdogGL320M extends Serializable {
		mode: ModeDOGGL320M;
		rebootInterval: number;
		unit: ModeUnitDOGGL320M;
		rebootTime: string;
		ignitionFrequency: number;
		digitalInputId: number;
		reportBeforeReboot: boolean;
	}

	export interface TimeAdjustmenGL320M extends Serializable {
		sign: ModeSignTMAGL320M;
		hourOffset: number;
		minuteOffset: number;
		daylightSaving: boolean;
		utcTime: string;
	}

	export interface NonMovementDetectionGL320M extends Serializable {
		suspendFRIAndGeoFenceWhenNoMovement: boolean;
		reportGTNMRWhenNoMovement: boolean;
		reportGTNMRWhenMovement: boolean;
		changeGPSFixIntervalAndGTFRIReportIntervalWhenNoMovement: boolean;
		noMovementDuration: number;
		movementDuration: number;
		movementThreshold: number;
		fixIntervalRest: number;
		sendIntervalRest: number;
		pmRestThreshold: number;
		pmMotionThreshold: number;
		urcReport: ReportUrcNMDGL320M;
		movementCommand: MovementCommandNMDGL320M;
		modeReport: ModeReportNMDGL320M;
	}

	export interface FunctionKeySettingsGL320M extends Serializable {
		powerKeyMode: ModePowerKeyFKSGL320M;
		powerMode: ModePowerFKSGL320M;
		functionKeyMode: ModeFunctionKeyFKSGL320M;
		powerKeyIndicationMode: ModePowerKeyIndicationFKSGL320M;
		functionKeyIndicationMode: ModeFunctionKeyIndicationFKSGL320M;
		reportSosMode: ModeSosReportFKSGL320M;
		firstTriggerTime: number;
		secondTriggerTime: number;
		firstTriggerEvent: ModeTriggerFKSGL320M;
		secondTriggerEvent: ModeTriggerFKSGL320M;
	}

	export interface NetworkSelectionGL320M extends Serializable {
		enabled: boolean;
		whiteOper1: string;
		whiteOper2: string;
		whiteOper3: string;
		blackOper1: string;
		blackOper2: string;
		blackOper3: string;
	}

	export interface OutsideWorkingHoursGL320M extends Serializable {
		mode: ModeOWHGL320M;
		monday: boolean;
		tuesday: boolean;
		wednesday: boolean;
		thursday: boolean;
		friday: boolean;
		saturday: boolean;
		sunday: boolean;
		workingHoursStart1: string;
		workingHoursEnd1: string;
		workingHoursStart2: string;
		workingHoursEnd2: string;
		digitalInputId: number;
		rfSleepMode: ModeRFSleepOWHGL320M;
	}

	export interface BindingSIMCardGL320M extends Serializable {
		iccidMode: ModeIccidSIMGL320M;
		iccid: string;
		unregisterToNetwork: boolean;
	}

	export interface SerialPortSettingsGL320M extends Serializable {
		index: number;
	}

	export interface FixedReportInformationGL320M extends Serializable {
		mode: ModeFRIGL320M;
		checkInterval: number;
		sendInterval: number;
		ignitionCheckInterval: number;
		ignitionSendInterval: number;
		startTime: string;
		endTime: string;
		distance: number;
		mileage: number;
		discardNoFix: boolean;
		movementDetectionMode: ModeMovementDetectionFRIGL320M;
		movementSpeed: number;
		movementDistance: number;
		movementSendNumber: number;
		corner: number;
		externalBatteryPercent: boolean;
		temperature: boolean;
		ratAndBand: boolean;
	}

	export interface GeoFenceParametersGL320M extends Serializable {
		geoId: number;
		mode: ModeGEOGL320M;
		longitude: number;
		latitude: number;
		radius: number;
		checkInterval: number;
		modeState: ModeStateGEOGL320M;
	}

	export interface SpeedAlarmParametersGL320M extends Serializable {
		mode: ModeSPDGL320M;
		minSpeed: number;
		maxSpeed: number;
		validTime: number;
		sendInterval: number;
	}

	export interface TemperatureAlarmGL320M extends Serializable {
		mode: ModeTEMGL320M;
		maxTemperature: number;
		minTemperature: number;
		duration: number;
		sendInterval: number;
	}

	export interface MotionSensorAlarmGL320M extends Serializable {
		mode: ModeMSAGL320M;
		lastPositionMode: ModeLastPositionMSAGL320M;
		sensitivity: number;
		alarmTimeout: number;
	}

	export interface DigitalInputPortSettingsGL320M extends Serializable {
		mode: ModeDISGL320M;
		debounceTime: number;
		connected: boolean;
	}

	export interface PreservingDeviceLogicStatesGL320M extends Serializable {
		mode: ModePDSGL320M;
		stateOfGeo: boolean;
		deviceResetType: boolean;
		lastKnownPosition: boolean;
		deviceState: boolean;
		externalPowerSupply: boolean;
		charging: boolean;
		digitalInputs: boolean;
		stateGTLSW: boolean;
		stateGTTSW: boolean;
		stateGTOMS: boolean;
	}

	export interface GPSAssistedMotionMeasurementGL320M extends Serializable {
		mode: ModeGAMGL320M;
		speedMode: ModeSpeedGAMGL320M;
		motionSpeedThreshold: number;
		motionCumulativeTime: number;
		motionlessCumulativeTime: number;
		failureTimeout: number;
	}

	export interface CommandStorageGL320M extends Serializable {
		mode: ModeCommandStorageCMDGL320M;
		cmdId: number;
		command: string;
		dynamic: boolean;
		parameter: string;
	}

	export interface UserDefinedFunctionGL320M extends Serializable {
		groupId: number;
		mode: ModeUDFGL320M;
		debounceTime: number;
		modeStoCmd: ModeStoCmdUDFGL320M;
		powerOnFinished: boolean;
		ignitionOn: boolean;
		ignitionOff: boolean;
		pdpAttached: boolean;
		pdpNotAttached: boolean;
		networkRegistered: boolean;
		networkNonRegistered: boolean;
		networkRoaming: boolean;
		networkNonRoaming: boolean;
		simCardLocked: boolean;
		gpsOn: boolean;
		gpsOff: boolean;
		deviceStationary: boolean;
		deviceMoving: boolean;
		externalCharge: boolean;
		noExternalCharge: boolean;
		deviceCharging: boolean;
		deviceNotCharging: boolean;
		externalBatteryInserted: boolean;
		noExternalBattery: boolean;
		digitalInput1Low: boolean;
		digitalInput1High: boolean;
		simCardInserted: boolean;
		simCardNotInserted: boolean;
		insideGeo0: boolean;
		insideGeo1: boolean;
		insideGeo2: boolean;
		insideGeo3: boolean;
		insideGeo4: boolean;
		insideGeo5: boolean;
		insideGeo6: boolean;
		insideGeo7: boolean;
		insideGeo8: boolean;
		insideGeo9: boolean;
		insideGeo10: boolean;
		insideGeo11: boolean;
		insideGeo12: boolean;
		insideGeo13: boolean;
		insideGeo14: boolean;
		insideGeo15: boolean;
		insideGeo16: boolean;
		insideGeo17: boolean;
		insideGeo18: boolean;
		insideGeo19: boolean;
		outsideGeo0: boolean;
		outsideGeo1: boolean;
		outsideGeo2: boolean;
		outsideGeo3: boolean;
		outsideGeo4: boolean;
		outsideGeo5: boolean;
		outsideGeo6: boolean;
		outsideGeo7: boolean;
		outsideGeo8: boolean;
		outsideGeo9: boolean;
		outsideGeo10: boolean;
		outsideGeo11: boolean;
		outsideGeo12: boolean;
		outsideGeo13: boolean;
		outsideGeo14: boolean;
		outsideGeo15: boolean;
		outsideGeo16: boolean;
		outsideGeo17: boolean;
		outsideGeo18: boolean;
		outsideGeo19: boolean;
		stocmd0: boolean;
		stocmd1: boolean;
		stocmd2: boolean;
		stocmd3: boolean;
		stocmd4: boolean;
		stocmd5: boolean;
		stocmd6: boolean;
		stocmd7: boolean;
		stocmd8: boolean;
		stocmd9: boolean;
		stocmd10: boolean;
		stocmd11: boolean;
		stocmd12: boolean;
		stocmd13: boolean;
		stocmd14: boolean;
		stocmd15: boolean;
		stocmd16: boolean;
		stocmd17: boolean;
		stocmd18: boolean;
		stocmd19: boolean;
		stocmd20: boolean;
		stocmd21: boolean;
		stocmd22: boolean;
		stocmd23: boolean;
		stocmd24: boolean;
		stocmd25: boolean;
		stocmd26: boolean;
		stocmd27: boolean;
		stocmd28: boolean;
		stocmd29: boolean;
		stocmd30: boolean;
		stocmd31: boolean;
	}

	export interface AdditionalParametersGL320M extends Serializable {
		timeoutApp: number;
	}

	export interface LocationRequestMessage extends RequestMessage {
	}

	export interface RequestCancelledEvent extends RequestRelatedEvent {
		usedId: number;
		userName: string;
	}

	export interface RequestQueuedEvent extends RequestRelatedEvent, Trimmable {
	}

	export interface RequestSendingEvent extends RequestRelatedEvent {
	}

	export interface RequestSentEvent extends RequestRelatedEvent, Trimmable {
		status: ResponseStatus;
	}

	export interface ResponseMessage extends IncomingMessage {
		originalRequest: RequestMessage;
		errors: Error[];
		status: ResponseStatus;
	}

	export interface Rt {
	}

	export interface VehicleActivityReport {
		locations: number[][];
		drivingTime: number;
		travelTime: number;
		stopTime: number;
		numStops: number;
		events: VehicleActivityEvent[];
	}

	export interface VehicleDataMessage extends IncomingMessage, BasicSerializedEntity {
		deviceTime: number;
		lastGPSDateTime: number;
		speed: number;
		heading: number;
		longitude: number;
		latitude: number;
		altitude: number;
		satelite: number;
		maxSpeed: number;
		vehicleMileage: number;
		deviceMileage: number;
		eventID: number;
		sensors: DValue[];
		actuators: DValue[];
	}

	export interface VehicleDataMessagePOI {
		position: Vertex;
		tag: string;
		platformTime: number;
		deviceTime: number;
		speed: number;
	}

	export interface VT10Properties extends ProprietaryDeviceParameters {
		speedEvent: SpeedEventConfiguration;
		analogInputFunction: AnalogInputFunctionConfiguration;
		mileageConfiguration: MileageConfiguration;
		track: TrackEventConfiguration;
		track2: Track2EventConfiguration;
		roaming: Roaming;
		powerSaving: PowerSaving;
		dr: DR;
		acc: ACC;
		towAlert: TowEventConfiguration;
		gpsAntenaCut: GPSAntenaCutEventConfiguration;
		ignitionOff: IgnitionOff;
		harshAcceleration: HarshAccelerationEventConfiguration;
		harshBreaking: HarshBreakingEventConfiguration;
		emergencyStop: EmergencyStopEventConfiguration;
		harshCornering: HarshCorneringEventConfiguration;
		idle: IdleEventConfiguration;
		jam: JamEventConfiguration;
		ledPAttern1: LEDPattern;
		ledPAttern2: LEDPattern;
		ledPAttern3: LEDPattern;
		ledPAttern4: LEDPattern;
		ledPAttern5: LEDPattern;
		rfDetected: RFDetectedEventConfiguration;
		tagInOut: TagInOutEventConfiguration;
		time: TimeConfiguration;
		sos: SOSEventConfiguration;
		rfTags: RFTag[];
		arm: ARM;
		acconWithoutTag: ACCOnWithoutTagEventConfiguration;
	}

	export interface ZoneEvent extends Event {
		zoneId: number;
		zoneName: string;
		zoneGroupName: string;
		isEntering: boolean;
	}

	export interface Fleet extends Trimmable, WithId {
		name: string;
		owner: User;
		enabled: boolean;
		groups: VehiclesGroup[];
		metadata: { [index: string]: string };
	}

	export interface Icon extends Trimmable, WithId {
		name: string;
		entity: string;
		path: string;
		id: number;
		imageEncoded: string;
	}

	export interface Maintenance extends Trimmable, WithId {
		// TODO: Field "_$update" was missing from legacy code!!!
		_$update: any;
		maintenancePlan: MaintenancePlan;
		history: MaintenanceExecution[];
		name: string;
		description: string;
		observations: string;
		enabled: boolean;
		periodicityType: MaintenancePeriodicityType;
		periodicityTimeUnit: MaintenancePeriodicityTimeUnit;
		periodicity: number;
		notifyBefore: number;
		type: String;
	}

	export interface MaintenanceExecution extends Trimmable, WithId {
		vehicle: Vehicle;
		observations: string;
		maintenance: Maintenance;
		enabled: Boolean;
		lastMaintenanceDate: Date;
		lastMaintenanceKms: number;
		nextMaintenanceDate: Date;
		nextMaintenanceKms: number;
		doneMaintenanceDate: Date;
		doneMaintenanceKms: number;
		status: string;
		incidents: Boolean;
		document: InvDocument[];
	}

	export interface FullInfoStatusType extends Trimmable {
		statusType: string;
		color: string;
	}

	export interface MaintenancePlan extends Trimmable, WithId {
		enabled: boolean;
		owner: User;
		name: string;
		noticeChannels: NoticeChannel[];
		maintenances: Maintenance[];
		roles: Role[];
	}

	export interface NoticeGroup extends Trimmable, WithId {
		noticeChannelsStr: string;
		name: string;
		description: string;
		owner: User;
		enabled: boolean;
		notifyBySMS: boolean;
		notifyByEmail: boolean;
		notifyByApp: boolean;
		notifyByWeb: boolean;
		visibleVehicles: Vehicle[];
		visibleVehiclesGroups: VehiclesGroup[];
		visibleFleets: Fleet[];
		visibleUsers: User[];
		tag_events: string[];
	}

	export interface POICategory extends Trimmable, WithId {
		name: string;
		owner: User;
		enabled: boolean;
		icon: Icon;
		pois: POI[];
	}

	export interface POI extends Trimmable, WithId {
		// TODO: Field "_$update" was missing from legacy code!!!
		_$update: any;
		id: number;
		poiCategory: POICategory;
		position: Vertex;
		name: string;
		enabled: boolean;
		address: string;
		postalCode: string;
		municipality: string;
		province: string;
		phoneNumber: string;
		contactPerson: string;
		timeZone: string;
		observations: string;
		param1: string;
		param2: string;
	}

	export interface DevicePreconfiguration extends Trimmable {
		id: number;
		name: string;
		parameters: DeviceParameters;
		firmware: FirmwareVersion;
		deviceType: Types;
	}

	export interface Profile extends Trimmable, WithId {
		name: string;
		description: string;
		owner: User;
		actions: Action[];
	}

	export interface FleetTreeSettings extends Trimmable, WithId {
		name: string;
		owner: User;
		visibleVehicles: Vehicle[];
		visibleVehiclesGroups: VehiclesGroup[];
		visibleFleets: Fleet[];
		sensorChartSettings: SensorChartSettings;
	}

	export interface MapSettings extends Trimmable {
		id: number;
		name: string;
		owner: User;
		visiblePOICategories: POICategory[];
		visibleZoneGroups: ZoneGroup[];
	}

	export interface RealTimeSettings extends Trimmable {
		id: number;
		name: string;
		owner: User;
		fleetTreeSettings: FleetTreeSettings;
		vehicleDetailsSettings: VehicleDetailsSettings;
		mapSettings: MapSettings;
	}

	export interface VehicleDetailsSettings extends Trimmable {
		id: number;
		name: string;
		owner: User;
		showVehicleName: boolean;
		showFleetName: boolean;
		showVehicleGroupName: boolean;
		showAddress: boolean;
		showSpeeed: boolean;
		showMileage: boolean;
		showAnalogIO: boolean;
		showStatus: boolean;
		showFieldLabel: boolean;
		view: View;
		showTelemetry: boolean;
		showMap: boolean;
		showQuickActions: boolean;

	}

	export interface Report extends Trimmable {
		id: number;
		name: string;
		requestDate: Date;
		owner: User;
		computationStartDate: Date;
		computationEndDate: Date;
		status: ReportStatus;
		type: ReportType;
		vehiclesIds: number[];
		vehiclesGroupsIds: number[];
		fleetsIds: number[];
		vehiclesIdsAndNames: VehicleIdNamePair[];
		periodStart: Date;
		periodEnd: Date;
	}

	export interface ReportTableHospitalMataro extends Trimmable {
		name: string;
		days: DaysTableHospitalMataro[];
		avis: boolean;
	}

	export interface DaysTableHospitalMataro extends Trimmable {
		day: string;
		data: DataTableHospitalMataro[];
	}

	export interface DataTableHospitalMataro extends Trimmable {
		name: string;
		value: number;
		newSensorData: boolean;
	}


	export interface Role extends Trimmable, WithId {
		name: string;
		description: string;
		owner: User;
		visibleVehicles: Vehicle[];
		visibleVehiclesGroups: VehiclesGroup[];
		visibleFleets: Fleet[];
		visibleNoticeGroups: NoticeGroup[];
		visibleMaintenancePlans: MaintenancePlan[];
		visibleZoneGroups: ZoneGroup[];
		visiblePoisCategory: POICategory[];
		visibleDevices: Device[];
		visibileRules: Rule[];
		visibleDelegations: Delegation[];
	}

	export interface Rule extends Trimmable, WithId {
		name: string;
		description: string;
		owener: User;
		code: string;
		executableCode: string;
		lastModification: number;
		enabled: boolean;
		frameTime: number;
		eventTime: number;
		events: string[];
		eventTag: string;
		eventDescriptionCat: string;
		eventDescriptionEs: string;
		eventDescriptionFr: string;
		eventDescriptionEn: string;
		fleets: Fleet[];
		vehicles: Vehicle[];
		vehiclesGroup: VehiclesGroup[];
	}

	export interface Translation {
		tag: string;
		catalan: string;
		english: string;
		spanish: string;
	}

	export interface User extends Trimmable, WithId {
		name?: string;
		enabled: boolean;
		timeZone: string;
		client: Client;
		parent: User;
		isAdmin: boolean;
		surname1: string;
		surname2: string;
		userName: string;
		password: string;
		email: string;
		phoneNumber: string;
		language: Language;
		accountExpiry: Date;
		metric: Metric;
		canManageUsers: boolean;
		canManageFleets: boolean;
		canManageMaintenancePlans: boolean;
		canManageDevices: boolean;
		canManageClients: boolean;
		canManageZoneGroups: boolean;
		canManagePOICategories: boolean;
		canManageNotificationGroups: boolean;
		canManageRules: boolean;
		defaultDashboard: string;
		canSeeUTERondesDashboard: boolean;
		canSeeReports: boolean;
		canSeeRTDashboard: boolean;
		canSeeSAT: boolean;
		canSeeRaceEvents: boolean;
		canSeeTax: boolean;
		canSeeTaco: boolean;
		canCreatUTERondesPOCVisits: boolean;
		canAccesBackend: boolean;
		roles: Role[];
		devices: Device[];
		fleets: Fleet[];
		maintenancePlans: MaintenancePlan[];
		noticeGroups: NoticeGroup[];
		poiCategories: POICategory[];
		profiles: Profile[];
		zoneGroups: ZoneGroup[];
		rolesProfiles: RoleProfilePair[];
		profileImage: Icon;
		canSeeInventoryVehicles: boolean;
		canSeeDrivers: boolean;
		canSeeMaintenance: boolean;
		canSeeTableManagement: boolean;
		canSeeDashboards: boolean;
		//canSeeBologistics: boolean;
		userCode: String;
		//canSeeApplogistics: boolean;
		activeDirectory: String;
		TypeuserappLogistics: number;
		postalAddress: String;
		children: User[];
		city: String;
		route: Rest_task.Route[];
		state: String;
		vehicles: Vehicle[];
		address: String;
		checkedvalue: boolean;
		population: String;
		delegUserData: Rest_task.DelegationUser[];
		province: String;
		routesDriverDefault: Rest_task.RouteDriverDefault[];
		canSeeInventoryReport: boolean;
		cp: string;
		birthDate: Date;
		ssoId: string;
		usertype: UserType[];
		tableSize: TableSize;
	}

	export interface UserType extends Trimmable, WithId {
		id: number;
		projectstype: ProjectsType;
		user: User;
	}
	//Pensat per guardar configs de taules, no està fet
	export interface TableUserSettings extends Trimmable, WithId {
		id: number;
		columnsVisible: string[];
		tableSize: TableSize;
	}

	export interface ProjectsType extends Trimmable, WithId {
		id: number;
		nameProject: string;
		usertype: UserType[];
	}

	export interface Vehicle extends Trimmable, WithId {
		billingAccount: BillingAccount;
		owner: User;
		maintenancePlan: MaintenancePlan;
		group: VehiclesGroup;
		enabled: boolean;
		name: string;
		km: number;
		maxSpeed: number;
		lastManualKMUpdate: number;
		metadata: { [index: string]: string };
		model: VehicleModel;
		dateOfManufacture: Date;
		device: Device;
		executions: MaintenanceExecution[];
		registered: boolean;
		delegationTask: Rest_task.Delegation;
		image: Rest.Icon;
		version: InvVehicleVersion;
		dateRegistered: Date;
		frameNumber: String;
		disabledDate: Date;
		gps: boolean;
		client: Client;
		disabledUser: User;
		createDate: Date;
		createUser: User;
		updateDate: Date;
		updateUser: User;
		status: VehicleStatus;
		usage: VehicleUsage;
		observations: String;
		fuelCard: Card;
		color: string;
		insurance: Rest.Insurance;
		financing: Rest.Financing;
		vehicleAssignments: Rest.InvVehicleAssignment[];
		driverAssignments: InvDriverAssignment[];
		delegation: Delegation;
		subDelegation: Subdelegation;
		fleetManager: User;
		serviceManager: User;
		zoneLeader: User;
	}

	export interface VehiclesGroup extends Trimmable, WithId {
		owner: User;
		name: string;
		icon: Icon;
		fleet: Fleet;
		metadata: { [index: string]: string };
		enabled: boolean;
		vehicles: Vehicle[];
	}

	export interface Zone extends Trimmable, WithId {
		_$update: boolean;
		name: string;
		circular: boolean;
		enabled: boolean;
		zoneGroup: ZoneGroup;
		radius: number;
		center: Vertex;
		eventOnEnter: boolean;
		eventOnLeave: boolean;
		rawPolygonVertexList: string;
		zoneJSON: string;
	}

	export interface ZoneGroup extends Trimmable, WithId {
		owner: User;
		numEventsValid: number;
		enabled: boolean;
		name: string;
		zones: Zone[];
		roles: Role[];
		vehicles: Vehicle[];
		vehiclesGroups: VehiclesGroup[];
		fleets: Fleet[];
		headquarters: boolean;

	}

	export interface Context {
		action: string;
		entity: string;
		id: number;
	}

	export interface PaginationRequest {
		offset: number;
		pageSize: number;
		sortAsc: boolean;
	}

	export interface Page<T> {
		filteredEntities: number;
		entities: T[];
		permissions: { [index: string]: number[] };
	}

	export interface Action extends Trimmable, WithId {
		name: string;
		description: string;
	}

	export interface Actuator extends Trimmable, WithId {
		name: string;
		tag: string;
		type: DType;
		port: number;
		conversionAlgorithm: ConversionAlgorithm;
		parameters: DeviceParameters;
	}

	export interface Trimmable {
	}

	export interface WithId {
		id: number;
	}

	export interface ListPageAndFilters<T> {
		page: Page<T>;
		filters: { [index: string]: TableFilterSelectItem[] };
	}

	export interface CategoricalValue {
		key: string;
		cat: string;
		es: string;
		fr: string;
		en: string;
	}

	export interface DeviceConfiguration extends Trimmable {
		deviceType: Types;
		id: number;
		status: Status;
		parameters: DeviceParameters;
	}

	export interface Request extends Trimmable, WithId {
		creationDate: Date;
		user: User;
		vehicle: Vehicle;
		device: Device;
		type: RequestType;
		requestStatus: RequestStatus;
		ok: boolean;
		ongoingDate: Date;
		cancellationDate: Date;
		completionDate: Date;
		kafkaMessage: string;
		requestMessage: string;
		responseMessage: string;
		errors: string;
		channel: RequestChannel;
	}

	export interface Message extends Serializable, TimedEntity {
		platformTime: number;
		vehicleId: number;
		fleetId: number;
		vehicleGroupId: number;
		tag: string;
	}

	export interface Event extends BasicSerializedEntity, TimedEntity {
		type: EventType;
		platformTime: number;
		deviceTime: number;
		vehicleId: number;
		vehicleGroupId: number;
		fleetId: number;
		tag: string;
	}

	export interface Error {
		description: string;
	}

	export interface Dashboards extends Serializable {
		id: number;
		name: string;
		url: string;
		client: Client;

		type: DashBoardsEnum;
	}

	export interface DashBoardChartsRequest extends Trimmable {
		coffeeMakers: number[];
		groups: number[];
		startDate: Date;
		endDate: Date;
	}

	export interface DashBoardChartsResponse extends Trimmable {
		coffeeMakerUse: CoffeeMakerUse[];
		consumption: SmartCoffeeWeek[];
		orders: SmartCoffeeWeek[];
		alerts: SmartCoffeeWeek[];
	}

	export interface SmartCoffeeWeek extends Trimmable {
		year: number;
		week: number;
		value: number;
	}

	export interface CoffeeMakerUse extends Trimmable {
		coffeeMaker: Vehicle;
		use: SmartCoffeeWeek[];
	}

	export interface CoffeeMakerUseResponse extends Trimmable {
		coffeeMakerUse: CoffeeMakerHourlyUse[];
	}
	export interface CoffeeMakerHourlyUse extends Trimmable {
		coffeeMaker: Vehicle;
		hourUse: HourlyCoffeeUse[];
	}

	export interface HourlyCoffeeUse extends Trimmable {
		hour: string;
		value: number;
	}

	export interface GlobalParameters extends Serializable {
		powerSaving: PowerSavingEnum;
		eventMaskRESPGTPNA: boolean;
		eventMaskRESPGTPFA: boolean;
		eventMaskRESPGTMPN: boolean;
		eventMaskRESPGTMPF: boolean;
		eventMaskRESPGTBPL: boolean;
		eventMaskRESPGTBTC: boolean;
		eventMaskRESPGTSTC: boolean;
		eventMaskRESPGTSTT: boolean;
		eventMaskRESPGTANT: boolean;
		eventMaskRESPGTPDP: boolean;
		eventMaskPowerONRESPGTRTL: boolean;
		eventMaskIgnitionReportRESPGTIGNRESPGTIGFREPSGTVGN: boolean;
		eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: boolean;
		ledON: boolean;
		infoReportEnable: boolean;
		infoReportInterval: number;
		gpslostTime: number;
		gsmreportMode: GSMReportModeEnum;
		gsmreportRESPGTRTL: boolean;
		gsmreportRESPGTFRIRESPGTERI: boolean;
		gsmreportRESPGTTOW: boolean;
		gsmreportRESPGTLBC: boolean;
		gpsworkingMode: GPSWorkingModeEnum;
		gsmreportRESPGTSOS: boolean;
	}

	export interface FixedReportInformation extends Serializable {
		mode: ModeFRI;
		discardNoFix: boolean;
		checkInterval: number;
		sendInterval: number;
		distance: number;
		mileage: number;
		cornerReport: number;
		iGFReportInterval: number;
		digitalFuelSensorData: boolean;
		oneWireData: boolean;
		canData: boolean;
		percentage: boolean;
		volume: boolean;
		rfAccessoryData: boolean;
	}

	export interface TowAlarm extends Serializable {
		towEnable: boolean;
		fakeTowDelay: number;
		towInterval: number;
		restDuration: number;
		engineOffToTow: number;
		motionDuration: number;
		motionThreshold: number;
	}

	export interface SpeedAlarmParameters extends Serializable {
		mode: ModeSAEnum;
		minSpeed: number;
		maxSpeed: number;
		validity: number;
		sendInterval: number;
	}

	export interface SOSParameters extends Serializable {
		modeSos: ModeSos;
		digitalInputID: number;
		sosNumber: string;
	}

	export interface HarshBehaviorMonitoring extends Serializable {
		mode: ModeHBM;
		highSpeed: number;
		mediumSpeed: number;
		vha: number;
		vhb: number;
		vma: number;
		vmb: number;
		vla: number;
		vlb: number;
		corneringThreshold: number;
		coreringDuration: number;
		accelerationThreshold: number;
		accelerationDuration: number;
	}

	export interface JammingDetection extends Serializable {
		mode: JammingModeEnum;
		signalThreshold: number;
		jammingCellNumberThreshold: number;
		enterJammingTimerThreshold: number;
		quitJammingTimerThreshold: number;
	}

	export interface StartStopReport extends Serializable {
		startStopReportMode: boolean;
		timeToStop: number;
		timeToStart: number;
		startSpeed: number;
		loginStop: number;
	}

	export interface JammingBehaviorSetting extends Serializable {
		mode: boolean;
		sirenOnTimer: number;
		sirenOffTimer: number;
		readyFuelRealeseTimer: number;
		checkSpeed: boolean;
		speedLimit: number;
		output1InitState: boolean;
		motionSensor: boolean;
		gpsFixFailureTimeoutTimer: number;
		enableSiren: boolean;
		releaseFuelCutOffTimer: number;
		checkJamingInT3: boolean;
		wiatingReleaseFuelTimer: number;
	}

	export interface AnalogInputPortSetting extends Serializable {
		mode: ModeAis;
		minThreshold: number;
		maxThreshold: number;
		sampleRate: number;
		mode2: ModeAis;
		minThreshold2: number;
		maxThreshold2: number;
		sampleRate2: number;
		voltageMarginError: number;
	}

	export interface DigitalInputPortSettings extends Serializable {
		mode: ModeDisIgn;
		enableD1: boolean;
		enableD2: boolean;
		debounceTimeD1: number;
		debounceTimeD2: number;
		validityTimeD1: number;
		validityTimeD2: number;
		samplePeriod: number;
	}

	export interface CanbusDeviceConfiguration extends Serializable {
		modeEnabled: boolean;
		canReportInterval: number;
		canReportIgf: number;
		vin: boolean;
		ignitionKey: boolean;
		totalDistance: boolean;
		totalFuelUsed: boolean;
		vehicleSpeed: boolean;
		engineRPM: boolean;
		engineCoolantTemperature: boolean;
		fuelConsumption: boolean;
		fuelLevel: boolean;
		range: boolean;
		acceleratorPedalPressure: boolean;
		totalEngineHours: boolean;
		totalDrivingTime: boolean;
		totalEngineIdleTime: boolean;
		totalIdleFuelUsed: boolean;
		axleWeight: boolean;
		tachographInformation: boolean;
		detailedInformation: boolean;
		lights: boolean;
		doors: boolean;
		totalVehicleOverspeedTime: boolean;
		totalVehicleEngineOverSpeedTime: boolean;
		totalDistanceImpulses: boolean;
		canReportExpansionMask: boolean;
		gpsInformation: boolean;
		gsmInformation: boolean;
		modeAdditionalEvent: ModeCanAdditionalEventEnum;
		adBlueLevel: boolean;
		axleWeight1st: boolean;
		axleWeight3rd: boolean;
		axleWeight4th: boolean;
		tachographOverspeedSignal: boolean;
		tachographVehicleMotionSignal: boolean;
		tachographDrivingDirection: boolean;
		analogInputValue: boolean;
		engineBrakingFactor: boolean;
		pedalBrakingFactor: boolean;
		totalAcceleratorKickDowns: boolean;
		totalEffectiveEngineSpeedTime: boolean;
		totalCruiseControlTime: boolean;
		totalAcceleratorKickDownTime: boolean;
		totalBrakeApplication: boolean;
		tachographDriver1CardNumber: boolean;
		tachographDriver2CardNumber: boolean;
		tachographDriver1Name: boolean;
		tachographDriver2Name: boolean;
		registrationNumber: boolean;
		expansionInformation: boolean;
		rapidBraking: boolean;
		rapidAccelerations: boolean;
		engineTorque: boolean;
	}

	export interface AuthenticationIdConfiguration extends Serializable {
		mode: ModeIDAEnum;
		startIndex: number;
		endIndex: number;
		idNumberList: String;
		timeoutAfterIgnitionOff: number;
		modeReport: ModeReportIdEnum;
		validityTime: number;
		outputId: number;
		outputStatus: number;
		duration: number;
		toggleTimes: number;
		modeReportRfid: ModeRFIDReportEnum;
	}

	export interface RequestRelatedEvent extends Event {
		requestType: RequestType;
		requestId: number;
		ownerId: number;
		ownerName: string;
	}

	export interface IncomingMessage extends Message {
		protocolManagerRef: string;
		incomingMessageType: IncomingMessageType;
	}

	export interface VehicleActivityEvent {
		// TODO: Field "id" was missing from legacy code!!!
		$id: any;
		type: VehicleActivityType;
		location: number[];
		s: number;
		e: number;
	}

	// @ts-ignore
	export interface DValue extends Serializable, RestExt.ExtendedDValue {
		dID: number;
		conversionAlgorithmLastUpdate: number;
		tag: string;
		conversionAlgorithmId: number;
		categoricValue: string;
		dType: DType;
		rawValue: any;
		time: number;
	}

	export interface BasicSerializedEntity {
	}

	export interface SpeedEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		minimumSpeed: number;
		maximumSpeed: number;
		speedDuration: number;
		outputPort: SpeedEventOutputPorts;
		outputControl: boolean;
		speedingMode: SpeedEventSpeedingModes;
		offSpeedingDuration: number;
		ledPatternIndex: number;
	}

	export interface AnalogInputFunctionConfiguration extends Trimmable, Serializable {
		id: number;
		analogPort: boolean;
		mode: AnalogInputModes;
		enabled: boolean;
		minimumVoltageLevel: number;
		maximumVoltageLevel: number;
		duration: number;
		outputPort: AnalogInputOutputPorts;
		outputControl: boolean;
	}

	export interface MileageConfiguration extends Trimmable, Serializable {
		id: number;
		mode: MileageConfigModes;
		mileage: number;
	}

	export interface TrackEventConfiguration extends Trimmable, Serializable {
		id: number;
		mode: TrackMode;
		time: number;
		distance: number;
		heading: number;
		trackingTimes: number;
		basis: TrackBasis;
	}

	export interface Track2EventConfiguration extends TrackEventConfiguration, Trimmable, Serializable {
		triggerOnInput1Value: InputValue;
		triggerOnInput2Value: InputValue;
		triggerOnInput3Value: InputValue;
		triggerOnInput4Value: InputValue;
		triggerOnMainPowerLost: boolean;
	}

	export interface Roaming extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		generateEvent: boolean;
	}

	export interface PowerSaving extends Trimmable, Serializable {
		id: number;
		mode: PowerSavingMode;
		delay: number;
		duration: number;
		offRegardlesOfTrack: boolean;
		enableGSensorDetection: boolean;
	}

	export interface DR extends Trimmable, Serializable {
		id: number;
		lowVoltageLevel: number;
		pollingOnInput1: boolean;
		pollingOnInput2: boolean;
		pollingOnInput3: boolean;
		pollingOnInput4: boolean;
		pollingOnMainPowerLow: boolean;
		pollingOnMainPowerLost: boolean;
		pollingOnMainPowerVoltageRecover: boolean;
		pollingOnMainPowerRecover: boolean;
		pollingOnBatteryVoltageLow: boolean;
		pollingOnBatteryVoltageRecover: boolean;
	}

	export interface ACC extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		onThreshold: number;
		offThreshold: number;
		duration: number;
	}

	export interface TowEventConfiguration extends Trimmable, Serializable {
		id: number;
		satellitesFixed: number;
		speedThreshold: number;
		towDuration: number;
		autoReset: number;
		enabled: boolean;
	}

	export interface GPSAntenaCutEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		outputPattern: OutputPattern;
	}

	export interface IgnitionOff extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		delay: number;
	}

	export interface HarshAccelerationEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		speedIncreaseThreshold: number;
		duration: number;
		ledPatternIndex: number;
	}

	export interface HarshBreakingEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		speedDropThreshold: number;
		duration: number;
		ledPatternIndex: number;
	}

	export interface EmergencyStopEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		speedDropThreshold: number;
		duration: number;
		ledPatternIndex: number;
	}

	export interface HarshCorneringEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		headingChangeThreshold: number;
		speedThreshold: number;
		duration: number;
		ledPatternIndex: number;
	}

	export interface IdleEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		speedThreshold: number;
		startDuration: number;
		endDuration: number;
	}

	export interface JamEventConfiguration extends Trimmable, Serializable {
		id: number;
		outputPattern: OutputPattern;
		enabled: boolean;
		onlyOnACCOff: boolean;
	}

	export interface LEDPattern extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		name: string;
		toggleLed1: boolean;
		toggleLed2: boolean;
		toggleLed3: boolean;
		toggleLed4: boolean;
		toggleLed5: boolean;
		toggleLed6: boolean;
		toggleLed7: boolean;
		toggleLed8: boolean;
		toggleLed9: boolean;
		finalValueLed1: boolean;
		finalValueLed2: boolean;
		finalValueLed3: boolean;
		finalValueLed4: boolean;
		finalValueLed5: boolean;
		finalValueLed6: boolean;
		finalValueLed7: boolean;
		finalValueLed8: boolean;
		finalValueLed9: boolean;
		ledToggleTimes: number;
		ledToggleDuration: number;
		buzzerToggleTimes: number;
		buzzerToggleDuration: number;
	}

	export interface RFDetectedEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		port: number;
		delay: number;
	}

	export interface TagInOutEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		tagDetectedTimeInterval: number;
		tagDetectedTimes: number;
		tagUndetectedTimeInterval: number;
		tagUndetectedTimes: number;
	}

	export interface TimeConfiguration extends Trimmable, Serializable {
		id: number;
		enableDoorLock: boolean;
		enableDoorUnlock: boolean;
		timeDoorLock: number;
		timeDoorUnlock: number;
	}

	export interface SOSEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		outputPattern: OutputPattern;
	}

	export interface RFTag extends Serializable {
		tag: string;
	}

	export interface ARM extends Trimmable, Serializable {
		id: number;
		eventAction: ARMEventAction;
		enable: boolean;
		enableSetArm: boolean;
		enableSetDisarm: boolean;
		enableSirenActivation: boolean;
	}

	export interface ACCOnWithoutTagEventConfiguration extends Trimmable, Serializable {
		id: number;
		enabled: boolean;
		outputPattern: OutputPattern;
		tagDetectedTimeInterval: number;
	}

	export interface NoticeChannel extends Trimmable, WithId {
		name: string;
	}

	export interface Vertex extends Trimmable {
		latitude: number;
		longitude: number;
	}

	export interface ProprietaryDeviceParameters extends Trimmable {
		type: Types;
	}

	export interface DeviceParameters extends Trimmable, WithId {
		sensors: Sensor[];
		actuators: Actuator[];
		proprietaryParameters: any;
	}

	export interface SensorChartSettings extends Trimmable {
		id: number;
		sensorTag: string;
		showCategoricalValue: boolean;
		categoricalValuesMappings: { [index: string]: CategoricalValueMapping };
		numericalValuesColors: { [index: string]: string };
		filterType: DEnum;
	}

	export interface DTagAndValue {
		dEnum: DEnum;
		tagAndValues: { [index: string]: CategoricalValue[] };
	}

	export interface RTFleet {
		id: number;
		name: string;
		groups: RTVehiclesGroup[];
	}

	export interface SimpleDeviceValue extends Trimmable {
		timestamp: Date;
		numericValue: number;
		categoricValue: string;
	}

	export interface VehicleIdNamePair {
		id: number;
		name: string;
	}

	export interface RoleProfilePair extends Trimmable, WithId {
		user: User;
		owner: User;
		role: Role;
		profile: Profile;
	}

	export interface Roleprofilehierarchy extends Trimmable, WithId {
		data: RoleProfileData[];
		error: string;
		ok: boolean;
	}

	export interface RoleProfileData extends Trimmable, WithId {
		level: number;
		profile: Profile;
		role: Role;
	}

	export interface VehicleBrand extends Trimmable, WithId {
		name: string;
		models: VehicleModel[];
		client: Client;
		logo: Icon;
		disabled: boolean;
	}

	export interface VehicleModel extends Trimmable, WithId {
		name: string;
		type: VehicleType;
		brand: VehicleBrand;
		client: Client;
		disabled: boolean;
	}

	export interface VehicleType extends Trimmable, WithId {
		name: string;
		models: VehicleModel[];
	}

	export interface VehicleVersion extends Trimmable, WithId {
		name: string;
		model: VehicleModel;
		cylinderCapacity: number;
		consumption: number;
		emissions: number;
		fuelCapacity: number;
		fuelType: FuelType;
		observations: string;
		client: Client;
		disabled: boolean;
	}

	export interface TableFilterSelectItem {
		label: string;
		value: string;
	}

	export interface Serializable {
	}

	export interface TimedEntity {
		time: number;
	}

	export interface OutputPattern extends Serializable {
		id: number;
		port: number;
		enable: boolean;
		setOn: boolean;
		toggle: boolean;
		toggleDuration: number;
		toggleTimes: number;
	}

	export interface Sensor extends Trimmable, WithId {
		name: string;
		tag: string;
		type: DType;
		port: number;
		externalId: string;
		conversionAlgorithm: ConversionAlgorithm;
		parameters: DeviceParameters;
	}

	export interface CategoricalValueMapping {
		color: string;
		keyTranslation: string;
	}

	export interface RTVehiclesGroup {
		id: number;
		name: string;
		iconURL: string;
		vehicles: RTVehicle[];
	}

	export interface RTVehicle {
		id: number;
		name: string;
	}

	export interface proximityVehicleDialog {
		id: number;
		fleetName: string;
		groupName: string;
		vehicleName: string;
		distance: string;
		time: string;
	}

	export interface SimOperatorType {
		id: number;
		name: string;
	}

	export interface SimDevicesPlanGroups {
		id: number;
		device: Device;
		groupId: SimPlanGroups;
		planId: SimPlans;
		simActivationDate: Date;
		simExpirationDate: Date;
		bajaSim: boolean;
		fechaBajaSim: Date;
		mesesPrimeraActivacion: number;
		lastDateNotification: Date;
	}

	export interface SimPlanGroups {
		id: number;
		name: string;
		description: string;
		simOperatorToken: SimOperatorToken;
		plans: SimPlans[];
	}

	export interface RawMessage extends IncomingMessage {
		raw: string;
		ip: string;
		port: number;
	}

	export interface SimPlans {
		id: number;
		name: string;
		description: string;
		price: number;
		expired: number;
		expirationDate: Date;
		M2McommercialGroupId: number;
		productWebId: number;
		translationLabel: string;
		simOperatorType: SimOperatorType;
		groups: SimPlanGroups[];
	}

	export interface SimOperatorToken {
		id: number;
		operatorId: number;
		description: string;
		usernameOp: string;
		passwordOp: string;
		urlBasicOp: string;
		token: string;
		tokenRefresh: string;
		dateIni: Date;
		dateEndToken: Date;
		dateEndTokenRefresh: Date;
		simPlanGroup: SimPlanGroups;
	}

	//Raquel: racings
	export interface Racing extends Trimmable {
		id: number;
		nameEvent: string;
		eventType: EventTypeRacing;
		eventCodeType: EventCodeTypeRacing;
		infoEvent: string;
		startPublicationDate: string;
		endPublicationDate: string;
		startDate: string;
		endDate: string;
		track: imageRacing;
		urlLegalText: string;
		deleted: boolean;
		deletedDate: Date;
		logoEvent: imageRacing;
		numPersons: number;
		usersRegistered: number;
		client: ClientRacing;
		poi: POIRacing[];
		codeEvents: CodesEvents[];
	}

	export interface POIRacing extends Trimmable, WithId {
		// TODO: Field "_$update" was missing from legacy code!!!
		id: number;
		namePoi: string;
		infoTxt: string;
		imagePoi: imageRacing;
		blogUrl: string;
		videoUrl: string;
		latitude: number;
		longitude: number;
		deleted: boolean;
		dateDeleted: Date | null;
		itemIcon: IconRacing;
		event: {
			id: number;
		};
	}

	export interface IconRacing extends Trimmable {
		id: number;
		iconId: Icon;
		client: ClientRacing;
		nameIcon: string;
	}

	export interface imageRacing extends Trimmable {
		id: number;
		name: string;
		entity: string;
		path: string;
		imageEncoded: string;
	}

	export interface EventTypeRacing extends Trimmable {
		id: number;
		nameEvent: string;
	}

	export interface EventCodeTypeRacing extends Trimmable {
		id: number;
		nameCode: string;
		uniqueCode: boolean;
	}

	export interface ClientRacing extends Trimmable {
		id: number;
		owner: User;
		name: string;
		enabled: boolean;
	}

	export interface CodesEvents extends Trimmable {
		id: number;
		code: string;
		usersRegistered: number;
		deleted: boolean;
		dateDeleted: Date;
	}

	export interface Credits extends Trimmable {
		id: number;
		client: Client;
		purchaseDate: Date;
		credits: number;
		costSMS: number;
		costEmail: number;
		user: User;
		creditPrice: number;
	}

	export interface CreditsHistory extends Trimmable {
		id: number;
		client: Client;
		purchaseDate: Date;
		credits: number;
		costSMS: number;
		costEmail: number;
		import: number;
		creditsBeforePurchase: number;
		user: User;
		creditPrice: number;
		actionUser: User;
	}

	export interface CreditsUserSAT extends Trimmable {
		currentCredits: Credits;
		historicCredits: CreditsHistory[];
	}

	/* <--- TERMINALS ---> */

	export interface Terminal extends WithId {
		user: User;
		platformId: PlatformApp;
		deviceToken: string;
		deviceDesc: string;
		language: Language;
		pushReception: boolean;
		appVersion: string;
	}

	export interface Tacograph extends Trimmable {
		id: number;
		file: Icon;
		downloadDate: Date;
		startDate: Date;
		endDate: Date;
	}

	export type TacographEnum = 'DRIVER' | 'VEHICLE';

	export interface TacographType extends Trimmable, WithId {
		name: string;
	}

	export interface ReportFiles extends Trimmable {
		id: number;
		owner: User;
		reportDate: Date;
		reportExecutionDate: Date;
		fileName: String;
		reportType: ReportTypes;
		entityType: ReportFileEntityType;
		entityId: number;
		periodType: ReportFilePeriodType;
		entityName: String;
	}

	export interface ReportTypes extends Trimmable {
		id: number;
		name: string;
	}

	export interface UnitMeasure extends Trimmable {
		id: number;
		unitName: string;
		unit_of_measure: string;

	}
	export interface RoutesCategory extends Trimmable {
		id: number;
		nameCategory: string;
		labelCategory: string;
	}

	/*****************************************************************/
	/*********  PROGRAMA DE FIDELITZACI�  **************  ini ********/
	/*****************************************************************/
	export interface FidProvider extends Trimmable {
		id: number;
		name: string;
		concession: string;
		adress: string;
		cp: string;
		city: string;
		state: string;
		codeCountry: string;
		web: string;
		email: string;
		phone: string;
		schedule: string;
		providerManagers: User[];
		logo: Icon;
	}

	export interface FidCampaigns extends Trimmable {
		id: number;
		provider: FidProvider;
		name: string;
		nameCat: string;
		nameEng: string;
		nameFra: string;
		nameIta: string;
		description: string;
		descriptionCat: string;
		descriptionEng: string;
		descriptionFra: string;
		descriptionIta: string;
		textColor: string;
		startDate: Date;
		endDate: Date;
		infoLink: string;
		infoLinkEng: string;
		infoLinkCat: string;
		infoLinkFra: string;
		infoLinkIta: string;
		buyLink: string;
		buyLinkCat: string;
		buyLinkEng: string;
		buyLinkFra: string;
		buyLinkIta: string;
		createdByUser: User;
		creationDate: Date;
		expirationDate: Date;
		targets: FidTargets[];
		campaignType: FidCampaignType;
		active: boolean;
		image: Icon;
	}

	export interface FidTargets extends Trimmable {
		id: number;
		campaign: FidCampaigns;
		name: string;
		description: string;
		createdByUser: User;
		creationDate: Date;
		filters: FidFilters[];
	}

	export interface FidFilters extends Trimmable {
		id: number;
		target: FidTargets;
		parameter: FidParameters;
		filterType: FidFilterType;
		value: string;
	}

	export interface FidSpace extends Trimmable {
		id: number;
		campaign: FidCampaigns;
		routes: FidRoute[];
	}

	export interface FidRoute extends Trimmable {
		id: number;
		nameRoute: string;
		nameRouteCat: string;
		nameRouteEng: string;
		nameRouteFra: string;
		nameRouteIta: string;
		shortDescription: string;
		shortDescriptionCat: string;
		shortDescriptionEng: string;
		shortDescriptionFra: string;
		shortDescriptionIta: string;
		description: string;
		descriptionCat: string;
		descriptionEng: string;
		descriptionFra: string;
		descriptionIta: string;
		space: FidSpace;
		enabled: boolean;
		datePublication: Date;
		numPos: number;
		category: RoutesCategory;
		totalKms: number;
		totalTime: string;
		totalTimeCat: string;
		totalTimeEng: string;
		totalTimeFra: string;
		totalTimeIta: string;
		image: Icon;
		track: Icon;
		infoLink: string;
		infoLinkCat: string;
		infoLinkEng: string;
		infoLinkFra: string;
		infoLinkIta: string;
		sharedLink: string;
		sharedLinkCat: string;
		sharedLinkEng: string;
		sharedLinkFra: string;
		sharedLinkIta: string;
		activitiesLink: string;
		activitiesLinkCat: string;
		activitiesLinkEng: string;
		activitiesLinkFra: string;
		activitiesLinkIta: string;
		pois: FidRoutePOI[];
	}

	export interface FidRoutePOI extends Trimmable {
		id: number;
		itemIcon: IconRacing;
		imagePoi: Icon;
		name: string;
		description: string;
		link1: string;
		link2: string;
		latitude: number;
		longitude: number;
		enabled: boolean;
		route: {
			id: number;
		};
	}

	export interface Translations extends Trimmable {
		esp: string;
		cat: string;
		eng: string;
		fra: string;
		ita: string;
		textArea: boolean;
		field: string;
		type: string;
		id: number;
	}

	export interface FidRouteCategory extends Trimmable {
		id: number;
		nameCategory: string;
		labelCategory: string;
	}

	export interface FullInfoParameters extends Trimmable {
		parameter: string;
		valueType: string;
		filterTypes: string[];
	}

	export interface ConfigurationColoring extends Trimmable {
		id: number;
		client: Client;
		coloring: ColoringInfo[];
	}

	export interface ColoringInfo extends Trimmable {
		rol: String;
		info: ColorInfo[];
	}

	export interface ColorInfo extends Trimmable {
		condition: String;
		value: String;
	}

	export interface FidSendNotifControl extends Trimmable {
		id: number;
		campaign: FidCampaigns;
		user: User;
		startProcesTime: Date;
		endProcesTime: Date;
		recipientsAmount: number;
		inboxInsert: number;
		pushSent: number;
		logInsert: number;
		state: String;
	}

	export interface refreshDocListRequest extends Trimmable {
		vehicleId?: number;
		type?: string;
		startDate?: Date;
		endDate?: Date;
		newDoc?: number;
	}


	export interface InventoryReports extends Trimmable, WithId {
		name: string;
		description: string;
		reportType: ReportType;
		icon: Icon;
		client: Client;
	}

	export interface InventoryReportRequest extends Trimmable {
		report: InventoryReport;
		filters: { [index: string]: string[] };
		parameters: string[]
	}

	export interface ReportResponse extends Trimmable {
		driverCode: ReportResponseItem;
		driverName: ReportResponseItem;
		billingCode: ReportResponseItem;
		billingName: ReportResponseItem;
		licenseExpirationDate: ReportResponseItem;
	}

	export interface ReportResponseItem extends Trimmable {
		field: string;
		value: string;
		status: string;
	}

	export interface MileageStats extends Trimmable {
		contrato: Financing;
		days: number;
		months: number;
		lastAU: Mileage;
		lastCO: Mileage;
		kmTheoric: number;
		kmsDesviation: number;
		kmsDay: number;
		kmsMonth: number;
		kmsEndCto: number;
		usefulLifeMonths: number;
		usefulLifeKms: number;
		usefulLifeDesviation: number;
		lastAssignmentDate: Date;
		lastAssignmentDays: number;
		lastAssignmentKmsDay: number;
		lastAssignmentKmsMonth: number;
		lastMonthDate: Date;
		lastMonthDays: number;
		lastMonthKmsDay: number;
		lastMonthKmsMonth: number;
		lastTrimDate: Date;
		lastTrimDays: number;
		lastTrimKmsDay: number;
		lastTrimKmsMonth: number;
		htcDate: Date;
		htcDays: number;
		htcKmsDay: number;
		htcKmsMonth: number;
		averageKmsDay: number;
		averageKmsMonth: number;
		errorTag: String;
	}

	/*****************************************************************/
	/*********  PROGRAMA DE FIDELITZACI�  **************  fin ********/
	/*****************************************************************/

	export interface HttpClient {
		request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R }): RestResponse<R>;
	}

	export class RestApplicationClient {
		constructor(protected httpClient: HttpClient) {
		}

		/**
		 * HTTP POST /api/action/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ActionController.Action_getPage
		 */
		Action_getPage(arg0: ListPaginationRequest): RestResponse<Page<Action>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/action/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/actuator/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ActuatorController.Actuator_getPage
		 */
		Actuator_getPage(arg0: ListPaginationRequest): RestResponse<Page<Actuator>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/actuator/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/billingAccount
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_create
		 */
		BillingAccount_create(arg0: BillingAccount): RestResponse<BillingAccount> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/billingAccount`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/billingAccount
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_update
		 */
		BillingAccount_update(arg0: BillingAccount): RestResponse<BillingAccount> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/billingAccount`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/billingAccount/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_getPage
		 */
		BillingAccount_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<BillingAccount>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/billingAccount/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/billingAccount/list
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_getPage
		 */
		BillingAccount_getAll(clientId?: number): RestResponse<BillingAccount[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/billingAccount/client` + clientId
			});
		}

		/**
		 * HTTP POST /api/billingAccount/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_getPageAndFilters
		 */
		BillingAccount_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<BillingAccount>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/billingAccount/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/billingAccount/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_changeStatus
		 */
		BillingAccount_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/billingAccount/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/billingAccount/{billingAccount}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.BillingAccountController.BillingAccount_find
		 */
		BillingAccount_find(arg0: string, queryParams?: { id?: number }): RestResponse<BillingAccount> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/billingAccount/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/inventory/cardconsumption
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.CardConsumptionController.CardConsumption_create
		 */
		CardConsumption_create(arg0: CardConsumption): RestResponse<CardConsumption> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/cardconsumption`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/inventory/cardconsumption
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.CardConsumptionController.CardConsumption_update
		 */
		CardConsumption_update(arg0: CardConsumption): RestResponse<CardConsumption> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/cardconsumption`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/inventory/cardconsumption/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.CardConsumptionController.CardConsumption_getPage
		 */
		CardConsumption_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<CardConsumption>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/cardconsumption/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/inventory/cardconsumption
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.CardConsumptionController.CardConsumption_find
		 */
		CardConsumption_find(arg0: string, queryParams?: { id?: number }): RestResponse<CardConsumption> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/cardconsumption/${arg0}`,
				queryParams: queryParams,
			});
		}

		CardConsumption_summary(arg0: number): RestResponse<CardConsumptionSummary> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/cardconsumption/summary/${arg0}`,
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_create
		 */
		Accessory_create(arg0: Accessory): RestResponse<Accessory> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/extrastype`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Accessory_update
		 */
		Accessory_update(arg0: Accessory): RestResponse<Accessory> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/extrastype`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Accessory_getPage
		 */
		Accessory_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Accessory>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/extrastype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Accessory_getPageAndFilters
		 */
		Accessory_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Accessory>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/extrastype/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Accessory_changeStatus
		 */
		Accessory_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/extrastype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Accessory_find
		 */
		Accessory_find(arg0: string, queryParams?: { id?: number }): RestResponse<Accessory> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/extrastype/${arg0}`,
				queryParams: queryParams,
			});
		}

		Accessory_getAll(arg0: string, queryParams?: { id?: number }): RestResponse<Accessory[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/extrastype/client${arg0}`,
				queryParams: queryParams,
			});
		}


		/**
		 * HTTP POST /api/inventory/provider
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_update
		 */
		Provider_create(arg0: Provider): RestResponse<Provider> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/provider`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/inventory/provider/update
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_update
		 */
		Provider_update(arg0: Provider): RestResponse<Provider> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/provider/update`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPage
		 */
		Provider_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Provider>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/provider/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPageAndFilters
		 */
		Provider_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Provider>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/provider/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_changeStatus
		 */
		Provider_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/provider/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_find
		 */
		Provider_find(arg0: string, queryParams?: { id?: number }): RestResponse<Provider> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/provider/${arg0}`,
				queryParams: queryParams,
			});
		}

		Provider_getTypes(): RestResponse<ProviderType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/providertype/all`,
			});
		}


		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_create
		 */
		Leader_create(arg0: InvLeader): RestResponse<InvLeader> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/leaders`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_update
		 */
		Leader_update(arg0: InvLeader): RestResponse<InvLeader> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/leaders`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPage
		 */
		Leader_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InvLeader>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/leaders/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPageAndFilters
		 */
		Leader_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<InvLeader>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/leaders/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_changeStatus
		 */
		Leader_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/leaders/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_find
		 */
		Leader_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvLeader> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/leaders/${arg0}`,
				queryParams: queryParams,
			});
		}

		Leader_getList(arg0: any): RestResponse<InvLeader[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/leaders/client${arg0}`
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_create
		 */
		Ceco_create(arg0: Ceco): RestResponse<Ceco> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/ceco`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_update
		 */
		Ceco_update(arg0: Ceco): RestResponse<Ceco> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/ceco`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPage
		 */
		Ceco_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Ceco>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/ceco/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPageAndFilters
		 */
		Ceco_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Ceco>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/ceco/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_changeStatus
		 */
		Ceco_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/ceco/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_find
		 */
		Ceco_find(arg0: string, queryParams?: { id?: number }): RestResponse<Ceco> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/ceco/${arg0}`,
				queryParams: queryParams,
			});
		}

		Ceco_getList(billingAccountId: number): RestResponse<Ceco[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/ceco/billingAccount${billingAccountId}`,
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_create
		 */
		VehicleUsage_create(arg0: VehicleUsage): RestResponse<VehicleUsage> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleusage`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.VehicleUsage_update
		 */
		VehicleUsage_update(arg0: VehicleUsage): RestResponse<VehicleUsage> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleusage`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.VehicleUsage_getPage
		 */
		VehicleUsage_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<VehicleUsage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleusage/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.VehicleUsage_getPageAndFilters
		 */
		VehicleUsage_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<VehicleUsage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleusage/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.VehicleUsage_changeStatus
		 */
		VehicleUsage_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleusage/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.VehicleUsage_find
		 */
		VehicleUsage_find(arg0: string, queryParams?: { id?: number }): RestResponse<VehicleUsage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleusage/${arg0}`,
				queryParams: queryParams,
			});
		}

		VehicleUsage_getList(clientId: number): RestResponse<VehicleUsage[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleusage/client${clientId}`
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_create
		 */
		Delegation_create(arg0: Delegation): RestResponse<Delegation> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/delegation`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_update
		 */
		Delegation_update(arg0: Delegation): RestResponse<Delegation> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/delegation`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_getPage
		 */
		Delegation_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Delegation>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/delegation/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_getPageAndFilters
		 */
		Delegation_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Delegation>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/delegation/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_changeStatus
		 */
		Delegation_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/delegation/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Delegation_find
		 */
		Delegation_find(arg0: string, queryParams?: { id?: number }): RestResponse<Delegation> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/delegation/${arg0}`,
				queryParams: queryParams,
			});
		}

		Delegation_getList(clientId: number): RestResponse<Delegation[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/delegation/client${clientId}`,
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_create
		 */
		Subdelegation_create(arg0: Subdelegation): RestResponse<Subdelegation> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdelegation`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_update
		 */
		Subdelegation_update(arg0: Subdelegation): RestResponse<Subdelegation> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/subdelegation`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_getPage
		 */
		Subdelegation_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Subdelegation>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdelegation/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_getPageAndFilters
		 */
		Subdelegation_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Subdelegation>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdelegation/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_changeStatus
		 */
		Subdelegation_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/subdelegation/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subelegation_find
		 */
		Subdelegation_find(arg0: string, queryParams?: { id?: number }): RestResponse<Subdelegation> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/subdelegation/${arg0}`,
				queryParams: queryParams,
			});
		}

		Subdelegation_getList(delegationId: number): RestResponse<Subdelegation[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/subdelegation/delegation${delegationId}`,
			});
		}

		Subdelegation_getListByClient(clientId: number): RestResponse<Subdelegation[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/subdelegation/client${clientId}`,
			});
		}

		/**
				/**
		 *
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_create
		 */
		Division_create(arg0: Division): RestResponse<Division> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/division`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_update
		 */
		Division_update(arg0: Division): RestResponse<Division> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/division`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_getPage
		 */
		Division_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Division>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/division/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_getPageAndFilters
		 */
		Division_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Division>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/division/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_changeStatus
		 */
		Division_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/division/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Division_find
		 */
		Division_find(arg0: string, queryParams?: { id?: number }): RestResponse<Division> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/division/${arg0}`,
				queryParams: queryParams,
			});
		}

		Division_getList(clientId: number): RestResponse<Division[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/division/client${clientId}`,
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_create
		 */
		Subdivision_create(arg0: Subdivision): RestResponse<Subdivision> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdivision`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_update
		 */
		Subdivision_update(arg0: Subdivision): RestResponse<Subdivision> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/subdivision`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_getPage
		 */
		Subdivision_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Subdivision>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdivision/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_getPageAndFilters
		 */
		Subdivision_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Subdivision>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/subdivision/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_changeStatus
		 */
		Subdivision_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/subdivision/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Subdivision_find
		 */
		Subdivision_find(arg0: string, queryParams?: { id?: number }): RestResponse<Subdivision> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/subdivision/${arg0}`,
				queryParams: queryParams,
			});
		}

		Subdivision_getList(clientId: number): RestResponse<Subdivision[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/subdivision/client${clientId}`,
			});
		}

		/*
		 * HTTP POST /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_create
		 */
		Mileage_create(arg0: any): RestResponse<Mileage> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/kmreading`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_update
		 */
		Mileage_update(arg0: any): RestResponse<Mileage> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/kmreading`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_getPage
		 */
		Mileage_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Mileage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/kmreading/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_changeStatus
		 */
		Mileage_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/kmreading/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_getPageAndFilters
		 */
		Mileage_find(arg0: string, queryParams?: { id?: number }): RestResponse<Mileage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/kmreading/${arg0}`,
				queryParams: queryParams,
			});
		}


		/*
		 * HTTP POST /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_create
		 */
		FinanceType_create(arg0: FinanceType): RestResponse<FinanceType> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/financingtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_update
		 */
		FinanceType_update(arg0: FinanceType): RestResponse<FinanceType> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/financingtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_getPage
		 */
		FinanceType_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<FinanceType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/financingtype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_changeStatus
		 */
		FinanceType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/financingtype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_getPageAndFilters
		 */
		FinanceType_find(arg0: string, queryParams?: { id?: number }): RestResponse<FinanceType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/financingtype/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/rentingtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RentingType_create
		 */
		RentingType_create(arg0: RentingType): RestResponse<RentingType> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/rentingtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/rentingtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RentingType_update
		 */
		RentingType_update(arg0: RentingType): RestResponse<RentingType> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/rentingtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/rentingtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RentingType_getPage
		 */
		RentingType_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<RentingType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/rentingtype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/rentingtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RentingType_changeStatus
		 */
		RentingType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/rentingtype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP GET /api/inventory/rentingtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RentingType_find
		 */
		RentingType_find(arg0: string, queryParams?: { id?: number }): RestResponse<RentingType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/rentingtype/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/financing
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Financing_create
		 */
		Financing_create(arg0: Financing): RestResponse<Financing> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/financing`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/financing/update
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Financing_update
		 */
		Financing_update(arg0: Financing): RestResponse<Financing> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/financing/update`,
				data: arg0,
			});
		}

		/**
		 *
		 * GET /api/inventory/financing/document
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Financing_getDocument
		 */
		Financing_getDocument(arg0: string, queryParams?: { id?: number }): RestResponse<Financing> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/financing/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * DELETE /api/inventory/financing
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Financing_delete
		 */
		Financing_delete(arg0: Financing[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/inventory/financing`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/financing/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Financing_getPage
		 */
		Financing_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Financing>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/financing/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FinanceType_changeStatus
		 */
		Financing_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/financing/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Financing_addDocument(queryParams): Rest.RestResponse<Rest.Financing> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/financing/add`,
				queryParams: queryParams
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/finesstatus
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FineStatus_create
		 */
		FineStatus_create(arg0: FineStatus): RestResponse<FineStatus> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/finesstatus`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/finesstatus
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FineStatus_update
		 */
		FineStatus_update(arg0: FineStatus): RestResponse<FineStatus> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/finesstatus`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/finesstatus/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FineStatus_getPage
		 */
		FineStatus_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<FineStatus>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/finesstatus/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/finesstatus/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FineStatus_changeStatus
		 */
		FineStatus_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/finesstatus/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/finesstatus/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.FineStatus_getPageAndFilters
		 */
		FineStatus_find(arg0: string, queryParams?: { id?: number }): RestResponse<FineStatus> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/finesstatus/${arg0}`,
				queryParams: queryParams,
			});
		}


		FineReasons_create(arg0: FineReasons): RestResponse<FineReasons> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/finesreasons`,
				data: arg0,
			});
		}

		FineReasons_update(arg0: FineReasons): RestResponse<FineReasons> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/finesreasons`,
				data: arg0,
			});
		}

		FineReasons_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<FineReasons>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/finesreasons/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		FineReasons_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/finesreasons/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		FineReasons_find(arg0: string, queryParams?: { id?: number }): RestResponse<FineReasons> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/finesreasons/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/fines
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_create
		 */
		Fine_create(arg0): RestResponse<Fine> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/fines`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/fines
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_update
		 */
		Fine_update(arg0): RestResponse<Fine> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/fines/update`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/fines/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_getPage
		 */
		Fine_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Fine>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/fines/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_getPageAndFilters
		 */
		Fine_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Fine>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/fines/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/fines/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_changeStatus
		 */
		Fine_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/fines/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/fines/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Fine_find
		 */
		Fine_find(arg0: string, queryParams?: { id?: number }): RestResponse<Fine> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/fines/${arg0}`,
				queryParams: queryParams,
			});
		}

		Fine_addDocument(queryParams): Rest.RestResponse<Rest.Fine> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/fines/add`,
				queryParams: queryParams
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/taxes
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_create
		 */
		Tax_create(arg0: Tax): RestResponse<Tax> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/taxes`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/taxes
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_update
		 */
		Tax_update(arg0: Tax): RestResponse<Tax> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/taxes`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/taxes/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_getPage
		 */
		Tax_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Tax>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/taxes/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_getPageAndFilters
		 */
		Tax_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Tax>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/taxes/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/taxes/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_changeStatus
		 */
		Tax_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/taxes/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/taxes/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Tax_find
		 */
		Tax_find(arg0: string, queryParams?: { id?: number }): RestResponse<Tax> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/taxes/${arg0}`,
				queryParams: queryParams,
			});
		}

		Tax_addDocument(queryParams): Rest.RestResponse<Rest.Tax> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/taxes/add`,
				queryParams: queryParams
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.WayToPay_create
		 */
		WayToPay_create(arg0: WayToPay): RestResponse<WayToPay> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/waytopay`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.WayToPay_update
		 */
		WayToPay_update(arg0: WayToPay): RestResponse<WayToPay> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/waytopay`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.WayToPay_getPage
		 */
		WayToPay_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<WayToPay>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/waytopay/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.WayToPay_changeStatus
		 */
		WayToPay_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/waytopay/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.WayToPay_getPageAndFilters
		 */
		WayToPay_find(arg0: string, queryParams?: { id?: number }): RestResponse<WayToPay> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/waytopay/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/card
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Card_create
		 */
		Card_create(arg0: Card): RestResponse<Card> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/card`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/card
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Card_update
		 */
		Card_update(arg0: Card): RestResponse<Card> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/card`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/card/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Card_getPage
		 */
		Card_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Card>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/card/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/card/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Card_changeStatus
		 */
		Card_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/card/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/card/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Card_getPageAndFilters
		 */
		Card_find(arg0: string, queryParams?: { id?: number }): RestResponse<Card> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/card/${arg0}`,
				queryParams: queryParams,
			});
		}

		Card_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Card>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/card/pageAndFilters`,
				data: arg0,
			});
		}

		Card_getList(arg0: string, queryParams?: { id?: number }): RestResponse<Card[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/card/client${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.CardType_create
		 */
		CardType_create(arg0: CardType): RestResponse<CardType> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/cardtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/cardtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.CardType_update
		 */
		CardType_update(arg0: CardType): RestResponse<CardType> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/cardtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.CardType_getPage
		 */
		CardType_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<CardType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/cardtype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.CardType_changeStatus
		 */
		CardType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/cardtype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/cardtype/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.CardType_getPageAndFilters
		 */
		CardType_find(arg0: string, queryParams?: { id?: number }): RestResponse<CardType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/cardtype/${arg0}`,
				queryParams: queryParams,
			});
		}

		InvHtcCard_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InvHtcCard>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/cardsHtc/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvHtcVehicleStatus_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InvHtcVehicleStatus>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleStatusHtc/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvHtcVehicleUsage_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InvHtcVehicleUsage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleUsageHtc/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/realState
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RealState_update
		 */
		RealState_create(arg0: RealState): RestResponse<RealState> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/inventory/realstate`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/realState
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RealState_update
		 */
		RealState_update(arg0: RealState): RestResponse<RealState> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle/inventory/realstate`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/realState/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RealState_getPage
		 */
		RealState_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<RealState>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/inventory/realstate/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/realState/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RealState_changeStatus
		 */
		RealState_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle/inventory/realstate/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP GET /api/inventory/realState/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.RealState_getPageAndFilters
		 */
		RealState_find(arg0: string, queryParams?: { id?: number }): RestResponse<RealState> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicle/inventory/realstate/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurance
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Insurance_create
		 */
		Insurance_create(arg0: Insurance): RestResponse<Insurance> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurance`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurance/update
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Insurance_update
		 */
		Insurance_update(arg0: Insurance): RestResponse<Insurance> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurance/update`,
				data: arg0,
			});
		}

		/**
		 *
		 * GET /api/inventory/insurance/document
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Insurance_getDocument
		 */
		Insurance_getDocument(arg0: string, queryParams?: { id?: number }): RestResponse<Insurance> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/insurance/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * DELETE /api/inventory/insurance
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Insurance_delete
		 */
		Insurance_delete(arg0: Insurance[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/inventory/insurance`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurance/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Insurance_getPage
		 */
		Insurance_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Insurance>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurance/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Insurance_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/insurance/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Insurance_addDocument(queryParams): Rest.RestResponse<Rest.Insurance> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurance/add`,
				queryParams: queryParams
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancecomponent
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_create
		 */
		InsuranceComponent_create(arg0: InsuranceComponent): RestResponse<InsuranceComponent> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurancecomponent`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/insurancecomponent
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_update
		 */
		InsuranceComponent_update(arg0: InsuranceComponent): RestResponse<InsuranceComponent> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/insurancecomponent`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancecomponent/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_getPage
		 */
		InsuranceComponent_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InsuranceComponent>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurancecomponent/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancecomponent/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_getPage
		 */
		InsuranceComponent_calculateQuarterly(arg0: Rest.InsuranceQuarterlyParameters): RestResponse<any> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurancecomponent/quarterly`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP GET /api/inventory/insurancecomponent
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_find
		 */
		InsuranceComponent_find(arg0: string, queryParams?: { id?: number }): RestResponse<InsuranceComponent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/insurancecomponent/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/insurancecomponent/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceComponent_changeStatus
		 */
		InsuranceComponent_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/insurancecomponent/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancetype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceType_create
		 */
		InsuranceType_create(arg0: InsuranceType): RestResponse<InsuranceType> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurancetype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/insurancetype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceType_update
		 */
		InsuranceType_update(arg0: InsuranceType): RestResponse<InsuranceType> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/insurancetype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancetype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceType_getPage
		 */
		InsuranceType_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InsuranceType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/insurancetype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/insurancetype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceType_changeStatus
		 */
		InsuranceType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/insurancetype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP GET /api/inventory/insurancetype/{id}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.InsuranceType_getPageAndFilters
		 */
		InsuranceType_find(arg0: string, queryParams?: { id?: number }): RestResponse<InsuranceType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/insurancetype/${arg0}`,
				queryParams: queryParams,
			});
		}

		VehicleStatus_create(arg0: VehicleStatus): RestResponse<VehicleStatus> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehiclestatus`,
				data: arg0,
			});
		}

		VehicleStatus_update(arg0: VehicleStatus): RestResponse<VehicleStatus> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehiclestatus`,
				data: arg0,
			});
		}

		VehicleStatus_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<VehicleStatus>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehiclestatus/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleStatus_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehiclestatus/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleStatus_find(arg0: string, queryParams?: { id?: number }): RestResponse<VehicleStatus> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehiclestatus/${arg0}`,
				queryParams: queryParams,
			});
		}

		//VehicleVersion
		VehicleVersion_getAll(modelId?: number): RestResponse<Rest.VehicleVersion[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleVersion/model` + modelId
			});
		}

		//VehicleVersion
		VehicleVersion_getByClient(clientId?: number): RestResponse<Rest.VehicleVersion[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleVersion/client` + clientId
			});
		}

		//VehicleStatus
		VehicleStatus_getAll(clientId?: number): RestResponse<Rest.VehicleStatus[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehiclestatus/client` + clientId
			});
		}

		//VehicleAssigments
		InvVehicleAssignments_getHtc(arg0: any): RestResponse<InvVehicleAssignment[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleassignments/htc`,
				data: arg0
			});
		}

		InvVehicleAssignments_create(arg0: any): RestResponse<InvVehicleAssignment> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleassignments`,
				data: arg0
			});
		}

		InvVehicleAssignments_update(arg0: any): RestResponse<InvVehicleAssignment> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleassignments`,
				data: arg0
			});
		}

		InvVehicleAssignments_getIvaTypes(): RestResponse<InvIvaType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleassignments/enum/ivatypes`
			});
		}

		InvVehicleAssignments_getPage(arg0: ListPaginationRequest): RestResponse<Page<InvVehicleAssignment>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleassignments/page`,
				data: arg0,
			});
		}

		InvVehicleAssignments_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<InvVehicleAssignment>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleassignments/pageAndFilters`,
				data: arg0,
			});
		}

		InvVehicleAssignments_getList(clientId: number): RestResponse<InvVehicleAssignment[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleassignments/client${clientId}`,
			});
		}

		InvVehicleAssignments_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleassignments/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvVehicleAssignments_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvVehicleAssignment> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleassignments/${arg0}`,
				queryParams: queryParams,
			});
		}

		//DriverAssigments
		InvDriverAssignments_getHtc(arg0: any): RestResponse<InvDriverAssignment[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverassignments/vehiclehtc`,
				data: arg0
			});
		}

		InvDriverAssignments_create(arg0: any): RestResponse<InvDriverAssignment> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverassignments`,
				data: arg0
			});
		}

		InvDriverAssignments_update(arg0: any): RestResponse<InvDriverAssignment> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driverassignments`,
				data: arg0
			});
		}

		InvDriverAssignments_getPage(arg0: ListPaginationRequest): RestResponse<Page<InvDriverAssignment>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverassignments/page`,
				data: arg0,
			});
		}

		InvDriverAssignments_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<InvDriverAssignment>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverassignments/pageAndFilters`,
				data: arg0,
			});
		}

		InvDriverAssignments_getList(clientId: number): RestResponse<InvDriverAssignment[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driverassignments/client${clientId}`,
			});
		}

		InvDriverAssignments_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driverassignments/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvDriverAssignments_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvDriverAssignment> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driverassignments/${arg0}`,
				queryParams: queryParams,
			});
		}

		/** INV VEHICLE ACCESSORIES **/
		InvVehicleAccessory_create(arg0: InvVehicleAccessory): RestResponse<InvVehicleAccessory> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleaccessories`,
				data: arg0,
			});
		}

		InvVehicleAccessory_update(arg0: InvVehicleAccessory): RestResponse<InvVehicleAccessory> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleaccessories`,
				data: arg0,
			});
		}

		InvVehicleAccessory_updateVerified(arg0: InvVehicleAccessory): RestResponse<InvVehicleAccessory> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleaccessories/verified`,
				data: arg0,
			});
		}

		InvVehicleAccessory_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Rest.Page<InvVehicleAccessory>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/vehicleaccessories/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvVehicleAccessory_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/vehicleaccessories/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		InvVehicleAccessory_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvVehicleAccessory> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/vehicleaccessories/${arg0}`,
				queryParams: queryParams,
			});
		}

		InvVehicle_getPage(arg0: ListPaginationRequest): RestResponse<Page<Vehicle>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/inventory/page`,
				data: arg0,
			});
		}

		/** INVENTORY REPORTS **/
		InventoryReport_get(): RestResponse<InventoryReport[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/reports/client`,
			})
		}

		InventoryReport_generateReport(arg0: InventoryReportRequest): RestResponse<any> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/reports/generate`,
				data: arg0
			})
		}

		/**
		 *
		 * HTTP POST /api/inventory/alarmtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.AlarmType_create
		 */
		AlarmType_create(arg0: InvAlarmType): RestResponse<InvAlarmType> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/alarmtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/alarmtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.AlarmType_update
		 */
		AlarmType_update(arg0: InvAlarmType): RestResponse<InvAlarmType> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/alarmtype`,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/alarmtype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.AlarmType_getPage
		 */
		AlarmType_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<InvAlarmType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/alarmtype/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP PUT /api/inventory/alarmtype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.AlarmType_changeStatus
		 */
		AlarmType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/alarmtype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP GET /api/inventory/alarmtype
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.AlarmType_find
		 */
		AlarmType_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvAlarmType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/alarmtype/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_create
		 */
		Client_create(arg0: Client): RestResponse<Client> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/client`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_update
		 */
		Client_update(arg0: Client): RestResponse<Client> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/client`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPage
		 */
		Client_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Client>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/client/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/client/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_getPageAndFilters
		 */
		Client_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Client>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/client/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/client/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_changeStatus
		 */
		Client_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/client/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/client/{client}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Client_find
		 */
		Client_find(arg0: string, queryParams?: { id?: number }): RestResponse<Client> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/client/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/conversionAlgorithm
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ConversionAlgorithmController.ConversionAlgorithm_create
		 */
		ConversionAlgorithm_create(arg0: ConversionAlgorithm): RestResponse<ConversionAlgorithm> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/conversionAlgorithm`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/conversionAlgorithm
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ConversionAlgorithmController.ConversionAlgorithm_delete
		 */
		ConversionAlgorithm_delete(arg0: ConversionAlgorithm[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/conversionAlgorithm`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/conversionAlgorithm
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ConversionAlgorithmController.ConversionAlgorithm_update
		 */
		ConversionAlgorithm_update(arg0: ConversionAlgorithm): RestResponse<ConversionAlgorithm> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/conversionAlgorithm`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/conversionAlgorithm/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ConversionAlgorithmController.ConversionAlgorithm_getPage
		 */
		ConversionAlgorithm_getPage(arg0: ListPaginationRequest): RestResponse<Page<ConversionAlgorithm>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/conversionAlgorithm/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/conversionAlgorithm/{cid}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ConversionAlgorithmController.ConversionAlgorithm_find
		 */
		ConversionAlgorithm_find(arg0: string, queryParams?: { id?: number }): RestResponse<ConversionAlgorithm> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/conversionAlgorithm/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/device
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_create
		 */
		Device_create(arg0: Device, queryParams?: { mode?: DeviceCreateMode }): RestResponse<Device> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/device`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/device
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_update
		 */
		Device_update(arg0: Device, queryParams?: { mode?: DeviceCreateMode }): RestResponse<Device> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/device`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/device/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_getPage
		 */
		Device_getPage(arg0: ListPaginationRequest): RestResponse<Page<Device>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/device/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/device/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_getPageAndFilters
		 */
		Device_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Device>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/device/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/device/requests/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_getRequestsPage
		 */
		Device_getRequestsPage(arg0: ListPaginationRequest): RestResponse<Page<Request>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/device/requests/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/device/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_changeStatus
		 */
		Device_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/device/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/device/{deviceId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_find
		 */
		Device_find(deviceId: string, queryParams?: { id?: number }): RestResponse<Device> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/device/${deviceId}`,
				queryParams: queryParams,
			});
		}

		/**
	   * HTTP GET /api/creditsDevice/{deviceId}
	   * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_find
	   */
		Device_getCredits(deviceId: number): any {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/creditsDevice/${deviceId}`,
			});
		}

		/**
		 * HTTP POST /api/creditsDevice/{deviceId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceController.Device_find
		 */
		Device_saveCredits(arg0): any {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/creditsDevice`,
				data: arg0,
			});
		}


		/**
		 * HTTP POST /api/devicetype/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceTypeController.DeviceType_getPage
		 */
		DeviceType_getPage(arg0: ListPaginationRequest): RestResponse<Page<DeviceType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/devicetype/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/devicetype/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceTypeController.DeviceType_changeStatus
		 */
		DeviceType_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/devicetype/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/devicetype/{devicetype}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DeviceTypeController.DeviceType_findDeviceType
		 */
		DeviceType_findDeviceType(devicetype: string, queryParams?: { devicetype?: number }): RestResponse<DeviceType> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/devicetype/${devicetype}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/dummy/ActuatorChangeRequest_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_ActuatorChangeRequest_Dummy
		 */
		Dummy_ActuatorChangeRequest_Dummy(): RestResponse<ActuatorChangeRequestMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/ActuatorChangeRequest_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/AnalogActuatorChangeRequest_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_AnalogActuatorChangeRequest_Dummy
		 */
		Dummy_AnalogActuatorChangeRequest_Dummy(): RestResponse<AnalogActuatorChangeRequest> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/AnalogActuatorChangeRequest_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/CallBackRequest_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_CallBackRequest_Dummy
		 */
		Dummy_CallBackRequest_Dummy(): RestResponse<CallBackRequestMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/CallBackRequest_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/CustomEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_CustomEvent_Dummy
		 */
		Dummy_CustomEvent_Dummy(): RestResponse<CustomEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/CustomEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/DeviceCreateMode_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_DeviceCreateMode_Dummy
		 */
		Dummy_DeviceCreateMode_Dummy(): RestResponse<DeviceCreateMode> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/DeviceCreateMode_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/DigitalActuatorChangeRequest_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_DigitalActuatorChangeRequest_Dummy
		 */
		Dummy_DigitalActuatorChangeRequest_Dummy(): RestResponse<DigitalActuatorChangeRequest> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/DigitalActuatorChangeRequest_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/DigitalSensorEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_DigitalSensorEvent_Dummy
		 */
		Dummy_DigitalSensorEvent_Dummy(): RestResponse<DigitalSensorEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/DigitalSensorEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/FreeTextRequestResponseMessage_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_FreeTextRequestResponseMessage_Dummy
		 */
		Dummy_FreeTextRequestResponseMessage_Dummy(): RestResponse<FreeTextRequestResponseMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/FreeTextRequestResponseMessage_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/FreeTextRequest_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_FreeTextRequest_Dummy
		 */
		Dummy_FreeTextRequest_Dummy(): RestResponse<FreeTextRequest> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/FreeTextRequest_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/LocationRequestMessage_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_LocationRequestMessage_Dummy
		 */
		Dummy_LocationRequestMessage_Dummy(): RestResponse<LocationRequestMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/LocationRequestMessage_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/RequestCancelledEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_RequestCancelledEvent_Dummy
		 */
		Dummy_RequestCancelledEvent_Dummy(): RestResponse<RequestCancelledEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/RequestCancelledEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/RequestQueuedEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_RequestQueuedEvent_Dummy
		 */
		Dummy_RequestQueuedEvent_Dummy(): RestResponse<RequestQueuedEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/RequestQueuedEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/RequestSendingEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_RequestSendingEvent_Dummy
		 */
		Dummy_RequestSendingEvent_Dummy(): RestResponse<RequestSendingEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/RequestSendingEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/RequestSentEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_RequestSentEvent_Dummy
		 */
		Dummy_RequestSentEvent_Dummy(): RestResponse<RequestSentEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/RequestSentEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/VehicleActivityReport_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_VehicleActivityReport_Dummy
		 */
		Dummy_VehicleActivityReport_Dummy(): RestResponse<VehicleActivityReport> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/VehicleActivityReport_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/VehicleDataMessage_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_VehicleDataMessage_Dummy
		 */
		Dummy_VehicleDataMessage_Dummy(): RestResponse<VehicleDataMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/VehicleDataMessage_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/ZoneEvent_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_ZoneEvent_Dummy
		 */
		Dummy_ZoneEvent_Dummy(): RestResponse<ZoneEvent> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/ZoneEvent_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/dummy_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_VT10Configuration_Dummy
		 */
		Dummy_VT10Configuration_Dummy(): RestResponse<VT10Properties> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/dummy_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/dummy_GV300
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_GV300_Dummy
		 */
		Dummy_GV300_Dummy(): RestResponse<GV300Params> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/dummy_GV300`,
			});
		}

		/**
		 * HTTP GET /api/dummy/response_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_Response_Dummy
		 */
		Dummy_Response_Dummy(): RestResponse<ResponseMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/response_Dummy`,
			});
		}

		/**
		 * HTTP GET /api/dummy/rt_Dummy
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.DummyController.Dummy_rt_Dummy
		 */
		Dummy_rt_Dummy(): RestResponse<Rt> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dummy/rt_Dummy`,
			});
		}

		/**
		 * HTTP POST /api/fleet
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_create
		 */
		Fleet_create(arg0: Fleet): RestResponse<Fleet> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fleet`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/fleet
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_update
		 */
		Fleet_update(arg0: Fleet): RestResponse<Fleet> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/fleet`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/fleet/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_getPage
		 */
		Fleet_getPage(arg0: ListPaginationRequest): RestResponse<Page<Fleet>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fleet/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/fleet/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_changeStatus
		 */
		Fleet_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/fleet/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/fleet/tree
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_getTree
		 */
		Fleet_getTree(arg0: ListPaginationRequest): RestResponse<Page<Fleet>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fleet/tree`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/fleet/tree
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_getTreeFleets
		 */
		Fleet_getTreeFleets(arg0: ListPaginationRequest): RestResponse<Page<Fleet>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fleet/tree/fleets`,
				data: arg0,
			});
		}

		Fleet_getInventoryTree(): RestResponse<Fleet[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fleet/tree/inventory`
			});
		}

		/**
		 * HTTP GET /api/fleet/{fleet}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.FleetController.Fleet_findFleet
		 */
		Fleet_findFleet(fleet: string, queryParams?: { fleet?: number }): RestResponse<Fleet> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fleet/${fleet}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/icon
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.IconController.Icon_create
		 */
		Icon_create(arg0: Icon): RestResponse<Icon> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/icon`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/icon
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.IconController.Icon_delete
		 */
		Icon_delete(arg0: Icon[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/icon`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/icon
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.IconController.Icon_update
		 */
		Icon_update(arg0: Icon): RestResponse<Icon> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/icon`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/icon/{entity}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.IconController.Icon_getEntityIcons
		 */
		Icon_getEntityIcons(entity: string, queryParams?: { entity?: string }): RestResponse<Icon[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/icon/${entity}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/icon/{entity}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.IconController.Icon_getEntityIcons
		 */
		Icon_getById(id: number): RestResponse<Icon> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/icon/download/${id}`,
			});
		}

		/**
		 * HTTP GET /api/login
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.LoginController.Login_refreshToken
		 */
		Login_refreshToken(): RestResponse<any> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/login`,
			});
		}

		/**
		 * HTTP POST /api/maintenance
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_create
		 */
		Maintenance_create(arg0: Maintenance): RestResponse<Maintenance> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenance`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/maintenance
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_update
		 */
		Maintenance_update(arg0: Maintenance): RestResponse<Maintenance> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenance`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/maintenance/maintenancevehicle/{maintenancevehicle}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_getExecutionsOfVehicle
		 */
		Maintenance_getExecutionsOfVehicle(
			maintenancevehicle: string,
			queryParams?: { maintenancevehicle?: string },
		): RestResponse<MaintenanceExecution[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenance/maintenancevehicle/${maintenancevehicle}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/maintenance/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_getPage
		 */
		Maintenance_getPage(arg0: ListPaginationRequest): RestResponse<Page<Maintenance>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenance/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenance/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_getPageAndFilters
		 */
		Maintenance_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Maintenance>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenance/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/maintenance/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceController.Maintenance_changeStatus
		 */
		Maintenance_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenance/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Maintenance_getList(arg0): RestResponse<Maintenance[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenance/client${arg0}`,
				data: arg0,
			});
		}

		Maintenance_getListByPlan(arg0): RestResponse<Maintenance[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenance/plan${arg0}`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenanceExecution
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_create
		 */
		MaintenanceExecution_create(arg0: MaintenanceExecution): RestResponse<MaintenanceExecution> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenanceExecution`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/maintenanceExecution
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_delete
		 */
		MaintenanceExecution_delete(arg0: MaintenanceExecution[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/maintenanceExecution`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/maintenanceExecution
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_update
		 */
		MaintenanceExecution_update(arg0: MaintenanceExecution): RestResponse<MaintenanceExecution> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenanceExecution`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenanceExecution/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_getPage
		 */
		MaintenanceExecution_getPage(arg0: ListPaginationRequest): RestResponse<Page<MaintenanceExecution>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenanceExecution/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenanceExecution/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_getPageAndFilters
		 */
		MaintenanceExecution_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<MaintenanceExecution>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenanceExecution/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/maintenanceExecution/{executionId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenanceExecutionController.MaintenanceExecution_find
		 */
		MaintenanceExecution_find(arg0: string, queryParams?: { id?: number }): RestResponse<MaintenanceExecution> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenanceExecution/${arg0}`,
				queryParams: queryParams,
			});
		}

		MaintenanceExecution_addDocument(queryParams): Rest.RestResponse<Rest.MaintenanceExecution> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenanceExecution/add`,
				queryParams: queryParams
			});
		}

		MaintenanceExecution_getStatusList(): RestResponse<String[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenanceExecution/enum/status`
			});
		}

		MaintenanceExecution_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenanceExecution/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenancePlan
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenancePlanController.MaintenancePlan_create
		 */
		MaintenancePlan_create(arg0: MaintenancePlan): RestResponse<MaintenancePlan> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenancePlan`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/maintenancePlan
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenancePlanController.MaintenancePlan_update
		 */
		MaintenancePlan_update(arg0: MaintenancePlan): RestResponse<MaintenancePlan> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenancePlan`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenancePlan/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenancePlanController.MaintenancePlan_getPage
		 */
		MaintenancePlan_getPage(arg0: ListPaginationRequest): RestResponse<Page<MaintenancePlan>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenancePlan/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/maintenancePlan/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenancePlanController.MaintenancePlan_getPageAndFilters
		 */
		MaintenancePlan_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<MaintenancePlan>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/maintenancePlan/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/maintenancePlan/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.MaintenancePlanController.MaintenancePlan_changeStatus
		 */
		MaintenancePlan_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/maintenancePlan/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		MaintenancePlan_getList(arg0): RestResponse<MaintenancePlan[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/maintenancePlan/client${arg0}`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/noticeGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_create
		 */
		NoticeGroup_create(arg0: NoticeGroup): RestResponse<NoticeGroup> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/noticeGroup`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/noticeGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_update
		 */
		NoticeGroup_update(arg0: NoticeGroup): RestResponse<NoticeGroup> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/noticeGroup`,
				data: arg0,
			});
		}

		NoticeGroup_deleteMaintenance(arg0: NoticeGroup): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/noticeGroup/maintenance/${arg0}`
			});
		}

		/**
		 * HTTP POST /api/noticeGroup/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_getPage
		 */
		NoticeGroup_getPage(arg0: ListPaginationRequest): RestResponse<Page<NoticeGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/noticeGroup/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/noticeGroup/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_getPageAndFilters
		 */
		NoticeGroup_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<NoticeGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/noticeGroup/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/noticeGroup/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_changeStatus
		 */
		NoticeGroup_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/noticeGroup/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/noticeGroup/{noticeGroupId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.NoticeGroupController.NoticeGroup_find
		 */
		NoticeGroup_find(noticeGroupId: string, queryParams?: { id?: number }): RestResponse<NoticeGroup> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/noticeGroup/${noticeGroupId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/poi
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_create
		 */
		POI_create(arg0: POI): RestResponse<POI> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/poi
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_update
		 */
		POI_update(arg0: POI): RestResponse<POI> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/poi`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poi/import
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_createByList
		 */
		POI_createByList(arg0: POI[]): RestResponse<POI[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi/import`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poi/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_getPage
		 */
		POI_getPage(arg0: ListPaginationRequest): RestResponse<Page<POI>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi/page`,
				data: arg0,
			});
		}
		/**
		 * HTTP POST /api/poi/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_getPage
		 */
		POI_getPageTask(arg0: ListPaginationRequest): RestResponse<Page<POI>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi/task/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poi/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_getPage
		 */
		POI_getPageTaskRoute(arg0: ListPaginationRequest): RestResponse<Page<POI>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi/task/route/page`,
				data: arg0,
			});
		}


		/**
		 * HTTP POST /api/poi/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_getPageAndFilters
		 */
		POI_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<POI>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poi/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/poi/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_changeStatus
		 */
		POI_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/poi/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/poi/{poiId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POIController.POI_find
		 */
		POI_find(arg0: string, queryParams?: { id?: number }): RestResponse<POI> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/poi/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/poiCategory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_create
		 */
		POICategory_create(arg0: POICategory): RestResponse<POICategory> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poiCategory`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/poiCategory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_update
		 */
		POICategory_update(arg0: POICategory): RestResponse<POICategory> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/poiCategory`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poiCategory/findAll
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_findAll
		 */
		POICategory_findAll(arg0: number[]): RestResponse<POICategory[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poiCategory/findAll`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poiCategory/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_getPage
		 */
		POICategory_getPage(arg0: ListPaginationRequest): RestResponse<Page<POICategory>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poiCategory/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/poiCategory/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_getPageAndFilters
		 */
		POICategory_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<POICategory>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/poiCategory/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/poiCategory/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.POICategoryController.POICategory_changeStatus
		 */
		POICategory_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/poiCategory/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}
		POICategory_getAllEnabledCategories(): RestResponse<POICategory[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/poiCategory/all`,
			});
		}

		/**
		 * HTTP POST /api/preconfig
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.PreconfigurationController.Preconfiguration_create
		 */
		Preconfiguration_create(arg0: DevicePreconfiguration): RestResponse<DevicePreconfiguration> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/preconfig`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/preconfig
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.PreconfigurationController.Preconfiguration_delete
		 */
		Preconfiguration_delete(arg0: DevicePreconfiguration[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/preconfig`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/preconfig
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.PreconfigurationController.Preconfiguration_update
		 */
		Preconfiguration_update(arg0: DevicePreconfiguration): RestResponse<DevicePreconfiguration> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/preconfig`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/preconfig/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.PreconfigurationController.Preconfiguration_getPage
		 */
		Preconfiguration_getPage(arg0: ListPaginationRequest): RestResponse<Page<DevicePreconfiguration>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/preconfig/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/preconfig/{preConfig}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.PreconfigurationController.Preconfiguration_find
		 */
		Preconfiguration_find(preConfig: string, queryParams?: { preConfig?: number }): RestResponse<DevicePreconfiguration> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/preconfig/${preConfig}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/profile
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ProfileController.Profile_create
		 */
		Profile_create(arg0: Profile): RestResponse<Profile> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/profile`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/profile
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ProfileController.Profile_delete
		 */
		Profile_delete(arg0: Profile[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/profile`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/profile
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ProfileController.Profile_update
		 */
		Profile_update(arg0: Profile): RestResponse<Profile> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/profile`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/profile/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ProfileController.Profile_getPage
		 */
		Profile_getPage(arg0: ListPaginationRequest): RestResponse<Page<Profile>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/profile/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/profile/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ProfileController.Profile_getPageAndFilters
		 */
		Profile_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Profile>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/profile/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/report
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ReportController.Report_create
		 */
		Report_create(arg0: Report): RestResponse<Report> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/report`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/report/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ReportController.Report_getPage
		 */
		Report_getPage(arg0: ListPaginationRequest): RestResponse<Page<Report>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/report/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/report/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ReportController.Report_getPageAndFilters
		 */
		Report_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Report>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/report/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/report/{rid}/{vid}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ReportController.Report_getReportPart
		 */
		Report_getReportPart(rid: string, vid: string, queryParams?: { rid?: number; vid?: number }): RestResponse<string> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/report/${rid}/${vid}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/report/hospitalmataro
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ReportController.Report_getHospitalMataro
		 */

		Report_getHospitalMataro(queryParams?: { startDate?: String; endDate?: String }): RestResponse<Page<ReportTableHospitalMataro>> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/report/hospitalmataro`,
				queryParams: queryParams
			})
		}

		/**
		 * HTTP POST /api/role
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RoleController.Role_create
		 */
		Role_create(arg0: Role): RestResponse<Role> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/role`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/role
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RoleController.Role_delete
		 */
		Role_delete(arg0: Role[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/role`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/role
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RoleController.Role_update
		 */
		Role_update(arg0: Role): RestResponse<Role> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/role`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/role/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RoleController.Role_getPage
		 */
		Role_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Role>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/role/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/role/{role}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RoleController.Role_find
		 */
		Role_find(arg0: string, queryParams?: { id?: number }): RestResponse<Role> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/role/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/rt/events/get
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getVehicleEvents
		 */
		RealTime_getVehicleEvents(arg0: number[], queryParams?: { start?: Date; end?: Date }): RestResponse<Event[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/events/get`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rt/fleetTreeInfo/asTree
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getTree
		 */
		RealTime_getTree(arg0: FleetTreeSettings): RestResponse<RTFleet[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/fleetTreeInfo/asTree`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rt/fleetTreeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_createFleetTreeSettings
		 */
		RealTime_createFleetTreeSettings(arg0: FleetTreeSettings): RestResponse<FleetTreeSettings> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/fleetTreeSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/rt/fleetTreeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getFleetTreeSettings
		 */
		RealTime_getFleetTreeSettings(): RestResponse<FleetTreeSettings[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/fleetTreeSettings`,
			});
		}

		/**
		 * HTTP PUT /api/rt/fleetTreeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_updateFleetTreeSettings
		 */
		RealTime_updateFleetTreeSettings(arg0: FleetTreeSettings): RestResponse<FleetTreeSettings> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rt/fleetTreeSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/rt/fleetTreeSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_deleteFleetTreeSettings
		 */
		RealTime_deleteFleetTreeSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/rt/fleetTreeSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/fleetTreeSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_findFleetTreeSettings
		 */
		RealTime_findFleetTreeSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<FleetTreeSettings> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/fleetTreeSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/rt/mapSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_createMapSettings
		 */
		RealTime_createMapSettings(arg0: MapSettings): RestResponse<MapSettings> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/mapSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/rt/mapSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getMapSettings
		 */
		RealTime_getMapSettings(): RestResponse<MapSettings[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/mapSettings`,
			});
		}

		/**
		 * HTTP PUT /api/rt/mapSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_updateMapSettings
		 */
		RealTime_updateMapSettings(arg0: MapSettings): RestResponse<MapSettings> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rt/mapSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/rt/mapSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_deleteMapSettings
		 */
		RealTime_deleteMapSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/rt/mapSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/mapSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_findMapSettings
		 */
		RealTime_findMapSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<MapSettings> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/mapSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/readings/count/{vehicleId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_countVehicleReadings
		 */
		RealTime_countVehicleReadings(vehicleId: string, queryParams?: { vehicleId?: number; start?: Date; end?: Date }): RestResponse<number> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/readings/count/${vehicleId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/readings/get/{vehicleId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getVehicleReadings
		 */
		RealTime_getVehicleReadings(
			vehicleId: string,
			queryParams?: { vehicleId?: number; start?: Date; end?: Date },
		): RestResponse<{ [index: string]: SimpleDeviceValue[] }> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/readings/get/${vehicleId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/rt/realTimeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_createRealTimeSettings
		 */
		RealTime_createRealTimeSettings(arg0: RealTimeSettings): RestResponse<RealTimeSettings> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/realTimeSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/rt/realTimeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getRealTimeSettings
		 */
		RealTime_getRealTimeSettings(): RestResponse<RealTimeSettings[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/realTimeSettings`,
			});
		}

		/**
		 * HTTP PUT /api/rt/realTimeSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_updateRealTimeSettings
		 */
		RealTime_updateRealTimeSettings(arg0: RealTimeSettings): RestResponse<RealTimeSettings> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rt/realTimeSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/rt/realTimeSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_deleteRealTimeSettings
		 */
		RealTime_deleteRealTimeSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/rt/realTimeSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/realTimeSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_findRealTimeSettings
		 */
		RealTime_findRealTimeSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<RealTimeSettings> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/realTimeSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/rt/totalizer/tagsAndValues
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getTagsAndCategoricalValues
		 */
		RealTime_getTagsAndCategoricalValues(arg0: number[]): RestResponse<DTagAndValue[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/totalizer/tagsAndValues`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rt/vehicleDataMessages
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getLastVehicleDataMessages
		 */
		RealTime_getLastVehicleDataMessages(
			arg0: number[],
			queryParams?: { maxCount?: number; start?: number; end?: number },
		): RestResponse<VehicleDataMessage[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/vehicleDataMessages`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rt/vehicleDetailsSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_createVehicleDetailsSettings
		 */
		RealTime_createVehicleDetailsSettings(arg0: VehicleDetailsSettings): RestResponse<VehicleDetailsSettings> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/vehicleDetailsSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/rt/vehicleDetailsSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getVehicleDetailsSettings
		 */
		RealTime_getVehicleDetailsSettings(): RestResponse<VehicleDetailsSettings[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/vehicleDetailsSettings`,
			});
		}

		/**
		 * HTTP PUT /api/rt/vehicleDetailsSettings
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_updateVehicleDetailsSettings
		 */
		RealTime_updateVehicleDetailsSettings(arg0: VehicleDetailsSettings): RestResponse<VehicleDetailsSettings> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rt/vehicleDetailsSettings`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/rt/vehicleDetailsSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_deleteVehicleDetailsSettings
		 */
		RealTime_deleteVehicleDetailsSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/rt/vehicleDetailsSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/rt/vehicleDetailsSettings/{settingsId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_findVehicleDetailsSettings
		 */
		RealTime_findVehicleDetailsSettings(settingsId: string, queryParams?: { settingsId?: number }): RestResponse<VehicleDetailsSettings> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/vehicleDetailsSettings/${settingsId}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/rt/vehicleInfo
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RealTimeController.RealTime_getVehicleInfo
		 */
		RealTime_getVehicleInfo(arg0: number[]): RestResponse<Vehicle[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rt/vehicleInfo`,
				data: arg0,
			});
		}

		RealTime_getLastVehicleDataMessage(arg0: number): RestResponse<VehicleDataMessage> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rt/lastVehicleDataMessage/${arg0}`,
			});
		}

		/**
		 * HTTP POST /api/rule
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_create
		 */
		Rule_create(arg0: Rule): RestResponse<Rule> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rule`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/rule
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_delete
		 */
		Rule_delete(arg0: Rule[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/rule`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/rule
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_update
		 */
		Rule_update(arg0: Rule): RestResponse<Rule> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rule`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rule/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_getPage
		 */
		Rule_getPage(arg0: ListPaginationRequest): RestResponse<Page<Rule>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rule/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/rule/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_getPageAndFilters
		 */
		Rule_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Rule>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/rule/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/rule/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_changeStatus
		 */
		Rule_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/rule/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/rule/{rid}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RuleController.Rule_find
		 */
		Rule_find(arg0: string, queryParams?: { id?: number }): RestResponse<Rule> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/rule/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/translation
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.TranslationController.Translation_create
		 */
		Translation_create(arg0: Translation): RestResponse<Translation> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/translation`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/translation
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.TranslationController.Translation_Delete
		 */
		Translation_Delete(arg0: Translation[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/translation`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/translation
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.TranslationController.Translation_getAll
		 */
		Translation_getAll(): RestResponse<Translation[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/translation`,
			});
		}

		/**
		 * HTTP PUT /api/translation
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.TranslationController.Translation_update
		 */
		Translation_update(arg0: Translation): RestResponse<Translation> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/translation`,
				data: arg0,
			});
		}

		/*------------------------*/
		/*----- USER METHODS -----*/

		/*------------------------*/

		/**
		 * HTTP POST /api/user
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_create
		 */
		User_create(arg0: User): RestResponse<User> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/user
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_getSelf
		 */
		User_getSelf(): RestResponse<User> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user`,
			});
			//return this.httpClient.request({ method: "GET", url: uriEncoding`api/user` });
		}

		/**
		 * HTTP PUT /api/user
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_update
		 */
		User_update(arg0: User): RestResponse<User> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/user`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/user/delete/task
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_deleteTask
		 */
		User_delete(arg0: number): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/user/delete/task`,
				data: arg0,
			});
		}

		User_refreshSession(): RestResponse<any> {
			//TODO: CHECK
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/login/refresh`,
			});
		}

		/**
		 * HTTP POST /api/user/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_getPage
		 */
		User_getPage(arg0: ListPaginationRequest): RestResponse<Page<User>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/user/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_getPageAndFilters
		 */
		User_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<User>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/user/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_changeStatus
		 */
		User_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/user/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/user/{uid}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.UserController.User_find
		 */
		User_find(uid: string, queryParams?: { id?: number }): RestResponse<User> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/${uid}`,
				queryParams: queryParams,
			});
		}

		User_getUserProfileInfo(uid: number): RestResponse<any> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/userProfileInfo/${uid}`,
			});
		}

		User_updateUserProfileInfo(userSettings: any): RestResponse<any> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/user/updateUserProfileInfo`,
				data: userSettings,
			});
		}

		User_getUserAllowedDashboards(uid: number): RestResponse<any> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/userAllowedDashboards/${uid}`,
			});
		}

		// User_AllDataTask(arg0: string, queryParams?: { id?: number }): RestResponse<User[]> {
		User_AllDataTask(uid: number): RestResponse<User> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/userAllDataTask/${uid}`
			});
		}

		/*User_DriversTask(uid: number): RestResponse<User> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/userDriversTask/${uid}`
			});
		}*/

		User_getUsersWithSharedClient(clientId: number): RestResponse<any> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/user/client/${clientId}`,
			});
		}

		/*---------------------------*/
		/*----- VEHICLE METHODS -----*/

		/*---------------------------*/

		/**
		 * HTTP POST /api/vehicle
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_create
		 */
		Vehicle_create(arg0: Vehicle): RestResponse<Vehicle> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/vehicle
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_update
		 */
		Vehicle_update(arg0: Vehicle): RestResponse<Vehicle> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/vehicle/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_getPage
		 */
		Vehicle_getPage(arg0: ListPaginationRequest): RestResponse<Page<Vehicle>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/vehicle/tree/vehicles
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_getTreeVehicles
		 */
		Vehicle_getTreeVehicles(arg0: ListPaginationRequest): RestResponse<Page<Vehicle>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/tree/vehicles`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/vehicle/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_changeStatus
		 */
		Vehicle_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		async Vehicle_changeStatusPromise(arg0: number[], queryParams?: { status?: boolean }): RestResponse<boolean> {
			await this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle/status`,
				queryParams: queryParams,
				data: arg0,
			});
			return true;
		}

		/**
		 * HTTP GET /api/vehicle/{vehicle}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleController.Vehicle_findVehicle
		 */
		Vehicle_findVehicle(vehicle: string, queryParams?: { vehicle?: number }): RestResponse<Vehicle> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicle/${vehicle}`,
				queryParams: queryParams,
			});
		}

		Vehicle_findVehicleByName(arg0): RestResponse<Vehicle[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicle/byname`,
				data: arg0,
			});
		}

		Vehicle_changeStatusInventory(arg0: Rest.Vehicle, queryParams?: { status?: boolean }): RestResponse<boolean> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicle/status/inventory`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/vehicleBrand/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleBrandController.VehicleBrand_getPage
		 */
		VehicleBrand_getPage(arg0: ListPaginationRequest, queryParams?: { clientId?: number }): RestResponse<Page<VehicleBrand>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleBrand/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		//VehicleBrand
		VehicleBrand_getAll(clientId: number): RestResponse<VehicleBrand[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleBrand/client` + clientId
			});
		}

		//VehicleModel
		VehicleModel_getAll(brandId: number): RestResponse<VehicleModel[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleModel/brand` + brandId
			});
		}

		//VehicleModelByCLient
		VehicleModel_getByClient(clientId: number): RestResponse<VehicleModel[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleModel/client` + clientId
			});
		}

		/**
		 * HTTP POST /api/vehicleBrand
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleBrandController.VehicleBrand_create
		 */
		VehicleBrand_create(arg0: VehicleBrand): RestResponse<Rest.VehicleBrand> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleBrand`,
				data: arg0
			})
		}

		/**
		 * HTTP POST /api/vehicleBrand
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleBrandController.VehicleBrand_update
		 */
		VehicleBrand_update(arg0: VehicleBrand): RestResponse<Rest.VehicleBrand> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleBrand/update`,
				data: arg0
			})
		}

		/**
		 * HTTP GET /api/vehicleBrand/{brand}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleBrandController.VehicleBrand_find
		 */
		VehicleBrand_find(brand: string, queryParams?: { id?: number }): RestResponse<VehicleBrand> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleBrand/${brand}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/vehicleBrand/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleBrandController.VehicleBrand_changeStatus
		 */
		VehicleBrand_changeStatus(arg0: number[], queryParams?: { id?: number, status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicleBrand/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleBrand_getList = async (): RestResponse<VehicleBrand[]> => {
			let result = await this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleBrand/page`,
				data: {
					"offset": 0,
					"pageSize": 999,
					"sortAsc": true,
					"sortBy": "name",
					"loadPermissions": true,
					"filters": {}
				},
			});

			let x = <Page<VehicleBrand>>result;
			return x.entities;
		}

		//TODO: Change it into its correct name
		/**
		 * HTTP GET /api/vehicleModel/type/{type}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleModelController.VehicleModel_getBrandsOfType
		 */
		VehicleModel_getBrandsOfType(type: string, queryParams?: { type?: number }): RestResponse<VehicleBrand[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleBrand/type/${type}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/vehicleModel/brandtype/{brandtype}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleModelController.VehicleModel_getModelsOfBrand
		 */
		VehicleModel_getModelsOfBrand(brandtype: string, queryParams?: { brandtype?: string }): RestResponse<VehicleModel[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleModel/brandtype/${brandtype}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/vehicleModel/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleModelController.VehicleModel_getPage
		 */
		VehicleModel_getPage(arg0: ListPaginationRequest): RestResponse<Page<VehicleModel>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleModel/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/vehicleModel
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleModelController.VehicleModel_create
		 */
		VehicleModel_create(arg0: VehicleModel): RestResponse<any> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleModel`,
				data: arg0,
			})
		}

		VehicleModel_update(arg0: VehicleModel): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicleModel`,
				data: arg0,
			})
		}

		VehicleModel_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicleModel/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleModel_find(arg0: string, queryParams?: { id?: number }): RestResponse<VehicleModel> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleVersion/${arg0}`,
				queryParams: queryParams,
			});
		}


		VehicleVersion_getPage(arg0: ListPaginationRequest): RestResponse<Page<VehicleVersion>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleVersion/page`,
				data: arg0,
			});
		}

		VehicleVersion_create(arg0: VehicleVersion): RestResponse<any> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleVersion`,
				data: arg0,
			})
		}

		VehicleVersion_update(arg0: VehicleVersion): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicleVersion`,
				data: arg0,
			})
		}

		VehicleVersion_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehicleVersion/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleVersion_find(arg0: string, queryParams?: { id?: number }): RestResponse<VehicleVersion> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleVersion/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/vehicleType/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehicleTypeController.VehicleType_getPage
		 */
		VehicleType_getPage(arg0: ListPaginationRequest): RestResponse<Page<VehicleType>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehicleType/page`,
				data: arg0,
			});
		}

		VehicleType_getAll(): RestResponse<VehicleType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicleType/list`
			});
		}

		/**
		 * HTTP POST /api/vehiclesGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_create
		 */
		VehiclesGroup_create(arg0: VehiclesGroup): RestResponse<VehiclesGroup> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehiclesGroup`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/vehiclesGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_update
		 */
		VehiclesGroup_update(arg0: VehiclesGroup): RestResponse<VehiclesGroup> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehiclesGroup`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/vehiclesGroup/group/{group}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_findGroup
		 */
		VehiclesGroup_findGroup(group: string, queryParams?: { group?: number }): RestResponse<VehiclesGroup> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehiclesGroup/group/${group}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/vehiclesGroup/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_getPage
		 */
		VehiclesGroup_getPage(arg0: ListPaginationRequest): RestResponse<Page<VehiclesGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehiclesGroup/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/vehiclesGroup/tree/groups
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_getTreeGroups
		 */
		VehiclesGroup_getTreeGroups(arg0: ListPaginationRequest): RestResponse<Page<VehiclesGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/vehiclesGroup/tree/groups`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/vehiclesGroup/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.VehiclesGroup_changeStatus
		 */
		VehiclesGroup_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/vehiclesGroup/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/vehiclesGroup/fleet/{fleetId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.VehiclesGroupController.getGroupsByFleet
		 */
		VehiclesGroup_findByFleet(fleetId: number): RestResponse<VehiclesGroup[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehiclesGroup/fleet/${fleetId}`
			});
		}

		VehiclesGroup_getByFleet(fleetId: number): RestResponse<VehiclesGroup[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehiclesGroup/inventory/fleet${fleetId}`
			});
		}

		/**
		 * HTTP POST /api/zone
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_create
		 */
		Zone_create(arg0: Zone): RestResponse<Zone> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zone`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/zone
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_update
		 */
		Zone_update(arg0: Zone): RestResponse<Zone> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/zone`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/zone/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_getPage
		 */
		Zone_getPage(arg0: ListPaginationRequest): RestResponse<Page<Zone>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zone/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/zone/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_getPageAndFilters
		 */
		Zone_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<Zone>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zone/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/zone/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_changeStatus
		 */
		Zone_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/zone/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		VehicleDataMessage_getPage(arg0: ListPaginationRequest, queryParams?: { vehicleId: number, startDate: string, endDate: string }): RestResponse<Page<VehicleDataMessage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/sat/vehicleInfo`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		RawMessage_getPage(arg0: ListPaginationRequest, queryParams?: { vehicleId: number, startDate: string, endDate: string }): RestResponse<Page<RawMessage>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/sat/rawInfo`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		Request_getPage(arg0: ListPaginationRequest, queryParams?: { vehicleId: number }): RestResponse<Page<Request>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/sat/sentMessages`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		SAT_getCredits(userId: number): RestResponse<CreditsUserSAT> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/sat/creditsUser/${userId}`,
			});
		}

		SAT_updateCredits(arg0: CreditsHistory): RestResponse<CreditsUserSAT> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/sat/creditsUser`,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/zone/{zoneId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneController.Zone_find
		 */
		Zone_find(arg0: string, queryParams?: { id?: number }): RestResponse<Zone> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/zone/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/zoneGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_create
		 */
		ZoneGroup_create(arg0: ZoneGroup): RestResponse<ZoneGroup> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zoneGroup`,
				data: arg0,
			});
		}

		ZoneGroup_delete(arg0: ZoneGroup[]): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/zoneGroup`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/zoneGroup
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_update
		 */
		ZoneGroup_update(arg0: ZoneGroup): RestResponse<ZoneGroup> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/zoneGroup`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/zoneGroup/findAll
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_findAll
		 */
		ZoneGroup_findAll(arg0: number[]): RestResponse<ZoneGroup[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zoneGroup/findAll`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/zoneGroup/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_getPage
		 */
		ZoneGroup_getPage(arg0: ListPaginationRequest): RestResponse<Page<ZoneGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zoneGroup/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/zoneGroup/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_getPageAndFilters
		 */
		ZoneGroup_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<ZoneGroup>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/zoneGroup/pageAndFilters`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/zoneGroup/status
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_changeStatus
		 */
		ZoneGroup_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/zoneGroup/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 * HTTP GET /api/zoneGroup/{zoneGroupId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ZoneGroupController.ZoneGroup_find
		 */
		ZoneGroup_find(arg0: string, queryParams?: { id?: number }): RestResponse<ZoneGroup> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/zoneGroup/${arg0}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/simOperatorType/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.SimOperatorTypeController.SimOperatorType_getPage
		 */
		SimOperatorType_getPage(arg0: ListPaginationRequest): RestResponse<Page<SimOperatorType>> {
			return this.httpClient.request({ method: 'POST', url: uriEncoding`api/simOperatorType/page`, data: arg0 });
		}

		/**
		 * HTTP GET /api/SimPlanGroups/operatorType/{operatorType}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.SimPlanGroupsController.SimPlanGroups_getSimGroupsByOperatorType
		 */
		SimPlanGroups_getSimGroupsByOperatorType(type: string, queryParams?: { type?: number }): RestResponse<SimPlanGroups[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/SimPlanGroups/operatorType/${type}`,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP GET /api/SimPlans/group/{groupId}
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.SimPlansController.SimPlans_getSimPlansByGroup
		 */
		SimPlans_getSimPlansByGroup(groupId: string, queryParams?: { groupId?: number }): RestResponse<SimPlans[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/SimPlans/group/${groupId}`,
				queryParams: queryParams,
			});
		}

		//RAQUEL: RACING

		/**
		 * HTTP POST /api/racing
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_create
		 */
		Racing_create(arg0: Racing): RestResponse<Racing> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/eventshistory`,
				data: arg0,
			});
		}

		/**
		 * HTTP POST /api/eventshistory/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_getPage
		 */
		Racing_getPage(arg0: ListPaginationRequest): RestResponse<Page<Racing>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/eventshistory/page`,
				data: arg0,
			});
		}

		/**
		 * HTTP PUT /api/eventshistory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_update
		 */
		Racing_update(arg0: Racing): RestResponse<Racing> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/eventshistory`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/eventshistory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_delete
		 */
		Racing_delete(arg0: Racing): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/eventshistory`,
				data: arg0,
			});
		}

		//

		Racing_gettyperacing(): RestResponse<EventTypeRacing[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/eventtype`,
			});
			return null;
		}

		Racing_downloadimage(arg0: number, queryParams?: { arg0?: number }): RestResponse<string> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/icon/download/${arg0}`,
				queryParams: queryParams,
			});
			return null;
		}

		Racing_gettypecode(): RestResponse<EventCodeTypeRacing[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/eventcodetype`,
			});

			return null;
		}

		Racing_getPoiIcons(clientId: number): RestResponse<IconRacing[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/iconevents`,
				queryParams: { clientId: clientId },
			});
		}

		//FIN RAQUEL: RACING

		/**
		 *
		 * HTTP POST /api/inventory/tachographfile/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_getPage
		 */
		Taco_getPage(arg0: ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): RestResponse<Page<Tacograph>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/tachographfile/page`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		/**
		 *
		 * HTTP POST /api/inventory/tachographfile/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.ClientController.Mileage_getPage
		 */
		Taco_download(arg0: number[]): RestResponse<string> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/tachographfile/download`,
				data: arg0
			});
		}

		//TAX
		ReportFiles_getPage(arg0: ListPaginationRequest, queryParams?: { vehicleId?: number, groupId?: number, fleetId?: number, clientId?: number, billingAccountId?: number, startDate: string, endDate: string }): RestResponse<Page<ReportFiles>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/reportfiles/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}
		ReportFiles_download(arg0: number[]): RestResponse<string> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/reportfiles/download`,
				data: arg0
			});
		}

		//DASHBOARDS
		Dashboards_findByClientId(clientId: number): RestResponse<Dashboards[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/dashboards/${clientId}`,
			});
		}

		// DASHBOARDS CHARTS
		DashboardsChartsRequest_get(arg0: DashBoardChartsRequest): RestResponse<DashBoardChartsResponse> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/smartcoffee/weeklyreports/report`,
				data: arg0,
			});
		}

		FetchHourlyCoffeeMakerUse(requestData: DashBoardChartsRequest): RestResponse<CoffeeMakerUseResponse> {
			return this.httpClient.request<CoffeeMakerUseResponse>({
				method: 'POST',
				url: 'api/smartcoffee/coffeeusereports/byhour',
				data: requestData
			});
		}

		//RoutesCategory
		RoutesCategory_getAll(queryParams: { lang: Language }): RestResponse<RoutesCategory[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/routescategory`,
				queryParams: queryParams
			});
		}

		//FidProviders
		FidProviders_getAll(): RestResponse<FidProvider[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/provider/list`
			});
		}

		//FidCampaign
		FidCampaigns_getOne(campaignId: number): RestResponse<FidProvider[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/provider/list`
			});
		}

		//PROMOTED_ROUTES
		PromotedRoutes_getPage(
			arg0: ListPaginationRequest,
			queryParams?: { showRoutes?: boolean, encodeImages?: boolean })
			: RestResponse<Page<FidSpace>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fid/space/page`,
				data: arg0,
				queryParams: queryParams
			});
		}
		//PROMOTED_ROUTES - Get Routes
		PromotedRoutes_getRoutes(
			arg0: ListPaginationRequest,
			queryParams?: { showPois?: boolean, encodeImages?: boolean })
			: RestResponse<Page<FidRoute>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fid/route/page`,
				data: arg0,
				queryParams: queryParams
			});
		}

		//PROMOTED_ROUTES - Get POIs
		PromotedRoutes_getRoutesPois(
			queryParams: { active?: boolean, routeId: number, encodeImages?: boolean })
			: RestResponse<Page<FidRoutePOI>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fid/routespoi/list`,
				queryParams: queryParams
			});
		}

		//GET filter parameters
		FidEnum_getParameters(): RestResponse<string[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/parameters`
			});
		}

		//GET filter parameters
		FidEnum_getFullInfoParameters(): RestResponse<FullInfoParameters[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/fullinfoparameters`
			});
		}

		//GET filter type by parameter
		FidEnum_getTypes(parameter: string): RestResponse<Rest.FidFilterType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/filtertype/` + parameter
			});
		}

		//GET value type by parameter
		FidEnum_getValueType(parameter: string): RestResponse<String> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/valuetype/` + parameter
			});
		}

		FidEnum_getDeviceCommunication(): RestResponse<String[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/devicecommunicationtype`
			});
		}

		FidEnum_getDeviceType(): RestResponse<String[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/devicetype`
			});
		}

		FidEnum_getGender(): RestResponse<String[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/gender`
			});
		}

		FidEnum_getLanguage(): RestResponse<String[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/campaigns/enum/language`
			});
		}

		//save Filter
		FidFilters_save(arg0: any): any {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fid/filters/save`,
				data: arg0,
			});
		}

		PromotedRoutes_sendNotifications(
			queryParams: { campaignId: number })
			: RestResponse<FidSendNotifControl> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/fid/sendnotifcontrol`,
				queryParams: queryParams
			});
		}

		PromotedRoutes_getNotifControl(queryParams: { campaignId: number })
			: RestResponse<FidSendNotifControl> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/fid/sendnotifcontrol`,
				queryParams: queryParams
			});
		}


		//Drivers
		Driver_create(arg0: InvDriver): RestResponse<InvDriver> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driver`,
				data: arg0,
			});
		}

		Driver_update(arg0: InvDriver): RestResponse<InvDriver> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driver`,
				data: arg0,
			});
		}

		Driver_getPage(arg0: ListPaginationRequest): RestResponse<Page<InvDriver>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driver/page`,
				data: arg0,
			});
		}

		Driver_getPageAndFilters(arg0: ListPaginationRequest): RestResponse<ListPageAndFilters<InvDriver>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driver/pageAndFilters`,
				data: arg0,
			});
		}

		Driver_getList(clientId: number): RestResponse<InvDriver[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driver/client${clientId}`,
			});
		}

		Driver_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driver/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Driver_getPageAll(arg0: ListPaginationRequest): RestResponse<Page<InvDriver>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driver/page/all`,
				data: arg0,
			});
		}

		Driver_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvDriver> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driver/${arg0}`,
				queryParams: queryParams,
			});
		}

		//Driver License
		DriverLicense_create(arg0: InvDriverLicense): RestResponse<InvDriverLicense> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverlicense`,
				data: arg0,
			});
		}

		DriverLicense_upload(arg0: InvDriverLicense[]): RestResponse<InvDriverLicense[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverlicense/upload`,
				data: arg0,
			});
		}

		DriverLicense_update(arg0: InvDriverLicense): RestResponse<InvDriverLicense> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driverlicense`,
				data: arg0,
			});
		}

		DriverLicense_getPage(arg0: ListPaginationRequest): RestResponse<Page<InvDriverLicense>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/driverlicense/page`,
				data: arg0,
			});
		}

		DriverLicense_changeStatus(arg0: number[], queryParams?: { status?: boolean }): RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/inventory/driverlicense/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		DriverLicense_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvDriverLicense> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driverlicense/${arg0}`,
				queryParams: queryParams,
			});
		}

		//Document

		Document_getPage(arg0: ListPaginationRequest): RestResponse<Page<InvDocument>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/document/page`,
				data: arg0,
			});
		}

		Document_delete(documentId: number): RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/inventory/document/${documentId}`
			});
		}

		Document_find(arg0: string, queryParams?: { id?: number }): RestResponse<InvDocument> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/document/${arg0}`,
				queryParams: queryParams,
			});
		}

		// Document_getTypeList(queryParams: { lang: Language }): RestResponse<InvDocumentType[]> {
		// 	return this.httpClient.request({
		// 		method: 'GET',
		// 		url: uriEncoding`api/inventory/document/enum/type`,
		// 		queryParams: queryParams,
		// 	});
		// }

		DriverPoints_findDocument(arg0: string, queryParams?: { id?: number }): RestResponse<InvDriverPoints> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/driverpoints/document/${arg0}`,
				queryParams: queryParams,
			});
		}

		Note_findDocument(arg0: string, queryParams?: { id?: number }): RestResponse<InvNote> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/note/document${arg0}`,
				queryParams: queryParams,
			});
		}

		//Fuel Type

		FuelType_getList(): RestResponse<Rest.FuelType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/fueltype/list`,
			});
		}

		Vehicle_getFuelTypes(queryParams: { lang: Rest.Language }): RestResponse<InvFuelType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/vehicle/enum/fueltypes`,
				queryParams: queryParams,
			});
		}

		MileageStats_getStats(arg0: string): RestResponse<MileageStats> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/inventory/kmstats/${arg0}`
			});
		}


	}

	export type RestResponse<R> = Promise<R>;

	export type DeviceCreateMode = 'NOT_SEND' | 'NORMAL' | 'FORCE_SEND';

	export type DType = 'ANALOG' | 'DIGITAL' | 'CANBUS' | 'DRIVER';

	export type FirmwareVersion =
		| 'VT10_V1'
		| 'VT10_V2'
		| 'VT10_V3'
		| 'GV300'
		| 'GMT200_A06V23'
		| 'ConcoxMT200'
		| 'WeTrack2'
		| 'GV300CAN'
		| 'GV800'
		| 'X8_V1'
		| 'X8_V2'
		| 'X8_V3'
		| 'VT200'
		| 'EVIX_V1'
		| 'AtlantisAppNoDevice'
		| 'GMT200_A08V04'
		| 'GMT200_A09V03'
		| 'Iofrog'
		| 'Teltonika'
		| 'OB22'
		| 'GL320M'
		| 'SmartCoffee';

	export type DeviceStatus = 'ACTIVE' | 'PENDING' | 'UNINSTALLED';

	export type RequestType = 'SET_ACTUATOR_VALUE' | 'GET_LOCATION' | 'CALL_BACK' | 'SEND_CONFIG' | 'FREE_TEXT';

	export type EventType =
		'CUSTOM'
		| 'ZONE'
		| 'GENERIC'
		| 'SENSOR'
		| 'REQUEST_QUEUED'
		| 'REQUEST_SENT'
		| 'REQUEST_SENDING'
		| 'REQUEST_CANCELLED';

	export type IncomingMessageType = 'RAW' | 'KEEP_ALIVE' | 'DATA' | 'INVALID' | 'REQUEST_RESPONSE';

	export type ResponseStatus = 'ACK' | 'NACK' | 'CANCELLED' | 'INVALID';

	export type IconEntityAssociationEnum = 'VEHICLE_GROUP' | 'POI_CATEGORY' | 'EVENTS' | 'PROFILE' | 'CAMPAIGN' | 'PROFILE_FLEET' | 'CLIENT' | 'ROUTE' | 'PROVIDER' | 'INVENTORY' | 'VEHICLE_BRAND' | 'INVENTORY_DOCUMENTS' | 'VEHICLE';

	export type MaintenancePeriodicityType = 'TIME_BASED' | 'DISTANCE_BASED';

	export type MaintenancePeriodicityTimeUnit = 'DAYS' | 'MONTHS' | 'YEARS';

	export type Types =
		'VT10'
		| 'GMT200'
		| 'GV300'
		| 'ConcoxMT200'
		| 'WeTrack2'
		| 'GV300CAN'
		| 'GV800'
		| 'X8'
		| 'VT200'
		| 'EVIX'
		| 'Iofrog'
		| 'Teltonika'
		| 'OB22'
		| 'GL320M'
		| 'SmartCoffee';


	//export type View = 'GRID' | 'MAP' | 'TELEMETRY' | 'QUICK_ACTIONS'| 'TABLE' | 'LIST';
	export type View = 'GRID' | 'TABLE';

	//export type View = 'GRID' | 'MAP' | 'TELEMETRY' | 'QUICK_ACTIONS';
	//export type View = 'TABLE' | 'LIST';

	export type ReportStatus = 'PENDING' | 'COMPUTING' | 'COMPLETED' | 'ERROR';

	export type ReportType = 'DRIVERS_EXPIRATION';

	export type Language = 'ES' | 'EN' | 'FR' | 'CA' | 'IT';

	export type Metric = 'INTERNATIONAL_SYSTEM_OF_UNIT' | 'IMPERIAL_SYSTEM';

	export type TableSize = 'BIG' | 'SMALL';

	export type Status = 'ACK' | 'PENDING' | 'NACK' | 'SAVED_BUT_NOT_SENT' | 'CANCELLED';

	export type RequestStatus = 'WAITING' | 'ONGOING' | 'CANCELED' | 'COMPLETED';

	export type RequestChannel = 'SMS' | 'GPRS';

	//export type InvDocumentType = 'NOTE' | 'LICENSE' | 'DGT_POINTS';

	export type InvLicenseType = 'AM' | 'A1' | 'A2' | 'A' | 'B' | 'C1' | 'C' | 'D1' | 'D' | 'BE' | 'C1E' | 'CE' | 'D1E' | 'DE';

	export type PowerSavingEnum = 'DISABLE' | 'MODE1' | 'MODE2';

	export type PowerSavingEnumGMT200 = 'DISABLE' | 'MODE1' | 'MODE2';

	export type GSMReportModeEnum =
		| 'NO_CELL_INFO_REPORT'
		| 'REPORT_CELL_INF_AFTER_FAILING_TO_GET_GPS'
		| 'REPORT_MSG_INF_RESPGTGSM_AFTER_GETTING_GPS_POSITION_SUCCESSFULL'
		| 'REPORT_MSG_INF_RESPGTGSM_REFARDLESS_OF_GPS_POSITION_FIX_RESULT_EVERY_TIME';

	export type GPSWorkingModeEnum = 'GPS_GLONASS' | 'GPS' | 'GLONASS';

	export type ModeFRI = 'Disable' | 'Time' | 'Distance' | 'Mileage' | 'Optimum' | 'TimeOrMileage';

	export type ModeFRIGMT200 = 'Disable' | 'Time' | 'Distance' | 'Mileage' | 'Optimum';

	export type ModeSAEnum = 'DISABLE' | 'REPORT_IN' | 'REPORT_OUT' | 'REPORT_UPDATE' | 'REPORT_IN_OUT';

	export type ModeSAEnumGMT200 = 'DISABLE' | 'REPORT_IN' | 'REPORT_OUT' | 'REPORT_UPDATE';

	export type ModeSos = 'DISABLE' | 'CALL_ONLY' | 'BACKEND' | 'BACKEND_CALL' | 'SMS_GATEWAY_AND_CALL';

	export type ModeSosGMT200 = 'DISABLE' | 'GPRS' | 'SMS';

	export type ModeHBM = 'DISABLE' | 'GPS' | 'MOTION_SENSOR' | 'GPS_OR_MS' | 'GPS_AND_MS';

	export type JammingModeEnum = 'DISABLE' | 'MODE1' | 'MODE2';

	export type JammingModeEnumGMT200 = 'DISABLE' | 'MODE1' | 'MODE2';

	export type ModeAis = 'DISABLE' | 'ENABLE_WITHIN' | 'ENABLE_OUTSIDE' | 'FUEL_SENSOR' | 'NO_ALARM' | 'PROCESSED_MODE';

	export type ModeDisIgn = 'HARD_WIRED' | 'MOTION_SATAUS' | 'EXTERNAL_POWER' | 'RESERVED' | 'ACCELEROMETER_MODE';

	export type VehicleActivityType = 'MOVING' | 'STOP';

	export type DashBoardsEnum = 'SUPERSET' | 'SMART_COFFEE';

	export type SpeedEventOutputPorts = 'DISABLE' | 'OUTPUT_1' | 'OUTPUT_2' | 'OUTPUT_3' | 'OUTPUT_4';

	export type SpeedEventSpeedingModes =
		| 'ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY'
		| 'ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY';

	export type AnalogInputModes =
		| 'DISABLE'
		| 'EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION'
		| 'EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_OT_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION';

	export type AnalogInputOutputPorts = 'DISABLE' | 'OUTPUT_1' | 'OUTPUT_2' | 'OUTPUT_3' | 'OUTPUT_4';

	export type MileageConfigModes =
		| 'DISABLE'
		| 'MILEAGE_WILL_BE_ACCUMULATED_REGARDLESS_THE_ACC_STATUS'
		| 'MILEAGE_WILL_BE_ACCUMULATED_ONLY_IF_THE_ACC_IS_ON';

	export type TrackMode =
		| 'DISABLE'
		| 'TIME'
		| 'DISTANCE'
		| 'TIME_AND_DISTANCE'
		| 'TIME_OR_DISTANCE'
		| 'HEADING'
		| 'HEADING_OR_TIME'
		| 'HEADING_OR_DISTANCE'
		| 'HEADING_OR_TIME_AND_DISTANCE'
		| 'HEADING_OR_TIME_OR_DISTANCE';

	export type TrackBasis = 'GPS_FIXED' | 'ANY_GPS' | 'ACC_ON_AND_GPS_FIXED' | 'ACC_ON_AND_ANY_GPS';

	export type InputValue = 'ANY' | 'ON' | 'OFF';

	export type PowerSavingMode = 'MODE_0' | 'MODE_1' | 'MODE_2';

	export type ARMEventAction = 'DISABLE' | 'LOGGING' | 'POLLING' | 'LOGGINPOLLING';

	export type DEnum = 'ALL' | 'SENSOR' | 'ACTUATOR';

	export type ModeAlarmMovingEnumConcoxMT200 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL' | 'GPRS_CALL';

	export type ModeBatteryAlarmEnumConcoxMT200 = 'GPRS' | 'GPRS_SMS';

	export type ModePowerAlarmEnumConcoxMT200 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL' | 'GPRS_CALL';

	export type ModeServerEnumConcoxMT200 = 'IP_ADDRESS' | 'DOMAIN_NAME';

	export type ModeProtocolServerEnumConcoxMT200 = 'TCP' | 'UDP';

	export type ModeSOSEnumConcoxMT200 = 'DELETE' | 'ADD';

	export type ModeVibrationAlarmEnumConcoxMT200 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL' | 'GPRS_CALL';

	export type ModeAlarmEnumConcoxMT200 = 'GPRS' | 'GPRS_SMS';

	export type ModeFenceEnumConcoxMT200 = 'CIRCLE_AREA' | 'RECTANGLE_AREA';

	export type ModeAlarmFenceEnumConcoxMT200 = 'IN' | 'OUT';

	export type ModeAlarmWeTrack2 = 'GPRS' | 'GPRS_SMS';

	export type ModePowerAlarmWeTrack2 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL';

	export type ModeAdressServerWeTrack2 = 'IP_ADDRESS' | 'DOMAIN_NAME';

	export type ModeProtocolServerWeTrack2 = 'TCP' | 'UDP';

	export type ModeAlarmMovingWeTrack2 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL';

	export type ModeSOSWeTrack2 = 'DELETE' | 'ADD';

	export type ModeVibrationAlarmWeTrack2 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL';

	export type ModeFenceWeTrack2 = 'CIRCLE_AREA' | 'RECTANGLE_AREA';

	export type ModeAlarmFenceWeTrack2 = 'IN' | 'OUT';

	export type ModeACCAlarmWeTrack2 = 'ACC_CHANGED_ALARM' | 'ACC_TURN_OFF_ALARM' | 'ACC_TURN_ON_ALARM';

	export type ModeExternalBatteryAlarmWeTrack2 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL' | 'GPRS_CALL';

	export type LedOnEnumGMT200 =
		| 'EACH_TIME_DEVICE_POWER_ON_LED_AND_GNSS_LED_WORK_30_MINUTES_AND_TURN_OFF'
		| 'POWER_LED_GNSS_LED_AND_GSM_LED_TURN_ON'
		| 'POWER_LED_GNSS_LED_AND_GSM_LED_WORK_30_MINUTES_AND_TURN_OFF';

	export type ModeCommandStorageEnumGMT200 = 'DELETE_COMMAND' | 'ADD_COMMAND';

	export type ModeEPSEnumGMT200 =
		'DISABLE'
		| 'ENABLE_WITHIN_RANGE'
		| 'ENABLE_OUTSIDE_RANGE'
		| 'ENABLE_WITHIN_OR_OUTSIDE_RANGE';

	export type ModeStoCmdEnumGMT200 = 'NOT_SEND_ACK_MESSAGE' | 'SEND_ACK_MESSAGE';

	export type ModeUDFEnumGMT200 = 'DISABLE_FUNCTIONS' | 'ENABLE_FUNCTIONS' | 'DELETE_FUNCTIONS';

	export type ModeGEOEnumGMT200 = 'DISABLE' | 'ENTER_ZONE' | 'EXIT_ZONE' | 'EXIT_ENTER_ZONE';

	export type ModeGEOStateEnumGMT200 = 'REPORT_STATE_FIRST_TIME' | 'REPORT_UNTIL_STATE_CHANGES';

	export type ModeReportSRIEnumGMT200 =
		| 'STOP'
		| 'TCP_SHORT_CONNECTION_PREFERRED'
		| 'TCP_SHORT_CONNECTION_FORCED'
		| 'TCP_LONG_CONNECTION'
		| 'UDP'
		| 'FORCED_SMS'
		| 'UDP_FIXED_LOCAL_PORT,BACKUP_SERVER_CONNECTION_TCP_LONG_CONNECTION';

	export type ModeBufferSRIEnumGMT200 = 'DISABLE' | 'LOW_PRIORITY' | 'HIGH_PRIORITY';

	export type ModeCanAdditionalEventEnum = 'ignore_all_additional_event' | 'by_ignition_on_off_event';

	export type ModeIDAEnum = 'Disable' | 'only_authorized_id_cards_unlock_vehicle' | 'any_id_card_unlock_vehicle';

	export type ModeReportIdEnum = 'report_id_authorized' | 'report_id_unauthorized' | 'report_id_logged_out';

	export type ModeRFIDReportEnum =
		'report_latest_rfid_after_getting_card_id'
		| 'report_latest_rfid_when_ignition_turned_on_and_card_authorized';

	export type PowerSavingGV300CANEnum = 'DISABLE' | 'MODE1' | 'MODE2';

	export type ModeFRIGV300CAN = 'Disable' | 'Time' | 'Distance' | 'Mileage' | 'Optimum' | 'TimeOrMileage';

	export type ModeSAEGV300CANEnum = 'DISABLE' | 'REPORT_IN' | 'REPORT_OUT' | 'REPORT_UPDATE';

	export type ModeSosGV300CAN = 'DISABLE' | 'GPRS' | 'SMS';

	export type ModeHBMGV300CAN = 'DISABLE' | 'GPS' | 'MOTION_SENSOR' | 'GPS_OR_MS' | 'GPS_AND_MS';

	export type JammingModeGV300CANEnum = 'DISABLE' | 'MODE1' | 'MODE2';

	export type ModeAisGV300CAN =
		'DISABLE'
		| 'ENABLE_WITHIN'
		| 'ENABLE_OUTSIDE'
		| 'FUEL_SENSOR'
		| 'NO_ALARM'
		| 'PROCESSED_MODE';

	export type ModeDisIgnGV300CAN =
		'HARD_WIRED'
		| 'MOTION_STATUS'
		| 'EXTERNAL_POWER'
		| 'RESERVED'
		| 'ACCELEROMETER_MODE';

	export type ModeIDAGV300CANEnum =
		'Disable'
		| 'only_authorized_id_cards_unlock_vehicle'
		| 'any_id_card_unlock_vehicle';

	export type ModeReportIdGV300CANEnum =
		'Disable'
		| 'report_id_authorized'
		| 'report_id_unauthorized'
		| 'authorized_unauthorized';

	export type GSMReportModeGV300CANEnum =
		| 'NO_CELL_INFO_REPORT'
		| 'REPORT_CELL_INF_AFTER_FAILING_TO_GET_GPS'
		| 'REPORT_MSG_INF_RESPGTGSM_AFTER_GETTING_GPS_POSITION_SUCCESSFULL'
		| 'REPORT_MSG_INF_RESPGTGSM_REFARDLESS_OF_GPS_POSITION_FIX_RESULT_EVERY_TIME';

	export type GPSWorkingModeGV300CANEnum = 'GPS_GLONASS' | 'GPS' | 'GLONASS';

	export type DeviceCommunicationType = 'SMS' | 'GPRS_ATLANITS' | 'GPRS_OTHERS';

	export type AppTypes = 'FLEET' | 'MOTO' | 'CAR' | 'AGRO';

	export type PowerSavingEnumGV800 = 'DISABLE' | 'MODE1' | 'MODE2' | 'MODE3';

	export type GSMReportModeGV800Enum =
		| 'NO_CELL_INFO_REPORT'
		| 'REPORT_CELL_INF_AFTER_FAILING_TO_GET_GPS'
		| 'REPORT_MSG_INF_RESPGTGSM_AFTER_GETTING_GPS_POSITION_SUCCESSFULL'
		| 'REPORT_MSG_INF_RESPGTGSM_REFARDLESS_OF_GPS_POSITION_FIX_RESULT_EVERY_TIME';

	export type ModeFRIGV800 = 'Disable' | 'Time' | 'Distance' | 'Mileage' | 'Optimum' | 'TimeOrMileage';

	export type ModeSAEnumGV800 = 'DISABLE' | 'REPORT_IN' | 'REPORT_OUT' | 'REPORT_UPDATE' | 'REPORT_IN_OUT';

	export type ModeSOSGV800 = 'DISABLE' | 'CALL_ONLY' | 'BACKEND' | 'BACKEND_CALL' | 'SMS_GATEWAY_AND_CALL';

	export type ModeHBMGV800 = 'DISABLE' | 'GPS' | 'MOTION_SENSOR' | 'GPS_AND_MS';

	export type ModeAisGV800 =
		'DISABLE'
		| 'ENABLE_WITHIN'
		| 'ENABLE_OUTSIDE'
		| 'FUEL_SENSOR'
		| 'NO_ALARM'
		| 'PROCESSED_MODE';

	export type ModeCanAdditionalEventEnumGV800 = 'ignore_all_additional_event' | 'by_ignition_on_off_event';

	export type ModeIDAGV800Enum = 'Disable' | 'only_authorized_id_cards_unlock_vehicle' | 'any_id_card_unlock_vehicle';

	export type ModeReportIdGV800Enum = 'report_id_authorized' | 'report_id_unauthorized' | 'report_id_logged_out';

	export type TrackModeX8 =
		'DISABLE'
		| 'TIME'
		| 'DISTANCE'
		| 'INTELLITRACK'
		| 'TIME_AND_ACC'
		| 'DISTANCE_AND_ACC'
		| 'INTELLITRACK_AND_ACC';

	export type ReportActionX8 =
		| 'DISABLE'
		| 'LOGGING'
		| 'POLLING'
		| 'LOGGING_AND_POLLING'
		| 'SET_OUTPUT'
		| 'LOGGING_AND_SET_OUTPUT'
		| 'POLLING_AND_SET_OUTPUT'
		| 'LOGGING_AND_POLLING_AND_SET_OUTPUT';

	export type SleepModeX8 = 'MODE_0' | 'MODE_1' | 'MODE_2';

	export type AnalogInputModeX8 = 'DISABLE' | 'VOLTAGE_INSIDE_RANGE' | 'VOLTAGE_OUTSIDE_RANGE';

	export type ReportModeIdleEventX8 = 'DISABLE' | 'LOGGING' | 'POLLING' | 'LOGGING_AND_POLLING';

	export type AnalogInputModesVT200 =
		| 'DISABLE'
		| 'EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION'
		| 'EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_OT_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION';

	export type AnalogActionModeVT200 = 'DISABLE' | 'LOGGING' | 'POLLING' | 'LOGGING_AND_POLLING';

	export type AnalogInputOutputPortsVT200 = 'DISABLE' | 'OUTPUT_1' | 'OUTPUT_2' | 'OUTPUT_3' | 'OUTPUT_4';

	export type PowerSavingModeVT200 = 'MODE_0' | 'MODE_1' | 'MODE_2';

	export type SleepingMaskModeVT200 = 'MODE1' | 'MODE2' | 'MODE3' | 'MODE4';

	export type SpeedEventModeVT200 = 'DISABLE' | 'POLLING' | 'LOGGING' | 'POLLING_AND_LOGGING';

	export type SpeedEventOutputPortsVT200 = 'DISABLE' | 'OUTPUT_1' | 'OUTPUT_2' | 'OUTPUT_3' | 'OUTPUT_4';

	export type SpeedEventSpeedingModesVT200 =
		| 'ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY'
		| 'ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY';

	export type TowEventModeVT200 = 'DISABLE' | 'POLLING' | 'LOGGING' | 'POLLING_AND_LOGGING';

	export type TrackModeVT200 =
		| 'DISABLE'
		| 'TIME'
		| 'DISTANCE'
		| 'TIME_AND_DISTANCE'
		| 'TIME_OR_DISTANCE'
		| 'HEADING'
		| 'HEADING_OR_TIME'
		| 'HEADING_OR_DISTANCE'
		| 'HEADING_OR_TIME_AND_DISTANCE'
		| 'HEADING_OR_TIME_OR_DISTANCE';

	export type TrackBasisVT200 = 'GPS_FIXED' | 'ANY_GPS' | 'ACC_ON_AND_GPS_FIXED' | 'ACC_ON_AND_ANY_GPS';

	export type PowerModeEnumGMT200 =
		'CHARGE_ON_NEED'
		| 'CHARGE_IGNITION_ON'
		| 'CHARGE_IGNITION_ON_OR_OFF_WITH_LOW_POWER';

	export type CellInfoReportEnumGMT200 = 'DISABLE' | 'REPORT_WHEN_GNSS' | 'REPORT_GTGSM_WHEN_GNSS' | 'REPORT_GTGSM';

	export type ModeGEOTriggerEnumGMT200 =
		| 'DISABLE'
		| ' AUTO_GEOFENCE_AFTER_IGNITION_OFF'
		| 'MANUAL_GEOFENCE_AFTER_IGNITION_OFF'
		| 'AUTOMATICALLY_GEOFENCE_AFTER_IGNITION_OFF';

	export type ModeGEOReportTriggerEnumGMT200 = 'DISABLE_GTGES' | 'ENABLE_GTGES';

	export type LocationCallEnumGMT200 = 'CALL' | 'CALL_AND_REPORT_GTLBC' | 'CALL_AND_CURRENT_POSITION';

	export type ModeDOGEnumGMT200 = 'DISABLE' | 'REBOOT_PERIODICALLY' | 'REBOOT_WHEN_IGNITION_ON';

	export type ModePDSEnumGMT200 = 'DISABLE' | 'PRESERVE' | 'RESET';

	export type ModeFCCEnumGMT200 =
		| 'DISABLE'
		| 'CHANGE_REPORT_IN_GTFRI_WHEN_IGNITION_OFF'
		| 'CHANGE_REPORT_IN_GTNMD_WHEN_DEVICE_NON_MOVEMENT_STATE'
		| 'CHANGE_REPORT_IN_GTTOW_WHEN_DEVICE_IS_TOWED';

	export type ModeCRAEnumGMT200 = 'DISABLE' | 'ENABLE' | 'MODE2' | 'MODE3';

	export type ModeCRASamplingStartEnumGMT200 = 'ACCELERATION_AFTER_POWER_ON' | 'ACCELERATION_AFTER_IGNITION_ON';

	export type MODEVMSEnumGMT200 =
		| 'DISABLE'
		| 'IGNITION_STATUS'
		| 'VOLTAGE_DETECTION_MODE'
		| 'ACCELEROMETER_DETECTION_MODE'
		| 'COMBINED_DETECTION_MODE';

	export type MODEVVSEnumGMT200 = 'DISABLE_SMART_VOLTAGE_ADJUSTMENT' | 'ENABLE';

	export type ModeTOWEnumGMT200 =
		'DISABLE'
		| 'ENABLE_SEND_TOW_ACCORDING_TOW_INTERVAL'
		| 'ENABLE_SEND_TOW_IMMEDIATELY_WHEN_ENTERING_OR_EXIT';

	export type ModeIDLEnumGMT200 = 'DISABLE' | 'ENABLE';

	export type ModeGEOLastPointGMT200 = 'DISABLE' | 'ENABLE';

	export type PlatformApp = 'IOS' | 'ANDROID';

	export type ModeBatteryAlarmEnumOB22 = 'GPRS' | 'GPRS_SMS';

	export type ModePowerAlarmEnumOB22 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL';

	export type ModeServerEnumOB22 = 'IP_ADDRESS' | 'DOMAIN_NAME';

	export type ModeProtocolServerEnumOB22 = 'TCP' | 'UDP';

	export type ModeSOSEnumOB22 = 'DELETE' | 'ADD';

	export type ModeFenceEnumOB22 = 'CIRCLE_AREA' | 'RECTANGLE_AREA';

	export type ModeAlarmFenceEnumOB22 = 'IN' | 'OUT';

	export type ModeAlarmEnumOB22 = 'GPRS' | 'GPRS_SMS';

	export type ModePullAlarmEnumOB22 = 'GPRS' | 'SMS_GPRS' | 'GPRS_SMS_PHONECALL' | 'GPRS_PHONECALL';

	//Report Files
	export type ReportTypesEnum = 'TAX';
	export type ReportFileEntityType = 'VEHICLE' | 'GROUP' | 'FLEET' | 'CLIENT';
	export type ReportFilePeriodType = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'ANNUAL';



	//ENUM GL320M
	export type NetworkModeBSIGL320M = 'AUTO' | 'GSM_ONLY' | 'LTE_ONLY' | 'GSM_FIRST';
	export type LTEModeBSIGL320M = 'M1_NB2' | 'NB2_M1' | 'M1' | 'NB2' | 'NB2_FIRST' | 'NB2_ONLY';
	export type ModeAuthenticationMethodBSIGL320M = 'NONE' | 'PAP' | 'CHAP' | 'PAP_OR_CHAP';
	export type ManualNetregBSIGL320M = 'DISABLE' | 'ENABLE';
	export type ModeReportSRIGL320M = 'STOP' | 'TCP_SHORT_CONNECTION_PREFERRED' | 'TCP_SHORT_CONNECTION_FORCED' | 'TCP_LONG_CONNECTION' | 'UDP' | 'FORCED_SMS' | 'UDP_FIXED_LOCAL_PORT' | 'BACKUP_SERVER_CONNECTION_TCP_LONG_CONNECTION';
	export type ModeBufferSRIGL320M = 'DISABLE' | 'LOW_PRIORITY' | 'HIGH_PRIORITY';
	export type ModeSackSRIGL320M = 'DISABLE' | 'RECIEVE_AND_CHECK' | 'RECIEVE_ONLY';
	export type MultiPacketSendingSRIGL320M = 'DISABLE' | 'ENABLE';
	export type ModeReportQSSGL320M = 'STOP' | 'TCP_SHORT_CONNECTION_PREFERRED' | 'TCP_SHORT_CONNECTION_FORCED' | 'TCP_LONG_CONNECTION' | 'UDP' | 'FORCED_SMS' | 'UDP_FIXED_LOCAL_PORT' | 'BACKUP_SERVER_CONNECTION_TCP_LONG_CONNECTION';
	export type ModeBufferQSSGL320M = 'DISABLE' | 'LOW_PRIORITY' | 'HIGH_PRIORITY';
	export type ModeSackQSSGL320M = 'DISABLE' | 'RECIEVE_AND_CHECK' | 'RECIEVE_ONLY';
	export type ModeGpsCFGGL320M = 'DISABLE' | 'TURN_OFF_AFTER_GPS_INFORMATION' | 'DISABLE_TURN_OFF_IN_IGNITION_OR_MOVEMENT';
	export type ModeAgpsCFGGL320M = 'DISABLE' | 'OFFLINE';
	export type LedOnCFGGL320M = 'EACH_TIME_AFTER_DEVICE_POWER_ON' | 'LEDS_NORMALLY' | 'LEDS_ALWAYS_OFF';
	export type ModeEpbCFGGL320M = 'DISABLE' | 'ENABLE';
	export type ModeGsmCFGGL320M = 'CELL_INFORMATION_NOT_ALLOWED' | 'ALLOW_CELL_INFORMATION_AFTER_FAILING_GPS_POSITION' | 'REPORT_GTGSM_AFTER_SUCCESSFULL_GPS' | 'REPORT_GTGSM';
	export type ModeWalkingCFGGL320M = 'DISABLE' | 'ENABLE';
	export type ModeDOGGL320M = 'DISABLE' | 'REBOOT_PERIODICALLY' | 'REBOOT_WHEN_IGNITION_ON';
	export type ModeUnitDOGGL320M = 'DAY' | 'HOUR';
	export type ModeSignTMAGL320M = 'PLUS' | 'MINUS';
	export type ReportUrcNMDGL320M = 'DISABLE' | 'ENABLE';
	export type MovementCommandNMDGL320M = 'NOT_CHANGE_MOTION_STATE' | 'FORCE_MOVEMENT_STATE';
	export type ModeReportNMDGL320M = 'REPORT_LAST_POSITION' | 'REPORT_CURRENT_POSITION' | 'REPORT_LAST_POSITION_THEN_TURN_ON_GPS';
	export type ModePowerKeyFKSGL320M = 'PRESSING_NOT_POWER' | 'PRESSING_POWER' | 'LONG_PRESSING_POWER_ACTIVATE_SOS';
	export type ModePowerFKSGL320M = 'DISABLE' | 'ENABLE';
	export type ModeFunctionKeyFKSGL320M = 'IGNORE' | 'GEO_FENCE' | 'GEO_FENCE_CURRENT_POSITION' | 'SOS' | 'LOCATION' | 'MIXED';
	export type ModePowerKeyIndicationFKSGL320M = 'DISABLE' | 'ENABLE';
	export type ModeFunctionKeyIndicationFKSGL320M = 'DISABLE' | 'ENABLE';
	export type ModeSosReportFKSGL320M = 'REPORT_LAST_GPS' | 'REPORT_CURRENT_GPS' | 'REPORT_LAST_GPS_THEN_REPORT_CURRENT_GPS';
	export type ModeTriggerFKSGL320M = 'IGNORE' | 'GEO_FENCE' | 'GEO_FENCE_CURRENT_POSITION' | 'SEND_SOS' | 'SEND_GTLOC';
	export type ModeOWHGL320M = 'DISABLE' | 'MANUAL' | 'FULL_MANUAL' | 'AUTOMATIC';
	export type ModeRFSleepOWHGL320M = 'NO_SHUT_DOWN' | 'SHUT_DOWN';
	export type ModeIccidSIMGL320M = 'NOT_BIND' | 'BIND_CURRENT_SIM' | 'BIND_SPECIFIED_SIM' | 'BIND_SIM_AND_LOCK';
	export type ModeFRIGL320M = 'DISABLE' | 'TIME' | 'DISTANCE' | 'MILEAGE' | 'OPTIMUM' | 'RESERVED' | 'TIME_OR_MILEAGE';
	export type ModeMovementDetectionFRIGL320M = 'DISABLE' | 'ENABLE';
	export type ModeGEOGL320M = 'DISABLE' | 'ENTER_ZONE' | 'EXIT_ZONE' | 'EXIT_ENTER_ZONE';
	export type ModeStateGEOGL320M = 'REPORT_STATE_FIRST_TIME' | 'REPORT_UNTIL_STATE_CHANGES';
	export type ModeSPDGL320M = 'DISABLE' | 'REPORT_IN' | 'REPORT_OUT' | 'REPORT_IN_OR_OUT' | 'REPORT_CHANGED_IN_TO_OUT_OR_OUT_TO_IN';
	export type ModeTEMGL320M = 'DISABLE' | 'REPORT_LOWER_THAN_MIN_TEMP' | 'REPORT_INSIDE_RANGE' | 'REPORT_HIGHER_THAN_MAX_TEMP' | 'REPORT_INSIDE_OR_OUTSIDE_RANGE';
	export type ModeMSAGL320M = 'DISABLE' | 'ENABLE';
	export type ModeLastPositionMSAGL320M = 'DISABLE' | 'ENABLE';
	export type ModeDISGL320M = 'DISABLE' | 'REPORT_GTDIS_ON_CHANGES' | 'CHANGES_SLEEP_MODE' | 'SOS_EVENT';
	export type ModePDSGL320M = 'DISABLE' | 'PRESERVE' | 'RESET';
	export type ModeGAMGL320M = 'DISABLE' | 'ENABLE';
	export type ModeSpeedGAMGL320M = 'DISABLE' | 'ENABLE';
	export type ModeCommandStorageCMDGL320M = 'DELETE_COMMAND' | 'ADD_COMMAND';
	export type ModeUDFGL320M = 'DISABLE_FUNCTIONS' | 'ENABLE_FUNCTIONS' | 'DELETE_FUNCTIONS';
	export type ModeStoCmdUDFGL320M = 'NOT_SEND_ACK_MESSAGE' | 'SEND_ACK_MESSAGE';

	// PROGRAMA DE FIDELITZACI�
	export type FidCampaignType = 'PUBLI' | 'PROMO' | 'SERVICE' | 'CLUB' | 'ROUTE';
	export type FidParameters = 'VEHICLE_MARCA' | 'VEHICLE_MODEL' | 'VEHICLE_ANY' | 'VEHICLE_CILINDRADA' |
		'VEHICLE_REGISTERED' | 'DEVICE_TYPE' | 'DEVICE_COMMUNICATION' | 'USER_ID' | 'USER_SEXE' | 'USER_EDAD' |
		'USER_CP' | 'USER_PAIS' | 'USER_LANGUAGE' | 'VEHICLE_KMS' | 'DEVICE_SIM_CADUCADA';
	export type FidFilterType = 'EQ' | 'GT' | 'GE' | 'LT' | 'LE' | 'CO' | 'NE';
	export type FidLinkType = 'INFO' | 'BUY' | 'INFOX';
	export type FuelType = 'lblPetrol' | 'lblDiesel' | 'lblEtanol' | 'lblHidrogen' | 'lblBiodiesel' | 'lblElectricity' | 'lblMetanol' | 'lblGNC' | 'lblGNL' | 'lblGLP' | 'lblHEV' | 'lblMHEV' | 'lblPHEV' | 'lblother';
	export type CardConsumptionInputType = 'IMPORT' | 'MANUAL';
	export type RenewalFilesType = 'CARD' | 'INSURANCE' | 'CONSUMPTION';
	function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
		let result = '';
		for (let i = 0; i < substitutions.length; i++) {
			result += template[i];
			result += encodeURIComponent(substitutions[i]);
		}
		result += template[template.length - 1];
		return result;
	}
}
