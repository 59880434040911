import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { FineStatusService } from 'app/modules/vehicle-inventory/services/table-management/fineStatus.service';

import FineStatus = Rest.FineStatus;

@Component({
    selector: 'app-fineStatus-form',
    templateUrl: './fineStatus-form.component.html',
    styleUrls: ['./fineStatus-form.component.scss'],
    providers: [FineStatusService],

})
export class FineStatusComponent extends EntityFormComponent<FineStatus>
    implements OnInit {

    norticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;


    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    constructor(
        private fineStatusService: FineStatusService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(fineStatusService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof FileStatusFormComponent
     */
    beforeShow() {
        this.active = !this.entity.disabled;

        /*Permissions */
        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.fineStatus.createFineStatus');
            this.entity.color = "#ff0000";
        } else {
            this.title = this.translateService.instant('tableManagement.fineStatus.createFineStatus');

        }
    }
}
