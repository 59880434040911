<p-accordionTab>

  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Power Saving Configuration</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="mode" [errors]="errors.mode">
        <p-dropdown [options]="availablePowSavingmodes" (onChange)="powerSavingModeChange($event)" [(ngModel)]="powerSaving.mode" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>
    <div [class.disableDIV]=!powerSavingSwitch>
      <div class="ui-g-12 ui-md-6">
        <div class="ui-g-4">
        </div>
        <div class="ui-g-8">
        </div>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="delay" [errors]="errors.delay">
          <div class="ui-inputgroup">
            <p-spinner id="delay" size="5" [(ngModel)]="powerSaving.delay" [min]="60" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">60~65535</span>
            <span class="ui-inputgroup-addon" i18n>seconds</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="duration" [errors]="errors.duration">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="powerSaving.duration" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535</span>
            <span class="ui-inputgroup-addon" i18n>hours</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="Off regardless track" [errors]="errors.offRegardlesOfTrack">
          <p-inputSwitch [(ngModel)]="powerSaving.offRegardlesOfTrack"></p-inputSwitch>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="g sensor detection" [errors]="errors.enableGSensorDetection">
          <p-inputSwitch [(ngModel)]="powerSaving.enableGSensorDetection"></p-inputSwitch>
        </form-input>
      </div>
    </div>
  </div>

</p-accordionTab>
