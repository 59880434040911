import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { I18nService } from "app/services/i18n/i18n.service";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-analog-input-configuration-form-x8",
  templateUrl: "./analog-input-configuration-form-x8.component.html",
  styleUrls: ["./analog-input-configuration-form-x8.component.css"],
})
export class AnalogInputConfigurationFormX8Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @ViewChild("ContentDiv") ContentDiv;
  @Input() analogInput: Rest.AnalogInputConfigurationX8;

  ModeOptions: any = [
    { label: "Disable", value: 0 },
    { label: "Voltage level is in range between min and max value", value: 1 },
    {
      label: "Voltage level is not in the range between min and max value",
      value: 2,
    },
  ];

  ReportModeOptions: any = [
    { label: "Disable", value: 0 },
    { label: "Logging", value: 1 },
    { label: "Polling", value: 2 },
    { label: "Logging and polling", value: 3 },
    { label: "Set Output", value: 4 },
    { label: "Logging and Set Output", value: 5 },
    { label: "Polling and Set Output", value: 6 },
    { label: "Logging and polling and Set Output", value: 7 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.analogInput);
    }
  }
  trackModeSwitch = false;
  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {}
}
