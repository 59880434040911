import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class TargetsService extends EntityService<Rest.FidTargets> {


	client: Rest.RestApplicationClient;
	startEditTarget = new Subject<{isNew: boolean, target: Rest.FidTargets}>();
	endTargetEditing = new Subject<Rest.FidTargets>();
	targetAdded = new Subject<Rest.FidTargets>();
	targetSaved = new Subject<Rest.FidTargets>();

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	public updateTarget(data): Promise<Rest.FidTargets> {
		// console.log("[TARGETS-SERVICE] *** updateTarget ****");
		// console.log(data);
		return this.http
		.post(environment.webApiBaseUrl + `api/fid/targets/save`, data)
		.toPromise()
		.then((response: any) => {
			 return response;
		})
		.catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
	}

	public createTarget(data): Promise<Rest.FidTargets> {
		// console.log("[TARGETS-SERVICE] *** createTarget ****");
		// console.log(data);
		return this.http
		.post(environment.webApiBaseUrl + `api/fid/targets`, data)
		.toPromise()
		.then((response: any) => {
			 return response;
		})
		.catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
	}

	public deleteTarget(data): Promise<Rest.FidTargets> {
		// console.log("[TARGETS-SERVICE] *** deleteTarget ****");
		// console.log(data);
		return this.http
		.delete(environment.webApiBaseUrl + `api/fid/targets/` + data)
		.toPromise()
		.then((response: any) => {
			 return response;
		})
		.catch((error) => {
			 this.notificationsService.add(Severity.error, 'Error', error.message);
			 return null;
		});
	}

}
