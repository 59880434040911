import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'millisToHumanReadable' })
export class MillisecondsToDuration implements PipeTransform {
    
  constructor() {

  }

  /**
   * Transform milliseconds into date format
   * @param time data or time in milliseconds format
   * @param toMinuts true if you want only minuts, false for full date
   */
  transform(time: any, toMinuts: boolean): any {

    let dateString = '';

    if(toMinuts){
        if(time!=null){
            return (time / (60 *1000));
        }else{
            return null;
        }
        
    }
    // Calculate the values
    const days = Math.floor( time / (24 * 60 * 60 * 1000));
    time = time - (days * 24 * 60 * 60 * 1000);
    const hours = Math.floor( time / (60 * 60 * 1000));
    time = time - (hours * 60 * 60 * 1000);
    const minutes = Math.floor( time / (60 * 1000));
    time = time - (minutes * 60 * 1000);
    const seconds = time / 1000;

    // Build the string
    if (days !== 0) {
      dateString += ' ' + days + 'd ';
    }
    if (hours !== 0) {
      dateString += ' ' + hours + 'h ';
    }
    if (minutes !== 0) {
      dateString += ' ' + minutes + 'm ';
    }
    if (seconds !== 0 || dateString === '') {
      dateString += ' ' + seconds.toFixed(0) + 's ';
    }

    return dateString;
  }

}
