import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";

import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { Rest } from "../../../rest/rest_client";
import { RoleService } from "../../../rest/role.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { RoleFormComponent } from "../role-form/role-form.component";

@Component({
    selector: "app-roles-list",
    templateUrl: "./roles-list.component.html",
    styleUrls: ["./roles-list.component.css"],
    providers: [RoleService, ConfirmationService, UserTimePipe],
})
export class RolesListComponent
    extends EntityListComponent<Rest.Role>
    implements OnInit {
    LanguageFilter: SelectItem[];
    MetricFilter: SelectItem[];

    @ViewChild(RoleFormComponent, { static: true }) roleForm: RoleFormComponent;

    user: Rest.User;

    constructor(
        roleService: RoleService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            roleService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.canBeEnabled = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.roleForm;
        /*Permissions */
        this.user = this.authenticationService.user;
        /** */

        this.afterLoad();
    }

    afterLoad() {
        this.paginationResult.entities.forEach((r: any) => {
            /*Permissions */
            r._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }
}
