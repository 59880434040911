import { Injectable, EventEmitter } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, Observer, Subject, Subscription, timer } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';

import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { UserTimePipe } from '../../components/shared/UserTimePipe';
import { JQueryHTTPClient } from '../../rest/JQueryHTTPClient';
import { Rest } from '../../rest/rest_client';
import { NotificationsService, Severity } from '../notifications-service/notifications.service';
import { RestExt } from '../rest-client-extension';
import { windowWhen } from 'rxjs/operators';
import { Rest_logistics } from 'app/modules/task/Logistics/services/rest_client_logistics';
import { Rest_task } from 'app/modules/task/administration/services/rest_client_task';
import { DelegationService } from 'app/modules/task/administration/services/delegation.service';
import { RestExtTask } from 'app/modules/task/rest-client-task-extension';
//https://medium.com/@lwojciechowski/websockets-with-angular2-and-rxjs-8b6c5be02fac
//https://www.lucidchart.com/techblog/2016/11/08/angular-2-and-observables-data-sharing-in-a-multi-view-application/

@Injectable()
export class RealTimeDataService {
    private socket: Subject<String>;
    //Socket client periodically sends keep alive messages to server and it must answer back. If lastKeepAliveMessageRecieved > maxKeepAliveDelay
    //assume the socket has closed. and start re-connection algorithm.
    private keepAliveSendInterval = 3000; //Miliseconds between each keep alive send to the server
    private maxKeepAliveDelay = 300000; //Must be bigger than keepAliveSendInterval
    private lastKeepAliveMessageRecieved: Date;
    private keepAliveMessageSendSubscription: Subscription;
    private socketStatusCheckSubscription: Subscription;
    private socketSubscription: Subscription;
    public isFirstConnection: boolean;
    public nextConnectionTime: Date;
    private connectionStatus: ConnectionStatus;
    public connectionStatusSubject = new BehaviorSubject<ConnectionStatus>('NOT_CONNECTED');
    private nextConnectionTrialDelay: number; //Delay between connection retrials. This value starts at nextConnectionTrialBaseDelay and is incremented multiplied by nextConnectionTrialDelayIncrementFactor on each connection error.
    private nextConnectionTrialBaseDelay = 10000; //Milliseconds to next connection trial. This value. Uppon a connect
    private nextConnectionTrialDelayIncrementFactor = 1; //Each time a connection fails, nextConnectionTrialDelay = nextConnectionTrialDelay*nextConnectionTrialDelayIncrementFactor;

    //context
    private watchedResponses: string[]; //list of resquest response we are waiting for.

    private sensorChartSettings: Rest.SensorChartSettings;

    public watchedVehicles = new BehaviorSubject<RestExt.WatchedVehicle[]>([]);

    public mapInbox = new Subject<MapMessage>(); //To communicate with the map compoent, one should write to this inbox
    public mapOutbox = new Subject<MapMessage>(); //To recieve communication form the map compoent, one should listen to this out box

    private _watchedVehicles: RestExt.WatchedVehicle[];

    public vehicleInfoArr: RestExt.ExtendedVehicleDataMessage[] = [];
    public vehicleInfo = new Subject<RestExt.ExtendedVehicleDataMessage>();
    public requestResponse = new Subject<RestExt.ExtendedResponse>();
    public event = new Subject<Rest.Event>();
    public settingsChange: EventEmitter<Rest.SensorChartSettings> = new EventEmitter<Rest.SensorChartSettings>();

    //Not readed incidences variables
    public notReadedIncidents = new Subject<Rest_logistics.Order>();
    private _watchedDelegations: Rest_task.Delegation[] = [];
    public watchedDelegations = new BehaviorSubject<Rest_task.Delegation[]>([]);

    public isMapDetached = false;
    public isMaster = true;



    private ws: WebSocket;
    private userSubscription;

    user: Rest.User;

    constructor(
        private jqueryClient: JQueryHTTPClient,
        private auth: AuthenticationService,
        private localSt: LocalStorageService,
        private userTime: UserTimePipe,
        private notificationService: NotificationsService,
        private delegationService: DelegationService,
    ) {

        this.watchedResponses = [];

        this._watchedVehicles = [];


        var that = this;
        this.watchedVehicles.subscribe((wv: RestExt.WatchedVehicle[]) => {
            console.debug('watchedVehicles changed');
            //check if subscription must be sent to server
            const ids1 = wv.map((v: RestExt.WatchedVehicle) => v.id);
            const ids2 = this._watchedVehicles.map((v: RestExt.WatchedVehicle) => v.id);
            //console.debug('old: ' + ids2);
            //console.debug('new: ' + ids1);

            this._watchedVehicles = wv;
            //Discard vehicleInfoArr related to removed vehicles
            // eslint-disable-next-line eqeqeq
            this.vehicleInfoArr = [...this.vehicleInfoArr.filter((inf) => wv.findIndex((v) => v.id == inf.vehicleId) != -1)];

            //USAR PARA INCIDENCIAS
            if (this.socket != null) {

                this.user = this.auth.user;
                let canManageIncidences = RestExtTask.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_INCIDENCES_NOTREAD_VIEW, this.user);
                //TODO: AFEGIR PERMISOS PER AL FOOD
                if (canManageIncidences) {
                    this.socket.next('subscriptions:' + JSON.stringify(wv.map((v: Rest.Vehicle) => ({ id: v.id }))) + '|delegations:' + JSON.stringify(that._watchedDelegations.map((d: Rest_task.Delegation) => ({ delegationId: d.id }))));
                    console.log('SDFGSDF DDSG subscriptions:' + JSON.stringify(wv.map((v: Rest.Vehicle) => ({ id: v.id }))) + '|delegations:' + JSON.stringify(that._watchedDelegations.map((d: Rest_task.Delegation) => ({ delegationId: d.id }))))
                } else {
                    this.socket.next('subscriptions:' + JSON.stringify(wv.map((v: Rest.Vehicle) => ({ id: v.id }))));
                }
                //this.socket.next('subscriptions:' + JSON.stringify(wv.filter(x => x.id < 10000).map((v: Rest.Vehicle) => ({ vehicleId: v.id }))));
            }

            /*
            if (!ids1.every((value) => ids2.indexOf(value) >= 0) || (!ids2.every((value) => ids1.indexOf(value) >= 0)) && this.isMaster) {
                console.debug('send subscriptions request to rt server');
                //the list of ids is different, subscirption message must be sent
                //this.socket.next("subscriptions:" + JSON.stringify(wv.map((v: Rest.Vehicle) => { return { "clientId": v.client.id, "vehicleId": v.id, }; })));
                this.socket.next('subscriptions:' + JSON.stringify(wv.map((v: Rest.Vehicle) => ({ vehicleId: v.id }))));
                console.log('subscriptions:' + JSON.stringify(wv.map((v: Rest.Vehicle) => ({ vehicleId: v.id }))));
            } else {
                console.log('hola');
                //The ids of the subscrived vehicles haven't changed, but the visibility imght have. Simulate that new data
                //arrived
                this.vehicleInfoArr.forEach((vi) => this.vehicleInfo.next(vi));
            }
            */
        });

        this.watchedDelegations.subscribe((wd: Rest_task.Delegation[]) => {
            console.debug('watchedDelegations changed');


            //USAR PARA INCIDENCIAS
            if (this.socket != null) {
                this.socket.next('subscriptions:' + JSON.stringify(this._watchedVehicles.map((v: Rest.Vehicle) => ({ id: v.id }))) + '|delegations:' + JSON.stringify(wd.map((d: Rest_task.Delegation) => ({ delegationId: d.id }))));
                console.log('SDFGSDF DDSG subscriptions:' + JSON.stringify(this._watchedVehicles.map((v: Rest.Vehicle) => ({ id: v.id }))) + '|delegations:' + JSON.stringify(wd.map((d: Rest_task.Delegation) => ({ delegationId: d.id }))));
                //this.socket.next('subscriptions:' + JSON.stringify(wv.filter(x => x.id < 10000).map((v: Rest.Vehicle) => ({ vehicleId: v.id }))));
            }

        });
        // TODO: IMPLEMENT PERMISSIONS FOR FOOD
        /*this.delegationService.getPage(this.paginationRequest).then((delegations) => {
            that._watchedDelegations = delegations.entities;

            // const ids = delegations.entities.map((d: Rest_task.Delegation) => d.id);

            this.watchedDelegations.next(that._watchedDelegations);
        });*/

        this.nextConnectionTrialDelay = this.nextConnectionTrialBaseDelay;
        this.init();
        let reconnectionPlaned = false;
        //Periodically check the connection status. If not connected and no reconnection is planed, plan a re-connection after nextConnectionTrialDelay
        timer(2000, 1000).subscribe((t) => {
            if (this.connectionStatus !== 'CONNECTED') {
                if (reconnectionPlaned) {
                    return;
                }
                reconnectionPlaned = true;
                this.connectionStatus = 'WAITING_TO_CONNECT';
                this.connectionStatusSubject.next(this.connectionStatus);
                this.nextConnectionTime = moment(new Date()).add(this.nextConnectionTrialDelay, 'ms').toDate();
                setTimeout(() => {
                    this.nextConnectionTrialDelay = this.nextConnectionTrialDelay * this.nextConnectionTrialDelayIncrementFactor;
                    this.socket = null;
                    this.init();
                    reconnectionPlaned = false;
                }, this.nextConnectionTrialDelay);
            }
        });
    }

    public detachMap() {
        //TODO: generic port
        //      const otherWindow = window.open('/index.html?map=' + encodeURIComponent(this.auth.token), 'window');
        const otherWindow = window.open('/map/rt-map=' + encodeURIComponent(this.auth.token), 'window');
        // const otherWindow = window.open('/map=' + encodeURIComponent(this.auth.token), 'window');

        this.isMapDetached = true;
        this.isMaster = true;
        //Set the current watched vehicles to local storage
        this.localSt.store('WatchedVehicles', JSON.stringify(this._watchedVehicles));
        //Whenever watched vehicles change, update local storage
        const watchedVehiclesLSUpdateSubs = this.watchedVehicles.subscribe((wv: RestExt.WatchedVehicle[]) => {
            this.localSt.store('WatchedVehicles', JSON.stringify(wv));
        });

        //Whenever a new message is put in the mapInbox, update local storage
        const mapInboxSubs = this.mapInbox.subscribe((wv: MapMessage) => {
            this.localSt.store('mapInbox', JSON.stringify(wv));
        });

        const mapOutboxSubs = this.localSt.observe('mapOutbox').subscribe((value) => this.mapOutbox.next(JSON.parse(value)));

        const that = this;
        console.log('otherWindow.addEventListener(beforeunload)');

        otherWindow.addEventListener('beforeunload', function (event) {
            console.log('Map view closed');

            watchedVehiclesLSUpdateSubs.unsubscribe();
            mapInboxSubs.unsubscribe();
            mapOutboxSubs.unsubscribe();
            that.isMapDetached = false;
            event.preventDefault(); // for first type of browsers
            return event.returnValue = 'test'; // for second type of browsers            
        });


        window.addEventListener('beforeunload', function (event) {
            console.log(' window.addEventListener(beforeunload, function (event) ');
            otherWindow.close();
        });

        /*
        otherWindow.onbeforeunload = (event) => {          
            console.log('Map view closed');
            event.returnValue = 'beforeunload'; 

            watchedVehiclesLSUpdateSubs.unsubscribe();
            mapInboxSubs.unsubscribe();
            mapOutboxSubs.unsubscribe();
            that.isMapDetached = false; }; */
    }

    /**
     *
     * @param vids Instruct the map to focus on the given vehicle ids
     */
    public focusMap(vids: number[]) {
        this.mapInbox.next({ tag: 'focus', payload: vids } as MapMessage);
    }

    /**
     *
     * @param vid Instruct the map to show a vehicle route
     * @param segments
     * @param focus
     */
    public showRoute(vid: number, segments: Rest.Vertex[][], focus: boolean) {
        this.mapInbox.next({
            tag: 'showRoute',
            payload: { vid, segments, focus },
        } as MapMessage);
    }

    /**
     *
     * @param vid Instruct the map to hide a vehicle route
     */
    public hideRoute(vid: number) {
        this.mapInbox.next({ tag: 'hideRoute', payload: vid } as MapMessage);
    }

    public initDetachedMode() {
        this.isMapDetached = true;
        this.isMaster = false;
        //Read initial WatchedVehicles from storage
        this.watchedVehicles.next(JSON.parse(this.localSt.retrieve('WatchedVehicles')));
        //Subscribe to changes in WatchedVehicles
        this.localSt.observe('WatchedVehicles').subscribe((value) => this.watchedVehicles.next(JSON.parse(value)));
        //Whenever a new message is put in localStorage mapInbox, send it to the mapInbox
        this.localSt.observe('mapInbox').subscribe((value) => this.mapInbox.next(JSON.parse(value)));
        //Whenever a new message is put at mapOutbox, send it to the local storage
        this.mapOutbox.subscribe((value) => this.localSt.store('mapOutbox', JSON.stringify(value)));
    }

    public reatachMap() {
        this.isMapDetached = false;
        this.isMaster = true;
    }

    private create(url): Subject<string> {
        if (this.ws != null) {
            this.ws.close();
        }

        this.ws = new WebSocket(url);
        if (this.ws.readyState !== this.ws.CONNECTING) {
            this.connectionStatus = 'NOT_CONNECTED';
            this.connectionStatusSubject.next(this.connectionStatus);
            this.socket = null;
        }
        const observable = Observable.create((obs: Observer<string>) => {
            this.ws.onmessage = obs.next.bind(obs);
            this.ws.onerror = obs.error.bind(obs);
            this.ws.onclose = obs.complete.bind(obs);
            return this.ws.close.bind(this.ws);
        });
        const observer = {
            next: (data: Object) => {
                if (this.ws.readyState === WebSocket.OPEN) {
                    this.ws.send(data.toString());
                } else {
                    console.error('socket is not ready to send ' + data);
                    this.init();
                }
            },
            complete: () => {
                //TODO: socket closed, show message to user and retry connection
                console.log('Socket closed!');
                this.connectionStatus = 'NOT_CONNECTED';
                this.connectionStatusSubject.next(this.connectionStatus);
                this.socket = null;
            },
            onerror: () => {
                //TODO: handle socket error
                console.log('Socket error!');
                this.connectionStatus = 'NOT_CONNECTED';
                this.connectionStatusSubject.next(this.connectionStatus);
                this.socket = null;
            },
        };
        return AsyncSubject.create(observer, observable);
    }

    private init() {
        this.connectionStatus = 'CONNECTING';
        this.connectionStatusSubject.next(this.connectionStatus);
        this.lastKeepAliveMessageRecieved = new Date();

        if (this.keepAliveMessageSendSubscription != null) {
            this.keepAliveMessageSendSubscription.unsubscribe();
        }
        const that = this;
        this.keepAliveMessageSendSubscription = timer(2000, this.keepAliveSendInterval).subscribe((t) => {
            if (this.connectionStatus === 'CONNECTED') {
                this.socket.next('ping:');
            }
        });

        if (this.socketStatusCheckSubscription != null) {
            this.socketStatusCheckSubscription.unsubscribe();
        }
        this.socketStatusCheckSubscription = timer(2000, this.maxKeepAliveDelay).subscribe((t) => {
            if (
                this.connectionStatus === 'CONNECTED' &&
                moment(this.lastKeepAliveMessageRecieved).add(this.maxKeepAliveDelay, 'ms').isBefore(new Date())
            ) {
                //Socket is connected but last keep alive message is older than maxKeepAliveDelay. Disconnect and re-connect.
                that.connectionStatus = 'NOT_CONNECTED';
                that.connectionStatusSubject.next(that.connectionStatus);
                this.socket = null;
                this.init();
            }
        });

        //Socket is zombie

        if (this.ws == null || (this.ws != null && this.ws.readyState !== 1)) {
            this.socket = null;
        }

        if (this.socket == null) {
            //TODO: extract url and port to properties file
            this.socket = this.create(environment.RTSocketURL);
        }

        if (this.socket != null) {
            this.subscribeToSocket();

            this.ws.addEventListener('open', function () {
                that.connectionStatus = 'CONNECTED';
                that.connectionStatusSubject.next(that.connectionStatus);
                that.nextConnectionTrialDelay = that.nextConnectionTrialBaseDelay; //Reset the connection delay
                that.login();
                //When connection is lost and we can re-connect, resume the session (re-send all subscriptions)
                if (!that.isFirstConnection) {
                    that.resumeSession();
                } else {
                    that.isFirstConnection = true;
                }
            });
        }
    }

    private resumeSession() {
        console.debug('resuming session');
        //Re-send visible vehicles
        //this.socket.next("subscriptions:" + JSON.stringify(this._watchedVehicles.map((v: Rest.Vehicle) => { return { "clientId": v.client.id, "vehicleId": v.id, }; })));
        //this.socket.next('subscriptions:' + JSON.stringify(this._watchedVehicles.filter(x => x.id < 10000).map((v: Rest.Vehicle) => ({ vehicleId: v.id }))));
        this.socket.next('subscriptions:' + JSON.stringify(this._watchedVehicles.map((v: Rest.Vehicle) => ({ id: v.id }))) + '|delegations:' + JSON.stringify(this._watchedDelegations.map((d: Rest_task.Delegation) => ({ delegationId: d.id }))));
        //Re-send watched responses
        this.watchedResponses.forEach((r: string) => {
            this.socket.next('watch_request_response:' + r);
        });
    }

    /**
     * Send login data
     */
    private login() {
        this.userSubscription = this.auth.userObservable.subscribe((user) => {
            if (user) {
                this.socket.next('login:' + user.id + '|' + this.auth.token);               
            }
        });
    }


    /**
     * Assing the color property to the vehicleInfo object based on the used sensorChart settings and the value of the device sensors.
     * @param vinfo
     */
    private computeDerivatedValues(vinfo: RestExt.ExtendedVehicleDataMessage) {
        const vehicle = this._watchedVehicles.find((v: Rest.Vehicle) => v.id === vinfo.vehicleId);
        vinfo.vehicleName = vehicle != null ? vehicle.name : '?';
        vinfo.groupName = vehicle != null && vehicle.group != null ? vehicle.group.name : '?';
        vinfo.fleetName = vehicle != null && vehicle.group != null && vehicle.group.fleet != null ? vehicle.group.fleet.name : '?';

        vinfo.icon =
            vehicle != null && vehicle.group != null && vehicle.group.icon != null
                ? vehicle.group.icon.imageEncoded
                : null;
        //TODO: warrada
        (vinfo as any).location = {
            longitude: (vinfo as any).longitude,
            latitude: (vinfo as any).latitude,
        };

        //Adapt time to user time
        //vinfo.deviceTime = this.userTime.toUserTime(vinfo.deviceTime);

        let matchingSensors = null;
        const defaultStateColorIgnitionOff = "#FF0000";
        const defaultStateColorIgnitionOn = "#008000";
        const defaultStateUnknown = "#c7d1d3";

        if (
            this.sensorChartSettings == null ||
            this.sensorChartSettings.sensorTag == null ||
            this.sensorChartSettings.filterType == null
        ) {
            // no settings available we have to check IGNITION
            if (vinfo.sensors != null) {
                const sensor = vinfo.sensors.find(s => s.tag === "IGNITION");

                //if vehicle has sensor IGNITION and is true
                if (sensor && sensor.rawValue) {
                    vinfo.color = defaultStateColorIgnitionOn; //green color
                } else {
                    vinfo.color = defaultStateColorIgnitionOff; // red color
                }
                return;

            }

            vinfo.color = defaultStateColorIgnitionOff;
            return;
        }


        if (this.sensorChartSettings.filterType === 'SENSOR') {
            matchingSensors = vinfo.sensors.filter((s: RestExt.ExtendedDValue) => s.device.tag === this.sensorChartSettings.sensorTag);
            if (matchingSensors.length === 0) {
                vinfo.color = defaultStateUnknown;
                return;
            }
        }
        if (this.sensorChartSettings.filterType === 'ACTUATOR') {
            matchingSensors = vinfo.actuators.filter((s: RestExt.ExtendedDValue) => s.device.tag === this.sensorChartSettings.sensorTag);
            if (matchingSensors.length === 0) {
                vinfo.color = defaultStateUnknown;
                return;
            }
        }
        if (this.sensorChartSettings.filterType === 'ALL') {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const s = vinfo.sensors.filter((s: RestExt.ExtendedDValue) => s.device.tag === this.sensorChartSettings.sensorTag);
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const a = vinfo.actuators.filter((s: RestExt.ExtendedDValue) => s.device.tag === this.sensorChartSettings.sensorTag);
            matchingSensors = s.concat(a);
            if (matchingSensors.length === 0) {
                vinfo.color = defaultStateUnknown;
                return;
            }
        }

        vinfo.category_idx = -1;
        //Use the first matching sensor
        const sensor = matchingSensors[0] as RestExt.ExtendedDValue;

        if (this.sensorChartSettings.showCategoricalValue) {
            if (sensor.categoricValue == null) {
                return;
            }
            vinfo.color = this.sensorChartSettings.categoricalValuesMappings[sensor.categoricValue].color;
            const options: string[] = [];
            // eslint-disable-next-line guard-for-in
            for (const i in this.sensorChartSettings.categoricalValuesMappings) {
                options.push(i);
            }
            vinfo.category_idx = options.indexOf(sensor.categoricValue);
        } else {
            const numericalMappings = this.sensorChartSettings.numericalValuesColors as any;
            vinfo.color = defaultStateUnknown;
            if (sensor.numericValue == null) {
                return;
            }
            if (numericalMappings.length !== 0) {
                for (const i in numericalMappings) {
                    //Is the sensor value is smaller than the maping max value
                    if (numericalMappings[i].key >= sensor.numericValue) {
                        vinfo.color = numericalMappings[i].value;
                        const options: string[] = [];
                        // eslint-disable-next-line guard-for-in, @typescript-eslint/no-shadow
                        for (const i in numericalMappings) {
                            options.push(i);
                        }
                        vinfo.category_idx = options.indexOf(i);
                        return;
                    }
                }
            }
        }
    }

    public setSensorChartSettings(sensorChartSettings: Rest.SensorChartSettings) {
        this.sensorChartSettings = {} as Rest.SensorChartSettings;
        Object.assign(this.sensorChartSettings, sensorChartSettings);
        if (this.sensorChartSettings == null) {
            return;
        }

        //Sort the numerical mappings
        if (!this.sensorChartSettings.showCategoricalValue && this.sensorChartSettings.numericalValuesColors) {
            const numericalMappings: Array<RestExt.Pair<number, string>> = [];
            // eslint-disable-next-line guard-for-in
            for (const key in this.sensorChartSettings.numericalValuesColors) {
                numericalMappings.push({
                    key: Number(key),
                    value: this.sensorChartSettings.numericalValuesColors[key],
                });
            }
            //sort
            numericalMappings.sort((a: RestExt.Pair<number, string>, b: RestExt.Pair<number, string>) => a.key - b.key);
            (this.sensorChartSettings as any).numericalValuesColors = numericalMappings;
        }
        //Recompute values
        this.vehicleInfo.forEach((vi: RestExt.ExtendedVehicleDataMessage) => {
            this.computeDerivatedValues(vi);
        });
        this.settingsChange.next(this.sensorChartSettings);
    }

    private handleIncomingVehicleInfoMessage(data: string) {
        const vehicleInfo = JQueryHTTPClient.objectWithDateStringToDate(
            JSON.parse(data.replace('vehicleinfo:', '').replace(/\bNaN\b/g, "null")),
        ) as RestExt.ExtendedVehicleDataMessage;
        //Find the associated vehicle
        const vehicle = this._watchedVehicles.find((i: Rest.Vehicle) => i.id === vehicleInfo.vehicleId);
        if (vehicle == null) {
            console.log('Vehicle not found!');
            return;
        }

        //transform DValue to ExtendedDvalue for sensors and actuators.
        //TODO: this code assigns maps the declared device sensors/actuators with the incoming data. It is only for test pourposes and should be done checking the id
        const finalSensors: RestExt.ExtendedDValue[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < vehicleInfo.sensors.length; i++) {
            (vehicleInfo.sensors[i] as RestExt.ExtendedDValue).device = vehicle.device.configuration.parameters.sensors.find(
                (s) => s.id === vehicleInfo.sensors[i].dID,
            );

            finalSensors.push(vehicleInfo.sensors[i] as RestExt.ExtendedDValue);
        }
        // @ts-ignore
        vehicleInfo.sensors = finalSensors;

        const finalActuators: RestExt.ExtendedDValue[] = [];
        if (vehicleInfo.actuators) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < vehicleInfo.actuators.length; i++) {
                (vehicleInfo.actuators[i] as RestExt.ExtendedDValue).device = vehicle.device.configuration.parameters.actuators.find(
                    (s) => s.id === vehicleInfo.actuators[i].dID,
                );
                finalActuators.push(vehicleInfo.actuators[i] as RestExt.ExtendedDValue);
            }
        }
        // @ts-ignore
        vehicleInfo.actuators = finalActuators;

        //Keep the vehicleInfoArr up to date
        //If an object of vehicle infor for this vehicle was already present, update id
        let catchedVehicleInfo = this.vehicleInfoArr.find((i: RestExt.ExtendedVehicleDataMessage) => i.vehicleId === vehicleInfo.vehicleId);
        if (catchedVehicleInfo != null) {
            vehicleInfo.sensors.forEach((sv: RestExt.ExtendedDValue) => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const csv = catchedVehicleInfo.sensors.find((csv: RestExt.ExtendedDValue) => csv.dID === sv.dID) as RestExt.ExtendedDValue;
                if (csv != null) {
                    csv.categoricValue = sv.categoricValue;
                    csv.numericValue = sv.numericValue;
                    csv.rawValue = sv.rawValue;
                    Object.assign(csv, csv);
                } else {
                    // @ts-ignore
                    catchedVehicleInfo.sensors.push(sv);
                }
                //TODO: handle sensor disappeared
            });
            vehicleInfo.actuators.forEach((sv: RestExt.ExtendedDValue) => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const csv = catchedVehicleInfo.actuators.find((csv: RestExt.ExtendedDValue) => csv.dID === sv.dID) as RestExt.ExtendedDValue;
                if (csv != null) {
                    csv.categoricValue = sv.categoricValue;
                    csv.numericValue = sv.numericValue;
                    csv.rawValue = sv.rawValue;
                    Object.assign(csv, csv);
                } else {
                    // @ts-ignore
                    catchedVehicleInfo.actuators.push(sv);
                }
                //TODO: handle actuator disappeared
            });

            catchedVehicleInfo.deviceTime = vehicleInfo.deviceTime;
            catchedVehicleInfo.icon = vehicleInfo.icon;
            catchedVehicleInfo.satelite = vehicleInfo.satelite;
            catchedVehicleInfo.location = vehicleInfo.location;
            catchedVehicleInfo.latitude = vehicleInfo.latitude;
            catchedVehicleInfo.longitude = vehicleInfo.longitude;
            catchedVehicleInfo.speed = vehicleInfo.speed;
            catchedVehicleInfo.platformTime = vehicleInfo.platformTime;
            catchedVehicleInfo.lastGPSDateTime = vehicleInfo.lastGPSDateTime;
            catchedVehicleInfo.heading = vehicleInfo.heading;
            catchedVehicleInfo.altitude = vehicleInfo.altitude;
            catchedVehicleInfo.maxSpeed = vehicleInfo.maxSpeed;
            catchedVehicleInfo.deviceMileage = vehicleInfo.deviceMileage;
            catchedVehicleInfo.vehicleMileage = vehicleInfo.vehicleMileage;
            catchedVehicleInfo.deviceMileage = vehicleInfo.deviceMileage;

            //Object.assign(catchedVehicleInfo, vehicleInfo);

            this.computeDerivatedValues(catchedVehicleInfo);
        } //If no VehicleInfo was available, create a new one
        else {
            this.computeDerivatedValues(vehicleInfo);
            this.vehicleInfoArr.push(vehicleInfo);
            catchedVehicleInfo = vehicleInfo;
        }
        //Notify the change to subscribers
        this.vehicleInfo.next(catchedVehicleInfo);
        return;
    }

    private handleNrIncidence(incidence: any) {
        const e = JQueryHTTPClient.objectWithDateStringToDate(JSON.parse(incidence.replace('incidenceinfo:', ''))) as Rest_logistics.Order;
        this.notReadedIncidents.next(e);
        return;
    }

    private handleRequestResponseMessage(data: string) {
        const response = JQueryHTTPClient.objectWithDateStringToDate(JSON.parse(data.replace('response:', ''))) as RestExt.ExtendedResponse;
        const vehicleInfo = this.vehicleInfoArr.find((i: RestExt.ExtendedVehicleDataMessage) => i.vehicleId === response.vehicleId);
        if (vehicleInfo == null) {
            console.log('Unknown vehicle ' + vehicleInfo);
        }
        response.vehicle_name = vehicleInfo != null ? vehicleInfo.vehicleName : '';
        //Remove it from the watched list
        const idx = this.watchedResponses.indexOf(response.originalRequest.id + ':' + response.vehicleId);
        if (idx >= 0) {
            this.watchedResponses.splice(idx, 1);
        }

        //Notify the message to subscribers
        this.requestResponse.next(response);
        return;
    }

    private handleEvent(data: string) {
        const e = JQueryHTTPClient.objectWithDateStringToDate(JSON.parse(data.replace('event:', ''))) as Rest.Event;
        this.event.next(e);
        return;
    }

    private subscribeToSocket() {
        if (this.socketSubscription != null) {
            this.socketSubscription.unsubscribe();
        }
        this.socket.asObservable().subscribe(
            (message: string) => {
                const data: string = (message as any).data;

                try {
                    if (data.startsWith('pong:')) {
                        this.handleKeepAliveMessage();
                        return;
                    }
                    if (data.startsWith('vehicleinfo:')) {
                        this.handleIncomingVehicleInfoMessage(data);
                        return;
                    }

                    if (data.startsWith('response:')) {
                        this.handleRequestResponseMessage(data);
                        return;
                    }
                    if (data.startsWith('notification:')) {
                        this.handleNotificationMessage(data);
                        return;
                    }
                    if (data.startsWith('event:')) {
                        this.handleEvent(data);
                        return;
                    }
                    if (data.includes('incidenceinfo:')) {
                        this.handleNrIncidence(data);
                        return;
                    }
                    else {
                        console.log('Unknown message ' + message);
                    }
                } catch (ex) {
                    console.log(ex);
                }
            },
            (err: any) => {
                console.log('socket error ' + err);
            },
        );
    }

    private handleKeepAliveMessage() {
        this.lastKeepAliveMessageRecieved = new Date();
    }

    private handleNotificationMessage(notification: any) {
        notification = JSON.parse(notification.replace('notification:', ''));
        this.notificationService.add(Severity.info, notification.title, notification.description);
    }

    /**
     * Send a request to watch for a message response
     */
    public watchRequestResponse(requestId: number, vehicleId: number) {
        this.watchedResponses.push(requestId + ':' + vehicleId);
        this.socket.next('watch_request_response:' + requestId + ':' + vehicleId);
    }

    public getCurrentVehicleInfo(): RestExt.ExtendedVehicleDataMessage[] {
        return JSON.parse(JSON.stringify(this.vehicleInfoArr));
    }
}

export interface MapMessage {
    tag: string;
    payload: any;
}

export type ConnectionStatus = 'NOT_CONNECTED' | 'CONNECTED' | 'WAITING_TO_CONNECT' | 'CONNECTING';

export interface MapMessage {
    tag: string;
    payload: any;
}
