import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-backup-battery-control-form-x8',
  templateUrl: './backup-battery-control-form-x8.component.html',
  styleUrls: ['./backup-battery-control-form-x8.component.css'],
})
export class BackupBatteryControlFormX8Component extends ServerValidatedFormComponent implements OnInit {

  @ViewChild('ContentDiv') ContentDiv;
  @Input() backupBattery: Rest.BackupBatteryControlX8;
 
  

  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.backupBattery);
    }
  }


}
