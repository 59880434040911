<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Global parameters
          (AT + GTCFG)</span>
      </div>
    </div>
  </p-header>
  <!--   <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.speedEvent.enabled>-->
  <!-- <input type="text" [(ngModel)]="deviceParams.balh"> -->


  <div class="ui-g">



    <!-- <div [class.disableDIV]=!trackModeSwitch> -->


    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Device Working
                Status Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">
          <!-- Power Saving Mode -->
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Power Saving Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="powerSaving" [(ngModel)]="globalParameters.powerSaving" [style]="{'width':'100%','min-width':'100px'}">
              </p-dropdown>
            </div>
          </div>

          <!-- Power Mode -->
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Power Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="powerOptions" [(ngModel)]="globalParameters.powerMode" [style]="{'width':'100%','min-width':'100px'}">
              </p-dropdown>
            </div>
          </div>

        </div>

        <div class="row align-items-center">
          <!--LED On-->
          <div class="col row dvcFields">
            <form-input fieldName="LED On">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="LedOptions" [(ngModel)]="globalParameters.ledON" [style]="{'width':'100%','min-width':'100px'}">
            </p-dropdown>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mask
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="ui-g">
          <form-input fieldName="Report Composition Mask"></form-input>

          <div class="ui-g">
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="Speed" [(ngModel)]="globalParameters.speed">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="Azimuth" [(ngModel)]="globalParameters.azimuth">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="Altitude" [(ngModel)]="globalParameters.altitude">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="GSM tower data" [(ngModel)]="globalParameters.gsmTowerData">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="Mileage" [(ngModel)]="globalParameters.mileage">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="Send Time" [(ngModel)]="globalParameters.sendTime">
                </p-checkbox>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="Device Name" [(ngModel)]="globalParameters.deviceName">
                </p-checkbox>
              </div>
            </div>

          </div>


        </div>
        <div class="ui-g">
          <form-input fieldName="Event Mask"></form-input>

          <div class="ui-g">
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTPNA" [(ngModel)]="globalParameters.eventMaskRESPGTPNA">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTBPF" [(ngModel)]="globalParameters.eventMaskRESPGTBPF">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox *ngIf="firmware > 5" binary="true" label="+RESP:GTCID"
                  [(ngModel)]="globalParameters.eventMaskRESPGTCID"></p-checkbox>
              </div>



            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTPFA" [(ngModel)]="globalParameters.eventMaskRESPGTPFA">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTBPL" [(ngModel)]="globalParameters.eventMaskRESPGTBPL">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" binary="true" label="+RESP:GTPDP"
                  [(ngModel)]="globalParameters.eventMaskRESPGTPDP">
                </p-checkbox>
              </div>



            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTMPN" [(ngModel)]="globalParameters.eventMaskRESPGTMPN">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTBTC" [(ngModel)]="globalParameters.eventMaskRESPGTBTC">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTRTL"
                  [(ngModel)]="globalParameters.eventMaskPowerONRESPGTRTL"></p-checkbox>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTMPF" [(ngModel)]="globalParameters.eventMaskRESPGTMPF">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTSTC" [(ngModel)]="globalParameters.eventMaskRESPGTSTC">
                </p-checkbox>
              </div>
              <div *ngIf="firmware > 5" class="col">
                <p-checkbox binary="true" label="+RESP:GTIGN / GTIGF / GTVGN / GTVGF"
                  [(ngModel)]="globalParameters.eventMaskIgnitionReportRESPGTIGNRESPGTIGFRESPGTVGNRESPGTVGF"></p-checkbox>
              </div>
              <div *ngIf="5 >= firmware" class="col">
                <p-checkbox binary="true" label="+RESP:GTIGN / GTIGF"
                  [(ngModel)]="globalParameters.eventMaskIgnitionReportRESPGTIGNRESPGTIGFRESPGTVGNRESPGTVGF"></p-checkbox>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col">
                <p-checkbox binary="true" label="+RESP:GTBPN" [(ngModel)]="globalParameters.eventMaskRESPGTBPN">
                </p-checkbox>
              </div>
              <div class="col">
                <p-checkbox binary="true" binary="true" label="+RESP:GTSTT"
                  [(ngModel)]="globalParameters.eventMaskRESPGTSTT">
                </p-checkbox>
              </div>
              <div *ngIf="firmware > 5" class="col">
                <p-checkbox binary="true" label="+RESP:GTIGL / GTVGL"
                  [(ngModel)]="globalParameters.eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL"></p-checkbox>
              </div>
              <div *ngIf="5 >= firmware" class="col">
                <p-checkbox binary="true" label="+RESP:GTIGL"
                  [(ngModel)]="globalParameters.eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL"></p-checkbox>
              </div>

            </div>
          </div>
        </div>
        
        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="GSM Report">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="cellOptions" [(ngModel)]="globalParameters.cellInfoReport"
              [style]="{'width':'100%'}">
            </p-dropdown>
          </div>
        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Other Function
                Settings</span>
            </div>
          </div>
        </p-header>
        <div class="row mt-3">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Info Report Enable">
              </form-input>
            </div>
            <div class="col" [style]="{'width':'200px'}">
              <p-inputSwitch [(ngModel)]="globalParameters.infoReportEnable"></p-inputSwitch>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Info Report Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="infoReportInterval" size="7" [(ngModel)]="globalParameters.infoReportInterval"
                  [min]="30" [max]="86400"></p-spinner>
                <span class="ui-inputgroup-addon">30~86400 </span>
                <span class="ui-inputgroup-addon">seconds</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Agps mode">
              </form-input>
            </div>
            <div class="col" [style]="{'width':'100%','min-width':'100px'}">
              <p-inputSwitch [(ngModel)]="globalParameters.agpsMode"></p-inputSwitch>
            </div>
          </div>

        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col lblStyle">
            <form-input fieldName="Location By Call">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="locationCallOptions" [(ngModel)]="globalParameters.locationCall"
            [style]="{'width':'100%','min-width':'100px'}">
            </p-dropdown>
          </div>
        </div>

      </p-accordionTab>
    </div>
  </div>
  <!-- </div> -->
</p-accordionTab>