import { Component, OnInit, ViewEncapsulation, Input,ViewChild } from '@angular/core';
import { Rest } from 'app/rest/rest_client';

@Component({
  selector: 'app-start-stop-report-information-form',
  templateUrl: './start-stop-report-information-form.component.html',
  styleUrls: ['./start-stop-report-information-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StartStopReportInformationFormComponent implements OnInit {

  @Input() StartStopReport: Rest.StartStopReport;
  
  @ViewChild('StartStopReportDiv', { static: true }) StartStopReportDiv;  

  
  constructor() { }

  ngOnInit() {
    if(this.StartStopReport!=null){this.disableAnalogInputDIV(!this.StartStopReport.startStopReportMode)}
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.StartStopReportDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.StartStopReportDiv.nativeElement.classList.remove('disableDIV');
    }
  }
 

}