import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import { MileageFormComponent } from '../mileage-form/mileage-form.component';
import { MileageService } from 'app/modules/vehicle-inventory/services/table-management/mileage.service';
import { formatDate } from '@angular/common';
import { MileageStatsService } from 'app/modules/vehicle-inventory/services/mileage-stats.service';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';

@Component({
    selector: 'app-mileage-list',
    templateUrl: './mileage-list.component.html',
    styleUrls: ['./mileage-list.component.css'],
    providers: [ConfirmationService, UserTimePipe, ExcelService, MileageStatsService],
})
export class MileageListComponent
    extends EntityListComponent<Rest.Mileage>
    implements OnInit {

    title: string = this.i18n._("Mileage");

    @ViewChild(MileageFormComponent, { static: true })
    editForm: MileageFormComponent;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    mileageDate: Date;

    @Input() vehicle: Rest.Vehicle;

    @Input() showTitle: boolean = true;

    // PERMISSIONS
    isCreateGranted: boolean = false;
    isFormGranted: boolean = false;
    isDisabledGranted: boolean = false;

	 updateListSubscription: Subscription;
    
     typeList: any = [
        {label: "AU",value:0},
        {label: "CO",value:1},
        {label: "HI",value:2},                     
        {label: "CM",value:3},  
      ];

    constructor(
        private objectService: MileageService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
		  protected mileageStatsService: MileageStatsService
    ) {
        super(
            objectService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );

        this.objectService = objectService;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.form = this.editForm;
        this.hasEntityFilters = false;

        this.setPermissions();

		  this.updateListSubscription = this.objectService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				this.getList(item.startDate, item.endDate);
			}
		);
    }

	 public getList(startDate?: Date, endDate?: Date) {
		this.beforeLoad();
		super.refresh();
	}

    beforeLoad() {
        this.paginationRequest.sortAsc = false;
        this.paginationRequest.sortBy = "readingDate";
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }

        this.paginationResult.entities.forEach((e: Rest.Mileage) => {
            e.mileageUpdateDate = new Date(e.lastManualKMUpdate);
        });

        this.paginationRequest.filters["vehicleId"] = [this.vehicle.id.toString()];
    }

    afterLoad() {
        this.objectService.getPage(this.paginationRequest).then((p) => {
            this.paginationResult = p;
        });

        this.paginationResult.entities.forEach((ct: any) => {
            ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
            ct._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "FinanceType"),
                ct.id
            );
            ct._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "FinanceType"),
                ct.id
            );
        });

        this.checkSelectAll();
    }

    setPermissions() {
        const user = this.authenticationService.user;
        const rolesForForm = ['INV_CONTROL_KM_READ', 'INV_CONTROL_KM_UPDATE'];

        this.isCreateGranted = this.authenticationService.isRoleGranted('INV_CONTROL_KM_CREATE', user);
        this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_CONTROL_KM_DISABLE', user);
        this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
    }

    onEditDialog(event) {
        if (!this.isFormGranted) {
            return;
        }

        if (event.type.toString().toUpperCase() === 'CO' || event.type.toString().toUpperCase() === 'HI') {
            super.editDialog(event);
        }
    }

	 onShowKmStats() {
		console.log("[MILEAGE-LIST] onShowKmStats ***");
		this.mileageStatsService.getKmStats.next();
	 }

    excelColumns = {};

    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.mileage.mileage');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }

    setExcelColumns() {
        const date = (entity: any) => {
            if (entity.readingDate === null || entity.readingDate === undefined) { return ''; }
            return formatDate(entity.readingDate, 'dd/MM/yyyy', 'es-ES');
        }

        const kms = (entity: any) => entity.kms ?? ''
        const type = (entity: any) => entity.type?.name ?? ''

        const consumptionRef = (entity: any) => {
            if (entity.consumption === null || entity.consumption === undefined) { return ''; }
            return entity.consumption?.consumptionRef;
        }

        const amount = (entity: any) => entity.consumption?.amount ?? ''
        const liters = (entity: any) => entity.consumption?.liters ?? ''
        const literCost = (entity: any) => entity.consumption?.literCost ?? ''

        this.excelColumns = {
            [this.translateService.instant('general.date')]: { bindField: date },
            [this.translateService.instant('tableManagement.mileage.mileage')]: { bindField: kms },
            [this.translateService.instant('general.type')]: { bindField: type },
            [this.translateService.instant('tableManagement.cardLists.card')]: { bindField: consumptionRef },
            [this.translateService.instant('tableManagement.fine.amount')]: { bindField: amount },
            [this.translateService.instant('tableManagement.cardsConsumption.litres')]: { bindField: liters },
            [this.translateService.instant('tableManagement.cardsConsumption.literCost')]: { bindField: literCost },
        }
    }
}
