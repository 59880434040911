import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { Rest } from 'app/rest/rest_client';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { saveAs } from 'file-saver';
@Injectable()
export class ExcelService {
constructor(private datePipe: DatePipe) { }
public exportAsExcelFile(json: any[], excelFileName: string, header?: string[]): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {header});
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}

private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + '_export_' +  this.datePipe.transform(new Date(), 'yyyyMMddHHmmss') + EXCEL_EXTENSION);
}

  //HTML TABLE TO EXCEL
  public exportTableAsExcelFile(element: HTMLElement, excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

    //HTML TABLE TO EXCEL
  public exportTableAsExcelFileV2(element: HTMLElement, excelFileName: string, tableData: any,  columns: String[], tableDataExcel?: any[]): void {
    //const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableDataExcel, {});

    for (let i = 0; i < tableData.length; i++) {
      for (const key in tableData[i]) {
        if (tableData[i].hasOwnProperty(key)) {
          const element = tableData[i][key];
          const cell = ws[XLSX.utils.encode_cell({ r: i + 1, c: columns.indexOf(element.field) })];
          const status = element.status;
          if (status) {
            if (cell) {
              if (!cell.s) {
                cell.s = {}; // Initialize 's' property if it doesn't exist
              }
              cell.s = { fill: { fgColor: { rgb: this.getStatusColor(status) } } }; // Set cell color
            } else {
              // Handle the case where 'cell' is undefined
              // For example, initialize 'cell' or log an error
            }
          }
        }
      }
    }

    const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  //HTML TABLE TO EXCEL WITH ROWS
  public exportTableAsExcelFileNumRows(element: HTMLElement, excelFileName: string, numRows: number): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true, sheetRows: numRows });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportToExcel(data: Rest.ReportResponseItem[]): void {
    const headers: string[] = this.extractHeaders(data);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers]);

    // Populate data
    data.forEach((row, rowIndex) => {
      headers.forEach((header, colIndex) => {
        const cellData = row[header];
        const value = cellData?.value || '';
        const status = cellData?.status || '';
        const cell = ws[XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex })];
        if (cell) {
          cell.v = value; // Set cell value
          if (status) {
            cell.s = { fill: { fgColor: { rgb: this.getStatusColor(status) } } }; // Set cell color
          }
        }
      });
    });

    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'exported_data');
  }

  extractHeaders(data: any[]): string[] {
    const headersSet: Set<string> = new Set();
    data.forEach(row => {
      Object.keys(row).forEach(key => {
        headersSet.add(key);
      });
    });
    return Array.from(headersSet);
  }

  getStatusColor(status: string): string {
    // Define color mappings based on status
    const colorMap: { [key: string]: string } = {
      'yellow': 'FFFF00',
      // Add more color mappings as needed
    };
    return colorMap[status] || 'FFFFFF'; // Default to white if status not found
  }
 

private isColor(str: string): boolean {
  // Check if the string represents a color (you can customize this check based on your requirements)
  return /^#[0-9A-F]{6}$/i.test(str);
}

private hexToRgb(hex: string): string {
  // Convert hex color to RGB format
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r},${g},${b}`;
}
}