import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { DriverpointsService } from 'app/modules/vehicle-inventory/services/driverpoints.service';
import { Rest } from 'app/rest/rest_client';
import { UserService } from 'app/rest/user.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';

@Component({
  selector: 'app-doc-driver-points',
  templateUrl: './doc-driver-points.component.html',
  styleUrls: ['./doc-driver-points.component.css'],
  providers: [DatePipe]

})
export class DocDriverPointsComponent extends EntityFormComponent<Rest.InvDriverPoints> implements OnInit {

	@Input() isNew: boolean = false;
   @Input() driverPoints: Rest.InvDriverPoints = null;
	@Input() isEditingDisabled?: boolean = false;
	//@Input() errorMessages?: string[];

   @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
   constructor(
    private driverPointsService: DriverpointsService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public userService: UserService,
	 private datePipe: DatePipe
	) {
   	super(driverPointsService, notificationsService, i18n, authenticationService);
	}

   ngOnInit(): void {
		console.log("[DOC-DRIVER-POINTS] ngOnInit ***");
		console.log(this.isNew);
		console.log(this.driverPoints);
      super.ngOnInit();
   }

	create(document): void {
		console.log("[DOC-DRIVER-POINTS] create ***");
		console.log(this.driverPoints);
		console.log(document);
		var that = this;
		let data = {
			id: 0,
			document: { "id": document.id },
			driver: { "id": this.driverPoints.driver.id },
			infoUser: { "id": this.authenticationService.user.id },
			infoDate: this.datePipe.transform(this.driverPoints.infoDate, 'yyyy-MM-dd') + "T00:00:00.000Z",
			points: this.driverPoints.points
		}
		// let data = {
		// 	id: 0,
		// 	document: document,
		// 	driver: this.driverPoints.driver,
		// 	infoUser: this.authenticationService.user,
		// 	infoDate: this.datePipe.transform(this.driverPoints.infoDate, 'yyyy-MM-dd') + "T00:00:00.000Z",
		// 	points: this.driverPoints.points
		// }
		let dataString = JSON.stringify(data);
		console.log(dataString);
  		this.driverPointsService.createDriverPoints(dataString).then((response) => {
      	that.notificationsService.add(Severity.success, "", this.translateService.instant('general.createSuccessfull'));
       		that.closeDialog.emit();
     	}).catch((error) => {
        	that.notificationsService.add(Severity.error, "", this.translateService.instant('general.createError'));
     	});
	}

  	update(): void {
		console.log("[DOC-DRIVER-POINTS] update ***");
		console.log(this.driverPoints);
		var that = this;
		let dataString = JSON.stringify(this.driverPoints);
 		this.driverPointsService.updateDriverPoints(dataString).then((response) => {
			that.notificationsService.add(Severity.success, "", this.translateService.instant('general.updateSuccessfull'));
			that.closeDialog.emit();
		}).catch((error) => {
			that.notificationsService.add(Severity.error, "", this.translateService.instant('general.updateError'));
 		});
	}

	validateDriverPoints() {
		console.log("[DOC-FORM] validateDriverPoints *** ");
		console.log(this.driverPoints);
		if (!this.driverPoints.points || !this.driverPoints.infoDate)  {
			return false;
		} else {
			return true;
		}
	}

}
