<!-- MINIATURA -->
<!-- <img class="thumbnailImage" alt='' [src]="imageSrc" [style.width.px]='thumbnailWidth'
  [style.height.px]='thumbnailHeight' (click)="showExpandableImage()">  -->
  <img class="thumbnailImage" alt='' [src]="imageSrc" style="max-width: 40px;"
   (click)="showExpandableImage()"> 
<!-- IMAGEN AMPLIADA -->
<!--<div class="childDialog"> -->
  <p-dialog id="childDialog" [contentStyleClass]="'fullScreenDialog'" [baseZIndex]='1001' showHeader="false"
    [responsive]="true" [closable]="true" [maximizable]="true" (onMaximize)="resizeDialog($event);"
    [(visible)]="displayExpandedImage" [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true"
    [draggable]="true" class="dialogimage">
    <div style="text-align:center;height:auto;">
      <div #container class="col-5" style="width: auto; height: auto; overflow : hidden; position: relative ">
        <img #img style="width:100%; transition: all .3s;" [src]='imageSrc' alt="" />
      </div>
    </div>
  </p-dialog> 
<!-- </div>   -->
