<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >INSTRUCTION PASSWORD CONFIGURATION (PWDSW)</span>
        </div>
        <div class="right">
          <p-inputSwitch [(ngModel)]="instructionPassword.enabled"></p-inputSwitch>
        </div>
      </div>
    </p-header>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input  fieldName="Password" >
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="text" pInputText [(ngModel)]="instructionPassword.password" maxlength="19" minlength="1" class="txtbStyle">
        </div>
      </div>
    </div> 
  </p-accordionTab>
  