<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SENSOR SET CONFIGURATION
          (SENSORSET)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Detection time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sensorSet.detectionTime" [min]="1" [max]="300"></p-spinner>
          <span class="ui-inputgroup-addon">1~300</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Vibration times to activate gps">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sensorSet.vibrationTimeGps" [min]="1" [max]="20"></p-spinner>
          <span class="ui-inputgroup-addon">1~20</span>
        </div>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Vibration times to vibration Alarm">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sensorSet.vibrationTimeAlarm" [min]="1" [max]="20"></p-spinner>
          <span class="ui-inputgroup-addon">1~20</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Working time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sensorSet.workingTime" [min]="1" [max]="3"></p-spinner>
          <span class="ui-inputgroup-addon">1~3</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>
    
  </div>
</p-accordionTab>