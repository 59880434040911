import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class FleetService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.Fleet): Rest.RestResponse<Rest.Fleet> {
return this.client.Fleet_create(arg0);
}

update(arg0: Rest.Fleet): Rest.RestResponse<Rest.Fleet> {
return this.client.Fleet_update(arg0);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Fleet>> {
return this.client.Fleet_getPage(arg0);
}

changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.Fleet_changeStatus(arg0, queryParams);
}

getTree(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Fleet>> {
return this.client.Fleet_getTree(arg0);
}

getTreeFleets(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Fleet>>{
  return this.client.Fleet_getTreeFleets(arg0);
}

getInventoryTree(): Rest.RestResponse<Rest.Fleet[]>{
	return this.client.Fleet_getInventoryTree();
 }

findFleet(fleet: string, queryParams?: { fleet?: number; }): Rest.RestResponse<Rest.Fleet> {
return this.client.Fleet_findFleet(fleet, queryParams);
}
}
