<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Fixed Report
                    Information (AT + GTFRI)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Basic Settings</span>
                        </div>
                    </div>
                </p-header>

                <!-- <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-dropdown [options]="modeOptions" [(ngModel)]="friParameters.mode"
                                [style]="{'width':'10px'}"></p-dropdown>
                        </div>
                    </div>
                </div> -->

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-dropdown [options]="modeOptions" [(ngModel)]="friParameters.mode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Check Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.checkInterval" [min]="1"
                                    [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">1~86400</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Send Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.sendInterval" [min]="1"
                                    [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">1~86400</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Ignition Check Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.ignitionCheckInterval" [min]="1"
                                    [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">1~86400</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Ignition Send Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.ignitionSendInterval" [min]="1"
                                    [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">1~86400</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Begin Time">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" maxlength="4" pInputText [(ngModel)]="friParameters.startTime"
                                    class="txtbStyle">
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="End Time">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" maxlength="4" pInputText [(ngModel)]="friParameters.endTime"
                                    class="txtbStyle" />
                            </div>
                        </div>
                    </div>

                </div>
            </p-accordionTab>


        </div>
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Mileage Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Distance">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.distance" [min]="50"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">50~65535</span>
                                <span class="ui-inputgroup-addon">m</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Mileage">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.mileage" [min]="50"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">50~65535</span>
                                <span class="ui-inputgroup-addon">m</span>
                            </div>
                        </div>
                    </div>

                </div>
            </p-accordionTab>
        </div>
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Other Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col">
                        <p-checkbox binary="true" label="Discard no Fix" [(ngModel)]="friParameters.discardNoFix">
                        </p-checkbox>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Movement Detection Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="movementDetectionOptions"
                                [(ngModel)]="friParameters.movementDetectionMode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Movement Speed">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.movementSpeed" [min]="1"
                                    [max]="999"></p-spinner>
                                <span class="ui-inputgroup-addon">1~999</span>
                                <span class="ui-inputgroup-addon">km/h</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Movement Distance">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.movementDistance" [min]="1"
                                    [max]="9999"></p-spinner>
                                <span class="ui-inputgroup-addon">1~9999</span>
                                <span class="ui-inputgroup-addon">m</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Movement Send Number">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.movementSendNumber" [min]="1"
                                    [max]="5"></p-spinner>
                                <span class="ui-inputgroup-addon">1~5</span>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Corner">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="friParameters.corner" [min]="0"
                                    [max]="180"></p-spinner>
                                <span class="ui-inputgroup-addon">0~180</span>
                                <span class="ui-inputgroup-addon">degree</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="ui-g">
                    <form-input fieldName="ERI Mask"></form-input>

                    <div class="row align-items-center">

                        <div class="col">
                            <p-checkbox binary="true" label="Extern Battery Percent"
                                [(ngModel)]="friParameters.externalBatteryPercent">
                            </p-checkbox>
                        </div>

                        <div class="col">
                            <p-checkbox binary="true" label="Temperature" [(ngModel)]="friParameters.temperature">
                            </p-checkbox>
                        </div>

                        <div class="col">
                            <p-checkbox binary="true" label="RAT and Band Data" [(ngModel)]="friParameters.ratAndBand">
                            </p-checkbox>
                        </div>

                    </div>

                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>