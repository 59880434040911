import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-apnconfiguration-concox-mt200",
  templateUrl: "./apnconfiguration-concox-mt200.component.html",
  styleUrls: ["./apnconfiguration-concox-mt200.component.css"],
})
export class ApnconfigurationConcoxMt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() apn: Rest.APNConfigurationConcoxMT200;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.apn);
    }
  }
}
