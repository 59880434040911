import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PhaseChangeEvent } from 'app/modules/vehicle-inventory/services/inventory-reports.service';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';

import Delegation = Rest.Delegation;

@Component({
  selector: 'app-vehicle-consumption-report-form',
  templateUrl: './vehicle-consumption-report-form.component.html',
  styleUrls: ['./vehicle-consumption-report-form.component.css'],
  providers: [
    DelegationService
  ],
  encapsulation: ViewEncapsulation.None
})
export class VehicleConsumptionReportFormComponent implements OnInit {

  @Output() phaseChange = new EventEmitter<PhaseChangeEvent>();
  @Input() report: PhaseChangeEvent;

  reportData: Rest.InventoryReport;

  reportForm = new FormGroup({
    delegation: new FormControl(null),
  });

  // PRESERVATION OF RELATIONSHIPS
  delegationRequestContext: Rest.ListPaginationRequest;

  // FORM OPTIONS LISTS
  delegations: Delegation[] = [];

  isDelegationLoaded: boolean = false;

  // FORM DATA
  selectedDelegations: Delegation[] = [];

  isDelegationChanged: boolean = false;

  constructor(
    private delegationService: DelegationService,
  ) { }

  ngOnInit(): void {
    this.delegationRequestContext = RestExt.firstPageRequestVehicleInventory();

    this.fetchData();
  }

  goBack() {
    this.phaseChange.emit({ phase: 1, resetData: true });
    this.reportForm.reset();
  }

  onSubmit() {
    if (this.reportForm.valid) {
      this.toPhase3();
    }
  }

  toPhase3() {
    this.phaseChange.emit({ phase: 3, reportData: this.report.reportData, formData: this.reportForm.value });
  }

  fetchData() {
    this.reportData = this.report.reportData;

    this.fetchDelegations();
  }

  fetchDelegations() {
    this.delegationRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { disabled: [false] },
    } as unknown as Rest.ListPaginationRequest;

    this.delegationService.getPage(this.delegationRequestContext).then((p) => {
      this.delegations = p.entities.map(delegation => ({
        ...delegation,
        codeName: `${delegation.code} ${delegation.name}`
      }));

      this.isDelegationLoaded = true;
    });
  }
}
