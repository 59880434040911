<!-- VEHICLE PANEL TELEMETRY VIEW MAIN CONTAINER -->
<div class="container-fluid p-0 ">

  <!-- ROW 1: SENSORS CONTAINER -->
  <div class="row m-0">
    <div class="container p-0 h-100 telemetry-view-inner-container">

      <!-- SENSORS CONTAINER HEADER-->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-header">
        <div class="d-flex align-items-center h-100">
          <div class="ps-3 telemetry-view-panel-header-title">
            Sensors
          </div>
        </div>
      </div>

      <!-- SENSORS CONTAINER BODY -->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-body">

        <!-- NO SENSORS -->
        <div *ngIf="vehicle.device.configuration.parameters.sensors.length === 0" class="ps-3 mt-3 mb-3 telemetry-view-title">
          No Sensors found!
        </div>

        <!-- SENSORS -->
        <div class="row ps-3 mt-3">
          <div class="col col-auto mb-3" *ngFor="let sensor of vehicle.device.configuration.parameters.sensors">
            <div 
              *ngIf="!isUserHosp || (sensor.tag === 'BLE2_TEMPERATURE' || sensor.tag === 'BLE3_TEMPERATURE')"
              class="d-flex align-items-center me-2 custom-telemetry-view-sensor-container">

              <!-- SENSOR CHECKBOX -->
              <input
                     class="form-check-input d-flex justify-content-center align-items-center mt-0 custom-checkbox"
                     type="checkbox"
                     [(ngModel)]="visibleDevices['s'+sensor.id]"
                     (change)="updatePlot()"
              />

              <!-- SENSOR LABEL -->
              <label class="form-label m-0 ms-2 me-2">
                {{sensor.name}}
              </label>

              <!-- SENSOR COLOR PICKER -->
              <input type="color"
                     class="form-control form-control-color custom-color-picker"
                     id="sensorColorPicker"
                     [(ngModel)]="deviceColors['s'+sensor.id]"
                     (change)="updatePlot()"
              >


            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- ROW 2: ACTUATORS CONTAINER -->
  <div class="row m-0 mt-2">
    <div class="container p-0 h-100 telemetry-view-inner-container">

      <!-- ACTUATORS CONTAINER HEADER-->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-header">
        <div class="d-flex align-items-center h-100">
          <div class="ps-3 telemetry-view-panel-header-title">
            Actuators
          </div>
        </div>
      </div>

      <!-- ACTUATORS CONTAINER BODY -->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-body">

        <!-- NO ACTUATORS -->
        <div *ngIf="vehicle.device.configuration.parameters.actuators.length === 0" class="ps-3 mt-3 mb-3 telemetry-view-title">
          No Actuators found!
        </div>

        <!-- ACTUATORS-->
        <div class="col col-auto mb-3" *ngFor="let actuator of vehicle.device.configuration.parameters.actuators">
          <div class="d-flex align-items-center me-2 custom-telemetry-view-sensor-container">

            <!-- ACTUATOR CHECKBOX -->
            <input class="form-check-input d-flex justify-content-center align-items-center mt-0 custom-checkbox"
                   type="checkbox"
                   [(ngModel)]="visibleDevices['a'+actuator.id]"
                   (change)="updatePlot()"
            />

            <!-- ACTUATOR LABEL -->
            <label class="form-label m-0 ms-2 me-2">
              {{actuator.name}}
            </label>

            <!-- ACTUATOR COLOR PICKER -->
            <input type="color"
                   class="form-control form-control-color custom-color-picker"
                   id="actuatorColorPicker"
                   [(ngModel)]="deviceColors['s'+actuator.id]"
                   (change)="updatePlot()"
            >

          </div>

        </div>

      </div>

    </div>
  </div>

  <!-- ROW 3: LIVE VIEW TOGGLE && DATE SELECT -->
  <div class="row m-0 mt-2">
    <div class="container p-0 h-100 telemetry-view-inner-container">

      <!-- LIVE VIEW TOGGLE && DATE SELECT CONTAINER HEADER-->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-header">
        <div class="d-flex align-items-center h-100">
          <div class="ps-3 telemetry-view-panel-header-title">
            Live View
          </div>
        </div>
      </div>

      <!-- LIVE VIEW TOGGLE && DATE SELECT CONTAINER BODY -->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-body">
        <div class="row ps-3 pe-3 mt-3 mb-3">

          <!-- LIVE VIEW TOGGLE -->
          <div class="col col-auto">
            <div class="form-check form-switch d-flex align-items-center custom-switch" style="height: 50px">
              <label class="form-check-label me-2 custom-label" for="liveViewSwitch">Live View</label>
              <input class="form-check-input" type="checkbox" id="liveViewSwitch" [(ngModel)]="liveView"
                     (ngModelChange)="toggleLiveView()">
            </div>
          </div>

          <!-- FROM DATE PICKER -->
          <div class="col">
            <div class="d-flex align-items-center">
              <label class="form-check-label me-2 custom-label" for="fromDatePicker">From:</label>
              <p-calendar
                [style]="{'height': '45px'}"
                [styleClass]="'custom-primeng-input'"
                id="fromDatePicker"
                [(ngModel)]="startDate"
                [disabled]="liveView"
                [showTime]=true
                (onSelect)="timeRangeUpdated()"
                [readonlyInput]="true"
              ></p-calendar>
            </div>
          </div>

          <!-- TO DATE PICKER -->
          <div class="col">
            <div class="d-flex align-items-center">
              <label class="form-check-label me-2 custom-label" for="toDatePicker">To:</label>
              <p-calendar
                [style]="{'height': '45px'}"
                [styleClass]="'custom-primeng-input'"
                id="toDatePicker"
                [(ngModel)]="endDate"
                [disabled]="liveView"
                [showTime]=true
                (onSelect)="timeRangeUpdated()"
                [readonlyInput]="true"
              ></p-calendar>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <!-- ROW 3: CHART -->
  <div class="row m-0 mt-2">
    <div class="container p-0 h-100 telemetry-view-inner-container">

      <!-- CHART CONTAINER HEADER-->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-header">
        <div class="d-flex align-items-center h-100">
          <div class="ps-3 telemetry-view-panel-header-title">
            Chart
          </div>
        </div>
      </div>

      <!-- CHART CONTAINER BODY -->
      <div class="container ps-3 pe-3 telemetry-view-inner-container-body">
        <div class="row ps-3 pe-3 mt-3 mb-3">

          <p-chart type="line" [data]="chartData" [options]="chartOptions"></p-chart>

        </div>
      </div>

    </div>
  </div>

</div>
