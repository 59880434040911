import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EntityService } from '../../rest/entity.service';
import { MaintenanceexecutionService } from '../../rest/maintenanceexecution.service';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { I18nService } from '../../services/i18n/i18n.service';
import { NotificationsService } from '../../services/notifications-service/notifications.service';
import { EntityFormComponent } from '../entity-form/entity-form.component';
import { DistanceFormatPipe } from '../shared/DistanceFormatPipe';
import { UserTimePipe } from '../shared/UserTimePipe';
import { Rest } from 'app/rest/rest_client';
import { TranslateService } from "@ngx-translate/core";
import { MaintenanceService } from 'app/rest/maintenance.service';
import { MaintenanceplanService } from 'app/rest/maintenanceplan.service';
//import MaintenanceExecution = Rest.MaintenanceExecution;

@Component({
	selector: 'app-maintenance-execution-form',
	templateUrl: './maintenance-execution-form.component.html',
	styleUrls: ['./maintenance-execution-form.component.css'],
	providers: [DistanceFormatPipe, TranslateService],
})
export class MaintenanceExecutionFormComponent extends EntityFormComponent<Rest.MaintenanceExecution> implements OnInit {
	@Input() vehicle: Rest.Vehicle;
	@Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();

	title: string;
	readonly = false;
	isEditingDisabled: boolean = false;
	maintenanceListLoaded: boolean = false;
	maintenanceSpinner: boolean = false;
	errorMessages: string[] = [];

	maintenancePlanList: Rest.MaintenancePlan[] = [];
	maintenanceList: Rest.Maintenance[] = [];
	selectedMaintenancePlan: Rest.MaintenancePlan = null;
	selectedMaintenance: Rest.Maintenance = null;

	isNew: boolean = false;
	maintenanceExecution: Rest.MaintenanceExecution = null;
	dateOfExecution: Date;
	kmofExecution: number;

	ids: number[] = [];

	// PERMISSIONS
	canEdit: boolean = false;

	constructor(
		public executionService: MaintenanceexecutionService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private userTime: UserTimePipe,
		private distanceFormat: DistanceFormatPipe,
		protected authenticationService: AuthenticationService,
		private translationService: TranslateService,
		private maintenanceplanService: MaintenanceplanService,
		private maintenanceService: MaintenanceService,
	) {
		super(executionService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		// console.log('[MAINTENANCE-EXECUTIONS-FORM] ngOnInit ***');
		super.ngOnInit();
		// console.log(this.entity);
		// console.log(this.vehicle);
		//Initialize entity object to avoid html render errors
		this.entity = {
			maintenance: {},
			vehicle: this.vehicle,
		} as Rest.MaintenanceExecution;
		this.getMaintenancePlanList(this.vehicle.client.id);

		this.setPermissions();
	}

	/**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof MaintenanceExecutionFormComponent
   */
	public beforeShow() {
		// console.log('[MAINTENANCE-EXECUTIONS-FORM] beforeShow ***');
		// console.log(this.entity);
	}

	beforeSave() {
		// this.entity.nextMaintenanceDate = this.userTime.calendarDateToUTC(this.dateOfExecution);
		// this.entity.nextMaintenanceKms = this.distanceFormat.userMetricToSystemMetric(this.kmofExecution);
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['MAINTENANCE_EXECUTION_CREATE', 'MAINTENANCE_EXECUTION_UPDATE'];

		this.canEdit = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	closeFormDialog(): void {
		this.display = false;
		this.refreshTable.emit();
	}

	public showDialog(isNew: boolean, maintenanceExecution: Rest.MaintenanceExecution): void {
		// console.log('[MAINTENANCE-EXECUTIONS-FORM] showDialog ***');
		this.isNew = isNew;
		this.display = true;
		// console.log(this.isNew);
		// console.log(this.vehicle);

		if (this.isNew) {
			//Initialize entity object to avoid html render errors
			this.entity = {
				maintenance: {},
				vehicle: this.vehicle,
			} as Rest.MaintenanceExecution;
			this.selectedMaintenancePlan = null;
			this.selectedMaintenance = null;
			this.entity.enabled = true;
			this.entity.incidents = false;
			this.title = this.translationService.instant('general.create') + " " + this.translationService.instant('maintenance.table.maintenance') + ": " + this.vehicle.name + " " + this.vehicle.model?.brand?.name + " - " + this.vehicle.model?.name + " - " + this.vehicle.version?.name;;
		} else {
			this.title = this.translationService.instant('general.edit') + " " + this.translationService.instant('maintenance.table.maintenance') + ": " + this.vehicle.name + " " + this.vehicle.model?.brand?.name + " - " + this.vehicle.model?.name + " - " + this.vehicle.version?.name;;
			this.entity = maintenanceExecution;

			this.ids = [];
			if (this.entity.document && this.entity.document.length > 0) {
				this.entity.document.forEach((x) => this.ids.push(x.id));
			} else {
				this.ids = [-1];
			}
			// console.log(this.ids);

			this.selectedMaintenancePlan = this.entity.maintenance.maintenancePlan;
			this.selectedMaintenance = this.entity.maintenance;
			// console.log("entity...");
			// console.log(this.selectedMaintenancePlan);
			// console.log("selectedMaintenancePlan...");
			// console.log(this.selectedMaintenancePlan);
			// console.log("selectedMaintenance...");
			// console.log(this.selectedMaintenance);
			if (this.selectedMaintenancePlan)
				this.getMaintenanceListByPlan(this.selectedMaintenancePlan.id);
		}
		// console.log(this.entity);
		// console.log(this.selectedMaintenancePlan);
	}

	onSaveExecution() {
		// console.log("[MAINTENANCE-EXECUTION-FORM] onSaveExecution ***");
		// console.log("this.vehicle...");
		// console.log(this.vehicle);
		// console.log("this.entity...");
		// console.log(this.entity);

		this.isEditingDisabled = true;
		if (this.validateExecutionForm()) {

			// console.log("this.entity.lastMaintenanceDate...");
			// console.log(this.entity.lastMaintenanceDate);
			// console.log("this.entity.nextMaintenanceDate...");
			// console.log(this.entity.nextMaintenanceDate);
			// console.log("this.entity.doneMaintenanceDate...");
			// console.log(this.entity.doneMaintenanceDate);
			let newStatus: String;
			if (this.isNew) {
				newStatus = this.entity.doneMaintenanceDate || this.entity.doneMaintenanceKms ? 'FINISHED' : 'OK';
			} else {
				newStatus = this.entity.doneMaintenanceDate || this.entity.doneMaintenanceKms ? 'FINISHED' : this.entity.status;
			}

			let meData = {
				id: this.isNew ? 0 : this.entity.id,
				vehicle: { id: this.vehicle.id },
				observations: this.entity.observations,
				maintenance: { id: this.selectedMaintenance.id },
				enabled: this.entity.enabled,
				lastMaintenanceDate: this.entity.lastMaintenanceDate ? this.entity.lastMaintenanceDate.toISOString() : null,
				lastMaintenanceKms: this.entity.lastMaintenanceKms ? this.entity.lastMaintenanceKms : null,
				nextMaintenanceDate: this.entity.nextMaintenanceDate ? this.entity.nextMaintenanceDate.toISOString() : null,
				nextMaintenanceKms: this.entity.nextMaintenanceKms ? this.entity.nextMaintenanceKms : null,
				doneMaintenanceDate: this.entity.doneMaintenanceDate ? this.entity.doneMaintenanceDate.toISOString() : null,
				doneMaintenanceKms: this.entity.doneMaintenanceKms ? this.entity.doneMaintenanceKms : null,
				status: newStatus,
				incidents: this.entity.incidents,
				document: this.isNew ? [] : this.entity.document,
			};
			// console.log(meData);

			if (this.isNew) {
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.executionService
						.create(meData)
						.then(
							function (response) {
								// console.log("==> create execution... " );
								// console.log(response);
								// console.log("==> Send refresh list... ");
								that.executionService.refreshList.next(null);
								that.isEditingDisabled = false;
								that.display = false;
							},
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							},
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});

			}
			else {
				const promise = new Promise<void>((resolve, reject) => {
					const that = this;
					this.executionService
						.update(meData)
						.then(
							function (response) {
								// console.log("==> update execution... ");
								// console.log(response);
								// console.log("==> Send refresh list...");
								that.executionService.refreshList.next(null);
								that.isEditingDisabled = false;
								that.display = false;
							},
							/* failure */
							function (error) {
								console.log('The request failed: ' + error);
								resolve();
							},
						)
						.catch(function (reason) {
							console.log('Catched: ' + reason);
							resolve();
						});
				});
			}

		} else {
			this.isEditingDisabled = false;
		}
	}

	public validateExecutionForm(): Boolean {
		// console.log("[MAINTENANCE-EXECUTION-FORM] validateExecutionForm ***");
		// console.log(this.entity);
		// console.log(this.vehicle);
		this.errorMessages = [];

		if (!this.selectedMaintenancePlan ||
			!this.selectedMaintenance ||
			(this.selectedMaintenance.periodicityType !== 'TIME_BASED' && !this.entity.nextMaintenanceKms) ||
			(this.selectedMaintenance.periodicityType === 'TIME_BASED' && !this.entity.nextMaintenanceDate)
		) {
			this.errorMessages.push(
				this.translationService.instant('error.required-field.generic'),
			);
			window.scroll({ top: 0, behavior: 'smooth' });
			this.isEditingDisabled = false;
		}
		return this.errorMessages.length === 0;
	}

	getMaintenancePlanList(clientId: number) {
		// console.log("[MAINTENANCE-EXECUTIONS-FORM] getMaintenancePlanList ***");
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.maintenanceplanService
				.getList(clientId)
				.then(
					function (response) {
						// console.log("==> MaintenancePlanList... " );
						// console.log(response);
						that.maintenancePlanList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	getMaintenanceListByPlan(planId: number) {
		// console.log("[MAINTENANCE-EXECUTIONS-FORM] getMaintenanceListByPlan ***");
		this.maintenanceListLoaded = false;
		this.maintenanceSpinner = true;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.maintenanceService
				.getListByPlan(planId)
				.then(
					function (response) {
						// console.log("==> MaintenanceList... " );
						// console.log(response);
						that.maintenanceList = response;
						that.maintenanceListLoaded = true;
						that.maintenanceSpinner = false;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	maintenancePlanChanged(event) {
		// console.log("[MAINTENANCE-EXECUTIONS-FORM] maintenancePlanChanged ***");
		this.maintenanceList = [];
		this.selectedMaintenance = null;
		this.getMaintenanceListByPlan(this.selectedMaintenancePlan.id);
	}

	maintenanceChanged(event) {
		// console.log("[MAINTENANCE-EXECUTIONS-FORM] maintenancePlanChanged ***");
		// console.log(this.selectedMaintenancePlan);
	}

	toggleDisabled($event) {

	}

	toggleIncidents($event) {

	}

	onHide() {
		// console.log("[MAINTENANCE-EXECUTIONS-FORM] onHide ***");
		this.executionService.refreshList.next(null);
	}

	refresh() {
		//console.log("[MAINTENANCE-EXECUTIONS-FORM] refresh");
		this.refreshTable.emit();
	}

}
