<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mt-4" *ngFor="let report of inventoryReports">
            <!-- Button acts as a card -->
            <button class="btn report-card" (click)="onSelectPhase(report)">
                <div class="card-body text-center">
                    <!-- Icon rendering -->
                    <div *ngIf="report.icon.imageEncoded; else defaultIcon">
                        <i [class]="report.icon" class="report-icon"></i>
                        <img #image src="{{report.icon.imageEncoded}}"
                            class="report-icon">
                    </div>
                    <ng-template #defaultIcon>
                        <i class="default-icon-class report-icon"></i>
                        <img #image src="{{'../../../../assets/images/UI_Elements/HD_transparent_picture.png'}}"
                            class="default-icon-class report-icon">
                    </ng-template>

                    <!-- Report title -->
                    <h5 class="card-tittle mt-2">{{report.name}}</h5>
                </div>
            </button>
        </div>
    </div>
</div>