import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "app/rest/JQueryHTTPClient";
import { EntityService } from "app/rest/entity.service";
import { Rest } from "app/rest/rest_client";



@Injectable()
export class FineReasonService extends EntityService<Rest.FineReasons> {
    client: Rest.RestApplicationClient;
    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.FineReasons): Rest.RestResponse<Rest.FineReasons> {
        try {
            return this.client.FineReasons_create(arg0);
        } catch (error) {
            console.error("Error creating FineReasons:", error);
            throw error;
        }
    }

    update(arg0: Rest.FineReasons): Rest.RestResponse<Rest.FineReasons> {
        try {
            return this.client.FineReasons_update(arg0);
        } catch (error) {
            console.error("Error updating FineReasons:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Rest.FineReasons>> {
        try {
            return this.client.FineReasons_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting FineReasons page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.FineReasons_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing FineReasons status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.FineReasons> {
        try {
            return this.client.FineReasons_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding FineReasons:", error);
            throw error;
        }
    }
}