<!-- PROVIDERS LIST PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
  [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
  [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
  [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name"
  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
  styleClass="p-datatable-striped">

  <!-- TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'tableManagement.providers.providersManagement' | translate }}
    </div>
  </ng-template>

  <!-- TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>

      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>

      <!-- IMAGE -->
      <th pSortableColumn='imei'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'general.image' | translate }}
          </div>
        </div>
      </th>

      <!-- NAME -->
      <th pSortableColumn="name">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.name' | translate }}
          </div>
        </div>
      </th>

      <!-- TYPE -->
			<th pSortableColumn="type">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.type' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="type"></p-sortIcon>
							<p-columnFilter [showApplyButton]="true" field="type" matchMode="in" display="menu"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false" appendTo="body"
								[hideOnClear]="true">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">

									<p-multiSelect [ngModel]="value" (onChange)="filter($event.value);" [filter]="true"
										[options]="typeList" placeholder="{{ 'general.filters.any' | translate }}"
										[virtualScroll]="true" itemSize="30" optionLabel="name" optionValue="id"
										[showToggleAll]="false" [panelStyle]="{'min-width': '350px'}">
										<ng-template let-option pTemplate="item">
											<div>
												<span class="ml-1">{{option.name}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

      <!-- ACTIVE -->
      <th pSortableColumn="disabled">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="disabled"></p-sortIcon>
            <p-columnFilter field="disabled" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>

    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-provider>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(provider)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="provider"></p-tableCheckbox>
        </div>
      </td>

      <!-- IMAGE -->
      <td>
        <div class="d-flex justify-content-center" *ngIf="!provider.image; else providerImage">
          <img #image src="{{'../../../../assets/images/UI_Elements/HD_transparent_picture.png'}}"
            class="img-responsive" [style]="{'max-height': '50px'}">
        </div>
        <ng-template #providerImage>
          <div class="d-flex justify-content-center">
            <img #image src="{{provider.image.imageEncoded}}" class="img-responsive" [style]="{'max-height': '50px'}">
          </div>
        </ng-template>
      </td>

      <!-- NAME -->
      <td>
        {{provider.name}}
      </td>

      <!-- TYPE -->
      <td>
        {{provider.type.name}}
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
            [ngClass]="{'true-icon pi-check-circle': !provider.disabled, 'false-icon pi-times-circle': provider.disabled}"></i>
        </div>
      </td>
    </tr>

  </ng-template>

  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="5" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD PROVIDER BUTTON -->
          <app-table-add-button [text]=" 'tableManagement.providers.provider' | translate " (btnClick)="create()"
            [isDisabled]=false></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0"></app-table-change-status-button>

          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-dialog [items]=" 'control-panel.status-dialog.clients' | translate " [display]="true"
    [service]=providersService [entitiesList]=selectedEntities (return)="refreshList($event)"
    (close)="closeStatusDialog($event)"></app-change-status-dialog>
</div>

<!-- PROVIDER FORM -->
<app-provider-form></app-provider-form>