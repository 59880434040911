<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	dataKey="id"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.vehicleUsage.vehicleUsage' | translate }}
		</div>
	</ng-template>
	<!-- #endregion TABLE TITE DEFINITION -->

	<!-- #region TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

         <!-- CODE -->
         <th pSortableColumn='code'>
				<div class='d-flex align-items-center h-100'>
					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
						{{ 'tableManagement.vehicleStatus.table.code' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
					  	<p-sortIcon field='code'></p-sortIcon>
						<p-columnFilter field='code' type='text' matchMode='contains'
							placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
							[showOperator]='false' [showAddButton]='false'></p-columnFilter>
					</div>

				</div>
			</th>

         <!-- NAME -->
         <th pSortableColumn='name'>
				<div class='d-flex align-items-center h-100'>
					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
						{{ 'tableManagement.vehicleStatus.table.name' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
					  	<p-sortIcon field='name'></p-sortIcon>
						<p-columnFilter field='name' type='text' matchMode='contains'
							placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
							[showOperator]='false' [showAddButton]='false'></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- START DATE -->
			<th pSortableColumn="startDate">
				<div
					title="{{ 'general.startDate' | translate }}"
					class="d-flex align-items-center h-100"
				>
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.startDate' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="startDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- DEASSIGN DATE -->
			<th pSortableColumn="endDate">
				<div
					title="{{ 'general.endDate' | translate }}"
					class="d-flex align-items-center h-100"
				>
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.endDate' | translate }}
					</div>
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="endDate"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="disabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="disabled"></p-sortIcon>
						<p-columnFilter
							field="disabled"
							type="boolean"
							display="menu"
							[showApplyButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>
	<!-- #endregion TABLE COLUMN DEFINITION -->

	<!-- #region TABLE BODY -->
	<ng-template pTemplate="body" let-htcusage>
		<tr class="p-selectable-row custom-rows">

			<!-- CODE -->
			<td>
				{{ htcusage.vehicleUsage.code }}
			</td>

			<!-- NAME -->
			<td>
				{{ htcusage.vehicleUsage.name }}
			</td>

			<!-- ASSIGN DATE -->
			<td>
				{{ htcusage.startDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- DEASSIGN DATE -->
			<td>
				{{ htcusage.endDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i
						class="pi"
						[ngClass]="{
							'true-icon pi-check-circle': !htcusage.vehicleUsage.disabled,
							'false-icon pi-times-circle': htcusage.vehicleUsage.disabled
						}"
					></i>
				</div>
			</td>

		</tr>
	</ng-template>
	<!-- #endregion TABLE BODY -->

	<!-- #region TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="5" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE EMPTY MESSAGE -->

</p-table>
