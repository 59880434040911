<!-- <p-messages [(value)]="messages"></p-messages> -->

<!-- PrimeNG DataTable -->
<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	dataKey="id"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- #region TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'maintenance.header' | translate }}
		</div>
	</ng-template>
	<!-- #endregion TABLE TITE DEFINITION -->

	<!-- Table Column/Header Definition -->
	<ng-template pTemplate="header">
		<tr>
			<!-- SELECT -->
			<th class="checkbox-column">
				<div class="d-flex justify-content-center">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</div>
			</th>

			<!-- maintenance -->
			<th pSortableColumn="maintenance">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'maintenance.table.maintenance' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="maintenanceID"></p-sortIcon>
						<p-columnFilter
							field="maintenanceID"
							matchMode="in"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						>
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-multiSelect
									[ngModel]="value"
									[options]="maintenanceList"
									placeholder="{{ 'general.filters.any' | translate }}"
									(onChange)="filter($event.value)"
									optionLabel="name"
									optionValue="id"
									display="chip"
									[showClear]="true"
								>
									<ng-template let-option pTemplate="item">
										<div class="p-multiselect-model-option">
											<span class="ml-1">{{ option.name }}</span>
										</div>
									</ng-template>
								</p-multiSelect>
							</ng-template>
						</p-columnFilter>
					</div>
				</div>
			</th>

			<!-- maintenancePlan -->
			<th pSortableColumn="planID">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'maintenance.table.maintenancePlan' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="planID"></p-sortIcon>
						<p-columnFilter
							field="planID"
							matchMode="in"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						>
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-multiSelect
									[ngModel]="value"
									[options]="planList"
									placeholder="{{ 'general.filters.any' | translate }}"
									(onChange)="filter($event.value)"
									optionLabel="name"
									optionValue="id"
									display="chip"
									[showClear]="true"
								>
									<ng-template let-option pTemplate="item">
										<div class="p-multiselect-model-option">
											<span class="ml-1">{{ option.name }}</span>
										</div>
									</ng-template>
								</p-multiSelect>
							</ng-template>
						</p-columnFilter>
					</div>
				</div>
			</th>

			<!-- PLANED -->
			<th>
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN  -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'maintenance.table.planed' | translate }}
					</div>
				</div>
			</th>

			<!-- STATUS -->
			<th pSortableColumn="status">
				<div class="d-flex align-items-center h-100">
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'maintenance.table.status' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN SORT && FILTER ICONS -->
						<div class="d-flex align-items-center h-100">
							<p-sortIcon field="status"></p-sortIcon>
							<p-columnFilter
								field="status"
								matchMode="in"
								display="menu"
								[showMatchModes]="false"
								[showOperator]="false"
								[showAddButton]="false"
							>
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<!-- -->
									<p-multiSelect
										[ngModel]="value"
										[options]="statusList"
										placeholder="{{ 'general.filters.any' | translate }}"
										display="chip"
										[showClear]="true"
										(onChange)="filter($event.value)"
									>
										<ng-template let-option pTemplate="item">
											<div
												class="maintenance-status"
												[style]="{ '--color': option | maintenanceExecutionStatusColor }"
											></div>
											{{ 'maintenance.status.' + option | translate }}
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</div>
			</th>

			<!-- DONE -->
			<th>
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN  -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'maintenance.table.done' | translate }}
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="enabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>
					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="enabled"></p-sortIcon>
						<p-columnFilter
							field="enabled"
							type="boolean"
							display="menu"
							[showApplyButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>

	<!-- Table Row Definition -->
	<ng-template pTemplate="body" let-maintenanceExecution>
		<tr class="p-selectable-row custom-rows" (dblclick)="openDialog(false, maintenanceExecution)">
			<!-- SELECT -->
			<td>
				<div class="d-flex justify-content-center">
					<p-tableCheckbox [value]="maintenanceExecution"></p-tableCheckbox>
				</div>
			</td>

			<!-- MAINTENANCE -->
			<td>{{ maintenanceExecution.maintenance?.name }}</td>

			<!-- MAINTENANCE PLAN -->
			<td>{{ maintenanceExecution.maintenance?.maintenancePlan?.name }}</td>

			<!-- PLANED DATE / KMS -->
			<td *ngIf="maintenanceExecution.maintenance.periodicityType === 'TIME_BASED'; else nextkms">
				{{ maintenanceExecution.nextMaintenanceDate | date : 'dd/MM/yy' }}
			</td>
			<ng-template #nextkms>
				<td>{{ maintenanceExecution.nextMaintenanceKms }} Kms.</td>
			</ng-template>

			<!-- STATUS COLOR MaintenanceStatusType / MaintenanceStatusColor -->
			<td>
				<div class="d-flex row-cols-2 w-100 align-items-center">
					<div
						class="maintenance-status"
						[style]="{ '--color': maintenanceExecution.status | maintenanceExecutionStatusColor }"
					></div>
					{{ 'maintenance.status.' + maintenanceExecution.status | translate }}
				</div>
			</td>

			<!-- DONE DATE / KMS -->
			<td *ngIf="maintenanceExecution.status !== 'FINISHED'">-</td>
			<td
				*ngIf="
					maintenanceExecution.status === 'FINISHED' &&
					maintenanceExecution.maintenance.periodicityType === 'TIME_BASED'
				"
			>
				{{ maintenanceExecution.doneMaintenanceDate | date : 'dd/MM/yy' }}
			</td>
			<td
				*ngIf="
					maintenanceExecution.status === 'FINISHED' &&
					maintenanceExecution.maintenance.periodicityType === 'DISTANCE_BASED'
				"
			>
				{{ maintenanceExecution.doneMaintenanceKms }} Kms.
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i
						class="pi"
						[ngClass]="{
							'true-icon pi-check-circle': maintenanceExecution.enabled,
							'false-icon pi-times-circle': !maintenanceExecution.enabled
						}"
					></i>
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- #region TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td [attr.colspan]="colspan" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE EMPTY MESSAGE -->

	<!-- #region TABLE FOOTER -->
	<ng-template pTemplate="footer">
		<tr>
			<td [attr.colspan]="colspan" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD FINE BUTTON -->
					<app-table-add-button
						*ngIf="isCreateGranted"
						[text]="'maintenance.table.maintenance' | translate"
						(btnClick)="openDialog(true, null)"
					>
					</app-table-add-button>
					<!-- CHANGE STATUS BUTTON -->
					<app-table-change-status-button (btnClick)="changeStatus()" 
						[isDisabled]="selectedEntities.length === 0 || !isDisabledGranted">
					</app-table-change-status-button>

					<!-- EXCEL BUTTON -->
					<!-- <app-table-excel-button
							class="buttonTableExcel"
							(btnClick)="exportExcel()">
						</app-table-excel-button> -->
				</div>
			</td>
		</tr>
	</ng-template>
	<!-- #endregion TABLE FOOTER -->

	<ng-template #status let-data="data">
		{{ data }}
	</ng-template>
</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
	<app-change-status-shared
		[items]="'maintenance.header' | translate"
		[display]="true"
		[service]="executionService"
		[entitiesList]="selectedEntities"
		(return)="refreshList($event)"
		(close)="closeStatusDialog($event)"
	></app-change-status-shared>
</div>

<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>

<app-maintenance-execution-form
	#editForm
	[vehicle]="vehicle"
	(refreshTable)="refreshList($event)"
></app-maintenance-execution-form>
