import {Rest} from "../rest/rest_client";
import {I18nService} from "./i18n/i18n.service";

export namespace RestExt {
  export interface WatchedVehicle extends Rest.Vehicle {
    visibleInMap: boolean;
  }

  export interface ExtendedVehicleDataMessage extends Rest.VehicleDataMessage {
    fleetName: string;
    groupName: string;
    address: string;
    vehicleName: string;
    color: string;
    category_idx: number;
    //sensor and actuator values are converted to Extended
    userCanChangeOutputs: boolean;
    userCanSendPosition: boolean;
    userCanRequestPosition: boolean;
    userCanSendMessage: boolean;
    userCanCall: boolean;
    icon: string;
    location: { latitude: 0; longitude: 1 };
  }

  // @ts-ignore
  export interface ExtendedDValue extends Rest.DValue {
    rawValue: any;
    dID: any;
    categoricValue: any;
    device: Rest.Actuator | Rest.Sensor;
    numericValue: number;
  }

  export interface ExtendedResponse extends Rest.ResponseMessage {
    vehicle_name: string;
  }

  export function getEnumNames(en: any): string[] {
    const objValues = Object.keys(en).map((k) => en[k]);
    return objValues.filter((v) => typeof v === "string") as string[];
  }

  /**
   *
   * Given an enum object, returns an array of select items where each item contains a key from the enum (translated) and the value is the value of the enum
   *
   * this is the same as doing:
   *
   *
   * //const objValues = Object.keys(RestExt.DeviceStatusEnum).map(k => RestExt.DeviceStatusEnum[k]);
   //const names = objValues.filter(v => typeof v === "string") as string[];
   //names.forEach(n => this.availableDeviceStatus.push({ label: this.i18n._(n), value: n }));
   * @param en
   * @param i18n
   */
  export function getEnumItems(en: any, i18n: I18nService): any[] {
    const selectItems: any[] = [];
    getEnumNames(en).forEach((n) =>
      selectItems.push({label: i18n._(n), value: n})
    );
    return selectItems;
  }

  export function getEnumItemsNoTranslation(en: any): any[] {
    const selectItems: any[] = [];
    getEnumNames(en).forEach((n) => selectItems.push({label: n, value: n}));
    return selectItems;
  }

  export function getEnabledFilter(en: any, i18n: I18nService): any[] {
    return en.map((e: any) => ({label: i18n._(e.label), value: e.value}));
  }

  export function entitiesToSelectItems(entities: any[]): any[] {
    return entities.map((e: any) => ({label: e.name, value: e}));
  }

  /**
   * Objects coming from server might contain unwanted data
   * @param object
   *
   * let obj = {"id":"blah","_$visited":"yes","name":"zzz","ob":{"ss":null,"_$visited":true,"f":undefined,"func":(a:any)=>{return a;},},"ar":["text",{"p1":"??","_$visited":true}]};
   RestExt.removeUnwantedProperties(obj);

   */

  export function removeUnwantedProperties(object: any) {
    if (object == null) {
      return;
    }
    Object.keys(object).forEach(function (prop) {
      if (object[prop] === undefined) {
        return true;
      }

      //We found one! kill it with fire!
      if (prop.startsWith("_$")) {
        delete object[prop];
        return true;
      }

      //check if its an array
      if (Object.prototype.toString.call(object[prop]) === "[object Array]") {
        //v instanceof Array
        //Recurively remove unwanted tags for each element of the array
        for (const idx in object[prop]) {
          removeUnwantedProperties(object[prop][idx]);
        }
        return true;
      }
      //check if it's an object
      if (typeof object[prop] === "object") {
        removeUnwantedProperties(object[prop]);
        return true;
      }

      return true;
    });
  }

  export function firstPageRequest() {
    let t = {
      sortBy: "id",
      sortAsc: true,
      pageSize: 10,
      loadPermissions: true,
      filters: {},
    } as Rest.ListPaginationRequest;
    t.filters.enabled = ["true"];
    return t;
  }


  export function firstPageRequestVehicleInventory() {
    let t = {
      sortAsc: true,
      pageSize: 10,
      loadPermissions: true,
      filters: {},
    } as Rest.ListPaginationRequest;
    t.filters.disabled = ["false"];
    return t;
  }

  export function firstPageRequestTask() {
    let t = {
      sortBy: "id",
      sortAsc: true,
      pageSize: 30,
      loadPermissions: true,
      filters: {},
    } as Rest.ListPaginationRequest;
     return t;
  }


  export function emptyRTSettings() {
    return {
      id: -1,
      fleetTreeSettings: <Rest.FleetTreeSettings>{
        id: -1,
        sensorChartSettings: <Rest.SensorChartSettings>{
          numericalValuesColors: {},
          categoricalValuesMappings: {},
        },
        visibleFleets: [],
        visibleVehicles: [],
        visibleVehiclesGroups: [],
      },
      vehicleDetailsSettings: <Rest.VehicleDetailsSettings>{
        id: -1,
        view: "GRID",
      },
      mapSettings: <Rest.MapSettings>{
        id: -1,
        visiblePOICategories: [],
        visibleZoneGroups: [],
      },
    } as Rest.RealTimeSettings;
  }

  function clean(obj) {
    if (
      typeof obj === "boolean" ||
      typeof obj === "number" ||
      typeof obj === "string"
    ) {
      return;
    }

    for (var subObject in obj) {
      if (obj[subObject] === null || obj[subObject] === undefined) {
        delete obj[subObject];
      } else {
        clean(obj[subObject]);
      }
    }
  }

  function recursiveAssignDefaultObjects(defaultObj, currentObj) {
    if (
      typeof defaultObj === "boolean" ||
      typeof defaultObj === "number" ||
      typeof defaultObj === "string"
    ) {
      return;
    }

    for (var subObject in defaultObj) {
      if (
        currentObj[subObject] === null ||
        currentObj[subObject] === undefined
      ) {
        currentObj[subObject] = defaultObj[subObject];
      } else {
        recursiveAssignDefaultObjects(
          defaultObj[subObject],
          currentObj[subObject]
        );
      }
    }
  }

  export function removeInvalidParams(
    conf: Rest.DeviceParameters,
    version: number,
    deviceType: string
  ) {
    var defaultConf = null;

    if (deviceType === "VT10") {
      defaultConf = emptyVT10Params1(version);
    } else if (deviceType === "GV300") {
      defaultConf = emptyGV300Params();
    } else if (deviceType === "GMT200") {
      defaultConf = emptyGMT200Params();
    } else if (deviceType === "ConcoxMT200") {
      defaultConf = emptyConcoxMT200Params();
    } else if (deviceType === "WeTrack2") {
      defaultConf = emptyWeTrack2Params();
    } else if (deviceType === "GV300CAN") {
      defaultConf = emptyGV300CANParams();
    } else if (deviceType === 'GV800') {
      defaultConf = emptyGV800Params();
    } else if (deviceType === 'X8') {
      defaultConf = emptyX8Params();
    } else if (deviceType === 'VT200') {
      defaultConf = emptyVT200Params();
    } else if (deviceType === 'EVIX') {
      defaultConf = emptyEVIXParams();
    }else if(deviceType === 'Iofrog'){
      defaultConf = emptyIofrogParams();
    }else if(deviceType === 'Teltonika'){
      defaultConf = emptyTeltonikaParams();
    }else if(deviceType === 'OB22'){
      defaultConf = emptyOB22Params();
    }else if(deviceType == 'GL320M'){
      defaultConf = emptyGL320MParams();
    }
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
    /*Object.keys(conf).forEach(function (prop) {
            if (defaultConf[prop] === undefined) {delete conf[prop] }
        return conf;
    })*/
  }

  export function addNewParams(
    conf: Rest.DeviceParameters,
    version: number,
    deviceType: string
  ) {
    var defaultConf = null;

    if (deviceType === "VT10") {
      defaultConf = emptyVT10Params1(version);
    } else if (deviceType === "GV300") {
      defaultConf = emptyGV300Params();
    } else if (deviceType === "GMT200") {
      defaultConf = emptyGMT200Params();
    } else if (deviceType === "ConcoxMT200") {
      defaultConf = emptyConcoxMT200Params();
    } else if (deviceType === "WeTrack2") {
      defaultConf = emptyWeTrack2Params();
    } else if (deviceType === "GV300CAN") {
      defaultConf = emptyGV300CANParams();
    } else if (deviceType === 'GV800') {
      defaultConf = emptyGV800Params();
    } else if (deviceType === 'X8') {
      defaultConf = emptyX8Params();
    } else if (deviceType === 'VT200') {
      defaultConf = emptyVT200Params();
    } else if (deviceType === 'EVIX') {
      defaultConf = emptyEVIXParams();
    }else if(deviceType === 'Iofrog'){
      defaultConf = emptyIofrogParams();
    }else if(deviceType === 'Teltonika'){
      defaultConf = emptyTeltonikaParams();
    }else if(deviceType === 'OB22'){
      defaultConf = emptyOB22Params();
    }else if(deviceType === 'GL320M'){
      defaultConf = emptyGL320MParams();
    }

    if (defaultConf) {
      console.log("No default config find");
    }
    clean(conf);

    recursiveAssignDefaultObjects(defaultConf, conf);
    //console.log("2conf: ",conf)
    /* Object.keys(conf).forEach(function (prop) {
            if (conf[prop] == null) {conf[prop] =  defaultConf[prop]}
        return conf;
    })*/
  }

  export function initializeVT10Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyVT10Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);

    /*
       Object.keys(defaultConf).forEach(function (prop) {
            if (conf[prop] == null) {conf[prop] =  defaultConf[prop]}
        return conf;
    })*/
  }

  export function initializeX8Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyX8Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeVT200Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyVT200Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeGV300Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyGV300Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);

    /*
       Object.keys(defaultConf).forEach(function (prop) {
            if (conf[prop] == null) {conf[prop] =  defaultConf[prop]}
        return conf;
    })*/
  }

  export function initializeGV300CANParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptyGV300CANParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function emptyGV300CANParams() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "GV300CAN" as Rest.Types,
        globalParameters: {
          //powerSaving: Rest.PowerSavingEnum,
          powerSaving: "DISABLE" as Rest.PowerSavingEnum,
          eventMaskRESPGTPFA: true,
          eventMaskRESPGTMPN: true,
          eventMaskRESPGTMPF: true,
          eventMaskRESPGTBPL: true,
          eventMaskRESPGTBTC: true,
          eventMaskRESPGTSTC: true,
          eventMaskRESPGTSTT: true,
          eventMaskRESPGTANT: true,
          eventMaskRESPGTPDP: true,
          eventMaskRESPGTPNA: true,
          eventMaskPowerONRESPGTRTL: true,
          eventMaskIgnitionReportRESPGTIGNRESPGTIGFREPSGTVGN: true,
          eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: true,
          ledON: true,
          infoReportEnable: true,
          infoReportInterval: 300,
          gpslostTime: 0,
          gpsworkingMode: "GPS_GLONASS" as Rest.GPSWorkingModeGV300CANEnum,
          gsmreportRESPGTLBC: true,
          gsmreportRESPGTTOW: false,
          gsmreportRESPGTFRIRESPGTERI: true,
          gsmreportRESPGTSOS: true,
          gsmreportMode:
            "NO_CELL_INFO_REPORT" as Rest.GSMReportModeGV300CANEnum,
          gsmreportRESPGTRTL: true,
        },
        friParameters: {
          mode: "Disable" as Rest.ModeFRIGV300CAN,
          discardNoFix: false,
          checkInterval: 0,
          sendInterval: 5,
          distance: 50,
          mileage: 50,
          cornerReport: 0,
          iGFReportInterval: 0,
          digitalFuelSensorData: false,
          oneWireData: false,
          canData: false,
          percentage: false,
          volume: false,
          bluetoothAccesoryData: false,
        },
        speedAlarmParameters: {
          mode: "DISABLE" as Rest.ModeSAEGV300CANEnum,
          minSpeed: 0,
          maxSpeed: 0,
          validity: 0,
          sendInterval: 30,
        },
        sosParameters: {
          modeSos: "DISABLE" as Rest.ModeSosGV300CAN,
          digitalInputID: 0,
          sosNumber: "",
        },
        hbmParameters: {
          mode: "DISABLE" as Rest.ModeHBMGV300CAN,
          highSpeed: 100,
          mediumSpeed: 60,
          vha: 0,
          vhb: 0,
          vma: 0,
          vmb: 0,
          vla: 0,
          vlb: 0,
          corneringThreshold: 30,
          coreringDuration: 40,
          accelerationThreshold: 15,
          accelerationDuration: 50,
        },
        aisParameters: {
          mode: "DISABLE" as Rest.ModeAisGV300CAN,
          rangeSwitch: true,
          minThreshold: 0,
          maxThreshold: 30000,
          sampleRate: 0,
          voltageMarginError: 35,
        },
        disParameters: {
          mode: "HARD_WIRED" as Rest.ModeDisIgnGV300CAN,
          mpfDebounceTime: 0,
          enableD1: false,
          enableD2: false,
          debounceTimeD1: 0,
          debounceTimeD2: 0,
          validityTimeD1: 0,
          validityTimeD2: 0,
          samplePeriod: 0,
        },
        jbsParameters: {
          mode: false,
          sirenOnTimer: 1,
          sirenOffTimer: 1,
          readyFuelRealeseTimer: 1,
          checkSpeed: false,
          speedLimit: 0,
          output1InitState: false,
          motionSensor: false,
          gpsFixFailureTimeoutTimer: 1,
          enableSiren: false,
          releaseFuelCutOffTimer: 0,
          checkJamingInT3: false,
          wiatingReleaseFuelTimer: 0,
        } as Rest.JammingBehaviorSettingGV300CAN,
        towAlarm: {
          towEnable: false,
          engineOffToTow: 10, // Minutes
          fakeTowDelay: 1, // Minutes
          towInterval: 300, // Seconds
          restDuration: 2, // 1 - 255 (x15sec)
          motionDuration: 3, // 1 - 10 (x100ms)
          motionThreshold: 2, // 1 - 9
        } as Rest.TowAlarm,
        jammingDetection: {
          mode: "DISABLE" as Rest.JammingModeGV300CANEnum,
          signalThreshold: 25,
          jammingCellNumberThreshold: 5,
          enterJammingTimerThreshold: 10,
          quitJammingTimerThreshold: 10,
        } as Rest.JammingDetectionGV300CAN,
        startStopReport: {
          startStopReportMode: false,
          timeToStop: 2,
          timeToStart: 1,
          startSpeed: 5,
          loginStop: 0,
        } as Rest.StartStopReportGV300CAN,
        canParameters: {
          modeEnabled: false,
          canReportInterval: 0,
          canReportIgf: 0,
          vin: true,
          ignitionKey: true,
          totalDistance: true,
          totalFuelUsed: true,
          vehicleSpeed: true,
          engineRPM: true,
          engineCoolantTemperature: true,
          fuelConsumption: true,
          fuelLevel: true,
          range: true,
          acceleratorPedalPressure: true,
          totalEngineHours: true,
          totalDrivingTime: true,
          totalEngineIdleTime: true,
          totalIdleFuelUsed: true,
          axleWeight: true,
          tachographInformation: true,
          detailedInformation: true,
          lights: true,
          doors: true,
          totalVehicleOverspeedTime: true,
          totalVehicleEngineOverSpeedTime: true,
          totalDistanceImpulses: false,
          canReportExpansionMask: false,
          gnssInformation: true,
          gsmInformation: true,
          modeAdditionalEvent:
            "ignore_all_additional_event" as Rest.ModeCanAdditionalEventEnum,
          adBlueLevel: true,
          axleWeight1st: true,
          axleWeight3rd: true,
          axleWeight4th: true,
          tachographOverspeedSignal: true,
          tachographVehicleMotionSignal: true,
          tachographDrivingDirection: true,
          analogInputValue: true,
          engineBrakingFactor: true,
          pedalBrakingFactor: true,
          totalAcceleratorKickDowns: true,
          totalEffectiveEngineSpeedTime: true,
          totalCruiseControlTime: true,
          totalAcceleratorKickDownTime: true,
          totalBrakeApplication: true,
          tachographDriver1CardNumber: true,
          tachographDriver2CardNumber: true,
          tachographDriver1Name: true,
          tachographDriver2Name: true,
          registrationNumber: true,
          rapidBraking: true,
          rapidAccelerations: true,
          expansionInformation: true,
        } as Rest.CanbusDeviceConfigurationGV300CAN,
        idaParameters: {
          mode: "Disable" as Rest.ModeIDAGV300CANEnum,
          timeoutAfterIgnitionOff: 30,
          modeReport: "report_id_authorized",
          validityTime: 30,
          outputId: 0,
          outputStatus: 0,
          duration: 0,
          toggleTimes: 0,
        } as Rest.AuthenticationIdConfigurationGV300CAN,
      } as Rest.GV300CANParams,
    };
  }

  export function emptyGV300Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "GV300" as Rest.Types,
        globalParameters: {
          //powerSaving: Rest.PowerSavingEnum,
          powerSaving: "DISABLE" as Rest.PowerSavingEnum,
          eventMaskRESPGTPFA: true,
          eventMaskRESPGTMPN: true,
          eventMaskRESPGTMPF: true,
          eventMaskRESPGTBPL: true,
          eventMaskRESPGTBTC: true,
          eventMaskRESPGTSTC: true,
          eventMaskRESPGTSTT: true,
          eventMaskRESPGTANT: true,
          eventMaskRESPGTPDP: true,
          eventMaskRESPGTPNA: true,
          eventMaskPowerONRESPGTRTL: true,
          eventMaskIgnitionReportRESPGTIGNRESPGTIGFREPSGTVGN: true,
          eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: true,
          ledON: true,
          infoReportEnable: true,
          infoReportInterval: 300,
          gpslostTime: 0,
          gpsworkingMode: "GPS_GLONASS" as Rest.GPSWorkingModeEnum,
          gsmreportRESPGTLBC: true,
          gsmreportRESPGTTOW: true,
          gsmreportRESPGTFRIRESPGTERI: true,
          gsmreportRESPGTSOS: true,
          gsmreportMode: "NO_CELL_INFO_REPORT" as Rest.GSMReportModeEnum,
          gsmreportRESPGTRTL: true,
        },
        friParameters: {
          mode: "Disable" as Rest.ModeFRI,
          discardNoFix: false,
          checkInterval: 0,
          sendInterval: 5,
          distance: 50,
          mileage: 50,
          cornerReport: 0,
          iGFReportInterval: 0,
          digitalFuelSensorData: false,
          oneWireData: false,
          canData: false,
          percentage: false,
          volume: false,
          rfAccessoryData: false,
        },
        speedAlarmParameters: {
          mode: "DISABLE" as Rest.ModeSAEnum,
          minSpeed: 0,
          maxSpeed: 0,
          validity: 0,
          sendInterval: 30,
        },
        sosParameters: {
          modeSos: "DISABLE" as Rest.ModeSos,
          digitalInputID: 0,
          sosNumber: "",
        },
        hbmParameters: {
          mode: "DISABLE" as Rest.ModeHBM,
          highSpeed: 100,
          mediumSpeed: 60,
          vha: 0,
          vhb: 0,
          vma: 0,
          vmb: 0,
          vla: 0,
          vlb: 0,
          corneringThreshold: 30,
          coreringDuration: 40,
          accelerationThreshold: 15,
          accelerationDuration: 50,
        },
        aisParameters: {
          mode: "DISABLE" as Rest.ModeAis,
          minThreshold: 0,
          maxThreshold: 30000,
          sampleRate: 0,
          mode2: "DISABLE" as Rest.ModeAis,
          minThreshold2: 0,
          maxThreshold2: 30000,
          sampleRate2: 0,
          voltageMarginError: 35,
        },
        disParameters: {
          mode: "HARD_WIRED" as Rest.ModeDisIgn,
          enableD1: false,
          enableD2: false,
          debounceTimeD1: 0,
          debounceTimeD2: 0,
          validityTimeD1: 0,
          validityTimeD2: 0,
          samplePeriod: 0,
        },
        jbsParameters: {
          mode: false,
          sirenOnTimer: 1,
          sirenOffTimer: 1,
          readyFuelRealeseTimer: 1,
          checkSpeed: false,
          speedLimit: 0,
          output1InitState: false,
          motionSensor: false,
          gpsFixFailureTimeoutTimer: 1,
          enableSiren: false,
          releaseFuelCutOffTimer: 0,
          checkJamingInT3: false,
          wiatingReleaseFuelTimer: 0,
        } as Rest.JammingBehaviorSetting,
        towAlarm: {
          towEnable: false,
          engineOffToTow: 10, // Minutes
          fakeTowDelay: 1, // Minutes
          towInterval: 300, // Seconds
          restDuration: 2, // 1 - 255 (x15sec)
          motionDuration: 3, // 1 - 10 (x100ms)
          motionThreshold: 2, // 1 - 9
        } as Rest.TowAlarm,
        jammingDetection: {
          mode: "DISABLE" as Rest.JammingModeEnum,
          signalThreshold: 25,
          jammingCellNumberThreshold: 5,
          enterJammingTimerThreshold: 10,
          quitJammingTimerThreshold: 10,
        } as Rest.JammingDetection,
        startStopReport: {
          startStopReportMode: false,
          timeToStop: 2,
          timeToStart: 1,
          startSpeed: 5,
          loginStop: 0,
        } as Rest.StartStopReport,
        canParameters: {
          modeEnabled: false,
          canReportInterval: 0,
          canReportIgf: 0,
          vin: true,
          ignitionKey: true,
          totalDistance: true,
          totalFuelUsed: true,
          vehicleSpeed: true,
          engineRPM: true,
          engineCoolantTemperature: true,
          fuelConsumption: true,
          fuelLevel: true,
          range: true,
          acceleratorPedalPressure: true,
          totalEngineHours: true,
          totalDrivingTime: true,
          totalEngineIdleTime: true,
          totalIdleFuelUsed: true,
          axleWeight: true,
          tachographInformation: true,
          detailedInformation: true,
          lights: true,
          doors: true,
          totalVehicleOverspeedTime: false,
          totalVehicleEngineOverSpeedTime: false,
          totalDistanceImpulses: false,
          canReportExpansionMask: false,
          gpsInformation: true,
          gsmInformation: true,
          modeAdditionalEvent:
            "ignore_all_additional_event" as Rest.ModeCanAdditionalEventEnum,
          adBlueLevel: true,
          axleWeight1st: true,
          axleWeight3rd: true,
          axleWeight4th: true,
          tachographOverspeedSignal: true,
          tachographVehicleMotionSignal: true,
          tachographDrivingDirection: true,
          analogInputValue: true,
          engineBrakingFactor: true,
          pedalBrakingFactor: true,
          totalAcceleratorKickDowns: true,
          totalEffectiveEngineSpeedTime: true,
          totalCruiseControlTime: true,
          totalAcceleratorKickDownTime: true,
          totalBrakeApplication: true,
          tachographDriver1CardNumber: true,
          tachographDriver2CardNumber: true,
          tachographDriver1Name: true,
          tachographDriver2Name: true,
          registrationNumber: true,
          rapidBraking: false,
          rapidAccelerations: false,
          engineTorque: false,
          expansionInformation: true,
        } as Rest.CanbusDeviceConfiguration,
        idaParameters: {
          mode: "Disable" as Rest.ModeIDAEnum,
          timeoutAfterIgnitionOff: 30,
          modeReport: "report_id_authorized",
          validityTime: 30,
          outputId: 0,
          outputStatus: 0,
          duration: 0,
          toggleTimes: 0,
          modeReportRfid: "report_latest_rfid_after_getting_card_id",
        } as Rest.AuthenticationIdConfiguration,
      } as Rest.GV300Params,
    } as Rest.DeviceParameters;
  }

  export function emptyGV800Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "GV800" as Rest.Types,
        globalParameters: {
          powerSaving: "MODE1" as Rest.PowerSavingEnumGV800,
          eventMaskRESPGTPNA: true,
          eventMaskRESPGTPFA: true,
          eventMaskRESPGTMPN: true,
          eventMaskRESPGTMPF: true,
          eventMaskRESPGTBPL: true,
          eventMaskRESPGTBTC: true,
          eventMaskRESPGTSTC: true,
          eventMaskRESPGTSTT: true,
          eventMaskRESPGTANT: true,
          eventMaskRESPGTPDP: false,
          eventMaskRESPGTPNL: true,
          eventMaskIgnitionOnRESPGTIGNGTIGF: true,
          eventMaskIgnitionOnOffRESPGTIGL: true,
          eventMaskGTEXP: false,
          ledON: false,
          infoReportEnable: false,
          infoReportInterval: 300,
          gsmreportRESPGTFRIRESPGTERI: true,
          gsmreportRESPGTLBC: true,
          gsmreportRESPGTRTL: true,
          gsmreportRESPGTSOS: true,
          gsmreportRESPGTTOW: true,
          gpslostTime: 0,
          wakeUpHourInterval: 24,
          sensorWakeUpDebounce: 10,
          workTimeWakeUp: 5,
          oneWireSamplePeriod: 0,
          oneWireInformation: false,
          gsmreportMode: "NO_CELL_INFO_REPORT" as Rest.GSMReportModeGV800Enum,
        } as Rest.GlobalParametersGV800,
        friParameters: {
          mode: "Disable" as Rest.ModeFRIGV800,
          discardNoFix: true,
          ignReportInterval: 30,
          distance: 1000,
          mileage: 1000,
          cornerReport: 0,
          iGFReportInterval: 600,
          rs485FuelSensorData: false,
          oneWireData: false,
          percentage: false,
          volume: false,
          rfAccessoryData: false,
          rpmInput: false,
        } as Rest.FixedReportInformationGV800,
        towAlarm: {
          towEnable: false,
          engineOffToTow: 10,
          fakeTowDelay: 1,
          towInterval: 300,
          restDuration: 2,
          motionDuration: 3,
          motionThreshold: 3,
        } as Rest.TowAlarmGV800,
        speedAlarmParameters: {
          mode: "DISABLE" as Rest.ModeSAEnumGV800,
          minSpeed: 0,
          maxSpeed: 0,
          validity: 60,
          sendInterval: 600,
        } as Rest.SpeedAlarmParametersGV800,
        sosParameters: {
          modeSos: "DISABLE" as Rest.ModeSOSGV800,
          digitalInputID: 0,
        } as Rest.SOSParametersGV800,
        hbmParameters: {
          mode: "DISABLE" as Rest.ModeHBMGV800,
          highSpeed: 100,
          vhb: 0,
          vha: 0,
          mediumSpeed: 60,
          vmb: 0,
          vma: 0,
          vlb: 0,
          vla: 0,
          corneringThreshold: 30,
          coreringDuration: 50,
          accelerationThreshold: 20,
          accelerationDuration: 65,
        } as Rest.HarshBehaviorMonitoringGV800,
        startStopReport: {
          startStopReportMode: false,
          timeToStop: 2,
          timeToStart: 1,
          startSpeed: 5,
          loginStop: 0,
        } as Rest.StartStopReportGV800,
        aisParameters: {
          analogInputId: 0,
          mode: "DISABLE" as Rest.ModeAisGV800,
          minThreshold: 0,
          maxThreshold: 30000,
          sampleRate: 0,
        } as Rest.AnalogInputPortSettingGV800,
        disParameters: {
          ignitionDetection: false,
          enableD1: false,
          debounceTimeD1: 0,
          validityTimeD1: 0,
          enableD2: false,
          debounceTimeD2: 0,
          validityTimeD2: 0,
          enableD3: false,
          debounceTimeD3: 0,
          validityTimeD3: 0,
          enableD4: false,
          debounceTimeD4: 0,
          validityTimeD4: 0,
          enableD5: false,
          debounceTimeD5: 0,
          validityTimeD5: 0,
        } as Rest.DigitalInputPortSettingsGV800,
        canParameters: {
          modeEnabled: false,
          canReportInterval: 0,
          modeAdditionalEvent:
            "ignore_all_additional_event" as Rest.ModeCanAdditionalEventEnumGV800,
          vin: true,
          ignitionKey: true,
          totalDistance: true,
          totalFuelUsed: true,
          vehicleSpeed: true,
          engineRPM: true,
          engineCoolantTemperature: true,
          fuelConsumption: true,
          fuelLevel: true,
          range: true,
          acceleratorPedalPressure: true,
          totalEngineHours: true,
          totalDrivingTime: true,
          totalEngineIdleTime: true,
          totalIdleFuelUsed: true,
          axleWeight: true,
          tachographInformation: true,
          detailedInformation: true,
          lights: true,
          doors: true,
          totalVehicleOverspeedTime: false,
          totalVehicleEngineOverSpeedTime: false,
          totalDistanceImpulses: false,
          canReportExpansionMask: false,
          gpsInformation: true,
          gsmInformation: true,
          adBlueLevel: true,
          axleWeight1st: true,
          axleWeight3rd: true,
          axleWeight4th: true,
          tachographOverspeedSignal: true,
          tachographVehicleMotionSignal: true,
          tachographDrivingDirection: true,
          analogInputValue: true,
          engineBrakingFactor: true,
          pedalBrakingFactor: true,
          totalAcceleratorKickDowns: true,
          totalEffectiveEngineSpeedTime: true,
          totalCruiseControlTime: true,
          totalAcceleratorKickDownTime: true,
          totalBrakeApplication: true,
          tachographDriver1CardNumber: true,
          tachographDriver2CardNumber: true,
          tachographDriver1Name: true,
          tachographDriver2Name: true,
          registrationNumber: true,
          expansionInformation: true,
          rapidBraking: false,
          rapidAccelerations: false,
        } as Rest.CanbusDeviceConfigurationGV800,
        idaParameters: {
          mode: "Disable",
          timeoutAfterIgnitionOff: 30,
          modeReport: "report_id_authorized",
          validityTime: 30,
          outputId: 0,
          outputStatus: false,
          duration: 0,
          toggleTimes: 0,
        } as Rest.AuthenticationIdConfigurationGV800,
      } as Rest.GV800Params,
    } as Rest.DeviceParameters;
  }

  export function initializeGV800Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyGV800Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeEVIXParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptyEVIXParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeIofrogParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptyIofrogParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeTeltonikaParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptyTeltonikaParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeSmartCoffeeParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptySmartCoffeeParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function initializeGMT200Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyGMT200Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);

    /*
       Object.keys(defaultConf).forEach(function (prop) {
            if (conf[prop] == null) {conf[prop] =  defaultConf[prop]}
        return conf;
    })*/
  }

  export function emptyEVIXParams() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "EVIX" as Rest.Types,
      } as Rest.EVIXParams,
    } as Rest.DeviceParameters;
  }

  export function emptySmartCoffeeParams() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "SmartCoffee" as Rest.Types,
      } as Rest.SmartCoffeeParams,
    } as Rest.DeviceParameters;
  }

  export function emptyIofrogParams(){
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "Iofrog" as Rest.Types,
      } as Rest.IofrogParams,
    } as Rest.DeviceParameters;
  }

  export function emptyTeltonikaParams(){
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "Teltonika" as Rest.Types,
      } as Rest.TeltonikaParams,
    } as Rest.DeviceParameters;
  }

  export function emptyGMT200Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "GMT200" as Rest.Types,
        globalParameters: {
          powerSaving: "DISABLE" as Rest.PowerSavingEnumGMT200,
          eventMaskRESPGTPFA: false,
          eventMaskRESPGTMPN: false,
          eventMaskRESPGTMPF: false,
          eventMaskRESPGTBPL: false,
          eventMaskRESPGTBTC: false,
          eventMaskRESPGTBPN: false,
          eventMaskRESPGTBPF: false,
          eventMaskRESPGTSTC: false,
          eventMaskRESPGTSTT: false,
          eventMaskRESPGTPDP: false,
          eventMaskRESPGTPNA: false,
          eventMaskPowerONRESPGTRTL: false,
          eventMaskIgnitionReportRESPGTIGNRESPGTIGFRESPGTVGNRESPGTVGF: false,
          eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL: false,
          eventMaskRESPGTCID: false,
          ledON:
            "EACH_TIME_DEVICE_POWER_ON_LED_AND_GNSS_LED_WORK_30_MINUTES_AND_TURN_OFF" as Rest.LedOnEnumGMT200,
          infoReportEnable: false,
          infoReportInterval: 300,
          powerMode: "CHARGE_IGNITION_ON" as Rest.PowerModeEnumGMT200,
          speed:true,
          azimuth:true,
          altitude:true,
          gsmTowerData:true,
          mileage:true,
          sendTime:true,
          deviceName:false,
          cellInfoReport: "DISABLE" as Rest.CellInfoReportEnumGMT200,
          agpsMode: false,
          locationCall:"CALL" as Rest.LocationCallEnumGMT200
        } as Rest.GlobalParametersGMT200,
        friParameters: {
          mode: "Disable" as Rest.ModeFRIGMT200,
          discardNoFix: true,
          sendInterval: 30,
          distance: 1000,
          mileage: 1000,
          cornerReport: 0,
          igfReportInterval: 600,
          periodEnable:true,
          startTime:"0000",
          endTime:"0000"
        } as Rest.FixedReportInformationGMT200,
        speedAlarmParameters: {
          mode: "DISABLE" as Rest.ModeSAEnumGMT200,
          minSpeed: 0,
          maxSpeed: 0,
          duration: 60,
          sendInterval: 300,
          outputId:0,
          outputStatus:false,
          durationOutput:0,
          toggleTimes:0
        } as Rest.SpeedAlarmParametersGMT200,
        sosParameters: {
          modeSos: "DISABLE" as Rest.ModeSosGMT200,
          digitalInputId: 0,
          sosNumber: "",
          outputId:0,
          outputStatus:false
        } as Rest.SOSParametersGMT200,
        hbmParameters: {
          modeEnabled: false,
          highSpeed: 100,
          mediumSpeed: 60,
          vha: 0,
          vhb: 0,
          vma: 0,
          vmb: 0,
          vla: 0,
          vlb: 0,
          outputId:0,
          outputStatus:false
        } as Rest.HarshBehaviorMonitoringGMT200,
        disParameters: {
          enable: false,
          mpfDebounceTime: 0,
          debounceTime: 0,
          validityTime: 0,
          noIgnition: false,
          samplePeriod: 1,
        } as Rest.DigitalInputPortSettingsGMT200,
        jbsParameters: {
          modeEnabled: false,
          speedLimit: 30,
          motionSensor: true,
          gnssFixFailureTimer: 120,
          delayDuration: 60,
          fuelCutOffTimer: 3600,
          releaseFuelCutOffTimer:0,
          checkJamming:false,
          waitingReleaseFuelTimer:0,
        } as Rest.JammingBehaviorSettingGMT200,
        towParameters: {
          modeTow:"DISABLE" as Rest.ModeTOWEnumGMT200,
          engineOffToTow: 60, // Seconds
          fakeTowDelay: 60, // Seconds
          towInterval: 300, // Seconds
          restDuration: 2, // 1 - 1800 (x15sec)
          motionDuration: 3, // 1 - 10 (x100ms)
          motionThreshold: 2, // 1 - 9
          outputId:0,
          outputStatus:false,
          duration:0,
          toggleTimes:0
        } as Rest.TowAlarmGMT200,
        jammingDetection: {
          mode: "DISABLE" as Rest.JammingModeEnumGMT200,
          signalThreshold: 25,
          jammingCellNumberThreshold: 5,
          enterJammingTimerThreshold: 10,
          quitJammingTimerThreshold: 10,
          outputId:0,
          outputStatus:false,
        } as Rest.JammingDetectionGMT200,
        startStopReport: {
          startStopReportMode: false,
          timeToStop: 2,
          timeToStart: 1,
          startSpeed: 5,
        } as Rest.StartStopReportGMT200,
        commandStorage: {
          mode: "DELETE_COMMAND" as Rest.ModeCommandStorageEnumGMT200,
          command:null,
        } as Rest.CommandStorageGMT200,
        externalPowerSupply: {
          mode: "ENABLE_OUTSIDE_RANGE" as Rest.ModeEPSEnumGMT200,
          minThreshold: 9500,
          maxThreshold: 15000,
          samplePeriod: 1,
          debounceTime: 1,
          outputId: 0,
          outputStatus: false,
          syncFri: false,
          validity: 1,
        } as Rest.ExternalPowerSupplyGMT200,
        userDefinedFunction: {
          mode: "DISABLE_FUNCTIONS" as Rest.ModeUDFEnumGMT200,
          powerOnFinished: false,
          ignitionOn: false,
          ignitionOff: false,
          gprsnetwork: false,
          noGPRSNetwork: false,
          gsmnetwork: false,
          noGSMNetwork: false,
          networkRoaming: false,
          networkNonRoaming: false,
          simCardLocked: false,
          gnssOn: false,
          gnssOff: false,
          deviceStationary: false,
          deviceMoving: false,
          externalCharge: false,
          noExternalCharge: false,
          digitalInput1Low: false,
          digitalInput1High: false,
          simCardInserted: false,
          simCardNotInserted: false,
          insideSpeedRange: false,
          outsideSpeedRange: false,
          messageNeedToBeSent: false,
          messageNoNeedToBeSent: false,
          towStatus: false,
          epsAlarmActivated: false,
          epsAlarmDesactivated: false,
          debounceTime: 0,
          insideGeo0: false,
          insideGeo1: false,
          insideGeo2: false,
          insideGeo3: false,
          insideGeo4: false,
          outsideGeo0: false,
          outsideGeo1: false,
          outsideGeo2: false,
          outsideGeo3: false,
          outsideGeo4: false,
          modeStoCmd: "NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdEnumGMT200,
          stocmd0:false,
          stocmd1:false,
          stocmd2:false,
          stocmd3:false,
          stocmd4:false,
          stocmd5:false,
          stocmd6:false,
          stocmd7:false,
          stocmd8:false,
          stocmd9:false,
          stocmd10:false,
          stocmd11:false,
          stocmd12:false,
          stocmd13:false,
          stocmd14:false,
          stocmd15:false,
          stocmd16:false,
          stocmd17:false,
          stocmd18:false,
          stocmd19:false,
          stocmd20:false,
          stocmd21:false,
          stocmd22:false,
          stocmd23:false,
          stocmd24:false,
          stocmd25:false,
          stocmd26:false,
          stocmd27:false,
          stocmd28:false,
          stocmd29:false,
          stocmd30:false,
          stocmd31:false,
          preserveExecutedState:false,
          networkJammingIsDetected:false,
          networkJammingIsNotDetected:false
        } as Rest.UserDefinedFunctionGMT200,
        geoParameters:{
          mode:"DISABLE" as Rest.ModeGEOEnumGMT200,
          radius:50,
          checkInterval:0,
          outputId:0,
          outputStatus:false,
          modeState:"REPORT_STATE_FIRST_TIME" as Rest.ModeGEOStateEnumGMT200,
          modeTrigger:"DISABLE" as Rest.ModeGEOTriggerEnumGMT200,
          modeReportTrigger:"DISABLE_GTGES" as Rest.ModeGEOReportTriggerEnumGMT200,
          useLastPoint:"DISABLE" as Rest.ModeGEOLastPointGMT200,
        } as Rest.GeoFenceParametersGMT200,
        sriParameters:{
          mode:"STOP" as Rest.ModeReportSRIEnumGMT200,
          buffer:"LOW_PRIORITY" as Rest.ModeBufferSRIEnumGMT200,
          heartbeatInterval:0,
          sackEnable:false,
          protocolFormat:false,
          alwaysOnConnection:false,
          enableSmsAck:false,
          backupServerIp:null,
          mainServerIp:null,
          smsGateway:null,
        } as Rest.ServerRegistrationInformationGMT200,
        outParameters:{
          status:false,
          duration:0,
          toggleTimes:0,
          output1NotReport:false,
        } as Rest.DigitalOutputPortSettingsGMT200,
        bsiParameters:{
          apn:"",
          apnUsername:"",
          apnPassword:"",
        } as Rest.BearerSettingInformationGMT200,
        dogParameters:{
          mode:"DISABLE" as Rest.ModeDOGEnumGMT200,
          ignitionFrequency:60,
          interval:30,
          time:"0200",
          reportBeforeReboot:true,
          digitalInputId:0,
          gsmInterval:60,
          pdpInterval:60,
          pdpResetInterval:30,
          failInterval:0
        } as Rest.ProtocolWatchdogGMT200,
        pdsParameters:{
          mode:"DISABLE" as Rest.ModePDSEnumGMT200,
          statesOfGeo:false,
          informationLastKnownPosition:false,
          ignitionOnOffStatus:false,
          stateCutOffRelayOutput:false,
          stateDigitalInput1:false,
          stateMainPower:false,
          stateMotion:false,
        } as Rest.PreservingDeviceLogicStatesGMT200,
        ffcParameters:{
          priority:0,
          mode:"DISABLE",
        } as Rest.FrequencyChangeFriGMT200,
        idlParameters:{
          mode:"DISABLE" as Rest.ModeIDLEnumGMT200,
          timeToIdling:2,
          timeToMovement:1,
          outputId:0,
          outputStatus:false,
        } as Rest.ExcessiveIdlingDetectionGMT200,
        craParameters:{
          mode:"DISABLE",
          thresholdX:50,
          thresholdY:50,
          thresholdZ:50,
          samplingStartMode:"ACCELERATION_AFTER_IGNITION_ON" as Rest.ModeCRASamplingStartEnumGMT200,
          samplesBeforeCrash:1500,
          samplesAfterCrash:1500,
          outputId:0,
          outputStatus:false,
          reportAcc:false,
          speedConfirmation:20,
          odoConfirmation:100,
          confirmationTime:10,
          fallDegrees:60
        } as Rest.CrashDetectionGMT200,
        ascParameters:{
          brakingSpeedThreshold:50,
          deltaSpeedThreshold:10,
          deltaHeadingThreshold:2,
          outputId:0,
          outputStatus:false,
        } as Rest.AxisCalibrationGMT200,
        iobParameters:{
          iobId:0,
          inputIgnitionDetection:false,
          inputDigitalInput1:false,
          triggerDigitalInput1:false,
          triggerIgnitionDetection:false,
          outputStatus:false,
          samplePeriod:0,
          outputId:0,
        } as Rest.OutputPortBindingGMT200,
        vmsParameters:{
          mode:"DISABLE",
          voltageVirtualIgnitionOffDetection:true,
          voltageVirtualIgnitionOnDetection:true,
          motionStatusVirtualIgnitionOffDetection:true,
          motionStatusVirtualIgnitionOnDetection:true
        } as Rest.VirtualIgnitionSelectionGMT200,
        vvsParameters:{
          ignitionOnVoltage:13500,
          voltageOffset:600,
          ignitionOnDebounce:10,
          mode:"ENABLE" as Rest.MODEVVSEnumGMT200,
          ignitionOffDebounce:10
        } as Rest.VoltageVirtualIgnitionGMT200,
        avsParameters:{
          restDuration:20,
          motionValidity:60
        } as Rest.AccelerometerVirtualIgnitionSettingsGMT200,
      } as Rest.GMT200Params,
    } as Rest.DeviceParameters;
  }

  export function initializeConcoxMT200Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyConcoxMT200Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function emptyConcoxMT200Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "ConcoxMT200" as Rest.Types,
        alarmMoving: {
          enabled: false,
          movingRadius: 300,
          mode: "SMS_GPRS" as Rest.ModeAlarmMovingEnumConcoxMT200,
        } as Rest.AlarmMovingConfigurationConcoxMT200,
        angle: {
          enabled: true,
          angleDegree: 30,
          detectionTime: 3,
        } as Rest.AngleConfigurationConcoxMT200,
        batteryAlarm: {
          enabled: true,
          modeAlarm: "GPRS_SMS" as Rest.ModeBatteryAlarmEnumConcoxMT200,
        } as Rest.BatteryAlarmConfigurationConcoxMT200,
        apn: {
          name: "",
        } as Rest.APNConfigurationConcoxMT200,
        defense: {
          delayDuration: 10,
        } as Rest.DefenseConfigurationConcoxMT200,
        externalBatteryAlarm: {
          enabled: false,
          modeAlarm: "GPRS" as Rest.ModeBatteryAlarmEnumConcoxMT200,
          power1: 128,
          power2: 138,
          timeDuration: 10,
        } as Rest.ExternalBatteryAlarmConfigurationConcoxMT200,
        externalBatteryProtection: {
          enabled: true,
          modeAlarm: "GPRS" as Rest.ModeBatteryAlarmEnumConcoxMT200,
          power: 125,
          timeDuration: 10,
        } as Rest.ExternalBatteryProtectionConfigurationConcoxMT200,
        gmt: {
          hemisphereTimeZone: "E",
          timeZoneHour: 8,
          halfTimeZone: 0,
        } as Rest.GMTConfigurationConcoxMT200,
        gprsAlarm: {
          enabled: false,
        } as Rest.GPRSBlockingAlarmConfigurationConcoxMT200,
        gprs: {
          enabled: true,
        } as Rest.GPRSConfigurationConcoxMT200,
        heartbeatInterval: {
          interval1: 5,
          interval2: 5,
        } as Rest.HeartBeatIntervalConfigurationConcoxMT200,
        hst: {
          speed: 30, //Aquest valor no surt a la documentació
        } as Rest.HSTConfigurationConcoxMT200,
        instructionPassword: {
          password: "666666",
          enabled: false,
        } as Rest.InstructionPasswordConfigurationConcoxMT200,
        mileage: {
          enabled: false,
          mileage: 0,
        } as Rest.MileageConfigurationConcoxMT200,
        powerAlarm: {
          enabled: true,
          modeAlarm: "GPRS_SMS_PHONECALL" as Rest.ModePowerAlarmEnumConcoxMT200,
          detectionTime: 5,
          minChargingTime: 1,
          stepAlarmProhibitionTime: 0,
        } as Rest.PowerAlarmConfigurationConcoxMT200,
        relay: {
          cutOff: false,
        } as Rest.RelayConfigurationConcoxMT200,
        reviseInstructionPassword: {
          oldPassword: "666666",
          newPassword: "666666",
        } as Rest.ReviseInstructionPasswordConfigurationConcoxMT200,
        sensor: {
          detectionTime: 10,
          alertDelay: 30,
          vibrationAlertInterval: 1,
        } as Rest.SensorConfigurationConcoxMT200,
        server: {
          addressMode: "DOMAIN_NAME" as Rest.ModeServerEnumConcoxMT200,
          address: "",
          port: 0,
          protocolMode: "TCP" as Rest.ModeProtocolServerEnumConcoxMT200,
        } as Rest.ServerConfigurationConcoxMT200,
        sos: {
          mode: "ADD" as Rest.ModeSOSEnumConcoxMT200,
          phoneNumbers: "",
        } as Rest.SOSConfigurationConcoxMT200,
        staticDataFiltering: {
          enabled: true,
          maxFilteringDistance: 100,
        } as Rest.StaticDataFilteringConfigurationConcoxMT200,
        vibrationAlarm: {
          enabled: false,
          modeAlarm:
            "GPRS_SMS_PHONECALL" as Rest.ModeVibrationAlarmEnumConcoxMT200,
        } as Rest.VibrationAlarmConfigurationConcoxMT200,
        sensorSet: {
          detectionTime: 10,
          vibrationTimeGps: 3,
          vibrationTimeAlarm: 5,
          workingTime: 1,
        } as Rest.SensorSetConfigurationConcoxMT200,
        gpsDataSendingInterval: {
          timer1: 10,
          timer2: 10,
        } as Rest.GPSDataSendingIntervalConfigurationConcoxMT200,
        alarmCallNumberTimes: {
          times: 3,
        } as Rest.AlarmCallNumberTimesConfigurationConcoxMT200,
        alarmOverSpeed: {
          enabled: false,
          intervalTime: 20,
          speedLimit: 50,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmEnumConcoxMT200,
        } as Rest.AlarmOverSpeedConfigurationConcoxMT200,
        automaticApn: {
          enabled: true,
        } as Rest.AutomaticAPNConfigurationConcoxMT200,
        centerPhoneNumber: {
          mode: "ADD" as Rest.ModeSOSEnumConcoxMT200,
          phoneNumber: "",
        } as Rest.CenterPhoneNumberConfigurationConcoxMT200,
        externalVoltageUpload: {
          enabled: false,
          uploadInterval: 600,
        } as Rest.ExternalVoltageUploadConfigurationConcoxMT200,
        fenceAlarm: {
          enabled: false,
          mode: "CIRCLE_AREA" as Rest.ModeFenceEnumConcoxMT200,
          circle: {
            latitudeCenter: 0,
            longitudeCenter: 0,
            radius: 100,
          } as Rest.CircleAreaConcoxMT200,
          rectangle: {
            latitude1: 0,
            longitude1: 0,
            latitude2: 0,
            longitude2: 0,
          } as Rest.RectangleAreaConcoxMT200,
          modeAlarmFence: null as Rest.ModeAlarmFenceEnumConcoxMT200,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmEnumConcoxMT200,
        } as Rest.FenceAlarmConfigurationConcoxMT200,
        gpsDistanceInterval: {
          distanceInterval: 300,
        } as Rest.GPSDistanceIntervalConfigurationConcoxMT200,
        networkLinkCoordinates: {
          networkLink: "http://maps.google.com/maps?q=",
        } as Rest.NetworkLinkCoordinatesConfigurationConcoxMT200,
        sensorSensitivity: {
          sensitivityGrade: 2,
        } as Rest.SensorSensitivityConfigurationConcoxMT200,
      } as Rest.ConcoxMT200Params,
    } as Rest.DeviceParameters;
  }

  export function initializeOB22Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyOB22Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function emptyOB22Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "OB22" as Rest.Types,
        angle: {
          enabled: true,
          angleDegree: 20,
          detectionTime: 3,
        } as Rest.AngleConfigurationOB22,
        batteryAlarm: {
          enabled: true,
          modeAlarm: "GPRS_SMS" as Rest.ModeBatteryAlarmEnumOB22,
        } as Rest.BatteryAlarmConfigurationOB22,
        apn: {
          name: "",
        } as Rest.APNConfigurationOB22,
        gmt: {
          hemisphereTimeZone: "E",
          timeZoneHour: 8,
          halfTimeZone: 0,
        } as Rest.GMTConfigurationOB22,
        gprsAlarm: {
          enabled: false,
        } as Rest.GPRSBlockingAlarmConfigurationOB22,
        gprs: {
          enabled: true,
        } as Rest.GPRSConfigurationOB22,
        heartbeatInterval: {
          interval1: 3,
          interval2: 3,
        } as Rest.HeartbeatIntervalConfigurationOB22,
        instructionPassword: {
          password: "000000",
          enabled: false,
        } as Rest.InstructionPasswordConfigurationOB22,
        mileage: {
          enabled: false,
          mileage: 0,
        } as Rest.MileageConfigurationOB22,
        powerAlarm: {
          enabled: true,
          modeAlarm: "SMS_GPRS" as Rest.ModePowerAlarmEnumOB22,
          detectionTime: 5,
          minChargingTime: 1,
        } as Rest.PowerAlarmConfigurationOB22,
        reviseInstructionPassword: {
          oldPassword: "000000",
          newPassword: "000000",
        } as Rest.ReviseInstructionPasswordConfigurationOB22,
        server: {
          addressMode: "DOMAIN_NAME" as Rest.ModeServerEnumOB22,
          address: "",
          port: 0,
          protocolMode: "TCP" as Rest.ModeProtocolServerEnumOB22,
        } as Rest.ServerConfigurationOB22,
        sos: {
          mode: "ADD" as Rest.ModeSOSEnumOB22,
          phoneNumbers: "",
        } as Rest.SOSConfigurationOB22,
        staticDataFiltering: {
          enabled: true,
          maxFilteringDistance: 100,
        } as Rest.StaticDataFilteringConfigurationOB22,
        gpsDataSendingInterval: {
          timer1: 10,
          timer2: 10,
        } as Rest.GPSDataSendingIntervalConfigurationOB22,
        alarmCallNumberTimes: {
          times: 3,
        } as Rest.AlarmCallNumberTimesConfigurationOB22,
        alarmOverSpeed: {
          enabled: false,
          intervalTime: 20,
          speedLimit: 100,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmEnumOB22,
        } as Rest.AlarmOverSpeedConfigurationOB22,
        automaticApn: {
          enabled: true,
        } as Rest.AutomaticAPNConfigurationOB22,
        fenceAlarm: {
          enabled: false,
          mode: "CIRCLE_AREA" as Rest.ModeFenceEnumOB22,
          circle: {
            latitudeCenter: 0,
            longitudeCenter: 0,
            radius: 100,
          } as Rest.CircleAreaOB22,
          rectangle: {
            latitude1: 0,
            longitude1: 0,
            latitude2: 0,
            longitude2: 0,
          } as Rest.RectangleAreaOB22,
          modeAlarmFence: null as Rest.ModeAlarmFenceEnumOB22,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmEnumOB22,
        } as Rest.FenceAlarmConfigurationOB22,
        gpsDistanceInterval: {
          distanceInterval: 300,
        } as Rest.GPSDistanceIntervalConfigurationOB22,
        networkLinkCoordinates: {
          networkLink: "http://maps.google.com/maps?q=",
        } as Rest.NetworkLinkCoordinatesConfigurationOB22,
        sensorSensitivity: {
          sensitivityGrade: 2,
        } as Rest.SensorSensitivityConfigurationOB22,
        automaticGmt:{
          enabled:false,
        } as Rest.AutomaticAPNConfigurationOB22,
        uploadAcc:{
          enabled: true,
        } as Rest.UploadACCConfigurationOB22,
        batch:{
          enabled: false,
          numberMessages: 10,
        } as Rest.BatchConfigurationOB22,
        sends:{
          timeDuration:5,
        } as Rest.SendsConfigurationOB22,
        delay:{
          time: 10,
        } as Rest.DelayVoiceMonitorConfigurationOB22,
        staticRep:{
          enabled: true,
          timeRange: 10,
          minDetectionSpeed:0,
          detectingTimes:1,
        } as Rest.StaticLastPositionConfigurationOB22,
        smsForwarding:{
          phoneNumber:"",
          content:"",
        } as Rest.SMSForwardingConfigurationOB22,
        lbsUpload:{
          enabled:false,
        } as Rest.LBSUploadConfigurationOB22,
        pullAlarm:{
          enabled:true,
          mode: "SMS_GPRS" as Rest.ModePullAlarmEnumOB22,
          detectionTime: 20,
          acceleration:30,
        } as Rest.PullAlarmConfigurationOB22
      } as Rest.OB22Params,
    } as Rest.DeviceParameters;
  }

  export function initializeWeTrack2Params(conf: Rest.DeviceParameters) {
    var defaultConf = emptyWeTrack2Params();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function emptyWeTrack2Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "WeTrack2" as Rest.Types,
        alarmMoving: {
          enabled: false,
          movingRadius: 300,
          mode: "SMS_GPRS" as Rest.ModeAlarmMovingWeTrack2,
        } as Rest.AlarmMovingConfigurationWeTrack2,
        angle: {
          enabled: true,
          angleDegree: 30,
          detectionTime: 3,
        } as Rest.AngleConfigurationWeTrack2,
        batteryAlarm: {
          enabled: true,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmMovingWeTrack2,
        } as Rest.BatteryAlarmConfigurationWeTrack2,
        apn: {
          name: "",
        } as Rest.APNConfigurationWeTrack2,
        defense: {
          delayDuration: 10,
        } as Rest.DefenseConfigurationWeTrack2,
        externalBatteryAlarm: {
          enabled: true,
          modeAlarm: "SMS_GPRS" as Rest.ModeExternalBatteryAlarmWeTrack2,
          power1: 128,
          power2: 138,
          timeDuration: 10,
        } as Rest.ExternalBatteryAlarmConfigurationWeTrack2,
        externalBatteryProtection: {
          enabled: true,
          modeAlarm: "GPRS" as Rest.ModeAlarmWeTrack2,
          voltageCutOff: 125,
          voltageReconnecting: 130,
          timeDuration: 10,
        } as Rest.ExternalBatteryProtectionConfigurationWeTrack2,
        gmt: {
          hemisphereTimeZone: "E",
          timeZoneHour: 8,
          halfTimeZone: 0,
        } as Rest.GMTConfigurationWeTrack2,
        gprsBlockingAlarm: {
          enabled: false,
        } as Rest.GPRSBlockingAlarmConfigurationWeTrack2,
        heartbeatInterval: {
          interval1: 5,
          interval2: 5,
        } as Rest.HeartBeatIntervalConfigurationWeTrack2,
        hst: {
          speed: 30, //Aquest valor no surt a la documentació
        } as Rest.HSTConfigurationWeTrack2,
        mileage: {
          enabled: false,
          mileage: 0,
        } as Rest.MileageConfigurationWeTrack2,
        powerAlarm: {
          enabled: true,
          modeAlarm: "GPRS_SMS_PHONECALL" as Rest.ModePowerAlarmWeTrack2,
          detectionTime: 5,
          minChargingTime: 300,
        } as Rest.PowerAlarmConfigurationWeTrack2,
        relay: {
          cutOff: false,
        } as Rest.RelayConfigurationWeTrack2,
        sensorSet: {
          detectionTime: 10,
          vibrationTimeGps: 3,
          vibrationTimeAlarm: 5,
          workingTime: 1,
        } as Rest.SensorSetConfigurationWeTrack2,
        server: {
          addressMode: "DOMAIN_NAME" as Rest.ModeAdressServerWeTrack2,
          address: "",
          port: 0,
          protocolMode: "TCP" as Rest.ModeProtocolServerWeTrack2,
        } as Rest.ServerConfigurationWeTrack2,
        sos: {
          mode: "ADD" as Rest.ModeSOSWeTrack2,
          phoneNumbers: "",
        } as Rest.SOSConfigurationWeTrack2,
        staticDataFiltering: {
          enabled: true,
          maxFilteringDistance: 100,
        } as Rest.StaticDataFilteringConfigurationWeTrack2,
        vibrationAlarm: {
          enabled: false,
          modeAlarm: "SMS_GPRS" as Rest.ModeVibrationAlarmWeTrack2,
        } as Rest.VibrationAlarmConfigurationWeTrack2,
        gpsDataSendingInterval: {
          timer1: 10,
          timer2: 10,
        } as Rest.GPSDataSendingIntervalConfigurationWeTrack2,
        alarmCallNumberTimes: {
          times: 3,
        } as Rest.AlarmCallNumberTimesConfigurationWeTrack2,
        alarmOverSpeed: {
          enabled: false,
          intervalTime: 20,
          speedLimit: 50,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmWeTrack2,
        } as Rest.AlarmOverSpeedConfigurationWeTrack2,
        automaticApn: {
          enabled: true,
        } as Rest.AutomaticAPNConfigurationWeTrack2,
        centerPhoneNumber: {
          mode: "ADD" as Rest.ModeSOSWeTrack2,
          phoneNumber: "",
        } as Rest.CenterPhoneNumberConfigurationWeTrack2,
        externalVoltageUpload: {
          enabled: false,
          uploadInterval: 600,
        } as Rest.ExternalVoltageUploadConfigurationWeTrack2,
        fenceAlarm: {
          enabled: false,
          mode: "CIRCLE_AREA" as Rest.ModeFenceWeTrack2,
          circle: {
            latitudeCenter: 0,
            longitudeCenter: 0,
            radius: 100,
          } as Rest.CircleAreaWeTrack2,
          rectangle: {
            latitude1: 0,
            longitude1: 0,
            latitude2: 0,
            longitude2: 0,
          } as Rest.RectangleAreaWeTrack2,
          modeAlarmFence: null as Rest.ModeAlarmFenceWeTrack2,
          modeAlarm: "GPRS_SMS" as Rest.ModeAlarmWeTrack2,
        } as Rest.FenceAlarmConfigurationWeTrack2,
        gpsDistanceInterval: {
          distanceInterval: 300,
        } as Rest.GPSDistanceIntervalConfigurationWeTrack2,
        networkLinkCoordinates: {
          networkLink: "http://maps.google.com/maps?q=",
        } as Rest.NetworkLinkCoordinatesConfigurationWeTrack2,
        sensorSensitivity: {
          sensitivityGrade: 2,
        } as Rest.SensorSensitivityConfigurationWeTrack2,
        accAlarm: {
          enabled: false,
          modeAlarm: "GPRS" as Rest.ModeAlarmWeTrack2,
          modeACC: "ACC_CHANGED_ALARM" as Rest.ModeACCAlarmWeTrack2,
        } as Rest.ACCAlarmConfigurationWeTrack2,
        lbsUpload: {
          enabled: false,
          uploadInterval: 60,
          timeOutTime: 60,
        } as Rest.LBSUploadConfigurationWeTrack2,
        locationDataUpload: {
          enabled: false,
        } as Rest.LocationDataUploadConfigurationWeTrack2,
      } as Rest.WeTrack2Params,
    } as Rest.DeviceParameters;
  }

  export function emptyVT10Params1(version: number) {
    switch (version) {
      case 1:
        return {
          id: 0,
          proprietaryParameters: {
            type: "VT10" as Rest.Types,
            speedEvent: {} as any,
            track: { mode: "DISABLE" } as any,
            track2: { mode: "DISABLE" } as any,
            towAlert: {} as any,
          } as Rest.VT10Properties,
        } as Rest.DeviceParameters;

      case 2:
        return {
          id: 0,
          proprietaryParameters: {
            type: "VT10" as Rest.Types,
            speedEvent: {} as any,
            track: { mode: "DISABLE" } as any,
            track2: { mode: "DISABLE" } as any,
            towAlert: {} as any,
            powerSaving: { mode: "MODE_0" } as any,
            dr: {} as any,
            acc: {} as any,
            gpsAntenaCut: { outputPattern: {} } as any,
          } as Rest.VT10Properties,
        } as Rest.DeviceParameters;

      case 3:
        return {
          id: 0,
          sensors: [],
          actuators: [],
          proprietaryParameters: {
            type: "VT10" as Rest.Types,
            speedEvent: {} as any,
            analogInputFunction: {} as any,
            mileageConfiguration: {} as any,
            track: { mode: "DISABLE" } as any,
            track2: { mode: "DISABLE" } as any,
            roaming: {} as any,
            powerSaving: { mode: "MODE_0" } as any,
            dr: {} as any,
            acc: {} as any,
            towAlert: {} as any,
            gpsAntenaCut: { outputPattern: {} } as any,
            ignitionOff: {} as any,
            harshAcceleration: {} as any,
            harshBreaking: {} as any,
            emergencyStop: {} as any,
            harshCornering: {} as any,
            idle: {} as any,
            jam: { outputPattern: {} } as any,
            ledPAttern1: {} as any,
            ledPAttern2: {} as any,
            ledPAttern3: {} as any,
            ledPAttern4: {} as any,
            ledPAttern5: {} as any,
            rfDetected: {} as any,
            tagInOut: {} as any,
            acconWithoutTag: { outputPattern: {} } as any,
            arm: {} as any,
            sos: { outputPattern: {} } as any,
            rfTags: [],
            time: {} as any,
          },
        } as Rest.DeviceParameters;
    }
  }

  export function emptyX8Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "X8" as Rest.Types,
        trackEvent: {
          mode: "DISABLE" as Rest.TrackModeX8,
          times: 0,
          persistTracking: false,
        } as Rest.TrackEventConfigurationX8,
        speedEvent: {
          enabled: false,
          reportAction: "DISABLE" as Rest.ReportActionX8,
          outputControl: false,
          minSpeed: 0,
          maxSpeed: 0,
        } as Rest.SpeedEventConfigurationX8,
        powerSaving: {
          lowVoltage: 12,
          mode: "MODE_0" as Rest.SleepModeX8,
          delay: 0,
          powerUpDuration: 0,
          powerDownDuration: 0,
          sleepPriority: false,
          enableShockSensor: false,
        } as Rest.PowerSavingX8,
        analogInput: {
          mode: "DISABLE" as Rest.AnalogInputModeX8,
          reportAction: "DISABLE" as Rest.ReportActionX8,
          outputControl: false,
        } as Rest.AnalogInputConfigurationX8,
        idleEvent: {
          enabled: false,
          reportMode: "DISABLE" as Rest.ReportModeIdleEventX8,
        } as Rest.IdleEventConfigurationX8,
        inputDelayTime: {
          delayTimeInput1: 7,
          delayTimeInput2: 7,
          delayTimeInput3: 7,
          delayTimeInput4: 7,
          delayTimeInput5: 7,
          delayTimeInput6: 7,
          delayTimeInput7: 7,
          delayTimeInput8: 7,
        } as Rest.InputDelayTimeConfigurationX8,
        backupBattery: {
          enabled: false,
        } as Rest.BackupBatteryControlX8,
        timerReport: {
          enabled: false,
        } as Rest.TimerReportConfigurationX8,
        mileage: {} as Rest.MileageConfigurationX8,
      } as Rest.X8Params,
    } as Rest.DeviceParameters;
  }

  export function emptyVT200Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "VT200" as Rest.Types,
        acc: {
          enabled: false,
          offThreshold: 0,
          onThreshold: 0,
          duration: 0,
        } as Rest.ACCVT200,
        analogInput: {
          mode: "DISABLE" as Rest.AnalogInputModesVT200,
          action: "DISABLE" as Rest.AnalogActionModeVT200,
          minVoltageLevel: 0,
          maxVoltageLevel: 0,
          duration: 0,
          outputPort: "DISABLE" as Rest.AnalogInputOutputPorts,
          outputControl: false,
        } as Rest.AnalogInputFunctionConfigurationVT200,
        dr: {
          lowVoltageLevel: 11.5,
          pollingOnInput1: true,
          pollingOnInput2: true,
          pollingOnInput3: true,
          pollingOnInput4: true,
          pollingOnMainPowerLow: true,
          pollingOnMainPowerLost: true,
          pollingOnBatteryVoltageLow: true,
          pollingOnBatteryVoltageRecover: false,
          pollingOnMainPowerRecover: false,
          pollingOnMainPowerVoltageRecover: false,
          loggingOnInput1: false,
          loggingOnInput2: false,
          loggingOnInput3: false,
          loggingOnInput4: false,
          loggingOnMainPowerLow: false,
          loggingOnMainPowerLost: false,
          loggingOnBatteryVoltageLow: false,
          loggingOnBatteryVoltageRecover: false,
          loggingOnMainPowerRecover: false,
          loggingOnMainPowerVoltageRecover: false,
        } as Rest.DRVT200,
        powerSaving: {
          mode: "MODE_0" as Rest.PowerSavingModeVT200,
          delay: 60,
          sleepMaskMode: "MODE1" as Rest.SleepingMaskModeVT200,
        } as Rest.PowerSavingVT200,
        speedEvent: {
          mode: "DISABLE" as Rest.SpeedEventModeVT200,
          minimumSpeed: 0,
          maximumSpeed: 0,
          speedDuration: 0,
          outputPort: "DISABLE" as Rest.SpeedEventOutputPortsVT200,
          outputControl: false,
          speedingMode:
            "ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY" as Rest.SpeedEventSpeedingModesVT200,
          offSpeedingDuration: 0,
        } as Rest.SpeedEventConfigurationVT200,
        towEvent: {
          mode: "DISABLE" as Rest.TowEventModeVT200,
          satellitesFixed: 3,
          speedThreshold: 10,
          towDuration: 10,
          autoReset: 0,
        } as Rest.TowEventConfigurationVT200,
        trackEvent: {
          mode: "DISABLE" as Rest.TrackModeVT200,
          time: 0,
          distance: 0,
          trackingTimes: 0,
          basis: "GPS_FIXED" as Rest.TrackBasisVT200,
        } as Rest.TrackEventConfigurationVT200,
      } as Rest.VT200Params,
    } as Rest.DeviceParameters;
  }

  export function emptyVT10Params() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "VT10" as Rest.Types,
        speedEvent: {} as any,
        analogInputFunction: {} as any,
        mileageConfiguration: {} as any,
        track: { mode: "DISABLE" } as any,
        track2: { mode: "DISABLE" } as any,
        roaming: {} as any,
        powerSaving: { mode: "MODE_0" } as any,
        dr: {} as any,
        acc: {} as any,
        towAlert: {} as any,
        gpsAntenaCut: {outputPattern: {}} as any,
        ignitionOff: {} as any,
        harshAcceleration: {} as any,
        harshBreaking: {} as any,
        emergencyStop: {} as any,
        harshCornering: {} as any,
        idle: {} as any,
        jam: {outputPattern: {}} as any,
        ledPAttern1: {} as any,
        ledPAttern2: {} as any,
        ledPAttern3: {} as any,
        ledPAttern4: {} as any,
        ledPAttern5: {} as any,
        rfDetected: {} as any,
        tagInOut: {} as any,
        acconWithoutTag: {outputPattern: {}} as any,
        arm: {} as any,
        sos: {outputPattern: {}} as any,
        rfTags: [],
        time: {} as any,
      },
    } as Rest.DeviceParameters;
  }

  export function initializeGL320MParams(conf: Rest.DeviceParameters) {
    var defaultConf = emptyGL320MParams();
    clean(conf);
    recursiveAssignDefaultObjects(defaultConf, conf);
  }

  export function emptyGL320MParams() {
    return {
      id: 0,
      sensors: [],
      actuators: [],
      proprietaryParameters: {
        type: "GL320M" as Rest.Types,
          bsiParameters:{
            networkMode:"AUTO" as Rest.NetworkModeBSIGL320M,
            lteMode: "M1" as Rest.LTEModeBSIGL320M,
            authenticationMethods:"NONE" as Rest.ModeAuthenticationMethodBSIGL320M,
            manualNetreg: "ENABLE" as Rest.ManualNetregBSIGL320M,
            edrxPeriodic:0,
            edrxM1Pagings:0,
            edrxNB2Pagings:0
          } as Rest.BearerSettingInformationGL320M,
          sriParameters:{
            mode: "STOP" as Rest.ModeReportSRIGL320M,
            buffer: "LOW_PRIORITY" as Rest.ModeBufferSRIGL320M,
            mainServerPort:0,
            backupServerPort:0,
            heartbeatInterval:0,
            modeSack:"DISABLE" as Rest.ModeSackSRIGL320M,
            enableSmsAck: false,
            modeMultipacketSending: "DISABLE" as Rest.MultiPacketSendingSRIGL320M,
            dnsLookupInterval:0,
          } as Rest.ServerRegistrationInformationGL320M,
          qssParameters:{
            modeReport: "STOP" as Rest.ModeReportQSSGL320M,
            modeBuffer: "LOW_PRIORITY" as Rest.ModeBufferQSSGL320M,
            mainServerPort:0,
            backupServerPort:0,
            heartbeatInterval:0,
            modeSack:"DISABLE" as Rest.ModeSackQSSGL320M,
          } as Rest.QuickStartSettingsGL320M,
          globalParameters:{
            gpsMode: "TURN_OFF_AFTER_GPS_INFORMATION" as Rest.ModeGpsCFGGL320M,
            gpsDelay: 5,
            speed: true,
            azimuth: true,
            altitude: true,
            cellInformation: true,
            sendTime: true,
            deviceName: false,
            eventMaskRESPGTPNA: true,
            eventMaskRESPGTPFA: true,
            eventMaskRESPGTEPN: true,
            eventMaskRESPGTEPF: true,
            eventMaskRESPGTBPL: true,
            eventMaskRESPGTBTC: true,
            eventMaskRESPGTSTC: true,
            eventMaskRESPGTSTT: true,
            eventMaskRESPGTPDP: true,
            eventMaskRESPGTPNL: true,
            eventMaskRESPGTIGNGTIGF: false,
            eventMaskRESPGTIGL: false,
            epbMode:"DISABLE" as Rest.ModeEpbCFGGL320M,
            ledON: "LEDS_NORMALLY" as Rest.LedOnCFGGL320M,
            infoReportEnable: true,
            infoReportInterval: 300,
            smsLocationRequest:true,
            expiryEnable:false,
            expiryTime:"20491231235959",
            agpsMode:"DISABLE" as Rest.ModeAgpsCFGGL320M,
            gsmMode:"CELL_INFORMATION_NOT_ALLOWED" as Rest.ModeGsmCFGGL320M,
            eventMaskRESPGTRTL: false,
            eventMaskRESPGTFRIGTERI:false,
            batteryPowerOn:false,
            batteryLowPercentage:10,
            walkingMode:"DISABLE" as Rest.ModeWalkingCFGGL320M,
          } as Rest.GlobalParametersGL320M,
          pinParameters:{
            autoUnlockPin: true,
          } as Rest.UnlockPINGL320M,
          dogParameters:{
            mode:"REBOOT_PERIODICALLY" as Rest.ModeDOGGL320M,
            ignitionFrequency:60,
            rebootInterval: 7,
            rebootTime: "0200",
            reportBeforeReboot: true,
            digitalInputId:0,
            unit:"DAY" as Rest.ModeUnitDOGGL320M,
          } as Rest.ProtocolWatchdogGL320M,
          tmaParameters:{
            sign:"PLUS" as Rest.ModeSignTMAGL320M,
            hourOffset:0,
            minuteOffset:0,
            daylightSaving:false,
          } as Rest.TimeAdjustmenGL320M,
          nmdParameters:{
            suspendFRIAndGeoFenceWhenNoMovement: false,
            reportGTNMRWhenMovement: false,
            reportGTNMRWhenNoMovement: false,
            changeGPSFixIntervalAndGTFRIReportIntervalWhenNoMovement: false,
            noMovementDuration: 2,
            movementDuration:3,
            movementThreshold:2,
            fixIntervalRest: 300,
            sendIntervalRest: 300,
            pmRestThreshold:2,
            pmMotionThreshold:3,
            urcReport: "DISABLE" as Rest.ReportUrcNMDGL320M,
            movementCommand: "NOT_CHANGE_MOTION_STATE" as Rest.MovementCommandNMDGL320M,
            modeReport: "REPORT_CURRENT_POSITION" as Rest.ModeReportNMDGL320M,
          } as Rest.NonMovementDetectionGL320M,
          fksParameters:{
            powerKeyMode: "PRESSING_POWER" as Rest.ModePowerKeyFKSGL320M,
            powerMode: "ENABLE" as Rest.ModePowerFKSGL320M,
            functionKeyMode: "SOS" as Rest.ModeFunctionKeyFKSGL320M,
            powerKeyIndicationMode: "DISABLE" as Rest.ModePowerKeyIndicationFKSGL320M,
            functionKeyIndicationMode:"DISABLE" as Rest.ModeFunctionKeyIndicationFKSGL320M,
            reportSosMode:"REPORT_LAST_GPS_THEN_REPORT_CURRENT_GPS" as Rest.ModeSosReportFKSGL320M,
            firstTriggerTime:3,
            secondTriggerTime:4,
            firstTriggerEvent:"SEND_GTLOC" as Rest.ModeTriggerFKSGL320M,
            secondTriggerEvent:"SEND_SOS" as Rest.ModeTriggerFKSGL320M,
          } as Rest.FunctionKeySettingsGL320M,
          ntsParameters:{
            enabled: false,
          } as Rest.NetworkSelectionGL320M,
          owhParameters:{
            mode: "DISABLE" as Rest.ModeOWHGL320M,
            monday:true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
            workingHoursStart1: "0900",
            workingHoursEnd1: "1200",
            workingHoursStart2: "1300",
            workingHoursEnd2: "1800",
            digitalInputId: 0,
            rfSleepMode:"NO_SHUT_DOWN" as Rest.ModeRFSleepOWHGL320M,
          } as Rest.OutsideWorkingHoursGL320M,
          simParameters:{
            iccidMode: "NOT_BIND" as Rest.ModeIccidSIMGL320M,
            unregisterToNetwork: true,
          } as Rest.BindingSIMCardGL320M,
          urtParameters:{
            index: 12,
          } as Rest.SerialPortSettingsGL320M,
          friParameters:{
            mode:"DISABLE" as Rest.ModeFRIGL320M,
            discardNoFix: true,
            startTime:"0000",
            endTime: "0000",
            checkInterval: 180,
            sendInterval: 180,
            ignitionCheckInterval: 180,
            ignitionSendInterval: 180,
            distance: 1000,
            mileage: 1000,
            movementDetectionMode: "DISABLE" as Rest.ModeMovementDetectionFRIGL320M,
            movementSpeed: 5,
            movementDistance: 50,
            movementSendNumber: 5,
            corner: 0,
            externalBatteryPercent: false,
            temperature: false,
            ratAndBand: false,
          } as Rest.FixedReportInformationGL320M,
          geoParameters:[
            {
              geoId:0,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:1,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:2,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:3,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:4,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:5,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:6,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:7,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:8,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:9,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:10,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:11,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:12,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:13,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:14,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:15,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:16,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:17,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:18,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            },
            {
              geoId:19,
              mode:"DISABLE" as Rest.ModeGEOGL320M,
              radius:50,
              checkInterval:0,
              modeState:"REPORT_UNTIL_STATE_CHANGES" as Rest.ModeStateGEOGL320M
            }
          ] as Rest.GeoFenceParametersGL320M[],
          spdParameters:{
            mode: "DISABLE" as Rest.ModeSPDGL320M,
            minSpeed: 0,
            maxSpeed: 0,
            validTime: 60,
            sendInterval: 300,
          } as Rest.SpeedAlarmParametersGL320M,
          temParameters:{
            mode:"DISABLE" as Rest.ModeTEMGL320M,
            minTemperature: 0,
            maxTemperature: 0,
            duration: 60,
            sendInterval:300,
          } as Rest.TemperatureAlarmGL320M,
          msaParameters:{
            mode: "DISABLE" as Rest.ModeMSAGL320M,
            lastPositionMode: "ENABLE" as Rest.ModeLastPositionMSAGL320M,
            sensitivity:5,
            alarmTimeout:5,
          } as Rest.MotionSensorAlarmGL320M,
          disParameters:{
            mode:"REPORT_GTDIS_ON_CHANGES" as Rest.ModeDISGL320M,
            debounceTime:5,
            connected:false,
          } as Rest.DigitalInputPortSettingsGL320M,
          pdsParameters:{
            mode:"PRESERVE" as Rest.ModePDSEnumGMT200,
            stateOfGeo:true,
            deviceResetType:false,
            lastKnownPosition:true,
            deviceState: false,
            externalPowerSupply:true,
            charging:true,
            digitalInputs:false,
            stateGTLSW:false,
            stateGTTSW:false,
            stateGTOMS:false,
          } as Rest.PreservingDeviceLogicStatesGL320M,
          gamParameters:{
            mode:"ENABLE" as Rest.ModeGAMGL320M,
            speedMode: "ENABLE" as Rest.ModeSpeedGAMGL320M,
            motionSpeedThreshold: 25,
            motionCumulativeTime:10,
            motionlessCumulativeTime:60,
            failureTimeout:60
          } as Rest.GPSAssistedMotionMeasurementGL320M,
          cmdParameters:[
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:0,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:1,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:2,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:3,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:4,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:5,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:6,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:7,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:8,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:9,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:10,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:11,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:12,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:13,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:14,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:15,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:16,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:17,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:18,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:19,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:20,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:21,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:22,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:23,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:24,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:25,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:26,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:27,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:28,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:29,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:30,
              dynamic:false,
            },
            {
              mode:"DELETE_COMMAND" as Rest.ModeCommandStorageCMDGL320M,
              cmdId:31,
              dynamic:false,
            },
          ] as Rest.CommandStorageGL320M[],
          udfParameters:[
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:0,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:1,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:2,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:3,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:4,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:5,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:6,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:7,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:8,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:9,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:10,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:11,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:12,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:13,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:14,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:15,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:16,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:17,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:18,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:19,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:20,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:21,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:22,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:23,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:24,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:25,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:26,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:27,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:28,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:29,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:30,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
            {
              mode:"DISABLE_FUNCTIONS" as Rest.ModeUDFGL320M,
              groupId:31,
              debounceTime:0,
              modeStoCmd:"NOT_SEND_ACK_MESSAGE" as Rest.ModeStoCmdUDFGL320M,
            },
        ] as Rest.UserDefinedFunctionGL320M[],
        additionalParameters:{
          timeoutApp:20,
        } as Rest.AdditionalParametersGL320M
      } as Rest.GL320MParams,
    } as Rest.DeviceParameters;
  }

  export function allRecordsRequest() {
    return {
      sortBy: "id",
      sortAsc: true,
      pageSize: 2147483647, //java max int
      loadPermissions: true,
      filters: {},
    } as Rest.ListPaginationRequest;
  }

  /*export const firstPage = {

        sortBy: 'id',
        sortAsc: true,
        pageSize: 10,
        loadPermissions: true,
        filters: {},
    } as Rest.ListPaginationRequest*/
  export class Pair<T, J> {
    key: T;
    value: J;
  }

  export const allRecords = {
    sortBy: "id",
    sortAsc: true,
    pageSize: 2147483647, //java max int
    loadPermissions: true,
    filters: {},
  } as Rest.ListPaginationRequest;

  export enum DeviceStatusEnum {
    ACTIVE,
    PENDING,
    UNINSTALLED,
  }

  export enum LanguageEnum {
    ES,
    EN,
    FR,
    CA,
    IT,
  }

    export enum FirmwareVersion {
        VT10_V1,
        VT10_V2,
        VT10_V3,
        GV300,
        GMT200_A06V23,
        ConcoxMT200,
        WeTrack2,
        GV300CAN,
        GV800,
        X8_V1,
        X8_V2,
        X8_V3,
        VT200,
        EVIX_V1,
        AtlantisAppNoDevice,
        GMT200_A08V04,
        GMT200_A09V03,
        Iofrog,
        Teltonika,
        OB22,
        GL320M,
        SmartCoffee
    }

  export enum MetricEnum {
    INTERNATIONAL_SYSTEM_OF_UNIT,
    IMPERIAL_SYSTEM,
  }

  export enum PeriodicityEnum {
    DAYS,
    MONTHS,
    YEARS,
  }

  export enum AnalogInputActions {
    LOGGING,
    POLLING,
    LOGGING_AND_POLLING,
  }

  export enum AnalogInputModes {
    DISABLE,
    EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION,
    EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_OT_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION,
  }

  export enum AnalogInputOutputPorts {
    DISABLE,
    OUTPUT_1,
    OUTPUT_2,
    OUTPUT_3,
    OUTPUT_4,
  }

  export enum MileageConfigModes {
    DISABLE,
    MILEAGE_WILL_BE_ACCUMULATED_REGARDLESS_THE_ACC_STATUS,
    MILEAGE_WILL_BE_ACCUMULATED_ONLY_IF_THE_ACC_IS_ON,
  }

  export enum SpeedEventModes {
    DISABLE,
    LOGGING,
    POLLING,
    LOGGING_AND_POLLING,
  }

  export enum SpeedEventOutputPorts {
    DISABLE,
    OUTPUT_1,
    OUTPUT_2,
    OUTPUT_3,
    OUTPUT_4,
  }

  export enum SpeedEventSpeedingModes {
    ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY,
    ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY,
  }

  export enum MaintenancePeriodicityType {
    TIME_BASED,
    DISTANCE_BASED,
  }

  export enum SensorTypeEnum {
    DIGITAL,
    ANALOG,
    CANBUS,
    DRIVER,
  }

  export enum DEnum {
    SENSOR,
    ACTUATOR,
  }

  export enum TrackConfigModes {
    DISABLE,
    TIME,
    DISTANCE,
    TIME_AND_DISTANCE,
    TIME_OR_DISTANCE,
    HEADING,
    HEADING_OR_TIME,
    HEADING_OR_DISTANCE,
    HEADING_OR_TIME_AND_DISTANCE,
    HEADING_OR_TIME_OR_DISTANCE,
  }

  export enum TrackBasisModes {
    GPS_FIXED,
    ANY_GPS,
    ACC_ON_AND_GPS_FIXED,
    ACC_ON_AND_ANY_GPS,
  }

  export enum TrackInputValues {
    ANY,
    ON,
    OFF,
  }

  export enum PowerSavingMode {
    MODE_0,
    MODE_1,
    MODE_2,
  }

  export enum PowerSavingModeEnumGMT200{
    DISABLE,
    MODE1,
    MODE2
  }

  export enum ActionEnum {
    VEHICLES_CREATE,
    VEHICLES_READ,
    VEHICLES_UPDATE,
    VEHICLES_DISABLE,
    MAINTENANCE_CREATE,
    MAINTENANCE_READ,
    MAINTENANCE_UPDATE,
    MAINTENANCE_DISABLE,
    MAINTENANCE_PLANS_READ,
    MAINTENANCE_PLANS_UPDATE,
    MAINTENANCE_PLANS_DISABLE,
    MAINTENANCE_EXECUTE,
    ZONES_CREATE,
    ZONES_UPDATE,
    ZONES_DISABLE,
    ZONES_GROUPS_READ,
    ZONES_GROUPS_UPDATE,
    ZONES_GROUPS_DISABLE,
    POIS_CATEGORIES_READ,
    POIS_CATEGORIES_UPDATE,
    POIS_CATEGORIES_DISABLE,
    POIS_CREATE,
    POIS_UPDATE,
    POIS_DISABLE,
    POIS_READ,
    NOTIFICATION_GROUPS_READ,
    NOTIFICATION_GROUPS_UPDATE,
    NOTIFICATION_GROUPS_DISABLE,
    VEHICLES_GROUPS_CREATE,
    VEHICLES_GROUPS_READ,
    VEHICLES_GROUPS_UPDATE,
    VEHICLES_GROUPS_DISABLE,
    FLEETS_READ,
    FLEETS_UPDATE,
    FLEETS_DISABLE,
    CLIENTS_READ,
    DEVICES_READ,
    DEVICES_UPDATE,
    DEVICES_DISABLE,
    PROFILES_READ,
    PROFILES_UPDATE,
    PROFILES_DISABLE,
    ROLES_READ,
    ROLES_UPDATE,
    ROLES_DISABLE,
    USERS_READ,
    USERS_UPDATE,
    USERS_DISABLE,
    SIM_DEVICES_PLAN_GROUPS_CREATE,
    SIM_DEVICES_PLAN_GROUPS_READ,
    SIM_DEVICES_PLAN_GROUPS_UPDATE,
    MOTO_CREATE_ROUTES,
    MOTO_READ_ROUTES,
    INSERT_EVIX_DATA,
    GET_EVIX_DATA,
    EVENTS_HISTORY_READ,
    EVENTS_HISTORY_CREATE,
    EVENTS_HISTORY_UPDATE,
    CREDITS_READ,
    CREDITS_CREATE,
    CREDITS_UPDATE,
    TAX_READ,
    TAX_DOWNLOAD,
    TAX_KPI,
    PR_STOPS_READ,
    INV_DELEGATIONS_CREATE,
    INV_DELEGATIONS_UPDATE,
    INV_DELEGATIONS_READ,
    INV_DELEGATIONS_ADMIN,
    INV_DELEGATIONS_DISABLE,
    INV_VEHICLE_DOC_CREATE,
    INV_VEHICLE_DOC_UPDATE,
    INV_VEHICLE_DOC_DELETE,
    INV_VEHICLE_DOC_DOWNLOAD,
    INV_VEHICLE_OBSERVATIONS_READ,
    INV_CONTROL_KM_CREATE,
    INV_CONTROL_KM_READ,
    INV_CONTROL_KM_UPDATE,
    INV_CONTROL_KM_DISABLE,
    INV_DRIVER_CREATE,
    INV_DRIVER_READ,
    INV_DRIVER_UPDATE,
    INV_DRIVER_DISABLE,
    INV_DRIVER_DOC_CREATE,
    INV_DRIVER_DOC_UPDATE,
    INV_DRIVER_DOC_DELETE,
    INV_DRIVER_DOC_DOWNLOAD,
    INV_PROVIDER_CREATE,
    INV_PROVIDER_READ,
    INV_PROVIDER_UPDATE,
    INV_PROVIDER_DISABLE,
    INV_VEH_STATUS_CREATE,
    INV_VEH_STATUS_READ,
    INV_VEH_STATUS_UPDATE,
    INV_VEH_STATUS_DISABLE,
    INV_VEH_USAGE_CREATE,
    INV_VEH_USAGE_READ,
    INV_VEH_USAGE_UPDATE,
    INV_VEH_USAGE_DISABLE,
    INV_VEH_BRAND_CREATE,
    INV_VEH_BRAND_READ,
    INV_VEH_BRAND_UPDATE,
    INV_VEH_BRAND_DISABLE,
    INV_VEH_MODEL_CREATE,
    INV_VEH_MODEL_READ,
    INV_VEH_MODEL_UPDATE,
    INV_VEH_MODEL_DISABLE,
    INV_VEH_VERSION_CREATE,
    INV_VEH_VERSION_READ,
    INV_VEH_VERSION_UPDATE,
    INV_VEH_VERSION_DISABLE,
    INV_EXTRAS_TYPE_CREATE,
    INV_EXTRAS_TYPE_READ,
    INV_EXTRAS_TYPE_UPDATE,
    INV_EXTRAS_TYPE_DISABLE,
    INV_FINES_STATUS_CREATE,
    INV_FINES_STATUS_READ,
    INV_FINES_STATUS_UPDATE,
    INV_FINES_STATUS_DISABLE,
    INV_FINES_REASONS_CREATE,
    INV_FINES_REASONS_READ,
    INV_FINES_REASONS_UPDATE,
    INV_FINES_REASONS_DISABLE,
    INV_SUBDELEGATIONS_CREATE,
    INV_SUBDELEGATIONS_READ,
    INV_SUBDELEGATIONS_UPDATE,
    INV_SUBDELEGATIONS_DISABLE,
    INV_DIVISIONS_CREATE,
    INV_DIVISIONS_READ,
    INV_DIVISIONS_UPDATE,
    INV_DIVISIONS_DISABLE,
    INV_SUBDIVISIONS_CREATE,
    INV_SUBDIVISIONS_READ,
    INV_SUBDIVISIONS_UPDATE,
    INV_SUBDIVISIONS_DISABLE,
    INV_COMPANYS_CREATE,
    INV_COMPANYS_READ,
    INV_COMPANYS_UPDATE,
    INV_COMPANYS_DISABLE,
    INV_CECOS_CREATE,
    INV_CECOS_READ,
    INV_CECOS_UPDATE,
    INV_CECOS_DISABLE,
    INV_LEADERS_CREATE,
    INV_LEADERS_READ,
    INV_LEADERS_UPDATE,
    INV_LEADERS_DISABLE,
    INV_CARD_TYPE_CREATE,
    INV_CARD_TYPE_READ,
    INV_CARD_TYPE_UPDATE,
    INV_CARD_TYPE_DISABLE,
    INV_CARDS_CREATE,
    INV_CARDS_READ,
    INV_CARDS_UPDATE,
    INV_CARDS_DISABLE,
    INV_CARD_CONSUMPTION_CREATE,
    INV_CARD_CONSUMPTION_READ,
    INV_CARD_CONSUMPTION_UPDATE,
    INV_CARD_CONSUMPTION_DISABLE,
    INV_FINES_CREATE,
    INV_FINES_READ,
    INV_FINES_UPDATE,
    INV_FINES_DISABLE,
    INV_FINES_DOC_CREATE,
    INV_FINES_DOC_UPDATE,
    INV_FINES_DOC_DELETE,
    INV_FINES_DOC_DOWNLOAD,
    INV_FINANCING_TYPE_CREATE,
    INV_FINANCING_TYPE_READ,
    INV_FINANCING_TYPE_UPDATE,
    INV_FINANCING_TYPE_DISABLE,
    INV_WAY_TO_PAY_CREATE,
    INV_WAY_TO_PAY_READ,
    INV_WAY_TO_PAY_UPDATE,
    INV_WAY_TO_PAY_DISABLE,
    INV_RENTING_TYPE_CREATE,
    INV_RENTING_TYPE_READ,
    INV_RENTING_TYPE_UPDATE,
    INV_RENTING_TYPE_DISABLE,
    INV_FINANCING_CREATE,
    INV_FINANCING_READ,
    INV_FINANCING_UPDATE,
    INV_FINANCING_DISABLE,
    INV_FINANCING_COSTS_READ,
    INV_FINANCING_DOC_CREATE,
    INV_FINANCING_DOC_UPDATE,
    INV_FINANCING_DOC_DELETE,
    INV_FINANCING_DOC_DOWNLOAD,
    INV_INSURANCE_TYPE_CREATE,
    INV_INSURANCE_TYPE_READ,
    INV_INSURANCE_TYPE_UPDATE,
    INV_INSURANCE_TYPE_DISABLE,
    INV_INSURANCE_CREATE,
    INV_INSURANCE_READ,
    INV_INSURANCE_UPDATE,
    INV_INSURANCE_DISABLE,
    INV_INSURANCE_COSTS_READ,
    INV_INSURANCE_DOC_CREATE,
    INV_INSURANCE_DOC_UPDATE,
    INV_INSURANCE_DOC_DELETE,
    INV_INSURANCE_DOC_DOWNLOAD,
    MAINTENANCE_EXECUTION_READ,
    MAINTENANCE_EXECUTION_CREATE,
    MAINTENANCE_EXECUTION_UPDATE,
    MAINTENANCE_EXECUTION_DISABLE,
    MAINTENANCE_EXECUTION_DOC_CREATE,
    MAINTENANCE_EXECUTION_DOC_UPDATE,
    MAINTENANCE_EXECUTION_DOC_DELETE,
    MAINTENANCE_EXECUTION_DOC_DOWNLOAD,
    INV_EXTRAS_CREATE,
    INV_EXTRAS_READ,
    INV_EXTRAS_UPDATE,
    INV_EXTRAS_DISABLE,
    INV_EXTRAS_UPDATE_VERIFIED,
    INV_EXTRAS_DOC_CREATE,
    INV_EXTRAS_DOC_UPDATE,
    INV_EXTRAS_DOC_DELETE,
    INV_EXTRAS_DOC_DOWNLOAD,
    INV_RS_READ,
    INV_TAXES_CREATE,
    INV_TAXES_READ,
    INV_TAXES_UPDATE,
    INV_TAXES_DISABLE,
    INV_TAXES_DOC_CREATE,
    INV_TAXES_DOC_UPDATE,
    INV_TAXES_DOC_DELETE,
    INV_TAXES_DOC_DOWNLOAD,
    MANAGE_CLIENT_USERS
  }

  export enum ARMEventAction {
    DISABLE,
    LOGGING,
    POLLINGLOGGINPOLLING,
  }

  export enum DeviceCommunicationType {
    SMS,
    GPRS_ATLANTIS,
    GPRS_OTHERS,
  }

  export enum AppTypes {
    FLEET,
    MOTO,
    CAR,
    AGRO,
  }

  export enum SimOperatorType {
    MOVISTAR,
    EMNIFY
  }

  export enum ReportTypesEnum{
    TAX = 1
  }

  export enum ReportFileEntityType{
    VEHICLE = 0,
    GROUP = 1,
    FLEET = 2,
    CLIENT = 3
  }

  export enum ReportFilePeriodType{
    DAILY = 0,
    WEEKLY = 1,
    MONTHLY = 2,
    ANNUAL = 3
  }

  export enum DocumentType{
	NOTE = 0,           //vehicle
	LICENSE = 1,        //driver
	DGT_POINTS = 2,     //driver
	CARD_BILLING = 3,   //card
	FINES = 4,          //fines
	FINANCING = 5,      //financing
	INSURANCE = 6,      //insurance
	TAXES = 7,          //taxes
	VEHICLE = 8 ,       //vehicle
	EXECUTION = 9       //MaintenanceExecution
  }

  export enum DocumentTypeDriver{
    LICENSE = 1,
    DGT_POINTS = 2
  }

  export enum DocumentTypeVehicle{
    NOTE = 0,
	 VEHICLE = 8
  }

  export enum DocumentTypeFines{
	FINES = 0
  }

  export enum DocumentTypeTaxes{
	TAXES = 0
  }

  export enum DocumentTypeFinancing{
	FINANCING = 5
  }

  export enum DocumentTypeInsurance{
	INSURANCE = 6
  }

  export enum DocumentTypeExecution{
	EXECUTION = 6
  }

  export enum ProviderType{
    Combustible = 1,
    ViaT = 2,
    Seguros = 3,
    Renting = 4,
    Concesionario = 5
  }

  export function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
      let result = "";
      for (let i = 0; i < substitutions.length; i++) {
          result += template[i];
          result += encodeURIComponent(substitutions[i]);
      }
      result += template[template.length - 1];
      return result;
  }

}
