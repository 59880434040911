import {Component, Input, OnChanges, OnInit, SimpleChanges,} from "@angular/core";

import {Rest} from "../../../../rest/rest_client";
import {RealtimeService} from "../../../../rest/realtime.service";
import {I18nService} from "../../../../services/i18n/i18n.service";
import {RestExt} from "../../../../services/rest-client-extension";
import {RealTimeDataService} from "../../../../services/rt-data/rt-data.service";
import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';

@Component({
  selector: "app-vehicle-card",
  templateUrl: "./vehicle-card.component.html",
  styleUrls: ["./vehicle-card.component.css"],
  providers: [RealtimeService],
})
export class VehicleCardComponent implements OnInit, OnChanges {
  @Input() settings: Rest.VehicleDetailsSettings;
  @Input() vehicle: Rest.Vehicle;
  isCollapsed: false;
  vehicleID: number | string;
  vehicleName: string;
  groupName: string;
  fleetName: string;
  vehicleIcon: string;

  /* TODO: DELETE TEMP VARIABLES AFTER FINAL VERSION: showMapView && showTelemetryView && showQuickActionsView */
  /* <--- TEMP VARIABLES ---> */
  showMapView: boolean = false;
  showTelemetryView: boolean = false;
  showQuickActionsView: boolean = false;
  isUserHosp: boolean = false;

  constructor(
    private i18n: I18nService,
    private rtService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private authenticationService: AuthenticationService
  ) {
    if(this.authenticationService.user.id == 277718){
      this.isUserHosp = true;
      this.showMapView= true;
      this.showTelemetryView = true
    }
  }

  ngOnInit() {    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["settings"]) {
      this.vehicleID = this.vehicle != null ? this.vehicle.id : "?";
      this.vehicleName = this.vehicle != null ? this.vehicle.name : "?";
      this.groupName =
        this.vehicle != null && this.vehicle.group != null
          ? this.vehicle.group.name
          : "?";

      this.vehicleIcon = this.vehicle != null && this.vehicle.group != null && this.vehicle.group.icon != null ? this.vehicle.group.icon.imageEncoded:"";
      
      this.fleetName =
        this.vehicle != null &&
        this.vehicle.group != null &&
        this.vehicle.group.fleet != null
          ? this.vehicle.group.fleet.name
          : "?";
    }
  }

  remove() {
    let newWatched = this.realTimeDataService.watchedVehicles.getValue();
    let v = newWatched.find((watched: RestExt.WatchedVehicle) => {
      return this.vehicle.id == watched.id;
    });
    if (v != null) v.visibleInMap = false;
    this.realTimeDataService.watchedVehicles.next(newWatched);
  }

  /* TODO: REMOVE TEMPS FUNCTIONS: map() && telemetry() && quickActions() */
  map(): void {
    this.showMapView = !this.showMapView;
  }

  telemetry(): void {
    this.showTelemetryView = !this.showTelemetryView;
  }

  quickActions(): void {
    this.showQuickActionsView = !this.showQuickActionsView;
  }
}
