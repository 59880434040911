import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { RestExt } from 'app/services/rest-client-extension';
import { VehicleUsageFormComponent } from "../vehicle-usage-form/vehicle-usage-form.component";
import { VehicleUsageService } from "app/rest/vehicleUsage.service";
import { ExcelService } from "app/services/excel-service/excel.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-vehicle-usage-list',
    templateUrl: './vehicle-usage-list.component.html',
    styleUrls: ['./vehicle-usage-list.component.css'],
    providers: [VehicleUsageService, ConfirmationService, UserTimePipe, ExcelService],
})
export class VehicleUsageListComponent
    extends EntityListComponent<Rest.VehicleUsage>
    implements OnInit {

    @ViewChild(VehicleUsageFormComponent, { static: true })
    editForm: VehicleUsageFormComponent;

    title: string = this.i18n._("Clients");
    disableSave: boolean;
    user: Rest.User;
    EnabledFilter: SelectItem[];

    constructor(
        private vehicleUsageService: VehicleUsageService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        public translateService: TranslateService,
    ) {
        super(
            vehicleUsageService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );
        this.vehicleUsageService = vehicleUsageService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.editForm;
        this.hasEntityFilters = false;
        this.user = this.authenticationService.user;
    }

    excelColumns = {};
    exportExcel() {
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.vehicleUsage.vehicleUsage');
        super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
    }
    setExcelColumns() {
        const code = (entity: any) => entity.code ?? ''
        const name = (entity: any) => entity.name ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''
        this.excelColumns = {
            //  [this.translateService.instant('tableManagement.vehicleUsage.usageCode')]: { bindField: code },
            [this.translateService.instant('general.name')]: { bindField: name },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }
    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }

    afterLoad() {
        // this.EnabledFilter = RestExt.getEnabledFilter(
        //     this.filters["enabled"],
        //     this.i18n
        // );
        // this.paginationResult.entities.forEach((cl: any) => {
        //     /*Permissions */
        //     cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
        //     cl._$update = true;
        //     /** */
        // });
        // /*Permissions */
        // this.checkSelectAll();
        /** */
    }
}
