import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { InsuranceTypeService } from 'app/modules/vehicle-inventory/services/table-management/InsuranceType.service';

import InsuranceType = Rest.InsuranceType;

@Component({
    selector: 'app-InsuranceType-form',
    templateUrl: './InsuranceType-form.component.html',
    styleUrls: ['./InsuranceType-form.component.scss'],
    providers: [InsuranceTypeService],
    encapsulation: ViewEncapsulation.None,
})
export class InsuranceTypeFormComponent
    extends EntityFormComponent<InsuranceType>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];

    title: string;

    active: boolean;

    constructor(
        private InsuranceTypeService: InsuranceTypeService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(InsuranceTypeService, notificationsService, i18n, authenticationService);
    }

    /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof InsuranceTypeFormComponent
   */
    beforeShow() {
        this.active = !this.entity.disabled;

        console.log(this.entity);

        if (this.isNew) {
            this.title = this.translateService.instant('tableManagement.insurance.createInsuranceType');
        } else {
            this.title = this.translateService.instant('tableManagement.insurance.editInsuranceType');
        }
    }
}