<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Harsh Behavior
          Monitoring (AT + GTHBM)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
		<div class="col lblStyle">
      <form-input fieldName="Speed Alarm Mode">
      </form-input>
		</div>
		<div class="col">
      <p-dropdown (onChange)="trackEventModeChange(hbmParameters.mode,$event)" [options]="modeOptions"
      [(ngModel)]="hbmParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
		</div>
	</div>

    <div [class.disableDIV]=!trackModeSwitch>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="High Speed">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.highSpeed" [min]="100" [max]="400"></p-spinner>
              <span class="ui-inputgroup-addon">100~400</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vhb">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vhb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vha">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vha" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Medium Speed">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.mediumSpeed" [min]="60" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">60~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vmb">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vmb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vma">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vma" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vlb">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vlb" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="6Vla">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.vla" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon">km/h</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Cornering and breaking threshold">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.corneringThreshold" [min]="30" [max]="70"></p-spinner>
              <span class="ui-inputgroup-addon">30~70</span>
              <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Cornering and breaking duration">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.coreringDuration" [min]="40" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">40~100</span>
              <span class="ui-inputgroup-addon">*8ms</span>
            </div>
          </div>
        </div>
      
      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Acceleration threshold">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.accelerationThreshold" [min]="15" [max]="50"></p-spinner>
              <span class="ui-inputgroup-addon">15~50</span>
              <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Acceleration duration">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="hbmParameters.accelerationDuration" [min]="50" [max]="250"></p-spinner>
              <span class="ui-inputgroup-addon">50~250</span>
              <span class="ui-inputgroup-addon">*8ms</span>
            </div>
          </div>
        </div>
      
      </div>
    </div>
</p-accordionTab>