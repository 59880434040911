<p-dialog [responsive]="true" [contentStyle]="{minHeight: '50px'}" width="650" height="450" [(visible)]="PopUpOpen"
    closeOnEscape=true>
    <p-header class ="headerStyle">
            {{header}}
    </p-header>
<!---->


    <label id="lblPosition" class="component_in_line2">{{lblSearchProximity}}</label>
    <label id="position" class = "positionStyle" (click)="showMarker()"><u>{{punto}}</u></label>
    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

  <!-- TODO: Since dataTable is deprecated in the newer versions of PrimeNG, a new implementation of the whole table is needed!!! -->
    <!--<p-dataTable [value]="rows" [sortField]="sortField" [sortOrder]="sortOrder" *ngIf="PopUpOpen">
        <p-column *ngFor="let col of cols" [field]="col.field" [header]="col.header" [hidden]="!col.visible" [sortable]="col.sortable"
        [style]="{'width':col.width}">
            <ng-template pTemplate="header" let-col>
                <a [title]="col.header">{{col.header}}</a>
            </ng-template>
            &lt;!&ndash; ___________________________________ROWS___________________________________ &ndash;&gt;
            <ng-template *ngIf="col.field === 'id'" let-row="rowData" pTemplate="body">
                <div [title]="row.id" class="overflow ellipsis">
                    {{row.id}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'fleetName'" let-row="rowData" pTemplate="body">
                <div [title]="row.fleetName" class="overflow ellipsis">
                    {{row.fleetName}}
                </div>
            </ng-template>

            <ng-template *ngIf="col.field === 'groupName'" let-row="rowData" pTemplate="body">
              <div [title]="row.groupName" class="overflow ellipsis">
                  {{row.groupName}}
              </div>
            </ng-template>

            <ng-template *ngIf="col.field === 'vehicleName'" let-row="rowData" pTemplate="body">
              <div [title]="row.vehicleName" class="overflow ellipsis">
                  {{row.vehicleName}}
              </div>
            </ng-template>

            <ng-template *ngIf="col.field === 'distance'" let-row="rowData" pTemplate="body">
              <div [title]="row.distance" class="overflow ellipsis">
                  {{row.distance}}
              </div>
            </ng-template>

            <ng-template *ngIf="col.field === 'time'" let-row="rowData" pTemplate="body">
              <div [title]="row.time" class="overflow ellipsis">
                  {{row.time}}
              </div>
            </ng-template>
        </p-column>
    </p-dataTable>-->

</p-dialog>

