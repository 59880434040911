<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Three Axis
          Self-Calibration (AT + GTASC)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Brake Speed Threshold">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="ascParameters.brakingSpeedThreshold" [min]="30" [max]="400"></p-spinner>
          <span class="ui-inputgroup-addon">30~400</span>
          <span class="ui-inputgroup-addon">km/h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Delta Speed Threshold">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="ascParameters.deltaSpeedThreshold" [min]="5" [max]="72"></p-spinner>
          <span class="ui-inputgroup-addon">5~72</span>
          <span class="ui-inputgroup-addon">km/h</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Delta Heading Threshold">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="ascParameters.deltaHeadingThreshold" [min]="0" [max]="5"></p-spinner>
          <span class="ui-inputgroup-addon">0~5</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Output ID">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="ascParameters.outputId" [min]="0" [max]="1"></p-spinner>
          <span class="ui-inputgroup-addon">0~1</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Output Status">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-inputSwitch [(ngModel)]="ascParameters.outputStatus"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

</p-accordionTab>