<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Quick Start
                    Setting (AT+GTQSS)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Basic Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Report Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="reportOptions" [(ngModel)]="qssParameters.modeReport"
                        [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">WCDMA Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="LTE APN">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="qssParameters.lteApn" maxlength="40" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="LTE APN User Name">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="qssParameters.lteApnUsername" maxlength="30" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="LTE APN Password">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="qssParameters.lteApnPassword" maxlength="30" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Backend Server
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Main Server IP">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="qssParameters.mainServerIp" maxlength="60" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Main Server Port">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="qssParameters.mainServerPort" [min]="0"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">0~65535</span>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Backup Server IP">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="qssParameters.backupServerIp" maxlength="60" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Backup Server Port">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="qssParameters.backupServerPort" [min]="0"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">0~65535</span>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="SMS Gateway">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" pInputText [(ngModel)]="qssParameters.smsGateway" maxlength="20" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Other Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Heartbeat Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="qssParameters.heartbeatInterval" [min]="0"
                                    [max]="360"></p-spinner>
                                <span class="ui-inputgroup-addon">0~360</span>
                                <span class="ui-inputgroup-addon">min</span>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Buffer Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="bufferOptions" [(ngModel)]="qssParameters.modeBuffer"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="SACK Enable">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="sackOptions" [(ngModel)]="qssParameters.modeSack"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                
                </div>
            </p-accordionTab>
        </div>
    </div>
    
</p-accordionTab>