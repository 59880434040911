<p-dialog header="{{title}}" (onAfterHide)="onHide($event)" [(visible)]="display" [responsive]="true" showEffect="fade"
    [modal]="true" *ngIf="display">

    <div class="ui-grid ui-grid-responsive ui-fluid">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>
        <div>
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6">

                    <form-input [required]="true" i18n-fieldName fieldName="{{'tableManagement.vehicleStatus.form.name' | translate}}" [errors]="errors.name">
                        <input pInputText id="name" [(ngModel)]="entity.name" />
                    </form-input>
                </div>
                <div class="ui-g-12 ui-md-6">
                    <form-input i18n-fieldName fieldName="{{'control-panel.poi-categories.poiEnabled' | translate }}" [errors]="errors.enabled">
                        <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
                    </form-input>
                </div>
            </div>
        </div>

    </div>

    <p-footer>

        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <div *ngIf="disableSave == false">
                <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
            </div>
            <div *ngIf="disableSave == true">
                <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
                    <button disabled="true" type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
                </div>
            </div>
            <div *ngIf="isNew == false">
              <button disabled="true" type="button" pButton icon="fa-check" (click)="changeStatus(!entity.enabled)" i18n-label label="{{'general.changeStatus' | translate}}"></button>
            </div>
        </div>


    </p-footer>

</p-dialog>