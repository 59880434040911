import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { Subject } from 'rxjs';

@Injectable()
export class MaintenanceexecutionService extends EntityService<Rest.MaintenanceExecution> {
	client: Rest.RestApplicationClient;
	refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

	constructor(private jquery: JQueryHTTPClient) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	create(arg0: any): Rest.RestResponse<Rest.MaintenanceExecution> {
		return this.client.MaintenanceExecution_create(arg0);
	}

	delete(arg0: Rest.MaintenanceExecution[]): Rest.RestResponse<void> {
		return this.client.MaintenanceExecution_delete(arg0);
	}

	update(arg0: any): Rest.RestResponse<Rest.MaintenanceExecution> {
		return this.client.MaintenanceExecution_update(arg0);
	}

	getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.MaintenanceExecution>> {
		return this.client.MaintenanceExecution_getPage(arg0);
	}

	getPageAndFilters(
		arg0: Rest.ListPaginationRequest
	): Rest.RestResponse<Rest.ListPageAndFilters<Rest.MaintenanceExecution>> {
		return this.client.MaintenanceExecution_getPageAndFilters(arg0);
	}

	// @ts-ignore
	find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.MaintenanceExecution> {
		return this.client.MaintenanceExecution_find(arg0, queryParams);
	}

	createDocument(executionId: number, documentId: number): Rest.RestResponse<Rest.MaintenanceExecution> {
		console.log('[MAINTENANCE-EXECUTION-SERVICE] createDocument ***');
		console.log(executionId);
		console.log(documentId);
		let queryParams = {
			executionId: executionId,
			documentId: documentId,
		};
		return this.client.MaintenanceExecution_addDocument(queryParams);
	}

	getStatusList() : Rest.RestResponse<String[]> {
		return this.client.MaintenanceExecution_getStatusList();
	}

	changeStatus(
		arg0: number[],
		queryParams?: { status?: boolean }
 	 ): Rest.RestResponse<any> {
		console.log("[MAINTENANCE-EXECUTION-SERVICE] changeStatus*** ");
		console.log(arg0);
		console.log(queryParams);
		return this.client.MaintenanceExecution_changeStatus(arg0, queryParams);
  }
}
