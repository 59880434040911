import { LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';


export class TranslationProviders {

    private static supportedLangs = ['es', 'en', 'ca','fr'];

    public static getTranslationFile = (locale?:string): Promise<any> => {
        const noProviders: Object[] = [];
        let navlang = navigator.language;     
        if(navlang.indexOf("-")!=-1)navlang = navlang.split("-")[0];  
        if (TranslationProviders.isLanguageSupported(navlang)) {
            locale = navlang;
        } else {
            locale = 'es'; //default language
        }        

        if (!locale) {
            return Promise.resolve(noProviders);
        }

        const file = `./assets/locale/messages.${locale}.xlf`;
        //const file = `./assets/locale/messages.ca.xlf`;

        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest;
            xhr.open('GET', file);
            xhr.onload = (data: any) => {
                resolve(
                    [
                        { provide: TRANSLATIONS, useValue: data.target.response },
                        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
                        { provide: LOCALE_ID, useValue: locale },
                    ],
                )
            };
            xhr.onerror = () => reject(noProviders);
            xhr.send();
        });
    };

    private static isLanguageSupported(lang: string): boolean {
        const n = this.supportedLangs;
        for (let i = 0; i < n.length; i++) {
            if (n[i] === lang) { return true; }
        }
        return false;
    }
}