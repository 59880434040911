import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RestExtTask } from 'app/modules/task/rest-client-task-extension';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { UserService } from '../../rest/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, Severity } from '../../services/notifications-service/notifications.service';
import rExt= RestExtTask;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
    @ViewChild('menuToggleButton') menuToggleButton: ElementRef;

    routerUrl: string;

    private canSeeRt: boolean;
    private canSeeUtePanel: boolean;
    private canSeeReports: boolean;
    private canSeeSettings: boolean;
    private canSeeRacing: boolean;
    private canSeeTax: boolean;
    private allowedDashboardsErrorContent: string;
    private canSeeSAT: boolean;
    private canSeeInventoryVehicles: boolean;
    private canSeeDrivers: boolean;
    private canSeeMaintenance: boolean;
    private canSeeTableManagement: boolean;
    private canSeeInventoryReports: boolean;
    private canSeeDashboards: boolean;
    private canSeeBoLogistics:boolean;
    private canSeeAppLogistics:boolean;
    private canManageCampaigns: boolean;
    private canSeeTaco: boolean;
    private canSeeBoAdministration:boolean;

    private expanded: boolean = false;

    constructor(private router: Router, private auth: AuthenticationService, private userService: UserService, private translate: TranslateService, private notificationService: NotificationsService) {
        /* Retrieve translation for the allowedDashboards error message */
        /*this.translate.get('sidebar.allowedDashboardsError').subscribe((allowedDashboardsErrorContent) => {
          this.allowedDashboardsErrorContent = allowedDashboardsErrorContent;
        });*/

        this.auth.userObservable.subscribe((user) => {
            if (user) {
                this.updatePermissions();
            }
        })
    }

    ngOnInit(): void {
        //this.updatePermissions();
    }

    toggleMenu() {
        this.menuToggleButton.nativeElement.classList.toggle('opened');
        this.menuToggleButton.nativeElement.setAttribute('area-expanded', this.menuToggleButton.nativeElement.classList.contains('opened'));
        this.expanded = !this.expanded;
    }

    showSidebar() {
        this.routerUrl = this.router.url;

        /* Check the Router location in order to show or hide the header */
        return !(
            this.routerUrl === '/' ||
            this.routerUrl.includes('/login') ||
            this.routerUrl === '/logout' ||
            this.routerUrl === '/404' ||
            this.routerUrl === '/map/sat-map'
        );
    }

    logout() {
        this.auth.logout();
        this.setAllPermissionsToFalse();
    }

    setAllPermissionsToFalse(): void {
        this.canSeeRt = false;
        this.canSeeUtePanel = false;
        this.canSeeReports = false;
        this.canSeeSettings = false;
        this.canSeeSAT = false;
        this.canSeeRacing = false;
        this.canSeeTax = false;
        this.canSeeInventoryVehicles = false;
        this.canSeeDrivers = false;
        this.canSeeMaintenance = false;
        this.canSeeTableManagement = false;
        this.canSeeInventoryReports = false;
        this.canSeeDashboards = false;
        this.canSeeBoLogistics=false;
        this.canManageCampaigns = false;
        this.canSeeAppLogistics = false;
        this.canSeeBoAdministration = false;
        this.canSeeTaco = false;
    }


    updatePermissions(): void {

        this.canSeeRacing = this.auth.user.canSeeRaceEvents;
        this.canSeeTax = this.auth.user.canSeeTax;
        this.canSeeTaco = this.auth.user.canSeeTaco;

        if (!this.auth.isUserLogged()) {
            this.setAllPermissionsToFalse();
        } else {
            this.userService.getUserAllowedDashboards(this.auth.user.id)
            .then((response) => {
                    console.log('+++ updatePerm', response);
                    this.canSeeRt = response.rt;
                    this.canSeeUtePanel = response.COEXPanel;
                    this.canSeeReports = response.reports;
                    this.canSeeSettings = response.controlPanel;
                    this.canSeeSAT = response.sat;
                    this.canSeeRacing = response.racing;
                    this.canSeeTax = response.tax;
                    this.canSeeInventoryVehicles = response.inventoryVehicles;
                    this.canSeeDrivers = response.drivers;
                    this.canSeeMaintenance = response.maintenance;
                    this.canSeeTableManagement = response.tableManagement;
                    this.canSeeInventoryReports = response.inventoryReports;
                    this.canSeeDashboards = response.dashboards;
                    this.canSeeBoLogistics= response.BOLogistics;
                    this.canSeeBoAdministration= response.BOLogistics;
                    if (response.BOLogistics)
                    {
                        this.canSeeBoLogistics= this.showlogisticstask();
                        this.canSeeBoAdministration= this.showadministration();
                    }
                    this.canManageCampaigns = response.campaigns;
                    this.canSeeAppLogistics = response.AppLogistics;
                    this.canSeeTaco = response.taco;
                })
                .catch(() => {
                    this.notificationService.add(Severity.error, 'ERROR', this.allowedDashboardsErrorContent);
                });
        }
    }

     showadministration():boolean{
        var managedelegation =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_DELEGATIONS_VIEW,this.auth.user);
        var manageuserbo = rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_USERSBO_VIEW,this.auth.user);
        var manageuserapp =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_USERSAPP_VIEW,this.auth.user)
        var manageroutes  =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_ROUTES_ADMIN_VIEW,this.auth.user); 
        var managestrore =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_STORES_VIEW,this.auth.user); 
        return managedelegation || manageuserbo|| manageuserapp || manageroutes || managestrore;
    }
    showlogisticstask():boolean{
        var manageroutes =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_ROUTES_VIEW,this.auth.user);
        var manageorders = rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_ORDERS_VIEW,this.auth.user);
        var manageincidences =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_INCIDENCES_VIEW,this.auth.user);
        var managepayment  =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_ORDERS_PAYMENT_VIEW,this.auth.user); 
        var managepackaging =  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_PACKAGING_VIEW,this.auth.user); 
        var manageloginversa=  rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_LOGINVERSE_VIEW,this.auth.user); 
        return manageroutes || manageorders|| manageincidences || managepayment || managepackaging || manageloginversa;
    }

    showPredictionsStack(): boolean {
        var managePredictions = rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_PREDICTION_ARTICLE,this.auth.user);
        var manageClassification = rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_PREDICTION_CLASSIFICATION,this.auth.user);
        var manageAnomalies = rExt.hasPermissionrp(RestExtTask.ActionTaskEnum.TASK_PREDICTION_ANOMALIES,this.auth.user);
        
        return managePredictions || manageClassification || manageAnomalies;
    }

    showRoutesPanel(): boolean {
        return this.canManageCampaigns;
    }

    showSATPanel(): boolean {
        return this.canSeeSAT;
    }

    showUtePanel(): boolean {
        return this.canSeeUtePanel;
    }

    showReports(): boolean {
        return this.canSeeReports;
    }

    showSettings(): boolean {
        return this.canSeeSettings;
    }

    showRt(): boolean {
        return this.canSeeRt;
    }

    showRacing(): boolean {
        return this.canSeeRacing;
    }

    showTax(): boolean {
        return this.canSeeTax;
    }

    showInventoryVehicles(): boolean {
        return this.canSeeInventoryVehicles;
    }

    showDrivers(): boolean {
        return this.canSeeDrivers;
    }

    showMaintenance(): boolean {
        return this.canSeeMaintenance;
    }

    showTableManagement(): boolean {
        return this.canSeeTableManagement;
    }

    showDataImport(): boolean {
        return this.canSeeTableManagement;
    }

    showReportsWizard(): boolean {
        return this.canSeeInventoryReports;
    }

    showDashboards(): boolean {
        return this.canSeeDashboards;
    }
    showBOLogistics(): boolean{
        return this.canSeeBoLogistics;
    }
    showBOAdministration():boolean{
        return this.canSeeBoAdministration;
    }
    showAppLogistics(): boolean{
        return this.canSeeAppLogistics;
    }
    showTaco(): boolean {
        return this.canSeeTaco;
    }

}
