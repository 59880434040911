import { Component, EventEmitter, Output } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LocaleData, defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.css'],
  providers: [I18nService],
})
export class WeekPickerComponent {
  @Output() dateSelected = new EventEmitter<{ startDate: Date, endDate: Date }>();

  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD/MM/YYYY',
    rangeInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: true,
    displayMonths: 1,
    selectWeekDateRange: true,
    selectFromOtherMonth: true,
    selectWeek: true,
    showTodayButton: true,
    showClearButton: true,
    isAnimated: true,
    adaptivePosition: true,
    rangeSeparator: ' - ',
  };
  dateRange: Date[];

  constructor(i18n: I18nService, private localeService: BsLocaleService) {
    // Modify the Spanish locale to start the week on Monday
    const customEsLocale: LocaleData = {
      ...esLocale,
      week: {
        dow: 1, // Monday is the first day of the week
        doy: 4  // The week that contains Jan 4th is the first week of the year
      }
    };

    // Define the modified locale
    defineLocale('es-custom', customEsLocale);
    this.localeService.use('es-custom');
  }

  onValueChange(value: Date[]): void {
    if (value.length === 2) {
      const startDate = new Date(value[0]);
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      const endDate = new Date(value[1]);
      if (endDate.getDay() !== 0) {
        endDate.setDate(endDate.getDate() + (7 - endDate.getDay()));
      }

      this.dateRange = [startDate, endDate];
      this.dateSelected.emit({ startDate: startDate, endDate: endDate });
    }
  }
}
