<p-accordionTab>
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Jamming Behavior Setting (AT + GTJBS)</span>
        </div>
        <div class="right">
            <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="jbsParameters.mode"></p-inputSwitch>
        </div>
      </div>
    </p-header>
  
  
  
    <div class="ui-g" #JammingBehaviousDiv>
      <!-- boolean -->
  
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Siren on timer">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.sirenOnTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535 *100ms</span>
            <!-- This has not a correct UI, should be checked -->
            <!-- <span class="ui-inputgroup-addon" ></span> -->
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Siren off timer">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.sirenOffTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535 *100ms</span>
            <!-- <span class="ui-inputgroup-addon" ></span> -->
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Ready fuel realese timer">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.readyFuelRealeseTimer" [min]="1" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">1~65535</span>
            <span class="ui-inputgroup-addon" >s</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Check speed">
          <p-inputSwitch [(ngModel)]="jbsParameters.checkSpeed"></p-inputSwitch>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Speed Limit">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.speedLimit" [min]="0" [max]="999"></p-spinner>
            <span class="ui-inputgroup-addon">0~999</span>
            <span class="ui-inputgroup-addon" >km/h</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Output 1 init state">
          <p-inputSwitch [(ngModel)]="jbsParameters.output1InitState"></p-inputSwitch>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Motion sensor">
          <p-inputSwitch [(ngModel)]="jbsParameters.motionSensor"></p-inputSwitch>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="GPS fix failure timeout timer">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.gpsFixFailureTimeoutTimer" [min]="1" [max]="100"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon" >min</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Enable Siren">
          <p-inputSwitch [(ngModel)]="jbsParameters.enableSiren"></p-inputSwitch>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Release fuel cut off timer">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="jbsParameters.releaseFuelCutOffTimer" [min]="0" [max]="1000"></p-spinner>
            <span class="ui-inputgroup-addon">0~100</span>
            <span class="ui-inputgroup-addon" >min</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Check Jamming In T3">
          <p-inputSwitch [(ngModel)]="jbsParameters.checkJamingInT3"></p-inputSwitch>
        </form-input>
      </div>
    
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Wiating release fuel timer">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="jbsParameters.wiatingReleaseFuelTimer" [min]="1" [max]="100"></p-spinner>
          <span class="ui-inputgroup-addon">0~100</span>
          <span class="ui-inputgroup-addon" >min</span>
        </div>
      </form-input>
    </div>
  </div>
  </p-accordionTab>