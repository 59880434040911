<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Binding SIM Card
          (AT+GTSIM)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="ICCID Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="modeOptions" [(ngModel)]="simParameters.iccidMode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="ICCID">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="text" pInputText [(ngModel)]="simParameters.iccid" maxlength="20" class="txtbStyle" />
        </div>
      </div>
    </div>

    <div class="col">
      <p-checkbox binary="true" label="Operation Mask" [(ngModel)]="simParameters.unregisterToNetwork">
      </p-checkbox>
    </div>

  </div>
</p-accordionTab>