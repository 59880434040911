import { Component, EventEmitter, Output } from "@angular/core";
import { ConfirmationService, TreeNode } from "primeng/api";
import { TreeModule } from "primeng/tree";

import { DevicetypeService } from "../../../rest/devicetype.service";
import { Rest } from "../../../rest/rest_client";
import { PreconfigurationService } from "../../../rest/preconfiguration.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { LoadingService } from "app/services/loading-service/loading.service";
import { UserTimePipe } from "app/components/shared/UserTimePipe";

@Component({
    selector: "app-device-types-list",
    templateUrl: "./device-types-list.component.html",
    styleUrls: ["./device-types-list.component.css"],
    providers: [PreconfigurationService, UserTimePipe],
})
export class DeviceTypesListComponent extends EntityListComponent<Rest.DeviceType> {
    deviceTypeTree: TreeNode[];
    deviceTypes: Rest.DeviceType[];
    //deviceTypeService: DevicetypeService;

    preConfigService: PreconfigurationService;
    preConfigsList: Rest.DevicePreconfiguration[];

    deviceTypePaginationRequest: Rest.ListPaginationRequest;

    collapse: boolean = false; //Show Expand All or Collapse All button

    //items:any;
    // eslint-disable-next-line
    @Output("nodeSelected")
        // eslint-disable-next-line @angular-eslint/no-output-native
    change: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();

    constructor(
        devicetypeService: DevicetypeService,
        preConfigService: PreconfigurationService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            devicetypeService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        // this.deviceTypeService = devicetypeService;
        this.preConfigService = preConfigService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.deviceTypeTree = [];
        const that = this;
        this.deviceTypePaginationRequest = RestExt.firstPageRequest();
        this.deviceTypePaginationRequest.filters = {};
        this.deviceTypePaginationRequest.pageSize = 100;
        this.service
            .getPage(this.deviceTypePaginationRequest)
            .then(
                function (response) {
                    that.deviceTypes = response.entities;
                    that.fillTree(that.deviceTypes);
                },
                function (error) {
                }
            )
            .catch(function (reason) {
            });
    }

    async fillTree(deviceTypes: Rest.DeviceType[]) {
        this.preConfigsList = (
            await this.preConfigService.getPage(RestExt.allRecords)
        ).entities;
        
        for (var deviceType in deviceTypes) {
            let deviceTypeNode: TreeNode;
            deviceTypeNode = {};
            deviceTypeNode.children = [];
            deviceTypeNode.label = deviceTypes[deviceType].name;
            deviceTypeNode.data = "DeviceType " + deviceTypes[deviceType].id;
            deviceTypeNode.expanded = true;
            deviceTypeNode.selectable = false;

            let preConfigButton: TreeNode;
            preConfigButton = {};
            preConfigButton.children = [];
            preConfigButton.label = this.i18n._("Add PreConfig ");
            preConfigButton.data =
                "AddPreConfig : " + "DeviceType-" + deviceTypes[deviceType].id;

            preConfigButton.type = "button";

            /**Permissions */
            if (this.user.isAdmin) {
                deviceTypeNode.children.push(preConfigButton);
            }

            for (const preConf in this.preConfigsList) {
                if (
                    this.preConfigsList[preConf].deviceType !=
                    deviceTypes[deviceType].name
                )
                    continue;
                let preConfig = {} as any;
                preConfig.children = [];
                preConfig.label = this.preConfigsList[preConf].name;
                preConfig.data = "PreConfig-" + this.preConfigsList[preConf].id;

                deviceTypeNode.children.push(preConfig);
            }
            this.deviceTypeTree.push(deviceTypeNode);
        }
    }

    expandAll() {
        this.deviceTypeTree.forEach((node) => {
            this.expandRecursive(node, true);
        });

        this.collapse = false;
    }

    collapseAll() {
        this.deviceTypeTree.forEach((node) => {
            this.expandRecursive(node, false);
        });

        this.collapse = true;
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    nodeSelect(event) {
        this.change.emit(event.node);
        window.scrollTo(0, 0);
    }

    preConfigExist(type: string, name: string) {
        let exist = false;

        this.deviceTypeTree.forEach(deviceType => {
            if (deviceType.label == type) {
                deviceType.children.forEach(device => {
                    if (device.label == name) {
                        exist = true;
                    }
                })
            }
        });

        return exist;
    }
}
