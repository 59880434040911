<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display">

  <div class="ui-grid ui-grid-responsive ui-fluid">

      <div class="ui-grid-row">
          <div class="ui-grid-col-12">
              <p-messages [(value)]="messages"></p-messages>
          </div>
      </div>
      <div class="ui-g">
          <div class="ui-g-12 ui-md-6">
              <form-input [required]="true" i18n-fieldName fieldName="{{'general.name' | translate}}" [errors]="errors.name">
                  <input pInputText id="name" [(ngModel)]="entity.name" />
              </form-input>
          </div>
          <div class="ui-g-12 ui-md-6">
              <form-input i18n-fieldName fieldName="{{'general.description' | translate}}" [errors]="errors.description">
                  <input pInputText id="description" [(ngModel)]="entity.description" />
              </form-input>
              <div class="ui-g-12 ui-md-6" style="width: 550px">
                  <form-input [required]="true" i18n-fieldName fieldName="{{'control-panel.users.actions' | translate}}" [errors]="errors.actions">
                      <p-listbox [options]="actions" [(ngModel)]="selectedActions" multiple="true" filter="filter" checkbox="true">
                      </p-listbox>
                  </form-input>
              </div>
          </div>
      </div>

  </div>

  <p-footer>

      <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <button type="button" pButton icon="fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
      </div>

  </p-footer>

</p-dialog>