import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../services/notifications-service/notifications.service';
import IconRacing = Rest.IconRacing;

@Injectable()
export class RacingService extends EntityService<Rest.Racing> {
    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient,
        private auth: AuthenticationService,
        private notificationsService: NotificationsService,
    ) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Racing): Rest.RestResponse<Rest.Racing> {
        return this.client.Racing_create(arg0);
    }

    delete(arg0: Rest.Racing[]): Rest.RestResponse<void> {
        return this.client.Racing_delete(arg0[0]);
    }

    update(arg0: Rest.Racing): Rest.RestResponse<Rest.Racing> {
        return this.client.Racing_update(arg0);
    }

    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Racing>> {
        return this.client.Racing_getPage(arg0);
    }

    Racing_gettypecode(): Rest.RestResponse<Rest.EventCodeTypeRacing[]> {
        return this.client.Racing_gettypecode();
    }

    Racing_gettyperacing(): Rest.RestResponse<Rest.EventTypeRacing[]> {
        return this.client.Racing_gettyperacing();
    }

    Racing_downloadimage(arg0: number): Rest.RestResponse<string> {
        return this.client.Racing_downloadimage(arg0);
    }

    getPoiIcons(clientId: number): Rest.RestResponse<IconRacing[]> {
        return this.client.Racing_getPoiIcons(clientId);
    }

    /**
     * addRacing()
     * */
    public addRacing(eventData: string, eventLogoFile?: any, eventTrackFile?: any): Promise<Rest.Racing> {

        /* FORM DATA */
        const formData = new FormData();
        formData.append('logoEvent', eventLogoFile);
        formData.append('trackEvent', eventTrackFile);
        formData.append('eventHistory', eventData);

        return this.http
            .post(environment.webApiBaseUrl + 'api/eventshistory', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    /**
     * updateRacing()
     * */
    public updateRacing(eventData: string, eventLogoFile?: any, eventTrackFile?: any): Promise<Rest.Racing> {

        /* FORM DATA */
        const formData = new FormData();
        formData.append('logoEvent', eventLogoFile);
        formData.append('trackEvent', eventTrackFile);
        formData.append('eventHistory', eventData);

        return this.http
            .post(environment.webApiBaseUrl + 'api/eventshistory/update', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    /**
     * deleteRacing()
     * */
    public deleteRacing(racingEvent: any): Promise<boolean> {

        return this.http.delete(
            environment.webApiBaseUrl + 'api/eventshistory',
            { body: racingEvent },
        ).toPromise()
            .then(() => {
                return true;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return false;
            });
    }

    /**
     * addPoi()
     * */
    addPoi(eventId: number, poiData: string, poiLogoFile?: any): Promise<Rest.POIRacing | null> {

        /* PARAMS */
        const params = new HttpParams().set('eventId', eventId);

        /* FORM DATA */
        const formData = new FormData();
        formData.append('poiLogoIcon', poiLogoFile);
        formData.append('poievents', poiData);

        return this.http
            .post(environment.webApiBaseUrl + 'api/poievents', formData, { params })
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    /**
     * updatePoi()
     * */
    updatePoi(poiData: string, poiLogoFile?: any): Promise<Rest.POIRacing | null> {

        /* FORM DATA */
        const formData = new FormData();
        formData.append('poiLogoIcon', poiLogoFile);
        formData.append('poievents', poiData);

        return this.http
            .post(environment.webApiBaseUrl + 'api/poievents/update', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    /**
     * deletePoi()
     * */
    deletePoi(poiId: number): Promise<boolean> {

        return this.http
            .delete(environment.webApiBaseUrl + 'api/poievents/' + poiId)
            .toPromise()
            .then(() => {
                return true;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return false;
            });
    }
}
