import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { RealtimeService } from "../../../rest/realtime.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService, Severity, } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";
import { VehiclePanelSettingsComponent } from "./settings/vehicle-panel-settings.component";
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';

@Component({
  selector: "app-vehicle-panel",
  templateUrl: "./vehicle-panel.component.html",
  styleUrls: ["./vehicle-panel.component.css"],
  providers: [RealtimeService, ConfirmationService],
})
export class VehiclePanelComponent implements OnInit, OnChanges, OnDestroy {
  vehicles: Rest.Vehicle[];
  _selectedSettings: any;
  private alive: boolean = true;
  private newSetting: boolean = false;

  @Input() selectedSettings: Rest.VehicleDetailsSettings;
  @Output() selectedSettingsChange: EventEmitter<Rest.VehicleDetailsSettings> =
    new EventEmitter<Rest.VehicleDetailsSettings>();
  @ViewChild('closeModalEl') private closeModalEl: ElementRef;
  @ViewChild(VehiclePanelSettingsComponent) vehiclePanelSettingsComponent;

  availableSettings = [];

  /**Settings form vairables */
  private isNew: boolean;
  displaySettingsForm: boolean = false;

  @ViewChild(VehiclePanelSettingsComponent, { static: true })
  vehiclePanelSettings;

  /**Real time fleet tree variables */
  private fleets: Rest.RTFleet[];

  /* TODO: DELETE TEMP VARIABLES: showTable && showGrid */
  showTable: boolean = false;
  showGrid: boolean = false;
  isUserHosp: boolean = false;
  showNewView: boolean = false;

  constructor(
    private i18n: I18nService,
    private rt: RealtimeService,
    private notificationsService: NotificationsService,
    private rtDataService: RealTimeDataService,
    private authenticationService: AuthenticationService
  ) {
    if (this.authenticationService.user.id == 277718) {
      this.isUserHosp = true;
      this.showGrid = true;
      this.showTable = false;
    }
  }

  ngOnInit() {
    this._selectedSettings = Object.assign({}, emptySettings);
    this.settingsChanged();

    this.vehiclePanelSettings.settingsChange
      .takeWhile(() => this.alive)
      .subscribe((s: Rest.VehicleDetailsSettings) => {
        //To force
        this.rtDataService.watchedVehicles.next(
          this.rtDataService.watchedVehicles.getValue()
        );
      });

    /*this.rtDataService.vehicleInfo.subscribe((vi: RestExt.ExtendedVehicleDataMessage) => {
            this.vehicleInfoArr = [... this.rtDataService.vehicleInfoArr.filter(inf=>{return this.rtDataService.watchedVehicles.getValue().findIndex(wv=>wv.id == inf.vehicleId && wv.visibleInMap)!=-1})];
        });*/
    this.vehicles = this.rtDataService.watchedVehicles
      .getValue()
      .filter((v) => v.visibleInMap);
    this.rtDataService.watchedVehicles
      .takeWhile(() => this.alive)
      .subscribe((watched: RestExt.WatchedVehicle[]) => {
        this.vehicles = watched.filter((v) => v.visibleInMap);

        //Discard vehicleInfoArr related to removed vehicles
        //this.vehicleInfoArr = [... this.vehicleInfoArr.filter(inf=>{return watched.findIndex(v=>v.id == inf.vehicleId && v.visibleInMap)!=-1})];
      });
  }

  remove(info: RestExt.ExtendedVehicleDataMessage) {
    let newWatched = this.rtDataService.watchedVehicles.getValue();
    let v = newWatched.find((watched: RestExt.WatchedVehicle) => {
      return info.vehicleId == watched.id;
    });
    if (v != null) v.visibleInMap = false;
    this.rtDataService.watchedVehicles.next(newWatched);
  }

  ngOnChanges(changes) {
    if (changes["selectedSettings"]) {
      if (this.selectedSettings.id == -1) return;
      if (this._selectedSettings.id == this.selectedSettings.id) return;

      //this._selectedSettings = this.selectedSettings;
      Object.assign(this._selectedSettings, this.selectedSettings);


      this.settingsChanged();
    }
  }

  /**
   * Reload the available settings for the user
   */
  settingsChanged() {
    return this.rt
      .getVehicleDetailsSettings()
      .then((settings: Rest.VehicleDetailsSettings[]) => {
        this.availableSettings = [];
        settings.forEach((setting, index) => {
          this.availableSettings.push(setting);

          if (!this.newSetting) {
            if (index == 0) {
              this._selectedSettings = this.availableSettings[index];
            }
            // if (setting.id === this.selectedSettings.id ) {
            if (this.selectedSettings.id == setting.id) {
              if (this.selectedSettings.id == setting.id) {
                this._selectedSettings = this.availableSettings[index];
              }
            }
          }
          else {
            this._selectedSettings = this.availableSettings[index];

          }
        });
        this.newSetting = false;
        /* TODO: FIX THE FOLLOWING MESS */
        /*this._selectedSettings = this.availableSettings[0];*/
        /*
                this.availableSettings = RestExt.entitiesToSelectItems(settings);
        */
        /*        if (this.availableSettings.length == 0)
                  this.availableSettings.push({
                    label: "-",
                    id: -1
                  });
                //There is no selected settings or it is no longer available. Select the first available one
                if (
                  this._selectedSettings == null ||
                  settings.find((as) => as.id == this._selectedSettings.id) == null
                ) {
                  this._selectedSettings = this.availableSettings[0];
                } else {
                  this._selectedSettings = this.availableSettings.find(
                    (as) => as.id == this._selectedSettings.id
                  );
                }
                if (this._selectedSettings.id == -1) {
                  this.selectedSettingsChange.emit(this._selectedSettings);
                } else {
                  //Retrieve the full data associated with the settings
                  this.rt
                    .findVehicleDetailsSettings(this._selectedSettings.id + "")
                    .then((settings: Rest.VehicleDetailsSettings) => {
                      this.availableSettings.find(
                        (as) => as.id == settings.id
                      ).value = settings;
                      this.availableSettings = [...this.availableSettings];
                      this._selectedSettings = settings;
                      this.selectedSettingsChange.emit(settings);
                    });
                }*/

        //To force
        this.rtDataService.watchedVehicles.next(
          this.rtDataService.watchedVehicles.getValue()
        );
      });
  }

  /**
   * Store a new settings object in the server
   */
  create() {
    this._selectedSettings.id = 0;
    this._selectedSettings = this.vehiclePanelSettingsComponent._settings;

    this.newSetting = true;
    this.rt
      .createVehicleDetailsSettings(this._selectedSettings)
      .then((settings: Rest.VehicleDetailsSettings) => {
        this._selectedSettings = settings;
        this.closeModalClick();
        this.settingsChanged();
        this.displaySettingsForm = false;
        this.notificationsService.add(
          Severity.success,
          this.i18n._(":)"),
          this.i18n._("Entity updated")
        );
      });
  }

  /**
   * Update an existing settings object
   */
  update() {
    this._selectedSettings = this.vehiclePanelSettingsComponent._settings;
    this.rt
      .updateVehicleDetailsSettings(this._selectedSettings)
      .then((settings: Rest.VehicleDetailsSettings) => {

        this._selectedSettings = settings;
        this.selectedSettings = settings;
        //   this.selectedSettingsChange.emit(settings);
        this.settingsChanged();
        this.closeModalClick();
        this.displaySettingsForm = false;
        this.notificationsService.add(
          Severity.success,
          this.i18n._(":)"),
          this.i18n._("Entity updated")
        );
      });
  }

  /**
   * Delete an existing settings object
   */
  delete() {
    this.rt
      .deleteVehicleDetailsSettings(this._selectedSettings.id + "")
      .then(res => {
        Object.assign(this._selectedSettings, emptySettings);
        this.closeModalClick();
        this.notificationsService.add(
          Severity.success,
          this.i18n._(":)"),
          this.i18n._("Entity deleted")
        );
        this.settingsChanged();
      });
  }

  openModal() {
    this.newSetting = false;
    Object.assign(this.vehiclePanelSettingsComponent._settings, this._selectedSettings);
    if (this._selectedSettings != null) {
      if (this._selectedSettings.id == -1) {
        this.showNewView = true;
      }
      else {
        this.showNewView = false;
      }
    }
    else {
      this.showNewView = true;
    }
  }

  public closeModalClick() {
    this.closeModalEl.nativeElement.click();
  }

  setNewViewForm() {
    this.showNewView = true;
    Object.assign(this.vehiclePanelSettingsComponent._settings, emptySettings);
    this.vehiclePanelSettingsComponent.initSelectedView(emptySettings.view);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /* TODO: DELETE TEMP FUNCTIONS: table() && grid() */
  table(): void {
    this.showTable = !this.showTable;
  }

  grid(): void {
    this.showGrid = !this.showGrid;
  }
}

const emptySettings = <Rest.VehicleDetailsSettings>{
  id: -1,
  view: "GRID",
  name: "",
  showMap: true,
  showTelemetry: false,
  showQuickActions: false

};
