export const environment = {
production: true,
envName: 'prod',
showMapOnUTERondesVehicleHistory:false,
 webApiBaseUrl:"/cmapi/", // Servidor CoordinationModule
RTAPIBaseUrl:"/rtapi/", // RealTimeApi
RTSocketURL:"wss://iot.atlantisfleet.com/rtsock/", // RealTimeSocketUrl
};


// REAL TIME
// export const environment = {
//   production: true,
//   envName: "vladi",
//   webApiBaseUrl: "http://81.46.226.105/cmapi/", // Server CoordinationModule
//   RTAPIBaseUrl: "http://81.46.226.105/rtapi/", // RealTime API
//   RTSocketURL: "ws://81.46.226.105/rtsock/", // RealTime Socket URL
// };
/*
export const environment = {
  production: true,
  envName: 'prod',
  showMapOnUTERondesVehicleHistory: false,
  webApiBaseUrl: "http://18.202.97.178/cmapi/", // Servidor CoordinationModule
  RTAPIBaseUrl: "http://18.202.97.178/rtapi/", // RealTimeApi
  RTSocketURL: "ws://18.202.97.178/rtsock/", // RealTimeSocketUrl
};*/
//  export const environment = {
//    production: true,
//    envName: 'prod',
//    showMapOnUTERondesVehicleHistory: false,
//    webApiBaseUrl: "http://18.202.97.178/cmapi/", // Servidor CoordinationModule
//   RTAPIBaseUrl: "http://18.202.97.178/rtapi/", // RealTimeApi
// export const environment = {
//     production: true,
//     envName: 'vladi',
//     webApiBaseUrl: 'http://81.46.226.105:10080/cmapi/', // Server CoordinationModule
//     RTAPIBaseUrl: 'http://81.46.226.105:10080/rtapi/', // RealTime API
//     RTSocketURL: 'ws://81.46.226.105:10080/rtsock/', // RealTime Socket URL
// };

/*
export const environment = {
  production: true,
  envName: "ja",
  webApiBaseUrl: "http://81.46.226.105:10080/cmapi/", // Server CoordinationModule
  RTAPIBaseUrl: "http://81.46.226.105:10080/rtapi/", // RealTime API
  RTSocketURL: "ws://81.46.226.105:10080/rtsock/", // RealTime Socket URL
};
*/

/* TEMP - LOCAL NIL */
/*
export const environment = {
  production: true,
  envName: "vladi",
  webApiBaseUrl: "http://192.168.4.19:4200/", // Server CoordinationModule
  RTAPIBaseUrl: "http://192.168.4.19:4400/", // RealTime API
  RTSocketURL: "ws://192.168.4.19:4300/", // RealTime Socket URL
};*/
/*
export const environment = {
  production: false,
  envName: 'nil',
  webApiBaseUrl:"http://localhost:4300/",
  RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
  RTSocketURL:"ws://localhost:4300/"
};
*/
// export const environment = {
//   production: false,
//   envName: 'nil',
//   webApiBaseUrl:"http://localhost:4200/",
//   RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
//   RTSocketURL:"ws://localhost:4300/"
// };
  /*
   export const environment ={
     production: false,
       envName: 'raq',
      showMapOnUTERondesVehicleHistory:false,
      webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
       RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
      RTSocketURL:"ws://localhost:4300/", // RealTimeSocketUrl
  } 
*/
//   export const environment ={
//     production: false,
//       envName: 'raq',
//     showMapOnUTERondesVehicleHistory:false,
//      webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
//      RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
//      RTSocketURL:"ws://localhost:4300/", // RealTimeSocketUrl
//  }
/*
export const environment = {
  production: true,
  envName: 'prod',
  showMapOnUTERondesVehicleHistory: false,
  webApiBaseUrl: "http://18.202.97.178/cmapi/", // Servidor CoordinationModule
  RTAPIBaseUrl: "http://18.202.97.178/rtapi/", // RealTimeApi
  RTSocketURL: "ws://18.202.97.178/rtsock/", // RealTimeSocketUrl
};
*/

//  export const environment ={
//     production: false,
//   envName: 'raq',
//   showMapOnUTERondesVehicleHistory:false,
//    webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
//     RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
//     RTSocketURL:"ws://localhost:4300/", // RealTimeSocketUrl
// }

// export const environment ={
//   production: false,
//  envName: 'raq',
//   showMapOnUTERondesVehicleHistory:false,
//   webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
//   RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
//    RTSocketURL:"ws://localhost:4300/", // RealTimeSocketUrl
//  };

// export const environment = {
//   production: true,
//   envName: 'prod',
//   showMapOnUTERondesVehicleHistory:false,
//   webApiBaseUrl:"https://iot.atlantisfleet.com/cmapi/", // Servidor CoordinationModule
//   RTAPIBaseUrl:"https://iot.atlantisfleet.com/rtapi/", // RealTimeApi
//   RTSocketURL:"wss://iot.atlantisfleet.com/rtsock/", // RealTimeSocketUrl
//     };

/*
export const environment = {
  production: true,
  envName: 'prod',
  showMapOnUTERondesVehicleHistory:false,
  webApiBaseUrl:"https://iot.atlantisfleet.com/cmapi/", // Servidor CoordinationModule
  RTAPIBaseUrl:"https://iot.atlantisfleet.com/rtapi/", // RealTimeApi
  RTSocketURL:"wss://iot.atlantisfleet.com/rtsock/", // RealTimeSocketUrl
    };
*/
/*
export const environment = {
  production: true,
  envName: 'cargol',
  showMapOnUTERondesVehicleHistory:true,
  webApiBaseUrl:"http://172.25.49.11:10003/cmapi/", // Servidor CoordinationModule
  //webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
  RTAPIBaseUrl:"http://172.25.49.11:10003/rtapi/", // RealTimeApi
  RTSocketURL:"ws://172.25.49.11:10003/rtsock/", // RealTimeSocketUrl
};
*/
//PRODUCCIÃƒ
//PRODUCCIÃƒ- RAQUEL EL QUE HABÃƒï¿½A
/*

export const environment = {
  production: true,
  envName: 'acens',
  showMapOnUTERondesVehicleHistory:true,
  webApiBaseUrl:"http://81.46.226.105/cmapi/", // Servidor CoordinationModule
  RTAPIBaseUrl:"http://81.46.226.105/rtapi/", // RealTimeApi
  RTSocketURL:"ws://81.46.226.105/rtsock/", // RealTimeSocketUrl
};
*/
/*
export const environment = {
  production: true,
  envName: 'cargol',
  webApiBaseUrl:"http://84.88.176.103:10003/cmapi/", // Servidor CoordinationModule
  //webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
  RTAPIBaseUrl:"http://84.88.176.103:10003/rtapi/", // RealTimeApi
  RTSocketURL:"ws://84.88.176.103:10003/rtsock/", // RealTimeSocketUrl
};*/

/*
export const environment = {
  production: true,
  envName: 'cargol',
  webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
  RTAPIBaseUrl:"http://172.25.49.11:10003/rtapi/", // RealTimeApi
  RTSocketURL:"ws://172.25.49.11:10003/rtsock/", // RealTimeSocketUrl
};
*/

// export const environment = {
//   production: true,
//   envName: 'nil',
//   //webApiBaseUrl:"http://84.88.176.103:10003/cmapi/", // Servidor CoordinationModule
//   webApiBaseUrl:"http://localhost:4200/", // Servidor CoordinationModule
//   RTAPIBaseUrl:"http://localhost:4300/rtapi", // RealTimeApi
//   RTSocketURL:"ws://localhost:4300/rtsock/", // RealTimeSocketUrl
// };

//PRO/DEV //
// export const environment = {
//   production: true,
//   envName: "xavif",
//   //webApiBaseUrl: "http://18.202.97.178/cmapi/", // Server CoordinationModule
//   RTAPIBaseUrl: "http://18.202.97.178/rtapi/", // RealTime API
//   RTSocketURL: "ws://18.202.97.178/rtsock/", // RealTime Socket URL
//   webApiBaseUrl: "http://localhost:4200/", // Servidor CoordinationModule
//   //RTAPIBaseUrl:"http://localhost:4400/", // RealTimeApi
//   //RTSocketURL:"ws://localhost:4300/", // RealTimeSocketUrl
// };
