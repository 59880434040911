import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class SatRouteReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    allowRetrieveCache = {
        //SAT
        'messages': true,
        'sent-messages': true,
        'received-messages': true,
        'devices': true,
        'users': true,
        //LOGISTICS TASK
        'orders': true,
        'routesg': true,
        'incidences': true,
        'transactions': true,
        'envases':true,
        'logisticainversa':true,
        //ADMIN TASK
        'delegations': true,
        'userbo': true,
        'userapp': true,
        'routes': true,
        'stores': true,

    };

/**
 * shouldDetach()
 * Invoked when leaving current path. If true, the store() method is invoked.
 * */
public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (this.isReusableRoute(route) ) {

        if (this.isReusableRoute(route)) {
            return this.allowRetrieveCache.hasOwnProperty(this.getPath(route));
        }

        return false;
    }
    return false;
}

    /**
     * store()
     * Stores the detached path.
     * */
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    /**
    * shouldAttach()
    * Invoked when entering new path. If true, the retrieve() method is invoked.
    * */
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (this.isReusableRoute(route)) {
            if (this.allowRetrieveCache[this.getPath(route)]) {
                return this.storedRouteHandles.has(this.getPath(route));
            }
        }
        return false;
    }

    /**
    * isSatRoute()
    * */
    private isReusableRoute(route: ActivatedRouteSnapshot): boolean {
        let path = route.parent?.parent?.routeConfig?.path 
        return path === 'sat' ||  path === 'logistics'  
    }

    /**
     * retrieve()
     * Retrieves a detached path.
     * */
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
    }

    /**
     * shouldReuseRoute()
     * Invoked when navigating between routes. If true, routing won't happen.If false, the routing will happen and the rest
     * of the methods will be called.
     * */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, previous: ActivatedRouteSnapshot): boolean {

        /* If navigating between SAT tabs */
       /** SAT  */
      
         //let path = route.parent?.parent?.routeConfig?.path 
        //return path === 'sat' ||  path === 'logistics'
        if (
            (
                this.getPath(future) !== 'messages' &&
                this.getPath(future) !== 'sent-messages' &&
                this.getPath(future) !== 'received-messages' &&
                this.getPath(future) !== 'devices' &&
                this.getPath(future) !== 'users'
            ) && (
                this.getPath(previous) === 'messages' ||
                this.getPath(previous) === 'sent-messages' ||
                this.getPath(previous) === 'received-messages' ||
                this.getPath(previous) === 'devices' ||
                this.getPath(previous) === 'users'
            )
        ) {

            this.allowRetrieveCache['messages'] = false;
            this.allowRetrieveCache['sent-messages'] = false;
            this.allowRetrieveCache['received-messages'] = false;
            this.allowRetrieveCache['devices'] = false;
            this.allowRetrieveCache['users'] = false;
        }

        /* If navigating out of SAT tabs */
        else if (
            (
                this.getPath(future) === 'messages' ||
                this.getPath(future) === 'sent-messages' ||
                this.getPath(future) === 'received-messages' ||
                this.getPath(future) === 'devices' ||
                this.getPath(future) === 'users'
            ) && (
                this.getPath(previous) === 'messages' ||
                this.getPath(previous) === 'sent-messages' ||
                this.getPath(previous) === 'received-messages' ||
                this.getPath(previous) === 'devices' ||
                this.getPath(previous) === 'users'
            )
        ) {
            this.allowRetrieveCache['messages'] = true;
            this.allowRetrieveCache['sent-messages'] = true;
            this.allowRetrieveCache['received-messages'] = true;
            this.allowRetrieveCache['devices'] = true;
            this.allowRetrieveCache['users'] = true;
        }
        /** LOGISTICS  */
         if (
            (
                this.getPath(future) !== 'orders' &&
                this.getPath(future) !== 'routesg' &&
                this.getPath(future) !== 'incidences' &&
                this.getPath(future) !== 'transactions' &&
                this.getPath(future) !== 'envases' &&
                this.getPath(future) !== 'logisticainversa' 
            ) && (
                this.getPath(previous) === 'orders' ||
                this.getPath(previous) === 'routesg' ||
                this.getPath(previous) === 'incidences'  ||
                this.getPath(previous) === 'transactions' ||
                this.getPath(previous) === 'envases' ||
                this.getPath(previous) === 'logisticainversa'
            )
        ) {

            this.allowRetrieveCache['orders'] = false;
            this.allowRetrieveCache['routesg'] = false;
            this.allowRetrieveCache['incidences'] = false;
            this.allowRetrieveCache['transactions'] = false;
            this.allowRetrieveCache['envases'] = false;
            this.allowRetrieveCache['logisticainversa'] = false;
        }

        /* If navigating out of ADMINISTRATION tabs */
        else if (
            (
                this.getPath(future) === 'orders' ||
                this.getPath(future) === 'routesg' ||
                this.getPath(future) === 'incidences'||
                this.getPath(future) === 'transactions' ||
                this.getPath(future) === 'envases' ||
                this.getPath(future) === 'logisticainversa' 
            ) && (
                this.getPath(previous) === 'orders' ||
                this.getPath(previous) === 'routesg' ||
                this.getPath(previous) === 'incidences' ||
                this.getPath(previous) === 'transactions' ||
                this.getPath(previous) === 'envases' ||
                this.getPath(previous) === 'logisticainversa'
            )
        ) {
            this.allowRetrieveCache['orders'] = true;
            this.allowRetrieveCache['routesg'] = true;
            this.allowRetrieveCache['incidences'] = true;
            this.allowRetrieveCache['transactions'] = true;
            this.allowRetrieveCache['envases'] = true;
            this.allowRetrieveCache['logisticainversa'] = true;
        }
        return future.routeConfig === previous.routeConfig;
    }

    /**
     * getPath()
     * */
    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig?.path) {
            return route.routeConfig.path;
        }
        return '';
    }



}
