<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
  [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
  [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
  [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true"
  currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
  styleClass="p-datatable-striped">

  <!-- #region TABLE TITLE DEFINITION -->
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
      {{ 'drivers.table.title' | translate }}
    </div>
  </ng-template>
  <!-- #endregion -->

  <!-- #region TABLE COLUMN DEFINITION -->
  <ng-template pTemplate="header">
    <tr>
      <!-- SELECT -->
      <th class="checkbox-column">
        <div class="d-flex justify-content-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>
      <!-- NAME -->
      <th pSortableColumn="code">
        <div class="d-flex align-items-center h-100">

          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.name' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='code'></p-sortIcon>
            <p-columnFilter field='user' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- DATE BIRTH -->
      <th>
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.birthDate' | translate }}
          </div>
        </div>
      </th>

      <!-- NIF -->
      <th pSortableColumn="nif">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.nif' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='nif'></p-sortIcon>
            <p-columnFilter field='nif' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- DATE EXPIRATION NIF -->
      <th>
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.expirationDateNif' | translate }}
          </div>
        </div>
      </th>

      <!-- DATE EXPEDITION LICENSE -->
      <th>
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.expeditionDateLicense' | translate }}
          </div>
        </div>
      </th>

      <!-- DATE EXPIRATION LICENSE -->
      <th>
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.expirationDateLicense' | translate }}
          </div>
        </div>
      </th>

      <!-- OBSERVATIONS -->
      <th pSortableColumn="observations">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.observations' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='observations'></p-sortIcon>
            <p-columnFilter field='observations' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- DELEGATION -->
      <th pSortableColumn="subdivision.division.code">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.division' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='subdivision.division.code'></p-sortIcon>
            <p-columnFilter field='division' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- SUBDELEGATION -->
      <th pSortableColumn="subdivision.code">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.subdivision' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='subdivision.code'></p-sortIcon>
            <p-columnFilter field='subdivision' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- BILLING ACCOUNT -->
      <th pSortableColumn="ceco.billingAccount.code">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.company' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='ceco.billingAccount.code'></p-sortIcon>
            <p-columnFilter field='billingAccount' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- CECO -->
      <th pSortableColumn="ceco.code">
        <div class='d-flex align-items-center justify-content-center flex-fill h-100'>
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.ceco' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='ceco.code'></p-sortIcon>
            <p-columnFilter field='ceco' type='text' matchMode='contains'
              placeholder="{{ 'general.search' | translate }}" display='menu' [showMatchModes]='false'
              [showOperator]='false' [showAddButton]='false'></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- IMPORT ERP -->
      <th pSortableColumn="importERP">
        <div class="d-flex align-items-center h-100">
          <!-- COLUMN TITLE -->
          <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
            {{ 'drivers.table.importERP' | translate }}
          </div>
          <!-- COLUMN SORT && FILTER ICONS -->
          <div class="d-flex align-items-center h-100">
            <p-sortIcon field="importERP"></p-sortIcon>
            <p-columnFilter field="importERP" type="boolean" display="menu" [showApplyButton]="false"></p-columnFilter>
          </div>
        </div>
      </th>

      <!-- ACTIVE -->
      <th pSortableColumn='disabled'>
        <div class='d-flex align-items-center h-100'>

          <!-- COLUMN TITLE -->
          <div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
            {{ 'general.active' | translate }}
          </div>

          <!-- COLUMN SORT && FILTER ICONS -->
          <div class='d-flex align-items-center h-100'>
            <p-sortIcon field='disabled'></p-sortIcon>
            <p-columnFilter field='disabled' type='boolean' display='menu' [showApplyButton]="false"></p-columnFilter>
          </div>

        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-driver>
    <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(driver)">

      <!-- SELECT -->
      <td>
        <div class="d-flex justify-content-center">
          <p-tableCheckbox [value]="driver"></p-tableCheckbox>
        </div>
      </td>

      <!-- NAME -->
      <td>
        ({{driver.code}}) {{driver.user.surname1}} {{driver.user.surname2}}, {{driver.user.name}}
      </td>

      <!-- DATE BIRTH-->
      <td>
        {{driver.user.birthDate | date: 'dd/MM/yyyy'}}
      </td>

      <!-- NIF -->
      <td>
        {{driver.nif}}
      </td>

      <!-- DATE NIF EXPIRATION -->
      <td>
        {{driver.nifExpirationDate | date: 'dd/MM/yyyy'}}
      </td>

      <!-- EXPEDITION DATE LICENSE -->
      <td>
        <ng-container *ngIf="driver.licenseData?.length > 0">
          {{ driver.licenseData[0].expeditionDate | date: 'dd/MM/yyyy' }}
        </ng-container>
      </td>

      <!-- EXPIRATION DATE LICENSE -->
      <td>
        <ng-container *ngIf="driver.licenseData?.length > 0">
          {{ driver.licenseData[0].expirationDate | date: 'dd/MM/yyyy' }}
        </ng-container>
      </td>

      <!-- OBSERVATIONS -->
      <td>
        {{driver.observations}}
      </td>

      <!-- DIVISION -->
      <td>
        ({{driver.subdivision?.division.code}}) {{driver.subdivision?.division.name}}
      </td>

      <!-- SUBDIVISION -->
      <td>
        ({{driver.subdivision?.code}}) {{driver.subdivision?.name}}
      </td>

      <!-- EMPRESA -->
      <td>
        ({{driver.ceco?.billingAccount.code}}) {{driver.ceco?.billingAccount.name}}
      </td>

      <!-- CECO -->
      <td>
        ({{driver.ceco?.code}}) {{driver.ceco?.description}}
      </td>

      <!-- IMPORT ERP -->
      <td>
        <ng-container *ngIf="driver.importERP"></ng-container>
        {{driver.importERP ? 'Si' : 'No'}}
      </td>

      <!-- ACTIVE -->
      <td>
        <div class="d-flex justify-content-center align-items-center">
          <i class="pi"
            [ngClass]="{'true-icon pi-check-circle': !driver.disabled, 'false-icon pi-times-circle': driver.disabled}"></i>
        </div>
      </td>
    </tr>

  </ng-template>
  <!-- TABLE EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="14" class="p-0">
        <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
          {{ 'table.tableEmptyMessage' | translate }}!
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="14" class="p-0">
        <div class="d-flex flex-row align-items-center p-3 gap-3">

          <!-- ADD DRIVER BUTTON -->
          <app-table-add-button [text]="'drivers.table.drivers' | translate " (btnClick)="create()"
            [isDisabled]='!isCreateGranted'></app-table-add-button>

          <!-- CHANGE STATUS BUTTON -->
          <app-table-change-status-button (btnClick)="changeStatus()"
            [isDisabled]="selectedEntities.length === 0 || !isDisabledGranted"></app-table-change-status-button>

          <!-- EXCEL BUTTON -->
          <app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>


<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
  <app-change-status-shared [items]=" 'drivers.table.drivers' | translate " [display]="true" [service]=driverService
    [entitiesList]=selectedEntities (return)="refreshList($event)"
    (close)="closeStatusDialog($event)"></app-change-status-shared>
</div>

<!-- DRIVER INFO CARD -->
<app-driver-info-card></app-driver-info-card>