import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild,} from "@angular/core";
import {ConfirmationService, SelectItem} from "primeng/api";

import {Rest} from "../../../rest/rest_client";
import {RealtimeService} from "../../../rest/realtime.service";
import {I18nService} from "../../../services/i18n/i18n.service";
import {NotificationsService, Severity,} from "../../../services/notifications-service/notifications.service";
import {RestExt} from "../../../services/rest-client-extension";
import {RealTimeDataService} from "../../../services/rt-data/rt-data.service";
import {FleetTreeComponent} from "../../fleet-edit-view/fleet-tree/fleet-tree.component";
import {RealTimeFleetTreeComponent} from "./fleet-tree/rt-fleet-tree.component";
import {SensorChartComponent} from "./sensor-chart/sensor-chart.component";
import {SensorChartV2Component} from "./sensor-chart-v2/sensor-chart-v2.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-side-panel",
  templateUrl: "./side-panel.component.html",
  styleUrls: ["./side-panel.component.css"],
  providers: [RealtimeService, ConfirmationService],
})
export class SidePanelComponent implements OnInit, OnChanges, OnDestroy {
  selectedElems: RestExt.WatchedVehicle[] = [];
  private alive: boolean = true;
  
  @Input() initialSettings: Rest.FleetTreeSettings;
  @Output() selectedSettingsChange: EventEmitter<Rest.FleetTreeSettings> =
  new EventEmitter<Rest.FleetTreeSettings>();

  hasAvailableSettings: boolean = false;
  availableSettings: SelectItem[];
  _selectedSettings: Rest.FleetTreeSettings;
  private createSettings: Rest.FleetTreeSettings;
  
  /**Settings form vairables */
  private isNew: boolean;
  
  
  /**Fleet filter form variables */
  displayFilterFleeetForm: boolean = false;
  fleetsGroupsAndVehiclesSelected: Object[];
  @ViewChild(RealTimeFleetTreeComponent, {static: true}) rtTree: RealTimeFleetTreeComponent;
  @ViewChild(SensorChartComponent, {static: false}) sensorChart;
  @ViewChild(FleetTreeComponent,{static:false}) tree:FleetTreeComponent;

  
  private ignoreWatchUpdate = false; //Internal flag to avoid the consumption of a self created watchedVehicle updated events
  /**Real time fleet tree variables */
  
  fleets: Rest.RTFleet[];
  deviceSearchString: string;
  
  temporarySensorCharts: Rest.SensorChartSettings = Object.assign({}, emptySensorSettings);
  displaySettingsForm: boolean = false;
  settingsModalVisible: boolean = true;
  panelNameEditActivated: boolean = false;
  originalPanelName: string = "";
  emptySettings: Rest.FleetTreeSettings;

  constructor(
    private i18n: I18nService,
    private rt: RealtimeService,
    private notificationsService: NotificationsService,
    private rtDataService: RealTimeDataService,
    private translate: TranslateService,
  ) {
    this.fleets = [];
  }

  

  ngOnInit() {
    var that = this;
    this.availableSettings = [];
    this._selectedSettings = Object.assign({}, emptySettings);
    this.fleetsGroupsAndVehiclesSelected = [];
    this.settingsChanged();
    //REset the watched vehicles
    this.rtDataService.watchedVehicles.next([]);


 /*
      this.sensorChart.settingsChange.takeWhile(() => this.alive).subscribe((s: Rest.SensorChartSettings) => {
        this.rtDataService.setSensorChartSettings(s);
      });
    */
    
   
    //IF watchedVehicles is updated, its possibe that some vehicles have been set to isVisible=false fleetTree must be updated accordingly
    this.rtDataService.watchedVehicles.takeWhile(() => this.alive).subscribe((watched: RestExt.WatchedVehicle[]) => {
      if (this.ignoreWatchUpdate) {
        this.ignoreWatchUpdate = false;
        return;
      }
      this.ignoreWatchUpdate = false;
      this.selectedElems = [...watched];
    });

    //When user checks/uncheks vehicles from the tree, watchedVehicles must be updated accordingly
    this.rtTree.selectionChange.takeWhile(() => this.alive).subscribe((data: Object[]) => {
      let watched = this.rtDataService.watchedVehicles.getValue();
      let selectedVehicles = this.rtTree.getcheckedVehiclesIds();
      
      watched.forEach((v: RestExt.WatchedVehicle) => {
        v.visibleInMap = selectedVehicles.indexOf(v.id) != -1;
      });
      //this.ignoreWatchUpdate = true;
      this.rtDataService.watchedVehicles.next(watched);
    });

    this.rtDataService.vehicleInfo.takeWhile(() => this.alive).subscribe((vi: RestExt.ExtendedVehicleDataMessage) => {
      this.fleets.forEach((f: Rest.RTFleet) => {
        f.groups.forEach((g: Rest.RTVehiclesGroup) => {
          g.vehicles.forEach((v: Rest.RTVehicle) => {
            if (vi.vehicleId == v.id) {
              Object.assign(v, vi);
            }
          });
        });
      });
    });

    
    this.updateFleetTree();
    this.settingsChanged();

   
    
  }
  ngOnChanges(changes) {
    if (changes["selectedSettings"]) {
      if (this.initialSettings.id == -1) return;
      if (this._selectedSettings.id == this.initialSettings.id) return;
      this._selectedSettings = this.initialSettings;

      var chng = changes as SimpleChange;
      this._selectedSettings = chng.currentValue;
      this.settingsChanged();
    }
    if (changes["sensorChartSettings"]) {
      this._selectedSettings.sensorChartSettings = this.initialSettings.sensorChartSettings;
    }
  }

  updateSettings(settings: Rest.FleetTreeSettings) {
    this._selectedSettings = settings;
  }

  updateFleetTree() {
    this.rt.getFleetTreeSettings().then((settings: Rest.FleetTreeSettings[]) => {
      let selectedSettings = settings.find((a) => a.id == this._selectedSettings.id);

      this.rt.getTree(selectedSettings).then((f: Rest.RTFleet[]) => {
        this.fleets = f;
        this.updateVehicleSubscription();
      });

      let watched = this.rtDataService.watchedVehicles.getValue();
      let selectedVehicles = this.rtTree.getcheckedVehiclesIds();
      watched.forEach((v: RestExt.WatchedVehicle) => {
        v.visibleInMap = selectedVehicles.indexOf(v.id) != -1;
      });
      this.rtDataService.watchedVehicles.next(watched);
    });
  }

  /**
   * Once the real-time fleet tree has changed, subscribe to the new data
   */
  updateVehicleSubscription() {
    let vehiclesIds: number[] = [];
    //Get the Ids of the vehicles
    this.fleets.forEach((f: Rest.RTFleet) => {
      f.groups.forEach((g: Rest.RTVehiclesGroup) => {
        g.vehicles.forEach((v: Rest.RTVehicle) => {
          vehiclesIds.push(v.id);
        });
      });
    });

    //Get the full data associated to the vehicles
    this.rt.getVehicleInfo(vehiclesIds).then((vehicles: Rest.Vehicle[]) => {
      //Notify
      this.rtDataService.watchedVehicles.next(
        vehicles as RestExt.WatchedVehicle[]
      );
    });
  }

  /**
   * Reload the available settings for the user
   */
  settingsChanged() {

    return this.rt.getFleetTreeSettings().then((settings: Rest.FleetTreeSettings[]) => {
      this.availableSettings = RestExt.entitiesToSelectItems(settings);
      this.hasAvailableSettings = settings.length > 0;
      
      
      if (this.hasAvailableSettings) {
        this._selectedSettings = this.availableSettings.find((as) => as.value.id == this._selectedSettings.id).value;
        
        this.rt.findFleetTreeSettings(this._selectedSettings.id + "").then((settings: Rest.FleetTreeSettings) => {
          this.availableSettings.find((as) => as.value.id == settings.id).value = settings;
          this.availableSettings = [...this.availableSettings];
          this._selectedSettings = settings;
          //Update the data for the fleet tree filter

          //Initialize the realtime fleet tree with dummy data
          this.rt.getTree(this._selectedSettings).then((f: Rest.RTFleet[]) => {
              //Manage the subscriptions for real time data
              this.fleets = f;
              this.updateVehicleSubscription();
            });

          this.rtDataService.setSensorChartSettings(
            this._selectedSettings.sensorChartSettings
          );

          this.rtDataService.settingsChange.next(settings.sensorChartSettings);
          this.originalPanelName = this._selectedSettings.name;
          
        });
      }
      this.displayFilterFleeetFormEvent();
    });
  }

  settingsChangedLast() {

    return this.rt.getFleetTreeSettings().then((settings: Rest.FleetTreeSettings[]) => {
      this.availableSettings = RestExt.entitiesToSelectItems(settings);
      this.hasAvailableSettings = settings.length > 0;
      
      
      if (this.hasAvailableSettings) {
        this._selectedSettings = this.availableSettings[this.availableSettings.length - 1].value;
        this.rt.findFleetTreeSettings(this._selectedSettings.id + "").then((settings: Rest.FleetTreeSettings) => {

          this.availableSettings.find((as) => as.value.id == settings.id).value = settings;
          this.availableSettings = [...this.availableSettings];
          this._selectedSettings = settings;

          //Update the data for the fleet tree filter

          //Initialize the realtime fleet tree with dummy data
          this.rt.getTree(this._selectedSettings).then((f: Rest.RTFleet[]) => {
              //Manage the subscriptions for real time data
              this.fleets = f;
              this.updateVehicleSubscription();
            });

          this.rtDataService.setSensorChartSettings(
            this._selectedSettings.sensorChartSettings
          );

          this.rtDataService.settingsChange.next(settings.sensorChartSettings);
          this.originalPanelName = this._selectedSettings.name;
          
        });
        
      }
      this.displayFilterFleeetFormEvent();
    });
  }

  displayFilterFleeetFormEvent() {
    //Force fleet tree to reload
    this.displayFilterFleeetForm = true;
    this.fleetsGroupsAndVehiclesSelected = [];
    if (this._selectedSettings != null) {
      if (this._selectedSettings.visibleFleets)
        this._selectedSettings.visibleFleets.forEach((f: Rest.Fleet) => {
          this.fleetsGroupsAndVehiclesSelected.push(f);
        });
      if (this._selectedSettings.visibleVehicles)
        this._selectedSettings.visibleVehicles.forEach((f: Rest.Vehicle) => {
          this.fleetsGroupsAndVehiclesSelected.push(f);
        });
      if (this._selectedSettings.visibleVehiclesGroups)
        this._selectedSettings.visibleVehiclesGroups.forEach(
          (f: Rest.VehiclesGroup) => {
            this.fleetsGroupsAndVehiclesSelected.push(f);
          }
        );
    }

    this.fleetsGroupsAndVehiclesSelected = [
      ...this.fleetsGroupsAndVehiclesSelected,
    ];
  }

  /**
   * Once the fleet filter form is closed, update the current selected settings with the fleets, vehicles, and groups selected in the form
   */
  updateFleetFilter() {
    this.displayFilterFleeetForm = false;
    this._selectedSettings.visibleFleets = [];
    this._selectedSettings.visibleVehicles = [];
    this._selectedSettings.visibleVehiclesGroups = [];
    this.fleetsGroupsAndVehiclesSelected.forEach((o: Object) => {
      if (FleetTreeComponent.isFleet(o)) {
        this._selectedSettings.visibleFleets.push(o as Rest.Fleet);
      }
      if (FleetTreeComponent.isVehicle(o)) {
        this._selectedSettings.visibleVehicles.push(o as Rest.Vehicle);
      }
      if (FleetTreeComponent.isVehicleGroup(o)) {
        this._selectedSettings.visibleVehiclesGroups.push(
          o as Rest.VehiclesGroup
        );
      }
    });

    if (this.initialSettings != null) {

      this._selectedSettings.visibleFleets = this._selectedSettings.visibleFleets;
      this._selectedSettings.visibleVehicles = this.initialSettings.visibleVehicles;
      this._selectedSettings.visibleVehiclesGroups = this.initialSettings.visibleVehiclesGroups;
    }

    this.rt.getTree(this._selectedSettings).then((f: Rest.RTFleet[]) => {
      this.fleets = f;
      this.updateVehicleSubscription();
    });
  }

  /**
   * Store a new settings object in the server
   */

  duplicate() {
    this._selectedSettings.id = 0;
    this._selectedSettings.sensorChartSettings.id = 0;
    this.rt
      .createFleetTreeSettings(this._selectedSettings)
      .then((settings: Rest.FleetTreeSettings) => {
        this._selectedSettings = settings;
        this.settingsChanged();
        this.notificationsService.add(
          Severity.success,
          this.i18n._(":)"),
          this.i18n._("Entity created")
        );
      });
  }

  createNew() {
    this._selectedSettings.sensorChartSettings = this.sensorChart._settings;
    // this._selectedSettings.sensorChartSettings.showCategoricalValue = this.sensorChart.settings;

    // this.selectedSettings.sensorChartSettings.numericalValuesColors = this.sensorChart.numericalValuesColors;
    
    // this.sensorChart.save();
    // this.sensorChart.settings = this.sensorChart._settings;
    // Object.assign(this.sensorChart.settings, this.sensorChart._settings);
    // this.sensorChart.updateBackingMapping();
    // this.sensorChart.displaySettingsForm = false;
    // this.rtDataService.setSensorChartSettings(this.sensorChart.settings);
    // this.sensorChart.reloadChart();
    
    this.displayFilterFleeetForm = false;
    this._selectedSettings.visibleFleets = [];
    this._selectedSettings.visibleVehicles = [];
    this._selectedSettings.visibleVehiclesGroups = [];
    this.fleetsGroupsAndVehiclesSelected.forEach((o: Object) => {
      if (FleetTreeComponent.isFleet(o)) {
        this._selectedSettings.visibleFleets.push(o as Rest.Fleet);
      }
      if (FleetTreeComponent.isVehicle(o)) {
        this._selectedSettings.visibleVehicles.push(o as Rest.Vehicle);
      }
      if (FleetTreeComponent.isVehicleGroup(o)) {
        this._selectedSettings.visibleVehiclesGroups.push(
          o as Rest.VehiclesGroup
        );
      }
    });
/*
    if (this.initialSettings != null) {
      this._selectedSettings.visibleFleets = this._selectedSettings.visibleFleets;
      this._selectedSettings.visibleVehicles = this.initialSettings.visibleVehicles;
      this._selectedSettings.visibleVehiclesGroups = this.initialSettings.visibleVehiclesGroups;
    }*/

    this.rt.getTree(this._selectedSettings).then((f: Rest.RTFleet[]) => {
      this.fleets = f;
      this.updateVehicleSubscription();
    });
    this._selectedSettings.id = 0;
    this._selectedSettings.sensorChartSettings.id = 0;

    this.rt.createFleetTreeSettings(this._selectedSettings).then((settings: Rest.FleetTreeSettings) => {
      this._selectedSettings = settings;
      this.settingsChangedLast();
      this.displaySettingsForm = false;
      this.notificationsService.add(
        Severity.success,
        "",
        this.translate.instant('general.createSuccessfull')
      );
    }).catch(() =>{
      this.notificationsService.add(
        Severity.error,
        "",
        this.translate.instant('general.createError')
      );
    });

  }

  /**
   * Update an existing settings object
   */
  update() {
    this._selectedSettings.sensorChartSettings = this.sensorChart._settings;
    // this._selectedSettings.sensorChartSettings.showCategoricalValue = this.sensorChart.settings;

    // this.selectedSettings.sensorChartSettings.numericalValuesColors = this.sensorChart.numericalValuesColors;
    
    // this.sensorChart.save();
    // this.sensorChart.settings = this.sensorChart._settings;
    // Object.assign(this.sensorChart.settings, this.sensorChart._settings);
    // this.sensorChart.updateBackingMapping();
    // this.sensorChart.displaySettingsForm = false;
    // this.rtDataService.setSensorChartSettings(this.sensorChart.settings);
    // this.sensorChart.reloadChart();
    
    this.displayFilterFleeetForm = false;
    this._selectedSettings.visibleFleets = [];
    this._selectedSettings.visibleVehicles = [];
    this._selectedSettings.visibleVehiclesGroups = [];
    this.fleetsGroupsAndVehiclesSelected.forEach((o: Object) => {
      if (FleetTreeComponent.isFleet(o)) {
        this._selectedSettings.visibleFleets.push(o as Rest.Fleet);
      }
      if (FleetTreeComponent.isVehicle(o)) {
        this._selectedSettings.visibleVehicles.push(o as Rest.Vehicle);
      }
      if (FleetTreeComponent.isVehicleGroup(o)) {
        this._selectedSettings.visibleVehiclesGroups.push(
          o as Rest.VehiclesGroup
        );
      }
    });
/*
    if (this.initialSettings != null) {
      this._selectedSettings.visibleFleets = this._selectedSettings.visibleFleets;
      this._selectedSettings.visibleVehicles = this.initialSettings.visibleVehicles;
      this._selectedSettings.visibleVehiclesGroups = this.initialSettings.visibleVehiclesGroups;
    }*/

    this.rt.getTree(this._selectedSettings).then((f: Rest.RTFleet[]) => {
      this.fleets = f;
      this.updateVehicleSubscription();
    });

    this.rt.updateFleetTreeSettings(this._selectedSettings).then((settings: Rest.FleetTreeSettings) => {
      this.settingsChanged();
      this.notificationsService.add(
        Severity.success,
        "",
        this.translate.instant('general.updateSuccessfull')
      );
    }).catch(() =>{
      this.notificationsService.add(Severity.error, '', this.translate.instant('general.updateError'))
    });
  }

  /**
   * Delete an existing settings object
   */
  delete() {
    let availableSettingsAfterDelete = this.availableSettings.length - 1;
    if (availableSettingsAfterDelete < 1) {
      let warning = this.translate.instant('real-time.settingsModal.cantDelete');
      this.notificationsService.add(
        Severity.warn,
        "!",
        warning
      );
    } else {
      this.emptySettings = this._selectedSettings;
      this.emptySettings.sensorChartSettings.categoricalValuesMappings = {};
      this.emptySettings.sensorChartSettings.numericalValuesColors = {};
      this.rt.updateFleetTreeSettings(this.emptySettings).then(() => {
        // this.rtDataService.setSensorChartSettings(this.sensorChart._settings);
        this.notificationsService.add(
          Severity.success,
          "",
          this.translate.instant('general.updateSuccessfull')
        );
        this.rt.deleteFleetTreeSettings(this._selectedSettings.id + "").then(() => {
          this.settingsChangedLast();
          this.notificationsService.add(
            Severity.success,
            "",
            this.translate.instant('general.deleteSuccessfull')
          );
        }).catch(() => {
          this.notificationsService.add(Severity.error, '', this.translate.instant('general.deleteError'))
      });
      });
      

        

    }
  }


  ngOnDestroy() {
    this.panelNameEditActivated = false;
    this.alive = false;
  }

  deviceSearch(): void {
    console.log(this.deviceSearchString);
    this.rtTree.search(this.deviceSearchString);
  }

  togglePanelNameEdit() {
    this.panelNameEditActivated = !this.panelNameEditActivated;
  }

  savePanelNameChange() {
    this.update();
    this.togglePanelNameEdit();
  }

  discardPanelNameChange() {
    this._selectedSettings.name = this.originalPanelName;
    this.togglePanelNameEdit();
  }
}

const emptySettings = <Rest.FleetTreeSettings>{
  id: -1,
  visibleFleets: [],
  visibleVehicles: [],
  visibleVehiclesGroups: [],
  sensorChartSettings: {
    id: -1,
    showCategoricalValue: false,
    sensorTag: "",
    categoricalValuesMappings: {},
    numericalValuesColors: {},
    filterType: "SENSOR",
  },
};

const emptySensorSettings = <Rest.SensorChartSettings>{
  id: 0,
  showCategoricalValue: false,
  sensorTag: "",
  categoricalValuesMappings: {},
  numericalValuesColors: {},
  filterType: "SENSOR",
};
