<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible' header='{{ spaceFormTitle }}' [(visible)]='isDialogVisible'
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]='true' [draggable]='false' [resizable]='false'
	[closeOnEscape]='false' [style]="{'width': '95%'}">

	<!-- FORM ERRORS -->
	<div #errorContainer>
		<div *ngIf='errorMessages.length !== 0'>
			<div *ngFor='let error of errorMessages'>
				<div class='alert alert-warning' role='alert'>{{error}}</div>
			</div>
		</div>
	</div>

	<!-- GENERAL PANEL -->
	<p-panel header="{{ 'general.generalInformation' | translate }}"
		[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header>

		<!-- DISABLED ROUTE ADVICE -->
		<div *ngIf="!isNew && !isEnabled" text-align: center>
			<div class='alert alert-warning' role='alert' style="background-color:rgb(197, 35, 35);
			  			color: whitesmoke;
						text-align: center">
				{{ 'promotedRoutes.routeDisabled' | translate }}
			</div>
		</div>

		<!-- SECCION 1 -->
		<div class='d-flex flex-column gap-3 p-3'>

			<!-- ROW 1: NAME -->
			<div class="row align-items-center">

				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'general.name' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input pInputText [(ngModel)]='route.nameRoute' class="txtbStyle" [readonly]='true'
							(click)='onTranslations("name")' />
					</div>
				</div>

				<!-- SHORT DESCRIPTION -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'promotedRoutes.shortDescription' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input pInputText [(ngModel)]='route.shortDescription' class="txtbStyle" [readonly]='true'
							(click)='onTranslations("shortDescription")' />
					</div>
				</div>

			</div>

			<!-- ROW 2: DESCRIPTION & BANNER -->
			<div class="row align-items-center">

				<!-- DESCRIPTION -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input i18n-fieldName fieldName="{{ 'general.description' | translate }}"
							[errors]="errors.description">
						</form-input>
					</div>
					<div class="col">
						<textarea pInputTextarea id="description" [(ngModel)]="route.description" [rows]="3"
							enabled="false" [readonly]='true' (click)='onTranslations("description")'
							class="txtbStyle"></textarea>
					</div>
				</div>

				<!-- BANNER -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input i18n-fieldName fieldName="{{ 'general.image' | translate }}"
							[errors]="errors.description">
						</form-input>
					</div>
					<div class="col">
						<button id='uploadImageButton' (click)='uploadImageInput.click()' class="txtbStyle">
							<img *ngIf='routeImageUrl' id='uploadImageIcon' class='ps-3' alt=''
								src='{{ routeImageUrl }}' class='img-responsive txtbStyle' />
							<img *ngIf='route?.image?.imageEncoded' id='uploadedImage' class='ps-3' alt=''
								[src]='routeEncodedImage' class='img-responsive txtbStyle'>
						</button>
						<input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png'
							(change)='imageChange($event)' class="txtbStyle">
					</div>
				</div>

			</div>

			<!-- ROW 4: INFO LINK  + kms-->
			<div class="row align-items-center">

				<!-- INFO LINK -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='false' fieldName="{{ 'promotedRoutes.infoLink' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input pInputText [(ngModel)]='route.infoLink' [readonly]='true'
							(click)='onTranslations("infoLink")' class="txtbStyle" />
					</div>
				</div>
				<!--
					<a	href=" {{route.infoLink}} ">
						<svg-icon src='assets/icons/link_navigate.svg'
						  class='d-flex flex-wrap custom-table-remove-button-icon'
						  [svgStyle]="{ 'height.px': 25, 'width.px': 25 }">
						</svg-icon>
					</a>
				-->

				<!-- TOTAL KMS I TIME -->
				<div class="col">
					<div class="col row dvcFields">
						<div class="col lblStyle">
							<form-input [required]='true' fieldName="{{ 'promotedRoutes.totalKms' | translate }} (Kms)"
								[errors]='errors.name'>
							</form-input>
						</div>
						<div class="col">
							<input pInputText [(ngModel)]='route.totalKms' class="txtbStyle" />
						</div>
					</div>

					<div class="col row dvcFields">
						<div class="col lblStyle">
							<form-input [required]='true' fieldName="{{ 'promotedRoutes.totalTime' | translate }}"
								[errors]='errors.name'>
							</form-input>
						</div>
						<div class="col">
							<input pInputText [(ngModel)]='route.totalTime' [readonly]='true'
								(click)='onTranslations("totalTime")' class="txtbStyle" />
						</div>
					</div>
				</div>

			</div>

			<!-- ROW 5: SHARED LINK + category -->
			<div class="row align-items-center">

				<!-- shared link -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='false' fieldName="{{ 'promotedRoutes.sharedLink' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input pInputText [(ngModel)]='route.sharedLink' [readonly]='true'
							(click)='onTranslations("sharedLink")' class="txtbStyle" />
					</div>
				</div>
				<!-- 
					<button type='button'
						class='btn custom-table-remove-button'
						href={{route.sharedLink}}>
						<svg-icon src='assets/icons/link_navigate.svg'
						  	class='d-flex flex-wrap custom-table-remove-button-icon'
						  	[svgStyle]="{ 'height.px': 25, 'width.px': 25 }">
						</svg-icon>
					</button>
				-->

				<!-- Category -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='true' fieldName="{{ 'promotedRoutes.category' | translate }}">
						</form-input>
					</div>
					<div class="col">
						<p-dropdown [options]='categoryList' [(ngModel)]='route.category' dataKey='id'
							optionLabel='nameCategory' [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
					</div>
				</div>

			</div>

			<!-- ROW 6: ACTIVITIES LINK -->
			<div class="row align-items-center">

				<!-- activities link -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='false' fieldName="{{ 'promotedRoutes.activitiesLink' | translate }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<input pInputText [(ngModel)]='route.activitiesLink' [readonly]='true'
							(click)='onTranslations("activitiesLink")' class="txtbStyle">
					</div>
				</div>
				<!-- 
					<button type='button'
						class='btn custom-table-remove-button'
						href={{route.activitiesLink}}>
						<svg-icon src='assets/icons/link_navigate.svg'
						  	class='d-flex flex-wrap custom-table-remove-button-icon'
						  	[svgStyle]="{ 'height.px': 25, 'width.px': 25 }">
						</svg-icon>
					</button> 
				-->

				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='false' fieldName="{{ ('promotedRoutes.enabledSpace' | translate) }}"
							[errors]='errors.name'>
						</form-input>
					</div>
					<div class="col">
						<p-inputSwitch #toggle [(ngModel)]="route.enabled" [disabled]="false"
							(onChange)="toggleActivation($event)"></p-inputSwitch>
					</div>
				</div>
				<!-- 
					<button type='button' class='btn custom-button' (click)='toggleActivation()' [disabled]='isEditingDisabled'>
						{{ campaign.active ? ('promotedRoutes.disabled' | translate) : ('promotedRoutes.enabled' | translate) }}
					</button>
				-->
				<div *ngIf="changesPending" style='color: red; padding-left: "20px"'>
					* {{ 'promotedRoutes.rememberToSave' | translate }}
				</div>

			</div>

		</div>

		<!-- PIE DE PAGINA -->
		<!-- <p-footer>
			<div class='d-flex flex-row-reverse bd-highlight'>
				<div class='p-2 bd-highlight'>
					<button type='button' class='btn custom-button' (click)='toggleActivation()' [disabled]='isEditingDisabled'>
						{{ route.enabled ? ('promotedRoutes.disabled' | translate) : ('promotedRoutes.enabled' | translate) }}
					</button>
				</div>
			</div>
		</p-footer> -->

	</p-panel>

	<!-- POI PANEL -->
	<p-panel [style]="{'margin-top': '16px', 'margin-bottom': '16px'}" header="{{ 'events.trackAndPois' | translate }}"
		[ngClass]="isEditingDisabled ? 'disabledPanel' : ''">
		<div class='d-flex flex-column gap-3 p-3'>

			<!-- ROW 1 -->
			<div class="row align-items-center">

				<!-- FILE TRACK -->
				<div class="col row dvcFields">
					<div class="col lblStyle">
						<form-input [required]='false' fieldName="{{ 'events.trackFile' | translate }}" [errors]=''>
						</form-input>
					</div>
					<div class="col">
						<input *ngIf="route.track !== null" pInputText [readonly]='true' [(ngModel)]='route.track.name'
							disabled="true" class="txtbStyle" >
						<input *ngIf="route.track === null" pInputText [disabled]='true'
							placeholder="{{ 'promotedRoutes.phTrackFileName' | translate }}" disabled="true"
							class="txtbStyle"/>
					</div>

					<!-- KML UPLOAD -->
					<button id='uploadKmlButton' class='btn btn-outline-dark'
						(click)='uploadKmlInput.click()'>...</button>
					<input #uploadKmlInput [hidden]='true' type='file' accept='.kml' (change)='changeKml($event)' class="txtbStyle">
				</div>

			</div>

			<div class='d-flex flex-column'>

				<!-- CREATE POIS WARNING -->
				<div *ngIf='isNew'>
					<div class='alert alert-warning' role='alert'>
						{{ 'events.cannotAddPois' | translate}}!
					</div>
				</div>

				<div class="row align-items-center">

					<div class="col row dvcFields">
						<app-custom-map #eventsMap mapId='eventsMap' display='true' class="mapStyle"
						[racingPois]='poiList' [options]='customMapOptions'
						(eventCreatePOI)='addPoi($event)'></app-custom-map>

						<div class='d-flex flex-row-reverse bd-highlight'>
							<div class='p-2 bd-highlight'>
								<app-table-add-button [text]=" 'promotedRoutes.pois.poimanually' | translate "
									(btnClick)="addPoiManual()"></app-table-add-button>
							</div>
						</div>
					</div>
				
					<div class="col row dvcFields">
						<p-table [value]='poiList' dataKey='id' [rows]='10' [rowsPerPageOptions]='[10,20,30,40,50]'
							[paginator]='true' [rowHover]='true' [showCurrentPageReport]='true' [autoLayout]='true'
							[ngClass]="{'disabledPanel': isNew}"
							currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
							styleClass='p-datatable-striped'>

							<!-- Table Title -->
							<ng-template pTemplate='caption'>
								<div class='p-d-flex p-ai-center p-jc-between text-center'>POIs</div>
							</ng-template>

							<!-- Table Column/Header Definition -->
							<ng-template pTemplate='header'>
								<tr>
									<th>
										<div class='p-d-flex p-jc-between p-ai-center text-center'>
											{{ 'events.pois.namePoi' | translate }}
										</div>
									</th>
									<th style='width: 3rem;'>
									</th>
								</tr>
							</ng-template>

							<!-- Table Row Definition -->
							<ng-template pTemplate='body' let-poi let-index='rowIndex'>
								<tr class='p-selectable-row' (dblclick)='editPoi(poi)'>
									<td style='text-align: center;'>
										{{poi.namePoi}}
									</td>

									<td style='width: 3rem'>
										<div class='d-flex'>
											<button type='button' id='deletePoiButton'
												class='btn custom-table-remove-button' (click)='deletePoi(poi, index)'>
												<svg-icon src='assets/icons/trash.svg' id='deletePoiIcon'
													class='d-flex flex-wrap custom-table-remove-button-icon'
													[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"></svg-icon>
											</button>
										</div>
									</td>

								</tr>

							</ng-template>

							<!-- TABLE EMPTY MESSAGE -->
							<ng-template pTemplate='emptymessage'>
								<tr>
									<td colspan='2' class='p-0'>
										<div class='d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message'
											i18n>
											{{ 'table.tableEmptyMessage' | translate }}!
										</div>
									</td>
								</tr>
							</ng-template>

						</p-table>
					</div>
				
				</div>

			</div>

		</div>

	</p-panel>

	<!-- POI FORM-->
	<app-poi-routes-form #poiRoutesForm *ngIf='!isNew' [route]='route' [selectedPoi]='selectedPoi'
		(poiAdded)='addPoi($event)' (poiSaved)='savePoi($event)'></app-poi-routes-form>


	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class='d-flex flex-row-reverse bd-highlight'>
			<div class='p-2 bd-highlight'>
				<button type='button' class='btn custom-button' (click)='saveRoute()' [disabled]='isEditingDisabled'>
					{{ 'general.save' | translate }}
				</button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<!-- DELETE EVENT DIALOG -->
<p-confirmDialog #deleteEventDialog [header]=" 'control-panel.delete-dialog.title' | translate " class='custom-dialog'
	icon='pi pi-exclamation-triangle'>
	<ng-template pTemplate="footer">

		<!-- REJECT BUTTON -->
		<button (click)="deleteEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>

		<!-- ACCEPT BUTTON -->
		<button (click)="deleteEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>

	</ng-template>
</p-confirmDialog>