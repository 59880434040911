import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
  selector: "app-track-event-config-form-vt200",
  templateUrl: "./track-event-config-form-vt200.component.html",
  styleUrls: ["./track-event-config-form-vt200.component.css"],
})
export class TrackEventConfigFormVt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @ViewChild("ContentDiv") ContentDiv;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() trackEvent: Rest.TrackEventConfigurationVT200;

  TrackMode: any = [
    { label: "DISABLE", value: 0 },
    { label: "TIME", value: 1 },
    { label: "DISTANCE", value: 2 },
    { label: "TIME_AND_DISTANCE", value: 3 },
    { label: "TIME_OR_DISTANCE", value: 4 },
    { label: "HEADING", value: 5 },
    { label: "HEADING_OR_TIME", value: 6 },
    { label: "HEADING_OR_DISTANCE", value: 7 },
    { label: "HEADING_OR_TIME_AND_DISTANCE", value: 8 },
    { label: "HEADING_OR_TIME_OR_DISTANCE", value: 9 },
  ];

  TrackBasis: any = [
    { label: "GPS_FIXED", value: 0 },
    { label: "ANY_GPS", value: 1 },
    { label: "ACC_ON_AND_GPS_FIXED", value: 2 },
    { label: "ACC_ON_AND_ANY_GPS", value: 3 },
  ];

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.ContentDiv.nativeElement.classList.remove("disableDIV");
    }
  }
  trackModeSwitch = false;
  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }

  onChange() {}
}
