import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-digital-output-port-setting-gmt200',
  templateUrl: './digital-output-port-setting-gmt200.component.html',
  styleUrls: ['./digital-output-port-setting-gmt200.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DigitalOutputPortSettingGmt200Component extends ServerValidatedFormComponent implements OnInit, OnChanges {

  @Input() outParameters: Rest.DigitalOutputPortSettingsGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.outParameters);
    }
  }

}
