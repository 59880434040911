<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Speed Event Configuration ($WP+SPD)</span>
            </div>
        </div>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="SpeedEvent Mode" >
          <p-dropdown   [options]="SpeedEventMode"
          [(ngModel)]="speedEvent.mode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
          <div class="ui-g-12 ui-md-6">
              <form-input  fieldName="Min Speed" >
                <div class="ui-inputgroup">
                  <p-spinner size="5" [(ngModel)]="speedEvent.minimumSpeed" [min]="0" [max]="255" (onChange)="onChange()"></p-spinner>
                  <span class="ui-inputgroup-addon">0~255</span>
                  <span class="ui-inputgroup-addon" >km</span>
                </div>
              </form-input>
            </div>
            <div class="ui-g-12 ui-md-6">
                <form-input  fieldName="Max Speed" >
                  <div class="ui-inputgroup">
                    <p-spinner size="5" [(ngModel)]="speedEvent.maximumSpeed" [min]="0" [max]="255" (onChange)="onChange()"></p-spinner>
                    <span class="ui-inputgroup-addon">0~255</span>
                    <span class="ui-inputgroup-addon" >km</span>
                  </div>
                </form-input>
              </div>
  
  
                
              <div class="ui-g-12 ui-md-6">
                  <form-input  fieldName="Output port Mode" >
                    <p-dropdown  [options]="OutputPortsModes"
                    [(ngModel)]="speedEvent.outputPort" [style]="{'width':'190px'}"></p-dropdown>
                  </form-input>
              </div>
              <div class="ui-g-12 ui-md-6">
                  <form-input  fieldName="Output Control">
                    <p-inputSwitch [(ngModel)]="speedEvent.outputControl"></p-inputSwitch>
                  </form-input>
                </div>   
                <div class="ui-g-12 ui-md-6">
                    <form-input  fieldName="Speeding Modes" >
                      <p-dropdown  (onChange)="trackEventModeChange(speedEvent.speedingMode,$event)"[options]="SpeedEventSpeedingModes"
                      [(ngModel)]="speedEvent.speedingMode" [style]="{'width':'190px'}"></p-dropdown>
                    </form-input>
                </div>
      <div class="ui-g-12 ui-md-6" [class.disableDIV]=!trackModeSwitch>
        <form-input  fieldName="Off speeding Duration" >
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="speedEvent.offSpeedingDuration" [min]="0" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">0~65535</span>
            <span class="ui-inputgroup-addon" >s</span>
          </div>
        </form-input>
      </div>
    </div>
  
  </p-accordionTab>
