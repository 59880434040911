<p-dialog header="{{ title }}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true"
	*ngIf="display" appendTo="body">
	<!-- #region BODY -->
	<div class="d-flex flex-column gap-3 p-3">

		<div #errorContainer>
			<div *ngIf='errorMessages.length !== 0'>
				<div *ngFor='let error of errorMessages'>
					<div class='alert alert-warning' role='alert'>{{error}}</div>
				</div>
			</div>
		</div>

		<!-- CONTRACT DATA PANEL -->
		<p-panel header="{{ 'drivers.form.contractData' | translate }}" [toggleable]="true" class="w-100">
			<!-- PROVIDER & FINANCING TYPE -->
			<div class="row mt-3">
				<!-- FINANCING TYPE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="financing-type" class="custom-label" i18n>
								<strong>{{ 'tableManagement.financing.financingType' | translate }} (*)</strong>
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="financeTypeList" [(ngModel)]="entity.financingType"
								[placeholder]="financeTypePlaceholder" dataKey="id" optionLabel="name"
								[style]="{ width: '250px' }" appendTo="body" [filter]="true" filterBy="name"
								(onChange)="onChangeFinanceType($event)" (onFilter)="onFilterFinanceType($event)"
								(ngModelChange)="ownVehicle = $event?.ownVehicle || false"
								[disable]="saving || !formManipulation">
							</p-dropdown>
						</div>
					</div>
				</div>

				<!-- PROVIDER Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="provider" class="custom-label" i18n>
								<strong>{{ 'tableManagement.providers.provider' | translate }} (*)</strong>
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="providerList" [(ngModel)]="provider"
								[placeholder]="providerPlaceholder" dataKey="id" optionLabel="name"
								[style]="{ width: '250px' }" appendTo="body" [filter]="true" filterBy="name"
								(onChange)="onChangeProvider($event)" [disable]="saving || !formManipulation">
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>

			<!-- CONTRACT & INTEREST RATE -->
			<div class="row mt-3">
				<!-- CONTRACT Input -->
				<div class="col-sm-6">
					<label for="contract" class="custom-label" i18n>
						<strong>{{'tableManagement.financing.contract' | translate}} (*)</strong>
					</label>
					<input type="text" id="contract" class="form-control custom-input" [(ngModel)]="entity.contract"
						[disabled]="saving || !formManipulation" />
				</div>

				<!-- INTEREST RATE Input -->
				<div class="col-sm-6">
					<label for="interest-rate" class="custom-label" i18n>
						{{'tableManagement.financing.interestRate' | translate}}
					</label>

					<div class="p-0"></div>

					<div class="col-7 input-wrapper">
						<p-inputNumber [(ngModel)]="entity.interestRate" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>
						<span class="input-symbol">%</span>
					</div>
				</div>
			</div>

			<!-- START DATE KM & END DATE -->
			<div class="row mt-3">
				<!-- START DATE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="start-date" class="custom-label" i18n>
								<!-- If the ownVehicle is set to true will show the first translation -->
								<strong>{{ ownVehicle ? ('general.buyDate' | translate) : ('general.startDate' |
									translate) }} (*)</strong>
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-calendar [(ngModel)]="startDateFinancing" id="start-date" [showTime]="false"
								dateFormat='{{i18n.dateFormat}}' showIcon="true" appendTo="body"
								[disabled]="saving || !formManipulation"></p-calendar>
						</div>
					</div>
				</div>

				<!-- END DATE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="end-date" class="custom-label" i18n>
								{{ 'general.endDate' | translate }}
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-calendar [(ngModel)]="endDateFinancing" id="end-date" [showTime]="false"
								dateFormat='{{i18n.dateFormat}}' showIcon="true" appendTo="body"
								[disabled]="ownVehicle || saving || !formManipulation"></p-calendar>
						</div>
					</div>
				</div>
			</div>

			<!-- CONTRACT KM & MONTHLY FEE -->
			<div class="row mt-3">
				<!-- CONTRACT KM Input -->
				<div class="col-md-6">
					<label for="contract-km" class="custom-label" i18n>
						{{'tableManagement.financing.contractKms' | translate}}
					</label>

					<div class="p-0"></div>

					<div class="input-wrapper">
						<input type="number" id="contract-km" class="form-control custom-input"
							[(ngModel)]="entity.contractKm" [disabled]="saving" />
						<span class="input-symbol">km</span>
					</div>
				</div>

				<!-- MONTHLY FEE Input -->
				<div class="col-md-6">
					<label for="monthly-fee" class="custom-label" i18n>
						{{'tableManagement.financing.monthlyFee' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<!-- Display this input when showValues is true -->
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.monthlyFee" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<!-- Display this input when showValues is false -->
						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- INVESTMENT COST & EXCESS COST -->
			<div class="row mt-3">
				<!-- INVESTMENT COST Input -->
				<div class="col-md-6">
					<label for="investment-cost" class="custom-label" i18n>
						{{ ownVehicle ? ('tableManagement.financing.buyingCost' | translate) :
						('tableManagement.financing.investmentCost' | translate)}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.investmentCost" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>

				<!-- EXCESS COST Input -->
				<div class="col-md-6">
					<label for="excess-cost" class="custom-label" i18n>
						{{'tableManagement.financing.excessCost' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.excessCost" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- DEFAULT COST -->
			<div class="row mt-3">
				<div class="col-md-6">
					<label for="investment-cost" class="custom-label" i18n>
						{{ 'tableManagement.financing.defaultCost' | translate }}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.unrealizedKmCost" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>
		</p-panel>

		<!-- FINANCING DATA PANEL -->
		<p-panel header="{{ 'drivers.form.contabilityData' | translate }}" [toggleable]="true" class="w-100">
			<!-- PFF VALUE & OPTIONS VALUE -->
			<div class="row mt-3">
				<!-- PFF VALUE Input -->
				<div class="col-md-6">
					<label for="pff-value" class="custom-label" i18n>
						{{'tableManagement.financing.pffValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.pffValue" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>

				<!-- OPTIONS VALUE Input -->
				<div class="col-md-6">
					<label for="options-value" class="custom-label" i18n>
						{{'tableManagement.financing.optionsValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.optionsValue" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- EXTRAS VALUE & TAX BASE VALUE -->
			<div class="row mt-3">
				<!-- EXTRAS VALUE Input -->
				<div class="col-md-6">
					<label for="extras-value" class="custom-label" i18n>
						{{'tableManagement.financing.extrasValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.extrasValue" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>

				<!-- TAX BASE VALUE Input -->
				<div class="col-md-6">
					<label for="tax-base-value" class="custom-label" i18n>
						{{'tableManagement.financing.taxBaseValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.taxBaseValue" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="true"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- VAT & VAT VALUE -->
			<div class="row mt-3">
				<!-- VAT Input -->
				<div class="col-md-6">
					<label for="vat" class="custom-label" i18n>
						{{ 'tableManagement.financing.vat' | translate }}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="vatPercentage" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">%</span>
					</div>
				</div>

				<!-- VAT VALUE Input -->
				<div class="col-md-6">
					<label for="vat-value" class="custom-label" i18n>
						{{ 'tableManagement.financing.vatValue' | translate }}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.vatValue" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="true"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- TOTAL VALUE & CANCELATION VALUE / AMORTIZATION PERIOD -->
			<div class="row mt-3">
				<!-- TOTAL VALUE Input -->
				<div class="col-md-6">
					<label for="total-value" class="custom-label" i18n>
						{{'tableManagement.financing.totalValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.totalValue" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" (ngModelChange)="onValuesChange()"
							[disabled]="true"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>
		</p-panel>

		<!-- VEHICLE AND COMPLEMENTARY DATA PANEL -->
		<p-panel header="{{ 'drivers.form.financingData' | translate }}" [toggleable]="true" class="w-100">
			<!-- VEHICLE & REPLACED VEHICLE -->
			<div class="row mt-3">
				<!-- VEHICLE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="vehicle" class="custom-label" i18n>
								{{ 'tableManagement.vehicle.vehicle' | translate }}
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="vehicleList" [(ngModel)]="entity.vehicle"
								[disabled]="vehicle || saving || !formManipulation" placeholder="{{ 'general.select' | translate }} {{
									'tableManagement.vehicle.vehicle' | translate
								}}" dataKey="id" optionLabel="name" [style]="{ width: '250px' }" appendTo="body" [filter]="true"
								filterBy="name" (onChange)="onChangeVehicle($event)"
								(onFilter)="onFilterVehicle($event)">
							</p-dropdown>
						</div>
					</div>
				</div>

				<!-- REPLACED VEHICLE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="replaced-vehicle" class="custom-label" i18n>{{
								'tableManagement.financing.replacedVehicle' | translate }}
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="replacedVehicleList" [(ngModel)]="entity.replacedVehicle"
								placeholder="{{ 'general.select' | translate }} {{
									'tableManagement.vehicle.vehicle' | translate
								}}" dataKey="id" optionLabel="name" [style]="{ width: '250px' }" appendTo="body" [filter]="true"
								filterBy="name" (onChange)="onChangeReplacedVehicle($event)"
								(onFilter)="onFilterReplacedVehicle($event)" [disabled]="saving || !formManipulation">
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>

			<!-- BUY BACK VALUE & KM ADJUSTMENT-->
			<div class="row mt-3">
				<!-- INVESTMENT COST Input -->
				<div class="col-md-6">
					<label for="buyback-value" class="custom-label" i18n>
						{{'tableManagement.financing.buybackValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.buyBackValue" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>

				<!-- KM ADJUSTMENT Input -->
				<div class="col-md-6">
					<label for="km-adjustment" class="custom-label" i18n>
						{{'tableManagement.financing.kmAdjustment' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber *ngIf="showValues" [(ngModel)]="entity.kmAdjustment" mode="decimal"
							locale="es-ES" (onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>

						<p-inputNumber *ngIf="!showValues" [ngModel]="''" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>

			<!-- WAY TO PAY & RENTING TYPE -->
			<div class="row mt-3">
				<!-- WAY TO PAY Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="way-to-pay" class="custom-label" i18n>
								<strong>{{ 'tableManagement.wayToPay.wayToPay' | translate }} (*)</strong>
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="wayToPayList" [ngModel]="entity.wayToPay"
								[placeholder]="wayToPayPlaceholder" dataKey="id" optionLabel="name"
								[style]="{ width: '250px' }" appendTo="body" [filter]="true" filterBy="name"
								(onChange)="onChangeWayToPay($event)" (onFilter)="onFilterWayToPay($event)"
								[disabled]="saving || !formManipulation">
							</p-dropdown>
						</div>
					</div>
				</div>

				<!-- RENTING TYPE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="renting-type" class="custom-label" i18n>
								{{ 'tableManagement.rentingTypes.rentingType' | translate }}
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-dropdown [options]="rentingTypeList" [ngModel]="entity.rentingType"
								[placeholder]="rentingTypePlaceholder" dataKey="id" optionLabel="name"
								[style]="{ width: '250px' }" appendTo="body" [filter]="true"
								[disabled]="ownVehicle || saving || !formManipulation" filterBy="name"
								(onChange)="onChangeRentingType($event)" (onFilter)="onFilterRentingType($event)">
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>

			<!-- OBSERVATIONS -->
			<div class="row mt-3">
				<div class="col-md-6">
					<label for="observations" class="custom-label" i18n>{{ 'general.observations' | translate }}</label>
					<textarea rows="2" cols="80" pInputTextarea [pTooltip]="entity.observations" tooltipPosition="top"
						[(ngModel)]="entity.observations" class="observations" fitContent="true"
						[disabled]="saving || !formManipulation"></textarea>
				</div>
			</div>
		</p-panel>

		<p-panel header="{{ 'drivers.form.cancelationData' | translate }}" [toggleable]="true" class="w-100">
			<!-- CANCELATION DATE & CANCELATION KM -->
			<div class="row mt-3" *ngIf="!ownVehicle">
				<!-- CANCELATION DATE Input -->
				<div class="col-md-6">
					<div class="row">
						<div class="col-12">
							<label for="cancelation-date" class="custom-label" i18n>
								{{ 'tableManagement.financing.cancelationDate' | translate }}
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<p-calendar [(ngModel)]="cancellationDateFinancing" id="cancelation-date" [showTime]="false"
								dateFormat='{{i18n.dateFormat}}' showIcon="true" appendTo="body"
								[disabled]="saving || !formManipulation"></p-calendar>
						</div>
					</div>
				</div>

				<!-- CANCELATION KM Input -->
				<div class="col-md-6">
					<label for="cancellation-km" class="custom-label" i18n>
						{{ 'tableManagement.financing.cancelationKm' | translate }}
					</label>

					<div class="input-wrapper">
						<input type="number" id="cancellation-km" class="form-control custom-input"
							[(ngModel)]="entity.cancellationKm" [disabled]="savingv || !formManipulation" />
						<span class="input-symbol">km</span>
					</div>
				</div>
			</div>

			<!-- AMORTIZATION PERIOD || CANCELATION VALUE -->
			<div class="row mt-3">
				<!-- AMORTIZATION PERIOD Input -->
				<div class="col-md-6" *ngIf="ownVehicle">
					<label for="amortization-period" class="custom-label" i18n>
						{{ 'tableManagement.financing.amortizationPeriod' | translate }}
					</label>
					<input type="number" id="amortization-period" class="form-control custom-input"
						[(ngModel)]="entity.amortizationPeriod" [disabled]="saving || !formManipulation" />
				</div>

				<!-- CANCELATION VALUE Input -->
				<div class="col-md-6" *ngIf="!ownVehicle">
					<label for="cancelation-value" class="custom-label" i18n>
						{{'tableManagement.financing.cancelationValue' | translate}}
					</label>

					<div class="col-7 input-wrapper">
						<p-inputNumber [(ngModel)]="entity.cancellationValue" mode="decimal" locale="es-ES"
							(onKeyDown)="onKeyDownDecimal($event)"
							[disabled]="saving || !formManipulation"></p-inputNumber>
						<span class="input-symbol">€</span>
					</div>
				</div>
			</div>
		</p-panel>

		<!-- ACTIVE Input -->
		<div class="row mt-3">
			<div class="col-md-auto d-flex align-items-center">
				<label for="fine-enabled" class="me-2" i18n>
					{{ 'general.active' | translate }}
				</label>
				<div class="form-check">
					<input class="form-check-input custom-checkbox" type="checkbox" [(ngModel)]="active"
						[disabled]="saving || !formManipulation" />
				</div>
			</div>
		</div>

		<!-- FILE -->
		<!-- <div class="row mt-3"> -->
		<!-- FILE NAME Input -->
		<!-- <div class="col-md-6">
                <label for="nombre document" class="custom-label" i18n>{{'tableManagement.financing.documentName' |
                    translate}}</label>
                <input type="text" id="contract" class="form-control custom-input" [ngModel]="entity.document?.name"
                    (ngModelChange)="onDocumentNameChange($event)">
            </div> -->

		<!-- FILE SELECT Input -->
		<!-- <div class="col-md-6">
                <label for="nombre document" class="custom-label" i18n></label>

                <input #uploadFileInput type="file" class="file-upload" (change)='onFileSelected($event)'>
            </div>
        </div> -->

		<!-- taula de documents -->
		<div class="row mt-3" *ngIf="!isNew">
			<app-doc-table type="financing" [entity]="entity" [nested]="true" [ids]="ids" (return)="refresh()">
			</app-doc-table>
		</div>

	</div>
	<!-- #endregion BODY -->

	<!-- #region FOOTER -->
	<p-footer>
		<div class="d-flex justify-content-center bd-highlight">
			<div class="p-2 bd-highlight">
				<div *ngIf="isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n
						[disabled]="saving || !formManipulation">
						{{ saving ? ('general.saving' | translate) : ('general.create' | translate) }}
					</button>
				</div>

				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="saveChanges()" i18n
						[disabled]="saving || !formManipulation">
						{{ saving ? ('general.saving' | translate) : ('general.save' | translate) }}
					</button>
				</div>
			</div>

			<div class="p-2 bd-highlight">
				<div *ngIf="!isNew">
					<button class="btn custom-button" (click)="changeStatus(!active)" i18n
						[disabled]="saving || !formManipulation">
						{{ 'general.changeStatus' | translate }}
					</button>
				</div>
			</div>
		</div>
	</p-footer>
	<!-- #endregion FOOTER -->
</p-dialog>

<!-- CONFIRM SAVE EVENT DIALOG -->
<p-confirmDialog appendTo="body" #missingFieldsDialog [header]=" 'confirm.critic' | translate "
	icon='pi pi-exclamation-triangle'>
	<ng-template pTemplate="footer">
		<button (click)="missingFieldsDialog.accept()" class="custom-button">
			OK
		</button>
	</ng-template>
</p-confirmDialog>