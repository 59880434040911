import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: 'app-delay-voice-monitor-configuration-ob22',
  templateUrl: './delay-voice-monitor-configuration-ob22.component.html',
  styleUrls: ['./delay-voice-monitor-configuration-ob22.component.css']
})
export class DelayVoiceMonitorConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit, OnChanges {

  @Input() delay: Rest.DelayVoiceMonitorConfigurationOB22;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.delay);
    }
  }

  handleChange($event: any) {}

}
