import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { RestExt } from 'app/services/rest-client-extension';


@Component({
  selector: "app-global-parameters-gmt200-form",
  templateUrl: "./global-parameters-gmt200-form.component.html",
  styleUrls: ["./global-parameters-gmt200-form.component.css"],
})
export class GlobalParametersGmt200FormComponent
 extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  @Input() globalParameters: Rest.GlobalParametersGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  powerSaving : any =[
    { label: "Disable power saving function", value: "DISABLE" },
    { label: "Deep saving mode", value: "MODE1" },
    { label: "Low saving mode", value: "MODE2" },
  ];
  LedOptions : any = [
    {
      label:
        "Each time the device powers on, Power LED and GSM LED will work for 30 minutes and then are turn off",
      value: "EACH_TIME_DEVICE_POWER_ON_LED_AND_GNSS_LED_WORK_30_MINUTES_AND_TURN_OFF",
    },
    { label: "Power LED, GSM LED and GNSS LED turn on as configured", value:"POWER_LED_GNSS_LED_AND_GSM_LED_TURN_ON"},
    {
      label: "Turn off all LED, Power LED and GNSS LED and GSM LED will work for 30 minutes and then are turn off",
      value: "POWER_LED_GNSS_LED_AND_GSM_LED_WORK_30_MINUTES_AND_TURN_OFF",
    },
  ];
  cellOptions : any = [
    {label:"Not allow the cells' information report.",value: "DISABLE"},
    {label:"Allow the cells' information report after failed to get GNSS position.", value:"REPORT_WHEN_GNSS"},
    {label:"Report the message +RESP:GTGSM after getting GNSS position successfully.", value: "REPORT_GTGSM_WHEN_GNSS"},
    {label:"Report the message +RESP:GTGSM in any situation if cell's information available.", value:"REPORT_GTGSM"}
  ];
  locationCallOptions: any = [
    {label:"Just hang up the call.", value:"CALL"},
    {label:"Hang up the call and report the current position (+RESP:GTLBC) to the backend server.", value:"CALL_AND_REPORT_GTLBC"},
    {label:"Hang up the call and report the current position as a google maps hyperlink to incoming call via SMS", value:"CALL_AND_CURRENT_POSITION"}
  ];
  powerOptions: any = [
    {label:"Charge the internal backup battery on need and power for unit.", value:"CHARGE_ON_NEED"},
    {label:"Only charge the internal backup battery when ignition on.", value:"CHARGE_IGNITION_ON"},
    {label:"Charge the internal backup battery when ignition on or when the capacity is less than 30 percent.", value:"CHARGE_IGNITION_ON_OR_OFF_WITH_LOW_POWER"}
  ];
  // TODO: Field was missing from legacy code!
  constructor(protected i18n: I18nService) {
    super(i18n);
   
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
}
