<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display"
    appendTo="body">

    <!-- BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- CODE -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="delegation-code" class="custom-label" i18n>
                    {{'general.code' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="delegation-code" class="form-control custom-input" [(ngModel)]="entity.code">
                </div>
            </div>
        </div>

        <!-- NAME -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="delegation-name" class="custom-label" i18n>
                    {{'general.name' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="delegation-name" class="form-control custom-input" [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- FLEET LEADER -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="delegation-fleetManager" class="custom-label" i18n>
                    {{ 'tableManagement.delegations.fleetManager' | translate }}
                </label>
            </div>
            <div class="col-6">
                <div class="float-end">
                    <p-dropdown [options]='fleetUserLeaders' [(ngModel)]='entity.fleetManager' dataKey='id'
                        placeholder="{{ 'general.select' | translate }} {{'tableManagement.delegations.fleetManager' | translate}}"
                        optionLabel='name' appendTo='body' [filter]="true" filterBy="name,surname1,surname2"
                        [style]="{'width':'250px'}" [disabled]='!leadersLoaded'>
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="entity.fleetManager">
                                <div> {{ entity.fleetManager?.surname1}} {{entity.fleetManager?.surname2}},
                                    {{entity.fleetManager?.name}}
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-user pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div> {{user?.surname1}} {{ user?.surname2}}, {{ user?.name}}
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
      
                </div>
            </div>
        </div>

        <!-- SERVICE LEADER -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="delegation-serviceManager" class="custom-label" i18n>
                    {{ 'tableManagement.delegations.serviceManager' | translate }}
                </label>
            </div>
            <div class="col-6">
                <div class="float-end">
                    <p-dropdown [options]='serviceUserLeaders' [(ngModel)]='entity.serviceManager' dataKey='id'
                        placeholder="{{ 'general.select' | translate }} {{'tableManagement.delegations.serviceManager' | translate}}"
                        optionLabel='name' appendTo='body' [filter]="true" filterBy="name,surname1,surname2"
                        [style]="{'width':'250px'}" [disabled]='!leadersLoaded'>
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="entity.serviceManager">
                                <div> {{ entity.serviceManager?.surname1}} {{entity.serviceManager?.surname2}},
                                    {{entity.serviceManager?.name}}
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-user pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div> {{user?.surname1}} {{ user?.surname2}}, {{ user?.name}}
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!-- ZONE LEADER -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="delegation-zoneLeader" class="custom-label" i18n>
                    {{'tableManagement.delegations.zoneChief' | translate}}
                </label>
            </div>

            <div class="col-6">
                <div class="float-end">
                    <p-dropdown [options]='zoneUserLeaders' [(ngModel)]='entity.zoneLeader' dataKey='id'
                        placeholder="{{ 'general.select' | translate }} {{'tableManagement.delegations.zoneChief' | translate}}"
                        optionLabel='name' appendTo='body' [filter]="true" filterBy="name,surname1,surname2"
                        [style]="{'width':'250px'}" [disabled]='!leadersLoaded'>
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="entity.zoneLeader">
                                <div> {{ entity.zoneLeader?.surname1}} {{entity.zoneLeader?.surname2}},
                                    {{entity.zoneLeader?.name}}
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-user pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div> {{user?.surname1}} {{ user?.surname2}}, {{ user?.name}}
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!-- ACTIVE -->
        <div class="row mt-3 mb-3">
            <div class="col-6 d-flex align-items-center">
                <label for="subdelegation-enabled" class="custom-label" i18n>
                    {{'general.active' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                        type="checkbox" [(ngModel)]="active">
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="saveDelegation()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveDelegation()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(!active)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>

</p-dialog>