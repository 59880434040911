import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-jamming-behavior-setting-form-gv300can",
  templateUrl: "./jamming-behavior-setting-form-gv300can.component.html",
  styleUrls: ["./jamming-behavior-setting-form-gv300can.component.css"],
})
export class JammingBehaviorSettingFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() jbsParameters: Rest.JammingBehaviorSettingGV300CAN;

  @ViewChild("JammingBehaviousDiv", { static: true }) JammingBehaviousDiv;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {
    if (this.jbsParameters != null) {
      this.disableAnalogInputDIV(!this.jbsParameters.mode);
    }
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.JammingBehaviousDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.JammingBehaviousDiv.nativeElement.classList.remove("disableDIV");
    }
  }
}
