import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'reports-header',
  templateUrl: './reports-header.component.html',
  styleUrls: ['./reports-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsHeaderComponent implements OnInit {
  @Input() phase: number;

  constructor() { }

  ngOnInit(): void {
  }

}
