<table>
    <thead>
      <tr>
        <th></th>
        <th>{{'tableManagement.cardsConsumption.summary.import' | translate }}</th>
        <th>{{'tableManagement.cardsConsumption.summary.liters' | translate }}</th>
        <th>{{'tableManagement.cardsConsumption.summary.kms' | translate }}</th>
        <th>{{'tableManagement.cardsConsumption.summary.costLiters' | translate }}</th>
        <th>{{'tableManagement.cardsConsumption.summary.costKms' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{'tableManagement.cardsConsumption.summary.lastMonth' | translate }}</td>
        <td>{{ data?.lastMonth?.importValue | number: '1.2-2' }} €</td>
        <td>{{ data?.lastMonth?.liters | number: '1.2-2' }}</td>
        <td>{{ data?.lastMonth?.kms | number: '1.0-0' }}</td>
        <td>{{ data?.lastMonth?.costLiters | number: '1.2-2' }}</td>
        <td>{{ data?.lastMonth?.costKms | number: '1.2-2' }} €</td>
      </tr>
      <tr>
        <td>{{'tableManagement.cardsConsumption.summary.lastQuarter' | translate }}</td>
        <td>{{ data?.lastQuarter?.importValue | number: '1.2-2' }} €</td>
        <td>{{ data?.lastQuarter?.liters | number: '1.2-2' }}</td>
        <td>{{ data?.lastQuarter?.kms | number: '1.0-0' }}</td>
        <td>{{ data?.lastQuarter?.costLiters | number: '1.2-2' }}</td>
        <td>{{ data?.lastQuarter?.costKms | number: '1.2-2' }} €</td>
      </tr>
      <tr>
        <td>{{'tableManagement.cardsConsumption.summary.historic' | translate }}</td>
        <td>{{ data?.historic?.importValue | number: '1.2-2' }} €</td>
        <td>{{ data?.historic?.liters | number: '1.2-2' }}</td>
        <td>{{ data?.historic?.kms | number: '1.0-0' }}</td>
        <td>{{ data?.historic?.costLiters | number: '1.2-2' }}</td>
        <td>{{ data?.historic?.costKms | number: '1.2-2' }} €</td>
      </tr>
    </tbody>
  </table>