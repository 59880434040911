<!-- <p-dialog *ngIf="display" header="{{ deviceFormTitle }}" [(visible)]="display"
    [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="true" [responsive]="true"
    [closeOnEscape]="false" (onHide)="onHide($event)" [contentStyle]="{'width':'100%'}"> -->
<p-dialog 
header="{{title}}"
[(visible)]="display"
[responsive]="true"
[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
[modal]="true"
[draggable]="true"
*ngIf="display"
[style]="{'width':'95%'}">

    <p-message *ngIf="entity.vehicle === null" severity="warning" i18n-text
        text="Device is not associated to a vehicle. Any changes in the configuration won't be sent to the device until it is associated with a vehicle."></p-message>

    <p-message *ngIf="entity.configuration !== null && entity.configuration.status === 'ACK'" severity="success"
        i18n-text text="Configuration confirmed by device"></p-message>

    <p-message *ngIf="entity.configuration !== null && entity.configuration.status === 'NACK'" severity="error"
        i18n-text text="Configuration rejected by device"></p-message>

    <p-message *ngIf="entity.configuration !== null && entity.configuration.status === 'PENDING'" severity="info"
        i18n-text text="Configuration pending to be confirmed by device"></p-message>

    <p-message *ngIf="entity.configuration !== null && entity.configuration.status === 'CANCELLED'" severity="info"
        i18n-text text="Configuration cancelled"></p-message>

    <p-message
        *ngIf="entity.configuration === null || entity.configuration.status === null || entity.configuration.status === 'SAVED_BUT_NOT_SENT'"
        severity="info" i18n-text text="Configuration not sent to the device"></p-message>

    <!-- PARAMS CONTAINER -->
    <div class="container">

        <!-- ROW 1 -->
        <div class="ui-grid ui-grid-responsive ui-fluid">
            <div class="ui-grid-row">
                <div class="ui-grid-col-12">
                    <p-messages [(value)]="messages"></p-messages>
                </div>
            </div>
        </div>

        <!-- ROW 2: DEVICE PARAMS -->
        <div class='row align-items-center'>

            <!-- COL 1 -->
            <div class='col d-flex flex-column gap-2'>

                <div class="row align-items-center">
                    <!-- VEHICLE ID -->
                    <div *ngIf="!isNew" class='col row dvcFields'>
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="vehicle id">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="vehicleId" [ngModel]="entity.vehicle?.id" [disabled]='true'
                                class="txtbStyle" />
                        </div>

                        <!-- <button pButton type="button" label="Vehicle info" class="btn btn-secondary" data-bs-toggle="modal"
                            data-bs-target="#vehicleInfoCard" style="margin-left: 20px;"
                            (click)="showVehicleForm()"></button> -->
                    </div>

                    <!-- PIN -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="pin" [errors]="errors.pin">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="pin" [(ngModel)]="entity.pin" type="number" class="txtbStyle" />
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- EXTERNAL DEVICE ID -->
                    <div class='col row dvcFields'>
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="external device id" [errors]="errors.externalId">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="deviceId" [(ngModel)]="entity.externalId" [min]="0"
                                class="txtbStyle" />
                        </div>
                    </div>

                    <!-- DEVICE TYPE -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input [required]="true" i18n-fieldName fieldName="device type"
                                [errors]="errors.deviceType">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown #ddDT id="deviceType" [options]="availableDeviceTypes"
                                (onChange)="deviceTypeChanged()" [(ngModel)]="entity.deviceType" [disabled]="!isNew"
                                filter="filter" [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- PASSWORD -->
                    <div class='col row dvcFields'>
                        <div class="col lblStyle">
                            <form-input [required]="true" i18n-fieldName fieldName="password"
                                [errors]="errors.password">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="password" [(ngModel)]="entity.password" class="txtbStyle" />
                        </div>
                    </div>

                    <!-- IMEI -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="IMEI" [errors]="errors.imei">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="imei" [(ngModel)]="entity.imei" type="number" class="txtbStyle" />
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- FIRMWARE VERSION -->
                    <div class="col row dvcFields"
                        *ngIf="entity && entity.deviceType && (entity.deviceType.name === 'VT10' || entity.deviceType.name === 'X8' || entity.deviceType.name === 'EVIX' || entity.deviceType.name === 'GMT200')">
                        <div class="col lblStyle">
                            <form-input [required]="true" i18n-fieldName fieldName="firmware version"
                                [errors]="errors.firmware">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown #ddFirmware id="firmware" i18n-placeholder placeholder="Select Firmware"
                                [options]="filterAvailableFirmware" [(ngModel)]="entity.firmware" [disabled]="!isNew"
                                filter="filter" [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- PHONE NUMBER -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="phone number" [errors]="errors.phoneNumber">
                            </form-input>
                        </div>
                        <div class="col">
                            <input pInputText id="phoneNumber" [(ngModel)]="entity.phoneNumber" class="txtbStyle" />
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- STATUS -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input [required]="true" i18n-fieldName fieldName="status" [errors]="errors.status">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown #ddSt id="status" i18n-placeholder placeholder="Select Status"
                                [options]="availableDeviceStatus" [(ngModel)]="entity.status" filter="filter"
                                [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- DATE OF LAST REVISION -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="date of last revision"
                                [errors]="errors.dateOfLastRevision">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"
                                [(ngModel)]="dateOfLastRevision" dateFormat="{{ i18n.dateFormat }}" showIcon="true"
                                styleClass="ui-column-filter" [style]="{'width':'100%','min-width':'100px'}">
                            </p-calendar>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- DATE OF MANUFACTURE -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="date of manufacture"
                                [errors]="errors.dateOfManufacture">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"
                                [(ngModel)]="dateOfManufacture" dateFormat="{{ i18n.dateFormat }}" showIcon="true"
                                styleClass="ui-column-filter" [style]="{'width':'100%','min-width':'100px'}">
                            </p-calendar>
                        </div>
                    </div>

                    <!-- DATE OF NEXT REVISION -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="date of next revision"
                                [errors]="errors.dateOfNextRevision">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"
                                [(ngModel)]="dateOfNextRevision" dateFormat="{{ i18n.dateFormat }}" showIcon="true"
                                styleClass="ui-column-filter" [style]="{'width':'100%','min-width':'100px'}">
                            </p-calendar>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- VEHICLE -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="vehicle" [errors]="errors.vehicle">
                            </form-input>
                        </div>
                        <div class="col">
                            <paged-search-dropdown [paginationRequest]="vehicleFilter"
                                [pageFunction]="this.vehicleService.getPage.bind(this.vehicleService)"
                                [(selectedEntity)]="entity.vehicle" [paginationRequest]="vehicleRequestContext">
                            </paged-search-dropdown>
                        </div>
                    </div>

                    <!-- COMMUNICATION TYPE -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="CommunicationType"
                                [errors]="errors.deviceCommunicationType">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown #ddSt id="CommunicationType" i18n-placeholder
                                placeholder="Select Communiction Type" [options]="availableCommunicationType"
                                [(ngModel)]="entity.deviceCommunicationType"
                                [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <!-- APP TYPES -->
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="appTypes" [errors]="errors.appTypes">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown #ddSt id="appTypes" i18n-placeholder placeholder="Select App types"
                                [options]="availableAppTypes" [(ngModel)]="entity.appTypes"
                                (onChange)="AppTypesChanged($event.value)"
                                [style]="{'width':'100%','min-width':'100px'}">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- ENABLED -->
                    <div class="col row dvcFields">
                        <form-input i18n-fieldName fieldName="enabled" [errors]="errors.enabled">
                            <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true">
                            </p-checkbox>
                        </form-input>
                    </div>

                </div>

                <!-- #region FULL DRIVER DATA -->
                <div class="row mt-4 mb-4">
                    <div class="col-sm-6">
                        <!-- insert a button that opens a modal with the full driver data -->
                        <button pButton type="button" label="Driver info" class="btn btn-secondary"
                            data-bs-toggle="modal" data-bs-target="#driverInfoCard" style="margin-left: 20px;"
                            (click)="showDriverForm()"></button>
                    </div>
                </div>

            </div>

        </div>

        <!-- ROW 3 -->
        <div class="row mt-4 mb-4">

            <div class="col">
                <h5 i18n>
                    Any of the parameters above will be sent to the device. Please if you
                    need to configure them use the terminal or other communication means.
                </h5>
            </div>

        </div>

        <div
            *ngIf="entity.appTypes==='MOTO' && entity.deviceCommunicationType === 'GPRS_ATLANTIS' && canReadSim === true">
            <p-panel i18n-header header="Sim Configuration">
                <div class="col d-flex flex-column gap-2">

                    <div class="row align-items-center">

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input [required]="true" i18n-fieldName fieldName="Sim Operator Type"
                                    [errors]="errors.simOperatorType">
                                </form-input>
                            </div>
                            <div class="col">
                                <p-dropdown #ddDT id="simOperatorType" [autoWidth]="false"
                                    [options]="availableSimOperatorTypes"
                                    (onChange)="SimOperatorTypeChanged($event.value)" [(ngModel)]="selectedOperatorType"
                                    [style]="{'width':'100%','min-width':'100px'}" filter="filter"
                                    [disabled]="disableDIV"></p-dropdown>
                            </div>
                        </div>

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName [required]="true" fieldName="Group Sim"
                                    [errors]="errors.groupId">
                                </form-input>
                            </div>
                            <div class="col">
                                <p-dropdown id="simPlanGroups" [autoWidth]="false" [options]="availableSimPlanGroups"
                                    [disabled]="dropdownSimPlanGroupsDisable || disableDIV"
                                    (onChange)="SimPlanGroupsChanged($event.value)" placeholder="Select a Group"
                                    [ngModel]="entity.sim?.groupId" (ngModelChange)="entity.sim.groupId=$event"
                                    [style]="{'width':'100%','min-width':'100px'}" filter="filter"></p-dropdown>
                            </div>
                        </div>

                    </div>

                    <div class="row align-items-center">

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName [required]="true" fieldName="Plan Sim"
                                    [errors]="errors.plansId">
                                </form-input>
                            </div>
                            <div class="col">
                                <p-dropdown id="simPlans" [autoWidth]="false" [options]="availableSimPlans"
                                    [disabled]="dropdownSimPlansDisable || disableDIV" [ngModel]="entity.sim?.planId"
                                    placeholder="Select a Plan" (ngModelChange)="entity.sim.planId=$event"
                                    [style]="{'width':'100%','min-width':'100px'}" filter="filter" dataKey="id"></p-dropdown>
                            </div>
                        </div>

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName [required]="false" fieldName="Pending Sim Activation"
                                    [errors]="errors.pendingSimActivation">
                                </form-input>
                            </div>
                            <div class="col" [style]="{'width':'100%','min-width':'100px'}">
                                <p-inputSwitch (onChange)="handleChange($event)"
                                    [(ngModel)]="entity.pendingSimActivation" [disabled]=disableDIV></p-inputSwitch>
                            </div>
                        </div>

                    </div>

                    <div class="row align-items-center">

                        <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
                        <div *ngIf="!isNew" class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName [required]="false" fieldName="Unsubscribe"
                                    [errors]="errors.pendingSimActivation">
                                </form-input>
                            </div>
                            <div class="col" [style]="{'width':'100%','min-width':'100px'}">
                                <p-inputSwitch (onChange)="unsubscribe($event)" [ngModel]="entity.sim?.bajaSim"
                                    [disabled]=disableDIV></p-inputSwitch>
                            </div>
                        </div>

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName [required]="true" fieldName="Meses primera activacion"
                                    [errors]="errors.mesesPrimeraActivacion">
                                </form-input>
                            </div>
                            <div class="col">
                                <div class="ui-inputgroup">
                                    <p-spinner size="5" [ngModel]="entity.sim?.mesesPrimeraActivacion"
                                        (ngModelChange)="entity.sim.mesesPrimeraActivacion=$event" [min]="1" [max]="36"
                                        [disabled]=disableDIV
                                        [style]="{'width':'100%','min-width':'100px'}"></p-spinner>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row align-items-center">

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName fieldName="Activation Date"
                                    [errors]="errors.simActivationDate">
                                </form-input>
                            </div>
                            <div class="col">
                                <p-calendar appendTo="body" [locale]="i18n.calendarLocale"
                                    [placeholder]="i18n.dateFormat" [ngModel]="entity.sim?.simActivationDate"
                                    (ngModelChange)="entity.sim.simActivationDate=$event" dateFormat={{i18n.dateFormat}}
                                    showIcon="true" styleClass="ui-column-filter"
                                    [disabled]="entity.pendingSimActivation || disableDIV"
                                    [style]="{'width':'100%','min-width':'100px'}"></p-calendar>
                            </div>
                        </div>

                        <div class="col row dvcFields">
                            <div class="col lblStyle">
                                <form-input i18n-fieldName fieldName="Expiration Date"
                                    [errors]="errors.simExpirationDate">
                                </form-input>
                            </div>
                            <div class="col">
                                <p-calendar appendTo="body" [locale]="i18n.calendarLocale"
                                    [placeholder]="i18n.dateFormat" [ngModel]="entity.sim?.simExpirationDate"
                                    (ngModelChange)="entity.sim.simExpirationDate=$event" dateFormat={{i18n.dateFormat}}
                                    showIcon="true" styleClass="ui-column-filter"
                                    [disabled]="entity.pendingSimActivation || disableDIV"
                                    [style]="{'width':'100%','min-width':'100px'}"></p-calendar>
                            </div>
                        </div>

                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName fieldName="Fecha Baja SIM" [errors]="errors.fechaBajaSim">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat"
                                [ngModel]="entity?.sim?.fechaBajaSim" (ngModelChange)="entity.sim.fechaBajaSim=$event"
                                dateFormat={{i18n.dateFormat}} showIcon="true" styleClass="ui-column-filter"
                                [disabled]="entity.pendingSimActivation || disableDIV"
                                [style]="{'width':'100%','min-width':'100px'}"></p-calendar>
                        </div>

                        <div class="col"></div>
                        <div class="col"></div>
                    </div>

                </div>
            </p-panel>
        </div>

        <div *ngIf="entity.deviceType !== null">
            <app-vt10-parameters-form #VT10ChildComponent
                *ngIf="entity.deviceType.name === 'VT10' && entity.firmware !== undefined" [isNew]="isNew"
                [isConfig]="true" [firmware]="entity.firmware" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-vt10-parameters-form>
            <app-gv300-parameters-form #GV300ChildComponent *ngIf="entity.deviceType.name === 'GV300'" [isNew]="isNew"
                [isConfig]="true" firmware="GV300" [deviceParams]="entity.configuration.parameters" i18n-title
                title></app-gv300-parameters-form>
            <app-gmt200-parameters-form #GMT200ChildComponent
                *ngIf="entity.deviceType.name === 'GMT200' && entity.firmware !== undefined" [isNew]="isNew"
                [isConfig]="true" [firmware]="entity.firmware" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-gmt200-parameters-form>
            <app-concox-mt200-parameters-form #ConcoxMT200ChildComponent
                *ngIf="entity.deviceType.name === 'ConcoxMT200'" [isNew]="isNew" [isConfig]="true"
                firmware="ConcoxMT200" [deviceParams]="entity.configuration.parameters" i18n-title
                title></app-concox-mt200-parameters-form>
            <app-we-track2-parameters-form #WeTrack2ChildComponent *ngIf="entity.deviceType.name === 'WeTrack2'"
                [isNew]="isNew" [isConfig]="true" firmware="WeTrack2" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-we-track2-parameters-form>
            <app-gv300can-parameters-form #Gv300canParametersFormComponent *ngIf="entity.deviceType.name === 'GV300CAN'"
                [isNew]="isNew" [isConfig]="true" firmware="GV300CAN" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-gv300can-parameters-form>
            <app-gv800-parameters-form #Gv800ParametersFormComponent *ngIf="entity.deviceType.name === 'GV800'"
                [isNew]="isNew" [isConfig]="true" firmware="GV800" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-gv800-parameters-form>
            <app-x8-parameters-form #X8ParametersFormComponent
                *ngIf="entity.deviceType.name === 'X8' && entity.firmware !== undefined" [isNew]="isNew"
                [isConfig]="true" firmware="entity.firmware" [deviceParams]="entity.configuration.parameters" i18n-title
                title></app-x8-parameters-form>
            <app-vt200-parameters-form #Vt200ParametersFormComponent *ngIf="entity.deviceType.name === 'VT200'"
                [isNew]="isNew" [isConfig]="true" firmware="VT200" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-vt200-parameters-form>
            <app-evix-parameters-form #EvixParametersFormComponent
                *ngIf="entity.deviceType.name === 'EVIX' && entity.firmware !== undefined" [isNew]="isNew"
                [isConfig]="true" firmware="entity.firmware" [deviceParams]="entity.configuration.parameters" i18n-title
                title></app-evix-parameters-form>
            <app-iofrog-parameters-form #IofrogParametersFormComponent *ngIf="entity.deviceType.name === 'Iofrog'"
                [isNew]="isNew" [isConfig]="true" firmware="Iofrog" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-iofrog-parameters-form>
            <app-teltonika-parameters-form #TeltonikaParametersFormComponent
                *ngIf="entity.deviceType.name === 'Teltonika'" [isNew]="isNew" [isConfig]="true" firmware="Teltonika"
                [deviceParams]="entity.configuration.parameters" i18n-title title></app-teltonika-parameters-form>
            <app-ob22-parameters-form #OB22ParametersFormComponent *ngIf="entity.deviceType.name === 'OB22'"
                [isNew]="isNew" [isConfig]="true" firmware="OB22" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-ob22-parameters-form>
            <app-gl320m-parameters-form #Gl320mParametersFormComponent *ngIf="entity.deviceType.name === 'GL320M'"
                [isNew]="isNew" [isConfig]="true" firmware="GL320M" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-gl320m-parameters-form>
            <app-smart-coffee-parameters-form #SmartCoffeeParametersFormComponent *ngIf="entity.deviceType.name === 'SmartCoffee'"
                [isNew]="isNew" [isConfig]="true" firmware="SmartCoffee" [deviceParams]="entity.configuration.parameters"
                i18n-title title></app-smart-coffee-parameters-form>
        </div>

        <!-- USER PANEL  -->
        <div class="pt-3">
            <p-panel *ngIf="!isNew" header="Users">

                <!-- USER TABLE -->
                <p-table [value]="users" [rows]="10" [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true"
                    [rowHover]="true" [autoLayout]="true">

                    <!-- Table Column/Header Definition -->
                    <ng-template pTemplate="header">
                        <tr>

                            <th pSortableColumn="name">
                                <div class="p-d-flex p-jc-between p-ai-center">Name
                                    <p-sortIcon field="name"></p-sortIcon>
                                    <p-columnFilter type="text" field="name" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="firstSurname">
                                <div class="p-d-flex p-jc-between p-ai-center">First Surname
                                    <p-sortIcon field="firstSurname"></p-sortIcon>
                                    <p-columnFilter type="text" field="firstSurname" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="secondSurname">
                                <div class="p-d-flex p-jc-between p-ai-center">Second Surname
                                    <p-sortIcon field="secondSurname"></p-sortIcon>
                                    <p-columnFilter type="text" field="secondSurname" placeholder="Search"
                                        display="menu" [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="username">
                                <div class="p-d-flex p-jc-between p-ai-center">Username
                                    <p-sortIcon field="username"></p-sortIcon>
                                    <p-columnFilter type="text" field="username" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="email">
                                <div class="p-d-flex p-jc-between p-ai-center">Email
                                    <p-sortIcon field="email"></p-sortIcon>
                                    <p-columnFilter type="text" field="email" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="expirationDate">
                                <div class="p-d-flex p-jc-between p-ai-center">Expiration Date
                                    <p-sortIcon field="expirationDate"></p-sortIcon>
                                </div>

                            </th>

                            <th pSortableColumn="enabled">

                                <div class="p-d-flex p-jc-between p-ai-center">Active
                                    <p-sortIcon field="enabled"></p-sortIcon>
                                    <p-columnFilter type="boolean" field="enabled" display="menu"
                                        [showApplyButton]="false" class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                        </tr>
                    </ng-template>

                    <!-- Table Row Definition -->
                    <ng-template pTemplate="body" let-user>
                        <tr class="p-selectable-row" (dblclick)="showUserFormComponent(user)">

                            <td>
                                <span class="p-column-title">Name</span>
                                {{user.name}}
                            </td>

                            <td>
                                <span class="p-column-title">First Surname</span>
                                {{user.surname1}}
                            </td>

                            <td>
                                <span class="p-column-title">Second Surname</span>
                                {{user.surname2}}
                            </td>

                            <td>
                                <span class="p-column-title">Username</span>
                                {{user.userName}}
                            </td>

                            <td>
                                <span class="p-column-title">Email</span>
                                {{user.email}}
                            </td>

                            <td>
                                <span class="p-column-title">Expiration Date</span>
                                {{user.accountExpiry|date: 'shortDate'}}
                            </td>

                            <td class="p-text-center">
                                <i class="pi"
                                    [ngClass]="{'true-icon pi-check-circle': user.enabled, 'false-icon pi-times-circle': !user.enabled}"></i>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="7">
                                <p i18n>No records found</p>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>

            </p-panel>
        </div>

        <!-- NOTIFICATIONS GROUPS PANEL -->
        <div class="pt-3">
            <p-panel *ngIf="!isNew" header="Notifications">

                <!-- NOTIFICATIONS GROUPS TABLE -->
                <p-table [value]="notificationsGroups" [rows]="10" [rowsPerPageOptions]="[10,20,30,40,50]"
                    [paginator]="true" [rowHover]="true" [autoLayout]="true">

                    <!-- Table Column/Header Definition -->
                    <ng-template pTemplate="header">
                        <tr>

                            <th pSortableColumn="name">
                                <div class="p-d-flex p-jc-between p-ai-center">Name
                                    <p-sortIcon field="name"></p-sortIcon>
                                    <p-columnFilter type="text" field="name" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th pSortableColumn="description">
                                <div class="p-d-flex p-jc-between p-ai-center">Description
                                    <p-sortIcon field="description"></p-sortIcon>
                                    <p-columnFilter type="text" field="description" placeholder="Search" display="menu"
                                        [showMatchModes]="false" [showOperator]="false"
                                        class="p-ml-auto"></p-columnFilter>
                                </div>
                            </th>

                            <th>Notification Channels</th>

                            <th pSortableColumn="enabled">

                                <div class="p-d-flex p-jc-between p-ai-center">Active
                                    <p-sortIcon field="enabled"></p-sortIcon>

                                    <p-columnFilter type="boolean" field="enabled" display="menu"
                                        [showApplyButton]="false" class="p-ml-auto"></p-columnFilter>

                                </div>
                            </th>
                        </tr>
                    </ng-template>

                    <!-- Table Row Definition -->
                    <ng-template pTemplate="body" let-noticeGroup>
                        <tr class="p-selectable-row">

                            <td>
                                <span class="p-column-title">Name</span>
                                {{noticeGroup.name}}
                            </td>
                            <td>
                                <span class="p-column-title">Description</span>
                                {{noticeGroup.description}}
                            </td>
                            <td>
                                <span class="p-column-title">Notice Channels</span>
                                {{noticeGroup.noticeChannelsStr}}
                            </td>
                            <td class="p-text-center">
                                <i class="pi"
                                    [ngClass]="{'true-icon pi-check-circle': noticeGroup.enabled, 'false-icon pi-times-circle': !noticeGroup.enabled}"></i>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="7">
                                <p i18n>No records found</p>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </p-panel>
        </div>
    </div>

    <div *ngIf="creditsVisible === true" class="pt-3">
        <p-panel i18n-header header="Initial credits">
            <div class="ui-g">
                <div class="ui-g-12 ui-md-6">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input i18n-fieldName [required]="true"
                                fieldName="Enter the initial credits for the device">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner id="spinner-credits" size="5" [min]="0" [(ngModel)]="currentCredits"
                                    (ngModelChange)="onChange($event)"></p-spinner>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </p-panel>
    </div>

    <!-- FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-end">

            <div *ngIf="disableSave === false">
                <button type="button" pButton icon="fa fa-check" (click)="customSave('NOT_SEND')" i18n-label
                    label="Save (not send)" style="margin-top: 5px;"></button>
                <button *ngIf="entity.vehicle !== null" type="button" pButton icon="fa fa-check"
                    (click)="customSave('NORMAL')" i18n-label label="Save (and send differences)"
                    style="margin-top: 5px;"></button>
                <button *ngIf="entity.vehicle !== null" type="button" pButton icon="fa fa-check"
                    (click)="customSave('FORCE_SEND')" i18n-label label="Save (and send all)"
                    style="margin-top: 5px;"></button>
            </div>

            <div *ngIf="disableSave === true">
                <div style="display: inline-block" i18n-pTooltip pTooltip="No permissions to update"
                    tooltipPosition="top">
                    <button disabled="true" *ngIf="entity.vehicle === null" type="button" pButton icon="fa fa-check"
                        (click)="customSave('NOT_SEND')" i18n-label label="Save (not send)"
                        style="margin-top: 5px;"></button>
                    <button disabled="true" *ngIf="entity.vehicle !== null" type="button" pButton icon="fa fa-check"
                        (click)="customSave(null)" i18n-label label="Save (and send differences)"
                        style="margin-top: 5px;"></button>
                    <button disabled="true" *ngIf="entity.vehicle !== null" type="button" pButton icon="fa fa-check"
                        (click)="customSave('FORCE_SEND')" i18n-label label="Save (and send all)"
                        style="margin-top: 5px;"></button>
                </div>
            </div>

            <div *ngIf="isNew === false">

                <div *ngIf="disabeChangeStatus === false">
                    <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
                        label="Change status" style="margin-top: 5px;"></button>
                </div>

                <div *ngIf="disabeChangeStatus === true">
                    <div style="display: inline-block" i18n-pTooltip pTooltip="No permissions to change status"
                        tooltipPosition="top">
                        <button disabled="true" type="button" pButton icon="fa fa-check"
                            (click)="changeStatus(!entity.enabled)" i18n-label label="Change status"
                            style="margin-top: 5px;"></button>
                    </div>
                </div>

            </div>

        </div>

    </p-footer>
</p-dialog>
