import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TimeZones} from './time_zones';

@Component({
  selector: 'app-time-zone-selector',
  templateUrl: './time-zone-selector.component.html',
  styleUrls: ['./time-zone-selector.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimeZoneSelectorComponent implements OnInit {

  @Input() timeZone: string;
  @Output() protected timeZoneChange: EventEmitter<string> = new EventEmitter();
  timeZones: any;
  timeZoneOptions: any;
  selectedTimeZone: any;

  constructor() {


  }

  ngOnInit() {

    this.timeZones = TimeZones.EN;
    this.timeZoneOptions = [];

    for (let tz in TimeZones.EN) {
      let timeZoneOption = {label: TimeZones.EN[tz], value: tz};
      this.timeZoneOptions.push(timeZoneOption);

      if (tz === this.timeZone) {
        this.selectedTimeZone = this.timeZoneOptions[this.timeZoneOptions.indexOf(timeZoneOption)];
      }

    }

  }

  timeZoneUpdate(event): void {
    this.selectedTimeZone = this.timeZoneOptions[this.timeZoneOptions.indexOf(event)];
    this.timeZoneChange.emit(this.timeZoneOptions[this.timeZoneOptions.indexOf(event)].value);
  }

}
