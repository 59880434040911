<p-dialog *ngIf='isDialogVisible'
          header='{{poiRacingFormTitle}}'
          [(visible)]='isDialogVisible'
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [closable]='true'
          (visibleChange)='initPoi()'
          [style]="{width: '50vw'}"
>

   <!-- DIALOG BODY -->
   <div class='d-flex flex-column align-content-center gap-3 p-3'>

      <!-- FORM ERRORS -->
      <div *ngIf='poiFormErrorMessages.length !== 0'>
         <div *ngFor='let error of poiFormErrorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>

      <!-- POI NAME -->
      <div class='d-flex align-content-center w-100'>
         <form-input [required]='true' fieldName="{{ 'general.name' | translate }}" [errors]=''>
            <input pInputText [(ngModel)]='poi.namePoi' [style]="{'width':'300px'}" />
         </form-input>
      </div>

      <!-- POI INFO -->
      <div class='d-flex align-content-center w-100'>
         <form-input [required]='false' fieldName="{{ 'general.description' | translate }}" [errors]=''>
            <input pInputText [(ngModel)]='poi.infoTxt' [style]="{'width':'300px'}" />
         </form-input>
      </div>

      <!-- POI URL BLOG -->
      <div class='d-flex align-content-center w-100'>
         <form-input [required]='false' fieldName="{{ 'events.pois.blogUrl' | translate }}" [errors]=''>
            <input pInputText [(ngModel)]='poi.blogUrl' [style]="{'width':'300px'}" />
         </form-input>
      </div>

      <!-- POI URL VIDEO -->
      <div class='d-flex align-content-center w-100'>
         <form-input [required]='false' fieldName="{{ 'events.pois.videoUrl' | translate }}" [errors]=''>
            <input pInputText [(ngModel)]='poi.videoUrl' [style]="{'width':'300px'}" />
         </form-input>
      </div>

      <!-- POI ICON -->
      <div class='d-flex align-content-center w-100'>
         <form-input [required]='true' fieldName="{{ 'general.icon' | translate }}">

            <!-- POI ICON THUMBNAIL && DROPDOWN -->
            <div class='d-flex flex-row'>

               <!-- POI ICON THUMBNAIL -->
               <div *ngIf='selectedPoiIcon' class='d-flex pe-3'>
                  <img [src]='selectedPoiIcon?.iconId?.imageEncoded' alt='' class='poi-icon-thumbnail' />
               </div>

               <!-- POI ICON DROPDOWN -->
               <div class='d-flex'>
                  <p-dropdown class='d-flex align-items-center'
                              [options]='poiIcons'
                              [(ngModel)]='selectedPoiIcon'
                              [style]="{'width':'300px','height': '40px','line-height':'normal'}"
                  >

                     <!-- SELECTED ICON TEMPLATE -->
                     <ng-template pTemplate='selectedItem'>
                        <div *ngIf='selectedPoiIcon'>
                           <div class='d-flex flex-row w-100 h-100 gap-3 ps-3'>
                              <img [src]='selectedPoiIcon?.iconId.imageEncoded' alt='' class='poi-icon' />
                              <div class='d-flex poi-icon-name'>{{selectedPoiIcon.nameIcon}}</div>
                           </div>
                        </div>
                     </ng-template>

                     <!-- ICON DROPDOWN TEMPLATE -->
                     <ng-template let-icon pTemplate='item'>
                        <div class='d-flex flex-row w-100 h-100 gap-3 ps-2'>
                           <img [src]='icon.iconId.imageEncoded' alt='' class='poi-icon' />
                           <div class='d-flex poi-icon-name'>{{icon.nameIcon}}</div>
                        </div>
                     </ng-template>

                  </p-dropdown>
               </div>

            </div>

         </form-input>
      </div>

      <!-- POI IMAGE -->
      <div class='d-flex flex-column align-content-center justify-content-center w-100 gap-3'>

         <!-- POI IMAGE LABEL -->
         <label style="font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em; font-weight: bold" i18n>
            {{ 'general.image' | translate }}
         </label>

         <!-- POI IMAGE UPLOAD BUTTON -->
         <div class='d-flex justify-content-center w-100'>
            <button id='uploadImageButton' (click)='uploadPoiImageInput.click()'>
               <img *ngIf='uploadImageIconUrl' id='uploadImageIcon' alt='' src='{{uploadImageIconUrl}}' />
               <img *ngIf='poi?.imagePoi?.imageEncoded' id='uploadedImage' alt='' [src]='poi.imagePoi.imageEncoded'>
            </button>
            <input #uploadPoiImageInput [hidden]='true' type='file' accept='.jpg, .png' (change)='onImageChange($event)'>
         </div>

      </div>

   </div>

   <!-- DIALOG FOOTER-->
   <p-footer>
      <div class='d-flex justify-content-end align-content-center p-2 w-100'>
         <button type='button' class='btn custom-button' (click)='savePoi()'>
            {{ 'general.save' | translate }}
         </button>
      </div>
   </p-footer>


</p-dialog>

