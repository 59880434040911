<!-- <p-dialog *ngIf="display" header="{{title}}" [(visible)]="display"
    [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false" [resizable]="false"
    [closeOnEscape]="false" > -->

<p-dialog *ngIf='isDialogVisible' header='{{ title | uppercase }}' [(visible)]='isDialogVisible'
	[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]='true' [draggable]='false' [resizable]='false'
	[closeOnEscape]='false' [style]="{width: '80vw'}">

	<!-- FORM ERRORS -->
	<div #errorContainer>
		<div *ngIf='errorMessages.length !== 0'>
			<div *ngFor='let error of errorMessages'>
				<div class='alert alert-warning' role='alert'>{{error}}</div>
			</div>
		</div>
	</div>

	<!-- PANELL DADES DEL DOCUMENT -->
	<div class='d-flex flex-column gap-3 p-3'>

		<div class='d-flex row-cols-2 w-100'>

			<div class='d-flex ps-3 align-items-center'>

				<div class="d-flex flex-column mb-3">

					<!-- NAME -->
					<div class="p-2 align-items-center">
						<form-input [required]='true' [align]="'cols-2'"
							fieldName="{{ 'documents.form.name' | translate }}">
							<input pInputText [(ngModel)]="entity.name" [disabled]='isEditingDisabled'
								[style]="{'width':'180px', 'margin-right':'20px'}" />
						</form-input>
					</div>
					<!-- TYPE -->
					<div class="p-2 align-items-center">
						<form-input [required]='true' [align]="'cols-2'"
							fieldName="{{ 'documents.form.type' | translate }}">
							<p-dropdown [options]='docTypeList' [(ngModel)]="entity.type" placeholder="Select..."
								[style]="{'width':'250px', 'margin-right':'5px'}" appendTo='body'
								[disabled]='isEditingDisabled || !isNew' (onChange)="onChangeType($event)">
								<ng-template pTemplate="item" let-item>
									{{'documents.type.' + item.label | translate}}
								</ng-template>
								<ng-template let-selectedItem pTemplate="selectedItem">
									{{'documents.type.' + selectedItem.label | translate}}
								</ng-template>
							</p-dropdown>
						</form-input>
					</div>
					<!-- VEHICLE / DRIVER -->
					<div *ngIf="showVehicle" class="p-2 align-items-center">
						<form-input [required]='true' [align]="'cols-2'"
							fieldName="{{ 'tableManagement.vehicle.vehicle' | translate }}">
							<input pInputText [(ngModel)]="selectedVehiclePlate" [disabled]='true'
								[style]="{'width':'180px', 'margin-right':'20px'}" />
						</form-input>
					</div>
					<!-- FILE -->
					<div class="p-2 align-items-center">
						<form-input [required]='true' [align]="'cols-2'"
							fieldName="{{ 'documents.form.file' | translate }}">
							<input pInputText [readonly]='true' [disabled]="!isNew" [ngModel]='entity.document?.name'
								multiple [style]="{'width':'250px', 'margin-right':'10px'}" />
							<button id="uploadDocumentButton" [disabled]="!isNew" class="btn btn-outline-dark"
								(click)="uploadDocumentInput.click()">{{ isNew ? '...' : 'X'}}</button>
							<input #uploadDocumentInput [hidden]="true" type="file" accept=".*"
								(change)="changeDocument($event)" multiple [style]="{'margin-right':'10px'}">
							<button type='button' class='btn custom-table-download-button' (click)="downloadFile()">
								<svg-icon src='assets/icons/download-button.svg'
									class='d-flex flex-wrap custom-table-download-button-icon'
									[svgStyle]="{ 'height.px': 25, 'width.px': 25 }"></svg-icon>
							</button>
						</form-input>
					</div>

					<!-- camps especials segons el tipus -->
					<div *ngIf="entity.type">
						<app-doc-driver-points #docDriverPoints *ngIf="entity.type === 'DGT_POINTS'" [isNew]="isNew"
							[driverPoints]="driverPoints" [isEditingDisabled]="isEditingDisabled">
						</app-doc-driver-points>
						<app-doc-note #docNote *ngIf="entity.type === 'NOTE'" [isNew]="isNew" [note]="note"
							[isEditingDisabled]="isEditingDisabled">
						</app-doc-note>
					</div>

				</div>

			</div>

		</div>

	</div>

	<!-- PIE DE PAGINA -->
	<p-footer>
		<div class='d-flex flex-row-reverse bd-highlight'>
			<div class='p-2 bd-highlight'>
				<button type='button' class='btn custom-button' (click)='onSaveDocument()'
					[disabled]='isEditingDisabled'>
					{{ isEditingDisabled ? ('general.saving' | translate) : ('general.save' | translate) }}
				</button>
			</div>
		</div>
	</p-footer>

</p-dialog>