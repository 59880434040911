import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {JQueryHTTPClient} from '../../../../../rest/JQueryHTTPClient';
import {Rest} from '../../../../../rest/rest_client';
import {RealtimeService} from '../../../../../rest/realtime.service';
import {I18nService} from '../../../../../services/i18n/i18n.service';
import {NotificationsService, Severity} from '../../../../../services/notifications-service/notifications.service';
import {RealTimeDataService} from '../../../../../services/rt-data/rt-data.service';
import {DeviceService} from '../../../../../rest/device.service';
import {Router} from '@angular/router';
import {RestExt} from '../../../../../services/rest-client-extension';
import {Modal} from 'bootstrap';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.css'],
  providers: [RealtimeService, DeviceService]
})


export class QuickActionsComponent implements OnInit, OnDestroy {
  @Input() vehicle: Rest.Vehicle;
  @Input() settings: Rest.VehicleDetailsSettings;

  routeShown: boolean = false;
  route: Rest.Vertex[] = [];

  constructor(private i18n: I18nService, private rtService: RealtimeService, private realTimeDataService: RealTimeDataService, private notificationService: NotificationsService, private deviceService: DeviceService, private router: Router, private jquery: JQueryHTTPClient) {
  }

  ioFormVisible: boolean = false;

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.routeShown) {
      this.routeShown = false;
      this.realTimeDataService.hideRoute(this.vehicle.id);
    }
  }

  /* TODO: CHECK center() CORRECT FUNCTIONALITY */
  center(): void {
    this.realTimeDataService.focusMap([this.vehicle.id]);
  }

  /* TODO: CHECK requestPosition() CORRECT FUNCTIONALITY */
  requestPosition(): void {
    //Construct the request object
    let req = <Rest.LocationRequestMessage>{
      id: null,
      vehicleId: this.vehicle.id,
      platformTime: null,
      requestType: "GET_LOCATION",
      tag: ""
    }

    //Send a request to the device
    this.deviceService.sendRequest(this.vehicle.id + "", req).then((request: Rest.RequestMessage) => {
      this.notificationService.add(Severity.success, this.i18n._(':)'), this.i18n._('Get location command queued. The message will be sent to the device as soon as possible'));

    }).catch((e: any) => {
      this.notificationService.add(Severity.error, this.i18n._(':('), this.i18n._('Unable to queue get location command.'));
    });
  }

  /**
   * vehicleSettings - Function that navigates to control-panel/devices and opens a dialog with the device information.
   * */
  vehicleSettings(): void {
    this.router.navigate(["control-panel/devices", {"id": this.vehicle.device.id}])
  }

  /* TODO: IMPLEMENT report() FUNCTIONALITY [[DISABLED]] */
  report(): void {

  }

  /* TODO: CHECK showRoute() CORRECT FUNCTIONALITY */
  showRoute(): Promise<any> {
    if (this.routeShown) {
      this.routeShown = false;
      this.realTimeDataService.hideRoute(this.vehicle.id);
    } else {

      //Get last 10 positions for the vehicle from the RT service

      return this.jquery.request({
        method: "POST",
        url: RestExt.uriEncoding`route`,
        queryParams: {maxCount: 10},
        data: [this.vehicle.id],
        headers: false,
        cmREST: false
      }).then((r: Rest.VehicleDataMessage[]) => {
        let vertex = r.map(m => <Rest.Vertex>{latitude: m.latitude, longitude: m.longitude});
        this.route = vertex;
        this.realTimeDataService.showRoute(this.vehicle.id, [vertex], true);
        this.routeShown = true;


        //From now on, when more data for the vehicle comes, the route will be updated as long as routeShown is true
        this.realTimeDataService.vehicleInfo.takeWhile(() => this.routeShown).subscribe((vi: Rest.VehicleDataMessage) => {
          if (vi.vehicleId != this.vehicle.id) return;
          this.route.push(<Rest.Vertex>{latitude: vi.latitude, longitude: vi.longitude});
          this.realTimeDataService.showRoute(this.vehicle.id, [this.route], false);
        });
      });

    }

  }

  /**
   * sendMessage - Function that opens the Bootstrap Modal inside the {{SmsComponent}}.
   * */
  sendMessage(modalRef: ElementRef): void {
    const sendMessageModal = new Modal(modalRef.nativeElement);
    sendMessageModal.show();
  }

  /* TODO: FINISH IoComponent IMPLEMENTATION */
  showIOModal(modalRef: ElementRef): void {
    const ioModal = new Modal(modalRef.nativeElement);
    ioModal.show();
  }

  /* TODO: IMPLEMENT alerts() FUNCTIONALITY [[DISABLED]] */
  alerts(): void {

  }

  /* TODO: IMPLEMENT sendLocation() FUNCTIONALITY [[DISABLED]] */
  sendLocation(): void {

  }

  /* TODO: CHECK showCallBackModal() CORRECT FUNCTIONALITY - PHONE NUMBER INPUT ACCEPTS STRINGS  */
  showCallBackModal(modalRef: ElementRef): void {
    const callBackModal = new Modal(modalRef.nativeElement);
    callBackModal.show();
  }

  /**
   * showMaintenances - Function that navigates to control-panel/fleets and opens a dialog with the vehicle information.
   * */
  showMaintenances(): void {
    this.router.navigate(["control-panel/fleets", {"vehicle": this.vehicle.id}]);
  }

  /* TODO: IMPLEMENT calendar() FUNCTIONALITY [[DISABLED]] */
  calendar(): void {

  }

  /* TODO: IMPLEMENT zones() FUNCTIONALITY [[DISABLED]] */
  zones(): void {

  }

}
