import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-apnconfiguration-we-track2",
  templateUrl: "./apnconfiguration-we-track2.component.html",
  styleUrls: ["./apnconfiguration-we-track2.component.css"],
})
export class ApnconfigurationWeTrack2Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input() apn: Rest.APNConfigurationWeTrack2;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.apn);
    }
  }
}
