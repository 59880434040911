import { Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import {EntityService} from "../../rest/entity.service";
import {I18nService} from "../../services/i18n/i18n.service";

@Component({
  selector: 'app-change-status-dialog-inverted',
  templateUrl: './change-status-dialog-inverted.component.html',
  styleUrls: ['./change-status-dialog-inverted.component.css'],
  providers: [EntityService],
})
export class ChangeStatusDialogInvertedComponent<T> implements OnInit {
  @Input() display: boolean;
  @Input() items: string;
  @Input() entitiesList: T[] = [];
  @Input() service: EntityService<T>;

  //For fleet tree
  @Input() isFleetTree: boolean;
  @Input() cascadeSelection: boolean;
  @Input() service2: EntityService<T>;
  @Input() service3: EntityService<T>;

  @Input() fleets: number[] = [];
  @Input() groups: number[] = [];
  @Input() vehicles: number[] = [];

  @Output() return = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  

  displayEnable = true;
  displayDisable = true;

  constructor(protected i18n: I18nService) {
  }

  ngOnInit() {
    this.onShow();

    this.entitiesList.forEach((e: any) => {
      if (this.containsProperty(e, "name")) {
        e.nameToShow = e.name;
      } else if (this.containsProperty(e, "imei")) {
        //If entity has no name is a device, and therefore has imei.
        e.nameToShow = e.imei;
      } else if (this.containsProperty(e, "label")) {
        //If entity has no name and no imei, for sure is a tree node.
        e.nameToShow = e.label;
      }
    });
  }

  async changeStatus(status: boolean) {
    const that = this;
    var changestatus = true;

    //For fleets tree
    if (this.isFleetTree) {
      //service: fleets
      if (this.fleets.length > 0) {
        await this.service
          .changeStatus(this.fleets, {status})
          .then(
            function (res) {
              changestatus = true;
            },
            (err) => {
              changestatus = false;
              console.log(err);
              that.handleError(err, that);
              that.return.emit(false);
            }
          )
          .catch((e) => {
            this.handleError(e, that);
          });
      }

      //service2: groups
      if (this.groups.length > 0) {
        await this.service2
          .changeStatus(this.groups, {status})
          .then(
            function (res) {
              changestatus = true;
            },
            (err) => {
              changestatus = false;
              console.log(err);
              that.handleError(err, that);
              that.return.emit(false);
            }
          )
          .catch((e) => {
            this.handleError(e, that);
          });
      }

      //service3: vehicles
      if (this.vehicles.length > 0) {
        await this.service3
          .changeStatus(this.vehicles, {status})
          .then(
            function (res) {
              changestatus = true;
            },
            (err) => {
              changestatus = false;
              console.log(err);
              that.handleError(err, that);
              that.return.emit(false);
            }
          )
          .catch((e) => {
            this.handleError(e, that);
          });
      }

      if (changestatus) {
        this.return.emit(true);
      }
    }
    //For other entities
    else {
      const listIds: number[] = [];

      for (const a in this.entitiesList) {
        listIds.push(this.entitiesList[a]["id"]);
      }

      this.service
        .changeStatus(listIds, {status})
        .then(
          function (res) {
            that.return.emit(true);
          },
          (err) => {
            console.log(err);
            that.handleError(err, that);
            that.return.emit(false);
          }
        )
        .catch((e) => {
          this.handleError(e, that);
        });
    }
  }

  handleError(err, that) {
    that.errors = {};
    if (typeof err === "string") {
      err = JSON.parse(err);
    }
    if (err.errors) {
      for (const i in err.errors) {
        if (err.errors[i].fieldName == "") {
          that.messages.push({
            severity: "error",
            detail: err.errors[i].errorDescription,
            summary: "",
          });
        } else {
          const e = err.errors[i];
          if (!that.errors[e.fieldName]) {
            that.errors[e.fieldName] = [];
          }
          that.errors[e.fieldName].push(e.errorDescription);
        }
      }
    }
  }

  onShow() {
    if (this.entitiesList.length > 0) {
      //If all entities are enabled
      if (this.checkStatusOfAllEntities(this.entitiesList, true)) {
        this.displayEnable = false;
        this.displayDisable = true;
      }
      //If all entities are disabled
      else if (this.checkStatusOfAllEntities(this.entitiesList, false)) {
        this.displayEnable = true;
        this.displayDisable = false;
      } else {
        this.displayEnable = true;
        this.displayDisable = true;
      }
    }
  }

  checkStatusOfAllEntities(entities: T[], status: boolean) {
    for (const a in entities) {
      if (entities[a]["enabled"] != status) {
        return false;
      }
    }
    return true;
  }

  onHide() {
    this.close.emit(false);
  }

  containsProperty(object: any, property: string): boolean {
    if (object == null) {
      return false;
    }

    for (const p in object) {
      if (p == property) {
        return true;
      }
    }
    return false;
  }
}
