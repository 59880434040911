<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Digital Input Port
          Settings(AT + GTDIS)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">
    <div class="col row dvcFields">
      <form-input fieldName="Ignition Detection"></form-input>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="No Ignition">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="disParameters.noIgnition"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Sample Period">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.samplePeriod" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon">(x2s)</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="MPF Debounce Time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.mpfDebounceTime" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon">(x2s)</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <form-input fieldName="Input 1"></form-input>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Enable This Input">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="disParameters.enable"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Debounce Time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.debounceTime" [min]="0" [max]="20"></p-spinner>
            <span class="ui-inputgroup-addon">0~20</span>
            <span class="ui-inputgroup-addon">*10ms</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Validity Time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="disParameters.validityTime" [min]="0" [max]="12"></p-spinner>
            <span class="ui-inputgroup-addon">0~12</span>
            <span class="ui-inputgroup-addon">*2s</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>