import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-hbm-form-gv300can",
  templateUrl: "./hbm-form-gv300can.component.html",
  styleUrls: ["./hbm-form-gv300can.component.css"],
})
export class HbmFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() hbmParameters: Rest.HarshBehaviorMonitoringGV300CAN;

  modeOptions: any = [
    { label: "Disable harsh behavior monitoring", value: 0 },
    { label: "Detection by GPS only", value: 1 },
    { label: "Detection by motion sensor only", value: 2 },
    { label: "Detection by GPS or motion sensor", value: 3 },
    { label: "Detection by GPS and motion sensor", value: 4 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.hbmParameters);
    }
  }

  trackModeSwitch = false;

  trackEventModeChange(value, event) {
    console.log("value:", value);
    if (value == 0) {
      this.trackModeSwitch = false;
    } else {
      this.trackModeSwitch = true;
    }
  }
}
