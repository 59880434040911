<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.insurance.insuranceManagement' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>

            <!-- SELECT -->
            <th class="checkbox-column">
                <div class="d-flex justify-content-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
            </th>

            <!-- POLICY NUMBER -->
            <th pSortableColumn="policyNumber">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.insurance.policyNumber' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="policyNumber"></p-sortIcon>
                        <p-columnFilter field="policyNumber" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- AGENT -->
            <th pSortableColumn="provider.name">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.providers.provider' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="provider.name"></p-sortIcon>
                        <p-columnFilter field="provider" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- COST -->
            <th *ngIf="canSeeCosts" pSortableColumn="cost">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.insurance.cost' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="cost"></p-sortIcon>
                        <p-columnFilter field="cost" type="number" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- OBSERVATIONS -->
            <th pSortableColumn="observations">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'drivers.table.observations' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="observations"></p-sortIcon>
                        <p-columnFilter field="observations" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- VEHICLE -->
            <th *ngIf="!nested" pSortableColumn="vehicle">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.vehicle.vehicle' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="vehicle"></p-sortIcon>
                        <p-columnFilter field="vehicle" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- INSURANCE TYPE -->
            <th pSortableColumn="insuranceType">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'general.type' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="insuranceType"></p-sortIcon>
                        <p-columnFilter field="insuranceType" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- START DATE -->
            <th pSortableColumn="startDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.start' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="startDate"></p-sortIcon>
                        <p-columnFilter field="startDate" type="date" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- END DATE -->
            <th pSortableColumn="endDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.end' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="endDate"></p-sortIcon>
                        <p-columnFilter field="endDate" type="date" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

            <!-- CANCELLATION DATE -->
            <th pSortableColumn="cancellationDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.accessories.disabledDate' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="cancellationDate"></p-sortIcon>
                        <p-columnFilter field="cancellationDate" type="date" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>

                </div>
            </th>

        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-insurance>

        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(insurance)">

            <!-- SELECT -->
            <td>
                <div class="d-flex justify-content-center">
                    <p-tableCheckbox [value]="insurance"></p-tableCheckbox>
                </div>
            </td>

            <!-- POLCIY NUMBER -->
            <td>
                {{ insurance.policyNumber }}
            </td>

            <!-- AGENT -->
            <td>
                {{ insurance.provider?.name }}
            </td>

            <!-- COST -->
            <td *ngIf="canSeeCosts">
                {{ insurance.cost }} €
            </td>

            <!-- OBSERVATIONS -->
            <td>
                {{ insurance.observations }}
            </td>

            <!-- VEHICLE -->
            <td *ngIf="!nested">
                {{ insurance.vehicle.name }}
            </td>

            <!-- INSURANCE TYPE -->
            <td>
                {{ insurance.insuranceType.name }}
            </td>

            <!-- START DATE -->
            <td>
                {{ insurance.startDate | date: 'dd/MM/yyyy' }}
            </td>

            <!-- END DATE -->
            <td>
                {{ insurance.endDate | date: 'dd/MM/yyyy' }}
            </td>

            <td>
                {{ insurance.disabledDate | date: 'dd/MM/yyyy' }}
            </td>

        </tr>

    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="colspan" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td [attr.colspan]="colspan" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">

                    <!-- ADD FINE BUTTON -->
                    <app-table-add-button *ngIf="isCreateGranted"
                        [text]=" 'tableManagement.insurance.insurances' | translate"
                        (btnClick)="create()"></app-table-add-button>

                    <!-- CHANGE STATUS BUTTON -->
                    <app-table-change-status-button (btnClick)="changeStatus()"
                        [isDisabled]="selectedEntities.length === 0 || !isDisabledGranted"></app-table-change-status-button>

                    <!-- ADD DUPLICATE BUTTON -->
                    <app-table-duplicate-button [text]=" 'tableManagement.insurance.insurances' | translate"
                        [isDisabled]=" selectedEntities.length != 1 || !isCreateGranted"
                        (btnClick)="duplicate(selectedEntities)"></app-table-duplicate-button>

                    <div class="d-flex flex-row " style="margin-left: auto;">
                        <!-- UPLOAD FILE FORM  -->
                        <div class="buttonTableExcel custom-image-button"
                            pTooltip="{{'tableManagement.insurance.insuranceRenewalProcess' | translate }}"
                            tooltipPosition="top" (click)="showFileForm()">
                            <svg-icon src="assets/icons/upload-file.svg"
                                [svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
                        </div>
                        <!-- TABLE EXCEL BUTTON -->
                        <app-table-excel-button class="buttonTableExcel"
                            (btnClick)="exportExcel()"></app-table-excel-button>
                        <!-- CALCULATE FORM  -->
                        <div class="buttonTableExcel custom-image-button"
                            pTooltip="{{'tableManagement.insurance.calculateQuarter' | translate }}"
                            tooltipPosition="top" (click)="showQuarterlyCalculation()">
                            <svg-icon src="assets/icons/upload-file.svg"
                                [svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
                        </div>
                        <!-- CALCULATE FORM  -->
                        <!--<div class="buttonTableExcel custom-image-button "  (click)="showReports()">
                                    <svg-icon src="assets/icons/upload-file.svg"
                                    [svgStyle]="{ 'height.px': 30, 'width.px': 30 }"></svg-icon>
                                </div>-->
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->

</p-table>

<!-- INSURANCE FORM -->
<app-insurance-form [vehicleId]="vehicle" (return)="refreshList($event,false)"></app-insurance-form>
<!-- INSURANCE UPLOAD FILE FORM -->
<app-upload-file-form (refreshTable)="refresh()" [renewalType]="renewalType"
    [title]="fileFormTitle"></app-upload-file-form>
<app-report></app-report>
<app-insurance-quarterly-calculation></app-insurance-quarterly-calculation>