<p-accordionTab i18n-header header="ACC On without Tag Event Configuration">
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>ACC On without Tag Event Configuration</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="deviceParams.proprietaryParameters.acconWithoutTag.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>
  <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.acconWithoutTag.enabled>

    <form-input i18n-fieldName fieldName="Output pattern" [errors]="errors.outputPattern">
      <app-output-pattern></app-output-pattern>
    </form-input>

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldname fieldName="Tag time interval" [errors]="errors.tagDetectedTimeInterval">
        <p-spinner id="tagDetectedTimeInterval" size="5" [(ngModel)]="deviceParams.proprietaryParameters.acconWithoutTag.tagDetectedTimeInterval" [min]="15"
          [max]="255"></p-spinner>
        <span class="ui-inputgroup-addon">15~255</span>
        <span class="ui-inputgroup-addon" i18n>seconds</span>
      </form-input>
    </div>

  </div>

</p-accordionTab>
