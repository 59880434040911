import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import {
    CustomMapOptions,
    UpdatePOIMarkerEvent,
} from "../../shared/custom-map/custom-map.model";
import { IconService } from "../../../rest/icon.service";
import { PoicategoryService } from "../../../rest/poicategory.service";
import { Rest } from "../../../rest/rest_client";
import { CercaliaMapService } from "../../../services/cercalia-map/cercalia-map.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-poi-category-form",
    templateUrl: "./poi-category-form.component.html",
    styleUrls: ["./poi-category-form.component.scss"],
    providers: [PoicategoryService, IconService, CercaliaMapService],
})
export class PoiCategoryFormComponent
    extends EntityFormComponent<Rest.POICategory>
    implements OnChanges, OnInit {
    availableIcons: SelectItem[];
    clonedEntity: Rest.POICategory;
    selectedIcon: any; //Rest.Icon;
    mySelectedIcon: any;
    iconsList: Rest.Icon[];

    displayAddIcons: boolean;
    uploadedFiles: any[] = [];
    entityCategory: Rest.IconEntityAssociationEnum = "POI_CATEGORY";
    poiCategory: Rest.POICategory;

    pois: any[];
    icon: string;

    /* Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    //opciones para configurar el mapa de pois.
    //estas opciones varian en función del modo en que se inicializa el dialogo cada vez.
    showMap = true;
    //showMap = false;
    customMapOptions: CustomMapOptions = {};

    //Datos de entrada para este componente. Se utilizan para configurar el mapa
    @Input() selectedPOICategory: Rest.POICategory;
    @Input() listPOIs: Rest.POI[]; //si existe listPOIs, se ignara la lista de POIs de selectedPOICategory

    //Evento que genera el formulario cuando se quiere actualizar la posición de un poi
    @Output() updatePOIevent: EventEmitter<UpdatePOIMarkerEvent> =
        new EventEmitter<UpdatePOIMarkerEvent>();

    //#region FORM LABELS
    title: string;
    nameLabel: string;
    iconLabel: string;
    enabledLabel: string;
    saveLabel: string;
    changeStatusLabel: string;
    //#endregion

    constructor(
        poiCategoryService: PoicategoryService,
        private iconService: IconService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        // private cercaliaMapService: CercaliaMapService,
        authenticationService: AuthenticationService,
        private translate: TranslateService,
    ) {
        super(
            poiCategoryService,
            notificationsService,
            i18n,
            authenticationService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        const that = this;
        this.iconService.getEntityIcons("POI_CATEGORY").then(function (response) {
            that.availableIcons = that.toSelectItem(response);
            that.iconsList = response;
        });
        this.initEntity(false);

        this.translateLabels();

        //si el input selectedPOICategory existe, inicializamos el nombre del mapa,
        // guardamos la lista de pois asocidos a este y su icono (para pintar sus pois en el mapa usando ese icono)
        let mapIdAux, poisAux, icon;
        if (this.selectedPOICategory) {
            mapIdAux = "" + this.selectedPOICategory.id;
            poisAux = this.selectedPOICategory.pois;
            icon =
                this.selectedPOICategory.icon.path +
                "/" +
                this.selectedPOICategory.icon.entity +
                "/" +
                this.selectedPOICategory.icon.name;
        }
        //dejamos abierta la posibilidad que el dialogo tenga muchos mapas. De momento todos los pois se pintant sobre el mismo mapa.
        //this.mapId = mapIdAux || 'map';
        //this.mapId = 'map';
        this.pois = poisAux || [];
        this.icon = icon;
    }

    /**
     * Cada vez que un @Input modifica su valor (desde el exterior), se ejecuta este método.
     * Lo aprovechamos para reconfigurar las opciones del dialogo y/o mapa
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        let mapIdAux, poisAux, icon;
        if (this.selectedPOICategory) {
            mapIdAux = "" + this.selectedPOICategory.id; //no lo usamos de momento
            poisAux = this.selectedPOICategory.pois;
            icon =
                this.selectedPOICategory.icon.path +
                "/" +
                this.selectedPOICategory.icon.entity +
                "/" +
                this.selectedPOICategory.icon.name;
        }
        //si input recive listPOIs, en el mapa se muestran estos en lugar de los asociados a selectedPOICategory
        if (this.listPOIs && this.listPOIs.length) {
            poisAux = this.listPOIs;
        }
        //this.mapId = mapIdAux || 'map';
        //this.mapId = 'map';
        this.pois = poisAux || [];
        this.icon = icon;
        this.configureMode();
    }

    /**
     * Se configuran las opciones del mapa para que este muestre o no ciertos iconos y
     * capture/genere los eventos correspondientes
     */
    private configureMode() {
        this.selectedPOICategory ? this.initEntity(true) : this.initEntity(false);
    }

    beforeShow() {
        /**Permissions */
        if (this.isNew) {
            this.disableSave = false;
        } else {
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */

        if (!this.isNew) {
            this.selectedIcon = {
                entity: this.entity.icon.entity,
                id: this.entity.icon.id,
                path: this.entity.icon.path,
                imageEncoded: this.entity.icon.imageEncoded
            };
        } else {
            let entity = "default";
            let id = -1;
            let path = "assets/images/default.gif";
            let imageEncoded = "";
            if (this.iconsList && this.iconsList.length) {
                entity = this.iconsList[0].entity;
                id = this.iconsList[0].id;
                path = this.iconsList[0].path;
                imageEncoded = this.iconsList[0].imageEncoded;
            }
            this.selectedIcon = {
                entity,
                id,
                path,
                imageEncoded
            };
        }
    }

    // beforeShow() {
    //   this.showMap = true;
    // }

    // initMapAfterViewInit(event) {
    //   if (!this.isNew) {
    //     this.selectedIcon = ({ entity: this.entity.icon.entity, id: this.entity.icon.id, path: this.entity.icon.path });
    //   }
    //   else {
    //     let entity = 'default';
    //     let id = -1;
    //     let path = 'assets/images/default.gif';
    //     if (this.iconsList && this.iconsList.length) {
    //       entity = this.iconsList[0].entity;
    //       id = this.iconsList[0].id;
    //       path = this.iconsList[0].path;
    //     }
    //     this.selectedIcon = ({
    //       entity,
    //       id,
    //       path,
    //     });
    //   }
    // }

    // close(event){
    //   this.showMap = false;
    // }

    beforeSave() {
        for (const a in this.entity.pois) {
            for (const p in this.entity.pois[a]) {
                if (p == "_$visited") {
                    delete this.entity.pois[a]["_$visited"];
                }
            }
        }

        //isNew indica si la POICategory se tiene que crear
        if (this.isNew) {
            for (const a in this.iconsList) {
                if (this.selectedIcon.id === this.iconsList[a].id) {
                    this.entity.icon = this.iconsList[a];
                }
            }
        }
        //Update PoiCategory
        else {
            for (const a in this.iconsList) {
                if (this.selectedIcon.id === this.iconsList[a].id) {
                    this.entity.icon = this.iconsList[a];
                }
            }
        }
    }

    onHide(event) {
        //this.showMap = false;
        this.onClose.emit();
    }

    beforeCreate() {
        this.initEntity(false);
    }

    saveNewPOIsList() {
        this.save();
    }

    addIcons() {
        this.displayAddIcons = true;
    }

    cancelAddIcons() {
        this.displayAddIcons = false;
        var that = this;
        this.availableIcons = [];
        this.iconsList = [];
        this.iconService.getEntityIcons("POI_CATEGORY").then(function (response) {
            that.availableIcons = that.toSelectItem(response);
            that.iconsList = response;
        });
    }

    saveIcons() {
    }

    onUpload(event) {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    notifyUpdatePOI(event: UpdatePOIMarkerEvent) {
        //Este formulario se encarga de gestionar Categorías de pois.
        //Cuando el mapa genera el evento que indica que se ha "movido" un poi de sitio,
        //delegamos la reponsabilidad de comunicarlo al backend al componente padre para que pueda
        //crear un nuevo vertex y añadirlo a la nueva posición del POI actualizado. También deberá
        //actualitzar provincia, postal code, address, municipality
        this.updatePOIevent.emit(event);
    }

    private initEntity(edit: boolean) {
        if (edit) {
            this.title = this.i18n._("Edit POI Category");
            this.isNew = false;
            //No se pueden crear pois. Se puede editar la posicion de los pois que se muestran
            //arrastrandolos por el mapa. El componente mapa solo actualiza los marcadores y genera eventos para
            //indicar a este formulario que se ha actualizado la posición de un marcador pero no hay peticiones en el backend
            //las peticiones las debe tratar el formulario y la lista de pois.
            //TODO, una vez realizada la petición al backend seria conveniente implementar un evento UpdatedPOIPositionSuccess o error
            //que se propagase desde el componente padre (poi-categories-list.component) al componente hijo (custom-map)
            this.customMapOptions = {
                createPOIonClick: false,
                editPOIs: true,
            };
            this.displayAddIcons = false;
        } else {
            this.title = this.i18n._("Create POI Category");
            this.isNew = true;
            this.customMapOptions = {
                createPOIonClick: false,
                editPOIs: false,
            };
            this.displayAddIcons = false;
        }
    }

    private toSelectItem(icons: Rest.Icon[]): SelectItem[] {
        const result = [];
        for (const a in icons) {
            result.push({
                label: icons[a].name,
                value: {
                    id: icons[a].id,
                    entity: icons[a].entity,
                    path: icons[a].path,
                    imageEncoded: icons[a].imageEncoded
                },
            });
        }
        return result;
    }


    // /**
    //  * Translate all the variables that are binded on the form,
    //  * using the translator library.
    //  *
    //  * @private
    //  * @memberof PoiCategoryFormComponent
    //  */
    private translateLabels() {
        this.title = this.translate.instant('control-panel.poi-categories.poi-category-title');
        this.nameLabel = this.translate.instant('control-panel.poi-categories.poiName');
        this.iconLabel = this.translate.instant('control-panel.poi-categories.poiIcon');
        this.enabledLabel = this.translate.instant('control-panel.poi-categories.poiEnabled');
        this.saveLabel = this.translate.instant('zones.zone-form.save');
        this.changeStatusLabel = this.translate.instant('zones.zone-form.change-status');
    }
}
