<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Analog Input Port
          Settings(AT+GTAIS)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g">

      <div class="ui-g-12">
        <form-input fieldName="Analog Input 1">

        </form-input>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Analog Input Port Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown (onChange)="trackEventModeChange(aisParameters.mode, $event)" [options]="modeOptions"
            [(ngModel)]="aisParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>

      <div [class.disableDIV]=!trackModeSwitch>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Min Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.minThreshold" [min]="0" [max]="30000"
                  (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~30000</span>
                <span class="ui-inputgroup-addon">mv</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Max Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.maxThreshold" [min]="0" [max]="30000"
                  (onChange)="onChangeMax()"></p-spinner>
                <span class="ui-inputgroup-addon">0~30000</span>
                <span class="ui-inputgroup-addon">mv</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Sample Rate">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.sampleRate" [min]="0" [max]="12"></p-spinner>
                <span class="ui-inputgroup-addon">0~12</span>
                <span class="ui-inputgroup-addon">*2s</span>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <form-input fieldName="Analog Input 2">

        </form-input>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Analog Input Port Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown (onChange)="trackEventModeChange2(aisParameters.mode2, $event)" [options]="modeOptions"
            [(ngModel)]="aisParameters.mode2" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>

      <div [class.disableDIV]=!trackModeSwitch2>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Min Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.minThreshold2" [min]="0" [max]="30000"
                  (onChange)="onChange()"></p-spinner>
                <span class="ui-inputgroup-addon">0~30000</span>
                <span class="ui-inputgroup-addon">mv</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Max Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.maxThreshold2" [min]="0" [max]="30000"
                  (onChange)="onChangeMax()"></p-spinner>
                <span class="ui-inputgroup-addon">0~30000</span>
                <span class="ui-inputgroup-addon">mv</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Sample Rate">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="aisParameters.sampleRate2" [min]="0" [max]="12"></p-spinner>
                <span class="ui-inputgroup-addon">0~12</span>
                <span class="ui-inputgroup-addon">*2s</span>
              </div>
            </div>
          </div>
        
        </div>

      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Voltage Margin Error">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="aisParameters.voltageMarginError" [min]="0" [max]="100"></p-spinner>
          <span class="ui-inputgroup-addon">0~100</span>
          <span class="ui-inputgroup-addon">*10mV</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>