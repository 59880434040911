<p-accordionTab i18n-header header="Time configuration">


  <div class="ui-g">

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fieldName="Door Lock" [errors]="errors.enableDoorLock">
        <p-inputSwitch [(ngModel)]="time.enableDoorLock"></p-inputSwitch>
      </form-input>
    </div>

    <div *ngIf="time.enableDoorLock">
      <div class="ui-g-12 ui-m-6">
        <form-input [required]="true" i18n-fieldname fieldName="Time door lock" [errors]="errors.timeDoorLock">
          <p-spinner id="timeConfiguration" size="5" [(ngModel)]="time.timeDoorLock" [min]="0" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">0~65535</span>
          <span class="ui-inputgroup-addon" i18n>ms</span>
        </form-input>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <form-input [required]="true" i18n-fieldname fieldName="Door Unlock" [errors]="errors.enableDoorLock">
        <p-inputSwitch [(ngModel)]="time.enableDoorUnlock"></p-inputSwitch>
      </form-input>
    </div>

    <div *ngIf="time.enableDoorUnlock">
      <div class="ui-g-12 ui-m-6">
        <form-input [required]="true" i18n-fieldname fieldName="Time door unlock" [errors]="errors.timeDoorUnlock">
          <p-spinner size="5" [(ngModel)]="time.timeDoorUnlock" [min]="0" [max]="65535"></p-spinner>
          <span class="ui-inputgroup-addon">0~65535</span>
          <span class="ui-inputgroup-addon" i18n>ms</span>
        </form-input>
      </div>
    </div>
  </div>

</p-accordionTab>
