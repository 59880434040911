<p-dialog *ngIf='isDialogVisible'
          header='{{poiRouteFormTitle}}'
          [(visible)]='isDialogVisible'
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='true'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [closable]='true'
          (visibleChange)='initPoi()'
          [style]="{width: '70vw'}"
>

	<!-- SECCION 1 -->
	<div class='d-flex flex-column gap-3 p-3'>

			     	<!-- FORM ERRORS -->
				  <div *ngIf='poiFormErrorMessages.length !== 0'>
						<div *ngFor='let error of poiFormErrorMessages'>
							<div class='alert alert-warning' role='alert'>{{error}}</div>
						</div>
					</div>

					<!-- POI NAME -->
					<div class='d-flex row-cols-2 w-100'>
						<div class='d-flex'>
							<form-input [required]='true' fieldName="{{ 'general.name' | translate }}" [errors]=''>
								<input pInputText [(ngModel)]='poi.name' [style]="{'width':'300px'}" />
							</form-input>
						</div>

						<div class='d-flex pb-3'>
							<form-input [required]='false' fieldName="{{ 'general.description' | translate }}" [errors]=''>
								<!-- <input pInputText [(ngModel)]='poi.description' [style]="{'width':'300px'}" /> -->
								<textarea pInputTextarea id="description" [(ngModel)]="poi.description"
									[rows]="3" [style]="{'width':'350px'}" enabled="false">
								</textarea>
							</form-input>
						</div>
					</div>

					<!-- POI URL BLOG -->
					<div class='d-flex row-cols-2 w-100'>
						<div class='d-flex'>
							<form-input [required]='false' fieldName="{{ 'promotedRoutes.pois.link1' | translate }}" [errors]=''>
								<input pInputText [(ngModel)]='poi.link1' [style]="{'width':'300px'}" />
							</form-input>
						</div>

						<div class='d-flex pb-3'>
							<form-input [required]='false' fieldName="{{ 'promotedRoutes.pois.link2' | translate }}" [errors]=''>
								<input pInputText [(ngModel)]='poi.link2' [style]="{'width':'300px'}" />
							</form-input>
						</div>
					</div>

					<!-- coordenades -->
					<div *ngIf='showCoordinates' class='d-flex row-cols-2 w-100'>
						<div class='d-flex'>
							<form-input [required]='false' fieldName="{{ 'sat.vehicleDataMessage.latitude' | translate }}" [errors]=''>
								<input pInputText [(ngModel)]='poi.latitude' [style]="{'width':'300px'}" />
							</form-input>
						</div>

						<div class='d-flex pb-3'>
							<form-input [required]='false' fieldName="{{ 'sat.vehicleDataMessage.longitude' | translate }}" [errors]=''>
								<input pInputText [(ngModel)]='poi.longitude' [style]="{'width':'300px'}" />
							</form-input>
						</div>
					</div>

					<!-- POI ICON -->
					<div class='d-flex row-cols-2 w-100'>
						<div class='d-flex'>
							<form-input [required]='true' fieldName="{{ 'general.icon' | translate }}">
								<!-- POI ICON THUMBNAIL && DROPDOWN -->
								<div class='d-flex flex-row'>
									<!-- POI ICON THUMBNAIL -->
									<div *ngIf='selectedPoiIcon' class='d-flex pe-3'>
										<img [src]='selectedPoiIcon?.iconId?.imageEncoded' alt='' class='poi-icon-thumbnail' />
									</div>
									<!-- POI ICON DROPDOWN -->
									<div class='d-flex'>
										<p-dropdown class='d-flex align-items-center'
                              	[options]='poiIcons'
											[(ngModel)]='selectedPoiIcon'
											dataKey="id"
											placeholder="Select..."
											appendTo='body'
											(onChange)="onChangeIconItem($event)"
                              	[style]="{'width':'300px','height': '40px','line-height':'normal'}"
                 					>

                     				<!-- SELECTED ICON TEMPLATE -->
                     				<ng-template pTemplate='selectedItem'>
                        				<div *ngIf='selectedPoiIcon'>
                           				<div class='d-flex flex-row w-100 h-100 gap-3 ps-3'>
                              				<img [src]='selectedPoiIcon?.iconId?.imageEncoded' alt='' class='poi-icon' />
                              				<div class='d-flex poi-icon-name'>{{selectedPoiIconName}}</div>
                           				</div>
                        				</div>
                     				</ng-template>

				                     <!-- ICON DROPDOWN TEMPLATE -->
                     				<ng-template let-icon pTemplate='item'>
                        				<div class='d-flex flex-row w-100 h-100 gap-3 ps-2'>
                           				<img [src]='icon.iconId.imageEncoded' alt='' class='poi-icon' />
                           				<div class='d-flex poi-icon-name'>{{icon.nameIcon}}</div>
                        				</div>
                     				</ng-template>

				                  </p-dropdown>
									</div>
								</div>
							</form-input>
						</div>

						<div class='d-flex pb-3'>
							<!-- POI IMAGE LABEL -->
							<label style="font-family:'Roboto', 'Trebuchet MS','Arial','Helvetica', 'sans-serif'; font-size:1em; font-weight: bold" i18n>
								{{ 'general.image' | translate }}
							</label>

							<!-- POI IMAGE UPLOAD BUTTON -->
							<div class='d-flex justify-content-center w-100'>
								<button id='uploadImageButton' (click)='uploadPoiImageInput.click()'>
									<img *ngIf='uploadImageIconUrl' id='uploadImageIcon' alt='' src='{{uploadImageIconUrl}}' />
									<img *ngIf='poi?.imagePoi?.imageEncoded' id='uploadedImage' alt='' [src]='poi.imagePoi.imageEncoded'>
								</button>
								<input #uploadPoiImageInput [hidden]='true' type='file' accept='.jpg, .png' (change)='onImageChange($event)'>
							</div>
						</div>
					</div>


	</div>


   <!-- DIALOG FOOTER-->
   <p-footer>
      <div class='d-flex justify-content-end align-content-center p-2 w-100'>
         <button type='button' class='btn custom-button' (click)='savePoi()'>
            {{ 'general.save' | translate }}
         </button>
      </div>
   </p-footer>


</p-dialog>

