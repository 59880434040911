<!-- DEVICES PRIMENG DATATABLE -->
<p-table [value]="paginationResult.entities"
		 [lazy]="true"
		 (onLazyLoad)="loadData($event)"
		 [rows]="paginationRequest.pageSize"
		 [totalRecords]="paginationResult.filteredEntities"
		 [rowsPerPageOptions]="[10,20,30,40,50]"
		 [paginator]="true"
		 [(selection)]="selectedEntities"
		 [loading]="loading"
		 [rowHover]="true"
		 [showCurrentPageReport]="true"
		 [autoLayout]="true"
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class="custom-table"
		 styleClass="p-datatable-striped"
>

	<!-- TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'control-panel.devices.devicesTable' | translate }}
		</div>
	</ng-template>

	<!-- TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>

			<!-- CHECKBOX COLUMN -->
			<th class="checkbox-column">
				<div class="d-flex justify-content-center">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</div>
			</th>

			<!-- IMEI -->
			<th pSortableColumn='imei'>
				<div class='d-flex align-items-center h-100'>

					<!-- COLUMN TITLE -->
					<div class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
						IMEI
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class='d-flex align-items-center h-100'>
						<p-sortIcon field='imei'></p-sortIcon>
						<p-columnFilter field='imei'
										type='text'
										matchMode='contains'
										placeholder="{{ 'general.search' | translate }}"
										display='menu'
										[showMatchModes]='false'
										[showOperator]='false'
										[showAddButton]='false'
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- DEVICE TYPE -->
			<th>
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.devices.deviceType' | translate }}
					</div>

					<!-- COLUMN FILTER -->
					<div class="d-flex align-items-center h-100">
						<p-columnFilter field="deviceTypes"
										type='text'
										matchMode="contains"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]="false"
						>
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-multiSelect [ngModel]="value"
											   [options]="filters['deviceType']"
											   placeholder="{{ 'general.search' | translate }}"
											   (onChange)="filter($event.value)"
								></p-multiSelect>
							</ng-template>
						</p-columnFilter>
					</div>

				</div>
			</th>

			<!-- EXTERNAL ID -->
			<th pSortableColumn="externalId">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.devices.externalId' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="externalId"></p-sortIcon>
						<p-columnFilter field="externalId"
										type="text"
										placeholder="{{ 'general.search' | translate }}"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]='false'
						></p-columnFilter>
					</div>

				</div>
			</th>

			<!-- STATUS -->
			<th pSortableColumn="status">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.status' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">

						<p-sortIcon field="status"></p-sortIcon>

						<p-columnFilter field="status"
										matchMode="contains"
										display="menu"
										[showMatchModes]="false"
										[showOperator]="false"
										[showAddButton]="false"
						>
							<ng-template pTemplate="filter" let-value let-filter="filterCallback">
								<p-multiSelect [ngModel]="value"
											   [options]="filters['status']"
											   placeholder="{{ 'general.search' | translate }}"
											   (onChange)="filter($event.value)"
								></p-multiSelect>
							</ng-template>
						</p-columnFilter>

					</div>

				</div>
			</th>

			<!-- DATE OF MANUFACTURE -->
			<th pSortableColumn="dateOfManufacture">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.devices.dateOfManufacture' | translate }}
					</div>

					<!-- COLUMN SORT ICON -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="dateOfManufacture"></p-sortIcon>
					</div>

				</div>
			</th>

			<!-- DATE OF LAST REVISION -->
			<th pSortableColumn="dateOfLastRevision">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.devices.dateOfLastRevision' | translate }}
					</div>

					<!-- COLUMN SORT ICON -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="dateOfLastRevision"></p-sortIcon>
					</div>

				</div>
			</th>

			<!-- DATE OF NEXT REVISION -->
			<th pSortableColumn="dateOfNextRevision">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'control-panel.devices.dateOfNextRevision' | translate }}
					</div>

					<!-- COLUMN SORT ICON -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="dateOfNextRevision"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="enabled">
				<div class="d-flex align-items-center h-100">

					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">

						<p-sortIcon field="enabled"></p-sortIcon>

						<p-columnFilter type="boolean"
										field="enabled"
										display="menu"
										[showApplyButton]="false"
						></p-columnFilter>
					</div>

				</div>
			</th>

		</tr>
	</ng-template>

	<!-- TABLE BODY -->
	<ng-template pTemplate="body" let-device>
		<tr class="p-selectable-row custom-rows" (dblclick)="editDialog(device)">

			<!-- SELECT -->
			<td>
				<div class="d-flex justify-content-center">
					<p-tableCheckbox [value]="device"></p-tableCheckbox>
				</div>
			</td>

			<!-- IMEI -->
			<td>
				{{device.imei}}
			</td>

			<!-- DEVICE TYPE -->
			<td>
				{{device.deviceType.name}}
			</td>

			<!-- EXTERNAL ID-->
			<td>
				{{device.externalId}}
			</td>

			<!-- STATUS -->
			<td>
				{{device._$status_str}}
			</td>

			<!-- DATE OF MANUFACTURE -->
			<td>
				<div class="d-flex justify-content-center">
					{{device.dateOfManufacture | date:'dd/MM/yy'}}
				</div>
			</td>

			<!-- DATE OF LAST REVISION -->
			<td>
				<div class="d-flex justify-content-center">
					{{device.dateOfLastRevision | date:'dd/MM/yy'}}
				</div>
			</td>

			<!-- DATE OF NEXT REVISION -->
			<td>
				<div class="d-flex justify-content-center">
					{{device.dateOfNextRevision | date:'dd/MM/yy'}}
				</div>
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i class="pi"
					   [ngClass]="{'true-icon pi-check-circle': device.enabled, 'false-icon pi-times-circle': !device.enabled}"></i>
				</div>
			</td>

		</tr>
	</ng-template>

	<!-- TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="10" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- FOOTER -->
	<ng-template pTemplate="footer">
		<tr>
			<td colspan="10" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">

					<!-- ADD DEVICE BUTTON -->
					<app-table-add-button text="{{ 'control-panel.devices.device' | translate }}"
										  (btnClick)="add()"
										  [isDisabled]="!user.canManageDevices"
					></app-table-add-button>

					<!-- CHANGE STATUS BUTTON -->
					<app-table-change-status-button (btnClick)="changeStatus()"
													[isDisabled]="!user.canManageDevices || selectedEntities.length === 0"
					></app-table-change-status-button>

				</div>
			</td>
		</tr>
	</ng-template>
</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
	<app-change-status-dialog [items]=" 'control-panel.status-dialog.devices' | translate "
							  [display]="true"
							  [service]=deviceService
							  [entitiesList]=selectedEntities
							  (return)="refreshList($event)"
							  (close)="closeStatusDialog($event)"
	></app-change-status-dialog>
</div>

<!-- DEVICE FORM -->
<app-device-form></app-device-form>
