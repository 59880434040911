import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { ClientService } from "../../../rest/client.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { ClientFormComponent } from "../client-form/client-form.component";

@Component({
    selector: "app-clients-list",
    templateUrl: "./clients-list.component.html",
    styleUrls: ["./clients-list.component.css"],
    providers: [ClientService, ConfirmationService, UserTimePipe],
})
export class ClientsListComponent
    extends EntityListComponent<Rest.Client>
    implements OnInit {
    title: string = this.i18n._("Clients");

    @ViewChild(ClientFormComponent, { static: true })
    clientForm: ClientFormComponent;

    disableSave: boolean;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    constructor(
        private clientService: ClientService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            clientService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.clientService = clientService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.clientForm;
        this.hasEntityFilters = true;
        /*Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );
        this.paginationResult.entities.forEach((cl: any) => {
            /*Permissions */
            cl._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
            cl._$update = true;
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }
}
