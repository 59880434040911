import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { VehicleService } from '../../../rest/vehicle.service';
import { DeviceService } from '../../../rest/device.service';
import { DevicetypeService } from '../../../rest/devicetype.service';
import { Rest } from '../../../rest/rest_client';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { VT10ParametersForm } from '../../device-parameters/vt10-parameters-form/vt10-parameters-form.component';
import { EntityFormComponent } from '../../entity-form/entity-form.component';
import { UserTimePipe } from '../../shared/UserTimePipe';
import {
    Gv300ParametersFormComponent
} from 'app/components/device-parameters/gv300-parameters-form/gv300-parameters-form.component';
import {
    GMT200ParametersFormComponent
} from 'app/components/device-parameters/gmt200-parameters-form/gmt200-parameters-form.component';
import {
    ConcoxMT200ParametersFormComponent
} from 'app/components/device-parameters/concox-mt200-parameters-form/concox-mt200-parameters-form.component';
import {
    WeTrack2ParametersFormComponent
} from 'app/components/device-parameters/we-track2-parameters-form/we-track2-parameters-form.component';
import {
    Gv300canParametersFormComponent
} from 'app/components/device-parameters/gv300can-parameters-form/gv300can-parameters-form.component';
import {
    Gv800ParametersFormComponent
} from 'app/components/device-parameters/gv800-parameters-form/gv800-parameters-form.component';
import {
    X8ParametersFormComponent
} from 'app/components/device-parameters/x8-parameters-form/x8-parameters-form.component';
import {
    Vt200ParametersFormComponent
} from 'app/components/device-parameters/vt200-parameters-form/vt200-parameters-form.component';
import { SimOperatorTypeService } from '../../../rest/simoperatortype.service';
import { SimPlansService } from '../../../rest/simplans.service';
import { SimPlanGroupsService } from '../../../rest/simplangroups.service';
import { Auxiliar } from '../../../model/Auxiliar';
import {
    EvixParametersFormComponent
} from '../../device-parameters/evix-parameters-form/evix-parameters-form.component';
import { UserService } from '../../../rest/user.service';
import { NoticegroupService } from '../../../rest/noticegroup.service';
import User = Rest.User;
import NoticeGroup = Rest.NoticeGroup;
import Device = Rest.Device;
import { IofrogParametersFormComponent } from 'app/components/device-parameters/iofrog-parameters-form/iofrog-parameters-form.component';
import { TeltonikaParametersFormComponent } from 'app/components/device-parameters/teltonika-parameters-form/teltonika-parameters-form.component';
import { Ob22ParametersFormComponent } from 'app/components/device-parameters/ob22-parameters-form/ob22-parameters-form.component';
import { Gl320mParametersFormComponent } from 'app/components/device-parameters/gl320m-parameters-form/gl320m-parameters-form.component';
import { SmartCoffeeParametersFormComponent } from 'app/components/device-parameters/smart-coffee-parameters-form/smart-coffee-parameters-form.component';

@Component({
    selector: "app-device-form",
    templateUrl: "./device-form.component.html",
    styleUrls: ["./device-form.component.css"],
    providers: [
        DeviceService,
        VehicleService,
        ConfirmationService,
        DevicetypeService,
        UserTimePipe,
        SimOperatorTypeService,
        SimPlansService,
        SimPlanGroupsService,
        NoticegroupService
    ],
})
export class DeviceFormComponent extends EntityFormComponent<Rest.Device> implements OnInit {
    @Output() userSelected = new EventEmitter<User>();

    @ViewChild(VT10ParametersForm) preConfigForm: VT10ParametersForm;

    @ViewChild("VT10ChildComponent") VT10ChildComponent: VT10ParametersForm;
    @ViewChild("GV300ChildComponent")
    GV300ChildComponent: Gv300ParametersFormComponent;
    @ViewChild("GMT200ChildComponent")
    GMT200ChildComponent: GMT200ParametersFormComponent;
    @ViewChild("ConcoxMT200ChildComponent")
    ConcoxMT200ChildComponent: ConcoxMT200ParametersFormComponent;
    @ViewChild("WeTrack2ChildComponent")
    WeTrack2ChildComponent: WeTrack2ParametersFormComponent;
    @ViewChild("Gv300canParametersFormComponent")
    Gv300canParametersFormComponent: Gv300canParametersFormComponent;
    @ViewChild("Gv800ParametersFormComponent")
    Gv800ParametersFormComponent: Gv800ParametersFormComponent;
    @ViewChild("X8ParametersFormComponent")
    X8ParametersFormComponent: X8ParametersFormComponent;
    @ViewChild("Vt200ParametersFormComponent")
    Vt200ParametersFormComponent: Vt200ParametersFormComponent;
    @ViewChild("EvixParametersFormComponent") EvixParametersFormComponent: EvixParametersFormComponent;
    @ViewChild("IofrogParametersFormComponent") IofrogParametersFormComponent: IofrogParametersFormComponent;
    @ViewChild("TeltonikaParametersFormComponent") TeltonikaParametersFormComponent: TeltonikaParametersFormComponent;
    @ViewChild("OB22ParametersFormComponent") OB22ParametersFormComponent: Ob22ParametersFormComponent;
    @ViewChild("Gl320mParametersFormComponent") Gl320mParametersFormComponent: Gl320mParametersFormComponent;
    @ViewChild("SmartCoffeeParametersFormComponent") SmartCoffeeParametersFormComponent: SmartCoffeeParametersFormComponent;
    deviceService: DeviceService;
    vehicleService: VehicleService;

    //TODO: ENABLEFILTER
    vehicleFilter: Rest.ListPaginationRequest;

    deviceTypeService: DevicetypeService;
    availableDeviceTypes: SelectItem[];
    availableDeviceStatus: SelectItem[];
    availableFirmware: SelectItem[];
    availableCommunicationType: SelectItem[];
    availableAppTypes: SelectItem[];
    availableSimOperatorTypes: SelectItem[];
    availableSimPlans: SelectItem[];
    availableSimPlanGroups: SelectItem[];
    disableSave: boolean;
    disabeChangeStatus: boolean;
    simOperatorTypeService: SimOperatorTypeService;
    simPlansService: SimPlansService;
    simPlanGroupsService: SimPlanGroupsService;
    authenticationservice: AuthenticationService;
    filterAvailableFirmware: SelectItem[];

    deviceFormTitle: string;

    /**Preservation of relationships */
    vehicleRequestContext: Rest.ListPaginationRequest;
    /*********************************/

    firmwareValue: any;
    firstFirmWare: any;

    dateOfLastRevision: Date;
    dateOfManufacture: Date;
    dateOfNextRevision: Date;
    dateOfSimActivation: Date;
    dateOfSimExpiration: Date;

    dropdownSimPlansDisable: Boolean;
    dropdownSimPlanGroupsDisable: Boolean;
    disableDIV: Boolean;

    selectedOperatorType: Rest.SimOperatorType;
    sim: Rest.SimDevicesPlanGroups;

    user: Rest.User;
    users: User[];
    notificationsGroups: NoticeGroup[];

    canReadSim: Boolean;
    canUpdateSim: Boolean;
    canCreateSim: Boolean;

    credits: any;
    currentCredits: number = 0;
    creditsVisible: Boolean = false;

    showVehicleCard: boolean = false;
    showDriverCard: boolean = false;


    constructor(
        deviceService: DeviceService,
        vehicleService: VehicleService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        devicetypeService: DevicetypeService,
        private userTime: UserTimePipe,
        authenticationService: AuthenticationService,
        simoperatortypeService: SimOperatorTypeService,
        simplansService: SimPlansService,
        simplangroupService: SimPlanGroupsService,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private noticeGroupService: NoticegroupService
    ) {
        super(deviceService, notificationsService, i18n, authenticationService);

        this.vehicleService = vehicleService;
        this.deviceService = deviceService;
        this.deviceTypeService = devicetypeService;
        this.simOperatorTypeService = simoperatortypeService;
        this.simPlansService = simplansService;
        this.simPlanGroupsService = simplangroupService;
        this.authenticationservice = authenticationService;

        /* RICARD */
        this.availableDeviceTypes = [];
        this.availableDeviceStatus = RestExt.getEnumItems(
            RestExt.DeviceStatusEnum,
            i18n
        );
        this.availableFirmware = RestExt.getEnumItemsNoTranslation(
            RestExt.FirmwareVersion
        );
        this.availableCommunicationType = RestExt.getEnumItemsNoTranslation(
            RestExt.DeviceCommunicationType
        );
        this.availableAppTypes = RestExt.getEnumItemsNoTranslation(
            RestExt.AppTypes
        );
        this.availableSimOperatorTypes = [];
        this.availableSimPlans = [];
        this.availableSimPlanGroups = [];
        this.filterAvailableFirmware = [];
        //Only allow to select VT10 firmware versions
        //This should be done when device type changes!

        this.dropdownSimPlanGroupsDisable = true;
        this.dropdownSimPlansDisable = true;
        this.canCreateSim = false;
        this.canReadSim = false;
        this.canUpdateSim = false;

        this.filterAvailableFirmware = this.availableFirmware;

        this.users = [];
    }

    ngOnInit() {
        //TODO: ENABLEFILTER
        this.vehicleFilter = RestExt.firstPageRequest();
        this.user = this.authenticationservice.user;

        if (this.user.profiles != null) {
            for (var i = 0; i < this.user.profiles.length; i++) {
                if (!this.canReadSim) {
                    if (this.user.profiles[i].actions.find((t) => t.name === RestExt.ActionEnum[RestExt.ActionEnum.SIM_DEVICES_PLAN_GROUPS_READ]) !== undefined) this.canReadSim = true;
                }
                if (!this.canCreateSim) {
                    if (this.user.profiles[i].actions.find((t) => t.name === RestExt.ActionEnum[RestExt.ActionEnum.SIM_DEVICES_PLAN_GROUPS_CREATE]) !== undefined) this.canCreateSim = true;
                }
                if (!this.canUpdateSim) {
                    if (this.user.profiles[i].actions.find((t) => t.name === RestExt.ActionEnum[RestExt.ActionEnum.SIM_DEVICES_PLAN_GROUPS_UPDATE]) !== undefined) this.canUpdateSim = true;
                }
            }
        }

        super.ngOnInit();

        this.deviceTypeService.getPage(RestExt.allRecords).then((dt: Rest.Page<Rest.DeviceType>) => {

            this.availableDeviceTypes = RestExt.entitiesToSelectItems(dt.entities);
            this.availableDeviceTypes.unshift({ label: "Select Type", value: null });
        });

        this.simOperatorTypeService.getPage(RestExt.allRecords).then((dt: Rest.Page<Rest.SimOperatorType>) => {
            this.availableSimOperatorTypes = RestExt.entitiesToSelectItems(dt.entities);
            this.availableSimOperatorTypes.unshift({ label: "Select Type", value: null });
        });

    }

    beforeShow() {
        this.credits = null;
        this.currentCredits = 0;
        this.creditsVisible = false;
        this.vehicleRequestContext = RestExt.firstPageRequest();
        this.disableHbmDIV();
        if (this.isNew == true) {
            this.deviceFormTitle = this.i18n._("Create Device");
            this.selectedOperatorType = null;
            this.dropdownSimPlanGroupsDisable = true;
            this.dropdownSimPlansDisable = true;
        } else {
            this.deviceFormTitle = this.i18n._("Edit Device");
            this.vehicleRequestContext.context = {
                entity: "DEVICE",
                action: "EDIT",
                id: this.entity.id,
            };

            if (this.entity.sim == null) {
                this.selectedOperatorType = null;
                this.dropdownSimPlanGroupsDisable = true;
                this.dropdownSimPlansDisable = true;
            }
        }

        if (this.isNew) {
            this.disableSave = false;
        } else {
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }

            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }

            /*this.entity.dateOfLastRevision != undefined ? (this.entity.dateOfLastRevision = new Date(this.entity.dateOfLastRevision)) : this.entity.dateOfLastRevision;
            this.entity.dateOfManufacture != undefined ? (this.entity.dateOfManufacture = new Date(this.entity.dateOfManufacture)) : this.entity.dateOfManufacture;
            this.entity.dateOfNextRevision != undefined ? (this.entity.dateOfNextRevision = new Date(this.entity.dateOfNextRevision)) : this.entity.dateOfNextRevision;*/
            /*this.entity.dateOfLastRevision = new Date(this.entity.dateOfLastRevision);
            this.entity.dateOfManufacture = new Date(this.entity.dateOfManufacture);
            this.entity.dateOfNextRevision = new Date(this.entity.dateOfNextRevision);*/

            this.dateOfLastRevision = this.userTime.utcToCalendarDate(
                this.entity.dateOfLastRevision
            );
            this.dateOfManufacture = this.userTime.utcToCalendarDate(
                this.entity.dateOfManufacture
            );
            this.dateOfNextRevision = this.userTime.utcToCalendarDate(
                this.entity.dateOfNextRevision
            );
            if (this.entity.sim != null) {
                this.entity.sim.simActivationDate = this.userTime.utcToCalendarDate(this.entity.sim.simActivationDate);
                this.entity.sim.simExpirationDate = this.userTime.utcToCalendarDate(this.entity.sim.simExpirationDate);
            }

            if (this.entity.appTypes == "MOTO") {
                this.getCredits();
            }

            /***********/
            if (!this.isNew) {
                this.firstFirmWare = RestExt.FirmwareVersion[this.entity.firmware] + 1;
            }
            /***********/

            if (!this.isNew && this.entity.sim != null) {
                const that = this;
                that.simOperatorTypeService.getPage(RestExt.allRecords).then(
                    function (response) {
                        that.selectedOperatorType = response.entities.find((t) => t.id == that.entity.sim.planId.simOperatorType.id);

                        that.simPlanGroupsService.getSimGroupsByOperatorType(that.selectedOperatorType.id.toString()).then(
                            function (response) {
                                that.availableSimPlanGroups = Auxiliar.toEntitySelectItemArr(response);
                                that.dropdownSimPlanGroupsDisable = response.length < 0;
                                that.simPlansService.getSimPlansByGroup(that.entity.sim.groupId.id.toString()).then(
                                    function (response) {
                                        that.availableSimPlans = Auxiliar.toEntitySelectItemArr(response);
                                        console.log(JSON.stringify(that.availableSimPlans));
                                        that.dropdownSimPlansDisable = response.length < 0;
                                        that.entity.sim.planId = response.find((t) => t.id == that.entity.sim.planId.id);
                                        console.log(JSON.stringify(that.entity.sim.planId));
                                    });
                            });
                    });
            }
        }

        if (!this.isNew) {

            /* Get the Users who are using the device */
            this.userService.getUsersByDevice(this.entity.id).then((users) => {
                this.users = users;
            });

            /* Get the Notice Groups assigned to the device */
            this.noticeGroupService.getNoticeGroupByDevice(this.entity.id).then((noticeGroups) => {
                this.notificationsGroups = noticeGroups;
            });
        }

    }

    onHide(event) {
        this.preConfigForm.initConfig();
    }

    beforeSave() {
        if (this.entity.deviceType.id === 1) {
            this.entity.configuration.parameters =
                this.VT10ChildComponent.getVT10DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "VT10";
        } else if (this.entity.deviceType.id === 3) {
            this.entity.configuration.parameters =
                this.GV300ChildComponent.getGV300DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "GV300";
            this.entity.firmware = "GV300";
        } else if (this.entity.deviceType.id === 2) {
            this.entity.configuration.parameters =
                this.GMT200ChildComponent.getGMT200DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type =
                "GMT200";
            //this.entity.firmware = "GMT200";
        } else if (this.entity.deviceType.id === 4) {
            this.entity.configuration.parameters =
                this.ConcoxMT200ChildComponent.getConcoxMT200DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type =
                "ConcoxMT200";
            this.entity.firmware = "ConcoxMT200";
        } else if (this.entity.deviceType.id === 5) {
            this.entity.configuration.parameters =
                this.WeTrack2ChildComponent.getWeTrack2DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type =
                "WeTrack2";
            this.entity.firmware = "WeTrack2";
        } else if (this.entity.deviceType.id === 6) {
            this.entity.configuration.parameters =
                this.Gv300canParametersFormComponent.getGV300CANDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type =
                "GV300CAN";
            this.entity.firmware = "GV300CAN";
        } else if (this.entity.deviceType.id === 7) {
            this.entity.configuration.parameters =
                this.Gv800ParametersFormComponent.getGV800DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "GV800";
            this.entity.firmware = "GV800";
        } else if (this.entity.deviceType.id === 8) {
            this.entity.configuration.parameters =
                this.X8ParametersFormComponent.getX8DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "X8";
        } else if (this.entity.deviceType.id === 9) {
            this.entity.configuration.parameters =
                this.Vt200ParametersFormComponent.getVT200DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "VT200";
            this.entity.firmware = "VT200";
        } else if (this.entity.deviceType.id === 10) {
            this.entity.configuration.parameters = this.EvixParametersFormComponent.getEVIXDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "EVIX";
        } else if (this.entity.deviceType.id === 12) {
            this.entity.configuration.parameters = this.IofrogParametersFormComponent.getIofrogDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "Iofrog";
            this.entity.firmware = "Iofrog";
        } else if (this.entity.deviceType.id === 13) {
            this.entity.configuration.parameters = this.TeltonikaParametersFormComponent.getTeltonikaDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "Teltonika";
            this.entity.firmware = "Teltonika";
        } else if (this.entity.deviceType.id === 14) {
            this.entity.configuration.parameters = this.OB22ParametersFormComponent.getOB22DeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "OB22";
            this.entity.firmware = "OB22";
        } else if (this.entity.deviceType.id === 15) {
            this.entity.configuration.parameters = this.Gl320mParametersFormComponent.getGL320MDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "GL320M";
            this.entity.firmware = "GL320M";
        }else if(this.entity.deviceType.id === 16){
            this.entity.configuration.parameters = this.SmartCoffeeParametersFormComponent.getSmartCoffeeDeviceParams();
            this.entity.configuration.parameters.proprietaryParameters.type = "SmartCoffee";
            this.entity.firmware = "SmartCoffee";
        }

        this.entity.dateOfLastRevision = this.userTime.calendarDateToUTC(
            this.dateOfLastRevision
        );
        this.entity.dateOfManufacture = this.userTime.calendarDateToUTC(
            this.dateOfManufacture
        );
        this.entity.dateOfNextRevision = this.userTime.calendarDateToUTC(
            this.dateOfNextRevision
        );

        if (this.entity.sim != null) {
            this.entity.sim.simActivationDate = this.userTime.calendarDateToUTC(this.entity.sim.simActivationDate);
            this.entity.sim.simExpirationDate = this.userTime.calendarDateToUTC(this.entity.sim.simExpirationDate);
        }

        this.entity.deviceType.id = this.entity.deviceType.id;
        //this.entity.firmware =
        /* We load  VT10 because its set by default on the form */
        //this.entity = <Rest.Device>{deviceType:<Rest.DeviceType>{id: this.entity.deviceType.id}};//1}};//VT10 is deviceType 1
        /* RICARD */
        //this.entity.configuration = <Rest.DeviceConfiguration>{deviceType: this.entity.deviceType.name};//"VT10"};


        if (this.entity.firmware != undefined) {
            this.firmwareValue = RestExt.FirmwareVersion[this.entity.firmware] + 1; //Add 1 because returns the index of enum value and starts at 0 which corresponds to first item*/
            if (this.isNew) {
                RestExt.removeInvalidParams(
                    this.entity.configuration.parameters,
                    this.firmwareValue,
                    this.entity.deviceType.name
                );
            } else {
                if (this.firstFirmWare > this.firmwareValue) {
                    RestExt.removeInvalidParams(
                        this.entity.configuration.parameters,
                        this.firmwareValue,
                        this.entity.deviceType.name
                    );
                } else {
                    RestExt.removeInvalidParams(
                        this.entity.configuration.parameters,
                        this.firmwareValue,
                        this.entity.deviceType.name
                    );
                    RestExt.addNewParams(
                        this.entity.configuration.parameters,
                        this.firmwareValue,
                        this.entity.deviceType.name
                    );
                }
            }
        }

    }

    customSave(mode: Rest.DeviceCreateMode) {
        if (mode == null) mode == "NORMAL";
        //console.log(JSON.stringify(this.entity));
        this.beforeSave();
        this.messages = [];
        const that = this;
        if (this.isNew) {
            this.deviceService
                .create(this.entity, { mode })
                .then(
                    function (res) {
                        that.display = false;

                        if (that.entity.appTypes == "MOTO") {
                            that.saveCredits(res);
                        }
                        that.return.emit(res);

                    },
                    (err) => {
                        console.log(err);
                        //that.return.emit(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.deviceService
                .update(this.entity, { mode })
                .then(
                    function (res) {
                        that.display = false;
                        if (that.creditsVisible) {
                            if (that.entity.appTypes == "MOTO") {
                                that.saveCredits(res);
                            }
                        }

                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }

    }

    SimOperatorTypeChanged(type: Rest.SimOperatorType) {
        const that = this;
        that.entity.sim = that.entity.sim === undefined || that.entity.sim === null ? {} as Rest.SimDevicesPlanGroups : that.entity.sim;
        if (type == null) {
            that.dropdownSimPlanGroupsDisable = true;
            that.entity.sim.groupId = null;
            that.entity.sim.planId = null;
        } else {
            this.simPlanGroupsService.getSimGroupsByOperatorType(type.id.toString()).then(
                function (response) {
                    that.availableSimPlanGroups = Auxiliar.toEntitySelectItemArr(response);
                    that.dropdownSimPlanGroupsDisable = response.length < 0;
                });
        }

        that.dropdownSimPlansDisable = true;
    }

    SimPlanGroupsChanged(groups: Rest.SimPlanGroups) {
        const that = this;
        this.simPlansService.getSimPlansByGroup(groups.id.toString()).then(
            function (response) {
                that.availableSimPlans = Auxiliar.toEntitySelectItemArr(response);
                that.dropdownSimPlansDisable = response.length < 0;
            });
    }

    disableHbmDIV() {
        if (this.canCreateSim && this.isNew || (this.canUpdateSim && !this.isNew)) {
            this.disableDIV = false;
        } else {
            this.disableDIV = true;
        }
    }

    deviceTypeChanged(event) {
        console.log(this.entity.deviceType.name);
        //change to all types
        this.filterAvailableFirmware = this.availableFirmware.filter(val => val.label.startsWith(this.entity.deviceType.name.toString()));
    }

    unsubscribe(event) {
        this.entity.sim.bajaSim = event.checked;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to change the status?',
            accept: () => {
                this.notificationsService.add(Severity.info, "Modified", "The unsubscribe status has been changed!");
            },
            reject: () => {
                this.notificationsService.add(Severity.info, "Denied", "The unsubscribe change, has been denied!");
                this.entity.sim.bajaSim = !this.entity.sim.bajaSim;

                if (this.entity.sim.bajaSim) {
                    this.entity.sim.fechaBajaSim = new Date();
                } else {
                    this.entity.sim.fechaBajaSim = null;
                }
            }
        });

        if (this.entity.sim.bajaSim) {
            this.entity.sim.fechaBajaSim = new Date();
        } else {
            this.entity.sim.fechaBajaSim = null;
        }
    }

    /**
     * showUserFormComponent
     * */
    public showUserFormComponent(user: User): void {
        this.userSelected.emit(user);
        this.display = false;
    }

    /**
     * showForm()
     * */
    public showForm(device: Device): void {

        /* Retrieve User's info from the server */
        this.deviceService.find(device.id.toString())
            .then((response: Device) => {

                /*Permissions */
                response['_$update'] = true;
                response['_$changeStatus'] = true;

                /* Update user */
                this.update(response);

            })
            .catch((error) => {
                this.handleError(error, this);
            });
    }

    /**
     * showVehicleForm
     */
    public showVehicleForm() {
        this.showVehicleCard = !this.showVehicleCard;
        this.display = false;
    }

    /**
     * closeVehicleForm
     */
    public closeVehicleForm() {
        this.display = true;
    }

    // Method to show the "Driver Info Card Modal"
    showDriverForm() {
        this.showDriverCard = !this.showDriverCard;
        this.display = false;
    }

    // Method to close the "Driver Info Card Modal"
    closeDriverForm() {
        this.display = true;
    }

    /**
     * getCredits()
     * */
    private getCredits() {
        this.currentCredits = 0;
        this.credits = null;

        let that = this;
        if (this.isNew) {
            this.creditsVisible = true;
        } else {
            this.deviceService.getCreditsDevice(that.entity.id).then((result) => {

                this.credits = result;
                if (this.entity.vehicle != null && !this.entity.vehicle.registered) {
                    this.creditsVisible = true;

                    if (result.credits != null) {
                        if (this.credits.applied) {
                            this.creditsVisible = false;
                        }
                        this.currentCredits = result.credits;
                    } else {
                        this.notificationsService.add(Severity.info, ("Ups"), ("This device has no credits"));
                    }
                }
            }).catch((err) => {

                this.notificationsService.add(Severity.info, ("Ups"), ("Error getting inital credits"));

                if (this.entity.vehicle != null) {
                    if (!this.entity.vehicle.registered) {
                        this.creditsVisible = true;
                    }
                }
            });
        }
    }

    /**
     * saveCredits()
     * */
    private saveCredits(res) {
        if (this.credits != null) {
            this.credits.credits = this.currentCredits;
            this.deviceService.saveCreditsDevice(this.credits);
        } else {
            this.createCredits(res);
        }
    }

    /**
     * onChange()
     * */
    onChange(value) {
        this.currentCredits = value;
    }

    createCredits(res) {

        let credit = {
            "id": 0,
            "credits": this.currentCredits,
            "device": res
        }
        this.deviceService.saveCreditsDevice(credit);
    }
    AppTypesChanged(value) {
        if (value == "MOTO") {
            this.creditsVisible = true;
        } else {
            this.creditsVisible = false;
        }
    }
}



