import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-arm-confgiuration",
  templateUrl: "./arm-confgiuration.component.html",
  styleUrls: ["./arm-confgiuration.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class ArmConfgiurationComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() arm: Rest.ARM;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  availableEventActions: SelectItem[];

  ngOnInit() {
    this.availableEventActions = RestExt.getEnumItems(
      RestExt.ARMEventAction,
      this.i18n
    );
  }
}
