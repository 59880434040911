import { Injectable } from '@angular/core';
import { JQueryHTTPClient } from './JQueryHTTPClient';

import { EntityService } from './entity.service';
import { Rest } from './rest_client';
import NoticeGroup = Rest.NoticeGroup;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationsService, Severity } from '../services/notifications-service/notifications.service';

@Injectable()
export class NoticegroupService extends EntityService<any> {
	client: Rest.RestApplicationClient;

	constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private notificationsService: NotificationsService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	create(arg0: any): Rest.RestResponse<Rest.NoticeGroup> {
		return this.client.NoticeGroup_create(arg0);
	}

	update(arg0: any): Rest.RestResponse<Rest.NoticeGroup> {
		return this.client.NoticeGroup_update(arg0);
	}

	deleteMaintenance(ngId: number): Promise<boolean> {
		return this.http
			.delete(environment.webApiBaseUrl + 'api/noticeGroup/maintenance/' + ngId)
			.toPromise()
			.then(() => {
				return true;
			})
			.catch((error) => {
				this.notificationsService.add(Severity.error, 'Error', error.message);
				return false;
			});
	}

	getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.NoticeGroup>> {
		return this.client.NoticeGroup_getPage(arg0);
	}

	getPageAndFilters(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.ListPageAndFilters<Rest.NoticeGroup>> {
		return this.client.NoticeGroup_getPageAndFilters(arg0);
	}

	changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<void> {
		return this.client.NoticeGroup_changeStatus(arg0, queryParams);
	}

	// @ts-ignore
	find(arg0: string, queryParams?: { id?: number }): Rest.RestResponse<Rest.NoticeGroup> {
		return this.client.NoticeGroup_find(arg0, queryParams);
	}

	public getNoticeGroupByDevice(deviceId: number): Promise<NoticeGroup[]> {
		/* HEADERS */
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.jquery.securityToken,
		});

		/* PARAMS */
		const params = new HttpParams().set('deviceId', deviceId);

		return this.http
			.get(environment.webApiBaseUrl + 'api/noticeGroup/device', { headers, params })
			.toPromise()
			.then((response: any) => {
				return response as NoticeGroup[];
			})
			.catch((error) => {
				this.notificationsService.add(Severity.error, 'Error', error.message);
				return [];
			});
	}

	public getNoticeGroupByTagsAndVehicle(vehicleId: number, tags: string[]): Promise<NoticeGroup[]> {
		/* HEADERS */
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.jquery.securityToken,
		});

		/* PARAMS */
		const params = new HttpParams().set('vehicleId', vehicleId);
		const body = tags;

		return this.http
			.post(environment.webApiBaseUrl + 'api/noticeGroup/byVehicleAndTags', body, { headers, params })
			.toPromise()
			.then((response: any) => {
				return response as NoticeGroup[];
			})
			.catch((error) => {
				this.notificationsService.add(Severity.error, 'Error', error.message);
				return [];
			});
	}
}
