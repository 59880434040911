<div class="container p-0 mt-2">

  <!-- ROW 1: SEARCH BOX & COLLAPSE BUTTON & CHANGE STATUS BUTTON -->
  <div class="row">
    <div class="d-flex justify-content-start align-items-center gap-3">

      <input style="width: 100%; height: 30px;" type="text" [(ngModel)]="searchString" (keyup)="search()" i18n-placeholder
             placeholder="{{'general.search' | translate}}">

      <div *ngIf="collapse;then collapsed else noCollapsed" class="left"></div>

      <ng-template #collapsed>
        <button pButton type="text" i18n-pTooltip pTooltip="{{'real-time.filterFleetsModal.expandAll' | translate}}" tooltipPosition="right"
                (click)="expandAll()" icon="fa fa-plus-square"
                style="height:30px; width:30px;text-align:center;vertical-align:middle;  font-size: 13px;"></button>
      </ng-template>

      <ng-template #noCollapsed>
        <button pButton type="text" i18n-pTooltip pTooltip="{{'real-time.filterFleetsModal.collapseAll' | translate}}" tooltipPosition="right"
                (click)="collapseAll()" icon="fa fa-minus-square"
                style="height:30px; width:30px; text-align:center;vertical-align:middle;  font-size: 13px;"></button>
      </ng-template>

      <div *ngIf="visibleChangeStatus && selectableEntities">
        <button pButton type="text" i18n-label label="Change status" (click)="changeStatus()" icon="fa fa-close"
                style="height:30px; width: 150px; text-align:center;vertical-align:middle;  font-size: 13px;"></button>
      </div>

    </div>
  </div>

  <div class="row mt-2">

    <p-tree [value]="fleetsTree" selectionMode="single" [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeExpand)="nodeExpanded($event)">

      <ng-template let-node pTemplate="entity">
        <div *ngIf="node.data.id > 0" class="left">
          <p-checkbox [disabled]="checkBoxDisabled[node.label+'-'+node.data.id]" [id]="node.entityType + node.data.id.toString()" binary="true"
                      (onChange)="nodeCheck($event, node)"
                      [(ngModel)]="optionEntities[node.label+'-'+node.data.id]" [style]="{'vertical-align':'middle', 'width':'30px'}"></p-checkbox>
        </div>

        <div *ngIf="selectableEntities;then selectable else noSelectable" class="left"></div>
        <ng-template #selectable>
          <!-- New -->

          <div *ngIf="node.data.id < 0;then noAbleToSelect else ableToSelect" class="left"></div>

          <ng-template #noAbleToSelect>
            <div *ngIf="node.entityType === 'Fleet';then noVisibleFleet else noVisibleGroup" class="left"></div>
            <ng-template #noVisibleFleet>
              <span i18n-pTooltip pTooltip="Non visible fleet"
                    style="vertical-align:middle;height:21px;text-align:center; font-size: 13px; padding-left:20px;align-items:flex-end;">{{node.label}}</span>
            </ng-template>
            <ng-template #noVisibleGroup>
              <span i18n-pTooltip pTooltip="Non visible group"
                    style="vertical-align:middle;height:21px;text-align:center; font-size: 13px; padding-left:20px;align-items:flex-end;">{{node.label}}</span>
            </ng-template>
          </ng-template>

          <ng-template #ableToSelect>
            <button [ngClass]="classButtons[node.label + '-' + node.data.id]" type="submit" (click)="nodeSelect($event,node)"
                    style="width: 150px; text-align: left">
              {{node.label}}
            </button>
          </ng-template>
        </ng-template>
        <ng-template #noSelectable>
          <span
            style="vertical-align:middle;height:21px; text-align:left; font-size: 13px; padding-left:10px;align-items:flex-end">{{node.label}}</span>
        </ng-template>
      </ng-template>

      <ng-template let-node pTemplate="button">
          <button (click)="addButtonClicked(node)" [disabled]="!node._$create || !showAddButtons"
            style="height:21px; text-align:center;vertical-align:middle;  font-size: 13px; margin-bottom: 5px; margin-top: 5px;"
            type="button" pButton icon="fa fa-plus-circle" label={{node.label}}></button>
      </ng-template>

    </p-tree>

    <div class="d-flex justify-content-start">
      <button class="btn custom-button mt-2" (click)="more()" [disabled]="hasMore===false" i18n>{{'general.loadMore' | translate}}</button>
    </div>

  </div>

</div>
