import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ViewSettingsFormComponent } from '../view-settings-form/view-settings-form.component';
import { Configuration } from '../models/Configuration';
import { IDFilter } from '../models/IDFilter';

import { UteControlPointHistory } from '../ute-control-point-history/ute-control-point-history';
import { UtePanelTableComponent } from '../ute-panel-table/ute-panel-table.component';
import { UteService } from 'app/rest/ute.service';
import { RealTimeDataService } from 'app/services/rt-data/rt-data.service';
import { Rest } from 'app/rest/rest_client';
import { BasicInfo, ControlPoint, PoCsCurrentStatusRow } from '../models/Rows';
import { RestExt } from 'app/services/rest-client-extension';
import { FleetService } from 'app/rest/fleet.service';
import { ZoneService } from 'app/rest/zone.service';
import { RealtimeService } from 'app/rest/realtime.service';
import { ConfigChangesInfo } from '../models/ConfigChangesInfo';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { UTEMapComponent } from '../ute-map/ute-map.component';

import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { LocalStorageinfo } from '../models/LocalStorageInfo';
import { UserService } from 'app/rest/user.service';
import { interval } from 'rxjs';
import { UteVehicleHistory } from '../ute-vehicle-history/ute-vehicle-history';
import { UteSummaryTableComponent } from '../ute-summary-table/ute-summary-table.component';
import { ZonegroupService } from '../../../rest/zonegroup.service';

import { paletterColor } from 'assets/constants/viewsConstants';
import { UteReportEventsComponent } from '../ute-report-events/ute-report-events.component';
import { TranslateService } from '@ngx-translate/core';

//import { paletterColor } from 'assets/constants/viewsConstants';

@Component({
    selector: 'app-ute-panel',
    templateUrl: './ute-panel.component.html',
    styleUrls: ['./ute-panel.component.css'],
    providers: [UteService, FleetService, ZoneService, RealtimeService, ZonegroupService],
    encapsulation: ViewEncapsulation.None,
})
export class UtePanelComponent implements OnInit, OnDestroy {
    @ViewChild('UteControlPointHistory', { static: true }) UteControlPointHistory: UteControlPointHistory;
    @ViewChild('UteVehicleHistory', { static: true }) UteVehicleHistory: UteVehicleHistory;
    @ViewChild('ViewSettingsDisplay', { static: true }) ViewSettingsDisplay: ViewSettingsFormComponent;
    @ViewChild('table', { static: true }) table: UtePanelTableComponent;
    @ViewChild('map', { static: true }) map: UTEMapComponent;
    @ViewChild('UteSummaryTableComponent', { static: true }) summary: UteSummaryTableComponent;
    @ViewChild('UteReportEventsComponent', { static: true }) reportEvents: UteReportEventsComponent;

    uteService: UteService;
    //TODO: add component destruction listener and change alive value to false
    alive: boolean = true;
    sideBarDisplay: boolean = false;
    // Server information
    vehicles: Map<number, string> = new Map<number, string>();
    groups: Map<number, string> = new Map<number, string>();
    cps: Map<number, Rest.Zone> = new Map<number, Rest.Zone>();

    ROWS_UPDATE_FREQ = 10000; // Time between row values update
    ROWS_BLINK_TIMEOUT = 6000; // Time duration of the blink update animation
    selectedConfig: any; // CONFIGS DROPDOWN VALUE
    configOptions: any[] = []; // LIST OF CONFIGURATION(S) RECIVED FROM WS

    // COLOR PALETTE
    whithoutColoring = paletterColor.whithoutColoring;

    vigilanceColors = paletterColor.vigilanceColors;
    MaintenanceColors = paletterColor.MaintenanceColors;

    //CONFIGURATION RECIVED FROM WS WITH ID SELECTED
    configuration: Configuration = {
        id: 0,
        name: 'no config',
        columns: [],
        coloring: null,
        showTable: true,
        showMap: true,
        onlyVisisbleVehicles: false,
        maintenanceContributesToVigilance: false,
        maintenanceTimeLimit: 60 * 24,
        vigilanceTimeLimit: 60 * 24,
    };
    defaultConfig: Configuration = {
        id: 0,
        name: 'no config',
        columns: [],
        coloring: null,
        showTable: true,
        showMap: true,
        onlyVisisbleVehicles: false,
        maintenanceContributesToVigilance: false,
        maintenanceTimeLimit: 60 * 24,
        vigilanceTimeLimit: 60 * 24,
    };

    //LOADED CONFIG VARS
    controlPointActive = false;
    lastMaintenanceDateActive = false;
    maintenanceLeftTimeActive = false;
    lastVigilanceDateActive = false;
    vigilanceLeftTimeActive = false;
    minorTimeRemainingActive = false;
    enterZoneDate = false;
    leaveZoneDate = false;
    durationTimeInZone = false;
    mileageInZone = false;
    vehicleActive = false;
    groupActive = false;
    vehiclesIds: number[] = [];
    nameIds: number[] = [];

    currentUpdatingRows = new Map(); //MAP WHERE WE STORE THE CURRENT ROWS BLINKING
    rows: PoCsCurrentStatusRow[] = []; // ROWS RECIVED FROM WS
    //Efficient structure to query rows by zone id
    rowsMap: Map<Number, PoCsCurrentStatusRow>;
    @Output() rowsWithColor: any[] = []; // ROWS WITH COLOR ASSIGNED
    @Output() centerVehicles = new EventEmitter<number[]>();

    constructor(
        uteService: UteService,
        private rt: RealtimeService,
        private rtDataService: RealTimeDataService,
        private fleetService: FleetService,
        private zoneService: ZoneService,
        private loadingService: LoadingService,
        protected authenticationService: AuthenticationService,
        protected userService: UserService,
        protected translate: TranslateService,
    ) {
        this.uteService = uteService;
    }

    async ngOnInit() {
        this.alive = true;
        //Periodically refresh session to avoid session timeout
        interval(30 * 1000)
            .takeWhile(() => this.alive)
            .subscribe(() => {
                this.authenticationService.updateToken();
            });

        this.loadingService.increaseRESTOngoingRequestCounter();
        try {
            // Load information about zone and vehicle available
            await this.loadEntitiesNames();

            // Load list of configurations and the current one
            await this.loadListConfigurationsWS();

            // When a new event arives,
            this.rtDataService.event.takeWhile(() => this.alive).subscribe((e) => this.updateTableRT(e));
        } catch (ex) {
            console.error(ex);
        }
        this.loadingService.decreaseRESTOngoingRequestCounter();
        /*console.log("befor",this.configuration)
      this.configuration.showMap = false;
      this.configuration.showTable = false;
      console.log("after",this.configuration)*/
        this.map.centerAllVehicles();
    }

    /**
     * Refresh the information actualitzable from the rows every (ROWS_UPDATE_FREQ) seconds
     */
    updateRowsTimers() {
        setInterval(() => {
            if (this.rowsWithColor != null && this.rowsWithColor.length > 0) {
                this.rowsWithColor.forEach((row) => {
                    // Refresh date values
                    this.computeDateColumns(row);

                    // Refresh color
                    row.color = this.assignColorToRow(row);

                    //Refresh child components
                });
                this.notifyTalbeChild();
            }
        }, this.ROWS_UPDATE_FREQ);
    }

    ngOnDestroy() {
        this.alive = false;
    }

    /**
     * Load the configuration if we saved previously it id on localstorage
     */
    loadConfigurationSotored() {
        let configId;

        /** Local storage sectin */
        let localStorageInfo: LocalStorageinfo = JSON.parse(
            localStorage.getItem(String(this.authenticationService.user.id)),
        ) as LocalStorageinfo;
        if (localStorageInfo != null && localStorageInfo.selectedConfig !== undefined) {
            configId = Number(localStorageInfo.selectedConfig);
        } else {
            configId = null;
        }
        //TODO: configId should be removed when user leaves sessions
        //Uncomment that

        if (configId != null) {
            this.selectedConfig = configId;
        } else {
            if (this.configOptions != null && this.configOptions.length > 0) {
                this.selectedConfig = this.configOptions[0].value;
            }
        }
    }

    public newer(l1: number, l2: number): boolean {
        if (l1 == null) return true;
        if (l2 == null) return false;
        return l1 < l2;
    }

    public updateRecord(
        rec: PoCsCurrentStatusRow,
        eventTime: number,
        vigilance: boolean,
        v: BasicInfo,
        g: BasicInfo,
        rol: String,
    ): void {
        let maintenance = rol.startsWith("Maintenance") ? true:false;
        if (rol.startsWith("Vigilance") || rol.startsWith("VigilanceAndMaintenance")) vigilance = true;

        let newest = this.newer(rec.lastMaintenanceDate, eventTime) && this.newer(rec.lastVigilanceDate, eventTime);

        if (vigilance && this.newer(rec.lastVigilanceDate, eventTime)) {
            rec.lastVigilanceDate = eventTime;
        }
        if (maintenance && this.newer(rec.lastMaintenanceDate, eventTime)) {
            rec.lastMaintenanceDate = eventTime;
        }
        if (newest) {
            rec.vehicle = v;
            rec.group = g;
        }
    }

    /**
     * Each time a UTE rondes vehicle enters a tracked zone, a new event with tag "ute_rondes_event" is generated.
     * These events contain the vehicle id, the zone id and the action (vigilance/maintenance)
     */
    updateTableRT(e: Rest.Event) {
        //Only interested in ute_rondes_events
        if (e.tag != 'ute_rondes_event') return;
        let ce = e as Rest.CustomEvent;
        //Event metadata should contain zoneId_[v/m]
        if (!ce.metadata) return;
        //Vehicle is not in the list of filtered vehicles. Ignore row
        if (this.vehiclesIds.indexOf(ce.vehicleId) < 0) return;

        let zoneId = parseInt(ce.metadata.split('_')[0]);
        let vigilancia = 'v' == ce.metadata.split('_')[1];
        //First time seeing this point
        let rec = null;
        let vehicle = {
            id: ce.vehicleId,
            name: this.vehicles.has(ce.vehicleId) ? this.vehicles.get(ce.vehicleId) : '?',
        } as BasicInfo;
        let group = {
            id: ce.vehicleGroupId,
            name: this.groups.has(ce.vehicleGroupId) ? this.groups.get(ce.vehicleGroupId) : '?',
        } as BasicInfo;

        if (!this.rowsMap.has(zoneId)) {
            rec = new PoCsCurrentStatusRow();
            this.updateRecord(rec, ce.deviceTime, vigilancia, vehicle, group, this.configuration.coloring.rol);
            rec.controlPoint = this.cps.has(zoneId)
                ? this.cps.get(zoneId)
                : ({
                    id: 0,
                    name: '?',
                    location: { latitude: 0, longitude: 0 },
                } as ControlPoint);
            this.rowsMap.set(zoneId, rec);
        } else {
            //Not the first event for this zone
            rec = this.rowsMap.get(zoneId);
            this.updateRecord(rec, ce.deviceTime, vigilancia, vehicle, group, this.configuration.coloring.rol);
        }
        this.computeDateColumns(rec);
        this.manageNewRow(rec);
    }

    /**
     * Update columns with dependence of currently time
     * @param row Row to update
     */
    computeDateColumns(row: any) {
        let maintenancePeriod = 1000 * 60 * 60 * this.configuration.maintenanceTimeLimit;
        let vigilancePeriod = 1000 * 60 * 60 * this.configuration.vigilanceTimeLimit;

        // Calcule maintenanceLeftTime and vigilanceLeftTime columns
        if (row.lastMaintenanceDate != null) {
            row.maintenanceLeftTime = maintenancePeriod - (new Date().getTime() - row.lastMaintenanceDate);
        }

        if (row.lastVigilanceDate != null) {
            row.vigilanceLeftTime = vigilancePeriod - (new Date().getTime() - row.lastVigilanceDate);
        }

        // TODO: DELETE console.log("rec.maintenanceLeftTime: "+rec.maintenanceLeftTime+" ,rec.vigilanceLeftTime: "+rec.vigilanceLeftTime+" ,.min: "+Math.min(rec.maintenanceLeftTime, rec.vigilanceLeftTime));
        // Calcule minorTimeRemaining column
        if (row.maintenanceLeftTime != null && row.vigilanceLeftTime != null) {
            row.minorTimeRemaining = Math.min(row.maintenanceLeftTime, row.vigilanceLeftTime);
        }
        if (row.maintenanceLeftTime == null) {
            row.minorTimeRemaining = row.vigilanceLeftTime;
        }
        if (row.vigilanceLeftTime == null) {
            row.minorTimeRemaining = row.maintenanceLeftTime;
        }
    }

    /**
     * Manage what we must do with the new row recived
     * @param rec row recived
     */
    manageNewRow(rec: PoCsCurrentStatusRow) {
        /**
         *
         *
         //let existingIndex: number = -1;
         let existingIndex1: boolean = false;
         // ROWS ARRAY

         // Find the index related to the controlPoint
         // -Old- existingIndex = this.rows.findIndex(row => row.controlPoint === rec.controlPoint );
         existingIndex1 = this.rowsMap.has(rec.controlPoint.id)
         if(existingIndex1 === false){ // If it doesn't exist
      //this.rows.push(rec); // we add it
      this.rowsMap.set(rec.controlPoint.id, rec);
    }else{
      //this.rows[existingIndex] = rec; // we substitute it
      this.rowsMap.set(rec.controlPoint.id, rec);
    }

         */
            // ROWSWITHCOLOR ARRAY

            //existingIndex = -1;
        let existingIndex: number = -1;
        // Find the index related to the controlPoint
        existingIndex = this.rowsWithColor.findIndex((row) => row.controlPoint === rec.controlPoint);
        if (existingIndex === -1 && existingIndex != null) {
            // If it doesn't exist

            this.addRowToRowsWithColors(rec); // If it is valid will be added
            this.blinkRow(rec.controlPoint); // If it is valid will add blink animation to the row
        } else {
            if (this.isValid(rec)) {
                rec.color = this.assignColorToRow(rec); // We assign a new color because we recive new parameters
                this.rowsWithColor[existingIndex] = rec; //Substitute into rowWithColor array(this one is used by the table)
                this.blinkRow(rec.controlPoint); // We add blink to this row
            } else {
                this.rowsWithColor.splice(existingIndex, 1); // If its not valid we delete it from the current visualized
            }
        }
        this.rowsWithColor = [...this.rowsWithColor];
    }

    /**
     * Make the row related to the controlPoint id recived blink on the table
     * @param controlPointId
     */
    blinkRow(controlPointId) {
        // Readme: Color atribute are css classes

        //We search for the row
        const row = this.rowsWithColor.find((x) => x.controlPoint === controlPointId);

        //If it exist
        if (row !== undefined && row != null) {
            // Add blink to the color, that will apply the class blink to it
            row.color = row.color + ' blink';

            // Befor start the clean up of the class blink we check if it was already started
            if (!this.currentUpdatingRows.has(row.controlPoint)) {
                // We add to update list the control point id
                this.currentUpdatingRows.set(row.controlPoint, row.controlPoint);

                // We start the countDown to remove the blink property
                window.setTimeout(() => {
                    // Remove blink from color atribute
                    row.color.replace('blink', '');
                    // When blink is over we delete the control point id from update map
                    this.currentUpdatingRows.delete(row.controlPoint);
                }, this.ROWS_BLINK_TIMEOUT);
            }
        }
    }

    /**
     * Route that load the list of configurations
     */
    async loadListConfigurationsWS() {
        this.configOptions = [];
        //Get configurations from WS
        const responce = await this.uteService.getListOfConfigurations();
        //Load configurations into dropdown options
        responce.forEach((config) => {
            this.configOptions.push({ label: config.name, value: config.id });
        });
        // If no configuration is selected try to load the localstore one
        if (this.selectedConfig == null) {
            this.loadConfigurationSotored(); // Load id of last configuration used

            await this.loadConfigurationWS(); // Get configuration from WS
        }
        this.triggerChangeDropDownSelection();
    }

    private triggerChangeDropDownSelection() {
        this.configOptions = [...this.configOptions];
    }

    /**
     * If some of the configuration change we update those changes into the panel
     */
    async configurationsChanged(config: ConfigChangesInfo) {
        /**close the sideMenu */
        this.sideBarDisplay = false;
        // We update the list of configurations
        await this.loadListConfigurationsWS();

        // If the action that call the changes method is not a delete we load the changed configuration
        if (!config.deleted) {
            this.selectedConfig = config.id;
        } else {
            // Otherwise if the change was a delete

            // We Check if the configuration deleted was the one running and substitute it by the first one on dropdown list
            if (
                this.selectedConfig === config.id &&
                this.configOptions[0] != null &&
                this.configOptions[0] !== undefined &&
                this.configOptions[0].value != null &&
                this.configOptions[0].value !== undefined
            ) {
                this.selectedConfig = this.configOptions[0].value;
            } else {
                this.selectedConfig = null;
            }
        }

        // Get the configuration from WS
        await this.loadConfigurationWS();
    }

    /**
     * Obtain the current status of the Points Of Control from the server
     */
    async getPoCsCurrentStatus() {
        this.loadingService.increaseRESTOngoingRequestCounter();
        // -OLD- this.rows = await this.uteService.getPoCsCurrentStatus();
        let rows = await this.uteService.getPoCsCurrentStatus(this.configuration?.coloring?.rol, this.vehiclesIds);
        this.loadingService.decreaseRESTOngoingRequestCounter();
        this.rowsMap = new Map<Number, PoCsCurrentStatusRow>();
        // -OLD- this.rows.forEach((r) => { this.rowsMap.set(r.controlPoint.id, r) })
        if(rows){
            rows.forEach((r) => {
                this.rowsMap.set(r.controlPoint.id, r);
            });
        }

        

    }

    /**
     * construct dictionaries with the names of the vehicles/groups/zones visibles by the user.
     * These dictionaries are used to engance the information contained in the events recieved in real time
     *
     * load all the fllets visible by the user and fill vehicles and groups dictionaries
     * load all the zones visible by the user and fill the zones dictionary
     */
    async loadEntitiesNames() {
        this.vehicles = new Map<number, string>();
        this.groups = new Map<number, string>();
        this.cps = new Map<number, Rest.Zone>();

        const fleets = (await this.fleetService.getTree({} as Rest.ListPaginationRequest)).entities;

        fleets.forEach((fleet) => {
            fleet.groups.forEach((group) => {
                this.groups.set(group.id, group.name);
                group.vehicles.forEach((vehicle) => {
                    this.vehicles.set(vehicle.id, vehicle.name);
                });
            });
        });

        // List of visible zone groups and zones
        const zonesList = (await this.zoneService.getPage({} as Rest.ListPaginationRequest)).entities;

        zonesList.forEach((zone) => {
            this.cps.set(zone.id, zone);
        });
    }

    // when selectedConfig changes
    async loadConfigurationWS() {
        // Remove the current one
        if (this.selectedConfig) {
            try {
                // Try to load the selected one
                this.configuration = await this.uteService.getConfiguration(this.selectedConfig);

                // We save config id as last loaded

                /** Local storage section */
                let localStorageInfo: LocalStorageinfo = JSON.parse(
                    localStorage.getItem(String(this.authenticationService.user.id)),
                ) as LocalStorageinfo;

                if (localStorageInfo == null || localStorageInfo.selectedConfig === undefined) {
                    localStorageInfo = new LocalStorageinfo();
                }

                localStorageInfo.selectedConfig = this.selectedConfig;
                localStorage.setItem(String(this.authenticationService.user.id), JSON.stringify(localStorageInfo));
            } catch (error) {
                console.log(error);
                this.configuration = JSON.parse(JSON.stringify(this.defaultConfig));
            }
        }
        //If no config has been selected or retrieving the config failed. Use default one
        if (!this.selectedConfig || !this.configuration) {
            this.configuration = JSON.parse(JSON.stringify(this.defaultConfig));
        }

        // Set up configuration selected
        await this.setUpConfiguration();

        // Query rows
        await this.getPoCsCurrentStatus();

        // Filter the rows
        this.filterTableRows();

        // Update the table values like 'left time' and color
        this.updateRowsTimers();

        //Subscribe to the new list of vehicles as per the filter spec
        const vehicles = await this.rt.getVehicleInfo(Array.from(this.vehiclesIds));
        this.rtDataService.watchedVehicles.next(vehicles as RestExt.WatchedVehicle[]);
    }

    /**
     * Notify child component  about changes on the configuration selected
     */
    notifyChildsAboutConfigUpdate() {
        // Refresh some o atributes need to change the table headers

        if (this.configuration) {
            this.notifyTalbeChild();
            if (this.map) {
                this.map.refreshMap();
            }
        }

        // TODO: Notify map component
    }

    notifyTalbeChild() {
        if (this.table) {
            this.table.loadHeaders(this.configuration);
        }
    }

    // Open settings dialog
    openViewPopUp() {
        this.ViewSettingsDisplay.show();
    }

    /**
     * After recive a new configuration we need to parse all the information that it have
     */
    async setUpConfiguration() {
        // Reset filter to apply
        this.controlPointActive = false;
        this.lastMaintenanceDateActive = false;
        this.maintenanceLeftTimeActive = false;
        this.lastVigilanceDateActive = false;
        this.vigilanceLeftTimeActive = false;
        this.minorTimeRemainingActive = false;
        this.enterZoneDate = false;
        this.leaveZoneDate = false;
        this.durationTimeInZone = false;
        this.mileageInZone = false;

        this.vehicleActive = false;
        this.groupActive = false;

        this.vehiclesIds = [];
        this.nameIds = [];

        if (this.configuration != null) {
            // Active the filters
            this.configuration.columns.forEach((configColum) => {
                if (configColum.filter !== undefined) {
                    if (configColum.id === 'controlPoint' && configColum.filter.active) {
                        this.controlPointActive = true;
                    } else if (configColum.id === 'lastMaintenanceDate' && configColum.filter.active) {
                        this.lastMaintenanceDateActive = true;
                    } else if (configColum.id === 'maintenanceLeftTime' && configColum.filter.active) {
                        this.maintenanceLeftTimeActive = true;
                    } else if (configColum.id === 'lastVigilanceDate' && configColum.filter.active) {
                        this.lastVigilanceDateActive = true;
                    } else if (configColum.id === 'vigilanceLeftTime' && configColum.filter.active) {
                        this.vigilanceLeftTimeActive = true;
                    } else if (configColum.id === 'minorTimeRemaining' && configColum.filter.active) {
                        this.minorTimeRemainingActive = true;
                    } else if (configColum.id === 'enterZoneDate' && configColum.filter.active) {
                        this.enterZoneDate = true;
                    }else if (configColum.id === 'leaveZoneDate' && configColum.filter.active) {
                        this.leaveZoneDate = true;
                    }else if(configColum.id === 'durationTimeInZone' && configColum.filter.active){
                        this.durationTimeInZone = true;
                    }else if (configColum.id === 'mileageInZone' && configColum.filter.active) {
                        this.mileageInZone = true;                    
                    } else if (configColum.id === 'vehicle' && configColum.filter.active) {
                        this.vehicleActive = true;
                        this.groupActive = true; // We set this to true inside vehicle becuase we join columns in config seccion
                    } else if (configColum.id === 'group' && configColum.filter.active) {
                        this.groupActive = true;
                    }
                }
            });

            // Load the vehicles and names valids
            await this.getVehicleAndGroupIds(this.vehicleActive, this.groupActive, this.vehiclesIds);
            this.getFilteredControlPointIds(this.controlPointActive, this.nameIds);
        }

        this.notifyChildsAboutConfigUpdate();
    }

    /**
     * This one will add the rows that match the filter
     */
    filterTableRows() {
        // If no configuration is load we load a default one
        if (this.configuration == null) {
            this.noConfigurationSetup();
        } else {
            this.rowsWithColor = []; // this ones will substitute the rows

            this.rowsMap.forEach((value: any, key: number) => {
                this.addRowToRowsWithColors(value);
            });
        }
    }

    /**
     * Fill the rowsWithColors with the rows
     * @param row Current row
     */
    private addRowToRowsWithColors(row: PoCsCurrentStatusRow) {
        // If row pas allthe filters we push them else we discard them
        if (this.isValid(row)) {
            // If its not created alreade we add and atribute color
            row.color = '';
            this.rowsWithColor.push(row);
            // We assign and color relative to the configuration and row
            this.rowsWithColor[this.rowsWithColor.length - 1].color = this.assignColorToRow(row);
        }
    }

    /**
     * Check if the row maches all the currently filters
     * @param row Current row
     */
    private isValid(row: PoCsCurrentStatusRow) {
        let valid = true;

        // For each of the filter if is active check if the row is valid, if not discard row
        if (this.controlPointActive) {
            if (!this.controlPointFilter(row, this.nameIds)) {
                valid = false;
            }
        }
        if (this.lastMaintenanceDateActive && valid) {
            if (!this.lastMaintenanceDateFilter(row)) {
                valid = false;
            }
        }
        if (this.maintenanceLeftTimeActive && valid) {
            if (!this.maintenanceLeftTimeFilter(row)) {
                valid = false;
            }
        }
        if (this.lastVigilanceDateActive && valid) {
            if (!this.lastVigilanceDateFilter(row)) {
                valid = false;
            }
        }
        if (this.vigilanceLeftTimeActive && valid) {
            if (!this.vigilanceLeftTimeFilter(row)) {
                valid = false;
            }
        }
        if (this.minorTimeRemainingActive && valid) {
            if (!this.minorTimeRemainingFilter(row)) {
                valid = false;
            }
        }

        if(this.enterZoneDate && valid){
            if(!this.enterZoneDateFilter(row)){
                valid = false;
            }
        }

        if(this.leaveZoneDate && valid){
            if(!this.leaveZoneDateFilter(row)){
                valid = false;
            }
        }

        if(this.durationTimeInZone && valid){
            if(!this.durationTimeInZoneFilter(row)){
                valid = false;
            }
        }

        if(this.mileageInZone && valid){
            if(!this.mileageInZoneFilter(row)){
                valid = false;
            }
        }

        if (this.vehicleActive && valid) {
            if (!this.vehicleFilter(row, this.vehiclesIds)) {
                valid = false;
            }
        }
        return valid;
    }

    /**
     * Fill the rowsWithColors with the rows IF THE CONFIGURATION SELECTED IS NOT VALID OR NULL
     */
    noConfigurationSetup() {
        this.rowsWithColor = []; // this ones will substitute the rows

        this.rowsMap.forEach((value: any, key: number) => {
            // -Old- this.rows.forEach((row) => {
            value.color = 'true';
            this.rowsWithColor.push(value);
            // We assign and color relative to the configuration and row
            this.rowsWithColor[this.rowsWithColor.length - 1].color = this.assignColorToRow(value);
        });
    }

    private async getFilteredControlPointIds(controlPointActive: boolean, controlPointIds: number[]) {
        if (controlPointActive) {
            if ((this.configuration.columns.find((col) => col.id === 'controlPoint').filter as IDFilter).ids == null) {
                return;
            } //HOLD IF NULL

            // ROBERT e afegit aixo, o vas treure per alguna rao?
            else {
                (this.configuration.columns.find((col) => col.id === 'controlPoint').filter as IDFilter).ids.forEach((element) => {
                    controlPointIds.push(element.id);
                });
            }
            // ---------------

            let cpGroupIds = [];
            (this.configuration.columns.find((col) => col.id === 'controlPoint').filter as IDFilter).ids.forEach((zone) => {
                //TODO: implement
                //(await this.zone.find(zone.id)).
                // cpGroupIds.push(zone.id);
            });
        }
    }

    //Click a poc in the map
    PoCClicked(poc) {
        this.UteControlPointHistory.show(poc.name, poc.id);
    }

    //Click a row in the map
    controlPointClicked(row) {
        this.UteControlPointHistory.show(row.controlPoint.name, row.controlPoint.id);
        //this.UteVehicleHistory.show(row.vehicle.name,row.vehicle.id);
    }

    vehicleClickedInTable(row) {
        this.UteVehicleHistory.show(row.vehicle.name, row.vehicle.id);
    }

    focusOnControlPointClicked(cpId) {
        this.map.centerOnPointOfControl(cpId);
    }

    vehicleClicked(vdm: RestExt.ExtendedVehicleDataMessage) {
        this.UteVehicleHistory.show(vdm.vehicleName, vdm.vehicleId);
    }

    /**
     * Parse all the list of vehciles and group to filter for
     * @param vehicleActive Filter is active
     * @param groupActive Filter is active
     * @param vehiclesIds List of active vehicles
     */
    private async getVehicleAndGroupIds(vehicleActive: boolean, groupActive: boolean, vehiclesIds: number[]) {
        //Selected configuration might contain vehicles no longer visible to the user. Remove them
        let fleets = (
            await this.fleetService.getTree({
                pageSize: 2147483647,
                filters: {},
                loadPermissions: false,
                offset: 0,
                sortAsc: true,
                sortBy: 'id',
            } as any)
        ).entities.filter((f) => f.enabled);
        let groups = fleets.reduce((acc, val) => acc.concat(val.groups), []).filter((g) => g.enabled);
        let vehicles = groups.reduce((acc, val) => acc.concat(val.vehicles), []).filter((v) => v.enabled);
        if (vehicleActive || groupActive) {
            // Since all both fleets vehicles and groups are storets in vehicles column
            (this.configuration.columns.find((col) => col.id === 'vehicle').filter as IDFilter).ids.forEach((FleetGroupVehicle) => {
                // If current item contains 'groups' we know its a fleet and we parse it to groups and vehicles
                if (FleetGroupVehicle.hasOwnProperty('groups')) {
                    let fleet = fleets.find((f) => f.id == FleetGroupVehicle.id);
                    if (fleet) {
                        fleet.groups
                            .filter((group) => group.enabled)
                            .forEach((group) => {
                                group.vehicles
                                    .filter((vehicle) => vehicle.enabled)
                                    .forEach((vehicle) => {
                                        vehiclesIds.push(vehicle.id);
                                    });
                            });
                    }

                    // If current item contains 'vehicles' we know its a group and we push it to group and we parse the vehicles on it
                } else if (FleetGroupVehicle.hasOwnProperty('vehicles')) {
                    let group = groups.find((g) => g.id == FleetGroupVehicle.id);
                    if (group) {
                        group.vehicles
                            .filter((vehicle) => vehicle.enabled)
                            .forEach((vehicle) => {
                                vehiclesIds.push(vehicle.id);
                            });
                    }
                }
                // If current item contains 'group' we know its a vehicle and we push it to vehicles
                else if (FleetGroupVehicle.hasOwnProperty('group')) {
                    /**we need to add the group related to the allowed ones */
                    vehiclesIds.push(FleetGroupVehicle.id);
                }
            });
        }

        let finalVehicles = vehiclesIds.filter((vid) => vehicles.find((v) => v.id == vid));
        vehiclesIds.splice(0, vehiclesIds.length);
        finalVehicles.forEach((v) => vehiclesIds.push(v));
    }

    /**
     * Assign colors to the rows depending on configuration selected Españoles, ya esta bien de salterse las leyes que e
     */
    assignColorToRow(row: any): string {
        let isBlinking = false;

        if (row.color.includes('blink')) {
            isBlinking = true;
        }

        if (this.configuration == null || this.configuration === undefined) {
            return '';
        }
        // WITHOUT COLORING
        if (!this.configuration.coloring) {
            return isBlinking ? 'blink' : '';
            //24h limit coloring
        }else{
            var timeDifferenceInHours = 0;

            if(this.configuration.coloring.rol === "Maintenance"){
                timeDifferenceInHours = (Date.now() - row.lastMaintenanceDate)  / (60 * 60 * 1000);
            }else{
                timeDifferenceInHours = (Date.now() - row.lastVigilanceDate) / (60 * 60 * 1000);
            }

            if(this.configuration.coloring.info !== undefined && this.configuration.coloring.info !== null){
                for (const condition of this.configuration.coloring.info) {
                    const operator = condition.condition.substr(0, 1); // Get the first character ("<" or ">")
                    const value = parseInt(condition.condition.substr(1)); // Get the numeric value
                    
                    if (operator === "<" && timeDifferenceInHours < value) {
                        return condition.value as string;
                        //break; // Exit the loop if a match is found
                    } else if (operator === ">" && timeDifferenceInHours > value) {
                        return condition.value as string;
                        //break; // Exit the loop if a match is found
                    }
                }
            }

        }

        return 'Color not properly set';
    }

    controlPointFilter(row: any, nameId: any[]): boolean {
        let zone: any = this.cps.get(row.controlPoint.id);

        if (
            zone !== undefined &&
            zone != null &&
            zone.zoneGroup !== undefined &&
            zone.zoneGroup != null &&
            zone.zoneGroup.id !== undefined &&
            zone.zoneGroup.id != null
        ) {
            return nameId.includes(zone.zoneGroup.id);
        } else {
            return false;
        }
    }

    lastMaintenanceDateFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'lastMaintenanceDate').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.lastMaintenanceDate < Date.now() - filter.time : row.lastMaintenanceDate > Date.now() - filter.time;
    }

    maintenanceLeftTimeFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'maintenanceLeftTime').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.maintenanceLeftTime > filter.time : row.maintenanceLeftTime < filter.time;
    }

    lastVigilanceDateFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'lastVigilanceDate').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.lastVigilanceDate < Date.now() - filter.time : row.lastVigilanceDate > Date.now() - filter.time;
    }

    minorTimeRemainingFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'minorTimeRemaining').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.minorTimeRemaining > filter.time : row.minorTimeRemaining < filter.time;
    }

    vigilanceLeftTimeFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'vigilanceLeftTime').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.vigilanceLeftTime > filter.time : row.vigilanceLeftTime < filter.time;
    }

    enterZoneDateFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'enterZoneDateFilter').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.enterZoneDateFilter < Date.now() - filter.time : row.enterZoneDateFilter > Date.now() - filter.time;
    }

    leaveZoneDateFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'leaveZoneDateFilter').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.leaveZoneDateFilter < Date.now() - filter.time : row.leaveZoneDateFilter > Date.now() - filter.time;
    }

    durationTimeInZoneFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'durationTimeInZoneFilter').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.durationTimeInZoneFilter < Date.now() - filter.time : row.durationTimeInZoneFilter > Date.now() - filter.time;
    }

    mileageInZoneFilter(row: any): boolean {
        const filter: any = this.configuration.columns.find((conf) => conf.id === 'mileageInZoneFilter').filter;

        if (filter.time == null) {
            return true;
        } // If the filter is active and the time value is null that means it was not properly set

        return filter.BiggerThan ? row.mileageInZoneFilter < Date.now() - filter.time : row.mileageInZoneFilter > Date.now() - filter.time;
    }

    vehicleFilter(row: any, vehicleId: number[]): boolean {
        return vehicleId.includes(row.vehicle.id);
    }

    groupTimeFilter(row: any, groupsId: number[]): boolean {
        return groupsId.includes(row.group.id);
    }

    openSummaryPopUp() {
        this.summary.show();
    }

    openEventsByDatePopUp() {
        this.reportEvents.show();
    }

    followVehicle(vehiclesId) {
        //  this.rtDataService.focusMap(vehiclesId);
        // this.map.centerMapAtPoint(41.38879, 2.15899,12);
        this.rtDataService.focusMap(vehiclesId);
    }

    /*
   private _dataTable: any;
   private rowCount: Number;
   private pageNoSize: any;

   exportCSV(dataTable) {
           this.rowCount = 50;
           this.pageNoSize = 'page=0&size=' + this.rowCount;
           this._dataTable = dataTable;
           this.getJSONData();
       }


   getJSONData() {
       this.getJSONDataService.get(uri + this.pageNoSize)
           .subscribe(
           data => {

                   this._dataTable.value = data;
                   this._dataTable.exportCSV();
                   this.rowCount = 10;

           },
           error => {
           },
       );
   }*/

    exportExcel() {
        this.table.exportExcel(this.translate.instant('general.table'));
    }

    centerAllVehicles() {
        this.map.centerAllVehicles();
    }

    showEventsByDate() {
    }

    
}

export interface MapMessage {
    tag: string;
    payload: any;
}
