import {Component, ElementRef, Input, OnInit, ViewChild,} from "@angular/core";

import {Rest} from "../../../../../rest/rest_client";
import {RealtimeService} from "../../../../../rest/realtime.service";
import {DeviceService} from "../../../../../rest/device.service";
import {I18nService} from "../../../../../services/i18n/i18n.service";
import {NotificationsService, Severity,} from "../../../../../services/notifications-service/notifications.service";
import {RestExt} from "../../../../../services/rest-client-extension";
import {RealTimeDataService} from "../../../../../services/rt-data/rt-data.service";
import {RequestResponseListener} from "../../../request-response-listener/request-response-listener.component";

@Component({
  selector: "app-io",
  templateUrl: "./io.component.html",
  styleUrls: ["./io.component.css"],
  providers: [RealtimeService, DeviceService],
})
export class IOComponent extends RequestResponseListener implements OnInit {
  @ViewChild('ioModal') ioModal: ElementRef;
  @ViewChild('closeIOModalButton') closeIOModalButton: ElementRef;
  @Input() vehicle: Rest.Vehicle;
  info: RestExt.ExtendedVehicleDataMessage;

  newAnalogActuatorValue: number = 0;
  newDigitalActuatorValue: boolean = false;
  toggle: boolean = false;
  toggletimes: number = 0;
  toggleInterval: number = 0;
  edditedActuatorValue: RestExt.ExtendedDValue = <RestExt.ExtendedDValue>{
    device: {},
  };

  actuatorFormVisible = false;

  constructor(
    protected i18n: I18nService,
    rtService: RealtimeService,
    realTimeDataService: RealTimeDataService,
    notificationService: NotificationsService,
    deviceService: DeviceService
  ) {
    super(i18n, realTimeDataService, notificationService, deviceService);
  }

  ngOnInit() {
    this.setup();

    //Subscrive to changes
    this.realTimeDataService.vehicleInfo
      .takeWhile(() => this.alive)
      .subscribe((vi) => {
        if (this.vehicle == null || vi.vehicleId != this.vehicle.id) return;
        this.info = vi;
      });

    //Get the current value
    if (this.vehicle != null)
      this.info = this.realTimeDataService.vehicleInfoArr.filter(
        (v) => v.vehicleId == this.vehicle.id
      )[0];
    if (!this.info) this.info = {} as any;
  }

  editActuatorValue(actuatorValue: RestExt.ExtendedDValue) {
    this.edditedActuatorValue = actuatorValue;
    this.actuatorFormVisible = true;
  }

  setNewActuatorValue() {
    //Construct the request object
    let req = <Rest.ActuatorChangeRequestMessage>{};
    if (this.edditedActuatorValue.device.type == "ANALOG") {
      req = <Rest.DigitalActuatorChangeRequest>{
        digital: true,
        id: null,
        vehicleId: this.info.vehicleId,
        platformTime: null,
        requestType: "SET_ACTUATOR_VALUE",
        tag: "",
        actuatorId: this.edditedActuatorValue.device.id,
        requestedValue: this.newDigitalActuatorValue,
        toggleInterval: this.toggleInterval,
        toggletimes: this.toggletimes,
      };
    } else {
      req = <Rest.AnalogActuatorChangeRequest>{
        digital: false,
        id: null,
        vehicleId: this.info.vehicleId,
        requestType: "SET_ACTUATOR_VALUE",
        platformTime: null,
        tag: "",
        actuatorId: this.edditedActuatorValue.device.id,
        requestedValue: this.newAnalogActuatorValue,
      };
      this.sendRequest(req, this.info.vehicleId)
        .then((responsePromise: any) => {
          this.notificationService.add(
            Severity.success,
            this.i18n._(":)"),
            this.i18n._("Actuator change request quued.")
          );
          responsePromise[0].then((response: Rest.ResponseMessage) => {
            //Handle the message
            switch (response.status) {
              case "ACK":
                this.notificationService.add(
                  Severity.success,
                  this.i18n._(":)"),
                  this.i18n._("Actuator change request acknowledged by device!")
                );
                break;
              case "NACK":
                this.notificationService.add(
                  Severity.error,
                  this.i18n._(":("),
                  this.i18n._(
                    "Actuator change request not acknowledged by device!"
                  )
                );
                break;
              default:
                this.notificationService.add(
                  Severity.warn,
                  this.i18n._(":|"),
                  this.i18n._(
                    "There was a problem sending the request to the device"
                  )
                );
                break;
            }
          });
        })
        .catch((e) => {
          this.notificationService.add(
            Severity.error,
            this.i18n._(":("),
            this.i18n._("Actuator change request not queued")
          );
        });
    }

    this.newAnalogActuatorValue = 0;
    this.newDigitalActuatorValue = false;
    this.toggletimes = 0;
    this.toggleInterval = 0;
    this.toggle = false;
    //this.editedActuatorValue =  <Rest.ActuatorValue>{actuator:{}};
    this.actuatorFormVisible = false;
  }

  discardNewActuatorvalue() {
    this.newAnalogActuatorValue = 0;
    this.newDigitalActuatorValue = false;
    this.toggletimes = 0;
    this.toggleInterval = 0;
    this.toggle = false;
    //this.editedActuatorValue =  <Rest.ActuatorValue>{actuator:{}};
    this.actuatorFormVisible = false;
  }

  closeIOModal(): void {

  }
}
