import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "./JQueryHTTPClient";

import { EntityService } from "./entity.service";
import { Rest } from "./rest_client";

@Injectable()
export class ConversionalgorithmService extends EntityService<Rest.ConversionAlgorithm> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(
    arg0: Rest.ConversionAlgorithm
  ): Rest.RestResponse<Rest.ConversionAlgorithm> {
    return this.client.ConversionAlgorithm_create(arg0);
  }

  delete(arg0: Rest.ConversionAlgorithm[]): Rest.RestResponse<void> {
    return this.client.ConversionAlgorithm_delete(arg0);
  }

  update(
    arg0: Rest.ConversionAlgorithm
  ): Rest.RestResponse<Rest.ConversionAlgorithm> {
    return this.client.ConversionAlgorithm_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.Page<Rest.ConversionAlgorithm>> {
    return this.client.ConversionAlgorithm_getPage(arg0);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.ConversionAlgorithm> {
    return this.client.ConversionAlgorithm_find(arg0, queryParams);
  }
}
