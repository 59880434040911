<div *ngIf="!disabled">
    <input pInputText type="text" [ngModel]="searchText" (ngModelChange)="search($event)" [style]="{'width':'100%','min-width':'100px'}"/>
</div>
<div *ngIf="disabled" style="display: inline-block;" pTooltip={{tooltip}}>
    <input pInputText [attr.disabled]="disabled" type="text" value="" [style]="{ width: '200px' }"/>
</div>

<!-- TODO: Fix incompatibility check between role and profile.
If the role is selected before the profile and they are incompatible, the API call will fail-->

<p-table *ngIf="showResults && (disabled === false)"
         [value]="availableItems"
         [autoLayout]="true"
         [rowHover]="true"


         >
  <ng-template pTemplate="body" let-item>
    <tr (click)=itemSelected(item) style="cursor:pointer;">
      {{item.label}}
    </tr>
  </ng-template>
</p-table>

<!--<table *ngIf="showResults && (disabled === false)" class="ui-datatable">
    <tbody class="ui-datatable-data ui-widget-content ui-datatable-hoverable-rows">
        <tr class="ui-widget-content" on-mouseenter="hovering=true" on-mouseleave="hovering=false" *ngFor="let item of availableItems; let even = even; let odd = odd;let hover = false"
            (click)=itemSelected(item) [ngClass]="{'ui-state-highlight':hover,'ui-datatable-even':even,'ui-datatable-odd':odd,'selected':(selectedItem && item.value===selectedItem.value)}">

            &lt;!&ndash;<th class="ui-cell-data">{{item.label}}</th>&ndash;&gt;
          <td >{{item.label}}</td>
        </tr>
        <tr class="ui-datatable-data ui-widget-content ui-datatable-hoverable-rows load-more" *ngIf="hasMore" (click)=loadMore()>...</tr>
    </tbody>
</table>-->
