import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {
   public requestCounter:number = 0;
   public requestCounterSubject: Subject<number> = new Subject<number>();
  
  public increaseRESTOngoingRequestCounter(): void {   
    this.requestCounterSubject.next(++this.requestCounter);
  }

  
  public decreaseRESTOngoingRequestCounter(): void {   
    if(this.requestCounter==0){
      console.log("Trying to decrease counter below 0");
      return;
    }
    this.requestCounterSubject.next(--this.requestCounter);
  }

}