<div>
    <!-- <button pButton type="text" label="Expand all" (click)="expandAll()"></button>
    <button pButton type="text" label="Collapse all" (click)="collapseAll()"></button> -->
    <div *ngIf="collapse;then collapsed else noCollapsed" class="left"></div>
    <ng-template #collapsed>
        <button pButton type="text" i18n-pTooltip pTooltip="Expand All" tooltipPosition="right" (click)="expandAll()"
                icon="fa fa-plus-square"
                style="height:21px; text-align:center;vertical-align:middle;  font-size: 13px; margin-bottom: 5px; margin-top: 5px;"></button>
    </ng-template>
    <ng-template #noCollapsed>
        <button pButton type="text" i18n-pTooltip pTooltip="Collapse All" tooltipPosition="right"
                (click)="collapseAll()" icon="fa fa-minus-square"
                style="height:21px; text-align:center;vertical-align:middle;  font-size: 13px; margin-bottom: 5px; margin-top: 5px;"></button>
    </ng-template>
</div>

<div>
    <p-tree [value]="deviceTypeTree" selectionMode="single" (onNodeSelect)="nodeSelect($event)">
        <ng-template let-node pTemplate="button">
            <button style="height:21px; text-align:center;vertical-align:middle;  font-size: 14px;" type="button"
                    pButton icon="fa fa-plus-circle" label={{node.label}}></button>
        </ng-template>
    </p-tree>
</div>
