import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService } from 'primeng/api';
import { RoutesService } from '../../fid-shared/service/routes.service';
import { RestExt } from 'app/services/rest-client-extension';
import { Subscription } from 'rxjs';
import { RoutesFormComponent } from '../routes-form/routes-form.component';
import { devOnlyGuardedExpression } from '@angular/compiler';
import { CampaignService } from 'app/modules/fid-shared/service/campaign.service';

@Component({
  selector: 'app-routes-list-form',
  templateUrl: './routes-list-form.component.html',
  styleUrls: ['./routes-list-form.component.css'],
  providers: [
	ConfirmationService
  ]
})
export class RoutesListFormComponent extends EntityListComponent<Rest.FidRoute> implements OnInit, OnDestroy {

	@Input() space: Rest.FidSpace;
	@Input() route: Rest.FidRoute;
	selectedRoute: Rest.FidRoute

	paginationRequest: Rest.ListPaginationRequest;
	paginationResult: Rest.Page<Rest.FidRoute>;
	filters: { [index: string]: Rest.TableFilterSelectItem[] };
	loadFilters: boolean; //Flag indicating that associated entity filters must be loaded at next request
	hasEntityFilters = false; //Flag indicating that the entity list can be filtered by associated entities. If true, requires that the REST service used has the method getPageAndFilters

	entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
	selectedAll: boolean = false;
	disableSelectAll: boolean = true;
	user: Rest.User;
	showDeleteDialog: boolean = false;

	loading: boolean = true; //Loading...
	isSelectableColumn: boolean = false;
	isColumn: boolean = false;
	isEditingDisabled: boolean = false;
	loaded: boolean = false;

	updateListSubscription: Subscription;
	orderUpdatedSubscription: Subscription;

	constructor(
		private routesService: RoutesService,
		public campaignService: CampaignService,
		protected confirmationService: ConfirmationService,
		protected notificationsService: NotificationsService,
		protected i18n: I18nService,
		protected authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		private translate: TranslateService
   ) {
		super(
			 routesService,
			 confirmationService,
			 notificationsService,
			 i18n,
			 authenticationService,
			 loadingService,
			 userTime
		);
   }

  ngOnInit(): void {
	//console.log("[ROUTES-LIST] OnInit ***");
	//console.log(this.space);
	this.user = this.authenticationService.user;
	this.loadFilters = true;
	this.visible = true;
	this.messages = [];

	this.updateListSubscription = this.routesService.routeSaved
	.subscribe(
		() => {
			this.refresh();
		}
	);
	this.orderUpdatedSubscription = this.routesService.orderSaved
	.subscribe(
		() => {
		}
	);

	/** ionicialitzem la sortida **/
	this.paginationResult = {
		filteredEntities: 0,
		entities: [],
	} as Rest.Page<Rest.FidRoute>;
	this.fetch();
  }

   fetch() {
		//console.log("[ROUTES-LIST] fetch ***");
		this.loadingService.increaseRESTOngoingRequestCounter();
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			//Load the filters and page if requested and the entity can be filtered by other entities
			let t = this.paginationRequest;

			/** informem els filtres de la consulta **/
			let pr = {
			   sortBy: "id",
				sortAsc: true,
				pageSize: 100,
				loadPermissions: true,
				filters: {},
			} as Rest.ListPaginationRequest;
			pr.filters.enabled = ["true"];
			pr.filters = {
				//active: [true + ""],
			 	spaceId: [this.space.id + ""]
			};
			this.paginationRequest = pr;

			this.routesService
				.getPage(this.paginationRequest)
				.then(
			 		function (response) {
						// console.log(response);
			  			that.paginationResult = response;
						that.space.routes = that.paginationResult.entities;
			  			/**Checkbox colum selection */
			  			for (var a in response.entities) {
								that.entitiesSelected[response.entities[a]['id']] = false;
			  			}
			  			that.selectedAll = false;
			  			/** */
			  			that.loadingService.decreaseRESTOngoingRequestCounter();
			  			resolve();
		 			},
		 			/* failure */

		 			function (error) {
				  		console.log('The request failed: ' + error);
			  			that.loadingService.decreaseRESTOngoingRequestCounter();
			  			resolve();
		 			},
				)
				.catch(function (reason) {
			 		console.log('Catched: ' + reason);
		 			that.loadingService.decreaseRESTOngoingRequestCounter();
					resolve();
				});
		});
		return promise;
   }

	refresh(): void {
		//console.log("[ROUTES-LIST-FORM] refresh *** ");
		const that = this;
		this.fetch().then(() => {
			// console.log(that.space.routes);
		});
   }

	// ngOnDestroy() {
	// 	//this.subscription.unsubscribe();
	// }

	// protected beforeLoad() {
	// 	console.log("*** beforeLoad() ***");
	// 	//this.paginationRequest.filters["active"] = ["true"];//we only need to search for reports of type tax
	// }

	protected afterLoad() {
		//console.log("[ROUTES-LIST] afterLoad ***");
		this.loaded = true;
		//console.log(this.space.routes);
	}

	public editRoute(route: Rest.FidRoute): void {
		//console.log("[ROUTES-LIST] editRoute ***");
		this.selectedRoute = route;
		this.selectedRoute.space = this.space;
		//console.log(this.selectedRoute);
		this.routesService.startedEditing.next(this.selectedRoute);
	}

	public createRoute(): void {
		//console.log("[ROUTES-LIST] createRoute ***");
		this.routesService.startedCreating.next(this.space);
	}

	public deleteRoute(route) {
		// console.log("[ROUTES-LIST] deleteRoute ***");
		// console.log(route);

		let confirmationMessage =
			this.translate.instant('control-panel.delete-dialog.stats-advice') + '<br><br>' +
			this.translate.instant('control-panel.delete-dialog.message') + ' ' +
			this.translate.instant('promotedRoutes.theRoute') + ': ' +
			'<strong>' + route.name + '</strong>?';

		//console.log(confirmationMessage);
		this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				//console.log("accept");
				this.routesService.deleteRoute(route.id).then((response) => {
					//console.log(response);
					if (response) {
						this.notificationsService.add(
							Severity.success,
							this.translate.instant('success.success'),
							this.translate.instant('success.delete.event')
						);
						/* actualizamos la variable local */
						// this.targetIndex = this.campaign.targets.indexOf(target);
						// this.campaign.targets.splice(this.targetIndex,1);
						// this.filterIndex = null;
						// this.filterList = null;
						// this.filterListLoaded = false;
						// this.resetFilterFields();
						this.space.routes.indexOf(route);

						/* actualizamos la lista de spaces */
						this.routesService.routeSaved.next();
					}
				});
			},
		});
	}

	public onRowReorder(event) {
		// console.log("[ROUTES-LIST] onRowReorder ***");
		// console.log(event); //dragIndex,	dropIndex
		let oldIndex: number = event.dragIndex;
		let newIndex: number = event.dropIndex;
		let oldPos: number = oldIndex + 1;
		let newPos: number = newIndex + 1;
		// console.log("oldIndex: " + oldIndex + ", oldPos: " + oldPos + " -> " + this.space.routes[oldIndex].numPos + ", " + this.space.routes[oldIndex].nameRoute);
		// console.log("newIndex: " + newIndex + ", newPos: " + newPos + " -> " + this.space.routes[newIndex].numPos + ", " + this.space.routes[newIndex].nameRoute);

		if (oldIndex !== newIndex) {
			let i = 1;
			this.space.routes.forEach((x) => {
				// console.log(x.numPos + " - " + x.nameRoute + " -> " + i);
				x.numPos = i;
				++i;

				//update route
				let routeData = {
					id: x.id,
					nameRoute: x.nameRoute,
					nameRouteCat: x.nameRouteCat,
					nameRouteEng: x.nameRouteEng,
					nameRouteFra: x.nameRouteFra,
					nameRouteIta: x.nameRouteIta,
					shortDescription: x.shortDescription,
					shortDescriptionCat: x.shortDescriptionCat,
					shortDescriptionEng: x.shortDescriptionEng,
					shortDescriptionFra: x.shortDescriptionFra,
					shortDescriptionIta: x.shortDescriptionIta,
					description: x.description,
					descriptionCat: x.descriptionCat,
					descriptionEng: x.descriptionEng,
					descriptionFra: x.descriptionFra,
					descriptionIta: x.descriptionIta,
					space: { id: this.space.id },
					enabled: x.enabled,
					datePublication: x.datePublication,
					numPos: x.numPos,
					category: { id: x.category.id },
					totalKms: x.totalKms,
					totalTime: x.totalTime,
					totalTimeCat: x.totalTimeCat,
					totalTimeEng: x.totalTimeEng,
					totalTimeFra: x.totalTimeFra,
					totalTimeIta: x.totalTimeIta,
					track: { id: x.track.id },
					image: { id: x.image.id },
					infoLink: x.infoLink,
					infoLinkCat: x.infoLinkCat,
					infoLinkEng: x.infoLinkEng,
					infoLinkFra: x.infoLinkFra,
					infoLinkIta: x.infoLinkIta,
					sharedLink: x.sharedLink,
					sharedLinkCat: x.sharedLinkCat,
					sharedLinkEng: x.sharedLinkEng,
					sharedLinkFra: x.sharedLinkFra,
					sharedLinkIta: x.sharedLinkIta,
					activitiesLink: x.activitiesLink,
					activitiesLinkCat: x.activitiesLinkCat,
					activitiesLinkEng: x.activitiesLinkEng,
					activitiesLinkFra: x.activitiesLinkFra,
					activitiesLinkIta: x.activitiesLinkIta
				};
				let RouteDataString = JSON.stringify(routeData);

				this.routesService.updateRoute(RouteDataString, null, null, true)
					.then((route) => {
						if (route) {
							this.routesService.orderSaved.next();
						}
			   	});
			});
		}
		// this.space.routes.forEach((x) => {
		// 	console.log("NEW: " + x.numPos + " - " + x.nameRoute);
		// });
	}

}

