import {I18nService} from '../../services/i18n/i18n.service';

export class ServerValidatedFormComponent {
  messages: any[];
  errors: any;
  baseName: string = null;

  constructor(protected i18n: I18nService) {
    this.messages = [];
    this.errors = {};
  }

  protected clearMessages(): void {
    this.messages = [];
    this.errors = {};
  }

  public handleError(err, that) {
    that.errors = {};
    if (typeof err === 'string') {
      err = JSON.parse(err);
    }
    if (err.errors) {
      that.messages.push({severity: "error", detail: this.i18n._('Form has errors'), summary: ""});
      for (const i in err.errors) {
        console.error(err.errors[i].fieldName + " " + err.errors[i].errorDescription);
      }
      for (const i in err.errors) {
        if (err.errors[i].fieldName == "") {
          that.messages.push({severity: "error", detail: err.errors[i].errorDescription, summary: ""});
        } else {
          const e = err.errors[i];

          if (!that.errors[e.fieldName]) {
            that.errors[e.fieldName] = [];
          }
          that.errors[e.fieldName].push(e.errorDescription);
        }
      }
    }
  }

}
