import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PhaseChangeEvent } from 'app/modules/vehicle-inventory/services/inventory-reports.service';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { CecoService } from "app/rest/ceco.service";
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { VehicleService } from 'app/rest/vehicle.service';

import BillingAcount = Rest.BillingAccount;
import Ceco = Rest.Ceco;
import Delegation = Rest.Delegation;
import Subdelegation = Rest.Subdelegation;
import Vehicle = Rest.Vehicle;

import Client = Rest.Client;

@Component({
  selector: 'app-fines-report-form',
  templateUrl: './fines-report-form.component.html',
  styleUrls: ['./fines-report-form.component.css'],
  providers: [
    BillingaccountService,
    CecoService,
    DelegationService,
    SubdelegationService,
    VehicleService
  ],
  encapsulation: ViewEncapsulation.None
})
export class FinesReportFormComponent implements OnInit {

  client: Client;

  @Output() phaseChange = new EventEmitter<PhaseChangeEvent>();
  @Input() report: PhaseChangeEvent;

  reportData: Rest.InventoryReport;

  reportForm = new FormGroup({
    billingAccount: new FormControl(null),
    ceco: new FormControl(null),
    delegation: new FormControl(null),
    subdelegation: new FormControl(null),
    vehicle: new FormControl(null),
  });

  // PRESERVATION OF RELATIONSHIPS
  billingAccountRequestContext: Rest.ListPaginationRequest;
  cecoRequestContext: Rest.ListPaginationRequest;
  delegationRequestContext: Rest.ListPaginationRequest;
  subdelegationRequestContext: Rest.ListPaginationRequest;
  vehicleRequestContext: Rest.ListPaginationRequest;

  // FORM OPTIONS LISTS
  billingAccounts: BillingAcount[] = [];
  cecos: Ceco[] = [];
  delegations: Delegation[] = [];
  subdelegations: Subdelegation[] = [];
  vehicles: Vehicle[] = [];

  isBillingAccountLoaded: boolean = false;
  isCecoLoaded: boolean = false;
  isDelegationLoaded: boolean = false;
  isSubdelegationLoaded: boolean = false;
  isVehicleLoaded: boolean = false;

  // FORM DATA
  selectedBillingAccounts: BillingAcount[] = [];
  selectedCecos: Ceco[] = [];
  selectedDelegations: Delegation[] = [];
  selectedSubdelegations: Subdelegation[] = [];
  selectedVehicles: Vehicle[] = [];

  isBillingAccountChanged: boolean = false;
  isCecoChanged: boolean = false;
  isDelegationChanged: boolean = false;
  isSubdelegationChanged: boolean = false;
  isVehicleChanged: boolean = false;

  constructor(
    protected authenticationService: AuthenticationService,
    private billingAccountService: BillingaccountService,
    private cecoService: CecoService,
    private delegationService: DelegationService,
    private subdelegationService: SubdelegationService,
    private vehicleService: VehicleService
  ) { }

  ngOnInit(): void {
    this.client = this.authenticationService.user.client;
    this.billingAccountRequestContext = RestExt.firstPageRequestVehicleInventory();
    this.delegationRequestContext = RestExt.firstPageRequestVehicleInventory();
    this.subdelegationRequestContext = RestExt.firstPageRequestVehicleInventory();

    this.cecoRequestContext = RestExt.firstPageRequest();
    this.vehicleRequestContext = RestExt.firstPageRequest();

    this.vehicleRequestContext.filters["clientId"] = [this.client.id.toString()];
    this.cecoRequestContext.filters["clientId"] = [this.client.id.toString()];

    this.fetchData();
  }

  goBack() {
    this.phaseChange.emit({ phase: 1, resetData: true });
    this.reportForm.reset();
  }

  onSubmit() {
    if (this.reportForm.valid) {
      this.toPhase3();
    }
  }

  toPhase3() {
    this.phaseChange.emit({ phase: 3, reportData: this.report.reportData, formData: this.reportForm.value });
  }

  fetchData() {
    this.reportData = this.report.reportData;

    this.fetchBillingAccounts();
    this.fetchCecos();
    this.fetchDelegations();
    this.fetchSubdelegations();
    this.fetchVehicles();
  }

  fetchBillingAccounts() {
    this.billingAccountRequestContext = {
      sortBy: 'code',
      sortAsc: true,
      filters: { enabled: [true] },
    } as unknown as Rest.ListPaginationRequest;
    this.billingAccountRequestContext.filters["clientID"] = [this.client.id.toString()];

    this.billingAccountService.getPage(this.billingAccountRequestContext).then((p) => {
      this.billingAccounts = p.entities.map(account => ({
        ...account,
        codeName: `${account.code} ${account.name}`
      }));

      this.isBillingAccountLoaded = true;
    });
  }

  fetchCecos() {
    this.cecoRequestContext = {
      sortBy: 'code',
      sortAsc: true,
      filters: { disabled: [false] },
    } as unknown as Rest.ListPaginationRequest;

    this.cecoService.getPage(this.cecoRequestContext).then((p) => {
      this.cecos = p.entities.map(ceco => ({
        ...ceco,
        codeName: `${ceco.code} ${ceco.description}`
      }));

      this.isCecoLoaded = true;
    });
  }

  fetchDelegations() {
    this.delegationRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { disabled: [false] },
    } as unknown as Rest.ListPaginationRequest;

    this.delegationService.getPage(this.delegationRequestContext).then((p) => {
      this.delegations = p.entities.map(delegation => ({
        ...delegation,
        codeName: `${delegation.code} ${delegation.name}`
      }));

      this.isDelegationLoaded = true;
    });
  }

  fetchSubdelegations() {
    this.subdelegationRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { disabled: [false] },
    } as unknown as Rest.ListPaginationRequest;

    this.subdelegationService.getPage(this.subdelegationRequestContext).then((p) => {
      this.subdelegations = p.entities.map(subdelegation => ({
        ...subdelegation,
        codeName: `${subdelegation.code} ${subdelegation.name}`
      }));

      this.isSubdelegationLoaded = true;
    });
  }

  fetchVehicles() {
    this.vehicleRequestContext = {
      sortBy: 'name',
      sortAsc: true,
      filters: { enabled: [true] },
    } as unknown as Rest.ListPaginationRequest;

    this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
      this.vehicles = p.entities;
      this.isVehicleLoaded = true;
    });
  }

}
