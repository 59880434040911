import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { ConfirmationService } from 'primeng/api';
import { InsuranceComponentService } from 'app/modules/vehicle-inventory/services/table-management/insuranceComponent.service';

@Component({
  selector: 'app-insurance-component-list',
  templateUrl: './insurance-component-list.component.html',
  styleUrls: ['./insurance-component-list.component.css'],
  providers: [ConfirmationService, UserTimePipe, InsuranceComponentService],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceComponentListComponent extends EntityListComponent<Rest.InsuranceComponent>
implements OnInit {

  title: string = this.i18n._("Insurance list");


  user: Rest.User;

  vehicleId: any = null;

  @Input() insuranceId: number;
  canSeeCosts: boolean = false;

  colspan: number;

  constructor(
    private insuranceComponentService: InsuranceComponentService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
	 protected route: ActivatedRoute
  ) {
    super(
      insuranceComponentService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
    );

    this.insuranceComponentService = insuranceComponentService;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hasEntityFilters = true;
    this.loadFilters = false;

	  console.log("vehicleId: " + this.vehicleId);

    const user = this.authenticationService.user;
    const rolesForForm = ['INV_INSURANCE_CREATE', 'INV_INSURANCE_UPDATE'];

    this.canSeeCosts = this.authenticationService.isRoleGranted('INV_INSURANCE_COSTS_READ', user);
    this.colspan = this.canSeeCosts ? 5 : 3;
    
    console.log(this.canSeeCosts);
  }

  protected beforeLoad(): void {
    this.paginationRequest.sortBy = 'startDate';
    if(this.insuranceId){
      this.paginationRequest.filters['insuranceId'] = [this.insuranceId.toString()];
    }

  }
  afterLoad() {
  }

}
