import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
import { MileageStatsService } from 'app/modules/vehicle-inventory/services/mileage-stats.service';
import { Rest } from 'app/rest/rest_client';
import { VehicleService } from 'app/rest/vehicle.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-mileage-stats',
	templateUrl: './mileage-stats.component.html',
	styleUrls: ['./mileage-stats.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class MileageStatsComponent extends EntityFormComponent<Rest.MileageStats> implements OnInit, OnDestroy {
	@Input() vehicle: Rest.Vehicle;

	isDialogVisible: boolean;
	isSevereError: boolean;
	isEditingDisabled: boolean;
	loading: boolean = true;
	errorMessages: string[];
	statsFormTitle: string;
	errorContainer: ElementRef;
	collapsed: Boolean = false;
	getStatsSubscription: Subscription;
	stats: Rest.MileageStats = null;

	/*******************/
	/*** PERMISSIONS ***/
	/*******************/
	isGetGranted: boolean = false;

	/**  */
	constructor(
		public mileageStatsService: MileageStatsService,
		public vehicleService: VehicleService,
		private authService: AuthenticationService,
		public i18n: I18nService,
		public notificationsService: NotificationsService,
		public translate: TranslateService
	) {
		super(mileageStatsService, notificationsService, i18n, authService);
		this.errorMessages = [];
	}

	ngOnInit() {
		console.log('[MILEAGE-STATS] ngOnInit ***');
		console.log(this.vehicle);
		super.ngOnInit();
		this.isSevereError = false;
		this.statsFormTitle = this.translate.instant('tableManagement.mileage.mileageSummary');
		this.statsFormTitle += ' (' + this.vehicle.name + ') ' + this.vehicle.model.brand.name + ' ' +
			this.vehicle.model.name + (this.vehicle.version ? (' ' + this.vehicle.version.name) : '');

		this.getStatsSubscription = this.mileageStatsService.getKmStats.subscribe(() => {
			console.log('_____ getStats _____');
			this.showDialog();
		});
		//this.setPermissions();
	}

	ngOnDestroy() {
		this.getStatsSubscription.unsubscribe();
	}

	public showDialog(): void {
		console.log('[MILEAGE-STATS] showDialog ***');
		this.isDialogVisible = true;
		this.loading = true;
		this.stats = {} as Rest.MileageStats;
		this.getStats();
	}

	public getStats() {
		console.log('[MILEAGE-STATS] getStats ***');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.mileageStatsService
				.getStats(this.vehicle.id)
				.then(
					function (response) {
						console.log(' ==> getStats ... ');
						that.stats = response;
						console.log(that.stats);
						that.loading = false;
						if (that.stats.errorTag !== null) {
							that.notificationsService.add(
								Severity.error,'ERROR!',
								that.translate.instant('error.get.data') + ' (Vehicle: ' +
								that.vehicle.id + ', Error: ' + that.translate.instant('tableManagement.mileage.errors.' + that.stats.errorTag)  + ")"
							);
						}
					},
					/* failure */
					function (error) {
						console.log('The request failed...');
						console.log(error);
						that.isSevereError = true;
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public onCloseButton() {
		this.isDialogVisible = false;
	}
}
