import { Directive, EventEmitter, OnDestroy, OnInit, Output, } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { I18nService } from '../../services/i18n/i18n.service';
import { NotificationsService, Severity } from '../../services/notifications-service/notifications.service';
import { ServerValidatedFormComponent } from '../server-validated-form/server-validated-form.component';

@Directive()
export class SimpleForm<T>
    extends ServerValidatedFormComponent
    implements OnInit, OnDestroy {
    public display: boolean;
    public isNew: boolean;
    protected alive: boolean = true;
    public entity: T;

    constructor(
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService
    ) {
        super(i18n); // cycle ROBERT
    }

    @Output() public return = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onClose = new EventEmitter();

    ngOnInit() {
        this.messages = [];
        this.errors = {};
        this.showModalForm(false);
        //TODO: remove initialization and add condition for showing form like:
        //<div *ngIf="entity">
        this.entity = <T>{};
    }

    protected beforeShow() {
    }

    protected beforeSave() {
    }

    protected beforeCreate() {
    }

    private subscriptor: Subscription;

    /**
     * Set the subscriptor for the form. onyl one can be subscrived at a time. If a subscription is already present, remove it.
     * @param s
     */
    public setFormSubscriptor(callback: any) {
        if (this.subscriptor != null) this.subscriptor.unsubscribe();
        this.subscriptor = this.return.subscribe(callback);
    }

    ngOnDestroy() {
        this.alive = false;
    }

    /**
     * Initialize an entity creation from scratch or from a given entity
     * @param entity
     */
    create(entity?: T) {
        this.beforeCreate();
        this.isNew = true;
        this.messages = [];
        this.errors = {};
        this.entity = entity ? entity : <T>{};
        this.beforeShow();
        this.showModalForm(true);
    }

    /**
     * Initialize an entity update using a copy of the provided entity
     * @param entity
     */
    update(entity: T) {
        this.isNew = false;
        this.messages = [];
        this.errors = {};
        this.entity = JSON.parse(JSON.stringify(entity));
        this.beforeShow();
        this.showModalForm(true);
     }

    updateNodisplay(entity: T) {
        this.isNew = false;
        this.messages = [];
        this.errors = {};
        this.entity = JSON.parse(JSON.stringify(entity));
        this.beforeShow();
        this.showModalForm(false);
     }


    showModalForm(show: boolean){
        try{
            //primeng modal
            this.display = show;
            if(show){          
                //bootsrap modal
              //  $('#FormModal'). modal('show') 
            }
            else{
                //bootsrap modal
                //$('#FormModal').modal('hide');     
            }          
        }
        catch(error)
        {
            console.error(error);
        }
      
    };


 
    
}
