import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AccessoryService } from 'app/rest/accessory.service';
import { CardService } from 'app/rest/card.service';
import { DivisionService } from 'app/rest/division.service';
import { FineService } from 'app/rest/fine.service';
import { FleetService } from 'app/rest/fleet.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { SubdivisionService } from 'app/rest/subdivision.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { VehicleUsageService } from 'app/rest/vehicleUsage.service';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { VehiclemodelService } from 'app/rest/vehiclemodel.service';
import { VehiclesgroupService } from 'app/rest/vehiclesgroup.service';
import { VehicleversionService } from 'app/rest/vehicleversion.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { DocumentationService } from '../../services/documentation.service';
import { DriverAssignmentsService } from '../../services/driver-assignments.service';
import { DriverService } from '../../services/driver.service';
import { InvVehicleService } from '../../services/invVehicle.service';
import { DelegationService } from '../../services/table-management/delegation.service';
import { FinancingService } from '../../services/table-management/financing.service';
import { InsuranceService } from '../../services/table-management/insurance.service';
import { TaxService } from '../../services/table-management/taxes.service';
import { VehicleAccessoryService } from '../../services/vehicle-accessory.service';
import { VehicleAssignmentsService } from '../../services/vehicle-assignments.service';
import { VehicleHtcService } from '../../services/vehicle-htc.service';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { ConfirmationService } from 'primeng/api';
import { MaintenanceplanService } from 'app/rest/maintenanceplan.service';
import { MaintenanceService } from 'app/rest/maintenance.service';
import { MaintenanceexecutionService } from 'app/rest/maintenanceexecution.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css'],
  providers: [
	  InvVehicleService,
	  DriverService,
	  VehicleService,
	  VehiclesgroupService,
	  VehicleUsageService,
	  FleetService,
	  VehiclesgroupService,
	  ExcelService,
	  VehicleHtcService,
	  FineService,
	  InsuranceService,
	  FinancingService,
	  DocumentationService,
	  VehiclebrandService,
	  VehiclemodelService,
	  VehicleversionService,
	  VehicleAccessoryService,
	  AccessoryService,
	  DelegationService,
	  SubdelegationService,
	  DivisionService,
	  SubdivisionService,
	  DriverAssignmentsService,
	  VehicleAssignmentsService,
	  TaxService,
	  CardService,
	  ConfirmationService,
	  UserTimePipe,
	  MaintenanceplanService,
	  MaintenanceService,
	  MaintenanceexecutionService
  ]
})
export class DriversComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
