<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Global parameters
          (AT + GTCFG)</span>
      </div>
    </div>
  </p-header>
  <!--   <div class="ui-g" [class.disableDIV]=!deviceParams.proprietaryParameters.speedEvent.enabled>-->
  <!-- <input type="text" [(ngModel)]="deviceParams.balh"> -->


  <div class="ui-g">



    <!-- <div [class.disableDIV]=!trackModeSwitch> -->


    <div class="ui-g-12">
      <form-input fieldName="Event Mask">

      </form-input>
    </div>

    <div class="col row dvcFields">
      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTPNA" [(ngModel)]="globalParameters.eventMaskRESPGTPNA">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTPFA" [(ngModel)]="globalParameters.eventMaskRESPGTPFA">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTMPN" [(ngModel)]="globalParameters.eventMaskRESPGTMPN">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTMPF" [(ngModel)]="globalParameters.eventMaskRESPGTMPF">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTBPL" [(ngModel)]="globalParameters.eventMaskRESPGTBPL">
        </p-checkbox>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTSTC" [(ngModel)]="globalParameters.eventMaskRESPGTSTC">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTSTT" [(ngModel)]="globalParameters.eventMaskRESPGTSTT">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTANT" [(ngModel)]="globalParameters.eventMaskRESPGTANT">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTPDP" [(ngModel)]="globalParameters.eventMaskRESPGTPDP">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="Power ON +RESP:GTRTL"
          [(ngModel)]="globalParameters.eventMaskPowerONRESPGTRTL"></p-checkbox>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col">
        <p-checkbox binary="true" label="Ignition report +RESP:GTIGN, +RESP:GTIGF, +REPS:GTVGN and +RESP:GTVGF"
          [(ngModel)]="globalParameters.eventMaskIgnitionReportRESPGTIGNRESPGTIGFREPSGTVGN"></p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="Ignition ON/OFF location report +RESP:GTIGL and +RESP:GTVGL"
          [(ngModel)]="globalParameters.eventMaskIgnitionOnOffRESPGTIGLRESPGTVGL"></p-checkbox>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Power Saving Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="powerSavingOptions" [(ngModel)]="globalParameters.powerSaving"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Info Report Enable">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="globalParameters.infoReportEnable"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Info Report Interval">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" id="infoReportInterval" size="7" [(ngModel)]="globalParameters.infoReportInterval"
              [min]="30" [max]="86400"></p-spinner>
            <span class="ui-inputgroup-addon">30~86400 </span>
            <span class="ui-inputgroup-addon">seconds</span>
          </div>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="GPS Lost Time">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" size="2" [(ngModel)]="globalParameters.gpslostTime" [min]="0" [max]="30"></p-spinner>
            <span class="ui-inputgroup-addon">0~30 </span>
            <span class="ui-inputgroup-addon">minutes</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="GPS Working Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown [options]="GPSWorkingModeOptions" [(ngModel)]="globalParameters.gpsworkingMode"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>

    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="gsm Report">
        </form-input>
      </div>
      <div class="col">
        <!-- [disabled]="!globalParameters." -->
        <p-dropdown [options]="GSMReportOptions" [(ngModel)]="globalParameters.gsmreportMode"
          [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTRTL" [(ngModel)]="globalParameters.gsmreportRESPGTRTL">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTLBC" [(ngModel)]="globalParameters.gsmreportRESPGTLBC">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTFRI +RESP:GTERI"
          [(ngModel)]="globalParameters.gsmreportRESPGTFRIRESPGTERI"></p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTSOS" [(ngModel)]="globalParameters.gsmreportRESPGTSOS">
        </p-checkbox>
      </div>

      <div class="col">
        <p-checkbox binary="true" label="+RESP:GTTOW" [(ngModel)]="globalParameters.gsmreportRESPGTTOW">
        </p-checkbox>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="LED On">
        </form-input>
      </div>
      <div class="col">
        <p-inputSwitch [(ngModel)]="globalParameters.ledON"></p-inputSwitch>
      </div>
    </div>

  </div>
  <!-- </div> -->
</p-accordionTab>