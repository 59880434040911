<!-- [sortField]="sortField" [sortOrder]="sortOrder" [rowStyleClass]="onRowRender"  scrollWidth="${showMapMode == false ? 100vw : 100%}" -->


<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
 -->

<!-- PrimeNG DataTable -->
<p-table #table [value]="rows"
         [autoLayout]="true"
         scrollHeight="74.0vh"
         [scrollable]="false"
         styleClass="p-datatable-sm"
>

  <!-- Table Column/Header Definition -->
  <ng-template pTemplate="header" let-columns>
    <tr>

      <th *ngIf="showMapMode === true">

      </th>

      <th class="text-center">
        Nº
      </th>

      <th *ngFor="let col of cols" [hidden]="!col.visible" [ngSwitch]="col.field">
        <div>
          <!-- HEADER -->
          <div class='d-flex align-items-center h-100'>
              <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100 overflow breakLongWords" *ngIf="expandedHeaders === true">
                {{col.header}}
              </div>
              <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100 overflow breakLongWords" *ngIf="expandedHeaders === false">
                {{col.shortHeader}}
              </div>
              <div *ngSwitchCase="'controlPoint'">
                <!-- COLUMN SORT && FILTER ICONS -->
                <div class='d-flex align-items-center h-100'>
                  <p-columnFilter field='controlPoint.name'
                  type='text'
                  matchMode='contains'
                  display='menu'
                  [showMatchModes]='false'
                  [showOperator]='false'
                  [showAddButton]='false'
                ></p-columnFilter>
                </div>

              </div>
              <div *ngSwitchCase="'vehicle'">
                          <!-- COLUMN SORT && FILTER ICONS -->
                          <div class='d-flex align-items-center h-100'>
                            <p-columnFilter field='vehicle.name'
                            type='text'
                            matchMode='contains'
                            display='menu'
                            [showMatchModes]='false'
                            [showOperator]='false'
                            [showAddButton]='false'
                          ></p-columnFilter>
                          </div>

              </div>
          </div>

    
            
          
        </div>
      </th>
    </tr>
  </ng-template>

  <!-- Table Row Definition -->
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
    <tr [ngStyle]="{ 'background-color': onRowRender(rowData), 'font-weight' :bold }">

      <td *ngIf="showMapMode === true" class="text-center">
        <button class="center-map-col btn btn-primary btn-sm" pButton icon="fa fa-map-marker" (click)="focusButton(rowData.controlPoint.id)"></button>
      </td>

      <td class="text-center">
        <div class="indexCol">{{i+1}}</div>
      </td>

      <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">

        <td *ngSwitchCase="'controlPoint'" [hidden]="!col.visible">
          <div  (click)="controlPointClicked(rowData)" [title]="rowData.controlPoint.name" class="overflow cellClicable">
            {{rowData.controlPoint.name}}
          </div>
        </td>

        <td *ngSwitchCase="'lastMaintenanceDate'" [hidden]="!col.visible">
          <div [title]="rowData.lastMaintenanceDate | date: 'short'" class="overflow">
            <span>
              {{rowData.lastMaintenanceDate | date: 'short'}}
            </span>
          </div>
        </td>

        <td *ngSwitchCase="'lastVigilanceDate'" [hidden]="!col.visible">
          <div  [title]="rowData.lastVigilanceDate | date: 'short'" class="overflow">
            <span>
              {{rowData.lastVigilanceDate | date: 'short'}}
            </span>
          </div>
        </td>

        <td *ngSwitchCase="'maintenanceLeftTime'" [hidden]="!col.visible">
          <div  [title]="rowData.maintenanceLeftTime | millisToHumanReadable" class="overflow">
            {{rowData.maintenanceLeftTime | millisToHumanReadable}}
          </div>
        </td>

        <td *ngSwitchCase="'vigilanceLeftTime'" [hidden]="!col.visible">
          <div [title]="rowData.vigilanceLeftTime | millisToHumanReadable" class="overflow">
            {{rowData.vigilanceLeftTime | millisToHumanReadable}}
          </div>
        </td>

        <td *ngSwitchCase="'minorTimeRemaining'" [hidden]="!col.visible">
          <div  [title]="rowData.minorTimeRemaining | millisToHumanReadable" class="overflow">
            {{rowData.minorTimeRemaining | millisToHumanReadable}}
          </div>
        </td>
<!--
        <td *ngSwitchCase="'enterZoneDate'" [hidden]="!col.visible">
          <div  [title]="rowData.enterZoneDate | millisToHumanReadable" class="overflow">
            {{rowData.enterZoneDate | millisToHumanReadable}}
          </div>
        </td>

        <td *ngSwitchCase="'leaveZoneDate'" [hidden]="!col.visible">
          <div  [title]="rowData.leaveZoneDate | millisToHumanReadable" class="overflow">
            {{rowData.leaveZoneDate | millisToHumanReadable}}
          </div>
        </td>

        <td *ngSwitchCase="'durationTimeInZone'" [hidden]="!col.visible">
          <div  [title]="rowData.durationTimeInZone| millisToHumanReadable" class="overflow">
            {{rowData.durationTimeInZone | millisToHumanReadable}}
          </div>
        </td>

        <td *ngSwitchCase="'mileageInZone'" [hidden]="!col.visible">
          <div  [title]="rowData.mileageInZone| millisToHumanReadable" class="overflow">
            {{rowData.mileageInZone | millisToHumanReadable}}
          </div>
        </td>-->

        <td *ngSwitchCase="'vehicle'" [hidden]="!col.visible">
          <div (click)="vehicleClicked(rowData)" [title]="rowData.vehicle.name" class="overflow cellClicable">
            {{rowData.vehicle.name}}
          </div>
        </td>

        


      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" >
    <tr>
      <td [attr.colspan]="columnsSize">
        <p i18n>No s'han trobat registres</p>
      </td>
    </tr>
  </ng-template>

</p-table>


<!--<p-dataTable #table responsive="true" [value]="rows" [rowStyleClass]="onRowRender" scrollable="true" scrollHeight="76.8vh"  scrollWidth="calc(100% + 88px)" >

        &lt;!&ndash; MAP COLUMN&ndash;&gt;
        <p-column  header="" *ngIf="showMapMode == true" styleClass="mapCol  overflow ellipsis ">
            <ng-template  let-row="rowData" pTemplate="body">
                <button class="center-map-col" pButton icon="fa fa-map-marker" lable="Center" (click)="focusButton(row.controlPoint.id)"></button>
            </ng-template>
        </p-column>

        &lt;!&ndash; INDEX COLUMN&ndash;&gt;
        <p-column  class="" header="Nº" styleClass="indexCol  overflow ellipsis">
            <ng-template  class="indexCol" let-i="rowIndex" pTemplate="rowExpansion">
                <div class="indexCol">{{i+1}}</div>
            </ng-template>
        </p-column>

        &lt;!&ndash; CUSTOM COLUMNS&ndash;&gt;
        <p-column width="auto" *ngFor="let col of cols" [field]="col.field" [header]="col.header" [hidden]="!col.visible">

            &lt;!&ndash; COLUMN WIH FILTER AND ORDER&ndash;&gt;
            <ng-template  pTemplate="header">
                <div>
                    &lt;!&ndash; HEADER &ndash;&gt;
                    <a [title]="col.header">
                        <div class="overflow ellipsis breakLongWords" *ngIf="expandedHeaders == true">
                            {{col.header}}
                        </div>
                        <div class=" overflow ellipsis breakLongWords" *ngIf="expandedHeaders == false">
                            {{col.shortHeader}}
                        </div>
                    </a>
                </div>
            </ng-template>


        &lt;!&ndash; ___________________________________ROWS______________class="overflow ellipsis cellClicable"_____________________ &ndash;&gt;




            &lt;!&ndash;CUSTOM ROWS&ndash;&gt;
            <ng-template *ngIf="col.field === 'controlPoint'" let-row="rowData" pTemplate="body">
                <div  (click)="controlPointClicked(row)" [title]="row.controlPoint.name" class="overflow ellipsis cellClicable">
                    {{row.controlPoint.name}}
                </div>
            </ng-template>



            <ng-template  *ngIf="col.field === 'lastMaintenanceDate'" let-row="rowData" pTemplate="body">
                <div [title]="row.lastMaintenanceDate | date: 'short'" class="overflow ellipsis">
                   <span  [attr.id]="row.maintenance_manual ? 'outstanding-field' : null" > {{row.lastMaintenanceDate | date: 'short'}} </span>
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'lastVigilanceDate'" let-row="rowData" pTemplate="body">
                <div  [title]="row.lastVigilanceDate | date: 'short'" class="overflow ellipsis">
                    <span [attr.id]="row.vigilance_manual ? 'outstanding-field' : null"> {{row.lastVigilanceDate | date: 'short'}} </span>
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'maintenanceLeftTime'" let-row="rowData" pTemplate="body">
                <div  [title]="row.maintenanceLeftTime | millisToHumanReadable" class="overflow ellipsis">
                    {{row.maintenanceLeftTime | millisToHumanReadable}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'vigilanceLeftTime'" let-row="rowData" pTemplate="body">
                <div [title]="row.vigilanceLeftTime | millisToHumanReadable" class="overflow ellipsis">
                    {{row.vigilanceLeftTime | millisToHumanReadable}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'minorTimeRemaining'" let-row="rowData" pTemplate="body">
                <div  [title]="row.minorTimeRemaining | millisToHumanReadable" class="overflow ellipsis">
                    {{row.minorTimeRemaining | millisToHumanReadable}}
                </div>
            </ng-template>
            <ng-template *ngIf="col.field === 'vehicle'" let-row="rowData" pTemplate="body">
                <div (click)="vehicleClicked(row)" [title]="row.vehicle.name" class="overflow ellipsis cellClicable">
                    {{row.vehicle.name}}
                </div>
            </ng-template>


        </p-column>



</p-dataTable>-->
<!--<app-poc-visit-form></app-poc-visit-form>-->
