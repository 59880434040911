import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';
@Component({
  selector: 'app-preserve-device-logic-states-gmt200',
  templateUrl: './preserve-device-logic-states-gmt200.component.html',
  styleUrls: ['./preserve-device-logic-states-gmt200.component.css'],
})
export class PreserveDeviceLogicStatesGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() pdsParameters: Rest.PreservingDeviceLogicStatesGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  constructor(protected i18n: I18nService) { super(i18n); }

  modeOptions: any = [
    {label: "Disable this function", value:"DISABLE"},
    {label: "Preserve special device logical state", value:"PRESERVE"},
    {label: "Reset logical states list in the <Mask>", value:"RESET"}
  ]

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.pdsParameters);
    }
  }

}
