<p-accordionTab>

  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Start/Stop Report (AT + GTSSR)</span>
        </div>
        <div class="right">
            <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="StartStopReport.startStopReportMode"></p-inputSwitch>
        </div>
      </div>
    </p-header>
    
      <div class="ui-g" #StartStopReportDiv>
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Time to Stop">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="timeToStop" size="2" [(ngModel)]="StartStopReport.timeToStop"
                        [min]="1" [max]="30"></p-spinner>
                    <span class="ui-inputgroup-addon">1~30</span>
                    <span class="ui-inputgroup-addon" >minutes</span>
                </div>
            </form-input>
          </div>
          
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Time to Start">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="timeToStart" size="2" [(ngModel)]="StartStopReport.timeToStart"
                        [min]="1" [max]="5"></p-spinner>
                        <span class="ui-inputgroup-addon">1~5</span>
                        <span class="ui-inputgroup-addon" >minutes</span>
                </div>
            </form-input>
          </div>
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Fake Tow Delay">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="Start Speed" size="2" [(ngModel)]="StartStopReport.startSpeed"
                        [min]="1" [max]="10"></p-spinner>
                        <span class="ui-inputgroup-addon">1~10</span>
                        <span class="ui-inputgroup-addon" >Kmƒh</span>
                </div>
            </form-input>
          </div>
  
          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Long Stop">
                <div class="ui-inputgroup">
                    <p-spinner step="1" id="loginStop" size="7" [(ngModel)]="StartStopReport.loginStop"
                        [min]="0" [max]="43200"></p-spinner>
                    <span class="ui-inputgroup-addon">0~43200 </span>
                    <span class="ui-inputgroup-addon" >minutes</span>
                </div>
            </form-input>
          </div>
  
            
        </div>
  
  </p-accordionTab>
