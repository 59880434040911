import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Rest } from 'app/rest/rest_client';

@Component({
  selector: 'app-jamming-detection-information-form',
  templateUrl: './jamming-detection-information-form.component.html',
  styleUrls: ['./jamming-detection-information-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class JammingDetectionInformationFormComponent implements OnInit {

  @Input() jammingDetection: Rest.JammingDetection;

  jammingModeOptions: any = [
    {label: "Disable.",value:0},
    {label: "Enable detection on: Enters into “Jamming” state.",value:1},
    {label: "Enable detection on: Enters or leaving “Jamming” state..",value:2},                     
  ];
  
  constructor() { }

  ngOnInit() {
  }

  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }
}
