<p-accordionTab>
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Analog Input Port
            Settings(AT+GTAIS)</span>
        </div>
      </div>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Analog Input Port Mode">
          <p-dropdown (onChange)="trackEventModeChange(aisParameters.mode, $event)" [options]="modeOptions"
            [(ngModel)]="aisParameters.mode" [style]="{'width':'190px'}"></p-dropdown>
        </form-input>
      </div>
      <div [class.disableDIV]=!trackModeSwitch>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Range Switch">
            <p-inputSwitch [(ngModel)]="aisParameters.rangeSwitch"></p-inputSwitch>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Min Threshold">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="aisParameters.minThreshold" [min]="0" [max]="30000"
                (onChange)="onChange()"></p-spinner>
              <span class="ui-inputgroup-addon">0~30000</span>
              <span class="ui-inputgroup-addon" >mv</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Max Threshold">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="aisParameters.maxThreshold"
                [min]="0" [max]="30000" (onChange)="onChangeMax()"></p-spinner>
              <span class="ui-inputgroup-addon">0~30000</span>
              <span class="ui-inputgroup-addon" >mv</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Sample Rate">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="aisParameters.sampleRate" [min]="0" [max]="12"></p-spinner>
              <span class="ui-inputgroup-addon">0~12</span>
              <span class="ui-inputgroup-addon" >*2s</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Voltage Margin Error">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="aisParameters.voltageMarginError" [min]="0" [max]="100"></p-spinner>
              <span class="ui-inputgroup-addon">0~100</span>
              <span class="ui-inputgroup-addon" >*10mV</span>
            </div>
          </form-input>
        </div>
      </div>
    </div>
  </p-accordionTab>