import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { DivisionService } from 'app/rest/division.service';
import { BillingaccountService } from 'app/rest/billingaccount.service';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-divisions-form',
  templateUrl: './divisions-form.component.html',
  styleUrls: ['./divisions-form.component.css'],
  providers: [DivisionService, BillingaccountService],
  encapsulation: ViewEncapsulation.None,
})
export class DivisionsFormComponent
  extends EntityFormComponent<Rest.Division>
  implements OnInit {

  noticeChannels: SelectItem[];
  channelsList: Rest.NoticeChannel[];
  title: string;
  active: boolean;

  // Preservation of relationships
  companyRequestContext: Rest.ListPaginationRequest;
  companyList: Rest.BillingAccount[] = [];


  /*Permissions */
  disableSave: boolean;
  disabeChangeStatus: boolean;

  constructor(
    private objectService: DivisionService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public billingAccountService: BillingaccountService,
  ) {
    super(objectService, notificationsService, i18n, authenticationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof DivisionsFormComponent
   */
  beforeShow() {
    this.active = !this.entity.disabled;
        //#region PRESERVATION OF RELATIONSHIPS
      //COMPANY

      this.companyRequestContext = {
        sortBy: "name",
        sortAsc: true,
        pageSize: 10,
        filters:{}
      } as unknown as Rest.ListPaginationRequest;
    /*Permissions */
    if (this.isNew) {
      this.disableSave = false;
      this.title = this.translateService.instant('tableManagement.divisions.createDivision');
    } else {
      this.title = this.translateService.instant('tableManagement.divisions.editDivision');

      if(this.entity.billingAccount){
        this.companyRequestContext.filters["name"] = [this.entity.billingAccount.name];
      }

      //#endregion PRESERVATION OF RELATIONSHIPS

      if (this.entity["_$update"] === true) {
        this.disableSave = false;
      } else {
        this.disableSave = true;
      }
      if (this.entity["_$changeStatus"] === true) {
        this.disabeChangeStatus = false;
      } else {
        this.disabeChangeStatus = true;
      }
    }



      this.companyRequestContext.filters["clientID"] = [this.authenticationService.user.client.id.toString()];


      this.billingAccountService.getPage(this.companyRequestContext).then((p) => {
        this.companyList = p.entities;
      });
  }

  onChangeCompany(event) {
    this.entity.billingAccount = event.value;
  }

  onFilterCompany(event) {
    this.companyRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters:{}
    } as unknown as Rest.ListPaginationRequest;

    this.companyRequestContext.filters["clientID"] = [this.authenticationService.user.client.id.toString()];
    this.companyRequestContext.filters["name"] = [event.filter ?? ''];
    this.billingAccountService.getPage(this.companyRequestContext).then((p) => {
      this.companyList = p.entities;
    });
  }
}