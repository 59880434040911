<!-- Always display the notification panel, decide content visibility inside -->
<div class="notification-panel shadow p-3 mb-3 bg-white rounded">
    <h4 class="notification-title d-flex justify-content-between align-items-center">
        {{ 'header.notifications-panel.notifications' | translate }}
    </h4>

    <!-- Handle the presence or absence of incidences within -->
    <div *ngIf="(hasUntreatedIncidences$ | async) as hasUntreated; else noIncidences">
        <div *ngIf="hasUntreated">
            <button (click)="onIncidenceClick()" class="btn btn-light d-flex align-items-center">
                <i class="bi bi-exclamation-circle-fill me-2"></i>
                {{ 'header.notifications-panel.untreatedIncidences' | translate }}
            </button>
        </div>
    </div>
    
    <ng-template #noIncidences>
        <p class="text-success">
            <i class="bi bi-check-circle-fill"></i> 
            {{ 'header.notifications-panel.noNotifications' | translate }}
        </p>
    </ng-template>
</div>