import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Rest } from 'app/rest/rest_client';

@Component({
  selector: 'app-jamming-detection-information-gmt200-form',
  templateUrl: './jamming-detection-information-gmt200-form.component.html',
  styleUrls: ['./jamming-detection-information-gmt200-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class JammingDetectionInformationGmt200FormComponent implements OnInit {
  @Input() jammingDetection: Rest.JammingDetectionGMT200;

  jammingModeOptions: any = [
    {label: "Disable Jamming detection function",value:"DISABLE"},
    {label: "Enable Report JDR Message",value:"MODE1"},
    {label: "Enable Report JDS Message",value:"MODE2"},                     
  ];
  constructor() { }

  ngOnInit() {
  }


  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }
}
