<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">STATIC DATA FILTERING
          (SF)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="staticDataFiltering.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Filtering Distance">
      </form-input>
    </div>
    <div class="col">
      <div class="ui-inputgroup">
        <p-spinner size="5" [(ngModel)]="staticDataFiltering.maxFilteringDistance" [min]="10" [max]="1000"></p-spinner>
        <span class="ui-inputgroup-addon">10~1000</span>
        <span class="ui-inputgroup-addon">m</span>
      </div>
    </div>
  </div>
</p-accordionTab>