<!-- CONFIRM DIALOG -->
<p-confirmDialog #confirmDialog
				 [style]="{ width: '50vw' }"
				 [position]='position'
				 [baseZIndex]='10000'
				 [message]="message + '?'"
				 [icon]='icon'
				 [closeOnEscape]='false'
				 [closable]='false'
				 [defaultFocus]="'reject'"
				 class='custom-confirm-dialog'
>

	<!-- HEADER TEMPLATE -->
	<ng-template pTemplate='header'></ng-template>

	<!-- FOOTER TEMPLATE -->
	<ng-template pTemplate='footer'>
		<div class="d-flex flex-row justify-content-end gap-3">

			<!-- REJECT BUTTON -->
			<button class='custom-button confirm-dialog-button d-flex align-items-center gap-2'
					(click)="confirmDialog.reject()"
			>
				<!-- BUTTON ICON -->
				<span class='d-flex align-items-center justify-content-center'>
					<svg-icon src='assets/icons/close.svg'
							  class='d-flex align-items-center justify-content-center'
							  [svgStyle]="{'height.px': 17, 'width.px': 17}"
					></svg-icon>
				</span>

				<!-- BUTTON TEXT -->
				<span class='ms-auto me-auto' i18n>
					{{ 'general.no' | translate }}
				</span>

			</button>

			<!-- ACCEPT BUTTON -->
			<button class='custom-button confirm-dialog-button d-flex align-items-center gap-2'
					(click)="confirmDialog.accept()"
			>
				<!-- BUTTON ICON -->
				<span class='d-flex align-items-center justify-content-center'>
					<svg-icon src='assets/icons/check.svg'
							  class='d-flex align-items-center justify-content-center'
							  [svgStyle]="{'height.px': 17, 'width.px': 17}"
					></svg-icon>
				</span>

				<!-- BUTTON TEXT -->
				<span class='ms-auto me-auto' i18n>
					{{ 'general.yes' | translate }}
				</span>

			</button>

		</div>
	</ng-template>

</p-confirmDialog>
