import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { UteService } from "app/rest/ute.service";
import { I18nService } from "app/services/i18n/i18n.service";
import { NotificationsService } from "app/services/notifications-service/notifications.service";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ExcelService } from "app/services/excel-service/excel.service";
import { Configuration } from "../models/Configuration";
@Component({
  selector: "app-ute-control-point-history",
  templateUrl: "./ute-control-point-history.html",
  styleUrls: ["./ute-control-point-history.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [UteService, ExcelService],
})
export class UteControlPointHistory implements OnInit {
  @Input() configuration: Configuration;
  private resolve;
  namePoint = "";
  invalidRange = false;
  idPoint: number = null;
  loading = false;
  
  dayIniSelected: Date;
  dayEndSelected: Date;
  rowsExcel: any[];
  rowsVisibles: any[] = [];
  PopUpOpen = false;
  rows: any[] = [];
  cols: any[] = [
    {
      header: "Data inici",
      field: "visitDate",
      visible: true,
      filter: false,
    },
    {
      header: "Vehicle",
      field: "vehicle",
      visible: true,
      filter: false,
    },
    {
      header: "Grup",
      field: "group",
      visible: true,
      filter: false,
    },
    {
      header: "Data Fi",
      field: "endVisitDate",
      visible: true,
      filter: false,
    },
    {
      header: "Durada",
      field: "durationVisit",
      visible: true,
      filter: false,
    },
    {
      header: "Km",
      field: "kmVisit",
      visible: true,
      filter: false,
    },

  ];
  // TODO: Fields "sortField" and "sortOrder" were missing from legacy code!!!
  sortField: Boolean;
  sortOrder: number;
  name: string;
  constructor(
    private uteService: UteService,
    public i18n: I18nService,
    private notificationsService: NotificationsService,
    private loadingService: LoadingService,
    private excelService: ExcelService,
  ) {}

  ngOnInit() {}

  async show(name: string, id: number) {
    this.dayIniSelected = new Date(Date.now());
    this.dayEndSelected = new Date(Date.now());
    this.name = name;
    this.namePoint = "Historial del punt de control: " + name;
    this.idPoint = id;
    this.rows = [];

    this.PopUpOpen = true;
    // WE WILL LOAD ROWS  WITH NAME ID this.loadChart(currenHumanID);
    await this.loadData();
  }

  async loadData() {
    let start = null;
    let end = null;
    let limit = null;

    start = this.dayIniSelected;
    start.setHours(0, 0, 0, 0);
    end = this.dayEndSelected;
    end.setHours(23, 59, 59, 0);

    this.loading = true;
    this.rows = await this.uteService.getPoCHistory(
      this.idPoint,
      start ? start.getTime() : null,
      end ? end.getTime() : null,
      limit,
      this.configuration.id
    );
    this.loading = false;
  }

  closeButton() {
    this.PopUpOpen = false;
  }

  exportExcel() {
    let element = document.getElementById("dt"); 
  this.excelService.exportTableAsExcelFile(element, this.name); 
  }
}
