import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  SimpleChanges,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-harsh-cornering-event-configuration",
  templateUrl: "./harsh-cornering-event-configuration.component.html",
  styleUrls: ["./harsh-cornering-event-configuration.component.css"],

  encapsulation: ViewEncapsulation.None,
})
export class HarshCorneringEventConfigurationComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() deviceParams: Rest.DeviceParameters;
  avaibleLedPatterns: SelectItem[];

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
    this.avaibleLedPatterns = [
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern1.name,
        value: 1,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern2.name,
        value: 2,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern3.name,
        value: 3,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern4.name,
        value: 4,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern5.name,
        value: 5,
      },
    ];
  }

  /**
   * Needed to prevent toggle of UI component
   * @param event
   */
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
  }
}
