<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SOS function
          (AT + GTSOS)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown (onChange)="trackEventModeChange(sosParameters.modeSos,$event)" [options]="modeOptions"
                [(ngModel)]="sosParameters.modeSos" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Digital Input ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="sosParameters.digitalInputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="SOS Number">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <input type="text" pInputText [(ngModel)]="sosParameters.sosNumber"
                [style]="{'width':'100%','min-width':'100px'}" />
            </div>
          </div>
        </div>
      </p-accordionTab>

    </div>
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="sosParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="sosParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>

        </div>
      </p-accordionTab>

    </div>

  </div>
</p-accordionTab>