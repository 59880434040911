<div id="pre-config-form">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>

  <p-panel header="WeTrack2 Parameters">
      <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
          <div class="ui-g-12 ui-md-6">
            
            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                </form-input>
              </div>
              <div class="col">
                <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                [(ngModel)]="selectedPreconfig" [style]="{'width':'100%','min-width':'100px'}" filter="filter"></p-dropdown>
              </div>
            </div>

          </div>
        </div>

    <p-tabView #tv (onChange)="tabChanged()">
      <p-tabPanel header="Events">
        <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
          <app-alarm-moving-configuration-we-track2 [alarmMoving]="deviceParams.proprietaryParameters.alarmMoving"></app-alarm-moving-configuration-we-track2>
          <app-angle-configuration-we-track2 [angle]="deviceParams.proprietaryParameters.angle"></app-angle-configuration-we-track2>
          <app-apnconfiguration-we-track2 [apn]="deviceParams.proprietaryParameters.apn"></app-apnconfiguration-we-track2>
          <app-battery-alarm-configuration-we-track2 [batteryAlarm]="deviceParams.proprietaryParameters.batteryAlarm"></app-battery-alarm-configuration-we-track2>
          <app-defense-configuration-we-track2 [defense]="deviceParams.proprietaryParameters.defense"></app-defense-configuration-we-track2>
          <app-external-battery-alarm-configuration-we-track2 [externalBatteryAlarm]="deviceParams.proprietaryParameters.externalBatteryAlarm"></app-external-battery-alarm-configuration-we-track2>
          <app-external-battery-protection-configuration-we-track2 [externalBatteryProtection]="deviceParams.proprietaryParameters.externalBatteryProtection"></app-external-battery-protection-configuration-we-track2>
          <app-gmtconfiguration-we-track2 [gmt]="deviceParams.proprietaryParameters.gmt"></app-gmtconfiguration-we-track2> 
          <app-gprsblocking-alarm-configuration-we-track2 [gprsBlockingAlarm]="deviceParams.proprietaryParameters.gprsBlockingAlarm"></app-gprsblocking-alarm-configuration-we-track2>
          <app-gpsdata-sending-interval-configuration-we-track2 [gpsDataSendingInterval]="deviceParams.proprietaryParameters.gpsDataSendingInterval"></app-gpsdata-sending-interval-configuration-we-track2>
          <app-heart-beat-interval-configuration-we-track2 [heartbeatInterval]="deviceParams.proprietaryParameters.heartbeatInterval"></app-heart-beat-interval-configuration-we-track2>
          <app-hstconfiguration-we-track2 [hst]="deviceParams.proprietaryParameters.hst"></app-hstconfiguration-we-track2>
          <app-mileage-configuration-we-track2 [mileage]="deviceParams.proprietaryParameters.mileage"></app-mileage-configuration-we-track2>
          <app-power-alarm-configuration-we-track2 [powerAlarm]="deviceParams.proprietaryParameters.powerAlarm"></app-power-alarm-configuration-we-track2>
          <app-relay-configuration-we-track2 [relay]="deviceParams.proprietaryParameters.relay"></app-relay-configuration-we-track2>
          <app-server-configuration-we-track2 [server]="deviceParams.proprietaryParameters.server"></app-server-configuration-we-track2>
          <app-sosconfiguration-we-track2 [sos]="deviceParams.proprietaryParameters.sos"></app-sosconfiguration-we-track2>
          <app-static-data-filtering-configuration-we-track2 [staticDataFiltering]="deviceParams.proprietaryParameters.staticDataFiltering"></app-static-data-filtering-configuration-we-track2>
          <app-vibration-alarm-configuration-we-track2 [vibrationAlarm]="deviceParams.proprietaryParameters.vibrationAlarm"></app-vibration-alarm-configuration-we-track2>
          <app-sensor-set-configuration-we-track2 [sensorSet]="deviceParams.proprietaryParameters.sensorSet"></app-sensor-set-configuration-we-track2>
          <app-alarm-call-number-times-configuration-we-track2 [alarmCallNumberTimes]="deviceParams.proprietaryParameters.alarmCallNumberTimes"></app-alarm-call-number-times-configuration-we-track2>
          <app-alarm-over-speed-configuration-we-track2 [alarmOverSpeed]="deviceParams.proprietaryParameters.alarmOverSpeed"></app-alarm-over-speed-configuration-we-track2>
          <app-automatic-apnconfiguration-we-track2 [automaticApn]="deviceParams.proprietaryParameters.automaticApn"></app-automatic-apnconfiguration-we-track2>
          <app-center-phone-number-configuration-we-track2 [centerPhoneNumber]="deviceParams.proprietaryParameters.centerPhoneNumber"></app-center-phone-number-configuration-we-track2>        
          <app-external-voltage-upload-configuration-we-track2 [externalVoltageUpload]="deviceParams.proprietaryParameters.externalVoltageUpload"></app-external-voltage-upload-configuration-we-track2>
          <app-fence-alarm-configuration-we-track2 [fenceAlarm]="deviceParams.proprietaryParameters.fenceAlarm"></app-fence-alarm-configuration-we-track2>
          <app-gpsdistance-interval-configuration-we-track2 [gpsDistanceInterval]="deviceParams.proprietaryParameters.gpsDistanceInterval"></app-gpsdistance-interval-configuration-we-track2>          
          <app-network-link-coordinates-configuration-we-track2 [networkLinkCoordinates]="deviceParams.proprietaryParameters.networkLinkCoordinates"></app-network-link-coordinates-configuration-we-track2>
          <app-sensor-sensitivity-configuration-we-track2 [sensorSensitivity]="deviceParams.proprietaryParameters.sensorSensitivity"></app-sensor-sensitivity-configuration-we-track2>
          <app-accalarm-configuration-we-track2 [accAlarm]="deviceParams.proprietaryParameters.accAlarm"></app-accalarm-configuration-we-track2>
          <app-lbsupload-configuration-we-track2 [lbsUpload]="deviceParams.proprietaryParameters.lbsUpload"></app-lbsupload-configuration-we-track2>
          <app-location-data-upload-configuration-we-track2 [locationDataUpload]="deviceParams.proprietaryParameters.locationDataUpload"></app-location-data-upload-configuration-we-track2>         
        </p-accordion>
      </p-tabPanel>

      <p-tabPanel  header="Sensors">
        <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
        <!-- Sensors list -->
      </p-tabPanel>
      <p-tabPanel i18n-header header="Actuators">
        <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
    </p-tabPanel>
    </p-tabView>
  </p-panel>
  <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>
