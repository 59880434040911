<!-- TABLE SEND MESSAGE BUTTON -->
<button class='btn d-flex align-items-center gap-2 table-send-message-button'
		type='button'
		(click)="onClick()"
		[disabled]="isDisabled"
>
	<!-- BUTTON ICON -->
	<span class='d-flex align-items-center justify-content-center'>
    <svg-icon src='assets/icons/message.svg'
			  class='d-flex align-items-center justify-content-center'
			  [svgStyle]="{'height.px': 15, 'width.px': 22}"
	></svg-icon>
  </span>

	<!-- BUTTON TEXT -->
	<span class='ms-auto me-auto' i18n>
    {{ 'general.sendMessageButton' | translate }}
  </span>

</button>
