import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { environment } from "environments/environment";
import { NotificationsService, Severity } from "app/services/notifications-service/notifications.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CardTypeService extends EntityService<Rest.CardType> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient, private http: HttpClient, notificationsService: NotificationsService) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  createCardType(entity: string, logo?: any): Promise<Rest.CardType> {
    /* FORM DATA */
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("cardType", entity);

    return this.http
      .post(environment.webApiBaseUrl + 'api/inventory/cardtype', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });
  }

  updateCardType(cardType: string, logo?: any): Promise<Rest.CardType> {
    /* FORM DATA */
    const formData = new FormData();
    formData.append("cardType", cardType);

    if (logo != undefined && logo != null) {
      formData.append("logo", logo);
    }

    return this.http
      .post(environment.webApiBaseUrl + 'api/inventory/cardtype/update', formData)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.notificationsService.add(Severity.error, 'Error', error.message);
        return null;
      });
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.CardType>> {
    return this.client.CardType_getPage(arg0, queryParams);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.CardType_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.CardType> {
    return this.client.CardType_find(arg0, queryParams);
  }
}