import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { RestExt } from 'app/services/rest-client-extension';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { SubdivisionService } from 'app/rest/subdivision.service';
import { DivisionService } from 'app/rest/division.service';


@Component({
  selector: 'app-subdivisions-form',
  templateUrl: './subdivisions-form.component.html',
  styleUrls: ['./subdivisions-form.component.css'],
  providers: [SubdivisionService, DivisionService],
  encapsulation: ViewEncapsulation.None,
})
export class SubdivisionsFormComponent
  extends EntityFormComponent<Rest.Subdivision>
  implements OnInit {

  noticeChannels: SelectItem[];
  channelsList: Rest.NoticeChannel[];
  title: string;

  active: boolean;

  // Preservation of relationships
  divisionRequestContext: Rest.ListPaginationRequest;
  divisionList: Rest.Division[] = [];


  /*Permissions */
  disableSave: boolean;
  disabeChangeStatus: boolean;

  constructor(
    private objectService: SubdivisionService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public divisionService: DivisionService,
  ) {
    super(objectService, notificationsService, i18n, authenticationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Loads and evaluates all the necessary data before showing the form.
   *
   * @memberof SubdivisionsFormComponent
   */
  beforeShow() {
    this.active = !this.entity.disabled;
    this.divisionList = [];
    this.divisionRequestContext = RestExt.firstPageRequest();

    this.divisionRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
      filters:{}
    } as unknown as Rest.ListPaginationRequest;
    /*Permissions */
    if (this.isNew) {
      this.disableSave = false;
      this.title = this.translateService.instant('tableManagement.subdivisions.createSubdivision');
    } else {
      this.title = this.translateService.instant('tableManagement.subdivisions.editSubdivision');

      //#region PRESERVATION OF RELATIONSHIPS
      //DIVISION



      if(this.entity.division){
        this.divisionRequestContext.filters["name"] = [this.entity.division.name];
    }

      //#endregion PRESERVATION OF RELATIONSHIPS


      if (this.entity["_$update"] === true) {
        this.disableSave = false;
      } else {
        this.disableSave = true;
      }
      if (this.entity["_$changeStatus"] === true) {
        this.disabeChangeStatus = false;
      } else {
        this.disabeChangeStatus = true;
      }
    }

    
    this.divisionService.getPage(this.divisionRequestContext).then((p) => {
      this.divisionList = p.entities;
    });
  }

  onChangeDivision(event) {
    this.entity.division = event.value;
  }

  onFilterDivision(event) {
    this.divisionRequestContext = {
      sortBy: "name",
      sortAsc: true,
      pageSize: 10,
    } as unknown as Rest.ListPaginationRequest;

    this.divisionService.getPage(this.divisionRequestContext).then((p) => {
      this.divisionList = p.entities;
    });
  }
}