<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">
  <div class="container-md">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <!-- #FORM BODY -->
    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.codeBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="code" class="form-control custom-input" [(ngModel)]="entity.code" />
        </div>
      </div>


    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'general.name' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.name" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.populationBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.population" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.countryBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.country" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.provinceBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.province" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.postalCodeBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.postalCode" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.addressBa' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.address" />

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col d-flex align-items-center">
        <label for="vehicle-data-settings-name" class="custom-label" i18n>
          {{'control-panel.clients.cif' | translate}}
        </label>
      </div>
      <div class="col">
        <div class="float-end">

          <input pInputText id="description" class="form-control custom-input" [(ngModel)]="entity.cif" />

        </div>
      </div>
    </div>


    <!-- ACTIVE -->
    <div *ngIf="!isNew">
      <div class="row mt-3">
        <div class="col d-flex align-items-center">
          <label for="vehicle-data-settings-name" class="custom-label" i18n>
            {{'general.active' | translate}}
          </label>
        </div>

        <div class="col">
          <div class="float-end">
            <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
              type="checkbox" [(ngModel)]="entity.enabled" [disabled]="true" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <div class="d-flex justify-content-center bd-highlight">
      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.save' | translate}}
          </button>
        </div>
        <div *ngIf="isNew">
          <button class="btn custom-button" (click)="save()" i18n>
            {{'general.create' | translate}}
          </button>
        </div>
      </div>
      <div class="p-2 bd-highlight">
        <div *ngIf="!isNew">
          <button class="btn custom-button" (click)="changeStatus(!entity.enabled)" i18n>
            {{'general.changeStatus' | translate}}
          </button>
        </div>
      </div>
    </div>
  </p-footer>

</p-dialog>