import {
  Component,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
} from "@angular/core";
import { Configuration } from "../models/Configuration";
import { I18nService } from "app/services/i18n/i18n.service";
import { ExcelService } from "app/services/excel-service/excel.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-ute-panel-table",
  templateUrl: "./ute-panel-table.component.html",
  styleUrls: ["./ute-panel-table.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [ExcelService, DatePipe],

  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtePanelTableComponent implements OnInit, OnChanges {
  @Output("controlPointClicked") controlPointClickedEvent =
    new EventEmitter<any>();
  @Output("vehicleClicked") vehicleClickedEvent = new EventEmitter<any>();
  @Output() focusOnControlPointClicked = new EventEmitter<number>();

  @Input() showMapMode;
  // Contains the id of the header that is sortedby
  sortField: string; // ="lastMaintenanceDate";
  // Sort order as number, 1 for asc and -1 for dec in single sort mode
  sortOrder: number; // = 1;
  //expandedHeaders
  expandedHeaders = true;

  columnsSize: number = 0;

  // Table columns
  cols: any[] = [
    {
      header: "Punt de control",
      shortHeader: "Punt Control",
      field: "controlPoint",
      visible: true,
      filter: false,
    },
    {
      header: "Data últim manteniment",
      shortHeader: "Data últim m",
      field: "lastMaintenanceDate",
      visible: true,
      filter: false,
    },
    {
      header: "Temps restant de manteniment",
      shortHeader: "Temps restant m",
      field: "maintenanceLeftTime",
      visible: true,
      filter: false,
    },

    {
      header: "Data última vigilancia",
      shortHeader: "Data últim v",
      field: "lastVigilanceDate",
      visible: true,
      filter: false,
    },
    {
      header: "Temps restant de vigilancia",
      shortHeader: "Temps restant v",
      field: "vigilanceLeftTime",
      visible: true,
      filter: false,
    },

    {
      header: this.i18n._("Minor Time Remaining"),
      shortHeader: this.i18n._("T Remaining"),
      field: "minorTimeRemaining",
      visible: true,
      filter: false,
    },
    /*{
      header: "Enter Zone Date",
      shortHeader: "E zone Date",
      field: "enterZoneDate",
      visible: true,
      filter: false,
    },
    {
      header: "Leave Zone Date",
      shortHeader: "L zone Date",
      field: "leaveZoneDate",
      visible: true,
      filter: false,
    },
    {
      header: "Duration Time In Zone",
      shortHeader: "d time in zone",
      field: "durationTimeInZone",
      visible: true,
      filter: false,
    },
    {
      header: "Mileage In Zone",
      shortHeader: "m in zone",
      field: "mileageInZone",
      visible: true,
      filter: false,
    },*/
    {
      header: "Vehicle",
      shortHeader: "Vehicle",
      field: "vehicle",
      visible: true,
      filter: false,
    },
  ];

  colsString: any[] = [
    "Punt de control",
    "Data últim manteniment",
    "Temps restant de manteniment",
    "Data última vigilancia",
    "Temps restant de vigilancia",
    "Minor Time Remaining",
    "Vehicle",
  ];

  @Input() rows: any[];
  configuration: Configuration = null;

  constructor(
    protected i18n: I18nService,
    private excelService: ExcelService,
    private dp: DatePipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    /** Secure the rows atribute is the one changing */
    if (changes.rows) {
      this.sortRows();
    }
  }

  ngOnInit() {
    //this.loadHeaders(this.configuration)// de moment esta aki perque com que no esperem a rebre del ws no li donave temps a arrivar les canfigs desde padre
  }

  /**
   * Used By father component to refresh the headers when a config is loaded
   * The method setup the headers of the table
   * @param configuration new configuration recieved
   */

  public loadHeaders(configuration: Configuration) {
    this.configuration = configuration;
    let someHeaderIsSort = false;

    if (this.configuration !== null && this.configuration !== undefined) {
      this.configuration.columns.forEach((confColumn) => {
        //Colum not found
        if (!this.cols.find((col) => col.field === confColumn.id)) return;
        // Load Visivility
        this.cols.find((col) => col.field === confColumn.id).visible =
          confColumn.visible;
        this.cols.find((col) => col.field === confColumn.id).filter =
          confColumn.filter.active;
        this.columnsSize = this.cols.filter((col) => col.visible === true).length + 2;

        if (confColumn.order.active) {
          // IF ORDER IS ACTIVED
          someHeaderIsSort = true;
          this.sortField = confColumn.id; // We store the id to sort by it

          if (confColumn.order.asc) {
            //event.sortOrder = Sort order as number, 1 for asc and -1 for dec in single sort mode
            this.sortOrder = 1;
          } else {
            this.sortOrder = -1;
          }
        }
      });

      this.setUpHeadersFormat();
      this.sortRows();
    }

    /* If no one of the headears are sorted*/
    if (!someHeaderIsSort) {
      this.sortField = null;
    }
  }

  public setUpHeadersFormat() {
    if (!this.configuration.showMap) {
      this.expandedHeaders = true;
      return;
    }
    const visibleCols = this.cols.filter((col) => col.visible === true);

    if (visibleCols.length > 3) {
      this.expandedHeaders = false;
    } else {
      this.expandedHeaders = true;
    }
  }

  resolveFieldData(data: any, field: string): any {
    if (data && field) {
      switch (field) {
        case "controlPoint":
          return data.controlPoint.name;
        case "lastMaintenanceDate":
          return this.dp.transform(data.lastMaintenanceDate, "short");
        case "lastVigilanceDate":
          return this.dp.transform(data.lastVigilanceDate, "short");
        case "maintenanceLeftTime":
          return this.dp.transform(data.maintenanceLeftTime, "short");
        case "minorTimeRemaining":
          return this.dp.transform(data.minorTimeRemaining, "short");
        case "enterZoneDate":
          return this.dp.transform(data.enterZoneDate, "short");
        case "leaveZoneDate":
          return this.dp.transform(data.leaveZoneDate, "short");
        case "durationTimeInZone":
          return this.dp.transform(data.durationTimeInZone, "short");
        case "mileageInZone":
          return this.dp.transform(data.mileageInZone, "short");
        case "vehicle":
          return data.vehicle.name;
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  rowsExcel: any[];
  rowObject: any;

  exportExcel(name) {
    this.rowsExcel = [];
    for (let i = 0; i < this.rows.length; i++) {
      this.rowObject = {};
      for (let j = 0; j < this.cols.length; j++) {
        let column = this.cols[j];
        if (column.field && column.visible) {
          this.rowObject[column.field] = this.resolveFieldData(
            this.rows[i],
            column.field
          );
        }
      }
      this.rowsExcel[i] = this.rowObject;
    }

    this.excelService.exportAsExcelFile(this.rowsExcel, name);
  }

  testexceldata: any = [
    {
      eid2: "e101",
      ename: "ravi",
      esal: 1000,
    },
    {
      eid2: "e102",
      ename: "ram",
      esal: 2000,
    },
    {
      eid2: "e103",
      ename: "rajesh",
      esal: 3000,
    },
  ];

  exportExcel2() {
    //    this.getColumns();
    //const doc = new jsPDF();
    /* let rowsExcel = [];

      for (let i = 0; i < this.rows.length ; i++) {
         for (let j = 0; j < this.colsString.length ; j++) {
            rowsExcel[i][j] = this.resolveFieldData(this.rows[i], this.colsString[j])
          }
                }

      doc.autoTable(this.colsString, rowsExcel);
      doc.save('Test.pdf');*/
  }

  focusButton(pocId: number) {
    this.focusOnControlPointClicked.emit(pocId);
  }

  public sortRows(): void {
    if (this.sortField == null) {
      return;
    }

    if (this.rows.length > 0) {
      if (this.sortField == "controlPoint") {
        this.rows.sort((a, b) =>
          this.Sort(a.controlPoint.name, b.controlPoint.name, false)
        );
      } else if (this.sortField == "lastMaintenanceDate") {
        this.rows.sort((a, b) =>
          this.Sort(a.lastMaintenanceDate, b.lastMaintenanceDate, true)
        );
      } else if (this.sortField == "lastVigilanceDate") {
        this.rows.sort((a, b) =>
          this.Sort(a.lastVigilanceDate, b.lastVigilanceDate, true)
        );
      } else if (this.sortField == "maintenanceLeftTime") {
        this.rows.sort((a, b) =>
          this.Sort(a.maintenanceLeftTime, b.maintenanceLeftTime, false)
        );
      } else if (this.sortField == "vigilanceLeftTime") {
        this.rows.sort((a, b) =>
          this.Sort(a.vigilanceLeftTime, b.vigilanceLeftTime, false)
        );
      } else if (this.sortField == "minorTimeRemaining") {
        this.rows.sort((a, b) =>
          this.Sort(a.minorTimeRemaining, b.minorTimeRemaining, false)
        );
      } else if (this.sortField == "enterZoneDate") {
        this.rows.sort((a, b) =>
          this.Sort(a.enterZoneDate, b.enterZoneDate, false)
        );
      } else if (this.sortField == "leaveZoneDate") {
        this.rows.sort((a, b) =>
          this.Sort(a.leaveZoneDate, b.leaveZoneDate, false)
        );
      } else if (this.sortField == "durationTimeInZone") {
        this.rows.sort((a, b) =>
          this.Sort(a.durationTimeInZone, b.durationTimeInZone, false)
        );
      } else if (this.sortField == "mileageInZone") {
        this.rows.sort((a, b) =>
          this.Sort(a.mileageInZone, b.mileageInZone, false)
        );
      } else if (this.sortField == "vehicle") {
        this.rows.sort((a, b) =>
          this.Sort(a.vehicle.name, b.vehicle.name, false)
        );
      } else if (this.sortField == "group") {
        this.rows.sort((a, b) => this.Sort(a.group.name, b.group.name, false));
      }
      //force the datatable to refresh
      this.rows = [...this.rows];
    }
  }

  private Sort(a, b, isDate): number {
    let x;
    if (a < b) {
      x = -1;
    } else if (a > b) {
      x = 1;
    } else {
      x = 0;
    }

    if (this.sortOrder === 1) {
      x = x * -1;
    }

    //If its a date is orderer in the oposite way
    if (isDate) {
      x = x * -1;
    }

    return x < 0 ? -1 : x > 0 ? 1 : 0;
  }

  public controlPointClicked(row) {
    this.controlPointClickedEvent.emit(row);
  }

  public vehicleClicked(row) {
    this.vehicleClickedEvent.emit(row);
  }
  /**
   * Refresh the style of each row
   * @param rowData table row
   */
  onRowRender(rowData: any): string {
    return rowData.color;
  }
}
