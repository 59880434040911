import { Component, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService } from "primeng/api";
import { ConversionalgorithmService } from "../../../rest/conversionalgorithm.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { ConversionAlorithmFormComponent } from "../conversion-algorithm-form/conversion-algorithm-form-component";

@Component({
    selector: "app-rule-list",
    templateUrl: "./conversion-algorithm-list.component.html",
    styleUrls: ["./conversion-algorithm-list.component.css"],
    providers: [ConversionalgorithmService, ConfirmationService, UserTimePipe],
})
export class ConversionAlogrithmListComponent extends EntityListComponent<Rest.ConversionAlgorithm> {
    @ViewChild(ConversionAlorithmFormComponent, {static: true})
    conversionAlgorithmForm: ConversionAlorithmFormComponent;

    constructor(
        private conversionAlgorithmService: ConversionalgorithmService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe
    ) {
        super(
            conversionAlgorithmService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.canBeEnabled = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.i18n._("Conversion Algorithms");
        this.fetchEntityBeforeShowUpdateForm = true;
        this.form = this.conversionAlgorithmForm;
        this.hasEntityFilters = false;
    }

    afterLoad() {
        this.paginationResult.entities.forEach((ca: any) => {
            ca._$status_str = true;

            /*Permissions */
            ca._$changeStatus = true;
            ca._$update = true;
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
    }
}
