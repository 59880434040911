import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationNavigationService {

  //ROUTES VARIABLES
  private fromNotificationPanel = false;

  // PANEL VISIBILITY VARIABLES
  private showNotificationsSubject = new BehaviorSubject<boolean>(false);
  showNotifications$ = this.showNotificationsSubject.asObservable();

  // INCIDENCES LIST BOOLEAN STATE VARIABLES
  private hasRemainingIncidencesSubject = new BehaviorSubject<boolean>(false);
  hasRemainingIncidences$ = this.hasRemainingIncidencesSubject.asObservable();

  constructor() { }

  // ROUTES METHODS
  setFromNotificationPanel(value: boolean): void {
    this.fromNotificationPanel = value;
  }

  isFromNotificationPanel(): boolean {
    return this.fromNotificationPanel;
  }

  toggle() {
    this.showNotificationsSubject.next(!this.showNotificationsSubject.value);
  }

  // PANEL VISIBILITY METHODS
  show() {
    this.showNotificationsSubject.next(true);
  }

  hide() {
    this.showNotificationsSubject.next(false);
  }

  // INCIDENCES LIST BOOLEAN STATE METHODS
  setHasRemainingIncidences(value: boolean): void {
    this.hasRemainingIncidencesSubject.next(value);
  }

  hasIncidences(): boolean {
    return this.hasRemainingIncidencesSubject.value;
  }

}
