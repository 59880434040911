import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { FineStatusService } from "app/modules/vehicle-inventory/services/table-management/fineStatus.service";
import { FineStatusComponent } from "../fineStatus-form/fineStatus-form.component";
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";
import FineStatus = Rest.FineStatus;

@Component({
    selector: 'app-finestatus-list',
    templateUrl: './fineStatus-list.component.html',
    styleUrls: ['./fineStatus-list.component.css'],
    providers: [FineStatusService, ExcelService],
})
export class FineStatusListComponent extends EntityListComponent<FineStatus>
    implements OnInit {

    @ViewChild(FineStatusComponent, { static: true })
    editForm: FineStatusComponent;

    title: string = this.i18n._("Fines");
    disableSave: boolean;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    constructor(
        private fineStatusService: FineStatusService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        protected translateService: TranslateService,
    ) {
        super(
            fineStatusService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.editForm;
        this.hasEntityFilters = false;
        this.user = this.authenticationService.user;
    }

    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
        this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }
    
    excelColumns = {};
    exportExcel(){
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.subdivisions.subdivisionsManagement');
        super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
    }
    setExcelColumns() {
        const name = (entity: any) => entity.name ?? '' 
        const code = (entity: any) => entity.code ?? '' 
        const disabled = (entity: any) => entity.disabled ?? '' 
        this.excelColumns = {
            [this.translateService.instant('general.code')]: { bindField: name },
            [this.translateService.instant('general.name')]:  { bindField: code },  
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
           }
      }
}