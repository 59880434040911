import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectItem } from "primeng/api";

import { Rest } from "../../rest/rest_client";
import { I18nService } from "../../services/i18n/i18n.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "paged-search-dropdown",
  templateUrl: "./paged-search-dropdown.html",
  styleUrls: ["./paged-search-dropdown.css"],
})
export class PagedSearchDropdown<T> implements OnInit {
  /**
   * sample usage:
   *
   * <paged-search-dropdown [pageFunction]="this.profileService.getPage.bind(this.profileService)" (selectedEntityChange)="searchChanged($event)" [(selectedEntity)]="selectedProfile" [labelProperty]="name" ></paged-search-dropdown>
   *
   * or
   *
   * <paged-search-dropdown [pageFunction]="this.profileService.getPage.bind(this.profileService)" [(selectedEntity)]="selectedProfile" [labelProperty]="name" ></paged-search-dropdown>
   *
   * or
   *
   *
   *   <paged-search-dropdown [pageFunction]="this.deviceService.getPage.bind(this.deviceService)" [(selectedEntity)]="vehicle.device" [paginationRequest]="{
          sortBy: 'imei',
          sortAsc: true,
          pageSize: 10,
          filters: {}
        }" [labelProperty]="imei" ></paged-search-dropdown>
   */

  /**Optional pagination requests object containing some pre-set filters and sort options. Please note that the filter corresponding to labelProperty will be overriden   */
  @Input() paginationRequest: Rest.ListPaginationRequest;
  /*the user input text will be sent to the pageFunction using this filter dictionary key*/

  @Input() labelProperty: string;
  @Input() labelType: string;
  //** */
  /**function that implements the pagging
   *
   example:

  [pageFunction]="this.profileService.getPage.bind(this.profileService)"

  this.profileService refers to an atribute of the component using the paged-search-dropdown component
  .bind(xxx) is used to solve the problem regardin the "this" keyword used inside the function
   *
   *
   */
  @Input() pageFunction: (
    req: Rest.ListPaginationRequest
  ) => Rest.RestResponse<Rest.Page<any>>;

  @Input() disabled: boolean = false;
  @Input() tooltip: string;

  /**event that indicates a change in the selected entity. The subscribers will recive the current selected entity as a parameter
   *
   * example:
   *
  searchChanged(item:any)
  {
    console.log("Hey! i'm the login form and the entity seected is "+item);
  }
   *
  */
  @Output() protected selectedEntityChange: EventEmitter<any> =
    new EventEmitter();
  /**Optional default selected entity */
  _selectedEntity: any = null;
  @Input()
  set selectedEntity(selectedEntity: any) {
    this._selectedEntity = selectedEntity;
    if (this._selectedEntity) {
      if (this.containsProperty(this._selectedEntity, "_$labelProperty")) {
        this.searchText = this._selectedEntity["_$labelProperty"];
        this.availableItems = [];
        this.availableItems.push({
          label: this._selectedEntity["_$labelProperty"],
          value: this._selectedEntity,
        });
      } else {
        if (this.labelProperty != undefined) {
          if (this.labelProperty.indexOf("|") >= 0) {
            let a;
            var splitted = this.labelProperty.split("|");

            for (a = 0; a < splitted.length; a++) {
              if (a == 0) {
                this._selectedEntity._$labelProperty =
                  this._selectedEntity[splitted[a].trim()] == null
                    ? "" + " | "
                    : this._selectedEntity[splitted[a].trim()] + " | ";
              }
              if (a < splitted.length - 1 && a > 0) {
                this._selectedEntity._$labelProperty =
                  this._selectedEntity[splitted[a].trim()] == null
                    ? this._selectedEntity._$labelProperty + " | "
                    : this._selectedEntity._$labelProperty +
                    this._selectedEntity[splitted[a].trim()] +
                    " | ";
              }
              if (a == splitted.length - 1) {
                this._selectedEntity._$labelProperty =
                  this._selectedEntity[splitted[a].trim()] == null
                    ? this._selectedEntity._$labelProperty
                    : this._selectedEntity._$labelProperty +
                    this._selectedEntity[splitted[a].trim()];
              }
            }

            this.searchText = this._selectedEntity["_$labelProperty"];
            this.availableItems = [];
            this.availableItems.push({
              label: this._selectedEntity["_$labelProperty"],
              value: this._selectedEntity,
            });
          } else {
            this.searchText = this._selectedEntity[this.labelProperty];
            this.availableItems = [];
            this.availableItems.push({
              label: this._selectedEntity[this.labelProperty],
              value: this._selectedEntity,
            });
          }
        } else {
          this.searchText = "";
          this.availableItems = [];
        }
      }
    } else {
      this.searchText = "";
      this.availableItems = [];
    }
  }
  get selectedEntity() {
    return this._selectedEntity;
  }

  public selectedItem: SelectItem;
  availableItems: SelectItem[];
  private availableEntities: any[];
  hasMore: boolean;
  showResults: boolean;
  searchText: string;
  hovering: boolean;
  constructor(protected i18n: I18nService) { }

  search(newValue) {
    this.hasMore = false;
    this.paginationRequest.offset = 0;
    this.paginationRequest.pageSize = 10;
    this.paginationRequest.filters[this.labelProperty] = [];
    this.paginationRequest.filters[this.labelProperty].push(newValue);
    this._selectedEntity = null;
    this.selectedItem = null;
    this.selectedEntityChange.emit(this._selectedEntity);
    this.availableItems = [];
    this.availableEntities = [];

    this.selectedEntity = null;
    this.callWS();
  }
  close(e: Event) {
    this.showResults = false;
    if (!this._selectedEntity) {
      this.searchText = "";
    }
  }
  itemSelected(item: SelectItem) {
    this.selectedItem = item;
    this.showResults = false;
    this.searchText = item.label;
    this._selectedEntity = this.selectedItem.value;
    this.selectedEntityChange.emit(this._selectedEntity);
  }

  containsProperty(object: any, property: string): boolean {
    if (object == null) {
      return false;
    }

    for (const p in object) {
      if (p == property) {
        return true;
      }
    }
    return false;
  }

  private callWS(): void {

    var splitted;
    let a;
    const that = this;
    this.paginationRequest.loadPermissions = false;
    this.pageFunction(this.paginationRequest).then(function (response) {
      that.showResults = true;
      that.availableEntities = response.entities;

      that.availableEntities.forEach((e: any) => {
        e._$labelProperty = "";
        if (that.labelProperty.indexOf("|") >= 0) {
          var splitted = that.labelProperty.split("|");

          for (a = 0; a < splitted.length; a++) {
            if (a == 0) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? " " + " | "
                  : e._$labelProperty + e[splitted[a].trim()] + " | "; //e[splitted[a].trim()] + ' | ';
            }
            if (a < splitted.length - 1 && a > 0) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? e._$labelProperty + " | "
                  : e._$labelProperty + e[splitted[a].trim()] + " | ";
            }
            if (a == splitted.length - 1) {
              e._$labelProperty =
                e[splitted[a].trim()] == null
                  ? e._$labelProperty
                  : e._$labelProperty + e[splitted[a].trim()];
            }
          }
        } else {
          e._$labelProperty = e[that.labelProperty];
        }

        if (that.labelType == "driver") {
          e._$labelProperty = e.user.name;
        }
      });



      that.hasMore = response.filteredEntities > response.entities.length;
      that.availableItems = that.availableEntities.map((e: any) => ({
        label: e._$labelProperty,
        value: e,
      }));

    });

  }

  loadMore() {
    this.hasMore = false;
    this.paginationRequest.pageSize += 10;
    this._selectedEntity = null;
    this.selectedItem = null;
    this.availableItems = [];
    this.availableEntities = [];

    this._selectedEntity = null;
    this.callWS();
  }

  ngOnInit() {
    if (this.labelProperty === undefined || this.labelProperty === null) {
      this.labelProperty = "name";
    }

    if (this.paginationRequest == null) {
      this.paginationRequest = {
        sortBy: this.labelProperty,
        sortAsc: true,
        pageSize: 10,
        filters: {},
      } as Rest.ListPaginationRequest;

      this.searchText = "";
      if (this._selectedEntity) {
        this.searchText = this._selectedEntity[this.labelProperty];
        this.availableItems = [];
        this.availableItems.push({
          label: this._selectedEntity[this.labelProperty],
          value: this._selectedEntity,
        });
      }
    }


    if (this._selectedEntity) {
      //new change
      this.itemSelected({
        label: this._selectedEntity[this.labelProperty],
        value: this._selectedEntity,
      });
    }

    if (this.labelType == "driver") {
      this.itemSelected({
        label: this._selectedEntity.user.name,
        value: this._selectedEntity,
      });
    }

  }
}
