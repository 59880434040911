<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">BATTERY ALARM
          (BATALM)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="batteryAlarm.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" #ContentDiv>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Battery Alarm Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="BatteryAlarmOptions" [(ngModel)]="batteryAlarm.modeAlarm" [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>

</p-accordionTab>