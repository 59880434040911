import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { Rest } from '../../../../rest/rest_client';
import { I18nService } from '../../../../services/i18n/i18n.service';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
    selector: 'app-sos-event-form',
    templateUrl: './sos-event-form.component.html',
    styleUrls: ['./sos-event-form.component.css'],

    encapsulation: ViewEncapsulation.None,
})
export class SosEventFormComponent extends ServerValidatedFormComponent implements OnInit {

    constructor(authenticationService: AuthenticationService, i18n: I18nService) {
        super(i18n);
    }

    @Input() sosEvent: Rest.SOSEventConfiguration;

    ngOnInit() {
    }

    /**
     * Needed to prevent toggle of UI component
     * @param event
     */
    handleChange(event) {
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();

    }

}
