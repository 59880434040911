import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { MileageService } from 'app/modules/vehicle-inventory/services/table-management/mileage.service';
import { last } from 'rxjs/operators';

@Component({
    selector: 'app-mileage-form',
    templateUrl: './mileage-form.component.html',
    styleUrls: ['./mileage-form.component.css'],
    //providers: [MileageService],
    encapsulation: ViewEncapsulation.None,
})
export class MileageFormComponent
    extends EntityFormComponent<Rest.Mileage>
    implements OnInit {

    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;

    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    readingDate: Date;
    lastDate: Date;
    newDate: Date;

    lastKM: number;

    @Input() vehicle: Rest.Vehicle;

    // PERMISSIONS
    canEdit: boolean = false;

    constructor(
        private objectService: MileageService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
    ) {
        super(objectService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.setPermissions();
    }

    setPermissions() {
        const user = this.authenticationService.user;
        const rolesForForm = ['INV_CONTROL_KM_CREATE', 'INV_CONTROL_KM_UPDATE'];

        this.canEdit = this.authenticationService.isOneRoleGranted(rolesForForm, user);
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof MileageFormComponent
     */
    beforeShow() {
        this.active = !this.entity.disabled;

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.mileage.mileage');

            this.entity.vehicle = this.vehicle;

            this.lastDate = new Date(this.entity.vehicle.lastManualKMUpdate);

            if (this.entity.vehicle.km) {
                this.lastKM = this.entity.vehicle.km;
            }
        } else {
            this.title = this.translateService.instant('tableManagement.mileage.mileage');

            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }

            this.readingDate = new Date(this.entity.readingDate);
            this.lastDate = new Date(this.entity.vehicle.lastManualKMUpdate);

            this.lastKM = this.entity.vehicle.km;
        }

        if (this.vehicle) {
            this.title = this.vehicle.name + ' ' + this.vehicle.model?.brand?.name + ' - ' + this.vehicle.model?.name + ' - ' + this.vehicle.version?.name;
        }
    }

    saveChanges() {
        this.entity.readingDate = this.readingDate;

        this.entity.vehicle.lastManualKMUpdate = this.readingDate.getTime();
        // this.entity.lastManualKMUpdate = this.newDate.getUTCMilliseconds();

        if (this.isNew) {

            let objectString = {
                id: this.entity.id,
                vehicle: { id: this.entity.vehicle.id, lastManualKMUpdate: this.entity.vehicle.lastManualKMUpdate },
                type: "CO",
                readingDate: this.entity.readingDate,
                kms: this.entity.kms,
                client: {
                    id: this.authenticationService.user.client.id,
                },
                disabled: false,
                // createUser: this.entity.createUser,
                // createDate: this.entity.createDate,
                // disabledUser: this.entity.disabledUser,
                //lastManualKMUpdate: this.entity.lastManualKMUpdate,
            };

            console.log('+++ create objectString', objectString);


            this.objectService
                .create(objectString)
                .then((p) => {
                    this.display = false;
                    this.return.emit(p);
                });
        } else {
            console.log('+++ entityt', this.entity);

            let objectString = {
                id: this.entity.id,
                vehicle: { id: this.entity.vehicle.id, lastManualKMUpdate: this.entity.vehicle.lastManualKMUpdate },
                type: this.entity.type,
                readingDate: this.entity.readingDate,
                kms: this.entity.kms,
                // consumption: { id: this.entity.consumption.id },
                client: this.entity.client,
                disabled: this.entity.disabled,
                // disabledDate: this.entity.disabledDate,
                // createUser: this.entity.createUser,
                // createDate: this.entity.createDate,
                // updateUser: this.entity.updateUser,
                // updateDate: this.entity.updateDate,
                // disabledUser: this.entity.disabledUser,
                //lastManualKMUpdate: this.entity.lastManualKMUpdate,
            };

            console.log('+++ objectString', objectString);

            this.objectService
                .update(objectString)
                .then((p) => {
                    this.display = false;
                    this.return.emit(p);
                });
        }
    }
}
