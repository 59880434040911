import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { FueltypeService } from 'app/modules/vehicle-inventory/services/fueltype.service';
import { IconService } from 'app/rest/icon.service';
import { Rest } from 'app/rest/rest_client';
import { VehicleService } from 'app/rest/vehicle.service';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { VehiclemodelService } from 'app/rest/vehiclemodel.service';
import { VehicletypeService } from 'app/rest/vehicletype.service';
import { VehicleversionService } from 'app/rest/vehicleversion.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { RestExt } from 'app/services/rest-client-extension';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-vehicle-version-form',
    templateUrl: './vehicle-version-form.component.html',
    styleUrls: ['./vehicle-version-form.component.css'],
    providers: [VehicleversionService, VehiclebrandService, VehicletypeService, VehiclemodelService],
})
export class VehicleVersionFormComponent extends EntityFormComponent<Rest.VehicleVersion> implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    availableTypes: SelectItem[];
    availableBrands: SelectItem[];
    availableModels: SelectItem[];
    title: string;
    /*Permissions */
    disableSave: boolean;

    typeRequestContext: Rest.ListPaginationRequest;
    brandRequestContext: Rest.ListPaginationRequest;
    modelRequestContext: Rest.ListPaginationRequest;
    client: Rest.Client;

    selectedType: Rest.VehicleType;
    selectedBrand: Rest.VehicleBrand;
    selectedModel: Rest.VehicleModel;

    @Input() optionsFuelType: SelectItem[];

    brandList: Rest.VehicleBrand[] = [];
    vehicleTypeList: Rest.VehicleType[] = [];
    modelList: Rest.VehicleModel[] = [];

    constructor(
        private vehicleVersionService: VehicleversionService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        public translateService: TranslateService,
        public typeService: VehicletypeService,
        public brandService: VehiclebrandService,
        public modelService: VehiclemodelService,
    ) {
        super(vehicleVersionService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeRequestContext = RestExt.allRecordsRequest();
        this.brandRequestContext = RestExt.allRecordsRequest();
        this.modelRequestContext = RestExt.allRecordsRequest();
        this.client = this.authenticationService.user.client;
        this.brandRequestContext.filters["client"] = [this.client.id.toString()];
        this.modelRequestContext.filters["client"] = [this.client.id.toString()];
        this.brandRequestContext.filters["disabled"] = ["false"];
        this.modelRequestContext.filters["disabled"] = ["false"];
        this.getVehicleTypeList();
    }

    /**
    * Loads and evaluates all the necessary data before showing the form.
    *
    * @memberof ModelFormComponent
    */
    beforeShow() {
        this.selectedType = null;
        this.selectedBrand = null;
        this.selectedModel = null;

        //this.brandRequestContext.filters["client"] = [this.client.id.toString()];
        //this.modelRequestContext.filters["client"] = [this.client.id.toString()];
        if (this.isNew) {
            this.selectedType = this.vehicleTypeList.find(t => t.name == "Car");
            this.title = this.translateService.instant('tableManagement.versions.createVersion');
        } else {

            this.title = this.translateService.instant('tableManagement.versions.editVersion');
            this.selectedType = this.entity.model.type;
            this.selectedBrand = this.entity.model.brand;
            this.selectedModel = this.entity.model;

            this.modelRequestContext.filters["brand.name"] = [this.selectedBrand.name];
            this.getModelList();
        }

        this.brandRequestContext.filters["type"] = [this.selectedType.id.toString()];
        this.getBrandList();

        console.log('+++ entity', this.entity);
    }

    protected beforeSave(): void {
        this.entity.client = this.client;
        this.entity.model = this.selectedModel;
    }

    typeChanged(type) {
        this.brandRequestContext.filters["type"] = [type.value.id.toString()];
        this.getBrandList();
        this.selectedBrand = null;
        this.selectedModel = null;
    }

    brandChanged(brand) {
        this.modelRequestContext.filters["brand.name"] = [brand.value.name];
        this.getModelList();
        this.selectedModel = null;
    }

    getVehicleTypeList() {
        var that = this;
        this.typeService
            .getPage(that.typeRequestContext)
            .then(
                function (response) {
                    that.vehicleTypeList = response.entities;
                    that.vehicleTypeList.sort((a, b) => a.name > b.name ? 1 : -1);
                },
                /* failure */
                function (error) {
                    console.log('The request failed: ' + error);

                }
            )
            .catch(function (reason) {
                console.log('Catched: ' + reason);
            });
    }

    getBrandList() {
        var that = this;
        this.brandService
            .getPage(that.brandRequestContext)
            .then(
                function (response) {
                    that.brandList = response.entities;
                    that.brandList.sort((a, b) => a.name > b.name ? 1 : -1);
                },
                /* failure */
                function (error) {
                    console.log('The request failed: ' + error);

                }
            )
            .catch(function (reason) {
                console.log('Catched: ' + reason);
            });
    }

    getModelList() {
        var that = this;
        this.modelService
            .getPage(that.modelRequestContext)
            .then(
                function (response) {
                    that.modelList = response.entities;
                    that.modelList.sort((a, b) => a.name > b.name ? 1 : -1);
                },
                /* failure */
                function (error) {
                    console.log('The request failed: ' + error);

                }
            )
            .catch(function (reason) {
                console.log('Catched: ' + reason);
            });
    }

    saveChanges() {
        this.messages = [];

        this.entity.client = this.authenticationService.user.client;

        const that = this;
        if (this.isNew) {
            this.vehicleVersionService
                .create(this.entity)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.vehicleVersionService
                .update(this.entity)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }
}