import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-table-change-status-button',
  templateUrl: './table-change-status-button.component.html',
  styleUrls: ['./table-change-status-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableChangeStatusButtonComponent {
  @Input() isDisabled = false;
  @Output() btnClick = new EventEmitter();

  constructor() {
  }

  onClick() {
    this.btnClick.emit();
  }

}
