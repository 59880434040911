import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { environment } from "environments/environment";
import { NotificationsService, Severity } from "app/services/notifications-service/notifications.service";
import { HttpClient } from "@angular/common/http";
import { RestVehicleInventory } from "../rest_vehicle-inventory";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
 })
export class TaxService extends EntityService<Rest.Tax> {
    client: Rest.RestApplicationClient;
    refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient, private http: HttpClient, notificationsService: NotificationsService) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Tax): Rest.RestResponse<Rest.Tax> {
        try {
            return this.client.Tax_create(arg0);
        } catch (error) {
            console.error("Error creating Tax:", error);
            throw error;
        }
    }

    update(arg0: Rest.Tax): Rest.RestResponse<Rest.Tax> {
        return this.client.Tax_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Rest.Tax>> {
        return this.client.Tax_getPage(arg0, queryParams);
    }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Tax>> {
        return this.client.Tax_getPageAndFilters(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        return this.client.Tax_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Tax> {
        return this.client.Tax_find(arg0, queryParams);
    }

   //  createDocument(taxId: number, vehicle: Rest.Vehicle, document?: any): Rest.RestResponse<Rest.Tax> {
   //      const formData = new FormData();
   //      formData.append('documentFile', document);
   //      formData.append('documentBody', JSON.stringify(document));

   //      return this.http
   //          .post(environment.webApiBaseUrl + 'api/inventory/taxes/add?taxId=' + taxId, formData)
   //          .toPromise()
   //          .then((response: any) => {
   //              return response;
   //          })
   //          .catch((error) => {
   //              this.notificationsService.add(Severity.error, 'Error', error.message);
   //              return null;
   //          });
   //  }

	 createDocument(taxId: number, documentId: number): Rest.RestResponse<Rest.Tax> {
		console.log("[TAX-SERVICE] createDocument ***");
		console.log(taxId);
		console.log(documentId);
		let queryParams = {
			taxId: taxId,
			documentId: documentId
		}
		return this.client.Tax_addDocument(queryParams);
  }
}
