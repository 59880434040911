import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ConfirmationService, LazyLoadEvent, SelectItem } from "primeng/api";

import { Rest } from "../../../rest/rest_client";
import { RealtimeService } from "../../../rest/realtime.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService, Severity, } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { Rest_logistics } from "app/modules/task/Logistics/services/rest_client_logistics";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";
import { SidePanelComponent } from "../side-panel/side-panel.component";
import { VehiclePanelComponent } from "../vehicle-panel/vehicle-panel.component";
import { RtMapComponent } from "../rt-map/rt-map.component";
import { TranslateService } from "@ngx-translate/core";
import { IncidencesNotReadService } from "app/modules/task/Logistics/services/incidences/incidences-nr.service";
import { RestExtTask } from "app/modules/task/rest-client-task-extension";
import { AuthenticationService } from "app/core/services/authentication/authentication.service";

import rExt = RestExtTask;

@Component({
  selector: "app-rt-base",
  templateUrl: "./rt-base.component.html",
  styleUrls: ["./rt-base.component.css"],
  providers: [RealtimeService, ConfirmationService, IncidencesNotReadService],
})
//http://usefulangle.com/post/4/javascript-communication-parent-child-window
//http://blog.sodhanalibrary.com/2016/01/communication-between-browser.html#.Weh3vVuCyCg

export class RealTimeBaseComponent implements OnInit {
  availableSettings: SelectItem[];
  selectedSettings: Rest.RealTimeSettings;
  showChartSettings: boolean = false;
  availableVehicleSettings = [];
  _selectedSettings: Rest.RealTimeSettings;
  defaultSettings: any;
  rtSettings: boolean = false;

  @Output() selectedSettingsChange: EventEmitter<Rest.RealTimeSettings> =
    new EventEmitter<Rest.RealTimeSettings>();

  @ViewChild(VehiclePanelComponent) vehiclePanelComponent;
  // @ViewChild(SidePanelComponent, {static: false}) sidePanel: SidePanelComponent;


  @ViewChild(VehiclePanelComponent, { static: true }) vehiclePanel;
  @ViewChild(SidePanelComponent, { static: true }) sidePanel;
  @ViewChild(RtMapComponent, { static: true }) mapPanel;

  /**Settings form vairables */
  private isNew: boolean;
  displaySettingsForm: boolean = false;
  public isUserHosp: boolean = true;

  // INCIDENCES NOTIFICATIONS VARIABLES
  nrIncidences: Rest.Page<Rest_logistics.Order>;

  // PERMISSIONS VARIABLES
  user: Rest.User;
  manageincidences: boolean;

  constructor(
    private i18n: I18nService,
    private rt: RealtimeService,
    public translate: TranslateService,
    private notificationsService: NotificationsService,
    public rtDataService: RealTimeDataService,
    protected incidencesNrService: IncidencesNotReadService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    // var that = this; // Never used 
    this.availableSettings = [];
    this.selectedSettings = Object.assign({}, emptySettings);
    this._selectedSettings = Object.assign({}, emptySettings);
    this.settingsChanged();

    this.user = this.authenticationService.user;
    this.manageincidences = rExt.hasPermissionrp(rExt.ActionTaskEnum.TASK_INCIDENCES_NOTREAD_VIEW, this.user);
    //PERMISSIONS FOR INCIDENCES VIEW ONLY
    if (this.manageincidences) {
      this.getNrIncidences({ first: 0, rows: 10, sortOrder: 1 });
    }
    //this.initDefaultSettings();
  }

  /**
   * Get not resolved incidences and notify user
   */
  getNrIncidences(event: LazyLoadEvent): void {
    let paginationRequest: Rest.ListPaginationRequest;
    paginationRequest = RestExt.allRecordsRequest();
    paginationRequest.sortAsc = false;
    paginationRequest.filters = {}
    paginationRequest.pageSize = event.rows;
    paginationRequest.sortAsc = event.sortOrder === 1;
    paginationRequest.offset = event.first;

    this.incidencesNrService.getPage(paginationRequest).then((incidences) => {
      this.nrIncidences = incidences;

      // Notify user of incidences not resolved
      if (this.nrIncidences.entities.length > 0) {
        this.notificationsService.add(
          Severity.warn,
          this.i18n._(":("),
          this.translate.instant('logistics.orders.incidencesnotread')
        );
      }
    });
  }

  /**
   * Reload the available settings for the user
   */
  settingsChanged() {
    this.rt.getRealTimeSettings().then((settings: Rest.RealTimeSettings[]) => {
      if (settings) {
        this.availableSettings = RestExt.entitiesToSelectItems(settings);


        if (this.availableSettings.length == 0) {

          this.rtSettings = false;
          this.availableSettings.push(<SelectItem>{
            label: "-",
            value: Object.assign({}, emptySettings),
          });
        } else {
          this.rtSettings = true;

        }

        //There is no selected settings or it is no longer available. Select the first available one
        if (
          this.selectedSettings == null ||
          settings.find((as) => as.id == this.selectedSettings.id) == null
        ) {
          this.selectedSettings = this.availableSettings[0].value;
        } else {
          this.selectedSettings = this.availableSettings.find(
            (as) => as.value.id == this.selectedSettings.id
          ).value;
        }
        if (this.selectedSettings.id == -1) {
          this.selectedSettingsChange.emit(this.selectedSettings);
        } else {
          //Retrieve the full data associated with the settings
          this.rt
            .findRealTimeSettings(this.selectedSettings.id + "")
            .then((settings: Rest.RealTimeSettings) => {
              this.availableSettings.find(
                (as) => as.value.id == settings.id
              ).value = settings;
              this.availableSettings = [...this.availableSettings];
              this.selectedSettings = settings;
              this.selectedSettingsChange.emit(settings);
            });
        }
        this.selectedSettings = settings[0];
      }
      this.sidePanel.updateSettings(this.selectedSettings.fleetTreeSettings);



      if (this.selectedSettings.id == -1) {

        this.rt.getFleetTreeSettings().then((treeSettings: Rest.FleetTreeSettings[]) => {

          const hasCategoricalValuesMappings = treeSettings.some((realTimeSettings) => {
            return "categoricalValuesMappings" in realTimeSettings.sensorChartSettings != null;
          });
          const hasNumericalValuesColors = treeSettings.some((realTimeSettings) => {
            return "numericalValuesColors" in realTimeSettings.sensorChartSettings != null;
          });
          if (hasCategoricalValuesMappings || hasNumericalValuesColors) {
            this.showChartSettings = true;
          } else {
            this.showChartSettings = false;
          }
        })
      } else {
        const hasCategoricalValuesMappings = this.selectedSettings?.fleetTreeSettings?.sensorChartSettings?.categoricalValuesMappings;
        const hasNumericalValuesColors = this.selectedSettings?.fleetTreeSettings?.sensorChartSettings?.numericalValuesColors;
        if (Object.keys(hasCategoricalValuesMappings).length == 0 && Object.keys(hasNumericalValuesColors).length == 0) {
          this.showChartSettings = false;
        } else {
          this.showChartSettings = true;
        }
      }


      Object.assign(this._selectedSettings, this.selectedSettings);

    });

  }

  /**
   * Start a new settings object in the server (refactored)
   *
   * @memberof RealTimeBaseComponent
   */

  /*
  async create() { // async makes the function a lot easier

    // try-catch in case createRealTimeSettings call fails
    try {

      const newSettings = await this.rt.createRealTimeSettings(this.selectedSettings);
      this.selectedSettings = newSettings;
      this.settingsChanged();
      this.displaySettingsForm = false;

      this.notificationsService.add(
        Severity.success,
        this.i18n._(":)"),
        this.i18n._("Entity updated")
      );

    } catch (error) {

      //Handles errors by notifying it into the console
      this.notificationsService.add(
        Severity.error,
        this.i18n._(":("),
        this.i18n._("Failed to create entity")
      );

      console.error(error);
    }*/
  create() {
    this.initDefaultSettings();
    Object.assign(this.selectedSettings, this.defaultSettings);
    this.selectedSettings.id = 0;

    this.rt
      .createRealTimeSettings(this.selectedSettings)
      .then((settings: Rest.RealTimeSettings) => {
        this.selectedSettings = settings;
        this.settingsChanged();
        this.displaySettingsForm = false;
        this.notificationsService.add(
          Severity.success,
          "",
          this.translate.instant('general.updateSuccessfull')
        );
      });
  }

  /**
   * Update an existing settings object
   */
  update() {
    Object.assign(this.selectedSettings, this._selectedSettings);
    this.rt
      .updateRealTimeSettings(this.selectedSettings)
      .then((settings: Rest.RealTimeSettings) => {
        this.settingsChanged();
        this.displaySettingsForm = false;
        this.notificationsService.add(
          Severity.success,
          "",
          this.translate.instant('general.updateSuccessfull')
        );
      });
  }

  /**
   * Delete an existing settings object
   */
  delete() {
    this.rt.deleteRealTimeSettings(this.selectedSettings.id + "").then(() => {
      this.notificationsService.add(
        Severity.success,
        this.i18n._(":)"),
        this.i18n._("Entity deleted")
      );
      this.settingsChanged();
    });
  }

  initSettingsModal() {
    Object.assign(this._selectedSettings, this.selectedSettings);
    if (!this.rtSettings) {
      this.create()
    }

  }

  initDefaultSettings() {
    this.defaultSettings = <Rest.RealTimeSettings>{
      id: 0,
      name: "default settings",
      fleetTreeSettings: this.sidePanel._selectedSettings,
      vehicleDetailsSettings: this.vehiclePanel._selectedSettings,
      mapSettings: this.mapPanel._selectedSettings,
    };

  }

}


const emptySettings = <Rest.RealTimeSettings>{
  id: -1,
  fleetTreeSettings: <Rest.FleetTreeSettings>{
    id: -1,
    sensorChartSettings: <Rest.SensorChartSettings>{
      id: -1,
      showCategoricalValue: false,
      sensorTag: "",
      categoricalValuesMappings: {},
      numericalValuesColors: {},
      filterType: "SENSOR",
    },
    visibleFleets: [],
    visibleVehicles: [],
    visibleVehiclesGroups: [],
  },
  vehicleDetailsSettings: <Rest.VehicleDetailsSettings>{
    id: -1,
    view: "GRID",
  },
  mapSettings: <Rest.MapSettings>{
    id: -1,
    visiblePOICategories: [],
    visibleZoneGroups: [],
  },
};


/*
const defaultSettings = <Rest.RealTimeSettings>{
  id: 0,
  name: "default_rt_settings",
  fleetTreeSettings: <Rest.FleetTreeSettings>{
    id: -1,
    sensorChartSettings: <Rest.SensorChartSettings>{
      id: -1,
      showCategoricalValue: false,
      sensorTag: "",
      categoricalValuesMappings: {},
      numericalValuesColors: {},
      filterType: "SENSOR",
    },
    visibleFleets: [],
    visibleVehicles: [],
    visibleVehiclesGroups: [],
  },
  vehicleDetailsSettings: <Rest.VehicleDetailsSettings>{
    id: -1,
    view: "GRID",
  },
  mapSettings: <Rest.MapSettings>{
    id: -1,
    visiblePOICategories: [],
    visibleZoneGroups: [],
  },
};
*/

