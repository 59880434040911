<p-accordionTab i18n-header header="Track Event configuration">

    <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
            <form-input i18n-fieldName fieldName="mode" [errors]="errors.mode">
                <p-dropdown (onChange)="trackEventModeChange($event)" [options]="availableTrackConfigModes" [(ngModel)]="track.mode" [style]="{'width':'190px'}"></p-dropdown>
            </form-input>
        </div>
        <div [class.disableDIV]=!trackModeSwitch>
            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="basis mode" [errors]="errors.basis">
                    <p-dropdown [options]="availableTrackBasisModes" [(ngModel)]="track.basis" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <div class="ui-g-4">
                </div>
                <div class="ui-g-8">
                </div>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="time interval" [errors]="errors.time">
                    <div class="ui-inputgroup">
                        <p-spinner id="time" size="5" [(ngModel)]="track.time" [min]="5" [max]="65535"></p-spinner>
                        <span class="ui-inputgroup-addon">5~65535</span>
                        <span class="ui-inputgroup-addon" i18n>times</span>
                    </div>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="distance interval" [errors]="errors.distance">
                    <div class="ui-inputgroup">
                        <p-spinner id="distance" size="5" [(ngModel)]="track.distance" [min]="100" [max]="65535"></p-spinner>
                        <span class="ui-inputgroup-addon">100~65535</span>
                        <span class="ui-inputgroup-addon" i18n>times</span>
                    </div>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="tracking times" [errors]="errors.trackingTimes">
                    <div class="ui-inputgroup">
                        <p-spinner id="trackingTimes" size="5" [(ngModel)]="track.trackingTimes" [min]="0" [max]="65535"></p-spinner>
                        <span class="ui-inputgroup-addon">0~65535</span>
                        <span class="ui-inputgroup-addon" i18n>times</span>
                    </div>
                </form-input>
            </div>

            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="heading" [errors]="errors.heading">
                    <div class="ui-inputgroup">
                        <p-spinner id="heading" size="5" [(ngModel)]="track.heading" [min]="10" [max]="90"></p-spinner>
                        <span class="ui-inputgroup-addon">10~90</span>
                        <span class="ui-inputgroup-addon" i18n>degrees</span>
                    </div>
                </form-input>
            </div>

            <div *ngIf=isTrack2 class="ui-g-12">
                <form-input i18n-fieldName fieldName="trigger on main power lost" [errors]="errors.triggerOnMainPowerLost">
                    <p-inputSwitch [(ngModel)]="track.triggerOnMainPowerLost"></p-inputSwitch>
                </form-input>
            </div>

            <div *ngIf=isTrack2 class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="Trigger input 1" [errors]="errors.triggerOnInput1Value">
                    <p-dropdown [options]="availableTrack2InputValues" [(ngModel)]="track.triggerOnInput1Value" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>
            <div *ngIf=isTrack2 class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="Trigger input 2" [errors]="errors.triggerOnInput2Value">
                    <p-dropdown [options]="availableTrack2InputValues" [(ngModel)]="track.triggerOnInput2Value" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>
            <div *ngIf=isTrack2 class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="Trigger input 3" [errors]="errors.triggerOnInput3Value">
                    <p-dropdown [options]="availableTrack2InputValues" [(ngModel)]="track.triggerOnInput3Value" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>
            <div *ngIf=isTrack2 class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="Trigger input 4" [errors]="errors.triggerOnInput4Value">
                    <p-dropdown [options]="availableTrack2InputValues" [(ngModel)]="track.triggerOnInput4Value" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>
            <div *ngIf=isTrack2 class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="Trigger input 5" [errors]="errors.triggerOnInput5Value">
                    <p-dropdown [options]="availableTrack2InputValues" [(ngModel)]="track.triggerOnInput5Value" [style]="{'width':'190px'}"></p-dropdown>
                </form-input>
            </div>
        </div>
        <div class="ui-g-12 ui-md-6">
            <div class="ui-g-4">
            </div>
            <div class="ui-g-8">
            </div>
        </div>


    </div>

</p-accordionTab>