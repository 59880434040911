import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { UserService } from 'app/rest/user.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { SubdelegationService } from 'app/rest/subdelegation.service';

import User = Rest.User;
import Subdelegation = Rest.Subdelegation;
import Delegation = Rest.Delegation;
import { RestExt } from 'app/services/rest-client-extension';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import { LeaderService } from 'app/rest/leader.service';

@Component({
    selector: 'app-subdelegation-form',
    templateUrl: './subdelegation-form.component.html',
    styleUrls: ['./subdelegation-form.component.scss'],
    providers: [SubdelegationService, IconService, UserService, DelegationService, LeaderService],
    encapsulation: ViewEncapsulation.None,
})
export class SubdelegationFormComponent
    extends EntityFormComponent<Subdelegation>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;

    active: boolean;

    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    // DROPDOWNS MOCK VARIABLES (WILL BE CHANGED IN THE FUTURE)
    clientId: number;
    //users: User[];
    //isUsersLoaded: Boolean = false;
    zoneUserLeaders: Rest.User[];
    fleetUserLeaders: Rest.User[];
    serviceUserLeaders: Rest.User[];

    leadersLoaded: Boolean = false;
 
    delegations: Delegation[];

    // Preservation of relationships
    delegationRequestContext: Rest.ListPaginationRequest;
    delegationList: Delegation[] = [];
	selectedDelegation: Delegation;
    /* *************************** */

    constructor(
        private subdelegationService: SubdelegationService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        public translateService: TranslateService,
        public userService: UserService,
        public delegationService: DelegationService,
        public leadersService: LeaderService,

    ) {
        super(subdelegationService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
     }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ClientFormComponent
     */
    beforeShow() {
        this.active = !this.entity.disabled;
        this.delegationList = [];
        // Presevation of relationships
        this.delegationRequestContext = RestExt.firstPageRequestVehicleInventory();
        this.getLeadersLists();

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.subdelegations.createSubdelegation');
        } else {
            this.title = this.translateService.instant('tableManagement.subdelegations.editSubdelegation');

            //#region PRESERVATION OF RELATIONSHIPS
            //DELEGATION
            this.delegationRequestContext = RestExt.firstPageRequestVehicleInventory();

            this.delegationRequestContext = {
                sortBy: "name",
                sortAsc: true,
                pageSize: 10,
                loadPermissions: false,
                filters:{}
            } as unknown as Rest.ListPaginationRequest;

            if(this.entity.delegation){
                this.delegationRequestContext.filters["name"] = [this.entity.delegation.name];
            }
        }

        this.delegationService.getPage(this.delegationRequestContext).then((p) => {
            this.delegationList= p.entities;
        });
    }

    public onChangeDelegation(event) {
        this.entity.delegation = event.value;
		this.selectedDelegation = event.value;
	}
    
    onFilterDelegationName(event) {
        this.delegationRequestContext = {
            sortBy: "name",
            sortAsc: true,
            pageSize: 10,
            filters: {
                name: [event.filter ?? ''],
                disabled: [false]
            },
        } as unknown as Rest.ListPaginationRequest;

        this.delegationService.getPage(this.delegationRequestContext).then((p) => {
            this.delegationList = p.entities;
        });
    }

    onFilterDelegationCode(event) {
        this.delegationRequestContext = {
            sortBy: "name",
            sortAsc: true,
            pageSize: 10,
            filters: {
                code: [event.filter ?? ''],
                disabled: [false]
            },
        } as unknown as Rest.ListPaginationRequest;

        this.delegationService.getPage(this.delegationRequestContext).then((p) => {
            this.delegationList = p.entities;
        });
    }

    saveSubDelegation() {
        this.messages = [];

        const that = this;

        /*
        const fleetManager: any = this.entity.fleetManager;
        const serviceManager: any = this.entity.serviceManager;
        const zoneLeader: any = this.entity.zoneLeader;

        // Remove the fullName property from the object
        const { fullName, ...userWithoutFullName } = fleetManager;
        const { fullName: fullName2, ...userWithoutFullName2 } = serviceManager;
        const { fullName: fullName3, ...userWithoutFullName3 } = zoneLeader;

        // Update the entity's properties with the object without fullName
        this.entity = {
            ...this.entity,
            fleetManager: userWithoutFullName,
            serviceManager: userWithoutFullName2,
            zoneLeader: userWithoutFullName3,
        }; */

        // Save logic
        if (this.isNew) {
            this.subdelegationService.create(this.entity)
                .then(function (res) {
                    that.display = false;
                    that.return.emit(res);
                })
                .catch((e) => {
                    that.handleError(e, that);
                });
        } else {
            this.subdelegationService.update(this.entity)
                .then(function (res) {
                    that.display = false;
                    that.return.emit(res);
                })
                .catch((e) => {
                    that.handleError(e, that);
                });
        }
    }
    /*
    async fetchUsersData() {
        try {
            this.clientId = await this.authenticationService.user.client.id;
            this.isUsersLoaded = false;

            this.users = await this.userService.getUsersByClient(this.clientId);

            this.users = this.users.map(user => ({
                ...user,
                fullName: `${user.surname1} ${user.surname2}, ${user.name}`
            }));

            if (this.users != null || this.users != undefined) {
                this.isUsersLoaded = true;
            }
        } catch (error) {
            //Error handling
            console.error('Error fetching users data: ', error);
        }
    } */


    getLeadersLists() {
	    let leadersFilters = {
           // 'disabled': [{value: true}]
		}
 		let leaderFilterRequest = {
			sortBy: null,
			sortAsc: true,
			pageSize: 2147483647,
			filters:  leadersFilters,
			loadPermissions: false
 		} as unknown as Rest.ListPaginationRequest;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.leadersService.getPage(leaderFilterRequest)
				.then(
					function (response) {
                        if(response.entities){
                             that.zoneUserLeaders = response.entities?.filter(leader => leader?.zoneLeader).map(leader => leader?.user);
                             that.serviceUserLeaders = response.entities?.filter(leader => leader?.serviceLeader).map(leader => leader?.user);;
                             that.fleetUserLeaders = response.entities?.filter(leader => leader?.fleetLeader).map(leader => leader?.user);;
                             that.leadersLoaded = true;
                        }						
 					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}
}
