import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { FinanceTypesFormComponent } from '../finance-types-form/finance-types-form.component';
import { FinanceTypeService } from 'app/modules/vehicle-inventory/services/table-management/financing_type.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from "app/services/excel-service/excel.service";

@Component({
  selector: 'app-finance-types-list',
  templateUrl: './finance-types-list.component.html',
  styleUrls: ['./finance-types-list.component.css'],
  providers: [FinanceTypeService, ConfirmationService, UserTimePipe, ExcelService],
})
export class FinanceTypesListComponent
  extends EntityListComponent<Rest.FinanceType>
  implements OnInit {

  title: string = this.i18n._("Finance Types");

  @ViewChild(FinanceTypesFormComponent, { static: true })
  editForm: FinanceTypesFormComponent;

  user: Rest.User;
  EnabledFilter: SelectItem[];

  constructor(
    private objectService: FinanceTypeService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected translateService: TranslateService
  ) {
    super(
      objectService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );

    this.objectService = objectService;
  }


  ngOnInit(): void {
    super.ngOnInit();

    this.form = this.editForm;
    this.hasEntityFilters = false;
  }

  beforeLoad() {
     if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }
  }
  afterLoad() {
    this.objectService.getPage(this.paginationRequest).then((p) => {
      this.paginationResult = p;
    });


    // this.EnabledFilter = RestExt.getEnabledFilter(
    //   this.filters["enabled"],
    //   this.i18n
    // );
    this.paginationResult.entities.forEach((ct: any) => {
      ct._$noticeChannelsStr = this.arrayToString(ct.noticeChannels);
      /*Permissions */
      ct._$changeStatus = this.hasPermission(
        this.permissionsEntityTypeSelector("changeStatus", "FinanceType"),
        ct.id
      ); //This is for the validation of entity-list (selectAll function)
      ct._$update = this.hasPermission(
        this.permissionsEntityTypeSelector("update", "FinanceType"),
        ct.id
      );
      /** */
    });

    this.checkSelectAll();
  }
}