/* eslint-disable prefer-const */
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../rest/rest_client";
import { ZonegroupService } from "../../../rest/zonegroup.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityListComponent } from "../../entity-list/entity-list.component";
import { ZoneGroupFormComponent } from "../zone-group-form/zone-group-form.component";

@Component({
    selector: "app-zone-groups-list",
    templateUrl: "./zone-groups-list.component.html",
    styleUrls: ["./zone-groups-list.component.css"],
    providers: [ZonegroupService, ConfirmationService, UserTimePipe],
})
export class ZoneGroupsListComponent
    extends EntityListComponent<Rest.ZoneGroup>
    implements OnInit {
    title: string;
    displayDialog: boolean;
    messageWarning: string;
    groupName: string;

    @ViewChild(ZoneGroupFormComponent, {static: true}) zoneGroupForm;

    EnabledFilter: SelectItem[];
    zonegroupService: ZonegroupService;
    expandedRows = [];
    //datos para compartir con custom-map
    listZones: Rest.Zone[]; //indica la lista de zonas que queremos mostrar en el mapa

    constructor(
        zonegroupService: ZonegroupService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        private activatedRoute: ActivatedRoute
    ) {
        super(
            zonegroupService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime
        );
        this.zonegroupService = zonegroupService;
        this.groupName = this.activatedRoute.snapshot.params.group;
        if (this.groupName !== "" && this.groupName !== undefined) {
            this.paginationRequest = RestExt.allRecordsRequest();
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.zoneGroupForm;
        this.title = this.i18n._("Zone Group");
        this.hasEntityFilters = true;
        this.fetchEntityBeforeShowUpdateForm = true;
        this.expandInitalGroup();
    }

    expandInitalGroup() {
        if (this.groupName !== "") {
            //   this.paginationResult = null;
        }
    }

    afterLoad() {
        this.EnabledFilter = RestExt.getEnabledFilter(
            this.filters["enabled"],
            this.i18n
        );
        this.paginationResult.entities.forEach((zg: any) => {
            /*Permissions */
            zg._$changeStatus = this.hasPermission(
                this.permissionsEntityTypeSelector("changeStatus", "Zone Group"),
                zg.id
            );
            zg._$update = this.hasPermission(
                this.permissionsEntityTypeSelector("update", "Zone Group"),
                zg.id
            );
            /** */
        });
        /*Permissions */
        this.checkSelectAll();
        /** */
        let groupFound = false;
        this.paginationResult.entities.forEach((zg) => {
            // eslint-disable-next-line prefer-const
            let idx = this.expandedRows.findIndex((zgb) => zgb.id === zg.id);
            if (idx > -1) {
                this.expandedRows[idx] = zg;
            } else if (this.groupName === zg.name) {
                this.expandRow(zg);
                groupFound = true;
            }
        });
        if (this.groupName !== "" && !groupFound) {
            // zonegroupService.getPage
        }
    }

    expandRow(row) {
        let idx = this.expandedRows.findIndex((zgb) => zgb.id === row.id);
        if (idx > -1) {
            return;
        } //Already expanded
        this.expandedRows.push(row);
    }

    notifyChildFetch() {
        this.refresh();
    }

    editZoneDialog(event) {
        if (!this.isSelectableColumn) {
            this.onRowDblClick({data: event});
        }
        this.isSelectableColumn = false;
        this.isColumn = true;
    }

    editZoneDialogTable(event) {
        if (
            !this.isColumn &&
            !this.isSelectableColumn &&
            event["originalEvent"]["srcElement"]["cellIndex"] > 1
        ) {
            this.onRowDblClick(event);
        }

        this.isSelectableColumn = false;
        this.isColumn = false;
    }
}
