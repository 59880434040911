import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Accordion } from 'primeng/accordion';
import { TabView } from 'primeng/tabview';
import { Auxiliar } from '../../../model/Auxiliar';
import { PreconfigurationService } from '../../../rest/preconfiguration.service';
import { Rest } from '../../../rest/rest_client';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { ServerValidatedFormComponent } from '../../server-validated-form/server-validated-form.component';

@Component({
    selector: 'app-gmt200-parameters-form',
    templateUrl: './gmt200-parameters-form.component.html',
    styleUrls: ['./gmt200-parameters-form.component.css'],
    providers: [PreconfigurationService, ConfirmationService],
    //encapsulation: ViewEncapsulation.None
})
export class GMT200ParametersFormComponent
    extends ServerValidatedFormComponent
    implements OnInit, OnChanges {
    //View params
    @Input() deviceParams: Rest.DeviceParameters; //.DeviceParameters;
    @Input() firmware: RestExt.FirmwareVersion;
    @Input() isNew: boolean;
    @Output() GMT200ParamsChange: EventEmitter<Rest.DeviceParameters> =
        new EventEmitter<Rest.DeviceParameters>();
    @ViewChild('tv', { static: true }) public tabView: TabView;
    @ViewChild('ac', { static: true }) accordion: Accordion;
    @Input() isConfig: boolean;
    @Output()
    refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

    preconfigService: PreconfigurationService;
    confirmationService: ConfirmationService;
    notificationsService: NotificationsService;

    firmwareValue: any;

    /*Events */
    selected = false;

    /*PreConfigs */
    preConfigsList: Rest.DevicePreconfiguration[];
    availablePreconfigs: SelectItem[];
    selectedPreconfig: any;
    selectednpmPreconfig: any;

    constructor(
        preconfigService: PreconfigurationService,
        confirmationService: ConfirmationService,
        notificationsService: NotificationsService,
        protected i18n: I18nService
    ) {
        super(i18n);
        this.preconfigService = preconfigService;
        this.confirmationService = confirmationService;
        this.notificationsService = notificationsService;
        //this.i18n = i18n;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isNew && this.deviceParams == null) {
            this.deviceParams = RestExt.emptyGMT200Params();
        }


        if (changes['deviceParams']) {
            this.GMT200ParamsChange.emit(this.deviceParams);
        }
        if (changes['firmware']) {
            if (this.firmware == undefined) {
                this.firmwareValue = undefined;
            } else {
                this.firmwareValue = RestExt.FirmwareVersion[this.firmware] + 1; //Add 1 because returns the index of enum value and starts at 0 which corresponds to first item
                RestExt.initializeGMT200Params(this.deviceParams);
                this.LoadPreconfigsList();
                /*if (this.isNew) {
                   RestExt.removeInvalidParams(this.[(deviceParams)], this.firmwareValue);
                 } else {
                   if (RestExt.FirmwareVersion[changes['firmware'].previousValue] + 1 > this.firmwareValue) {
                     RestExt.removeInvalidParams(this.[(deviceParams)], this.firmwareValue);
                   } else {
                     RestExt.addNewParams(this.[(deviceParams)], this.firmwareValue);
                   }
                 }*/
            }
        }
    }

    ngOnInit(): void {
        this.initConfig();

        if (this.isConfig) {
            this.LoadPreconfigsList();
        }
    }

    initConfig() {
        this.preConfigsList = [];
        this.selectedPreconfig = null;
        if (this.accordion !== undefined) {
            this.tabView.activeIndex = 0;
            this.closeAllAccordionTabs();
        }
    }

    loadPreconfig(event) {
        this.preconfigService
            .find(event.value.id)
            .then((pre: Rest.DevicePreconfiguration) => {
                this.fillPreConfig(pre.parameters as Rest.DeviceParameters);
            });
    }

    LoadPreconfigsList() {
        let paginationRequest: Rest.ListPaginationRequest;
        this.availablePreconfigs = [];
        this.selectedPreconfig = null;
        this.preConfigsList = [];
        const that = this;
        paginationRequest = RestExt.firstPageRequest();
        paginationRequest.sortAsc = false;
        paginationRequest.filters = {
            firmware: [this.firmware + ''],
        };

        this.preconfigService.getPage(paginationRequest).then(function (response) {
            that.preConfigsList = response.entities;
            that.availablePreconfigs = Auxiliar.toEntitySelectItemArr(
                response.entities
            );
        });
    }

    closeAllAccordionTabs() {
        if (!this.isNullOrUndefined(this.accordion.tabs)) {
            for (const tab of this.accordion.tabs) {
                if (tab.selected) {
                    tab.selected = false;
                }
            }
        }
    }

    isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
        return typeof obj === 'undefined' || obj === null;
    }

    public getGMT200DeviceParams(): any {
        return this.deviceParams;
    }

    fillPreConfig(newParams: Rest.DeviceParameters) {
        //to load the values of the preconfig events but not the id assigned
        //remove id because if is from preconfig we have to assign as new sensors to config

        //to load the values of the preconfig events but not the id assigned
        newParams.id = this.deviceParams.id;

        // eslint-disable-next-line guard-for-in
        for (const a in newParams.sensors) {
            delete newParams.sensors[a]['id'];
        }

        // eslint-disable-next-line guard-for-in
        for (const a in newParams.actuators) {
            delete newParams.actuators[a]['id'];
        }

        //Load the rest preconfig parameters to GMT200 config

        this.deviceParams.proprietaryParameters.type = 'GMT200';
        this.deviceParams = newParams;
        /*this.deviceParams.sensors = newParams.sensors;
        this.deviceParams.actuators = newParams.actuators;
        this.deviceParams.proprietaryParameters.disParameters =
          newParams.proprietaryParameters.disParameters;
        this.deviceParams.proprietaryParameters.friParameters =
          newParams.proprietaryParameters.friParameters;
        this.deviceParams.proprietaryParameters.globalParameters =
          newParams.proprietaryParameters.globalParameters;
        this.deviceParams.proprietaryParameters.hbmParameters =
          newParams.proprietaryParameters.hbmParameters;
        this.deviceParams.proprietaryParameters.jammingDetection =
          newParams.proprietaryParameters.jammingDetection;
        this.deviceParams.proprietaryParameters.jbsParameters =
          newParams.proprietaryParameters.jbsParameters;
        this.deviceParams.proprietaryParameters.sosParameters =
          newParams.proprietaryParameters.sosParameters;
        this.deviceParams.proprietaryParameters.speedAlarmParameters =
          newParams.proprietaryParameters.speedAlarmParameters;
        this.deviceParams.proprietaryParameters.startStopReport =
          newParams.proprietaryParameters.startStopReport;
        this.deviceParams.proprietaryParameters.towAlarm =
          newParams.proprietaryParameters.towAlarm;
            this.deviceParams.proprietaryParameters.geoParameters = newParams.proprietaryParameters.geoParameters;
        this.deviceParams.proprietaryParameters.sriParameters = newParams.proprietaryParameters.sriParameters;
        this.deviceParams.proprietaryParameters.outParameters = newParams.proprietaryParameters.outParameters;
        this.deviceParams.proprietaryParameters.bsiParameters = newParams.proprietaryParameters.bsiParameters;
        this.deviceParams.proprietaryParameters.dogParameters = newParams.proprietaryParameters.dogParameters;
        this.deviceParams.proprietaryParameters.pdsParameters = newParams.proprietaryParameters.pdsParameters;
        this.deviceParams.proprietaryParameters.ffcParameters = newParams.proprietaryParameters.ffcParameters;
        this.deviceParams.proprietaryParameters.idlParameters = newParams.proprietaryParameters.idlParameters;
        this.deviceParams.proprietaryParameters.craParameters = newParams.proprietaryParameters.craParameters;
        this.deviceParams.proprietaryParameters.ascParameters = newParams.proprietaryParameters.ascParameters;
        this.deviceParams.proprietaryParameters.iobParameters = newParams.proprietaryParameters.iobParameters;
        this.deviceParams.proprietaryParameters.vmsParameters = newParams.proprietaryParameters.vmsParameters;
        this.deviceParams.proprietaryParameters.vvsParameters = newParams.proprietaryParameters.vvsParameters;
        this.deviceParams.proprietaryParameters.avsParameters = newParams.proprietaryParameters.avsParameters;*/

        // eslint-disable-next-line guard-for-in
        for (const a in this.deviceParams.sensors) {
            this.deviceParams.sensors[a].parameters = null; //configTmp;
        }
        // eslint-disable-next-line guard-for-in
        for (const a in this.deviceParams.actuators) {
            this.deviceParams.actuators[a].parameters = null; //configTmp;
        }
    }

    tabChanged() {
        /* TODO: IMPORTANT - Should be implemented!!! */
    }
}
