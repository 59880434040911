<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Geo-Fence Configuration (AT + GTGEO)</span>
            </div>
          </div>
        </p-header>
        
        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="GEO ID" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="idOptions"
                    [style]="{'width':'100%','min-width':'100px'}" (onChange) = "onGeoIdChange($event)"></p-dropdown>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Mode" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="modeOptions"
                    [(ngModel)]="geoParameters[selectedIndex].mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>
        
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Latitude" >
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <input type="number" pInputText [(ngModel)]="geoParameters[selectedIndex].latitude" class="txtbStyle"/>
                        </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Longitude" >
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <input type="number" pInputText [(ngModel)]="geoParameters[selectedIndex].longitude" class="txtbStyle"/>
                    </div>
                </div>
            </div>
        
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Radius">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" size="2" [(ngModel)]="geoParameters[selectedIndex].radius"
                            [min]="25" [max]="6000000"></p-spinner>
                        <span class="ui-inputgroup-addon">25~6000000</span>
                        <span class="ui-inputgroup-addon" >m</span>
                    </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input  fieldName="Check Interval">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" size="2" [(ngModel)]="geoParameters[selectedIndex].checkInterval"
                            [min]="0" [max]="86400"></p-spinner>
                        <span class="ui-inputgroup-addon">0~86400</span>
                        <span class="ui-inputgroup-addon" >s</span>
                    </div>
                </div>
            </div>
        
        </div>

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="State Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="modeStateOptions"
                [(ngModel)]="geoParameters[selectedIndex].modeState" [style]="{'width':'100%','min-width':'100px'}" appendTo="body"></p-dropdown>
            </div>
        </div>
      </p-accordionTab>