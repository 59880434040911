<!-- VEHICLE VIEW SETTINGS MAIN CONTAINER -->
<div class="row mt-1 ">
  <div class="col mt-3">
    <div class="d-flex align-items-center h-100">
      <label for="vehicle-data-settings-name" class="form-label custom-label mb-0 me-2" i18n>
        {{'real-time.vehiclePanel.settingsName' | translate}}:
      </label>
      <input type="text" id="vehicle-data-settings-name" class="form-control custom-input h-45"
        [(ngModel)]=_settings.name>
    </div>
  </div>
</div>

<div class="container-fluid p-0 custom-panel" id="vehicle-view-settings-main-container">




  <!-- ROW 2: VEHICLE PANEL VIEW SETTINGS LABEL -->
  <!-- <div class="row text-center mt-5">
    <label class="custom-title custom-vehicle-panel-settings-title" i18n>
      {{'real-time.vehiclePanel.vehiclePanelViewSettings' | translate}}
    </label>
  </div>

  <hr class="separator mt-0 mb-3 mt-1 custom-real-time-separator">  -->


  <!-- ROW 1: VEHICLE VIEW SETTINGS NAV TABS -->
  <div class="row m-0 mt-4">
    <ul class="nav nav-tabs custom-nav-tabs">

      <li class="nav-item w-45">
        <a href="#view-headers" class="nav-link active p-0 custom-title custom-tab-title first-nav-tab"
          data-bs-toggle="tab">
          <div class="d-flex align-items-center justify-content-center h-100" i18n>
            {{'real-time.vehiclePanel.settings.headers' | translate}}
          </div>
        </a>
      </li>

      <li class="nav-item w-45">
        <a href="#view-content" class="nav-link p-0 custom-title custom-tab-title" data-bs-toggle="tab">
          <div class="d-flex align-items-center justify-content-center h-100" i18n>
            {{'real-time.vehiclePanel.settings.content' | translate}}
          </div>
        </a>
      </li>

      <!-- <li class="nav-item flex-fill">
        <div class="d-flex w-100">
          <a class="nav-link p-0 custom-title custom-tab-title empty-tab">
          </a>
        </div>
      </li> -->

    </ul>
  </div>

  <!-- ROW 2: VEHICLE VIEW SETTINGS NAV CONTENT -->
  <div class="row m-0">
    <div class="tab-content p-0">

      <!-- TAB 1: VIEW HEADERS -->
      <div class="tab-pane fade show active" id="view-headers">
        <div class="container-fluid">

          <!--ROW 1: CHECKBOXES -->
          <div class="row mt-4 mb-4">

            <!-- COL 1: VEHICLE NAME && FLEET NAME && VEHICLE GROUP NAME && ADDRESS && SPEED -->
            <div class="col">

              <!-- VEHICLE NAME -->
              <div class="d-flex align-items-center">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showVehicleName" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.vehicleName' | translate}}
                  </label>

                </div>
              </div>

              <!-- FLEET NAME -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showFleetName" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.fleetName' | translate}}
                  </label>

                </div>
              </div>

              <!-- VEHICLE GROUP NAME -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showVehicleGroupName" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.vehicleGroupName' | translate}}
                  </label>

                </div>
              </div>

              <!-- ADDRESS -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showAddress" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'general.address' | translate}}
                  </label>

                </div>
              </div>

              <!-- SPEED -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showSpeeed" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.speed' | translate}}
                  </label>

                </div>
              </div>

            </div>

            <!-- COL 2: MILEAGE && ANALOGIO && STATUS && FIELD LABEL -->
            <div class="col">

              <!-- MILEAGE -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showMileage" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.mileage' | translate}}
                  </label>

                </div>
              </div>

              <!-- ANALOG IO -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showAnalogIO" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.analogIO' | translate}}
                  </label>

                </div>
              </div>

              <!-- STATUS -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showStatus" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'general.status' | translate}}
                  </label>

                </div>
              </div>

              <!-- FIELD LABEL -->
              <div class="d-flex align-items-center mt-2">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showFieldLabel" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    {{'real-time.vehiclePanel.settings.fieldLabel' | translate}}
                  </label>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

      <!-- TAB 2: VIEW CONTENT -->
      <div class="tab-pane fade" id="view-content">
        <div class="container-fluid">

          <!-- ROW 1: VIEW DROPDOWN -->
          <div class="row mt-4 mb-4 h-100">
            <div class="col-auto mr-auto">
              <div class="d-flex align-items-center">
                <label for="language" class="form-label custom-label mb-0 me-3" i18n>
                  {{'real-time.vehiclePanel.settings.view' | translate}}:
                </label>
                <select (ngModelChange)="saveView($event)" name="language" id="language"
                  class="form-select custom-select custom-vehicle-panel-view-select" [(ngModel)]="selectedView">
                  <option *ngFor="let view of availableViews" [ngValue]="view">{{view.label}}</option>
                </select>
              </div>
            </div>

            <div class="col-auto mr-auto">
              <div class="d-flex align-items-center ">
                <div class="form-check d-flex align-items-center">
                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showMap" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    Mapa
                  </label>

                </div>
              </div>
            </div>

            <div class="col-auto mr-auto">
              <div class="d-flex align-items-center  ">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showTelemetry" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    Telemetry
                  </label>

                </div>
              </div>
            </div>

            <div class="col-auto mr-auto">
              <div class="d-flex align-items-center  ">
                <div class="form-check d-flex align-items-center">

                  <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="_settings.showQuickActions" />

                  <label class="form-label custom-label m-0 ms-3" i18n>
                    Quick Actions
                  </label>

                </div>
              </div>
            </div>

          </div>
          <!-- TODO: ELIMINATE EMBEDDED STYLES & IMPLEMENT VIEW PREVIEWS -->
          <!-- ROW 2: VIEW IMAGE PREVIEW -->
          <div class="row mb-4" id="vehicle-view-preview-container" style="height: 300px;">
            <div class="d-flex align-items-center justify-content-center h-100 w-100 p-0">

              <!--
              <div *ngIf="selectedView.value === 'GRID'">
                <label class="form-label custom-label text-uppercase" style="color: #C42C22 !important; font-size: 2rem !important;">
                  GRID view image
                </label>
              </div>


              <div *ngIf="selectedView.value === 'MAP'">
                <label class="form-label custom-label text-uppercase" style="color: #C42C22 !important; font-size: 2rem !important;">
                  MAP view image
                </label>
              </div>

      
              <div *ngIf="selectedView.value === 'TELEMETRY'">
                <label class="form-label custom-label text-uppercase" style="color: #C42C22 !important; font-size: 2rem !important;">
                  TELEMETRY view image
                </label>
              </div>
-->
              <div *ngIf="selectedView.value === 'TABLE'">
                <!-- <label class="form-label custom-label text-uppercase" style="color: #C42C22 !important; font-size: 2rem !important;">
                  TABLE view image
                </label>  -->
                <img class="w-100" src="../../../../../assets/images/settings/table_preview.PNG" id="table-preview">

              </div>


              <div *ngIf="selectedView.value === 'GRID'">
                <img class="w-100" src="../../../../../assets/images/settings/grid_preview.PNG" id="table-preview">

              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <!-- ROW 3: SAVE BUTTON -->
  <div hidden class="row m-0 pt-3 pb-3" id="vehicle-panel-settings-save-button-container">
    <div class="d-flex align-items-center justify-content-center">
      <button id="vehicle-panel-settings-save-button" class="btn custom-button" (click)=save() i18n>
        {{'general.save' | translate}}
      </button>
    </div>
  </div>

</div>