import { Injectable } from '@angular/core';
import { Rest } from '../../../rest/rest_client';
import { EntityService } from '../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';


@Injectable()
export class DashboardsService extends EntityService<any> {

    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    public findByClientId(arg0: number): Rest.RestResponse<Rest.Dashboards[]> {
        return this.client.Dashboards_findByClientId(arg0);
    }

}