<p-dialog header="{{title}}" [draggable]="true" (onHide)="close($event)" [(visible)]="display" [responsive]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" *ngIf="display" [contentStyle]="{'width': 'auto'}">

  <div >

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <p-panel i18n-header header="{{ 'control-panel.poi-categories.poiData' | translate }}" [toggleable]="true" [style]="{'margin-bottom':'20px'}">

      <!-- EDIT POI CONTAINER -->
      <div class="container">

        <!-- ROW 1 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="{{'control-panel.poi-categories.poiName' | translate}}" [errors]="errors.name">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="name" [(ngModel)]="entity.name" class="txtbStyle" />
            </div>
          
          </div>


          <div class="col row align-items-end mt-2">

            <div class="col">
              <form-input [required]="true" i18n-fieldName fieldName="{{'control-panel.poi-categories.position' | translate}}" [errors]="errors.position">
              </form-input>
            </div>

            <div class="col">            
              <div *ngIf="entity.position">
                <div class="d-flex justify-content-center w-100 w-md-50" [style]="{'min-width':'170px'}">
                  <span style="text-align: end">{{entity.position?.longitude}}</span>
                  <span style="text-align: end;">{{entity.position?.latitude}}</span>
                </div>
              </div>
            </div>
          
          </div>
          
        </div>

        <!-- ROW 2 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="{{'general.address' | translate}}" [errors]="errors.address">              
              </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="address" [(ngModel)]="entity.address" class="txtbStyle" />
            </div>

          </div>


          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="{{'general.postalCode' | translate}}" [errors]="errors.postalCode">
              </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="postalCode" [(ngModel)]="entity.postalCode" class="txtbStyle" />
            </div>

          </div>

        </div>

        <!-- ROW 3 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="{{'general.municipality' | translate}}" [errors]="errors.municipality">
              </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="municipality" [(ngModel)]="entity.municipality" class="txtbStyle"/>
            </div>

          </div>


          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="{{'general.province' | translate}}" [errors]="errors.province">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="province" [(ngModel)]="entity.province" class="txtbStyle" />
            </div>

          </div>

        </div>

        <!-- ROW 4 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input i18n-fieldName fieldName="{{'userProfile.phone' | translate}}" [errors]="errors.phone">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="number" id="phone" [(ngModel)]="entity.phoneNumber" class="txtbStyle"/>
            </div>

          </div>


          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input i18n-fieldName fieldName="{{'control-panel.poi-categories.contactPerson' | translate}}" [errors]="errors.contactPerson">
              </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="contactPerson" [(ngModel)]="entity.contactPerson" class="txtbStyle"/>
            </div>

          </div>

        </div>

        <!-- ROW 5 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input i18n-fieldName fieldName="{{'general.observations' | translate}}" [errors]="errors.observations">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="observations" [(ngModel)]="entity.observations" class="txtbStyle"/>
            </div>

          </div>


          <div class="col row align-items-end mt-2">

            <div class="col lblStyle">
              <form-input i18n-fieldName fieldName="{{'control-panel.poi-categories.param1' | translate}}" [errors]="errors.param1">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="param1" [(ngModel)]="entity.param1" class="txtbStyle"/>
            </div>
  
          </div>

        </div>        

        <!-- ROW 6 -->
        <div class="row align-items-center">

          <div class="col row align-items-end mt-2">
          
            <div class="col">
              <form-input i18n-fieldName fieldName="{{'control-panel.poi-categories.param2' | translate}}" [errors]="errors.param2">
                </form-input>
            </div>
            <div class="col">
              <input pInputText type="text" id="param2" [(ngModel)]="entity.param2" class="txtbStyle" />
            </div>
          
          </div>


          <div class="col row align-items-end mt-2">

            <div class="col">
              <form-input *ngIf="isNew && realTime" i18n-fieldName
                fieldName="{{'control-panel.poi-categories.poiCategory' | translate}}" [errors]="errors.poiCategory">
                <p-dropdown [options]="availableCategories" [(ngModel)]="entity.poiCategory" placeholder="Select Category"
                  [style]="{'width':'220px'}" filter="filter" (onChange)="categoryChanged()">
                  <ng-template let-category pTemplate="item">
                    <div class='d-flex flex-row w-100 h-100 gap-3 ps-3'>
                      <img src='{{category?.icon?.imageEncoded}}' class="poi-icon" />
                      <div class='d-flex poi-icon-name'>{{category?.name}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-category pTemplate="selectedItem">
                    <div class='d-flex poi-icon-name'>{{category?.name}}</div>
                  </ng-template>
                </p-dropdown>
              </form-input>
            </div>

          </div>

        </div>

        <!-- ROW 7 -->
        <div class="row row-cols-2 align-items-center mt-2">

          <div class="col">
            <form-input *ngIf="!isNew" i18n-fieldName [required]="true" fieldName="{{'control-panel.poi-categories.poiEnabled' | translate}}" [errors]="errors.enabled">
              <div class="d-flex justify-content-center">
                <p-checkbox disabled="true" [(ngModel)]="entity.enabled" binary="true"></p-checkbox>
              </div>
            </form-input>
          </div>
        </div>

      </div>
    </p-panel>

    <!-- MAP -->
    <div class="container p-4">
      <div *ngIf="showMap">
        <app-custom-map #map mapId="map-pois" [display]="display" [options]="customMapOptions" [pois]="pois"
          [iconPOI]="icon" (eventDeletePOI)="notifyDeletePOI($event)" (eventCreatePOI)="notifyCreatePOI($event)"
          (eventUpdatePOI)="notifyCreatePOI($event.location)" (loadMap)="initMapAfterViewInit($event)">
        </app-custom-map>
      </div>
    </div>

  </div>

  <p-footer>

    <!-- Permissions -->
    <div class="d-flex justify-content-end align-items-center">

      <div *ngIf="isNew">
        <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
      </div>

      <div *ngIf="isNew === false" class="d-flex justify-content-end align-items-center">

        <div *ngIf="entity._$update">
          <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="{{'general.save' | translate}}"></button>
        </div>

        <div *ngIf="entity._$update === false">
          <div style="display: inline-block;" i18n-pTooltip pTooltip="No permissions to update" tooltipPosition="top">
            <button disabled="true" type="button" pButton icon="fa fa-check" (click)="save()" i18n-label
              label="{{'general.save' | translate}}"></button>
          </div>
        </div>

        <div *ngIf="entity._$update">
          <button type="button" pButton icon="fa fa-check" (click)="changeStatus(!entity.enabled)" i18n-label
            label="{{'general.changeStatus' | translate}}"></button>
        </div>
      </div>

    </div>

  </p-footer>

</p-dialog>