<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Excessive Idling
          Detection (AT + GTIDL)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Mode">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-dropdown [options]="modeOptions" [(ngModel)]="idlParameters.mode"
              [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Time to Idling">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="idlParameters.timeToIdling" [min]="1" [max]="30"></p-spinner>
                <span class="ui-inputgroup-addon">1~30</span>
                <span class="ui-inputgroup-addon">min</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Time to Movement">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="idlParameters.timeToMovement" [min]="1" [max]="5"></p-spinner>
                <span class="ui-inputgroup-addon">1~5</span>
                <span class="ui-inputgroup-addon">min</span>
              </div>
            </div>
          </div>

        </div>
      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="idlParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="idlParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>

        </div>
      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>