<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SOS function
          (AT + GTSOS)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
		<div class="col lblStyle">
      <form-input fieldName="Speed Alarm Mode">
      </form-input>
		</div>
		<div class="col">
      <p-dropdown (onChange)="trackEventModeChange(sosParameters.modeSos,$event)" [options]="modeOptions"
      [(ngModel)]="sosParameters.modeSos" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
		</div>
	</div>

    <div [class.disableDIV]=!trackModeSwitch>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Digital Input ID">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="sosParameters.digitalInputID" (onChange)="onChange()" [min]="0"
                [max]="12"></p-spinner>
              <span class="ui-inputgroup-addon">0~3</span>
              <span class="ui-inputgroup-addon">9~12</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="SOS Number">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <input type="text" pInputText [(ngModel)]="sosParameters.sosNumber" class="txtbStyle">
            </div>
          </div>
        </div>
      </div>

    </div>
</p-accordionTab>