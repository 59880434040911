
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Configuration } from 'app/components/ute-rondes/models/Configuration';
import { Rest } from './rest_client';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { PoCsCurrentStatusRow, PoCHistoryRow, VehicleHistoryRow } from 'app/components/ute-rondes/models/Rows';

@Injectable()
export class UteService {
 
    securityToken: any;

    httpClient: JQueryHTTPClient;
	constructor(private jquery: JQueryHTTPClient) {  
        this.httpClient = jquery;
    } 

    public getPoCHistory(PoCId:number, from:number,to:number,limit:number, uteConfigId):Promise<PoCHistoryRow[]>
    {   
        return new Promise((resolve, reject) => {
            let args =[];
            if(from)args.push("from="+from);
            if(to)args.push("to="+to);
            if(limit)args.push("limit="+limit);
            if(uteConfigId)args.push("uteConfigId="+uteConfigId);
            this.httpClient.request({url:"api/ute/PoCHistory/"+PoCId+"?"+ args.join("&"),method:"GET"}).then((list)=>{               
                resolve(list);
            }).catch(e => {                
                reject(e);
            });
        });      
    }

     public getUTERondesHistoryRecords(rol:String, from:number, to:number, vids:number[]):Promise<PoCsCurrentStatusRow[]>
     {   
        let args =[];
        if(from)args.push("from="+from);
        if(to)args.push("to="+to);

        if(to != null && from != null){    
            var urlVar = "api/ute/PoCsHistoryStatus"
            return new Promise((resolve, reject) => {
                this.httpClient.request({url: urlVar, queryParams:{"rol":rol, "from":from, "to":to}, data:vids,method:"POST"}).then((list)=>{               
                    resolve(list);
                }).catch(e => {                
                    reject(e);
                });
            });  
        }
    }

    public getVehicleHistory(vid:number, from:number, to:number, limit:number):Promise<VehicleHistoryRow[]>
    {   
        let args =[];
        if(from)args.push("from="+from);
        if(to)args.push("to="+to);
        if(limit)args.push("limit="+limit);
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/VehicleHistory/"+vid+"?"+ args.join("&"),method:"GET"}).then((list)=>{               
                resolve(list);
            }).catch(e => {                
                reject(e);
            });
        });      
    }
    public getPoCsCurrentStatus(rol:String,vids:number[]):Promise<PoCsCurrentStatusRow[]>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/PoCsCurrentStatus",queryParams:{"rol":rol},data:vids,method:"POST"}).then((list)=>{
                if(list){
                    for(var i = 0; i < list.length; i++){
                        if(list[i].controlPoint.name == "Sortida 11 Besòs"){
                            var test =""
                            test = "test"
                        }
                    }
                } 
                
                resolve(list); 
                
            }).catch(e => {
                reject(e);
            });
        });
    }

    public getListOfConfigurations():Promise<Configuration[]>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/configurations/ids",method:"GET"}).then((list)=>{
                resolve(list);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public getConfiguration(id:number):Promise<Configuration>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/configurations/"+id,method:"GET"}).then((configuration)=>{
                resolve(configuration);
            }).catch(e => {
               reject(e);
            });
        });
    }

    public deleteConfiguration(id:number):Promise<any>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/configurations/"+id,method:"DELETE"}).then((responce)=>{
                resolve(responce);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public updateConfiguration(configuration: Configuration):Promise<Configuration>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/configurations",method:"PUT",data:configuration}).then((responce)=>{
                resolve(responce);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public createVisit(cpId:number,vId:number,date:number):Promise<void>{
        return this.httpClient.request({url:"api/ute/createVisit",method:"POST",queryParams:{"cpId":cpId,"vId":vId,"date":date}})
    }
    public createConfiguration(configuration: Configuration):Promise<Configuration>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/ute/configurations",method:"POST",data:configuration}).then(responce=>{
                resolve(responce);
            }).catch(e => {
                reject(e);
            });
        });
    }

    public findColoringConfigurationByClient(cId: number):Promise<Rest.ConfigurationColoring>{
        return new Promise((resolve, reject) => {
            this.httpClient.request({url:"api/panelcoloring/"+cId,method:"GET"}).then(responce=>{
                resolve(responce);
            }).catch(e => {
                reject(e);
            });
        });
    }
}
