import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class DevicetypeService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.DeviceType>> {
return this.client.DeviceType_getPage(arg0);
}

changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
return this.client.DeviceType_changeStatus(arg0, queryParams);
}

findDeviceType(devicetype: string, queryParams?: { devicetype?: number; }): Rest.RestResponse<Rest.DeviceType> {
return this.client.DeviceType_findDeviceType(devicetype, queryParams);
}
}
