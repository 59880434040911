<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">FENCE ALARM
          CONFIGURATION (FENCE)</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="fenceAlarm.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Fence Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="FenceOptions" (onChange)="fenceModeChange($event)" [(ngModel)]="fenceAlarm.mode"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Latitude Center">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="number" pInputText [(ngModel)]="fenceAlarm.circle.latitudeCenter" class="txtbStyle">
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Longitude Center">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="number" pInputText [(ngModel)]="fenceAlarm.circle.longitudeCenter" class="txtbStyle">
        </div>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Fence Radius">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="fenceAlarm.circle.radius" [min]="1" [max]="9999"></p-spinner>
          <span class="ui-inputgroup-addon">1~9999</span>
          <span class="ui-inputgroup-addon">m</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Fence Alarm Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="FenceAlarmOptions" [(ngModel)]="fenceAlarm.modeAlarmFence"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Alarm Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="AlarmOptions" [(ngModel)]="fenceAlarm.modeAlarm"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

  </div>

  <div *ngIf="!isCircle">

    <div class="row align-items-center">

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Latitude1">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="fenceAlarm.rectangle.latitude1" [min]="-90" [max]="90"></p-spinner>
              <span class="ui-inputgroup-addon">-90~90</span>
              <span class="ui-inputgroup-addon">degree</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Longitude1">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="fenceAlarm.rectangle.longitude1" [min]="-180" [max]="180"></p-spinner>
              <span class="ui-inputgroup-addon">-180~180</span>
              <span class="ui-inputgroup-addon">degree</span>
            </div>
          </div>
        </div>

      </div>

      <div class="row align-items-center">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Latitude2">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="fenceAlarm.rectangle.latitude2" [min]="-90" [max]="90"></p-spinner>
              <span class="ui-inputgroup-addon">-90~90</span>
              <span class="ui-inputgroup-addon">degree</span>
            </div>
          </div>
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Longitude2">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="fenceAlarm.rectangle.longitude2" [min]="-180" [max]="180"></p-spinner>
              <span class="ui-inputgroup-addon">-180~180</span>
              <span class="ui-inputgroup-addon">degree</span>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</p-accordionTab>