<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Idle Event Configuration ($ST+IDLE)</span>
      </div>
      <div class="right">
        <p-inputSwitch [(ngModel)]="idleEvent.enabled" (onChange)="handleChange($event)"></p-inputSwitch>
    </div>
    </div>
  </p-header>
      <div class="ui-g">

        <div class="ui-g" #ContentDiv>

          <div class="ui-g-12 ui-md-6">
            <form-input  fieldName="Report Action" >
            <p-dropdown [options]="ReportModeOptions"
              [(ngModel)]="idleEvent.reportMode" [style]="{'width':'190px'}"></p-dropdown>
            </form-input>
          </div>
                  
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Idle Speed" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idleEvent.idleSpeed" [min]="0" [max]="65535"></p-spinner>
              <span class="ui-inputgroup-addon">0~65535</span>
              <span class="ui-inputgroup-addon" >km/h</span>
            </div>
          </form-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Duration" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idleEvent.duration" [min]="1" [max]="65535"></p-spinner>
              <span class="ui-inputgroup-addon">1~65535</span>
              <span class="ui-inputgroup-addon" >min</span>
            </div>
          </form-input>
        </div>      
      </div>
      </div>
</p-accordionTab>
