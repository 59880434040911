import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../rest/entity.service";
import { Rest } from "../../../rest/rest_client";
import { Severity } from "app/services/notifications-service/notifications.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

import InventoryReport = Rest.InventoryReport
import InventoryReportRequest = Rest.InventoryReportRequest;

@Injectable()
export class InventoryReportsService extends EntityService<InventoryReport>{
  client: Rest.RestApplicationClient;

  constructor(
    private jquery: JQueryHTTPClient,
    private http: HttpClient
  ) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  get(): Rest.RestResponse<InventoryReport[]> {
    try {
      return this.client.InventoryReport_get();
    } catch (error) {
      console.error("Error getting InventoryReports:", error);
      throw error;
    }
  }

  generateReport(inventoryReportRequest: InventoryReportRequest): Rest.RestResponse<any> {
    try {
      return this.client.InventoryReport_generateReport(inventoryReportRequest);
    } catch (error) {
      console.error("Error generating report:", error);
      throw error;
    }
  }

}

export interface PhaseChangeEvent {
  phase: number;
  reportData?: InventoryReport;
  formData?: any;
  resetData?: boolean;
}
