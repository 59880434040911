import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-temperature-alarm-gl320m',
  templateUrl: './temperature-alarm-gl320m.component.html',
  styleUrls: ['./temperature-alarm-gl320m.component.css']
})
export class TemperatureAlarmGl320mComponent extends ServerValidatedFormComponent implements OnInit {

  modeOptions: any = [
    { label: "Disable this function", value: "DISABLE" },
    { label: "Report +RESP:GTTEM when the current temperature is lower than <Min Temperature>", value: "REPORT_LOWER_THAN_MIN_TEMP" },
    { label: "Report +RESP:GTTEM when the current temperature is inside the temperature range", value: "REPORT_INSIDE_RANGE" },
    { label: "Report +RESP:GTTEM when the current temperature is higher than <Max Temperature>", value: "REPORT_HIGHER_THAN_MAX_TEMP" },
    { label: "Report +RESP:GTTEM when the current temperature within or outside the range", value: "REPORT_INSIDE_OR_OUTSIDE_RANGE" },
  ];
  
  @Input() temParameters: Rest.TemperatureAlarmGL320M;
  
  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.temParameters);
    }
  }
}
