import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-track-event-config-form",
  templateUrl: "./track-event-config-form.component.html",
  styleUrls: ["./track-event-config-form.component.css"],
})
export class TrackEventConfigFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() track: Rest.TrackEventConfiguration | Rest.Track2EventConfiguration;
  @Input() isTrack2: Boolean;
  trackModeSwitch: boolean;

  @ViewChild("trackevent") trackevent;

  availableTrackConfigModes: SelectItem[];
  availableTrackBasisModes: SelectItem[];
  availableTrack2InputValues: SelectItem[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes["track"]) {
      this.trackModeSwitch =
        this.track.mode ==
        RestExt.TrackConfigModes[RestExt.TrackConfigModes.DISABLE]
          ? false
          : true;
    }
  }
  ngOnInit() {
    this.availableTrackConfigModes = RestExt.getEnumItems(
      RestExt.TrackConfigModes,
      this.i18n
    );
    this.availableTrackBasisModes = RestExt.getEnumItems(
      RestExt.TrackBasisModes,
      this.i18n
    );
    this.availableTrack2InputValues = RestExt.getEnumItems(
      RestExt.TrackInputValues,
      this.i18n
    );
    //this.track.mode = "DISABLE";
    this.trackModeSwitch =
      this.track.mode ==
      RestExt.TrackConfigModes[RestExt.TrackConfigModes.DISABLE]
        ? false
        : true;
  }

  trackEventModeChange(event) {
    this.trackModeSwitch =
      this.track.mode ==
      RestExt.TrackConfigModes[RestExt.TrackConfigModes.DISABLE]
        ? false
        : true;
  }
}
