<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display"
    appendTo="body">
    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="policyNumber" class="custom-label" i18n>
                    {{'tableManagement.insurance.policyNumber' | translate}}
                </label>
                <div class="p-0"></div>
                <input type="text" id="policyNumber" class="form-control custom-input" [(ngModel)]="entity.policyNumber"
                    [disabled]="!formManipulation">
            </div>

            <div class="col-sm-6">
                <label for="insurance-provider" class="custom-label" i18n>{{'tableManagement.insurance.insurer' |
                    translate}}</label>
                <div class="p-0"></div>
                <p-dropdown [options]='providerList' [(ngModel)]='entity.provider'
                    placeholder="{{'general.select' | translate}} {{'tableManagement.insurance.insurer' | translate}}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                    filterBy="name" (onChange)="onChangeProvider($event)" (onFilter)="onFilterProvider($event)"
                    [disabled]="!formManipulation">
                </p-dropdown>
            </div>
        </div>


        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="agent" class="custom-label" i18n>
                    {{'tableManagement.insurance.agent' | translate}}
                </label>
                <div class="p-0"></div>
                <input  placeholder="Sin asignar" type="text" id="agent" class="form-control custom-input" [(ngModel)]="entity.agent"
                    [disabled]="!formManipulation">
            </div>

            <div *ngIf="!vehicleId" class="col-sm-6">
                <label for="insurance-vehicle" class="custom-label" i18n>{{'tableManagement.vehicle.vehicle' |
                    translate}}</label>
                <div class="p-0"></div>
                <p-dropdown [options]='vehicleList' [(ngModel)]='entity.vehicle'
                    placeholder="{{'general.select' | translate}} {{'tableManagement.vehicle.vehicle' | translate}}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                    filterBy="name" (onChange)="onChangeVehicle($event)" (onFilter)="onFilterVehicle($event)"
                    [disabled]="!formManipulation">
                </p-dropdown>
            </div>

        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="cost" class="custom-label" i18n>
                    {{'tableManagement.insurance.cost' | translate}}
                </label>

                <div class="p-0"></div>

                <div class="col-6 input-wrapper">
                    <p-inputNumber *ngIf="canSeeCosts" [(ngModel)]="entity.cost" mode="decimal" locale="es-ES"
                        (onKeyDown)="onKeyDownDecimal($event)" [disabled]="!formManipulation"></p-inputNumber>

                    <p-inputNumber *ngIf="!canSeeCosts" [ngModel]="" mode="decimal" locale="es-ES"
                        (onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
                    <span class="input-symbol">€</span>
                </div>

            </div>

            <div class="col-sm-6">
                <label for="fleetCost" class="custom-label" i18n>
                    {{'tableManagement.insurance.fleetCost' | translate}}
                </label>

                <div class="p-0"></div>

                <div class="col-6 input-wrapper">
                    <p-inputNumber *ngIf="canSeeCosts" [(ngModel)]="entity.fleetCost" mode="decimal" locale="es-ES"
                        (onKeyDown)="onKeyDownDecimal($event)" [disabled]="!formManipulation"></p-inputNumber>

                    <p-inputNumber *ngIf="!canSeeCosts" [ngModel]="''" mode="decimal" locale="es-ES"
                        (onKeyDown)="onKeyDownDecimal($event)" [disabled]="true"></p-inputNumber>
                    <span class="input-symbol">€</span>
                </div>

            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="startDate" class="custom-label" i18n>
                    {{'general.startDate' | translate}}
                </label>

                <div class="p-0"></div>

                <p-calendar [(ngModel)]='startDateInsurance' [showTime]='false' [style]="{'width':'200px'}"
                    showIcon='true' appendTo='body' [firstDayOfWeek]="1" dateFormat='{{i18n.dateFormat}}'
                    [disabled]="!formManipulation"></p-calendar>
            </div>

            <div class="col-sm-6">
                <label for="endDate" class="custom-label" i18n>
                    {{'general.endDate' | translate}}
                </label>

                <div class="p-0"></div>

                <p-calendar [(ngModel)]='endDateInsurance' [showTime]='false' [style]="{'width':'200px'}"
                    showIcon='true' appendTo='body' dateFormat='{{i18n.dateFormat}}'
                    [disabled]="!formManipulation"></p-calendar>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-6">
                <label for="insurance-type" class="custom-label" i18n>{{'tableManagement.insurance.insuranceType' |
                    translate}}</label>

                <div class="p-0"></div>

                <p-dropdown [options]='insuranceTypeList' [(ngModel)]='entity.insuranceType'
                    placeholder="{{'general.select' | translate}} {{'tableManagement.insurance.insuranceType' | translate}}"
                    dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' [filter]="true"
                    filterBy="name" (onChange)="onChangeInsuranceType($event)"
                    (onFilter)="onFilterInsuranceType($event)" [disabled]="!formManipulation">
                </p-dropdown>
            </div>

            <div class="col-sm-6">
                <label for="fine-name" class="custom-label" i18n>
                    {{'general.active' | translate }}
                </label>

                <div class="p-0"></div>

                <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                    type="checkbox" [(ngModel)]="active" [disabled]="!formManipulation">
            </div>
        </div>

        <!-- OBSERVATIONS -->
        <div class="row mt-3">
            <div class="col-md-6">
                <label for="observations" class="custom-label" i18n>{{'general.observations' |
                    translate}}</label>

                <textarea rows="2" cols="80" pInputTextarea [pTooltip]="entity.observations" tooltipPosition="top"
                    [(ngModel)]="entity.observations" class="observations" fitContent="true"
                    [disabled]="!formManipulation"></textarea>
            </div>
        </div>

        <div class="row mt-3">
            <label for="cancellationDate" class="custom-label" i18n>
                {{'tableManagement.financing.cancelationDate' | translate}}
            </label>

            <div class="p-0"></div>

            <p-calendar [(ngModel)]='disabledDateInsurance' [showTime]='false' [style]="{'width':'200px'}"
                showIcon='true' appendTo='body' [firstDayOfWeek]="1" dateFormat='{{i18n.dateFormat}}'
                [disabled]="!formManipulation"></p-calendar>
        </div>

        <div class="p-2"></div>
        <!-- COMPONENT -->
        <p-panel *ngIf="!isNew" header="{{ 'tableManagement.insurance.insuranceComponentTitle' | translate }}"
            [toggleable]="true" class='w-100' [collapsed]="true">
            <app-insurance-component-list [insuranceId]="entity.id"></app-insurance-component-list>
        </p-panel>

        <!-- taula de documents -->
        <div class="row mt-3" *ngIf="!isNew">
            <app-doc-table type="insurance" [entity]="entity" [nested]="true" [ids]="ids" (return)="refresh()">
            </app-doc-table>
        </div>

    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="saveChanges()" [disabled]="!formManipulation" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>

                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveChanges()" [disabled]="!formManipulation" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>

            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(!entity.disabled)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>

<!-- CONFIRM SAVE EVENT DIALOG -->
<p-confirmDialog appendTo="body" #missingFieldsDialog [header]=" 'confirm.critic' | translate "
    icon='pi pi-exclamation-triangle'>
    <ng-template pTemplate="footer">
        <button (click)="missingFieldsDialog.accept()" class="custom-button">
            OK
        </button>
    </ng-template>
</p-confirmDialog>