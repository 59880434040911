import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { Rest } from 'app/rest/rest_client';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { Subject, Subscription } from 'rxjs';
import { SpaceService } from '../service/space.service';
import { TranslationsService } from '../service/translations.service';
import { CampaignService } from '../service/campaign.service';
import { ProviderService } from '../service/provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignFormComponent extends EntityFormComponent<Rest.FidSpace> implements OnInit, OnDestroy {

	@Input() space: Rest.FidSpace;
	@Input() isNew: boolean;

	isDialogVisible: boolean;
	isEditingDisabled: boolean;
	errorMessages: string[];
	spaceFormTitle: string;
	errorContainer: ElementRef;
	changesPending: boolean;

	editingSubscription: Subscription;
	creatingSubscription: Subscription;
	editMode = false;

	/** variables FidSpace */
	//space: Rest.FidSpace;
	selectedSpace: Rest.FidSpace;
	campaign: Rest.FidCampaigns;
	editedCampaign: Rest.FidCampaigns;
	selectedProvider: Rest.FidProvider;
	spaceImageUrl: string;
	spaceImageFile: any;
	spaceEncodedImage: any;
	eventTypeList: Rest.EventTypeRacing[];
	selectedEventType: Rest.EventTypeRacing;
	providerList: Rest.FidProvider[];
	startDate: Date | null;
	endDate: Date | null;

	/* Event Code Type variables */
	eventCodeTypeList: Rest.EventCodeTypeRacing[];
	selectedEventCodeType: Rest.EventCodeTypeRacing;
	generalEventCode: Rest.CodesEvents;
	uniqueEventCodeList: Rest.CodesEvents[];

	/* POI variables */
	map: any;
	poiList: Rest.POIRacing[];
	selectedPoi: Rest.POIRacing | null;
	selectedTrack: any;
	showPoiDeleteConfirmDialog: boolean;
	entityKmlFileLoaded: boolean;
	disablePoiPanel: boolean;
	isEnabled: boolean;

	/* multilanguage fields */
	translations: Rest.Translations;
	translationSubscription: Subscription;

	sendNotifControl: Rest.FidSendNotifControl | null;

   constructor(
      public spaceService: SpaceService,
		public campaignService: CampaignService,
		public providerService: ProviderService,
      private authService: AuthenticationService,
      public i18n: I18nService,
      public notificationsService: NotificationsService,
		private translate: TranslateService,
		public translationsService: TranslationsService,
		protected confirmationService: ConfirmationService
   ) {
      super(spaceService, notificationsService, i18n, authService);
      this.editMode = false;
      this.errorMessages = [];
		this.isEditingDisabled = false;
   }

   ngOnInit() {
		// console.log("[CAMPAIGN-FORM] OnInit *****");
   	super.ngOnInit();
		this.translationSubscription = this.campaignService.endTranslationEditing
      .subscribe(
         (item: Rest.FidCampaigns) => {
			  this.editedCampaign = item;
			  this.refresh();
         }
      );
		this.fillProviders();
		// console.log(this.isNew);
		if (this.isNew) {
			this.initCampaign();
		}else{
			this.campaign = JSON.parse(JSON.stringify(this.space.campaign));
			this.initCampaign();
		}
		this.changesPending = false;
		this.sendNotifControl = null;
   }

	ngOnDestroy() {
		this.translationSubscription.unsubscribe();
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	console.log("[CAMPAIGN-FORM] ngOnChanges ***");
	// 	console.log(changes);
	// 	this.campaignService.changesPending.next();
	// }

	public initCampaign() {
		// console.log("[CAMPAIGN-FORM] initSpace ***");
		// console.log("this.isNew: " + this.isNew);
		this.isEditingDisabled = false;
		// console.log(this.campaign);

		if (this.isNew) {
			this.campaign = this.campaignService.initCampaign();
			this.isEnabled = false;
		} else {
			this.isEnabled = this.campaign.active;
			if (this.campaign.image) {
				this.spaceImageUrl = null;
				this.spaceEncodedImage = this.campaign.image.imageEncoded;
			}else{
				this.spaceImageUrl = 'assets/icons/no-image-available.svg';
				this.spaceEncodedImage = null;
			}
			this.startDate = new Date(this.campaign.startDate);
			this.endDate = new Date(this.campaign.endDate);
			this.isEditingDisabled = false;
			this.getNotifControl(this.campaign);
		}
	}

	public imageChange(event: any): void {
		// console.log("[CAMPAIGN-FORM] imageChange() ****** ");
		// console.log(event);
		let reader = new FileReader();
		this.spaceImageFile = event.target.files[0];
		reader.onload = (event: any) => {
			 this.spaceImageUrl = event.target.result;
			 this.spaceEncodedImage = null;
		};
		reader.readAsDataURL(event.target.files[0]);
		//this.refresh();
	}

	public eventStartDateChange(): void {
		// console.log("[CAMPAIGN-FORM] eventStartDateChange() **** " + this.startDate);
	}

	public eventEndDateChange(): void {
		// console.log("[CAMPAIGN-FORM] eventStartDateChange() **** " + this.endDate);
	}

	public onTranslations(field: string): void {
		switch(field) {
			case "name": {
				this.translations = {
					esp: this.campaign.name,
					cat: this.campaign.nameCat,
					eng: this.campaign.nameEng,
					fra: this.campaign.nameFra,
					ita: this.campaign.nameIta,
					textArea: false,
					field: field,
					type: "campaign",
					id: this.campaign.id
				}
				break;
			}
			case "description": {
				this.translations = {
					esp: this.campaign.description,
					cat: this.campaign.descriptionCat,
					eng: this.campaign.descriptionEng,
					fra: this.campaign.descriptionFra,
					ita: this.campaign.descriptionIta,
					textArea: true,
					field: field,
					type: "campaign",
					id: this.campaign.id
				}
				break;
			}
			case "infoLink": {
				this.translations = {
					esp: this.campaign.infoLink,
					cat: this.campaign.infoLinkCat,
					eng: this.campaign.infoLinkEng,
					fra: this.campaign.infoLinkFra,
					ita: this.campaign.infoLinkIta,
					textArea: false,
					field: field,
					type: "campaign",
					id: this.campaign.id
				}
				break;
			}
			case "buyLink": {
				this.translations = {
					esp: this.campaign.buyLink,
					cat: this.campaign.buyLinkCat,
					eng: this.campaign.buyLinkEng,
					fra: this.campaign.buyLinkFra,
					ita: this.campaign.buyLinkIta,
					textArea: false,
					field: field,
					type: "campaign",
					id: this.campaign.id
				}
				break;
			}
			default: {
				//statements;
				break;
			}
		}
		this.translationsService.startEditTranslations.next(this.translations);

	}

	/** event **/
	public refreshTranslations(translations: Rest.Translations): void {
		// console.log("[CAMPAIGN-FORM] refreshTranslations *****");
		// console.log(translations);
		this.translationsService.refreshTranslations(translations);
	}

	public refresh() {
		// console.log("[CAMPAIGN-FORM] refresh *****");
		// console.log(this.editedCampaign);
		if (this.editedCampaign.name !== null) this.campaign.name = this.editedCampaign.name;
		if (this.editedCampaign.nameCat !== null) this.campaign.nameCat = this.editedCampaign.nameCat;
		if (this.editedCampaign.nameEng !== null) this.campaign.nameEng = this.editedCampaign.nameEng;
		if (this.editedCampaign.nameFra !== null) this.campaign.nameFra = this.editedCampaign.nameFra;
		if (this.editedCampaign.nameIta !== null) this.campaign.nameIta = this.editedCampaign.nameIta;
		if (this.editedCampaign.description !== null) this.campaign.description = this.editedCampaign.description;
		if (this.editedCampaign.descriptionCat !== null) this.campaign.descriptionCat = this.editedCampaign.descriptionCat;
		if (this.editedCampaign.descriptionEng !== null) this.campaign.descriptionEng = this.editedCampaign.descriptionEng;
		if (this.editedCampaign.descriptionFra !== null) this.campaign.descriptionFra = this.editedCampaign.descriptionFra;
		if (this.editedCampaign.descriptionIta !== null) this.campaign.descriptionIta = this.editedCampaign.descriptionIta;
		if (this.editedCampaign.textColor !== null) this.campaign.textColor = this.editedCampaign.textColor;
		if (this.editedCampaign.startDate !== null) this.campaign.startDate = this.editedCampaign.startDate;
		if (this.editedCampaign.endDate !== null) this.campaign.endDate = this.editedCampaign.endDate;
		if (this.editedCampaign.infoLink !== null) this.campaign.infoLink = this.editedCampaign.infoLink;
		if (this.editedCampaign.infoLinkCat !== null) this.campaign.infoLinkCat = this.editedCampaign.infoLinkCat;
		if (this.editedCampaign.infoLinkEng !== null) this.campaign.infoLinkEng = this.editedCampaign.infoLinkEng;
		if (this.editedCampaign.infoLinkFra !== null) this.campaign.infoLinkFra = this.editedCampaign.infoLinkFra;
		if (this.editedCampaign.infoLinkIta !== null) this.campaign.infoLinkIta = this.editedCampaign.infoLinkIta;
		if (this.editedCampaign.buyLink !== null) this.campaign.buyLink = this.editedCampaign.buyLink;
		if (this.editedCampaign.buyLinkCat !== null) this.campaign.buyLinkCat = this.editedCampaign.buyLinkCat;
		if (this.editedCampaign.buyLinkEng !== null) this.campaign.buyLinkEng = this.editedCampaign.buyLinkEng;
		if (this.editedCampaign.buyLinkFra !== null) this.campaign.buyLinkFra = this.editedCampaign.buyLinkFra;
		if (this.editedCampaign.buyLinkIta !== null) this.campaign.buyLinkIta = this.editedCampaign.buyLinkIta;

		// console.log(this.campaign);
	}

	public saveCampaign() {

		// console.log("[CAMPAIGN-FORM] saveCampaign ***");
		// console.log(this.campaign);
		// console.log(this.isNew);
		// console.log(this.spaceImageFile);
		if (this.validateCampaignForm()) {

			let routeData = {
				id: this.isNew ? 0 : this.campaign.id,
				provider: { id: this.campaign.provider.id },
				name: this.campaign.name,
				nameCat: this.campaign.nameCat,
				nameEng: this.campaign.nameEng,
				nameFra: this.campaign.nameFra,
				nameIta: this.campaign.nameIta,
				description: this.campaign.description,
				descriptionCat: this.campaign.descriptionCat,
				descriptionEng: this.campaign.descriptionEng,
				descriptionFra: this.campaign.descriptionFra,
				descriptionIta: this.campaign.descriptionIta,
				textColor: this.campaign.textColor,
				startDate: this.startDate,
				endDate: this.endDate,
				infoLink: this.campaign.infoLink,
				infoLinkEng: this.campaign.infoLinkEng,
				infoLinkCat: this.campaign.infoLinkCat,
				infoLinkFra: this.campaign.infoLinkFra,
				infoLinkIta: this.campaign.infoLinkIta,
				buyLink: this.campaign.buyLink,
				buyLinkCat: this.campaign.buyLinkCat,
				buyLinkEng: this.campaign.buyLinkEng,
				buyLinkFra: this.campaign.buyLinkFra,
				buyLinkIta: this.campaign.buyLinkIta,
				createdByUser: this.isNew ? { id: this.authService.user.id } : { id: this.campaign.createdByUser.id },
				creationDate: this.isNew ? new Date() : this.campaign.creationDate,
				expirationDate: null,
				campaignType: this.isNew ? "ROUTE" : this.campaign.campaignType,
				active: this.isNew ? false : this.campaign.active,
				image: (!this.spaceImageFile && this.campaign.image !== null) ? { id: this.campaign.image.id } : null,
			};
			/* Stringify the custom Racing Event structure */
			let DataString = JSON.stringify(routeData);
			// console.log(DataString);

			/* Add new Route */
			if (this.isNew) {
				 this.spaceService.addSpace(DataString, this.spaceImageFile).then((space) => {
					  if (space) {
							this.spaceService.spaceAdded.next();
					  }
				 });
			}
			/* Save existing Route */
			else {
				this.campaignService.updateCampaign(DataString, this.spaceImageFile).then((campaign) => {
				   if (campaign) {
						this.campaignService.campaignSaved.next();
						this.isDialogVisible = false;
					}
				});
			}
	  }
	}

	private validateCampaignForm(): boolean {
		// console.log("[CAMPAIGN-FORM] validateCampaignForm ***");
		this.errorMessages = [];

		// console.log(this.campaign.name);
		// console.log(this.startDate);
		// console.log(this.endDate);
		// console.log(this.campaign.provider);

		if (this.campaign.name === null || this.campaign.name === '' ||
			this.startDate === null ||
			this.endDate === null ||
			this.campaign.provider === null	) {
			this.errorMessages.push(
				 this.translate.instant('error.required-field.generic'),
			);
	   }
		// console.log(this.errorMessages);
		return this.errorMessages.length === 0;
  }

  public fillProviders() {
	// console.log("[CAMPAIGN-FORM] fillProviders ***");
	const promise = new Promise<void>((resolve, reject) => {
		const that = this;
		this.providerService
			.getProviders()
			.then(
				 function (response) {
					that.providerList = response;
					// console.log(that.providerList);
				 },
				 /* failure */
				 function (error) {
					  console.log('The request failed: ' + error);
					  resolve();
				 },
			)
			.catch(function (reason) {
				 console.log('Catched: ' + reason);
				resolve();
			});
	});
  }

   public toggleActivation(event) {
		// console.log("[CAMPAIGN-FORM] toggleActivation ***");
		// console.log(event);
		this.changesPending = true;
	}

	public scheduleSendNotif(space: Rest.FidSpace): void {

		let confirmationMessage = this.translate.instant('promotedRoutes.notificationMsg');
		//console.log(confirmationMessage);
	   this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				let queryParams = {
					campaignId: space.campaign.id
				};
			 	this.campaignService.createSendNotif(queryParams).then((response) => {
					if (response) {
						this.notificationsService.add(
						 	Severity.success,
						 	this.translate.instant('success.success'),
						 	this.translate.instant('promotedRoutes.notification.send')
						);
						this.refreshNotifState();
						this.sendNotifControl = response;
				  	}
			 	});
			},
	   });

	}

	public getNotifControl(campaign: Rest.FidCampaigns) {

		// console.log("[CAMPAIGN-SERVICE] getNotifControl *** ");
		let queryParams = {
			campaignId: campaign.id
		};

		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.campaignService
				.getNotifControl(queryParams)
				.then(
					function (response) {
						that.sendNotifControl = response;
					},
					/* failure */
					function (error) {
					   console.log('The request failed.');
						that.sendNotifControl = null;
						resolve();
					},
				)
				.catch(function (reason) {
					 console.log('Catched: ' + reason);
					resolve();
				});
		});

	}

	public cancelSendNotif(space: Rest.FidSpace): void {

		let confirmationMessage = this.translate.instant('promotedRoutes.cancelNotificationMsg');
		//console.log(confirmationMessage);
	   this.confirmationService.confirm({
			message: confirmationMessage,
			accept: () => {
				let httpParams = new HttpParams().set('campaignId', space.campaign.id);
				let options = { params: httpParams };
				this.campaignService.deleteNotifControl(options)
				.then((response) => {
					if (response) {
						this.refreshNotifState();
						this.notificationsService.add(
						 	Severity.success,
						 	this.translate.instant('success.success'),
						 	this.translate.instant('success.delete.request')
						);
				  	}
			 	});
			},
	   });

	}

	public refreshNotifState() {
		this.getNotifControl(this.campaign);
	}

}
