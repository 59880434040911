<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Report Detection Event Configuration ($WP+SETDR)</span>
            </div>
        </div>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="low Voltage Level" >
          <div class="ui-inputgroup">
            <p-spinner size="3" [step]="0.01" [(ngModel)]="dr.lowVoltageLevel" [min]="0" [max]="30"></p-spinner>
            <span class="ui-inputgroup-addon">0~30</span>
            <span class="ui-inputgroup-addon" >v</span>
          </div>
        </form-input>
      </div>
  
    <div class="ui-g-12">
        <form-input fieldName="polling">
          
        </form-input>
    </div>
    <div class="ui-g-12">

        <div class="ui-g-12">
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input1" [(ngModel)]="dr.pollingOnInput1">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input2" [(ngModel)]="dr.pollingOnInput2">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input3" [(ngModel)]="dr.pollingOnInput3">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input4" [(ngModel)]="dr.pollingOnInput4">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power low" [(ngModel)]="dr.pollingOnMainPowerLow">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power lost" [(ngModel)]="dr.pollingOnMainPowerLost">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power voltage recover" [(ngModel)]="dr.pollingOnMainPowerVoltageRecover">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power recover" [(ngModel)]="dr.pollingOnMainPowerRecover">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Battery voltage low" [(ngModel)]="dr.pollingOnBatteryVoltageLow">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Battery voltage recover" [(ngModel)]="dr.pollingOnBatteryVoltageRecover">
                </p-checkbox>
            </div>
        </div>
    </div>

    <div class="ui-g-12">
        <form-input fieldName="logging">
          
        </form-input>
    </div>
    <div class="ui-g-12">

        <div class="ui-g-12">
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input1" [(ngModel)]="dr.loggingOnInput1">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input2" [(ngModel)]="dr.loggingOnInput2">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input3" [(ngModel)]="dr.loggingOnInput3">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Input4" [(ngModel)]="dr.loggingOnInput4">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power low" [(ngModel)]="dr.loggingOnMainPowerLow">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power lost" [(ngModel)]="dr.loggingOnMainPowerLost">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Battery voltage low" [(ngModel)]="dr.loggingOnBatteryVoltageLow">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power voltage recover" [(ngModel)]="dr.loggingOnMainPowerVoltageRecover">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Main power recover" [(ngModel)]="dr.loggingOnMainPowerRecover">
                </p-checkbox>
            </div>
            <div class="ui-g-4">
                <p-checkbox binary="true" label="Battery voltage recover" [(ngModel)]="dr.loggingOnBatteryVoltageRecover">
                </p-checkbox>
            </div>
        </div>
    </div>
    </div>
    
  </p-accordionTab>
