import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Subscription, timer} from 'rxjs';
import 'rxjs/add/operator/takeWhile';

import {I18nService} from '../../../services/i18n/i18n.service';
import {ConnectionStatus, RealTimeDataService} from '../../../services/rt-data/rt-data.service';

@Component({
  selector: 'app-connection-watch',
  templateUrl: './connection-watch.component.html',
  styleUrls: ['./connection-watch.component.css'],
  providers: [],
})

export class ConnectionWatchComponent implements OnInit, OnDestroy {

  connectionStatus: any;
  reconnectionTimer: any;

  private showRTConnectionInfo: boolean;
  rtConnectionInfoText: string;
  private connectionTimer: Subscription;
  private alive = true;
  rtConnectionInfoSeverity: string;

  constructor(private i18n: I18nService, private rtDataService: RealTimeDataService) {
  }

  ngOnInit() {
    this.rtDataService.connectionStatusSubject.takeWhile(() => this.alive).subscribe((s: ConnectionStatus) => {
      if (this.connectionTimer != null) {
        this.connectionTimer.unsubscribe();
      }

      switch (s) {
        case "CONNECTED":
          this.rtConnectionInfoSeverity = "success";
          this.rtConnectionInfoText = this.i18n._('Connected');

          break;
        case "CONNECTING":
          this.rtConnectionInfoSeverity = "info";
          this.rtConnectionInfoText = this.i18n._('Connecting');
          break;
        case "NOT_CONNECTED":
        case "WAITING_TO_CONNECT":
          this.rtConnectionInfoSeverity = "error";

          /* TODO: Delete variable <<rtConnectionInfoText>>, since it is not used */
          this.rtConnectionInfoText = this.i18n._('Not connected. Next retry: ~');

          this.connectionTimer = timer(0, 1000).subscribe((t) => {

            this.rtConnectionInfoText = this.i18n._('Not connected. Next retry: ') + moment(new Date()).diff(this.rtDataService.nextConnectionTime, "s") + " s";
            this.reconnectionTimer = Math.abs(moment(new Date()).diff(this.rtDataService.nextConnectionTime, "s")) + " s";
          });

          break;
      }
      this.connectionStatus = this.rtConnectionInfoText;
      this.showRTConnectionInfo = true;
    });
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.connectionTimer != null) {
      this.connectionTimer.unsubscribe();
    }
  }

}
