<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GEO-FENCE CONFIGURATION
          (AT + GTGEO)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GeoFence
                Settings</span>
            </div>
          </div>
        </p-header>

        <!-- 
        <div class="col row dvcFields">
          <div class="col lblStyle">
      
          </div>
          <div class="col">
      
          </div>
        </div> 
      -->
        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="GEO ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" id="signalThreshold" size="2" [(ngModel)]="geoParameters.geoId" [min]="0"
                  [max]="4"></p-spinner>
                <span class="ui-inputgroup-addon">0~4</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown (onChange)="geoFenceModeChange(geoParameters.mode,$event)" [options]="modeOptions"
                [(ngModel)]="geoParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Latitude">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="number" pInputText [(ngModel)]="geoParameters.latitude"
                  [style]="{'width':'100%','min-width':'100px'}" />
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Longitude">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="number" pInputText [(ngModel)]="geoParameters.longitude"
                  [style]="{'width':'100%','min-width':'100px'}" />
              </div>
            </div>
          </div>

        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Radius">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" size="2" [(ngModel)]="geoParameters.radius" [min]="50" [max]="6000000"></p-spinner>
                <span class="ui-inputgroup-addon">50~6000000</span>
                <span class="ui-inputgroup-addon">m</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Check Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" size="2" [(ngModel)]="geoParameters.checkInterval" [min]="5"
                  [max]="86400"></p-spinner>
                <span class="ui-inputgroup-addon">5~86400</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>

        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner step="1" size="2" [(ngModel)]="geoParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>
        
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="geoParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>
        
        </div>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="State mode">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown [options]="modeStateOptions" [(ngModel)]="geoParameters.modeState"
            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Trigger mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="modeTriggerOptions" [(ngModel)]="geoParameters.modeTrigger"
              [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Trigger Report">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown [options]="modeReportTriggerOptions" [(ngModel)]="geoParameters.modeReportTrigger"
              [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        </div>

        <div *ngIf="firmware > 5" class="row row-cols-2 mt-3">
          <form-input fieldName="Use Last Point">
            <p-dropdown [options]="modeLastPointOptions" [(ngModel)]="geoParameters.useLastPoint"
              [style]="{'width':'340px'}"></p-dropdown>
          </form-input>
        </div>
      </p-accordionTab>
    </div>
  </div>

</p-accordionTab>