<p-dialog 
*ngIf="display" header="{{title}}" [(visible)]="display"
[transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false" [resizable]="false"
[closeOnEscape]="false"
[style]="{width: '40vw'}">
 
  <div class="content">
    <div class="row mt-4">
      <label for="name-doc" class="form-label custom-label mb-0 me-2" i18n>
        <!-- {{ 'documents.form.file' | translate }} -->
        {{ 'general.selectFile' | translate }} 
      </label>
    </div>
    <div class="row mt-1">
        <!-- ROW 1 -->
        <div class='col-md-11'>  
           <!--  <input pInputText [disabled]='true' [ngModel]='entity.document?.name' disabled="true" /> -->
           <input pInputText [disabled]='true' [ngModel]='documentFile?.name' style="width: 100%;"   disabled="true" />  
        </div>      
        <div class="col-md-1" style="padding-left: 0px;">
          <button id="uploadDocumentButton" class="btn btn-outline-dark" (click)="uploadDocumentInput.click()">
            ...
        </button>
        <input #uploadDocumentInput [hidden]="true" type="file" accept=".*" (change)="changeDocument($event)">
        </div>
        <br>
    </div>
    <div class="row  mt-4">
      <div class="col-7">
  </div>
      <div class="col-5">
        <button class="btn custom-button float-end " (click)="uploadFile()" i18n>
          {{'general.startProcess' | translate}}
      </button>
    </div>
    </div>
  </div>
</p-dialog>
