import { HttpClient } from "@angular/common/http";
import { Rest } from "app/rest/rest_client";

export namespace RestVehicleInventory {

   export interface HttpClient {
      request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R }): RestResponse<R>;
   }

   export type RestResponse<R> = Promise<R>;

   //#region DRIVER MODELS
   // export interface Driver {
   //    id: number;
   //    name: string;
   //    phone: string;
   //    email: string;
   //    status: boolean;
   //    created_at: string;
   //    updated_at: string;
   // }

   export interface DriverInventoryClient {
      constructor(httpClient: HttpClient);
   }
   // #endregion DRIVER MODELS

	export interface InvVehicleHtcRequest extends Rest.Trimmable {
		vehicleId: number,
		startDate?: Date,
		endDate?: Date
	}

	export interface InvVehicleHtcResponse extends Rest.Trimmable, Rest.WithId {
		vehicle: Rest.Vehicle;
		vehicleStatus: Rest.VehicleStatus[];
		vehicleUsage: Rest.VehicleUsage[];
		documents: Rest.InvDocument[];
		notes: Rest.InvNote[];
		//vehicleAssignments:
		//vehicleAccessories: null,
		//rentings: null,
		//insurances: null,
		//taxes: null,
		//fines: null,
		//maintenances: null,
		//cards: [],
		//maintenance: null,
		//driversAssignments: null
  }

	export interface BillingAccountDD extends Rest.BillingAccount {
		label: string;
	}

	export interface VehicleStatusDD extends Rest.VehicleStatus {
		label: string;
	}



	export class RestApplicationClient {
		constructor(protected httpClient: HttpClient) {
		}

		//HtcVehicle
		InvHtcVehicle_find(arg0: any): RestResponse<InvVehicleHtcResponse>{
			console.log(arg0);
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/inventory/htcvehicle`,
			 	data: arg0
			});
		}

	}

	function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
		let result = '';
		for (let i = 0 ; i < substitutions.length ; i++) {
			 result += template[i];
			 result += encodeURIComponent(substitutions[i]);
		}
		result += template[template.length - 1];
		return result;
   }

}

