import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { RealTimeDataService } from '../../services/rt-data/rt-data.service';

@Component({
    selector: 'app-dummy',
    templateUrl: './dummy.component.html',
    styleUrls: ['./dummy.component.css'],
})
export class DummyComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private auth: AuthenticationService, private rtDataService: RealTimeDataService) {
    }

    ngOnInit() {

        this.router.events.subscribe((r: any) => {
            let m = this.router.url.match(".*?map=(.*)");
            if (m) {
                this.auth.loginWithToken(decodeURIComponent(m[1])).then(() => {

                    this.rtDataService.initDetachedMode();
                    this.router.navigate(['map']);
                    console.log("you are in!!")

                });
            }
        });

    }


}
