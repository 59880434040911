import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { CustomMapComponent } from 'app/components/shared/custom-map/custom-map.component';
import { CustomMapOptions } from 'app/components/shared/custom-map/custom-map.model';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { RealtimeService } from 'app/rest/realtime.service';
import { Rest } from 'app/rest/rest_client';
import { CercaliaMapService } from 'app/services/cercalia-map/cercalia-map.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';

@Component({
  selector: 'app-vehicle-state',
  templateUrl: './vehicle-state.component.html',
  styleUrls: ['./vehicle-state.component.css'],
  providers: [RealtimeService],
})
export class VehicleStateComponent extends EntityFormComponent<Rest.VehicleDataMessage> implements OnInit {


  @Input() vehicleId: number;
  @ViewChild('mapVehicleState', { static: false }) map: CustomMapComponent;
  customMapOptions: CustomMapOptions = {};
  address: string;
  statusText: string;
  status: boolean;
  dateStatus:String;
  markers: Rest.Vertex[] = [];
  constructor(
    private realtimeService: RealtimeService,
    notificationsService: NotificationsService,
    protected i18n: I18nService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private cercaliaMapService: CercaliaMapService,
) {
    super(realtimeService, notificationsService, i18n, authenticationService);
}

ngOnInit() {
    super.ngOnInit();
}

async showForm() {
  this.markers = [];
  await this.searchStateVehicle(); // Wait for the searchStateVehicle to complete

  if (this.entity) {
    this.display = true;
  }


}

searchStateVehicle() {
  this.map = null;
  var that = this;
  return new Promise<void>((resolve, reject) => {
    this.realtimeService.getLastVehicleDataMessage(this.vehicleId)
      .then((data) => {
        this.entity = data;

        
        if(this.entity){
          //get the status
          const sensor = this.entity.sensors.find(s => s.tag === "IGNITION");

          //if vehicle has sensor IGNITION and is true
          if(sensor){
            console.log(sensor.time);
            const rawDate = new Date(sensor.time);
            const date = rawDate.toLocaleDateString() + " " + rawDate.toLocaleTimeString();
            this.statusText = sensor.rawValue ? this.translate.instant('sat.statusVehicle.statusTable.statusOn') : this.translate.instant('sat.statusVehicle.statusTable.statusOff');
            this.dateStatus = date;
            this.status = sensor.rawValue;
          }
        

          // Get the address       
          this.cercaliaMapService
          .reverseGeocode(this.entity.longitude, this.entity.latitude)
          .then((location) => {
            this.address = location.address + " / " + location.city + " - " + location.region + " / " + location.country;
          });
          const vertex = {
            longitude: this.entity.longitude,
            latitude: this.entity.latitude,
          }
          this.markers.push(vertex);

        }
        resolve();
      })
      .catch((error) => {
        this.notificationsService.add(Severity.warn, 'Warn', 'This vehicle doesn\'t exist or has no data');
        reject();
      });
  });
}

getStatusStyle(): any {
  if (this.status === true) {
    return { color: 'green', 'font-weight': 'bold' };
  } else if (this.status === false) {
    return { color: 'red', 'font-weight': 'bold' };
  } else {
    return {}; // Empty object for default style (black text)
  }
}

refresh(){
  this.searchStateVehicle();
}
}
