import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';

import {
	CreateZoneMarkerEvent,
	CustomMapOptions,
	DrawZoneType,
	UpdatePOIMarkerEvent,
	UpdateZoneMarkerEvent,
} from '../custom-map/custom-map.model';

import { ConfirmationService } from 'primeng/api';
import { Rest } from '../../../rest/rest_client';
import { RestExt } from '../../../services/rest-client-extension';
import { CercaliaMapService } from '../../../services/cercalia-map/cercalia-map.service';
import { Subject, interval } from 'rxjs';
import { delay } from 'q';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { UserTimePipe } from "app/components/shared/UserTimePipe";

//Importing the function from a JS file, in order to use it later
import { createCustomFullScreencontrol } from "../../../../../src/assets/js/cercaliaFullScreen";
import { TranslateService } from "@ngx-translate/core";

declare var jquery: any;
declare var $: any;

@Component({
	selector: 'app-custom-map',
	templateUrl: './custom-map.component.html',
	styleUrls: ['./custom-map.component.css'],
	providers: [CercaliaMapService, ConfirmationService, UserTimePipe],
})
export class CustomMapComponent implements OnInit, AfterViewInit, OnChanges {
	public map: any;
	public router: Router;
	public kml: any; //raquel
	public imgPopUp: string; //raquel
	//public popupMarker: any;
	public zIndex: any;

	@ViewChild('canvas') canvas: ElementRef;

	//data that the map recieves
	@Input() mapId: string; //div's id that contains the cercalia map
	@Input() options: CustomMapOptions; //options for map configuration
	@Input() display: boolean = true; //indica si el componente padre que contiene el mapa esta visible o no.
	@Input() showCercacliaOptions: boolean = true; //Shows the top options of the cercalia map, made for small divs.
	@Input() customMapStyle: string;

	@Output() loadMap = new EventEmitter<boolean>();
	loadMapPromise = new Promise<void>((resolve, reject) => {
		//resolve();
		// console.log(this.map);
		if (this.map != null) {
			resolve();
		}
		else {
			const subscription = interval(1000).subscribe((data) => {
				if (this.map != null) {
					subscription.unsubscribe();
					resolve();
				}
			});
		}
	});

	@Input() pois: Rest.POI[]; //lista de pois que se pintan en el mapa
	@Input() racingPois: Rest.POIRacing[];
	@Input() iconPOI: string; //icono generico para pintar los pois (si pois[n] tiene icono, este tiene preferencia)
	@Input() markers: Rest.Vertex[];
	//Eventos generados por el mapa
	//se quiere eliminar un poi. De momento solo elimina marcador.
	//TODO No hay backend, pero esta logica la debe gestionar el componente padre.

	@Output() eventDeletePOI = new EventEmitter<any>();
	//Evento que indica la nueva posición del marcador que estamos intentando crear. El evento solo comparte la nueva location
	@Output() eventCreatePOI = new EventEmitter<any>();
	//Evento que indica el poi que se quiere actualizar y su nueva posicion
	@Output() eventUpdatePOI = new EventEmitter<UpdatePOIMarkerEvent>();
	//   end POIS    //
	// ------------- //

	// ------------- //
	//     Vehicles      //
	@Input() iconVehicle: string; //icono generico para pintar los vehiculos (si vehiculos[n] tiene icono, este tiene preferencia)

	//   end Vehicles    //
	// ------------- //

	// ------------- //
	//     ZONES     //
	@Input() zones: Rest.Zone[]; //lista de zonas que se pintan en el mapa
	//Evento que indica la nueva posición del marcador que estamos intentando crear. El evento solo comparte la nueva location
	@Output() eventCreateZone = new EventEmitter<CreateZoneMarkerEvent>();
	//Evento que indica el poi que se quiere actualizar y su nueva posicion
	@Output() eventUpdateZone = new EventEmitter<UpdateZoneMarkerEvent>();
	//   end POIS    //
	// ------------- //

	@Output() eventZoomChanged = new EventEmitter<any>();
	@Output() eventZoneClicked = new EventEmitter<Rest.Zone>();
	@Output() eventVehicleClicked = new EventEmitter<RestExt.ExtendedVehicleDataMessage>();

	marker = null;

	client;

	/**
	 * Styles for the zones that we're drawing/editing in the map
	 *
	 * @private
	 * @memberof CustomMapComponent
	 */
	private defaultTargetFeatureStyle = {
		strokeColor: '#ff0000',
		outlineColor: '#ff0000',
		fillColor: '#00ff00',
		strokeWidth: 1,
		outline: false,
	};

	/**
	 * Styles for the zones that are painted only
	 *
	 * @private
	 * @memberof CustomMapComponent
	 */
	private defaultFeatureStyle = {
		strokeColor: '#222222',
		outlineColor: '#222222',
		fillColor: '#ffffff',
		strokeWidth: 1,
		outline: false,
	};

	@ViewChild('mymap', { static: true }) mapDiv;

	constructor(private cercaliaMapService: CercaliaMapService, private authenticationService: AuthenticationService, private confirmationService: ConfirmationService, private Router: Router, protected userTime: UserTimePipe, private translate: TranslateService
	) {
		this.router = this.Router;
		this.client = this.authenticationService.user.client;
	}

	safeLonLat(lon, lat) {
		if (lat < -90 || lat > 90 || lon < -180 || lon > 180) {
			console.error('Invalid lon lat: [' + lon + ',' + lat + ']');
			return new this.cercaliaMapService.cercalia.LonLat(0, 0);
		}
		return new this.cercaliaMapService.cercalia.LonLat(lon, lat);
	}

	private labelsShown = false;

	ngOnInit() {
		if (!this.options) {
			this.options = {};
		}
		let oldZoom = null;
		interval(200).subscribe(async () => {
			await this.loadMapPromise;
			let newZoom = this.map.getZoom();
			if (oldZoom != newZoom) {
				let zoomLimit = 16;
				if (newZoom > zoomLimit && !this.labelsShown) {
					this.labelsShown = true;
					this.loadZones();
				}
				if (newZoom < zoomLimit && this.labelsShown) {
					this.labelsShown = false;
					this.loadZones();
				}
				this.eventZoomChanged.emit({
					oldZoom: oldZoom,
					newZoom: newZoom,
				});
			}
			oldZoom = newZoom;
		});

		this.zIndex = 200;
	}

	/**
	 * Solo se ejecuta una vez. Después del ngOnInit cuando la vista ya se ha renderizado.
	 * Es importante inicializar el mapa de cercalia (aunque esté vacío)
	 */
	async ngAfterViewInit() {
		await delay((resolve, reject) => {
			resolve();
		}, 1000);
		await this.initMap();

		this.loadMapPromise.then(() => {
			this.loadMap.emit(true);
		});
	}

	/**
	 * Cada vez que se produce un cambio en los @Inputs, ejecutamos esta función.
	 * Lo usamos para reconfigurar el mapa cada vez. Si el padre no esta visible o el mapa no existe, no hacemos nada.
	 * @param changes
	 */
	async ngOnChanges(changes: SimpleChanges) {
		// console.log("[CUSTOM-MAP] ngOnChanges ***");
		if (changes['display'] && this.display) {
			//this.initMap();
			await this.loadMapPromise;
			if (this.pois) {
				this.loadPOIS();
			}
			if (changes?.racingPois) {
				this.loadRacingPois();
			}
			if(changes?.markers){
				this.loadMarkers();

			}
			if (this.zones) {
				this.loadZones();
			}
			this.map.updateSize();
			this.centerMap(null);
			return;
		}
		await this.loadMapPromise;
		//si ha cambiado la configuracion, se re-inicializa el mapa.
		if (changes['options'] && this.map && this.map.getContainerId() === this.mapId) {
			this.reConfigMap();
		}
		if (this.map && this.display) {
			//si tiene pois, los cargamos
			if (this.pois) {
				this.loadPOIS();
			}

			if (changes.racingPois) {
				this.loadRacingPois();
			}
			if (this.zones) {
				this.loadZones();
			}
		}
	}

	async refresh() {
		await this.loadMapPromise;
		if (this.map) {
			delay(100).then(() => {
				this.map.updateSize();
			});
		}
	}

	async deleteMarkerDialog(markerDropTarget) {
		await this.loadMapPromise;
		this.confirmationService.confirm({
			header: 'Delete POI...',
			key: 'deleteMarker',
			message: 'Are you sure that you want to perform this action?',
			accept: () => {
				this.deleteMarker(markerDropTarget);
			},
		});
	}

	toManyMarkersDialog() {
		this.confirmationService.confirm({
			header: 'To many POIs...',
			key: 'toManyMarkers',
			message: 'You have reached the maximun number of POIs',
		});
	}

	/**
	 * Generates the cercalia map instance, adding
	 * in it all its properties
	 *
	 * @return {*}
	 * @memberof CustomMapComponent
	 */
	async initMap() {
		//if(!this.display) return;
		$('#' + this.mapId).html('');

		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		//  if(!this.map) return;
		const eventsFactory = CustomMapEventsFactory(this);
		const onClickMap = eventsFactory.onClickMap;

		//Defining all the options that the map will have
		if (this.showCercacliaOptions) {
			const mapOptions: any = {
				target: this.mapId,
				controls: [this.cercaliaMapService.cercalia.control.MapControls.SCALELINE,
				this.cercaliaMapService.cercalia.control.MapControls.GAS_STATIONS,
				this.cercaliaMapService.cercalia.control.MapControls.LAYER_SWITCHER,
				this.cercaliaMapService.cercalia.control.MapControls.METEO,
				this.cercaliaMapService.cercalia.control.MapControls.NAVBAR,
				this.cercaliaMapService.cercalia.control.MapControls.MOBILEBAR,
				this.cercaliaMapService.cercalia.control.MapControls.ZOOM,
				this.cercaliaMapService.cercalia.control.MapControls.TRAFFIC,
				this.cercaliaMapService.cercalia.control.MapControls.ISOCHRONES,
				this.cercaliaMapService.cercalia.control.MapControls.LOGISTICS_RESTRICTIONS,
				this.cercaliaMapService.cercalia.control.MapControls.ISOCHRONES,
				this.cercaliaMapService.cercalia.control.MapControls.STREET_VIEW,
				],
				rightClickMenuOptions: [this.cercaliaMapService.cercalia.ContextMenu.Option.ADDRESS],
				geolocationEnabled: true,
				multipleFeaturesInPixel: false,
			};

			if (this.options && this.options.createPOIonClick) {
				mapOptions.defaultClick = onClickMap;
				mapOptions.controls.push(this.cercaliaMapService.cercalia.control.MapControls.NAVBAR);
			}

			this.map = new this.cercaliaMapService.cercalia.Map(mapOptions);

			// Calling and adding the fullScreen control into the map
			var customControl = createCustomFullScreencontrol(this.map);
			this.map.addCustomControl(customControl);
		} else {

			const mapOptions: any = {
				target: this.mapId,
				controls: [this.cercaliaMapService.cercalia.control.MapControls.SCALELINE,
				this.cercaliaMapService.cercalia.control.MapControls.ZOOM,
				],
				rightClickMenuOptions: [this.cercaliaMapService.cercalia.ContextMenu.Option.ADDRESS],
				geolocationEnabled: true,
				multipleFeaturesInPixel: false,
			};

			if (this.options && this.options.createPOIonClick) {
				mapOptions.defaultClick = onClickMap;
				mapOptions.controls.push(this.cercaliaMapService.cercalia.control.MapControls.NAVBAR);
			}

			this.map = new this.cercaliaMapService.cercalia.Map(mapOptions);
		}

		let contextMenu = this.map.getContextMenu();

		for (let i = 0; i < this.options.contextMenuOptions?.length; i++) {
			contextMenu.addOption(
				this.options.contextMenuOptions[i].id,
				this.options.contextMenuOptions[i].label,
				this.options.contextMenuOptions[i].callback,
				this.clickFunction
			);
		}

		await this.loadMapPromise;
		this.configDrawZones();
	}


	/**
	 * Reconfigures the map (doesen't create a new one) to indicate what events should be
	 * generated for the actual configuration options
	 *
	 * @memberof CustomMapComponent
	 */
	async reConfigMap() {
		await this.loadMapPromise;
		const eventsFactory = CustomMapEventsFactory(this);
		this.options.createPOIonClick ? this.map.setDefaultClick(eventsFactory.onClickMap) : this.map.setDefaultClick();
		this.configDrawZones();
	}

	async createPOI() {
		await this.loadMapPromise;
		const eventsFactory = CustomMapEventsFactory(this);
		this.options.createPOIonClick ? this.map.setDefaultClick(eventsFactory.onCreatePOI) : this.map.setDefaultClick();
	}

	private clickFunction(event, position) {
		this.map.setCenter(position, 13);
		if (!this.marker) {
			this.marker = new this.cercaliaMapService.cercalia.Marker({
				position: position
			});
			this.map.addMarker(this.marker);
		} else {
			this.marker.setPosition(position);
		}
	}
	/**
	 * loadRacingPois()
	 * */
	loadRacingPois(): void {
		if (this.map && this.cercaliaMapService.cercalia) {

			/* Remove all markers to prevent duplicates */
			this.map.removeAllMarkers();

			this.racingPois.forEach((poi) => {

				/* Create POI icon */
				let icon = new this.cercaliaMapService.cercalia.Icon({
					src: poi.itemIcon.iconId.imageEncoded,
					scale: 0.2,
				});

				/* Prepare POI popup content */
				let poiPopupContent = `<div class='d-flex flex-column w-100'>`;

				/* Append POI image */
				if (poi.imagePoi) {
					poiPopupContent += `<div class='d-flex justify-content-center w-100 pb-3'><img style='max-width: 80%; max-height: 40%' src='` + poi.imagePoi.imageEncoded + `' alt=''/></div>`;
				}

				/* Append POI info */
				if (poi.infoTxt) {
					poiPopupContent += `<div class='d-flex pb-3' style='font: normal normal normal 1rem Gotham; color: #575756;'>` + poi.infoTxt + `</div>`;
				}

				/* Append POI blog and video urls */
				if (poi.videoUrl || poi.blogUrl) {
					poiPopupContent += `<div class='d-flex flex-row gap-3'>`;

					/* Append POI video url */
					if (poi.videoUrl) {
						const icon = !this.options.popupOptions?.videoLinkIcon ? './assets/images/Racing/video.png' : this.options.popupOptions?.videoLinkIcon;
						//poiPopupContent += `<a href='` + poi.videoUrl.trim() + `' target='_blank' id='video'><img src='` + './assets/images/Racing/video.png' + `' alt='video' class='thumbnails'  style='width: 30px;height:30px;'/></a>`;
						poiPopupContent += `<a href='` + poi.videoUrl.trim() + `' target='_blank' id='video'><img src='` + icon + `' alt='video' class='thumbnails'  style='width: 30px;height:30px;'/></a>`;
						poiPopupContent = `<div title='` + poi.videoUrl.trim()+ `' >` + poiPopupContent + `<div>`;
					}
					/* Append POI blog url */
					if (poi.blogUrl) {
						const icon = !this.options.popupOptions?.blogLinkIcon ? './assets/images/Racing/blog.png' : this.options.popupOptions?.blogLinkIcon;
						//poiPopupContent += `<a href='` + poi.blogUrl.trim() + `'  target='_blank' id='blog'><img src='` + './assets/images/Racing/blog.png' + `' alt='blog' class='thumbnails' style='width: 30px;height:30px;'/></a>`;
						poiPopupContent += `<a href='` + poi.blogUrl.trim() + `'  target='_blank' id='blog'><img src='` + icon + `' alt='blog' class='thumbnails' style='width: 30px;height:30px;'/></a>`;
						poiPopupContent = `<div title='` + poi.videoUrl.trim()+ `' >` + poiPopupContent + `<div>`;
					}

					poiPopupContent += `</div>`;
				}

				poiPopupContent += `</div>`;


				/* TODO: Fix popup */
				/* Create POI popup */
				// let popup = new this.cercaliaMapService.cercalia.Popup({
				// 	minWidth: !this.options.popupOptions?.popUpMinWidth ? 400 : this.options.popupOptions?.popUpMinWidth,
				// 	maxWidth: !this.options.popupOptions?.popUpMaxWidth ? 600 : this.options.popupOptions?.popUpMaxWidth,
				// 	title: poi.namePoi,
				// 	content: poiPopupContent
				// });

				/* Create marker options */
				// let markerOptions = {
				// 	position: this.safeLonLat(poi.longitude, poi.latitude),
				// 	icon: icon,
				// 	popup: popup
				// };

				/* Create marker */
				//let marker = new this.cercaliaMapService.cercalia.Marker(markerOptions);
				//marker.setId(poi.id);

				/* Create marker */
				const marker = new this.cercaliaMapService.cercalia.Marker({
					id: poi.id,
					position: this.safeLonLat(poi.longitude, poi.latitude),
					icon: icon,
					popup: new this.cercaliaMapService.cercalia.Popup({
						minWidth: !this.options.popupOptions?.popUpMinWidth ? 400 : this.options.popupOptions?.popUpMinWidth,
						maxWidth: !this.options.popupOptions?.popUpMaxWidth ? 600 : this.options.popupOptions?.popUpMaxWidth,
						title: poi.namePoi,
						content: poiPopupContent,
						//fadeAnimation: false
					}),
					onClick: (marker) => {
						if (this.options.popupOptions?.mode === "single") {
							this.closePopups(poi.id);
						};
						marker.openPopup();
					}
				});
				this.map.addMarker(marker);
			});
		}
	}

	closePopups = (id) => {
		const markersMap = this.map.getMarkers();
		markersMap.forEach(marker => {
			if (marker.c !== id) marker.closePopup();
		});
	}

	loadMarkers(): void {
		if (this.map && this.cercaliaMapService.cercalia) {

			/* Remove all markers to prevent duplicates */
			this.map.removeAllMarkers();
			var id=0;
			this.markers.forEach((m) => {

				/* Create POI icon */
				let icon = new this.cercaliaMapService.cercalia.Icon({
					scale: 1,
				});

				/* Create marker options */
				let markerOptions = {
					position: this.safeLonLat(m.longitude, m.latitude),
					icon,
				};
				/* Create marker */
				let marker = new this.cercaliaMapService.cercalia.Marker(markerOptions);
				marker.setId('marker' + id);
				this.map.addMarker(marker);
				id++;
			});
		}
	}

	/**
	 * Vacia los marcadores actuales (si existen) y carga todos los marcadores que
	 * se han recibido por parámetro (this.pois). A cada marcador se agrega un popup con sus datos
	 */
	async loadPOIS() {
		await this.loadMapPromise;
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		if (!this.map) {
			return;
		}

		//empty current pois
		this.unloadPOIS();

		let i;
		const eventsFactory = CustomMapEventsFactory(this);
		//from POIs to markers
		for (i = 0; i < this.pois.length; i++) {
			//popup to display when the marker is clicked
			let popup = null;
			if (this.pois[i].name != null || this.pois[i].address != null || this.pois[i].observations != null) {
				popup = new this.cercaliaMapService.cercalia.Popup({
					title: this.pois[i].name,
					content:
						`
                    <h2>` +
						this.pois[i].name +
						`</h2>
                    <p>` +
						this.pois[i].address +
						`</p>
                    <span style='font-size:0.8em;'> ` +
						this.pois[i].observations +
						`</span>
                `,
				});
			}

			const lon = this.pois[i].position.longitude;
			const lat = this.pois[i].position.latitude;

			//configuramos el marcador que vamos a crear
			const markerOptions: any = {
				position: this.safeLonLat(lon, lat),
				popup,
			};

			//Los marcadores solo se pueden arrastrar si esta activado el modo edición
			const onDropMarker = eventsFactory.onDropMarker;
			const onDropEditMarker = eventsFactory.onDropEditMarker;
			if (this.options.editPOIs) {
				//permitimos mover (actualizar posición) de los marcadores
				markerOptions.draggable = true;
				markerOptions.onDrop = onDropMarker;
			}

			//si estemos editando un POI, se muestra un icono distinto
			if (this.options.editPOIid !== this.pois[i].id) {
				//Si se ha indicado el icono al cargar el mapa, el marcador creado lo usa.
				//si existe un icono local, se usa ese, en caso contraria se usa el que se pasa por parametro
				const iconPath =
					this.pois[i].poiCategory && this.pois[i].poiCategory.icon
						? this.pois[i].poiCategory.icon.imageEncoded
						: this.iconPOI;
				if (iconPath) {
					markerOptions.icon = new this.cercaliaMapService.cercalia.Icon({
						src: iconPath,
						scale: 0.2,
					});
				}
				// si el icono tiene id = 0 quiere decir que es un poi creado desde el mapa de real-time i tiene que poder moverse
				if (this.options.editPOIid == 0) {
					markerOptions.draggable = true;
					markerOptions.onDrop = onDropEditMarker;
				}

			}
			//si es el icono que se quiere editar, se puede arrastrar para modificar us posición.
			//el evento que se lanza al moverlo solo actualiza el formulario
			else {
				markerOptions.draggable = true;
				markerOptions.onDrop = onDropEditMarker;
			}

			//Creamos el marcador
			const marker = new this.cercaliaMapService.cercalia.Marker(markerOptions);
			marker.setId('p' + this.pois[i].id);

			//Los marcadores solo se pueden eliminar si esta activado el modo edición
			if (this.options.editPOIs) {
				//Permitimos eliminar marcadores
				const onDoubleClickMarker = eventsFactory.onDoubleClickMarker;
				marker.onDoubleClick = onDoubleClickMarker;
			}

			/* const marker2 = new this.cercaliaMapService.cercalia.Marker({
			position: this.safeLonLat(lon, lat),
			draggable: false
			});*/
			this.map.addMarker(marker);
		}
	}

	unloadPOIS() {
		if (this.map) {
			this.map.removeMarkers(this.map.getMarkers().filter((m) => (m.getId() as string).startsWith('p')));
		}
	}

	async addVehicleRoute(vid: number, segments: Rest.Vertex[][], focus = false, onClick?: any) {
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		await this.loadMapPromise;
		await this.removeVehicleRoute(vid);

		let multipointStr = 'MULTIPOINT (' + segments.map((s) => s.map((p) => p.longitude + ' ' + p.latitude).join(', ')).join(',') + ')';
		var multipoints = new this.cercaliaMapService.cercalia.Feature({
			strokeColor: '#1ebd98',
			outlineColor: '#1ebd98',
			fillColor: '#1ebd98',
			zIndex: 0,
			strokeWidth: 1,
			outline: false,
			wkt: multipointStr,
		});
		multipoints.setId('rv_' + multipoints + '_mp');
		this.map.addFeature(multipoints);

		let pointsStr = segments.map((s) => '(' + s.map((p) => p.longitude + ' ' + p.latitude).join(', ') + ')').join(',');
		if (pointsStr.trim() == '()') {
			return;
		}
		let isMultiline = segments.length > 1;
		var lineStr = isMultiline ? 'MULTILINESTRING(' + pointsStr + ')' : 'LINESTRING' + pointsStr;

		var lineStrFeature = new this.cercaliaMapService.cercalia.Feature({
			wkt: lineStr,
			showDirection: true,
			zIndex: -1,
		});
		lineStrFeature.setId('rv_' + vid);
		this.map.addFeature(lineStrFeature);

		if (focus) {
			this.map.fitBounds(lineStrFeature.getBounds());
		}
	}

	async removeVehicleRoutes() {
		await this.loadMapPromise;
		this.map.removeFeatures(this.map.getFeatures().filter((m) => (m.getId() as string).startsWith('rv_')));
	}

	async removeVehicleRoute(vid: number) {
		await this.loadMapPromise;
		this.map.removeFeatures(this.map.getFeatures().filter((f) => f.getId() == 'rv_' + vid));
	}


	async addVehicle(vehicle: RestExt.ExtendedVehicleDataMessage) {
		await this.loadMapPromise;
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		//remove the vehicle (if it exits)

		let mrks = this.map.getMarkers().filter((m) => {
			return m.getId() + '' == 'v' + vehicle.vehicleId;
		});

		const lon = vehicle.location.longitude;
		const lat = vehicle.location.latitude;


		//this.map.removeMarkers(mrks);

		/*	if(!popupOpen){
				this.popupMarker.hide(false)
			}
			*/

		var popupMarker = new this.cercaliaMapService.cercalia.Popup({
			title: vehicle.vehicleName,
			content: this.getVehiclePopUpHtml(vehicle, ""),
			customPopup: true,
			zIndex: 300,
			//visible:  popupOpen,
		});
		//todo borrar if ternari. Sempre .position...
		//const lon = this.pois[i].position ? this.pois[i].position.longitude : -1 - (Math.random() * 6);
		//const lat = this.pois[i].position ? this.pois[i].position.latitude : 41.5 - (Math.random() * 5);


		//configuramos el marcador que vamos a crear
		//	this.popupMarker.setVisible(popupOpen);
		if (mrks.length > 0) {
			/*
			this.map.getMarkers().filter((m) => {
				return m.getId() + '' == 'v' + vehicle.vehicleId;
			})[0].setPosition( this.safeLonLat(lon, lat));

			this.map.getMarkers().filter((m) => {
				return m.getId() + '' == 'v' + vehicle.vehicleId;
			})[1].setPosition( this.safeLonLat(lon, lat));

			*/
			mrks[0].setPosition(this.safeLonLat(lon, lat))
			mrks[1].setPosition(this.safeLonLat(lon, lat))

			var rotation = 0;
			if(vehicle.sensors != null){
                const sensor = vehicle.sensors.find(s => s.tag === "IGNITION");

                //if vehicle has sensor IGNITION and is true
				if(sensor && sensor.rawValue){ //this is correct

					stateSrc = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="78 596.536 32.954 52.338">
					<g data-name="Group 376">
					  <path d="M94.477 596.536c10.17 7.754 16.476 23.753 16.476 33.923 0 10.17-6.306 18.415-16.476 18.415-10.17 0-16.477-8.244-16.477-18.415 0-10.17 6.306-26.169 16.477-33.923Z"
					  fill="` + this.getVehicleColorState(vehicle.color,false)+ `" fill-rule="evenodd" data-name="Path 652"/>
					  <path d="M0 10.617a10.717 10.717 0 1 1 0 .1z" fill-rule="evenodd" fill="url(#a)" transform="translate(83.46 621.36)" data-name="Ellipse 380"/>
					</g>
					<defs>
					  <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">
						<stop stop-color="` +this.getVehicleColorState(vehicle.color,true) + `" offset="0"/>
						<stop stop-color="` + this.getVehicleColorState(vehicle.color,false) + `" offset="1"/>
					  </linearGradient>
					</defs>
				  </svg>`;
				  rotation = vehicle.heading;
                }else{
					stateSrc = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">\
					<g data-name="Path 656">\
						<path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" \
						fill-rule="evenodd" fill="url(#a)" transform="translate(125.85 682.004)"/>\
						<path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" \
						stroke-linejoin="round" stroke-linecap="round" stroke="' + this.getVehicleColorState(vehicle.color,false) + '" fill="transparent" stroke-width=".96295"/>\
					</g>\
					<defs>\
						<linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">\
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,true) + '" offset="0"/> \
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,false)+ '" offset="1"/>\
						</linearGradient>\
					</defs>\
				</svg>';
                }
            }else{
				stateSrc = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">\
					<g data-name="Path 656">\
						<path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" \
						fill-rule="evenodd" fill="url(#a)" transform="translate(125.85 682.004)"/>\
						<path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" \
						stroke-linejoin="round" stroke-linecap="round" stroke="' + this.getVehicleColorState(vehicle.color,false) + '" fill="transparent" stroke-width=".96295"/>\
					</g>\
					<defs>\
						<linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">\
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,true) + '" offset="0"/> \
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,false)+ '" offset="1"/>\
						</linearGradient>\
					</defs>\
				</svg>';
			}

			mrks[1].setIcon(new this.cercaliaMapService.cercalia.Icon({
				svg: escape(stateSrc),
				scale: 0.5,
				rotation: rotation
			}));



			let popupOpen: Boolean = false;
			if (mrks != null && mrks.length > 0) {

				if (mrks[0] != null && mrks[0].getPopup() != null && mrks[0].getPopup().isVisible()) {
					popupOpen = true;
					this.cercaliaMapService
						.reverseGeocode(vehicle.longitude, vehicle.latitude)
						.then((location) => {
							var locationS = location as any;

							/* Get the first line for the address field - Street name & number */

							var addressFirstLine = location.fullAddress;
							var addressFirstLine = addressFirstLine.substring(0, addressFirstLine.indexOf(','));

							/* Get the second line for the address field - City & Province */
							var addressSecondLine = location.city + ', ' + location.subregion;


							mrks[0].getPopup().setContent(this.getVehiclePopUpHtml(vehicle, location.fullAddress));
							var params;
							this.createPopUpImage(params, vehicle);

						});

				}
			}
		}
		else if (mrks.length == 0) {
			this.zIndex++;

			const markerOptions1: any = {
				position: this.safeLonLat(lon, lat),
				//	zIndex: this.zIndex,
				//http://www.cercalia.com/api/v5/doc/cercalia.SimpleLabel.html
				simpleLabel: new this.cercaliaMapService.cercalia.SimpleLabel({
					text: vehicle.vehicleName,
					offset: [-20, -20],
				}),
				onClick: () => {
					this.eventVehicleClicked.emit(vehicle as any);

					this.cercaliaMapService
						.reverseGeocode(vehicle.longitude, vehicle.latitude)
						.then((location) => {
							var locationS = location as any;

							/* Get the first line for the address field - Street name & number */
							var addressFirstLine = location.fullAddress;
							var addressFirstLine = addressFirstLine.substring(0, addressFirstLine.indexOf(','));
							/* Get the second line for the address field - City & Province */
							var addressSecondLine = location.city + ', ' + location.subregion;

							popupMarker.setTitle(vehicle.vehicleName + " - " + vehicle.groupName)
							popupMarker.setContent(this.getVehiclePopUpHtml(vehicle, location.fullAddress))
							//   createDivStaticImage();
							var params;
							delay(1000).then(() => this.createPopUpImage(params, vehicle));


						});




				},
				popup: popupMarker
				//zIndex : this.vehicleIndex,
			};

			//configuramos el marcador que vamos a crear
			const markerOptions2: any = {
				position: this.safeLonLat(lon, lat),
				//http://www.cercalia.com/api/v5/doc/cercalia.SimpleLabel.html
				//  simpleLabel: new this.cercaliaMapService.cercalia.SimpleLabel({ text: "",  offset: [-20,-20] }),
				onClick: () => {
					this.eventVehicleClicked.emit(vehicle as any);
				},
			};

			if (vehicle.icon) {
				markerOptions1.icon = new this.cercaliaMapService.cercalia.Icon({
					src: vehicle.icon,
					//  size:[23,33],
					//scale: 0.3
				});

				const marker1 = new this.cercaliaMapService.cercalia.Marker(markerOptions1);
				marker1.setId('v' + vehicle.vehicleId);

				this.map.addMarker(marker1);
				/*if(popupOpen){
					var size = this.map.getMarkers().length;
					this.map.getMarkers().get(size-1).openPopup(false)
				} */
				var stateSrc = '';
				var rotation = 0;
				if(vehicle.sensors != null){
					const sensor = vehicle.sensors.find(s => s.tag === "IGNITION");

					//if vehicle has sensor IGNITION and is true
					if(sensor && sensor.rawValue){
						stateSrc = `<svg xmlns="http://www.w3.org/2000/svg" width="32.954" height="52.338" viewBox="78 596.536 32.954 52.338">
					<g data-name="Group 376">
					  <path d="M94.477 596.536c10.17 7.754 16.476 23.753 16.476 33.923 0 10.17-6.306 18.415-16.476 18.415-10.17 0-16.477-8.244-16.477-18.415 0-10.17 6.306-26.169 16.477-33.923Z"
					  fill="` + this.getVehicleColorState(vehicle.color,false)+ `" fill-rule="evenodd" data-name="Path 652"/>
					  <path d="M0 10.617a10.717 10.717 0 1 1 0 .1z" fill-rule="evenodd" fill="url(#a)" transform="translate(83.46 621.36)" data-name="Ellipse 380"/>
					</g>
					<defs>
					  <linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">
						<stop stop-color="` +this.getVehicleColorState(vehicle.color,true) + `" offset="0"/>
						<stop stop-color="` + this.getVehicleColorState(vehicle.color,false) + `" offset="1"/>
					  </linearGradient>
					</defs>
				  </svg>`;
						rotation = vehicle.heading;
					}else{
						stateSrc = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">\
					<g data-name="Path 656">\
						<path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" \
						fill-rule="evenodd" fill="url(#a)" transform="translate(125.85 682.004)"/>\
						<path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" \
						stroke-linejoin="round" stroke-linecap="round" stroke="' + this.getVehicleColorState(vehicle.color,false) + '" fill="transparent" stroke-width=".96295"/>\
					</g>\
					<defs>\
						<linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">\
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,true) + '" offset="0"/> \
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,false)+ '" offset="1"/>\
						</linearGradient>\
					</defs>\
				</svg>';
					}
				}else{
					stateSrc = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">\
					<g data-name="Path 656">\
						<path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" \
						fill-rule="evenodd" fill="url(#a)" transform="translate(125.85 682.004)"/>\
						<path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" \
						stroke-linejoin="round" stroke-linecap="round" stroke="' + this.getVehicleColorState(vehicle.color,false) + '" fill="transparent" stroke-width=".96295"/>\
					</g>\
					<defs>\
						<linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">\
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,true) + '" offset="0"/> \
							<stop stop-color="' + this.getVehicleColorState(vehicle.color,false)+ '" offset="1"/>\
						</linearGradient>\
					</defs>\
				</svg>';
				}


				markerOptions2.icon = new this.cercaliaMapService.cercalia.Icon({
					svg: escape(stateSrc),
					scale: 0.5,
					rotation: rotation
					//zIndex: this.zIndex,
				});

				const marker2 = new this.cercaliaMapService.cercalia.Marker(markerOptions2);
				marker2.setId('v' + vehicle.vehicleId);
				this.map.addMarker(marker2);

			}

		}
	}

	createPopUpImage(params, vehicle) {
		var staticMapService = new this.cercaliaMapService.cercalia.service.StaticMap();
		params = {
			width: parseInt("210"),
			height: parseInt("130"),
			center: new this.cercaliaMapService.cercalia.LonLat(vehicle.longitude, vehicle.latitude),
			zoom: parseInt("18")
		};


		staticMapService.createFromParams(params, function (img) {
			if (document.querySelector('#staticImageDiv_' + vehicle.vehicleId.toString())) {
				document.querySelector('#staticImageDiv_' + vehicle.vehicleId.toString()).remove();
			}

			var div = document.createElement('div');
			div.id = 'staticImageDiv_' + vehicle.vehicleId.toString();
			div.className = 'staticImageDiv';
			div.style.position = 'relative'
			div.innerHTML = 'Generating image ...';
			document.querySelector('#imgMapContainer_' + vehicle.vehicleId.toString()).appendChild(div);

			var imgMarker = document.createElement('img');
			imgMarker.src = './assets/icons/location.svg'
			imgMarker.style.position = 'absolute'
			imgMarker.style.top = '40%'
			imgMarker.style.left = '44%'
			imgMarker.style.width = '22px'
			imgMarker.style.height = '22px'

			imgMarker.className = 'markerIcon'


			img.position = 'relative'
			img.style.top = '0px'
			img.style.left = '0px'

			var imageDivId = '#staticImageDiv_' + vehicle.vehicleId.toString()
			document.querySelector('#staticImageDiv_' + vehicle.vehicleId.toString()).innerHTML = '';
			img.className = 'staticMapImage'
			document.querySelector('#staticImageDiv_' + vehicle.vehicleId.toString()).appendChild(imgMarker);
			document.querySelector('#staticImageDiv_' + vehicle.vehicleId.toString()).appendChild(img);
		});



		//div.innerHTML = 'Generating image ...';
	}

	delay(time) {
		return new Promise(resolve => setTimeout(resolve, time));
	}




	handleImagePopup(img) {

		var test_arg = arguments[0]
		//this.imgPopUp = "<img src='"+img.src;+"'>"
		document.querySelector('#staticImageDiv').innerHTML = '';
		document.querySelector('#staticImageDiv').appendChild(img);
		// this.imgPopUp = img;

	}


	getVehiclePopUpHtml(vehicle, address) {
		// staticMapService.createFromMap(this.map, );

		let vehiclePopUpContent = "<html>";
		vehiclePopUpContent += "<head>";
		vehiclePopUpContent += "<style>"
		vehiclePopUpContent += '.cercalia-marker-popup-0 .content.cercalia-marker-popup { padding: 14px 14px 14px 14px;} '
		vehiclePopUpContent += ''


		//vehiclePopUpContent +='.markerIcon{ position: absolute;top: 30px; left: 30px; border: 1px green solid;}'
		//	vehiclePopUpContent +='.staticImageDiv{ position: relative; top: 0;left: 0;width: 230px;}'
		//	vehiclePopUpContent +='.staticMapImage { position: relative;top: 0;left: 0;}'
		vehiclePopUpContent += "</style>"
		vehiclePopUpContent += "</head>"
		vehiclePopUpContent += "<body>"


		if (vehicle != null) {

			vehiclePopUpContent += "<div style='width: 210px; height: 130px;' id='imgMapContainer_" + vehicle.vehicleId.toString() + "' "
			vehiclePopUpContent += "style='"
			vehiclePopUpContent += "background-color: #fff;"
			vehiclePopUpContent += "overflow: auto;"
			vehiclePopUpContent += "top: 105px;"
			vehiclePopUpContent += " "
			vehiclePopUpContent += "box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);"
			vehiclePopUpContent += " z-index: 1000;'> "
			//vehiclePopUpContent += " '<img  style='position: absolute;top: 30px; left: 30px; border: 1px green solid;' class='markerIcon' src='./assets/icons/gps.svg'/>"
			vehiclePopUpContent += " </div>"

			var stateSrc = '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="125.85 682.004 26.992 26.992">\
			<g data-name="Path 656">\
				<path d="M13.496 0c7.454 0 13.496 6.042 13.496 13.496 0 7.454-6.042 13.496-13.496 13.496C6.042 26.992 0 20.95 0 13.496 0 6.042 6.042 0 13.496 0Z" \
				fill-rule="evenodd" fill="url(#a)" transform="translate(125.85 682.004)"/>\
				<path d="M139.346 682.504c7.177 0 12.996 5.818 12.996 12.996 0 7.177-5.819 12.996-12.996 12.996-7.178 0-12.996-5.819-12.996-12.996 0-7.178 5.818-12.996 12.996-12.996Z" \
				stroke-linejoin="round" stroke-linecap="round" stroke="' + this.getVehicleColorState(vehicle.color,false) + '" fill="transparent" stroke-width=".96295"/>\
			</g>\
			<defs>\
				<linearGradient x1=".5" y1="0" x2=".5" y2="1" id="a">\
					<stop stop-color="' + this.getVehicleColorState(vehicle.color,true) + '" offset="0"/> \
					<stop stop-color="' + this.getVehicleColorState(vehicle.color,false)+ '" offset="1"/>\
				</linearGradient>\
			</defs>\
		</svg>';
			////CANNOT GET DEVICE TYPE FROM THIS VEHICLE INFO SO WILL FILTER BY CLIENT NAME AND VEHICLE SPEED Abraham 18/04/2024
			if (this.client.name !== 'Smart Coffee' && (vehicle.speed != null || vehicle.speed != undefined || vehicle.speed != 0)) {
				var stateDesc = this.translate.instant('real-time.statusVehicleMap.stopped') +" " + this.userTime.transform(vehicle.deviceTime, "short");
			} else {
				var stateDesc = this.translate.instant('real-time.statusVehicleMap.lastData') +" " + this.userTime.transform(vehicle.deviceTime, "short");
			}
			var gpsSrc = './assets/icons/gps.svg';
			var clockSrc = './assets/icons/clock.svg';
			var locationSrc = './assets/icons/location.svg';
			var speedSrc = './assets/icons/speed.svg';


			if(vehicle.sensors != null){
                const sensor = vehicle.sensors.find(s => s.tag === "IGNITION");

                //if vehicle has sensor IGNITION and is true
                if(sensor && sensor.rawValue){
					stateDesc = this.translate.instant('real-time.statusVehicleMap.running') + " " + this.userTime.transform(vehicle.deviceTime, "short");
                }
            }

			vehiclePopUpContent += "<div style='margin-top: 10px; font-size: 12px;' id='vehicleData'>"
			vehiclePopUpContent += "	<div id='devicePosition'>"
			vehiclePopUpContent += "		<div  >" + stateSrc + " " + stateDesc + "</div>"
			vehiclePopUpContent += "		<div  style='margin-top: 6px;'> <img style='margin-right: 10px;' src='" + locationSrc + "'   width='15' height='15'>" + address + "</div>"
			vehiclePopUpContent += "		<div  style='margin-top: 6px;'> <img   style='margin-right: 10px;' src='" + gpsSrc + "'   width='15' height='15'>" + vehicle.latitude + "&nbsp;&nbsp;&nbsp;&nbsp;" + vehicle.longitude + "</div>"
			vehiclePopUpContent += "		<div  style='margin-top: 6px;'> <img style='margin-right: 10px;' src='" + clockSrc + "'   width='15' height='15'>" + this.userTime.transform(vehicle.deviceTime, "short")
			//CANNOT GET DEVICE TYPE FROM THIS VEHICLE INFO SO WILL FILTER BY CLIENT NAME AND VEHICLE SPEED Abraham 18/04/2024
			if (this.client.name !== 'Smart Coffee' && (vehicle.speed != null || vehicle.speed != undefined || vehicle.speed != 0)) {
				vehiclePopUpContent += "		<div  style='margin-top: 6px;'> <img style='margin-right: 10px;' src='" + speedSrc + "'   width='15' height='15'>" + vehicle.speed + " Km/h</div>"
			} else {
				vehiclePopUpContent += "</div>"
			}
			vehiclePopUpContent += "		<div  style='margin-top: 6px;'> </div>"
			vehiclePopUpContent += "	</div>"
			/*
			vehiclePopUpContent += "	<div id='deviceTime'>"
			vehiclePopUpContent += "		<div >Device Time:</div>"
			vehiclePopUpContent += "		<div >"+  this.userTime.transform(vehicle.deviceTime , "short")  +"</div>"
			vehiclePopUpContent += "	</div>"
			*/
			vehiclePopUpContent += "</div>"
		}
		vehiclePopUpContent += "</body> </html>";
		return vehiclePopUpContent;
	}


	getImagenRumbo(rumbo) {
		/* ----------------------------------------- */
		try {
			//Main code
			var rumbo_arreglat;
			var num_divisions = 16;
			rumbo_arreglat = Math.round(Math.round(rumbo / (360 / num_divisions)) * (360 / num_divisions));
			return rumbo_arreglat;
		} catch (e) {
			//Error handle
			//Log(appInfo,"GetImagenRumbo: " + e.description);
		}
	}

	removeVehicle(vehicle: RestExt.ExtendedVehicleDataMessage) {
		this.map.removeMarkers(this.map.getMarkers().filter((m) => (m.getId() as string) == 'v' + vehicle.vehicleId));

		//this.map.removeMarkers(this.map.getMarkers().filter((m => (m.getId() as string) == "v" + vehicle.vehicleId + "_state")));
	}

	/**
	 *
	 * @param ids Given a list of vehicle ids to keep, remove all displayed vehicles that are not in that list.
	 */
	keepVehicles(ids: number[]) {
		if (this.map) {
			let idsInMap = this.map
				.getMarkers()
				.filter((m) => (m.getId() as string).startsWith('v'))
				.map((m) => parseInt((m.getId() as string).substr(1)));
			let idsToRemove = idsInMap.filter((id) => ids.indexOf(id) == -1);
			this.map.removeMarkers(
				this.map.getMarkers().filter((m) => {
					let id = m.getId().substr(1); //Keep the numeric value (remove the v)
					return m.getId().startsWith('v') && idsToRemove.indexOf(parseInt(id)) != -1;
				}),
			);
		}
	}

	/**
	 * Remove all vehicles from the map
	 */
	removeVehicles() {
		if (this.map) {
			this.map.removeMarkers(this.map.getMarkers().filter((m) => (m.getId() as string).startsWith('v')));
		}
	}

	/**
	 * Vacia los marcadores actuales (si existen) y carga todos los marcadores que
	 * se han recibido por parámetro (this.pois). A cada marcador se agrega un popup con sus datos
	 */
	async loadZones() {
		await this.loadMapPromise;
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		if (!this.map) {
			return;
		}

		//empty current pois
		this.unloadZones();

		let i;
		const eventsFactory = CustomMapEventsFactory(this);
		//from zones to features
		for (i = 0; i < this.zones.length; i++) {
			let zone = this.zones[i];
			//common options for all features (polygon and circle)
			let featureOptions: any = {
				id: zone.id,
			};

			if (this.labelsShown) {
				featureOptions.simpleLabel = new this.cercaliaMapService.cercalia.SimpleLabel({
					text: zone.name,
					overflow: true,
				});
			}

			//configuramos el feature que vamos a crear
			if (zone.circular) {
				const lon = zone.center.longitude;
				const lat = zone.center.latitude;
				const radius = zone.radius;
				const polygonCircular = true;
				const center = this.safeLonLat(lon, lat);
				const circleGeometry = this.cercaliaMapService.cercalia.Util.createCircleGeometry(center, radius, polygonCircular);

				featureOptions.geometry = circleGeometry;
			}
			else {
				featureOptions.wkt = zone.rawPolygonVertexList ? JSON.parse(zone.rawPolygonVertexList) : 'POLYGON((0.0 0.0,1.0 1.0,2.0 2.0,3.0 3.0))';
			}
			///Apply defaultFeatureStyle but preserve featureOptions

			let col = {} as any;

			if ((zone as any).strokeColor) {
				col.strokeColor = (zone as any).strokeColor;
			}
			if ((zone as any).outlineColor) {
				col.outlineColor = (zone as any).outlineColor;
			}
			if ((zone as any).fillColor) {
				col.fillColor = (zone as any).fillColor;
			}
			if ((zone as any).strokeWidth) {
				col.strokeWidth = (zone as any).strokeWidth;
			}
			if ((zone as any).outline) {
				col.outline = (zone as any).outline;
			}

			featureOptions = Object.assign({}, this.defaultFeatureStyle, featureOptions, col);

			//Los marcadores solo se pueden arrastrar si esta activado el modo edición
			if (this.options.editZones) {
				const onDragUpdateZoneEnd = eventsFactory.onDragUpdateZoneEnd;
				//permitimos mover (actualizar posición) de los marcadores
				featureOptions.editable = false;
				featureOptions.draggable = true;
				featureOptions.onDragEnd = onDragUpdateZoneEnd;
			}

			//si estemos editando una zona, se usa un estilo diferente, se permite editar y arrastrar
			if (this.options.editZoneid === zone.id) {
				//only editable if polygon.
				const editable = this.options.drawZoneType === DrawZoneType.Polygon ? true : false;
				const onDragTargetZone = eventsFactory.onDragTargetZone;
				featureOptions.editable = editable;
				featureOptions.draggable = true;
				featureOptions.onDragEnd = onDragTargetZone;
				featureOptions.onDrop = eventsFactory.onDropFeatureVertex;

				//Solo se pueden editar los vertices de las zonas poligonales
				if (editable) {
					featureOptions.onModifyEnd = eventsFactory.onModifyEnd;
				}
				featureOptions = Object.assign(featureOptions, this.defaultTargetFeatureStyle);
			}
			featureOptions.onClick = () => {
				this.eventZoneClicked.emit(zone);
			};
			const feature = new this.cercaliaMapService.cercalia.Feature(featureOptions);
			feature.setId('z_' + zone.id);
			this.map.addFeature(feature);
		}
	}

	private getRandomColor() {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	getMapImage() {
		var staticMap = new this.cercaliaMapService.cercalia.service.StaticMap();
		let that = this;
		staticMap.createFromMap(this.map, function (img) {
			var ImageSource = img.src;
			localStorage.setItem('mapToPrint', ImageSource);
			const url = that.router.serializeUrl(that.router.createUrlTree(['/printMapa']));
			window.open(url, '_blank');
		});
	}

	unloadZones() {
		if (this.map) {
			this.map.removeFeatures(this.map.getFeatures().filter((m) => (m.getId() as string).startsWith('z_')));
			//this.map.removeAllFeatures();
		}
	}

	getMarkers(): any[] {
		return this.map.getMarkers() || [];
	}

	/**
	 * Given a list of vehicle id's center the map to their markers
	 *
	 * @param markers http://www.cercalia.com/api/v5/doc/cercalia.Map.html#centerToMarkers
	 */
	async centerMap(vids: number[]) {
		await this.loadMapPromise;
		let markers = vids != null ? this.map.getMarkers().filter((m) => vids.find((id) => 'v' + id == (m.getId() as string))) : null;
		this.map.centerToMarkers(markers, true, true);
	}

	async centerMapAtPoint(lat: number, lon: number, zoom: number) {
		await this.loadMapPromise;
		this.map.setCenter(this.safeLonLat(lon, lat), zoom, true);
	}

	async centerMapAtZone(zids: number[]) {
		await this.loadMapPromise;
		if (!this.zones || this.zones.length == 0) {
			return;
		}
		let zones = this.map.getFeatures().filter((f) => zids.find((zid) => f.getId() == 'z_' + zid));
		if (zones && zones.length >= 1) {
			let bounds = zones[0].getBounds();
			for (let i = 1; i < zones.length; i++) {
				bounds.extend(zones[i].getBounds());
			}
			this.map.fitBounds(bounds);
		}
	}

	createMarker(lonLat) {
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		const localMarkers = this.getMarkers();
		const dif = localMarkers.length - this.pois?.length;
		//hay restriccion al crear y el total de marcadores actuales supera el limite.
		if (this.options.restrictNPois && dif >= this.options.restrictNPois) {
			this.toManyMarkersDialog();
			return;
		}

		const eventsFactory = CustomMapEventsFactory(this);
		const onDropEditMarker = eventsFactory.onDropEditMarker;
		let icon = new this.cercaliaMapService.cercalia.Icon({
			src: this.iconPOI,
			scale: 1,
		});
		const marker = new this.cercaliaMapService.cercalia.Marker({
			icon: icon,
			position: lonLat,
			draggable: true,
			onDrop: onDropEditMarker,
		});
		this.map.addMarker(marker);

		this.cercaliaMapService.doReverseGeocoding(marker.getPosition()).then((location) => {
			this.eventCreatePOI.emit(location);
		});
	}

	drawEvent(lat: number, lon: number, clickCallback?: any) {
		const marker = new this.cercaliaMapService.cercalia.Marker({
			position: this.safeLonLat(lon, lat),
			draggable: false,
			onClick: clickCallback,
		});
		this.map.addMarker(marker);
	}

	deleteMarker(marker) {
		this.map.removeMarkers(marker);
		this.eventDeletePOI.emit(marker);
	}

	updateMarker(marker) {
		const poiId = (marker.getId() as string).substr(1);
		this.cercaliaMapService.doReverseGeocoding(marker.getPosition()).then((location) => {
			const event: UpdatePOIMarkerEvent = {
				poiId: parseInt(marker),
				location,
			};
			this.eventUpdatePOI.emit(event);
		});
	}

	updateEditMarker(marker) {
		//genera evento para indicar que se tiene que actualizar el formulario
		this.cercaliaMapService.doReverseGeocoding(marker.getPosition()).then((location) => {
			this.eventCreatePOI.emit(location);
		});
	}

	/********* raquel:  CREATE/DELETE/CENTER TRACK KML ******/
	async addtrackKML(kml1: string) {
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		await this.loadMapPromise;
		await this.removetrackKML();
		this.kml = new this.cercaliaMapService.cercalia.KML({
			map: this.map,
			name: 'track',
			url: kml1,
			onLoadUrl: function () {
				this.kml.centerToFeatures();
			}.bind(this),
		});
	}

	async removetrackKML() {
		await this.loadMapPromise;
		if (this.kml != null) {
			this.kml.clear();
		}
	}

	async centerKML() {
		await this.loadMapPromise;
		if (this.kml != null) {
			this.kml.centerToFeatures();
		}
	}

	/***********************************/

	drawZoneEnd(feature: any) {
		const eventsFactory = CustomMapEventsFactory(this);
		this.map.disableDrawInteraction();
		feature.setOnDragEnd(eventsFactory.onDragTargetZone);
		if (this.options.drawZoneType === DrawZoneType.Polygon) {
			feature.setEditable(true);
			feature.setOnModifyEnd(eventsFactory.onModifyEnd);
		}
		this.notifyUpdateFormCreateZone(feature, null);
	}

	notifyUpdateFormCreateZone(feature: any, wkt: string) {
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		let evt: CreateZoneMarkerEvent;
		//emit event from wkt data (always polygon)
		if (wkt) {
			evt = {
				zone: {
					wkt: JSON.stringify(wkt),
					zoneType: DrawZoneType.Polygon,
				},
			};
		}
		//emit event from feature data (polygon or circle)
		else {
			//circle
			if (this.options.drawZoneType === DrawZoneType.Circle) {
				const circleData = this.cercaliaMapService.cercalia.Util.getCircleData(feature);
				evt = {
					zone: {
						zoneType: DrawZoneType.Circle,
						radius: circleData.radius,
						centerLon: circleData.center.getLon(),
						centerLat: circleData.center.getLat(),
					},
				};
			}
			//polygon
			else {
				evt = {
					zone: {
						wkt: JSON.stringify(feature.getWKT()),
						zoneType: DrawZoneType.Polygon,
					},
				};
			}
		}
		this.eventCreateZone.emit(evt);
	}

	/**
	 *
	 * @param feature cercalia.feature
	 * @param event ol.interaction.Modify.Event
	 */
	notifyModifyVertexEnd(feature: any, event: any) {
		console.log('moveee');
		console.log(feature);
		console.log(event);
		this.notifyUpdateFormCreateZone(feature, null);
	}

	private configDrawZones() {
		if (this.options.drawZones) {
			const drawEndFunction = CustomMapEventsFactory(this).drawEndFunction;
			const draggable = true;
			const drawType: string =
				this.options.drawZoneType !== undefined && this.options.drawZoneType !== null ? DrawZoneType[this.options.drawZoneType] : 'Circle';
			this.map.enableDrawInteraction(drawType, true, this.defaultTargetFeatureStyle, drawEndFunction);
		}
	}

	notifyCreatePOI(lonLat: any) {
		if (!this.cercaliaMapService.cercalia) {
			return;
		}
		this.cercaliaMapService.doReverseGeocoding(lonLat).then((location) => {
			this.eventCreatePOI.emit(location);
		});
	}

	getVehicleColorState(color: string, lighter: boolean): string {

		var gradientColor = this.calculateGradientColor(color);

		 // Convert hex color codes to RGB values
		 const rgb1 = this.hexToRgb(color);
		 const rgb2 = this.hexToRgb(gradientColor);

		 // Calculate the total intensity of each color
		 const intensity1 = rgb1.r + rgb1.g + rgb1.b;
		 const intensity2 = rgb2.r + rgb2.g + rgb2.b;


		 if(intensity1 > intensity2){
			if(!lighter){
			  color = gradientColor;
			}
		 }else{
		   if(lighter){
			 color = gradientColor;
		   }
		 }


		return color;
	  }

	  calculateGradientColor(color){
		var components = [
		  (255-(255-Math.trunc(Math.round(parseInt(color.substr(1,2),16))/1.2))).toString(16),
		  (255-(255-Math.trunc(Math.round(parseInt(color.substr(3,2),16))/1.2))).toString(16),
		  (255-(255-Math.trunc(Math.round(parseInt(color.substr(5,2),16))/1.2))).toString(16)
		  ];
		  if(components[0].length < 2) components[0] = "0"+components[0];
		  if(components[1].length < 2) components[1] = "0"+components[1];
		  if(components[2].length < 2) components[2] = "0"+components[2];

		  var out = "#"+components[0]+components[1]+components[2];
		  return out;
	  }

	  isLighterColor(color1, color2) {
		// Convert hex color codes to RGB values
		const rgb1 = this.hexToRgb(color1);
		const rgb2 = this.hexToRgb(color2);

		// Calculate the total intensity of each color
		const intensity1 = rgb1.r + rgb1.g + rgb1.b;
		const intensity2 = rgb2.r + rgb2.g + rgb2.b;

		// Compare the intensities to determine which color is lighter
		if (intensity1 > intensity2) {
		  return color1;
		} else {
		  return color2;
		}
	  }

	  hexToRgb(hex) {
		// Convert the hex color code to an RGB value
		const r = parseInt(hex.substring(1, 3), 16);
		const g = parseInt(hex.substring(3, 5), 16);
		const b = parseInt(hex.substring(5, 7), 16);
		return { r, g, b };
	  }
}

/**
 *
 * Utilizamos esta función para encapsular todos los eventos generados en el mapa.
 * Esta función se utiliza a modo de "factory" para no perder la referencia del objecto CustomMapComponent (controlador del mapa)
 * ya que el "this" no existe en el momento de ejecutar los eventos.
 *
 * @param {CustomMapComponent} customMapComponent controlador del mapa
 * @return {*}
 */
function CustomMapEventsFactory(customMapComponent: CustomMapComponent) {
	const events: any = {
		onDropMarker,
		onDropEditMarker,
		onDoubleClickMarker,
		onClickMap,
		drawEndFunction,
		onDragZoneEnd,
		onDragUpdateZoneEnd,
		onDragTargetZone,
		onDropFeatureVertex,
		onModifyEnd,
		onCreatePOI,
	};
	return events;

	function onDropMarker(listMarkers) {
		const markerDropped = this;
		customMapComponent.updateMarker(markerDropped);
	}

	function onDropEditMarker(listMarkers) {
		const markerDropped = this;
		customMapComponent.updateEditMarker(markerDropped);
	}

	function onDoubleClickMarker(marker, event) {
		event.stopPropagation();
		customMapComponent.deleteMarkerDialog(marker);
	}

	/**
	 * Assign function to click on map. Function parameters: (1->pixel, 2->cercalia.LonLat).
	 * @param pixel Pixel where clicked with mouse right click.
	 * @param lonLat Map coordinate.
	 */
	function onClickMap(pixel: any, lonLat: any) {
		//TODO BUG!!!! lonLat hauria de estar amb EPSG:4326 (lat, lon) però està
		//retornar les coordenades com a Mercator (UTM). Fix temporal. Convertir lonLat a EPSG:4326 i crear manualment
		//la position
		//borrar ! bug cercalia
		//const coords = window['proj4']('EPSG:3857', 'EPSG:4326', [lonLat.getLon(), lonLat.getLat()]);
		//const lon = coords[0];
		//const lat = coords[1];
		//end borrar
		const lon = lonLat.getLon();
		const lat = lonLat.getLat();
		customMapComponent.createMarker(lonLat);
	}

	function onCreatePOI(pixel: any, lonLat: any) {
		customMapComponent.notifyCreatePOI(lonLat);
	}

	/**
	 *
	 * @param feature cercalia.feature (http://www.cercalia.com/api/v5/doc/cercalia.Feature.html)
	 */
	function drawEndFunction(feature: any) {
		customMapComponent.drawZoneEnd(feature);
	}

	function onDragZoneEnd(feature: any) {
		console.log('onDragZoneEnd: ' + feature.getWKT());
	}

	function onDragUpdateZoneEnd(feature: any) {
		console.log('onDragUpdateZoneEnd: ' + feature.getWKT());
	}

	function onDragTargetZone(feature: any) {
		customMapComponent.notifyUpdateFormCreateZone(feature, null);
	}

	function onDropFeatureVertex(feature: any) {
		console.log(feature);
	}

	function onModifyEnd(feature, event) {
		customMapComponent.notifyModifyVertexEnd(feature, event);
	}
}
