import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-download-button',
  templateUrl: './table-download-button.component.html',
  styleUrls: ['./table-download-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableDownloadButtonComponent{

  @Input() isDisabled = false;
  @Output() btnClick = new EventEmitter();

  constructor() {
  }

  onClick() {
    this.btnClick.emit();
  }

}
