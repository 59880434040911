import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { CustomMapComponent } from "app/components/shared/custom-map/custom-map.component";
import { SelectItem } from "primeng/api";
import { TabView } from "primeng/tabview";
import { Rest } from "../../../rest/rest_client";
import { ZoneService } from "../../../rest/zone.service";
import { ZonegroupService } from "../../../rest/zonegroup.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { FleetTreeComponent } from "../../fleet-edit-view/fleet-tree/fleet-tree.component";
import {
    CustomMapOptions,
    UpdateZoneMarkerEvent,
} from "../../shared/custom-map/custom-map.model";
import ZoneGroup = Rest.ZoneGroup;
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-zone-group-form",
    templateUrl: "./zone-group-form.component.html",
    styleUrls: ["./zone-group-form.component.scss"],
    providers: [ZonegroupService, ZoneService],
})
export class ZoneGroupFormComponent
    extends EntityFormComponent<Rest.ZoneGroup>
    implements OnInit {
    selectedChannels: SelectItem[];
    disableZoneTypeSelector = false;
    // -------------- //
    // OPCIONES MAPA  //
    // showMap = true;
    showMap = false;

    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    customMapOptions: CustomMapOptions = {};

    user: Rest.User;

    //Evento que genera el formulario cuando se quiere actualizar la posición de un poi
    @Output() updateZoneEvent: EventEmitter<UpdateZoneMarkerEvent> =
        new EventEmitter<UpdateZoneMarkerEvent>();
    @Input() realTime: boolean;
    //@Output() close = new EventEmitter();

    // End OPCIONES MAPA  //
    // ------------------ //

    //#region FORM LABELS
    title: string;
    zoneGroupLabel: string;
    fleetsLabel: string;
    nameLabel: string;
    headquartersLabel: string;
    enabledLabel: string;
    eventsLabel: string;
    saveLabel: string;
    changeStatusLabel: string;
    //#endregion

    @ViewChild("mapPH") mapPH;
    @ViewChild("map") map: CustomMapComponent;
    @ViewChild("tv", { static: true }) public tabView: TabView;
    numevents: SelectItem[];
    fleetsGroupsAndVehiclesSelected: object[];

    constructor(
        private zoneGroupService: ZonegroupService,
        private zoneService: ZoneService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        private translate: TranslateService,
    ) {
        // @ts-ignore
        super(zoneGroupService, notificationsService, i18n, authenticationService);
        this.numevents = [];
        this.numevents.push({ label: "1", value: 1 });
        this.numevents.push({ label: "2", value: 2 });
        this.numevents.push({ label: "3", value: 3 });
        this.numevents.push({ label: "4", value: 4 });
        this.numevents.push({ label: "5", value: 5 });
    }

    ngOnInit() {
        super.ngOnInit();
        const that = this;
        this.title = this.i18n._("Zone Group");
        this.fleetsGroupsAndVehiclesSelected = [];

        this.user = this.authenticationService.user;

        this.translateLabels();
    }

    focus() {
        //this.map.nativeElement.classList.add('disableDIV');
        if (this.mapPH.nativeElement.classList[0] === "disableDIV") {
            this.mapPH.nativeElement.classList.remove("disableDIV");
        } else {
            this.mapPH.nativeElement.classList.add("disableDIV");
        }
    }

    focusOutOfMap() {
        if (!this.realTime) {
            if (this.mapPH.nativeElement.classList[0] !== "disableDIV") {
                this.mapPH.nativeElement.classList.add("disableDIV");
            }
        }
    }

    /**
     * Se configuran las opciones del mapa para que este muestre o no ciertos iconos y
     * capture/genere los eventos correspondientes
     */
    private configureMap() {
        //create Group Zone
        if (this.isNew) {
            //No se pueden ni crear ni editar pois (solo visibles)
            this.customMapOptions = {
                editZones: false,
                drawZones: false,
            };
            //this.title = this.i18n._('Create Group Zone');
        }
        //edit Group Zone
        else {
            //No se pueden crear pois. Se puede editar la posicion de los pois que se muestran
            //arrastrandolos por el mapa. El componente mapa solo actualiza los marcadores y genera eventos para
            //indicar a este formulario que se ha actualizado la posición de un marcador pero no hay peticiones en el backend
            //las peticiones las debe tratar el formulario y la lista de pois.
            //TODO, una vez realizada la petición al backend seria conveniente implementar un evento UpdatedPOIPositionSuccess o error
            //que se propagase desde el componente padre (poi-categories-list.component) al componente hijo (custom-map)
            this.customMapOptions = {
                editZones: false,
                drawZones: false,
            };
            //this.title = this.i18n._('Edit Group Zone');
        }
        //Dirty trick...
        window.setTimeout(() => {
            if (this.entity && this.entity.zones && this.map) {
                this.map.centerMapAtZone(this.entity.zones.map((z) => z.id));
            }
        }, 500);
    }

    beforeShow() {
        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.entity.numEventsValid = 1;
        } else {
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
        /** */

        this.showMap = false;
        this.fleetsGroupsAndVehiclesSelected = [];

        if (!this.isNew) {
            this.entity.zones.forEach((z: Rest.Zone) => {
                z.name = z.name + " (id: " + z.id + ")";
            });

            this.zoneGroupService
                .find(this.entity.id + "")
                .then((resp: Rest.ZoneGroup) => {
                    //this.entity = resp;

                    if (resp.fleets != null) {
                        resp.fleets.forEach((d: Rest.Fleet) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    if (resp.vehicles != null) {
                        resp.vehicles.forEach((d: Rest.Vehicle) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    if (resp.vehiclesGroups != null) {
                        resp.vehiclesGroups.forEach((d: Rest.VehiclesGroup) => {
                            this.fleetsGroupsAndVehiclesSelected.push(d);
                        });
                    }
                    this.fleetsGroupsAndVehiclesSelected = [
                        ...this.fleetsGroupsAndVehiclesSelected,
                    ];
                });
        }

        if (this.isNew) {
            this.title = "Create Group Zone";
            this.entity.numEventsValid = this.numevents.find(
                (b) => b.value === 1
            ).value;
        } else {
            this.title = this.i18n._("Edit Group Zone");
        }

        // eslint-disable-next-line prefer-const
        for (let a in this.tabView.tabs) {
            if (a === "0") {
                this.tabView.tabs[0].selected = true;
            } else {
                this.tabView.tabs[a].selected = false;
            }
        }

        if (this.isNew) {
            this.disableSave = false;
        } else {
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }

            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }
        }
    }

    beforeSave() {
        this.entity.fleets = [];
        this.entity.vehiclesGroups = [];
        this.entity.vehicles = [];
        this.fleetsGroupsAndVehiclesSelected.forEach((o: object) => {
            if (FleetTreeComponent.isFleet(o)) {
                this.entity.fleets.push(o as Rest.Fleet);
            }
            if (FleetTreeComponent.isVehicle(o)) {
                this.entity.vehicles.push(o as Rest.Vehicle);
            }
            if (FleetTreeComponent.isVehicleGroup(o)) {
                this.entity.vehiclesGroups.push(o as Rest.VehiclesGroup);
            }
        });
    }

    initMapAfterViewInit(event) {
        if (this.realTime) {
            this.showMap = false;
        } else {
            //If the zone group is not new and the zones haven't been loaded, load them
            if (!this.isNew && this.entity.zones == null) {
                const request = RestExt.allRecords;
                request.filters = { zoneGroupID: [this.entity.id.toString()] };
                this.zoneService
                    .getPage(request)
                    .then((result: Rest.Page<Rest.Zone>) => {
                        this.entity.zones = result.entities;
                        //Remove cicular reference
                        this.entity.zones.forEach((z: Rest.Zone) => {
                            z.zoneGroup = null;
                        });
                        this.configureMap();
                    });
            }
            this.configureMap();
        }
    }

    /**
     * Translates all the variables that are binded on the form,
     * using the translator library.
     *
     * @private
     * @memberof ZoneFormComponent
     */
    private translateLabels() {
        this.title = this.translate.instant('zones.zone-group.title');
        this.zoneGroupLabel = this.translate.instant('zones.zone-group.zone-group-data');
        this.fleetsLabel = this.translate.instant('zones.zone-group.fleets');
        this.nameLabel = this.translate.instant('zones.zone-group.name');
        this.headquartersLabel = this.translate.instant('zones.zone-group.headquarters');
        this.enabledLabel = this.translate.instant('zones.zone-form.enabled');
        this.eventsLabel = this.translate.instant('zones.zone-form.events');
        this.saveLabel = this.translate.instant('zones.zone-form.save');
        this.changeStatusLabel = this.translate.instant('zones.zone-form.change-status');
    }

    close(event) {
        this.showMap = false;
    }
}
