import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-command-storage-gl320m',
  templateUrl: './command-storage-gl320m.component.html',
  styleUrls: ['./command-storage-gl320m.component.css']
})
export class CommandStorageGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Delete the stored command", value: "DELETE_COMMAND" },
    { label: "Add the stored command", value: "ADD_COMMAND" },
 ];
  @Input() cmdParameters: Rest.CommandStorageGL320M[];
  idOptions: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(i => ({label: i.toString(), value: i}));
  
  parameterOptions = ["bsiParameters","sriParameters","qssParameters","globalParameters","pinParameters","dogParameters","tmaParameters","nmdParameters","fksParameters","ntsParameters","owhParameters","simParameters","urtParameters","friParameters","geoParameters","spdParameters","temParameters","msaParameters","disParameters","pdsParameters","gamParameters","udfParameters"].map(i => ({label: i, value: i}));


  constructor(protected i18n: I18nService) { super(i18n); }
  selectedIndex: number = 0;
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.cmdParameters);
    }
  }

  onCommandIdChange(event){
    this.selectedIndex = event.value;
  }
}