<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SET PULL ALARM
          (PULLALM)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="pullAlarm.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Pull Alarm Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="PullAlarmOptions" [(ngModel)]="pullAlarm.mode"
          [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Detection Time">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="pullAlarm.detectionTime" [min]="2" [max]="60"></p-spinner>
          <span class="ui-inputgroup-addon">2~60</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="acceleration">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="pullAlarm.acceleration" [min]="1" [max]="100"></p-spinner>
          <span class="ui-inputgroup-addon">1~100</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>