import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InfoCardComponent implements OnInit {

  @Input() title: string;
  @Input() counter: number;
  @Input() statistics: number;
  @Input() icon: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
