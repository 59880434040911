<div id="pre-config-form">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>

  <p-panel header="EVIX Parameters">
      <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
          <div class="ui-g-12 ui-md-6">
              <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                  <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                      [(ngModel)]="selectedPreconfig" [style]="{'width':'200px'}" filter="filter"></p-dropdown>
              </form-input>
          </div>
        </div>
          
    <p-tabView #tv (onChange)="tabChanged()">      
      <p-tabPanel  header="Sensors">
        <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
        <!-- Sensors list -->
      </p-tabPanel>
      <p-tabPanel i18n-header header="Actuators">
          <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
      </p-tabPanel>

    </p-tabView>
  </p-panel>
  <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>
