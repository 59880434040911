import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-revise-instruction-password-configuration-concox-mt200",
  templateUrl:
    "./revise-instruction-password-configuration-concox-mt200.component.html",
  styleUrls: [
    "./revise-instruction-password-configuration-concox-mt200.component.css",
  ],
})
export class ReviseInstructionPasswordConfigurationConcoxMt200Component
  extends ServerValidatedFormComponent
  implements OnChanges
{
  @Input()
  reviseInstructionPassword: Rest.ReviseInstructionPasswordConfigurationConcoxMT200;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.reviseInstructionPassword);
    }
  }
}
