import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-delay-input-config-form',
  templateUrl: './delay-input-config-form.component.html',
  styleUrls: ['./delay-input-config-form.component.css'],

})
export class DelayInputConfigFormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {

  constructor(protected i18n: I18nService) {  super(i18n);}

  @Input() deviceParams: Rest.DeviceParameters;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
