import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LoadingComponent {
    @Input() loading: boolean;
    @Input() fullScreen: boolean;
    @Input() height: number;
    @Input() width: number;

    constructor() {

        /* Default values */
        this.loading = false;
        this.height = 100;
        this.width = 100;

    }

}
