import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { VehiclemodelService } from 'app/rest/vehiclemodel.service';
import { VehicletypeService } from 'app/rest/vehicletype.service';
import { RestExt } from 'app/services/rest-client-extension';
import { Auxiliar } from 'app/model/Auxiliar';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';
@Component({
    selector: 'app-model-form',
    templateUrl: './model-form.component.html',
    styleUrls: ['./model-form.component.scss'],
    providers: [VehiclemodelService, IconService, VehicletypeService, VehiclebrandService, InvVehicleService],
})
export class ModelFormComponent
    extends EntityFormComponent<Rest.VehicleModel>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    availableTypes: SelectItem[];
    availableBrands: SelectItem[];
    title: string;
    disableSave: boolean;

    modelEnabled: boolean = true;
    typeRequestContext: Rest.ListPaginationRequest;
    brandRequestContext: Rest.ListPaginationRequest;
    client: Rest.Client;
    brandList: Rest.VehicleBrand[] = [];
    vehicleTypeList: Rest.VehicleType[] = [];

    constructor(
        public invVehicleService: InvVehicleService,
        private modelService: VehiclemodelService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
        public translateService: TranslateService,
        public typeService: VehicletypeService,
        public brandService: VehiclebrandService
    ) {
        super(modelService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeRequestContext = RestExt.allRecordsRequest();
        this.brandRequestContext = RestExt.firstPageRequestVehicleInventory();
        this.client = this.authenticationService.user.client;
        this.brandRequestContext.filters["client"] = [this.client.id.toString()];
        this.getVehicleTypeList();
        this.getBrandsByClient();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ModelFormComponent
     */
    beforeShow(): void {
        this.modelEnabled = !this.entity.disabled;

        if (this.isNew) {
            this.entity.type = this.vehicleTypeList.find(t => t.name == "Car");
            this.title = this.translateService.instant('tableManagement.models.createModel');
        } else {
            this.title = this.translateService.instant('tableManagement.models.editModel');
        }
    }

    getVehicleTypeList() {
        var that = this;
        this.typeService
            .getPage(that.typeRequestContext)
            .then(
                function (response) {
                    that.vehicleTypeList = response.entities;
                    that.vehicleTypeList.sort((a, b) => a.name > b.name ? 1 : -1);
                },
                /* failure */
                function (error) {
                    console.log('The request failed: ' + error);

                }
            )
            .catch(function (reason) {
                console.log('Catched: ' + reason);
            });
    }

    typeChanged(type) {
        this.getBrandsByClient();
        this.entity.brand = null;
    }

    async getBrandsByClient() {
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            this.invVehicleService
                .getBrands(this.authenticationService.user.client.id)
                .then(
                    function (response) {
                        that.brandList = [];
                        that.brandList = response;
                        that.brandList.sort((a, b) => a.name > b.name ? 1 : -1);
                    },
                    function (error) {
                        console.log('The request failed: ' + error);
                        resolve();
                    }
                )
                .catch(function (reason) {
                    console.log('Catched: ' + reason);
                    resolve();
                });
        });
    }

    protected beforeSave(): void {
        this.entity.client = this.client;
    }

    saveChanges() {
        this.messages = [];
        this.entity.client = this.authenticationService.user.client;

        const that = this;
        if (this.isNew) {
            this.modelService
                .create(this.entity)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.modelService
                .update(this.entity)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }
}
