<div class="container-fluid real-time-main-container">
  <!-- ROW 1: INFO CARDS -->
  <div class="row pb-4 pt-0 info-card-container">

    <!-- COL 1: CONNECTION STATUS CARD -->
    <div class="col ms-5">
      <app-connection-watch></app-connection-watch>
    </div>
        <!-- COL 2: VEHICLE STATS-->
    <div *ngIf="showChartSettings" class="col">
      <app-settings-stats></app-settings-stats>
    </div>
        <!-- COL 2: EXAMPLE CARD 2 - FLEETS IN MAINTENANCE -->
        <div class="col">
            <app-info-card
              title="Fleets in maintenance"
              counter="7"
              statistics="-3.48"
              icon="assets/icons/info-card-wrench.svg"
              hidden="true"
            ></app-info-card>
          </div>



    <!-- COL 3: EXAMPLE CARD 3 - DELIVERIES -->
    <div class="col">
      <app-info-card
        title="Deliveries"
        counter="924"
        statistics="3.48"
        icon="assets/icons/info-card-box.svg"
        hidden="true"
      ></app-info-card>
    </div>

    <!-- COL 4: EXAMPLE CARD 4 - PERFORMANCE -->
    <div class="col me-5">
      <app-info-card
        title="Performance"
        counter="48.65%"
        statistics="3.48"
        icon="assets/icons/info-card-bars.svg"
        hidden="true"
      ></app-info-card>
    </div>

    <div class="col me-5"  style="position: relative">

    <div data-bs-toggle="modal" style="text-align:right;position: absolute;bottom: 0; right: 0; margin-bottom: 10px;" (click)="initSettingsModal()"  data-bs-target="#RTPanelSettings" class="help-icon">
      <svg-icon src="assets/icons/settings.svg" class="d-flex flex-wrap" [svgStyle]="{ 'height.px': 25, 'width.px': 20 }"></svg-icon>    </div>
    </div>

  </div>


  <!-- TODO: FIX IMPLEMENTATION - UNCOMMENT -->
  <!--ROW 2: BASE PANEL CONFIG-->

  <!-- ROW 3: FLEET TREE & MAP PANELS -->
  <div class="row">

    <!-- COL 1: FLEET TREE PANEL && VEHICLE STATUS -->
    <div class="col col-sm-4 mt-4 ms-3" style="width: 25%;">

      <!-- FLEET TREE -->
      <app-side-panel [(initialSettings)]="selectedSettings.fleetTreeSettings"></app-side-panel>

      <!-- VEHICLE STATUS -->
      <!--<div class="d-flex align-items-center justify-content-center rt-fleet-vehicle-status-outer-container">
        <div class="d-flex align-items-center justify-content-center w-100 gap-4 pt-1 ms-4 me-4 mt-1 mb-2 rt-fleet-vehicle-status-inner-container">-->

          <!-- VEHICLE GREEN STATUS -->
          <!--<div class="d-flex align-items-center">
            <img src="assets/icons/fleet-vehicle-green.png" class="me-2 rt-fleet-vehicle-status-icon">
            <span class="rt-fleet-vehicle-status-counter">0</span>
          </div>-->

          <!-- VEHICLE ORANGE STATUS -->
          <!--<div class="d-flex align-items-center">
            <img src="assets/icons/fleet-vehicle-orange.png" class="me-2 rt-fleet-vehicle-status-icon">
            <span class="rt-fleet-vehicle-status-counter">0</span>
          </div>-->

          <!-- VEHICLE RED STATUS -->
          <!--<div class="d-flex align-items-center">
            <img src="assets/icons/fleet-vehicle-red.png" class="me-2 rt-fleet-vehicle-status-icon">
            <span class="rt-fleet-vehicle-status-counter">0</span>
          </div>

        </div>
      </div>-->
    </div>

    <!-- COL 2: MAP PANEL -->
      <div class="col mt-4 me-4">
          <!-- <app-rt-map  *ngIf="!rtDataService.isMapDetached" [(selectedSettings)]="selectedSettings.mapSettings" ></app-rt-map> -->
         <app-rt-map [hidden]="rtDataService.isMapDetached" [(selectedSettings)]="selectedSettings.mapSettings" ></app-rt-map>  

      </div>
    </div>

  <!-- ROW 4: VEHICLE PANEL -->
  <div class="row">
    <div class="col mt-5 ms-3 me-5 mb-5">
      <app-vehicle-panel [(selectedSettings)]="selectedSettings.vehicleDetailsSettings"></app-vehicle-panel>
    </div>
  </div>

</div>

<!-- BASE PANEL EDIT NAME DIALOG -->
<p-dialog #op i18n-header header="View name"  [responsive]="true" showEffect="fade" [modal]="true">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    
    <div class="row mt-3">
      <div class="col">
        <div class="d-flex justify-content-end align-items-center">
          <label i18n style="margin-right: 4%;">Base panel:</label>
          <p-dropdown (onChange)="settingsChanged()" [options]="availableSettings" [(ngModel)]="selectedSettings"
                      [style]="{'width':'250px'}"></p-dropdown>
        </div>
      </div>
  
      <div class="col">
        <button i18n class="btn btn-primary" style="margin-right: 3%; width: 100px;" (click)="displaySettingsForm=true">Save</button>
        <button i18n class="btn btn-danger" style="width: 100px;" [disabled]="selectedSettings.id===-1" (click)="delete()">Delete</button>
      </div>
  
    </div>
  
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <label i18n>Name:</label>
        <input type="text" pInputText placeholder-i18n placeholder="Settings name" [(ngModel)]=selectedSettings.name>
      </div>
    </div>

    <p-footer>
      <button (click)=create() i18n>Save as new</button>
      <button *ngIf="selectedSettings.id!==-1" (click)=update() i18n>
        {{'general.update' | translate}}
      </button>
    </p-footer>

  </div>
</p-dialog>

<div  class="modal fade vehicle-panel-settings-modal" id="RTPanelSettings" aria-hidden="true" aria-labelledby="RTPanelSettings"
     data-bs-backdrop="static"
     data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">

    <div class="modal-content">

      <div class="modal-header">
        <div class="container-fluid">

          <!-- ROW 1: MODAL TITLE & CLOSE BUTTON -->
          <div class="row">

            <!-- COL 1: MODAL TITLE-->
            <div class="col-10">
              <span class="modal-title custom-title" i18n>
                {{'real-time.settingsModal.settingsModalTitle' | translate}}
              </span>
            </div>

            <!-- COL 2: CLOSE BUTTON -->
            <div class="col">
              <div class="d-flex align-items-center justify-content-end h-100">
                <button type="button" #closeModalEl  class="btn-close m-0 p-0 custom-modal-close-button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="modal-body">
        <div class="container-fluid">


          <div class="row">
            <div class="col">
            
   
             <!-- <div class="row row-cols-4 m-4">
               <div class="col ps-0 pe-0">
                  <div class="d-flex align-items-center h-100">
                    <select name="language"
                            id="language"
                            class="form-select custom-select custom-vehicle-panel-select"
                            [(ngModel)]="_selectedSettings.vehicleDetailsSettings"
                    >
    
                      <option *ngFor="let setting of vehiclePanel.availableSettings" [ngValue]="setting">{{setting.name}}</option>
                    </select>
                  </div>
                </div>
             </div> -->

        <!-- Bootsrap combo no funciona -->
          <!--
             <div class="col ps-0 pe-0">
              <div class="d-flex align-items-center h-100">
                <select name="language"
                        id="language"
                        class="form-select custom-select custom-vehicle-panel-select"
                        [(ngModel)]="_selectedSettings.fleetTreeSettings"
                >
                  <option *ngFor="let setting of sidePanel.availableSettings" [ngValue]="setting">{{setting.name}}</option>
                </select>
              </div>
            </div> -->

            <div class=" justify-content-end align-items-center">
              <div hidden class="row">
                <div class="col">
                  <label class="form-label custom-label" i18n style="margin-right: 4%;">Base panel:</label>
                </div>
                <div class="col">
                  <p-dropdown (onChange)="settingsChanged()" [options]="availableSettings" [(ngModel)]="selectedSettings"
                  [style]="{'width':'250px'}"></p-dropdown>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label class="form-label custom-label" i18n style="margin-right: 4%;">{{'real-time.rtSettings.lateral_panel' | translate}}:</label> 
                </div>
                <div class="col">
                  <p-dropdown   optionLabel="label"  optionValue="value" [options]="sidePanel.availableSettings"  [(ngModel)]="_selectedSettings.fleetTreeSettings"
                  [style]="{'width':'250px'}"></p-dropdown>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <label class="form-label custom-label" i18n style="margin-right: 4%;">{{'real-time.rtSettings.map_panel' | translate}}:</label> 
                </div>
                <div class="col">
                  <p-dropdown  optionValue="value" optionLabel="label" [options]="mapPanel.availableSettings"  [(ngModel)]="_selectedSettings.mapSettings"
                  [style]="{'width':'250px'}"></p-dropdown> 
                </div>
              </div>

              
              <div class="row mt-4">
                <div class="col">
                  <label class="form-label custom-label" i18n style="margin-right: 4%;">{{'real-time.rtSettings.vehicle_panel' | translate}}:</label> 
                </div>
                <div class="col">
                  <p-dropdown   optionLabel="name" [options]="vehiclePanel.availableSettings"  [(ngModel)]="_selectedSettings.vehicleDetailsSettings"
                  [style]="{'width':'250px'}"></p-dropdown>
                </div>
              </div>
  
            </div>  

            <div class="row mt-4 ">
              <div class="col"></div>
              <div class="col"></div>
              <div class="col">
                <button  class="btn custom-button custom-device-panel-button "  style="float: right;" (click)=update() i18n>
                  Guardar
                  </button>
              </div> 
          </div>
  
    <!--       <div class="ui-grid-row">
            <div class="ui-grid-col-12">
              <label i18n>Name:</label>
              <input type="text" pInputText placeholder-i18n placeholder="Settings name" [(ngModel)]=_selectedSettings.name>
            </div>
          </div>

           <button (click)=create() i18n>Save as new</button>
            <button *ngIf="selectedSettings.id!==-1" (click)=update() i18n>Update</button>
            <button i18n class="btn btn-danger" style="width: 100px;" [disabled]="selectedSettings.id===-1" (click)="delete()">Delete</button>
        -->

             <!--  <div class="d-flex align-items-center">
                <label for="language" class="form-label custom-label mb-0 me-3" i18n>
                  Base panel:
                                </label>
                <select   (ngModelChange)="settingsChanged()"  name="language" id="language" class="form-select custom-select custom-vehicle-panel-view-select"
                        [(ngModel)]="selectedSettings" >
                  <option *ngFor="let setting of availableSettings" [ngValue]="setting">{{setting.label}}</option>
                </select>
              </div> -->

             </div>
          </div>
          <div class="row">
      
          
          </div>
          <div class="col  mt-3">
            <div class="d-flex align-items-center justify-content-end h-100 gap-2">

            </div>

          </div>

          </div>
        </div>
      </div>

    </div>

  </div>
 