import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-fixed-report-information-gl320m',
  templateUrl: './fixed-report-information-gl320m.component.html',
  styleUrls: ['./fixed-report-information-gl320m.component.css']
})
export class FixedReportInformationGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
    { label: "Disable the fixed report function", value: "DISABLE" },
    { label: "Enable the fixed time report", value: "TIME" },
    { label: "Enable the fixed distance report", value: "DISTANCE" },
    { label: "Enable the fixed mileage report", value: "MILEAGE" },
    { label: "Optimum report", value: "OPTIMUM" },
    { label: "Reserved", value: "RESERVED" },
    { label: "Fixed Time or Mileage Report", value: "TIME_OR_MILEAGE" },
 ];

 movementDetectionOptions: any = [
  { label: "Disable the movement detection function", value: "DISABLE" },
  { label: "Enable the movement detection function", value: "ENABLE" },
 ]
  @Input() friParameters: Rest.FixedReportInformationGL320M;
  


  constructor(protected i18n: I18nService) { super(i18n); }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.friParameters);
    }
  }
}