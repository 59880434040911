import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-canbus-device-configuration-gv300can",
  templateUrl: "./canbus-device-configuration-gv300can.component.html",
  styleUrls: ["./canbus-device-configuration-gv300can.component.css"],
})
export class CanbusDeviceConfigurationGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @ViewChild("ContentDiv", { static: true }) ContentDiv;
  @Input() canParameters: Rest.CanbusDeviceConfigurationGV300CAN;

  AdditionalEventOptions: any = [
    { label: "ignore all additional event", value: 0 },
    { label: "by ignition on off event", value: 1 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {
    if (this.canParameters != null) {
      this.disableDIV(!this.canParameters.modeEnabled);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.canParameters);
    }
  }
  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.ContentDiv.nativeElement.classList.remove("disableDIV");
    }
  }
}
