import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
    manageDevices: boolean;
    manageVehicles: boolean;
    manageMaintenancePlans: boolean;
    manageZones: boolean;
    managePOI: boolean;
    manageNotificationGroups: boolean;
    manageRules: boolean;
    manageClients: boolean;
    manageUsers: boolean;

    constructor(private auth: AuthenticationService, private router: Router) {
        this.manageDevices = auth.user.canManageDevices;
        this.manageVehicles = auth.user.canManageFleets;
        this.manageMaintenancePlans = auth.user.canManageMaintenancePlans;
        this.manageZones = auth.user.canManageZoneGroups;
        this.managePOI = auth.user.canManagePOICategories;
        this.manageNotificationGroups = auth.user.canManageNotificationGroups;
        this.manageRules = auth.user.canManageRules;
        this.manageClients = auth.user.canManageClients;
        this.manageUsers = auth.user.canManageUsers;
        /*
        this.router.events.subscribe((r: any) => {
            if (r.url === '/control-panel') {
                this.getDefaultRoute();
            }
        });*/
    }

    ngOnInit(): void {
        this.getDefaultRoute();
    }

    getDefaultRoute(): void {
        if (this.manageDevices) {
            this.router.navigate(['/control-panel/devices']);           
        } else if (this.manageVehicles) {
            this.router.navigate(['/control-panel/fleets']);
        } else if (this.manageMaintenancePlans) {
            this.router.navigate(['/control-panel/maintenancePlans']);
        } else if (this.manageZones) {
            this.router.navigate(['/control-panel/zoneGroups']);
        } else if (this.managePOI) {
            this.router.navigate(['/control-panel/poiCategory']);
        } else if (this.manageNotificationGroups) {
            this.router.navigate(['/control-panel/noticeGroups']);
        } else if (this.manageRules) {
            this.router.navigate(['/control-panel/rule']);
        } else if (this.manageClients) {
            this.router.navigate(['/control-panel/clients']);
        } else if (this.manageUsers) {
            this.router.navigate(['/control-panel/users']);
        } else {
            this.router.navigate(['/control-panel']);
        }
    }
}
