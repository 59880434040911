<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Backend Server
                    Register Information (AT + GTSRI)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Basic Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Report Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="reportOptions" [(ngModel)]="sriParameters.mode"
                        [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Backend Server
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Main Server IP">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="sriParameters.mainServerIp" maxlength="60" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Main Server Port">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="sriParameters.mainServerPort" [min]="0"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">0~65535</span>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Backup Server IP">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="sriParameters.backupServerIp" maxlength="60" class="txtbStyle"/>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Backup Server Port">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="sriParameters.backupServerPort" [min]="0"
                                    [max]="65535"></p-spinner>
                                <span class="ui-inputgroup-addon">0~65535</span>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="SMS Gateway">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <input type="text" pInputText [(ngModel)]="sriParameters.smsGateway" maxlength="20" class="txtbStyle"/>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Other Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Heartbeat Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="sriParameters.heartbeatInterval" [min]="0"
                                    [max]="360"></p-spinner>
                                <span class="ui-inputgroup-addon">0~360</span>
                                <span class="ui-inputgroup-addon">min</span>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Buffer Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="bufferOptions" [(ngModel)]="sriParameters.buffer"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="SACK Enable">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="sackOptions" [(ngModel)]="sriParameters.modeSack"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                
                </div>

                <div class="row mt-3">
                    <p-checkbox binary="true" label="SMS ACK Enable" [(ngModel)]="sriParameters.enableSmsAck">
                    </p-checkbox>
                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Multi-packet Sending">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="multipacketOptions" [(ngModel)]="sriParameters.modeMultipacketSending"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="DNS Lookup Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" [(ngModel)]="sriParameters.dnsLookupInterval" [min]="0"
                                    [max]="1440"></p-spinner>
                                <span class="ui-inputgroup-addon">0~1440</span>
                                <span class="ui-inputgroup-addon">min</span>
                            </div>
                        </div>
                    </div>
                
                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>