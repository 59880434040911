<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Frequency Change of
          Fixed Report Information (AT + GTFFC)</span>
      </div>
    </div>
  </p-header>
  <div class="row mt-3">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Priority">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="ffcParameters.priority" [min]="0" [max]="4"></p-spinner>
          <span class="ui-inputgroup-addon">0~4</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Mode">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-dropdown [options]="modeOptions" [(ngModel)]="ffcParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
        </div>
      </div>
    </div>

  </div>

</p-accordionTab>