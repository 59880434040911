import { Injectable } from '@angular/core';
import { EntityService } from '../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';
import { Rest } from '../../../rest/rest_client';

@Injectable()
export class SATService extends EntityService<any> {
    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }


    getCreditsUser(userId: number): Rest.RestResponse<Rest.CreditsUserSAT> {
        return this.client.SAT_getCredits(userId);
    }

    updateCreditsUser(arg0: Rest.CreditsHistory): Rest.RestResponse<Rest.CreditsUserSAT> {
        return this.client.SAT_updateCredits(arg0);
    }

}
