<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Speed Alarm
          (AT + GTSPD)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Speed Alarm Mode">
      </form-input>
    </div>
    <div class="col">
      <p-dropdown (onChange)="trackEventModeChange(speedAlarmParameters.mode,$event)" [options]="modeOptions"
        [(ngModel)]="speedAlarmParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
    </div>
  </div>

  <div [class.disableDIV]=!trackModeSwitch>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Min speed">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="speedAlarmParameters.minSpeed" [min]="0" [max]="400"></p-spinner>
            <span class="ui-inputgroup-addon">0~400</span>
            <span class="ui-inputgroup-addon">km/h</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Max speed">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="speedAlarmParameters.maxSpeed" [min]="0" [max]="400"></p-spinner>
            <span class="ui-inputgroup-addon">5~400</span>
            <span class="ui-inputgroup-addon">km/h</span>
          </div>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Validity">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="speedAlarmParameters.validity" [min]="0" [max]="3600"></p-spinner>
            <span class="ui-inputgroup-addon">0~3600</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Send Interval">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="speedAlarmParameters.sendInterval" [min]="0" [max]="3600"></p-spinner>
            <span class="ui-inputgroup-addon">0~3600</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</p-accordionTab>