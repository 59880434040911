import {
    Component,
    ElementRef,EventEmitter,
    Input,OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,

} from "@angular/core";
import { ConfirmationService, TreeNode } from "primeng/api";
import { RealtimeService } from "../../../../rest/realtime.service";
import { FleetService } from "../../../../rest/fleet.service";
import { Rest } from "../../../../rest/rest_client";
import { AuthenticationService } from "../../../../core/services/authentication/authentication.service";
import { RealTimeDataService } from "../../../../services/rt-data/rt-data.service";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../services/rest-client-extension";
import { TreeQuickActionsComponent } from "./tree-quick-actions/tree-quick-actions.component";

export interface FleetTreeNode extends TreeNode {
    entityType: "Fleet" | "Group" | "Vehicle";
    selected: boolean;
    enabled: boolean;
}

export enum TreeQuickActionsType {
  center,
  requestPosition,
  settings,
  report,
  showRoute,
  sendMessage,
  io,
  alerts,
  sendLocation,
  call,
  maintenances,
  calendar,
  zones
}

@Component({
    selector: "app-rt-fleet-tree",
    templateUrl: "./rt-fleet-tree.component.html",
    styleUrls: ["./rt-fleet-tree.component.css"],
    providers: [FleetService, ConfirmationService, RealtimeService],
})
export class RealTimeFleetTreeComponent implements OnInit, OnChanges {
  @ViewChild('groupExpandButton') groupExpandButton: ElementRef;

  @Input() selection: RestExt.WatchedVehicle[];
  @Input() fleets: Rest.RTFleet[];

  @Input() cascadeSelection: boolean; //When select checkbox of entity, all sub entities become selected
  @Input() selectableEntities: boolean; //When select label of entity, all its information get loaded on the right panel

  @Output() selectionChange: EventEmitter<Object[]> = new EventEmitter<Object[]>();
  @Output() vehiclesSelected: EventEmitter<Number[]> = new EventEmitter<Number[]>();

  /***Options entities */
  optionEntities: Map<string, boolean> = new Map<string, boolean>(); //All nodes selected by user and by cascade effect
  classButtons: Map<string, string> = new Map<string, string>();
  indeterminateEntity: Map<string, boolean> = new Map<string, boolean>();
    VehiclesInfo: Array<RestExt.ExtendedVehicleDataMessage> = []; //lastData of every vehicle we have 
  collapse: boolean = false; //Show Expand All or Collapse All button
  collapsedGroup: boolean = false;
  collapsedFleet: boolean = false;

  
  nodeSelected: FleetTreeNode;
  selectAfterChangeStatus: boolean = false;
  /*** */
  
  private hasMore: boolean;
  fleetsTree: FleetTreeNode[];
  selectedNodes: FleetTreeNode[]; //All nodes selected by user (not in cascade)
  fleetService: FleetService;
  searchString: string;
  items: any;
  
  omitSelectionChange: boolean = false; //When user clicks a checkbox, checkboxSelected method is called, this method modifies the selection input var to change
  //and this causes the onChanges event to fire. This flag is used to stop this behaviour.
  
  // Vehicle options
  hoveredVehicleId: number = -1;
  vehicleOptions: TreeQuickActionsType[] = [
    TreeQuickActionsType.center,
    TreeQuickActionsType.settings,
    TreeQuickActionsType.maintenances,
    TreeQuickActionsType.showRoute,
  ];

  // vehicleOptions: TreeQuickActionsComponent[] = [
  //   new TreeQuickActionsComponent(TreeQuickActionsType.settings),
  //   new TreeQuickActionsComponent(TreeQuickActionsType.showRoute),
  // ];


    constructor(
        fleetService: FleetService,
        private rt: RealtimeService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        private i18n: I18nService,
        private authenticationService: AuthenticationService,
        private realTimeDataService: RealTimeDataService
    ) {
        this.fleetService = fleetService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["fleets"]) {
            this.fillTree();
        }
        if (changes["selection"] && !this.omitSelectionChange) {
            //Simulate that the user has clicked each selected entity
            this.selection.forEach((s: object) => {
                if (s.hasOwnProperty("visibleInMap")) {
                    if (RealTimeFleetTreeComponent.isVehicle(s)) {
                        let vehicles = [];
                        this.fleetsTree.forEach((f) =>
                            f.children.forEach(
                                (g) => (vehicles = vehicles.concat(g.children))
                            )
                        );
                        let vehicle = vehicles.find((v) => s["id"] == v.data["id"]);                       
                        this.nodeCheck(true, vehicle as FleetTreeNode, false);
                        this.nodeCheck(s["visibleInMap"], vehicle as FleetTreeNode, false);
                    }
                }
            });
        }

        //Always will be a vehicle
        if (
            changes["TestDeleteEntity"] &&
            changes["TestDeleteEntity"].currentValue != undefined
        ) {
            for (var fleet in this.fleetsTree) {
                for (var group in this.fleetsTree[fleet].children) {
                    for (var vehicle in this.fleetsTree[fleet].children[group].children) {
                        if (
                            this.fleetsTree[fleet].children[group].children[vehicle].data
                                .id == changes["TestDeleteEntity"].currentValue["id"]
                        ) {
                            this.optionEntities[
                            this.fleetsTree[fleet].children[group].children[vehicle].label +
                            "-" +
                            this.fleetsTree[fleet].children[group].children[vehicle].data
                                .id
                                ] = false;
                            this.nodeCheck(
                                false,
                                this.fleetsTree[fleet].children[group].children[
                                    vehicle
                                    ] as FleetTreeNode,
                                false
                            );
                        }
                    }
                }
            }
        }

        this.omitSelectionChange = false;
    }

    ngOnInit() {
      this.fleetsTree = [];
      this.selectedNodes = [];
      var that = this;

        this.realTimeDataService.vehicleInfo.subscribe(
          (v: RestExt.ExtendedVehicleDataMessage) => {
              this.updateVehicleData(v);
          }
      );
    }

    fillTree() {
        this.selectedNodes = [];

        let lowerCaseSearchStr =
            this.searchString != null ? this.searchString.toLowerCase() : null;

        this.fleetsTree = [];

        for (var fleet in this.fleets) {
            this.optionEntities[
            this.fleets[fleet].name + "-" + this.fleets[fleet].id
                ] = false;
            let fleetMatchesSearch =
                lowerCaseSearchStr == null ||
                this.fleets[fleet].name.toLowerCase().includes(lowerCaseSearchStr); //Weather or not the fleet or any of the sub-elemnets (vehicle-group,vehicle) matches the given search string (if any)

            var fleetNode: FleetTreeNode = {
                type: "entity",
                children: [],
                label: this.fleets[fleet].name,
                data: this.fleets[fleet],
                entityType: "Fleet",
                expanded: this.searchString == null,
                selectable: false,
                selected: false,
                styleClass: fleetMatchesSearch ? "highlighted" : "",
                enabled: true,
            };

            for (var group in this.fleets[fleet].groups) {
                let groupMatchesSearch =
                    lowerCaseSearchStr == null ||
                    this.fleets[fleet].groups[group].name
                        .toLowerCase()
                        .includes(lowerCaseSearchStr); //Weather or not the vehicle-group or any of the composing vehicles matches the given search string (if any)
                this.optionEntities[
                this.fleets[fleet].groups[group].name +
                "-" +
                this.fleets[fleet].groups[group].id
                    ] = false;

                var groupNode: FleetTreeNode = {
                    type: "entity",
                    children: [],
                    label: this.fleets[fleet].groups[group].name,
                    data: this.fleets[fleet].groups[group],
                    entityType: "Group",
                    parent: fleetNode,
                    expanded: this.searchString == null,
                    selectable: false,
                    styleClass: groupMatchesSearch ? "highlighted" : "",
                    selected: false,
                    enabled: true,
                    /** */
                };

                //expand the fleet node if the group matches the search
                if (groupMatchesSearch) fleetNode.expanded = true;

                for (var vehicle in this.fleets[fleet].groups[group].vehicles) {
                    //expand the group node if no search string has been provided or the group node name contains the searched string
                    var vehicleNode: FleetTreeNode = {
                        type: "entity",
                        children: [],
                        label: this.fleets[fleet].groups[group].vehicles[vehicle].name,
                        data: this.fleets[fleet].groups[group].vehicles[vehicle],
                        parent: groupNode,
                        selectable: false,
                        entityType: "Vehicle",
                        selected: false,
                        enabled: true,
                        /** */
                    };

                    //The label of the node matches the search string
                    let vehicleMatchesSearchString =
                        lowerCaseSearchStr == null ||
                        this.fleets[fleet].groups[group].vehicles[vehicle].name
                            .toLowerCase()
                            .includes(lowerCaseSearchStr);

                    if (!vehicleMatchesSearchString) continue;

                    groupMatchesSearch = true; //The vehicle matchhes the search string, so does the fleet
                    vehicleNode.styleClass = "highlighted";
                    groupNode.expanded = true;
                    fleetNode.expanded = true;
                    groupNode.children.push(vehicleNode);
                }

                //only add the group if matches the search string
                if (groupMatchesSearch) {
                    fleetMatchesSearch = true;
                    fleetNode.children.push(groupNode);
                }
            }

            if (!fleetMatchesSearch) continue;
            this.fleetsTree.push(fleetNode);
        }
        //Sort
        this.fleetsTree.forEach((f) =>
            f.children.forEach(
                (g) =>
                    (g.children = g.children.sort((a, b) =>
                        a.label.localeCompare(b.label)
                    ))
            )
        );
        this.fleetsTree.forEach(
            (f) =>
                (f.children = f.children.sort((a, b) => a.label.localeCompare(b.label)))
        );
        this.fleetsTree = this.fleetsTree.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        this.fleetsTree = [...this.fleetsTree];
    }

  search(searchWord: string) {
    this.searchString = searchWord;
    this.fillTree();
  }

    public static isVehicleGroup(s: Object): boolean {
        return s.hasOwnProperty("vehicles");
    }

    public static isFleet(s: Object): boolean {
        return s.hasOwnProperty("groups");
    }

    public static isVehicle(s: Object): boolean {
        return !s.hasOwnProperty("groups") && !s.hasOwnProperty("vehicles");
    }

    expandAll() {
        this.fleetsTree.forEach((node) => {
            this.expandRecursive(node, true);
        });

        this.collapse = false;
    }

    collapseAll() {
        this.fleetsTree.forEach((node) => {
            this.expandRecursive(node, false);
        });

        this.collapse = true;
    }

    ngAfterViewInit() {
    }

  private expandRecursive(node: FleetTreeNode, isExpand: boolean) {

    if (node.entityType === 'Fleet') {
      this.collapsedFleet = !isExpand;
    }

    if (node.entityType === 'Group') {
      this.collapsedGroup = !isExpand;
    }

    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(<FleetTreeNode>childNode, isExpand);
      });
    }
  }

    /**Check */
    nodeCheck(event, node: FleetTreeNode, updateSelection: boolean = true) {
        this.optionEntities[node.label + "-" + node.data.id] = event.target.checked;

    if (event.target.checked) {
      if (!this.selectedNodes.find((item) => item.data.id == node.data.id && item.entityType == node.entityType)) {
        this.selectedNodes.push(node);
      }
    } else {
      this.deselectNode(node);
    }

        if (this.cascadeSelection) {
            if (node.entityType === "Fleet") {
                this.indeterminateEntity[node.label + "-" + node.data.id] = false;

        if (node.children.length > 0) {
          for (var g in node.children) {
            //marcar o desmarcar los grupos
            this.indeterminateEntity[node.children[g].label + "-" + node.children[g].data.id] = false;
            this.optionEntities[node.children[g].label + "-" + node.children[g].data.id] = event.target.checked;

                        //Si se ha seleccionado la flota, borramos los grupos seleccionados previamente.
                        for (var i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                            if (this.selectedNodes[i] === node.children[g]) {
                                this.selectedNodes.splice(i, 1);
                            }
                        }

            for (var v in node.children[g].children) {
              //marcar o desmarcar los grupos
              this.indeterminateEntity[node.children[g].children[v].label + "-" + node.children[g].children[v].data.id] = false;
              this.optionEntities[node.children[g].children[v].label + "-" + node.children[g].children[v].data.id] = event.target.checked;

                            //Si se ha seleccionado el grupo, borramos los vehiculos seleccionados previamente.
                            for (var i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                                if (this.selectedNodes[i] === node.children[g].children[v]) {
                                    this.selectedNodes.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }

      if (node.entityType === "Group") {
        this.indeterminateEntity[node.label + "-" + node.data.id] = false;
        this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;

        //Seleccion en cascada hacia abajo
        if (node.children.length > 0) {
          for (var g in node.children) {
            //marcamos o desmarcamos los vehiculos
            this.optionEntities[node.children[g].label + "-" + node.children[g].data.id] = event.target.checked;
            this.indeterminateEntity[node.children[g].label + "-" + node.children[g].data.id] = false;

                        //Si se ha seleccionado el grupo, borramos los vehiculos seleccionados previamente.
                        for (var i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                            if (this.selectedNodes[i] === node.children[g]) {
                                this.selectedNodes.splice(i, 1);
                            }
                        }
                    }
                }

        this.optionEntities[node.parent.label + "-" + node.parent.data.id] = false;

        //Seleccion en cascada hacia arriba
        if (event.target.checked) {
          if (
            this.vehiclesCheckedOfNode(node.parent) == this.numOfVehicles(node.parent)
          ) {
            this.optionEntities[node.parent.label + "-" + node.parent.data.id] = true;
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;

            //si se marca el padre desmarcamos el nodo seleccionado y marcamos el padre
            if (!this.selectedNodes.find((item) => item.data.id == node.parent.data.id && item.entityType == "Fleet")) {
              this.selectedNodes.push(node.parent as FleetTreeNode);
            }
            this.deselectNode(node);

            for (var child in node.parent.children) {
              this.deselectNode(node.parent.children[child] as FleetTreeNode);
            }
          } else {
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = true;
          }
        } else {
          if (this.vehiclesCheckedOfNode(node.parent) == 0) {
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;
          } else {
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = true;
          }
        }
      }

      if (node.entityType === "Vehicle") {
        //Group indeterminated
        if (event.target.checked) {
          if (this.vehiclesCheckedOfGroup(node.parent) == this.numOfVehiclesGroup(node.parent)) {
            this.optionEntities[node.parent.label + "-" + node.parent.data.id] = true;
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;

            //si se marca el padre desmarcamos el nodo seleccionado y marcamos el padre
            if (!this.selectedNodes.find((item) => item.data.id == node.parent.data.id && item.entityType == "Group")) {
              this.selectedNodes.push(node.parent as FleetTreeNode);
            }
            this.deselectNode(node);

            for (var child in node.parent.children) {
              this.deselectNode(node.parent.children[child] as FleetTreeNode);
            }
          } else {
            this.optionEntities[node.parent.label + "-" + node.parent.data.id] = false;
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = true;
            this.deselectNode(node.parent as FleetTreeNode);
          }
        } else {
          this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;
          this.optionEntities[node.parent.label + "-" + node.parent.data.id] = false;
          this.deselectNode(node.parent as FleetTreeNode);

          if (this.vehiclesCheckedOfGroup(node.parent) == 0) {
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = false;
          } else {
            this.indeterminateEntity[node.parent.label + "-" + node.parent.data.id] = true;
          }
        }

        //Flota indeterminated
        if (event.target.checked) {
          if (this.vehiclesCheckedOfNode(node.parent.parent) == this.numOfVehicles(node.parent.parent)) {
            this.optionEntities[node.parent.parent.label + "-" + node.parent.parent.data.id] = true;
            this.indeterminateEntity[node.parent.parent.label + "-" + node.parent.parent.data.id] = false;

            //si se marca el padre desmarcamos el nodo seleccionado y marcamos el padre
            if (!this.selectedNodes.find((item) => item.data.id == node.parent.parent.data.id && item.entityType == "Fleet")) {
              this.selectedNodes.push(node.parent.parent as FleetTreeNode);
            }
            this.deselectNode(node.parent as FleetTreeNode);

            for (var child in node.parent.parent.children) {
              this.deselectNode(node.parent.parent.children[child] as FleetTreeNode);
            }
          } else {
            this.optionEntities[node.parent.parent.label + "-" + node.parent.parent.data.id] = false;
            this.indeterminateEntity[node.parent.parent.label + "-" + node.parent.parent.data.id] = true;
            this.deselectNode(node.parent.parent as FleetTreeNode);
          }
        } else {
          this.optionEntities[node.parent.parent.label + "-" + node.parent.parent.data.id] = false;
          this.deselectNode(node.parent.parent as FleetTreeNode);

          if (this.vehiclesCheckedOfNode(node.parent.parent) == 0) {
            this.indeterminateEntity[node.parent.parent.label + "-" + node.parent.parent.data.id] = false;
          } else {
            this.indeterminateEntity[node.parent.parent.label + "-" + node.parent.parent.data.id] = true;
          }
        }
      }
    }

    if (updateSelection) {
      this.omitSelectionChange = true;
      this.checkedVehicles();
    }

  }

    checkedVehicles() {
        let vehiclesIds: number[] = [];
        //Get the Ids of the vehicles
        this.fleets.forEach((f: Rest.RTFleet) => {
            f.groups.forEach((g: Rest.RTVehiclesGroup) => {
                g.vehicles.forEach((v: Rest.RTVehicle) => {
                    if (this.optionEntities[v.name + "-" + v.id] === true) {
                        vehiclesIds.push(v.id);
                    }
                });
            });
        });
        
        this.rt.getVehicleInfo(vehiclesIds).then((vehicles: Rest.Vehicle[]) => {
            this.selectionChange.emit(vehicles);
        });
    }

    deselectNode(node: FleetTreeNode) {
        for (var i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
            if (this.selectedNodes[i] === node) {
                this.selectedNodes.splice(i, 1);
            }
        }
    }

    numOfVehiclesGroup(node: TreeNode) {
        var totalOfVehicles: number = 0;
        for (var i = 0 ; i < node.children.length ; i++) {
            totalOfVehicles = totalOfVehicles + 1;
        }

        return totalOfVehicles;
    }

  vehiclesCheckedOfGroup(node: TreeNode) {
    var checkedVehicles: number = 0;
    for (var i = 0; i < node.children.length; i++) {
      if (
        this.optionEntities[
        node.children[i].label + "-" + node.children[i].data.id
          ] == true
      ) {
        checkedVehicles = checkedVehicles + 1;
      }
    }
    return checkedVehicles;
  }

    numOfVehicles(node: TreeNode) {
        var totalOfVehicles: number = 0;
        for (var i = 0 ; i < node.children.length ; i++) {
            for (var j = 0 ; j < node.children[i].children.length ; j++) {
                totalOfVehicles = totalOfVehicles + 1;
            }
        }
        return totalOfVehicles;
    }

  vehiclesCheckedOfNode(node: TreeNode) {
    //Siempre le pasamos una flota
    var checkedVehicles: number = 0;
    for (var i = 0; i < node.children.length; i++) {
      for (var j = 0; j < node.children[i].children.length; j++) {
        if (
          this.optionEntities[
          node.children[i].children[j].label +
          "-" +
          node.children[i].children[j].data.id
            ] == true
        ) {
          checkedVehicles = checkedVehicles + 1;
        }
      }
    }

        return checkedVehicles;
    }

    getcheckedVehiclesIds() {
        let ids = [];
        this.fleetsTree.map(
            (f) => (ids = ids.concat(this.getcheckedVehiclesIdsOfNode(f)))
        );
        return ids;
    }

    getcheckedVehiclesIdsOfNode(node: FleetTreeNode): number[] {
        var checkedVehicles: number[] = [];
    if (node.entityType === "Fleet") {
      for (var i = 0; i < node.children.length; i++) {
        for (var j = 0; j < node.children[i].children.length; j++) {
          if (
            this.optionEntities[
            node.children[i].children[j].label +
            "-" +
            node.children[i].children[j].data.id
              ] == true
          ) {
            checkedVehicles.push(node.children[i].children[j].data.id);
          }
        }
      }
    }
    if (node.entityType === "Group") {
      for (var i = 0; i < node.children.length; i++) {
        if (
          this.optionEntities[
          node.children[i].label + "-" + node.children[i].data.id
            ] == true
        ) {
          checkedVehicles.push(node.children[i].data.id);
        }
      }
    }
    if (node.entityType === "Vehicle") {
      checkedVehicles.push(node.data.id);
    }    
    return checkedVehicles;
  }

  nodeClicked(node: FleetTreeNode) {
    var checkedVehicles = this.getcheckedVehiclesIdsOfNode(node);
    this.realTimeDataService.focusMap(checkedVehicles);
  }

  isNodeEmpty(node: FleetTreeNode): boolean {
    return node.children.length === 0;
  }

  vehicleLabelClicked(node: FleetTreeNode) {
    // Iterate through tree to check if node is active
    for (let i = 0; i < this.selectedNodes.length; i++) {
      const elementI = this.selectedNodes[i];
      for (let j = 0; j < elementI.children.length; j++) {
        const elementJ = elementI.children[j];
        for (let k = 0; k < elementJ.children.length; k++) {
          const elementK = elementJ.children[k];

          if (elementK.data.id === node.data.id) {
            this.realTimeDataService.focusMap([node.data.id]);
          }
        }
      }
    }
  }

  updateVehicleData(v:RestExt.ExtendedVehicleDataMessage){
    const index = this.VehiclesInfo.findIndex((x) => x.vehicleId === v.vehicleId);
    if (index ===-1){
      //add new data to vehicles
      this.VehiclesInfo.push(v);
    }else{
      //update lastData
      this.VehiclesInfo.splice(index, 1, v);
    }
  }

  getVehicleColorState(id: number, lighter: boolean): string {
    // Find the element in the array with the given id
    const vehicle = this.VehiclesInfo.find(v => v.vehicleId === id);
    // Return the status if the vehicle was found, or an error message if not
    var color = "";

    if(vehicle && vehicle.color){    
        color = vehicle.color;    
    }else{
      color = "#c7d1d3"; //if we don't have info of the vehicle we have to put grey
    }

    var gradientColor = this.calculateGradientColor(color);

     // Convert hex color codes to RGB values
     const rgb1 = this.hexToRgb(color);
     const rgb2 = this.hexToRgb(gradientColor);
   
     // Calculate the total intensity of each color
     const intensity1 = rgb1.r + rgb1.g + rgb1.b;
     const intensity2 = rgb2.r + rgb2.g + rgb2.b;

     
     if(intensity1 > intensity2){
        if(!lighter){
          color = gradientColor;
        }
     }else{
       if(lighter){
         color = gradientColor;
       }
     }
   

    return color;
  }

  calculateGradientColor(color){
    var components = [
      (255-(255-Math.trunc(Math.round(parseInt(color.substr(1,2),16))/1.2))).toString(16),
      (255-(255-Math.trunc(Math.round(parseInt(color.substr(3,2),16))/1.2))).toString(16),
      (255-(255-Math.trunc(Math.round(parseInt(color.substr(5,2),16))/1.2))).toString(16)
      ];
      if(components[0].length < 2) components[0] = "0"+components[0];
      if(components[1].length < 2) components[1] = "0"+components[1];
      if(components[2].length < 2) components[2] = "0"+components[2];
      
      var out = "#"+components[0]+components[1]+components[2];
      return out;
  }

  isLighterColor(color1, color2) {
    // Convert hex color codes to RGB values
    const rgb1 = this.hexToRgb(color1);
    const rgb2 = this.hexToRgb(color2);
  
    // Calculate the total intensity of each color
    const intensity1 = rgb1.r + rgb1.g + rgb1.b;
    const intensity2 = rgb2.r + rgb2.g + rgb2.b;
  
    // Compare the intensities to determine which color is lighter
    if (intensity1 > intensity2) {
      return color1;
    } else {
      return color2;
    }
  }
  
  hexToRgb(hex) {
    // Convert the hex color code to an RGB value
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return { r, g, b };
  }

}
