import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { RacingService } from '../../../rest/racing.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { Rest } from '../../../rest/rest_client';
import { UserTimePipe } from '../../shared/UserTimePipe';
import { LoadingService } from '../../../services/loading-service/loading.service';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { RestExt } from '../../../services/rest-client-extension';
import { RacingFormComponent } from '../racing-form/racing-form.component';
import { EntityListComponent } from '../../entity-list/entity-list.component';
import { TranslateService } from '@ngx-translate/core';


/*import { forEach } from '@angular/router/src/utils/collection';

import { CustomDatePipe } from '../../../rest/custom.datepipe';
import { EventMapComponent } from '../event-map/event-map.component';
import { EventsPoisComponent } from '../events-pois/events-pois.component';*/

@Component({
    selector: 'app-racing-list',
    templateUrl: './racing-list.component.html',
    styleUrls: ['./racing-list.component.css'],
    providers: [RacingService, UserTimePipe],
})

export class RacingListComponent extends EntityListComponent<Rest.Racing> implements OnInit, OnDestroy {
    @ViewChild(RacingFormComponent, { static: true })
    racingForm: RacingFormComponent;

    protected alive: boolean = true;
    messages: any[];
    visible: boolean;
    errors = {};
    date_now = Date.now() - 24 * 60 * 60 * 1000;
    paginationRequest: Rest.ListPaginationRequest;
    paginationResult: Rest.Page<Rest.Racing>;
    filters: { [index: string]: Rest.TableFilterSelectItem[] };
    loadFilters: boolean; //Flag indicating that associated entity filters must be loaded at next request
    hasEntityFilters = false; //Flag indicating that the entity list can be filtered by associated entities. If true, requires that the REST service used has the method getPageAndFilters
    availableTypeEvent: Rest.EventTypeRacing[];
    availableTypeCode: Rest.EventCodeTypeRacing[];
    /**Check box Column selection */
    entitiesSelected: Map<number, boolean> = new Map<number, boolean>();
    selectedAll: boolean = false;
    disableSelectAll: boolean = true;
    user: Rest.User;
    /** */
    showDeleteDialog: boolean = false;

    loading: boolean = true; //Loading...

    isSelectableColumn: boolean = false;
    isColumn: boolean = false;
    imagedelete: string = '../../../assets/images/Racing/delete.png';

    selectedRacingEvent: Rest.Racing;

    constructor(
        protected service: RacingService,
        protected notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected confirmationservice: ConfirmationService,
        private translate: TranslateService,
    ) {
        super(
            service,
            confirmationservice,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
        );
    }

    selectableColumn() {
        this.isSelectableColumn = true;
    }

    initFilters() {
    }

    ngOnInit() {

        this.user = this.authenticationService.user;
        this.loadFilters = true;
        this.visible = true;
        this.messages = [];

        this.paginationRequest = RestExt.firstPageRequest();
        this.paginationResult = {
            filteredEntities: 0,
            entities: [],
        } as Rest.Page<Rest.Racing>;
        this.filters = {};
        this.paginationRequest.filters = {};
        this.fetch();
        this.beforeCreate();
    }

    ngOnDestroy() {
        this.alive = false;
    }

    refresh(): void {
        const that = this;
        this.fetch().then(() => {
        });
    }

    /*applyFilters(event: LazyLoadEvent) {
      this.paginationRequest.filters = {};
      this.paginationRequest.pageSize = event.rows;
      for (const key in event.filters) {
         //It is a multiselect filter, and thus, the value is an array.
         if (event.filters[key].value.constructor === Array) {
            this.paginationRequest.filters[key] = event.filters[key].value;
         }
         else {
            //It is a single selection filter (input text, date,etc...) we have to create an array with the only value
            this.paginationRequest.filters[key] = [event.filters[key].value];
         }
      }
      this.paginationRequest.sortBy = event.sortField;
      this.paginationRequest.sortAsc = event.sortOrder == 1;
      this.paginationRequest.offset = event.first;
    }*/

    /*loadData(event: LazyLoadEvent) {
      this.applyFilters(event);
      this.fetch().then(() => {
         console.log('LOAD DATA');
         console.log(this.paginationResult);
      });
      this.fetchTypes();
    }*/

    arrayToString(array) {
        const nestedEntities = [];
        for (const a in array) {
            nestedEntities.push(array[a].name);
        }
        return nestedEntities.join(', ');
    }

    fetch() {
        //this.loading = true;
        // super temporal hasta que estén las funciones.

        this.loadingService.increaseRESTOngoingRequestCounter();
        const promise = new Promise<void>((resolve, reject) => {
            const that = this;
            //Load the filters and page if requested and the entity can be filtered by other entities
            let t = this.paginationRequest;

            /**
             * Convert all the dates from the user defined time zone to UTC
             */
            if (this.paginationRequest != null) {
                for (var f in this.paginationRequest.filters) {
                    for (var idx in this.paginationRequest.filters[f]) {
                        if (
                            (this.paginationRequest.filters[f][idx] as any) instanceof Date
                        ) {
                            (this.paginationRequest.filters[f] as any)[idx] =
                                this.userTime.calendarDateToUTC(
                                    this.paginationRequest.filters[f][idx],
                                );
                        }
                    }
                }
            }
            if (this.loadFilters && this.hasEntityFilters) {
                //If paginationRequest is null create a new one
                if (this.paginationRequest == null) {
                    this.paginationRequest = RestExt.firstPageRequest();
                }

                this.service
                    .getPageAndFilters(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response.page;
                            that.filters = response.filters;
                            that.loadFilters = false;
                            /**Checkbox colum selection */
                            for (var a in response.page.entities) {
                                that.entitiesSelected[response.page.entities[a]['id']] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            that.loading = false;
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },

                        function (error) {
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        resolve();
                    });
            } else {
                this.service
                    .getPage(this.paginationRequest)
                    .then(
                        function (response) {
                            that.paginationResult = response;
                            /**Checkbox colum selection */
                            for (var a in response.entities) {
                                that.entitiesSelected[response.entities[a]['id']] = false;
                            }
                            that.selectedAll = false;
                            /** */
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },
                        /* failure */

                        function (error) {
                            console.log('The request failed: ' + error);
                            that.loadingService.decreaseRESTOngoingRequestCounter();
                            resolve();
                        },
                    )
                    .catch(function (reason) {
                        console.log('Catched: ' + reason);
                        that.loadingService.decreaseRESTOngoingRequestCounter();
                        resolve();
                    });
            }
        });
        return promise;
    }

    beforeCreate() {
        //ponemos lo que necesitamos obtenemos los datos de la bbdd llamando a sus funciones correspondientes
        this.service.Racing_gettyperacing()
            .then((response) => {
                this.availableTypeEvent = response;
                /* Init form input variables */
            })
            .catch(() => {
                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.get.racingType')
                );
            });
        this.service.Racing_gettypecode()
            .then((response) => {
                this.availableTypeCode = response;
                /* Init form input variables */
            })
            .catch(() => {
                this.notificationsService.add(
                    Severity.error,
                    'Error',
                    this.translate.instant('error.get.codeType')
                );
            });

    }

    //RAQUELLL FALTA EL ABRIR  LA OTRA PANTALLA YA LO HAREMOS.

    protected handleError(err, that) {
        that.errors = {};
        if (typeof err === 'string') {
            try {
                err = JSON.parse(err);
                if ((err.class = 'AtlantisMessage')) {
                    this.notificationsService.add(
                        err.type == 'Warning' ? Severity.warn : Severity.error,
                        this.i18n._(':('),
                        err.message,
                    );
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    /**
     * createRacingEvent()
     * */
    public createRacingEvent(): void {
        this.selectedRacingEvent = null;
        this.racingForm.showDialog(true);
    }

    /**
     * editRacingEvent()
     * */
    public editRacingEvent(racingEvent: Rest.Racing): void {
        this.selectedRacingEvent = racingEvent;
        this.racingForm.showDialog(false);
    }

    /**
     * deleteRacingEvent()
     * */
    public deleteRacingEvent(racingEvent: Rest.Racing): void {
        let confirmationMessage = this.translate.instant('control-panel.delete-dialog.message') +
            ' ' +
            this.translate.instant('events.theEvent') +
            ': ' +
            `<strong>` + racingEvent.nameEvent + '</strong>?';

        this.confirmationservice.confirm({
            message: confirmationMessage,
            accept: () => {

                this.service.deleteRacing(racingEvent).then((response) => {
                    if (response) {
                        this.notificationsService.add(
                            Severity.success,
                            this.translate.instant('success.success'),
                            this.translate.instant('success.delete.event')
                        );
                        this.refresh();
                    }
                });

            }, 
        });
    }

    /**
     * addRacingEvent()
     * */
    public addRacingEvent(): void {
        this.refresh();
    }

    /**
     * saveRacingEvent()
     * */
    public saveRacingEvent(): void {
        this.refresh();
    }

}
