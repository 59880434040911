import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { FinancingService } from 'app/modules/vehicle-inventory/services/table-management/financing.service';
import { FinancingFormComponent } from '../financing-form/financing-form.component';
import { ExcelService } from "app/services/excel-service/excel.service";
import Financing = Rest.Financing;
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';

@Component({
  selector: 'app-financing-list',
  templateUrl: './financing-list.component.html',
  styleUrls: ['./financing-list.component.css'],
  providers: [ConfirmationService, UserTimePipe, ExcelService],
  encapsulation: ViewEncapsulation.None
})
export class FinancingListComponent
  extends EntityListComponent<Financing>
  implements OnInit {

  @Input() vehicle?: Rest.Vehicle = null;
  @Input() startDate?: Date = null;
  @Input() endDate?: Date = null;
  @Input() nested?: Boolean = false;

  @Input() showTitle: boolean = true;

  title: string = this.i18n._("Financing list");

  @ViewChild(FinancingFormComponent, { static: true })
  editForm: FinancingFormComponent;

  user: Rest.User;
  EnabledFilter: SelectItem[];

  refreshListSuscription: Subscription;

  // PERMISSIONS
  isCreateGranted: boolean = false;
  isFormGranted: boolean = false;
  isDisabledGranted: boolean = false;

  constructor(
    private financingService: FinancingService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    authenticationService: AuthenticationService,
    protected loadingService: LoadingService,
    protected userTime: UserTimePipe,
    protected excelService: ExcelService,
    protected route: ActivatedRoute,
    public translateService: TranslateService
  ) {
    super(
      financingService,
      confirmationService,
      notificationsService,
      i18n,
      authenticationService,
      loadingService,
      userTime,
      excelService
    );

    this.financingService = financingService;
  }

  ngOnInit(): void {
    // console.log("[FINANCING-LIST] ngOninit ***");
    super.ngOnInit();

    this.form = this.editForm;
    this.hasEntityFilters = false;
    this.loadFilters = false;

    this.refreshListSuscription = this.financingService.refreshList.subscribe(
      (item: RestVehicleInventory.InvVehicleHtcRequest) => {
        //   console.log(" ____ REFRESH FINANCING LIST _____");
        //   console.log(item.startDate);
        //   console.log(item.endDate);
        this.getList(item.startDate, item.endDate);
      }
    );

    this.setPermissions();
  }

  beforeLoad(): void {
    // console.log("[FINANCING-LIST] beforeLoad");
    // console.log(this.startDate);
    // console.log(this.endDate);
    this.paginationRequest.sortBy = "startDate";
    this.paginationRequest.sortAsc= false;
    if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
    if (this.startDate) this.paginationRequest.filters['startDate'] = [this.userTime.toUTCISOString(this.startDate)];
    if (this.endDate) this.paginationRequest.filters['endDate'] = [this.userTime.toUTCISOString(this.endDate)];
    if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
      this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
    }
    //  console.log(this.paginationRequest.filters);
  }

  setPermissions() {
    const user = this.authenticationService.user;
    const rolesForForm = ['INV_FINANCING_READ', 'INV_FINANCING_UPDATE'];

    this.isCreateGranted = this.authenticationService.isRoleGranted('INV_FINANCING_CREATE', user);
    this.isDisabledGranted = this.authenticationService.isRoleGranted('INV_FINANCING_DISABLE', user);
    this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
  }

  editDialog(event) {
    if (!this.isFormGranted) {
      return;
    }

    if (!this.isSelectableColumn) {
      this.onRowDblClick({ data: event });
    }

    this.isSelectableColumn = false;
    this.isColumn = true;
  }

  public getList(startDate: Date, endDate: Date) {
    if (startDate) this.startDate = startDate;
    if (endDate) this.endDate = endDate;
    this.beforeLoad();
    super.refresh();
  }

  afterLoad() {
    //  console.log('+++ entities', this.paginationResult.entities);

    this.checkSelectAll();
  }

  excelColumns = {};

  exportExcel() {
    this.setExcelColumns();
    let fileNameTitle = this.translateService.instant('tableManagement.financing.financing');
    super.exportExcelWithColumns(fileNameTitle, this.excelColumns);
  }

  setExcelColumns() {
    const type = (entity: any) => entity.financingType?.name ?? ''
    const startDate = (entity: any) => entity.startDate ?? ''
    const endDate = (entity: any) => entity.endDate ?? ''
    const cancellationtDate = (entity: any) => entity.cancellationDate ?? ''

    this.excelColumns = {
      [this.translateService.instant('general.name')]: { bindField: type },
      [this.translateService.instant('tableManagement.financing.start')]: { bindField: startDate },
      [this.translateService.instant('tableManagement.financing.end')]: { bindField: endDate },
      [this.translateService.instant('tableManagement.financing.cancelationDate')]: { bindField: cancellationtDate },
    }
  }

  duplicate(entities) {
    this.editForm.duplicate(entities[0]);
  }
}
