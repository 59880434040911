<p-accordionTab>  
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Input Delay Time Configuration ($ST+SINP)</span>
      </div>
    </div>
  </p-header>
      <div class="ui-g">                
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input1 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput1" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input2 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput2" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input3 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput3" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input4 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput4" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input5 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput5" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input6 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput6" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input7 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput7" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Input8 Delay" >
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="inputDelayTime.delayTimeInput8" [min]="0" [max]="255"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon" >(x100ms)</span>
            </div>
          </form-input>
        </div>                
      </div>
</p-accordionTab>
