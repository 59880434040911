<p-dialog header="POI's From File" [responsive]="true" showEffect="fade" [modal]="true"  [draggable]="true"
  [hidden]="false" [(visible)]="visible">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>

  <div class="ui-grid ui-grid-responsive ui-fluid">

    <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
 -->

    <!-- PrimeNG DataTable -->
    <p-table #dt [value]="LoadedPOIs"
             [rows]="10"
             [rowsPerPageOptions]="[10,20,30,40,50]"
             [paginator]="true"
             [rowHover]="true"
             [autoLayout]="true"
    >

      <!-- Table Title -->
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between text-center">POIs</div>
      </ng-template>

      <!-- Table Column/Header Definition -->
      <ng-template pTemplate="header">
        <tr>

          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Name</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Address</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Municipality</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Param. 1</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Param. 2</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Latitude</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Longitude</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Postal Code</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Province</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Phone Number</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Contact Person</div>
          </th>

          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Observations</div>
          </th>

        </tr>
      </ng-template>

      <!-- Table Row Definition -->
      <ng-template pTemplate="body" let-poi>
        <tr class="p-selectable-row">

          <td>
            <p-tableCheckbox [value]="poi"></p-tableCheckbox>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.name">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.name}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.address">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.address}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.municipality">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.municipality}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.param1">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.param1}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.param2">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.param2}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input type="number" step= "0.01" [min]="-90.00" [max]="90.00"  [(ngModel)]="poi.position.latitude" >
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.position.latitude}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input type="number" step= "0.01" [min]="-180.00" [max]="180.00"  [(ngModel)]="poi.position.longitude" >
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.position.longitude}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="poi.postalCode">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.postalCode}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.province">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.province}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="poi.phoneNumber">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.phoneNumber}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.contactPerson">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.contactPerson}}
              </ng-template>

            </p-cellEditor>
          </td>

          <td pEditableColumn>
            <p-cellEditor>

              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="poi.observations">
              </ng-template>

              <ng-template pTemplate="output">
                {{poi.observations}}
              </ng-template>

            </p-cellEditor>
          </td>

        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" >
        <tr>
          <td colspan="13">
            <p i18n>No records found</p>
          </td>
        </tr>
      </ng-template>

    </p-table>



    <!--<p-dataTable [value]="LoadedPOIs" [editable]="true" [rows]="10" [rowsPerPageOptions]="[10,20,30]"
      [paginator]="true" #dt>
    &lt;!&ndash; [(selection)]="selectedEntities" &ndash;&gt;

      <p-header>
        <ng-container i18n>POIs</ng-container>
      </p-header>

      <p-column [style]="{'width':'38px'}" selectionMode="multiple"></p-column>
      <p-column field="name" i18n-header header="Name" [editable]="true"></p-column>
      <p-column field="address" i18n-header header="address" [editable]="true"></p-column>
      <p-column field="municipality" i18n-header header="municipality" [editable]="true"></p-column>
      <p-column field="param1" i18n-header header="param1" [editable]="true"></p-column>
      <p-column field="param2" i18n-header header="param2" [editable]="true"></p-column>
      <p-column field="position.latitude" i18n-header header="latitude" [editable]="true">
        <ng-template let-col let-poi="rowData" pTemplate="editor">
          <input type="number" step= "0.01" [min]="-90.00" [max]="90.00"  [(ngModel)]="poi.position.latitude" >
      </ng-template>
      </p-column>
      <p-column field="position.longitude" i18n-header header="longitude" [editable]="true">
        <ng-template let-col let-poi="rowData" pTemplate="editor">
          <input type="number" step= "0.1" [min]="-180.00" [max]="180.00" [(ngModel)]="poi.position.longitude" >
      </ng-template>
      </p-column>
      <p-column field="postalCode" i18n-header header="postalCode" [editable]="true"></p-column>
      <p-column field="province" i18n-header header="province" [editable]="true"></p-column>
      <p-column field="phoneNumber" i18n-header header="phoneNumber" [editable]="true"></p-column>
      <p-column field="contactPerson" i18n-header header="contactPerson" [editable]="true"></p-column>
      <p-column field="observations" i18n-header header="observations" [editable]="true"></p-column>

    </p-dataTable>-->

    <div style="max-width: 100%; margin-top: 20px; margin-bottom: 20px;">
      <input #fileInput type="file" class="upload" (change)="changeListener($event.target.files)" accept=".csv">
    </div>


    <button type="button" pButton icon="fa fa-plus" style="float:left" (click)="createPOIs()" i18n-label label="Save"></button>

  </div>

</p-dialog>
