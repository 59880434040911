import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { LoadingService } from 'app/services/loading-service/loading.service';
import { MaintenanceexecutionService } from '../../rest/maintenanceexecution.service';
import { Rest } from '../../rest/rest_client';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { I18nService } from '../../services/i18n/i18n.service';
import { NotificationsService } from '../../services/notifications-service/notifications.service';
import { EntityListComponent } from '../entity-list/entity-list.component';
import { MaintenanceExecutionFormComponent } from '../maintenance-execution-form/maintenance-execution-form.component';
import { UserTimePipe } from '../shared/UserTimePipe';
import { MaintenanceService } from 'app/rest/maintenance.service';
import { MaintenanceplanService } from 'app/rest/maintenanceplan.service';
import { Subscription } from 'rxjs';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';

@Component({
	selector: 'app-maintenance-executions-list',
	templateUrl: './maintenance-executions-list.component.html',
	styleUrls: ['./maintenance-executions-list.component.css'],
	providers: [
		MaintenanceexecutionService,
		MaintenanceService,
		MaintenanceplanService,
		ConfirmationService,
		UserTimePipe,
	],
	encapsulation: ViewEncapsulation.None
})
export class MaintenanceExecutionsListComponent
	extends EntityListComponent<Rest.MaintenanceExecution>
	implements OnInit, OnDestroy {
	executions: Rest.MaintenanceExecution[];

	title: string;
	displayDialog: boolean;
	colspan: string;

	messageWarning: string;
	containsNestedEntities = [];

	//datos para compartir con formulario
	selectedExecution: Rest.MaintenanceExecution; //execution que queremos editar
	selectedMaintenance: Rest.Maintenance; //Maintenance padre del execution que queremos crear/editar

	@Input() parentMaintenance?: Rest.Maintenance; //Maintenance del execution (contiene lista de executions)
	@Input() vehicle: Rest.Vehicle;

	@Input() showTitle: boolean = true;

	@ViewChild('editForm') editForm: MaintenanceExecutionFormComponent;

	executionService: MaintenanceexecutionService;
	showStatusDialog = false;

	planList: Rest.MaintenancePlan[] = [];
	maintenanceList: Rest.Maintenance[] = [];
	statusList: String[] = [];

	refreshListSuscription: Subscription;

	/*Permissions */
	hasPermissions: boolean;

	isCreateGranted: boolean = false;
	isFormGranted: boolean = false;
	isDisabledGranted: boolean = false;

	/** */

	constructor(
		executionService: MaintenanceexecutionService,
		protected confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		protected i18n: I18nService,
		authenticationService: AuthenticationService,
		protected loadingService: LoadingService,
		protected userTime: UserTimePipe,
		private maintenanceplanService: MaintenanceplanService,
		private maintenanceService: MaintenanceService
	) {
		super(
			executionService,
			confirmationService,
			notificationsService,
			i18n,
			authenticationService,
			loadingService,
			userTime
		);
		this.executionService = executionService;
	}

	ngOnInit() {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] ngOnInit ***');
		// console.log('vehicle...');
		// console.log(this.vehicle);
		super.ngOnInit();
		this.fetchEntityBeforeShowUpdateForm = true;
		this.hasEntityFilters = true;
		this.colspan = '7';
		this.getMaintenanceList(this.vehicle.client.id);
		this.getMaintenancePlanList(this.vehicle.client.id);
		this.getStatusList();
		this.refreshListSuscription = this.executionService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				// console.log(' ____ REFRESH EXEC. LIST _____');
				this.getList();
			}
		);

		this.setPermissions();
	}

	onDestroy() {
		this.refreshListSuscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authenticationService.user;
		const rolesForForm = ['MAINTENANCE_EXECUTION_READ', 'MAINTENANCE_EXECUTION_UPDATE'];

		this.isCreateGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_CREATE', user);
		this.isDisabledGranted = this.authenticationService.isRoleGranted('MAINTENANCE_EXECUTION_DISABLE', user);
		this.isFormGranted = this.authenticationService.isOneRoleGranted(rolesForForm, user);
	}

	protected beforeLoad() {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] beforeLoad ***');
		this.paginationRequest.filters['vehicleID'] = [this.vehicle.id.toString()];
		this.paginationRequest.sortBy = 'nextMaintenanceDate';
		this.paginationRequest.sortAsc = false;
		console.log(this.paginationRequest);
	}

	afterLoad() {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] afterLoad ***');
		// console.log(this.paginationResult.entities);
		this.paginationResult.entities.forEach((me: any) => {
			/*Permissions */
			me._$changeStatus = this.hasPermissions;
			me._$delete = this.hasPermissions;
			me._$update = this.hasPermissions;
			/** */
		});
		/*Permissions */
		this.checkSelectAll();
		/** */
	}

	openDialog(isNew: boolean, maintenanceExecution: Rest.MaintenanceExecution) {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] editDialog ***');
		// console.log(isNew);
		// console.log(maintenanceExecution);

		if (!this.isFormGranted) {
			return;
		}
		
		this.editForm.showDialog(isNew, maintenanceExecution);
	}

	addExecution(maintenance: Rest.Maintenance) {
		this.create();
	}

	getMaintenancePlanList(clientId: number) {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] getMaintenancePlanList ***');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.maintenanceplanService
				.getList(clientId)
				.then(
					function (response) {
						// console.log('==> MaintenancePlanList... ');
						// console.log(response);
						that.planList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	getMaintenanceList(clientId: number) {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] getMaintenanceList ***');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.maintenanceService
				.getList(clientId)
				.then(
					function (response) {
						// console.log('==> MaintenanceList... ');
						// console.log(response);
						that.maintenanceList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	getStatusList() {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] getStatusList ***');
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.executionService
				.getStatusList()
				.then(
					function (response) {
						// console.log('==> StatusList... ');
						// console.log(response);
						that.statusList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}

	public getList() {
		// console.log('[MAINTENANCE-EXECUTIONS-LIST] getList ***');
		this.paginationRequest.filters = {};
		this.beforeLoad();
		super.refresh();
	}

}
