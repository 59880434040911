import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-analog-input-config-form",
  templateUrl: "./analog-input-config-form.component.html",
  styleUrls: ["./analog-input-config-form.component.css"],
})
export class AnalogInputConfigFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @ViewChild("analoginput", { static: true }) analoginput;
  analogInputSwitch: boolean;
  @Input() deviceParams: Rest.DeviceParameters;

  /**Analog Input Event */
  availableanalogInputModes: SelectItem[];

  availableanalogAction: SelectItem[];

  availableanalogOutputPort: SelectItem[];

  ngOnInit() {
    // Analog Input Fields
    this.availableanalogInputModes = RestExt.getEnumItems(
      RestExt.AnalogInputModes,
      this.i18n
    );
    this.availableanalogAction = RestExt.getEnumItems(
      RestExt.AnalogInputActions,
      this.i18n
    );
    this.availableanalogOutputPort = RestExt.getEnumItems(
      RestExt.AnalogInputOutputPorts,
      this.i18n
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;

    /**Analog Input Fields*/
    this.analogInputSwitch =
      this.deviceParams.proprietaryParameters.analogInputFunction.mode ==
        RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] ||
      this.deviceParams.proprietaryParameters.analogInputFunction.mode ==
        undefined;
    this.disableAnalogInputDIV(this.analogInputSwitch);
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt
      .AnalogInputModes[
      RestExt.AnalogInputModes
        .EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION
    ] as Rest.AnalogInputModes;
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    this.analogInputSwitch = !disable;

    if (disable) {
      this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt
        .AnalogInputModes[
        RestExt.AnalogInputModes.DISABLE
      ] as Rest.AnalogInputModes;
      this.analoginput.nativeElement.classList.add("disableDIV");
    } else {
      this.analoginput.nativeElement.classList.remove("disableDIV");
    }
  }

  analogInputModeChange(event) {
    this.analogInputSwitch =
      this.deviceParams.proprietaryParameters.analogInputFunction.mode ==
      RestExt.SpeedEventModes[RestExt.AnalogInputModes.DISABLE];
    this.disableAnalogInputDIV(this.analogInputSwitch);
  }

  analogInputOutputPortChange(event) {
    this.deviceParams.proprietaryParameters.analogInputFunction.outputControl =
      !(
        this.deviceParams.proprietaryParameters.analogInputFunction
          .outputPort ==
        RestExt.MileageConfigModes[RestExt.MileageConfigModes.DISABLE]
      );
  }

  analogInputOutputControlChange(event) {
    if (
      !this.deviceParams.proprietaryParameters.analogInputFunction.outputControl
    ) {
      this.deviceParams.proprietaryParameters.analogInputFunction.outputPort =
        RestExt.AnalogInputOutputPorts[
          RestExt.AnalogInputOutputPorts.DISABLE
        ] as Rest.AnalogInputOutputPorts;
    } else {
      this.deviceParams.proprietaryParameters.analogInputFunction.outputPort =
        RestExt.AnalogInputOutputPorts[
          RestExt.AnalogInputOutputPorts.OUTPUT_1
        ] as Rest.AnalogInputOutputPorts;
    }
  }
}
