import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InventoryReportsService, PhaseChangeEvent } from 'app/modules/vehicle-inventory/services/inventory-reports.service';
import { Rest } from 'app/rest/rest_client';

import InventoryReport = Rest.InventoryReport

@Component({
  selector: 'reports-body',
  templateUrl: './reports-body.component.html',
  styleUrls: ['./reports-body.component.css'],
  providers: [InventoryReportsService],
  encapsulation: ViewEncapsulation.None
})
export class ReportsBodyComponent implements OnInit {
  @Output() phaseChange = new EventEmitter<PhaseChangeEvent>();
  svgImageSafeUrl: SafeUrl;

  inventoryReports: InventoryReport[] = [];

  constructor(
    private inventoryReportsService: InventoryReportsService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.inventoryReportsService.get().then(
      (reports) => {
        this.inventoryReports = reports;
        console.log('Inventory reports:', reports);
      },
      (error) => {
        console.error('Error fetching inventory reports', error);
      }
    );
  }

  onSelectPhase(report: InventoryReport): void {
    console.log('Selected report:', report.reportType);

    this.phaseChange.emit({
      phase: 2,
      reportData: report
    });
  }

}

