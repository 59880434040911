<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\change-status-dialog\change-status-dialog.component.ts
-->
<p i18n="@@cf66a918c046ec07c72d608f0f261d23">Change Status Confirmation</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\billing-account-form\billing-account-form.component.ts
-->
<p i18n="@@c6866844b15b24c42837f62d600d7cb7">Create Billing Account</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\billing-account-form\billing-account-form.component.ts
-->
<p i18n="@@f41616ad747c5521a8f81263a90585f3">Edit Billing Account</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\billing-accounts-list\billing-accounts-list.component.ts
-->
<p i18n="@@cdc72ff495ca5f32f79f98af0d925fc7">Billing Accounts</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\client-form\client-form.component.ts
-->
<p i18n="@@4b30a9a8daca2dba2cd37bcde3224a89">Create Client</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\client-form\client-form.component.ts
-->
<p i18n="@@026c410d726ddc04ea3500b3afb819ce">Edit Client</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\clients\clients-list\clients-list.component.ts
-->
<p i18n="@@0f558243fbf45f1cd840fff01957f57b">Clients</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\conversion-algorithm\conversion-algorithm-form\conversion-algorithm-form-component.ts
-->
<p i18n="@@1512e7b328028d056734e8a083702294">Create Conversion Algorithm</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\conversion-algorithm\conversion-algorithm-form\conversion-algorithm-form-component.ts
-->
<p i18n="@@d064fc9fb1d42b3f433a9871c4d4cd86">Edit Conversion Algorithm</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\conversion-algorithm\conversion-algorithm-list\conversion-algorithm-list.component.ts
-->
<p i18n="@@22945a7ea99796f52bf9dca97184a336">Conversion Algorithms</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\command-terminal\command-terminal.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\command-terminal\command-terminal.component.ts
-->
<p i18n="@@208197d4fa60ae11d0bf81a8d5d64a4e">Error sending request</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\device-form\device-form.component.ts
-->
<p i18n="@@8d14baf002c5ae75be0460f72ef286ed">Create Device</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\device-form\device-form.component.ts
-->
<p i18n="@@7af6089460a015e008c46e562bb9201f">Edit Device</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\devices-list\devices-list.component.ts
-->
<p i18n="@@c03ca67dda321195d74c951097f240c6">Devices</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\devices-list\devices-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\devices-list\devices-list.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\devices-list\devices-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\devices-list\devices-list.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\requests-log\requests-log.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\requests-log\requests-log.component.ts
-->
<p i18n="@@61c965f4288ab28f56a1c865b99e0d65">Request canceled</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\requests-log\requests-log.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-edit-view\requests-log\requests-log.component.ts
-->
<p i18n="@@f06f121bf362204876a2c2605e440da1">Error cancelling request</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-parameters\sensor-form\sensor-form.component.ts
-->
<p i18n="@@c43518b4bddf9fb3e97b3edced011c53">Create Sensor</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-parameters\sensor-form\sensor-form.component.ts
-->
<p i18n="@@6efb54cb7fe75c086aca8d9a39671dac">Edit Sensor</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@2b97e10ff1e0fe5d21637afe412af260">Entity created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-type-edit-view.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\device-type-edit-view\device-types-list\device-types-list.component.ts
-->
<p i18n="@@0926ff05a58729ce6a615d345238c719">Add PreConfig </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@6fb4a939a53ec8d39a3f26117b31a3be">Are you sure you want to delete these entities?</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@782c836c7bad0438fbf6692322b377bc">Entities deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@782c836c7bad0438fbf6692322b377bc">Entities deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@f8b235cb17b4a4ccf53d61e19d6d39e8">Entities status changed</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\entity-list\entity-list.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-form\fleet-form.component.ts
-->
<p i18n="@@5ce598123c3f05da906a58b27c69729e">Fleets</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-form\fleet-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-form\fleet-form.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree\fleet-tree.component.ts
-->
<p i18n="@@bbf016b376a8513e2402c3056967bc68">Add Fleet</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree\fleet-tree.component.ts
-->
<p i18n="@@b112803ab9deb7deba2a6b24ef3bb91a">Add Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree\fleet-tree.component.ts
-->
<p i18n="@@84327c13f45e0fab33ebd64a31ceb4a5">Add Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree-edit\fleet-tree-edit.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree-edit\fleet-tree-edit.component.ts
-->
<p i18n="@@f8b235cb17b4a4ccf53d61e19d6d39e8">Entities status changed</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\fleet-tree-edit\fleet-tree-edit.component.ts
-->
<p i18n="@@07214c6750d983a32e0a33da225c4efd">entities</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@2287bb501f0e2bb186948cb386e6d51d">Vehicle Groups</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\group-form\group-form.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@d7a3e7d73fdfba9654a12335ea991e94">Please, select a fleet</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@73102ba56895d964448554452fb14b10">Please, select a client</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@085983ebca4334dfa61efc5dcf5b683b">Entities updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@f63ca53bbc70b2d02e57a9bdf3bd444c">Are you sure you want to delete this vehicle?</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\fleet-edit-view\vehicle-form\vehicle-form.component.ts
-->
<p i18n="@@782c836c7bad0438fbf6692322b377bc">Entities deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\form-input\form-input.component.ts
-->
<p i18n="@@09737938747269600702609ab170f2f5">Required Field</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@9d1a0949c39e66a0cd65240bc0ac9177">Sunday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@6f8522e0610541f1ef215a22ffa66ff6">Monday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@5792315f09a5d54fb7e3d066672b507f">Tuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@796c163589f295373e171842f37265d5">Wednesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@0f1143729b7fb15244716cfcfafd35bb">Thuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@c33b138a163847cdb6caeeb7c9a126b4">Friday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@8b7051187b9191cdcdae6ed5a10e5adc">Saturday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@86f5978d9b80124f509bdb71786e929e">January</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@659e59f062c75f81259d22786d6c44aa">February</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@fa3e5edac607a88d8fd7ecb9d6d67424">March</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@3fcf026bbfffb63fb24b8de9d0446949">April</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@195fbb57ffe7449796d23466085ce6d8">May</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@688937ccaf2a2b0c45a1c9bbba09698d">June</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1b539f6f34e8503c97f6d3421346b63c">July</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@41ba70891fb6f39327d8ccb9b1dafb84">August</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@cc5d90569e1c8313c2b1c2aab1401174">September</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@eca60ae8611369fe28a02e2ab8c5d12e">October</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@7e823b37564da492ca1629b4732289a8">November</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@82331503174acbae012b2004f6431fa5">December</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1dd1c5fb7f25cd41b291d43a89e3aefd">Today</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@dc30bc0c7914db5918da4263fce93ad2">Clear</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@85367c3faf74b058939654bdef8968d7">DAYS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f632ac1a3f5ea93d8001efda8531eefa">MONTHS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@c6407e1804447362468229fd4a430f9c">YEARS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@aa85f1840e282d8a8304dbc2c0d7c9b2">EN</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@04c19fa1e772ab66f0aad2efe61f25cd">ES</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@11aedd0e432747c2bcd97b82808d24a0">FR</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@00d23a76e43b46dae9ec7aa9dcbebb32">Enabled</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b9f5c797ebbf55adccdd8539a65a0241">Disabled</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@055c1a591abb0e8cd86dc969727bcc0b">DISABLED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@3a769a916836dccf98801212615595d3">MODE_0</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f7ede748a6b41c7c91c18ed2d155b33e">MODE_1</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@cac4185dc8a44d8ae07bfb94c2830017">MODE_2</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@8e8e661e23c6e60b35dfa6115b344ede">GPS_FIXED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2974ea792af7bb10d37f39c4fa2e0476">ANY_GPS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@4470f9d20853b9ec7d5a586e1dae8082">ACC_ON_AND_GPS_FIXED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@3fdae4cb228dedc261b988943e6ae255">ACC_ON_AND_ANY_GPS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@8e1bde3c3d303163521522cf1d62f21f">ANY</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@90651ebea9a35ec4e018c8157492e17c">ON</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@88559a0cfd8250c9d65970cc145c92d4">OFF</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@72700b6ac14b90435377dcbaeb77e908">SENSOR</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e4ad238c25dd7d39e2831428adaf7332">ACTUATOR</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@88559a0cfd8250c9d65970cc145c92d4">OFF</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@346ff32eaa3c09983fb2ec057816d352">TIME</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b06c2037eb7a58030a42212c8244d477">DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b74e5b1420e7839e28e2b1ab7194de4a">TIME_AND_DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@14703e05786764da4bb5bee4770ea80b">TIME_OR_DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@cc65dd7b535ac7fc2363aeb572cc2a8e">HEADING</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@16c9c35bceedbd13e0859a283382dd20">HEADING_OR_TIME</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@33a5bed59eea06d0ef459d559aa7d0fa">HEADING_OR_DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2f87bdf69d4c284bafab21610b4e3710">HEADING_OR_TIME_AND_DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@639685d3627da1a04dc7977e286aa035">HEADING_OR_TIME_OR_DISTANCE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@fc93ea58f6d27ffed2b6518ffecf3e4e">DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@042bca9f4ffd4cb8530fa2a9035e00c3">LOGGING</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f6f593da1de6b5bb23fc2ef3e637c72b">POLLING</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1fd90c947e8c148b48814c8004b376ff">LOGGING_AND_POLLING</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@18ff74f43da410c5529f7d6fca84f115">ACTIVE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@c69f06e1a9b016d133907b4e5f5864d2">PENDING</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@043754bb9e6124d2e71679f56f498357">UNINSTALLED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@ec01f94a1865e66adc4a3cd6fc5f7867">INTERNATIONAL_SYSTEM_OF_UNIT</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@8d8fc42ef14cef1a5d10dc762f2dbf6b">IMPERIAL_SYSTEM</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@fc93ea58f6d27ffed2b6518ffecf3e4e">DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@937b4cae58aaf7ef96a42b85063b8056">EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@402bbf98248b3b965adc90b22ac7c671">EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_OT_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@fdd37345f05e0b52388ce7eeb003c480">OUTPUT_1</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b958003390dfaa83c6b2e8882d356f23">OUTPUT_2</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@615813f1b2d1dd2d842aab15e34105a9">OUTPUT_3</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e6732084813d7344cd448ab2abbf7116">OUTPUT_4</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@3fab00df2571006e1fb89a33b86b6538">MILEAGE_WILL_BE_ACCUMULATED_REGARDLESS_THE_ACC_STATUS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2334b913d63880238b7ecef44062f231">MILEAGE_WILL_BE_ACCUMULATED_ONLY_IF_THE_ACC_IS_ON</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@aad9f04d7801bab15a95661b36cbcdca">ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@381f5c4281791330848b2b48aeadcd38">ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@7b13ca71d545e3fb822b4c90a876bb83">TIME_BASED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e8cb3d61678b604704e553226c7a84d5">DISTANCE_BASED</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@6d803302c669b84818dfd9d88534e1d2">DIGITAL</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@8b7d08163aa92ed2a8aceb06b9488e60">ANALOG</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b89201d268c92e66f6a042942a501b42">CANBUS</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@0420dcd0a9b3fc40a86ae7e7b530bcbf">DRIVER</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@17799f0e309729d38073256eb142afae">VEHICLES_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@57a998bc914e92e4db26e2354e694de9">VEHICLES_CREATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e8f71d546b7d4b249e7097a05d6a6fd3">VEHICLES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@b6af9e4cdbfbfe6287de47bd904a31fd">VEHICLES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@d36c2382049f88bcf6453ebc5c6d8987">MAINTENANCE_CREATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@0996563fb503a1357d6360c949454953">MAINTENANCE_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f4c7ad3d6b97d3207e27ea4dcd4b70e6">MAINTENANCE_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@ea810969dc9ccf12c24b18c65f3b908b">MAINTENANCE_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@ddb5b2b39f1310e57d8f1264644ad6c3">MAINTENANCE_PLANS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e85af1ccf476c9c6ba3203356bb43efa">MAINTENANCE_PLANS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@6a405900f7eabd1b3d53e6b7bf6106cb">MAINTENANCE_PLANS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@a784f6dccbcfe20e9d546d343b0b50bb">MAINTENANCE_EXECUTE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1de9fd2c83c8f38d8934aa1f6f2534e1">ZONES_CREATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@af1bcee08f24961976c3bc4ae9c50282">ZONES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@9fb73153681f50b9afd233d750a3d91e">ZONES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@c556ae40f1f182876b5d3315ce3637f2">ZONES_GROUPS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@27a85e03d59702c02189a3673a2ac2dd">ZONES_GROUPS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@64efdd7b0fa3dfa8c66911680890bdd0">ZONES_GROUPS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@10b55432696b0ab1ea921c3f503b6231">POIS_CATEGORIES_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@6b47a5f1546df8d9a84886cf8e295067">POIS_CATEGORIES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f350f90a371610f1adb82c0c17e85955">POIS_CATEGORIES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@abd2fbd34a5c84a113a2fe3cfa5a9ce3">POIS_CREATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1f718bf780bf047882a5a3b702e7c2ca">POIS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@66b692d2ec3f4d62b13a8e336b35feb2">POIS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@75ca03c1956d42b46f076ca66a963fcc">POIS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@667709ee2dfcec26845222952b36e6fa">NOTIFICATION_GROUPS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@f8f542f92f3bb7ea21e72243cd615ff7">NOTIFICATION_GROUPS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@773b73da5b10d27da45e88a6ca47f070">VEHICLES_GROUPS_CREATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@cfc3ae81dd364a7f510756063361e61e">VEHICLES_GROUPS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@70d9ea1a20bf083d1fd71d84dc925de3">VEHICLES_GROUPS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e16309f39b56f68892b590203dc8ae46">VEHICLES_GROUPS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2046604c80d9c6d703b910ff45a91696">FLEETS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@884d972aef6e97ad69e8595fe027fd4a">FLEETS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@e619557ff27335080d3c21d3d62cf1a5">FLEETS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2c82f9228ddfa8008394f694a3bfadde">CLIENTS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@721ae6ecd17070acfc68091aae125ec7">DEVICES_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@41781496b219572d851a8b7db52898ec">DEVICES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@bb9e9e3267eec6746501896394e1b998">DEVICES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@baf631f480e79480aad0d2e10fb8adc3">PROFILES_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@2d43c9233781a4e3907d4020e84098e6">PROFILES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@1b0178d2055eabb3c44c315f48e94e93">PROFILES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@90cae3d7314b50ed1abf302906e1d3e3">ROLES_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@3c6a25ddff7dd7ba67ceacaa1beb22c2">ROLES_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@470e15a241c4989daa43797c78476529">ROLES_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@d796fa75a324ee49ebe729b78e6ae30a">USERS_READ</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@5eb8d687db42237fff66c6a554cd7a5f">USERS_UPDATE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\hack-translations\hack-translations.component.ts
-->
<p i18n="@@6ffd1cbe2e708dd37ff632ad715135fa">USERS_DISABLE</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-execution-form\maintenance-execution-form.component.ts
-->
<p i18n="@@60234c2fcda38e062933426af61a8459">Edit Maintenance Execution</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-execution-form\maintenance-execution-form.component.ts
-->
<p i18n="@@a7ebd6392ba17a9950a5dd11c3755540">Create Maintenance Execution</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-executions-list\maintenance-executions-list.component.ts
-->
<p i18n="@@3557daaffc170265e8beb2e528230955">Maintenance Executions</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-form\maintenance-form.component.ts
-->
<p i18n="@@744596e4591b540c31fc1cdcee13d45c">Create Maintenance Plan</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-form\maintenance-form.component.ts
-->
<p i18n="@@67fc4f73435198706915f615e6df2be4">Edit Mantenance Plan</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-plan-form\maintenance-plan-form.component.ts
-->
<p i18n="@@1a87ecb1dac90a4edbaf97d7c08a9607">Create Mantenance Plan</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-plan-form\maintenance-plan-form.component.ts
-->
<p i18n="@@67fc4f73435198706915f615e6df2be4">Edit Mantenance Plan</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenance-plans-list\maintenance-plans-list.component.ts
-->
<p i18n="@@d63770abc88256d8c5d8a8c7c5191343">Maintenance Plans</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@6fb4a939a53ec8d39a3f26117b31a3be">Are you sure you want to delete these entities?</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@782c836c7bad0438fbf6692322b377bc">Entities deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@10d0de28911c5f66463b9c8783f8148a">Maintenance</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@6bfda37d48cb08101032bc159d08e159">Execution saved</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-list\maintenances-list.component.ts
-->
<p i18n="@@5b6afab91d464a3da8e2744d3ea5d0eb">Execution updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\maintenances-view-list\maintenances-view-list.component.ts
-->
<p i18n="@@b77dbba97dfa741a25e96dc90958930e">Maintenances</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@f9aae5fda8d810a29f12d1e61b4ab25f">Users</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@a5cd3ed116608dac017f14c046ea56bf">Roles</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@d0f25115288c15321ecf672f0d6a83ea">Profiles</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@8aaaedc342a0dce1d73d33f3fb69a2af">Access Control</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@ea491d32f8fb82481bb1e8e01922b7e0">Device management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@c03ca67dda321195d74c951097f240c6">Devices</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@0a871d23f4d35719329afd286c93ff53">Device Types</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@22945a7ea99796f52bf9dca97184a336">Conversion Algorithms</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@d63770abc88256d8c5d8a8c7c5191343">Maintenance Plans</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@83467144e8aa33bf67ff729d1540fa81">Vehicles</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@ba3fe8d3c601a27a8c1e41e3a34b9808">Vehicle management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@eb06fbff4c4dfe5b3370019994f43743">Zone management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@d373deb4cc6d7183bc0ce753be5a8f0e">Zone Groups</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@4cbbfc418b8bc90cc7cad6b6394026ce">POIs management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@9165b70a2939d46f9a0389719bd9e8f9">POIs Categories</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@1a9011e2a75719eacce57df4c041a0b5">Notice Groups</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@1f4da964f8eab62e96e8cfe406e44364">Rules</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@910b9d90c181858d263c037a39ea55da">Notice management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@0f558243fbf45f1cd840fff01957f57b">Clients</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@f144bdc2ab9ea34b7ca238dcd6d23b11">User: </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@f4f70727dc34561dfde1a3c529b6205c">Settings</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@15d591e35aad74f573aa26d48757956a">Sign-out</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@37a502907cd750af8c65517a75d00030">Real Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@529d3c1794f8aa2ed9493259e3a9923f">UTE Panel</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@c91c7b93c28cd18741b71f727ee81ee3">Reports</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@684e1388a91900480c702ac5b8f6b4d8">SAT</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@fe4dbcab9b910577e5035e97ac068dae">Management</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\menu\menu.component.ts
-->
<p i18n="@@0ba8adc53c16a6ffe145978e834b02f8">Dashboards</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\notice-group\notice-group-form\notice-group-form.component.ts
-->
<p i18n="@@efcbbd5ac94cf152a2f0faad5d45cb31">Create Notice Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\notice-group\notice-group-form\notice-group-form.component.ts
-->
<p i18n="@@854f9d588433fc2aa116eb2b52d52d49">Edit Notice Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\notice-group\notice-groups-list\notice-groups-list.component.ts
-->
<p i18n="@@ed65dc1dfb2e983dba586a7c71c34779">Notice Groups </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@2dd864c678acad598aceb62e7d3cb3be">The file contains empty required fields</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@a9ea507c722f214742557fda56457319">Entities created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-file-form\poi-categories-file-form.component.ts
-->
<p i18n="@@6609dd239e143d00002123326c5e4aad">Something went wrong</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-list\poi-categories-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-categories-list\poi-categories-list.component.ts
-->
<p i18n="@@3fee178bd17f7e3114ed3e1907a095c7">POI position updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-category-form\poi-category-form.component.ts
-->
<p i18n="@@f60c5c666da6766a7c2489d9e5042ea2">Edit POI Category</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-category-form\poi-category-form.component.ts
-->
<p i18n="@@263ece87eee6cc5ffed53c8f672d2352">Create POI Category</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-form\poi-form.component.ts
-->
<p i18n="@@d9027a18d3e395cc915f7e6a06499e6c">Edit POI</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-form\poi-form.component.ts
-->
<p i18n="@@4ed99769b1572f8a974b62019271a20a">Create POI</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\pois\poi-list\poi-list.component.ts
-->
<p i18n="@@b42594ba21b94d3eeb26f538b60ddaf6">POIs</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\profile-form\profile-form.component.ts
-->
<p i18n="@@159ee966ac2343b9452a38397c3291bc">Create Profile</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\profile-form\profile-form.component.ts
-->
<p i18n="@@1860fb17b107cbb39cf6bbfa61130803">Edit Profile</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\base\rt-base.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\connection-watch\connection-watch.component.ts
-->
<p i18n="@@2ec0d16e4ca169baedb9b2d50ec5c6d7">Connected</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\connection-watch\connection-watch.component.ts
-->
<p i18n="@@e321c53b354930ba96f0243e652df458">Connecting</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\connection-watch\connection-watch.component.ts
-->
<p i18n="@@be5de9c8a4bbf0e9d57eace948cb1f95">Not connected. Next retry: ~</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\connection-watch\connection-watch.component.ts
-->
<p i18n="@@a7fcb7903a061a3d92c715a0cdc8239a">Not connected. Next retry: </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@eec1410b000840f1b6f843da424d1f40">ORDEN</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@6a3917288b438e291fc44bb2a8309cae">FLOTA</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@0e1acd306ccdfc56c24491739cd9be66">GRUPO</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@290427a7725d5e942af4a1a8f82bd691">DESCRIPCI�N</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@9c5b78cdd7faa4dc117aa606a1b0d342">DISTANCIA</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@cc2b77e9562f40774c45f7673300b537">TIEMPO</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\map-menu\vehicle-proximity-dialog\vehicle-proximity-dialog.ts
-->
<p i18n="@@5992d7a70c29fa5682a045017eaeb72b">INFORMACION</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@830a83152cb0c5e9e4fd59ac0e0c6c0c">Search Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@342793ec986ba0191a91916e6968ec95">Print Mapa</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@962a4c03ca790e4682304039c71e79d4">Create Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@250a2921239a509938b6bf8361427e2d">Zone created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\rt-map\rt-map.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\side-panel.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\sensor-chart\sensor-chart.component.ts
-->
<p i18n="@@397ae2f1915691d54607441f899e100c">Categorical</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\side-panel\sensor-chart\sensor-chart.component.ts
-->
<p i18n="@@5225763efc97aebd39e5a303fceb57ac">Numerical</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@66b21bfa2215e4edc26a58366be9081c">Entity updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\vehicle-panel.component.ts
-->
<p i18n="@@bf3b278958d5a477eb5a040c1e51ca54">Entity deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\call-back\call-back.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\call-back\call-back.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\call-back\call-back.component.ts
-->
<p i18n="@@9059510e7057a763a5ecfb48ac827a97">:|</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\call-back\call-back.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\call-back\call-back.component.ts
-->
<p i18n="@@6c09bd93c8e2fa81b1a8d583f7acf028">There was a problem ququing the command</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@83a5bf44db8dda2531beb680b5448dda">Actuator change request quued.</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@9c23eb6830423dfa48232c326fda8543">Actuator change request acknowledged by device!</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@9059510e7057a763a5ecfb48ac827a97">:|</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\io\io.component.ts
-->
<p i18n="@@8f9c9ca661cff26251bf92666dae2676">Actuator change request not queued</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\quick-actions\quick-actions.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\quick-actions\quick-actions.component.ts
-->
<p i18n="@@33d8acdf3fbade4acc4e79dc71909689">Get location command queued. The message will be sent to the device as soon as possible</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\quick-actions\quick-actions.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\quick-actions\quick-actions.component.ts
-->
<p i18n="@@19c3cb18a4aa1e5ab81db9b301247881">Unable to queue get location command.</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@9c23eb6830423dfa48232c326fda8543">Actuator change request acknowledged by device!</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@09eaadde0426fbbcedbb9bc87a328ba7">Actuator change request not acknowledged by device!</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@3f34bdfb5e961350b931336b087c2cb9">SMS queued. Waiting for message to be sent</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\sms\sms.component.ts
-->
<p i18n="@@da7a1951acbfafdfb8f62d6b98f47e9d">Unable to request an actuator change</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\telemetry\vehicle-telemetry.component.ts
-->
<p i18n="@@d6318882bab2fc0fc14f02b828e425f9">Too much data</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\real-time\vehicle-panel\views\telemetry\vehicle-telemetry.component.ts
-->
<p i18n="@@e0ece70f7d8bc8d0edf7b6a3fd0272e0">A lot of data</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\reports\report-form\report-form.component.ts
-->
<p i18n="@@623f0149e2f964975e51e15fbfae1b8d">VEHICLE_ACTIVITY_REPORT</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\reports\report-list\report-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\reports\report-list\report-list.component.ts
-->
<p i18n="@@2b512f54428b03ce9377c0dc0548b605">Report request created. The result will be available soon</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\reports\report-list\report-list.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\roles\role-form\role-form.component.ts
-->
<p i18n="@@670610147aff3f08b58a6c53cb5936dd">Create Role</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\roles\role-form\role-form.component.ts
-->
<p i18n="@@7158415ddd8d364c23ff16c1c0e46338">Edit Role</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\rule\rule-form\rule-form-component.ts
-->
<p i18n="@@3afa6e6d9bfdae35a4f33bb2e5cd3fb8">Create Rule</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\rule\rule-form\rule-form-component.ts
-->
<p i18n="@@0a4405bf4be3f74bf4e02b0b59496114">Edit Rule</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\rule\rule-list\rule-list.component.ts
-->
<p i18n="@@1f4da964f8eab62e96e8cfe406e44364">Rules</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\server-validated-form\server-validated-form.component.ts
-->
<p i18n="@@32b206d81650dabf6f8d9e766a8d92c0">Form has errors</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@f31e34009109f09221d5a1606b7024f7">Entitie created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@d4e02b1a2c13547deccbddea067b61f5">Entitie updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@6fb4a939a53ec8d39a3f26117b31a3be">Are you sure you want to delete these entities?</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\translation-list\translation-list.component.ts
-->
<p i18n="@@782c836c7bad0438fbf6692322b377bc">Entities deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-form\user-form.component.ts
-->
<p i18n="@@9afd8946aa9310740f59b94e310cc74c">UTE Rondes</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-form\user-form.component.ts
-->
<p i18n="@@37a502907cd750af8c65517a75d00030">Real Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-form\user-form.component.ts
-->
<p i18n="@@266a3018a06011c0ad8d695b61ca6a61">Create User</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-form\user-form.component.ts
-->
<p i18n="@@65d7cc265608a3f28d479f7cb8e742ec">Edit User</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-settings\user-settings.component.ts
-->
<p i18n="@@c888438d14855d7d96a2724ee9c306bd">Settings updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-settings\user-settings.component.ts
-->
<p i18n="@@c888438d14855d7d96a2724ee9c306bd">Settings updated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-settings\user-settings.component.ts
-->
<p i18n="@@2dd6bece9c0b0bcf7004a3a5c7e50ea3">You have succesfully updated your settings</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-settings\user-settings.component.ts
-->
<p i18n="@@9afd8946aa9310740f59b94e310cc74c">UTE Rondes</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\users\user-settings\user-settings.component.ts
-->
<p i18n="@@37a502907cd750af8c65517a75d00030">Real Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\poc-visit-form\poc-visit-form.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\poc-visit-form\poc-visit-form.component.ts
-->
<p i18n="@@027a56cf11f6f871320dac9ed5c47968">Pleade fill all the inputs before save</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\poc-visit-form\poc-visit-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\poc-visit-form\poc-visit-form.component.ts
-->
<p i18n="@@4f8bc8dff741273ca7c8ae63f8fb9e79">:(</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-control-point-history\ute-control-point-history.ts
-->
<p i18n="@@44749712dbec183e983dcd78a7736c41">Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-control-point-history\ute-control-point-history.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-control-point-history\ute-control-point-history.ts
-->
<p i18n="@@03937134cedab9078be39a77ee3a48a0">Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-control-point-history\ute-control-point-history.ts
-->
<p i18n="@@77d9c873f1cf2fcf781aaf41492fd046">Control Point History: </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@0e91b677f146005ba04546001f22cdf7">Control Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@017608d685dccfcd8f584c94017c54f0">C Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@5ac4f6c2be5ed1d5f4c7b96d0719af4e">Last Maintenance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@590aaa67f792cefbb4d6fccaa4cd7f96">M Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@22fe869f4fc51cc31a84251776b46450">Maintenance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@5a80e1c8588da37d9b7291615b65bebf">M Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@a9b283898696b18b9a45d37c4a6675a0">Last Vigilance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@6f54658579cf897d61164837ac4fe569">V Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@8d013c4dbb9e83f4f314ffed09f72a25">Vigilance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@1f70166081c5e9c6122369010b820868">V Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@4729e1d6d348e66442f917044f2db504">Minor Time Remaining</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@a8138427a271f88198663f4915587756">T Remaining</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@0e91b677f146005ba04546001f22cdf7">Control Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@5ac4f6c2be5ed1d5f4c7b96d0719af4e">Last Maintenance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@22fe869f4fc51cc31a84251776b46450">Maintenance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@a9b283898696b18b9a45d37c4a6675a0">Last Vigilance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@8d013c4dbb9e83f4f314ffed09f72a25">Vigilance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@4729e1d6d348e66442f917044f2db504">Minor Time Remaining</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-panel-table\ute-panel-table.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@0e91b677f146005ba04546001f22cdf7">Control Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@017608d685dccfcd8f584c94017c54f0">C Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@a9b283898696b18b9a45d37c4a6675a0">Last Vigilance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@6f54658579cf897d61164837ac4fe569">V Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@5ac4f6c2be5ed1d5f4c7b96d0719af4e">Last Maintenance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@590aaa67f792cefbb4d6fccaa4cd7f96">M Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-report-events\ute-report-events.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@0e91b677f146005ba04546001f22cdf7">Control Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@017608d685dccfcd8f584c94017c54f0">C Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@5ac4f6c2be5ed1d5f4c7b96d0719af4e">Last Maintenance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@590aaa67f792cefbb4d6fccaa4cd7f96">M Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@22fe869f4fc51cc31a84251776b46450">Maintenance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@5a80e1c8588da37d9b7291615b65bebf">M Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@a9b283898696b18b9a45d37c4a6675a0">Last Vigilance Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@6f54658579cf897d61164837ac4fe569">V Last Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@8d013c4dbb9e83f4f314ffed09f72a25">Vigilance Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@1f70166081c5e9c6122369010b820868">V Left Time</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@4729e1d6d348e66442f917044f2db504">Minor Time Remaining</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@a8138427a271f88198663f4915587756">T Remaining</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-summary-table\ute-summary-table.component.ts
-->
<p i18n="@@d6af9c1eaff2a89ebd3f8d0c542be12b">Vehicle</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-vehicle-history\ute-vehicle-history.ts
-->
<p i18n="@@44749712dbec183e983dcd78a7736c41">Date</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-vehicle-history\ute-vehicle-history.ts
-->
<p i18n="@@0e91b677f146005ba04546001f22cdf7">Control Point</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\ute-vehicle-history\ute-vehicle-history.ts
-->
<p i18n="@@d3136d4f6772c5b8057e09aa602bb6cd">Vehicle History: </p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@cf3fb1ff52ea1eed3347ac5401ee7f0c">Ascending</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@e3cf5ac19407b1a62c6fccaff675a53b">Descending</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@ad647e4ae904c7ed54ee93040d8298c7">Less than</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f82fbd90112505203d3325ced32de305">Bigger than</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@46d7f5e8da4dfdab6b8f4439ec27f291">New Configuration</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f2a6c498fb90ee345d997f888fce3b18">Delete</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@d46ec53fbd6fe7046e7010a7d1a44e6f">The configuration was deleted</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@c9cc8cce247e49bae79f15173ce97354">Save</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@146a3bd67c34f7ad0f94c1935ca67485">The configuration was saved</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@c9cc8cce247e49bae79f15173ce97354">Save</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@146a3bd67c34f7ad0f94c1935ca67485">The configuration was saved</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f4d1ea475eaa85102e2b4e6d95da84bd">Confirmation</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f4d1ea475eaa85102e2b4e6d95da84bd">Confirmation</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f4d1ea475eaa85102e2b4e6d95da84bd">Confirmation</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@f4d1ea475eaa85102e2b4e6d95da84bd">Confirmation</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@c9cc8cce247e49bae79f15173ce97354">Save</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\ute-rondes\view-settings-form\view-settings-form.component.ts
-->
<p i18n="@@c9cc8cce247e49bae79f15173ce97354">Save</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@ae2fd93db7a96c6c8eb65aa02dc03217">Circular</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@790ea32fd49d8f0de4dc94aa8d00387d">Poligonal</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@738f88e738887279a7e484b715918724">Notice Group Created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@50585be4e3159a71c874c590d2ba12ec">:)</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@7063690f087b0c0f375c625012d66ccd">Zone Group Created</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@8be17ea65033d91d036bc0e477a2a853">Edit Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-form\zone-form.component.ts
-->
<p i18n="@@962a4c03ca790e4682304039c71e79d4">Create Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-group-form\zone-group-form.component.ts
-->
<p i18n="@@0d747a9935707d5e73cccfd5648adf0d">Create Group Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-group-form\zone-group-form.component.ts
-->
<p i18n="@@39dbada08a27cf4bfcbfba375237aaa6">Edit Group Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-group-form\zone-group-form.component.ts
-->
<p i18n="@@fa3a370037891423ce0877840a31465c">Zone Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-group-form\zone-group-form.component.ts
-->
<p i18n="@@39dbada08a27cf4bfcbfba375237aaa6">Edit Group Zone</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-groups-list\zone-groups-list.component.ts
-->
<p i18n="@@fa3a370037891423ce0877840a31465c">Zone Group</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\components\zones\zone-list\zone-list.component.ts
-->
<p i18n="@@dad1f8d794ee0dd7753fe75e73b78f31">Zones</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@2e92ae79ff32b37fee4368a594792183">Connection error</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@d456e22b80e39306877c4217cfa2462d">The server is not responding</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@98b189ae65ac0fd2937a57185e539d47">Authentication error</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@75bab72075b5d559a2c52e7d893d4b4c">You are not authenticated</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@18c86ff68653dcb977b739cd2160d1ee">Upss</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@6609dd239e143d00002123326c5e4aad">Something went wrong</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@18c86ff68653dcb977b739cd2160d1ee">Upss</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@6609dd239e143d00002123326c5e4aad">Something went wrong</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@18c86ff68653dcb977b739cd2160d1ee">Upss</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@6609dd239e143d00002123326c5e4aad">Something went wrong</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@18c86ff68653dcb977b739cd2160d1ee">Upss</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\rest\jqueryhttpclient.ts
-->
<p i18n="@@6609dd239e143d00002123326c5e4aad">Something went wrong</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@fe5b8dc61a291c463003339f11bc8492">dd/mm/yy</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@9d1a0949c39e66a0cd65240bc0ac9177">Sunday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@6f8522e0610541f1ef215a22ffa66ff6">Monday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@5792315f09a5d54fb7e3d066672b507f">Tuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@796c163589f295373e171842f37265d5">Wednesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@0f1143729b7fb15244716cfcfafd35bb">Thuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@c33b138a163847cdb6caeeb7c9a126b4">Friday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@8b7051187b9191cdcdae6ed5a10e5adc">Saturday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@9d1a0949c39e66a0cd65240bc0ac9177">Sunday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@6f8522e0610541f1ef215a22ffa66ff6">Monday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@5792315f09a5d54fb7e3d066672b507f">Tuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@796c163589f295373e171842f37265d5">Wednesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@0f1143729b7fb15244716cfcfafd35bb">Thuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@c33b138a163847cdb6caeeb7c9a126b4">Friday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@8b7051187b9191cdcdae6ed5a10e5adc">Saturday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@9d1a0949c39e66a0cd65240bc0ac9177">Sunday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@6f8522e0610541f1ef215a22ffa66ff6">Monday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@5792315f09a5d54fb7e3d066672b507f">Tuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@796c163589f295373e171842f37265d5">Wednesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@0f1143729b7fb15244716cfcfafd35bb">Thuesday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@c33b138a163847cdb6caeeb7c9a126b4">Friday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@8b7051187b9191cdcdae6ed5a10e5adc">Saturday</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@86f5978d9b80124f509bdb71786e929e">January</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@659e59f062c75f81259d22786d6c44aa">February</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@fa3e5edac607a88d8fd7ecb9d6d67424">March</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@3fcf026bbfffb63fb24b8de9d0446949">April</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@195fbb57ffe7449796d23466085ce6d8">May</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@688937ccaf2a2b0c45a1c9bbba09698d">June</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@1b539f6f34e8503c97f6d3421346b63c">July</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@41ba70891fb6f39327d8ccb9b1dafb84">August</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@cc5d90569e1c8313c2b1c2aab1401174">September</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@eca60ae8611369fe28a02e2ab8c5d12e">October</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@7e823b37564da492ca1629b4732289a8">November</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@82331503174acbae012b2004f6431fa5">December</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@86f5978d9b80124f509bdb71786e929e">January</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@659e59f062c75f81259d22786d6c44aa">February</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@fa3e5edac607a88d8fd7ecb9d6d67424">March</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@5e60b5ec40e89b36af60305c59be3a00">Abril</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@195fbb57ffe7449796d23466085ce6d8">May</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@688937ccaf2a2b0c45a1c9bbba09698d">June</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@1b539f6f34e8503c97f6d3421346b63c">July</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@41ba70891fb6f39327d8ccb9b1dafb84">August</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@cc5d90569e1c8313c2b1c2aab1401174">September</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@eca60ae8611369fe28a02e2ab8c5d12e">October</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@7e823b37564da492ca1629b4732289a8">November</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@82331503174acbae012b2004f6431fa5">December</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@1dd1c5fb7f25cd41b291d43a89e3aefd">Today</p>
<!--c:\atlantis fleet\cycle-project\proj\web_ui\src\app\services\i18n\i18n.service.ts
-->
<p i18n="@@dc30bc0c7914db5918da4263fce93ad2">Clear</p>
