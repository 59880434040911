<!-- GENERAL PANEL -->
<p-panel header="{{ 'promotedRoutes.routeList' | translate }}"
	[ngClass]="isEditingDisabled ? 'disabledPanel' : ''" i18n-header>

	<!-- PROMOTED-ROUTES PRIMENG DATATABLE
		 (onLazyLoad)='loadData($event)'
	-->
	<p-table
		 id="routes-table"
		 [value]='paginationResult.entities'
		 [lazy]='true'
		 [rows]='paginationRequest.pageSize'
		 [totalRecords]='paginationResult.filteredEntities'
		 [rowsPerPageOptions]='[10,20,30,40,50]'
		 [paginator]='false'
		 [rowHover]='true'
		 [showCurrentPageReport]='true'
		 [autoLayout]='true'
		 currentPageReportTemplate=
		 "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
		 class='custom-table'
		 styleClass='p-datatable-striped'
		 (onRowReorder)="onRowReorder($event)"
	>

		<!------ TABLE COLUMN DEFINITION ------------------------------------------------------------>
		<ng-template pTemplate='header'>
			<tr>
				<!-- DELETE -->
				<th class='checkbox-column'>
					<!-- EMPTY -->
				</th>

				<!-- order -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'promotedRoutes.order' | translate }}
						</div>
					</div>
				</th>

				<!-- NAME -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'general.name' | translate }}
						</div>
					</div>
				</th>

				<!-- Description -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'promotedRoutes.description' | translate }}
						</div>
					</div>
				</th>

				<!-- Category -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'promotedRoutes.category' | translate }}
						</div>
					</div>
				</th>

				<!-- totalKms -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'promotedRoutes.totalKms' | translate }}
						</div>
					</div>
				</th>

				<!-- totalTime -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'promotedRoutes.totalTime' | translate }}
						</div>
					</div>
				</th>

				<!-- STATUS -->
				<th>
					<div class="d-flex align-items-center h-100">
						<!-- COLUMN TITLE -->
						<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
							{{ 'general.status' | translate }}
						</div>
					</div>
				</th>

			</tr>
		</ng-template>

		<!------- TABLE BODY ------------------------------------------------------------------------>
		<ng-template pTemplate='body' let-route let-index="rowIndex">
			<tr class='p-selectable-row custom-rows'
				(dblclick)='editRoute(route)'
				[pReorderableRow]="index">

				<!-- DELETE -->
				<td>
					<button type='button'
							class='btn custom-table-remove-button'
							(click)='deleteRoute(route)'
					>
						<svg-icon src='assets/icons/trash.svg'
								  class='d-flex flex-wrap custom-table-remove-button-icon'
								  [svgStyle]="{ 'height.px': 25, 'width.px': 25 }"
						></svg-icon>
					</button>
				</td>

				<!-- ORDER -->
				<td pReorderableRowHandle>
					{{ route.numPos }}
				</td>

				<!-- NAME -->
				<td>
					{{ route.nameRoute }}
				</td>

				<!-- Description -->
				<td>
					{{ route.shortDescription }}
				</td>

				<!-- CATEGOREY -->
				<td>
					{{ route.category.nameCategory }}
				</td>

				<!-- TOTALKMS -->
				<td>
					{{ route.totalKms }} Kms.
				</td>

				<!-- TOTALTIME -->
				<td>
					{{ route.totalTime }}
				</td>

				<!-- STATUS -->
				<td>
					<div *ngIf='route.enabled'> {{ 'promotedRoutes.enabled' | translate }} </div>
					<div *ngIf='!route.enabled'> {{ 'promotedRoutes.disabled' | translate }} </div>
				</td>

			</tr>
		</ng-template>

		<!------ TABLE EMPTY MESSAGE ---------------------------------------------------------------->
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8" class="p-0">
					<div *ngIf="!loaded" class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message"
						 i18n>
						{{ 'general.loading' | translate }}
					</div>
					<div *ngIf="loaded" class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message"
						 i18n>
						{{ 'table.tableEmptyMessage' | translate }}!
					</div>
				</td>
			</tr>
		</ng-template>

		<!------ FOOTER ----------------------------------------------------------------------------->
		<ng-template pTemplate="footer">
			<tr>
				<td colspan="8" class="p-0">
					<div class="d-flex flex-row align-items-center p-3 gap-3">

						<!-- ADD RACING EVENT BUTTON -->
						<app-table-add-button [text]=" 'promotedRoutes.addRoute' | translate "
											  (btnClick)="createRoute()"
						></app-table-add-button>

					</div>
				</td>
			</tr>
		</ng-template>

	</p-table>

</p-panel>

<!-- DELETE EVENT DIALOG -->
<p-confirmDialog #deleteEventDialog
				 [header]=" 'control-panel.delete-dialog.title' | translate "
				 class='custom-dialog'
				 icon='pi pi-exclamation-triangle'
>
	<ng-template pTemplate="footer">

		<!-- REJECT BUTTON -->
		<button (click)="deleteEventDialog.reject()" class="custom-button">
			{{ 'general.no' | translate }}
		</button>

		<!-- ACCEPT BUTTON -->
		<button (click)="deleteEventDialog.accept()" class="custom-button">
			{{ 'general.yes' | translate }}
		</button>

	</ng-template>
</p-confirmDialog>

<!-- SPACE FORM -->
<app-routes-form></app-routes-form>

