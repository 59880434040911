<!-- taxes LIST PRIMENG DATATABLE -->
<p-table
	[value]="paginationResult.entities"
	[lazy]="true"
	(onLazyLoad)="loadData($event)"
	[rows]="paginationRequest.pageSize"
	[totalRecords]="paginationResult.filteredEntities"
	[rowsPerPageOptions]="[10, 20, 30, 40, 50]"
	[paginator]="true"
	[(selection)]="selectedEntities"
	[loading]="loading"
	[rowHover]="true"
	[showCurrentPageReport]="true"
	[autoLayout]="true"
	dataKey="id"
	currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
	class="custom-table"
	styleClass="p-datatable-striped"
>
	<!-- TABLE TITLE DEFINITION -->
	<ng-template pTemplate="caption">
		<div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
			{{ 'tableManagement.tax.taxesManagement' | translate }}
		</div>
	</ng-template>

	<!-- TABLE COLUMN DEFINITION -->
	<ng-template pTemplate="header">
		<tr>
			<!-- SELECT -->
			<th class="checkbox-column">
				<div class="d-flex justify-content-center">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</div>
			</th>

			<!-- VEHICLE -->
			<th pSortableColumn="vehicle.name" *ngIf="showVehicleColumn">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.vehicle.vehicle' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="vehicle.name"></p-sortIcon>
						<p-columnFilter
							field="vehicle.name"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- DESCRIPTION -->
			<th pSortableColumn="description">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.description' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="description"></p-sortIcon>
						<p-columnFilter field="description" type="text" matchMode="contains"
							placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
							[showOperator]="false" [showAddButton]="false">
						</p-columnFilter>
					</div>
				</div>
			</th>

			<!-- AMOUNT -->
			<th pSortableColumn="taxImport">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.fine.amount' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="taxImport"></p-sortIcon>
						<p-columnFilter
							field="taxImport"
							type="text"
							matchMode="contains"
							placeholder="{{ 'general.search' | translate }}"
							display="menu"
							[showMatchModes]="false"
							[showOperator]="false"
							[showAddButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>

			<!-- PAY DATE -->
			<th pSortableColumn="sanctionDate">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'tableManagement.tax.payDate' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="imei"></p-sortIcon>
					</div>
				</div>
			</th>

			<!-- ACTIVE -->
			<th pSortableColumn="disabled">
				<div class="d-flex align-items-center h-100">
					<!-- COLUMN TITLE -->
					<div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
						{{ 'general.active' | translate }}
					</div>

					<!-- COLUMN SORT && FILTER ICONS -->
					<div class="d-flex align-items-center h-100">
						<p-sortIcon field="disabled"></p-sortIcon>
						<p-columnFilter
							field="disabled"
							type="boolean"
							display="menu"
							[showApplyButton]="false"
						></p-columnFilter>
					</div>
				</div>
			</th>
		</tr>
	</ng-template>

	<!-- TABLE BODY -->
	<ng-template pTemplate="body" let-tax>
		<tr class="p-selectable-row custom-rows" (dblclick)="editDialog(tax)">
			<!-- SELECT -->
			<td>
				<div class="d-flex justify-content-center">
					<p-tableCheckbox [value]="tax"></p-tableCheckbox>
				</div>
			</td>

			<!-- VEHICLE -->
			<td *ngIf="showVehicleColumn">
				{{ tax.vehicle !== null ? tax.vehicle?.name : 'N/A' }}
			</td>

			<!-- DESCRIPTION -->
			<td>
				{{ tax.description }}
			</td>

			<!-- IMPORT -->
			<td>{{ tax.taxImport }} €</td>

			<!-- PAY DATE -->
			<td>
				{{ tax.payDate | date : 'dd/MM/yyyy' }}
			</td>

			<!-- ACTIVE -->
			<td>
				<div class="d-flex justify-content-center align-items-center">
					<i
						class="pi"
						[ngClass]="{ 'true-icon pi-check-circle': !tax.disabled, 'false-icon pi-times-circle': tax.disabled }"
					></i>
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- TABLE EMPTY MESSAGE -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="5" class="p-0">
				<div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
					{{ 'table.tableEmptyMessage' | translate }}!
				</div>
			</td>
		</tr>
	</ng-template>

	<!-- FOOTER -->
	<ng-template pTemplate="footer">
		<tr>
			<td colspan="7" class="p-0">
				<div class="d-flex flex-row align-items-center p-3 gap-3">
					<!-- ADD tax BUTTON -->
					<app-table-add-button
						*ngIf="isCreateGranted"
						[text]="'tableManagement.tax.tax' | translate"
						(btnClick)="create()"
					></app-table-add-button>

					<!-- CHANGE STATUS BUTTON -->
					<app-table-change-status-button
						(btnClick)="changeStatus()"
						[isDisabled]="!user.canManageClients || selectedEntities.length === 0 || !isDisabledGranted"
					></app-table-change-status-button>

					<!-- EXCEL BUTTON -->
					<app-table-excel-button class="buttonTableExcel" (btnClick)="exportExcel()"></app-table-excel-button>
				</div>
			</td>
		</tr>
	</ng-template>
</p-table>

<!-- CHANGE STATUS DIALOG -->
<div *ngIf="showStatusDialog">
	<app-change-status-shared
		[items]="'control-panel.status-dialog.maintenancePlans' | translate"
		[display]="true"
		[service]="taxeservice"
		[entitiesList]="selectedEntities"
		(return)="refreshList($event)"
		(close)="closeStatusDialog($event)"
	></app-change-status-shared>
</div>

<!-- tax FORM -->
<app-taxes-form [vehicle]="vehicle" (return)="refreshList($event, false)"></app-taxes-form>
