<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Fixed Report Information
          (AT + GTFRI)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <!--
        <div class="col row dvcFields">
          <div class="col lblStyle">
          </div>
          <div class="col">
          </div>
        </div>
        -->

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mode">
              </form-input>
            </div>
            <div class="col">
              <p-dropdown (onChange)="trackEventModeChange(friParameters.mode,$event)" [options]="modeOptions"
                [(ngModel)]="friParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Send Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="friParameters.sendInterval" [min]="5" [max]="86400"></p-spinner>
                <!-- Known bug: if check is changed, fri will only be in a correct value after changed -->
                <span class="ui-inputgroup-addon">5~86400</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="IGF Report Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="friParameters.igfReportInterval" [min]="0" [max]="86400"></p-spinner>
                <span class="ui-inputgroup-addon">0~86400</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Report Period
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Period Enable">
              </form-input>
            </div>
            <div class="col">
              <p-inputSwitch [(ngModel)]="friParameters.periodEnable"></p-inputSwitch>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Start Time">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="text" maxlength="4" pInputText [(ngModel)]="friParameters.startTime"
                  [style]="{'width':'100%'}" />
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="End Time">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <input type="text" maxlength="4" pInputText [(ngModel)]="friParameters.endTime"
                  [style]="{'width':'100%'}" />
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mileage
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Distance">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="friParameters.distance" [min]="50" [max]="65535"></p-spinner>
                <span class="ui-inputgroup-addon">50~65535</span>
                <span class="ui-inputgroup-addon">m</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Mileage">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="friParameters.mileage" [min]="50" [max]="65535"></p-spinner>
                <span class="ui-inputgroup-addon">50~65535</span>
                <span class="ui-inputgroup-addon">m</span>
              </div>
            </div>
          </div>

        </div>

      </p-accordionTab>
    </div>
    <div class="ui-g-12">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Other
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Discard No Fix">
              </form-input>
            </div>
            <div class="col">
              <p-inputSwitch [(ngModel)]="friParameters.discardNoFix"></p-inputSwitch>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Corner Report">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="friParameters.cornerReport" [min]="0" [max]="180"></p-spinner>
                <span class="ui-inputgroup-addon">0~180</span>
                <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>