<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >GPS DATA SENDING BATCH (BATCH)</span>
        </div>
  
        <div class="right">
          <p-inputSwitch [(ngModel)]="batch.enabled"></p-inputSwitch>
        </div>
      </div>
    </p-header>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input  fieldName="Number of Messages" >
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="batch.numberMessages" [min]="1" [max]="50"></p-spinner>
          <span class="ui-inputgroup-addon">1~50</span>
        </div>
      </div>
    </div>  
  </p-accordionTab>