import {
    Input,
    Component,
    ViewEncapsulation,
    SimpleChanges,
    OnChanges,
} from "@angular/core";
import { AuthenticationService } from "../../../../core/services/authentication/authentication.service";
import { Rest } from "../../../../rest/rest_client";
import { I18nService } from "../../../../services/i18n/i18n.service";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";

@Component({
    selector: "app-output-pattern",
    templateUrl: "./output-pattern.component.html",
    styleUrls: ["./output-pattern.component.css"],

    encapsulation: ViewEncapsulation.None,
})
export class OutputPatternComponent
    extends ServerValidatedFormComponent
    implements OnChanges {
    constructor(
        authenticationService: AuthenticationService,
        protected i18n: I18nService
    ) {
        super(i18n);
    }

    @Input() outputPattern: Rest.OutputPattern;

    ngOnChanges(changes: SimpleChanges) {
        if (!changes["deviceParams"]) return;
    }
}
