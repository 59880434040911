<!-- NEW DIALOG -->
<p-dialog *ngIf='isDialogVisible'
          header='{{ vehicleFormTitle | uppercase }}'
          [(visible)]='isDialogVisible'
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]='true'
          [draggable]='false'
          [resizable]='false'
          [closeOnEscape]='false'
          [style]="{width: '80vw'}"
>
   <!-- FORM ERRORS -->
   <div #errorContainer>
      <div *ngIf='errorMessages.length !== 0'>
         <div *ngFor='let error of errorMessages'>
            <div class='alert alert-warning' role='alert'>{{error}}</div>
         </div>
      </div>
   </div>

	<!-- PANELL HISTORIC DE VEHICLE, sel.leccio de dates -->
	<div *ngIf="!isNew" class='d-flex flex-column gap-3 p-3'>
		<p-panel
			header="{{ 'tableManagement.vehicleHtc.vehicleHtc' | translate }}"
			[ngClass]="isEditingDisabled ? 'disabledPanel' : ''"
			i18n-header>

			<div class='d-flex row-cols-1 w-100'>
				<div class='d-flex'>
					<form-input fieldName="{{ 'tableManagement.vehicleHtc.since' | translate }}" [errors]='' [required]='true' style="margin-right: 20px">
						<p-calendar [(ngModel)]='startDate'
									(ngModelChange)='onStartDateChange()'
									[readonlyInput]='true'
									[showTime]='false'
									[style]="{'width':'150px'}"
									dateFormat='{{i18n.dateFormat}}'
									showIcon='true'
									appendTo='body'
						></p-calendar>
					</form-input>
					<form-input fieldName="{{ 'tableManagement.vehicleHtc.to' | translate }}" [errors]='' [required]='true' style="margin-right: 20px">
						<p-calendar [(ngModel)]='endDate'
									(ngModelChange)='onEndDateChange()'
									[disabled]='startDate === null'
									[readonlyInput]='true'
									[maxDate]="today"
									[showTime]='false'
									[style]="{'width':'150px'}"
									dateFormat='{{i18n.dateFormat}}'
									showIcon='true'
									appendTo='body'
						></p-calendar>
					</form-input>
					<form-input [required]='false' fieldName="{{ 'tableManagement.vehicleHtc.period' | translate }}">
						<p-dropdown
							[options]='historicOptionsList'
							[(ngModel)]='selectedHistoricOptionsList'
							dataKey='id'
							optionLabel='value'
							[style]="{'width':'250px'}"
							appendTo='body'
							(onChange)="onChangePeriod($event)"
						></p-dropdown>
					</form-input>
					<button
						type='button'
						class='btn custom-button'
						(click)='onSearch()'
						[style]="{'margin-left': '50px'}">
						{{ 'general.search' | translate }}
					</button>
				</div>
			</div>
		</p-panel>
	</div>

	<!-- Pestanyes: Manteniments / ALERTES (boto per desactivar-les totes?) / DOCUMENTACIO / NOTES -->
	<div class='d-flex flex-column gap-3 p-3'>
		<p-tabView [scrollable]="true">

			<!-- DRIVER ASSIGNMENTS -->
			<p-tabPanel header="{{ 'drivers.table.drivers' | translate }}">
				<p>
					<app-drivers-assignments-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-drivers-assignments-list>
				</p>
			</p-tabPanel>

			<!-- VEHICLE ASSIGNMENTS -->
			<p-tabPanel header="{{ 'vehicleAssignments.header' | translate }}">
				<p>
					<app-vehicle-assignments-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-vehicle-assignments-list>
				</p>
			</p-tabPanel>

			<!-- ACCESSORIES -->
			<p-tabPanel header="{{ 'tableManagement.accessories.accessories' | translate }}">
				<p>
					<app-inventory-vehicle-accessory-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-inventory-vehicle-accessory-list>
				</p>
			</p-tabPanel>

			<!-- DOCUMENTOS -->
			<p-tabPanel header="{{ 'documents.header' | translate }}">
				<p>
					<app-doc-table
						[type]="'vehicle'"
						[entity]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true"
					></app-doc-table>
			 	</p>
			</p-tabPanel>

			<!-- MULTAS -->
			<p-tabPanel header="{{ 'tableManagement.fine.fines' | translate }}">
				<p>
					<app-fine-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate"
						[nested]="true">
					</app-fine-list>
				</p>
			</p-tabPanel>

			<!-- TAXES -->
			<p-tabPanel header="{{ 'tableManagement.tax.taxes' | translate }}">
				<p>
					<app-taxes-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-taxes-list>
				</p>
			</p-tabPanel>

			<!-- FINANCING -->
			<p-tabPanel header="{{ 'tableManagement.financing.financing' | translate }}">
				<p>
					<app-financing-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-financing-list>
				</p>
			</p-tabPanel>

			<!-- INSURANCE -->
			<p-tabPanel header="{{ 'tableManagement.insurances' | translate }}">
				<p>
					<app-insurance-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-insurance-list>
				</p>
			</p-tabPanel>

			<!-- CARDS -->
			<p-tabPanel header="{{ 'tableManagement.cardLists.cards' | translate }}">
				<p>
					<app-htc-card-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-htc-card-list>
				</p>
			</p-tabPanel>

			<!-- VEHICLE STATUS -->
			<p-tabPanel header="{{ 'tableManagement.vehicleStatus.vehicleStatus' | translate }}">
				<p>
					<app-htc-vehicle-status-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-htc-vehicle-status-list>
				</p>
			</p-tabPanel>

			<!-- VEHICLE USAGE -->
			<p-tabPanel header="{{ 'tableManagement.vehicleUsage.vehicleUsage' | translate }}">
				<p>
					<app-htc-vehicle-usage-list
						[vehicle]="vehicle"
						[startDate]="startDate"
						[endDate]="endDate">
					</app-htc-vehicle-usage-list>
				</p>
			</p-tabPanel>

  		</p-tabView>
	</div>

</p-dialog>


