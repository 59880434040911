import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-server-configuration-ob22',
  templateUrl: './server-configuration-ob22.component.html',
  styleUrls: ['./server-configuration-ob22.component.css']
})
export class ServerConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {

  @Input() server: Rest.ServerConfigurationOB22;

  constructor(protected i18n: I18nService) {  super(i18n);}

  AddressOptions: any = [
    {label: "IP address",value:"IP_ADDRESS"},
    {label: "Domain name",value:"DOMAIN_NAME"},      
  ];

  ProtocolOptions: any =[
    {label: "TCP", value:"TCP"},
    {label: "UDP", value:"UDP"}
  ]

 
  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.server);
    }
  }

}
