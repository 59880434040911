import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { JQueryHTTPClient } from 'app/rest/JQueryHTTPClient';
import { EntityService } from 'app/rest/entity.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class ProviderService extends EntityService<Rest.FidProvider> {

	client: Rest.RestApplicationClient;

   constructor(
		private jquery: JQueryHTTPClient,
		private http: HttpClient,
		private auth: AuthenticationService,
		private notificationsService: NotificationsService
	) {
		super();
		this.client = new Rest.RestApplicationClient(jquery);
	}

	public getProviders(): Rest.RestResponse<Rest.FidProvider[]> {
		// console.log("[PROVIDERS-SERVICE] *** getProviders ****");
		return this.client.FidProviders_getAll();
	}

}
