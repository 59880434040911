<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Authentication Id(AT+GTIDA)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12 ui-md-6">
      <form-input  fieldName="Mode">
        <p-dropdown (onChange)="trackEventModeChange(idaParameters.mode, $event)" [options]="modeOptions"
          [(ngModel)]="idaParameters.mode" [style]="{'width':'190px'}"></p-dropdown>
      </form-input>
    </div>
    <div [class.disableDIV]=!trackModeSwitch>
     
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Start Index">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="idaParameters.startIndex" [min]="1" [max]="250"
              (onChange)="onChange()"></p-spinner>
            <span class="ui-inputgroup-addon">1~250</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="End Index">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.endIndex" [min]="1" [max]="250"
                (onChange)="onChange()"></p-spinner>
              <span class="ui-inputgroup-addon">1~250</span>
            </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="ID Number list" >
            <div class="ui-inputgroup">
              <input type="text" pInputText pattern="^[a-fA-F0-9]*$" [(ngModel)]="idaParameters.idNumberList"/>
            </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Timeout after Ignition Off">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="idaParameters.timeoutAfterIgnitionOff" [min]="15" [max]="600"></p-spinner>
            <span class="ui-inputgroup-addon">15~600</span>
            <span class="ui-inputgroup-addon" >sec</span>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Mode Report">
            <p-dropdown [options]="modeReportOptions"
              [(ngModel)]="idaParameters.modeReport" [style]="{'width':'190px'}"></p-dropdown>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Validity Time">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.validityTime" [min]="15" [max]="600"></p-spinner>
              <span class="ui-inputgroup-addon">15~600</span>
              <span class="ui-inputgroup-addon" >sec</span>
            </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Output Id">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.outputId" [min]="0" [max]="5"></p-spinner>
              <span class="ui-inputgroup-addon">0~3</span>
            </div>
          </form-input>
      </div>   
      <div class="ui-g-12 ui-md-6">
        <form-input  fieldName="Output status">
          <div class="ui-inputgroup">
              <p-checkbox binary="true" label="Output Status" [(ngModel)]="idaParameters.outputStatus">
              </p-checkbox>
          </div>
        </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Duration">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.duration"
                [min]="0" [max]="2"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon">(X100 ms)</span>
            </div>
          </form-input>
      </div>
      <div class="ui-g-12 ui-md-6">
          <form-input  fieldName="Toggle Times">
            <div class="ui-inputgroup">
              <p-spinner size="5" [(ngModel)]="idaParameters.toggleTimes"
                [min]="0" [max]="2"></p-spinner>
              <span class="ui-inputgroup-addon">0~255</span>
              <span class="ui-inputgroup-addon">sec</span>
            </div>
          </form-input>
      </div>     
    </div>
  </div>
</p-accordionTab>