<p-dialog header="{{'control-panel.vehicles.vehicleBrand.title' | translate}}" (onHide)="onHide($event)" [(visible)]="display" [responsive]="true" showEffect="fade"
          [modal]="false" appendTo="body">

    <div class="ui-g-12 ui-md-6">
        <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleBrand.brandName' | translate}}">
            <input pInputText id="name" [(ngModel)]="entity.name" type="text" />
        </form-input>
    </div>
    <div class="ui-g-12 ui-md-6">
            <button type="button" pButton icon="fa fa-check"
                (click)="createBrand()" label="{{'general.create' | translate}}"></button>
    </div>

</p-dialog>