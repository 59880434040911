<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.insurance.insuranceComponentTitle' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>

            <!-- POLICY NUMBER -->
            <th pSortableColumn="description">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.insurance.description' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="description"></p-sortIcon>
                        <p-columnFilter field="description" type="text" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false" ></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- START DATE -->
            <th pSortableColumn="startDate">
                <div class="d-flex align-items-center h-100">
            
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.start' | translate }}
                    </div>
            
                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="startDate"></p-sortIcon>
                    </div>
            
                </div>
            </th>
            
            <!-- END DATE -->
           <th pSortableColumn="endDate">
                <div class="d-flex align-items-center h-100">
            
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.end' | translate }}
                    </div>
            
                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                       <p-sortIcon field="endDate"></p-sortIcon>

                    </div>
            
                </div>
            </th>

            <!-- COST -->
            <th *ngIf="canSeeCosts" pSortableColumn="cost">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.insurance.cost' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="cost"></p-sortIcon>
                        <p-columnFilter field="cost" type="numeric" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

            <!-- FLEET COST -->
            <th *ngIf="canSeeCosts" pSortableColumn="fleetCost">
                <div class="d-flex align-items-center h-100">
                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.insurance.fleetCost' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="fleetCost"></p-sortIcon>
                        <p-columnFilter field="fleetCost" type="numeric" matchMode="contains"
                            placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </div>
            </th>

        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-entity>

        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(entity)">

            <!-- OBSERVATIONS -->
            <td>
                {{ entity.description }}
            </td>
           
            <!-- START DATE -->
            <td>
                {{ entity.startDate | date: 'dd/MM/yyyy' }}
            </td>
            
            <!-- END DATE -->
            <td>
                {{ entity.endDate | date: 'dd/MM/yyyy' }}
            </td>

            <!-- COST -->
            <td *ngIf="canSeeCosts">
                {{ entity.cost | number: '1.2-2'}} €
            </td>

            <!-- FLEET COST -->
            <td *ngIf="canSeeCosts">
                {{ entity.fleetCost | number: '1.2-2'}} €
            </td>

        </tr>

    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="colspan" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

</p-table>