import { Injectable } from '@angular/core';
import { EntityService } from '../../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../../rest/JQueryHTTPClient';
import { Rest_task } from './rest_client_task';
import {Rest} from '../../../../rest/rest_client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { NotificationsService, Severity } from '../../../../services/notifications-service/notifications.service';


@Injectable()
export class DelegationService extends EntityService<Rest_task.Delegation> {
    client: Rest.RestApplicationClient;

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient,
        private auth: AuthenticationService,
        private notificationsService: NotificationsService,
    ) {
        super();
        this.client_task = new Rest_task.RestApplicationClient(jquery);
    }

    create(arg0: Rest_task.Delegation): Rest.RestResponse<Rest_task.Delegation> {
        return this.client_task.delegationAddAllDataTask(arg0);
    }
    

        update(arg0: Rest_task.Delegation): Rest.RestResponse<Rest_task.Delegation> {
        return this.client_task.delegationUpdateAllDataTask(arg0);
    }


    delete(arg0: Rest_task.Delegation[]): Rest.RestResponse<void>  {
        return this.client_task.Delegation_delete(arg0[0]);
    }


    getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_task.Delegation>> {
        return this.client_task.Delegation_getPage(arg0);
    }

    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest_task.Delegation> {
        return this.client_task.Delegation_find(arg0, queryParams);
    }



    public delegationGetAllDataTask(id:number): Rest.RestResponse<Rest_task.Delegation>{
        return this.client_task.delegationGetAllDataTask(id);



    }

    public delegationUpdateAllDataTask(uid: number, arg1:Rest_task.Delegation): Rest.RestResponse<Rest_task.Delegation>{
        return this.client_task.delegationUpdateAllDataTask(uid,arg1);
    }
    public deleteDelegation(arg0: Rest_task.Delegation): Rest.RestResponse<boolean>  {
        return this.client_task.Delegation_delete(arg0);
    }
    /*
    getAvailableUserAssignations(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest_task.DelegationUser[]> {
        return this.client_task.Delegation_getAvailableUserAssignations(arg0);
    }
    */
}
