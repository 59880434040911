<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-6">
            <!-- REPORT TITLE  -->
            <h2 class="mb-3">{{'reportsWizard.header.reportOf' | translate }} {{report.reportData.name}}</h2>

            <!-- GO BACK BUTTON -->
            <button type="button" class="btn btn-secondary mb-3" (click)="goBack()">
                <i class="pi pi-arrow-left"></i>
                {{ 'reportsWizard.reportsForm.goBack' | translate }}
            </button>

            <!-- FORM START -->
            <form>
                <!-- CALENDAR LICENSE INPUTS -->
                <div *ngIf="report.reportData.reportType === 'DRIVERS_EXPIRATION'">
                    <div class="form-group mb-3">
                        <form-input
						[required]="true"
						fieldName="{{ 'userProfile.accountExpiryDate' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>
                        <p-calendar [(ngModel)]='alarmDate' id="alarmDateDriver" [showTime]='false' dateFormat="dd-mm-yy"
                            showIcon='true' class="ms-3"></p-calendar>
                        </form-input>
                    </div>
                </div>

                <!-- CALENDAR INSURANCE INPUTS -->
                <div *ngIf="report.reportData.reportType === 'INSURANCE_ACTIVE'">
                    <div class="form-group mb-3">
                        <form-input
						[required]="true"
						fieldName="{{ 'documents.driverPoints.date' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>
                        <p-calendar [(ngModel)]='startDate' id="startDate" [showTime]='false' dateFormat="dd-mm-yy"
                            showIcon='true' appendTo='body' class="ms-3"></p-calendar>
                        </form-input>
                    </div>
                </div>

                <!-- CALENDAR FINES INPUTS -->
                <div
                    *ngIf="report.reportData.reportType === 'FINES' || report.reportData.reportType === 'VEHICLE_EXPIRATION'">
                    <div class="form-group mb-3">
                        <form-input
						[required]="true"
						fieldName="{{ 'general.startDate' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>
                        <p-calendar [(ngModel)]='startDateFines' id="startDate" [showTime]='false' dateFormat="dd-mm-yy"
                            showIcon='true' appendTo='body' class="ms-3"></p-calendar>
                            </form-input>
                    </div>

                    <div class="form-group mb-3">
                        <form-input
						[required]="true"
						fieldName="{{ 'general.endDate' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>

                        <p-calendar [(ngModel)]='endDateFines' id="endDate" [showTime]='false' dateFormat="dd-mm-yy"
                            showIcon='true' appendTo='body' class="ms-3"></p-calendar>
                        </form-input>
                    </div>
                </div>

                <!-- CALENDAR MAINTENANCES INPUT -->
                <div *ngIf="report.reportData.reportType === 'ITV_EXPIRATION'">
                    <div class="form-group mb-3">
                        <form-input
						[required]="true"
						fieldName="{{ 'reportsWizard.reportGenerator.alarmDate' | translate }}"
						[style]="{ 'margin-right': '50px' }"
					>
                        <p-calendar [(ngModel)]='alarmDateMaintenances' id="alarmDate" [showTime]='false'
                            dateFormat="dd-mm-yy" showIcon='true' appendTo='body' class="ms-3"></p-calendar>
                        </form-input>
                    </div>
                </div>
            </form>
            <!-- FORM END -->

            <!-- GENERATE REPORT BUTTON -->
            <button pButton type="button" class="btn btn-primary mt-3" (click)="generateReport()"
                label="{{ 'reportsWizard.reportsForm.generateReport' | translate }}"
                [ngStyle]="{'background-color': '#C42C22', 'border-color': '#C42C22'}">

            </button>
        </div>

        <!-- REPORT TABLE -->
        <p-dialog *ngIf="display" header="{{report?.reportData?.name}}" [(visible)]="display"
            [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'" [modal]="true" [draggable]="false"
            [resizable]="false" [closeOnEscape]="false" [style]="{width: dialogWidth}">
            <div class="content">
                <p-table id="dt" [columns]="columns" [value]="tableDataFormatted" [loading]="loading" [rowHover]="true"
                    [showCurrentPageReport]="true" [autoLayout]="true" dataKey="name" class="custom-table"
                    styleClass="p-datatable-striped" [rows]="10" [paginator]="tableDataFormatted.length > 10"
                    [showCurrentPageReport]="true" (sortFunction)="customSort($event)" [customSort]="true"
                    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}">
                    <!-- HEADER -->
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" class="text-center align-middle"
                                [pSortableColumn]='col.field'>
                                <!--
                                {{col.header}}
                                <div class='d-flex align-items-center h-100'>
                                     <p-sortIcon field='{{col.field}}'></p-sortIcon>
                                </div>
-->
                                <div class='d-flex align-items-center h-100'>
                                    <!-- COLUMN TITLE -->
                                    <div
                                        class='d-flex align-items-center align-items-center justify-content-center flex-fill h-100'>
                                        {{ col.header }}
                                    </div>
                                    <!-- COLUMN SORT && FILTER ICONS -->
                                    <div class='d-flex align-items-center h-100'>
                                        <p-sortIcon field='{{col.field}}'></p-sortIcon>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <!-- BODY -->
                    <ng-template pTemplate="body" let-tableRow let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns" class="text-center align-middle">
                                {{tableRow[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <!-- FOOTER -->
                    <ng-template pTemplate="footer">
                        <tr>
                            <td [attr.colspan]="columns.length" class="p-0">
                                <div class="d-flex flex-row align-items-center p-3 gap-3">
                                    <div class="d-flex flex-row " style="margin-right: auto;">
                                        <app-table-excel-button class="buttonTableExcel"
                                            (btnClick)="exportExcel()"></app-table-excel-button>
                                        <app-table-pdf-button pTooltip="{{'reports.exportPDF' | translate }}"
                                            tooltipPosition="top" class="buttonTableExcel"
                                            (btnClick)="exportPdf()"></app-table-pdf-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-dialog>
    </div>
</div>