import { I18nService } from '../../services/i18n/i18n.service';
import { Component } from '@angular/core';

//TODO https://github.com/angular/angular/issues/11405
// create a dummy component to house our extra translations, 
// don't forget to add the component to declarations of AppModule.
// This is so that ng-xi18n can find the html (I think) and add it to the translation file.
@Component({
  selector: 'app-hack-translations',
  templateUrl: './hack-translations.html',
})


export class HackTranslationsComponent {
  private i18n:I18nService;
  constructor() {}    

  toTranslate()
  {
    this.i18n._('Sunday');
    this.i18n._('Monday');
    this.i18n._('Tuesday');
    this.i18n._('Wednesday');
    this.i18n._('Thuesday');
    this.i18n._('Friday');
    this.i18n._('Saturday');

    this.i18n._('January');
    this.i18n._('February');
    this.i18n._('March');
    this.i18n._('April');
    this.i18n._('May');
    this.i18n._('June');
    this.i18n._('July');
    this.i18n._('August');
    this.i18n._('September');
    this.i18n._('October');
    this.i18n._('November');
    this.i18n._('December');
    this.i18n._('Today');
    this.i18n._('Clear');

    this.i18n._('DAYS');
    this.i18n._('MONTHS');
    this.i18n._('YEARS');

    this.i18n._('EN');
    this.i18n._('ES');
    this.i18n._('FR');

    this.i18n._('Enabled');
    this.i18n._('Disabled');
    this.i18n._('DISABLED');
    
    this.i18n._('MODE_0');
    this.i18n._('MODE_1');
    this.i18n._('MODE_2');

    this.i18n._('GPS_FIXED');
    this.i18n._('ANY_GPS');
    this.i18n._('ACC_ON_AND_GPS_FIXED');
    this.i18n._('ACC_ON_AND_ANY_GPS');  

    this.i18n._('ANY');
    this.i18n._('ON');
    this.i18n._('OFF');

    this.i18n._('SENSOR');
    this.i18n._('ACTUATOR');
    this.i18n._('OFF');

    this.i18n._('TIME');
    this.i18n._('DISTANCE');
    this.i18n._('TIME_AND_DISTANCE');
    this.i18n._('TIME_OR_DISTANCE');
    this.i18n._('HEADING');
    this.i18n._('HEADING_OR_TIME');
    this.i18n._('HEADING_OR_DISTANCE');
    this.i18n._('HEADING_OR_TIME_AND_DISTANCE');
    this.i18n._('HEADING_OR_TIME_OR_DISTANCE');
    
    
    this.i18n._('DISABLE');
    this.i18n._('LOGGING');
    this.i18n._('POLLING');
    this.i18n._('LOGGING_AND_POLLING');
    this.i18n._('ACTIVE');
    this.i18n._('PENDING');
    this.i18n._('UNINSTALLED');
    this.i18n._('INTERNATIONAL_SYSTEM_OF_UNIT');
    this.i18n._('IMPERIAL_SYSTEM');
    this.i18n._('DISABLE');
    this.i18n._('EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_IN_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION');
    this.i18n._('EVENT_TRIGGERED_WHEN_VOLTAGE_LEVEL_OT_THE_RANGE_FOR_ASSIGNED_TIME_OF_DURATION');
    this.i18n._('OUTPUT_1');
    this.i18n._('OUTPUT_2');
    this.i18n._('OUTPUT_3');
    this.i18n._('OUTPUT_4');
    this.i18n._('MILEAGE_WILL_BE_ACCUMULATED_REGARDLESS_THE_ACC_STATUS');
    this.i18n._('MILEAGE_WILL_BE_ACCUMULATED_ONLY_IF_THE_ACC_IS_ON');
    this.i18n._('ENTER_SPEEDING_REPORT_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY');
    this.i18n._('ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY');
    this.i18n._('TIME_BASED');
    this.i18n._('DISTANCE_BASED');
    this.i18n._('DIGITAL');
    this.i18n._('ANALOG');
    this.i18n._('CANBUS');
    this.i18n._('DRIVER');
    this.i18n._('VEHICLES_READ');
    this.i18n._('VEHICLES_CREATE');
    this.i18n._('VEHICLES_UPDATE');
    this.i18n._('VEHICLES_DISABLE');
    this.i18n._('MAINTENANCE_CREATE');
    this.i18n._('MAINTENANCE_READ');
    this.i18n._('MAINTENANCE_UPDATE');
    this.i18n._('MAINTENANCE_DISABLE');
    this.i18n._('MAINTENANCE_PLANS_READ');
    this.i18n._('MAINTENANCE_PLANS_UPDATE');
    this.i18n._('MAINTENANCE_PLANS_DISABLE');
    this.i18n._('MAINTENANCE_EXECUTE');
    this.i18n._('ZONES_CREATE');
    this.i18n._('ZONES_UPDATE');
    this.i18n._('ZONES_DISABLE');
    this.i18n._('ZONES_GROUPS_READ');
    this.i18n._('ZONES_GROUPS_UPDATE');
    this.i18n._('ZONES_GROUPS_DISABLE');
    this.i18n._('POIS_CATEGORIES_READ');
    this.i18n._('POIS_CATEGORIES_UPDATE');
    this.i18n._('POIS_CATEGORIES_DISABLE');
    this.i18n._('POIS_CREATE');
    this.i18n._('POIS_UPDATE');
    this.i18n._('POIS_DISABLE');
    this.i18n._('POIS_READ');
    this.i18n._('NOTIFICATION_GROUPS_READ');
    this.i18n._('NOTIFICATION_GROUPS_UPDATE');
    this.i18n._('VEHICLES_GROUPS_CREATE');
    this.i18n._('VEHICLES_GROUPS_READ');
    this.i18n._('VEHICLES_GROUPS_UPDATE');
    this.i18n._('VEHICLES_GROUPS_DISABLE');
    this.i18n._('FLEETS_READ');
    this.i18n._('FLEETS_UPDATE');
    this.i18n._('FLEETS_DISABLE');
    this.i18n._('CLIENTS_READ');
    this.i18n._('DEVICES_READ');
    this.i18n._('DEVICES_UPDATE');
    this.i18n._('DEVICES_DISABLE');
    this.i18n._('PROFILES_READ');
    this.i18n._('PROFILES_UPDATE');
    this.i18n._('PROFILES_DISABLE');
    this.i18n._('ROLES_READ');
    this.i18n._('ROLES_UPDATE');
    this.i18n._('ROLES_DISABLE');
    this.i18n._('USERS_READ');
    this.i18n._('USERS_UPDATE');
    this.i18n._('USERS_DISABLE');

  }
}
