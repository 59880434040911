import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-hbm-form-gv800',
  templateUrl: './hbm-form-gv800.component.html',
  styleUrls: ['./hbm-form-gv800.component.css'],
})
export class HbmFormGv800Component extends ServerValidatedFormComponent implements OnInit {

  @Input() hbmParameters: Rest.HarshBehaviorMonitoringGV800;

  modeOptions: any = [
    {label: "Disable harsh behavior monitoring",value:0},
    {label: "Detection by GPS only",value:1},
    {label: "Detection by motion sensor only",value:2},                     
    {label: "Detection by GPS and motion sensor",value:3},
  ];
 
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.hbmParameters);
    }
  }
  
  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }

}
