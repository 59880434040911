import { HttpClient } from '@angular/common/http';
import { Rest } from '../../../../rest/rest_client';
import { Rest_task } from '../../../task/administration/services/rest_client_task';
import { environment } from 'environments/environment';

export namespace Rest_logistics {
	export interface Order extends Rest.Trimmable, Rest.WithId {
		id: number;
		orderDelivery: OrderDelivery;
		client: Rest.Client;
		route: Rest_task.Route;
		customer: Customer;
		type: number;  /*  0-Deliverys, 1-Orders Treball */
		user: Rest.User;
		orderNumber: string;
		orderDate: Date;
		state: number;
		comments: string;
		description: string;
		timeWindowFrom: string;
		timeWindowTill: string;
		dateCreated: Date;
		lastModified: Date;
		starthour_inhour: Date;
		finalhour_inhour: Date;
		inHour: number;
		hasIncidence: Boolean;
		priority: number;
		deleted: Boolean;
		dateDeleted: Date;
		packages: OrderPackage[];
		contents: OrderContent[];
		incidences: OrderIncidence[];
		packaging: OrderPackaging[];
		images: orderImage[];
		events: OrderEvent[];
		vehicleRegistrationNumber: string;
		error: Boolean;
      errorMessage: string;
	}


	export interface OrderEvent extends Rest.Trimmable, Rest.WithId {
		id: number;
		order: Order;
		orderNumber: string;
		packageNumber: string;
		typeEvent: number;
		status: number;
		statusDate: Date;
		readingType: number;
		dateCreated: Date;
		name: string;
		dni: string;
		image1: orderImage;
		image2: orderImage;
	}

	export interface OrderDelivery extends Rest.Trimmable, Rest.WithId {
		id: number;
		order: Order;
		client: Rest.Client;
		deliveryNumber: string;
		finalNumber: string;
		deliveryStatus: number;	 /*	 0:esperando carga, 1:cargando, 2:cargado, 3:en reparto, 4:recogido, 5:entregado, 6:devoluci�n entregada;7:entregado incidencias */
		pickingState: number;	 /* 0-pendiente, 1-preparando, 2-preparado */
		type: number;
		incompleteLoading: boolean;
		cash: boolean;
		amountDelivered: number;
		totalAmount: number;
		imageNeeded: number;
		numimages: number;
		deliveryStatusDetail: number;
		payMethod: PaymentMethod;
		hourIniQR: string;
		hourEndQR: string;
		hasValise: boolean;
		totalPackages: number;
		unloadedPackages: number;
		deliveryStatusLabel: string;
		origin: Rest_logistics.Customer;
		typeOrderDelivery: number;
		deliveriedDate: Date;
		lastModified: Date;
	}

	export interface OrderContent extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Order;
		//unitMeasure: UnitMeasure;
		//returnReason: ReturnReason;
		productCode: string;
		description1: string;
		description2: string;
		quantity: number;
		unitPrice: number;
		vat: number;
		amount: number;
		grossAmount: number;
		prepQuantity: number;
		discount: number;
		deficit: Boolean;
		deficit_partial: Boolean;
		dateCreated: Date;
		productLot: string;
		unitName: string;
		unitMeasure: UnitMeasure;
		//contentPackages: OrderContentPackage;
		error: Boolean;
      errorMessage: string;
	}

	export interface OrderPackage extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Order;
		packageNumber: string;
		productFamily: string;
		numpack: number;
		status: number;
		statusDate: Date;
		orderNumber: string;
		orderContentPackages: OrderContentPackage;
		error: Boolean;
      errorMessage: string;
	}

	export interface Customer extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		poi: Rest.POI;
		code: string;
		name1: string;
		name2: string;
		address1: string;
		address2: string;
		city: string;
		postCode: string;
		state: string;
		country: string;
		contactPerson: string;
		phoneNumber: string;
		mail: string;
		latitude: number;
		longitude: number;
		geocoded: number;
		totalPackaging: number;
		qrCode: string;
		deleted: boolean;
		dateDeleted: Date;
		customerpackaging: CustomerPackaging[];
		customervalisecodes: CustomerValiseCode[];
		customerPickupAssigAuto: CustomerPickupAssigAuto[];
		customerRoutes: CustomerRoute[];

	}

	export interface CustomerPackaging extends Rest.Trimmable, Rest.WithId {
		id: number;
		customer: Customer;
		client: Rest.Client;
		packagingTypeId: typePackaging;
		valueDelivery: number;
		valuePickup: number;
		valueinCustomer: number;
		dateLastRegulation: Date;
		valueLastRegulation: number;
	}

	export interface CustomerPickupAssigAuto extends Rest.Trimmable, Rest.WithId {
		customer: Customer;
		route: Rest_task.Route;
		monday: Boolean;
		tuesday: Boolean;
		wednesday: Boolean;
		thursday: Boolean;
		friday: Boolean;
		saturday: Boolean;
		sunday: Boolean;
		time: string;
		offsetTime: number;
	}

	export interface CustomerRoute extends Rest.Trimmable, Rest.WithId {
		customer: Customer;
		route: Rest_task.Route;
		origin: Customer;
		sheduleAxC: Boolean;
		groupage: Boolean;
		routeGroupage: Rest_task.Route;
		intermediate: Customer;
		sheduleAxC1: Boolean;
		groupage2: Boolean;
		routeGroupage2: Rest_task.Route;
		intermediate2: Customer;
		sheduleAxC2: Boolean;
		monday: Boolean;
		tuesday: Boolean;
		wednesday: Boolean;
		thursday: Boolean;
		friday: Boolean;
		saturday: Boolean;
		sunday: Boolean;
		time: string;
		offsetTime: number;
		deleted: boolean;
		dateDeleted: Date;
		customerRouteSchedule: CustomerRouteSchedule[];
		//Table Row Edit Variables
		isEditing: Boolean;
		isAddingCustomerRouteSchedule: Boolean;
	}

	export interface CustomerRouteSchedule extends Rest.Trimmable, Rest.WithId {
		customerRoute: CustomerRoute;
		monday: Boolean;
		tuesday: Boolean;
		wednesday: Boolean;
		thursday: Boolean;
		friday: Boolean;
		saturday: Boolean;
		sunday: Boolean;
		time: string;
		offsetTime: number;
	}

	export interface CustomerValiseCode extends Rest.Trimmable, Rest.WithId {
		customer: Customer;
		codevalise: string;
		deleted: boolean;
	}


	export interface IncidenceType extends Rest.Trimmable, Rest.WithId {
		id: number;
		code: string;
		name: string;
		description: string;
		configurationtable: string;
		lblType: string;

	}

	export interface OrderIncidence extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Order;
		sequence: number;
		type: IncidenceType;
		orderContent: OrderContent;
		orderPackage: OrderPackage;
		user: Rest.User;
		comments: string;
		numImages: number;
		quantity: number;
		productLot: string;
		readIncidence: boolean;
		dateIncidenceRead: Date;
		dateCreated: Date;
		returnReason: ReturnReason;
		unitMeasure: UnitMeasure;
		orderContentPackage: OrderContentPackage;
		incidenceImages: IncidenceImage[];
		incidenceContentPackages:incidenceContentPackages[];
	}
	export interface incidenceContentPackages extends  Rest.Trimmable, Rest.WithId {
		id:number;
		contentLot:string;
		incidence:OrderIncidence;
		orderContentPackage: OrderContentPackage;
	}
	export interface IncidenceImage extends Rest.Trimmable, Rest.WithId {
		id: number;
		incidence: OrderIncidence;
		icon: Rest.Icon;
		numImage: number;
		dateCreated: Date;
	}

	export interface UnitMeasure extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		unitName: string;
		tolerance: number;
		deleted: boolean;
		dateDeleted: Date;
	}
	export interface ReturnReason extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		reasonName: string;
		code: string;
		typeId: number;
		deleted: boolean;
		dateDeleted: Date;
	}
	export interface OrderContentPackage extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Order;
		orderContent: OrderContent;
		orderPackage: OrderPackage;
		productLot: string;
		prepQuantity: number;
	}
	export interface OrderPackaging extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Order;
		type: typePackaging;
		routeInter: Order;
		code: String;
		codeQR: String;
		codeQRInter: String;
		datePickup: Date;
		valuePickup: number;
		state: number;
		dateDelivery: Date;
		valueDelivery: number;
		hasInterPackaging: boolean;
		routeInterDate: Date;
		deliveryInterDate: Date;
		pickupInterDate: Date;
		dateCreated: Date;
		lastModified: Date;
		customer: Customer;
		customerinter: Customer;
		DestinoFinal: String;
	}

	export interface OrderPayment extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		order: Rest_logistics.Order;
		paymentDate: Date;
		reference: string;
		code: string;
		autorizationnumber: string;
		totalAmount: number;
		errormsg: string;
		state: string;
		paymentok: boolean;
		localfile: string;
		remotefile: string;
	}
	export interface headboard_order_columns extends Rest.Trimmable, Rest.WithId {
		date: boolean;
		deliverynumber: boolean;
		state: boolean;
		route: boolean;
		driver: boolean;
		customer: boolean;
		origin: boolean;
		carregistration: boolean;
	}
	export interface general_order_columns extends Rest.Trimmable, Rest.WithId {
		delivery: general_order_event_columns;
		pickUp: general_order_event_columns;
		deliveryPickUp: general_order_event_columns;

	}
	export interface general_order_event_columns extends Rest.Trimmable, Rest.WithId {
		dni: boolean;
		name: boolean;
		packaging: boolean;
		valise: boolean;
		orderSign: boolean;
		orderPhoto: boolean;

	}

	export interface incidences_deliveried_order_columns extends Rest.Trimmable, Rest.WithId {
		incidence_date: boolean;
		comment: boolean;
		images: boolean;
	}
	export interface incidences_product_order_columns extends Rest.Trimmable, Rest.WithId {
		incidence_date: boolean;
		product: boolean;
		lot: boolean;
		unitmeasure: boolean;
		quantity: boolean;
		reason: boolean;
		comment: boolean;
		images: boolean;
	}
	export interface incidences_order_columns extends Rest.Trimmable, Rest.WithId {
		incidences_deliveried: incidences_deliveried_order_columns;
		incidences_product: incidences_product_order_columns;
	}
	export interface product_detail_order_detail_columns extends Rest.Trimmable, Rest.WithId {
		code: boolean;
		description: boolean;
		lot: boolean;
		unitmeasure: boolean;
		quantity: boolean;
		weighedquanttity: boolean;
		missingproduct: boolean;
	}
	export interface detail_order_detail_columns extends Rest.Trimmable, Rest.WithId {
		schedule: boolean;
		valise: boolean;
		comment: boolean;
		productsvisible: boolean;
		packagesvisible: boolean;
		valisecomment: boolean;
		products: product_detail_order_detail_columns;
		package: package_order_detail_columns;

	}
	export interface package_order_detail_columns extends Rest.Trimmable, Rest.WithId {
		number: boolean;
		numberpackage: boolean;
		family: boolean;
		state: boolean;
		readpackages: boolean;
	}

	export interface packing_count_order_detail_columns extends Rest.Trimmable, Rest.WithId {
		visible: boolean;
	}
	export interface packing_count_order_detail_columns extends Rest.Trimmable, Rest.WithId {
		visible: boolean;
	}
	export interface orders_details_columns extends Rest.Trimmable, Rest.WithId {
		headboard: headboard_order_columns;
		general: general_order_columns;
		incidences: incidences_order_columns;
		detail: detail_order_detail_columns;
		package: package_order_detail_columns;
		packagingCodes: packing_count_order_detail_columns;
		packagingCount: packing_count_order_detail_columns;

	}



	export interface Orders_columns extends Rest.Trimmable, Rest.WithId {
		origin: boolean;
		charged: boolean;
		custiomer_code: boolean;
		customer_name: boolean;
		date_end: boolean;
		delivered: boolean;
		delivery_date: boolean;
		delivery_number: boolean;
		delivery_state: boolean;
		hasincidence: boolean;
		hour: boolean;
		hour_end: boolean;
		hour_start: boolean;
		hour_from: boolean;
		hour_start_qr: boolean;
		hour_end_qr: boolean;
		hour_until: boolean;
		inhour: boolean;
		packaging: boolean;
		route: boolean;
		tocollect: boolean
		collected: boolean;
		type_pay: boolean;
		pickedup: boolean;

	}
	export interface routesdetail_columns extends Rest.Trimmable, Rest.WithId {
		stateviewdetail: number;
		assigndriver: boolean;
		updateroutes: boolean;
		packaging: boolean;
		routedata: boolean;
	}
	export interface routes_columns extends Rest.Trimmable, Rest.WithId {
		delivery_date: boolean;
		route: boolean;
		driver: boolean;
		registration: boolean;
		kms_start: boolean;
		kms_end: boolean;
		route_state: boolean;
		total_deliveries: boolean;
		total_deliveries_customer: boolean;
		delivered: boolean;
		effectiveness: boolean;
		pallets_deliveries: boolean;
		pallets_forced: boolean;
		pallets_delivered: boolean;
		//packaging_toreturn:boolean;
		//packaging_returned:boolean;
		packaging: boolean;
		images: boolean;
		comments: boolean;
	}
	export interface Incidences_columns extends Rest.Trimmable, Rest.WithId {
		delivery_date: boolean;
		route: boolean;
		driver: boolean;
		delivery_number: boolean;
		customer: boolean;
		date_deliveried: boolean;
	}


	export interface Incidences_deliveriedcolumns extends Rest.Trimmable, Rest.WithId {
		incidence_date: boolean;
		comment: boolean;
		images: boolean;
	}
	export interface Incidences_productcolumns extends Rest.Trimmable, Rest.WithId {
		incidence_date: boolean;
		product: boolean;
		lot: boolean;
		unitmeasure: boolean;
		quantity: boolean;
		reason: boolean;
		comment: boolean;
		images: boolean;
	}
	export interface admin_columns extends Rest.Trimmable, Rest.WithId {
		delegations: string;
		usersbo: usersboadmin_columns;
		usersapp: usersappadmin_columns;
		routes: routesadmin_columns;
		stores: storesboadmin_columns;
	}
	export interface usersboadmin_columns extends Rest.Trimmable, Rest.WithId {
		delegation:boolean;
	}
	export interface usersappadmin_columns extends Rest.Trimmable, Rest.WithId {
		delegation:boolean;
	}
	export interface routesadmin_columns extends Rest.Trimmable, Rest.WithId {
		delegation:boolean;
		type:boolean;
	}
	export interface storesboadmin_columns extends Rest.Trimmable, Rest.WithId {
		QR:boolean;
		POI:boolean;
		address:boolean;
		label:string;
	}

	export interface packaging_columns extends Rest.Trimmable, Rest.WithId {
		code: boolean;
	}

	export interface OrderPayment_columns extends Rest.Trimmable, Rest.WithId {
		delivery_date: boolean;
		ordernumber: boolean;
		delivery_number: boolean;
		route: boolean;
		customer_name: boolean;
		driver_name: boolean;
		referpayment: boolean;
		import: boolean;
		code_transaction: boolean;
		date_transaction: boolean;
	}


	export interface reverselog_columns extends Rest.Trimmable, Rest.WithId {
		order_date: boolean;
		collection_date: boolean;
		route: boolean;
		delivery_number: boolean;
		driver: boolean;
		origin: boolean;
		destination: boolean;
		type: boolean;
		code: boolean;
		state: boolean;
		delivey_date: boolean;
		qr: boolean;
		quantity: boolean;
	}

	export interface orderImage extends Rest.Trimmable, Rest.WithId {
		id: number;
		order: Order;
		type: number;
		icon: Rest.Icon;
		deliveryStatus: number;
		dateCreated: Date;
	}

	export interface routes_total extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		route: Rest_task.Route;
		orderDate: Date;
		routeName: string;
		ordersTotal: number;
		ordersEnd: number;
		ordersInit: number;
		ordersNotEnd: number;
		ordersPendCharge: number;
		kmStart: number;
		kmEnd: number;
		packagesCharged: number;
		packages_chargedforced: number;
		packages_delivered: number;
		packagingTotalDelivered: number;
		packagingTotalReturn: number;
		totalInHour: number;
		effectiveness: number;
		comments: string;
		//endPhoto:Rest.Icon;
		routehistory: routeHistory;
		routetotalpackaging: routetotal_Packaging[];
		routeStateOrders: number;
		customersTotal: number;
		usersIds: string;
		usernames: string;
		vehiclesIds: string;
		vehiclesNames: string;
	}
	export interface routetotal_Packaging extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		route: Rest_task.Route;
		routetotal: routes_total;
		orderDate: Date;
		typePackaging: typePackaging[];
		totalDelivery: number;
		totalToReturn: number;
		totalReturned: number;
		dateCreated: Date;
	}
	export interface typePackaging extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		type_code: string;
	}

	export interface routeHistory extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		id: number;
		route: Rest_task.Route;
		vehicle: Rest.Vehicle;
		routeName: string;
		userName: string;
		vehicleName: string;
		locationStart: number;
		locationEnd: number;
		dateStart: Date;
		dateEnd: Date;
		kmStart: number;
		kmEnd: number;
		isRouteEnded: boolean;
		routeimages: RouteImages;
		routehistorypackaging: RouteHistoryPackaging;
		//endPhoto:number;
		comments: string;
		//signatureDriver:number;
		//signatureReceiver:number;
		packaginReceiverName: string;
		dateCreated: Date;
		lastModified: Date;
		orderDate: Date;

	}
	export interface RouteHistoryPackaging extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		id: number;
		route: Rest_task.Route;
		packagingType: typePackaging[];
		totalReturned: number;
		totalClientreturned: number;
		dateCreated: Date;
		lastModified: Date;
	}
	export interface RouteImages extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		id: number;
		route: Rest_task.Route;
		orderDate: Date;
		type: number;
		icon: Rest.Icon;
		dateCreated: Date;
	}
	export interface clientconfiguration extends Rest.Trimmable, Rest.WithId {
		configuration: string;
	}
	export interface configuration extends Rest.Trimmable, Rest.WithId {
		type: string;
		filterorders: filterorders;
		orders: Orders_columns;
		orders_details: orders_details_columns;
		general: string;
		incidences: Incidences_columns;
		incidences_deliveried: Incidences_deliveriedcolumns;
		incidences_product: Incidences_productcolumns;
		reverselog: reverselog_columns;
		products: string;
		package: string;
		routes: routes_columns;
		routesdetail: routesdetail_columns;
		valise: string;
		import: string;
		transactions: OrderPayment_columns;
		packaging: packaging_columns;
		admin: admin_columns;
	}

	export interface UserRoute extends Rest.Trimmable, Rest.WithId {
		id: number;
		client: Rest.Client;
		user: Rest.User;
		route: Rest_task.Route;
		routeDate: Date;
		loadValidation: Boolean;
		dateValidation: Date;
		dateCreated: Date;
		lastModified: Date;
		status: Boolean;
	}

	export interface PaymentMethod extends Rest.Trimmable, Rest.WithId{
		id: number;
		payName: string;
	}

	export interface filterorders extends Rest.Trimmable, Rest.WithId {
		value: [];
		date: [];
	}

	export interface orders_details extends Rest.Trimmable, Rest.WithId {
		//falta
		value: [];
		date: [];
	}
	export interface ReportTypes extends Rest.Trimmable {
		id: number;
		name: string;
	}

	export interface ExcelMapping extends Rest.Trimmable, Rest.WithId {
		client: Rest.Client;
		name: string;
		objectClass: string;
		mappingJson: string;
		enabled: Boolean;
	}

	export interface HttpClientCustom {
		request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R }): Rest.RestResponse<R>;
	}

	export class RestApplicationClient {
		constructor(protected httpClient: HttpClientCustom, private http?: HttpClient,
		) {
		}

		/* ORDERS */
		/**
		 * HTTP POST /api/Orders
		 * 		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_getPage
		 */
		Order_create(arg0: Order): Rest.RestResponse<Rest_logistics.Order> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/Orders`,
				data: arg0,
			});
		}


		/**
		 * HTTP POST /api/eventshistory/page
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_getPage

		Orders_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { clientId: number}): Rest.RestResponse<Rest.Page<Orders>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/Orderss/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}*/
		/*
		Order_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
		return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/page`,
				data: arg0,
			});
		}*/

		Order_find(uid: string, queryParams?: { id?: number }): Rest.RestResponse<Order> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/orders/${uid}`,
				queryParams: queryParams,
			});
		}

		Order_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string, dateType: number }): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		Order_getAllData(orderId: number) {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/orders/order/` + orderId
			});
		}
		/**
		 * HTTP PUT /api/eventshistory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_update
		 */
		Order_update(arg0: Order): Rest.RestResponse<Order> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/Orders`,
				data: arg0,
			});
		}

		/**
		 * HTTP DELETE /api/eventshistory
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.EventsHistoryController.EventsHistory_delete
		 */
		Order_delete(arg0: Order): Rest.RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/Orders`,
				data: arg0,
			});
		}

		Order_updateOrderComment(arg0: Order): Rest.RestResponse<Order> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/update/comment`,
				data: arg0,
			});
		}

		Order_getOrderIncidence(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/getOrderIncidence`,
				data: arg0,
				queryParams: queryParams,
			});
		}
		Order_getOrderIncidencenr(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/getOrderIncidencenr`,
				data: arg0,

			});
		}
		Order_getFields(): Rest.RestResponse<string[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/LogisticsOrderFields`
			});
		}
		OrderPackage_getFields(): Rest.RestResponse<string[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/OrderPackageFields`
			});
		}
		OrderContent_getFields(): Rest.RestResponse<string[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/OrderContentFields`
			});
		}

		/* ORDER PACKAGES */
		OrderPackage_createWithOrderId(arg0: OrderPackage, orderId: number): Rest.RestResponse<OrderPackage> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/packages/create/${orderId}`,
				data: arg0,
			});
		}

		OrderPackage_update(arg0: OrderPackage): Rest.RestResponse<OrderPackage> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/packages/update`,
				data: arg0,
			});
		}

		OrderPackage_deleteWithPackageId(orderPackage: OrderPackage): Rest.RestResponse<Boolean> {
			return this.http.delete(
				environment.webApiBaseUrl + `api/task/packages/delete/${orderPackage.id}`,
				//	{ body: orderPackage },
			).toPromise()
				.then(() => {
					return true;
				})
				.catch((error) => {
					return false;
				});
		}
		OrderPackage_getOrderPackages(orderId: number): Rest.RestResponse<OrderPackage[]> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/packages/order/${orderId}`,
				data: null,
			});
		}


		OrderPackage_create(arg0: OrderPackage): Rest.RestResponse<OrderPackage> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/packages/create`,
				data: arg0,
			});
		}

		OrderPackage_delete(arg0: OrderPackage[]): Rest.RestResponse<OrderPackage> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/packages/delete`,
				data: arg0,
			});
		}



		/* ORDER PACKAGING */
		OrderPackaging_create(arg0: OrderPackaging): Rest.RestResponse<OrderPackaging> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/packages/create`,
				data: arg0,
			});
		}
		OrderPackaging_update(arg0: OrderPackaging): Rest.RestResponse<OrderPackaging> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/packages/update`,
				data: arg0,
			});
		}
		OrderPackaging_delete(arg0: OrderPackaging[]): Rest.RestResponse<OrderPackaging> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/packages/delete`,
				data: arg0,
			});
		}

		OrderPackaging_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.OrderPackaging>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/packaging/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		/* update date and route of list orders */
		Order_Updateroutedate(arg0: Rest_logistics.Order[], queryParams?: { id: number, dateOrder: string }): Rest.RestResponse<boolean> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/updateroutedate`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		//PACKAGING
		Order_getOrdersPackaging(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/getOrdersPacakging`,
				data: arg0,
				queryParams: queryParams,
			});
		}
		//LOGISTICA INVERSE
		Order_getOrderLogInverseNew(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/getOrderLogInverseNew`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		//Añadir LOGISTICA INVERSA
		Order_AddUpdateOrderLogInverse(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.Order>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/AddUpdateOrderLogInverse`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		//SEGREGATE ORDER
		Order_AddSegregate(arg0: Rest.ListPaginationRequest, queryParams?: { date: string }): Rest.RestResponse<Rest.GenericResp> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/orders/segregate`,
				data: arg0,
				queryParams: queryParams,
			});
		}
		//* METHODS ROUTES TAB **/
		Routesg_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.routes_total>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routeTotal/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		/**
		 * HTTP POST /api/user/pageAndFilters
		 * Java method: org.eurecat.atlantis_cycle.coordination_module.rest.ajax.RouteController.User_getPageAndFilters
		 */
		Routesg_getPageAndFilters(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.ListPageAndFilters<Rest_logistics.routes_total>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routeTotal/pageAndFilters`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		Routesg_updatedriverroute(queryParams?: { id: number, driverid: number }): Rest.RestResponse<Rest.Page<Rest_logistics.routes_total>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/routeTotal/updatedriver`,
				queryParams: queryParams,
			});
		}

		Routesg_findtypepackaging(): Rest.RestResponse<Rest_logistics.typePackaging[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/packagingType`,
			});
		}
		//INCIDENCES
		Incidences_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.OrderIncidence>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/incidences/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		getAllDataIncidence(orderId: number): Rest.RestResponse<Rest_logistics.OrderIncidence[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/incidences/getAllData/` + orderId,
			});
		}

		getTypeIncidence(): Rest.RestResponse<Rest_logistics.IncidenceType[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/incidences/getTypeIncidence`
			});
		}

		markedincidences(orderId: number): Rest.RestResponse<boolean> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/incidences/markedincidences/` + orderId,
			});
		}

		// ORDERS PAYMENT - TRANSACTIONS
		OrdersPayment_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, endDate: string }): Rest.RestResponse<Rest.Page<Rest_logistics.OrderPayment>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/payment/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		Customer_getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest_logistics.Customer>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/page`,
				data: arg0,
			});
		}

		Customer_find(uid: string, queryParams?: { id?: number }): Rest.RestResponse<Customer> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/customer/${uid}`,
				queryParams: queryParams,
			});
		}


		Customer_create(arg0: Customer): Rest.RestResponse<Customer> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer`,
				data: arg0,
			});
		}

		Customer_update(arg0: number[], queryParams?: { status: boolean }): Rest.RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/customer`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Customer_changeStatus(arg0: number[], queryParams?: { status?: boolean }): Rest.RestResponse<any> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/customer/status`,
				queryParams: queryParams,
				data: arg0,
			});
		}

		Customer_delete(arg0: Customer): Rest.RestResponse<any> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/customer`,
				data: arg0,
			});
		}

		CustomerValiseCode_Create(arg0: CustomerValiseCode): Rest.RestResponse<CustomerValiseCode> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/customerValiseCode/create`,
				data: arg0,
			});
		}

		CustomerValiseCode_changeStatus(arg0: CustomerValiseCode): Rest.RestResponse<number> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/customer/customerValiseCode/status`,
				data: arg0,
			});
		}

		CustomerPickupAssigAuto_CreateAndUpdate(arg0: CustomerPickupAssigAuto): Rest.RestResponse<CustomerPickupAssigAuto> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/customerPickupAssigAuto/createAndUpdate`,
				data: arg0,
			});
		}

		CustomerPickupAssigAuto_Delete(arg0: CustomerPickupAssigAuto): Rest.RestResponse<CustomerPickupAssigAuto> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/customer/customerPickupAssigAuto/delete`,
				data: arg0,
			});
		}

		CustomerRoute_CreateAndUpdate(arg0: CustomerRoute): Rest.RestResponse<Rest.GenericResp<Rest_logistics.CustomerRoute>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/customerRoute/createAndUpdate`,
				data: arg0,
			});
		}
		CustomerRoute_Delete(arg0: CustomerRoute): Rest.RestResponse<CustomerRoute> {
			return this.httpClient.request({
				method: 'DELETE',
				url: uriEncoding`api/task/customer/customerRoute/delete`,
				data: arg0,
			});
		}
		/*
	  CustomerPickupAssigAuto_Update(arg0: CustomerPickupAssigAuto): Rest.RestResponse<CustomerPickupAssigAuto> {
			return this.httpClient.request({
				method: 'PUT',
				url: uriEncoding`api/task/customer/customerPickupAssigAuto/update`,
				data: arg0,
			});
		}*/

		Customer_regularCustomer(customer: Rest_logistics.Customer): Rest.RestResponse<boolean> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/regularCustomer`,
				data: customer
			});
		}

		Customer_getRoutesInter(arg0: Rest.ListPaginationRequest, queryParams?: { type: number }): Rest.RestResponse<Rest.Page<Rest_logistics.Customer>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/customer/customerroutesinter`,
				data: arg0,
				queryParams: queryParams
			});
		}

		//USER ROUTE
		UserRoute_getPage(arg0: Rest.ListPaginationRequest, queryParams?: { startDate: string, typeRoute: number }): Rest.RestResponse<Rest.Page<Rest_logistics.UserRoute>> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/userroute/page`,
				data: arg0,
				queryParams: queryParams,
			});
		}

		UserRoute_AddUpdateUserRouteLogInverseInter(queryParams?: { driverId: number, routeId: number, dateRoute: string }): Rest.RestResponse<Rest.GenericResp> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/userroute/addupdate`,
				queryParams: queryParams,
			});
		}

		UserRoute_deleteOrderLogInverseInter(queryParams?: { userRouteId: number }): Rest.RestResponse<Rest.GenericResp> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/userroute/delete`,
				queryParams: queryParams,
			});
		}

		PaymentMethod_getList(): Rest.RestResponse<PaymentMethod[]> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/paymentmethods`,
 			});
		}


		/* EXCEL MAPPING */
		ExcelMapping_create(arg0: any): Rest.RestResponse<Rest_logistics.ExcelMapping> {
			return this.httpClient.request({
				method: 'POST',
				url: uriEncoding`api/task/excelmapping`,
				data: arg0,
			});
		}

		ExcelMapping_find(arg0: string): Rest.RestResponse<ExcelMapping> {
			return this.httpClient.request({
				method: 'GET',
				url: uriEncoding`api/task/excelmapping/${arg0}`
			});
		}

	}




	function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
		let result = '';
		for (let i = 0; i < substitutions.length; i++) {
			result += template[i];
			result += encodeURIComponent(substitutions[i]);
		}
		result += template[template.length - 1];
		return result;

	}

	export enum filterRoutes {
		Route,
		Driver,
		Order
	}
	export enum StateRoute {
		uninitiated,
		started,
		finished,
		partiallycompleted
	}

	export function firstPageRequestRoutesInventory() {
		let t = {
			sortAsc: true,
			pageSize: 10,
			loadPermissions: true,
			filters: {},
		} as Rest.ListPaginationRequest;
		//t.filters.disabled = ["false"];
		return t;
	}
}
