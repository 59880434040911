import { Injectable } from '@angular/core';
import {EntityService} from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class VehicleService  extends EntityService<Rest.Vehicle>{

	client: Rest.RestApplicationClient;

	constructor(private jquery: JQueryHTTPClient) {
   	super();
    	this.client = new Rest.RestApplicationClient(jquery);
  	}

 	create(arg0: Rest.Vehicle): Rest.RestResponse<Rest.Vehicle> {
		return this.client.Vehicle_create(arg0);
	}

	update(arg0: Rest.Vehicle): Rest.RestResponse<Rest.Vehicle> {
		return this.client.Vehicle_update(arg0);
	}

	getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Vehicle>> {
		return this.client.Vehicle_getPage(arg0);
	}

	changeStatus(arg0: number[], queryParams?: { status?: boolean; }): Rest.RestResponse<void> {
		return this.client.Vehicle_changeStatus(arg0, queryParams);
	}

	findVehicle(vehicle: string, queryParams?: { vehicle?: number; }): Rest.RestResponse<Rest.Vehicle> {
		return this.client.Vehicle_findVehicle(vehicle, queryParams);
	}

	findVehicleByName(arg0): Rest.RestResponse<Rest.Vehicle[]> {
		return this.client.Vehicle_findVehicleByName(arg0);
	}

	getTreeVehicles(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.Vehicle>>{
  		return this.client.Vehicle_getTreeVehicles(arg0);
	}

}
