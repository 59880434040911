import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: 'app-automatic-apnconfiguration-ob22',
  templateUrl: './automatic-apnconfiguration-ob22.component.html',
  styleUrls: ['./automatic-apnconfiguration-ob22.component.css']
})
export class AutomaticAPNConfigurationOB22Component
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() automaticApn: Rest.AutomaticAPNConfigurationOB22;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.automaticApn);
    }
  }

  handleChange($event: any) {}
}
