import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { ConfirmationService, TreeNode } from "primeng/api";

import { FleetService } from "../../../rest/fleet.service";
import { VehicleService } from "../../../rest/vehicle.service";
import { VehiclesgroupService } from "../../../rest/vehiclesgroup.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import {
  NotificationsService,
  Severity,
} from "../../../services/notifications-service/notifications.service";
import { FleetFormComponent } from "../fleet-form/fleet-form.component";
import { FleetTreeComponent } from "../fleet-tree/fleet-tree.component";
import { GroupFormComponent } from "../group-form/group-form.component";
import { VehicleFormComponent } from "../vehicle-form/vehicle-form.component";
import { ActivatedRoute, ParamMap } from "@angular/router";

export interface FleetTreeNode extends TreeNode {
  entityType: "Fleet" | "Group" | "Vehicle";
  selected: boolean;
}

@Component({
  selector: "app-fleet-tree-edit",
  templateUrl: "./fleet-tree-edit.component.html",
  styleUrls: ["./fleet-tree-edit.component.css"],
  providers: [
    FleetService,
    VehiclesgroupService,
    VehicleService,
    ConfirmationService,
  ],
})
// export class FleetEditViewComponent implements OnChanges {
export class FleetEditViewComponent implements OnInit {
  @ViewChild(FleetTreeComponent, { static: true })
  fleetList: FleetTreeComponent;
  @ViewChild(FleetFormComponent, { static: true })
  fleetForm: FleetFormComponent;
  @ViewChild(GroupFormComponent, { static: true })
  groupForm: GroupFormComponent;
  @ViewChild(VehicleFormComponent, { static: true })
  vehicleForm: VehicleFormComponent;

  @Input() showStatusDialog: boolean;

  checkBoxDisabled: Map<string, boolean> = new Map<string, boolean>();

  fleetsChangeStatus: number[] = [];
  groupsChangeStatus: number[] = [];
  vehiclesChangeStatus: number[] = [];

  fleetService: FleetService;
  groupService: VehiclesgroupService;
  vehicleService: VehicleService;

  selectedEntities: Object[] = [];

  entities: string = this.i18n._("entities");

  constructor(
    fleetService: FleetService,
    groupService: VehiclesgroupService,
    vehicleService: VehicleService,
    protected confirmationService: ConfirmationService,
    public notificationsService: NotificationsService,
    protected i18n: I18nService,
    private route: ActivatedRoute
  ) {
    this.fleetService = fleetService;
    this.groupService = groupService;
    this.vehicleService = vehicleService;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let vid = +params.get("vehicle");
      if (vid) this.vehicleForm.show(false, { id: vid }, { _$update: true });
    });
  }

  notifyNewFleet(event) {
    this.fleetList.ngOnInit();
  }

  notifyNewGroup(event) {
    this.fleetList.ngOnInit();
  }

  notifyNewVehicle(event) {
    this.fleetList.ngOnInit();
  }

  refreshFleetList(event) {
    this.fleetList.ngOnInit();
  }

  selectNode() {
    this.fleetList.selectAfterChangeStatus = true;
  }

  toggleDeletionMode() {}

  deleteSelected() {}

  nodeSelected(node: FleetTreeNode) {
    this.fleetForm.display = false;
    this.groupForm.display = false;
    this.vehicleForm.display = false;

    if (node.entityType == "Fleet") {
      this.fleetForm.show(node.data, node);
    }
    if (node.entityType == "Group") {
      //if node is of type button, init the form vehicle group form as a new entity
      this.groupForm.show(node.type == "button", node.data, node);
    }
    if (node.entityType == "Vehicle") {
      //if node is of type button, init the form vehicle group form as a new entity
      this.vehicleForm.show(node.type == "button", node.data, node);
    }
  }

  // checkedEntities(entitiesSelected: TreeNode[]){
  //   this.selectedEntities = entitiesSelected;
  // }

  enableEntities(event: Map<string, boolean>) {
    this.checkBoxDisabled = event;
  }

  /** #ChangeStatus */
  /**Enviar las tres cadenas de entidades y no solo una, preparar la entidad padre (changestatus component) para recibirlos */
  changeStatus(event: FleetTreeNode[]) {
    for (var i = this.selectedEntities.length - 1; i >= 0; i--) {
      this.selectedEntities.splice(i, 1);
    }

    for (var a in event) {
      if (event[a].entityType == "Fleet") {
        this.fleetsChangeStatus.push(event[a].data.id);
      }
      if (event[a].entityType == "Group") {
        this.groupsChangeStatus.push(event[a].data.id);
      }
      if (event[a].entityType == "Vehicle") {
        this.vehiclesChangeStatus.push(event[a].data.id);
      }

      this.selectedEntities.push(event[a]);
    }

    this.fleetForm.display = false;
    this.groupForm.display = false;
    this.vehicleForm.display = false;

    this.showStatusDialog = true;
  }

  containsNode(node, list) {
    return list.some((x) => x === node); // true
  }

  refreshList(event) {
    // RICARD: Removed becuase was poping on every click in the node tree
    //this.notificationsService.add(Severity.success, this.i18n._(':)'), this.i18n._('Entities status changed'));
    this.fleetsChangeStatus = [];
    this.groupsChangeStatus = [];
    this.vehiclesChangeStatus = [];
    this.selectedEntities = [];
    this.showStatusDialog = false;
    this.fleetList.updateTreeData();
  }

  closeStatusDialog(event) {
    // this.fleetsChangeStatus = [];
    // this.groupsChangeStatus = [];
    // this.vehiclesChangeStatus = [];
    // this.selectedEntities = [];
    var close: boolean;
    close = event;
    this.showStatusDialog = close;
  }
  /***************** */

  /**Permissions */
  // permissions(event){
  //   this.fleetForm.permissions = event;
  // }
  /** */
}
