import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { VehiclebrandService } from 'app/rest/vehiclebrand.service';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { ClientService } from 'app/rest/client.service';
import { I18nService } from 'app/services/i18n/i18n.service';

import VehicleBrand = Rest.VehicleBrand;
import { RestExt } from 'app/services/rest-client-extension';

@Component({
    selector: 'app-brand-form',
    templateUrl: './brand-form.component.html',
    styleUrls: ['./brand-form.component.scss'],
    providers: [VehiclebrandService, IconService, ClientService],
    encapsulation: ViewEncapsulation.None,
})
export class BrandFormComponent
    extends EntityFormComponent<VehicleBrand>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    brandID: number;

    brandImage: any;
    brandImageFile: any;
    brandEncodedImage: string;
    newBrandImage: Rest.Icon;

    entityEnumImageBrand: Rest.IconEntityAssociationEnum = "VEHICLE_BRAND";

    iconImage: Rest.Icon;

    // Preservation of relationships
    clientRequestContext: Rest.ListPaginationRequest;
    /* *************************** */

    constructor(
        private brandService: VehiclebrandService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
        public translateService: TranslateService,
        private clientService: ClientService,
    ) {
        super(brandService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof BrandFormComponent
     */
    beforeShow() {
        // Presevation of relationships
        this.clientRequestContext = RestExt.firstPageRequestVehicleInventory();

        this.brandEncodedImage = '../../../../assets/images/UI_Elements/HD_transparent_picture.png'; //Reset the image to an empty png image from assets.

        this.brandID = this.entity.id;

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.translateService.instant('tableManagement.brands.createBrand');
        } else {
            this.title = this.translateService.instant('tableManagement.brands.editBrand');
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }

            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }

            // We are setting the value of the path of the image, depending if it's already set or not
            if (this.entity.logo != null) {
                this.brandEncodedImage = this.entity.logo.imageEncoded;
            }
        }
        /** */
    }

    /**
     * Takes a local image, and communicates with the API, in order to 
     * restore the client image.
     *
     * @param {*} event
     * @memberof BrandFormComponent
     */
    imageChange(event): void {

        const reader = new FileReader();
        this.brandImage = event.target.files[0];
        reader.readAsDataURL(this.brandImage);
        reader.onload = (event: any) => {
            console.log(reader.result);
            this.brandEncodedImage = event.target.result;
        };
    }

    saveChanges() {
        this.messages = [];

        this.entity.client = this.authenticationService.user.client;
        let brandString = JSON.stringify(this.entity);

        const that = this;
        if (this.isNew) {
            this.brandService
                .createBrand(brandString, this.brandImage)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        } else {
            this.brandService
                .updateBrand(brandString, this.brandImage)
                .then(
                    function (res) {
                        that.display = false;
                        that.return.emit(res);
                    },
                    (err) => {
                        console.log(err);
                        that.handleError(err, that);
                    }
                )
                .catch((e) => {
                    this.handleError(e, that);
                });
        }
    }
}

