import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';

@Pipe({ name: 'distance' })
export class DistanceFormatPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe, private authService: AuthenticationService) {
    }

    /**
     * Given a distance measure in system metric (Km), return the value in the user metric
     * @param value
     */
    systemMetricToUserMetric(value: number): number {
        const useKM = this.authService.user.metric == "INTERNATIONAL_SYSTEM_OF_UNIT";
        let conversionFactor = 1.0;
        let unitsString = "Km";
        if (!useKM) {
            return value * (1.0 / 1.609344);
        }
        return value;
    }

    /**
     * Given a distance measure in user metric, return the value in system metric (Km)
     * @param value
     */
    userMetricToSystemMetric(value: number): number {
        const useKM = this.authService.user.metric == "INTERNATIONAL_SYSTEM_OF_UNIT";
        let conversionFactor = 1.0;
        let unitsString = "Km";
        if (!useKM) {
            return 1.609344 * value;
        }
        return value;
    }


    transform(numericValue: number, addUnits: boolean): string {


        const useKM = this.authService.user.metric == "INTERNATIONAL_SYSTEM_OF_UNIT";
        let conversionFactor = 1.0;
        let unitsString = "Km";

        if (!useKM) {
            conversionFactor = 1.0 / 1.609344;
            unitsString = "Mil";
        }
        if (numericValue == null && addUnits) {
            return unitsString;
        }
        if (numericValue == null) {
            return null;
        }

        numericValue = numericValue * conversionFactor
        const numberStr = this.decimalPipe.transform(numericValue, "1.0-3");
        if (!addUnits) {
            numberStr;
        }

        return numberStr + " " + unitsString;
    }
}
