import { Injectable, OnDestroy } from "@angular/core";

import { Rest } from "../../../rest/rest_client";
import { DeviceService } from "../../../rest/device.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RealTimeDataService } from "../../../services/rt-data/rt-data.service";

@Injectable()
// TODO: Add Angular decorator.
export class RequestResponseListener implements OnDestroy {
  messageWatch: { [key: number]: any };
  protected alive: boolean = true;
  constructor(
    protected i18n: I18nService,
    protected realTimeDataService: RealTimeDataService,
    protected notificationService: NotificationsService,
    protected deviceService: DeviceService
  ) {}

  setup() {
    this.messageWatch = {};
    /**
     * When a request response is recieved check if there is any watch defined over it (it means that it is an actuatorChangeRequestResponse originated from this component).
     * If true, handle it, if not, ignore it.
     */
    this.realTimeDataService.requestResponse
      .takeWhile(() => this.alive)
      .subscribe((resp: Rest.ResponseMessage) => {
        for (let requestId in this.messageWatch) {
          if ((requestId as any) != resp.originalRequest.id) continue;
          this.messageWatch[requestId](resp);
          //Remove the watch
          delete this.messageWatch[requestId];
          break;
        }
      });
  }

  /**
   * Returning a promise object inside a pormise causes problems, we must wrap the promise in an array
   */
  sendRequest(request: Rest.RequestMessage, vid: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      //Send a request to the device
      this.deviceService
        .sendRequest(vid + "", request)
        .then((request: Rest.RequestMessage) => {
          this.realTimeDataService.watchRequestResponse(request.id, vid);
          let responsePromise = new Promise<Rest.ResponseMessage>(
            (rsv, rej) => {
              this.messageWatch[request.id] = function (r) {
                rsv(r);
              };
            }
          );
          resolve([responsePromise]);
        })
        .catch((e: any) => {
          reject(null);
        });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
