import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-command-storage-configuration-gmt200',
  templateUrl: './command-storage-configuration-gmt200.component.html',
  styleUrls: ['./command-storage-configuration-gmt200.component.css'],
})
export class CommandStorageConfigurationGmt200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() commandStorage: Rest.CommandStorageGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }
  modeOptions: any = [
    {label: "Delete the stored command",value:"DELETE_COMMAND"},
    {label: "Add the stored command",value:"ADD_COMMAND"},          
  ];
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.commandStorage);
    }
  }

}
