<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Temperature
                    Alarm (AT+GTTEM)</span>
            </div>
        </div>
    </p-header>

    <div class="row mt-3">

        <div class="col row dvcFields">
            <div class="col lblStyle">
                <form-input fieldName="Mode">
                </form-input>
            </div>
            <div class="col">
                <p-dropdown [options]="modeOptions" [(ngModel)]="temParameters.mode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Max Temperature">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="temParameters.maxTemperature" [min]="-20"
                            [max]="60"></p-spinner>
                        <span class="ui-inputgroup-addon">-20~60</span>
                        <span class="ui-inputgroup-addon">°C</span>
                    </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Min Temperature">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="temParameters.minTemperature" [min]="-20"
                            [max]="60"></p-spinner>
                        <span class="ui-inputgroup-addon">-20~60</span>
                        <span class="ui-inputgroup-addon">°C</span>
                    </div>
                </div>
            </div>
        
        </div>

        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Duration">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="temParameters.duration" [min]="0"
                            [max]="3600"></p-spinner>
                        <span class="ui-inputgroup-addon">0~3600</span>
                        <span class="ui-inputgroup-addon">sec</span>
                    </div>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Send Interval">
                    </form-input>
                </div>
                <div class="col">
                    <div class="ui-inputgroup">
                        <p-spinner size="5" step="1" [(ngModel)]="temParameters.sendInterval" [min]="0"
                            [max]="3600"></p-spinner>
                        <span class="ui-inputgroup-addon">0~3600</span>
                        <span class="ui-inputgroup-addon">sec</span>
                    </div>
                </div>
            </div>
        
        </div>
        
    </div>
</p-accordionTab>