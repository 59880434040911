 
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { UserService } from 'app/rest/user.service';
 import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { DocumentationService } from 'app/modules/vehicle-inventory/services/documentation.service';
import { Subscription } from 'rxjs';
import { RestExt } from 'app/services/rest-client-extension';
import { DocNoteComponent } from '../doc-note/doc-note.component';
import { DocDriverLicenseComponent } from '../doc-driver-license/doc-driver-license.component';
import { DocDriverPointsComponent } from '../doc-driver-points/doc-driver-points.component';
import { DriverpointsService } from 'app/modules/vehicle-inventory/services/driverpoints.service';
import { NoteService } from 'app/modules/vehicle-inventory/services/note.service';
import { Rest } from 'app/rest/rest_client';
@Component({
  selector: "app-upload-file-form",
  templateUrl: "./upload-file-form.component.html",
  styleUrls: ["./upload-file-form.component.scss"],
  providers:[DocumentationService, DriverpointsService, NoteService]
})
export class UploadFileFormComponent extends  EntityFormComponent<Rest.InvDocument> implements OnInit {

  @ViewChild('docNote') docNote: DocNoteComponent;
  @ViewChild('docDriverLicense') docDriverLicense: DocDriverLicenseComponent;
  @ViewChild('docDriverPoints') docDriverPoints: DocDriverPointsComponent;

  @Output() refreshTable: EventEmitter<void> = new EventEmitter<void>();
  @Input() renewalType: string;
  @Input() title: string;

   isDialogVisible=false;
  optionsFilterType: any[];
  documentFile: any;
  driverPoints: Rest.InvDriverPoints;
  note: Rest.InvNote;
  fileName ="";
  constructor(
      private documentService: DocumentationService,
      notificationsService: NotificationsService,
      protected i18n: I18nService,
      private authenticationService: AuthenticationService,
      public translateService: TranslateService,
      public userService: UserService,
      private driverPointsService: DriverpointsService,
      private noteService: NoteService
  ) {
      super(documentService, notificationsService, i18n, authenticationService);
  }

  ngOnInit() {
      super.ngOnInit();
      
  }
  public showDialog( ){
    this.display = true;
   }
   
   public changeDocument(event: any) {
    this.documentFile = event.target.files[0];
 
    this.fileName = this.documentFile.name;
}
  closeFormDialog(): void {
    this.display = false;
    this.refreshTable.emit();
  }
  protected beforeShow(): void {
    if(this.isNew){
        this.title = this.translateService.instant("document.form.createDocument");
    }else{
        this.title = this.translateService.instant("document.form.editDocument");
    }
}

uploadFile(): void {
  var that = this;
    this.documentService.renewalFiles(this.documentFile, this.renewalType).then((response) => {
      that.notificationsService.add(Severity.success, "", this.translateService.instant('general.updateSuccessfull'));
      that.display = false;
      that.refreshTable.emit();
    }).catch((error) => {
      that.notificationsService.add(Severity.error, "", this.translateService.instant('general.updateError'));
    });
}


}


