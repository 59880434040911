import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { RestExt } from 'app/services/rest-client-extension';


@Component({
  selector: 'app-user-defined-functions-configuration-gmt200',
  templateUrl: './user-defined-functions-configuration-gmt200.component.html',
  styleUrls: ['./user-defined-functions-configuration-gmt200.component.css'],
})
export class UserDefinedFunctionsConfigurationGmt200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() userDefinedFunction: Rest.UserDefinedFunctionGMT200;
  @Input() firmware: RestExt.FirmwareVersion;
  constructor(protected i18n: I18nService) { super(i18n); }
  modeOptions: any = [
    {label: "Disable the group.",value:"DISABLE_FUNCTIONS"},
    {label: "Enable the group.",value:"ENABLE_FUNCTIONS"},
    {label: "Delete the group.", value:"DELETE_FUNCTIONS"},          
  ];

  modeAck: any = [
    {label:"Do not send acknowledgment message when execute the stored command.",value:"NOT_SEND_ACK_MESSAGE"},
    {label:"Send acknowledgment message when execute the stored command.", value:"SEND_ACK_MESSAGE"},
  ]
  ngOnInit() {
    console.log(JSON.stringify(this.firmware))
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.userDefinedFunction);
    }
  }

}
