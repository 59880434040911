<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">SOS CONFIGURATION
          (SOS)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Sos Mode">
        </form-input>
      </div>
      <div class="col">
        <p-dropdown [options]="SosOptions" [(ngModel)]="sos.mode" [style]="{'width':'100%','min-width':'100px'}">
        </p-dropdown>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Phone Numbers (,)">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <input type="text" pInputText [(ngModel)]="sos.phoneNumbers" class="txtbStyle">
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>