<p-accordionTab>  
    <p-header>
      <div class="ui-helper-clearfix">
        <div class="left">
          <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >GPS CONTROLLED TIME (SENDS)</span>
        </div>
      </div>
    </p-header>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input  fieldName="Time Duration" >
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="sends.timeDuration" [min]="0" [max]="300"></p-spinner>
          <span class="ui-inputgroup-addon">0~300</span>
          <span class="ui-inputgroup-addon">min</span>
        </div>
      </div>
    </div>  
  </p-accordionTab>