<p-table [value]="paginationResult.entities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [rows]="paginationRequest.pageSize" [totalRecords]="paginationResult.filteredEntities"
    [rowsPerPageOptions]="[10,20,30,40,50]" [paginator]="true" [(selection)]="selectedEntities" [loading]="loading"
    [rowHover]="true" [showCurrentPageReport]="true" [autoLayout]="true" dataKey="id"
    currentPageReportTemplate="{first} - {last} {{ 'table.of' | translate }} {totalRecords}" class="custom-table"
    styleClass="p-datatable-striped">

    <!-- #region TABLE TITLE DEFINITION -->
    <ng-template pTemplate="caption">
        <div *ngIf="showTitle" class="d-flex align-items-center justify-content-center text-uppercase mb-5 table-title">
            {{ 'tableManagement.financing.financingsManagement' | translate }}
        </div>
    </ng-template>
    <!-- #endregion TABLE TITE DEFINITION -->

    <!-- #region TABLE COLUMN DEFINITION -->
    <ng-template pTemplate="header">
        <tr>

            <!-- SELECT -->
            <th class="checkbox-column">
                <div class="d-flex justify-content-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
            </th>
                        <!-- CONTRACT -->
                        <th pSortableColumn="contract">
                            <div class="d-flex align-items-center h-100">
                                <!-- COLUMN TITLE -->
                                <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                                    {{ 'tableManagement.financing.contract' | translate }}
                                </div>
            
                                <!-- COLUMN SORT && FILTER ICONS -->
                                <div class="d-flex align-items-center h-100">
                                    <p-sortIcon field="contract"></p-sortIcon>
                                    <p-columnFilter field="contract" type="text" matchMode="contains"
                                        placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                                        [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                                </div>
                            </div>
                        </th>
                        <!-- PROVIDER -->
                        <th pSortableColumn="provider.name">
                            <div class="d-flex align-items-center h-100">
            
                                <!-- COLUMN TITLE -->
                                <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                                    {{ 'tableManagement.providers.provider' | translate }}
                                </div>
            
                                <!-- COLUMN SORT && FILTER ICONS -->
                                <div class="d-flex align-items-center h-100">
                                    <p-sortIcon field="provider.name"></p-sortIcon>
                                    <p-columnFilter field="provider" type="text" matchMode="contains"
                                        placeholder="{{ 'general.search' | translate }}" display="menu" [showMatchModes]="false"
                                        [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                                </div>
            
                            </div>
                        </th>




            <!-- START DATE -->
            <th pSortableColumn="startDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.start' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="startDate"></p-sortIcon>

                    </div>
                </div>
            </th>

            <!-- END DATE -->
            <th pSortableColumn="endDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.end' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="endDate"></p-sortIcon>

                    </div>

                </div>
            </th>

            <!-- CANCELLATION DATE -->
            <th pSortableColumn="endDate">
                <div class="d-flex align-items-center h-100">

                    <!-- COLUMN TITLE -->
                    <div class="d-flex align-items-center align-items-center justify-content-center flex-fill h-100">
                        {{ 'tableManagement.financing.cancelationDate' | translate }}
                    </div>

                    <!-- COLUMN SORT && FILTER ICONS -->
                    <div class="d-flex align-items-center h-100">
                        <p-sortIcon field="endDate"></p-sortIcon>

                    </div>

                </div>
            </th>



        </tr>
    </ng-template>
    <!-- #endregion TABLE COLUMN DEFINITION -->

    <!-- #region TABLE BODY -->
    <ng-template pTemplate="body" let-entity>
        <tr class="p-selectable-row custom-rows" (dblclick)="editDialog(entity)">

            <!-- SELECT -->
            <td>
                <div class="d-flex justify-content-center">
                    <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                </div>
            </td>

            <!-- CONTRACT -->
            <td>
                {{ entity.contract }}
            </td>
            <!-- PROVIDER -->
            <td>
                {{entity.provider?.name}}
            </td>
       
            <!-- START DATE -->
            <td>
                {{ entity.startDate | date: 'dd/MM/yyyy' }}
            </td>

            <!-- END DATE -->
            <td>
                {{ entity.endDate | date: 'dd/MM/yyyy' }}
            </td>

            <!-- CANCELATION DATE -->
            <td>
                {{ entity.cancellationDate | date: 'dd/MM/yyyy' }}
            </td>


        </tr>

    </ng-template>
    <!-- #endregion TABLE BODY -->

    <!-- #region TABLE EMPTY MESSAGE -->
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" class="p-0">
                <div class="d-flex align-items-center justify-content-center h-100 p-5 custom-empty-message" i18n>
                    {{ 'table.tableEmptyMessage' | translate }}!
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE EMPTY MESSAGE -->

    <!-- #region TABLE FOOTER -->
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="8" class="p-0">
                <div class="d-flex flex-row align-items-center p-3 gap-3">

                    <!-- ADD FINE BUTTON -->
                    <app-table-add-button *ngIf="isCreateGranted"
                        [text]=" 'tableManagement.financing.financings' | translate"
                        (btnClick)="create()"></app-table-add-button>

                    <!-- CHANGE STATUS BUTTON -->
                    <app-table-change-status-button (btnClick)="changeStatus()"
                        [isDisabled]=" selectedEntities.length === 0 || !isDisabledGranted"></app-table-change-status-button>

                    <!-- ADD DUPLICATE BUTTON -->
                    <app-table-duplicate-button [text]=" 'tableManagement.financing.financings' | translate"
                        [isDisabled]="selectedEntities.length !== 1 || !isCreateGranted"
                        (btnClick)="duplicate(selectedEntities)"></app-table-duplicate-button>


                    <app-table-excel-button class="buttonTableExcel"
                        (btnClick)="exportExcel()"></app-table-excel-button>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- #endregion TABLE FOOTER -->
</p-table>

<!-- FINE FORM -->
<app-financing-form [vehicle]="vehicle" (return)="refreshList($event,false)"></app-financing-form>