<p-dialog [responsive]="true"  [contentStyle]="{minHeight: '250px'}" [header]="namePoint" [(visible)]="PopUpOpen" dismissableMask= true modal=true >

  <div class="d-flex justify-content-between align-items-center">
    <div class="flex-grow-1 m-2">
      <div>
        <label id='startDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
          {{ 'general.startDate' | translate }}:
        </label>
        <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayIniSelected" (onSelect)="loadData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
      </div>
    </div>
  
    <div class="flex-grow-1 m-2">
      <div>
        <label id='endDateLabel' class='form-label mb-0 me-3 custom-label sat-label'>
          {{ 'general.endDate' | translate }}:
        </label>
        <p-calendar appendTo="body" [locale]="i18n.calendarLocale" [placeholder]="i18n.dateFormat" [(ngModel)]="dayEndSelected" (onSelect)="loadData()" dateFormat={{i18n.dateFormat}} showIcon="true"></p-calendar>
      </div>
    </div>
    <div>
      <div class="buttonTableExcel text-end">
        <button class="btn btn-success btn-sm" (click)="exportExcel()" pButton type="button" i18n-label label="Excel"></button>
      </div>
    </div>
  </div>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />


  <!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
  1. Check correct functionality;
  2. Remove unused code;
  3. Add translations;
-->

  <!-- PrimeNG DataTable -->
  <p-table id="dt" [value]="rows"
           [rowHover]="true"
           [autoLayout]="true"
           *ngIf="PopUpOpen"
  >

    <!-- Table Column/Header Definition -->
    <ng-template pTemplate="header" let-columns>
      <tr>

        <th *ngFor="let col of cols" [hidden]="!col.visible">
          <div>
            <a  [title]="col.header">{{col.header}}</a>

            <!--COLUMN WIH FILTER-->
            <div *ngIf="col.filter === true && col.field !== sortField" >
              <i style="margin: 2px;" class="fa fa-filter" aria-hidden="true"></i>
            </div>

            <!--COLUMN WIH FILTER AND ORDER-->
            <div  *ngIf="col.filter === true && col.field === sortField">

              <i style="margin: 2px; "class="fa fa-filter" aria-hidden="true"></i>
              <div *ngIf="sortOrder === 1"><!--ASC-->
                <i style="margin: 2px; "class="fa fa-sort-up" aria-hidden="true"></i>
              </div>
              <div *ngIf="sortOrder === -1"><!--DESC-->
                <i style="margin: 2px; "class="fa fa-sort-down" aria-hidden="true"></i>
              </div>

            </div>

            <!--COLUMN ORDER-->
            <div *ngIf="col.filter === false && col.field === sortField">

              <div *ngIf="sortOrder === 1"><!--ASC-->
                <i style="margin: 2px; "class="fa fa-sort-up" aria-hidden="true"></i>
              </div>
              <div *ngIf="sortOrder === -1"><!--DESC-->
                <i style="margin: 2px; "class="fa fa-sort-down" aria-hidden="true"></i>
              </div>

            </div>

          </div>

        </th>

      </tr>
    </ng-template>

    <!-- Table Row Definition -->
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>

        <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">

          <td *ngSwitchCase="'Viewtype'" [hidden]="!col.visible">
            <div  [title]="rowData.Viewtype" class="overflow ellipsis">
              {{rowData.Viewtype}}
            </div>
          </td>

          <td *ngSwitchCase="'visitDate'" [hidden]="!col.visible">
            <div [title]="rowData.visitDate | date: 'short'" class="overflow ellipsis">
              {{rowData.visitDate | date: 'short'}}
            </div>
          </td>

          <td *ngSwitchCase="'timeOverTheLimit'" [hidden]="!col.visible">
            <div [title]="rowData.timeOverTheLimit | millisToHumanReadable" class="overflow ellipsis">
              {{rowData.timeOverTheLimit | millisToHumanReadable}}
            </div>
          </td>

          <td *ngSwitchCase="'vehicle'" [hidden]="!col.visible">
            <div [title]="rowData.vehicle.name" class="overflow ellipsis">
              {{rowData.vehicle.name}}
            </div>
          </td>

          <td *ngSwitchCase="'group'" [hidden]="!col.visible">
            <div [title]="rowData.group.name" class="overflow ellipsis">
              {{rowData.group.name}}
            </div>
          </td>

          <td *ngSwitchCase="'endVisitDate'" [hidden]="!col.visible">
            <div [title]="rowData.endVisitDate | date: 'short'" class="overflow ellipsis">
              {{rowData.endVisitDate !== 0 ? (rowData.endVisitDate | date: 'short') : '' }}
            </div>
          </td>

          <td *ngSwitchCase="'durationVisit'" [hidden]="!col.visible">
            <div [title]="rowData.durationVisit" class="overflow ellipsis">
              {{rowData.durationVisit}}
            </div>
          </td>

          <td *ngSwitchCase="'kmVisit'" [hidden]="!col.visible">
            <div [title]="rowData.kmVisit" class="overflow ellipsis">
              {{rowData.kmVisit}}
            </div>
          </td>


        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" >
      <tr>
        <td colspan="7">
          <p i18n>No records found</p>
        </td>
      </tr>
    </ng-template>

  </p-table>


  <!--<p-dataTable  [value]="rows" [sortField]="sortField" [sortOrder]="sortOrder"  *ngIf="PopUpOpen" >

    <p-column *ngFor="let col of cols" [field]="col.field" [header]="col.header" [hidden]="!col.visible">

        &lt;!&ndash; __________________________________COLUMN__________________________________ &ndash;&gt;

        &lt;!&ndash; COLUMN STANDARD &ndash;&gt;
        <ng-template *ngIf="col.filter === false && col.field !== sortField" pTemplate="header" let-col >
            <a  [title]="col.header">{{col.header}}</a>
        </ng-template>

        &lt;!&ndash; COLUMN WIH FILTER &ndash;&gt;
        <ng-template *ngIf="col.filter === true && col.field !== sortField" pTemplate="header" let-col >
            <a [title]="col.header">{{col.header}}</a><i style="margin: 2px;"class="fa fa-filter" aria-hidden="true"></i>
        </ng-template>

        &lt;!&ndash; COLUMN WIH FILTER AND ORDER&ndash;&gt;
        <ng-template  *ngIf="col.filter === true && col.field === sortField" pTemplate="header" let-col >
            <a [title]="col.header">{{col.header}}</a>
            <i style="margin: 2px;"class="fa fa-filter" aria-hidden="true"></i>
            <div *ngIf="sortOrder === 1">&lt;!&ndash;ASC&ndash;&gt;
                <i style="margin: 2px;"class="fa fa-sort-up" aria-hidden="true"></i>
            </div>
            <div *ngIf="sortOrder === -1">&lt;!&ndash;DESC&ndash;&gt;
                <i style="margin: 2px;"class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </ng-template>

        &lt;!&ndash; COLUMN ORDER&ndash;&gt;
        <ng-template   *ngIf="col.filter === false && col.field === sortField" pTemplate="header" let-col >
            <a [title]="col.header">{{col.header}}</a>
            <div *ngIf="sortOrder === 1">&lt;!&ndash;ASC&ndash;&gt;
                <i style="margin: 2px;"class="fa fa-sort-up" aria-hidden="true"></i>
            </div>
            <div *ngIf="sortOrder === -1">&lt;!&ndash;DESC&ndash;&gt;
                <i style="margin: 2px;"class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </ng-template>



        &lt;!&ndash; ___________________________________ROWS___________________________________ &ndash;&gt;

        &lt;!&ndash;CUSTOM ROWS&ndash;&gt;
        <ng-template *ngIf="col.field === 'Viewtype'" let-row="rowData" pTemplate="body">
            <div  [title]="row.Viewtype" class="overflow ellipsis">
                {{row.Viewtype}}
                &lt;!&ndash;   {{row.lastMaintenanceDate | date: 'dd/MM/yyyy'}} &ndash;&gt;
            </div>
        </ng-template>
        <ng-template *ngIf="col.field === 'visitDate'" let-row="rowData" pTemplate="body">
            <div [title]="row.visitDate | date: 'short'" class="overflow ellipsis">
                {{row.visitDate | date: 'short'}}
                &lt;!&ndash;   {{row.lastMaintenanceDate | date: 'dd/MM/yyyy'}} &ndash;&gt;
            </div>
        </ng-template>
        <ng-template *ngIf="col.field === 'timeOverTheLimit'" let-row="rowData" pTemplate="body">
            <div [title]="row.timeOverTheLimit | millisToHumanReadable" class="overflow ellipsis">
                {{row.timeOverTheLimit | millisToHumanReadable}}
                &lt;!&ndash;   {{row.lastMaintenanceDate | date: 'dd/MM/yyyy'}} &ndash;&gt;
            </div>
        </ng-template>
        <ng-template *ngIf="col.field === 'vehicle'" let-row="rowData" pTemplate="body">
            <div [title]="row.vehicle.name" class="overflow ellipsis">
                {{row.vehicle.name}}
                &lt;!&ndash;   {{row.lastMaintenanceDate | date: 'dd/MM/yyyy'}} &ndash;&gt;
            </div>
        </ng-template>
        <ng-template *ngIf="col.field === 'group'" let-row="rowData" pTemplate="body">
            <div [title]="row.group.name" class="overflow ellipsis">
                {{row.group.name}}
                &lt;!&ndash;   {{row.lastMaintenanceDate | date: 'dd/MM/yyyy'}} &ndash;&gt;
            </div>
        </ng-template>


    </p-column>
</p-dataTable>-->
<p-footer >
  <button pButton type="button" (click)="closeButton()" i18n-label label="Tancar"></button>
</p-footer>
</p-dialog>
