<p-confirmDialog [baseZIndex]="9999" i18n-acceptLabel acceptLabel="Yes" i18n-header header="Confirmation" icon="fa fa-exclamation-triangle"
></p-confirmDialog>

<p-dialog i18n-header header="Editar configuració"
          [(visible)]="display"
          [contentStyle]="{height: '100%'}"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '70vw'}"
          [modal]="true"
          styleClass="dialog"
          dismissableMask=true
>

  <!-- LEFT COLUMN: Configurations List -->
  <div class="mainColumn1">
        <div style="text-align: center; margin:10px;">
            <h4 i18n>Llista configuracions</h4>
        </div>
        <div *ngFor="let item of allConfigurations; let idx = index">
            <div class="configItem">
                <button pButton class="ui-button-secondary titleButton" [label]="item.name"
                    (click)="confirmChangeConfiguration(item.id)"></button>
                <button pButton type="button" class="deleteButton" (click)="confirmDeleteConfiguration(idx)" icon="fa fa-times"></button>
            </div>
        </div>
        <div style="text-align: center; margin:10px;">
            <button pButton icon="fa fa-plus" i18n-label label="Nou" (click)="loadBaseConfiguration()"></button>
        </div>
    </div>

  <!-- RIGHT COLUMN: Current/New Configuration Editor -->
  <div *ngIf="currentConfiguration === null" class="mainColumn2" style="width: 500px"></div>

  <div *ngIf="currentConfiguration !== null" class="mainColumn2">

    <div style="margin-bottom: 2%;">

      <h4 class="h4NoJump" style="margin-right: 2%;" i18n>Name:</h4>
      <input type="text" pInputText size="50" i18n-placeholder placeholder="New Configuration" style="width:auto" [(ngModel)]="currentConfiguration.name">
    </div>

    <h4 i18n style="margin-bottom: 2%;">Columns:</h4>
    <div *ngFor="let item of currentConfiguration.columns">

      <!-- ___ controlPoint ___ -->
      <div *ngIf="item.id === 'controlPoint'">
        <p-panel i18n-header header="Control Point Group" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

            <!-- Columna -->
            <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">

              <ng-template pTemplate="icons">
                <div style="display:inline-block;"><i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Only points selected will be show."></i></div>
                <div style="float:right">
                  <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true" disabled="true"></p-checkbox>
                </div>
              </ng-template>

                <paged-picklist
                  [paginationRequest]="zonesFilter"
                  [pageFunction]="this.categoryService.getPage.bind(this.categoryService)"
                  labelProperty="name"
                  [(rightListEntities)]="rightListEntities">
                </paged-picklist>

              </p-panel>

              <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
                <ng-template pTemplate="icons">

                  <div style="display:inline-block;">
                    <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Name (Ascending or Descending)."></i>
                  </div>

                  <div style="float:right">
                    <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
                  </div>

                </ng-template>

                <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>

              </p-panel>
          </p-panel>
      </div>

      <!-- ___ Last Maintenance Date ___ -->
      <div *ngIf="item.id === 'lastMaintenanceDate'">
        <p-panel i18n-header header="Last Maintenance Date" [toggleable]="true" [collapsed]="!item.visible">

          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;"><i class="fa fa-info iconInsidePanel" tooltipPosition="right" i18n-pTooltip pTooltip="Filter by a difference of minutes from now."></i></div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>

            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Maintenance Left Time, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                  <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true"
                      (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>

        </p-panel>
      </div>

      <!-- ___ Maintenance Left Time ___ -->
      <div *ngIf="item.id === 'maintenanceLeftTime'">
        <p-panel i18n-header header="Maintenance Left Time" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by amount of minutes."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions" ></p-dropdown>

            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;"><i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Maintenance Left Time, from less to more."></i></div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div>

      <!-- ___ Last Vigilance Date ___ -->
      <div *ngIf="item.id === 'lastVigilanceDate'">
        <p-panel i18n-header header="Last Vigilance Date" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by a difference of minutes from now."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>
            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Vigilance Date, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div>

      <!-- ___ Vigilance Left Time ___ -->
      <div *ngIf="item.id === 'vigilanceLeftTime'">
        <p-panel i18n-header header="Vigilance Left Time" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by amount of minutes."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>

            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Vigilance Left Time, from less to more."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>

          </p-panel>
        </p-panel>
      </div>

      <!-- ___ Minor Time Remaining ___ -->
      <div *ngIf="item.id === 'minorTimeRemaining'">
        <p-panel i18n-header header="Minor Time Remaining" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by amount of minutes."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>

            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Minor Time Remaining, from less to more."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div>

      <!-- ___ enter zone date ___ -->
      <!--<div *ngIf="item.id === 'enterZoneDate'">
        <p-panel i18n-header header="Enter Zone Date" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by a difference of minutes from now."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>
            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Vigilance Date, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div>-->

      <!-- ___ leave zone date ___ -->
      <!--<div *ngIf="item.id === 'leaveZoneDate'">
        <p-panel i18n-header header="Leave Zone Date" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>


          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by a difference of minutes from now."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>
            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Vigilance Date, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div> -->

      <!-- ___ duration in zone ___ -->
      <!--<div *ngIf="item.id === 'durationTimeInZone'">
        <p-panel i18n-header header="Duration Inside Zone" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>


          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by a difference of minutes from now."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>
            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Vigilance Date, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div> -->

      <!-- ___ mileage inside zone ___ -->
      <!-- <div *ngIf="item.id === 'mileageInZone'">
        <p-panel i18n-header header="Mileage inside zone" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Filter by a difference of minutes from now."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="fileterOptions"></p-dropdown>
            <div class="input-group">
              <div class="input-group2">
                <input class="inputFilter" type="number" min="0" oninput="validity.valid||(value='');" pInputText i18n-placeholder placeholder="minutes">
                <span class="input-group-addon" i18n>minutes</span>
              </div>
            </div>
          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Order by column Last Vigilance Date, from most recent to oldest."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>
              </div>
            </ng-template>

            <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
          </p-panel>
        </p-panel>
      </div> -->

      

      <!-- ___ Vehicle ___ -->
      <div *ngIf="item.id === 'vehicle' ">
        <p-panel i18n-header header="Vehicle" [toggleable]="true" [collapsed]="!item.visible">
          <ng-template pTemplate="icons">
            <div style="float:right">
              <p-checkbox name="Visible" [(ngModel)]="item.visible" (onChange)="transferToGroup($event)" binary="true"></p-checkbox>
            </div>
          </ng-template>

          <!-- Columna -->
          <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active ">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Only vehicles selected will be shown. Selecting a group will select automatically the vehicles on it and selecting a fleet will select automatically the groups belongs and consequently the vehicles assigned."></i>
              </div>

              <div style="float:right">
                <p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true" disabled="true"></p-checkbox>
              </div>
            </ng-template>

            <app-fleet-tree [viewDisableds]=false [(selection)]="fleetsGroupsAndVehiclesSelected" [selectableEntities]=false [cascadeSelection]=true></app-fleet-tree>

          </p-panel>

          <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="true">
            <ng-template pTemplate="icons">
              <div style="display:inline-block;">
                <i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="You can order by vehicle or group name(Ascending or Descending)."></i>
              </div>

              <div style="float:right"></div>
            </ng-template>

            <div style="margin:5px; width:100%">
              <p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox>Vehicle

              <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc"></p-dropdown>
            </div>

            <div *ngFor="let itemInsideVehicle of currentConfiguration.columns">
              <div *ngIf="itemInsideVehicle.id === 'group'" style="margin:5px; width:100%">
                <p-checkbox name="2" [(ngModel)]="itemInsideVehicle.order.active" binary="true" (onChange)="OrderChanged($event.value,itemInsideVehicle.id, itemInsideVehicle.order.active)"></p-checkbox>

                Group

                <p-dropdown class="inputFilter2" [options]="orderOptions" [(ngModel)]="itemInsideVehicle.order.asc"></p-dropdown>
              </div>
            </div>
          </p-panel>
        </p-panel>
      </div>

            <!-- ___ Group ___
        <div *ngIf="item.id == 'group'">
            <p-panel i18n-header header="group" [toggleable]="true" [collapsed]="!item.visible">
                <p-header>
                    <div style="float:right"><p-checkbox name="Visible" [(ngModel)]="item.visible" binary="true"></p-checkbox></div>
                </p-header>



                    <p-panel i18n-header header="Filter" [toggleable]="true" [collapsed]="!item.filter.active">
                        <p-header>
                            <div style="display:inline-block;"><i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="XXXXXXXXXXXXXXXXXXXXXXXXX"></i></div><div style="float:right"><p-checkbox name="1" [(ngModel)]="item.filter.active" binary="true"></p-checkbox></div>
                        </p-header>

                        <p-dropdown class="inputFilter" [options]="fileterOptions" [(ngModel)]="item.filter.BiggerThan" ></p-dropdown>
                        <input class="inputFilter" type="text" pInputText i18n-placeholder placeholder="minutes" [(ngModel)]="item.filter.time">
                    </p-panel>
                    <p-panel i18n-header header="Order" [toggleable]="true" [collapsed]="!item.order.active">
                        <p-header>
                            <div style="display:inline-block;"><i i18n-pTooltip class="fa fa-info iconInsidePanel" tooltipPosition="right" pTooltip="Ascending and Descending"></i></div><div style="float:right"><p-checkbox name="2" [(ngModel)]="item.order.active" binary="true" (onChange)="OrderChanged($event.value,item.id, item.order.active)"></p-checkbox></div>
                        </p-header>
                        <p-dropdown class="inputFilter" [options]="orderOptions" [(ngModel)]="item.order.asc" ></p-dropdown>
                    </p-panel>
            </p-panel>
        </div>-->

    </div>

    <div style="margin-top: 20px;"></div>

    <div class="h4NoJump">
      <div style="float: left">
        <h4 style="display: inline-block" i18n>Coloring</h4>
      </div>

      <div style="margin-left: 20px; float: right">
        <p-inputSwitch i18n-onLabel i18n-offLabel onLabel="Yes" offLabel="No" [(ngModel)]="coloring"></p-inputSwitch>
      </div>
    </div>

    <div class="colorColumn1" *ngIf="coloring" style="margin-bottom: 20px;">
      <div class="colorBox"
           *ngFor="let color of currentConfiguration.coloring.info"
           [ngStyle]="{'background-color':color.value}">
        {{color.condition}}
      </div>
    </div>

    <div style="margin-top: 20px;">
      
    </div>

    <div class="container">
       

      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Rol</h4>
        </div>

        <div class="col">
          <p-dropdown [options]="optionsRolColoring" [(ngModel)]="currentConfiguration.coloring"></p-dropdown>
        </div>

      </div>
      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Vigilance time limit</h4>
        </div>

        <div class="col">
          <input type="number" pInputText size="50" i18n-placeholder placeholder="Vigilance time limit" style="width:auto" [(ngModel)]="currentConfiguration.vigilanceTimeLimit">
          <span class="hours-caret" style="margin-left: 1%;">h</span>
        </div>

      </div>
      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Maintenance time limit</h4>
        </div>

        <div class="col">
          <input type="number" pInputText size="50" i18n-placeholder placeholder="Maintenance time limit" style="width:auto" [(ngModel)]="currentConfiguration.maintenanceTimeLimit">
          <span class="hours-caret" style="margin-left: 1%">h</span>
        </div>

      </div>
      <!--
      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Maintenance vehicle contributes to vigilance</h4>
        </div>

        <div class="col">
            <p-inputSwitch [(ngModel)]="currentConfiguration.maintenanceContributesToVigilance"></p-inputSwitch>
        </div>

      </div>-->

      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Show table</h4>
        </div>

        <div class="col">
          <div style="float:left; width:100%">
          <p-inputSwitch [(ngModel)]="currentConfiguration.showTable"></p-inputSwitch>
          </div>
        </div>

      </div>

      <div class="row align-items-center" style="margin-top: 3%;">

        <div class="col">
          <h4 i18n>Show map</h4>
        </div>

        <div class="col">
          <div style="float:left; width:100%">
            <p-inputSwitch [(ngModel)]="currentConfiguration.showMap"></p-inputSwitch>
          </div>
        </div>

      </div>
    </div>


        <!-- DISABLED FUNCTIONALITY
    <h3 i18n>The map shows only visible vehicles markers</h3>
    <div style="float:left; width:100%"><p-inputSwitch i18n-onLabel i18n-offLabel onLabel="Yes" offLabel="No" [(ngModel)]="currentConfiguration.onlyVisisbleVehicles"></p-inputSwitch></div>
    -->


    </div>
    <p-footer>

            <div *ngIf="currentConfiguration !== null" >

                    <button pButton class="ui-button-secondary" i18n-label
                    label="Cancel" (click)="confirmCancelConfigEdit()"></button>
                    <button pButton style="text-align: center;" i18n-label
                    label="Save" (click)="confirmSaveConfiguration()"></button>
           </div>

    </p-footer>
</p-dialog>
