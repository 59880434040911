import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges,ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';

@Component({
  selector: 'app-tow-alarm-information-form',
  templateUrl: './tow-alarm-information-form.component.html',
  styleUrls: ['./tow-alarm-information-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TowAlarmInformationFormComponent implements OnInit, OnChanges  {

  @Input() towAlarm: Rest.TowAlarm;
  @ViewChild('towAlarmDiv', { static: true }) towAlarmDiv;  

  constructor() { }

  ngOnInit() {
    if(this.towAlarm!=null){this.disableAnalogInputDIV(!this.towAlarm.towEnable)}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.towAlarmDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.towAlarmDiv.nativeElement.classList.remove('disableDIV');
    }
  }

  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }

}
