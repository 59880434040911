import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild,} from "@angular/core";
import {Rest} from "../../../../../rest/rest_client";
import {RealtimeService} from "../../../../../rest/realtime.service";
import {I18nService} from "../../../../../services/i18n/i18n.service";
import {RealTimeDataService} from "../../../../../services/rt-data/rt-data.service";
import {RestExt} from "../../../../../services/rest-client-extension";
import {CustomMapComponent} from "../../../../shared/custom-map/custom-map.component";
import {SMSComponent} from "../sms/sms.component";
import {CercaliaMapService} from "../../../../../services/cercalia-map/cercalia-map.service";
import {Modal} from 'bootstrap';
import { AuthenticationService } from '../../../../../core/services/authentication/authentication.service';



@Component({
  selector: "app-vehicle-details",
  templateUrl: "./vehicle-details.component.html",
  styleUrls: ["./vehicle-details.component.css"],
  providers: [RealtimeService, CercaliaMapService],
})
export class VehicleDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: Rest.VehicleDetailsSettings;
  @Input() vehicle: Rest.Vehicle;
  info: RestExt.ExtendedVehicleDataMessage;
  @ViewChild(CustomMapComponent, {static: true}) map: CustomMapComponent;
  @ViewChild(SMSComponent, {static: true}) sms: SMSComponent;
  mapID: string;
  stringArrwStyle: string = null;
  gpsIconColor: string = null;
  driverIconColor: string = "red";
  signalIconColor: string = "red";
  currentTime: number;
  isUserHosp: boolean = false;


  addressFirstLine: string;
  addressSecondLine: string;

  customMapOptions = {
    controls: [],
    contextMenuOptions: [],
    rightClickMenu: false,
  };
  private alive: boolean = true;

  location: Location;
  lastLatLon: any;
  static lastMapID: number = 0;

  constructor(
    private i18n: I18nService,
    private rtService: RealtimeService,
    private realTimeDataService: RealTimeDataService,
    private cercaliaMapService: CercaliaMapService,
    private authenticationService: AuthenticationService
  ) {
    this.mapID = "vehicle-detail-map" + VehicleDetailsComponent.lastMapID;
    VehicleDetailsComponent.lastMapID++;
    
    if(this.authenticationService.user.id == 277718){
      this.isUserHosp = true;
    }
    
    
    //Subscrive to updates
    this.realTimeDataService.vehicleInfo
      .takeWhile(() => this.alive)
      .subscribe((vi) => {
        if (this.vehicle == null || vi.vehicleId != this.vehicle.id) return;
        this.processVehicleInfo(vi);
      });
    if (!this.info) this.info = {} as any;
  }

  ngOnInit() {
    this.currentTime = Date.now();

    this.info = {} as any;

    //When the map has loaded. Get the current value for the car
    this.map.loadMap
      .takeWhile(() => this.alive)
      .subscribe((mapReady) => {
        if (mapReady && this.vehicle != null)
          this.processVehicleInfo(
            this.realTimeDataService.vehicleInfoArr.filter(
              (v) => v.vehicleId == this.vehicle.id
            )[0]
          );
      });
  }

  private processVehicleInfo(vi: RestExt.ExtendedVehicleDataMessage) {
    if (vi == null) return;
    this.info = vi;

    let posChanged =
      !this.lastLatLon ||
      this.lastLatLon.latitude != vi.location.latitude ||
      this.lastLatLon.longitude != vi.location.longitude;
    this.lastLatLon = vi.location;

    if (posChanged) this.map.addVehicle(this.info);
    let head = this.info.heading;
    this.stringArrwStyle = "rotate(" + head + "deg)";
    if (!this.info.satelite) this.gpsIconColor = "black";
    if (this.info.satelite >= 0) this.gpsIconColor = "red";
    if (this.info.satelite >= 3) this.gpsIconColor = "yellow";
    if (this.info.satelite >= 4) this.gpsIconColor = "green";

    var vehicle = this.realTimeDataService.watchedVehicles
      .getValue()
      .find((v) => v.id == this.info.vehicleId) as Rest.Vehicle;
    let driverIdSensor = vehicle
      ? vehicle.device.configuration.parameters.sensors.find(
        (s) => s.tag == "DRIVER_ID"
      )
      : null;

    if (
      !driverIdSensor ||
      !this.info.sensors.find((s) => s.dID == driverIdSensor.id)
    ) {
      this.driverIconColor = "black";
    } else {
      let id = (
        this.info.sensors.find((s) => s.dID == driverIdSensor.id) as any
      ).numericValue;
      if (!id) {
        this.driverIconColor = "red";
      } else {
        this.driverIconColor = "green";
      }
    }
    //tenim dades de fa <=1 hora , groc si tenim dades de fa <= 8 hores i vermell per la resta.
    this.signalIconColor = "red";

    //Device time is in GMT+0 epoch
    if (
      new Date().getTime() +
      new Date().getTimezoneOffset() * 60 * 1000 -
      this.info.deviceTime <=
      1000 * 60 * 60 * 8
    )
      this.signalIconColor = "yellow";
    if (
      new Date().getTime() +
      new Date().getTimezoneOffset() * 60 * 1000 -
      this.info.deviceTime <=
      1000 * 60 * 60
    )
      this.signalIconColor = "green";

    if (posChanged) {
      this.cercaliaMapService
        .reverseGeocode(this.info.longitude, this.info.latitude)
        .then((location) => {
          this.location = location as any;

          /* Get the first line for the address field - Street name & number */
          this.addressFirstLine = location.fullAddress;
          this.addressFirstLine = this.addressFirstLine.substring(0, this.addressFirstLine.indexOf(','));

          /* Get the second line for the address field - City & Province */
          this.addressSecondLine = location.city + ', ' + location.subregion;
        });
      this.map.centerMap(null);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges(changes) {
  }

  showSMSForm(modalRef: ElementRef) {
    const modal = new Modal(modalRef.nativeElement);
    modal.show();
  }

  centerVehicleOnMap() {
    this.map.centerMap([this.vehicle.id]);
  }
}
