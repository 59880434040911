import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: 'app-batch-configuration-ob22',
  templateUrl: './batch-configuration-ob22.component.html',
  styleUrls: ['./batch-configuration-ob22.component.css']
})
export class BatchConfigurationOB22Component extends ServerValidatedFormComponent
implements OnInit  {

  @Input() batch: Rest.BatchConfigurationOB22;
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.batch);
    }
  }

  handleChange($event: any) {}

}
