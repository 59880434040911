<p-dialog header="{{'control-panel.vehicles.vehicleModel.title' | translate}}" (onHide)="onHide($event)" [(visible)]="display" [responsive]="true" showEffect="fade"
          [modal]="false" appendTo="body">
    <div class="ui-g">
            <div class="ui-g-4 ui-sm-12">
                    <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleType' | translate}}" [errors]="errors.type">
                        <p-dropdown id="dropdownTypes" (onChange)="typeChanged($event.value)" i18n-placeholder
                            placeholder="Select a Type" [options]="availableTypes" [(ngModel)]="selectedType"
                            [style]="{'width':'190px'}" filter="filter"></p-dropdown>
                    </form-input>
                </div>
            
                <div class="ui-g-4 ui-sm-12">
                    <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleBrand.name' | translate}}" [errors]="errors.brand">
                        <div class="d-flex justify-content-end align-items-center">                       
                            <p-dropdown id="dropdownBrands" [disabled]=dropdownBrandsDisable appendTo="body"
                                (onChange)="brandChanged($event.value)" i18n-placeholder
                                placeholder="Select a Brand" [options]="availableBrands"
                                [(ngModel)]="selectedBrand" [style]="{'width':'190px'}" filter="filter">
                            </p-dropdown>                           
                        </div>
                    </form-input>
                        
                </div>
        <div class="ui-g-12 ui-md-6">
            <form-input [required]="true" fieldName="{{'control-panel.vehicles.vehicleModel.modelName' | translate}}">
                <input [disabled]=dropdownModelsDisable pInputText id="name" [(ngModel)]="entity.name" type="text" />
            </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
            <button  type="button" pButton icon="fa fa-check"
                (click)="createModel()" label="{{'general.create' | translate}}"></button>
        </div>
    </div>


</p-dialog>