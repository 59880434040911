import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-excessive-idling-detection-gmt200',
  templateUrl: './excessive-idling-detection-gmt200.component.html',
  styleUrls: ['./excessive-idling-detection-gmt200.component.css'],
})
export class ExcessiveIdlingDetectionGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() idlParameters: Rest.ExcessiveIdlingDetectionGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }
  modeOptions: any = [
    {label: "Disable this function", value:"DISABLE"},
    {label: "Enable this function", value:"ENABLE"}
  ]
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.idlParameters);
    }
  }

}
