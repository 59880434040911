
export class TimeZones
{

    // Array of timezones. Each time zone contains: id, gmt offset (in hours), human readable name
    public static EN:{ [index: string]:  string } =
    {
        'Africa/Abidjan':'Africa/Abidjan (GMT +00:00) - CI',
        'Africa/Accra':'Africa/Accra (GMT +00:00) - GH',
        'Africa/Bissau':'Africa/Bissau (GMT +00:00) - GW',
        'Africa/Casablanca':'Africa/Casablanca (GMT +00:00) - MA',
        'Africa/El_Aaiun':'Africa/El_Aaiun (GMT +00:00) - EH',
        'Africa/Monrovia':'Africa/Monrovia (GMT +00:00) - LR',
        'America/Danmarkshavn':'America/Danmarkshavn (GMT +00:00) - GL',
        'Antarctica/Troll':'Antarctica/Troll (GMT +00:00) - AQ',
        'Atlantic/Canary':'Atlantic/Canary (GMT +00:00) - ES',
        'Atlantic/Faroe':'Atlantic/Faroe (GMT +00:00) - FO',
        'Atlantic/Madeira':'Atlantic/Madeira (GMT +00:00) - PT',
        'Atlantic/Reykjavik':'Atlantic/Reykjavik (GMT +00:00) - IS',
        'Etc/GMT':'Etc/GMT (GMT +00:00) - ',
        'Etc/UTC':'Etc/UTC (GMT +00:00) - ',
        'Europe/Dublin':'Europe/Dublin (GMT +00:00) - IE',
        'Europe/Lisbon':'Europe/Lisbon (GMT +00:00) - PT',
        'Europe/London':'Europe/London (GMT +00:00) - GB',
        'Africa/Algiers':'Africa/Algiers (GMT +01:00) - DZ',
        'Africa/Ceuta':'Africa/Ceuta (GMT +01:00) - ES',
        'Africa/Lagos':'Africa/Lagos (GMT +01:00) - NG',
        'Africa/Ndjamena':'Africa/Ndjamena (GMT +01:00) - TD',
        'Africa/Tunis':'Africa/Tunis (GMT +01:00) - TN',
        'Europe/Amsterdam':'Europe/Amsterdam (GMT +01:00) - NL',
        'Europe/Andorra':'Europe/Andorra (GMT +01:00) - AD',
        'Europe/Belgrade':'Europe/Belgrade (GMT +01:00) - RS',
        'Europe/Berlin':'Europe/Berlin (GMT +01:00) - DE',
        'Europe/Brussels':'Europe/Brussels (GMT +01:00) - BE',
        'Europe/Budapest':'Europe/Budapest (GMT +01:00) - HU',
        'Europe/Copenhagen':'Europe/Copenhagen (GMT +01:00) - DK',
        'Europe/Gibraltar':'Europe/Gibraltar (GMT +01:00) - GI',
        'Europe/Luxembourg':'Europe/Luxembourg (GMT +01:00) - LU',
        'Europe/Madrid':'Europe/Madrid (GMT +01:00) - ES',
        'Europe/Malta':'Europe/Malta (GMT +01:00) - MT',
        'Europe/Monaco':'Europe/Monaco (GMT +01:00) - MC',
        'Europe/Oslo':'Europe/Oslo (GMT +01:00) - NO',
        'Europe/Paris':'Europe/Paris (GMT +01:00) - FR',
        'Europe/Prague':'Europe/Prague (GMT +01:00) - CZ',
        'Europe/Rome':'Europe/Rome (GMT +01:00) - IT',
        'Europe/Stockholm':'Europe/Stockholm (GMT +01:00) - SE',
        'Europe/Tirane':'Europe/Tirane (GMT +01:00) - AL',
        'Europe/Vienna':'Europe/Vienna (GMT +01:00) - AT',
        'Europe/Warsaw':'Europe/Warsaw (GMT +01:00) - PL',
        'Europe/Zurich':'Europe/Zurich (GMT +01:00) - CH',
        'Africa/Cairo':'Africa/Cairo (GMT +02:00) - EG',
        'Africa/Johannesburg':'Africa/Johannesburg (GMT +02:00) - ZA',
        'Africa/Khartoum':'Africa/Khartoum (GMT +02:00) - SD',
        'Africa/Maputo':'Africa/Maputo (GMT +02:00) - MZ',
        'Africa/Tripoli':'Africa/Tripoli (GMT +02:00) - LY',
        'Africa/Windhoek':'Africa/Windhoek (GMT +02:00) - NA',
        'Asia/Amman':'Asia/Amman (GMT +02:00) - JO',
        'Asia/Beirut':'Asia/Beirut (GMT +02:00) - LB',
        'Asia/Damascus':'Asia/Damascus (GMT +02:00) - SY',
        'Asia/Famagusta':'Asia/Famagusta (GMT +02:00) - CY',
        'Asia/Gaza':'Asia/Gaza (GMT +02:00) - PS',
        'Asia/Hebron':'Asia/Hebron (GMT +02:00) - PS',
        'Asia/Jerusalem':'Asia/Jerusalem (GMT +02:00) - IL',
        'Asia/Nicosia':'Asia/Nicosia (GMT +02:00) - CY',
        'Europe/Athens':'Europe/Athens (GMT +02:00) - GR',
        'Europe/Bucharest':'Europe/Bucharest (GMT +02:00) - RO',
        'Europe/Chisinau':'Europe/Chisinau (GMT +02:00) - MD',
        'Europe/Helsinki':'Europe/Helsinki (GMT +02:00) - FI',
        'Europe/Kaliningrad':'Europe/Kaliningrad (GMT +02:00) - RU',
        'Europe/Kiev':'Europe/Kiev (GMT +02:00) - UA',
        'Europe/Riga':'Europe/Riga (GMT +02:00) - LV',
        'Europe/Sofia':'Europe/Sofia (GMT +02:00) - BG',
        'Europe/Tallinn':'Europe/Tallinn (GMT +02:00) - EE',
        'Europe/Uzhgorod':'Europe/Uzhgorod (GMT +02:00) - UA',
        'Europe/Vilnius':'Europe/Vilnius (GMT +02:00) - LT',
        'Europe/Zaporozhye':'Europe/Zaporozhye (GMT +02:00) - UA',
        'Africa/Juba':'Africa/Juba (GMT +03:00) - SS',
        'Africa/Nairobi':'Africa/Nairobi (GMT +03:00) - KE',
        'Antarctica/Syowa':'Antarctica/Syowa (GMT +03:00) - AQ',
        'Asia/Baghdad':'Asia/Baghdad (GMT +03:00) - IQ',
        'Asia/Qatar':'Asia/Qatar (GMT +03:00) - QA',
        'Asia/Riyadh':'Asia/Riyadh (GMT +03:00) - SA',
        'Europe/Istanbul':'Europe/Istanbul (GMT +03:00) - TR',
        'Europe/Kirov':'Europe/Kirov (GMT +03:00) - RU',
        'Europe/Minsk':'Europe/Minsk (GMT +03:00) - BY',
        'Europe/Moscow':'Europe/Moscow (GMT +03:00) - RU',
        'Europe/Simferopol':'Europe/Simferopol (GMT +03:00) - RU',
        'Europe/Volgograd':'Europe/Volgograd (GMT +03:00) - RU',
        'Asia/Tehran':'Asia/Tehran (GMT +03:30) - IR',
        'Asia/Baku':'Asia/Baku (GMT +04:00) - AZ',
        'Asia/Dubai':'Asia/Dubai (GMT +04:00) - AE',
        'Asia/Tbilisi':'Asia/Tbilisi (GMT +04:00) - GE',
        'Asia/Yerevan':'Asia/Yerevan (GMT +04:00) - AM',
        'Europe/Astrakhan':'Europe/Astrakhan (GMT +04:00) - RU',
        'Europe/Samara':'Europe/Samara (GMT +04:00) - RU',
        'Europe/Saratov':'Europe/Saratov (GMT +04:00) - RU',
        'Europe/Ulyanovsk':'Europe/Ulyanovsk (GMT +04:00) - RU',
        'Indian/Mahe':'Indian/Mahe (GMT +04:00) - SC',
        'Indian/Mauritius':'Indian/Mauritius (GMT +04:00) - MU',
        'Indian/Reunion':'Indian/Reunion (GMT +04:00) - RE',
        'Asia/Kabul':'Asia/Kabul (GMT +04:30) - AF',
        'Antarctica/Mawson':'Antarctica/Mawson (GMT +05:00) - AQ',
        'Asia/Aqtau':'Asia/Aqtau (GMT +05:00) - KZ',
        'Asia/Aqtobe':'Asia/Aqtobe (GMT +05:00) - KZ',
        'Asia/Ashgabat':'Asia/Ashgabat (GMT +05:00) - TM',
        'Asia/Atyrau':'Asia/Atyrau (GMT +05:00) - KZ',
        'Asia/Dushanbe':'Asia/Dushanbe (GMT +05:00) - TJ',
        'Asia/Karachi':'Asia/Karachi (GMT +05:00) - PK',
        'Asia/Oral':'Asia/Oral (GMT +05:00) - KZ',
        'Asia/Samarkand':'Asia/Samarkand (GMT +05:00) - UZ',
        'Asia/Tashkent':'Asia/Tashkent (GMT +05:00) - UZ',
        'Asia/Yekaterinburg':'Asia/Yekaterinburg (GMT +05:00) - RU',
        'Indian/Kerguelen':'Indian/Kerguelen (GMT +05:00) - TF',
        'Indian/Maldives':'Indian/Maldives (GMT +05:00) - MV',
        'Asia/Colombo':'Asia/Colombo (GMT +05:30) - LK',
        'Asia/Kolkata':'Asia/Kolkata (GMT +05:30) - IN',
        'Asia/Kathmandu':'Asia/Kathmandu (GMT +05:45) - NP',
        'Antarctica/Vostok':'Antarctica/Vostok (GMT +06:00) - AQ',
        'Asia/Almaty':'Asia/Almaty (GMT +06:00) - KZ',
        'Asia/Bishkek':'Asia/Bishkek (GMT +06:00) - KG',
        'Asia/Dhaka':'Asia/Dhaka (GMT +06:00) - BD',
        'Asia/Omsk':'Asia/Omsk (GMT +06:00) - RU',
        'Asia/Qyzylorda':'Asia/Qyzylorda (GMT +06:00) - KZ',
        'Asia/Thimphu':'Asia/Thimphu (GMT +06:00) - BT',
        'Asia/Urumqi':'Asia/Urumqi (GMT +06:00) - CN',
        'Indian/Chagos':'Indian/Chagos (GMT +06:00) - IO',
        'Asia/Yangon':'Asia/Yangon (GMT +06:30) - MM',
        'Indian/Cocos':'Indian/Cocos (GMT +06:30) - CC',
        'Antarctica/Davis':'Antarctica/Davis (GMT +07:00) - AQ',
        'Asia/Bangkok':'Asia/Bangkok (GMT +07:00) - TH',
        'Asia/Barnaul':'Asia/Barnaul (GMT +07:00) - RU',
        'Asia/Ho_Chi_Minh':'Asia/Ho_Chi_Minh (GMT +07:00) - VN',
        'Asia/Hovd':'Asia/Hovd (GMT +07:00) - MN',
        'Asia/Jakarta':'Asia/Jakarta (GMT +07:00) - ID',
        'Asia/Krasnoyarsk':'Asia/Krasnoyarsk (GMT +07:00) - RU',
        'Asia/Novokuznetsk':'Asia/Novokuznetsk (GMT +07:00) - RU',
        'Asia/Novosibirsk':'Asia/Novosibirsk (GMT +07:00) - RU',
        'Asia/Pontianak':'Asia/Pontianak (GMT +07:00) - ID',
        'Asia/Tomsk':'Asia/Tomsk (GMT +07:00) - RU',
        'Indian/Christmas':'Indian/Christmas (GMT +07:00) - CX',
        'Asia/Brunei':'Asia/Brunei (GMT +08:00) - BN',
        'Asia/Choibalsan':'Asia/Choibalsan (GMT +08:00) - MN',
        'Asia/Hong_Kong':'Asia/Hong_Kong (GMT +08:00) - HK',
        'Asia/Irkutsk':'Asia/Irkutsk (GMT +08:00) - RU',
        'Asia/Kuala_Lumpur':'Asia/Kuala_Lumpur (GMT +08:00) - MY',
        'Asia/Kuching':'Asia/Kuching (GMT +08:00) - MY',
        'Asia/Macau':'Asia/Macau (GMT +08:00) - MO',
        'Asia/Makassar':'Asia/Makassar (GMT +08:00) - ID',
        'Asia/Manila':'Asia/Manila (GMT +08:00) - PH',
        'Asia/Shanghai':'Asia/Shanghai (GMT +08:00) - CN',
        'Asia/Singapore':'Asia/Singapore (GMT +08:00) - SG',
        'Asia/Taipei':'Asia/Taipei (GMT +08:00) - TW',
        'Asia/Ulaanbaatar':'Asia/Ulaanbaatar (GMT +08:00) - MN',
        'Australia/Perth':'Australia/Perth (GMT +08:00) - AU',
        'Asia/Pyongyang':'Asia/Pyongyang (GMT +08:30) - KP',
        'Australia/Eucla':'Australia/Eucla (GMT +08:45) - AU',
        'Asia/Chita':'Asia/Chita (GMT +09:00) - RU',
        'Asia/Dili':'Asia/Dili (GMT +09:00) - TL',
        'Asia/Jayapura':'Asia/Jayapura (GMT +09:00) - ID',
        'Asia/Khandyga':'Asia/Khandyga (GMT +09:00) - RU',
        'Asia/Seoul':'Asia/Seoul (GMT +09:00) - KR',
        'Asia/Tokyo':'Asia/Tokyo (GMT +09:00) - JP',
        'Asia/Yakutsk':'Asia/Yakutsk (GMT +09:00) - RU',
        'Pacific/Palau':'Pacific/Palau (GMT +09:00) - PW',
        'Australia/Adelaide':'Australia/Adelaide (GMT +09:30) - AU',
        'Australia/Broken_Hill':'Australia/Broken_Hill (GMT +09:30) - AU',
        'Australia/Darwin':'Australia/Darwin (GMT +09:30) - AU',
        'Antarctica/DumontDUrville':'Antarctica/DumontDUrville (GMT +10:00) - AQ',
        'Asia/Ust-Nera':'Asia/Ust-Nera (GMT +10:00) - RU',
        'Asia/Vladivostok':'Asia/Vladivostok (GMT +10:00) - RU',
        'Australia/Brisbane':'Australia/Brisbane (GMT +10:00) - AU',
        'Australia/Currie':'Australia/Currie (GMT +10:00) - AU',
        'Australia/Hobart':'Australia/Hobart (GMT +10:00) - AU',
        'Australia/Lindeman':'Australia/Lindeman (GMT +10:00) - AU',
        'Australia/Melbourne':'Australia/Melbourne (GMT +10:00) - AU',
        'Australia/Sydney':'Australia/Sydney (GMT +10:00) - AU',
        'Pacific/Chuuk':'Pacific/Chuuk (GMT +10:00) - FM',
        'Pacific/Guam':'Pacific/Guam (GMT +10:00) - GU',
        'Pacific/Port_Moresby':'Pacific/Port_Moresby (GMT +10:00) - PG',
        'Australia/Lord_Howe':'Australia/Lord_Howe (GMT +10:30) - AU',
        'Antarctica/Casey':'Antarctica/Casey (GMT +11:00) - AQ',
        'Antarctica/Macquarie':'Antarctica/Macquarie (GMT +11:00) - AU',
        'Asia/Magadan':'Asia/Magadan (GMT +11:00) - RU',
        'Asia/Sakhalin':'Asia/Sakhalin (GMT +11:00) - RU',
        'Asia/Srednekolymsk':'Asia/Srednekolymsk (GMT +11:00) - RU',
        'Pacific/Bougainville':'Pacific/Bougainville (GMT +11:00) - PG',
        'Pacific/Efate':'Pacific/Efate (GMT +11:00) - VU',
        'Pacific/Guadalcanal':'Pacific/Guadalcanal (GMT +11:00) - SB',
        'Pacific/Kosrae':'Pacific/Kosrae (GMT +11:00) - FM',
        'Pacific/Norfolk':'Pacific/Norfolk (GMT +11:00) - NF',
        'Pacific/Noumea':'Pacific/Noumea (GMT +11:00) - NC',
        'Pacific/Pohnpei':'Pacific/Pohnpei (GMT +11:00) - FM',
        'Asia/Anadyr':'Asia/Anadyr (GMT +12:00) - RU',
        'Asia/Kamchatka':'Asia/Kamchatka (GMT +12:00) - RU',
        'Pacific/Auckland':'Pacific/Auckland (GMT +12:00) - NZ',
        'Pacific/Fiji':'Pacific/Fiji (GMT +12:00) - FJ',
        'Pacific/Funafuti':'Pacific/Funafuti (GMT +12:00) - TV',
        'Pacific/Kwajalein':'Pacific/Kwajalein (GMT +12:00) - MH',
        'Pacific/Majuro':'Pacific/Majuro (GMT +12:00) - MH',
        'Pacific/Nauru':'Pacific/Nauru (GMT +12:00) - NR',
        'Pacific/Tarawa':'Pacific/Tarawa (GMT +12:00) - KI',
        'Pacific/Wake':'Pacific/Wake (GMT +12:00) - UM',
        'Pacific/Wallis':'Pacific/Wallis (GMT +12:00) - WF',
        'Pacific/Chatham':'Pacific/Chatham (GMT +12:45) - NZ',
        'Pacific/Apia':'Pacific/Apia (GMT +13:00) - WS',
        'Pacific/Enderbury':'Pacific/Enderbury (GMT +13:00) - KI',
        'Pacific/Fakaofo':'Pacific/Fakaofo (GMT +13:00) - TK',
        'Pacific/Tongatapu':'Pacific/Tongatapu (GMT +13:00) - TO',
        'Pacific/Kiritimati':'Pacific/Kiritimati (GMT +14:00) - KI',
        'America/Scoresbysund':'America/Scoresbysund (GMT −01:00) - GL',
        'Atlantic/Azores':'Atlantic/Azores (GMT −01:00) - PT',
        'Atlantic/Cape_Verde':'Atlantic/Cape_Verde (GMT −01:00) - CV',
        'America/Noronha':'America/Noronha (GMT −02:00) - BR',
        'Atlantic/South_Georgia':'Atlantic/South_Georgia (GMT −02:00) - GS',
        'America/Araguaina':'America/Araguaina (GMT −03:00) - BR',
        'America/Argentina/Buenos_Aires':'America/Argentina/Buenos_Aires (GMT −03:00) - AR',
        'America/Argentina/Catamarca':'America/Argentina/Catamarca (GMT −03:00) - AR',
        'America/Argentina/Cordoba':'America/Argentina/Cordoba (GMT −03:00) - AR',
        'America/Argentina/Jujuy':'America/Argentina/Jujuy (GMT −03:00) - AR',
        'America/Argentina/La_Rioja':'America/Argentina/La_Rioja (GMT −03:00) - AR',
        'America/Argentina/Mendoza':'America/Argentina/Mendoza (GMT −03:00) - AR',
        'America/Argentina/Rio_Gallegos':'America/Argentina/Rio_Gallegos (GMT −03:00) - AR',
        'America/Argentina/Salta':'America/Argentina/Salta (GMT −03:00) - AR',
        'America/Argentina/San_Juan':'America/Argentina/San_Juan (GMT −03:00) - AR',
        'America/Argentina/San_Luis':'America/Argentina/San_Luis (GMT −03:00) - AR',
        'America/Argentina/Tucuman':'America/Argentina/Tucuman (GMT −03:00) - AR',
        'America/Argentina/Ushuaia':'America/Argentina/Ushuaia (GMT −03:00) - AR',
        'America/Bahia':'America/Bahia (GMT −03:00) - BR',
        'America/Belem':'America/Belem (GMT −03:00) - BR',
        'America/Cayenne':'America/Cayenne (GMT −03:00) - GF',
        'America/Fortaleza':'America/Fortaleza (GMT −03:00) - BR',
        'America/Godthab':'America/Godthab (GMT −03:00) - GL',
        'America/Maceio':'America/Maceio (GMT −03:00) - BR',
        'America/Miquelon':'America/Miquelon (GMT −03:00) - PM',
        'America/Montevideo':'America/Montevideo (GMT −03:00) - UY',
        'America/Paramaribo':'America/Paramaribo (GMT −03:00) - SR',
        'America/Punta_Arenas':'America/Punta_Arenas (GMT −03:00) - CL',
        'America/Recife':'America/Recife (GMT −03:00) - BR',
        'America/Santarem':'America/Santarem (GMT −03:00) - BR',
        'America/Sao_Paulo':'America/Sao_Paulo (GMT −03:00) - BR',
        'Antarctica/Palmer':'Antarctica/Palmer (GMT −03:00) - AQ',
        'Antarctica/Rothera':'Antarctica/Rothera (GMT −03:00) - AQ',
        'Atlantic/Stanley':'Atlantic/Stanley (GMT −03:00) - FK',
        'America/St_Johns':'America/St_Johns (GMT −03:30) - CA',
        'America/Asuncion':'America/Asuncion (GMT −04:00) - PY',
        'America/Barbados':'America/Barbados (GMT −04:00) - BB',
        'America/Blanc-Sablon':'America/Blanc-Sablon (GMT −04:00) - CA',
        'America/Boa_Vista':'America/Boa_Vista (GMT −04:00) - BR',
        'America/Campo_Grande':'America/Campo_Grande (GMT −04:00) - BR',
        'America/Caracas':'America/Caracas (GMT −04:00) - VE',
        'America/Cuiaba':'America/Cuiaba (GMT −04:00) - BR',
        'America/Curacao':'America/Curacao (GMT −04:00) - CW',
        'America/Glace_Bay':'America/Glace_Bay (GMT −04:00) - CA',
        'America/Goose_Bay':'America/Goose_Bay (GMT −04:00) - CA',
        'America/Grand_Turk':'America/Grand_Turk (GMT −04:00) - TC',
        'America/Guyana':'America/Guyana (GMT −04:00) - GY',
        'America/Halifax':'America/Halifax (GMT −04:00) - CA',
        'America/La_Paz':'America/La_Paz (GMT −04:00) - BO',
        'America/Manaus':'America/Manaus (GMT −04:00) - BR',
        'America/Martinique':'America/Martinique (GMT −04:00) - MQ',
        'America/Moncton':'America/Moncton (GMT −04:00) - CA',
        'America/Port_of_Spain':'America/Port_of_Spain (GMT −04:00) - TT',
        'America/Porto_Velho':'America/Porto_Velho (GMT −04:00) - BR',
        'America/Puerto_Rico':'America/Puerto_Rico (GMT −04:00) - PR',
        'America/Santiago':'America/Santiago (GMT −04:00) - CL',
        'America/Santo_Domingo':'America/Santo_Domingo (GMT −04:00) - DO',
        'America/Thule':'America/Thule (GMT −04:00) - GL',
        'Atlantic/Bermuda':'Atlantic/Bermuda (GMT −04:00) - BM',
        'America/Atikokan':'America/Atikokan (GMT −05:00) - CA',
        'America/Bogota':'America/Bogota (GMT −05:00) - CO',
        'America/Cancun':'America/Cancun (GMT −05:00) - MX',
        'America/Detroit':'America/Detroit (GMT −05:00) - US',
        'America/Eirunepe':'America/Eirunepe (GMT −05:00) - BR',
        'America/Guayaquil':'America/Guayaquil (GMT −05:00) - EC',
        'America/Havana':'America/Havana (GMT −05:00) - CU',
        'America/Indiana/Indianapolis':'America/Indiana/Indianapolis (GMT −05:00) - US',
        'America/Indiana/Marengo':'America/Indiana/Marengo (GMT −05:00) - US',
        'America/Indiana/Petersburg':'America/Indiana/Petersburg (GMT −05:00) - US',
        'America/Indiana/Vevay':'America/Indiana/Vevay (GMT −05:00) - US',
        'America/Indiana/Vincennes':'America/Indiana/Vincennes (GMT −05:00) - US',
        'America/Indiana/Winamac':'America/Indiana/Winamac (GMT −05:00) - US',
        'America/Iqaluit':'America/Iqaluit (GMT −05:00) - CA',
        'America/Jamaica':'America/Jamaica (GMT −05:00) - JM',
        'America/Kentucky/Louisville':'America/Kentucky/Louisville (GMT −05:00) - US',
        'America/Kentucky/Monticello':'America/Kentucky/Monticello (GMT −05:00) - US',
        'America/Lima':'America/Lima (GMT −05:00) - PE',
        'America/Nassau':'America/Nassau (GMT −05:00) - BS',
        'America/New_York':'America/New_York (GMT −05:00) - US',
        'America/Nipigon':'America/Nipigon (GMT −05:00) - CA',
        'America/Panama':'America/Panama (GMT −05:00) - PA',
        'America/Pangnirtung':'America/Pangnirtung (GMT −05:00) - CA',
        'America/Port-au-Prince':'America/Port-au-Prince (GMT −05:00) - HT',
        'America/Rio_Branco':'America/Rio_Branco (GMT −05:00) - BR',
        'America/Thunder_Bay':'America/Thunder_Bay (GMT −05:00) - CA',
        'America/Toronto':'America/Toronto (GMT −05:00) - CA',
        'America/Bahia_Banderas':'America/Bahia_Banderas (GMT −06:00) - MX',
        'America/Belize':'America/Belize (GMT −06:00) - BZ',
        'America/Chicago':'America/Chicago (GMT −06:00) - US',
        'America/Costa_Rica':'America/Costa_Rica (GMT −06:00) - CR',
        'America/El_Salvador':'America/El_Salvador (GMT −06:00) - SV',
        'America/Guatemala':'America/Guatemala (GMT −06:00) - GT',
        'America/Indiana/Knox':'America/Indiana/Knox (GMT −06:00) - US',
        'America/Indiana/Tell_City':'America/Indiana/Tell_City (GMT −06:00) - US',
        'America/Managua':'America/Managua (GMT −06:00) - NI',
        'America/Matamoros':'America/Matamoros (GMT −06:00) - MX',
        'America/Menominee':'America/Menominee (GMT −06:00) - US',
        'America/Merida':'America/Merida (GMT −06:00) - MX',
        'America/Mexico_City':'America/Mexico_City (GMT −06:00) - MX',
        'America/Monterrey':'America/Monterrey (GMT −06:00) - MX',
        'America/North_Dakota/Beulah':'America/North_Dakota/Beulah (GMT −06:00) - US',
        'America/North_Dakota/Center':'America/North_Dakota/Center (GMT −06:00) - US',
        'America/North_Dakota/New_Salem':'America/North_Dakota/New_Salem (GMT −06:00) - US',
        'America/Rainy_River':'America/Rainy_River (GMT −06:00) - CA',
        'America/Rankin_Inlet':'America/Rankin_Inlet (GMT −06:00) - CA',
        'America/Regina':'America/Regina (GMT −06:00) - CA',
        'America/Resolute':'America/Resolute (GMT −06:00) - CA',
        'America/Swift_Current':'America/Swift_Current (GMT −06:00) - CA',
        'America/Tegucigalpa':'America/Tegucigalpa (GMT −06:00) - HN',
        'America/Winnipeg':'America/Winnipeg (GMT −06:00) - CA',
        'Pacific/Easter':'Pacific/Easter (GMT −06:00) - CL',
        'Pacific/Galapagos':'Pacific/Galapagos (GMT −06:00) - EC',
        'America/Boise':'America/Boise (GMT −07:00) - US',
        'America/Cambridge_Bay':'America/Cambridge_Bay (GMT −07:00) - CA',
        'America/Chihuahua':'America/Chihuahua (GMT −07:00) - MX',
        'America/Creston':'America/Creston (GMT −07:00) - CA',
        'America/Dawson_Creek':'America/Dawson_Creek (GMT −07:00) - CA',
        'America/Denver':'America/Denver (GMT −07:00) - US',
        'America/Edmonton':'America/Edmonton (GMT −07:00) - CA',
        'America/Fort_Nelson':'America/Fort_Nelson (GMT −07:00) - CA',
        'America/Hermosillo':'America/Hermosillo (GMT −07:00) - MX',
        'America/Inuvik':'America/Inuvik (GMT −07:00) - CA',
        'America/Mazatlan':'America/Mazatlan (GMT −07:00) - MX',
        'America/Ojinaga':'America/Ojinaga (GMT −07:00) - MX',
        'America/Phoenix':'America/Phoenix (GMT −07:00) - US',
        'America/Yellowknife':'America/Yellowknife (GMT −07:00) - CA',
        'America/Dawson':'America/Dawson (GMT −08:00) - CA',
        'America/Los_Angeles':'America/Los_Angeles (GMT −08:00) - US',
        'America/Tijuana':'America/Tijuana (GMT −08:00) - MX',
        'America/Vancouver':'America/Vancouver (GMT −08:00) - CA',
        'America/Whitehorse':'America/Whitehorse (GMT −08:00) - CA',
        'Pacific/Pitcairn':'Pacific/Pitcairn (GMT −08:00) - PN',
        'America/Anchorage':'America/Anchorage (GMT −09:00) - US',
        'America/Juneau':'America/Juneau (GMT −09:00) - US',
        'America/Metlakatla':'America/Metlakatla (GMT −09:00) - US',
        'America/Nome':'America/Nome (GMT −09:00) - US',
        'America/Sitka':'America/Sitka (GMT −09:00) - US',
        'America/Yakutat':'America/Yakutat (GMT −09:00) - US',
        'Pacific/Gambier':'Pacific/Gambier (GMT −09:00) - PF',
        'Pacific/Marquesas':'Pacific/Marquesas (GMT −09:30) - PF',
        'America/Adak':'America/Adak (GMT −10:00) - US',
        'Pacific/Honolulu':'Pacific/Honolulu (GMT −10:00) - US',
        'Pacific/Rarotonga':'Pacific/Rarotonga (GMT −10:00) - CK',
        'Pacific/Tahiti':'Pacific/Tahiti (GMT −10:00) - PF',
        'Pacific/Niue':'Pacific/Niue (GMT −11:00) - NU',
        'Pacific/Pago_Pago':'Pacific/Pago_Pago (GMT −11:00) - AS',
        
    }
} 
