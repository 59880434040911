<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">EXTERNAL BATTERY ALARM
          PROTECTION (EXBATCUT)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="externalBatteryProtection.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Battery Alarm Mode">
          </form-input>
        </div>
        <div class="col">
          <p-dropdown [options]="BatteryAlarmOptions" [(ngModel)]="externalBatteryProtection.modeAlarm"
            [style]="{'width':'100%','min-width':'100px'}">
          </p-dropdown>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Voltage Cut Off">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="externalBatteryProtection.voltageCutOff" [min]="10" [max]="360"></p-spinner>
            <span class="ui-inputgroup-addon">10~360</span>
            <span class="ui-inputgroup-addon">daV</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Voltage reconnecting">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="externalBatteryProtection.voltageReconnecting" [min]="10"
              [max]="360"></p-spinner>
            <span class="ui-inputgroup-addon">10~360</span>
            <span class="ui-inputgroup-addon">daV</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Time duration">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="externalBatteryProtection.timeDuration" [min]="1" [max]="300"></p-spinner>
            <span class="ui-inputgroup-addon">1~300</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</p-accordionTab>