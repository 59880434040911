<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Delay Input Configuration</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">

    <div class="ui-g-12 ui-md-6">
      <form-input i18n-fieldName fieldName="Delay Input 1" [errors]="errors.input1Delay">
        <div class="ui-inputgroup">
          <p-spinner thousandSeparator="." id="input1Delay" size="12" [(ngModel)]="deviceParams.proprietaryParameters.uncfg.input1Delay" step="1" [min]="0"
            [max]="255">
          </p-spinner>
          <span class="ui-inputgroup-addon">0.0~255.0</span>
          <span class="ui-inputgroup-addon" i18n>ms</span>
        </div>
      </form-input>
    </div>

    <div class="ui-g-12 ui-md-6">
        <form-input i18n-fieldName fieldName="Delay Input 2" [errors]="errors.input2Delay">
          <div class="ui-inputgroup">
            <p-spinner thousandSeparator="." id="input2Delay" size="12" [(ngModel)]="deviceParams.proprietaryParameters.uncfg.input2Delay" step="1" [min]="0"
              [max]="255">
            </p-spinner>
            <span class="ui-inputgroup-addon">0.0~255.0</span>
            <span class="ui-inputgroup-addon" i18n>ms</span>
          </div>
        </form-input>
      </div>

      <div class="ui-g-12 ui-md-6">
          <form-input i18n-fieldName fieldName="Delay Input 3" [errors]="errors.input3Delay">
            <div class="ui-inputgroup">
              <p-spinner thousandSeparator="." id="input3Delay" size="12" [(ngModel)]="deviceParams.proprietaryParameters.uncfg.input3Delay" step="1" [min]="0"
                [max]="255">
              </p-spinner>
              <span class="ui-inputgroup-addon">0.0~255.0</span>
              <span class="ui-inputgroup-addon" i18n>ms</span>
            </div>
          </form-input>
        </div>

        <div class="ui-g-12 ui-md-6">
            <form-input i18n-fieldName fieldName="Delay Input 4" [errors]="errors.input4Delay">
              <div class="ui-inputgroup">
                <p-spinner thousandSeparator="." id="input4Delay" size="12" [(ngModel)]="deviceParams.proprietaryParameters.uncfg.input4Delay" step="1" [min]="0"
                  [max]="255">
                </p-spinner>
                <span class="ui-inputgroup-addon">0.0~255.0</span>
                <span class="ui-inputgroup-addon" i18n>ms</span>
              </div>
            </form-input>
          </div>

  </div>
</p-accordionTab>