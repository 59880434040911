

  <p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>Mileage Configuration</span>
            </div>
            <div class="right">
                <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="mileageSwitch"></p-inputSwitch>
            </div>
        </div>
    </p-header>

    <div class="ui-g" #configmileage>
        <div class="ui-g-12 ui-md-6">
            <input pInputText hidden="true" id="id" [(ngModel)]="deviceParams.proprietaryParameters.mileageConfiguration.id" type="text" />

            <form-input i18n-fieldName fieldName="mode" [errors]="errors.mileageMode">
                <p-dropdown (onChange)="mileageConfigModeChange($event)" [options]="availablemileageConfigModes" [(ngModel)]="deviceParams.proprietaryParameters.mileageConfiguration.mode"
                    [style]="{'width':'300px'}"></p-dropdown>
            </form-input>
        </div>
        <div class="ui-g-12 ui-md-6">
            <form-input i18n-fieldName fieldName="mileage" [errors]="errors.mileage">
                <div class="ui-inputgroup">
                    <p-spinner thousandSeparator="." id="mileage" (onChange)="mileageChanged()" size="12" [(ngModel)]="mileage" step="0.1"
                        [min]="0" [max]="4294967.2"></p-spinner>
                    <span class="ui-inputgroup-addon">0.0~{{4294967.2|distance}}</span>
                    <span class="ui-inputgroup-addon">{{null|distance:true}}</span>
                </div>
            </form-input>
        </div>
    </div>

</p-accordionTab>