import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverAssignmentsService } from 'app/modules/vehicle-inventory/services/driver-assignments.service';
import { RestVehicleInventory } from 'app/modules/vehicle-inventory/services/rest_vehicle-inventory';
import { DelegationService } from 'app/modules/vehicle-inventory/services/table-management/delegation.service';
import { VehicleAssignmentsService } from 'app/modules/vehicle-inventory/services/vehicle-assignments.service';
import { DivisionService } from 'app/rest/division.service';
import { Rest } from 'app/rest/rest_client';
import { SubdelegationService } from 'app/rest/subdelegation.service';
import { SubdivisionService } from 'app/rest/subdivision.service';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicle-assignments-list',
  templateUrl: './vehicle-assignments-list.component.html',
  styleUrls: ['./vehicle-assignments-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleAssignmentsListComponent extends EntityListComponent<Rest.InvVehicleAssignment> implements OnInit, OnDestroy {
	title: string = "Assignments";

	@Input() vehicle?: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;

	refreshListSuscription: Subscription;

	delegationList: Rest.Delegation[] = [];
	isDelegationListLoaded: Boolean = false;
	subdelegationList: Rest.Subdelegation[] = [];
	isSubdelegationListLoaded: Boolean = false;
	companiesList: Rest.BillingAccount[] = [];
	isCompaniesListLoaded: Boolean = false;
	cecoList: Rest.Ceco[] = [];
	isCecoListLoaded: Boolean = false;

	user: Rest.User;
	EnabledFilter: SelectItem[];
	constructor(
		 protected vehicleAssignmentsService: VehicleAssignmentsService,
		 protected confirmationService: ConfirmationService,
		 public notificationsService: NotificationsService,
		 protected i18n: I18nService,
		 authenticationService: AuthenticationService,
		 protected loadingService: LoadingService,
		 protected userTime: UserTimePipe,
		 protected excelService: ExcelService,
		 protected translateService: TranslateService,
		 public delegationService: DelegationService,
		 public subdelegationService: SubdelegationService,
	) {
		 super(
			  vehicleAssignmentsService,
			  confirmationService,
			  notificationsService,
			  i18n,
			  authenticationService,
			  loadingService,
			  userTime,
			  excelService
		 );
	}

	ngOnInit() {
		 super.ngOnInit();
		 /*Permissions */
		 this.user = this.authenticationService.user;
		 /** */
		 this.getDelegationList(this.vehicle.client.id);
		 this.getSubdelegationList(this.vehicle.client.id);
		 this.refreshListSuscription = this.vehicleAssignmentsService.refreshList.subscribe(
			(item: RestVehicleInventory.InvVehicleHtcRequest) => {
				// console.log("[VEHICLE-ASSIGNMENTS-LIST] refreshList ***");
				// console.log(item);
				this.getList(item.startDate, item.endDate);
			}
		);
	}

	onDestroy() {
		this.refreshListSuscription.unsubscribe();
	}

	public getList(startDate: Date, endDate: Date) {
		// console.log("[VEHICLE-ASSIGNMENTS-LIST] getList ***");
		this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		this.startDate = startDate;
		this.endDate = endDate;
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.endDate.toString()];
		// console.log(this.paginationRequest);
		super.refresh();
	}

	beforeLoad(): void {
		if (this.vehicle) this.paginationRequest.filters['vehicleId'] = [this.vehicle.id.toString()];
		if (this.startDate) this.paginationRequest.filters['startDate'] = [this.startDate.toISOString()];
		if (this.endDate) this.paginationRequest.filters['endDate'] = [this.endDate.toISOString()];
	}

	public afterLoad() {
		// console.log("[VEHICLE-ASSIGNMENTS-LIST] afterLoad ***");
		// console.log(this.paginationResult);
	}

	public getDelegationList(clientId: number) {
		// console.log("[VEHICLE-ASSIGNMENTS-LIST] getDelegationList ***");
		// console.log(clientId);
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.delegationService
				.getList(clientId)
				.then(
			 		function (response) {
						that.delegationList = response;
						that.isDelegationListLoaded = true;
						// console.log("*** delegationList ***");
						// console.log(that.delegationList);
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}

	public getSubdelegationList(clientId: number) {
		// console.log("[VEHICLE-ASSIGNMENTS-LIST] getSubdelegationList ***");
		// console.log(clientId);
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.subdelegationService
				.getListByClient(clientId)
				.then(
			 		function (response) {
						that.subdelegationList = response;
						that.isSubdelegationListLoaded = true;
						// console.log("*** subdelegationList ***");
						// console.log(that.subdelegationList);
			 		},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		},
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}


	excelColumns = {};

	exportExcel(){
		 this.setExcelColumns();
		 let fileNameTitle = this.translateService.instant('vehicleAssignments.header');
		 super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
	}
	setExcelColumns() {
		 const fullName = (entity: any) => `(${entity.code ?? ''})  ${entity.user?.surname1 ?? ''} ${entity.user?.surname2 ?? ''},  ${entity.user?.name ?? ''}`//${ } concatenación
		 const startDate = (entity: any) => entity.startDate ?? '';
		 const endDate = (entity: any) => entity.endDate ?? '';
		 const disabled = (entity: any) => entity.disabled ?? ''

		this.excelColumns = {
			 [this.translateService.instant('drivers.table.name')]: { bindField: fullName },
			 [this.translateService.instant('drivers.table.startDate')]: { bindField: startDate },
			 [this.translateService.instant('drivers.table.endDate')]: {  bindField: endDate },
			 [this.translateService.instant('general.disabled')]: { bindField: disabled },
			}
	}



	}
