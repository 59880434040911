import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { environment } from "environments/environment";
import { NotificationsService, Severity } from "app/services/notifications-service/notifications.service";
import { HttpClient } from "@angular/common/http";

import CardConsumption = Rest.CardConsumption;

@Injectable()
export class CardConsumptionService extends EntityService<CardConsumption> {
    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient, private http: HttpClient, notificationsService: NotificationsService) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create (arg0: CardConsumption): Rest.RestResponse<CardConsumption> {
        try {
            return this.client.CardConsumption_create(arg0);
        } catch (error) {
            console.error("Error creating card consumption:", error);
            throw error;
        }
    }

    update (arg0: CardConsumption): Rest.RestResponse<CardConsumption> {
        try {
            return this.client.CardConsumption_update(arg0);
        } catch (error) {
            console.error("Error updating card consumption:", error);
            throw error;
        }
    }

    getPage (arg0: Rest.ListPaginationRequest, queryParams?: { includeDisabled?: boolean }): Rest.RestResponse<Rest.Page<CardConsumption>> {
        try {
            return this.client.CardConsumption_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting page of card consumptions:", error);
            throw error;
        }
    }

    // @ts-ignore
    find (arg0: string, queryParams?: { id?: number }): Rest.RestResponse<CardConsumption> {
        try {
            return this.client.CardConsumption_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding card consumption:", error);
            throw error;
        }
    }

    summary(arg0: number): Rest.RestResponse<Rest.CardConsumptionSummary> {
        try {
            return this.client.CardConsumption_summary(arg0);
        } catch (error) {
            console.error("Error getting card consumption summary:", error);
            throw error;
        }
    }
}