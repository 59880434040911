<div class="container-fluid p-0">
  <div class="row">

    <!-- COL 1: AVAILABLE ITEMS -->
    <div class="col">

      <!-- ROW 1: AVAILABLE LABEL & SEARCH BOX-->
      <div class="col row dvcFields">
        <!-- COL 1: AVAILABLE LABEL -->
        <div class="col lblStyle">
          <label class="custom-label" i18n>{{'general.available' | translate}}</label>
        </div>
        <!-- COL 2: AVAILABLE SEARCH BOX -->
        <div class="col">
          <input type="text" class="form-control custom-input paged-picklist-search-box txtbStyle"
            placeholder="{{'general.search' | translate}}" [ngModel]="searchLeftText"
            (ngModelChange)="searchLeft($event)" />
        </div>
      </div>

      <!-- ROW 2: PRIMENG LIST BOX -->
      <div class="col row dvcFields">
        <p-listbox [options]="availableLeft" multiple=true checkbox="true" [(ngModel)]="selectedLeft"
          (onDblClick)="leftItemDblClick($event)"></p-listbox>
      </div>

      <!-- ROW 3: LOAD MORE & ADD BUTTONS -->
      <div class="row align-items-center">

        <!-- COL 1: LOAD MORE BUTTON-->
        <div class="col row dvcFields">
          <div class="col">
            <button class="btn custom-button" (click)="more()" [disabled]="hasMore===false" i18n [style]="{'min-width':'150px'}">
              {{'general.loadMore' | translate}}
            </button>
          </div>
        </div>

        <!-- COL 2: ADD BUTTON -->
        <div class="col row dvcFields">
          <div class="col">
            <button class="btn custom-button" (click)="add()" [disabled]="selectedLeft.length===0">
              >>
            </button>
          </div>
        </div>

      </div>

    </div>

    <!-- COL 2: SELECTED ITEMS -->
    <div class="col">

      <!-- ROW 1: SELECTED LABEL & SEARCH BOX -->
      <div class="col row dvcFields">
        <!-- COL 1: SELECTED LABEL -->
        <div class="col">
          <label class="custom-label" i18n>{{'general.selected' | translate}}</label>
        </div>
        <!-- COL 2: SELECTED SEARCH BOX -->
        <div class="col">
          <input type="text" class="form-control custom-input paged-picklist-search-box"
          placeholder="{{'general.search' | translate}}" [ngModel]="searchRightText"
          (ngModelChange)="searchRight($event)" />
        </div>
      </div>

      <!-- ROW 2: PRIMENG LIST BOX -->
      <div class="col row dvcFields">
          <p-listbox [options]="availableRight" multiple=true checkbox="true" [(ngModel)]="selectedRight"
          (onDblClick)="rightItemDblClick($event)"></p-listbox>
      </div>

      <!-- ROW 3: REMOVE BUTTON -->
      <div class="col dvcFields">
        <button class="btn custom-button" (click)="remove()" [disabled]="selectedRight.length===0">
          << </button>
      </div>

    </div>

  </div>
</div>