import { Component, OnInit, ViewChild } from "@angular/core";
import { UserTimePipe } from "app/components/shared/UserTimePipe";
import { LoadingService } from "app/services/loading-service/loading.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { ProviderService } from "app/rest/provider.service";
import { Rest } from "../../../../../../rest/rest_client";
import { AuthenticationService } from "../../../../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../../../../services/rest-client-extension";
import { EntityListComponent } from "../../../../../../components/entity-list/entity-list.component";
import { ProviderFormComponent } from "../providers-form/provider-form.component";
import { ExcelService } from "app/services/excel-service/excel.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.css'],
    providers: [ConfirmationService, UserTimePipe, ProviderService, ExcelService],
})
export class ProvidersComponent
    extends EntityListComponent<Rest.Provider>
    implements OnInit {
    title: string = this.i18n._("Providers");

    @ViewChild(ProviderFormComponent, { static: true })
    classForm: ProviderFormComponent;

    disableSave: boolean;

    user: Rest.User;
    EnabledFilter: SelectItem[];

    providersList: Rest.Provider[] = [];

    typeList: Rest.ProviderType[] = [];

    constructor(
        private providerService: ProviderService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        protected i18n: I18nService,
        authenticationService: AuthenticationService,
        protected loadingService: LoadingService,
        protected userTime: UserTimePipe,
        protected excelService: ExcelService,
        public translateService: TranslateService,
    ) {
        super(
            providerService,
            confirmationService,
            notificationsService,
            i18n,
            authenticationService,
            loadingService,
            userTime,
            excelService
        );
        this.providerService = providerService;
    }

    ngOnInit() {
        super.ngOnInit();
        this.form = this.classForm;
        this.hasEntityFilters = false;
        this.user = this.authenticationService.user;
        this.getTypeList();
    }
    
    beforeLoad() {
        if (this.paginationRequest.filters.hasOwnProperty("disabled")) {
            this.paginationRequest.filters["disabled"] = this.paginationRequest.filters["disabled"][0].toString() == "true" ? ["false"] : ["true"];
        }
    }
    afterLoad() {
        // this.EnabledFilter = RestExt.getEnabledFilter(
        //     this.filters["enabled"],
        //     this.i18n
        // );
        // this.paginationResult.entities.forEach((p: any) => {
        //     /*Permissions */
        //     p._$changeStatus = true; //This is for the validation of entity-list (selectAll function)
        //     p._$update = true;
        //     /** */
        // });
        /*Permissions */
        // this.checkSelectAll();
        /** */
    }
    excelColumns = {};
    exportExcel(){
        this.setExcelColumns();
        let fileNameTitle = this.translateService.instant('tableManagement.providers.providers');
        super.exportExcelWithColumns(fileNameTitle,  this.excelColumns);
    }
    setExcelColumns() {
        const name = (entity: any) => entity.name ?? ''
        const type = (entity: any) => entity.type?.name ?? ''
        const disabled = (entity: any) => entity.disabled ?? ''  
        this.excelColumns = {
            [this.translateService.instant('general.name')]:  { bindField: name },  
            [this.translateService.instant('general.code')]: { bindField: type },
            [this.translateService.instant('general.disabled')]: { bindField: disabled },
        }
    }

    public getTypeList() {
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.providerService
				.getTypes()
				.then(
					function (response) {
						that.typeList = response;
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						resolve();
					}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}
}
