import { Component, Input, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-digital-input-port-setting-gv300can",
  templateUrl: "./digital-input-port-setting-gv300can.component.html",
  styleUrls: ["./digital-input-port-setting-gv300can.component.css"],
})
export class DigitalInputPortSettingGv300canComponent {
  @Input() disParameters: Rest.DigitalInputPortSettingsGV300CAN;

  modeOptions: any = [
    { label: "Hard wired ignition detection mode.", value: 0 },
    { label: "Motion status to simulate ignition status.", value: 1 },
    { label: "External power voltage mode", value: 2 },
    // { label: "Reserved", value: 3 },
    { label: "Accerelerometer mode.", value: 4 },
  ];

  constructor(protected i18n: I18nService) {
    // TODO: Fix constructor. Super can't be called!!!
    // super(i18n);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.disParameters);
    }
  }
}
