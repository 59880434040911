<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Fixed Report Information
          (AT + GTFRI)</span>
      </div>
    </div>
  </p-header>

  <div class="col row dvcFields">
    <div class="col lblStyle">
      <form-input fieldName="Report Mode">
      </form-input>
    </div>
    <div class="col">
      <p-dropdown (onChange)="trackEventModeChange(friParameters.mode,$event)" [options]="modeOptions"
        [(ngModel)]="friParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
    </div>
  </div>

  <div [class.disableDIV]=!trackModeSwitch>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Discard No Fix">
          </form-input>
        </div>
        <div class="col">
          <p-inputSwitch [(ngModel)]="friParameters.discardNoFix"></p-inputSwitch>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Check Interval">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.checkInterval" [min]="0" [max]="86400"
              (onChange)="onChange()"></p-spinner>
            <span class="ui-inputgroup-addon">0~86400</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Send Interval">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.sendInterval"
              [min]="5 > friParameters.checkInterval ? 5 : friParameters.checkInterval" [max]="86400"></p-spinner>
            <!-- Known bug: if check is changed, fri will only be in a correct value after changed -->
            <span class="ui-inputgroup-addon">5~86400</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Distance">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.distance" [min]="50" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">50~65535</span>
            <span class="ui-inputgroup-addon">m</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Mileage">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.mileage" [min]="50" [max]="65535"></p-spinner>
            <span class="ui-inputgroup-addon">50~65535</span>
            <span class="ui-inputgroup-addon">m</span>
          </div>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Corner Report">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.cornerReport" [min]="0" [max]="180"></p-spinner>
            <span class="ui-inputgroup-addon">0~180</span>
            <!-- <span class="ui-inputgroup-addon" >km/h</span> -->
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="IGF Report Interval">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner size="5" [(ngModel)]="friParameters.iGFReportInterval" [min]="0" [max]="86400"></p-spinner>
            <span class="ui-inputgroup-addon">0~86400</span>
            <span class="ui-inputgroup-addon">s</span>
          </div>
        </div>
      </div>

    </div>

    <div class="ui-g-12">
      <form-input fieldName="Eri Mask">
      </form-input>
    </div>
    
    <div class="ui-g-12">

      <div class="ui-g-12">
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Digital Fuel Sensor Data" [(ngModel)]="friParameters.digitalFuelSensorData">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="1-Wire Data" [(ngModel)]="friParameters.oneWireData">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="CAN Data" [(ngModel)]="friParameters.canData">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Percentage" [(ngModel)]="friParameters.percentage">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="Volume" [(ngModel)]="friParameters.volume">
          </p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox binary="true" label="RF433 Accessory Data" [(ngModel)]="friParameters.rfAccessoryData">
          </p-checkbox>
        </div>
      </div>
    </div>
  </div>

</p-accordionTab>