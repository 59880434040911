import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval as observableInterval, Observable, of as observableOf, Subject, } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '../../components/shared/custom-map/custom-map.model';

@Injectable()
export class CercaliaMapService {
    private base = 'http://lb.cercalia.com/clients/ATLANTISJSON?';
    cercalia: any = null;
    isInitialized = false;
    public isCercaliaReady: BehaviorSubject<boolean>;
    id: number;

    constructor(
        private http: HttpClient,
    ) {
        /* TODO: DELETE CONSOLE.LOG() */

        this.id = Math.random();
        console.log('__1__ <<CercaliaMapService>> CONSTRUCTOR');
        console.log('__1.1__ <<id>> value:');
        console.log(this.id);

        this.isCercaliaReady = new BehaviorSubject<boolean>(false);

        document.addEventListener('cercalia-ready', () => {
            console.log('__1.2__ CERCALIA IS READY');
            this.isCercaliaReady.next(true);
        });

        this.cercalia = window['cercalia'] || null;
        this.isInitialized = !!this.cercalia;
        this.onMapInitialized();
    }

    checkInitialized(): Observable<boolean> {
        return observableOf(this.isInitialized);
    }

    onMapInitialized(): Promise<boolean> {

        return new Promise((resolve, reject) => {
            if (this.isInitialized) {
                resolve(true);
            } else {
                const interval = observableInterval(1000);
                const subscription = interval.subscribe((data) => {
                    //console.log('timer in subscription');
                    //console.log(data);
                    if (window['cercalia']) {
                        this.cercalia = window['cercalia'];
                        this.isInitialized = true;
                        subscription.unsubscribe();
                        resolve(true);
                    }
                });
            }
        });
    }

    getTimezone(lon: any, lat: any): Promise<any> {
        const query =
            this.base + 'cmd=prox&rqge=timezone&mocs=gdd&mo=' + lat + ',' + lon;
        return this.http
            .get(query)
            .pipe(map((res) => res))
            .toPromise();
    }

    getProximity(data: any): Promise<any> {
        let query =
            this.base +
            'cmd=prox&mo=' +
            data.y +
            ',' +
            data.x +
            '&mocs=gdd&rqmolist=';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0 ; i < data.mos.length ; i++) {
            query += data.mos[i] + ',';
        }
        query =
            query.substring(0, query.length - 1) +
            '&weight=' +
            data.weight +
            '&num=' +
            data.num +
            '&inverse=' +
            data.inverse;
        return this.http
            .get(query)
            .pipe(map((res) => res))
            .toPromise();
    }

    reverseGeocode(lon: number, lat: number): Promise<Location> {
        return this.doReverseGeocoding(new this.cercalia.LonLat(lon, lat));
    }

    private getPositionFromReverseGeocoding(reverseData): Location {
        const gelist = reverseData.cercalia.proximity.gelist;
        const ge = gelist.ge;
        const location = gelist.location;
        //TODO la api de cercalia no especifica quins valors retornara sempre al fer la petició.
        //prodrien arribar a ser null o undefined sempre...
        return {
            country: ge.country ? ge.country.value : '',
            municipality: ge.municipality ? ge.municipality.value : '',
            region: ge.region ? ge.region.value : '',
            subregion: ge.subregion ? ge.subregion.value : '',
            prefix: ge.prefix ? ge.prefix : '',
            postalCode: ge.postalcode ? ge.postalcode.id : '',
            address: ge.name ? ge.name : '',
            city: ge.city ? ge.city.value : '',
            lon: ge.coord.x,
            lat: ge.coord.y,
            fullAddress: this.cercalia.Util.getProximityFormattedAddress(reverseData),
        };
    }

    doReverseGeocoding(position: any): Promise<Location> {
        const that = this;
        const reverseGeocodingService =
            new this.cercalia.service.ReverseGeocoding();

        const promise: Promise<Location> = new Promise((resolve, reject) => {
            //Reverse geocoding to get address
            reverseGeocodingService.getDirection(position, function (data) {
                const location = that.getPositionFromReverseGeocoding(data);

                that
                    .getTimezone(position.getLon(), position.getLat())
                    .then((data) => {
                        location.timezone = data.cercalia.proximity.gelist.ge[0].id;
                        resolve(location);
                    })
                    .catch((err) => {
                        resolve(location);
                    });
            });
        });
        return promise;
    }
}
