import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
@Component({
  selector: 'app-voltage-virtual-ignition-gmt200',
  templateUrl: './voltage-virtual-ignition-gmt200.component.html',
  styleUrls: ['./voltage-virtual-ignition-gmt200.component.css'],
})
export class VoltageVirtualIgnitionGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() vvsParameters: Rest.VoltageVirtualIgnitionGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }

  modeOptions: any = [
    {label: "Disable the smart voltage adjustmen algorithm", value:"DISABLE_SMART_VOLTAGE_ADJUSTMENT"},
    {label: "Enable the smart voltage adjustment algorithm", value:"ENABLE"},
  ]

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.vvsParameters);
    }
  }

}
