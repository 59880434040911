import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-sensor-sensitivity-configuration-ob22',
  templateUrl: './sensor-sensitivity-configuration-ob22.component.html',
  styleUrls: ['./sensor-sensitivity-configuration-ob22.component.css']
})
export class SensorSensitivityConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {
  @Input() sensorSensitivity: Rest.SensorSensitivityConfigurationOB22;
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.sensorSensitivity);
    }
  }

}
