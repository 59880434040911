import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';


@Component({
  selector: 'app-instruction-password-configuration-ob22',
  templateUrl: './instruction-password-configuration-ob22.component.html',
  styleUrls: ['./instruction-password-configuration-ob22.component.css']
})
export class InstructionPasswordConfigurationOB22Component extends ServerValidatedFormComponent implements OnInit {

  @Input() instructionPassword: Rest.InstructionPasswordConfigurationOB22;
  @ViewChild('ContentDiv', { static: true }) ContentDiv;
  constructor(protected i18n: I18nService) {  super(i18n);}

 
  ngOnInit() {
    if(this.instructionPassword!=null){this.disableDIV(!this.instructionPassword.enabled)}
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.instructionPassword);
    }
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableDIV(!event.checked);
  }

  disableDIV(disable: Boolean) {
    
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.ContentDiv.nativeElement.classList.add('disableDIV');
    }
    else {
      this.ContentDiv.nativeElement.classList.remove('disableDIV');
    }
  }

}