import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-speed-alarm-gmt200-form',
  templateUrl: './speed-alarm-gmt200-form.component.html',
  styleUrls: ['./speed-alarm-gmt200-form.component.css'],
})
export class SpeedAlarmGmt200FormComponent extends ServerValidatedFormComponent implements OnInit {

  @Input() speedAlarmParameters: Rest.SpeedAlarmParametersGMT200;

  modeOptions: any = [
    {label: "Disable speed alarm",value:"DISABLE"},
    {label: "Enable when current speed in the range",value:"REPORT_IN"},
    {label: "Enable when current speed outside the range",value:"REPORT_OUT"},                     
    {label: "Enable when current speed within or outside the range",value:"REPORT_UPDATE"},              
  ];
 
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.speedAlarmParameters);
    }
  }

  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }

}
