import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { ClientService } from "../../../rest/client.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { EntityFormComponent } from "../../entity-form/entity-form.component";
import { TranslateService } from "@ngx-translate/core";
import { IconService } from 'app/rest/icon.service';

@Component({
    selector: "app-client-form",
    templateUrl: "./client-form.component.html",
    styleUrls: ["./client-form.component.scss"],
    providers: [ClientService, IconService],
})
export class ClientFormComponent
    extends EntityFormComponent<Rest.Client>
    implements OnInit {
    noticeChannels: SelectItem[];
    channelsList: Rest.NoticeChannel[];
    title: string;
    /*Permissions */
    disableSave: boolean;
    disabeChangeStatus: boolean;

    clientID: number;

    clientImage: any;
    clientImageFile: any;
    clientEncodedImage: string;

    entityEnumImageClient: Rest.IconEntityAssociationEnum = "CLIENT";

    profileImage: Rest.Icon;

    //FORM LABELS
    clientImageLabel: string;

    constructor(
        private clientService: ClientService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private iconService: IconService,
    ) {
        super(clientService, notificationsService, i18n, authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    /**
     * Loads and evaluates all the necessary data before showing the form.
     *
     * @memberof ClientFormComponent
     */
    beforeShow() {

        this.clientID = this.entity.id;

        this.clientImageLabel = this.translate.instant('control-panel.clients.clientImage');

        /*Permissions */
        if (this.isNew) {
            this.disableSave = false;
            this.title = this.i18n._("Create Client");
        } else {
            this.title = this.i18n._("Edit Client");
            if (this.entity["_$update"] === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
            if (this.entity["_$changeStatus"] === true) {
                this.disabeChangeStatus = false;
            } else {
                this.disabeChangeStatus = true;
            }

            // We are setting the value of the path of the image, depending if it's already set or not
            if (this.entity.clientLogo != null) {
                this.clientEncodedImage = this.entity.clientLogo.imageEncoded;
            } else {
                this.clientEncodedImage = '../../../../assets/images/UI_Elements/HD_transparent_picture.png'; //Reset the image to an empty png image from assets.
            }
        }
        /** */
    }

    /**
     * Takes a local image, and communicates with the API, in order to 
     * restore the client image.
     *
     * @param {*} event
     * @memberof ClientFormComponent
     */
    imageChange(event): void {
        let reader = new FileReader();
        this.clientImage = event.target.files[0];
        reader.onload = (event: any) => {
            this.clientEncodedImage = event.target.result;
        };
        //reader.readAsDataURL(event.target.files[0]);

        /* FORM DATA */
        const formData = new FormData();
        formData.append('myfile[]', this.clientImage);
        formData.append('entity', this.entityEnumImageClient);
        formData.append('clientId', this.clientID.toString());

        this.iconService.uploadIcon(formData).then((res) => {
            this.notificationsService.add(Severity.success, this.translate.instant('userProfile.settingImageTitle'), this.translate.instant('userProfile.settingsImageUpdatedMessage'));
            this.profileImage = res;
            this.clientEncodedImage = res.imageEncoded;
            this.authenticationService.updateUserProfile(this.profileImage);
        }).catch(() => {
            this.notificationsService.add(Severity.error, this.translate.instant('userProfile.settingsFailedImageTitle'), this.translate.instant('userProfile.settingsFailedImageMessage'));
        });
    }
}
