import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-start-stop-report-information-form-gv300can",
  templateUrl: "./start-stop-report-information-form-gv300can.component.html",
  styleUrls: ["./start-stop-report-information-form-gv300can.component.css"],
})
export class StartStopReportInformationFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() StartStopReport: Rest.StartStopReportGV300CAN;

  @ViewChild("StartStopReportDiv", { static: true }) StartStopReportDiv;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {
    if (this.StartStopReport != null) {
      this.disableAnalogInputDIV(!this.StartStopReport.startStopReportMode);
    }
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    this.disableAnalogInputDIV(!event.checked);
  }

  disableAnalogInputDIV(disable: Boolean) {
    if (disable) {
      //this.deviceParams.proprietaryParameters.analogInputFunction.mode = RestExt.AnalogInputModes[RestExt.AnalogInputModes.DISABLE] as Rest.AnalogInputModes;
      this.StartStopReportDiv.nativeElement.classList.add("disableDIV");
    } else {
      this.StartStopReportDiv.nativeElement.classList.remove("disableDIV");
    }
  }
}
