import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-axis-calibration-gmt200',
  templateUrl: './axis-calibration-gmt200.component.html',
  styleUrls: ['./axis-calibration-gmt200.component.css'],
})
export class AxisCalibrationGMT200Component extends ServerValidatedFormComponent implements OnInit {

  @Input() ascParameters: Rest.AxisCalibrationGMT200;
  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.ascParameters);
    }
  }

}
