<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Preserve Device
                    Special Logical State (AT + GTPDS)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Main
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Mode">
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="modeOptions" [(ngModel)]="pdsParameters.mode"
                        [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
        </div>
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mask
                                Settings</span>
                        </div>
                    </div>
                </p-header>
                <div class="row mt-3">
                    <form-input fieldName="Mask"></form-input>

                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="State of GEO" [(ngModel)]="pdsParameters.stateOfGeo">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="State of charging" [(ngModel)]="pdsParameters.charging">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Device reset type"
                                [(ngModel)]="pdsParameters.deviceResetType">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="Staet of digital inputs"
                                [(ngModel)]="pdsParameters.digitalInputs">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Information of last Known position"
                                [(ngModel)]="pdsParameters.lastKnownPosition">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="State in the command AT+GTLSW from EBK"
                                [(ngModel)]="pdsParameters.stateGTLSW">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Current device state"
                                [(ngModel)]="pdsParameters.deviceState">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="State in the command AT+GTTSW from EBK"
                                [(ngModel)]="pdsParameters.stateGTTSW">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="State of external power supply"
                                [(ngModel)]="pdsParameters.externalPowerSupply">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="State in the Command AT+GTOMS from EBK"
                                [(ngModel)]="pdsParameters.stateGTOMS">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>