<div id="pre-config-form">

  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <p-messages [(value)]="messages"></p-messages>
    </div>
  </div>
  <div *ngIf="firmwareValue !== undefined">
    <p-panel header="GMT200 Parameters">
      <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable"
              [options]="availablePreconfigs" [(ngModel)]="selectednpmPreconfig"
              [style]="{'width':'100%','min-width':'100px'}" filter="filter"></p-dropdown>
          </div>
        </div>

      </div>

      <p-tabView #tv (onChange)="tabChanged()">
        <p-tabPanel header="Events">
          <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
            <app-bearer-setting-information-gmt200
              [bsiParameters]="deviceParams.proprietaryParameters.bsiParameters"></app-bearer-setting-information-gmt200>
            <app-server-registration-information-gmt200
              [sriParameters]="deviceParams.proprietaryParameters.sriParameters"></app-server-registration-information-gmt200>
            <app-global-parameters-gmt200-form [globalParameters]="deviceParams.proprietaryParameters.globalParameters"
              [firmware]="firmwareValue">
            </app-global-parameters-gmt200-form>
            <app-protocol-watchdog-gmt200
              [dogParameters]="deviceParams.proprietaryParameters.dogParameters"></app-protocol-watchdog-gmt200>
            <app-preserve-device-logic-states-gmt200 [pdsParameters]="deviceParams.proprietaryParameters.pdsParameters"
              [firmware]="firmwareValue"></app-preserve-device-logic-states-gmt200>
            <app-fixed-report-information-gmt200-form
              [friParameters]="deviceParams.proprietaryParameters.friParameters">
            </app-fixed-report-information-gmt200-form>
            <app-frequency-change-fri-gmt200 [ffcParameters]="deviceParams.proprietaryParameters.ffcParameters"
              [firmware]="firmwareValue"></app-frequency-change-fri-gmt200>
            <app-geo-fence-configuration-gmt200 [geoParameters]="deviceParams.proprietaryParameters.geoParameters"
              [firmware]="firmwareValue"></app-geo-fence-configuration-gmt200>
            <app-tow-alarm-information-gmt200-form [towParameters]="deviceParams.proprietaryParameters.towParameters"
              [firmware]="firmwareValue">
            </app-tow-alarm-information-gmt200-form>
            <app-speed-alarm-gmt200-form
              [speedAlarmParameters]="deviceParams.proprietaryParameters.speedAlarmParameters">
            </app-speed-alarm-gmt200-form>
            <app-sos-gmt200-form
              [sosParameters]="deviceParams.proprietaryParameters.sosParameters"></app-sos-gmt200-form>
            <app-excessive-idling-detection-gmt200
              [idlParameters]="deviceParams.proprietaryParameters.idlParameters"></app-excessive-idling-detection-gmt200>
            <app-hbm-gmt200-form
              [hbmParameters]="deviceParams.proprietaryParameters.hbmParameters"></app-hbm-gmt200-form>
            <app-start-stop-report-information-gmt200-form
              [StartStopReport]="deviceParams.proprietaryParameters.startStopReport"></app-start-stop-report-information-gmt200-form>
            <app-jamming-behavior-setting-gmt200-form [jbsParameters]="deviceParams.proprietaryParameters.jbsParameters"
              [firmware]="firmwareValue">
            </app-jamming-behavior-setting-gmt200-form>
            <app-jamming-detection-information-gmt200-form
              [jammingDetection]="deviceParams.proprietaryParameters.jammingDetection"></app-jamming-detection-information-gmt200-form>
            <app-crash-detection-gmt200 [craParameters]="deviceParams.proprietaryParameters.craParameters"
              [firmware]="firmwareValue"></app-crash-detection-gmt200>
            <app-axis-calibration-gmt200
              [ascParameters]="deviceParams.proprietaryParameters.ascParameters"></app-axis-calibration-gmt200>
            <app-digital-output-port-setting-gmt200 [outParameters]="deviceParams.proprietaryParameters.outParameters"
              [firmware]="firmwareValue"></app-digital-output-port-setting-gmt200>
            <app-external-power-supply-configuration-gmt200
              [externalPowerSupply]="deviceParams.proprietaryParameters.externalPowerSupply"></app-external-power-supply-configuration-gmt200>
            <app-digital-input-port-setting-gmt200
              [disParameters]="deviceParams.proprietaryParameters.disParameters"></app-digital-input-port-setting-gmt200>
            <app-output-port-binding-gmt200
              [iobParameters]="deviceParams.proprietaryParameters.iobParameters"></app-output-port-binding-gmt200>
            <app-command-storage-configuration-gmt200
              [commandStorage]="deviceParams.proprietaryParameters.commandStorage"></app-command-storage-configuration-gmt200>
            <app-user-defined-functions-configuration-gmt200
              [userDefinedFunction]="deviceParams.proprietaryParameters.userDefinedFunction"
              [firmware]="firmwareValue"></app-user-defined-functions-configuration-gmt200>
            <div *ngIf="firmwareValue > 5">
              <app-virtual-ignition-selection-gmt200
                [vmsParameters]="deviceParams.proprietaryParameters.vmsParameters"></app-virtual-ignition-selection-gmt200>
              <app-voltage-virtual-ignition-gmt200
                [vvsParameters]="deviceParams.proprietaryParameters.vvsParameters"></app-voltage-virtual-ignition-gmt200>
              <app-accelerometer-virtual-ignition-settings-gmt200
                [avsParameters]="deviceParams.proprietaryParameters.avsParameters"></app-accelerometer-virtual-ignition-settings-gmt200>
            </div>

          </p-accordion>
        </p-tabPanel>

        <p-tabPanel header="Sensors">
          <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
          <!-- Sensors list -->
        </p-tabPanel>
        <p-tabPanel i18n-header header="Actuators">
          <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
        </p-tabPanel>

      </p-tabView>
    </p-panel>
  </div>

  <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>