import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { I18nService } from '../../../services/i18n/i18n.service';
import { RacingService } from '../../../rest/racing.service';
import { Rest } from '../../../rest/rest_client';
import { NotificationsService, Severity } from '../../../services/notifications-service/notifications.service';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { UserTimePipe } from '../../shared/UserTimePipe';
import { EntityFormComponent } from '../../entity-form/entity-form.component';
import { PoiService } from '../../../rest/poi.service';
import { ContextMenuOption, CustomMapOptions } from '../../shared/custom-map/custom-map.model';
import { CustomMapComponent } from '../../shared/custom-map/custom-map.component';
import { PoiRacingFormComponent } from '../../pois/poi-racing-form/poi-racing-form.component';
import { CercaliaMapService } from '../../../services/cercalia-map/cercalia-map.service';
import * as xml2js from 'xml2js';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import Racing = Rest.Racing;
import EventTypeRacing = Rest.EventTypeRacing;
import EventCodeTypeRacing = Rest.EventCodeTypeRacing;
import CodesEvents = Rest.CodesEvents;

@Component({
    selector: 'app-racing-form',
    templateUrl: './racing-form.component.html',
    styleUrls: ['./racing-form.component.css'],
    providers: [RacingService, ConfirmationService, PoiService],
})
export class RacingFormComponent extends EntityFormComponent<Rest.Racing> implements OnInit, DoCheck, OnChanges {
    @Input() selectedRacingEvent: Racing | null;
    @Input() eventTypes: EventTypeRacing[];
    @Input() eventCodeTypes: EventCodeTypeRacing[];
    @Output() racingEventAdded = new EventEmitter();
    @Output() racingEventSaved = new EventEmitter<any>();
    @ViewChild('poiRacingForm', { static: false }) poiForm: PoiRacingFormComponent;
    @ViewChild('errorContainer') errorContainer: ElementRef;

    @ViewChild('eventsMap', { static: false }) set eventsMap(eventsMap: CustomMapComponent) {
		console.log("****** EVENTS MAP *****");
		console.log(eventsMap);
        if (eventsMap) {

            if (!this.isNew) {
                this.customMapOptions = {
                    contextMenuOptions: [
                        <ContextMenuOption>{
                            id: '1',
                            label: this.translate.instant('events.pois.createPoi'),
                            callback: (event: any, position: any) => {
                                eventsMap.map.closePopups();
										  console.log(this.poiForm);
                                this.poiForm.showDialog(true, position);
                            },
                        },
                    ],
                };
            } else {
                this.customMapOptions = {
                    contextMenuOptions: [],
                };
            }

				console.log("-----");
				console.log(eventsMap.loadMapPromise);
            eventsMap.loadMapPromise.then(() => {
                this.map = eventsMap;
            });

        }
    }

    isDialogVisible: boolean;
    isEditingDisabled: boolean;
    errorMessages: string[];

    /* Racing Event general variables */
    racingEvent: Racing;
    eventImageUrl: string;
    eventImageFile: any;
    eventEncodedImage: any;
    eventTypeList: EventTypeRacing[];
    selectedEventType: EventTypeRacing;
    eventStartDate: Date | null;
    eventEndDate: Date | null;
    publicationStartDate: Date | null;
    publicationEndDate: Date | null;
    dateNow: Date;
    racingFormTitle: string;
    csvIconUrl: string;
    customMapOptions: CustomMapOptions;

    /* Event Code Type variables */
    eventCodeTypeList: EventCodeTypeRacing[];
    selectedEventCodeType: EventCodeTypeRacing;
    generalEventCode: CodesEvents;
    uniqueEventCodeList: CodesEvents[];

    /* POI variables */
    map: any;
    poiList: Rest.POIRacing[];
    selectedPoi: Rest.POIRacing | null;
    selectedTrack: any;
    showPoiDeleteConfirmDialog: boolean;
    entityKmlFileLoaded: boolean;
    disablePoiPanel: boolean;

    constructor(
        public racingService: RacingService,
        private authService: AuthenticationService,
        public i18n: I18nService,
        public notificationsService: NotificationsService,
        private userTime: UserTimePipe,
        private confirmationService: ConfirmationService,
        private mapService: CercaliaMapService,
        private _sanitizer: DomSanitizer,
        private translate: TranslateService,
    ) {
        super(racingService, notificationsService, i18n, authService);

        this.isEditingDisabled = false;
        this.errorMessages = [];

        this.eventStartDate = null;
        this.eventEndDate = null;
        this.publicationStartDate = null;
        this.publicationEndDate = null;
        this.dateNow = new Date(Date.now());

        this.poiList = [];
        this.selectedPoi = null;
        this.selectedTrack = {
            fileName: '',
            kmlFile: null,
        };
        this.showPoiDeleteConfirmDialog = false;
        this.entityKmlFileLoaded = false;
        this.disablePoiPanel = false;
        this.csvIconUrl = './assets/images/Racing/csv.png';

    }

    ngOnInit() {
		console.log("***** racing-form OnInit ****");
        super.ngOnInit();
    }

    ngDoCheck() {
        /* Await for map to be loaded */
        if (this.map?.map && !this.entityKmlFileLoaded) {

            /* When the map is loaded && if the kml file has not been loaded yet */
            this.loadEntityKmlFile();
        }
    }

    ngOnChanges(changes) {
		console.log("**** OnChanges ***** " );
		//console.log(this.selectedRacingEvent.id);
        /* If Event Types are loaded */
        if (changes.eventTypes?.currentValue) {
            this.eventTypeList = this.eventTypes;
            this.selectedEventType = this.eventTypeList[0];
        }

        /* If Event Code Types are loaded */
        if (changes.eventCodeTypes?.currentValue) {
            this.eventCodeTypeList = this.eventCodeTypes;
            this.selectedEventCodeType = this.eventCodeTypeList[0];
        }

        /* If Racing Event is loaded */
        if (changes.selectedRacingEvent?.currentValue) {
            this.initRacingEvent(false);
        }
    }

    //<editor-fold desc="General Methods">

    /**
     * showDialog()
     * */
    public showDialog(isNew: boolean): void {
		console.log("***** showDialog ***** ");
        this.initRacingFormDialog();
        this.isNew = isNew;
		  console.log("isNew: " + isNew);
        if (isNew) {
            this.initRacingEvent(true);
        }

        this.isDialogVisible = true;
    }

    /**
     * initRacingFormDialog()
     * */
    public initRacingFormDialog(): void {
        this.scrollWindowToTop();
        this.disablePoiPanel = false;
        this.errorMessages = [];
        this.map = null;
    }

    /**
     * validateRacingForm()
     * */
    private validateRacingForm(): boolean {
        this.errorMessages = [];

        /* Check if Name is empty */
        if (this.racingEvent.nameEvent === '') {
            this.errorMessages.push(
                this.translate.instant('error.required-field.name'),
            );
        }

        /* Check if Publication Start Date is empty */
        if (!this.publicationStartDate) {
            this.errorMessages.push(
                this.translate.instant('error.required-field.publicationStartDate'),
            );
        }

        /* Check if Publication End Date is empty */
        if (!this.publicationEndDate) {
            this.errorMessages.push(
                this.translate.instant('error.required-field.publicationEndDate'),
            );
        }

        /* Check if Event Start Date is empty */
        if (!this.eventStartDate) {
            this.errorMessages.push(
                this.translate.instant('error.required-field.startDate'),
            );
        }

        /* Check if Event End Date is empty */
        if (!this.eventEndDate) {
            this.errorMessages.push(
                this.translate.instant('error.required-field.endDate'),
            );
        }

        /* Check if Number of Persons is integer (split by .) and that it's positive ( > 0) */
        if (this.racingEvent.numPersons && (this.racingEvent.numPersons.toString().split('.').length !== 1 || this.racingEvent.numPersons < 0)) {
            this.errorMessages.push(
                this.translate.instant('error.positive-integer.numberPersons'),
            );
        }

        return this.errorMessages.length === 0;
    }

    /**
     * initRacingEvent()
     * */
    private initRacingEvent(isNew: boolean): void {
		console.log("***** initRacingEvent *****");
		console.log(this.selectedRacingEvent);
        /* If creating Event */
        if (isNew) {
            this.racingEvent = {
                id: 0,
                nameEvent: '',
                eventType: this.selectedEventType,
                eventCodeType: this.selectedEventCodeType,
                infoEvent: '',
                startPublicationDate: null,
                endPublicationDate: null,
                startDate: null,
                endDate: null,
                track: null,
                urlLegalText: '',
                deleted: false,
                deletedDate: null,
                logoEvent: null,
                numPersons: 0,
                usersRegistered: 0,
                client: null,
                poi: null,
                codeEvents: null,
            };

            this.isEditingDisabled = false;
            this.eventStartDate = null;
            this.eventEndDate = null;
            this.publicationStartDate = null;
            this.publicationEndDate = null;
            this.eventImageUrl = 'assets/icons/no-image-available.svg';
            this.selectedEventType = this.eventTypeList[0];
            this.selectedEventCodeType = this.eventCodeTypeList[0];
            this.uniqueEventCodeList = [];
            this.entityKmlFileLoaded = true;
            this.generalEventCode = {
                id: 0,
                code: '',
                usersRegistered: 0,
                deleted: false,
                dateDeleted: null,
            };
            this.selectedTrack = {
                fileName: '',
                kmlFile: null,
            };
            this.poiList = [];
        }

        /* If updating Event */
        else {
            /* Init racingEvent */
            this.racingEvent = {
                id: this.selectedRacingEvent.id,
                nameEvent: this.selectedRacingEvent.nameEvent,
                eventType: this.selectedRacingEvent.eventType,
                eventCodeType: this.selectedRacingEvent.eventCodeType,
                infoEvent: this.selectedRacingEvent.infoEvent,
                startPublicationDate: null,
                endPublicationDate: null,
                startDate: null,
                endDate: null,
                track: this.selectedRacingEvent.track,
                urlLegalText: this.selectedRacingEvent.urlLegalText,
                deleted: this.selectedRacingEvent.deleted,
                deletedDate: this.selectedRacingEvent.deletedDate,
                logoEvent: this.selectedRacingEvent.logoEvent,
                numPersons: this.selectedRacingEvent.numPersons,
                usersRegistered: this.selectedRacingEvent.usersRegistered,
                client: this.selectedRacingEvent.client,
                poi: this.selectedRacingEvent.poi,
                codeEvents: this.selectedRacingEvent.codeEvents,
            };

            if (this.selectedRacingEvent.logoEvent) {
                this.eventImageUrl = null;
                this.eventEncodedImage = this.selectedRacingEvent.logoEvent.imageEncoded;
            } else {
                this.eventImageUrl = 'assets/icons/no-image-available.svg';
                this.eventEncodedImage = null;
            }

            /* Init dates */
            this.publicationStartDate = new Date(Date.parse(this.selectedRacingEvent.startPublicationDate));
            this.publicationEndDate = new Date(Date.parse(this.selectedRacingEvent.endPublicationDate));
            this.eventStartDate = new Date(Date.parse(this.selectedRacingEvent.startDate));
            this.eventEndDate = new Date(Date.parse(this.selectedRacingEvent.endDate));

            /* Init Disable Editing */
            this.isEditingDisabled = this.dateNow > this.eventStartDate;

            /* Init Event Type */
            this.selectedEventType = this.selectedRacingEvent.eventType;

            /* Init Selected Event Code Type */
            this.selectedEventCodeType = this.selectedRacingEvent.eventCodeType;

            /* Init Event Code  */
            if (this.selectedRacingEvent.eventCodeType.uniqueCode) {
                if (this.selectedRacingEvent.codeEvents[0]) {
                    this.generalEventCode = this.selectedRacingEvent.codeEvents[0];
                } else {
                    this.generalEventCode = {
                        id: 0,
                        code: '',
                        usersRegistered: 0,
                        deleted: false,
                        dateDeleted: null,
                    };
                }
            } else {
                if (this.selectedRacingEvent.codeEvents?.length !== 0) {
                    this.uniqueEventCodeList = this.selectedRacingEvent.codeEvents;
                } else {
                    this.uniqueEventCodeList = [];
                }
            }

            /* Init KML track  */
				console.log("***** INIT KML TRACK ******");
				console.log(this.selectedRacingEvent.track);
            if (this.selectedRacingEvent.track) {
                this.selectedTrack.fileName = this.selectedRacingEvent.track.name;
                this.entityKmlFileLoaded = false;
            } else {
                this.selectedTrack = {
                    fileName: '',
                    kmlFile: null,
                };
                this.entityKmlFileLoaded = true;
            }

            /* Init POI list */
            this.poiList = this.selectedRacingEvent.poi;
        }
    }

    /**
     * saveRacingEvent()
     * */
    public saveRacingEvent(): void {

        if (this.validateRacingForm()) {

            /* Create custom Racing Event structure to send to API */
            let eventData = {
                id: this.isNew ? 0 : this.racingEvent.id,
                nameEvent: this.racingEvent.nameEvent,
                eventType: this.selectedEventType,
                eventCodeType: this.selectedEventCodeType,
                infoEvent: this.racingEvent.infoEvent,
                startPublicationDate: this.publicationStartDate.toISOString(),
                endPublicationDate: this.publicationEndDate.toISOString(),
                startDate: this.eventStartDate.toISOString(),
                endDate: this.eventEndDate.toISOString(),
                track: !this.selectedTrack.kmlFile ? this.racingEvent.track : null,
                urlLegalText: this.racingEvent.urlLegalText,
                deleted: false,
                deletedDate: null,
                logoEvent: !this.eventImageFile ? this.racingEvent.logoEvent : null,
                numPersons: this.racingEvent.numPersons,
                usersRegistered: this.racingEvent.usersRegistered ? this.racingEvent.usersRegistered : null,
                client: {
                    id: this.authService.user.client.id,
                },
                poi: this.poiList,
                codeEvents: null,
            };

            /* If the Event Code is general for all users */
            if (this.selectedEventCodeType.uniqueCode) {
                if (this.generalEventCode.code !== '') {
                    eventData.codeEvents = [this.generalEventCode];
                }
            }

            /* If the Event Code is unique for each user */
            else {
                if (this.uniqueEventCodeList.length !== 0) {
                    eventData.codeEvents = this.uniqueEventCodeList;
                }
            }

            /* Stringify the custom Racing Event structure */
            let racingEventString = JSON.stringify(eventData);

            /* Add new Racing Event */
            if (this.isNew) {
                this.racingService.addRacing(racingEventString, this.eventImageFile, this.selectedTrack.kmlFile).then((racingEvent) => {
                    if (racingEvent) {
                        this.racingEventAdded.emit();
                        this.isDialogVisible = false;
                    }
                });
            }

            /* Save existing Racing Event */
            else {
                this.racingService.updateRacing(racingEventString, this.eventImageFile, this.selectedTrack.kmlFile).then((racingEvent) => {
                    if (racingEvent) {
                        this.racingEventSaved.emit();
                        this.isDialogVisible = false;
                    }
                });

            }
        } else {
            this.scrollDialogToTop();
        }

    }

    /**
     * scrollWindowToTop()
     * */
    private scrollWindowToTop(): void {
        window.scroll({ top: 0, behavior: 'smooth' });
    }

    /**
     * scrollDialogToTop()
     * */
    private scrollDialogToTop(): void {
        this.errorContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    //</editor-fold>

    //<editor-fold desc="Racing Event General Information">

    /**
     * imageChange()
     * */
    public imageChange(event: any): void {
        let reader = new FileReader();
        this.eventImageFile = event.target.files[0];
        reader.onload = (event: any) => {
            this.eventImageUrl = event.target.result;
            this.eventEncodedImage = null;
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    /**
     * eventStartDateChange()
     * */
    public eventStartDateChange(): void {
        this.eventEndDate = null;
        this.publicationStartDate = null;
        this.publicationEndDate = null;
    }

    //</editor-fold>

    //<editor-fold desc="Event Code">

    /**
     * changeCSV()
     * */
    public changeCSV(event: any): void {
        let file = event.target.files[0];

        if (file) {

            if (file.type === 'text/csv') {
                let reader = new FileReader();
                reader.readAsText(file);

                reader.onload = (event: any) => {

                    /* Get text from file */
                    let stringFromFile = event.target.result;

                    /* Split text in by line break */
                    let linesFromFile = stringFromFile.split(/\r?\n|\r/);

                    this.uniqueEventCodeList = [];

                    /* For each split string, create a new Code Events entity */
                    linesFromFile.forEach((stringCode) => {
                        let code: CodesEvents = {
                            id: 0,
                            code: stringCode,
                            usersRegistered: 0,
                            deleted: false,
                            dateDeleted: null,
                        };

                        this.uniqueEventCodeList.push(code);
                    });

                };
            } else {
                this.notificationsService.add(Severity.error, 'Error', this.translate.instant('error.file-type.csv'));
            }

        }

    }

    //</editor-fold>

    //<editor-fold desc="POIS">

    /**
     * changeKml()
     * */
    public changeKml(event: any) {
			console.log("***** changeKml **** ");
        let kmlFile = event.target.files[0];
        let reader = new FileReader();
        let xml: any;

        reader.onload = (loadEvent: any) => {
            let result = loadEvent.target.result;

            /* Parse kml to a JSON structure */
            xml2js.parseString(result, (error, parsedXml) => {
                xml = parsedXml ? parsedXml : null;
            });

            /* Parse the XML JSON structure to wkt */
            this.parseKmlFile(xml)
                .then((wkt: string) => {
                    this.selectedTrack.kmlFile = kmlFile;
                    this.selectedTrack.fileName = kmlFile.name;

                    let feature = new this.mapService.cercalia.Feature({
                        strokeColor: '#c42c22',
                        fillColor: '#c42c22',
                        strokeWidth: 3,
                        outline: false,
                        wkt: wkt,
                    });

                    this.map.map.addFeature(feature);
                    this.map.map.centerToFeatures([feature]);
                })
                .catch((error) => {
                    this.notificationsService.add(Severity.error, 'Error', error);
                });
        };

        reader.readAsText(kmlFile);
    }

    /**
     * parseKmlFile()
     * */
    private parseKmlFile(xml: any): Promise<string> {
		console.log("***** parseKmlFile **** ");
        return new Promise<string>((resolve, reject) => {
            /* Search for the <LineString> tag inside the xml object */
            let lineStrings = this.findNestedObject(xml, 'LineString');
            let coordinates = [];

            /* Search for the <coordinates> tag inside the <LineString> objects */
            if (lineStrings.length !== 0) {
                lineStrings.forEach((coordinate) => {
                    coordinates.push(this.findNestedObject(coordinate, 'coordinates'));
                });
            } else {
                reject();
            }

            if (coordinates.length !== 0) {
                let parsedCoordinates: string = '';

                /* Clean each coordinate */
                coordinates.forEach((coordinate, index) => {

                    parsedCoordinates += this.cleanCoordinate(coordinate[0]);

                    if (index !== coordinates.length - 1) {
                        parsedCoordinates += ',';
                    }
                });

                /* Check if the coordinates are expressed in 2d or 3d and concatenate accordingly */
                if (this.getLineStringCoordinatesLength(parsedCoordinates) === 2) {
                    parsedCoordinates = 'LINESTRING(' + parsedCoordinates + ')';
                } else if (this.getLineStringCoordinatesLength(parsedCoordinates) === 3) {
                    parsedCoordinates = 'LINESTRING Z(' + parsedCoordinates + ')';
                } else {
                    reject();
                }

                resolve(parsedCoordinates);
            } else {
                reject(this.translate.instant('error.process.kml') + '!');
            }
        });
    }

    /**
     * loadEntityKmlFile()
     * */
    private loadEntityKmlFile(): void {
		console.log("***** loadEntityKmlFile **** ");
        if (this.selectedRacingEvent?.track) {

            let content = atob(this.selectedRacingEvent.track.imageEncoded);
            let xml: any;

            xml2js.parseString(content, (error, parsedXml) => {
                xml = parsedXml ? parsedXml : null;
            });

            this.parseKmlFile(xml)
                .then((wkt: string) => {

                    let feature = new this.mapService.cercalia.Feature({
                        strokeColor: '#c42c22',
                        fillColor: '#c42c22',
                        strokeWidth: 3,
                        outline: false,
                        wkt: wkt,
                    });

                    this.map.map.addFeature(feature);

                    this.map.map.centerToFeatures([feature]);
                })
                .catch((error) => {
                    this.notificationsService.add(Severity.error, 'Error', error);
                });
        }

        this.entityKmlFileLoaded = true;
    }

    /**
     * findNestedObject()
     * */
    private findNestedObject(objectToSearch, keyToFind): any {
        let foundObjects = [];

        JSON.stringify(objectToSearch, (_, nestedObject) => {
            if (nestedObject && nestedObject[keyToFind]) {
                foundObjects.push(nestedObject[keyToFind]);
            }
            return nestedObject;
        });

        return foundObjects;
    }

    /**
     * cleanCoordinate()
     * */
    private cleanCoordinate(coordinate): string {
        let parsedString = coordinate[0];

        /* STEP 1: Clean content from special characters */
        parsedString = parsedString.replace(/(?:\r|\n|\t)/g, '');

        /* STEP 2: If there is a leftover white space in the beginning, remove it */
        if (parsedString[0] === ' ') {
            parsedString = parsedString.trimStart(1);
        }

        /* STEP 3: If there are is a leftover white space at the end, remove it */
        if (parsedString[parsedString.length - 1] === ' ') {
            parsedString = parsedString.trimEnd(1);
        }

        /* STEP 4: Replace all white spaces to ';' to separate the pairs */
        parsedString = parsedString.replace(/\s/g, ';');

        /* STEP 5: Replace all ',' to white space to separate each coordinate inside the pairs */
        parsedString = parsedString.replace(/,/g, ' ');

        /* STEP 6: Replace all ';' back to white spaces to separate the pairs */
        parsedString = parsedString.replace(/;/g, ',');

        return parsedString;
    }

    /**
     * getLineStringCoordinatesLength()
     * */
    private getLineStringCoordinatesLength(lineString: string): number | null {
        let splitString = lineString.split(',');

        if (splitString[0]) {
            return splitString[0].split(' ').length;
        } else {
            return null;
        }
    }

    /**
     * addPoi()
     * */
    public addPoi(poi): void {
        this.poiList = [poi, ...this.poiList];
        this.entity.poi = this.poiList;
    }

    /**
     * savePoi()
     * */
    public savePoi(poi): void {
        let indexOfSelectedPoi = this.entity.poi.indexOf(this.selectedPoi);
        this.entity.poi[indexOfSelectedPoi] = poi;
    }

    /**
     * editPoi()
     * */
    public editPoi(poi): void {
        this.map.map.closePopups();
        this.selectedPoi = poi;
        this.poiForm.showDialog(false);
    }

    /**
     * deletePoi()
     * */
    public deletePoi(poi, poiIndex): void {
        this.selectedPoi = poi;

        let confirmationMessage = this.translate.instant('control-panel.delete-dialog.message') +
            ' ' +
            this.translate.instant('events.pois.thePoi') +
            ': ' +
            `<strong>` + poi.namePoi + '</strong>?';

        this.confirmationService.confirm({
            message: confirmationMessage,
            accept: () => {
                this.racingService.deletePoi(poi.id).then((isPoiDeleted: boolean) => {
                    if (isPoiDeleted) {

                        /* A sliced copy of poiList is created and assigned to the existing list to trigger PrimeNG's table to detect the change and update it */
                        this.poiList = this.poiList.slice(0, poiIndex).concat(this.poiList.slice(poiIndex + 1));
                    }
                });
            },
            reject: () => {
                this.selectedPoi = null;
            },
        });
    }

    //</editor-fold>
}
