import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { VehicleAccessoryService } from 'app/modules/vehicle-inventory/services/vehicle-accessory.service';
import { AccessoryService } from 'app/rest/accessory.service';
import { Rest } from 'app/rest/rest_client';
import { VehicleService } from 'app/rest/vehicle.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { RestExt } from 'app/services/rest-client-extension';
import { Subscription } from 'rxjs';
import { AlarmTypeService } from 'app/modules/vehicle-inventory/services/table-management/alarmType.service';

@Component({
  selector: 'app-inventory-vehicle-accessory-form',
  templateUrl: './inventory-vehicle-accessory-form.component.html',
  styleUrls: ['./inventory-vehicle-accessory-form.component.css'],
  providers:[AlarmTypeService]
})
export class InventoryVehicleAccessoryFormComponent extends EntityFormComponent<Rest.InvVehicleAccessory> implements OnInit, OnDestroy {

	@Input() vehicle: Rest.Vehicle;
	vehicleAccessory: Rest.InvVehicleAccessory = null;

	isDialogVisible: boolean;
	isEditingDisabled: boolean;
	errorMessages: string[];
	vehicleAccessoryFormTitle: string = "";
	panelTitle: string;
	errorContainer: ElementRef;

	editingSubscription: Subscription;
	creatingSubscription: Subscription;

	extrasTypeList: Rest.Accessory[] = [];
	alarmTypeList: Rest.InvAlarmType[] = [];
	verificationDate: Date = null;
	alarmTypeRequestContext: Rest.ListPaginationRequest;

	// PERMISSIONS
	verifiedGranted: boolean = false;
	allFieldsGranted: boolean = false;
	updateGranted: boolean = false;

	/**  */
   constructor(
      public vehicleAccessoryService: VehicleAccessoryService,
		public vehicleService: VehicleService,
      private authService: AuthenticationService,
      public i18n: I18nService,
      public notificationsService: NotificationsService,
		private translate: TranslateService,
		public accessoryService: AccessoryService,
		private datePipe: DatePipe,
		public alarmTypeService: AlarmTypeService,
    ) {
      super(vehicleAccessoryService, notificationsService, i18n, authService);
      this.errorMessages = [];
    }

    ngOnInit() {
   	super.ngOnInit();
		// console.log("[VEHICLE-ACCESSORY-FORM] ngOnInit ***");
		// console.log(this.vehicle);
		this.panelTitle = this.translate.instant('tableManagement.vehicle.vehicle') +
			": (" + this.vehicle.name + ") " +
			(this.vehicle.model?.brand !== null ? this.vehicle.model?.brand?.name : "") + " " +
			(this.vehicle.model !== null ? this.vehicle.model?.name : "") + " " +
			(this.vehicle.version !== null ? this.vehicle.version.name : "");
		this.editingSubscription = this.vehicleAccessoryService.startedEditing
      	.subscribe(
        	(item: Rest.InvVehicleAccessory) => {
				this.isNew = false;
				this.vehicleAccessoryFormTitle = this.translate.instant('tableManagement.accessories.editAccessory');
				this.showDialog(item);
         });
		this.creatingSubscription = this.vehicleAccessoryService.startedCreating
      	.subscribe(
        	() => {
				this.isNew = true;
				this.vehicleAccessoryFormTitle = this.translate.instant('tableManagement.accessories.createAccessory');
			   this.showDialog(null);
         });
		/** inicialitzacion **/
		this.vehicleAccessory = this.vehicleAccessoryService.init(this.vehicle);
		this.getExtrasTypeByClient(this.vehicle.client.id);

		this.alarmTypeRequestContext = RestExt.firstPageRequestVehicleInventory();
		this.alarmTypeRequestContext.pageSize = 1000000000;
		this.alarmTypeService.getPage(this.alarmTypeRequestContext).then((p) => {
			this.alarmTypeList = p.entities;
		});

		this.setPermissions();
    }

	ngOnDestroy() {
		this.editingSubscription.unsubscribe();
		this.creatingSubscription.unsubscribe();
	}

	setPermissions() {
		const user = this.authService.user;
		const rolesForForm = ['INV_EXTRAS_CREATE', 'INV_EXTRAS_UPDATE'];

		this.verifiedGranted = this.authService.isRoleGranted('INV_EXTRAS_UPDATE_VERIFIED', user);
		this.updateGranted = this.authService.isRoleGranted('INV_EXTRAS_UPDATE', user);

		this.allFieldsGranted = this.authService.isOneRoleGranted(rolesForForm, user);

		this.allFieldsGranted = this.allFieldsGranted || this.verifiedGranted;
	}

	public showDialog(item: Rest.InvVehicleAccessory): void {
		// console.log("[VEHICLE-ACCESSORY-FORM] showDialog ***");
		// console.log(item);
		if (this.isNew) {
			this.vehicleAccessory = this.vehicleAccessoryService.init(this.vehicle);
		}else{
			this.vehicleAccessory = item;
		}
		this.isDialogVisible = true;
		this.isEditingDisabled = false;
   }

	public onSaveVehicleAccessory() {

		this.isEditingDisabled = true;
		if (this.validateVehicleAccessoryForm()) {
			/** vehicle data */
			let vehicleAccessoryData = {
				id: this.isNew ? 0 : this.vehicleAccessory.id,
				vehicle: { "id": this.vehicle.id },
				description: this.vehicleAccessory.description,
				installationDate: this.vehicleAccessory.installationDate === null ? null : this.vehicleAccessory.installationDate.toISOString(),
				desinstallationDate: this.vehicleAccessory.desinstallationDate === null ? null : this.vehicleAccessory.desinstallationDate.toISOString(),
				alarmType: this.vehicleAccessory.alarmType,
				stationCode: this.vehicleAccessory.stationCode,
				serialNumber: this.vehicleAccessory.serialNumber,
				value: this.vehicleAccessory.value,
				verified: this.vehicleAccessory.verified,
				verificationDate: this.vehicleAccessory.verificationDate === null ? null : this.vehicleAccessory.verificationDate.toISOString(),
				extrasType: { "id": this.vehicleAccessory.extrasType.id},
				observations: this.vehicleAccessory.observations,
				client: { "id": this.vehicle.client.id},
				disabled: this.vehicleAccessory.disabled,
			};

			if (this.isNew) {
				this.createVehicle(vehicleAccessoryData);
			}else{
				this.onUpdateVehicle(vehicleAccessoryData);
			}

		}
	}

	public validateVehicleAccessoryForm(): Boolean {
		this.errorMessages = [];

		if (!this.vehicleAccessory.extrasType ||
			 !this.vehicleAccessory.description || this.vehicleAccessory.description === '' ||
			 !this.vehicleAccessory.installationDate ||
			
			 (this.vehicleAccessory.extrasType.code === 'AL' && !this.vehicleAccessory.alarmType)
		) {
			this.errorMessages.push(
				 this.translate.instant('error.required-field.generic'),
			);
			this.isEditingDisabled = false;
			window.scroll({ top: 0, behavior: 'smooth' });
	   }
		return this.errorMessages.length === 0;
	}

	public createVehicle(newAccessory) {
		this.isEditingDisabled = true;
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.vehicleAccessoryService
				.create(newAccessory)
				.then(
					 function (response) {
						that.vehicleAccessory = response;
						that.isDialogVisible = false;
						that.isEditingDisabled = false;
						that.notificationsService.add(
							Severity.success,
							that.translate.instant('general.generalInformation') + "!",
							that.translate.instant('Accessory ' + that.vehicleAccessory.id + ' created successful.'));
						that.vehicleAccessoryService.refreshList.next({ vehicleId: that.vehicle.id, startDate: new Date(), endDate: new Date() });
					 },
					 /* failure */
					 function (error) {
						console.log('The request failed: ' + error);
						  resolve();
					 },
				)
			.catch(function (reason) {
				console.log('Catched: ' + reason);
				resolve();
			});
		});
	}

	public onUpdateVehicle(newAccessory) {
		this.isEditingDisabled = true;


		if(this.updateGranted){
			const promise = new Promise<void>((resolve, reject) => {
				const that = this;
				this.vehicleAccessoryService
					.update(newAccessory)
					.then(
						function (response) {
							  that.vehicleAccessory = response;
							that.isDialogVisible = false;
							that.isEditingDisabled = false;
							that.notificationsService.add(
								Severity.success,
								that.translate.instant('general.generalInformation') + "!",
								that.translate.instant('Accessory ' + that.vehicleAccessory.id + ' updated successful.'));
							that.vehicleAccessoryService.refreshList.next({ vehicleId: that.vehicle.id, startDate: new Date(), endDate: new Date() });
						 },
						 /* failure */
						 function (error) {
							console.log('The request failed: ' + error);
							resolve();
						 },
					)
					.catch(function (reason) {
						console.log('Catched: ' + reason);
						resolve();
					});
			});
		}else if(this.verifiedGranted){
			const promise = new Promise<void>((resolve, reject) => {
				const that = this;
				this.vehicleAccessoryService
					.updateVerified(newAccessory)
					.then(
						function (response) {
							  that.vehicleAccessory = response;
							that.isDialogVisible = false;
							that.isEditingDisabled = false;
							that.notificationsService.add(
								Severity.success,
								that.translate.instant('general.generalInformation') + "!",
								that.translate.instant('Accessory ' + that.vehicleAccessory.id + ' updated successful.'));
							that.vehicleAccessoryService.refreshList.next({ vehicleId: that.vehicle.id, startDate: new Date(), endDate: new Date() });
						 },
						 /* failure */
						 function (error) {
							console.log('The request failed: ' + error);
							resolve();
						 },
					)
					.catch(function (reason) {
						console.log('Catched: ' + reason);
						resolve();
					});
			});
		}


		
	}

	/*********************/
	/**  EVENTS         **/
	/*********************/
	public toggleVerified(event) {
		// console.log("[VEHICLE-ACCESSORY-FORM] toggleVerified ***");
		// console.log(event);
		this.vehicleAccessory.verificationDate = event.checked === true ? new Date() : null;
	}

	public onExtrasTypeChange(event) {
		// console.log("[VEHICLE-ACCESSORY-FORM] onExtrasTypeChange ***");
		// console.log(event);
		/* reset dels valors que depenen del extrasType seleccionat */
		this.vehicleAccessory.alarmType = null;
		this.vehicleAccessory.stationCode = "";

		this.vehicleAccessory.description = event.value.name;
	}

	/*********************/
	/**  GET BD DATA    **/
	/*********************/
	public getExtrasTypeByClient(clientId: number) {
		// console.log("[VEHICLE-ACCESSORY-FORM] getExtrasTypeByClient ***");
		// console.log(clientId);
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			this.accessoryService
				.getListByClient(clientId.toString())
				.then(
			 		function (response) {
						that.extrasTypeList = response
						that.extrasTypeList.forEach(x => {
							x.fullName = x.name + (x.code !== null ? (" (" + x.code + ")") : "");
						});
						that.extrasTypeList.sort((a,b) => a.name > b.name ? 1 : -1);
						// console.log(that.extrasTypeList);
					},
			 		/* failure */
			 		function (error) {
						console.log('The request failed: ' + error);
				  		resolve();
			 		}
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
					resolve();
				});
		});
	}
}
