import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";

import RentingType = Rest.RentingType;

@Injectable()
export class RentingTypeService extends EntityService<RentingType> {
    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: RentingType): Rest.RestResponse<RentingType> {
        try {
            return this.client.RentingType_create(arg0);
        } catch (error) {
            console.error("Error creating RentingType:", error);
            throw error;
        }
    }

    update(arg0: RentingType): Rest.RestResponse<RentingType> {
        try {
            return this.client.RentingType_update(arg0);
        } catch (error) {
            console.error("Error updating RentingType:", error);
            throw error;
        }
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<RentingType>> {
        try {
            return this.client.RentingType_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        try {
            return this.client.RentingType_changeStatus(arg0, queryParams);
        } catch (error) {
            console.error("Error changing status:", error);
            throw error;
        }
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<RentingType> {
        try {
            return this.client.RentingType_find(arg0, queryParams);
        } catch (error) {
            console.error("Error finding RentingType:", error);
            throw error;
        }
    }
}