import { Injectable } from '@angular/core';
import { Rest } from '../../../rest/rest_client';
import { EntityService } from '../../../rest/entity.service';
import { JQueryHTTPClient } from '../../../rest/JQueryHTTPClient';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardsChartsService extends EntityService<any> {

    client: Rest.RestApplicationClient;

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    getDashBoardChartsData(arg0: Rest.DashBoardChartsRequest): Rest.RestResponse<Rest.DashBoardChartsResponse> {
        return this.client.DashboardsChartsRequest_get(arg0);
    }

    getDashBoardCoffeMakerUser(arg0: Rest.DashBoardChartsRequest): Rest.RestResponse<Rest.CoffeeMakerUseResponse> {
        return this.client.FetchHourlyCoffeeMakerUse(arg0);
    }

}

export interface ChartConfig {
    data: any[];  // Array of objects, each representing a data point
    colorScheme: any; // Object { domain: string[] }
    title: string; // Title of the chart
    xAxisLabel: string;
    yAxisLabel: string;
}