import { Component, OnInit, ViewChild, } from "@angular/core";
import { ConfirmationService, SelectItem } from "primeng/api";
import { DevicetypeService } from "../../rest/devicetype.service";
import { Rest } from "../../rest/rest_client";
import { PreconfigurationService } from "../../rest/preconfiguration.service";
import { AuthenticationService } from "../../core/services/authentication/authentication.service";
import { I18nService } from "../../services/i18n/i18n.service";
import { NotificationsService, Severity, } from "../../services/notifications-service/notifications.service";
import { RestExt } from "../../services/rest-client-extension";
import { VT10ParametersForm } from "../device-parameters/vt10-parameters-form/vt10-parameters-form.component";
import { ServerValidatedFormComponent } from "../server-validated-form/server-validated-form.component";
import { DeviceTypesListComponent } from "./device-types-list/device-types-list.component";
import {
    Gv300ParametersFormComponent
} from "../device-parameters/gv300-parameters-form/gv300-parameters-form.component";
import {
    GMT200ParametersFormComponent
} from "../device-parameters/gmt200-parameters-form/gmt200-parameters-form.component";
import {
    ConcoxMT200ParametersFormComponent
} from "../device-parameters/concox-mt200-parameters-form/concox-mt200-parameters-form.component";
import {
    WeTrack2ParametersFormComponent
} from "../device-parameters/we-track2-parameters-form/we-track2-parameters-form.component";
import {
    Gv300canParametersFormComponent
} from "../device-parameters/gv300can-parameters-form/gv300can-parameters-form.component";
import {
    Gv800ParametersFormComponent
} from "../device-parameters/gv800-parameters-form/gv800-parameters-form.component";
import { X8ParametersFormComponent } from "../device-parameters/x8-parameters-form/x8-parameters-form.component";
import {
    Vt200ParametersFormComponent
} from "../device-parameters/vt200-parameters-form/vt200-parameters-form.component";
import { EvixParametersFormComponent } from '../device-parameters/evix-parameters-form/evix-parameters-form.component';
import { IofrogParametersFormComponent } from '../device-parameters/iofrog-parameters-form/iofrog-parameters-form.component';
import { TeltonikaParametersFormComponent } from '../device-parameters/teltonika-parameters-form/teltonika-parameters-form.component';
import { Ob22ParametersFormComponent } from '../device-parameters/ob22-parameters-form/ob22-parameters-form.component';
import { Gl320mParametersFormComponent } from '../device-parameters/gl320m-parameters-form/gl320m-parameters-form.component';
import { SmartCoffeeParametersFormComponent } from "../device-parameters/smart-coffee-parameters-form/smart-coffee-parameters-form.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-device-type-edit-view",
    templateUrl: "./device-type-edit-view.component.html",
    styleUrls: ["./device-type-edit-view.component.css"],
    providers: [PreconfigurationService, DevicetypeService, ConfirmationService],
})
export class DeviceTypeEditViewComponent
    extends ServerValidatedFormComponent
    implements OnInit {
    @ViewChild(DeviceTypesListComponent, { static: true })
    deviceTypesList: DeviceTypesListComponent;
    @ViewChild(VT10ParametersForm) vt10ParametersForm: VT10ParametersForm;
    @ViewChild(Gv300ParametersFormComponent)
    Gv300ParametersFormComponent: Gv300ParametersFormComponent;
    @ViewChild(GMT200ParametersFormComponent)
    GMT200ParametersFormComponent: GMT200ParametersFormComponent;
    @ViewChild(ConcoxMT200ParametersFormComponent)
    ConcoxMT200ParametersFormComponent: ConcoxMT200ParametersFormComponent;
    @ViewChild(WeTrack2ParametersFormComponent)
    WeTrack2ParametersFormComponent: WeTrack2ParametersFormComponent;
    @ViewChild(Gv300canParametersFormComponent)
    Gv300canParametersFormComponent: Gv300canParametersFormComponent;
    @ViewChild(Gv800ParametersFormComponent)
    Gv800ParametersFormComponent: Gv800ParametersFormComponent;
    @ViewChild(X8ParametersFormComponent)
    X8ParametersFormComponent: X8ParametersFormComponent;
    @ViewChild(Vt200ParametersFormComponent)
    Vt200ParametersFormComponent: Vt200ParametersFormComponent;
    @ViewChild(EvixParametersFormComponent) EvixParametersFormComponent: EvixParametersFormComponent;
    @ViewChild(IofrogParametersFormComponent) IofrogParametersFormComponent: IofrogParametersFormComponent;
    @ViewChild(TeltonikaParametersFormComponent) TeltonikaParametersFormComponent: TeltonikaParametersFormComponent;
    @ViewChild(Ob22ParametersFormComponent) Ob22ParametersFormComponent: Ob22ParametersFormComponent;
    @ViewChild(Gl320mParametersFormComponent) Gl320mParametersFormComponent: Gl320mParametersFormComponent;
    @ViewChild(SmartCoffeeParametersFormComponent) SmartCoffeeParametersFormComponent: SmartCoffeeParametersFormComponent;
    isNewPreConfig = false;

    preConfigService: PreconfigurationService;
    preConfigSelected: Rest.DevicePreconfiguration;
    duplicatedPreConfig: Rest.DevicePreconfiguration;

    user: Rest.User;
    display: boolean = false;
    valor: string;
    availableFirmware: SelectItem[];

    constructor(
        deviceTypeService: DevicetypeService,
        protected i18n: I18nService,
        preConfigService: PreconfigurationService,
        protected confirmationService: ConfirmationService,
        public translateService: TranslateService,
        public notificationsService: NotificationsService,
        protected authenticationService: AuthenticationService
    ) {
        super(i18n);
        this.preConfigService = preConfigService;
        this.availableFirmware = RestExt.getEnumItemsNoTranslation(
            RestExt.FirmwareVersion
        );
    }

    ngOnInit() {
        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    refreshDeviceTypeList(event) {
        this.deviceTypesList.ngOnInit();
        if (event) {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._("Entities updated")
            );
        } else {
            this.notificationsService.add(
                Severity.success,
                this.i18n._(":)"),
                this.i18n._("Entity deleted")
            );
        }
    }

    save() {
        this.clearMessages();
        //this.messages = [];

        if (this.isNewPreConfig) {
            console.log(
                "just created this.preConfigSelected: ",
                this.preConfigSelected
            );
            this.preConfigService
                .create(this.preConfigSelected)
                .then(
                    (res: Rest.DevicePreconfiguration) => {
                        this.notificationsService.add(
                            Severity.success,
                            this.i18n._(":)"),
                            this.i18n._("Entity created")
                        );
                        this.deviceTypesList.ngOnInit();
                        this.resetRecordZone();
                    },
                    (err) => {
                        console.log(err);
                        this.handleError(err, this);
                        if (this.vt10ParametersForm) {
                            this.vt10ParametersForm.handleError(err, this);
                        }
                        if (this.Gv300ParametersFormComponent) {
                            this.Gv300ParametersFormComponent.handleError(err, this);
                        }
                        if (this.GMT200ParametersFormComponent) {
                            this.GMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.ConcoxMT200ParametersFormComponent) {
                            this.ConcoxMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.WeTrack2ParametersFormComponent) {
                            this.WeTrack2ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv300canParametersFormComponent) {
                            this.Gv300canParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv800ParametersFormComponent) {
                            this.Gv800ParametersFormComponent.handleError(err, this);
                        }
                        if (this.X8ParametersFormComponent) {
                            this.X8ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Vt200ParametersFormComponent) {
                            this.Vt200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.EvixParametersFormComponent) {
                            this.EvixParametersFormComponent.handleError(err, this);
                        }
                        if (this.IofrogParametersFormComponent) {
                            this.IofrogParametersFormComponent.handleError(err, this);
                        }
                        if (this.TeltonikaParametersFormComponent) {
                            this.TeltonikaParametersFormComponent.handleError(err, this);
                        }

                        if (this.Ob22ParametersFormComponent) {
                            this.Ob22ParametersFormComponent.handleError(err, this);
                        }

                        if (this.Gl320mParametersFormComponent) {
                            this.Gl320mParametersFormComponent.handleError(err, this);
                        }

                        if (this.SmartCoffeeParametersFormComponent) {
                            this.SmartCoffeeParametersFormComponent.handleError(err, this);
                        }

                    }
                )
                .catch((e) => {
                    this.handleError(e, this);
                    //this.vt10ParametersForm.handleError(e, this);
                    if (this.vt10ParametersForm) {
                        this.vt10ParametersForm.handleError(e, this);
                    }
                    if (this.Gv300ParametersFormComponent) {
                        this.Gv300ParametersFormComponent.handleError(e, this);
                    }
                    if (this.GMT200ParametersFormComponent) {
                        this.GMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.ConcoxMT200ParametersFormComponent) {
                        this.ConcoxMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.WeTrack2ParametersFormComponent) {
                        this.WeTrack2ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv300ParametersFormComponent) {
                        this.Gv300canParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv800ParametersFormComponent) {
                        this.Gv800ParametersFormComponent.handleError(e, this);
                    }
                    if (this.X8ParametersFormComponent) {
                        this.X8ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Vt200ParametersFormComponent) {
                        this.Vt200ParametersFormComponent.handleError(e, this);

                    }
                    if (this.EvixParametersFormComponent) {
                        this.EvixParametersFormComponent.handleError(e, this);
                    }
                    if (this.IofrogParametersFormComponent) {
                        this.IofrogParametersFormComponent.handleError(e, this);
                    }
                    if (this.TeltonikaParametersFormComponent) {
                        this.TeltonikaParametersFormComponent.handleError(e, this);
                    }
                    if (this.Ob22ParametersFormComponent) {
                        this.Ob22ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gl320mParametersFormComponent) {
                        this.Gl320mParametersFormComponent.handleError(e, this);
                    }
                    if (this.SmartCoffeeParametersFormComponent) {
                        this.SmartCoffeeParametersFormComponent.handleError(e, this);
                    }
                });
        } else {
            console.log(
                "already created this.preConfigSelected: ",
                this.preConfigSelected
            );

            this.preConfigService
                .update(this.preConfigSelected)
                .then(
                    (res: Rest.DevicePreconfiguration) => {
                        this.notificationsService.add(
                            Severity.success,
                            "",
                            this.translateService.instant('general.updateSuccessfull')
                        );
                        this.deviceTypesList.ngOnInit();
                        this.resetRecordZone();
                    },
                    (err) => {
                        console.log(err);
                        this.handleError(err, this);
                        //this.vt10ParametersForm.handleError(err, this);
                        if (this.vt10ParametersForm) {
                            this.vt10ParametersForm.handleError(err, this);
                        }
                        if (this.Gv300ParametersFormComponent) {
                            this.Gv300ParametersFormComponent.handleError(err, this);
                        }
                        if (this.GMT200ParametersFormComponent) {
                            this.GMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.ConcoxMT200ParametersFormComponent) {
                            this.ConcoxMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.WeTrack2ParametersFormComponent) {
                            this.WeTrack2ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv300canParametersFormComponent) {
                            this.Gv300canParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv800ParametersFormComponent) {
                            this.Gv800ParametersFormComponent.handleError(err, this);
                        }
                        if (this.X8ParametersFormComponent) {
                            this.X8ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Vt200ParametersFormComponent) {
                            this.Vt200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.EvixParametersFormComponent) {
                            this.EvixParametersFormComponent.handleError(err, this);
                        }
                        if (this.IofrogParametersFormComponent) {
                            this.IofrogParametersFormComponent.handleError(err, this);
                        }
                        if (this.TeltonikaParametersFormComponent) {
                            this.TeltonikaParametersFormComponent.handleError(err, this);
                        }
                        if (this.Ob22ParametersFormComponent) {
                            this.Ob22ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gl320mParametersFormComponent) {
                            this.Gl320mParametersFormComponent.handleError(err, this);
                        }
                        if (this.SmartCoffeeParametersFormComponent) {
                            this.SmartCoffeeParametersFormComponent.handleError(err, this);
                        }
                    }
                )
                .catch((e) => {
                    this.handleError(e, this);
                    //this.vt10ParametersForm.handleError(e, this);
                    if (this.vt10ParametersForm) {
                        this.vt10ParametersForm.handleError(e, this);
                    }
                    if (this.Gv300ParametersFormComponent) {
                        this.Gv300ParametersFormComponent.handleError(e, this);
                    }
                    if (this.GMT200ParametersFormComponent) {
                        this.GMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.ConcoxMT200ParametersFormComponent) {
                        this.ConcoxMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.WeTrack2ParametersFormComponent) {
                        this.WeTrack2ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv300canParametersFormComponent) {
                        this.Gv300canParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv800ParametersFormComponent) {
                        this.Gv800ParametersFormComponent.handleError(e, this);
                    }
                    if (this.X8ParametersFormComponent) {
                        this.X8ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Vt200ParametersFormComponent) {
                        this.Vt200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.EvixParametersFormComponent) {
                        this.EvixParametersFormComponent.handleError(e, this);
                    }
                    if (this.IofrogParametersFormComponent) {
                        this.IofrogParametersFormComponent.handleError(e, this);
                    }
                    if (this.TeltonikaParametersFormComponent) {
                        this.TeltonikaParametersFormComponent.handleError(e, this);
                    }
                    if (this.Ob22ParametersFormComponent) {
                        this.Ob22ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gl320mParametersFormComponent) {
                        this.Gl320mParametersFormComponent.handleError(e, this);
                    }
                    if (this.SmartCoffeeParametersFormComponent) {
                        this.SmartCoffeeParametersFormComponent.handleError(e, this);
                    }
                });
        }
    }

    delete() {

        var preConfigList: Rest.DevicePreconfiguration[] = [];
        preConfigList.push(this.preConfigSelected);
        this.preConfigService
            .delete(preConfigList)
            .catch((e) => {
                this.handleError(e, this);
                if (this.vt10ParametersForm) {
                    this.vt10ParametersForm.handleError(e, this);
                }
                if (this.Gv300ParametersFormComponent) {
                    this.Gv300ParametersFormComponent.handleError(e, this);
                }
                if (this.GMT200ParametersFormComponent) {
                    this.GMT200ParametersFormComponent.handleError(e, this);
                }
                if (this.ConcoxMT200ParametersFormComponent) {
                    this.ConcoxMT200ParametersFormComponent.handleError(e, this);
                }
                if (this.WeTrack2ParametersFormComponent) {
                    this.WeTrack2ParametersFormComponent.handleError(e, this);
                }
                if (this.Gv300canParametersFormComponent) {
                    this.Gv300canParametersFormComponent.handleError(e, this);
                }
                if (this.Gv800ParametersFormComponent) {
                    this.Gv800ParametersFormComponent.handleError(e, this);
                }
                if (this.X8ParametersFormComponent) {
                    this.X8ParametersFormComponent.handleError(e, this);
                }
                if (this.Vt200ParametersFormComponent) {
                    this.Vt200ParametersFormComponent.handleError(e, this);
                }
                if (this.EvixParametersFormComponent) {
                    this.EvixParametersFormComponent.handleError(e, this);
                }
                if (this.IofrogParametersFormComponent) {
                    this.IofrogParametersFormComponent.handleError(e, this);
                }
                if (this.TeltonikaParametersFormComponent) {
                    this.TeltonikaParametersFormComponent.handleError(e, this);
                }
                if (this.Ob22ParametersFormComponent) {
                    this.Ob22ParametersFormComponent.handleError(e, this);
                }
                if (this.Gl320mParametersFormComponent) {
                    this.Gl320mParametersFormComponent.handleError(e, this);
                }
                if (this.SmartCoffeeParametersFormComponent) {
                    this.SmartCoffeeParametersFormComponent.handleError(e, this);
                }
            });

        this.notificationsService.add(
            Severity.success,
            this.i18n._(":)"),
            this.i18n._("Entity deleted")
        );
        this.deviceTypesList.ngOnInit();
        this.resetRecordZone();
    }

    loadNode(event) {
        console.log("loadNode");
        this.clearMessages();
        const typeNode: String = event.data;
        const that = this;
        this.isNewPreConfig = typeNode.search("AddPreConfig") == 0;

        if (!this.isNewPreConfig) {
            this.preConfigService.find(typeNode.split("-")[1]).then((response) => {
                console.log("hh response: ", response);
                this.preConfigSelected = response;
                this.preConfigSelected.deviceType = response.deviceType;
                console.log("hh this.preConfigSelected: ", this.preConfigSelected);
            });
        } else {
            this.resetRecordZone();
            /*console.log("this.preConfigSelected = 23re23e23e223e")
              this.preConfigSelected = <Rest.DevicePreconfiguration>{id:0,name:"", deviceType:"VT10",parameters:RestExt.emptyVT10Params(), firmware:undefined};
              this.preConfigSelected.firmware = undefined;*/
        }
    }

    //Change diveceType when we select the firmware in the dropdown
    public firmwareChange(event) {
        this.isNewPreConfig = true;

        if (this.preConfigSelected.firmware === "GV300") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "GV300",
                parameters: RestExt.emptyGV300Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "GV300";
            this.preConfigSelected.parameters.proprietaryParameters.type = "GV300";
        } else if (this.preConfigSelected.firmware.startsWith("VT10")) {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "VT10",
                parameters: RestExt.emptyVT10Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "VT10";
            this.preConfigSelected.parameters.proprietaryParameters.type = "VT10";
        } else if (this.preConfigSelected.firmware.startsWith("GMT200")) {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "GMT200",
                parameters: RestExt.emptyGMT200Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "GMT200";
            this.preConfigSelected.parameters.proprietaryParameters.type = "GMT200";
        } else if (this.preConfigSelected.firmware === "ConcoxMT200") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "ConcoxMT200",
                parameters: RestExt.emptyConcoxMT200Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "ConcoxMT200";
            this.preConfigSelected.parameters.proprietaryParameters.type =
                "ConcoxMT200";
        } else if (this.preConfigSelected.firmware === "WeTrack2") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "WeTrack2",
                parameters: RestExt.emptyWeTrack2Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "WeTrack2";
            this.preConfigSelected.parameters.proprietaryParameters.type = "WeTrack2";
        } else if (this.preConfigSelected.firmware === "GV300CAN") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "GV300CAN",
                parameters: RestExt.emptyGV300CANParams(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "GV300CAN";
            this.preConfigSelected.parameters.proprietaryParameters.type = "GV300CAN";
        } else if (this.preConfigSelected.firmware === "GV800") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "GV800",
                parameters: RestExt.emptyGV800Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "GV800";
            this.preConfigSelected.parameters.proprietaryParameters.type = "GV800";
        } else if (this.preConfigSelected.firmware.startsWith("X8")) {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "X8",
                parameters: RestExt.emptyX8Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "X8";
            this.preConfigSelected.parameters.proprietaryParameters.type = "X8";
        } else if (this.preConfigSelected.firmware === "VT200") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "VT200",
                parameters: RestExt.emptyVT200Params(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "VT200";
            this.preConfigSelected.parameters.proprietaryParameters.type = "VT200";
        } else if (this.preConfigSelected.firmware.startsWith('EVIX')) {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "EVIX",
                parameters: RestExt.emptyEVIXParams(),
                firmware: this.preConfigSelected.firmware
            };
            this.preConfigSelected.deviceType = 'EVIX';
            this.preConfigSelected.parameters.proprietaryParameters.type = 'EVIX';
        } else if (this.preConfigSelected.firmware === "Iofrog") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "Iofrog",
                parameters: RestExt.emptyIofrogParams(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "Iofrog";
            this.preConfigSelected.parameters.proprietaryParameters.type = "Iofrog";
        } else if (this.preConfigSelected.firmware === "Teltonika") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "Teltonika",
                parameters: RestExt.emptyTeltonikaParams(),
                firmware: this.preConfigSelected.firmware,
            };
            this.preConfigSelected.deviceType = "Teltonika";
            this.preConfigSelected.parameters.proprietaryParameters.type = "Teltonika";
        } else if (this.preConfigSelected.firmware === "OB22") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "OB22",
                parameters: RestExt.emptyOB22Params(),
                firmware: this.preConfigSelected.firmware
            }
        } else if (this.preConfigSelected.firmware === "GL320M") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "GL320M",
                parameters: RestExt.emptyGL320MParams(),
                firmware: this.preConfigSelected.firmware
            }
        } else if (this.preConfigSelected.firmware === "SmartCoffee") {
            this.preConfigSelected = <Rest.DevicePreconfiguration>{
                id: 0,
                name: this.preConfigSelected.name,
                deviceType: "SmartCoffee",
                parameters: RestExt.emptySmartCoffeeParams(),
                firmware: this.preConfigSelected.firmware
            }
        }

        console.log(
            "this.preConfigSelected.deviceType: ",
            this.preConfigSelected.deviceType
        );
    }

    resetRecordZone() {
        // That allow us save a delte record instanly
        this.isNewPreConfig = true;
        // reset object
        this.preConfigSelected = <Rest.DevicePreconfiguration>{
            id: 0,
            name: "",
            deviceType: "VT10",
            parameters: RestExt.emptyVT10Params(),
            firmware: undefined,
        };
        // reset dropdown
        this.preConfigSelected.firmware = undefined;
    }

    duplicate() {
        this.display = true;
        this.valor = this.preConfigSelected.name + "_copia";
        this.duplicatedPreConfig = null;
    }

    saveDuplicate() {
        this.display = false;

        this.duplicatedPreConfig = this.preConfigSelected;
        this.duplicatedPreConfig.name = this.valor;
        this.duplicatedPreConfig.id = 0;

        let nameAlreadyExist = this.deviceTypesList.preConfigExist(this.duplicatedPreConfig.deviceType, this.duplicatedPreConfig.name);

        if (nameAlreadyExist) {
            this.notificationsService.add(
                Severity.error,
                "ERROR",
                "This name is already used!")
            this.deviceTypesList.ngOnInit();
            this.resetRecordZone();
        } else {
            this.preConfigService
                .create(this.duplicatedPreConfig)
                .then(
                    (res: Rest.DevicePreconfiguration) => {
                        this.notificationsService.add(
                            Severity.success,
                            this.i18n._(":)"),
                            this.i18n._("Entity created")
                        );
                        this.deviceTypesList.ngOnInit();
                        this.resetRecordZone();
                    },
                    (err) => {
                        console.log(err);
                        this.handleError(err, this);
                        if (this.vt10ParametersForm) {
                            this.vt10ParametersForm.handleError(err, this);
                        }
                        if (this.Gv300ParametersFormComponent) {
                            this.Gv300ParametersFormComponent.handleError(err, this);
                        }
                        if (this.GMT200ParametersFormComponent) {
                            this.GMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.ConcoxMT200ParametersFormComponent) {
                            this.ConcoxMT200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.WeTrack2ParametersFormComponent) {
                            this.WeTrack2ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv300canParametersFormComponent) {
                            this.Gv300canParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gv800ParametersFormComponent) {
                            this.Gv800ParametersFormComponent.handleError(err, this);
                        }
                        if (this.X8ParametersFormComponent) {
                            this.X8ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Vt200ParametersFormComponent) {
                            this.Vt200ParametersFormComponent.handleError(err, this);
                        }
                        if (this.EvixParametersFormComponent) {
                            this.EvixParametersFormComponent.handleError(err, this);
                        }
                        if (this.IofrogParametersFormComponent) {
                            this.IofrogParametersFormComponent.handleError(err, this);
                        }
                        if (this.TeltonikaParametersFormComponent) {
                            this.TeltonikaParametersFormComponent.handleError(err, this);
                        }
                        if (this.Ob22ParametersFormComponent) {
                            this.Ob22ParametersFormComponent.handleError(err, this);
                        }
                        if (this.Gl320mParametersFormComponent) {
                            this.Gl320mParametersFormComponent.handleError(err, this);
                        }
                        if (this.SmartCoffeeParametersFormComponent) {
                            this.SmartCoffeeParametersFormComponent.handleError(err, this);
                        }
                    }
                )
                .catch((e) => {
                    this.handleError(e, this);
                    if (this.vt10ParametersForm) {
                        this.vt10ParametersForm.handleError(e, this);
                    }
                    if (this.Gv300ParametersFormComponent) {
                        this.Gv300ParametersFormComponent.handleError(e, this);
                    }
                    if (this.GMT200ParametersFormComponent) {
                        this.GMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.ConcoxMT200ParametersFormComponent) {
                        this.ConcoxMT200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.WeTrack2ParametersFormComponent) {
                        this.WeTrack2ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv300ParametersFormComponent) {
                        this.Gv300canParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gv800ParametersFormComponent) {
                        this.Gv800ParametersFormComponent.handleError(e, this);
                    }
                    if (this.X8ParametersFormComponent) {
                        this.X8ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Vt200ParametersFormComponent) {
                        this.Vt200ParametersFormComponent.handleError(e, this);
                    }
                    if (this.EvixParametersFormComponent) {
                        this.EvixParametersFormComponent.handleError(e, this);
                    }
                    if (this.IofrogParametersFormComponent) {
                        this.IofrogParametersFormComponent.handleError(e, this);
                    }
                    if (this.TeltonikaParametersFormComponent) {
                        this.TeltonikaParametersFormComponent.handleError(e, this);
                    }
                    if (this.Ob22ParametersFormComponent) {
                        this.Ob22ParametersFormComponent.handleError(e, this);
                    }
                    if (this.Gl320mParametersFormComponent) {
                        this.Gl320mParametersFormComponent.handleError(e, this);
                    }
                    if (this.SmartCoffeeParametersFormComponent) {
                        this.SmartCoffeeParametersFormComponent.handleError(e, this);
                    }
                });
        }
    }

    onChange(value: string) {
        this.valor = value;
    }

    notifyNewPreConfig($event: any) {
        // TODO: Method "notifyNewPreConfig" was missing in legacy code!!!
    }
}
