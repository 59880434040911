import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'app-fixed-report-information-gmt200-form',
  templateUrl: './fixed-report-information-gmt200-form.component.html',
  styleUrls: ['./fixed-report-information-gmt200-form.component.css'],
})
export class FixedReportInformationGmt200FormComponent extends ServerValidatedFormComponent implements OnInit, OnChanges {

  @Input() friParameters: Rest.FixedReportInformationGMT200;

  modeOptions: SelectItem[]; 
  trackModeSwitch = false;
  
  constructor(protected i18n: I18nService) {  
    super(i18n);
    this.modeOptions = [
      {label: "Disable function",value:"Disable"},
      {label: "Fixed Time Report",value:"Time"},
      {label: "Fixed Distance Report",value:"Distance"},                     
      {label: "Fixed Mileage Report",value:"Mileage"},                     
      {label: "Optimum Report",value:"Optimum"},                                          
    ];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.friParameters);
    }
  }
 

  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    } 
  }
}