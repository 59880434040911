import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-global-parameters-form-gv800',
  templateUrl: './global-parameters-form-gv800.component.html',
  styleUrls: ['./global-parameters-form-gv800.component.css'],
})
export class GlobalParametersFormGv800Component extends ServerValidatedFormComponent implements OnInit {

  @Input() globalParameters: Rest.GlobalParametersGV800;

  

  powerSavingOptions: any = [
    {label: "Disable the power saving function.",value:0},
    {label: "Mode 1 of the power saving function.",value:1},
    {label: "Mode 2 of the power saving function.",value:2}, 
    {label: "Mode 3 of the power saving function.", value:3},                    
  ];
 
  GSMReportOptions: any = [
    {label: "Do not allow the cell information report.",value:0},
    {label: "Report the cell information after failing to get GPS position if cell information is available.",value:1},
    {label: "Report the message +RESP:GTGSM after getting GPS position successfully every time if cell information is available.",value:2},
    {label: "Report the message +RESP:GTGSM regardless of GPS position fix result every time if cell information is available.",value:3},                     
  ];

  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }

  /*trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }*/

}
