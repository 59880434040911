import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Rest } from 'app/rest/rest_client';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../service/translations.service';

@Component({
  selector: 'app-translations-form',
  templateUrl: './translations-form.component.html',
  styleUrls: ['./translations-form.component.css']
})
export class TranslationsFormComponent extends EntityFormComponent<Rest.Translations> implements OnInit, OnDestroy {

  isDialogVisible: boolean;
  space: Rest.FidSpace;
  errorMessages: string[];
  editMode = false;
  spaceFormTitle: string;
  isTextArea: boolean;
  translations: Rest.Translations;

  @Output() translationsSaved = new EventEmitter<Rest.Translations>();
  subscription: Subscription;

  constructor(
	public translationsService: TranslationsService,
	private authService: AuthenticationService,
	public i18n: I18nService,
	public notificationsService: NotificationsService,
	private translate: TranslateService
	) {
		super(translationsService, notificationsService, i18n, authService);
		this.editMode = false;
      this.errorMessages = [];
	}

  	ngOnInit(): void {
		// console.log("[TRANSLATIONS] ***** on-init *****");
   	super.ngOnInit();
		this.subscription = this.translationsService.startEditTranslations
      .subscribe(
         (item: Rest.Translations) => {
				// console.log(item.type);
				this.showDialog(item);
         }
      );
	}

  	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
   * showDialog()
   * */
	public showDialog(translations: Rest.Translations): void {
		// console.log("[TRANSLATIONS] ***** showDialog ***** ");
		// console.log(translations);
		this.errorMessages = [];
		this.isTextArea = translations.textArea;
		window.scroll({ top: 0, behavior: 'smooth' });
		this.isDialogVisible = true;
		this.initSpace(translations);
	}

	public initSpace(translations: Rest.Translations) {
		this.translations = translations;
	}

	public onSaveTranslations(): void {
		// console.log("[TRANSLATIONS] **** onSaveTranslations *****");
		// console.log(this.translations);
		// if (this.validateTranslations()) {
			this.isDialogVisible = false;
			this.translationsSaved.emit(this.translations);
		// }
	}

	// private validateTranslations(): boolean {
	// 	this.errorMessages = [];

	// 	if (this.translations.esp === "") {
	// 		this.errorMessages.push(
	// 			this.translate.instant('error.required-field.generic'),
	// 	  );
	// 	}
	// 	return this.errorMessages.length === 0;
	// }

}
