<p-dialog header={{roleFormTitle}}
          [(visible)]="display"
          [responsive]="true"
          [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
          [modal]="true"
          [draggable]="true"
          *ngIf="display"
          [style]="{'min-width':'50%'}"
>

  <div class="ui-grid ui-grid-responsive">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <p-tabView #tv>

      <p-tabPanel i18n-header header="General" [style]="{'display':'flex'}">

        <div class="row align-items-center">

          <div class="col row dvcFields mt-2">
            <div class="col lblStyle">
              <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
              </form-input>
            </div>
            <div class="col">
              <input pInputText id="name" [(ngModel)]="entity.name" class="txtbStyle">
            </div>
          </div>
        
          <div class="col row dvcFields mt-2">
            <div class="col lblStyle">
              <form-input i18n-fieldName fieldName="description" [errors]="errors.description">
              </form-input>
            </div>
            <div class="col">
              <input pInputText id="description" [(ngModel)]="entity.description" class="txtbStyle">
            </div>
          </div>
        
        </div>

      </p-tabPanel>

      <p-tabPanel *ngIf="fleetTab" i18n-header header="{{'control-panel.vehicles.fleet' | translate}}">
        <app-fleet-tree [(selection)]="fleetsGroupsAndVehiclesSelected" [viewDisableds]=false [cascadeSelection]=true
          [selectableEntities]=false></app-fleet-tree>
      </p-tabPanel>

      <p-tabPanel *ngIf="maintenancePlanTab" i18n-header header="{{'control-panel.maintenance-plans.maintenancePlan' | translate}}">
        <paged-picklist [paginationRequest]="MaintenancePlanRequestContext"
          [pageFunction]="this.maintenancePlanService.getPage.bind(this.maintenancePlanService)" labelProperty="name"
          [(rightListEntities)]="entity.visibleMaintenancePlans">
        </paged-picklist>
      </p-tabPanel>

      <p-tabPanel *ngIf="zoneGroupTab" i18n-header header="{{'control-panel.zone-groups.zoneGroup' | translate}}">
        <paged-picklist [paginationRequest]="ZoneGroupRequestContext"
          [pageFunction]="this.zoneGroupService.getPage.bind(this.zoneGroupService)" labelProperty="name"
          [(rightListEntities)]="entity.visibleZoneGroups"></paged-picklist>
      </p-tabPanel>

      <p-tabPanel *ngIf="poiCategoryTab" i18n-header header="{{'control-panel.poi-categories.poiCategory' | translate}}">
        <paged-picklist [paginationRequest]="poiCategoryRequestContext"
          [pageFunction]="this.poiCategoryService.getPage.bind(this.poiCategoryService)" labelProperty="name"
          [(rightListEntities)]="entity.visiblePoisCategory"></paged-picklist>
      </p-tabPanel>

      <p-tabPanel *ngIf="deviceTab" i18n-header header="{{'general.device' | translate}}">
        <paged-picklist [paginationRequest]="deviceRequestContext"
          [pageFunction]="this.deviceService.getPage.bind(this.deviceService)"
          labelProperty="externalId | imei | phoneNumber"
          [(rightListEntities)]="entity.visibleDevices"></paged-picklist>
      </p-tabPanel>

      <p-tabPanel *ngIf="notificationGroupTab" i18n-header header="{{'control-panel.notifications-groups.notificationGroup' | translate}}">
        <paged-picklist [paginationRequest]="noticeGroupRequestContext"
          [pageFunction]="this.noticegroupsService.getPage.bind(this.noticegroupsService)" labelProperty="name"
          [(rightListEntities)]="entity.visibleNoticeGroups"></paged-picklist>
      </p-tabPanel>

      <p-tabPanel *ngIf="delegationsTab" i18n-header header="{{'tableManagement.delegations.delegations' | translate}}">
        <paged-picklist [paginationRequest]="delegationsRequestContext"
          [pageFunction]="this.delegationService.getPage.bind(this.delegationService)" labelProperty="name"
          [(rightListEntities)]="entity.visibleDelegations"></paged-picklist>
      </p-tabPanel>



    </p-tabView>

  </div>

  <p-footer>

    <div class="d-flex justify-content-end">
      <button type="button" pButton icon="fa fa-check" (click)="save()" i18n-label label="Save"></button>
    </div>


  </p-footer>

</p-dialog>
