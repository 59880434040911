<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Speed Alarm
          (AT + GTSPD)</span>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="col row dvcFields">
          <div class="col lblStyle">
            <form-input fieldName="Mode">
            </form-input>
          </div>
          <div class="col">
            <p-dropdown (onChange)="trackEventModeChange(speedAlarmParameters.mode,$event)" [options]="modeOptions"
              [(ngModel)]="speedAlarmParameters.mode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
          </div>
        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Min Speed">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.minSpeed" [min]="0" [max]="400"></p-spinner>
                <span class="ui-inputgroup-addon">0~400</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Max Speed">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.maxSpeed" [min]="0" [max]="400"></p-spinner>
                <span class="ui-inputgroup-addon">5~400</span>
                <span class="ui-inputgroup-addon">km/h</span>
              </div>
            </div>
          </div>

        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Duration">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.duration" [min]="0" [max]="3600"></p-spinner>
                <span class="ui-inputgroup-addon">0~3600</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Send Interval">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.sendInterval" [min]="0" [max]="3600"></p-spinner>
                <span class="ui-inputgroup-addon">0~3600</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>

        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="speedAlarmParameters.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>

        </div>

        <div class="row align-items-center">

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Duration">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.durationOutput" [min]="0" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">0~255</span>
                <span class="ui-inputgroup-addon">(x100ms)</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Toggle Times">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="speedAlarmParameters.toggleTimes" [min]="0" [max]="255"></p-spinner>
                <span class="ui-inputgroup-addon">0~255</span>
              </div>
            </div>
          </div>

        </div>

      </p-accordionTab>
    </div>

  </div>
</p-accordionTab>