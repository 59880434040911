
<!-- TODO: VERY IMPORTANT: PrimeNG TurboTable Implemented! Pending:
1. Check correct functionality;
2. Remove unused code;
3. Add translations;
5. Fix search box for {{conversionAlgorithm}}
-->

<!-- PrimeNG DataTable -->
<p-table #dt [value]="actuators"
         [rows]="10"
         [totalRecords]="actuators !==null ? actuators.length: 0"
         [rowsPerPageOptions]="[10,20,30,40,50]"
         [paginator]="true"
         [(selection)]="selectedEntities"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [autoLayout]="true"
         currentPageReportTemplate=
         "{first} - {last} {{ 'table.of' | translate }} {totalRecords}"
>

  <!-- Table Column/Header Definition -->
  <ng-template pTemplate="header">
    <tr>

      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Conversion Algorithm</div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Name</div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Tag</div>
      </th>

      <th>
        <div class="p-d-flex p-jc-between p-ai-center">Port</div>
      </th>

    </tr>
  </ng-template>

  <!-- Table Row Definition -->
  <ng-template pTemplate="body" let-actuator>
    <tr class="p-selectable-row" (dblclick)="editDialog(actuator)">

      <td>
        <p-tableCheckbox [value]="actuator"></p-tableCheckbox>
      </td>

      <td>
        <span class="p-column-title">Conversion Algorithm</span>
        <div *ngIf="actuator.conversionAlgorithm != null">
          <span>{{actuator.conversionAlgorithm.name}}</span>
        </div>
      </td>

      <td>
        <span class="p-column-title">Name</span>
        {{actuator.name}}
      </td>

      <td>
        <span class="p-column-title">Tag</span>
        {{actuator.tag}}
      </td>

      <td>
        <span class="p-column-title">Port</span>
        {{actuator.port}}
      </td>

    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">
        <p i18n>No records found</p>
      </td>
    </tr>
  </ng-template>

</p-table>

<!--<p-dataTable [value]=actuators [totalRecords]="actuators!==null?actuators.length:0" [rows]="10" [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,40,50]"
    [paginator]="true" [(selection)]="selectedEntities" resizableColumns="true" (onRowDblclick)="onRowDblClick($event)"
    #dt>

    <p-header>
        <ng-container i18n>Actuators</ng-container>
    </p-header>

    &lt;!&ndash; <p-column [style]="{'width':'38px'}" selectionMode="multiple"></p-column> &ndash;&gt;
    <p-column [style]="{'width':'38px'}">
        <ng-template pTemplate="header" let-col>
            <p-checkbox [(ngModel)]="selectedAll" binary="true" (onChange)="selectAll(selectedAll)" [style]="{'vertical-align':'middle'}"></p-checkbox>
        </ng-template>
        <ng-template let-col let-actuator="rowData" let-ri="rowIndex" pTemplate="body">
            <div (dblclick)="selectableColumn()">
                <p-checkbox [(ngModel)]="entitiesSelected[actuator.id]" binary="true" (onChange)="changeSelection(actuator.id, entitiesSelected[actuator.id])"
                    [style]="{'vertical-align':'middle'}"></p-checkbox>
            </div>
        </ng-template>
    </p-column>

    <p-column field="conversionAlgorithm" i18n-header header="Conversion Algorithm" [filter]="false" i18n-filterPlaceholder filterPlaceholder="Search"
     filterMatchMode="in">
    <ng-template let-col let-actuator="rowData" let-ri="rowIndex" pTemplate="body">
        <div *ngIf="actuator.conversionAlgorithm !== null">
            <span>{{actuator.conversionAlgorithm.name}}</span>
        </div>
    </ng-template>
    </p-column>

    <p-column field="name" i18n-header header="Name" [style]="{'cursor':'pointer'}">
        <ng-template pTemplate="body" let-col let-actuator="rowData">
            <div (dblclick)="editDialog(actuator)">
                <span>{{actuator.name}}</span>
            </div>
        </ng-template>
    </p-column>

    <p-column field="tag" i18n-header header="Tag" [style]="{'cursor':'pointer'}">
        <ng-template pTemplate="body" let-col let-actuator="rowData">
            <div (dblclick)="editDialog(actuator)">
                <span>{{actuator.tag}}</span>
            </div>
        </ng-template>
    </p-column>

    <p-column field="port" i18n-header header="Port" [style]="{'cursor':'pointer'}">
        <ng-template pTemplate="body" let-col let-actuator="rowData">
            <div (dblclick)="editDialog(actuator)">
                <span>{{actuator.port}}</span>
            </div>
        </ng-template>
    </p-column>

    <ng-template pTemplate="emptymessage">
        <p i18n>No records found</p>
    </ng-template>
</p-dataTable>-->

<div class="ui-helper-clearfix" style="width:100%"></div>

<p-confirmDialog i18n-header header="Confirmation" icon="fa fa-question-circle"></p-confirmDialog>

<div class="ui-helper-clearfix mt-3" style="width:100%; height: 50px;" *ngIf="user.isAdmin === true">
    <button type="button" pButton icon="fa fa-plus" style="float:left" (click)="create()" i18n-label label="Add"></button>
    <div *ngIf="selectedEntities.length > 0">
        <button type="button" pButton icon="fa fa-close" style="float:left" (click)="delete()" i18n-label label="Remove selected"></button>
    </div>
</div>

<app-actuator-form></app-actuator-form>
