<p-accordionTab>
    <p-header>
        <div class="ui-helper-clearfix">
            <div class="left">
                <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Global
                    Configuration (AT + GTCFG)</span>
            </div>
        </div>
    </p-header>

    <div class="ui-g">
        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Device Working Status
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="GPS On Need">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="gpsOptions" [(ngModel)]="globalParameters.gpsMode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="GPS Fix Delay">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="globalParameters.gpsDelay" [min]="5"
                                    [max]="60"></p-spinner>
                                <span class="ui-inputgroup-addon">5~60</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="AGPS Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="agpsOptions" [(ngModel)]="globalParameters.agpsMode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="LED On">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="ledOnOptions" [(ngModel)]="globalParameters.ledON"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col">
                        <p-checkbox binary="true" label="Expiry Enable" [(ngModel)]="globalParameters.expiryEnable">
                        </p-checkbox>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Expiry Time">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <input type="text" pInputText [(ngModel)]="globalParameters.expiryTime" maxlength="14"
                                    class="txtbStyle" />
                            </div>
                        </div>
                    </div>

                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Mask
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row mt-3">
                    <form-input fieldName="Report Item Mask"></form-input>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Speed" [(ngModel)]="globalParameters.speed">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="Send Time" [(ngModel)]="globalParameters.sendTime">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Azimuth" [(ngModel)]="globalParameters.azimuth">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="Device Name" [(ngModel)]="globalParameters.deviceName">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Altitude" [(ngModel)]="globalParameters.altitude">
                            </p-checkbox>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="Cell Information"
                                [(ngModel)]="globalParameters.cellInformation">
                            </p-checkbox>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <form-input fieldName="Event Mask"></form-input>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTPNA"
                                [(ngModel)]="globalParameters.eventMaskRESPGTPNA">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTSTC"
                                [(ngModel)]="globalParameters.eventMaskRESPGTSTC">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTPFA"
                                [(ngModel)]="globalParameters.eventMaskRESPGTPFA">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTSTT"
                                [(ngModel)]="globalParameters.eventMaskRESPGTSTT">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTEPN"
                                [(ngModel)]="globalParameters.eventMaskRESPGTEPN">
                            </p-checkbox>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTEPF"
                                [(ngModel)]="globalParameters.eventMaskRESPGTEPF">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTPDP"
                                [(ngModel)]="globalParameters.eventMaskRESPGTPDP">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTPNL"
                                [(ngModel)]="globalParameters.eventMaskRESPGTPNL">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTBPL"
                                [(ngModel)]="globalParameters.eventMaskRESPGTBPL">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTIGN / GTIGF"
                                [(ngModel)]="globalParameters.eventMaskRESPGTIGNGTIGF">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTBTC"
                                [(ngModel)]="globalParameters.eventMaskRESPGTBTC">
                            </p-checkbox>
                        </div>
                        <div class="col">
                            <p-checkbox binary="true" label="+RESP:GTIGL"
                                [(ngModel)]="globalParameters.eventMaskRESPGTIGL">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <form-input fieldName="Location Request Mask"></form-input>
                    <div class="row mt-3">
                        <p-checkbox binary="true" label="SMS location request"
                            [(ngModel)]="globalParameters.smsLocationRequest">
                        </p-checkbox>
                    </div>
                </div>
            </p-accordionTab>
        </div>

        <div class="ui-g-12">
            <p-accordionTab [selected]="true">
                <p-header>
                    <div class="ui-helper-clearfix">
                        <div class="left">
                            <span class="ui-panel-title"
                                style="font-size:16px;display:inline-block;margin-top:2px">Other Function
                                Settings</span>
                        </div>
                    </div>
                </p-header>

                <div class="row align-items-center">

                    <div class="col">
                        <p-checkbox binary="true" label="Info Report Enable"
                            [(ngModel)]="globalParameters.infoReportEnable">
                        </p-checkbox>
                    </div>

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Info Report Interval">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="globalParameters.infoReportInterval"
                                    [min]="30" [max]="86400"></p-spinner>
                                <span class="ui-inputgroup-addon">30~86400</span>
                                <span class="ui-inputgroup-addon">sec</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="EPB Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="epbOptions" [(ngModel)]="globalParameters.epbMode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col">
                        <p-checkbox binary="true" label="Battery Switch Power On"
                            [(ngModel)]="globalParameters.batteryPowerOn">
                        </p-checkbox>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="GSM Report"></form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="gsmOptions" [(ngModel)]="globalParameters.gsmMode"
                                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>

                    <div class="col">
                        <p-checkbox binary="true" label="+RESP:GTRTL" [(ngModel)]="globalParameters.eventMaskRESPGTRTL">
                        </p-checkbox>
                    </div>

                    <div class="col">
                        <p-checkbox binary="true" label="+RESP:GTFRI / GTERI"
                            [(ngModel)]="globalParameters.eventMaskRESPGTFRIGTERI">
                        </p-checkbox>
                    </div>

                </div>

                <div class="row align-items-center">

                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Battery Low Percentage">
                            </form-input>
                        </div>
                        <div class="col">
                            <div class="ui-inputgroup">
                                <p-spinner size="5" step="1" [(ngModel)]="globalParameters.batteryLowPercentage" [min]="0"
                                    [max]="30"></p-spinner>
                                <span class="ui-inputgroup-addon">0~30</span>
                                <span class="ui-inputgroup-addon">%</span>
                            </div>
                        </div>
                    </div>
                
                    <div class="col row dvcFields">
                        <div class="col lblStyle">
                            <form-input fieldName="Walking Mode">
                            </form-input>
                        </div>
                        <div class="col">
                            <p-dropdown [options]="walkingOptions" [(ngModel)]="globalParameters.walkingMode"
                            [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                        </div>
                    </div>
                
                </div>
            </p-accordionTab>
        </div>
    </div>
</p-accordionTab>