<div class="d-flex flex-row p-3 gap-3">

    <!-- DEVICE TYPES LIST-->
    <div class="d-flex w-25 overflow-auto">
        <app-device-types-list (nodeSelected)="loadNode($event)"></app-device-types-list>
    </div>

    <!-- DEVICE TYPE PRE CONFIG -->
    <div class="d-flex flex-column flex-grow-1" *ngIf="preConfigSelected !== undefined">

        <div class="ui-grid ui-grid-responsive ui-fluid">

            <div class="ui-grid-row">
                <div class="ui-grid-col-12">
                    <p-messages [(value)]="messages"></p-messages>
                </div>
            </div>

            <div class="container">

                <!-- ROW 1 -->
                <div class="row align-items-center mt-4">
                    <form-input [required]="true" i18n-fieldName fieldName="name" [errors]="errors.name">
                        <input class="form-control input-lg" pInputText id="name" [(ngModel)]="preConfigSelected.name"
                               [style]="{'width':'250px'}"/>
                    </form-input>
                </div>

                <!-- ROW 2 -->
                <div class="row align-items-center mt-4 mb-4">
                    <form-input [required]="true" i18n-fieldName fieldName="firmware version"
                                [errors]="errors.firmware">
                        <p-dropdown #ddFirmware id="firmware" i18n-placeholder placeholder="Select Firmware"
                                    [options]="availableFirmware"
                                    (onChange)="firmwareChange($event)" [(ngModel)]="preConfigSelected.firmware"
                                    [style]="{'width':'250px'}"></p-dropdown>
                    </form-input>
                </div>

                <!--suppress AngularUndefinedBinding -->
                <app-vt10-parameters-form *ngIf="preConfigSelected.deviceType==='VT10'"
                                          [deviceParams]="preConfigSelected.parameters"
                                          [isConfig]=false
                                          [firmware]=preConfigSelected.firmware
                                          (newPreConfigVT10)="notifyNewPreConfig($event)"
                                          (refreshList)="refreshDeviceTypeList($event)"
                ></app-vt10-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-gv300-parameters-form *ngIf="preConfigSelected.deviceType==='GV300'"
                                           [deviceParams]="preConfigSelected.parameters"
                                           [isConfig]=false
                                           [firmware]=preConfigSelected.firmware
                                           (newPreConfigGV300)="notifyNewPreConfig($event)"
                                           (refreshList)="refreshDeviceTypeList($event)"
                ></app-gv300-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-gmt200-parameters-form *ngIf="preConfigSelected.deviceType==='GMT200'"
                                            [deviceParams]="preConfigSelected.parameters"
                                            [isConfig]=false
                                            [firmware]=preConfigSelected.firmware
                                            (newPreConfigGMT200)="notifyNewPreConfig($event)"
                                            (refreshList)="refreshDeviceTypeList($event)"
                ></app-gmt200-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-concox-mt200-parameters-form *ngIf="preConfigSelected.deviceType==='ConcoxMT200'"
                                                  [deviceParams]="preConfigSelected.parameters"
                                                  [isConfig]=false
                                                  [firmware]=preConfigSelected.firmware
                                                  (newPreConfigConcoxMT200)="notifyNewPreConfig($event)"
                                                  (refreshList)="refreshDeviceTypeList($event)"
                ></app-concox-mt200-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-we-track2-parameters-form *ngIf="preConfigSelected.deviceType==='WeTrack2'"
                                               [deviceParams]="preConfigSelected.parameters"
                                               [isConfig]=false
                                               [firmware]=preConfigSelected.firmware
                                               (newPreConfigWeTrack2)="notifyNewPreConfig($event)"
                                               (refreshList)="refreshDeviceTypeList($event)"
                ></app-we-track2-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-gv300can-parameters-form *ngIf="preConfigSelected.deviceType==='GV300CAN'"
                                              [deviceParams]="preConfigSelected.parameters"
                                              [isConfig]=false
                                              [firmware]=preConfigSelected.firmware
                                              (newPreConfigGV300CAN)="notifyNewPreConfig($event)"
                                              (refreshList)="refreshDeviceTypeList($event)"
                ></app-gv300can-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-gv800-parameters-form *ngIf="preConfigSelected.deviceType==='GV800'"
                                           [deviceParams]="preConfigSelected.parameters"
                                           [isConfig]=false
                                           [firmware]=preConfigSelected.firmware
                                           (newPreConfigGV800)="notifyNewPreConfig($event)"
                                           (refreshList)="refreshDeviceTypeList($event)"
                ></app-gv800-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-x8-parameters-form *ngIf="preConfigSelected.deviceType==='X8'"
                                        [deviceParams]="preConfigSelected.parameters"
                                        [isConfig]=false
                                        [firmware]=preConfigSelected.firmware
                                        (newPreConfigX8)="notifyNewPreConfig($event)"
                                        (refreshList)="refreshDeviceTypeList($event)"
                ></app-x8-parameters-form>

                <!--suppress AngularUndefinedBinding -->
                <app-vt200-parameters-form *ngIf="preConfigSelected.deviceType==='VT200'"
                                           [deviceParams]="preConfigSelected.parameters"
                                           [isConfig]=false
                                           [firmware]=preConfigSelected.firmware
                                           (newPreConfigVT200)="notifyNewPreConfig($event)"
                                           (refreshList)="refreshDeviceTypeList($event)"
                ></app-vt200-parameters-form>

                <app-evix-parameters-form *ngIf="preConfigSelected.deviceType==='EVIX'"
                                          [deviceParams]="preConfigSelected.parameters"
                                          [isConfig]=false
                                          [firmware]=preConfigSelected.firmware
                                          (newPreConfigEVIX)="notifyNewPreConfig($event)"
                                          (refreshList)="refreshDeviceTypeList($event)"
                ></app-evix-parameters-form>
                <app-iofrog-parameters-form *ngIf="preConfigSelected.deviceType==='Iofrog'"
                                            [deviceParams]="preConfigSelected.parameters"
                                            [isConfig]=false
                                            [firmware]=preConfigSelected.firmware
                                            (newPreConfigIofrog)="notifyNewPreConfig($event)"
                                            (refreshList)="refreshDeviceTypeList($event)"
                ></app-iofrog-parameters-form>
                <app-teltonika-parameters-form *ngIf="preConfigSelected.deviceType==='Teltonika'"
                                            [deviceParams]="preConfigSelected.parameters"
                                            [isConfig]=false
                                            [firmware]=preConfigSelected.firmware
                                            (newPreConfigTeltonika)="NotifyNewPreConfig($event)"
                                            (refreshList)="refreshDeviceTypeList($event)"
                ></app-teltonika-parameters-form>
                <app-ob22-parameters-form *ngIf="preConfigSelected.deviceType==='OB22'"
                                            [deviceParams]="preConfigSelected.parameters"
                                            [isConfig]=false
                                            [firmware]=preConfigSelected.firmware
                                            (newPreConfigTeltonika)="NotifyNewPreConfig($event)"
                                            (refreshList)="refreshDeviceTypeList($event)"
                ></app-ob22-parameters-form>
                <app-gl320m-parameters-form *ngIf="preConfigSelected.deviceType === 'GL320M'"
                                            [deviceParams]="preConfigSelected.parameters"
                                            [isConfig]=false
                                            [firmware]=preConfigSelected.firmware
                                            (newPreConfigTeltonika)="NotifyNewPreConfig($event)"
                                            (refreshList)="refreshDeviceTypeList($event)"
                ></app-gl320m-parameters-form>
                <app-smart-coffee-parameters-form *ngIf="preConfigSelected.deviceType === 'SmartCoffee'"
                                                  [deviceParams]="preConfigSelected.parameters"
                                                  [isConfig]=false
                                                  [firmware]=preConfigSelected.firmware
                                                  (newPreConfigSmartCoffee)="NotifyNewPreConfig($event)"
                                                  (refreshList)="refreshDeviceTypeList($event)"
                ></app-smart-coffee-parameters-form>


                <div class="d-flex justify-content-end mt-3" *ngIf="user.isAdmin === true">
                    <p-dialog header="Confirmation" [(visible)]="display" [style]="{width: '50vw'}">
                        <ng-template pTemplate="content">
                            <div class="d-flex display-inline-block mb-4 ms-5 mt-4">
                                <i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i>
                                <h6 style="margin-left: 10px">Are you sure do you want to duplicate?</h6>
                            </div>
                            <div class="d-flex display-inline-block mb-4 ms-5">
                                <h5 style="margin-right: 50%">Name (*)</h5>
                                <input id="name" (change)="onChange($event.target.value)" style="width: 250px;"
                                       value="{{valor}}">
                            </div>
                        </ng-template>

                        <ng-template pTemplate="footer">
                            <p-button icon="pi pi-check" (click)="saveDuplicate()" label="OK"
                                      class="p-button-text"></p-button>
                        </ng-template>
                    </p-dialog>

                    <div *ngIf="preConfigSelected.firmware !== undefined">
                        <button style="width:140px;" type="button" [disabled]="isNewPreConfig" pButton
                                icon="fa fa-plus-circle" (click)="duplicate()" i18n-label label="Duplicate"></button>
                    </div>

                    <div *ngIf="preConfigSelected.firmware !== undefined">
                        <button style="width:140px; min-width: 120px; margin-left: 5%;" type="button" pButton
                                icon="fa fa-check" (click)="save()" i18n-label label="Save"></button>
                    </div>

                    <div *ngIf="preConfigSelected.firmware !== undefined">
                        <button style="width:140px; min-width: 120px; margin-left: 10%;" [disabled]="isNewPreConfig"
                                type="button" pButton icon="fa fa-close" (click)="delete()" i18n-label
                                label="Remove"></button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
