<div id="pre-config-form">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>
  
    <p-panel header="GL320M Parameters">
        <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
          <div class="ui-g-12 ui-md-6">
        
            <div class="col row dvcFields">
              <div class="col lblStyle">
                <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                </form-input>
              </div>
              <div class="col">
                <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable"
                  [options]="availablePreconfigs" [(ngModel)]="selectedPreconfig" [style]="{'width':'100%','min-width':'100px'}"
                  filter="filter"></p-dropdown>
              </div>
            </div>
        
          </div>
        </div>
  
      <p-tabView #tv (onChange)="tabChanged()">
        <p-tabPanel header="Events">
          <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
              <app-bearer-setting-information-gl320m [bsiParameters]="deviceParams.proprietaryParameters.bsiParameters"></app-bearer-setting-information-gl320m>
              <app-binding-sim-card-gl320m [simParameters]="deviceParams.proprietaryParameters.simParameters"></app-binding-sim-card-gl320m>
              <app-command-storage-gl320m [cmdParameters]="deviceParams.proprietaryParameters.cmdParameters"></app-command-storage-gl320m> 
              <app-digital-input-port-settings-gl320m [disParameters]="deviceParams.proprietaryParameters.disParameters"></app-digital-input-port-settings-gl320m>
              <app-fixed-report-information-gl320m [friParameters]="deviceParams.proprietaryParameters.friParameters"></app-fixed-report-information-gl320m>
              <app-function-key-settings-gl320m [fksParameters]="deviceParams.proprietaryParameters.fksParameters"></app-function-key-settings-gl320m>
              <app-geo-fence-parameters-gl320m [geoParameters]="deviceParams.proprietaryParameters.geoParameters"></app-geo-fence-parameters-gl320m>
              <app-global-parameters-gl320m [globalParameters]="deviceParams.proprietaryParameters.globalParameters"></app-global-parameters-gl320m>
              <app-gps-assisted-motion-measurement-gl320m [gamParameters]="deviceParams.proprietaryParameters.gamParameters"></app-gps-assisted-motion-measurement-gl320m>
              <app-motion-sensor-gl320m [msaParameters]="deviceParams.proprietaryParameters.msaParameters"></app-motion-sensor-gl320m>              
              <app-network-selection-gl320m [ntsParameters]="deviceParams.proprietaryParameters.ntsParameters"></app-network-selection-gl320m>
              <app-non-movement-detection-gl320m [nmdParameters]="deviceParams.proprietaryParameters.nmdParameters"></app-non-movement-detection-gl320m>
              <app-outside-working-hours-gl320m [owhParameters]="deviceParams.proprietaryParameters.owhParameters"></app-outside-working-hours-gl320m>
              <app-preserving-device-logic-states-gl320m [pdsParameters]="deviceParams.proprietaryParameters.pdsParameters"></app-preserving-device-logic-states-gl320m>
              <app-protocol-watchdog-gl320m [dogParameters]="deviceParams.proprietaryParameters.dogParameters"></app-protocol-watchdog-gl320m>
              <app-quick-start-settings-gl320m [qssParameters]="deviceParams.proprietaryParameters.qssParameters"></app-quick-start-settings-gl320m>
              <app-serial-port-settings-gl320m [urtParameters]="deviceParams.proprietaryParameters.urtParameters"></app-serial-port-settings-gl320m>
              <app-server-registration-information-gl320m [sriParameters]="deviceParams.proprietaryParameters.sriParameters"></app-server-registration-information-gl320m>
              <app-speed-alarm-parameters-gl320m [spdParameters]="deviceParams.proprietaryParameters.spdParameters"></app-speed-alarm-parameters-gl320m>
              <app-temperature-alarm-gl320m [temParameters]="deviceParams.proprietaryParameters.temParameters"></app-temperature-alarm-gl320m>
              <app-time-adjustment-gl320m [tmaParameters]="deviceParams.proprietaryParameters.tmaParameters"></app-time-adjustment-gl320m>
              <app-unlock-pin-gl320m [pinParameters]="deviceParams.proprietaryParameters.pinParameters"></app-unlock-pin-gl320m>
              <app-user-defined-function-gl320m [udfParameters]="deviceParams.proprietaryParameters.udfParameters"></app-user-defined-function-gl320m>
              <app-additional-configuration-gl320m [additionalParameters]="deviceParams.proprietaryParameters.additionalParameters"></app-additional-configuration-gl320m>
            </p-accordion>
        </p-tabPanel>
  
        <p-tabPanel  header="Sensors">
          <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
          <!-- Sensors list -->
        </p-tabPanel>
        <p-tabPanel i18n-header header="Actuators">
            <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
        </p-tabPanel>
  
      </p-tabView>
    </p-panel>
    <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
  </div>
  