import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { JQueryHTTPClient } from './JQueryHTTPClient';
import { Rest } from './rest_client';

@Injectable()
export class PreconfigurationService  extends EntityService<any>{
  client: Rest.RestApplicationClient;
	constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

 create(arg0: Rest.DevicePreconfiguration): Rest.RestResponse<Rest.DevicePreconfiguration> {
return this.client.Preconfiguration_create(arg0);
}

delete(arg0: Rest.DevicePreconfiguration[]): Rest.RestResponse<void> {
return this.client.Preconfiguration_delete(arg0);
}

update(arg0: Rest.DevicePreconfiguration): Rest.RestResponse<Rest.DevicePreconfiguration> {
return this.client.Preconfiguration_update(arg0);
}

getPage(arg0: Rest.ListPaginationRequest): Rest.RestResponse<Rest.Page<Rest.DevicePreconfiguration>> {
return this.client.Preconfiguration_getPage(arg0);
}

// @ts-ignore
  find(preConfig: string, queryParams?: { preConfig?: number; }): Rest.RestResponse<Rest.DevicePreconfiguration> {
return this.client.Preconfiguration_find(preConfig, queryParams);
}
}
