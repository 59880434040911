import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { Severity } from "app/services/notifications-service/notifications.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

import Insurance = Rest.Insurance;
import { Subject } from "rxjs";
import { RestVehicleInventory } from "../rest_vehicle-inventory";

@Injectable()
export class InsuranceService extends EntityService<Insurance> {
    client: Rest.RestApplicationClient;
    refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    createInsurance(insurance: string, document?: any): Rest.RestResponse<Insurance> {
        const formData = new FormData();
        formData.append('insurance', insurance);

        if (document) {
            formData.append('documentFile', document);
        }

        return this.http
            .post(environment.webApiBaseUrl + 'api/inventory/insurance', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    updateInsurance(insurance: string, document?: any): Rest.RestResponse<Insurance> {
        const formData = new FormData();
        formData.append('insurance', insurance);

        if (document) {
            formData.append('documentFile', document);
        }

        return this.http
            .post(environment.webApiBaseUrl + 'api/inventory/insurance/update', formData)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
                this.notificationsService.add(Severity.error, 'Error', error.message);
                return null;
            });
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean; }
    ): Rest.RestResponse<Rest.Page<Insurance>> {
        try {
            return this.client.Insurance_getPage(arg0, queryParams);
        } catch (error) {
            console.error("Error getting Insurance page:", error);
            throw error;
        }
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        return this.client.Insurance_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    delete(arg0: Insurance[]): Rest.RestResponse<void> {
        try {
            return this.client.Insurance_delete(arg0);
        } catch (error) {
            console.error("Error deleting Insurance:", error);
            throw error;
        }
    }


    // @ts-ignore
    create(arg0: Rest.Insurance): Rest.RestResponse<Insurance> {
        try {
            return this.client.Insurance_create(arg0);
        } catch (error) {
            console.error("Error creating Insurance:", error);
            throw error;
        }
    }

    // @ts-ignore
    update(arg0: Rest.Insurance): Rest.RestResponse<Insurance> {
        try {
            return this.client.Insurance_update(arg0);
        } catch (error) {
            console.error("Error updating Insurance:", error);
            throw error;
        }
    }

    createDocument(insuranceId: number, documentId: number): Rest.RestResponse<Rest.Insurance> {
        let queryParams = {
            insuranceId: insuranceId,
            documentId: documentId
        }
        return this.client.Insurance_addDocument(queryParams);
    }

}
