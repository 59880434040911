<p-dialog i18n-header header="Add Icon " (onHide)="onHide($event)" [(visible)]="display" [responsive]="true" showEffect="fade"
          [modal]="true" appendTo="body" *ngIf="display">

  <p-growl [value]="msgs"></p-growl>


  <p-fileUpload name="myfile[]" (uploadHandler)="onUpload($event)" customUpload="true"
    accept="image/*" maxFileSize="10000000" previewWidth="50" withCredentials="true" #uf></p-fileUpload>


</p-dialog>
