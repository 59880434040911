/* eslint-disable prefer-const, guard-for-in */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from "@angular/core";
import { ConfirmationService, TreeNode } from "primeng/api";

import { FleetService } from "../../../rest/fleet.service";
import { Rest } from "../../../rest/rest_client";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { I18nService } from "../../../services/i18n/i18n.service";
import { NotificationsService } from "../../../services/notifications-service/notifications.service";
import { RestExt } from "../../../services/rest-client-extension";
import { VehiclesgroupService } from "app/rest/vehiclesgroup.service";
import { VehicleService } from "app/rest/vehicle.service";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface FleetTreeNode extends TreeNode {
    entityType: "Fleet" | "Group" | "Vehicle";
    selected: boolean;
    enabled: boolean;
    _$create: boolean;
    _$update: boolean;
    _$changeStatus: boolean;
}

@Component({
    selector: "app-fleet-tree",
    templateUrl: "./fleet-tree.component.html",
    styleUrls: ["./fleet-tree.component.css"],
    providers: [FleetService, ConfirmationService, VehiclesgroupService, VehicleService],
})
export class FleetTreeComponent implements OnInit, OnChanges {
    @Input() showAddButtons: boolean;
    @Input() selection: object[];

    @Input() cascadeSelection: boolean; //true: When select checkbox of entity, all sub entities become selected
    @Input() selectableEntities: boolean; //true: When select label of entity, all its information get loaded on the right panel
    @Input() viewDisableds: boolean;

    @Output() selectionChange: EventEmitter<object[]> = new EventEmitter<object[]>();
    // eslint-disable-next-line
    @Output("nodeSelected") change: EventEmitter<TreeNode> =
        new EventEmitter<TreeNode>();
    // eslint-disable-next-line
    @Output("changeStatus") changeStatusEmiter: EventEmitter<TreeNode[]> =
        new EventEmitter<TreeNode[]>();
    // eslint-disable-next-line
    @Output("enableEntities") enableEntitiesEmiter: EventEmitter<Map<string, boolean>> = new EventEmitter<Map<string, boolean>>();

    /*Expanded nodes */
    expandedIds = [];

    /***Options entities */
    optionEntities: Map<string, boolean> = new Map<string, boolean>(); //All nodes selected by user and by cascade effect
    classButtons: Map<string, string> = new Map<string, string>();
    checkBoxDisabled: Map<string, boolean> = new Map<string, boolean>(); //All checkbox of all nodes and its status (enabled/disabled)

    //css class for nodes
    enabled = "enabled";
    disabled = "disabled";
    enabledSelected = "enabled-selected";
    disabledSelected = "disabled-selected";

    collapse = false; //Show Expand All or Collapse All button
    visibleChangeStatus = false; //When check entity show change status button or not

    nodeSelected: FleetTreeNode;
    selectAfterChangeStatus = false;
    /*** */

    /*Permissions */
    permissions: Map<RestExt.ActionEnum, number[]> = new Map<RestExt.ActionEnum,
        number[]>();
    /** */

    hasMore: boolean;
    fleetsTree: TreeNode[];
    selectedNodes: FleetTreeNode[]; //All nodes selected by user (not in cascade)
    fleets: Rest.Fleet[];
    fleetService: FleetService;
    vehicleGroupService: VehiclesgroupService;
    vehicleService: VehicleService;
    searchString: string;
    items: any;
    pageRequest: Rest.ListPaginationRequest;

    omitSelectionChange = false; //When user clicks a checkbox, checkboxSelected method is called, this method modifies the selection input var to change

    public static isVehicleGroup(s: object): boolean {
        return s.hasOwnProperty("fleet");
    }

    public static isFleet(s: object): boolean {
        return !s.hasOwnProperty("group") && !s.hasOwnProperty("fleet");
    }

    public static isVehicle(s: object): boolean {
        return s.hasOwnProperty("group");
    }

    //and this causes the onChanges event to fire. This flag is used to stop this behaviour.

    constructor(
        fleetService: FleetService,
        protected confirmationService: ConfirmationService,
        public notificationsService: NotificationsService,
        private i18n: I18nService,
        private authenticationService: AuthenticationService,
        vehicleGroupService: VehiclesgroupService,
        vehicleService: VehicleService
    ) {
        this.fleetService = fleetService;
        this.vehicleGroupService = vehicleGroupService;
        this.vehicleService = vehicleService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["selection"]) {
            if (!this.omitSelectionChange) {
                this.fillTree();
            } else {
                this.omitSelectionChange = false;
            }
        }

        if (changes["selection"].currentValue !== undefined) {
            if (changes["selection"].currentValue.length === 0) {
                // eslint-disable-next-line guard-for-in
                for (const entity in this.optionEntities) {
                    this.optionEntities[entity] = false;
                }

                //Se habilitan todos menos los que tienen que estar deshabilitados por permisos
                //a no ser que se no sea la pantalla de flotas (this.selectableEntities==true), entonces siempre habilitado (disabled = false)
                //Notice Groups
                for (const entity in this.checkBoxDisabled) {
                    //31/01*********************************
                    //this.checkBoxDisabled[entity] = (!this.selectableEntities ? false : this.disabledByPermission(entity));

                    if (this.selectableEntities === false) {
                        this.checkBoxDisabled[entity] = false;
                    } else {
                        if (this.disabledByPermission(entity) === true) {
                            this.checkBoxDisabled[entity] = true;
                        } else {
                            //El padre esta deshabilitado
                            if (this.fatherIsEnabled(entity) === false) {
                                this.checkBoxDisabled[entity] = false;
                            } else {
                                this.checkBoxDisabled[entity] = true;
                            }
                        }
                    }
                    /************************************* */
                }
            } else {
                // eslint-disable-next-line guard-for-in
                for (const entity in changes["selection"].currentValue) {
                    this.setUpNode(changes["selection"].currentValue[entity]);
                }
            }
        }
    }

    disabledByPermission(entity: string): boolean {
        //return true;
        for (const fleet in this.fleetsTree) {
            if (
                this.fleetsTree[fleet].type !== "button" &&
                this.fleetsTree[fleet].data.name +
                "-" +
                this.fleetsTree[fleet].data.id ===
                entity
            ) {
                return !this.fleetsTree[fleet]["_$changeStatus"];
            } else {
                for (const group in this.fleetsTree[fleet].children) {
                    if (
                        this.fleetsTree[fleet].children[group].type !== "button" &&
                        this.fleetsTree[fleet].children[group].data.name +
                        "-" +
                        this.fleetsTree[fleet].children[group].data.id ===
                        entity
                    ) {
                        return !this.fleetsTree[fleet].children[group]["_$changeStatus"];
                    } else {
                        for (const vehicle in this.fleetsTree[fleet].children[group]
                            .children) {
                            if (
                                this.fleetsTree[fleet].children[group].children[vehicle]
                                    .type !== "button" &&
                                this.fleetsTree[fleet].children[group].children[vehicle].data
                                    .name +
                                "-" +
                                this.fleetsTree[fleet].children[group].children[vehicle].data
                                    .id ===
                                entity
                            ) {
                                return !this.fleetsTree[fleet].children[group].children[
                                    vehicle
                                    ]["_$changeStatus"];
                            }
                        }
                    }
                }
            }
        }
    }

    setUpNode(node: any) {
        this.optionEntities[node.name + "-" + node.id] = true;
        for (const entity in this.selectedNodes) {
            if (
                this.selectedNodes[entity].data.name +
                "-" +
                this.selectedNodes[entity].data.id ===
                node.name + "-" + node.id
            ) {
                if (this.selectedNodes[entity]["entityType"] === "Fleet") {
                    for (const group in this.selectedNodes[entity].data.groups) {
                        if (
                            (this.classButtons[
                                this.selectedNodes[entity].data.groups[group].name +
                                "-" +
                                this.selectedNodes[entity].data.groups[group].id
                                    ] !== this.disabled &&
                                this.classButtons[
                                this.selectedNodes[entity].data.groups[group].name +
                                "-" +
                                this.selectedNodes[entity].data.groups[group].id
                                    ] !== this.disabledSelected) ||
                            this.selectableEntities === false
                        ) {
                            this.checkBoxDisabled[
                            this.selectedNodes[entity].data.groups[group].name +
                            "-" +
                            this.selectedNodes[entity].data.groups[group].id
                                ] = true;
                            this.optionEntities[
                            this.selectedNodes[entity].data.groups[group].name +
                            "-" +
                            this.selectedNodes[entity].data.groups[group].id
                                ] = true;
                            for (const vehicle in this.selectedNodes[entity].data.groups[
                                group
                                ].vehicles) {
                                if (
                                    (this.classButtons[
                                        this.selectedNodes[entity].data.groups[group].vehicles[
                                            vehicle
                                            ].name +
                                        "-" +
                                        this.selectedNodes[entity].data.groups[group].vehicles[
                                            vehicle
                                            ].id
                                            ] !== this.disabled &&
                                        this.classButtons[
                                        this.selectedNodes[entity].data.groups[group].vehicles[
                                            vehicle
                                            ].name +
                                        "-" +
                                        this.selectedNodes[entity].data.groups[group].vehicles[
                                            vehicle
                                            ].id
                                            ] !== this.disabledSelected) ||
                                    this.selectableEntities === false
                                ) {
                                    this.checkBoxDisabled[
                                    this.selectedNodes[entity].data.groups[group].vehicles[
                                        vehicle
                                        ].name +
                                    "-" +
                                    this.selectedNodes[entity].data.groups[group].vehicles[
                                        vehicle
                                        ].id
                                        ] = true;
                                    this.optionEntities[
                                    this.selectedNodes[entity].data.groups[group].vehicles[
                                        vehicle
                                        ].name +
                                    "-" +
                                    this.selectedNodes[entity].data.groups[group].vehicles[
                                        vehicle
                                        ].id
                                        ] = true;
                                }
                            }
                        }
                    }
                }
                if (this.selectedNodes[entity]["entityType"] === "Group") {
                    for (const vehicle in this.selectedNodes[entity].data.vehicles) {
                        if (
                            (this.classButtons[
                                this.selectedNodes[entity].data.vehicles[vehicle].name +
                                "-" +
                                this.selectedNodes[entity].data.vehicles[vehicle].id
                                    ] !== this.disabled &&
                                this.classButtons[
                                this.selectedNodes[entity].data.vehicles[vehicle].name +
                                "-" +
                                this.selectedNodes[entity].data.vehicles[vehicle].id
                                    ] !== this.disabledSelected) ||
                            this.selectableEntities === false
                        ) {
                            this.checkBoxDisabled[
                            this.selectedNodes[entity].data.vehicles[vehicle].name +
                            "-" +
                            this.selectedNodes[entity].data.vehicles[vehicle].id
                                ] = true;
                            this.optionEntities[
                            this.selectedNodes[entity].data.vehicles[vehicle].name +
                            "-" +
                            this.selectedNodes[entity].data.vehicles[vehicle].id
                                ] = true;
                        }
                    }
                }
            }
        }
    }

    fatherIsEnabled(entity: string): boolean {
        for (const fleet in this.fleetsTree) {
            if (
                this.fleetsTree[fleet].type !== "button" &&
                this.fleetsTree[fleet].data.name +
                "-" +
                this.fleetsTree[fleet].data.id ===
                entity
            ) {
                return !this.fleetsTree[fleet]["_$changeStatus"];
            } else {
                for (const group in this.fleetsTree[fleet].children) {
                    if (
                        this.fleetsTree[fleet].children[group].type !== "button" &&
                        this.fleetsTree[fleet].children[group].data.name +
                        "-" +
                        this.fleetsTree[fleet].children[group].data.id ===
                        entity
                    ) {
                        return !this.fleetsTree[fleet].data.enabled;
                    } else {
                        for (const vehicle in this.fleetsTree[fleet].children[group]
                            .children) {
                            if (
                                this.fleetsTree[fleet].children[group].children[vehicle]
                                    .type !== "button" &&
                                this.fleetsTree[fleet].children[group].children[vehicle].data
                                    .name +
                                "-" +
                                this.fleetsTree[fleet].children[group].children[vehicle].data
                                    .id ===
                                entity
                            ) {
                                return !this.fleetsTree[fleet].children[group].data.enabled;
                            }
                        }
                    }
                }
            }
        }
    }

    more() {
        this.pageRequest.pageSize += 10;
        this.updateTreeData();
    }

    search() {
        this.pageRequest.filters = {};
        this.pageRequest.pageSize = 10;
        this.pageRequest.filters["name"] = [
            this.searchString && this.searchString.trim().length > 0
                ? this.searchString
                : "%",
        ];
        this.updateTreeData();
    }

    updateTreeData() {
        const that = this;
        this.fleetService
            .getTreeFleets(this.pageRequest)
            .then(
                function (response) {
                    that.fleets = response.entities;
                    /*Permissions */
                    // eslint-disable-next-line guard-for-in
                    for (const a in response.permissions) {
                        that.permissions[RestExt.ActionEnum[a]] = response.permissions[a];
                    }
                    //that.permissionsEmiter.emit(that.permissions);
                    /** */
                    that.fillTree();
                    that.hasMore = response.entities.length < response.filteredEntities;

                    if (that.selectAfterChangeStatus) {
                        that.selectNode();
                        that.selectAfterChangeStatus = false;
                    }
                },
                function (error) {
                }
            )
            .catch(function (reason) {
            });
    }

    ngOnInit() {
        this.expandedIds = [];
        this.saveExpandedNodes();

        this.fleetsTree = [];
        this.selectedNodes = [];
        const that = this;
        this.pageRequest = RestExt.firstPageRequest();
        if (this.viewDisableds) {
            this.pageRequest.filters = {};
        }
        this.updateTreeData();
        this.visibleChangeStatus = false;
    }

    saveExpandedNodes() {
        this.expandedIds = [];

        /** FLEET IDS */
        this.fleetsTree.forEach((fleet) => {
            if (fleet.type === "entity") {
                if (fleet.expanded) {
                    this.expandedIds.push(fleet.data.id);

                    /** GROUP IDS */
                    fleet.children.forEach((group) => {
                        if (group.type === "entity") {
                            if (group.expanded) {
                                this.expandedIds.push(group.data.id);

                                /** VEHICLE IDS */
                                group.children.forEach((vehicle) => {
                                    if (vehicle.type === "entity") {
                                        if (vehicle.expanded) {
                                            this.expandedIds.push(vehicle.data.id);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
    }

    restoreExpandedBranches() {
        if (this.expandedIds.length === 0) {
            return;
        }
        /** FLEET IDS */
        this.fleetsTree.forEach((fleet) => {
            /** If we find all the ids we return */
            if (this.expandedIds.length === 0) {
                return;
            }
            if (fleet.type === "entity") {
                if (this.expandedIds.includes(fleet.data.id)) {
                    fleet.expanded = true;
                    /** We remove the id from the array */
                    this.expandedIds.splice(this.expandedIds.indexOf(fleet.data.id), 1);

                    /** GROUP IDS */
                    fleet.children.forEach((group) => {
                        /** If we find all the ids we return */
                        if (this.expandedIds.length === 0) {
                            return;
                        }
                        if (group.type === "entity") {
                            if (this.expandedIds.includes(group.data.id)) {
                                group.expanded = true;

                                /** We remove the id from the array */
                                this.expandedIds.splice(
                                    this.expandedIds.indexOf(group.data.id),
                                    1
                                );

                                /** VEHICLE IDS */
                                group.children.forEach((vehicle) => {
                                    /** If we find all the ids we return */
                                    if (this.expandedIds.length === 0) {
                                        return;
                                    }
                                    if (vehicle.type === "entity") {
                                        if (this.expandedIds.includes(vehicle.data.id)) {
                                            vehicle.expanded = true;

                                            /** We remove the id from the array */
                                            this.expandedIds.splice(
                                                this.expandedIds.indexOf(vehicle.data.id),
                                                1
                                            );
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });

        /** If some of the nodes are deleted and was expanded, we will remove it */
        this.expandedIds = [];
    }

    fillTree() {
        this.selectedNodes = [];

        const lowerCaseSearchStr =
            this.searchString != null && this.searchString.length > 0
                ? this.searchString.toLowerCase()
                : null;

        this.fleetsTree = [];
        if (
            this.showAddButtons &&
            this.authenticationService.user.canManageFleets
        ) {
            const fleetNodeButton: FleetTreeNode = {
                children: [],
                label: this.i18n._("Add Fleet"),
                data: null,
                selectable: false,
                type: "button",
                entityType: "Fleet",
                selected: false,
                enabled: true,
                /** Permissions */
                _$create: this.authenticationService.user.canManageFleets, //this.permissionsEntityTypeSelector('create', 'Fleet'),
                _$update: false,
                _$changeStatus: false,
                /** */
            };

            this.fleetsTree.push(fleetNodeButton);
        }

        // eslint-disable-next-line guard-for-in
        for (const fleet in this.fleets) {
            this.optionEntities[
            this.fleets[fleet].name + "-" + this.fleets[fleet].id
                ] = false;
            this.classButtons[this.fleets[fleet].name + "-" + this.fleets[fleet].id] =
                this.fleets[fleet].enabled ? this.enabled : this.disabled;

            this.checkBoxDisabled[
            this.fleets[fleet].name + "-" + this.fleets[fleet].id
                ] = !this.selectableEntities
                ? false
                : !this.hasPermission(
                    this.permissionsEntityTypeSelector("changeStatus", "Fleet"),
                    this.fleets[fleet].id
                );

            const fleetNode: FleetTreeNode = {
                type: "entity",
                children: [],
                label: this.fleets[fleet].name,
                data: this.fleets[fleet],
                entityType: "Fleet",
                expanded: false,
                selectable: false,
                selected: false,
                styleClass: this.fleets[fleet].name
                    .toLowerCase()
                    .includes(lowerCaseSearchStr)
                    ? "highlighted"
                    : "",
                enabled: this.fleets[fleet].enabled,
                /*Permissions */
                _$create: this.authenticationService.user.canManageFleets,
                _$update: this.hasPermission(
                    this.permissionsEntityTypeSelector("update", "Fleet"),
                    this.fleets[fleet].id
                ),
                _$changeStatus: !this.selectableEntities
                    ? false
                    : this.hasPermission(
                        this.permissionsEntityTypeSelector("changeStatus", "Fleet"),
                        this.fleets[fleet].id
                    ),
                /** */
            };

                const groupNodeButton: FleetTreeNode = {
                    children: [],
                    label: this.i18n._("Add Group"),
                    data: this.fleets[fleet],
                    type: "button",
                    selectable: false,
                    entityType: "Group",
                    selected: false,
                    enabled: true,
                    /*Permissions */
                    _$create: this.hasPermission(
                        this.permissionsEntityTypeSelector("create", "Group"),
                        this.fleets[fleet].id
                    ),
                    _$update: false,
                    _$changeStatus: false,
                    /** */
                };
                fleetNode.children.push(groupNodeButton);
            


            
            

            // eslint-disable-next-line guard-for-in
            for (const group in this.fleets[fleet].groups) {
                this.optionEntities[
                this.fleets[fleet].groups[group].name +
                "-" +
                this.fleets[fleet].groups[group].id
                    ] = false;
                this.classButtons[
                this.fleets[fleet].groups[group].name +
                "-" +
                this.fleets[fleet].groups[group].id
                    ] = this.fleets[fleet].groups[group].enabled
                    ? this.enabled
                    : this.disabled;

                /************************** */
                //31/02
                //this.checkBoxDisabled[this.fleets[fleet].groups[group].name + "-" + this.fleets[fleet].groups[group].id] = (!this.selectableEntities ? false: !this.hasPermission(this.permissionsEntityTypeSelector('changeStatus', 'Group'), this.fleets[fleet].groups[group].id));

                if (this.selectableEntities === false) {
                    this.checkBoxDisabled[
                    this.fleets[fleet].groups[group].name +
                    "-" +
                    this.fleets[fleet].groups[group].id
                        ] = false;
                } else {
                    if (
                        this.hasPermission(
                            this.permissionsEntityTypeSelector("changeStatus", "Group"),
                            this.fleets[fleet].groups[group].id
                        ) === false
                    ) {
                        this.checkBoxDisabled[
                        this.fleets[fleet].groups[group].name +
                        "-" +
                        this.fleets[fleet].groups[group].id
                            ] = true;
                    } else {
                        if (this.fleets[fleet].enabled === false) {
                            this.checkBoxDisabled[
                            this.fleets[fleet].groups[group].name +
                            "-" +
                            this.fleets[fleet].groups[group].id
                                ] = true;
                        } else {
                            this.checkBoxDisabled[
                            this.fleets[fleet].groups[group].name +
                            "-" +
                            this.fleets[fleet].groups[group].id
                                ] = false;
                        }
                    }
                }

                /**************************** */

                const groupNode: FleetTreeNode = {
                    type: "entity",
                    children: [],
                    label: this.fleets[fleet].groups[group].name,
                    data: this.fleets[fleet].groups[group],
                    entityType: "Group",
                    parent: fleetNode,
                    expanded: false,
                    selectable: false,
                    styleClass: this.fleets[fleet].groups[group].name
                        .toLowerCase()
                        .includes(lowerCaseSearchStr)
                        ? "highlighted"
                        : "",
                    selected: false,
                    enabled: this.fleets[fleet].groups[group].enabled,
                    /*Permissions */
                    _$create: this.hasPermission(
                        this.permissionsEntityTypeSelector("create", "Group"),
                        this.fleets[fleet].id
                    ),
                    _$update: this.hasPermission(
                        this.permissionsEntityTypeSelector("update", "Group"),
                        this.fleets[fleet].groups[group].id
                    ),
                    _$changeStatus: !this.selectableEntities
                        ? false
                        : this.hasPermission(
                            this.permissionsEntityTypeSelector("changeStatus", "Group"),
                            this.fleets[fleet].groups[group].id
                        ),
                    /** */
                };
                let vgSelected = false;
                if (
                    this.selection.find((s: object) => {
                        return FleetTreeComponent.isVehicleGroup(s)
                            ? s["id"] === groupNode.data["id"]
                            : false;
                    })
                ) {
                    vgSelected = true;
                    this.selectedNodes.push(groupNode);

                    //When we select one node we also select its childrens and disable each checkbox
                    // eslint-disable-next-line guard-for-in, prefer-const
                    for (let vehicle in groupNode.data.vehicles) {
                        this.optionEntities[
                        groupNode.data.vehicles[vehicle].name +
                        "-" +
                        groupNode.data.vehicles[vehicle].id
                            ] = true;
                        this.checkBoxDisabled[
                        groupNode.data.vehicles[vehicle].name +
                        "-" +
                        groupNode.data.vehicles[vehicle].id
                            ] = true;
                    }
                }
                //expand the fleet node if the group matches the search
                if (
                    vgSelected ||
                    this.fleets[fleet].groups[group].name
                        .toLowerCase()
                        .includes(lowerCaseSearchStr)
                ) {
                    fleetNode.expanded = true;
                }

                    const vehicleNodeButton: FleetTreeNode = {
                        children: [],
                        label: this.i18n._("Add Vehicle"),
                        data: this.fleets[fleet].groups[group],
                        type: "button",
                        selectable: false,
                        entityType: "Vehicle",
                        selected: false,
                        enabled: true,
                        /*Permissions */
                        _$create: this.hasPermission(
                            this.permissionsEntityTypeSelector("create", "Vehicle"),
                            this.fleets[fleet].groups[group].id
                        ),
                        _$update: false,
                        _$changeStatus: false,
                        /** */
                    };
    
                    groupNode.children.push(vehicleNodeButton);                               
                // eslint-disable-next-line guard-for-in
                for (const vehicle in this.fleets[fleet].groups[group].vehicles) {
                    this.classButtons[
                    this.fleets[fleet].groups[group].vehicles[vehicle].name +
                    "-" +
                    this.fleets[fleet].groups[group].vehicles[vehicle].id
                        ] = this.fleets[fleet].groups[group].vehicles[vehicle].enabled
                        ? this.enabled
                        : this.disabled;

                    let selectedPrevius = false;

                    for (let i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                        if (
                            this.selectedNodes[i].data.name ===
                            this.fleets[fleet].groups[group].name &&
                            this.selectedNodes[i].data.id ===
                            this.fleets[fleet].groups[group].id
                        ) {
                            selectedPrevius = true;
                        }
                    }

                    //If group is already in selectedNodes array, all vehicles have been previously selected, otherwise all vehicles are initialized.
                    if (selectedPrevius === false) {
                        this.optionEntities[
                        this.fleets[fleet].groups[group].vehicles[vehicle].name +
                        "-" +
                        this.fleets[fleet].groups[group].vehicles[vehicle].id
                            ] = false;

                        //31/01*********************************
                        //this.checkBoxDisabled[this.fleets[fleet].groups[group].vehicles[vehicle].name + "-" + this.fleets[fleet].groups[group].vehicles[vehicle].id] = (!this.selectableEntities ? false:!this.hasPermission(this.permissionsEntityTypeSelector('changeStatus', 'Vehicle'), this.fleets[fleet].groups[group].vehicles[vehicle].id));

                        if (this.selectableEntities === false) {
                            this.checkBoxDisabled[
                            this.fleets[fleet].groups[group].vehicles[vehicle].name +
                            "-" +
                            this.fleets[fleet].groups[group].vehicles[vehicle].id
                                ] = false;
                        } else {
                            if (
                                this.hasPermission(
                                    this.permissionsEntityTypeSelector("changeStatus", "Vehicle"),
                                    this.fleets[fleet].groups[group].vehicles[vehicle].id
                                ) === false
                            ) {
                                this.checkBoxDisabled[
                                this.fleets[fleet].groups[group].vehicles[vehicle].name +
                                "-" +
                                this.fleets[fleet].groups[group].vehicles[vehicle].id
                                    ] = true;
                            } else {
                                if (this.fleets[fleet].groups[group].enabled === false) {
                                    this.checkBoxDisabled[
                                    this.fleets[fleet].groups[group].vehicles[vehicle].name +
                                    "-" +
                                    this.fleets[fleet].groups[group].vehicles[vehicle].id
                                        ] = true;
                                } else {
                                    this.checkBoxDisabled[
                                    this.fleets[fleet].groups[group].vehicles[vehicle].name +
                                    "-" +
                                    this.fleets[fleet].groups[group].vehicles[vehicle].id
                                        ] = false;
                                }
                            }
                        }
                        /************************************* */
                    }

                    //expand the group node if no search string has been provided or the group node name contains the searched string
                    const vehicleNode: FleetTreeNode = {
                        type: "entity",
                        children: [],
                        label: this.fleets[fleet].groups[group].vehicles[vehicle].name,
                        data: this.fleets[fleet].groups[group].vehicles[vehicle],
                        parent: groupNode,
                        selectable: false,
                        entityType: "Vehicle",
                        selected: false,
                        enabled: this.fleets[fleet].groups[group].vehicles[vehicle].enabled,
                        /*Permissions */
                        _$create: this.hasPermission(
                            this.permissionsEntityTypeSelector("create", "Vehicle"),
                            this.fleets[fleet].groups[group].id
                        ),
                        _$update: this.hasPermission(
                            this.permissionsEntityTypeSelector("update", "Vehicle"),
                            this.fleets[fleet].groups[group].vehicles[vehicle].id
                        ),
                        _$changeStatus: !this.selectableEntities
                            ? false
                            : this.hasPermission(
                                this.permissionsEntityTypeSelector("changeStatus", "Vehicle"),
                                this.fleets[fleet].groups[group].vehicles[vehicle].id
                            ),
                        /** */
                    };
                    //The label of the node matches the search string. Highlight it
                    const highlightVehicleNode = this.fleets[fleet].groups[
                        group
                        ].vehicles[vehicle].name
                        .toLowerCase()
                        .includes(lowerCaseSearchStr);
                    if (highlightVehicleNode) {
                        vehicleNode.styleClass = "highlighted";
                        groupNode.expanded = true;
                        fleetNode.expanded = true;
                    }
                    groupNode.children.push(vehicleNode);
                    if (
                        this.selection.find((s: object) =>
                            FleetTreeComponent.isVehicle(s)
                                ? s["id"] === vehicleNode.data["id"]
                                : false
                        )
                    ) {
                        this.selectedNodes.push(vehicleNode);
                        groupNode.expanded = true;
                        fleetNode.expanded = true;
                    }
                }
                fleetNode.children.push(groupNode);
            }
            this.fleetsTree.push(fleetNode);
            if (
                this.selection.find((s: object) => {
                    return FleetTreeComponent.isFleet(s)
                        ? s["id"] === fleetNode.data["id"]
                        : false;
                })
            ) {
                this.selectedNodes.push(fleetNode);

                //When we select one node we also select its childrens and disable each checkbox
                // eslint-disable-next-line guard-for-in
                for (let group in fleetNode.data.groups) {
                    this.optionEntities[
                    fleetNode.data.groups[group].name +
                    "-" +
                    fleetNode.data.groups[group].id
                        ] = true;
                    this.checkBoxDisabled[
                    fleetNode.data.groups[group].name +
                    "-" +
                    fleetNode.data.groups[group].id
                        ] = true;

                    // eslint-disable-next-line guard-for-in
                    for (let vehicle in fleetNode.data.groups[group].vehicles) {
                        this.optionEntities[
                        fleetNode.data.groups[group].vehicles[vehicle].name +
                        "-" +
                        fleetNode.data.groups[group].vehicles[vehicle].id
                            ] = true;
                        this.checkBoxDisabled[
                        fleetNode.data.groups[group].vehicles[vehicle].name +
                        "-" +
                        fleetNode.data.groups[group].vehicles[vehicle].id
                            ] = true;
                    }
                }
            }
        }
        //Sort
        this.fleetsTree.forEach((f) =>
            f.children.forEach(
                (g) =>
                    (g.children = g.children.sort((a, b) =>
                        a.label.localeCompare(b.label)
                    ))
            )
        );
        this.fleetsTree.forEach(
            (f) =>
                (f.children = f.children.sort((a, b) => a.label.localeCompare(b.label)))
        );
        this.fleetsTree = this.fleetsTree.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        this.fleetsTree = [...this.fleetsTree];
        for (const entity in this.selection) {
            this.setUpNode(this.selection[entity]);
        }

        /*RESTORE EXPANDED NODES */
        this.restoreExpandedBranches();                  
    }

    expandAll() {
        this.fleetsTree.forEach((node) => {
            this.expandRecursive(node, true);
        });

        this.collapse = false;
    }

    collapseAll() {
        this.fleetsTree.forEach((node) => {
            this.expandRecursive(node, false);
        });

        this.collapse = true;
    }

    ngAfterViewInit() {
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    addButtonClicked(node: any) {
        this.change.emit(node);

        for (const g in this.classButtons) {
            if (this.classButtons[g] === this.enabledSelected) {
                this.classButtons[g] = this.enabled;
            }
            if (this.classButtons[g] === this.disabledSelected) {
                this.classButtons[g] = this.disabled;
            }
        }
    }

    /*Check */
    nodeCheck(event, node: FleetTreeNode) {
        if (event.checked) {
            if (!this.selectedNodes.find((item) => item.data.id == node.data.id && item.entityType == node.entityType)) {
              this.selectedNodes.push(node);
            }
          } else {
            this.deselectNode(node);
          }

        //Checkbox of node selected can't be disabled
        //this.checkBoxDisabled[node.label + "-" + node.data.id] = event;

        //INFO: "&& node.enabled" is used to select in cascade only enabled entities
        //INFO: "this.selectableEntities==false" is used for cascade in forms which are not Vehicles like ROL
        if (
            this.cascadeSelection &&
            (node.enabled || this.selectableEntities === false)
        ) {
            if (node.entityType === "Fleet") {
                if (node.children.length > 0) {
                    for (const g in node.children) {
                        if (node.children[g].label !== "Add Group") {
                            //INFO: If subentity is already disabled checkbox ain't gonna be checked
                            if (
                                (this.classButtons[
                                    node.children[g].label + "-" + node.children[g].data.id
                                        ] !== this.disabled &&
                                    this.classButtons[
                                    node.children[g].label + "-" + node.children[g].data.id
                                        ] !== this.disabledSelected) ||
                                this.selectableEntities === false
                            ) {
                                //
                                this.optionEntities[
                                node.children[g].label + "-" + node.children[g].data.id
                                    ] = event;

                                for (let i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                                    if (this.selectedNodes[i] === node.children[g]) {
                                        this.selectedNodes.splice(i, 1);
                                    }
                                }

                                //

                                //INFO: If subentity is selected in cascade like in this case, checkbox of this node is disabled but selected
                                this.checkBoxDisabled[
                                node.children[g].label + "-" + node.children[g].data.id
                                    ] = event;

                                //
                            }
                            //
                            for (const v in node.children[g].children) {
                                if (node.children[g].label !== "Add Vehicle") {
                                    //INFO: If subentity is already disabled checkbox ain't gonna be checked
                                    if (
                                        (this.classButtons[
                                            node.children[g].children[v].label +
                                            "-" +
                                            node.children[g].children[v].data.id
                                                ] !== this.disabled &&
                                            this.classButtons[
                                            node.children[g].children[v].label +
                                            "-" +
                                            node.children[g].children[v].data.id
                                                ] !== this.disabledSelected) ||
                                        this.selectableEntities === false
                                    ) {
                                        //
                                        this.optionEntities[
                                        node.children[g].children[v].label +
                                        "-" +
                                        node.children[g].children[v].data.id
                                            ] = event;

                                        for (let i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                                            if (
                                                this.selectedNodes[i] === node.children[g].children[v]
                                            ) {
                                                this.selectedNodes.splice(i, 1);
                                            }
                                        }
                                        //

                                        //INFO: If subentity is selected in cascade like in this case, checkbox of this node is disabled but selected
                                        this.checkBoxDisabled[
                                        node.children[g].children[v].label +
                                        "-" +
                                        node.children[g].children[v].data.id
                                            ] = event;
                                    }
                                    //
                                }
                            }
                        }
                    }
                }
            }

            if (node.entityType === "Group") {
                if (node.children.length > 0) {
                    for (const g in node.children) {
                        if (node.children[g].label !== "Add Vehicle") {
                            //INFO: If subentity is already disabled checkbox ain't gonna be checked
                            if (
                                (this.classButtons[
                                    node.children[g].label + "-" + node.children[g].data.id
                                        ] !== this.disabled &&
                                    this.classButtons[
                                    node.children[g].label + "-" + node.children[g].data.id
                                        ] !== this.disabledSelected) ||
                                this.selectableEntities === false
                            ) {
                                //

                                this.optionEntities[
                                node.children[g].label + "-" + node.children[g].data.id
                                    ] = event;

                                for (let i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
                                    if (this.selectedNodes[i] === node.children[g]) {
                                        this.selectedNodes.splice(i, 1);
                                    }
                                }
                                //

                                //INFO: If subentity is selected in cascade like in this case, checkbox of this node is disabled but selected
                                this.checkBoxDisabled[
                                node.children[g].label + "-" + node.children[g].data.id
                                    ] = event;
                                //
                            }
                            //
                        }
                    }
                }
            }
        }

        this.omitSelectionChange = true;

        if (this.selectedNodes.length > 0) {
            this.visibleChangeStatus = true;
        } else {
            this.visibleChangeStatus = false;
        }

        this.selection = this.selectedNodes.map((n: FleetTreeNode) => n.data);
        this.selectionChange.emit(this.selection);
    }

    selectNode() {
        if (
            this.classButtons[
            this.nodeSelected.label + "-" + this.nodeSelected.data.id
                ] === this.enabled
        ) {
            this.classButtons[
            this.nodeSelected.label + "-" + this.nodeSelected.data.id
                ] = this.enabledSelected;
        } else {
            this.classButtons[
            this.nodeSelected.label + "-" + this.nodeSelected.data.id
                ] = this.disabledSelected;
        }
    }

    nodeSelect(event: any, node: FleetTreeNode) {
        this.nodeSelected = node;

        for (const g in this.classButtons) {
            if (this.classButtons[g] === this.enabledSelected) {
                this.classButtons[g] = this.enabled;
            }
            if (this.classButtons[g] === this.disabledSelected) {
                this.classButtons[g] = this.disabled;
            }
        }

        if (this.classButtons[node.label + "-" + node.data.id] === this.enabled) {
            this.classButtons[node.label + "-" + node.data.id] = this.enabledSelected;
        } else {
            this.classButtons[node.label + "-" + node.data.id] =
                this.disabledSelected;
        }

        this.change.emit(node);
        this.enableEntitiesEmiter.emit(this.checkBoxDisabled);
    }

    /** #ChangeStatus */
    changeStatus() {
        this.changeStatusEmiter.emit(this.selectedNodes);
    }

    /***************** */

    /*Permissions */

    /*Match from view entity the type of each node to select which permission we need to check  */

    permissionsEntityTypeSelector(action: string, entityType: string) {
        switch (entityType) {
            case "Fleet":
                switch (action) {
                    case "changeStatus":
                        return RestExt.ActionEnum.FLEETS_DISABLE;
                    case "update":
                        return RestExt.ActionEnum.FLEETS_UPDATE;
                    //  case 'create':
                    //      return this.user.canManageFleets;
                }
            // eslint-disable-next-line no-fallthrough
            case "Group":
                switch (action) {
                    case "changeStatus":
                        return RestExt.ActionEnum.VEHICLES_GROUPS_DISABLE;
                    case "update":
                        return RestExt.ActionEnum.VEHICLES_GROUPS_UPDATE;
                    case "create":
                        return RestExt.ActionEnum.VEHICLES_GROUPS_CREATE;
                }
            // eslint-disable-next-line no-fallthrough
            case "Vehicle":
                switch (action) {
                    case "changeStatus":
                        return RestExt.ActionEnum.VEHICLES_DISABLE;
                    case "update":
                        return RestExt.ActionEnum.VEHICLES_UPDATE;
                    case "create":
                        return RestExt.ActionEnum.VEHICLES_CREATE;
                }
        }
    }

    /*returns true if the list of entities contains selected entity */
    hasPermission(action: RestExt.ActionEnum, id: number): boolean {
        return this.permissions[action].some((x) => x === id);
    }

    deselectNode(node: FleetTreeNode) {
        for (var i = this.selectedNodes.length - 1 ; i >= 0 ; i--) {
            if (this.selectedNodes[i] === node) {
                this.selectedNodes.splice(i, 1);
            }
        }
    }

    nodeExpanded(node){
        var that = this;
        that.pageRequest.filters = {};
        that.pageRequest.pageSize = 1000000;

        if(node.node.entityType === "Fleet"){
            let fleetToUpdate = that.fleets.find(obj => obj.id === node.node.data.id);
            
            if(fleetToUpdate && fleetToUpdate.groups == null){
                that.pageRequest.filters["fleet"] = [node.node.data.id.toString()];
                that.vehicleGroupService
                .getTreeGroups(that.pageRequest)
                .then(
                    function (response) {    
                        fleetToUpdate.groups = response.entities;                  
                        /*Permissions */
                        // eslint-disable-next-line guard-for-in
                        for (const a in response.permissions) {
                            that.permissions[RestExt.ActionEnum[a]] = response.permissions[a];
                        }
                        //that.permissionsEmiter.emit(that.permissions);
                        /** */
                        that.saveExpandedNodes();
                        that.fillTree();
                        that.hasMore = response.entities.length < response.filteredEntities;
    
                        if (that.selectAfterChangeStatus) {
                            that.selectNode();
                            that.selectAfterChangeStatus = false;
                        }
                    },
                    function (error) {
                    }
                )
                .catch(function (reason) {
                });
            }         
        }else if(node.node.entityType = "Group"){

            let fleetToUpdate = that.fleets.find(obj => obj.id === node.node.parent.data.id);
            let groupToUpdate = fleetToUpdate.groups.find(obj => obj.id === node.node.data.id);

            if(fleetToUpdate && groupToUpdate && groupToUpdate.vehicles == null){
                that.pageRequest.filters["group"] = [node.node.data.id.toString()];
                that.vehicleService
                .getTreeVehicles(that.pageRequest)
                .then(
                    function(response){
                        groupToUpdate.vehicles = response.entities;

                        for (const a in response.permissions) {
                            that.permissions[RestExt.ActionEnum[a]] = response.permissions[a];
                        }
                        //that.permissionsEmiter.emit(that.permissions);
                        /** */
                        that.saveExpandedNodes();
                        that.fillTree();
                        
                        that.hasMore = response.entities.length < response.filteredEntities;
    
                        if (that.selectAfterChangeStatus) {
                            that.selectNode();
                            that.selectAfterChangeStatus = false;
                        }
                    }
                )
            }
        }
    };
}
