import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Rest } from "../../../../rest/rest_client";
import { RestExt } from "../../../../services/rest-client-extension";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-speed-event-config-form",
  templateUrl: "./speed-event-config-form.component.html",
  styleUrls: ["./speed-event-config-form.component.css"],
})
export class SpeedEventConfigFormComponent
  extends ServerValidatedFormComponent
  implements OnInit, OnChanges
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() deviceParams: Rest.DeviceParameters;
  @ViewChild("speedevent") speedevent;

  /**Speed Event */
  minSpeedingDuration: number;
  maxSpeedingDuration = 65535;

  availablespeedingmode: SelectItem[];

  avaibleLedPatterns: SelectItem[];

  availablespeedingduration: SelectItem[];

  availableSpeedModes: SelectItem[];

  availableEventDurations: SelectItem[];

  availableoutputport: SelectItem[];

  disableOffSpeedingDuration: boolean;

  ngOnInit() {
    // Speed Event Fields
    this.availableSpeedModes = RestExt.getEnumItems(
      RestExt.SpeedEventModes,
      this.i18n
    );
    this.availableoutputport = RestExt.getEnumItems(
      RestExt.SpeedEventOutputPorts,
      this.i18n
    );
    this.availablespeedingmode = RestExt.getEnumItems(
      RestExt.SpeedEventSpeedingModes,
      this.i18n
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;

    /**Speed Event Fields*/
    this.speedingModeChange();
    this.avaibleLedPatterns = [
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern1.name,
        value: 1,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern2.name,
        value: 2,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern3.name,
        value: 3,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern4.name,
        value: 4,
      },
      {
        label: this.deviceParams.proprietaryParameters.ledPAttern5.name,
        value: 5,
      },
    ];
  }

  handleChange(event) {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
  }

  speedingModeChange() {
    if (
      this.deviceParams &&
      this.deviceParams.proprietaryParameters &&
      this.deviceParams.proprietaryParameters.speedEvent
    ) {
      //Sometimes its called without any reason dropdown (onChanges)
      this.disableOffSpeedingDuration =
        this.deviceParams.proprietaryParameters.speedEvent.speedingMode ==
        RestExt.SpeedEventSpeedingModes[
          RestExt.SpeedEventSpeedingModes
            .ENTER_AND_END_SPEEDING_REPORTS_ACCORDING_TO_THE_DEFINED_DURATION_CONTINUALLY
        ];
    } else {
      console.log(
        "One of this is undefined: this.deviceParams.proprietaryParameters.speedEvent"
      );
    }
    if (this.disableOffSpeedingDuration) {
      this.minSpeedingDuration = 15;
    } else {
      this.minSpeedingDuration = 0;
    }
  }

  speedEventModeChange(event) {}

  speedEventOutputPortChange(event) {
    this.deviceParams.proprietaryParameters.speedEvent.outputControl = !(
      this.deviceParams.proprietaryParameters.speedEvent.outputPort ==
      RestExt.SpeedEventOutputPorts[RestExt.SpeedEventOutputPorts.DISABLE]
    );
  }

  speedEventOutputControlChange(event) {
    if (!this.deviceParams.proprietaryParameters.speedEvent.outputControl) {
      this.deviceParams.proprietaryParameters.speedEvent.outputPort = RestExt
        .SpeedEventOutputPorts[
        RestExt.SpeedEventOutputPorts.DISABLE
      ] as Rest.SpeedEventOutputPorts;
    } else {
      this.deviceParams.proprietaryParameters.speedEvent.outputPort = RestExt
        .SpeedEventOutputPorts[
        RestExt.SpeedEventOutputPorts.OUTPUT_1
      ] as Rest.SpeedEventOutputPorts;
    }
  }
}
