import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityFormComponent } from 'app/components/entity-form/entity-form.component';
import { Rest } from 'app/rest/rest_client';
import { NotificationsService, Severity } from 'app/services/notifications-service/notifications.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { FineService } from 'app/rest/fine.service';
import { VehicleService } from 'app/rest/vehicle.service';
import { UserService } from 'app/rest/user.service';
import { FineStatusService } from 'app/modules/vehicle-inventory/services/table-management/fineStatus.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { isNumericLiteral } from 'typescript';
import { FineReasonService } from 'app/modules/vehicle-inventory/services/table-management/fineReason.service';
import { InvVehicleService } from 'app/modules/vehicle-inventory/services/invVehicle.service';

@Component({
	selector: 'app-fine-form',
	templateUrl: './fine-form.component.html',
	styleUrls: ['./fine-form.component.css'],
	providers: [FineStatusService, UserService, DriverService, InvVehicleService, FineReasonService], //FineService,
	encapsulation: ViewEncapsulation.None,
})
export class FineFormComponent extends EntityFormComponent<Rest.Fine> implements OnInit {
	@Input() vehicle?: Rest.Vehicle = null;
	@Input() startDate?: Date = null;
	@Input() endDate?: Date = null;

	noticeChannels: SelectItem[];
	channelsList: Rest.NoticeChannel[];
	title: string;

	active: boolean;

	/*Permissions */
	disableSave: boolean;
	disabeChangeStatus: boolean;
	file: File;

	newUser: Rest.User;
	newVehicle: Rest.Vehicle;

	sanctionDate: Date;
	notificationDate: Date;
	identityRequestDate: Date;
	identityDate: Date;
	paymentDate: Date;

	editVehicle: boolean;
	backupVehicle: Rest.Vehicle;

	statusRequestContext: any;
	vehicleRequestContext: any;
	driverRequestContext: any;
	reasonRequestContext: any;
	responsibleRequestContext: any;

	statusList: Rest.FineStatus[] = [];

	vehicleList: Rest.Vehicle[] = [];
	vehiclePlaceholder: string;
	driverList: Rest.InvDriver[] = [];
	reasonList: Rest.FineReasons[] = [];
	responsibleList: Rest.User[] = [];

	ids: number[] = [];

	driverLabel: string = '';

	locale: string = 'es-ES';

	communicationMethods: string[];
	errorMessages: string[] = [];
	saving: boolean = false;
	isUpdateGranted: boolean = false;
	isDisableGranted: boolean = false;
	isCreateGranted: boolean = false;

	constructor(
		private objectService: FineService,
		notificationsService: NotificationsService,
		protected i18n: I18nService,
		private authenticationService: AuthenticationService,
		public translateService: TranslateService,
		public vehicleService: InvVehicleService,
		public userService: UserService,
		public fineStatusService: FineStatusService,
		public driverService: DriverService,
		public fineReasonService: FineReasonService
	) {
		super(objectService, notificationsService, i18n, authenticationService);
	}

	ngOnInit() {
		// console.log('[FINE-FORM] ngOnInit ***');
		super.ngOnInit();
		this.communicationMethods = ['MAIL', 'CARTA', 'WEB'];
		this.setPermissions();
		// this.config.setTranslation({
		//   firstDayOfWeek: this.translateService.instant('date.firstDayOfWeek'),
		//   dayNamesMin: this.translateService.instant('date.dayNamesMin'),
		//   monthNames: this.translateService.instant('date.months'),
		// });
	}


	setPermissions() {
		const user = this.authenticationService.user;
		this.isCreateGranted = this.authenticationService.isRoleGranted('INV_FINES_CREATE', user);
		this.isUpdateGranted = this.authenticationService.isRoleGranted('INV_FINES_UPDATE', user);
		this.isDisableGranted = this.authenticationService.isRoleGranted('INV_FINES_DISABLE', user);
	}

	/**
	 * Loads and evaluates all the necessary data before showing the form.
	 *
	 * @memberof FineFormComponent
	 */
	beforeShow() {
		// console.log('[FINE-FORM] beforeShow ***');

		this.active = !this.entity.disabled;
		this.editVehicle = true;
		this.backupVehicle = this.entity.vehicle;

		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: false,
		} as Rest.ListPaginationRequest;

		this.driverRequestContext = {
			sortBy: 'code',
			sortAsc: true,
			pageSize: 10,
			loadPermissions: false,
			filters: {},
		} as Rest.ListPaginationRequest;

		if (this.vehicle) {
			this.entity.vehicle = this.vehicle;
			this.vehicleRequestContext.filters['name'] = [this.vehicle.name];
		}

		this.statusRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {},
			loadPermissions: true,
		} as Rest.ListPaginationRequest;

		this.reasonRequestContext = {
			sortBy: 'reason',
			sortAsc: true,
			pageSize: 1000000000,
			loadPermissions: false,
			filters: { disabled: ['false'] },
		};

		this.responsibleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 100,
			loadPermissions: false,
			filters: { fullName: [''] },
		};

		if (this.isNew) {
			this.sanctionDate = null;
			this.notificationDate = null;
			this.identityRequestDate = null;
			this.identityDate = null;
			this.paymentDate = null;

			this.disableSave = false;
			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.fine.createFine')
				: this.vehicle.name +
				  ' ' +
				  this.vehicle.model?.brand?.name +
				  ' - ' +
				  this.vehicle.model?.name +
				  ' - ' +
				  this.vehicle.version?.name;
			if (this.entity.vehicle) {
				this.assignDriverFromVehicle();
			}
		} else {
			this.ids = [];
			if (this.entity.document && this.entity.document.length > 0) {
				this.entity.document.forEach((x) => this.ids.push(x.id));
			} else {
				this.ids = [-1];
			}

			this.title = !this.vehicle
				? this.translateService.instant('tableManagement.fine.editFine')
				: this.vehicle.name +
				  ' ' +
				  this.vehicle.model?.brand?.name +
				  ' - ' +
				  this.vehicle.model?.name +
				  ' - ' +
				  this.vehicle.version?.name;
			if (this.entity['_$update'] === true) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
			if (this.entity['_$changeStatus'] === true) {
				this.disabeChangeStatus = false;
			} else {
				this.disabeChangeStatus = true;
			}

			this.sanctionDate = this.entity.sanctionDate ? new Date(this.entity.sanctionDate) : null;
			this.notificationDate = this.entity.notificationDate ? new Date(this.entity.notificationDate) : null;
			this.identityRequestDate = this.entity.identityRequestDate ? new Date(this.entity.identityRequestDate) : null;
			this.identityDate = this.entity.identityDate ? new Date(this.entity.identityDate) : null;
			this.paymentDate = this.entity.paymentDate ? new Date(this.entity.paymentDate) : null;

		}

		if (this.entity.driver != null) {
			this.driverRequestContext.filters['user'] = [this.entity.driver.user.surname2 + ", " + this.entity.driver.user.name];
		}

		this.vehiclePlaceholder = this.translateService.instant('general.select');
		this.vehiclePlaceholder += ' ';
		this.vehiclePlaceholder += this.translateService.instant('tableManagement.cardLists.vehicle');

		if (this.entity.vehicle != null && this.entity.vehicle !== undefined) {
			this.vehiclePlaceholder = this.entity.vehicle.name;
		}

		this.fineStatusService.getPage(this.statusRequestContext).then((p) => {
			this.statusList = p.entities;
		});

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});

		this.driverService.getPageAll(this.driverRequestContext).then((p) => {
			this.driverList = p.entities.map((driver) => ({
				...driver,
				formatDriverLabel: `(${driver.code}) ${driver.user.surname1} ${driver.user.surname2}, ${driver.user.name}`,
			}));
		});

		this.fineReasonService.getPage(this.reasonRequestContext).then((p) => {
			this.reasonList = p.entities;
		});

		this.userService.getPage(this.responsibleRequestContext).then((p) => {
			this.responsibleList = p.entities.map((user) => ({
				...user,
				formatResponsibleLabel: `${user.surname1} ${user.surname2}, ${user.name}`,
			}));
		});

		if (this.vehicle !== null && this.vehicle !== undefined) {
			this.editVehicle = false;
		}

		this.display = true;
	}

	onChangeStatus(event) {
		this.entity.finesStatus = event.value;
	}

	onFilterStatus(event) {
		this.statusRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {
				name: [event.filter ?? ''],
			},
			loadPermissions: false,
		} as unknown as Rest.ListPaginationRequest;

		this.fineStatusService.getPage(this.statusRequestContext).then((p) => {
			this.statusList = p.entities;
		});
	}

	onChangeResponsible(event) {
		if (event.value !== null) {
			this.entity.responsible = event.value;
		}
	}

	onFilterResponsible(event) {
		if (event.filter === null) {
			event.filter = '';
		}

		this.responsibleRequestContext.filters['fullName'] = [event.filter];

		this.userService.getPage(this.responsibleRequestContext).then((p) => {
			this.responsibleList = p.entities.map((responsible) => ({
				...responsible,
				formatResponsibleLabel: `${responsible.surname1} ${responsible.surname2}, ${responsible.name}`,
			}));
		});
	}

	onChangeVehicle(event) {
		this.entity.vehicle = event.value;
		this.assignDriverFromVehicle();
	}

	assignDriverFromVehicle() {
		// console.log('[FINE-FORM] assignDriverFromVehicle ***');
		if (this.entity.vehicle.driverAssignments != null) {
			const newestAssignment = this.entity.vehicle.driverAssignments.reduce((latest, current) => {
				const latestStartDate = latest ? new Date(latest.startDate) : new Date(0);
				const currentStartDate = new Date(current.startDate);
				return currentStartDate > latestStartDate ? current : latest;
			}, null);

			// console.log(newestAssignment);
			if (newestAssignment != null) {
				this.entity.driver = {
					id: newestAssignment.driver.id,
					user: newestAssignment.driver.user,
				} as Rest.InvDriver;
				var driver = this.driverList.find((d) => d.id === newestAssignment.driver.id);
				if (driver) {
					this.entity.driver = driver;
				} else {
					this.driverRequestContext.filters['user'] = [newestAssignment.driver.user.name];
					this.driverService.getPageAll(this.driverRequestContext).then((p) => {
						this.driverList = p.entities.map((driver) => ({
							...driver,
							formatDriverLabel: `(${driver.code}) ${driver.user.surname1} ${driver.user.surname2}, ${driver.user.name}`,
						}));
						this.entity.driver = this.driverList.find((d) => d.id === newestAssignment.driver.id);
					});
				}
			}
		}
	}

	onFilterVehicle(event) {
		this.vehicleRequestContext = {
			sortBy: 'name',
			sortAsc: true,
			pageSize: 10,
			filters: {
				name: [event.filter ?? ''],
			},
			loadPermissions: false,
		} as unknown as Rest.ListPaginationRequest;

		this.vehicleService.getPage(this.vehicleRequestContext).then((p) => {
			this.vehicleList = p.entities;
		});
	}

	onChangeDriver(event) {
		this.entity.driver = event.value;
	}

	onFilterDriver(event) {
		this.driverRequestContext.filters['user'] = [event.filter ?? ''];
		this.driverService.getPageAll(this.driverRequestContext).then((p) => {
			this.driverList = p.entities.map((driver) => ({
				...driver,
				formatDriverLabel: `(${driver.code}) ${driver.user.surname1} ${driver.user.surname2}, ${driver.user.name}`,
			}));
		});
	}

	onKeyDownDecimal(event: any) {
		var pressedKey = event.key;
		const keyMap = new Map<number, string>();
		keyMap.set(110, ',');
		const { keyCode } = event;
		if (!event.target.value.includes(',') && (keyMap.has(keyCode) || pressedKey === ',')) {
			event.preventDefault();
			event.target.value += ',';
		}
	}

	onFileSelected(event) {
		this.file = event.target.files[0];
	}

	validateFineForm() {
		this.errorMessages = [];
		if (
			!this.entity.issuer ||
			this.entity.issuer === '' ||
			!this.sanctionDate ||
			!this.entity.reason ||
			!this.entity.fileNumber ||
			!this.entity.vehicle ||
			!this.entity.driver
		) {
			this.errorMessages.push(this.translateService.instant('error.required-field.generic'));
			// this.notificationsService.add(
			// 	Severity.error,
			// 	this.translateService.instant(this.isNew ? 'general.createError' : 'updateError') + '!',
			// 	this.translateService.instant('error.required-field.generic')
			// );
			window.scroll({ top: 0, behavior: 'smooth' });
			this.saving = false;
		}
		return this.errorMessages.length === 0;
	}

	saveChanges() {
		this.saving = true;
		if (this.validateFineForm()) {
			this.entity.sanctionDate = this.sanctionDate;
			this.entity.notificationDate = this.notificationDate;
			this.entity.identityRequestDate = this.identityRequestDate;
			this.entity.identityDate = this.identityDate;
			this.entity.paymentDate = this.entity.paidByDelegation ? this.paymentDate : null;

			if (this.isNew) {
				let apiFine = {
					id: 0,
					vehicle: { id: this.entity.vehicle.id },
					driver: { id: this.entity.driver.id },
					observations: this.entity.observations,
					sanctionDate: this.entity.sanctionDate,
					dgtPoints: this.entity.dgtPoints,
					client: {
						id: this.authenticationService.user.client.id,
					},
					disabled: false,
					issuer: this.entity.issuer,
					reason: this.entity.reason,
					fileNumber: this.entity.fileNumber,
					amount: this.entity.amount,
					bonusAmount: this.entity.bonusAmount,
					notificationDate: this.entity.notificationDate,
					identityRequestDate: this.entity.identityRequestDate,
					identityDate: this.entity.identityDate,
					paymentDate: this.entity.paymentDate,
					finesStatus: {
						id: this.entity.finesStatus.id,
					},
					responsible: this.entity.responsible,
					notificationMethod: this.entity.notificationMethod,
					identificationMethod: this.entity.identificationMethod
				};

				// console.log(apiFine);
				let fineString = JSON.stringify(apiFine);
				this.objectService
					.createFine(fineString)
					.then((p) => {
						this.display = false;
						this.saving = false;
						this.objectService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
						this.return.emit(p);
					})
					.catch((error) => {
						this.notificationsService.add(Severity.error, 'Error', error.message);
						this.saving = false;
						return null;
					});
			} else {
				if (this.entity.vehicle === null) {
					this.entity.vehicle = this.backupVehicle;
				}

				// console.log(this.entity);
				let fineString = JSON.stringify(this.entity);
				this.objectService
					.updateFine(fineString)
					.then((p) => {
						this.display = false;
						this.saving = false;
						this.objectService.refreshList.next({ vehicleId: this.entity.vehicle.id, endDate: new Date() });
						this.return.emit(p);
					})
					.catch((error) => {
						this.notificationsService.add(Severity.error, 'Error', error.message);
						this.saving = false;
						return null;
					});
			}
		}
	}

	refresh() {
		this.return.emit();
	}
}
