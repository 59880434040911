<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GPRS BLOCKING ALARM
          CONFIGURATION (GPRSALM)</span>
      </div>

      <div class="right">
        <p-inputSwitch [(ngModel)]="gprsBlockingAlarm.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>
</p-accordionTab>