import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';
import { I18nService } from 'app/services/i18n/i18n.service';

@Component({
  selector: 'app-fixed-report-information-form',
  templateUrl: './fixed-report-information-form.component.html',
  styleUrls: ['./fixed-report-information-form.component.css'],

})
export class FixedReportInformationFormComponent extends ServerValidatedFormComponent  implements OnInit, OnChanges {

  @Input() friParameters: Rest.FixedReportInformation;

  modeOptions: any = [
    {label: "Disable function",value:0},
    {label: "Fixed Time Report",value:1},
    {label: "Fixed Distance Report",value:2},                     
    {label: "Fixed Mileage Report",value:3},                     
    {label: "Optimum Report",value:4},                     
    {label: "Time or mileage",value:5},                     
  ];
 
  constructor(protected i18n: I18nService) {  super(i18n);}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams",this.friParameters);
    }
  }

  onChange() {
    if (this.friParameters.sendInterval < this.friParameters.checkInterval) {
      this.friParameters.sendInterval = this.friParameters.checkInterval
    }
  }
  
  trackModeSwitch = false;
  
  trackEventModeChange(value, event){
    console.log("value:",value)
    if(value == 0){
      this.trackModeSwitch =  false;
    }else{
      this.trackModeSwitch =  true;
    }
  }
}
