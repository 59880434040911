<p-accordionTab>
    
        <p-header>
            <div class="ui-helper-clearfix">
                <div class="left">
                    <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>ACC Event</span>
                </div>
                <div class="right">
                    <p-inputSwitch   (onChange)="handleChange($event)"  [(ngModel)]="acc.enabled"></p-inputSwitch>
                </div>
            </div>
        </p-header>
    
        <div class="ui-g" [class.disableDIV]=!acc.enabled>

          <div class="ui-g-12 ui-md-6">
              <form-input i18n-fieldName fieldName="duration" [errors]="errors.duration">
                  <div class="ui-inputgroup">
                      <p-spinner thousandSeparator="." id="duration" size="12" [(ngModel)]="acc.duration" step="1"
                          [min]="0" [max]="65535"></p-spinner>
                      <span class="ui-inputgroup-addon">0~65535</span>
                      <span class="ui-inputgroup-addon" i18n>seconds</span>
                      
                  </div>
              </form-input>
          </div>
    
          <div class="ui-g-12 ui-md-6">
              <form-input i18n-fieldName fieldName="On Threshoold" [errors]="errors.onThreshold">
                  <div class="ui-inputgroup">
                      <p-spinner thousandSeparator="." id="onThreshold" size="12" [(ngModel)]="acc.onThreshold" step="0.1"
                          [min]="0" [max]="30.0"></p-spinner>
                      <span class="ui-inputgroup-addon">0.0~30.0</span>
                      <span class="ui-inputgroup-addon" i18n>voltage</span>
                  </div>
              </form-input>
          </div>

          <div class="ui-g-12 ui-md-6">
              <form-input i18n-fieldName fieldName="Off Threshoold" [errors]="errors.offThreshold">
                  <div class="ui-inputgroup">
                      <p-spinner thousandSeparator="." id="offThreshold" size="12" [(ngModel)]="acc.offThreshold" step="0.1"
                          [min]="0" [max]="30.0"></p-spinner>
                      <span class="ui-inputgroup-addon" i18n>0.0~30.0</span>
                      <span class="ui-inputgroup-addon" i18n>voltage</span>
                  </div>
              </form-input>
          </div>


        </div>
    
    </p-accordionTab>