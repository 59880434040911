import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityListComponent } from 'app/components/entity-list/entity-list.component';
import { UserTimePipe } from 'app/components/shared/UserTimePipe';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { DriverService } from 'app/modules/vehicle-inventory/services/driver.service';
import { Rest } from 'app/rest/rest_client';
import { ExcelService } from 'app/services/excel-service/excel.service';
import { I18nService } from 'app/services/i18n/i18n.service';
import { LoadingService } from 'app/services/loading-service/loading.service';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { DriverInfoCardComponent } from '../driver/driver-info-card/driver-info-card.component';
import { Subscription } from 'rxjs';
import { RestExt } from 'app/services/rest-client-extension';

@Component({
  selector: 'app-drivers-select-list',
  templateUrl: './drivers-select-list.component.html',
  styleUrls: ['./drivers-select-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DriversSelectListComponent extends EntityListComponent<Rest.InvDriver> implements OnInit, OnDestroy {
	title: string = "Drivers";

	user: Rest.User;
	EnabledFilter: SelectItem[];
	isDialogVisible: Boolean = false;
	showListSubscription: Subscription;

	driverRequestContext: any;
	driverList: Rest.InvDriver[] = [];

	constructor(
		public driverService: DriverService,
		public confirmationService: ConfirmationService,
		public notificationsService: NotificationsService,
		public i18n: I18nService,
		authenticationService: AuthenticationService,
		public loadingService: LoadingService,
		public userTime: UserTimePipe
	) {
		 super(
			  driverService,
			  confirmationService,
			  notificationsService,
			  i18n,
			  authenticationService,
			  loadingService,
			  userTime
		 );
	}

	ngOnInit() {
		// console.log("[DRIVERS-SELECT-LIST] ngOnInit ***");
		super.ngOnInit();
		/*Permissions */
		this.user = this.authenticationService.user;
		/** */
		this.showListSubscription = this.driverService.selectDriver
			.subscribe(
				() => {
					// console.log("[DRIVERS-SELECT-LIST] driverSelected SUBJECT ***");
					this.showDialog();
				});
	}

	ngOnDestroy(): void {
		this.showListSubscription.unsubscribe();
	}

	showDialog() {
		// console.log("[DRIVERS-SELECT-LIST] showDialog ***");
		this.isDialogVisible = true;
	}

	public selectDriver(event) {
		// console.log("[DRIVERS-SELECT-LIST] selectDriver ***");
		// console.log(event);
		this.driverService.driverSelected.next(event);
		this.isDialogVisible = false;
	}

	fetch() {
		// console.log("[DRIVERS-SELECT-LIST] fetch ***");
		this.loading = true;
		this.loadingService.increaseRESTOngoingRequestCounter();
		const promise = new Promise<void>((resolve, reject) => {
			const that = this;
			//Load the filters and page if requested and the entity can be filtered by other entities
			let t = this.paginationRequest;
			/**
			  * Convert all the dates from the user defined time zone to UTC
			  */
			if (this.paginationRequest != null) {
			   for (let f in this.paginationRequest.filters) {
					for (let idx in this.paginationRequest.filters[f]) {
						if ((this.paginationRequest.filters[f][idx] as any) instanceof Date) {
							(this.paginationRequest.filters[f] as any)[idx] = this.userTime.calendarDateToUTC(this.paginationRequest.filters[f][idx]);
						}
					}
				}
			}

		   this.service
				.getPageAll(this.paginationRequest)
				.then(
					function (response) {
						// console.log("*** fetch response ***");
						// console.log(response);
					  	that.paginationResult = response;
							/*Permissions */
							// eslint-disable-next-line @typescript-eslint/no-shadow
							for (let a in response.permissions) {
								that.permissions[RestExt.ActionEnum[a]] = response.permissions[a];
							}
							//that.form.permissions = that.permissions;
							/** */
							/*Checkbox colum selection */
							for (let a in response.entities) {
								that.entitiesSelected[response.entities[a]['id']] = false;
							}
							that.selectedAll = false;
							/** */
							that.loading = false;
							that.loadingService.decreaseRESTOngoingRequestCounter();
							resolve();
					},
					/* failure */
					function (error) {
						console.log('The request failed: ' + error);
						that.loadingService.decreaseRESTOngoingRequestCounter();
						that.loading = false;
						resolve();
					},
				)
				.catch(function (reason) {
					console.log('Catched: ' + reason);
						that.loadingService.decreaseRESTOngoingRequestCounter();
						that.loading = false;
						resolve();
				});
	 		});
		return promise;
	}
}

