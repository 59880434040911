<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">External power supply
          configuration
          Settings(AT + GTEPS)</span>
      </div>
    </div>
  </p-header>

  <div class="ui-g">
    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Basic
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row row-cols-2 mt-3">
          <div class="col lblStyle">
            <form-input fieldName="Mode">
            </form-input>
          </div>
          <div class="col">
            <div class="ui-inputgroup">
              <p-dropdown [options]="modeOptions" [(ngModel)]="externalPowerSupply.mode"
                [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Min Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.minThreshold" [min]="250"
                  [max]="32000"></p-spinner>
                <span class="ui-inputgroup-addon">250~32000</span>
                <span class="ui-inputgroup-addon">mV</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Max Threshold">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.maxThreshold" [min]="250"
                  [max]="32000"></p-spinner>
                <span class="ui-inputgroup-addon">250~32000</span>
                <span class="ui-inputgroup-addon">mV</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Sample Period">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.samplePeriod" [min]="0" [max]="12"></p-spinner>
                <span class="ui-inputgroup-addon">0~12</span>
                <span class="ui-inputgroup-addon">s(x2)</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Validity">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.validity" [min]="0" [max]="5"></p-spinner>
                <span class="ui-inputgroup-addon">0~5</span>
                <span class="ui-inputgroup-addon">s(x5s)</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Debounce Time">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.debounceTime" [min]="0" [max]="5"></p-spinner>
                <span class="ui-inputgroup-addon">0~5</span>
                <span class="ui-inputgroup-addon">s</span>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Output
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output ID">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-spinner size="5" [(ngModel)]="externalPowerSupply.outputId" [min]="0" [max]="1"></p-spinner>
                <span class="ui-inputgroup-addon">0~1</span>
              </div>
            </div>
          </div>

          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Output Status">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="externalPowerSupply.outputStatus"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </div>

    <div class="ui-g">
      <p-accordionTab [selected]="true">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Other
                Settings</span>
            </div>
          </div>
        </p-header>

        <div class="row mt-3">
          <div class="col row dvcFields">
            <div class="col lblStyle">
              <form-input fieldName="Sync with FRI">
              </form-input>
            </div>
            <div class="col">
              <div class="ui-inputgroup">
                <p-inputSwitch [(ngModel)]="externalPowerSupply.syncFri"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>

      </p-accordionTab>
    </div>
  </div>
</p-accordionTab>