import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../rest/JQueryHTTPClient";

import { EntityService } from "../rest/entity.service";
import { Rest } from "../rest/rest_client";

@Injectable()
export class VehicleUsageService extends EntityService<Rest.VehicleUsage> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.VehicleUsage): Rest.RestResponse<Rest.VehicleUsage> {
    return this.client.VehicleUsage_create(arg0);
  }

  update(arg0: Rest.VehicleUsage): Rest.RestResponse<Rest.VehicleUsage> {
    return this.client.VehicleUsage_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.VehicleUsage>> {
    return this.client.VehicleUsage_getPage(arg0, queryParams);
  }

  getPageAndFilters(
    arg0: Rest.ListPaginationRequest
  ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.VehicleUsage>> {
    return this.client.VehicleUsage_getPageAndFilters(arg0);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.VehicleUsage_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.VehicleUsage> {
    return this.client.VehicleUsage_find(arg0, queryParams);
  }

  	getList(clientId: number): Rest.RestResponse<Rest.VehicleUsage[]> {
		return this.client.VehicleUsage_getList(clientId);
	}
}
