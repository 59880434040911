import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-digital-input-port-setting-gv800',
  templateUrl: './digital-input-port-setting-gv800.component.html',
  styleUrls: ['./digital-input-port-setting-gv800.component.css'],
})
export class DigitalInputPortSettingGv800Component extends ServerValidatedFormComponent implements OnInit {

  @Input() disParameters: Rest.DigitalInputPortSettingsGV800;

  constructor(protected i18n: I18nService) { super(i18n); }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["deviceParams"]) {
      console.log("arrive deviceParams", this.disParameters);
    }
  }

}
