import { Component } from '@angular/core';
import { ConversionalgorithmService } from '../../../rest/conversionalgorithm.service';

import { Rest } from '../../../rest/rest_client';
import { I18nService } from '../../../services/i18n/i18n.service';
import { NotificationsService } from '../../../services/notifications-service/notifications.service';
import { SimpleForm } from '../../simple-form/simple-form';
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";

@Component({
    selector: 'app-actuator-form',
    templateUrl: './actuator-form.component.html',
    styleUrls: ['./actuator-form.component.css'],
    providers: [ConversionalgorithmService]
})

export class ActuatorFormComponent extends SimpleForm<Rest.Sensor> {

    enableFilter;
    conversionAlgorithmService: ConversionalgorithmService;
    user: Rest.User;

    constructor(
        conversionAlgorithmService: ConversionalgorithmService,
        notificationsService: NotificationsService,
        protected i18n: I18nService,
        protected authenticationService: AuthenticationService
    ) {
        super(notificationsService, i18n, authenticationService);
        this.conversionAlgorithmService = conversionAlgorithmService;
    }

    ngOnInit() {
        //TODO: ENABLEFILTER
        this.enableFilter = {
            sortBy: 'name',
            sortAsc: true,
            pageSize: 10,
            loadPermissions: true,
            filters: {},
        };

        super.ngOnInit();
        /**Permissions */
        this.user = this.authenticationService.user;
        /** */
    }

    save() {
        this.return.emit(this.entity);
        this.display = false;
    }

}
