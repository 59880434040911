import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "app/rest/JQueryHTTPClient";
import { EntityService } from "app/rest/entity.service";
import { Rest } from "app/rest/rest_client";


@Injectable()
export class VehicleStatusService extends EntityService<Rest.VehicleStatus> {
  client: Rest.RestApplicationClient;
  constructor(private jquery: JQueryHTTPClient) {
    super();
    this.client = new Rest.RestApplicationClient(jquery);
  }

  create(arg0: Rest.VehicleStatus): Rest.RestResponse<Rest.VehicleStatus> {
      return this.client.VehicleStatus_create(arg0);
  }

  update(arg0: Rest.VehicleStatus): Rest.RestResponse<Rest.VehicleStatus> {
    return this.client.VehicleStatus_update(arg0);
  }

  getPage(
    arg0: Rest.ListPaginationRequest,
    queryParams?: { includeDisabled?: boolean }
  ): Rest.RestResponse<Rest.Page<Rest.VehicleStatus>> {
    return this.client.VehicleStatus_getPage(arg0, queryParams);
  }

  changeStatus(
    arg0: number[],
    queryParams?: { status?: boolean }
  ): Rest.RestResponse<void> {
    return this.client.VehicleStatus_changeStatus(arg0, queryParams);
  }

  // @ts-ignore
  find(
    arg0: string,
    queryParams?: { id?: number }
  ): Rest.RestResponse<Rest.VehicleStatus> {
    return this.client.VehicleStatus_find(arg0, queryParams);
  }
}