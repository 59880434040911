import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-report-detection-event-form",
  templateUrl: "./report-detection-event-form.component.html",
  styleUrls: ["./report-detection-event-form.component.css"],
})
export class ReportDetectionEventFormComponent
  extends ServerValidatedFormComponent
  implements OnChanges
{
  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  @Input() dr: Rest.DR;

  ngOnChanges(changes: SimpleChanges) {}
}
