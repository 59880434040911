import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-table-duplicate-button',
  templateUrl: './table-duplicate-button.component.html',
  styleUrls: ['./table-duplicate-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableDuplicateButtonComponent {

  _text: string;

  @Input() set text(text: string) {
    this._text = text;
  }

  @Input() isDisabled = false;
  @Output() btnClick = new EventEmitter();

  constructor() {
    this._text = '';
  }

  onClick() {
    this.btnClick.emit();
  }
}
