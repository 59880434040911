<p-accordionTab i18n-header header="">
<p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" i18n>SOS Event</span>
      </div>
      <div class="right">
        <p-inputSwitch   (onChange)="handleChange($event)"  [(ngModel)]="sosEvent.enabled"></p-inputSwitch>
      </div>
    </div>
  </p-header>
  <div class="ui-g" [class.disableDIV]=!sosEvent.enabled>

    <form-input i18n-fieldName fieldName="Output pattern" [errors]="errors.name">
      <app-output-pattern [outputPattern]="sosEvent.outputPattern"></app-output-pattern>
    </form-input>
  </div>

</p-accordionTab>
