<div id="pre-config-form">

    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-messages [(value)]="messages"></p-messages>
      </div>
    </div>

    <p-panel header="GV300CAN Parameters">
        <div class="ui-g" *ngIf=isConfig style="margin-bottom:20px">
            <div class="ui-g-12 ui-md-6">
                <form-input i18n-fieldName fieldName="load preconfig" [errors]="errors.preConfig">
                    <p-dropdown (onChange)="loadPreconfig($event)" i18n-placeholder placeholder="Select" editable="editable" [options]="availablePreconfigs"
                        [(ngModel)]="selectedPreconfig" [style]="{'width':'200px'}" filter="filter"></p-dropdown>
                </form-input>
            </div>
          </div>

      <p-tabView #tv (onChange)="tabChanged()">
        <p-tabPanel header="Events">
          <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
            <!-- This should be where all form components will display -->
            <app-global-parameters-form-gv300can [globalParameters]="deviceParams.proprietaryParameters.globalParameters">
            </app-global-parameters-form-gv300can>
            <app-fixed-report-information-form-gv300can [friParameters]="deviceParams.proprietaryParameters.friParameters">
            </app-fixed-report-information-form-gv300can>
            <app-tow-alarm-information-form-gv300can [towAlarm]="deviceParams.proprietaryParameters.towAlarm">
            </app-tow-alarm-information-form-gv300can>
            <app-speed-alarm-form-gv300can [speedAlarmParameters]="deviceParams.proprietaryParameters.speedAlarmParameters">
            </app-speed-alarm-form-gv300can>
            <app-sos-form-gv300can [sosParameters]="deviceParams.proprietaryParameters.sosParameters"></app-sos-form-gv300can>
            <app-hbm-form-gv300can [hbmParameters]="deviceParams.proprietaryParameters.hbmParameters"></app-hbm-form-gv300can>
            <app-jamming-detection-information-form-gv300can
              [jammingDetection]="deviceParams.proprietaryParameters.jammingDetection">
            </app-jamming-detection-information-form-gv300can>
            <app-start-stop-report-information-form-gv300can
              [StartStopReport]="deviceParams.proprietaryParameters.startStopReport">
            </app-start-stop-report-information-form-gv300can>
            <app-jamming-behavior-setting-form-gv300can [jbsParameters]="deviceParams.proprietaryParameters.jbsParameters">
            </app-jamming-behavior-setting-form-gv300can>
            <app-analog-input-port-setting-gv300can [aisParameters]="deviceParams.proprietaryParameters.aisParameters"></app-analog-input-port-setting-gv300can>
            <app-digital-input-port-setting-gv300can [disParameters]="deviceParams.proprietaryParameters.disParameters"></app-digital-input-port-setting-gv300can>
            <app-canbus-device-configuration-gv300can [canParameters]="deviceParams.proprietaryParameters.canParameters"></app-canbus-device-configuration-gv300can>
            <app-authentication-id-configuration-gv300can [idaParameters]="deviceParams.proprietaryParameters.idaParameters"></app-authentication-id-configuration-gv300can>
          </p-accordion>

        </p-tabPanel>


        <p-tabPanel  header="Sensors">
          <app-sensor-list [(sensors)]="deviceParams.sensors"></app-sensor-list>
          <!-- Sensors list -->
        </p-tabPanel>

        <p-tabPanel i18n-header header="Actuators">
          <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
      </p-tabPanel>

        <!-- Commented code for actuators (why?)
              <p-tabPanel  header="Actuators">
                  <app-actuators-list [(actuators)]="deviceParams.actuators"></app-actuators-list>
              </p-tabPanel>

              <p-tabPanel  header="Led Patterns">
                  <p-accordion [multiple]="true" [style]="{'margin-top':'10px'}" #ac>
                      <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern1"></app-led-pattern-config-form>
                      <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern2"></app-led-pattern-config-form>
                      <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern3"></app-led-pattern-config-form>
                      <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern4"></app-led-pattern-config-form>
                      <app-led-pattern-config-form [(ledPattern)]="deviceParams.proprietaryParameters.ledPAttern5"></app-led-pattern-config-form>
                  </p-accordion>
              </p-tabPanel>

              <p-tabPanel  header="RFTags">
                  <app-rftag-configuration [(RFTags)]="deviceParams.proprietaryParameters.rfTags"></app-rftag-configuration>
              </p-tabPanel>

               -->
      </p-tabView>
    </p-panel>
    <p-confirmDialog  header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
  </div>
