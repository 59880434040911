import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../rest/JQueryHTTPClient";
import { EntityService } from "../rest/entity.service";
import { Rest } from "../rest/rest_client";
import { Severity } from "app/services/notifications-service/notifications.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { RestVehicleInventory } from "app/modules/vehicle-inventory/services/rest_vehicle-inventory";

@Injectable({
	providedIn: 'root'
 })
export class FineService extends EntityService<Rest.Fine>{
    client: Rest.RestApplicationClient;
    refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(
        private jquery: JQueryHTTPClient,
        private http: HttpClient
    ) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: Rest.Fine): Rest.RestResponse<Rest.Fine> {
        return this.client.Fine_create(arg0);
    }

    update(arg0: Rest.Fine): Rest.RestResponse<Rest.Fine> {
        return this.client.Fine_update(arg0);
    }

   //  createFine(arg0: any): Rest.RestResponse<Rest.Fine> {
	// 	try {
	// 		return this.client.Fine_create(arg0);
	//   } catch (error) {
	// 		console.error("Error creating Fine:", error);
	// 		throw error;
	//   }
   //  }

   //  updateFine(arg0: any): Rest.RestResponse<Rest.Fine> {
	// 	return this.client.Fine_update(arg0);
   //  }

	createFine(fine: string, document?: any): Rest.RestResponse<Rest.Fine> {
		const formData = new FormData();
		formData.append('fines', fine);

		if (document) {
			 formData.append('documentFile', document);
		}

		return this.http
			 .post(environment.webApiBaseUrl + 'api/inventory/fines', formData)
			 .toPromise()
			 .then((response: any) => {
				  return response;
			 });
	}

  updateFine(fine: string, document?: any): Rest.RestResponse<Rest.Fine> {
		const formData = new FormData();
		formData.append('fines', fine);

		if (document) {
			 formData.append('documentFile', document);
		}

		return this.http
			 .post(environment.webApiBaseUrl + 'api/inventory/fines/update', formData)
			 .toPromise()
			 .then((response: any) => {
				  return response;
			 });
  }

    getPage(
        arg0: Rest.ListPaginationRequest,
    ): Rest.RestResponse<Rest.Page<Rest.Fine>> {
        return this.client.Fine_getPage(arg0);
    }

    getPageAndFilters(
        arg0: Rest.ListPaginationRequest
    ): Rest.RestResponse<Rest.ListPageAndFilters<Rest.Fine>> {
        return this.client.Fine_getPageAndFilters(arg0);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<any> {
        return this.client.Fine_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Fine> {
        return this.client.Fine_find(arg0, queryParams);
    }

	createDocument(fineId: number, documentId: number): Rest.RestResponse<Rest.Fine> {
		console.log("[FINE-SERVICE] createDocument ***");
		console.log(fineId);
		console.log(documentId);
		let queryParams = {
			fineId: fineId,
			documentId: documentId
		}
		return this.client.Fine_addDocument(queryParams);
   }
}
