import { Injectable } from "@angular/core";
import { JQueryHTTPClient } from "../../../../rest/JQueryHTTPClient";
import { EntityService } from "../../../../rest/entity.service";
import { Rest } from "../../../../rest/rest_client";
import { Subject } from "rxjs";
import { RestVehicleInventory } from "../rest_vehicle-inventory";

@Injectable()
export class MileageService extends EntityService<Rest.Mileage> {
    client: Rest.RestApplicationClient;
	 refreshList = new Subject<RestVehicleInventory.InvVehicleHtcRequest>();

    constructor(private jquery: JQueryHTTPClient) {
        super();
        this.client = new Rest.RestApplicationClient(jquery);
    }

    create(arg0: any): Rest.RestResponse<Rest.Mileage> {
        try {
            return this.client.Mileage_create(arg0);
        } catch (error) {
            throw error;
        }
    }

    update(arg0: any): Rest.RestResponse<Rest.Mileage> {
        return this.client.Mileage_update(arg0);
    }

    getPage(
        arg0: Rest.ListPaginationRequest,
        queryParams?: { includeDisabled?: boolean }
    ): Rest.RestResponse<Rest.Page<Rest.Mileage>> {
        return this.client.Mileage_getPage(arg0, queryParams);
    }

    changeStatus(
        arg0: number[],
        queryParams?: { status?: boolean }
    ): Rest.RestResponse<void> {
        return this.client.Mileage_changeStatus(arg0, queryParams);
    }

    // @ts-ignore
    find(
        arg0: string,
        queryParams?: { id?: number }
    ): Rest.RestResponse<Rest.Mileage> {
        return this.client.Mileage_find(arg0, queryParams);
    }
}
