<p-accordionTab>
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="left">
              <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px" >Function Key Setting (AT+GTFKS)</span>
            </div>
          </div>
        </p-header>
        
        <div class="row align-items-center">

            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Power Key Mode" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="powerKeyOptions"
                    [(ngModel)]="fksParameters.powerKeyMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>
        
            <div class="col row dvcFields">
                <div class="col lblStyle">
                    <form-input fieldName="Full Power On" >
                    </form-input>
                </div>
                <div class="col">
                    <p-dropdown [options]="powerOptions"
                    [(ngModel)]="fksParameters.powerMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                </div>
            </div>
        
        </div>
            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Function Key Mode" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="functionKeyOptions"
                        [(ngModel)]="fksParameters.functionKeyMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Power Key Indication" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="powerKeyIndicationOptions"
                        [(ngModel)]="fksParameters.powerKeyIndicationMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
            </div>
            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Function Key Mode" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="functionKeyIndicationOptions"
                        [(ngModel)]="fksParameters.functionKeyIndicationMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="SOS Report Mode" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="sosOptions"
                        [(ngModel)]="fksParameters.reportSosMode" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="First Trigger Time">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="fksParameters.firstTriggerTime"
                                [min]="1" [max]="99"></p-spinner>
                            <span class="ui-inputgroup-addon">1~99</span>
                            <span class="ui-inputgroup-addon" >s</span>
                        </div>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input  fieldName="Second Trigger Time">
                        </form-input>
                    </div>
                    <div class="col">
                        <div class="ui-inputgroup">
                            <p-spinner size="5" step="1" [(ngModel)]="fksParameters.secondTriggerTime"
                                [min]="1" [max]="99"></p-spinner>
                            <span class="ui-inputgroup-addon">1~99</span>
                            <span class="ui-inputgroup-addon" >s</span>
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="row align-items-center">

                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="First Trigger Event" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="triggerOptions"
                        [(ngModel)]="fksParameters.firstTriggerEvent" [style]="{'width':'100%','min-width':'100px'}" appendTo="body"></p-dropdown>
                    </div>
                </div>
            
                <div class="col row dvcFields">
                    <div class="col lblStyle">
                        <form-input fieldName="Second Trigger Event" >
                        </form-input>
                    </div>
                    <div class="col">
                        <p-dropdown [options]="triggerOptions"
                        [(ngModel)]="fksParameters.secondTriggerEvent" [style]="{'width':'100%','min-width':'100px'}"></p-dropdown>
                    </div>
                </div>
            
            </div>
      </p-accordionTab>