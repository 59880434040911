<div *ngIf="showHeader()" class="main-header-container">
	<div class="container-fluid inner-header-container align-items-center p-0">

		<!-- ROW 1: PAGE TITLE & PROFILE ITEMS -->
		<div class="row align-self-center header-first-row m-0">

			<!-- COL 1: PAGE TITLE-->
			<div class="col align-self-center mx-5">
				<div class="d-flex align-items-center">

					<div>
						<svg-icon src='{{sectionIconUrl}}' [svgStyle]="{'width.px': 30}"></svg-icon>
					</div>

					<div class="title-text mx-2 d-none d-sm-flex">
						<span>{{sectionTitle}}</span>
					</div>

				</div>
			</div>

			<!-- COL 2: PROFILE ITEMS -->
			<div class="col align-self-center me-5">
				<div class="d-flex justify-content-end align-items-center gap-4">

					<div class="position-relative">
						<div class="notification-icon" (click)="showNotificationsPanel()">
							<!-- Always display the default bell icon -->
							<svg-icon src="assets/icons/bell.svg" [svgStyle]="{'height.px': 20}"></svg-icon>

							<!-- Conditionally display a badge if there are notifications -->
							<div *ngIf="hasRemainingIncidences || (hasUntreatedIncidences$ | async)"
								class="notification-badge"></div>
						</div>

						<app-notification-panel *ngIf="showNotifications" class="m-1"></app-notification-panel>
					</div>

					<div class="help-icon" (click)="showHelpPanel()">
						<svg-icon src="assets/icons/help-circle.svg" [svgStyle]="{'height.px': 20}"></svg-icon>
					</div>

					<div class="d-flex align-items-center">

						<div class="user-image" (click)="showUserPanel(userProfilePanel['modal'])">
							<img src="{{userProfileImage}}">
						</div>

						<div class="user-name mx-3 me-0 d-none d-sm-flex">
							<span>{{userNameAndSurname}}</span>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

<app-user-settings #userProfilePanel></app-user-settings>