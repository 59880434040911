import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Rest } from "../../../../rest/rest_client";
import { ServerValidatedFormComponent } from "../../../server-validated-form/server-validated-form.component";
import { I18nService } from "app/services/i18n/i18n.service";

@Component({
  selector: "app-global-parameters-form-gv300can",
  templateUrl: "./global-parameters-form-gv300can.component.html",
  styleUrls: ["./global-parameters-form-gv300can.component.css"],
})
export class GlobalParametersFormGv300canComponent
  extends ServerValidatedFormComponent
  implements OnInit
{
  @Input() globalParameters: Rest.GlobalParametersGV300CAN;

  powerSavingOptions: any = [
    { label: "Disable the power saving function.", value: 0 },
    { label: "Mode 1 of the power saving function.", value: 1 },
    { label: "Mode 2 of the power saving function.", value: 2 },
  ];

  GSMReportOptions: any = [
    { label: "Do not allow the cell information report.", value: 0 },
    {
      label:
        "Report the cell information after failing to get GPS position if cell information is available.",
      value: 1,
    },
    {
      label:
        "Report the message +RESP:GTGSM after getting GPS position successfully every time if cell information is available.",
      value: 2,
    },
    {
      label:
        "Report the message +RESP:GTGSM regardless of GPS position fix result every time if cell information is available.",
      value: 3,
    },
  ];

  GPSWorkingModeOptions: any = [
    { label: "GPS and GLONASS positioning systems.", value: 0 },
    { label: "GPS positioning system.", value: 1 },
    { label: "GLONASS positioning system.", value: 2 },
  ];

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["deviceParams"]) return;
  }
}
