import { Component, OnInit, ViewEncapsulation, OnChanges, Input, SimpleChanges } from '@angular/core';
import { I18nService } from 'app/services/i18n/i18n.service';
import { Rest } from '../../../../rest/rest_client';
import { ServerValidatedFormComponent } from '../../../server-validated-form/server-validated-form.component';

@Component({
  selector: 'app-protocol-watchdog-gl320m',
  templateUrl: './protocol-watchdog-gl320m.component.html',
  styleUrls: ['./protocol-watchdog-gl320m.component.css']
})
export class ProtocolWatchdogGl320mComponent extends ServerValidatedFormComponent implements OnInit {


  modeOptions: any = [
   { label: "Disable this function", value: "DISABLE"},
   { label: "Reboot periodically according to the <Interval> and <Time> setting", value: "REBOOT_PERIODICALLY"},
   { label: "Reboot when ignition on", value: "REBOOT_WHEN_IGNITION_ON"},
  ]

  unitOptions: any = [
   { label: "Unit is day", value: "DAY"},
   { label: "Unit is hour", value: "HOUR"},
  ]
 
   @Input() dogParameters: Rest.ProtocolWatchdogGL320M;
   constructor(protected i18n: I18nService) { super(i18n); }
 
   ngOnInit() {
   }
 
 
 
   ngOnChanges(changes: SimpleChanges){
     if (changes["deviceParams"]) {
       console.log("arrive deviceParams", this.dogParameters);
     }
   }
 
 }
