<p-dialog header="{{title}}" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" *ngIf="display" appendTo="body">

    <!-- #region BODY -->
    <div class="container-md">

        <div class="ui-grid-row">
            <div class="ui-grid-col-12">
                <p-messages [(value)]="messages"></p-messages>
            </div>
        </div>

        <!-- NAME -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardType-name" class="custom-label" i18n>
                    {{'general.name' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="cardType-name" class="form-control custom-input" [(ngModel)]="entity.name">
                </div>
            </div>
        </div>

        <!-- PROVIDER -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="vehicle-data-settings-name" class="custom-label" i18n>
                    {{'promotedRoutes.providerName' | translate}}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <p-dropdown [options]='selectableProviders' [(ngModel)]='entity.provider'
                        dataKey='id' optionLabel='name' [style]="{'width':'250px'}" appendTo='body' filter="true" filterBy="name"
                        (onChange)="onChangeProvider($event)"
                        (onFilter)="onFilterProvider($event)">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!-- CODE PRODUCT LIMIT -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardType-cpLimit" class="custom-label" i18n>
                    {{'tableManagement.cardTypes.codeProductLimit' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="text" id="cardType-cpLimit" class="form-control custom-input"
                        [(ngModel)]="entity.codeProductLimit">
                </div>
            </div>
        </div>

        <!-- PRODUCT LIMIT -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardType-productLimit" class="custom-label" i18n>
                    {{'tableManagement.cardTypes.productLimit' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardType-productLimit" class="form-control custom-input"
                        [(ngModel)]="entity.productLimit">
                </div>
            </div>
        </div>

        <!-- OPERATION LIMIT -->
        <div class="row mt-3">
            <div class="col d-flex align-items-center">
                <label for="cardType-operationLimit" class="custom-label" i18n>
                    {{'tableManagement.cardTypes.operationLimit' | translate}}
                </label>
            </div>

            <div class="col">
                <div class="float-end">
                    <input type="number" id="cardType-operationLimit" class="form-control custom-input"
                        [(ngModel)]="entity.operationLimit">
                </div>
            </div>
        </div>

        <!-- ACTIVE -->
        <div class="row mt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="cardType-enabled" class="custom-label" i18n>
                    {{'general.active' | translate }}
                </label>
            </div>
            <div class="col">
                <div class="float-end">
                    <input class="form-check-input d-flex justify-content-center align-items-center custom-checkbox"
                        type="checkbox" [(ngModel)]="active">
                </div>
            </div>
        </div>

        <div class="row mt-2 mb-4">

            <!-- PROFILE IMAGE -->
            <img #image src="{{cardTypeEncodedImage}}" class="custom-user-profile-image mx-auto d-block p-0">

            <!-- ADD IMAGE BUTTON -->
            <div id="outer-icon-holder" class="d-flex mx-auto align-items-center justify-content-center">
                <div id="inner-icon-holder" class="d-flex align-items-center justify-content-center"
                    (click)='uploadImageInput.click()'>
                    <img src="assets/icons/camera-plus.png" class="custom-user-profile-icon" />
                    <input #uploadImageInput [hidden]='true' type='file' accept='.jpg, .png'
                        (change)='imageChange($event)'>
                </div>
            </div>
        </div>
    </div>
    <!-- #endregion BODY -->

    <!-- #region FOOTER -->
    <p-footer>
        <div class="d-flex justify-content-center bd-highlight">
            <div class="p-2 bd-highlight">
                <div *ngIf="isNew">
                    <button class="btn custom-button" (click)="saveCardType()" i18n>
                        {{'general.create' | translate}}
                    </button>
                </div>
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="saveCardType()" i18n>
                        {{'general.save' | translate}}
                    </button>
                </div>
            </div>
            <div class="p-2 bd-highlight">
                <div *ngIf="!isNew">
                    <button class="btn custom-button" (click)="changeStatus(!active)" i18n>
                        {{'general.changeStatus' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
    <!-- #endregion FOOTER -->
</p-dialog>