<!----------------------->
<!-- FULLSCREEN NAVBAR -->
<!----------------------->
<nav class="navbar navbar-expand-lg mt-3 mb-4">
	<div class="container">
		<div class="collapse navbar-collapse align-items-center justify-content-center" id="navbarSupportedContent">
			<ul class="navbar-nav">

				<!-- PROVIDERS -->
				<li class="nav-item custom-table-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="providers"
						[routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/gps.svg" id='clientsIcon'
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.providers.providers' | translate}}</div>
					</a>
				</li>

				<!-- VEHICLE DROPDOWN -->
				<li class="nav-item dropdown custom-table-nav-element">

					<!-- VEHICLE DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': vehicleStatus.isActive || vehicleUsage.isActive || brands.isActive || models.isActive || versions.isActive || accessories.isActive || fines.isActive || fineStatus.isActive }"
						role="button" id="vehicleDropdown" role="button" data-bs-toggle="dropdown"
						aria-expanded="false">
						<svg-icon src="assets/icons/vehicle.svg"
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.vehicles' | translate}}</div>
					</a>


					<!-- VEHICLE DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu"
						aria-labelledby="vehicleDropdown">
						<!-- VEHICLE STATUS -->
						<li class="nav-item">
							<!-- VEHICLE STATUS -->
							<a [routerLinkActive]="['nav-link-active']" routerLink="vehicleStatus"
								#vehicleStatus="routerLinkActive" class="nav-link">
								<div class="nav-bar-text" i18n>
									{{'tableManagement.vehicleStatus.vehicleStatus' | translate}}
								</div>
							</a>
							<!-- VEHICLE USAGE -->
							<a [routerLinkActive]="['nav-link-active']" routerLink="vehicleUsage"
								#vehicleUsage="routerLinkActive" class="nav-link">
								<div class="nav-bar-text" i18n>
									{{'tableManagement.vehicleUsage.vehicleUsage' | translate}}
								</div>
							</a>
							<!-- VEHICLE BRAND -->
							<a [routerLinkActive]="['nav-link-active']" routerLink="brands" #brands="routerLinkActive"
								class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.brands.brands' | translate}}
								</div>
							</a>

							<!-- VEHICLE MODEL -->
							<a [routerLinkActive]="['nav-link-active']" routerLink="models" #models="routerLinkActive"
								class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.models.models' | translate}}
								</div>
							</a>

							<!-- VEHICLE VERSION -->
							<a [routerLinkActive]="['nav-link-active']" routerLink="versions"
								#versions="routerLinkActive" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.versions.version' | translate}}
								</div>
							</a>

							<!-- ACCESSORIES -->
							<a [routerLinkActive]="['nav-link-active']" #accessories="routerLinkActive"
								routerLink="accessories" class="nav-link">
								<div class="nav-bar-text" i18n>
									{{'tableManagement.accessories.accessories' | translate}}
								</div>
							</a>
							<!-- FINES -->
							<a [routerLinkActive]="['nav-link-active']" #fines="routerLinkActive" routerLink="fines"
								class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.fine.fines' |
									translate}}</div>
							</a>
							<!-- FINES STATUS -->
							<a [routerLinkActive]="['nav-link-active']" #fineStatus="routerLinkActive"
								routerLink="fineStatus" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.fineStatus.finesStatus' |
									translate}}</div>
							</a>
							<!-- FINES REASONS -->
							<a [routerLinkActive]="['nav-link-active']" #fineReasons="routerLinkActive"
								routerLink="fineReasons" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.fineReason.fineReason' |
									translate}}</div>
							</a>
						</li>

					</ul>
				</li>

				<!-- ORGANIZATION DROPDOWN -->
				<li class="nav-item dropdown custom-table-nav-element">

					<!-- ORGANIZATION DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': delegations.isActive || subdelegations.isActive  || divisions.isActive  || subdivisions.isActive  || companies.isActive  || costCentres.isActive || leaders.isActive}"
						id="organizationsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/memory-chip.svg"
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.organization' | translate}}</div>
					</a>

					<!-- ORGANIZATION DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu"
						aria-labelledby="organizationsDropdown">

						<!-- DELEGATIONS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #delegations="routerLinkActive"
								routerLink="delegations" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.delegations.delegations' | translate}}
								</div>
							</a>
						</li>

						<!-- SUBDELEGATIONS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #subdelegations="routerLinkActive"
								routerLink="subdelegations" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.subdelegations.subdelegations' |
									translate}}</div>
							</a>
						</li>

						<!-- DIVISIONS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #divisions="routerLinkActive"
								routerLink="divisions" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.divisions.divisions' | translate}}
								</div>
							</a>
						</li>

						<!-- SUBDDIVISIONS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #subdivisions="routerLinkActive"
								routerLink="subdivisions" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.subdivisions.subdivisions' |
									translate}}</div>
							</a>
						</li>

						<!-- COMPANIES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #companies="routerLinkActive"
								routerLink="companies" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.companies.companies' |
									translate}}</div>
							</a>
						</li>

						<!-- COST CENTRES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #costCentres="routerLinkActive"
								routerLink="costCentres" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.costCentres.costCentres' |
									translate}}</div>
							</a>
						</li>

						<!-- LEADERS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #leaders="routerLinkActive" routerLink="leaders"
								class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.leaders.leaders' | translate}}</div>
							</a>
						</li>
					</ul>
				</li>

				<!-- CARDS DROPDOWN -->
				<li class="nav-item dropdown custom-table-nav-element">

					<!-- CARDS DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': cardTypes.isActive || cardLists.isActive || cardConsumptions.isActive}"
						id="cardDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/card.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.cards' | translate}}</div>
					</a>

					<!-- CARDS DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu" aria-labelledby="cardDropdown">

						<!-- CARD TYPES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #cardTypes="routerLinkActive"
								routerLink="cardTypes" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.cardTypes.cardTypes' |
									translate}}</div>
							</a>
						</li>

						<!-- CARD LISTS  -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #cardLists="routerLinkActive"
								routerLink="cardLists" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.cardLists.cardLists' |
									translate}}</div>
							</a>
						</li>

						<!-- CARD CONSUMPTIONS -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #cardConsumptions="routerLinkActive"
								routerLink="cardConsumptions" class="nav-link">
								<div class="nav-bar-text" i18n>
									{{'tableManagement.cardsConsumption.cardConsumptions' |
									translate}}
								</div>
							</a>
						</li>

					</ul>
				</li>

				<!-- FINANCE -->
				<li class="nav-item dropdown custom-table-nav-element">
					<!-- FINANCES DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': financeTypes.isActive || wayToPay.isActive  || rentingTypes.isActive}"
						id="rentingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/contract.svg"
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.contracts' | translate}}</div>
					</a>
					<!-- FINANCES DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu"
						aria-labelledby="rentingDropdown">

						<!-- FINANCE TYPES -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #financeTypes="routerLinkActive"
								routerLink="financeTypes" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.finances.financeTypes' |
									translate}}</div>
							</a>
						</li>

						<!-- WAYS  TO PAY -->
						<li class="nav-item">
							<a [routerLinkActive]="['nav-link-active']" #wayToPay="routerLinkActive"
								routerLink="wayToPay" class="nav-link">
								<div class="nav-bar-text" i18n>{{'tableManagement.wayToPay.waysToPay' |
									translate}}</div>
							</a>
						</li>

						<!-- RENTING TYPES -->
						<a [routerLinkActive]="['nav-link-active']" #rentingTypes="routerLinkActive"
							routerLink="rentingTypes" class="nav-link">
							<div class="nav-bar-text" i18n>{{'tableManagement.rentingTypes.rentingTypes' |
								translate}}</div>
						</a>

						<!-- FINANCING -->
						<a [routerLinkActive]="['nav-link-active']" #deviceTypes="routerLinkActive"
							routerLink="financing" class="nav-link">
							<div class="nav-bar-text" i18n>{{'tableManagement.financing.financings' |
								translate}}</div>
						</a>
					</ul>
				</li>

				<!-- INSURANCE -->
				<li class="nav-item dropdown custom-table-nav-element">

					<!-- INSURANCE DROPDOWN HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': InsuranceType.isActive }" id="insuranceDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/shield_check.svg"
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.insurances' | translate}}</div>
					</a>

					<!-- INSURANCE DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu"
						aria-labelledby="insuranceDropdown">

						<!-- INSURACNCE TYPES -->
						<li class="nav-item">
							<a class="nav-link" routerLink="InsuranceType" #InsuranceType="routerLinkActive"
								[routerLinkActive]="['nav-link-active']">
								<div class="nav-bar-text" i18n>{{'tableManagement.insurance.insuranceTypes' |
									translate}}
								</div>
							</a>
						</li>

						<!-- INSURANCE -->
						<li class="nav-item">
							<a class="nav-link" routerLink="insurance" [routerLinkActive]="['nav-link-active']">
								<div class="nav-bar-text" i18n>{{'tableManagement.insurances' |
									translate}}
								</div>
							</a>
						</li>
					</ul>
				</li>

				<!-- MAINTENANCE -->
				<li class="nav-item dropdown custom-table-nav-element">
					<!-- MAINTENANCE HEADER -->
					<a class="nav-link d-flex align-items-center justify-content-center p-0"
						[ngClass]="{'nav-link-active': maintenancePlans.isActive }" id="maintenanceDropdown"
						role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<svg-icon src="assets/icons/settings.svg"
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'header.maintenance' | translate}}</div>
					</a>

					<!-- MAINTENANCE DROPDOWN ELEMENTS -->
					<ul class="dropdown-menu animate slideIn custom-table-dropdown-menu"
						aria-labelledby="maintenanceDropdown">

						<!-- MAINTENANCE PLANS -->
						<li class="nav-item">
							<a class="nav-link" routerLink="maintenancePlans" #maintenancePlans="routerLinkActive"
								[routerLinkActive]="['nav-link-active']">
								<div class="nav-bar-text" i18n>{{'navbar.maintenancePlans' |
									translate}}
								</div>
							</a>
						</li>
					</ul>
				</li>

				<!-- REAL STATE -->
				<li class="nav-item custom-table-nav-element">
					<a class="nav-link d-flex justify-content-center align-items-center p-0" routerLink="realState"
						[routerLinkActive]="['nav-link-active']">
						<svg-icon src="assets/icons/gps.svg" id='clientsIcon'
							class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
							[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
						<div class="nav-bar-text" i18n>{{'tableManagement.real-state.realState' |
							translate}}</div>
					</a>
				</li>

			</ul>
		</div>
	</div>
</nav>

<!------------------->
<!-- MOBILE NAVBAR -->
<!------------------->
<div class="dropdown dropend d-sm-block d-lg-none">

	<a id="mobileMenuDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"
		aria-expanded="false">
		<svg-icon src="assets/icons/three-bars.svg" [svgStyle]="{ 'height.px': 50, 'width.px': 50 }"></svg-icon>
	</a>

	<div class="dropdown-menu animate slideIn border-0 p-0 mx-5" aria-labelledby="mobileMenuDropdown"
		style="min-width: 250px;">
		<div class="list-group custom-mobile-menu-list-group">

			<!-- DEVICES -->
			<div class="list-group-item border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex align-items-center justify-content-start p-0"
					[ngClass]="{'nav-link-active': devices.isActive || deviceTypes.isActive || conversionAlgorithms.isActive}"
					role="button" href="#devicesCollapse" data-bs-toggle="collapse" aria-controls="devicesCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/memory-chip.svg"
						class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.devices' | translate}}</div>
				</a>
			</div>

			<!-- DEVICES SUB ITEMS -->
			<div class="list-group collapse" id="devicesCollapse">

				<!-- DEVICES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #devices="routerLinkActive" routerLink="devices"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.devices' | translate}}</div>
					</a>
				</div>

				<!-- DEVICE TYPES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #deviceTypes="routerLinkActive" routerLink="deviceTypes"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.deviceTypes' | translate}}</div>
					</a>
				</div>

				<!-- CONVERSION ALGORITHM -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [routerLinkActive]="['nav-link-active']" #conversionAlgorithms="routerLinkActive"
						routerLink="conversionAlgorithms" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.conversionAlgorithms' | translate}}</div>
					</a>
				</div>
			</div>

			<!-- VEHICLES -->
			<div *ngIf="manageVehicles || manageMaintenancePlans"
				class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': vehicles.isActive || maintenancePlans.isActive, 'disabled': !manageVehicles && !manageMaintenancePlans}"
					role="button" href="#vehiclesCollapse" data-bs-toggle="collapse" aria-controls="vehiclesCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/vehicle.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.vehicles' | translate}}</div>
				</a>
			</div>

			<!-- VEHICLES SUB ITEMS -->
			<div class="list-group collapse" id="vehiclesCollapse">

				<!-- VEHICLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageVehicles}" routerLink="fleets"
						[routerLinkActive]="['nav-link-active']" #vehicles="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.vehicles' | translate}}</div>
					</a>
				</div>

				<!--MAINTENANCE PLANS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageMaintenancePlans}" routerLink="maintenancePlans"
						[routerLinkActive]="['nav-link-active']" #maintenancePlans="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.maintenancePlans' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- ZONES -->
			<div *ngIf="manageZones" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="zoneGroups"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/grid.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.zones' | translate}}</div>
				</a>
			</div>

			<!-- PDIs -->
			<div *ngIf="managePOI" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="poiCategory"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/gps.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.pdi' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS -->
			<div *ngIf="manageNotificationGroups || manageRules"
				class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': notificationGroups.isActive || rules.isActive}" role="button"
					href="#notificationsCollapse" data-bs-toggle="collapse" aria-controls="notificationsCollapse"
					aria-expanded="false">
					<svg-icon src="assets/icons/bell.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.notifications' | translate}}</div>
				</a>
			</div>

			<!-- NOTIFICATIONS SUB ITEMS -->
			<div class="list-group collapse" id="notificationsCollapse">

				<!-- NOTIFICATIONS GROUPS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageNotificationGroups}" routerLink="noticeGroups"
						[routerLinkActive]="['nav-link-active']" #notificationGroups="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.notificationGroups' | translate}}</div>
					</a>
				</div>

				<!-- RULES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a [ngClass]="{'disabled': !manageRules}" routerLink="rule" [routerLinkActive]="['nav-link-active']"
						#rules="routerLinkActive" class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.rules' | translate}}</div>
					</a>
				</div>

			</div>

			<!-- CLIENTS -->
			<div *ngIf="manageClients" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0" routerLink="clients"
					[routerLinkActive]="['nav-link-active']">
					<svg-icon src="assets/icons/star.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-stroke-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.clients' | translate}}</div>
				</a>
			</div>

			<!-- USERS -->
			<div *ngIf="manageUsers" class="list-group-item  border-0 p-0 px-3 custom-menu-list-group-item">
				<a class="nav-link d-flex justify-content-start align-items-center p-0"
					[ngClass]="{'nav-link-active': users.isActive || roles.isActive || profiles.isActive}" role="button"
					href="#usersCollapse" data-bs-toggle="collapse" aria-controls="usersCollapse" aria-expanded="false">
					<svg-icon src="assets/icons/user.svg" class="d-flex flex-wrap mx-1 nav-bar-icon svg-fill-icon"
						[svgStyle]="{ 'height.px': 14, 'width.px': 14 }"></svg-icon>
					<div class="nav-bar-text" i18n>{{'navbar.users' | translate}}</div>
				</a>
			</div>

			<!-- USERS SUB ITEMS -->
			<div class="list-group collapse" id="usersCollapse">

				<!-- USERS -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="users" [routerLinkActive]="['nav-link-active']" #users="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.users' | translate}}</div>
					</a>
				</div>

				<!-- ROLES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="roles" [routerLinkActive]="['nav-link-active']" #roles="routerLinkActive"
						class="nav-link border-bottom">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.roles' | translate}}</div>
					</a>
				</div>

				<!-- PROFILES -->
				<div class="nav-item list-group-item border-0 px-4 custom-menu-list-group-item">
					<a routerLink="profiles" [routerLinkActive]="['nav-link-active']" #profiles="routerLinkActive"
						class="nav-link">
						<div class="nav-bar-text border-bottom" i18n>{{'navbar.profiles' | translate}}</div>
					</a>
				</div>

			</div>

		</div>
	</div>

</div>