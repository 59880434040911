<p-accordionTab>


  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">Start/Stop Report
          (AT + GTSSR)</span>
      </div>
      <div class="right">
        <p-inputSwitch (onChange)="handleChange($event)"
          [(ngModel)]="StartStopReport.startStopReportMode"></p-inputSwitch>
      </div>
    </div>
  </p-header>

  <div class="ui-g" #StartStopReportDiv>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Time to Stop">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" id="timeToStop" size="2" [(ngModel)]="StartStopReport.timeToStop" [min]="1"
              [max]="30"></p-spinner>
            <span class="ui-inputgroup-addon">0~30</span>
            <span class="ui-inputgroup-addon">minutes</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Time to Start">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" id="timeToStart" size="2" [(ngModel)]="StartStopReport.timeToStart" [min]="1"
              [max]="5"></p-spinner>
            <span class="ui-inputgroup-addon">1~5</span>
            <span class="ui-inputgroup-addon">minutes</span>
          </div>
        </div>
      </div>

    </div>

    <div class="row align-items-center">

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Fake Tow Delay">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" id="Start Speed" size="2" [(ngModel)]="StartStopReport.startSpeed" [min]="1"
              [max]="10"></p-spinner>
            <span class="ui-inputgroup-addon">1~10</span>
            <span class="ui-inputgroup-addon">Kmƒh</span>
          </div>
        </div>
      </div>

      <div class="col row dvcFields">
        <div class="col lblStyle">
          <form-input fieldName="Long Stop">
          </form-input>
        </div>
        <div class="col">
          <div class="ui-inputgroup">
            <p-spinner step="1" id="loginStop" size="7" [(ngModel)]="StartStopReport.loginStop" [min]="0"
              [max]="43200"></p-spinner>
            <span class="ui-inputgroup-addon">0~43200 </span>
            <span class="ui-inputgroup-addon">minutes</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</p-accordionTab>