<p-accordionTab>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="left">
        <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">GPS DATA SENDING
          INTERVAL (TIMER)</span>
      </div>
    </div>
  </p-header>

  <div class="row align-items-center">

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Timer ACC ON">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="gpsDataSendingInterval.timer1" [min]="5" [max]="18000"></p-spinner>
          <span class="ui-inputgroup-addon">5~18000</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

    <div class="col row dvcFields">
      <div class="col lblStyle">
        <form-input fieldName="Timer ACC OFF">
        </form-input>
      </div>
      <div class="col">
        <div class="ui-inputgroup">
          <p-spinner size="5" [(ngModel)]="gpsDataSendingInterval.timer2" [min]="0" [max]="18000"></p-spinner>
          <span class="ui-inputgroup-addon">0~18000</span>
          <span class="ui-inputgroup-addon">sec</span>
        </div>
      </div>
    </div>

  </div>
</p-accordionTab>