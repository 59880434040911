<!-- TABLE CHANGE STATUS BUTTON -->
<button class='btn d-flex align-items-center gap-2 table-download-button'
        type='button'
        (click)="onClick()"
        [disabled]="isDisabled"
>
  <!-- BUTTON ICON -->
  <span class='d-flex align-items-center justify-content-center'>
    <svg-icon src='assets/icons/download-button.svg'
              class='d-flex align-items-center justify-content-center'
              [svgStyle]="{'height.px': 15, 'width.px': 15}"
    ></svg-icon>
  </span>

  <!-- BUTTON TEXT -->
  <span class='ms-auto me-auto' i18n>
    {{ 'general.downloadFile' | translate }}
  </span>

</button>